import noop from "common/utils/universal/noop";
import * as React from "react";
import { useProgrammingConceptFormListItemIdContext } from "./ProgrammingConceptFormListItemIdContext";
import { useProgrammingConceptIdContext } from "./ProgrammingConceptIdContext";

type ProgrammingConceptId = string;
type ListItemId = string;
type InvalidFieldIds = Set<string>;
type ListItemProgrammingConceptInvalidFields = Map<ListItemId, InvalidFieldIds>;
type ProgrammingConceptInvalidFields =
  | InvalidFieldIds
  | ListItemProgrammingConceptInvalidFields;

export type InvalidFieldsType = Map<
  ProgrammingConceptId,
  ProgrammingConceptInvalidFields
>;

const InvalidFieldsContext = React.createContext<InvalidFieldsType>(new Map());

const SetInvalidFieldsContext =
  React.createContext<React.Dispatch<React.SetStateAction<InvalidFieldsType>>>(
    noop
  );

export class AllInvalidFields extends Map<
  string,
  ProgrammingConceptInvalidFields
> {}

export const useInvalidFields = () => React.useContext(InvalidFieldsContext);

export const useInvalidProgrammingConceptFields = (
  programmingConceptId: string
) => useInvalidFields().get(programmingConceptId);

export const useProgrammingConceptHasInvalidFields = (
  programmingConceptId: string
) => {
  const invalidProgrammingConceptFields =
    useInvalidProgrammingConceptFields(programmingConceptId);
  if (invalidProgrammingConceptFields instanceof Map) {
    return (
      !!invalidProgrammingConceptFields.size &&
      [...invalidProgrammingConceptFields.values()].some(
        (invalidListItemFields) => invalidListItemFields.size
      )
    );
  }

  return !!invalidProgrammingConceptFields?.size;
};

export const useInvalidListItemFields = (
  listItemId: string | null | undefined
) => {
  const programmingConceptId = useProgrammingConceptIdContext();
  const programmingConceptInvalidFields =
    useInvalidProgrammingConceptFields(programmingConceptId);

  return listItemId && programmingConceptInvalidFields instanceof Map
    ? programmingConceptInvalidFields.get(listItemId) ?? new Set()
    : new Set();
};

export const useListItemHasInvalidFields = (
  listItemId: string | null | undefined
) => !!useInvalidListItemFields(listItemId).size;

export const useFieldIsInvalid = (fieldId: string) => {
  const invalidProgrammingConceptFields = useInvalidProgrammingConceptFields(
    useProgrammingConceptIdContext()
  );
  const listItemId = useProgrammingConceptFormListItemIdContext();
  const invalidListItemFields = useInvalidListItemFields(listItemId);

  return listItemId
    ? invalidListItemFields.has(fieldId)
    : !!invalidProgrammingConceptFields?.has(fieldId);
};

export const useSetInvalidFields = () =>
  React.useContext(SetInvalidFieldsContext);

export const isListItemProgrammingConceptInvalidFields = (
  item: ProgrammingConceptInvalidFields
): item is ListItemProgrammingConceptInvalidFields => item instanceof Map;

const defaultInvalidFieldIds = new Set() as InvalidFieldIds;

const getInvalidFieldIds = (
  programmingConceptId: string,
  listItemId: string | null | undefined,
  invalidFields: InvalidFieldsType
): InvalidFieldIds => {
  const invalidProgrammingConceptFields =
    invalidFields.get(programmingConceptId);
  if (!invalidProgrammingConceptFields) {
    return defaultInvalidFieldIds;
  }

  if (
    isListItemProgrammingConceptInvalidFields(invalidProgrammingConceptFields)
  ) {
    return listItemId
      ? invalidProgrammingConceptFields.get(listItemId) ??
          defaultInvalidFieldIds
      : defaultInvalidFieldIds;
  }

  return invalidProgrammingConceptFields;
};

const fieldIsInInvalidFieldsState = (
  fieldId: string,
  programmingConceptId: string,
  listItemId: string | null | undefined,
  invalidFields: InvalidFieldsType
) =>
  getInvalidFieldIds(programmingConceptId, listItemId, invalidFields).has(
    fieldId
  );

const addInvalidField = (
  fieldId: string,
  programmingConceptId: string,
  listItemId: string | null | undefined,
  invalidFields: InvalidFieldsType
) => {
  const isInInvalidFieldsState = fieldIsInInvalidFieldsState(
    fieldId,
    programmingConceptId,
    listItemId,
    invalidFields
  );
  if (!isInInvalidFieldsState) {
    const invalidFieldIdsClone = new Set(
      getInvalidFieldIds(programmingConceptId, listItemId, invalidFields)
    ).add(fieldId);

    const invalidProgrammingConceptFields =
      invalidFields.get(programmingConceptId);
    const invalidProgrammingConceptFieldsClone =
      listItemId &&
      invalidProgrammingConceptFields &&
      isListItemProgrammingConceptInvalidFields(invalidProgrammingConceptFields)
        ? new Map(invalidProgrammingConceptFields).set(
            listItemId,
            invalidFieldIdsClone
          )
        : listItemId
        ? new Map([[listItemId, invalidFieldIdsClone]])
        : invalidFieldIdsClone;

    const invalidFieldsClone = new Map(invalidFields);
    invalidFieldsClone.set(
      programmingConceptId,
      invalidProgrammingConceptFieldsClone
    );

    return invalidFieldsClone;
  } else {
    return invalidFields;
  }
};
export const removeProgrammingConceptFromInvalidFields =
  (programmingConceptId: string) => (allInvalidFields: AllInvalidFields) => {
    if (allInvalidFields.has(programmingConceptId)) {
      const clone = new AllInvalidFields(allInvalidFields);
      clone.delete(programmingConceptId);
      return clone;
    } else {
      return allInvalidFields;
    }
  };

export const removeProgrammingConceptsFromInvalidFields =
  (programmingConceptIds: string[]) => (allInvalidFields: AllInvalidFields) =>
    programmingConceptIds.reduce(
      (acc, programmingConceptId) =>
        removeProgrammingConceptFromInvalidFields(programmingConceptId)(acc),
      allInvalidFields
    );

export const removeAllInvalidFieldsFromListItem = (
  programmingConceptId: string,
  listItemId: string | null | undefined,
  invalidFields: InvalidFieldsType
) => {
  const isInInvalidFieldsState =
    getInvalidFieldIds(programmingConceptId, listItemId, invalidFields).size >
    0;
  if (isInInvalidFieldsState) {
    const emptyInvalidFieldIdString = new Set<string>();

    const invalidProgrammingConceptFields =
      invalidFields.get(programmingConceptId);
    const invalidProgrammingConceptFieldsClone = listItemId
      ? new Map(
          invalidProgrammingConceptFields &&
          isListItemProgrammingConceptInvalidFields(
            invalidProgrammingConceptFields
          )
            ? invalidProgrammingConceptFields
            : []
        ).set(listItemId, emptyInvalidFieldIdString)
      : emptyInvalidFieldIdString;

    const invalidFieldsClone = new Map(invalidFields);
    invalidFieldsClone.set(
      programmingConceptId,
      invalidProgrammingConceptFieldsClone
    );

    return invalidFieldsClone;
  } else {
    return invalidFields;
  }
};

export const removeInvalidFieldTypeFromAllListItems = (
  programmingConceptId: string,
  fieldIdType: string,
  invalidFields: InvalidFieldsType
) => {
  const invalidProgrammingConceptFields =
    invalidFields.get(programmingConceptId);
  if (invalidProgrammingConceptFields instanceof Map) {
    const invalidProgrammingConceptFieldsClone = new Map(
      invalidProgrammingConceptFields
    );
    invalidProgrammingConceptFieldsClone.forEach(
      (invalidFieldIds, listItemId) => {
        invalidFieldIds.forEach((invalidFieldId) => {
          if (invalidFieldId.startsWith(fieldIdType)) {
            invalidFieldIds.delete(invalidFieldId);
          }
        });
      }
    );

    const invalidFieldsClone = new Map(invalidFields);
    invalidFieldsClone.set(
      programmingConceptId,
      invalidProgrammingConceptFieldsClone
    );

    return invalidFieldsClone;
  } else {
    return invalidFields;
  }
};

export const removeInvalidField = (
  fieldId: string,
  programmingConceptId: string,
  listItemId: string | null | undefined,
  invalidFields: InvalidFieldsType
) => {
  const isInInvalidFieldsState = fieldIsInInvalidFieldsState(
    fieldId,
    programmingConceptId,
    listItemId,
    invalidFields
  );
  if (isInInvalidFieldsState) {
    const invalidFieldIdsClone = new Set(
      getInvalidFieldIds(programmingConceptId, listItemId, invalidFields)
    );
    invalidFieldIdsClone.delete(fieldId);

    const invalidProgrammingConceptFields =
      invalidFields.get(programmingConceptId);
    const invalidProgrammingConceptFieldsClone = listItemId
      ? new Map(
          invalidProgrammingConceptFields &&
          isListItemProgrammingConceptInvalidFields(
            invalidProgrammingConceptFields
          )
            ? invalidProgrammingConceptFields
            : []
        ).set(listItemId, invalidFieldIdsClone)
      : invalidFieldIdsClone;

    const invalidFieldsClone = new Map(invalidFields);
    invalidFieldsClone.set(
      programmingConceptId,
      invalidProgrammingConceptFieldsClone
    );

    return invalidFieldsClone;
  } else {
    return invalidFields;
  }
};

export const useSetFieldValidity = (
  programmingConceptId: string,
  listItemId: string | null | undefined
) => {
  const setInvalidFields = useSetInvalidFields();

  return React.useCallback(
    (element: HTMLInputElement | HTMLSelectElement) => {
      setInvalidFields((invalidFields) =>
        element.willValidate && !element.validity.valid
          ? addInvalidField(
              element.id,
              programmingConceptId,
              listItemId,
              invalidFields
            )
          : removeInvalidField(
              element.id,
              programmingConceptId,
              listItemId,
              invalidFields
            )
      );
    },
    [setInvalidFields, programmingConceptId, listItemId]
  );
};

export function InvalidFieldsContextProvider(props: {
  invalidFields: InvalidFieldsType;
  setInvalidFields: React.Dispatch<React.SetStateAction<InvalidFieldsType>>;
  children: React.ReactNode;
}) {
  return (
    <InvalidFieldsContext.Provider value={props.invalidFields}>
      <SetInvalidFieldsContext.Provider value={props.setInvalidFields}>
        {/* Ensure we don't re-render the children if only the state has changed */}
        {React.useMemo(() => props.children, [props.children])}
      </SetInvalidFieldsContext.Provider>
    </InvalidFieldsContext.Provider>
  );
}
