/**
 * @generated SignedSource<<e18e7982c94efd8a597d1a91439ff817>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UniviewMotionDetectionRegions_secureComCamera$data = {
  readonly id: string;
  readonly motionDetectionRegions: {
    readonly activeDetectionRegionIndex: number | null;
    readonly maxCoordinatesX: number;
    readonly maxCoordinatesY: number;
    readonly minCoordinatesX: number;
    readonly minCoordinatesY: number;
    readonly mouseDown: boolean | null;
    readonly regions: ReadonlyArray<{
      readonly activeCoordinateIndexes: ReadonlyArray<number> | null;
      readonly coordinates: ReadonlyArray<{
        readonly x: number;
        readonly y: number;
      }>;
      readonly daySensitivityLevel: number;
      readonly id: string;
      readonly index: number | null;
    }>;
  };
  readonly " $fragmentType": "UniviewMotionDetectionRegions_secureComCamera";
};
export type UniviewMotionDetectionRegions_secureComCamera$key = {
  readonly " $data"?: UniviewMotionDetectionRegions_secureComCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"UniviewMotionDetectionRegions_secureComCamera">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UniviewMotionDetectionRegions_secureComCamera",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MotionDetectionRegions",
      "kind": "LinkedField",
      "name": "motionDetectionRegions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minCoordinatesX",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxCoordinatesX",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minCoordinatesY",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxCoordinatesY",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MotionDetectionRegion",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "daySensitivityLevel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MotionDetectionRegionCoordinates",
              "kind": "LinkedField",
              "name": "coordinates",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "x",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "y",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activeCoordinateIndexes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "index",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activeDetectionRegionIndex",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mouseDown",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SecureComCamera",
  "abstractKey": null
};
})();

(node as any).hash = "fd4b007a120f34a5fdc0ea753cbc32e0";

export default node;
