/**
 * @generated SignedSource<<02bc7247f1d75063de7a3ab8a81df73a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsPanicSupervisionField_systemOptions$data = {
  readonly panicSupervision?: boolean;
  readonly " $fragmentType": "SystemOptionsPanicSupervisionField_systemOptions";
};
export type SystemOptionsPanicSupervisionField_systemOptions$key = {
  readonly " $data"?: SystemOptionsPanicSupervisionField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsPanicSupervisionField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsPanicSupervisionField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "panicSupervision",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "9c08e57f0939c8ebf42a736057be3320";

export default node;
