/**
 *
 * SleepIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SleepIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M62,19c-1.104,0-2,0.896-2,2v6h-4.691C39.641,12.107,21.428,15.121,20.652,15.26c-0.759,0.134-1.349,0.689-1.561,1.397
    c-0.838-0.291-1.725-0.45-2.628-0.45c-4.45,0-8.07,3.64-8.07,8.114c0,0.915,0.157,1.822,0.456,2.679H4V11c0-1.104-0.896-2-2-2
    s-2,0.896-2,2v44c0,1.104,0.896,2,2,2s2-0.896,2-2v-4h56v4c0,1.104,0.896,2,2,2s2-0.896,2-2V21C64,19.896,63.104,19,62,19z
     M13.266,26.868c-0.571-0.731-0.874-1.612-0.874-2.547c0-2.269,1.826-4.114,4.07-4.114c0.93,0,1.819,0.338,2.538,0.92V27h-5.651
    C13.32,26.957,13.298,26.91,13.266,26.868z M4,47V31h15v16H4z M23,47V19.028c4.679-0.32,18.4-0.112,30.097,11.398
    C53.471,30.793,53.975,31,54.5,31H60v16H23z"
      />
    </g>
  </SvgIcon>
);

export default SleepIcon;
