/**
 * @generated SignedSource<<8855795932e2054f5683b3c286dd9817>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommPathAccountNumberField_panel$data = {
  readonly accountNumber: string;
  readonly " $fragmentType": "CommPathAccountNumberField_panel";
};
export type CommPathAccountNumberField_panel$key = {
  readonly " $data"?: CommPathAccountNumberField_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathAccountNumberField_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathAccountNumberField_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNumber",
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "53e86f6e9f9e2b1f99c933b38692ed3e";

export default node;
