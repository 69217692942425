import DaColors from "app/common/DaColors";
import DaFontWeight from "app/common/DaFontWeight";
import Icon from "components/Icon";
import React from "react";
import styled from "styled-components/macro";

interface CheckboxProps {
  checked: boolean;
  label?: string | JSX.Element;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  backgroundColor?: string;
  parentCheckbox?: boolean;
  disabled?: boolean;
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  textAlign?: string;
  boxSize?: string;
  fontSz?: string;
}

// Styled components
const CheckboxContainer = styled.div<{ parentCheckbox?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: ${(props) => (props.parentCheckbox ? ".8rem" : "0")};
  background-color: ${(props) =>
    props.parentCheckbox ? DaColors.Neutral300 : DaColors.White};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
  height: 0;
  width: 0;
`;

const StyledCheckbox = styled.div<{
  checked: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  boxSize?: string;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.boxSize ? props.boxSize : "2rem")};
  height: ${(props) => (props.boxSize ? props.boxSize : "2rem")};
  margin-right: 0.8rem;
  background: ${(props) =>
    props.checked
      ? props.backgroundColor || DaColors.InfoBase20Percent
      : props.disabled
      ? DaColors.Neutral300
      : DaColors.White};
  border-radius: 0.2rem;
  border: 0.1rem solid
    ${(props) =>
      props.checked
        ? DaColors.InfoBase
        : props.disabled
        ? DaColors.Neutral300
        : DaColors.Neutral500};

  transition: all 150ms;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  svg {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }

  &:hover {
    border-color: ${DaColors.PrimaryBase};
  }

  &:focus {
    border-color: ${DaColors.PrimaryBase};
  }

  &:active {
    border-color: ${DaColors.PrimaryBase};
  }
`;

const Label = styled.span<{ parentCheckbox?: boolean; fontSz?: string }>`
  margin-left: 0.8rem;
  min-height: 2.1rem;
  margin-bottom: 0;
  width: 100%;
  font-weight: ${(props) =>
    props.parentCheckbox ? DaFontWeight.Bold : DaFontWeight.Regular};
  font-size: ${(props) =>
    props.fontSz ? props.fontSz : props.parentCheckbox ? "1.4rem " : "1.2rem"};
  background-color: ${(props) =>
    props.parentCheckbox ? DaColors.Neutral300 : DaColors.White};
  margin: ${(props) =>
    props.parentCheckbox ? "0 0.4rem 0 0" : ".8rem 0.4rem .8rem 0"};
  padding: ${(props) => (props.parentCheckbox ? ".8rem" : "0")};
`;

// Checkbox functional component
const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  label,
  onChange,
  backgroundColor,
  parentCheckbox,
  disabled,
  id,
  onClick,
  className,
  textAlign,
  boxSize,
  fontSz,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement;
    target.checked = !target.checked;
    const newEvent = {
      ...e,
      target,
    };
    onChange &&
      onChange(newEvent as unknown as React.ChangeEvent<HTMLInputElement>);
  };
  return (
    <CheckboxContainer
      className={className}
      parentCheckbox={parentCheckbox}
      id={id}
    >
      <HiddenCheckbox
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <StyledCheckbox
        checked={checked}
        backgroundColor={backgroundColor}
        disabled={disabled}
        onClick={handleClick}
        boxSize={boxSize}
      >
        <Icon name={"checkmark"} color={DaColors.Info600} />
      </StyledCheckbox>
      {label && (
        <Label parentCheckbox={parentCheckbox} fontSz={fontSz}>
          {label}
        </Label>
      )}
    </CheckboxContainer>
  );
};

export default Checkbox;
