/**
 * @generated SignedSource<<870b7f8062267a9d3eee0b3b87a9cb0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts";
};
export type XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "beca6d94d41af8d931b97026a338218f";

export default node;
