import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useMessagingSetupFragment } from "./MessagingSetupContext";
import { MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup$key } from "./__generated__/MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup.graphql";

export const messagingSetupSendSmsOnOpeningAndClosingFieldId = () =>
  "messaging-setup-send-sms-on-opening-and-closing";

function MessagingSetupSendSmsOnOpeningAndClosingField() {
  const [
    { sendSmsOnOpeningAndClosing, enableMessaging },
    updateMessagingSetup,
  ] = useMessagingSetupFragment<MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup$key>(
    graphql`
      fragment MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup on MessagingSetup {
        sendSmsOnOpeningAndClosing
        enableMessaging
      }
    `
  );

  const fieldId = messagingSetupSendSmsOnOpeningAndClosingFieldId();
  const disabled = !enableMessaging;
  const label = "SMS O/C";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Enable Opening and Closing SMS messages for MyAccess users."
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={sendSmsOnOpeningAndClosing}
        onChange={() => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(
              !sendSmsOnOpeningAndClosing,
              "sendSmsOnOpeningAndClosing"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MessagingSetupSendSmsOnOpeningAndClosingField;
