import { App } from "app-module";
import graphql from "babel-plugin-relay/macro";
import { IconButtonThatLooksLikeLink } from "components/DaStyledElements/ClickableElements";
import { GenericPageFallback } from "components/GenericPageFallback";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React, { Suspense, useMemo } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { react2angular } from "react2angular";
import { idAsString, toDealerId } from "securecom-graphql/client";
import styled from "styled-components";
import IntegrationModal from "./IntegrationForm";
import { IntegrationGridView } from "./IntegrationGridView";
import { ReceiverForm } from "./ReceiverForm";
import { ReceiverTable } from "./ReceiverTable";
import {
  UserIsTechSupportProvider,
  useUserIsTechSupport,
} from "./UserIsTechSupportContext";
import { CentralStationQuery } from "./__generated__/CentralStationQuery.graphql";

const CentralStationSettingsGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(2, minmax(0, max-content));
  grid-template-columns: 1fr;
  grid-template-areas: "settings" "receivers";
  grid-gap: var(--measure-1x);
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

const CentralStationSettingsGridReceivers = styled.div`
  grid-area: receivers;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;
const CentralStationSettingsGridSettings = styled.div`
  grid-area: settings;
`;

const CentralStationView: React.FC<
  React.PropsWithChildren<{ dealerId: string; userService: any }>
> = ({ dealerId, userService }) => {
  const data = useLazyLoadQuery<CentralStationQuery>(
    graphql`
      query CentralStationQuery($dealerId: ID!) {
        ...IntegrationGridView_query
        ...IntegrationForm_Query
        dealer(id: $dealerId) {
          receivers(sort: { keys: ["number"], order: ASC }) {
            totalCount
          }
          id
          ...ReceiverTableDealer_dealer
          ...ReceiverFormModalDealer_dealer
          ...NewReceiverFormModalDealer_dealer
        }
      }
    `,
    { dealerId: idAsString(toDealerId(dealerId)) }
  );
  const [receiverModalOpen, setReceiverModalOpen] = React.useState(false);
  const [integrationModalOpen, setIntegrationModalOpen] = React.useState(false);
  const { isTechSupport, setIsTechSupport } = useUserIsTechSupport();

  React.useEffect(() => {
    if (userService.canReadMonitoringReceiverSettingsOnly()) {
      setIsTechSupport(true);
    }
  }, [userService, setIsTechSupport]);

  return data.dealer ? (
    <>
      <div className="row">
        <div className="page-header">
          <div className="page-header__left">
            <div className="page-header__title">Monitoring Center Settings</div>
          </div>
        </div>
      </div>
      <CentralStationSettingsGrid>
        <CentralStationSettingsGridSettings>
          <div className="row">
            <div className="sub-header">
              <div className="sub-header__left">
                <div className="sub-header__title">Monitoring Centers</div>
                <IconButtonThatLooksLikeLink
                  className="btn btn-sm btn-add ml"
                  onClick={() => {
                    setIntegrationModalOpen(true);
                  }}
                  disabled={isTechSupport}
                ></IconButtonThatLooksLikeLink>
              </div>
            </div>
          </div>
          <div className="row">
            <IntegrationGridView queryRef={data} />
          </div>
        </CentralStationSettingsGridSettings>
        <CentralStationSettingsGridReceivers>
          <div className="row">
            <div className="sub-header">
              <div className="sub-header__left">
                <div className="sub-header__title">Receivers</div>
                <IconButtonThatLooksLikeLink
                  className="btn btn-sm btn-add ml"
                  onClick={() => setReceiverModalOpen(true)}
                  disabled={isTechSupport}
                ></IconButtonThatLooksLikeLink>
              </div>
            </div>
          </div>
          <div className="row">
            <ReceiverTable dealerRef={data.dealer} />
          </div>
        </CentralStationSettingsGridReceivers>
      </CentralStationSettingsGrid>
      {receiverModalOpen && data.dealer.receivers.totalCount < 100 && (
        <ReceiverForm
          dealerRef={data.dealer}
          onCancel={() => setReceiverModalOpen(false)}
        />
      )}
      {integrationModalOpen && (
        <IntegrationModal
          queryRef={data}
          onCancel={() => setIntegrationModalOpen(false)}
        />
      )}
    </>
  ) : null;
};

const CentralStationRoot: React.FC<React.PropsWithChildren<any>> = ({
  RelayService,
  $state,
  $rootScope,
  UserService,
}: any) => {
  const environment = useMemo(
    () => RelayService.getEnvironment(),
    [RelayService, UserService]
  );
  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <Suspense fallback={<GenericPageFallback />}>
        <UserIsTechSupportProvider>
          <AlertsContextProvider $rootScope={$rootScope}>
            <CentralStationView
              dealerId={$state.params.dealer_id}
              userService={UserService}
            />
          </AlertsContextProvider>
        </UserIsTechSupportProvider>
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "centralStation",
    react2angular(
      CentralStationRoot,
      [],
      ["RelayService", "$state", "$rootScope", "UserService"]
    )
  );
};
