/**
 * @ngdoc service
 * @name App.factory:CustomerUser
 *
 * @description
 * Factory/model for a CustomerUser, which is also known as App User.  Simply, a user belonging to a single customer.
 *
 */
App.factory("CustomerUser", [
  "$q",
  "$filter",
  "PROPS",
  "UsersV2API",
  "CustomerUsersV2API",
  "baseUser",
  function ($q, $filter, PROPS, UsersV2API, CustomerUsersV2API, baseUser) {
    var User = function (userObject) {
      angular.extend(this, userObject);
      angular.extend(this, {
        /**
         * Get a new, default user template object from VK
         */
        new: function (customerId) {
          var deferred = $q.defer();
          var _this = this;
          this.customer_id = customerId;
          CustomerUsersV2API.new(
            { customer_id: customerId }, //params
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              _this.extendMe(data.user);
              _this.isNew = true;
              _this.init();
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },
        /**
         * Create the user in VK, only after calling this.new()
         * @param {boolean} [addToExistingPerson] - Adding this user to an existing person
         */
        create: function (addToExistingPerson) {
          var deferred = $q.defer();
          var _this = this;
          var params = { customer_id: _this.customer_id };
          if (addToExistingPerson) {
            params.auto_generate_email = true;
          }
          // Create a copy of this object for manipulating the data before passing it to the API
          var parsedUser = angular.copy(this);
          CustomerUsersV2API.create(
            params, //params
            parsedUser.toJsonForCreate(),
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              _this.extendMe(data.user);
              deferred.resolve(data);
            },
            function (error) {
              //failure
              _this.handleError(error);
              deferred.reject(error);
            }
          ).$promise.finally(function () {
            parsedUser = null; // null out the temp object used to send data to the server
          });
          return deferred.promise;
        },

        /**
         * Function to initialize necessary attributes
         */
        init: function () {
          this.original_user_permissions = angular.copy(_this.user_permissions);
        },

        isSystemSelected: function (systemID) {
          var _this = this;
          var selectedSystems = $filter("filter")(
            _this.user_permissions_checklist,
            { panel_id: systemID }
          );
          return selectedSystems.length > 0;
        },

        /**
         * Function to extend _this_ with additional/new attributes
         */
        extendMe: function (userObject) {
          angular.extend(this, userObject);
        },
      });

      var _this = this;
      this.extendMe(new baseUser());
    };
    return User;
  },
]);
