/**
 * @generated SignedSource<<4020e74d69419ba1d5445ad7f74c4006>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationForm_area$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly number: string;
  readonly " $fragmentSpreads": FragmentRefs<"AreaAccountNumberField_xrArea" | "AreaArmFirstAreaField_xrArea" | "AreaArmedOutputNumberField_area" | "AreaAutoArmField_area" | "AreaAutoDisarmField_area" | "AreaBadZonesField_area" | "AreaBankSafeVaultField_xrArea" | "AreaBurglaryBellOutputNumberField_area" | "AreaCardPlusPinField_xrArea" | "AreaCommonAreaField_xrArea" | "AreaContext_area" | "AreaDualAuthorityField_xrArea" | "AreaLateArmDelayField_area" | "AreaLateOutputNumberField_area" | "AreaListItem_area" | "AreaNameField_area" | "AreaNumberField_area" | "AreaOpenClosingReportsField_area" | "AreaTwoManRuleField_xrArea" | "XRAreaInformationFormAreaTitle_area">;
  readonly " $fragmentType": "XRAreaInformationForm_area";
};
export type XRAreaInformationForm_area$key = {
  readonly " $data"?: XRAreaInformationForm_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationForm_area">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRAreaInformationForm_area",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaAccountNumberField_xrArea"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaArmedOutputNumberField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaLateOutputNumberField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaLateArmDelayField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaBankSafeVaultField_xrArea"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaCommonAreaField_xrArea"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaArmFirstAreaField_xrArea"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaBurglaryBellOutputNumberField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaOpenClosingReportsField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaTwoManRuleField_xrArea"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaDualAuthorityField_xrArea"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRAreaInformationFormAreaTitle_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaCardPlusPinField_xrArea"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaContext_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaNumberField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaNameField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaAutoArmField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaAutoDisarmField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaBadZonesField_area"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaListItem_area"
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "c2365f9a0e202ee90966720ea93825a6";

export default node;
