import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { Svg } from "components/CameraEditCommon/CameraEditStyledComponents";
import {
  motionDetectionRegionsSvgId,
  viewBoxMaxHeight,
  viewBoxMaxWidth,
} from "components/CameraEditCommon/constants";
import {
  Coordinates,
  RegionBounds,
  ViewBox,
} from "components/CameraEditCommon/types";
import {
  getKeyboardTransformer,
  getMouseTransformer,
  getRegionColor,
  removeIndexes,
  renderXCoordinate,
  renderYCoordinate,
} from "components/CameraEditCommon/utils";
import * as React from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import { isNullOrUndefined } from "util";
import DetectionRegion from "./DetectionRegion";
import { VarHubCameraWithClientSchemaData } from "./types";
import { removeRegionUpdater, transformForKeyPress, translate } from "./utils";
import { RecorderCameraDetectionRegions_varHubCamera$key } from "./__generated__/RecorderCameraDetectionRegions_varHubCamera.graphql";

type Props = {
  camera: RecorderCameraDetectionRegions_varHubCamera$key;
  aspectRatio: number;
  isEditable: boolean;
};

function documentKeyDownUpdater(props: {
  store: RecordSourceProxy;
  cameraId: string;
  event: KeyboardEvent;
  bounds: RegionBounds;
}) {
  const { store, cameraId, event, bounds } = props;

  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  const activeDetectionRegionIndex = camera.getValue(
    "activeDetectionRegionIndex"
  );
  const detectionRegions = camera.getLinkedRecords("detectionRegions");

  if (isNullOrUndefined(activeDetectionRegionIndex)) {
    return;
  }

  if (event.key === "Escape") {
    camera.setValue(null, "activeDetectionRegionIndex");
  } else if (event.key === "Tab") {
    camera.setValue(
      (activeDetectionRegionIndex + 1) % detectionRegions.length,
      "activeDetectionRegionIndex"
    );
  } else if (event.key === "Backspace") {
    const activeRegion = detectionRegions[activeDetectionRegionIndex];
    const activeCoordinateIndexes = activeRegion.getValue(
      "activeCoordinateIndexes"
    );
    if (activeCoordinateIndexes?.length) {
      const geometry = activeRegion.getLinkedRecord("geometry");
      const coordinates = geometry.getValue("coordinates");
      geometry.setValue(
        [removeIndexes([...activeCoordinateIndexes])(coordinates[0])],
        "coordinates"
      );
      activeRegion.setValue([], "activeCoordinateIndexes");
    } else {
      const regionId = camera
        .getLinkedRecords("detectionRegions")
        [activeDetectionRegionIndex]?.getValue("id");
      if (regionId) {
        removeRegionUpdater(store, cameraId, regionId);
      }
    }
  } else if (
    isNotNullOrUndefined(activeDetectionRegionIndex) &&
    detectionRegions[activeDetectionRegionIndex]
  ) {
    transformForKeyPress(getKeyboardTransformer(bounds)(event))(
      camera.getLinkedRecords("detectionRegions")[activeDetectionRegionIndex]
    );
  }
}

function documentMouseDownUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: MouseEvent
) {
  const { target } = event;
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  const detectionRegions = camera.getLinkedRecords("detectionRegions");
  const activeDetectionRegionIndex = camera.getValue(
    "activeDetectionRegionIndex"
  );

  if (
    target instanceof Element &&
    target.id.match(/motion-detection-region-[0-9]+/)
  ) {
    const idParts = target.id.split("-");
    const activeRegionIndex = Number(idParts[3]);
    const activeRegion = detectionRegions[activeRegionIndex];
    const activeCoordinateIndexes = activeRegion.getValue(
      "activeCoordinateIndexes"
    );

    if (idParts[4] === "point") {
      const activeCoordinates = event.shiftKey
        ? new Set(activeCoordinateIndexes)
        : new Set<number>();
      activeCoordinates.add(Number(idParts[5]));
      activeRegion.setValue([...activeCoordinates], "activeCoordinateIndexes");
      camera.setValue(true, "mouseDown");
    } else if (activeCoordinateIndexes?.length) {
      activeRegion.setValue([], "activeCoordinateIndexes");
    } else {
      camera.setValue(activeRegionIndex, "activeDetectionRegionIndex");
      camera.setValue(true, "mouseDown");
    }
  } else if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
    const regions = camera.getLinkedRecords("detectionRegions");
    const activeRegion = regions[activeDetectionRegionIndex];
    if (isNotNullOrUndefined(activeRegion)) {
      activeRegion.setValue([], "activeCoordinateIndexes");
      camera.setValue(null, "activeDetectionRegionIndex");
    }
    camera.setValue(true, "mouseDown");
  } else {
    camera.setValue(true, "mouseDown");
  }
}

function documentMouseUpUpdater(store: RecordSourceProxy, cameraId: string) {
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  camera.setValue(false, "mouseDown");
}

function documentMouseMoveUpdater(props: {
  store: RecordSourceProxy;
  cameraId: string;
  event: MouseEvent;
  bounds: RegionBounds;
}) {
  const { store, cameraId, event, bounds } = props;
  const camera = store.get(
    cameraId
  ) as RecordProxy<VarHubCameraWithClientSchemaData>;
  const mouseDown = camera.getValue("mouseDown");
  const detectionRegions = camera.getLinkedRecords("detectionRegions");
  const activeDetectionRegionIndex = camera.getValue(
    "activeDetectionRegionIndex"
  );

  const svgElement = document.getElementById(motionDetectionRegionsSvgId);

  if (
    svgElement &&
    mouseDown &&
    isNotNullOrUndefined(activeDetectionRegionIndex) &&
    detectionRegions[activeDetectionRegionIndex]
  ) {
    translate(getMouseTransformer(bounds)(svgElement)(event))(
      detectionRegions[activeDetectionRegionIndex]
    );
  }
}

function DetectionRegions(props: Props) {
  const data = useFragment(
    graphql`
      fragment RecorderCameraDetectionRegions_varHubCamera on VarConnectedCamera {
        id
        activeDetectionRegionIndex
        mouseDown
        detectionRegions {
          id
          index
          slotNumber
          activeCoordinateIndexes
          focus
          geometry {
            coordinates
          }
        }
        minRegionX
        maxRegionX
        minRegionY
        maxRegionY
      }
    `,
    props.camera
  );

  const relayEnv = useRelayEnvironment();
  const { aspectRatio, isEditable } = props;

  const {
    id,
    activeDetectionRegionIndex,
    minRegionX,
    maxRegionX,
    minRegionY,
    maxRegionY,
  } = data;

  const bounds = React.useMemo(
    () => ({
      minX: minRegionX,
      maxX: maxRegionX,
      minY: minRegionY,
      maxY: maxRegionY,
    }),
    [minRegionX, maxRegionX, minRegionY, maxRegionY]
  );

  const viewBox = React.useMemo(
    (): ViewBox =>
      aspectRatio <= viewBoxMaxWidth / viewBoxMaxHeight
        ? [viewBoxMaxWidth * aspectRatio, viewBoxMaxHeight]
        : [viewBoxMaxWidth, viewBoxMaxHeight / aspectRatio],
    [aspectRatio]
  );

  const xCoordinateRenderer = React.useCallback(
    renderXCoordinate(bounds)(viewBox),
    [bounds, viewBox]
  );

  const yCoordinateRenderer = React.useCallback(
    renderYCoordinate(bounds)(viewBox),
    [bounds, viewBox]
  );

  React.useLayoutEffect(() => {
    relayEnv.commitUpdate((store) => {
      const camera = store.get(
        id
      ) as RecordProxy<VarHubCameraWithClientSchemaData>;
      const regions = camera.getLinkedRecords("detectionRegions");
      regions.forEach((region, index) => {
        region.setValue(index, "index");
      });
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentKeyDownUpdater({ store, cameraId: id, event, bounds });
      });
    }
    function handleMouseDown(event: MouseEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseDownUpdater(store, id, event);
      });
    }
    function handleMouseUp(event: MouseEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseUpUpdater(store, id);
      });
    }
    function handleMouseMove(event: MouseEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }

      relayEnv.commitUpdate((store) => {
        documentMouseMoveUpdater({
          store,
          cameraId: id,
          event,
          bounds,
        });
      });
    }

    if (isEditable) {
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (isEditable) {
        document.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [relayEnv, id, activeDetectionRegionIndex, bounds, viewBox]);

  const regions = React.useMemo(() => {
    if (isNullOrUndefined(activeDetectionRegionIndex)) {
      return data.detectionRegions;
    }

    const regionsWithActiveOnTop = [...data.detectionRegions];
    return regionsWithActiveOnTop.concat(
      regionsWithActiveOnTop.splice(activeDetectionRegionIndex, 1)
    );
  }, [activeDetectionRegionIndex, data.detectionRegions]);

  return (
    <Svg
      id={motionDetectionRegionsSvgId}
      viewBox={`0 0 ${viewBox[0]} ${viewBox[1]}`}
    >
      {regions.map((region) => (
        <DetectionRegion
          key={region.id}
          aspectRatio={aspectRatio}
          mouseDown={!!data.mouseDown}
          color={getRegionColor(region.slotNumber ?? -1)}
          index={region.index ?? -1}
          renderX={xCoordinateRenderer}
          renderY={yCoordinateRenderer}
          bounds={bounds}
          coordinates={(region.geometry.coordinates[0] as Coordinates[]) ?? []}
          activeCoordinateIndexes={
            (region.activeCoordinateIndexes as number[]) ?? []
          }
          cameraId={data.id}
          isActive={activeDetectionRegionIndex === region.index}
        />
      ))}
    </Svg>
  );
}

export default DetectionRegions;
