/**
 * @generated SignedSource<<84475643659327845629694d2bf54615>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
import { FragmentRefs } from "relay-runtime";
export type CommPathCommTypeField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly id: string;
  readonly number: number;
  readonly type: CommunicationPathType;
  readonly " $fragmentType": "CommPathCommTypeField_communicationPath";
};
export type CommPathCommTypeField_communicationPath$key = {
  readonly " $data"?: CommPathCommTypeField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathCommTypeField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathCommTypeField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "278adeb395f65020f889adaaa75df2aa";

export default node;
