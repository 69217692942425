App.factory("DealerDefaultsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/dealers/:dealer_id/:resource/:id",
      {},
      {
        newDefault: {
          method: "GET",
          params: { resource: "@resource", dealer_id: "@dealer_id", id: "new" },
        },
        indexDefaults: {
          method: "GET",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
          isArray: true,
        },
        showDefault: {
          method: "GET",
          params: { resource: "@resource", dealer_id: "@dealer_id", id: "@id" },
        },
        createDefault: {
          method: "POST",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
        },
        updateDefault: {
          method: "PUT",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
        },
        destroyDefault: {
          method: "DELETE",
          params: { resource: "@resource", dealer_id: "@dealer_id", id: "@id" },
        },
        newXRDefaultTpl: {
          method: "GET",
          params: { resource: "@resource", dealer_id: "@dealer_id", id: "new" },
        },
        createXRDefaultTpl: {
          method: "POST",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
        },
        getXRDefaultsTpl: {
          method: "GET",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
          isArray: true,
        },
        updateXRDefaultTpl: {
          method: "PUT",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
          isArray: true,
        },
        destroyXRDefaultTpl: {
          method: "DELETE",
          params: { resource: "@resource", dealer_id: "@dealer_id", id: "@id" },
        },
      }
    );
  },
]);
