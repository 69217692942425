/**
 * @generated SignedSource<<8a5608c1576009396380d8566a2cd0f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type BellOptionsAuxiliary1BellActionField_bellOptions$data = {
  readonly auxiliary1BellAction: BellActions;
  readonly " $fragmentType": "BellOptionsAuxiliary1BellActionField_bellOptions";
};
export type BellOptionsAuxiliary1BellActionField_bellOptions$key = {
  readonly " $data"?: BellOptionsAuxiliary1BellActionField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsAuxiliary1BellActionField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsAuxiliary1BellActionField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auxiliary1BellAction",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "667bd01c4e94f39eb0a4af62fb439c9a";

export default node;
