/**
 * @ngdoc service
 * @name App.factory:PanelTestV2API
 *
 * @description
 * API factory for Panel Test (Communications, Walk Test, Z-Wave)
 */
App.factory("PanelTestV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/:test/:method",
      {},
      {
        callAPI: {
          method: "POST",
          params: { panel_id: "@panel_id", test: "@test", method: "@method" },
        },
      }
    );
  },
]);
