/**
 * @generated SignedSource<<04f9ae9e52be476b83e9e09a0ab56be1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTBellOptionsProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly bellOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"BellOptionsAutomaticBellTestField_bellOptions" | "BellOptionsAuxiliary1BellActionField_bellOptions" | "BellOptionsAuxiliary2BellActionField_bellOptions" | "BellOptionsBellCutoffTimeField_bellOptions" | "BellOptionsBellOutputField_bellOptions" | "BellOptionsBurglaryBellActionField_bellOptions" | "BellOptionsCarbonMonoxideBellActionField_bellOptions" | "BellOptionsContext_bellOptions" | "BellOptionsEmergencyBellActionField_bellOptions" | "BellOptionsFireBellActionField_bellOptions" | "BellOptionsPanicBellActionField_bellOptions" | "BellOptionsSupervisoryBellActionField_bellOptions">;
    } | null;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XTBellOptionsProgrammingConceptForm_controlSystem";
};
export type XTBellOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XTBellOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTBellOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTBellOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BellOptions",
          "kind": "LinkedField",
          "name": "bellOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsContext_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsBellCutoffTimeField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsAutomaticBellTestField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsBellOutputField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsFireBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsBurglaryBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsSupervisoryBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsPanicBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsEmergencyBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsAuxiliary1BellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsAuxiliary2BellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsCarbonMonoxideBellActionField_bellOptions"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "114973db4710cd408fb4e1d502904318";

export default node;
