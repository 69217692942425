/**
 * @generated SignedSource<<ec47d14fc6086bc4f9aff4ca41800333>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRCardFormatsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly cardFormats: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: string;
      readonly " $fragmentSpreads": FragmentRefs<"XRCardFormatsProgrammingConceptFormInline_cardFormat">;
    }>;
    readonly id: string;
  };
  readonly " $fragmentType": "XRCardFormatsProgrammingConceptFormInline_controlSystem";
};
export type XRCardFormatsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRCardFormatsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCardFormatsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRCardFormatsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "f73bf5abe412334fe4e6acede8846a94";

export default node;
