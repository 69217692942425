export interface RawEventType {
  id: number;
  group: string;
  display_name: string;
}

export interface EventType {
  id: number;
  group: string;
  displayName: string;
}

const RELEVANT_EVENT_TYPE_IDS = [
  1, // Burglary Alarm
  2, // Armed All
  3, // Armed Perimeter
  4, // Some Areas Armed
  6, // All Areas Armed
  7, // Some Areas Disarmed
  9, // Armed Away
  10, // All Areas Disarmed
  11, // Armed Home
  12, // Armed Sleep
  13, // Alarm Canceled
  14, // Alarm Verified
  15, // Ambush
  19, // Zone Bypassed
  20, // Exit Zone Closed
  21, // Exit Zone Opened
  22, // Zone Activity
  23, // Zone/Device Trouble
  24, // Emergency Alarm
  25, // Fire Alarm
  26, // Panic Alarm
  28, // Alarm
  39, // Armed Stay
  40, // Access Granted
  41, // Access Denied: Arming Area
  42, // Access Denied: Failed to Exit
  43, // Access Denied: Invalid Area
  44, // Access Denied: Invalid Code
  45, // Access Denied: Invalid Level
  46, // Access Denied: Invalid Time
  66, // Door Locked
  67, // Door Unlocked
  68, // Door Forced Open
  74, // Zone Closed
  75, // Zone Opened
  76, // System Inactivity
  77, // Zone Inactivity
  78, // User Inactivity
  79, // Supervisory Alarm
  80, // Entry Delay Started
  83, // Carbon Monoxide Alarm
  84, // Door Propped
  85, // Inactive Door Access Code
  87, // Lock Down Cleared
  88, // Lock Down Initiated
  89, // Custom Action Activated
  92, // Early Morning Ambush
  99, // Video - Appearing Analytics Started
  100, // Video - Audio Detection Analytics Started
  101, // Video - Entering Analytics Started
  102, // Video - Exiting Analytics Started
  103, // Video - Explosion Analytics Started
  104, // Video - Face Detection Analytics Started
  105, // Video - Glass Break Analytics Started
  106, // Video - Gunshot Analytics Started
  107, // Video - Intrusion Analytics Started
  108, // Video - Loitering Analytics Started
  109, // Video - Passing Analytics Started
  110, // Video - Person Detection Analytics Started
  111, // Video - Scream Analytics Started
  112, // Video - Tamper Analytics Started
  113, // Video - Vehicle Detection Analytics Started
  116, // Z-Wave Device Lock Status
  117, // Z-Wave Device Switch Status
  119, // Zone Tamper Message
  133, // AlarmVision Object Detected
  137, // AlarmVision Camera Offline
  138, // AlarmVision Camera Online
  139, // AlarmVision Device Event
  140, // Keypad Event
  141, // Uniview Object Detected
  142, // Uniview Motion Detected
  143, // Uniview Manual Record
  153, // XV Object Detected
  154, // XV Camera Offline
  155, // XV Camera Online
  156, // XV Device Event
];

export const parseEventTypes = (rawEventTypes: RawEventType[]): EventType[] => {
  const relevantRawEventTypes = rawEventTypes.filter((e) =>
    RELEVANT_EVENT_TYPE_IDS.includes(e.id)
  );
  return relevantRawEventTypes.map((e) => ({
    id: e.id,
    group: e.group,
    displayName: e.display_name,
  }));
};
