import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreIpv6Field_remoteOptions$key } from "./__generated__/RemoteOptionsEntreIpv6Field_remoteOptions.graphql";

export const remoteOptionsEntreIpv6FieldId = () => "remote-options-entre-ipv6";

function RemoteOptionsEntreIpv6Field() {
  const [{ entreIpv6, entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreIpv6Field_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreIpv6Field_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreConnection
            entreIpv6
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreIpv6FieldId();
  const label = "Entré IPv6";
  const disabled = entreConnection !== RemoteOptionsConnectionType.NETWORK;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip={
        "Enable this option to use an IPv6 connection to the Entré server."
      }
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={!!entreIpv6}
        onChange={() => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(!entreIpv6, "entreIpv6");

            recordProxy.setValue(
              "0000:0000:0000:0000:0000:0000:0000:0000",
              "entreIpv6Address"
            );
            recordProxy.setValue(
              "0000:0000:0000:0000:0000:0000:0000:0000",
              "entreBackupIpv6Address"
            );
            recordProxy.setValue("0.0.0.0", "entreIpAddress");
            recordProxy.setValue("0.0.0.0", "entreBackupIpAddress");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreIpv6Field;
