App.controller("LayoutCtrl", LayoutCtrl);

LayoutCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$modal",
  "StyleDataAccessService",
];

function LayoutCtrl($rootScope, $scope, $modal, StyleDataAccessService) {
  init();

  function init() {
    $scope.isCollapsed = false;

    // slide show data
    //mockup data
    $scope.noWrapSlides = false;
    $scope.myInterval = 3000;
    $scope.slides = [
      { image: "https://dmp.com/File/bi0n/dashboard_1.png" },
      { image: "https://dmp.com/File/g9p0/dashboard_2.png" },
      { image: "https://dmp.com/File/5i3y/dashboard_3.png" },
    ];
    //pagination
    $scope.totalItems = 64;
    $scope.currentPage = 4;

    $scope.setPage = function (pageNo) {
      $scope.currentPage = pageNo;
    };

    $scope.pageChanged = function () {};

    $scope.maxSize = 5;
    $scope.bigTotalItems = 175;
    $scope.bigCurrentPage = 1;

    StyleDataAccessService.getStyleData("layout-data.json")
      .then(
        function (layoutData) {
          $scope.LayoutData = layoutData;
        },
        function (layoutError) {
          $rootScope.alerts.push({ type: "error", message: layoutError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    /**
     * Creates and opens an example  modal
     ********************************************************************/
    $scope.openExampleModal = function (size) {
      var exampleModal = $modal.open({
        templateUrl: "myModalContent.html",
        controller: "ExampleModalInstanceCtrl",
        windowClass: "",
        size: size,
        backdrop: true,
        scope: $scope,
      });

      modalInstance.result
        .then(
          function (reason) {
            $state.forceReload();
          },
          function () {}
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * A controller for the ExampleModal
     **********************************************************************************************/
    App.controller(
      "ExampleModalInstanceCtrl",
      function ($scope, $modalInstance, $state, UserService) {
        $scope.cancel = function () {
          $modalInstance.dismiss("cancel");
        };

        /**
         * Function Continue()
         * Fires firmware update and dismisses panel
         * ***********************************************/
        $scope.cancelExampleModal = function () {
          $modalInstance.dismiss("cancel");
        };
      }
    );
  }
}
