/**
 * @ngdoc directive
 * @name App.directive:sortPortlets
 * @restrict A
 * @description
 * Allows panel portlets to be dragged and dropped on the screen.  Wrapper for jquery-ui sortable.
 */
App.directive("daSortPortlets", function ($compile, $timeout) {
  var controllerFunc = function ($scope, $element) {
    // Component is optional
    if (!$.fn.sortable) return;

    var Selector = "[portlet]",
      storageKeyName = "portletState";

    angular.element(document).ready(function () {
      $timeout(function () {
        $(Selector).sortable({
          connectWith: Selector,
          items: "div.panel",
          handle: ".portlet-handler",
          opacity: 0.7,
          placeholder: "portlet box-placeholder",
          cancel: ".portlet-cancel",
          forcePlaceholderSize: true,
          iframeFix: false,
          tolerance: "pointer",
          helper: "original",
          revert: 200,
          forceHelperSize: true,
          start: saveListSize,
          update: savePortletOrder,
          create: loadPortletOrder,
        });
        // optionally disables mouse selection
        //.disableSelection()
      }, 0);
    });

    function savePortletOrder(event, ui) {
      var self = event.target;
      var data = angular.fromJson($scope.$storage[storageKeyName]);
      if (!data) {
        data = {};
      }
      data[self.id] = $(self).sortable("toArray").filter(Boolean);
      $scope.$storage[storageKeyName] = angular.toJson(data);

      // save portlet size to avoid jumps
      saveListSize.apply(self);
    }

    function loadPortletOrder(event) {
      var self = event.target;
      var data = angular.fromJson($scope.$storage[storageKeyName]);
      if (data) {
        var portletId = self.id,
          panels = data[portletId];
        if (panels) {
          var portlet = $("#" + portletId);

          $.each(panels, function (index, value) {
            $("#" + value).appendTo(portlet);
          });
        }
      }

      // save portlet size to avoid jumps
      saveListSize.apply(self);
    }

    // Keeps a consistent size in all portlet lists
    function saveListSize() {
      var $this = $(this);
      $this.css("min-height", $this.height());
    }
  };
  return {
    restrict: "A",
    priority: 1000,
    link: controllerFunc,
  };
});
