import styled from "styled-components";
import NakedList from "../NakedList";

const BasicArmingAreas = styled(NakedList)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export default BasicArmingAreas;
