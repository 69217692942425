import graphql from "babel-plugin-relay/macro";
import { rangeInclusive } from "common/utils/universal/array";
import { SimpleGrid } from "components/DaStyledElements/SimpleGrid";
import {
  DeleteConfirmModalBody,
  DeleteConfirmModalOverlay,
} from "components/Modal/ConfirmModal";
import * as R from "ramda";
import * as React from "react";
import { useMutation } from "react-relay/hooks";
import { joinSpaced } from "utils/string";
import { useCreateNotification } from "../EntryPointContext";
import { InlineField, ReadOnlyInput } from "../FormFields";
import Accordion from "../Layout/Accordion";
import { SectionHeader, SectionHeaderFull } from "../Layout/Layout";
import styles from "./DoorForm.module.css";
import { SiteControlSystemFormDispatch } from "./ExistingSiteControlSystemForm";
import { DoorFormFloorsRemoveOutputModuleMutation } from "./__generated__/DoorFormFloorsRemoveOutputModuleMutation.graphql";
import { ExistingSiteControlSystemForm_siteControlSystem$data } from "./__generated__/ExistingSiteControlSystemForm_siteControlSystem.graphql";
const FLOORS_PER_ELEVATOR = 10;
const MAX_NUMBER_ELEVATOR_MODULES = 9;

const removeOutputModuleMutation = graphql`
  mutation DoorFormFloorsRemoveOutputModuleMutation($outputModule: ID!) {
    removeSiteControlSystemOutputModule(outputModule: $outputModule) {
      ... on RemoveSiteControlSystemOutputModuleFailureResponse {
        error {
          ... on NotFoundError {
            errorType: type
          }
          ... on UnknownError {
            errorType: type
          }
        }
      }

      ... on RemoveSiteControlSystemOutputModuleSuccessResponse {
        outputModule {
          id
          name
          intendedUsage
        }
        controlSystem {
          id
        }
      }
    }
  }
`;

const ElevatorOutputModule = ({
  id,
  state,
  address,
  dispatch,
}: {
  id?: string;
  state: ElevatorOutputModuleState;
  address: number | string;
  dispatch: React.Dispatch<ElevatorAction>;
}) => {
  const [confirmingDeactivate, setConfirmingDeactivate] = React.useState(false);
  const createNotification = useCreateNotification();
  const [removeOutputModule, isRemovingOutputModule] =
    useMutation<DoorFormFloorsRemoveOutputModuleMutation>(
      removeOutputModuleMutation
    );

  return (
    <Accordion
      forceOpen={confirmingDeactivate}
      initiallyOpen={state.isOpen}
      header={
        <Accordion.Header>
          <Accordion.Toggleable>
            <span>
              <strong>{state.name}</strong>
            </span>
          </Accordion.Toggleable>

          <button
            type="button"
            className={joinSpaced(
              "btn btn-xs btn-danger",
              styles["btn-override"]
            )}
            disabled={isRemovingOutputModule}
            onClick={() => {
              setConfirmingDeactivate(true);
            }}
          >
            {isRemovingOutputModule ? (
              <span>
                <i
                  className={joinSpaced(
                    "fa fa-spin fa-spinner",
                    styles["icon-override"]
                  )}
                ></i>
                Removing...
              </span>
            ) : (
              <span>Remove</span>
            )}
          </button>
        </Accordion.Header>
      }
    >
      <div className="mar-b-24">
        <SimpleGrid columns={2} rows={1}>
          <InlineField>
            <InlineField.Label htmlFor={`output-module-${address}-name`}>
              Name
            </InlineField.Label>
            <InlineField.Input>
              <ReadOnlyInput
                id={`output-module-${address}-name`}
                name={`output-module-${address}-name`}
                className="form-control"
                type="text"
                value={state.name}
                placeholder=""
                maxLength={32}
              />
            </InlineField.Input>
          </InlineField>
          <InlineField>
            <InlineField.Label htmlFor={`output-module-${address}-address`}>
              Address
            </InlineField.Label>
            <InlineField.Input>
              <ReadOnlyInput
                id={`output-module-${address}-address`}
                name={`output-module-${address}-address`}
                className="form-control"
                type="text"
                value={address}
                placeholder=""
                maxLength={32}
              />
            </InlineField.Input>
          </InlineField>
        </SimpleGrid>
      </div>
      <SimpleGrid columns={2} rows={Math.floor(FLOORS_PER_ELEVATOR / 2)}>
        {rangeInclusive(1, FLOORS_PER_ELEVATOR).map((number, index) => {
          const label = `relayNumber${number}-${address}`;

          return (
            <InlineField key={number}>
              <InlineField.Label htmlFor={label}>
                Relay {number}
              </InlineField.Label>
              <InlineField.Input>
                <input
                  id={label}
                  name={label}
                  type="text"
                  onChange={(event) => {
                    dispatch({
                      type: "UPDATED_OUTPUT_NAME",
                      payload: {
                        address,
                        index,
                        value: event.currentTarget.value,
                      },
                    });
                  }}
                  className="form-control"
                  value={state.outputs[index]}
                  maxLength={32}
                />
              </InlineField.Input>
            </InlineField>
          );
        })}
      </SimpleGrid>

      {confirmingDeactivate && (
        <DeleteConfirmModalOverlay>
          <DeleteConfirmModalBody
            header="Deactivate Confirmation"
            actionPending={isRemovingOutputModule}
            pendingText="Removing..."
            onConfirm={() => {
              if (id) {
                removeOutputModule({
                  variables: { outputModule: id },
                  onCompleted: (results) => {
                    setConfirmingDeactivate(false);
                    if ("error" in results) {
                      createNotification({
                        type: "error",
                        text: "Unable to remove output module.",
                      });
                    } else {
                      dispatch({
                        type: "REMOVED_OUTPUT_MODULE",
                        payload: { address },
                      });
                      createNotification({
                        type: "success",
                        text: "Output module was removed.",
                      });
                    }
                  },
                  onError: () => {
                    createNotification({
                      type: "error",
                      text: "Unable to remove output module.",
                    });
                  },
                });
              } else {
                dispatch({
                  type: "REMOVED_OUTPUT_MODULE",
                  payload: { address },
                });
                setConfirmingDeactivate(false);
              }
            }}
            onCancel={() => setConfirmingDeactivate(false)}
          >
            Are you sure you would like to remove this output module?
          </DeleteConfirmModalBody>
        </DeleteConfirmModalOverlay>
      )}
    </Accordion>
  );
};

export type ElevatorOutputModuleState = {
  name: string;
  isOpen: boolean;
  outputs: string[];
};
export type ElevatorState = {
  [key: string]: ElevatorOutputModuleState | null;
  [key: number]: ElevatorOutputModuleState | null;
};
export type ElevatorAction =
  | {
      type: "UPDATED_OUTPUT_NAME";
      payload: {
        address: string | number;
        index: number;
        value: string;
      };
    }
  | {
      type: "ADDED_OUTPUT_MODULE";
    }
  | {
      type: "REMOVED_OUTPUT_MODULE";
      payload: {
        address: string | number;
      };
    };

export const isElevatorAction = (action: any): action is ElevatorAction =>
  [
    "UPDATED_OUTPUT_NAME",
    "ADDED_OUTPUT_MODULE",
    "REMOVED_OUTPUT_MODULE",
  ].includes(action.type);

export const initElevatorOutputModuleState = (
  data: ExistingSiteControlSystemForm_siteControlSystem$data
): ElevatorState => {
  return data.outputModulesConnection.nodes.reduce(
    (acc, outputModule) => {
      return {
        ...acc,
        [outputModule.address]: {
          name: `Elevator Module # ${outputModule.address}`,
          outputs: outputModule.outputsConnection.nodes.reduce(
            (acc, node) =>
              node.relayNumber
                ? R.update(node.relayNumber - 1, node.name, acc)
                : acc,
            new Array(10).fill("")
          ),
          isOpen: false,
        },
      };
    },
    {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
    }
  );
};

const moduleExists = (
  entry: [number | string, ElevatorOutputModuleState | null]
): entry is [number | string, ElevatorOutputModuleState] => !!entry[1];
const entryKeyAsNumber = ([key]: any) => Number(key);

const getNextAddress = (currentList: number[]) => {
  return R.difference([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], currentList)[0];
};

export const elevatorReducer = (
  state: ElevatorState,
  action: ElevatorAction
): ElevatorState => {
  switch (action.type) {
    case "UPDATED_OUTPUT_NAME":
      return R.assocPath(
        [action.payload.address, "outputs", action.payload.index],
        action.payload.value,
        state
      ) as ElevatorState;
    case "ADDED_OUTPUT_MODULE": {
      const next = getNextAddress(
        Object.entries(state).filter(moduleExists).map(entryKeyAsNumber)
      );
      return R.assocPath(
        [next],
        {
          name: `Elevator Module # ${next}`,
          outputs: ["", "", "", "", "", "", "", "", "", ""],
          isOpen: true,
        },
        state
      );
    }
    case "REMOVED_OUTPUT_MODULE": {
      return R.assocPath([action.payload.address], null, state);
    }
    default:
      return state;
  }
};

export function DoorFormFloors({
  state,
  dispatch,
  data,
}: {
  state: ElevatorState;
  dispatch: SiteControlSystemFormDispatch;
  data: ExistingSiteControlSystemForm_siteControlSystem$data;
}) {
  const persistedOutputModulesByAddress = R.indexBy(
    ({ address }) => String(address),
    data.outputModulesConnection.nodes
  );

  const outputModules = Object.entries(state).filter(moduleExists);

  return (
    <>
      <SectionHeaderFull>
        <SectionHeader.Title>
          <span className="mar-r-8">Floors</span>

          <SectionHeader.Add
            onClick={() => {
              if (outputModules.length <= MAX_NUMBER_ELEVATOR_MODULES) {
                dispatch({ type: "ADDED_OUTPUT_MODULE" });
              }
            }}
            disabled={outputModules.length >= MAX_NUMBER_ELEVATOR_MODULES}
          />
        </SectionHeader.Title>
      </SectionHeaderFull>

      {outputModules.map(([address, value]) => (
        <ElevatorOutputModule
          id={persistedOutputModulesByAddress?.[Number(address)]?.id}
          address={address}
          key={address}
          state={value as ElevatorOutputModuleState}
          dispatch={dispatch}
        />
      ))}
    </>
  );
}

export default DoorFormFloors;
