App.controller("ParentDealerCtrl", [
  "$scope",
  "UserService",
  function ($scope, UserService) {
    $scope.UserService = UserService;
    $scope.search = "";
    $scope.itemsPerPage = 25;
    $scope.currentPage = 1;
  },
]);
