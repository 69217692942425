import MassProgrammingGlobalHolidayDates from "components/MassProgrammingGlobalHolidayDates";
import { react2angular } from "react2angular";

App.component(
  "massProgrammingGlobalHolidayDates",
  react2angular(
    MassProgrammingGlobalHolidayDates,
    [],
    ["$stateParams", "RelayService", "$state"]
  )
);
