export const App = angular
  .module("dealeradmin", [
    "ngRoute",
    "ngResource",
    "ngAnimate",
    "ngMessages",
    "ngStorage",
    "ngCookies",
    "ngSanitize",
    "ipCookie",
    "angular.filter",
    "pascalprecht.translate",
    "ngInflection",
    "ngInputModified",
    "ui.bootstrap",
    "ui.router",
    "oc.lazyLoad",
    "toaster",
    "cfp.loadingBar",
    "dcbImgFallback",
    "angular-flot",
    "datatables",
    "datatables.bootstrap",
    "datatables.buttons",
    "ngAside",
    "ui.select",
    "ui.utils",
    "angular-chartist",
    "masonry",
    "checklist-model",
    "angularUtils.directives.dirPagination",
    "angular-directive-percentage",
    "smoothScroll",
    "dndLists",
    "googlechart",
    "ngMap",
    "ngCsv",
    "iso.directives",
    "angular-cron-jobs",
    "angular.vertilize",
    "ngSlimScroll",
    "textAngular",
  ])
  .value("googleChartApiConfig", {
    version: "50",
  });
