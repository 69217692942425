import DaColors from "app/common/DaColors";
import Button from "components/Button";
import { Checkbox } from "components/DaStyledElements";
import Modal from "components/Modal";
import React from "react";

import { defaultTheme } from "react-select";
import styled, { ThemeProvider } from "styled-components/macro";

type LegaleseModalTypes = {
  modalTitle?: string;
  titleColor?: string;
  srcFile: string;
  hasAgreementCheckbox?: boolean;
  hasCloserIcon?: boolean;
  handleCloseModal: () => void;
  handleSubmit: () => void;
  modalOpen?: boolean;
  loginText?: string;
};
export const LegaleseModal = ({
  modalTitle,
  titleColor,
  srcFile,
  hasAgreementCheckbox = false,
  hasCloserIcon = false,
  handleCloseModal,
  handleSubmit,
  modalOpen,
  loginText,
}: LegaleseModalTypes) => {
  const [atBottom, setAtBottom] = React.useState(false);
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  const [isAgreed, setIsAgreed] = React.useState(false);

  // track scrolling if security agreement only
  React.useEffect(() => {
    if (hasAgreementCheckbox) {
      const iframe = iframeRef.current;

      const handleScroll = () => {
        if (iframe) {
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow?.document;
          if (iframeDocument) {
            const scrollTop =
              iframeDocument.documentElement.scrollTop ||
              iframeDocument.body.scrollTop;
            const scrollHeight =
              iframeDocument.documentElement.scrollHeight ||
              iframeDocument.body.scrollHeight;
            const clientHeight =
              iframeDocument.documentElement.clientHeight ||
              iframeDocument.body.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight) {
              setAtBottom(true);

              const iframeWindow = iframe.contentWindow;
              if (iframeWindow) {
                iframeWindow.removeEventListener("scroll", handleScroll, {});
              }
            }
          }
        }
      };
      if (iframe) {
        const iframeWindow = iframe.contentWindow;
        if (iframeWindow) {
          iframeWindow.addEventListener("scroll", handleScroll, {
            passive: true,
          });
        }
      }

      return () => {
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, []);

  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Modal size={"large"}>
        {hasCloserIcon ? (
          <CloserIconButton onClick={() => handleCloseModal()}>
            <span className="icon-close_cancel"></span>
          </CloserIconButton>
        ) : null}
        <StyledModalHeader>
          <TitleText titleColor={titleColor ? `${titleColor}` : ""}>
            <span> {modalTitle}</span>
          </TitleText>
        </StyledModalHeader>
        <Modal.Body>
          <StyledIframe
            id="iframe-bob"
            name="iframe-bob"
            ref={iframeRef}
            src={srcFile}
          ></StyledIframe>
        </Modal.Body>
        {hasAgreementCheckbox ? (
          <Modal.Footer>
            <AgreementInfo>
              <span>By agreeing to the terms of the agreement above, you:</span>
              <StyledUl>
                <StyledListItem>
                  Confirm and acknowledge that you have read and understand the
                  agreement, including the data processing addenda and privacy
                  policy
                </StyledListItem>
                <StyledListItem>
                  Confirm that you are 18 years of age or older
                </StyledListItem>
                <StyledListItem>
                  Accept the agreement and understand you are legally bound by
                  its terms
                </StyledListItem>
              </StyledUl>
            </AgreementInfo>
            <CheckBoxWrapper>
              <Checkbox
                disabled={!atBottom}
                label="I agree to the terms of the agreement above"
                checked={isAgreed}
                onChange={() => handleCheckboxChange()}
                boxSize="3.2rem"
                fontSz="2.4rem"
              />
            </CheckBoxWrapper>
            <ButtonWrapper>
              <TextOnlyButton
                className="mar-r-32"
                onClick={() => handleCloseModal()}
              >
                Cancel
              </TextOnlyButton>
              <SolidButton
                type="button"
                onClick={() => handleSubmit()}
                disabled={!isAgreed}
              >
                {loginText ??  "Log in"}
              </SolidButton>
            </ButtonWrapper>
          </Modal.Footer>
        ) : null}
      </Modal>
    </ThemeProvider>
  );
};

const StyledModal = styled(Modal)`
  position: relative;
`;

const CloserIconButton = styled.button`
  border: none;
  color: var(--color-neutral-700);
  background-color: transparent;
  font-size: 2rem;
  color: ${DaColors.Neutral800};
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`;

const StyledListItem = styled.li`
  text-align: left;
`;

const StyledUl = styled.ul`
  list-style-type: disc !important;
`;

const TitleText = styled.div<{ titleColor: string }>`
  font-size: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  color: () =>
    ${({ titleColor }) => {
      return titleColor ? `${titleColor}` : `${DaColors.Neutral300}`;
    }};
`;

const StyledIframe = styled.iframe`
  height: 40vh;
  width: 100%;
`;

const AgreementInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  font-size: 1.8rem;
`;

const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.6rem 0;
`;

const TextOnlyButton = styled(Button)`
  border: none;
  background: transparent;
  color: ${DaColors.Primary600};
  font-size: 2.4rem;
  font-weight: bold;
  &:hover {
    color: ${DaColors.Primary700};
    background: transparent;
  }
`;

const SolidButton = styled(Button)`
  background: ${DaColors.Primary500};
  color: white;
  font-size: 2.4rem;
  width: 24rem;
  font-weight: bold;

  &:visited {
    background: ${DaColors.Primary700};
    color: white;
    text-decoration: none;
  }

  &:hover {
    background: ${DaColors.Primary700};
    color: white;
    text-decoration: none;
  }

  &:active {
    background: ${DaColors.Primary700};
    color: white;
    text-decoration: none;
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  width: 100%;
`;
