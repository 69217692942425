import React from "react";
import BaseGroupForm from "./BaseGroupForm";
import { GroupFormState } from "./reducer";

type Props = {
  customerId: string;
  controlSystemId: string;
};

const GroupNew = ({ customerId, controlSystemId }: Props) => {
  const initialFormState: GroupFormState = {
    name: "",
    doors: [],
    areas: [],
    schedules: [],
    lockdown: false,
    armAuthority: false,
    disarmAuthority: false,
    swipeTwiceToArm: false,
    schedulesEnabled: false,
    userProgramming: false,
    groupsProgramming: false,
    auditDaysEnabled: false,
    auditDaysValue: "",
    persisted: false,
    validationErrors: [],
    swipeTwiceDisabled: true,
    rawAreas: [],
    rawDoors: [],
  };

  return (
    <BaseGroupForm
      customerId={customerId}
      controlSystemId={controlSystemId}
      initialFormState={initialFormState}
      isNew={true}
    />
  );
};

export default GroupNew;
