/**
 * @generated SignedSource<<9fc0e1b38826d822554b6d873ccb6bd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsXrX1CommunicationField_networkOptions$data = {
  readonly enableDdp: boolean;
  readonly " $fragmentType": "NetworkOptionsXrX1CommunicationField_networkOptions";
};
export type NetworkOptionsXrX1CommunicationField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsXrX1CommunicationField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsXrX1CommunicationField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsXrX1CommunicationField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableDdp",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "1d72f160460161e5a24c5d0bb9e1a20b";

export default node;
