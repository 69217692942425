import { GroupFormState, GroupValidationError } from "./reducer";

export const createSequentialArray = (length: number) => {
  return Array.from({ length: length }, (_, index) => index + 1);
};

const validator = (formState: GroupFormState) => {
  const newValidationErrors: GroupValidationError[] = [];

  if (!formState.number) {
    newValidationErrors.push({
      fieldName: "number",
      errorMessages: ["This field is required."],
    });
  } else {
    if (!createSequentialArray(10).includes(Number(formState.number))) {
      newValidationErrors.push({
        fieldName: "number",
        errorMessages: [
          `Must be a number between ${
            createSequentialArray(10)[0]
          } and ${createSequentialArray(10).at(-1)}`,
        ],
      });
    }
  }

  if (!formState.name) {
    newValidationErrors.push({
      fieldName: "name",
      errorMessages: ["This field is required."],
    });
  }

  if (formState.auditDaysEnabled) {
    if (isNaN(Number(formState.auditDaysValue))) {
      newValidationErrors.push({
        fieldName: "auditDay",
        errorMessages: ["Must be number."],
      });
    } else if (
      Number(formState.auditDaysValue) > 425 ||
      Number(formState.auditDaysValue) < 0
    ) {
      newValidationErrors.push({
        fieldName: "auditDay",
        errorMessages: ["Must be 0-425."],
      });
    }
  }

  return { ...formState, validationErrors: newValidationErrors };
};

export default validator;
