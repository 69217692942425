import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { resolveOutputNumbers } from "../../OutputInformationFields/utils";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "../../OutputOptionsFields/utils";
import { useHardwareModel } from "../../PanelContext";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useHouseCode } from "../../SystemOptionsFields/SystemOptionsContext";
import TextInput from "../../TextInput";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationBurglaryBellOutputNumberField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationBurglaryBellOutputNumberField_systemAreaInformation.graphql";

export const systemAreaInformationBurglaryBellOutputNumberFieldId = () =>
  `system-area-information-burglary-bell-output-number-field`;

function SystemAreaInformationBurglaryBellOutputNumberField() {
  const [{ burglaryBellOutputNumber }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationBurglaryBellOutputNumberField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationBurglaryBellOutputNumberField_systemAreaInformation on SystemAreaInformation {
          burglaryBellOutputNumber
        }
      `
    );

  const fieldId = systemAreaInformationBurglaryBellOutputNumberFieldId();
  const hardwareModel = useHardwareModel();
  const houseCode = useHouseCode();

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const { current: originalValue } = React.useRef(burglaryBellOutputNumber);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Burglary Bell Output"
      tooltip="Turn on the output any time a Burglary Zone is placed in alarm. Turn off when any area is disarmed and no other Burglary Zones are in alarm."
    >
      <TextInput
        id={fieldId}
        value={burglaryBellOutputNumber}
        required
        maxLength={3}
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(target.value, "burglaryBellOutputNumber");
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                originalValue,
                hardwareModel,
                validNumericalRange
              ),
              "burglaryBellOutputNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationBurglaryBellOutputNumberField;
