import React from "react";

const AttentionWarning = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_373_793)">
      <path
        d="M8.48017 1.28L7.04017 1.44C4.85351 5.38667 0.448173 13.344 0.320173 13.6C0.192173 13.856 0.800173 14.88 1.12017 15.36H14.8802L15.3602 12.64L8.48017 1.28Z"
        fill="#FED51B"
      />
      <path
        d="M15.5482 12.0485L10.1739 2.1135C9.63269 1.11 8.86069 0.557251 8.00019 0.557251C7.13919 0.557251 6.36619 1.11025 5.82369 2.11425L0.453193 12.0515C-0.0808074 13.0395 -0.145808 14.0085 0.270943 14.7805C0.695943 15.567 1.53294 16 2.62819 16L13.3719 15.9983C14.4659 15.9983 15.3037 15.5648 15.7312 14.7758C16.1457 14.0048 16.0807 13.0365 15.5482 12.0485ZM14.8512 14.3008C14.6074 14.7505 14.0822 14.9983 13.3719 14.9983L2.62819 15C1.91769 15 1.39294 14.7533 1.15069 14.3055C0.899693 13.8405 0.964442 13.209 1.33294 12.5273L6.70369 2.59C7.06319 1.92425 7.52394 1.5575 8.00044 1.5575C8.47594 1.5575 8.93544 1.9235 9.29419 2.58875L14.6684 12.5238C15.0359 13.2053 15.1004 13.837 14.8512 14.3008Z"
        fill="black"
      />
      <path d="M8.5 11.75H7.5V13H8.5V11.75Z" fill="black" />
      <path d="M8.5 5.75H7.5V10.25H8.5V5.75Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_373_793">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AttentionWarning;
