import graphql from "babel-plugin-relay/macro";
import { noop } from "common/utils";
import FormGridItems from "components/FormGridItems";
import * as React from "react";
import { useFragment, useQueryLoader } from "react-relay/hooks";
import { mapCommTypeToLabel } from "utils/control-systems";
import Sections, { SectionHeader } from "../Layout/Layout";
import { ExistingSiteControlSystemModal } from "./ExistingSiteControlSystemForm";
import FirmwareUpdateBadge from "./FirmwareUpdateBadge";
import NewSiteControlSystemElevatorForm from "./NewSiteControlSystemElevatorForm";
import { SiteControlSystemJobStatusBadgeWithAutoRefresh } from "./SiteControlSystemJobStatusBadge";
import { SiteControlSystemOnlineStatusBadge } from "./SiteControlSystemOnlineStatusBadge";
import existingControlSystemQuery, {
  ExistingSiteControlSystemFormQuery,
  ExistingSiteControlSystemFormQuery$variables,
} from "./__generated__/ExistingSiteControlSystemFormQuery.graphql";
import { SiteElevatorSystemsSection_site$key } from "./__generated__/SiteElevatorSystemsSection_site.graphql";
import { SiteElevatorSystemsSection_siteControlSystem$key } from "./__generated__/SiteElevatorSystemsSection_siteControlSystem.graphql";

function ElevatorListItem({
  siteElevatorSystemRef,
  loadQuery,
  setInitialSelectedDoor,
}: {
  siteElevatorSystemRef: SiteElevatorSystemsSection_siteControlSystem$key;
  loadQuery: (
    variables: ExistingSiteControlSystemFormQuery$variables,
    options?: { fetchPolicy?: any }
  ) => void;
  setInitialSelectedDoor: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const data = useFragment(
    graphql`
      fragment SiteElevatorSystemsSection_siteControlSystem on SiteControlSystem {
        id
        serialNumber
        type
        commType
        name
        hasPendingChanges
        online
        doors(includeUnprogrammedDoors: true) {
          id
          name
        }
        ...FirmwareUpdateBadge_siteControlSystem
        ...SiteControlSystemJobStatusBadge_siteControlSystem
        ...SiteControlSystemOnlineStatusBadge_siteControlSystem
      }
    `,
    siteElevatorSystemRef
  );

  return (
    <FormGridItems.Item x1PreProgram={!data.online}>
      <FormGridItems.ClickableContent
        onClick={() => {
          data.hasPendingChanges
            ? noop()
            : loadQuery(
                {
                  siteControlSystemId: data.id,
                },
                { fetchPolicy: "network-only" }
              );
        }}
      >
        <FormGridItems.ItemTitle>
          <SiteControlSystemOnlineStatusBadge siteControlSystem={data} />
          SN: {data.serialNumber}
        </FormGridItems.ItemTitle>

        <FormGridItems.ItemSubtitle>
          {mapCommTypeToLabel(data.commType)}
        </FormGridItems.ItemSubtitle>
        <React.Suspense fallback={null}>
          <SiteControlSystemJobStatusBadgeWithAutoRefresh
            siteControlSystem={data}
          />
          <FirmwareUpdateBadge controlSystem={data} />
        </React.Suspense>
      </FormGridItems.ClickableContent>

      <FormGridItems.ClickableContent
        onClick={(event) => {
          event.stopPropagation();
          setInitialSelectedDoor(data.doors?.[0]?.id);
          data.hasPendingChanges
            ? noop()
            : loadQuery(
                {
                  siteControlSystemId: data.id,
                },
                { fetchPolicy: "network-only" }
              );
        }}
      >
        <FormGridItems.ItemTitle>
          {data.doors?.[0]?.name || `Unnamed Elevator`}
        </FormGridItems.ItemTitle>
      </FormGridItems.ClickableContent>
    </FormGridItems.Item>
  );
}

function SiteElevatorSystemsSection({
  siteRef,
  initiallyOpenFirstElevator,
}: {
  siteRef: SiteElevatorSystemsSection_site$key;
  initiallyOpenFirstElevator: boolean;
}) {
  const data = useFragment(
    graphql`
      fragment SiteElevatorSystemsSection_site on Site {
        totalControlSystems
        doorControlSystems: controlSystems(include: [DOOR_ACCESS]) {
          firmwareStatus
          firmwareUpdateProgress
        }
        elevatorControlSystems: controlSystems(include: [ELEVATOR]) {
          id
          serialNumber
          type
          commType
          doors(includeUnprogrammedDoors: true) {
            id
            name
          }
          firmwareStatus
          firmwareUpdateProgress
          ...SiteElevatorSystemsSection_siteControlSystem
          ...SiteControlSystemsSection_siteControlSystem
        }
        ...NewSiteControlSystemElevatorForm_site
      }
    `,
    siteRef
  );

  const [
    existingControlSystemQueryReference,
    loadExistingControlSystemQuery,
    disposeExistingControlSystemQuery,
  ] = useQueryLoader<ExistingSiteControlSystemFormQuery>(
    existingControlSystemQuery
  );

  const firstSystem = data.elevatorControlSystems?.[0];
  const [addingElevator, setAddingElevator] = React.useState(false);
  const [initialSelectedDoor, setInitialSelectedDoor] = React.useState<
    string | null
  >((initiallyOpenFirstElevator && firstSystem?.doors?.[0]?.id) || null);

  const selectedControlSystem = React.useMemo(
    () =>
      existingControlSystemQueryReference &&
      (data.elevatorControlSystems.find(
        ({ id }) =>
          id ===
          existingControlSystemQueryReference.variables?.siteControlSystemId
      ) ??
        null),
    [data.elevatorControlSystems, existingControlSystemQueryReference]
  );

  const siteHasMoreThanOneDoor = data.totalControlSystems > 1;

  const siteHasFirmwareUpdatesInProgress =
    data.elevatorControlSystems || data.doorControlSystems
      ? [...data.elevatorControlSystems, ...data.doorControlSystems].some(
          ({ firmwareStatus }) => firmwareStatus === "UPDATES_IN_PROGRESS"
        )
      : false;

  function closeModal() {
    setAddingElevator(false);
    setInitialSelectedDoor(null);
    disposeExistingControlSystemQuery();
  }

  return (
    <>
      <Sections.Section>
        <SectionHeader>
          <SectionHeader.Title>Elevators</SectionHeader.Title>
          <SectionHeader.Add
            onClick={() => {
              setAddingElevator(true);
            }}
          />
        </SectionHeader>
        <FormGridItems>
          {data.elevatorControlSystems ? (
            data.elevatorControlSystems.map((system) => {
              return (
                <ElevatorListItem
                  key={system.id}
                  siteElevatorSystemRef={system}
                  loadQuery={loadExistingControlSystemQuery}
                  setInitialSelectedDoor={setInitialSelectedDoor}
                />
              );
            })
          ) : (
            <span>no elevators</span>
          )}
        </FormGridItems>
      </Sections.Section>

      {addingElevator && (
        <NewSiteControlSystemElevatorForm
          siteRef={data}
          onCancel={closeModal}
          onSaved={(siteControlSystemId, firstDoorId) => {
            setAddingElevator(false);
            setInitialSelectedDoor(firstDoorId);
            loadExistingControlSystemQuery({ siteControlSystemId });
          }}
        />
      )}

      {!!(existingControlSystemQueryReference && selectedControlSystem) && (
        <ExistingSiteControlSystemModal
          siteHasFirmwareUpdatesInProgress={siteHasFirmwareUpdatesInProgress}
          firmwareStatus={selectedControlSystem.firmwareStatus}
          firmwareUpdateProgress={selectedControlSystem.firmwareUpdateProgress}
          initialSelectedDoor={initialSelectedDoor}
          queryReference={existingControlSystemQueryReference}
          onSaved={closeModal}
          onDeleted={closeModal}
          onCancel={closeModal}
          canDelete={siteHasMoreThanOneDoor}
          key={
            existingControlSystemQueryReference.variables.siteControlSystemId
          }
        />
      )}
    </>
  );
}

export default SiteElevatorSystemsSection;
