/**
 * @generated SignedSource<<58e34c3b9079e6497d6ca2b9d08e5650>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelFirmwareStatus = "UPDATES_AVAILABLE" | "UPDATES_FAILED" | "UPDATES_IN_PROGRESS" | "UPDATES_SUCCESSFUL" | "UP_TO_DATE";
import { FragmentRefs } from "relay-runtime";
export type FirmwareUpdateBadge_siteControlSystem$data = {
  readonly firmwareStatus: PanelFirmwareStatus;
  readonly firmwareUpdateDismissed: boolean | null;
  readonly firmwareUpdateProgress: number | null;
  readonly site: {
    readonly controlSystems: ReadonlyArray<{
      readonly firmwareStatus: PanelFirmwareStatus;
      readonly firmwareUpdateProgress: number | null;
    }>;
    readonly firmwareUpdateDismissed: boolean | null;
  } | null;
  readonly " $fragmentType": "FirmwareUpdateBadge_siteControlSystem";
};
export type FirmwareUpdateBadge_siteControlSystem$key = {
  readonly " $data"?: FirmwareUpdateBadge_siteControlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirmwareUpdateBadge_siteControlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firmwareStatus",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firmwareUpdateProgress",
  "storageKey": null
},
v2 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firmwareUpdateDismissed",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirmwareUpdateBadge_siteControlSystem",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteControlSystem",
          "kind": "LinkedField",
          "name": "controlSystems",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "0f7f997bb5f22b926f2eeb21abee94bd";

export default node;
