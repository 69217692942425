import { Dispatch } from "react";

export type ReceiverFormState = {
  readonly id: string | null;
  readonly automationPrefix: string;
  readonly number: number;
  readonly ipAddress: string;
  readonly port: number | null;
  readonly description: string;
  readonly centralStationAutomationIntegrationId: string | null;
  readonly automationPrefixBlurred: boolean;
  readonly numberBlurred: boolean;
  readonly ipAddressBlurred: boolean;
  readonly portBlurred: boolean;
  readonly cancelButtonClicked: boolean;
};

type Action =
  | { type: "AUTOMATION_PREFIX_CHANGED"; value: string }
  | { type: "NUMBER_CHANGED"; value: number }
  | { type: "IP_CHANGED"; value: string }
  | { type: "PORT_CHANGED"; value: number }
  | { type: "DESCRIPTION_CHANGED"; value: string }
  | {
      type: "CENTRAL_STATION_AUTOMATION_INTEGRATION_CHANGED";
      value: string | null;
    }
  | { type: "AUTOMATION_PREFIX_BLURRED" }
  | { type: "NUMBER_BLURRED" }
  | { type: "IP_BLURRED" }
  | { type: "PORT_BLURRED" }
  | { type: "CANCEL_BUTTON_CLICKED" };

export type ReceiverFormDispatch = Dispatch<Action>;

export const reducer = (
  state: ReceiverFormState,
  action: Action
): ReceiverFormState => {
  switch (action.type) {
    case "AUTOMATION_PREFIX_CHANGED":
      return {
        ...state,
        automationPrefix: action.value,
        automationPrefixBlurred: false,
      };
    case "NUMBER_CHANGED":
      return {
        ...state,
        number: Number(action.value.toString().substring(0, 2)),
        numberBlurred: false,
      };
    case "IP_CHANGED":
      return { ...state, ipAddress: action.value.substring(0, 15) };
    case "PORT_CHANGED":
      return {
        ...state,
        port: Number(action.value.toString().substring(0, 5)),
        portBlurred: false,
      };
    case "DESCRIPTION_CHANGED":
      return { ...state, description: action.value };
    case "CENTRAL_STATION_AUTOMATION_INTEGRATION_CHANGED":
      return { ...state, centralStationAutomationIntegrationId: action.value };
    case "AUTOMATION_PREFIX_BLURRED":
      return { ...state, automationPrefixBlurred: true };
    case "NUMBER_BLURRED":
      return { ...state, numberBlurred: true };
    case "IP_BLURRED":
      return { ...state, ipAddressBlurred: true };
    case "PORT_BLURRED":
      return { ...state, portBlurred: true };
    case "CANCEL_BUTTON_CLICKED":
      return { ...state, cancelButtonClicked: true };
    default:
      return state;
  }
};
