import graphql from "babel-plugin-relay/macro";
import { useResetControlSystemIntegrations } from "components/StatusBadge";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { IntegrationForm } from "./Form";
import { reducer } from "./IntegrationFormReducer";
import { NewIntegrationFormAddIntegrationMutation } from "./__generated__/NewIntegrationFormAddIntegrationMutation.graphql";
import { NewIntegrationForm_Query$key } from "./__generated__/NewIntegrationForm_Query.graphql";

const addIntegrationMutation = graphql`
  mutation NewIntegrationFormAddIntegrationMutation(
    $dealer: ID!
    $integration: AddDealerCentralStationAutomationIntegrationInput!
  ) {
    addDealerCentralStationAutomationIntegration(
      dealer: $dealer
      integration: $integration
    ) {
      __typename
      ... on AddDealerCentralStationAutomationIntegrationErrorResponse {
        error {
          ... on Error {
            errorType: type
          }
        }
      }
      ... on AddDealerCentralStationAutomationIntegrationSuccessResponse {
        dealer {
          id
          centralStationAutomationIntegrations {
            id
            username
            name
            displayedName
            status
            emergencyContactsEnabled
            systemTestsEnabled
            cmsDealerNumber
          }
        }
      }
    }
  }
`;

export const NewIntegrationForm: React.FC<
  React.PropsWithChildren<{
    onCancel: () => void;
    queryRef: NewIntegrationForm_Query$key;
  }>
> = ({ onCancel, queryRef }) => {
  const data = useFragment(
    graphql`
      fragment NewIntegrationForm_Query on Query {
        ...FormIntegrationBaseFragment
        dealer(id: $dealerId) {
          id
        }
      }
    `,
    queryRef
  );

  const showAlert = useShowAlert();
  const [saveNewIntegration, isSavingNewIntegration] =
    useMutation<NewIntegrationFormAddIntegrationMutation>(
      addIntegrationMutation
    );
  const [state, dispatch] = React.useReducer(reducer, {
    id: null,
    centralStationName: "",
    username: "",
    password: "",
    emergencyContactsEnabled: true,
    systemTestsEnabled: true,
    usernameBlurred: false,
    passwordBlurred: false,
    centralStationCode: "",
    cmsDealerNumber: "",
  });
  const resetControlSystemIntegrations = useResetControlSystemIntegrations();

  return (
    <IntegrationForm
      isSavingIntegration={isSavingNewIntegration}
      baseFormDataFragment={data}
      state={state}
      dispatch={dispatch}
      onCancel={onCancel}
      onSave={() => {
        if (state.centralStationName && state.username && state.password) {
          saveNewIntegration({
            variables: {
              dealer: data.dealer?.id ?? "",
              integration: {
                centralStationName:
                  state.centralStationName === "other"
                    ? state.centralStationCode
                    : state.centralStationName,
                username: state.username,
                password: state.password,
                emergencyContactsEnabled: state.emergencyContactsEnabled,
                systemTestsEnabled: state.systemTestsEnabled,
                cmsDealerNumber: state.cmsDealerNumber,
              },
            },
            onCompleted: ({ addDealerCentralStationAutomationIntegration }) => {
              if ("dealer" in addDealerCentralStationAutomationIntegration) {
                showAlert({
                  type: "success",
                  text: "Created Integration",
                });
                onCancel();
                resetControlSystemIntegrations();

                return;
              }
              if (
                "error" in addDealerCentralStationAutomationIntegration &&
                addDealerCentralStationAutomationIntegration.error
                  ?.errorType === "INVALID_CENTRAL_STATION_CODE"
              ) {
                showAlert({
                  type: "error",
                  text: `Monitoring Center code is incorrect.`,
                });
                return;
              }
              if (
                "error" in addDealerCentralStationAutomationIntegration &&
                addDealerCentralStationAutomationIntegration.error
                  ?.errorType === "INVALID_CREDENTIALS"
              ) {
                showAlert({
                  type: "error",
                  text: `Unable to connect to  ${
                    state.centralStationName === "other"
                      ? state.centralStationCode
                      : state.centralStationName
                  }. Username or Password is incorrect. Re-enter the username/password and try again.`,
                });
                return;
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to add Monitoring Center. Ensure credentials contain API permissions and try again.",
                });
              }
            },
          });
        }
      }}
    />
  );
};
