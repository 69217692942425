import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { commPathCellApn2FieldId } from "components/FullProgramming/common/CommPathFields/CommPathCellApn2Field";
import { commPathCellApnFieldId } from "components/FullProgramming/common/CommPathFields/CommPathCellApnField";
import { commPathCheckInMinutesFieldId } from "components/FullProgramming/common/CommPathFields/CommPathCheckInMinutesField";
import { commPathCommTypeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathCommTypeField";
import { commPathDuplicateAlarmsFieldId } from "components/FullProgramming/common/CommPathFields/CommPathDuplicateAlarmsField";
import { commPathFailTestHoursFieldId } from "components/FullProgramming/common/CommPathFields/CommPathFailTestHoursField";
import { commPathFailTimeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathFailTimeField";
import { commPathFirstPhoneNumberFieldId } from "components/FullProgramming/common/CommPathFields/CommPathFirstPhoneNumberField";
import {
  commPathListItemTemplateId,
  commPathNumberFieldId,
} from "components/FullProgramming/common/CommPathFields/CommPathNumberField";
import { commPathProtocolFieldId } from "components/FullProgramming/common/CommPathFields/CommPathProtocolField";
import { commPathReceiverIpAddressFieldId } from "components/FullProgramming/common/CommPathFields/CommPathReceiverIpAddressField";
import { commPathReceiverPortFieldId } from "components/FullProgramming/common/CommPathFields/CommPathReceiverPortField";
import { commPathRetryTimeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathRetryTimeField";
import { commPathSecondPhoneNumberFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSecondPhoneNumberField";
import { commPathSendPathFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSendPathField";
import { commPathTestReportFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTestReportField";
import { commPathTestTimeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTestTimeField";
import { commPathTypeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTypeField";
import { commPathUseCheckInFieldId } from "components/FullProgramming/common/CommPathFields/CommPathUseCheckInField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XFCommunicationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XFFullProgramming/XFCommunicationProgrammingConceptForm/__generated__/XFCommunicationProgrammingConceptFormInline_controlSystem.graphql";
import * as React from "react";
import { readInlineData } from "react-relay";
import {
  getCommunicationPathState,
  getState as getCommunicationState,
} from "../../../XFFullProgramming/XFCommunicationProgrammingConceptForm";
import { XfProgrammingTemplateCommunicationPathInput } from "../Create/__generated__/XFTemplateSaveMutation.graphql";
import { XFCommunicationPathTemplateDataInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFCommunicationPathTemplateDataInline_xfProgrammingTemplateConcepts.graphql";

export const getState = (
  communicationPath: XFCommunicationPathTemplateDataInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFCommunicationPathTemplateDataInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        communication {
          id
          included
          number
          commType {
            included
            data
          }
          type {
            included
            data
          }
          testReport {
            included
            data
          }
          testTime {
            included
            data
          }
          firstPhone {
            included
            data
          }
          secondPhone {
            included
            data
          }
          sendPath {
            included
            data
          }
          useCheckIn {
            included
            data
          }
          checkInMinutes {
            included
            data
          }
          failTime {
            included
            data
          }
          remoteIP {
            included
            data
          }
          receiverPort {
            included
            data
          }
          protocol {
            included
            data
          }
          retryTime {
            included
            data
          }
          apn {
            included
            data
          }
          apn2 {
            included
            data
          }
          duplicateAlarms {
            included
            data
          }
          failTestHours {
            included
            data
          }
        }
      }
    `,
    communicationPath
  );

export const getInput = (
  controlSystem: XFCommunicationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XfProgrammingTemplateCommunicationPathInput[] => {
  const {
    panel: { communicationPaths },
  } = getCommunicationState(controlSystem);

  return communicationPaths
    ? communicationPaths
        .filter(isNotNullOrUndefined)
        .map(getCommunicationPathState)
        .map((communicationPath) => ({
          included: includedFields.has(
            commPathListItemTemplateId(String(communicationPath.number))
          ),
          number: communicationPath.number,
          commType: {
            included: includedFields.has(
              commPathCommTypeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.commType,
          },
          type: {
            included: includedFields.has(
              commPathTypeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.type,
          },
          testReport: {
            included: includedFields.has(
              commPathTestReportFieldId(String(communicationPath.number))
            ),
            data: communicationPath.testReport,
          },
          testTime: {
            included: includedFields.has(
              commPathTestTimeFieldId(String(communicationPath.number))
            ),
            data: String(communicationPath.testTime).trim(),
          },
          firstPhone: {
            included: includedFields.has(
              commPathFirstPhoneNumberFieldId(String(communicationPath.number))
            ),
            data: communicationPath.firstPhone,
          },
          secondPhone: {
            included: includedFields.has(
              commPathSecondPhoneNumberFieldId(String(communicationPath.number))
            ),
            data: communicationPath.secondPhone,
          },
          sendPath: {
            included: includedFields.has(
              commPathSendPathFieldId(String(communicationPath.number))
            ),
            data: communicationPath.sendPath,
          },
          useCheckIn: {
            included: includedFields.has(
              commPathUseCheckInFieldId(String(communicationPath.number))
            ),
            data: communicationPath.useCheckIn,
          },
          checkInMinutes: {
            included: includedFields.has(
              commPathCheckInMinutesFieldId(String(communicationPath.number))
            ),
            data: communicationPath.checkInMinutes,
          },
          failTime: {
            included: includedFields.has(
              commPathFailTimeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.failTime,
          },
          remoteIP: {
            included: includedFields.has(
              commPathReceiverIpAddressFieldId(String(communicationPath.number))
            ),
            data: communicationPath.remoteIP,
          },
          receiverPort: {
            included: includedFields.has(
              commPathReceiverPortFieldId(String(communicationPath.number))
            ),
            data: communicationPath.receiverPort,
          },
          protocol: {
            included: includedFields.has(
              commPathProtocolFieldId(String(communicationPath.number))
            ),
            data: communicationPath.protocol,
          },
          retryTime: {
            included: includedFields.has(
              commPathRetryTimeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.retryTime,
          },
          apn: {
            included: includedFields.has(
              commPathCellApnFieldId(String(communicationPath.number))
            ),
            data: communicationPath.apn,
          },
          apn2: {
            included: includedFields.has(
              commPathCellApn2FieldId(String(communicationPath.number))
            ),
            data: communicationPath.apn2,
          },
          duplicateAlarms: {
            included: includedFields.has(
              commPathDuplicateAlarmsFieldId(String(communicationPath.number))
            ),
            data: communicationPath.duplicateAlarms,
          },
          failTestHours: {
            included: includedFields.has(
              commPathFailTestHoursFieldId(String(communicationPath.number))
            ),
            data: communicationPath.failTestHours,
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XFCommunicationPathTemplateDataInline_xfProgrammingTemplateConcepts$key,
  setIncludedFields: React.Dispatch<
    React.SetStateAction<IncludedTemplateFieldsType>
  >
) => {
  const { communication } = getState(template);

  if (!communication) {
    return;
  }

  communication.filter(isNotNullOrUndefined).forEach((commPath, index) => {
    const number = String(commPath.number);
    if (commPath.included) {
      setIncludedFields(
        setToggle(commPathListItemTemplateId(String(commPath.number)))
      );
      setIncludedFields(setToggle(commPathNumberFieldId(number)));
    }
    if (commPath.commType?.included) {
      setIncludedFields(setToggle(commPathCommTypeFieldId(number)));
    }
    if (commPath.type?.included) {
      setIncludedFields(setToggle(commPathTypeFieldId(number)));
    }
    if (commPath.testReport?.included) {
      setIncludedFields(setToggle(commPathTestReportFieldId(number)));
    }
    if (commPath.testTime?.included) {
      setIncludedFields(setToggle(commPathTestTimeFieldId(number)));
    }
    if (commPath.firstPhone?.included) {
      setIncludedFields(setToggle(commPathFirstPhoneNumberFieldId(number)));
    }
    if (commPath.secondPhone?.included) {
      setIncludedFields(setToggle(commPathSecondPhoneNumberFieldId(number)));
    }
    if (commPath.sendPath?.included) {
      setIncludedFields(setToggle(commPathSendPathFieldId(number)));
    }
    if (commPath.useCheckIn?.included) {
      setIncludedFields(setToggle(commPathUseCheckInFieldId(number)));
    }
    if (commPath.checkInMinutes?.included) {
      setIncludedFields(setToggle(commPathCheckInMinutesFieldId(number)));
    }
    if (commPath.failTime?.included) {
      setIncludedFields(setToggle(commPathFailTimeFieldId(number)));
    }
    if (commPath.remoteIP?.included) {
      setIncludedFields(setToggle(commPathReceiverIpAddressFieldId(number)));
    }
    if (commPath.receiverPort?.included) {
      setIncludedFields(setToggle(commPathReceiverPortFieldId(number)));
    }
    if (commPath.protocol?.included) {
      setIncludedFields(setToggle(commPathProtocolFieldId(number)));
    }
    if (commPath.retryTime?.included) {
      setIncludedFields(setToggle(commPathRetryTimeFieldId(number)));
    }
    if (commPath.apn?.included) {
      setIncludedFields(setToggle(commPathCellApnFieldId(number)));
    }
    if (commPath.apn2?.included) {
      setIncludedFields(setToggle(commPathCellApn2FieldId(number)));
    }
    if (commPath.duplicateAlarms?.included) {
      setIncludedFields(setToggle(commPathDuplicateAlarmsFieldId(number)));
    }
    if (commPath.failTestHours?.included) {
      setIncludedFields(setToggle(commPathFailTestHoursFieldId(number)));
    }
  });
};
