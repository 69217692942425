/**
 * App.service.PersonUsersService
 *
 * Service for PersonUsers.
 *
 * PersonUsers are objects comprised of users with their person information attached to them
 */
App.service("PersonUsersService", [
  "$q",
  "PersonsAPI",
  "DealerODataAPI",
  "OdataPageService",
  "UserService",
  function ($q, PersonsAPI, DealerODataAPI, OdataPageService, UserService) {
    // Public accessors
    this.searchAll = searchAll;
    this.getByUserId = getByUserId;
    this.getByCustomerId = getByCustomerId;
    this.getByPersonId = getByPersonId;
    this.getPersonnel = getPersonnel;
    this.convertToSCAPIPerson = convertToSCAPIPerson;

    // Private functions and variables
    /**
     * A list of parameters of the
     * @type {string[]}
     */
    var searchFields = [
      "person_email_address",
      "person_first_name",
      "person_last_name",
    ];

    /**
     * Get all PersonUsers for the current (UserService) dealer
     * @param {string} searchText - Search text to filter results on
     * @returns {Promise} - success includes an array of PersonUsers
     */
    function searchAll(searchText) {
      var params = { $filter: "(" };
      angular.forEach(searchFields, function (field, idx) {
        params.$filter += "contains(" + field + ",'" + searchText + "')";
        if (idx < searchFields.length - 1) {
          params.$filter += " or ";
        }
      });
      params.$filter += ")";
      return getPersonUsers(params);
    }

    /**
     * Get a specified personUser
     * @param userId - The id of the user for which you'd like the person and other users for the person
     * @returns {Promise} - success includes the PersonUsers of the specified user_id
     */
    function getByUserId(userId) {
      if (+userId > 0) {
        var deferred = $q.defer();
        getPersonUsers({ $filter: "user_id eq " + userId })
          .then(
            function (data) {
              deferred.resolve(data[0]);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      } else {
        // Reject immediately
        return $q.reject();
      }
    }

    /**
     * Get all PersonUsers for the specified customer
     * @param customerId - The id of the user for which you'd like the person and other users for the person
     * @returns {Promise} - success includes an array of PersonUsers of the specified customer
     */
    function getByCustomerId(customerId) {
      if (+customerId > 0) {
        return getPersonUsers({ $filter: "customer_id eq " + customerId });
      } else {
        // Reject immediately
        return $q.reject();
      }
    }

    /**
     * Get all PersonUsers for the specified person
     * @param personId - The id of the person
     * @returns {Promise} - success includes an array of PersonUsers of the specified person
     */
    function getByPersonId(personId) {
      if (+personId > 0) {
        return getPersonUsers({ $filter: "person_id eq " + personId });
      } else {
        // Reject immediately
        return $q.reject();
      }
    }

    /**
     * Get a collection of user-person objects
     * @param {{}} [additionalParams] - extra parameters to send with the route
     * @returns {Promise} - success includes an array of PersonUsers of the logged in user's dealer
     */
    function getPersonUsers(additionalParams) {
      var deferred = $q.defer();
      var oDataPageService = new OdataPageService();
      var params = {
        dealer_id: UserService.dealer_id,
      };
      if (additionalParams) {
        angular.forEach(Object.keys(additionalParams), function (field) {
          params[field] = additionalParams[field];
        });
      }
      oDataPageService
        .getAllPages(DealerODataAPI.getPersonUsers(params).$promise)
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "PersonUsersService->getPersonUsers() error: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function getPersonnel() {
      var deferred = $q.defer();
      var oDataPageService = new OdataPageService();
      var params = {
        dealer_id: UserService.dealer_id,
        $filter: "user_accessible_type eq 'Dealer'",
      };
      oDataPageService
        .getAllPages(DealerODataAPI.getPersonUsers(params).$promise)
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "PersonUsersService->getPersonUsers() error: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * Function to convert a personUser to a SCAPI person
     * @param personUser
     */
    function convertToSCAPIPerson(personUser) {
      return {
        id: personUser.person_id,
        first_name: personUser.person_first_name,
        last_name: personUser.person_last_name,
        email_address: personUser.person_email_address,
      };
    }
  },
]);
