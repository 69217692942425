enum DealerVideoAccessType {
  Denied = "denied",
  Perpetual = "perpetual",
  Temporary = "temporary",
}

const ALLOWED_TYPES = new Set([
  DealerVideoAccessType.Perpetual,
  DealerVideoAccessType.Temporary,
]);

interface RawDealerVideoAccess {
  access_type: DealerVideoAccessType;
  end_time?: number;
  start_time?: number;
}

type DealerVideoAccess = {
  endTimeMs?: number;
  isAllowed: boolean;
};

export const parseDealerVideoAccess = (
  rawDealerVideoAccess: RawDealerVideoAccess
): DealerVideoAccess => {
  return {
    endTimeMs: rawDealerVideoAccess.end_time,
    isAllowed: ALLOWED_TYPES.has(rawDealerVideoAccess.access_type),
  };
};
