/**
 * @generated SignedSource<<fdb6cc8dc0cfb24c5f834f9a6da5b3a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GroupEditGroupQuery$variables = {
  groupId: string;
};
export type GroupEditGroupQuery$data = {
  readonly group: {
    readonly accessibleAreas?: ReadonlyArray<number | null> | null;
    readonly areas?: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly number: string;
    } | null> | null;
    readonly armableAreas?: ReadonlyArray<number | null> | null;
    readonly doors?: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly number: number;
    } | null> | null;
    readonly id?: string;
    readonly name?: string | null;
    readonly number?: string | null;
    readonly options?: {
      readonly armAuthority: boolean | null;
      readonly auditDaysEnabled: boolean | null;
      readonly auditDaysValue: string | null;
      readonly disarmAuthority: boolean | null;
      readonly groupsProgramming: boolean | null;
      readonly lockdown: boolean | null;
      readonly schedules: boolean | null;
      readonly swipeTwiceToArm: boolean | null;
      readonly userProgramming: boolean | null;
    } | null;
    readonly schedules?: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly number: string;
    } | null> | null;
  } | null;
};
export type GroupEditGroupQuery = {
  response: GroupEditGroupQuery$data;
  variables: GroupEditGroupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "groupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Door",
  "kind": "LinkedField",
  "name": "doors",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessibleAreas",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "armableAreas",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Schedule",
  "kind": "LinkedField",
  "name": "schedules",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Options",
  "kind": "LinkedField",
  "name": "options",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armAuthority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disarmAuthority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swipeTwiceToArm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userProgramming",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupsProgramming",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auditDaysEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auditDaysValue",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupEditGroupQuery",
    "selections": [
      {
        "alias": "group",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "areas",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "Group",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupEditGroupQuery",
    "selections": [
      {
        "alias": "group",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "areas",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "Group",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0dea19107ba64ffd0497aa0222b3596e",
    "id": null,
    "metadata": {},
    "name": "GroupEditGroupQuery",
    "operationKind": "query",
    "text": "query GroupEditGroupQuery(\n  $groupId: ID!\n) {\n  group: node(id: $groupId) {\n    __typename\n    ... on Group {\n      id\n      number\n      name\n      doors {\n        id\n        name\n        number\n      }\n      accessibleAreas\n      armableAreas\n      schedules {\n        id\n        name\n        number\n      }\n      areas {\n        __typename\n        id\n        name\n        number\n      }\n      options {\n        armAuthority\n        disarmAuthority\n        swipeTwiceToArm\n        lockdown\n        schedules\n        userProgramming\n        groupsProgramming\n        auditDaysEnabled\n        auditDaysValue\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "225e358ea9ef2c3a32ee7b8163487537";

export default node;
