import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaTwoManRuleField_xrArea$key } from "./__generated__/AreaTwoManRuleField_xrArea.graphql";

export const areaTwoManRuleFieldId = (number: string) =>
  `area-two-man-rule-${number}`;

function AreaTwoManRuleField() {
  const [{ number, twoManRule }, updateArea] =
    useAreaFragment<AreaTwoManRuleField_xrArea$key>(
      graphql`
        fragment AreaTwoManRuleField_xrArea on XrArea {
          number
          twoManRule
        }
      `
    );

  const fieldId = areaTwoManRuleFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={"Two Man Rule"}
      tooltip={
        "When enabled, 2 valid User Codes are required to arm or access the Area."
      }
    >
      (
      <Switch
        label="Two Man Rule"
        id={fieldId}
        checked={!!twoManRule}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!twoManRule, "twoManRule");
          });
        }}
      />
      )
    </ProgrammingConceptForm.Field>
  );
}

export default AreaTwoManRuleField;
