import graphql from "babel-plugin-relay/macro";
import { BaseButton } from "common/components/web/Button";
import {
  ConfirmModalOverlay,
  DeleteConfirmModalBody,
} from "components/Modal/ConfirmModal";
import React from "react";
import {
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay/hooks";
import { fetchQuery } from "relay-runtime";
import { ClientApplication } from "securecom-graphql/client";
import styled from "styled-components";
import { useShowAlert } from "../../contexts/AlertsContext";
import { Flex, Form, Notification } from "../DaStyledElements";
import { ErrorMsg } from "../DaStyledElements/Form";
import Icon from "../Icon";
import { SubmitButtonType } from "../LoginForm";
import {
  TwoFactorCodeDeliveryMethods,
  TwoFASetupFormReducer,
} from "./TwoFASetupModalFormReducer";
import { TwoFASetupMutation } from "./__generated__/TwoFASetupMutation.graphql";
import { TwoFASetupRemoveMutation } from "./__generated__/TwoFASetupRemoveMutation.graphql";
import twoFaQuery, {
  TwoFASetupUserQuery,
} from "./__generated__/TwoFASetupUserQuery.graphql";
import { TwoFASetupValidateCodeMutation } from "./__generated__/TwoFASetupValidateCodeMutation.graphql";
import { TwoFASetup_person$key } from "./__generated__/TwoFASetup_person.graphql";
const MessageParagraph = styled.div`
  font-size: 1.4rem;
  line-height: 1.5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--measure-2x);
`;

const BaseButtonPrimary = styled(BaseButton)`
  color: var(--color-primary-600);
  background-color: #def1f7;
  border-color: var(--color-primary-500);
  &:hover {
    color: var(--color-primary-600);
  }
`;

const BaseButtonDanger = styled(BaseButton)`
  color: var(--color-danger-600);
  background-color: #f4cfd5;
  border-color: var(--color-danger-500);
  &:hover {
    color: var(--color-danger-600);
  }
`;

const InlineInputLg = styled.input`
  padding: 0.8rem 1.6rem;
  height: 4.4rem;
`;
const SecurityCodeInput = styled.input`
  padding: 0.8rem 1.6rem;
  text-align: center;
  height: 5.6rem;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.4em;
  width: 8.5em;
`;

const setUpTwoFAMutation = graphql`
  mutation TwoFASetupMutation(
    $validationMethod: ValidationMethod!
    $phoneNumber: String
    $clientApplication: ClientApplication!
  ) {
    configureTwoFactorSettings(
      validationMethod: $validationMethod
      phoneNumber: $phoneNumber
      clientApplication: $clientApplication
    ) {
      __typename
      ... on ConfigureTwoFactorSettingsErrorResponse {
        __typename
        error {
          ... on InvalidPhoneNumberError {
            InvalidPhoneNumberError: type
          }
          ... on UnknownError {
            UnknownErrorType: type
          }
        }
      }

      ... on ConfigureTwoFactorSettingsNoActionRequiredResponse {
        twoFactorSettings {
          enabled
          requiredByDealer
          validationMethod
          phoneNumber
          requiredByCustomer
          __typename
        }
      }

      ... on ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse {
        uuid
        validationMethod
        sanitizedContactInfo
      }
    }
  }
`;

const removeTwoFAMutation = graphql`
  mutation TwoFASetupRemoveMutation {
    disableTwoFactorAuthentication {
      __typename
      ... on DisableTwoFactorAuthenticationErrorResponse {
        __typename
        error {
          ... on InvalidPhoneNumberError {
            InvalidErrorType: type
          }
          ... on UnauthorizedError {
            UnauthorizedErrorType: type
          }
          ... on UnknownError {
            UnknownErrorType: type
          }
        }
      }

      ... on DisableTwoFactorAuthenticationSuccessResponse {
        twoFactorSettings {
          enabled
          requiredByDealer
          validationMethod
          phoneNumber
          requiredByCustomer
          __typename
        }
      }
    }
  }
`;

const validateSecurityCode = graphql`
  mutation TwoFASetupValidateCodeMutation(
    $uuid: String!
    $code: String!
    $clientApplication: ClientApplication!
  ) {
    validateTwoFactorCode(
      uuid: $uuid
      code: $code
      clientApplication: $clientApplication
    ) {
      __typename
      ... on ValidateTwoFactorCodeErrorResponse {
        __typename
        error {
          ... on TwoFactorInvalidCodeError {
            InvalidCodeError: type
          }
          ... on TwoFactorSessionFailureError {
            SessionFailureError: type
          }
          ... on TwoFactorSessionExpiredError {
            SessionExpiredError: type
          }
          ... on TwoFactorMaxAttemptsReachedError {
            MaxAttemptsReachedError: type
          }
          ... on UnknownError {
            TwoFactorUnknownError: type
          }
        }
      }
      ... on ValidateTwoFactorCodeSuccessResponse {
        __typename
        users {
          authToken
          user {
            id
            twoFactorSettings {
              enabled
              requiredByDealer
              validationMethod
              phoneNumber
              requiredByCustomer
              __typename
            }
          }
        }
      }
    }
  }
`;

function TwoFASetupModal({
  closeModal,
  personRef,
}: {
  closeModal: () => void;
  personRef: TwoFASetup_person$key;
}) {
  const data = useFragment(
    graphql`
      fragment TwoFASetup_person on Person {
        id
        emailAddress
        appUser {
          twoFactorSettings {
            requiredByDealer
            enabled
            validationMethod
            phoneNumber
          }
        }
      }
    `,
    personRef
  );
  const initialFormState = {
    email: data.emailAddress,
    emailEnabled:
      data.appUser?.twoFactorSettings?.validationMethod === "EMAIL" || false,
    emailSecurityCode: "",
    emailSecurityCodeError: null,
    emailSecurityCodeInvalid: false,
    emailSecurityCodeVisible: false,
    emailSecurityCodeReplyViewShown: false,
    emailSetupSuccessViewVisible: false,
    phoneError: null,
    securityCodeDeliveryMethod: null,
    setupSuccessViewVisible: false,
    smsEnabled:
      data.appUser?.twoFactorSettings?.validationMethod === "SMS" || false,
    smsPhoneViewShown: false,
    smsSecurityCode: "",
    smsSecurityCodeBlurred: false,
    smsSecurityCodeInvalid: false,
    smsSecurityCodeReplyViewShown: false,
    smsSetupSuccessViewVisible: false,
    smsSecurityCodeError: null,
    confirmToDisable: false,
    smsViewShown: false,
    twoFactorAuthEnabled: data.appUser?.twoFactorSettings?.enabled || false,
    twoFactorPhoneNumber: data.appUser?.twoFactorSettings?.phoneNumber || "",
    twoFactorPhoneNumberChanged: false,
    twoFaIsEnabledViewVisible:
      data.appUser?.twoFactorSettings?.enabled || false,
    twoFASetupShown: true,
    twoFaRequiredByDealer:
      data.appUser?.twoFactorSettings?.requiredByDealer || false,
    uuid: "",
    showTwoFAExplanation: false,
  };

  const [state, dispatch] = React.useReducer(
    TwoFASetupFormReducer,
    initialFormState
  );

  const [setUpTwoFactorAuth, isSettingUpTwoFactorAuth] =
    useMutation<TwoFASetupMutation>(setUpTwoFAMutation);

  const [validateCode, isValidatingCode] =
    useMutation<TwoFASetupValidateCodeMutation>(validateSecurityCode);

  const [disableTwoFactorAuth, isDisablingTwoFactorAuth] =
    useMutation<TwoFASetupRemoveMutation>(removeTwoFAMutation);

  const showAlert = useShowAlert();

  const env: any = useRelayEnvironment();

  const submitTwoFaSetup = function () {
    if (state.twoFactorPhoneNumber && !state.phoneError) {
      setUpTwoFactorAuth({
        variables: {
          validationMethod: "SMS",
          phoneNumber: state.twoFactorPhoneNumber,
          clientApplication: ClientApplication.DEALER_ADMIN,
        },
        onCompleted: (response) => {
          if (
            response.configureTwoFactorSettings.__typename ===
            "ConfigureTwoFactorSettingsErrorResponse"
          ) {
            response.configureTwoFactorSettings.error.InvalidPhoneNumberError
              ? showAlert({
                  type: "error",
                  text: `Invalid Phone Number.`,
                })
              : showAlert({
                  type: "error",
                  text: `Unknown Error.`,
                });
          }
          if (
            response.configureTwoFactorSettings.__typename ===
            "ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse"
          ) {
            showAlert({
              type: "success",
              text: `Security Code Sent`,
            });

            dispatch({
              type: "SMS_SUBMIT_PHONE_NUMBER_BUTTON_CLICKED",
              value: true,
              uuid: response.configureTwoFactorSettings.uuid!,
            });
          }
          if (
            response.configureTwoFactorSettings.__typename ===
            "ConfigureTwoFactorSettingsNoActionRequiredResponse"
          ) {
            showAlert({
              type: "success",
              text: `Two-Factor is Enabled. Phone number is the same. `,
            });
            dispatch({
              type: "SMS_SECURITY_CODE_SUBMIT_BUTTON_CLICKED",
              smsEnabled: true,
            });
          }
        },
      });
    }
  };
  const submitTwoFaAuthenticationSms = function () {
    if (state.smsSecurityCode) {
      validateCode({
        variables: {
          uuid: state.uuid,
          code: state.smsSecurityCode,
          clientApplication: ClientApplication.DEALER_ADMIN,
        },
        onCompleted: (response) => {
          if (
            response.validateTwoFactorCode.__typename ===
            "ValidateTwoFactorCodeErrorResponse"
          ) {
            if (response.validateTwoFactorCode.error.InvalidCodeError) {
              showAlert({
                type: "error",
                text: `This code is invalid or has expired. Try again`,
              });
            } else if (
              response.validateTwoFactorCode.error.SessionFailureError
            ) {
              showAlert({
                type: "error",
                text: `Session Error Please try again.`,
              });
            } else if (
              response.validateTwoFactorCode.error.SessionExpiredError
            ) {
              showAlert({
                type: "error",
                text: `Time limit exceeded. Please generate another code and try again. `,
              });
            } else if (
              response.validateTwoFactorCode.error.MaxAttemptsReachedError
            ) {
              showAlert({
                type: "error",
                text: `Max number of attempts reached. Please generate a new code and try again.`,
              });
              dispatch({
                type: "SMS_RESPOND_MAX_ATTEMPTS_REACHED",
              });
            } else
              showAlert({
                type: "error",
                text: `Please generate a new code and try again.`,
              });
          }
          if (
            response.validateTwoFactorCode.__typename ===
            "ValidateTwoFactorCodeSuccessResponse"
          ) {
            showAlert({
              type: "success",
              text: `Text two factor authentication has been activated.`,
            });
            dispatch({
              type: "SMS_SECURITY_CODE_SUBMIT_BUTTON_CLICKED",
              smsEnabled: true,
            });
          }
        },
      });
    }
  };

  const submitTwoFaAuthenticationEmail = function () {
    if (state.emailSecurityCode) {
      validateCode({
        variables: {
          uuid: state.uuid,
          code: state.emailSecurityCode,
          clientApplication: ClientApplication.DEALER_ADMIN,
        },
        onCompleted: (response) => {
          if (
            response.validateTwoFactorCode.__typename ===
            "ValidateTwoFactorCodeErrorResponse"
          ) {
            if (response.validateTwoFactorCode.error.InvalidCodeError) {
              showAlert({
                type: "error",
                text: `This code is invalid or has expired. Try again`,
              });
            } else if (
              response.validateTwoFactorCode.error.SessionFailureError
            ) {
              showAlert({
                type: "error",
                text: `Session Error Please try again.`,
              });
            } else if (
              response.validateTwoFactorCode.error.SessionExpiredError
            ) {
              showAlert({
                type: "error",
                text: `Time limit exceeded. Please generate another code and try again. `,
              });
            } else if (
              response.validateTwoFactorCode.error.MaxAttemptsReachedError
            ) {
              showAlert({
                type: "error",
                text: `Max number of attempts reached. Please generate a new code and try again.`,
              });
              dispatch({
                type: "EMAIL_RESPOND_MAX_ATTEMPTS_REACHED",
              });
            } else
              showAlert({
                type: "error",
                text: `Please generate a new code and try again.`,
              });
          }
          if (
            response.validateTwoFactorCode.__typename ===
            "ValidateTwoFactorCodeSuccessResponse"
          ) {
            showAlert({
              type: "success",
              text: `Email two factor authentication has been activated.`,
            });
            dispatch({
              type: "EMAIL_SECURITY_CODE_SUBMIT_BUTTON_CLICKED",
              emailEnabled: true,
            });
          }
        },
      });
    }
  };

  const handleKeypress = ({
    event,
    button,
  }: {
    event: React.KeyboardEvent<HTMLInputElement>;
    button: SubmitButtonType;
  }) => {
    if (event.key === "Enter") {
      button === SubmitButtonType.EMAIL_CODE
        ? submitTwoFaAuthenticationEmail()
        : button === SubmitButtonType.SMS_CODE
        ? submitTwoFaAuthenticationSms()
        : submitTwoFaSetup();
    }
  };

  return (
    <>
      <div className="pad-16">
        {/* MODAL HEADER  */}
        <h1 className="printed-concept-heading mar-t-0">
          <span>
            {!state.securityCodeDeliveryMethod
              ? "Two Factor Authentication"
              : state.securityCodeDeliveryMethod ===
                TwoFactorCodeDeliveryMethods.SMS
              ? "Text Two Factor Authentication"
              : state.smsSecurityCodeReplyViewShown ||
                state.securityCodeDeliveryMethod ===
                  TwoFactorCodeDeliveryMethods.EMAIL
              ? "Enter Email Security Code"
              : state.smsEnabled
              ? "Two Factor Authentication Enabled"
              : state.emailEnabled
              ? "Two Factor Authentication Enabled"
              : null}
          </span>{" "}
          <span>
            {!state.securityCodeDeliveryMethod && (
              <Icon
                name="radial_info"
                onClick={() => {
                  dispatch({
                    type: "TOGGLE_TWO_FA_EXPLANATION",
                  });
                }}
              ></Icon>
            )}
          </span>
        </h1>
        {/* ENABLED  VIEW*/}
        {state.twoFaIsEnabledViewVisible && (
          <Notification.Alert
            type="success"
            message={
              state.smsEnabled
                ? `Enabled Text: ${state.twoFactorPhoneNumber}`
                : `Enabled Email: ${state.email}`
            }
          />
        )}
        {/* SETUP ROOT */}
        {!state.securityCodeDeliveryMethod && (
          <>
            {state.showTwoFAExplanation && (
              <MessageParagraph>
                Two-Factor Authentication adds an extra layer of security for
                your account. When you log in, you will be required to enter a
                security code that is sent in a text message or email
              </MessageParagraph>
            )}
            {!state.twoFactorAuthEnabled && (
              <>
                <MessageParagraph>
                  How would you like to receive the security code?
                </MessageParagraph>
                <BaseButtonPrimary
                  className="btn btn-sm btn-dmp mar-r-8"
                  onClick={() => {
                    dispatch({
                      type: "SMS_SETUP_BUTTON_CLICKED",
                      value: true,
                    });
                  }}
                >
                  Text
                </BaseButtonPrimary>
                <BaseButtonPrimary
                  className="btn btn-sm btn-dmp"
                  onClick={() => {
                    setUpTwoFactorAuth({
                      variables: {
                        validationMethod: "EMAIL",
                        phoneNumber: "",
                        clientApplication: ClientApplication.DEALER_ADMIN,
                      },
                      onCompleted: (response) => {
                        if (
                          response.configureTwoFactorSettings.__typename ===
                          "ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse"
                        ) {
                          dispatch({
                            type: "EMAIL_SETUP_BUTTON_CLICKED",
                            email:
                              response.configureTwoFactorSettings
                                .sanitizedContactInfo,
                            uuid: response.configureTwoFactorSettings.uuid!,
                          });

                          showAlert({
                            type: "success",
                            text: `Two factor code sent to ${data.emailAddress}.`,
                          });
                        }
                        if (
                          response.configureTwoFactorSettings.__typename ===
                          "ConfigureTwoFactorSettingsErrorResponse"
                        ) {
                          showAlert({
                            type: "error",
                            text: `Setup Failed`,
                          });
                        }
                      },
                    });
                  }}
                  disabled={isSettingUpTwoFactorAuth}
                >
                  Email
                </BaseButtonPrimary>
              </>
            )}
          </>
        )}
        {/* SMS SETUP */}
        {state.smsPhoneViewShown && (
          <>
            <Flex.Row
              className="mar-t-16"
              justify={"center"}
              align={"flex-start"}
            >
              <MessageParagraph>
                Enter the phone number you'd like the security code sent to when
                you log in.
              </MessageParagraph>
            </Flex.Row>
            <Flex.Row justify="center" align="center" direction="column">
              <Form.Label>Phone Number</Form.Label>
              <div className="form-inline mar-b-8">
                <Form.Group className={`required`}>
                  <Flex.Row justify="center" align="center" direction="column">
                    <InlineInputLg
                      style={{ textAlign: "center" }}
                      name="phone-number"
                      id="phone-number"
                      value={state.twoFactorPhoneNumber}
                      className="form-control"
                      maxLength={15}
                      onFocus={() =>
                        dispatch({
                          type: "TWO_FACTOR_PHONE_NUMBER_BLURRED",
                          value: false,
                        })
                      }
                      onChange={(event) =>
                        dispatch({
                          type: "TWO_FACTOR_PHONE_NUMBER_CHANGED",
                          value: event.target.value,
                        })
                      }
                      onKeyDown={(event) => {
                        handleKeypress({
                          event: event as React.KeyboardEvent<HTMLInputElement>,
                          button: SubmitButtonType.PHONE_NUMBER,
                        });
                      }}
                      required
                    />
                    {state.phoneError && (
                      <ErrorMsg>{state.phoneError}</ErrorMsg>
                    )}
                  </Flex.Row>
                </Form.Group>
              </div>
              <BaseButtonPrimary
                className="btn btn-dmp mar-t-4"
                disabled={
                  state.twoFactorPhoneNumber.length < 10 ||
                  state.phoneError != null ||
                  isSettingUpTwoFactorAuth
                }
                onClick={() => {
                  dispatch({
                    type: "TWO_FACTOR_PHONE_NUMBER_BLURRED",
                    value: true,
                  });
                  submitTwoFaSetup();
                }}
              >
                Send Security Code
              </BaseButtonPrimary>
            </Flex.Row>
          </>
        )}
        {/* SMS SECURITY CODE VERIFICATION */}
        {state.smsSecurityCodeReplyViewShown && (
          <>
            <MessageParagraph>
              Enter the security code that was sent to your phone number ending
              in {state.twoFactorPhoneNumber.slice(-4)} to finish setting up
              Two-Factor Authentication
            </MessageParagraph>
            <Flex.Row justify="center" align="center" direction="column">
              <Form.Group className={`required`}>
                <Flex.Row justify="center" align="center" direction="column">
                  <SecurityCodeInput
                    name="security-code-sms"
                    id="security-code-sms"
                    maxLength={6}
                    value={state.smsSecurityCode}
                    className="form-control"
                    onFocus={() => {
                      dispatch({
                        type: "SMS_SECURITY_CODE_BLURRED",
                        value: false,
                      });
                    }}
                    onChange={(event) =>
                      dispatch({
                        type: "SMS_SECURITY_CODE_CHANGED",
                        value: event.target.value,
                      })
                    }
                    onKeyDown={(event) => {
                      handleKeypress({
                        event: event as React.KeyboardEvent<HTMLInputElement>,
                        button: SubmitButtonType.SMS_CODE,
                      });
                    }}
                    required
                  />
                  {state.smsSecurityCodeError && (
                    <ErrorMsg>{state.smsSecurityCodeError}</ErrorMsg>
                  )}
                </Flex.Row>
              </Form.Group>

              <BaseButtonPrimary
                className="btn btn-dmp"
                onClick={() => {
                  if (state.smsSecurityCode.length === 6) {
                    submitTwoFaAuthenticationSms();
                  } else {
                    dispatch({
                      type: "SMS_SECURITY_CODE_BLURRED",
                      value: true,
                    });
                  }
                }}
                disabled={isValidatingCode}
              >
                Confirm Code
              </BaseButtonPrimary>
            </Flex.Row>
          </>
        )}
        {/* EMAIL SECURITY CODE VERIFICATION */}
        {state.emailSecurityCodeReplyViewShown && (
          <>
            <MessageParagraph>
              A security code will be sent to your email each time you log in.
              Enter the security code that was sent to {data.emailAddress} to
              finish setting up Two-Factor Authentication.
            </MessageParagraph>
            <Flex.Row justify="center" align="center" direction="column">
              <Form.Group className={"required"}>
                <Flex.Row justify="center" align="center" direction="column">
                  <SecurityCodeInput
                    className="form-control"
                    name="email-security-code"
                    id="email-security-code"
                    value={state.emailSecurityCode}
                    maxLength={6}
                    onChange={(event) =>
                      dispatch({
                        type: "EMAIL_SECURITY_CODE_CHANGED",
                        value: event.target.value,
                      })
                    }
                    onFocus={() => {
                      dispatch({
                        type: "EMAIL_SECURITY_CODE_BLURRED",
                        value: false,
                      });
                    }}
                    onKeyDown={(event) => {
                      handleKeypress({
                        event: event as React.KeyboardEvent<HTMLInputElement>,
                        button: SubmitButtonType.EMAIL_CODE,
                      });
                    }}
                  />
                  {state.emailSecurityCodeError && (
                    <ErrorMsg>{state.emailSecurityCodeError}</ErrorMsg>
                  )}
                </Flex.Row>
              </Form.Group>
              <BaseButtonPrimary
                className="btn btn-dmp"
                onClick={() => {
                  if (state.emailSecurityCode.length === 6) {
                    submitTwoFaAuthenticationEmail();
                  } else {
                    dispatch({
                      type: "EMAIL_SECURITY_CODE_BLURRED",
                      value: true,
                    });
                  }
                }}
                disabled={isValidatingCode}
              >
                Confirm Code
              </BaseButtonPrimary>
            </Flex.Row>
            <Flex.Row justify="center">
              <Flex.Col></Flex.Col>
            </Flex.Row>
          </>
        )}
        {/* SET UP SUCCESS FOR BOTH METHODS */}
        {state.smsSetupSuccessViewVisible && (
          <Notification.Alert
            type="success"
            message={`Enabled Text: ${state.twoFactorPhoneNumber}`}
          />
        )}
        {state.emailSetupSuccessViewVisible && (
          <Notification.Alert
            type="success"
            message={`Enabled Email: ${data.emailAddress}`}
          />
        )}
      </div>

      <Flex.Row justify="flex-end">
        {/* FOOTER BUTTON FOR SMS */}
        {(state.smsSetupSuccessViewVisible || state.smsEnabled) &&
          state.twoFactorAuthEnabled && (
            <>
              <BaseButtonPrimary
                className="btn btn-dmp btn-sm mar-r-4"
                onClick={() => {
                  dispatch({
                    type: "CHANGE_PHONE_BUTTON_CLICKED",
                  });
                }}
              >
                Change Phone
              </BaseButtonPrimary>
            </>
          )}

        {/* DISABLE BOTH */}
        {state.twoFactorAuthEnabled &&
          !data.appUser?.twoFactorSettings?.requiredByDealer && (
            <>
              <BaseButtonDanger
                className=" btn btn-sm btn-danger mar-r-4"
                onClick={() => {
                  dispatch({
                    type: "SHOW_CONFIRM_TO_DISABLE",
                    confirmToDisable: true,
                  });
                }}
              >
                Disable
              </BaseButtonDanger>

              {state.confirmToDisable && (
                <ConfirmModalOverlay>
                  <DeleteConfirmModalBody
                    header="Deactivate Two-Factor Authentication Confirmation"
                    children="Are you sure you would like to deactivate two-factor authentication?"
                    onConfirm={() => {
                      disableTwoFactorAuth({
                        variables: {},
                        onCompleted: (response) => {
                          if (
                            response.disableTwoFactorAuthentication
                              .__typename ===
                            "DisableTwoFactorAuthenticationSuccessResponse"
                          ) {
                            showAlert({
                              type: "success",
                              text: `Two Factor Authorization was disabled.`,
                            });
                          }
                          if (
                            response.disableTwoFactorAuthentication
                              .__typename ===
                            "DisableTwoFactorAuthenticationErrorResponse"
                          ) {
                            showAlert({
                              type: "error",
                              text: `Two Factor Authorization was not disabled.`,
                            });
                          }
                          fetchQuery(
                            env,
                            twoFaQuery,
                            {},
                            { fetchPolicy: "network-only" }
                          )
                            .toPromise()
                            .then(() =>
                              dispatch({
                                type: "DISABLED_BUTTON_CLICKED",
                                initialFormState,
                                email: data.emailAddress,
                              })
                            );
                        },
                      });
                      closeModal();
                    }}
                    onCancel={() => {
                      dispatch({
                        type: "SHOW_CONFIRM_TO_DISABLE",
                        confirmToDisable: false,
                      });
                    }}
                  />
                </ConfirmModalOverlay>
              )}
            </>
          )}

        {(state.smsSecurityCodeReplyViewShown ||
          state.emailSecurityCodeReplyViewShown) && (
          <>
            <BaseButtonPrimary
              className="mar-r-4"
              onClick={() => {
                state.smsSecurityCodeReplyViewShown
                  ? submitTwoFaSetup()
                  : setUpTwoFactorAuth({
                      variables: {
                        validationMethod: "EMAIL",
                        phoneNumber: "",
                        clientApplication: ClientApplication.DEALER_ADMIN,
                      },
                    });
              }}
              disabled={isSettingUpTwoFactorAuth}
            >
              Send A New Code
            </BaseButtonPrimary>
          </>
        )}
        <BaseButton
          className="btn btn-default "
          type="reset"
          onClick={closeModal}
        >
          {state.smsSetupSuccessViewVisible ||
          state.emailSetupSuccessViewVisible ||
          state.twoFaIsEnabledViewVisible
            ? "Close"
            : "Cancel"}
        </BaseButton>
      </Flex.Row>
    </>
  );
}

export default function TwoFASetupModalContainer({
  closeModal,
}: {
  closeModal: () => void;
}) {
  const data = useLazyLoadQuery<TwoFASetupUserQuery>(
    graphql`
      query TwoFASetupUserQuery {
        myPerson {
          ...TwoFASetup_person
        }
      }
    `,
    {},
    { fetchPolicy: "network-only" }
  );

  if (!data.myPerson) {
    return null;
  }

  return <TwoFASetupModal closeModal={closeModal} personRef={data.myPerson} />;
}
