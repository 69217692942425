/**
 * @generated SignedSource<<0b29d858ee1a9ba904e62a34f6c05067>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathDayOfWeek = "FRIDAY" | "MONDAY" | "NONE" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY";
export type CommunicationPathTestFrequencyUnit = "DAYS" | "HOURS";
import { FragmentRefs } from "relay-runtime";
export type CommPathTestFrequencyDayField_communicationPath$data = {
  readonly number: number;
  readonly testDayOfWeek: CommunicationPathDayOfWeek;
  readonly testFrequencyNumber: number;
  readonly testFrequencyUnit: CommunicationPathTestFrequencyUnit;
  readonly " $fragmentType": "CommPathTestFrequencyDayField_communicationPath";
};
export type CommPathTestFrequencyDayField_communicationPath$key = {
  readonly " $data"?: CommPathTestFrequencyDayField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathTestFrequencyDayField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathTestFrequencyDayField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testDayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testFrequencyUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testFrequencyNumber",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "d604ec235bd3131946262f9e1029a39c";

export default node;
