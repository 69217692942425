/**
 * @generated SignedSource<<c17137451c48c9631d0a7fa424d7c211>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreCheckInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entreConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreOutgoingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entrePassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreSupervisoryReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreUserCommandReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreZoneReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly firstApn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly integratorBackupConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorOutgoingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorSupervisoryReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorUserCommandReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorZoneReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly validEntreCheckinMinutes: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "d4d9fd934ece1a61de9db865e8a3405c";

export default node;
