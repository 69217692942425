/**
 *
 * BarGraphIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const BarGraphIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M0,0v64h64V0H0z M60,4v16H48V10H36v10H4V4H60z M14,32v8H4V24h32v16H26v-8H14z M48,24h12v16H48V24z M4,60V44h10v16H4z
     M26,44h10v16H26V44z M48,60V44h12v16H48z"
      />
    </g>
  </SvgIcon>
);

export default BarGraphIcon;
