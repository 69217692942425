/**
 * @generated SignedSource<<27639f735f8e83acd8361bc324a0cca9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraDetectionRegionSettings_varHub$data = {
  readonly id: string;
  readonly panelConnectionEnabled: boolean | null;
  readonly " $fragmentType": "RecorderCameraDetectionRegionSettings_varHub";
};
export type RecorderCameraDetectionRegionSettings_varHub$key = {
  readonly " $data"?: RecorderCameraDetectionRegionSettings_varHub$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionRegionSettings_varHub">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderCameraDetectionRegionSettings_varHub",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "panelConnectionEnabled",
      "storageKey": null
    }
  ],
  "type": "VarHub",
  "abstractKey": null
};

(node as any).hash = "85ace19ac1cad5fd08a132a500a831d4";

export default node;
