import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsDisplayTimeField_systemOptions$key } from "./__generated__/SystemOptionsDisplayTimeField_systemOptions.graphql";

export const systemOptionsDisplayTimeFieldId = () =>
  "system-options-display-time";

function DisplayTimeField() {
  const [{ displayTime }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsDisplayTimeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsDisplayTimeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            displayTime
          }
          ... on XtSystemOptions {
            displayTime
          }
        }
      `
    );

  const fieldId = systemOptionsDisplayTimeFieldId();
  const label = "Time Display";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="If enabled, allows the keypad to display day and time in the Status List."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={displayTime}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!displayTime, "displayTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DisplayTimeField;
