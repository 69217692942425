import {
  asID,
  fromControlSystemId,
  fromCustomerId,
  fromSiteId,
  PanelHardwareModel,
} from "securecom-graphql/client";
import { System } from "./CustomerSummarySystemTableComponent";

/**
 *
 * This function constructs a navigation object containing the URL and relevant parameters
 * based on the system's type and provided customer ID. For X1 type systems, it includes
 * the customer ID and site ID. For other systems, it includes the customer ID and control
 * system ID.
 *
 * @param {System} system - The system object used to generate navigation details.
 * @param {any} UserService - Service to check user permissions.
 * @param {string} customerId - The customer ID used in navigation parameters.
 * @returns An object containing the URL and navigation parameters, or undefined if the
 *          user cannot view systems.
 */

const generateNavUrl = (
  system: System,
  UserService: any,
  customerId: string
) => {
  const { systemId } = fromControlSystemId(asID(system.id));

  if (!UserService.canViewSystems()) return;
  if (system.isX1) {
    const siteId = fromSiteId(asID(system.site.id)).siteId;
    return {
      url: `app.sites.edit`,
      info: { customer_id: customerId, site_id: siteId },
    };
  }
  return {
    url: `app.control_system`,
    info: { customer_id: customerId, control_system_id: systemId },
  };
};

/**
 *
 * The function generates a navigation URL and additional info based on the provided system.
 * It then constructs parameters based on whether the system is of type 'X1'. For an X1 system,
 * customer_id and site_id are used, while for other systems, customer_id and control_system_id
 * are used. The navigation is performed using the state's 'go' method.
 *
 * @param {System} system - The system object containing details for navigation.
 * @param {any} state - The current state, used to perform the navigation.
 * @param {any} UserService - Service to check user permissions.
 * @param {string} customerId - The customer ID for navigation.
 * @param {any} stateParams - Additional state parameters used in navigation.
 * @returns Void. The function performs navigation without returning a value.
 */

export const navToSystemUrl = (
  system: System,
  state: any,
  UserService: any,
  customerId: string,
  stateParams: any
) => {
  const navDetails = generateNavUrl(system, UserService, customerId);

  if (!navDetails) return;

  const { url, info } = navDetails;

  // Construct parameters based on whether the system is of type 'X1'
  const params = system.isX1
    ? {
        customer_id: fromCustomerId(asID(info.customer_id)).customerId,
        site_id: info.site_id,
      }
    : {
        customer_id: stateParams.customer_id,
        control_system_id: info.control_system_id,
      };

  return state.go(url, params);
};

/**
 * This Function formats the System Type to a more user-friendly string. It maps various
 * system type codes to more descriptive strings. For example, 'CELLCOMSL is mapped to "CellComSL"
 * and XF6_100 is mapped to "XF6-100".
 */

export const getSystemTypePrettyPrint = (system: System) => {
  switch (system.panel.hardwareModel) {
    case PanelHardwareModel.CELLCOM_SL:
      return "CellComSL";
    case PanelHardwareModel.CELLCOM_EX:
      return "CellComEX";
    case PanelHardwareModel.ICOM_SL:
      return "iComSL";
    case PanelHardwareModel.ICOM_E:
      return "iComE";
    case PanelHardwareModel.ICOM:
      return "iCom";
    case PanelHardwareModel.ICOM_LNC:
      return "iComLNC";
    case PanelHardwareModel.ICOM_NRL:
      return "iComNRL";
    case PanelHardwareModel.DUALCOM:
      return "DualCom";
    case PanelHardwareModel.MINI_CELLCOM:
      return "MiniCellCom";
    case PanelHardwareModel.CELLCOM:
      return "CellCom";
    case PanelHardwareModel.XTLP:
      return system.panel.hardwareModelName
        ? system.panel.hardwareModelName
        : "XTLplus";
    case PanelHardwareModel.VIDEO_ONLY:
      return "Video Only";
    case PanelHardwareModel.X001:
      return "X1";
    case PanelHardwareModel.XF6_100:
      return "XF6-100";
    case PanelHardwareModel.XF6_500:
      return "XF6-500";
    case PanelHardwareModel.TMS6:
      return "TMSentry";
    default:
      return system.panel.hardwareModel;
  }
};

/**
 *
 * This function formats the account numbers of two systems by concatenating the account prefix
 * and account number, padding them with zeros if necessary. It then compares these formatted
 * account numbers to determine their order.
 *
 * @param {System} systemA - The first system to compare.
 * @param {System} systemB - The second system to compare.
 * @returns {number} - A value indicating the order of the two systems based on account numbers.
 */

export const sortSystemsByAccount = (systemA: System, systemB: System) => {
  const getFormattedAccountNumber = (system: System) => {
    const fullAccount = `${system.panel.accountPrefix}${system.panel.accountNumber}`;
    // Ensure the string has at least 4 characters, pad with zeros if not
    return fullAccount.padEnd(4, "0").substring(0, 4);
  };
  const formattedAccountA = getFormattedAccountNumber(systemA);
  const formattedAccountB = getFormattedAccountNumber(systemB);

  return formattedAccountA.localeCompare(formattedAccountB);
};

/**
 *
 * Based on the system's communication type, this function returns a descriptive string. It maps
 * various communication type codes to more user-friendly descriptions. For example, 'PERSISTENT'
 * is mapped to 'EASYconnect'.
 *
 * @param {System} system - The system object from which to retrieve the communication type.
 * @returns {string} - A descriptive string representing the system's communication type.
 */

export const getCommType = (system: System) => {
  switch (system.commType) {
    case "PERSISTENT":
      return "EASYconnect";
    case "PERSISTENT_WITH_CELL_BACKUP":
      return "EASYConnect + Cell Backup";
    case "CELL":
      return "Cell";
    case "NETWORK":
      return "Network";
    default:
      return "-";
  }
};
