import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import {
  useHas1100T,
  useSoftwareVersion,
  useWirelessZoneNumberRange,
  useZoneIsWirelessOnly,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationDMPWirelessField_zone$key } from "./__generated__/ZoneInformationDMPWirelessField_zone.graphql";

export const zoneInformationDMPWirelessFieldId = (number: string) =>
  `zone-information-dmp-wireless-${number}`;

function ZoneInformationDMPWirelessField(props: { houseCode?: string }) {
  const [{ number, wireless, competitorWireless }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationDMPWirelessField_zone$key>(
      graphql`
        fragment ZoneInformationDMPWirelessField_zone on Zone {
          number
          competitorWireless
          wireless
        }
      `
    );

  const fieldId = zoneInformationDMPWirelessFieldId(String(number));
  const wirelessZones = useWirelessZoneNumberRange();
  const softwareVersion = useSoftwareVersion();
  const isWirelessOnly = useZoneIsWirelessOnly()(number);
  const has1100T = useHas1100T();
  const disabled =
    !number ||
    !wirelessZones.includes(Number(number)) ||
    Number(props.houseCode) === 0 ||
    !!competitorWireless;
  const label = softwareVersion > 172 ? "DMP Wireless" : "Wireless";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={wireless}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            const toggledWireless = !wireless;
            //  Force DMP Wireless to true if the zone is wireless only and
            //   there is no competitor wireless
            const forceDMPWireless = isWirelessOnly && !has1100T;
            // Force Competitor Wireless to true if the zone is wireless only and
            // DMP wireless will be set to false
            const forceCompetitorWireless =
              has1100T && isWirelessOnly && !toggledWireless;

            recordProxy.setValue(
              forceDMPWireless || toggledWireless,
              "wireless"
            );
            if (!forceDMPWireless) {
              recordProxy.setValue("", "serialNumber");
            }
            if (has1100T) {
              recordProxy.setValue(
                forceCompetitorWireless,
                "competitorWireless"
              );
            }
            if (!toggledWireless) {
              recordProxy.setValue(false, "wirelessDisarmDisableEnabled");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationDMPWirelessField;
