/**
 *
 * HomeLargeIcon
 * @author Chad Watson
 *
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const HomeLargeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <title>home_large</title>
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          d="M32.6,6l-0.3-0.2h-0.7L20,13.1V7.9H8.1v12.5L0,25.5v7h5.1v25.7h54.7V32.6H64v-7L32.6,6z M10.6,10.3h7v4.2l-7,4.3
      L10.6,10.3z M61.5,30.1h-4.1v25.6H35.2V38.2h-6.3v17.6H7.6V30.1H2.4v-3.3L32,8.4l29.6,18.5L61.5,30.1z"
        />
        <rect x="43" y="38.2" width="7" height="7" />
        <rect x="14.6" y="38.2" width="6.9" height="7" />
      </g>
    </g>
  </SvgIcon>
);

export default HomeLargeIcon;
