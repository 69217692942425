import {
  destroyStreams,
  getStreamingConfig,
} from "dealer-admin/src/apis/vidprox";
import AuthContext from "dealer-admin/src/contexts/AuthContext";
import Hls from "hls.js";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import VideoLoadingOverlay from "./VideoLoadingOverlay";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

type Props = {
  deviceId: string;
  width: number | undefined;
  height: number;
};

const SecurecomPlayer = forwardRef(function SecurecomPlayer(
  { deviceId, height, width }: Props,
  ref
) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isRecycling, setIsRecycling] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPausedByUser, setIsPausedByUser] = useState(false);
  const { authToken } = useContext(AuthContext);

  const catchUp = () => {
    if (videoRef.current && !isNaN(videoRef.current.duration)) {
      videoRef.current.currentTime = videoRef.current.duration;
    }
  };

  const goLive = () => {
    if (videoRef.current) {
      setIsPausedByUser(false);
      catchUp();
      videoRef.current.play();
      setIsLoading(false);
    }
  };

  const pause = () => {
    if (videoRef.current) {
      setIsPausedByUser(true);
      videoRef.current.pause();
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        play: goLive,
        pause,
      };
    },
    []
  );

  useEffect(() => {
    let ignore = false;
    let hls: Hls;

    const recycleStream = async () => {
      setIsLoading(true);
      await destroyStreams(authToken, deviceId);
      if (hls) {
        hls.destroy();
      }
      setIsRecycling(true);
    };

    const startStreaming = async () => {
      const { hlsUrl } = await getStreamingConfig(authToken, deviceId);
      hls = new Hls();

      hls.on(Hls.Events.MANIFEST_PARSED, goLive);

      hls.on(Hls.Events.ERROR, function (event, data) {
        // The stream from vidprox typically dies after a few minutes and we need to get a new one
        if (data.type === Hls.ErrorTypes.NETWORK_ERROR && data.fatal) {
          recycleStream();
        }
      });

      if (!ignore && videoRef.current) {
        hls.attachMedia(videoRef.current);
        hls.loadSource(hlsUrl);
        setIsRecycling(false);
      }
    };

    startStreaming();

    return () => {
      ignore = true;
      if (hls) {
        hls.destroy();
      }
    };
  }, [authToken, deviceId, isRecycling]);

  // Some browsers automatically pause the video when the user switches to a different tab/window.
  // The video starts back up when the user comes back, but it doesn't catch up to the current
  // time. So we need to tell it to catch up. But if the user has paused the video manually, we
  // want to honor that and leave the video where it was. So we only set up this event listener
  // when the video is not currently paused by the user.
  useEffect(() => {
    if (!isPausedByUser) {
      const visibilityChangeHandler = () => {
        if (document.visibilityState === "visible") {
          catchUp();
        }
      };

      document.addEventListener("visibilitychange", visibilityChangeHandler);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          visibilityChangeHandler
        );
      };
    }
  }, [isPausedByUser]);

  return (
    <Wrapper style={{ width, height }}>
      {isLoading && <VideoLoadingOverlay />}
      <video muted ref={videoRef}></video>
    </Wrapper>
  );
});

export default SecurecomPlayer;
