//#region IMPORTS
import { RelayServiceType } from "app/common/services/relay-service";
import { Flex } from "components/DaStyledElements";
import { UserServiceType } from "components/SiteForm/types";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import {
  CustomFirmwareUploadFormReducer,
  CustomFirmwareUploadFormState,
} from "./CustomFirmwareUploaderReducer";
//#endregion IMPORTS

//#region TYPES
type CustomFirmwareUploadFormProps = {
  relayService: RelayServiceType;
  userService: UserServiceType;
  rootScope: any;
  stateParams: any;
  $state: any;
  stateIsEdit: boolean;
  authToken: string;
  customGraphicsUrl: string;
  environment: any;
};

//#endregion TYPES

//  Set The Intial State of the Form
const initialState: CustomFirmwareUploadFormState = {
  name: "",
  version: "",
  passcode: "",
  file: null,
  showError: false,
  errorMessage: "",
  uploadingFile: false,
};

export function CustomFirmwareUploadForm({
  customGraphicsUrl,
  authToken,
}: CustomFirmwareUploadFormProps) {
  const showAlert = useShowAlert();

  //  Set The Reducer
  const [state, dispatch] = React.useReducer(
    CustomFirmwareUploadFormReducer,
    initialState
  );

  const handleFileUpload = async (
    file: File,
    passcode: string,
    name: string,
    version: string,
    uploadingFile: boolean
  ) => {
    if (file) {
      // Create a new FormData instance
      const formData = new FormData();
      // Append the file to the formData instance
      formData.append("file", file);
      // Append the passcode to the formData instance
      formData.append("passcode", passcode);
      // Set the uploadingFile state to true to indicate the upload process
      dispatch({ type: "UPLOADING_FILE", value: true });
      try {
        // Create the url to send the form data to using the customGraphicsUrl, authToken, name, and version
        const destinationUrl = `${customGraphicsUrl}/api/v1/CustomKeyPadGraphics?name=${name}&version=${version}`;
        // Send the form data to the server using the fetch API
        const response = await fetch(destinationUrl, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        // Parse the response as JSON
        const data = await response.json();
        // Check if the response is successful
        if (response.ok) {
          // If success, show a success alert message
          showAlert({
            type: "success",
            text: `Firmware Uploaded`,
          });
          // Set the uploadingFile state to false to indicate the upload process is done
          dispatch({ type: "UPLOADING_FILE", value: false });
        } else {
          // Throw an error with the data message
          throw new Error(data.message);
        }
      } catch (error) {
        // Show an error alert message
        showAlert({
          type: "error",
          text: "Error uploading firmware. Please try again.",
        });

        // Set the uploadingFile state to false to indicate the upload process is done
        dispatch({ type: "UPLOADING_FILE", value: false });
      }
    } else {
      // Show an error alert message
      showAlert({
        type: "error",
        text: `Please select a valid zip file`,
      });
    }
  };

  return (
    <form
      onSubmit={() => {
        // Check if the file, passcode, name, and version are set, then call the handleFileUpload function
        if (state.file && state.passcode && state.name && state.version)
          handleFileUpload(
            state.file,
            state.passcode,
            state.name,
            state.version,
            state.uploadingFile
          );
      }}
    >
      <div className="form-horizontal">
        <div className="form-group required">
          <label htmlFor="name" className="control-label col-sm-3">
            Name
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="name"
              name="name"
              type="text"
              value={state.name}
              onChange={(event) =>
                dispatch({ type: "UPDATE_NAME", value: event.target.value })
              }
            />
          </div>
        </div>
        <div className="form-group required">
          <label htmlFor="version" className="control-label col-sm-3">
            Version
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="version"
              name="version"
              type="text"
              value={state.version}
              onChange={(event) =>
                dispatch({ type: "UPDATE_VERSION", value: event.target.value })
              }
            />
          </div>
        </div>
        <div className="form-group required">
          <label htmlFor="passcode" className="control-label col-sm-3">
            Passcode
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="passcode"
              name="passcode"
              type="text"
              value={state.passcode}
              onChange={(event) =>
                dispatch({ type: "UPDATE_PASSCODE", value: event.target.value })
              }
            />
          </div>
        </div>
        <div className="form-group required">
          <label htmlFor="fileToUpload" className="control-label col-sm-3">
            Upload
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="fileToUpload"
              name="fileToUpload"
              type="file"
              onChange={(event) =>
                dispatch({
                  type: "UPDATE_FILE",
                  value: event.target.files ? event.target.files[0] : null,
                })
              }
              accept=".zip"
            />
          </div>
        </div>
        <Flex.Row justify={"flex-end"}>
          <button
            className="btn btn-primary btn-sm"
            type="submit"
            disabled={
              // Disable the button if the file, passcode, name, or version
              //are not set or if the file is uploading
              !state.name ||
              !state.version ||
              !state.passcode ||
              !state.file ||
              state.uploadingFile
            }
          >
            {
              // Show "Upload in Progress..." if the file is uploading, otherwise show "Upload"
              state.uploadingFile ? <>Upload in Progress...</> : <>Upload</>
            }
          </button>
        </Flex.Row>
      </div>
    </form>
  );
}

export default CustomFirmwareUploadForm;
