/**
 * @generated SignedSource<<b74e375ee88e055e377f966b7a966057>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly networkOptions: {
    readonly dhcpEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dnsServer: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly gatewayAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly ipv6Address: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Dns: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Enabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Gateway: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Prefix: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly listenPort734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly localIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly subnetMask: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "bbd6328b354aedb9d40c214f0017b1b0";

export default node;
