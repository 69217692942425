import graphql from "babel-plugin-relay/macro";
import PhoneNumberInput from "components/FullProgramming/common/PhoneNumberInput";
import * as React from "react";
import { CommunicationComType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver1SecondPhoneNumberField_communication$key } from "./__generated__/CommunicationReceiver1SecondPhoneNumberField_communication.graphql";

export const communicationReceiver1SecondPhoneNumberFieldId = () =>
  "communication-receiver1-second-phone-number";

function CommunicationReceiver1SecondPhoneNumberField() {
  const [{ receiver1SecondaryPhoneNumber, comType }, updateCommunication] =
    useCommunicationFragment<CommunicationReceiver1SecondPhoneNumberField_communication$key>(
      graphql`
        fragment CommunicationReceiver1SecondPhoneNumberField_communication on Communication {
          receiver1SecondaryPhoneNumber
          comType
        }
      `
    );

  const fieldId = communicationReceiver1SecondPhoneNumberFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Second Phone Number"
      disabled={comType === CommunicationComType.CELL}
    >
      <PhoneNumberInput
        id={fieldId}
        maxLength={32}
        value={String(receiver1SecondaryPhoneNumber ?? "")}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "receiver1SecondaryPhoneNumber");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver1SecondPhoneNumberField;
