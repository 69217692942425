App.service("TempAppUserService", [
  "TempAppUserAPI",
  "$q",
  "UserService",
  "TEMP_DEALER_APP_USER",
  "PROPS",
  function (TempAppUserAPI, $q, UserService, TEMP_DEALER_APP_USER, PROPS) {
    /**
     * Create temporary access to the desired system. If hoursToLive is not declared, the default setting is sent.
     * @param {controlSystemModel} system
     * @param {int, string} [hoursToLive=TEMP_DEALER_APP_USER.DEFAULT_HOURS_TO_LIVE]
     * @returns {*}
     */
    this.create = function (system, hoursToLive) {
      //Check if tech support and on whitelist
      const isSpecialTechSupport =
        PROPS.TECH_SUPPORT_TEMP_LOGIN_AS_CUSTOMER.has(UserService.email);

      var deferred = $q.defer();
      if (angular.isUndefined(system)) {
        deferred.reject();
      }
      var body = {
        AuthToken: UserService.auth_token,
        PanelId: system.panels[system.panel_index].id,
      };
      body.HoursTillDestruction =
        typeof hoursToLive !== undefined && +hoursToLive > 0
          ? +hoursToLive
          : isSpecialTechSupport
          ? TEMP_DEALER_APP_USER.SPECIAL_TECH_SUPPORT_HOURS_TO_LIVE
          : TEMP_DEALER_APP_USER.DEFAULT_HOURS_TO_LIVE;

      TempAppUserAPI.create(
        angular.toJson(body),
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "TempAppUserService->create() error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.getStatus = function (groupId) {
      var deferred = $q.defer();
      TempAppUserAPI.getStatus(
        { groupId: groupId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "TempAppUserService->getStatus() error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  },
]);
