import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import {
  outputGroupListItemTemplateId,
  OUTPUT_GROUP_IDS,
} from "components/FullProgramming/common/OutputGroupFields/OutputGroupNumberField";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  fromControlSystemId,
  fromOutputId,
  idAsString,
  OutputGroup,
  Panel,
  toGlobalId,
  toOutputGroupId,
} from "securecom-graphql/client";
import XFOutputGroupFields from "./XFOutputGroupFields";
import {
  XFOutputGroupsProgrammingConceptFormInline_controlSystem$data,
  XFOutputGroupsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XFOutputGroupsProgrammingConceptFormInline_controlSystem.graphql";
import { XFOutputGroupsProgrammingConceptFormInline_outputGroup$data } from "./__generated__/XFOutputGroupsProgrammingConceptFormInline_outputGroup.graphql";
import { XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import { XFOutputGroupsProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XFOutputGroupsProgrammingConceptFormNavButton_controlSystem.graphql";
import { XFOutputGroupsProgrammingConceptFormOutputGroupDeleteMutation } from "./__generated__/XFOutputGroupsProgrammingConceptFormOutputGroupDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XFOutputGroupsProgrammingConceptFormOutputGroupRefreshMutation,
} from "./__generated__/XFOutputGroupsProgrammingConceptFormOutputGroupRefreshMutation.graphql";
import {
  XFOutputGroupsProgrammingConceptFormOutputGroupSendMutation,
  XFOutputGroupsProgrammingConceptFormOutputGroupSendMutation$data,
} from "./__generated__/XFOutputGroupsProgrammingConceptFormOutputGroupSendMutation.graphql";
import { XFOutputGroupsProgrammingConceptForm_controlSystem$key } from "./__generated__/XFOutputGroupsProgrammingConceptForm_controlSystem.graphql";

export const title = "Output Groups";
export const conceptId = "xf-output-groups";

export const getState = (
  controlSystem: XFOutputGroupsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFOutputGroupsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          id
          outputGroupsRange
          maxOutputGroups
          outputGroups {
            id
            number
            isNew
            ...XFOutputGroupsProgrammingConceptFormInline_outputGroup
          }
        }
      }
    `,
    controlSystem
  );

export const getOutputGroupState = (output: any) =>
  readInlineData(
    graphql`
      fragment XFOutputGroupsProgrammingConceptFormInline_outputGroup on OutputGroup
      @inline {
        id
        name
        number
        isNew
        outputNumber1
        outputNumber2
        outputNumber3
        outputNumber4
        outputNumber5
        outputNumber6
        outputNumber7
        outputNumber8
      }
    `,
    output
  );

const deleteMutation = graphql`
  mutation XFOutputGroupsProgrammingConceptFormOutputGroupDeleteMutation(
    $outputGroupId: ID!
  ) {
    deleteOutputGroup(outputGroupId: $outputGroupId) {
      ... on DeleteOutputGroupSuccessPayload {
        __typename
        deletedOutputGroupId
      }
      ... on FailedToRemoveOutputGroupErrorPayload {
        error: type
      }
    }
  }
`;

const refreshMutation = graphql`
  mutation XFOutputGroupsProgrammingConceptFormOutputGroupRefreshMutation(
    $id: ID!
  ) {
    refreshOutputGroups(systemId: $id) {
      ... on RefreshOutputGroupsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFOutputGroupsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshOutputGroupsError {
        error: type
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XFOutputGroupsProgrammingConceptFormOutputGroupSendMutation(
    $systemId: ID!
    $outputGroups: [OutputGroupProgrammingInput!]!
  ) {
    sendOutputGroupsProgramming(
      systemId: $systemId
      outputGroups: $outputGroups
    ) {
      ... on Error {
        type
      }
      ... on SendOutputGroupsProgrammingSuccessPayload {
        results {
          __typename
          ... on SendOutputGroupsProgrammingOutputGroupSuccessPayload {
            outputGroup {
              __typename
              id
              number
              ...XFOutputGroupsProgrammingConceptFormInline_outputGroup
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;
const mergeOldAndNewOutputGroups = (
  response: XFOutputGroupsProgrammingConceptFormOutputGroupSendMutation$data,
  originalControlSystemData: XFOutputGroupsProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendOutputGroupsProgramming.results) {
    const successfulOutputs = response.sendOutputGroupsProgramming.results
      .map((output) => {
        if (
          output.__typename ===
          "SendOutputGroupsProgrammingOutputGroupSuccessPayload"
        ) {
          return output;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.outputGroup)
      .map(getOutputGroupState);

    const mergedOutputGroupsMap = new Map<
      string,
      XFOutputGroupsProgrammingConceptFormInline_outputGroup$data
    >();

    originalControlSystemData.panel.outputGroups
      .map(getOutputGroupState)
      .forEach((item) => mergedOutputGroupsMap.set(item.id, item));

    successfulOutputs.forEach((item) =>
      mergedOutputGroupsMap.set(item.id, {
        ...mergedOutputGroupsMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedOutputGroupsMap.values());
  } else {
    return [];
  }
};

const updateParentControlSystem = (
  response: XFOutputGroupsProgrammingConceptFormOutputGroupSendMutation$data,
  originalControlSystemData: XFOutputGroupsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedOutputGroups = mergeOldAndNewOutputGroups(
    response,
    originalControlSystemData
  );

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshOutputGroups: {
        __typename: "RefreshOutputGroupsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            outputs: mergedOutputGroups,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFOutputGroupsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendOutputGroup, isSending] =
    useMutation<XFOutputGroupsProgrammingConceptFormOutputGroupSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const changedOutputGroups = useChangedProgrammingConcept(conceptId);
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { outputGroups },
        } = getState(props.controlSystem);
        if (outputGroups) {
          sendOutputGroup({
            variables: {
              systemId,
              outputGroups: outputGroups
                .filter(
                  (outputGroup) =>
                    outputGroup.isNew ||
                    (!!changedOutputGroups &&
                      listItemHasChanged(
                        outputGroup.id,
                        changedOutputGroups
                      )) ||
                    isSavingAllListItems
                )
                .map(getOutputGroupState)
                .map((outputGroup) => ({
                  id: outputGroup.id,
                  name: outputGroup.name,
                  number: outputGroup.number,
                  outputNumber1: outputGroup.outputNumber1,
                  outputNumber2: outputGroup.outputNumber2,
                  outputNumber3: outputGroup.outputNumber3,
                  outputNumber4: outputGroup.outputNumber4,
                  outputNumber5: outputGroup.outputNumber5,
                  outputNumber6: outputGroup.outputNumber6,
                  outputNumber7: outputGroup.outputNumber7,
                  outputNumber8: outputGroup.outputNumber8,
                  isNew: outputGroup.isNew,
                })),
            },
            onCompleted: (response) => {
              const saveErrors: SaveErrors = [];
              if (response.sendOutputGroupsProgramming.type && showAlerts) {
                showAlert({
                  type: "error",
                  text: `Error Sending ${title} - Panel Not Found`,
                });
              } else if (response.sendOutputGroupsProgramming.results) {
                response.sendOutputGroupsProgramming.results.forEach(
                  (response) => {
                    if (
                      response.__typename ===
                      "SendOutputGroupsProgrammingOutputGroupSuccessPayload"
                    ) {
                      resetLastUpdated(response.outputGroup.id);
                    } else if (
                      response.__typename === "SendListItemsErrorPayload"
                    ) {
                      saveErrors.push({
                        programmingConcept: title,
                        errors: response.errors,
                        listItemNumber: response.number,
                      });
                    }
                  }
                );

                updateParentControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );

                if (!saveErrors.length && showAlerts) {
                  showAlert({
                    type: "success",
                    text: `Successfully Updated ${title}`,
                  });
                }
              }
              resolve(saveErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSending,
  ];
};
export const useRetrieveMutation = (props: {
  controlSystem: XFOutputGroupsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshOutputGroup, isRefreshing] =
    useMutation<XFOutputGroupsProgrammingConceptFormOutputGroupRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshOutputGroup({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshOutputGroups;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Output Group Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, response);
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Failed To Retrieve Output Group Programming: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Failed To Retrieve Output Group Programming",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

const readOutputGroupsTemplateData = (
  programmingTemplateConcepts: XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        outputGroups {
          id
          included
          number
          name {
            included
            data
          }
          outputNumber1 {
            included
            data
          }
          outputNumber2 {
            included
            data
          }
          outputNumber3 {
            included
            data
          }
          outputNumber4 {
            included
            data
          }
          outputNumber5 {
            included
            data
          }
          outputNumber6 {
            included
            data
          }
          outputNumber7 {
            included
            data
          }
          outputNumber8 {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).outputGroups;

export function applyTemplateData(
  programmingTemplateConcepts: XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);

  const outputGroupRecordProxies =
    panelRecordProxy.getLinkedRecords("outputGroups") ?? [];
  const outputGroupsByNumber = indexRecordProxiesByNumber(
    outputGroupRecordProxies
  );

  const outputGroupsTemplateData =
    readOutputGroupsTemplateData(programmingTemplateConcepts) ?? [];

  outputGroupsTemplateData.forEach((outputGroupTemplateData) => {
    if (outputGroupTemplateData?.included) {
      let outputGroupRecordProxy = outputGroupsByNumber.get(
        outputGroupTemplateData.number
      );
      if (!outputGroupRecordProxy) {
        const newOutputGroupId = applyNewOutputGroupToOutputGroupsList(
          {
            id: panelRecordProxy.getValue("id"),
            outputGroupsRange: panelRecordProxy.getValue("outputGroupsRange"),
            newOutputGroup: panelRecordProxy.getLinkedRecord(
              "newOutputGroup"
            ) && {
              id: panelRecordProxy
                .getLinkedRecord("newOutputGroup")
                .getValue("id"),
            },
            outputGroups: outputGroupRecordProxies.map((recordProxy) => ({
              number: recordProxy.getValue("number"),
            })),
          },
          store
        );
        if (newOutputGroupId) {
          outputGroupRecordProxy = store.get(
            newOutputGroupId
          ) as RecordProxy<OutputGroup>;
          if (outputGroupRecordProxy) {
            outputGroupRecordProxy.setValue(
              outputGroupTemplateData.number,
              "number"
            );
          }
        }
      }

      if (!outputGroupRecordProxy) {
        return;
      }

      applyTemplateScalarDataToRecordProxy(
        outputGroupRecordProxy,
        outputGroupTemplateData
      );

      if (!outputGroupsByNumber.has(outputGroupTemplateData.number)) {
        outputGroupsByNumber.set(
          outputGroupTemplateData.number,
          outputGroupRecordProxy
        );
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(outputGroupsByNumber),
    "outputGroups"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XFOutputGroupsProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XFOutputGroupsProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            outputGroups {
              isNew
            }
          }
        }
      `
    );
  const { outputGroups } = controlSystem.panel;
  const itemsCount = outputGroups.length;
  const hasNewItems = itemsCount > 0 && outputGroups.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFOutputGroupsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFOutputGroupsProgrammingConceptForm_controlSystem on ControlSystem {
          __typename
          id
          copiedOutputGroup {
            id
          }
          panel {
            id
            ...OutputGroupNumberField_panel
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            outputGroupsRange
            maxOutputGroups
            outputGroups {
              id
              name
              number
              isNew
              ...XFOutputGroupFields_outputGroup
            }
            newOutputGroup {
              id
              number
              isNew
              name
              ...XFOutputGroupFields_outputGroup
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(OUTPUT_GROUP_IDS);
  const {
    outputGroups,
    newOutputGroup,
    helpFiles: { programmingGuideUrl },
  } = controlSystem.panel;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    controlSystem.panel.outputGroups[0]?.id ?? null
  );

  const [deleteOutputGroup, isDeleting] =
    useMutation<XFOutputGroupsProgrammingConceptFormOutputGroupDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();

  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedOutputGroup = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.outputGroups.findIndex(
          (outputGroup) => outputGroup.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.outputGroups.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return controlSystem.panel.outputGroups[newSelectedIndex]?.id ?? null;
      });
      const outputGroup = controlSystem.panel.outputGroups.find(
        (outputGroup) => outputGroup.id === selectedListItemId
      );
      uncheckListItem(String(outputGroup?.number));
      if (outputGroup?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "outputGroups",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "OutputGroup",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            outputGroup?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteOutputGroup({
          variables: {
            outputGroupId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "outputGroups",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedOutputGroupId } = response.deleteOutputGroup;
            if (deletedOutputGroupId) {
              showAlert({
                type: "success",
                text: "Output Group Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "outputGroups",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedOutputGroupId, error } = response.deleteOutputGroup;
            if (deletedOutputGroupId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedOutputGroupId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "outputGroups",
                      controlSystem.panel.id,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Output Group: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Output Group",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Output Group",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const canAdd = availableNumbers.size > 0;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Output%20Groups`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.outputGroups
        )}
        amountAvailable={availableNumbers.size}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newOutputGroupId = applyNewOutputGroupToOutputGroupsList(
                  controlSystem.panel,
                  store
                );
                if (newOutputGroupId) {
                  setSelectedListItemId(newOutputGroupId);
                }
              });
            }}
          >
            Add Output Group
          </ProgrammingConceptForm.AddButton>
        }
      >
        {(conceptId === activeConcept || isApplying || isSavingAll) && (
          <ProgrammingConceptForm.ListItemsContainer>
            <ProgrammingConceptForm.ListItemPicker
              selectedId={selectedListItemId}
              onChange={(id) => {
                setSelectedListItemId(id);
              }}
              newItemId={newOutputGroup?.id}
              items={outputGroups.map((outputGroup) => ({
                id: outputGroup.id,
                templateListItemId: outputGroupListItemTemplateId(
                  String(outputGroup.number)
                ),
                isnew: outputGroup.isNew,
                label: `#${outputGroup.number} ${outputGroup.name}`,
              }))}
            />
            <ProgrammingConceptForm.SelectedItemsContainer
              selectedListItemId={selectedListItemId}
              setSelectedListItemId={setSelectedListItemId}
            >
              {outputGroups.map(
                (outputGroup) =>
                  (outputGroup.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                    programmingConcepts[conceptId].isSaving ||
                    isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                    isSavingAll) && (
                    <RemountOnUpdateContainer nodeId={outputGroup.id}>
                      <ProgrammingConceptForm.SelectedItem
                        conceptId={conceptId}
                        isnew={outputGroup.isNew}
                        visible={outputGroup.id === selectedListItemId}
                        key={outputGroup.id}
                        listItemId={outputGroup.id}
                        templateListItemId={outputGroupListItemTemplateId(
                          String(outputGroup.number)
                        )}
                        title={`Output Group #${outputGroup.number}`}
                        onDuplicate={
                          canAdd &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const duplicateId =
                                applyDuplicatedOutputGroupToOutputGroupsList(
                                  selectedListItemId,
                                  controlSystem.panel,
                                  store
                                );
                              if (duplicateId) {
                                setSelectedListItemId(duplicateId);
                              }
                            });
                          })
                        }
                        onCopy={() => {
                          relayEnv.commitUpdate((store) => {
                            const controlSystemRecord = store.get(
                              controlSystem.id
                            );
                            const outputGroupRecord =
                              store.get<OutputGroup>(selectedListItemId);
                            if (controlSystemRecord && outputGroupRecord) {
                              const tempRecord =
                                store.get("copiedOutputGroup") ??
                                store.create(
                                  "copiedOutputGroup",
                                  "OutputGroup"
                                );
                              tempRecord.copyFieldsFrom(outputGroupRecord);
                              controlSystemRecord.setLinkedRecord(
                                tempRecord,
                                "copiedOutputGroup"
                              );
                            }
                          });
                        }}
                        onPaste={
                          !!controlSystem.copiedOutputGroup &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const outputGroupRecord =
                                store.get<OutputGroup>(selectedListItemId);
                              const copiedOutputGroupRecord =
                                store.get<OutputGroup>("copiedOutputGroup");
                              if (
                                outputGroupRecord &&
                                copiedOutputGroupRecord
                              ) {
                                applyOutputGroupProgrammingToOutputGroup(
                                  copiedOutputGroupRecord,
                                  outputGroupRecord
                                );
                              }
                            });
                          })
                        }
                        onRemove={removeSelectedOutputGroup}
                      >
                        <RemountOnUpdateContainer nodeId={outputGroup.id}>
                          <XFOutputGroupFields
                            key={outputGroup.id}
                            outputGroup={outputGroup}
                          />
                        </RemountOnUpdateContainer>
                      </ProgrammingConceptForm.SelectedItem>
                    </RemountOnUpdateContainer>
                  )
              )}
            </ProgrammingConceptForm.SelectedItemsContainer>
          </ProgrammingConceptForm.ListItemsContainer>
        )}
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewOutputGroupToOutputGroupsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newOutputGroup: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newOutputGroup } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newOutputGroupTemplate =
      panelRecord.getLinkedRecord<OutputGroup>("newOutputGroup");
    if (newOutputGroup) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromOutputId(
          asID(newOutputGroupTemplate.getDataID())
        );
        const nextNewOutputGroupId = idAsString(
          toOutputGroupId(systemId, nextNumber)
        );
        const nextNewOutputGroup = store.create(
          nextNewOutputGroupId,
          "OutputGroup"
        ) as RecordProxy<OutputGroup>;
        nextNewOutputGroup.copyFieldsFrom(newOutputGroupTemplate!);
        nextNewOutputGroup.setValue(nextNewOutputGroupId, "id");
        nextNewOutputGroup.setValue(nextNumber, "number");
        nextNewOutputGroup.setValue(true, "isNew");

        const outputGroupRecords =
          panelRecord.getLinkedRecords("outputGroups") ?? [];
        panelRecord.setLinkedRecords(
          [...outputGroupRecords, nextNewOutputGroup],
          "outputGroups"
        );

        return nextNewOutputGroup.getValue("id");
      }
    }
  }
};

const applyDuplicatedOutputGroupToOutputGroupsList = (
  outputGroupId: string,
  panel: Parameters<typeof getAvailableNumbers>[0] & {
    readonly id: string;
    readonly newOutputGroup: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newOutputGroup } = panel;
  const panelRecord = store.get<Panel>(id);
  const outputGroupRecord = store.get<OutputGroup>(outputGroupId);
  if (panelRecord && outputGroupRecord) {
    const newOutputGroupTemplate =
      panelRecord.getLinkedRecord<OutputGroup>("newOutputGroup");
    if (newOutputGroup) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromOutputId(
          asID(newOutputGroupTemplate.getDataID())
        );
        const nextNewOutputGroupId = idAsString(
          toOutputGroupId(systemId, nextNumber)
        );
        const nextNewOutputGroup = store.create(
          nextNewOutputGroupId,
          "OutputGroup"
        ) as RecordProxy<OutputGroup>;
        nextNewOutputGroup.copyFieldsFrom(newOutputGroupTemplate);
        nextNewOutputGroup.setValue(nextNewOutputGroupId, "id");
        nextNewOutputGroup.setValue(nextNumber, "number");
        nextNewOutputGroup.setValue(true, "isNew");

        const duplicatedOutputGroupRecord =
          applyOutputGroupProgrammingToOutputGroup(
            outputGroupRecord,
            nextNewOutputGroup
          );
        const outputGroupRecords =
          panelRecord.getLinkedRecords("outputGroups") ?? [];
        panelRecord.setLinkedRecords(
          [...outputGroupRecords, duplicatedOutputGroupRecord],
          "outputGroups"
        );

        return duplicatedOutputGroupRecord.getValue("id");
      }
    }
  }
};

const applyOutputGroupProgrammingToOutputGroup = (
  source: RecordProxy<OutputGroup>,
  dest: RecordProxy<OutputGroup>
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  const isNew = dest.getValue("isNew");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  return dest;
};

const getAvailableNumbers = (panel: {
  readonly outputGroupsRange: ReadonlyArray<number>;
  readonly outputGroups: ReadonlyArray<{
    readonly number: number;
  }>;
}) => {
  const { outputGroupsRange } = panel;

  const allPossibleNumbers = new Set(outputGroupsRange);
  const takenNumbers = new Set(panel.outputGroups.map(({ number }) => number));
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));
