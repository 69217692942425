import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { networkOptionsDhcpEnabledFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsDhcpEnabledField";
import { networkOptionsDnsServerFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsDnsServerField";
import { networkOptionsGatewayAddressFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsGatewayAddressField";
import { networkOptionsLocalIpAddressFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsLocalIpAddressField";
import { networkOptionsSubnetMaskFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsSubnetMaskField";
import { XFNetworkOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XFFullProgramming/__generated__/XFNetworkOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getNetworkOptionsState } from "../../../XFFullProgramming/XFNetworkOptionsProgrammingConceptForm";
import { ProgrammingTemplateNetworkOptionsInput } from "../Create/__generated__/XFTemplateSaveMutation.graphql";
import { XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        networkOptions {
          id
          included
          dhcpEnabled {
            included
            data
          }
          localIpAddress {
            included
            data
          }
          gatewayAddress {
            included
            data
          }
          subnetMask {
            included
            data
          }
          dnsServer {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XFNetworkOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateNetworkOptionsInput => {
  const {
    panel: { networkOptions },
  } = getNetworkOptionsState(controlSystem);

  if (!networkOptions) {
    return { included: false };
  }

  const networkOptionsInputFields = {
    dhcpEnabled: {
      included: includedFields.has(networkOptionsDhcpEnabledFieldId()),
      data: networkOptions.dhcpEnabled,
    },
    localIpAddress: {
      included: includedFields.has(networkOptionsLocalIpAddressFieldId()),
      data: networkOptions.localIpAddress,
    },
    gatewayAddress: {
      included: includedFields.has(networkOptionsGatewayAddressFieldId()),
      data: networkOptions.gatewayAddress,
    },
    subnetMask: {
      included: includedFields.has(networkOptionsSubnetMaskFieldId()),
      data: networkOptions.subnetMask,
    },
    dnsServer: {
      included: includedFields.has(networkOptionsDnsServerFieldId()),
      data: networkOptions.dnsServer,
    },
  };

  const networkOptionsIncluded = Object.values(networkOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: networkOptionsIncluded,
    ...networkOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { networkOptions } = getState(template);

  if (!networkOptions) return;

  if (networkOptions.dhcpEnabled?.included) {
    setIncludedFields(setToggle(networkOptionsDhcpEnabledFieldId()));
  }
  if (networkOptions.localIpAddress?.included) {
    setIncludedFields(setToggle(networkOptionsLocalIpAddressFieldId()));
  }
  if (networkOptions.gatewayAddress?.included) {
    setIncludedFields(setToggle(networkOptionsGatewayAddressFieldId()));
  }
  if (networkOptions.subnetMask?.included) {
    setIncludedFields(setToggle(networkOptionsSubnetMaskFieldId()));
  }
  if (networkOptions.dnsServer?.included) {
    setIncludedFields(setToggle(networkOptionsDnsServerFieldId()));
  }
};
