import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsAllowOwnUserCodeChangeField_systemOptions$key } from "./__generated__/SystemOptionsAllowOwnUserCodeChangeField_systemOptions.graphql";

export const systemOptionsAllowOwnUserCodeChangeFieldId = () =>
  "system-options-allow-own-user-code-change";

function SystemOptionsAllowOwnUserCodeChangeField() {
  const [{ allowOwnUserCodeChange }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsAllowOwnUserCodeChangeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsAllowOwnUserCodeChangeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            allowOwnUserCodeChange
          }
        }
      `
    );

  const fieldId = systemOptionsAllowOwnUserCodeChangeFieldId();
  const label = "Allow Own User Code Change";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="If enabled, Users can change their own User Code regardless of their profile settings."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={allowOwnUserCodeChange}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              !allowOwnUserCodeChange,
              "allowOwnUserCodeChange"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsAllowOwnUserCodeChangeField;
