/**
 * @generated SignedSource<<f5c94cee31086c3f7da930940b842a70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type X1Type = "X1" | "X8";
import { FragmentRefs } from "relay-runtime";
export type SiteControlSystemJobStatusBadge_siteControlSystem$data = {
  readonly hasFailedPreProgrammingJob: boolean;
  readonly hasPendingChanges: boolean;
  readonly id: string;
  readonly online: boolean;
  readonly type: X1Type;
  readonly " $fragmentType": "SiteControlSystemJobStatusBadge_siteControlSystem";
};
export type SiteControlSystemJobStatusBadge_siteControlSystem$key = {
  readonly " $data"?: SiteControlSystemJobStatusBadge_siteControlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteControlSystemJobStatusBadge_siteControlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SiteControlSystemJobStatusBadge_siteControlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "SiteControlSystemJobStatusBadge_siteControlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingChanges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFailedPreProgrammingJob",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};

(node as any).hash = "e90132844ec3e15beda5141e44ab4b4f";

export default node;
