/**
 * @generated SignedSource<<736f7146e390bbcd455c122b759cd3db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XFRemoteOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: "XfRemoteOptions";
      readonly appKey: string | null;
      readonly entreBackupConnection: RemoteOptionsConnectionType | null;
      readonly entreBackupIncomingPort: string | null;
      readonly entreBackupIpAddress: string | null;
      readonly entreCheckInMinutes: number | null;
      readonly entreConnection: RemoteOptionsConnectionType | null;
      readonly entreIncomingPort: string | null;
      readonly entreIpAddress: string | null;
      readonly entreOutgoingPort: string | null;
      readonly entrePassphrase: string | null;
      readonly entreSupervisoryReports: boolean | null;
      readonly entreUserCommandReports: boolean | null;
      readonly entreZoneReports: boolean | null;
      readonly firstApn: string | null;
      readonly id: string;
      readonly integratorBackupConnection: RemoteOptionsConnectionType | null;
      readonly integratorBackupIncomingPort: string | null;
      readonly integratorBackupIpAddress: string | null;
      readonly integratorConnection: RemoteOptionsConnectionType | null;
      readonly integratorIncomingPort: string | null;
      readonly integratorIpAddress: string | null;
      readonly integratorOutgoingPort: string | null;
      readonly integratorPassphrase: string | null;
      readonly integratorSupervisoryReports: boolean | null;
      readonly integratorUserCommandReports: boolean | null;
      readonly integratorZoneReports: boolean | null;
      readonly remoteKey: string;
      readonly validEntreCheckinMinutes: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  };
  readonly " $fragmentType": "XFRemoteOptionsProgrammingConceptFormInline_controlSystem";
};
export type XFRemoteOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFRemoteOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFRemoteOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFRemoteOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "a47ceac99b98c74c1d4634be5250115c";

export default node;
