// imports

import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import React from "react";
import { ZoneNumberOfEols, ZoneType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationNumberOfEolsField_zone$key } from "./__generated__/ZoneInformationNumberOfEolsField_zone.graphql";
export const zoneInformationNumberOfEolsFieldId = (number: string) =>
  `zone-information-number-of-eols-${number}`;

function ZoneInformationNumberOfEolsField() {
  const [{ number, numberOfEols, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationNumberOfEolsField_zone$key>(
      graphql`
        fragment ZoneInformationNumberOfEolsField_zone on Zone {
          type
          number
          numberOfEols
        }
      `
    );
  const [isDisabled, setIsDisabled] = React.useState(false);

  const fieldId = zoneInformationNumberOfEolsFieldId(String(number));
  const disabled = [
    ZoneType.FIRE,
    ZoneType.FIRE_VERIFY,
    ZoneType.TAMPER,
  ].includes(type as ZoneType);
  const hardwareModel = useHardwareModel();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Number Of EOL Resistors"
      tooltip="Each intrusion zone can be assigned a specific number of EOL resistors: 1 resistor is Standard, 2 resistors supports a tamper zone state, and 3 resistors will support anti-masking operation."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={numberOfEols ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "numberOfEols");
          });
        }}
      >
        <Select.Option value={ZoneNumberOfEols.ONE}>1</Select.Option>
        <Select.Option value={ZoneNumberOfEols.TWO}>2</Select.Option>
        <Select.Option value={ZoneNumberOfEols.THREE}>3</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationNumberOfEolsField;
