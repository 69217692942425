/**
 * @generated SignedSource<<151b4addeb63b5f3b6f9245ef31d7e77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTSystemReportsProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly softwareVersion: string;
    readonly systemReports: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemReportsAbortReportsField_systemReports" | "SystemReportsAccessKeypadsField_systemReports" | "SystemReportsAmbushReportsField_systemReports" | "SystemReportsBypassReportsField_systemReports" | "SystemReportsCodeChangeReportsField_systemReports" | "SystemReportsContext_systemReports" | "SystemReportsEarlyToCloseField_systemReports" | "SystemReportsEntryCheckinProtectionField_systemReports" | "SystemReportsLateToOpenField_systemReports" | "SystemReportsOpenCloseEnableField_systemReports" | "SystemReportsScheduleChangeReportsField_systemReports" | "SystemReportsSendStoredMessageField_systemReports" | "SystemReportsVideoReportsField_systemReports" | "SystemReportsZoneRestoralReportsField_systemReports">;
    } | null;
  };
  readonly " $fragmentType": "XTSystemReportsProgrammingConceptForm_controlSystem";
};
export type XTSystemReportsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XTSystemReportsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSystemReportsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTSystemReportsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SystemReports",
          "kind": "LinkedField",
          "name": "systemReports",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsContext_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsAbortReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsBypassReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsScheduleChangeReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsCodeChangeReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsAmbushReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsVideoReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsZoneRestoralReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsAccessKeypadsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsLateToOpenField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsEarlyToCloseField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsOpenCloseEnableField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsSendStoredMessageField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsEntryCheckinProtectionField_systemReports"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "b859ed790b71f558269bee6ac5f0a1bc";

export default node;
