/**
 * @generated SignedSource<<d235eb219b55a4276bef98d354f419b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathProtocol = "TCP" | "UDP";
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly communication: ReadonlyArray<{
    readonly apn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly apn2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly checkInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly commType: {
      readonly data: CommunicationPathCommType | null;
      readonly included: boolean | null;
    } | null;
    readonly duplicateAlarms: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly failTestHours: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly failTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly firstPhone: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly number: number;
    readonly protocol: {
      readonly data: CommunicationPathProtocol | null;
      readonly included: boolean | null;
    } | null;
    readonly receiverPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteIP: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly retryTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly secondPhone: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sendPath: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly testReport: {
      readonly data: CommunicationPathTestReport | null;
      readonly included: boolean | null;
    } | null;
    readonly testTime: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly type: {
      readonly data: CommunicationPathType | null;
      readonly included: boolean | null;
    } | null;
    readonly useCheckIn: {
      readonly data: CommunicationPathUseCheckIn | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "c931382a0a1deb610b06919f4d0540ac";

export default node;
