App.controller("FastXTLCtrl", [
  "$scope",
  "$rootScope",
  "$http",
  "$filter",
  "$q",
  "UserService",
  "focus",
  function ($scope, $rootScope, $http, $filter, $q, UserService, focus) {
    $scope.focus = focus;
    $scope.sirenZone = "";
    $scope.sirenZonePadded = "";
    $scope.zoneDeleted = false;

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     * This is the only way we can wait for this asynchronous work to be done to start initializing the zones.
     */
    $scope.$on("all_concepts_retrieved", function () {
      if (
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .hardware_model == "XTLP"
      ) {
        var zoneFile = "app/common/programming/zone_info_fast_xtl_plus.json";
        $scope.sirenZone = 61;
        $scope.sirenZonePadded = "061";
      } else {
        // XTL
        var zoneFile = "app/common/programming/zone_info_fast_xtl.json";
        $scope.sirenZone = 41;
        $scope.sirenZonePadded = "041";
      }

      $http
        .get(zoneFile)
        .then(
          function (data) {
            $scope.xtl_zone_defaults = data.data;
            initializeAllFields();
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    });

    /**
     * Function to initialize several different fields.  This is done on fast-xtl programming so that we can automatically
     * pull all of the data that we can from other sources for the user, making their required input very minimal.
     */
    function initializeAllFields() {
      setWeatherZip();
      initializeZones($scope.xtl_zone_defaults);
      setFastTestTime();
      setFastAppKey();
      setRemoteKey();
      setSiren();
    }

    /**
     * Sets the Panel.system_options.weather to null if it comes back from the panel as 00000
     */
    function setWeatherZip() {
      if ($scope.Panel.system_options) {
        var weatherZip = $scope.Panel.system_options.weather;
        if (weatherZip == "00000") $scope.Panel.system_options.weather = "";
      }
    }

    /**
     * Sets the Panel.communication.a_recl_tm to account_number MOD 1440
     */
    function setFastTestTime() {
      if ($scope.Panel.communication) {
        var testTime = $scope.Panel.communication.a_recl_tm;
        if (!testTime || testTime == null || testTime == "") {
          var mod =
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .account_number % 1440;
          var date = dateTimeForceUTC(new Date("2001-01-01T00:00:00Z"));
          $scope.Panel.communication.a_recl_tm = dateTimeStringToISOString(
            date.setMinutes(date.getMinutes() + mod)
          );
          // Also default the TEST DAYS field to 01
          $scope.Panel.communication.ctest_days = "01";
        }
      }
    }

    /**
     * Sets the app key to this dealer's app_key if it's a network panel
     */
    function setFastAppKey() {
      if ($scope.controlSystem.panels) {
        // If it's not an XTLN, then we won't set the APPKEY
        if (
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .hardware_model == "XTLN" ||
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .hardware_model == "XTLW" ||
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .hardware_model == "XTLP"
        ) {
          $scope.Panel.remote_options.app_key = UserService.dealerInfo.app_key;
        }
      }
    }

    /**
     * Sets the Panel.remote_options.crypt_key to the control system's Remote Key
     */
    function setRemoteKey() {
      if ($scope.Panel.remote_options) {
        $scope.Panel.remote_options.crypt_key =
          $scope.controlSystem.panels[
            $scope.controlSystem.panel_index
          ].remote_key;
      }
    }

    /**
     * If there is not already an output 41 set, then create a new one, prepared for the user to enter a serial number.
     */
    function setSiren() {
      if ($scope.Panel.output_informations) {
        // We only need to set this if there is NOT already an output 41.
        var foundSiren = $filter("filter")(
          $scope.Panel.output_informations,
          function (item) {
            return Number(item.number) == $scope.sirenZone;
          }
        );
        if (foundSiren.length < 1) {
          // Create a new output_information, then set the number to 41
          var sirenOutput = {
            name: "SIREN 1",
            number: $scope.sirenZone.toString(),
            out_supv: "3",
            serial_num: "",
            tripwbell: "Y",
            isNew: true,
          };
          $scope.Panel.output_informations.push(sirenOutput);
          // Also set the bell_out to 41, but only if it's not already set to something
          if ($scope.Panel.bell_options.bell_out == "000")
            $scope.Panel.bell_options.bell_out = $scope.sirenZonePadded;
        }
      }
    }

    /**
     * Check the siren field before saving. If it's empty (unset), then delete the item from the output_informations concept.
     * @returns {*}
     */
    function checkSirenBeforeSave() {
      var deferred = $q.defer();
      if ($scope.Panel.output_informations) {
        // Remove the output if no Serial Number is set
        var itemFound = false;
        for (var i = 0; i < $scope.Panel.output_informations.length; i++) {
          var item = $scope.Panel.output_informations[i];
          if (Number(item.number) == $scope.sirenZone && !item.serial_num) {
            itemFound = true;
            $scope.Panel.deleteItem("output_informations", item)
              .then(
                function (data) {
                  deferred.resolve();
                },
                function (error) {
                  deferred.reject();
                }
              )
              .catch(function (error) {
                console.error(error);
              });
            $scope.Panel.bell_options.bell_out = "000";
          }
        }
        if (!itemFound) {
          deferred.resolve();
        }
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    /**
     * Set the serial_no to null to "delete" a zone.
     * @param zoneItem
     */
    $scope.fastDeleteZoneClick = function (zoneItem) {
      zoneItem.serial_no = null;
      $scope.zoneDeleted = true;
    };

    /**
     * Validate that there are no other zones with the same serial_no.  If there is, null out the serial_no, then set focus back in the field.
     * @param thisZone
     * @returns {boolean}
     */
    $scope.blurSerialNumber = function (thisZone, formInput) {
      // If the input is invalid, don't let them leave the field
      if (formInput.$invalid) {
        focus("serial-" + thisZone.number);
        return true;
      }

      if (!thisZone.serial_no) {
        thisZone.serial_no = null;
        thisZone.rowIsEnabled = false;
        return true;
      }

      var foundZones = $filter("filter")(
        $scope.Panel.zone_informations,
        function (zone) {
          return (
            Number(zone.number) != thisZone.number &&
            zone.serial_no == thisZone.serial_no
          );
        }
      );

      var foundUnsavedZones = $filter("filter")(
        $scope.xtl_zone_defaults,
        function (zone) {
          return (
            Number(zone.number) != thisZone.number &&
            zone.serial_no == thisZone.serial_no
          );
        }
      );

      if (
        (foundZones && foundZones.length > 0) ||
        (foundUnsavedZones && foundUnsavedZones.length > 0)
      ) {
        thisZone.serial_no = null;
        $rootScope.alerts.push({
          type: "error",
          text:
            "Serial Number " +
            thisZone.serial_no +
            " is already being used on Zone " +
            (foundZones.length > 0
              ? foundZones[0].name
              : foundUnsavedZones[0].name),
        });
        focus("serial-" + thisZone.number);
        return false;
      }
      thisZone.rowIsEnabled = false;
      return true;
    };

    /**
     * Merges retrieved zones (API) into the templated zones.
     * @param xtl_zone_defaults
     */
    var initializeZones = function (xtl_zone_defaults) {
      //Merge in zone_informations into xtl_zone_defaults
      angular.forEach(xtl_zone_defaults, function (zone) {
        var found = $filter("filter")($scope.Panel.zone_informations, {
          number: zone.number,
        });
        if (found && found.length > 0) {
          angular.extend(zone, {
            number: found[0].number,
            name: found[0].name,
            tipe: found[0].tipe,
            area_list: found[0].area_list,
            serial_no: found[0].serial_no,
          });
        } else {
          zone.isNew = true;
        }
      });
    };

    /**
     * Function to prepare the Panel.zone_informations concept to be saved.
     * @returns {*}
     */
    var mergeZonesForSaving = function () {
      var deferred = $q.defer();
      var promises = [];
      // ADD in zone_informations into xtl_zone_defaults
      angular.forEach($scope.xtl_zone_defaults, function (zone) {
        // A serial_no means the user intends this zone to be saved, or remain saved.
        if (zone.serial_no != "" && zone.serial_no != null) {
          // Search for an existing matching zone number in zone_informations
          var found = $filter("filter")($scope.Panel.zone_informations, {
            number: zone.number,
          });
          // If we found it, then it already exists, we're just going to set the serial number in case it was modified.
          if (found && found.length > 0) {
            angular.extend(found[0], { serial_no: zone.serial_no });
          } else {
            // If we didn't find it, then we need to create a new zone (API), then extend it with this zone.
            var addPromise = $scope.Panel.newItem("zone_informations").then(
              function (newZone) {
                angular.extend(newZone, zone);
              },
              function (error) {
                $rootScope.alerts.push({ type: "error", json: error });
              }
            );
            promises.push(addPromise);
          }
        }
      });
      // DELETE zone_informations where matching zone number in xtl_zone_defaults doesn't have a serial_no
      angular.forEach(
        $scope.Panel.zone_informations,
        function (zone_information, index) {
          var found = $filter("filter")($scope.xtl_zone_defaults, {
            number: zone_information.number,
          });
          if (found.length > 0) {
            if (!found[0].serial_no) {
              var deletePromise = $scope.Panel.deleteItem(
                "zone_informations",
                zone_information
              );
              promises.push(deletePromise);
            }
          }
        }
      );
      if (promises.length > 0) {
        $q.all(promises)
          .then(
            function () {
              deferred.resolve();
            },
            function (error) {
              $rootScope.alerts.push({ type: "error", json: error });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    };

    /**
     * Function to set the correct Area name for display
     */
    $scope.displayAreaName = function (areaList) {
      if (angular.isUndefined(areaList)) return "";
      switch (areaList) {
        case "01":
          return "PERIM";
        case "02":
          return "INT";
        default:
          return "";
      }
    };

    /**
     * Send any updated programming to the panel, and once successful, save the controlSystem data.
     * This allows us to keep remote_key in sync between programming and system information.
     * @param {boolean} offlineSave - save changes to VK for offline (pre-program) panel.
     */
    $scope.fastSendAllProgramming = function (offlineSave, forceSend) {
      checkSirenBeforeSave()
        .then(
          function () {
            mergeZonesForSaving()
              .then(
                function () {
                  let suppressToasts = false;
                  var saveFunction = offlineSave
                    ? $scope.Panel.saveProgramming()
                    : $scope.Panel.sendProgramming(
                        [],
                        suppressToasts,
                        forceSend
                      );
                  saveFunction
                    .then(
                      function () {
                        $scope.controlSystem.save();
                        initializeAllFields();
                      },
                      function (error) {}
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  $rootScope.alerts.push({ type: "error", json: error });
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Retrieve programming for Fast XTL, then initialize fields
     */
    $scope.fastRetrieveAllProgramming = function () {
      $scope.Panel.refresh()
        .then(function () {
          initializeAllFields();
        })
        .catch(function (error) {
          console.error(error);
        });
    };
  },
]);
