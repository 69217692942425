import graphql from "babel-plugin-relay/macro";
import Space from "common/components/web/Space";
import AdvancedFields from "components/AdvancedFields";
import { useHouseCode } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsContext";
import ZoneInformationContactField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationContactField";
import ZoneInformationCrossZoneField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationCrossZoneField";
import ZoneInformationFireBellOutputNumberField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationFireBellOutputNumberField";
import ZoneInformationPresignalKeypadsField from "components/FullProgramming/common/ZoneInformationFields/ZoneInformationPresignalKeypadsField";
import * as React from "react";
import { useFragment } from "react-relay";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import ZoneInformationArmedOpenMessageField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenMessageField";
import ZoneInformationArmedOpenOutputActionField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenOutputActionField";
import ZoneInformationArmedOpenOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationArmedOpenOutputNumberField";
import ZoneInformationArmedShortMessageField from "../../common/ZoneInformationFields/ZoneInformationArmedShortMessageField";
import ZoneInformationArmedShortOutputActionField from "../../common/ZoneInformationFields/ZoneInformationArmedShortOutputActionField";
import ZoneInformationArmedShortOutputNumberField from "../../common/ZoneInformationFields/ZoneInformationArmedShortOutputNumberField";
import { ZoneInformationContextProvider } from "../../common/ZoneInformationFields/ZoneInformationContext";
import ZoneInformationDMPWirelessField from "../../common/ZoneInformationFields/ZoneInformationDMPWirelessField";
import ZoneInformationExpanderSerialNumberField from "../../common/ZoneInformationFields/ZoneInformationExpanderSerialNumberField";
import ZoneInformationFastResponseField from "../../common/ZoneInformationFields/ZoneInformationFastResponseField";
import ZoneInformationFirePanelSlaveInputField from "../../common/ZoneInformationFields/ZoneInformationFirePanelSlaveInputField";
import ZoneInformationNameField from "../../common/ZoneInformationFields/ZoneInformationNameField";
import ZoneInformationNumberField from "../../common/ZoneInformationFields/ZoneInformationNumberField";
import ZoneInformationSerialNumberField from "../../common/ZoneInformationFields/ZoneInformationSerialNumberField";
import ZoneInformationSupervisionTimeField from "../../common/ZoneInformationFields/ZoneInformationSupervisionTimeField";
import ZoneInformationSwingerBypassField from "../../common/ZoneInformationFields/ZoneInformationSwingerBypassField";
import ZoneInformationTypeField from "../../common/ZoneInformationFields/ZoneInformationTypeField";
import ZoneInformationZoneRetardDelayField from "../../common/ZoneInformationFields/ZoneInformationZoneRetardDelayField";
import { XFZoneInformationsFields_zone$key } from "./__generated__/XFZoneInformationsFields_zone.graphql";

function XFZoneInformationsFields(props: {
  zoneInformation: XFZoneInformationsFields_zone$key;
}) {
  const data = useFragment(
    graphql`
      fragment XFZoneInformationsFields_zone on Zone {
        id
        type
        ...ZoneInformationContext_zone
        ...ZoneInformationNameField_zone
        ...ZoneInformationNumberField_zone
        ...ZoneInformationTypeField_zone
        ...ZoneInformationDMPWirelessField_zone
        ...ZoneInformationSerialNumberField_zone
        ...ZoneInformationContactField_zone
        ...ZoneInformationSupervisionTimeField_zone
        ...ZoneInformationPrewarnKeypadsField_zone
        ...ZoneInformationPresignalKeypadsField_zone
        ...ZoneInformationSwingerBypassField_zone
        ...ZoneInformationCrossZoneField_zone
        ...ZoneInformationFireBellOutputNumberField_zone
        ...ZoneInformationArmedOpenMessageField_zone
        ...ZoneInformationArmedOpenOutputNumberField_zone
        ...ZoneInformationArmedOpenOutputActionField_zone
        ...ZoneInformationArmedShortMessageField_zone
        ...ZoneInformationArmedShortOutputNumberField_zone
        ...ZoneInformationArmedShortOutputActionField_zone
        ...ZoneInformationZoneRetardDelayField_zone
        ...ZoneInformationFastResponseField_zone
        ...ZoneInformationZoneRealTimeStatusField_zone
        ...ZoneInformationFirePanelSlaveInputField_zone
        ...ZoneInformationExpanderSerialNumberField_zone
      }
    `,
    props.zoneInformation
  );

  const houseCode = useHouseCode();

  return (
    <ZoneInformationContextProvider zoneInformation={data}>
      <ProgrammingConceptForm.Fields>
        <ZoneInformationNumberField />
        <ZoneInformationNameField />
        <ZoneInformationTypeField />
        <ZoneInformationExpanderSerialNumberField />
      </ProgrammingConceptForm.Fields>
      {/* Wireless Fields */}
      <Space />
      <h3>Wireless</h3>
      <ProgrammingConceptForm.Fields>
        <ZoneInformationDMPWirelessField houseCode={houseCode} />
        <ZoneInformationSerialNumberField />
        <ZoneInformationContactField />
        <ZoneInformationSupervisionTimeField />
      </ProgrammingConceptForm.Fields>
      <Space />
      {/* Advanced Fields */}
      <AdvancedFields>
        <ProgrammingConceptForm.Fields>
          <ZoneInformationSwingerBypassField />
          <ZoneInformationZoneRetardDelayField />
          <ZoneInformationPresignalKeypadsField />
          <ZoneInformationFastResponseField />
          <ZoneInformationCrossZoneField />
          <ZoneInformationFirePanelSlaveInputField />
        </ProgrammingConceptForm.Fields>
      </AdvancedFields>
      <Space />
      {/* Actions Fields */}
      <AdvancedFields label="Actions">
        <ProgrammingConceptForm.Fields>
          <ZoneInformationFireBellOutputNumberField />
          <ZoneInformationArmedOpenMessageField />
          <ZoneInformationArmedOpenOutputNumberField />
          <ZoneInformationArmedOpenOutputActionField />
          <ZoneInformationArmedShortMessageField />
          <ZoneInformationArmedShortOutputNumberField />
          <ZoneInformationArmedShortOutputActionField />
        </ProgrammingConceptForm.Fields>
      </AdvancedFields>
    </ZoneInformationContextProvider>
  );
}

export default XFZoneInformationsFields;
