/**
 * @ngdoc service
 * @name App.factory:Receiver
 *
 * @description
 * API factory for Receiver
 *
 */
App.factory("Receiver", [
  function () {
    var Receiver = function (the_receiver, dealer_id) {
      angular.extend(this, {
        id: null,

        dealer_id: dealer_id,

        number: 0,

        alarm: false,

        supervisory_trouble: false,

        open_close: false,

        test_report: false,

        communication_trouble: false,

        test_days: 0,

        primary_ip: "0.0.0.0",

        primary_port: 0,

        secondary_ip: null,

        secondary_port: null,

        extendMe: function (receiver) {
          angular.extend(this, receiver);
        },

        reset: function () {
          this.extendMe(
            new Receiver({ number: this.number, id: this.id }, dealer_id)
          );
        },
      });

      if (the_receiver != null && angular.isDefined(the_receiver)) {
        this.extendMe(the_receiver);
      }
    };
    return Receiver;
  },
]);
