App.controller(
  "DeletePaymentSourceModalInstanceCtrl",
  function ($scope, $modalInstance, $window, PaymentsService) {
    /** Different states the modal can be in. */
    $scope.modalStates = {
      START: "start", // Modal is open and waiting for user to confirm.
      PENDING: "pending", // Delete is in progress, waiting for response from API.
      ERROR: "error", // An error happened trying to delete.
    };

    // Set inital state
    $scope.modalState = $scope.modalStates.START;

    /**
     * Starts the delete. Triggered when user clicks the delete button.
     */
    $scope.confirm = function () {
      $scope.modalState = $scope.modalStates.PENDING;

      Promise.resolve()
        .then(
          () =>
            $scope.billingAccount.isAutoPayment &&
            PaymentsService.updateAutoPayment(
              $scope.billingAccount.dealerCode,
              false, // Is Enabled Flag, false to deactivate
              $scope.billingAccount.paymentSource.id
            )
        )
        .then(() =>
          PaymentsService.deletePaymentSource(
            $scope.billingAccount.dealerCode,
            $scope.billingAccount.paymentSource.id
          )
        )
        .then(() => {
          $modalInstance.close();
          $scope.modalState = $scope.modalStates.START;
        })
        .catch((error) => {
          $scope.modalState = $scope.modalStates.ERROR;
        });
    };

    /**
     * Closes the modal and does not do the delete.
     * Triggered when user clicks the cancel button.
     */
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };
  }
);
