import { RelayServiceType } from "app/common/services/relay-service";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import AddSystemImageButton from "./AddSystemImageButton";

interface AddSystemImageButtonRootProps {
  getSystemImages: () => void;
  imageCount: number;
  $rootScope: any;
  UserService: any;
  RelayService: RelayServiceType;
  PROPS: any;
}
function AddSystemImageButtonRoot(props: AddSystemImageButtonRootProps) {
  const {
    getSystemImages,
    imageCount,
    $rootScope,
    UserService,
    RelayService,
    PROPS,
  } = props;

  return (
    <RelayEnvironmentProvider environment={RelayService.getEnvironment()}>
      <AlertsContextProvider $rootScope={$rootScope}>
        <AddSystemImageButton
          authToken={UserService.auth_token}
          controlSystemId={UserService.control_system_id}
          imageCount={imageCount}
          imageApiUrl={PROPS.imageApiUrl}
          isHidden={!UserService.canEditSystems()}
          refreshSystemImages={getSystemImages}
        />
      </AlertsContextProvider>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "addSystemImageButton",
    react2angular(
      AddSystemImageButtonRoot,
      ["getSystemImages", "imageCount"],
      ["$rootScope", "UserService", "RelayService", "PROPS"]
    )
  );
}
