App.factory("customReport", [
  "UserService",
  "PROPS",
  "$timeout",
  "$http",
  "JobService",
  "$q",
  "$filter",
  "CustomReportsAPI",
  "DealerODataAPI",
  "OdataPageService",
  "JobSchedulerService",
  "Customer",
  "ControlSystemsService",
  function (
    UserService,
    PROPS,
    $timeout,
    $http,
    JobService,
    $q,
    $filter,
    CustomReportsAPI,
    DealerODataAPI,
    OdataPageService,
    JobSchedulerService,
    Customer,
    ControlSystemsService
  ) {
    var customReport = function (customReportObject) {
      // Setup any init value and methods
      angular.extend(this, {
        init: function () {
          if (!this.CronExpression) this.CronExpression = "0 0 * 1 * ? *";
          if (!this.MeridianTimeDisplay) {
            this.MeridianTimeDisplay = {
              hour: 1,
              indicator: "AM",
            };
            var cron = this.CronExpression.replace(/\s+/g, " ").split(" ");
            if (cron[2] !== "*") {
              var val = parseInt(cron[2]);
              if (val > 11) {
                this.MeridianTimeDisplay.indicator = "PM";
              }
              this.MeridianTimeDisplay.hour =
                val === 0 ? 12 : val === 12 ? val : val % 12;
            }
            if (cron[3] === "L" || parseInt(cron[3]) > 29) {
              cron[3] = 29;
              this.CronExpression = cron.join(" ");
            }
          }
          if (!this.Format) this.Format = "Pdf";
          if (!this.ConfigData) this.ConfigData = {};
          if (angular.isUndefined(this.Enabled)) this.Enabled = true;
          if (!this.EmailList) this.EmailList = UserService.email;
          if (!this.ConfigData.days_ago) this.days_ago = "7";
          if (this.ConfigData.start_date)
            this.ConfigData.start_date = new Date(this.ConfigData.start_date);
          if (!this.TimeZone) this.TimeZone = "Central Standard Time";
          if (!this.Custom) this.Custom = false;
          if (!this.CustomRequest) this.CustomRequest = {};
        },

        setDefaults: function () {
          this.ConfigData.hardware_model = "*";
          this.ConfigData.hardware_model_name = "*";
          this.ConfigData.comm_type = "*";
          this.ConfigData.services = {};
          this.ConfigData.services.lock_control = {};
          this.ConfigData.services.lock_control.include = false;
          this.ConfigData.services.lock_control.enabled = null;
          this.ConfigData.services.lock_control.title = "Z-Wave Doors";
          this.ConfigData.services.light_control = {};
          this.ConfigData.services.light_control.include = false;
          this.ConfigData.services.light_control.enabled = null;
          this.ConfigData.services.light_control.title = "Z-Wave Lights";
          this.ConfigData.services.thermostat_control = {};
          this.ConfigData.services.thermostat_control.include = false;
          this.ConfigData.services.thermostat_control.enabled = null;
          this.ConfigData.services.thermostat_control.title =
            "Z-Wave Thermostats";
          this.ConfigData.services.traffic_count = {};
          this.ConfigData.services.traffic_count.include = false;
          this.ConfigData.services.traffic_count.enabled = null;
          this.ConfigData.services.traffic_count.title = "Traffic Count";
          this.ConfigData.services.real_time_events = {};
          this.ConfigData.services.real_time_events.include = false;
          this.ConfigData.services.real_time_events.enabled = null;
          this.ConfigData.services.real_time_events.title = "Advanced Reports";
          this.ConfigData.services.premium_video = {};
          this.ConfigData.services.premium_video.include = false;
          this.ConfigData.services.premium_video.enabled = null;
          this.ConfigData.services.premium_video.title = "Video";
          this.ConfigData.services.four_additional_video_channels = {};
          this.ConfigData.services.four_additional_video_channels.include = false;
          this.ConfigData.services.four_additional_video_channels.enabled =
            null;
          this.ConfigData.services.four_additional_video_channels.title =
            "Expanded Video";
          this.ConfigData.services.sensor_activity = {};
          this.ConfigData.services.sensor_activity.include = false;
          this.ConfigData.services.sensor_activity.enabled = null;
          this.ConfigData.services.sensor_activity.title = "Sensor Activity";
          this.ConfigData.services.user_code_edit = {};
          this.ConfigData.services.user_code_edit.include = false;
          this.ConfigData.services.user_code_edit.enabled = null;
          this.ConfigData.services.user_code_edit.title =
            "User Codes Management";
          this.ConfigData.services.schedule_edit = {};
          this.ConfigData.services.schedule_edit.include = false;
          this.ConfigData.services.schedule_edit.enabled = null;
          this.ConfigData.services.schedule_edit.title = "Schedules Management";
          this.ConfigData.services.video_verification = {};
          this.ConfigData.services.video_verification.include = false;
          this.ConfigData.services.video_verification.enabled = null;
          this.ConfigData.services.video_verification.title =
            "Video Verification";
          this.ConfigData.services.door_edit = {};
          this.ConfigData.services.door_edit.include = false;
          this.ConfigData.services.door_edit.enabled = null;
          this.ConfigData.services.door_edit.title = "Door Control";
          this.ConfigData.services.hikvision_doorbell = {};
          this.ConfigData.services.hikvision_doorbell.include = false;
          this.ConfigData.services.hikvision_doorbell.enabled = null;
          this.ConfigData.services.hikvision_doorbell.title = "Video Doorbell";
          this.ConfigData.troubles = {};
          this.ConfigData.troubles.zone_trouble = false;
          this.ConfigData.troubles.zwave_battery = false;
          this.ConfigData.troubles.ac_power = false;
          this.ConfigData.troubles.ac_power_restored = false;
          this.ConfigData.troubles.system_low_battery = false;
          this.ConfigData.troubles.system_test = false;
          this.ConfigData.troubles.zone_inactivity = false;
        },

        TROUBLE_TYPES: {
          zone_trouble: {
            subcategory: "Event::Subcategory::ZoneDeviceTrouble::Base",
            subcategory_id: 23,
            display_name: "Zone/Device Trouble",
          },
          zwave_battery: {
            subcategory: "Event::Subcategory::LowBattery::Base",
            subcategory_id: 30,
            display_name: "Z-Wave Device Low Battery",
          },
          ac_power: {
            subcategory: "Event::Subcategory::SystemMessage::ACPowerFailure",
            subcategory_id: 31,
            display_name: "AC Power Failure",
          },
          ac_power_restored: {
            subcategory: "Event::Subcategory::SystemMessage::ACPowerRestored",
            subcategory_id: 32,
            display_name: "AC Power Restored",
          },
          system_low_battery: {
            subcategory: "Event::Subcategory::SystemMessage::LowBattery",
            subcategory_id: 34,
            display_name: "Low Battery",
          },
          system_test: {
            subcategory: "Event::Subcategory::SystemMessage::SystemTest",
            subcategory_id: 36,
            display_name: "System Test",
          },
          zone_inactivity: {
            subcategory: "Event::Subcategory::SystemMessage::ZoneInactivity",
            subcategory_id: 77,
            display_name: "Zone Inactivity",
          },
        },

        save: function () {
          this.getData();
          if (this.isNew) return this.createReport();
          else return this.updateReport();
        },

        createReport: function () {
          var deferred = $q.defer();
          var _this = this;
          CustomReportsAPI.createReport(
            {},
            _this.toJson(),
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        updateReport: function () {
          var deferred = $q.defer();
          var _this = this;
          CustomReportsAPI.updateReport(
            { report_id: _this.Id },
            _this.toJson(),
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        delete: function () {
          var deferred = $q.defer();
          var _this = this;
          CustomReportsAPI.deleteReport(
            { report_id: _this.Id },
            function () {
              deferred.resolve();
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        runReport: function () {
          var deferred = $q.defer();
          var _this = this;

          CustomReportsAPI.runReport(
            { report_id: _this.Id },
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        toJson: function () {
          var returnBody = angular.copy(this);
          delete returnBody.TROUBLE_TYPES;
          delete returnBody.isNew;
          returnBody.ConfigData = angular.toJson(this.ConfigData);
          returnBody.CustomRequest = angular.toJson(this.CustomRequest);
          returnBody.DealerId = angular.copy(UserService.dealer_id);
          returnBody.fixCronExpression();
          delete returnBody.MeridianTimeDisplay;
          return returnBody;
        },

        getData: function () {
          var deferred = $q.defer();
          var _this = this;
          var apiCall = undefined;
          if (_this.ConfigData.hardware_model === "XTLtouch") {
            _this.ConfigData.hardware_model = "XTLP";
            _this.ConfigData.hardware_model_name = "XTLtouch";
          }
          switch (this.ConfigData.report_type) {
            case "new_systems":
              apiCall = _this.getPanelsForDealer(
                UserService.dealer_id,
                _this.ConfigData.hardware_model,
                _this.ConfigData.comm_type,
                _this.ConfigData.start_date,
                null,
                _this.ConfigData.days_ago,
                _this.ConfigData.tag
              );
              break;
            case "virtual_keypad_services":
              apiCall = _this.getSystemsByServices(
                UserService.dealer_id,
                _this.ConfigData.services,
                _this.ConfigData.hardware_model,
                _this.ConfigData.comm_type,
                _this.ConfigData.start_date,
                null,
                _this.ConfigData.days_ago,
                _this.ConfigData.tag
              );
              break;
            case "troubles":
              apiCall = _this.getTroubleEvents(
                UserService.dealer_id,
                _this.ConfigData.troubles,
                _this.ConfigData.hardware_model,
                _this.ConfigData.comm_type,
                _this.ConfigData.start_date,
                null,
                _this.ConfigData.days_ago,
                _this.ConfigData.tag
              );
              break;
            case "installs":
              apiCall = _this.getSystemsByInstalls(
                UserService.dealer_id,
                _this.ConfigData.hardware_model,
                _this.ConfigData.comm_type,
                _this.ConfigData.start_date,
                null,
                _this.ConfigData.days_ago,
                _this.ConfigData.install_type,
                _this.ConfigData.installed_at,
                _this.ConfigData.installer,
                _this.ConfigData.salesperson,
                _this.ConfigData.use_install_date,
                _this.ConfigData.tag
              );
          }

          if (angular.isUndefined(apiCall)) {
            deferred.reject();
          } else {
            apiCall
              .then(
                function (data) {
                  deferred.resolve(data);
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
          return deferred.promise;
        },

        getPanelsForDealer: function (
          dealerID,
          hardwareModel,
          commType,
          startDate,
          endDate,
          daysAgo,
          tagId
        ) {
          var deferred = $q.defer();
          var queryFilter = "";
          var expandFilter = "";
          var selectFilter = "";
          var columns = [
            { name: "Customer", field: "control_system.customer.name" },
            { name: "System Name", field: "control_system.name" },
            { name: "Prefix", field: "account_prefix" },
            { name: "Account", field: "account_number" },
            {
              name: "System Type",
              field: "hardware_model",
              type: "hardware_model",
            },
            { name: "Serial", field: "serial_number" },
            {
              name: "Created",
              field: "control_system.created_at",
              type: "datetime_tz_12",
            },
            {
              name: "Installed",
              field: "control_system.installed_at",
              type: "datetime_tz_12",
              visible: false,
            },
            {
              name: "Email",
              field: "control_system.customer.email",
              visible: false,
            },
            {
              name: "Postal Code",
              field: "control_system.postal_code",
              visible: false,
            },
            {
              name: "Address",
              field: "control_system.address_1",
              visible: false,
            },
            {
              name: "Address 2",
              field: "control_system.address_2",
              visible: false,
            },
            { name: "City", field: "control_system.city", visible: false },
            { name: "State", field: "control_system.state", visible: false },
            {
              name: "Country",
              field: "control_system.country",
              visible: false,
            },
          ];
          this.HeaderMap = columns;

          queryFilter = this.buildQueryFilter(
            hardwareModel,
            commType,
            startDate,
            "",
            endDate,
            daysAgo
          );

          if (queryFilter) {
            queryFilter = "&$filter=" + queryFilter;
          }

          queryFilter = queryFilter.replace(/ and $/, "");

          if (tagId) {
            queryFilter += `&tag_id=${tagId}`;
          }

          expandFilter =
            "$expand=control_system($select=name,postal_code,address_1,address_2,city,state,country,created_at,installed_at;$expand=customer($select=name,email))";

          selectFilter =
            "$select=account_prefix,account_number,hardware_model,serial_number";

          const url = `${
            PROPS.reportsApiUrl
          }/api/v1/dealers(${dealerID})/vk.GetPanels()?${selectFilter}&${
            expandFilter + queryFilter
          }`;

          this.OdataQuery = encodeURI(url);

          var promise = $http.get(url);
          ODPS = new OdataPageService();
          ODPS.getAllPages(promise)
            .then(
              function (data) {
                //success
                deferred.resolve(data);
              },
              function (error) {
                //failure
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        //TODO: Add endDate or now() function call into query
        getSystemsByServices: function (
          dealerID,
          services,
          hardwareModel,
          commType,
          startDate,
          endDate,
          daysAgo,
          tagId
        ) {
          var deferred = $q.defer();
          var _this = this;
          var queryFilter = "";
          var serviceSelect = "$select=";
          var columns = [
            { name: "Customer", field: "control_system.customer.name" },
            { name: "System Name", field: "control_system.name" },
            { name: "Prefix", field: "account_prefix" },
            { name: "Account", field: "account_number" },
            {
              name: "System Type",
              field: "hardware_model",
              type: "hardware_model",
            },
            { name: "Serial", field: "serial_number" },
            {
              name: "Created",
              field: "control_system.created_at",
              type: "datetime_tz_12",
            },
            {
              name: "Email",
              field: "control_system.customer.email",
              visible: false,
            },
            {
              name: "Postal Code",
              field: "control_system.postal_code",
              visible: false,
            },
            {
              name: "Address",
              field: "control_system.address_1",
              visible: false,
            },
            {
              name: "Address 2",
              field: "control_system.address_2",
              visible: false,
            },
            { name: "City", field: "control_system.city", visible: false },
            { name: "State", field: "control_system.state", visible: false },
            {
              name: "Country",
              field: "control_system.country",
              visible: false,
            },
          ];

          queryFilter = this.buildQueryFilter(
            hardwareModel,
            commType,
            startDate,
            "",
            endDate,
            daysAgo
          );

          // Loop through the services and create the filters
          var serviceFilter = "";
          angular.forEach(services, function (value, service) {
            if (value.include) {
              // Select the filter field
              if (value.enabled !== null)
                serviceFilter =
                  serviceFilter +
                  " " +
                  "control_system/services_managers/any(sm:sm/" +
                  service +
                  "_enabled eq " +
                  value.enabled +
                  ") and ";
              serviceSelect = serviceSelect + service + "_enabled,";
              // Define the filter column
              var column = {};
              column.name = _this.ConfigData.services[service].title;
              column.field =
                "control_system.services_managers[0]." + service + "_enabled";
              column.type = "boolean";
              columns.push(column);
            }
          });
          this.HeaderMap = columns;

          serviceSelect = serviceSelect.replace(/,$/, "");

          // Merge the filters and clean up the trailing and...
          queryFilter = queryFilter + serviceFilter;
          queryFilter = queryFilter.replace(/ and $/, "");

          var filter = queryFilter ? "&$filter=" + queryFilter : "";

          queryFilter =
            "$expand=control_system($expand=services_managers(" +
            serviceSelect +
            "),customer($select=name,email))" +
            filter;

          if (tagId) {
            queryFilter += `&tag_id=${tagId}`;
          }

          const url = `${PROPS.reportsApiUrl}/api/v1/dealers(${dealerID})/vk.GetPanels()?${queryFilter}`;
          this.OdataQuery = encodeURI(url);

          var promise = $http.get(url);

          var ODPS = new OdataPageService();
          ODPS.getAllPages(promise)
            .then(
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        getSystemsByInstalls: function (
          dealerId,
          hardwareModel,
          commType,
          startDate,
          endDate,
          daysAgo,
          installType,
          installDate,
          installer,
          salesPerson,
          useInstallDate,
          tagId
        ) {
          var deferred = $q.defer();
          let timeFieldName = useInstallDate ? "Install Date" : "Created";
          let timeField = useInstallDate ? "installed_at" : "created_at";

          var _this = this;
          var columns = [
            { name: "Installer", field: "primary_installer_full_name" },
            {
              name: "Installer Email",
              field: "primary_installer_email_address",
              visible: false,
            },
            { name: "Salesperson", field: "sales_person_full_name" },
            {
              name: "Salesperson Email",
              field: "sales_person_email_address",
              visible: false,
            },
            { name: "System Name", field: "name" },
            {
              name: "System type",
              field: "panels[0].hardware_model",
              type: "hardware_model",
            },
            {
              name: "Connection type",
              field: "panels[0].comm_type",
              type: "comm_type",
            },
            { name: timeFieldName, field: timeField, type: "datetime_tz_12" },
            {
              name: "Installation Type",
              field: "installation_type",
              type: "string_titlecase",
            },
            { name: "Account Number", field: "panels[0].account_number" },
            { name: "Customer", field: "customer.name" },
            {
              name: "Serial",
              field: "panels[0].serial_number",
              visible: false,
            },
            { name: "Email", field: "customer.email", visible: false },
            { name: "Postal Code", field: "postal_code", visible: false },
            { name: "Address", field: "address_1", visible: false },
            { name: "Address 2", field: "address_2", visible: false },
            { name: "City", field: "city", visible: false },
            { name: "State", field: "state", visible: false },
            { name: "Country", field: "country", visible: false },
          ];
          _this.HeaderMap = columns;

          let expand = "&$expand=panels,customer($select=name,id)";

          let queryFilter = this.buildQueryFilter(
            "*",
            "*",
            startDate,
            "",
            endDate,
            daysAgo,
            "*",
            useInstallDate
          );

          //need to do filter for comm type and hardware model with "any" because of the expand.
          if (
            angular.isDefined(hardwareModel) &&
            hardwareModel !== "*" &&
            hardwareModel != null
          ) {
            queryFilter =
              queryFilter +
              "panels/any(p:p/hardware_model eq '" +
              hardwareModel +
              "') and ";
          }

          if (
            angular.isDefined(commType) &&
            commType !== "*" &&
            commType != null
          ) {
            queryFilter =
              queryFilter +
              "panels/any(p:p/comm_type eq '" +
              commType +
              "') and ";
          }

          let installFilter = "";

          if (
            angular.isDefined(installType) &&
            installType !== "*" &&
            installType !== "" &&
            installType != null
          ) {
            installFilter =
              installFilter +
              "installation_type eq SecureCom.Models.Enums.installation_type'" +
              installType +
              "' and ";
          }

          if (
            angular.isDefined(salesPerson) &&
            salesPerson !== "*" &&
            salesPerson != null
          ) {
            installFilter =
              installFilter + "sales_person_id eq " + salesPerson + " and ";
          }

          if (
            angular.isDefined(installer) &&
            installer !== "*" &&
            installer != null
          ) {
            installFilter =
              installFilter + "primary_installer_id eq " + installer + " and ";
          }

          installFilter = installFilter.replace(/ and $/, "");

          if (queryFilter) {
            queryFilter = "&$filter=" + queryFilter + installFilter;
          }

          queryFilter = queryFilter.replace(/ and $/, "");

          if (tagId) {
            queryFilter += `&tag_id=${tagId}`;
          }

          const url = `${
            PROPS.reportsApiUrl
          }/api/v1/dealers(${dealerId})/vk.GetControlSystems?${
            expand + queryFilter
          }`;

          this.OdataQuery = encodeURI(url);
          var promise = $http.get(url);

          var ODPS = new OdataPageService();
          ODPS.getAllPages(promise)
            .then(
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        //TODO: Add endDate or now() function call into query
        getTroubleEvents: function (
          dealerID,
          troubles,
          hardwareModel,
          commType,
          startDate,
          endDate,
          daysAgo,
          tagId
        ) {
          var deferred = $q.defer();
          var _this = this;
          var queryFilter = "";
          var columns = [
            { name: "Customer", field: "customer_name" },
            { name: "System Name", field: "control_system_name" },
            { name: "Prefix", field: "panel_account_prefix" },
            { name: "Account", field: "panel_account_number" },
            {
              name: "System Type",
              field: "panel_hardware_model",
              type: "hardware_model",
            },
            { name: "Serial", field: "panel_serial_number" },
            { name: "Event", field: "subcategory_display_name" },
            { name: "Message", field: "event_history_message" },
            {
              name: "Event Time",
              field: "event_event_at",
              type: "datetime_raw_12",
            },
            { name: "Email", field: "customer_email", visible: false },
            {
              name: "Postal Code",
              field: "control_system_postal_code",
              visible: false,
            },
            {
              name: "Address",
              field: "control_system_address_1",
              visible: false,
            },
            {
              name: "Address 2",
              field: "control_system_address_2",
              visible: false,
            },
            { name: "City", field: "control_system_city", visible: false },
            { name: "State", field: "control_system_state", visible: false },
            {
              name: "Country",
              field: "control_system_country",
              visible: false,
            },
          ];
          this.HeaderMap = columns;

          queryFilter = this.buildQueryFilter(
            hardwareModel,
            commType,
            startDate,
            "",
            endDate,
            daysAgo
          );

          // Loop through the troubles and create the filters
          var troublePrefix = "subcategory_group eq 'Troubles' and ";
          var troubleFilter = "";
          if (Object.keys(troubles).length > 0) {
            angular.forEach(troubles, function (value, trouble) {
              if (value === true)
                troubleFilter =
                  troubleFilter +
                  " " +
                  "subcategory_id eq " +
                  _this.TROUBLE_TYPES[trouble].subcategory_id +
                  " or ";
            });
            troubleFilter = troubleFilter.replace(/ or $/, "");
          }

          troubleFilter = troubleFilter
            ? troublePrefix + "( " + troubleFilter + ")"
            : troublePrefix;

          // Merge the filters and clean up the trailing and...
          queryFilter = queryFilter + troubleFilter;
          queryFilter = queryFilter.replace(/ or $/, "");
          queryFilter = queryFilter.replace(/ and $/, "");

          if (tagId) {
            queryFilter += `&tag_id=${tagId}`;
          }

          var filter = "&$filter=" + queryFilter;

          const url =
            `${PROPS.oDataEventReportsUrl}/api/v1/dealers(${dealerID})/vk.GetReportEvents()?` +
            `$select=panel_id,panel_hardware_model,panel_comm_type,panel_serial_number,panel_account_prefix,` +
            `panel_account_number,control_system_id,control_system_name,control_system_postal_code,` +
            `control_system_address_1,control_system_address_2,control_system_city,control_system_state,` +
            `control_system_country,customer_name,customer_email,event_id,event_message,subcategory,` +
            `subcategory_display_name,event_event_at,event_history_message${filter}`;

          this.OdataQuery = encodeURI(url);
          var promise = $http.get(url);

          var ODPS = new OdataPageService();
          ODPS.getAllPages(promise)
            .then(
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        buildQueryFilter: function (
          hardwareModel,
          commType,
          startDate,
          panelObject,
          endDate,
          daysAgo,
          hardwareModelName,
          useInstallDate
        ) {
          if (panelObject) panelObject = panelObject + "/";
          var dateField, hardwareField, commField, hardwareNameField;
          if (this.ConfigData.report_type === "troubles") {
            dateField = "event_event_at";
            hardwareField = "panel_hardware_model";
            commField = "panel_comm_type";
            hardwareNameField = "panel_hardware_model";
          } else {
            dateField = "created_at";
            hardwareField = "hardware_model";
            hardwareNameField = "hardware_model_name";
            commField = "comm_type";
          }

          if (this.ConfigData.report_type === "installs" && useInstallDate) {
            dateField = "installed_at";
          }

          var queryFilter = "";
          if (
            angular.isDefined(startDate) &&
            startDate !== null &&
            startDate !== ""
          ) {
            var startDateObject = new Date(startDate);
            queryFilter =
              dateField + " gt " + startDateObject.toISOString() + " and ";
          }
          if (
            angular.isDefined(daysAgo) &&
            daysAgo !== null &&
            daysAgo !== "" &&
            daysAgo !== "0"
          ) {
            queryFilter = dateField + " gt now(-" + daysAgo + ") and ";
          }
          if (angular.isDefined(hardwareModel) && hardwareModel !== "*") {
            queryFilter =
              queryFilter +
              panelObject +
              hardwareField +
              " eq '" +
              hardwareModel +
              "' and ";
          }
          if (
            angular.isDefined(hardwareModelName) &&
            hardwareModelName !== "*" &&
            this.ConfigData.report_type !== "troubles"
          ) {
            queryFilter =
              queryFilter +
              panelObject +
              hardwareNameField +
              " eq '" +
              hardwareModelName +
              "' and ";
          }
          if (angular.isDefined(commType) && commType !== "*") {
            queryFilter =
              queryFilter +
              panelObject +
              commField +
              " eq '" +
              commType +
              "' and ";
          }
          return queryFilter;
        },

        extendMe: function (reportData) {
          angular.extend(this, reportData);
        },

        fixCronExpression: function () {
          var cron = this.CronExpression.replace(/\s+/g, " ").split(" ");
          cron[2] = this.MeridianTimeDisplay.hour;
          if (cron[2] === 12) {
            cron[2] = 0;
          }
          if (this.MeridianTimeDisplay.indicator === "PM") {
            cron[2] += 12;
          }
          if (parseInt(cron[3]) > 28) {
            cron[3] = "L";
          }
          this.CronExpression = cron.join(" ");
        },
      });

      if (
        customReportObject !== null &&
        angular.isDefined(customReportObject)
      ) {
        this.extendMe(customReportObject);
        this.ConfigData = angular.fromJson(this.ConfigData);
        this.CustomRequest = angular.fromJson(this.CustomRequest);
        this.init();
      } else {
        this.init();
        this.setDefaults();
      }
    };

    return customReport;
  },
]);
