import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { SystemType } from "securecom-graphql/client";
import {
  useHardwareModel,
  useSystemOptionsSystemType,
} from "../../PanelContext";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationAreaSchedulesField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationAreaSchedulesField_systemAreaInformation.graphql";

export const systemAreaInformationAreaSchedulesFieldId = () =>
  `system-area-information-area-schedules`;

function SystemAreaInformationAreaSchedulesField() {
  const [{ areaSchedules }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationAreaSchedulesField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationAreaSchedulesField_systemAreaInformation on SystemAreaInformation {
          id
          areaSchedules
        }
      `
    );

  const hardwareModel = useHardwareModel();
  const systemType = useSystemOptionsSystemType();
  const { isXt75 } = resolvePanelType(hardwareModel);
  const disabled = isXt75 && systemType !== SystemType.AREA;

  const fieldId = systemAreaInformationAreaSchedulesFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Area Schedules"
      tooltip={`If enabled, each Area can have its own ${
        isXt75 ? "Schedule" : "set of Schedules"
      }. Otherwise, all Areas follow the Schedule assigned to Area 1.`}
    >
      <Switch
        label="Area Schedules"
        id={fieldId}
        checked={areaSchedules}
        disabled={disabled}
        onChange={() => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(!areaSchedules, "areaSchedules");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationAreaSchedulesField;
