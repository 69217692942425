/**
 * @generated SignedSource<<94ad9807461c480b3128ef32e3619db2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE";
import { FragmentRefs } from "relay-runtime";
export type SiteDiagnosticsEasyConnectStatusFragment_controlSystem$data = {
  readonly easyConnectStatus: {
    readonly lastCheckIn: string | null;
    readonly status: ConnectionStatus;
  } | null;
  readonly hasEasyConnect: boolean;
  readonly id: string;
  readonly online: boolean;
  readonly " $fragmentType": "SiteDiagnosticsEasyConnectStatusFragment_controlSystem";
};
export type SiteDiagnosticsEasyConnectStatusFragment_controlSystem$key = {
  readonly " $data"?: SiteDiagnosticsEasyConnectStatusFragment_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsEasyConnectStatusFragment_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteDiagnosticsEasyConnectStatusFragment_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEasyConnect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemEasyConnectStatus",
      "kind": "LinkedField",
      "name": "easyConnectStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastCheckIn",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};

(node as any).hash = "c18b3fb5f8008f673658a0fe6fd8c0c4";

export default node;
