import React from "react";
import styled from "styled-components";
import { defaultTheme } from "../../app/common/DaColors";

const RecorderMegapixelProgressBar = ({
  megapixelsEnabled,
  megapixelThresholdWarning,
  megapixelThresholdCritical,
  supportedMegapixels,
  backgroundColor = defaultTheme.backgroundColor,
}: {
  megapixelsEnabled: number;
  megapixelThresholdWarning: boolean;
  megapixelThresholdCritical: boolean;
  supportedMegapixels: number;
  backgroundColor?: string;
}) => {
  const megapixelsPercentage = (megapixelsEnabled / supportedMegapixels) * 100;

  let progressColor: string;
  if (megapixelThresholdCritical) {
    progressColor = defaultTheme.failure;
  } else if (megapixelThresholdWarning) {
    progressColor = defaultTheme.caution;
  } else {
    progressColor = defaultTheme.success;
  }

  return (
    <div>
      <BarWrapper>
        <BackgroundBar color={backgroundColor} />
        <ProgressBar
          widthPercentage={Math.min(100, megapixelsPercentage)}
          color={progressColor}
        />
      </BarWrapper>
      <Label>
        <div>
          {megapixelsEnabled}/{supportedMegapixels} MP
        </div>
      </Label>
    </div>
  );
};

const BarWrapper = styled.div`
  position: relative;
`;
const BackgroundBar = styled.div<{ color: string }>`
  height: 0.8rem;
  border-radius: 0.4rem;
  width: 100%;
  background-color: ${(props) => props.color};
  padding-top: 0.8rem;
`;
const ProgressBar = styled.div<{ widthPercentage: number; color: string }>`
  position: absolute;
  top: 0;
  height: 0.8rem;
  border-radius: 0.4rem;
  width: ${(props) => props.widthPercentage}%;
  background-color: ${(props) => props.color};
`;
const Label = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 1.2rem;
`;

export default RecorderMegapixelProgressBar;
