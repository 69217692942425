import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";
import LoadingSpinner from "components/LoadingSpinner";
import React, { useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import styled from "styled-components";
import { SiteControlSystemJobStatusBadge_siteControlSystem$key } from "./__generated__/SiteControlSystemJobStatusBadge_siteControlSystem.graphql";
import { SiteControlSystemJobStatusBadge_siteControlSystemQuery } from "./__generated__/SiteControlSystemJobStatusBadge_siteControlSystemQuery.graphql";

const JobStatusBadge = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  height: 2rem;
  width: 15rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  font-weight: 700;
`;

export const SiteControlSystemJobStatusBadge = ({
  status,
}: {
  status: boolean;
}) => {
  return status ? (
    <JobStatusBadge color="mediumseagreen">Changes Pending</JobStatusBadge>
  ) : null;
};

export const SiteControlSystemJobStatusBadgeWithAutoRefresh = ({
  siteControlSystem,
}: {
  siteControlSystem: SiteControlSystemJobStatusBadge_siteControlSystem$key;
}) => {
  const [data, refetch] = useRefetchableFragment<
    SiteControlSystemJobStatusBadge_siteControlSystemQuery,
    SiteControlSystemJobStatusBadge_siteControlSystem$key
  >(
    graphql`
      fragment SiteControlSystemJobStatusBadge_siteControlSystem on SiteControlSystem
      @refetchable(
        queryName: "SiteControlSystemJobStatusBadge_siteControlSystemQuery"
      ) {
        id
        type
        hasPendingChanges
        hasFailedPreProgrammingJob
        online
      }
    `,
    siteControlSystem
  );

  const { hasPendingChanges, online, hasFailedPreProgrammingJob } = data;

  useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (hasPendingChanges) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetch({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 10000));
        }, 10000)
      );
    }
    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [hasPendingChanges, refetch]);

  return hasPendingChanges ? (
    <JobStatusBadge color="mediumseagreen">
      <LoadingSpinner style={{ marginRight: ".5rem" }} /> Changes Pending
    </JobStatusBadge>
  ) : hasFailedPreProgrammingJob ? (
    <JobStatusBadge color="red">Programming Failed</JobStatusBadge>
  ) : !online ? (
    <JobStatusBadge color="grey">
      <Icon name="history" style={{ marginRight: ".5rem" }} />
      Pre-Programmed
    </JobStatusBadge>
  ) : null;
};
