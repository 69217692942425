/**
 * @ngdoc object
 * @name App.factory:timeScheduleModel
 * @function
 * @requires UserService
 * @requires PROPS
 * @requires $timeout
 * @requires JobService
 * @requires $q
 * @requires $filter
 *
 * @description
 * Factory which returns a timeScheduleModel Object. The model is built from the time_schedules API response, and
 * has helper methods which allow you to create and update a time_schedule
 *
 */
App.factory("timeScheduleModel", [
  "UserService",
  "PROPS",
  "$timeout",
  "JobService",
  "baseScheduleModel",
  "scheduleUtilService",
  function (
    UserService,
    PROPS,
    $timeout,
    JobService,
    baseScheduleModel,
    scheduleUtilService
  ) {
    var timeScheduleModel = function (time_schedule) {
      // Setup any init value and methods
      angular.extend(this, {
        isBusy: false, // Indicates if a long running API job is currently active on the current model
        panelFamily: "XR550",
        beginFieldName: "_open",
        endFieldName: "_close",

        /**
         * @ngdoc object
         * @name method:initSchedule
         * @methodOf App.factory:timeScheduleModel
         *
         * @description
         * Initialize a new time_schedule
         *
         */
        initSchedule: function () {
          this.beginColumn = this.beginFieldName;
          this.endColumn = this.endFieldName;

          // Set the full field names, for use with error handling on the form
          for (var x = 0; x < this.days.length; x++) {
            this.days[x].beginField = this.days[x].prefix + this.beginColumn;
            this.days[x].endField = this.days[x].prefix + this.endColumn;
          }

          var _this = this;
          angular.forEach(scheduleUtilService.holidays, function (hol) {
            var holiday = angular.copy(hol);
            holiday.beginField = holiday.prefix + _this.beginColumn;
            holiday.endField = holiday.prefix + _this.endColumn;
            _this.days.push(holiday);
          });

          // Pre-create the necessary attributes
          this.doors = [];
          this.areas = [];
          this.outputs = [];
          this.favorites = [];

          // Initialize days (Inherited from base schedule model)
          this.initDays("beginField", "begin_date");
          this.initDays("endField", "end_date");

          // Initialize Temp Schedule begin and end dates
          if (this.begin_date && this.end_date) {
            this.begin_date = dateTimeForceUTC(new Date(this.begin_date));
            this.end_date = dateTimeForceUTC(new Date(this.end_date));
          }
        },

        /**
         * @ngdoc object
         * @name method:extendMe
         * @methodOf App.factory:TimeScheduleModel
         *
         * @description
         * Extend the time schedule object with the sent data
         */
        extendMe: function (scheduleData) {
          angular.extend(this, scheduleData);
        },
      });

      this.extendMe(new baseScheduleModel()); // Extend the base schedule model
      if (time_schedule !== null && angular.isDefined(time_schedule)) {
        this.extendMe(time_schedule);
      }
    };

    return timeScheduleModel;
  },
]);
