import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsLateToCloseOutputField_outputOptions$key } from "./__generated__/OutputOptionsLateToCloseOutputField_outputOptions.graphql";

export const outputOptionsLateToCloseOutputFieldId = () =>
  "output-options-late-to-close-output";

function OutputOptionsLateToCloseOutputField() {
  const [{ lateToCloseOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsLateToCloseOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsLateToCloseOutputField_outputOptions on OutputOptions {
          id
          lateToCloseOutput {
            id
            number
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsLateToCloseOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const originalValue = React.useRef(lateToCloseOutput.formattedNumber).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Late To Close Output"
      tooltip="Output turns on if a closing schedule expires without all Areas Armed. Turns off when all Areas are Armed, closing is extended, or schedule changed."
    >
      <TextInput
        id={fieldId}
        value={lateToCloseOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "lateToCloseOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "lateToCloseOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsLateToCloseOutputField;
