/**
 * @generated SignedSource<<64772496ddbd55c801b2591f20d72e77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KeyfobSerialNumberField_SerialNumberList_panel$data = {
  readonly keyfobs: ReadonlyArray<{
    readonly id: string;
    readonly keyfobSerialNumber: string;
  }>;
  readonly " $fragmentType": "KeyfobSerialNumberField_SerialNumberList_panel";
};
export type KeyfobSerialNumberField_SerialNumberList_panel$key = {
  readonly " $data"?: KeyfobSerialNumberField_SerialNumberList_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobSerialNumberField_SerialNumberList_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobSerialNumberField_SerialNumberList_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Keyfob",
      "kind": "LinkedField",
      "name": "keyfobs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keyfobSerialNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "a21ff396afb1683a8ebd91b9d4fae87b";

export default node;
