/**
 * @generated SignedSource<<38a0a0a1cb70502d26cf0c1a4b1f8dfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteDiagnosticsQuery$variables = {
  siteId: string;
};
export type SiteDiagnosticsQuery$data = {
  readonly node: {
    readonly controlSystems?: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsControlSystemFragment_controlSystem">;
    }>;
    readonly id?: string;
    readonly name?: string;
  } | null;
};
export type SiteDiagnosticsQuery = {
  response: SiteDiagnosticsQuery$data;
  variables: SiteDiagnosticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastCheckIn",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteDiagnosticsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteControlSystem",
                "kind": "LinkedField",
                "name": "controlSystems",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SiteDiagnosticsControlSystemFragment_controlSystem"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteDiagnosticsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteControlSystem",
                "kind": "LinkedField",
                "name": "controlSystems",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "softwareDisplayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isX1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isX8",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serialNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasEasyConnect",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCell",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "online",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPendingChanges",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteDoor",
                    "kind": "LinkedField",
                    "name": "doors",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "siteDoorNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "connectionStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ControlSystemEasyConnectStatus",
                    "kind": "LinkedField",
                    "name": "easyConnectStatus",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ControlSystemCellStatus",
                    "kind": "LinkedField",
                    "name": "cellStatus",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "commType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29f42c1f1886821f9bd83e181b9103ab",
    "id": null,
    "metadata": {},
    "name": "SiteDiagnosticsQuery",
    "operationKind": "query",
    "text": "query SiteDiagnosticsQuery(\n  $siteId: ID!\n) {\n  node(id: $siteId) {\n    __typename\n    ... on Site {\n      id\n      name\n      controlSystems {\n        id\n        ...SiteDiagnosticsControlSystemFragment_controlSystem\n      }\n    }\n    id\n  }\n}\n\nfragment SiteDiagnosticsCellStatusFragment_controlSystem on SiteControlSystem {\n  id\n  commType\n  hasCell\n  online\n  cellStatus {\n    status\n    lastCheckIn\n  }\n}\n\nfragment SiteDiagnosticsCommunicationStatusFragment_controlSystem on SiteControlSystem {\n  id\n  hasEasyConnect\n  online\n  easyConnectStatus {\n    status\n  }\n  hasCell\n  cellStatus {\n    status\n  }\n}\n\nfragment SiteDiagnosticsControlSystemFragment_controlSystem on SiteControlSystem {\n  id\n  name\n  softwareDisplayName\n  isX1\n  isX8\n  serialNumber\n  hasEasyConnect\n  hasCell\n  online\n  hasPendingChanges\n  doors {\n    id\n    name\n    siteDoorNumber\n    ...SiteDiagnosticsDoorFragment_door\n  }\n  ...SiteDiagnosticsCommunicationStatusFragment_controlSystem\n  ...SiteDiagnosticsEasyConnectStatusFragment_controlSystem\n  ...SiteDiagnosticsCellStatusFragment_controlSystem\n}\n\nfragment SiteDiagnosticsDoorFragment_door on SiteDoor {\n  name\n  connectionStatus\n  number\n}\n\nfragment SiteDiagnosticsEasyConnectStatusFragment_controlSystem on SiteControlSystem {\n  id\n  hasEasyConnect\n  online\n  easyConnectStatus {\n    status\n    lastCheckIn\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2da05e4b1bc11e99526ea094c64eb32";

export default node;
