import graphql from "babel-plugin-relay/macro";
import React, { useEffect } from "react";
import { useFragment, useRefetchableFragment } from "react-relay/hooks";

import {
  useEventEmitter,
  useOpenHIkvisionNvrModal,
  useUserCanEditVideoDevices,
} from "components/SiteForm/EntryPointContext";
import Icon from "./Components/Icon";
import { PillButton } from "./Components/PillButton";
import SuspenseBoundary from "./Components/SuspenseBoundary";
import styles from "./VideoForm.module.css";
import Grid from "./VideoSection";
import { HikvisionNvrsNvr_site$key } from "./__generated__/HikvisionNvrsNvr_site.graphql";
import { HikvisionNvrs_hikvisionNvr$key } from "./__generated__/HikvisionNvrs_hikvisionNvr.graphql";
import { HikvisionNvrs_site$key } from "./__generated__/HikvisionNvrs_site.graphql";

export function NvrHeaderLabel({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className="nvr-title">
      {title}
      <span className="nvr-data">{children}</span>
    </div>
  );
}

function HikvisionNvr({
  hikvisionNvrRef,
  siteRef,
}: {
  hikvisionNvrRef: HikvisionNvrs_hikvisionNvr$key;
  siteRef: HikvisionNvrsNvr_site$key;
} & React.HTMLProps<HTMLElement>) {
  const { billingControlSystemId } = useFragment(
    graphql`
      fragment HikvisionNvrsNvr_site on Site {
        billingControlSystemId
      }
    `,
    siteRef
  );
  const [nvr] = useRefetchableFragment(
    graphql`
      fragment HikvisionNvrs_hikvisionNvr on HikvisionNvr
      @refetchable(queryName: "VideoFormHikvisionNvrRefetchQuery") {
        id
        name
        host
        streamingPort
        managementPort
        status
        serialNumber
        model
        channels {
          id
          name
          number
        }
      }
    `,
    hikvisionNvrRef
  );

  const openHikvisionNvrModal = useOpenHIkvisionNvrModal();
  const userCanEditVideoDevices = useUserCanEditVideoDevices();

  return (
    <div className="col-xs-12 pl0 pr0">
      <div className="table-responsive">
        <div className="video-device__header--nvr">
          <NvrHeaderLabel title="Name: ">{nvr.name}</NvrHeaderLabel>
          <NvrHeaderLabel title="Model: ">{nvr.model}</NvrHeaderLabel>
          {nvr.status && (
            <NvrHeaderLabel title="Activation Status:">
              {nvr.status}
            </NvrHeaderLabel>
          )}

          <div className="video-device__header--nvr__button-area">
            {userCanEditVideoDevices && (
              <button
                type="button"
                className="btn btn-dmp btn-sm"
                onClick={() => openHikvisionNvrModal(billingControlSystemId)}
              >
                Edit
              </button>
            )}
          </div>
        </div>

        {nvr.channels.length && (
          <table
            style={{ width: "100%" }}
            className="display table table-striped table-fixed--not-mobile"
          >
            <thead>
              <tr>
                <th className="subdued w50">Channel Number</th>
                <th className="subdued w50">Channel Name</th>
              </tr>
            </thead>
            <tbody>
              {nvr.channels.map((channel) => {
                return (
                  <tr key={channel.number}>
                    <td className="text-left">
                      <span className="link link-primary table-emphasis">
                        {channel.number}
                      </span>
                    </td>
                    <td className="text-left">
                      <span>{channel.name}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div className="clearfix"></div>
      </div>
      <div className="btn-wrapper pull-right"></div>
    </div>
  );
}

export default function HikvisionNvrs({
  siteRef,
}: {
  siteRef: HikvisionNvrs_site$key;
}) {
  const openHikvisionNvrModal = useOpenHIkvisionNvrModal();
  const hikEventEmitter = useEventEmitter();
  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment HikvisionNvrs_site on Site
      @refetchable(queryName: "VideoFormHikvisionNvrsRefetchQuery") {
        ...HikvisionNvrsNvr_site
        billingControlSystemId
        hikvisionNvrs {
          id
          ...HikvisionNvrs_hikvisionNvr
        }
      }
    `,
    siteRef
  );

  useEffect(() => {
    function refresh() {
      refetch({}, { fetchPolicy: "network-only" });
    }
    hikEventEmitter.on("hik-changed", refresh);
    return () => {
      hikEventEmitter.off("hik-changed", refresh);
    };
  }, []);

  return (
    <Grid>
      <Grid.Header>
        <Icon className="icon-server_2" />
        NVR's
        <div className={styles["securecom-add-button"]}>
          <PillButton
            onClick={() => openHikvisionNvrModal(data.billingControlSystemId)}
          />
        </div>
      </Grid.Header>
      {data.hikvisionNvrs.map((hikvisionNvr) => (
        <SuspenseBoundary key={hikvisionNvr.id}>
          <HikvisionNvr siteRef={data} hikvisionNvrRef={hikvisionNvr} />
        </SuspenseBoundary>
      ))}
    </Grid>
  );
}
