import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay/hooks";
import { useUserCanEditVideoDevices } from "../EntryPointContext";
import { Sheet } from "../Layout/Layout";
import SuspenseBoundary from "./VideoForm/Components/SuspenseBoundary";
import VideoSelection from "./VideoForm/Components/VideoSelection";
import HikvisionNvrs from "./VideoForm/HikvisionNvrs";
import { HikvisionNvrsForm_site$key } from "./__generated__/HikvisionNvrsForm_site.graphql";

export default function HikvisionNvrsForm({
  siteRef,
}: {
  siteRef: HikvisionNvrsForm_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment HikvisionNvrsForm_site on Site {
        ...HikvisionNvrs_site
      }
    `,
    siteRef
  );

  const userCanEditVideoDevices = useUserCanEditVideoDevices();

  return (
    <Sheet>
      <VideoSelection>
        <VideoSelection.Description title={<>Hikvision&reg; NVR</>} />
      </VideoSelection>

      {userCanEditVideoDevices && (
        <VideoSelection.Section>
          <SuspenseBoundary>
            <HikvisionNvrs siteRef={data} />
          </SuspenseBoundary>
        </VideoSelection.Section>
      )}
    </Sheet>
  );
}
