/**
 * @generated SignedSource<<7841aa502a2d783609ef53b691a2cab0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NetworkOptionsWifiSecurity = "NONE" | "UNSUPPORTED" | "WEP" | "WPA";
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly networkOptions: {
      readonly __typename: "NetworkOptions";
      readonly ddpPassphrase: string;
      readonly dhcpEnabled: boolean;
      readonly dnsServer: string;
      readonly enableDdp: boolean;
      readonly gatewayAddress: string;
      readonly id: string;
      readonly ipv6Address: string;
      readonly ipv6Dns: string;
      readonly ipv6Enabled: boolean;
      readonly ipv6Gateway: string;
      readonly ipv6Prefix: string;
      readonly listenPort734N: string;
      readonly listenPort734NMax: number;
      readonly listenPort734NMin: number;
      readonly localIpAddress: string;
      readonly passphrase: string;
      readonly passphrase734N: string;
      readonly programmingPort: string;
      readonly subnetMask: string;
      readonly wifiPassphrase: string;
      readonly wifiPassphraseMaxLength: number;
      readonly wifiSecurity: NetworkOptionsWifiSecurity;
      readonly wifiSsid: string;
    } | null;
  };
  readonly " $fragmentType": "TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem";
};
export type TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "6cf0363dd14cce8c5cf42e2a3844e12b";

export default node;
