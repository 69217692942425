/**
 * @generated SignedSource<<617dc1e09d1114aa513dded3d7f663aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ControlSystemContextUseVarEnabled_controlSystem$data = {
  readonly varEnabled: boolean | null;
  readonly " $fragmentType": "ControlSystemContextUseVarEnabled_controlSystem";
};
export type ControlSystemContextUseVarEnabled_controlSystem$key = {
  readonly " $data"?: ControlSystemContextUseVarEnabled_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseVarEnabled_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ControlSystemContextUseVarEnabled_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "varEnabled",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "39130fe9dd722f3768f561b993fa649a";

export default node;
