/**
 * @generated SignedSource<<4df1c8be81c4e1cdd9f5068c375fc22f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationArmedOpenMessageField_zone$data = {
  readonly armedOpenActionMessage: ZoneMessage | null;
  readonly number: string;
  readonly type: ZoneType;
  readonly " $fragmentType": "ZoneInformationArmedOpenMessageField_zone";
};
export type ZoneInformationArmedOpenMessageField_zone$key = {
  readonly " $data"?: ZoneInformationArmedOpenMessageField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationArmedOpenMessageField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationArmedOpenMessageField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedOpenActionMessage",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "c4c3eaa1020cf9febe2cdb306c59e6c0";

export default node;
