/**
 * @generated SignedSource<<546f453721f72c4d5d352b7a247fe00a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelFirmwareStatus = "UPDATES_AVAILABLE" | "UPDATES_FAILED" | "UPDATES_IN_PROGRESS" | "UPDATES_SUCCESSFUL" | "UP_TO_DATE";
import { FragmentRefs } from "relay-runtime";
export type SiteFirmwareUpdateDialogue_site$data = {
  readonly controlSystems: ReadonlyArray<{
    readonly firmwareStatus: PanelFirmwareStatus;
    readonly firmwareUpdateDismissed: boolean | null;
    readonly firmwareUpdateProgress: number | null;
    readonly id: string;
    readonly serialNumber: string;
  }>;
  readonly firmwareUpdateDismissed: boolean | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SiteFirmwareUpdateDialogue_UpdateProgress_site">;
  readonly " $fragmentType": "SiteFirmwareUpdateDialogue_site";
};
export type SiteFirmwareUpdateDialogue_site$key = {
  readonly " $data"?: SiteFirmwareUpdateDialogue_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteFirmwareUpdateDialogue_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firmwareUpdateDismissed",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteFirmwareUpdateDialogue_site",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firmwareStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firmwareUpdateProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteFirmwareUpdateDialogue_UpdateProgress_site"
    },
    (v1/*: any*/)
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "2d640c9cf346d92432079d68cc1f5494";

export default node;
