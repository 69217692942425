import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplayAuxiliary2ZoneKeypadsField_statusListDisplay$key } from "./__generated__/StatusListDisplayAuxiliary2ZoneKeypadsField_statusListDisplay.graphql";

export const statusListDisplayAuxiliary2ZoneKeypadsFieldId = () =>
  "status-list-display-auxiliary-2-zone-keypads";

function StatusListDisplayAuxiliary2ZoneKeypadsField() {
  const [{ auxiliary2ZoneKeypads }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplayAuxiliary2ZoneKeypadsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplayAuxiliary2ZoneKeypadsField_statusListDisplay on StatusListDisplay {
          auxiliary2ZoneKeypads
        }
      `
    );

  const fieldId = statusListDisplayAuxiliary2ZoneKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Auxiliary 2 Zone Keypads"
      tooltip="Configure which keypad addresses show Auxiliary 2 Zone Alarms and Troubles."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={auxiliary2ZoneKeypads}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "auxiliary2ZoneKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "auxiliary2ZoneKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplayAuxiliary2ZoneKeypadsField;
