import { OrderedMap, OrderedSet } from "immutable";
import PropTypes from "prop-types";
import { seconds } from "../../../utils/dates";
import {
  GarageIcon,
  LightIcon,
  KeyIcon,
  TemperatureIcon,
  PowerIcon,
} from "../Icons";
export const TOP_BAR_HEIGHT = 70;
export const MENU_Z_INDEX = 10;
export const GUTTER = 10;
export const GUTTER_LARGE = 20;
export const BORDER_RADIUS = "4px";
export const CUBIC_BEZIER = "cubic-bezier(0.63, 0.64, 0.3, 1)";
export const SYSTEM_TYPES = {
  XR: "XR",
  XT: "XT",
};
export const CONNECTION_LENGTH_IN_MINUTES = 3;
export const DEVICE_TYPES = {
  BARRIER_OPERATOR: "BARRIER_OPERATOR",
  LIGHT: "LIGHT",
  LOCK: "LOCK",
  THERMOSTAT: "THERMOSTAT",
  APPLIANCE: "APPLIANCE",
};
export const DEVICE_TYPE_NAMES = {
  [DEVICE_TYPES.BARRIER_OPERATOR]: "garage door",
  [DEVICE_TYPES.LIGHT]: "light",
  [DEVICE_TYPES.LOCK]: "lock",
  [DEVICE_TYPES.THERMOSTAT]: "thermostat",
  [DEVICE_TYPES.APPLIANCE]: "appliance",
};
export const ICON_COMPONENTS_BY_DEVICE_TYPE = {
  [DEVICE_TYPES.BARRIER_OPERATOR]: GarageIcon,
  [DEVICE_TYPES.LIGHT]: LightIcon,
  [DEVICE_TYPES.LOCK]: KeyIcon,
  [DEVICE_TYPES.THERMOSTAT]: TemperatureIcon,
  [DEVICE_TYPES.APPLIANCE]: PowerIcon,
};
export const deviceTypePropType = PropTypes.oneOf(
  Object.keys(DEVICE_TYPES).map((key) => DEVICE_TYPES[key])
);
export const BREAKPOINTS = OrderedMap([
  ["xxxxxlarge", 2000],
  ["xxxxlarge", 1620],
  ["xxxlarge", 1440],
  ["xxlarge", 1220],
  ["xlarge", 1024],
  ["large", 960],
  ["medium", 768],
  ["small", 600],
  ["xsmall", 480],
  ["xxsmall", 320],
  ["base", 0],
]);
export const VIDEO_CHANNEL_CLIP_RECORD_LENGTH = seconds(20);
export const DOOR_TYPES = {
  DOOR: "734_DOOR",
  LOCK: "LOCK",
  BARRIER_OPERATOR: "BARRIER_OPERATOR",
};
export const SCHEDULE_TYPES = {
  XT_ARMING: "XT_ARMING",
  XT_FAVORITE: "XT_FAVORITE",
  XT_OUTPUT: "XT_OUTPUT",
  XR: "XR",
};
export const FOOTNOTE_SYMBOL = "†";
export const TROUBLE_SUBCATEGORY_IDS = {
  zoneOrDeviceTrouble: 23,
  zwaveDeviceLowBattery: 30,
  acPowerFailure: 31,
  acPowerRestored: 32,
  systemLowBattery: 34,
  systemTest: 36,
  zoneInactivity: 77,
};
export const ALL_TROUBLE_IDS = OrderedSet.of(
  TROUBLE_SUBCATEGORY_IDS.zoneOrDeviceTrouble,
  TROUBLE_SUBCATEGORY_IDS.zwaveDeviceLowBattery,
  TROUBLE_SUBCATEGORY_IDS.acPowerFailure,
  TROUBLE_SUBCATEGORY_IDS.acPowerRestored,
  TROUBLE_SUBCATEGORY_IDS.systemLowBattery,
  TROUBLE_SUBCATEGORY_IDS.systemTest,
  TROUBLE_SUBCATEGORY_IDS.zoneInactivity
);
export const ALARMS_SUBCATEGORY_IDS = {
  BURG: 1,
  ALARM_CANCELLED: 13,
  ALARM_VERIFIED: 14,
  AMBUSH: 15,
  EMERGENCY: 24,
  FIRE: 25,
  PANIC: 26,
  BASE: 28,
  SUPERVISORY: 79,
  CO: 83,
};
export const ALL_ALARMS_SUBCATEGORY_IDS = OrderedSet.of(
  ALARMS_SUBCATEGORY_IDS.BURG,
  ALARMS_SUBCATEGORY_IDS.ALARM_CANCELLED,
  ALARMS_SUBCATEGORY_IDS.ALARM_VERIFIED,
  ALARMS_SUBCATEGORY_IDS.AMBUSH,
  ALARMS_SUBCATEGORY_IDS.EMERGENCY,
  ALARMS_SUBCATEGORY_IDS.FIRE,
  ALARMS_SUBCATEGORY_IDS.PANIC,
  ALARMS_SUBCATEGORY_IDS.BASE,
  ALARMS_SUBCATEGORY_IDS.SUPERVISORY,
  ALARMS_SUBCATEGORY_IDS.CO
);

export const ENTER = 13;
export const SPACE_BAR = 32;
export const LEFT_ARROW = 37;
export const UP_ARROW = 38;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 40;
export const BACKSPACE = 8;
export const ESCAPE = 27;
