import Button from "components/Button";
import { Flex } from "components/DaStyledElements";
import Modal from "components/Modal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { react2angular } from "react2angular";

import styled from "styled-components";

const Timer = styled.div`
  color: var(--color-info-500);
  font-size: 3.2em;
  padding: 0.2em 0;
`;

const LogoutModalTitle = styled.h3`
  text-decoration: underline;
  text-underline-position: under;
  font-weight: var(--font-weight-bold);
`;

const activityEvents = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
  "hashchange",
];

const MINUTES_TO_LOGOUT = 45; //minutes
const MINUTES_TO_SHOW_MODAL = 5; //minutes
const countDownTime = MINUTES_TO_LOGOUT * 60;
const showModalTime = MINUTES_TO_SHOW_MODAL * 60;

const secondsToTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  const seconds = Math.floor(timeInSeconds - minutes * 60);

  return `${minutes.toString()}:${seconds.toString().padStart(2, "0")}`;
};

function AutoLogoutModal({ $state, UserService, RelayService }: any) {
  const [timeLeft, setTimeLeft] = useState(countDownTime);
  const startTime = useRef(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(countDownTime - (Date.now() - startTime.current) / 1000);
    }, 1000);

    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );
    return () => {
      clearInterval(interval);

      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (timeLeft <= 0) {
      RelayService.logout();
      $state.go("page.login", { autoLogout: true });
    }
  }, [$state, UserService, timeLeft]);

  const resetTimer = useCallback(() => {
    setTimeLeft(countDownTime);
    startTime.current = Date.now();
  }, [setTimeLeft]);

  const shouldShowLogoutModal = timeLeft <= showModalTime;
  useEffect(() => {
    if (shouldShowLogoutModal) {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    } else {
      activityEvents.forEach((event) =>
        window.addEventListener(event, resetTimer)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowLogoutModal]);

  return shouldShowLogoutModal ? (
    <Modal size="medium" backdrop="static" animate={true}>
      <Flex.Row justify={"center"} align={"center"}>
        <LogoutModalTitle>
          You are about to be logged out due to inactivity.
        </LogoutModalTitle>
      </Flex.Row>
      <Modal.Body>
        <Flex.Row justify="center" align="center">
          <h4 className="mar-0">You will be logged out in:</h4>
        </Flex.Row>
        <Flex.Row justify="center" align="center">
          <Timer>{secondsToTime(timeLeft)}</Timer>
        </Flex.Row>
        <Flex.Row justify="center" align="center">
          <h4 className="mar-0">Would you like to stay signed in?</h4>
        </Flex.Row>
      </Modal.Body>
      <Modal.Footer>
        <Flex.Row justify="center" align="center">
          <Button
            design="default"
            size="md"
            style={{ marginRight: "0.8rem" }}
            type="reset"
            onClick={() => {
              RelayService.logout();
              $state.go("page.login");
            }}
          >
            Sign Out
          </Button>
          <Button
            design="primary"
            size="md"
            type="submit"
            onClick={() => {
              resetTimer();
            }}
          >
            Stay Signed In
          </Button>
        </Flex.Row>
      </Modal.Footer>
    </Modal>
  ) : null;
}

export function dangerouslyAddToApp() {
  App.component(
    "autoLogoutModal",
    react2angular(
      AutoLogoutModal,
      [],
      ["$rootScope", "$state", "$scope", "UserService", "RelayService"]
    )
  );
}
