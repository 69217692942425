/**
 * @generated SignedSource<<d200a779300dec9f693477de2f2b0527>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTDeviceInformationsFields_deviceInformation$data = {
  readonly id: string;
  readonly number: number;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformation1100TField_deviceInformation" | "DeviceInformation1100TWirelessFrequencyField_deviceInformation" | "DeviceInformationContext_deviceInformation" | "DeviceInformationDoorField_deviceInformation" | "DeviceInformationNameField_deviceInformation" | "DeviceInformationNetworkField_deviceInformation" | "DeviceInformationNumberField_deviceInformation" | "DeviceInformationSerialNumberField_deviceInformation" | "DeviceInformationSupervisionTimeField_deviceInformation" | "DeviceInformationWirelessField_deviceInformation">;
  readonly " $fragmentType": "XTDeviceInformationsFields_deviceInformation";
};
export type XTDeviceInformationsFields_deviceInformation$key = {
  readonly " $data"?: XTDeviceInformationsFields_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTDeviceInformationsFields_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTDeviceInformationsFields_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationContext_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationNumberField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationNameField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationDoorField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationNetworkField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformation1100TField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformation1100TWirelessFrequencyField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationWirelessField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationSerialNumberField_deviceInformation"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceInformationSupervisionTimeField_deviceInformation"
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "1ba2c6d7c21133fbe52a3345f4183680";

export default node;
