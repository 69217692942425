import { css, keyframes } from "styled-components";
import { BREAKPOINTS } from "../components/web/constants";
export const em = (ratioToRoot, contextRatioToRoot = 1) =>
  ratioToRoot / contextRatioToRoot;
export const px = (value) => `${value}px`;
export const noSelection = css`
  &::selection {
    background: transparent;
    color: inherit;
  }
`;

const breakpoint =
  (width, type = "min") =>
  (...args) =>
    css`
      @media screen and (${type}-width: ${width}) {
        ${css(...args)}
      }
    `;

/** @type {{
 *   xxxxxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxxxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xlarge: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xlargeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   large: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   largeMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   medium: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   mediumMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   small: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   smallMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xsmall: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xsmallMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxsmall: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   xxsmallMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   base: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 *   baseMax: (...args: any[]) => import("styled-components").FlattenSimpleInterpolation;
 * }} */
export const media = BREAKPOINTS.entrySeq().reduce(
  (result, [key, val]) => ({
    ...result,
    [key.toLowerCase()]: breakpoint(`${val}px`),
    [`${key.toLowerCase()}Max`]: breakpoint(`${val - 1}px`, "max"),
  }),
  {}
);
const headline = css`
  display: block;
  margin: 15px 0;
`;
export const h1 = css`
  ${headline} font-size: 38px;
  font-weight: 200;
`;
export const h2 = css`
  ${headline} font-size: 30px;
  font-weight: 200;
`;
export const h3 = css`
  ${headline} font-size: 20px;
  font-weight: 400;
`;
export const fadeInOut = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

//Z-indexes
const base = 0;
const above = 1;
const below = -1;

const zIndexBackdrop = below + base;
const zIndexBase = base;
const zIndexComponent = above + base;
const zIndexButton = above + zIndexComponent;
const zIndexMenu = above + zIndexButton;
const zIndexModal = above + zIndexMenu;
const zIndexTooltip = above + zIndexModal;
const zIndexSticky = above + zIndexTooltip;
const zIndexBanner = above + zIndexSticky;
const zIndexModalPriority = above + zIndexBanner;
const zIndexTooltipPriority = above + zIndexModalPriority;

/**
 * @param BACKDROP Below base, value: -1
 * @param BASE Base, value: 0
 * @param COMPONENT Above base, value: 1
 * @param BUTTON Above COMPONENT, value: 2
 * @param MENU Above BUTTON, value: 3
 * @param MODAL Above MENU, value: 4
 * @param TOOLTIP Above MODAL, value: 5
 * @param STICKY Above TOOLTIP, value: 6
 * @param BANNER Above NAV, value: 7
 * @param MODAL_PRIORITY Above BANNER, value: 8
 * @param TOOLTIP_PRIORITY Above MODAL_PRIORITY, value 9
 */
export const Z_INDEX = {
  /** -1 */
  BACKDROP: zIndexBackdrop,
  /** 0 */
  BASE: zIndexBase,
  /** 1 */
  COMPONENT: zIndexComponent,
  /** 2 */
  BUTTON: zIndexButton,
  /** 3 */
  MENU: zIndexMenu,
  /** 4 */
  MODAL: zIndexModal,
  /** 5 */
  TOOLTIP: zIndexTooltip,
  /** 6 */
  STICKY: zIndexSticky,
  /** 7 */
  BANNER: zIndexBanner,
  /** 8 */
  MODAL_PRIORITY: zIndexModalPriority,
  /** 9 */
  TOOLTIP_PRIORITY: zIndexTooltipPriority,
};

/** Convert a number of px to rem */
export const pxToRem = (px) => px / 16;

/** Convert a number of rem to px */
export const remToPx = (rem) => rem * 16;

/** Calculate n of cqw based on a container's width (1%) */
export const calcCqw = (containerWidth, nCqw) =>
  containerWidth * (1 / 100) * nCqw;

/** Calculate n of cqh based on a container's height (1%) */
export const calcCqh = (containerHeight, nCqh) =>
  containerHeight * (1 / 100) * nCqh;
