/**
 * @generated SignedSource<<d290f7a9ffb7695bfc3a9ef407b7134f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsHeatSaverTemperatureField_outputOptions$data = {
  readonly heatSaverTemperature: string;
  readonly " $fragmentType": "OutputOptionsHeatSaverTemperatureField_outputOptions";
};
export type OutputOptionsHeatSaverTemperatureField_outputOptions$key = {
  readonly " $data"?: OutputOptionsHeatSaverTemperatureField_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsHeatSaverTemperatureField_outputOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsHeatSaverTemperatureField_outputOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heatSaverTemperature",
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};

(node as any).hash = "3408d8be87b2bd98cfc2211cac8b1c02";

export default node;
