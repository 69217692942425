import { GenericPageFallback } from "components/GenericPageFallback";
import React, { Suspense } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import Groups from ".";

const GroupsAngularEntryPoint = ({
  RelayService,
  UserService,
  $stateParams,
}: any) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<GenericPageFallback />}>
        <Groups
          customerId={$stateParams.customer_id}
          controlSystemId={$stateParams.control_system_id}
          canEdit={UserService.canEditGroups()}
        />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "groups",
    react2angular(
      GroupsAngularEntryPoint,
      [],
      ["RelayService", "UserService", "$stateParams"]
    )
  );
};
