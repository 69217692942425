import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsPanicAlarmOutputField_outputOptions$key } from "./__generated__/OutputOptionsPanicAlarmOutputField_outputOptions.graphql";

export const outputOptionsPanicAlarmOutputFieldId = () =>
  "output-options-panic-alarm-output";

function OutputOptionsPanicAlarmOutputField() {
  const [{ panicAlarmOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsPanicAlarmOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsPanicAlarmOutputField_outputOptions on OutputOptions {
          id
          panicAlarmOutput {
            id
            number
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsPanicAlarmOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));
  const { isXr } = resolvePanelType(hardwareModel);

  const { current: originalValue } = React.useRef(
    panicAlarmOutput.formattedNumber
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Panic Alarm Output"
      tooltip={
        isXr
          ? "This output will be turned on when a Panic Zone goes into Alarm. The output turns off after all Panic zones are restored and a Sensor Reset is performed."
          : "This output will be turned on when a Panic Zone goes into Alarm."
      }
    >
      <TextInput
        id={fieldId}
        value={panicAlarmOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "panicAlarmOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "panicAlarmOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsPanicAlarmOutputField;
