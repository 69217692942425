import graphql from "babel-plugin-relay/macro";
import { getEnumKeyFromValue } from "common/utils/universal/enums";
import * as React from "react";
import { NacProtocols } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import Select from "../Select";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsNac1SyncProtocolField_bellOptions$key } from "./__generated__/BellOptionsNac1SyncProtocolField_bellOptions.graphql";

enum Protocol {
  DMP = "DMP",
  GENTEX = "Gentex",
  WHEELOCK = "Wheelock",
  SYSTEM_SENSOR = "System Sensor",
}

export const bellOptionsNac1SyncProtocolFieldId = () =>
  "bell-options-nac1-sync-protocol";

function BellOptionsNac1SyncProtocolField() {
  const [{ nac1SyncProtocol }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsNac1SyncProtocolField_bellOptions$key>(
      graphql`
        fragment BellOptionsNac1SyncProtocolField_bellOptions on BellOptions {
          id
          nac1SyncProtocol
        }
      `
    );

  const fieldId = bellOptionsNac1SyncProtocolFieldId();

  const mapValue = (value: string) => getEnumKeyFromValue(Protocol, value);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="NAC 1 Sync Protocol"
      tooltip="The protocol used to sync devices on NAC circuit 1."
    >
      <Select
        id={fieldId}
        value={nac1SyncProtocol ?? ""}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(
              target.value as NacProtocols,
              "nac1SyncProtocol"
            );
          });
        }}
      >
        <Select.Option value={mapValue(Protocol.DMP)}>
          {Protocol.DMP}
        </Select.Option>
        <Select.Option value={mapValue(Protocol.GENTEX)}>
          {Protocol.GENTEX}
        </Select.Option>
        <Select.Option value={mapValue(Protocol.SYSTEM_SENSOR)}>
          {Protocol.SYSTEM_SENSOR}
        </Select.Option>
        <Select.Option value={mapValue(Protocol.WHEELOCK)}>
          {Protocol.WHEELOCK}
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsNac1SyncProtocolField;
