/**
 * @generated SignedSource<<67bd3214edb344514ff6b4d09a23135c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly nonOutputs: {
    readonly coolSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cutoffOutputs: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly heatSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly outputOptions: {
    readonly included: boolean;
  } | null;
  readonly outputs: {
    readonly ambushOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAllOrAwayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedHomeOrPerimeterOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedSleepOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly beginExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly closingWaitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly communicationFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly endExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly lateToCloseOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly lockdownOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly panicAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly readyOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteArmingOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sensorResetOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneMonitorOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "6bebb9b652b480da2ec5a2b9586726bf";

export default node;
