/**
 *
 * ArmingShieldLargeIcon
 * @author Chad Watson
 *
 */

import React from "react";
import SvgIcon from "./SvgIcon";

const VIEW_SIZE = 64;

const ArmingShieldLargeIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <title>arming_shield_large</title>
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          d="M57.8,7.8c-0.2-0.6-0.8-2.6-4.1-4.1C50.1,2,43.5,0,32.4,0C24,0,17.2,0.9,12.3,2.6C10.6,3.2,6.5,5,6.1,8.1
      C6,8.8,6,11,6,11.2v23.2c0,8.9,6.8,16.4,12.4,21.2c1.1,0.9,2.2,1.8,3.4,2.6l0.1,0.1l0,0c4.9,3.5,9.5,5.8,10.5,5.8
      c2,0,25.6-12.6,25.6-29.6V11.2C58.1,10.1,58,8.9,57.8,7.8z M45.4,54.8C39.3,60,33.2,63,32.4,63c-0.5,0-3.7-1.4-7.8-4.1h0.5
      c2.5-0.1,4.9-0.7,7.1-1.9l0,0l0.2-0.1c3.4-1.9,6.6-4.2,9.5-6.8c6.3-5.5,9.6-11.2,9.6-16.4v-23c1.8,1.1,5.4,3.7,5.3,6.9v0.1l0,0
      v16.7C57,42.9,50.7,50.2,45.4,54.8L45.4,54.8z M13.4,10.1c0.7-0.6,4.7-2.9,19-2.9c11,0,16.7,1.8,18.4,2.9v23.6
      c0,6.2-5,12-9.2,15.6c-2.8,2.4-5.9,4.6-9.1,6.5c-2.2-1.2-19-10.9-19-22.1V10.1z M14,3.1c-0.7,1-1.1,2.1-1.4,3.2
      c-0.2,1.2-0.3,2.4-0.3,3.6v23.8c0,5.2,3.4,10.9,9.9,16.4c2.8,2.3,5.8,4.4,9,6.3c-1.9,0.9-3.9,1.4-6,1.5c-0.9,0-1.9-0.1-2.7-0.5
      c-1.2-0.8-2.3-1.7-3.4-2.6C13.5,50.2,7,42.9,7,34.4V11.2c0,0,0-2.3,0.1-3.1s0.9-3,5.6-4.6C13.1,3.4,13.5,3.3,14,3.1L14,3.1z
       M54.7,11.7c-1-0.9-2.1-1.6-3.2-2.2c-1.4-0.8-3-1.4-4.6-1.8c-4.8-1-9.6-1.5-14.5-1.4c-12.2,0-17.1,1.6-19,2.7
      c0.1-1.8,0.5-5.6,3.1-6.4h0.1c5.3-1.1,10.6-1.6,16-1.5c10.9,0,17.4,1.9,20.9,3.6c2.9,1.3,3.4,2.9,3.5,3.5c0.2,1.1,0.2,2.1,0.2,3.2
      v3.1C56.4,13.4,55.6,12.5,54.7,11.7z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ArmingShieldLargeIcon;
