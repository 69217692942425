/**
 * @generated SignedSource<<6059d463504d1414d8fbfa931ab1715f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathProtocol = "TCP" | "UDP";
import { FragmentRefs } from "relay-runtime";
export type CommPathProtocolField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly protocol: CommunicationPathProtocol;
  readonly " $fragmentType": "CommPathProtocolField_communicationPath";
};
export type CommPathProtocolField_communicationPath$key = {
  readonly " $data"?: CommPathProtocolField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathProtocolField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathProtocolField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protocol",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "48002ccbc31536b85e828ae530983ee7";

export default node;
