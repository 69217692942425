/**
 * @generated SignedSource<<88676b3f6dc2aa1141b3c9e2e5c6f10d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2FirstIpPortField_communication$data = {
  readonly receiver2Port: string;
  readonly " $fragmentType": "CommunicationReceiver2FirstIpPortField_communication";
};
export type CommunicationReceiver2FirstIpPortField_communication$key = {
  readonly " $data"?: CommunicationReceiver2FirstIpPortField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2FirstIpPortField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2FirstIpPortField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2Port",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "4b58df8953dfc0bfa3c0ea0bc6f9e634";

export default node;
