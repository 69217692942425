/**
 * @generated SignedSource<<fd428dea5ea09ec02a122d04324c31ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HistoryTableFragment$data = {
  readonly mobileCredentialsHistoryConnection: {
    readonly nodes: ReadonlyArray<{
      readonly amount: number;
      readonly by: string;
      readonly cost: number;
      readonly customerName: string;
      readonly date: string;
      readonly id: string;
      readonly notes: string;
    }>;
  };
  readonly " $fragmentType": "HistoryTableFragment";
};
export type HistoryTableFragment$key = {
  readonly " $data"?: HistoryTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HistoryTableFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HistoryTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DealerMobileCredentialsHistoryConnection",
      "kind": "LinkedField",
      "name": "mobileCredentialsHistoryConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MobileCredentialHistoryEvent",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "by",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "notes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customerName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cost",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};

(node as any).hash = "7f713e5f67cc517c62e32897cc090777";

export default node;
