/**
 * @generated SignedSource<<cc925c57684c4a34f4e317e69e93e6df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2SupervisoryTroubleReportsField_communication$data = {
  readonly receiver2Backup: boolean;
  readonly receiver2SupervisoryTroubleReports: boolean;
  readonly " $fragmentType": "CommunicationReceiver2SupervisoryTroubleReportsField_communication";
};
export type CommunicationReceiver2SupervisoryTroubleReportsField_communication$key = {
  readonly " $data"?: CommunicationReceiver2SupervisoryTroubleReportsField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2SupervisoryTroubleReportsField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2SupervisoryTroubleReportsField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2Backup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2SupervisoryTroubleReports",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "9937204c01660ed16986571d07b11e88";

export default node;
