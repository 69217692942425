import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const DeviceInformationContext = React.createContext<any>({});

export function DeviceInformationContextProvider(props: {
  deviceInformation: any;
  children: React.ReactNode;
}) {
  return (
    <DeviceInformationContext.Provider value={props.deviceInformation}>
      {props.children}
    </DeviceInformationContext.Provider>
  );
}

export function useDeviceInformationFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const deviceInformation = React.useContext(DeviceInformationContext);

  const data = useFragment(fragmentInput, deviceInformation as TKey);

  const { id } = useFragment(
    graphql`
      fragment DeviceInformationContext_deviceInformation on DeviceInformation {
        id
      }
    `,
    deviceInformation as any
  );

  const update = (
    updater: (deviceInformation: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
