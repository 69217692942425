/**
 * @generated SignedSource<<569ebbdaee86286ab72ecb3e9a484b96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryZoneInformationsFields_zone$data = {
  readonly id: string;
  readonly number: string;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationAreaField_zone" | "ZoneInformationArmAreasField_zone" | "ZoneInformationArmedOpenMessageField_zone" | "ZoneInformationArmedOpenOutputActionField_zone" | "ZoneInformationArmedOpenOutputNumberField_zone" | "ZoneInformationArmedShortMessageField_zone" | "ZoneInformationArmedShortOutputActionField_zone" | "ZoneInformationArmedShortOutputNumberField_zone" | "ZoneInformationArmingStyleField_zone" | "ZoneInformationChimeSoundField_zone" | "ZoneInformationContext_zone" | "ZoneInformationCrossZoneField_zone" | "ZoneInformationDisarmedOpenMessageField_zone" | "ZoneInformationDisarmedOpenOutputActionField_zone" | "ZoneInformationDisarmedOpenOutputNumberField_zone" | "ZoneInformationDisarmedShortMessageField_zone" | "ZoneInformationDisarmedShortOutputActionField_zone" | "ZoneInformationDisarmedShortOutputNumberField_zone" | "ZoneInformationNameField_zone" | "ZoneInformationNumberField_zone" | "ZoneInformationReceiverRoutingField_zone" | "ZoneInformationSwingerBypassField_zone" | "ZoneInformationTypeField_zone" | "ZoneInformationZoneAuditDaysField_zone">;
  readonly " $fragmentType": "TMSentryZoneInformationsFields_zone";
};
export type TMSentryZoneInformationsFields_zone$key = {
  readonly " $data"?: TMSentryZoneInformationsFields_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryZoneInformationsFields_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryZoneInformationsFields_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationContext_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationNameField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationTypeField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationAreaField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationChimeSoundField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSwingerBypassField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationCrossZoneField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationZoneAuditDaysField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationReceiverRoutingField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmAreasField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedOpenMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedOpenOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedOpenOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedShortMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedShortOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedShortOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmingStyleField_zone"
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "dd04b490f81a893d4ff8b163f216371f";

export default node;
