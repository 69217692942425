import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreBackupIpAddressField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreBackupIpAddressField_remoteOptions.graphql";

export const remoteOptionsEntreBackupIpAddressFieldId = () =>
  "remote-options-entre-backup-ip-address";

function RemoteOptionsEntreBackupIpAddressField() {
  const [
    { entreBackupIpAddress, entreConnection, entreBackupConnection, entreIpv6 },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsEntreBackupIpAddressField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsEntreBackupIpAddressField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          entreBackupIpAddress
          entreConnection
          entreBackupConnection
          entreIpv6
        }
        ... on XfRemoteOptions {
          entreBackupIpAddress
          entreConnection
          entreBackupConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsEntreBackupIpAddressFieldId();
  const disabled =
    entreConnection === RemoteOptionsConnectionType.NONE ||
    entreBackupConnection === RemoteOptionsConnectionType.NONE ||
    (!!entreIpv6 && entreBackupConnection !== RemoteOptionsConnectionType.CELL);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré Backup IP Address"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={entreBackupIpAddress || ""}
        disabled={disabled}
        maxLength={16}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        validationMessage="Please enter a valid IP address"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "entreBackupIpAddress")
          );
        }}
        onBlur={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "entreBackupIpAddress"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreBackupIpAddressField;
