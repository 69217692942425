/**
 * @generated SignedSource<<4a55742eef593af68844e90132e38776>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PcLogReportsZonesReportsEnabledField_pcLogReports$data = {
  readonly zonesReportsEnabled: boolean;
  readonly " $fragmentType": "PcLogReportsZonesReportsEnabledField_pcLogReports";
};
export type PcLogReportsZonesReportsEnabledField_pcLogReports$key = {
  readonly " $data"?: PcLogReportsZonesReportsEnabledField_pcLogReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"PcLogReportsZonesReportsEnabledField_pcLogReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PcLogReportsZonesReportsEnabledField_pcLogReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zonesReportsEnabled",
      "storageKey": null
    }
  ],
  "type": "PcLogReports",
  "abstractKey": null
};

(node as any).hash = "ec2d59c97b150b380c0521599c5babf9";

export default node;
