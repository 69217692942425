import { defaultTheme } from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import FadeInOut from "common/components/web/FadeInOut";
import RelayEnvironmentCloneProvider from "components/RelayEnvironmentCloneProvider";
import * as React from "react";
import { GraphQLTaggedNode, useFragment, useLazyLoadQuery } from "react-relay";
import { VariablesOf } from "relay-runtime";
import { PanelHardwareModel } from "securecom-graphql/client";
import { ThemeProvider } from "styled-components/macro";
import { DealerContextProvider } from "../common/DealerContext";
import FullProgrammingForm, {
  ApplyTemplateFunc,
  Concept,
  FullProgrammingFormShell,
  ProgrammingConceptSidebarButton,
} from "../common/FullProgrammingForm";
import { OriginalControlSystemContextProvider } from "../common/OriginalControlSystemContext";
import {
  OriginalDealerContextProvider,
  useOriginalDealer,
} from "../common/OriginalDealerContext";
import ActiveConceptContext from "./ActiveConceptContext";
import { ChangedProgrammingConceptsContextProvider } from "./ChangedProgrammingConceptsContext";
import { ControlSystemContextProvider } from "./ControlSystemContext";
import {
  FallbackProgrammingContextProvider,
  ProgrammingContextProvider,
} from "./ProgrammingContext";
import { SearchProvider } from "./SearchContext";
import { useTemplateContext } from "./TemplateContext";
import { FullProgrammingVersionEntryPoint_dealer$key } from "./__generated__/FullProgrammingVersionEntryPoint_dealer.graphql";

function FullProgrammingVersionEntryPointMain(props: {
  controlSystemInline: any;
  controlSystemInlineFragment: GraphQLTaggedNode;
  concepts: Concept[];
  systemName: string;
  hardwareModel: PanelHardwareModel;
  customerId: string;
  applyTemplate: ApplyTemplateFunc;
}) {
  const navButtons = props.concepts.map(({ conceptId, NavButton }) =>
    React.cloneElement(NavButton(), { key: conceptId })
  );

  return (
    <ProgrammingContextProvider
      concepts={props.concepts.map((concept, index) => ({
        conceptId: concept.conceptId,
        useSaveMutation: concept.useSaveMutation,
        useRetrieveMutation: concept.useRetrieveMutation,
        hasNewItems: !!navButtons[index].props.hasNewItems,
      }))}
      controlSystem={props.controlSystemInline}
      controlSystemInlineFragment={props.controlSystemInlineFragment}
      hardwareModel={props.hardwareModel}
    >
      <FullProgrammingForm
        concepts={props.concepts}
        navButtons={navButtons}
        systemName={props.systemName}
        applyTemplate={props.applyTemplate}
      />
    </ProgrammingContextProvider>
  );
}

function FullProgrammingVersionEntryPointFragment(props: {
  controlSystem: ControlSystemKey;
  controlSystemFragment: GraphQLTaggedNode;
  controlSystemInline: any;
  controlSystemInlineFragment: GraphQLTaggedNode;
  concepts: Concept[];
  applyTemplate: ApplyTemplateFunc;
}) {
  const dealer = useFragment(
    graphql`
      fragment FullProgrammingVersionEntryPoint_dealer on Dealer {
        id
        ...DealerContext_dealer
        ...FullProgrammingForm_dealer
        ...ProgrammingTemplateForm_dealer
      }
    `,
    useOriginalDealer() as FullProgrammingVersionEntryPoint_dealer$key
  );

  // This data is used as the state for the form fields.
  // Having it separated from the controlSystemInline allows
  // for efficient rendering of the form. If the "inline" data
  // was included then the entire form would re-render for
  // each field change.
  const controlSystem = useFragment(
    props.controlSystemFragment,
    props.controlSystem as any
  );

  return (
    <DealerContextProvider dealer={dealer}>
      <ThemeProvider theme={defaultTheme}>
        <ChangedProgrammingConceptsContextProvider>
          <ControlSystemContextProvider controlSystem={controlSystem}>
            <FullProgrammingVersionEntryPointMain
              concepts={props.concepts}
              controlSystemInline={props.controlSystemInline}
              controlSystemInlineFragment={props.controlSystemInlineFragment}
              systemName={props.controlSystem.name}
              hardwareModel={props.controlSystem.panel.hardwareModel}
              customerId={props.controlSystem.customer.id}
              applyTemplate={props.applyTemplate}
            />
          </ControlSystemContextProvider>
        </ChangedProgrammingConceptsContextProvider>
      </ThemeProvider>
    </DealerContextProvider>
  );
}

type ControlSystemKey = {
  readonly id: string;
  readonly name: string;
  readonly panel: {
    readonly hardwareModel: PanelHardwareModel;
  };
  readonly customer: {
    readonly id: string;
  };
};

type FullProgrammingFormOperationType = {
  readonly variables: {
    readonly softwareVersion?: string;
    readonly hardwareModel?: PanelHardwareModel;
    readonly systemId?: string;
    readonly dealerId: string;
  };
  readonly response: {
    readonly dealer: {
      readonly id: string;
    } | null;
    readonly controlSystem: ControlSystemKey | null;
    readonly controlSystemInline: {
      readonly id: string;
    } | null;
  };
};

type Props<TQuery extends FullProgrammingFormOperationType> = {
  systemName: string;
  concepts: Concept[];
  gqlQuery: GraphQLTaggedNode;
  gqlQueryVariables: VariablesOf<TQuery>;
  gqlFormControlSystemFragment: GraphQLTaggedNode;
  gqlFormControlSystemInlineFragment: GraphQLTaggedNode;
  applyTemplate: ApplyTemplateFunc;
};

function FullProgrammingVersionEntryPoint<
  TQuery extends FullProgrammingFormOperationType
>(props: Props<TQuery>) {
  const data = useLazyLoadQuery<TQuery>(
    props.gqlQuery,
    props.gqlQueryVariables,
    { fetchPolicy: "network-only" }
  );

  // TODO: Show a fallback UI that the control system doesn't exist
  return data.dealer?.id &&
    data.controlSystem?.id &&
    data.controlSystemInline?.id ? (
    <OriginalDealerContextProvider dealer={data.dealer}>
      <OriginalControlSystemContextProvider controlSystem={data.controlSystem}>
        <RelayEnvironmentCloneProvider>
          <FullProgrammingVersionEntryPointFragment
            controlSystem={data.controlSystem}
            controlSystemInline={data.controlSystemInline}
            concepts={props.concepts}
            controlSystemFragment={props.gqlFormControlSystemFragment}
            controlSystemInlineFragment={
              props.gqlFormControlSystemInlineFragment
            }
            applyTemplate={props.applyTemplate}
          />
        </RelayEnvironmentCloneProvider>
      </OriginalControlSystemContextProvider>
    </OriginalDealerContextProvider>
  ) : null;
}

function FullProgrammingVersionEntryPointRoot<
  TQuery extends FullProgrammingFormOperationType
>(props: Props<TQuery>) {
  const [activeConcept, setActiveConcept] = React.useState(
    props.concepts[0].conceptId
  );

  const { isEditing: isEditingTemplate } = useTemplateContext();

  return (
    <FadeInOut visible>
      <ActiveConceptContext.Provider
        value={React.useMemo(
          () => [activeConcept, setActiveConcept],
          [activeConcept, setActiveConcept]
        )}
      >
        <SearchProvider>
          <React.Suspense
            fallback={
              <FallbackProgrammingContextProvider concepts={props.concepts}>
                <FullProgrammingFormShell
                  concepts={props.concepts}
                  systemName={props.systemName}
                  navButtons={props.concepts.map(({ conceptId, title }) =>
                    //removes these concepts from the nav bar when editing a template
                    conceptId !== "xr-feature-keys" &&
                    conceptId !== "takeover-panel-zone-informations" &&
                    !isEditingTemplate ? (
                      <ProgrammingConceptSidebarButton
                        key={conceptId}
                        conceptId={conceptId}
                        title={title}
                      />
                    ) : (
                      <></>
                    )
                  )}
                />
              </FallbackProgrammingContextProvider>
            }
          >
            <FullProgrammingVersionEntryPoint {...props} />
          </React.Suspense>
        </SearchProvider>
      </ActiveConceptContext.Provider>
    </FadeInOut>
  );
}

export default FullProgrammingVersionEntryPointRoot;
