import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, SystemReports } from "securecom-graphql/client";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import SystemReportsAbortReportsField from "../common/SystemReportsFields/SystemReportsAbortReportsField";
import SystemReportsAmbushReportsField from "../common/SystemReportsFields/SystemReportsAmbushReportsField";
import SystemReportsBypassReportsField from "../common/SystemReportsFields/SystemReportsBypassReportsField";
import SystemReportsCodeChangeReportsField from "../common/SystemReportsFields/SystemReportsCodeChangeReportsField";
import { SystemReportsContextProvider } from "../common/SystemReportsFields/SystemReportsContext";
import SystemReportsEarlyToCloseField from "../common/SystemReportsFields/SystemReportsEarlyToCloseField";
import SystemReportsEntryCheckinProtectionField from "../common/SystemReportsFields/SystemReportsEntryCheckinProtectionField";
import SystemReportsLateToOpenField from "../common/SystemReportsFields/SystemReportsLateToOpenField";
import SystemReportsOpenCloseEnableField from "../common/SystemReportsFields/SystemReportsOpenCloseEnableField";
import SystemReportsSendStoredMessage from "../common/SystemReportsFields/SystemReportsSendStoredMessage";
import SystemReportsZoneRestoralReportsField from "../common/SystemReportsFields/SystemReportsZoneRestoralReportsField";
import { panelVersionGTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  XTSystemReportsProgrammingConceptFormInline_controlSystem$data,
  XTSystemReportsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XTSystemReportsProgrammingConceptFormInline_controlSystem.graphql";
import { XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XTSystemReportsProgrammingConceptFormSystemReportsRefreshMutation,
} from "./__generated__/XTSystemReportsProgrammingConceptFormSystemReportsRefreshMutation.graphql";
import {
  XTSystemReportsProgrammingConceptFormSystemReportsSendMutation,
  XTSystemReportsProgrammingConceptFormSystemReportsSendMutation$data,
} from "./__generated__/XTSystemReportsProgrammingConceptFormSystemReportsSendMutation.graphql";
import { XTSystemReportsProgrammingConceptForm_controlSystem$key } from "./__generated__/XTSystemReportsProgrammingConceptForm_controlSystem.graphql";

export const title = "System Reports";
export const conceptId = "xt-system-reports";

export const getState = (
  controlSystem: XTSystemReportsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTSystemReportsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          systemReports {
            id
            abortReports
            accessKeypads
            ambushReports
            bypassReports
            codeChangeReports
            earlyToClose
            earlyToCloseMin
            earlyToCloseMax
            lateToOpen
            lateToOpenMin
            lateToOpenMax
            scheduleChangeReports
            videoSystemReports
            zoneRestoralReports
            openCloseEnable
            sendStoredMessage
            entryCheckinProtection
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XTSystemReportsProgrammingConceptFormSystemReportsRefreshMutation(
    $id: ID!
  ) {
    refreshSystemReports(id: $id) {
      ... on RefreshSystemReportsSuccessPayload {
        __typename
        controlSystem {
          ...XTSystemReportsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XTSystemReportsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XTSystemReportsProgrammingConceptFormSystemReportsRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshSystemReports;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "System Reports Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSystemReports: {
                    __typename: response.refreshSystemReports.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve System Reports: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve System Reports",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XTSystemReportsProgrammingConceptFormSystemReportsSendMutation(
    $systemId: ID!
    $systemReports: SystemReportsInput!
  ) {
    sendSystemReports(systemId: $systemId, systemReports: $systemReports) {
      ... on SendSystemReportsSuccessPayload {
        __typename
        controlSystem {
          id
          ...XTSystemReportsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSystemReportsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XTSystemReportsProgrammingConceptFormSystemReportsSendMutation$data,
  originalControlSystemData: XTSystemReportsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendSystemReports.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshSystemReports: {
          __typename: "RefreshSystemReportsSuccessPayload",
          controlSystem: getState(response.sendSystemReports.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XTSystemReportsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XTSystemReportsProgrammingConceptFormSystemReportsSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { systemReports },
        } = getState(props.controlSystem);
        if (systemReports) {
          save({
            variables: {
              systemId,
              systemReports: {
                abortReports: systemReports.abortReports,
                accessKeypads: systemReports.accessKeypads,
                ambushReports: systemReports.ambushReports,
                bypassReports: systemReports.bypassReports,
                codeChangeReports: systemReports.codeChangeReports,
                earlyToClose: systemReports.earlyToClose,
                lateToOpen: systemReports.lateToOpen,
                scheduleChangeReports: systemReports.scheduleChangeReports,
                videoSystemReports: systemReports.videoSystemReports,
                zoneRestoralReports: systemReports.zoneRestoralReports,
                openCloseEnable: systemReports.openCloseEnable,
                sendStoredMessage: systemReports.sendStoredMessage,
                entryCheckinProtection: systemReports.entryCheckinProtection,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendSystemReports.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved to the System`,
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendSystemReports.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendSystemReports.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readSystemReportsTemplateData = (
  programmingTemplateConcepts: XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        systemReports {
          included
          abortReports {
            included
            data
          }
          accessKeypads {
            included
            data
          }
          ambushReports {
            included
            data
          }
          bypassReports {
            included
            data
          }
          codeChangeReports {
            included
            data
          }
          earlyToClose {
            included
            data
          }
          lateToOpen {
            included
            data
          }
          scheduleChangeReports {
            included
            data
          }
          videoSystemReports {
            included
            data
          }
          zoneRestoralReports {
            included
            data
          }
          openCloseEnable {
            included
            data
          }
          sendStoredMessage {
            included
            data
          }
          entryCheckinProtection {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).systemReports ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSystemReportsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const systemReportsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "systemReports",
      "SystemReports"
    ) as unknown as RecordProxy<SystemReports>;

    applyTemplateScalarDataToRecordProxy(
      systemReportsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [{ panel }] =
    useControlSystemFragment<XTSystemReportsProgrammingConceptForm_controlSystem$key>(graphql`
      fragment XTSystemReportsProgrammingConceptForm_controlSystem on ControlSystem {
        panel {
          softwareVersion
          helpFiles {
            programmingGuideUrl
            installGuideUrl
          }
          systemReports {
            ...SystemReportsContext_systemReports
            ...SystemReportsAbortReportsField_systemReports
            ...SystemReportsBypassReportsField_systemReports
            ...SystemReportsScheduleChangeReportsField_systemReports
            ...SystemReportsCodeChangeReportsField_systemReports
            ...SystemReportsAmbushReportsField_systemReports
            ...SystemReportsVideoReportsField_systemReports
            ...SystemReportsZoneRestoralReportsField_systemReports
            ...SystemReportsAccessKeypadsField_systemReports
            ...SystemReportsLateToOpenField_systemReports
            ...SystemReportsEarlyToCloseField_systemReports
            ...SystemReportsOpenCloseEnableField_systemReports
            ...SystemReportsSendStoredMessageField_systemReports
            ...SystemReportsEntryCheckinProtectionField_systemReports
          }
        }
      }
    `);

  const {
    systemReports,
    softwareVersion,
    helpFiles: { programmingGuideUrl },
  } = panel;

  return (
    <PanelContextProvider panel={panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        title={title}
        helpLink={`${programmingGuideUrl}#System%20Reports`}
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(systemReports)}
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          <SystemReportsContextProvider systemReports={systemReports}>
            <ProgrammingConceptForm.Fields>
              <SystemReportsZoneRestoralReportsField />
              <SystemReportsOpenCloseEnableField />
              <SystemReportsAbortReportsField />
              <SystemReportsBypassReportsField />
              <SystemReportsCodeChangeReportsField />
              <SystemReportsAmbushReportsField />
              <SystemReportsLateToOpenField />
              <SystemReportsEarlyToCloseField />
              {panelVersionGTOE(172, softwareVersion) && (
                <>
                  <SystemReportsSendStoredMessage />
                </>
              )}
              {panelVersionGTOE(182, softwareVersion) && (
                <>
                  <SystemReportsEntryCheckinProtectionField />
                </>
              )}
            </ProgrammingConceptForm.Fields>
          </SystemReportsContextProvider>
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}
