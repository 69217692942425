/**
 * @generated SignedSource<<0cdd6e0d5d38ab79366ee94d88236a2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneSensorType = "GLASSBREAK" | "HOLD_UP" | "PIR_OR_RECESSED_DOOR" | "REPEATER" | "SMOKE" | "UNIVERSAL_TRANSMITTER" | "UNKNOWN_DEVICE" | "WIRELESS_FOUR_ZONE_EXPANDER" | "WIRELESS_PIR";
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationContactNormallyOpenField_zone$data = {
  readonly contactNumber: ZoneTransmitterContact | null;
  readonly number: string;
  readonly sensorType: ZoneSensorType;
  readonly wireless: boolean;
  readonly wirelessContactNormallyOpen: boolean;
  readonly " $fragmentType": "ZoneInformationContactNormallyOpenField_zone";
};
export type ZoneInformationContactNormallyOpenField_zone$key = {
  readonly " $data"?: ZoneInformationContactNormallyOpenField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationContactNormallyOpenField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationContactNormallyOpenField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wirelessContactNormallyOpen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sensorType",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "11f1bc21c8b163966a3b7228fb5533cb";

export default node;
