export const CUBIC_BEZIER: string = "cubic-bezier(0.63, 0.64, 0.3, 1)";
export const BORDER_RADIUS = "4px";
export const BREAKPOINTS = {
  xxxxxlarge: 2000,
  xxxxlarge: 1620,
  xxxlarge: 1440,
  xxlarge: 1220,
  xlarge: 1024,
  large: 960,
  medium: 768,
  small: 600,
  xsmall: 480,
  xxsmall: 320,
  base: 0,
} as const;
export const FUSEJS_SCORE_THRESHOLD = 0.2;
export const FUSEJS_SCORE_DISTANCE = 1000;
export const ENTER = 13;
export const SPACE_BAR = 32;
export const LEFT_ARROW = 37;
export const UP_ARROW = 38;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 40;
export const BACKSPACE = 8;
export const ESCAPE = 27;


