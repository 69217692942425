import React from "react";
import styled from "styled-components";
import { ExitIcon } from "../Icons";
import { SuccessButton, VARIANT } from "../Button";

const AccessButton = (
  props: { accessMessage: string } & Parameters<typeof SuccessButton>[0]
) => (
  <StyledButton {...props} variant={VARIANT.outline} size="small">
    <Icon />
    <Text>{props.accessMessage}</Text>
  </StyledButton>
);

export default AccessButton;

const StyledButton = styled(SuccessButton)`
  width: 100%;
  margin-top: 14px;
  border-radius: 3px;
  white-space: pre;
`;
const Icon = styled(ExitIcon)`
  margin-right: 5px;
  font-size: 1.2em;
  vertical-align: middle;
`;
const Text = styled.span`
  vertical-align: middle;
`;
