/**
 * @generated SignedSource<<269e43e1a5e99bc04622c2f3bb4bef5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2SecondIpPortField_communication$data = {
  readonly receiver2Port2: string;
  readonly " $fragmentType": "CommunicationReceiver2SecondIpPortField_communication";
};
export type CommunicationReceiver2SecondIpPortField_communication$key = {
  readonly " $data"?: CommunicationReceiver2SecondIpPortField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2SecondIpPortField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2SecondIpPortField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2Port2",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "8108708dd455b595f80b2b6045a5fb26";

export default node;
