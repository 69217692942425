import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsIpv6AddressField_networkOptions$key } from "./__generated__/NetworkOptionsIpv6AddressField_networkOptions.graphql";

export const networkOptionsIpv6AddressFieldId = () =>
  "network-options-ipv6-address";

function NetworkOptionsIpv6AddressField() {
  const [{ ipv6Address, ipv6Enabled, dhcpEnabled }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsIpv6AddressField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsIpv6AddressField_networkOptions on NetworkOptions {
          ipv6Address
          ipv6Enabled
          dhcpEnabled
        }
      `
    );

  const fieldId = networkOptionsIpv6AddressFieldId();
  const disabled = !ipv6Enabled || dhcpEnabled;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="IPv6 Address"
      disabled={!ipv6Enabled}
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={ipv6Address}
        required
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "ipv6Address");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsIpv6AddressField;
