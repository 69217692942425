/**
 * @generated SignedSource<<5ba7bb7f0d56f48a6be2a317b55055db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions$data = {
  readonly alarmReceiverAuthorization?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions";
};
export type RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "alarmReceiverAuthorization",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "2c682dc6e9f60a1057c69895da3253ab";

export default node;
