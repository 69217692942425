App.controller("TypographyCtrl", [
  "$rootScope",
  "$scope",
  "StyleDataAccessService",
  function ($rootScope, $scope, StyleDataAccessService) {
    init();

    function init() {
      StyleDataAccessService.getStyleData("typography-data.json")
        .then(
          function (typoData) {
            $scope.TypographyData = typoData;
            Prism.highlightAll();
          },
          function (typoError) {
            $rootScope.alerts.push({ type: "error", message: typoError });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      $scope.textExample =
        "The quick brown fox jumps over the lazy dog and feels as if he were in the seventh heaven.";
    }
  },
]);
App.directive("bindHtmlUnsafe", function ($parse, $compile) {
  return function ($scope, $element, $attrs) {
    var compile = function (newHTML) {
      newHTML = $compile(newHTML)($scope);
      $element.html("").append(newHTML);
    };

    var htmlName = $attrs.bindHtmlUnsafe;

    $scope.$watch(htmlName, function (newHTML) {
      if (!newHTML) return;
      compile(newHTML);
    });
  };
});
