import { App } from "app-module.js";
import React from "react";
import { react2angular } from "react2angular";

export default function PageHeader({ title }: { title: React.ReactNode }) {
  return <div className="page-header__title">{title}</div>;
}

export function dangerouslyAddToApp() {
  App.component("reactPageHeader", react2angular(PageHeader, ["title"]));
}
