/**
 * @ngdoc service
 * @name App.factory:PanelEventsService
 *
 * @description
 * Service for retrieving panel events for the VAR
 *
 */
App.factory("PanelEventsService", [
  "$q",
  "PanelODataAPI",
  "PanelODataEventHistoriesAPI",
  function ($q, PanelODataAPI, PanelODataEventHistoriesAPI) {
    return {
      /**
       * @ngdoc object
       * @name property:panel_id
       * @type Number
       * @methodOf App.factory:PanelEventsService
       *
       * @description
       * Holds the Panel ID Number.
       */
      panel_id: 0,

      /**
       * @ngdoc object
       * @name method:getPanelEventHistories
       *
       * @description
       * Sends command to panel get statistical data.
       *
       * @param {Number} panel_id of the panel
       * @return {promise} The event histories for a VAR panel
       */
      getPanelEventHistories: function (panelId) {
        const deferred = $q.defer();

        PanelODataEventHistoriesAPI.getPanelEventHistories(
          { panel_id: panelId },
          (data) => {
            const morphedData = data.value.map((event) => {
              const eventVideoData = JSON.parse(event.wealth);
              const morphedEvent = {
                ...event,
                event_stream_url: eventVideoData?.event_stream_url,
                cam_id: eventVideoData?.cam_id,
                camect_hub_id: eventVideoData?.camect_hub_id,
                start_ts_ms: eventVideoData?.start_ts_ms?.[0],
                end_ts_ms: eventVideoData?.end_ts_ms,
                video_url: eventVideoData?.video_url,
              };
              return morphedEvent;
            });

            deferred.resolve(morphedData);
          },
          (error) => {
            console.log(error);
            return deferred.error(error);
          }
        );

        return deferred.promise;
      },
    };
  },
]);
