import * as React from "react";
import styled from "styled-components";

export const LinkThatLooksLikeButton = styled.a`
  margin-right: 0.8rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 3px;
  border: 0.1rem solid;
  color: var(--color-primary);
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

export const ButtonThatLooksLikeLink = styled.button`
  border: none;
  background-color: transparent;
  color: var(--color-primary);
  font-weight: 600;
  transition: all 200ms;

  &:hover {
    color: var(--color-primary-800);
  }
`;

export const IconButtonThatLooksLikeLink = styled.button`
  cursor: pointer;
  border: none;
  background-color: var(--color-info-500);
  color: white;
  font-weight: 600;
  transition: all 200ms;

  &:hover {
    background-color: var(--color-info-600);
  }
`;

export const StyledIconButton = styled.i`
  cursor: pointer;
`;

export const IconButton = ({
  icon,
  color,
  onClick,
  className,
  ...rest
}: {
  icon?: React.ReactNode;
  color?: string | (() => string);
  className?: string;
  onClick?: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledIconButton
      color={color}
      onClick={onClick}
      className={className}
      {...rest}
    >
      {icon}
    </StyledIconButton>
  );
};

export default {
  LinkThatLooksLikeButton,
  IconButtonThatLooksLikeLink,
  ButtonThatLooksLikeLink,
  IconButton,
};
