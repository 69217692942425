import { DaButton } from "components/DaStyledElements";
import React, { useState } from "react";
import ClaimSystemModal from "./ClaimSystemModal";

interface ClaimSystemModalButtonProps {
  $rootScope: any;
  UserService: any;
  $scope: any;
  $state: any;
  controlSystemService: any;

  system: {
    dealerId: string;
    customerId: string;
    panelId: string;
    systemName: string;
    panelAccount: string;
    panelAccountNumber: string;
    panelSerialNumber: string;
  };
}

const ClaimSystemModalButton: React.FC<ClaimSystemModalButtonProps> = ({
  $rootScope,
  UserService,
  $scope,
  $state,
  controlSystemService,
  system,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const disabledButton = () =>
    !system.dealerId || !system.customerId || !system.panelId;
  return (
    <>
      <DaButton onClick={openModal} disabled={disabledButton()}>
        Claim System
      </DaButton>
      {modalIsOpen && (
        <ClaimSystemModal
          handleModalClose={closeModal}
          UserService={UserService}
          $scope={$scope}
          $state={$state}
          controlSystemService={controlSystemService}
          selectedSystem={system}
          onSuccessCloseModal={closeModal}
        />
      )}
    </>
  );
};

export default ClaimSystemModalButton;
