/**
 *Directive matching country code to corresponding country
 */
App.directive("daCodeToCountry", [
  "$compile",
  "COUNTRY_CODES",
  "$filter",
  function ($compile, COUNTRY_CODES, $filter) {
    var linker = function (scope, element, attrs) {
      attrs.$observe("daCodeToCountry", function () {
        if (
          typeof attrs.daCodeToCountry != "undefined" &&
          attrs.daCodeToCountry.length > 0
        ) {
          var c = $filter("filter")(COUNTRY_CODES, {
            code: attrs.daCodeToCountry,
          });
          if (typeof c != "undefined" && c.length > 0) {
            element.replaceWith(
              $compile("<span>" + c[0].country + "</span>")(scope)
            );
          }
        }
      });
    };
    return {
      restrict: "A",
      link: linker,
    };
  },
]);
