App.directive("daTransformIcon", function () {
  var first = true;
  return {
    restrict: "A",
    scope: {
      flag: "=daTransformIcon",
    },
    link: function (scope, element) {
      scope.$watch("flag", function () {
        element.toggleClass("open", scope.flag);
        first = true;
      });
    },
  };
});
