/**
 * @generated SignedSource<<dfd179b3b82973a6ecb0431e23755205>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NacProtocols = "DMP" | "GENTEX" | "SYSTEM_SENSOR" | "UNKNOWN" | "WHEELOCK";
import { FragmentRefs } from "relay-runtime";
export type BellOptionsNac1SyncProtocolField_bellOptions$data = {
  readonly id: string;
  readonly nac1SyncProtocol: NacProtocols | null;
  readonly " $fragmentType": "BellOptionsNac1SyncProtocolField_bellOptions";
};
export type BellOptionsNac1SyncProtocolField_bellOptions$key = {
  readonly " $data"?: BellOptionsNac1SyncProtocolField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsNac1SyncProtocolField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsNac1SyncProtocolField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nac1SyncProtocol",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "828572dee9bd4a1ac6daaad3317b9a44";

export default node;
