App.controller("FireInspectionDue", [
  "$scope",
  "$q",
  "$rootScope",
  "UserService",
  "DashboardDataService",
  "DTColumnBuilder",
  "DataTablesUtilsService",
  function (
    $scope,
    $q,
    $rootScope,
    UserService,
    DashboardDataService,
    DTColumnBuilder,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      fireInspectionDueEvents()
    );
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption("order", [0, "asc"], [1, "asc"], [2, "asc"]);

    function fireInspectionDueEvents() {
      var deferred = $q.defer();
      DashboardDataService.getFireInspectionDueEvents($scope.dealerID)
        .then(
          function (systems) {
            deferred.resolve(systems);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error getting systems",
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }
    $scope.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle("Account Number")
        .withOption("aDataSort", [0, 1])
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "account_number");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "panel_name");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Name")
        .withOption("aDataSort", [0, 1])
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "customer_name");
        }),
      DTColumnBuilder.newColumn("fire_inspection_due_event_date")
        .withTitle("Received")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
      DTColumnBuilder.newColumn("fire_inspection_complete_event_date")
        .withTitle("Completed")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
    ];
  },
]);
