import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { prop } from "ramda";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  FeatureKeyEnum,
  NetworkOptions,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { NetworkOptionsContextProvider } from "../common/NetworkOptionsFields/NetworkOptionsContext";
import NetworkOptionsDhcpEnabledField from "../common/NetworkOptionsFields/NetworkOptionsDhcpEnabledField";
import NetworkOptionsDnsServerField from "../common/NetworkOptionsFields/NetworkOptionsDnsServerField";
import NetworkOptionsGatewayAddressField from "../common/NetworkOptionsFields/NetworkOptionsGatewayAddressField";
import NetworkOptionsIpv6AddressField from "../common/NetworkOptionsFields/NetworkOptionsIpv6AddressField";
import NetworkOptionsIpv6DnsServerField from "../common/NetworkOptionsFields/NetworkOptionsIpv6DnsServerField";
import NetworkOptionsIpv6GatewayField from "../common/NetworkOptionsFields/NetworkOptionsIpv6GatewayField";
import NetworkOptionsIpv6PrefixBitsField from "../common/NetworkOptionsFields/NetworkOptionsIpv6PrefixBitsField";
import NetworkOptionsListenPort734NField from "../common/NetworkOptionsFields/NetworkOptionsListenPort734NField";
import NetworkOptionsLocalIpAddressField from "../common/NetworkOptionsFields/NetworkOptionsLocalIpAddressField";
import NetworkOptionsPassphrase734NField from "../common/NetworkOptionsFields/NetworkOptionsPassphrase734NField";
import NetworkOptionsPassphraseField from "../common/NetworkOptionsFields/NetworkOptionsPassphraseField";
import NetworkOptionsSubnetMaskField from "../common/NetworkOptionsFields/NetworkOptionsSubnetMaskField";
import NetworkOptionsUseIpv6Field from "../common/NetworkOptionsFields/NetworkOptionsUseIpv6Field";
import NetworkOptionsWifiPassphraseField from "../common/NetworkOptionsFields/NetworkOptionsWifiPassphraseField";
import NetworkOptionsWifiSecurityField from "../common/NetworkOptionsFields/NetworkOptionsWifiSecurityField";
import NetworkOptionsWifiSsidField from "../common/NetworkOptionsFields/NetworkOptionsWifiSsidField";
import NetworkOptionsXrX1CommunicationField from "../common/NetworkOptionsFields/NetworkOptionsXrX1CommunicationField";
import NetworkOptionsXrX1PassphraseField from "../common/NetworkOptionsFields/NetworkOptionsXrX1PassphraseField";
import { panelVersionGTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  XRNetworkOptionsProgrammingConceptFormInline_controlSystem$data,
  XRNetworkOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XRNetworkOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XRNetworkOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRNetworkOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XRNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation,
} from "./__generated__/XRNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation.graphql";
import {
  XRNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation,
  XRNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation$data,
} from "./__generated__/XRNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation.graphql";
import { XRNetworkOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XRNetworkOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Network Options";
export const conceptId = "xr-network-options";

export const getState = (
  controlSystem: XRNetworkOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRNetworkOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          networkOptions {
            id
            wifiSsid
            wifiSecurity
            wifiPassphrase
            wifiPassphraseMaxLength
            dhcpEnabled
            localIpAddress
            gatewayAddress
            subnetMask
            dnsServer
            listenPort734N
            listenPort734NMin
            listenPort734NMax
            passphrase734N
            ipv6Enabled
            ipv6Address
            ipv6Gateway
            ipv6Prefix
            ipv6Dns
            programmingPort
            passphrase
            enableDdp
            ddpPassphrase
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XRNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshNetworkOptions(id: $id) {
      ... on RefreshNetworkOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XRNetworkOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XRNetworkOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshNetworkOptions, isRefreshing] =
    useMutation<XRNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshNetworkOptions({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshNetworkOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Network Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshNetworkOptions: {
                    __typename: response.refreshNetworkOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Network Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Network Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XRNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation(
    $systemId: ID!
    $networkOptions: NetworkOptionsInput!
  ) {
    sendNetworkOptions(systemId: $systemId, networkOptions: $networkOptions) {
      ... on SendNetworkOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XRNetworkOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendNetworkOptionsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XRNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation$data,
  originalControlSystemData: XRNetworkOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendNetworkOptions.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshNetworkOptions: {
          __typename: "RefreshNetworkOptionsSuccessPayload",
          controlSystem: getState(response.sendNetworkOptions.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XRNetworkOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendNetworkOptions, isSendingNetworkOptions] =
    useMutation<XRNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { networkOptions },
        } = getState(props.controlSystem);
        if (networkOptions) {
          sendNetworkOptions({
            variables: {
              systemId,
              networkOptions: {
                wifiSsid: networkOptions.wifiSsid,
                wifiSecurity: networkOptions.wifiSecurity,
                wifiPassphrase: networkOptions.wifiPassphrase,
                dhcpEnabled: networkOptions.dhcpEnabled,
                localIpAddress: networkOptions.localIpAddress,
                gatewayAddress: networkOptions.gatewayAddress,
                subnetMask: networkOptions.subnetMask,
                dnsServer: networkOptions.dnsServer,
                listenPort734N: networkOptions.listenPort734N,
                passphrase734N: networkOptions.passphrase734N,
                passphrase: networkOptions.passphrase,
                ipv6Enabled: networkOptions.ipv6Enabled,
                ipv6Address: networkOptions.ipv6Address,
                ipv6Gateway: networkOptions.ipv6Gateway,
                ipv6Prefix: networkOptions.ipv6Prefix,
                ipv6Dns: networkOptions.ipv6Dns,
                programmingPort: networkOptions.programmingPort,
                enableDdp: networkOptions.enableDdp,
                ddpPassphrase: networkOptions.ddpPassphrase,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendNetworkOptions.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Network Options Programming Saved To the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendNetworkOptions.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendNetworkOptions.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSendingNetworkOptions,
  ];
};

const readNetworkOptionsTemplateData = (
  programmingTemplateConcepts: XRNetworkOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRNetworkOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        networkOptions {
          included
          wifiSsid {
            included
            data
          }
          wifiSecurity {
            included
            data
          }
          wifiPassphrase {
            included
            data
          }
          dhcpEnabled {
            included
            data
          }
          localIpAddress {
            included
            data
          }
          gatewayAddress {
            included
            data
          }
          subnetMask {
            included
            data
          }
          dnsServer {
            included
            data
          }
          listenPort734N {
            included
            data
          }
          passphrase734N {
            included
            data
          }
          ipv6Enabled {
            included
            data
          }
          ipv6Address {
            included
            data
          }
          ipv6Gateway {
            included
            data
          }
          ipv6Prefix {
            included
            data
          }
          ipv6Dns {
            included
            data
          }
          programmingPort {
            included
            data
          }
          passphrase {
            included
            data
          }
          enableDdp {
            included
            data
          }
          ddpPassphrase {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).networkOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XRNetworkOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readNetworkOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const networkOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "networkOptions",
      "NetworkOptions"
    ) as unknown as RecordProxy<NetworkOptions>;

    if (templateData.ipv6Enabled?.included && templateData.ipv6Enabled.data) {
      networkOptionsRecordProxy.setValue(true, "dhcpEnabled");
      networkOptionsRecordProxy.setValue(
        templateData.ipv6Enabled.data ? "064" : "000",
        "ipv6Prefix"
      );
    }

    applyTemplateScalarDataToRecordProxy(
      networkOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRNetworkOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRNetworkOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            featureKeys {
              key
            }
            networkOptions {
              id
              ...NetworkOptionsContext_networkOptions
              ...NetworkOptionsWifiSsidField_networkOptions
              ...NetworkOptionsWifiSecurityField_networkOptions
              ...NetworkOptionsWifiPassphraseField_networkOptions
              ...NetworkOptionsUseIpv6Field_networkOptions
              ...NetworkOptionsDhcpEnabledField_networkOptions
              ...NetworkOptionsLocalIpAddressField_networkOptions
              ...NetworkOptionsGatewayAddressField_networkOptions
              ...NetworkOptionsSubnetMaskField_networkOptions
              ...NetworkOptionsDnsServerField_networkOptions
              ...NetworkOptionsIpv6AddressField_networkOptions
              ...NetworkOptionsIpv6GatewayField_networkOptions
              ...NetworkOptionsIpv6PrefixBitsField_networkOptions
              ...NetworkOptionsIpv6DnsServerField_networkOptions
              ...NetworkOptionsListenPort734NField_networkOptions
              ...NetworkOptionsPassphrase734NField_networkOptions
              ...NetworkOptionsPassphraseField_networkOptions
              ...NetworkOptionsXrX1PassphraseField_networkOptions
              ...NetworkOptionsXrX1CommunicationField_networkOptions
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHasWifiCommType_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );
  const softwareVersion = Number(controlSystem.panel.softwareVersion);

  const {
    panel: {
      featureKeys,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const encryptionFeatureKeyEnabled = featureKeys
    .map(prop("key"))
    .includes(FeatureKeyEnum.ENCRYPTION);

  // TODO: Add support for international panels if they have extra fields

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Network%20Options`}
        title={title}
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.networkOptions
        )}
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          <NetworkOptionsContextProvider
            networkOptions={controlSystem.panel.networkOptions}
          >
            <ProgrammingConceptForm.Fields>
              {panelVersionGTOE(202, softwareVersion) ? (
                <>
                  <NetworkOptionsWifiSsidField />
                  <NetworkOptionsWifiSecurityField />
                  <NetworkOptionsWifiPassphraseField />
                </>
              ) : null}
              {panelVersionGTOE(191, softwareVersion) ? (
                <NetworkOptionsUseIpv6Field />
              ) : null}
              <NetworkOptionsDhcpEnabledField />
              <NetworkOptionsLocalIpAddressField />
              <NetworkOptionsGatewayAddressField />
              <NetworkOptionsSubnetMaskField />
              <NetworkOptionsDnsServerField />
              {panelVersionGTOE(191, softwareVersion) ? (
                <>
                  <NetworkOptionsIpv6AddressField />
                  <NetworkOptionsIpv6GatewayField />
                  <NetworkOptionsIpv6PrefixBitsField />
                  <NetworkOptionsIpv6DnsServerField />
                </>
              ) : null}
              <NetworkOptionsListenPort734NField />
              <NetworkOptionsPassphrase734NField />
              {encryptionFeatureKeyEnabled ? (
                <NetworkOptionsPassphraseField />
              ) : null}
              {panelVersionGTOE(231, softwareVersion) ? (
                <>
                  <NetworkOptionsXrX1CommunicationField />
                  <NetworkOptionsXrX1PassphraseField />
                </>
              ) : null}
            </ProgrammingConceptForm.Fields>
          </NetworkOptionsContextProvider>
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}
