/**
 * @generated SignedSource<<1afab39cccb28bfd2f3f31f1ce2308d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type AreaBadZonesField_area$data = {
  readonly autoArmEnabled: boolean;
  readonly badZonesOption: BadZonesOption;
  readonly id: string;
  readonly number: string;
  readonly " $fragmentType": "AreaBadZonesField_area";
};
export type AreaBadZonesField_area$key = {
  readonly " $data"?: AreaBadZonesField_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaBadZonesField_area">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaBadZonesField_area",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badZonesOption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoArmEnabled",
      "storageKey": null
    }
  ],
  "type": "Area",
  "abstractKey": "__isArea"
};

(node as any).hash = "57f965b3da028bdd59f80f25b281820e";

export default node;
