/**
 * @ngdoc directive
 * @name App.directive:dynamicInputName
 * @function
 *
 * @description
 * Converts a variable into a form input attribute, then compile's the view
 */
App.directive("daDynamicInputName", function ($compile) {
  return {
    restrict: "A",
    terminal: true,
    priority: 1000,
    link: function (scope, element, attrs) {
      var errors = attrs.dynamicModelErrors;
      if (errors) {
        element.attr("ng-model-errors", errors);
        element.removeAttr("dynamic-model-errors");
      }

      // dynamic-input-model is
      var model = attrs.dynamicInputModel;
      if (model) {
        element.attr("ng-model", model);
        element.removeAttr("dynamic-input-model");
      }

      // dynamic-input-name is not a scoped attribute, so we have to $eval it to get the value.  Otherwise it would be a string.
      var name = scope.$eval(attrs.daDynamicInputName);
      if (name) {
        element.attr("name", name);
        element.removeAttr("da-dynamic-input-name");
        $compile(element)(scope);
      }
    },
  };
});
