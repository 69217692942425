import {
  DeleteConfirmModalBody,
  DeleteConfirmModalOverlay,
} from "components/Modal/ConfirmModal";
import * as React from "react";

const UnavailableOutputWarningModal = ({
  isOpen,
  onYes,
  onNo,
}: {
  isOpen: boolean;
  onYes: () => void;
  onNo: () => void;
}) =>
  isOpen ? (
    <DeleteConfirmModalOverlay>
      <DeleteConfirmModalBody
        onCancel={onNo}
        onConfirm={onYes}
        confirmText="Yes"
        cancelText="No"
        header="Unavailable Output"
      >
        This output is currently in use. Would you like to overwrite this
        output? Any rules created from the existing output will be deleted.
      </DeleteConfirmModalBody>
    </DeleteConfirmModalOverlay>
  ) : null;

export default UnavailableOutputWarningModal;
