import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathReceiverPortField_communicationPath$key } from "./__generated__/CommPathReceiverPortField_communicationPath.graphql";

export const commPathReceiverPortFieldId = (number: string) =>
  `comm-path-receiver-port-${number}`;

function CommPathReceiverPortField() {
  const [{ number, commType, receiverPort }, updateCommPath] =
    useCommPathFragment<CommPathReceiverPortField_communicationPath$key>(
      graphql`
        fragment CommPathReceiverPortField_communicationPath on CommunicationPath {
          number
          commType
          receiverPort
        }
      `
    );

  const fieldId = commPathReceiverPortFieldId(String(number));
  const disabled = !["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Receiver IP Port"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={receiverPort}
        disabled={disabled}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        min={1}
        max={65535}
        inlineHelp="1-65535"
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "receiverPort");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(1, 65535, Number(target.value));
          updateCommPath((recordProxy) => {
            recordProxy.setValue(
              String(value.toString().padStart(5, `0`)),
              "receiverPort"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathReceiverPortField;
