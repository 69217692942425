/**
 * @generated SignedSource<<efce788c2f63f48cdcb073ac375352e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Grades = "GRADE_0" | "GRADE_2" | "GRADE_3";
import { FragmentRefs } from "relay-runtime";
export type XTSecurityGradeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly securityGrade: {
      readonly grade: Grades;
      readonly id: string;
      readonly updatedAt: string;
    } | null;
  };
  readonly " $fragmentType": "XTSecurityGradeProgrammingConceptFormInline_controlSystem";
};
export type XTSecurityGradeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTSecurityGradeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSecurityGradeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTSecurityGradeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "f259435091bdd4824f57fff3b4004ad8";

export default node;
