/**
 * @generated SignedSource<<4533761d06fb9d6961c9acaee3b8e154>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagsSettingsTableQuery$variables = {
  dealerId: string;
};
export type TagsSettingsTableQuery$data = {
  readonly getDealerTagsRelationsCounts: ReadonlyArray<{
    readonly dealerTag: {
      readonly description: string | null;
      readonly id: string;
      readonly name: string | null;
    };
    readonly tagCustomerCount: number;
    readonly tagPanelCount: number;
  } | null>;
};
export type TagsSettingsTableQuery = {
  response: TagsSettingsTableQuery$data;
  variables: TagsSettingsTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "dealerId"
      }
    ],
    "concreteType": "TagRelationsCounts",
    "kind": "LinkedField",
    "name": "getDealerTagsRelationsCounts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "dealerTag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagCustomerCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagPanelCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagsSettingsTableQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagsSettingsTableQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "67575820494dd1d47f7e324b6b537f55",
    "id": null,
    "metadata": {},
    "name": "TagsSettingsTableQuery",
    "operationKind": "query",
    "text": "query TagsSettingsTableQuery(\n  $dealerId: ID!\n) {\n  getDealerTagsRelationsCounts(id: $dealerId) {\n    dealerTag {\n      id\n      name\n      description\n    }\n    tagCustomerCount\n    tagPanelCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5276e66a204f766352f09c6208c732f";

export default node;
