/**
 * @generated SignedSource<<be68ee1b25dda4a4a9f88f5cd32a33be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRSecurityGradeProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly securityGrade: {
    readonly grade: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XRSecurityGradeProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRSecurityGradeProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRSecurityGradeProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRSecurityGradeProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRSecurityGradeProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "f613df99ac986387466752e401d0e5ca";

export default node;
