App.factory("GetEulaAgreementAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v4/user_agreements/mine",
      {},
      {
        getEula: {
          method: "Get",
        },
      }
    );
  },
]);
