import { setDelete, setToggle } from "common/utils/universal/set";
import { curry } from "ramda";
import { useIncludedTemplateFields } from "../common/IncludedTemplateFieldsContext";
import {
  removeAllInvalidFieldsFromListItem,
  useInvalidFields,
  useInvalidListItemFields,
  useSetInvalidFields,
} from "../common/InvalidFieldsContext";
import { useProgrammingConceptFormListItemIdContext } from "../common/ProgrammingConceptFormListItemIdContext";
import { useProgrammingConceptIdContext } from "../common/ProgrammingConceptIdContext";

export const useUncheckListItem = () => {
  const setIncludedFields = useIncludedTemplateFields()[1];

  return curry((FIELD_IDS: ((number: string) => string)[], number: string) =>
    FIELD_IDS.forEach((id) => {
      const fieldId = id(number);
      setIncludedFields(setDelete(fieldId));
    })
  );
};

export const useUpdateListItemOnNumberChange = (
  FIELD_IDS: ((number: string) => string)[],
  duplicateItemsExist: boolean,
  currentNumber: string,
  listItemTemplateId: (number: string) => string
): [(newNumber: string) => void, (newNumber: string) => void] => {
  const updateListItemOnChange = useUpdateListItemOnChange(
    FIELD_IDS,
    duplicateItemsExist,
    currentNumber,
    listItemTemplateId
  );

  const updateListItemOnBlur = useUpdateListItemOnBlur(
    FIELD_IDS,
    duplicateItemsExist,
    currentNumber,
    listItemTemplateId
  );

  return [updateListItemOnChange, updateListItemOnBlur];
};

export const useUpdateListItemOnChange = (
  FIELD_IDS: ((number: string) => string)[],
  duplicateAreasExist: boolean,
  currentNumber: string,
  listItemTemplateId: (number: string) => string
) => {
  const [includedFields, setIncludedFields] = useIncludedTemplateFields();
  const setInvalidFields = useSetInvalidFields();
  const invalidFields = useInvalidFields();
  const programmingConceptId = useProgrammingConceptIdContext();
  const listItemId = useProgrammingConceptFormListItemIdContext();
  const itemInvalidFields = useInvalidListItemFields(listItemId);

  return function onChange(newNumber: string) {
    if (itemInvalidFields.size > 0) {
      //This is for clearing the invalid fields on the list item when the number is changed
      setInvalidFields(
        removeAllInvalidFieldsFromListItem(
          programmingConceptId,
          listItemId,
          invalidFields
        )
      );
    }
    if (
      //This is for moving the selected fields to the new list item from the old list item
      !includedFields.has(listItemTemplateId(newNumber)) &&
      !duplicateAreasExist
    ) {
      setIncludedFields(setToggle(listItemTemplateId(newNumber)));
      FIELD_IDS.forEach((id) => {
        const oldId = id(currentNumber);
        setIncludedFields(setDelete(oldId));
      });
    } else if (
      !includedFields.has(listItemTemplateId(newNumber)) &&
      duplicateAreasExist
    ) {
      setIncludedFields(setToggle(listItemTemplateId(newNumber)));
    }
  };
};

export const useUpdateListItemOnBlur = (
  FIELD_IDS: ((number: string) => string)[],
  duplicateAreasExist: boolean,
  currentNumber: string,
  listItemTemplateId: (number: string) => string
) => {
  const [includedFields, setIncludedFields] = useIncludedTemplateFields();
  const setInvalidFields = useSetInvalidFields();
  const invalidFields = useInvalidFields();
  const programmingConceptId = useProgrammingConceptIdContext();
  const listItemId = useProgrammingConceptFormListItemIdContext();
  const itemInvalidFields = useInvalidListItemFields(listItemId);

  return function onBlur(newNumber: string) {
    if (itemInvalidFields.size > 0) {
      //This is for clearing the invalid fields on the list item when the number is changed on blur
      setInvalidFields(
        removeAllInvalidFieldsFromListItem(
          programmingConceptId,
          listItemId,
          invalidFields
        )
      );
    }
    if (
      //This is for moving the selected fields to the new list item from the old list item
      !includedFields.has(listItemTemplateId(newNumber)) &&
      !duplicateAreasExist
    ) {
      FIELD_IDS.forEach((id) => {
        const oldId = id(currentNumber);
        const newId = id(newNumber.toString());
        if (includedFields.has(oldId)) {
          setIncludedFields(setToggle(oldId));
          setIncludedFields(setToggle(newId));
        }
      });
    } else if (
      !includedFields.has(listItemTemplateId(newNumber)) &&
      duplicateAreasExist
    ) {
      setIncludedFields(setToggle(listItemTemplateId(newNumber)));
    }
  };
};
