/**
 * @generated SignedSource<<da2d276dd563a81b0bb4fabc6b2d2f1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Device734CardOptions = "ANY" | "CUSTOM" | "DMP" | "NONE";
export type Device734NoCommWithPanelRelayAction = "ANY" | "LAST" | "OFF" | "ON" | "SITE";
export type DeviceInformation1100TWirelessFrequencyType = "DSC" | "HONEYWELL" | "INTERLOGIX" | "NONE" | "_2GIG";
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
export type DeviceInformationWirelessSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XRDeviceSetupProgrammingConceptFormInline_deviceInformation$data = {
  readonly __typename: string;
  readonly accessAreas?: string;
  readonly autoForceArm?: boolean;
  readonly axNumber?: string;
  readonly device734?: {
    readonly cardFormatOptions: Device734CardOptions | null;
    readonly enableOnboardSpeaker: boolean | null;
    readonly enableZone3RequestToExit: boolean | null;
    readonly enforceSiteCode: boolean | null;
    readonly noCommWithPanelRelayAction: Device734NoCommWithPanelRelayAction | null;
    readonly number: number | null;
    readonly numberOfUserCodeDigits: number | null;
    readonly siteCode1: string | null;
    readonly siteCode2: string | null;
    readonly siteCode3: string | null;
    readonly siteCode4: string | null;
    readonly siteCode5: string | null;
    readonly siteCode6: string | null;
    readonly siteCode7: string | null;
    readonly siteCode8: string | null;
    readonly siteCodeBitLength: number | null;
    readonly siteCodeStartBitPosition: number | null;
    readonly userCodeBitLength: number | null;
    readonly userCodeStartBitPosition: number | null;
    readonly wiegandBitLength: number | null;
    readonly zone2BypassOnRequestToExit: boolean | null;
    readonly zone2BypassTime: number | null;
    readonly zone2RelockOnStateChange: boolean | null;
    readonly zone3RequestToExitTime: number | null;
  } | null;
  readonly deviceCommunicationMethod?: DeviceInformationCommType;
  readonly deviceType?: DeviceInformationType;
  readonly displayAreas?: string;
  readonly doorForceMessages?: boolean;
  readonly doorRealTimeStatusMessages?: boolean;
  readonly egressAreas?: string;
  readonly fireExit?: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly lxNumber?: string;
  readonly name?: string;
  readonly number?: number;
  readonly overrideScheduleWhenArmed?: boolean;
  readonly pinDisarm?: boolean | null;
  readonly privateDoor?: boolean;
  readonly publicDoor?: boolean;
  readonly remoteProgram734?: boolean;
  readonly serialNumber?: string;
  readonly strikeDelay?: number;
  readonly strikeTime?: number;
  readonly supervisionTime?: DeviceInformationWirelessSupervisionTime | null;
  readonly triggerOutputGroupWithAccess?: boolean;
  readonly wirelessTranslator1100t?: boolean;
  readonly wirelessTranslator1100tFrequency?: DeviceInformation1100TWirelessFrequencyType;
  readonly " $fragmentType": "XRDeviceSetupProgrammingConceptFormInline_deviceInformation";
};
export type XRDeviceSetupProgrammingConceptFormInline_deviceInformation$key = {
  readonly " $data"?: XRDeviceSetupProgrammingConceptFormInline_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRDeviceSetupProgrammingConceptFormInline_deviceInformation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRDeviceSetupProgrammingConceptFormInline_deviceInformation"
};

(node as any).hash = "11af965775d5111b3d85966f405960b5";

export default node;
