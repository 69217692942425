/**
 * @generated SignedSource<<ccc5135262d9f43893997be9ea8302c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver1SecondIpAddressField_communication$data = {
  readonly receiver1Ip2: string;
  readonly " $fragmentType": "CommunicationReceiver1SecondIpAddressField_communication";
};
export type CommunicationReceiver1SecondIpAddressField_communication$key = {
  readonly " $data"?: CommunicationReceiver1SecondIpAddressField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver1SecondIpAddressField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver1SecondIpAddressField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver1Ip2",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "9e664c4a415f9ed950797af1935dc53d";

export default node;
