/**
 * @generated SignedSource<<152e556bd10564bd27c558c2fc0a1cd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccessibleType = "CUSTOMER" | "DEALER" | "SUPERVISOR";
export type ClientApplication = "DEALER_ADMIN" | "VIRTUAL_KEYPAD";
export type DealerRole = "ACCOUNTANT" | "ADMIN" | "OPERATOR" | "SALES_MANAGER" | "SALES_PERSON" | "TECHNICIAN" | "VIDEO_VERIFIER";
export type SupervisorRole = "ADMIN" | "OPERATOR" | "TECHNICIAN";
export type UserRole = "ACCESS_ONLY" | "ADMIN" | "STANDARD";
export type ValidationMethod = "EMAIL" | "NONE" | "SMS";
export type LoginForm_TwoFALoginAuthorizationMutation$variables = {
  clientApplication: ClientApplication;
  code: string;
  isV4Login?: boolean | null;
  uuid: string;
};
export type LoginForm_TwoFALoginAuthorizationMutation$data = {
  readonly validateTwoFactorCode: {
    readonly __typename: "ValidateTwoFactorCodeErrorResponse";
    readonly __typename: "ValidateTwoFactorCodeErrorResponse";
    readonly error: {
      readonly __typename: string;
    };
  } | {
    readonly __typename: "ValidateTwoFactorCodeSuccessResponse";
    readonly __typename: "ValidateTwoFactorCodeSuccessResponse";
    readonly users: ReadonlyArray<{
      readonly authToken: string;
      readonly user: {
        readonly id: string;
        readonly twoFactorSettings: {
          readonly __typename: "TwoFactorSettings";
          readonly enabled: boolean;
          readonly phoneNumber: string | null;
          readonly requiredByCustomer: boolean;
          readonly requiredByDealer: boolean;
          readonly validationMethod: ValidationMethod;
        } | null;
      };
    }>;
  } | {
    readonly __typename: "ValidateTwoFactorCodeSuccessResponseWithBearerToken";
    readonly __typename: "ValidateTwoFactorCodeSuccessResponseWithBearerToken";
    readonly users: ReadonlyArray<{
      readonly bearerToken: string;
      readonly refreshToken: string;
      readonly user: {
        readonly __typename: string;
        readonly accessible_id: number;
        readonly accessible_type: AccessibleType;
        readonly customerRole?: UserRole;
        readonly dealerRole?: DealerRole;
        readonly email: string;
        readonly id: string;
        readonly supervisorRole?: SupervisorRole;
      };
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type LoginForm_TwoFALoginAuthorizationMutation = {
  response: LoginForm_TwoFALoginAuthorizationMutation$data;
  variables: LoginForm_TwoFALoginAuthorizationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientApplication"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "code"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isV4Login"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v4 = [
  {
    "kind": "Variable",
    "name": "clientApplication",
    "variableName": "clientApplication"
  },
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  },
  {
    "kind": "Variable",
    "name": "isV4Login",
    "variableName": "isV4Login"
  },
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "accessibleType",
          "value": [
            "DEALER",
            "SUPERVISOR"
          ]
        }
      ],
      "concreteType": "AuthenticatedV4UserPayload",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bearerToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refreshToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": "accessible_type",
              "args": null,
              "kind": "ScalarField",
              "name": "accessibleType",
              "storageKey": null
            },
            {
              "alias": "accessible_id",
              "args": null,
              "kind": "ScalarField",
              "name": "accessibleId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "customerRole",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                }
              ],
              "type": "CustomerUser",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "dealerRole",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                }
              ],
              "type": "DealerUser",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "supervisorRole",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                }
              ],
              "type": "SupervisorUser",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "users(accessibleType:[\"DEALER\",\"SUPERVISOR\"])"
    }
  ],
  "type": "ValidateTwoFactorCodeSuccessResponseWithBearerToken",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authToken",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredByDealer",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validationMethod",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredByCustomer",
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ValidateTwoFactorCodeErrorResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginForm_TwoFALoginAuthorizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "validateTwoFactorCode",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthenticateUserPayload",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TwoFactorSettings",
                        "kind": "LinkedField",
                        "name": "twoFactorSettings",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ValidateTwoFactorCodeSuccessResponse",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoginForm_TwoFALoginAuthorizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "validateTwoFactorCode",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthenticateUserPayload",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TwoFactorSettings",
                        "kind": "LinkedField",
                        "name": "twoFactorSettings",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ValidateTwoFactorCodeSuccessResponse",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b11e7ed64cd35e5f0385c45a355af578",
    "id": null,
    "metadata": {},
    "name": "LoginForm_TwoFALoginAuthorizationMutation",
    "operationKind": "mutation",
    "text": "mutation LoginForm_TwoFALoginAuthorizationMutation(\n  $uuid: String!\n  $code: String!\n  $clientApplication: ClientApplication!\n  $isV4Login: Boolean\n) {\n  validateTwoFactorCode(uuid: $uuid, code: $code, clientApplication: $clientApplication, isV4Login: $isV4Login) {\n    __typename\n    ... on ValidateTwoFactorCodeSuccessResponseWithBearerToken {\n      __typename\n      users(accessibleType: [DEALER, SUPERVISOR]) {\n        bearerToken\n        refreshToken\n        user {\n          id\n          accessible_type: accessibleType\n          accessible_id: accessibleId\n          email\n          __typename\n          ... on CustomerUser {\n            customerRole: role\n          }\n          ... on DealerUser {\n            dealerRole: role\n          }\n          ... on SupervisorUser {\n            supervisorRole: role\n          }\n        }\n      }\n    }\n    ... on ValidateTwoFactorCodeSuccessResponse {\n      __typename\n      users {\n        authToken\n        user {\n          __typename\n          id\n          twoFactorSettings {\n            enabled\n            requiredByDealer\n            validationMethod\n            phoneNumber\n            requiredByCustomer\n            __typename\n            id\n          }\n        }\n      }\n    }\n    ... on ValidateTwoFactorCodeErrorResponse {\n      error {\n        __typename\n      }\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "021d1de17056240eec8fa64769431f22";

export default node;
