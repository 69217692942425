import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZonePirSensitivity, ZoneSensorType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { wirelessPirSensitivityEnabledSerialRange } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationPIRSensitivityField_zone$key } from "./__generated__/ZoneInformationPIRSensitivityField_zone.graphql";

export const zoneInformationPIRSensitivityFieldId = (number: string) =>
  `zone-information-pir-sensitivity-${number}`;

function ZoneInformationPIRSensitivityField() {
  const [
    { number, wireless, wirelessPirSensitivity, sensorType, serialNumber },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationPIRSensitivityField_zone$key>(
    graphql`
      fragment ZoneInformationPIRSensitivityField_zone on Zone {
        number
        wireless
        wirelessPirSensitivity
        sensorType
        serialNumber
      }
    `
  );
  const fieldId = zoneInformationPIRSensitivityFieldId(String(number));
  const serialNumberSetValid =
    Number(serialNumber) >= wirelessPirSensitivityEnabledSerialRange.min &&
    Number(serialNumber) <= wirelessPirSensitivityEnabledSerialRange.max;
  const disabled = !(
    wireless &&
    ZoneSensorType.WIRELESS_PIR === sensorType &&
    serialNumberSetValid
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="PIR Sensitivity"
      tooltip="Select PIR sensitivity: HIGH = maximum sensitivity LOW = 75% sensitivity for harsh environments"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={wirelessPirSensitivity ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "wirelessPirSensitivity");
          });
        }}
      >
        <Select.Option value={ZonePirSensitivity.LOW}>Low</Select.Option>
        <Select.Option value={ZonePirSensitivity.HIGH}>High</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationPIRSensitivityField;
