/**
 * @generated SignedSource<<ff1424acb686a9bc9025464f1e5230a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputInformationProgrammingConceptFormInline_output$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: number;
  readonly realTimeStatus: boolean | null;
  readonly " $fragmentType": "XFOutputInformationProgrammingConceptFormInline_output";
};
export type XFOutputInformationProgrammingConceptFormInline_output$key = {
  readonly " $data"?: XFOutputInformationProgrammingConceptFormInline_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputInformationProgrammingConceptFormInline_output">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputInformationProgrammingConceptFormInline_output"
};

(node as any).hash = "1af6e9b79a1cd2a71e6a777f7fe98463";

export default node;
