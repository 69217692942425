/**
 * @generated SignedSource<<393adbaf30f1d59954d4f4a3326f4520>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathUse893AField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly use893A: boolean;
  readonly " $fragmentType": "CommPathUse893AField_communicationPath";
};
export type CommPathUse893AField_communicationPath$key = {
  readonly " $data"?: CommPathUse893AField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathUse893AField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathUse893AField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "use893A",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "ea83f268370c46bb26d4a77ebb5a740c";

export default node;
