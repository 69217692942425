import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationClosingCodeField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationClosingCodeField_systemAreaInformation.graphql";

export const systemAreaInformationClosingCodeFieldId = () =>
  `system-area-information-closing-code`;

function SystemAreaInformationClosingCodeField() {
  const [{ closingCode }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationClosingCodeField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationClosingCodeField_systemAreaInformation on SystemAreaInformation {
          closingCode
        }
      `
    );

  const fieldId = systemAreaInformationClosingCodeFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Closing Code"
      tooltip="If enabled, a User Code is required to arm the System."
    >
      <Switch
        label="Closing Code"
        id={fieldId}
        checked={closingCode}
        onChange={() => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(!closingCode, "closingCode");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationClosingCodeField;
