/**
 * @generated SignedSource<<72b12e6028461a2df9f9513d19451732>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemAreaInformationAnyBypassField_systemAreaInformation$data = {
  readonly anyBypass: boolean;
  readonly " $fragmentType": "SystemAreaInformationAnyBypassField_systemAreaInformation";
};
export type SystemAreaInformationAnyBypassField_systemAreaInformation$key = {
  readonly " $data"?: SystemAreaInformationAnyBypassField_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemAreaInformationAnyBypassField_systemAreaInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemAreaInformationAnyBypassField_systemAreaInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anyBypass",
      "storageKey": null
    }
  ],
  "type": "SystemAreaInformation",
  "abstractKey": null
};

(node as any).hash = "d3d340a4f08e5251a31c9009a8755cf7";

export default node;
