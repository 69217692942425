App.factory("RefreshPanelStatisticsV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    // See existing API documentation on line below
    // http://jenkins.dmp.com:8080/view/Farad%20(VK)/job/farad-panel_statistics/ws/doc/Api/V2/RefreshedPanelStatisticsController.html  (docs)
    return $resource(
      PROPS.apiUrl + "/v2/refreshed_panel_statistics/:object_id",
      {
        json: 1, //required flag to instruct the API to return a JSON structure (instead of xml)
      },
      {
        refreshPanelStatistics: {
          method: "GET",
          params: { object_id: "@object_id" },
        },
      }
    );
  },
]);

/**
 * @ngdoc service
 * @name App.factory:PanelStatisticsV2API
 *
 * @description
 * API factory for Panel Statistics Data Retrieval
 *
 */
App.factory("PanelStatisticsV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    // See existing API documentation on line below
    // http://jenkins.dmp.com:8080/view/Farad%20(VK)/job/farad-panel_statistics/ws/doc/Api/V2/PanelStatisticsController.html (docs)
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/panel_statistics",
      {
        //json: 1 //required flag to instruct the API to return a JSON structure (instead of xml)
      },
      {
        getPanelStatistics: {
          method: "GET",
          params: { panel_id: "@panel_id" },
          isArray: true,
        },
      }
    );
  },
]);
