/**
 * @generated SignedSource<<da0ded84b72a0659ca39e8b9926d893d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Device734CardOptions = "ANY" | "CUSTOM" | "DMP" | "NONE";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationCardOptionsField_deviceInformation$data = {
  readonly device734?: {
    readonly cardFormatOptions: Device734CardOptions | null;
    readonly enforceSiteCode: boolean | null;
    readonly numberOfUserCodeDigits: number | null;
  } | null;
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly number: number;
  readonly remoteProgram734?: boolean;
  readonly " $fragmentType": "DeviceInformationCardOptionsField_deviceInformation";
};
export type DeviceInformationCardOptionsField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationCardOptionsField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationCardOptionsField_deviceInformation">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remoteProgram734",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Device734",
    "kind": "LinkedField",
    "name": "device734",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cardFormatOptions",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfUserCodeDigits",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enforceSiteCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationCardOptionsField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrDeviceInformation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75DeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};
})();

(node as any).hash = "4b8f016c7d959b0eec1b7ebd5b6d1c50";

export default node;
