/**
 * @generated SignedSource<<daad4ae44c76bbcf20a2e43e2239e5a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeatureKeyEnum = "ALL_NO_YES_OPTION" | "ALL_USER_CODE_CHANGE" | "BANAMEX_MANTRAP" | "BANK_OF_AMERICA" | "CANADIAN" | "DOOR_ADD_ON_A" | "DOOR_ADD_ON_B" | "DUAL_NETWORK_PATH" | "DURESS_PLUS_2" | "EMAIL_MESSAGING" | "ENCRYPTION" | "EUROPEAN_FEATURES" | "FE_MORAN" | "IGNORE_BROADCAST" | "MINIMUM_USER_NAME" | "NATIONAL_ACCOUNT" | "PRINTER" | "RESTRICT_PROFILES" | "SERVICE_USER_AUTHENTICATION" | "SMITH_SOUTHERN" | "SMITH_WESSON" | "STARBUCKS" | "SUPPORT_200_KEY_FOBS" | "WELLS_FARGO" | "XR150" | "XR350";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type XRFeatureKeysProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly featureKeys: ReadonlyArray<{
      readonly key: FeatureKeyEnum;
    }>;
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"FeatureKeysSendKeyField_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XRFeatureKeysProgrammingConceptForm_controlSystem";
};
export type XRFeatureKeysProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XRFeatureKeysProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRFeatureKeysProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRFeatureKeysProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FeatureKey",
          "kind": "LinkedField",
          "name": "featureKeys",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeatureKeysSendKeyField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "53a0d334caf140ffeb7fb7b582002b73";

export default node;
