/**
 * @generated SignedSource<<c726de2b58694819faa01478306ae8b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatFormExistingModal_cardFormat$data = {
  readonly id: string;
  readonly name: string;
  readonly requireSiteCode: boolean;
  readonly siteCodeLength: number;
  readonly siteCodePosition: number;
  readonly siteCodes: ReadonlyArray<number | null>;
  readonly type: number;
  readonly userCodeDigits: number;
  readonly userCodeLength: number;
  readonly userCodePosition: number;
  readonly wiegandLength: number;
  readonly " $fragmentType": "CardFormatFormExistingModal_cardFormat";
};
export type CardFormatFormExistingModal_cardFormat$key = {
  readonly " $data"?: CardFormatFormExistingModal_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatFormExistingModal_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatFormExistingModal_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wiegandLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodePosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodeLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCodePosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCodeLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCodeDigits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requireSiteCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodes",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "3c3711d5e22dcc0735def2829e406554";

export default node;
