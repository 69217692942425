/**
 * @generated SignedSource<<18310be14d507ea3c00479c1f1f70d07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ControlSystemCommType = "CELL" | "NETWORK" | "PERSISTENT" | "PERSISTENT_WITH_CELL_BACKUP" | "UNKNOWN";
export type X1Type = "X1" | "X8";
import { FragmentRefs } from "relay-runtime";
export type SiteControlSystemsSection_siteControlSystem$data = {
  readonly commType: ControlSystemCommType;
  readonly doors: ReadonlyArray<{
    readonly active: boolean;
    readonly id: string;
    readonly name: string;
    readonly number: number;
  }>;
  readonly hasPendingChanges: boolean;
  readonly id: string;
  readonly online: boolean;
  readonly serialNumber: string;
  readonly type: X1Type;
  readonly " $fragmentSpreads": FragmentRefs<"FirmwareUpdateBadge_siteControlSystem" | "SiteControlSystemJobStatusBadge_siteControlSystem" | "SiteControlSystemOnlineStatusBadge_siteControlSystem">;
  readonly " $fragmentType": "SiteControlSystemsSection_siteControlSystem";
};
export type SiteControlSystemsSection_siteControlSystem$key = {
  readonly " $data"?: SiteControlSystemsSection_siteControlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteControlSystemsSection_siteControlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteControlSystemsSection_siteControlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingChanges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeUnprogrammedDoors",
          "value": true
        }
      ],
      "concreteType": "SiteDoor",
      "kind": "LinkedField",
      "name": "doors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        }
      ],
      "storageKey": "doors(includeUnprogrammedDoors:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FirmwareUpdateBadge_siteControlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteControlSystemJobStatusBadge_siteControlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteControlSystemOnlineStatusBadge_siteControlSystem"
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "e709f2a06529f44411be7e60e627aa39";

export default node;
