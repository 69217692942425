import { defaultTheme } from "app/common/DaColors";
import Tooltip, { MessagePosition } from "common/components/web/Tooltip";
import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Icon from "./Icon";

interface InlineTooltipProps {
  message: string;
  position?: MessagePosition;
  color?: string;
  icon?: React.ReactNode;
}
function InlineTooltip(props: InlineTooltipProps) {
  const {
    message,
    position = MessagePosition.Right,
    color = "--color-primary",
    icon,
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Tooltip
        style={{ display: "inline-block" }}
        anchor={({ registerAnchor }) => (
          <InfoIcon
            ref={registerAnchor}
            onMouseOver={() => setTooltipOpen(true)}
            onMouseOut={() => setTooltipOpen(false)}
            style={{ margin: "0.5rem" }}
          >
            {icon ? icon : <Icon name="radial_info" color={`var(${color})`} />}
          </InfoIcon>
        )}
        position={position}
        open={tooltipOpen}
        onDismiss={() => setTooltipOpen(false)}
        zIndex={5000}
        children={
          <TooltipContainer>
            <PopoverContent>
              <PopoverMessage>{message}</PopoverMessage>
            </PopoverContent>
          </TooltipContainer>
        }
      ></Tooltip>
    </ThemeProvider>
  );
}

export default InlineTooltip;

const InfoIcon = styled.i`
  cursor: pointer;
  color: var(--color-primary-500);
  position: relative;
`;

const TooltipContainer = styled.div`
  font-size: 1.4rem;
`;

const PopoverContent = styled.div`
  padding: 0.9rem 1.4rem;
`;

const PopoverMessage = styled.div`
  margin: 0 0 1rem;
  white-space: pre-wrap;
`;
