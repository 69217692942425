import * as React from "react";
import styled from "styled-components";
import { focusRing } from "utils/styles";

const Label = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  line-height: 1;
  cursor: pointer;
`;
const VisualSwitch = styled.i`
  display: flex;
  align-items: center;
  position: relative;
  width: 3.8rem;
  height: 2rem;
  border-radius: 2rem;
  border: 1px solid #dddddd;
  background-color: white;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset;
  transition: 0.5s all;

  &::after {
    content: "";
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    background-color: white;
    border-radius: 9999px;
    border: 1px solid #dddddd;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    transition: 0.2s all;
  }
`;
const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + ${VisualSwitch} {
    background-color: var(--color-info-600);
    border-color: var(--color-info-600);

    &::after {
      border-color: var(--color-info-500);
      transform: translateX(100%);
    }
  }

  &:focus + ${VisualSwitch} {
    ${focusRing};
  }
`;

const DaSwitch = React.forwardRef(
  (
    {
      label,
      labelPosition,
      ...rest
    }: {
      label: string;
      labelPosition?: "left" | "right" | undefined;
    } & React.HTMLProps<HTMLInputElement>,
    ref: React.ForwardedRef<HTMLInputElement | null>
  ) => {
    return (
      <Label aria-labelledby={label} className="styled-switch">
        {labelPosition === "left" ? (
          <span className="control-label mar-r-8">{label}</span>
        ) : null}
        <Input
          {...(rest as React.HTMLAttributes<HTMLInputElement>)}
          className="form-control"
          type="checkbox"
          ref={ref}
        />

        <VisualSwitch />
        {labelPosition === "right" ? (
          <span className="control-label mar-l-8">{label}</span>
        ) : null}
      </Label>
    );
  }
);

export default DaSwitch;
