import graphql from "babel-plugin-relay/macro";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { getClosestOutputValue } from "../OutputOptionsFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationArmedShortOutputNumberField_zone$key } from "./__generated__/ZoneInformationArmedShortOutputNumberField_zone.graphql";

export const zoneInformationArmedShortOutputNumberFieldId = (number: string) =>
  `zone-information-armed-short-output-number-${number}`;

function ZoneInformationArmedShortOutputNumberField() {
  const [{ number, armedShortOutputNumber, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationArmedShortOutputNumberField_zone$key>(
      graphql`
        fragment ZoneInformationArmedShortOutputNumberField_zone on Zone {
          number
          type
          armedShortOutputNumber
        }
      `
    );

  const fieldId = zoneInformationArmedShortOutputNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);
  const { OUTPUT_NUMBER: RULES } = resolveZoneFieldRules(hardwareModel);
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const originalValue = React.useRef(armedShortOutputNumber).current;
  const disabled = [ZoneType.ARMING, ZoneType.TAMPER].includes(
    type as ZoneType
  );
  const label = isXf ? "Short Output" : "Armed Short Output";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Output turns on with the programmed cadence when this Zone is in a short condition while the Area is Armed. Turns off when Area is Disarmed next."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={armedShortOutputNumber}
        pattern={RULES.PATTERN}
        inlineHelp={RULES.INLINE_HELP}
        validationMessage={RULES.VALIDATION_MSG}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "armedShortOutputNumber");
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                originalValue,
                hardwareModel,
                validNumericalRange
              ),
              "armedShortOutputNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationArmedShortOutputNumberField;
