import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const CommunicationContext = React.createContext<any>({});

export function CommunicationContextProvider(props: {
  communication: any;
  children: React.ReactNode;
}) {
  return (
    <CommunicationContext.Provider value={props.communication}>
      {props.children}
    </CommunicationContext.Provider>
  );
}

export function useCommunicationFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const communication = React.useContext(CommunicationContext);

  const data = useFragment(fragmentInput, communication as TKey);

  const { id } = useFragment(
    graphql`
      fragment CommunicationContext_communication on Communication {
        id
      }
    `,
    communication as any
  );

  const update = (
    updater: (communication: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
