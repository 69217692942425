import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsProgrammingPortField_networkOptions$key } from "./__generated__/NetworkOptionsProgrammingPortField_networkOptions.graphql";

export const networkOptionsProgrammingPortFieldId = () =>
  "network-options-programming-port";

// v241 introduced ability for certain XT models to have their programming port set to '0'
const ALLOW_XT_PORT_0_SOFTWARE_VERSION = 241;

function NetworkOptionsProgrammingPortField() {
  const [{ programmingPort }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsProgrammingPortField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsProgrammingPortField_networkOptions on NetworkOptions {
          programmingPort
        }
      `
    );

  // v241 introduced ability for certain XT models to have their programming port set to '0'
  const allowPort0Panels = [
    "XT30",
    "XT30L",
    "XT50",
    "XT50L",
    "XTL",
    "XTLN",
    "XTLP",
    "XTLW",
  ];

  const hardwareModel = useHardwareModel();
  const softwareVersion = useSoftwareVersion();
  const portLowestNumber =
    allowPort0Panels.includes(hardwareModel) &&
    softwareVersion >= ALLOW_XT_PORT_0_SOFTWARE_VERSION
      ? 0
      : 1;

  const fieldId = networkOptionsProgrammingPortFieldId();

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Programming Port">
      <NumericInput
        id={fieldId}
        min={portLowestNumber}
        max={65535}
        value={programmingPort}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp={`${portLowestNumber}-65535`}
        validationMessage={`Valid values are ${portLowestNumber}-65535.`}
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "programmingPort"
            );
          });
        }}
        onBlur={({ target }) => {
          const valueAsNumber = Number(target.value);
          const value = clamp(portLowestNumber, 65535, valueAsNumber);
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(
              String(value).padStart(5, `0`),
              "programmingPort"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsProgrammingPortField;
