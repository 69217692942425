import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import GlobalHolidayDatesModalForm from "./GlobalHolidayDatesModalForm";
import { EditGlobalHolidayDatesModalQuery } from "./__generated__/EditGlobalHolidayDatesModalQuery.graphql";

function EditGlobalHolidayDatesModal({
  holidayId,
  closeModal,
  dealerId,
}: {
  holidayId: string;
  closeModal: () => void;
  dealerId: string;
}) {
  const holiday = useLazyLoadQuery<EditGlobalHolidayDatesModalQuery>(
    graphql`
      query EditGlobalHolidayDatesModalQuery($holidayId: ID!) {
        globalHolidayDateQuery(id: $holidayId) {
          id
          name
          scapiId
          description
          clazz
          date
          panelAssociation
        }
      }
    `,
    { holidayId: holidayId },
    { fetchPolicy: "network-only" }
  );
  return (
    <GlobalHolidayDatesModalForm
      data={holiday.globalHolidayDateQuery!}
      closeModal={closeModal}
      isNew={false}
      dealerId={dealerId}
      panelsAssociated={holiday.globalHolidayDateQuery?.panelAssociation.length}
    />
  );
}

export default EditGlobalHolidayDatesModal;
