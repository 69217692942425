/**
 * @generated SignedSource<<49590a3bebb0119ef47ff28297c1d2ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DigitalWatchdogSpectrumNvrs_site$data = {
  readonly billingControlSystemId: string;
  readonly digitalWatchdogSpectrumNvrs: ReadonlyArray<{
    readonly channels: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly number: number;
    }>;
    readonly id: string;
    readonly installerEmail: string | null;
    readonly name: string;
    readonly serialNumber: string;
  }>;
  readonly id: string;
  readonly " $fragmentType": "DigitalWatchdogSpectrumNvrs_site";
};
export type DigitalWatchdogSpectrumNvrs_site$key = {
  readonly " $data"?: DigitalWatchdogSpectrumNvrs_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"DigitalWatchdogSpectrumNvrs_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./DigitalWatchdogSpectrumRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "DigitalWatchdogSpectrumNvrs_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingControlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DigitalWatchdogSpectrumNvr",
      "kind": "LinkedField",
      "name": "digitalWatchdogSpectrumNvrs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "installerEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DigitalWatchdogSpectrumNvrVideoChannel",
          "kind": "LinkedField",
          "name": "channels",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "7dfe424d53a4ca30c3e0330824125933";

export default node;
