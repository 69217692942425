import loadScript from "common/utils/loadScript";
import { getApUiUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import * as React from "react";
import { react2angular } from "react2angular";
import { getAuthFromJWT } from "utils/string";

// This is duplicated in each place where we're loading ap-ui--should it be centralized?
declare global {
  interface Window {
    mountApUi: (id: string, props: any) => void;
    unmountApUi: () => void;
  }
}

// This is duplicated in each place where we're loading ap-ui--should it be centralized?
const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;

const baseUrl = getApUiUrl(secureComEnv);
const apUiUrl = `${baseUrl}/ap-ui.js`;
loadScript(apUiUrl);

export default function ApAlarmVision({
  refreshId, // This is just to give Angular an easy way to force a reload of ap-ui
  UserService,
}: {
  refreshId: number;
  UserService: any;
}): JSX.Element | null {
  React.useEffect(() => {
    window.mountApUi("ap-ui", {
      page: "alarm-vision-config",
      // scwToken: UserService.auth_token,
      scwToken:
        UserService.auth_token.length > 40
          ? getAuthFromJWT(UserService.auth_token)
          : UserService.auth_token,
      controlSystemId: UserService.control_system_id,
      dealerId: UserService.dealer_id,
      customerId: UserService.customer_id,
      userId: UserService.user.id,
    });

    return () => window.unmountApUi();
  }, [refreshId]);

  return <div id="ap-ui"></div>;
}

export function dangerouslyAddToApp() {
  App.component(
    "apAlarmVision",
    react2angular(ApAlarmVision, ["refreshId"], ["UserService"])
  );
}
