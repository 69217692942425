/**
 *
 * withAncestorAnimatingState
 * @author Chad Watson
 *
 */

import PropTypes from "prop-types";
import { getContext } from "recompose";

const withAncestorAnimatingState = getContext({
  animating: PropTypes.bool
});

export default withAncestorAnimatingState;
