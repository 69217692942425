/**
 * @generated SignedSource<<6ac077f4d90e686fd46c1c37f1786854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputInformationTripWithPanelBellField_output$data = {
  readonly isWireless: boolean;
  readonly number: number;
  readonly serialNumber: string | null;
  readonly tripWithPanelBell: boolean;
  readonly " $fragmentType": "OutputInformationTripWithPanelBellField_output";
};
export type OutputInformationTripWithPanelBellField_output$key = {
  readonly " $data"?: OutputInformationTripWithPanelBellField_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationTripWithPanelBellField_output">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputInformationTripWithPanelBellField_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tripWithPanelBell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWireless",
      "storageKey": null
    }
  ],
  "type": "Output",
  "abstractKey": null
};

(node as any).hash = "0784b5c9f85578bd62e0945090d9afbb";

export default node;
