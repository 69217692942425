import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsEnableKeypadPanicKeysField_systemOptions$key } from "./__generated__/SystemOptionsEnableKeypadPanicKeysField_systemOptions.graphql";

export const systemOptionsEnableKeypadPanicKeysFieldId = () =>
  "system-options-enable-keypad-panic-keys";

function EnableKeypadPanicKeysField() {
  const [{ enableKeypadPanicKeys }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsEnableKeypadPanicKeysField_systemOptions$key>(
      graphql`
        fragment SystemOptionsEnableKeypadPanicKeysField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            enableKeypadPanicKeys
          }
          ... on XtSystemOptions {
            enableKeypadPanicKeys
          }
          ... on Xt75SystemOptions {
            enableKeypadPanicKeys
          }
          ... on TMSentrySystemOptions {
            enableKeypadPanicKeys
          }
        }
      `
    );

  const fieldId = systemOptionsEnableKeypadPanicKeysFieldId();
  const label = "Enable Keypad Panic Keys";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Allows the Fire, Panic, and Emergency panic keys to be used at the keypad."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={enableKeypadPanicKeys}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              !enableKeypadPanicKeys,
              "enableKeypadPanicKeys"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default EnableKeypadPanicKeysField;
