/**
 *
 */
App.controller("SchedulesXTListCtrl", [
  "$rootScope",
  "$scope",
  "$state",
  "$filter",
  "UserService",
  "MultiPanelService",
  "PanelProgArchiveService",
  function (
    $rootScope,
    $scope,
    $state,
    $filter,
    UserService,
    MultiPanelService,
    PanelProgArchiveService
  ) {
    $scope.controlSystem = UserService.controlSystem;
    $scope._family =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].hardware_family;
    var panel_model =
      $scope.controlSystem.panels[$scope.controlSystem.panel_index]
        .hardware_model;

    $scope.isBusy = true;
    $scope.dow = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]; //Used for schedule details drop down
    //$scope.tracked_outputs = []; // Stores panel outputs that are being tracked
    $scope.canAddFavorite = false; // Used by the UI to toggle the Add Favorite drop down item
    $scope.canAddOutput = false; // Used by the UI to toggle the Add Output drop down item
    $scope.scheduledJobs = []; // Updated in initPage()
    $scope.scheduledJobCount = 0; // Used to track changes in the amount of scheduled jobs
    $scope.PanelProgArchiveService = PanelProgArchiveService;

    /**
     * @ngdoc object
     * @name initPage
     *
     * @description
     * Combine all the schedules into one list
     *
     */
    function initPage() {
      // Initialize the multi-panel service, clearing out any existing interval timers that are running.
      MultiPanelService.initialize();
      if (
        $scope.Panel.hasOwnProperty("schedules") ||
        panel_model === "MiniCellCom"
      ) {
        // Checking to see if there are scheduled user code jobs for this panel
        MultiPanelService.getActiveJobsForPanelConcept(
          MultiPanelService.XT_SCHEDULE_JOBS
        )
          .then(function (jobs) {
            // If we have at least one pending job, start a watcher to get updates
            if (jobs.length > 0) {
              MultiPanelService.startMultiPanelWatcher(
                MultiPanelService.XT_SCHEDULE_JOBS
              );
            }

            // Parse the RemoteJson returned from the job scheduler service for display
            parseScheduledJobs(jobs);
            $scope.scheduledJobs = jobs;
            $scope.scheduledJobCount = jobs.length;
          })
          .catch(function (error) {
            console.error(error);
          });

        // Make sure that all of our concept numbers are uniformly formatted (ex. 001)
        angular.forEach($scope.Panel.output_informations, function (output) {
          output.number = $filter("zpad")(output.number, 3);
        });
        angular.forEach($scope.Panel.output_schedules, function (output) {
          output.number = $filter("zpad")(output.number, 3);
        });
        angular.forEach($scope.Panel.favorites, function (favorite) {
          favorite.number = $filter("zpad")(favorite.number, 3);
        });
        angular.forEach($scope.Panel.favorite_schedules, function (favorite) {
          favorite.number = $filter("zpad")(favorite.number, 3);
        });

        $scope.canAddFavorite = canAddFavoriteSchedule();
        $scope.canAddOutput = canAddOutputSchedule();
        combineAllSchedules();
        $scope.isBusy = false;
      }
    }

    /**
     * Loop through a list of scheduled jobs and massage them so that they're easier to work with
     */
    function parseScheduledJobs(jobs) {
      // Parse the RemoteJson returned from the job scheduler service for display
      angular.forEach(jobs, function (job) {
        if (job.RemoteJson !== null) {
          job.RemoteJson = angular.fromJson(job.RemoteJson);
        }
        var jobType = "";
        // Get the type of schedule we're working with
        if (job.JobDesc.contains("Arming")) {
          jobType = "schedule";
        } else if (job.JobDesc.contains("Output")) {
          jobType = "output_schedule";
        } else if (job.JobDesc.contains("Favorite")) {
          jobType = "favorite_schedule";
        }

        // Parse some job information to make it easier to display in the UI
        job.RemoteJson === null
          ? (job.number = job.SelectedItem)
          : (job.number = job.RemoteJson[jobType].number);
        if (jobType == "schedule") {
          job.name = "Arming Schedule";
          job.scheduleType = "arming";
        } else if (jobType == "output_schedule") {
          job.name = resolveScheduleName("output", job.number);
          job.scheduleType = "output";
        } else if (jobType == "favorite_schedule") {
          job.name = resolveScheduleName("favorite", job.number);
          job.scheduleType = "favorite";
        }
      });
    }

    /**
     * @ngdoc object
     * @name combineAllSchedules
     *
     * @description
     * Combines all schedule types into one list for displaying to the user
     *
     */
    function combineAllSchedules() {
      $scope.allSchedules = [];

      // For XT Panels there is only one arming schedule, which is perminent and is located in the panel when it is shipped.
      // Previously we had to retrieve from the panel in order to see it. However, with the addition of schedules into pre-programming
      // we need to artificially add the arming schedule to the panel if it hasn't already been added because we cannot retrieve an offline panel.
      if (
        !$scope.controlSystem.panels[0].online &&
        !$scope.Panel.schedules.length > 0
      ) {
        $scope.Panel.schedules.push({
          fri_close: "",
          fri_open: "",
          mon_close: "",
          mon_open: "",
          number: "01",
          sat_close: "",
          sat_open: "",
          sun_close: "",
          sun_open: "",
          thu_close: "",
          thu_open: "",
          tue_close: "",
          tue_open: "",
          wed_close: "",
          wed_open: "",
        });
      }
      angular.forEach($scope.Panel.schedules, function (schedule) {
        if (!schedule.isNew) {
          $scope.allSchedules.push({
            name: "Arming Schedule",
            number: schedule.number,
            type: "arming",
            details: schedule,
          });
        }
      });
      angular.forEach($scope.Panel.favorite_schedules, function (schedule) {
        if (!schedule.isNew) {
          $scope.allSchedules.push({
            name: resolveScheduleName("favorite", schedule.number),
            number: schedule.number,
            type: "favorite",
            details: schedule,
          });
        }
      });
      angular.forEach($scope.Panel.output_schedules, function (schedule) {
        if (!schedule.isNew) {
          $scope.allSchedules.push({
            name: resolveScheduleName("output", schedule.number),
            number: schedule.number,
            type: "output",
            details: schedule,
          });
        }
      });
    }

    /**
     * @ngdoc object
     * @name resolveScheduleName
     *
     * @description
     * Set the schedule name to the selected output's name
     *
     */
    function resolveScheduleName(type, number) {
      switch (type) {
        case "favorite":
          //If we find a favorite with a matching number, use its name, else return the default value
          if (
            $filter("filter")(
              $scope.Panel["favorites"],
              { number: number },
              true
            ).length
          ) {
            return $filter("filter")(
              $scope.Panel["favorites"],
              { number: number },
              true
            )[0].name;
          } else {
            return type + " " + number;
          }
        case "output":
          //If we find an output with a matching number, use its name, else return the default value
          if (panel_model === "TMS6") {
            const outputs = [
              { number: 1, outputName: "Output 1" },
              { number: 2, outputName: "Output 2" },
            ];
            // Ensure `number` is a number for comparison
            const outputNumber = Number(number);
            // Check if the output number is valid
            if (outputs.some((output) => output.number === outputNumber)) {
              const foundOutput = outputs.find(
                (output) => output.number === outputNumber
              );
              return foundOutput.outputName;
            } else {
              return type + " " + number;
            }
          }

          if (
            $filter("filter")(
              $scope.Panel["output_informations"],
              { number: number },
              true
            ).length
          ) {
            return $filter("filter")(
              $scope.Panel["output_informations"],
              { number: number },
              true
            )[0].name;
          } else {
            return type + " " + number;
          }
        default:
          return type + " " + number;
      }
    }

    /**
     * @ngdoc object
     * @name canAddFavoriteSchedule
     *
     * @description
     * Checks to see if there are any favorites that aren't already assigned to schedules
     *
     * @returns {boolean} True if there's a favorite not assigned to a schedule, else false
     *
     */
    function canAddFavoriteSchedule() {
      if (
        DoesNestedPropertyExist($scope.Panel, "favorites") &&
        DoesNestedPropertyExist($scope.Panel, "favorite_schedules")
      ) {
        return $scope.Panel["favorites"].every(function (favorite) {
          return $filter("filter")(
            $scope.Panel["favorite_schedules"],
            { number: favorite.number },
            true
          ).shift();
        });
      } else {
        return false;
      }
    }

    /**
     * @ngdoc object
     * @name canAddOutputSchedule
     *
     * @description
     * Checks to see if there are any outputs that aren't already assigned to schedules
     *
     * @returns {boolean} True if there's an output not assigned to a schedule, else false
     *
     */
    function canAddOutputSchedule() {
      if (
        angular.isUndefined($scope.Panel["output_informations"]) &&
        (panel_model === "MiniCellCom" || !(panel_model === "TMS6"))
      ) {
        return false;
      }
      if (panel_model === "TMS6") {
        // Check if there are less than 2 output schedules
        if ($scope.Panel.output_schedules.length < 2) {
          // Get the outputs already in use
          $scope.outputInUse = $scope.Panel.output_schedules.map(
            (output) => output.number
          );
          return $scope.outputInUse.length < 2;
        } else {
          return false; // Since we already have 2 or more schedules
        }
      } else {
        // For non-TMS6 panels, validate outputs
        return $scope.Panel.output_informations.some(function (output) {
          // If the output number isn't valid, return false immediately
          if (!$scope.Panel.isValidNumber("output_schedules", output.number)) {
            return false;
          }
          // Check to see if this output number is currently being used in a schedule
          var scheduleFound = $filter("filter")(
            $scope.Panel.output_schedules,
            { number: output.number },
            true
          ).length;
          return !scheduleFound;
        });
      }
    }

    /**
     * @ngdoc
     * @name deleteSchedule
     *
     * @description
     * Removes the specified schedule from the panel then calls goToSchedules
     *
     * @param {schedule_type} scheduleToDelete The schedule to remove from the panel
     */
    $scope.deleteSchedule = function (scheduleToDelete) {
      $scope.isBusy = true;
      var scheduleType = scheduleToDelete.type + "_schedules";
      $scope.Panel.deleteItem(scheduleType, scheduleToDelete)
        .then(function () {
          $scope.isBusy = true;
          initPage();
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:refresh
     *
     * @description
     * Updates the required concepts from the panel
     *
     */
    $scope.refresh = function () {
      $scope.isBusy = true;
      $scope.Panel.refresh()
        .then(
          function (data) {
            //Success
            $scope.isBusy = true;
            initPage();
          },
          function (error) {
            //Failure
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.isBusy = false;
        });
    };

    /**
     * @ngdoc object
     * @name goToEditSchedule
     *
     * @description
     * Navigates to the specified schedule page
     *
     * @param {object} schedule The schedule to navigate to
     *
     */
    $scope.goToEditSchedule = function (schedule) {
      $state.go("^.schedule_edit_" + schedule.type + "_xt", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
        panel_id: UserService.panel_id,
        number: schedule.number,
      });
    };

    /**
     * Check to see if a pending job exists for the given item
     */
    $scope.pendingJobExists = function (scheduleType, number) {
      var result = $filter("filter")($scope.scheduledJobs, {
        scheduleType: scheduleType,
        number: number,
      });
      if (result.length > 0) {
        return result[0];
      }
      return false;
    };

    $scope.filterAddJobs = function (job) {
      return job.JobDesc.includes("Add");
    };

    initPage();

    /**
     * Listen for the multiPanelStatusUpdate event, to handle any pending multi-panel jobs
     */
    $scope.$on("event-multiPanelStatusUpdate", function (evt, data) {
      $scope.scheduledJobs = data; // Update our scheduled jobs list

      // Parse the RemoteJson returned from the job scheduler service for display
      parseScheduledJobs(data);

      // If there are no more multi-panel jobs pending, or if we've recently completed a job, refresh the page
      if (data.length == 0 || $scope.scheduledJobCount > data.length) {
        $scope.refresh();
      }

      $scope.scheduledJobCount = data.length; // Update our scheduled job count with the latest number
    });

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     */
    $scope.$on("all_concepts_retrieved", function () {
      initPage();
    });
  },
]);
