import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay/hooks";
import Sections, { SectionHeader } from "../Layout/Layout";

import {
  ExistingOutputModuleModal,
  NewOutputModuleModal,
} from "./SiteOutputModulesForm";

import FormGridItems from "components/FormGridItems";
import {
  SiteOutputModulesSection_site$data,
  SiteOutputModulesSection_site$key,
} from "./__generated__/SiteOutputModulesSection_site.graphql";

type Props = {
  siteRef: SiteOutputModulesSection_site$key;
};

export default function SiteOutputModulesSection(props: Props) {
  const data = useFragment(
    graphql`
      fragment SiteOutputModulesSection_site on Site {
        ...SiteOutputModulesFormNewModule_site
        outputModulesConnection(include: OUTPUTS) {
          nodes {
            ...SiteOutputModulesFormExistingModule_siteOutputModule
            id
            name
            address
            controlSystem {
              serialNumber
              doors {
                name
              }
            }
            outputsConnection {
              nodes {
                name
                relayNumber
                type
              }
            }
          }
        }
      }
    `,
    props.siteRef
  );

  const [adding, setAdding] = React.useState(false);
  const [selectedOutputModuleFragment, setSelectedOutputModuleFragment] =
    React.useState<
      | SiteOutputModulesSection_site$data["outputModulesConnection"]["nodes"][number]
      | null
    >(null);

  return (
    <>
      <Sections.Section>
        <SectionHeader>
          <SectionHeader.Title>Outputs</SectionHeader.Title>

          <SectionHeader.Add
            onClick={() => {
              setAdding(true);
            }}
          />
        </SectionHeader>

        <FormGridItems>
          {data.outputModulesConnection?.nodes?.map((outputModule) => (
            <FormGridItems.Item key={outputModule.id}>
              <FormGridItems.ClickableContent
                onClick={() => {
                  setSelectedOutputModuleFragment(outputModule);
                }}
              >
                <FormGridItems.ItemTitle>
                  {outputModule.name}
                </FormGridItems.ItemTitle>
                <FormGridItems.ItemSubtitle>
                  Connected to:{" "}
                  {outputModule.controlSystem?.doors?.[0]?.name ??
                    `Unnamed #${outputModule.controlSystem?.serialNumber}`}
                </FormGridItems.ItemSubtitle>
              </FormGridItems.ClickableContent>
            </FormGridItems.Item>
          ))}
        </FormGridItems>
      </Sections.Section>
      {selectedOutputModuleFragment && (
        <ExistingOutputModuleModal
          siteOutputModule={selectedOutputModuleFragment}
          onCancel={() => {
            setSelectedOutputModuleFragment(null);
          }}
          onSaved={() => {
            setSelectedOutputModuleFragment(null);
          }}
          onDeleted={() => {
            setSelectedOutputModuleFragment(null);
          }}
        />
      )}
      {adding && (
        <NewOutputModuleModal
          site={data}
          onCancel={() => {
            setAdding(false);
          }}
          onSaved={() => {
            setAdding(false);
          }}
        />
      )}
    </>
  );
}
