/**
 * @generated SignedSource<<cf979832efb9243ea8c485bf61e65a60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobGroupListRefreshQuery$variables = {
  panelId: number;
};
export type JobGroupListRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobGroupList_query">;
};
export type JobGroupListRefreshQuery = {
  response: JobGroupListRefreshQuery$data;
  variables: JobGroupListRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "panelId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobGroupListRefreshQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "JobGroupList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobGroupListRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "panelId",
            "variableName": "panelId"
          }
        ],
        "concreteType": "SystemDiagnosticsPanelJobGroup",
        "kind": "LinkedField",
        "name": "systemDiagnosticsPanelJobGroups",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db132ad4dfe6393219ac3d7550c5fd30",
    "id": null,
    "metadata": {},
    "name": "JobGroupListRefreshQuery",
    "operationKind": "query",
    "text": "query JobGroupListRefreshQuery(\n  $panelId: Int!\n) {\n  ...JobGroupList_query\n}\n\nfragment JobGroupList_query on Query {\n  systemDiagnosticsPanelJobGroups(panelId: $panelId) {\n    groupId\n    groupDesc\n    groupStatus\n    groupMessage\n    groupClass\n    createdAt\n    updatedAt\n    completedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "94fbee651c19d1b3eb986485c7de33ae";

export default node;
