import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationSwingerBypassField_zone$key } from "./__generated__/ZoneInformationSwingerBypassField_zone.graphql";

export const zoneInformationSwingerBypassFieldId = (number: string) =>
  `zone-information-swinger-bypass-${number}`;

function ZoneInformationSwingerBypassField() {
  const [{ number, type, swingerBypassEnabled }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationSwingerBypassField_zone$key>(
      graphql`
        fragment ZoneInformationSwingerBypassField_zone on Zone {
          number
          type
          swingerBypassEnabled
        }
      `
    );

  const fieldId = zoneInformationSwingerBypassFieldId(String(number));
  const disabled = [
    ZoneType.ARMING,
    ZoneType.DOORBELL,
    ZoneType.TAMPER,
  ].includes(type as ZoneType);
  const label = "Swinger Bypass";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Allows this Zone to be swinger bypassed according to the programmed Swinger Bypass Trips and Reset Swinger Bypass value."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={swingerBypassEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!swingerBypassEnabled, "swingerBypassEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationSwingerBypassField;
