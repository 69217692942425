/**
 * @generated SignedSource<<cb0e0ff770c18aa6b24ff6f72236de0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75DeviceInformationNumberField_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly number: number;
  }>;
  readonly " $fragmentType": "XT75DeviceInformationNumberField_panel";
};
export type XT75DeviceInformationNumberField_panel$key = {
  readonly " $data"?: XT75DeviceInformationNumberField_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75DeviceInformationNumberField_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75DeviceInformationNumberField_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "92da9108885da925e4e76f7a29adc9f8";

export default node;
