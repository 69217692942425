import graphql from "babel-plugin-relay/macro";
import Space from "common/components/web/Space";
import DeviceInformationActivateOnboardSpeakerField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateOnboardSpeakerField";
import DeviceInformationActivateZone2BypassField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone2BypassField";
import DeviceInformationActivateZone3RequestToExitField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone3RequestToExitField";
import DeviceInformationCardOptionsField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationCardOptionsField";
import DeviceInformationNoCommWithPanelField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationNoCommWithPanelField";
import DeviceInformationRelockOnZone2ChangeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRelockOnZone2ChangeField";
import DeviceInformationRequireSiteCodeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRequireSiteCodeField";
import DeviceInformationSiteCode1Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode1Field";
import DeviceInformationSiteCode2Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode2Field";
import DeviceInformationSiteCode3Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode3Field";
import DeviceInformationSiteCode4Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode4Field";
import DeviceInformationSiteCode5Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode5Field";
import DeviceInformationSiteCode6Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode6Field";
import DeviceInformationSiteCode7Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode7Field";
import DeviceInformationSiteCode8Field from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode8Field";
import DeviceInformationSiteCodeLengthField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCodeLengthField";
import DeviceInformationSiteCodePositionField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCodePositionField";
import DeviceInformationUserCodeDigitsField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeDigitsField";
import DeviceInformationUserCodeLengthField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeLengthField";
import DeviceInformationUserCodePositionField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodePositionField";
import DeviceInformationWiegandCodeLengthField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationWiegandCodeLengthField";
import DeviceInformationZone2BypassTimeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone2BypassTimeField";
import DeviceInformationZone3RequestToExitTimeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone3RequestToExitTimeField";
import DeviceInformationAccessAreasField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationAccessAreasField";
import DeviceInformationAutoForceArmField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationAutoForceArmField";
import DeviceInformationDeviceCommunicationTypeField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceCommunicationTypeField";
import DeviceInformationDeviceTypeField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceTypeField";
import DeviceInformationDisplayAreasField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDisplayAreasField";
import DeviceInformationDoorRealTimeStatusField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDoorRealTimeStatusField";
import DeviceInformationEgressAreasField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationEgressAreasField";
import DeviceInformationFireExitReleaseField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationFireExitReleaseField";
import DeviceInformationOutputGroupField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationOutputGroupField";
import DeviceInformationPinDisarmField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPinDisarmField";
import DeviceInformationPrivateDoorField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPrivateDoorField";
import DeviceInformationProgram734OptionsField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationProgram734OptionsField";
import DeviceInformationPublicDoorField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPublicDoorField";
import DeviceInformationScheduleOverrideField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationScheduleOverrideField";
import DeviceInformationSendDoorForcedMessageField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSendDoorForcedMessageField";
import DeviceInformationStrikeDelayField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeDelayField";
import DeviceInformationStrikeTimeField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeTimeField";
import * as React from "react";
import { useFragment } from "react-relay";
import DeviceInformation1100TWirelessFrequencyField from "../../common/DeviceInformationFields/DeviceInformation1100TWirelessFrequencyField";
import { DeviceInformationContextProvider } from "../../common/DeviceInformationFields/DeviceInformationContext";
import DeviceInformationNameField from "../../common/DeviceInformationFields/DeviceInformationNameField";
import DeviceInformationSerialNumberField from "../../common/DeviceInformationFields/DeviceInformationSerialNumberField";
import DeviceInformationSupervisionTimeField from "../../common/DeviceInformationFields/DeviceInformationSupervisionTimeField";
import {
  useSoftwareVersion,
  useSupports1100T,
  useSupportsCustomCardFormats,
  useSupportsPrivateDoor,
} from "../../common/PanelContext";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import XRDeviceInformationNumberField from "./XRDeviceInformationNumberField";
import { XRDeviceInformationsFields_deviceInformation$key } from "./__generated__/XRDeviceInformationsFields_deviceInformation.graphql";

function XRDeviceInformationsFields(props: {
  deviceInformation: XRDeviceInformationsFields_deviceInformation$key;
}) {
  const data = useFragment(
    graphql`
      fragment XRDeviceInformationsFields_deviceInformation on DeviceInformation {
        ... on XrDeviceInformation {
          id
          deviceType
          deviceCommunicationMethod
          remoteProgram734
        }
        ...DeviceInformationContext_deviceInformation
        ...XRDeviceInformationNumberField_deviceInformation
        ...DeviceInformationNameField_deviceInformation
        ...DeviceInformationDeviceTypeField_deviceInformation
        ...DeviceInformationDeviceCommunicationTypeField_deviceInformation
        ...DeviceInformationDisplayAreasField_deviceInformation
        ...DeviceInformationAutoForceArmField_deviceInformation
        ...DeviceInformation1100TField_deviceInformation
        ...DeviceInformation1100TWirelessFrequencyField_deviceInformation
        ...DeviceInformationSerialNumberField_deviceInformation
        ...DeviceInformationSupervisionTimeField_deviceInformation
        ...DeviceInformationAccessAreasField_deviceInformation
        ...DeviceInformationEgressAreasField_deviceInformation
        ...DeviceInformationDisplayAreasField_deviceInformation
        ...DeviceInformationStrikeTimeField_deviceInformation
        ...DeviceInformationStrikeDelayField_deviceInformation
        ...DeviceInformationFireExitReleaseField_deviceInformation
        ...DeviceInformationPublicDoorField_deviceInformation
        ...DeviceInformationOutputGroupField_deviceInformation
        ...DeviceInformationScheduleOverrideField_deviceInformation
        ...DeviceInformationPinDisarmField_deviceInformation
        ...DeviceInformationDoorRealTimeStatusField_deviceInformation
        ...DeviceInformationSendDoorForcedMessageField_deviceInformation
        ...DeviceInformationSendDoorForcedMessageField_deviceInformation
        ...DeviceInformationProgram734OptionsField_deviceInformation
        ...DeviceInformationActivateZone2BypassField_deviceInformation
        ...DeviceInformationZone2BypassTimeField_deviceInformation
        ...DeviceInformationRelockOnZone2ChangeField_deviceInformation
        ...DeviceInformationActivateZone3RequestToExitField_deviceInformation
        ...DeviceInformationZone3RequestToExitTimeField_deviceInformation
        ...DeviceInformationActivateOnboardSpeakerField_deviceInformation
        ...DeviceInformationCardOptionsField_deviceInformation
        ...DeviceInformationRequireSiteCodeField_deviceInformation
        ...DeviceInformationUserCodeDigitsField_deviceInformation
        ...DeviceInformationNoCommWithPanelField_deviceInformation
        ...DeviceInformationPrivateDoorField_deviceInformation
        ...DeviceInformationWiegandCodeLengthField_deviceInformation
        ...DeviceInformationSiteCodePositionField_deviceInformation
        ...DeviceInformationSiteCodeLengthField_deviceInformation
        ...DeviceInformationUserCodePositionField_deviceInformation
        ...DeviceInformationUserCodeLengthField_deviceInformation
        ...DeviceInformationSiteCode1Field_deviceInformation
        ...DeviceInformationSiteCode2Field_deviceInformation
        ...DeviceInformationSiteCode3Field_deviceInformation
        ...DeviceInformationSiteCode4Field_deviceInformation
        ...DeviceInformationSiteCode5Field_deviceInformation
        ...DeviceInformationSiteCode6Field_deviceInformation
        ...DeviceInformationSiteCode7Field_deviceInformation
        ...DeviceInformationSiteCode8Field_deviceInformation
      }
    `,
    props.deviceInformation
  );
  const supports1100TField = useSupports1100T();
  const softwareVersion = useSoftwareVersion();
  const supportsPrivateDoor = useSupportsPrivateDoor();
  const supportsCustomCardFormats = useSupportsCustomCardFormats();

  return (
    <DeviceInformationContextProvider deviceInformation={data}>
      <ProgrammingConceptForm.Fields>
        <XRDeviceInformationNumberField />
        <DeviceInformationNameField />
        <DeviceInformationDeviceTypeField />
        {supportsPrivateDoor ? <DeviceInformationPrivateDoorField /> : null}
        <DeviceInformationDeviceCommunicationTypeField />
        <DeviceInformationDisplayAreasField />
        <DeviceInformationAutoForceArmField />
        {softwareVersion >= 742 ? <DeviceInformationPinDisarmField /> : null}
        {supports1100TField ? (
          <DeviceInformation1100TWirelessFrequencyField />
        ) : null}
        <DeviceInformationSerialNumberField />
        <DeviceInformationSupervisionTimeField />
        <DeviceInformationAccessAreasField />
        <DeviceInformationEgressAreasField />
        <DeviceInformationStrikeTimeField />
        {softwareVersion > 107 ? <DeviceInformationStrikeDelayField /> : null}
        <DeviceInformationFireExitReleaseField />
        <DeviceInformationPublicDoorField />
        <DeviceInformationOutputGroupField />
        <DeviceInformationScheduleOverrideField />
        <DeviceInformationDoorRealTimeStatusField />
        <DeviceInformationSendDoorForcedMessageField />

        <DeviceInformationProgram734OptionsField />
      </ProgrammingConceptForm.Fields>
      <>
        <Space />
        <h3>734 Options</h3>
        <ProgrammingConceptForm.Fields>
          <DeviceInformationActivateZone2BypassField />
          <DeviceInformationZone2BypassTimeField />
          <DeviceInformationRelockOnZone2ChangeField />
          <DeviceInformationActivateZone3RequestToExitField />
          <DeviceInformationZone3RequestToExitTimeField />
          <DeviceInformationActivateOnboardSpeakerField />
          <DeviceInformationCardOptionsField />
          {!supportsCustomCardFormats ? (
            <>
              <DeviceInformationWiegandCodeLengthField />
              <DeviceInformationSiteCodePositionField />
              <DeviceInformationSiteCodeLengthField />
              <DeviceInformationUserCodePositionField />
              <DeviceInformationUserCodeLengthField />
              <DeviceInformationRequireSiteCodeField />
              <DeviceInformationSiteCode1Field />
              <DeviceInformationSiteCode2Field />
              <DeviceInformationSiteCode3Field />
              <DeviceInformationSiteCode4Field />
              <DeviceInformationSiteCode5Field />
              <DeviceInformationSiteCode6Field />
              <DeviceInformationSiteCode7Field />
              <DeviceInformationSiteCode8Field />
              <DeviceInformationUserCodeDigitsField />
            </>
          ) : (
            <>
              <DeviceInformationRequireSiteCodeField />
              <DeviceInformationUserCodeDigitsField />
            </>
          )}
          <DeviceInformationNoCommWithPanelField />
        </ProgrammingConceptForm.Fields>
      </>
    </DeviceInformationContextProvider>
  );
}

export default XRDeviceInformationsFields;
