/**
 * @generated SignedSource<<664789d1fc3e6bc22de23c55476b5633>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DoorListFragment_controlSystem$data = {
  readonly doorsConnection: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"DoorWidgetFragment_door">;
    }>;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "DoorListFragment_controlSystem";
};
export type DoorListFragment_controlSystem$key = {
  readonly " $data"?: DoorListFragment_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"DoorListFragment_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./DoorListFragment_controlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "DoorListFragment_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemDoorsConnection",
      "kind": "LinkedField",
      "name": "doorsConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Door",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DoorWidgetFragment_door"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "880fc06e67d9fa6fb9a785c28fceb78b";

export default node;
