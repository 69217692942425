/**
 * @ngdoc service
 * @name App.factory:ScheduleUseService
 *
 * @description
 * API factory for Schedule Use
 *
 */
App.factory("ScheduleUseService", [
  "$q",
  "PanelV2API",
  function ($q, PanelV2API) {
    return {
      /**
       * Gets Schedule Use data for a specific schedule
       * @param panelID
       * @param scheduleID
       * @returns {*}
       */
      getScheduleUse: function (panelID, scheduleID) {
        const deferred = $q.defer();
        PanelV2API.getScheduleUse(
          { panel_id: panelID, item_id: scheduleID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
