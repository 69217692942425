import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";
import { OutputGroupContextProvider } from "../../common/OutputGroupFields/OutputGroupContext";
import OutputGroupNameField from "../../common/OutputGroupFields/OutputGroupNameField";
import OutputGroupNumberField from "../../common/OutputGroupFields/OutputGroupNumberField";
import OutputGroupOutputNumberField from "../../common/OutputGroupFields/OutputGroupOutputNumberField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { XFOutputGroupFields_outputGroup$key } from "./__generated__/XFOutputGroupFields_outputGroup.graphql";

function XFOutputGroupFields(props: {
  outputGroup: XFOutputGroupFields_outputGroup$key;
}) {
  const data = useFragment(
    graphql`
      fragment XFOutputGroupFields_outputGroup on OutputGroup {
        id
        ...OutputGroupContext_outputGroup
        ...OutputGroupNameField_outputGroup
        ...OutputGroupNumberField_outputGroup
        ...OutputGroupOutputNumberField_outputGroup
      }
    `,
    props.outputGroup
  );

  return (
    <OutputGroupContextProvider outputGroup={data}>
      <ProgrammingConceptForm.Fields>
        <OutputGroupNumberField />
        <OutputGroupNameField />
        <OutputGroupOutputNumberField outputNumber="1" />
        <OutputGroupOutputNumberField outputNumber="2" />
        <OutputGroupOutputNumberField outputNumber="3" />
        <OutputGroupOutputNumberField outputNumber="4" />
        <OutputGroupOutputNumberField outputNumber="5" />
        <OutputGroupOutputNumberField outputNumber="6" />
        <OutputGroupOutputNumberField outputNumber="7" />
        <OutputGroupOutputNumberField outputNumber="8" />
      </ProgrammingConceptForm.Fields>
    </OutputGroupContextProvider>
  );
}

export default XFOutputGroupFields;
