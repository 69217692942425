App.controller("CreateUpdatePasswordLinkCtrl", [
  "$scope",
  "$rootScope",
  "$state",
  "UserService",
  "LoginService",
  "$q",
  function ($scope, $rootScope, $state, UserService, LoginService, $q) {
    $scope.getFormType = () => {
      switch ($state.current.url) {
        case "/create-password?token":
          return "Create";
        default:
          return "Update";
      }
    };

    const init = () => {
      $scope.token = $state.params?.token ?? "";
      $scope.formType = $scope.getFormType();
    };

    init();
  },
]);
