/**
 * @generated SignedSource<<97eab733b12ed6c285e97bca6c16f51c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusBadgeAllBadgesQuery$variables = {
  customerId: string;
};
export type StatusBadgeAllBadgesQuery$data = {
  readonly node: {
    readonly controlSystems?: {
      readonly nodes: ReadonlyArray<{
        readonly __typename: "ControlSystem";
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"StatusBadgeFragment">;
      }>;
    };
    readonly id: string;
  } | null;
};
export type StatusBadgeAllBadgesQuery = {
  response: StatusBadgeAllBadgesQuery$data;
  variables: StatusBadgeAllBadgesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "customerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatusBadgeAllBadgesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystemConnection",
                "kind": "LinkedField",
                "name": "controlSystems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ControlSystem",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "StatusBadgeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Customer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatusBadgeAllBadgesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystemConnection",
                "kind": "LinkedField",
                "name": "controlSystems",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ControlSystem",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ControlSystemCentralStationAutomationIntegration",
                        "kind": "LinkedField",
                        "name": "centralStationAutomationIntegration",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "systemTestExpiresAt",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Customer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "588cb845f8a05a55b9828a0641a367d9",
    "id": null,
    "metadata": {},
    "name": "StatusBadgeAllBadgesQuery",
    "operationKind": "query",
    "text": "query StatusBadgeAllBadgesQuery(\n  $customerId: ID!\n) {\n  node(id: $customerId) {\n    __typename\n    id\n    ... on Customer {\n      controlSystems {\n        nodes {\n          __typename\n          id\n          ...StatusBadgeFragment\n        }\n      }\n    }\n  }\n}\n\nfragment StatusBadgeFragment on ControlSystem {\n  id\n  centralStationAutomationIntegration {\n    id\n    name\n    systemTestExpiresAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "456854676fddde681fd8018c1b5c5e87";

export default node;
