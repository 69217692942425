/**
 * @generated SignedSource<<26bafd2bc856abd2d35627b4b49fc5b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecorderEditUpdatePanelIntegrationMutation$variables = {
  hubId: string;
  panelConnectionEnabled: boolean;
  systemId: string;
};
export type RecorderEditUpdatePanelIntegrationMutation$data = {
  readonly updatePanelIntegration: {
    readonly __typename: "UpdatePanelIntegrationErrorPayload";
    readonly message: string | null;
    readonly type: string | null;
  } | {
    readonly __typename: "UpdatePanelIntegrationSuccessPayload";
    readonly enabled: boolean | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type RecorderEditUpdatePanelIntegrationMutation = {
  response: RecorderEditUpdatePanelIntegrationMutation$data;
  variables: RecorderEditUpdatePanelIntegrationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "panelConnectionEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "enabled",
        "variableName": "panelConnectionEnabled"
      },
      {
        "kind": "Variable",
        "name": "hubId",
        "variableName": "hubId"
      },
      {
        "kind": "Variable",
        "name": "systemId",
        "variableName": "systemId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updatePanelIntegration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          }
        ],
        "type": "UpdatePanelIntegrationSuccessPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UpdatePanelIntegrationErrorPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderEditUpdatePanelIntegrationMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecorderEditUpdatePanelIntegrationMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "efeeef513e85998f15685d54875ada88",
    "id": null,
    "metadata": {},
    "name": "RecorderEditUpdatePanelIntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation RecorderEditUpdatePanelIntegrationMutation(\n  $hubId: String!\n  $systemId: String!\n  $panelConnectionEnabled: Boolean!\n) {\n  updatePanelIntegration(hubId: $hubId, systemId: $systemId, enabled: $panelConnectionEnabled) {\n    __typename\n    ... on UpdatePanelIntegrationSuccessPayload {\n      enabled\n    }\n    ... on UpdatePanelIntegrationErrorPayload {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "307d79a084bb9202937deb2b7a6be3f7";

export default node;
