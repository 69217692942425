import DaColors from "app/common/DaColors";
import { VideoVerificationInfo } from "dealer-admin/src/apis/scapi/video-verification-info-parser";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RadialUser from "./RadialUser";
import { friendlyDateTimeString } from "./utils/time-parser";

type Props = {
  videoVerificationInfo: VideoVerificationInfo;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.2rem;
  width: 100%;
  background-color: ${DaColors.White};
  padding: 0.8rem 1.6rem;
  border-bottom: 1px solid ${DaColors.Neutral25};
`;
const DealerLogoWrapper = styled.div`
  height: 5.6rem;
`;
const DealerLogo = styled.img`
  max-height: 100%;
  max-width: 32rem;
  object-fit: contain;
`;
const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
const FieldName = styled.span`
  font-weight: bold;
`;

const Header = ({
  videoVerificationInfo: {
    dealerLogoUrl,
    customerName,
    accountNumber,
    lastAlarmAt,
  },
}: Props) => {
  // If a logo URL is provided, try to load it. If it fails to load, don't display a logo image at
  // all.
  const [logoAvailable, setLogoAvailable] = useState(!!dealerLogoUrl);
  useEffect(() => {
    setLogoAvailable(!!dealerLogoUrl);
  }, [dealerLogoUrl]);

  return (
    <Wrapper>
      <DealerLogoWrapper>
        {logoAvailable ? (
          <DealerLogo
            src={dealerLogoUrl}
            onError={() => setLogoAvailable(false)}
          />
        ) : null}
      </DealerLogoWrapper>
      <AccountInfo>
        <RadialUser />
        <div>
          <div>
            <FieldName>Customer:</FieldName> {customerName}
          </div>
          <div>
            <FieldName>Dealer Account Number:</FieldName> {accountNumber}
          </div>
          <div>
            <FieldName>Last Alarm Time:</FieldName>{" "}
            {friendlyDateTimeString(lastAlarmAt)}
          </div>
        </div>
      </AccountInfo>
    </Wrapper>
  );
};

export default Header;
