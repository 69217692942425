/**
 * @generated SignedSource<<c0ae47c0a80bceaab9aba5c5fc02effa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatContext_cardFormat$data = {
  readonly id: string;
  readonly " $fragmentType": "CardFormatContext_cardFormat";
};
export type CardFormatContext_cardFormat$key = {
  readonly " $data"?: CardFormatContext_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatContext_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatContext_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "86dab0b3db53090309811d4c10d1d03f";

export default node;
