/**
 * @name App.directive:daPanelEntity
 * @desc This directive creates the watchers needed to run rules for any given panel object: zone, output, etc.
 *       It can be used for a single field by specifying a field
 * @property entity: object - The panel entity (singular concept or specified zone, output, etc.)
 * @property field: string - a specific field within an entity. This is intended to be used for individual fields that span
 *   the items of a concept. For instance, transmission delay is a field on every communication path (when thinking in terms
 *   of panel remote strings). Changing it on one path causes the value to change on all of the remaining paths.
 * @property concept: object - Panel concept: system_options, zone_informations, etc.
 * @property panel: object
 *
 * @example <div da-panel-entity  ng-if="item.isOpen" entity="item" concept="concept" panel="Panel">
 */

App.directive("daPanelEntity", [
  "AXBusService",
  "PanelDefRuleService",
  function (AXBus, PanelDefRules) {
    return {
      restrict: "E",

      scope: {
        entity: "=",
        field: "@",
        concept: "<",
        panel: "=",
        forPrint: "<",
        formInvalid: "<",
      },

      link: function (scope) {
        /**
         * If this directive is for an individual field, the entity number is meaningless, otherwise if there is no entity
         * number, it is null. otherwise, it will be used for bubbling up error messages.
         * @type {null}
         */
        var entityNumber = angular.isDefined(scope.field)
          ? null
          : DoesNestedPropertyExist(scope, "entity.number")
          ? +scope.entity.number
          : null;
        scope.panel.initializeSessionData(
          scope.concept.key,
          +entityNumber,
          scope.field
        );

        var defaultsApplied = false;
        scope.$watch("entity", function (newVal) {
          // New items will be {isBusy: true} until the response for a new item from SCAPI so only process this rule when
          // there is more than one property and you haven't yet applied default rules
          if (newVal && Object.keys(newVal).length > 1 && !defaultsApplied) {
            /**
             * Process default rules rules that target fields in this entity once for new items to set field ranges and
             * default values.
             * note: Other rules (override, etc.) are processed in prog_router_controller and run when their reference
             * field changes value as another da-panel-entity.
             */
            if (propertyExistsAndEquals(scope, "entity.isNew", true)) {
              angular.forEach(
                scope.panel.session.panelDefRules.targetGroups[
                  scope.concept.key
                ],
                function (targetFieldRules, field) {
                  if (targetFieldRules.defaultRules.length > 0)
                    PanelDefRules.processRules(
                      targetFieldRules.defaultRules,
                      scope.panel,
                      scope.entity.number
                    );
                  if (targetFieldRules.hideRules.length > 0)
                    PanelDefRules.processRules(
                      targetFieldRules.hideRules,
                      scope.panel,
                      scope.entity.number
                    );
                  if (targetFieldRules.disableRules.length > 0)
                    PanelDefRules.processRules(
                      targetFieldRules.disableRules,
                      scope.panel,
                      scope.entity.number
                    );
                }
              );
              defaultsApplied = true;
            }
          }
        });

        /**
         * Create form invalid watcher to update invalid form for duplicate entity numbers
         */
        scope.$watch("formInvalid", function (newValue, oldValue) {
          if (Boolean(newValue) !== Boolean(oldValue)) {
            if (scope.panel[scope.concept.key].isArray) {
              // Handle duplicate entity numbers
              if (
                DoesNestedPropertyExist(scope, "entity.number") &&
                uniqueEntity()
              ) {
                setInvalidIndicator(Boolean(newValue), +scope.entity.number);
              }
            } else {
              setInvalidIndicator(Boolean(newValue));
            }
          }
        });

        /**
         * Watch fields that may effect properties of other fields. i.e. If a rule exists that effects another field's
         * value/parameters, watch the field that would cause the change.
         * @type {Array}
         */
        var watchFields = [];
        angular.forEach(
          scope.panel.session.panelDefRules.referenceGroups[scope.concept.key],
          function (referenceFieldRules, fieldKey) {
            addToWatchFields(fieldKey);
            addAncillaryFields(referenceFieldRules, "defaultRules");
            addAncillaryFields(referenceFieldRules, "overrideFieldRules");
            addAncillaryFields(referenceFieldRules, "hideRules");
            addAncillaryFields(referenceFieldRules, "disableRules");
          }
        );

        /**
         * Add a field to the watch array if it doesn't already exist
         * @param field
         */
        function addToWatchFields(field) {
          if (watchFields.indexOf(field) === -1) {
            watchFields.push(field);
          }
        }

        /**
         * Add reference fields from compound rules
         * @param {[{}]}rules
         * @param {string} type - type of rule (default, overrideField, etc.)
         */
        function addAncillaryFields(rules, type) {
          angular.forEach(rules[type], function (rule) {
            angular.forEach(rule["MULTI_REFERENCE"], function (mRule) {
              if (mRule.REF_CONCEPT === scope.concept.key) {
                addToWatchFields(mRule.REF_FIELD);
              }
            });
            angular.forEach(rule["CONDITIONAL_REFERENCES"], function (cRule) {
              if (cRule.REF_CONCEPT === scope.concept.key) {
                addToWatchFields(cRule.REF_FIELD);
              }
            });
          });
        }

        var hasNumberWatcher = false;
        angular.forEach(watchFields, function (fieldKey) {
          var fieldRules =
            scope.panel.session.panelDefRules.referenceGroups[
              scope.concept.key
            ][fieldKey];

          if (scope.forPrint && scope.forPrint === true) {
            if (fieldRules.hideRules.length > 0)
              PanelDefRules.processRules(
                fieldRules.hideRules,
                scope.panel,
                scope.entity.number
              );
          } else {
            if (fieldKey === "number") hasNumberWatcher = true;
            /**
             * The $watch, to keep an eye on changes from the REFERENCE_FIELD
             */
            scope.$watch("entity." + fieldKey, function (newValue, oldValue) {
              if (fieldKey === "number") {
                processNumberChange(newValue, oldValue);
              }
              if (fieldRules.overrideFieldRules.length > 0)
                PanelDefRules.processRules(
                  fieldRules.overrideFieldRules,
                  scope.panel
                );
              // The rule watcher fires when initialized and the entity may not be defined yet
              if (scope.entity) {
                // If this is part of an entity and it's new, apply default rules even if the reference value hasn't changed.
                // Otherwise, apply default rules only if the value has changed
                if (
                  fieldRules.defaultRules.length > 0 &&
                  (scope.entity.isNew ||
                    (newValue !== oldValue && oldValue !== undefined))
                ) {
                  PanelDefRules.processRules(
                    fieldRules.defaultRules,
                    scope.panel,
                    scope.entity.number
                  );
                }
                if (fieldRules.hideRules.length > 0)
                  PanelDefRules.processRules(
                    fieldRules.hideRules,
                    scope.panel,
                    scope.entity.number
                  );
                if (fieldRules.disableRules.length > 0)
                  PanelDefRules.processRules(
                    fieldRules.disableRules,
                    scope.panel,
                    scope.entity.number
                  );
              }
            });
          }
        });

        // If not already watching the number, set up a watcher for new entities
        if (!hasNumberWatcher && entityNumber !== null) {
          scope.$watch("entity.number", function (newValue, oldValue) {
            processNumberChange(newValue, oldValue);
          });
        }

        /**
         * Calls panel.setInvalidIndicator with necessary scoped variables
         * @param {boolean} val
         * @param {number} [recordNumber] - The entity number (for array concepts)
         */
        function setInvalidIndicator(val, recordNumber) {
          scope.panel.setInvalidIndicator(
            val,
            scope.concept.key,
            recordNumber,
            scope.field
          );
        }

        /**
         * Set session data for the number.
         * note: Assumes that the number has been initialized
         *
         * @param newValue
         * @param oldValue
         */
        function processNumberChange(newValue, oldValue) {
          if (newValue && +newValue !== +oldValue) {
            var existingEntity = scope.panel[scope.concept.key].find(function (
              entity
            ) {
              return +entity.number === +oldValue;
            });
            // Remove the session data for the old number if there isn't an actual entity there. The number should only
            // change for new entities (since the number field should be disabled for not isNew items)
            if (
              DoesNestedPropertyExist(
                scope.panel,
                "session.CONCEPTS." +
                  scope.concept.key +
                  ".NUMBERS." +
                  +oldValue
              )
            ) {
              if (angular.isUndefined(existingEntity)) {
                delete scope.panel.session.CONCEPTS[scope.concept.key].NUMBERS[
                  +oldValue
                ];
              }
            }
            scope.panel.initializeSessionData(
              scope.concept.key,
              +newValue,
              scope.field
            );
            // Run hide rules for all fields other than number
            angular.forEach(watchFields, function (fieldKey) {
              var fieldRules =
                scope.panel.session.panelDefRules.referenceGroups[
                  scope.concept.key
                ][fieldKey];
              if (fieldKey !== "number") {
                if (fieldRules.hideRules.length > 0)
                  PanelDefRules.processRules(
                    fieldRules.hideRules,
                    scope.panel,
                    scope.entity.number
                  );
              }
            });
          }
          // For 550 family device informations, set a value to indicate an AX bus address
          if (
            scope.concept.key === "device_informations" &&
            scope.panel.isXR550Family(scope.panel.panel_model) &&
            angular.isDefined(newValue)
          ) {
            var entityNumber = +newValue;
            scope.panel.session.CONCEPTS[scope.concept.key].NUMBERS[
              entityNumber
            ]["isAXDeviceNum"] = AXBus.AXBusNumbers.indexOf(entityNumber) >= 0;
          }
          if (scope.panel.isSharedConcept(scope.concept.key)) {
            scope.panel.updateItemsAvailable(scope.concept.key);
          }
          // Handle duplicate entity numbers
          if (
            angular.isDefined(scope.formInvalid) &&
            DoesNestedPropertyExist(scope, "entity.number") &&
            uniqueEntity()
          ) {
            setInvalidIndicator(
              Boolean(scope.formInvalid),
              +scope.entity.number
            );
          }
        }

        function uniqueEntity() {
          if (
            DoesNestedPropertyExist(scope, "concept.key") &&
            DoesNestedPropertyExist(scope, "panel." + scope.concept.key)
          ) {
            var likeNumberedEntities = scope.panel[scope.concept.key].filter(
              function (entity) {
                return +entity.number === +scope.entity.number;
              }
            );
            return likeNumberedEntities.length === 1;
          } else {
            return false;
          }
        }
      },
    };
  },
]);
