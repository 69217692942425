import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsAmbushReportsField_systemReports$key } from "./__generated__/SystemReportsAmbushReportsField_systemReports.graphql";

export const systemReportsAmbushReportsFieldId = () =>
  "system-reports-ambush-reports";

function SystemReportsAmbushReportsField() {
  const [{ ambushReports }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsAmbushReportsField_systemReports$key>(
      graphql`
        fragment SystemReportsAmbushReportsField_systemReports on SystemReports {
          ambushReports
        }
      `
    );

  const fieldId = systemReportsAmbushReportsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Ambush Reports"
      tooltip="Send an ambush report to the Receiver when the User Code for User Number 1 is entered."
    >
      <Switch
        label="Ambush Reports"
        id={fieldId}
        name={fieldId}
        checked={ambushReports}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(!ambushReports, "ambushReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsAmbushReportsField;
