/**
 * @ngdoc service
 * @name App.factory:ZoneCameraMappings
 *
 * @description
 * API factory for Mapping Zones to Cameras
 *
 */
App.factory("ZoneCameraMappingsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl +
        "/v2/panels/:panel_id/video_channel_mapped_zones/:update_mappings",
      {},
      {
        getMappings: {
          method: "GET",
          params: { panel_id: "@panel_id" },
          isArray: true,
          page: "@page",
        },
        updateMappings: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            update_mappings: "update_mappings",
            isArray: true,
          },
        },
      }
    );
  },
]);
