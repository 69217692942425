/**
 * @generated SignedSource<<057923ac162017be38d63aee9ea64d32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsDisarmedAnswerRingsField_remoteOptions$data = {
  readonly disarmedAnswerRings?: number | null;
  readonly " $fragmentType": "RemoteOptionsDisarmedAnswerRingsField_remoteOptions";
};
export type RemoteOptionsDisarmedAnswerRingsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsDisarmedAnswerRingsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsDisarmedAnswerRingsField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "disarmedAnswerRings",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsDisarmedAnswerRingsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "2ddbbe8c479f9077603dee20f535fad5";

export default node;
