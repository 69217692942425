/**
 * @ngdoc service
 * @name App.factory:GeoLocationAPI
 *
 * @description
 * API factory for GeoLocation
 *
 */

App.factory("GeoLocationAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/:type/",
      {},
      {
        requestLatLong: { method: "POST", params: { type: "Geolocation" } },
      }
    );
  },
]);
