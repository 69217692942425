import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import {
  ZoneSensorType,
  ZoneTransmitterContact,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationContactNormallyOpenField_zone$key } from "./__generated__/ZoneInformationContactNormallyOpenField_zone.graphql";

export const zoneInformationContactNormallyOpenFieldId = (number: string) =>
  `zone-information-contact-normally-open-${number}`;

function ZoneInformationContactNormallyOpenField() {
  const [
    {
      number,
      wirelessContactNormallyOpen,
      contactNumber,
      wireless,
      sensorType,
    },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationContactNormallyOpenField_zone$key>(
    graphql`
      fragment ZoneInformationContactNormallyOpenField_zone on Zone {
        number
        wirelessContactNormallyOpen
        wireless
        contactNumber
        sensorType
      }
    `
  );

  const fieldId = zoneInformationContactNormallyOpenFieldId(String(number));

  //Field is only enabled for zones using a wireless device using an external contact
  const disabled =
    !wireless ||
    ![
      ZoneSensorType.UNIVERSAL_TRANSMITTER,
      ZoneSensorType.WIRELESS_FOUR_ZONE_EXPANDER,
    ].includes(sensorType as ZoneSensorType) ||
    contactNumber !== ZoneTransmitterContact.EXTERNAL;
  const label = "Contact Normally Open";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
      tooltip="Select operation for external contact. Yes = Normally Open (N/O) No = Normally Closed (N/C)"
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={wirelessContactNormallyOpen}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              !wirelessContactNormallyOpen,
              "wirelessContactNormallyOpen"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationContactNormallyOpenField;
