/**
 * @generated SignedSource<<85eafb086964c89869524a10d104cb5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommPathContext_communicationPath$data = {
  readonly id: string;
  readonly " $fragmentType": "CommPathContext_communicationPath";
};
export type CommPathContext_communicationPath$key = {
  readonly " $data"?: CommPathContext_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathContext_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathContext_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "a1b8db5e84cecc17956d5c5a00dfad91";

export default node;
