import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsIso2CountryCodeField_systemOptions$key } from "./__generated__/SystemOptionsIso2CountryCodeField_systemOptions.graphql";

export const systemOptionsIso2CountryCodeFieldId = () =>
  "system-options-iso2-country-code";

function Iso2CountryCodeField() {
  const [{ iso2CountryCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsIso2CountryCodeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsIso2CountryCodeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            iso2CountryCode
          }
          ... on XtSystemOptions {
            iso2CountryCode
          }
        }
      `
    );

  const fieldId = systemOptionsIso2CountryCodeFieldId();

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="ISO2 Country Code">
      <TextInput
        type="text"
        id={fieldId}
        name={fieldId}
        validationMessage="2 character maximum."
        pattern="^[A-Z]{1,2}$"
        maxLength={2}
        value={iso2CountryCode}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value.toUpperCase(), "iso2CountryCode");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default Iso2CountryCodeField;
