/**
 * @generated SignedSource<<20c81767930eca41c8f9db34db25f5cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationProgrammingConceptFormNavButton_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly areas: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly isNew: boolean;
        } | null;
      }>;
    };
  };
  readonly " $fragmentType": "XRAreaInformationProgrammingConceptFormNavButton_controlSystem";
};
export type XRAreaInformationProgrammingConceptFormNavButton_controlSystem$key = {
  readonly " $data"?: XRAreaInformationProgrammingConceptFormNavButton_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptFormNavButton_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRAreaInformationProgrammingConceptFormNavButton_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 32
            },
            {
              "kind": "Literal",
              "name": "sort",
              "value": {
                "keys": [
                  "number"
                ],
                "order": "ASC"
              }
            }
          ],
          "concreteType": "AreaConnection",
          "kind": "LinkedField",
          "name": "areas",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AreaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isNew",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "areas(first:32,sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "9f78873c2fc0b8b4c2091ce4a4066769";

export default node;
