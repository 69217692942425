/**
 * @generated SignedSource<<a080fa5d01cf94873bc3680b69fc2fe0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommPathTrouble = "ALL" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XRStatusListDisplayProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly statusListDisplay: {
      readonly auxiliary1ZoneKeypads: string;
      readonly auxiliary2ZoneKeypads: string;
      readonly burglaryZoneKeypads: string;
      readonly carbonMonoxideZoneKeypads: string;
      readonly commPathTrouble: CommPathTrouble;
      readonly emergencyZoneKeypads: string;
      readonly fireZoneKeypads: string;
      readonly id: string;
      readonly panicZoneKeypads: string;
      readonly supervisoryZoneKeypads: string;
      readonly systemTroubleStatusMonitors: string;
    } | null;
  };
  readonly " $fragmentType": "XRStatusListDisplayProgrammingConceptFormInline_controlSystem";
};
export type XRStatusListDisplayProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRStatusListDisplayProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRStatusListDisplayProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRStatusListDisplayProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "5dc9a3cf61c695771dddd3e207d96689";

export default node;
