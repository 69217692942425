/**
 * @generated SignedSource<<6b1fa8103c7840bd5f328b7341810fa3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeyfobButtonType = "ARMING" | "DISARMING" | "EMERGENCY" | "EMERGENCY_TWO_BUTTON" | "OUTPUT" | "PANIC" | "PANIC_TWO_BUTTON" | "SENSOR_RESET" | "STATUS" | "TOGGLE_ARM_DISARM" | "UNUSED";
export type KeyfobOutputAction = "MOMENTARY" | "OFF" | "PULSE" | "STEADY" | "TOGGLE";
import { FragmentRefs } from "relay-runtime";
export type KeyfobButtonOutputField_keyfob$data = {
  readonly buttonFourAction: KeyfobButtonType;
  readonly buttonFourOutput: string;
  readonly buttonFourOutputAction: KeyfobOutputAction;
  readonly buttonOneAction: KeyfobButtonType;
  readonly buttonOneOutput: string;
  readonly buttonOneOutputAction: KeyfobOutputAction;
  readonly buttonThreeAction: KeyfobButtonType;
  readonly buttonThreeOutput: string;
  readonly buttonThreeOutputAction: KeyfobOutputAction;
  readonly buttonTwoAction: KeyfobButtonType;
  readonly buttonTwoOutput: string;
  readonly buttonTwoOutputAction: KeyfobOutputAction;
  readonly number: number;
  readonly " $fragmentType": "KeyfobButtonOutputField_keyfob";
};
export type KeyfobButtonOutputField_keyfob$key = {
  readonly " $data"?: KeyfobButtonOutputField_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobButtonOutputField_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobButtonOutputField_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOneAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreeAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOneOutput",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoOutput",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreeOutput",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourOutput",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOneOutputAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoOutputAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreeOutputAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourOutputAction",
      "storageKey": null
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "8993e564de004bbc2a4ffeb99f6af821";

export default node;
