/**
 * @ngdoc service
 * @name App.factory:NewsItems
 *
 * @description
 * Factory/model for a NewsItem.  News Items are created by Supervisor Admins and displayed on the dealer dashboard.
 */
App.factory("NewsItems", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/NewsItems/:news_item_id",
      {},
      {
        post: { method: "POST" },
        getAll: { method: "GET", isArray: true },
        getItem: { method: "GET" },
        put: { method: "PUT" },
        delete: { method: "DELETE" },
      }
    );
  },
]);
