/**
 * @generated SignedSource<<8220ac1dea280b8c67651e3ca7f927b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRStatusListDisplayProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly statusListDisplay: {
      readonly id: string;
      readonly supportsCarbonMonoxideZoneKeypads: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay" | "StatusListDisplayAuxiliary2ZoneKeypadsField_statusListDisplay" | "StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay" | "StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay" | "StatusListDisplayCommPathTroubleField_statusListDisplay" | "StatusListDisplayContext_statusListDisplay" | "StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay" | "StatusListDisplayFireZoneKeypadsField_statusListDisplay" | "StatusListDisplayPanicZoneKeypadsField_statusListDisplay" | "StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay" | "StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XRStatusListDisplayProgrammingConceptForm_controlSystem";
};
export type XRStatusListDisplayProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XRStatusListDisplayProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRStatusListDisplayProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRStatusListDisplayProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatusListDisplay",
          "kind": "LinkedField",
          "name": "statusListDisplay",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "supportsCarbonMonoxideZoneKeypads",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayContext_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayFireZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayPanicZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayAuxiliary2ZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusListDisplayCommPathTroubleField_statusListDisplay"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "fe577c7a60ea96ce780b6066a6dd42f8";

export default node;
