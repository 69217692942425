import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathCheckInMinutesField_communicationPath$key } from "./__generated__/CommPathCheckInMinutesField_communicationPath.graphql";

export const commPathCheckInMinutesFieldId = (number: string) =>
  `comm-path-check-in-minutes-${number}`;

function CommPathCheckInMinutesField() {
  const [
    {
      number,
      commType,
      useCheckIn,
      checkInMinutes,
      checkInMinutesMin,
      checkInMinutesMax,
    },
    updateCommPath,
  ] = useCommPathFragment<CommPathCheckInMinutesField_communicationPath$key>(
    graphql`
      fragment CommPathCheckInMinutesField_communicationPath on CommunicationPath {
        number
        useCheckIn
        commType
        checkInMinutes
        checkInMinutesMin
        checkInMinutesMax
      }
    `
  );

  const fieldId = commPathCheckInMinutesFieldId(String(number));
  const { current: originalValue } = React.useRef(checkInMinutes);
  const { isXt75 } = resolvePanelType(useHardwareModel());
  const disabled =
    !isXt75 && (["CID", "DD"].includes(commType) || useCheckIn !== "YES");

  const gqlCheckinRange = `${checkInMinutesMin}–${checkInMinutesMax}`;
  const inlineHelp = isXt75 ? `0, ${gqlCheckinRange}` : gqlCheckinRange;
  const validCheckinRange = isXt75
    ? range(checkInMinutesMin, checkInMinutesMax + 1).concat(0)
    : range(checkInMinutesMin, checkInMinutesMax + 1);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Check-in Minutes"
      tooltip="Number of minutes the System waits between sending Check-In messages to the Receiver."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={checkInMinutes.toString()}
        required
        disabled={disabled}
        inlineHelp={inlineHelp}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "checkInMinutes"
            );
          });
        }}
        onBlur={({ target }) => {
          const valueAsNumber = Number(target.value);
          const value =
            !target.value || isNaN(valueAsNumber)
              ? originalValue
              : closest(valueAsNumber, validCheckinRange);
          updateCommPath((recordProxy) => {
            recordProxy.setValue(value, "checkInMinutes");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathCheckInMinutesField;
