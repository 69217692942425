/**
 * @generated SignedSource<<c6ea8f73536edc37675bc75e0cb46486>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationAccountNumberField_communication$data = {
  readonly accountNumber: string;
  readonly " $fragmentType": "CommunicationAccountNumberField_communication";
};
export type CommunicationAccountNumberField_communication$key = {
  readonly " $data"?: CommunicationAccountNumberField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationAccountNumberField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationAccountNumberField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNumber",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "9eb64b8a554791712511638bf095c882";

export default node;
