import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const OutputGroupContext = React.createContext<any>({});

export function OutputGroupContextProvider(props: {
  outputGroup: any;
  children: React.ReactNode;
}) {
  return (
    <OutputGroupContext.Provider value={props.outputGroup}>
      {props.children}
    </OutputGroupContext.Provider>
  );
}

export function useOutputGroupFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const outputGroup = React.useContext(OutputGroupContext);

  const data = useFragment(fragmentInput, outputGroup as TKey);

  const { id } = useFragment(
    graphql`
      fragment OutputGroupContext_outputGroup on OutputGroup {
        id
      }
    `,
    outputGroup as any
  );

  const update = (
    updater: (outputGroups: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
