interface RawIensoLivestreamServerConfig {
  password: string;
  stun_url: string;
  turn_url: string[];
  username: string;
}

interface RawIensoLivestream {
  session_token: string;
  signaling_url: string;
  turn_server: RawIensoLivestreamServerConfig;
}

export type IensoLivestream = {
  password: string;
  sessionToken: string;
  signalingUrl: string;
  stunUrl: string;
  turnUrls: string[];
  username: string;
};

export const parseIensoLivestream = (
  rawIensoLivestream: RawIensoLivestream
): IensoLivestream => {
  return {
    password: rawIensoLivestream.turn_server.password,
    sessionToken: rawIensoLivestream.session_token,
    signalingUrl: rawIensoLivestream.signaling_url,
    stunUrl: rawIensoLivestream.turn_server.stun_url,
    turnUrls: rawIensoLivestream.turn_server.turn_url,
    username: rawIensoLivestream.turn_server.username,
  };
};
