import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver1SupervisoryTroubleReportsField_communication$key } from "./__generated__/CommunicationReceiver1SupervisoryTroubleReportsField_communication.graphql";

export const communicationReceiver1SupervisoryTroubleReportsFieldId = () =>
  "communication-receiver-1-supervisory-trouble-reports";

function CommunicationReceiver1SupervisoryTroubleReportsField() {
  const [{ receiver1SupervisoryTroubleReports }, updateCommunication] =
    useCommunicationFragment<CommunicationReceiver1SupervisoryTroubleReportsField_communication$key>(
      graphql`
        fragment CommunicationReceiver1SupervisoryTroubleReportsField_communication on Communication {
          receiver1SupervisoryTroubleReports
        }
      `
    );
  const fieldId = communicationReceiver1SupervisoryTroubleReportsFieldId();
  const label = "Supervisory / Trouble Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Turns on Zone Trouble, Low Battery, Missing, Fault and System Trouble reports and associated restoral reports."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={receiver1SupervisoryTroubleReports}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              !receiver1SupervisoryTroubleReports,
              "receiver1SupervisoryTroubleReports"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver1SupervisoryTroubleReportsField;
