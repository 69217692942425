/**
 * @generated SignedSource<<7d48a3b4bc84ce22de0c0310e1c7b3f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreBackupIpv6AddressField_remoteOptions$data = {
  readonly entreBackupConnection?: RemoteOptionsConnectionType | null;
  readonly entreBackupIpv6Address?: string | null;
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly entreIpv6?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsEntreBackupIpv6AddressField_remoteOptions";
};
export type RemoteOptionsEntreBackupIpv6AddressField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreBackupIpv6AddressField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreBackupIpv6AddressField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreBackupIpv6AddressField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreBackupIpv6Address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreConnection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreBackupConnection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreIpv6",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "9adcc3e97a49dba2d7b8ae01f54c4f24";

export default node;
