/**
 *
 * EditableLabel Messages
 * @author Chad Watson
 *
 * This contains all the text for the EditableLabel component.
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  edit: {
    id: "app.containers.ProfilesPage.edit",
    defaultMessage: "Edit",
  },
  done: {
    id: "app.containers.ProfilesPage.done",
    defaultMessage: "Done",
  },
});
