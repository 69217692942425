import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { setIncludedFields as setAreaInformationIncludedFields } from "./Data/XRAreaInformationTemplateData";
import { setIncludedFields as setBellOptionsIncludedFields } from "./Data/XRBellOptionsTemplateData";
import { setIncludedFields as setCardFormatsIncludedFields } from "./Data/XRCardFormatsTemplateData";
import { setIncludedFields as setCommunicationIncludedFields } from "./Data/XRCommunicationPathTemplateData";
import { setIncludedFields as setDeviceInformationIncludedFields } from "./Data/XRDeviceInformationTemplateData";
import { setIncludedFields as setKeyfobsIncludedFields } from "./Data/XRKeyfobsTemplateData";
import { setIncludedFields as setLockoutCodeIncludedFields } from "./Data/XRLockoutCodeTemplateData";
import { setIncludedFields as setMenuDisplayIncludedFields } from "./Data/XRMenuDisplayTemplateData";
import { setIncludedFields as setNetworkOptionsIncludedFields } from "./Data/XRNetworkOptionsTemplateData";
import { setIncludedFields as setOutputGroupsIncludedFields } from "./Data/XROutputGroupsTemplateData";
import { setIncludedFields as setOutputInformationsIncludedFields } from "./Data/XROutputInformationTemplateData";
import { setIncludedFields as setOutputOptionsIncludedFields } from "./Data/XROutputOptionsTemplateData";
import { setIncludedFields as setPcLogReportsIncludedFields } from "./Data/XRPcLogReportsTemplateData";
import { setIncludedFields as setRemoteOptionsIncludedFields } from "./Data/XRRemoteOptionsTemplateData";
import { setIncludedFields as setSecurityGradeIncludedFields } from "./Data/XRSecurityGradeTemplateData";
import { setIncludedFields as setStatusListDisplayIncludedFields } from "./Data/XRStatusListDisplayTemplateData";
import { setIncludedFields as setSystemAreaInformationIncludedFields } from "./Data/XRSystemAreaInformationTemplateData";
import { setIncludedFields as setSystemOptionsIncludedFields } from "./Data/XRSystemOptionsTemplateData";
import { setIncludedFields as setSystemReportsIncludedFields } from "./Data/XRSystemReportsTemplateData";
import { setIncludedFields as setZoneInformationIncludedFields } from "./Data/XRZoneInformationTemplateData";
import { XRTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        ...XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRCommunicationPathTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRDeviceInformationTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRKeyfobsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRNetworkOptionsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XROutputInformationTemplateDataInline_xrProgrammingTemplateConcepts
        ...XROutputOptionsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRRemoteOptionsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRSystemOptionsTemplateDataInline_XrProgrammingTemplateConcepts
        ...XRSystemReportsTemplateDataInline_xrProgrammingTemplateConcepts
        ...XRZoneInformationTemplateDataInline_xrProgrammingTemplateConcepts
      }
    `,
    template
  );

export const setAllIncludedFields = (
  template: XRTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const templateData = getState(template);

  setAreaInformationIncludedFields(templateData, setIncludedFields);
  setBellOptionsIncludedFields(templateData, setIncludedFields);
  setCardFormatsIncludedFields(templateData, setIncludedFields);
  setCommunicationIncludedFields(templateData, setIncludedFields);
  setDeviceInformationIncludedFields(templateData, setIncludedFields);
  setKeyfobsIncludedFields(templateData, setIncludedFields);
  setLockoutCodeIncludedFields(templateData, setIncludedFields);
  setMenuDisplayIncludedFields(templateData, setIncludedFields);
  setNetworkOptionsIncludedFields(templateData, setIncludedFields);
  setOutputOptionsIncludedFields(templateData, setIncludedFields);
  setOutputGroupsIncludedFields(templateData, setIncludedFields);
  setOutputInformationsIncludedFields(templateData, setIncludedFields);
  setPcLogReportsIncludedFields(templateData, setIncludedFields);
  setRemoteOptionsIncludedFields(templateData, setIncludedFields);
  setSecurityGradeIncludedFields(templateData, setIncludedFields);
  setStatusListDisplayIncludedFields(templateData, setIncludedFields);
  setSystemAreaInformationIncludedFields(templateData, setIncludedFields);
  setSystemOptionsIncludedFields(templateData, setIncludedFields);
  setSystemReportsIncludedFields(templateData, setIncludedFields);
  setZoneInformationIncludedFields(templateData, setIncludedFields);
};
