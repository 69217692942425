import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputInformationFragment } from "./OutputInformationContext";
import { resolveOutputFieldRules } from "./utils";
import { OutputInformationNameField_output$key } from "./__generated__/OutputInformationNameField_output.graphql";

export const outputInformationNameFieldId = (number: string) =>
  `output-information-name-${number}`;

function OutputInformationNameField() {
  const [{ number, name }, updateOutputInformation] =
    useOutputInformationFragment<OutputInformationNameField_output$key>(
      graphql`
        fragment OutputInformationNameField_output on Output {
          number
          name
        }
      `
    );

  const fieldId = outputInformationNameFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { NAME: RULES } = resolveOutputFieldRules(hardwareModel);
  const { isXr, isXf } = resolvePanelType(hardwareModel);
  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Output Name">
      <TextInput
        id={fieldId}
        pattern={RULES.PATTERN}
        value={name}
        required
        maxLength={isXr || isXf ? 32 : 16}
        onChange={({ target }) => {
          updateOutputInformation((recordProxy) => {
            recordProxy.setValue(target.value, "name");
          });
        }}
        onBlur={({ target }) => {
          updateOutputInformation((recordProxy) => {
            recordProxy.setValue(target.value.trim().toUpperCase(), "name");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputInformationNameField;
