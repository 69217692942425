/**
 * @generated SignedSource<<5843a108cbb3cd4babb1f3b63adbf8a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationSerialNumberField_deviceInformation$data = {
  readonly deviceCommunicationMethod?: DeviceInformationCommType;
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly number: number;
  readonly serialNumber: string;
  readonly wireless?: boolean;
  readonly wirelessTranslator1100t?: boolean;
  readonly " $fragmentType": "DeviceInformationSerialNumberField_deviceInformation";
};
export type DeviceInformationSerialNumberField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationSerialNumberField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationSerialNumberField_deviceInformation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wirelessTranslator1100t",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceCommunicationMethod",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationSerialNumberField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wireless",
          "storageKey": null
        }
      ],
      "type": "XtDeviceInformation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "XrDeviceInformation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "Xt75DeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};
})();

(node as any).hash = "9a9055e8e5aca8caf6d84b1513658a5b";

export default node;
