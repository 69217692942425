/**** Job Group List Component ****/

/*
 *  This component will provide a table with a list of job groups for a given panel ID
 *  which the user can click to select a job group. This will pass the group ID up to the
 *  parent component using the setSelectedGroup function.
 */

import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import styled from "styled-components";
import { JobGroupListQuery } from "./__generated__/JobGroupListQuery.graphql";
import { JobGroupListRefreshQuery } from "./__generated__/JobGroupListRefreshQuery.graphql";
import { JobGroupList_query$key } from "./__generated__/JobGroupList_query.graphql";

const WrapCell = styled.td`
  overflow: visible !important;
  text-overflow: visible !important;
  word-wrap: break-word !important;
  white-space: normal !important;
`;

const TableRow = styled.tr`
  cursor: pointer;
  &&:hover {
    background-color: #edf0f4;
  }
`;

const StatusIndicator = styled.span`
  font-size: 2em !important;
  vertical-align: top !important;
  line-height: 0.6em !important;
  margin-left: 5px !important;
  display: inline-block !important;
  color: ${(props) => props.color || "var(--color-warning-500)"} !important;
`;

const groupListQuery = graphql`
  query JobGroupListQuery($panelId: Int!) {
    ...JobGroupList_query
  }
`;

function formatDateString(dateString: string) {
  // small function to format dates from "YYYY-MM-DDTHH:MM:SS-5:00" to something more readable
  const dateObj = new Date(dateString);
  return `${dateObj.getMonth()}/${dateObj.getDay()}/${dateObj.getFullYear()} at ${dateObj
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${dateObj.getSeconds().toString().padStart(2, "0")}`;
}

function copyGroup(result: any) {
  const padNum = 25;
  const copyText =
    "Group Id:".padEnd(padNum - 1) +
    (result.groupId ? result.groupId : "-") +
    "\nStatus:".padEnd(padNum) +
    (result.groupStatus ? result.groupStatus : "-") +
    "\nGroup Class:".padEnd(padNum) +
    (result.groupClass ? result.groupClass : "-") +
    "\nGroup Description:".padEnd(padNum) +
    (result.groupDesc ? result.groupDesc : "-") +
    "\nGroup Message:".padEnd(padNum) +
    (result.groupMessage ? result.groupMessage : "-") +
    "\nCreated:".padEnd(padNum) +
    (result.createdAt ? formatDateString(result.createdAt) : "-") +
    "\nUpdated:".padEnd(padNum) +
    (result.updatedAt ? formatDateString(result.updatedAt) : "-") +
    "\nCompleted:".padEnd(padNum) +
    (result.completedAt ? formatDateString(result.completedAt) : "-") +
    "\n";

  return copyText;
}

function JobGroupList({
  inputPanelId,
  setSelectedGroup,
  setModalOpen,
}: {
  inputPanelId: number;
  setSelectedGroup: (group: any) => void;
  setModalOpen: (val: boolean) => void;
}) {
  // we'll keep track of the ID locally to show the user which group is selected
  const [selectedGroupId, setSelectedGroupId] = React.useState(-1);

  const data = useLazyLoadQuery<JobGroupListQuery>(groupListQuery, {
    panelId: inputPanelId,
  });

  // made refetchable to allow refreshes
  const [refreshedGroup, refetch] = useRefetchableFragment<
    JobGroupListRefreshQuery,
    JobGroupList_query$key
  >(
    graphql`
      fragment JobGroupList_query on Query
      @refetchable(queryName: "JobGroupListRefreshQuery") {
        systemDiagnosticsPanelJobGroups(panelId: $panelId) {
          groupId
          groupDesc
          groupStatus
          groupMessage
          groupClass
          createdAt
          updatedAt
          completedAt
        }
      }
    `,
    data
  );

  return (
    <>
      {refreshedGroup.systemDiagnosticsPanelJobGroups.length == 0 && (
        <p style={{ textAlign: "center" }}>
          Couldn't find any job groups for this panel.
        </p>
      )}
      {refreshedGroup.systemDiagnosticsPanelJobGroups.length > 0 && (
        <div
          className="table-condensed"
          style={{ maxHeight: "30em", minHeight: "20em", overflow: "scroll" }}
        >
          <table className="table table-striped table-fixed--not-mobile">
            <caption>
              <button
                onClick={() => refetch({}, { fetchPolicy: "network-only" })}
                className="link link-primary color-primary-600"
                style={{ textDecoration: "none", marginLeft: "20px" }}
              >
                <strong>Refresh</strong>
              </button>
            </caption>
            <thead>
              <tr>
                <th style={{ width: "5%", textAlign: "center" }}></th>
                <th>Group ID</th>
                <th>Status</th>
                <th>Group Class</th>
                <th>Group Description</th>
                <th>Group Message</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Completed</th>
                <th style={{ width: "5%", textAlign: "center" }}></th>
              </tr>
            </thead>
            <tbody>
              {refreshedGroup.systemDiagnosticsPanelJobGroups.map((group) => {
                return (
                  <TableRow
                    key={group.groupId}
                    onClick={() => {
                      setSelectedGroup(group);
                      setSelectedGroupId(group.groupId);
                      setModalOpen(true);
                    }}
                  >
                    <td>
                      <button
                        title="Copy to Clipboard"
                        className="link link-primary color-primary-600"
                        style={{ textDecoration: "none", zIndex: "1000" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigator.clipboard.writeText(copyGroup(group));
                        }}
                      >
                        <i
                          style={{
                            fontSize: "1.8em",
                            verticalAlign: "middle",
                          }}
                          className="icon-doc"
                        ></i>
                      </button>
                    </td>
                    <td>{group.groupId}</td>
                    <td>
                      {group.groupStatus}
                      {group.groupStatus == "fail" && (
                        <StatusIndicator color="var(--color-danger-500)">
                          &bull;
                        </StatusIndicator>
                      )}
                      {group.groupStatus == "success" && (
                        <StatusIndicator color="var(--color-success-500)">
                          &bull;
                        </StatusIndicator>
                      )}
                      {group.groupStatus != "fail" &&
                        group.groupStatus != "success" && (
                          <StatusIndicator>&bull;</StatusIndicator>
                        )}
                    </td>
                    <WrapCell>{group.groupClass}</WrapCell>
                    <WrapCell>{group.groupDesc}</WrapCell>
                    <WrapCell>{group.groupMessage}</WrapCell>
                    <WrapCell>
                      {group.createdAt
                        ? formatDateString(group.createdAt)
                        : "-"}
                    </WrapCell>
                    <WrapCell>
                      {group.updatedAt
                        ? formatDateString(group.updatedAt)
                        : "-"}
                    </WrapCell>
                    <WrapCell>
                      {group.completedAt
                        ? formatDateString(group.completedAt)
                        : "-"}
                    </WrapCell>

                    <td style={{ padding: "1.2rem 0" }}>
                      {group.groupId == selectedGroupId && (
                        <i className="icon-checkmark"></i>
                      )}
                    </td>
                  </TableRow>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default JobGroupList;
