/**
 * @generated SignedSource<<6c84106742cbecc3a842c4ced8e13a8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UniviewCameraEditFormFormatSdCardMutation$variables = {
  deviceId: string;
};
export type UniviewCameraEditFormFormatSdCardMutation$data = {
  readonly formatSdCard: {
    readonly message: string | null;
    readonly success: boolean;
  };
};
export type UniviewCameraEditFormFormatSdCardMutation = {
  response: UniviewCameraEditFormFormatSdCardMutation$data;
  variables: UniviewCameraEditFormFormatSdCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      }
    ],
    "concreteType": "FormatSdCardPayload",
    "kind": "LinkedField",
    "name": "formatSdCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UniviewCameraEditFormFormatSdCardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UniviewCameraEditFormFormatSdCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f694fb019f8bdc3d167c0478e1ed8bdc",
    "id": null,
    "metadata": {},
    "name": "UniviewCameraEditFormFormatSdCardMutation",
    "operationKind": "mutation",
    "text": "mutation UniviewCameraEditFormFormatSdCardMutation(\n  $deviceId: ID!\n) {\n  formatSdCard(deviceId: $deviceId) {\n    success\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "eedf918d7472d5abb944380019817022";

export default node;
