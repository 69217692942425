/**
 * @generated SignedSource<<846e2dec4519af8ba222bf1ac0e77131>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly pcLogReports: {
    readonly armDisarmReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly doorAccessReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly logRealTimeStatusEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly networkIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly networkPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly userCommandReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly zonesReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "e5b8adc5cebf786922be7266bc3b5b49";

export default node;
