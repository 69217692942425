import NakedButton from "common/components/web/Button/NakedButton";
import Flex from "common/components/web/Flex";
import NakedList from "common/components/web/NakedList";
import { visuallyHidden } from "common/components/web/VisuallyHidden";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDelete, setToggle } from "common/utils/universal/set";
import ConfirmActionModal from "components/ConfirmActionModal";
import { Tooltip } from "components/DaStyledElements";
import GenericErrorBoundary from "components/GenericErrorBoundary";
import { GenericPageFallback } from "components/GenericPageFallback";
import Icon from "components/Icon";
import GenericTextInput from "components/Inputs/TextInput";
import MoreButton from "components/MoreButton";
import NeutralText from "components/NeutralText";
import Select from "components/Select";
import Tag from "components/Tag";
import { flow } from "fp-ts/lib/function";
import React, { useMemo } from "react";
import styled, { css } from "styled-components/macro";
import { focusRing } from "utils/styles";
import { resolvePanelType } from "../utils/panel";
import { IndicatorState, indicatorStyles } from "../utils/styles";
import { XF_DEVICE_INFORMATION_NUMBER } from "../XFFullProgramming/XFDeviceSetupProgrammingConceptForm/XFDeviceInformationNumberField";
import { XR_DEVICE_INFORMATION_NUMBER } from "../XRFullProgramming/XRDeviceSetupProgrammingConceptForm/XRDeviceInformationNumberField";
import { XT75_DEVICE_INFORMATION_NUMBER } from "../XT75FullProgramming/XT75DeviceSetupProgrammingConceptForm/XT75DeviceInformationNumberField";
import ActiveConceptContext from "./ActiveConceptContext";
import { AREA_ACCOUNT_NUMBER } from "./AreaInformationFields/AreaFields/AreaAccountNumberField";
import { AREA_NUMBER } from "./AreaInformationFields/AreaFields/AreaNumberField";
import { CARD_FORMAT_NUMBER } from "./CardFormatFields/CardFormatNumberField";
import {
  ChangedProgrammingConceptContextProvider,
  useFieldIsChanged,
  useListItemHasChanged,
} from "./ChangedProgrammingConceptsContext";
import { commPathAccountNumberFieldId } from "./CommPathFields/CommPathAccountNumberField";
import { COMM_PATH_NUMBER } from "./CommPathFields/CommPathNumberField";
import { communicationAccountNumberFieldId } from "./CommunicationFields/CommunicationAccountNumberField";
import { DEVICE_INFORMATION_NUMBER } from "./DeviceInformationFields/DeviceInformationNumberField";
import { DEVICE_INFORMATION_SERIAL_NUMBER } from "./DeviceInformationFields/DeviceInformationSerialNumberField";
import {
  useIncludedTemplateField,
  useIncludedTemplateFields,
} from "./IncludedTemplateFieldsContext";
import {
  useFieldIsInvalid,
  useListItemHasInvalidFields,
  useSetInvalidFields,
} from "./InvalidFieldsContext";
import IsNewContext from "./IsNewContext";
import { KEY_FOB_NUMBER } from "./KeyfobFields/KeyfobNumberField";
import { KEYFOB_SERIAL_NUMBER } from "./KeyfobFields/KeyfobSerialNumberField";
import { KEYFOB_USER_NAME } from "./KeyfobFields/KeyfobUsernameField";
import { KEYFOB_USER_NUMBER } from "./KeyfobFields/KeyfobUserNumberField";
import { networkOptionsDnsServerFieldId } from "./NetworkOptionsFields/NetworkOptionsDnsServerField";
import { networkOptionsGatewayAddressFieldId } from "./NetworkOptionsFields/NetworkOptionsGatewayAddressField";
import { networkOptionsIpv6AddressFieldId } from "./NetworkOptionsFields/NetworkOptionsIpv6AddressField";
import { networkOptionsIpv6DnsServerFieldId } from "./NetworkOptionsFields/NetworkOptionsIpv6DnsServerField";
import { networkOptionsIpv6GatewayFieldId } from "./NetworkOptionsFields/NetworkOptionsIpv6GatewayField";
import { networkOptionsIpv6PrefixBitsFieldId } from "./NetworkOptionsFields/NetworkOptionsIpv6PrefixBitsField";
import { networkOptionsLocalIpAddressFieldId } from "./NetworkOptionsFields/NetworkOptionsLocalIpAddressField";
import { networkOptionsSubnetMaskFieldId } from "./NetworkOptionsFields/NetworkOptionsSubnetMaskField";
import { networkOptionsUseIpv6FieldId } from "./NetworkOptionsFields/NetworkOptionsUseIpv6Field";
import { OUTPUT_GROUP_NUMBER } from "./OutputGroupFields/OutputGroupNumberField";
import { OUTPUT_INFORMATION_NUMBER } from "./OutputInformationFields/OutputInformationNumberField";
import { OUTPUT_INFORMATION_SERIAL_NUMBER } from "./OutputInformationFields/OutputInformationSerialNumberField";
import Panel from "./Panel";
import ProgrammingConceptFormListItemIdContext, {
  useProgrammingConceptFormListItemIdContext,
} from "./ProgrammingConceptFormListItemIdContext";
import ProgrammingConceptIdContext, {
  useProgrammingConceptIdContext,
} from "./ProgrammingConceptIdContext";
import { useProgrammingActionsContext } from "./ProgrammingContext";
import { remoteOptionsRemoteKeyFieldId } from "./RemoteOptionsFields/RemoteOptionsRemoteKeyField";
import { useSearchContext } from "./SearchContext";
import SelectedListItemContext from "./SelectedListItemContext";
import StandardButton from "./StandardButton";
import { useTemplateContext } from "./TemplateContext";
import TemplateListItemIdContext from "./TemplateListItemIdContext";
import { ZONE_INFORMATION_EXPANDER_SERIAL_NUMBER } from "./ZoneInformationFields/ZoneInformationExpanderSerialNumberField";
import { ZONE_INFORMATION_NUMBER } from "./ZoneInformationFields/ZoneInformationNumberField";
import { ZONE_INFORMATION_SENSOR_TYPE_FIELD } from "./ZoneInformationFields/ZoneInformationSensorTypeField";
import { ZONE_INFORMATION_SERIAL_NUMBER } from "./ZoneInformationFields/ZoneInformationSerialNumberField";
import { ZONE_INFORMATION_VPLEX_SERIAL_NUMBER } from "./ZoneInformationFields/ZoneInformationVplexSerialNumberField";

const inlineLabelBreakpoint = "37.5rem";
const inlineListItemsBreakpoint = "60rem";

const RootContainer = styled((props) => {
  const { isSaving, isRetrieving } =
    useProgrammingActionsContext().programmingConcepts[
      useProgrammingConceptIdContext()
    ];
  return <Panel {...props} as="fieldset" disabled={isSaving || isRetrieving} />;
})`
  margin: 0;
  padding: 0;
`;
const ContentContainer = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const MainContainer = styled.div`
  padding: var(--measure-20);
`;

const AddButton = styled(StandardButton)``;
const AddIcon = styled(Icon)`
  @media screen and (min-width: ${inlineListItemsBreakpoint}) {
    margin-right: 0.4rem;
  }
`;
const AddButtonText = styled.span`
  display: none;

  @media screen and (min-width: ${inlineListItemsBreakpoint}) {
    display: inline-block;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--measure-20);

  & ${AddButton} {
    display: none;
    @media screen and (min-width: ${inlineListItemsBreakpoint}) {
      display: inline-block;
    }
  }
`;
const Title = styled.h1`
  margin: 0 var(--measure-half) 0 0;
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-text-default);
`;
const HelpLink = styled.a`
  display: inline-block;

  &:focus {
    ${focusRing};
  }
`;
const HelpIcon = styled.em`
  font-size: 1.4em;
  position: relative;
`;
const Table = styled.table`
  width: 100%;
  border: none;
  background-color: transparent;
`;
const Row = styled.tr`
  border: none;
  width: 100%;
`;
const FieldIncludedCell = styled.td`
  border: none;
  padding: 0.2rem var(--measure-2x) 0.2rem 0;
  vertical-align: middle;
  width: auto;
`;
const Cell = styled.td<{ disabled?: boolean }>`
  display: block;
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  user-select: ${({ disabled }) => (disabled ? "none" : "auto")};

  @media screen and (min-width: ${inlineLabelBreakpoint}) {
    display: table-cell;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    vertical-align: middle;
  }
`;
const FieldLabelCell = styled(Cell)`
  padding-right: var(--measure-2x);
  padding-left: 0;
  padding-bottom: var(--measure-half);
  width: auto;
`;
const FieldLabelContainer = styled.span`
  @media screen and (min-width: ${inlineLabelBreakpoint}) {
    display: flex;
    align-items: center;
    vertical-align: middle;
    min-height: 3.6rem;
  }
`;
const FieldValueCell = styled(Cell)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: var(--measure-2x);
  width: 100%;
`;
const FieldLabelText = styled.label<{
  indicator?: IndicatorState | null | undefined;
}>`
  white-space: nowrap;
  margin: 0;
  font-weight: 700;

  &::after {
    content: "";
    display: inline-block;
    ${indicatorStyles};
  }
`;

const ToolTipFieldLabelTextWrapper = styled.div`
  padding-right: 0.5rem;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    ${indicatorStyles};
  }
`;
const Checkbox = styled.div`
  margin: 0 !important;
`;
const Legend = styled.legend`
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
`;
const EmptyMessage = styled.p`
  display: none;
  font-size: var(--measure-font-16);

  @media screen and (min-width: ${inlineListItemsBreakpoint}) {
    display: block;
  }
`;
const ButtonGroup = styled.div`
  min-width: 67px;
`;

function HeaderLayout(props: {
  title: React.ReactNode;
  helpLink?: string;
  buttons?: React.ReactNode;
}) {
  const amountAvailable = React.useContext(AmountAvailableContext);
  return (
    <Header>
      <Flex flexWrap="wrap">
        <Flex>
          <Legend>
            <Title>{props.title}</Title>
          </Legend>
          {props.helpLink ? (
            <HelpLink href={props.helpLink} target="_blank" rel="noreferrer">
              <HelpIcon className="btn-help icon-radial_question btn-help-concept pull-right">
                &nbsp;
              </HelpIcon>
            </HelpLink>
          ) : null}
        </Flex>
        {isNotNullOrUndefined(amountAvailable) ? (
          <Tag variant="success">
            {amountAvailable >= 0 ? amountAvailable : 0} available
          </Tag>
        ) : null}
      </Flex>
      {props.buttons}
    </Header>
  );
}

function AllNoneButtons(props: {
  conceptId: string;
  listItemTemplateId?: string;
}) {
  const { listItemTemplateId } = props;
  const { allFields } = useTemplateContext();
  const [includedFields, setIncludedFields] = useIncludedTemplateFields();
  const allFieldsCount = allFields.size;
  const getConceptFields = () => {
    const conceptId = props.conceptId.startsWith("takeover")
      ? props.conceptId.replace("takeover-panel-", "")
      : props.conceptId.startsWith("tmsentry")
      ? props.conceptId.replace("tmsentry-", "")
      : props.conceptId.startsWith("xt75")
      ? props.conceptId.replace("xt75-", "")
      : props.conceptId.slice(3);
    let fields: string[] = [];
    switch (conceptId) {
      case "network-options":
      case "remote-options":
      case "system-reports":
      case "system-options":
      case "bell-options":
      case "output-options":
      case "menu-display":
      case "status-list-display":
      case "pc-log-reports":
      case "lockout-code":
      case "security-grade":
        fields = Array.from(allFields).filter((field) =>
          field.startsWith(conceptId)
        );
        break;
      case "communication":
        if (listItemTemplateId) {
          // Get the last characters of listItemTemplateId including "-" to serve as identifier for records of fields
          const fieldIdentifier = listItemTemplateId.slice(
            listItemTemplateId.lastIndexOf("-")
          );
          // Get fields starting with concept field pattern and fieldIdentifier
          fields = Array.from(allFields).filter(
            (field) =>
              field.startsWith("comm-path") && field.endsWith(fieldIdentifier)
          );
        } else {
          // No listItemTemplateId means it's coming from header so search for fieldIds with no numbers at the end
          fields = Array.from(allFields).filter(
            (field) =>
              (field.startsWith("comm-path") || field.startsWith(conceptId)) &&
              isNaN(field.slice(-1) as unknown as number)
          );
        }
        break;
      case "device-setup":
        if (listItemTemplateId) {
          const fieldIdentifier = listItemTemplateId.slice(
            listItemTemplateId.lastIndexOf("-")
          );
          fields = Array.from(allFields).filter(
            (field) =>
              field.startsWith("device-information") &&
              field.endsWith(fieldIdentifier)
          );
        }
        break;
      case "card-formats":
        if (listItemTemplateId) {
          const fieldIdentifier = listItemTemplateId.slice(
            listItemTemplateId.lastIndexOf("-")
          );
          fields = Array.from(allFields).filter(
            (field) =>
              field.startsWith("card-format") && field.endsWith(fieldIdentifier)
          );
        }
        break;
      case "output-groups":
        if (listItemTemplateId) {
          const fieldIdentifier = listItemTemplateId.slice(
            listItemTemplateId.lastIndexOf("-")
          );
          fields = Array.from(allFields).filter(
            (field) =>
              field.startsWith("output-group") &&
              field.endsWith(fieldIdentifier)
          );
        }
        break;
      case "area-information":
        if (listItemTemplateId) {
          const fieldIdentifier = listItemTemplateId.slice(
            listItemTemplateId.lastIndexOf("-")
          );
          fields = Array.from(allFields).filter(
            (field) =>
              field.startsWith("area") && field.endsWith(fieldIdentifier)
          );
        } else {
          fields = Array.from(allFields).filter((field) =>
            field.startsWith("system-area-information")
          );
        }
        break;
      case "key-fobs":
        if (listItemTemplateId) {
          const fieldIdentifier = listItemTemplateId.slice(
            listItemTemplateId.lastIndexOf("-")
          );
          fields = Array.from(allFields).filter(
            (field) =>
              field.startsWith("keyfob") && field.endsWith(fieldIdentifier)
          );
        }
        break;
      case "output-information":
      case "zone-information":
        if (listItemTemplateId) {
          const fieldIdentifier = listItemTemplateId.slice(
            listItemTemplateId.lastIndexOf("-")
          );
          fields = Array.from(allFields).filter(
            (field) =>
              field.startsWith(conceptId) && field.endsWith(fieldIdentifier)
          );
        }
        break;
    }

    return fields;
  };
  let conceptFields = useMemo(getConceptFields, [allFields.size]);

  const handleAllClick = () => {
    conceptFields = getConceptFields(); // Have to renew conceptFields to get the latest fields because the item number may have changed
    conceptFields.forEach((field) => {
      if (!includedFields.has(field)) {
        setIncludedFields(setToggle(field));
      }
    });
  };
  const handleNoneClick = () => {
    conceptFields = getConceptFields(); // Have to renew conceptFields to get the latest fields because the item number may have changed
    conceptFields.forEach((field) => {
      if (includedFields.has(field)) {
        setIncludedFields(setToggle(field));
      }
    });
  };
  return (
    <ButtonGroup className="btn-group btn-group-xs" role="group">
      <button
        className="btn btn-default"
        type="button"
        onClick={handleAllClick}
      >
        All
      </button>
      <button
        className="btn btn-default"
        type="button"
        onClick={handleNoneClick}
      >
        None
      </button>
    </ButtonGroup>
  );
}

function ProgrammingConceptFormFields(props: {
  conceptId: string;
  initialDataIsNotEmptyOrNull: boolean;
  isArrayConcept?: boolean;
  children: React.ReactNode;
}) {
  const programmingContext = useProgrammingActionsContext();
  const actions = programmingContext.programmingConcepts[props.conceptId];

  if (actions?.isRetrieving) {
    return <GenericPageFallback />;
  } else if (props.initialDataIsNotEmptyOrNull || props.isArrayConcept) {
    return <>{props.children}</>;
  } else {
    return <>Unable to Connect to Panel. Please Retry.</>;
  }
}

const AmountAvailableContext = React.createContext<number | null>(null); //total amount available

const KeypadBusAmountAvailableContext = React.createContext<number | null>(
  null
); //specific to XR keypad bus

export const AxDoorDevicesAmountAvailableContext = React.createContext<
  number | null
>(null); //specific to XR ax door devices

const AddButtonContext = React.createContext<React.ReactNode>(null);

function ProgrammingConceptFormAddButton(props: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}) {
  const [activeConcept] = React.useContext(ActiveConceptContext);
  const amountAvailable = React.useContext(AmountAvailableContext);
  const { searchValues, setSearchValues, setForceClear } = useSearchContext();
  return (
    <AddButton
      type="button"
      size="small"
      disabled={Number(amountAvailable) <= 0}
      onClick={(event) => {
        // Need to clear search as new record will not meet any existing search criteria
        if (searchValues.get(activeConcept) !== "") {
          setSearchValues(new Map(searchValues).set(activeConcept, ""));
          setForceClear(true);
        }
        props.onClick(event);
      }}
    >
      <AddIcon name="add" size="0.8em" />
      <AddButtonText> {props.children}</AddButtonText>
    </AddButton>
  );
}

function ProgrammingConceptForm(props: {
  conceptId: string;
  title: string;
  amountAvailable?: number;
  axDoorDevicesAmountAvailable?: number;
  addButton?: React.ReactNode;
  children: React.ReactNode;
  initialDataIsNotEmptyOrNull: boolean;
  isArrayConcept?: boolean;
  helpLink?: string;
  deleting?: boolean;
}) {
  const { isEditing } = useTemplateContext();
  return (
    <ProgrammingConceptIdContext.Provider value={props.conceptId}>
      <AmountAvailableContext.Provider value={props.amountAvailable ?? null}>
        <AxDoorDevicesAmountAvailableContext.Provider
          value={props.axDoorDevicesAmountAvailable ?? null}
        >
          <AddButtonContext.Provider value={props.addButton}>
            <RootContainer id={props.conceptId}>
              <ContentContainer>
                <MainContainer>
                  <HeaderLayout
                    title={props.title}
                    helpLink={props.helpLink}
                    buttons={props.addButton}
                  />
                  {isEditing && !props.isArrayConcept && (
                    <AllNoneButtons conceptId={props.conceptId} />
                  )}
                  <GenericErrorBoundary fallback={null}>
                    <ProgrammingConceptFormFields
                      conceptId={props.conceptId}
                      initialDataIsNotEmptyOrNull={
                        props.initialDataIsNotEmptyOrNull
                      }
                      isArrayConcept={props.isArrayConcept}
                    >
                      <ChangedProgrammingConceptContextProvider>
                        {props.children}
                      </ChangedProgrammingConceptContextProvider>
                    </ProgrammingConceptFormFields>
                  </GenericErrorBoundary>
                </MainContainer>
              </ContentContainer>
            </RootContainer>
          </AddButtonContext.Provider>
        </AxDoorDevicesAmountAvailableContext.Provider>
      </AmountAvailableContext.Provider>
    </ProgrammingConceptIdContext.Provider>
  );
}

function Fields(props: { children: React.ReactNode }) {
  return (
    <Table>
      <tbody>{props.children}</tbody>
    </Table>
  );
}

ProgrammingConceptForm.Fields = Fields;
ProgrammingConceptForm.AddButton = ProgrammingConceptFormAddButton;

function FieldLabel(props: {
  htmlFor?: string;
  tooltip?: string;
  indicator?: IndicatorState | null | undefined;
  children: React.ReactNode;
}) {
  return (
    <FieldLabelContainer>
      {props.tooltip ? (
        <Tooltip content={props.tooltip}>
          <ToolTipFieldLabelTextWrapper indicator={props.indicator}>
            <Tooltip.LabelText>{props.children}</Tooltip.LabelText>
          </ToolTipFieldLabelTextWrapper>
        </Tooltip>
      ) : (
        <FieldLabelText htmlFor={props.htmlFor} indicator={props.indicator}>
          {props.children}
        </FieldLabelText>
      )}
    </FieldLabelContainer>
  );
}

const EXCLUDED_FIELDS: Array<RegExp> = [
  new RegExp(`^${commPathAccountNumberFieldId()}$`),
  new RegExp(`^${communicationAccountNumberFieldId()}$`),
  new RegExp(`^${remoteOptionsRemoteKeyFieldId()}$`),
  new RegExp(`^${networkOptionsLocalIpAddressFieldId()}$`),
  new RegExp(`^${networkOptionsGatewayAddressFieldId()}$`),
  new RegExp(`^${networkOptionsSubnetMaskFieldId()}$`),
  new RegExp(`^${networkOptionsIpv6AddressFieldId()}$`),
  new RegExp(`^${networkOptionsIpv6GatewayFieldId()}$`),
  new RegExp(`^${networkOptionsIpv6PrefixBitsFieldId()}$`),
  new RegExp(`^${networkOptionsIpv6DnsServerFieldId()}$`),
  new RegExp(`^${KEYFOB_SERIAL_NUMBER}`),
  new RegExp(`^${KEYFOB_USER_NUMBER}`),
  new RegExp(`^${KEYFOB_USER_NAME}`),
  new RegExp(`^${OUTPUT_INFORMATION_SERIAL_NUMBER}`),
  new RegExp(`^${ZONE_INFORMATION_EXPANDER_SERIAL_NUMBER}`),
  new RegExp(`^${ZONE_INFORMATION_VPLEX_SERIAL_NUMBER}`),
  new RegExp(`^${ZONE_INFORMATION_SERIAL_NUMBER}`),
  new RegExp(`^${DEVICE_INFORMATION_SERIAL_NUMBER}`),
  new RegExp(`^${AREA_ACCOUNT_NUMBER}`),
  new RegExp(`^${ZONE_INFORMATION_SENSOR_TYPE_FIELD}`),
];

const INCLUDED_FIELDS: Array<RegExp> = [
  // Numbered Items have to be included here to be auto selected in templates
  new RegExp(`^${COMM_PATH_NUMBER}`),
  new RegExp(`^${DEVICE_INFORMATION_NUMBER}`),
  new RegExp(`^${OUTPUT_INFORMATION_NUMBER}`),
  new RegExp(`^${AREA_NUMBER}`),
  new RegExp(`^${ZONE_INFORMATION_NUMBER}`),
  new RegExp(`^${KEY_FOB_NUMBER}`),
  new RegExp(`^${XR_DEVICE_INFORMATION_NUMBER}`),
  new RegExp(`^${XF_DEVICE_INFORMATION_NUMBER}`),
  new RegExp(`^${XT75_DEVICE_INFORMATION_NUMBER}`),
  new RegExp(`^${OUTPUT_GROUP_NUMBER}`),
  new RegExp(`^${CARD_FORMAT_NUMBER}`),
];

function ProgrammingConceptFormField(props: {
  label: React.ReactNode;
  tooltip?: string;
  fieldId: string;
  children: React.ReactNode;
  disabled?: boolean;
}) {
  const { fieldId } = props;
  const listItemTemplateId = React.useContext(TemplateListItemIdContext);

  const [includedFields, setIncludedFields] = useIncludedTemplateFields();
  const { isEditing, hardwareModel, allFields } = useTemplateContext();
  const fieldIsAlwaysIncluded = INCLUDED_FIELDS.some((regex) =>
    regex.test(fieldId)
  );
  const fieldIsAlwaysExcluded = EXCLUDED_FIELDS.some((regex) =>
    regex.test(fieldId)
  );

  const listItemIsIncluded =
    !!listItemTemplateId && includedFields.has(listItemTemplateId);

  const isIncluded = listItemTemplateId
    ? (includedFields.has(fieldId) && listItemIsIncluded) ||
      (fieldIsAlwaysIncluded && listItemIsIncluded)
    : includedFields.has(fieldId) || fieldIsAlwaysIncluded;

  const isInvalid = useFieldIsInvalid(fieldId);
  const isChanged = useFieldIsChanged(fieldId);

  const { isXr, isXf } = resolvePanelType(hardwareModel);

  const disabled = props.disabled || (isEditing && !isIncluded);

  const allowsDns = isXr || isXf;

  const checkboxDisabled =
    fieldIsAlwaysExcluded ||
    fieldIsAlwaysIncluded ||
    (!allowsDns &&
      new RegExp(`^${networkOptionsDnsServerFieldId()}$`).test(fieldId));

  if (!fieldIsAlwaysIncluded && !fieldIsAlwaysExcluded) {
    allFields.add(fieldId);
  }

  return (
    <Row>
      {isEditing && (listItemIsIncluded || !listItemTemplateId) && (
        <FieldIncludedCell>
          <Checkbox
            className="checkbox c-checkbox needsclick"
            data-list-item-id={listItemTemplateId}
            data-field-id={fieldId}
          >
            <label className="needsclick" title="Include in template">
              <input
                disabled={checkboxDisabled}
                type="checkbox"
                checked={isIncluded}
                onChange={() => {
                  if (
                    isNotNullOrUndefined(listItemTemplateId) &&
                    listItemIsIncluded
                  ) {
                    setIncludedFields(setToggle(fieldId));
                  } else if (
                    isNotNullOrUndefined(listItemTemplateId) &&
                    !listItemIsIncluded
                  ) {
                    setIncludedFields(
                      flow(setDelete(fieldId), setDelete(listItemTemplateId))
                    );
                  } else {
                    setIncludedFields(setToggle(fieldId));
                    if (fieldId === networkOptionsUseIpv6FieldId())
                      //Have to include this field if the user is including the IPv6 field
                      setIncludedFields(
                        setToggle(networkOptionsIpv6PrefixBitsFieldId())
                      );
                  }
                }}
              />
              <span className="icon-checkmark"></span>
            </label>
          </Checkbox>
        </FieldIncludedCell>
      )}
      <FieldLabelCell disabled={disabled}>
        <FieldLabel
          htmlFor={fieldId}
          tooltip={props.tooltip}
          indicator={
            isEditing
              ? undefined
              : isInvalid
              ? "WARNING"
              : isChanged
              ? "INFO"
              : undefined
          }
        >
          {props.label}
        </FieldLabel>
      </FieldLabelCell>
      <FieldValueCell disabled={disabled}>{props.children}</FieldValueCell>
    </Row>
  );
}

ProgrammingConceptForm.Field = ProgrammingConceptFormField;

const ListItemsDropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--measure-2x);

  @media screen and (min-width: ${inlineListItemsBreakpoint}) {
    display: none;
    margin-bottom: 0;
  }
`;
const ListItemsDropdown = styled(Select)`
  margin-right: var(--measure-half);
`;
const ListItemsContainer = styled.div<{ withDivider?: boolean }>`
  ${({ withDivider }) =>
    withDivider &&
    css`
      margin-top: var(--measure-1x);
      padding-top: var(--measure-3x);
      border-top: 2px solid var(--color-neutral-400);

      @media screen and (min-width: ${inlineListItemsBreakpoint}) {
        margin-top: var(--measure-4x);
        padding-top: var(--measure-3x);
        border-top: 1px solid var(--color-neutral-400);
      }
    `};

  @media screen and (min-width: ${inlineListItemsBreakpoint}) {
    display: flex;
    align-items: flex-start;
  }
`;
const ListItems = styled(NakedList)`
  display: none;

  @media screen and (min-width: ${inlineListItemsBreakpoint}) {
    display: block;
    max-width: 37rem;
    width: 100%;
    position: sticky;
    top: 75px;

    & > :not(:last-child) {
      margin-bottom: var(--measure-half);
    }
  }
`;
const ItemsContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
`;
const ListItemTitleText = styled(NeutralText)<{
  indicator?: IndicatorState | null | undefined;
}>`
  display: inline-block;
  position: relative;
  padding-right: 1.5rem;
  font-size: var(--measure-font-14);
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Needed to keep the list items from pushing into programming field area*/

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    ${indicatorStyles};
  }
`;
const ListItemArrow = styled.span`
  flex-shrink: 0;
  margin-left: var(--measure-half);
  font-size: var(--measure-font-16);
  color: var(--color-neutral-500);
`;
const Li = styled.li`
  position: relative;
  margin: 0 !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-neutral-400);
  width: 100%;
`;
const ListItemButton = styled(NakedButton)<{
  selected?: boolean;
  includedInTemplate?: boolean;
}>`
  width: 100%;
  padding: var(--measure-12) 0 var(--measure-12) var(--measure-12);
  border-left: 4px solid
    ${({ selected }) => (selected ? "var(--color-info-900)" : "transparent")};
  opacity: 1;

  & ${ListItemArrow} {
    color: ${({ selected }) =>
      selected ? "var(--color-neutral-900)" : "var(--color-neutral-500)"};
  }

  &:hover {
    & ${ListItemArrow} {
      color: var(--color-neutral-900);
    }
  }

  &:focus {
    ${focusRing};
  }
`;
const SelectedItemsContainer = styled.div`
  flex: 1;
  min-width: 0;
`;
const SelectedItemContainer = styled.fieldset<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
  flex-basis: 100%;
  margin: 0;
  padding: 0;
  border: none;

  @media screen and (min-width: ${inlineListItemsBreakpoint}) {
    margin-left: var(--measure-2x);
  }
`;
const SelectedItemHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--measure-2x);
`;
const SelectedItemTitle = styled.h1`
  margin: 0;
  font-size: var(--measure-font-16);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${inlineListItemsBreakpoint}) {
    ${visuallyHidden};
  }
`;

export const cardFormatListItemTemplateId = (number: string) =>
  `card-format-${number}`;

export const CARD_FORMAT_IDS = [
  cardFormatListItemTemplateId,
  commPathAccountNumberFieldId,
  communicationAccountNumberFieldId,
  remoteOptionsRemoteKeyFieldId,
  networkOptionsLocalIpAddressFieldId,
  networkOptionsGatewayAddressFieldId,
  networkOptionsSubnetMaskFieldId,
  networkOptionsIpv6AddressFieldId,
  networkOptionsIpv6GatewayFieldId,
  networkOptionsIpv6PrefixBitsFieldId,
  networkOptionsIpv6DnsServerFieldId,
];

function ProgrammingConceptFormListItem(props: {
  id: string;
  templateListItemId: string;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  canIncludeInTemplate?: boolean;
}) {
  const { isEditing } = useTemplateContext();
  const [includedInTemplate, toggleIncludedInTemplate] =
    useIncludedTemplateField(props.templateListItemId);
  const { canIncludeInTemplate = true } = props;

  return isEditing && !canIncludeInTemplate ? null : (
    <ProgrammingConceptFormListItemIdContext.Provider value={props.id}>
      <TemplateListItemIdContext.Provider value={props.templateListItemId}>
        <Li>
          <ListItemButton
            selected={props.selected}
            includedInTemplate={isEditing ? includedInTemplate : true}
            onClick={props.onClick}
            type="button"
          >
            <Flex alignItems="center" as="span">
              {!!isEditing && (
                <Checkbox className="checkbox c-checkbox needsclick">
                  <label className="needsclick" title="Include in template">
                    <input
                      type="checkbox"
                      checked={includedInTemplate}
                      onChange={() => {
                        toggleIncludedInTemplate();
                      }}
                    />
                    <span className="icon-checkmark"></span>
                  </label>
                </Checkbox>
              )}
              {props.leftContent}
              <Flex
                alignItems="center"
                justifyContent="flex-end"
                as="span"
                style={{ marginLeft: "auto" }}
              >
                {props.rightContent}
                <ListItemArrow>
                  <Icon name="control_right" />
                </ListItemArrow>
              </Flex>
            </Flex>
          </ListItemButton>
        </Li>
      </TemplateListItemIdContext.Provider>
    </ProgrammingConceptFormListItemIdContext.Provider>
  );
}

function ProgrammingConceptFormSelectedItem(props: {
  conceptId: string;
  isnew: boolean;
  visible?: boolean;
  listItemId: string;
  templateListItemId: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  onDuplicate?: (() => void) | false;
  onCopy?: (() => void) | false;
  onPaste?: (() => void) | false;
  onRemove?: (() => void) | false;
  isRemoteProgramZone?: boolean;
  isCamera?: boolean;
}) {
  const setInvalidFields = useSetInvalidFields();
  const [activeConcept] = React.useContext(ActiveConceptContext);
  const programmingContextId = useProgrammingConceptIdContext();
  const { searchValues, toggleForceNav } = useSearchContext();
  const [removeListItemModalIsOpen, setRemoveListItemModalIsOpen] =
    React.useState(false);
  const { isEditing } = useTemplateContext();
  const [includedFields] = useIncludedTemplateFields();
  const listItemIsIncluded = includedFields.has(props.templateListItemId);

  const cleanUpErrorState = () => {
    //Cleans up error state for removed listItems so that the error indicator does not stay lit for items that are removed when in error
    setInvalidFields((invalidFields) => {
      const programmingContextInvalidItems =
        invalidFields.get(programmingContextId);
      if (programmingContextInvalidItems?.has(props.listItemId)) {
        programmingContextInvalidItems.delete(props.listItemId);
      }
      const newProgrammingContextInvalidItems =
        programmingContextInvalidItems ?? new Map();

      return invalidFields.set(
        programmingContextId,
        newProgrammingContextInvalidItems
      );
    });
  };
  const options = [];
  if (props.onDuplicate !== undefined) {
    options.push({
      message: "Duplicate",
      disabled: !props.onDuplicate,
      onClick: () => {
        if (props.onDuplicate) {
          props.onDuplicate();
        }
      },
    });
  }
  if (props.onCopy !== undefined) {
    options.push({
      message: "Copy",
      disabled: !props.onCopy,
      onClick: () => {
        if (props.onCopy) {
          props.onCopy();
        }
      },
    });
  }
  if (props.onPaste !== undefined) {
    options.push({
      message: "Paste",
      disabled: !props.onPaste,
      onClick: () => {
        if (props.onPaste) {
          props.onPaste();
        }
      },
    });
  }
  if (props.onRemove !== undefined) {
    options.push({
      message: "Remove",
      disabled: !props.onRemove,
      onClick: () => {
        setRemoveListItemModalIsOpen(true);
      },
    });
  }

  const container = React.useRef<HTMLFieldSetElement | null>(null);

  React.useLayoutEffect(() => {
    if (props.visible) {
      const node = container.current;
      if (node) {
        const topbarHeight =
          document.getElementById("js-topnavbar")?.offsetHeight ?? 0;
        const { top } = node.getBoundingClientRect();
        if (top - topbarHeight < 0) {
          window.scrollTo({ top: topbarHeight });
        }
      }
    }
  }, [props.visible]);

  return (
    <IsNewContext.Provider value={props.isnew}>
      <SelectedItemContainer
        visible={props.visible}
        id={props.listItemId}
        ref={container}
      >
        <SelectedItemHeader>
          {props.title ? (
            <Legend>
              <SelectedItemTitle>{props.title}</SelectedItemTitle>
            </Legend>
          ) : (
            <div />
          )}
          {!!options.length && <MoreButton items={options} />}
        </SelectedItemHeader>
        {isEditing && listItemIsIncluded && (
          <AllNoneButtons
            conceptId={props.conceptId}
            listItemTemplateId={props.templateListItemId}
          />
        )}
        <ProgrammingConceptFormListItemIdContext.Provider
          value={props.listItemId}
        >
          <TemplateListItemIdContext.Provider value={props.templateListItemId}>
            <ChangedProgrammingConceptContextProvider>
              {removeListItemModalIsOpen && (
                <ConfirmActionModal
                  onConfirm={() => {
                    setRemoveListItemModalIsOpen(false);
                    if (props.onRemove) {
                      if (searchValues.get(activeConcept) !== "") {
                        // Toggle forceNav to navigate to the top record after delete.
                        toggleForceNav();
                      }
                      props.onRemove();
                      cleanUpErrorState();
                    }
                  }}
                  onCancel={() => setRemoveListItemModalIsOpen(false)}
                  confirmButtonTitle={
                    props.isRemoteProgramZone || props.isCamera
                      ? "Force Remove"
                      : "Remove"
                  }
                  cancelButtonTitle="Cancel"
                  title="Remove Item"
                  makeConfirmButtonRed={
                    props.isRemoteProgramZone || props.isCamera
                  }
                  message={
                    props.isRemoteProgramZone
                      ? `Are you sure you want to delete this zone? This zone is associated with an AlarmVision detection region. If this zone is deleted, the camera's detection region will not properly communicate with the panel. If possible, remove this zone by deleting the AlarmVision detection region.`
                      : props.isCamera
                      ? `Are you sure you want to delete this device? This device is associated to an XV Camera. If this device is deleted, the camera will not properly communicate with the panel. If possible, remove this device in the XV Camera settings.`
                      : "This will delete the selected item. Are you sure?"
                  }
                />
              )}
              {props.children}
            </ChangedProgrammingConceptContextProvider>
          </TemplateListItemIdContext.Provider>
        </ProgrammingConceptFormListItemIdContext.Provider>
      </SelectedItemContainer>
    </IsNewContext.Provider>
  );
}

function ListItemsDropdownOption(props: {
  label: React.ReactNode;
  listItemId: string;
}) {
  return <option value={props.listItemId}>{props.label}</option>;
}

function ListItemTitle(
  props: Omit<React.ComponentProps<typeof ListItemTitleText>, "indicator"> & {
    isnew?: boolean;
  }
) {
  const listItemId = useProgrammingConceptFormListItemIdContext() as string;
  const hasInvalidFields = useListItemHasInvalidFields(listItemId);
  const hasChanges = useListItemHasChanged(listItemId);
  const { isEditing } = useTemplateContext();

  return (
    <ListItemTitleText
      {...props}
      indicator={
        isEditing
          ? null
          : hasInvalidFields
          ? "WARNING"
          : hasChanges || props.isnew
          ? "INFO"
          : undefined
      }
    />
  );
}

function ProgrammingConceptFormSelectedItemsContainer(props: {
  selectedListItemId: string | null;
  setSelectedListItemId:
    | React.Dispatch<React.SetStateAction<string | null>>
    | React.Dispatch<React.SetStateAction<string>>;
  children?: React.ReactNode;
}) {
  const { selectedListItemId, setSelectedListItemId } = props;
  return (
    <SelectedItemsContainer>
      <SelectedListItemContext.Provider
        value={React.useMemo(
          () => [selectedListItemId, setSelectedListItemId],
          [selectedListItemId, setSelectedListItemId]
        )}
      >
        {props.children}
      </SelectedListItemContext.Provider>
    </SelectedItemsContainer>
  );
}

export function ProgrammingConceptFormListItemPicker(props: {
  selectedId: string;
  items: {
    id: string;
    templateListItemId: string;
    label: React.ReactNode;
    isnew: boolean;
    rightContent?: React.ReactNode;
  }[];
  newItemId?: string | null | undefined;
  onChange: (id: string) => void;
  emptyMessage?: React.ReactNode;
}) {
  const { selectedId, items, onChange } = props;
  const emptyMessage = props.emptyMessage ?? "No items";
  const [activeConcept] = React.useContext(ActiveConceptContext);
  const { searchValues, setSearchValues, forceClear, setForceClear, forceNav } =
    useSearchContext();
  const searchResults = React.useMemo(() => {
    const searchValue = searchValues.get(activeConcept);

    if (searchValue) {
      return items.filter((item) => {
        const searchTarget = item.label?.toString().toLowerCase() ?? "";
        return searchTarget.includes(searchValue.toLowerCase());
      });
    } else {
      return items;
    }
  }, [searchValues, items]);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValues(
      new Map(searchValues).set(activeConcept, event.target.value)
    );
  };

  React.useEffect(() => {
    // If search was forcefully cleared due to adding, we need to reset that flag and not navigate to the first record
    // as it will already be on the new record. Otherwise, when searchValue updates, we navigate to the first record.
    if (forceClear) {
      setForceClear(false);
    } else {
      onChange(searchResults[0]?.id ?? null);
    }
  }, [searchValues, forceNav]);
  // forceNav's value is irrelevant. It's toggled to get into useEffect to navigate to first record when a record is deleted.

  return (
    <>
      <ListItemsDropdownContainer>
        <ListItemsDropdown
          value={selectedId}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        >
          {items.length ? (
            items.map((item) => (
              <ListItemsDropdownOption
                key={item.id}
                label={item.label}
                listItemId={item.id}
              />
            ))
          ) : (
            <option value="">{emptyMessage}</option>
          )}
        </ListItemsDropdown>
        {React.useContext(AddButtonContext)}
      </ListItemsDropdownContainer>
      <ProgrammingConceptForm.ListItems>
        {!!items.length && (
          <GenericTextInput
            placeholder="Search..."
            value={searchValues.get(activeConcept) ?? ""}
            onChange={handleSearch}
          />
        )}
        {searchResults.length ? (
          <ItemsContainer>
            {searchResults.map((item) => (
              <ProgrammingConceptForm.ListItem
                key={item.id}
                id={item.id}
                templateListItemId={item.templateListItemId}
                selected={selectedId === item.id}
                onClick={() => {
                  onChange(item.id);
                }}
                leftContent={
                  <ProgrammingConceptForm.ListItemTitle isnew={item.isnew}>
                    {item.label}
                  </ProgrammingConceptForm.ListItemTitle>
                }
                rightContent={item.rightContent}
              />
            ))}
          </ItemsContainer>
        ) : (
          <EmptyMessage>
            <NeutralText>{emptyMessage}</NeutralText>
          </EmptyMessage>
        )}
      </ProgrammingConceptForm.ListItems>
    </>
  );
}

ProgrammingConceptForm.ListItem = ProgrammingConceptFormListItem;
ProgrammingConceptForm.ListItems = ListItems;
ProgrammingConceptForm.ListItemsContainer = ListItemsContainer;
ProgrammingConceptForm.ListItemTitle = ListItemTitle;
ProgrammingConceptForm.ListItemPicker = ProgrammingConceptFormListItemPicker;
ProgrammingConceptForm.SelectedItemsContainer =
  ProgrammingConceptFormSelectedItemsContainer;
ProgrammingConceptForm.SelectedItem = ProgrammingConceptFormSelectedItem;

export default ProgrammingConceptForm;

export const ProgrammingConceptFormFallback = React.memo(
  (props: { conceptId: string; title: string; helpLink?: string }) => (
    <Panel>
      <ContentContainer>
        <MainContainer>
          <HeaderLayout title={props.title} helpLink={props.helpLink} />
          <GenericPageFallback />
        </MainContainer>
      </ContentContainer>
    </Panel>
  )
);
