import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const AreaContext = React.createContext<any>({});

export function AreaContextProvider(props: {
  area: any;
  children: React.ReactNode;
}) {
  return (
    <AreaContext.Provider value={props.area}>
      {props.children}
    </AreaContext.Provider>
  );
}

export function useAreaFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const areaContext = React.useContext(AreaContext);

  const data = useFragment(fragmentInput, areaContext as TKey);

  const area = useFragment(
    graphql`
      fragment AreaContext_area on Area {
        id
        ... on XrArea {
          id
        }
      }
    `,
    areaContext as any
  );

  const update = (updater: (area: RecordProxy<KeyTypeData<TKey>>) => void) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(area.id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
