import loadScriptConditionally from "common/utils/loadScriptConditionally";
import { getBarCodeUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import Button from "components/Button";
import { GenericConfirmModal } from "components/Modal/ConfirmModal";
import React, { useEffect, useState } from "react";
declare global {
  interface Window {
    mountBarcodeScanner: (id: string, props: any) => void;
    unmountBarcodeScanner: () => void;
  }
}

const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;
const baseUrl = getBarCodeUrl(secureComEnv);
const barCodeUrl = `${baseUrl}/barcode-scanner.js`;

type Props = {
  updateZoneInformation?: any;
  updateDeviceInformation?: any;
  updateOutputInformation?: any;
  wireless?: boolean;
};

const BarCodeScanner = ({
  updateZoneInformation,
  updateDeviceInformation,
  updateOutputInformation,
  wireless,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [barCode, setBarcode] = useState<string>("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loadScript, setLoadScript] = React.useState(false);

  useEffect(() => {
    loadScriptConditionally({
      callback: () => {
        setLoadScript(true);
      },
      src: barCodeUrl,
      id: "barcodeScanner",
    });
  }, []);

  useEffect(() => {
    if (!barCode) return;

    setIsOpen(false);

    //This Callback will set the value in the target field based on the update function being passed in from the props
    const recordProxyCallback = (recordProxy: {
      setValue: (arg0: string, arg1: string) => void;
    }) => {
      const targetField = getTargetField();

      if (targetField) {
        recordProxy.setValue(barCode, targetField);
      }
    };

    const getTargetField = () => {
      const targetFieldMap = {
        updateZoneInformation: "expanderSerialNumber",
        updateDeviceInformation: "serialNumber",
        updateOutputInformation: "serialNumber",
        updateWirelessInformation: "serialNumber",
      };

      const activeUpdater = getActiveUpdater();

      return activeUpdater ? targetFieldMap[activeUpdater] : null;
    };

    const getActiveUpdater = () => {
      if (wireless && updateZoneInformation) return "updateWirelessInformation";
      if (updateZoneInformation) return "updateZoneInformation";
      if (updateDeviceInformation) return "updateDeviceInformation";
      if (updateOutputInformation) return "updateOutputInformation";
      return null;
    };

    const activeUpdater = getActiveUpdater();

    if (activeUpdater) {
      switch (activeUpdater) {
        case "updateWirelessInformation":
        case "updateZoneInformation":
          updateZoneInformation(recordProxyCallback);
          break;
        case "updateDeviceInformation":
          updateDeviceInformation(recordProxyCallback);
          break;
        case "updateOutputInformation":
          updateOutputInformation(recordProxyCallback);
          break;
      }
    } else {
      if (barCode.length === 8) {
        const serialElement = document.getElementsByName(
          "panels_serial_number"
        )[1] as HTMLInputElement;
        serialElement.value = barCode;
      }
      if (barCode.length === 20) {
        const simElement = document.getElementsByName(
          "panels_sim_identifier"
        )[1] as HTMLInputElement;
        simElement.value = barCode;
      }
    }
  }, [
    barCode,
    updateDeviceInformation,
    updateOutputInformation,
    updateZoneInformation,
    wireless,
  ]);

  useEffect(() => {
    try {
      window.mountBarcodeScanner("barcode-scanner", {
        setBarcode,
      });
    } catch (error) {
      console.error(error);
    }
    return () => {
      if (window.unmountBarcodeScanner) {
        window.unmountBarcodeScanner();
      }
    };
  }, [isOpen]);

  return loadScript ? (
    <>
      {windowWidth <= 800 || window.navigator.userAgent.includes("iPad") ? (
        <>
          <label className="control-label col-sm-3"></label>
          <Button
            id="barCodeScanner"
            type="button"
            onClick={() => setIsOpen(true)}
            className={"btn btn-sm btn-dmp"}
            style={{ border: "1px solid #49b6d7", width: "100%" }}
          >
            Scan Barcode
          </Button>
          {isOpen ? (
            <GenericConfirmModal
              header="Barcode Scanner"
              onCancel={() => setIsOpen(false)}
              suppressConfirm={true}
              cancelText={"Cancel"}
            >
              <div id="barcode-scanner"></div>
            </GenericConfirmModal>
          ) : null}
        </>
      ) : null}
    </>
  ) : null;
};

export default BarCodeScanner;
