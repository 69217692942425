import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsXrX1CommunicationField_networkOptions$key } from "./__generated__/NetworkOptionsXrX1CommunicationField_networkOptions.graphql";

export const networkOptionsXrX1CommunicationFieldId = () =>
  "network-options-xrx1-communication";

function NetworkOptionsXrX1CommunicationField() {
  const [{ enableDdp }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsXrX1CommunicationField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsXrX1CommunicationField_networkOptions on NetworkOptions {
          enableDdp
        }
      `
    );

  const fieldId = networkOptionsXrX1CommunicationFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="XR/X1 Communication"
      tooltip="Enable to allow this XR to communicate to other XRs or X1s on the same site. Must be enabled on all XRs or X1s with a matching passphrase for proper connection."
    >
      <Switch
        label="XR/X1 Communication"
        id={fieldId}
        checked={enableDdp}
        onChange={() => {
          updateNetworkOptions((recordProxy) => {
            const enabled = !enableDdp;
            recordProxy.setValue(enabled, "enableDdp");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsXrX1CommunicationField;
