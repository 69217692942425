/**
 * @generated SignedSource<<32db6d84093ba50468133135d1285f34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type CommPathFailTimeField_communicationPath$data = {
  readonly checkInMinutes: number;
  readonly commType: CommunicationPathCommType;
  readonly failTime: number;
  readonly failTimeMax: number;
  readonly failTimeMin: number;
  readonly number: number;
  readonly useCheckIn: CommunicationPathUseCheckIn;
  readonly " $fragmentType": "CommPathFailTimeField_communicationPath";
};
export type CommPathFailTimeField_communicationPath$key = {
  readonly " $data"?: CommPathFailTimeField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathFailTimeField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathFailTimeField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTimeMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTimeMax",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "618493d9ee8422e968fcaf9d3ee7022b";

export default node;
