/**
 * @generated SignedSource<<135fda8703406e43ed4df557477c09ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecureComNvrs_site$data = {
  readonly billingControlSystemId: string;
  readonly customer: {
    readonly id: string;
  };
  readonly id: string;
  readonly securecomNvrs: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SecureComNvrs_secureComNvr">;
  }>;
  readonly " $fragmentType": "SecureComNvrs_site";
};
export type SecureComNvrs_site$key = {
  readonly " $data"?: SecureComNvrs_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComNvrs_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecureComNvrs_site",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingControlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SecureComNvr",
      "kind": "LinkedField",
      "name": "securecomNvrs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecureComNvrs_secureComNvr"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "a4d9624aadcec0d8a047bc2300564834";

export default node;
