/**
 * @generated SignedSource<<e292e203509d0ec8482ca2d321a20dbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemReportsContext_systemReports$data = {
  readonly id: string;
  readonly " $fragmentType": "SystemReportsContext_systemReports";
};
export type SystemReportsContext_systemReports$key = {
  readonly " $data"?: SystemReportsContext_systemReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemReportsContext_systemReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemReportsContext_systemReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SystemReports",
  "abstractKey": null
};

(node as any).hash = "de3f08f271371e07de8b24e94d5b9365";

export default node;
