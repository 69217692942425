import { isNil, pathEq } from "ramda";

export const isFixed = pathEq(["style", "position"], "fixed");

export function getElementOffsetTop(
  element: HTMLElement,
  offsetTop = 0
): number {
  if (
    isNil(element.offsetParent) ||
    !(element.offsetParent instanceof HTMLElement)
  ) {
    return offsetTop;
  }

  return isFixed(element.offsetParent)
    ? offsetTop + element.getBoundingClientRect().top
    : getElementOffsetTop(
        element.offsetParent,
        !isNaN(element.offsetTop)
          ? offsetTop + element.offsetTop - element.scrollTop
          : offsetTop
      );
}
