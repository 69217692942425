import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { range } from "ramda";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationStrikeDelayField_deviceInformation$key } from "./__generated__/DeviceInformationStrikeDelayField_deviceInformation.graphql";

export const deviceInformationStrikeDelayFieldId = (number: string) =>
  `device-information-strike-delay-${number}`;

function DeviceInformationStrikeDelayField() {
  const [{ number, strikeDelay, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationStrikeDelayField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationStrikeDelayField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            strikeDelay
            deviceType
          }
          ... on Xt75DeviceInformation {
            strikeDelay
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationStrikeDelayFieldId(String(number));
  const softwareVersion = useSoftwareVersion();
  const disabled = deviceType !== DeviceInformationType.DOOR;
  const validStrikeDelayRange =
    softwareVersion > 107 ? range(0, 10).concat(11) : range(0, 10);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Strike Delay"
      tooltip="Number of minutes to delay a door strike after a valid code or card read."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={strikeDelay ?? 5}
        required
        disabled={disabled}
        inlineHelp={softwareVersion > 107 ? "0-9,11" : "0-9"}
        validationMessage={
          softwareVersion > 107
            ? "Valid values are 0-9, 11."
            : "Valid values are 0-9."
        }
        pattern={softwareVersion > 107 ? "([0-9]?|11)" : "([0-9]?)"}
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(target.value, "strikeDelay");
          });
        }}
        onBlur={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              closest(Number(target.value), validStrikeDelayRange),
              "strikeDelay"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationStrikeDelayField;
