import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { remoteOptionsAlarmReceiverAuthorizationFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAlarmReceiverAuthorizationField";
import { remoteOptionsAllowCellularRemoteProgrammingFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAllowCellularRemoteProgrammingField";
import { remoteOptionsAllowNetworkRemoteFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAllowNetworkRemoteField";
import { remoteOptionsApn2FieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsApn2Field";
import { remoteOptionsApnFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsApnField";
import { remoteOptionsAppKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { remoteOptionsArmedAnswerRingsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsArmedAnswerRingsField";
import { remoteOptionsDisarmedAnswerRingsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsDisarmedAnswerRingsField";
import { remoteOptionsEntreArmDisarmReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreArmDisarmReportsField";
import { remoteOptionsEntreBackupConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupConnectionField";
import { remoteOptionsEntreBackupIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupIpAddress";
import { remoteOptionsEntreBackupIpv6AddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupIpv6AddressField";
import { remoteOptionsEntreBackupTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupTcpPortField";
import { remoteOptionsEntreCheckInMinutesFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreCheckInMinutesField";
import { remoteOptionsEntreConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreConnectionField";
import { remoteOptionsEntreDoorReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreDoorReportsField";
import { remoteOptionsEntreIncomingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIncomingTcpPortField";
import { remoteOptionsEntreIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIpAddressField";
import { remoteOptionsEntreIpv6AddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIpv6AddressField";
import { remoteOptionsEntreIpv6FieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIpv6Field";
import { remoteOptionsEntreOutgoingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreOutgoingTcpPortField";
import { remoteOptionsEntrePassphraseFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntrePassphraseField";
import { remoteOptionsEntreSupervisoryReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreSupervisoryReportsField";
import { remoteOptionsEntreUserCommandReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreUserCommandReportsField";
import { remoteOptionsEntreVideoReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreVideoReportsField";
import { remoteOptionsEntreZoneReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreZoneReportsField";
import { remoteOptionsIntegratorArmDisarmReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorArmDisarmReportsField";
import { remoteOptionsIntegratorBackupConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupConnectionField";
import { remoteOptionsIntegratorBackupIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupIpAddressField";
import { remoteOptionsIntegratorBackupTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupTcpPortField";
import { remoteOptionsIntegratorCheckInMinutesFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorCheckInMinutesField";
import { remoteOptionsIntegratorConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorConnectionField";
import { remoteOptionsIntegratorDoorReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorDoorReportsField";
import { remoteOptionsIntegratorIncomingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorIncomingTcpPortField";
import { remoteOptionsIntegratorIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorIpAddressField";
import { remoteOptionsIntegratorOutgoingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorOutgoingTcpPortField";
import { remoteOptionsIntegratorPassphraseFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorPassphraseField";
import { remoteOptionsIntegratorSupervisoryReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorSupervisoryReportsField";
import { remoteOptionsIntegratorUserCommandReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorUserCommandReportsField";
import { remoteOptionsIntegratorVideoReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorVideoReportsField";
import { remoteOptionsIntegratorZoneReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorZoneReportsField";
import { remoteOptionsNetworkProgrammingPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsNetworkProgrammingPortField";
import { remoteOptionsPcModemFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsPcModemField";
import { remoteOptionsRemoteChangeIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteChangeIpAddressField";
import { remoteOptionsRemoteChangePortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteChangePortField";
import { remoteOptionsRemoteDisarmFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import { remoteOptionsRemoteKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteKeyField";
import { remoteOptionsRemotePhoneNumberFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemotePhoneNumberField";
import { remoteOptionsSendLocalChangesFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsSendLocalChangesField";
import { remoteOptionsServiceReceiverAuthorizationFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsServiceReceiverAuthorizationField";
import { XRRemoteOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRRemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getRemoteOptionsState } from "../../../XRFullProgramming/XRRemoteOptionsProgrammingConceptForm";
import { XrProgrammingTemplateRemoteOptionsInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRRemoteOptionsTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRRemoteOptionsTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRRemoteOptionsTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRRemoteOptionsTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          id
          included
          remoteKey {
            data
            included
          }
          remoteDisarm {
            data
            included
          }
          appKey {
            data
            included
          }
          armedAnswerRings {
            data
            included
          }
          disarmedAnswerRings {
            data
            included
          }
          alarmReceiverAuthorization {
            data
            included
          }
          serviceReceiverAuthorization {
            data
            included
          }
          pcModem {
            data
            included
          }
          allowNetworkRemote {
            data
            included
          }
          networkProgrammingPort {
            data
            included
          }
          allowCellularRemoteProgramming {
            data
            included
          }
          firstApn {
            data
            included
          }
          secondApn {
            data
            included
          }
          sendLocalChanges {
            data
            included
          }
          remoteChangesIpAddress {
            data
            included
          }
          remoteChangesPort {
            data
            included
          }
          remoteChangesPhoneNumber {
            data
            included
          }
          entreConnection {
            data
            included
          }
          entreIncomingPort {
            data
            included
          }
          entreIpAddress {
            data
            included
          }
          entreOutgoingPort {
            data
            included
          }
          entreIpv6 {
            data
            included
          }
          entreIpv6Address {
            data
            included
          }
          entreBackupConnection {
            data
            included
          }
          entreBackupIpAddress {
            data
            included
          }
          entreBackupIncomingPort {
            data
            included
          }
          entreBackupIpv6Address {
            data
            included
          }
          entreCheckInMinutes {
            data
            included
          }
          entreArmDisarmReports {
            data
            included
          }
          entreZoneReports {
            data
            included
          }
          entreUserCommandReports {
            data
            included
          }
          entreDoorReports {
            data
            included
          }
          entreSupervisoryReports {
            data
            included
          }
          validEntreCheckinMinutes {
            data
            included
          }
          entrePassphrase {
            data
            included
          }
          entreVideoReports {
            data
            included
          }
          integratorConnection {
            data
            included
          }
          integratorIncomingPort {
            data
            included
          }
          integratorIpAddress {
            data
            included
          }
          integratorOutgoingPort {
            data
            included
          }
          integratorBackupConnection {
            data
            included
          }
          integratorBackupIpAddress {
            data
            included
          }
          integratorBackupIncomingPort {
            data
            included
          }
          integratorArmDisarmReports {
            data
            included
          }
          integratorZoneReports {
            data
            included
          }
          integratorUserCommandReports {
            data
            included
          }
          integratorDoorReports {
            data
            included
          }
          integratorSupervisoryReports {
            data
            included
          }
          integratorCheckInMinutes {
            data
            included
          }
          integratorPassphrase {
            data
            included
          }
          integratorVideoReports {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRRemoteOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateRemoteOptionsInput => {
  const {
    panel: { remoteOptions },
  } = getRemoteOptionsState(controlSystem);

  if (!remoteOptions) {
    return { included: false };
  }

  const remoteOptionsInputFields =
    remoteOptions?.__typename === "XrRemoteOptions"
      ? {
          remoteKey: {
            data: remoteOptions.remoteKey,
            included: includedFields.has(remoteOptionsRemoteKeyFieldId()),
          },
          remoteDisarm: {
            data: remoteOptions.remoteDisarm,
            included: includedFields.has(remoteOptionsRemoteDisarmFieldId()),
          },
          appKey: {
            data: remoteOptions.appKey,
            included: includedFields.has(remoteOptionsAppKeyFieldId()),
          },
          armedAnswerRings: {
            data: remoteOptions.armedAnswerRings,
            included: includedFields.has(
              remoteOptionsArmedAnswerRingsFieldId()
            ),
          },
          disarmedAnswerRings: {
            data: remoteOptions.disarmedAnswerRings,
            included: includedFields.has(
              remoteOptionsDisarmedAnswerRingsFieldId()
            ),
          },
          alarmReceiverAuthorization: {
            data: remoteOptions.alarmReceiverAuthorization,
            included: includedFields.has(
              remoteOptionsAlarmReceiverAuthorizationFieldId()
            ),
          },
          serviceReceiverAuthorization: {
            data: remoteOptions.serviceReceiverAuthorization,
            included: includedFields.has(
              remoteOptionsServiceReceiverAuthorizationFieldId()
            ),
          },
          pcModem: {
            data: remoteOptions.pcModem,
            included: includedFields.has(remoteOptionsPcModemFieldId()),
          },
          allowNetworkRemote: {
            data: remoteOptions.allowNetworkRemote,
            included: includedFields.has(
              remoteOptionsAllowNetworkRemoteFieldId()
            ),
          },
          networkProgrammingPort: {
            data: remoteOptions.networkProgrammingPort,
            included: includedFields.has(
              remoteOptionsNetworkProgrammingPortFieldId()
            ),
          },
          allowCellularRemoteProgramming: {
            data: remoteOptions.allowCellularRemoteProgramming,
            included: includedFields.has(
              remoteOptionsAllowCellularRemoteProgrammingFieldId()
            ),
          },
          firstApn: {
            data: remoteOptions.firstApn,
            included: includedFields.has(remoteOptionsApnFieldId()),
          },
          secondApn: {
            data: remoteOptions.secondApn,
            included: includedFields.has(remoteOptionsApn2FieldId()),
          },
          sendLocalChanges: {
            data: remoteOptions.sendLocalChanges,
            included: includedFields.has(
              remoteOptionsSendLocalChangesFieldId()
            ),
          },
          remoteChangesIpAddress: {
            data: remoteOptions.remoteChangesIpAddress,
            included: includedFields.has(
              remoteOptionsRemoteChangeIpAddressFieldId()
            ),
          },
          remoteChangesPort: {
            data: remoteOptions.remoteChangesPort,
            included: includedFields.has(
              remoteOptionsRemoteChangePortFieldId()
            ),
          },
          remoteChangesPhoneNumber: {
            data: remoteOptions.remoteChangesPhoneNumber,
            included: includedFields.has(
              remoteOptionsRemotePhoneNumberFieldId()
            ),
          },
          entreConnection: {
            data: remoteOptions.entreConnection,
            included: includedFields.has(remoteOptionsEntreConnectionFieldId()),
          },
          entreIncomingPort: {
            data: remoteOptions.entreIncomingPort,
            included: includedFields.has(
              remoteOptionsEntreIncomingTcpPortFieldId()
            ),
          },
          entreIpAddress: {
            data: remoteOptions.entreIpAddress,
            included: includedFields.has(remoteOptionsEntreIpAddressFieldId()),
          },
          entreOutgoingPort: {
            data: remoteOptions.entreOutgoingPort,
            included: includedFields.has(
              remoteOptionsEntreOutgoingTcpPortFieldId()
            ),
          },
          entreIpv6: {
            data: remoteOptions.entreIpv6,
            included: includedFields.has(remoteOptionsEntreIpv6FieldId()),
          },
          entreIpv6Address: {
            data: remoteOptions.entreIpv6Address,
            included: includedFields.has(
              remoteOptionsEntreIpv6AddressFieldId()
            ),
          },
          entreBackupConnection: {
            data: remoteOptions.entreBackupConnection,
            included: includedFields.has(
              remoteOptionsEntreBackupConnectionFieldId()
            ),
          },
          entreBackupIpAddress: {
            data: remoteOptions.entreBackupIpAddress,
            included: includedFields.has(
              remoteOptionsEntreBackupIpAddressFieldId()
            ),
          },
          entreBackupIncomingPort: {
            data: remoteOptions.entreBackupIncomingPort,
            included: includedFields.has(
              remoteOptionsEntreBackupTcpPortFieldId()
            ),
          },
          entreBackupIpv6Address: {
            data: remoteOptions.entreBackupIpv6Address,
            included: includedFields.has(
              remoteOptionsEntreBackupIpAddressFieldId()
            ),
          },
          entreCheckInMinutes: {
            data: remoteOptions.entreCheckInMinutes,
            included: includedFields.has(
              remoteOptionsEntreCheckInMinutesFieldId()
            ),
          },
          entreArmDisarmReports: {
            data: remoteOptions.entreArmDisarmReports,
            included: includedFields.has(
              remoteOptionsEntreArmDisarmReportsFieldId()
            ),
          },
          entreZoneReports: {
            data: remoteOptions.entreZoneReports,
            included: includedFields.has(
              remoteOptionsEntreZoneReportsFieldId()
            ),
          },
          entreUserCommandReports: {
            data: remoteOptions.entreUserCommandReports,
            included: includedFields.has(
              remoteOptionsEntreUserCommandReportsFieldId()
            ),
          },
          entreDoorReports: {
            data: remoteOptions.entreDoorReports,
            included: includedFields.has(
              remoteOptionsEntreDoorReportsFieldId()
            ),
          },
          entreSupervisoryReports: {
            data: remoteOptions.entreSupervisoryReports,
            included: includedFields.has(
              remoteOptionsEntreSupervisoryReportsFieldId()
            ),
          },
          entrePassphrase: {
            data: remoteOptions.entrePassphrase,
            included: includedFields.has(remoteOptionsEntrePassphraseFieldId()),
          },
          entreVideoReports: {
            data: remoteOptions.entreVideoReports,
            included: includedFields.has(
              remoteOptionsEntreVideoReportsFieldId()
            ),
          },
          integratorConnection: {
            data: remoteOptions.integratorConnection,
            included: includedFields.has(
              remoteOptionsIntegratorConnectionFieldId()
            ),
          },
          integratorIncomingPort: {
            data: remoteOptions.integratorIncomingPort,
            included: includedFields.has(
              remoteOptionsIntegratorIncomingTcpPortFieldId()
            ),
          },
          integratorIpAddress: {
            data: remoteOptions.integratorIpAddress,
            included: includedFields.has(
              remoteOptionsIntegratorIpAddressFieldId()
            ),
          },
          integratorOutgoingPort: {
            data: remoteOptions.integratorOutgoingPort,
            included: includedFields.has(
              remoteOptionsIntegratorOutgoingTcpPortFieldId()
            ),
          },
          integratorBackupConnection: {
            data: remoteOptions.integratorBackupConnection,
            included: includedFields.has(
              remoteOptionsIntegratorBackupConnectionFieldId()
            ),
          },
          integratorBackupIpAddress: {
            data: remoteOptions.integratorBackupIpAddress,
            included: includedFields.has(
              remoteOptionsIntegratorBackupIpAddressFieldId()
            ),
          },
          integratorBackupIncomingPort: {
            data: remoteOptions.integratorBackupIncomingPort,
            included: includedFields.has(
              remoteOptionsIntegratorBackupTcpPortFieldId()
            ),
          },
          integratorArmDisarmReports: {
            data: remoteOptions.integratorArmDisarmReports,
            included: includedFields.has(
              remoteOptionsIntegratorArmDisarmReportsFieldId()
            ),
          },
          integratorZoneReports: {
            data: remoteOptions.integratorZoneReports,
            included: includedFields.has(
              remoteOptionsIntegratorZoneReportsFieldId()
            ),
          },
          integratorUserCommandReports: {
            data: remoteOptions.integratorUserCommandReports,
            included: includedFields.has(
              remoteOptionsIntegratorUserCommandReportsFieldId()
            ),
          },
          integratorDoorReports: {
            data: remoteOptions.integratorDoorReports,
            included: includedFields.has(
              remoteOptionsIntegratorDoorReportsFieldId()
            ),
          },
          integratorSupervisoryReports: {
            data: remoteOptions.integratorSupervisoryReports,
            included: includedFields.has(
              remoteOptionsIntegratorSupervisoryReportsFieldId()
            ),
          },
          integratorCheckInMinutes: {
            data: remoteOptions.integratorCheckInMinutes,
            included: includedFields.has(
              remoteOptionsIntegratorCheckInMinutesFieldId()
            ),
          },
          integratorPassphrase: {
            data: remoteOptions.integratorPassphrase,
            included: includedFields.has(
              remoteOptionsIntegratorPassphraseFieldId()
            ),
          },
          integratorVideoReports: {
            data: remoteOptions.integratorVideoReports,
            included: includedFields.has(
              remoteOptionsIntegratorVideoReportsFieldId()
            ),
          },
        }
      : {};

  const remoteOptionsIncluded = Object.values(remoteOptionsInputFields).some(
    (field) => field?.included
  );

  return { included: remoteOptionsIncluded, ...remoteOptionsInputFields };
};

export const setIncludedFields = (
  template: XRRemoteOptionsTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { remoteOptions } = getState(template);

  if (!remoteOptions) return;

  if (remoteOptions.remoteKey?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteKeyFieldId()));
  }
  if (remoteOptions.remoteDisarm?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteDisarmFieldId()));
  }
  if (remoteOptions.appKey?.included) {
    setIncludedFields(setToggle(remoteOptionsAppKeyFieldId()));
  }
  if (remoteOptions.armedAnswerRings?.included) {
    setIncludedFields(setToggle(remoteOptionsArmedAnswerRingsFieldId()));
  }
  if (remoteOptions.disarmedAnswerRings?.included) {
    setIncludedFields(setToggle(remoteOptionsDisarmedAnswerRingsFieldId()));
  }
  if (remoteOptions.alarmReceiverAuthorization?.included) {
    setIncludedFields(
      setToggle(remoteOptionsAlarmReceiverAuthorizationFieldId())
    );
  }
  if (remoteOptions.serviceReceiverAuthorization?.included) {
    setIncludedFields(
      setToggle(remoteOptionsServiceReceiverAuthorizationFieldId())
    );
  }
  if (remoteOptions.pcModem?.included) {
    setIncludedFields(setToggle(remoteOptionsPcModemFieldId()));
  }
  if (remoteOptions.allowNetworkRemote?.included) {
    setIncludedFields(setToggle(remoteOptionsAllowNetworkRemoteFieldId()));
  }
  if (remoteOptions.networkProgrammingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsNetworkProgrammingPortFieldId()));
  }
  if (remoteOptions.allowCellularRemoteProgramming?.included) {
    setIncludedFields(
      setToggle(remoteOptionsAllowCellularRemoteProgrammingFieldId())
    );
  }
  if (remoteOptions.firstApn?.included) {
    setIncludedFields(setToggle(remoteOptionsApnFieldId()));
  }
  if (remoteOptions.secondApn?.included) {
    setIncludedFields(setToggle(remoteOptionsApn2FieldId()));
  }
  if (remoteOptions.sendLocalChanges?.included) {
    setIncludedFields(setToggle(remoteOptionsSendLocalChangesFieldId()));
  }
  if (remoteOptions.remoteChangesIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteChangeIpAddressFieldId()));
  }
  if (remoteOptions.remoteChangesPort?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteChangePortFieldId()));
  }
  if (remoteOptions.remoteChangesPhoneNumber?.included) {
    setIncludedFields(setToggle(remoteOptionsRemotePhoneNumberFieldId()));
  }
  if (remoteOptions.entreConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreConnectionFieldId()));
  }
  if (remoteOptions.entreIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreIncomingTcpPortFieldId()));
  }
  if (remoteOptions.entreIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreIpAddressFieldId()));
  }
  if (remoteOptions.entreOutgoingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreOutgoingTcpPortFieldId()));
  }
  if (remoteOptions.entreIpv6?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreIpv6FieldId()));
  }
  if (remoteOptions.entreIpv6Address?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreIpv6AddressFieldId()));
  }
  if (remoteOptions.entreBackupConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupConnectionFieldId()));
  }
  if (remoteOptions.entreBackupIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupIpAddressFieldId()));
  }
  if (remoteOptions.entreBackupIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupTcpPortFieldId()));
  }
  if (remoteOptions.entreBackupIpv6Address?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupIpv6AddressFieldId()));
  }
  if (remoteOptions.entreCheckInMinutes?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreCheckInMinutesFieldId()));
  }
  if (remoteOptions.entreArmDisarmReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreArmDisarmReportsFieldId()));
  }
  if (remoteOptions.entreZoneReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreZoneReportsFieldId()));
  }
  if (remoteOptions.entreUserCommandReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreUserCommandReportsFieldId()));
  }
  if (remoteOptions.entreDoorReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreDoorReportsFieldId()));
  }
  if (remoteOptions.entreSupervisoryReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreSupervisoryReportsFieldId()));
  }
  if (remoteOptions.entrePassphrase?.included) {
    setIncludedFields(setToggle(remoteOptionsEntrePassphraseFieldId()));
  }
  if (remoteOptions.entreVideoReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreVideoReportsFieldId()));
  }
  if (remoteOptions.integratorConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorConnectionFieldId()));
  }
  if (remoteOptions.integratorIncomingPort?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorIncomingTcpPortFieldId())
    );
  }
  if (remoteOptions.integratorIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorIpAddressFieldId()));
  }
  if (remoteOptions.integratorOutgoingPort?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorOutgoingTcpPortFieldId())
    );
  }
  if (remoteOptions.integratorBackupConnection?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorBackupConnectionFieldId())
    );
  }
  if (remoteOptions.integratorBackupIpAddress?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorBackupIpAddressFieldId())
    );
  }
  if (remoteOptions.integratorBackupIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorBackupTcpPortFieldId()));
  }
  if (remoteOptions.integratorArmDisarmReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorArmDisarmReportsFieldId())
    );
  }
  if (remoteOptions.integratorZoneReports?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorZoneReportsFieldId()));
  }
  if (remoteOptions.integratorUserCommandReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorUserCommandReportsFieldId())
    );
  }
  if (remoteOptions.integratorDoorReports?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorDoorReportsFieldId()));
  }
  if (remoteOptions.integratorSupervisoryReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorSupervisoryReportsFieldId())
    );
  }
  if (remoteOptions.integratorCheckInMinutes?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorCheckInMinutesFieldId())
    );
  }
  if (remoteOptions.integratorPassphrase?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorPassphraseFieldId()));
  }
  if (remoteOptions.integratorVideoReports?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorVideoReportsFieldId()));
  }
};
