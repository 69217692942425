/**
 * @generated SignedSource<<3b3e0532ba4f09b5396901619ff88a56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup$data = {
  readonly enableMessaging: boolean;
  readonly sendSmsOnOpeningAndClosing: boolean;
  readonly " $fragmentType": "MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup";
};
export type MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup$key = {
  readonly " $data"?: MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendSmsOnOpeningAndClosing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableMessaging",
      "storageKey": null
    }
  ],
  "type": "MessagingSetup",
  "abstractKey": null
};

(node as any).hash = "0211cea3a7d029eaa3517458178f504a";

export default node;
