// Import this file first
import "./dangerously-add-app-to-window";
// And then everything else--these comments keep the automatic import ordering
// from changing the order and completely breaking the app
import { SecureComEnv } from "common/utils/universal/types";
import "./app";
import "./dangerously-add-react-components-to-app";
import "./dangerously-add-utils-to-window";
import { dangerouslyAddPropertiesToApp } from "./properties";
import { renderApp } from "./render-app";
import "./sass/app.scss";

const env = (process.env?.REACT_APP_SECURECOM_ENV ?? "dev1") as SecureComEnv;
const stagingBranch = process.env.REACT_APP_CLIENT_STAGING_BRANCH;
const appVersion = process.env?.REACT_APP_VERSION ?? "0.0.0";
const sha =
  process.env?.REACT_APP_SHA ?? "0000000000000000000000000000000000000000";
const appDate = new Intl.DateTimeFormat().format(
  new Date(process.env?.REACT_APP_DATE ?? new Date())
);

dangerouslyAddPropertiesToApp({
  env,
  stagingBranch,
  appDate,
  appVersion,
  sha,
});

renderApp();
