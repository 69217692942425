/**
 *
 * SearchTerm
 * @author Chad Watson
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, withHandlers, setPropTypes } from "recompose";
import { BORDER_RADIUS } from "common/components/web/constants";
import {
  themePanelBorder,
  themePrimary,
  themeRgba,
} from "common/components/web/Theme";
import { BACKSPACE, LEFT_ARROW, RIGHT_ARROW, ESCAPE } from "common/constants";
import { injectIntl } from "react-intl";
import NakedButton from "../NakedButton";
import { CloseCancelIcon } from "../Icons";
import messages from "./messages";

const Root = styled.li`
  position: relative;
  margin-right: 5px;
`;
const Button = styled(NakedButton)`
  display: block;
  padding: 0.6em 1.35em 0.6em 0.75em;
  border-radius: ${BORDER_RADIUS};
  border: ${themePanelBorder};
  background: white;

  &:focus {
    background: ${themeRgba(0.8, themePrimary)};
    border-color: ${themePrimary};
    color: white;

    & ~ button {
      color: white;
    }
  }
`;
const Text = styled.span`
  margin-right: 0.35em;
  font-weight: 500;
`;
const CloseButton = styled(NakedButton)`
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  padding: 0.25em;
  font-size: 0.75em;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const SearchTerm = ({
  removeSearchTerm,
  onKeyDown,
  children,
  intl,
  buttonRef,
}) => (
  <Root>
    <Button ref={buttonRef} onKeyDown={onKeyDown}>
      <Text>{children}</Text>
    </Button>
    <CloseButton
      onClick={removeSearchTerm}
      tabIndex={-1}
      title={intl.formatMessage(messages.removeSearchTerm)}
    >
      <CloseCancelIcon />
    </CloseButton>
  </Root>
);

SearchTerm.propTypes = {
  children: PropTypes.node.isRequired,
  removeSearchTerm: PropTypes.func,
  onKeyDown: PropTypes.func,
  buttonRef: PropTypes.func,
};

const enhance = compose(
  setPropTypes({
    index: PropTypes.number.isRequired,
  }),
  withHandlers(() => {
    let button = null;

    return {
      buttonRef: ({ buttonRef }) => (ref) => {
        button = ref;

        if (buttonRef) {
          buttonRef(ref);
        }
      },
      removeSearchTerm: ({ removeSearchTerm, index }) => () =>
        removeSearchTerm(index),
      onKeyDown: ({
        removeSearchTerm,
        index,
        selectPreviousSearchTerm,
        selectNextSearchTerm,
      }) => ({ which }) => {
        if (which === BACKSPACE) {
          removeSearchTerm(index);
        } else if (which === LEFT_ARROW) {
          selectPreviousSearchTerm();
        } else if (which === RIGHT_ARROW) {
          selectNextSearchTerm();
        } else if (which === ESCAPE) {
          button.blur();
        }
      },
    };
  }),
  injectIntl
);

export default enhance(SearchTerm);
