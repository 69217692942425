/**
 * @generated SignedSource<<5bdf63af00eee36aa900d3e930e0382d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SevenInchContactInfoEditQuery$variables = {
  id: string;
};
export type SevenInchContactInfoEditQuery$data = {
  readonly dealer: {
    readonly dealerKeypadContactInfo?: {
      readonly __typename: "DealerKeypadContactInfo";
      readonly createdAt: string | null;
      readonly dealerId: number;
      readonly id: string;
      readonly line1: string | null;
      readonly line2: string | null;
      readonly line3: string | null;
      readonly line4: string | null;
      readonly line5: string | null;
      readonly line6: string | null;
      readonly updatedAt: string | null;
    } | null;
    readonly id?: string;
  } | null;
};
export type SevenInchContactInfoEditQuery = {
  response: SevenInchContactInfoEditQuery$data;
  variables: SevenInchContactInfoEditQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "DealerKeypadContactInfo",
  "kind": "LinkedField",
  "name": "dealerKeypadContactInfo",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dealerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "line1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "line2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "line3",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "line4",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "line5",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "line6",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SevenInchContactInfoEditQuery",
    "selections": [
      {
        "alias": "dealer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Dealer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SevenInchContactInfoEditQuery",
    "selections": [
      {
        "alias": "dealer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Dealer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89446d331ea153f1fff0cc47bb388f11",
    "id": null,
    "metadata": {},
    "name": "SevenInchContactInfoEditQuery",
    "operationKind": "query",
    "text": "query SevenInchContactInfoEditQuery(\n  $id: ID!\n) {\n  dealer: node(id: $id) {\n    __typename\n    ... on Dealer {\n      id\n      dealerKeypadContactInfo {\n        __typename\n        id\n        dealerId\n        line1\n        line2\n        line3\n        line4\n        line5\n        line6\n        createdAt\n        updatedAt\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0165720c93d742849b21e810037b616c";

export default node;
