import graphql from "babel-plugin-relay/macro";
import { useResetControlSystemIntegrations } from "components/StatusBadge";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { IntegrationForm } from "./Form";
import { IntegrationFormState, reducer } from "./IntegrationFormReducer";
import { EditIntegrationFormDealerCentralStationIntegrationMutation } from "./__generated__/EditIntegrationFormDealerCentralStationIntegrationMutation.graphql";
import {
  EditIntegrationForm_query$data,
  EditIntegrationForm_query$key,
} from "./__generated__/EditIntegrationForm_query.graphql";

const initState = (
  integration: NonNullable<
    EditIntegrationForm_query$data["dealer"]
  >["centralStationAutomationIntegrations"][0]
): IntegrationFormState => ({
  id: integration.id,
  centralStationName: integration.name,
  username: integration.username,
  password: "",
  emergencyContactsEnabled: integration.emergencyContactsEnabled,
  systemTestsEnabled: integration.systemTestsEnabled,
  usernameBlurred: false,
  passwordBlurred: false,
  centralStationCode: "",
  cmsDealerNumber: integration.cmsDealerNumber,
});

const updateDealerCentralStationIntegration = graphql`
  mutation EditIntegrationFormDealerCentralStationIntegrationMutation(
    $integration: UpdateDealerCentralStationAutomationIntegrationInput!
  ) {
    updateDealerCentralStationAutomationIntegration(integration: $integration) {
      ... on UpdateDealerCentralStationAutomationIntegrationErrorResponse {
        __typename
        error {
          ... on Error {
            errorType: type
          }
        }
      }
      ... on UpdateDealerCentralStationAutomationIntegrationSuccessResponse {
        __typename
        dealer {
          id
          centralStationAutomationIntegrations {
            name
            displayedName
            status
            emergencyContactsEnabled
            systemTestsEnabled
            username
            cmsDealerNumber
            authorityLevels {
              __typename
              name
            }
          }
        }
      }
    }
  }
`;

export const EditIntegrationForm: React.FC<
  React.PropsWithChildren<{
    id: string;
    queryRef: EditIntegrationForm_query$key;
    onCancel: () => void;
  }>
> = ({ id, queryRef, onCancel }) => {
  const data = useFragment(
    graphql`
      fragment EditIntegrationForm_query on Query {
        ...FormIntegrationBaseFragment
        dealer(id: $dealerId) {
          id
          centralStationAutomationIntegrations {
            __typename
            id
            name
            displayedName
            username
            status
            emergencyContactsEnabled
            systemTestsEnabled
            cmsDealerNumber
          }
        }
      }
    `,
    queryRef
  );

  const [editIntegration, isEditingIntegration] =
    useMutation<EditIntegrationFormDealerCentralStationIntegrationMutation>(
      updateDealerCentralStationIntegration
    );
  const showAlert = useShowAlert();
  const integration = data.dealer?.centralStationAutomationIntegrations.find(
    (integration) => integration.id === id
  )!;

  const [state, dispatch] = React.useReducer(reducer, integration, initState);

  const resetControlSystemIntegrations = useResetControlSystemIntegrations();

  return (
    <IntegrationForm
      isSavingIntegration={isEditingIntegration}
      state={state}
      dispatch={dispatch}
      displayedName={integration.displayedName}
      baseFormDataFragment={data}
      onCancel={onCancel}
      onSave={() => {
        editIntegration({
          variables: {
            integration: {
              id,
              centralStationName: state.centralStationName,
              username: state.username || null,
              password: state.password || null,
              emergencyContactsEnabled: state.emergencyContactsEnabled,
              systemTestsEnabled: state.systemTestsEnabled,
              cmsDealerNumber: state.cmsDealerNumber,
            },
          },
          onCompleted: (response) => {
            if (
              response.updateDealerCentralStationAutomationIntegration
                .__typename ===
              "UpdateDealerCentralStationAutomationIntegrationSuccessResponse"
            ) {
              showAlert({
                type: "success",
                text: "Created integration",
              });
              onCancel();
              resetControlSystemIntegrations();
            } else {
              showAlert({
                type: "error",
                text: "Unable to create integration",
              });
            }
          },
        });
      }}
    />
  );
};
export default EditIntegrationForm;
