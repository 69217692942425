/**
 * @generated SignedSource<<9112d6b3bafc8bfb8ec7ca2f38b7cd79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly systemReports: {
      readonly abortReports: boolean;
      readonly accessKeypads: string;
      readonly ambushReports: boolean;
      readonly bypassReports: boolean;
      readonly codeChangeReports: boolean;
      readonly earlyToClose: number;
      readonly earlyToCloseMax: number;
      readonly earlyToCloseMin: number;
      readonly entryCheckinProtection: boolean;
      readonly id: string;
      readonly lateToOpen: number;
      readonly lateToOpenMax: number;
      readonly lateToOpenMin: number;
      readonly openCloseEnable: boolean;
      readonly scheduleChangeReports: boolean;
      readonly sendStoredMessage: boolean;
      readonly videoSystemReports: boolean;
      readonly zoneRestoralReports: SystemReportsZoneRestoralReports;
    } | null;
  };
  readonly " $fragmentType": "TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem";
};
export type TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "4cbbae5e586a8def01f0e41fbbb4137e";

export default node;
