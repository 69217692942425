import graphql from "babel-plugin-relay/macro";
import noop from "common/utils/universal/noop";
import { partitionFulfilled } from "common/utils/universal/promise";
import { useShowAlert } from "contexts/AlertsContext";
import { useEnsureConnectionReady } from "contexts/InitialConnectContext";
import { indexBy, prop } from "ramda";
import * as React from "react";
import { GraphQLTaggedNode, useFragment, useMutation } from "react-relay";
import { ErrorType, SystemType } from "securecom-graphql/client";
import { PROGRAMMING_TIMEOUT_REFRESH } from "securecom-graphql/src/utils/constants";
import { resolvePanelType } from "../utils/panel";
import {
  removeProgrammingConceptFromChangedProgrammingConcepts,
  removeProgrammingConceptsFromChangedProgrammingConcepts,
  useChangedProgrammingConcepts,
  useSetChangedProgrammingConcepts,
} from "./ChangedProgrammingConceptsContext";
import { Concept, OnSave, SaveErrors } from "./FullProgrammingForm";
import {
  removeProgrammingConceptFromInvalidFields,
  removeProgrammingConceptsFromInvalidFields,
  useSetInvalidFields,
} from "./InvalidFieldsContext";
import { useRefreshSpecifiedConceptsContext } from "./ProgrammingAngularConnectionContext";
import { PanelHardwareModel } from "./__generated__/PanelContextUseHardwareModel_panel.graphql";

type ProgrammingActions = {
  conceptId: string;
  onSave: OnSave;
  isSaving: boolean;
  onRetrieve: (showAlerts: boolean) => Promise<void>;
  isRetrieving: boolean;
  hasChanges: boolean;
  suppressAreaInfoSave?: boolean;
};

type ProgrammingConcepts = {
  [key: string]: ProgrammingActions;
};

export const ProgrammingContext = React.createContext<{
  isValidatingProgramming: boolean;
  setIsValidatingProgramming:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  isSavingAllProgramming: boolean;
  setIsSavingAllProgramming:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  isSendingAllProgramming: boolean;
  setIsSendingAllProgramming:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  isSendingPreProgramming: boolean;
  setIsSendingPreProgramming:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  isSendingAllChanges: boolean;
  setIsSendingAllChanges:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  isSendingConcept: boolean;
  setIsSendingConcept:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  isRetrievingAll: boolean;
  setIsRetrievingAll:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  showOutputSerialNumberError: boolean;
  setShowOutputSerialNumberError:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {});
  programmingConcepts: ProgrammingConcepts;
}>({
  isValidatingProgramming: false,
  setIsValidatingProgramming: () => {},
  isSavingAllProgramming: false,
  setIsSavingAllProgramming: () => {},
  isSendingPreProgramming: false,
  setIsSendingPreProgramming: () => {},
  isSendingAllProgramming: false,
  setIsSendingAllProgramming: () => {},
  isSendingAllChanges: false,
  setIsSendingAllChanges: () => {},
  isSendingConcept: false,
  setIsSendingConcept: () => {},
  isRetrievingAll: false,
  setIsRetrievingAll: () => {},
  showOutputSerialNumberError: false,
  setShowOutputSerialNumberError: () => {},
  programmingConcepts: {},
});

export function FallbackProgrammingContextProvider(props: {
  concepts: Concept[];
  children: React.ReactNode;
}) {
  return (
    <ProgrammingContext.Provider
      value={{
        isValidatingProgramming: false,
        setIsValidatingProgramming: noop,
        isSavingAllProgramming: false,
        setIsSavingAllProgramming: noop,
        isSendingAllProgramming: false,
        setIsSendingAllProgramming: noop,
        isSendingAllChanges: false,
        setIsSendingAllChanges: noop,
        isSendingConcept: false,
        setIsSendingConcept: noop,
        isSendingPreProgramming: false,
        setIsSendingPreProgramming: noop,
        isRetrievingAll: false,
        setIsRetrievingAll: noop,
        showOutputSerialNumberError: false,
        setShowOutputSerialNumberError: noop,
        programmingConcepts: indexBy(
          prop("conceptId"),
          props.concepts.map((concept) => ({
            conceptId: concept.conceptId,
            onSave: (showAlerts = false, isSendingAllProgramming = false) =>
              new Promise((resolve) => resolve([] as SaveErrors)),
            isSaving: false,
            onRetrieve: async () => {},
            isRetrieving: false,
            hasChanges: false,
            suppressAreaInfoSave: false,
          }))
        ),
      }}
    >
      {props.children}
    </ProgrammingContext.Provider>
  );
}

export function ProgrammingContextProvider(props: {
  concepts: {
    conceptId: Concept["conceptId"];
    useSaveMutation: Concept["useSaveMutation"];
    useRetrieveMutation: Concept["useRetrieveMutation"];
    hasNewItems: boolean;
  }[];
  controlSystem: any;
  controlSystemInlineFragment: GraphQLTaggedNode;
  hardwareModel: PanelHardwareModel;
  children: React.ReactNode;
}) {
  const controlSystem = useFragment(
    props.controlSystemInlineFragment,
    props.controlSystem
  );

  const changedFields = useChangedProgrammingConcepts();

  const [isValidatingProgramming, setIsValidatingProgramming] =
    React.useState(false);
  const [isSavingAllProgramming, setIsSavingAllProgramming] =
    React.useState(false);
  const [isSendingAllProgramming, setIsSendingAllProgramming] =
    React.useState(false);
  const [isSendingAllChanges, setIsSendingAllChanges] = React.useState(false);
  const [isSendingConcept, setIsSendingConcept] = React.useState(false);
  const [isRetrievingAll, setIsRetrievingAll] = React.useState(false);
  const [isSendingPreProgramming, setIsSendingPreProgramming] =
    React.useState(false);
  const [showOutputSerialNumberError, setShowOutputSerialNumberError] =
    React.useState(false);

  const { isXr, isXt } = resolvePanelType(
    props.hardwareModel as PanelHardwareModel
  );
  const modelPrefix = isXt ? "xt" : isXr ? "xr" : "";
  let areaInfoChanged = false;
  if (modelPrefix) {
    const oldSystemType = changedFields
      ?.get(`${modelPrefix}-system-options`)
      ?.changedFields.get("system-options-system-type");
    const newSystemType =
      controlSystem.__fragments[
        `${modelPrefix.toUpperCase()}SystemOptionsProgrammingConceptFormInline_controlSystem`
      ]?.panel?.systemOptions?.systemType;
    areaInfoChanged =
      !!oldSystemType &&
      oldSystemType !== newSystemType &&
      newSystemType !== SystemType.AREA;
  }

  const actions = props.concepts.map((concept) => {
    const [onSave, isSaving] = concept.useSaveMutation({ controlSystem });
    const [onRetrieve, isRetrieving] = concept.useRetrieveMutation({
      controlSystem,
    });
    const hasChanges =
      changedFields.has(concept.conceptId) || concept.hasNewItems;

    // Suppress area information from saving if systemType is updated as System Options will sync with Area Information programatically.
    const suppressAreaInfoSave =
      concept.conceptId.includes("area-information") &&
      hasChanges &&
      areaInfoChanged;

    return {
      conceptId: concept.conceptId,
      onSave,
      isSaving,
      onRetrieve,
      isRetrieving,
      hasChanges: hasChanges,
      ...(suppressAreaInfoSave && {
        suppressAreaInfoSave: suppressAreaInfoSave,
      }),
    };
  });

  return (
    <ProgrammingContext.Provider
      value={{
        isValidatingProgramming,
        setIsValidatingProgramming,
        isSavingAllProgramming,
        setIsSavingAllProgramming,
        isSendingAllProgramming,
        setIsSendingAllProgramming,
        isSendingAllChanges,
        setIsSendingAllChanges,
        isSendingConcept,
        setIsSendingConcept,
        isRetrievingAll,
        setIsRetrievingAll,
        isSendingPreProgramming,
        setIsSendingPreProgramming,
        showOutputSerialNumberError,
        setShowOutputSerialNumberError,
        programmingConcepts: indexBy(prop("conceptId"), actions),
      }}
    >
      {React.useMemo(() => props.children, [props.children])}
    </ProgrammingContext.Provider>
  );
}

export const useProgrammingActionsContext = () =>
  React.useContext(ProgrammingContext);

export const useConceptsAreValidating = () => {
  const concepts = useProgrammingActionsContext();
  return concepts.isValidatingProgramming;
};

export const useSetConceptsAreValidating = () => {
  const concepts = useProgrammingActionsContext();
  return concepts.setIsValidatingProgramming;
};

export const useConceptsAreLoading = () => {
  const concepts = useProgrammingActionsContext();

  return (
    concepts.isSavingAllProgramming ||
    concepts.isSendingAllChanges ||
    concepts.isSendingAllProgramming ||
    concepts.isSendingConcept ||
    concepts.isSendingPreProgramming ||
    concepts.isRetrievingAll
  );
};

export const useSetAllConceptInteractionsFalse = () => {
  const context = useProgrammingActionsContext();
  return () => {
    context.setIsSavingAllProgramming(false);
    context.setIsSendingAllChanges(false);
    context.setIsSendingAllProgramming(false);
    context.setIsSendingConcept(false);
    context.setIsSendingPreProgramming(false);
    context.setIsRetrievingAll(false);
  };
};

export const useSetBusyStateForPreProgramming = () => {
  const context = useProgrammingActionsContext();
  return () => {
    context.setIsSendingPreProgramming(true);
  };
};

export const useConceptsHaveChanges = () => {
  const { programmingConcepts } = useProgrammingActionsContext();
  return Object.keys(programmingConcepts).some(
    (key) => programmingConcepts[key].hasChanges
  );
};

export const useSaveAllProgramming = () => {
  //used for saving offline programming
  const context = useProgrammingActionsContext();

  return async () => {
    await context.setIsSavingAllProgramming(true);
  };
};

export const useSendAllProgramming = () => {
  //used to send when you want to come on line or are already online
  const context = useProgrammingActionsContext();

  return async () => {
    await context.setIsSendingAllProgramming(true);
  };
};

export const useBringPanelOnlineAndSendChanges = (
  systemId: string,
  hardwareModel: string
) => {
  const programmingActionsContext = useProgrammingActionsContext();
  const setAllConceptInteractionsFalse = useSetAllConceptInteractionsFalse();
  const setBusyStateForPreProgramming = useSetBusyStateForPreProgramming();
  const ensureConnectionReady = useEnsureConnectionReady();
  const showAlert = useShowAlert();
  const setChangedProgrammingConcepts = useSetChangedProgrammingConcepts();

  const { isXr, isTakeoverPanel, isTMS6, isXt75 } = resolvePanelType(
    hardwareModel as PanelHardwareModel
  );

  const [sendPreProgrammingMutation] = useMutation(graphql`
    mutation ProgrammingContextSendPreProgrammingMutation(
      $systemId: ID!
      $isXr: Boolean!
      $isXt: Boolean!
      $isTakeover: Boolean!
      $isXt75: Boolean!
    ) {
      sendPreProgramming(systemId: $systemId) {
        ... on ControlSystem {
          ...FullProgrammingForm_controlSystem
          ...XRFullProgramming_controlSystem @include(if: $isXr)
          ...XTFullProgramming_controlSystem @include(if: $isXt)
          ...TakeoverPanelFullProgramming_controlSystem
          ...TMSentryFullProgramming_controlSystem @include(if: $isTakeover)
          ...XT75FullProgramming_controlSystem @include(if: $isXt75)
        }
      }
    }
  `);

  const [sendPreProgrammingBackup] = useMutation(graphql`
    mutation ProgrammingContextSendPreProgrammingBackupMutation(
      $systemId: ID!
    ) {
      sendPreProgrammingBackup(systemId: $systemId) {
        ... on ControlSystem {
          ...FullProgrammingForm_controlSystem
        }
      }
    }
  `);
  const [takePanelOffline] = useMutation(graphql`
    mutation ProgrammingContextTakePanelOfflineMutation($systemId: ID!) {
      takePanelOffline(systemId: $systemId) {
        ... on ControlSystem {
          id
        }
      }
    }
  `);

  return async () => {
    setBusyStateForPreProgramming();
    try {
      const results = await Promise.allSettled(
        Object.entries(programmingActionsContext.programmingConcepts).map(
          ([key, { onSave }]) =>
            onSave(false).then((result) => [key, result] as const)
        )
      );
      const { rejected, resolved } = partitionFulfilled(results);

      if (resolved.length) {
        setChangedProgrammingConcepts(
          removeProgrammingConceptsFromChangedProgrammingConcepts(
            resolved.map(([programmingConceptId]) => programmingConceptId)
          )
        );
      }

      if (rejected.length) {
        showAlert({
          type: "error",
          text: "Error Saving Programming to Dealer Admin",
        });
        setAllConceptInteractionsFalse();
        return;
      }
      await sendPreProgrammingBackup({
        variables: {
          systemId: systemId,
        },
        onCompleted(data) {
          (async () => {
            try {
              await ensureConnectionReady();
            } catch {
              takePanelOffline({
                variables: {
                  systemId: systemId,
                },
              });
              setAllConceptInteractionsFalse();
              return;
            }

            try {
              await sendPreProgrammingMutation({
                variables: {
                  systemId: systemId,
                  isXr: isXr,
                  isXt: !(isXr || isTakeoverPanel), // there is no `isXt` exported from the resolve panel model function,
                  // but we know if the panel is not xr or takeover, it's an xt
                  isTakeover: isTakeoverPanel,
                  isTMSentry: isTMS6,
                  isXt75: isXt75,
                },
                onCompleted(data) {
                  setAllConceptInteractionsFalse();
                  showAlert({
                    type: "success",
                    text: "Programming successfully sent to the system",
                  });
                  setChangedProgrammingConcepts(new Map());
                  // Since the retrieve is happening during the scapi pre-programming job, we need to manually set the state
                  // of the changed concepts because we know that what returns from the mutation is up to date with the panel.
                },
              });
            } catch {
              setAllConceptInteractionsFalse();
              showAlert({
                type: "error",
                text: "Error Sending Programming To the System",
              });
            }
          })();
        },
        onError() {
          setAllConceptInteractionsFalse();
          showAlert({
            type: "error",
            text: "Error Sending Programming To the System",
          });
        },
      });
    } catch {
      showAlert({
        type: "error",
        text: "Error Saving programming to Dealer Admin",
      });
      setAllConceptInteractionsFalse();
      return;
    }
  };
};

export const useRetrieveAllProgramming = () => {
  const context = useProgrammingActionsContext();
  const ensureConnectionReady = useEnsureConnectionReady();
  const showAlert = useShowAlert();
  const setChangedProgrammingConcepts = useSetChangedProgrammingConcepts();
  const setInvalidFields = useSetInvalidFields();

  const refreshSpecifiedConcepts = useRefreshSpecifiedConceptsContext();

  return async () => {
    await ensureConnectionReady();
    context.setIsRetrievingAll(true);

    try {
      refreshSpecifiedConcepts([]);
      const programmingResults = Promise.allSettled(
        Object.entries(context.programmingConcepts).map(
          ([key, { onRetrieve }]) =>
            onRetrieve(false).then((response) => [key, response] as const)
        )
      );

      const longRunningTimeoutPromise2 = Promise.allSettled([
        new Promise<readonly [string, void]>((_, reject) => {
          setTimeout(
            () => reject(ErrorType.STILL_WORKING),
            PROGRAMMING_TIMEOUT_REFRESH
          );
        }),
      ]);

      const results = await Promise.race([
        programmingResults,
        longRunningTimeoutPromise2,
      ]);

      const { rejected, resolved } = partitionFulfilled(results);

      if (resolved.length) {
        setChangedProgrammingConcepts(
          removeProgrammingConceptsFromChangedProgrammingConcepts(
            resolved.map(([programmingConceptId]) => programmingConceptId)
          )
        );
        setInvalidFields(
          removeProgrammingConceptsFromInvalidFields(
            resolved.map(([programmingConceptId]) => programmingConceptId)
          )
        );
      }

      if (rejected.length) {
        if (
          rejected.every((response) => response === ErrorType.STILL_WORKING)
        ) {
          showAlert({
            type: "warning",
            text: "System contains a lot of data. Dealer Admin is still retrieving programming.",
          });
        } else {
          showAlert({
            type: "error",
            text: "Error Retrieving Programming From the System",
          });
        }
      } else {
        showAlert({
          type: "success",
          text: "Programming Retrieved From the System",
        });
      }
    } catch {
      showAlert({
        type: "error",
        text: "Error Retrieving Programming From the System",
      });
    } finally {
      context.setIsRetrievingAll(false);
    }
  };
};

export const useSendAllChanges = () => {
  const context = useProgrammingActionsContext();

  return async () => {
    await context.setIsSendingAllChanges(true);
  };
};

export const useSendConceptChanges = (concept: Concept) => {
  const { conceptId, title } = concept;
  const { programmingConcepts, setIsSendingConcept } =
    useProgrammingActionsContext();
  const actions = programmingConcepts[conceptId];

  const disabled = actions.isRetrieving || actions.isSaving; // TODO: Disable while removing?

  const message = actions.isSaving ? `Sending ${title}...` : `Send ${title}`;

  return [
    async () => {
      await setIsSendingConcept(true);
    },
    message,
    disabled,
  ] as const;
};

export const useRetrieveConcept = (concept: Concept) => {
  const { conceptId, title } = concept;
  const { programmingConcepts, setIsRetrievingAll } =
    useProgrammingActionsContext();
  const ensureConnectionReady = useEnsureConnectionReady();
  const actions = programmingConcepts[conceptId];

  const disabled = actions.isRetrieving || actions.isSaving; // TODO: Disable while removing?

  const setChangedProgrammingConcepts = useSetChangedProgrammingConcepts();
  const setInvalidFields = useSetInvalidFields();

  const message = actions.isRetrieving
    ? `Retrieving ${title}...`
    : `Retrieve ${title}`;

  return [
    async () => {
      await ensureConnectionReady();
      setIsRetrievingAll(true);
      try {
        await actions.onRetrieve(true);
        setChangedProgrammingConcepts(
          removeProgrammingConceptFromChangedProgrammingConcepts(conceptId)
        );
        setInvalidFields(removeProgrammingConceptFromInvalidFields(conceptId));
      } finally {
        setIsRetrievingAll(false);
      }
    },
    message,
    disabled,
  ] as const;
};
