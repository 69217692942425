App.service("ImagesService", [
  "ControlSystemImagesAPI",
  "$q",
  "PROPS",
  "IMAGE_SIZES",
  function (ControlSystemImagesAPI, $q, PROPS, IMAGE_SIZES) {
    // public accessors
    this.ControlSystems = {
      GetImageUrls: GetSystemImageURLs,
      Create: CreateSystemImage,
      DestroyImage: DestroySystemImage,
      DestroyAll: DestroyAllSystemImages,
    };

    this.GetAllSizeURLs = GetAllSizeURLs;

    // private functions

    /**
     * Get URLs on the image server for a requested size
     * @param ControlSystemId
     * @param ImageSize - Size of the image being requested.
     *                    Options: EXTRA_LARGE, LARGE, TABLET, MOBILE_LARGE, MOBILE_MEDIUM, MOBILE_SMALL, THUMB
     * @returns {*}
     */
    function GetSystemImageURLs(ControlSystemId, ImageSize) {
      var deferred = $q.defer();
      ControlSystemImagesAPI.getUrls(
        { ControlSystemId: ControlSystemId, ImageSize: ImageSize },
        function (fileLocations) {
          var Urls = [];
          angular.forEach(fileLocations, function (fileLocation) {
            Urls.push(PROPS.imageApiUrl + fileLocation);
          });
          deferred.resolve(Urls);
        },
        function (error) {
          console.error(
            "ControlSystemCtrl-i->init(): error getting system images: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * CreateSystemImage a system image
     * @param ControlSystemId
     * @param deviceImageLocation - URL of the file location on the device
     * @param {string} authToken - authentication token of the logged-in user
     * @returns {*}
     */
    function CreateSystemImage(
      ControlSystemId,
      deviceImageLocation,
      authToken
    ) {
      var deferred = $q.defer();
      window.resolveLocalFileSystemURL(
        deviceImageLocation,
        function (fileEntry) {
          fileEntry.file(function (file) {
            var server = encodeURI(
              PROPS.imageApiUrl + "/api/v1/ControlSystems/" + ControlSystemId
            );

            function successCallback(data) {
              deferred.resolve(PROPS.imageApiUrl + data.response);
            }

            function failCallback(error) {
              console.error(
                "ControlSystemCtrl-i->CreateSystemImage(): error creating system images: " +
                  angular.toJson(error)
              );
              deferred.reject(error);
            }

            var options = new FileUploadOptions();
            options.fileName = file.name;
            options.httpMethod = "POST";
            options.mimeType = file.type;
            options.headers = {
              Authorization: "Bearer " + authToken,
            };

            var ft = new FileTransfer();
            ft.upload(
              file.localURL,
              server,
              successCallback,
              failCallback,
              options
            );
          });
        },
        function (error) {
          console.error("No dice: " + angular.toJson(error));
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Delete a singular image. (Will delete all sizes of the image)
     * @param ControlSystemId
     * @param systemImageUrl - URL of the file on the image server
     * @returns {*}
     */
    function DestroySystemImage(ControlSystemId, systemImageUrl) {
      var urlParts = systemImageUrl.split("/");
      var fileName = urlParts[urlParts.length - 1];
      var props = {
        ControlSystemId: ControlSystemId,
        FileName: fileName,
      };
      return DestroyControlSystemImage(props);
    }

    /**
     * Destroy all sizes of all images for a given control system
     * @param ControlSystemId
     * @returns {*}
     */
    function DestroyAllSystemImages(ControlSystemId) {
      var props = {
        ControlSystemId: ControlSystemId,
        route: "All",
      };
      return DestroyControlSystemImage(props);
    }

    /**
     * Destroy system images.
     * note: This function has no properties so it will fail if you call it directly.
     * @param props
     * @returns {*}
     */
    function DestroyControlSystemImage(props = {}) {
      return new Promise((resolve, reject) => {
        ControlSystemImagesAPI.destroy(props, resolve, reject);
      });
    }

    function GetAllSizeURLs(imageURL) {
      if (imageURL === null) return imageURL;
      var SizedImageURLs = {};
      var Basename = imageURL.substring(
        imageURL.lastIndexOf("/") + 1,
        imageURL.lastIndexOf(".")
      );
      var Keys = Object.keys(IMAGE_SIZES);
      var timeStamp = new Date().getTime();
      angular.forEach(Keys, function (key) {
        SizedImageURLs[key] =
          imageURL.substring(0, imageURL.indexOf(".jpg")) +
          "/" +
          Basename +
          IMAGE_SIZES[key].suffix +
          ".jpg?" +
          timeStamp;
      });
      return SizedImageURLs;
    }
  },
]);
