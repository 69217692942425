/**
 * @generated SignedSource<<dff230e36bb8362d51715dfa6322adbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRDeviceSetupProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly axBusDoorDevicesMax: number;
    readonly deviceInformations: ReadonlyArray<{
      readonly __typename: string;
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XRDeviceSetupProgrammingConceptFormInline_deviceInformation">;
    }>;
    readonly deviceNumberRange: ReadonlyArray<number>;
    readonly id: string;
    readonly keypadBusDevicesMax: number;
    readonly totalDevicesMax: number;
  };
  readonly " $fragmentType": "XRDeviceSetupProgrammingConceptFormInline_controlSystem";
};
export type XRDeviceSetupProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRDeviceSetupProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRDeviceSetupProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRDeviceSetupProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "5d9273d8d14ee348581671fa5d451b2e";

export default node;
