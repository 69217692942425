import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import { ControlSystem, PcLogReports } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import PcLogReportsArmDisarmReportsEnabledField from "../common/PcLogReportsFields/PcLogReportsArmDisarmReportsEnabledField";
import { PcLogReportsContextProvider } from "../common/PcLogReportsFields/PcLogReportsContext";
import PcLogReportsDoorAccessReportsEnabledField from "../common/PcLogReportsFields/PcLogReportsDoorAccessReportsEnabledField";
import PcLogReportsLogRealTimeStatusReportsEnabledField from "../common/PcLogReportsFields/PcLogReportsLogRealTimeStatusReportsEnabledField";
import PcLogReportsNetworkIpAddressField from "../common/PcLogReportsFields/PcLogReportsNetworkIpAddressField";
import PcLogReportsNetworkPortField from "../common/PcLogReportsFields/PcLogReportsNetworkPortField";
import PcLogReportsSupervisoryReportsEnabledField from "../common/PcLogReportsFields/PcLogReportsSupervisoryReportsEnabledField";
import PcLogReportsUserCommandReportsEnabledField from "../common/PcLogReportsFields/PcLogReportsUserCommandReportsEnabledField";
import PcLogReportsZonesReportsEnabledField from "../common/PcLogReportsFields/PcLogReportsZonesReportsEnabledField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XRPcLogReportsProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XRPcLogReportsProgrammingConceptFormInline_controlSystem.graphql";
import { XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XRPcLogReportsProgrammingConceptFormRefreshMutation,
} from "./__generated__/XRPcLogReportsProgrammingConceptFormRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XRPcLogReportsProgrammingConceptFormsPcLogReportsSendMutation,
} from "./__generated__/XRPcLogReportsProgrammingConceptFormsPcLogReportsSendMutation.graphql";
import { XRPcLogReportsProgrammingConceptForm_controlSystem$key } from "./__generated__/XRPcLogReportsProgrammingConceptForm_controlSystem.graphql";

export const conceptId = "xr-pc-log-reports";
export const title = "PC Log Reports";

export const getState = (
  controlSystem: XRPcLogReportsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRPcLogReportsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          pcLogReports {
            __typename
            id
            networkIpAddress
            networkPort
            armDisarmReportsEnabled
            zonesReportsEnabled
            userCommandReportsEnabled
            doorAccessReportsEnabled
            supervisoryReportsEnabled
            logRealTimeStatusEnabled
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XRPcLogReportsProgrammingConceptFormRefreshMutation($id: ID!) {
    refreshPcLogReports(systemId: $id) {
      ... on RefreshPcLogReportsSuccessResponse {
        __typename
        controlSystem {
          ...XRPcLogReportsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshPcLogReportsErrorResponse {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XRPcLogReportsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshPcLogReports, isRefreshing] =
    useMutation<XRPcLogReportsProgrammingConceptFormRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshPcLogReports({
          variables: { id },
          onCompleted: (response) => {
            if (response.refreshPcLogReports.controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "PC Log Reports Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshPcLogReports: {
                    __typename: response.refreshPcLogReports.__typename,
                    controlSystem: getState(
                      response.refreshPcLogReports.controlSystem
                    ),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                showAlert({
                  type: "error",
                  text: "Unable to Retrieve PC Log Reports",
                });
              }
              reject();
            }
          },
          onError: () => {
            if (showAlerts) {
              showAlert({
                type: "error",
                text: "Unable to Retrieve PC Log Reports Programming From The System",
              });
            }
            reject();
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XRPcLogReportsProgrammingConceptFormsPcLogReportsSendMutation(
    $systemId: ID!
    $pcLogReports: PcLogReportsInput!
  ) {
    updatePcLogReports(systemId: $systemId, pcLogReports: $pcLogReports) {
      ... on UpdatePcLogReportsSuccessResponse {
        __typename
        controlSystem {
          __typename
          ...XRPcLogReportsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on UpdatePcLogReportsErrorResponse {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;
export const useSaveMutation = (props: {
  controlSystem: XRPcLogReportsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendPcLogReports, isSendingPcLogReports] =
    useMutation<XRPcLogReportsProgrammingConceptFormsPcLogReportsSendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { pcLogReports },
        } = getState(props.controlSystem);
        if (pcLogReports) {
          sendPcLogReports({
            variables: {
              systemId,
              pcLogReports: {
                networkIpAddress: pcLogReports.networkIpAddress,
                networkPort: pcLogReports.networkPort,
                armDisarmReportsEnabled: pcLogReports.armDisarmReportsEnabled,
                zonesReportsEnabled: pcLogReports.zonesReportsEnabled,

                userCommandReportsEnabled:
                  pcLogReports.userCommandReportsEnabled,
                doorAccessReportsEnabled: pcLogReports.doorAccessReportsEnabled,

                supervisoryReportsEnabled:
                  pcLogReports.supervisoryReportsEnabled,

                logRealTimeStatusEnabled: pcLogReports.logRealTimeStatusEnabled,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.updatePcLogReports.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);
                // Update original data store
                const operation = createOperationDescriptor(
                  sendMutationConcreteRequest,
                  { id: systemId }
                );
                if (parentRelayEnv) {
                  parentRelayEnv.commitPayload(operation, {
                    updatePcLogReports: {
                      __typename: response.updatePcLogReports.__typename,
                      controlSystem: getState(
                        response.updatePcLogReports.controlSystem
                      ),
                    },
                  });
                }
              } else if (response.updatePcLogReports.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.updatePcLogReports.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSendingPcLogReports,
  ];
};

const readPcLogReportsTemplateData = (
  programmingTemplateConcepts: XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        pcLogReports {
          included
          networkIpAddress {
            included
            data
          }
          networkPort {
            included
            data
          }
          armDisarmReportsEnabled {
            included
            data
          }
          zonesReportsEnabled {
            included
            data
          }
          userCommandReportsEnabled {
            included
            data
          }
          doorAccessReportsEnabled {
            included
            data
          }
          supervisoryReportsEnabled {
            included
            data
          }
          logRealTimeStatusEnabled {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).pcLogReports ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readPcLogReportsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const pcLogReportsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "pcLogReports",
      "PcLogReports"
    ) as unknown as RecordProxy<PcLogReports>;

    applyTemplateScalarDataToRecordProxy(pcLogReportsRecordProxy, templateData);
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRPcLogReportsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRPcLogReportsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            pcLogReports {
              ...PcLogReportsContext_pcLogReports
              ...PcLogReportsNetworkIpAddressField_pcLogReports
              ...PcLogReportsNetworkPortField_pcLogReports
              ...PcLogReportsArmDisarmReportsEnabledField_pcLogReports
              ...PcLogReportsZonesReportsEnabledField_pcLogReports
              ...PcLogReportsUserCommandReportsEnabledField_pcLogReports
              ...PcLogReportsDoorAccessReportsEnabledField_pcLogReports
              ...PcLogReportsSupervisoryReportsEnabledField_pcLogReports
              ...PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseSoftwareDate_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <PcLogReportsContextProvider
        pcLogReports={controlSystem.panel.pcLogReports}
      >
        <ProgrammingConceptForm
          key={controlSystem.panel?.id}
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#PC%20Log%20Reports`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.pcLogReports
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <PcLogReportsNetworkIpAddressField />
            <PcLogReportsNetworkPortField />
            <PcLogReportsArmDisarmReportsEnabledField />
            <PcLogReportsZonesReportsEnabledField />
            <PcLogReportsUserCommandReportsEnabledField />
            <PcLogReportsDoorAccessReportsEnabledField />
            <PcLogReportsSupervisoryReportsEnabledField />
            <PcLogReportsLogRealTimeStatusReportsEnabledField />
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </PcLogReportsContextProvider>
    </PanelContextProvider>
  );
}
