// Extracting the Contact Info Edit component from the SevenInchKeypadSettings component
// to make it more readable and maintainable.

import graphql from "babel-plugin-relay/macro";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useEffect, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { idAsString, toDealerId } from "securecom-graphql/client";
import {
  AddressLine,
  AddressTextArea,
  ContactInformationArea,
  ContactText,
  DealerExampleLogo,
  DealerLogoArea,
  FormContactEdit,
  FormContactEditArea,
  FormGroup,
  Input,
  Label,
  SevenInchKeyPadContactPreviewAreaSample,
  TitleContactScreenArea,
} from "./SevenInchKeypadStyles";
import { SevenInchLogoContext } from "./SevenInchLogoContext";
import { SevenInchContactInfoEditMutation } from "./__generated__/SevenInchContactInfoEditMutation.graphql";
import { SevenInchContactInfoEditQuery } from "./__generated__/SevenInchContactInfoEditQuery.graphql";

// #region TYPES
type SevenInchKeypadContactInfoProps = {
  dealerId: string;
  stateIsEdit: boolean;
};

interface ContactInfo {
  line1: string | null | undefined;
  line2: string | null | undefined;
  line3: string | null | undefined;
  line4: string | null | undefined;
  line5: string | null | undefined;
  line6: string | null | undefined;
}
// #endregion TYPES
const updateContactInfo = graphql`
  mutation SevenInchContactInfoEditMutation(
    $dealerId: Int!
    $dealerKeypadContactInfoInput: DealerKeypadContactInfoInput!
  ) {
    SendDealerKeypadContactInfo(
      dealerId: $dealerId
      dealerKeypadContactInfoInput: $dealerKeypadContactInfoInput
    ) {
      ... on SendDealerKeypadContactInfoSuccessPayload {
        __typename
        dealer {
          id
          dealerKeypadContactInfo {
            __typename
            id
            dealerId
            line1
            line2
            line3
            line4
            line5
            line6
            createdAt
            updatedAt
          }
        }
      }
      ... on SendDealerKeypadContactInfoErrorResponse {
        __typename

        type
      }
    }
  }
`;

const ContactInfoEdit = ({
  dealerId,
  stateIsEdit,
}: SevenInchKeypadContactInfoProps) => {
  const { imageSource } = React.useContext(SevenInchLogoContext);
  //#region CONTACT INFO HANDLING
  // mutation hook to update contact info
  const [contactInfo, setContactInfo] = useState<
    ContactInfo | undefined | null
  >(null);
  const [updateKeypadContactInfo, isUpdatingKeypadContactInfo] =
    useMutation<SevenInchContactInfoEditMutation>(updateContactInfo);

  const [contactFormData, setContactFormData] = useState<ContactInfo>({
    line1: null,
    line2: null,
    line3: null,
    line4: null,
    line5: null,
    line6: null,
  });
  //  isEditingDealer is used to toggle the edit/disabled state for the dealer contact info inputs
  const [isEditingDealer, setIsEditingDealer] = useState(stateIsEdit || false);
  const showAlert = useShowAlert();

  //renderContactInfoDisplayValues:  function to render the contact info display values
  // if the value is null, undefined, or " ", set the value to unicode "\u00A0" (non-breaking space)
  //this mimics the behavior of the 7" keypad
  const renderContactInfoDisplayValues = (
    value: string | null | undefined
  ): string => {
    return value !== null && value !== undefined && value !== " "
      ? value
      : "\u00A0";
  };

  // handleContactInfoBlur
  // if the value is null, undefined, or " ", set the value to "\u00A0"
  // else return the value
  // call this function in the onBlur handler for each input
  const handleContactInfoBlur = (
    fieldName: keyof ContactInfo,
    value: string
  ): void => {
    setContactFormData((prevData: any) => ({
      ...prevData,
      [fieldName]: renderContactInfoDisplayValues(value),
    }));
  };

  // function to handle saving contact info
  const handleContactInfoSave = (contactInfo: ContactInfo) => {
    if (!!contactInfo) {
      updateKeypadContactInfo({
        variables: {
          dealerId: parseInt(dealerId),
          dealerKeypadContactInfoInput: {
            DealerId: parseInt(dealerId),
            Line1: contactInfo.line1 || " ",
            Line2: contactInfo.line2 || " ",
            Line3: contactInfo.line3 || " ",
            Line4: contactInfo.line4 || " ",
            Line5: contactInfo.line5 || " ",
            Line6: contactInfo.line6 || " ",
          },
        },
        onCompleted: (data) => {
          if (
            data.SendDealerKeypadContactInfo?.__typename ===
            "SendDealerKeypadContactInfoSuccessPayload"
          ) {
            showAlert({
              type: "success",
              text: `Contact Info Updated`,
            });

            setContactInfo(
              data.SendDealerKeypadContactInfo.dealer?.dealerKeypadContactInfo
            );
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: `Error updating contact info`,
          });
        },
      });
    }
  };

  // handleContactInfoInputChange
  const handleContactInfoInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof ContactInfo
  ) => {
    const { value } = event.target;
    setContactFormValues({ ...contactFormValues, [key]: value });
    setContactInfo({ ...contactFormValues, [key]: value });
  };

  const [contactFormValues, setContactFormValues] = useState<ContactInfo>({
    line1: null,
    line2: null,
    line3: null,
    line4: null,
    line5: null,
    line6: null,
  });

  const contactInfoData = useLazyLoadQuery<SevenInchContactInfoEditQuery>(
    graphql`
      query SevenInchContactInfoEditQuery($id: ID!) {
        dealer: node(id: $id) {
          ... on Dealer {
            id

            dealerKeypadContactInfo {
              __typename
              id
              dealerId
              line1
              line2
              line3
              line4
              line5
              line6
              createdAt
              updatedAt
            }
          }
        }
      }
    `,
    { id: idAsString(toDealerId(dealerId)) }
  );

  const dealerContactInfo = contactInfoData?.dealer?.dealerKeypadContactInfo;

  useEffect(() => {
    // On initial page load, check if data is present
    if (dealerContactInfo) {
      setContactFormValues(dealerContactInfo);
    }
  }, []);

  // #endregion CONTACT INFO HANDLING

  return (
    <>
      <TitleContactScreenArea>
        <h4>{"\u00A0"} </h4>
      </TitleContactScreenArea>
      <SevenInchKeyPadContactPreviewAreaSample>
        <DealerLogoArea>
          <DealerExampleLogo src={imageSource} />
        </DealerLogoArea>
        <ContactInformationArea>
          <ContactText style={{ marginTop: "2rem" }}>
            Custom Message (Account #, etc)
          </ContactText>
          <ContactText>
            {renderContactInfoDisplayValues(contactFormValues.line1)}
          </ContactText>
          <ContactText>
            {renderContactInfoDisplayValues(contactFormValues.line2)}
          </ContactText>
          <ContactText>
            {renderContactInfoDisplayValues(contactFormValues.line3)}
          </ContactText>
          <ContactText>
            {renderContactInfoDisplayValues(contactFormValues.line4)}
          </ContactText>
          <AddressTextArea>
            <AddressLine>
              {renderContactInfoDisplayValues(contactFormValues.line5)}
            </AddressLine>
            <AddressLine>
              {renderContactInfoDisplayValues(contactFormValues.line6)}
            </AddressLine>
          </AddressTextArea>
        </ContactInformationArea>
        <img
          src="/assets/img/7in-keypad/contact_screen_blank.png"
          alt="contact screen"
          style={{ width: "100%", height: "auto" }}
        />
      </SevenInchKeyPadContactPreviewAreaSample>
      <FormContactEditArea>
        <FormContactEdit>
          <FormGroup>
            <Label htmlFor="Line1">Line 1</Label>
            <Input
              maxLength={45}
              placeholder="45 characters max"
              className="form-control"
              type="text"
              name="Line1"
              id="Line1"
              value={contactFormValues.line1 || ""}
              disabled={!isEditingDealer}
              onChange={(event) => handleContactInfoInputChange(event, "line1")}
              onBlur={(event) =>
                handleContactInfoBlur("line1", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Line2">Line 2</Label>
            <Input
              maxLength={45}
              placeholder="45 characters max"
              className="form-control"
              type="text"
              id="Line2"
              name="Line2"
              value={contactFormValues.line2 || ""}
              onChange={(event) => handleContactInfoInputChange(event, "line2")}
              disabled={!isEditingDealer}
              onBlur={(event) =>
                handleContactInfoBlur("line2", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Line3">Line 3</Label>
            <Input
              maxLength={45}
              placeholder="45 characters max"
              className="form-control"
              type="text"
              id="Line3"
              name="Line3"
              disabled={!isEditingDealer}
              value={contactFormValues.line3 || ""}
              onChange={(event) => handleContactInfoInputChange(event, "line3")}
              onBlur={(event) =>
                handleContactInfoBlur("line3", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Line4">Line 4</Label>
            <Input
              maxLength={45}
              placeholder="45 characters max"
              className="form-control"
              type="text"
              id="Line4"
              name="Line4"
              disabled={!isEditingDealer}
              value={contactFormValues.line4 || ""}
              onChange={(event) => handleContactInfoInputChange(event, "line4")}
              onBlur={(event) =>
                handleContactInfoBlur("line4", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Line5">Line 5</Label>
            <Input
              maxLength={45}
              placeholder="45 characters max"
              className="form-control"
              type="text"
              id="Line5"
              name="Line5"
              disabled={!isEditingDealer}
              value={contactFormValues.line5 || ""}
              onChange={(event) => handleContactInfoInputChange(event, "line5")}
              onBlur={(event) =>
                handleContactInfoBlur("line5", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="Line6">Line 6</Label>
            <Input
              maxLength={45}
              placeholder="45 characters max"
              className="form-control"
              type="text"
              id="Line6"
              name="Line6"
              disabled={!isEditingDealer}
              value={contactFormValues.line6 || ""}
              onChange={(event) => handleContactInfoInputChange(event, "line6")}
              onBlur={(event) =>
                handleContactInfoBlur("line6", event.target.value)
              }
            />
          </FormGroup>
          <FormGroup
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                setContactInfo(contactInfo);
                setIsEditingDealer(true);
                handleContactInfoSave(
                  contactInfo ? contactInfo : contactFormValues
                );
              }}
              disabled={!isEditingDealer || isUpdatingKeypadContactInfo}
            >
              Save Contact Info
            </button>
          </FormGroup>
        </FormContactEdit>
      </FormContactEditArea>{" "}
    </>
  );
};
export default ContactInfoEdit;
