App.factory("DealerDashboardAPI", [
  "$resource",
  "PROPS",
  ($resource, PROPS) => {
    return $resource(
      `${PROPS.dealerApiUrl}/api/v1/DealerDashboard/:action/:dealer_id`,
      {},
      {
        getDealerInformationData: {
          method: "GET",
          params: {
            action: "DealerInformation",
            dealer_id: "@dealer_id",
          },
        },

        getDealerLevelData: {
          method: "GET",
          isArray: true,
          params: {
            action: "DealerLevels",
          },
        },
        getMonthlySalesHistoryData: {
          method: "GET",
          isArray: true,
          params: {
            action: "MonthlySalesHistory",
            dealer_id: "@dealer_id",
          },
        },
        getTopTenProductsPurchasedData: {
          method: "GET",
          params: {
            action: "TopProducts",
            dealer_id: "@dealer_id",
          },
        },
        getSalesOverviewData: {
          method: "GET",
          params: {
            action: "SalesOverview",
            dealer_id: "@dealer_id",
          },
        },
        getSecurecomStatsData: {
          method: "GET",
          params: {
            action: "SecureComStats",
            dealer_id: "@dealer_id",
          },
        },
        getNewProductsData: {
          method: "GET",
          isArray: true,
          params: {
            action: "NewProducts",
            dealer_id: "@dealer_id",
          },
        },
        getDoorsTotal: {
          method: "GET",
          params: {
            action: "TotalDoors",
            dealer_id: "@dealer_id",
          },
        },
      }
    );
  },
]);

App.factory("DealerDashboardBrainierAPI", [
  "$resource",
  "PROPS",
  ($resource, PROPS) => {
    return $resource(
      `${PROPS.dealerApiUrl}/api/v1/Brainier/Training/:dealer_id`,
      {},
      {
        getBrainierTrainingData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
          },
        },
      }
    );
  },
]);
