import graphql from "babel-plugin-relay/macro";
import Button from "components/Button";
import { Flex } from "components/DaStyledElements";
import Modal from "components/Modal";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import styled from "styled-components";
import { WsDiscoveryProbeQuery } from "./__generated__/WsDiscoveryProbeQuery.graphql";

type WsDiscoveryProbeType = {
  systemId: string;
  hubId: string;
  hubName?: string;
  setIsWsDiscoveryProbeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isWsDiscoveryProbeModalOpen: boolean;
  spacerRight?: boolean;
};

const WsDiscoveryProbe = ({
  hubId,
  hubName,
  systemId,
  setIsWsDiscoveryProbeModalOpen,
  isWsDiscoveryProbeModalOpen,
  spacerRight,
}: WsDiscoveryProbeType) => {
  return (
    <>
      <Button
        className={spacerRight ? "mar-r-8" : " "}
        design="default"
        type="button"
        onClick={() => {
          setIsWsDiscoveryProbeModalOpen(true);
        }}
      >
        WS Discovery Probe
      </Button>

      {isWsDiscoveryProbeModalOpen ? (
        <WsDiscoveryProbeModal
          setIsWsDiscoveryProbeModalOpen={setIsWsDiscoveryProbeModalOpen}
          hubId={hubId}
          hubName={hubName ? hubName : ""}
          systemId={systemId}
        />
      ) : null}
    </>
  );
};

type wsDiscoveryProbeModalTypes = {
  setIsWsDiscoveryProbeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hubId: string;
  hubName: string;
  systemId: string;
};

const WsDiscoveryProbeModal = ({
  setIsWsDiscoveryProbeModalOpen,
  hubId,
  hubName,
  systemId,
}: wsDiscoveryProbeModalTypes) => {
  const data = useLazyLoadQuery<WsDiscoveryProbeQuery>(
    graphql`
      query WsDiscoveryProbeQuery($systemId: String!, $hubId: String!) {
        getWsDiscoveryProbe(systemId: $systemId, hubId: $hubId) {
          ... on WsDiscoveryOnDemandProbeSuccessResponsePayload {
            success
            error
            result
          }
          ... on WsDiscoveryOnDemandProbeErrorResponsePayload {
            message
          }
        }
      }
    `,
    { systemId: systemId, hubId: hubId }
  );

  const dateTimeStamp = new Date().toISOString();

  const probeJson = JSON.stringify(data, null, 2);

  return (
    <Modal
      size="large"
      onClickOutside={() => setIsWsDiscoveryProbeModalOpen(false)}
    >
      <Modal.Header>
        <Flex.Row justify="space-between">
          <h4>WS Discovery Probe for {hubName ? hubName : "Gateway"}</h4>
          <span>
            <button
              className="btn btn-default btn-sm mar-l-8"
              onClick={() => setIsWsDiscoveryProbeModalOpen(false)}
            >
              Close
            </button>
          </span>
        </Flex.Row>
      </Modal.Header>
      <ScrollingModalBody>
        <pre>{probeJson}</pre>
        <CloseButtonContainer></CloseButtonContainer>
      </ScrollingModalBody>
    </Modal>
  );
};

const ScrollingModalBody = styled(Modal.Body)`
  max-height: 60rem;
  overflow-y: scroll;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export default WsDiscoveryProbe;
