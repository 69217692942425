/**
 * @generated SignedSource<<f4a6dc79edea234ff212a1d5419d4a52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsApn2Field_remoteOptions$data = {
  readonly allowCellularRemoteProgramming?: boolean | null;
  readonly secondApn?: string | null;
  readonly " $fragmentType": "RemoteOptionsApn2Field_remoteOptions";
};
export type RemoteOptionsApn2Field_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsApn2Field_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsApn2Field_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsApn2Field_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondApn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowCellularRemoteProgramming",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "34f6df9d1cb32e6a3238b95c6fab9710";

export default node;
