/**
 * @generated SignedSource<<b0201b8b2f4194c82c3dcfb02c696e72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommunicationPathAlarmReports = "FIRE" | "NO" | "YES";
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathDayOfWeek = "FRIDAY" | "MONDAY" | "NONE" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY";
export type CommunicationPathDoorAccessReports = "DENY" | "NO" | "YES";
export type CommunicationPathProtocol = "TCP" | "UDP";
export type CommunicationPathSubstitutionCode = "NO" | "SHARED" | "YES";
export type CommunicationPathSupervisoryTroubleReports = "FIRE" | "NO" | "YES";
export type CommunicationPathTestFrequencyUnit = "DAYS" | "HOURS";
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XT75CommunicationProgrammingConceptFormInline_communicationPath$data = {
  readonly __typename: "CommunicationPath";
  readonly accountNumber: string | null;
  readonly alarmReports: CommunicationPathAlarmReports;
  readonly alarmSwitchover: number;
  readonly alarmSwitchoverMax: number;
  readonly alarmSwitchoverMin: number;
  readonly apn: string;
  readonly checkInMinutes: number;
  readonly checkInMinutesMax: number;
  readonly checkInMinutesMin: number;
  readonly commType: CommunicationPathCommType;
  readonly doorAccessReports: CommunicationPathDoorAccessReports;
  readonly duplicateAlarms: boolean;
  readonly encryptionEnabled: boolean;
  readonly failTestHours: number;
  readonly failTime: number;
  readonly failTimeMax: number;
  readonly failTimeMin: number;
  readonly firstPhone: string;
  readonly id: string;
  readonly isNew: boolean;
  readonly number: number;
  readonly openCloseUserReports: boolean;
  readonly protocol: CommunicationPathProtocol;
  readonly receiverPort: string;
  readonly remoteIP: string;
  readonly remoteIPv6: string;
  readonly retryTime: number;
  readonly retryTimeMax: number;
  readonly retryTimeMin: number;
  readonly secondLinePrefixFor893A: string;
  readonly secondPhone: string;
  readonly sendFail: boolean;
  readonly sendPath: boolean;
  readonly substitutionCode: CommunicationPathSubstitutionCode;
  readonly supervisoryTroubleReports: CommunicationPathSupervisoryTroubleReports;
  readonly testDayOfWeek: CommunicationPathDayOfWeek;
  readonly testFrequencyNumber: number;
  readonly testFrequencyNumberMax: number;
  readonly testFrequencyNumberMin: number;
  readonly testFrequencyUnit: CommunicationPathTestFrequencyUnit;
  readonly testReport: CommunicationPathTestReport;
  readonly testTime: string;
  readonly transmissionDelay: number;
  readonly transmissionDelayValidValues: ReadonlyArray<number>;
  readonly type: CommunicationPathType;
  readonly use893A: boolean;
  readonly useCheckIn: CommunicationPathUseCheckIn;
  readonly useIPv6: boolean;
  readonly " $fragmentType": "XT75CommunicationProgrammingConceptFormInline_communicationPath";
};
export type XT75CommunicationProgrammingConceptFormInline_communicationPath$key = {
  readonly " $data"?: XT75CommunicationProgrammingConceptFormInline_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CommunicationProgrammingConceptFormInline_communicationPath">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75CommunicationProgrammingConceptFormInline_communicationPath"
};

(node as any).hash = "8c5040a8ed5d588e8a772220cd13c9a5";

export default node;
