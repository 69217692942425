import { getCamectUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import Modal from "components/Modal";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Icon from "../Icon";
import LoadingSpinner from "../LoadingSpinner";

interface Step {
  name: string;
  endpoint: string;
  status: "pending" | "checking" | "success" | "failure";
  message?: string;
}

const overallStatusTexts = {
  checking: {
    text: "Checking Configuration",
    icon: <LoadingSpinner size="small" />,
  },
  success: {
    text: "Device Successfully Connected. No Connection Errors Found.",
    icon: <Icon name="radial_check" color={`#029F7D`} />,
  },
  failure: {
    text: "Error Found.",
    icon: <Icon name="radial_alert" color={`#f24965`} />,
  },
};

const performFetch = async (url: string, method: string, authToken: string) => {
  try {
    const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
      "production") as SecureComEnv;
    const response = await fetch(`${getCamectUrl(secureComEnv)}/${url}`, {
      method,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("API request failed");
    }
  } catch (error) {
    throw new Error("API request failed");
  }
};

const RecorderTestConnectionModal = ({
  setIsOpen,
  systemId,
  hubId,
  userService,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  systemId: string;
  hubId: any | null;
  userService: any;
}) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const initialSteps = useRef<Step[]>([]); // Add useRef to hold the initial steps
  const [overallStatus, setOverallStatus] = useState<
    "checking" | "success" | "failure"
  >("checking");
  const [areInitialStepsFetched, setAreInitialStepsFetched] = useState(false); // New state variable

  // Fetch steps data from the API
  useEffect(() => {
    const fetchSteps = async () => {
      try {
        // TODO: look at converting direct api call to GraphQL
        const data = await performFetch(
          `api/v1/camect/${systemId}/integration/status/${hubId}/steps`,
          "GET",
          userService.auth_token
        );
        // Set the steps, initially with "pending" status and no message
        const initialStepsData = data.map((step: Step) => ({
          ...step,
          status: "pending",
          message: undefined,
        }));
        setSteps(initialStepsData);
        initialSteps.current = initialStepsData; // Store the initial steps in the useRef
        setAreInitialStepsFetched(true);
      } catch (error) {
        // Handle fetch error here
      }
    };

    fetchSteps();
  }, []);

  useEffect(() => {
    const checkSteps = async () => {
      let shouldContinue = true;
      let hasError = false;

      for (let i = 0; i < steps.length && shouldContinue; i++) {
        setSteps((prevSteps) =>
          prevSteps.map((step, index) =>
            index === i ? { ...step, status: "checking" } : step
          )
        );

        // Perform the actual API call for the current step
        try {
          // TODO: look at converting direct api call to GraphQL
          const data = await performFetch(
            `api/v1/camect/${systemId}/integration/status/${hubId}/${steps[i].endpoint}`,
            "GET",
            userService.auth_token
          );
          const success = data.status === "success";
          const fatal = data.status === "fatal";
          const message = success ? undefined : data.message;
          setSteps((prevSteps) =>
            prevSteps.map((step, index) =>
              index === i
                ? { ...step, status: success ? "success" : "failure", message }
                : step
            )
          );

          if (!success) {
            // The step failed.
            hasError = true;
            if (fatal) {
              // We shouldn't continue past this step.
              shouldContinue = false;
            }
          }

          // Stop checking steps if shouldContinue is false
          if (!shouldContinue) {
            break;
          }
        } catch (error) {
          // Handle fetch error here
          console.error("Fetch Error:", error);
          setSteps((prevSteps) =>
            prevSteps.map((step, index) =>
              index === i
                ? { ...step, status: "failure", message: "API request failed" }
                : step
            )
          );
          hasError = true;
          shouldContinue = false;
        }
      }

      // Determine the overall status based on step statuses
      setOverallStatus(hasError ? "failure" : "success");
    };

    if (areInitialStepsFetched) {
      checkSteps();
    }
  }, [areInitialStepsFetched]);

  // Determine if steps are currently running
  const stepsRunning = steps.some((step) => step.status === "checking");

  return (
    <Modal size="medium" onClickOutside={() => setIsOpen(false)}>
      <Modal.Header>
        <h4>Test Connection</h4>
      </Modal.Header>
      <Modal.Body>
        {/* Overall progress message */}
        {overallStatusTexts[overallStatus] && (
          <OverallStatusContainer status={overallStatus}>
            {overallStatusTexts[overallStatus].icon}
            <OverallStatusText status={overallStatus}>
              {overallStatusTexts[overallStatus].text}
            </OverallStatusText>
          </OverallStatusContainer>
        )}

        <StepList>
          {steps.map((step, index) => (
            <StepContainer
              key={index}
              status={step.status}
              isEven={index % 2 === 0}
              isRunning={step.status === "checking"}
              isFailed={step.status === "failure"}
            >
              <StepContent>
                <StepLabel status={step.status}>{step.name}</StepLabel>
                {step.message && <StepMessage>{step.message}</StepMessage>}
              </StepContent>
              {step.status === "checking" && <LoadingSpinner size="small" />}
              {step.status === "success" && (
                <Icon name="radial_check" color={`#029F7D`} />
              )}
              {step.status === "failure" && (
                <Icon name="radial_alert" color={`#f24965`} />
              )}
            </StepContainer>
          ))}
        </StepList>
        <CloseButtonContainer>
          <button
            className="btn btn-dmp btn-sm"
            onClick={() => setIsOpen(false)}
          >
            {stepsRunning ? "Cancel" : "Close"}
          </button>
        </CloseButtonContainer>
      </Modal.Body>
    </Modal>
  );
};

export default RecorderTestConnectionModal;

const StepList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface StepContainerProps {
  status: "pending" | "checking" | "success" | "failure";
}

const StepContainer = styled.div<
  StepContainerProps & {
    isEven: boolean;
    isRunning: boolean;
    isFailed: boolean;
  }
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  /* Alternate background colors based on isEven prop */
  background-color: ${(props) => (props.isEven ? "#fff" : "#f2f2f2")};
  display: grid;
  grid-template-columns: auto 1fr; /* First column for the icon, second column for the content */
  grid-gap: 1.5rem;
  align-items: center; /* Vertically align the items */
  justify-items: end; /* Right-align the items within the grid */

  /* Set styles for the currently running step */
  ${(props) =>
    props.isRunning &&
    `
    background-color: #E5E9F0; /* Light blue background */
    border: 1px solid #49B6D7; /* Bright blue outline */
  `}
  /* Set styles for the failed step */
  ${(props) =>
    props.isFailed &&
    `
    border: 1px solid #F24965; /* Red outline for failed steps */
  `}

    /* Add padding to the right of the icons */
  > svg {
    margin-right: 1.5rem; /* Adjust the margin value as needed */
  }
`;

const StepLabel = styled.div<StepContainerProps>`
  /* Set the color to dark grey for pending steps */
  color: ${(props) => (props.status === "pending" ? "#777" : "inherit")};
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: ${(props) => (props.status === "checking" ? "bold" : "normal")};
`;

const StepMessage = styled.div`
  color: #333;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the container */
`;

const OverallStatusContainer = styled.div<{
  status: "checking" | "success" | "failure";
}>`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  /* Set overall status styles based on status prop */
  background-color: ${(props) =>
    props.status === "success"
      ? "#d6f7df"
      : props.status === "failure"
      ? "#ffebee"
      : "#e6f7ff"};
  border: ${(props) =>
    props.status === "success"
      ? "1px solid #5ca941"
      : props.status === "failure"
      ? "1px solid #f24965"
      : "1px solid #49b6d7"};
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

interface OverallStatusTextProps {
  status: "checking" | "success" | "failure";
}

const OverallStatusText = styled.span<OverallStatusTextProps>`
  color: ${(props) =>
    props.status === "success"
      ? "#5ca941"
      : props.status === "failure"
      ? "#f24965"
      : "#49b6d7"};
  font-weight: bold;
  margin-left: 1rem;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;
