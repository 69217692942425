App.factory("CustomerKeypadCustomGraphicApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/Customer/:customer_id/Keypad/CustomGraphics",
      {},
      {
        getAssociatedCustomGraphic: {
          method: "GET",
          params: { customer_id: "@customer_id" },
        },
        createCustomGraphicAssociation: {
          method: "POST",
          params: { customer_id: "@customer_id" },
        },
        deleteCustomGraphicAssociation: {
          method: "DELETE",
          params: { customer_id: "@customer_id" },
        },
      }
    );
  },
]);
