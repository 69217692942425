import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { NetworkOptionsWifiSecurity } from "securecom-graphql/client";
import { useHardwareModel, useHasWifiCommType } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsWifiSecurityField_networkOptions$key } from "./__generated__/NetworkOptionsWifiSecurityField_networkOptions.graphql";

export const networkOptionsWifiSecurityFieldId = () =>
  "network-options-wifi-security";

function NetworkOptionsWifiSecurityField() {
  const [{ wifiSecurity }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsWifiSecurityField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsWifiSecurityField_networkOptions on NetworkOptions {
          wifiSecurity
        }
      `
    );

  const fieldId = networkOptionsWifiSecurityFieldId();
  const hasWifi = useHasWifiCommType();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);
  const disabled = !isXr && !hasWifi;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Wi-Fi Security"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        disabled={disabled}
        value={wifiSecurity}
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "wifiSecurity");
          });
        }}
      >
        <Select.Option value={NetworkOptionsWifiSecurity.WEP}>
          WEP
        </Select.Option>
        <Select.Option value={NetworkOptionsWifiSecurity.WPA}>
          WPA
        </Select.Option>
        <Select.Option value={NetworkOptionsWifiSecurity.NONE}>
          None
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsWifiSecurityField;
