/**
 * @generated SignedSource<<da0411e9894d6c1c9826c2cc3fb0942a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CaptureFormat = "CID" | "DMP" | "FOUR2" | "SIA";
export type EolResistorValue = "FOUR_POINT_SEVEN_THOUSAND" | "ONE_THOUSAND" | "THREE_POINT_THREE_THOUSAND" | "TWO_POINT_TWO_THOUSAND";
export type KeypadInput = "DSC" | "ECP" | "NONE";
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XTSystemOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly systemOptions: {
      readonly __typename: "XtSystemOptions";
      readonly armDisarmActivityDays?: number;
      readonly captureFormat?: CaptureFormat | null;
      readonly celsius?: boolean;
      readonly closingCheck?: boolean;
      readonly closingCode?: boolean;
      readonly crossZoneTime?: number;
      readonly detectJam?: boolean;
      readonly displayTime?: boolean;
      readonly ecpPartition?: number | null;
      readonly enableKeypadPanicKeys?: boolean;
      readonly entryDelay1?: number;
      readonly entryDelay2?: number;
      readonly eolResistorValue?: EolResistorValue;
      readonly exitDelay?: number;
      readonly hoursFromGMT?: number;
      readonly houseCode?: string;
      readonly id: string;
      readonly iso2CountryCode?: string;
      readonly keypadInput?: KeypadInput | null;
      readonly occupiedPremises?: boolean;
      readonly powerFailDelay?: number;
      readonly primaryProgrammingLanguage?: PanelProgrammingLanguage | null;
      readonly primaryUserLanguage?: PanelProgrammingLanguage | null;
      readonly resetSwingerBypass?: boolean;
      readonly secondaryProgrammingLanguage?: PanelProgrammingLanguage | null;
      readonly secondaryUserLanguage?: PanelProgrammingLanguage | null;
      readonly swingerBypassTrips?: number;
      readonly systemType?: SystemType;
      readonly timeChange?: boolean;
      readonly useBuiltIn1100Wireless?: boolean;
      readonly useFalseAlarmQuestion?: boolean;
      readonly weatherPostalCode?: string;
      readonly weatherZipCode?: string;
      readonly wirelessAudibles?: WirelessAudibles | null;
      readonly wirelessEncryption1100?: WirelessEncryption1100;
      readonly wirelessEncryption1100Passphrase?: string;
      readonly zoneActivityHours?: number;
    };
  };
  readonly " $fragmentType": "XTSystemOptionsProgrammingConceptFormInline_controlSystem";
};
export type XTSystemOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTSystemOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSystemOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTSystemOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "6d6f048ccc19e163d3a6ca075a448155";

export default node;
