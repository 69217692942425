/**
 * @generated SignedSource<<ba580d27df523a95d683f7780411f4ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationVplexSerialNumberField_zone$data = {
  readonly competitorWireless: boolean | null;
  readonly number: string;
  readonly serialNumber: string;
  readonly wireless: boolean;
  readonly " $fragmentType": "ZoneInformationVplexSerialNumberField_zone";
};
export type ZoneInformationVplexSerialNumberField_zone$key = {
  readonly " $data"?: ZoneInformationVplexSerialNumberField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationVplexSerialNumberField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationVplexSerialNumberField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competitorWireless",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "96a38b2a970e4d6b0c8765b0b6509549";

export default node;
