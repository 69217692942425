/**
 * @generated SignedSource<<e69e32c32f0fa6738782b71a2c432b18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationSiteCode6Field_deviceInformation$data = {
  readonly device734?: {
    readonly enforceSiteCode: boolean | null;
    readonly siteCode6: string | null;
  } | null;
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly number: number;
  readonly " $fragmentType": "DeviceInformationSiteCode6Field_deviceInformation";
};
export type DeviceInformationSiteCode6Field_deviceInformation$key = {
  readonly " $data"?: DeviceInformationSiteCode6Field_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationSiteCode6Field_deviceInformation">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Device734",
    "kind": "LinkedField",
    "name": "device734",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siteCode6",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enforceSiteCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationSiteCode6Field_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrDeviceInformation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75DeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};
})();

(node as any).hash = "04c13562d16d2461e5331f4c8ab8ca36";

export default node;
