App.factory("TechSupportService", [
  "$q",
  "TechSupportApi",
  function ($q, TechSupportApi) {
    return {
      getErrorTypes: function () {
        var deferred = $q.defer();
        var _this = this;
        TechSupportApi.getErrorTypes(
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      getError: function (error_id) {
        var deferred = $q.defer();
        var _this = this;
        TechSupportApi.getErrorInformation(
          { error_id: error_id },
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      getErrorsByType: function (type_id) {
        var deferred = $q.defer();
        var _this = this;
        TechSupportApi.getErrorMessagesByType(
          { type_id: type_id },
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
