/**
 * @generated SignedSource<<32c32114c8db164ed38217a79c64abe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommPathSecondLinePrefixFor893AField_communicationPath$data = {
  readonly number: number;
  readonly secondLinePrefixFor893A: string;
  readonly use893A: boolean;
  readonly " $fragmentType": "CommPathSecondLinePrefixFor893AField_communicationPath";
};
export type CommPathSecondLinePrefixFor893AField_communicationPath$key = {
  readonly " $data"?: CommPathSecondLinePrefixFor893AField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathSecondLinePrefixFor893AField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathSecondLinePrefixFor893AField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "use893A",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondLinePrefixFor893A",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "3b3b47123d7fa78aa73bae15b20801aa";

export default node;
