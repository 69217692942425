import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsUseIpv6Field_networkOptions$key } from "./__generated__/NetworkOptionsUseIpv6Field_networkOptions.graphql";

export const networkOptionsUseIpv6FieldId = () => "network-options-use-ipv6";

function NetworkOptionsUseIpv6Field() {
  const [{ ipv6Enabled, ipv6Prefix }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsUseIpv6Field_networkOptions$key>(
      graphql`
        fragment NetworkOptionsUseIpv6Field_networkOptions on NetworkOptions {
          ipv6Enabled
          ipv6Prefix
        }
      `
    );

  const fieldId = networkOptionsUseIpv6FieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Use IPv6"
      tooltip="Enable to use a local IPv6 Address."
    >
      <Switch
        label="Use IPv6"
        id={fieldId}
        checked={ipv6Enabled}
        onChange={() => {
          updateNetworkOptions((recordProxy) => {
            const enabled = !ipv6Enabled;
            recordProxy.setValue(enabled, "ipv6Enabled");
            if (enabled) {
              recordProxy.setValue(true, "dhcpEnabled");
            }

            if (enabled && Number(ipv6Prefix) === 0) {
              recordProxy.setValue("064", "ipv6Prefix");
            } else {
              recordProxy.setValue("000", "ipv6Prefix");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsUseIpv6Field;
