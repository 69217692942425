/**
 * @ngdoc service
 * @name App.factory:PanelTest
 *
 * @description
 * Object used to store information about panel tests (comm, walk, Z-Wave)
 *
 */
App.factory("PanelTest", [
  "$q",
  "$rootScope",
  "$filter",
  "$timeout",
  "$interval",
  "PROPS",
  "PanelTestService",
  "PANEL_TEST_STATUSES",
  "PANEL_TEST_TYPES",
  "COMM_TEST_TYPES",
  "UserService",
  function (
    $q,
    $rootScope,
    $filter,
    $timeout,
    $interval,
    PROPS,
    PanelTestService,
    PANEL_TEST_STATUSES,
    PANEL_TEST_TYPES,
    COMM_TEST_TYPES,
    UserService
  ) {
    var PanelTest = function (dealerId, panelId, testType, autoUpdate) {
      var _this = this;

      angular.extend(this, {
        // Timer used to get status updates
        updateTimer: undefined,

        // The panel id that the test is running on
        panelId: "",

        // The dealer id that the panel is associated with
        dealerId: "",

        // Test type of test we're running
        testType: "",

        // The current status of the test
        status: PANEL_TEST_STATUSES.NEW,

        // A human readable status message
        statusMessage: "",

        // The current status code from VK
        statusCode: "",

        // The overall result of the test
        testPassed: undefined,

        // The time in milliseconds to wait between checking for updates
        updateTimerInterval: 3000,

        // The consecutive number of times we've failed to retrieve test updates
        refreshFailCount: 0,

        // The maximum number of times we can fail to get updates before aborting
        refreshFailThreshold: 10,

        // Zones for walk tests
        zones: [],

        // Communication paths for comm tests
        commPaths: [],

        // Used to store the state of a multi part communication test
        commTestMultiState: undefined,

        // Z-Wave devices
        devices: [],

        /**
         * @ngdoc object
         * @name method:get
         * @methodOf App.factory:PanelTest
         *
         * @description
         * Gets any matching PanelTest from the list of tests in PanelTestService
         *
         * @params {string} dealerId The dealer ID that is associated with the panel
         * @params {string} panelId The panel ID that the test is running on
         * @params {string} testType PANEL_TEST_TYPE The type of test we're looking for
         *
         */
        init: function (dealerId, panelId, testType, autoUpdate) {
          this.dealerId = dealerId;
          this.panelId = panelId;
          this.testType = testType;

          // Create two comm objects for holding communication test results
          this.commPaths.push({ connectionType: "N", status: 0, isNew: true });
          this.commPaths.push({ connectionType: "C", status: 0, isNew: true });

          if (autoUpdate) {
            this.startUpdateTimer();
          }
        },

        /**
         * Gets an updated status for the panel test
         */
        getStatus: function () {
          if (_this.status != PANEL_TEST_STATUSES.RUNNING) {
            return;
          }
          PanelTestService.getTestEvents(
            _this.dealerId,
            _this.panelId,
            _this.testType
          ).then(
            function (data) {
              // On successful update, reset our fail count
              self.refreshFailCount = 0;

              // Loop through all received events, and parse the results
              angular.forEach(data, function (event) {
                _this.parseEventDetails(event);
                _this.updateTestState();
                _this.isTestComplete();
              });
            },
            function (error) {
              // Increment our failed count
              _this.refreshFailCount++;

              if (_this.refreshFailCount >= _this.refreshFailThreshold) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Failed to get updated .",
                  json: data.error,
                });
                _this.stopUpdateTimer();
              }
            }
          );
        },

        /**
         * Parse test events received from the Dealer API
         * @param event {Object} An event object from Dealer API
         */
        parseEventDetails: function (event) {
          var status, device, eventNumber;

          // Parse the results based on test type
          switch (_this.testType) {
            case PANEL_TEST_TYPES.COMM_TEST:
              var commType = event.panel_statistics[0].connection_type;
              if (commType == "W") {
                commType = "N";
              } // Treat Network and Wi-Fi the same
              status = event.panel_statistics[0].status;
              var commTest = $filter("filter")(
                _this.commPaths,
                { connectionType: commType },
                true
              );
              if (commTest.length > 0) {
                commTest[0].status = parseInt(status);
              }
              break;
            case PANEL_TEST_TYPES.SENSOR_TEST:
              angular.forEach(
                event.event_details,
                function (each_event_detail) {
                  eventNumber = $filter("zpad")(each_event_detail.number, 3);
                  var zonesInEvent = $filter("filter")(
                    _this.zones,
                    { number: eventNumber },
                    true
                  );
                  angular.forEach(zonesInEvent, function (zone) {
                    if (zonesInEvent.length > 0) {
                      zone.checkedIn = true;
                    }
                  });
                }
              );
              break;
            case PANEL_TEST_TYPES.WIRELESS_TEST:
              angular.forEach(
                event.event_details,
                function (each_event_detail) {
                  eventNumber = $filter("zpad")(each_event_detail.number, 3);
                  var zonesInEvent = $filter("filter")(
                    _this.zones,
                    { number: eventNumber },
                    true
                  );
                  angular.forEach(zonesInEvent, function (zone) {
                    if (zonesInEvent.length > 0) {
                      zone.checkedIn = true;
                    }
                  });
                }
              );
              break;
            case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
              eventNumber = $filter("zpad")(event.event_details[0].number, 3);
              var requests = event.panel_statistics[0].max_count;
              var replies = event.panel_statistics[0].actual_count;
              device = $filter("filter")(
                _this.devices,
                { number: eventNumber },
                true
              );
              if (device.length > 0) {
                device[0].requests = requests;
                device[0].replies = replies;
              }
              break;
            case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
              eventNumber = $filter("zpad")(event.event_details[0].number, 3);
              status = event.panel_statistics[0].status;
              device = $filter("filter")(
                _this.devices,
                { number: eventNumber },
                true
              );
              if (device.length > 0) {
                device[0].status = status;
              }
              break;
            default:
              break;
          }
        },

        /**
         * Update the overall test state based on individual item results
         */
        updateTestState: function () {
          var testSuccessful = false;
          switch (_this.testType) {
            // COMMUNICATION TEST
            case PANEL_TEST_TYPES.COMM_TEST:
              // Check to see if we're running a multi part comm test
              switch (this.commTestMultiState) {
                case "network":
                  this.commTestMultiState = "cellular";
                  this.startCommTest(COMM_TEST_TYPES.Cellular, true);
                  break;
                case "cellular":
                default:
                  this.commTestMultiState = undefined;
                  break;
              }
              var failedNetworkTests = $filter("filter")(
                _this.commPaths,
                function (value, index, array) {
                  var successState = 4;
                  // Determine what a successful state is based on connection type
                  value.connectionType == "N"
                    ? (successState = 4)
                    : (successState = 6);
                  // We want to know if any comm test has completed successfully in order to show/hide the success icon
                  if (value.status == successState) {
                    testSuccessful = true;
                  }
                  // If we weren't testing this comm path, return false to not add this test to the failed tests list
                  if (!value.testing) {
                    return false;
                  }
                  return value.status > successState;
                }
              );
              _this.testPassed = !(failedNetworkTests.length > 0);
              if (_this.testPassed && !testSuccessful) {
                _this.testPassed = undefined;
              }
              break;
            // SENSOR TEST
            case PANEL_TEST_TYPES.SENSOR_TEST:
              var failedZones = $filter("filter")(_this.zones, {
                checkedIn: false,
              });
              _this.testPassed = !(failedZones.length > 0);
              break;
            case PANEL_TEST_TYPES.WIRELESS_TEST:
              var failedZones = $filter("filter")(_this.zones, {
                checkedIn: false,
              });
              _this.testPassed = !(failedZones.length > 0);
              break;
            // Z-WAVE DIAGNOSTICS TEST
            case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
              var failedDevices = $filter("filter")(
                _this.devices,
                function (value, index, array) {
                  if (value.replies == 10) {
                    testSuccessful = true;
                  }
                  if (value.isNew || value.requests == 0) {
                    return false;
                  }
                  return value.replies < 10;
                }
              );
              _this.testPassed = !(failedDevices.length > 0);
              if (_this.testPassed && !testSuccessful) {
                _this.testPassed = undefined;
              }
              break;
            // Z-WAVE OPTIMIZATION
            case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
              var failedDevices = $filter("filter")(
                _this.devices,
                { status: "F" },
                true
              );
              _this.testPassed = !(failedDevices.length > 0);
              break;
            default:
              break;
          }
        },

        /**
         * Determines if the current test is finished running
         */
        isTestComplete: function () {
          var complete = true;

          switch (_this.testType) {
            case PANEL_TEST_TYPES.COMM_TEST:
              angular.forEach(_this.commPaths, function (comm) {
                if (_this.commTestMultiState !== undefined) {
                  complete = false;
                }
                if (
                  comm.testing &&
                  comm.connectionType === "N" &&
                  comm.status < 4
                ) {
                  complete = false;
                }
                if (
                  comm.testing &&
                  comm.connectionType === "C" &&
                  comm.status < 6
                ) {
                  complete = false;
                }
              });
              break;
            case PANEL_TEST_TYPES.SENSOR_TEST:
              complete = false;
              break;
            case PANEL_TEST_TYPES.WIRELESS_TEST:
              angular.forEach(_this.zones, function (sensor) {
                if (sensor.checkedIn === false) {
                  complete = false;
                }
              });
              break;
            case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
              angular.forEach(_this.devices, function (device) {
                if (device.testing && device.replies < 10) {
                  complete = false;
                }
              });
              break;
            case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
              angular.forEach(_this.devices, function (device) {
                if (device.status === "") {
                  complete = false;
                }
              });
              break;
            default:
              break;
          }

          if (complete) {
            _this.endTest();
          }
        },

        /**
         * Start checking for panel test updates
         */
        startUpdateTimer: function () {
          if (!this.updateTimer) {
            this.updateTimer = $interval(
              _this.getStatus,
              _this.updateTimerInterval
            );
          }
        },

        /**
         * Stop checking for panel test updates
         */
        stopUpdateTimer: function () {
          $interval.cancel(this.updateTimer);
          this.updateTimer = undefined;
        },

        /**
         * Schedules a panel test for the current panel
         * Note: Instead of calling this function directly, use one of the helper functions (startCommTest, etc)
         * @param {object} requestBody: The request body to send
         * @param {boolean} autoUpdate: Should we check for updates automatically
         * @returns {object} A promise that resolves either to the current test status or the returned error
         */
        startTest: function (requestBody, autoUpdate, requestJobStatus = true) {
          var deferred = $q.defer();
          _this.statusMessage = "";
          _this.statusCode = "";

          if (_this.commTestMultiState != "cellular") {
            _this.status = PANEL_TEST_STATUSES.NEW;
          }
          PanelTestService.callTestAPI(
            _this.panelId,
            _this.testType,
            "begin",
            requestBody,
            requestJobStatus
          ).then(
            function (data) {
              // Only continue if the user hasn't cancelled the job
              if (_this.status == PANEL_TEST_STATUSES.CANCELLED) {
                return;
              }
              // Store the status message from VK
              if (data.job && data.job.details) {
                // Update our message with the returned status from the API. Hide the Remote can remain connected message.
                var msg = data.job.details.message;
                msg == "Remote can remain connected."
                  ? (_this.statusMessage = "")
                  : (_this.statusMessage = msg);
                _this.statusCode = data.job.details.code;
              }

              _this.status = PANEL_TEST_STATUSES.RUNNING;
              if (autoUpdate) {
                _this.startUpdateTimer();
              }
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },

        /**
         * Schedules a given walk test for the current panel
         * @param {string} walkTestType: The type of walk test to schedule (WALK_TEST_TYPE)
         * @param {boolean} autoUpdate: Should we check for updates automatically
         * @returns {object} A promise that resolves either to the current test status or the returned error
         */
        startWalkTest: function (
          walkTestType,
          autoUpdate,
          requestJobStatus = true
        ) {
          var deferred = $q.defer();

          var requestBody = {};
          requestBody.tipe = walkTestType;
          requestBody.burg_zones = "Y";
          requestBody.fire_zones = "Y";
          requestBody.panel_bell = "Y";
          requestBody.panic_zns = "Y";
          requestBody.supv_zones = "Y";
          requestBody.panel_bell = "N";
          if (UserService.controlSystem.panels[0].hardware_family === "XF6") {
            requestBody.co_zones = "Y";
          }

          _this.startTest(requestBody, autoUpdate, requestJobStatus).then(
            function (data) {
              // Reset our zone test states
              angular.forEach(_this.zones, function (sensor) {
                sensor.checkedIn = false;
              });
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },

        /**
         * Schedules a given comm test for the current panel
         * @param {string} commTestPath: The type of comm test to schedule (COMM_TEST_TYPES)
         * @param {boolean} autoUpdate: Should we check for updates automatically
         * @returns {object} A promise that resolves either to the current test status or the returned error
         */
        startCommTest: function (commTestPath, autoUpdate) {
          var deferred = $q.defer();
          var requestBody = {};

          // If we're running a multi part comm test, mark multi part as true and begin the network test
          if (commTestPath == COMM_TEST_TYPES.All) {
            this.commTestMultiState = "network";
            commTestPath = COMM_TEST_TYPES.Network;
          }

          requestBody.path_no_tt = commTestPath;

          _this.startTest(requestBody, autoUpdate).then(
            function (data) {
              // Reset our comm test states
              angular.forEach(_this.commPaths, function (comm) {
                if (comm.testing && _this.commTestMultiState != "cellular") {
                  // Reset the status and set isNew to false.
                  comm.status = 0;
                  comm.isNew = false;
                }
              });
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },

        /**
         * Schedules the Z-Wave optimize process for the current panel
         * @param {boolean} autoUpdate: Should we check for updates automatically
         * @returns {object} A promise that resolves either to the current process status or the returned error
         */
        startZWaveOptimize: function (autoUpdate) {
          var deferred = $q.defer();

          _this.startTest(null, autoUpdate).then(
            function (data) {
              // Reset our Z-Wave optimize status
              angular.forEach(_this.devices, function (device) {
                device.status = "";
              });
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },

        /**
         * Schedules a given Z-Wave diagnostics test process for the current panel
         * @param {boolean} autoUpdate: Should we check for updates automatically
         * @returns {object} A promise that resolves either to the current process status or the returned error
         */
        startZWaveTest: function (autoUpdate) {
          var deferred = $q.defer();
          var requestBody = {};
          requestBody.device_list = [];

          // get a list of selected Z-Wave device numbers
          var devices = $filter("filter")(_this.devices, { testing: true });
          angular.forEach(devices, function (device) {
            requestBody.device_list.push(device.number);
          });

          _this.startTest(requestBody, autoUpdate).then(
            function (data) {
              // Reset our Z-Wave optimize status
              angular.forEach(_this.devices, function (device) {
                if (device.testing) {
                  // Reset the status and set isNew to false.
                  device.requests = 0;
                  device.replies = 0;
                  device.isNew = false;
                }
              });
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },

        /**
         * Ends any currently running panel test
         */
        endTest: function (commTestPath, type) {
          var deferred = $q.defer();
          _this.statusMessage = "";
          _this.statusCode = "";

          var requestBody = {};
          if (commTestPath && angular.isDefined(commTestPath)) {
            requestBody.path_no_tt = commTestPath;
          }

          if (type) {
            requestBody.tipe = type;
          }

          PanelTestService.callTestAPI(
            _this.panelId,
            _this.testType,
            "end",
            requestBody
          ).then(
            function (data) {
              // Store the status message from VK
              if (data.job && data.job.details) {
                // Update our message with the returned status from the API. Hide the Remote can remain connected message.
                var msg = data.job.details.message;
                msg == "Remote can remain connected."
                  ? (_this.statusMessage = "")
                  : (_this.statusMessage = msg);
                _this.statusCode = data.job.details.code;
                _this.updateTestState();
              }

              _this.status = PANEL_TEST_STATUSES.STOPPED;
              _this.stopUpdateTimer();
              deferred.resolve(data);
            },
            function (error) {
              _this.stopUpdateTimer();
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },
      });

      // Initialize the panel test object
      this.init(dealerId, panelId, testType, autoUpdate);
    };

    return PanelTest;
  },
]);
