import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { outputInformationNameFieldId } from "components/FullProgramming/common/OutputInformationFields/OutputInformationNameField";
import {
  outputInformationListItemTemplateId,
  outputInformationNumberFieldId,
} from "components/FullProgramming/common/OutputInformationFields/OutputInformationNumberField";
import { outputInformationOutputSupervisionFieldId } from "components/FullProgramming/common/OutputInformationFields/OutputInformationOutputSupervisionField";
import { outputInformationRealTimeStatusFieldId } from "components/FullProgramming/common/OutputInformationFields/OutputInformationRealTimeStatusField";
import { outputInformationTripWithPanelBellFieldId } from "components/FullProgramming/common/OutputInformationFields/OutputInformationTripWithPanelBellField";
import { XTOutputInformationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XTFullProgramming/XTOutputInformationProgrammingConceptForm/__generated__/XTOutputInformationProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  getOutputState,
  getState as getOutputInformationsState,
} from "../../../XTFullProgramming/XTOutputInformationProgrammingConceptForm";
import { ProgrammingTemplateOutputInformationInput } from "../Create/__generated__/XTTemplateSaveMutation.graphql";
import { XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        outputInformations {
          id
          included
          number
          name {
            included
            data
          }
          outputSupervision {
            included
            data
          }
          tripWithPanelBell {
            included
            data
          }
          realTimeStatus {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XTOutputInformationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateOutputInformationInput[] => {
  const {
    panel: { outputInformations },
  } = getOutputInformationsState(controlSystem);

  return outputInformations
    ? outputInformations
        .filter(isNotNullOrUndefined)
        .map(getOutputState)
        .map((output) => ({
          included: includedFields.has(
            outputInformationListItemTemplateId(String(output.number))
          ),
          number: output.number,
          name: {
            included: includedFields.has(
              outputInformationNameFieldId(String(output.number))
            ),
            data: output.name,
          },
          outputSupervision: {
            included: includedFields.has(
              outputInformationOutputSupervisionFieldId(String(output.number))
            ),
            data: output.outputSupervision,
          },
          tripWithPanelBell: {
            included: includedFields.has(
              outputInformationTripWithPanelBellFieldId(String(output.number))
            ),
            data: output.tripWithPanelBell,
          },
          realTimeStatus: {
            included: includedFields.has(
              outputInformationRealTimeStatusFieldId(String(output.number))
            ),
            data: output.realTimeStatus,
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { outputInformations } = getState(template);

  if (!outputInformations) {
    return;
  }

  outputInformations.filter(isNotNullOrUndefined).forEach((output) => {
    const number = String(output.number);

    if (output.included) {
      setIncludedFields(setToggle(outputInformationListItemTemplateId(number)));
      setIncludedFields(setToggle(outputInformationNumberFieldId(number)));
    }
    if (output.name?.included) {
      setIncludedFields(setToggle(outputInformationNameFieldId(number)));
    }
    if (output.outputSupervision?.included) {
      setIncludedFields(
        setToggle(outputInformationOutputSupervisionFieldId(number))
      );
    }
    if (output.tripWithPanelBell?.included) {
      setIncludedFields(
        setToggle(outputInformationTripWithPanelBellFieldId(number))
      );
    }
    if (output.realTimeStatus?.included) {
      setIncludedFields(
        setToggle(outputInformationRealTimeStatusFieldId(number))
      );
    }
  });
};
