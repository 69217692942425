import graphql from "babel-plugin-relay/macro";
import PhoneNumberInput from "components/FullProgramming/common/PhoneNumberInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathSecondPhoneNumberField_communicationPath$key } from "./__generated__/CommPathSecondPhoneNumberField_communicationPath.graphql";

export const commPathSecondPhoneNumberFieldId = (number: string) =>
  `comm-path-second-phone-number-${number}`;

function CommPathSecondPhoneNumberField() {
  const [{ number, secondPhone, commType }, updateCommPath] =
    useCommPathFragment<CommPathSecondPhoneNumberField_communicationPath$key>(
      graphql`
        fragment CommPathSecondPhoneNumberField_communicationPath on CommunicationPath {
          number
          secondPhone
          commType
        }
      `
    );

  const fieldId = commPathSecondPhoneNumberFieldId(String(number));
  const disabled = ["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Second Phone Number"
      disabled={disabled}
    >
      <PhoneNumberInput
        id={fieldId}
        maxLength={32}
        value={secondPhone.toString()}
        autoComplete="tel"
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "secondPhone");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathSecondPhoneNumberField;
