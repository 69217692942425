import graphql from "babel-plugin-relay/macro";
import { Concept } from "components/FullProgramming/common/FullProgrammingForm";
import * as React from "react";
import { fetchQuery, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  PanelHardwareModel,
  toControlSystemId,
} from "securecom-graphql/client";
import FullProgrammingVersionEntryPoint from "../../FullProgramming/common/FullProgrammingVersionEntryPoint";
import {
  createConcepts,
  xfFullProgrammingControlSystem,
  xfFullProgrammingInlineControlSystem,
} from "../../FullProgramming/XFFullProgramming";
import { XFTemplateContainerTemplateQuery } from "./__generated__/XFTemplateContainerTemplateQuery.graphql";

export const XFTemplateContainer: React.FC<{
  dealerId: string;
  hardwareModel: PanelHardwareModel;
  softwareVersion: string;
}> = ({ dealerId, hardwareModel, softwareVersion }) => {
  const concepts = React.useMemo(
    () =>
      createConcepts({
        softwareVersion: Number(softwareVersion),
        supportsCustomCardFormats: false,
      }),
    [softwareVersion]
  );

  return (
    <FullProgrammingVersionEntryPoint
      systemName={`Default ${hardwareModel}`}
      concepts={concepts}
      gqlQuery={graphql`
        query XFTemplateContainerQuery(
          $dealerId: ID!
          $hardwareModel: PanelHardwareModel!
          $softwareVersion: String!
        ) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: defaultControlSystem(
            hardwareModel: $hardwareModel
            softwareVersion: $softwareVersion
            dealerId: $dealerId
          ) {
            id
            name
            panel {
              hardwareModel
            }
            customer {
              id
            }
            ...XFFullProgramming_controlSystem
            ...NetworkOptionsDhcpEnabledField_controlSystem
            ...XFSystemOptionsProgrammingConceptFormInline_controlSystem
            ...XFZoneInformationProgrammingConceptFormInline_controlSystem
            ...XFCommunicationProgrammingConceptFormInline_controlSystem
            ...XFDeviceSetupProgrammingConceptFormInline_controlSystem
            ...XFOutputInformationProgrammingConceptFormInline_controlSystem
            ...XFOutputGroupsProgrammingConceptFormInline_controlSystem
          }
          controlSystemInline: defaultControlSystem(
            hardwareModel: $hardwareModel
            softwareVersion: $softwareVersion
            dealerId: $dealerId
          ) {
            id
            ...XFFullProgrammingInline_controlSystem
          }
        }
      `}
      gqlQueryVariables={{
        hardwareModel,
        softwareVersion,
        dealerId,
      }}
      gqlFormControlSystemFragment={xfFullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={xfFullProgrammingInlineControlSystem}
      applyTemplate={applyTemplate(toControlSystemId(-1).toString(), concepts)}
    />
  );
};

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<XFTemplateContainerTemplateQuery>(
    environment,
    graphql`
      query XFTemplateContainerTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on XfProgrammingTemplateConcepts {
                ...XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFSystemOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFLockoutCodeProgrammingConceptFormInline_xfProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
