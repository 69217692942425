/**
 * @ngdoc service
 * @name App.factory:CustomerScoreAPI
 *
 * @description
 * API factory for Customer Score
 *
 */
App.factory("CustomerScoreAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/dealers/:dealer_id/customerscore/:customer_id",
      {},
      {
        getScore: {
          method: "GET",
          params: { customer_id: "@customer_id", dealer_id: "@dealer_id" },
        },
      }
    );
  },
]);
