import graphql from "babel-plugin-relay/macro";
import { useResetControlSystemIntegrations } from "components/StatusBadge";
import { useShowAlert } from "contexts/AlertsContext";
import { difference } from "ramda";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { idAsString } from "securecom-graphql/client";
import ReceiverFormModal from "./ReceiverFormModal";
import { ReceiverFormState, reducer } from "./ReceiverFormReducer";
import { NewReceiverFormAddReceiverMutation } from "./__generated__/NewReceiverFormAddReceiverMutation.graphql";
import {
  NewReceiverFormModalDealer_dealer$data,
  NewReceiverFormModalDealer_dealer$key,
} from "./__generated__/NewReceiverFormModalDealer_dealer.graphql";

const initState = ({
  dealer,
  initialNumber,
}: {
  dealer: NewReceiverFormModalDealer_dealer$data;
  initialNumber?: number;
}): ReceiverFormState => {
  const nextNumber = (() => {
    const currentHighest = Math.max(
      ...dealer.receivers.nodes.map((receiver) => receiver.number)
    );
    const validRange = Array.from(Array(100).keys());
    const currentNumbers = dealer.receivers.nodes.map(
      (receiver) => receiver.number
    );
    return (
      difference(validRange, currentNumbers).find(
        (number) => number > currentHighest && number < 100
      ) ??
      difference(validRange, currentNumbers)[1] ??
      null
    ); // Returns highest available number
  })();

  return {
    id: null,
    automationPrefix: "",
    number: initialNumber ?? nextNumber,
    ipAddress: "",
    port: null,
    description: "",
    centralStationAutomationIntegrationId:
      dealer.centralStationAutomationIntegrations[0]?.id ?? null,
    automationPrefixBlurred: false,
    numberBlurred: false,
    ipAddressBlurred: false,
    portBlurred: false,
    cancelButtonClicked: false,
  };
};

const addReceiverMutation = graphql`
  mutation NewReceiverFormAddReceiverMutation($receiver: AddReceiverInput!) {
    addReceiver(receiver: $receiver) {
      ... on AddReceiverErrorPayload {
        __typename
        type
      }
      ... on AddReceiverSuccessPayload {
        __typename
        dealer {
          id
          receivers(sort: { keys: ["number"], order: ASC }) {
            nodes {
              ...ReceiverTableReceiver_receiver
            }
          }
          uninitializedReceiverNumbers
        }
      }
    }
  }
`;

export const NewReceiverForm: React.FC<
  React.PropsWithChildren<{
    dealerRef: NewReceiverFormModalDealer_dealer$key;
    initialNumber?: number;
    onCancel: () => void;
  }>
> = ({ dealerRef, initialNumber, onCancel }) => {
  const dealer = useFragment(
    graphql`
      fragment NewReceiverFormModalDealer_dealer on Dealer {
        id
        receivers(sort: { keys: ["number"], order: ASC }) {
          nodes {
            number
          }
        }
        centralStationAutomationIntegrations {
          id
          name
        }
        ...ReceiverFormModalDealer_dealer
      }
    `,
    dealerRef
  );

  const [state, dispatch] = React.useReducer(
    reducer,
    { dealer, initialNumber },
    initState
  );
  const [addReceiver, isAddingReceiver] =
    useMutation<NewReceiverFormAddReceiverMutation>(addReceiverMutation);
  const showAlert = useShowAlert();

  const resetControlSystemIntegrations = useResetControlSystemIntegrations();

  return (
    <ReceiverFormModal
      state={state}
      dispatch={dispatch}
      onCancel={onCancel}
      dealerRef={dealer}
      isSaving={() => isAddingReceiver}
      onSave={() => {
        if (
          state.ipAddress &&
          state.port &&
          !isNaN(state.port) &&
          state.automationPrefix &&
          state.number
        ) {
          addReceiver({
            variables: {
              receiver: {
                ip: state.ipAddress,
                port: state.port,
                automationPrefix: state.automationPrefix,
                number: state.number,
                dealerId: idAsString(dealer.id),
                description: state.description,
                centralStationAutomationIntegrationId:
                  state.centralStationAutomationIntegrationId,
              },
            },
            onCompleted: (response) => {
              if (
                response.addReceiver.__typename === "AddReceiverSuccessPayload"
              ) {
                showAlert({
                  type: "success",
                  text: "Created Receiver",
                });
                onCancel();
                resetControlSystemIntegrations();
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to create receiver",
                });
              }
            },
          });
        }
      }}
    />
  );
};
export default NewReceiverForm;
