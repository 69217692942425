/**
 * @generated SignedSource<<827478a6f325e6cc462fc2d1c7b072bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT30SystemOptions_systemOptions$data = {
  readonly supportsEolResistorField?: boolean;
  readonly " $fragmentType": "XT30SystemOptions_systemOptions";
};
export type XT30SystemOptions_systemOptions$key = {
  readonly " $data"?: XT30SystemOptions_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT30SystemOptions_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT30SystemOptions_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportsEolResistorField",
          "storageKey": null
        }
      ],
      "type": "XtSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "20937811eaeecc7880108abbae2c5fe2";

export default node;
