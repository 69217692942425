import graphql from "babel-plugin-relay/macro";
import { useSetHasChanges } from "contexts/HasChangesContext";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType } from "react-relay/relay-hooks/helpers";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import { Dealer, ProgrammingTemplate } from "securecom-graphql/client";

const DealerContext = React.createContext<any>({});

export type DealerRecordProxyType = Dealer;

export function DealerContextProvider(props: {
  dealer: any;
  children: React.ReactNode;
}) {
  return (
    <DealerContext.Provider value={props.dealer}>
      {props.children}
    </DealerContext.Provider>
  );
}

export function useDealerFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const dealer = React.useContext(DealerContext);

  const data = useFragment(fragmentInput, dealer as TKey);

  const { id } = useFragment(
    graphql`
      fragment DealerContext_dealer on Dealer {
        id
      }
    `,
    dealer as any
  );

  const setHasChanges = useSetHasChanges();

  const update = (
    updater: (dealer: RecordProxy<DealerRecordProxyType>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<DealerRecordProxyType>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
    setHasChanges(true);
  };

  return [data, update] as const;
}

export const updateDealer = (
  store: RecordSourceProxy,
  dealerId: string,
  updater: (recordProxy: RecordProxy<DealerRecordProxyType>) => void
) => {
  const dealer = store.get<DealerRecordProxyType>(dealerId);
  if (dealer) {
    updater(dealer);
  }
};

export const updateTemplate = (
  store: RecordSourceProxy,
  dealerId: string,
  updater: (recordProxy: RecordProxy<ProgrammingTemplate>) => void
) => {
  const newTemplate = store
    .get<Dealer>(dealerId)
    ?.getLinkedRecord<ProgrammingTemplate>("newTemplate");
  if (newTemplate) {
    updater(newTemplate);
  }
};
