/**
 * @generated SignedSource<<789eb449068f012a42ddb2fa7e68f8d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTDeviceSetupProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly axBusDoorDevicesMax: number;
    readonly deviceInformations: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XTDeviceSetupProgrammingConceptFormInline_deviceInformation">;
    }>;
    readonly deviceNumberRange: ReadonlyArray<number>;
    readonly id: string;
    readonly keypadBusDevicesMax: number;
    readonly totalDevicesMax: number;
  };
  readonly " $fragmentType": "XTDeviceSetupProgrammingConceptFormInline_controlSystem";
};
export type XTDeviceSetupProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTDeviceSetupProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTDeviceSetupProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTDeviceSetupProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "843d0af4fe1430cb09cbfd43183aca92";

export default node;
