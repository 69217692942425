import graphql from "babel-plugin-relay/macro";
import DeviceInformationDeviceTypeField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceTypeField";
import * as React from "react";
import { useFragment } from "react-relay";
import { DeviceInformationContextProvider } from "../../common/DeviceInformationFields/DeviceInformationContext";
import DeviceInformationNameField from "../../common/DeviceInformationFields/DeviceInformationNameField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import XFDeviceInformationNumberField from "./XFDeviceInformationNumberField";
import { XFDeviceInformationsFields_deviceInformation$key } from "./__generated__/XFDeviceInformationsFields_deviceInformation.graphql";

function XFDeviceInformationsFields(props: {
  deviceInformation: XFDeviceInformationsFields_deviceInformation$key;
}) {
  const data = useFragment(
    graphql`
      fragment XFDeviceInformationsFields_deviceInformation on DeviceInformation {
        ... on XfDeviceInformation {
          id
          deviceType
        }
        ...DeviceInformationContext_deviceInformation
        ...XFDeviceInformationNumberField_deviceInformation
        ...DeviceInformationNameField_deviceInformation
        ...DeviceInformationDeviceTypeField_deviceInformation
      }
    `,
    props.deviceInformation
  );

  return (
    <DeviceInformationContextProvider deviceInformation={data}>
      <ProgrammingConceptForm.Fields>
        <XFDeviceInformationNumberField />
        <DeviceInformationNameField />
        <DeviceInformationDeviceTypeField />
      </ProgrammingConceptForm.Fields>
    </DeviceInformationContextProvider>
  );
}

export default XFDeviceInformationsFields;
