import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { fetchQuery, useFragment, useRefetchableFragment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { asString, ControlSystem, ID } from "securecom-graphql/client";
import { Concept } from "../common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../common/FullProgrammingVersionEntryPoint";
import * as BellOptions from "./XFBellOptionsProgrammingConceptForm";
import * as Communication from "./XFCommunicationProgrammingConceptForm";
import * as DeviceSetup from "./XFDeviceSetupProgrammingConceptForm";
import * as LockoutCode from "./XFLockoutCodeProgrammingConceptForm";
import * as NetworkOptions from "./XFNetworkOptionsProgrammingConceptForm";
import * as OutputGroups from "./XFOutputGroupsProgrammingConceptForm";
import * as OutputInformation from "./XFOutputInformationProgrammingConceptForm";
import * as OutputOptions from "./XFOutputOptionsProgrammingConceptForm";
import * as RemoteOptions from "./XFRemoteOptionsProgrammingConceptForm";
import * as SystemOptions from "./XFSystemOptionsProgrammingConceptForm";
import * as ZoneInformation from "./XFZoneInformationProgrammingConceptForm";
import { XFFullProgrammingContainer_controlSystem$key } from "./__generated__/XFFullProgrammingContainer_controlSystem.graphql";
import { XFFullProgrammingContainer_templateStatus_controlSystem$key } from "./__generated__/XFFullProgrammingContainer_templateStatus_controlSystem.graphql";
import { XFFullProgrammingContainer_templateStatus_controlSystemQuery } from "./__generated__/XFFullProgrammingContainer_templateStatus_controlSystemQuery.graphql";
import { XFFullProgrammingTemplateQuery } from "./__generated__/XFFullProgrammingTemplateQuery.graphql";

export const createConcepts = ({
  softwareVersion,
  supportsCustomCardFormats,
}: {
  softwareVersion: number;
  supportsCustomCardFormats: boolean;
}) =>
  [
    Communication,
    NetworkOptions,
    DeviceSetup,
    RemoteOptions,
    SystemOptions,
    BellOptions,
    OutputInformation,
    OutputOptions,
    OutputGroups,
    ZoneInformation,
    LockoutCode,
  ]
    .filter(isNotNullOrUndefined)
    .map(({ Form, ...rest }) => ({
      ...rest,
      Form: React.memo(Form),
    })) as Concept[];

export const xfFullProgrammingControlSystem = graphql`
  fragment XFFullProgramming_controlSystem on ControlSystem {
    id
    ...FullProgrammingForm_controlSystem
    ...ProgrammingTemplateForm_controlSystem
    ...ControlSystemContext_controlSystem
    ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
    ...XFCommunicationProgrammingConceptForm_controlSystem
    ...XFCommunicationProgrammingConceptFormNavButton_controlSystem
    ...XFNetworkOptionsProgrammingConceptForm_controlSystem
    ...XFDeviceSetupProgrammingConceptForm_controlSystem
    ...XFDeviceSetupProgrammingConceptFormNavButton_controlSystem
    ...XFRemoteOptionsProgrammingConceptForm_controlSystem
    ...XFSystemOptionsProgrammingConceptForm_controlSystem
    ...XFBellOptionsProgrammingConceptForm_controlSystem
    ...XFOutputInformationProgrammingConceptForm_controlSystem
    ...XFOutputInformationProgrammingConceptFormNavButton_controlSystem
    ...XFOutputOptionsProgrammingConceptForm_controlSystem
    ...XFOutputGroupsProgrammingConceptForm_controlSystem
    ...XFOutputGroupsProgrammingConceptFormNavButton_controlSystem
    ...XFZoneInformationProgrammingConceptForm_controlSystem
    ...XFZoneInformationProgrammingConceptFormNavButton_controlSystem
    ...XFLockoutCodeProgrammingConceptForm_controlSystem
    panel {
      ...PanelContextUseHasNetworkCommType_panel
      ...PanelContextUseHasWifiCommType_panel
    }
  }
`;

export const xfFullProgrammingInlineControlSystem = graphql`
  fragment XFFullProgrammingInline_controlSystem on ControlSystem {
    ...XFCommunicationProgrammingConceptFormInline_controlSystem
    ...XFNetworkOptionsProgrammingConceptFormInline_controlSystem
    ...XFDeviceSetupProgrammingConceptFormInline_controlSystem
    ...XFRemoteOptionsProgrammingConceptFormInline_controlSystem
    ...XFSystemReportsProgrammingConceptFormInline_controlSystem
    ...XFSystemOptionsProgrammingConceptFormInline_controlSystem
    ...XFBellOptionsProgrammingConceptFormInline_controlSystem
    ...XFOutputInformationProgrammingConceptFormInline_controlSystem
    ...XFOutputOptionsProgrammingConceptFormInline_controlSystem
    ...XFOutputGroupsProgrammingConceptFormInline_controlSystem
    ...XFZoneInformationProgrammingConceptFormInline_controlSystem
    ...XFLockoutCodeProgrammingConceptFormInline_controlSystem
  }
`;

function XFFullProgrammingContainer(props: {
  dealerId: ID;
  systemId: string;
  controlSystem: XFFullProgrammingContainer_controlSystem$key;
}) {
  const controlSystemFragment = useFragment(
    graphql`
      fragment XFFullProgrammingContainer_controlSystem on ControlSystem {
        name
        panel {
          softwareVersion
          supportsCustomCardFormats
        }
        ...XFFullProgrammingContainer_templateStatus_controlSystem
      }
    `,
    props.controlSystem
  );

  const { name, panel } = controlSystemFragment;
  const softwareVersion = Number(panel?.softwareVersion);
  const { supportsCustomCardFormats } = panel;
  const concepts = React.useMemo(
    () => createConcepts({ softwareVersion, supportsCustomCardFormats }),
    [softwareVersion, supportsCustomCardFormats]
  );

  const [{ templatePreProgrammingJobStatus }, refetchJobStatus] =
    useRefetchableFragment<
      XFFullProgrammingContainer_templateStatus_controlSystemQuery,
      XFFullProgrammingContainer_templateStatus_controlSystem$key
    >(
      graphql`
        fragment XFFullProgrammingContainer_templateStatus_controlSystem on ControlSystem
        @refetchable(
          queryName: "XFFullProgrammingContainer_templateStatus_controlSystemQuery"
        ) {
          templatePreProgrammingJobStatus
        }
      `,
      controlSystemFragment
    );

  const jobPending = [
    "new",
    "created",
    "running",
    "acquired",
    "started",
  ].includes(templatePreProgrammingJobStatus ?? "");

  React.useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (jobPending) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetchJobStatus({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 5000));
        }, 5000)
      );
    }

    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [refetchJobStatus, jobPending]);

  return jobPending ? (
    <GenericPageFallback message={"Applying Template Programming..."} />
  ) : (
    <FullProgrammingVersionEntryPoint
      systemName={name}
      concepts={concepts}
      gqlQuery={graphql`
        query XFFullProgrammingQuery($dealerId: ID!, $systemId: ID!) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: node(id: $systemId) {
            ... on ControlSystem {
              id
              name
              panel {
                hardwareModel
              }
              customer {
                id
              }
              ...XFFullProgramming_controlSystem
              ...NetworkOptionsDhcpEnabledField_controlSystem
              ...XFSystemOptionsProgrammingConceptFormInline_controlSystem
              ...XFZoneInformationProgrammingConceptFormInline_controlSystem
              ...XFCommunicationProgrammingConceptFormInline_controlSystem
              ...XFDeviceSetupProgrammingConceptFormInline_controlSystem
              ...XFOutputInformationProgrammingConceptFormInline_controlSystem
              ...XFOutputGroupsProgrammingConceptFormInline_controlSystem
            }
          }
          controlSystemInline: node(id: $systemId) {
            ... on ControlSystem {
              id
              ...XFFullProgrammingInline_controlSystem
            }
          }
        }
      `}
      gqlQueryVariables={{
        systemId: props.systemId,
        dealerId: asString(props.dealerId),
      }}
      gqlFormControlSystemFragment={xfFullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={xfFullProgrammingInlineControlSystem}
      applyTemplate={applyTemplate(props.systemId, concepts)}
    />
  );
}

export default XFFullProgrammingContainer;

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<XFFullProgrammingTemplateQuery>(
    environment,
    graphql`
      query XFFullProgrammingTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on XfProgrammingTemplateConcepts {
                systemOptions {
                  included
                }
                ...XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFSystemOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts
                ...XFLockoutCodeProgrammingConceptFormInline_xfProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
