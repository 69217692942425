App.factory("FindNearestCustomerAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/Dealer/:dealer_id/NearestCustomers",
      {},
      {
        findNearestCustomer: {
          method: "POST",
          isArray: true,
          params: { dealer_id: "@dealer_id" },
        },
      }
    );
  },
]);
