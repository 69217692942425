App.factory("VideoVerifyV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/:url",
      {},
      {
        verify: { method: "GET", params: { url: "@url" } },
        clips: { method: "GET", params: { url: "@url" }, isArray: true },
        record: { method: "POST", params: { url: "@url" } },
      }
    );
  },
]);
