/**
 * @name App.service:OnlinePanelService
 *
 * @description Utility service for taking the panel offline and restoring it online
 */
App.service("OnlinePanelService", [
  "$q",
  "ControlSystemsService",
  "$modal",
  "$state",
  "UserService",
  "$rootScope",
  function (
    $q,
    ControlSystemsService,
    $modal,
    $state,
    UserService,
    $rootScope
  ) {
    var _this = this;

    /**
     * A list of values for hardware_model for which the Pre-Programming toggle is disabled
     * @type {[string]}
     */
    _this.panelModelsDisallowedInUI = [
      "XTL",
      "XR500",
      "iComLNC",
      "MiniCellCom",
      "iComSL",
    ];

    /**
     * Indicates the panel is online; any programming sent to SCAPI will be sent to the panel
     * @returns {boolean}
     */
    _this.isOnline = function () {
      return (
        onlinePropertyExists() &&
        ControlSystemsService.currentControlSystem.panels[
          ControlSystemsService.currentControlSystem.panel_index
        ].online === true
      );
    };

    /**
     * Indicates the panel is offline; any programming sent to SCAPI will not be sent to the panel
     * @returns {boolean}
     */
    _this.isOffline = function () {
      return (
        onlinePropertyExists() &&
        ControlSystemsService.currentControlSystem.panels[
          ControlSystemsService.currentControlSystem.panel_index
        ].online === false
      );
    };

    /**
     * Refresh the control system and indicate if the panel is online in the resolve()
     */
    _this.getUpdatedOnlineStatus = function () {
      var deferred = $q.defer();
      refreshControlSystem()
        .then(
          function () {
            if (onlinePropertyExists()) {
              deferred.resolve(
                ControlSystemsService.currentControlSystem.panels[
                  ControlSystemsService.currentControlSystem.panel_index
                ].online
              );
            } else {
              deferred.reject("Internal Error");
            }
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Bring the panel online so that programming data sent to SCAPI is sent to the panel.
     */
    _this.bringOnline = function () {
      var deferred = $q.defer();
      // Retrieve control system so that the only property changed is the online flag
      _this
        .getUpdatedOnlineStatus()
        .then(
          function (isOnline) {
            if (isOnline) {
              deferred.resolve();
            } else {
              // Create a backup of the current panel so the local panel can be restored in case anything goes wrong.
              var panelBackup = createPanelBackup();
              // Set the online flag on the panel to true so that the control system requiresSerialNumber() and
              // requiresDeviceId() can be used
              ControlSystemsService.currentControlSystem.panels[
                ControlSystemsService.currentControlSystem.panel_index
              ].online = true;
              if (
                ControlSystemsService.currentControlSystem.panels[
                  ControlSystemsService.currentControlSystem.panel_index
                ].auto_program
              ) {
                ControlSystemsService.currentControlSystem.panels[
                  ControlSystemsService.currentControlSystem.panel_index
                ].auto_program = false;
              }
              // Check that panel can connect
              var validSerialNumber = true;
              var validDeviceId = true;
              // TODO: Should the control-system.js validate() be used instead? and/or should there be a validateSerial() there?
              // Use this panel for comparison only. Properties must be set on the ControlSystemService to be updated when saved.
              var panel =
                ControlSystemsService.currentControlSystem.panels[
                  ControlSystemsService.currentControlSystem.panel_index
                ];
              if (
                ControlSystemsService.currentControlSystem.requiresSerialNumber()
              ) {
                var panelSerialPattern = RegExp("[0-9A-F]{8}");
                validSerialNumber = panelSerialPattern.test(
                  panel.serial_number
                );
              }
              if (
                ControlSystemsService.currentControlSystem.requiresDeviceId()
              ) {
                validDeviceId =
                  panel.sim_identifier !== null && panel.sim_identifier !== "";
              }
              if (validSerialNumber && validDeviceId) {
                saveSystem()
                  .then(
                    function () {
                      deferred.resolve();
                    },
                    function (error) {
                      restorePanel(panelBackup);
                      deferred.reject(error);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                // Open a modal indicating information is missing
                switch ($rootScope.appProperties.type) {
                  case "dealerAdmin":
                    var bringOnlineModal = $modal.open({
                      templateUrl: "bringOnlineMissingInfoModal.html",
                      controller: "BringOnlineMissingInfoCtrl",
                      size: "md",
                      backdrop: "static",
                      resolve: {
                        validSerialNumber: function () {
                          return validSerialNumber;
                        },
                        validDeviceId: function () {
                          return validDeviceId;
                        },
                      },
                    });
                    bringOnlineModal.result
                      .then(
                        function (reason) {
                          switch (reason) {
                            case "save":
                              saveSystem()
                                .then(
                                  function () {
                                    deferred.resolve();
                                  },
                                  function (error) {
                                    restorePanel(panelBackup);
                                    deferred.reject(error);
                                  }
                                )
                                .catch(function (error) {
                                  console.error(error);
                                });
                              break;
                            case "edit":
                              editFunction(deferred);
                              // Reject with "USER_CANCELLED" to cancel any queued actions and not toast an error
                              deferred.reject("USER_CANCELLED");
                              break;
                            default:
                              console.warn(
                                "bringOnlineModal closed with reason: " + reason
                              );
                              // Reject with "USER_CANCELLED" to cancel any queued actions and not toast an error
                              deferred.reject("USER_CANCELLED");
                          }
                        },
                        function (error) {
                          errorFunction(error, panelBackup);
                          // note: A modal can be dismissed by hitting the escape key. Regardless, say USER_CANCELLED so there's no toast
                          deferred.reject("USER_CANCELLED");
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                    break;
                  case "techApp":
                    $modal
                      .fromTemplateUrl(
                        "templates/initial_connection/bring-online-missing-info-modal.html",
                        {
                          animation: "animated slideInRight",
                        }
                      )
                      .then(function (modal) {
                        modal.show();
                        modal.scope.validSerialNumber = validSerialNumber;
                        modal.scope.validDeviceId = validDeviceId;
                        modal.scope.controlSystem =
                          ControlSystemsService.currentControlSystem;
                        modal.scope.UserService = UserService;
                        modal.scope.save = function () {
                          saveSystem()
                            .then(
                              function () {
                                deferred.resolve();
                              },
                              function (error) {
                                restorePanel(panelBackup);
                                deferred.reject(error);
                              }
                            )
                            .catch(function (error) {
                              console.error(error);
                            });
                          closeModal();
                        };
                        modal.scope.edit = function () {
                          editFunction();
                          // Reject with "USER_CANCELLED" to cancel any queued actions and not toast an error
                          deferred.reject("USER_CANCELLED");
                          closeModal();
                        };
                        modal.scope.cancel = function () {
                          errorFunction("cancelled", panelBackup);
                          // note: A modal can be dismissed by hitting the escape key. Regardless, say USER_CANCELLED so there's no toast
                          deferred.reject("USER_CANCELLED");
                          closeModal();
                        };
                        function closeModal() {
                          modal.hide();
                          modal.remove();
                        }
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                    break;
                  default:
                    deferred.reject("Unknown app type");
                    break;
                }
              }
            }
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    function editFunction() {
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          $state.go("app.control_system_edit", {
            customer_id: UserService.customer_id,
            control_system_id: ControlSystemsService.currentControlSystem.id,
          });
          break;
        case "techApp":
          $state.go("dealer.customer.controlsystem.controlSystemEdit", {
            dealer_id: UserService.dealer_id,
            customer_id: UserService.customer_id,
            control_system_id: ControlSystemsService.currentControlSystem.id,
          });
          break;
        default:
          console.warn("OnlinePanelService->editFunction() - Unknown app type");
          break;
      }
    }

    function errorFunction(error, panelBackup) {
      restorePanel(panelBackup);
    }

    /**
     * Save the control system
     */
    var saveSystem = function () {
      var deferred = $q.defer();
      ControlSystemsService.currentControlSystem
        .save()
        .then(
          function () {
            // note: Saving the control system updates the currentControlSystem
            if (
              ControlSystemsService.currentControlSystem.panels[
                ControlSystemsService.currentControlSystem.panel_index
              ].online === true
            ) {
            } else {
              // note: I can't foresee this happening so I'm just warning the console
              console.warn(
                "saveSystem() - control system saved successfully but is still offline"
              );
            }
            deferred.resolve();
          },
          function (error) {
            console.error(
              "saveSystem() unable to save control system to return to online. Error " +
                angular.toJson(error)
            );
            deferred.reject(error);
          },
          function (info) {
            console.info("saveSystem() info: " + angular.toJson(info));
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Create a backup of the current panel
     * @returns {{}} - a copy of the panel
     */
    var createPanelBackup = function () {
      var panelBackup = {};
      angular.merge(
        panelBackup,
        ControlSystemsService.currentControlSystem.panels[
          ControlSystemsService.currentControlSystem.panel_index
        ]
      );
      return panelBackup;
    };

    /**
     * Set the current panel to the object passed in
     * @param {{}} panelBackup - The panel as it was before changes were made
     */
    var restorePanel = function (panelBackup) {
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ] = {};
      angular.merge(
        ControlSystemsService.currentControlSystem.panels[
          ControlSystemsService.currentControlSystem.panel_index
        ],
        panelBackup
      );
    };

    /**
     * Retrieve the current control system to ensure all properties are up-to-date
     * note: properties such as door devices are attached to the control system as a convenience of some of the
     * consumers of SCAPI. If those properties are not up-to-date, just changing the online flag and saving coul
     * overwrite them.
     */
    var refreshControlSystem = function () {
      var deferred = $q.defer();
      ControlSystemsService.getControlSystem(
        ControlSystemsService.currentControlSystem.id,
        ControlSystemsService.currentControlSystem.customer_id
      )
        .then(
          function () {
            deferred.resolve();
          },
          function (error) {
            console.error(
              "OnlinePanelService->refreshControlSystem() - Error retrieving control system: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * @returns {boolean} - Ensures the control system is loaded and the panel object has the online property
     * note: property will not exist if control system has not been retrieved
     */
    var onlinePropertyExists = function () {
      return (
        DoesNestedPropertyExist(
          ControlSystemsService,
          "currentControlSystem.panel_index"
        ) &&
        ControlSystemsService.currentControlSystem.hasOwnProperty("panels") &&
        angular.isDefined(
          ControlSystemsService.currentControlSystem.panels[
            ControlSystemsService.currentControlSystem.panel_index
          ]
        ) &&
        angular.isDefined(
          ControlSystemsService.currentControlSystem.panels[
            ControlSystemsService.currentControlSystem.panel_index
          ].online
        )
      );
    };
  },
]);

/**
 * A controller specifically for confirm initial connect
 */
App.controller("BringOnlineMissingInfoCtrl", [
  "$scope",
  "$modalInstance",
  "ControlSystemsService",
  "validSerialNumber",
  "validDeviceId",
  "$location",
  function (
    $scope,
    $modalInstance,
    ControlSystemsService,
    validSerialNumber,
    validDeviceId,
    $location
  ) {
    $scope.controlSystem = ControlSystemsService.currentControlSystem;
    $scope.validSerialNumber = validSerialNumber;
    $scope.validDeviceId = validDeviceId;
    $scope.headless = $location.search().headless;

    $scope.save = function () {
      $modalInstance.close("save");
    };

    $scope.edit = function () {
      $modalInstance.close("edit");
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };
  },
]);
