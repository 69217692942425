/**
 * @ngdoc service
 * @name App.factory:CurrentDealerV2API
 *
 * @description
 * Returns dealer information
 *
 */
App.factory("CurrentDealerV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/customers/:customer_id/dealer",
      {},
      { get: { method: "GET", params: {} } }
    );
  },
]);
