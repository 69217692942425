/**
 *
 * ResetIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ResetIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M56.481,9.795l5.545,0.176l0.127-3.998l-12.57-0.398l-0.171,13.316l4,0.051l0.083-6.477
    c4.308,5.349,6.651,12.168,6.499,19.193c-0.168,7.925-3.358,15.254-8.984,20.639c-5.357,5.127-12.344,7.882-19.622,7.696
    C15.948,59.641,3.664,46.357,4.006,30.38C4.185,21.9,7.93,13.955,14.28,8.581l-2.584-3.054C4.471,11.642,0.21,20.669,0.006,30.295
    C-0.382,48.474,13.655,63.59,31.297,63.992C31.535,63.997,31.774,64,32.013,64c8.104,0,15.808-3.114,21.763-8.813
    c6.398-6.124,10.027-14.45,10.219-23.443C64.167,23.705,61.458,15.897,56.481,9.795z"
      />
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default ResetIcon;
