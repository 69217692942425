/**
 *
 * ExteriorLightIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ExteriorLightIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M58.036,19.72l-10-12C47.656,7.264,47.094,7,46.5,7H38V2c0-1.104-0.896-2-2-2h-7c-1.104,0-2,0.896-2,2v5
    h-8.5c-0.561,0-1.096,0.235-1.475,0.648l-11,12c-0.535,0.585-0.676,1.431-0.356,2.157C5.988,22.531,6.707,23,7.5,23h2.575
    l9.892,25.718c0.008,0.021,0.024,0.037,0.033,0.058V55c0,1.104,0.896,2,2,2h5v5c0,1.104,0.896,2,2,2h7c1.104,0,2-0.896,2-2v-5h4
    c1.104,0,2-0.896,2-2v-6.989L54.581,23H56.5c0.776,0,1.482-0.449,1.812-1.151C58.641,21.146,58.533,20.315,58.036,19.72z M31,4h3v3
    h-3V4z M19.38,11h26.184l6.666,8H12.047L19.38,11z M14.36,23H30v23h-6.793L14.36,23z M34,60h-3v-3h3V60z M24,53v-3h16v3H24z
     M40.508,46H34V23h16.238L40.508,46z"
      />
    </g>
  </SvgIcon>
);

export default ExteriorLightIcon;
