/**
 * @generated SignedSource<<c511a269d8acb9cb64bd5cf6b63bd8d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly outputInformations: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputSupervision: {
      readonly data: OutputSupervision | null;
      readonly included: boolean | null;
    } | null;
    readonly realTimeStatus: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly tripWithPanelBell: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "49859392d0cd2e33f7f69bfaf1f99176";

export default node;
