/**
 *Directive of tabs that go with da-vertical-tabset directive
 */
App.directive("daVerticalTab", function () {
  return {
    restrict: "E",
    transclude: true,
    template: '<div role="tabpanel" ng-show="active" ng-transclude></div>',
    require: "^daVerticalTabset",
    scope: {
      icon: "@",
      heading: "@",
    },
    link: function (scope, elem, attr, tabsetCtrl) {
      scope.active = false;
      tabsetCtrl.addTab(scope);
    },
  };
});
