import { test } from "ramda";
import { getRandomInt } from "./numbers";

export const toTitleCase = (string: string) =>
  string
    .split(" ")
    .map((part) => `${part[0]?.toUpperCase()}${part.slice(1).toLowerCase()}`)
    .join(" ");

export const keepOnlyDigits = (value: string) =>
  value.split("").filter(test(/\d/gim)).join("");

// -----------------------------------------------------------------------------
// POINT FREE STRING PROTOTYPE FUNCTIONS
// -----------------------------------------------------------------------------

export const anchor: (
  ...args: Parameters<typeof String.prototype.anchor>
) => (str: string) => ReturnType<typeof String.prototype.anchor> =
  (...args) =>
  (str: string) =>
    str.anchor(...args);
export const big: (str: string) => ReturnType<typeof String.prototype.big> = (
  str: string
) => str.big();
export const blink: (str: string) => ReturnType<typeof String.prototype.blink> =
  (str: string) => str.blink();
export const bold: (str: string) => ReturnType<typeof String.prototype.bold> = (
  str: string
) => str.bold();
export const charAt: (
  ...args: Parameters<typeof String.prototype.charAt>
) => (str: string) => ReturnType<typeof String.prototype.charAt> =
  (...args) =>
  (str: string) =>
    str.charAt(...args);
export const charCodeAt: (
  ...args: Parameters<typeof String.prototype.charCodeAt>
) => (str: string) => ReturnType<typeof String.prototype.charCodeAt> =
  (...args) =>
  (str: string) =>
    str.charCodeAt(...args);
export const codePointAt: (
  ...args: Parameters<typeof String.prototype.codePointAt>
) => (str: string) => ReturnType<typeof String.prototype.codePointAt> =
  (...args) =>
  (str: string) =>
    str.codePointAt(...args);
export const concat: (
  ...args: Parameters<typeof String.prototype.concat>
) => (str: string) => ReturnType<typeof String.prototype.concat> =
  (...args) =>
  (str: string) =>
    str.concat(...args);
export const endsWith: (
  ...args: Parameters<typeof String.prototype.endsWith>
) => (str: string) => ReturnType<typeof String.prototype.endsWith> =
  (...args) =>
  (str: string) =>
    str.endsWith(...args);
export const fixed: (str: string) => ReturnType<typeof String.prototype.fixed> =
  (str: string) => str.fixed();
export const fontcolor: (
  ...args: Parameters<typeof String.prototype.fontcolor>
) => (str: string) => ReturnType<typeof String.prototype.fontcolor> =
  (...args) =>
  (str: string) =>
    str.fontcolor(...args);
export const fontsize: (
  ...args: Parameters<typeof String.prototype.fontsize>
) => (str: string) => ReturnType<typeof String.prototype.fontsize> =
  (...args) =>
  (str: string) =>
    str.fontsize(...args);
export const includes: (
  ...args: Parameters<typeof String.prototype.includes>
) => (str: string) => ReturnType<typeof String.prototype.includes> =
  (...args) =>
  (str: string) =>
    str.includes(...args);
export const indexOf: (
  ...args: Parameters<typeof String.prototype.indexOf>
) => (str: string) => ReturnType<typeof String.prototype.indexOf> =
  (...args) =>
  (str: string) =>
    str.indexOf(...args);
export const italics: (
  str: string
) => ReturnType<typeof String.prototype.italics> = (str: string) =>
  str.italics();
export const lastIndexOf: (
  ...args: Parameters<typeof String.prototype.lastIndexOf>
) => (str: string) => ReturnType<typeof String.prototype.lastIndexOf> =
  (...args) =>
  (str: string) =>
    str.lastIndexOf(...args);
export const length: (str: string) => typeof String.prototype.length = (
  str: string
) => str.length;
export const link: (
  ...args: Parameters<typeof String.prototype.link>
) => (str: string) => ReturnType<typeof String.prototype.link> =
  (...args) =>
  (str: string) =>
    str.link(...args);
export const localeCompare: (
  ...args: Parameters<typeof String.prototype.localeCompare>
) => (str: string) => ReturnType<typeof String.prototype.localeCompare> =
  (...args) =>
  (str: string) =>
    str.localeCompare(...args);
export const match: (
  ...args: Parameters<typeof String.prototype.match>
) => (str: string) => ReturnType<typeof String.prototype.match> =
  (...args) =>
  (str: string) =>
    str.match(...args);
export const matchAll: (
  ...args: Parameters<typeof String.prototype.matchAll>
) => (str: string) => ReturnType<typeof String.prototype.matchAll> =
  (...args) =>
  (str: string) =>
    str.matchAll(...args);
export const normalize: (
  ...args: Parameters<typeof String.prototype.normalize>
) => (str: string) => ReturnType<typeof String.prototype.normalize> =
  (...args) =>
  (str: string) =>
    str.normalize(...args);
export const padEnd: (
  ...args: Parameters<typeof String.prototype.padEnd>
) => (str: string) => ReturnType<typeof String.prototype.padEnd> =
  (...args) =>
  (str: string) =>
    str.padEnd(...args);
export const padStart: (
  ...args: Parameters<typeof String.prototype.padStart>
) => (str: string) => ReturnType<typeof String.prototype.padStart> =
  (...args) =>
  (str: string) =>
    str.padStart(...args);
export const repeat: (
  ...args: Parameters<typeof String.prototype.repeat>
) => (str: string) => ReturnType<typeof String.prototype.repeat> =
  (...args) =>
  (str: string) =>
    str.repeat(...args);
export const replace: (
  ...args: Parameters<typeof String.prototype.replace>
) => (str: string) => ReturnType<typeof String.prototype.replace> =
  (...args) =>
  (str: string) =>
    str.replace(...args);
export const replaceAll: (
  ...args: Parameters<typeof String.prototype.replaceAll>
) => (str: string) => ReturnType<typeof String.prototype.replaceAll> =
  (...args) =>
  (str: string) =>
    str.replaceAll(...args);
export const search: (
  ...args: Parameters<typeof String.prototype.search>
) => (str: string) => ReturnType<typeof String.prototype.search> =
  (...args) =>
  (str: string) =>
    str.search(...args);
export const slice: (
  ...args: Parameters<typeof String.prototype.slice>
) => (str: string) => ReturnType<typeof String.prototype.slice> =
  (...args) =>
  (str: string) =>
    str.slice(...args);
export const small: (str: string) => ReturnType<typeof String.prototype.small> =
  (str: string) => str.small();
export const split: (
  ...args: Parameters<typeof String.prototype.split>
) => (str: string) => ReturnType<typeof String.prototype.split> =
  (...args) =>
  (str: string) =>
    str.split(...args);
export const startsWith: (
  ...args: Parameters<typeof String.prototype.startsWith>
) => (str: string) => ReturnType<typeof String.prototype.startsWith> =
  (...args) =>
  (str: string) =>
    str.startsWith(...args);
export const strike: (
  str: string
) => ReturnType<typeof String.prototype.strike> = (str: string) => str.strike();
export const sub: (str: string) => ReturnType<typeof String.prototype.sub> = (
  str: string
) => str.sub();
export const substr: (
  ...args: Parameters<typeof String.prototype.substr>
) => (str: string) => ReturnType<typeof String.prototype.substr> =
  (...args) =>
  (str: string) =>
    str.substr(...args);
export const substring: (
  ...args: Parameters<typeof String.prototype.substring>
) => (str: string) => ReturnType<typeof String.prototype.substring> =
  (...args) =>
  (str: string) =>
    str.substring(...args);
export const sup: (str: string) => ReturnType<typeof String.prototype.sup> = (
  str: string
) => str.sup();
export const toLocaleLowerCase: (
  ...args: Parameters<typeof String.prototype.toLocaleLowerCase>
) => (str: string) => ReturnType<typeof String.prototype.toLocaleLowerCase> =
  (...args) =>
  (str: string) =>
    str.toLocaleLowerCase(...args);
export const toLocaleUpperCase: (
  ...args: Parameters<typeof String.prototype.toLocaleUpperCase>
) => (str: string) => ReturnType<typeof String.prototype.toLocaleUpperCase> =
  (...args) =>
  (str: string) =>
    str.toLocaleUpperCase(...args);
export const toLowerCase: (
  str: string
) => ReturnType<typeof String.prototype.toLowerCase> = (str: string) =>
  str.toLowerCase();
export const toString: (
  str: string
) => ReturnType<typeof String.prototype.toString> = (str: string) =>
  str.toString();
export const toUpperCase: (
  str: string
) => ReturnType<typeof String.prototype.toUpperCase> = (str: string) =>
  str.toUpperCase();
export const trim: (str: string) => ReturnType<typeof String.prototype.trim> = (
  str: string
) => str.trim();
export const trimEnd: (
  str: string
) => ReturnType<typeof String.prototype.trimEnd> = (str: string) =>
  str.trimEnd();
export const trimLeft: (
  str: string
) => ReturnType<typeof String.prototype.trimLeft> = (str: string) =>
  str.trimLeft();
export const trimRight: (
  str: string
) => ReturnType<typeof String.prototype.trimRight> = (str: string) =>
  str.trimRight();
export const trimStart: (
  str: string
) => ReturnType<typeof String.prototype.trimStart> = (str: string) =>
  str.trimStart();
export const valueOf: (
  str: string
) => ReturnType<typeof String.prototype.valueOf> = (str: string) =>
  str.valueOf();

export const toUTFChar = (hex: number) => {
  return String.fromCodePoint(hex);
};

export const getRandomUTF8String = (length: number) => {
  return new Array(length)
    .fill(null)
    .map(() => toUTFChar(getRandomInt(0, 65535)))
    .join("");
};
export const join =
  (separator = ",") =>
  (...items: string[] | string[][]) => {
    return items.flat(Infinity).join(separator);
  };

export const joinSpaced = join(" ");

export const noWidow = (str: string) => {
  for (let i = str.length - 1; i >= 0; i--) {
    if (str[i] === " ") {
      return `${str.slice(0, i)}\u00a0${str.slice(i)}`;
    }
  }
  return str;
};
