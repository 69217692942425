/**
 * @generated SignedSource<<0d74810617f4a7244ec6f3f1eb4fb2cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly requireSiteCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode2: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode3: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode4: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode5: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode6: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode7: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode8: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCodeLength: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCodePosition: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly userCodeDigits: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly userCodeLength: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly userCodePosition: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly wiegandLength: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "3fb5fc5b41b81f03430fd0e508a5c086";

export default node;
