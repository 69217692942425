import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsGatewayAddressField_networkOptions$key } from "./__generated__/NetworkOptionsGatewayAddressField_networkOptions.graphql";

export const networkOptionsGatewayAddressFieldId = () =>
  "network-options-gateway-address";

function NetworkOptionsGatewayAddressField() {
  const [{ gatewayAddress, dhcpEnabled }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsGatewayAddressField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsGatewayAddressField_networkOptions on NetworkOptions {
          gatewayAddress
          dhcpEnabled
        }
      `
    );

  const fieldId = networkOptionsGatewayAddressFieldId();
  const disabled = dhcpEnabled;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Gateway Address"
      tooltip="Enter the local gateway address. The Gateway IP Address is needed to exit your local 
      network."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        required={!dhcpEnabled}
        value={gatewayAddress}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "gatewayAddress");
          });
        }}
        onBlur={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "gatewayAddress"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsGatewayAddressField;
