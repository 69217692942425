import { getCamectUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import Button from "components/Button";
import { LoadingSpinner } from "components/DaStyledElements";
import React from "react";

type DownloadLogsType = {
  systemId: string;
  hubId: string;
  hubName?: string;
  spacerRight?: boolean;
  userService: any;
};

const DownloadLogs = ({
  systemId,
  hubId,
  hubName,
  spacerRight,
  userService,
}: DownloadLogsType) => {
  const [fetchKey, setFetchKey] = React.useState<number | undefined>(undefined);
  const [isLogComponentVisible, setIsLogComponentVisible] =
    React.useState(false);
  const setLogVisible = () => {
    setIsLogComponentVisible((prevState) => !prevState);
  };

  return (
    <>
      <Button
        className={spacerRight ? "mar-r-8" : " "}
        design="default"
        type="button"
        onClick={() => {
          setIsLogComponentVisible(true);
        }}
        disabled={isLogComponentVisible}
      >
        View Logs {isLogComponentVisible ? <LoadingSpinner /> : null}
      </Button>

      {isLogComponentVisible ? (
        <ViewLogsSpan
          hubId={hubId}
          hubName={hubName}
          systemId={systemId}
          setLogVisible={setLogVisible}
          userService={userService}
        />
      ) : null}
    </>
  );
};

type ViewLogsSpanType = {
  systemId: string;
  hubId: string;
  hubName?: string;
  setLogVisible: () => void;
  userService: any;
};

const ViewLogsSpan = ({
  hubId,
  hubName,
  systemId,
  setLogVisible,
  userService,
}: ViewLogsSpanType) => {
  React.useEffect(() => {
    // Logic to determine when to close the component
    const timer = setTimeout(() => {
      setLogVisible();
    }, 100);

    // Cleanup to clear the timer if the component unmounts before the timer fires
    return () => clearTimeout(timer);
  }, [setLogVisible]);

  const downloadAndSaveJson = async (
    url: string,
    method: string,
    authToken: string
  ) => {
    const dateTimeStamp = () => new Date().toISOString();

    try {
      const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
        "production") as SecureComEnv;
      const response = await fetch(`${getCamectUrl(secureComEnv)}/${url}`, {
        method: method,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const jsonData = await response.json();

      const jsonString = JSON.stringify(jsonData, null, 2);

      const blob = new Blob([jsonString], { type: "application/json" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        hubName ? hubName : "Gateway"
      }-logs-${dateTimeStamp()}.txt`; // File name // Trigger the download

      document.body.appendChild(link);
      link.click(); // Clean up by removing the link

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching and saving JSON:", error);
    }
  };

  // Invoke the function
  downloadAndSaveJson(
    `api/v1/camect/${systemId}/integration/command/${hubId}/panel-integration-logs`,
    "POST",
    userService.auth_token
  );

  // returning an invisible component so that the graphql call is made once per click
  return <span></span>;
};

export default DownloadLogs;
