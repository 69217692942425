import { headAndLast } from "common/utils/universal/array";
import { roundToNearest } from "common/utils/universal/numbers";
import { ascend, sort } from "ramda";

export const sortNumbersAsc = sort<number>(ascend((x) => x));

export const groupRanges = (xs: number[] | readonly number[]) => {
  const sorted = sortNumbersAsc([...new Set(xs)]);
  return sorted.reduce(
    (acc, num, index) =>
      !acc.length || sorted[index - 1] !== num - 1
        ? acc.concat([[num]])
        : [...acc.slice(0, acc.length - 1), [...acc[acc.length - 1], num]],
    [] as number[][]
  );
};

export const createPlaceholderFromRange = (xs: number[] | readonly number[]) =>
  groupRanges(xs)
    .filter((group) => group.length)
    .map((group) => headAndLast(group).join("-"))
    .join(",");

export const findNearestValueInRange = (
  x: number,
  xs: number[] | readonly number[]
) => {
  if (!xs.length || new Set(xs).has(x)) {
    return x;
  }

  const groupedRanges = groupRanges(xs);
  for (let i = 0; i < groupedRanges.length; i++) {
    const [min, max] = headAndLast(groupedRanges[i]);
    const [nextMin] = groupedRanges[i + 1]
      ? headAndLast(groupedRanges[i + 1])
      : [];

    if (
      min !== undefined &&
      ((i === 0 && x < min) || (max === undefined && nextMin === undefined))
    ) {
      return min;
    } else if (max !== undefined && nextMin === undefined && x > max) {
      return max;
    } else if (min !== undefined && nextMin !== undefined && x < nextMin) {
      return roundToNearest(max ?? min, nextMin, x);
    }
  }

  return x;
};
