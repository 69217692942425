import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsNetworkPortField_pcLogReports$key } from "./__generated__/PcLogReportsNetworkPortField_pcLogReports.graphql";

export const pcLogReportsNetworkPortFieldId = () =>
  "pc-log-reports-network-port";

function PcLogReportsNetworkPortField() {
  const [{ networkPort }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsNetworkPortField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsNetworkPortField_pcLogReports on PcLogReports {
          networkPort
        }
      `
    );

  const fieldId = pcLogReportsNetworkPortFieldId();
  const { current: originalValue } = React.useRef(networkPort);
  const label = "Port";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enter the port number. Valid numbers are from 0 to 65535."
    >
      <NumericInput
        id={fieldId}
        value={networkPort?.toString() ?? "2001"}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "networkPort"
            );
          });
        }}
        onBlur={({ target }) => {
          const valueAsNumber = Number(target.value);
          const value = isNaN(valueAsNumber)
            ? originalValue
            : clamp(1, 65535, valueAsNumber);
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(String(value).padStart(5, `0`), "networkPort");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsNetworkPortField;
