App.controller("BusinessAnalyticsScheduleModalCtrl", [
  "$scope",
  "$modalInstance",
  "UserService",
  "dealerUser",
  "CustomReportsService",
  "customReport",
  "$rootScope",
  "CustomRolesService",
  "TIME_ZONES_NAMES_WINDOWS_SERVER",
  "report",
  function (
    $scope,
    $modalInstance,
    UserService,
    dealerUser,
    CustomReportsService,
    customReport,
    $rootScope,
    CustomRolesService,
    TIME_ZONES_NAMES_WINDOWS_SERVER,
    report
  ) {
    $scope.data = {
      personnel: {
        loading: true,
        retrieved: false,
        list: [],
      },
      report: {
        loading: true,
        retrieved: false,
        object: null,
      },
      people: {
        selected: [],
        clearSelected: function () {
          this.selected = [];
        },
      },
      retrieved: function () {
        return this.personnel.retrieved && this.report.retrieved;
      },
      isBusy: function () {
        return this.personnel.loading || this.report.loading;
      },
    };

    $scope.addEmail = function (input) {
      if (validateEmail(input)) return { email: input };
      else return null;
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.saveReport = function () {
      if ($scope.data.people.selected.length) {
        // $scope.data.report.object.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        $scope.data.report.object.EmailList = $scope.data.people.selected
          .map((p) => p.email)
          .join(" ");
        $scope.data.report.object
          .save()
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Report schedule saved successfully",
              });
              $modalInstance.close("saved");
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error saving report schedule",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    $scope.deleteReport = function () {
      if (!$scope.data.report.object.isNew) {
        $scope.data.report.object
          .delete()
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Report schedule deleted successfully",
              });
              $modalInstance.close("deleted");
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error deleting report schedule",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    function getExistingReport() {
      $scope.data.report.loading = true;
      CustomReportsService.getReport(report.id)
        .then(
          function (report) {
            $scope.data.report.retrieved = true;
            $scope.data.report.object = report;
            initSelectedPeople();
          },
          function (error) {
            $scope.data.report.retrieved = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving saved reports",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
          $scope.data.report.retrieved = false;
        })
        .finally(function () {
          $scope.data.report.loading = false;
        });
    }

    function retrieveReports() {
      $scope.data.report.loading = true;
      CustomReportsService.getAllReportsForUser(
        UserService.dealer_id,
        UserService.user.id
      )
        .then(
          function (reports) {
            $scope.data.report.retrieved = true;
            // TODO: Switch on report.type
            var businessAnalyticsReport = reports.find(function (r) {
              return r.CustomRequest.type === "business_analytics";
            });
            if (businessAnalyticsReport) {
              $scope.data.report.object = businessAnalyticsReport;
              initSelectedPeople();
            }
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving saved reports",
              json: error,
            });
            $scope.data.report.retrieved = false;
          }
        )
        .catch(function (error) {
          console.error(error);
          $scope.data.report.retrieved = false;
        })
        .finally(function () {
          $scope.data.report.loading = false;
        });
    }

    function retrievePersonnel() {
      $scope.data.personnel.loading = true;
      UserService.dealerInfo
        .getPersonnel()
        .then(
          function (data) {
            $scope.data.personnel.retrieved = true;
            for (var i = 0; i < data.length; i++) {
              $scope.data.personnel.list.push(new dealerUser(data[i].user));
            }
            initSelectedPeople();
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving personnel",
              json: error,
            });
            $scope.data.personnel.retrieved = false;
          }
        )
        .catch(function (error) {
          console.error(error);
          $scope.data.personnel.retrieved = false;
        })
        .finally(function () {
          $scope.data.personnel.loading = false;
        });
    }

    function initSelectedPeople() {
      if ($scope.data.retrieved()) {
        $scope.data.report.object.EmailList.split(" ").forEach((email) => {
          $scope.data.people.selected.push(
            // If the email is that of a personnel, add it to the list
            $scope.data.personnel.list.find(function (p) {
              return p.email === email;
            }) || { email: email, isTag: true } // otherwise, add the email list as a tagged item
          );
        });
      }
    }

    function validateEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    function buildNewReport() {
      // TODO: switch on report.type
      var baReport = new customReport();
      baReport.isNew = true;
      baReport.ReportName = "Business Health Report";
      baReport.ConfigData = {};
      baReport.OdataQuery = "";
      baReport.HeaderMap = [];
      baReport.Custom = true;
      baReport.CustomRequest = { type: "business_analytics" };
      return baReport;
    }

    function getTimeZones() {
      $scope["timeZones"] = [];
      CustomRolesService.getTimeZones(UserService.dealer_id)
        .then(
          function (data) {
            angular.forEach(data, function (zone) {
              if (TIME_ZONES_NAMES_WINDOWS_SERVER.includes(zone.name)) {
                $scope.timeZones.push(zone.name);
              }
            });
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to get time zones",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function init() {
      if (typeof report.id === "number") {
        getExistingReport();
      } else {
        $scope.data.report.object = buildNewReport();
        retrieveReports();
      }
      retrievePersonnel();
      getTimeZones();
    }

    init();
  },
]);
