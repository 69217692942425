/**
 * @ngdoc API Resource
 * @name App.factory:JobsAPIV2
 *
 * @description
 * Retrieves Job Status from the VK Server.
 *
 */
App.factory("JobsAPIV2", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.jobStatusUrl + "/v2/jobs/:job_number/",
      {},
      {
        show: { method: "GET", params: { job_number: "@job_number" } },
      }
    );
  },
]);

App.factory("JobsAPIV1", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.jobStatusUrl + "/1/jobs/:job_number/status",
      {},
      {
        show: { method: "GET", params: { job_number: "@job_number" } },
      }
    );
  },
]);
