// This is a wrapper that handles loading the JS and CSS for Camect's <dmp-player> component.
import DaColors from "app/common/DaColors";
import React, { forwardRef, useEffect } from "react";

const CAMECT_STYLESHEET_URL =
  "https://home.camect.com/css/v2/font_awesome_v5.13.0.min.css";
const CAMECT_SCRIPT_URL =
  "https://home.camect.com/js/v2/dmp_player_web_component.min.js";
const BACKGROUND_COLOR = DaColors.Black;

// Find a 16/9 resolution that makes sense for the size of the player
const getResolutionForWidth = (width = 0) => {
  if (width > 1600) return [1920, 1080];
  if (width > 1067) return [1280, 720];
  if (width > 747) return [854, 480];
  return [640, 360];
};

type Props = {
  hubId: string;
  camId: string;
  authToken: string;
  env: string;
  width: number | undefined;
  height: number;
};

const CamectPlayer = forwardRef(function CamectPlayer(
  { hubId, camId, authToken, env, width, height }: Props,
  ref: any
) {
  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    const existingStylesheet = [...document.getElementsByTagName("link")].find(
      (s) => s.href === CAMECT_STYLESHEET_URL
    );
    if (!existingStylesheet) {
      const camectStylesLinkTag = document.createElement("link");
      camectStylesLinkTag.rel = "stylesheet";
      camectStylesLinkTag.href = CAMECT_STYLESHEET_URL;
      head.appendChild(camectStylesLinkTag);
    }

    const existingScript = [...document.getElementsByTagName("script")].find(
      (s) => s.src === CAMECT_SCRIPT_URL
    );
    if (!existingScript) {
      const camectPlayerScriptTag = document.createElement("script");
      camectPlayerScriptTag.src = CAMECT_SCRIPT_URL;
      head.appendChild(camectPlayerScriptTag);
    }
  }, []);

  const [videoWidth, videoHeight] = getResolutionForWidth(width);

  return (
    // Without this wrapper div, all sorts of weird auto-resizing stuff happens.
    <div style={{ width, height }}>
      <dmp-player
        id={camId}
        ref={ref}
        key={width}
        hub-id={hubId}
        cam-id={camId}
        auth-token={authToken}
        env={env}
        width={width || 0}
        height={height}
        video-width={videoWidth}
        video-height={videoHeight}
        bg={BACKGROUND_COLOR}
        style={{ display: "flex", justifyContent: "center" }}
      ></dmp-player>
    </div>
  );
});

export default CamectPlayer;
