/**
 * @generated SignedSource<<f8e7ddf5cfc79f9774b10fca93139696>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReceiverFormModalDealer_dealer$data = {
  readonly centralStationAutomationIntegrations: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly id: string;
  readonly receivers: {
    readonly nodes: ReadonlyArray<{
      readonly automationPrefix: string;
      readonly description: string | null;
      readonly id: string;
      readonly ip: string;
      readonly number: number;
      readonly port: number;
    }>;
  };
  readonly " $fragmentType": "ReceiverFormModalDealer_dealer";
};
export type ReceiverFormModalDealer_dealer$key = {
  readonly " $data"?: ReceiverFormModalDealer_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReceiverFormModalDealer_dealer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReceiverFormModalDealer_dealer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sort",
          "value": {
            "keys": [
              "number"
            ],
            "order": "ASC"
          }
        }
      ],
      "concreteType": "ReceiverConnection",
      "kind": "LinkedField",
      "name": "receivers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Receiver",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "automationPrefix",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ip",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "port",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "receivers(sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CentralStationAutomationIntegration",
      "kind": "LinkedField",
      "name": "centralStationAutomationIntegrations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};
})();

(node as any).hash = "2a93fc59818901f50b244214c9322cff";

export default node;
