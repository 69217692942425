import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { useSupports1100T } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationDoorField_deviceInformation$key } from "./__generated__/DeviceInformationDoorField_deviceInformation.graphql";

export const deviceInformationDoorFieldId = (number: string) =>
  `device-information-door-${number}`;

function DeviceInformationDoorField() {
  const [{ number, door, wirelessTranslator1100t }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationDoorField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationDoorField_deviceInformation on DeviceInformation {
          id
          number
          ... on XtDeviceInformation {
            door
            wirelessTranslator1100t
          }
        }
      `
    );

  const fieldId = deviceInformationDoorFieldId(String(number));
  const supports1100T = useSupports1100T();
  const label = "Door";

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={label}>
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={door}
        onChange={() => {
          if (!door) {
            updateDeviceInformation((recordProxy) => {
              recordProxy.setValue(false, "network");
            });
            if (supports1100T) {
              updateDeviceInformation((recordProxy) => {
                recordProxy.setValue(false, "wirelessTranslator1100t");
              });
              if (wirelessTranslator1100t) {
                //If 1100T is on then wireless should be turned off when 1100T is turned off
                updateDeviceInformation((recordProxy) => {
                  recordProxy.setValue(false, "wireless");
                });
              }
            }
          }
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!door, "door");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationDoorField;
