/**
 * @generated SignedSource<<28c32e09d14cef3a9e6dd46f4cfafa11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationSerialNumberField_deviceSerialNumberList_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly id: string;
    readonly serialNumber: string;
  }>;
  readonly " $fragmentType": "DeviceInformationSerialNumberField_deviceSerialNumberList_panel";
};
export type DeviceInformationSerialNumberField_deviceSerialNumberList_panel$key = {
  readonly " $data"?: DeviceInformationSerialNumberField_deviceSerialNumberList_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationSerialNumberField_deviceSerialNumberList_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationSerialNumberField_deviceSerialNumberList_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "32e086c488a8e4944a4534579ae8a438";

export default node;
