import { useClickOutside } from "common/hooks/useClickOutside";
import DatePickerCalendar from "components/DatePickerCalendar";
import Icon from "components/Icon";
import { format } from "date-fns";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components/macro";

export const DateInputPopover = ({
  selectedDate,
  setSelectedDate,
  disabled,
}: {
  selectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
}) => {
  const popover = useRef(null);
  const [isOpen, toggle] = useState(false);
  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  const monthDayPattern = "^(0?[1-9]|1[0-2])/(0?[1-9]|[12][0-9]|3[01])$";
  //this regex just checks for a month/day format
  //it allows either mm/dd, m/dd, mm/d, or m/d formats

  return (
    <Root>
      <DateInput
        className="form-control"
        onChange={handleInput}
        placeholder="MM/DD"
        value={selectedDate}
        pattern={monthDayPattern}
        title="Date should be a valid month and day combination in a MM/DD format."
        required
        onBlur={(e) => {
          if (e.target.reportValidity()) {
            setSelectedDate(
              format(
                new Date(`${e.target.value}/${new Date().getUTCFullYear()}`),
                "MM/dd"
              )
            );
          }
        }}
        maxLength={5}
        disabled={disabled}
      />
      <CalendarDropdownButton
        onClick={() => {
          if (!disabled) {
            toggle(true);
          }
        }}
        disabled={disabled}
      >
        <Icon name="date" size="3rem" color="grey" />
      </CalendarDropdownButton>

      {isOpen && (
        <Popover ref={popover}>
          <DatePickerCalendar
            selectedDate={`${selectedDate}/${new Date().getUTCFullYear()}`}
            setSelectedDate={setSelectedDate}
            closeDatePickerModal={close}
          />
        </Popover>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;
const CalendarDropdownButton = styled.div<{ disabled?: boolean }>`
  width: 6rem;
  height: 3.5rem;
  border-radius: 0 0.4rem 0.4rem 0;
  border: 0.1rem solid #dde6e9;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  display: flex;
  justify-content: center;
`;
const Popover = styled.div`
  position: absolute;
  top: calc(100% + 0.8rem);
  left: 9.7rem;
  border: 0.1rem solid black;
  border-radius: 0.2rem;
  background-color: white;
`;
const DateInput = styled.input`
  border-radius: 0.4rem 0 0 0.4rem;
`;
