/**
 * @generated SignedSource<<36b744d8dc9adcd303ff0157b4b2b0e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver1AlarmReportsField_communication$data = {
  readonly receiver1AlarmReports: boolean;
  readonly " $fragmentType": "CommunicationReceiver1AlarmReportsField_communication";
};
export type CommunicationReceiver1AlarmReportsField_communication$key = {
  readonly " $data"?: CommunicationReceiver1AlarmReportsField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver1AlarmReportsField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver1AlarmReportsField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver1AlarmReports",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "133d8f9830db1070c7931b316af25fa5";

export default node;
