/**
 * @generated SignedSource<<09f47ed706001547afe153672991bd42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VarHubPanelConnectionStatus = "CONNECTED" | "DISCONNECTED" | "PARTIAL" | "PENDING" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub$data = {
  readonly isOnline: boolean;
  readonly panelConnectionStatus: VarHubPanelConnectionStatus;
  readonly " $fragmentType": "RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub";
};
export type RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub$key = {
  readonly " $data"?: RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "panelConnectionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnline",
      "storageKey": null
    }
  ],
  "type": "VarHub",
  "abstractKey": null
};

(node as any).hash = "a6ec94f905fbfa6c0f9a3f8b71f8f565";

export default node;
