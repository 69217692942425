/**
 * @generated SignedSource<<18e29a8033ed87ccb30225ff6fd541da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly networkOptions: {
    readonly dhcpEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dnsServer: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly gatewayAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly localIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly subnetMask: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "11337a923f6a3e3587f48365d58ab6cb";

export default node;
