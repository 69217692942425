import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationAccountNumberField_communication$key } from "./__generated__/CommunicationAccountNumberField_communication.graphql";

export const communicationAccountNumberFieldId = () =>
  "communication-account-number";

function CommunicationAccountNumberField() {
  const [{ accountNumber }] =
    useCommunicationFragment<CommunicationAccountNumberField_communication$key>(
      graphql`
        fragment CommunicationAccountNumberField_communication on Communication {
          accountNumber
        }
      `
    );

  const fieldId = communicationAccountNumberFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Account Number"
      tooltip="Account number for this System. Will be truncated to 4 digits when sent over CID."
    >
      <NumericInput id={fieldId} value={accountNumber} disabled />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationAccountNumberField;
