import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreBackupTcpPortField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreBackupTcpPortField_remoteOptions.graphql";

export const remoteOptionsEntreBackupTcpPortFieldId = () =>
  "remote-options-entre-backup-tcp-port";

function RemoteOptionsEntreBackupTcpPortField() {
  const [
    { entreConnection, entreBackupIncomingPort, entreBackupConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsEntreBackupTcpPortField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsEntreBackupTcpPortField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          entreConnection
          entreBackupConnection
          entreBackupIncomingPort
        }
        ... on XfRemoteOptions {
          entreConnection
          entreBackupConnection
          entreBackupIncomingPort
        }
      }
    `
  );

  const fieldId = remoteOptionsEntreBackupTcpPortFieldId();
  const disabled =
    entreConnection === RemoteOptionsConnectionType.NONE ||
    entreBackupConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré Backup TCP Port"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={entreBackupIncomingPort || ""}
        maxLength={16}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        disabled={disabled}
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "entreBackupIncomingPort");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(1, 65535, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(
              String(value).padStart(5, `0`),
              "entreBackupIncomingPort"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreBackupTcpPortField;
