import React from "react";
import styled, { css } from "styled-components";
import Fuse from "fuse-immutable";
import { withHandlers } from "recompose";
import uuid from "uuid/v1";
import {
  compose,
  sum,
  add,
  converge,
  prop,
  trim,
  isEmpty,
  max,
  min,
  inc,
  dec,
  applySpec,
  always,
} from "ramda";
import { List } from "immutable";
import Measure from "react-measure";
import { GUTTER, BORDER_RADIUS } from "common/components/web/constants/index";
import {
  themePanelBorderColor,
  themePrimary,
  themeGray,
} from "common/components/web/Theme";
import { seconds } from "utils/dates";
import {
  FUSEJS_SCORE_THRESHOLD,
  FUSEJS_SCORE_DISTANCE,
  ENTER,
  BACKSPACE,
  LEFT_ARROW,
  ESCAPE,
} from "common/constants/index";
import { SearchRightIcon, CloseCancelIcon } from "../Icons";
import NakedButton from "../NakedButton";
import NakedList from "../NakedList";
import StatusIndicators from "../StatusIndicators";
import SearchTerm from "./SearchTerm";
export const SIZES = {
  DEFAULT: "default",
  SMALL: "small",
};
export const BORDER_WIDTH = 1;
export const MARGIN_BOTTOM = GUTTER;
const DEFAULT_SEARCH_TERMS = List.of("");
const DEFAULT_SEARCH_TERM_WIDTHS = List();
const SEARCH_TERM_DELINEATOR = " ";
const lastSearchTermIndex = compose(dec, prop("size"), prop("searchTerms"));
export const STYLE_VALUES = {
  [SIZES.DEFAULT]: {
    FONT_SIZE: 24,
    PADDING: 20,
    HEIGHT: 50,
  },
  [SIZES.SMALL]: {
    FONT_SIZE: 18,
    PADDING: 15,
    HEIGHT: 40,
  },
};
export const calculateHeight = (size = SIZES.DEFAULT) => {
  const { HEIGHT } = STYLE_VALUES[size];
  return HEIGHT + BORDER_WIDTH + MARGIN_BOTTOM;
};
export class SearchField extends React.Component {
  static defaultProps = {
    data: null,
    throttle: false,
    useSearchTerms: false,
    size: SIZES.DEFAULT,
    onKeyDown: null,
    inputRef: null,
    className: "",
    loading: false,
    initialQuery: "",
  };
  state = {
    query: "",
    focus: false,
    searchTerms: DEFAULT_SEARCH_TERMS,
    searchTermWidths: DEFAULT_SEARCH_TERM_WIDTHS,
    activeSearchTermIndex: 0,
  };
  throttleTimeout = null;
  loadingTimeout = null;
  searchTerms = [];
  input = null;

  constructor(props) {
    super(props);
    this.id = uuid();
    this.state = {
      query: props.initialQuery,
      focus: false,
      searchTerms: DEFAULT_SEARCH_TERMS,
      searchTermWidths: DEFAULT_SEARCH_TERM_WIDTHS,
      activeSearchTermIndex: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { query, activeSearchTermIndex, searchTerms } = this.state;
    const { data, throttle } = this.props;

    if (query !== prevState.query || (data && !data.equals(prevProps.data))) {
      if (throttle) {
        if (this.throttleTimeout) {
          clearTimeout(this.throttleTimeout);
        }

        if (query) {
          this.throttleTimeout = setTimeout(
            () => this.props.onChange(query.trim()),
            seconds(0.5)
          );
        } else {
          this.props.onChange(query.trim());
        }
      } else {
        this.props.onChange(query.trim());
      }
    }

    const activeSearchTerm = this.searchTerms[activeSearchTermIndex];

    if (
      activeSearchTermIndex !== prevState.activeSearchTermIndex &&
      activeSearchTerm
    ) {
      activeSearchTerm.focus();
    } else if (
      (searchTerms.size !== prevState.searchTerms.size ||
        activeSearchTermIndex !== prevState.activeSearchTermIndex) &&
      activeSearchTermIndex === lastSearchTermIndex(this.state) &&
      this.input
    ) {
      this.input.focus();
    }
  }

  componentWillUnmount() {
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }
  }

  get currentSearchTerm() {
    return this.props.useSearchTerms
      ? this.state.searchTerms.last()
      : this.state.query;
  }

  setFieldRef = (ref) => {
    this.input = ref;

    if (this.props.inputRef) {
      this.props.inputRef(ref);
    }
  };
  handleFocus = (event) => {
    this.setState(
      applySpec({
        focus: always(true),
        activeSearchTermIndex: lastSearchTermIndex,
      }),
      () => {
        if (this.props.onFocus) {
          this.props.onFocus(event);
        }
      }
    );
  };
  handleBlur = (event) => {
    this.setState(
      {
        focus: false,
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      }
    );
  };
  handleQueryChange = ({ target }) => {
    this.setState(({ searchTerms }) => {
      const { value } = target;

      if (!this.props.useSearchTerms) {
        return {
          query: value,
        };
      }

      const parsedValue = value.split(SEARCH_TERM_DELINEATOR);
      let newSearchTerms = null;

      if (trim(value).length && parsedValue.length > 1) {
        newSearchTerms = searchTerms
          .slice(0, -1)
          .concat(List(parsedValue).skipWhile(isEmpty));
      } else if (searchTerms.size === 1) {
        newSearchTerms = List.of(value);
      } else {
        newSearchTerms = searchTerms.slice(0, -1).concat(value);
      }

      return {
        searchTerms: newSearchTerms,
        query: newSearchTerms.join(SEARCH_TERM_DELINEATOR),
        activeSearchTermIndex: newSearchTerms.size - 1,
      };
    });
  };
  handleEnter = () => {
    this.setState(({ searchTerms }) => {
      const newSearchTerms = searchTerms.concat("");
      return {
        searchTerms: newSearchTerms,
        query: newSearchTerms.join(SEARCH_TERM_DELINEATOR),
        activeSearchTermIndex: newSearchTerms.size - 1,
      };
    });

    if (this.input) {
      this.input.blur();
    }
  };
  handleKeyDown = (event) => {
    const { useSearchTerms } = this.props;
    const { searchTerms } = this.state; // Prevents Firefox navigating back

    if (
      useSearchTerms &&
      searchTerms.last() === "" &&
      event.which === BACKSPACE
    ) {
      event.preventDefault();
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    } // This is a hack to fix a weird issue where the field is still
    // focused but the cursor disappears

    if (this.input) {
      this.input.blur();
      this.input.focus();
    }

    if (!useSearchTerms) {
      return;
    }

    const { which } = event;
    const { selectionStart } = event.target;

    if (which === ENTER) {
      this.handleEnter();
    } else if (
      [LEFT_ARROW, BACKSPACE].includes(which) &&
      selectionStart === 0
    ) {
      this.selectPreviousSearchTerm();
    } else if (which === ESCAPE && this.input) {
      this.input.blur();
    }
  };
  clearResults = () =>
    this.setState({
      query: "",
      searchTerms: DEFAULT_SEARCH_TERMS,
      searchTermWidths: DEFAULT_SEARCH_TERM_WIDTHS,
      activeSearchTermIndex: 0,
    });
  removeSearchTerm = (index) => {
    this.setState(({ searchTerms, searchTermWidths }) => {
      const newSearchTerms = searchTerms.splice(index, 1);
      return {
        query: newSearchTerms.join(SEARCH_TERM_DELINEATOR),
        searchTerms: newSearchTerms,
        searchTermWidths: searchTermWidths.splice(index, 1),
        activeSearchTermIndex: newSearchTerms.size - 1,
      };
    });
  };
  selectNextSearchTerm = () => {
    this.setState(
      applySpec({
        activeSearchTermIndex: converge(min, [
          lastSearchTermIndex,
          compose(inc, prop("activeSearchTermIndex")),
        ]),
      })
    );
  };
  selectPreviousSearchTerm = () => {
    this.setState(
      applySpec({
        activeSearchTermIndex: compose(
          max(0),
          dec,
          prop("activeSearchTermIndex")
        ),
      })
    );
  };

  render() {
    const { HEIGHT, PADDING, FONT_SIZE } = STYLE_VALUES[this.props.size];
    const searchTermsWidth = sum(
      this.state.searchTermWidths.valueSeq().toArray()
    );
    return (
      <Root className={this.props.className}>
        <IconWrapper htmlFor={this.id} size={FONT_SIZE} left={PADDING}>
          <Icon focus={this.state.focus} />
        </IconWrapper>
        <Input
          autoComplete="off"
          autoCorrect="off"
          type="text"
          id={this.id}
          ref={this.setFieldRef}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleQueryChange}
          onKeyDown={this.handleKeyDown}
          value={this.currentSearchTerm}
          placeholder={this.props.loading ? undefined : this.props.placeholder}
          fontSize={FONT_SIZE}
          padding={PADDING}
          height={HEIGHT}
          searchTermsWidth={searchTermsWidth}
        />
        {this.props.useSearchTerms && (
          <SearchTerms height={HEIGHT} padding={PADDING} fontSize={FONT_SIZE}>
            {this.state.searchTerms
              .slice(0, -1)
              .valueSeq()
              .map((text, index) => (
                <Measure
                  key={index}
                  onMeasure={({ width }) =>
                    this.setState(({ searchTermWidths }) => ({
                      searchTermWidths: searchTermWidths.set(index, width),
                    }))
                  }
                  includeMargin
                >
                  <SearchTerm
                    buttonRef={(ref) => {
                      this.searchTerms[index] = ref;
                    }}
                    index={index}
                    removeSearchTerm={this.removeSearchTerm}
                    selectPreviousSearchTerm={this.selectPreviousSearchTerm}
                    selectNextSearchTerm={this.selectNextSearchTerm}
                  >
                    {text}
                  </SearchTerm>
                </Measure>
              ))}
          </SearchTerms>
        )}
        {this.props.loading && (
          <LoadingWrapper left={searchTermsWidth + PADDING + FONT_SIZE + 10}>
            <StatusIndicators loading />
          </LoadingWrapper>
        )}
        {!!this.state.query && (
          <ClearButton onClick={this.clearResults}>
            <CloseButtonIcon />
          </ClearButton>
        )}
      </Root>
    );
  }
}
const enhance = withHandlers({
  onChange: ({ onChange, data, searchKeys, useSearchTerms, sortByScore }) => {
    const fuse = new Fuse(data || List(), {
      threshold: FUSEJS_SCORE_THRESHOLD,
      distance: FUSEJS_SCORE_DISTANCE,
      keys: searchKeys,
      tokenize: useSearchTerms,
      matchAllTokens: useSearchTerms,
      sortByScore,
    });
    return (query) => {
      onChange(query ? fuse.search(query) : null);
    };
  },
});
export default enhance(SearchField);
const basePaddingLeft = compose(
  add(10),
  converge(add, [prop("padding"), prop("fontSize")])
);
const inputPaddingLeft = converge(add, [
  prop("searchTermsWidth"),
  basePaddingLeft,
]);
const Root = styled.div`
  position: relative;
  margin-bottom: ${MARGIN_BOTTOM}px;
  border: ${BORDER_WIDTH}px solid ${themePanelBorderColor};
  border-radius: ${BORDER_RADIUS};
`;
const IconWrapper = styled.label`
  position: absolute;
  top: 50%;
  left: ${({ left }) => left}px;
  transform: translateY(-50%);
  width: 1em;
  height: 1em;
  font-size: ${({ size }) => size}px;
`;
const Icon = styled(SearchRightIcon)`
  display: block;
  cursor: pointer;

  ${({ focus }) =>
    focus &&
    css`
      color: ${themePrimary};
    `};
`;
const Input = styled.input`
  width: 100%;
  padding: 0 ${({ padding }) => `${padding}px`} 0 ${inputPaddingLeft}px;
  border: none;
  border-radius: inherit;
  background: white;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ height }) => height}px;
  appearance: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
const ClearButton = styled(NakedButton)`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 2em;
  height: 2em;
  transform: translateY(-50%);
  color: ${themeGray};
  opacity: 0.3;
  font-size: 12px;
  line-height: 2;

  &:hover {
    opacity: 1;
  }
`;
const CloseButtonIcon = styled(CloseCancelIcon)`
  line-height: 1;
`;
const SearchTerms = styled(NakedList)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: ${basePaddingLeft}px;
  height: ${prop("height")}px;
  padding: ${prop("padding")}px 0;
`;
const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ left }) => left}px;
  transform: translateY(-50%);
`;
