/**
 * @generated SignedSource<<21a01674d5cf3562c1616559295c5f61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "3626de5ddb7d90808cfd1e40e1c6ae15";

export default node;
