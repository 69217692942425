App.directive("daTempAppUser", [
  "TempAppUserService",
  "$rootScope",
  "$modal",
  "$q",
  function (TempAppUserService, $rootScope, $modal, $q) {
    return {
      restrict: "C",
      controller: function ($scope) {
        $scope.createTempUser = function (systemId) {
          $scope.openStatusModal(systemId).then(
            function () {},
            function () {},
            function () {}
          );
        };

        var statusModal = {};
        /**
         * Creates and opens a modal to display status messages during first connect
         **/
        $scope.openStatusModal = function (systemId) {
          var deferred = $q.defer();
          switch ($rootScope.appProperties.type) {
            case "dealerAdmin":
              statusModal = $modal.open({
                templateUrl: "app/common/templates/temp-user-modal-tpl.html",
                controller: "TempUserModalCtrl",
                size: "md",
                backdrop: "static",
                resolve: {
                  systemId: function () {
                    return systemId;
                  },
                },
              });
              statusModal.result.then(
                function (result) {
                  if (result.createdTempUser) {
                    deferred.resolve();
                  } else {
                    deferred.reject();
                  }
                },
                function () {
                  deferred.reject();
                }
              );
              break;
            case "techApp":
              console.warn("daTempAppUser directive does not support TechApp");
              deferred.reject();
              break;
            default:
              console.warn(
                "daTempAppUser directive does not support $rootScope.appProperties.type: " +
                  $rootScope.appProperties.type
              );
              deferred.reject();
              break;
          }
          return deferred.promise;
        };
      },
    };
  },
]);

/**
 * A controller specifically for status while attempting to retrieve programming
 */
App.controller("TempUserModalCtrl", [
  "$interval",
  "$scope",
  "$modalInstance",
  "PROPS",
  "TempAppUserService",
  "UserService",
  "$rootScope",
  "systemId",
  "ControlSystemsService",
  "controlSystemModel",
  "$q",
  "LoginService",
  "AboutMeV2API",
  "GoogleAnalyticsService",
  function (
    $interval,
    $scope,
    $modalInstance,
    PROPS,
    TempAppUserService,
    UserService,
    $rootScope,
    systemId,
    ControlSystemsService,
    controlSystemModel,
    $q,
    LoginService,
    AboutMeV2API,
    GoogleAnalyticsService
  ) {
    var _this = this;

    $scope.system = {};
    $scope.tempUser = null;
    $scope.UserService = UserService;
    $scope.props = {
      title: UserService.customerInfo.name + " systems",
      constants: PROPS,
    };
    $scope.group = {
      statuses: {},
      step: 0,
      createJobSuccess: false,
      error: false,
    };

    $scope.cancel = function () {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.dismiss("cancel");
    };

    $scope.trackUser = function () {
      GoogleAnalyticsService.trackEvent(
        "control-system",
        "login-as-customer",
        "dealer-login"
      );
    };

    $scope.close = function () {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.close({
        createdTempUser: $scope.group.createJobSuccess,
      });
    };

    var successfulJobStatuses = ["completed", "success"];
    var inProgressJobStatuses = [
      "new",
      "created",
      "acquired",
      "started",
      "running",
    ];
    function jobCompleted(status) {
      return !inProgressJobStatuses.includes(status);
    }
    function didJobSucceed(status) {
      return successfulJobStatuses.includes(status);
    }

    function init() {
      $scope.group.step = 1;
      getDesiredSystem().then(
        function (system) {
          $scope.system = system;
          TempAppUserService.create(system).then(
            function (SchedulerJobGroup) {
              console.debug(
                "TempUserModalCtrl->init() created temp user. Response data: " +
                  angular.toJson(SchedulerJobGroup)
              );
              startJobMonitor(SchedulerJobGroup.Id);
            },
            function () {
              errorCloseModal();
            }
          );
        },
        function () {
          errorCloseModal();
        }
      );
    }

    function getDesiredSystem() {
      var deferred = $q.defer();
      if (+systemId > 0) {
        var system = new controlSystemModel({
          customer_id: UserService.customer_id,
          control_system_id: +systemId,
        });
        system.get().then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "TempUserModalCtrl->getDesiredSystem() error: " +
                angular.toJson(error)
            );
            deferred.reject();
          }
        );
      } else {
        deferred.resolve(ControlSystemsService.currentControlSystem);
      }
      return deferred.promise;
    }

    function errorCloseModal() {
      $rootScope.alerts.push({
        type: "error",
        text: "error creating temporary app user",
      });
      $scope.close();
    }

    /**
     * Starts interval function that checks Initial Connection job group status,
     * updating scoped data for display in the initial connection modal
     */
    var startJobMonitor = function (groupId) {
      $scope.group.step = 2;
      $scope.group.refreshing = true;
      _this.intervalWorker = $interval(function () {
        TempAppUserService.getStatus(groupId).then(
          function (groupData) {
            $scope.group.statuses = groupData;
            if (jobCompleted(groupData.CreateJobStatus)) {
              $scope.group.step = 3;
              $interval.cancel(_this.intervalWorker);
              $scope.group.refreshing = false;
              $scope.group.createJobSuccess = didJobSucceed(
                $scope.group.statuses.CreateJobStatus
              );
              LoginService.getLoggedInPerson().then(
                function (personData) {
                  var customerUsers = personData.filter(function (u) {
                    return (
                      u.user.accessible_type === "Customer" &&
                      +u.user.accessible_id === +$scope.system.customer_id
                    );
                  });
                  switch (customerUsers.length) {
                    case 0:
                      console.error(
                        "No (temp) Customer admin user on Person for Dealer admin"
                      );
                      failTempUser();
                      break;
                    case 1:
                      finalizeTempUser(customerUsers[0].user);
                      break;
                    default:
                      // The API will prevent creation of a second user but it's a matter of timing. If the job server
                      // is backed up or one is deleted right after the Log Me In button is hit, duplicates can be
                      // created
                      console.warn(
                        "Dealer admin user has more than 1 app user."
                      );
                      var tempUser = null;
                      var UserInfoPromises = [];
                      angular.forEach(customerUsers, function (u) {
                        UserInfoPromises.push(
                          getUserInfo(u.user.authentication_token)
                        );
                      });
                      $q.all(UserInfoPromises).then(
                        function (userData) {
                          for (var i = 0; i < userData.length; i++) {
                            if (
                              userData[i].user.id ===
                              groupData.DestroyJobData.TempAppUserId
                            ) {
                              tempUser = customerUsers[i].user;
                              break;
                            }
                          }
                          if (tempUser === null) {
                            console.warn(
                              "Could not get user information for temporary app user"
                            );
                            failTempUser();
                          } else {
                            finalizeTempUser(tempUser);
                          }
                        },
                        function (error) {
                          console.error(
                            "Error getting information on temporary Customer admin logins for person: " +
                              angular.toJson(error)
                          );
                          failTempUser();
                        }
                      );
                      break;
                  }
                },
                function (error) {
                  console.error(
                    "CustomerSummaryCtrl - error getting logged in person: " +
                      angular.toJson(error)
                  );
                }
              );
              $rootScope.$broadcast("TempAppUserCreated");
            }
          },
          function () {
            failTempUser();
          }
        );
      }, PROPS.initialConnectionWatcherInterval);
    };

    function finalizeTempUser(tempUser) {
      $scope.group.step = 4;
      $scope.tempUser = tempUser;
    }

    function failTempUser() {
      $scope.group.step = 3;
      $interval.cancel(_this.intervalWorker);
      $scope.group.refreshing = false;
      $scope.group.error = true;
    }

    function getUserInfo(authToken) {
      var deferred = $q.defer();
      AboutMeV2API.get(
        { auth_token: authToken },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    init();
  },
]);
