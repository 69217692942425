/**
 * @generated SignedSource<<0e6b0c363badb079f160417bba9977e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery$variables = {
  dealerId: string;
};
export type MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery$data = {
  readonly massProgrammingQuickDetailedCustomersList: {
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"HolidaySystemSelectorModalFragment_systemData">;
  };
};
export type MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery = {
  response: MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery$data;
  variables: MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "massProgrammingQuickDetailedCustomersList",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HolidaySystemSelectorModalFragment_systemData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "massProgrammingQuickDetailedCustomersList",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isMassProgramPanelQuickDetailedResponse"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MassProgramPanelQuickDetailedCustomer",
                "kind": "LinkedField",
                "name": "customers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "controlSystemName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "controlSystemId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "panelHardwareModel",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MassProgramPanelQuickDetailedSuccessResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorMessage",
                "storageKey": null
              }
            ],
            "type": "MassProgramPanelQuickDetailedErrorResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13f3b9c8bd39241b22c9ccac16ca25fe",
    "id": null,
    "metadata": {},
    "name": "MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery",
    "operationKind": "query",
    "text": "query MassProgrammingGlobalHolidayDatesJobNewQuickSystemsQuery(\n  $dealerId: ID!\n) {\n  massProgrammingQuickDetailedCustomersList(id: $dealerId) {\n    __typename\n    ...HolidaySystemSelectorModalFragment_systemData\n  }\n}\n\nfragment HolidaySystemSelectorModalFragment_systemData on MassProgramPanelQuickDetailedResponse {\n  __isMassProgramPanelQuickDetailedResponse: __typename\n  ... on MassProgramPanelQuickDetailedSuccessResponse {\n    __typename\n    customers {\n      customerName\n      customerId\n      controlSystemName\n      controlSystemId\n      panelHardwareModel\n    }\n  }\n  ... on MassProgramPanelQuickDetailedErrorResponse {\n    __typename\n    errorType\n    errorMessage\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d63769bc07fd04ce6478e149806d9fc";

export default node;
