/**
 * Created by kweigand on 2/10/15.
 */

App.controller("RightAsideController", [
  "$scope",
  "$aside",
  function ($scope, $aside, $timeout) {
    $scope.bob = {};

    $scope.openAside = function (position) {
      $scope.bob = $aside.open({
        templateUrl: "app/ken-test-menu/aside.html",
        placement: position,
        size: "sm",
        backdrop: false,
        controller: function ($scope, $modalInstance) {
          $scope.ok = function (e) {
            $modalInstance.close();
            e.stopPropagation();
          };
          $scope.cancel = function (e) {
            $modalInstance.dismiss();
            e.stopPropagation();
          };
        },
      });
    };

    $scope.closeAside = function () {
      $scope.bob.close();
    };
  },
]);
