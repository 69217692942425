/**
 * @name App.directive:daThreeButtons
 * @desc This directive creates the Cancel, Save, Delete/Clear buttons seen on programming screens like users or schedules.
 * @property {boolean} bottom - is this the bottom row of buttons?
 * @property {function} cancelFn - function run when cancel button is hit
 * @property {function} sendFn - function to send information to the panel
 * @property {sendLabel} sendLabel - label for the button that triggers the sendFn
 * @property {function} saveFn - function to save programming to VK (for offline panels)
 * @property {boolean} saveHide - hide the save button
 * @property {boolean} saveDisable - condition for disabling the save button. true => disabled
 * @property {function} saveFn - function run when save button is hit
 * @property {function} destroyFn - function run when record is destroyed
 * @property {boolean} destroyShow - condition for showing destroy button. true => show
 * @property {string} destroyMessage - displayed for destroy confirmation
 * @property {string} locationString - optional attribute to pass a popup location ( default => top; acceptable values: < top| bottom | left | right > )
 * @property {string} destroyLabel - label for destroy button. ex: Delete (record), Clear (XT arm schedule can't be deleted)
 *
 * @example <da-three-buttons bottom="false" cancel-fn="cancelEdit()" save-disable="scheduleForm.$invalid"
                              send-fn="sendScheduleChanges()" send-label="{{'buttons.SEND_PROGRAMMING' | translate}}"
                              save-fn="Panel.saveProgramming()"
                              destroy-fn="deleteSchedule()" destroy-show="actionType == 'Edit'"
                              destroy-message="Are you sure you want to delete this schedule?"></da-three-buttons>
 */

App.directive("daThreeButtons", [
  "OnlinePanelService",
  "UserService",
  "$filter",
  daThreeButtons,
]);

function daThreeButtons(OnlinePanelService, UserService, $filter) {
  return {
    restrict: "E",

    templateUrl: "/app/common/templates/three-buttons.html",

    scope: {
      bottom: "=",
      cancelFn: "&",
      sendFn: "&",
      sendLabel: "@",
      saveFn: "&",
      saveHide: "=",
      saveDisable: "=",
      destroyFn: "&",
      destroyShow: "=",
      destroyMessage: "@",
      destroyLabel: "@",
      destroyDisable: "=",
      locationString: "@",
    },

    link: function (scope) {
      scope.OnlinePanelService = OnlinePanelService;
      scope.UserService = UserService;
      scope.deleteButtonLabel =
        scope.destroyLabel || $filter("translate")("buttons.DELETE");
    },
  };
}
