/**
 * @generated SignedSource<<bf32f205205ea3264053bbd623328636>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFCommPathFields_communicationPath$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathCheckInMinutesField_communicationPath" | "CommPathCommTypeField_communicationPath" | "CommPathContext_communicationPath" | "CommPathDuplicateAlarmsField_communicationPath" | "CommPathFailTestHoursField_communicationPath" | "CommPathFailTimeField_communicationPath" | "CommPathNumberField_communicationPath" | "CommPathProtocolField_communicationPath" | "CommPathReceiverIpAddressField_communicationPath" | "CommPathReceiverPortField_communicationPath" | "CommPathRetryTimeField_communicationPath" | "CommPathSendPathField_communicationPath" | "CommPathTestReportField_communicationPath" | "CommPathTestTimeField_communicationPath" | "CommPathTypeField_communicationPath" | "CommPathUseCheckInField_communicationPath">;
  readonly " $fragmentType": "XFCommPathFields_communicationPath";
};
export type XFCommPathFields_communicationPath$key = {
  readonly " $data"?: XFCommPathFields_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFCommPathFields_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFCommPathFields_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathContext_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathNumberField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCommTypeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTypeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestReportField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSendPathField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathUseCheckInField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCheckInMinutesField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathFailTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathProtocolField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathRetryTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathFailTestHoursField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathDuplicateAlarmsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathReceiverIpAddressField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathReceiverPortField_communicationPath"
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "547e614cf617a2ee77c3f78d7b0fefcb";

export default node;
