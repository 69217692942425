import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { useMutation } from "react-relay";
import {
  createOperationDescriptor,
  readInlineData,
  RecordProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  BellOptions,
  ControlSystem,
  NacProtocols,
} from "securecom-graphql/client";
import BellOptionsBellCutoffTimeField from "../common/BellOptionsFields/BellOptionsBellCutoffTimeField";
import BellOptionsBellOutputField from "../common/BellOptionsFields/BellOptionsBellOutputField";
import BellOptionsCarbonMonoxideBellActionField from "../common/BellOptionsFields/BellOptionsCarbonMonoxideBellActionField";
import { BellOptionsContextProvider } from "../common/BellOptionsFields/BellOptionsContext";
import BellOptionsFireBellActionField from "../common/BellOptionsFields/BellOptionsFireBellAction";
import BellOptionsNac1SyncProtocolField from "../common/BellOptionsFields/BellOptionsNac1SyncProtocolField";
import BellOptionsNac2SyncProtocolField from "../common/BellOptionsFields/BellOptionsNac2SyncProtocolField";
import BellOptionsSelectiveSilenceField from "../common/BellOptionsFields/BellOptionsSelectiveSilenceField";
import BellOptionsSupervisoryBellActionField from "../common/BellOptionsFields/BellOptionsSupervisoryBellActionField";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { PanelContextProvider } from "../common/PanelContext";
import ProgrammingConceptForm from "../common/ProgrammingConceptForm";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import refreshMutationConcreteRequest, {
  XFBellOptionsProgrammingConceptFormBellOptionsRefreshMutation,
} from "./__generated__/XFBellOptionsProgrammingConceptFormBellOptionsRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XFBellOptionsProgrammingConceptFormBellOptionsSendMutation,
  XFBellOptionsProgrammingConceptFormBellOptionsSendMutation$data,
} from "./__generated__/XFBellOptionsProgrammingConceptFormBellOptionsSendMutation.graphql";
import { XFBellOptionsProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XFBellOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import { XFBellOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XFBellOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Bell Options";
export const conceptId = "xf-bell-options";

export const getState = (
  controlSystem: XFBellOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFBellOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          bellOptions {
            id
            nac1SyncProtocol
            nac2SyncProtocol
            emergencyBellAction
            auxiliary1BellAction
            auxiliary2BellAction
            carbonMonoxideBellAction
            bellCutoffTime
            bellCutoffTimeMin
            bellCutoffTimeMax
            automaticBellTest
            bellOutput
            fireBellAction
            burglaryBellAction
            supervisoryBellAction
            panicBellAction
            strobe
            selectiveSilence
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XFBellOptionsProgrammingConceptFormBellOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshBellOptions(id: $id) {
      ... on RefreshBellOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFBellOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XFBellOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshBellOptions, isRefreshingBellOptions] =
    useMutation<XFBellOptionsProgrammingConceptFormBellOptionsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        refreshBellOptions({
          variables: {
            id: systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshBellOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Bell Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshBellOptions: {
                    __typename: response.refreshBellOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Bell Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Bell Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshingBellOptions,
  ];
};
const saveMutation = graphql`
  mutation XFBellOptionsProgrammingConceptFormBellOptionsSendMutation(
    $systemId: ID!
    $bellOptions: BellOptionsInput!
  ) {
    sendBellOptionsProgramming(systemId: $systemId, bellOptions: $bellOptions) {
      ... on SendBellOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFBellOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendBellOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XFBellOptionsProgrammingConceptFormBellOptionsSendMutation$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendBellOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(sendMutationConcreteRequest, {
      id: response.sendBellOptionsProgramming.controlSystem,
    });

    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        sendBellOptions: {
          __typename: response.sendBellOptionsProgramming.__typename,
          controlSystem: getState(
            response.sendBellOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFBellOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendBellOptions, isSendingBellOptions] =
    useMutation<XFBellOptionsProgrammingConceptFormBellOptionsSendMutation>(
      saveMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { bellOptions },
        } = getState(props.controlSystem);

        if (bellOptions) {
          sendBellOptions({
            variables: {
              systemId: systemId,
              bellOptions: {
                bellCutoffTime: bellOptions.bellCutoffTime,
                nac1SyncProtocol:
                  bellOptions.nac1SyncProtocol ?? NacProtocols.UNKNOWN,
                nac2SyncProtocol:
                  bellOptions.nac2SyncProtocol ?? NacProtocols.UNKNOWN,
                automaticBellTest: bellOptions.automaticBellTest,
                bellOutput: bellOptions.bellOutput,
                fireBellAction: bellOptions.fireBellAction,
                burglaryBellAction: bellOptions.burglaryBellAction,
                supervisoryBellAction: bellOptions.supervisoryBellAction,
                panicBellAction: bellOptions.panicBellAction,
                emergencyBellAction: bellOptions.emergencyBellAction,
                auxiliary1BellAction: bellOptions.auxiliary1BellAction,
                auxiliary2BellAction: bellOptions.auxiliary2BellAction,
                carbonMonoxideBellAction: bellOptions.carbonMonoxideBellAction,
                strobe: bellOptions.strobe,
                selectiveSilence: Boolean(bellOptions.selectiveSilence),
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendBellOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Bell Options Programming Saved To the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(response, parentRelayEnv);
              } else if (response.sendBellOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendBellOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSendingBellOptions,
  ];
};

const readBellOptionsTemplateData = (
  programmingTemplateConcepts: XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        bellOptions {
          included
          bellCutoffTime {
            included
            data
          }
          nac1SyncProtocol {
            data
            included
          }
          nac2SyncProtocol {
            data
            included
          }
          selectiveSilence {
            data
            included
          }
          automaticBellTest {
            included
            data
          }
          bellOutput {
            included
            data
          }
          fireBellAction {
            included
            data
          }
          burglaryBellAction {
            included
            data
          }
          supervisoryBellAction {
            included
            data
          }
          panicBellAction {
            included
            data
          }
          emergencyBellAction {
            included
            data
          }
          auxiliary1BellAction {
            included
            data
          }
          auxiliary2BellAction {
            included
            data
          }
          carbonMonoxideBellAction {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).bellOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readBellOptionsTemplateData(programmingTemplateConcepts);

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const bellOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "bellOptions",
      "BellOptions"
    ) as unknown as RecordProxy<BellOptions>;

    applyTemplateScalarDataToRecordProxy(bellOptionsRecordProxy, templateData);
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFBellOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFBellOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            bellOptions {
              id
              nac1SyncProtocol
              nac2SyncProtocol
              ...BellOptionsContext_bellOptions
              ...BellOptionsBellCutoffTimeField_bellOptions
              ...BellOptionsNac1SyncProtocolField_bellOptions
              ...BellOptionsNac2SyncProtocolField_bellOptions
              ...BellOptionsAutomaticBellTestField_bellOptions
              ...BellOptionsBellOutputField_bellOptions
              ...BellOptionsFireBellActionField_bellOptions
              ...BellOptionsBurglaryBellActionField_bellOptions
              ...BellOptionsSupervisoryBellActionField_bellOptions
              ...BellOptionsPanicBellActionField_bellOptions
              ...BellOptionsEmergencyBellActionField_bellOptions
              ...BellOptionsAuxiliary1BellActionField_bellOptions
              ...BellOptionsAuxiliary2BellActionField_bellOptions
              ...BellOptionsCarbonMonoxideBellActionField_bellOptions
              ...BellOptionsStrobeField_bellOptions
              ...BellOptionsSelectiveSilenceField_bellOptions
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
          }
        }
      `
    );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const bellOptions = controlSystem.panel.bellOptions;
  const nac1Protocol = bellOptions?.nac1SyncProtocol;
  const nac2Protocol = bellOptions?.nac2SyncProtocol;
  const bothNacsUseDmp =
    nac1Protocol === NacProtocols.DMP && nac2Protocol === NacProtocols.DMP;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <BellOptionsContextProvider bellOptions={controlSystem.panel.bellOptions}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          title={title}
          helpLink={`${programmingGuideUrl}#Bell%20Options`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.bellOptions
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <BellOptionsBellCutoffTimeField />
              <BellOptionsNac1SyncProtocolField />
              <BellOptionsNac2SyncProtocolField />
              {!bothNacsUseDmp ? <BellOptionsSelectiveSilenceField /> : null}
              <BellOptionsBellOutputField />
              <BellOptionsFireBellActionField />
              <BellOptionsSupervisoryBellActionField />
              <BellOptionsCarbonMonoxideBellActionField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </BellOptionsContextProvider>
    </PanelContextProvider>
  );
}
