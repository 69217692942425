import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useMessagingSetupFragment } from "./MessagingSetupContext";
import { MessagingSetupDestination1UserField_messagingSetup$key } from "./__generated__/MessagingSetupDestination1UserField_messagingSetup.graphql";

export const messagingSetupDestination1UserFieldId = () =>
  "messaging-setup-destination-1-user";

function MessagingSetupDestination1UserField() {
  const [
    { userNumberForFirstContact, firstEmailOrPhoneNumber, enableMessaging },
    updateMessagingSetup,
  ] = useMessagingSetupFragment<MessagingSetupDestination1UserField_messagingSetup$key>(
    graphql`
      fragment MessagingSetupDestination1UserField_messagingSetup on MessagingSetup {
        userNumberForFirstContact
        firstEmailOrPhoneNumber
        enableMessaging
      }
    `
  );

  const fieldId = messagingSetupDestination1UserFieldId();
  const disabled =
    !enableMessaging ||
    !new RegExp(
      "(0{0,4}[2-9][0-9]{6}|0{0,3}[1-9][0-9]{7}|0{0,2}[1-9][0-9]{8}|0?[1-9][0-9]{9}|1[0-9][0-8][0-9]{8})"
    ).test(firstEmailOrPhoneNumber);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Destination 1 User"
      disabled={disabled}
      tooltip="User number to associate with messages received from the Destination 1 phone number."
    >
      <TextInput
        id={fieldId}
        value={userNumberForFirstContact}
        disabled={disabled}
        validationMessage={"Valid values are 0-99."}
        pattern="([0]{0,1}[0-9]|[1-9][0-9])"
        inlineHelp="0-99"
        onChange={({ target }) => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(target.value, "userNumberForFirstContact");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MessagingSetupDestination1UserField;
