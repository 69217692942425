import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { KeypadInput } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsEcpPartitionField_systemOptions$key } from "./__generated__/SystemOptionsEcpPartitionField_systemOptions.graphql";

export const systemOptionsEcpPartitionFieldId = () =>
  "system-options-ecp-partition";

function SystemOptionsEcpPartitionField() {
  const [{ ecpPartition, keypadInput }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsEcpPartitionField_systemOptions$key>(
      graphql`
        fragment SystemOptionsEcpPartitionField_systemOptions on SystemOptions {
          ... on XtSystemOptions {
            ecpPartition
            keypadInput
          }
        }
      `
    );

  const fieldId = systemOptionsEcpPartitionFieldId();
  const disabled = keypadInput !== KeypadInput.ECP;

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="ECP Partition">
      <Select
        id={fieldId}
        name={fieldId}
        value={ecpPartition ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(Number(target.value), "ecpPartition");
          });
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8].map((partitionNumber) => (
          <Select.Option value={partitionNumber}>
            {partitionNumber}
          </Select.Option>
        ))}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsEcpPartitionField;
