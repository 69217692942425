/**
 * @generated SignedSource<<bfd51387be018493671e4263a8fd7876>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Device734CardOptions = "ANY" | "CUSTOM" | "DMP" | "NONE";
export type Device734NoCommWithPanelRelayAction = "ANY" | "LAST" | "OFF" | "ON" | "SITE";
export type DeviceInformation1100TWirelessFrequencyType = "DSC" | "HONEYWELL" | "INTERLOGIX" | "NONE" | "_2GIG";
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
export type DeviceInformationWirelessSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly axNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly device734: {
      readonly data: {
        readonly cardFormatOptions: {
          readonly data: Device734CardOptions | null;
          readonly included: boolean | null;
        } | null;
        readonly enableOnboardSpeaker: {
          readonly data: boolean | null;
          readonly included: boolean | null;
        } | null;
        readonly enableZone3RequestToExit: {
          readonly data: boolean | null;
          readonly included: boolean | null;
        } | null;
        readonly enforceSiteCode: {
          readonly data: boolean | null;
          readonly included: boolean | null;
        } | null;
        readonly noCommWithPanelRelayAction: {
          readonly data: Device734NoCommWithPanelRelayAction | null;
          readonly included: boolean | null;
        } | null;
        readonly numberOfUserCodeDigits: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode1: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode2: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode3: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode4: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode5: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode6: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode7: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCode8: {
          readonly data: string | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCodeBitLength: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
        readonly siteCodeStartBitPosition: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
        readonly userCodeBitLength: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
        readonly userCodeStartBitPosition: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
        readonly wiegandBitLength: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
        readonly zone2BypassOnRequestToExit: {
          readonly data: boolean | null;
          readonly included: boolean | null;
        } | null;
        readonly zone2BypassTime: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
        readonly zone2RelockOnStateChange: {
          readonly data: boolean | null;
          readonly included: boolean | null;
        } | null;
        readonly zone3RequestToExitTime: {
          readonly data: number | null;
          readonly included: boolean | null;
        } | null;
      } | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceCommunicationMethod: {
      readonly data: DeviceInformationCommType | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceType: {
      readonly data: DeviceInformationType | null;
      readonly included: boolean | null;
    } | null;
    readonly displayAreas: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly doorForceMessages: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly doorRealTimeStatusMessages: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly fireExit: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lxNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly overrideScheduleWhenArmed: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly publicDoor: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteProgram734: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly strikeDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly strikeTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisionTime: {
      readonly data: DeviceInformationWirelessSupervisionTime | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessTranslator1100t: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessTranslator1100tFrequency: {
      readonly data: DeviceInformation1100TWirelessFrequencyType | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "741aa2992e901900751ae39e32d41d7b";

export default node;
