/**
 * @generated SignedSource<<d96e6ee01362a44b37b8689342b054d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFFullProgramming_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHasNetworkCommType_panel" | "PanelContextUseHasWifiCommType_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem" | "FullProgrammingForm_controlSystem" | "ProgrammingTemplateForm_controlSystem" | "XFBellOptionsProgrammingConceptForm_controlSystem" | "XFCommunicationProgrammingConceptFormNavButton_controlSystem" | "XFCommunicationProgrammingConceptForm_controlSystem" | "XFDeviceSetupProgrammingConceptFormNavButton_controlSystem" | "XFDeviceSetupProgrammingConceptForm_controlSystem" | "XFLockoutCodeProgrammingConceptForm_controlSystem" | "XFNetworkOptionsProgrammingConceptForm_controlSystem" | "XFOutputGroupsProgrammingConceptFormNavButton_controlSystem" | "XFOutputGroupsProgrammingConceptForm_controlSystem" | "XFOutputInformationProgrammingConceptFormNavButton_controlSystem" | "XFOutputInformationProgrammingConceptForm_controlSystem" | "XFOutputOptionsProgrammingConceptForm_controlSystem" | "XFRemoteOptionsProgrammingConceptForm_controlSystem" | "XFSystemOptionsProgrammingConceptForm_controlSystem" | "XFZoneInformationProgrammingConceptFormNavButton_controlSystem" | "XFZoneInformationProgrammingConceptForm_controlSystem">;
  readonly " $fragmentType": "XFFullProgramming_controlSystem";
};
export type XFFullProgramming_controlSystem$key = {
  readonly " $data"?: XFFullProgramming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFFullProgramming_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFFullProgramming_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FullProgrammingForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFCommunicationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFCommunicationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFNetworkOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFDeviceSetupProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFDeviceSetupProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFRemoteOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFSystemOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFBellOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFOutputInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFOutputInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFOutputOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFOutputGroupsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFOutputGroupsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFZoneInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFZoneInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFLockoutCodeProgrammingConceptForm_controlSystem"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasNetworkCommType_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasWifiCommType_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "a0adbb470bae51832335bef08fabffc5";

export default node;
