App.factory("BillingAccount", [
  "$q",
  "PROPS",
  "BillingService",
  "PaymentsService",
  "UserService",
  function ($q, PROPS, BillingService, PaymentsService, UserService) {
    var BillingAccount = function () {
      angular.extend(this, {
        isLoading: false,
        billingCycle: 0,
        currentBalance: 0,
        currentCharges: 0,
        pastDueCharges: 0,
        dealerId: 0, // Dealer id
        dealerCode: "", // Dealer code (for payment account)
        customerId: "", // Payments customer id
        orbiCustomerId: "", // Orbipay customer id
        isAutoPayment: false,
        hasConfirmedPayment: false,
        errors: [],
        scheduledPayments: [],

        paymentSources: [], // List of payment sources from our database
        paymentSource: null, // The active payment source
        orbiFundingAccountId: "", // The Orbipay funding account id for the active payment source
        accountSubType: "", // Account sub type for the active payment source

        get: function (dealerCode, dealerId) {
          this.errors = [];
          this.isLoading = true;
          this.dealerId = dealerId;
          var _this = this;
          var deffered = $q.defer();

          let billingSummaryPromise =
            BillingService.getAccountSummary(dealerCode);
          let paymentAccountPromise =
            PaymentsService.getCustomerAccount(dealerCode);
          let autoPaymentPromise = PaymentsService.getAutoPayment(dealerCode);

          $q.all([
            billingSummaryPromise,
            paymentAccountPromise,
            autoPaymentPromise,
          ]).then(
            function (data) {
              _this.initBillingAccount(data[0]);
              _this.initPaymentAccount(data[1]);
              _this.initAutoPayment(data[2]);
              deffered.resolve(data);
              _this.isLoading = false;
            },
            function (error) {
              _this.errors.push(
                "Unable to retrieve Billing Account Information."
              );
              _this.isLoading = false;
              console.error(error);
              deffered.reject(error);
            }
          );

          // This call must be separate, the returned promise cannot
          // include this call because the call is unreliable for new customers
          this.getScheduledPayments().then(
            function (data) {
              _this.scheduledPayments = data;
            },
            function (error) {}
          );

          return deffered.promise;
        },

        initBillingAccount: function (json) {
          json = this.safeObj(json);
          this.billingCycle = json.billingCycle;
          this.currentBalance = json.currentBalance;
          this.currentCharges = json.currentCharges;
          this.pastDueCharges = json.pastDueCharges;
          this.dealerCode = json.dealerCode;
          this.currency = json.currency;
        },
        initPaymentAccount: function (json) {
          json = this.safeObj(json);
          this.paymentSources = json.payment_sources;
          this.customerId = json.id;
          this.orbiCustomerId = json.orbipay_id;

          this.paymentSource = this.getActivePaymentSource();
          if (this.paymentSource != null) {
            this.orbiFundingAccountId = this.paymentSource.remote_id;
            this.accountSubType = this.paymentSource.account_sub_type;
          }
        },
        initAutoPayment: function (json) {
          this.isAutoPayment = json.Enabled;
        },
        chargeAccount: function (amount, userEmail) {
          let deffered = $q.defer();

          amount = +amount * 100;
          PaymentsService.chargeAccount(
            this.paymentSource.id,
            amount,
            this.dealerId,
            userEmail
          ).then(
            function (data) {
              this.hasConfirmedPayment = true;
              deffered.resolve(data);
            },
            function (error) {
              deffered.reject(error);
            }
          );

          return deffered.promise;
        },
        saveAutoPayment: function (dealerCode, enabled, paymentSourceId) {
          let deffered = $q.defer();

          PaymentsService.updateAutoPayment(
            dealerCode,
            enabled,
            paymentSourceId
          ).then(
            function (data) {
              deffered.resolve(data);
            },
            function (error) {
              deffered.reject(error);
            }
          );

          return deffered.promise;
        },

        /**
         * Checks if the dealer has a payments customer.
         */
        hasPaymentCustomer: function () {
          return this.customerId > 0;
        },

        /**
         * Check if the dealer has an active payment source.
         */
        hasPaymentSource: function () {
          return this.paymentSource != null;
        },

        /**
         * Checks if payment sources are required
         *
         */
        paymentSourceRequired: function () {
          return UserService.enforceDistributionSubscriberValidPaymentMethod();
        },

        /**
         * Gets the first valid payment source returned for the payment customer account.
         * It's currently only possible to have one active payment source at a time. Any
         * other payment sources will be marked as deactivated.
         */
        getActivePaymentSource: function () {
          if (!this.paymentSources || this.paymentSources.length === 0) {
            return null;
          }

          for (let i = 0; i < this.paymentSources.length; ++i) {
            let source = this.paymentSources[i];
            if (!source.is_deactivated) {
              return source;
            }
          }

          /* No active payment source found */
          return null;
        },

        getSourceDisplayName: function () {
          if (this.hasPaymentSource()) {
            return (
              this.getOrbiAccountType() +
              " " +
              this.paymentSource.name +
              " " +
              this.paymentSource.account_number_masked
            );
          } else {
            return "";
          }
        },

        canApplyPastDueFee: function () {
          //this account has past due charges
          if (this.pastDueCharges > 0) {
            //the account is NOT an ACH
            if (
              this.accountSubType != "checking" &&
              this.accountSubType != "savings"
            ) {
              return true; //fees CAN be applied to this account
            }
          }
          return false; //no fees can be appled to this account
        },

        /**
         * Gets the Orbipay account type; either "card" or "bank".
         */
        getOrbiAccountType: function () {
          return this.accountSubType.split("_").length > 1 ? "card" : "bank";
        },

        getScheduledPayments: function () {
          return PaymentsService.getScheduledPayments(this.dealerCode);
        },

        currencyIsUSD: function () {
          return this.currency === "USD";
        },

        /**
         *
         * @param {*} obj
         * @description
         * *Safely access any property on an object. If the property does not exist, return empty string.
         */
        safeObj: function (obj) {
          let safelyObj = new Proxy(obj, {
            get(target, name, receiver) {
              if (!Reflect.has(target, name)) {
                return "";
              }
              return Reflect.get(target, name, receiver);
            },
          });

          return safelyObj;
        },
      });
    };

    return BillingAccount;
  },
]);
