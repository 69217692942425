import graphql from "babel-plugin-relay/macro";
import DaRefreshButton from "common/components/web/DaRefreshButton";
import { addClassesIf } from "common/utils/web/css";
import BigBlueAddButton from "components/BigBlueAddButton";
import { TableDataCell } from "components/CameraTable/styles";
import {
  Input,
  Panel,
  Table,
  TableFooter,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { GenericPageFallback } from "components/GenericPageFallback";
import { TableHeaderSorting } from "components/MobileCredentials/TableStyledComponents";
import {
  AngularStateProvider,
  useStateDotGo,
} from "components/SystemDiagnostics/AngularStateProvider";
import { pipe } from "fp-ts/function";
import * as R from "ramda";
import * as React from "react";
import {
  RelayEnvironmentProvider,
  useLazyLoadQuery,
  useRefetchableFragment,
} from "react-relay/hooks";
import { objectSearch } from "search-filterer";
import { idAsString, toDealerId } from "securecom-graphql/client";
import styled from "styled-components/macro";
import { MassProgrammingGlobalHolidayDatesFragment$key } from "./__generated__/MassProgrammingGlobalHolidayDatesFragment.graphql";
import { MassProgrammingGlobalHolidayDatesQuery } from "./__generated__/MassProgrammingGlobalHolidayDatesQuery.graphql";
import { MassProgrammingGlobalHolidayDatesRefetchQuery } from "./__generated__/MassProgrammingGlobalHolidayDatesRefetchQuery.graphql";

enum SortKey {
  Id,
  CreatedBy,
  Type,
  Failed,
  Status,
}

export default function MassProgrammingGlobalHolidayDatesRoot({
  RelayService,
  $stateParams,
  $state,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AngularStateProvider state={$state}>
          <MassProgrammingGlobalHolidayDates
            dealerId={$stateParams.dealer_id}
            state={$state}
          />
        </AngularStateProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}
function MassProgrammingGlobalHolidayDates({
  dealerId,
  state,
}: {
  dealerId: string;
  state: any;
}) {
  const dealer = useLazyLoadQuery<MassProgrammingGlobalHolidayDatesQuery>(
    graphql`
      query MassProgrammingGlobalHolidayDatesQuery($id: ID!) {
        dealer: node(id: $id) {
          ... on Dealer {
            ...MassProgrammingGlobalHolidayDatesFragment
          }
        }
      }
    `,
    { id: idAsString(toDealerId(dealerId)) }
  );
  const [data, refetch] = useRefetchableFragment<
    MassProgrammingGlobalHolidayDatesRefetchQuery,
    MassProgrammingGlobalHolidayDatesFragment$key
  >(
    graphql`
      fragment MassProgrammingGlobalHolidayDatesFragment on Dealer
      @refetchable(queryName: "MassProgrammingGlobalHolidayDatesRefetchQuery") {
        globalHolidayDateJobs {
          scapiId
          personWhoStartedJob {
            userId
            firstName
            lastName
            email
          }
          jobData {
            operation
          }
          createdAt
          completedAt
          failedToUpdate
          groupStatus
          jobStatus
          totalSystems
          successfullyUpdated
          updateInProgress
          waitingForUpdate
          schedulerJobTypeId
        }
        globalHolidayDates {
          id
        }
      }
    `,
    dealer.dealer
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const handleRefetch = () => {
    setIsLoading(true);
    refetch(
      {},
      {
        fetchPolicy: "store-and-network",
        onComplete: () => {
          setIsLoading(false);
        },
      }
    );
  };
  const transformedData =
    data?.globalHolidayDateJobs?.map((job) => ({
      id: job?.scapiId ?? 0,
      createdBy: job?.personWhoStartedJob
        ? `${job?.personWhoStartedJob?.firstName ?? ""} ${
            job?.personWhoStartedJob?.lastName ?? ""
          }`.trim()
        : job?.personWhoStartedJob?.email ?? "",
      type: "XR",
      failed: job?.failedToUpdate ?? 0,
      status: job?.jobStatus ?? "",
    })) ?? [];
  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Descending,
    sortKey: SortKey.Id,
  });
  // break these out so changing page size doesn't cause it to re-search etc...
  const searchedEvents = React.useMemo(
    () =>
      pipe(transformedData, (events) =>
        objectSearch(
          paginationState.search,
          ["id", "createdBy", "type", "failed", "status"],
          events
        )
      ),
    [transformedData, paginationState.search]
  );
  const sortedEvents = React.useMemo(
    () =>
      R.sort(
        (paginationState.sortOrder === SortOrder.Ascending
          ? R.ascend
          : R.descend)((thing) => {
          switch (paginationState.sortKey) {
            case SortKey.Id:
              return thing.id;
            case SortKey.CreatedBy:
              return thing.createdBy;
            case SortKey.Type:
              return thing.type;
            case SortKey.Failed:
              return thing.failed;
            case SortKey.Status:
              return thing.status;

            default:
              return SortKey.Id;
          }
        }),
        searchedEvents
      ),
    [paginationState.sortKey, paginationState.sortOrder, searchedEvents]
  );

  const pagedEvents = React.useMemo(
    () =>
      R.splitEvery(paginationState.pageSize, sortedEvents)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, sortedEvents]
  );
  const [hoveredHeader, setHoveredHeader] = React.useState("");
  const maxPage = Math.ceil(sortedEvents.length / paginationState.pageSize);

  const nav = useStateDotGo();
  return (
    <>
      <div className="row">
        <div className="col-12 pad-h-16">
          <div
            className="page-header"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="page-header__left">
              <div className="page-header__title mar-r-8">
                Send Global Holiday Dates
              </div>
              {/* This should be hidden if no holiday dates exist */}
              <BigBlueAddButton
                disabled={data?.globalHolidayDates?.length === 0}
                onClick={() =>
                  state.go("app.dealer.globalHolidayDatesJobNew", {
                    dealer_id: dealerId,
                  })
                }
              />
            </div>
            <div className="page-header__right">
              <DaRefreshButton
                showIcon={isLoading}
                isRefreshing={isLoading}
                onClick={handleRefetch}
              ></DaRefreshButton>
            </div>
          </div>
        </div>
      </div>
      <Panel.BorderlessTable style={{ minHeight: "48rem" }}>
        <TableHeader>
          <TableHeader.Left>
            <label className="sr-only" htmlFor="search-input"></label>
            <Input.TableSearch
              id="search-input"
              value={paginationState.search}
              onChange={(search) => {
                dispatch({
                  type: ActionType.SetSearch,
                  search,
                });
              }}
            />
          </TableHeader.Left>
        </TableHeader>
        <Table>
          <thead>
            <tr>
              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.Id ||
                        hoveredHeader === "Id",
                      "is-active",
                    ],
                    [
                      paginationState.sortKey === SortKey.Id &&
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortKey === SortKey.Id &&
                        paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Id) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Id,
                    });
                  }
                }}
                onMouseEnter={() => setHoveredHeader("Id")}
                onMouseLeave={() => setHoveredHeader("")}
              >
                Id
              </TableHeaderSorting>
              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.CreatedBy ||
                        hoveredHeader === "CreatedBy",
                      "is-active",
                    ],
                    [
                      paginationState.sortKey === SortKey.CreatedBy &&
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortKey === SortKey.CreatedBy &&
                        paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.CreatedBy) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.CreatedBy,
                    });
                  }
                }}
                onMouseEnter={() => setHoveredHeader("CreatedBy")}
                onMouseLeave={() => setHoveredHeader("")}
              >
                Created By
              </TableHeaderSorting>
              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.Type ||
                        hoveredHeader === "Type",
                      "is-active",
                    ],
                    [
                      paginationState.sortKey === SortKey.Type &&
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortKey === SortKey.Type &&
                        paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Type) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Type,
                    });
                  }
                }}
                onMouseEnter={() => setHoveredHeader("Type")}
                onMouseLeave={() => setHoveredHeader("")}
              >
                Type
              </TableHeaderSorting>

              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.Failed ||
                        hoveredHeader === "Failed",
                      "is-active",
                    ],
                    [
                      paginationState.sortKey === SortKey.Failed &&
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortKey === SortKey.Failed &&
                        paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Failed) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Failed,
                    });
                  }
                }}
                onMouseEnter={() => setHoveredHeader("Failed")}
                onMouseLeave={() => setHoveredHeader("")}
              >
                Failed
              </TableHeaderSorting>
              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.Status ||
                        hoveredHeader === "Status",
                      "is-active",
                    ],
                    [
                      paginationState.sortKey === SortKey.Status &&
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortKey === SortKey.Status &&
                        paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Status) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Status,
                    });
                  }
                }}
                onMouseEnter={() => setHoveredHeader("Status")}
                onMouseLeave={() => setHoveredHeader("")}
              >
                Status
              </TableHeaderSorting>
            </tr>
          </thead>

          <tbody>
            {!R.isEmpty(pagedEvents) ? (
              pagedEvents.map((event) => (
                <tr key={event.id}>
                  <td>{event.id ?? null}</td>
                  <td>{event.createdBy}</td>
                  <td>{event.type}</td>
                  <td>{event.failed === 0 ? "-" : event.failed}</td>
                  <td>
                    {event.status === "success" ? (
                      <SuccessText>Complete</SuccessText>
                    ) : event.status === "fail" ? (
                      <FailedText>Failed</FailedText>
                    ) : (
                      <>{event.status}</>
                    )}
                  </td>
                  <td>
                    <ViewButton
                      onClick={() => {
                        nav(
                          "app.dealer.globalHolidayDatesViewSingleJob",
                          {
                            job_id: event.id,
                          },
                          { reload: true }
                        );
                      }}
                    >
                      View
                    </ViewButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <TableDataCell colSpan={5}>
                  No holiday dates found.
                </TableDataCell>
              </tr>
            )}
          </tbody>
        </Table>
        <TableFooter>
          <TableFooter.Left></TableFooter.Left>
          <TableFooter.Center>
            <Paginate.Paginator
              state={paginationState}
              dispatch={dispatch}
              maxPage={maxPage}
            />
          </TableFooter.Center>
          <TableFooter.Right>
            <Paginate.ItemsPerPage
              state={paginationState}
              dispatch={dispatch}
            />
          </TableFooter.Right>
        </TableFooter>
      </Panel.BorderlessTable>
    </>
  );
}

const ViewButton = styled.button`
  color: #3d85a3;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.2s ease-out;
`;
const SuccessText = styled.span`
  color: var(--color-success-500);
  display: flex;
  justify-content: flex-start;
`;
const FailedText = styled.span`
  color: var(--color-danger-500);
  display: flex;
  justify-content: flex-start;
`;
