/**
 * @generated SignedSource<<b4f17c0594f6bf695cceb9a7a107caf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SiteOutputType = "FLOORS" | "OUTPUTS";
import { FragmentRefs } from "relay-runtime";
export type SiteOutputModulesSection_site$data = {
  readonly outputModulesConnection: {
    readonly nodes: ReadonlyArray<{
      readonly address: number;
      readonly controlSystem: {
        readonly doors: ReadonlyArray<{
          readonly name: string;
        }>;
        readonly serialNumber: string;
      } | null;
      readonly id: string;
      readonly name: string;
      readonly outputsConnection: {
        readonly nodes: ReadonlyArray<{
          readonly name: string;
          readonly relayNumber: number | null;
          readonly type: SiteOutputType;
        }>;
      };
      readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesFormExistingModule_siteOutputModule">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesFormNewModule_site">;
  readonly " $fragmentType": "SiteOutputModulesSection_site";
};
export type SiteOutputModulesSection_site$key = {
  readonly " $data"?: SiteOutputModulesSection_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesSection_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteOutputModulesSection_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteOutputModulesFormNewModule_site"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "include",
          "value": "OUTPUTS"
        }
      ],
      "concreteType": "SiteOutputModulesConnection",
      "kind": "LinkedField",
      "name": "outputModulesConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteOutputModule",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SiteOutputModulesFormExistingModule_siteOutputModule"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteControlSystem",
              "kind": "LinkedField",
              "name": "controlSystem",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serialNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SiteDoor",
                  "kind": "LinkedField",
                  "name": "doors",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteOutputModuleOutputsConnection",
              "kind": "LinkedField",
              "name": "outputsConnection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SiteOutput",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relayNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "outputModulesConnection(include:\"OUTPUTS\")"
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "fd265afacec476703c4ee08feeb9bcf2";

export default node;
