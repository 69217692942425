export type CameraType = "camect" | "ienso" | "legacy" | "v-6000";

export enum CameraStatus {
  Offline = "offline",
  Online = "online",
}

export interface StreamableCamera {
  authToken: string;
  cameraId: string;
  cameraType: CameraType;
  clipsPath?: string;
  env: string;
  hubId: string;
  lastCheckin?: Date;
  name: string;
  status: CameraStatus;
}
