import DaColors from "app/common/DaColors";
import CleanDotsSpinner from "dealer-admin/src/components/DaStyledElements/CleanDotsSpinner";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: ${DaColors.Black};
  color: ${DaColors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`;

const VideoLoadingOverlay = () => (
  <Wrapper>
    <CleanDotsSpinner size={16} color={DaColors.White} />
    <div>Connecting...</div>
  </Wrapper>
);

export default VideoLoadingOverlay;
