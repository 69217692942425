/**
 * @name App.factory.RecentActivityService
 *
 * Service for showing Recent Activity.
 *
 */
App.service("RecentActivityService", [
  "UserService",
  function (UserService) {
    const MAX_RECENT_SYSTEM_SEARCH_RESULTS = 3;
    const RECENT_SYSTEM_SEARCH_STORAGE_KEY = "recentSystemSearches";
    const MAX_RECENT_CUSTOMER_SEARCH_RESULTS = 3;
    const RECENT_CUSTOMER_SEARCH_STORAGE_KEY = "recentCustomerSearches";

    this.props = {
      storageKeys: {
        system: RECENT_SYSTEM_SEARCH_STORAGE_KEY,
        customer: RECENT_CUSTOMER_SEARCH_STORAGE_KEY,
      },
    };

    const addUserAndDealerToKey = (key) => {
      return `${key}-${UserService.dealer_id}-${UserService?.user?.id}`;
    };

    const getActivityList = (key) => {
      return (
        angular.fromJson(
          window.localStorage.getItem(addUserAndDealerToKey(key))
        ) || []
      );
    };

    this.getList = function (key) {
      return getActivityList(key);
    };

    this.updateList = function (key, entry) {
      var recentSearches = [];
      var indexInResults = -1;
      var maxEntries = 0;
      switch (key) {
        case RECENT_SYSTEM_SEARCH_STORAGE_KEY:
          recentSearches = getActivityList(key);
          indexInResults = recentSearches.findIndex(function (rs) {
            return rs.id === entry.id && rs.isSite === entry.isSite;
          });
          maxEntries = MAX_RECENT_SYSTEM_SEARCH_RESULTS;
          break;
        case RECENT_CUSTOMER_SEARCH_STORAGE_KEY:
          recentSearches = getActivityList(key);
          indexInResults = recentSearches.indexOf(entry);
          maxEntries = MAX_RECENT_CUSTOMER_SEARCH_RESULTS;
          break;
        default:
          console.warn(
            "RecentActivityService->updateList() - unknown key: " + key
          );
          return;
      }
      // If the item is already in the list, remove it
      if (indexInResults > -1) {
        recentSearches.splice(indexInResults, 1);
      }
      // Add the entry to the beginning of the list (as it is the most recently searched)
      recentSearches.unshift(entry);
      // Trim the list if it exceeds the max number of results
      if (recentSearches.length > maxEntries) {
        var numberOver = recentSearches.length - maxEntries;
        recentSearches.splice(recentSearches.length - numberOver, numberOver);
      }
      // Store the result
      window.localStorage.setItem(
        addUserAndDealerToKey(key),
        angular.toJson(recentSearches)
      );
    };

    this.removeSystem = function (id, isSite) {
      var indexInResults = -1;
      var recentSystems = getActivityList(RECENT_SYSTEM_SEARCH_STORAGE_KEY);
      indexInResults = recentSystems.findIndex(function (s) {
        return +s.id === +id && s.isSite === !!isSite;
      });
      if (indexInResults > -1) {
        recentSystems.splice(indexInResults, 1);
      }
      window.localStorage.setItem(
        addUserAndDealerToKey(RECENT_SYSTEM_SEARCH_STORAGE_KEY),
        angular.toJson(recentSystems)
      );
    };
  },
]);
