/**
 * Created by kweigand on 5/27/15.
 */
App.controller("SchedulesXTOutputCtrl", [
  "$scope",
  "$filter",
  "UserService",
  "$stateParams",
  "xtOutputScheduleModel",
  "scheduleUtilService",
  "PanelProgArchiveService",
  function (
    $scope,
    $filter,
    UserService,
    $stateParams,
    xtOutputScheduleModel,
    scheduleUtilService,
    PanelProgArchiveService
  ) {
    $scope.controlSystem = UserService.controlSystem;
    $scope._family =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].hardware_family;
    var panel_model =
      $scope.controlSystem.panels[$scope.controlSystem.panel_index]
        .hardware_model;

    $scope.output_number = $stateParams.number;
    $scope.outputs = [];
    $scope.PanelProgArchiveService = PanelProgArchiveService;

    // Filters used to modify how the multi-panel list operates
    $scope.multiPanelFilters.push({
      field: "ITEM.software_version",
      operator: ">=",
      value: "100", // This is set dynamically on load and when sunrise_sunset values change
      action: "disable",
      displayTemplate:
        "Panel system version is less than {{ multiPanelFilterData.value }}.",
    });

    $scope.multiPanelFilters.push({
      field: "ITEM.hardware_model",
      operator: "==",
      value: panel_model,
      action: "hide",
    });

    function initPage() {
      $scope.base.isBusy = true;

      // Only proceed if the output_schedules concept has been loaded. Otherwise, we're waiting for all_concepts_retrieved to fire.
      if (
        (angular.isDefined($scope.Panel["output_schedules"]) &&
          angular.isDefined($scope.Panel["output_informations"]) &&
          panel_model !== "TMS6") ||
        (panel_model === "TMS6" &&
          angular.isDefined($scope.Panel["output_schedules"]))
      ) {
        // Are we creating a new schedule or editing an existing one?
        if ($scope.base.mode == "New") {
          // Create a new output schedule and update the Schedule Service's currentSchedule property
          $scope.Panel.newItem("output_schedules")
            .then(function (scheduleData) {
              buildSchedule(scheduleData);
              $scope.base.currentSchedule.number = undefined;
            })
            .catch(function (error) {
              console.error(error);
            });

          if (panel_model === "TMS6") {
            // For TMS6, define static outputs and filter out existing ones
            const allOutputs = [
              { number: 1, name: "Output 1" },
              { number: 2, name: "Output 2" },
            ];
            $scope.outputs = allOutputs.filter(function (op) {
              const outputExists = $filter("filter")(
                $scope.Panel["output_schedules"],
                function (item) {
                  return Number(item.number) === Number(op.number);
                },
                true
              ).length;
              return (
                !outputExists &&
                $scope.Panel.isValidNumber("output_schedules", op.number)
              );
            });
          } else {
            // For other panels, get a list of all outputs that are valid and aren't assigned to a schedule
            angular.forEach($scope.Panel["output_informations"], function (op) {
              var output = $filter("filter")(
                $scope.Panel["output_schedules"],
                { number: op.number },
                true
              ).length;
              if (
                !output &&
                $scope.Panel.isValidNumber("output_schedules", op.number)
              ) {
                $scope.outputs.push(op);
              }
            });
          }

          $scope.base.isBusy = false;
        } else {
          // Editing an existing schedule
          var scheduleData = $filter("filter")(
            $scope.Panel["output_schedules"],
            { number: $scope.output_number },
            true
          )[0];
          buildSchedule(scheduleData);

          // Pad the schedule number since VK will return just the number
          $scope.base.currentSchedule.number = $filter("zpad")(
            $scope.base.currentSchedule.number,
            3
          );

          getScheduledOutput(scheduleData.number);
          $scope.base.isBusy = false;
        }
      }
    }

    function buildSchedule(scheduleData) {
      $scope.base.currentSchedule = angular.extend(
        scheduleData,
        new xtOutputScheduleModel()
      );
      $scope.base.currentScheduleType = "output_schedule";
      $scope.base.currentSchedule.initSchedule();
      scheduleUtilService.initTwilightData($scope.base.currentSchedule);
      $scope.updateMultiPanelFilters();
    }

    function getScheduledOutput(outputNumber) {
      let output;

      // If TMS6, we need to use the output_schedules concept to get the output name
      if (panel_model === "TMS6") {
        output = $filter("filter")(
          $scope.Panel["output_schedules"],
          { number: "outputNumber" },
          true
        ).shift();
      } else {
        // Use output_informations for other models
        output = $filter("filter")(
          $scope.Panel["output_informations"],
          { number: "outputNumber" },
          true
        ).shift();
      }
      // If we find an output with a matching number, use its name, else return the default value
      if (angular.isDefined(output)) {
        $scope.outputs.push(output);
      } else {
        $scope.outputs.push({
          number: outputNumber,
          name: "Output " + outputNumber,
        });
      }
    }

    initPage();

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     */
    $scope.$on("all_concepts_retrieved", function () {
      // Loop through the favorites and add a left zero...painful.
      angular.forEach($scope.Panel.outputs, function (output) {
        output.number = $filter("zpad")(output.number, 3);
      });
      initPage();
    });
  },
]);
