/**
 * @generated SignedSource<<02ed4792cb271e7efc5c92749bdb84ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsClosingWaitField_systemOptions$data = {
  readonly closingWait?: boolean;
  readonly " $fragmentType": "SystemOptionsClosingWaitField_systemOptions";
};
export type SystemOptionsClosingWaitField_systemOptions$key = {
  readonly " $data"?: SystemOptionsClosingWaitField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsClosingWaitField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "closingWait",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsClosingWaitField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "42a1df743e18d2844d75cbdf754ad867";

export default node;
