import DaColors from "app/common/DaColors";
import Icon from "components/Icon";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import BoxShadows from "./StyleConstants/BoxShadows";

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${DaColors.Info500};
  color: ${DaColors.White};
  border: 0.1rem solid ${DaColors.Info500};
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 1000rem;

  &:hover {
    background: ${DaColors.Info400};
    box-shadow: ${BoxShadows.ButtonHover};
    transition: all 0.3s ease;
  }
`;

const CleanAddButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Wrapper {...props}>
      <Icon name="add" size="1.4rem" />
    </Wrapper>
  );
};

export default CleanAddButton;
