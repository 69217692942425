/**
 *
 * CircleCancelIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CircleCancelIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M54.632,9.415C48.583,3.38,40.543,0.057,31.986,0.057c-8.546,0-16.581,3.322-22.626,9.353C3.324,15.434,0,23.457,0,31.999
    c0,8.545,3.327,16.57,9.366,22.596c6.039,6.027,14.08,9.347,22.644,9.348c0.001,0,0.001,0,0.001,0
    c8.558,0,16.593-3.317,22.628-9.339C60.675,48.58,64,40.558,64,32.015C64,23.468,60.673,15.442,54.632,9.415z M31.994,4.057
    c6.805,0,13.234,2.412,18.326,6.82L31.999,29.18L13.672,10.872C18.76,6.467,25.187,4.057,31.994,4.057z M4,31.999
    c0-6.807,2.419-13.231,6.839-18.304L29.17,32.007L10.847,50.312C6.423,45.236,4,38.809,4,31.999z M32.01,60
    c-6.815,0-13.247-2.438-18.33-6.836l18.319-18.316l18.328,18.33C45.247,57.574,38.821,60,32.012,60H32.01z M53.16,50.319
    L34.829,32.007L53.154,13.7C57.579,18.776,60,25.204,60,32.015C60,38.822,57.581,45.246,53.16,50.319z"
      />
    </g>
  </SvgIcon>
);

export default CircleCancelIcon;
