import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathAlarmSwitchoverField_communicationPath$key } from "./__generated__/CommPathAlarmSwitchoverField_communicationPath.graphql";

export const commPathAlarmSwitchoverFieldId = (number: string) =>
  `comm-path-alarm-switchover-${number}`;

function CommPathAlarmSwitchoverField() {
  const [
    {
      number,
      alarmSwitchover,
      alarmSwitchoverMin,
      alarmSwitchoverMax,
      commType,
    },
    updateCommPath,
  ] = useCommPathFragment<CommPathAlarmSwitchoverField_communicationPath$key>(
    graphql`
      fragment CommPathAlarmSwitchoverField_communicationPath on CommunicationPath {
        number
        alarmSwitchover
        alarmSwitchoverMin
        alarmSwitchoverMax
        commType
      }
    `
  );

  const fieldId = commPathAlarmSwitchoverFieldId(String(number));
  const { current: originalValue } = React.useRef(alarmSwitchover);
  const disabled = ["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Alarm Switchover"
      tooltip="Number of attempts to send an alarm message on this path before moving to the next available backup path."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={alarmSwitchover.toString()}
        disabled={disabled}
        required
        inlineHelp={`${alarmSwitchoverMin}–${alarmSwitchoverMax}`}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "alarmSwitchover"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(alarmSwitchoverMin, alarmSwitchoverMax, valueAsNumber);
            recordProxy.setValue(value, "alarmSwitchover");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathAlarmSwitchoverField;
