import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, LockoutCode } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { LockoutCodeContextProvider } from "../common/LockoutCodeFields/LockoutCodeContext";
import LockoutCodeField from "../common/LockoutCodeFields/LockoutCodeField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XFLockoutCodeProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XFLockoutCodeProgrammingConceptFormInline_controlSystem.graphql";
import { XFLockoutCodeProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFLockoutCodeProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XFLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation,
} from "./__generated__/XFLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XFLockoutCodeProgrammingConceptFormLockoutCodeSendMutation,
  XFLockoutCodeProgrammingConceptFormLockoutCodeSendMutation$data,
} from "./__generated__/XFLockoutCodeProgrammingConceptFormLockoutCodeSendMutation.graphql";
import { XFLockoutCodeProgrammingConceptForm_controlSystem$key } from "./__generated__/XFLockoutCodeProgrammingConceptForm_controlSystem.graphql";

export const title = "Lockout Code";
export const conceptId = "xf-lockout-code";

export const getState = (
  controlSystem: XFLockoutCodeProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFLockoutCodeProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          lockoutCode {
            id
            code
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XFLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation(
    $id: ID!
  ) {
    refreshLockoutCode(id: $id) {
      ... on RefreshLockoutCodeSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFLockoutCodeProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XFLockoutCodeProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshLockoutCode, isRefreshing] =
    useMutation<XFLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshLockoutCode({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, __typename, error } =
              response.refreshLockoutCode;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Lockout Code Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshLockoutCode: {
                    __typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Lockout Code: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Lockout Code",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XFLockoutCodeProgrammingConceptFormLockoutCodeSendMutation(
    $systemId: ID!
    $code: String!
  ) {
    sendLockoutCodeProgramming(systemId: $systemId, code: $code) {
      ... on SendLockoutCodeProgrammingSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XFLockoutCodeProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendLockoutCodeProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XFLockoutCodeProgrammingConceptFormLockoutCodeSendMutation$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendLockoutCodeProgramming.controlSystem) {
    const operation = createOperationDescriptor(sendMutationConcreteRequest, {
      id: response.sendLockoutCodeProgramming.controlSystem.id,
    });
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        sendLockoutCodeProgramming: {
          __typename: response.sendLockoutCodeProgramming.__typename,
          controlSystem: getState(
            response.sendLockoutCodeProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFLockoutCodeProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendLockoutCode, isSendingLockoutCode] =
    useMutation<XFLockoutCodeProgrammingConceptFormLockoutCodeSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { lockoutCode },
        } = getState(props.controlSystem);
        sendLockoutCode({
          variables: {
            systemId,
            code: lockoutCode?.code ?? "00000",
          },
          onCompleted: (response) => {
            const sendErrors: SaveErrors = [];
            if (response.sendLockoutCodeProgramming.controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Lockout Code Programming Sent to the System",
                });
              }
              resetLastUpdated(conceptId);
              updateOriginalControlSystem(response, parentRelayEnv);
            } else if (response.sendLockoutCodeProgramming.errors) {
              sendErrors.push({
                programmingConcept: title,
                errors: response.sendLockoutCodeProgramming.errors,
              });
            }
            resolve(sendErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSendingLockoutCode,
  ];
};

const readLockoutCodeTemplateData = (
  programmingTemplateConcepts: XFLockoutCodeProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFLockoutCodeProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        lockoutCode {
          included
          code {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).lockoutCode ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XFLockoutCodeProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readLockoutCodeTemplateData(programmingTemplateConcepts);

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const lockoutCodeRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "lockoutCode",
      "LockoutCode"
    ) as unknown as RecordProxy<LockoutCode>;

    applyTemplateScalarDataToRecordProxy(lockoutCodeRecordProxy, templateData);
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFLockoutCodeProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFLockoutCodeProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            lockoutCode {
              id
              ...LockoutCodeContext_lockoutCode
              ...LockoutCodeField_lockoutCode
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
          }
        }
      `
    );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <LockoutCodeContextProvider lockoutCode={controlSystem.panel.lockoutCode}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          title={title}
          helpLink={`${programmingGuideUrl}#Set%20Lockout%20Code`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.lockoutCode
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <LockoutCodeField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </LockoutCodeContextProvider>
    </PanelContextProvider>
  );
}
