/**
 * @generated SignedSource<<6cd9cd86968c075649e93ee33bb5b70f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone$data = {
  readonly id: string;
  readonly name: string;
  readonly number: string;
  readonly " $fragmentType": "XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone";
};
export type XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone$key = {
  readonly " $data"?: XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "e125eafe128b7ed734c473ff569a3a26";

export default node;
