import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsPassphrase734NField_networkOptions$key } from "./__generated__/NetworkOptionsPassphrase734NField_networkOptions.graphql";

export const networkOptionsPassphrase734NFieldId = () =>
  "network-options-passphrase-734-n";

function NetworkOptionsPassphrase734NField() {
  const [{ passphrase734N }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsPassphrase734NField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsPassphrase734NField_networkOptions on NetworkOptions {
          passphrase734N
        }
      `
    );

  const fieldId = networkOptionsPassphrase734NFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="734N Passphrase"
      tooltip="Message encryption requires an 8-16 character alphanumeric passphrase. Must be the same as the '734N Passphrase' programmed in the 734N or 734N-WiFi."
    >
      <TextInput
        id={fieldId}
        value={passphrase734N}
        pattern={"([A-Z0-9 .*#\\-]{8,16}|)"}
        inlineHelp="8-16 Characters A-Z 0-9 .*#-"
        maxLength={16}
        validationMessage="8-16 Characters A-Z 0-9 .*#-"
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value.toUpperCase(), "passphrase734N");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsPassphrase734NField;
