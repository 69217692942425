import {
  systemIsScheduledForFutureInstall,
  systemWillBeAutoProgrammed,
} from "utils/control-systems";

/**
 * @ngdoc service
 * @name App.factory:ControlSystemsService
 *
 * @description
 * Service for loading and updating Control Systems for a particular Customer (or User ID - future).
 *
 */
App.factory("ControlSystemsService", [
  "$rootScope",
  "$q",
  "UserService",
  "controlSystemModel",
  "$filter",
  "SimManagementService",
  "ControlSystemService",
  "DealerService",
  "Customer",
  "ReplacePanelService",
  "ClientEventsService",
  "PanelProgArchiveService",
  "RelayService",
  function (
    $rootScope,
    $q,
    UserService,
    controlSystemModel,
    $filter,
    SimManagementService,
    ControlSystemService,
    DealerService,
    Customer,
    ReplacePanelService,
    ClientEventsService,
    PanelProgArchiveService,
    RelayService
  ) {
    var controlSystemsService = {
      /**
       * @ngdoc object
       * @name property:controlSystems
       * @type Array
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Holds the Array of Control Systems for the current UserService customer id.
       */
      controlSystems: [],
      /**
       * @ngdoc object
       * @name property:currentControlSystem
       * @type Object
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Reference to the currently selected Control System
       */
      currentControlSystem: {},
      /**
       * @ngdoc object
       * @name property:control_systems_list
       * @type Array
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Holds a key pair list of Customer Number and Control System Number.  The list is used to populate the related
       * Control System Models and is populated into the 'controlSystems' array.
       *
       * @example
       * <pre>[{customer_id: 1234, control_system_id: 4321}, {customer_id: 1234, control_system_id: 5678}]</pre>
       */
      control_systems_list: [],
      /**
       * @ngdoc object
       * @name property:dealer
       * @type Object
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Holds the Dealer information for the currently selected Control System (currentControlSystem).
       */
      dealer: {}, //TODO: Move to Dealer Service
      //Currently not implemented
      dealer_licenses: [], //TODO: Move to Dealer Service
      /**
       * @ngdoc object
       * @name property:cellular_available
       * @type Boolean
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Indicates if SIM activation/modification/deactivation is available for the currently selected Control System (currentControlSystem).
       */
      cellular_available: true,
      /**
       * @ngdoc object
       * @name property:sim
       * @type Object
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Holds the SIM information for the currently selected Control System (currentControlSystem).  As SIM is queried,
       * full SIM status and attributes are populated.
       */
      sim: { isAvailable: false, status_type: "" },
      /**
       * @ngdoc object
       * @name property:isBusy
       * @type Boolean
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Indicates if the ControlSystemsService is busy with a long running operation.
       */
      isBusy: false,
      /**
       * @ngdoc object
       * @name method:getControlSystemsList
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Creates each Control System from the 'control_system_list' and populates the 'controlSystems' array.
       *
       * @param {Boolean} refresh Indicates whether to refresh from server data
       * @return {promise} The promise of the 'controlSystems' array
       */
      getControlSystemsList: function (refresh) {
        refresh = typeof refresh === "undefined" ? false : refresh;
        var deferred = $q.defer();
        var _this = this;
        var controlSystemsPromises = [];
        if (!this.controlSystems.length || refresh) {
          this.destroyControlSystems(); //if empty or refresh destroy existing Control Systems array
          for (var x = 0; x < this.control_systems_list.length; x++) {
            //var controlSystem = new controlSystemModel(this.control_systems_list[x]);
            //controlSystemsPromises.push(controlSystem.get());
            controlSystemsPromises.push(
              ControlSystemService.edit(
                this.control_systems_list[x].customer_id,
                this.control_systems_list[x].control_system_id
              )
            );
          }
          $q.all(controlSystemsPromises)
            .then(
              function (dataArray) {
                _this.controlSystems = { control_systems: dataArray };
                deferred.resolve(_this.controlSystems);
              },
              function (error) {
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          deferred.resolve(this.controlSystems);
        }
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:showControlSystem
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Returns and sets the selected Control System based on the Control System ID.  Requires View permission
       *
       * @param {Number} controlSystemID The desired Control System's ID Number
       * @param {Number} customerID The Customer ID
       * @return {promise} The promise of the requested Control System's model
       */
      showControlSystem: function (controlSystemID, customerID) {
        UserService.isBusy = true;
        var deferred = $q.defer();
        var _this = this;
        var controlSystem = new controlSystemModel({
          customer_id: customerID,
          control_system_id: controlSystemID,
        });
        $q.all([
          controlSystem.showControlSystem(),
          UserService.setDealerInfo(
            new DealerService({ customer_id: customerID })
          ),
        ])
          .then(
            function (data) {
              _this.currentControlSystem = data[0];
              let neededDataPromises = [];
              neededDataPromises.push(
                ClientEventsService.initialConnection.getEvents(
                  _this.currentControlSystem.id
                )
              );
              neededDataPromises.push(
                PanelProgArchiveService.qualifies(_this.currentControlSystem.id)
              );
              $q.all(neededDataPromises)
                .then(
                  function (neededData) {
                    _this.currentControlSystem.qualifiesForProgArchive =
                      neededData[1];
                    UserService.setControlSystem(_this.currentControlSystem);
                    UserService.panelConnected =
                      _this.currentControlSystem.panels[0].online &&
                      ClientEventsService.initialConnection.hasBeenEstablished();
                    UserService.panel_id =
                      _this.currentControlSystem.panels[
                        _this.currentControlSystem.panel_index
                      ].id;
                    UserService.setCustomerInfo(new Customer(customerID));
                    _this.dealer = UserService.dealerInfo; //TODO Remove this assignment and change references to the User Service
                    UserService.isBusy = false;
                    deferred.resolve(_this.currentControlSystem);
                  },
                  function (error) {
                    console.error(
                      `ControlSystemsService->showControlSystem() - Error getting needed data: ${angular.toJson(
                        error
                      )}`
                    );
                    UserService.isBusy = false;
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(
                    `ControlSystemsService->showControlSystem() - Caught error getting needed data: ${angular.toJson(
                      error
                    )}`
                  );
                });
            },
            function (error) {
              console.error(
                `ControlSystemsService->showControlSystem() - Error getting system / dealer: ${angular.toJson(
                  error
                )}`
              );
              UserService.isBusy = false;
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(
              `ControlSystemsService->showControlSystem() - Error getting system / dealer: ${angular.toJson(
                error
              )}`
            );
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getControlSystem
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Returns and sets the selected Control System based on the Control System ID.  Requires Edit permission
       *
       * @param {Number} controlSystemID The desired Control System's ID Number
       * @param {Number} customerID The Customer ID
       * @return {promise} The promise of the requested Control System's model
       */
      getControlSystem: function (controlSystemID, customerID) {
        UserService.isBusy = true;
        var deferred = $q.defer();
        var _this = this;
        var controlSystem = new controlSystemModel({
          customer_id: customerID,
          control_system_id: controlSystemID,
        });
        $q.all([
          controlSystem.get(),
          UserService.setDealerInfo(
            new DealerService({ customer_id: customerID })
          ),
        ])
          .then(
            function (data) {
              _this.currentControlSystem = data[0];
              //Check to see if the panel is one of the old XR500 family.  If so then change the panel hardware model to XR500 to make sure
              //we show the correct cell plans.
              if (
                _this.currentControlSystem.panels[0].hardware_family == "XR500"
              ) {
                _this.currentControlSystem.panels[0].hardware_model = "XR500";
              }
              let neededDataPromises = [];
              neededDataPromises.push(
                ClientEventsService.initialConnection.getEvents(
                  _this.currentControlSystem.id
                )
              );
              neededDataPromises.push(
                PanelProgArchiveService.qualifies(_this.currentControlSystem.id)
              );
              $q.all(neededDataPromises)
                .then(
                  function (neededData) {
                    if (
                      ReplacePanelService.replacingPanel() &&
                      +ReplacePanelService.replacementPanelData.panelId !==
                        +_this.currentControlSystem.control_system_id
                    ) {
                      ReplacePanelService.init();
                    }
                    _this.currentControlSystem.qualifiesForProgArchive =
                      neededData[1];
                    UserService.setControlSystem(_this.currentControlSystem);
                    UserService.panelConnected =
                      _this.currentControlSystem.panels[0].online &&
                      ClientEventsService.initialConnection.hasBeenEstablished();
                    UserService.panel_id =
                      _this.currentControlSystem.panels[
                        _this.currentControlSystem.panel_index
                      ].id;
                    UserService.setCustomerInfo(new Customer(customerID));
                    _this.dealer = UserService.dealerInfo; //TODO Remove this assignment and change references to the User Service
                    // Determine whether we should lookup the SIM by identifier or comm_address
                    var sim_identifier =
                      _this.currentControlSystem.panels[
                        _this.currentControlSystem.panel_index
                      ].sim_identifier;
                    var comm_address =
                      _this.currentControlSystem.panels[
                        _this.currentControlSystem.panel_index
                      ].comm_address;
                    var simIdentifier = sim_identifier
                      ? sim_identifier
                      : comm_address;
                    if (
                      shouldGetSIMHistory(
                        _this.currentControlSystem.panels[
                          _this.currentControlSystem.panel_index
                        ].comm_type,
                        _this.currentControlSystem
                      )
                    ) {
                      _this
                        .findSim(simIdentifier)
                        .then(
                          function () {
                            if (_this.sim.msisdn && _this.sim.isAvailable) {
                              _this.currentControlSystem.panels[
                                _this.currentControlSystem.panel_index
                              ].comm_address = _this.sim.msisdn;
                            }
                            if (
                              !_this.sim.msisdn &&
                              _this.sim.isAvailable &&
                              _this.sim.status_type == "new"
                            ) {
                              _this.currentControlSystem.panels[
                                _this.currentControlSystem.panel_index
                              ].comm_address = "";
                            }
                            UserService.isBusy = false;
                            deferred.resolve(_this.currentControlSystem);
                          },
                          function (error) {
                            //if the sim lookup fails, we just keep going, but disallow activate, modify, deactivate.
                            console.error(
                              `ControlSystemsService->getControlSystem() - Error finding sim: ${angular.toJson(
                                error
                              )}`
                            );
                            _this.sim = { isAvailable: false };
                            _this.cellular_available = false;
                            UserService.isBusy = false;
                            deferred.resolve(_this.currentControlSystem);
                          }
                        )
                        .catch(function (error) {
                          console.error(
                            `ControlSystemsService->getControlSystem() - Caught error getting sim history: ${angular.toJson(
                              error
                            )}`
                          );
                        });
                    } else {
                      _this.sim = { isAvailable: false };
                      UserService.isBusy = false;
                      deferred.resolve(_this.currentControlSystem);
                    }
                  },
                  function (error) {
                    console.error(
                      `ControlSystemsService->getControlSystem() - Error getting needed data: ${angular.toJson(
                        error
                      )}`
                    );
                    UserService.isBusy = false;
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(
                    `ControlSystemsService->getControlSystem() - Caught error getting needed data: ${angular.toJson(
                      error
                    )}`
                  );
                });
            },
            function (error) {
              console.error(
                `ControlSystemsService->getControlSystem() - Error getting system / dealer: ${angular.toJson(
                  error
                )}`
              );
              UserService.isBusy = false;
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(
              `ControlSystemsService->getControlSystem() - Caught error getting system / dealer: ${angular.toJson(
                error
              )}`
            );
          });
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:createControlSystem
       * @methodOf App.factory:createControlSystem
       *
       * @description
       * Creates and returns a new Control System model
       *
       * @return {Object} A new Control System model
       */
      createControlSystem: function () {
        this.sim = { isAvailable: false, status_type: "" };
        return new controlSystemModel({
          customer_id: UserService.customer_id,
          control_system_id: "new",
        });
      },
      /**
       * @ngdoc object
       * @name method:destroyControlSystems
       * @methodOf App.factory:createControlSystem
       *
       * @description
       * Destroys the current list of Control Systems in the 'controlSystems' property.
       *
       */
      destroyControlSystems: function () {
        this.controlSystems = [];
      },
      /**
       * @ngdoc object
       * @name method:getCurrentDealer
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Returns the Dealer Information.
       *
       * @param {Number} customerID The ID of the currently selected customer.
       * @return {promise} The promise of the Dealer information
       * //TODO:MOVE THIS TO A DEALER SERVICE!!!
       */
      getCurrentDealer: function (customerID) {
        var deferred = $q.defer();
        var _this = this;
        customerID =
          typeof customerID == "undefined"
            ? UserService.customer_id
            : customerID;
        SimManagementService.getCurrentDealer(customerID)
          .then(
            function (data) {
              //success
              _this.dealer = data.dealer;
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (info) {
              deferred.notify(info);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getDealerLicenses
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Returns a list of SIM licenses associated to the selected dealer
       *
       * @note
       * Currently not used.
       *
       * @param {Number} dealerID The ID of the currently selected dealer.
       * @return {promise} The promise of the Dealer License list
       * //TODO:MOVE THIS TO A DEALER SERVICE!!!
       */
      getDealerLicenses: function (dealerID) {
        var deferred = $q.defer();
        var _this = this;
        SimManagementService.getDealerLicenses(dealerID)
          .then(
            function (data) {
              //success
              _this.dealer_licenses = data;
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (info) {
              deferred.notify(info);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:findSim
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Populates the 'sim' property.
       *
       * @param {String} simID The MEID, ICCID, or MSISDN (phone number).
       * @param {boolean} [renameSystem=true] - rename the system
       * @return {promise} The promise of the SIM Status information
       */
      findSim: function (simID, renameSystem = false, hideNotification) {
        var _this = this;
        var deferred = $q.defer();
        this.isBusy = true;
        // Initialize a sim object for the UI
        this.sim = {
          isAvailable: false,
          identifier: simID,
          status_type: "Please Wait...",
          carrier: "Unknown",
          legacyCellTechType: "",
        };
        SimManagementService.find(
          simID,
          _this.dealer.securecom_license,
          _this.currentControlSystem,
          UserService.dealerInfo.dealer_code,
          UserService.dealerInfo.id
        )
          .then(
            function (data) {
              //success
              if (data.sim && data.sim != null) {
                //sim is not new
                _this.sim = data.sim;
                if (
                  data.sim.status_type !== "unauthorized" ||
                  data.sim.license.key === _this.dealer.securecom_license
                ) {
                  _this.sim.isAvailable = true;
                  // If the modem is LTE, the sim id is the imei. Otherwise, it is the identifier passed in
                  _this.currentControlSystem.panels[
                    _this.currentControlSystem.panel_index
                  ].sim_identifier = _this.sim.isVerizonV1
                    ? _this.sim.imei
                    : _this.sim.identifier;
                  //Setting the carrier field for the panel
                  _this.currentControlSystem.panels[
                    _this.currentControlSystem.panel_index
                  ].sim_carrier = _this.sim.carrier;
                  //If there is a modem standard for the SIM use it for the panel, if not use a blank string
                  _this.currentControlSystem.panels[
                    _this.currentControlSystem.panel_index
                  ].modem_standard = DoesNestedPropertyExist(
                    _this.sim,
                    "modem.standard.name"
                  )
                    ? _this.sim.modem.standard.name
                    : _this.sim.legacyCellTechType;
                } else {
                  _this.sim.status_type = "unauthorized";
                  _this.sim.isAvailable = false;
                  _this.cellular_available = false;
                }
              } else {
                //sim is new
                _this.sim.status_type = "new";
                _this.sim.isAvailable = true;
                _this.cellular_available = true;
              }
              var plans = _this.getPlans(_this.sim.identifier);
              //var logs = SimManagementService.getSIMLogs(_this.sim.identifier, _this.dealer.securecom_license);

              var history = SimManagementService.getSIMHistory(
                _this.sim.identifier,
                _this.dealer.securecom_license,
                _this.dealer.id
              );
              $q.all([plans, history])
                .then(
                  function (data) {
                    _this.sim.history = data[1];
                    if (
                      _this.sim.history &&
                      _this.sim.history.description &&
                      _this.currentControlSystem.isNew
                    ) {
                      var r = /<(.*?)>/;
                      var desc = _this.sim.history.description;
                      _this.sim.history.panel_name = desc.split(r)[3];
                      var panelName = _this.sim.history.panel_name.split("-");
                      if (
                        panelName.length == 2 &&
                        panelName[1].toString().length
                      ) {
                        _this.sim.history.panel_name = panelName[1];
                      }

                      if (
                        renameSystem &&
                        _this.sim.history.status !== "inactive" &&
                        _this.sim.history.status !== "inactive-pending"
                      ) {
                        if (
                          _this.currentControlSystem.name !==
                          _this.sim.history.panel_name
                        ) {
                          if (!hideNotification) {
                            $rootScope.alerts.push({
                              type: "warning",
                              text: "System Name changed to match MEID/SIM information.",
                            }); //TODO: Add more specific i18n
                          }
                          _this.currentControlSystem.name =
                            _this.sim.history.panel_name;
                        }

                        if (
                          _this.currentControlSystem.panels[
                            _this.currentControlSystem.panel_index
                          ].account_prefix !== _this.sim.history.receiver_number
                        ) {
                          if (!hideNotification) {
                            $rootScope.alerts.push({
                              type: "warning",
                              text: "Receiver Number changed to match MEID/SIM information.",
                            }); //TODO: Add more specific i18n
                          }
                          _this.currentControlSystem.panels[
                            _this.currentControlSystem.panel_index
                          ].account_prefix = _this.sim.history.receiver_number;
                        }

                        if (
                          _this.currentControlSystem.panels[
                            _this.currentControlSystem.panel_index
                          ].account_number !== _this.sim.history.account_number
                        ) {
                          if (!hideNotification) {
                            $rootScope.alerts.push({
                              type: "warning",
                              text: "Account Number changed to match MEID/SIM information.",
                            }); //TODO: Add more specific i18n
                          }
                          _this.currentControlSystem.panels[
                            _this.currentControlSystem.panel_index
                          ].account_number = _this.sim.history.account_number;
                        }
                      }
                    }

                    // I moved this block inside the $q.all because this function was occasionally resolving too early and we weren't keeping the selected_rate_plan
                    if (_this.sim.rate_subplan) {
                      // On SIM retrieval, set the plan and text dropdowns to the incoming plan based on the description if
                      // they appear valid

                      // Certain rate plans no longer have text plans. Subsequently, the Web Services api does not return a text plan. We need to conditionally pre-populate the correct rate plan
                      var plan = _this.sim.rate_subplan.includes("-T")
                        ? _this.sim.rate_subplan.split("-T")
                        : [_this.sim.rate_subplan, "0"];

                      if (plan.length === 2) {
                        var incomingRatePlan = plan[0];
                        var incomingTextPlan = plan[1];
                        // note: Somehow sim sub-plans are getting into a '<plan>-Tundefined' or 'undefined-Tundefined' state
                        if (incomingRatePlan !== "undefined") {
                          _this.sim.selected_rate_plan = incomingRatePlan;
                        }
                        if (incomingTextPlan !== "undefined") {
                          _this.sim.selected_text_plan = incomingTextPlan;
                        }
                      }
                    }
                    _this.isBusy = false;
                    deferred.resolve();
                  },
                  function (error) {
                    _this.isBusy = false;
                    deferred.resolve();
                  }
                )
                .catch(function (error) {
                  console.error("PLANS AND HISTORY ERROR CATCH" + error);
                });
              if (
                _this.currentControlSystem.panels[
                  _this.currentControlSystem.panel_index
                ].programming_type === "MiniCellCom"
              ) {
                // MiniCellComs activate automatically so rate and text plans come back as null at this point.
                _this.sim.selected_rate_plan = "CellComSL";
                _this.sim.selected_text_plan = "0";
              }
              zeroTextPlanIfNotValid(_this.sim);
            },
            function (error) {
              _this.isBusy = false;
              _this.sim.status_type = "error"; //TODO: i18n this.
              _this.sim.isAvailable = false;
              _this.cellular_available = false;
              $rootScope.alerts.push({
                type: "error",
                text: "Error finding MEID/SIM information.  ",
                json: error,
              }); //TODO: Add more specific i18n title to this.
              deferred.reject();
            },
            function (info) {
              deferred.notify(info);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getPlans
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Adds the available Rate and Text plans to the sim object
       *
       * @param {String} simID The MEID or ICCID.
       * @return {promise} The promise of the Rate and Text Plans
       */
      getPlans: function (simID) {
        var _this = this;
        var currentHardwareModel, currentProgrammingType, tempHardwareModel;
        currentHardwareModel =
          _this.currentControlSystem.panels[
            _this.currentControlSystem.panel_index
          ].hardware_model;
        currentProgrammingType =
          _this.currentControlSystem.panels[
            _this.currentControlSystem.panel_index
          ].programming_type;
        // Default to current hardware_model if there is one.  If not, use the programming_type, but if it's XT, we need to make it XT50.
        tempHardwareModel = currentHardwareModel
          ? currentHardwareModel
          : currentProgrammingType == "XT"
          ? "XT50"
          : currentProgrammingType;
        var deferred = $q.defer();
        var ratePlans = SimManagementService.getRatePlans(
          simID,
          tempHardwareModel,
          UserService.dealerInfo.id
        );
        var textPlans = SimManagementService.getTextPlans(
          simID,
          tempHardwareModel,
          UserService.dealerInfo.id
        );
        $q.all([ratePlans, textPlans])
          .then(
            function (successArray) {
              _this.sim.rate_plans = successArray[0];
              // TODO: Remove when WS returns rate plans based on history
              if (
                currentHardwareModel == "XTLP" ||
                currentHardwareModel == "XT30" ||
                currentHardwareModel == "XT50" ||
                currentProgrammingType == "XT" ||
                currentProgrammingType == "XTLP"
              ) {
                var foundBackup = $filter("filter")(
                  _this.sim.rate_plans,
                  function (rate_plan) {
                    return rate_plan.rate_plan.value == "Backup";
                  }
                );
                var foundFirstNet = _this.sim.rate_plans.find(({ rate_plan }) =>
                  rate_plan.value.includes("FIRSTNET")
                );
                if (foundBackup.length == 0 && !foundFirstNet)
                  _this.sim.rate_plans.push({
                    rate_plan: { value: "Backup", display: "Backup" },
                  });
              }
              _this.sim.text_plans = successArray[1];
              _this.cellular_available = true;
              if (
                !successArray[0].length ||
                (!successArray[1].length &&
                  !(
                    systemWillBeAutoProgrammed(_this.currentControlSystem) &&
                    systemIsScheduledForFutureInstall(
                      _this.currentControlSystem
                    )
                  ))
              ) {
                //if(!_this.sim.status_type)
                if (
                  _this.currentControlSystem.panels[0].hardware_family ===
                    "XF6" &&
                  _this.sim.rate_plan === "FIRSTNET"
                ) {
                  _this.sim.status_type =
                    "XF6 Series does not support FirstNet communicators.";
                } else {
                  _this.sim.status_type = "invalid / unknown";
                }
                _this.sim.isAvailable = false;
                _this.cellular_available = false;
                //These are to clear the data from these fields if changing from DMP to non DMP sim
                _this.currentControlSystem.panels[
                  _this.currentControlSystem.panel_index
                ].sim_carrier = _this.sim.carrier;
                _this.currentControlSystem.panels[
                  _this.currentControlSystem.panel_index
                ].modem_standard = DoesNestedPropertyExist(
                  _this.sim,
                  "modem.standard.name"
                )
                  ? _this.sim.modem.standard.name
                  : _this.sim.legacyCellTechType;
              }
              if (
                !(
                  systemWillBeAutoProgrammed(_this.currentControlSystem) &&
                  systemIsScheduledForFutureInstall(_this.currentControlSystem)
                )
              ) {
                validateSelectedPlans(_this.sim);
              }
              deferred.resolve();
            },
            function (error) {
              _this.sim.rate_plans = [];
              _this.sim.text_plans = [];
              _this.sim.status_type = "error";
              _this.sim.isAvailable = false;
              _this.cellular_available = false;
              deferred.resolve();
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:activateSIM
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Sends the 'Activate' request and re-queries the SIM status.
       *
       */
      activateSIM: function (dealerCode) {
        var deferred = $q.defer();
        this.sim.isAvailable = false;
        this.sim.status_type = "Activating"; //TODO:i18n this.
        zeroTextPlanIfNotValid(this.sim);
        var _this = this;
        SimManagementService.activate(
          formatSIMBody(this),
          _this.currentControlSystem,
          dealerCode,
          _this.currentControlSystem.panels[0].id,
          UserService.dealerInfo.id
        )
          .then(
            function (data) {
              //success
              if (data.success) {
                if (
                  !(
                    systemIsScheduledForFutureInstall(
                      _this.currentControlSystem
                    ) && systemWillBeAutoProgrammed(_this.currentControlSystem)
                  )
                ) {
                  _this
                    .findSim(_this.sim.identifier)
                    .then(
                      function (data) {
                        deferred.resolve(data);
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              } else {
                _this.sim.rate_plans = [];
                _this.sim.text_plans = [];
                _this.sim.status_type = data.error_message
                  ? data.error_message
                  : "Unknown Activation Error!"; //TODO:i18n this.
                _this.sim.isAvailable = false;
                _this.cellular_available = false;
                $rootScope.alerts.push({
                  type: "error",
                  text: data.error_message
                    ? data.error_message
                    : "Unknown MEID/SIM Activation Error!",
                });
                deferred.reject();
              }
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error activating MEID/SIM.  ",
                json: error,
              });
              _this
                .findSim(_this.sim.identifier)
                .then(
                  function (data) {
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (info) {}
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:activateSIM
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Sends the 'Modify' request and re-queries the SIM status.
       *
       */
      modifySIM: function () {
        this.sim.isAvailable = false;
        this.sim.status_type = "Modifying..."; //TODO:i18n this.
        zeroTextPlanIfNotValid(this.sim);
        var deferred = $q.defer();
        SimManagementService.modify(
          formatSIMBody(this),
          UserService.dealerInfo.id
        )
          .then(
            function (data) {
              //success
              if (data.success) {
                deferred.resolve();
              } else {
                deferred.resolve();
              }
            },
            function (error) {
              deferred.resolve();
            },
            function (info) {}
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:deactivateSIM
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Sends the 'Deactivate' request and re-queries the SIM status.
       *
       */
      deactivateSIM: function () {
        this.sim.isAvailable = false;
        this.sim.status_type = "Deactivating"; //TODO:i18n this.
        const _this = this;
        const deferred = $q.defer();
        SimManagementService.deactivate(
          formatSIMBody(this),
          _this.currentControlSystem,
          UserService.dealerInfo.dealer_code,
          _this.currentControlSystem.panels[0].id,
          UserService.dealerInfo.id
        )
          .then(
            function (data) {
              //success
              if (data.success) {
                _this.findSim(_this.sim.identifier).finally(function () {
                  deferred.resolve();
                });
              } else {
                _this.sim.rate_plans = [];
                _this.sim.text_plans = [];
                _this.sim.status_type = data.error_message
                  ? data.error_message
                  : "Unknown Deactivation Error!"; //TODO:i18n this.
                _this.sim.isAvailable = false;
                _this.cellular_available = false;
                $rootScope.alerts.push({
                  type: "error",
                  text: data.error_message
                    ? data.error_message
                    : "Unknown Deactivation Error!",
                });
                deferred.reject();
              }
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error deactivating MEID/SIM.  ",
                json: error,
              });
              _this.findSim(_this.sim.identifier);
              deferred.reject();
            },
            function (info) {}
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:refreshSIMLogs
       * @methodOf App.factory:ControlSystemsService
       *
       * @description
       * Refreshes just the sim logs property of the sim object.
       *@note
       * Not used, may not need
       *
       * @returns (Promise) Returns a promise of the sim logs and updates the sim object.
       */
      refreshSIMLogs: function () {
        var deferred = $q.defer();
        var _this = this;
        SimManagementService.getSIMLogs(
          _this.sim.identifier,
          _this.dealer.securecom_license
        )
          .then(
            function (dataArray) {
              _this.sim.logs = dataArray;
              deferred.resolve(dataArray);
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error refreshing MEID/SIM logs.  ",
                json: error,
              });
              deferred.reject(error);
            },
            function (info) {
              deferred.notify(info);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
    };

    /**
     * Validate that the text plan is valid for the sim
     * @param sim
     */
    function zeroTextPlanIfNotValid(sim) {
      if (sim.isLTE) {
        sim.selected_text_plan = "0";
      }
    }

    function formatSIMBody(system) {
      return {
        iccid: system.sim.identifier,
        rate_subplan:
          system.sim.selected_rate_plan +
          (system.sim.selected_text_plan
            ? "-T" + system.sim.selected_text_plan
            : ""),
        receiver:
          system.currentControlSystem.panels[
            system.currentControlSystem.panel_index
          ].account_prefix,
        account:
          system.currentControlSystem.panels[
            system.currentControlSystem.panel_index
          ].account_number,
        name: system.currentControlSystem.name,
        key: system.dealer.securecom_license,
      };
    }

    function shouldGetSIMHistory(commType, system) {
      var commTypeNeedsIt = ["cell", "persistent_w_cell_backup"].includes(
        commType
      );
      var routeNeedsIt = true; // Default to true
      const systemNeedsIt = !(
        systemIsScheduledForFutureInstall(system) &&
        systemWillBeAutoProgrammed(system)
      );
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          routeNeedsIt = ["app.control_system_edit"].includes(
            $rootScope.stateChange.next.name
          );
          break;
        case "techApp":
          routeNeedsIt = [
            "dealer.customer.controlsystem.controlSystemEdit",
          ].includes($rootScope.stateChange.next.name);
          break;
        default:
          console.warn(
            "ControlSystemsService->shouldGetSIMHistory() - Unknown app type"
          );
          break;
      }
      return commTypeNeedsIt && routeNeedsIt && systemNeedsIt;
    }

    /**
     * If set, check that the settings for the selected rate and text plans are in their respective list
     * @param sim
     */
    function validateSelectedPlans(sim) {
      if (sim.selected_rate_plan) {
        if (angular.isArray(sim.rate_plans)) {
          var ratePlan = sim.rate_plans.find(function (plan) {
            return plan.rate_plan.value === sim.selected_rate_plan;
          });
          if (angular.isUndefined(ratePlan)) {
            delete sim.selected_rate_plan;
          }
        } else {
          console.warn(
            "ControlSystemsService->validateSelectedPlans called with non-array rate_plans: " +
              angular.toJson(sim.rate_plans)
          );
        }
      }
      if (sim.selected_text_plan) {
        if (angular.isArray(sim.text_plans)) {
          var textPlan = sim.text_plans.find(function (textPlan) {
            return textPlan.text_plan.value === sim.selected_text_plan;
          });
          if (angular.isUndefined(textPlan)) {
            delete sim.selected_text_plan;
          }
        } else {
          console.warn(
            "ControlSystemsService->validateSelectedPlans called with non-array text_plans: " +
              angular.toJson(sim.rate_plans)
          );
        }
      }
    }

    return controlSystemsService;
  },
]);
