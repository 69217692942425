/**
 * @generated SignedSource<<05a44635ed101cf1b58a3cd6b6baf025>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type CommPathUseCheckInField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly id: string;
  readonly number: number;
  readonly type: CommunicationPathType;
  readonly useCheckIn: CommunicationPathUseCheckIn;
  readonly " $fragmentType": "CommPathUseCheckInField_communicationPath";
};
export type CommPathUseCheckInField_communicationPath$key = {
  readonly " $data"?: CommPathUseCheckInField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathUseCheckInField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathUseCheckInField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "ed76f6ad01f59df21041a96949479697";

export default node;
