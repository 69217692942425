import styled from "styled-components";

const Label = styled.label`
  position: relative;
  width: 100%;
  margin: 0;
  font-weight: normal;
`;

export default Label;
