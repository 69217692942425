import { fetchFileSrcFromUrlWithBearerAuth } from "common/utils/web/url";
App.controller("InvoiceDownloadCtrl", [
  "$scope",
  "UserService",
  "DealerInvoicesService",
  "PROPS",
  "$stateParams",
  "$modal",
  "$q",
  "BillingService",
  "PaymentsService",
  "BillingAccount",
  function (
    $scope,
    UserService,
    DealerInvoicesService,
    PROPS,
    $stateParams,
    $modal,
    $q,
    BillingService,
    PaymentService,
    BillingAccount
  ) {
    $scope.billingAccount = new BillingAccount();
    $scope.UserService = UserService;
    $scope.modalOpen = false;

    function init() {
      getInvoices();

      $scope.billingAccount = new BillingAccount();
      $scope.billingAccount
        .get(UserService.dealerInfo.dealer_code, UserService.dealerInfo.id)
        .then(
          function () {
            $scope.getBillingCycle();
          },
          function (error) {}
        );
      if (
        UserService.enforceDistributionSubscriberValidPaymentMethod() &&
        !$scope.modalOpen
      ) {
        $scope.openBillingAccountModal();
      }
    }

    // Allows child modals to force update the billingAccount data.
    // The child modals share $scope.billingAccount, however
    // it does not seem to update the parent when the scope
    // is changed in the child.
    $scope.refreshBase = async () => {
      init();
      await UserService.dealerInfo.getHasValidBillingInformation();
    };

    function getInvoices() {
      DealerInvoicesService.getInvoices()
        .then(
          function (data) {
            $scope.invoices = data;
          },
          function (error) {
            console.error(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.openAutoPaymentModal = function (data) {
      const { result } = $modal.open({
        templateUrl: "app/billing/auto-payment-modal.html",
        controller: "AutoPaymentModalInstanceCtrl",
        windowClass: "",
        size: "md",
        backdrop: true,
        scope: $scope,
      });

      result.then(
        () => {
          init();
        },
        () => {
          init();
        }
      );
    };

    $scope.openBillingAccountModal = function (data) {
      $scope.modalOpen = true;
      const { result } = $modal.open({
        templateUrl: "app/billing/billing-account-modal.html",
        controller: "BillingAccountModalInstanceCtrl",
        windowClass: "",
        size: "md",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
      });

      result.then(
        () => {
          init();
          $scope.modalOpen = false;
        },
        () => {
          init();
          $scope.modalOpen = false;
        }
      );
    };

    $scope.openPaymentModal = function (data) {
      const { result } = $modal.open({
        templateUrl: "app/billing/payment-modal.html",
        controller: "PaymentModalInstanceCtrl",
        windowClass: "",
        size: "md",
        backdrop: true,
        scope: $scope,
      });

      result.then(
        () => {
          init();
        },
        () => {
          init();
        }
      );
    };

    $scope.getBillingCycle = function () {
      if ($scope.billingAccount) {
        switch ($scope.billingAccount.billingCycle) {
          case 1:
            return {
              runDate: "4th",
              dipDate: "7th",
            };
          case 15:
            return {
              runDate: "18th",
              dipDate: "21st",
            };
          case 22:
            return {
              runDate: "25th",
              dipDate: "28th",
            };
          default:
            return {
              runDate: 0,
              dipDate: 0,
            };
        }
      } else {
        return {
          runDate: 0,
          dipDate: 0,
        };
      }
    };

    $scope.downloadPdf = async function (invoice) {
      const url = await fetchFileSrcFromUrlWithBearerAuth(
        `${PROPS.dealerApiUrl}/api/v1/DealerInvoices/Download/${$stateParams.dealer_id}/${invoice.InvoiceNumber}/pdf`,
        UserService.auth_token
      );
      window.open(url, "_blank");
    };

    $scope.openPdf = async function (invoice) {
      const url = await fetchFileSrcFromUrlWithBearerAuth(
        `${PROPS.dealerApiUrl}/api/v1/DealerInvoices/View/${$stateParams.dealer_id}/${invoice.InvoiceNumber}/pdf`,
        UserService.auth_token
      );
      window.open(url, "_blank");
    };

    $scope.downloadXlsx = async function (invoice) {
      const url = await fetchFileSrcFromUrlWithBearerAuth(
        `${PROPS.dealerApiUrl}/api/v1/DealerInvoices/Download/${$stateParams.dealer_id}/${invoice.InvoiceNumber}/zip`,
        UserService.auth_token
      );
      window.open(url, "_blank");
    };

    init();

    $scope.$watch(
      "UserService.dealerInfo.dealer_code",
      function (newVal, oldVal) {
        if (newVal && !oldVal) {
          init();
        }
      }
    );
  },
]);
