/**
 * @generated SignedSource<<8638ab604c38f792e13fe2e7bad5bb8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2SecondPhoneNumberField_communication$data = {
  readonly comType: CommunicationComType;
  readonly receiver2SecondaryPhoneNumber: string;
  readonly " $fragmentType": "CommunicationReceiver2SecondPhoneNumberField_communication";
};
export type CommunicationReceiver2SecondPhoneNumberField_communication$key = {
  readonly " $data"?: CommunicationReceiver2SecondPhoneNumberField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2SecondPhoneNumberField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2SecondPhoneNumberField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2SecondaryPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "6bb2558590a484fac49eb5567e95e90b";

export default node;
