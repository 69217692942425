/**
 * @generated SignedSource<<53215f1fb60047d55553ca580f5dca64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XROutputGroupFields_outputGroup$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"OutputGroupContext_outputGroup" | "OutputGroupNameField_outputGroup" | "OutputGroupNumberField_outputGroup" | "OutputGroupOutputNumberField_outputGroup">;
  readonly " $fragmentType": "XROutputGroupFields_outputGroup";
};
export type XROutputGroupFields_outputGroup$key = {
  readonly " $data"?: XROutputGroupFields_outputGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"XROutputGroupFields_outputGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XROutputGroupFields_outputGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupContext_outputGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupNameField_outputGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupNumberField_outputGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupOutputNumberField_outputGroup"
    }
  ],
  "type": "OutputGroup",
  "abstractKey": null
};

(node as any).hash = "a09e936aff0246e33cc0955d087956ea";

export default node;
