import graphql from "babel-plugin-relay/macro";
import Alert from "components/Alert";
import { adjust, assoc, clamp, prop } from "ramda";
import * as React from "react";
import {
  fetchQuery,
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useRefetchableFragment,
  useRelayEnvironment,
} from "react-relay";
import { useCreateNotification } from "../EntryPointContext";
import { FieldLabel, ReadOnlyInput } from "../FormFields";
import styles from "./SiteControlSystemsForm.module.css";
import { ExistingSiteControlSystemFormQuery } from "./__generated__/ExistingSiteControlSystemFormQuery.graphql";
import {
  ChangeSiteControlSystemOutputModuleInput,
  ExistingSiteControlSystemFormSaveMutation,
  OsdpOrWiegand,
} from "./__generated__/ExistingSiteControlSystemFormSaveMutation.graphql";
import {
  ExistingSiteControlSystemForm_siteControlSystem$data,
  ExistingSiteControlSystemForm_siteControlSystem$key,
} from "./__generated__/ExistingSiteControlSystemForm_siteControlSystem.graphql";

import Flex from "common/components/web/Flex";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { addKeyIfTruthy } from "common/utils/universal/object";
import Modal from "components/Modal";
import {
  ConfirmModalOverlay,
  DeleteConfirmModalBody,
  DeleteConfirmModalOverlay,
} from "components/Modal/ConfirmModal";
import {
  ControlSystemCommType,
  InitialConnectionStatus,
  SiteControlSystem,
} from "securecom-graphql/client";
import Spacer from "../Layout/Spacer";
import { DoorForm } from "./DoorForm";
import SuspenseBoundary from "./VideoForm/Components/SuspenseBoundary";
import { ExistingSiteControlSystemFormDeleteMutation } from "./__generated__/ExistingSiteControlSystemFormDeleteMutation.graphql";
import { ExistingSiteControlSystemFormSetupMutation } from "./__generated__/ExistingSiteControlSystemFormSetupMutation.graphql";
import { ExistingSiteControlSystemFormTrackSetupQuery } from "./__generated__/ExistingSiteControlSystemFormTrackSetupQuery.graphql";

import PillButton from "components/PillButton";
import { Disposable, Variables } from "relay-runtime";
import styled from "styled-components/macro";
import {
  DoorFormFloors,
  ElevatorAction,
  ElevatorOutputModuleState,
  elevatorReducer,
  ElevatorState,
  initElevatorOutputModuleState,
  isElevatorAction,
} from "./DoorFormFloors";
import FirmwareUpdateProgress from "./FirmwareUpdateProgress";
import ReplaceX1Modal from "./ReplaceX1Modal";
import { SiteControlSystemJobStatusBadgeWithAutoRefresh } from "./SiteControlSystemJobStatusBadge";
import {
  dismissAvailableUpdates,
  dismissFailedUpdates,
  UpdatesAvailable,
  UpdatesFailed,
} from "./SiteFirmwareUpdateDialogue";
import { ExistingSiteControlSystemFormCreateX1AutoProgrammingJobMutation } from "./__generated__/ExistingSiteControlSystemFormCreateX1AutoProgrammingJobMutation.graphql";
import { ExistingSiteControlSystemFormReplacingX1ProgressMutation } from "./__generated__/ExistingSiteControlSystemFormReplacingX1ProgressMutation.graphql";
import { PanelFirmwareStatus } from "./__generated__/FirmwareUpdateBadge_siteControlSystem.graphql";

const InvisibleSpacer = styled.span`
  opacity: 0;
`;
const AddDoorCount = styled.span`
  color: lightgrey;
`;

function Header(props: React.HTMLProps<HTMLDivElement>) {
  return <div {...props} className={styles["form-header"]} />;
}

function Footer(props: React.HTMLProps<HTMLDivElement>) {
  return <div {...props} className={styles["form-footer"]} />;
}

function FormError(props: React.HTMLProps<HTMLDivElement>) {
  return <div {...props} className={styles["form-error"]} />;
}

export type DoorState = {
  edited: boolean;
  id: string;
  name: string;
  strikeTime: string;
  strikeDelay: string;
  strikeDelayBlurred: boolean;
  fireZone: boolean;
  fireExit: boolean;
  includeInLockdown: boolean;
  doorPropTime: string;
  osdpOrWiegand: OsdpOrWiegand;
  doorSensor: boolean;
  doorForce: boolean;
  requestToExit: boolean;
  unlockOnRex: boolean;
  buzzer: boolean;
  led: boolean;
  active: boolean;
  validationErrors: {
    strikeDelay?: React.ReactNode | null;
  };
  number: number;
  showDoor: boolean;
};
export type OnboardOutputState = {
  onboardOutput1: (Pick<
    NonNullable<
      ExistingSiteControlSystemForm_siteControlSystem$data["onboardOutput1"]
    >,
    "name" | "id"
  > | null) & { edited: boolean };
  onboardOutput2: (Pick<
    NonNullable<
      ExistingSiteControlSystemForm_siteControlSystem$data["onboardOutput2"]
    >,
    "name" | "id"
  > | null) & { edited: boolean };
};

export type SiteControlSystemFormState = {
  id: string;
  doors: DoorState[];
  elevatorOutputModules: ElevatorState;
  saveErrorMessage: React.ReactNode;
  confirmingDelete: boolean;
  confirmingFirmwareUpdate: boolean;
  setupFailed: boolean;
  refetching: boolean;
  replacingPanel: boolean;
  alarmPanelIntegration: {
    outputNumber: number | null;
    shouldShowError: boolean;
  } | null;
  commType: string;
};

export type SiteDoor =
  ExistingSiteControlSystemForm_siteControlSystem$data["doors"][0];

export type SiteControlSystemFormAction =
  | {
      type: "SETUP_FAILED" | "RETRY_SETUP_BUTTON_CLICKED";
    }
  | {
      type:
        | "DOOR_NAME_CHANGED"
        | "DOOR_STRIKE_TIME_CHANGED"
        | "DOOR_STRIKE_TIME_BLURRED"
        | "DOOR_STRIKE_DELAY_CHANGED"
        | "DOOR_STRIKE_DELAY_BLURRED"
        | "DOOR_PROP_TIME_CHANGED"
        | "DOOR_PROP_TIME_BLURRED";
      doorIndex: number;
      value: string;
    }
  | {
      type: "DOOR_OSDP_OR_WIEGAND_SELECTED";
      doorIndex: number;
      value: OsdpOrWiegand;
    }
  | {
      type: "COMM_TYPE_CHANGED";
      value: string;
    }
  | {
      type:
        | "DOOR_BUZZER_TOGGLED"
        | "DOOR_LED_TOGGLED"
        | "DOOR_FIRE_ZONE_TOGGLED"
        | "DOOR_FIRE_EXIT_TOGGLED"
        | "DOOR_INCLUDE_IN_LOCKDOWN_TOGGLED"
        | "DOOR_SENSOR_TOGGLED"
        | "DOOR_FORCE_TOGGLED"
        | "DOOR_REQUEST_TO_EXIT_TOGGLED"
        | "DOOR_UNLOCK_ON_REX_TOGGLED"
        | "RESEND_DOOR";

      doorIndex: number;
    }
  | {
      type: "DOOR_DEACTIVATED";
      doorIndex: number;
      door: SiteDoor;
    }
  | {
      type: "ALARM_PANEL_OUTPUT_SELECTED";
      outputNumber: number | null;
    }
  | { type: "SHOW_ALARM_PANEL_OUTPUT_NUMBER_ERROR" }
  | {
      type:
        | "DELETE_BUTTON_CLICKED"
        | "DELETE_CANCELED"
        | "UPDATE_BUTTON_CLICKED"
        | "UPDATE_CANCELED"
        | "UPDATE_RUNNING"
        | "ALARM_PANEL_TOGGLED"
        | "DOOR_NAME_BLANKED"
        | "SHOW_NEXT_DOOR";
    }
  | {
      type: "UPDATE_REFETCHING";
      value: boolean;
    }
  | {
      type: "UPDATE_REPLACING_PANEL";
      value: boolean;
    }
  | {
      type: "UPDATE_DOORS";
      value: any;
    }
  | ElevatorAction;

export type SiteControlSystemFormDispatch =
  React.Dispatch<SiteControlSystemFormAction>;

const updateDoor = (
  index: number,
  updater: (doorState: DoorState) => DoorState,
  state: SiteControlSystemFormState
) => ({
  ...state,
  doors: adjust(index, updater, state.doors),
});

const validateStrikeDelay = (value: number) =>
  value >= 0 && value <= 11 && value !== 10
    ? null
    : "Valid values are 0-9, 11.";

function doorReducer(
  state: DoorState,
  action: SiteControlSystemFormAction
): DoorState {
  switch (action.type) {
    case "DOOR_NAME_CHANGED":
      return assoc("name", action.value, state);
    case "DOOR_STRIKE_TIME_CHANGED":
      return assoc("strikeTime", action.value, state);
    case "DOOR_STRIKE_TIME_BLURRED":
      return assoc(
        "strikeTime",
        clamp<number>(0, 250, Number(action.value)).toString(),
        state
      );
    case "DOOR_STRIKE_DELAY_CHANGED":
      return {
        ...state,
        strikeDelay: action.value,
        validationErrors: {
          ...state.validationErrors,
          strikeDelay: validateStrikeDelay(Number(action.value)),
        },
      };
    case "DOOR_STRIKE_DELAY_BLURRED": {
      const strikeDelay = clamp<number>(0, 11, Number(action.value)).toString();
      return {
        ...state,
        strikeDelayBlurred: true,
        strikeDelay,
        validationErrors: {
          ...state.validationErrors,
          strikeDelay: validateStrikeDelay(Number(strikeDelay)),
        },
      };
    }
    case "DOOR_PROP_TIME_CHANGED":
      return assoc("doorPropTime", action.value, state);
    case "DOOR_PROP_TIME_BLURRED":
      return assoc(
        "doorPropTime",
        clamp<number>(0, 240, Number(action.value)).toString(),
        state
      );
    case "DOOR_OSDP_OR_WIEGAND_SELECTED":
      return assoc("osdpOrWiegand", action.value, state);
    case "DOOR_BUZZER_TOGGLED":
      return { ...state, buzzer: !state.buzzer };
    case "DOOR_LED_TOGGLED":
      return { ...state, led: !state.led };
    case "DOOR_FIRE_ZONE_TOGGLED":
      return { ...state, fireZone: !state.fireZone };
    case "DOOR_FIRE_EXIT_TOGGLED":
      return { ...state, fireExit: !state.fireExit };
    case "DOOR_INCLUDE_IN_LOCKDOWN_TOGGLED":
      return { ...state, includeInLockdown: !state.includeInLockdown };
    case "DOOR_SENSOR_TOGGLED":
      return {
        ...state,
        doorSensor: !state.doorSensor,
        doorForce: !state.doorSensor ? state.doorForce : false,
      };
    case "DOOR_FORCE_TOGGLED":
      return { ...state, doorForce: !state.doorForce };
    case "DOOR_REQUEST_TO_EXIT_TOGGLED":
      return {
        ...state,
        requestToExit: !state.requestToExit,
        unlockOnRex: !state.requestToExit,
      };
    case "DOOR_UNLOCK_ON_REX_TOGGLED":
      return { ...state, unlockOnRex: !state.unlockOnRex };

    case "DOOR_DEACTIVATED":
      return {
        ...state,
        ...initDoorState(action.door),
      };
    default:
      return state;
  }
}

function reducer(
  state: SiteControlSystemFormState,
  action: SiteControlSystemFormAction
): SiteControlSystemFormState {
  switch (action.type) {
    case "RESEND_DOOR":
      return {
        ...state,
        doors: state.doors.map((door, index) => {
          if (index === action.doorIndex) {
            return { ...door, edited: true };
          }
          return door;
        }),
      };
    case "SETUP_FAILED":
      return { ...state, setupFailed: true };
    case "RETRY_SETUP_BUTTON_CLICKED":
      return { ...state, setupFailed: false };
    case "DELETE_BUTTON_CLICKED":
      return { ...state, confirmingDelete: true };
    case "DELETE_CANCELED":
      return { ...state, confirmingDelete: false };
    case "UPDATE_BUTTON_CLICKED":
      return { ...state, confirmingFirmwareUpdate: true };
    case "UPDATE_CANCELED":
    case "UPDATE_RUNNING":
      return { ...state, confirmingFirmwareUpdate: false };
    case "UPDATE_REFETCHING":
      return { ...state, refetching: action.value };
    case "UPDATE_REPLACING_PANEL":
      return { ...state, replacingPanel: action.value };
    case "UPDATE_DOORS":
      return { ...state, doors: action.value.map(initDoorState) };
    case "DOOR_NAME_BLANKED":
      return {
        ...state,
        alarmPanelIntegration: null,
      };
    case "SHOW_NEXT_DOOR":
      const nextDoorIndex = state.doors.findIndex(
        (door) => !door.showDoor && door.number !== 1
      );
      return {
        ...state,
        doors: state.doors.map((door, index) => {
          if (index === nextDoorIndex) {
            return { ...door, showDoor: true };
          }
          return door;
        }),
      };
    case "COMM_TYPE_CHANGED":
      return {
        ...state,
        commType: action.value,
      };
    case "ALARM_PANEL_TOGGLED":
      return {
        ...state,
        alarmPanelIntegration: state.alarmPanelIntegration
          ? null
          : { outputNumber: null, shouldShowError: false },
      };
    case "ALARM_PANEL_OUTPUT_SELECTED":
      return {
        ...state,
        alarmPanelIntegration: {
          outputNumber: action.outputNumber,
          shouldShowError: false,
        },
      };
    case "SHOW_ALARM_PANEL_OUTPUT_NUMBER_ERROR":
      return {
        ...state,
        alarmPanelIntegration: {
          outputNumber: state.alarmPanelIntegration?.outputNumber ?? null,
          shouldShowError: true,
        },
      };
    default:
      return isElevatorAction(action)
        ? {
            ...state,
            elevatorOutputModules: elevatorReducer(
              state.elevatorOutputModules,
              action
            ),
          }
        : isNotNullOrUndefined(action.doorIndex)
        ? updateDoor(
            action.doorIndex,
            (door) => ({ ...doorReducer(door, action), edited: true }),
            state
          )
        : state;
  }
}

const hasFireZone = (state: SiteControlSystemFormState) =>
  state.doors.some(prop("fireZone"));

function initDoorState(door: SiteDoor): DoorState {
  return {
    edited: false,
    id: door.id,
    name: door.name,
    strikeTime: door.strikeTime.toString(),
    strikeDelay: door.strikeDelay.toString(),
    strikeDelayBlurred: false,
    fireZone: door.fireZone,
    fireExit: door.fireExit,
    includeInLockdown: door.includeInLockdown,
    doorPropTime: door.doorPropTime.toString(),
    osdpOrWiegand: door.osdpOrWiegand,
    doorSensor: door.doorSensor,
    doorForce: door.doorForce,
    requestToExit: door.requestToExit,
    unlockOnRex: door.unlockOnRex,
    buzzer: door.buzzer,
    led: door.led,
    active: door.active,
    validationErrors: {
      strikeDelay: null,
    },
    number: door.number,
    showDoor: door.number === 1 || door.active,
  };
}
function initOnboardOutputState(
  data: ExistingSiteControlSystemForm_siteControlSystem$data
): OnboardOutputState {
  return {
    onboardOutput1: {
      id: data.onboardOutput1?.id ?? "",
      name: data.onboardOutput1?.name ?? "",
      edited: false,
    },
    onboardOutput2: {
      id: data.onboardOutput2?.id ?? "",
      name: data.onboardOutput2?.name ?? "",
      edited: false,
    },
  };
}
const applyOnboardOutputState = (onboardOutputs: OnboardOutputState) => ({
  ...(onboardOutputs.onboardOutput1.edited
    ? {
        onboardOutput1: {
          ...addKeyIfTruthy("id", onboardOutputs.onboardOutput1.id),
          ...addKeyIfTruthy("name", onboardOutputs.onboardOutput1.name),
        },
      }
    : {}),
  ...(onboardOutputs.onboardOutput2.edited
    ? {
        onboardOutput2: {
          ...addKeyIfTruthy("id", onboardOutputs.onboardOutput2.id),
          ...addKeyIfTruthy("name", onboardOutputs.onboardOutput2.name),
        },
      }
    : {}),
});

function initState(
  data: ExistingSiteControlSystemForm_siteControlSystem$data
): SiteControlSystemFormState {
  return {
    id: data.id,
    doors: data.doors.map(initDoorState),
    saveErrorMessage: null,
    confirmingDelete: false,
    confirmingFirmwareUpdate: false,
    setupFailed: false,
    refetching: false,
    replacingPanel: !!data.replacingPanelJobGroupId,
    elevatorOutputModules: initElevatorOutputModuleState(data),
    alarmPanelIntegration: data.alarmPanelIntegrationOutput
      ? {
          outputNumber: data.alarmPanelIntegrationOutput.number,
          shouldShowError: false,
        }
      : null,
    commType: data.commType,
  };
}

export const setupSiteControlSystemMutation = graphql`
  mutation ExistingSiteControlSystemFormSetupMutation($controlSystemId: ID!) {
    setupSiteControlSystem(controlSystemId: $controlSystemId) {
      ... on InitialConnectionFailure {
        type
      }
      ... on SetupSiteControlSystemSuccessPayload {
        controlSystem {
          id
          online
          hasPendingChanges
          initialConnectionStatus
          firmwareStatus
          firmwareUpdateProgress
        }
      }
    }
  }
`;

export const createX1AutoProgrammingJobMutation = graphql`
  mutation ExistingSiteControlSystemFormCreateX1AutoProgrammingJobMutation(
    $controlSystemId: ID!
  ) {
    createX1AutoProgrammingJob(controlSystemId: $controlSystemId) {
      ... on NotFoundError {
        type
      }
      ... on CreateX1AutoProgrammingJobSuccessPayload {
        controlSystem {
          id
          online
          hasPendingChanges
          initialConnectionStatus
          firmwareStatus
          firmwareUpdateProgress
        }
      }
    }
  }
`;

const saveControlSystemMutation = graphql`
  mutation ExistingSiteControlSystemFormSaveMutation(
    $controlSystem: UpdateSiteControlSystemInput!
    $elevatorOutputModules: [ChangeSiteControlSystemOutputModuleInput!]
  ) {
    updateSiteControlSystem(
      controlSystem: $controlSystem
      elevatorOutputModules: $elevatorOutputModules
    ) {
      ... on Error {
        errorType: type
      }
      ... on UpdateSiteControlSystemSuccessResponse {
        site {
          ...SiteControlSystemsSection_site
          ...SiteOutputModulesSection_site
          ...SiteElevatorSystemsSection_site
        }
        controlSystem {
          ...ExistingSiteControlSystemForm_siteControlSystem
        }
      }
    }
  }
`;

const deleteControlSystemMutation = graphql`
  mutation ExistingSiteControlSystemFormDeleteMutation($controlSystemId: ID!) {
    removeSiteControlSystem(controlSystemId: $controlSystemId) {
      ... on NotFoundError {
        errorType: type
      }
      ... on Site {
        ...SiteControlSystemsSection_site
        ...SiteElevatorSystemsSection_site
        ...SiteOutputModulesSection_site
      }
    }
  }
`;

const replacingX1ProgressMutation = graphql`
  mutation ExistingSiteControlSystemFormReplacingX1ProgressMutation(
    $jobId: Int!
  ) {
    refreshProgress(jobId: $jobId) {
      ... on RefreshProgressSuccessResponse {
        jobStatus
        jobOutput
      }
      ... on NotFoundError {
        errorType: type
      }
    }
  }
`;

const trackSetupQuery = graphql`
  query ExistingSiteControlSystemFormTrackSetupQuery($controlSystemId: ID!) {
    siteControlSystemSetupTracked(controlSystemId: $controlSystemId) {
      ... on InitialConnectionFailure {
        errorType: type
      }
      ... on SiteControlSystem {
        id
        initialConnectionStatus
        firmwareStatus
        firmwareUpdateProgress
      }
    }
  }
`;

function ExistingSiteControlSystemForm({
  firmwareStatus,
  firmwareUpdateProgress,
  siteHasFirmwareUpdatesInProgress,
  siteControlSystemRef,
  initialSelectedDoor,
  onSaved,
  onDeleted,
  onCancel,
  canAutoFocus,
  canDelete,
}: {
  firmwareStatus: PanelFirmwareStatus;
  firmwareUpdateProgress: number | null;
  siteHasFirmwareUpdatesInProgress: boolean;
  siteControlSystemRef: ExistingSiteControlSystemForm_siteControlSystem$key;
  initialSelectedDoor: string | null;
  onSaved: () => void;
  onDeleted: () => void;
  onCancel: () => void;
  canAutoFocus: boolean;
  canDelete: boolean;
}) {
  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment ExistingSiteControlSystemForm_siteControlSystem on SiteControlSystem
      @refetchable(
        queryName: "ExistingSiteControlSystemFormRefetch_siteControlSystemQuery"
      ) {
        id
        name
        serialNumber
        type
        softwareVersion
        initialConnectionStatus
        isBillingControlSystem
        intendedUsage
        maxOutputModules
        availableOutputModulesCount
        hasCell
        hasEasyConnect
        cellStatus {
          status
        }
        easyConnectStatus {
          status
        }
        commType
        online
        outputModulesConnection {
          totalCount
          nodes {
            id
            name
            address
            intendedUsage
            outputsConnection {
              totalCount
              nodes {
                id
                name
                type
                relayNumber
              }
            }
          }
        }
        doors(includeUnprogrammedDoors: true) {
          id
          name
          active
          strikeTime
          strikeDelay
          fireZone
          fireExit
          includeInLockdown
          doorPropTime
          osdpOrWiegand
          doorSensor
          doorForce
          requestToExit
          unlockOnRex
          buzzer
          led
          number
        }
        site {
          id
          hasFireZone
          firmwareUpdateDismissed
          alarmPanelX1Integration
          armablePanelSerialNumber
        }
        alarmPanelIntegrationOutput {
          id
          number
        }
        onboardOutput1 {
          id
          name
          outputIntendedUsage
          isAvailableForAlarmIntegration
        }
        onboardOutput2 {
          id
          name
          outputIntendedUsage
          isAvailableForAlarmIntegration
        }
        firmwareUpdateDismissed
        hasPendingChanges
        hasFailedPreProgrammingJob
        replacingPanelJobGroupId
        hasReplaceX1Backup
        ...SiteControlSystemJobStatusBadge_siteControlSystem
      }
    `,
    siteControlSystemRef
  );

  const createNotification = useCreateNotification();

  const { initialConnectionStatus, online } = data;

  const [runSetupControlSystemMutation, settingUpControlSystem] =
    useMutation<ExistingSiteControlSystemFormSetupMutation>(
      setupSiteControlSystemMutation
    );
  const [saveControlSystem, savingControlSystem] =
    useMutation<ExistingSiteControlSystemFormSaveMutation>(
      saveControlSystemMutation
    );
  const [deleteControlSystem, deletingControlSystem] =
    useMutation<ExistingSiteControlSystemFormDeleteMutation>(
      deleteControlSystemMutation
    );

  const [createX1AutoProgrammingJob, creatingX1AutoProgrammingJob] =
    useMutation<ExistingSiteControlSystemFormCreateX1AutoProgrammingJobMutation>(
      createX1AutoProgrammingJobMutation
    );

  const [state, dispatch] = React.useReducer(reducer, data, initState);

  const [onboardOutputs, setOnboardOutputs] =
    React.useState<OnboardOutputState>(initOnboardOutputState(data));
  const [replaceX1ModalOpen, setReplaceX1ModalOpen] = React.useState(false);
  const includeOnboardOutputs = (door: DoorState) => door.number === 1;
  const relayEnv = useRelayEnvironment();

  const firmwareUpdateDismissed =
    !!data.site?.firmwareUpdateDismissed || !!data.firmwareUpdateDismissed;

  const alarmPanelIntegrationIsDisabled =
    //if new armablePanelSerialNumber is not null
    !!data.site?.armablePanelSerialNumber ||
    // if there is no fire zone on the first door
    state.doors.some((door) => door.fireZone && door.number === 1) ||
    // or there a panel has an alarm integration and its not this one
    (!!data.site?.alarmPanelX1Integration && !data.alarmPanelIntegrationOutput);

  const setupFailed = () => {
    relayEnv.commitUpdate((store) => {
      const record = store.get<SiteControlSystem>(data.id);
      if (record) {
        record.setValue(
          InitialConnectionStatus.NONE,
          "initialConnectionStatus"
        );
      }
    });
    dispatch({ type: "SETUP_FAILED" });
  };

  const trackSetup = () =>
    fetchQuery<ExistingSiteControlSystemFormTrackSetupQuery>(
      relayEnv,
      trackSetupQuery,
      { controlSystemId: data.id },
      { fetchPolicy: "network-only" }
    )
      .toPromise()
      .then((result) => {
        const initialConnectionStatus =
          result?.siteControlSystemSetupTracked?.initialConnectionStatus;
        if (initialConnectionStatus) {
          relayEnv.commitUpdate((store) => {
            const record = store.get<SiteControlSystem>(data.id);
            if (record) {
              record.setValue(
                initialConnectionStatus,
                "initialConnectionStatus"
              );
            }
          });
        } else {
          setupFailed();
        }
      })
      .catch(() => {
        setupFailed();
      });

  const setupControlSystem = () => {
    runSetupControlSystemMutation({
      variables: {
        controlSystemId: data.id,
      },
      optimisticUpdater: (store) => {
        const record = store.get<SiteControlSystem>(data.id);
        if (record) {
          record.setValue(
            InitialConnectionStatus.PENDING,
            "initialConnectionStatus"
          );
        }
      },
      updater: (store, result) => {
        const record = store.get<SiteControlSystem>(data.id);
        if (record) {
          record.setValue(
            result.setupSiteControlSystem?.controlSystem
              ? InitialConnectionStatus.PENDING
              : InitialConnectionStatus.NONE,
            "initialConnectionStatus"
          );
        }
      },
      onError: () => {
        setupFailed();
      },
      onCompleted: (result) => {
        if (!result.setupSiteControlSystem?.controlSystem) {
          setupFailed();
        } else {
          trackSetup();
        }
      },
    });
  };

  const runCreateX1AutoProgrammingJobMutation = () => {
    createX1AutoProgrammingJob({
      variables: {
        controlSystemId: data.id,
      },
      optimisticUpdater: (store) => {
        const record = store.get<SiteControlSystem>(data.id);
        if (record) {
          record.setValue(true, "hasPendingChanges");
        }
      },
      updater: (store, result) => {
        const record = store.get<SiteControlSystem>(data.id);
        if (record) {
          record.setValue(
            result.createX1AutoProgrammingJob?.controlSystem?.hasPendingChanges,
            "hasPendingChanges"
          );
        }
      },

      onError: () => {
        createNotification({
          type: "error",
          text: "Programming job failed.",
        });
      },
      onCompleted: ({ createX1AutoProgrammingJob }) => {
        if (createX1AutoProgrammingJob.type) {
          createNotification({
            type: "error",
            text: "Programming job failed.",
          });
        } else {
          createNotification({
            type: "success",
            text: "Auto programming job created successfully.",
          });
          onSaved();
        }
      },
    });
  };

  React.useEffect(() => {
    if (initialConnectionStatus === InitialConnectionStatus.PENDING && online) {
      trackSetup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unShownDoors = state.doors.filter((door) => !door.showDoor);

  return (
    <div style={{ position: "relative" }}>
      <Header>
        <Flex alignItems="center">
          <FieldLabel
            htmlFor="serialNumber"
            style={{ whiteSpace: "nowrap", paddingRight: "var(--measure-1x)" }}
          >
            Serial Number
          </FieldLabel>
          <div className="input-group">
            <ReadOnlyInput
              type="text"
              id="serialNumber"
              name="serialNumber"
              value={data.serialNumber}
              required
            />
            {initialConnectionStatus === InitialConnectionStatus.COMPLETE &&
            online ? (
              <span className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-dmp btn--replace-panel"
                  onClick={() => setReplaceX1ModalOpen(true)}
                  disabled={
                    savingControlSystem ||
                    deletingControlSystem ||
                    state.replacingPanel
                  }
                >
                  {`${data.hasReplaceX1Backup ? "Retry " : ""}Replace X1`}
                </button>
              </span>
            ) : null}
          </div>
          {initialConnectionStatus === InitialConnectionStatus.COMPLETE &&
          online &&
          !firmwareUpdateDismissed &&
          firmwareStatus === "UPDATES_AVAILABLE" &&
          !savingControlSystem &&
          !deletingControlSystem &&
          !state.replacingPanel ? (
            <PillButton
              marginLeft="var(--measure-12)"
              onClick={() => {
                dispatch({ type: "UPDATE_BUTTON_CLICKED" });
              }}
            >
              Update Available
            </PillButton>
          ) : null}
        </Flex>
        <SiteControlSystemJobStatusBadgeWithAutoRefresh
          siteControlSystem={data}
        />
        {(initialConnectionStatus !== InitialConnectionStatus.PENDING ||
          !online) && (
          <div className={styles.actions}>
            <button
              type="button"
              className="btn btn-sm btn-default"
              disabled={savingControlSystem || deletingControlSystem}
              onClick={onCancel}
            >
              {state.replacingPanel || state.refetching ? "Close" : "Cancel"}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-info"
              disabled={
                savingControlSystem ||
                deletingControlSystem ||
                data.hasPendingChanges ||
                state.replacingPanel ||
                state.doors.find(
                  (door) => door.number === 1 && door.name === ""
                ) !== undefined
              }
              onClick={() => {
                if (
                  state.alarmPanelIntegration &&
                  !state.alarmPanelIntegration.outputNumber
                ) {
                  dispatch({ type: "SHOW_ALARM_PANEL_OUTPUT_NUMBER_ERROR" });
                  return;
                }

                if (!savingControlSystem) {
                  saveControlSystem({
                    variables: {
                      controlSystem: {
                        id: data.id,
                        commType: state.commType as ControlSystemCommType,
                        doors: state.doors
                          .filter((door) => door.edited)
                          .map((door) => ({
                            id: door.id,
                            name: door.name,
                            strikeTime: Number(door.strikeTime),
                            strikeDelay: Number(door.strikeDelay),
                            fireZone: door.fireZone,
                            fireExit: door.fireExit,
                            includeInLockdown: door.includeInLockdown,
                            doorPropTime: Number(door.doorPropTime),
                            osdpOrWiegand: door.osdpOrWiegand,
                            doorSensor: door.doorSensor,
                            doorForce: door.doorForce,
                            requestToExit: door.requestToExit,
                            unlockOnRex: door.unlockOnRex,
                            buzzer: door.buzzer,
                            led: door.led,
                          })),
                        ...applyOnboardOutputState(onboardOutputs),
                        alarmPanelIntegration: state.alarmPanelIntegration
                          ?.outputNumber
                          ? {
                              outputNumber:
                                state.alarmPanelIntegration.outputNumber,
                            }
                          : null,
                      },
                      elevatorOutputModules:
                        data.intendedUsage === "ELEVATOR"
                          ? Object.entries(state.elevatorOutputModules)
                              .filter(
                                (
                                  entry: [string, any]
                                ): entry is [
                                  string,
                                  ElevatorOutputModuleState
                                ] => !!entry[1]
                              )
                              .reduce<
                                ChangeSiteControlSystemOutputModuleInput[]
                              >(
                                (acc, [key, value]) => [
                                  ...acc,
                                  {
                                    address: Number(key),
                                    name: value.name,
                                    outputs: value.outputs.map(
                                      (output, index) => ({
                                        relayNumber: index + 1,
                                        name: output,
                                      })
                                    ),
                                  },
                                ],
                                []
                              )
                          : null,
                    },
                    onError: () => {
                      createNotification({
                        type: "error",
                        text: "Failed to update doors.",
                      });
                    },
                    onCompleted: ({ updateSiteControlSystem }) => {
                      if (updateSiteControlSystem.errorType) {
                        createNotification({
                          type: "error",
                          text: "Failed to update doors.",
                        });
                      } else {
                        createNotification({
                          type: "success",
                          text: "Doors updated successfully.",
                        });
                        onSaved();
                      }
                    },
                  });
                }
              }}
            >
              {savingControlSystem ? "Saving..." : "Save"}
            </button>
            {canDelete && (
              <button
                disabled={
                  savingControlSystem ||
                  deletingControlSystem ||
                  state.replacingPanel
                }
                className="btn btn-sm btn-danger"
                onClick={() => {
                  dispatch({ type: "DELETE_BUTTON_CLICKED" });
                }}
              >
                Remove
              </button>
            )}
          </div>
        )}
      </Header>
      <Spacer size="4x" />
      {data.hasFailedPreProgrammingJob ? (
        <ProgrammingFailedAlert
          retryingPreProgrammingJob={creatingX1AutoProgrammingJob}
          retryPreProgrammingJob={runCreateX1AutoProgrammingJobMutation}
          dispatch={dispatch}
        />
      ) : null}
      {state.setupFailed ? (
        <SetupFailedAlert
          setupControlSystem={setupControlSystem}
          settingUpControlSystem={settingUpControlSystem}
          dispatch={dispatch}
        />
      ) : (state.replacingPanel || state.refetching) && online ? (
        <ReplacingAlert
          state={state}
          data={data}
          refetch={refetch}
          dispatch={dispatch}
        />
      ) : initialConnectionStatus === InitialConnectionStatus.NONE && online ? (
        <SetupRequiredAlert
          setupControlSystem={setupControlSystem}
          settingUpControlSystem={settingUpControlSystem}
          dispatch={dispatch}
        />
      ) : initialConnectionStatus === InitialConnectionStatus.PENDING &&
        online ? (
        <SetupPendingAlert />
      ) : initialConnectionStatus === InitialConnectionStatus.COMPLETE ||
        !online ? (
        <>
          {state.saveErrorMessage && (
            <FormError>
              <Alert type="error">{state.saveErrorMessage}</Alert>
            </FormError>
          )}
          {!online ? <PreProgramAlert /> : null}
          {state.doors.map((doorState, index) =>
            doorState.number === 1 || doorState.showDoor ? (
              <DoorForm
                key={doorState.id}
                doorIndex={index}
                state={doorState}
                commType={state.commType}
                intendedUse={data.intendedUsage}
                dispatch={dispatch}
                hasPendingChanges={data.hasPendingChanges}
                hasFireZone={!!data.site?.hasFireZone || hasFireZone(state)}
                alarmPanelIntegration={state.alarmPanelIntegration}
                alarmPanelIntegrationIsDisabled={
                  alarmPanelIntegrationIsDisabled
                }
                initiallyFocusName={initialSelectedDoor === doorState.id}
                canAutoFocus={canAutoFocus}
                isCellOnlyPanel={data.hasCell && !data.hasEasyConnect}
                onboardOutputs={
                  includeOnboardOutputs(doorState) ? onboardOutputs : undefined
                }
                setOnboardOutputs={
                  includeOnboardOutputs(doorState)
                    ? setOnboardOutputs
                    : undefined
                }
                onboardOutput1Available={
                  // if its not currently programmed, it's available
                  (!data.onboardOutput1 &&
                    data.alarmPanelIntegrationOutput?.number !== 1) ||
                  (data.onboardOutput1?.isAvailableForAlarmIntegration ?? false)
                }
                onboardOutput2Available={
                  (!data.onboardOutput2 &&
                    data.alarmPanelIntegrationOutput?.number !== 2) ||
                  (data.onboardOutput2?.isAvailableForAlarmIntegration ?? false)
                }
              />
            ) : null
          )}
          {data.intendedUsage === "ELEVATOR" && data.name && (
            <DoorFormFloors
              state={state.elevatorOutputModules}
              dispatch={dispatch}
              data={data}
            />
          )}
          {unShownDoors.length && data.intendedUsage !== "ELEVATOR" ? (
            <Footer>
              <Spacer size="2x" />
              <div className={styles.actions}>
                <button
                  type="button"
                  className="btn btn-info"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  onClick={() => {
                    dispatch({ type: "SHOW_NEXT_DOOR" });
                  }}
                >
                  <InvisibleSpacer>
                    {unShownDoors.length} available
                  </InvisibleSpacer>
                  <span>+ Add Door </span>
                  <AddDoorCount>{unShownDoors.length} available</AddDoorCount>
                </button>
              </div>
            </Footer>
          ) : null}
        </>
      ) : null}
      {state.confirmingDelete && (
        <DeleteConfirmModalOverlay>
          <DeleteConfirmModalBody
            actionPending={deletingControlSystem}
            onConfirm={() => {
              if (!deletingControlSystem) {
                deleteControlSystem({
                  variables: {
                    controlSystemId: data.id,
                  },
                  onCompleted({ removeSiteControlSystem }) {
                    if (!removeSiteControlSystem.errorType) {
                      onDeleted();
                      createNotification({
                        type: "success",
                        text: "Door removed successfully.",
                      });
                    } else {
                      createNotification({
                        type: "error",
                        text: `Failed to delete device #${data.serialNumber}.`,
                      });
                    }
                  },
                });
              }
            }}
            onCancel={() => {
              dispatch({ type: "DELETE_CANCELED" });
            }}
          >
            Are you sure you want to delete this device?
          </DeleteConfirmModalBody>
        </DeleteConfirmModalOverlay>
      )}
      {firmwareUpdateDismissed ? null : firmwareStatus ===
        "UPDATES_IN_PROGRESS" ? (
        <ConfirmModalOverlay>
          <FirmwareUpdateProgress percentage={firmwareUpdateProgress ?? 0} />
          <Flex justifyContent="flex-end">
            <button
              type="button"
              className="btn btn-sm btn-default"
              onClick={onCancel}
            >
              Dismiss
            </button>
          </Flex>
        </ConfirmModalOverlay>
      ) : firmwareStatus === "UPDATES_FAILED" ? (
        <ConfirmModalOverlay>
          <UpdatesFailed
            siteId={data.site!.id}
            onCancel={() => {
              // If the site already has updates in progress, we want to allow
              // them to dismiss the Updates Available indicator but without
              // dismissing the firmware updates stuff for the rest of the site.
              if (siteHasFirmwareUpdatesInProgress) {
                relayEnv.commitUpdate((store) => {
                  const systemRecord = store.get<SiteControlSystem>(data.id);
                  if (systemRecord) {
                    systemRecord.setValue(true, "firmwareUpdateDismissed");
                  }
                });
              } else {
                dismissFailedUpdates(relayEnv, data.site!.id);
              }
              dispatch({ type: "UPDATE_CANCELED" });
            }}
            onRun={() => {
              dispatch({ type: "UPDATE_RUNNING" });
            }}
          />
        </ConfirmModalOverlay>
      ) : state.confirmingFirmwareUpdate ? (
        <ConfirmModalOverlay>
          <UpdatesAvailable
            siteId={data.site?.id ?? ""}
            onCancel={() => {
              // If the site already has updates in progress, we want to allow
              // them to dismiss the Updates Available indicator but without
              // dismissing the firmware updates stuff for the rest of the site.
              if (siteHasFirmwareUpdatesInProgress) {
                relayEnv.commitUpdate((store) => {
                  const systemRecord = store.get<SiteControlSystem>(data.id);
                  if (systemRecord) {
                    systemRecord.setValue("UP_TO_DATE", "firmwareStatus");
                    systemRecord.setValue(true, "firmwareUpdateDismissed");
                  }
                });
              } else {
                dismissAvailableUpdates(relayEnv, data.site!.id);
              }
              dispatch({ type: "UPDATE_CANCELED" });
            }}
            onRun={() => {
              dispatch({ type: "UPDATE_RUNNING" });
            }}
          />
        </ConfirmModalOverlay>
      ) : null}
      {replaceX1ModalOpen ? (
        <ReplaceX1Modal
          siteId={data.id}
          systemName={data.name}
          hasBackup={data.hasReplaceX1Backup}
          onCancel={() => setReplaceX1ModalOpen(false)}
          refetch={refetch}
          dispatch={dispatch}
        />
      ) : null}
    </div>
  );
}

function SetupPendingAlert() {
  return (
    <Alert type="info" icon={<i className="fa fa-spin fa-spinner"></i>}>
      Setting up X1. This could take a few seconds.
    </Alert>
  );
}

interface ReplacingAlertProps {
  state: SiteControlSystemFormState;
  refetch: (vars: Partial<Variables>, options?: any) => Disposable;
  data: ExistingSiteControlSystemForm_siteControlSystem$data;
  dispatch: React.Dispatch<SiteControlSystemFormAction>;
}
function ReplacingAlert(props: ReplacingAlertProps) {
  const { state, data, refetch, dispatch } = props;
  const { replacingPanelJobGroupId } = data;
  const createNotification = useCreateNotification();
  const [refreshProgress] =
    useMutation<ExistingSiteControlSystemFormReplacingX1ProgressMutation>(
      replacingX1ProgressMutation
    );
  const currentReplacingPanelJobGroupId = React.useRef(0);

  React.useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const refreshPolls: Array<Timer> = [];
    const pollingInterval = 10000;

    // Note: From the ReplaceX1Modal, this component can be entered early before refetching of the data for replacement is complete.
    // When that happens, replacingPanelJobGroupId will be null but we still want to show that the X1 is being replaced. Once the
    // refetch triggered from the modal is complete, this page will automatically rerender showing replacingPanelJobGroupId is not
    // null and will continue to poll and rerender until the job is complete.
    if (replacingPanelJobGroupId) {
      currentReplacingPanelJobGroupId.current = replacingPanelJobGroupId;
      refreshPolls.push(
        setTimeout(function reloadData() {
          refetch({}, { fetchPolicy: "store-and-network" });
          refreshPolls.push(setTimeout(reloadData, pollingInterval));
        }, pollingInterval)
      );
    } else if (!state.refetching) {
      // We don't want to update the state when it is refetching triggered from the ReplaceX1Modal

      // At this point, the jobGroup should be completed, so checking the result of the job.
      refreshProgress({
        variables: {
          jobId: currentReplacingPanelJobGroupId.current,
        },
        onCompleted: ({ refreshProgress }) => {
          dispatch({ type: "UPDATE_DOORS", value: data.doors });
          dispatch({ type: "UPDATE_REPLACING_PANEL", value: false });
          if (refreshProgress.jobStatus === "fail") {
            createNotification({
              type: "error",
              text: refreshProgress.jobOutput ?? "Failed to replace X1.",
            });
          } else {
            createNotification({
              type: "success",
              text: "X1 successfully replaced.",
            });
          }
        },
      });
    }
    return () => {
      refreshPolls.forEach((id) => clearTimeout(id));
    };
  }, [replacingPanelJobGroupId, refetch]);

  return (
    <Alert type="info" icon={<i className="fa fa-spin fa-spinner"></i>}>
      Replacing X1… This may take a few minutes to complete. Do not power down
      the X1, or remove its network or cellular connection.
    </Alert>
  );
}

type SetupFailedAlertProps = {
  settingUpControlSystem: boolean;
  setupControlSystem: () => void;
  dispatch: React.Dispatch<SiteControlSystemFormAction>;
};

function SetupFailedAlert(props: SetupFailedAlertProps) {
  return (
    <Alert type="error" icon={<i className="icon-attention text-danger"></i>}>
      Failed to set up X1
      <button
        type="button"
        className="btn btn-sm btn-info"
        style={{ marginLeft: "1rem" }}
        disabled={props.settingUpControlSystem}
        onClick={() => {
          props.dispatch({ type: "RETRY_SETUP_BUTTON_CLICKED" });

          if (!props.settingUpControlSystem) {
            props.setupControlSystem();
          }
        }}
      >
        Retry
      </button>
    </Alert>
  );
}

type SetupRequiredAlertProps = {
  settingUpControlSystem: boolean;
  setupControlSystem: () => void;
  dispatch: React.Dispatch<SiteControlSystemFormAction>;
};

function SetupRequiredAlert(props: SetupRequiredAlertProps) {
  return (
    <Alert type="info" icon={<i className="icon-radial_info text-primary"></i>}>
      <Flex justifyContent="space-between">
        Dealer Admin needs to set up this X1. This could take a few seconds to
        complete.
        <button
          type="button"
          className="btn btn-sm btn-info"
          disabled={props.settingUpControlSystem}
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            if (!props.settingUpControlSystem) {
              props.setupControlSystem();
            }
          }}
        >
          Set Up
        </button>
      </Flex>
    </Alert>
  );
}

type ProgrammingFailedAlertProps = {
  retryingPreProgrammingJob: boolean;
  retryPreProgrammingJob: () => void;
  dispatch: React.Dispatch<SiteControlSystemFormAction>;
};

function ProgrammingFailedAlert(props: ProgrammingFailedAlertProps) {
  return (
    <Alert type="error" icon={<i className="icon-radial_info text-alert"></i>}>
      <Flex justifyContent="space-between">
        Programming failed to send down to this X1. Ensure the X1 is powered up
        and has a reliable cellular or network connection and try again.
        <button
          type="button"
          className="btn btn-sm btn-alert"
          disabled={props.retryingPreProgrammingJob}
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            if (!props.retryingPreProgrammingJob) {
              props.retryPreProgrammingJob();
            }
          }}
        >
          {props.retryingPreProgrammingJob ? (
            <>
              <i className="fa fa-spin fa-spinner" /> Retrying
            </>
          ) : (
            "Retry"
          )}
        </button>
      </Flex>
    </Alert>
  );
}

const ProgrammingHeader = styled.div`
  font-weight: bold;
  padding-left: 1rem;
`;
const ProgrammingContent = styled.div`
  padding-left: 2rem;
`;

function PreProgramAlert() {
  return (
    <Alert type="info">
      <Flex>
        <i className="fa fa-spin fa-spinner" />
        <ProgrammingHeader>Programming Pending</ProgrammingHeader>
      </Flex>
      <ProgrammingContent>
        Any changes made to this X1, or any user configuration in Virtual Keypad
        will be automatically saved. When this X1 comes online, it will be
        automatically programmed.
      </ProgrammingContent>
    </Alert>
  );
}

function ExistingSiteControlSystemFormRoot({
  firmwareStatus,
  firmwareUpdateProgress,
  queryReference,
  siteHasFirmwareUpdatesInProgress,
  initialSelectedDoor,
  onSaved,
  onDeleted,
  onCancel,
  canAutoFocus,
  canDelete,
}: {
  firmwareStatus: PanelFirmwareStatus;
  firmwareUpdateProgress: number | null;
  queryReference: PreloadedQuery<ExistingSiteControlSystemFormQuery>;
  initialSelectedDoor: string | null;
  siteHasFirmwareUpdatesInProgress: boolean;
  onSaved: () => void;
  onDeleted: () => void;
  onCancel: () => void;
  canAutoFocus: boolean;
  canDelete: boolean;
}) {
  const data = usePreloadedQuery(
    graphql`
      query ExistingSiteControlSystemFormQuery($siteControlSystemId: ID!) {
        node(id: $siteControlSystemId) {
          ... on SiteControlSystem {
            ...ExistingSiteControlSystemForm_siteControlSystem
            id
          }
        }
      }
    `,
    queryReference
  );

  return data.node?.id ? (
    <ExistingSiteControlSystemForm
      firmwareStatus={firmwareStatus}
      firmwareUpdateProgress={firmwareUpdateProgress}
      siteControlSystemRef={data.node}
      siteHasFirmwareUpdatesInProgress={siteHasFirmwareUpdatesInProgress}
      initialSelectedDoor={initialSelectedDoor}
      onSaved={onSaved}
      onDeleted={onDeleted}
      onCancel={onCancel}
      canAutoFocus={canAutoFocus}
      canDelete={canDelete}
    />
  ) : null;
}

export function ExistingSiteControlSystemModal(props: {
  firmwareStatus: PanelFirmwareStatus;
  firmwareUpdateProgress: number | null;
  siteHasFirmwareUpdatesInProgress: boolean;
  queryReference: PreloadedQuery<ExistingSiteControlSystemFormQuery>;
  initialSelectedDoor: string | null;
  onSaved: () => void;
  onDeleted: () => void;
  onCancel: () => void;
  canDelete: boolean;
}) {
  const [canAutoFocus, setCanAutoFocus] = React.useState(false);
  return (
    <Modal
      onTransitionEnd={() => {
        setCanAutoFocus(true);
      }}
      size="large"
      animate={false}
    >
      <Modal.Body>
        <SuspenseBoundary>
          <ExistingSiteControlSystemFormRoot
            {...props}
            canAutoFocus={canAutoFocus}
            canDelete={props.canDelete}
          />
        </SuspenseBoundary>
      </Modal.Body>
    </Modal>
  );
}
