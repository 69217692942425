import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatNameField_cardFormat$key } from "./__generated__/CardFormatNameField_cardFormat.graphql";

export const cardFormatNameFieldId = (number: string) =>
  `card-format-name-${number}`;

function CardFormatNameField() {
  const [{ name, number }, updateCardFormat] =
    useCardFormatFragment<CardFormatNameField_cardFormat$key>(
      graphql`
        fragment CardFormatNameField_cardFormat on CardFormat {
          id
          name
          number
        }
      `
    );

  const fieldId = cardFormatNameFieldId(number);
  const disabled = false;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Card Format Name"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        required
        value={name}
        maxLength={16}
        disabled={disabled}
        onChange={({ target }) => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(target.value, "name");
          });
        }}
        onBlur={({ target }) => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(target.value.trim().toUpperCase(), "name");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatNameField;
