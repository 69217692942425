/**
 *
 * SearchRightIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SearchRightIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M57.845,5.821c-3.839-3.834-8.946-5.946-14.379-5.946c-5.435,0-10.544,2.113-14.388,5.948
    c-3.845,3.837-5.962,8.938-5.961,14.364c0,4.727,1.609,9.204,4.565,12.814L0.193,58.534l2.723,2.932l27.592-25.628
    c3.638,3.008,8.169,4.648,12.953,4.648c5.435,0,10.543-2.111,14.383-5.944c3.846-3.837,5.964-8.938,5.963-14.364
    C63.807,14.754,61.688,9.654,57.845,5.821z M55.019,31.711c-3.085,3.079-7.189,4.775-11.558,4.775
    c-4.367,0-8.473-1.696-11.561-4.776c-3.084-3.077-4.783-7.169-4.783-11.522c0-4.356,1.699-8.452,4.787-11.532
    c3.088-3.083,7.193-4.78,11.562-4.78c4.365,0,8.469,1.696,11.554,4.777c3.087,3.078,4.787,7.172,4.787,11.526
    C59.808,24.534,58.107,28.629,55.019,31.711z"
      />
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default SearchRightIcon;
