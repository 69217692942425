/**
 * @generated SignedSource<<81469ecebd1c2152dc80a7f5c6142037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PcLogReportsArmDisarmReportsEnabledField_pcLogReports$data = {
  readonly armDisarmReportsEnabled: boolean;
  readonly " $fragmentType": "PcLogReportsArmDisarmReportsEnabledField_pcLogReports";
};
export type PcLogReportsArmDisarmReportsEnabledField_pcLogReports$key = {
  readonly " $data"?: PcLogReportsArmDisarmReportsEnabledField_pcLogReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"PcLogReportsArmDisarmReportsEnabledField_pcLogReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PcLogReportsArmDisarmReportsEnabledField_pcLogReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armDisarmReportsEnabled",
      "storageKey": null
    }
  ],
  "type": "PcLogReports",
  "abstractKey": null
};

(node as any).hash = "6d937bbea4b80995c9c9ea7094a9fffc";

export default node;
