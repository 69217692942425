import loadScript from "common/utils/loadScript";
import { getApUiUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import React from "react";
import { getAuthFromJWT } from "utils/string";

declare global {
  interface Window {
    mountApUi: (id: string, props: any) => void;
    unmountApUi: () => void;
  }
}

const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;
const baseUrl = getApUiUrl(secureComEnv);
const apUiUrl = `${baseUrl}/ap-ui.js`;
loadScript(apUiUrl);

type Props = {
  authToken: string;
  macAddresses: string[];
};

const ApHealthAndDiagnostics = ({ authToken, macAddresses }: Props) => {
  React.useEffect(() => {
    window.mountApUi("ap-ui", {
      page: "video-health-diagnostics",
      scwToken: authToken.length > 40 ? getAuthFromJWT(authToken) : authToken,
      deviceMacAddresses: macAddresses,
    });

    return () => window.unmountApUi();
  }, [authToken, macAddresses]);

  return <div id="ap-ui"></div>;
};

export default ApHealthAndDiagnostics;
