import { isNil, curry, pipe, not, equals, isEmpty } from "ramda";
import { isNotNull, isNotNullOrUndefined } from "./function";

export function isObjectLiteral(object: any): object is object {
  return (
    !isNil(object) &&
    Object.prototype.toString.call(object) === "[object Object]" &&
    Object.getPrototypeOf(object) === Object.prototype
  );
}

export function hasOwnProperty(property: string, object: any) {
  return Object.prototype.hasOwnProperty.call(object, property);
}

export function hasAnyProperties(properties: string[], object: any) {
  for (let property in properties) {
    if (hasOwnProperty(property, object)) {
      return true;
    }
  }

  return false;
}

export const propIdAsString = <A extends { id: any }>(obj: A) => String(obj.id);

type UnionFromEntriesArray<T, K extends keyof T> = K extends string
  ? [K, T[K]]
  : [string, T[K]];

/**
 * Types object entries as a union of key value pairs.
 *
 * **Notes:**
 * 1. The object should have all string keys
 *     - number keys will be converted by Object.entries to "string"
 *     - Symbol keys will be ignored
 *
 * E.G:
 * ```
 * {
 *   "key": "👍",
 *   1: "👎", // converted to "string"
 *   [Symbol(5)]: "👎", // ignored
 * }
 * ```
 *
 * 2. When using this function it is essential to make sure the types
 * of the object are correct, if they are not it could cause unintended
 * consequences:
 *
 * > ... It makes sense only in the domain of type variables (i.e. T and keyof T).
 * > Once you move to the instantiated type world it degenerates because an object
 * > can (and often does) have more properties at run-time than are statically known
 * > at compile time.
 *
 * For more details see:
 * https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
 *
 * @returns an array with the union of [key, value] for each object key
 */
export function getEntries<T extends Readonly<{ [key: string]: any }>>(
  object: T
) {
  return (Object.entries(object) as unknown) as UnionFromEntriesArray<
    T,
    keyof T
  >[];
}

export const addKeyIf = (validatorFn: (v: any) => boolean) => (
  key: string,
  value: any | undefined,
  formatValue: (v: any) => any = (v) => v
) => {
  return validatorFn(value) ? { [key]: formatValue(value) } : {};
};

export const addKeyIfNotNull = addKeyIf(isNotNull);
export const addKeyIfNotEmpty = addKeyIf(pipe(isEmpty, not));
export const addKeyIfNotUndefined = addKeyIf(pipe(equals(undefined), not));
export const addKeyIfNotNullOrUndefined = addKeyIf(isNotNullOrUndefined);

/**
 * @deprecated use addKeyIfNotUndefined instead
 */
export const conditionallyAddKey = (
  key: string,
  value: any | undefined,
  formatValue: (v: any) => any = (v) => v
) => {
  return value !== undefined && !value?.isNothing
    ? { [key]: formatValue(value) }
    : {};
};

export const addKeyIfTruthy = (
  key: string,
  value: any,
  formatValue: (v: any) => any = (v) => v
) => {
  return !!value ? { [key]: formatValue(value) } : {};
};

type AddKey = (<Key extends string, Value, Object>(
  key: Key,
  value: Value,
  object: Object
) => Object & { [addedKey in Key]: Value }) &
  (<Key extends string, Value>(
    key: Key,
    value: Value
  ) => <Object>(object: Object) => Object & { [addedKey in Key]: Value }) &
  (<Key extends string>(
    key: Key
  ) => <Value, Object>(
    value: Value,
    object: Object
  ) => Object & { [addedKey in Key]: Value }) &
  (<Key extends string>(
    key: Key
  ) => <Value>(
    value: Value
  ) => <Object>(object: Object) => Object & { [addedKey in Key]: Value });
export const addKey: AddKey = curry((key: string, value: any, object: any) => ({
  ...object,
  [key]: value,
})) as any;
