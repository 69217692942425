/**
 * @generated SignedSource<<bb77dcb698ab150c0274e45c6e5efef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneStatus = "BYPASSED" | "DEFAULT" | "LOW_BATTERY" | "MISSING" | "NORMAL" | "OPEN" | "SHORT" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneStatusIndicator_zone$data = {
  readonly status: ZoneStatus | null;
  readonly " $fragmentType": "ZoneStatusIndicator_zone";
};
export type ZoneStatusIndicator_zone$key = {
  readonly " $data"?: ZoneStatusIndicator_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneStatusIndicator_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneStatusIndicator_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "d6b98f1ce8e45f7ce8459d5d35c1b408";

export default node;
