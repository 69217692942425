/**
 *
 * CycleIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CycleIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M61.301,42.107c0.148-0.466,0.288-0.936,0.417-1.408c0.015-0.055,0.028-0.109,0.042-0.163
      c0.124-0.466,0.239-0.934,0.343-1.405c0.01-0.049,0.02-0.098,0.029-0.145c0.104-0.484,0.199-0.972,0.281-1.462
      c0.003-0.019,0.006-0.038,0.009-0.058c0.257-1.551,0.414-3.128,0.447-4.724c0.004-0.177-0.004-0.351-0.003-0.526
      c0.059-7.609-2.513-14.976-7.178-20.775l5.209,0.167l0.129-3.998L48.828,7.22l-0.166,12.977l4,0.051l0.076-6.021
      c3.969,5.011,6.148,11.307,6.128,17.9c-0.001,0.149,0.005,0.3,0.002,0.449c-0.162,7.698-3.253,14.81-8.701,20.025
      c-5.146,4.925-11.82,7.524-18.82,7.393c-2.969-0.068-5.876-0.635-8.639-1.683l-1.418,3.74c3.188,1.209,6.541,1.862,9.966,1.94
      c0.23,0.006,0.46,0.009,0.69,0.009c7.187,0,14.049-2.554,19.58-7.251l0.047,0.055c4.548-3.871,7.87-8.946,9.695-14.595
      C61.279,42.175,61.29,42.141,61.301,42.107z"
        />
        <path
          d="M11.133,49.838C7.07,44.687,4.861,38.154,5.004,31.425c0.162-7.698,3.252-14.811,8.701-20.026
      c5.146-4.926,11.806-7.537,18.822-7.393c3.013,0.068,5.959,0.65,8.757,1.728l1.438-3.732c-3.229-1.244-6.629-1.915-10.104-1.993
      c-8.057-0.198-15.76,2.836-21.679,8.501c-6.222,5.955-9.75,14.063-9.936,22.832C0.84,39.104,3.429,46.645,8.185,52.557
      l-5.21-0.166l-0.127,3.998l12.199,0.39l0.163-12.976l-4-0.051L11.133,49.838z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default CycleIcon;
