/**
 * @ngdoc service
 * @name App.factory:CustomerReferralAPI
 *
 * @description
 * API factory for Customer Referral Program
 *
 */

App.factory("CustomerEngagementAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/dealers/:dealer_id/:type",
      {},
      {
        requestCount: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            type: "CustomerEngagementEmails/count",
          },
        },
        requestLastSentDates: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            type: "CustomerEngagementEmails/dates",
          },
          isArray: true,
        },
      }
    );
  },
]);
