/**
Service for Cameras In Alarm
*/

App.factory("CamerasInAlarmService", [
  "$q",
  "CamerasInAlarmAPI",
  "$filter",
  "UserService",
  function ($q, CamerasInAlarmAPI, $filter, UserService) {
    return {
      getCamsInAlarm: function (dealerId, controlSystemId) {
        var deferred = $q.defer();
        CamerasInAlarmAPI.getCamsInAlarm(
          {
            dealer_id: dealerId,
            control_system_id: controlSystemId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
