/**
 * @generated SignedSource<<3ed6bdffa6ae72e05b6918537c1cdfd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly outputInformations: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputSupervision: {
      readonly data: OutputSupervision | null;
      readonly included: boolean | null;
    } | null;
    readonly realTimeStatus: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly tripWithPanelBell: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "8473a96b5661223b28b8a694d16084ef";

export default node;
