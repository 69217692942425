import Icon from "components/Icon";
import * as React from "react";
import styled from "styled-components";

export const PasswordIndicatorCluster = styled.div`
  display: flex;
  font-size: 1.2rem;
  margin-left: 1.7rem;
  margin-top: 0.7rem;
  margin-bottom: 2rem;
`;

export const PasswordIndicatorClusterRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

type PasswordConditionIndicatorPropsType = {
  conditionText: string;
  conditionMet: boolean;
};
const PasswordConditionIndicator = ({
  conditionText,
  conditionMet,
}: PasswordConditionIndicatorPropsType) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        style={{
          color: conditionMet && "var(--color-success-500)",
        }}
        size={"1.4rem"}
        name="radial_check"
      />
      <strong
        style={{
          marginLeft: "0.3em",
        }}
      >
        {conditionText}
      </strong>
    </div>
  );
};

export default PasswordConditionIndicator;
