import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationBackupCheckInMinutesField_communication$key } from "./__generated__/CommunicationBackupCheckInMinutesField_communication.graphql";

export const communicationBackupCheckInMinutesFieldId = () =>
  "communication-backup-check-in-minutes";

function CommunicationBackupCheckInMinutesField() {
  const [
    {
      backupCheckinMinutes,
      backupFailTimeMinutes,
      checkInMinutesValidValues,
      backupCell,
    },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationBackupCheckInMinutesField_communication$key>(
    graphql`
      fragment CommunicationBackupCheckInMinutesField_communication on Communication {
        backupCheckinMinutes
        backupFailTimeMinutes
        checkInMinutesValidValues
        backupCell
      }
    `
  );

  const fieldId = communicationBackupCheckInMinutesFieldId();
  const { current: originalValue } = React.useRef(backupCheckinMinutes);
  const label = "Backup Check-in Minutes";
  const disabled = !backupCell;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Number of minutes the System waits between sending Check-In messages to the Receiver using backup cell."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={backupCheckinMinutes ?? ""}
        required
        disabled={disabled}
        validationMessage="Valid values are 0, 3-240."
        pattern="(0{0,3}|0{0,2}[3-9]|0?[1-9][0-9]|1[0-9]{2}|2[0-3][0-9]|240)"
        inlineHelp={"0,3-240"}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "backupCheckinMinutes"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : closest(valueAsNumber, checkInMinutesValidValues);
            recordProxy.setValue(Number(value), "backupCheckinMinutes");
            if (valueAsNumber === 0) {
              recordProxy.setValue(Number(value), "backupFailTimeMinutes");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationBackupCheckInMinutesField;
