/**
 * @generated SignedSource<<7d1a8ef934fbbe85801fe846ee5eab9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemReportsOpenCloseEnableField_systemReports$data = {
  readonly openCloseEnable: boolean;
  readonly " $fragmentType": "SystemReportsOpenCloseEnableField_systemReports";
};
export type SystemReportsOpenCloseEnableField_systemReports$key = {
  readonly " $data"?: SystemReportsOpenCloseEnableField_systemReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemReportsOpenCloseEnableField_systemReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemReportsOpenCloseEnableField_systemReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openCloseEnable",
      "storageKey": null
    }
  ],
  "type": "SystemReports",
  "abstractKey": null
};

(node as any).hash = "0027d9f848a5242c518768d00d35d380";

export default node;
