/**
 * @ngdoc service
 * @name App.factory:Customer
 *
 * @description
 * API factory for Customer Information
 *
 */
App.factory("UserCodeService", [
  "$q",
  "PartsCatalogAPI",
  "PanelsODataAPI",
  function ($q, PartsCatalogAPI, PanelsODataAPI) {
    return {
      getPart: function (panel_id, userId) {
        var deferred = $q.defer();
        var text = "";

        /*get two random characters for api call*/
        var getRandomChar = function () {
          var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
          for (var i = 0; i < 2; i++)
            text += possible.charAt(
              Math.floor(Math.random() * possible.length)
            );
          return text;
        };
        getRandomChar();
        var random = text;

        PartsCatalogAPI.show(
          {
            rand:
              random.toString() +
              "-" +
              panel_id.toString() +
              "-" +
              Number(userId).toString(),
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getCodesForPanel: function (panelId, select) {
        let deferred = $q.defer();
        let codesPromise = PanelsODataAPI.getUserCodes({
          id: panelId,
          $select: select || "*",
        }).$promise;
        let oDataPageService = new OdataPageService();
        oDataPageService.getAllPages(codesPromise).then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "ODataService->getCustomers() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getUserCode: function (panelId, number, select) {
        let deferred = $q.defer();
        PanelsODataAPI.getUserCodes(
          {
            id: panelId,
            $select: select || "*",
            $filter: "record_number eq " + number,
          },
          function (data) {
            deferred.resolve(data.value[0]);
          },
          function (error) {
            console.error(
              "ODataService->getCustomers() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getCredentialsByPanelId: function (panelId, filter) {
        let deferred = $q.defer();
        PanelsODataAPI.getCredentials(
          {
            id: panelId,
            $filter: filter,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "ODataService->getCredentials() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
