import graphql from "babel-plugin-relay/macro";
import Flex from "common/components/web/Flex";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { average } from "common/utils/universal/numbers";
import DefaultButton from "components/Button";
import Icon from "components/Icon";
import { HorizontalPopover } from "components/Popover";
import React, { useEffect } from "react";
import {
  useFragment,
  useMutation,
  useRefetchableFragment,
  useRelayEnvironment,
} from "react-relay";
import { Site } from "securecom-graphql/client";
import styled from "styled-components";
import FirmwareUpdateProgress from "./FirmwareUpdateProgress";
import { SiteFirmwareUpdateDialogueMutation } from "./__generated__/SiteFirmwareUpdateDialogueMutation.graphql";
import {
  SiteFirmwareUpdateDialogue_site$data,
  SiteFirmwareUpdateDialogue_site$key,
} from "./__generated__/SiteFirmwareUpdateDialogue_site.graphql";
import { SiteFirmwareUpdateDialogue_UpdateProgressQuery } from "./__generated__/SiteFirmwareUpdateDialogue_UpdateProgressQuery.graphql";
import { SiteFirmwareUpdateDialogue_UpdateProgress_site$key } from "./__generated__/SiteFirmwareUpdateDialogue_UpdateProgress_site.graphql";

const Container = styled.section<{ color?: string }>`
  margin: 0 auto var(--measure-2x);
  padding: var(--measure-2x);
  background-color: white;
  border-radius: 0.4rem;
  border: 1px solid ${({ color }) => color || "var(--color-info-500)"};
`;
const Title = styled.h1<{ color?: React.CSSProperties["color"] }>`
  display: flex;
  align-items: center;
  margin: 0 0 var(--measure-12);
  color: ${({ color }) => color || "var(--color-info-500)"};
`;
const TitleIcon = styled(Icon)`
  font-size: var(--measure-font-24);
`;
const TitleText = styled.span`
  display: inline-block;
  margin-left: var(--measure-1x);
  font-size: var(--measure-font-20);
`;
const Button = styled(DefaultButton)`
  min-width: 6rem;

  &:not(:last-child) {
    margin-right: var(--measure-1x);
  }
`;
const ButtonContainer = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-right: var(--measure-1x);
  }

  & ${Button} {
    margin-right: 0;
  }
`;

export function dismissFailedUpdates(
  relayEnv: ReturnType<typeof useRelayEnvironment>,
  siteId: string
) {
  relayEnv.commitUpdate((store) => {
    const siteRecord = store.get<
      Site & {
        firmwareUpdateDismissed: boolean | null | undefined;
      }
    >(siteId);
    if (siteRecord) {
      siteRecord.setValue(true, "firmwareUpdateDismissed");
      const siteSystems = siteRecord.getLinkedRecords("controlSystems");
      if (siteSystems) {
        siteSystems
          .filter(
            (systemRecord) =>
              systemRecord.getValue("firmwareStatus") === "UPDATES_FAILED"
          )
          .forEach((systemRecord) => {
            systemRecord.setValue("UP_TO_DATE", "firmwareStatus");
            systemRecord.setValue(null, "firmwareUpdateProgress");
            systemRecord.setValue(true, "firmwareUpdateDismissed");
          });
      }
    }
  });
}

export function dismissSuccessfulUpdates(
  relayEnv: ReturnType<typeof useRelayEnvironment>,
  siteId: string
) {
  relayEnv.commitUpdate((store) => {
    const siteRecord = store.get<
      Site & {
        firmwareUpdateDismissed: boolean | null | undefined;
      }
    >(siteId);
    if (siteRecord) {
      siteRecord.setValue(true, "firmwareUpdateDismissed");
      const siteSystems = siteRecord.getLinkedRecords("controlSystems");
      if (siteSystems) {
        siteSystems
          .filter(
            (systemRecord) =>
              systemRecord.getValue("firmwareStatus") === "UPDATES_SUCCESSFUL"
          )
          .forEach((systemRecord) => {
            systemRecord.setValue("UP_TO_DATE", "firmwareStatus");
            systemRecord.setValue(null, "firmwareUpdateProgress");
            systemRecord.setValue(true, "firmwareUpdateDismissed");
          });
      }
    }
  });
}

export function dismissAvailableUpdates(
  relayEnv: ReturnType<typeof useRelayEnvironment>,
  siteId: string
) {
  relayEnv.commitUpdate((store) => {
    const siteRecord = store.get<
      Site & {
        firmwareUpdateDismissed: boolean | null | undefined;
        controlSystems: Site["controlSystems"][number] &
          {
            firmwareUpdateDismissed: boolean | null | undefined;
          }[];
      }
    >(siteId);
    if (siteRecord) {
      siteRecord.setValue(true, "firmwareUpdateDismissed");
      const controlSystems = siteRecord.getLinkedRecords("controlSystems");
      if (controlSystems) {
        controlSystems.forEach((controlSystem) => {
          controlSystem.setValue(true, "firmwareUpdateDismissed");
        });
      }
    }
  });
}

export default function SiteFirmwareUpdateDialogue(props: {
  site: SiteFirmwareUpdateDialogue_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment SiteFirmwareUpdateDialogue_site on Site {
        id
        firmwareUpdateDismissed
        controlSystems {
          id
          firmwareStatus
          firmwareUpdateProgress
          serialNumber
          firmwareUpdateDismissed
        }
        ...SiteFirmwareUpdateDialogue_UpdateProgress_site
      }
    `,
    props.site
  );

  const updatesInProgress = data?.controlSystems?.some(
    ({ firmwareStatus }) => firmwareStatus === "UPDATES_IN_PROGRESS"
  );

  const updatesFailed =
    !updatesInProgress &&
    data?.controlSystems?.some(
      ({ firmwareStatus }) => firmwareStatus === "UPDATES_FAILED"
    );

  const updatesSucceeded =
    !updatesInProgress &&
    data?.controlSystems?.every(
      ({ firmwareStatus }) => firmwareStatus === "UPDATES_SUCCESSFUL"
    );

  const updatesAvailable = data?.controlSystems?.some(
    ({ firmwareStatus }) => firmwareStatus === "UPDATES_AVAILABLE"
  );

  const relayEnv = useRelayEnvironment();

  const firmwareUpdateDismissed =
    data.firmwareUpdateDismissed ||
    data.controlSystems?.every(
      (controlSystem) => controlSystem.firmwareUpdateDismissed
    );

  return firmwareUpdateDismissed ? null : updatesInProgress ? (
    <UpdatesInProgress site={data} />
  ) : updatesFailed ? (
    <Container className="box" color="var(--color-danger-700)">
      <UpdatesFailed
        siteId={data.id}
        onCancel={() => {
          dismissFailedUpdates(relayEnv, data.id);
        }}
      />
    </Container>
  ) : updatesSucceeded ? (
    <Container className="box" color="var(--color-success-400)">
      <UpdatesSucceeded
        siteId={data.id}
        onDismiss={() => {
          dismissSuccessfulUpdates(relayEnv, data.id);
        }}
      />
    </Container>
  ) : updatesAvailable ? (
    <Container className="box">
      <UpdatesAvailable
        siteId={data.id}
        onCancel={() => {
          dismissAvailableUpdates(relayEnv, data.id);
        }}
      />
    </Container>
  ) : null;
}

export function UpdatesAvailable(props: {
  siteId: string;
  onCancel: () => void;
  onRun?: () => void;
}) {
  const [runUpdates, runningUpdates] =
    useMutation<SiteFirmwareUpdateDialogueMutation>(runUpdatesMutation);
  const [confirmingDismiss, setConfirmingDismiss] = React.useState(false);

  return (
    <>
      <Title>
        <TitleIcon name="radial_alert" />
        <TitleText>Update Available</TitleText>
      </Title>
      <p>
        A new update is available for X1s on this site. Some features may not be
        available until all X1s are updated. Would you like to update now?
      </p>
      <Flex alignItems="center" justifyContent="flex-end">
        <ButtonContainer>
          <Button
            design="default"
            disabled={runningUpdates}
            onClick={() => {
              setConfirmingDismiss(true);
            }}
          >
            No
          </Button>
          {confirmingDismiss && (
            <ConfirmDismissPopover
              onConfirm={props.onCancel}
              onCancel={() => {
                setConfirmingDismiss(false);
              }}
            />
          )}
        </ButtonContainer>
        <Button
          design="primary"
          disabled={runningUpdates}
          onClick={() => {
            runUpdates({
              variables: {
                siteId: props.siteId,
              },
              onCompleted: props.onRun,
            });
          }}
        >
          Yes
        </Button>
      </Flex>
    </>
  );
}

export function UpdatesFailed(props: {
  siteId: string;
  onCancel: () => void;
  onRun?: () => void;
}) {
  const [runUpdates, runningUpdates] =
    useMutation<SiteFirmwareUpdateDialogueMutation>(runUpdatesMutation);
  const [confirmingDismiss, setConfirmingDismiss] = React.useState(false);

  return (
    <>
      <Title color="var(--color-danger-600)">
        <TitleIcon name="radial_alert" />
        <TitleText>Updates Failed</TitleText>
      </Title>
      <p>
        One or more X1s on this site failed to update. Would you like to retry
        now?
      </p>
      <Flex alignItems="center" justifyContent="flex-end">
        <ButtonContainer>
          <Button
            design="default"
            disabled={runningUpdates}
            onClick={() => {
              setConfirmingDismiss(true);
            }}
          >
            Ignore
          </Button>
          {confirmingDismiss && (
            <ConfirmDismissPopover
              onConfirm={props.onCancel}
              onCancel={() => {
                setConfirmingDismiss(false);
              }}
            />
          )}
        </ButtonContainer>
        <Button
          design="primary"
          disabled={runningUpdates}
          onClick={() => {
            runUpdates({
              variables: {
                siteId: props.siteId,
              },
              onCompleted: props.onRun,
            });
          }}
        >
          Retry
        </Button>
      </Flex>
    </>
  );
}

export function UpdatesSucceeded(props: {
  siteId: string;
  onDismiss: () => void;
}) {
  return (
    <>
      <Title color="var(--color-success-400)">
        <TitleIcon name="radial_check" />
        <TitleText>Updates Complete</TitleText>
      </Title>
      <p>Your X1s are now up to date.</p>
      <Flex alignItems="center" justifyContent="flex-end">
        <Button design="default" onClick={props.onDismiss}>
          Dismiss
        </Button>
      </Flex>
    </>
  );
}

function UpdatesInProgress(props: {
  site: SiteFirmwareUpdateDialogue_site$data;
}) {
  const [data, refetch] = useRefetchableFragment<
    SiteFirmwareUpdateDialogue_UpdateProgressQuery,
    SiteFirmwareUpdateDialogue_UpdateProgress_site$key
  >(
    graphql`
      fragment SiteFirmwareUpdateDialogue_UpdateProgress_site on Site
      @refetchable(
        queryName: "SiteFirmwareUpdateDialogue_UpdateProgressQuery"
      ) {
        id
        controlSystems {
          firmwareUpdateProgress
          firmwareStatus
        }
      }
    `,
    props.site
  );

  useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (
      data.controlSystems.some(
        ({ firmwareUpdateProgress }) => firmwareUpdateProgress !== null
      )
    ) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetch({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 15000));
        }, 15000)
      );
    }
    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [data, refetch]);

  return (
    <Container className="box">
      <FirmwareUpdateProgress
        percentage={Math.round(
          average(
            ...props.site.controlSystems
              .map(({ firmwareUpdateProgress }) => firmwareUpdateProgress)
              .filter(isNotNullOrUndefined)
          )
        )}
      />
    </Container>
  );
}

function ConfirmDismissPopover(props: {
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <HorizontalPopover
      title="Ignore Updates"
      buttons={
        <>
          <Button design="default" onClick={props.onCancel}>
            No
          </Button>
          <Button design="primary" onClick={props.onConfirm}>
            Yes
          </Button>
        </>
      }
      style={{ width: "260px" }}
      onDismiss={props.onCancel}
    >
      Are you sure you want to continue without updating?
    </HorizontalPopover>
  );
}

const runUpdatesMutation = graphql`
  mutation SiteFirmwareUpdateDialogueMutation($siteId: ID!) {
    updateSiteFirmwares(siteId: $siteId) {
      ... on UpdateSiteFirmwaresErrorPayload {
        error {
          type
        }
      }
      ... on UpdateSiteFirmwaresSuccessPayload {
        site {
          ...SiteFirmwareUpdateDialogue_site
        }
      }
    }
  }
`;
