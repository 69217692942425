/**
 * App.service.ClientEventsService
 *
 * Service for the client events data. Initially for determining if an initial connection has been completed
 * for the panel attached to the ControlSystemsService. May be expanded to cover other client events in the
 * future.
 */
App.service("ClientEventsService", [
  "$q",
  "ClientEventsAPI",
  function ($q, ClientEventsAPI) {
    // Initial Connection functions for this service
    this.initialConnection = {
      getEvents: getEvents,
      hasBeenEstablished: hasBeenEstablished,
    };

    this.changeConnectionInfo = {
      getChangeConnectionInfoEvents: getChangeConnectionInfoEvents,
    };

    // Properties for managing initial connection data.
    var initialConnectionData = {
      hasBeenEstablished: false,
    };

    /**
     * Called by the ControlSystemsService when a control system is retrieved. If the panel has changed, the locally
     * cached panel properties are updated and the initial connection data is retrieved.
     * @param {string|int} panelId
     * @returns {*} - promise
     */
    function getEvents(panelId) {
      var deferred = $q.defer();
      if (panelId === null) {
        // Panel is new
        initialConnectionData.hasBeenEstablished = false;
        deferred.resolve();
      } else {
        // Retrieve the status from the API
        ClientEventsAPI.getInitialConnectionSucceeded(
          { dataId: panelId },
          function (data) {
            initialConnectionData.hasBeenEstablished = data.hasBeenEstablished;
            deferred.resolve();
          },
          function (error) {
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    }

    /**
     * If the panel has changed, cached panel properties are updated and the change connection info data is retrieved.
     * @param {string|int} panelId
     * @returns {*} - promise
     */
    function getChangeConnectionInfoEvents(panelId) {
      var deferred = $q.defer();
      if (panelId === null) {
        // Panel is new
        deferred.resolve();
      } else {
        // Retrieve the status from the API
        ClientEventsAPI.getChangeConnectionInfoSucceeded(
          { dataId: panelId },
          function (data) {
            deferred.resolve();
          },
          function (error) {
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    }

    /**
     * Accessor for initialConnection hasBeenEstablished
     * @returns {boolean}
     */
    function hasBeenEstablished() {
      return initialConnectionData.hasBeenEstablished;
    }
  },
]);
