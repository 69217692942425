import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationFireExitReleaseField_deviceInformation$key } from "./__generated__/DeviceInformationFireExitReleaseField_deviceInformation.graphql";

export const deviceInformationFireExitReleaseFieldId = (number: string) =>
  `device-information-fire-exit-release-${number}`;

function DeviceInformationFireExitReleaseField() {
  const [{ number, fireExit, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationFireExitReleaseField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationFireExitReleaseField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            fireExit
            deviceType
          }
          ... on Xt75DeviceInformation {
            fireExit
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationFireExitReleaseFieldId(String(number));
  const label = "Fire Exit Release";
  const disabled = deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Fire Exit allows the door access relay to grant egress whenever Fire panic keys are pressed or a Fire / Fire Verify alarm is in the Status List."
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={fireExit}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!fireExit, "fireExit");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationFireExitReleaseField;
