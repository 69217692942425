/**
 * @name App.directive:daZipCodeAlert
 * @desc This directive creates a pop-up 'no weather zip in system options' for schedules and allows for programming the zip.
 * @property panel: The panel object
 * @property busy: The busy indicator for the form. Set while saving the Zip Code.
 *
 * @example <da-zip-code-alert panel="Panel" busy="isBusy"></da-zip-code-alert>
 */

App.directive("daZipCodeAlert", daZipCodeAlert);
daZipCodeAlert.$inject = ["$rootScope"];
function daZipCodeAlert($rootScope) {
  return {
    restrict: "E",

    scope: {
      panel: "=",
      busy: "=",
    },

    templateUrl: "/app/common/templates/zip-code-alert.html",

    link: function (scope) {
      scope.data = [];
      scope.data.tempZip = "";
      scope.data.tempIso = "";

      scope.saveZip = function () {
        scope.busy = true;

        // determine if panel is international before setting zip
        scope.panel.panel_version <= 599
          ? (scope.panel.system_options.weather = scope.data.tempZip)
          : (scope.panel.system_options.weather_in = scope.data.tempZip);
        scope.panel.system_options.cntry_code = scope.data.tempIso;

        scope.panel
          .sendProgramming("system_options")
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Weather Zip Code saved.",
              });
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error saving Weather Zip Code to the system.",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          })
          .finally(function () {
            scope.busy = false;
          });
      };
    },
  };
}
