/**
 * @generated SignedSource<<aaaf42d49bca4c65134ac17ab13273bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VideoDeviceStatus = "ACTIVATING" | "ACTIVE" | "ERROR" | "INACCESSIBLE";
import { FragmentRefs } from "relay-runtime";
export type SecureComNvrs_secureComNvr$data = {
  readonly channels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly id: string;
  readonly name: string;
  readonly status: VideoDeviceStatus;
  readonly " $fragmentType": "SecureComNvrs_secureComNvr";
};
export type SecureComNvrs_secureComNvr$key = {
  readonly " $data"?: SecureComNvrs_secureComNvr$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComNvrs_secureComNvr">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecureComNvrs_secureComNvr",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SecureComNvrVideoChannel",
      "kind": "LinkedField",
      "name": "channels",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SecureComNvr",
  "abstractKey": null
};
})();

(node as any).hash = "97b16a40093dcdb7ada67e20c4a7c6cd";

export default node;
