import graphql from "babel-plugin-relay/macro";
import { getSecureComUrlCreators } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import { urlIsValid } from "common/utils/universal/url";
import { SmallButton } from "components/Button";
import { Flex, Form } from "components/DaStyledElements";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useState } from "react";
import {
  commitLocalUpdate,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import {
  DownloadableFileType,
  fromDownloadableFileId,
  ID,
} from "securecom-graphql/client";
import { EditFileModalDownloadableFile_downloadableFile$key } from "./__generated__/EditFileModalDownloadableFile_downloadableFile.graphql";

const EditFileModal: React.FC<
  React.PropsWithChildren<{
    authToken: string;
    downloadableFileKey: EditFileModalDownloadableFile_downloadableFile$key;
    closeModal: () => void;
  }>
> = ({ authToken, downloadableFileKey, closeModal }) => {
  const downloadableFile = useFragment(
    graphql`
      fragment EditFileModalDownloadableFile_downloadableFile on DownloadableFile {
        id
        name
        isPublic
        infoUrl
        createdAt
        fileName
      }
    `,
    downloadableFileKey
  );

  const [title, setTitle] = useState(downloadableFile.name ?? "");
  const [link, setLink] = useState(downloadableFile.infoUrl ?? "");
  const [isPublic, setIsPublic] = useState(downloadableFile.isPublic ?? true);
  const [titleVisited, setTitleVisited] = useState(false);
  const [linkFieldVisited, setLinkFieldVisited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const showAlert = useShowAlert();

  const env = (process.env?.REACT_APP_SECURECOM_ENV ??
    "production") as SecureComEnv;

  const urls = getSecureComUrlCreators({ env });

  const relayEnv = useRelayEnvironment();
  return (
    <Modal size="medium">
      <Modal.Header>
        <span className="h3">Firmware Upload Settings</span>
        <hr className="mar-t-0 mar-b-0" />
      </Modal.Header>
      <Modal.Body>
        <form>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label htmlFor="title">
                  Title (Version Number & Date)
                </Form.Label>
                <input
                  id="title"
                  className="form-control"
                  type="text"
                  required
                  maxLength={256}
                  value={title}
                  onBlur={() => {
                    setTitleVisited(true);
                  }}
                  onChange={(event) => setTitle(event.target.value)}
                />
                {!title && titleVisited && (
                  <div className="error-container">
                    <div
                      className="has-error"
                      style={{ color: "var(--color-danger-500)" }}
                    >
                      A title is required.
                    </div>
                  </div>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label> Link to release notes</Form.Label>
                <input
                  className="form-control"
                  required
                  type="text"
                  onBlur={() => setLinkFieldVisited(true)}
                  value={link}
                  pattern="[A-Za-z]{3}"
                  onChange={(event) => setLink(event.target.value)}
                />
                {!urlIsValid(link) && linkFieldVisited && (
                  <div className="error-container">
                    <div
                      className="has-error"
                      style={{ color: "var(--color-danger-500)" }}
                    >
                      Please enter a valid link (example: https://dmp.com).
                    </div>
                  </div>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label>Firmware Upload</Form.Label>
                <input
                  className="form-control"
                  required
                  type="text"
                  value={downloadableFile.fileName}
                  disabled={true}
                />
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <div className="checkbox c-checkbox checkbox-inLine needsclick">
              <label className="needsclick">
                <input
                  type="checkbox"
                  className="needsclick"
                  checked={isPublic}
                  onChange={(event) => setIsPublic(event.target.checked)}
                />
                <span className="icon-checkmark"></span>Public
              </label>
            </div>
          </Flex.Row>
          <Flex.Row justify={"flex-end"}>
            <SmallButton
              style={{ marginRight: "0.8rem" }}
              type="reset"
              text="Cancel"
              onClick={closeModal}
            />
            <SmallButton
              type="primary"
              text={isSaving ? "Saving" : "Save"}
              disabled={!title || !urlIsValid(link) || isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  const formData = new FormData();
                  formData.append("title", title);
                  formData.append(
                    "guid",
                    fromDownloadableFileId(downloadableFile.id as unknown as ID)
                      .downloadableFileId
                  );
                  formData.append("link", link);
                  formData.append("file_name", downloadableFile.fileName);
                  formData.append("public", isPublic.toString());
                  formData.append("type", "remote-link");
                  const data: {
                    created_at: string;
                    guid: string;
                    link: string;
                    public: boolean;
                    title: string;
                    type: string;
                    url: string;
                    file_name: string;
                  } = await fetch(urls.files(`/api/v1/files/update`), {
                    method: "PUT",
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    body: formData, // body data type must match "Content-Type" header
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  })
                    .then((response) => response.json())
                    .catch((error) => {
                      throw error;
                    });
                  commitLocalUpdate(relayEnv, (store) => {
                    const downloadableFileFromStore = store.get(
                      downloadableFile.id
                    );
                    if (downloadableFileFromStore) {
                      downloadableFileFromStore.setValue(
                        data.created_at,
                        "createdAt"
                      );
                      downloadableFileFromStore.setValue(
                        data.public,
                        "isPublic"
                      );
                      downloadableFileFromStore.setValue(data.title, "name");
                      downloadableFileFromStore.setValue(
                        DownloadableFileType.REMOTE_LINK,
                        "type"
                      );
                      downloadableFileFromStore.setValue(
                        data.url,
                        "downloadUrl"
                      );
                      downloadableFileFromStore.setValue(data.link, "infoUrl");
                      downloadableFileFromStore.setValue(
                        data.file_name,
                        "fileName"
                      );
                    }
                  });
                  showAlert({
                    type: "success",
                    text: "Updated file: " + data.title,
                  });
                } catch {
                  showAlert({
                    type: "error",
                    text: "Unable to update file: " + downloadableFile.name,
                  });
                }
                setIsSaving(false);
                closeModal();
              }}
            />
          </Flex.Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default EditFileModal;
