/**
 * @generated SignedSource<<923ea2d73fe782f6707c1291881ec552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HikvisionNvrsForm_site$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HikvisionNvrs_site">;
  readonly " $fragmentType": "HikvisionNvrsForm_site";
};
export type HikvisionNvrsForm_site$key = {
  readonly " $data"?: HikvisionNvrsForm_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"HikvisionNvrsForm_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HikvisionNvrsForm_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HikvisionNvrs_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "3e6d3c8b4bec1b56d19221e02b519e3e";

export default node;
