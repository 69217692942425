import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { asID, fromKeyfobId } from "securecom-graphql/client";
import { useControlSystem } from "../ControlSystemContext";
import {
  useHardwareModel,
  useKeyfobNumberRange,
  usePanelFragment,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { keyfobButtonActionFieldId } from "./KeyfobButtonActionField";
import { keyfobButtonAreasFieldId } from "./KeyfobButtonAreasField";
import { keyfobButtonOutputActionFieldId } from "./KeyfobButtonOutputActionField";
import { keyfobButtonOutputFieldId } from "./KeyfobButtonOutputField";
import { keyfobButtonPressTimeFieldId } from "./KeyfobButtonPressTimeField";
import { useKeyfobFragment } from "./KeyfobContext";
import { keyfobNumberOfButtonsFieldId } from "./KeyfobNumberOfButtonsField";
import { keyfobSupervisionTimeFieldId } from "./KeyfobSupervisionTimeField";
import { keyfobUserNumberFieldId } from "./KeyfobUserNumberField";
import { KeyfobNumberField_keyfob$key } from "./__generated__/KeyfobNumberField_keyfob.graphql";
import { KeyfobNumberField_panel$key } from "./__generated__/KeyfobNumberField_panel.graphql";

export const keyfobListItemTemplateId = (number: string) => `keyfob-${number}`;
export const KEY_FOB_NUMBER = "key-fob-number-";
export const keyfobNumberFieldId = (number: string) => KEY_FOB_NUMBER + number;
export const KEYFOB_IDS = [
  keyfobListItemTemplateId,
  keyfobUserNumberFieldId,
  keyfobSupervisionTimeFieldId,
  keyfobNumberOfButtonsFieldId,
  keyfobButtonActionFieldId("One"),
  keyfobButtonActionFieldId("Two"),
  keyfobButtonActionFieldId("Three"),
  keyfobButtonActionFieldId("Four"),
  keyfobButtonAreasFieldId("One"),
  keyfobButtonAreasFieldId("Two"),
  keyfobButtonAreasFieldId("Three"),
  keyfobButtonAreasFieldId("Four"),
  keyfobButtonOutputActionFieldId("One"),
  keyfobButtonOutputActionFieldId("Two"),
  keyfobButtonOutputActionFieldId("Three"),
  keyfobButtonOutputActionFieldId("Four"),
  keyfobButtonOutputFieldId("One"),
  keyfobButtonOutputFieldId("Two"),
  keyfobButtonOutputFieldId("Three"),
  keyfobButtonOutputFieldId("Four"),
  keyfobButtonPressTimeFieldId("One"),
  keyfobButtonPressTimeFieldId("Two"),
  keyfobButtonPressTimeFieldId("Three"),
  keyfobButtonPressTimeFieldId("Four"),
];

function KeyfobNumberField() {
  const [{ id, number, isNew }, updateKeyfob] =
    useKeyfobFragment<KeyfobNumberField_keyfob$key>(
      graphql`
        fragment KeyfobNumberField_keyfob on Keyfob {
          id
          number
          isNew
        }
      `
    );

  const [{ keyfobs }] = usePanelFragment<KeyfobNumberField_panel$key>(graphql`
    fragment KeyfobNumberField_panel on Panel {
      keyfobs {
        number
      }
    }
  `);

  const duplicateKeyfobsExist =
    keyfobs.filter(
      ({ number: keyfobNumber }) => Number(keyfobNumber) === Number(number)
    ).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      KEYFOB_IDS,
      duplicateKeyfobsExist,
      String(number),
      keyfobListItemTemplateId
    );

  const fieldId = keyfobNumberFieldId(`${fromKeyfobId(asID(id)).number}`);
  const keyfobNumberRange = useKeyfobNumberRange();
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();
  const disabled = !isNew;
  const { isXr, isXtlPlus, isXt75 } = resolvePanelType(useHardwareModel());
  const inlineHelp =
    isXr || isXt75 ? "400-449" : isXtlPlus ? "51-54 & 61-64" : "31-34 & 41-44";

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Key Fob Number">
      <NumericInput
        id={fieldId}
        value={number}
        required
        disabled={disabled}
        inlineHelp={inlineHelp}
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(emptyStringOrNumber(target.value), "number");
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate zone/output/keyfob numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = number;
          relayEnv.commitUpdate((store) => {
            const controlSystemRecord = store.get(controlSystem.id);
            const isXr = controlSystemRecord?.getValue("isXr");
            const currentKeyfob = store.get(id);
            if (currentKeyfob) {
              const panelRecord = controlSystemRecord?.getLinkedRecord("panel");
              const keyfobRecords = panelRecord?.getLinkedRecords("keyfobs");

              const outputInformationConflicts = !isXr
                ? panelRecord
                    ?.getLinkedRecords("outputInformations")
                    ?.map((outputs) =>
                      [
                        31, 32, 33, 34, 41, 42, 43, 44, 51, 52, 53, 54, 61, 62,
                        63, 64,
                      ].includes(Number(outputs.getValue("number")))
                        ? outputs.getValue("number")?.toString()
                        : "0"
                    )
                : [];

              const zoneConflicts = !isXr
                ? panelRecord
                    ?.getLinkedRecords("zoneInformations")
                    ?.map((zones) =>
                      [
                        31, 32, 33, 34, 41, 42, 43, 44, 51, 52, 53, 54, 61, 62,
                        63, 64,
                      ].includes(Number(zones.getValue("number")))
                        ? zones.getValue("number")?.toString()
                        : "0"
                    )
                : [];

              if (keyfobRecords) {
                const takenNumbers = new Set(
                  keyfobRecords
                    .map((keyfobRecord) =>
                      keyfobRecord.getValue("id") !== id
                        ? keyfobRecord.getValue("number")?.toString()
                        : "0"
                    )
                    .concat(outputInformationConflicts)
                    .concat(zoneConflicts)
                );

                const valueAsNumber = Number(target.value);
                const value = closest(
                  !target.value || isNaN(valueAsNumber) ? 1 : valueAsNumber,
                  keyfobNumberRange.filter(
                    (num) => !takenNumbers.has(num.toString())
                  )
                );
                currentKeyfob.setValue(value, "number");
                blurredNumber = value;
              }
            }
          });
          onBlurNumberUpdate(String(blurredNumber));
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobNumberField;
