/**
 * @generated SignedSource<<bfde5fde7859a3e4a2519fd16e7e5943>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LockoutCodeField_lockoutCode$data = {
  readonly code: string;
  readonly " $fragmentType": "LockoutCodeField_lockoutCode";
};
export type LockoutCodeField_lockoutCode$key = {
  readonly " $data"?: LockoutCodeField_lockoutCode$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockoutCodeField_lockoutCode">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockoutCodeField_lockoutCode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "type": "LockoutCode",
  "abstractKey": null
};

(node as any).hash = "692f0060ae09ec2e137dfe8f4af0b11f";

export default node;
