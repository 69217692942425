/**
 * Company Store API
 */

App.factory("CompanyStoreAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.companyStoreApiUrl +
        "/api/v1/dealers/:dealer_id/:url_suffix/:item_id/:url_suffix_2",
      {},
      {
        showDealer: { method: "GET", params: { dealer_id: "@dealer_id" } },
        updateDealer: { method: "PUT", params: { dealer_id: "@dealer_id" } },

        showDealerItems: {
          method: "GET",
          params: { dealer_id: "@dealer_id", url_suffix: "items" },
        },
        updateItem: {
          method: "PUT",
          params: { dealer_id: "@dealer_id", url_suffix: "items" },
        },

        createContract: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "monitoring_contracts",
          },
        },
        indexContracts: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "monitoring_contracts",
            all: true,
          },
        }, //return all plans
        showContract: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            item_id: "@item_id",
            url_suffix: "monitoring_contracts",
          },
        }, //return one plans
        updateContract: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            item_id: "@item_id",
            url_suffix: "monitoring_contracts",
          },
        },

        createAgreement: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            item_id: "@item_id",
            url_suffix: "monitoring_contracts",
            url_suffix_2: "contract",
          },
        },
        showAgreement: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            item_id: "@item_id",
            url_suffix: "monitoring_contracts",
            url_suffix_2: "contract",
          },
        },

        createInstallCenter: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "installation_centers",
          },
        },
        destroyInstallCenter: {
          method: "DELETE",
          params: {
            dealer_id: "@dealer_id",
            item_id: "@item_id",
            url_suffix: "installation_centers",
          },
        },
        indexInstallCenters: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "installation_centers",
          },
        },
        showInstallCenter: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            item_id: "@item_id",
            url_suffix: "installation_centers",
          },
        },
        updateInstallCenter: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            item_id: "@item_id",
            url_suffix: "installation_centers",
          },
        },

        //Sales app APIs
        showSalesAppItems: {
          method: "GET",
          params: { dealer_id: "@dealer_id", url_suffix: "sales_app_items" },
        },
        updateSalesAppItems: {
          method: "PUT",
          params: { dealer_id: "@dealer_id", url_suffix: "sales_app_items" },
        },

        createCustomItem: {
          method: "POST",
          params: { dealer_id: "@dealer_id", url_suffix: "custom_items" },
        },
        indexCustomItems: {
          method: "GET",
          params: { dealer_id: "@dealer_id", url_suffix: "custom_items" },
        },
        updateCustomItem: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "custom_items",
            item_id: "@item_id",
          },
        },
        deleteCustomItem: {
          method: "DELETE",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "custom_items",
            item_id: "@item_id",
          },
        },

        createSalesAppContract: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "sales_app_monitoring_contracts",
          },
        },
        indexSalesAppContracts: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "sales_app_monitoring_contracts",
          },
        },
        showSalesAppContract: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "sales_app_monitoring_contracts",
            item_id: "@item_id",
          },
        },
        updateSalesAppContract: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            url_suffix: "sales_app_monitoring_contracts",
            item_id: "@item_id",
          },
        },
      }
    );
  },
]);
