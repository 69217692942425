/**
 * @generated SignedSource<<afd35b20472cea196e83c5adb7cdbc25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SendLocalChangesType = "DIGITAL_DIALER" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsRemoteChangePortField_remoteOptions$data = {
  readonly remoteChangesPort?: string | null;
  readonly sendLocalChanges?: SendLocalChangesType | null;
  readonly " $fragmentType": "RemoteOptionsRemoteChangePortField_remoteOptions";
};
export type RemoteOptionsRemoteChangePortField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsRemoteChangePortField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsRemoteChangePortField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsRemoteChangePortField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remoteChangesPort",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sendLocalChanges",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "98f9dec1781d92ad6fa55cd27583b555";

export default node;
