App.service("PanelDefRuleService", function () {
  /**
   * Get a list of rules for the given panel / concept(s) with 2 groups: reference fields and target fields.
   * Each of the groups is organized by rule type: default, override_field, hide, default
   * @param panel
   * @param concepts
   * @returns {object}
   */
  this.getRules = function (panel, concepts) {
    var definition = {};
    var allRules = [];
    if (!angular.isArray(concepts)) {
      concepts = [concepts];
    }
    angular.forEach(concepts, function (concept) {
      definition = getDefinition(panel, concept.key);
      allRules = allRules.concat(getDefinitionRules(definition));
    });
    return groupRules(allRules);
  };

  /**
   * Processes a group of rules, one-at-a-time. Rules are validated to see if they can be processed, then they are applied
   * if valid. In the case of HIDE / DISABLE, all rules that may hide or disable the target field are checked. If any is
   * valid, the field is hidden / disabled.
   * @param rules
   * @param panel
   * @param entityNumber item number
   */
  this.processRules = function (rules, panel, entityNumber) {
    if (angular.isDefined(entityNumber && angular.isString(entityNumber))) {
      entityNumber = +entityNumber;
    }
    angular.forEach(rules, function (rule) {
      var rulePrefix =
        panel.PDS.panel_def.CONCEPTS[rule.TARGET_CONCEPT].DISPLAY.NAME +
        " (" +
        rule.TYPE +
        ") rule. Watch: " +
        rule.REF_CONCEPT +
        "-" +
        rule.REF_FIELD +
        " | target: " +
        rule.TARGET_CONCEPT +
        "-" +
        rule.TARGET_FIELD +
        " | ";
      var referenceEntity = rule.hasOwnProperty("MULTI_REFERENCE")
        ? null
        : getEntity("REF_CONCEPT", rule, panel, entityNumber);
      var targetEntity = getEntity("TARGET_CONCEPT", rule, panel, entityNumber);
      if (
        angular.isDefined(referenceEntity) &&
        rule.hasOwnProperty("TARGET_CONCEPT") &&
        panel.hasOwnProperty(rule.TARGET_CONCEPT)
      ) {
        var ruleProcessedOnNumber = false;
        switch (rule.TYPE) {
          case "DEFAULT":
            if (
              panel[rule.TARGET_CONCEPT].isArray &&
              (angular.isUndefined(targetEntity) ||
                (angular.isArray(targetEntity) && targetEntity.length === 0))
            ) {
              break;
            }
            if (!angular.isArray(targetEntity)) {
              var defaultArray = [];
              defaultArray.push(targetEntity);
              targetEntity = defaultArray;
            }
            angular.forEach(targetEntity, function (targetEntity) {
              var referenceEntity = getEntity(
                "REF_CONCEPT",
                rule,
                panel,
                +targetEntity.number
              );
              var referenceValue = getReferenceFieldValue(
                rule,
                panel,
                referenceEntity
              );
              var validDefault = validateSingleRule(
                panel,
                referenceEntity,
                targetEntity,
                rule,
                referenceValue,
                rulePrefix
              );
              if (
                validDefault.valid === true &&
                conditionsMet(
                  panel,
                  referenceEntity,
                  rule,
                  +targetEntity.number,
                  rulePrefix
                )
              ) {
                switch (rule.OPERATOR) {
                  case "==":
                    if (validDefault.isRangeRule) {
                      var defaultEqualsRuleKeys = Object.keys(
                        rule.REFERENCE_VALUES_MAP
                      );
                      if (defaultEqualsRuleKeys.length === 1) {
                        targetEntity[rule.TARGET_FIELD] =
                          rule.REFERENCE_VALUES_MAP[defaultEqualsRuleKeys[0]];
                        if (rule.TARGET_FIELD === "number")
                          ruleProcessedOnNumber = true;
                      } else
                        console.warn(
                          rulePrefix +
                            " " +
                            defaultEqualsRuleKeys.length +
                            " values found in the REFERENCE_VALUES_MAP. For DEFAULT rules where the key is a range, only 1 can be used."
                        );
                    } else {
                      targetEntity[rule.TARGET_FIELD] =
                        rule.REFERENCE_VALUES_MAP[referenceValue];
                    }
                    break;
                  case "!=":
                    var defaultNotEqualsRuleKeys = Object.keys(
                      rule.REFERENCE_VALUES_MAP
                    );
                    if (defaultNotEqualsRuleKeys.length === 1) {
                      targetEntity[rule.TARGET_FIELD] =
                        rule.REFERENCE_VALUES_MAP[defaultNotEqualsRuleKeys[0]];
                      if (rule.TARGET_FIELD === "number")
                        ruleProcessedOnNumber = true;
                    } else
                      console.warn(
                        rulePrefix +
                          " " +
                          defaultNotEqualsRuleKeys.length +
                          " values found in the REFERENCE_VALUES_MAP. For DEFAULT not equal rules, only 1 can be used."
                      );
                    break;
                  case "IN_PATTERN":
                  case "NOT_IN_PATTERN":
                    targetEntity[rule.TARGET_FIELD] =
                      rule.REFERENCE_PATTERN_TARGET_VALUE.value;
                    break;
                  default:
                    console.warn(
                      "DEFAULT rules do not support the operator: " +
                        rule.OPERATOR
                    );
                    break;
                }
              }
            });
            break;
          case "OVERRIDE_FIELD":
            if (angular.isArray(referenceEntity)) {
              if (referenceEntity.length > 0) {
                referenceEntity = referenceEntity[0];
              } else {
                break;
              }
            }
            var referenceValue = getReferenceFieldValue(
              rule,
              panel,
              referenceEntity
            );
            if (
              validateSingleRule(
                panel,
                referenceEntity,
                targetEntity,
                rule,
                referenceValue,
                rulePrefix
              ).valid &&
              conditionsMet(
                panel,
                referenceEntity,
                rule,
                +referenceEntity.number,
                rulePrefix
              )
            ) {
              var keys = Object.keys(rule.REFERENCE_VALUES_MAP);
              var eqRuleKey = "";
              var isEqRule = rule.OPERATOR === "==";
              if (isEqRule) {
                for (var kIdx = 0; kIdx < keys.length; kIdx++) {
                  var keyProperties = keys[kIdx].split("|");
                  for (var vIdx = 0; vIdx < keyProperties.length; vIdx++) {
                    if (referenceValue === keyProperties[vIdx]) {
                      eqRuleKey = keys[kIdx];
                      break;
                    }
                  }
                  if (eqRuleKey !== "") break;
                }
              }
              // Replace the field's DISPLAY attribute with that of the rule
              panel.PDS.panel_def.CONCEPTS[rule.TARGET_CONCEPT][
                rule.TARGET_FIELD
              ].DISPLAY = isEqRule
                ? rule.REFERENCE_VALUES_MAP[eqRuleKey].DISPLAY
                : rule.REFERENCE_VALUES_MAP[keys[0]].DISPLAY;
              // Replace the field's VALUES attribute with that of the rule

              panel.PDS.panel_def.CONCEPTS[rule.TARGET_CONCEPT][
                rule.TARGET_FIELD
              ].VALUES = isEqRule
                ? rule.REFERENCE_VALUES_MAP[eqRuleKey].VALUES
                : rule.REFERENCE_VALUES_MAP[keys[0]].VALUES;

              if (rule.TARGET_FIELD === "number") ruleProcessedOnNumber = true;
            }
            break;
          case "HIDE":
            if (
              panel[rule.TARGET_CONCEPT].isArray &&
              (angular.isUndefined(targetEntity) ||
                (angular.isArray(targetEntity) && targetEntity.length === 0))
            ) {
              break;
            }
            var hideTargetRules =
              panel.session.panelDefRules.targetGroups[rule.TARGET_CONCEPT][
                rule.TARGET_FIELD
              ].hideRules;

            if (!angular.isArray(targetEntity)) {
              var hideArray = [];
              hideArray.push(targetEntity);
              targetEntity = hideArray;
            }
            angular.forEach(targetEntity, function (targetEntity) {
              referenceEntity = getEntity(
                "REF_CONCEPT",
                rule,
                panel,
                +targetEntity.number
              );
              var anyHideRuleTrue = anyRuleValid(
                panel,
                referenceEntity,
                hideTargetRules,
                +targetEntity.number,
                rulePrefix
              );
              // If any rule says to hide the field, hide it
              if (targetEntity.hasOwnProperty("number")) {
                panel.initializeSessionData(
                  rule.TARGET_CONCEPT,
                  +targetEntity.number,
                  rule.TARGET_FIELD
                );
                panel.session.CONCEPTS[rule.TARGET_CONCEPT].NUMBERS[
                  +targetEntity.number
                ].FIELDS[rule.TARGET_FIELD]["hiddenByRule"] = anyHideRuleTrue;
              } else {
                panel.initializeSessionData(
                  rule.TARGET_CONCEPT,
                  undefined,
                  rule.TARGET_FIELD
                );
                panel.session.CONCEPTS[rule.TARGET_CONCEPT].FIELDS[
                  rule.TARGET_FIELD
                ]["hiddenByRule"] = anyHideRuleTrue;
              }
              if (anyHideRuleTrue && rule.TARGET_FIELD === "number")
                ruleProcessedOnNumber = true;
            });
            break;
          case "DISABLE":
            if (angular.isArray(referenceEntity)) {
              if (referenceEntity.length > 0) {
                referenceEntity = referenceEntity[0];
              } else {
                break;
              }
            }
            var disableTargetRules =
              panel.session.panelDefRules.targetGroups[rule.TARGET_CONCEPT][
                rule.TARGET_FIELD
              ].disableRules;
            var anyDisableRuleTrue = anyRuleValid(
              panel,
              referenceEntity,
              disableTargetRules,
              entityNumber,
              rulePrefix
            );
            panel.initializeSessionData(
              rule.TARGET_CONCEPT,
              undefined,
              rule.TARGET_FIELD
            );
            // If any rule says to disable the field, disable it
            panel.session.CONCEPTS[rule.TARGET_CONCEPT].FIELDS[
              rule.TARGET_FIELD
            ]["disabledByRule"] = anyDisableRuleTrue;
            if (anyDisableRuleTrue && rule.TARGET_FIELD === "number")
              ruleProcessedOnNumber = true;
            break;
        }
        if (ruleProcessedOnNumber) {
          panel.updateItemsAvailable(rule.TARGET_CONCEPT);
        }
      } else {
        if (rule.hasOwnProperty("TARGET_CONCEPT")) {
          var conceptStatus = panel.hasOwnProperty(rule.TARGET_CONCEPT)
            ? "Panel has property: " + rule.TARGET_CONCEPT
            : "Panel does not have property: " + rule.TARGET_CONCEPT;
        }
      }
    });
  };

  /**
   * Get the panel item (entity) that the rule is referring to
   * @param {string} conceptType
   * @param {object} rule
   * @param {object} panel
   * @param {int} entityNumber
   * @returns {*}
   */
  function getEntity(conceptType, rule, panel, entityNumber) {
    if (angular.isDefined(conceptType) && rule.hasOwnProperty(conceptType)) {
      if (panel.hasOwnProperty(rule[conceptType])) {
        if (panel[rule[conceptType]].isArray) {
          if (angular.isDefined(entityNumber)) {
            return panel[rule[conceptType]].find(function (entity) {
              return +entity.number === entityNumber;
            });
          } else {
            // If an entity number has not been provided, return all of the open items
            return panel[rule[conceptType]].filter(function (entity) {
              return entity.isOpen === true;
            });
          }
        } else {
          return panel[rule[conceptType]];
        }
      }
    }
  }

  /**
   * Function to retrieve panel def style definition for concept or field (if provided)
   * @param panel
   * @param conceptKey
   * @param fieldKey
   * @returns {*}
   */
  function getDefinition(panel, conceptKey, fieldKey) {
    try {
      var definition;
      if (angular.isDefined(fieldKey)) {
        definition = {};
        // Create a display object for logging purposes
        definition["DISPLAY"] =
          panel.PDS.panel_def.CONCEPTS[conceptKey][fieldKey].DISPLAY;
        // Set the field information for rule grouping (if there are any)
        definition[fieldKey] =
          panel.PDS.panel_def.CONCEPTS[conceptKey][fieldKey];
        // Directive is not for a field
      } else {
        definition = panel.PDS.panel_def.CONCEPTS[conceptKey];
      }
      return definition;
    } catch (exception) {
      console.error("getDefinition error: " + angular.toJson(exception));
      return {};
    }
  }

  /**
   * Gets the value of the reference field for a rule
   * @param rule
   * @param panel
   * @param item - zone, output, etc.
   * @returns {*}
   */
  function getReferenceFieldValue(rule, panel, item) {
    var value;
    switch (rule.REF_TYPE) {
      case "ITEM":
        value =
          isUndefinedOrNull(item) || isUndefinedOrNull(item[rule.REF_FIELD])
            ? null
            : item[rule.REF_FIELD];
        break;
      case "CONCEPTS":
        value =
          isUndefinedOrNull(panel[rule.REF_CONCEPT]) ||
          isUndefinedOrNull(panel[rule.REF_CONCEPT][rule.REF_FIELD]) ||
          panel[rule.REF_CONCEPT].isBusy
            ? null
            : panel[rule.REF_CONCEPT][rule.REF_FIELD];
        break;
    }
    return value;
  }

  /**
   * Gathers all rules within a concept definition, adding reference and target values to each rule
   * @param definition
   * @returns {Array}
   */
  function getDefinitionRules(definition) {
    var rules = [];
    // Iterate through all constraints and add all rules to rules array
    angular.forEach(definition, function (field, field_name) {
      if (field.hasOwnProperty("RULES")) {
        angular.forEach(field.RULES, function (rule) {
          var newRule = angular.copy(rule);
          newRule["TARGET_FIELD"] = field_name;
          newRule["TARGET_CONCEPT"] = definition.DISPLAY.key;
          if (newRule.hasOwnProperty("CONDITIONAL_REFERENCES")) {
            angular.forEach(
              newRule.CONDITIONAL_REFERENCES,
              function (condition) {
                if (condition.hasOwnProperty("REFERENCE_FIELD")) {
                  // Add reference information and fields used for validation of condition
                  addReferenceInfo(condition, definition);
                  condition["TYPE"] = rule.TYPE;
                  condition["TARGET_FIELD"] = field_name;
                  condition["TARGET_CONCEPT"] = definition.DISPLAY.key;
                }
              }
            );
          }
          if (newRule.hasOwnProperty("REFERENCE_FIELD")) {
            addReferenceInfo(newRule, definition);
            if (
              angular.isDefined(newRule.REF_FIELD) &&
              angular.isDefined(newRule.REF_CONCEPT)
            ) {
              rules = rules.concat(newRule);
            }
          } else if (newRule.hasOwnProperty("MULTI_REFERENCE")) {
            var allClear = true;
            angular.forEach(newRule.MULTI_REFERENCE, function (mRRule) {
              mRRule["TYPE"] = newRule.TYPE;
              addReferenceInfo(mRRule, definition);
              if (
                angular.isUndefined(mRRule.REF_FIELD) ||
                angular.isUndefined(mRRule.REF_CONCEPT)
              ) {
                allClear = false;
              }
            });
            if (allClear) {
              rules = rules.concat(newRule);
            }
          }
        });
      }
    });
    return rules;
  }

  /**
   * Add properties to the rule describing the location of the reference on the panel object
   * @param {object} rule
   * @param {object} definition
   */
  function addReferenceInfo(rule, definition) {
    if (rule.hasOwnProperty("REFERENCE_FIELD")) {
      var fieldParts = rule.REFERENCE_FIELD.split(".");
      switch (fieldParts[0]) {
        case "ITEM":
          rule["REF_TYPE"] = "ITEM";
          rule["REF_FIELD"] = fieldParts[1];
          rule["REF_CONCEPT"] = definition.DISPLAY.key;
          break;
        case "CONCEPTS":
          rule["REF_TYPE"] = "CONCEPTS";
          rule["REF_FIELD"] = fieldParts[2];
          rule["REF_CONCEPT"] = fieldParts[1];
          break;
        default:
          console.warn("Unrecognized REFERENCE_FIELD: " + fieldParts[0]);
          break;
      }
    }
  }

  /**
   * Groups a list of rules into 2 categories, reference field and target field. Organizes rules withing those groups
   * into by rule type: default, override_field, hide, disable
   * @param rules - An array of panel_def rules
   * @returns {object} An array of rules grouped by 2 properties, the reference value and the targeted field to change
   */
  function groupRules(rules) {
    // Group the default rules bvy REFERENCE_FIELD (the field that will be watched)
    var groupedRules = {};
    groupedRules["referenceGroups"] = {};
    groupedRules["targetGroups"] = {};
    angular.forEach(rules, function (rule) {
      // Add rules to reference groups. These will be used to set up watchers.
      if (rule.hasOwnProperty("REF_CONCEPT")) {
        addRuleToGroup(
          groupedRules.referenceGroups,
          rule,
          rule.REF_CONCEPT,
          rule.REF_FIELD
        );
      }
      // Add the full rule to each multi-reference reference field
      else if (rule.hasOwnProperty("MULTI_REFERENCE")) {
        angular.forEach(rule.MULTI_REFERENCE, function (mRule) {
          addRuleToGroup(
            groupedRules.referenceGroups,
            rule,
            mRule.REF_CONCEPT,
            mRule.REF_FIELD
          );
        });
      }
      // Add the full rule to each conditional reference field
      if (rule.hasOwnProperty("CONDITIONAL_REFERENCES")) {
        angular.forEach(rule.CONDITIONAL_REFERENCES, function (cRule) {
          addRuleToGroup(
            groupedRules.referenceGroups,
            rule,
            cRule.REF_CONCEPT,
            cRule.REF_FIELD
          );
        });
      }

      addRuleToGroup(
        groupedRules.targetGroups,
        rule,
        rule.TARGET_CONCEPT,
        rule.TARGET_FIELD
      );
    });

    return groupedRules;
  }

  /**
   * Place a rule in a rule group
   * @param {object} ruleGroup
   * @param {object} rule
   * @param {string} refConcept
   * @param {string} refField
   */
  function addRuleToGroup(ruleGroup, rule, refConcept, refField) {
    if (angular.isUndefined(ruleGroup[refConcept])) {
      ruleGroup[refConcept] = {};
    }
    // If there's not already a group, add one
    if (angular.isUndefined(ruleGroup[refConcept][refField])) {
      var referenceGroup = {};
      referenceGroup["defaultRules"] = [];
      referenceGroup["overrideFieldRules"] = [];
      referenceGroup["hideRules"] = [];
      referenceGroup["disableRules"] = [];
      categorizeRule(rule, referenceGroup);
      ruleGroup[refConcept][refField] = referenceGroup;
    }
    // Otherwise, add the mRule to the group with the same REFERENCE_FIELD
    else {
      categorizeRule(rule, ruleGroup[refConcept][refField]);
    }
  }

  /**
   * Places a given rule in the appropriate subcategory (default, override_field, etc.) for the given group
   * @param rule
   * @param group
   */
  function categorizeRule(rule, group) {
    switch (rule.TYPE) {
      case "DEFAULT":
        group.defaultRules.push(rule);
        break;
      case "OVERRIDE_FIELD":
        group.overrideFieldRules.push(rule);
        break;
      case "HIDE":
        group.hideRules.push(rule);
        break;
      case "DISABLE":
        group.disableRules.push(rule);
        break;
      default:
        console.warn(
          "categorizeRule function | Unknown rule type: '" +
            rule.TYPE +
            "' | rule: '" +
            rule +
            "'"
        );
        break;
    }
  }

  /**
   * Iterate through an array of rules. Return true if any valid rule is found.
   *
   * @param {object} panel
   * @param {object} referenceEntity
   * @param targetRules - an array of panelDef-type rules
   * @param entityNumber
   * @param rulePrefix - a prefix for comments / debugging purposes
   * @returns {Array} - of boolean values indicating the validity of the provided array of target rules
   */
  function anyRuleValid(
    panel,
    referenceEntity,
    targetRules,
    entityNumber,
    rulePrefix
  ) {
    for (var k = 0; k < targetRules.length; k++) {
      var targetPrefix = "Target rule " + (k + 1) + " | ";
      if (targetRules[k].hasOwnProperty("MULTI_REFERENCE")) {
        var multiResults = [];
        // Build an array of bool values, indicating if each rule of a multi-reference rule is valid
        angular.forEach(targetRules[k].MULTI_REFERENCE, function (rule) {
          var referenceEntity = getEntity(
            "REF_CONCEPT",
            rule,
            panel,
            entityNumber
          );
          var referenceValue = getReferenceFieldValue(
            rule,
            panel,
            referenceEntity
          );
          var targetEntity = getEntity(
            "TARGET_CONCEPT",
            rule,
            panel,
            entityNumber
          );
          multiResults.push(
            validateSingleRule(
              panel,
              referenceEntity,
              targetEntity,
              rule,
              referenceValue,
              rulePrefix
            ).valid
          );
        });
        // Based on the multi-reference operator, compare the results of each part of the multi-ref rule
        if (
          targetRules[k].MULTI_REFERENCE_OPERATOR &&
          targetRules[k].MULTI_REFERENCE_OPERATOR === "AND"
        ) {
          if (
            multiResults.every(function (allTrue) {
              return allTrue;
            })
          ) {
            return true;
          }
        } else {
          // Assume OR
          if (
            multiResults.some(function (anyTrue) {
              return anyTrue;
            })
          ) {
            return true;
          }
        }
      } else {
        referenceEntity = getEntity(
          "REF_CONCEPT",
          targetRules[k],
          panel,
          entityNumber
        );
        var referenceValue = getReferenceFieldValue(
          targetRules[k],
          panel,
          referenceEntity
        );
        var targetEntity = getEntity(
          "TARGET_CONCEPT",
          targetRules[k],
          panel,
          entityNumber
        );
        if (
          validateSingleRule(
            panel,
            referenceEntity,
            targetEntity,
            targetRules[k],
            referenceValue,
            rulePrefix
          ).valid
        ) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * For rules with a CONDITIONAL_REFERENCES property. Validate the conditions.
   * @param {object} panel
   * @param {object} referenceItem - the panel entity to which the rule refers
   * @param {object} rule
   * @param {int} entityNumber - the number of the panel entity
   * @param rulePrefix - a prefix for comments / debugging purposes
   * @returns {boolean} - true if conditions are met
   */
  function conditionsMet(panel, referenceItem, rule, entityNumber, rulePrefix) {
    // All conditional references must be true for rule to be valid
    if (rule.hasOwnProperty("CONDITIONAL_REFERENCES")) {
      var conditions = [];
      angular.forEach(rule.CONDITIONAL_REFERENCES, function (conditionRule) {
        var referenceEntity = getEntity(
          "REF_CONCEPT",
          conditionRule,
          panel,
          entityNumber
        );
        var referenceValue = getReferenceFieldValue(
          conditionRule,
          panel,
          referenceEntity
        );
        var singleRuleResult = newValidationResult();
        switch (conditionRule.TYPE) {
          case "DEFAULT":
          case "OVERRIDE_FIELD":
            switch (conditionRule.OPERATOR) {
              case "==":
              case "!=":
                if (conditionRule.hasOwnProperty("REFERENCE_VALUES_ARRAY")) {
                  checkReferenceValuesArray(
                    conditionRule,
                    referenceValue,
                    rulePrefix,
                    singleRuleResult
                  );
                  conditions.push(singleRuleResult.valid);
                } else {
                  console.warn(
                    rulePrefix +
                      "Condition rule not valid. " +
                      conditionRule.TYPE +
                      " " +
                      conditionRule.OPERATOR +
                      " rules require a REFERENCE_VALUES_ARRAY"
                  );
                }
                break;
            }
            break;
        }
      });
      return conditions.every(function (val) {
        return val;
      });
    } else return true;
  }

  /**
   * Creates a new tuple result for rule validation
   * @returns {{valid: boolean, isRangeRule: boolean}}
   */
  function newValidationResult() {
    return {
      valid: false,
      isRangeRule: false,
    };
  }

  /**
   * Check the value of a single programming Rule.
   *
   * @param panel
   * @param {object} referenceItem: the item the rule references
   * @param {object} targetItem: the item to which the rule will be applied
   * @param {object} rule
   * @param {any} value: The value of the reference field
   * @param {string} rulePrefix - A prefix for the log message
   * @returns {object} with 2 properties: valid = true if the value meets the conditions of the rule; isRangeRule = true if the rule is a range
   */
  function validateSingleRule(
    panel,
    referenceItem,
    targetItem,
    rule,
    value,
    rulePrefix
  ) {
    var singleRuleResult = newValidationResult();

    // This check is necessary to both make sure rules are correctly formatted and to skip before concepts are loaded.
    switch (rule.TYPE) {
      case "DEFAULT":
        if (
          angular.isUndefined(referenceItem) ||
          angular.isUndefined(targetItem[rule.TARGET_FIELD]) ||
          skipIfValid(panel, targetItem, rule, rulePrefix)
        )
          // A default requires reference and target items, return while valid is still false
          return singleRuleResult;
        else {
          // Different types of rules can have different reference type objects based on the operator.
          switch (rule.OPERATOR) {
            case "==":
            case "!=":
              if (rule.hasOwnProperty("REFERENCE_VALUES_MAP")) {
                checkReferenceValuesMap(
                  rule,
                  value,
                  rulePrefix,
                  singleRuleResult
                );
              } else {
                console.warn(
                  rulePrefix +
                    "Unable to process rule. DEFAULT == and != rules require a REFERENCE_VALUES_MAP"
                );
              }
              break;
            case "IN_PATTERN":
            case "NOT_IN_PATTERN":
              if (rule.hasOwnProperty("REFERENCE_PATTERN_TARGET_VALUE")) {
                checkReferencePatternTargetValue(
                  rule,
                  value,
                  rulePrefix,
                  singleRuleResult
                );
              } else {
                console.warn(
                  rulePrefix +
                    "Unable to process rule. DEFAULT IN_PATTERN and NOT_IN_PATTERN rules require a REFERENCE_PATTERN_TARGET_VALUE"
                );
              }
              break;
          }
        }
        break;
      case "OVERRIDE_FIELD":
        if (
          angular.isUndefined(
            panel.PDS.panel_def.CONCEPTS[rule.TARGET_CONCEPT][rule.TARGET_FIELD]
          )
        )
          // If there's no field to replace, there's a problem
          return singleRuleResult;
        else {
          switch (rule.OPERATOR) {
            case "==":
            case "!=":
              if (rule.hasOwnProperty("REFERENCE_VALUES_MAP")) {
                checkReferenceValuesMap(
                  rule,
                  value,
                  rulePrefix,
                  singleRuleResult
                );
              } else {
                console.warn(
                  rulePrefix +
                    "Unable to process rule. OVERRIDE_FIELD == and != rules require a REFERENCE_VALUES_MAP"
                );
              }
              break;
            case "IN_PATTERN":
            case "NOT_IN_PATTERN":
              if (rule.hasOwnProperty("REFERENCE_PATTERN")) {
                checkReferencePattern(
                  rule,
                  value,
                  rulePrefix,
                  singleRuleResult
                );
              } else {
                console.warn(
                  rulePrefix +
                    "Unable to process rule. OVERRIDE_FIELD IN_PATTERN and NOT_IN_PATTERN rules require a REFERENCE_PATTERN"
                );
              }
              break;
          }
        }
        break;
      case "HIDE":
        switch (rule.OPERATOR) {
          case "==":
          case "!=":
            if (rule.hasOwnProperty("REFERENCE_VALUES_ARRAY")) {
              checkReferenceValuesArray(
                rule,
                value,
                rulePrefix,
                singleRuleResult
              );
            } else {
              console.warn(
                rulePrefix +
                  "Unable to process rule. HIDE == and != rules require a REFERENCE_VALUES_ARRAY"
              );
            }
            break;
          case "IN_PATTERN":
          case "NOT_IN_PATTERN":
            if (rule.hasOwnProperty("REFERENCE_PATTERN")) {
              checkReferencePattern(rule, value, rulePrefix, singleRuleResult);
            } else {
              console.warn(
                rulePrefix +
                  "Unable to process rule. HIDE IN_PATTERN and NOT_IN_PATTERN rules require a REFERENCE_PATTERN"
              );
            }
            break;
        }
        break;
      case "DISABLE":
        switch (rule.OPERATOR) {
          case "==":
            if (rule.hasOwnProperty("REFERENCE_VALUES_ARRAY")) {
              checkReferenceValuesArray(
                rule,
                value,
                rulePrefix,
                singleRuleResult
              );
            } else {
              console.warn(
                rulePrefix +
                  "Unable to process rule. DISABLE == rules require a REFERENCE_VALUES_ARRAY"
              );
            }
            break;
          case "!=":
            // TODO: Modify rules so that there is only one type of data field for disable !=
            // note: Because we use angular.merge() to build our panel def, there could be both REFERENCE_VALUES_RANGE
            // and REFERENCE_VALUES_ARRAY properties on the object. This favors the range (as we've always done)
            var ruleHasReferenceValuesRange = rule.hasOwnProperty(
              "REFERENCE_VALUES_RANGE"
            );
            var ruleHasReferenceValuesArray = rule.hasOwnProperty(
              "REFERENCE_VALUES_ARRAY"
            );
            if (ruleHasReferenceValuesRange && ruleHasReferenceValuesArray) {
              console.warn(
                rulePrefix +
                  "Rule has both REFERENCE_VALUES_RANGE and REFERENCE_VALUES_ARRAY. Validating rule based on range only."
              );
            }
            if (ruleHasReferenceValuesRange) {
              checkReferenceValueRange(
                rule,
                value,
                rulePrefix,
                singleRuleResult
              );
            } else if (ruleHasReferenceValuesArray) {
              checkReferenceValuesArray(
                rule,
                value,
                rulePrefix,
                singleRuleResult
              );
            } else {
              console.warn(
                rulePrefix +
                  "Unable to process rule. DISABLE != rules require a REFERENCE_VALUES_RANGE or REFERENCE_VALUES_ARRAY"
              );
            }
            break;
          case "IN_PATTERN":
          case "NOT_IN_PATTERN":
            if (rule.hasOwnProperty("REFERENCE_PATTERN")) {
              checkReferencePattern(rule, value, rulePrefix, singleRuleResult);
            } else {
              console.warn(
                rulePrefix +
                  "Unable to process rule. DISABLE IN_PATTERN and NOT_IN_PATTERN rules require a REFERENCE_PATTERN"
              );
            }
            break;
        }
        break;
      default:
        break;
    }
    return singleRuleResult;
  }

  /**
   * For rules with a REFERENCE_VALUES_MAP property. Validate the rule based on the reference value and target condition.
   * @param {object} rule
   * @param {*} value - the value of the panel entity that is referenced
   * @param {string} rulePrefix - A prefix for the log message
   * @param {object} singleRuleResult - result of the singleRuleValidation
   */
  function checkReferenceValuesMap(rule, value, rulePrefix, singleRuleResult) {
    if (
      rule.hasOwnProperty("REFERENCE_VALUES_MAP") &&
      rule.hasOwnProperty("OPERATOR")
    ) {
      var ruleKeys = Object.keys(rule.REFERENCE_VALUES_MAP);
      switch (rule.OPERATOR) {
        case "==":
        case "!=":
          // If the first key contains a dash after at least 1 character, it might be a range of numbers
          if (ruleKeys[0].indexOf("-") > 0) {
            if (ruleKeys.length === 1) {
              var kRanges = ruleKeys[0].split(",");
              if (kRanges.length === 1) {
                var rValues = getRangeValues(kRanges[0], rulePrefix);
                if (rValues.valid === false) break;
                singleRuleResult.isRangeRule = true;
                var numValue = +value;
                switch (rule.OPERATOR) {
                  case "==":
                    singleRuleResult.valid =
                      numValue >= rValues.low && numValue <= rValues.high;
                    break;
                  case "!=":
                    singleRuleResult.valid =
                      numValue < rValues.low || numValue > rValues.high;
                    break;
                }
              } else {
                console.warn(
                  rulePrefix +
                    "Multiple ranges found; Not supported for rule operator: '" +
                    rule.OPERATOR +
                    "'"
                );
              }
            } else {
              console.warn(
                rulePrefix +
                  " " +
                  ruleKeys.length +
                  " rules found. For ranges, only 1 rule is supported."
              );
            }
          } else {
            var keys = Object.keys(rule.REFERENCE_VALUES_MAP);
            var values = [];
            angular.forEach(keys, function (key) {
              var keyProperties = key.split("|");
              angular.forEach(keyProperties, function (val) {
                if (values.indexOf(val) === -1) {
                  values.push(val);
                }
              });
            });
            switch (rule.OPERATOR) {
              case "==":
                singleRuleResult.valid = values.indexOf(value) > -1;
                break;
              case "!=":
                singleRuleResult.valid = values.indexOf(value) === -1;
                break;
            }
          }
          break;
        case "IN_PATTERN":
          if (ruleKeys.length === 1) {
            var inPatternRegEx = new RegExp("^" + ruleKeys[0] + "$");
            singleRuleResult.valid = inPatternRegEx.test(value);
            break;
          } else {
            console.warn(
              rulePrefix +
                " " +
                ruleKeys.length +
                " rules found. For IN_PATTERN operator, only 1 rule is supported."
            );
          }
          break;
        case "NOT_IN_PATTERN":
          if (ruleKeys.length === 1) {
            var notInPatternRegEx = new RegExp("^" + ruleKeys[0] + "$");
            singleRuleResult.valid = !notInPatternRegEx.test(value);
            break;
          } else {
            console.warn(
              rulePrefix +
                " " +
                ruleKeys.length +
                " rules found. For NOT_IN_PATTERN operator, REFERENCE_VALUES_MAP may only contain 1 value."
            );
          }
          break;
      }
    }
  }

  /**
   * For rules with a REFERENCE_VALUES_RANGE property. Validate the rule based on the reference value and target condition.
   * @param {object} rule
   * @param {*} value - the value of the panel entity that is referenced
   * @param {string} rulePrefix - A prefix for the log message
   * @param {object} singleRuleResult - result of the singleRuleValidation
   */
  function checkReferenceValueRange(rule, value, rulePrefix, singleRuleResult) {
    if (rule.hasOwnProperty("REFERENCE_VALUES_RANGE")) {
      var rangeRules = [];
      var ranges = rule.REFERENCE_VALUES_RANGE.split(",");
      for (var i = 0; i < ranges.length; i++) {
        var rangeValues = getRangeValues(ranges[i], rulePrefix);
        // Check for non-numbers. If any non-numbers are encountered, return true (to disable or hide field).
        if (rangeValues.valid === false) singleRuleResult.valid = true;
        // Check each range value, then put it in rangeRules for later compilation
        if (rule.OPERATOR === "==") {
          rangeRules.push(
            value >= rangeValues.low && value <= rangeValues.high
          );
        } else {
          rangeRules.push(value < rangeValues.low || value > rangeValues.high);
        }
      }
      // Compile all the rangeRules into a single result theRule
      if (rule.OPERATOR === "==") {
        //theRuleResults.push(rangeRules.some(function (rule) { return rule; }));
        singleRuleResult.valid = rangeRules.some(function (rule) {
          return rule;
        });
      } else {
        //theRuleResults.push(rangeRules.every(function (rule) { return rule; }));
        singleRuleResult.valid = rangeRules.every(function (rule) {
          return rule;
        });
      }
    }
  }

  /**
   * For rules with a REFERENCE_VALUES_ARRAY property. Validate the rule based on the reference value and target condition.
   * @param {object} rule
   * @param {*} value - the value of the panel entity that is referenced
   * @param {string} rulePrefix - A prefix for the log message
   * @param {object} singleRuleResult - result of the singleRuleValidation
   */
  function checkReferenceValuesArray(
    rule,
    value,
    rulePrefix,
    singleRuleResult
  ) {
    if (rule.hasOwnProperty("REFERENCE_VALUES_ARRAY")) {
      switch (rule.OPERATOR) {
        case "==":
          singleRuleResult.valid =
            rule.REFERENCE_VALUES_ARRAY.indexOf(value) > -1;
          break;
        case "!=":
          singleRuleResult.valid =
            rule.REFERENCE_VALUES_ARRAY.indexOf(value) < 0;
          break;
      }
    }
  }

  /**
   * For rules with a REFERENCE_PATTERN property. Validate the rule based on the reference value and target condition.
   * @param {object} rule
   * @param {*} value - the value of the panel entity that is referenced
   * @param {string} rulePrefix - A prefix for the log message
   * @param {object} singleRuleResult - result of the singleRuleValidation
   */
  function checkReferencePattern(rule, value, rulePrefix, singleRuleResult) {
    if (rule.hasOwnProperty("REFERENCE_PATTERN")) {
      switch (rule.OPERATOR) {
        case "IN_PATTERN":
          var inRefPattern = new RegExp("^" + rule.REFERENCE_PATTERN + "$");
          singleRuleResult.valid = inRefPattern.test(value);
          break;
        case "NOT_IN_PATTERN":
          var notInRefPattern = new RegExp("^" + rule.REFERENCE_PATTERN + "$");
          singleRuleResult.valid = !notInRefPattern.test(value);
          break;
      }
    }
  }

  /**
   * For rules with a REFERENCE_PATTERN_TARGET_VALUE property. Validate the rule based on the reference value and target condition.
   * @param {object} rule
   * @param {*} value - the value of the panel entity that is referenced
   * @param {string} rulePrefix - A prefix for the log message
   * @param {object} singleRuleResult - result of the singleRuleValidation
   */
  function checkReferencePatternTargetValue(
    rule,
    value,
    rulePrefix,
    singleRuleResult
  ) {
    if (rule.hasOwnProperty("REFERENCE_PATTERN_TARGET_VALUE")) {
      switch (rule.OPERATOR) {
        case "IN_PATTERN":
          var inRefPattern = new RegExp(
            "^" + rule.REFERENCE_PATTERN_TARGET_VALUE.pattern + "$"
          );
          singleRuleResult.valid = inRefPattern.test(value);
          break;
        case "NOT_IN_PATTERN":
          var notInRefPattern = new RegExp(
            "^" + rule.REFERENCE_PATTERN_TARGET_VALUE.pattern + "$"
          );
          singleRuleResult.valid = !notInRefPattern.test(value);
          break;
      }
    }
  }

  /**
   * Check to see if we should skip this rule based on the current value of the target field
   * @param {object} panel
   * @param {object} targetEntity - the target object on the panel
   * @param {object} rule
   * @param {string} rulePrefix - A prefix for the log message
   * @returns {boolean} - true if rule should be skipped
   */
  function skipIfValid(panel, targetEntity, rule, rulePrefix) {
    var skip = false;
    if (propertyExistsAndEquals(rule, "SKIP_IF_VALID", true)) {
      var targetFieldRegEx = new RegExp(
        "^" +
          panel.PDS.panel_def.CONCEPTS[rule.TARGET_CONCEPT][rule.TARGET_FIELD]
            .DISPLAY.PATTERN +
          "$"
      );
      skip = targetFieldRegEx.test(targetEntity[rule.TARGET_FIELD]);
    }
    return skip;
  }

  /**
   * Determines if the given string is a range with numbers; sets a 'valid' property if 2 numbers are found
   * @param range
   * @param rulePrefix
   * @returns {object}
   */
  function getRangeValues(range, rulePrefix) {
    var rangeValues = {};
    // Break apart the range into separate values
    var values = range.split("-");
    // Check for non-numbers
    if (!isNumber(values[0]) || !isNumber(values[1])) {
      console.warn(rulePrefix + "Non-numeric value in range");
      rangeValues["valid"] = false;
    } else {
      rangeValues["valid"] = true;
    }
    var sorted = values.sort(function (a, b) {
      return a - b;
    });
    // Convert to numbers
    rangeValues["low"] = +sorted[0];
    rangeValues["high"] = +sorted[1];
    return rangeValues;
  }
});
