/**
 * @generated SignedSource<<2b9c49f7a61cfc0e4d476fd2485dd3a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsPrimaryUserLanguageField_systemOptions$data = {
  readonly primaryUserLanguage?: PanelProgrammingLanguage | null;
  readonly " $fragmentType": "SystemOptionsPrimaryUserLanguageField_systemOptions";
};
export type SystemOptionsPrimaryUserLanguageField_systemOptions$key = {
  readonly " $data"?: SystemOptionsPrimaryUserLanguageField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsPrimaryUserLanguageField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "primaryUserLanguage",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsPrimaryUserLanguageField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "09576e60dd8c8a19cbd43af93b0c6a0f";

export default node;
