/**
 *
 * AnimatedLockIcon
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Motion, spring } from "react-motion";
import SvgIcon from "./SvgIcon";

const defaultStyle = {
  y: 0,
};

const unlockedStyle = {
  y: spring(-60, { stiffness: 220 }),
};

const AnimatedLockIcon = ({
  unlocked,
  ...rest
}: {
  unlocked: boolean;
  rest: Parameters<typeof SvgIcon>[0];
}) => (
  <SvgIcon {...rest} viewBox="0 0 658 658" enableBackground="new 0 0 658 658">
    <Motion
      defaultStyle={defaultStyle}
      style={unlocked ? unlockedStyle : defaultStyle}
    >
      {({ y }) => (
        <path
          transform={`translate(0 ${y})`}
          d="M201.8,310.7c0,0,0-16.2,0-54.8s16.4-68.9,37.7-90.2s51.9-37.7,90.2-37.7s68.7,16.1,90.2,37.7s37.7,51,37.7,90.2 c0,33.4,0,114.8,0,114.8h-54.8c0,0,0-93.3,0-114.8s-10.6-40.9-21.4-51.7s-27.3-21.4-51.7-21.4c-24.3,0-40.5,10.2-51.7,21.4 c-11.2,11.2-21.4,28.4-21.4,51.7c0,27.4,0,54.8,0,54.8H201.8z"
        />
      )}
    </Motion>
    <path d="M456.9,310.7c0,0,19.3-2.3,28.5,8c9.2,10.3,8,19.4,8,19.4v164.5c0,0,0.4,11-8,19.4c-8.4,8.4-19.4,8-19.4,8H192 c0,0-11.6-0.2-19.4-8c-7.8-7.8-8-19.4-8-19.4V338.1c0,0-0.2-11.2,8-19.4c8.2-8.2,19.4-8,19.4-8h9.1H456.9z" />
  </SvgIcon>
);

AnimatedLockIcon.propTypes = {
  unlocked: PropTypes.bool,
};

export default AnimatedLockIcon;
