/**
 * @generated SignedSource<<88cf28cb073094c2a469323dcc37197d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathCellApn2Field_communicationPath$data = {
  readonly apn2: string;
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly " $fragmentType": "CommPathCellApn2Field_communicationPath";
};
export type CommPathCellApn2Field_communicationPath$key = {
  readonly " $data"?: CommPathCellApn2Field_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathCellApn2Field_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathCellApn2Field_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "apn2",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "25db72ed95caf37c8781007b37bd772c";

export default node;
