App.factory("StyleDataAccessService", [
  "$http",
  "$q",
  "StyleguideDataAPI",
  function ($http, $q, StyleguideDataAPI) {
    return {
      getStyleData: function (thefile) {
        var deferred = $q.defer();
        StyleguideDataAPI.getStyleData(
          { filename: thefile },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);

App.factory("StyleguideDataAPI", [
  "$resource",
  function ($resource) {
    //$resource(url, [paramDefaults], [actions], options);
    return $resource(
      "/app/styleguide/styleguide-data/:filename ",
      {},
      {
        getStyleData: {
          method: "GET",
          params: { filename: "@filename" },
          isArray: true,
        },
      }
    );
  },
]);
