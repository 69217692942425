/**
 * @generated SignedSource<<41d2cccd7c6e36460161c7473073e137>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
export type DeviceInformationWirelessSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationSupervisionTimeField_deviceInformation$data = {
  readonly deviceCommunicationMethod?: DeviceInformationCommType;
  readonly deviceType?: DeviceInformationType;
  readonly number: number;
  readonly supervisionTime: DeviceInformationWirelessSupervisionTime | null;
  readonly wireless?: boolean;
  readonly wirelessTranslator1100t?: boolean;
  readonly " $fragmentType": "DeviceInformationSupervisionTimeField_deviceInformation";
};
export type DeviceInformationSupervisionTimeField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationSupervisionTimeField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationSupervisionTimeField_deviceInformation">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceCommunicationMethod",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationSupervisionTimeField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supervisionTime",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wireless",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wirelessTranslator1100t",
          "storageKey": null
        }
      ],
      "type": "XtDeviceInformation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrDeviceInformation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75DeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};
})();

(node as any).hash = "c39ed4d50a744c07c13b066bec51a059";

export default node;
