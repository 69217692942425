/**
 *
 * useLockedValue
 * @author Chad Watson
 *
 *
 */
import { useRef } from "react";
export default function useLockedValue(value, locked = true) {
  const cachedValue = useRef(value);

  if (locked) {
    return cachedValue.current;
  }

  cachedValue.current = value;
  return cachedValue.current;
}
