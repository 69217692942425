App.directive(
  "daPrintField",
  function ($compile, $http, $templateRequest, $filter) {
    return {
      replace: true,
      scope: {
        ngModel: "=",
        ngModelErrors: "=",
        fieldObjectModel: "=",
        conceptObjectModel: "=",
        panelModel: "=",
        item: "=",
        useCase: "@",
      },
      restrict: "E",
      link: function (scope, element, attrs) {
        var textTemplate =
          "/app/programming/partials/print-view/print-form-normal-field.html";
        var selectTemplate =
          "/app/programming/partials/print-view/print-form-select-field.html";
        var timeTemplate =
          "/app/programming/partials/print-view/print-form-time-field.html";

        var getTemplate = function (inputType) {
          var template = "";
          var templateLoader;
          switch (inputType) {
            case "String":
              template = textTemplate;
              break;
            case "Select":
              template = selectTemplate;
              break;
            case "UTC Time":
              template = timeTemplate;
              break;
            default:
              template = textTemplate;
              break;
          }
          //templateLoader = $http.get(template, { cache: $templateCache });
          return template;
        };

        function update() {
          scope.opts = attrs;
          scope.dynamicSelectValues = scope.fieldObjectModel.VALUES;
          if (scope.useCase == "mass_programming") {
            scope.ngModel = scope.ngModel === "" ? "-" : scope.ngModel;
          }
          var inputType = DoesNestedPropertyExist(
            scope,
            "fieldObjectModel.DISPLAY.Data_Type"
          )
            ? scope.fieldObjectModel.DISPLAY.Data_Type
            : "String";
          var values = scope.fieldObjectModel.VALUES;
          if (angular.isDefined(values)) {
            inputType = "Select";
          }
          if (attrs.hidden) {
            inputType = "Hidden";
          }

          var loader = getTemplate(inputType);

          $templateRequest(getTemplate(inputType))
            .then(function (html) {
              element.html(html);
              $compile(element.contents())(scope);
            })
            .catch(function (error) {
              console.error(error);
            });
        }
        // Call update to compile the formInput the first time
        update();
      },

      controller: function ($scope, $element, $attrs) {
        /**
         *
         * @name getObjKeyFromValue
         *
         * Given a value, searches through an object's properties to find the matching property name
         */
        $scope.getObjKeyFromValue = function (obj, value) {
          if (!$scope.panelModel[$scope.conceptObjectModel.key].isbusy) {
            for (var key in obj) {
              if (obj.hasOwnProperty(key)) {
                if (obj[key] == value) {
                  return key;
                }
              }
            }
          }

          return undefined;
        };
      },
    };
  }
);
