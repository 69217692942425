/**
 * @generated SignedSource<<eeb8d01c5b201a944f93acdd6e93b48a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2FirstPhoneNumberField_communication$data = {
  readonly comType: CommunicationComType;
  readonly receiver2PrimaryPhoneNumber: string;
  readonly " $fragmentType": "CommunicationReceiver2FirstPhoneNumberField_communication";
};
export type CommunicationReceiver2FirstPhoneNumberField_communication$key = {
  readonly " $data"?: CommunicationReceiver2FirstPhoneNumberField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2FirstPhoneNumberField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2FirstPhoneNumberField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2PrimaryPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "7a3eef9f6ff0048af556359ece7a5e6a";

export default node;
