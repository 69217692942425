import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, NetworkOptions } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { NetworkOptionsContextProvider } from "../common/NetworkOptionsFields/NetworkOptionsContext";
import NetworkOptionsDhcpEnabledField from "../common/NetworkOptionsFields/NetworkOptionsDhcpEnabledField";
import NetworkOptionsDnsServerField from "../common/NetworkOptionsFields/NetworkOptionsDnsServerField";
import NetworkOptionsGatewayAddressField from "../common/NetworkOptionsFields/NetworkOptionsGatewayAddressField";
import NetworkOptionsLocalIpAddressField from "../common/NetworkOptionsFields/NetworkOptionsLocalIpAddressField";
import NetworkOptionsSubnetMaskField from "../common/NetworkOptionsFields/NetworkOptionsSubnetMaskField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XFNetworkOptionsProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XFNetworkOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XFNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation,
} from "./__generated__/XFNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XFNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation,
  XFNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation$data,
} from "./__generated__/XFNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation.graphql";
import { XFNetworkOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XFNetworkOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Network Options";
export const conceptId = "xf-network-options";

export const getState = (
  controlSystem: XFNetworkOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFNetworkOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          networkOptions {
            id
            dhcpEnabled
            localIpAddress
            gatewayAddress
            subnetMask
            dnsServer
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XFNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshNetworkOptions(id: $id) {
      ... on RefreshNetworkOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFNetworkOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XFNetworkOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshNetworkOptions, isRefreshing] =
    useMutation<XFNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshNetworkOptions({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshNetworkOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Network Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshNetworkOptions: {
                    __typename: response.refreshNetworkOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Network Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Network Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XFNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation(
    $systemId: ID!
    $networkOptions: NetworkOptionsInput!
  ) {
    sendNetworkOptions(systemId: $systemId, networkOptions: $networkOptions) {
      ... on SendNetworkOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XFNetworkOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendNetworkOptionsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XFNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendNetworkOptions.controlSystem) {
    const operation = createOperationDescriptor(sendMutationConcreteRequest, {
      id: response.sendNetworkOptions.controlSystem.id,
    });
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        sendNetworkOptions: {
          __typename: response.sendNetworkOptions.__typename,
          controlSystem: getState(response.sendNetworkOptions.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFNetworkOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendNetworkOptions, isSendingNetworkOptions] =
    useMutation<XFNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { networkOptions },
        } = getState(props.controlSystem);
        if (networkOptions) {
          sendNetworkOptions({
            variables: {
              systemId,
              networkOptions: {
                wifiSsid: "",
                wifiSecurity: "NONE",
                wifiPassphrase: "",
                dhcpEnabled: networkOptions.dhcpEnabled,
                localIpAddress: networkOptions.localIpAddress,
                gatewayAddress: networkOptions.gatewayAddress,
                subnetMask: networkOptions.subnetMask,
                dnsServer: networkOptions.dnsServer,
                listenPort734N: "",
                passphrase734N: "",
                passphrase: null,
                ipv6Enabled: false,
                ipv6Address: "",
                ipv6Gateway: "",
                ipv6Prefix: "",
                ipv6Dns: "",
                programmingPort: "",
                enableDdp: false,
                ddpPassphrase: "",
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendNetworkOptions.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Network Options Programming Saved To the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(response, parentRelayEnv);
              } else if (response.sendNetworkOptions.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendNetworkOptions.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSendingNetworkOptions,
  ];
};

const readNetworkOptionsTemplateData = (
  programmingTemplateConcepts: XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        networkOptions {
          included
          dhcpEnabled {
            included
            data
          }
          localIpAddress {
            included
            data
          }
          gatewayAddress {
            included
            data
          }
          subnetMask {
            included
            data
          }
          dnsServer {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).networkOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readNetworkOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const networkOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "networkOptions",
      "NetworkOptions"
    ) as unknown as RecordProxy<NetworkOptions>;

    applyTemplateScalarDataToRecordProxy(
      networkOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFNetworkOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFNetworkOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            featureKeys {
              key
            }
            networkOptions {
              id
              ...NetworkOptionsContext_networkOptions
              ...NetworkOptionsDhcpEnabledField_networkOptions
              ...NetworkOptionsLocalIpAddressField_networkOptions
              ...NetworkOptionsGatewayAddressField_networkOptions
              ...NetworkOptionsSubnetMaskField_networkOptions
              ...NetworkOptionsDnsServerField_networkOptions
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHasWifiCommType_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Network%20Options`}
        title={title}
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.networkOptions
        )}
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          <NetworkOptionsContextProvider
            networkOptions={controlSystem.panel.networkOptions}
          >
            <ProgrammingConceptForm.Fields>
              <NetworkOptionsDhcpEnabledField />
              <NetworkOptionsLocalIpAddressField />
              <NetworkOptionsGatewayAddressField />
              <NetworkOptionsSubnetMaskField />
              <NetworkOptionsDnsServerField />
            </ProgrammingConceptForm.Fields>
          </NetworkOptionsContextProvider>
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}
