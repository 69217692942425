// SevenInchKeypadSettings component
// This component is is a content area for the SevenInchKeypadSettings
// tab section of the Dealer Settings page.
// It is a container for the SevenInchKeypadSettingsForm component.
// The SevenInchKeypadSettingsForm component is a form that allows the user
// to upload a logo and update the dealer contact information for the 7" keypad.

//#region IMPORTS
import { RelayServiceType } from "app/common/services/relay-service";
import { UserServiceType } from "components/SiteForm/types";
import React, { useState } from "react";
import SevenInchContactInfoEdit from "./SevenInchContactInfoEdit";
import { SettingsGridKeypad } from "./SevenInchKeypadStyles";
import { SevenInchLogoContext } from "./SevenInchLogoContext";
import SevenInchLogoEdit from "./SevenInchLogoEdit";

//#endregion IMPORTS

// #region TYPES
type SevenInchKeypadSettingsContentProps = {
  relayService: RelayServiceType;
  userService: UserServiceType;
  rootScope: any;
  stateParams: any;
  dealerId: string;
  $state: any;
  stateIsEdit: boolean;
  authToken: string;
  imageUrl: string;
  environment: any;
};

// #endregion TYPES

export function SevenInchKeypadForm({
  dealerId,
  stateIsEdit,
  authToken,
  imageUrl,
}: SevenInchKeypadSettingsContentProps) {
  const [imageSource, setImageSource] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(
    null
  );

  return (
    <SevenInchLogoContext.Provider
      value={{ imageSource, setImageSource, selectedFile, setSelectedFile }}
    >
      <SettingsGridKeypad>
        {/* Logo Preview and Edit Area  */}

        <SevenInchLogoEdit
          dealerId={dealerId}
          stateIsEdit={stateIsEdit}
          authToken={authToken}
          imageUrl={imageUrl}
        />

        {/* Contact Preview and Edit Area */}
        <SevenInchContactInfoEdit
          dealerId={dealerId}
          stateIsEdit={stateIsEdit}
        />
      </SettingsGridKeypad>
    </SevenInchLogoContext.Provider>
  );
}
