import graphql from "babel-plugin-relay/macro";
import { sleep } from "common/utils/universal/promise";
import { Flex } from "components/DaStyledElements";
import { GenericPageFallback } from "components/GenericPageFallback";
import { pipe } from "fp-ts/function";
import * as React from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { asString, toDealerId } from "securecom-graphql/client";
import styled from "styled-components";
import AvailableCredentialsTable from "./AvailableCredentialsTable";
import HistoryTable from "./HistoryTable";
import IssueCredentials from "./IssueCredentials";
import { MobileCredentialsQuery } from "./__generated__/MobileCredentialsQuery.graphql";

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, minmax(0, max-content));
  grid-template-areas: "cred-config-form" "summary-table" "history-table";
  grid-row-gap: 1.6rem;
  max-width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr minmax(0, max-content);
    grid-template-areas: "cred-config-form summary-table summary-table" "history-table history-table history-table";
    grid-gap: 1.6rem;
  }
`;
const GridCredConfiguration = styled.div`
  grid-area: cred-config-form;
`;
const GridSummary = styled.div`
  grid-area: summary-table;
`;
const GridHistory = styled.div`
  grid-area: history-table;
`;
const SubHeader = styled.div`
  font-size: 2rem;
  display: inline-block;
  margin: var(--measure-2x) 0;
  line-height: var(--measure-3x);
`;

const suspendUntilAngularDataIsPresent = (data: any) => {
  const dataIsStillMissing = data === null || data === undefined;

  if (dataIsStillMissing) {
    throw sleep(50);
  }
};

export default function MobileCredentialsRoot({
  RelayService,
  $stateParams,
  UserService,
  $rootScope,
  $state,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <MobileCredentials
          dealerId={$stateParams.dealer_id}
          userService={UserService}
          rootScope={$rootScope}
          state={$state}
        />
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}
function MobileCredentials({
  dealerId,
  userService,
  rootScope,
  state,
}: {
  dealerId: string;
  userService: any;
  rootScope: any;
  state: any;
}) {
  const navigateToCustomer = (customerId: number) => {
    state.go("app.customers.customersummary", {
      dealer_id: dealerId,
      customer_id: customerId,
    });
  };
  suspendUntilAngularDataIsPresent(userService.user);
  const data = useLazyLoadQuery<MobileCredentialsQuery>(
    graphql`
      query MobileCredentialsQuery($dealerId: ID!) {
        dealer(id: $dealerId) {
          ...IssueCredentialsFragment_dealer
          ...AvailableCredentialsTableFragment_dealer
          customersConnection {
            nodes {
              name
              availableMobileCredentials
            }
          }
          ...HistoryTableFragment
        }
      }
    `,
    { dealerId: pipe(dealerId, toDealerId, asString) }
  );

  const dealer = data.dealer;

  const createNotification = (notification: {
    type: "success" | "warning" | "error";
    text: string;
  }) => {
    rootScope.alerts.push(notification);
    rootScope.$apply();
  };

  return dealer ? (
    <>
      <div className="row">
        <div className="page-header">
          <div className="page-header__left">
            <div className="page-header__title">
              Mobile Bluetooth Credentials
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Grid>
          {userService.canIssueMobileCredentials() && (
            <GridCredConfiguration>
              <Flex.Row>
                <Flex.Col size={1}>
                  <SubHeader>Purchase Bluetooth Credentials</SubHeader>
                </Flex.Col>
              </Flex.Row>
              <IssueCredentials
                dealer={dealer}
                createNotification={createNotification}
              />
            </GridCredConfiguration>
          )}
          <GridSummary>
            <Flex.Row>
              <Flex.Col size={1}>
                <SubHeader>Available Bluetooth Credentials</SubHeader>
              </Flex.Col>
            </Flex.Row>
            <AvailableCredentialsTable
              dealerRef={dealer}
              linkFunction={navigateToCustomer}
            />
          </GridSummary>

          <GridHistory>
            <SubHeader>Purchase History</SubHeader>
            <HistoryTable dealerRef={dealer} />
          </GridHistory>
        </Grid>
      </div>
    </>
  ) : null;
}
