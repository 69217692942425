/**
 * @name App.directive:daTwilightOffsetSelector
 * @desc This directive creates the sunrise / sunset selector on schedules
 * @property label: string - Directional label for the time entry. ex: Begin or End, On or Off, etc.
 * @property beginTime: Bool - is this for the earlier time slot (begin/on/activate/arm slot)
 * @property periodDropdown: Value for the sunrise/sunset dropdown
 * @property offsetDropdown: Value for the offset selector
 * @property setPeriodFn: Function to run when sunrise or sunset is selected
 * @property setOffsetFn: Function to run when the offset is selected
 *
 * @example <da-twilight-offset-selector label="Begin" begin-time="true" period-dropdown="twilightData.beginPeriodDropdown"
              offset-dropdown="twilightData.beginOffsetDropdown" set-period-fn="setPeriod(begin, dir)"
              set-offset-fn="setOffset(begin, offset)">
            </da-twilight-offset-selector>
 */

App.directive("daTwilightOffsetSelector", daTwilightOffsetSelector);

daTwilightOffsetSelector.$inject = ["scheduleUtilService"];
function daTwilightOffsetSelector(scheduleUtilService) {
  return {
    restrict: "E",

    scope: {
      label: "@",
      beginTime: "=",
      periodDropdown: "=",
      offsetDropdown: "=",
      setPeriodFn: "&",
      setOffsetFn: "&",
    },

    link: function (scope) {
      scope.offsets = scheduleUtilService.twilightData.offsets;
    },

    templateUrl: "/app/common/templates/twilight-offset-selector.html",
  };
}
