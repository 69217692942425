/**
 * @ngdoc object
 * @name App.controller:ReplacePanelCtrl
 *
 *
 * @description
 *  Controller for the panel replacement modal
 */
App.controller("ChangeAccountNumberRemoteKeyCtrl", [
  "$scope",
  "$modalInstance",
  "ControlSystemsService",
  "Panel",
  "$state",
  "UserService",
  "controlSystemForm",
  "originalSerialNumber",
  "originalAccountNumber",
  "originalReceiverNumber",
  "originalRemoteKey",
  "changeType",
  "ReplacePanelService",
  "ChangeConnectionInfoService",
  "$q",
  "VALIDATION_PATTERNS",
  function (
    $scope,
    $modalInstance,
    ControlSystemsService,
    Panel,
    $state,
    UserService,
    controlSystemForm,
    originalSerialNumber,
    originalAccountNumber,
    originalReceiverNumber,
    originalRemoteKey,
    changeType,
    ReplacePanelService,
    ChangeConnectionInfoService,
    $q,
    VALIDATION_PATTERNS
  ) {
    $scope["controlSystemForm"] = controlSystemForm;
    $scope["new_serial_number"] = "";
    $scope["new_account_number"] =
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].account_number;
    $scope["new_remote_key"] =
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].remote_key;
    $scope["new_account_prefix"] =
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].new_account_prefix;
    $scope["hasPanelErrors"] = false;
    $scope["panelErrorMessages"] = [];
    $scope["hasCachedData"] = false;
    $scope["panel"] =
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ];
    $scope["appKey"] = UserService.dealerInfo.app_key;
    $scope["VALIDATION_PATTERNS"] = VALIDATION_PATTERNS;
    $scope["changeType"] = changeType;

    $scope.init = function () {
      var panel = new Panel(UserService.panel_id);
      panel
        .fetch("system_options")
        .then(
          function () {
            if ($scope.panel.hardware_family === "XF6") {
              $scope.hasCachedData = true;
            } else if ($scope.panel.hardware_family === "TMSentry") {
              $scope.hasCachedData = DoesNestedPropertyExist(
                panel,
                "system_options.time_chg"
              );
            } else {
              $scope.hasCachedData = DoesNestedPropertyExist(
                panel,
                "system_options.arm_mode"
              );
            }
          },
          function (error) {
            console.warn(
              "Error retrieving system_options! Error: " + angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.changeRemoteKey = function (changeField) {
      var deferred = $q.defer();
      //have to set the remote key here because the changeConnectionInfo function will not update the remote key when an existing connection is running
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].remote_key =
        $scope.changeAccountNumberRemoteKeyForm.panels_remote_key.$modelValue;
      ChangeConnectionInfoService.changeConnectionInfo(changeField)
        .then(
          function (runConnection) {
            runConnection;
            $scope.close();
          },
          function (error) {
            console.error(
              "PanelService->update() - ensure change remote key error: " +
                angular.toJson(error)
            );
            $scope.cancel();
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    };

    $scope.changeConnectionInfo = function (changeField) {
      var deferred = $q.defer();
      ChangeConnectionInfoService.changeConnectionInfo()
        .then(
          function (runConnection) {
            runConnection;
            $scope.close();
          },
          function (error) {
            console.error(
              "PanelService->update() - ensure change connection info error: " +
                angular.toJson(error)
            );
            $scope.cancel();
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    };

    function reloadPage() {
      $state.forceReload();
    }

    $scope.close = function () {
      $modalInstance.dismiss("closed");
    };

    $(document).keydown(function (event) {
      if (event.keyCode == 27) {
        $scope.cancel();
      }
    });

    /**
     * Cancel and close modal
     */
    $scope.cancel = function () {
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].account_number = originalAccountNumber;
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].account_prefix = originalReceiverNumber;
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].remote_key = originalRemoteKey;
      $modalInstance.dismiss("cancel");
    };
  },
]);
