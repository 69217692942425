/**
 * @generated SignedSource<<df31daded6f72b536a7236c51180b465>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationDMPWirelessField_zone$data = {
  readonly competitorWireless: boolean | null;
  readonly number: string;
  readonly wireless: boolean;
  readonly " $fragmentType": "ZoneInformationDMPWirelessField_zone";
};
export type ZoneInformationDMPWirelessField_zone$key = {
  readonly " $data"?: ZoneInformationDMPWirelessField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationDMPWirelessField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationDMPWirelessField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competitorWireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "0b437f19e55ed555da80d776db62470b";

export default node;
