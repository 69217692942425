export const addClassIf = (
  className: string,
  shouldAddClass: boolean,
  currentClass = ""
) => (shouldAddClass ? [currentClass, className].join(" ") : currentClass);

export const addClassesIf = (
  conditionalClasses: { [className: string]: boolean } | [boolean, string][],
  currentClass = ""
) =>
  currentClass
    .concat(" ")
    .concat(
      Array.isArray(conditionalClasses)
        ? conditionalClasses
            .filter(([shouldAddClass]) => shouldAddClass)
            .map(([_, className]) => className)
            .join(" ")
        : Object.entries(conditionalClasses)
            .filter(([, shouldAddClass]) => shouldAddClass)
            .map(([className]) => className)
            .join(" ")
    )
    .trim();

export function classNames(...classes: (string | false | null | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}
