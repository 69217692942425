/**
 * @generated SignedSource<<dceb4bcead51d006701a5584b9a8ccb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type FullProgrammingForm_controlSystem$data = {
  readonly customer: {
    readonly id: string;
  };
  readonly id: string;
  readonly panel: {
    readonly hardwareModel: PanelHardwareModel;
    readonly id: string;
    readonly online: boolean;
  };
  readonly " $fragmentType": "FullProgrammingForm_controlSystem";
};
export type FullProgrammingForm_controlSystem$key = {
  readonly " $data"?: FullProgrammingForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"FullProgrammingForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FullProgrammingForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "online",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "e1ed07fe8ab739aeae7f10bffe0f3de8";

export default node;
