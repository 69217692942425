/**
 * @generated SignedSource<<69293ff696b806c64a858aac1f1c0ccd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommunicationPathAlarmReports = "FIRE" | "NO" | "YES";
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathDayOfWeek = "FRIDAY" | "MONDAY" | "NONE" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY";
export type CommunicationPathDoorAccessReports = "DENY" | "NO" | "YES";
export type CommunicationPathProtocol = "TCP" | "UDP";
export type CommunicationPathSubstitutionCode = "NO" | "SHARED" | "YES";
export type CommunicationPathSupervisoryTroubleReports = "FIRE" | "NO" | "YES";
export type CommunicationPathTestFrequencyUnit = "DAYS" | "HOURS";
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly communication: ReadonlyArray<{
    readonly alarmReports: {
      readonly data: CommunicationPathAlarmReports | null;
      readonly included: boolean | null;
    } | null;
    readonly alarmSwitchover: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly apn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly checkInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly commType: {
      readonly data: CommunicationPathCommType | null;
      readonly included: boolean | null;
    } | null;
    readonly doorAccessReports: {
      readonly data: CommunicationPathDoorAccessReports | null;
      readonly included: boolean | null;
    } | null;
    readonly duplicateAlarms: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly failTestHours: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly failTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly firstPhone: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly number: number;
    readonly openCloseUserReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly panicTest: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly protocol: {
      readonly data: CommunicationPathProtocol | null;
      readonly included: boolean | null;
    } | null;
    readonly receiverPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteIP: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteIPv6: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly retryTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly secondLinePrefixFor893A: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly secondPhone: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sendFail: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly sendPath: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly substitutionCode: {
      readonly data: CommunicationPathSubstitutionCode | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryTroubleReports: {
      readonly data: CommunicationPathSupervisoryTroubleReports | null;
      readonly included: boolean | null;
    } | null;
    readonly testDayOfWeek: {
      readonly data: CommunicationPathDayOfWeek | null;
      readonly included: boolean | null;
    } | null;
    readonly testFrequencyNumber: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly testFrequencyUnit: {
      readonly data: CommunicationPathTestFrequencyUnit | null;
      readonly included: boolean | null;
    } | null;
    readonly testReport: {
      readonly data: CommunicationPathTestReport | null;
      readonly included: boolean | null;
    } | null;
    readonly testTime: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly transmissionDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly type: {
      readonly data: CommunicationPathType | null;
      readonly included: boolean | null;
    } | null;
    readonly use893A: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly useCheckIn: {
      readonly data: CommunicationPathUseCheckIn | null;
      readonly included: boolean | null;
    } | null;
    readonly useIPv6: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "a43405aceb1dd0fad2f62be4f608fac9";

export default node;
