import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaArmFirstAreaField_xrArea$key } from "./__generated__/AreaArmFirstAreaField_xrArea.graphql";

export const areaArmFirstAreaFieldId = (number: string) =>
  `area-arm-first-area-${number}`;

function AreaArmFirstAreaField() {
  const [{ number, armFirstArea }, updateArea] =
    useAreaFragment<AreaArmFirstAreaField_xrArea$key>(
      graphql`
        fragment AreaArmFirstAreaField_xrArea on XrArea {
          number
          armFirstArea
        }
      `
    );

  const fieldId = areaArmFirstAreaFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Arm First Area"
      tooltip="When arming from a Keypad, Arm First Areas are armed if they are included in the Keypad's Display Areas when any other Area is armed."
    >
      <Switch
        label="Arm First Area"
        id={fieldId}
        checked={armFirstArea}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!armFirstArea, "armFirstArea");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaArmFirstAreaField;
