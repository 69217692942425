/**
 *
 * Small
 *
 */

import styled from "styled-components";

const Small = styled.small`
  color: ${({ theme }) => theme.grayMedium};
  font-weight: 500;
`;

export default Small;
