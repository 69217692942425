import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { CommunicationPathEncryptionScheme } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathEncryptionSchemaField_communicationPath$key } from "./__generated__/CommPathEncryptionSchemaField_communicationPath.graphql";

export const commPathEncryptionSchemaFieldId = (number: string) =>
  `comm-path-encryption-schema-${number}`;

function CommPathEncryptionSchemaField() {
  const [{ number, encryptionScheme, encryptionEnabled }, updateCommPath] =
    useCommPathFragment<CommPathEncryptionSchemaField_communicationPath$key>(
      graphql`
        fragment CommPathEncryptionSchemaField_communicationPath on CommunicationPath {
          number
          encryptionScheme
          encryptionEnabled
        }
      `
    );

  const fieldId = commPathEncryptionSchemaFieldId(String(number));
  const disabled = !encryptionEnabled;
  const label = "Encryption Schema";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Determines the unit of measurement for the Test Frequency Number. The combination of these fields determines how often this communication path is tested."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        value={encryptionScheme}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "encryptionScheme");
          });
        }}
      >
        <Select.Option value={CommunicationPathEncryptionScheme._128_BIT}>
          128 Bit
        </Select.Option>
        <Select.Option value={CommunicationPathEncryptionScheme._256_BIT}>
          256 Bit
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathEncryptionSchemaField;
