/**
 * @generated SignedSource<<67c4367af2aba94326078d96ef4ae188>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputGroupsProgrammingConceptFormInline_outputGroup$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: number;
  readonly outputNumber1: string;
  readonly outputNumber2: string;
  readonly outputNumber3: string;
  readonly outputNumber4: string;
  readonly outputNumber5: string;
  readonly outputNumber6: string;
  readonly outputNumber7: string;
  readonly outputNumber8: string;
  readonly " $fragmentType": "XFOutputGroupsProgrammingConceptFormInline_outputGroup";
};
export type XFOutputGroupsProgrammingConceptFormInline_outputGroup$key = {
  readonly " $data"?: XFOutputGroupsProgrammingConceptFormInline_outputGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputGroupsProgrammingConceptFormInline_outputGroup">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputGroupsProgrammingConceptFormInline_outputGroup"
};

(node as any).hash = "7b1c783f07b0760064a762ff76980e70";

export default node;
