import { removeParamFromUrl } from "common/utils";
/**
 * @ngdoc service
 * @name App.factory:OdataPageService
 *
 * @description
 * Manages long running jobs submitted to any OData API with paging enabled
 *
 */
App.factory("OdataPageService", [
  "$rootScope",
  "$q",
  "$http",
  "PROPS",
  "$filter",
  "$timeout",
  function ($rootScope, $q, $http, PROPS, $filter, $timeout) {
    return function () {
      angular.extend(this, {
        pageData: [],

        /**
         * @ngdoc object
         * @name method:process
         * @methodOf App.factory:OdataPageService
         *
         * @description
         * Returns a new Deferred Promise.  Calls itself recursively until the job is
         * complete, fails, or times out.
         *
         * @param {Promise} httpPromise The resource promise that will return the initial data
         * @param {Number} [maxCount] Optional Override the max number of requests for the status of the current job
         * @param {promise} [deferred] Passed in on recursive calls to the process function.  Created new on the first call.
         * @return {promise} The promise of beautiful data.
         */
        getAllPages: function (httpPromise, deferred) {
          deferred = typeof deferred === "undefined" ? $q.defer() : deferred;
          var _this = this;

          httpPromise
            .then(
              function (inData) {
                var data = null;
                // For some reason, data returned from $http in this method is returned as the value of key "data":, so
                // we use this logic to strip it down to the base data level again.
                if (inData.data) {
                  data = inData.data;
                } else {
                  data = inData;
                }
                // If there is data, append it to the data array
                if (data.value.length > 0) {
                  _this.pageData = _this.pageData.concat(data.value);
                  deferred.notify(_this.pageData);
                }
                if (data["@odata.nextLink"]) {
                  // Get the next page
                  const nextLink = removeParamFromUrl(
                    // Remove the auth token from the next link to work with JWT
                    "auth_token",
                    data["@odata.nextLink"]
                  );
                  _this
                    .getAllPages($http.get(nextLink), deferred)
                    .then(
                      function () {
                        deferred.resolve(_this.pageData);
                      },
                      function (error) {
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                } else {
                  deferred.resolve(_this.pageData);
                }
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });

          return deferred.promise;
        },
      });
    };
  },
]);
