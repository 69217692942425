App.service("IpResolverService", [
  "$q",
  "$http",
  "PROPS",
  function ($q, $http, PROPS) {
    return {
      //get the IP addresses associated with an account
      getIP: function () {
        var deferred = $q.defer();
        let fd = new FormData();
        fd.append("key", PROPS.ipapiKey);
        let salt = Date.now();
        $http
          .post(PROPS.ipResolverUrl + `?${salt}`, fd, {
            headers: { "Content-Type": undefined },
          })
          .then(
            function (data) {
              deferred.resolve(data.data.ip);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        return deferred.promise;
      },
    };
  },
]);
