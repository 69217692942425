/**
 * @ngdoc service
 * @name App.factory:RemoteUpdateFactory
 *
 * @description
 * WARNING!: This should only be used for BULK updates.  Individual updates should use the RemoteUpdateService.
 *
 */
App.factory("RemoteUpdateFactory", [
  "$rootScope",
  "$q",
  "$http",
  "PROPS",
  "$filter",
  "JobSchedulerService",
  "$interval",
  "UserService",
  "Customer",
  "AvailableUpdates",
  function (
    $rootScope,
    $q,
    $http,
    PROPS,
    $filter,
    JobSchedulerService,
    $interval,
    UserService,
    Customer,
    AvailableUpdates
  ) {
    var remoteUpdateFactory = function () {
      angular.extend(this, {
        /**
         * Local constants to define which jobType and jobGroupType we are using for remote Update jobs.
         */
        JOB_TYPE_NAME: "PANEL_UPDATE",
        //JOB_TYPE_NAME: 'THREAD_TEST',
        JOB_GROUP_TYPE_NAME: "PANEL_UPDATE",

        /**
         * The array of Remote Update job groups that are in the current context (one panel)
         */
        //updateJobGroups: [],

        /**
         * An object with attributes representing the possible statuses of an update
         */
        updateStatuses: {
          UPDATE_UNKNOWN: "",
          UPDATE_AVAILABLE: "updateAvailable",
          UPDATE_NOT_AVAILABLE: "updateNotAvailable",
          UPDATE_IN_PROGRESS: "updateInProgress",
        },

        /**
         * String to hold the current status.  It will be one of the updateStatuses
         */
        currentStatus: "",

        /**
         * The current percent complete for _this_ update.
         */
        updatePercent: 0,

        /**
         * The panel that we are updating or getting status of
         */
        activePanelId: null,

        /**
         * Initialize this service.
         *
         * @param panelID
         */
        initialize: function (panelID) {
          this.activePanelId = panelID;
          //this.stopUpdateWatcher(this.activeUpdateWatcher);
          this.currentStatus = "";
          this.updatePercent = 0;
          //delete this.updateJobGroups;
        },

        /**
         * Set the currentStatus attribute
         * @param status
         */
        setStatus: function (status) {
          this.currentStatus = status;
        },

        /**
         * Creates a scheduled JobGroup to update the activePanel with the software version specified
         * @returns {Promise} a promise, which will eventually resolve the JSON data of the JobGroup that is created.
         */
        updatePanel: function (
          fromVersion,
          toVersion,
          hardware_model,
          customerName,
          systemName,
          availableUpdateObject
        ) {
          var _this = this;
          var deferred = $q.defer();
          this.setStatus(_this.updateStatuses.UPDATE_IN_PROGRESS);
          var job = JobSchedulerService.getJobTemplate();
          var jobGroup = JobSchedulerService.getJobGroupTemplate();

          var jobGroupTypePromise = JobSchedulerService.getJobGroupTypeByName(
            this.JOB_GROUP_TYPE_NAME
          );
          var jobTypePromise = JobSchedulerService.getJobTypeByName(
            this.JOB_TYPE_NAME
          );

          $q.all([jobGroupTypePromise, jobTypePromise])
            .then(
              function (values) {
                jobGroup.SchedulerJobGroupTypeId = values[0].Id;
                job.SchedulerJobTypeId = values[1].Id;

                jobGroup.GroupClass = "BULK";
                jobGroup.GroupData = _this.getGroupData(
                  fromVersion,
                  toVersion,
                  customerName,
                  systemName
                );
                jobGroup.PanelId = _this.activePanelId;
                //jobGroup.Email = null;
                job.PanelId = _this.activePanelId;

                if (availableUpdateObject)
                  job.SelectedItem = angular.toJson(availableUpdateObject);
                else
                  job.SelectedItem = angular.toJson(_this.availableUpdates[0]);

                jobGroup.SchedulerJobs.push(job);
                JobSchedulerService.createJobGroup(jobGroup)
                  .then(
                    function (data) {
                      //_this.updateJobGroups.push(data);
                      //_this.startUpdateWatcher(data.Id);
                      deferred.resolve(data);
                    },
                    function (error) {
                      deferred.reject(error);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * Retrieve the available updates data from VK
         *
         */
        getAvailableUpdates: function (softwareVersion, softwareDate) {
          var deferred = $q.defer();
          var _this = this;
          AvailableUpdates.query(
            { id: _this.activePanelId },
            function (data) {
              if (data && data.length > 0) {
                // Loop through the data, reformat it and sort it
                var updates = [];
                angular.forEach(data, function (panel_firmware) {
                  updates.push(panel_firmware.panel_firmware);
                });
                _this.availableUpdates = $filter("orderBy")(updates, [
                  "-version",
                  "-code_date",
                ]);
                var updateVersion = _this.availableUpdates[0].version;
                var updateCodeDate = dateTimeForceUTC(
                  new Date(_this.availableUpdates[0].code_date)
                );

                if (softwareVersion) var myVersion = softwareVersion;
                else
                  var myVersion = Number(
                    UserService.controlSystem.panels[0].software_version
                  );

                if (softwareDate) var myDate = softwareDate;
                else
                  var myDate = parsePanelDate(
                    UserService.controlSystem.panels[0].software_date
                  );

                if (
                  myVersion < updateVersion ||
                  (myVersion == updateVersion && myDate < updateCodeDate)
                ) {
                  _this.setStatus(_this.updateStatuses.UPDATE_AVAILABLE);
                } else {
                  _this.setStatus(_this.updateStatuses.UPDATE_NOT_AVAILABLE);
                }
              } else {
                _this.setStatus(_this.updateStatuses.UPDATE_NOT_AVAILABLE);
              }

              deferred.resolve(_this.availableUpdates);
            },
            function (error) {
              //log if error
              _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        /**
         * Function to set the GroupData field on the Job Group
         * @param fromVersion
         * @param toVersion
         * @returns {string|undefined|string}
         */
        getGroupData: function (
          fromVersion,
          toVersion,
          customerName,
          systemName
        ) {
          var groupData = {};
          if (customerName) groupData.customer_name = customerName;
          else if (UserService.customerInfo)
            groupData.customer_name = UserService.customerInfo.name;
          else groupData.customer_name = "-----";

          if (systemName) groupData.control_system_name = systemName;
          else if (UserService.controlSystem)
            groupData.control_system_name = UserService.controlSystem.name;
          else groupData.control_system_name = "-----";

          groupData.from_version = fromVersion;
          groupData.to_version = toVersion;
          return angular.toJson(groupData);
        },
      });
    };
    return remoteUpdateFactory;
  },
]);
