/**
 * @generated SignedSource<<ba09a4abbbc979256358f474e4960511>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiagnosticSearchQuery$variables = {
  queryString: string;
  useWildCard: string;
};
export type DiagnosticSearchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DiagnosticSearch_query">;
};
export type DiagnosticSearchQuery = {
  response: DiagnosticSearchQuery$data;
  variables: DiagnosticSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "queryString"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "useWildCard"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiagnosticSearchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DiagnosticSearch_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiagnosticSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "queryString",
            "variableName": "queryString"
          },
          {
            "kind": "Variable",
            "name": "useWildCard",
            "variableName": "useWildCard"
          }
        ],
        "concreteType": "SystemDiagnosticsPanel",
        "kind": "LinkedField",
        "name": "systemDiagnosticsPanelSearch",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelSerialNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "controlSystemName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "controlSystemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "simIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelCommAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hardwareModel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelSoftwareVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelSoftwareDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "panelArmingSystem",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5dfb8e52d0d10cbb90b4ffecc60569f",
    "id": null,
    "metadata": {},
    "name": "DiagnosticSearchQuery",
    "operationKind": "query",
    "text": "query DiagnosticSearchQuery(\n  $queryString: String!\n  $useWildCard: String!\n) {\n  ...DiagnosticSearch_query\n}\n\nfragment DiagnosticSearch_query on Query {\n  systemDiagnosticsPanelSearch(queryString: $queryString, useWildCard: $useWildCard) {\n    panelSerialNumber\n    controlSystemName\n    accountNumber\n    controlSystemId\n    customerId\n    simIdentifier\n    panelCommAddress\n    hardwareModel\n    panelSoftwareVersion\n    panelSoftwareDate\n    panelArmingSystem\n    commType\n  }\n}\n"
  }
};
})();

(node as any).hash = "11afca1ef9f0eb9d504212459f0325d7";

export default node;
