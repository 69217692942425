/**
 * @ngdoc service
 * @name App.factory:CustomerEngagementService
 *
 * @description
 * Manages the  Customer Engagement programming jobs
 *
 */
App.factory("CustomerEngagementService", [
  "$q",
  "$stateParams",
  "JobSchedulerService",
  "CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE",
  "CustomerEngagementAPI",
  "UserService",
  function (
    $q,
    $stateParams,
    JobSchedulerService,
    CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE,
    CustomerEngagementAPI,
    UserService
  ) {
    return {
      /**
       * Local constants to define which jobType and jobGroupType we are using for mass programming jobs.
       */
      JOB_TYPE_NAME: "GENERATE_CUSTOMER_ENGAGEMENT_EMAILS",
      //JOB_TYPE_NAME: 'THREAD_TEST',
      JOB_GROUP_TYPE_NAME: "CAMPAIGN_EMAILS",

      /**
       * The array of Customer Engagement Email Campaign job groups that are in the current context (one panel)
       */
      customerEngagementCampaignEmailJobGroups: [],

      /**
       * Creates a scheduled JobGroup to create the Customer Engagement Email Campaign
       * @returns {Promise} a promise, which will eventually resolve the JSON data of the JobGroup that is created.
       */
      generateEmailCampaign: function (campaignType, campaignName) {
        var _this = this;
        var deferred = $q.defer();
        var job = JobSchedulerService.getJobTemplate();
        var jobGroup = JobSchedulerService.getJobGroupTemplate();

        var jobGroupTypePromise = JobSchedulerService.getJobGroupTypeByName(
          this.JOB_GROUP_TYPE_NAME
        );
        var jobTypePromise = JobSchedulerService.getJobTypeByName(
          this.JOB_TYPE_NAME
        );

        $q.all([jobGroupTypePromise, jobTypePromise])
          .then(
            function (values) {
              jobGroup.SchedulerJobGroupTypeId = values[0].Id;
              job.SchedulerJobTypeId = values[1].Id;
              jobGroup.DealerId = $stateParams.dealer_id;
              //jobGroup.GroupClass = "DEBUG"

              let newJob = angular.copy(job);
              newJob.DealerId = jobGroup.DealerId;
              newJob.SelectedItem = campaignType;
              newJob.ReferenceDesc =
                values[1].JobTypeDesc + " : " + campaignName;
              delete newJob.RemoteJson;
              delete newJob.RemoteAction;
              jobGroup.SchedulerJobs.push(newJob);

              JobSchedulerService.createJobGroup(jobGroup)
                .then(
                  function (data) {
                    _this.customerEngagementCampaignEmailJobGroups.push(data);
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the status of that specific Job Group ID
       * @param jobID
       */
      getEmailCampaignStatus: function (jobGroupID) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupReportData(jobGroupID)
          .then(
            function (jobGroups) {
              UserService.dealerInfo
                .getPersonnel()
                .then(
                  function (persons) {
                    for (let jobGroup in jobGroups) {
                      jobGroups[jobGroup].FirstName = null;
                      jobGroups[jobGroup].LastName = null;
                      for (let person in persons) {
                        if (
                          jobGroups[jobGroup].UserId == persons[person].user.id
                        ) {
                          jobGroups[jobGroup].FirstName =
                            persons[person].user.first_name;
                          jobGroups[jobGroup].LastName =
                            persons[person].user.last_name;
                        }
                      }
                    }
                    deferred.resolve(jobGroups);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Customer Email Engagement Campaigns for the specified Dealer
       * @param dealer_id
       * @returns {*}
       */
      getAllEmailCampaignForDealer: function (dealer_id) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupTypeByName(_this.JOB_GROUP_TYPE_NAME)
          .then(
            function (data) {
              var jobGroupTypeID = data.Id;
              JobSchedulerService.getJobGroupsByDealerId(
                jobGroupTypeID,
                dealer_id
              )
                .then(
                  function (updatedJobGroups) {
                    //Apply the personnel names to the Job
                    UserService.dealerInfo
                      .getPersonnel()
                      .then(
                        function (persons) {
                          for (let jobGroup in updatedJobGroups) {
                            updatedJobGroups[jobGroup].FirstName = null;
                            updatedJobGroups[jobGroup].LastName = null;
                            for (let person in persons) {
                              if (
                                updatedJobGroups[jobGroup].UserId ==
                                persons[person].user.id
                              ) {
                                updatedJobGroups[jobGroup].FirstName =
                                  persons[person].user.first_name;
                                updatedJobGroups[jobGroup].LastName =
                                  persons[person].user.last_name;
                              }
                            }
                          }
                          deferred.resolve(updatedJobGroups);
                        },
                        function (error) {
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Get all Customer Email Engagement Campaigns for the specified Dealer
       * @param dealer_id
       * @returns {*}
       */
      getAllEmailCampaignJobsForDealer: function (dealer_id) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobTypeByName(_this.JOB_TYPE_NAME)
          .then(
            function (data) {
              var jobTypeID = data.Id;
              JobSchedulerService.getJobListByDealerId(jobTypeID, dealer_id)
                .then(
                  function (campaignEmailJobs) {
                    //Apply the personnel names to the Job
                    UserService.dealerInfo
                      .getPersonnel()
                      .then(
                        function (persons) {
                          for (let job in campaignEmailJobs) {
                            campaignEmailJobs[job].FirstName = null;
                            campaignEmailJobs[job].LastName = null;
                            for (let person in persons) {
                              if (
                                campaignEmailJobs[job].UserId ==
                                persons[person].user.id
                              ) {
                                campaignEmailJobs[job].FirstName =
                                  persons[person].user.first_name;
                                campaignEmailJobs[job].LastName =
                                  persons[person].user.last_name;
                              }
                            }
                          }
                          deferred.resolve(campaignEmailJobs);
                        },
                        function (error) {
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },
      getEmailCount: function (dealerId, campaignType) {
        var deferred = $q.defer();
        var _this = this;
        var params = {
          dealer_id: dealerId,
        };
        var requestBody = {
          EmailCampaignType: campaignType,
        };
        CustomerEngagementAPI.requestCount(
          params,
          requestBody,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getLastSentDates: function (dealerId) {
        var deferred = $q.defer();
        var params = {
          dealer_id: dealerId,
        };
        CustomerEngagementAPI.requestLastSentDates(
          params,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(error);
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
