App.factory("CustomReportsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.jobsApiUrl +
        "/api/v1/SchedulerJobReports/:dealers/:dealer_id/:customers/:customer_id/:users/:user_id/:report_id/:action",
      {},
      {
        newReport: { method: "GET", params: { report_id: "new" } },
        getAllReportsForDealer: {
          method: "GET",
          params: { dealers: "Dealers", dealer_id: "@dealer_id" },
          isArray: true,
        },
        getAllReportsForCustomer: {
          method: "GET",
          params: {
            dealers: "Dealers",
            dealer_id: "@dealer_id",
            customers: "customers",
            customer_id: "@customer_id",
          },
          isArray: true,
        },
        getAllReportsForUser: {
          method: "GET",
          params: {
            dealers: "Dealers",
            dealer_id: "@dealer_id",
            users: "users",
            user_id: "@user_id",
          },
          isArray: true,
        },
        getReportStatus: {
          method: "GET",
          params: { report_id: "@report_id", action: "status" },
        },
        showReport: { method: "GET", params: { report_id: "@report_id" } },
        runReport: {
          method: "GET",
          params: { report_id: "@report_id", action: "run" },
        },
        runOnDemandReport: {
          method: "POST",
          params: { action: "runOnDemand" },
        },
        createReport: { method: "POST" },
        updateReport: { method: "PUT", params: { report_id: "@report_id" } },
        deleteReport: { method: "DELETE", params: { report_id: "@report_id" } },
      }
    );
  },
]);
