import React, { Dispatch, SetStateAction } from "react";
import { ArmedSolidShieldLargeIcon, ArmingShieldLargeIcon } from "../Icons";
import Wrapper from "./BasicArmingWrapper";
import Areas from "./BasicArmingAreas";
import Area from "./BasicArmingArea";
import AreaButton from "./BasicArmingAreaButton";
import AreaButtonContainer from "./BasicArmingAreaButtonContainer";
import ShieldWrap from "./BasicArmingShieldWrap";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";
import { SingleAreaArming_controlSystem$key } from "./__generated__/SingleAreaArming_controlSystem.graphql";
import {
  SingleAreaArmingArmMutation,
  SingleAreaArmingArmMutationResponse,
} from "./__generated__/SingleAreaArmingArmMutation.graphql";
import {
  SingleAreaArmingDisarmMutation,
  SingleAreaArmingDisarmMutationResponse,
} from "./__generated__/SingleAreaArmingDisarmMutation.graphql";
import messages from "./messages";
import { useIntl } from "react-intl";
import { AreaArmedStatusEnum, ArmedStatusEnum } from "securecom-graphql/client";

const SingleAreaArming = (props: {
  controlSystem: SingleAreaArming_controlSystem$key;
  setStatusMessage: Function;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const system = useFragment(
    graphql`
      fragment SingleAreaArming_controlSystem on ControlSystem {
        id
        name
        isArmed
        isInAlarm
        isECP
        isDSC
        isSingleAreaSystem
        isHSASystem
        isHASystem
        isAreaSystem
        armedStatus
        areas(sort: { keys: ["number"], order: ASC }) {
          nodes {
            id
            name
            armedStatus
            isInAlarm
            number
          }
        }
      }
    `,
    props.controlSystem
  );

  const [
    disarmArea,
    isDisarmingArea,
  ] = useMutation<SingleAreaArmingDisarmMutation>(graphql`
    mutation SingleAreaArmingDisarmMutation($areaId: ID!) {
      disarmArea(areaId: $areaId) {
        ... on MultipleAreasDisarmedSuccessPayload {
          controlSystem {
            ...AreaArming_controlSystem
            areas(sort: { keys: ["number"], order: ASC }) {
              nodes {
                id
                name
                armedStatus
                isInAlarm
                number
                ...Area_area
              }
            }
            armedStatus
          }
        }
        ... on Error {
          type
        }
      }
    }
  `);

  const [
    armArea,
    isArmingArea,
  ] = useMutation<SingleAreaArmingArmMutation>(graphql`
    mutation SingleAreaArmingArmMutation($areaId: ID!) {
      armArea(areaId: $areaId) {
        ... on MultipleAreasArmedSuccessPayload {
          controlSystem {
            ...AreaArming_controlSystem
            areas(sort: { keys: ["number"], order: ASC }) {
              nodes {
                armedStatus
                ...Area_area
              }
            }
            armedStatus
          }
        }
        ... on BadZonesError {
          badZones {
            name
            number
          }
        }
        ... on Error {
          type
        }
      }
    }
  `);
  const intl = useIntl();
  const armedStatusMessage = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.armed).toLowerCase(),
    },
  };
  const disarmedStatusMessage = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.disarmed).toLowerCase(),
    },
  };
  const disarm = {
    ...messages.clickInstructions,
    values: {
      instruction: intl.formatMessage(messages.disarm).toLowerCase(),
    },
  };
  const arm = {
    ...messages.clickInstructions,
    values: {
      instruction: intl.formatMessage(messages.arm).toLowerCase(),
    },
  };
  const handleAreaMouseEnter = () => {
    if (system.armedStatus === ArmedStatusEnum.ARMED) {
      props.setStatusMessage(disarm);
    } else {
      props.setStatusMessage(arm);
    }
  };
  const handleAreaMouseLeave = () => {
    if (isDisarmingArea || isArmingArea) return;
    if (system.isArmed) {
      props.setStatusMessage(armedStatusMessage);
    } else if (!system.isArmed) {
      props.setStatusMessage(disarmedStatusMessage);
    }
  };

  return (
    <Wrapper>
      <Areas>
        <Area active count={1} inactive={false} index={0}>
          <AreaButton
            arming={isArmingArea}
            disarming={isDisarmingArea}
            armed={system.isArmed}
            onClick={() => {
              if (system.isArmed) {
                props.setStatusMessage(messages.disarming);
                disarmArea({
                  variables: {
                    areaId: system.areas.nodes[0].id,
                  },
                  onCompleted: (
                    response: SingleAreaArmingDisarmMutationResponse
                  ) => {
                    if (
                      response.disarmArea?.controlSystem?.areas?.nodes[0]
                        ?.armedStatus === AreaArmedStatusEnum.ARMED
                    ) {
                      props.setStatusMessage(armedStatusMessage);
                    } else {
                      props.setStatusMessage(disarmedStatusMessage);
                    }
                  },
                  onError: () => {
                    props.setStatusMessage(armedStatusMessage);
                  },
                });
              } else {
                props.setStatusMessage(messages.arming);
                armArea({
                  variables: {
                    areaId: system.areas.nodes[0].id,
                  },
                  onCompleted: (
                    response: SingleAreaArmingArmMutationResponse
                  ) => {
                    if (
                      response.armArea?.controlSystem?.areas?.nodes[0]
                        ?.armedStatus === AreaArmedStatusEnum.ARMED
                    ) {
                      props.setStatusMessage(armedStatusMessage);
                    } else {
                      props.setStatusMessage(disarmedStatusMessage);
                    }
                  },
                  onError: (error) => {
                    props.setStatusMessage(disarmedStatusMessage);
                  },
                });
              }
            }}
            disabled={isArmingArea || isDisarmingArea}
            onMouseEnter={handleAreaMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <ShieldWrap>
                {system.isArmed ? (
                  <ArmedSolidShieldLargeIcon valign="top" />
                ) : (
                  <ArmingShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
      </Areas>
    </Wrapper>
  );
};

export default SingleAreaArming;
