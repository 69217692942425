/**
 * @ngdoc service
 * @name App.factory:ProgrammingTemplateService
 *
 * @description
 * Manages the  mass panel programming jobs
 *
 */
App.factory("ProgrammingTemplateService", [
  "$q",
  "$stateParams",
  "JobSchedulerService",
  "UserService",
  "ProgrammingTemplateAPI",
  "DEALER_API_DEFAULTS",
  function (
    $q,
    $stateParams,
    JobSchedulerService,
    UserService,
    ProgrammingTemplateAPI,
    DEALER_API_DEFAULTS
  ) {
    return {
      xrDefaultsProgFormat: [
        { outer: "Communications", inner: "communication_paths" },
        { outer: "Communications", inner: "communication" },
        { outer: "RemoteOptions", inner: "remote_options" },
        { outer: "SystemOptions", inner: "system_options" },
        { outer: "SystemReports", inner: "system_reports" },
        { outer: "StatusList", inner: "status_list" },
        { outer: "BellOptions", inner: "bell_options" },
        { outer: "OutputOptions", inner: "output_options" },
        { outer: "LockoutCode", inner: "lockout_code" },
      ],

      newProgTpl: function (dealer_id, resource) {
        let deferred = $q.defer();

        ProgrammingTemplateAPI.newProgTpl(
          {
            resource: resource,
            dealer_id: dealer_id,
          }, //params

          function (newTpl) {
            //success

            deferred.resolve(newTpl);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      createProgTpl: function (panel, enabled, template, resource) {
        // template.template_data = {};
        let deferred = $q.defer();
        let _this = this;
        template.template_data = angular.toJson(
          _this.getTplData(panel, enabled)
        );
        template.receiver_communication =
          this.getReceiverCommunicationType(panel);
        ProgrammingTemplateAPI.createProgTpl(
          {
            resource: resource,
          },
          template,
          function (template) {
            deferred.resolve(template);
          },
          function (error) {
            // deferred.reject(error);
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Get all Mass Programming for the specified Dealer
       * @param dealer_id
       * @returns {*}
       */
      getProgTpls: function (dealer_id, resource) {
        let deferred = $q.defer();
        ProgrammingTemplateAPI.getProgTpls(
          {
            dealer_id: dealer_id,
            resource: resource,
          }, //params

          function (xrDefaults) {
            //success

            deferred.resolve(xrDefaults);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      updateProgTpl: function (dealer_id, panel, enabled, template, resource) {
        let deferred = $q.defer();
        let _this = this;
        template.template_data = angular.toJson(
          _this.getTplData(panel, enabled)
        );
        template.receiver_communication =
          this.getReceiverCommunicationType(panel);
        ProgrammingTemplateAPI.updateProgTpl(
          {
            dealer_id: dealer_id,
            resource: resource,
          },
          template,

          function (updatedTpl) {
            //success

            deferred.resolve(updatedTpl);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      destroyProgTpl: function (dealer_id, id, resource) {
        let deferred = $q.defer();
        ProgrammingTemplateAPI.destroyProgTpl(
          {
            dealer_id: dealer_id,
            id: id,
            resource: resource,
          }, //params

          function (deletedXRDefault) {
            //success

            deferred.resolve(deletedXRDefault);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Function to set the TemplateData field on the Job Group
       * @param panel
       */
      getTplData: function (panel, enabled) {
        let _this = this;

        let TemplateData = {};

        for (let i in _this.xrDefaultsProgFormat) {
          let outer = _this.xrDefaultsProgFormat[i].outer;
          let inner = _this.xrDefaultsProgFormat[i].inner;
          if (!TemplateData.hasOwnProperty(outer)) {
            TemplateData[outer] = {};
          }

          TemplateData[outer][inner] = inner;

          if (panel[inner]) {
            TemplateData[outer][inner] = panel[inner];
          } else {
            TemplateData[outer][inner] = null;
          }
        }

        //Cleanup for Communications paths that do not exist and putting them in the format that the API needs to be sent.

        if (TemplateData.hasOwnProperty("Communications")) {
          if (TemplateData.Communications.communication_paths) {
            for (let i in TemplateData.Communications.communication_paths) {
              let comPath = angular.copy(
                TemplateData.Communications.communication_paths[i]
              );
              //This is to kill off all of the Com Paths that have no enabled fields
              TemplateData.Communications.communication_paths[i] = {};

              if (enabled.Panel.hasOwnProperty("communication_paths")) {
                for (let enabledComPath in enabled.Panel.communication_paths) {
                  let includePath = false;
                  for (let field in enabled.Panel.communication_paths[
                    enabledComPath
                  ]) {
                    if (
                      field !== "isOpen" &&
                      field !== "number" &&
                      enabled.Panel.communication_paths[enabledComPath][field]
                    ) {
                      includePath = true;
                    }
                  }
                  if (enabledComPath == comPath.number && includePath) {
                    TemplateData.Communications.communication_paths[
                      i
                    ].communication_path = comPath;
                  }
                }
              }
            }
            let finalComPathData =
              TemplateData.Communications.communication_paths;
            TemplateData.Communications = finalComPathData;
          }
        }

        TemplateData.panel_id = panel.panel_id;
        TemplateData.panel_model = panel.panel_model;
        TemplateData.panel_version = panel.panel_version;
        TemplateData.concepts = panel.concepts;
        TemplateData.panel_hardware_class = panel.panel_hardware_class;
        TemplateData.enabled = enabled;

        return TemplateData;
      },

      /**
       * Function to apply calculate the receiver communication type for templates
       * @param {*} panel
       *
       */

      getReceiverCommunicationType: function (panel) {
        let _this = this;
        if (panel.communication_paths) {
          for (let i in panel.communication_paths) {
            if (
              panel.communication_paths[i].hasOwnProperty(
                "communication_path"
              ) &&
              panel.communication_paths[i].communication_path.comm_type == "L"
            )
              return "CELL";
          }
          return "NET";
        } else if (panel.communication) {
          if (
            panel.communication.com_type == "6" ||
            panel.communication.back_cell == "Y"
          ) {
            return "CELL";
          } else return "NET";
        } else return "NET";
      },

      /**
       * Function to apply Template Data to Panel object
       * @param {*} panel
       * @param {*} TemplateData
       */

      applyTplData: function (panel, TemplateData) {
        let _this = this;

        for (let i in _this.xrDefaultsProgFormat) {
          let outer = _this.xrDefaultsProgFormat[i].outer;
          let inner = _this.xrDefaultsProgFormat[i].inner;

          if (TemplateData.hasOwnProperty(outer)) {
            if (TemplateData[outer][inner]) {
              if (Object.keys(TemplateData[outer][inner]).length != 0) {
                panel[inner] = TemplateData[outer][inner];
              }
            }
            if (
              outer == "Communications" &&
              inner == "communication_paths" &&
              panel.panel_model == "XR550"
            ) {
              for (let i in TemplateData.Communications) {
                if (TemplateData.Communications[i].communication_path) {
                  if (!panel.hasOwnProperty("communication_paths")) {
                    panel.communication_paths = [];
                  }
                  for (let j in panel.communication_paths) {
                    if (
                      panel.communication_paths[j].number ===
                      TemplateData.Communications[i].communication_path.number
                    ) {
                      panel.communication_paths[j] =
                        TemplateData.Communications[i].communication_path;
                    }
                  }
                }
              }
              if (panel.hasOwnProperty("communication_paths")) {
                panel.communication_paths.isArray = true;
              }
            }
          }
        }
        panel.panel_hardware_class = TemplateData.panel_hardware_class;

        return panel;
      },
    };
  },
]);
