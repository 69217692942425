import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaAccountNumberField_xrArea$key } from "./__generated__/AreaAccountNumberField_xrArea.graphql";

export const AREA_ACCOUNT_NUMBER = "area-information-account-number-";

export const areaAreaAccountNumberFieldId = (number: string) =>
  AREA_ACCOUNT_NUMBER + number;

function AreaAccountNumberField() {
  const [{ number, accountNumber }, updateArea] =
    useAreaFragment<AreaAccountNumberField_xrArea$key>(
      graphql`
        fragment AreaAccountNumberField_xrArea on XrArea {
          number
          accountNumber
        }
      `
    );

  const fieldId = areaAreaAccountNumberFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Account Number"
      tooltip="The System sends this account number with Area messages and Events sent to the Central Station."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={accountNumber}
        required
        min={1}
        max={65535}
        maxLength={5}
        inlineHelp="1-65535"
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        onChange={({ target }) => {
          updateArea((recordProxy) => {
            recordProxy.setValue(target.value, "accountNumber");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaAccountNumberField;
