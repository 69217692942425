import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  SystemOptions,
  SystemType,
} from "securecom-graphql/client";
import {
  ChangedProgrammingConcepts,
  removeProgrammingConceptFromChangedProgrammingConcepts,
  useSetChangedProgrammingConcepts,
} from "../common/ChangedProgrammingConceptsContext";
import {
  ControlSystemContextProvider,
  useControlSystemFragment,
} from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { PanelContextProvider } from "../common/PanelContext";
import ProgrammingConceptForm from "../common/ProgrammingConceptForm";
import SystemOptionsBypassLimitField from "../common/SystemOptionsFields/SystemOptionsBypassLimitField";
import SystemOptionsCelsiusField from "../common/SystemOptionsFields/SystemOptionsCelsiusField";
import SystemOptionsClosingWaitField from "../common/SystemOptionsFields/SystemOptionsClosingWaitField";
import { SystemOptionsContextProvider } from "../common/SystemOptionsFields/SystemOptionsContext";
import SystemOptionsCrossZoneTimeField from "../common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import SystemOptionsDetectWirelessJammingField from "../common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import SystemOptionsEnableKeypadPanicKeysField from "../common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import SystemOptionsEntryDelay1Field from "../common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import SystemOptionsEntryDelay2Field from "../common/SystemOptionsFields/SystemOptionsEntryDelay2Field";
import SystemOptionsHoursFromGMTField from "../common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import SystemOptionsKeypadArmedLedField from "../common/SystemOptionsFields/SystemOptionsKeypadArmedLedField";
import SystemOptionsLatchedSupervisoryZonesField from "../common/SystemOptionsFields/SystemOptionsLatchedSupervisoryZonesField";
import SystemOptionsOccupiedPremisesField from "../common/SystemOptionsFields/SystemOptionsOccupiedPremisesField";
import SystemOptionsPowerFailDelayField from "../common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import SystemOptionsResetSwingerBypassField from "../common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import SystemOptionsSwingerBypassTripsField from "../common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import SystemOptionsSystemTypeField from "../common/SystemOptionsFields/SystemOptionsSystemTypeField";
import SystemOptionsTimeChangeField from "../common/SystemOptionsFields/SystemOptionsTimeChangeField";
import SystemOptionsUseBuiltIn1100WirelessField from "../common/SystemOptionsFields/SystemOptionsUseBuiltIn1100WirelessField";
import SystemOptionsUseFalseAlarmQuestionField from "../common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import SystemOptionsWeatherZipCodeField from "../common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import SystemOptionsWirelessAudiblesField from "../common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import SystemOptionsWirelessEncryption1100Field from "../common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import SystemOptionsWirelessEncryption1100PassphraseField from "../common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import SystemOptionsWirelessHouseCodeField from "../common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import SystemOptionsZoneActivityHoursField from "../common/SystemOptionsFields/SystemOptionsZoneActivityHoursField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  conceptId as xt75AreaConceptId,
  useSaveMutation as useSaveXT75Areas,
} from "./XT75AreaInformationProgrammingConceptForm";
import { XT75AreaInformationProgrammingConceptFormInline_controlSystem$key } from "./XT75AreaInformationProgrammingConceptForm/__generated__/XT75AreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import {
  XT75SystemOptionsProgrammingConceptFormInline_controlSystem$data,
  XT75SystemOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XT75SystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XT75SystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation,
} from "./__generated__/XT75SystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation.graphql";
import {
  XT75SystemOptionsProgrammingConceptFormSendSystemOptionsMutation,
  XT75SystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
} from "./__generated__/XT75SystemOptionsProgrammingConceptFormSendSystemOptionsMutation.graphql";
import { XT75SystemOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XT75SystemOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "System Options";
export const conceptId = "xt75-system-options";

export const getState = (
  controlSystem: XT75SystemOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XT75SystemOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          systemOptions {
            __typename
            id
            ... on Xt75SystemOptions {
              systemType
              instantArming
              closingWait
              resetSwingerBypass
              entryDelay1
              entryDelay2
              crossZoneTime
              powerFailDelay
              swingerBypassTrips
              zoneActivityHours
              timeChange
              hoursFromGMT
              houseCode
              instantArming
              keypadArmedLed
              latchedSupervisoryZones
              bypassLimit
              houseCode
              detectJam
              wirelessAudibles
              occupiedPremises
              useBuiltIn1100Wireless
              enableKeypadPanicKeys
              celsius
              keypadArmedLed
              useFalseAlarmQuestion
              panicSupervision
              weatherZipCode
              wirelessEncryption1100
              wirelessEncryption1100Passphrase
              useBuiltIn1100Wireless
            }
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XT75SystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation(
    $systemId: ID!
  ) {
    refreshSystemOptions(systemId: $systemId) {
      ... on RefreshSystemOptionsSuccessResponse {
        __typename
        controlSystem {
          ...XT75SystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XT75SystemOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XT75SystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            systemId: id,
          },
          onCompleted: (response) => {
            const { controlSystem, type } = response.refreshSystemOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "System Options Programming Retrieved From The System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSystemOptions: {
                    __typename: response.refreshSystemOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (type) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve System Options: ${hyphenScoreToTitleCase(
                      type
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve System Options",
                  });
                }
              }
              reject(type);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XT75SystemOptionsProgrammingConceptFormSendSystemOptionsMutation(
    $systemId: ID!
    $xt75SystemOptionsInput: Xt75SystemOptionsInput!
  ) {
    sendXt75SystemOptionsProgramming(
      systemId: $systemId
      xt75SystemOptionsInput: $xt75SystemOptionsInput
    ) {
      ... on SendSystemOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...XT75SystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSystemOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const syncAreas = (
  oldControlSystem: XT75SystemOptionsProgrammingConceptFormInline_controlSystem$data,
  newControlSystem: XT75SystemOptionsProgrammingConceptFormInline_controlSystem$data,
  setChangedProgrammingConcept: React.Dispatch<
    React.SetStateAction<ChangedProgrammingConcepts>
  >,
  saveAreas: (
    showAlerts?: boolean,
    isSavingAllListItems?: boolean
  ) => Promise<SaveErrors>
) => {
  const {
    panel: {
      systemOptions: { systemType: oldSystemType },
    },
  } = oldControlSystem;
  const {
    panel: {
      systemOptions: { systemType: newSystemType },
    },
  } = newControlSystem;

  if (oldSystemType !== newSystemType && newSystemType !== SystemType.AREA) {
    saveAreas().finally(() => {
      setChangedProgrammingConcept(
        removeProgrammingConceptFromChangedProgrammingConcepts(
          xt75AreaConceptId
        )
      );
    });
  }
};

const updateOriginalControlSystem = (
  response: XT75SystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
  originalControlSystemData: XT75SystemOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXt75SystemOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshSystemOptions: {
          __typename: "RefreshSystemOptionsSuccessPayload",
          controlSystem: getState(
            response.sendXt75SystemOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XT75SystemOptionsProgrammingConceptFormInline_controlSystem$key &
    XT75AreaInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XT75SystemOptionsProgrammingConceptFormSendSystemOptionsMutation>(
      saveMutation
    );
  const [saveAreas] = useSaveXT75Areas({
    controlSystem: props.controlSystem,
  });

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const originalControlSystem = useOriginalControlSystem();
  const setChangedProgrammingConcept = useSetChangedProgrammingConcepts();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise(async (resolve, reject) => {
        const {
          id: systemId,
          panel: { systemOptions },
        } = getState(props.controlSystem);

        if (systemOptions) {
          save({
            variables: {
              systemId,
              xt75SystemOptionsInput: {
                bypassLimit: systemOptions.bypassLimit,
                celsius: systemOptions.celsius,
                crossZoneTime: systemOptions.crossZoneTime,
                closingWait: systemOptions.closingWait,
                detectJam: systemOptions.detectJam,
                enableKeypadPanicKeys: systemOptions.enableKeypadPanicKeys,
                entryDelay1: systemOptions.entryDelay1,
                entryDelay2: systemOptions.entryDelay2,
                hoursFromGMT: systemOptions.hoursFromGMT,
                houseCode: systemOptions.houseCode,
                instantArming: systemOptions.instantArming,
                keypadArmedLed: systemOptions.keypadArmedLed,
                latchedSupervisoryZones: systemOptions.latchedSupervisoryZones,
                occupiedPremises: systemOptions.occupiedPremises,
                powerFailDelay: systemOptions.powerFailDelay,
                resetSwingerBypass: systemOptions.resetSwingerBypass,
                swingerBypassTrips: systemOptions.swingerBypassTrips,
                systemType:
                  systemOptions.systemType ?? SystemType.ALL_PERIMETER,
                timeChange: systemOptions.timeChange,
                useBuiltIn1100Wireless: systemOptions.useBuiltIn1100Wireless,
                useFalseAlarmQuestion: systemOptions.useFalseAlarmQuestion,
                weatherZipCode: systemOptions.weatherZipCode,
                wirelessAudibles: systemOptions.wirelessAudibles,
                wirelessEncryption1100: systemOptions.wirelessEncryption1100,
                wirelessEncryption1100Passphrase:
                  systemOptions.wirelessEncryption1100Passphrase,
                zoneActivityHours: systemOptions.zoneActivityHours,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXt75SystemOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);

                syncAreas(
                  getState(originalControlSystem),
                  getState(props.controlSystem),
                  setChangedProgrammingConcept,
                  saveAreas
                );

                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendXt75SystemOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXt75SystemOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readSystemOptionsTemplateData = (
  programmingTemplateConcepts: XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          systemType {
            included
            data
          }
          instantArming {
            included
            data
          }
          closingWait {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          entryDelay2 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          zoneActivityHours {
            included
            data
          }
          timeChange {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          houseCode {
            included
            data
          }
          detectJam {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          useBuiltIn1100Wireless {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          occupiedPremises {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          celsius {
            included
            data
          }
          latchedSupervisoryZones {
            included
            data
          }
          bypassLimit {
            included
            data
          }
          keypadArmedLed {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).systemOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSystemOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const systemOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "systemOptions",
      "SystemOptions"
    ) as unknown as RecordProxy<SystemOptions>;

    applyTemplateScalarDataToRecordProxy(
      systemOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XT75SystemOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XT75SystemOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          __typename
          id
          panel {
            ...SystemOptionsSystemTypeField_panel
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseSoftwareVersion_panel
            systemOptions {
              ... on Xt75SystemOptions {
                houseCode
                wirelessEncryption1100
              }
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsSystemTypeField_systemOptions
              ...SystemOptionsInstantArmingField_systemOptions
              ...SystemOptionsClosingWaitField_systemOptions
              ...SystemOptionsResetSwingerBypassField_systemOptions
              ...SystemOptionsEntryDelay1Field_systemOptions
              ...SystemOptionsEntryDelay2Field_systemOptions
              ...SystemOptionsCrossZoneTimeField_systemOptions
              ...SystemOptionsPowerFailDelayField_systemOptions
              ...SystemOptionsSwingerBypassTripsField_systemOptions
              ...SystemOptionsZoneActivityHoursField_systemOptions
              ...SystemOptionsTimeChangeField_systemOptions
              ...SystemOptionsHoursFromGMTField_systemOptions
              ...SystemOptionsLatchedSupervisoryZonesField_systemOptions
              ...SystemOptionsBypassLimitField_systemOptions
              ...SystemOptionsWirelessHouseCodeField_systemOptions
              ...SystemOptionsDetectWirelessJammingField_systemOptions
              ...SystemOptionsWirelessAudiblesField_systemOptions
              ...SystemOptionsOccupiedPremisesField_systemOptions
              ...SystemOptionsUseBuiltIn1100WirelessField_systemOptions
              ...SystemOptionsEnableKeypadPanicKeysField_systemOptions
              ...SystemOptionsCelsiusField_systemOptions
              ...SystemOptionsKeypadArmedLedField_systemOptions
              ...SystemOptionsUseFalseAlarmQuestionField_systemOptions
              ...SystemOptionsWeatherZipCodeField_systemOptions
              ...SystemOptionsWirelessEncryption1100Field_systemOptions
              ...SystemOptionsWirelessEncryption1100PassphraseField_systemOptions
            }
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem
        }
      `
    );

  const softwareVersion = Number(controlSystem.panel.softwareVersion);

  const {
    panel: {
      systemOptions,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <ControlSystemContextProvider controlSystem={controlSystem}>
      <PanelContextProvider panel={controlSystem.panel}>
        <SystemOptionsContextProvider systemOptions={systemOptions}>
          <ProgrammingConceptForm
            conceptId={conceptId}
            title="System Options"
            helpLink={`${programmingGuideUrl}#System%20Options`}
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
              controlSystem.panel.systemOptions
            )}
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <ProgrammingConceptForm.Fields>
                <SystemOptionsSystemTypeField />
                {/* <SystemOptionsInstantArmingField /> */}
                {/* TODO: Uncomoment above line if panel gets fixed
       Normally, when you're using H/S/A or A/P, you can use instant arm to bypass exit delay
       Because of a hardware bug, we're not able to turn it off on the XT75
        So we will always set it to "Y" for now and just hide the field in programming for now */}
                <SystemOptionsClosingWaitField />
                <SystemOptionsEntryDelay1Field />
                <SystemOptionsEntryDelay2Field />
                <SystemOptionsCrossZoneTimeField />
                <SystemOptionsPowerFailDelayField />
                <SystemOptionsSwingerBypassTripsField />
                <SystemOptionsResetSwingerBypassField />
                <SystemOptionsZoneActivityHoursField />
                <SystemOptionsTimeChangeField />
                <SystemOptionsHoursFromGMTField />
                <SystemOptionsLatchedSupervisoryZonesField />
                <SystemOptionsBypassLimitField />
                <SystemOptionsWirelessHouseCodeField />
                <SystemOptionsDetectWirelessJammingField />
                <SystemOptionsWirelessAudiblesField />
                <SystemOptionsUseBuiltIn1100WirelessField />
                <SystemOptionsEnableKeypadPanicKeysField />
                <SystemOptionsOccupiedPremisesField />
                <SystemOptionsCelsiusField />
                <SystemOptionsKeypadArmedLedField />
                <SystemOptionsUseFalseAlarmQuestionField />
                {softwareVersion <= 599 ? (
                  <SystemOptionsWeatherZipCodeField />
                ) : null}
                <SystemOptionsWirelessEncryption1100Field />
                <SystemOptionsWirelessEncryption1100PassphraseField />
              </ProgrammingConceptForm.Fields>
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </PanelContextProvider>
    </ControlSystemContextProvider>
  );
}
