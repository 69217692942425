import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const RemoteOptionsContext = React.createContext<any>({});

export function RemoteOptionsContextProvider(props: {
  remoteOptions: any;
  children: React.ReactNode;
}) {
  return (
    <RemoteOptionsContext.Provider value={props.remoteOptions}>
      {props.children}
    </RemoteOptionsContext.Provider>
  );
}

export function useRemoteOptionsFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const remoteOptions = React.useContext(RemoteOptionsContext);

  const data = useFragment(fragmentInput, remoteOptions as TKey);

  const { id } = useFragment(
    graphql`
      fragment RemoteOptionsContext_remoteOptions on RemoteOptions {
        id
      }
    `,
    remoteOptions as any
  );

  const update = (
    updater: (remoteOptions: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
