/**
 *
 * OutletIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const OutletIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <g>
            <g>
              <path
                d="M49.775,62h-35.49c-0.434,0-0.855-0.141-1.202-0.401C12.549,61.197,0,51.549,0,33.058
            C0,11.554,12.659,2.891,13.198,2.541C13.522,2.332,13.899,2,14.285,2h35.49c0.407,0,0.804,0.344,1.139,0.576
            C51.448,2.945,64,11.991,64,33.199c0,17.702-12.448,27.887-12.978,28.309C50.667,61.791,50.228,62,49.775,62z M15.001,58
            h34.025C51.233,55.988,60,46.902,60,33.09C60,16.184,51.219,8,49.094,6H14.936c-0.907,1-3.093,2.809-5.253,6.074
            C7.09,15.994,4,22.89,4,32.947C4,47.486,12.813,56.111,15.001,58z M38.379,52H25.616C24.177,52,23,50.936,23,49.489v-6.813
            C23,39.971,25.1,35,31.923,35c6.322,0,8.884,4.307,9.064,7.212C40.992,42.271,41,42.33,41,42.384v7.105
            C41,50.936,39.82,52,38.379,52z M27.006,48H37v-5.546C37,42.219,36.661,39,31.93,39c-4.478,0-4.887,3.082-4.923,3.711
            L27.006,48z M42,31c-1.104,0-2-0.896-2-2V16.415c0-1.104,0.896-2,2-2s2,0.896,2,2V29C44,30.104,43.104,31,42,31z M22,31
            c-1.104,0-2-0.896-2-2V16c0-1.104,0.896-2,2-2s2,0.896,2,2v13C24,30.104,23.104,31,22,31z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default OutletIcon;
