import { flow } from "fp-ts/function";
import { curry } from "ramda";
import { InferPromiseType } from "./types";

export const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

export async function sequence<A, B>(
  fn: (x: A) => Promise<B>,
  list: readonly A[]
) {
  const results: B[] = [];

  for (let item of list) {
    const result = await fn(item);
    results.push(result);
  }

  return results;
}

export const curriedSequence = curry(sequence);

type AsyncFind = (<A = any, B = any>(
  fn: (x: A) => Promise<B>,
  predicate: (x: B) => boolean,
  list: readonly A[]
) => B | undefined) &
  (<A = any, B = any>(
    fn: (x: A) => Promise<B>,
    predicate: (x: B) => boolean
  ) => (list: readonly A[]) => B | undefined) &
  (<A = any, B = any>(
    fn: (x: A) => Promise<B>
  ) => (
    predicate: (x: B) => boolean
  ) => (list: readonly A[]) => B | undefined) &
  (<A = any, B = any>(
    fn: (x: A) => Promise<B>
  ) => (predicate: (x: B) => boolean, list: readonly A[]) => B | undefined);
export const asyncFind: AsyncFind = curry(
  async <A, B>(
    fn: (x: A) => Promise<B>,
    predicate: (x: B) => boolean,
    list: readonly A[]
  ) => {
    for (let item of list) {
      const result = await fn(item);
      if (predicate(result)) {
        return result;
      }
    }

    return undefined;
  }
);

export const allSettledResultIsFulfilled = <A>(
  result: PromiseSettledResult<A>
): result is PromiseFulfilledResult<A> => result.status === "fulfilled";

export const allSettledResultIsRejected = (
  result: PromiseSettledResult<any>
): result is PromiseRejectedResult => result.status === "rejected";

/** Filters items in an array, but the predicate function can be asynchronous */
export const asyncFilter = async <A>(
  predicate: (item: A) => Promise<boolean>,
  array: A[]
) => {
  const fail = Symbol();

  return Promise.all(
    array.map(async (item) =>
      predicate(item)
        .then((shouldInclude) => (shouldInclude ? item : fail))
        .catch(() => fail)
    )
  ).then((items) => items.filter((item: any) => item !== fail));
};

type PromiseAll = <A extends readonly Promise<any>[]>(
  promises: A
) => A["length"] extends 1
  ? Promise<[A[number]]>
  : A["length"] extends 2
  ? Promise<[InferPromiseType<A[0]>, InferPromiseType<A[1]>]>
  : A["length"] extends 3
  ? Promise<
      [InferPromiseType<A[0]>, InferPromiseType<A[1]>, InferPromiseType<A[2]>]
    >
  : A["length"] extends 4
  ? Promise<
      [
        InferPromiseType<A[0]>,
        InferPromiseType<A[1]>,
        InferPromiseType<A[2]>,
        InferPromiseType<A[3]>
      ]
    >
  : A["length"] extends 5
  ? Promise<
      [
        InferPromiseType<A[0]>,
        InferPromiseType<A[1]>,
        InferPromiseType<A[2]>,
        InferPromiseType<A[3]>,
        InferPromiseType<A[4]>
      ]
    >
  : Promise<InferPromiseType<A[0]>[]>;
export const promiseAll: PromiseAll = (list: any) => Promise.all(list) as any;

type ThenFlow = (<A, B>(ab: (a: A) => B) => (a: Promise<A>) => B) &
  (<A, B, C>(ab: (a: A) => B, bc: (b: B) => C) => (a: Promise<A>) => C) &
  (<A, B, C, D>(
    ab: (a: A) => B,
    bc: (b: B) => C,
    cd: (c: C) => D
  ) => (a: Promise<A>) => D) &
  (<A, B, C, D, E>(
    ab: (a: A) => B,
    bc: (b: B) => C,
    cd: (c: C) => D,
    de: (d: D) => E
  ) => (a: Promise<A>) => E) &
  (<A, B, C, D, E, F>(
    ab: (a: A) => B,
    bc: (b: B) => C,
    cd: (c: C) => D,
    de: (d: D) => E,
    ef: (e: E) => F
  ) => (a: Promise<A>) => F) &
  (<A, B, C, D, E, F, G>(
    ab: (a: A) => B,
    bc: (b: B) => C,
    cd: (c: C) => D,
    de: (d: D) => E,
    ef: (e: E) => F,
    fg: (f: F) => G
  ) => (a: Promise<A>) => G) &
  (<A, B, C, D, E, F, G, H>(
    ab: (a: A) => B,
    bc: (b: B) => C,
    cd: (c: C) => D,
    de: (d: D) => E,
    ef: (e: E) => F,
    fg: (f: F) => G,
    gh: (g: G) => H
  ) => (a: Promise<A>) => H) &
  (<A, B, C, D, E, F, G, H, I>(
    ab: (a: A) => B,
    bc: (b: B) => C,
    cd: (c: C) => D,
    de: (d: D) => E,
    ef: (e: E) => F,
    fg: (f: F) => G,
    gh: (g: G) => H,
    hi: (h: H) => I
  ) => (a: Promise<A>) => I) &
  (<A, B, C, D, E, F, G, H, I, J>(
    ab: (a: A) => B,
    bc: (b: B) => C,
    cd: (c: C) => D,
    de: (d: D) => E,
    ef: (e: E) => F,
    fg: (f: F) => G,
    gh: (g: G) => H,
    hi: (h: H) => I,
    ij: (i: I) => J
  ) => (a: Promise<A>) => J);
export const thenFlow: ThenFlow = (...fns: any[]) => (a: any) =>
  // @ts-ignore - I couldn't trivially get this to work with the type definitions
  a.then(flow(...fns));

export const partitionFulfilled = <A>(results: PromiseSettledResult<A>[]) =>
  results.reduce(
    (acc, result) =>
      result.status === "rejected"
        ? { ...acc, rejected: [...acc.rejected, result.reason] }
        : { ...acc, resolved: [...acc.resolved, result.value] },
    {
      rejected: [] as any[],
      resolved: [] as A[],
    } as const
  );
