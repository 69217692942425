/**
 * @generated SignedSource<<b58b6829b80e355dd46c2416c5439b1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly areaInformations: ReadonlyArray<{
    readonly autoArmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly autoDisarmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly badZonesOption: {
      readonly data: BadZonesOption | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
  } | null> | null;
  readonly " $fragmentType": "TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "83c5b8419ce2bfb689753eefbced23c1";

export default node;
