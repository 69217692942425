import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Area,
  ProgrammingTemplate,
  Zone,
  ZoneType,
} from "securecom-graphql/client";
import { useAreaList } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useTemplateContext } from "../TemplateContext";
import { TwentyFourHourZoneTypes } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationAreaField_zone$key } from "./__generated__/ZoneInformationAreaField_zone.graphql";

export const zoneInformationAreaFieldId = (number: string) =>
  `zone-information-area-${number}`;

function ZoneInformationAreaField() {
  const [{ number, area, type, isECP, id: zoneInformationId }] =
    useZoneInformationFragment<ZoneInformationAreaField_zone$key>(
      graphql`
        fragment ZoneInformationAreaField_zone on Zone {
          number
          id
          type
          isECP
          area {
            id
            number
            name
          }
        }
      `
    );

  const fieldId = zoneInformationAreaFieldId(String(number));

  const disabled =
    [...TwentyFourHourZoneTypes, ZoneType.ARMING, ZoneType.TAMPER].includes(
      type as ZoneType
    ) || isECP;

  const { templateId } = useTemplateContext();
  const areaList = useAreaList();
  const relayEnv = useRelayEnvironment();
  const { isEditing: templateIsEditing } = useTemplateContext();

  const onChangeClicked = React.useRef(false);

  React.useEffect(() => {
    let areaNumber = area ? area.number : areaList?.[0]?.number ?? null;
    if (templateId && !onChangeClicked.current) {
      relayEnv.commitUpdate((store) => {
        const template = store.get<ProgrammingTemplate>(templateId);
        areaNumber = String(
          template
            ?.getLinkedRecord("concepts")
            ?.getLinkedRecords("zoneInformations")
            .find((zone) => Number(zone.getValue("number")) === Number(number))
            ?.getLinkedRecord("area")
            ?.getValue("data") ?? ""
        );
      });
    }
    const selectedId = areaList.find(
      (areaInList) => Number(areaInList.number) === Number(areaNumber)
    );
    relayEnv.commitUpdate((store) => {
      const zone = store.get<Zone>(zoneInformationId);
      const area = store.get<Area>(selectedId?.id ?? "");
      if (zone && area) {
        zone.setLinkedRecord(area, "area");
      }
    });
  }, [area, areaList, number, relayEnv, templateId, zoneInformationId]);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Area"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={area ? area.number : areaList?.[0]?.number ?? null}
        disabled={disabled}
        required={templateIsEditing ? false : true}
        onChange={({ target }) => {
          onChangeClicked.current = true;
          const selectedId = areaList.find(
            (areaInList) => areaInList.number === target.value
          );
          relayEnv.commitUpdate((store) => {
            const zone = store.get<Zone>(zoneInformationId);
            const area = store.get<Area>(selectedId?.id ?? "");
            if (zone && area) {
              zone.setLinkedRecord(area, "area");
            }
          });
        }}
      >
        {!area && !areaList?.[0] && (
          <Select.Option value={undefined} key={"NONE"}></Select.Option>
        )}
        {areaList.map((area) => (
          <Select.Option key={area.id} value={area.number}>
            {area.name}
          </Select.Option>
        ))}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationAreaField;
