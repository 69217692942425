import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { BellActions } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsAuxiliary1BellActionField_bellOptions$key } from "./__generated__/BellOptionsAuxiliary1BellActionField_bellOptions.graphql";

export const bellOptionsAuxiliary1BellActionFieldId = () =>
  "bell-options-auxiliary1-bell-action";

function BellOptionsAuxiliary1BellActionField() {
  const [{ auxiliary1BellAction }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsAuxiliary1BellActionField_bellOptions$key>(
      graphql`
        fragment BellOptionsAuxiliary1BellActionField_bellOptions on BellOptions {
          auxiliary1BellAction
        }
      `
    );

  const fieldId = bellOptionsAuxiliary1BellActionFieldId();
  const softwareVersion = useSoftwareVersion();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Auxiliary 1 Bell Action"
      tooltip="Set the cadence the Bell Output uses for alarms on Auxiliary 1 type Zones."
    >
      <Select
        id={fieldId}
        value={auxiliary1BellAction}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(target.value, "auxiliary1BellAction");
          });
        }}
      >
        <Select.Option value={BellActions.STEADY}>Steady</Select.Option>
        <Select.Option value={BellActions.PULSE}>Pulse</Select.Option>
        <Select.Option value={BellActions.TEMPORAL}>Temporal</Select.Option>
        <Select.Option value={BellActions.NONE}>None</Select.Option>
        {panelVersionGTOE(183, softwareVersion) && (
          <>
            <Select.Option value={BellActions.TEMPORAL_3}>
              Temporal 3
            </Select.Option>
            <Select.Option value={BellActions.TEMPORAL_4}>
              Temporal 4
            </Select.Option>
          </>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsAuxiliary1BellActionField;
