import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { getEntries } from "common/utils/universal/object";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  idAsString,
  OutputOptions,
  toUnprogrammedFavoriteId,
  toUnprogrammedOutputId,
} from "securecom-graphql/client";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import OutputOptionsArmedOutputField from "../common/OutputOptionsFields/OutputOptionsArmedOutputField";
import OutputOptionsCommunicationFailOutputField from "../common/OutputOptionsFields/OutputOptionsCommunicationFailOutputField";
import { OutputOptionsContextProvider } from "../common/OutputOptionsFields/OutputOptionsContext";
import OutputOptionsCoolSaverTemperatureField from "../common/OutputOptionsFields/OutputOptionsCoolSaverTemperatureField";
import OutputOptionsCutoffOutputsField from "../common/OutputOptionsFields/OutputOptionsCutoffOutputsField";
import OutputOptionsHeatSaverTemperatureField from "../common/OutputOptionsFields/OutputOptionsHeatSaverTemperatureField";
import OutputOptionsOutputCutoffTimeField from "../common/OutputOptionsFields/OutputOptionsOutputCutoffTimeField";
import OutputOptionsRemoteArmingOutputField from "../common/OutputOptionsFields/OutputOptionsRemoteArmingOutputField";
import { SystemOptionsContextProvider } from "../common/SystemOptionsFields/SystemOptionsContext";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$data,
  TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { TakeoverPanelOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TakeoverPanelOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation,
} from "./__generated__/TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation.graphql";
import {
  TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsSendMutation,
  TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsSendMutation$data,
} from "./__generated__/TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsSendMutation.graphql";
import { TakeoverPanelOutputOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/TakeoverPanelOutputOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Output Options";
export const conceptId = "takeover-panel-output-options";

export const getState = (
  controlSystem: TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          outputOptions {
            id
            panicAlarmOutput {
              id
              number
              formattedNumber
            }
            ambushOutput {
              id
              number
              formattedNumber
            }
            entryDelayOutput {
              id
              number
              formattedNumber
            }
            beginExitOutput {
              id
              number
              formattedNumber
            }
            endExitOutput {
              id
              number
              formattedNumber
            }
            readyOutput {
              id
              number
              formattedNumber
            }
            lateToCloseOutput {
              id
              number
              formattedNumber
            }
            disarmedOutput {
              id
              number
              formattedNumber
            }
            armAlarmOutput {
              id
              number
              formattedNumber
            }
            heatSaverTemperature
            armedOutput {
              id
              number
              formattedNumber
            }
            coolSaverTemperature
            armedHomeOrPerimeterOutput {
              id
              number
              formattedNumber
            }
            armedAllOrAwayOutput {
              id
              number
              formattedNumber
            }
            armedSleepOutput {
              id
              number
              formattedNumber
            }
            carbonMonoxideOutput {
              id
              number
              formattedNumber
            }
            zoneMonitorOutput {
              id
              number
              formattedNumber
            }
            remoteArmingOutput {
              id
              number
              formattedNumber
            }
            burglaryOutput {
              id
              number
              formattedNumber
            }
            cutoffOutputs
            outputCutoffTime
            outputCutoffTimeMin
            outputCutoffTimeMax
            communicationFailOutput {
              id
              number
              formattedNumber
            }
            fireAlarmOutput {
              id
              number
              formattedNumber
            }
            fireTroubleOutput {
              id
              number
              formattedNumber
            }
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshOutputOptions(systemId: $id) {
      ... on RefreshOutputOptionsSuccessResponse {
        __typename
        controlSystem: system {
          ...TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshOutputOptionsErrorResponse {
        error {
          type
        }
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshOutputOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Output Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshOutputOptions: {
                    __typename: response.refreshOutputOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Output Options: ${hyphenScoreToTitleCase(
                      error.type ?? "UNKNOWN_ERROR"
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Output Options",
                  });
                }
              }
              reject(error?.type);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsSendMutation(
    $systemId: ID!
    $outputOptions: OutputOptionsInput!
  ) {
    sendOutputOptions(systemId: $systemId, outputOptions: $outputOptions) {
      ... on SendOutputOptionsSuccessPayload {
        __typename
        controlSystem {
          id
          ...TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendOutputOptionsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsSendMutation$data,
  originalControlSystemData: TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendOutputOptions.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshOutputOptions: {
          __typename: "RefreshOutputOptionsSuccessPayload",
          controlSystem: getState(response.sendOutputOptions.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TakeoverPanelOutputOptionsProgrammingConceptFormOutputOptionsSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { outputOptions },
        } = getState(props.controlSystem);
        if (outputOptions) {
          save({
            variables: {
              systemId,
              outputOptions: {
                armedOutput: outputOptions.armedOutput.formattedNumber,
                burglaryOutput: outputOptions.burglaryOutput.formattedNumber,
                cutoffOutputs: outputOptions.cutoffOutputs,
                outputCutoffTime: outputOptions.outputCutoffTime.toString(),
                communicationFailOutput:
                  outputOptions.communicationFailOutput.formattedNumber,
                fireAlarmOutput: outputOptions.fireAlarmOutput.formattedNumber,
                fireTroubleOutput:
                  outputOptions.fireTroubleOutput.formattedNumber,
                panicAlarmOutput:
                  outputOptions.panicAlarmOutput.formattedNumber,
                ambushOutput: outputOptions.ambushOutput.formattedNumber,
                entryDelayOutput:
                  outputOptions.entryDelayOutput.formattedNumber,
                beginExitOutput: outputOptions.beginExitOutput.formattedNumber,
                endExitOutput: outputOptions.endExitOutput.formattedNumber,
                readyOutput: outputOptions.readyOutput.formattedNumber,
                lateToCloseOutput:
                  outputOptions.lateToCloseOutput.formattedNumber,
                disarmedOutput: outputOptions.disarmedOutput.formattedNumber,
                armAlarmOutput: outputOptions.armAlarmOutput.formattedNumber,
                heatSaverTemperature: outputOptions.heatSaverTemperature,
                coolSaverTemperature: outputOptions.coolSaverTemperature,
                armedHomeOrPerimeterOutput:
                  outputOptions.armedHomeOrPerimeterOutput.formattedNumber,
                armedAllOrAwayOutput:
                  outputOptions.armedAllOrAwayOutput.formattedNumber,
                armedSleepOutput:
                  outputOptions.armedSleepOutput.formattedNumber,
                carbonMonoxideOutput:
                  outputOptions.carbonMonoxideOutput.formattedNumber,
                zoneMonitorOutput:
                  outputOptions.zoneMonitorOutput.formattedNumber,
                remoteArmingOutput:
                  outputOptions.remoteArmingOutput.formattedNumber,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendOutputOptions.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Output Options Programming Saved to the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendOutputOptions.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendOutputOptions.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readOutputOptionsTemplateData = (
  programmingTemplateConcepts: TakeoverPanelOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        outputOptions {
          included
        }
        nonOutputs: outputOptions {
          cutoffOutputs {
            included
            data
          }
          outputCutoffTime {
            included
            data
          }
          heatSaverTemperature {
            included
            data
          }
          coolSaverTemperature {
            included
            data
          }
        }
        outputs: outputOptions {
          armedOutput {
            included
            data
          }
          burglaryOutput {
            included
            data
          }
          communicationFailOutput {
            included
            data
          }
          fireAlarmOutput {
            included
            data
          }
          fireTroubleOutput {
            included
            data
          }
          panicAlarmOutput {
            included
            data
          }
          ambushOutput {
            included
            data
          }
          entryDelayOutput {
            included
            data
          }
          beginExitOutput {
            included
            data
          }
          endExitOutput {
            included
            data
          }
          readyOutput {
            included
            data
          }
          lateToCloseOutput {
            included
            data
          }
          disarmedOutput {
            included
            data
          }
          armAlarmOutput {
            included
            data
          }
          armedHomeOrPerimeterOutput {
            included
            data
          }
          armedAllOrAwayOutput {
            included
            data
          }
          armedSleepOutput {
            included
            data
          }
          carbonMonoxideOutput {
            included
            data
          }
          zoneMonitorOutput {
            included
            data
          }
          remoteArmingOutput {
            included
            data
          }
          telephoneTroubleOutput {
            included
            data
          }
          deviceFailOutput {
            included
            data
          }
          sensorResetOutput {
            included
            data
          }
          closingWaitOutput {
            included
            data
          }
          supervisoryAlarmOutput {
            included
            data
          }
          lockdownOutput {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ) ?? { outputOptions: { included: false } };

export function applyTemplateData(
  programmingTemplateConcepts: TakeoverPanelOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const systemId = controlSystemRecordProxy.getValue("id");
  const templateData = readOutputOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData?.outputOptions?.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const outputOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "outputOptions",
      "OutputOptions"
    ) as unknown as RecordProxy<OutputOptions>;

    if (templateData.nonOutputs) {
      applyTemplateScalarDataToRecordProxy(
        outputOptionsRecordProxy,
        templateData.nonOutputs
      );
    }

    if (templateData.outputs) {
      getEntries(templateData.outputs).forEach(([fieldKey, fieldData]) => {
        if (
          typeof fieldData === "object" &&
          fieldData?.included &&
          fieldData.data
        ) {
          const isFavorite = fieldData.data.charAt(0).toUpperCase() === "F";
          const outputOptionId = idAsString(
            isFavorite
              ? toUnprogrammedFavoriteId(systemId, fieldData.data)
              : toUnprogrammedOutputId(systemId, fieldData.data)
          );
          const outputOptionRecordProxy =
            store.get(idAsString(outputOptionId)) ??
            store.create(
              outputOptionId,
              isFavorite ? "UnprogrammedFavorite" : "UnprogrammedOutput"
            );
          outputOptionRecordProxy.setValue(fieldData.data, "number");
          outputOptionRecordProxy.setValue(fieldData.data, "formattedNumber");
          outputOptionsRecordProxy.setLinkedRecord(
            outputOptionRecordProxy,
            fieldKey
          );
        }
      });
    }
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<TakeoverPanelOutputOptionsProgrammingConceptForm_controlSystem$key>(graphql`
      fragment TakeoverPanelOutputOptionsProgrammingConceptForm_controlSystem on ControlSystem {
        supportsZwave
        panel {
          softwareVersion
          hardwareModel
          helpFiles {
            programmingGuideUrl
            installGuideUrl
          }
          ...PanelContext_panel
          ...PanelContextUseSoftwareVersion_panel
          ...PanelContextUseHardwareModel_panel
          outputOptions {
            ...OutputOptionsContext_outputOptions
            ...OutputOptionsCutoffOutputsField_outputOptions
            ...OutputOptionsOutputCutoffTimeField_outputOptions
            ...OutputOptionsCommunicationFailOutputField_outputOptions
            ...OutputOptionsArmedOutputField_outputOptions
            ...OutputOptionsRemoteArmingOutputField_outputOptions
            ...OutputOptionsHeatSaverTemperatureField_outputOptions
            ...OutputOptionsCoolSaverTemperatureField_outputOptions
          }
          systemOptions {
            ...SystemOptionsContext_systemOptions
            ...SystemOptionsContextSystemType_systemOptions
            ...SystemOptionsContextHouseCode_systemOptions
          }
        }
      }
    `);

  const { panel } = controlSystem;
  const {
    outputOptions,
    systemOptions,
    helpFiles: { programmingGuideUrl },
  } = panel;

  return (
    <PanelContextProvider panel={panel}>
      <OutputOptionsContextProvider outputOptions={outputOptions}>
        <SystemOptionsContextProvider systemOptions={systemOptions}>
          <ProgrammingConceptForm
            conceptId={conceptId}
            title={title}
            helpLink={`${programmingGuideUrl}#Output%20Options`}
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(outputOptions)}
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <ProgrammingConceptForm.Fields>
                <OutputOptionsCutoffOutputsField />
                <OutputOptionsOutputCutoffTimeField />
                <OutputOptionsCommunicationFailOutputField />
                <OutputOptionsArmedOutputField />
                <OutputOptionsRemoteArmingOutputField />
                {controlSystem.supportsZwave && (
                  <>
                    <OutputOptionsHeatSaverTemperatureField />
                    <OutputOptionsCoolSaverTemperatureField />
                  </>
                )}
              </ProgrammingConceptForm.Fields>
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </OutputOptionsContextProvider>
    </PanelContextProvider>
  );
}
