/**
 * @generated SignedSource<<29808b4e89e63fa439ee70b9091620bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagingSetupContext_messagingSetup$data = {
  readonly id: string;
  readonly " $fragmentType": "MessagingSetupContext_messagingSetup";
};
export type MessagingSetupContext_messagingSetup$key = {
  readonly " $data"?: MessagingSetupContext_messagingSetup$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagingSetupContext_messagingSetup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagingSetupContext_messagingSetup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MessagingSetup",
  "abstractKey": null
};

(node as any).hash = "1e23a6264a11659ce302f90e0568cc06";

export default node;
