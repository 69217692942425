/**
 * @generated SignedSource<<c953dd08ffeb0c13e37c22063ad44552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommPathNumberField_communicationPath$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly number: number;
  readonly " $fragmentType": "CommPathNumberField_communicationPath";
};
export type CommPathNumberField_communicationPath$key = {
  readonly " $data"?: CommPathNumberField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathNumberField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathNumberField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "948c0a4d426b16dcd925c76d57830ead";

export default node;
