/**
 * @generated SignedSource<<5174197a764293a9542aa0fd4140ba5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XTOutputInformationProgrammingConceptFormInline_output$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly isWireless: boolean;
  readonly name: string;
  readonly number: number;
  readonly outputSupervision: OutputSupervision | null;
  readonly realTimeStatus: boolean | null;
  readonly serialNumber: string | null;
  readonly tripWithPanelBell: boolean;
  readonly " $fragmentType": "XTOutputInformationProgrammingConceptFormInline_output";
};
export type XTOutputInformationProgrammingConceptFormInline_output$key = {
  readonly " $data"?: XTOutputInformationProgrammingConceptFormInline_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTOutputInformationProgrammingConceptFormInline_output">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTOutputInformationProgrammingConceptFormInline_output"
};

(node as any).hash = "ce730bb7a152c43bc0b701e3b08aa93a";

export default node;
