import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  margin-bottom: 4rem;
`;

interface Props {
  message?: string;
}

export function GenericPageFallback(props: Props) {
  return (
    <Container>
      {props.message && <Message>{props.message}</Message>}
      <div className="csspinner traditional" />
    </Container>
  );
}
