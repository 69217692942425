/**
 * @generated SignedSource<<2960a322b3ab74fe43f0ecd64af51401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CentralStationAutomationIntegrationStatus = "CONNECTED" | "NOT_CONNECTED";
import { FragmentRefs } from "relay-runtime";
export type FormIntegrationBaseFragment$data = {
  readonly centralStationIntegrationNames: ReadonlyArray<{
    readonly __typename: "CentralStationIntegrationName";
    readonly displayName: string;
    readonly value: string;
  }>;
  readonly dealer: {
    readonly centralStationAutomationIntegrations: ReadonlyArray<{
      readonly __typename: "CentralStationAutomationIntegration";
      readonly emergencyContactsEnabled: boolean;
      readonly id: string;
      readonly name: string;
      readonly status: CentralStationAutomationIntegrationStatus;
      readonly systemTestsEnabled: boolean;
    }>;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "FormIntegrationBaseFragment";
};
export type FormIntegrationBaseFragment$key = {
  readonly " $data"?: FormIntegrationBaseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormIntegrationBaseFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "dealerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormIntegrationBaseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CentralStationIntegrationName",
      "kind": "LinkedField",
      "name": "centralStationIntegrationNames",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "dealerId"
        }
      ],
      "concreteType": "Dealer",
      "kind": "LinkedField",
      "name": "dealer",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CentralStationAutomationIntegration",
          "kind": "LinkedField",
          "name": "centralStationAutomationIntegrations",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emergencyContactsEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "systemTestsEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "d8301328fb939339540d879041770aa5";

export default node;
