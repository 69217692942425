/**
 * @ngdoc directive
 * @name directive:capitalize
 * @function
 *
 * @description
 * Capitalizes the ngModel on the same input directive as this tag
 */
App.directive("daCapitalize", function () {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, modelCtrl) {
      var capitalize = function (inputValue) {
        if (angular.isUndefined(inputValue) || inputValue == null)
          return inputValue;

        var capitalized = inputValue.toUpperCase();
        if (capitalized !== inputValue) {
          // see where the cursor is before the update so that we can set it back
          var selection = element[0].selectionStart;
          modelCtrl.$setViewValue(capitalized);
          modelCtrl.$render();
          // set back the cursor after rendering
          element[0].selectionStart = selection;
          element[0].selectionEnd = selection;
        }
        return capitalized;
      };
      modelCtrl.$parsers.push(capitalize);
      capitalize(scope[attrs.ngModel]); // capitalize initial value
    },
  };
});
