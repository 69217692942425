/**
 * @generated SignedSource<<f4f86f8b2a82edfda2e642486bfab6b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay$data = {
  readonly armDisarmDisplayKeypads: string;
  readonly " $fragmentType": "MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay";
};
export type MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay$key = {
  readonly " $data"?: MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armDisarmDisplayKeypads",
      "storageKey": null
    }
  ],
  "type": "MenuDisplay",
  "abstractKey": null
};

(node as any).hash = "cb11475287c86227275fd6ea007e7e6f";

export default node;
