/**
 * @generated SignedSource<<3a3cf621d94408a3b174beaa877f24fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationNumberField_zone$data = {
  readonly competitorWireless: boolean | null;
  readonly id: string;
  readonly isECP: boolean;
  readonly isNew: boolean;
  readonly number: string;
  readonly wireless: boolean;
  readonly " $fragmentType": "ZoneInformationNumberField_zone";
};
export type ZoneInformationNumberField_zone$key = {
  readonly " $data"?: ZoneInformationNumberField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationNumberField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationNumberField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isECP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competitorWireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "6f89be0ee7052b9dac098cc36147aea7";

export default node;
