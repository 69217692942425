/**
 * @generated SignedSource<<a61a904a640c48fac4c837a7d51f3014>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTFullProgrammingContainer_templateStatus_controlSystem$data = {
  readonly id: string;
  readonly templatePreProgrammingJobStatus: string | null;
  readonly " $fragmentType": "XTFullProgrammingContainer_templateStatus_controlSystem";
};
export type XTFullProgrammingContainer_templateStatus_controlSystem$key = {
  readonly " $data"?: XTFullProgrammingContainer_templateStatus_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTFullProgrammingContainer_templateStatus_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./XTFullProgrammingContainer_templateStatus_controlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "XTFullProgrammingContainer_templateStatus_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatePreProgrammingJobStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "3fdc5284c2db2c56ae1c4e0a80ea1a6d";

export default node;
