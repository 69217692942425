/**
 * @generated SignedSource<<4052fa32a0cac719d298e9ea4a3297ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75TemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75BellOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75DeviceInformationTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75KeyfobsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75NetworkOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75OutputOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75SystemOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts" | "XT75ZoneInformationTemplateDataInline_xt75ProgrammingTemplateConcepts">;
  readonly " $fragmentType": "XT75TemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75TemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75TemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75TemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75TemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "24fb9570cb925313837450cff9041677";

export default node;
