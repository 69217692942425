import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMutation } from "react-relay/hooks";
import { useCreateNotification } from "../EntryPointContext";
import {
  FieldErrorMessage,
  FieldInputContainer,
  FieldLabel,
  FieldRow,
  NaturalNumberInput,
  Switch,
} from "../FormFields";
import Accordion from "../Layout/Accordion";

import {
  DeleteConfirmModalBody,
  DeleteConfirmModalOverlay,
} from "components/Modal/ConfirmModal";
import { ControlSystemCommType, OsdpOrWiegand } from "securecom-graphql/client";
import { joinSpaced } from "utils/string";
import styles from "./DoorForm.module.css";
import {
  DoorState,
  OnboardOutputState,
  SiteControlSystemFormDispatch,
  SiteControlSystemFormState,
} from "./ExistingSiteControlSystemForm";
import UnavailableOutputWarningModal from "./UnavailableOutputWarningModal";
import {
  DoorFormDeactivateMutation,
  DoorFormDeactivateMutation$data,
} from "./__generated__/DoorFormDeactivateMutation.graphql";

function FormSections({ children }: { children: React.ReactNode }) {
  return <div className={styles["form-sections"]}>{children}</div>;
}

FormSections.Section = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles["form-section"]}>{children}</div>;
};

const deactivateDoorMutation = graphql`
  mutation DoorFormDeactivateMutation($doorId: ID!) {
    deactivateSiteDoor(doorId: $doorId) {
      ... on FailedToDeactivateSiteDoorError {
        errorType: type
      }
      ... on NotFoundError {
        errorType: type
      }
      ... on SiteDoor {
        id
        name
        number
        active
        strikeTime
        strikeDelay
        doorForce
        fireZone
        fireExit
        includeInLockdown
        doorPropTime
        osdpOrWiegand
        doorSensor
        requestToExit
        unlockOnRex
        buzzer
        led
        site {
          ...SiteControlSystemsSection_site
        }
        controlSystem {
          ...ExistingSiteControlSystemForm_siteControlSystem
        }
      }
    }
  }
`;

export function DoorForm({
  state,
  dispatch,
  hasFireZone,
  doorIndex,
  initiallyFocusName = false,
  initiallyOpen = true,
  canAutoFocus = true,
  onboardOutputs,
  setOnboardOutputs,
  intendedUse,
  alarmPanelIntegration,
  alarmPanelIntegrationIsDisabled,
  onboardOutput1Available,
  onboardOutput2Available,
  hasPendingChanges,
  isCellOnlyPanel,
  commType,
}: {
  state: DoorState;
  dispatch: SiteControlSystemFormDispatch;
  hasFireZone: boolean;
  doorIndex: number;
  initiallyFocusName?: boolean;
  initiallyOpen?: boolean;
  canAutoFocus?: boolean;
  onboardOutputs?: OnboardOutputState;
  setOnboardOutputs?: React.Dispatch<React.SetStateAction<OnboardOutputState>>;
  intendedUse?: string;
  alarmPanelIntegration: SiteControlSystemFormState["alarmPanelIntegration"];
  alarmPanelIntegrationIsDisabled: boolean;
  onboardOutput1Available: boolean;
  onboardOutput2Available: boolean;
  hasPendingChanges: boolean;
  isCellOnlyPanel: boolean;
  commType: string;
}) {
  const [deactivateSiteDoor, isDeactivating] =
    useMutation<DoorFormDeactivateMutation>(deactivateDoorMutation);
  const onboardOutputsRef = React.useRef(onboardOutputs);

  const nameField = React.useRef<HTMLInputElement | null>(null);

  const label = (key: string) => `door::${state.id}::${key}`;

  const canEdit = !!state.name && !isDeactivating;

  const [confirmingDeactivate, setConfirmingDeactivate] = React.useState(false);

  React.useEffect(() => {
    if (canAutoFocus && initiallyFocusName && nameField.current) {
      nameField.current.focus();

      nameField.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [canAutoFocus]); // eslint-disable-line

  const createNotification = useCreateNotification();

  const [modalOpenForOutput, setModalOpenForOutput] = React.useState<
    1 | 2 | null
  >(null);

  return (
    <>
      <Accordion
        initiallyOpen={initiallyOpen}
        header={
          <Accordion.Header>
            <Accordion.Toggleable>
              <span>
                {intendedUse === "ELEVATOR" ? (
                  state.name ? (
                    <strong>{state.name}</strong>
                  ) : (
                    <i>Unnamed Elevator</i>
                  )
                ) : state.name ? (
                  <strong>{state.name}</strong>
                ) : (
                  <i>Unused Door</i>
                )}
              </span>
            </Accordion.Toggleable>

            {state.active &&
              state.name &&
              intendedUse === "DOOR_ACCESS" &&
              state.number !== 1 && (
                <button
                  type="button"
                  className={joinSpaced(
                    "btn btn-xs btn-danger",
                    styles["btn-override"]
                  )}
                  disabled={isDeactivating}
                  onClick={() => {
                    setConfirmingDeactivate(true);
                  }}
                >
                  {isDeactivating ? (
                    <span>
                      <i
                        className={joinSpaced(
                          "fa fa-spin fa-spinner",
                          styles["icon-override"]
                        )}
                      ></i>
                      Deactivating...
                    </span>
                  ) : (
                    <span>Deactivate</span>
                  )}
                </button>
              )}
          </Accordion.Header>
        }
      >
        <FormSections>
          <FormSections.Section>
            <FieldRow>
              <FieldLabel htmlFor={label(`name-${state.number}`)}>
                Name
              </FieldLabel>
              <input
                ref={nameField}
                id={label(`name-${state.number}`)}
                name={label(`name-${state.number}`)}
                className="form-control"
                type="text"
                value={state.name}
                required={intendedUse === "DOOR_ACCESS" && state.number === 1}
                style={{
                  borderColor:
                    intendedUse === "DOOR_ACCESS" &&
                    state.number === 1 &&
                    state.name === ""
                      ? "red"
                      : "",
                }}
                placeholder={
                  intendedUse === "ELEVATOR"
                    ? "E.G.: South Elevator"
                    : "E.G.: Office Door"
                }
                disabled={isDeactivating}
                maxLength={32}
                onChange={({ target }) => {
                  dispatch({
                    type: "DOOR_NAME_CHANGED",
                    doorIndex,
                    value: target.value,
                  });
                }}
                onBlur={({ target }) => {
                  if (target.value === "" && state.number === 1) {
                    dispatch({
                      type: "DOOR_NAME_BLANKED",
                    });

                    if (
                      onboardOutputs &&
                      setOnboardOutputs &&
                      alarmPanelIntegration?.outputNumber
                    ) {
                      const outputKey =
                        alarmPanelIntegration.outputNumber === 1
                          ? "onboardOutput1"
                          : "onboardOutput2";
                      setOnboardOutputs({
                        ...onboardOutputs,
                        [outputKey]: {
                          ...onboardOutputs[outputKey],
                          name: "",
                        },
                      });
                    }
                  }
                }}
              />
            </FieldRow>
            {onboardOutputs ? (
              <FieldRow>
                <FieldLabel htmlFor={label("commType")}>
                  Connection Type
                </FieldLabel>
                <select
                  className="form-control"
                  id={label("commType")}
                  name={label("commType")}
                  value={commType}
                  required
                  disabled={!canEdit}
                  onChange={({ currentTarget }) => {
                    dispatch({
                      type: "COMM_TYPE_CHANGED",
                      value: currentTarget.value,
                    });
                  }}
                >
                  <option value={ControlSystemCommType.PERSISTENT}>
                    EASYconnect
                  </option>
                  <option value={ControlSystemCommType.CELL}>Cell</option>
                  <option
                    value={ControlSystemCommType.PERSISTENT_WITH_CELL_BACKUP}
                  >
                    EASYconnect + Cell Backup
                  </option>
                </select>
              </FieldRow>
            ) : null}

            {doorIndex !== 0 && intendedUse === "DOOR_ACCESS" ? (
              <FieldRow>
                <FieldLabel htmlFor={label("address")}>Address</FieldLabel>

                <FieldInputContainer>
                  <NaturalNumberInput
                    id={label("address")}
                    name={label("address")}
                    className="form-control"
                    value={state.number}
                    disabled
                  />
                </FieldInputContainer>
              </FieldRow>
            ) : null}
            <FieldRow>
              {intendedUse === "DOOR_ACCESS" ? (
                <FieldLabel htmlFor={label("strikeTime")}>
                  Strike Time (seconds)
                </FieldLabel>
              ) : (
                <FieldLabel htmlFor={label("floorActivationTime")}>
                  Floor Activation Time (seconds)
                </FieldLabel>
              )}
              <FieldInputContainer>
                <NaturalNumberInput
                  id={label("strikeTime")}
                  name={label("strikeTime")}
                  className="form-control"
                  value={state.strikeTime}
                  min={0}
                  max={250}
                  placeholder="E.G.: 30"
                  disabled={!canEdit}
                  onChange={({ currentTarget }) => {
                    dispatch({
                      type: "DOOR_STRIKE_TIME_CHANGED",
                      doorIndex,
                      value: currentTarget.value,
                    });
                  }}
                  onBlur={({ currentTarget }) => {
                    dispatch({
                      type: "DOOR_STRIKE_TIME_BLURRED",
                      doorIndex,
                      value: currentTarget.value,
                    });
                  }}
                />
              </FieldInputContainer>
            </FieldRow>

            {intendedUse === "DOOR_ACCESS" && (
              <FieldRow error={state.validationErrors.strikeDelay}>
                <FieldLabel htmlFor={label("strikeDelay")}>
                  Strike Delay (minutes)
                </FieldLabel>
                <FieldInputContainer>
                  <NaturalNumberInput
                    id={label("strikeDelay")}
                    name={label("strikeDelay")}
                    className="form-control"
                    value={state.strikeDelay}
                    placeholder="E.G.: 0"
                    inlineHelp="(0-9, 11)"
                    ranges={[[0, 9], [11]]}
                    min={0}
                    max={11}
                    disabled={!canEdit}
                    onChange={({ currentTarget }) => {
                      dispatch({
                        type: "DOOR_STRIKE_DELAY_CHANGED",
                        doorIndex,
                        value: currentTarget.value,
                      });
                    }}
                    onBlur={({ currentTarget }) => {
                      dispatch({
                        type: "DOOR_STRIKE_DELAY_BLURRED",
                        doorIndex,
                        value: currentTarget.value,
                      });
                    }}
                  />
                  {state.validationErrors.strikeDelay && (
                    <FieldErrorMessage>
                      {state.validationErrors.strikeDelay}
                    </FieldErrorMessage>
                  )}
                </FieldInputContainer>
              </FieldRow>
            )}

            <FieldRow>
              <FieldLabel htmlFor={label("osdpOrWiegand")}>
                Reader Protocol
              </FieldLabel>
              <select
                className="form-control"
                id={label("osdpOrWiegand")}
                name={label("osdpOrWiegand")}
                value={state.osdpOrWiegand}
                required
                disabled={!canEdit}
                onChange={({ target }) => {
                  dispatch({
                    type: "DOOR_OSDP_OR_WIEGAND_SELECTED",
                    doorIndex,
                    value: target.value as OsdpOrWiegand,
                  });
                }}
              >
                <option value={OsdpOrWiegand.OSDP}>OSDP</option>
                <option value={OsdpOrWiegand.WIEGAND}>Wiegand</option>
              </select>
            </FieldRow>

            {state.osdpOrWiegand === "OSDP" && (
              <>
                <FieldRow>
                  <FieldLabel htmlFor={label("buzzer")}>Buzzer</FieldLabel>
                  <Switch
                    label="Buzzer"
                    id={label("buzzer")}
                    name={label("buzzer")}
                    checked={state.buzzer}
                    disabled={!canEdit}
                    onChange={() => {
                      dispatch({ type: "DOOR_BUZZER_TOGGLED", doorIndex });
                    }}
                  />
                </FieldRow>

                <FieldRow>
                  <FieldLabel htmlFor={label("led")}>LED</FieldLabel>
                  <Switch
                    label="LED"
                    id={label("led")}
                    name={label("led")}
                    checked={state.led}
                    disabled={!canEdit}
                    onChange={() => {
                      dispatch({ type: "DOOR_LED_TOGGLED", doorIndex });
                    }}
                  />
                </FieldRow>
              </>
            )}
            {onboardOutputs && setOnboardOutputs && (
              <>
                <FieldRow>
                  <FieldLabel id={label("onboardOutput1")}>
                    Onboard Output 1 Name
                  </FieldLabel>
                  <input
                    placeholder="Onboard Output 1"
                    id={label("onboardOutput1")}
                    className="form-control"
                    disabled={
                      isDeactivating ||
                      alarmPanelIntegration?.outputNumber === 1
                    }
                    maxLength={32}
                    value={onboardOutputs?.onboardOutput1?.name ?? ""}
                    onChange={({ target }) => {
                      setOnboardOutputs({
                        ...onboardOutputs,
                        onboardOutput1: {
                          ...onboardOutputs.onboardOutput1,
                          name: target.value,
                          edited:
                            onboardOutputsRef.current?.onboardOutput1?.name !==
                            target.value,
                        },
                      });
                    }}
                  />
                </FieldRow>
                <FieldRow>
                  <FieldLabel id={label("onboardOutput2")}>
                    Onboard Output 2 Name
                  </FieldLabel>
                  <input
                    placeholder="Onboard Output 2"
                    id={label("onboardOutput2")}
                    className="form-control"
                    disabled={
                      isDeactivating ||
                      alarmPanelIntegration?.outputNumber === 2
                    }
                    maxLength={32}
                    value={onboardOutputs?.onboardOutput2?.name ?? ""}
                    onChange={({ target }) => {
                      setOnboardOutputs({
                        ...onboardOutputs,
                        onboardOutput2: {
                          ...onboardOutputs.onboardOutput2,
                          name: target.value,
                          edited:
                            onboardOutputsRef.current?.onboardOutput2?.name !==
                            target.value,
                        },
                      });
                    }}
                  />
                </FieldRow>
              </>
            )}
          </FormSections.Section>
          <FormSections.Section>
            {intendedUse === "DOOR_ACCESS" && (
              <FieldRow>
                <FieldLabel htmlFor={label("fireZone")}>Fire Zone</FieldLabel>
                <Switch
                  label="Fire Zone"
                  id={label("fireZone")}
                  name={label("fireZone")}
                  checked={state.fireZone}
                  disabled={
                    !canEdit ||
                    (!!alarmPanelIntegration &&
                      doorIndex === 0 &&
                      !state.fireZone)
                  }
                  onChange={() => {
                    dispatch({ type: "DOOR_FIRE_ZONE_TOGGLED", doorIndex });
                  }}
                />
              </FieldRow>
            )}
            {intendedUse === "DOOR_ACCESS" && hasFireZone && (
              <FieldRow>
                <FieldLabel htmlFor={label("fireExit")}>Fire Exit</FieldLabel>
                <Switch
                  label="Fire Exit"
                  id={label("fireExit")}
                  name={label("fireExit")}
                  checked={state.fireExit}
                  disabled={!canEdit}
                  onChange={() => {
                    dispatch({ type: "DOOR_FIRE_EXIT_TOGGLED", doorIndex });
                  }}
                />
              </FieldRow>
            )}

            <FieldRow>
              <FieldLabel htmlFor={label("includeInLockdown")}>
                Include In Lockdown
              </FieldLabel>
              <Switch
                label="Include in Lockdown"
                id={label("includeInLockdown")}
                name={label("includeInLockdown")}
                checked={state.includeInLockdown}
                disabled={!canEdit}
                onChange={() => {
                  dispatch({
                    type: "DOOR_INCLUDE_IN_LOCKDOWN_TOGGLED",
                    doorIndex,
                  });
                }}
              />
            </FieldRow>

            <FieldRow>
              <FieldLabel htmlFor={label("doorSensor")}>Door Sensor</FieldLabel>
              <Switch
                label="Sensor"
                id={label("doorSensor")}
                name={label("doorSensor")}
                type="checkbox"
                checked={state.doorSensor}
                disabled={!canEdit}
                onChange={() => {
                  dispatch({ type: "DOOR_SENSOR_TOGGLED", doorIndex });
                }}
              />
            </FieldRow>
            {state.doorSensor && (
              <>
                <FieldRow>
                  <FieldLabel htmlFor={label("doorPropTime")}>
                    Prop Time (seconds)
                  </FieldLabel>
                  <FieldInputContainer>
                    <NaturalNumberInput
                      id={label("doorPropTime")}
                      name={label("doorPropTime")}
                      className="form-control"
                      value={state.doorPropTime}
                      placeholder="E.G.: 120"
                      min={0}
                      max={240}
                      disabled={!canEdit}
                      onChange={({ currentTarget }) => {
                        dispatch({
                          type: "DOOR_PROP_TIME_CHANGED",
                          doorIndex,
                          value: currentTarget.value,
                        });
                      }}
                      onBlur={({ currentTarget }) => {
                        dispatch({
                          type: "DOOR_PROP_TIME_BLURRED",
                          doorIndex,
                          value: currentTarget.value,
                        });
                      }}
                    />
                  </FieldInputContainer>
                </FieldRow>
                <FieldRow>
                  <FieldLabel htmlFor={label("doorForce")}>
                    Door Forced Message
                  </FieldLabel>
                  <Switch
                    label="Force"
                    id={label("doorForce")}
                    name={label("doorForce")}
                    type="checkbox"
                    checked={state.doorForce}
                    disabled={!canEdit}
                    onChange={() => {
                      dispatch({ type: "DOOR_FORCE_TOGGLED", doorIndex });
                    }}
                  />
                </FieldRow>
              </>
            )}
            <FieldRow>
              <FieldLabel htmlFor={label("requestToExit")}>
                Request To Exit
              </FieldLabel>
              <Switch
                label="Request To Exit"
                id={label("requestToExit")}
                name={label("requestToExit")}
                checked={state.requestToExit}
                disabled={!canEdit}
                onChange={() => {
                  dispatch({ type: "DOOR_REQUEST_TO_EXIT_TOGGLED", doorIndex });
                }}
              />
            </FieldRow>
            {state.requestToExit && (
              <FieldRow>
                <FieldLabel htmlFor={label("unlockOnRex")}>
                  Unlock On REX
                </FieldLabel>
                <Switch
                  label="Unlock on Request To Exit"
                  id={label("unlockOnRex")}
                  name={label("unlockOnRex")}
                  checked={state.unlockOnRex}
                  disabled={!canEdit}
                  onChange={() => {
                    dispatch({ type: "DOOR_UNLOCK_ON_REX_TOGGLED", doorIndex });
                  }}
                />
              </FieldRow>
            )}
            {onboardOutputs &&
              setOnboardOutputs &&
              intendedUse === "DOOR_ACCESS" && (
                <>
                  <FieldRow
                    title={
                      isCellOnlyPanel
                        ? "Alarm Panel Integration Requires Network Connectivity"
                        : alarmPanelIntegrationIsDisabled
                        ? "Alarm Panel Integration Is Disabled For This Door"
                        : undefined
                    }
                  >
                    <FieldLabel id={label("alarmPanel")}>
                      Alarm Panel
                    </FieldLabel>
                    <Switch
                      label="Alarm Panel"
                      id={label("alarmPanel")}
                      name={label("alarmPanel")}
                      checked={!!alarmPanelIntegration}
                      disabled={
                        (isCellOnlyPanel ||
                          alarmPanelIntegrationIsDisabled ||
                          !canEdit) &&
                        !alarmPanelIntegration
                      }
                      onChange={() => {
                        if (alarmPanelIntegration?.outputNumber) {
                          const outputKey =
                            alarmPanelIntegration.outputNumber === 1
                              ? "onboardOutput1"
                              : "onboardOutput2";
                          setOnboardOutputs({
                            ...onboardOutputs,
                            [outputKey]: {
                              ...onboardOutputs[outputKey],
                              name: "",
                              edited: true,
                            },
                          });
                        }
                        dispatch({ type: "ALARM_PANEL_TOGGLED" });
                        dispatch({ type: "RESEND_DOOR", doorIndex });
                      }}
                    />
                  </FieldRow>

                  {!!alarmPanelIntegration && (
                    <FieldRow error={alarmPanelIntegration.shouldShowError}>
                      <FieldLabel id={label("alarmPanelOutput")}>
                        Output to Alarm Panel
                      </FieldLabel>
                      <FieldInputContainer>
                        <select
                          className="form-control"
                          id={label("alarmPanelOutput")}
                          name={label("alarmPanelOutput")}
                          value={alarmPanelIntegration.outputNumber ?? ""}
                          required
                          disabled={isDeactivating}
                          onChange={({ target }) => {
                            const outputNumber = target.value
                              ? Number(target.value)
                              : null;

                            if (
                              (outputNumber === 1 &&
                                !onboardOutput1Available) ||
                              (outputNumber === 2 && !onboardOutput2Available)
                            ) {
                              setModalOpenForOutput(outputNumber);
                              return;
                            }

                            dispatch({
                              type: "ALARM_PANEL_OUTPUT_SELECTED",
                              outputNumber: outputNumber,
                            });
                            dispatch({ type: "RESEND_DOOR", doorIndex });
                            setOnboardOutputs(
                              setAppropriateOutputNamesForAlarmPanel({
                                oldOutputNumber:
                                  alarmPanelIntegration.outputNumber,
                                newOutputNumber: outputNumber,
                                onboardOutputs,
                              })
                            );
                          }}
                        >
                          <option disabled value="">
                            Select an output
                          </option>
                          <option value={1}>
                            Onboard Output 1{" "}
                            {alarmPanelIntegration.outputNumber === 1
                              ? ""
                              : onboardOutput1Available
                              ? "(Available)"
                              : "(Unavailable)"}
                          </option>
                          <option value={2}>
                            Onboard Output 2{" "}
                            {alarmPanelIntegration.outputNumber === 2
                              ? ""
                              : onboardOutput2Available
                              ? "(Available)"
                              : "(Unavailable)"}
                          </option>
                        </select>
                        {alarmPanelIntegration.shouldShowError && (
                          <FieldErrorMessage>
                            Please select an output for your alarm panel
                          </FieldErrorMessage>
                        )}
                      </FieldInputContainer>
                    </FieldRow>
                  )}
                </>
              )}
          </FormSections.Section>
        </FormSections>

        {confirmingDeactivate && (
          <DeleteConfirmModalOverlay>
            <DeleteConfirmModalBody
              header="Deactivate Confirmation"
              actionPending={isDeactivating}
              pendingText="Deactivating..."
              onConfirm={() => {
                deactivateSiteDoor({
                  variables: { doorId: state.id },
                  onCompleted: ({ deactivateSiteDoor }) => {
                    setConfirmingDeactivate(false);
                    if (deactivateSiteDoor?.errorType) {
                      createNotification({
                        type: "error",
                        text: "Unable to deactivate door.",
                      });
                    } else {
                      dispatch({
                        type: "DOOR_DEACTIVATED",
                        doorIndex,
                        door: deactivateSiteDoor as Required<
                          Omit<
                            DoorFormDeactivateMutation$data["deactivateSiteDoor"],
                            "errorType"
                          >
                        >,
                      });
                      createNotification({
                        type: "success",
                        text: "Door was deactivated.",
                      });
                    }
                  },
                  onError: () => {
                    createNotification({
                      type: "error",
                      text: "Unable to deactivate door.",
                    });
                  },
                });
              }}
              onCancel={() => setConfirmingDeactivate(false)}
            >
              Are you sure you would like to deactivate this door?
            </DeleteConfirmModalBody>
          </DeleteConfirmModalOverlay>
        )}
      </Accordion>
      <UnavailableOutputWarningModal
        isOpen={!!modalOpenForOutput}
        onYes={() => {
          dispatch({
            type: "ALARM_PANEL_OUTPUT_SELECTED",
            outputNumber: modalOpenForOutput,
          });
          if (onboardOutputs && setOnboardOutputs) {
            setOnboardOutputs(
              setAppropriateOutputNamesForAlarmPanel({
                oldOutputNumber: alarmPanelIntegration?.outputNumber ?? null,
                newOutputNumber: modalOpenForOutput,
                onboardOutputs,
              })
            );
          }
          setModalOpenForOutput(null);
        }}
        onNo={() => setModalOpenForOutput(null)}
      />
    </>
  );
}

const setAppropriateOutputNamesForAlarmPanel = ({
  oldOutputNumber,
  newOutputNumber,
  onboardOutputs,
}: {
  oldOutputNumber: number | null;
  newOutputNumber: number | null;
  onboardOutputs: OnboardOutputState;
}): OnboardOutputState => {
  // if they don't change the output, no changes needed
  if (oldOutputNumber === newOutputNumber) {
    return onboardOutputs;
  }

  const newSelectedKey = newOutputNumber
    ? (`onboardOutput${newOutputNumber}` as "onboardOutput1" | "onboardOutput2")
    : null;
  const oldSelectedKey = oldOutputNumber
    ? (`onboardOutput${oldOutputNumber}` as "onboardOutput1" | "onboardOutput2")
    : null;

  // if they change it to none, just clear the current name
  if (oldSelectedKey && !newSelectedKey) {
    return {
      ...onboardOutputs,
      [oldSelectedKey]: {
        ...onboardOutputs[oldSelectedKey],
        name: "",
        edited: true,
      },
    };
  }

  // if they go from none to select one, just change that ones name
  if (!oldSelectedKey && newSelectedKey) {
    return {
      ...onboardOutputs,
      [newSelectedKey]: {
        ...onboardOutputs[newSelectedKey],
        name: "Alarm Panel Output",
        edited: true,
      },
    };
  }

  // only path left is switching from one output to the other, in which case
  // we should clear the old one, and set the new one
  return {
    ...onboardOutputs,
    [newSelectedKey!]: {
      ...onboardOutputs[newSelectedKey!],
      name: "Alarm Panel Output",
      edited: true,
    },
    [oldSelectedKey!]: {
      ...onboardOutputs[oldSelectedKey!],
      name: "",
      edited: true,
    },
  };
};
