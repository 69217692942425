App.factory("DealerUserAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/dealers/:dealer_id/users/:new_user",
      {},
      {
        getUsers: {
          method: "GET",
          params: { dealer_id: "@dealer_id" },
          isArray: "true",
        },
        new: {
          method: "GET",
          params: { dealer_id: "@dealer_id", new_user: "new" },
        },
        create: { method: "POST", params: { dealer_id: "@dealer_id" } },
      }
    );
  },
]);
