const scriptCache = new Map();

// Adapted from https://github.com/webpack/webpack/issues/150#issuecomment-275952354
/**
 *
 * @param url
 * @returns ()=>void
 */
const loadScript = (url: string) => {
  let promise;

  if (scriptCache.has(url)) {
    promise = scriptCache.get(url);
  } else {
    promise = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.onerror = () => reject(new Error(`Failed to load '${url}'`));
      script.onload = resolve;
      script.async = true;
      script.src = url;
      if (document.currentScript) {
        document.currentScript.parentNode?.insertBefore(
          script,
          document.currentScript
        );
      } else {
        (document.head || document.getElementsByTagName("head")[0]).appendChild(
          script
        );
      }
    });

    scriptCache.set(url, promise);
  }
};

export default loadScript;
