/**
 * @generated SignedSource<<e80b772ad7b7134732aa54014b25f305>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type TMSentryAreaInformationProgrammingConceptFormInline_area$data = {
  readonly __typename: string;
  readonly autoArmEnabled: boolean;
  readonly autoDisarmEnabled: boolean;
  readonly badZonesOption: BadZonesOption;
  readonly hasChanges: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: string;
  readonly " $fragmentType": "TMSentryAreaInformationProgrammingConceptFormInline_area";
};
export type TMSentryAreaInformationProgrammingConceptFormInline_area$key = {
  readonly " $data"?: TMSentryAreaInformationProgrammingConceptFormInline_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryAreaInformationProgrammingConceptFormInline_area">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryAreaInformationProgrammingConceptFormInline_area"
};

(node as any).hash = "5acf070175174da90135a944efca4e3d";

export default node;
