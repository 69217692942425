import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationScheduleOverrideField_deviceInformation$key } from "./__generated__/DeviceInformationScheduleOverrideField_deviceInformation.graphql";

export const deviceInformationScheduleOverrideFieldId = (number: string) =>
  `device-information-schedule-override-${number}`;

function DeviceInformationScheduleOverrideField() {
  const [
    { number, overrideScheduleWhenArmed, deviceType },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationScheduleOverrideField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationScheduleOverrideField_deviceInformation on DeviceInformation {
        number
        ... on XrDeviceInformation {
          overrideScheduleWhenArmed
          deviceType
        }
        ... on Xt75DeviceInformation {
          overrideScheduleWhenArmed
          deviceType
        }
      }
    `
  );

  const fieldId = deviceInformationScheduleOverrideFieldId(String(number));
  const label = "Schedule Override";
  const disabled = deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Allows door ON / OFF schedules to be overridden by the Armed condition of the System."
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={overrideScheduleWhenArmed}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              !overrideScheduleWhenArmed,
              "overrideScheduleWhenArmed"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationScheduleOverrideField;
