import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { useSupports1100T } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationNetworkField_deviceInformation$key } from "./__generated__/DeviceInformationNetworkField_deviceInformation.graphql";

export const deviceInformationNetworkFieldId = (number: string) =>
  `device-information-network-${number}`;

function DeviceInformationNetworkField() {
  const [{ number, door, network }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationNetworkField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationNetworkField_deviceInformation on DeviceInformation {
          number
          ... on XtDeviceInformation {
            door
            network
          }
        }
      `
    );

  const fieldId = deviceInformationNetworkFieldId(String(number));
  const supports1100T = useSupports1100T();
  const disabled = !!door;
  const label = "Network";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
    >
      <Switch
        label={label}
        id={fieldId}
        checked={network}
        disabled={disabled}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!network, "network");
            if (!network) recordProxy.setValue(false, "wireless");
          });
          if (!network && supports1100T)
            updateDeviceInformation((recordProxy) => {
              recordProxy.setValue(false, "wirelessTranslator1100t");
            });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationNetworkField;
