/**
 * @generated SignedSource<<857b5cc2c05703ff3d46f458080f3897>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HolidaySystemSelectorModalFragment_systemData$data = {
  readonly __typename: "MassProgramPanelQuickDetailedErrorResponse";
  readonly errorMessage: string;
  readonly errorType: string;
  readonly " $fragmentType": "HolidaySystemSelectorModalFragment_systemData";
} | {
  readonly __typename: "MassProgramPanelQuickDetailedSuccessResponse";
  readonly customers: ReadonlyArray<{
    readonly controlSystemId: string | null;
    readonly controlSystemName: string | null;
    readonly customerId: number | null;
    readonly customerName: string | null;
    readonly panelHardwareModel: string | null;
  } | null>;
  readonly " $fragmentType": "HolidaySystemSelectorModalFragment_systemData";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "HolidaySystemSelectorModalFragment_systemData";
};
export type HolidaySystemSelectorModalFragment_systemData$key = {
  readonly " $data"?: HolidaySystemSelectorModalFragment_systemData$data;
  readonly " $fragmentSpreads": FragmentRefs<"HolidaySystemSelectorModalFragment_systemData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HolidaySystemSelectorModalFragment_systemData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MassProgramPanelQuickDetailedCustomer",
          "kind": "LinkedField",
          "name": "customers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customerName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customerId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "controlSystemName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "controlSystemId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "panelHardwareModel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "MassProgramPanelQuickDetailedSuccessResponse",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorMessage",
          "storageKey": null
        }
      ],
      "type": "MassProgramPanelQuickDetailedErrorResponse",
      "abstractKey": null
    }
  ],
  "type": "MassProgramPanelQuickDetailedResponse",
  "abstractKey": "__isMassProgramPanelQuickDetailedResponse"
};
})();

(node as any).hash = "c8709576f3821703d5b4a47507b1960c";

export default node;
