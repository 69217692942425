/**
 * @ngdoc service
 * @name App.factory:CustomerLinkedProfilesService
 *
 * @description
 * Customer Linked Profiles Service (Group Profiles)
 *
 */
App.factory("CustomerLinkedProfilesService", [
  "CustomerLinkedProfilesV2API",
  "$q",
  "$filter",
  function (CustomerLinkedProfilesV2API, $q, $filter) {
    return {
      getLinkedProfiles: function (customerId) {
        var deferred = $q.defer();
        CustomerLinkedProfilesV2API.getLinkedProfiles(
          {
            customer_id: customerId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
