import React, { CSSProperties } from "react";
import styled from "styled-components";
import NakedList from "../NakedList";
import Tooltip, { MessagePosition } from "../Tooltip";
import MenuItem, { MenuItemProps } from "./MenuItem";

const Root = styled.div`
  position: relative;
  margin-left: 15px;
  line-height: 1;
`;
const List = styled(NakedList)`
  padding: 0.25em;
`;
export const MenuListItem = styled.li`
  &:first-child {
    border-top-left-radius: ${Tooltip.borderRadius};
    border-top-right-radius: ${Tooltip.borderRadius};
  }

  &:last-child {
    border-bottom-left-radius: ${Tooltip.borderRadius};
    border-bottom-right-radius: ${Tooltip.borderRadius};
  }
`;

export type PopUpMenuProps = {
  position?: MessagePosition;
  anchor: React.ReactElement;
  items: MenuItemProps[];
  open: boolean;
  onDismiss: () => void;
  className?: string;
  zIndex?: number;
  fixed?: boolean;
  baseFontSize?: CSSProperties["fontSize"];
};

function PopUpMenu({
  anchor,
  items,
  open,
  onDismiss,
  className,
  position,
  zIndex,
  fixed,
  baseFontSize,
}: PopUpMenuProps) {
  return (
    <Root className={className}>
      <Tooltip
        fixed={fixed}
        position={position}
        anchor={({ registerAnchor }) => (
          <div ref={registerAnchor}>{anchor}</div>
        )}
        open={open}
        onDismiss={onDismiss}
        zIndex={zIndex}
        baseFontSize={baseFontSize}
      >
        <List>
          {items.map((item, index) => (
            <MenuListItem key={index.toString()}>
              <MenuItem
                message={item.message}
                disabled={item.disabled}
                icon={item.icon}
                onClick={(event) => {
                  onDismiss();
                  if (item.onClick) {
                    item.onClick(event);
                  }
                }}
              />
            </MenuListItem>
          ))}
        </List>
      </Tooltip>
    </Root>
  );
}

PopUpMenu.Item = MenuItem;

export default PopUpMenu;
