App.factory("IpWhitelistAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/Dealer/:dealer_id/WhiteListIp/:route/:id",
      {},
      {
        getWhitelist: {
          method: "GET",
          params: { route: "List", dealer_id: "@dealer_id" },
          isArray: true,
        },
        newWhitelistIp: {
          method: "GET",
          params: { route: "AddressId", dealer_id: "@dealer_id", id: "new" },
        },
        getWhitelistIp: {
          method: "GET",
          params: { route: "AddressId", dealer_id: "@dealer_id", id: "@id" },
        },
        createWhitelistIP: {
          method: "POST",
          params: { dealer_id: "@dealer_id" },
        },
        updateWWhitelistIP: {
          method: "PUT",
          params: { route: "AddressId", dealer_id: "@dealer_id", id: "@id" },
        },
        destroyWhitelistIP: {
          method: "DELETE",
          params: { route: "AddressId", dealer_id: "@dealer_id", id: "@id" },
        },
      }
    );
  },
]);
