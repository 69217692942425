/**
 * @generated SignedSource<<b2a5ccb03fbaadf97a4b14d3728838a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DeviceInformation1100TWirelessFrequencyType = "DSC" | "HONEYWELL" | "INTERLOGIX" | "NONE" | "_2GIG";
export type DeviceInformationWirelessSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XTDeviceSetupProgrammingConceptFormInline_deviceInformation$data = {
  readonly __typename: "XtDeviceInformation";
  readonly axNumber?: string;
  readonly door?: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly lxNumber?: string;
  readonly name: string;
  readonly network?: boolean;
  readonly number: number;
  readonly serialNumber?: string;
  readonly supervisionTime?: DeviceInformationWirelessSupervisionTime | null;
  readonly wireless?: boolean;
  readonly wirelessTranslator1100t?: boolean;
  readonly wirelessTranslator1100tFrequency?: DeviceInformation1100TWirelessFrequencyType;
  readonly " $fragmentType": "XTDeviceSetupProgrammingConceptFormInline_deviceInformation";
};
export type XTDeviceSetupProgrammingConceptFormInline_deviceInformation$key = {
  readonly " $data"?: XTDeviceSetupProgrammingConceptFormInline_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTDeviceSetupProgrammingConceptFormInline_deviceInformation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTDeviceSetupProgrammingConceptFormInline_deviceInformation"
};

(node as any).hash = "833409c191d7c63e2c41027a751bb248";

export default node;
