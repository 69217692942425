import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationTamperField_zone$key } from "./__generated__/ZoneInformationTamperField_zone.graphql";

function ZoneInformationTamperField() {
  const [zoneInformation, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationTamperField_zone$key>(
      graphql`
        fragment ZoneInformationTamperField_zone on Zone {
          id
          number
          type
          tamperEnabled
        }
      `
    );

  const { tamperEnabled, type } = zoneInformation;
  const disabled = [
    ZoneType.FIRE,
    ZoneType.FIRE_VERIFY,
    ZoneType.CARBON_MONOXIDE,
    ZoneType.AUXILIARY_1,
    ZoneType.AUXILIARY_2,
    ZoneType.ARMING,
    ZoneType.DOORBELL,
  ].includes(type as ZoneType);
  const id = `zone-information-tamper-${zoneInformation.number}`;
  const label = "Tamper";
  const tooltip = "Enable when using dual EOL resistors.";
  const softwareVersion = useSoftwareVersion();
  const tamperDefault = softwareVersion >= 693 ? true : false;
  return (
    <ProgrammingConceptForm.Field
      fieldId={id}
      label={label}
      disabled={disabled}
      tooltip={tooltip}
    >
      <Switch
        label={label}
        id={id}
        name={id}
        checked={tamperEnabled ?? tamperDefault}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!tamperEnabled, "tamperEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationTamperField;
