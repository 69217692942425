App.controller("CameraMappingCtrl", [
  "$q",
  "$scope",
  "ControlSystemsService",
  "VideoDeviceService",
  "PANEL_CONCEPTS",
  "PanelProgrammingService",
  "UserService",
  "$stateParams",
  "ZoneCameraMappingsService",
  "$filter",
  "$rootScope",
  function (
    $q,
    $scope,
    ControlSystemsService,
    VideoDeviceService,
    PANEL_CONCEPTS,
    PanelProgrammingService,
    UserService,
    $stateParams,
    ZoneCameraMappingsService,
    $filter,
    $rootScope
  ) {
    $scope.controlSystem = ControlSystemsService.currentControlSystem;

    init();

    $scope.cameraList = {};
    $scope.tabId = "zones";
    var camToZone = {}; //initial object to find mappings
    camToZone.video_channel_mapped_zone = {
      video_channel_id: null,
      zone_record_number: null,
      _destroy: false,
    };
    var mappingsToSend = []; //populate array with camera/zone mappings
    $scope.finalOBJ = {}; //array needs to be wrapped in object for sending call
    $scope.zonesToCameras = {};
    $scope.zonesToCameras = {
      zones: [],
      cameras: [],
      mappings: [],
    };
    $scope.displayZones = [];

    function getCamerasAndZones() {
      var promises = [];
      $scope.cameraNames = [];
      VideoDeviceService.isBusy = true;
      PanelProgrammingService.panel_id = $scope.controlSystem.panels[0].id;
      promises.push(
        PanelProgrammingService.get(
          "zone_informations",
          true,
          PanelProgrammingService.getSessionKey(),
          { tracked: true }
        )
      );
      promises.push(VideoDeviceService.getVideoDevices(true));
      $q.all(promises)
        .then(
          function (data) {
            for (let zone of data[0].zone_informations) {
              if (
                zone.tipe !== "A1" &&
                zone.tipe !== "A2" &&
                zone.tipe !== "--"
              )
                $scope.zonesToCameras.zones.push(zone);
            }
            findName(data[1].cameras); //find the name of channels.cameras
            findName(data[1].nvrs); //find the name of channels.nvrs
            findName(data[1].dvrs); //find the name of channels.dvrs
            $scope.zonesToCameras.cameras = $scope.cameraNames;
            getCameraMappings()
              .then(
                function () {
                  for (
                    let i = 0;
                    i < $scope.zonesToCameras.mappings.length;
                    i++
                  ) {
                    //Loop through mapped channels, look for matching channel_id, then set channel_name
                    for (
                      let j = 0;
                      j < $scope.zonesToCameras.mappings[i].channels.length;
                      j++
                    ) {
                      angular.forEach(
                        $scope.cameraNames,
                        function (cameraName) {
                          if (
                            cameraName.channel_id ===
                            $scope.zonesToCameras.mappings[i].channels[j]
                              .channel_id
                          ) {
                            $scope.zonesToCameras.mappings[i].channels[
                              j
                            ].channel_name = cameraName.channel_name;
                          }
                        }
                      );
                    }
                  }
                  $scope.incomingMapping();
                },
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error Retrieving Zones and Cameras",
                    json: error,
                  });
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Retrieving Zones and Cameras",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }
    function findName(camOrNvrOrDvr) {
      for (let k = 0; k < camOrNvrOrDvr.length; k++) {
        //Break array into individual camera, then save the name for later
        for (let l = 0; l < camOrNvrOrDvr[k].channels.length; l++) {
          if (camOrNvrOrDvr[k].channels[l].online === false) {
            //Filter out NVR cameras that are offline
          } else {
            $scope.cameraNames.push({
              channel_id: camOrNvrOrDvr[k].channels[l].id,
              channel_name: camOrNvrOrDvr[k].channels[l].name,
            });
          }
        }
      }
    }

    function getCameraMappings() {
      var deferred = $q.defer();
      ZoneCameraMappingsService.getMappings(PanelProgrammingService.panel_id)
        .then(
          function (data) {
            let mappings = [];
            let mapped_channels = [];
            for (let d of data) {
              mappings.push(d.video_channel_mapped_zone);
            }
            for (let map of mappings) {
              let thisZone = map.zone_record_number;
              let thisChannel = map.video_channel_id;
              let uniqueZone = true;
              for (let mapChan of mapped_channels) {
                if (thisZone == mapChan.zone) {
                  mapChan.channels.push({
                    channel_id: thisChannel,
                    channel_name: null,
                  });
                  uniqueZone = false;
                }
              }
              if (uniqueZone) {
                mapped_channels.push({
                  zone: thisZone,
                  channels: [
                    {
                      channel_id: thisChannel,
                      channel_name: null,
                    },
                  ],
                });
              }
            }
            $scope.zonesToCameras.mappings = mapped_channels;
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function createMappingToSend() {
      for (let pristineMapping of $scope.mappedZonesToCamerasPristine) {
        var dirtyZone = $scope.mappedZonesToCameras.filter(function (dirty) {
          //match pristine and dirty mappings obj for compare
          return dirty.zone_number === pristineMapping.zone_number;
        });
        for (let pristineChannel of pristineMapping.channels) {
          var thisDirtyZone = angular.toJson(dirtyZone[0]);
          thisDirtyZone = angular.fromJson(thisDirtyZone);
          var isFound = indexOfByPropertyValue(
            thisDirtyZone.channels,
            "channel_id",
            pristineChannel.channel_id
          );
          if (isFound < 0) {
            camToZone = {
              video_channel_mapped_zone: {
                video_channel_id: pristineChannel.channel_id,
                zone_record_number: pristineMapping.zone_number,
                _destroy: true,
              },
            };
            mappingsToSend.push(camToZone);
          }
        }
      }

      for (let mappings of $scope.mappedZonesToCameras) {
        //If not deleting, send back entire obj. and API will handle new mappings
        for (let channel of mappings.channels) {
          camToZone = {
            video_channel_mapped_zone: {
              video_channel_id: channel.channel_id,
              zone_record_number: mappings.zone_number,
            },
          };
          mappingsToSend.push(camToZone);
        }
      }
      $scope.finalOBJ.video_channel_mapped_zones = mappingsToSend; //set array with mapping edits to finalOBJ for updateMappings call
    }

    $scope.updateCameraMappings = function () {
      createMappingToSend();
      ZoneCameraMappingsService.updateMappings(
        PanelProgrammingService.panel_id,
        $scope.finalOBJ
      )
        .then(
          function () {
            $scope.mappedZonesToCamerasPristine = angular.copy(
              $scope.mappedZonesToCameras
            );
            $rootScope.alerts.push({
              type: "success",
              text: "Zones and Cameras Assigned",
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Assigning Zones to Cameras",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.incomingMapping = function () {
      $scope.mappedZonesToCameras = [];
      for (let zone of $scope.zonesToCameras.zones) {
        let zoneEntry = {};
        zoneEntry.zone_number = parseInt(zone.number);
        zoneEntry.zone_name = zone.name;
        let foundZone = $scope.zonesToCameras.mappings.filter(
          (thisZone) => parseInt(thisZone.zone) === parseInt(zone.number)
        );
        if (foundZone.length > 0) zoneEntry.channels = foundZone[0].channels;
        else zoneEntry.channels = [];
        zoneEntry.channels.sort(sortCameraMappings);

        $scope.mappedZonesToCameras.push(zoneEntry);
      }
      $scope.mappedZonesToCamerasPristine = angular.copy(
        $scope.mappedZonesToCameras
      );
    };

    function sortCameraMappings(a, b) {
      // Use toUpperCase() to ignore character casing
      if (
        isUndefinedOrNull(a.channel_name) ||
        isUndefinedOrNull(b.channel_name)
      )
        return 1;
      const channelA = a.channel_name.toUpperCase();
      const channelB = b.channel_name.toUpperCase();

      let comparison = 0;
      if (channelA > channelB) {
        comparison = 1;
      } else if (channelA < channelB) {
        comparison = -1;
      }
      return comparison;
    }

    function init() {
      getCamerasAndZones();
      $scope.panelConcepts = PANEL_CONCEPTS;
      $scope.VideoDeviceService = VideoDeviceService;
    }
  },
]);
