/**
 * @generated SignedSource<<44a72b2846d05a1082dfc6e73d3b1fff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneOutputAction = "FOLLOW" | "MOMENTARY" | "NONE" | "PULSE" | "STEADY";
export type ZoneSensorType = "GLASSBREAK" | "HOLD_UP" | "PIR_OR_RECESSED_DOOR" | "REPEATER" | "SMOKE" | "UNIVERSAL_TRANSMITTER" | "UNKNOWN_DEVICE" | "WIRELESS_FOUR_ZONE_EXPANDER" | "WIRELESS_PIR";
export type ZoneSupervisionTime = "NONE" | "_1440_MIN" | "_240_MIN" | "_3_MIN" | "_60_MIN" | "_720_MIN";
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type XFZoneInformationProgrammingConceptFormInline_zone$data = {
  readonly __typename: "Zone";
  readonly armedOpenActionMessage: ZoneMessage | null;
  readonly armedOpenOutputAction: ZoneOutputAction | null;
  readonly armedOpenOutputNumber: string;
  readonly armedShortActionMessage: ZoneMessage | null;
  readonly armedShortOutputAction: ZoneOutputAction | null;
  readonly armedShortOutputNumber: string;
  readonly competitorWireless: boolean | null;
  readonly contactNumber: ZoneTransmitterContact | null;
  readonly crossZoneEnabled: boolean;
  readonly expanderSerialNumber: string | null;
  readonly fastResponseEnabled: boolean;
  readonly fireBellOutputNumber: string | null;
  readonly firePanelSlaveInput: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: string;
  readonly presignalKeypads: string | null;
  readonly prewarnKeypads: string;
  readonly realTimeStatusEnabled: boolean;
  readonly retardDelayEnabled: boolean;
  readonly sensorType: ZoneSensorType;
  readonly serialNumber: string;
  readonly supervisionTime: ZoneSupervisionTime | null;
  readonly swingerBypassEnabled: boolean;
  readonly type: ZoneType;
  readonly wireless: boolean;
  readonly " $fragmentType": "XFZoneInformationProgrammingConceptFormInline_zone";
};
export type XFZoneInformationProgrammingConceptFormInline_zone$key = {
  readonly " $data"?: XFZoneInformationProgrammingConceptFormInline_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFZoneInformationProgrammingConceptFormInline_zone">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFZoneInformationProgrammingConceptFormInline_zone"
};

(node as any).hash = "dc587753314aec1e185b7f9d1bedd9f0";

export default node;
