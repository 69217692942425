/**
 * @generated SignedSource<<096da22d5d634961639b0328848340e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemReportsAccessKeypadsField_systemReports$data = {
  readonly accessKeypads: string;
  readonly " $fragmentType": "SystemReportsAccessKeypadsField_systemReports";
};
export type SystemReportsAccessKeypadsField_systemReports$key = {
  readonly " $data"?: SystemReportsAccessKeypadsField_systemReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemReportsAccessKeypadsField_systemReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemReportsAccessKeypadsField_systemReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessKeypads",
      "storageKey": null
    }
  ],
  "type": "SystemReports",
  "abstractKey": null
};

(node as any).hash = "0f24635fa5cc0add61010e9c1b9c154e";

export default node;
