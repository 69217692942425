/**
 * @generated SignedSource<<ad35737b5df8479e1dfa46781b434861>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HolidayDateClass = "A" | "B" | "C";
export type CreateHolidayDateInput = {
  clazz: HolidayDateClass;
  date: string;
  dealerId?: number | null;
  description?: string | null;
  globalHolidayId?: number | null;
  name: string;
  panelId: number;
};
export type SchedulesHolidayDatesModalCreateMutation$variables = {
  input: CreateHolidayDateInput;
};
export type SchedulesHolidayDatesModalCreateMutation$data = {
  readonly createHolidayDate: {
    readonly controlSystem?: {
      readonly holidayDates: ReadonlyArray<{
        readonly class: HolidayDateClass;
        readonly date: string;
        readonly description: string;
        readonly globalHolidayId: number | null;
        readonly id: string;
        readonly isGlobalHoliday: boolean;
        readonly name: string;
      } | null>;
    } | null;
    readonly errorMessage?: string;
  };
};
export type SchedulesHolidayDatesModalCreateMutation = {
  response: SchedulesHolidayDatesModalCreateMutation$data;
  variables: SchedulesHolidayDatesModalCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "HolidayDate",
  "kind": "LinkedField",
  "name": "holidayDates",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "class",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGlobalHoliday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalHolidayId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    }
  ],
  "type": "CreateHolidayDateErrorResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchedulesHolidayDatesModalCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createHolidayDate",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "controlSystem",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CreateHolidayDateSuccessResponse",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchedulesHolidayDatesModalCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createHolidayDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "controlSystem",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CreateHolidayDateSuccessResponse",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8a55e684bba80cf9e591b2e58df4700",
    "id": null,
    "metadata": {},
    "name": "SchedulesHolidayDatesModalCreateMutation",
    "operationKind": "mutation",
    "text": "mutation SchedulesHolidayDatesModalCreateMutation(\n  $input: CreateHolidayDateInput!\n) {\n  createHolidayDate(input: $input) {\n    __typename\n    ... on CreateHolidayDateSuccessResponse {\n      controlSystem {\n        holidayDates {\n          id\n          name\n          date\n          description\n          class\n          isGlobalHoliday\n          globalHolidayId\n        }\n        id\n      }\n    }\n    ... on CreateHolidayDateErrorResponse {\n      errorMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8262a0f235113574b776d1fdb25378c";

export default node;
