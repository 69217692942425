import styled, { css } from "styled-components";

const BasicArmingAreaButtonLabel = styled.span<{ armed: boolean }>`
  display: block;
  font-weight: 500;
  white-space: pre;

  ${({ armed }) =>
    armed &&
    css`
      opacity: 0;
    `};
`;
export default BasicArmingAreaButtonLabel;
