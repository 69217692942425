/**
 * @generated SignedSource<<b0d9f5dbfbdc5bfed9b634bf017ecb0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeyfobButtonType = "ARMING" | "DISARMING" | "EMERGENCY" | "EMERGENCY_TWO_BUTTON" | "OUTPUT" | "PANIC" | "PANIC_TWO_BUTTON" | "SENSOR_RESET" | "STATUS" | "TOGGLE_ARM_DISARM" | "UNUSED";
export type KeyfobPressTime = "LONG" | "SHORT";
import { FragmentRefs } from "relay-runtime";
export type KeyfobButtonPressTimeField_keyfob$data = {
  readonly buttonFourAction: KeyfobButtonType;
  readonly buttonFourPressTime: KeyfobPressTime;
  readonly buttonOneAction: KeyfobButtonType;
  readonly buttonOnePressTime: KeyfobPressTime;
  readonly buttonThreeAction: KeyfobButtonType;
  readonly buttonThreePressTime: KeyfobPressTime;
  readonly buttonTwoAction: KeyfobButtonType;
  readonly buttonTwoPressTime: KeyfobPressTime;
  readonly number: number;
  readonly " $fragmentType": "KeyfobButtonPressTimeField_keyfob";
};
export type KeyfobButtonPressTimeField_keyfob$key = {
  readonly " $data"?: KeyfobButtonPressTimeField_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobButtonPressTimeField_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobButtonPressTimeField_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOneAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreeAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOnePressTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoPressTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreePressTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourPressTime",
      "storageKey": null
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "1b18fa89f6f0809032255777e1a4b75e";

export default node;
