/**
 * @generated SignedSource<<9eac66deda0e9c975e963a677fc8705a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type ApplyTemplateModalQuery$variables = {
  customerId: string;
  hardwareModel: PanelHardwareModel;
  isInternational: boolean;
};
export type ApplyTemplateModalQuery$data = {
  readonly customer: {
    readonly id: string;
    readonly programmingTemplates?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string | null;
        readonly node: {
          readonly id: string;
          readonly name: string;
        } | null;
      }>;
    };
  } | null;
};
export type ApplyTemplateModalQuery = {
  response: ApplyTemplateModalQuery$data;
  variables: ApplyTemplateModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hardwareModel"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isInternational"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "customerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "items": [
            {
              "kind": "Variable",
              "name": "hardwareModels.0",
              "variableName": "hardwareModel"
            }
          ],
          "kind": "ListValue",
          "name": "hardwareModels"
        },
        {
          "kind": "Variable",
          "name": "isInternational",
          "variableName": "isInternational"
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": {
            "keys": [
              "name"
            ],
            "order": "ASC"
          }
        }
      ],
      "concreteType": "ProgrammingTemplatesConnection",
      "kind": "LinkedField",
      "name": "programmingTemplates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProgrammingTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProgrammingTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplyTemplateModalQuery",
    "selections": [
      {
        "alias": "customer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplyTemplateModalQuery",
    "selections": [
      {
        "alias": "customer",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb0f1e6119d6853f72f3ea44f247917b",
    "id": null,
    "metadata": {},
    "name": "ApplyTemplateModalQuery",
    "operationKind": "query",
    "text": "query ApplyTemplateModalQuery(\n  $customerId: ID!\n  $hardwareModel: PanelHardwareModel!\n  $isInternational: Boolean!\n) {\n  customer: node(id: $customerId) {\n    __typename\n    id\n    ... on Customer {\n      programmingTemplates(hardwareModels: [$hardwareModel], sort: {keys: [\"name\"], order: ASC}, isInternational: $isInternational) {\n        edges {\n          cursor\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "045359f7a6fc06799109675e23e23762";

export default node;
