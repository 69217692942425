import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";
import {
  asID,
  fromOutputOptionsId,
  idAsString,
  OutputOptions,
  toUnprogrammedOutputId,
} from "securecom-graphql/client";

const OutputOptionsContext = React.createContext<any>({});

export function OutputOptionsContextProvider(props: {
  outputOptions: any;
  children: React.ReactNode;
}) {
  return (
    <OutputOptionsContext.Provider value={props.outputOptions}>
      {props.children}
    </OutputOptionsContext.Provider>
  );
}

export function useOutputOptionsFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const outputOptions = React.useContext(OutputOptionsContext);

  const data = useFragment(fragmentInput, outputOptions as TKey);

  const { id } = useFragment(
    graphql`
      fragment OutputOptionsContext_outputOptions on OutputOptions {
        id
      }
    `,
    outputOptions as any
  );

  const update = (
    updater: (outputOptions: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  const onChange = (
    outputOptionsId: string,
    newOutputNumber: string,
    fieldName: string
  ) =>
    relayEnv.commitUpdate((store) => {
      const outputOptions = store.get<OutputOptions>(outputOptionsId);
      if (outputOptions) {
        const newOutputId = toUnprogrammedOutputId(
          fromOutputOptionsId(asID(outputOptionsId)).systemId,
          newOutputNumber
        );
        const nextOutput =
          store.get(idAsString(newOutputId)) ??
          store.create(idAsString(newOutputId), "UnprogrammedOutput");
        nextOutput.setValue(newOutputNumber, "formattedNumber");
        nextOutput.setValue(newOutputNumber, "number");
        outputOptions.setLinkedRecord(nextOutput, fieldName);
      }
    });

  return [data, update, onChange] as const;
}
