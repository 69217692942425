/**
 * @generated SignedSource<<b79a633fcf892e8557f6b428f5772dc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseHasVplexDevices_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly deviceType?: DeviceInformationType;
    readonly lxNumber?: string;
    readonly number?: number;
  }>;
  readonly supportsVplex: boolean;
  readonly " $fragmentType": "PanelContextUseHasVplexDevices_panel";
};
export type PanelContextUseHasVplexDevices_panel$key = {
  readonly " $data"?: PanelContextUseHasVplexDevices_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHasVplexDevices_panel">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lxNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseHasVplexDevices_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportsVplex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "XrDeviceInformation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Xt75DeviceInformation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "824afd7249a66ab8380fceaa83b9d8f5";

export default node;
