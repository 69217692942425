import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { resolveOutputNumbers } from "../../OutputInformationFields/utils";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "../../OutputOptionsFields/utils";
import { useHardwareModel } from "../../PanelContext";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useHouseCode } from "../../SystemOptionsFields/SystemOptionsContext";
import TextInput from "../../TextInput";
import { useAreaFragment } from "./AreaContext";
import { AreaArmedOutputNumberField_area$key } from "./__generated__/AreaArmedOutputNumberField_area.graphql";

export const areaArmedOutputNumberFieldId = (number: string) =>
  `area-armed-output-numer-field-${number}`;

function AreaArmedOutputNumberField() {
  const [{ number, armedOutputNumber }, updateArea] =
    useAreaFragment<AreaArmedOutputNumberField_area$key>(
      graphql`
        fragment AreaArmedOutputNumberField_area on Area {
          ... on XrArea {
            number
            armedOutputNumber
          }
          ... on Xt75Area {
            number
            armedOutputNumber
          }
        }
      `
    );

  const fieldId = areaArmedOutputNumberFieldId(number!);
  const hardwareModel = useHardwareModel();
  const houseCode = useHouseCode();
  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const { current: originalValue } = React.useRef(armedOutputNumber);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Armed Output Number"
      tooltip="Turn the output on when the Area is armed. Turn the output off when the Area is disarmed."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={armedOutputNumber}
        required
        maxLength={3}
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) => {
          updateArea((recordProxy) => {
            recordProxy.setValue(target.value, "armedOutputNumber");
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateArea((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                originalValue ? originalValue.toString() : "",
                hardwareModel,
                validNumericalRange
              ),
              "armedOutputNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaArmedOutputNumberField;
