/** 
 * @description Load a script conditionally
 * @param callback Any callback, usually one that sets state which is used to render a component * @param src The source url of your script
 * @param id The id that identifies the script in the DOM
 * @example 
 * //Inside a useEffect
 * loadScriptConditionally({
        callback: () => {
          setComponentIsLoaded(true);
        },
        src: "https://home.camect.com/js/v2/dmp_player_web_component.min.js",
        id: "camectScript",
      });
 */
const loadScriptConditionally = ({
  callback,
  src,
  id,
}: {
  callback: () => void;
  src: string;
  id: string;
}) => {
  const existingPlayerScript = document.getElementById(id);

  if (!existingPlayerScript) {
    const playerScript = document.createElement("script");
    playerScript.src = src;

    playerScript.id = id;

    document.body.appendChild(playerScript);

    playerScript.onload = () => {
      if (callback) callback();
    };
  }

  if (existingPlayerScript && callback) callback();
};

export default loadScriptConditionally;
