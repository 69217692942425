import graphql from "babel-plugin-relay/macro";
import { sleep } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { fetchQuery, useFragment, useRefetchableFragment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { asString, ControlSystem, ID } from "securecom-graphql/client";
import { Concept } from "../common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../common/FullProgrammingVersionEntryPoint";
import * as AreaInformation from "./XT75AreaInformationProgrammingConceptForm";
import * as BellOptions from "./XT75BellOptionsProgrammingConceptForm";
import * as CardFormats from "./XT75CardFormatsProgrammingConceptForm";
import * as Communication from "./XT75CommunicationProgrammingConceptForm";
import * as DeviceSetup from "./XT75DeviceSetupProgrammingConceptForm";
import * as Keyfobs from "./XT75KeyFobsProgrammingConceptForm";
import * as LockoutCode from "./XT75LockoutCodeProgrammingConceptForm";
import * as NetworkOptions from "./XT75NetworkOptionsProgrammingConceptForm";
import * as OutputInformation from "./XT75OutputInformationProgrammingConceptForm";
import * as OutputOptions from "./XT75OutputOptionsProgrammingConceptForm";
import * as RemoteOptions from "./XT75RemoteOptionsProgrammingConceptForm";
import * as SystemOptions from "./XT75SystemOptionsProgrammingConceptForm";
import * as SystemReports from "./XT75SystemReportsProgrammingConceptForm";
import * as ZoneInformation from "./XT75ZoneInformationsProgrammingConceptForm";
import { XT75FullProgrammingContainer_controlSystem$key } from "./__generated__/XT75FullProgrammingContainer_controlSystem.graphql";
import { XT75FullProgrammingContainer_templateStatus_controlSystem$key } from "./__generated__/XT75FullProgrammingContainer_templateStatus_controlSystem.graphql";
import { XT75FullProgrammingContainer_templateStatus_controlSystemQuery } from "./__generated__/XT75FullProgrammingContainer_templateStatus_controlSystemQuery.graphql";
import { XT75FullProgrammingTemplateQuery } from "./__generated__/XT75FullProgrammingTemplateQuery.graphql";

export const createConcepts = ({
  supportsCustomCardFormats,
}: {
  supportsCustomCardFormats: boolean;
}) =>
  [
    Communication,
    NetworkOptions,
    DeviceSetup,
    supportsCustomCardFormats ? CardFormats : null,
    RemoteOptions,
    SystemReports,
    SystemOptions,
    BellOptions,
    OutputInformation,
    OutputOptions,
    AreaInformation,
    ZoneInformation,
    Keyfobs,
    LockoutCode,
  ]
    .filter(isNotNullOrUndefined)
    .map(({ Form, ...rest }) => ({
      ...rest,
      Form: React.memo(Form),
    })) as Concept[];

export const xt75FullProgrammingControlSystem = graphql`
  fragment XT75FullProgramming_controlSystem on ControlSystem {
    id
    ...FullProgrammingForm_controlSystem
    ...ProgrammingTemplateForm_controlSystem
    ...ControlSystemContext_controlSystem
    ...XT75CommunicationProgrammingConceptForm_controlSystem
    ...XT75CommunicationProgrammingConceptFormNavButton_controlSystem
    ...XT75NetworkOptionsProgrammingConceptForm_controlSystem
    ...XT75DeviceSetupProgrammingConceptForm_controlSystem
    ...XT75DeviceSetupProgrammingConceptFormNavButton_controlSystem
    ...XT75CardFormatsProgrammingConceptForm_controlSystem
    ...XT75CardFormatsProgrammingConceptFormNavButton_controlSystem
    ...XT75RemoteOptionsProgrammingConceptForm_controlSystem
    ...XT75SystemReportsProgrammingConceptForm_controlSystem
    ...XT75SystemOptionsProgrammingConceptForm_controlSystem
    ...XT75BellOptionsProgrammingConceptForm_controlSystem
    ...XT75OutputInformationProgrammingConceptForm_controlSystem
    ...XT75OutputInformationProgrammingConceptFormNavButton_controlSystem
    ...XT75OutputOptionsProgrammingConceptForm_controlSystem
    ...XT75AreaInformationProgrammingConceptForm_controlSystem
    ...XT75AreaInformationProgrammingConceptFormNavButton_controlSystem
    ...XT75ZoneInformationsProgrammingConceptForm_controlSystem
    ...XT75ZoneInformationsProgrammingConceptFormNavButton_controlSystem
    ...XT75KeyFobsProgrammingConceptForm_controlSystem
    ...XT75KeyFobsProgrammingConceptFormNavButton_controlSystem
    ...XT75KeyFobsProgrammingConceptFormNavButton_controlSystem
    ...XT75LockoutCodeProgrammingConceptForm_controlSystem
    ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
    panel {
      ...PanelContextUseHasNetworkCommType_panel
      ...PanelContextUseHasWifiCommType_panel
    }
  }
`;

export const xt75FullProgrammingInlineControlSystem = graphql`
  fragment XT75FullProgrammingInline_controlSystem on ControlSystem
  @refetchable(queryName: "XT75FullProgrammingInlineQuery") {
    ...XT75CommunicationProgrammingConceptFormInline_controlSystem
    ...XT75NetworkOptionsProgrammingConceptFormInline_controlSystem
    ...XT75DeviceSetupProgrammingConceptFormInline_controlSystem
    ...XT75CardFormatsProgrammingConceptFormInline_controlSystem
    ...XT75RemoteOptionsProgrammingConceptFormInline_controlSystem
    ...XT75SystemReportsProgrammingConceptFormInline_controlSystem
    ...XT75SystemOptionsProgrammingConceptFormInline_controlSystem
    ...XT75BellOptionsProgrammingConceptFormInline_controlSystem
    ...XT75OutputInformationProgrammingConceptFormInline_controlSystem
    ...XT75OutputOptionsProgrammingConceptFormInline_controlSystem
    ...XT75AreaInformationProgrammingConceptFormInline_controlSystem
    ...XT75ZoneInformationsProgrammingConceptFormInline_controlSystem
    ...XT75KeyFobsProgrammingConceptFormInline_controlSystem
    ...XT75LockoutCodeProgrammingConceptFormInline_controlSystem
  }
`;

function XT75FullProgrammingContainer(props: {
  dealerId: ID;
  systemId: string;
  controlSystem: XT75FullProgrammingContainer_controlSystem$key;
}) {
  const controlSystemFragment = useFragment(
    graphql`
      fragment XT75FullProgrammingContainer_controlSystem on ControlSystem {
        name
        panel {
          softwareVersion
          supportsCustomCardFormats
        }
        ...XT75FullProgrammingContainer_templateStatus_controlSystem
      }
    `,
    props.controlSystem
  );
  const { name, panel } = controlSystemFragment;
  const { supportsCustomCardFormats } = panel;

  const concepts = React.useMemo(
    () => createConcepts({ supportsCustomCardFormats }),
    [supportsCustomCardFormats]
  );

  const [{ templatePreProgrammingJobStatus }, refetchJobStatus] =
    useRefetchableFragment<
      XT75FullProgrammingContainer_templateStatus_controlSystemQuery,
      XT75FullProgrammingContainer_templateStatus_controlSystem$key
    >(
      graphql`
        fragment XT75FullProgrammingContainer_templateStatus_controlSystem on ControlSystem
        @refetchable(
          queryName: "XT75FullProgrammingContainer_templateStatus_controlSystemQuery"
        ) {
          templatePreProgrammingJobStatus
        }
      `,
      controlSystemFragment
    );

  const jobPending = [
    "new",
    "created",
    "running",
    "acquired",
    "started",
  ].includes(templatePreProgrammingJobStatus ?? "");

  React.useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (jobPending) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetchJobStatus({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 5000));
        }, 5000)
      );
    }

    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [refetchJobStatus, jobPending]);

  return jobPending ? (
    <GenericPageFallback message={"Applying Template Programming..."} />
  ) : (
    <FullProgrammingVersionEntryPoint
      systemName={name}
      concepts={concepts}
      gqlQuery={graphql`
        query XT75FullProgrammingQuery($dealerId: ID!, $systemId: ID!) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: node(id: $systemId) {
            ... on ControlSystem {
              id
              name
              templatePreProgrammingJobStatus
              panel {
                hardwareModel
              }
              customer {
                id
              }
              ...XT75FullProgramming_controlSystem
              ...NetworkOptionsDhcpEnabledField_controlSystem
              ...XT75AreaInformationProgrammingConceptFormOriginalAreas_controlSystem
              ...XT75SystemOptionsProgrammingConceptFormInline_controlSystem
              ...XT75NetworkOptionsProgrammingConceptFormInline_controlSystem
              ...XT75RemoteOptionsProgrammingConceptFormInline_controlSystem
              ...XT75SystemReportsProgrammingConceptFormInline_controlSystem
              ...XT75OutputOptionsProgrammingConceptFormInline_controlSystem
              ...XT75LockoutCodeProgrammingConceptFormInline_controlSystem
              ...XT75BellOptionsProgrammingConceptFormInline_controlSystem
              ...XT75ZoneInformationsProgrammingConceptFormInline_controlSystem
              ...XT75CommunicationProgrammingConceptFormInline_controlSystem
              ...XT75DeviceSetupProgrammingConceptFormInline_controlSystem
              ...XT75CardFormatsProgrammingConceptFormInline_controlSystem
              ...XT75OutputInformationProgrammingConceptFormInline_controlSystem
              ...XT75KeyFobsProgrammingConceptFormInline_controlSystem
              ...XT75AreaInformationProgrammingConceptFormInline_controlSystem
            }
          }
          controlSystemInline: node(id: $systemId) {
            ... on ControlSystem {
              id
              ...XT75FullProgrammingInline_controlSystem
            }
          }
        }
      `}
      gqlQueryVariables={{
        systemId: props.systemId,
        dealerId: asString(props.dealerId),
      }}
      gqlFormControlSystemFragment={xt75FullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={
        xt75FullProgrammingInlineControlSystem
      }
      applyTemplate={applyTemplate(props.systemId, concepts)}
    />
  );
}

export default XT75FullProgrammingContainer;

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<XT75FullProgrammingTemplateQuery>(
    environment,
    graphql`
      query XT75FullProgrammingTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on Xt75ProgrammingTemplateConcepts {
                systemOptions {
                  included
                  systemType {
                    included
                    data
                  }
                }
                ...XT75CommunicationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75CardFormatsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75SystemReportsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75OutputInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75ZoneInformationsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75KeyFobsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75LockoutCodeProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      if (
        data.concepts?.systemOptions?.included &&
        data.concepts?.systemOptions?.systemType?.included
      ) {
        environment.commitUpdate((store) => {
          const controlSystem =
            store.get<ControlSystem>(systemId) ??
            (store.create(
              systemId,
              "ControlSystem"
            ) as RecordProxy<ControlSystem>);
          if (
            controlSystem
              .getLinkedRecord("systemOptions")
              ?.getValue("systemType") !==
            data.concepts?.systemOptions?.systemType?.data
          ) {
            concepts.forEach((concept) => {
              if (concept.applyTemplateData) {
                if (concept.conceptId === "xt75-system-options") {
                  concept.applyTemplateData(
                    data.concepts,
                    controlSystem,
                    store
                  );
                }
              }
            });
          }
        });
        await sleep(50);
      }
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
