/**
 * @ngdoc service
 * @name App.factory:VideoDevicesV2API
 *
 * @description
 * API factory for Video Devices
 *
 */
App.factory("VideoDevicesV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/video_devices/:video_device_id/:method",
      {},
      {
        get: { method: "GET", params: { video_device_id: "@video_device_id" } },
        save: {
          method: "PUT",
          params: { video_device_id: "@video_device_id" },
        }, // TODO: This should be a patch (Devin is working on this)
        refresh_channels: {
          method: "POST",
          params: {
            video_device_id: "@video_device_id",
            method: "refresh_channels",
          },
          timeout: 30000,
        },
        test_connection: {
          method: "POST",
          params: {
            video_device_id: "@video_device_id",
            method: "test_connection",
          },
        },
        reactivate: {
          method: "POST",
          params: { video_device_id: "@video_device_id", method: "reactivate" },
        },
        search_available: {
          method: "GET",
          params: { method: "search_available", identifier: "@identifier" },
        },
        forceDelete: {
          method: "DELETE",
          params: { video_device_id: "@video_device_id", force: "@force" },
        },
        add_remote_channel: {
          method: "POST",
          params: {
            video_device_id: "@video_device_id",
            method: "add_remote_channel",
            name: "@name",
            username: "@username",
            password: "@password",
            ip_address: "@ip_address",
            port: "@port",
          },
        },
        refresh: {
          method: "GET",
          params: { video_device_id: "@video_device_id", method: "refresh" },
        },
      }
    );
  },
]);
