import graphql from "babel-plugin-relay/macro";
import TextInput from "components/Inputs/TextInput"; //This is the correct import.  We do not want the error checking and change checking import for this field.
import * as React from "react";
import { usePanelFragment } from "../PanelContext";
import { FeatureKeysSendKeyField_panel$key } from "./__generated__/FeatureKeysSendKeyField_panel.graphql";

export const featureKeysSendKeyFieldId = () => "feature-keys-send-key";

function FeatureKeysSendKeyField(props: {
  updateFeatureKey: (featureKeyCode: string) => void;
  sending: boolean;
}) {
  const [featureKeyCode, setFeatureKeyCode] = React.useState("");
  const [panel] = usePanelFragment<FeatureKeysSendKeyField_panel$key>(graphql`
    fragment FeatureKeysSendKeyField_panel on Panel {
      serialNumber
    }
  `);

  const fieldId = featureKeysSendKeyFieldId();

  return (
    <div>
      <em>{`Serial No. ${panel.serialNumber}`}</em>
      <div
        style={{ display: "flex", paddingBottom: "2em", width: "fit-content" }}
      >
        <TextInput
          id={fieldId}
          name={fieldId}
          value={featureKeyCode}
          disabled={props.sending}
          onChange={({ target }) => {
            setFeatureKeyCode(target.value);
          }}
        />
        <button
          disabled={!featureKeyCode || props.sending}
          className="btn btn-info"
          onClick={() => props.updateFeatureKey(featureKeyCode)}
        >
          {props.sending ? `Sending Key` : `Send Key`}
        </button>
      </div>
    </div>
  );
}

export default FeatureKeysSendKeyField;
