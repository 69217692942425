import DaColors from "app/common/DaColors";
import SvgIcon from "common/components/web/Icons/SvgIcon";
import React from "react";
import styled, { css, keyframes } from "styled-components";

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

const animation = css`
  ${spin} 1.5s infinite linear;
`;

const Svg = styled(SvgIcon)`
  animation: ${animation};

  ${({ centered }) =>
    centered &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.5em;
      margin-left: -0.5em;
    `};
`;

type Props = React.ComponentProps<typeof Svg>;

const LoadingSpinner = (props: Props) => {
  const { fill = DaColors.Neutral300, ...otherProps } = props;

  return (
    <Svg
      {...otherProps}
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
      fill={fill}
    >
      <path d="M490.69,233.32h-3l-75.62.18-3.67.05a22.6,22.6,0,0,0,1.83,45.12h81a22.74,22.74,0,0,0-.56-45.36ZM381.11,163.07l.05-.05,54.37-54.25,2.87-2.92A22.75,22.75,0,0,0,405.87,74.2l-2.08,2.12-53.35,53.57-2.56,2.58a22.6,22.6,0,0,0,33.21,30.6ZM256,.29a22.77,22.77,0,0,0-22.73,21.27v3l.22,75.52v3.65a22.63,22.63,0,0,0,45.18-1.79v-.1l0-76.72V21A22.79,22.79,0,0,0,256,.29ZM162.89,131,108.6,76.7l-2.92-2.9A22.76,22.76,0,0,0,74,106.27l2.1,2.12,53.63,53.24,2.59,2.58A22.61,22.61,0,0,0,163,131ZM103.54,278.43a22.59,22.59,0,0,0-1.79-45.11h-81a22.74,22.74,0,0,0,.57,45.34h3l75.62-.25h3.64Zm27.33,70.46-.06.09L76.46,403.25l-2.88,2.87a22.77,22.77,0,0,0,32.51,31.7l2.1-2.13,53.33-53.57,2.57-2.57a22.63,22.63,0,0,0-33.24-30.66Zm147.6,59.39a22.62,22.62,0,0,0-45.16,1.79v.09l0,76.73V491a22.79,22.79,0,0,0,45.43-.56v-3l-.25-75.52Zm157.43-4.66-53.64-53.24-2.57-2.58A22.61,22.61,0,0,0,349,381l.08.09,54.28,54.26,2.92,2.84A22.76,22.76,0,0,0,438,405.74Z" />
    </Svg>
  );
};

export default LoadingSpinner;
