import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsVideoReportsField_systemReports$key } from "./__generated__/SystemReportsVideoReportsField_systemReports.graphql";

export const systemReportsVideoReportsFieldId = () =>
  "system-reports-video-reports";

function SystemReportsVideoReportsField() {
  const [{ videoSystemReports }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsVideoReportsField_systemReports$key>(
      graphql`
        fragment SystemReportsVideoReportsField_systemReports on SystemReports {
          videoSystemReports
        }
      `
    );

  const fieldId = systemReportsVideoReportsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Video Reports"
      tooltip="Enables the panel to forward video events received from supported camera systems to the receiver."
    >
      <Switch
        label="Video Reports"
        id={fieldId}
        name={fieldId}
        checked={videoSystemReports}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(!videoSystemReports, "videoSystemReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsVideoReportsField;
