App.service("CustomRolesService", [
  "$q",
  "CustomRolesAPI",
  "DAYS_OF_WEEK",
  function ($q, CustomRolesAPI, DAYS_OF_WEEK) {
    var _this = this;
    var days = Object.keys(DAYS_OF_WEEK);
    var cachedDealerId = undefined;
    this.DAY_START_TIME = "00:00:00";
    this.DAY_END_TIME = "23:59:59";
    this.newRoleTemplate = undefined;
    this.roleTimeTemplate = undefined;
    this.roleCacheUpToDate = false;
    this.cachedRoles = [];
    this.cachedTimeZones = [];
    var permissionsLabels = {
      none: "Hidden",
      viewOnly: "View Only",
      viewEdit: "View & Edit",
      viewEditDelete: "View, Edit & Delete",
      viewActivate: "View & Activate",
      viewActivateDeactivate: "View, Activate & Deactivate",
      viewIssuePurchase: "View/Issue/Purchase",
      viewIssue: "View & Issue",
    };

    this.dropDownOptionsTechApp = {
      customers: [permissionsLabels.viewOnly, permissionsLabels.viewEdit],
      app_users: [
        permissionsLabels.viewOnly,
        permissionsLabels.viewEdit,
        permissionsLabels.viewEditDelete,
      ],
      systems: [permissionsLabels.viewOnly, permissionsLabels.viewEdit],
      features: [permissionsLabels.viewOnly, permissionsLabels.viewEdit],
      cellular: [
        permissionsLabels.viewOnly,
        permissionsLabels.viewActivate,
        permissionsLabels.viewActivateDeactivate,
      ],
      mobile_credentials: [
        permissionsLabels.none,
        permissionsLabels.viewIssuePurchase,
      ],
      programming: [
        permissionsLabels.none,
        permissionsLabels.viewOnly,
        permissionsLabels.viewEdit,
        permissionsLabels.viewEditDelete,
      ],
    };

    this.dropDownOptionsCustom = {
      customers: [
        permissionsLabels.none,
        permissionsLabels.viewOnly,
        permissionsLabels.viewEdit,
        permissionsLabels.viewEditDelete,
      ],
      systems: [
        permissionsLabels.viewOnly,
        permissionsLabels.viewEdit,
        permissionsLabels.viewEditDelete,
      ],
      app_users: [
        permissionsLabels.none,
        permissionsLabels.viewOnly,
        permissionsLabels.viewEditDelete,
      ],
      user_codes: [
        permissionsLabels.none,
        permissionsLabels.viewOnly,
        permissionsLabels.viewEditDelete,
      ],
      personnel: [
        permissionsLabels.none,
        permissionsLabels.viewOnly,
        permissionsLabels.viewEditDelete,
      ],
      schedules: [
        permissionsLabels.none,
        permissionsLabels.viewOnly,
        permissionsLabels.viewEditDelete,
      ],
      profiles: [
        permissionsLabels.none,
        permissionsLabels.viewOnly,
        permissionsLabels.viewEditDelete,
      ],
      cellular: [
        permissionsLabels.viewOnly,
        permissionsLabels.viewActivate,
        permissionsLabels.viewActivateDeactivate,
      ],
      mobile_credentials: [
        permissionsLabels.none,
        permissionsLabels.viewIssuePurchase,
      ],
    };

    /**
     * Indicates the user is one that could have a role assigned
     * @param {*} user - the user
     * @returns {boolean} - true if user could have role
     */
    this.mayHaveCustomRole = function (user) {
      if (
        angular.isDefined(user) &&
        user.hasOwnProperty("accessible_type") &&
        user.hasOwnProperty("role")
      ) {
        return (
          user.accessible_type.toLowerCase() === "dealer" &&
          (user.role.toLowerCase() === "technician" || user.user_auth_id)
        );
      }
      return false;
    };

    /**
     * Get the roles for a dealer
     * @param {int} dealerId
     */
    this.getRoles = function (dealerId) {
      if (dealerId !== cachedDealerId) {
        cachedDealerId = dealerId;
        this.roleCacheUpToDate = false;
        this.cachedRoles = [];
      }
      if (this.roleCacheUpToDate) {
        return $q.resolve(this.cachedRoles);
      } else {
        var deferred = $q.defer();
        retrieveRoles(dealerId)
          .then(
            function (preparedRoles) {
              _this.roleCacheUpToDate = true;
              _this.cachedRoles = preparedRoles;
              deferred.resolve(preparedRoles);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      }
    };

    /**
     * Update the for a dealer from the server
     * @param {int} dealerId
     */
    this.refreshRoles = function (dealerId) {
      var deferred = $q.defer();
      this.roleCacheUpToDate = false;
      this.getRoles(dealerId)
        .then(
          function (roles) {
            deferred.resolve(roles);
          },
          function () {
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Get a template for a new role for a given dealer
     * @param {int} dealerId
     */
    this.getNewRoleTemplate = function (dealerId, authId) {
      if (
        angular.isDefined(cachedDealerId) &&
        angular.isDefined(this.newRoleTemplate) &&
        this.newRoleTemplate.hasOwnProperty("dealer_id") &&
        this.newRoleTemplate.dealer_id === cachedDealerId
      ) {
        return $q.resolve(this.newRoleTemplate);
      }
      var deferred = $q.defer();
      CustomRolesAPI.new(
        { dealer_id: dealerId, auth_id: authId },
        function (data) {
          _this.newRoleTemplate = cleanData(data);
          _this.newRoleTemplate["isNew"] = true;
          prepareForGUI(_this.newRoleTemplate);
          deferred.resolve(_this.newRoleTemplate);
        },
        function (error) {
          console.error(
            "Error in  getNewRoleTemplate() from CustomRolesAPI 'new': " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Get a specific role for a dealer
     * @param {int} dealerId
     * @param {int} roleId
     */
    this.getRole = function (dealerId, roleId) {
      if (this.roleCacheUpToDate) {
        var role = this.cachedRoles.find(function (r) {
          return r.id === +roleId;
        });
        if (angular.isDefined(role)) {
          return $q.resolve(role);
        }
      }
      var deferred = $q.defer();
      CustomRolesAPI.getRole(
        { dealer_id: dealerId, role_id: roleId },
        function (data) {
          var role = cleanData(data);
          prepareForGUI(role);
          deferred.resolve(role);
        },
        function (error) {
          console.error(
            "getRole() error getting role: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Send a new role to the server
     * @param {*} role
     */
    this.createRole = function (role) {
      var deferred = $q.defer();
      prepareForAPI(role)
        .then(
          function (preparedRole) {
            CustomRolesAPI.create(
              { dealer_id: role.dealer_id },
              preparedRole,
              function (data) {
                _this.roleCacheUpToDate = false;
                var role = cleanData(data);
                prepareForGUI(role);
                deferred.resolve(role);
              },
              function (error) {
                console.error(
                  "Error in  createRole() from CustomRolesAPI 'create': " +
                    angular.toJson(error)
                );
                deferred.reject(error);
              }
            );
          },
          function () {
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Modify an existing role
     * @param {*} role
     */
    this.updateRole = function (role) {
      var deferred = $q.defer();
      prepareForAPI(role)
        .then(
          function (preparedRole) {
            CustomRolesAPI.update(
              { dealer_id: role.dealer_id, role_id: role.id },
              preparedRole,
              function (data) {
                _this.roleCacheUpToDate = false;
                var role = cleanData(data);
                prepareForGUI(role);
                deferred.resolve(role);
              },
              function (error) {
                console.error(
                  "Error in  updateRole() from CustomRolesAPI 'update': " +
                    angular.toJson(error)
                );
                deferred.reject(error);
              }
            );
          },
          function () {
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Remove a role from the server
     * @param {*} role
     */
    this.deleteRole = function (role) {
      var deferred = $q.defer();
      CustomRolesAPI.destroy(
        { dealer_id: role.dealer_id, role_id: role.id },
        function () {
          _this.roleCacheUpToDate = false;
          deferred.resolve();
        },
        function (error) {
          console.error("Error deleting role: " + angular.toJson(error));
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Get the full list of time zones supported by the server
     * @param {int} dealerId
     */
    this.getTimeZones = function (dealerId) {
      if (this.cachedTimeZones.length > 0) {
        return $q.resolve(this.cachedTimeZones);
      }
      var deferred = $q.defer();
      CustomRolesAPI.getTimeZones(
        { dealer_id: dealerId },
        function (data) {
          _this.cachedTimeZones = cleanData(data);
          deferred.resolve(_this.cachedTimeZones);
        },
        function (error) {
          console.error(
            "Error retrieving time zones: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Get the role assigned to a specific user
     * @param {int} dealer_id
     * @param {int} user_id
     */
    this.getUserRole = function (dealer_id, user_id) {
      var deferred = $q.defer();
      CustomRolesAPI.getRoleOfUser(
        { dealer_id: dealer_id, data_id: user_id },
        function (data) {
          var role = cleanData(data);
          prepareForGUI(role);
          deferred.resolve(role);
        },
        function (error) {
          // Not finding a role for a user means they don't have one assigned; is not a problem
          if (propertyExistsAndEquals(error, "status", 404)) {
            deferred.resolve(null);
          } else {
            console.error("Error retrieving role: " + angular.toJson(error));
            deferred.reject(error);
          }
        }
      );
      return deferred.promise;
    };

    /**
     * Get the list of users that are assigned a specific role
     * @param {*} role
     */
    this.getRoleAssignees = function (role) {
      var deferred = $q.defer();
      CustomRolesAPI.getRoleAssignees(
        { dealer_id: role.dealer_id, role_id: role.id },
        function (data) {
          var users = cleanData(data);
          deferred.resolve(users);
        },
        function (error) {
          console.error(
            "Error getting users of role: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Assign a role to one or more users
     * @param {*} role
     * @param {*|[*]} users - single user or array of users
     * @returns {Promise}
     */
    this.assignRoleToUsers = function (role, users) {
      if (
        angular.isUndefined(role) ||
        !role.hasOwnProperty("dealer_id") ||
        !role.hasOwnProperty("id") ||
        angular.isUndefined(users)
      ) {
        return $q.reject("Not valid parameters");
      }
      var preparedUsers = prepareAPIUsersArray(users);
      var deferred = $q.defer();
      if (users.length === 0) {
        deferred.resolve();
      } else {
        CustomRolesAPI.assignRoleToUsers(
          { dealer_id: role.dealer_id, role_id: role.id },
          angular.toJson(preparedUsers),
          function () {
            deferred.resolve();
          },
          function (error) {
            console.error(
              "Error adding role to users: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    };

    /**
     * Remove a role from one or more users
     * @param {*} role
     * @param {*|[*]} users - single user or array of users
     * @returns {Promise}
     */
    this.removeRoleFromUsers = function (role, users) {
      if (
        angular.isUndefined(role) ||
        !role.hasOwnProperty("dealer_id") ||
        !role.hasOwnProperty("id") ||
        angular.isUndefined(users)
      ) {
        return $q.reject("Not valid parameters");
      }
      var preparedUsers = prepareAPIUsersArray(users);
      var deferred = $q.defer();
      if (users.length === 0) {
        deferred.resolve();
      } else {
        CustomRolesAPI.removeRoleFromUsers(
          { dealer_id: role.dealer_id, role_id: role.id },
          angular.toJson(preparedUsers),
          function () {
            deferred.resolve();
          },
          function (error) {
            console.error(
              "Error removing role from users: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    };

    /**
     * Make an array of objects with ids
     * @param {*|[*]} users - single user or array of users
     * @returns {Array}
     */
    function prepareAPIUsersArray(users) {
      var preparedUsers = [];
      if (!angular.isArray(users)) {
        users = [users];
      }
      angular.forEach(users, function (u) {
        var user = { user: { id: u.id } };
        preparedUsers.push(user);
      });
      return preparedUsers;
    }

    /**
     * Change the time string from the server into one that can be used in the GUI, adjusted for time zone
     * @param {string} roleTime
     * @returns {string}
     */
    this.getGUIDateString = function (roleTime) {
      var newDate = new Date(2000, 0, 1);
      var roleTimeParts = roleTime.split(":");
      newDate.setHours(roleTimeParts[0], roleTimeParts[1], roleTimeParts[2]);
      var timeZoneOffset = newDate.getTimezoneOffset() * 60 * 1000; // user's offset time, in minutes
      var adjustedDate = new Date(newDate.getTime() - timeZoneOffset);
      return adjustedDate.toISOString();
    };

    /**
     * Removes the used promise data from an async call.
     * note: If left and the object is extended/merged, a call stack exceeded error can occur.
     * @param {{}|[]} data - object or array from server
     * @returns {{}|[]} - data without used promise info
     */
    function cleanData(data) {
      var cleanObject = angular.isArray(data) ? [] : {};
      angular.merge(cleanObject, data);
      delete cleanObject.$promise;
      delete cleanObject.$resolved;
      return cleanObject;
    }

    /**
     * Get roles for the dealer from the server, format them for use in the GUI
     * @param {int} dealerId
     */
    function retrieveRoles(dealerId) {
      var deferred = $q.defer();
      CustomRolesAPI.getRoles(
        { dealer_id: dealerId },
        function (data) {
          var roles = cleanData(data);
          angular.forEach(roles, function (role) {
            prepareForGUI(role);
          });
          deferred.resolve(roles);
        },
        function (error) {
          console.error("Error retrieving roles: " + angular.toJson(error));
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Prepare a role from the server for use in the GUI
     * @param {*} role
     */
    function prepareForGUI(role) {
      if (role.hasOwnProperty("isNew") && role.isNew === true) {
        role.name = "New Role";
        role.description = "";
      }
      if (hasValidAuthTime(role)) {
        role["all_day"] = scheduledAllDay(role.user_auth_times[0]);
        role["start_time"] = _this.getGUIDateString(
          role.user_auth_times[0].start_time
        );
        role["end_time"] = _this.getGUIDateString(
          role.user_auth_times[0].end_time
        );
        angular.forEach(days, function (day) {
          role[day.toLowerCase()] =
            indexOfByPropertyValue(
              role.user_auth_times,
              "day_of_week",
              DAYS_OF_WEEK[day].number
            ) !== -1;
        });
      } else {
        role["all_day"] = false;
        role["start_time"] = "";
        role["end_time"] = "";
        angular.forEach(days, function (day) {
          role[day.toLowerCase()] = false;
        });
      }
      if (!role.hasOwnProperty("user_auth_permissions")) {
        console.warn(
          "CustomRolesService->prepareForGUI() - No permissions set attached to role: " +
            angular.toJson(role)
        );
      } else {
        role["GUIPermissions"] = {
          customers: makeReadableCRUD(
            role.user_auth_permissions.customers_add,
            role.user_auth_permissions.customers_view,
            role.user_auth_permissions.customers_edit,
            role.user_auth_permissions.customers_delete,
            true
          ),
          app_users: makeReadableCRUD(
            role.user_auth_permissions.app_users_add,
            role.user_auth_permissions.app_users_view,
            role.user_auth_permissions.app_users_edit,
            role.user_auth_permissions.app_users_delete,
            true
          ),
          systems: makeReadableCRUD(
            role.user_auth_permissions.systems_add,
            role.user_auth_permissions.systems_view,
            role.user_auth_permissions.systems_edit,
            role.user_auth_permissions.systems_delete,
            true
          ),
          included_features: makeReadableViewEdit(
            role.user_auth_permissions.included_features_view,
            role.user_auth_permissions.included_features_edit
          ),
          add_on_features: makeReadableViewEdit(
            role.user_auth_permissions.add_on_features_view,
            role.user_auth_permissions.add_on_features_edit
          ),
          cellular: makeReadableCellPermissions(
            role.user_auth_permissions.cellular_view,
            role.user_auth_permissions.cellular_activate,
            role.user_auth_permissions.cellular_deactivate
          ),
          mobile_credentials: makeReadableMobileCredentialPermissions(
            role.user_auth_permissions.mobile_credentials_view,
            role.user_auth_permissions.mobile_credentials_purchase,
            role.user_auth_permissions.mobile_credentials_allocate
          ),
          programming: makeReadableCRUD(
            role.user_auth_permissions.programming_add,
            role.user_auth_permissions.programming_view,
            role.user_auth_permissions.programming_edit,
            role.user_auth_permissions.programming_delete,
            true
          ),
          personnel: makeReadableCRUD(
            false,
            role.user_auth_permissions.personnel_view,
            role.user_auth_permissions.personnel_edit,
            role.user_auth_permissions.personnel_delete,
            true
          ),
          user_codes: makeReadableCRUD(
            false,
            role.user_auth_permissions.user_codes_view,
            role.user_auth_permissions.user_codes_edit,
            role.user_auth_permissions.user_codes_delete,
            true
          ),
          schedules: makeReadableCRUD(
            false,
            role.user_auth_permissions.schedules_view,
            role.user_auth_permissions.schedules_edit,
            role.user_auth_permissions.schedules_delete,
            true
          ),
          profiles: makeReadableCRUD(
            false,
            role.user_auth_permissions.profiles_view,
            role.user_auth_permissions.profiles_edit,
            role.user_auth_permissions.profiles_delete,
            true
          ),
        };
      }
    }

    /**
     * Format a human-readable version of a create, read, update and destroy set of permissions permission
     * @param {boolean} create
     * @param {boolean} read
     * @param {boolean} update
     * @param {boolean} destroy
     * @param {boolean} [mayBeNone] - can have none as an option
     * @returns {string}
     */
    function makeReadableCRUD(create, read, update, destroy, mayBeNone) {
      if (
        (create && read && update && destroy) ||
        (!create && read && update && destroy)
      ) {
        return permissionsLabels.viewEditDelete;
      } else if (create && read && update && !destroy) {
        return permissionsLabels.viewEdit;
      } else if (!create && read && !update && !destroy) {
        return permissionsLabels.viewOnly;
      } else if (mayBeNone && !create && !read && !update && !destroy) {
        return permissionsLabels.none;
      } else {
        return "";
      }
    }

    /**
     * Format a human-readable version of a read and update set of permissions
     * @param {boolean} read
     * @param {boolean} update
     * @returns {string}
     */
    function makeReadableViewEdit(read, update) {
      if (read && update) {
        return permissionsLabels.viewEdit;
      } else if (read && !update) {
        return permissionsLabels.viewOnly;
      } else {
        return "";
      }
    }

    /**
     * Format a human-readable version of the cellular permissions
     * @param {boolean} view
     * @param {boolean} activate
     * @param {boolean} deactivate
     * @returns {string}
     */
    function makeReadableCellPermissions(view, activate, deactivate) {
      if (view && activate && deactivate) {
        return permissionsLabels.viewActivateDeactivate;
      } else if (view && activate && !deactivate) {
        return permissionsLabels.viewActivate;
      } else if (view && !activate && !deactivate) {
        return permissionsLabels.viewOnly;
      } else {
        return "";
      }
    }

    /**
     * Format a human-readable version of the mobile credentials permissions
     * @param {boolean} view
     * @param {boolean} purchase
     * @param {boolean} allocate
     * @returns {string}
     */
    function makeReadableMobileCredentialPermissions(view, purchase, allocate) {
      if (view && purchase && allocate) {
        return permissionsLabels.viewIssuePurchase;
      } else {
        return permissionsLabels.none;
      }
    }

    /**
     * Prepare a role from the GUI for the server
     * @param {*} role
     */
    function prepareForAPI(role) {
      var deferred = $q.defer();
      var roleCopy = {};
      angular.merge(roleCopy, role);
      delete roleCopy.isMock;
      delete roleCopy.$promise;
      delete roleCopy.$resolved;
      var startTime = roleCopy.all_day
        ? _this.DAY_START_TIME
        : prepareAPITime(roleCopy.start_time);
      delete roleCopy.start_time;
      var endTime = roleCopy.all_day
        ? _this.DAY_END_TIME
        : prepareAPITime(roleCopy.end_time);
      delete roleCopy.all_day;
      delete roleCopy.end_time;
      //prepareForGUI(roleCopy);
      setPermissionsForAPI(roleCopy);
      //prepareForGUI(roleCopy);
      delete roleCopy.GUIPermissions;
      var promises = [];
      var deletedDayNumbers = [];
      angular.forEach(days, function (day) {
        var dayNumber = DAYS_OF_WEEK[day].number;
        var dayIndex = indexOfByPropertyValue(
          roleCopy.user_auth_times,
          "day_of_week",
          dayNumber
        );
        if (dayIndex === -1) {
          if (roleCopy[day.toLowerCase()] === true) {
            promises.push(
              createTime(
                roleCopy,
                dayNumber,
                startTime,
                endTime,
                roleCopy.isNew
              )
                .then(function (time) {
                  roleCopy.user_auth_times.push(time);
                })
                .catch(function (error) {
                  console.error(error);
                })
            );
          }
        } else if (!roleCopy.isNew) {
          if (roleCopy[day.toLowerCase()] === true) {
            if (
              roleCopy.user_auth_times[dayIndex].start_time !== startTime ||
              roleCopy.user_auth_times[dayIndex].end_time !== endTime
            ) {
              roleCopy.user_auth_times[dayIndex].start_time = startTime;
              roleCopy.user_auth_times[dayIndex].end_time = endTime;
              promises.push(
                updateTime(roleCopy, roleCopy.user_auth_times[dayIndex])
                  .then(function (time) {
                    roleCopy.user_auth_times[dayIndex] = time;
                  })
                  .catch(function (error) {
                    console.error(error);
                  })
              );
            }
          } else {
            promises.push(
              destroyTime(roleCopy, roleCopy.user_auth_times[dayIndex].id)
                .then(function () {
                  deletedDayNumbers.push(dayNumber);
                })
                .catch(function (error) {
                  console.error(error);
                })
            );
          }
        }
      });
      delete roleCopy.isNew;
      $q.all(promises)
        .then(
          function (values) {
            finalizeAPIPreparation(true, values, roleCopy, deletedDayNumbers);
            deferred.resolve(angular.toJson(roleCopy));
          },
          function (values) {
            finalizeAPIPreparation(false, values, roleCopy, deletedDayNumbers);
            // We've done all we can do to prepare the object. The subsequent POST/PUT may still work.
            deferred.resolve(angular.toJson(roleCopy));
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function setPermissionsForAPI(role) {
      switch (role.GUIPermissions.cellular) {
        case permissionsLabels.viewActivateDeactivate:
          role.user_auth_permissions.cellular_view = true;
          role.user_auth_permissions.cellular_activate = true;
          role.user_auth_permissions.cellular_deactivate = true;
          break;
        case permissionsLabels.viewActivate:
          role.user_auth_permissions.cellular_view = true;
          role.user_auth_permissions.cellular_activate = true;
          role.user_auth_permissions.cellular_deactivate = false;
          break;
        case permissionsLabels.viewOnly:
          role.user_auth_permissions.cellular_view = true;
          role.user_auth_permissions.cellular_activate = false;
          role.user_auth_permissions.cellular_deactivate = false;
          break;
        default:
          role.user_auth_permissions.cellular_view = false;
          role.user_auth_permissions.cellular_activate = false;
          role.user_auth_permissions.cellular_deactivate = false;
      }

      switch (role.GUIPermissions.mobile_credentials) {
        case permissionsLabels.viewIssuePurchase:
          role.user_auth_permissions.mobile_credentials_view = true;
          role.user_auth_permissions.mobile_credentials_purchase = true;
          role.user_auth_permissions.mobile_credentials_allocate = true;
          break;
        default:
          role.user_auth_permissions.mobile_credentials_view = false;
          role.user_auth_permissions.mobile_credentials_purchase = false;
          role.user_auth_permissions.mobile_credentials_allocate = false;
          break;
      }
      var permissionCRUDTypes = [
        "customers",
        "app_users",
        "systems",
        "personnel",
        "user_codes",
        "schedules",
        "profiles",
      ];
      angular.forEach(permissionCRUDTypes, function (type) {
        setCRUDPropertiesForAPI(role, type);
      });
      var permissionsViewEditTypes = ["included_features", "add_on_features"];
      angular.forEach(permissionsViewEditTypes, function (type) {
        setViewEditPropertiesForAPI(role, type);
      });
    }

    function setCRUDPropertiesForAPI(role, type) {
      switch (role.GUIPermissions[type]) {
        case permissionsLabels.viewEditDelete:
          role.user_auth_permissions[type + "_add"] = true;
          role.user_auth_permissions[type + "_view"] = true;
          role.user_auth_permissions[type + "_edit"] = true;
          role.user_auth_permissions[type + "_delete"] = true;
          break;
        case permissionsLabels.viewEdit:
          role.user_auth_permissions[type + "_add"] = true;
          role.user_auth_permissions[type + "_view"] = true;
          role.user_auth_permissions[type + "_edit"] = true;
          role.user_auth_permissions[type + "_delete"] = false;
          break;
        case permissionsLabels.viewOnly:
          role.user_auth_permissions[type + "_add"] = false;
          role.user_auth_permissions[type + "_view"] = true;
          role.user_auth_permissions[type + "_edit"] = false;
          role.user_auth_permissions[type + "_delete"] = false;
          break;
        case permissionsLabels.none:
          role.user_auth_permissions[type + "_add"] = false;
          role.user_auth_permissions[type + "_view"] = false;
          role.user_auth_permissions[type + "_edit"] = false;
          role.user_auth_permissions[type + "_delete"] = false;
          break;
      }
    }

    function setViewEditPropertiesForAPI(role, type) {
      switch (role.GUIPermissions[type]) {
        case permissionsLabels.viewEdit:
          role.user_auth_permissions[type + "_view"] = true;
          role.user_auth_permissions[type + "_edit"] = true;
          break;
        case permissionsLabels.viewOnly:
          role.user_auth_permissions[type + "_view"] = true;
          role.user_auth_permissions[type + "_edit"] = false;
          break;
      }
    }

    /**
     * Clean-up routine for prepareForAPI(), called whether success or fail
     * @param {boolean} success
     * @param {[*]} qAllResultValues - result of the $q.all CRUD times
     * @param {*} roleCopy - the role being prepared
     * @param {[int]} deletedDayNumbers - day (times) numbers that were deleted from the server
     */
    function finalizeAPIPreparation(
      success,
      qAllResultValues,
      roleCopy,
      deletedDayNumbers
    ) {
      var resultSummary = success
        ? "Successfully created, updated and/or deleted role times."
        : "Unable to create, update and/or delete role times.";
      angular.forEach(deletedDayNumbers, function (dayNumber) {
        var dayIndex = indexOfByPropertyValue(
          roleCopy.user_auth_times,
          "day_of_week",
          dayNumber
        );
        roleCopy.user_auth_times.splice(dayIndex, 1);
      });
      angular.forEach(days, function (day) {
        delete roleCopy[day.toLowerCase()];
      });
    }

    /**
     * Convert a GUI time string to one for the server, adjusting for time zone
     * @param {string} dateString
     * @returns {string}
     */
    function prepareAPITime(dateString) {
      var date = new Date(dateString);
      var timeZoneOffset = date.getTimezoneOffset() * 60 * 1000; // user's offset time, in minutes
      var adjustedDate = new Date(date.getTime() + timeZoneOffset);
      var hours = adjustedDate.getHours();
      var minutes = adjustedDate.getMinutes();
      var seconds = adjustedDate.getSeconds();
      return (
        apiTimePad(hours) +
        ":" +
        apiTimePad(minutes) +
        ":" +
        apiTimePad(seconds)
      );
    }

    /**
     * Adds a zero to single digit numbers to prep time string for API
     * @param {int} number - the number to pad
     * @returns {string}
     */
    function apiTimePad(number) {
      return number < 10 ? "0" + number.toString() : number.toString();
    }

    /**
     * Get a template for a role time
     * @param {int} dealerId
     */
    function getNewRoleTime(dealerId) {
      // If a role time template has been cached for the dealer
      if (
        angular.isDefined(_this.roleTimeTemplate) &&
        angular.isDefined(cachedDealerId) &&
        dealerId === cachedDealerId
      ) {
        var newRoleTime = {};
        angular.merge(newRoleTime, _this.roleTimeTemplate);
        // Resolve immediately
        return $q.resolve(newRoleTime);
      }
      // Otherwise, create a promise and get one from the server
      var deferred = $q.defer();
      CustomRolesAPI.newTime(
        { dealer_id: dealerId },
        function (data) {
          _this.roleTimeTemplate = cleanData(data);
          deferred.resolve(_this.roleTimeTemplate);
        },
        function (error) {
          console.error("getNewRoleTime() error: " + angular.toJson(error));
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Is the time scheduled ro all day
     * @param {*} roleTime - time
     * @returns {boolean} - true if start and end times match all day times
     */
    function scheduledAllDay(roleTime) {
      return (
        roleTime.start_time === _this.DAY_START_TIME &&
        roleTime.end_time === _this.DAY_END_TIME
      );
    }

    /**
     * Indicates the role has at least 1 valid time
     * @param {*} role
     * @returns {boolean} - true if the role has a valid time
     */
    function hasValidAuthTime(role) {
      return (
        role.hasOwnProperty("user_auth_times") &&
        role.user_auth_times.length > 0 &&
        role.user_auth_times[0].hasOwnProperty("start_time") &&
        role.user_auth_times[0].hasOwnProperty("end_time")
      );
    }

    /**
     * Send a new time to the server
     * @param {*} role
     * @param {int} dayNumber
     * @param {string} startTime
     * @param {string} endTime
     * @param {boolean} forNewRole
     */
    function createTime(role, dayNumber, startTime, endTime, forNewRole) {
      var deferred = $q.defer();
      getNewRoleTime(role.dealer_id)
        .then(
          function (roleTimeTemplate) {
            var roleTime = {};
            angular.merge(roleTime, roleTimeTemplate);
            roleTime.user_auth_id = role.id;
            roleTime.start_time = startTime;
            roleTime.end_time = endTime;
            roleTime.day_of_week = dayNumber;
            // Times for new roles can be sent in the post for the new role
            if (forNewRole) {
              deferred.resolve(roleTime);
            } else {
              // Times for existing roles must be sent by themselves
              CustomRolesAPI.createTime(
                { dealer_id: role.dealer_id, role_id: role.id },
                angular.toJson(roleTime),
                function (data) {
                  var time = cleanData(data);
                  deferred.resolve(time);
                },
                function (error) {
                  console.error(
                    "Error creating time: " + angular.toJson(error)
                  );
                  deferred.reject();
                }
              );
            }
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * Update an existing time on the server
     * @param {*} role
     * @param {*} time
     */
    function updateTime(role, time) {
      var deferred = $q.defer();
      CustomRolesAPI.updateTime(
        { dealer_id: role.dealer_id, role_id: role.id, data_id: time.id },
        angular.toJson(time),
        function (data) {
          var time = cleanData(data);
          deferred.resolve(time);
        },
        function (error) {
          console.error("Error updating time: " + error);
          deferred.reject();
        }
      );
      return deferred.promise;
    }

    /**
     * Delete a time from the server
     * @param {*} role
     * @param {int} timeId
     */
    function destroyTime(role, timeId) {
      var deferred = $q.defer();
      CustomRolesAPI.destroyTime(
        { dealer_id: role.dealer_id, role_id: role.id, data_id: timeId },
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error("Error deleting time: " + angular.toJson(error));
          deferred.reject();
        }
      );
      return deferred.promise;
    }

    /**
     * Tech roles that require a system test to access a panel, require that a tech claim the panel for themselves,
     * locking out other techs. This function claims a panel.
     * @param dealerId
     * @param customerId
     * @param panelId
     */
    this.claimSystem = function (dealerId, customerId, panelId) {
      var deferred = $q.defer();
      CustomRolesAPI.claimSystem(
        { dealer_id: dealerId, data_id: customerId, data_id_2: panelId },
        {},
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error("Error claiming system: " + angular.toJson(error));
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.claimedSystems = function (dealerId, userId) {
      var deferred = $q.defer();
      CustomRolesAPI.claimedSystems(
        { dealer_id: dealerId, data_id: userId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "CustomRolesService->claimedSystems() error: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  },
]);
