/**
 * @ngdoc service
 * @name App.factory:Analytics
 *
 * @description
 * Factory for working with Google Analytics analytics.js library
 *
 */
App.factory("GoogleAnalyticsService", [
  "$rootScope",
  "$window",
  "$location",
  function ($rootScope, $window, $location) {
    return {
      trackingId: "",

      /**
       * Initializes the Google Analytics library.
       * @param trackingId {string} The tracking ID from the Google Analytics dashboard
       */
      // init: function (trackingId) {
      //   this.trackingId = trackingId;

      //   $window.ga("create", {
      //     storage: "none",
      //     trackingId: trackingId,
      //     clientId: $rootScope.$storage.getItem("ga:clientId"),
      //   });

      //   // Since the service is used on both web and mobile, don't verify the protocol used.
      //   // This should be set to null since Cordova uses file:// instead of http(s)://
      //   $window.ga("set", "checkProtocolTask", null);
      //   $window.ga(
      //     "set",
      //     "transportUrl",
      //     "https://www.google-analytics.com/collect"
      //   );
      //   $window.ga(function (tracker) {
      //     // If no clientId has been stored locally, get the current clientId from the tracking data and store it
      //     if (!$rootScope.$storage.getItem("ga:clientId")) {
      //       $rootScope.$storage.setItem("ga:clientId", tracker.get("clientId"));
      //     }
      //   });
      // },

      init: function (trackingId) {
        this.trackingId = trackingId;

        // Setting dataLayer & gtag to window because I'm using a custom code text field in a tag management system
        window.dataLayer = window.dataLayer || [];
        window.gtag =
          window.gtag ||
          function () {
            window.dataLayer.push(arguments);
          };
        window.gtag("js", new Date());
        window.gtag("config", trackingId);

        // Set initial gtag/js?id=<first ID> script to <head>
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "//www.googletagmanager.com/gtag/js?id=" + trackingId;
        document.getElementsByTagName("head")[0].appendChild(script);

        $rootScope.$on("$stateChangeSuccess", function (event) {
          $window.gtag("config", trackingId, {
            debug_mode: true,
            page_path: $location.path(),
          });
          $window.gtag("event", "page_view");
        });
      },

      /**
       * Sets the User ID that Google Analytics will use for aggregating user activity
       * @param userId {string} The user ID to send to Google Analytics
       */
      setUserId: function (userId) {
        $window.gtag(`config`, this.trackingId, { userId: userId });
      },

      /**
       * Track an app screen hit
       * @param screenName {string} The name of the screen the user is on
       * @param appName {string} The name of the App
       * @param appVersion {string} The application version
       */
      trackScreen: function (screenName, appName, appVersion) {
        $window.gtag("event", "screen_view", {
          app_name: appName,
          screen_name: screenName,
          app_version: appVersion,
        });
      },

      /**
       * Track a website page view
       * @param page {string} the url for the page (must begin with a forward slash, typically: /{route.name})
       */
      trackPage: function (page) {
        // Make sure that the page starts with a forward slash
        if (page.indexOf("/") !== 0) {
          page = "/" + page;
        }

        $window.gtag("config", this.trackingId, { page_path: page });
        $window.gtag("event", "page_view");
      },

      /**
       * Track a specific event that occurs on either an app or website
       * @param category {string} The event category. Example: Dealer
       * @param action {string} The action that was taken. Example: Tech Tools Opened
       * @param label {string} A label used categorize the event. Example: Dealer ID
       * @param value {string} A value associated with the event. Example: 123456
       * @param args {object} Additional arguments to send to Google Analytics
       */
      trackEvent: function (category, action, label, value, args) {
        $window.gtag("event", action, {
          send_to: this.trackingId,
          event_category: category,
          event_label: label,
          value: value,
          ...args,
        });
      },
    };
  },
]);
