/**
 * Converting Bool selection into a 'Yes' or 'No'
 */
App.directive("daConvertBool", [
  "$compile",
  function ($compile) {
    var linker = function (scope, element, attrs) {
      attrs.$observe("daConvertBool", function () {
        if (
          typeof attrs.daConvertBool != "undefined" &&
          attrs.daConvertBool.length > 0
        ) {
          if (attrs.daConvertBool == "true") {
            element.replaceWith(
              $compile(
                attrs.type == "check"
                  ? '<svg class="svg-btn-img--table" style=" width: 1.4rem; height: 1.4rem; fill: #0F700F">\n' +
                      '<use xlink:href="assets/img/sprite.svg#icon-checkmark" href="assets/img/sprite.svg#icon-checkmark"></use>\n' +
                      "</svg>"
                  : "<span>Yes</span>"
              )(scope)
            );
          } else {
            element.replaceWith(
              $compile(
                attrs.type == "check" ? "<span></span>" : "<span>No</span>"
              )(scope)
            );
          }
        }
      });
    };
    return {
      restrict: "A",
      link: linker,
    };
  },
]);

App.directive("daConvertBoolYesNo", [
  "$compile",
  function ($compile) {
    const linker = function (scope, element, attrs) {
      attrs.$observe("daConvertBoolYesNo", function () {
        if (attrs.daConvertBoolYesNo == "true") {
          element.replaceWith(
            $compile(
              attrs.type === "check"
                ? `<svg class="svg-btn-img--table" style=" width: 1.4rem; height: 1.4rem; fill: #0F700F; margin-top: .8rem" >
                      <use xlink:href="assets/img/sprite.svg#icon-checkmark" href="assets/img/sprite.svg#icon-checkmark"></use>
                      </svg>`
                : `<span>Yes</span>`
            )(scope)
          );
        } else {
          element.replaceWith(
            $compile(
              attrs.type === "check"
                ? `<svg class="svg-btn-img--table" style=" width: 1.4rem; height: 1.4rem; fill: #cacaca; margin-top: .8rem">
                <use xlink:href="assets/img/sprite.svg#icon-close_cancel" href="assets/img/sprite.svg#icon-close_cancel"></use>
                </svg>`
                : `<span>No</span>`
            )(scope)
          );
        }
      });
    };
    return {
      restrict: "A",
      link: linker,
    };
  },
]);
