import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { SystemType } from "securecom-graphql/client";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import {
  useHouseCode,
  useSystemType,
} from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsArmedSleepOutputField_outputOptions$key } from "./__generated__/OutputOptionsArmedSleepOutputField_outputOptions.graphql";

export const outputOptionsArmedSleepOutputFieldId = () =>
  `output-options-armed-sleep`;

function OutputOptionsArmedSleepOutputField() {
  const [{ armedSleepOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsArmedSleepOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsArmedSleepOutputField_outputOptions on OutputOptions {
          id
          armedSleepOutput {
            id
            number
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsArmedSleepOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const disabled = useSystemType() !== SystemType.HOME_SLEEP_AWAY;

  const originalValue = React.useRef(armedSleepOutput.formattedNumber).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Armed Sleep Output"
      tooltip="This output turns on steady when the System is Armed Sleep. Alarms cause the output to pulse until 3 minutes after Disarming."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={armedSleepOutput.formattedNumber}
        disabled={disabled}
        required
        pattern={disabled ? undefined : pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "armedSleepOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "armedSleepOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsArmedSleepOutputField;
