/**
 * @generated SignedSource<<1baee3ee53e409a8f3e84e69c249cb17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeyfobButtonType = "ARMING" | "DISARMING" | "EMERGENCY" | "EMERGENCY_TWO_BUTTON" | "OUTPUT" | "PANIC" | "PANIC_TWO_BUTTON" | "SENSOR_RESET" | "STATUS" | "TOGGLE_ARM_DISARM" | "UNUSED";
import { FragmentRefs } from "relay-runtime";
export type KeyfobButtonActionField_keyfob$data = {
  readonly buttonFourAction: KeyfobButtonType;
  readonly buttonOneAction: KeyfobButtonType;
  readonly buttonThreeAction: KeyfobButtonType;
  readonly buttonTwoAction: KeyfobButtonType;
  readonly number: number;
  readonly " $fragmentType": "KeyfobButtonActionField_keyfob";
};
export type KeyfobButtonActionField_keyfob$key = {
  readonly " $data"?: KeyfobButtonActionField_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobButtonActionField_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobButtonActionField_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOneAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreeAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourAction",
      "storageKey": null
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "2464252be4a62fb5a96384419c3ef927";

export default node;
