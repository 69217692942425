import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import * as React from "react";
import { usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { CommPathAccountNumberField_panel$key } from "./__generated__/CommPathAccountNumberField_panel.graphql";

export const commPathAccountNumberFieldId = () => "comm-path-account-number";

function CommPathAccountNumberField() {
  const [{ accountNumber }] =
    usePanelFragment<CommPathAccountNumberField_panel$key>(
      graphql`
        fragment CommPathAccountNumberField_panel on Panel {
          accountNumber
        }
      `
    );

  const fieldId = commPathAccountNumberFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Account Number"
      tooltip="Account number for this System. Will be truncated to 4 digits when sent over CID."
    >
      <NumericInput id={fieldId} value={accountNumber} disabled />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathAccountNumberField;
