/**
 * @generated SignedSource<<6d2d2de771d1499b9c1b7e6ecde9849e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsExitDelayField_systemOptions$data = {
  readonly exitDelay?: number;
  readonly exitDelayMax?: number;
  readonly exitDelayMin?: number;
  readonly " $fragmentType": "SystemOptionsExitDelayField_systemOptions";
};
export type SystemOptionsExitDelayField_systemOptions$key = {
  readonly " $data"?: SystemOptionsExitDelayField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsExitDelayField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "exitDelay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "exitDelayMin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "exitDelayMax",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsExitDelayField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentrySystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "ad4cbcab41a558ec916b39d63188345d";

export default node;
