import DaColors from "app/common/DaColors";
import React, { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 1.6rem;
  background-color: ${DaColors.White};
  border: 0.1rem solid ${DaColors.Neutral600};
  border-radius: 0.4rem;
`;
const HeadingWrapper = styled.div`
  padding-bottom: 0.8rem;
  font-size: 1.4rem;
  font-weight: bold;
`;

type Props = {
  heading?: ReactNode;
};

const CleanPanel = ({ heading, children }: PropsWithChildren<Props>) => {
  return (
    <Wrapper>
      {heading && <HeadingWrapper>{heading}</HeadingWrapper>}
      {children}
    </Wrapper>
  );
};

export default CleanPanel;
