import { App } from "app-module";
import { RelayServiceType } from "app/common/services/relay-service";
import graphql from "babel-plugin-relay/macro";
import { addClassesIf } from "common/utils/web/css";
import {
  Input,
  Panel,
  Table,
  TableFooter,
  TableFooterReducedPadding,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { GenericPageFallback } from "components/GenericPageFallback";
import { TableHeaderSorting } from "components/MobileCredentials/TableStyledComponents";
import { AlertObject, AlertsContextProvider } from "contexts/AlertsContext";
import { ascend, descend, isEmpty, sort, splitEvery } from "ramda";
import React, { Suspense } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { react2angular } from "react2angular";
import { vagueSearchUsingGetters } from "search-filterer";
import styled from "styled-components";
import { DealerActivityRootQuery } from "./__generated__/DealerActivityRootQuery.graphql";

const FixedWidthTableHeaderSorting = styled(TableHeaderSorting)`
  width: 25%;
`;

const MinHeightPanelTable = styled(Panel.Table)`
  min-height: 8rem;
  border: 0;
`;

const FlexTableCell = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  RelayService: RelayServiceType;
  $stateParams: {
    dealer_id: string;
    id: string;
    hardware_model: string;
  };
  UserService: {
    dealer_id: string;
    isParentDealerLogin(): boolean;
    isSupervisorAccessible(): boolean;
  };
  $rootScope: {
    alerts: AlertObject[];
    $digest: () => void;
  };
  $state: {
    go: (
      route: string,
      params: { [key: string]: string | number },
      options?: { reload?: boolean }
    ) => void;
  };
};

enum SortKey {
  DealerName,
  YTDSales,
  YTDSalesVariance,
  Systems,
  Cellular,
  Apps,
  DoorsOfAccess,
  AdvancedReports,
  VideoVerification,
  AutoProgrammed,
  YTDSystems,
  MTDSystems,
}

const DealerActivityRoot = ({ UserService, $state }: any) => {
  const data = useLazyLoadQuery<DealerActivityRootQuery>(
    graphql`
      query DealerActivityRootQuery($queryString: String!) {
        searchDealerActivity(queryString: $queryString) {
          id
          dealerId
          name
          ytdSales
          ytdSalesVariance
          systems
          cellular
          apps
          doorsOfAccess
          advancedReports
          videoVerification
          autoProgrammed
          ytdSystems
          mtdSystems
          ytdVarianceSystems
          mtdVarianceSystems
        }
      }
    `,
    { queryString: "test" },
    { fetchPolicy: "network-only" }
  );

  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Descending,
    sortKey: SortKey.Systems,
    pageSize: 25,
  });

  // Order
  const orderedDealerData = React.useMemo(
    () =>
      sort(
        (paginationState.sortOrder === SortOrder.Ascending ? ascend : descend)(
          (dealer) => {
            switch (paginationState.sortKey) {
              case SortKey.YTDSales:
                return dealer.ytdSales;
              case SortKey.YTDSalesVariance:
                return dealer.ytdSalesVariance > 0
                  ? dealer.ytdSalesVariance * 100 - 100
                  : 0;
              case SortKey.Systems:
                return dealer.systems;
              case SortKey.Cellular:
                return dealer.cellular;
              case SortKey.Apps:
                return dealer.apps;
              case SortKey.DoorsOfAccess:
                return dealer.doorsOfAccess;
              case SortKey.AdvancedReports:
                return dealer.advancedReports;
              case SortKey.VideoVerification:
                return dealer.videoVerification;
              case SortKey.AutoProgrammed:
                return dealer.autoProgrammed;
              case SortKey.YTDSystems:
                return dealer.ytdSystems;
              case SortKey.MTDSystems:
                return dealer.mtdSystems;
              default:
                return (dealer.name ?? "").toUpperCase();
            }
          }
        ),
        data.searchDealerActivity
      ),
    [
      paginationState.sortKey,
      paginationState.sortOrder,
      data.searchDealerActivity,
    ]
  );

  // Search
  const searchedDealerData = React.useMemo(
    () =>
      vagueSearchUsingGetters(
        paginationState.search,
        [(data) => data.name.toUpperCase()],
        orderedDealerData ? orderedDealerData : []
      ),
    [orderedDealerData, paginationState.search]
  );

  // Paginate
  const pagedDealerData = React.useMemo(
    () =>
      splitEvery(paginationState.pageSize, searchedDealerData)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, searchedDealerData]
  );

  const maxPage = Math.ceil(
    searchedDealerData.length / paginationState.pageSize
  );

  return (
    <>
      <MinHeightPanelTable>
        <TableHeader>
          <TableHeader.Left>
            <label className="sr-only" htmlFor="search-input">
              Search
            </label>
            <Input.TableSearch
              id="search-input"
              value={paginationState.search}
              onChange={(search) => {
                dispatch({
                  type: ActionType.SetSearch,
                  search,
                });
                paginationState.currentPage = 1;
              }}
            />
          </TableHeader.Left>
        </TableHeader>
        <Table>
          <thead>
            <tr>
              <FixedWidthTableHeaderSorting></FixedWidthTableHeaderSorting>
              <FixedWidthTableHeaderSorting
                onClick={() => {
                  paginationState.currentPage = 1;
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.DealerName,
                  });
                }}
              >
                Dealer
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [paginationState.sortKey === SortKey.Systems, "is-active"],
                  [
                    paginationState.sortKey === SortKey.Systems,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.Systems &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.Systems &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.Systems) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Systems,
                    });
                  }
                }}
              >
                Systems
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [paginationState.sortKey === SortKey.Cellular, "is-active"],
                  [
                    paginationState.sortKey === SortKey.Cellular,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.Cellular &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.Cellular &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.Cellular) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Cellular,
                    });
                  }
                }}
              >
                Cellular
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [paginationState.sortKey === SortKey.Apps, "is-active"],
                  [
                    paginationState.sortKey === SortKey.Apps,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.Apps &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.Apps &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.Apps) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Apps,
                    });
                  }
                }}
              >
                Apps
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [
                    paginationState.sortKey === SortKey.DoorsOfAccess,
                    "is-active",
                  ],
                  [
                    paginationState.sortKey === SortKey.DoorsOfAccess,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.DoorsOfAccess &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.DoorsOfAccess &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.DoorsOfAccess) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.DoorsOfAccess,
                    });
                  }
                }}
              >
                Doors
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [
                    paginationState.sortKey === SortKey.AdvancedReports,
                    "is-active",
                  ],
                  [
                    paginationState.sortKey === SortKey.AdvancedReports,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.AdvancedReports &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.AdvancedReports &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.AdvancedReports) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.AdvancedReports,
                    });
                  }
                }}
              >
                Advanced Reports
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [
                    paginationState.sortKey === SortKey.VideoVerification,
                    "is-active",
                  ],
                  [
                    paginationState.sortKey === SortKey.VideoVerification,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.VideoVerification &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.VideoVerification &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.VideoVerification) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.VideoVerification,
                    });
                  }
                }}
              >
                Video Verification
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [
                    paginationState.sortKey === SortKey.AutoProgrammed,
                    "is-active",
                  ],
                  [
                    paginationState.sortKey === SortKey.AutoProgrammed,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.AutoProgrammed &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.AutoProgrammed &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.AutoProgrammed) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.AutoProgrammed,
                    });
                  }
                }}
              >
                YTD Auto Programmed
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [paginationState.sortKey === SortKey.YTDSystems, "is-active"],
                  [
                    paginationState.sortKey === SortKey.YTDSystems,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.YTDSystems &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.YTDSystems &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.YTDSystems) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.YTDSystems,
                    });
                  }
                }}
              >
                YTD Systems
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [paginationState.sortKey === SortKey.YTDSales, "is-active"],
                  [
                    paginationState.sortKey === SortKey.YTDSales,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.YTDSales &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.YTDSales &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.YTDSales) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.YTDSales,
                    });
                  }
                }}
              >
                YTD Sales
              </FixedWidthTableHeaderSorting>
              <FixedWidthTableHeaderSorting
                className={addClassesIf([
                  [
                    paginationState.sortKey === SortKey.YTDSalesVariance,
                    "is-active",
                  ],
                  [
                    paginationState.sortKey === SortKey.YTDSalesVariance,
                    "tablesort-sortable",
                  ],
                  [
                    paginationState.sortKey === SortKey.YTDSalesVariance &&
                      paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortKey === SortKey.YTDSalesVariance &&
                      paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ])}
                onClick={() => {
                  paginationState.currentPage = 1;
                  if (paginationState.sortKey === SortKey.YTDSalesVariance) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.YTDSalesVariance,
                    });
                  }
                }}
              >
                Sales Variance
              </FixedWidthTableHeaderSorting>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(pagedDealerData) ? (
              pagedDealerData.map((dealer, rowId) => {
                return (
                  <tr key={dealer.dealerId}>
                    <td>{rowId + 1}</td>
                    <td>
                      {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          onClick={() =>
                            window.open(
                              `/#/app/dealers/${dealer.dealerId}/dealer-dashboard`,
                              "_self"
                            )
                          }
                          className="text-left link link-primary"
                        >
                          {dealer.name}
                        </a>
                      }
                    </td>

                    <td className="text-left">
                      {(dealer.systems > 0 ? dealer.systems : 0).toLocaleString(
                        undefined
                      )}
                    </td>
                    <td className="text-left">
                      <FlexTableCell>
                        <span className="mar-r-4">
                          {(dealer.cellular > 0
                            ? dealer.cellular
                            : 0
                          ).toLocaleString(undefined)}
                        </span>
                        <span className="pad-r-16">
                          (
                          {(dealer.systems > 0 && dealer.cellular > 0
                            ? (dealer.cellular / dealer.systems) * 100
                            : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })}
                          %)
                        </span>
                      </FlexTableCell>
                    </td>
                    <td>
                      <FlexTableCell>
                        <span className="mar-r-4">
                          {(dealer.apps > 0 ? dealer.apps : 0).toLocaleString(
                            undefined
                          )}
                        </span>
                        <span className="pad-r-16">
                          (
                          {(dealer.systems > 0 && dealer.apps > 0
                            ? (dealer.apps / dealer.systems) * 100
                            : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })}
                          %)
                        </span>
                      </FlexTableCell>
                    </td>
                    <td className="text-left">
                      {(dealer.doorsOfAccess > 0
                        ? dealer.doorsOfAccess
                        : 0
                      ).toLocaleString(undefined)}
                    </td>
                    <td>
                      <FlexTableCell>
                        <span className="mar-r-4">
                          {(dealer.advancedReports > 0
                            ? dealer.advancedReports
                            : 0
                          ).toLocaleString(undefined)}
                        </span>
                        <span className="pad-r-16">
                          (
                          {(dealer.doorsOfAccess > 0 &&
                          dealer.advancedReports > 0
                            ? (dealer.advancedReports / dealer.doorsOfAccess) *
                              100
                            : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })}
                          %)
                        </span>
                      </FlexTableCell>
                    </td>
                    <td>
                      <FlexTableCell>
                        <span className="mar-r-4">
                          {(dealer.videoVerification > 0
                            ? dealer.videoVerification
                            : 0
                          ).toLocaleString(undefined)}
                        </span>
                        <span className="pad-r-16">
                          (
                          {(dealer.systems > 0 && dealer.videoVerification > 0
                            ? (dealer.videoVerification / dealer.systems) * 100
                            : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })}
                          %)
                        </span>
                      </FlexTableCell>
                    </td>
                    <td>
                      <FlexTableCell>
                        <span className="mar-r-4">
                          {(dealer.autoProgrammed > 0
                            ? dealer.autoProgrammed
                            : 0
                          ).toLocaleString(undefined)}
                        </span>
                        <span className="pad-r-16">
                          (
                          {(dealer.ytdSystems > 0 && dealer.autoProgrammed > 0
                            ? (dealer.autoProgrammed / dealer.ytdSystems) * 100
                            : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })}
                          %)
                        </span>
                      </FlexTableCell>
                    </td>
                    <td>
                      <FlexTableCell>
                        <span className="mar-r-4">
                          {(dealer.ytdSystems > 0
                            ? dealer.ytdSystems
                            : 0
                          ).toLocaleString(undefined)}
                        </span>
                      </FlexTableCell>
                    </td>
                    <td>
                      <span className="mar-r-2">
                        $
                        {(dealer.ytdSales > 0
                          ? dealer.ytdSales
                          : 0
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </td>
                    <td>
                      <span
                        className={addClassesIf(
                          [
                            [dealer.ytdSalesVariance > 1, "text-success"],
                            [
                              dealer.ytdSalesVariance < 1 &&
                                dealer.ytdSalesVariance > 0,
                              "text-danger",
                            ],
                          ],
                          "pad-r-16"
                        )}
                      >
                        {(dealer.ytdSalesVariance > 0
                          ? dealer.ytdSalesVariance * 100 - 100
                          : 0
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                        %
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>No data</td>
              </tr>
            )}
          </tbody>
        </Table>
        <TableFooterReducedPadding>
          <TableFooter.Left>
            <Paginate.ItemsOfItemCount
              items={pagedDealerData.length}
              itemCount={data.searchDealerActivity.length}
            />
          </TableFooter.Left>
          <TableFooter.Center>
            <Paginate.Paginator
              state={paginationState}
              dispatch={dispatch}
              maxPage={maxPage}
            />
          </TableFooter.Center>
          <TableFooter.Right>
            <Paginate.ItemsPerPage
              state={paginationState}
              dispatch={dispatch}
            />
          </TableFooter.Right>
        </TableFooterReducedPadding>
      </MinHeightPanelTable>
    </>
  );
};

const DealerActivityAngularEntryPoint = ({
  RelayService,
  UserService,
  $state,
  $rootScope,
}: any) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <DealerActivityRoot UserService={UserService} $state={$state} />
        </AlertsContextProvider>
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "dealerActivity",
    react2angular(
      DealerActivityAngularEntryPoint,
      [],
      ["RelayService", "UserService", "$state", "$rootScope", "$stateParams"]
    )
  );
};
