import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { range } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsHoldupConfirmationTimeField_systemOptions$key } from "./__generated__/SystemOptionsHoldupConfirmationTimeField_systemOptions.graphql";

export const systemOptionsHoldupConfirmationTimeFieldId = () =>
  "system-options-holdup-confirmation-time";

function SystemOptionsHoldupConfirmationTimeField() {
  const [{ holdupConfirmationTime }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsHoldupConfirmationTimeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsHoldupConfirmationTimeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            holdupConfirmationTime
          }
        }
      `
    );

  const fieldId = systemOptionsHoldupConfirmationTimeFieldId();
  const originalValue = React.useRef(holdupConfirmationTime).current;
  const validRange = [0, ...range(8, 21)];

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Holdup Confirmation Time"
      tooltip="Confirmation time requires two alarm signals to be received within a designated time frame in order to verify the alarm. Both Intruder and Holdup times require two zones of the same type to trip, with the second trip verifying that alarm."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={holdupConfirmationTime?.toString()}
        required
        min={0}
        max={20}
        inlineHelp={`0, 8-20 Hours`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "holdupConfirmationTime"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = closest(
              !target.value || isNaN(valueAsNumber)
                ? originalValue ?? 0
                : valueAsNumber,
              validRange
            );
            recordProxy.setValue(Number(value), "holdupConfirmationTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsHoldupConfirmationTimeField;
