/**
 * @generated SignedSource<<502366a08a92be41013395e5472aad75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationFailTimeField_communication$data = {
  readonly checkInMinutes: number;
  readonly comType: CommunicationComType;
  readonly failTime: number;
  readonly failTimeValidValues: ReadonlyArray<number>;
  readonly " $fragmentType": "CommunicationFailTimeField_communication";
};
export type CommunicationFailTimeField_communication$key = {
  readonly " $data"?: CommunicationFailTimeField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationFailTimeField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationFailTimeField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTimeValidValues",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "905ebf68207ebad566c5e086383340b5";

export default node;
