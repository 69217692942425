import { getVidproxUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";

const LOW_RES_STREAM_NUMBER = 2;
const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;
const VIDPROX_BASE_URL = getVidproxUrl(secureComEnv);

const getStreamingConfig = async (scwToken: string, deviceId: string) => {
  const path = `/api/video_channel/${deviceId}/live`;
  const query = `?uriParams.auth_token=${scwToken}&uriParams.stream_number=${LOW_RES_STREAM_NUMBER}`;
  const url = `${VIDPROX_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return { hlsUrl: responseJson.hls_url };
  } else {
    throw Error(`Vidprox Error: ${response.status}:${response.statusText}`);
  }
};

const destroyStreams = async (scwToken: string, deviceId: string) => {
  const path = `/api/video_channel/${deviceId}/streams`;
  const query = `?uriParams.auth_token=${scwToken}`;
  const url = `${VIDPROX_BASE_URL}${path}${query}`;
  const response = await fetch(url, { method: "DELETE" });
  if (!response.ok) {
    throw Error(`Vidprox Error: ${response.status}:${response.statusText}`);
  }
};

export { destroyStreams, getStreamingConfig };
