/**
 * @generated SignedSource<<816f32aa84d8e46fb52ca04d711069bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TakeoverPanelType = "DSC" | "ECP";
export type TakeoverPanelSetupSetupPanelMutation$variables = {
  installerCode: string;
  panelType: TakeoverPanelType;
  systemId: string;
  vistaPanel: boolean;
};
export type TakeoverPanelSetupSetupPanelMutation$data = {
  readonly setupTakeoverPanel: {
    readonly __typename: string;
  };
};
export type TakeoverPanelSetupSetupPanelMutation = {
  response: TakeoverPanelSetupSetupPanelMutation$data;
  variables: TakeoverPanelSetupSetupPanelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "installerCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "panelType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vistaPanel"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "installerCode",
        "variableName": "installerCode"
      },
      {
        "kind": "Variable",
        "name": "panelType",
        "variableName": "panelType"
      },
      {
        "kind": "Variable",
        "name": "systemId",
        "variableName": "systemId"
      },
      {
        "kind": "Variable",
        "name": "vistaPanel",
        "variableName": "vistaPanel"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "setupTakeoverPanel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TakeoverPanelSetupSetupPanelMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "TakeoverPanelSetupSetupPanelMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f003e878441c5394af4381215a79b411",
    "id": null,
    "metadata": {},
    "name": "TakeoverPanelSetupSetupPanelMutation",
    "operationKind": "mutation",
    "text": "mutation TakeoverPanelSetupSetupPanelMutation(\n  $systemId: ID!\n  $vistaPanel: Boolean!\n  $installerCode: String!\n  $panelType: TakeoverPanelType!\n) {\n  setupTakeoverPanel(systemId: $systemId, vistaPanel: $vistaPanel, installerCode: $installerCode, panelType: $panelType) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bf36a710713d1f4ee70a4cd969a275d";

export default node;
