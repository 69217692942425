/**
 * @generated SignedSource<<655694e2256399c7270607afd8da3439>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationWirelessField_deviceInformation$data = {
  readonly network?: boolean;
  readonly number: number;
  readonly wireless?: boolean;
  readonly wirelessTranslator1100t?: boolean;
  readonly " $fragmentType": "DeviceInformationWirelessField_deviceInformation";
};
export type DeviceInformationWirelessField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationWirelessField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationWirelessField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationWirelessField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "network",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wirelessTranslator1100t",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wireless",
          "storageKey": null
        }
      ],
      "type": "XtDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "9b5d62366e7b69f29780b725f0cafe20";

export default node;
