import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { curry, omit } from "ramda";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { getClosestOutputValue } from "../OutputOptionsFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputGroupFragment } from "./OutputGroupContext";
import { resolveOutputGroupFieldRules } from "./utils";
import { OutputGroupOutputNumberField_outputGroup$key } from "./__generated__/OutputGroupOutputNumberField_outputGroup.graphql";

type OutputNumberType = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";

export const outputGroupOutputNumberFieldId = curry(
  (outputNumber: OutputNumberType, number: string) =>
    `output-group-${outputNumber}-number-${number}`
);

function OutputGroupOutputNumberField(props: {
  outputNumber: OutputNumberType;
}) {
  const [outputGroup, updateOutputGroup] =
    useOutputGroupFragment<OutputGroupOutputNumberField_outputGroup$key>(
      graphql`
        fragment OutputGroupOutputNumberField_outputGroup on OutputGroup {
          id
          number
          outputNumber1
          outputNumber2
          outputNumber3
          outputNumber4
          outputNumber5
          outputNumber6
          outputNumber7
          outputNumber8
        }
      `
    );

  const { outputNumber } = props;
  const fieldId = outputGroupOutputNumberFieldId(
    outputNumber,
    String(outputGroup.number)
  );
  const outputGroupTestList = Object.values(
    omit(["id", "number"], outputGroup)
  ).map((value) => value);
  const noOutputSelected = outputGroupTestList.join("").match(`^0*$`); // all zeros means no output selected
  const hardwareModel = useHardwareModel();
  const { OUTPUT_NUMBER: RULES } = resolveOutputGroupFieldRules(hardwareModel);
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={`Output Number ${outputNumber}`}
    >
      <TextInput
        id={fieldId}
        pattern={noOutputSelected ? `a^` : RULES.PATTERN} //if no output is selected we want the pattern to match nothing
        required
        validationMessage={RULES.VALIDATION_MESSAGE}
        inlineHelp={RULES.INLINE_HELP}
        value={
          outputNumber === "2"
            ? outputGroup.outputNumber2
            : outputNumber === "3"
            ? outputGroup.outputNumber3
            : outputNumber === "4"
            ? outputGroup.outputNumber4
            : outputNumber === "5"
            ? outputGroup.outputNumber5
            : outputNumber === "6"
            ? outputGroup.outputNumber6
            : outputNumber === "7"
            ? outputGroup.outputNumber7
            : outputNumber === "8"
            ? outputGroup.outputNumber8
            : outputGroup.outputNumber1
        }
        maxLength={4}
        onChange={({ target }) => {
          updateOutputGroup((recordProxy) => {
            recordProxy.setValue(
              target.value,
              `outputNumber${props.outputNumber}`
            );
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;

          updateOutputGroup((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                String(0), // This gets turned into the next available number in OutputGroupNumberField, so we can't revert to the original value
                hardwareModel,
                validNumericalRange
              ),
              `outputNumber${props.outputNumber}`
            );
          });
        }}
        getValidationMessage={(input) => {
          if (input.validity.patternMismatch) {
            if (noOutputSelected)
              return "One output group number must contain a value other than zero.";
          }
          return "";
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputGroupOutputNumberField;
