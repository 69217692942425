/**
 * @generated SignedSource<<53665986591a57a852bbea7fe83f243f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly outputOptions: {
    readonly ambushOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAllOrAwayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedHomeOrPerimeterOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedSleepOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly beginExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly closingWaitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly communicationFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly coolSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cutoffOutputs: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly endExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly heatSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lateToCloseOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly lockdownOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly panicAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly readyOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteArmingOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sensorResetOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly telephoneTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneMonitorOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "3adb97bb4dbce61383c1f1f277d54614";

export default node;
