import { UnionToIntersection } from "common/utils/universal/types";
import { adjust, isNil } from "ramda";

export type ReducerState<T extends (...args: any) => any> = T extends (
  state: infer P,
  actions: any
) => any
  ? P
  : never;

export type ReducerActions<T extends (...args: any) => any> = T extends (
  state: any,
  actions: infer P
) => any
  ? P
  : never;

export type Reducer<State extends any, Actions extends any> = (
  state: State,
  actions: Actions
) => State;

export type ReducerWithIndex<State extends any, Element extends any> = (
  state: State,
  element: Element,
  index: number
) => State;

export function sequenceReducers<Reducers extends Reducer<any, any>[]>(
  reducers: Reducers
) {
  return (
    state: UnionToIntersection<ReducerState<typeof reducers[number]>>,
    action: ReducerActions<typeof reducers[number]>
  ) =>
    reducers.reduce(
      (previousState, reducer) => reducer(previousState, action),
      state
    );
}

export function scopeReducer<R extends Reducer<any, any>, Key extends string>(
  key: Key,
  reducer: R
): Reducer<{ [key in Key]: ReducerState<R> }, ReducerActions<R>> {
  return (
    state: { [key in Key]: ReducerState<R> },
    action: ReducerActions<R>
  ) => ({
    ...state,
    [key]: reducer(state[key], action),
  });
}

export function indexReducer<
  R extends Reducer<any, any>,
  IndexerFn extends (action: ReducerActions<R>) => number
>(
  indexer: IndexerFn,
  reducer: R
): Reducer<ReducerState<R>[], ReducerActions<R>> {
  return (state: ReducerState<R>[], action: ReducerActions<R>) => {
    const index = indexer(action);

    return isNil(index)
      ? state
      : adjust(index, (current) => reducer(current, action), state);
  };
}

export const debugReducer =
  <R extends Reducer<any, any>>(reducer: R) =>
  (state: ReducerState<R>, action: ReducerActions<R>): ReducerState<R> => {
    const nextState = reducer(state, action);
    console.group(action.type);
    console.log("action", action);
    console.log("state", state);
    console.log("nextState", nextState);
    console.groupEnd();
    return nextState;
  };
