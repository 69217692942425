import { SecureComEnv } from "common/utils/universal/types";

type DealerAdminEnv = Exclude<SecureComEnv, "production"> | "prod";

/**
 * Calculates the dealer admin environment from SecureCom environment.
 * @param env
 */
export function getDealerAdminEnv(env: SecureComEnv): DealerAdminEnv {
  return env === SecureComEnv.Production ? "prod" : env;
}

/**
 * Calculates the SecureCom environment from the dealer admin environment.
 * @param env
 */
export function getSecureComEnv(env: DealerAdminEnv): SecureComEnv {
  return env === "prod" ? SecureComEnv.Production : env;
}
