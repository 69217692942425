App.directive("sidebarMenuRevealer", () => {
  return {
    restrict: "E",
    replace: true,
    template: `<div class="sidebar__menu-revealer">
                <svg class="pull-right not-visible-collapsed sidebar__icon--svg--arrow">
                 <use xlink:href="assets/img/sprite.svg#icon-control_down"></use>
                </svg>
              </div>
              `,
  };
});
