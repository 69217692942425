export enum Pages {
  Root = "Root",
  J16Reset = "J16Reset",
  PowerReset = "PowerReset",
  BrownOutReset = "BrownOutReset",
  WatchdogReset = "WatchdogReset",
  LockupReset = "LockupReset",
  SystemReset = "SystemReset",
}

export enum SortKey {
  ConnectionType,
  Dealer,
  HardwareType,
  Resets,
  Serial,
  Version,
}

export type SystemMatch = {
  panel_serial_number: string;
  control_system_name: string;
  account_number: number;
  control_system_id: number;
  customer_id: number;
  sim_identifier: string;
  panel_comm_address: string;
};

export type PanelStat = {
  [index: string]: number | string | boolean | undefined;
  readonly reportedAt: string;
  readonly statId: number;
  readonly acVoltage?: number;
  readonly dcVoltage?: number;
  readonly currentCellSignal?: number;
  readonly strongestCellSignal?: number;
  readonly weakestCellSignal?: number;
  readonly cellRetries?: number;
  readonly cellRoaming?: boolean;
  readonly networkRetries?: number;
  readonly maxCount?: number;
  readonly actualCount?: number;
  readonly pathNumber?: number;
  readonly connectionType?: string;
  readonly status?: string;
  readonly cellTowerId?: number;
  readonly originalSimIdentifier?: string;
  readonly newSimIdentifier?: string;
  readonly deviceType?: string;
};

export type PanelSearchResult = {
  readonly hardwareModel?: string;
  readonly commType?: string;
  readonly panelId?: number;
  readonly panelName?: string;
  readonly panelSerialNumber?: string;
  readonly controlSystemName?: string;
  readonly accountNumber?: number;
  readonly controlSystemId?: number;
  readonly customerId?: number;
  readonly simIdentifier?: string;
  readonly panelCommAddress?: string;
  readonly panelSoftwareVersion?: string;
  readonly panelSoftwareDate?: string;
  readonly panelAccountNumber?: number;
  readonly dealerId?: number;
  readonly panelArmingSystem?: string;
};

export interface GraphTitle {
  [index: string]: { unit: string; title: string };
}
