import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { lockoutCodeFieldId } from "components/FullProgramming/common/LockoutCodeFields/LockoutCodeField";
import { TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/TakeoverPanelFullProgramming/__generated__/TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getLockoutCodeState } from "../../../TakeoverPanelFullProgramming/TakeoverPanelLockoutCodeProgrammingConceptForm";
import { ProgrammingTemplateLockoutCodeInput } from "../Create/__generated__/TakeoverTemplateSaveMutation.graphql";
import { TakeoverLockoutCodeTemplateDataInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TakeoverLockoutCodeTemplateDataInline_takeoverProgrammingTemplateConcepts.graphql";

export const getState = (
  template: TakeoverLockoutCodeTemplateDataInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverLockoutCodeTemplateDataInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        lockoutCode {
          id
          included
          code {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateLockoutCodeInput => {
  const {
    panel: { lockoutCode },
  } = getLockoutCodeState(controlSystem);

  if (!lockoutCode) {
    return { included: false };
  }

  return {
    included: includedFields.has(lockoutCodeFieldId()),
    code: {
      included: includedFields.has(lockoutCodeFieldId()),
      data: lockoutCode.code,
    },
  };
};

export const setIncludedFields = (
  template: TakeoverLockoutCodeTemplateDataInline_takeoverProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { lockoutCode } = getState(template);

  if (lockoutCode?.code?.included) {
    setIncludedFields(setToggle(lockoutCodeFieldId()));
  }
};
