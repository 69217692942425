/**
 * ChangeConnectionInfoResource - resource for interacting with hange connectin info scheduled jobs
 */
App.factory("ChangeConnectionInfoResource", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.jobsApiUrl + "/api/v1/ChangeConnectionInfo/:panel_id/:action",
      {},
      {
        getIncomplete: {
          method: "GET",
          params: { panel_id: "@panel_id", action: "Incomplete" },
        },
        create: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            DesiredAccountNumber: "@acct_num",
            DesiredRemoteKey: "@remote_key",
            DesiredAccountPrefix: "@account_prefix",
          },
        },
        get: { method: "GET", params: { GroupId: "@groupID" } },
        cancel: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            action: "Cancel",
            GroupId: "@groupID",
          },
        },
      }
    );
  },
]);
