/**
 *
 * TransitionView hooks
 * @author Chad Watson
 *
 *
 */
import { useContext } from "react";
import Context from "./Context";
export const useTransitionViewContext = () => useContext(Context);
export const useAnimating = () => useTransitionViewContext().animating;
export const useLeaving = () => {
  const { animating, active } = useTransitionViewContext();
  return animating && !active;
};
