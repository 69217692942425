/**
 * @ngdoc service
 * @name App.factory:VideoChannel
 *
 * @description
 * API factory for Video Channel
 *
 */
App.factory("VideoChannel", [
  "$q",
  "PROPS",
  "VideoChannelV2API",
  "MotionDetectionRegion",
  "Camera",
  "VIDEO_MOTION_DETECTION",
  "$interval",
  function (
    $q,
    PROPS,
    VideoChannelV2API,
    MotionDetectionRegion,
    Camera,
    VIDEO_MOTION_DETECTION,
    $interval
  ) {
    var VideoChannel = function (channelData) {
      angular.extend(this, {
        number: 1, // Default to the first channel for a video device

        ceiling_mount: false,

        events_enabled: "off", // Values: On, Off, Scheduled, Armed Only

        event_interval: 0,

        record_on_alarm: false,

        schedules: [],

        motion_detection_regions: [],

        stream_available: false,

        camera: null, // Used to store streaming details for the video channel

        placeholder: false,

        tryStreaming: null, // Used to store the

        device_type: null,
        /**
         * @ngdoc object
         * @name method:delete
         * @methodOf App.factory:VideoChannel
         *
         * @description
         * Deletes and Deactivates the video device
         *
         * @return {promise} The promise from the Video Devices API 'delete'.
         */
        getStreamingDetails: function (channel) {
          var deferred = $q.defer();
          // If this is a placeholder channel, don't bother trying to get a video stream
          if (channel.placeholder) {
            deferred.reject("Placeholder channel");
            return deferred.promise;
          }
          // Try to get streaming details from VK API
          VideoChannelV2API.getStreamingDetails(
            { video_channel_id: channel.id },
            function (data) {
              // Success
              if (angular.isDefined(_this.tryStreaming)) {
                $interval.cancel(_this.tryStreaming);
              }
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              angular.extend(channel, data.video_channel);
              channel.parseSchedules();
              channel.camera = new Camera(
                "/v2/video_channels/" + channel.id + "/streaming_details"
              );
              channel.stream_available = true;
              deferred.resolve();
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              channel.stream_available = false;
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        initializeMotionDetectionRegions: function (deviceModel) {
          // Make sure that we don't already have data in the regions array
          if (this.motion_detection_regions.length == 0) {
            var motionDetectionRegion = new MotionDetectionRegion(deviceModel);
            if (this.device_type == "camera") {
              motionDetectionRegion.night_sensitivity_level =
                VIDEO_MOTION_DETECTION.camera_day_sensitivity_level;
              motionDetectionRegion.day_sensitivity_level =
                VIDEO_MOTION_DETECTION.camera_night_sensitivity_level;
            }

            this.motion_detection_regions.push(motionDetectionRegion);
          }
        },

        parseSchedules: function () {
          angular.forEach(this.schedules, function (schedule, key) {
            // Format the Schedule times
            schedule.time_start = UTCFromScheduleTime(
              schedule.start_hour,
              schedule.start_minute,
              schedule.start_am_pm
            );
            schedule.time_end = UTCFromScheduleTime(
              schedule.end_hour,
              schedule.end_minute,
              schedule.end_am_pm
            );
          });
        },

        /**
         * @ngdoc object
         * @name method:extendMe
         * @methodOf App.factory:VideoChannel
         *
         * @description
         * Extend the video channel object with the sent data
         */
        extendMe: function (channelData) {
          angular.extend(this, channelData);
        },
      });

      // If channel data was provided, extend the video channel object with said data
      if (typeof channelData != "undefined") {
        var _this = this;
        this.extendMe(channelData);

        // If it's been over an hour since the video device was created, don't try to get streaming details
        var createdAt = dateTimeForceUTC(new Date(channelData.created_at));
        var currentTime = dateTimeForceUTC(new Date());
        var difference = dateTimeMinutesBetween(createdAt, currentTime);
        if (difference < 60) {
          // Try to get streaming details every 3 seconds 5 times or until we successfully get details back
          this.tryStreaming = $interval(
            function () {
              _this.getStreamingDetails(_this);
            },
            1000,
            5
          );
        }
      } else {
        // If we didn't receive data, initialize default data
        //this.initializeMotionDetectionRegions(); - Removed Handled in the Save Function in Video Device
      }
    };

    return VideoChannel;
  },
]);
