/**
 * @ngdoc service
 * @name App.factory:DealerDefaultsService
 *
 * @description
 * Holds the API and methods for the Dealer Defaults
 * Possible resource options are: xt_defaults, services_defaults, app_user_defaults
 *
 */
App.factory("DealerDefaultsService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "DealerDefaultsAPI",
  function ($rootScope, $q, $http, $filter, PROPS, DealerDefaultsAPI) {
    return {
      getAllDefaults: function (dealer_id, resource) {
        var deferred = $q.defer();
        DealerDefaultsAPI.indexDefaults(
          {
            resource: resource,
            dealer_id: dealer_id,
          }, //params

          function (data) {
            //success
            deferred.resolve($filter("orderBy")(data, "name"));
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets a default template for the specified resource (resource)
       * @param dealer_id string The dealer id to get a template for
       * @param resource string The name of the resource to get a template for (ex: services_defaults, app_user_defaults, xt_defaults)
       * @returns {*}
       */
      newDealerDefaults: function (dealer_id, resource) {
        var deferred = $q.defer();

        DealerDefaultsAPI.newDefault(
          {
            resource: resource,
            dealer_id: dealer_id,
          }, //params

          function (data) {
            //success
            data.isNew = true; // Set an isNew flag on our item template
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the specified dealer resource (resource). An id can be passed in to get a specific default (resource_id)
       * @param dealer_id string The dealer id to delete defaults from
       * @param resource string The default resource to delete (ex: services_defaults, app_user_defaults, xt_defaults)
       * @param resource_id string (Optional) The id of the resource to get
       * @returns {*}
       */
      getDealerDefaults: function (dealer_id, resource, resource_id) {
        var deferred = $q.defer();

        DealerDefaultsAPI.showDefault(
          {
            resource: resource,
            dealer_id: dealer_id,
            id: resource_id,
          }, //params

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Creates the specified dealer resource (resource)
       * @param dealer_id string The dealer id to delete defaults from
       * @param resource string The default resource to delete (ex: services_defaults, app_user_defaults, xt_defaults)
       * @param default_obj Object The default object to be created
       * @returns {*}
       */
      createDealerDefaults: function (dealer_id, resource, default_obj) {
        var deferred = $q.defer();

        DealerDefaultsAPI.createDefault(
          {
            resource: resource,
            dealer_id: dealer_id,
          }, //params

          default_obj,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Updates the specified dealer resource (resource)
       * @param dealer_id string The dealer id to update defaults for
       * @param resource string The name of the resource to update
       * @param default_obj Object the default object to update
       * @returns {*}
       */
      updateDealerDefaults: function (dealer_id, resource, default_obj) {
        var deferred = $q.defer();

        DealerDefaultsAPI.updateDefault(
          {
            resource: resource,
            dealer_id: dealer_id,
          }, //params

          default_obj,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Removes the specified dealer resource (resource). If this is a multi item default an id is also required (resource_id)
       * @param dealer_id string The dealer id to delete defaults from
       * @param resource string The default resource to delete (ex: services_defaults, app_user_defaults, xt_defaults)
       * @param resource_id string (Optional) The id of the resource to delete
       * @returns {*}
       */
      deleteDealerDefaults: function (dealer_id, resource, resource_id) {
        var deferred = $q.defer();

        DealerDefaultsAPI.destroyDefault(
          {
            resource: resource,
            dealer_id: dealer_id,
            id: resource_id,
          }, //params

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
