import { GenericPageFallback } from "components/GenericPageFallback";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import { SevenInchKeypadForm } from "./SevenInchKeypadForm";

export default function SevenInchKeypadSettings({
  RelayService,
  $stateParams,
  UserService,
  $rootScope,
  $state,
  stateIsEdit,
  PROPS,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  const imageUrl = PROPS.imageApiUrl;
  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <SevenInchKeypadForm
            dealerId={$stateParams.dealer_id}
            userService={UserService}
            rootScope={$rootScope}
            $state={$state}
            stateIsEdit={stateIsEdit}
            relayService={RelayService}
            stateParams={$stateParams}
            authToken={UserService.auth_token}
            imageUrl={imageUrl}
            environment={environment}
          />
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "sevenInchKeypadSettings",
    react2angular(
      SevenInchKeypadSettings,
      ["stateIsEdit"],
      [
        "$rootScope",
        "$state",
        "$scope",
        "UserService",
        "RelayService",
        "$stateParams",
        "PROPS",
      ]
    )
  );
}
