import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import noop from "common/utils/universal/noop";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { ascend, range } from "ramda";
import * as React from "react";
import {
  ConnectionHandler,
  readInlineData,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import {
  Area,
  AreaConnection,
  AreaEdge,
  asID,
  BadZonesOption,
  ControlSystem,
  fromAreaId,
  fromControlSystemId,
  fromGlobalId,
  ID,
  idAsString,
  SystemType as SystemTypeEnum,
  toAreaId,
  toGlobalId,
} from "securecom-graphql/client";
import ActiveConceptContext from "../common/ActiveConceptContext";
import AreaAutoArmField from "../common/AreaInformationFields/AreaFields/AreaAutoArmField";
import AreaAutoDisarmField from "../common/AreaInformationFields/AreaFields/AreaAutoDisarmField";
import AreaBadZonesField from "../common/AreaInformationFields/AreaFields/AreaBadZonesField";
import { AreaContextProvider } from "../common/AreaInformationFields/AreaFields/AreaContext";
import AreaNameField from "../common/AreaInformationFields/AreaFields/AreaNameField";
import AreaNumberField, {
  areaListItemTemplateId,
  AREA_IDS,
} from "../common/AreaInformationFields/AreaFields/AreaNumberField";
import { SystemAreaInformationContextProvider } from "../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "../common/ChangedProgrammingConceptsContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { useOriginalControlSystemData } from "../common/OriginalControlSystemContext";
import { useProgrammingActionsContext } from "../common/ProgrammingContext";
import { SystemOptionsContextProvider } from "../common/SystemOptionsFields/SystemOptionsContext";
import { useTemplateContext } from "../common/TemplateContext";
import { useUncheckListItem } from "../Templates/utils";
import { removeAreaFromStore } from "../utils";
import { emptyStringOrNumber } from "../utils/format";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { TMSentryAreaInformationProgrammingConceptFormAreaInformationDeleteMutation } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormAreaInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  TMSentryAreaInformationProgrammingConceptFormAreaInformationRefreshMutation,
} from "./__generated__/TMSentryAreaInformationProgrammingConceptFormAreaInformationRefreshMutation.graphql";
import { TMSentryAreaInformationProgrammingConceptFormAreaInformationSendMutation } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormAreaInformationSendMutation.graphql";
import { TMSentryAreaInformationProgrammingConceptFormInline_area$key } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormInline_area.graphql";
import { TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$key } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import { TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts.graphql";
import { TMSentryAreaInformationProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormNavButton_controlSystem.graphql";
import { TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem$key } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem.graphql";
import {
  SystemType,
  TMSentryAreaInformationProgrammingConceptForm_controlSystem$key,
} from "./__generated__/TMSentryAreaInformationProgrammingConceptForm_controlSystem.graphql";

export const title = "Area Information";
export const conceptId = "tmsentry-area-information";

export const getState = (
  controlSystem: TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryAreaInformationProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          areas(first: 32, sort: { keys: ["number"], order: ASC }) {
            __id
            __typename
            edges {
              __typename
              cursor
              node {
                __typename
                id
                number
                isNew
                ...TMSentryAreaInformationProgrammingConceptFormInline_area
              }
            }
          }
        }
      }
    `,
    controlSystem
  );

export const getAreaState = (
  area: TMSentryAreaInformationProgrammingConceptFormInline_area$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryAreaInformationProgrammingConceptFormInline_area on Area
      @inline {
        __typename
        id
        name
        number
        autoArmEnabled
        badZonesOption
        autoDisarmEnabled
        hasChanges
        isNew
      }
    `,
    area
  );

const retrieveMutation = graphql`
  mutation TMSentryAreaInformationProgrammingConceptFormAreaInformationRefreshMutation(
    $systemId: ID!
  ) {
    refreshAreaInformations(systemId: $systemId) {
      ... on RefreshAreaInformationsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...TMSentryAreaInformationProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TMSentryAreaInformationProgrammingConceptFormAreaInformationRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            systemId: id,
          },
          onCompleted: (response) => {
            const { __typename, controlSystem, error } =
              response.refreshAreaInformations;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Area Information Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshAreaInformations: {
                    __typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to refresh Area Information: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to refresh Area Information",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const deleteMutation = graphql`
  mutation TMSentryAreaInformationProgrammingConceptFormAreaInformationDeleteMutation(
    $areaId: ID!
  ) {
    deleteAreaInformation(areaId: $areaId) {
      ... on DeleteAreaInformationSuccessPayload {
        __typename
        deletedAreaId
      }
      ... on FailedToRemoveAreaErrorPayload {
        error: type
      }
    }
  }
`;

const saveMutation = graphql`
  mutation TMSentryAreaInformationProgrammingConceptFormAreaInformationSendMutation(
    $systemId: ID!
    $areas: [AreaProgrammingInput!]!
  ) {
    sendXtAreaProgramming(systemId: $systemId, areas: $areas) {
      ... on SendAreasProgrammingSuccessPayload {
        results {
          __typename
          ... on SendAreasProgrammingAreaSuccessPayload {
            area {
              __typename
              id
              number
              ...TMSentryAreaInformationProgrammingConceptFormInline_area
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

export const useSaveMutation = (props: {
  controlSystem: TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TMSentryAreaInformationProgrammingConceptFormAreaInformationSendMutation>(
      saveMutation
    );
  const [deleteAreaInformation, isDeleting] =
    useMutation<TMSentryAreaInformationProgrammingConceptFormAreaInformationDeleteMutation>(
      deleteMutation
    );

  const deleteArea = async (areaId: string): Promise<void> =>
    new Promise((resolve) => {
      deleteAreaInformation({
        variables: {
          areaId,
        },
        onCompleted: () => {
          resolve();
        },
      });
    });

  const showAlert = useShowAlert();
  const changedAreas = useChangedProgrammingConcept(conceptId);
  const lookupOriginalData = useOriginalControlSystemData();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise(async (resolve, reject) => {
        try {
          const {
            id: systemId,
            panel: { areas },
          } = getState(props.controlSystem);

          const {
            panel: { areas: originalAreas },
          } = lookupOriginalData<TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem$key>(
            graphql`
              fragment TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem on ControlSystem {
                panel {
                  areas(first: 32, sort: { keys: ["number"], order: ASC }) {
                    __id
                    edges {
                      node {
                        id
                        number
                      }
                    }
                  }
                }
              }
            `
          );
          const areaNumbers = areas.edges
            .flatMap((area) => area.node?.number)
            .filter(isNotNullOrUndefined)
            .map((number) => Number(number));

          await Promise.allSettled(
            originalAreas.edges
              .flatMap((ogArea) => ogArea.node)
              .filter(isNotNullOrUndefined)
              .map((ogArea) => {
                // If the current areas do not include the original
                // areas, then delete the original areas
                if (!areaNumbers.includes(Number(ogArea.number))) {
                  if (ogArea.id) {
                    return deleteArea(ogArea.id);
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              })
              .filter(isNotNullOrUndefined)
          );

          save({
            variables: {
              systemId,
              areas: areas.edges
                .flatMap((edge) => edge.node)
                .filter(isNotNullOrUndefined)
                .filter(
                  (area) =>
                    (!!changedAreas &&
                      listItemHasChanged(area.id, changedAreas)) ||
                    area.isNew ||
                    isSavingAllListItems
                )
                .map(getAreaState)
                .map((area) => ({
                  id: area.id,
                  autoArmEnabled: area.autoArmEnabled,
                  autoDisarmEnabled: area.autoDisarmEnabled,
                  name: area.name,
                  number: area.number,
                  badZonesOption: area.badZonesOption,
                  isNew: area.isNew,
                })),
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXtAreaProgramming.type && showAlerts) {
                showAlert({
                  type: "error",
                  text: `Error Sending ${title} - Panel Not Found`,
                });
              } else if (response.sendXtAreaProgramming.results) {
                response.sendXtAreaProgramming.results.forEach((response) => {
                  if (
                    response.__typename ===
                    "SendAreasProgrammingAreaSuccessPayload"
                  ) {
                    resetLastUpdated(response.area.id);
                  } else if (
                    response.__typename === "SendListItemsErrorPayload"
                  ) {
                    sendErrors.push({
                      programmingConcept: title,
                      errors: response.errors,
                      listItemNumber: response.number,
                    });
                  }
                });

                // TODO: Update parent relay environment

                if (!sendErrors.length && showAlerts) {
                  showAlert({
                    type: "success",
                    text: `Successfully Updated ${title}`,
                  });
                }
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        } catch {
          showAlert({
            type: "error",
            text: "Failed To Update Area Informations",
          });
          reject();
        }
      }),
    isSaving || isDeleting,
  ];
};

const readAreaInformationsTemplateData = (
  programmingTemplateConcepts: TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        areaInformations {
          included
          number
          name {
            included
            data
          }
          autoArmEnabled {
            included
            data
          }
          autoDisarmEnabled {
            included
            data
          }
          badZonesOption {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).areaInformations;

export function applyTemplateData(
  programmingTemplateConcepts: TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const systemId = controlSystemRecordProxy.getValue("id");
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const areasConnection = panelRecordProxy.getLinkedRecord("areas", {
    first: 32,
    sort: { keys: ["number"], order: "ASC" },
  });
  const areaEdgesByNumber = new Map<number, RecordProxy<AreaEdge>>(
    areasConnection
      .getLinkedRecords("edges")
      .map((edge) => [
        Number(edge.getLinkedRecord("node").getValue("number")),
        edge,
      ]) ?? []
  );

  const areasTemplateData =
    readAreaInformationsTemplateData(programmingTemplateConcepts) ?? [];
  areasTemplateData.forEach((areaTemplateData) => {
    if (areaTemplateData?.included) {
      const existingAreaEdgeRecordProxy = areaEdgesByNumber.get(
        areaTemplateData.number
      );
      if (existingAreaEdgeRecordProxy) {
        applyTemplateScalarDataToRecordProxy(
          existingAreaEdgeRecordProxy.getLinkedRecord("node"),
          areaTemplateData
        );
      } else {
        const newAreaId = toAreaId(systemId, areaTemplateData.number);
        applyNewAreaToAreaList(
          { areas: { __id: areasConnection.getDataID() } },
          newAreaId,
          store
        );
        if (newAreaId) {
          const areaRecordProxy = store.get(
            idAsString(newAreaId)
          ) as RecordProxy<Area>;
          if (areaRecordProxy) {
            const areaEdgeRecordProxy = ConnectionHandler.createEdge(
              store,
              areasConnection,
              areaRecordProxy,
              "AreaEdge"
            ) as RecordProxy<AreaEdge>;

            applyTemplateScalarDataToRecordProxy(
              areaRecordProxy,
              areaTemplateData
            );
            areaEdgesByNumber.set(areaTemplateData.number, areaEdgeRecordProxy);
          }
        }
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    [...areaEdgesByNumber.entries()]
      .sort(ascend(([number]) => number))
      .map(([, areaEdgeRecordProxy]) => areaEdgeRecordProxy),
    "areas"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<TMSentryAreaInformationProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment TMSentryAreaInformationProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            areas(first: 32, sort: { keys: ["number"], order: ASC }) {
              edges {
                node {
                  isNew
                }
              }
            }
          }
        }
      `
    );
  const { areas } = controlSystem.panel;
  const itemsCount = areas.edges?.length ?? 0;
  const hasNewItems =
    itemsCount > 0 && !!areas.edges?.some(({ node }) => node?.isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<TMSentryAreaInformationProgrammingConceptForm_controlSystem$key>(graphql`
      fragment TMSentryAreaInformationProgrammingConceptForm_controlSystem on ControlSystem {
        __typename
        id
        copiedArea {
          id
        }
        panel {
          id
          hardwareModel
          helpFiles {
            programmingGuideUrl
            installGuideUrl
          }
          ...SystemOptionsSystemTypeField_panel
          ...AreaNumberField_panel
          ...PanelContext_panel
          areas(first: 32, sort: { keys: ["number"], order: ASC }) {
            __id
            edges {
              cursor
              node {
                id
                name
                number
                autoArmEnabled
                badZonesOption
                autoDisarmEnabled
                isNew
                hasChanges
                maxNameLength
                ...AreaContext_area
                ...AreaNumberField_area
                ...AreaNameField_area
                ...AreaAutoArmField_area
                ...AreaAutoDisarmField_area
                ...AreaBadZonesField_area
              }
            }
            maxNumberOfAreas
            minNumberOfAreas
          }
          systemOptions {
            ... on XtSystemOptions {
              systemType
            }
            ...SystemOptionsContext_systemOptions
            ...SystemOptionsContextSystemType_systemOptions
            ...SystemOptionsContextHouseCode_systemOptions
            ...SystemOptionsContextIsAreaSystem_systemOptions
          }
        }
      }
    `);

  const { areas, systemOptions, hardwareModel } = controlSystem.panel;

  const systemId = fromGlobalId(controlSystem.panel.id as unknown as ID)[1];

  const currentAreaNumbers = new Set(
    areas.edges.map((area) => Number(area.node?.number))
  );

  // Takeover panels don't have selectable systemType
  // Areas always capped at 6
  const maxNumberOfAreas = 6;

  const nextAreaNumber = Math.min(
    ...range(1, maxNumberOfAreas + 1).filter(
      (areaNumber) => !currentAreaNumbers.has(areaNumber)
    )
  );

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    areas.edges[0]?.node?.id ?? null
  );

  const newAreaId = toAreaId(systemId, nextAreaNumber, true);

  const numberOfAvailableAreas = maxNumberOfAreas - areas.edges.length;

  const availableAreasExist = numberOfAvailableAreas > 0;

  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const uncheckListItem = useUncheckListItem()(AREA_IDS);

  const canRemove = areas.edges.length > 0 || templateIsEditing;

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const relayEnv = useRelayEnvironment();
  const parentRelayEnv = useParentRelayEnvironment();

  const [deleteAreaInformation, isDeleting] =
    useMutation<TMSentryAreaInformationProgrammingConceptFormAreaInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();

  if (systemOptions?.systemType !== SystemTypeEnum.AREA) {
    relayEnv.commitUpdate((store) => {
      const panelRecord = store.get(controlSystem.panel.id);
      if (panelRecord) {
        // Match DA current behavior, always set systemType to AREA
        const systemOptions = panelRecord.getLinkedRecord("systemOptions");
        if (systemOptions) {
          systemOptions.setValue(SystemTypeEnum.AREA, "systemType");
        }
      }
    });
  }

  const removeSelectedArea = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = areas.edges.findIndex(
          (area) => area.node?.id === selectedListItemId
        );
        const lastItemIsSelected = selectedIndex === areas.edges.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return areas.edges[newSelectedIndex]?.node?.id ?? null;
      });

      const area = areas.edges.find(
        (area) => area.node?.id === selectedListItemId
      );
      uncheckListItem(area?.node?.id ?? "");
      if (area?.node?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeAreaFromStore(areas.__id, selectedListItemId, store);
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Area",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            area?.node?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteAreaInformation({
          variables: {
            areaId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            const areasConnection = store.get<AreaConnection>(areas.__id);
            if (areasConnection) {
              ConnectionHandler.deleteNode(areasConnection, selectedListItemId);
            }
          },
          updater: (store, response) => {
            const { deletedAreaId } = response.deleteAreaInformation;
            if (deletedAreaId) {
              removeAreaFromStore(areas.__id, selectedListItemId, store);
              showAlert({
                type: "success",
                text: "Area Deleted From the System",
              });
            }
          },
          onCompleted: (response) => {
            const { deletedAreaId, error } = response.deleteAreaInformation;
            if (deletedAreaId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedAreaId) {
                    removeAreaFromStore(
                      areas.__id,
                      selectedListItemId,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Area: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Area",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Area",
            });
          },
        });
      }
    }
  };
  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <SystemAreaInformationContextProvider systemAreaInformation={areas}>
        <SystemOptionsContextProvider systemOptions={systemOptions}>
          <ProgrammingConceptForm
            conceptId={conceptId}
            helpLink={`${programmingGuideUrl}#Area%20Information`}
            title={title}
            deleting={isDeleting}
            isArrayConcept
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
              controlSystem.panel.areas.edges
            )}
            amountAvailable={maxNumberOfAreas - areas.edges.length}
            addButton={
              <ProgrammingConceptForm.AddButton
                onClick={() => {
                  relayEnv.commitUpdate((store) => {
                    const createdAreaId = applyNewAreaToAreaList(
                      { areas: { __id: controlSystem.panel.areas.__id } },
                      newAreaId,
                      store
                    );
                    if (createdAreaId) {
                      setSelectedListItemId(createdAreaId);
                    }
                  });
                }}
              >
                Add Area
              </ProgrammingConceptForm.AddButton>
            }
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              {(conceptId === activeConcept || isApplying || isSavingAll) && (
                <ProgrammingConceptForm.ListItemsContainer>
                  <ProgrammingConceptForm.ListItemPicker
                    selectedId={selectedListItemId ?? ""}
                    onChange={(id) => {
                      setSelectedListItemId(id);
                    }}
                    newItemId={idAsString(newAreaId)}
                    items={areas.edges
                      .map(({ node }) => node)
                      .filter(isNotNullOrUndefined)
                      .map((area) => ({
                        id: area.id,
                        isnew: area.isNew,
                        templateListItemId: areaListItemTemplateId(area.number),
                        templateUpdater: noop,
                        label: `#${emptyStringOrNumber(area.number)} ${
                          area.name
                        }`,
                      }))}
                  />
                  <ProgrammingConceptForm.SelectedItemsContainer
                    selectedListItemId={selectedListItemId}
                    setSelectedListItemId={setSelectedListItemId}
                  >
                    {areas.edges.map(
                      (area) =>
                        area.node &&
                        (area.node.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                          programmingConcepts[conceptId].isSaving ||
                          isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                          isSavingAll) && (
                          <RemountOnUpdateContainer nodeId={area.node.id}>
                            <ProgrammingConceptForm.SelectedItem
                              conceptId={conceptId}
                              isnew={area.node.isNew}
                              visible={area.node.id === selectedListItemId}
                              key={area.node.id}
                              listItemId={area.node.id}
                              templateListItemId={areaListItemTemplateId(
                                area.node.number
                              )}
                              title={`# ${emptyStringOrNumber(
                                area.node.number
                              )} ${area.node.name}`}
                              onDuplicate={
                                availableAreasExist &&
                                (() => {
                                  relayEnv.commitUpdate((store) => {
                                    if (areas.__id && selectedListItemId) {
                                      const areasConnection = store.get(
                                        areas.__id
                                      );
                                      const areaToDuplicate =
                                        store.get(selectedListItemId);
                                      if (areasConnection && areaToDuplicate) {
                                        const id = `${newAreaId}`;
                                        const newAreaRecord = store.create(
                                          id,
                                          "Area"
                                        );
                                        newAreaRecord.copyFieldsFrom(
                                          areaToDuplicate
                                        );
                                        newAreaRecord.setValue(id, "id");
                                        newAreaRecord.setValue(
                                          fromAreaId(newAreaId).number,
                                          "number"
                                        );
                                        newAreaRecord.setValue(true, "isNew");
                                        const newEdge =
                                          ConnectionHandler.createEdge(
                                            store,
                                            areasConnection,
                                            newAreaRecord,
                                            "AreaEdge"
                                          );

                                        newEdge.setValue(id, "cursor");

                                        ConnectionHandler.insertEdgeAfter(
                                          areasConnection,
                                          newEdge
                                        );

                                        setSelectedListItemId(id);
                                      }
                                    }
                                  });
                                })
                              }
                              onCopy={() => {
                                if (selectedListItemId) {
                                  relayEnv.commitUpdate((store) => {
                                    const controlSystemRecord = store.get(
                                      controlSystem.id
                                    );
                                    const areaRecord =
                                      store.get<Area>(selectedListItemId);
                                    if (controlSystemRecord && areaRecord) {
                                      const tempRecord =
                                        store.get("copiedArea") ??
                                        store.create("copiedArea", "Area");
                                      tempRecord.copyFieldsFrom(areaRecord);
                                      controlSystemRecord.setLinkedRecord(
                                        tempRecord,
                                        "copiedArea"
                                      );
                                    }
                                  });
                                }
                              }}
                              onPaste={
                                !!controlSystem.copiedArea &&
                                !!selectedListItemId &&
                                (() => {
                                  relayEnv.commitUpdate((store) => {
                                    const areaRecord =
                                      store.get<Area>(selectedListItemId);
                                    const copiedAreaRecord =
                                      store.get<Area>("copiedArea");
                                    if (areaRecord && copiedAreaRecord) {
                                      applyAreaProgrammingToAreaInformation(
                                        copiedAreaRecord,
                                        areaRecord,
                                        systemOptions?.systemType
                                      );
                                    }
                                  });
                                })
                              }
                              onRemove={canRemove && removeSelectedArea}
                            >
                              <AreaContextProvider area={area.node}>
                                <ProgrammingConceptForm.Fields
                                  key={area.cursor}
                                >
                                  <AreaNumberField />
                                  <AreaNameField />
                                  {hardwareModel !== "CELLCOM_EX" && (
                                    <AreaAutoArmField />
                                  )}
                                  {area.node.autoArmEnabled && (
                                    <AreaBadZonesField />
                                  )}
                                  {hardwareModel !== "CELLCOM_EX" && (
                                    <AreaAutoDisarmField />
                                  )}
                                </ProgrammingConceptForm.Fields>
                              </AreaContextProvider>
                            </ProgrammingConceptForm.SelectedItem>
                          </RemountOnUpdateContainer>
                        )
                    )}
                  </ProgrammingConceptForm.SelectedItemsContainer>
                </ProgrammingConceptForm.ListItemsContainer>
              )}
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </SystemAreaInformationContextProvider>
    </PanelContextProvider>
  );
}

const applyNewAreaToAreaList = (
  panel: {
    readonly areas: {
      readonly __id: string;
    };
  },
  newAreaId: ID,
  store: RecordSourceProxy
) => {
  if (panel.areas.__id) {
    const areasConnection = store.get<AreaConnection>(panel.areas.__id);

    if (areasConnection) {
      const id = `${newAreaId}`;
      const newAreaRecord = store.create(id, "Area") as RecordProxy<Area>;
      newAreaRecord.setValue(id, "id");
      newAreaRecord.setValue("", "name");
      newAreaRecord.setValue(true, "isNew");
      newAreaRecord.setValue(fromAreaId(newAreaId).number, "number");
      newAreaRecord.setValue(16, "maxNameLength");
      newAreaRecord.setValue(false, "autoArmEnabled");
      newAreaRecord.setValue(BadZonesOption.BYPASS, "badZonesOption");
      newAreaRecord.setValue(false, "autoDisarmEnabled");

      const newEdge = ConnectionHandler.createEdge(
        store,
        areasConnection,
        newAreaRecord,
        "AreaEdge"
      );

      newEdge.setValue(id, "cursor");

      ConnectionHandler.insertEdgeAfter(areasConnection, newEdge);

      return id;
    }
  }

  return null;
};

const applyAreaProgrammingToAreaInformation = (
  source: RecordProxy<Area>,
  dest: RecordProxy<Area>,
  systemType: SystemType | undefined
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  const isNew = dest.getValue("isNew");
  const name = dest.getValue("name");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  if (systemType !== "AREA") {
    dest.setValue(name, "name");
  }

  return dest;
};
