import { MessagePosition } from "common/components/web/Tooltip";
import Icon from "components/Icon";
import InlineTooltip from "components/InlineTooltip";
import TextInput from "components/Inputs/TextInput";
import Switch from "components/Switch";
import React from "react";
import styled from "styled-components/macro";

const SettingsOption = ({
  type,
  label,
  info,
  value,
  disabled,
  checked,
  dropdownOptions,
  helpFile,
  radialInfo,
  required,
  errorAlert,
  errorMessage,
  width,
  className,
  setSecondaryNetworkOption,
  setInputChange,
}: {
  type: string;
  label?: string;
  info?: string;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  dropdownOptions?: string[];
  helpFile?: string;
  radialInfo?: string;
  required?: boolean;
  errorAlert?: boolean;
  errorMessage?: string;
  width?: string;
  className?: string;
  setSecondaryNetworkOption?: (selectedOption: string) => void;
  setInputChange?: (newText: string) => void;
}) => {
  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedOption = event.target.value;
    if (setSecondaryNetworkOption) {
      setSecondaryNetworkOption(selectedOption); // Call the function to update the option
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    if (setInputChange) {
      setInputChange(newText); // Call the provided function to update the value
    }
  };

  return (
    <Root className={className}>
      <TextContainer type={type}>
        <Label>
          {radialInfo ? (
            <RadialInfo>
              {label}
              <InlineTooltip
                message={radialInfo}
                position={MessagePosition.Left}
              />
            </RadialInfo>
          ) : (
            label
          )}
          {required ? <span className="text-danger"> *</span> : <></>}
        </Label>
        {errorAlert ? (
          <ErrorMessage>
            <Icon name="attention" />
            <ErrorMessageText>{errorMessage}</ErrorMessageText>
          </ErrorMessage>
        ) : (
          <></>
        )}
        <Description>{info}</Description>
        {helpFile ? (
          <a
            href={helpFile}
            className="da-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Helpfile
          </a>
        ) : null}
      </TextContainer>
      {type === "switch" ? (
        <Switch label="test" checked={checked} onChange={handleInputChange} />
      ) : type === "check" ? (
        <CheckBox type="checkbox" />
      ) : type === "text" ? (
        <VarOptionsTextInput
          width={width}
          disabled={disabled !== undefined ? disabled : true}
          value={value}
          onChange={handleInputChange}
        />
      ) : type === "dropdown" && dropdownOptions ? (
        <StyledSelect
          onChange={handleDropdownChange}
          className="form-control"
          value={value}
          disabled={disabled}
        >
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </StyledSelect>
      ) : null}
    </Root>
  );
};

export default SettingsOption;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.6rem;
`;
const StyledSelect = styled.select`
  max-width: 20rem;
`;
const RadialInfo = styled.span`
  font-size: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const TextContainer = styled.div<{ type: string }>`
  max-width: ${(props) =>
    props.type === "text" || props.type === "dropdown" ? "20rem" : "45rem"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${(props) =>
    props.type === "text" || props.type === "dropdown" ? "0rem" : "10rem"};
`;
const Label = styled.span`
  font-weight: bold;
  width: 15rem;
`;
const Description = styled.span`
  width: 100%;
`;
const CheckBox = styled.input`
  &:checked {
    accent-color: var(--color-info-600) !important;
  }
  width: 2.3rem;
  height: 2.3rem;
`;
const VarOptionsTextInput = styled(TextInput)`
  max-width: ${(props) => props.width || "20rem"} !important;
`;
const ErrorMessage = styled.span`
  display: flex;
  color: red;
  position: absolute;
  margin-top: 5rem;
  justify-content: flex-end;
`;
const ErrorMessageText = styled.div`
  color: black;
  margin-left: 0.4rem;
`;
