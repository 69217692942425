/**
 * @generated SignedSource<<bc8963945ccfc5bc9dfa2ef82231e7b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
export type DualAuthorityEnum = "ALL" | "ARM" | "DISARM" | "NO";
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly areaInformations: ReadonlyArray<{
    readonly armFirstArea: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly autoArmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly autoDisarmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly badZonesOption: {
      readonly data: BadZonesOption | null;
      readonly included: boolean | null;
    } | null;
    readonly bankSafeVault: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cardPlusPin: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly commonArea: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dualAuthority: {
      readonly data: DualAuthorityEnum | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lateArmDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly lateOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly openClosingReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "a031b6e70c2763564aa06862f38bb231";

export default node;
