/**
 * @generated SignedSource<<32e73cfb12965ecb65cc80d0bceda26d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateVarHubSettingsInput = {
  deviceId?: number | null;
  disableAutoAddCameras?: boolean | null;
  immixCams?: ReadonlyArray<UpdateHubSettingsImmixCamera | null> | null;
  immixEnabled?: boolean | null;
  panelIntegrationGatewayIpAddress?: string | null;
  panelIntegrationPanelDiscoveryEnabled?: boolean | null;
  panelIntegrationPanelIpAddress?: string | null;
  password?: string | null;
  recipientDomain?: string | null;
  sendAutoSuppression?: boolean | null;
  smtpPort?: number | null;
  smtpServer?: string | null;
  user?: string | null;
};
export type UpdateHubSettingsImmixCamera = {
  camName?: string | null;
  camectCamId?: string | null;
  deviceId?: number | null;
  immixInput?: number | null;
};
export type RecorderEditUpdateVarHubSettingsMutation$variables = {
  hubId: string;
  hubSettings: UpdateVarHubSettingsInput;
  systemId: string;
};
export type RecorderEditUpdateVarHubSettingsMutation$data = {
  readonly updateVarHubSettings: {
    readonly __typename: "UpdateVarHubSettingsErrorPayload";
    readonly message: string | null;
    readonly type: string | null;
  } | {
    readonly __typename: "UpdateVarHubSettingsSuccessPayload";
    readonly status: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type RecorderEditUpdateVarHubSettingsMutation = {
  response: RecorderEditUpdateVarHubSettingsMutation$data;
  variables: RecorderEditUpdateVarHubSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubSettings"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hubId",
        "variableName": "hubId"
      },
      {
        "kind": "Variable",
        "name": "hubSettings",
        "variableName": "hubSettings"
      },
      {
        "kind": "Variable",
        "name": "systemId",
        "variableName": "systemId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateVarHubSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "type": "UpdateVarHubSettingsSuccessPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UpdateVarHubSettingsErrorPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderEditUpdateVarHubSettingsMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecorderEditUpdateVarHubSettingsMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "046b7945674840712ca9f8f96a044397",
    "id": null,
    "metadata": {},
    "name": "RecorderEditUpdateVarHubSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation RecorderEditUpdateVarHubSettingsMutation(\n  $systemId: String!\n  $hubId: String!\n  $hubSettings: UpdateVarHubSettingsInput!\n) {\n  updateVarHubSettings(hubId: $hubId, systemId: $systemId, hubSettings: $hubSettings) {\n    __typename\n    ... on UpdateVarHubSettingsSuccessPayload {\n      status\n    }\n    ... on UpdateVarHubSettingsErrorPayload {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5a5b4054aad4cef64b5a9b813ef33e6";

export default node;
