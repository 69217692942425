/**
 * @generated SignedSource<<665e2160a13af16d686613873057b01a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryRemoteOptionsProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly remoteOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsAppKeyField_remoteOptions" | "RemoteOptionsContext_remoteOptions" | "RemoteOptionsRemoteDisarmField_remoteOptions">;
    } | null;
    readonly softwareVersion: string;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel">;
  };
  readonly " $fragmentType": "TMSentryRemoteOptionsProgrammingConceptForm_controlSystem";
};
export type TMSentryRemoteOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: TMSentryRemoteOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryRemoteOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryRemoteOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "remoteOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsContext_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsRemoteDisarmField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsAppKeyField_remoteOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "5c82e22abeb43f91b590cec66af3ecf3";

export default node;
