import DaColors from "app/common/DaColors";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Anchor = styled.a`
  cursor: pointer;
  font-weight: 600;
  color: ${DaColors.Primary500};
  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  text?: string;
  href?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
};

const CleanLink = ({
  children,
  text,
  href,
  openInNewTab,
  onClick,
}: PropsWithChildren<Props>) => {
  return (
    <Anchor
      href={href}
      target={openInNewTab ? "_blank" : "_self"}
      rel="noreferrer"
      onClick={onClick}
    >
      {children || text}
    </Anchor>
  );
};

export default CleanLink;
