App.factory("ModemUpgradeUnavailableDatesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/Dealers/:dealer_id/ModemUpgradeUnavailableDates/:Select",
      {},
      {
        getModemUpgradeUnavailableDates: {
          method: "GET",
          isArray: true,
          params: { dealer_id: "@dealer_id" },
        },
        batchCreateModemUpgradeUnavailableDates: {
          method: "PUT",
          params: { dealer_id: "@dealer_id", Select: "Select" },
        },
      }
    );
  },
]);
