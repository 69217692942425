import * as React from "react";
import { BaseInput, BaseInputProps } from "./BaseInput";
import InlineHelp from "./InlineHelp";
import Label from "./Label";

const TextInput = React.forwardRef(
  (
    props: BaseInputProps & {
      inlineHelp?: React.ReactNode;
      validationMessage?: string;
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { className = "", inlineHelp, validationMessage, ...rest } = props;
    return (
      <Label>
        <BaseInput
          type="text"
          {...rest}
          className={`form-control ${className}`}
          ref={ref}
        />
        {inlineHelp && <InlineHelp>{inlineHelp}</InlineHelp>}
      </Label>
    );
  }
);

export default TextInput;
