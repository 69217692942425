/**
 * @generated SignedSource<<80260b4741a5d8cdc907d09a1d183522>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationZoneRealTimeStatusField_zone$data = {
  readonly number: string;
  readonly realTimeStatusEnabled: boolean;
  readonly type: ZoneType;
  readonly " $fragmentType": "ZoneInformationZoneRealTimeStatusField_zone";
};
export type ZoneInformationZoneRealTimeStatusField_zone$key = {
  readonly " $data"?: ZoneInformationZoneRealTimeStatusField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationZoneRealTimeStatusField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationZoneRealTimeStatusField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realTimeStatusEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "28b2bc397730406949cbbb217bb37a75";

export default node;
