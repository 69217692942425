/**
 *
 * MusicIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const MusicIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M63.645,43.703c-0.022-0.053-0.055-0.099-0.079-0.15l0.016-0.007L53.486,19.349l-0.029,0.013l-1.004-2.42L23.491,28.96
        l0.571,1.377l8.198,20.815c-0.037,0-0.071-0.006-0.109-0.006c-1.387,0-2.855,0.307-4.242,0.886
        c-4.32,1.791-6.695,5.631-5.405,8.75C23.35,62.797,25.512,64,28.29,64c1.382,0,2.85-0.305,4.246-0.881
        c1.908-0.792,3.527-2.035,4.559-3.5c1.206-1.713,1.512-3.624,0.84-5.243c-0.11-0.265-0.245-0.513-0.398-0.749l-5.405-13.725
        l21.936-8.774l3.902,9.355c-0.035-0.001-0.067-0.006-0.103-0.006c-1.384,0-2.854,0.307-4.249,0.886
        c-2.51,1.038-4.502,2.86-5.328,4.873c-0.551,1.34-0.573,2.684-0.072,3.871c0.831,2.021,2.99,3.229,5.775,3.229
        c1.389,0,2.859-0.307,4.248-0.886c2.508-1.039,4.5-2.859,5.329-4.869C64.12,46.246,64.146,44.906,63.645,43.703z M33.823,57.317
        c-0.592,0.841-1.62,1.608-2.817,2.105C30.093,59.8,29.153,60,28.29,60c-1.221,0-1.942-0.396-2.094-0.756
        c-0.305-0.738,0.833-2.517,3.249-3.519c0.907-0.379,1.844-0.579,2.706-0.579c0.929,0,1.562,0.23,1.881,0.504l0.144,0.363
        l0.077-0.03C34.34,56.305,34.187,56.801,33.823,57.317z M30.664,36.18l-1.985-5.041l21.645-8.982l2.202,5.279L30.664,36.18z
         M59.872,46.056c-0.319,0.772-1.339,1.943-3.167,2.701c-0.91,0.379-1.848,0.579-2.713,0.579c-1.215,0-1.928-0.389-2.083-0.766
        c-0.106-0.253-0.016-0.582,0.08-0.813c0.317-0.773,1.335-1.943,3.159-2.698c0.915-0.38,1.855-0.581,2.718-0.581
        c1.04,0,1.898,0.316,2.087,0.768C60.059,45.497,59.967,45.825,59.872,46.056z"
          />
          <path
            d="M19.21,32.916l6.44-18.344c2.899,1.159,5.494,1.642,7.769,1.641c6.111,0,9.953-3.396,11.423-5.969l1.136-1.988
        L26.362,0.474l-9.344,26.61c-1.369-1.365-3.249-2.474-5.388-3.105c-1.303-0.39-2.615-0.587-3.901-0.587
        c-3.814,0-6.693,1.7-7.513,4.438c-0.424,1.416-0.229,2.937,0.56,4.395C2,34.484,4.577,36.38,7.664,37.294
        c1.304,0.39,2.617,0.587,3.905,0.587c3.157,0,5.723-1.237,6.946-3.225l0.074,0.025l0.109-0.312
        c0.156-0.296,0.287-0.604,0.384-0.929C19.135,33.268,19.177,33.092,19.21,32.916z M39.745,10.051
        c-2.025,1.668-6.06,3.489-12.769,0.744l1.817-5.174L39.745,10.051z M8.806,33.46c-2.469-0.73-3.949-2.102-4.512-3.14
        c-0.189-0.352-0.387-0.87-0.245-1.344c0.279-0.934,1.793-1.585,3.681-1.585c0.898,0,1.825,0.141,2.761,0.421
        c2.472,0.73,3.952,2.098,4.514,3.134c0.205,0.38,0.303,0.728,0.294,1.039l-0.242,0.688c-0.521,0.727-1.862,1.208-3.486,1.208
        C10.67,33.881,9.741,33.74,8.806,33.46z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default MusicIcon;
