App.service("QueueService", [
  "$q",
  function ($q) {
    this.waitForAllPromisesToResolve = function (promises) {
      var deferred = $q.defer();
      // check that you have promises
      if (angular.isDefined(promises)) {
        if (!angular.isArray(promises)) {
          promises = [promises];
        }
        var promisesValid = [];
        angular.forEach(promises, function (promise) {
          promisesValid.push(isValidPromise(promise));
        });
        if (
          promisesValid.every(function (isValid) {
            return isValid;
          })
        ) {
          var resolvedPromises = [];
          var anyPromiseFailed = false;
          var errors = [];

          // First create an array for all promises to indicate when they've resolved
          angular.forEach(promises, function (promise, key) {
            resolvedPromises[key] = false;
          });

          // Helper to check if all promises have resolved
          var checkStates = function () {
            if (
              resolvedPromises.every(function (isResolved) {
                return isResolved;
              })
            ) {
              if (anyPromiseFailed) {
                deferred.reject(errors);
              } else {
                deferred.resolve();
              }
            }
          };

          // Loop through the promises
          // a second loop to be sure that checkStates is called when all promises are set to false first
          angular.forEach(promises, function (promise, key) {
            $q.when(promise)
              .then(
                function () {
                  resolvedPromises[key] = true;
                  checkStates();
                },
                function (error) {
                  anyPromiseFailed = true;
                  resolvedPromises[key] = true;
                  errors[key] = error;
                  checkStates();
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          });
        } else {
          deferred.reject("Not all promises are valid.");
        }
      } else {
        deferred.reject("No promises provided.");
      }
      return deferred.promise;
    };

    var isValidPromise = function (value) {
      return value && typeof value.then === "function";
    };
  },
]);
