/**=========================================================
 * Module: sidebar-menu.js
 * Provides a simple way to implement bootstrap collapse plugin using a target
 * next to the current element (sibling)
 * Targeted elements must have [data-toggle="collapse-next"]
 =========================================================*/
App.controller("SidebarController", [
  "$rootScope",
  "$scope",
  "$state",
  "$location",
  "$modal",
  "$anchorScroll",
  "$http",
  "$timeout",
  "$filter",
  "APP_MEDIAQUERY",
  "UserService",
  "UserSettingsService",
  "RecentActivityService",
  function (
    $rootScope,
    $scope,
    $state,
    $location,
    $modal,
    $anchorScroll,
    $http,
    $timeout,
    $filter,
    mq,
    UserService,
    UserSettingsService,
    RecentActivityService
  ) {
    //$scope.Panel = new Panel(PanelProgrammingService.panel_id);
    $scope.search = {};
    $scope.recentSystems = [];
    $scope.UserService = UserService;
    var currentState = $rootScope.$state.current.name;
    var $win = $(window);
    var $html = $("html");
    var $body = $("body");

    $scope.dealer_id = UserService.dealer_id;

    UserSettingsService.useUserSettings($scope);

    $scope.getTryNewProgramming = () => true;

    /**
     * Array of Objects :  $scope.nav_menu_items
     *
     * desc: used to manipulate accordion collapse state
     * accordion collapse control
     *
     */

    $scope.nav_menu_items = [
      { name: "search", isVisible: false },
      { name: "programming", isVisible: true },
      { name: "site", isVisible: false },
      { name: "tools", isVisible: false },
      { name: "resources", isVisible: false },
      { name: "settings", isVisible: false },
      { name: "personnel", isVisible: false },
      { name: "help", isVisible: false },
    ];

    function refreshSystemList() {
      $scope.recentSystems = RecentActivityService.getList(
        RecentActivityService.props.storageKeys.system
      );
    }

    //dev function
    $rootScope.$watch("stateChange", function (newVal) {
      refreshSystemList();
    });

    /**
     * $scope.collapseOtherMenuItems
     * desc: Toggles passed menu item and closes all other menu items in  $scope.nav_menu_items
     * @param openItem: Menu item toggle that has been clicked by user
     *
     */
    $scope.collapseOtherMenuItems = function (openItem) {
      angular.forEach($scope.nav_menu_items, function (value) {
        if (value.name == openItem) {
          //toggles the state of the passed menu item.
          value.isVisible = !value.isVisible;
        } else {
          value.isVisible = false; // collapes all other menu items
        }
      });
    };

    /**
     * $rootScope.toggleMenuItem
     * desc: sets the accordion of the passed menu item (name) to visible
     * scope at
     * @param name
     */
    $rootScope.toggleMenuItem = function (name) {
      //set isVisible of passed menu item to true
      return $filter("filter")($scope.nav_menu_items, { name: name })[0]
        .isVisible;
    };
    /**
     * $scope.openAllSubmenus
     * desc: Opens all menu accordions
     *       Runs on broadcast event: collapseSidebarMenu
     *       expands all submenus
     */
    $scope.openAllSubmenus = function () {
      angular.forEach($scope.nav_menu_items, function (value) {
        // sets the value of every menu item - isVisible = true
        value.isVisible = true;
      });
    };

    /**
     *  $scope.closeSubmenus
     *  desc: keeps programming accordion open and collapses everything else
     *  runs on global broadcast event: expandSidebarMenu
     * @param name
     */
    $scope.closeSubmenus = function (name) {
      angular.forEach($scope.nav_menu_items, function (value) {
        if (value.name == "programming" || value.name == "site") {
          value.isVisible = true;
        } else {
          value.isVisible = false;
        }
      });
    };

    /* $scope.controlSubmenuVisibility = function (name) {

      if ($rootScope.app.layout.isToggled) {
        openAllSubmenus()
      }
      };*/

    /**
     *  $scope.collapseTheMenuOnRefresh
     *  desc: keeps programming accordion open and collapses everything else on refresh
     *  ONLY IF THE SIDEBAR MENU IS NOT TOGGLED TO ICON ONLY VIEW
     *  runs on global broadcast event: expandSidebarMenu
     */
    $scope.collapseTheMenuOnRefresh = function () {
      if (!$rootScope.app.layout.isToggled) {
        angular.forEach($scope.nav_menu_items, function (value) {
          if (value.name == "programming" || value.name == "site") {
            value.isVisible = true;
          } else {
            value.isVisible = false;
          }
        });
      } else {
        angular.forEach($scope.nav_menu_items, function (value) {
          value.isVisible = true;
        });
      }
    };

    /**
     *
     * global broadcast: expandSidebarMenu
     * desc:  if global broadcast event is caught then run closeSubmenus()
     */

    $scope.$on("expandSidebarMenu", function () {
      $scope.closeSubmenus();
    });

    /**
     *
     * global broadcast: collapseSidebarMenu
     * desc:  if global broadcast event is caught then run closeSubmenus()
     * $scope.openAllSubmenus()
     */

    $scope.$on("collapseSidebarMenu", function () {
      $scope.openAllSubmenus();
    });
    /**
     *
     * global broadcast: collapseSidebarMenu
     * desc:  if global broadcast event is caught then run closeSubmenus()
     * $scope.openAllSubmenus()
     */
    // Listen for the parent event on $scope
    $rootScope.$on("layoutLoaded", function (event, data) {
      $scope.collapseTheMenuOnRefresh();
    });

    $rootScope.toggleTheMenu = function () {
      $rootScope.app.layout.isToggled = !$rootScope.app.layout.isToggled;
    };

    $rootScope.scrollTo = function (selector) {
      $location.hash(selector);
      $anchorScroll();
    };

    // Adjustment on route changes
    $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams) {
        currentState = toState.name;
        // Hide sidebar automatically on mobile
        $("body.aside-toggled").removeClass("aside-toggled");
        $rootScope.app.layout.isToggled = false;
        $rootScope.$broadcast("closeSidebarMenu");
      }
    );

    // Normalize state on resize to avoid multiple checks
    $win.on("resize", function () {
      if (isMobile()) $body.removeClass("aside-collapsed");
      else {
        $body.removeClass("aside-toggled");
        if (
          angular.isDefined($rootScope.app.layout.isToggled) &&
          $rootScope.app.layout.isToggled
        )
          $rootScope.$apply(function () {
            $rootScope.app.layout.isToggled = false;
          });
      }
    });

    // Check item and children active state
    var isActive = function (item) {
      if (!item) return;

      if (!item.sref || item.sref == "#") {
        var foundActive = false;
        angular.forEach(item.submenu, function (value, key) {
          if (isActive(value)) foundActive = true;
        });
        return foundActive;
      } else return $state.is(item.sref);
    };

    // Load menu from json file
    // -----------------------------------

    $scope.getMenuItemPropClasses = function (item) {
      return (
        (item.heading ? "nav-heading" : "") + (isActive(item) ? " active" : "")
      );
    };

    $scope.loadSidebarMenu = function () {
      var menuJson = "/app/common/sidebar-menu.json",
        menuURL = menuJson + "?v=" + new Date().getTime(); // jumps cache
      $http
        .get(menuURL)
        .then(function (items) {
          $scope.menuItems = items;
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.loadSidebarMenu();

    // Handle sidebar collapse items
    // -----------------------------------
    var collapseList = [];

    $scope.addCollapse = function ($index, item) {
      collapseList[$index] = !isActive(item);
    };

    $scope.isCollapse = function ($index) {
      return collapseList[$index];
    };

    $scope.toggleCollapse = function ($index) {
      // collapsed sidebar doesn't toggle drodopwn
      if (isSidebarCollapsed() && !isMobile()) return true;
      // make sure the item index exists
      if (typeof collapseList[$index] === undefined) return true;

      closeAllBut($index);
      collapseList[$index] = !collapseList[$index];

      return true;

      function closeAllBut($index) {
        angular.forEach(collapseList, function (v, i) {
          if ($index !== i) collapseList[i] = true;
        });
      }
    };

    // Helper checks
    // -----------------------------------

    function isMobile() {
      return $win.width() < mq.tablet;
    }

    function isTouch() {
      return $html.hasClass("touch");
    }

    function isSidebarCollapsed() {
      return $body.hasClass("aside-collapsed");
    }

    function isSidebarToggled() {
      return $body.hasClass("aside-toggled");
    }

    function isSidebarMenuCollapsed() {
      return $body.hasClass("aside-collapsed");
    }

    $scope.doSearch = function () {
      $state.go("app.dealer.search", {
        dealer_id: UserService.dealer_id,
        criteria: $scope.search.category,
        parameter: $scope.search.value,
      });
    };
  },
]);

App.controller("TopNavController", [
  "$scope",
  "UserService",
  "DealerService",
  "$state",
  function ($scope, UserService, DealerService, $state) {
    $scope.unsetSelectedDealer = function () {
      UserService.unsetSelectedDealer(
        new DealerService({ dealer_id: UserService.user.accessible_id })
      )
        .then(function () {
          $state.go(
            "app.dealer.search",
            {
              dealer_id: UserService.dealer_id,
              criteria: "Dealers",
              parameter: "",
            },
            { reload: true }
          );
        })
        .catch(function (error) {
          console.error(error);
        });
    };
  },
]);

App.controller("UserBlockController", [
  "$scope",
  "UserService",
  function ($scope, UserService) {
    $scope.UserService = UserService;

    $scope.userBlockVisible = true;

    $scope.$on("toggleUserBlock", function (event, args) {
      $scope.userBlockVisible = !$scope.userBlockVisible;
    });
  },
]);
