/**
 * @generated SignedSource<<593535f541a11152c781f0cdd136d0b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type CommPathCheckInMinutesField_communicationPath$data = {
  readonly checkInMinutes: number;
  readonly checkInMinutesMax: number;
  readonly checkInMinutesMin: number;
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly useCheckIn: CommunicationPathUseCheckIn;
  readonly " $fragmentType": "CommPathCheckInMinutesField_communicationPath";
};
export type CommPathCheckInMinutesField_communicationPath$key = {
  readonly " $data"?: CommPathCheckInMinutesField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathCheckInMinutesField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathCheckInMinutesField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutesMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutesMax",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "6baed22d279f400d0f30f8e7eddf7be4";

export default node;
