import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsCIDFormatField_systemOptions$key } from "./__generated__/SystemOptionsCIDFormatField_systemOptions.graphql";

export const systemOptionsCIDFormatFieldId = () => "system-options-cid-format";

function SystemOptionsCIDFormatField(props: { is231: boolean }) {
  const { is231 } = props;
  const [{ captureFormat }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsCIDFormatField_systemOptions$key>(
      graphql`
        fragment SystemOptionsCIDFormatField_systemOptions on SystemOptions {
          ... on XtSystemOptions {
            captureFormat
          }
        }
      `
    );

  const fieldId = systemOptionsCIDFormatFieldId();

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Capture Format">
      <Select
        id={fieldId}
        name={fieldId}
        value={captureFormat ?? undefined}
        required
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "captureFormat");
          });
        }}
      >
        <Select.Option value="DMP">DMP</Select.Option>
        <Select.Option value="CID">CID</Select.Option>
        {is231 ? <Select.Option value="FOUR2">4-2</Select.Option> : null}
        {is231 ? <Select.Option value="SIA">SIA</Select.Option> : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsCIDFormatField;
