/**
 * @ngdoc service
 * @name App.factory:IntegrationService
 *
 * @description
 * API Interface service for 3rd party Integrations such as Sedona.
 *
 */
App.factory("IntegrationService", [
  "$q",
  "SedonaIntegrationV1API",
  "IntegrationV1API",
  "UserService",
  function ($q, SedonaIntegrationV1API, IntegrationV1API, UserService) {
    return {
      /**
       * @ngdoc object
       * @name method:getDealerIntegration
       * @methodOf App.factory:getDealerIntegration
       *
       * @description
       * Gets a list of all integrations for the specified dealer
       *
       * @return {promise} Returns a promise that will resolve a list of integrations for the dealer
       */
      getAllDealerIntegrations: function (dealerId) {
        var deferred = $q.defer();

        IntegrationV1API.getAllDealerIntegrations(
          {
            dealerId: dealerId,
          }, // no params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getDealerIntegrationPreferences
       * @methodOf App.factory:getDealerIntegrationPreferences
       *
       * @description
       * Gets the integration preferencesfor the specified dealer and integration type (ex. Sedona)
       *
       * @return {promise} Returns a promise that will resolve the dealer integration preferences
       */
      getDealerIntegrationPreferences: function (dealerId, integrationType) {
        var deferred = $q.defer();

        IntegrationV1API.getIntegration(
          {
            dealerId: dealerId,
            serviceType: integrationType,
          }, // no params
          function (data) {
            //success
            deferred.resolve(data.IntegrationPreference);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      createOrUpdateDealerIntegration: function (
        dealerId,
        integrationType,
        isActive
      ) {
        // Check to see if an existing integration exists for the dealer and type
        if (!Array.isArray(UserService.dealerInfo.integrations)) {
          return false;
        }
        var existingIntegration = UserService.dealerInfo.integrations.find(
          function (integration) {
            return (
              integration.ServiceType.toLowerCase() ===
              integrationType.toLowerCase()
            );
          }
        );

        return existingIntegration
          ? this.updateIntegration(
              dealerId,
              existingIntegration.Id,
              integrationType,
              isActive
            )
          : this.createIntegration(dealerId, integrationType, isActive);
      },

      /**
       * Creates a new dealer integration object for the specified dealer ID and integrationType (ex. sedona)
       * @param dealerId The dealer ID to create the integration for
       * @param integrationType The type of integration record we're creating for the dealer (ex. sedona)
       * @param isActive should the newly created integration be marked as active
       */
      createIntegration: function (dealerId, integrationType, isActive) {
        var deferred = $q.defer();

        var integrationBody = {
          ServiceType: integrationType,
          DealerId: dealerId,
          Active: isActive,
        };

        IntegrationV1API.createIntegration(
          {},
          integrationBody,
          function () {
            //successfully created the integration
            deferred.resolve();
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * Updates the integration for the specified dealer
       * @param dealerId The dealer ID whose integration we're updating
       * @param integrationId the id of the integration that we're updating
       * @param integrationType the type of integration we're updating
       * @param isActive Should the integration be marked as active
       */
      updateIntegration: function (
        dealerId,
        integrationId,
        integrationType,
        isActive
      ) {
        var deferred = $q.defer();

        var integrationBody = {
          Id: integrationId,
          ServiceType: integrationType,
          DealerId: dealerId,
          Active: isActive,
        };

        IntegrationV1API.updateIntegration(
          {
            integrationId: integrationId,
          },
          integrationBody,
          function () {
            //successfully created the integration
            deferred.resolve();
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getSedonaVersion
       * @methodOf App.factory:getSedonaVersion
       *
       * @description
       * Gets the version number of Sedona. Useful for testing if you can talk to the Sedona API
       *
       * @return {promise} Returns a promise that will resolve to an object with Sedona version information
       */
      getSedonaVersion: function (url, token) {
        var deferred = $q.defer();

        SedonaIntegrationV1API.getVersion(
          {
            // params
            url: url,
            sedonaAuthToken: token,
          },
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getSedonaCustomerTypes
       * @methodOf App.factory:getSedonaCustomerTypes
       *
       * @description
       * Gets a list of customer types available in Sedona
       *
       * @return {promise} Returns a promise that will resolve the Sedona customer types list
       */
      getSedonaCustomerTypes: function (integrationId) {
        var deferred = $q.defer();

        SedonaIntegrationV1API.getCustomerTypes(
          {
            // params
            integrationId: integrationId,
          },
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getSedonaTermCodes
       * @methodOf App.factory:getSedonaTermCodes
       *
       * @description
       * Gets a list of term codes available in Sedona
       *
       * @return {promise} Returns a promise that will resolve the Sedona term codes list
       */
      getSedonaTermCodes: function (integrationId) {
        var deferred = $q.defer();

        SedonaIntegrationV1API.getTerms(
          {
            // params
            integrationId: integrationId,
          },
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getSedonaBranches
       * @methodOf App.factory:getSedonaBranches
       *
       * @description
       * Gets a list of branch codes available in Sedona
       *
       * @return {promise} Returns a promise that will resolve the Sedona branch codes list
       */
      getSedonaBranches: function (integrationId) {
        var deferred = $q.defer();

        SedonaIntegrationV1API.getBranches(
          {
            // params
            integrationId: integrationId,
          },
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getSedonaSystemTypes
       * @methodOf App.factory:getSedonaSystemTypes
       *
       * @description
       * Gets a list of system types available in Sedona
       *
       * @return {promise} Returns a promise that will resolve the Sedona system types list
       */
      getSedonaSystemTypes: function (integrationId) {
        var deferred = $q.defer();

        SedonaIntegrationV1API.getSystemTypes(
          {
            // params
            integrationId: integrationId,
          },
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getSedonaParts
       * @methodOf App.factory:getSedonaParts
       *
       * @description
       * Gets a list of the available parts in Sedona
       *
       * @return {bool} True if video devices were retrieved, regardless of the method, else, false
       */
      getSedonaParts: function (integrationId) {
        var deferred = $q.defer();

        SedonaIntegrationV1API.getParts(
          {
            // params
            integrationId: integrationId,
          },
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getIntegrationParts
       * @methodOf App.factory:getIntegrationParts
       *
       * @description
       * Gets a list of the available integration parts
       * Integration parts link a Sales APP item to an integration (Sedona) item
       *
       * @return {promise} Returns a promise that will resolve a list of integration parts
       */
      getIntegrationParts: function (id, searchType) {
        var deferred = $q.defer();

        IntegrationV1API.getParts(
          {
            id: id,
            searchType: searchType,
          }, // no params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      updateIntegrationParts: function (integrationId, parts) {
        var deferred = $q.defer();

        IntegrationV1API.updateParts(
          {
            // params
            integrationId: integrationId,
          },
          parts, // body
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * Update the preference items for the specified integrationID
       * @param integrationId
       * @param preferences
       */
      updateIntegrationPreferences: function (integrationId, preferences) {
        var deferred = $q.defer();

        IntegrationV1API.updatePreferences(
          {
            // params
            integrationId: integrationId,
          },
          preferences, // body
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      buildIntegrationPart: function (
        integrationId,
        partNumber,
        integrationPartNumber
      ) {
        var integrationPart = {
          IntegrationId: integrationId,
          PartNumber: partNumber,
          IntegrationPartNumber: integrationPartNumber,
          Description: "",
          Active: true,
        };

        return integrationPart;
      },
    };
  },
]);
