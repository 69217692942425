import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import {
  EditingFrom,
  useTemplateContext,
} from "components/FullProgramming/common/TemplateContext";
import { useStateDotGo } from "components/SystemDiagnostics/AngularStateProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { useFragment, useMutation } from "react-relay";
import {
  asID,
  CommunicationComType,
  fromDealerId,
} from "securecom-graphql/client";
import { getInput as getBellOptionsInput } from "../Data/XFBellOptionsTemplateData";
import { getInput as getCommunicationPathInput } from "../Data/XFCommunicationPathTemplateData";
import { getInput as getDeviceInformationsInput } from "../Data/XFDeviceInformationTemplateData";
import { getInput as getLockoutCodeInput } from "../Data/XFLockoutCodeTemplateData";
import { getInput as getNetworkOptionsInput } from "../Data/XFNetworkOptionsTemplateData";
import { getInput as getOutputGroupsInput } from "../Data/XFOutputGroupsTemplateData";
import { getInput as getOutputInformationsInput } from "../Data/XFOutputInformationTemplateData";
import { getInput as getOutputOptionsInput } from "../Data/XFOutputOptionsTemplateData";
import { getInput as getRemoteOptionsInput } from "../Data/XFRemoteOptionsTemplateData";
import { getInput as getSystemOptionsInput } from "../Data/XFSystemOptionsTemplateData";
import { getInput as getSystemReportsInput } from "../Data/XFSystemReportsTemplateData";
import { getInput as getZoneInformationInput } from "../Data/XFZoneInformationTemplateData";
import { xfTemplateSaveMutation } from "./XFTemplateSaveMutation";
import { XFTemplateSaveHook_controlSystem$key } from "./__generated__/XFTemplateSaveHook_controlSystem.graphql";
import { XFTemplateSaveMutation } from "./__generated__/XFTemplateSaveMutation.graphql";

export const useXFTemplateSaveMutation = (
  controlSystem: XFTemplateSaveHook_controlSystem$key
): [
  (
    includedFields: IncludedTemplateFieldsType,
    templateName: string,
    customerId: string,
    dealerId: string
  ) => void,
  boolean
] => {
  const [saveXfTemplate, isSavingXFTemplate] =
    useMutation<XFTemplateSaveMutation>(xfTemplateSaveMutation);

  const data = useFragment(
    graphql`
      fragment XFTemplateSaveHook_controlSystem on ControlSystem {
        panel {
          hardwareModel
        }
        ...XFCommunicationProgrammingConceptFormInline_controlSystem
        ...XFNetworkOptionsProgrammingConceptFormInline_controlSystem
        ...XFDeviceSetupProgrammingConceptFormInline_controlSystem
        ...XFRemoteOptionsProgrammingConceptFormInline_controlSystem
        ...XFSystemReportsProgrammingConceptFormInline_controlSystem
        ...XFSystemOptionsProgrammingConceptFormInline_controlSystem
        ...XFBellOptionsProgrammingConceptFormInline_controlSystem
        ...XFOutputInformationProgrammingConceptFormInline_controlSystem
        ...XFOutputOptionsProgrammingConceptFormInline_controlSystem
        ...XFOutputGroupsProgrammingConceptFormInline_controlSystem
        ...XFZoneInformationProgrammingConceptFormInline_controlSystem
        ...XFLockoutCodeProgrammingConceptFormInline_controlSystem
      }
    `,
    controlSystem
  );

  const templateData = useTemplateContext();

  const showAlert = useShowAlert();
  const nav = useStateDotGo();

  return [
    (
      includedFields: IncludedTemplateFieldsType,
      templateName: string,
      customerId: string,
      dealerId: string
    ) => {
      saveXfTemplate({
        variables: {
          dealerId: dealerId,
          templateData: {
            id: templateData.templateId ? templateData.templateId : null,
            name: templateName,
            hardwareModel: templateData.hardwareModel,
            dealerId: dealerId,
            receiverCommType: CommunicationComType.NONE,
            customerId: customerId,
            concepts: {
              bellOptions: getBellOptionsInput(data, includedFields),
              communication: getCommunicationPathInput(data, includedFields),
              deviceInformations: getDeviceInformationsInput(
                data,
                includedFields
              ),
              networkOptions: getNetworkOptionsInput(data, includedFields),
              outputGroups: getOutputGroupsInput(data, includedFields),
              outputInformations: getOutputInformationsInput(
                data,
                includedFields
              ),
              outputOptions: getOutputOptionsInput(data, includedFields),
              remoteOptions: getRemoteOptionsInput(data, includedFields),
              systemOptions: getSystemOptionsInput(data, includedFields),
              systemReports: getSystemReportsInput(data, includedFields),
              zoneInformations: getZoneInformationInput(data, includedFields),
              lockoutCode: getLockoutCodeInput(data, includedFields),
            },
          },
        },
        onCompleted: (response) => {
          if (
            response.saveXfProgrammingTemplate?.__typename ===
            "SaveProgrammingTemplateSuccessResult"
          ) {
            showAlert({
              type: "success",
              text: "Successfully saved template.",
            });
            if (templateData.editingFrom === EditingFrom.NEW_TEMPLATE_PAGE) {
              nav(
                "app.dealer.default_programming",
                {
                  dealer_id: fromDealerId(asID(dealerId)).dealerId,
                },
                { reload: true }
              );
            } else if (
              templateData.editingFrom === EditingFrom.PROGRAMMING_PAGE
            ) {
              templateData.toggleIsEditing();
              nav("app.panel_programming.programming", {}, { reload: true });
            }
          } else {
            if (
              response.saveXfProgrammingTemplate?.__typename ===
              "SaveProgrammingTemplateErrorResult"
            ) {
              showAlert({
                type: "error",
                text: `Error saving template`,
              });
            }
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: `Error saving template`,
          });
        },
      });
    },
    isSavingXFTemplate,
  ];
};
