import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZoneReceiverRoute, ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationReceiverRoutingField_zone$key } from "./__generated__/ZoneInformationReceiverRoutingField_zone.graphql";

export const zoneInformationReceiverRoutingFieldId = (number: string) =>
  `zone-information-receiver-routing-${number}`;

function ZoneInformationReceiverRoutingField() {
  const [{ number, receiverRouting, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationReceiverRoutingField_zone$key>(
      graphql`
        fragment ZoneInformationReceiverRoutingField_zone on Zone {
          number
          type
          receiverRouting
        }
      `
    );

  const fieldId = zoneInformationReceiverRoutingFieldId(String(number));
  const disabled =
    type !== ZoneType.AUXILIARY_1 && type !== ZoneType.AUXILIARY_2;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Receiver Routing"
      tooltip="Select which Receiver(s) get Supervisory / Trouble messages pertaining to this Zone."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={receiverRouting ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "receiverRouting");
          });
        }}
      >
        <Select.Option value={ZoneReceiverRoute.NORMAL}>Normal</Select.Option>
        <Select.Option value={ZoneReceiverRoute.RECEIVER_ONE_ONLY}>
          Receiver 1 Only
        </Select.Option>
        <Select.Option value={ZoneReceiverRoute.RECEIVER_TWO_ONLY}>
          Receiver 2 Only
        </Select.Option>
        <Select.Option value={ZoneReceiverRoute.BOTH_RECEIVERS}>
          Both Receivers
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationReceiverRoutingField;
