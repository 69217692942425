App.controller("DealerReportsCtrl", [
  "$scope",
  "$rootScope",
  "$modal",
  "$window",
  "$state",
  "$http",
  "$timeout",
  "$filter",
  "$q",
  "MIN_VIEW_SEARCH",
  "focus",
  "DashboardDataService",
  "CustomRolesService",
  "UserService",
  "dealer_id",
  "customReport",
  "CustomReportsService",
  "$localStorage",
  "DataTablesUtilsService",
  "DTColumnDefBuilder",
  "PROPS",
  "TagsService",
  function (
    $scope,
    $rootScope,
    $modal,
    $window,
    $state,
    $http,
    $timeout,
    $filter,
    $q,
    MIN_VIEW_SEARCH,
    focus,
    DashboardDataService,
    CustomRolesService,
    UserService,
    dealer_id,
    customReport,
    CustomReportsService,
    $localStorage,
    DataTablesUtilsService,
    DTColumnDefBuilder,
    PROPS,
    TagsService
  ) {
    $scope.PROPS = PROPS;
    $scope.graphChange = graphChange;
    $scope.runCustomReport = runCustomReport;
    $scope.saveCustomReport = saveCustomReport;
    $scope.deleteCustomReport = deleteCustomReport;
    $scope.selectReportType = selectReportType;
    $scope.editReport = editReport;
    $scope.resetReport = resetReport;
    $scope.today = today;
    $scope.clear = clear;
    $scope.start_date_open = start_date_open;
    $scope.graph = {};
    $scope.graph.concept = "customers";
    $scope.selectTimeTitle = "Last 3 Months";
    $scope.selectGraphTitle = "Customers Added";
    $scope.open = false;
    $scope.chartType = "LineChart";
    var loadedCharts = [];
    $scope.UserService = UserService;
    $scope.chartTitle = "";
    $scope.wantsToSave = false;
    $scope.totalCustomers = 0;
    $scope.totalSystems = 0;
    $scope.totalUsers = 0;
    $scope.totalTroubles = 0;
    // Delete the previously stored report, if there is one.
    delete $localStorage.thisCustomReport;
    $scope.personnelNames = [];
    $scope.dealerTags = [];

    $scope.dtOptions = DataTablesUtilsService.getEmptyDTOptions("Reports");

    $scope.dtOptions.withButtons([]);
    $scope.dtOptions.withOption("order", [1, "asc"], [2, "asc"]);

    $scope.dtColumnDefs = [
      DTColumnDefBuilder.newColumnDef(0).notSortable(),
      DTColumnDefBuilder.newColumnDef(1),
      DTColumnDefBuilder.newColumnDef(2),
      DTColumnDefBuilder.newColumnDef(3).notSortable(),
      DTColumnDefBuilder.newColumnDef(4).notSortable(),
      DTColumnDefBuilder.newColumnDef(5).notSortable(),
      DTColumnDefBuilder.newColumnDef(6).notSortable(),
    ];

    // On reload of the reports & analytics page, the UserService may not have retrieved user/dealer info
    // yet. Watch for the needed info to be loaded to prior to retrieving
    $scope.$watch("UserService.user", function (newVal, oldVal) {
      if (newVal && !oldVal) {
        getCustomReportsList();
      }
    });
    $scope.$watch("UserService.dealerInfo.id", function (newVal, oldVal) {
      if (newVal && !oldVal) {
        getPersonnelData();
      }
    });

    function init() {
      // Setup the datepicker
      $scope.dateFilters = {};
      $scope.datePickerOpen = {};
      $scope.currentDate = new Date();
      $scope.today();
      getNewReport();
      initGraph();
      getTimeZones();
      getDealerTags();
      if (UserService.user) {
        getCustomReportsList();
      }
      if (UserService.dealerInfo?.id) {
        getPersonnelData();
      }
    }

    init();

    /**
     * Get personnel data
     * used to get information for primary installer
     */
    function getPersonnelData() {
      UserService.dealerInfo
        .getPersonnelNames()
        .then(
          function (data) {
            for (let i = 0; i < data.length; i++) {
              $scope.personnelNames.push(data[i]);
            }
            $scope.personnelNames.sort();
          },
          function (error) {
            console.error(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getNewReport() {
      CustomReportsService.newReport()
        .then(
          function (report) {
            $scope.thisCustomReport = report;
            $scope.thisCustomReport.init();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error with custom report server.  ",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getCustomReportsList() {
      var deferred = $q.defer();
      CustomReportsService.getAllReportsForUser(dealer_id, UserService.user.id)
        .then(
          function (reports) {
            $scope.customReports = reports.filter(function (r) {
              return r.CustomRequest.type !== "business_analytics";
            });
            if ($scope.customReports.length <= MIN_VIEW_SEARCH.NUM) {
              $scope.dtOptions.withDOM(
                '<"top"<"pull-right">>t<"bottom"<"pull-left"i>>'
              );
            }
            deferred.resolve(reports);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving saved reports.  ",
              json: error,
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    }

    $scope.quickReportItemsLeft = [
      {
        title: "app users",
        icon: "phone_x",
        report_links: [
          { title: "all app users", sref: "app.dealer.all-app-users" },
        ],
      },
      {
        title: "audit",
        icon: "logger",
        report_links: [
          { title: "app users", sref: "app.dealer.app_user_audit" },
          { title: "personnel", sref: "app.dealer.personnel_audit" },
          { title: "system", sref: "app.dealer.systems_audit" },
          { title: "all activity", sref: "app.dealer.activity_audit" },
        ],
      },
      {
        title: "cellular",
        icon: "cell_signal-full",
        report_links: [
          {
            title: "systems with low cell signal",
            sref: "app.dealer.low-cell-signal-users",
          },
        ],
      },
      {
        title: "customers",
        icon: "users",
        report_links: [
          {
            title: "all customers & systems",
            sref: "app.dealer.customer_list({dealer_id: UserService.dealer_id})",
          },
        ],
      },
    ];
    $scope.quickReportItemsRight = [
      {
        title: "last month's installs by:",
        icon: "sales_team",
        report_links: [
          {
            title: "salesperson",
            sref: "app.dealer.installs-by-salespersons({dealer_id: UserService.dealer_id})",
          },
          {
            title: "installer",
            sref: "app.dealer.installs-by-installers({dealer_id: UserService.dealer_id})",
          },
          {
            title: "type",
            sref: "app.dealer.installs-by-type({dealer_id: UserService.dealer_id})",
          },
        ],
      },
      {
        title: "systems",
        icon: "armed_shield_solid",
        report_links: [
          {
            title: "no app use last 30 days",
            sref: "app.dealer.systems-without-app-use-for-month",
          },
          {
            title: "systems added today",
            sref: "app.dealer.system-added-today",
          },
          {
            title: "all cameras",
            sref: "app.dealer.camera-status-report({report_type:'all'})",
          },
          {
            title: "cameras not online",
            sref: "app.dealer.camera-status-report({report_type:'offline'})",
          },
          {
            title: "cameras online",
            sref: "app.dealer.camera-status-report({report_type:'online'})",
          },
          {
            title: "systems without apps enabled",
            sref: "app.dealer.systems-without-apps",
          },
          {
            title: "systems with arm only app",
            sref: "app.dealer.systems-with-arming-app",
          },
          {
            title: "auto programming errors",
            sref: "app.dealer.auto-programming-errors",
          },
          {
            title: "late to open",
            sref: "app.dealer.systems-with-late-to-open",
          },
          {
            title: "early to close",
            sref: "app.dealer.systems-with-early-to-close",
          },
          {
            title: "lockdown",
            sref: "app.dealer.systems-with-lockdown",
          },
          {
            title: "bypassed zone",
            sref: "app.dealer.systems-with-bypassed-zone",
          },
          {
            title: "fire inspection due",
            sref: "app.dealer.fire-inspection-due",
          },
        ],
      },
    ];
    $scope.customReports = [];

    /**
     * Initialization Function for Multi-Graph View
     */
    function initGraph() {
      $scope.graph.concept = "control_systems";
      $scope.graph.timeFrame = "3";
      $scope.isBusy = true;
      // Get the total number of Customers, Control Systems and App Users
      var customersPromise =
        DashboardDataService.getNumberOfCustomersForDealer(dealer_id);
      var systemsPromise =
        DashboardDataService.getNumberOfPanelsForDealer(dealer_id);
      var usersPromise =
        DashboardDataService.getNumberOfAppUsersForDealer(dealer_id);
      // var now = new Date();
      // var month = now.getMonth() + 1;
      // var year = now.getFullYear();
      // var troublesPromise = DashboardDataService.getTroublesForMonth(
      //   dealer_id,
      //   month,
      //   year
      // );
      $q.all([
        customersPromise,
        systemsPromise,
        usersPromise,
        // troublesPromise
      ])
        .then(
          function (data) {
            $scope.totalCustomers = data[0].value ?? 0;
            $scope.totalSystems = data[1] ?? 0;
            $scope.totalUsers = data[2] ?? 0;
            // $scope.totalTroubles = data[3].count;
          },
          function (error) {
            // Do anything?
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      graphChange($scope.graph.timeFrame);
    }

    /**
     * Ng-Change function on graph view. Changes Displayed Graph
     * @param {int} timeFrame - Time Fram for Graph, used for title.
     */
    function graphChange(timeFrame) {
      $scope.isBusy = true;
      var chartPromise;
      var deferred = $q.defer();
      var title = "";
      var method;

      switch ($scope.graph.concept) {
        case "customers":
          title = "New Customers";
          $scope.graphDescription =
            "New Customers added per month, over the last " +
            timeFrame +
            " months";
          method = DashboardDataService.getCustomersAddedForMonth;
          deferred.resolve(
            lineGraphInit($scope.graph.concept, title, method, "LineChart")
          );
          break;
        case "control_systems":
          title = "New Systems";
          $scope.graphDescription =
            "New Systems added per month, over the last " +
            timeFrame +
            " months";
          method = DashboardDataService.getSystemsAddedForMonth;
          deferred.resolve(
            lineGraphInit($scope.graph.concept, title, method, "LineChart")
          );
          break;
        case "users":
          title = "App Users Last Login";
          $scope.graphDescription =
            "The last time each app user logged in, by month, over the last " +
            timeFrame +
            " months";
          method = DashboardDataService.getUsersSignedInForMonth;
          deferred.resolve(
            lineGraphInit($scope.graph.concept, title, method, "ColumnChart")
          );
          break;
        case "troubles":
          title = "Trouble Events";
          $scope.graphDescription =
            "Number of troubles during each month, for the last " +
            timeFrame +
            " months";
          method = DashboardDataService.getTroublesForMonth;
          deferred.resolve(
            lineGraphInit($scope.graph.concept, title, method, "LineChart")
          );
          break;
      }
      deferred.promise
        .then(function (chartObj) {
          if (isNotALoadedChart(loadedCharts, chartObj.chartName)) {
            loadedCharts.push(chartObj);
          }
          $scope.chart = chartObj;
          $scope.isBusy = false;
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Function to create a Line Graph Chart ObJ
     * @param {string} concept - ODATA Concept for Graph Data.
     * @param {string} field - Field to search for in ODATA.
     * @param {string} chartTitle - Title of Chart.
     */
    function lineGraphInit(concept, chartTitle, method, chartType) {
      $scope.chartType =
        typeof chartType !== "undefined" ? chartType : $scope.chartType;
      var apiToReference =
        concept == "vk.GetDetailedCustomerList()"
          ? "DealerODataAPI"
          : "GenericODataAPI";
      var timeFrameNum = parseInt($scope.graph.timeFrame);

      //$scope.chartType = 'LineChart';
      var myChartObject = {
        chartName: concept + timeFrameNum,
        type: $scope.chartType,
        displayed: true,
        options: {
          legend: "none",
          vAxis: {
            minValue: 0,
            viewWindow: {
              min: 0,
            },
          },
          chartArea: {
            width: "90%",
            height: "80%",
          },
          title: $scope.graphDescription,
          dmp_title: chartTitle,
          displayExactValues: true,
          titleTextStyle: {
            color: "#3d85a2",
            fontSize: 12,
            fontName:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
            bold: false,
            italic: true,
            display: false,
          },
          colors: [
            "#316B82",
            "#377892",
            "#3D85A2",
            "#5091AB",
            "#639DB4",
            "#76A9BD",
            "#89B5C6",
            "#9CC1CF",
          ],
          animation: {
            duration: 1000,
            easing: "in",
          },
        },
        formatters: {},
      };
      /**
       * Function that calculates month/year for New Control System graph
       */
      var deferred = $q.defer();
      if (isNotALoadedChart(loadedCharts, myChartObject.chartName)) {
        DashboardDataService.getCountForMonthPastRange(
          dealer_id,
          timeFrameNum,
          method,
          concept
        )
          .then(
            function (customerCount) {
              var labels = [];
              var series = [];
              var c = [];

              var label1 = {};
              label1.id = "Month";
              label1.label = label1.id;
              label1.type = "string";
              labels.push(label1);

              var label2 = {};
              label2.id = "Count";
              label2.label = label2.id;
              label2.type = "number";
              labels.push(label2);

              customerCount.reverse();
              for (var month = 0; month < customerCount.length; month++) {
                var columnTitle =
                  customerCount[month].month +
                  "/" +
                  customerCount[month].year.toString().substr(2);

                series.push({
                  c: [{ v: columnTitle }, { v: customerCount[month].count }],
                });
              }

              myChartObject.data = {
                cols: labels,
                rows: series,
              };

              deferred.resolve(myChartObject);
            },
            function (customerCountError) {
              deferred.reject(customerCountError);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        deferred.resolve(
          loadedChartLookup(loadedCharts, myChartObject.chartName)
        );
      }
      return deferred.promise;
    }

    /**
     * Function to check if a chart is loaded already.
     * @param {chartObj []} arrayOfCharts - An array of chart objects wanting to search.
     * @param {string} chartName - The name of the search
     */
    function isNotALoadedChart(arrayOfCharts, chartName) {
      return (
        typeof $filter("filter")(arrayOfCharts, {
          chartName: chartName,
        })[0] == "undefined",
        true
      );
    }

    /**
     * Function to look for chart in arrayOfCharts
     * @param {chartObj []} arrayOfCharts - An Array of chart objects wanting to search.
     * @param {string} chartName - The name of the chart you're searching for.
     */
    function loadedChartLookup(arrayOfCharts, chartName) {
      return $filter("filter")(arrayOfCharts, {
        chartName: chartName,
      })[0];
    }

    function colorSet() {
      return [
        "#316B82",
        "#377892",
        "#3D85A2",
        "#5091AB",
        "#639DB4",
        "#76A9BD",
        "#89B5C6",
        "#9CC1CF",
      ];
    }

    function today() {
      $scope.dt = new Date();
    }

    function clear() {
      $scope.dt = null;
    }

    $scope.dateOptions = {
      formatYear: "yyyy",
      startingDay: 1,
    };

    function start_date_open($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datePickerOpen.startDateOpened = true;
    }

    function runCustomReport(report) {
      if (typeof report !== "undefined") {
        $scope.thisCustomReport = new customReport(report);
      }
      $localStorage.thisCustomReport = $scope.thisCustomReport;
      $state.go("app.dealer.custom_report_view");
    }

    $scope.runNowOnServer = function (report) {
      report.runReport();
    };

    function saveCustomReport() {
      $scope.isBusy = true;
      $scope.thisCustomReport
        .save()
        .then(
          function () {
            getCustomReportsList();
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "Report was saved.",
            });
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error saving report.  ",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function deleteCustomReport(report) {
      $scope.isBusy = true;
      report
        .delete()
        .then(
          function () {
            resetReport();
            $rootScope.alerts.push({
              type: "success",
              text: "Report was deleted.",
            });
            getCustomReportsList();
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error deleting report.  ",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectReportType() {
      $scope.thisCustomReport.setDefaults();
    }

    function editReport(theReport) {
      $scope.wantsToSave = true;
      $scope.thisCustomReport = new customReport(theReport);
    }

    function resetReport() {
      $scope.wantsToSave = false;
      getNewReport();
    }

    function getTimeZones() {
      $scope["timeZones"] = [];
      CustomRolesService.getTimeZones(UserService.dealer_id)
        .then(
          function (data) {
            angular.forEach(data, function (zone) {
              $scope.timeZones.push(zone.name);
            });
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to get time zones",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getDealerTags() {
      TagsService.getDealerTags(UserService.dealer_id)
        .then(
          (tags) => ($scope.dealerTags = tags),
          (error) => {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to get tags",
            });
            console.error(error);
          }
        )
        .catch((error) => console.error(error));
    }
  },
]);
