import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsUserCommandReportsEnabledField_pcLogReports$key } from "./__generated__/PcLogReportsUserCommandReportsEnabledField_pcLogReports.graphql";

export const pcLogReportsUserCommandReportsEnabledFieldId = () =>
  "pc-log-reports-user-command-reports-enabled";

function PcLogReportsUserCommandReportsField() {
  const [{ userCommandReportsEnabled }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsUserCommandReportsEnabledField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsUserCommandReportsEnabledField_pcLogReports on PcLogReports {
          userCommandReportsEnabled
        }
      `
    );

  const fieldId = pcLogReportsUserCommandReportsEnabledFieldId();
  const label = "User Command Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Sends user code changes, schedule changes, and door access denied events."
    >
      <Switch
        label={label}
        id={fieldId}
        checked={!!userCommandReportsEnabled}
        onChange={() => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(
              !userCommandReportsEnabled,
              "userCommandReportsEnabled"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsUserCommandReportsField;
