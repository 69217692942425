// import {
//   brightnessMax,
//   contrastMax,
//   hueMax,
//   saturationMax,
//   sharpnessMax,
//   sliderMin,
// } from "src/components/CameraSettingsPage/defaults";
// import regionColors from "src/components/CameraSettingsPage/Drawing/colors";

export const SERIAL_NUMBER_DASH_INDEXES = [5, 11];
export const SERIAL_NUMBER_LENGTH_WITHOUT_DASHES = 15;
export const SERIAL_NUMBER_LENGTH_WITH_DASHES =
  SERIAL_NUMBER_LENGTH_WITHOUT_DASHES + SERIAL_NUMBER_DASH_INDEXES.length;

interface RawIensoCamera {
  active: boolean;
  device_id: string;
  device_name: string;
  device_serial_number: string;
  device_type_name: string;
  firmware: RawFirmware;
  network_interfaces: RawNetworkInterface[];
  storage_devices: RawStorageDevice[];
  status: string;
  stream: RawStream;
  time_zone: string;
  ienso_camera_id: number;
  panel_device_number: number;
}

interface RawFirmware {
  last_updated: string;
  version: string;
}

interface RawNetworkInterface {
  ip_address: string;
  mac_address: string;
  mask: number;
  type: string;
}

interface RawStorageDevice {
  cid: string;
  status: string;
}

interface RawStream {
  fps: number;
  resolution: string;
  resolution_name: string;
}

interface RawIensoCameraSettings {
  camera_name: string;
  brightness: number;
  contrast: number;
  day_night_mode: string;
  detection_regions: RawIensoCameraDetectionRegion[];
  flip_image: boolean;
  fps: number;
  hdr: boolean;
  hide_from_video_verification: boolean;
  hue: number;
  saturation: number;
  sharpness: number;
  timezone: string;
  video_quality: string;
}

interface RawIensoCameraDetectionRegion {
  detect_people: boolean;
  detect_vehicles: boolean;
  geometry: number[][];
  id: string;
  name: string;
  zone_area: number;
  zone_name: string;
  zone_number: number;
}

export type IensoCamera = {
  active: boolean;
  deviceName: string;
  firmware: IensoFirmware;
  serialNumber: string;
  deviceId: string;
  iensoCameraId: number;
  deviceTypeName: string;
  networkInterfaces: IensoNetworkInterface[];
  status: string;
  timeZone: string;
  storageDevices: IensoStorageDevice[];
  stream: IensoStream;
  panelDeviceNumber: number;
};

export interface IensoFirmware {
  lastUpdated: string;
  version: string;
}

export interface IensoNetworkInterface {
  ipAddress: string;
  macAddress: string;
  mask: number;
  type: string;
}

export interface IensoStorageDevice {
  cid: string;
  status: string;
}
export interface IensoStream {
  fps: number;
  resolution: string;
  resolutionName: string;
}

// Where feasible we want to display serial numbers to the user with dashes.
const addDashes = (serialNumber: string): string => {
  const characters = serialNumber.split("");
  SERIAL_NUMBER_DASH_INDEXES.forEach((index) => {
    characters.splice(index, 0, "-");
  });
  return characters.join("");
};

export const transformRawIensoCamera = (c: RawIensoCamera): IensoCamera => {
  return {
    active: c.active,
    deviceId: c.device_id,
    deviceName: c.device_name,
    deviceTypeName: c.device_type_name,
    firmware: parseIensoFirmware(c.firmware),
    networkInterfaces: parseIensoNetwork(c.network_interfaces),
    serialNumber: addDashes(c.device_serial_number),
    status: c.status,
    storageDevices: parseIensoStorage(c.storage_devices),
    stream: parseIensoStream(c.stream),
    timeZone: c.time_zone,
    iensoCameraId: c.ienso_camera_id,
    panelDeviceNumber: c.panel_device_number,
  };
};

export const parseIensoCameras = (
  rawIensoCameras: RawIensoCamera[]
): IensoCamera[] => {
  return rawIensoCameras.map(transformRawIensoCamera);
};

const parseIensoFirmware = (rFirmware: RawFirmware): IensoFirmware => {
  return {
    lastUpdated: rFirmware.last_updated,
    version: rFirmware.version,
  };
};

const parseIensoNetwork = (
  rNetwork: RawNetworkInterface[]
): IensoNetworkInterface[] => {
  return rNetwork.map((i) => ({
    ipAddress: i.ip_address,
    macAddress: i.mac_address,
    mask: i.mask,
    type: i.type,
  }));
};

const parseIensoStorage = (
  rStorage: RawStorageDevice[]
): IensoStorageDevice[] => {
  return rStorage.map((s) => ({
    cid: s.cid,
    status: s.status,
  }));
};

const parseIensoStream = (rStream: RawStream): IensoStream => {
  return {
    fps: rStream.fps,
    resolution: rStream.resolution,
    resolutionName: rStream.resolution_name,
  };
};

// export const parseRawIensoCameraSettings = (
//   settings: RawIensoCameraSettings,
// ): ConfigurableCameraSettings => {
//   const detectionRegions =
//     settings.detection_regions.map((dr, i) => {
//       const hasZoneName = dr.zone_name && dr.zone_name.length != 0;
//       const hasZoneNumber = dr.zone_number;
//       const hasPersistedAlarmZone = Boolean(hasZoneName || hasZoneNumber);

//       return {
//         detectPeople: dr.detect_people,
//         detectVehicles: dr.detect_vehicles,
//         displayRegions: true,
//         geometry: dr.geometry,
//         name: dr.name,
//         id: dr.id,
//         zoneArea: dr.zone_area,
//         zoneName: dr.zone_name,
//         zoneNumber: dr.zone_number,
//         validationErrors: { name: [], detection: [], zoneArea: [], zoneName: [], zoneNumber: [] },
//         hasPersistedAlarmZone,
//         index: i + 1,
//         dotColor: regionColors.classNames[i],
//         drawingColor: regionColors.hex[i],
//       } as CameraDetectionRegion;
//     }) || [];

//   return {
//     brightness: calcSliderValue(settings.brightness, sliderMin, brightnessMax),
//     cameraName: settings.camera_name,
//     contrast: calcSliderValue(settings.contrast, sliderMin, contrastMax),
//     dayNightMode: settings.day_night_mode,
//     detectionRegions: detectionRegions,
//     flipImage: settings.flip_image,
//     fps: settings.fps,
//     hdr: settings.hdr,
//     hideFromVideoVerification: settings.hide_from_video_verification,
//     hue: calcSliderValue(settings.hue, sliderMin, hueMax),
//     saturation: calcSliderValue(settings.saturation, sliderMin, saturationMax),
//     sharpness: calcSliderValue(settings.sharpness, sliderMin, sharpnessMax),
//     timeZone: settings.timezone,
//     videoQuality: settings.video_quality,
//   };
// };

//convert ConfigurableCamera settings (settings for sake of changing settings in AP-UI)
//back into a RawIensoCameraSettings for purposes of POST'ing it to CamectAPI
// export const serializeConfigurableCameraSettings = (
//   settings: ConfigurableCameraSettings
// ): RawIensoCameraSettings => {
//   const detectionRegions = settings.detectionRegions?.map((dr) => {
//     return {
//       detect_people: dr.detectPeople,
//       detect_vehicles: dr.detectVehicles,
//       geometry: dr.geometry,
//       id: dr.id,
//       name: dr.name,
//       zone_area: dr.zoneArea,
//       zone_name: dr.zoneName,
//       zone_number: dr.zoneNumber,
//     };
//   }) as RawIensoCameraDetectionRegion[];

//   return {
//     brightness: calcSliderPercentage(
//       settings.brightness,
//       sliderMin,
//       brightnessMax
//     ),
//     camera_name: settings.cameraName,
//     contrast: calcSliderPercentage(settings.contrast, sliderMin, contrastMax),
//     day_night_mode: settings.dayNightMode,
//     detection_regions: detectionRegions,
//     flip_image: settings.flipImage,
//     fps: settings.fps,
//     hdr: settings.hdr,
//     hide_from_video_verification: settings.hideFromVideoVerification,
//     hue: calcSliderPercentage(settings.hue, sliderMin, hueMax),
//     saturation: calcSliderPercentage(
//       settings.saturation,
//       sliderMin,
//       saturationMax
//     ),
//     sharpness: calcSliderPercentage(
//       settings.sharpness,
//       sliderMin,
//       sharpnessMax
//     ),
//     timezone: settings.timeZone,
//     video_quality: settings.videoQuality,
//   };
// };

// For get interfaces with an ip address, preferring the 'WIRED' type
export const getActiveIensoInterface = (
  iensoNetworkInterfaces: IensoNetworkInterface[]
): IensoNetworkInterface | undefined => {
  let ipInterfaces = iensoNetworkInterfaces.filter((i) => i.ipAddress);
  if (ipInterfaces.length > 1) {
    const types = new Set(ipInterfaces.map((i) => i.type));
    if (types.size > 1) {
      ipInterfaces = ipInterfaces.filter((i) => i.type == "WIRED");
    }
  }
  return ipInterfaces[0];
};

export const calcSliderValue = (
  percentage: number,
  min: number,
  max: number
): number => {
  const value = (max - min) * percentage - Math.abs(min);
  return Number(value.toFixed(0));
};

export const calcSliderPercentage = (
  value: number,
  min: number,
  max: number
): number => {
  const percentage = (value + Math.abs(min)) / (max - min);
  return Number(percentage.toFixed(2));
};
