import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsZoneRestoralReportsField_systemReports$key } from "./__generated__/SystemReportsZoneRestoralReportsField_systemReports.graphql";

export const systemReportsZoneRestoralReportsFieldId = () =>
  "system-reports-zone-restoral-reports";

function SystemReportsZoneRestoralReportsField() {
  const [{ zoneRestoralReports }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsZoneRestoralReportsField_systemReports$key>(
      graphql`
        fragment SystemReportsZoneRestoralReportsField_systemReports on SystemReports {
          zoneRestoralReports
        }
      `
    );

  const fieldId = systemReportsZoneRestoralReportsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Zone Restoral Reports"
      tooltip="If enabled in Communication Programming, send messages when a zone restores from trouble or alarm conditions."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={zoneRestoralReports}
        required
        onChange={({ target }) => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(target.value, "zoneRestoralReports");
          });
        }}
      >
        <Select.Option value="YES">Yes</Select.Option>
        <Select.Option value="NO">No</Select.Option>
        <Select.Option value="DISARM">Disarm</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsZoneRestoralReportsField;
