import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathTestReportField_communicationPath$key } from "./__generated__/CommPathTestReportField_communicationPath.graphql";

export const commPathTestReportFieldId = (number: string) =>
  `comm-path-test-report-${number}`;

function CommPathTestReportField() {
  const [commPath, updateCommPath] =
    useCommPathFragment<CommPathTestReportField_communicationPath$key>(
      graphql`
        fragment CommPathTestReportField_communicationPath on CommunicationPath {
          number
          testReport
        }
      `
    );

  const fieldId = commPathTestReportFieldId(String(commPath.number));

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Test Report"
      tooltip="Determines strategy for sending Test Reports on this communication path."
    >
      <Select
        id={fieldId}
        value={commPath.testReport}
        required
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "testReport");
          });
        }}
      >
        <Select.Option value="YES">Yes</Select.Option>
        <Select.Option value="NO">No</Select.Option>
        <Select.Option value="DEFER">Defer</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathTestReportField;
