import Modal from "components/Modal";
import React, { useState } from "react";
import { react2angular } from "react2angular";
import styled from "styled-components";

const CloserIconButton = styled.button`
  border: none;
  color: var(--color-neutral-700);
  background-color: transparent;
  @media (min-width: 84.4rem) {
    color: white;
  }
`;
const SidebarButton = styled.button`
  border: none;
  padding: 0.5rem 0 0.5rem 4.4rem;
  font-size: 1.2rem;
  position: relative;
  display: block;
  color: #3d85a3;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

function MarketingCentralModal({ closeModal }: { closeModal: () => void }) {
  return (
    <Modal size="large">
      <div className="marketing-central-splash">
        <div className="marketing-central-splash__body modal-body">
          <div className="marketing-central-splash__closer">
            <CloserIconButton onClick={closeModal}>
              <span className="icon-close_cancel"></span>
            </CloserIconButton>
          </div>
          <div className="marketing-central-splash__logo">
            <img
              src="assets/img/MarketingCentral_Horizontal_Color.svg"
              alt="marketing central logo"
            />
          </div>
          <div className="marketing-central-splash__subheader">
            Marketing materials to help promote your services and grow your
            business
          </div>
          <p className="marketing-central-splash__description">
            Create professional literature with your logo, company description,
            and contact information.
          </p>
          <a
            className="btn btn-block marketing-central-splash__btn"
            href="https://www.dmp.com/company/dealer-services/marketing#sign-up-for-marketing-central"
            target="_blank"
          >
            SIGN UP NOW
          </a>
          <p className="marketing-central-splash__login">
            Already have an account?
            <a
              className="link link-primary mar-l-8"
              href="https://portal.assets.site/23783"
              target="_blank"
            >
              Log in
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
}

export function MarketingCentralDisplay() {
  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <>
      <SidebarButton onClick={() => setSettingsOpen(true)}>
        Marketing Central
      </SidebarButton>
      {settingsOpen && (
        <MarketingCentralModal
          closeModal={() => setSettingsOpen(false)}
        ></MarketingCentralModal>
      )}
    </>
  );
}
export function dangerouslyAddToApp() {
  App.component(
    "marketingCentralDisplay",
    react2angular(MarketingCentralDisplay, [], ["$rootScope", "$scope"])
  );
}
