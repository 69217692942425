App.factory("UserODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/users()/",
      {},
      {
        getUsers: { method: "GET" },
      }
    );
  },
]);

App.factory("ControlSystemsODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/control_systems/",
      {},
      {
        getSystems: { method: "GET" },
      }
    );
  },
]);

App.factory("PanelsODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/panels(:id)/:concept",
      {},
      {
        getCredentials: {
          method: "GET",
          params: { concept: "vk.GetCredentials" },
        },
        getUserCodes: { method: "GET", params: { concept: "vk.GetUserCodes" } },
      }
    );
  },
]);

App.factory("VideoDevicesODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/video_devices/",
      {},
      {
        getVideoDevices: { method: "GET" },
      }
    );
  },
]);

App.factory("ThirdPartyVideoDevicesODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/third_party_video_devices/",
      {},
      {
        getThirdPartyVideoDevices: { method: "GET" },
      }
    );
  },
]);

App.factory("CustomersODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/customers/",
      {},
      {
        get: { method: "GET" },
      }
    );
  },
]);

App.factory("AlarmVisionXVMACAddressAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    const XVResource = $resource(
      PROPS.camectUrl + "/api/v1/camect/hubs/:macAddress"
    );
    return {
      getXV: (macAddress) => {
        // Use $get method of the resource, which returns a promise
        return XVResource.get({ macAddress: macAddress }).$promise;
      },
    };
  },
]);
