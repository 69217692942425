/**
 * @generated SignedSource<<847964304cb8bd323b4eba495df9d8ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MassProgrammingGlobalHolidayDatesFragment$data = {
  readonly globalHolidayDateJobs: ReadonlyArray<{
    readonly completedAt: string;
    readonly createdAt: string;
    readonly failedToUpdate: number;
    readonly groupStatus: string;
    readonly jobData: {
      readonly operation: string | null;
    } | null;
    readonly jobStatus: string;
    readonly personWhoStartedJob: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly userId: number | null;
    } | null;
    readonly scapiId: number;
    readonly schedulerJobTypeId: number;
    readonly successfullyUpdated: number;
    readonly totalSystems: number;
    readonly updateInProgress: number;
    readonly waitingForUpdate: number;
  } | null>;
  readonly globalHolidayDates: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly " $fragmentType": "MassProgrammingGlobalHolidayDatesFragment";
};
export type MassProgrammingGlobalHolidayDatesFragment$key = {
  readonly " $data"?: MassProgrammingGlobalHolidayDatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MassProgrammingGlobalHolidayDatesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./MassProgrammingGlobalHolidayDatesRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "MassProgrammingGlobalHolidayDatesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SchedulerJob",
      "kind": "LinkedField",
      "name": "globalHolidayDateJobs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scapiId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonWhoStartedJob",
          "kind": "LinkedField",
          "name": "personWhoStartedJob",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JobDataType",
          "kind": "LinkedField",
          "name": "jobData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "operation",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "failedToUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groupStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSystems",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "successfullyUpdated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updateInProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waitingForUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schedulerJobTypeId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalHolidayDate",
      "kind": "LinkedField",
      "name": "globalHolidayDates",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Dealer",
  "abstractKey": null
};
})();

(node as any).hash = "c7bd72da91348d78606f9c8685d760bb";

export default node;
