import graphql from "babel-plugin-relay/macro";
import { addClassesIf } from "common/utils/web/css";
import {
  Input,
  Panel,
  Table,
  TableFooter,
  TableFooterReducedPadding,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import ExportTableButtons from "components/ExportTableButtons";
import { ascend, descend, isEmpty, sort, splitEvery } from "ramda";
import * as React from "react";
import { useFragment } from "react-relay/hooks";
import { objectSearch } from "search-filterer";
import styled from "styled-components";
import {
  TableHeaderSorting,
  TableHeaderSortingTextRight,
} from "./TableStyledComponents";
import { AvailableCredentialsTableFragment_dealer$key } from "./__generated__/AvailableCredentialsTableFragment_dealer.graphql";

enum SortKey {
  CustomerName,
  TotalPurchased,
  Active,
  Available,
}
const MinHeightPanelTable = styled(Panel.Table)`
  min-height: 48rem;
`;

const ButtonThatLooksLikeLink = styled.button`
  border: none;
  background-color: transparent;
  color: var(--color-primary);
  font-weight: 600;
  transition: all 200ms;

  &:hover {
    color: var(--color-primary-800);
  }
`;

export default function AvailableCredentialsTable({
  linkFunction,
  dealerRef,
}: {
  linkFunction: (customerId: number) => void;
  dealerRef: AvailableCredentialsTableFragment_dealer$key;
}) {
  const data = useFragment(
    graphql`
      fragment AvailableCredentialsTableFragment_dealer on Dealer {
        credentialsSummaryConnection {
          nodes {
            id
            availableCredits
            totalPurchased
            totalMobileCredentials
            customer {
              id
              name
              databaseId
            }
          }
        }
      }
    `,
    dealerRef
  );

  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Ascending,
    sortKey: SortKey.CustomerName,
    pageSize: 5,
  });

  // break these out so changing page size doesn't cause it to re-search etc...
  const searchedSummaries = React.useMemo(
    () =>
      objectSearch(
        paginationState.search,
        ["customer.name"],
        data.credentialsSummaryConnection.nodes
      ),
    [data.credentialsSummaryConnection.nodes, paginationState.search]
  );

  const sortedSummaries = React.useMemo(
    () =>
      sort(
        (paginationState.sortOrder === SortOrder.Ascending ? ascend : descend)(
          (summary) => {
            switch (paginationState.sortKey) {
              case SortKey.CustomerName:
                return summary.customer?.name.toLowerCase();
              case SortKey.TotalPurchased:
                return summary.totalPurchased;
              case SortKey.Active:
                return summary.totalMobileCredentials;
              case SortKey.Available:
                return summary.availableCredits;
              default:
                return summary.customer?.name.toLowerCase();
            }
          }
        ),
        searchedSummaries
      ),
    [paginationState.sortKey, paginationState.sortOrder, searchedSummaries]
  );

  const pagedSummaries = React.useMemo(
    () =>
      splitEvery(paginationState.pageSize, sortedSummaries)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, sortedSummaries]
  );

  const maxPage = Math.ceil(sortedSummaries.length / paginationState.pageSize);
  return (
    <MinHeightPanelTable>
      <TableHeader>
        <TableHeader.Left>
          <label className="sr-only" htmlFor="search-input">
            Search
          </label>
          <Input.TableSearch
            id="search-input"
            value={paginationState.search}
            onChange={(search) => {
              dispatch({
                type: ActionType.SetSearch,
                search,
              });
            }}
          />
        </TableHeader.Left>

        <TableHeader.Right>
          <ExportTableButtons
            title="Mobile Credentials Customer Summary"
            columnTitles={[
              "Customer",
              "Total Purchased",
              "Active",
              "Available",
            ]}
            widthPercentages={[40, 20, 20, 20]}
            data={React.useMemo(
              () =>
                sort(
                  ascend(
                    (summary) => summary.customer?.name.toLowerCase() ?? ""
                  ),
                  data.credentialsSummaryConnection.nodes
                ).map((summary) => [
                  summary.customer?.name ?? "",
                  String(summary.totalPurchased),
                  String(summary.totalMobileCredentials),
                  String(summary.availableCredits),
                ]),
              [data.credentialsSummaryConnection.nodes]
            )}
          />
        </TableHeader.Right>
      </TableHeader>

      <Table>
        <thead>
          <tr>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [
                    paginationState.sortKey === SortKey.CustomerName,
                    "is-active",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.CustomerName) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.CustomerName,
                  });
                }
              }}
            >
              Customer
            </TableHeaderSorting>

            <TableHeaderSortingTextRight
              className={addClassesIf(
                [
                  [
                    paginationState.sortKey === SortKey.TotalPurchased,
                    "is-active",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.TotalPurchased) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.TotalPurchased,
                  });
                }
              }}
            >
              Total Purchased
            </TableHeaderSortingTextRight>

            <TableHeaderSortingTextRight
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Active, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Active) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Active,
                  });
                }
              }}
            >
              Active
            </TableHeaderSortingTextRight>

            <TableHeaderSortingTextRight
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Available, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Available) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Available,
                  });
                }
              }}
            >
              Available
            </TableHeaderSortingTextRight>
          </tr>
        </thead>

        <tbody>
          {!isEmpty(pagedSummaries) ? (
            pagedSummaries.map((summary) => (
              <tr key={summary.customer?.id}>
                <td>
                  {summary.customer?.name && summary.customer?.databaseId ? (
                    <ButtonThatLooksLikeLink
                      onClick={() => {
                        linkFunction(summary.customer?.databaseId!);
                      }}
                    >
                      {summary.customer?.name}
                    </ButtonThatLooksLikeLink>
                  ) : (
                    ""
                  )}
                </td>
                <td className="text-right">{summary.totalPurchased}</td>
                <td className="text-right">{summary.totalMobileCredentials}</td>
                <td className="text-right">{summary.availableCredits}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>No data</td>
            </tr>
          )}
        </tbody>
      </Table>

      <TableFooterReducedPadding>
        <TableFooter.Left></TableFooter.Left>
        <TableFooter.Center>
          <Paginate.Paginator
            state={paginationState}
            dispatch={dispatch}
            maxPage={maxPage}
          />
        </TableFooter.Center>
        <TableFooter.Right>
          {" "}
          <Paginate.ItemsPerPage state={paginationState} dispatch={dispatch} />
        </TableFooter.Right>
      </TableFooterReducedPadding>
    </MinHeightPanelTable>
  );
}
