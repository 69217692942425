/**
 * @generated SignedSource<<7bfdcbcbf59672e7c580671fb037ed17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgrammingTemplateForm_controlSystem$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryTemplateSaveHook_controlSystem" | "TakeoverTemplateSaveHook_controlSystem" | "XFTemplateSaveHook_controlSystem" | "XRTemplateSaveHook_controlSystem" | "XT75TemplateSaveHook_controlSystem" | "XTTemplateSaveHook_controlSystem">;
  readonly " $fragmentType": "ProgrammingTemplateForm_controlSystem";
};
export type ProgrammingTemplateForm_controlSystem$key = {
  readonly " $data"?: ProgrammingTemplateForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgrammingTemplateForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgrammingTemplateForm_controlSystem",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTTemplateSaveHook_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRTemplateSaveHook_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75TemplateSaveHook_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFTemplateSaveHook_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverTemplateSaveHook_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryTemplateSaveHook_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "8be501bddef293b04ed5f15a4e864b4d";

export default node;
