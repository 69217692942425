/**
 * @ngdoc service
 * @name App.factory:RemoteUpdateService
 *
 * @description
 * Manages multi-panel jobs through the job scheduler
 *
 */
App.factory("MultiPanelService", [
  "$rootScope",
  "$q",
  "$http",
  "PROPS",
  "$filter",
  "JobSchedulerService",
  "$interval",
  "UserService",
  "Customer",
  "Panel",
  "ControlSystemODataAPI",
  function (
    $rootScope,
    $q,
    $http,
    PROPS,
    $filter,
    JobSchedulerService,
    $interval,
    UserService,
    Customer,
    Panel,
    ControlSystemODataAPI
  ) {
    return {
      // These arrays hold lists of job types that are used for querying the job scheduler API
      XT_SCHEDULE_JOBS: [
        "ADD_ARM_SCHEDULE_V2",
        "UPDATE_ARM_SCHEDULE_V2",
        "DELETE_ARM_SCHEDULE_V2",
        "ADD_OUT_SCHEDULE_V2",
        "UPDATE_OUT_SCHEDULE_V2",
        "DELETE_OUT_SCHEDULE_V2",
        "ADD_FAV_SCHEDULE_V2",
        "UPDATE_FAV_SCHEDULE_V2",
        "DELETE_FAV_SCHEDULE_V2",
      ],
      XR_SCHEDULE_JOBS: [
        "ADD_XR_SCHEDULE_V2",
        "UPDATE_XR_SCHEDULE_V2",
        "DELETE_XR_SCHEDULE_V2",
      ],
      XT75_SCHEDULE_JOBS: [
        "ADD_XR_SCHEDULE_V2",
        "UPDATE_XR_SCHEDULE_V2",
        "DELETE_XR_SCHEDULE_V2",
      ],
      XT_USER_JOBS: [
        "ADD_XT_USER_V2",
        "UPDATE_XT_USER_V2",
        "DELETE_XT_USER_V2",
      ],
      XR_USER_JOBS: [
        "ADD_XR_USER_V2",
        "UPDATE_XR_USER_V2",
        "DELETE_XR_USER_v2",
      ],
      ACTIVE_JOB_STATUSES: ["NEW", "CREATED", "ACQUIRED", "STARTED", "RUNNING"],
      COMPLETE_JOB_STATUSES: [
        "COMPLETE",
        "SUCCESS",
        "FAILED",
        "FAIL",
        "ERROR",
        "UNKNOWN",
      ],

      initialize: function () {
        this.stopMultiPanelWatcher();
      },

      /**
       * Creates and runs a multi-panel job then returns the result
       * @param jobType {string} The type of job that we're running (ex. ADD_ARM_SCHEDULE_V2, ADD_XR_USER_V2, etc)
       * @param panels {array} A list of panels that we're running the job on. (Only the panel id field is required)
       * @param jobData {object} optional for delete. The data that we're passing to the job scheduler. Either an object or an array if different data needs to be passed for each panel
       * @param itemIdentifier {string} optional for create. This is the unique identifier for the object (typically number)
       */
      createMultiPanelJob: function (jobType, panels, jobData, itemIdentifier) {
        var _this = this;
        var deferred = $q.defer();
        var queuedJobs = [];

        // Determine the job and group name based on the jobType
        var jobDetails = _this.calcJobDetails(jobType);

        // Loop through all panels, creating a multi-panel job for each one
        for (var i = 0; i < panels.length; i++) {
          // Since we can pass in either an object or an array for jobData, figure out which one was passed in,
          // so we know what data to send along with the job.
          var data = Array.isArray(jobData) ? jobData[i] : jobData;
          switch (jobDetails.command) {
            case "create":
              queuedJobs.push(
                _this.panelCreateJob(data, panels[i].id, jobDetails.job)
              );
              break;
            case "update":
              queuedJobs.push(
                _this.panelUpdateJob(
                  data,
                  panels[i].id,
                  jobDetails.job,
                  itemIdentifier
                )
              );
              break;
            case "delete":
              queuedJobs.push(
                _this.panelDeleteJob(
                  panels[i].id,
                  jobDetails.job,
                  itemIdentifier
                )
              );
              break;
          }
        }

        // Run all of our queued jobs, creating SchedulerJobs for each one
        // On success, create and run a SchedulerJobGroup
        $q.all(queuedJobs)
          .then(
            function (jobs) {
              // SchedulerJobs created, run the SchedulerJobGroup
              _this
                .scheduleJob(jobDetails.group, jobs)
                .then(
                  function (result) {
                    deferred.resolve();
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Builds a scheduled job used for creating a panel concept
       * @returns {Promise} a promise, which will eventually resolve the JSON data of a Scheduler Job
       */
      panelCreateJob: function (remoteJson, panelId, jobType) {
        var deferred = $q.defer();
        var job = {};

        // Get details for the job by name
        JobSchedulerService.getJobTypeByName(jobType)
          .then(
            function (data) {
              job.SchedulerJobTypeId = data.Id;
              job.PanelId = panelId;
              job.RemoteJson = angular.toJson(remoteJson);
              deferred.resolve(job);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Builds a scheduled job used for updating an existing panel concept
       * @returns {Promise} a promise, which will eventually resolve the JSON data of a Scheduler Job
       */
      panelUpdateJob: function (remoteJson, panelId, jobType, selectedItem) {
        var deferred = $q.defer();
        var job = {};

        // Get details for the job by name
        JobSchedulerService.getJobTypeByName(jobType)
          .then(
            function (data) {
              job.SchedulerJobTypeId = data.Id;
              job.PanelId = panelId;
              job.SelectedItem = selectedItem;
              job.RemoteJson = angular.toJson(remoteJson);
              deferred.resolve(job);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Builds a scheduled job used for deleting an existing panel concept
       * @returns {Promise} a promise, which will eventually resolve the JSON data of a Scheduler Job
       */
      panelDeleteJob: function (panelId, jobType, selectedItem) {
        var deferred = $q.defer();
        var job = {};

        // Get details for the job by name
        JobSchedulerService.getJobTypeByName(jobType)
          .then(
            function (data) {
              job.SchedulerJobTypeId = data.Id;
              job.PanelId = panelId;
              job.SelectedItem = selectedItem;
              deferred.resolve(job);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Creates a scheduled JobGroup to perform a multi panel action
       * @returns {Promise} a promise, which will eventually resolve the JSON data of the JobGroup that is created.
       */
      scheduleJob: function (groupType, jobs) {
        var _this = this;
        var deferred = $q.defer();
        var jobGroup = {};

        JobSchedulerService.getJobGroupTypeByName(groupType)
          .then(
            function (groupData) {
              jobGroup.Email = UserService.email;
              jobGroup.SchedulerJobGroupTypeId = groupData.Id;

              jobGroup.PanelId = UserService.panel_id;

              // push each supplied job to the jobGroup
              jobGroup.SchedulerJobs = jobs;

              JobSchedulerService.createJobGroup(jobGroup)
                .then(
                  function (data) {
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the status of that specific Job Group ID
       * @param jobID
       */
      getUpdateStatus: function (jobGroupID) {
        var deferred = $q.defer();
        JobSchedulerService.getJobGroup(jobGroupID)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Updates for any panel that my auth_token has access to (Dealer?)
       * @returns {*}
       */
      getAllUpdates: function () {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupTypeByName(_this.JOB_GROUP_TYPE_NAME)
          .then(
            function (data) {
              var jobGroupTypeID = data.Id;
              JobSchedulerService.getJobGroups(jobGroupTypeID)
                .then(
                  function (data) {
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Get all updates for the specified Dealer
       * @param dealer_id
       * @returns {*}
       */
      getAllUpdatesForDealer: function (dealer_id) {
        var _this = this;
        var deferred = $q.defer();

        _this
          .getAllUpdates()
          .then(
            function (data) {
              //Clear the previous updateJobGroups
              _this.updateJobGroups = $filter("filter")(data, {
                DealerId: dealer_id,
              });
              deferred.resolve(_this.updateJobGroups);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Get all specified running or scheduled jobs for activePanel
       *
       * @returns {*}
       */
      getActiveJobsForPanelConcept: function (jobList) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobsListByPanel(jobList, UserService.panel_id)
          .then(
            function (data) {
              // Filter out any jobs that aren't active
              var activeJobs = data.filter(function (job) {
                return (
                  _this.ACTIVE_JOB_STATUSES.indexOf(
                    job.JobStatus.toUpperCase()
                  ) > -1
                );
              });
              deferred.resolve(activeJobs);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * A watcher to check multi-panel jobs on an interval, stopping when complete or when stopped manually.
       * @param jobList
       */
      startMultiPanelWatcher: function (jobList) {
        var _this = this;
        this.activeMultiPanelWatcher = $interval(function () {
          _this
            .getActiveJobsForPanelConcept(jobList)
            .then(
              function (data) {
                // If there are no pending jobs, stop the watcher and return the results
                if (data.length == 0) {
                  _this.stopMultiPanelWatcher(_this.activeMultiPanelWatcher);
                }
                // Send event to trigger controller to reload the route
                $rootScope.$broadcast("event-multiPanelStatusUpdate", data);
              },
              function (error) {
                _this.stopMultiPanelWatcher(_this.activeMultiPanelWatcher);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }, PROPS.multiPanelWatcherInterval);
      },

      /**
       * Stop the activeMultiPanelWatcher interval update watcher
       * @param promise
       */
      stopMultiPanelWatcher: function (promise) {
        // If a promise is passed in, use that, otherwise, use the local promise
        promise =
          typeof promise === "undefined"
            ? this.activeMultiPanelWatcher
            : promise;
        if (angular.isDefined(promise)) {
          $interval.cancel(promise);
          promise = undefined;
        }
      },

      /**
       * @ngdoc object
       * @name method:calcJobDetails
       * @methodOf App.Controller:MultiPanelService
       *
       * @description
       * Determines what the appropriate job group name and job names are based on the desired action
       */
      calcJobDetails: function (action) {
        var jobDetails = {};

        switch (action) {
          case "ADD_XR_SCHEDULE_V2":
            jobDetails.group = "ADD_XR_SCHEDULE";
            jobDetails.job = "ADD_XR_SCHEDULE_V2";
            jobDetails.command = "create";
            break;
          case "UPDATE_XR_SCHEDULE_V2":
            jobDetails.group = "UPDATE_XR_SCHEDULE";
            jobDetails.job = "UPDATE_XR_SCHEDULE_V2";
            jobDetails.command = "update";
            break;
          case "DELETE_XR_SCHEDULE_V2":
            jobDetails.group = "DELETE_XR_SCHEDULE";
            jobDetails.job = "DELETE_XR_SCHEDULE_V2";
            jobDetails.command = "delete";
            break;
          case "ADD_XT75_SCHEDULE_V2":
            jobDetails.group = "ADD_XT75_SCHEDULE";
            jobDetails.job = "ADD_XT75_SCHEDULE_V2";
            jobDetails.command = "create";
            break;
          case "UPDATE_XT75_SCHEDULE_V2":
            jobDetails.group = "UPDATE_XT75_SCHEDULE";
            jobDetails.job = "UPDATE_XT75_SCHEDULE_V2";
            jobDetails.command = "update";
            break;
          case "DELETE_XT75_SCHEDULE_V2":
            jobDetails.group = "DELETE_XT75_SCHEDULE";
            jobDetails.job = "DELETE_XT75_SCHEDULE_V2";
            jobDetails.command = "delete";
            break;
          case "ADD_ARM_SCHEDULE_V2":
            jobDetails.group = "ADD_XT_SCHEDULE";
            jobDetails.job = "ADD_ARM_SCHEDULE_V2";
            jobDetails.command = "create";
            break;
          case "UPDATE_ARM_SCHEDULE_V2":
            jobDetails.group = "UPDATE_XT_SCHEDULE";
            jobDetails.job = "UPDATE_ARM_SCHEDULE_V2";
            jobDetails.command = "update";
            break;
          case "DELETE_ARM_SCHEDULE_V2":
            jobDetails.group = "DELETE_XT_SCHEDULE";
            jobDetails.job = "DELETE_ARM_SCHEDULE_V2";
            jobDetails.command = "delete";
            break;
          case "ADD_OUT_SCHEDULE_V2":
            jobDetails.group = "ADD_XT_SCHEDULE";
            jobDetails.job = "ADD_OUT_SCHEDULE_V2";
            jobDetails.command = "create";
            break;
          case "UPDATE_OUT_SCHEDULE_V2":
            jobDetails.group = "UPDATE_XT_SCHEDULE";
            jobDetails.job = "UPDATE_OUT_SCHEDULE_V2";
            jobDetails.command = "update";
            break;
          case "DELETE_OUT_SCHEDULE_V2":
            jobDetails.group = "DELETE_XT_SCHEDULE";
            jobDetails.job = "DELETE_OUT_SCHEDULE_V2";
            jobDetails.command = "delete";
            break;
          case "ADD_FAV_SCHEDULE_V2":
            jobDetails.group = "ADD_XT_SCHEDULE";
            jobDetails.job = "ADD_FAV_SCHEDULE_V2";
            jobDetails.command = "create";
            break;
          case "UPDATE_FAV_SCHEDULE_V2":
            jobDetails.group = "UPDATE_XT_SCHEDULE";
            jobDetails.job = "UPDATE_FAV_SCHEDULE_V2";
            jobDetails.command = "update";
            break;
          case "DELETE_FAV_SCHEDULE_V2":
            jobDetails.group = "DELETE_XT_SCHEDULE";
            jobDetails.job = "DELETE_FAV_SCHEDULE_V2";
            jobDetails.command = "delete";
            break;
          case "ADD_XR_USER_V2":
            jobDetails.group = "ADD_XR_USER";
            jobDetails.job = "ADD_XR_USER_V2";
            jobDetails.command = "create";
            break;
          case "UPDATE_XR_USER_V2":
            jobDetails.group = "UPDATE_XR_USER";
            jobDetails.job = "UPDATE_XR_USER_V2";
            jobDetails.command = "update";
            break;
          case "DELETE_XR_USER_V2":
            jobDetails.group = "DELETE_XR_USER";
            jobDetails.job = "DELETE_XR_USER_V2";
            jobDetails.command = "delete";
            break;
          case "ADD_XT_USER_V2":
            jobDetails.group = "ADD_XT_USER";
            jobDetails.job = "ADD_XT_USER_V2";
            jobDetails.command = "create";
            break;
          case "UPDATE_XT_USER_V2":
            jobDetails.group = "UPDATE_XT_USER";
            jobDetails.job = "UPDATE_XT_USER_V2";
            jobDetails.command = "update";
            break;
          case "DELETE_XT_USER_V2":
            jobDetails.group = "DELETE_XT_USER";
            jobDetails.job = "DELETE_XT_USER_V2";
            jobDetails.command = "delete";
            break;
        }

        return jobDetails;
      },

      /**
       * Get the panels for the indicated customer and attach properties needed for the multi-panel service
       * @param {int} customerId
       * @param {int} [selectedPanelId] - the panel that is currently selected in the UI
       */
      getPanels: function (customerId, selectedPanelId) {
        var deferred = $q.defer();
        var panels = [];
        var customer = new Customer(customerId);
        // Retrieve controls systems for the given customer
        customer
          .getControlSystems(customerId)
          .then(
            function (data) {
              angular.forEach(data, function (result) {
                var system = result.control_system;
                var panel = new Panel(system.panels[0].id);
                panel.id = system.panels[0].id;
                panel.name = system.name;
                panel.arming_system = system.panels[0].arming_system;
                panel.hardware_family = system.panels[0].hardware_family;
                panel.hardware_model = system.panels[0].hardware_model;
                panel.software_version = system.panels[0].software_version;
                if (selectedPanelId) {
                  panel.selected = panel.panel_id === +selectedPanelId;
                  panel.templateSystem = panel.panel_id === +selectedPanelId;
                }
                panel.online = system.panels[0].online;
                delete panel.PDS;
                delete panel.pristine;
                if (panel.hardware_family != "Video Only") panels.push(panel);
              });
              deferred.resolve(panels);
            },
            function (error) {
              console.error(
                "MultiPanelService-getPanels() - Error: " +
                  angular.toJson(error)
              );
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * Get the panels for the indicated customer and attach properties needed for the multi-panel service Using oData
       * @param {int} customerId
       * @param {int} [selectedPanelId] - the panel that is currently selected in the UI
       */
      getPanelsWithOdata: function (
        customerId,
        selectedPanelId,
        profiles = true
      ) {
        var deferred = $q.defer();
        var panels = [];

        ControlSystemODataAPI.getPanelsForMultiPanelUser(
          {
            customer_id: customerId,
            $select: "id,name,panels",
            $expand: `panels($select=id,arming_system,hardware_model,software_version,online)`,
          }, //params
          function (data) {
            //success
            angular.forEach(data.value, function (system) {
              var panel = new Panel(system.panels[0].id);
              panel.id = system.panels[0].id;
              panel.name = system.name;
              panel.arming_system = system.panels[0].arming_system;
              panel.hardware_family = getHardwareFamilyFromHardwareModel(
                system.panels[0].hardware_model
              );
              panel.hardware_model = system.panels[0].hardware_model;
              panel.software_version = system.panels[0].software_version;
              if (selectedPanelId) {
                panel.selected = panel.panel_id === +selectedPanelId;
                panel.templateSystem = panel.panel_id === +selectedPanelId;
              }
              panel.online = system.panels[0].online;
              delete panel.PDS;
              delete panel.pristine;
              if (panel.hardware_family != "Video Only") panels.push(panel);
            });
            deferred.resolve(panels);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
