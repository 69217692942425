/**
 * @generated SignedSource<<df95d29af7ba2062b1d845dc20a51efb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly " $fragmentSpreads": FragmentRefs<"XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts" | "XTBellOptionsTemplateDataInline_xtProgrammingTemplateConcepts" | "XTCommunicationTemplateDataInline_xtProgrammingTemplateConcepts" | "XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts" | "XTKeyfobsTemplateDataInline_xtProgrammingTemplateConcepts" | "XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts" | "XTNetworkOptionsTemplateDataInline_xtProgrammingTemplateConcepts" | "XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts" | "XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts" | "XTRemoteOptionsTemplateDataInline_xtProgrammingTemplateConcepts" | "XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts" | "XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts" | "XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts" | "XTZoneInformationTemplateDataInline_xtProgrammingTemplateConcepts">;
  readonly " $fragmentType": "XTTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "7c621deb713bb738fdf2b0466b0e4d4e";

export default node;
