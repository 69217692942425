import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  Area,
  asID,
  fromZoneId,
  idAsString,
  ProgrammingTemplate,
  SystemType,
  toAreaId,
  Zone,
  ZoneType,
} from "securecom-graphql/client";
import { useAreaList } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemType } from "../SystemOptionsFields/SystemOptionsContext";
import { useTemplateContext } from "../TemplateContext";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationFollowAreaField_zone$key } from "./__generated__/ZoneInformationFollowAreaField_zone.graphql";

export const zoneInformationFollowAreaFieldId = (number: string) =>
  `zone-information-follow-area-${number}`;

function ZoneInformationFollowAreaField() {
  const [{ number, followArea, type, id }] =
    useZoneInformationFragment<ZoneInformationFollowAreaField_zone$key>(
      graphql`
        fragment ZoneInformationFollowAreaField_zone on Zone {
          id
          number
          type
          followArea {
            id
            number
            name
          }
        }
      `
    );

  const fieldId = zoneInformationFollowAreaFieldId(String(number));
  const systemType = useSystemType();
  const disabled =
    ![
      ZoneType.NIGHT,
      ZoneType.DAY,
      ZoneType.AUXILIARY_1,
      ZoneType.AUXILIARY_2,
    ].includes(type as ZoneType) || systemType !== SystemType.AREA;
  const areaList = useAreaList();
  const relayEnv = useRelayEnvironment();
  const { templateId } = useTemplateContext();
  const onChangeClicked = React.useRef(false);

  React.useEffect(() => {
    let areaNumber = followArea?.number ?? "NONE";
    if (templateId && !onChangeClicked.current) {
      relayEnv.commitUpdate((store) => {
        const template = store.get<ProgrammingTemplate>(templateId);
        areaNumber = String(
          template
            ?.getLinkedRecord("concepts")
            ?.getLinkedRecords("zoneInformations")
            .find((zone) => Number(zone.getValue("number")) === Number(number))
            ?.getLinkedRecord("followArea")
            ?.getValue("data") ?? ""
        );
      });
    }
    const selectedId = areaList.find(
      (areaInList) => Number(areaInList.number) === Number(areaNumber)
    );
    relayEnv.commitUpdate((store) => {
      const zone = store.get<Zone>(id);
      const area = store.get<Area>(selectedId?.id ?? "");
      if (zone && area) {
        zone.setLinkedRecord(area, "followArea");
      } else if (!area && zone) {
        let unselectedArea = store.get<Area>(
          idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0"))
        );
        if (!unselectedArea) {
          unselectedArea = store.create(
            idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0")),
            "Area"
          ) as RecordProxy<Area>;
        }

        if (unselectedArea) {
          unselectedArea.setValue("0", "number");
          zone.setLinkedRecord(unselectedArea, "followArea");
        }
      }
    });
  }, [followArea, areaList, number, relayEnv, templateId, id]);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Follow Area"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={
          followArea?.number === "0" || followArea === null
            ? "NONE"
            : followArea?.number
        }
        disabled={disabled}
        onChange={({ target }) => {
          onChangeClicked.current = true;
          const selectedId = areaList.find(
            (areaInList) => areaInList.number === target.value
          );
          relayEnv.commitUpdate((store) => {
            const zone = store.get<Zone>(id);
            const area = store.get<Area>(selectedId?.id ?? "");
            if (area && zone) {
              zone.setLinkedRecord(area, "followArea");
            } else if (!area && zone) {
              let unselectedArea = store.get<Area>(
                idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0"))
              );
              if (!unselectedArea) {
                unselectedArea = store.create(
                  idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0")),
                  "Area"
                ) as RecordProxy<Area>;
              }

              if (unselectedArea) {
                unselectedArea.setValue("0", "number");
                zone.setLinkedRecord(unselectedArea, "followArea");
              }
            }
          });
        }}
      >
        <Select.Option value={"NONE"} id={"NONE"}>
          -
        </Select.Option>
        {areaList.map((area) => (
          <Select.Option key={area.id} value={area.number}>
            {area.name}
          </Select.Option>
        ))}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationFollowAreaField;
