/**
 * @generated SignedSource<<2959fcd64183e616f5f21965e1b9f76d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GroupsListDeleteGroupMutation$variables = {
  groupNumber: string;
  systemId: string;
};
export type GroupsListDeleteGroupMutation$data = {
  readonly deleteGroup: {
    readonly __typename: "DeleteGroupErrorResponse";
    readonly message: string | null;
  } | {
    readonly __typename: "DeleteGroupSuccessResponse";
    readonly system: {
      readonly groupsConnection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string | null;
            readonly number: string | null;
          };
        }>;
      };
      readonly id: string;
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type GroupsListDeleteGroupMutation = {
  response: GroupsListDeleteGroupMutation$data;
  variables: GroupsListDeleteGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupNumber"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "profileNumber",
        "variableName": "groupNumber"
      },
      {
        "kind": "Variable",
        "name": "systemId",
        "variableName": "systemId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "deleteGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ControlSystem",
            "kind": "LinkedField",
            "name": "system",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GroupsConnection",
                "kind": "LinkedField",
                "name": "groupsConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Group",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "number",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "DeleteGroupSuccessResponse",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "DeleteGroupErrorResponse",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupsListDeleteGroupMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GroupsListDeleteGroupMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7a34ecf78010bdcd57a288530d74eb27",
    "id": null,
    "metadata": {},
    "name": "GroupsListDeleteGroupMutation",
    "operationKind": "mutation",
    "text": "mutation GroupsListDeleteGroupMutation(\n  $systemId: ID!\n  $groupNumber: String!\n) {\n  deleteGroup(systemId: $systemId, profileNumber: $groupNumber) {\n    __typename\n    ... on DeleteGroupSuccessResponse {\n      system {\n        id\n        groupsConnection {\n          edges {\n            node {\n              id\n              number\n              name\n            }\n          }\n        }\n      }\n    }\n    ... on DeleteGroupErrorResponse {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "36da7f66dbe6e431a3e44d61abb9cdd7";

export default node;
