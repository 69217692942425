/**
 * @generated SignedSource<<fb24dabd0305111aa90bf766273fcfe7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTDeviceSetupProgrammingConceptForm_controlSystem$data = {
  readonly copiedDeviceInformation: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly axBusDoorDevicesMax: number;
    readonly deviceInformations: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly name: string;
      readonly number: number;
      readonly wirelessTranslator1100t?: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"XTDeviceInformationsFields_deviceInformation">;
    }>;
    readonly deviceNumberRange: ReadonlyArray<number>;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly keypadBusDevicesMax: number;
    readonly newDevice: {
      readonly id: string;
      readonly isNew: boolean;
      readonly name: string;
      readonly number: number;
      readonly wirelessTranslator1100t?: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"XTDeviceInformationsFields_deviceInformation">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly totalDevicesMax: number;
    readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationNumberField_panel" | "DeviceInformationSerialNumberField_deviceSerialNumberList_panel" | "PanelContextUseDeviceNumberRange_panel" | "PanelContextUseHardwareModel_panel" | "PanelContextUseHas1100T_panel" | "PanelContextUseRemoteZoneList_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContextUseSupports1100T_panel" | "PanelContextUseSupportsXR550_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XTDeviceSetupProgrammingConceptForm_controlSystem";
};
export type XTDeviceSetupProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XTDeviceSetupProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTDeviceSetupProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isNew",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "XTDeviceInformationsFields_deviceInformation"
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "wirelessTranslator1100t",
        "storageKey": null
      }
    ],
    "type": "XtDeviceInformation",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTDeviceSetupProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalDevicesMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keypadBusDevicesMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "axBusDoorDevicesMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceNumberRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHas1100T_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeviceInformationNumberField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseRemoteZoneList_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "deviceInformations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "newDevice",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseDeviceNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupports1100T_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsXR550_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeviceInformationSerialNumberField_deviceSerialNumberList_panel"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "copiedDeviceInformation",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "ab82ad720f4a9bc77cf22d3fc16e429c";

export default node;
