import DaColors from "app/common/DaColors";
import {
  EventSource,
  SecurityEvent,
} from "dealer-admin/src/apis/odata/security-events-parser";
import React, { Fragment, useState } from "react";
import styled from "styled-components/macro";
import EventThumbnail, { ThumbnailSize } from "./EventThumbnail";
import ToggleButton from "./ToggleButton";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  height: 7.2rem;
  width: 100%;
`;
const FilterWrapperOuter = styled.div`
  position: absolute;
  left: 0.8rem;
`;
const FilterWrapperInner = styled.div`
  position: relative;
  width: max-content;
`;
const AlarmCountBadge = styled.div`
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  border-radius: 0.4rem;
  background-color: ${DaColors.Info1000};
  padding: 0 0.4rem;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${DaColors.White};
`;
const ThumbnailsWrapperOuter = styled.div`
  display: flex;
  justify-content: center;
  width: 75%;
`;
const ThumbnailsWrapperInner = styled.div`
  display: flex;
  align-items: start;
  overflow-x: auto;
  padding: 0.6rem 0;
`;
const ThumbnailWrapperOuter = styled.div<{ isSelected: boolean }>`
  width: 9.6rem;
  flex: none;
  border-radius: 0.4rem;
  border: 0.4rem solid
    ${(props) => (props.isSelected ? DaColors.Info500 : "transparent")};
`;
const ThumbnailWrapperInner = styled.div`
  position: relative;
`;
const HoverOverlay = styled.div`
  position: absolute;
  inset: 0px;
  &:hover {
    background-color: hsla(194, 64%, 56%, 0.2);
  }
`;

type Props = {
  events: SecurityEvent[];
  eventSource: EventSource;
  selectedEventId: number | null | undefined;
  onSelectEvent: (selectedEvent: SecurityEvent) => void;
};

const EventThumbnails = ({
  events,
  eventSource,
  selectedEventId,
  onSelectEvent,
}: Props) => {
  const videoEvents = events.filter((e) => e.videoUrl);
  const alarmEvents: SecurityEvent[] = videoEvents.filter((e) => e.isAlarm);
  const [isFilterToggledOn, setIsFilterToggledOn] = useState<boolean>(false);
  const displayedEvents = isFilterToggledOn ? alarmEvents : videoEvents;

  return (
    <Wrapper>
      <FilterWrapperOuter>
        <FilterWrapperInner>
          <ToggleButton
            offLabel={`All ${eventSource}`}
            onLabel="Alarms"
            isOn={isFilterToggledOn}
            onToggle={setIsFilterToggledOn}
          ></ToggleButton>
          <AlarmCountBadge>{alarmEvents.length.toString()}</AlarmCountBadge>
        </FilterWrapperInner>
      </FilterWrapperOuter>
      <ThumbnailsWrapperOuter>
        <ThumbnailsWrapperInner>
          {displayedEvents.map((event) => {
            if (event.videoUrl) {
              return (
                <Fragment key={event.id}>
                  <ThumbnailWrapperOuter
                    isSelected={event.id === selectedEventId}
                    onClick={() => onSelectEvent(event)}
                  >
                    <ThumbnailWrapperInner>
                      <EventThumbnail
                        event={event}
                        size={ThumbnailSize.Small}
                      />
                      <HoverOverlay />
                    </ThumbnailWrapperInner>
                  </ThumbnailWrapperOuter>
                </Fragment>
              );
            }
          })}
        </ThumbnailsWrapperInner>
      </ThumbnailsWrapperOuter>
    </Wrapper>
  );
};

export default EventThumbnails;
