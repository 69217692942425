/**
 * Component: AddCustomerSummarySystemsTableComponentRoot
 *
 * Description:
 * The `AddCustomerSummarySystemsTableComponentRoot` serves as a React wrapper component to integrate
 * the `CustomerSummarySystemsTableComponent` with an AngularJS application. It provides the necessary
 * environment and props to the React component and handles its integration using `react2angular`.
 *
 * Props:
 * - The component accepts a variety of props including `UserService`, application state (`$state`, `$stateParams`),
 *   `RelayService`, and several action handlers (`moveSystem`, `serviceRequestModal`, `tempUserModal`,
 *   `authorizeTempAppUser`, `openDeleteSystemModal`). It also receives `deletedSystemId` to track the deletion
 *   of systems.
 *
 * Behavior:
 * - The component sets up a Relay environment using `RelayService` for data fetching in the React component.
 * - It utilizes `React.Suspense` to handle the loading state while waiting for data.
 * - The component renders the `CustomerSummarySystemsTableComponent`, passing all necessary props.
 * - It includes a console log for debugging purposes, tracking the `deletedSystemId`.
 * - This component acts as a bridge between the React and AngularJS parts of the application, ensuring smooth data
 *   flow and component interaction.
 *
 * Usage:
 * - This wrapper component is specifically used for integrating the React-based `CustomerSummarySystemsTableComponent`
 *   within an AngularJS application, adhering to the hybrid architecture of the application.
 * - It is registered in the AngularJS application using the `react2angular` library for seamless integration.
 */

import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { react2angular } from "react2angular";
import CustomerSummarySystemsTableComponent, {
  MovedSystemInfo,
} from "./CustomerSummarySystemTableComponent";

interface AddCustomerSummarySystemsTableComponentRootProps {
  UserService: any;
  PROPS: any;
  $state: any;
  $stateParams: any;
  RelayService: any;
  TEMP_DEALER_APP_USER: any;
  INSTALL_GUIDE: any;
  PROGRAMMING_GUIDE: any;
  moveSystem: any;
  serviceRequestModal: any;
  tempUserModal: any;
  authorizeTempAppUser: any;
  openDeleteSystemModal: any;
  openMoveSystemModal: any;
  openHandleBillableSystemModal: any;
  billingControlSystemId: number;
  mobileWalletCredsEnabled: boolean;
  deletedSystemId: string;
  movedSystemInfo: MovedSystemInfo;
}

const AddCustomerSummarySystemsTableComponentRoot = ({
  RelayService,
  $state,
  $stateParams,
  UserService,
  TEMP_DEALER_APP_USER,
  INSTALL_GUIDE,
  PROGRAMMING_GUIDE,
  moveSystem,
  serviceRequestModal,
  tempUserModal,
  authorizeTempAppUser,
  openDeleteSystemModal,
  openMoveSystemModal,
  openHandleBillableSystemModal,
  deletedSystemId,
  movedSystemInfo,
  billingControlSystemId,
  mobileWalletCredsEnabled,
}: AddCustomerSummarySystemsTableComponentRootProps) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <CustomerSummarySystemsTableComponent
          stateParams={$stateParams}
          state={$state}
          UserService={UserService}
          TEMP_DEALER_APP_USER={TEMP_DEALER_APP_USER}
          INSTALL_GUIDE={INSTALL_GUIDE}
          PROGRAMMING_GUIDE={PROGRAMMING_GUIDE}
          moveSystem={moveSystem}
          serviceRequestModal={serviceRequestModal}
          tempUserModal={tempUserModal}
          authorizeTempAppUser={authorizeTempAppUser}
          openDeleteSystemModal={openDeleteSystemModal}
          openMoveSystemModal={openMoveSystemModal}
          openHandleBillableSystemModal={openHandleBillableSystemModal}
          deletedSystemId={deletedSystemId}
          movedSystemInfo={movedSystemInfo}
          billingControlSystemId={billingControlSystemId}
          mobileWalletCredsEnabled={mobileWalletCredsEnabled}
        />
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
};

export function dangerouslyAddToApp() {
  App.component(
    "addCustomerSummarySystemsTableComponent",
    react2angular(
      AddCustomerSummarySystemsTableComponentRoot,
      [
        "moveSystem",
        "serviceRequestModal",
        "tempUserModal",
        "authorizeTempAppUser",
        "openDeleteSystemModal",
        "openMoveSystemModal",
        "openHandleBillableSystemModal",
        "billingControlSystemId",
        "deletedSystemId",
        "movedSystemInfo",
        "mobileWalletCredsEnabled",
      ],
      [
        "$state",
        "UserService",
        "$stateParams",
        "RelayService",
        "TEMP_DEALER_APP_USER",
        "INSTALL_GUIDE",
        "PROGRAMMING_GUIDE",
        "DashboardDataService",
        "$rootScope",
      ]
    )
  );
}
