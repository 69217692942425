/**
 * @generated SignedSource<<b7250f310a5d89fa8733a311eb11649c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreIpv6AddressField_remoteOptions$data = {
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly entreIpv6?: boolean | null;
  readonly entreIpv6Address?: string | null;
  readonly " $fragmentType": "RemoteOptionsEntreIpv6AddressField_remoteOptions";
};
export type RemoteOptionsEntreIpv6AddressField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreIpv6AddressField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreIpv6AddressField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreIpv6AddressField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreIpv6Address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreConnection",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreIpv6",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "2bfe2af4ea7a1c97851cbe31e480dfb3";

export default node;
