import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, StatusListDisplay } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import StatusListDisplayAuxiliary1ZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplayAuxiliary1ZoneKeypadsField";
import StatusListDisplayAuxiliary2ZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplayAuxiliary2ZoneKeypadsField";
import StatusListDisplayBurglaryZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplayBurglaryZoneKeypadsField";
import StatusListDisplayCarbonMonoxideZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplayCarbonMonoxideZoneKeypadsField";
import StatusListDisplayCommPathTroubleField from "../common/StatusListDisplayFields/StatusListDisplayCommPathTroubleField";
import { StatusListDisplayContextProvider } from "../common/StatusListDisplayFields/StatusListDisplayContext";
import StatusListDisplayEmergencyZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplayEmergencyZoneKeypadsField";
import StatusListDisplayFireZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplayFireZoneKeypadsField";
import StatusListDisplayPanicZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplayPanicZoneKeypadsField";
import StatusListDisplaySupervisoryZoneKeypadsField from "../common/StatusListDisplayFields/StatusListDisplaySupervisoryZoneKeypadsField";
import StatusListDisplaySystemTroubleStatusMonitorsField from "../common/StatusListDisplayFields/StatusListDisplaySystemTroubleStatusMonitorsField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  XRStatusListDisplayProgrammingConceptFormInline_controlSystem$data,
  XRStatusListDisplayProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XRStatusListDisplayProgrammingConceptFormInline_controlSystem.graphql";
import { XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XRStatusListDisplayProgrammingConceptFormStatusListDisplayRefreshMutation,
} from "./__generated__/XRStatusListDisplayProgrammingConceptFormStatusListDisplayRefreshMutation.graphql";
import {
  XRStatusListDisplayProgrammingConceptFormStatusListDisplaySendMutation,
  XRStatusListDisplayProgrammingConceptFormStatusListDisplaySendMutation$data,
} from "./__generated__/XRStatusListDisplayProgrammingConceptFormStatusListDisplaySendMutation.graphql";
import { XRStatusListDisplayProgrammingConceptForm_controlSystem$key } from "./__generated__/XRStatusListDisplayProgrammingConceptForm_controlSystem.graphql";

export const title = "Status List Display";
export const conceptId = "xr-status-list-display";

export const getState = (
  controlSystem: XRStatusListDisplayProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRStatusListDisplayProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          statusListDisplay {
            id
            systemTroubleStatusMonitors
            fireZoneKeypads
            burglaryZoneKeypads
            supervisoryZoneKeypads
            panicZoneKeypads
            emergencyZoneKeypads
            auxiliary1ZoneKeypads
            auxiliary2ZoneKeypads
            carbonMonoxideZoneKeypads
            commPathTrouble
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XRStatusListDisplayProgrammingConceptFormStatusListDisplayRefreshMutation(
    $systemId: ID!
  ) {
    refreshStatusListDisplay(systemId: $systemId) {
      ... on RefreshStatusListDisplaySuccessResponse {
        __typename
        controlSystem {
          id
          ...XRStatusListDisplayProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshStatusListDisplayErrorResponse {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XRStatusListDisplayProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshStatusListDisplay, isRefreshing] =
    useMutation<XRStatusListDisplayProgrammingConceptFormStatusListDisplayRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshStatusListDisplay({
          variables: {
            systemId: id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshStatusListDisplay;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Status List Display refreshed successfully",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshStatusListDisplay: {
                    __typename: response.refreshStatusListDisplay.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve ${title}: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve ${title}`,
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XRStatusListDisplayProgrammingConceptFormStatusListDisplaySendMutation(
    $systemId: ID!
    $statusListDisplay: StatusListDisplayInput!
  ) {
    updateStatusListDisplay(
      systemId: $systemId
      statusListDisplay: $statusListDisplay
    ) {
      ... on UpdateStatusListDisplaySuccessResponse {
        __typename
        controlSystem {
          __typename
          id
          ...XRStatusListDisplayProgrammingConceptFormInline_controlSystem
        }
      }
      ... on UpdateStatusListDisplayErrorResponse {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XRStatusListDisplayProgrammingConceptFormStatusListDisplaySendMutation$data,
  originalControlSystemData: XRStatusListDisplayProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.updateStatusListDisplay.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshStatusListDisplay: {
          __typename: "RefreshStatusListDisplaySuccessPayload",
          controlSystem: getState(
            response.updateStatusListDisplay.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XRStatusListDisplayProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendStatusListDisplay, isSending] =
    useMutation<XRStatusListDisplayProgrammingConceptFormStatusListDisplaySendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { statusListDisplay },
        } = getState(props.controlSystem);

        if (statusListDisplay) {
          sendStatusListDisplay({
            variables: {
              systemId,
              statusListDisplay: {
                systemTroubleStatusMonitors:
                  statusListDisplay.systemTroubleStatusMonitors,
                fireZoneKeypads: statusListDisplay.fireZoneKeypads,
                burglaryZoneKeypads: statusListDisplay.burglaryZoneKeypads,
                supervisoryZoneKeypads:
                  statusListDisplay.supervisoryZoneKeypads,
                panicZoneKeypads: statusListDisplay.panicZoneKeypads,
                emergencyZoneKeypads: statusListDisplay.emergencyZoneKeypads,
                auxiliary1ZoneKeypads: statusListDisplay.auxiliary1ZoneKeypads,
                auxiliary2ZoneKeypads: statusListDisplay.auxiliary2ZoneKeypads,
                carbonMonoxideZoneKeypads:
                  statusListDisplay.carbonMonoxideZoneKeypads,
                commPathTrouble: statusListDisplay.commPathTrouble,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.updateStatusListDisplay.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.updateStatusListDisplay.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.updateStatusListDisplay.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        } else {
          showAlert({
            type: "error",
            text: "Unable to send Status List Display Programming",
          });
          reject();
        }
      }),
    isSending,
  ];
};

const readStatusListDisplayTemplateData = (
  programmingTemplateConcepts: XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        statusListDisplay {
          included
          systemTroubleStatusMonitors {
            included
            data
          }
          fireZoneKeypads {
            included
            data
          }
          burglaryZoneKeypads {
            included
            data
          }
          supervisoryZoneKeypads {
            included
            data
          }
          panicZoneKeypads {
            included
            data
          }
          emergencyZoneKeypads {
            included
            data
          }
          auxiliary1ZoneKeypads {
            included
            data
          }
          auxiliary2ZoneKeypads {
            included
            data
          }
          carbonMonoxideZoneKeypads {
            included
            data
          }
          commPathTrouble {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).statusListDisplay ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readStatusListDisplayTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const statusListDisplayRecordProxy =
      panelRecordProxy.getOrCreateLinkedRecord(
        "statusListDisplay",
        "StatusListDisplay"
      ) as unknown as RecordProxy<StatusListDisplay>;

    applyTemplateScalarDataToRecordProxy(
      statusListDisplayRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRStatusListDisplayProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRStatusListDisplayProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            statusListDisplay {
              id
              supportsCarbonMonoxideZoneKeypads
              ...StatusListDisplayContext_statusListDisplay
              ...StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay
              ...StatusListDisplayFireZoneKeypadsField_statusListDisplay
              ...StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay
              ...StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay
              ...StatusListDisplayPanicZoneKeypadsField_statusListDisplay
              ...StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay
              ...StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay
              ...StatusListDisplayAuxiliary2ZoneKeypadsField_statusListDisplay
              ...StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay
              ...StatusListDisplayCommPathTroubleField_statusListDisplay
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
          }
        }
      `
    );
  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <StatusListDisplayContextProvider
        statusListDisplay={controlSystem.panel.statusListDisplay}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Status%20List`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.statusListDisplay
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <StatusListDisplaySystemTroubleStatusMonitorsField />
              <StatusListDisplayFireZoneKeypadsField />
              <StatusListDisplayBurglaryZoneKeypadsField />
              <StatusListDisplaySupervisoryZoneKeypadsField />
              <StatusListDisplayPanicZoneKeypadsField />
              <StatusListDisplayEmergencyZoneKeypadsField />
              <StatusListDisplayAuxiliary1ZoneKeypadsField />
              <StatusListDisplayAuxiliary2ZoneKeypadsField />
              {controlSystem.panel.statusListDisplay
                ?.supportsCarbonMonoxideZoneKeypads && (
                <StatusListDisplayCarbonMonoxideZoneKeypadsField />
              )}
              <StatusListDisplayCommPathTroubleField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </StatusListDisplayContextProvider>
    </PanelContextProvider>
  );
}
