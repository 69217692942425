import noop from "common/utils/universal/noop";
import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { useConceptIsActive } from "./ActiveConceptContext";
import { useProgrammingConceptIdContext } from "./ProgrammingConceptIdContext";

const SelectedListItemContext = createContext<
  [
    string | null,
    Dispatch<SetStateAction<string | null>> | Dispatch<SetStateAction<string>>
  ]
>([null, noop]);

export default SelectedListItemContext;

export const useSelectedListItem = () => useContext(SelectedListItemContext)[0];

export const useSetSelectedListItem = () =>
  useContext(SelectedListItemContext)[1];

export const useListItemIsSelected = (
  listItemId: string | null | undefined
) => {
  const isActiveProgrammingConcept = useConceptIsActive(
    useProgrammingConceptIdContext()
  );
  const selectedListItem = useSelectedListItem();

  return listItemId
    ? selectedListItem === listItemId && isActiveProgrammingConcept
    : isActiveProgrammingConcept;
};
