import graphql from "babel-plugin-relay/macro";

export const xfTemplateSaveMutation = graphql`
  mutation XFTemplateSaveMutation(
    $dealerId: ID!
    $templateData: XfProgrammingTemplateInput!
  ) {
    saveXfProgrammingTemplate(
      dealerId: $dealerId
      templateData: $templateData
    ) {
      ... on SaveProgrammingTemplateErrorResult {
        error
        messages
      }
      ... on SaveProgrammingTemplateSuccessResult {
        programmingTemplate {
          concepts {
            ...XFTemplateDataInline_xfProgrammingTemplateConcepts
          }
        }
      }
      __typename
    }
  }
`;
