/**
 * @generated SignedSource<<7f713f463af5468695533c313837dbd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly bellOptions: {
    readonly automaticBellTest: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary1BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary2BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly bellCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly bellOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly emergencyBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly fireBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly panicBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "d5e292be9a64b955dea6db89d99386e4";

export default node;
