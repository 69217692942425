App.controller("DirectivesCtrl", DirectivesCtrl);
DirectivesCtrl.$inject = ["$rootScope", "$scope", "StyleDataAccessService"];
function DirectivesCtrl($rootScope, $scope, StyleDataAccessService) {
  init();

  function init() {
    StyleDataAccessService.getStyleData("directive-data.json")
      .then(
        function (directiveData) {
          $scope.DirectiveData = directiveData;
          Prism.highlightAll();
        },
        function (formError) {
          $rootScope.alerts.push({ type: "error", message: formError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });
  }
}
