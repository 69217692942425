/**
 *
 * Icons for Favorites
 * @author Chad Watson
 *
 *
 */
import { compose, defaultTo, prop, __ } from "ramda";
import FAVORITE from "../Icons/FavoriteIcon";
import THERMOSTAT from "../Icons/TemperatureIcon";
import LIGHT from "../Icons/LightIcon";
import LOCK from "../Icons/LockLockedIcon";
import COOL from "../Icons/ThermCoolIcon";
import HEAT from "../Icons/ThermHeatIcon";
import OUTPUTS from "../Icons/OutletIcon";
import ENERGY_SAVING from "../Icons/LeafIcon";
import ARM_ALL from "../Icons/AllIcon";
import ARM_PERIMETER from "../Icons/PerimeterIcon";
import HOME from "../Icons/HomeIcon";
import SLEEP from "../Icons/SleepIcon";
import AWAY from "../Icons/AwayIcon";
import ROOMS from "../Icons/RoomsIcon";
import KIDS from "../Icons/ChildIcon";
import DINNER from "../Icons/DinnerIcon";
import DAYTIME from "../Icons/SunnyIcon";
import DUSK from "../Icons/SunriseSunsetIcon";
import NIGHT from "../Icons/NightIcon";
import MUSIC from "../Icons/MusicIcon";
import DATE from "../Icons/DateIcon";
import MOVIE from "../Icons/MovieNightIcon";
import EXIT from "../Icons/ExitIcon";
import OFFICE from "../Icons/DeskIcon";
import TRAVEL from "../Icons/TravelIcon";
import GAME from "../Icons/GameIcon";
import EXTERIOR_LIGHT from "../Icons/ExteriorLightIcon";
import GARAGE from "../Icons/GarageIcon";
const favorites = {
  FAVORITE,
  THERMOSTAT,
  LIGHT,
  LOCK,
  COOL,
  HEAT,
  OUTPUTS,
  ENERGY_SAVING,
  ARM_ALL,
  ARM_PERIMETER,
  HOME,
  SLEEP,
  AWAY,
  ROOMS,
  KIDS,
  DINNER,
  DAYTIME,
  DUSK,
  NIGHT,
  MUSIC,
  DATE,
  MOVIE,
  EXIT,
  OFFICE,
  TRAVEL,
  GAME,
  EXTERIOR_LIGHT,
  GARAGE,
};
export default favorites;
export const getFavoriteIcon = compose(
  defaultTo(FAVORITE),
  prop(__, favorites)
);
