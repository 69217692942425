import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { isAXBusRange } from "./utils";
import { DeviceInformationAutoForceArmField_deviceInformation$key } from "./__generated__/DeviceInformationAutoForceArmField_deviceInformation.graphql";

export const deviceInformationAutoForceArmFieldId = (number: string) =>
  `device-information-auto-force-arm-${number}`;

function DeviceInformationAutoForceArmField() {
  const [{ number, autoForceArm, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationAutoForceArmField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationAutoForceArmField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            autoForceArm
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationAutoForceArmFieldId(String(number));
  const label = "Auto Force Arm";
  const disabled =
    isAXBusRange(number) ||
    deviceType === DeviceInformationType.ZONE_EXPANDER ||
    deviceType === DeviceInformationType._1100T ||
    deviceType === DeviceInformationType.VPLEX ||
    deviceType === DeviceInformationType.CAMERA ||
    deviceType === DeviceInformationType.XR550;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Arming applies to all Display Areas without requiring Areas to be selected. Faulted zones are Force Armed."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        disabled={disabled}
        label={label}
        name={fieldId}
        checked={autoForceArm}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!autoForceArm, "autoForceArm");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationAutoForceArmField;
