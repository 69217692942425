/**
 * @generated SignedSource<<bf3d34f75b087437e081ce7ba4ae5cb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeatureKeysSendKeyField_panel$data = {
  readonly serialNumber: string | null;
  readonly " $fragmentType": "FeatureKeysSendKeyField_panel";
};
export type FeatureKeysSendKeyField_panel$key = {
  readonly " $data"?: FeatureKeysSendKeyField_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureKeysSendKeyField_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeatureKeysSendKeyField_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "3b8fe1aead1d9d4d35b3b987345050bf";

export default node;
