import { useRef, useState } from "react";

/**
 * Allows basic "play pause" backend control
 *
 * @param isPlayingWhenLoaded should the videos be playing when they are loaded?
 * @returns
 */
const useTogglePlay = (
  isPlayingWhenLoaded = false
): [boolean, React.RefObject<HTMLVideoElement>, () => void] => {
  const [isPlaying, setIsPlaying] = useState(isPlayingWhenLoaded);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = (): void => {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  return [isPlaying, videoRef, togglePlay];
};

export default useTogglePlay;
