/**
 * @generated SignedSource<<5b36a774acea794643b5bf8c0243a684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedJobGroup_query$data = {
  readonly systemDiagnosticsJobListFromGroup: ReadonlyArray<{
    readonly completedAt: string | null;
    readonly createdAt: string | null;
    readonly jobDesc: string | null;
    readonly jobId: number;
    readonly jobMessage: string | null;
    readonly jobStatus: string | null;
    readonly schedulerJobType: string | null;
    readonly updatedAt: string | null;
  }>;
  readonly " $fragmentType": "SelectedJobGroup_query";
};
export type SelectedJobGroup_query$key = {
  readonly " $data"?: SelectedJobGroup_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectedJobGroup_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "groupId"
    },
    {
      "kind": "RootArgument",
      "name": "panelId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./SelectedJobGroupRefreshQuery.graphql')
    }
  },
  "name": "SelectedJobGroup_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "groupId",
          "variableName": "groupId"
        },
        {
          "kind": "Variable",
          "name": "panelId",
          "variableName": "panelId"
        }
      ],
      "concreteType": "SystemDiagnosticsPanelJob",
      "kind": "LinkedField",
      "name": "systemDiagnosticsJobListFromGroup",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobDesc",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schedulerJobType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3a8a5097f363f134572ddab361ff1b9d";

export default node;
