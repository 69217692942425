import graphql from "babel-plugin-relay/macro";
import DaRefreshButton from "common/components/web/DaRefreshButton";
import { Panel, Table, TableFooter } from "components/DaStyledElements";
import { DaButtonDefault } from "components/DaStyledElements/Button";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { GenericPageFallback } from "components/GenericPageFallback";
import {
  AngularStateProvider,
  useStateDotGo,
} from "components/SystemDiagnostics/AngularStateProvider";
import * as React from "react";
import { useMemo, useState } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { idAsString, toGlobalSchedulerJobId } from "securecom-graphql/client";
import styled, { css } from "styled-components/macro";
import { MassProgrammingGlobalHolidayDatesViewQuery } from "./__generated__/MassProgrammingGlobalHolidayDatesViewQuery.graphql";

interface JobInfoType {
  readonly completedAt: string;
  readonly dealerHolidayDate: {
    readonly holidayDate: string | null;
    readonly holidayName: string | null;
  } | null;
  readonly failedToUpdate: number;
  readonly id: string;
  readonly jobData: { readonly operation: string | null } | null;
  readonly jobOutputDetails:
    | readonly ({
        readonly customerName: string | null;
        readonly status: string | null;
        readonly systemType: string | null;
      } | null)[]
    | null;
  readonly personWhoStartedJob: {
    readonly email: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
  } | null;
  readonly scapiId: number;
  readonly successfullyUpdated: number;
  readonly totalSystems: number;
  readonly updateInProgress: number;
  readonly waitingForUpdate: number;
}
interface HolidayInfoType {
  readonly holidayDate: string | null;
  readonly holidayName: string | null;
}

function formatDateAndTime(isoDateString: string): {
  formattedDate: string;
  formattedTime: string;
} {
  if (isoDateString === "") {
    return { formattedDate: "", formattedTime: "" };
  }
  const date = new Date(isoDateString);

  // Format the date as "weekday, month day, year"
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    weekday: "long", // e.g., Monday
    year: "numeric", // e.g., 2023
    month: "long", // e.g., September
    day: "numeric", // e.g., 18
  });
  const formattedDate = dateFormatter.format(date);

  // Format the time as "hour:minute AM/PM"
  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric", // e.g., 1
    minute: "numeric", // e.g., 50
    hour12: true,
  });
  const formattedTime = timeFormatter.format(date);

  return { formattedDate, formattedTime };
}
function formatDateToMMDD(ddmm: string) {
  if (ddmm && ddmm.length === 4) {
    const dd = ddmm.substring(0, 2);
    const mm = ddmm.substring(2, 4);
    return `${mm} / ${dd}`;
  }
  return ddmm;
}

export default function MassProgrammingGlobalHolidayDatesViewRoot({
  RelayService,
  $stateParams,
  $state,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AngularStateProvider state={$state}>
          <MassProgrammingGlobalHolidayDatesView
            dealerId={$stateParams.dealer_id}
            jobId={$stateParams.job_id}
          />
        </AngularStateProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}
function MassProgrammingGlobalHolidayDatesView({
  dealerId,
  jobId,
}: {
  jobId: string;
  dealerId: string;
}) {
  const [fetchKey, updateFetchKey] = useState(jobId ?? "");

  const dealerJobInfo =
    useLazyLoadQuery<MassProgrammingGlobalHolidayDatesViewQuery>(
      graphql`
        query MassProgrammingGlobalHolidayDatesViewQuery($id: ID!) {
          holiday: holidayDateJobQuery(id: $id) {
            ... on SchedulerJob {
              id
              scapiId
              jobData {
                operation
              }
              personWhoStartedJob {
                firstName
                lastName
                email
              }
              jobOutputDetails {
                customerName
                systemType
                status
              }
              dealerHolidayDate {
                holidayName
                holidayDate
              }
              completedAt
              totalSystems
              successfullyUpdated
              updateInProgress
              waitingForUpdate
              failedToUpdate
            }
          }
        }
      `,
      {
        id: idAsString(toGlobalSchedulerJobId(Number(dealerId), Number(jobId))),
      },
      {
        fetchPolicy: "network-only",
        fetchKey,
      }
    );

  const jobInfo = dealerJobInfo.holiday;

  const handleRefetch = () => {
    updateFetchKey(jobId + Date.now());
  };
  const holidayInfo = jobInfo?.dealerHolidayDate;
  const { formattedDate, formattedTime } = formatDateAndTime(
    jobInfo?.completedAt ?? ""
  );
  const holidayDateFormatted = formatDateToMMDD(holidayInfo?.holidayDate ?? "");
  type SortConfig = {
    column: string;
    direction: "asc" | "desc" | "none";
  };
  function isKeyOfDataEntry(key: string): key is keyof DataEntry {
    return ["customerName", "systemType", "status"].includes(key);
  }
  // Adjust the useState call to use the SortConfig type
  const [sortConfig, setSortConfig] = React.useState<SortConfig>({
    column: "customerName",
    direction: "asc",
  });
  type DataEntry = {
    customerName: string;
    systemType: string;
    status: string;
  };
  enum SortKey {
    customerName,
    systemType,
    status,
  }
  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Ascending,
    sortKey: SortKey.customerName,
    pageSize: 10,
  });
  const [data, setData] = useState<DataEntry[]>(
    jobInfo?.jobOutputDetails?.map((detail) => ({
      customerName: String(detail?.customerName ?? ""),
      systemType: detail?.systemType ?? "",
      status: detail?.status ?? "",
    })) || []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortedData = useMemo(
    () => sortData(data, sortConfig),
    [data, sortConfig]
  );
  const paginatedData = useMemo(() => {
    const startIndex =
      (paginationState.currentPage - 1) * paginationState.pageSize;
    return sortedData.slice(startIndex, startIndex + paginationState.pageSize);
  }, [sortedData, paginationState.currentPage, paginationState.pageSize]);

  function sortData(data: DataEntry[], sortConfig: SortConfig) {
    if (
      !sortConfig.column ||
      sortConfig.direction === "none" ||
      !isKeyOfDataEntry(sortConfig.column)
    ) {
      return data; // No sorting applied if no matching column or direction is 'none'
    }

    return [...data].sort((a, b) => {
      const key = sortConfig.column as keyof DataEntry;
      if (a[key] < b[key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const handleSort = (columnName: string) => {
    return () => {
      // Return a function that matches the expected signature
      setSortConfig((currentSortConfig) => {
        if (currentSortConfig.column === columnName) {
          return {
            column: columnName,
            direction: currentSortConfig.direction === "asc" ? "desc" : "asc",
          };
        }
        return { column: columnName, direction: "asc" };
      });
    };
  };

  const nav = useStateDotGo();

  const goBackToGlobalHolidayDates = () => {
    nav(
      "app.dealer.globalHolidayDates",
      { active_tab: "sendGlobalHoliday" },
      { reload: true }
    );
  };

  function setStatusColorClass(status: string): string {
    switch (status) {
      case "Success":
        return "text-success";
      case "Failure":
        return "text-danger";
      default:
        return "";
    }
  }
  const maxPage = Math.ceil(data.length / paginationState.pageSize);

  //  get the reason detail for the job
  const getReasonDetail = (
    holidayInfo: HolidayInfoType | null | undefined,
    jobInfo: JobInfoType | null
  ) => {
    const holidayName = holidayInfo?.holidayName ?? "";
    const operation = jobInfo?.jobData?.operation ?? "";
    return `${holidayName} ${operation}`.trim();
  };

  return (
    <>
      <Header>
        <PageHeader>
          <PageHeaderLeft>
            <PageHeaderTitle>Global Holiday Date ID#{jobId}</PageHeaderTitle>
          </PageHeaderLeft>
          <PageHeaderRight>
            <DaButtonDefault
              className="btn btn-sm btn-default"
              onClick={goBackToGlobalHolidayDates}
            >
              Back
            </DaButtonDefault>
            <DaRefreshButton
              onClick={handleRefetch}
              showIcon={false}
            ></DaRefreshButton>
          </PageHeaderRight>
        </PageHeader>
      </Header>
      <Grid>
        <StyledColumn>
          <SubHeader>
            <SubHeaderTitle>Programming</SubHeaderTitle>
          </SubHeader>
          <Panel.Default>
            {/* <!--REASON--> */}
            <DetailView>
              <JobInfo>
                <DetailGroup>
                  <DetailLabel>Created By</DetailLabel>
                  <DetailData>
                    {jobInfo?.personWhoStartedJob?.firstName ?? ""}{" "}
                    {jobInfo?.personWhoStartedJob?.lastName ?? ""}
                  </DetailData>
                  <DetailDataSubdued>
                    {jobInfo?.personWhoStartedJob?.email ?? ""}
                  </DetailDataSubdued>
                </DetailGroup>
                {/* <!--COMPLETED--> */}
                <DetailGroup>
                  <DetailLabel>Date / Time Complete</DetailLabel>
                  <DetailData>{formattedDate}</DetailData>
                  <DetailData>{formattedTime}</DetailData>
                </DetailGroup>

                {/* <!--REASON--> */}
                <DetailGroup>
                  <DetailLabel>Reason</DetailLabel>
                  <DetailData>
                    {getReasonDetail(holidayInfo, jobInfo)}
                  </DetailData>
                </DetailGroup>
              </JobInfo>

              <SystemInfo>
                {/* <!--NUMBER OF SYSTEMS--> */}
                <DetailGroupNumerical>
                  <DetailLabel>Total Systems</DetailLabel>
                  <DetailDataNumerical>
                    {jobInfo?.totalSystems}
                  </DetailDataNumerical>
                </DetailGroupNumerical>
                {/* <!--NUMBER OF SYSTEMS--> */}
                <DetailGroupNumerical>
                  <DetailLabel>Successfully Updated</DetailLabel>
                  <DeatilDataNumericalSuccess>
                    {jobInfo?.successfullyUpdated}
                  </DeatilDataNumericalSuccess>
                </DetailGroupNumerical>

                {/* <!--SYSTEMS CURRENTLY UPDATING--> */}
                <DetailGroupNumerical>
                  <DetailLabel>Update in Progress</DetailLabel>
                  <DetailDataNumerical>
                    {jobInfo?.updateInProgress}
                  </DetailDataNumerical>
                </DetailGroupNumerical>

                {/* <!--SYSTEMS CURRENTLY PENDING--> */}
                <DetailGroupNumerical>
                  <DetailLabel>Waiting for Update</DetailLabel>
                  <DetailDataNumerical>
                    {jobInfo?.waitingForUpdate}
                  </DetailDataNumerical>
                </DetailGroupNumerical>

                {/* <!--SYSTEMS FAILED--> */}
                <DetailGroupNumerical>
                  <DetailLabel>Failed to Update</DetailLabel>
                  <DeatilDataNumericalFail>
                    {jobInfo?.failedToUpdate}
                  </DeatilDataNumericalFail>
                </DetailGroupNumerical>
              </SystemInfo>
            </DetailView>
            {holidayInfo !== null && (
              <>
                <PrintedConceptHeading>
                  <h4>Holiday Configuration</h4>
                </PrintedConceptHeading>
                {/* TODO: Once we get multiple holiday dates, loop through and add aditional ones for each of these. */}
                <HolidayGroup>
                  <HolidayDataLeft>
                    {holidayInfo?.holidayName ?? ""}
                  </HolidayDataLeft>
                  <HolidayDataRight>{holidayDateFormatted}</HolidayDataRight>
                </HolidayGroup>
              </>
            )}
          </Panel.Default>
        </StyledColumn>
        <StyledColumn>
          <SubHeader>
            <SubHeaderTitle>Systems</SubHeaderTitle>
          </SubHeader>
          <Panel.Table>
            <Table>
              <thead>
                <tr>
                  <SortableTh
                    onClick={handleSort("customerName")}
                    columnName="customerName"
                    isSorted={sortConfig.column === "customerName"}
                    sortDirection={
                      sortConfig.column === "customerName"
                        ? sortConfig.direction
                        : "none"
                    }
                  >
                    Customer
                  </SortableTh>
                  <SortableTh
                    onClick={handleSort("systemType")}
                    columnName="systemType"
                    isSorted={sortConfig.column === "systemType"}
                    sortDirection={
                      sortConfig.column === "systemType"
                        ? sortConfig.direction
                        : "none"
                    }
                  >
                    System
                  </SortableTh>
                  <SortableTh
                    onClick={handleSort("status")}
                    columnName="status"
                    isSorted={sortConfig.column === "status"}
                    sortDirection={
                      sortConfig.column === "status"
                        ? sortConfig.direction
                        : "none"
                    }
                  >
                    Status
                  </SortableTh>
                </tr>
              </thead>
              <tbody>
                {/* add pagination */}
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.customerName}</td>
                      <td>{item.systemType}</td>
                      <td>
                        <span className={setStatusColorClass(item.status)}>
                          {item.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>No Systems Available</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <TableFooter>
              <TableFooter.Left></TableFooter.Left>
              <TableFooter.Center>
                <Paginate.Paginator
                  state={paginationState}
                  dispatch={dispatch}
                  maxPage={maxPage}
                />
              </TableFooter.Center>
              <TableFooter.Right>
                <Paginate.ItemsPerPage
                  state={paginationState}
                  dispatch={dispatch}
                />
              </TableFooter.Right>
            </TableFooter>
          </Panel.Table>
        </StyledColumn>
      </Grid>
    </>
  );
}

const screenSm = "57.6rem";
const globalBorder = "#cccccc";
const fontWeightRegular = "400";
const fontWeightSemiBold = "600";
const borderColor = "#c9c9c9";
const StyledColumn = styled.div`
  padding: 1rem;
  width: 100%;

  @media (max-width: 76.7rem) {
    padding: 0;
  }
`;
const flexDisplayStandard = `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Header = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 76.8rem) {
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 1rem;
`;
const PageHeader = styled.div`
  ${flexDisplayStandard}
  margin-top: 0;
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid #cccccc;
  margin-bottom: 1.6rem;
`;
const PageHeaderLeft = styled.div`
  ${flexDisplayStandard}
  flex: 0 0 1;
`;
const PageHeaderRight = styled.div`
  ${flexDisplayStandard}
  flex: 0 0 1;
  gap: 0.8rem;
`;
const PageHeaderTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.4rem;
  line-height: 1;
  > *:first-child {
    padding-right: 0.8rem;
  }
  @media print {
    padding-left: 2.4rem;
  }
`;
const SubHeader = styled.div`
  font-size: 1.6rem;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem 0;
  @media screen and (min-width: ${screenSm}) {
    flex-flow: row wrap;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  padding-bottom: 1rem;
`;
const SubHeaderTitle = styled.div`
  margin-bottom: 0.8rem;

  @media screen and (min-width: ${screenSm}) {
    margin-bottom: 0;
  }
  &.inside-panel {
    display: flex;
    justify-content: flex-start;
    flex: 0 0 max-content;
    align-items: center;
    color: #333333;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.33333;
    padding: 1.6rem 0;
    margin-bottom: 1.6rem;
    border-bottom: 0.1rem solid rgba(${globalBorder}, 0.5);
  }
`;
const HolidayGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
`;
const HolidayDataLeft = styled.span`
  flex: 1;
`;
const PrintedConceptHeading = styled.div`
  border-bottom: 0.1rem solid ${globalBorder};
  margin-bottom: 1.6rem;

  h4 {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;
const HolidayDataRight = styled.span`
  flex: 2;
  text-align: left;
`;
const DetailGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0.8rem 0;
  margin-bottom: 0.8rem;
`;
const DetailGroupNumerical = styled(DetailGroup)`
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;
const DetailView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "job-info system-info";
  grid-column-gap: 1.6rem;
`;
const JobInfo = styled.div`
  grid-area: job-info;
  border-right: 0.1rem solid ${globalBorder};
`;
const SystemInfo = styled.div`
  grid-area: system-info;
`;
const DetailLabel = styled.div`
  font-weight: ${fontWeightRegular};
  font-size: 1.2rem;
`;
const DetailData = styled.div`
  font-weight: ${fontWeightSemiBold};
  font-size: 1.4rem;
`;
const DetailDataSubdued = styled(DetailData)`
  font-weight: ${fontWeightRegular};
  color: #666666;
`;
const DetailDataNumerical = styled(DetailData)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: ${fontWeightSemiBold};
`;
const DeatilDataNumericalSuccess = styled(DetailDataNumerical)`
  color: #3c763d;
`;
const DeatilDataNumericalFail = styled(DetailDataNumerical)`
  color: #a94442;
`;
interface SortableThProps {
  columnName: string;
  isSorted: boolean;
  sortDirection: "asc" | "desc" | "none";
}
const SortableTh = styled.th<SortableThProps>`
  position: relative;
  cursor: pointer;
  &:hover {
    background: #f8f9fa;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0.6rem;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    visibility: ${({ sortDirection }) =>
      sortDirection === "none"
        ? "visible"
        : "hidden"}; // Initially show both arrows
  }
  &::before {
    top: 1rem;
    border-bottom: 0.7rem solid ${borderColor}; // The bottom border forms the upward-pointing arrow
  }
  &::after {
    top: 2rem;
    border-top: 0.7rem solid ${borderColor}; // The top border forms the downward-pointing arrow
  }
  ${({ sortDirection }) =>
    sortDirection === "asc" &&
    css`
      &::before {
        visibility: visible;
      }
      &::after {
        visibility: hidden;
      }
    `}
  ${({ sortDirection }) =>
    sortDirection === "desc" &&
    css`
      &::before {
        visibility: hidden;
      }
      &::after {
        visibility: visible;
      }
    `}
`;
