/**
 * @generated SignedSource<<6f8cc5c1b892c01f7dd011de7548e83e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts$data = {
  readonly communication: {
    readonly backupCell: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly backupCheckinMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly backupDialer: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly backupFailTimeMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly cellApn1: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cellApn2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cellTestDays: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly checkInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly comType: {
      readonly data: CommunicationComType | null;
      readonly included: boolean | null;
    } | null;
    readonly dialerTestDays: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly failTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly netTrouble: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly networkTestDays: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1AlarmReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1Ip: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1Ip2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1OpenCloseUserReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1Port: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1Port2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1PrimaryPhoneNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1SecondaryPhoneNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1SupervisoryTroubleReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver1TestReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2AlarmReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2Backup: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2Ip: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2Ip2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2OpenCloseUserReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2Port: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2Port2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2PrimaryPhoneNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2SecondaryPhoneNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2SupervisoryTroubleReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly receiver2TestReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly testTime: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly transmissionDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts";
};
export type TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "584ebb91731ce432fe9cc867ca33a67d";

export default node;
