import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { BellActions } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsEmergencyBellActionField_bellOptions$key } from "./__generated__/BellOptionsEmergencyBellActionField_bellOptions.graphql";

export const bellOptionsEmergencyBellActionFieldId = () =>
  "bell-options-emergency-bell-action";

function BellOptionsEmergencyBellActionField() {
  const [{ emergencyBellAction }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsEmergencyBellActionField_bellOptions$key>(
      graphql`
        fragment BellOptionsEmergencyBellActionField_bellOptions on BellOptions {
          emergencyBellAction
        }
      `
    );

  const fieldId = bellOptionsEmergencyBellActionFieldId();
  const softwareVersion = useSoftwareVersion();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Emergency Bell Action"
      tooltip="Set the cadence the Bell Output uses for alarms on Emergency type Zones."
    >
      <Select
        id={fieldId}
        value={emergencyBellAction}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(target.value, "emergencyBellAction");
          });
        }}
      >
        <Select.Option value={BellActions.STEADY}>Steady</Select.Option>
        <Select.Option value={BellActions.PULSE}>Pulse</Select.Option>
        <Select.Option value={BellActions.TEMPORAL}>Temporal</Select.Option>
        <Select.Option value={BellActions.NONE}>None</Select.Option>
        {softwareVersion >= 183 && (
          <>
            <Select.Option value={BellActions.TEMPORAL_3}>
              Temporal 3
            </Select.Option>
            <Select.Option value={BellActions.TEMPORAL_4}>
              Temporal 4
            </Select.Option>
          </>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsEmergencyBellActionField;
