/**
 * @ngdoc service
 * @name App.factory:GeoLocationService
 *
 * @description
 * Customer Referral Program Service
 *
 */
App.factory("GeoLocationService", [
  "GeoLocationAPI",
  "$q",
  "$filter",
  "UserService",
  "$http",
  "PROPS",
  function (GeoLocationAPI, $q, $filter, UserService, $http, PROPS) {
    return {
      requestLatLong: function (body) {
        var deferred = $q.defer();
        GeoLocationAPI.requestLatLong(
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
