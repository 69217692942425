App.controller("SystemsWithArmingAppCtrl", [
  "$scope",
  "$q",
  "$rootScope",
  "UserService",
  "DashboardDataService",
  "DTColumnBuilder",
  "DataTablesUtilsService",
  function (
    $scope,
    $q,
    $rootScope,
    UserService,
    DashboardDataService,
    DTColumnBuilder,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      systemsWithArmingApp()
    );
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption("order", [0, "asc"], [1, "asc"], [2, "asc"]);

    function systemsWithArmingApp() {
      var deferred = $q.defer();
      DashboardDataService.getSystemsWithArmingApp($scope.dealerID)
        .then(
          function (systems) {
            deferred.resolve(systems);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error getting systems",
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }
    $scope.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Name")
        .withOption("aDataSort", [0, 1])
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "customer_name");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row,
            "control_system_name"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Email")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "customer_email");
        }),
      DTColumnBuilder.newColumn("customer_postal_code")
        .withTitle("Postal Code")
        .notVisible(),
      DTColumnBuilder.newColumn("customer_address1")
        .withTitle("Address")
        .notVisible(),
      DTColumnBuilder.newColumn("customer_city").withTitle("City").notVisible(),
      DTColumnBuilder.newColumn("customer_state_province")
        .withTitle("State")
        .notVisible(),
      DTColumnBuilder.newColumn("customer_country")
        .withTitle("Country")
        .notVisible(),
      DTColumnBuilder.newColumn("customer_postal_code")
        .withTitle("Postal Code")
        .notVisible(),
    ];
  },
]);
