import { makeSuspendable } from "common/utils/universal/suspense";
import * as React from "react";
import { IntlProvider } from "react-intl";

// Add supported translations here
export const translations = {
  en: () => import("../../translations/compiled/en.json"),
};

// Do not modify the rest of this file unless you know what you're doing.

const fetchTranslations = (hasIntl) => async (locale) => {
  // Polyfills Intl for browsers that don't support it
  if (!hasIntl) {
    // @ts-ignore
    await import("intl");
  }

  const fetchMessages =
    locale in translations ? translations[locale] : translations.en;
  return await fetchMessages();
};

const { language } = window.navigator;

const locale =
  Object.keys(translations).find((locale) =>
    language.startsWith(locale.toLowerCase())
  ) ?? "en";

const resource = makeSuspendable(
  fetchTranslations(typeof window.Intl !== "undefined")(locale)
);

function LanguageProvider({ children }) {
  return (
    <IntlProvider key={locale} locale={locale} messages={resource.read()}>
      {children}
    </IntlProvider>
  );
}

export default LanguageProvider;
