import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsAppKeyField_remoteOptions$key } from "./__generated__/RemoteOptionsAppKeyField_remoteOptions.graphql";

export const remoteOptionsAppKeyFieldId = () => "remote-options-app-key";

function RemoteOptionsAppKeyField() {
  const [{ appKey }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsAppKeyField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsAppKeyField_remoteOptions on RemoteOptions {
          appKey
        }
      `
    );

  const fieldId = remoteOptionsAppKeyFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="App Key"
      tooltip="Key used to enable EASYconnect."
    >
      <TextInput
        id={fieldId}
        value={appKey || ""}
        pattern="^.*$"
        maxLength={8}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "appKey")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsAppKeyField;
