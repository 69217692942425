import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ZoneMessage, ZoneType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { TwentyFourHourZoneTypes } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationDisarmedShortMessageField_zone$key } from "./__generated__/ZoneInformationDisarmedShortMessageField_zone.graphql";

export const zoneInformationDisarmedShortMessageFieldId = (number: string) =>
  `zone-information-disarmed-short-message-${number}`;

function ZoneInformationDisarmedShortMessageField() {
  const [{ number, disarmedShortActionMessage, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationDisarmedShortMessageField_zone$key>(
      graphql`
        fragment ZoneInformationDisarmedShortMessageField_zone on Zone {
          number
          type
          disarmedShortActionMessage
        }
      `
    );

  const fieldId = zoneInformationDisarmedShortMessageFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { isXr, isXt75 } = resolvePanelType(hardwareModel);
  const disabled = [
    ...TwentyFourHourZoneTypes,
    ZoneType.ARMING,
    ZoneType.TAMPER,
  ].includes(type as ZoneType);
  const alarmEnabled = ![...TwentyFourHourZoneTypes, ZoneType.ARMING].includes(
    type as ZoneType
  );
  const troubleEnabled = ![
    ...TwentyFourHourZoneTypes,
    ZoneType.ARMING,
  ].includes(type as ZoneType);
  const localEnabled = ![...TwentyFourHourZoneTypes, ZoneType.ARMING].includes(
    type as ZoneType
  );
  const cancelAmbushEnabled = isXr;
  const doorProppedOpenEnabled = isXr || isXt75;
  const sensorResetEnabled = isXr || isXt75;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Disarmed Short Message"
      tooltip="Defines action taken by the System when this Zone is in short condition while the Area is Disarmed. Transmits Alarm or Trouble to the Receiver."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={disarmedShortActionMessage ?? undefined}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "disarmedShortActionMessage");
          });
        }}
      >
        <Select.Option value={ZoneMessage.NONE}>None</Select.Option>
        {alarmEnabled && (
          <Select.Option value={ZoneMessage.ALARM}>Alarm</Select.Option>
        )}
        {troubleEnabled && (
          <Select.Option value={ZoneMessage.TROUBLE}>Trouble</Select.Option>
        )}
        {localEnabled && (
          <Select.Option value={ZoneMessage.LOCAL}>Local</Select.Option>
        )}
        {cancelAmbushEnabled && (
          <Select.Option value={ZoneMessage.CANCEL_AMBUSH}>
            Cancel Ambush
          </Select.Option>
        )}
        {doorProppedOpenEnabled && (
          <Select.Option value={ZoneMessage.DOOR_PROPPED_OPEN}>
            Door Propped Open
          </Select.Option>
        )}
        {sensorResetEnabled && (
          <Select.Option value={ZoneMessage.SENSOR_RESET}>
            Sensor Reset
          </Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationDisarmedShortMessageField;
