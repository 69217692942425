/**
 * @generated SignedSource<<4a8bbe36488d939a14f3c5974b24822d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly systemAreaInformation: {
    readonly anyBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly areaSchedules: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCheck: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly openClosingReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "4be9bb1bcbe5c2ffc88883c3c76ab3dd";

export default node;
