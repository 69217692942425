import {
  baseInputStyles,
  BaseInputStylesProps,
} from "components/Inputs/BaseInput";
import * as React from "react";
import styled from "styled-components";

const BaseSelect = styled.select<BaseInputStylesProps>`
  ${baseInputStyles};

  &:enabled {
    background-color: white !important;
  }
`;

function SelectOption(props: React.HTMLProps<HTMLOptionElement>) {
  return <option {...props} />;
}

export type SelectProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  BaseInputStylesProps;

const Select = React.forwardRef(
  (props: SelectProps, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const { className } = props;
    return (
      <BaseSelect
        {...props}
        className={`form-control ${className}`}
        ref={ref}
      />
    );
  }
);

const SelectNamespace = Object.assign(Select, {
  Option: SelectOption,
});

export default SelectNamespace;
