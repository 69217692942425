/**
 * @generated SignedSource<<ef656e47f1d9d2399ac6041e50d2c60d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommPathTrouble = "ALL" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly statusListDisplay: {
    readonly auxiliary1ZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary2ZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly commPathTrouble: {
      readonly data: CommPathTrouble | null;
      readonly included: boolean | null;
    } | null;
    readonly emergencyZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly panicZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly systemTroubleStatusMonitors: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "d3604080ba3373bae7f171bd107ff35e";

export default node;
