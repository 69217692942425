/**
 * @generated SignedSource<<5e92af03f35a08724590010c5d623a57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatWiegandCodeLengthField_CardFormatList_panel$data = {
  readonly cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly wiegandLength: number;
  }>;
  readonly " $fragmentType": "CardFormatWiegandCodeLengthField_CardFormatList_panel";
};
export type CardFormatWiegandCodeLengthField_CardFormatList_panel$key = {
  readonly " $data"?: CardFormatWiegandCodeLengthField_CardFormatList_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatWiegandCodeLengthField_CardFormatList_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatWiegandCodeLengthField_CardFormatList_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CardFormat",
      "kind": "LinkedField",
      "name": "cardFormats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wiegandLength",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "1c410e5dc33f4fca96d61e01ba95a3ec";

export default node;
