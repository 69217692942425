import * as _ from "lodash";
import { useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";

const DEBOUNCE_TIME = 150;

type DebouncedSize = {
  ref: React.RefCallback<Element>;
  width: number | undefined;
  height: number | undefined;
};

/**
 * @returns DebouncedSize - An object containing the ref, width, and height.
 */
const useDebouncedResizeObserver = (): DebouncedSize => {
  const [size, setSize] = useState<Record<string, number | undefined>>({
    width: 0,
    height: 0,
  });

  //We use the `trailing` option here to call setSize when the timeout ends.
  const onResize = useMemo(
    () => _.debounce(setSize, DEBOUNCE_TIME, { trailing: true }),
    []
  );

  const { ref } = useResizeObserver({ onResize });

  return { ref, width: size.width, height: size.height };
};

export default useDebouncedResizeObserver;
