/**
 * @generated SignedSource<<ab4699d5dfe085cd0a0e3eb88e1ed159>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly systemReports: {
    readonly abortReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly accessKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ambushReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly bypassReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly codeChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly earlyToClose: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryCheckinProtection: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lateToOpen: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly openCloseEnable: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly scheduleChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly sendStoredMessage: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly videoSystemReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneRestoralReports: {
      readonly data: SystemReportsZoneRestoralReports | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "e0978acca5f5ec38e9c3fc5a8812887a";

export default node;
