/**
 *Directive validating data against list items
 */
App.directive("daValidAgainstList", [
  "$filter",
  function ($filter) {
    return {
      scope: {
        listOfValids: "=",
        field: "=",
        nullIsValid: "=",
      },
      restrict: "A",
      require: "ngModel",
      link: function (scope, element, attributes, ngModel) {
        ngModel.$validators.validItem = function (modelValue) {
          var filterExpression = {};
          filterExpression[scope.field] = modelValue;
          if (scope.nullIsValid && modelValue == null) return true;
          return !(
            $filter("filter")(scope.listOfValids, filterExpression).length === 0
          );
        };
      },
    };
  },
]);
