/**
 * @ngdoc service
 * @name App.factory:ModemScheduledUpgradesService
 *
 * @description
 * Holds the API and methods for the Modem Upgrade Settings
 *
 */
App.factory("ModemScheduledUpgradesService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "ModemScheduledUpgradesAPI",
  "UserService",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    ModemScheduledUpgradesAPI,
    UserService
  ) {
    return {
      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemScheduledUpgradesService
       *
       * @description
       * Returns an array of all scheduled upgrades for that dealer.
       *
       * @param {Promise} data- promise response
       */
      getScheduledUpgradesForDealer: function (dealerId) {
        var deferred = $q.defer();

        ModemScheduledUpgradesAPI.getScheduledUpgradesForDealer(
          {
            dealer_id: dealerId,
          },

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemScheduledUpgradesService
       *
       * @description
       * Deletes one scheduled upgrade for the dealer.
       *
       * @param {Promise} data- promise response
       */
      deleteScheduledUpgradeForDealer: function (dealerId, upgradeId) {
        var deferred = $q.defer();

        ModemScheduledUpgradesAPI.deleteScheduledUpgradeForDealer(
          {
            dealer_id: dealerId,
            id: upgradeId,
          },

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
