import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorBackupIpAddressField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorBackupIpAddressField_remoteOptions.graphql";

export const remoteOptionsIntegratorBackupIpAddressFieldId = () =>
  "remote-options-integrator-backup-ip-address";

function RemoteOptionsIntegratorBackupIpAddressField() {
  const [
    {
      integratorBackupIpAddress,
      integratorConnection,
      integratorBackupConnection,
    },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorBackupIpAddressField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorBackupIpAddressField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorBackupIpAddress
          integratorConnection
          integratorBackupConnection
        }
        ... on XfRemoteOptions {
          integratorBackupIpAddress
          integratorConnection
          integratorBackupConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorBackupIpAddressFieldId();

  const disabled =
    integratorConnection === RemoteOptionsConnectionType.NONE ||
    integratorBackupConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Integrator Backup IP Address"
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={integratorBackupIpAddress || ""}
        maxLength={16}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        validationMessage="Please enter a valid IP address"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "integratorBackupIpAddress")
          );
        }}
        onBlur={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "integratorBackupIpAddress"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorBackupIpAddressField;
