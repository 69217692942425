import graphql from "babel-plugin-relay/macro";
import Flex from "common/components/web/Flex";
import TestConnectionButton, {
  DeviceType,
  TestButton,
} from "components/CameraTable/TestConnectionButton";
import { GenericPageFallback } from "components/GenericPageFallback";
import NeutralText from "components/NeutralText";
import RedirectTo404 from "components/RedirectTo404";
import { VarStatusPuck } from "components/VarStatusBadge";
import { format } from "date-fns";
import React, { Suspense } from "react";
import {
  RelayEnvironmentProvider,
  useFragment,
  useLazyLoadQuery,
} from "react-relay/hooks";
import {
  fromSiteControlSystemId,
  ID,
  toSiteId,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import DaColors from "../../app/common/DaColors";
import { SiteDiagnosticsCellStatusFragment_controlSystem$key } from "./__generated__/SiteDiagnosticsCellStatusFragment_controlSystem.graphql";
import { SiteDiagnosticsCommunicationStatusFragment_controlSystem$key } from "./__generated__/SiteDiagnosticsCommunicationStatusFragment_controlSystem.graphql";
import {
  SiteDiagnosticsControlSystemFragment_controlSystem$data,
  SiteDiagnosticsControlSystemFragment_controlSystem$key,
} from "./__generated__/SiteDiagnosticsControlSystemFragment_controlSystem.graphql";
import { SiteDiagnosticsDoorFragment_door$key } from "./__generated__/SiteDiagnosticsDoorFragment_door.graphql";
import { SiteDiagnosticsEasyConnectStatusFragment_controlSystem$key } from "./__generated__/SiteDiagnosticsEasyConnectStatusFragment_controlSystem.graphql";
import { SiteDiagnosticsQuery } from "./__generated__/SiteDiagnosticsQuery.graphql";

const AlignChildren: any = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

AlignChildren.SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

//css var '--measure-[1-8]x' all multiples of 8
const SectionHeader: any = styled.section`
  font-size: 1.4rem;
  margin: 1.4rem 0 var(--measure-1x);
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  display: block;
`;
SectionHeader.Divider = styled.hr`
  margin: 0 0 var(--measure-half);
`;

//grid for content area
const DiagGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  grid-auto-rows: minmax(min-content, auto);
  grid-gap: var(--measure-2x);
  list-style: none;
  margin: 0;
  padding: 0;
`;

// grid for each X1/8
const SiteDiagPanel: any = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content var(--measure-10x) max-content;
  grid-template-areas: "site-info-lt site-info-rt" "communication-info communication-info" "door-list door-list";
  grid-gap: var(--measure-1x);
  list-style: none;
  margin: 0;
  padding: var(--measure-2x);
`;

SiteDiagPanel.Left = styled.div`
  grid-area: site-info-lt;
`;

SiteDiagPanel.Right = styled.div`
  grid-area: site-info-rt;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

SiteDiagPanel.ComInfo = styled.div`
  grid-area: communication-info;
  padding-bottom: var(--measure-1x);
`;

SiteDiagPanel.ComInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--measure-1x) 0;
`;

SiteDiagPanel.DoorList = styled.div`
  grid-area: door-list;
`;

SiteDiagPanel.DoorListItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: var(--measure-1x) 0;
`;

const AnalyticsButton = styled(TestButton)`
  background-color: ${DaColors.Info500};
  color: #fff;

  &:hover {
    background: ${(props) =>
      props.isPreProgram ? `${DaColors.Info500}` : `${DaColors.Info700}`};
    color: #fff;
  }

  :disabled {
    opacity: 0.4;
  }
`;

const ComInfoTextContainer = styled.div`
  display: grid;
`;

const ComInfoStatusPuck = styled(VarStatusPuck)`
  margin-bottom: 1.5rem;
`;

export default function SiteDiagnosticsRoot({
  $state,
  $stateParams,
  RelayService,
}: {
  $state: any;
  $stateParams: any;
  RelayService: any;
}) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<GenericPageFallback />}>
        <SiteDiagnostics
          customerId={$stateParams.customer_id}
          siteId={$stateParams.site_id}
          state={$state}
        />
      </Suspense>
    </RelayEnvironmentProvider>
  );
}
function SiteDiagnostics({
  customerId,
  siteId,
  state,
}: {
  customerId: string;
  siteId: string;
  state: any;
}) {
  const data = useLazyLoadQuery<SiteDiagnosticsQuery>(
    graphql`
      query SiteDiagnosticsQuery($siteId: ID!) {
        node(id: $siteId) {
          ... on Site {
            id
            name
            controlSystems {
              id
              ...SiteDiagnosticsControlSystemFragment_controlSystem
            }
          }
        }
      }
    `,
    {
      siteId: String(toSiteId(customerId, siteId)),
    },
    {
      UNSTABLE_renderPolicy: "partial",
      fetchPolicy: "store-and-network",
    }
  );
  return data.node?.id ? (
    <>
      <div className="row">
        <div className="page-header">
          <div className="page-header__left">
            <div className="page-header__title">Diagnostics</div>
          </div>
        </div>
      </div>
      <div className="row">
        <DiagGrid>
          {data.node.controlSystems?.map((system) => (
            <Suspense fallback={<WidgetFallback />} key={system.id}>
              <X1DiagWidget controlSystemRef={system} state={state} />
            </Suspense>
          ))}
        </DiagGrid>
      </div>
    </>
  ) : (
    <RedirectTo404 />
  );
}

//Creates a row for doors in each system table
function DoorRow({
  door,
  controlSystem,
}: {
  door: SiteDiagnosticsDoorFragment_door$key;
  controlSystem: SiteDiagnosticsControlSystemFragment_controlSystem$data;
}) {
  const doorData = useFragment(
    graphql`
      fragment SiteDiagnosticsDoorFragment_door on SiteDoor {
        name
        connectionStatus
        number
      }
    `,
    door
  );
  const controlSystemData =
    useFragment<SiteDiagnosticsCommunicationStatusFragment_controlSystem$key>(
      graphql`
        fragment SiteDiagnosticsCommunicationStatusFragment_controlSystem on SiteControlSystem {
          id
          hasEasyConnect
          online
          easyConnectStatus {
            status
          }
          hasCell
          cellStatus {
            ... on ControlSystemCellStatus {
              status
            }
          }
        }
      `,
      controlSystem
    );
  const isSystemOnline =
    (controlSystemData.cellStatus?.status === "ONLINE" ||
      controlSystemData.easyConnectStatus?.status === "ONLINE") &&
    (doorData.number === 1 || doorData.connectionStatus === "ONLINE");
  return (
    <SiteDiagPanel.DoorListItem>
      <AlignChildren>
        <VarStatusPuck
          status={isSystemOnline}
          isPreProgram={!controlSystemData.online}
        />
        {doorData.name || "Door"}
      </AlignChildren>
    </SiteDiagPanel.DoorListItem>
  );
}

//used for Primary and Secondary Communication Type rows
// Parent of EasyConnectComRow and CellCommRow
function ComPathRow({
  type,
  isGood,
  isBackup,
  lastCheckIn,
  isPreProgram,
}: {
  type: string;
  isGood: boolean;
  isBackup?: boolean;
  lastCheckIn?: string | null;
  isPreProgram?: boolean | null;
}) {
  const formatLastCheckIn = lastCheckIn
    ? format(new Date(lastCheckIn), "M/d/u h:mm a")
    : "-";
  return (
    <>
      <SiteDiagPanel.ComInfoItem>
        <AlignChildren>
          <ComInfoStatusPuck status={isGood} isPreProgram={isPreProgram} />
          <ComInfoTextContainer>
            {`${type}${type === "Cellular" && isBackup ? " (Backup)" : ""}`}
            <NeutralText style={{ fontSize: "var(--measure-font-10)" }}>
              Last Check-in: {formatLastCheckIn}
            </NeutralText>
          </ComInfoTextContainer>
        </AlignChildren>
      </SiteDiagPanel.ComInfoItem>
    </>
  );
}

// Creates a table row for Easy Connect com type using ComPathRow
function EasyConnectComRow({
  controlSystem,
}: {
  controlSystem: SiteDiagnosticsControlSystemFragment_controlSystem$data;
}) {
  const data =
    useFragment<SiteDiagnosticsEasyConnectStatusFragment_controlSystem$key>(
      graphql`
        fragment SiteDiagnosticsEasyConnectStatusFragment_controlSystem on SiteControlSystem {
          id
          hasEasyConnect
          online
          easyConnectStatus {
            status
            lastCheckIn
          }
        }
      `,
      controlSystem
    );
  return (
    <ComPathRow
      type="EASYconnect"
      isGood={data.easyConnectStatus?.status === "ONLINE"}
      lastCheckIn={data.easyConnectStatus?.lastCheckIn}
      isPreProgram={!data.online}
    />
  );
}

// Creates a table row for Cell com type using ComPathRow
function CellCommRow({
  controlSystem,
}: {
  controlSystem: SiteDiagnosticsControlSystemFragment_controlSystem$data;
}) {
  const data = useFragment<SiteDiagnosticsCellStatusFragment_controlSystem$key>(
    graphql`
      fragment SiteDiagnosticsCellStatusFragment_controlSystem on SiteControlSystem {
        id
        commType
        hasCell
        online
        cellStatus {
          status
          lastCheckIn
        }
      }
    `,
    controlSystem
  );
  return (
    <>
      <ComPathRow
        type="Cellular"
        isGood={data.cellStatus?.status === "ONLINE"}
        isBackup={data.commType === "PERSISTENT_WITH_CELL_BACKUP"}
        lastCheckIn={data.cellStatus?.lastCheckIn}
        isPreProgram={!data.online}
      />
    </>
  );
}

//Renders a display for each X1/8 system
function X1DiagWidget({
  controlSystemRef,
  state,
}: {
  controlSystemRef: SiteDiagnosticsControlSystemFragment_controlSystem$key;
  state: any;
}) {
  const controlSystemData =
    useFragment<SiteDiagnosticsControlSystemFragment_controlSystem$key>(
      graphql`
        fragment SiteDiagnosticsControlSystemFragment_controlSystem on SiteControlSystem {
          id
          name
          softwareDisplayName
          isX1
          isX8
          serialNumber
          hasEasyConnect
          hasCell
          online
          hasPendingChanges
          doors {
            id
            name
            siteDoorNumber
            ...SiteDiagnosticsDoorFragment_door
          }
          ...SiteDiagnosticsCommunicationStatusFragment_controlSystem
          ...SiteDiagnosticsEasyConnectStatusFragment_controlSystem
          ...SiteDiagnosticsCellStatusFragment_controlSystem
        }
      `,
      controlSystemRef
    );
  const { controlSystemId, customerId, siteId } = fromSiteControlSystemId(
    controlSystemData.id as unknown as ID
  );
  const isPreProgram = !controlSystemData.online;

  return (
    <>
      <div
        className="panel panel-table"
        style={{
          border: isPreProgram ? "0.2rem solid grey" : "0.1rem solid #ccc",
        }}
      >
        <SiteDiagPanel>
          <SiteDiagPanel.Left>
            <div
              style={{
                fontSize: "var(--measure-font-14)",
                fontWeight: "var(--font-weight-bold)",
              }}
            >
              SN:&nbsp;
              {controlSystemData.serialNumber}
            </div>
            <NeutralText>
              Software Version:&nbsp;
              {controlSystemData.softwareDisplayName}
            </NeutralText>
          </SiteDiagPanel.Left>
          <SiteDiagPanel.Right>
            <AnalyticsButton
              type="button"
              onClick={() => {
                state.go("app.control_system.site_panel_statistics", {
                  control_system_id: controlSystemId,
                  customer_id: customerId,
                  site_id: siteId,
                });
              }}
              disabled={isPreProgram}
              {...(isPreProgram ? { isPreProgram } : {})}
            >
              View Analytics
            </AnalyticsButton>
          </SiteDiagPanel.Right>
          <SiteDiagPanel.ComInfo>
            {controlSystemData.hasEasyConnect && controlSystemData.hasCell ? (
              <>
                <AlignChildren.SpaceBetween>
                  <EasyConnectComRow controlSystem={controlSystemData} />
                  <TestConnectionButton
                    deviceType={DeviceType.X1}
                    deviceId={controlSystemData.id}
                    isPreProgram={isPreProgram}
                  />
                </AlignChildren.SpaceBetween>
                <CellCommRow controlSystem={controlSystemData} />
              </>
            ) : controlSystemData.hasEasyConnect ? (
              <AlignChildren.SpaceBetween>
                <EasyConnectComRow controlSystem={controlSystemData} />
                <TestConnectionButton
                  deviceType={DeviceType.X1}
                  deviceId={controlSystemData.id}
                  isPreProgram={isPreProgram}
                />
              </AlignChildren.SpaceBetween>
            ) : controlSystemData.hasCell ? (
              <AlignChildren.SpaceBetween>
                <CellCommRow controlSystem={controlSystemData} />
                <TestConnectionButton
                  deviceType={DeviceType.X1}
                  deviceId={controlSystemData.id}
                  isPreProgram={isPreProgram}
                />
              </AlignChildren.SpaceBetween>
            ) : null}
          </SiteDiagPanel.ComInfo>
          <SiteDiagPanel.DoorList>
            <SectionHeader>Doors</SectionHeader>
            <SectionHeader.Divider />
            {controlSystemData.doors.map((door) =>
              door.name ? (
                <DoorRow
                  door={door}
                  controlSystem={controlSystemData}
                  key={`${door.name}${door.siteDoorNumber}`}
                />
              ) : null
            )}
          </SiteDiagPanel.DoorList>
        </SiteDiagPanel>
      </div>
    </>
  );
}

function WidgetFallback() {
  return (
    <div className="panel" style={{ minHeight: "250px" }}>
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <div className="csspinner traditional" />
      </Flex>
    </div>
  );
}
