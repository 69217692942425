import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathSendFailField_communicationPath$key } from "./__generated__/CommPathSendFailField_communicationPath.graphql";

export const commPathSendFailFieldId = (number: string) =>
  `comm-path-send-fail-${number}`;

function CommPathSendFailField() {
  const [{ number, sendFail }, updateCommPath] =
    useCommPathFragment<CommPathSendFailField_communicationPath$key>(
      graphql`
        fragment CommPathSendFailField_communicationPath on CommunicationPath {
          number
          sendFail
        }
      `
    );

  const fieldId = commPathSendFailFieldId(String(number));

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Send Communication Trouble"
      tooltip="Send communication trouble messages to the receiver on this path."
    >
      <Switch
        label="Send Communication Trouble"
        id={fieldId}
        checked={sendFail}
        onChange={() => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(!sendFail, "sendFail");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathSendFailField;
