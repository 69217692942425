/**
 * @generated SignedSource<<04974b13157fa33b80cf27c27087dd87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly id: string;
    readonly totalWirelessZoneMax: number;
    readonly totalZonesMax: number;
    readonly zoneInformations: ReadonlyArray<{
      readonly __typename: "Zone";
      readonly id: string;
      readonly isNew: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"TMSentryZoneInformationsProgrammingConceptFormInline_zone">;
    }>;
    readonly zoneNumberRange: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem";
};
export type TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "a441d0d6c9540df3e3412ee5756a19dc";

export default node;
