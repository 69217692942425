import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { CommunicationPathType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathPanicTestField_communicationPath$key } from "./__generated__/CommPathPanicTestField_communicationPath.graphql";

export const commPathPanicTestFieldId = (number: string) =>
  `comm-path-panic-test-${number}`;

function CommPathPanicTestField() {
  const [{ number, panicTest, type }, updateCommPath] =
    useCommPathFragment<CommPathPanicTestField_communicationPath$key>(
      graphql`
        fragment CommPathPanicTestField_communicationPath on CommunicationPath {
          number
          panicTest
          type
        }
      `
    );

  const fieldId = commPathPanicTestFieldId(String(number));
  const disabled = type === CommunicationPathType.BACKUP;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Panic Test"
      disabled={disabled}
      tooltip="Allows Panic Zone Test results to be sent to the receiver."
    >
      <Switch
        label="Panic Test"
        id={fieldId}
        disabled={disabled}
        checked={panicTest}
        onChange={() => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(!panicTest, "panicTest");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathPanicTestField;
