App.factory("StoreOrdersAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    //returns information for dealer_id
    return $resource(
      PROPS.companyStoreApiUrl +
        "/api/v1/dealers/:dealer_id/orders/:order_id/:action",
      {},
      {
        indexOrders: { method: "GET", params: { dealer_id: "@dealer_id" } },
        getOrder: {
          method: "GET",
          params: { dealer_id: "@dealer_id", order_id: "@order_id" },
        },
        approveOrder: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            order_id: "@order_id",
            action: "approve",
          },
        },
        declineOrder: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            order_id: "@order_id",
            action: "decline",
          },
        },
        getSalesStatistics: {
          method: "GET",
          params: { dealer_id: "@dealer_id", action: "sales_statistics" },
        },
      }
    );
  },
]);
