/**
 * @generated SignedSource<<e3c7ca4b580f35df1b7bec72c3c7eabc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE";
import { FragmentRefs } from "relay-runtime";
export type SiteControlSystemOnlineStatusBadge_siteControlSystem$data = {
  readonly cellStatus: {
    readonly status: ConnectionStatus;
  } | null;
  readonly easyConnectStatus: {
    readonly status: ConnectionStatus;
  } | null;
  readonly hasCell: boolean;
  readonly hasEasyConnect: boolean;
  readonly id: string;
  readonly online: boolean;
  readonly " $fragmentType": "SiteControlSystemOnlineStatusBadge_siteControlSystem";
};
export type SiteControlSystemOnlineStatusBadge_siteControlSystem$key = {
  readonly " $data"?: SiteControlSystemOnlineStatusBadge_siteControlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteControlSystemOnlineStatusBadge_siteControlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SiteControlSystemOnlineStatusBadge_siteControlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "SiteControlSystemOnlineStatusBadge_siteControlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEasyConnect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemCellStatus",
      "kind": "LinkedField",
      "name": "cellStatus",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemEasyConnectStatus",
      "kind": "LinkedField",
      "name": "easyConnectStatus",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "d494317a0cce997e948dcda3169ca0de";

export default node;
