/**
 * @generated SignedSource<<576a09f2f3ffb370c633832afb7fc184>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XFRemoteOptionsProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: string;
      readonly entreBackupConnection?: RemoteOptionsConnectionType | null;
      readonly entreConnection?: RemoteOptionsConnectionType | null;
      readonly integratorBackupConnection?: RemoteOptionsConnectionType | null;
      readonly integratorConnection?: RemoteOptionsConnectionType | null;
      readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsApnField_remoteOptions" | "RemoteOptionsAppKeyField_remoteOptions" | "RemoteOptionsContext_remoteOptions" | "RemoteOptionsEntreBackupConnectionField_remoteOptions" | "RemoteOptionsEntreBackupIpAddressField_remoteOptions" | "RemoteOptionsEntreBackupTcpPortField_remoteOptions" | "RemoteOptionsEntreCheckInMinutesField_remoteOptions" | "RemoteOptionsEntreConnectionField_remoteOptions" | "RemoteOptionsEntreIncomingTcpPortField_remoteOptions" | "RemoteOptionsEntreIpAddressField_remoteOptions" | "RemoteOptionsEntreOutgoingTcpPortField_remoteOptions" | "RemoteOptionsEntrePassphraseField_remoteOptions" | "RemoteOptionsEntreSupervisoryReportsField_remoteOptions" | "RemoteOptionsEntreUserCommandReportsField_remoteOptions" | "RemoteOptionsEntreZoneReportsField_remoteOptions" | "RemoteOptionsIntegratorBackupConnectionField_remoteOptions" | "RemoteOptionsIntegratorBackupIpAddressField_remoteOptions" | "RemoteOptionsIntegratorBackupTcpPortField_remoteOptions" | "RemoteOptionsIntegratorConnectionField_remoteOptions" | "RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions" | "RemoteOptionsIntegratorIpAddressField_remoteOptions" | "RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions" | "RemoteOptionsIntegratorPassphraseField_remoteOptions" | "RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions" | "RemoteOptionsIntegratorUserCommandReportsField_remoteOptions" | "RemoteOptionsIntegratorZoneReportsField_remoteOptions" | "RemoteOptionsRemoteKeyField_remoteOptions">;
    } | null;
    readonly softwareVersion: string;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XFRemoteOptionsProgrammingConceptForm_controlSystem";
};
export type XFRemoteOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XFRemoteOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFRemoteOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFRemoteOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "remoteOptions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "integratorConnection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "integratorBackupConnection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entreConnection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entreBackupConnection",
                  "storageKey": null
                }
              ],
              "type": "XfRemoteOptions",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsContext_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsRemoteKeyField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsApnField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsAppKeyField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorBackupConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorBackupIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorBackupTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorZoneReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorUserCommandReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsIntegratorPassphraseField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreIncomingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreOutgoingTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreBackupConnectionField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreBackupIpAddressField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreBackupTcpPortField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreZoneReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreUserCommandReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreSupervisoryReportsField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntreCheckInMinutesField_remoteOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RemoteOptionsEntrePassphraseField_remoteOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "074dd8c5ee9f2cd575f7fa22f066499a";

export default node;
