/**
 * @ngdoc service
 * @name App.factory:CustomerControlSystemsV2API
 *
 * @description
 * API factory for Control System Information (using customer)
 *
 */
App.factory("CustomerControlSystemsV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl +
        "/v2/customers/:customer_id/control_systems/:control_system_id/:edit/:new",
      {},
      {
        create: { method: "POST", params: { customer_id: "@customer_id" } },
        edit: {
          method: "GET",
          params: {
            customer_id: "@customer_id",
            control_system_id: "@control_system_id",
            edit: "edit",
          },
        },
        new: {
          method: "GET",
          params: { customer_id: "@customer_id", new: "new" },
        },
        update: {
          method: "PUT",
          params: {
            customer_id: "@customer_id",
            control_system_id: "@control_system_id",
          },
        },
        destroy: {
          method: "DELETE",
          params: { control_system_id: "@control_system_id" },
        },
      }
    );
  },
]);
