import { Dispatch } from "react";

export type ChangePasswordFormState = {
  readonly currentPassword: string;
  readonly newPassword: string;
  readonly confirmPassword: string;
  readonly showCurrentPasswordError: boolean;
  readonly showNewPasswordError: boolean;
  readonly showConfirmPasswordError: boolean;
  readonly passwordsDontMatch: boolean;
  readonly currentPasswordVisibility: boolean;
  readonly newPasswordVisibility: boolean;
  readonly changePasswordError: string;
};

type Action =
  | { type: "CURRENT_PASSWORD_CHANGED"; value: string }
  | { type: "CURRENT_PASSWORD_BLURRED"; value: boolean }
  | { type: "CURRENT_PASSWORD_FOCUSED"; value: boolean }
  | { type: "NEW_PASSWORD_CHANGED"; value: string }
  | { type: "NEW_PASSWORD_BLURRED" }
  | { type: "NEW_PASSWORD_FOCUSED" }
  | { type: "CONFIRM_PASSWORD_CHANGED"; value: string }
  | { type: "CONFIRM_PASSWORD_BLURRED"; value: boolean }
  | { type: "CONFIRM_PASSWORD_FOCUSED"; value: boolean }
  | { type: "PASSWORDS_DONT_MATCH" }
  | { type: "TOGGLE_CURRENT_PASSWORD_VISIBILITY" }
  | { type: "TOGGLE_NEW_PASSWORD_VISIBILITY" }
  | { type: "CHANGE_PASSWORD_SUBMITTED" }
  | { type: "CHANGE_PASSWORD_MODAL_CLOSED" }
  | { type: "CHANGE_PASSWORD_ERRORED"; error: string };

export type ChangePasswordFormDispatch = Dispatch<Action>;

export const ChangePasswordFormReducer = (
  state: ChangePasswordFormState,
  action: Action
): ChangePasswordFormState => {
  switch (action.type) {
    case "CURRENT_PASSWORD_CHANGED":
      return {
        ...state,
        currentPassword: action.value,
      };
    case "CURRENT_PASSWORD_BLURRED":
      return {
        ...state,
        showCurrentPasswordError: true,
      };
    case "CURRENT_PASSWORD_FOCUSED":
      return {
        ...state,
        showCurrentPasswordError: false,
      };

    case "NEW_PASSWORD_CHANGED":
      return {
        ...state,
        newPassword: action.value,
      };
    case "NEW_PASSWORD_BLURRED":
      return {
        ...state,
        showNewPasswordError: true,
      };
    case "NEW_PASSWORD_FOCUSED":
      return {
        ...state,
        showNewPasswordError: false,
      };

    case "CONFIRM_PASSWORD_CHANGED":
      return {
        ...state,
        confirmPassword: action.value,
      };
    case "CONFIRM_PASSWORD_BLURRED":
    case "CONFIRM_PASSWORD_FOCUSED":
      return {
        ...state,
        showConfirmPasswordError: action.value,
      };

    case "PASSWORDS_DONT_MATCH":
      return {
        ...state,
        passwordsDontMatch: true,
      };
    case "TOGGLE_CURRENT_PASSWORD_VISIBILITY":
      return {
        ...state,
        currentPasswordVisibility: !state.currentPasswordVisibility,
      };
    case "TOGGLE_NEW_PASSWORD_VISIBILITY":
      return {
        ...state,
        newPasswordVisibility: !state.newPasswordVisibility,
      };
    case "CHANGE_PASSWORD_SUBMITTED":
      return {
        ...state,
        changePasswordError: "",
      };
    case "CHANGE_PASSWORD_MODAL_CLOSED":
      return {
        ...state,
        currentPassword: "",
        showCurrentPasswordError: false,
        newPassword: "",
        showNewPasswordError: false,
        confirmPassword: "",
        showConfirmPasswordError: false,
        passwordsDontMatch: false,
        currentPasswordVisibility: false,
        newPasswordVisibility: false,
        changePasswordError: "",
      };
    case "CHANGE_PASSWORD_ERRORED":
      switch (action.error) {
        case "InvalidPasswordError":
          return {
            ...state,
            changePasswordError: "Invalid Password",
          };
        case "FailsPasswordStandardsError": {
          return {
            ...state,
            changePasswordError:
              "Must have 8-16 characters and any two upper, lower, number, or special Characters",
          };
        }
        default:
          return {
            ...state,
            changePasswordError: "Change Password Errored",
          };
      }

    default:
      return state;
  }
};
