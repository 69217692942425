/**
 * @generated SignedSource<<5f96a759463c23d9a8f32bce7572278f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HolidayDateClass = "A" | "B" | "C";
export type GlobalHolidayDatesTableQuery$variables = {
  dealerId: string;
};
export type GlobalHolidayDatesTableQuery$data = {
  readonly dealer: {
    readonly globalHolidayDates: ReadonlyArray<{
      readonly clazz: HolidayDateClass;
      readonly date: string;
      readonly description: string;
      readonly id: string;
      readonly name: string;
      readonly scapiId: number;
    }>;
    readonly id: string;
  } | null;
};
export type GlobalHolidayDatesTableQuery = {
  response: GlobalHolidayDatesTableQuery$data;
  variables: GlobalHolidayDatesTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "dealerId"
      }
    ],
    "concreteType": "Dealer",
    "kind": "LinkedField",
    "name": "dealer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalHolidayDate",
        "kind": "LinkedField",
        "name": "globalHolidayDates",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scapiId",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clazz",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalHolidayDatesTableQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalHolidayDatesTableQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ae03376bc5d4de09dcc73a1fdbef3f5d",
    "id": null,
    "metadata": {},
    "name": "GlobalHolidayDatesTableQuery",
    "operationKind": "query",
    "text": "query GlobalHolidayDatesTableQuery(\n  $dealerId: ID!\n) {\n  dealer(id: $dealerId) {\n    id\n    globalHolidayDates {\n      scapiId\n      id\n      name\n      description\n      date\n      clazz\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5426df4b595372f384163833afb0cf28";

export default node;
