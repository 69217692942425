import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { listOfSiteCodesToObject } from "./utils";
import { CardFormatSiteCode8Field_cardFormat$key } from "./__generated__/CardFormatSiteCode8Field_cardFormat.graphql";

export const cardFormatSiteCode8FieldId = (number: string) =>
  `card-format-site-code-8-${number}`;

function CardFormatSiteCode8Field() {
  const [cardFormat, updateCardFormat] =
    useCardFormatFragment<CardFormatSiteCode8Field_cardFormat$key>(
      graphql`
        fragment CardFormatSiteCode8Field_cardFormat on CardFormat {
          siteCodes
          requireSiteCode
          number
        }
      `
    );

  const fieldId = cardFormatSiteCode8FieldId(cardFormat.number);
  const siteCodes = listOfSiteCodesToObject(cardFormat.siteCodes);
  const { siteCode8 } = siteCodes;
  const originalValue = React.useRef(siteCode8).current;
  const disabled = !cardFormat?.requireSiteCode;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Site Code 8"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        pattern={
          "(0?[0-9]{0,7}|1[0-5][0-9]{6}|16[0-6][0-9]{5}|167[0-6][0-9]{4}|1677[0-6][0-9]{3}|16777[0-1][0-9]{2}|1677720[0-9]|1677721[0-4])"
        }
        validationMessage="Valid values are 0-16777214."
        value={siteCode8 ?? ""}
        inlineHelp={"0-16777214"}
        min={0}
        max={16777214}
        onChange={({ target }) => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(
              Object.values({
                ...siteCodes,
                siteCode8: emptyStringOrNumber(target.value),
              }),
              "siteCodes"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCardFormat((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(0, 16777214, valueAsNumber);
            recordProxy.setValue(
              Object.values({
                ...siteCodes,
                siteCode8: target.value === "" ? null : value,
              }),
              "siteCodes"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatSiteCode8Field;
