import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { outputGroupNameFieldId } from "components/FullProgramming/common/OutputGroupFields/OutputGroupNameField";
import {
  outputGroupListItemTemplateId,
  outputGroupNumberFieldId,
} from "components/FullProgramming/common/OutputGroupFields/OutputGroupNumberField";
import { outputGroupOutputNumberFieldId } from "components/FullProgramming/common/OutputGroupFields/OutputGroupOutputNumberField";
import { XROutputGroupsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/XROutputGroupsProgrammingConceptForm/__generated__/XROutputGroupsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  getOutputGroupState,
  getState as getOutputGroupsState,
} from "../../../XRFullProgramming/XROutputGroupsProgrammingConceptForm";
import { XrProgrammingTemplateOutputGroupInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        outputGroups {
          id
          included
          number
          name {
            data
            included
          }
          outputNumber1 {
            data
            included
          }
          outputNumber2 {
            data
            included
          }
          outputNumber3 {
            data
            included
          }
          outputNumber4 {
            data
            included
          }
          outputNumber5 {
            data
            included
          }
          outputNumber6 {
            data
            included
          }
          outputNumber7 {
            data
            included
          }
          outputNumber8 {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XROutputGroupsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateOutputGroupInput[] => {
  const {
    panel: { outputGroups },
  } = getOutputGroupsState(controlSystem);

  return outputGroups
    ? outputGroups
        .filter(isNotNullOrUndefined)
        .map(getOutputGroupState)
        .map((outputGroup) => ({
          included: includedFields.has(
            outputGroupListItemTemplateId(String(outputGroup.number))
          ),
          number: outputGroup.number,
          name: {
            data: outputGroup.name,
            included: includedFields.has(
              outputGroupNameFieldId(outputGroup.number)
            ),
          },
          outputNumber1: {
            data: outputGroup.outputNumber1,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("1", outputGroup.number)
            ),
          },
          outputNumber2: {
            data: outputGroup.outputNumber2,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("2", outputGroup.number)
            ),
          },
          outputNumber3: {
            data: outputGroup.outputNumber3,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("3", outputGroup.number)
            ),
          },
          outputNumber4: {
            data: outputGroup.outputNumber4,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("4", outputGroup.number)
            ),
          },
          outputNumber5: {
            data: outputGroup.outputNumber5,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("5", outputGroup.number)
            ),
          },
          outputNumber6: {
            data: outputGroup.outputNumber6,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("6", outputGroup.number)
            ),
          },
          outputNumber7: {
            data: outputGroup.outputNumber7,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("7", outputGroup.number)
            ),
          },
          outputNumber8: {
            data: outputGroup.outputNumber8,
            included: includedFields.has(
              outputGroupOutputNumberFieldId("8", outputGroup.number)
            ),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { outputGroups } = getState(template);

  if (!outputGroups) {
    return;
  }

  outputGroups.filter(isNotNullOrUndefined).forEach((outputGroup) => {
    const number = String(outputGroup.number);

    if (outputGroup.included) {
      setIncludedFields(setToggle(outputGroupListItemTemplateId(number)));
      setIncludedFields(setToggle(outputGroupNumberFieldId(number)));
    }
    if (outputGroup.name?.included) {
      setIncludedFields(setToggle(outputGroupNameFieldId(number)));
    }
    if (outputGroup.outputNumber1?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("1", number)));
    }
    if (outputGroup.outputNumber2?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("2", number)));
    }
    if (outputGroup.outputNumber3?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("3", number)));
    }
    if (outputGroup.outputNumber4?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("4", number)));
    }
    if (outputGroup.outputNumber5?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("5", number)));
    }
    if (outputGroup.outputNumber6?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("6", number)));
    }
    if (outputGroup.outputNumber7?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("7", number)));
    }
    if (outputGroup.outputNumber8?.included) {
      setIncludedFields(setToggle(outputGroupOutputNumberFieldId("8", number)));
    }
  });
};
