import DaColors from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import React, { useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import {
  VideoDeviceSettingConfirmation,
  VideoDeviceStatus,
} from "securecom-graphql/client/schema-types";
import styled from "styled-components";
import { formatDateString } from "utils/dates";
import { VarStatusBadge_Puck_varHub$key } from "./__generated__/VarStatusBadge_Puck_varHub.graphql";
import { VarStatusBadge_Puck_varHubQuery } from "./__generated__/VarStatusBadge_Puck_varHubQuery.graphql";
import { VarStatusBadge_varHub$key } from "./__generated__/VarStatusBadge_varHub.graphql";
import { VarStatusBadge_varHubQuery } from "./__generated__/VarStatusBadge_varHubQuery.graphql";

const Root = styled.div<{ active: boolean | null }>`
  background-color: ${(props) =>
    props.active === null
      ? DaColors.Caution500
      : props.active
      ? DaColors.Success500
      : DaColors.Failure500};
  height: 2rem;
  width: 8rem;
  color: ${(props) => (props.active ? "white" : "white")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  font-weight: 700;
`;

const VarStatusBadge = ({ status }: { status: boolean }) => {
  return <Root active={status}>{status ? "ONLINE" : "OFFLINE"}</Root>;
};

export const VarStatusBadgeWithAutoRefresh = ({
  varHub,
}: {
  varHub: VarStatusBadge_varHub$key;
}) => {
  const [data, refetch] = useRefetchableFragment<
    VarStatusBadge_varHubQuery,
    VarStatusBadge_varHub$key
  >(
    graphql`
      fragment VarStatusBadge_varHub on VarHub
      @refetchable(queryName: "VarStatusBadge_varHubQuery") {
        isOnline
      }
    `,
    varHub
  );

  const { isOnline } = data;

  useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (!isOnline) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetch({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 10000));
        }, 10000)
      );
    }
    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [isOnline, refetch]);

  return <Root active={isOnline}>{isOnline ? "ONLINE" : "OFFLINE"}</Root>;
};

export const CameraStatusBadge = ({ status }: { status: boolean | null }) => {
  return (
    <Root active={status}>
      {status === null ? "UNKNOWN" : status ? "ONLINE" : "OFFLINE"}
    </Root>
  );
};

const Puck = styled.div<{ active: boolean | null; pending?: boolean | null }>`
  background-color: ${(props) =>
    props.pending
      ? "grey"
      : props.active === null
      ? DaColors.Caution500
      : props.active
      ? DaColors.Success500
      : DaColors.Failure500};
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.8rem;
  color: ${(props) => (props.active ? "white" : "white")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
  flex-shrink: 0;
`;

const PuckWithActivationStatus = styled.div<{
  onlineStatus: boolean | null;
  isConfirmedStatus: boolean | null;
  isStatusActivated: boolean | null;
}>`
  background-color: ${(props) => {
    switch (true) {
      case props.onlineStatus === true &&
        props.isStatusActivated === true &&
        props.isConfirmedStatus === true:
        return DaColors.Success500;
      case props.onlineStatus === true &&
        (props.isStatusActivated === false ||
          props.isConfirmedStatus === false):
        return DaColors.Caution500;
      default:
        return DaColors.Failure500;
    }
  }};
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.8rem;
  color: "white";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
  flex-shrink: 0;
`;

export const VarStatusPuck = ({
  className,
  status,
  isPreProgram,
}: {
  className?: string;
  status: boolean;
  isPreProgram?: boolean | null;
}) => {
  return (
    <Puck
      className={className}
      active={status}
      title={isPreProgram ? "PENDING" : status ? "ONLINE" : "OFFLINE"}
      pending={isPreProgram ?? false}
    ></Puck>
  );
};

export const CameraStatusPuck = ({
  status,
  lastCheckIn,
}: {
  status: boolean | null;
  lastCheckIn?: string;
}) => {
  const titleInfo = () => {
    if (status === null) {
      return `UNKNOWN ${
        lastCheckIn ? `\nLast Check In: ${formatDateString(lastCheckIn)}` : ""
      }`;
    } else if (status) {
      return `ONLINE ${
        lastCheckIn ? `\nLast Check In: ${formatDateString(lastCheckIn)}` : ""
      }`;
    } else {
      return `OFFLINE ${
        lastCheckIn ? `\nLast Check In: ${formatDateString(lastCheckIn)}` : ""
      }`;
    }
  };
  return <Puck active={status} title={titleInfo()}></Puck>;
};

const isStatusActivated = (activationStatus: string): boolean | null => {
  return activationStatus
    ? [VideoDeviceStatus.ACTIVE].includes(activationStatus as VideoDeviceStatus)
    : null;
};

/**
 *
 * @param confirmationStatus
 * @returns true for VideoDeviceSettingConfirmation send, confirmed and pending statuses
 */
const isConfirmedStatus = (
  confirmationStatus: string | undefined
): boolean | null => {
  return confirmationStatus
    ? [
        VideoDeviceSettingConfirmation.SENT,
        VideoDeviceSettingConfirmation.CONFIRMED,
        VideoDeviceSettingConfirmation.PENDING,
      ].includes(confirmationStatus as VideoDeviceSettingConfirmation)
    : null;
};

export const CameraStatusPuckWithActivationStatus = ({
  activationStatus,
  onlineStatus,
  settingConfirmationStatus,
  lastCheckIn,
}: {
  activationStatus: VideoDeviceStatus;
  onlineStatus: boolean | null;
  settingConfirmationStatus?: VideoDeviceSettingConfirmation;
  lastCheckIn?: string;
}) => {
  const titleInfo = () => {
    // first gate the onlineStatus
    if (onlineStatus) {
      // switch on the activationStatus
      switch (activationStatus) {
        case VideoDeviceStatus.ACTIVE: {
          // as long as it is active we need to then switch on the settingConfirmationStatus (if included)  If we have no info or the confirmation status is Sent/Confirmed we will show Online as the status
          switch (settingConfirmationStatus) {
            case VideoDeviceSettingConfirmation.PENDING:
              return `PENDING Sending Programming ${
                lastCheckIn
                  ? `\nLast Check In: ${formatDateString(lastCheckIn)}`
                  : ""
              }`;
            case VideoDeviceSettingConfirmation.CONFIRMATION_ERROR:
            case VideoDeviceSettingConfirmation.TIMEOUT:
            case VideoDeviceSettingConfirmation.API_ERROR:
              return `Camera not in Sync. Select camera and save to resolve. ${
                lastCheckIn
                  ? `\nLast Check In: ${formatDateString(lastCheckIn)}`
                  : ""
              }`;
            case VideoDeviceSettingConfirmation.SENT:
            case VideoDeviceSettingConfirmation.CONFIRMED:
            default:
              return `ONLINE ${
                lastCheckIn
                  ? `\nLast Check In: ${formatDateString(lastCheckIn)}`
                  : ""
              }`;
          }
        }
        case VideoDeviceStatus.ACTIVATING:
          return VideoDeviceStatus.ACTIVATING;
        default:
          return `ACTIVATION ERROR ${
            lastCheckIn
              ? `\nLast Check In: ${formatDateString(lastCheckIn)}`
              : ""
          }`;
      }
      //onlineStatus is false
    } else {
      return `CHECK IN FAILED ${
        lastCheckIn ? `\nLast Check In: ${formatDateString(lastCheckIn)}` : ""
      }`;
    }
  };

  return (
    <PuckWithActivationStatus
      onlineStatus={onlineStatus}
      isStatusActivated={isStatusActivated(activationStatus)}
      isConfirmedStatus={isConfirmedStatus(settingConfirmationStatus)}
      title={titleInfo()}
    ></PuckWithActivationStatus>
  );
};

export const VarStatusPuckWithAutoRefresh = ({
  varHub,
}: {
  varHub: VarStatusBadge_Puck_varHub$key;
}) => {
  const [data, refetch] = useRefetchableFragment<
    VarStatusBadge_Puck_varHubQuery,
    VarStatusBadge_Puck_varHub$key
  >(
    graphql`
      fragment VarStatusBadge_Puck_varHub on VarHub
      @refetchable(queryName: "VarStatusBadge_Puck_varHubQuery") {
        isOnline
      }
    `,
    varHub
  );

  const { isOnline } = data;

  useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (!isOnline) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetch({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 20000));
        }, 10000)
      );
    }
    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [isOnline, refetch]);

  return (
    <Puck active={isOnline} title={isOnline ? "ONLINE" : "OFFLINE"}></Puck>
  );
};

export default VarStatusBadge;
