/**
 * @ngdoc service
 * @name App.factory:ModemUpgradeCustomersService
 *
 * @description
 * Holds the API and methods for the Modem Upgrade Customers
 *
 */
App.factory("ModemUpgradeUnavailableDatesService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "ModemUpgradeUnavailableDatesAPI",
  "UserService",
  "IpResolverService",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    ModemUpgradeUnavailableDatesAPI,
    UserService,
    IpResolverService
  ) {
    return {
      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeSettingsService
       *
       * @description
       * Get array of Id, DealerId, and UnavailableDate.
       *
       * @param {Promise} data- promise response
       */
      getModemUpgradeUnavailableDates: function (dealer_id) {
        var deferred = $q.defer();
        ModemUpgradeUnavailableDatesAPI.getModemUpgradeUnavailableDates(
          {
            dealer_id: dealer_id,
          }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeSettingsService
       *
       * @description
       * Delete and recreate new list of dates.
       *
       * @param {Promise} data- promise response
       */
      batchCreateModemUpgradeUnavailableDates: function (
        upgradeCustomers,
        DealerId
      ) {
        var deferred = $q.defer();

        ModemUpgradeUnavailableDatesAPI.batchCreateModemUpgradeUnavailableDates(
          {
            dealer_id: DealerId,
          }, //params

          upgradeCustomers,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
