import { MessagePosition } from "common/components/web/Tooltip";
import Tooltip from "components/Tooltip";
import React from "react";
import styled from "styled-components";
import DiagnosticNameValueTable from "./DiagnosticNameValueTable";
import { PanelSearchResult } from "./utils";

const TooltipText = styled.p`
  padding: 0;
  margin: 1rem;
  font-size: 1.2rem;
`;
const InfoTitleContainer = styled.div`
  float: right;
  margin: 1rem;
  & > div {
    display: inline !important;
    margin-left: 20px;
  }
`;
function copyAll(result: PanelSearchResult) {
  const padNum = 25;
  const copyText =
    "System Name:".padEnd(padNum - 1) +
    (result.controlSystemName ? result.controlSystemName : "-") +
    "\nAccount Number:".padEnd(padNum) +
    (result.accountNumber ? result.accountNumber : "-") +
    "\nControl System ID:".padEnd(padNum) +
    (result.controlSystemId ? result.controlSystemId : "-") +
    "\nSerial Number:".padEnd(padNum) +
    (result.panelSerialNumber ? result.panelSerialNumber : "-") +
    "\nCustomer ID:".padEnd(padNum) +
    (result.customerId ? result.customerId : "-") +
    "\nSIM Identifier:".padEnd(padNum) +
    (result.simIdentifier ? result.simIdentifier : "-") +
    "\nComm Address:".padEnd(padNum) +
    (result.panelCommAddress ? result.panelCommAddress : "-") +
    "\nComm Type:".padEnd(padNum) +
    (result.commType ? result.commType : "-") +
    "\nSoftware Version:".padEnd(padNum) +
    (result.panelSoftwareVersion
      ? `${result.panelSoftwareVersion} (${result.panelSoftwareDate})`
      : "-") +
    "\nHardware Model:".padEnd(padNum) +
    (result.hardwareModel ? result.hardwareModel : "-") +
    "\nArming System:".padEnd(padNum) +
    (result.panelArmingSystem ? result.panelArmingSystem : "-") +
    "\n";

  return copyText;
}
function CopyButton({
  text,
  children,
  id,
}: {
  text: string | undefined;
  children: JSX.Element | string;
  id: string;
}) {
  const [buttonIsHovered, setButtonIsHovered] = React.useState(false);
  const [buttonIsClicked, setButtonIsClicked] = React.useState(false);

  return (
    <Tooltip
      open={buttonIsHovered}
      position={MessagePosition.Top}
      zIndex={1000}
      anchor={({ registerAnchor }) => (
        <button
          id={id}
          ref={registerAnchor as any}
          className="link link-primary color-primary-600"
          style={{ textDecoration: "none" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (text) navigator.clipboard.writeText(text);
            setButtonIsClicked(true);
          }}
          onMouseEnter={() => setButtonIsHovered(true)}
          onMouseLeave={() => {
            setButtonIsHovered(false);
            setButtonIsClicked(false);
          }}
        >
          {children}
        </button>
      )}
    >
      <TooltipText>
        {buttonIsClicked ? "Copied" : "Copy to clipboard"}
      </TooltipText>
    </Tooltip>
  );
}

function SystemInformation({
  selectedResult,
}: {
  selectedResult: PanelSearchResult;
}) {
  const data = [
    {
      name: "System Name",
      value: selectedResult.controlSystemName || "-",
    },
    {
      name: "Account Number",
      value: selectedResult.accountNumber || "-",
    },
    {
      name: "Control System ID",
      value: selectedResult.controlSystemId || "-",
    },
    {
      name: "Serial Number",
      value: selectedResult.panelSerialNumber || "-",
    },
    {
      name: "Customer ID",
      value: selectedResult.customerId || "-",
    },
    {
      name: "SIM Identifier",
      value: selectedResult.simIdentifier || "-",
    },
    {
      name: "Comm Address",
      value: selectedResult.panelCommAddress || "-",
    },
    {
      name: "Comm Type",
      value: selectedResult.commType || "-",
    },
    {
      name: "Software Version",
      value: `${selectedResult.panelSoftwareVersion} (${selectedResult.panelSoftwareDate})`,
    },
    {
      name: "Hardware Model",
      value: selectedResult.hardwareModel || "-",
    },
    {
      name: "Arming System",
      value: selectedResult.panelArmingSystem || "-",
    },
  ];
  return (
    <div>
      <InfoTitleContainer>
        <button
          style={{ textDecoration: "none" }}
          className="btn btn-primary btn-sm"
          onClick={() =>
            window.open(
              `/#/app/customers/${selectedResult.customerId}/control_systems/${selectedResult.controlSystemId}`
            )
          }
        >
          Go to System Information Page
        </button>
        <CopyButton
          text={copyAll(selectedResult)}
          id={selectedResult.controlSystemId + "copyButton"}
        >
          <i
            style={{ fontSize: "1.8em", verticalAlign: "middle" }}
            className="icon-doc"
          ></i>
        </CopyButton>
      </InfoTitleContainer>
      <DiagnosticNameValueTable data={data} />
    </div>
  );
}

export default SystemInformation;
