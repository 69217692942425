/**
 * @generated SignedSource<<6763cb13449b0730cf3cd0063191d8ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagFieldValueQuery$variables = {
  typeId: string;
};
export type TagFieldValueQuery$data = {
  readonly getTagsByType: {
    readonly tags: ReadonlyArray<{
      readonly id: string;
      readonly isFixed: boolean | null;
      readonly label: string | null;
      readonly value: string | null;
    } | null>;
  };
};
export type TagFieldValueQuery = {
  response: TagFieldValueQuery$data;
  variables: TagFieldValueQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "typeId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "typeId"
      }
    ],
    "concreteType": "TagFieldValue",
    "kind": "LinkedField",
    "name": "getTagsByType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFixed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagFieldValueQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagFieldValueQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c16346dd2552489112f96968a834a4f2",
    "id": null,
    "metadata": {},
    "name": "TagFieldValueQuery",
    "operationKind": "query",
    "text": "query TagFieldValueQuery(\n  $typeId: ID!\n) {\n  getTagsByType(id: $typeId) {\n    tags {\n      id\n      isFixed\n      label\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "94c734b30b7d428d0ee11d3730ad7270";

export default node;
