/**
 * @generated SignedSource<<71b3af0d56f205737fac8ea58b24f768>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsInstantArmingField_systemOptions$data = {
  readonly instantArming?: boolean;
  readonly " $fragmentType": "SystemOptionsInstantArmingField_systemOptions";
};
export type SystemOptionsInstantArmingField_systemOptions$key = {
  readonly " $data"?: SystemOptionsInstantArmingField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsInstantArmingField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "instantArming",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsInstantArmingField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "cd7bab015c7f37c07812e5c32b340b97";

export default node;
