import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment, useMutation } from "react-relay/hooks";
import { SecureComVideoLevel } from "securecom-graphql/client";
import { Sheet } from "../Layout/Layout";
import VideoSelection from "./VideoForm/Components/VideoSelection";
import SecureComCameras from "./VideoForm/SecureComCameras";
import SecureComNvrs from "./VideoForm/SecureComNvrs";
import { SecureComVideoForm_site$key } from "./__generated__/SecureComVideoForm_site.graphql";

type Props = {
  siteRef: SecureComVideoForm_site$key;
};

export default function SecureComVideoForm({ siteRef }: Props) {
  const data = useFragment(
    graphql`
      fragment SecureComVideoForm_site on Site {
        id
        securecomVideoLevel
        securecomNvrEnabled
        ...SecureComCameras_site
        ...SecureComNvrs_site
      }
    `,
    siteRef
  );

  const [setVideoLevel] = useMutation(graphql`
    mutation SecureComVideoFormSetVideoLevelMutation(
      $siteId: ID!
      $videoLevel: SecureComVideoLevel!
    ) {
      setSecureComVideoLevelForSite(siteId: $siteId, videoLevel: $videoLevel) {
        ... on Site {
          ...SecureComVideoForm_site
        }
      }
    }
  `);

  return (
    <Sheet>
      <VideoSelection>
        <VideoSelection.Description title={<div>Cameras &amp; NVR</div>} />
        <div className="feature__read-only-data drop-in-and-expand-ngif">
          <div className="form-inline">
            <select
              className="form-control input-sm"
              value={data.securecomVideoLevel}
              onChange={({ target }) => {
                const videoLevel = target.value;
                setVideoLevel({
                  variables: {
                    siteId: data.id,
                    videoLevel,
                  },
                  optimisticUpdater: (store) => {
                    const siteRecord = store.get(data.id);
                    if (siteRecord) {
                      siteRecord.setValue(videoLevel, "securecomVideoLevel");
                    }
                  },
                  // TODO: Handle edge case error
                });
              }}
            >
              <option value={SecureComVideoLevel.NONE} disabled hidden>
                Choose number of cameras
              </option>
              <option value={SecureComVideoLevel.STANDARD}>
                8 Camera/250MB Storage (included)
              </option>
              <option value={SecureComVideoLevel.STANDARD_PLUS_FOUR}>
                12 Camera/500MB Storage
              </option>
              <option value={SecureComVideoLevel.STANDARD_PLUS_EIGHT}>
                16 Camera/750MB Storage
              </option>
            </select>
          </div>
        </div>
      </VideoSelection>
      <VideoSelection.Section>
        <SecureComCameras siteRef={data} />
      </VideoSelection.Section>
      {data.securecomNvrEnabled && (
        <VideoSelection.Section>
          <SecureComNvrs siteRef={data} />
        </VideoSelection.Section>
      )}
    </Sheet>
  );
}
