import noop from "common/utils/universal/noop";
import * as React from "react";

type RoutingContextType = {
  go: (
    route: string,
    params: { [key: string]: string | number },
    options?: { reload?: boolean }
  ) => void;
  $stateParams: { [key: string]: string | number };
  cfpLoadingBar: { start: () => void; complete: () => void };
};

const RoutingContext = React.createContext<RoutingContextType>({
  go: noop,
  $stateParams: {},
  cfpLoadingBar: { start: noop, complete: noop },
});

type RoutingContextProviderProps = {
  children: React.ReactNode;
  $state: {
    go: (
      route: string,
      params: { [key: string]: string | number },
      options?: { reload?: boolean }
    ) => void;
  };
  $stateParams: { [key: string]: string | number };
  cfpLoadingBar: { start: () => void; complete: () => void };
};

export const RoutingContextProvider = ({
  children,
  $state,
  $stateParams,
  cfpLoadingBar,
}: RoutingContextProviderProps) => {
  return (
    <RoutingContext.Provider
      value={React.useMemo(
        () => ({
          go: (route, params, options) => $state.go(route, params, options),
          $stateParams: $stateParams,
          cfpLoadingBar: cfpLoadingBar,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [$state]
      )}
    >
      {children}
    </RoutingContext.Provider>
  );
};

export const useRoutingContext = () => React.useContext(RoutingContext);

export const useGoToRoute = () => useRoutingContext().go;
export const useGetStateParams = () => useRoutingContext().$stateParams;
export const useDaLoadingBar = () => useRoutingContext().cfpLoadingBar;
