/**
 * @generated SignedSource<<b3ceb11783d8652ecc2a884e7bf85948>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type RecorderTableQuery$variables = {
  systemId: string;
};
export type RecorderTableQuery$data = {
  readonly node: {
    readonly __typename: string;
    readonly id: string;
    readonly panel?: {
      readonly hardwareModel: PanelHardwareModel;
      readonly softwareVersion: string;
    };
    readonly varHubs?: ReadonlyArray<{
      readonly __typename: "VarHub";
      readonly cameras: ReadonlyArray<{
        readonly addedToDB: boolean;
        readonly camectCamId: string;
        readonly cameraName: string;
        readonly framesPerSecond: number;
        readonly height: number;
        readonly hubId: number;
        readonly id: string;
        readonly ipAddress: string;
        readonly isHidden: boolean;
        readonly isStreaming: boolean;
        readonly make: string | null;
        readonly manuallyAdded: boolean;
        readonly megapixels: number;
        readonly videoCodec: string;
        readonly width: number;
      }>;
      readonly hardwareModel: string;
      readonly hardwareModelDisplayName: string;
      readonly hubId: number;
      readonly hubName: string;
      readonly id: string;
      readonly isOnline: boolean;
      readonly macAddress: string;
      readonly megapixelThresholdCritical: boolean;
      readonly megapixelThresholdWarning: boolean;
      readonly panelConnectionEnabled: boolean | null;
      readonly supportedMegapixels: number;
      readonly systemId: number;
      readonly " $fragmentSpreads": FragmentRefs<"PanelConnectionBadge_varHub" | "RecorderAddCameraModal_varHub" | "VarStatusBadge_Puck_varHub">;
    }>;
  } | null;
};
export type RecorderTableQuery = {
  response: RecorderTableQuery$data;
  variables: RecorderTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "systemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareModel",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareVersion",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOnline",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelConnectionEnabled",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareModelDisplayName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixelThresholdWarning",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixelThresholdCritical",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "supportedMegapixels",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedToDB",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectCamId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipAddress",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStreaming",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "make",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "megapixels",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoCodec",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "framesPerSecond",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manuallyAdded",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHidden",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectHubId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "varHubs",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RecorderAddCameraModal_varHub"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PanelConnectionBadge_varHub"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "VarStatusBadge_Puck_varHub"
                  },
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v4/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHubCamera",
                    "kind": "LinkedField",
                    "name": "cameras",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v6/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "varHubs",
                "plural": true,
                "selections": [
                  (v29/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disableAutoAddCameras",
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unsupportedFrameRate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unsupportedCodec",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHubCamera",
                    "kind": "LinkedField",
                    "name": "cameras",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cameraId",
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isScapiCamera",
                        "storageKey": null
                      },
                      (v21/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "needsCredential",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "playerAuthToken",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "playerUrl",
                        "storageKey": null
                      },
                      (v23/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scapiCameraId",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v19/*: any*/),
                      (v6/*: any*/),
                      (v22/*: any*/),
                      (v24/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "panelConnectionStatus",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/),
                  (v4/*: any*/),
                  (v12/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2e87a4d174ebbdb756864b8f31b9259",
    "id": null,
    "metadata": {},
    "name": "RecorderTableQuery",
    "operationKind": "query",
    "text": "query RecorderTableQuery(\n  $systemId: ID!\n) {\n  node(id: $systemId) {\n    id\n    __typename\n    ... on ControlSystem {\n      id\n      panel {\n        hardwareModel\n        softwareVersion\n        id\n      }\n      varHubs {\n        ...RecorderAddCameraModal_varHub\n        ...PanelConnectionBadge_varHub\n        ...VarStatusBadge_Puck_varHub\n        id\n        hubId\n        systemId\n        hubName\n        macAddress\n        isOnline\n        panelConnectionEnabled\n        hardwareModel\n        hardwareModelDisplayName\n        megapixelThresholdWarning\n        megapixelThresholdCritical\n        supportedMegapixels\n        cameras {\n          id\n          addedToDB\n          camectCamId\n          cameraName\n          height\n          hubId\n          ipAddress\n          isStreaming\n          make\n          megapixels\n          width\n          videoCodec\n          framesPerSecond\n          manuallyAdded\n          isHidden\n        }\n        __typename\n      }\n    }\n  }\n}\n\nfragment PanelConnectionBadge_varHub on VarHub {\n  panelConnectionStatus\n  isOnline\n  id\n}\n\nfragment RecorderAddCameraModal_varHub on VarHub {\n  camectHubId\n  hubId\n  hubName\n  disableAutoAddCameras\n  megapixelThresholdWarning\n  megapixelThresholdCritical\n  supportedMegapixels\n  unsupportedFrameRate\n  unsupportedCodec\n  cameras {\n    addedToDB\n    camectCamId\n    camectHubId\n    cameraId\n    cameraName\n    ipAddress\n    isEnabled\n    isScapiCamera\n    isStreaming\n    macAddress\n    needsCredential\n    playerAuthToken\n    playerUrl\n    megapixels\n    videoCodec\n    framesPerSecond\n    manuallyAdded\n    isHidden\n    scapiCameraId\n    id\n  }\n}\n\nfragment VarStatusBadge_Puck_varHub on VarHub {\n  isOnline\n  id\n}\n"
  }
};
})();

(node as any).hash = "b893e5b45dae4191bfe6ecb082410eae";

export default node;
