/**
 * @generated SignedSource<<04f64814fffc142f49d674c636b997a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HolidayDateClass = "A" | "B" | "C";
export type MassProgrammingGlobalHolidayDatesJobNewDealerQuery$variables = {
  dealerId: string;
};
export type MassProgrammingGlobalHolidayDatesJobNewDealerQuery$data = {
  readonly dealer: {
    readonly globalHolidayDates: ReadonlyArray<{
      readonly clazz: HolidayDateClass;
      readonly date: string;
      readonly description: string;
      readonly id: string;
      readonly name: string;
      readonly scapiId: number;
    }>;
  } | null;
};
export type MassProgrammingGlobalHolidayDatesJobNewDealerQuery = {
  response: MassProgrammingGlobalHolidayDatesJobNewDealerQuery$data;
  variables: MassProgrammingGlobalHolidayDatesJobNewDealerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "GlobalHolidayDate",
  "kind": "LinkedField",
  "name": "globalHolidayDates",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scapiId",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clazz",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MassProgrammingGlobalHolidayDatesJobNewDealerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MassProgrammingGlobalHolidayDatesJobNewDealerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7dda4609edb9d180d523e3df5fa2515a",
    "id": null,
    "metadata": {},
    "name": "MassProgrammingGlobalHolidayDatesJobNewDealerQuery",
    "operationKind": "query",
    "text": "query MassProgrammingGlobalHolidayDatesJobNewDealerQuery(\n  $dealerId: ID!\n) {\n  dealer(id: $dealerId) {\n    globalHolidayDates {\n      scapiId\n      id\n      name\n      date\n      description\n      clazz\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e7c83dced89a26dc7d6c9076abcb820";

export default node;
