/**
 * @generated SignedSource<<22b1f47a3663e0a3ec45edb4bae11b27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Area_area$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ArmingSlider_area">;
  readonly " $fragmentType": "Area_area";
};
export type Area_area$key = {
  readonly " $data"?: Area_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"Area_area">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Area_area",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArmingSlider_area"
    }
  ],
  "type": "Area",
  "abstractKey": "__isArea"
};

(node as any).hash = "7d24a1cfd91bdbf116057d43a48f943f";

export default node;
