/**
 * @generated SignedSource<<7ee3c147847fb6650d01d1cf5d17ac35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathDoorAccessReports = "DENY" | "NO" | "YES";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
import { FragmentRefs } from "relay-runtime";
export type CommPathDoorAccessReportsField_communicationPath$data = {
  readonly doorAccessReports: CommunicationPathDoorAccessReports;
  readonly number: number;
  readonly type: CommunicationPathType;
  readonly " $fragmentType": "CommPathDoorAccessReportsField_communicationPath";
};
export type CommPathDoorAccessReportsField_communicationPath$key = {
  readonly " $data"?: CommPathDoorAccessReportsField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathDoorAccessReportsField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathDoorAccessReportsField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doorAccessReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "ce0c53009e334556e00321978f21c860";

export default node;
