import DaColors from "app/common/DaColors";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.span`
  position: relative;
  display: grid;
  height: 3rem;
  width: max-content;
  cursor: pointer;
  user-select: none;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-radius: 0.3rem;
  border: 0.2rem solid ${DaColors.Primary500};
  background-color: ${DaColors.White};
  padding: 0.3rem 0;
  font-size: 1.4rem;
  line-height: 2rem;
`;
const Slider = styled.span<{ isOn: boolean }>`
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: ${DaColors.Primary500};
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: ${(props) => (props.isOn ? "translateX(100%)" : "none")};
  border-radius: ${(props) =>
    props.isOn ? "0.3rem 0 0 0.3rem" : "0 0.3rem 0.3rem 0"};
`;
const Side = styled.span<{ isActive: boolean }>`
  z-index: 10;
  padding: 0 1.6rem;
  text-align: center;
  font-weight: ${(props) => (props.isActive ? "bold" : "inherit")};
  color: ${(props) => (props.isActive ? DaColors.White : "inherit")};
`;
const HiddenWidthPlaceholder = styled.div`
  visibility: hidden;
  height: 0px;
  font-weight: bold;
`;

type Props = {
  offLabel: string | ReactNode;
  onLabel: string | ReactNode;
  isOn: boolean;
  onToggle: (isOn: boolean) => void;
};

// To base the width of the button on the width of the labels, and have both sides be the same
// width, ToggleButton uses grid. But since we are switching between normal and bold font weights
// depending on which side is active, the width of the wider side changes, which makes the whole
// button's width change, and it looks jumpy. So we're putting an invisible, zero-height div on
// each side, with the bold version of the label, so that each side will always be wide enough to
// contain the bold text.
const ToggleButton = ({ offLabel, onLabel, isOn, onToggle }: Props) => {
  return (
    <Wrapper>
      <Slider isOn={isOn} />
      <Side isActive={!isOn} onClick={() => isOn && onToggle(false)}>
        <HiddenWidthPlaceholder>{offLabel}</HiddenWidthPlaceholder>
        {offLabel}
      </Side>
      <Side isActive={isOn} onClick={() => !isOn && onToggle(true)}>
        <HiddenWidthPlaceholder>{onLabel}</HiddenWidthPlaceholder>
        {onLabel}
      </Side>
    </Wrapper>
  );
};

export default ToggleButton;
