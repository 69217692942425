import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from ".././ProgrammingConceptForm";
import { useOriginalControlSystemData } from "../OriginalControlSystemContext";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { useDnsIsEditableWithDhcpEnabled } from "./NetworkOptionsDnsServerField";
import { NetworkOptionsDhcpEnabledField_controlSystem$key } from "./__generated__/NetworkOptionsDhcpEnabledField_controlSystem.graphql";
import { NetworkOptionsDhcpEnabledField_networkOptions$key } from "./__generated__/NetworkOptionsDhcpEnabledField_networkOptions.graphql";

export const networkOptionsDhcpEnabledFieldId = () =>
  "network-options-dhcp-enabled";

function NetworkOptionsDhcpEnabledField() {
  const [
    {
      dhcpEnabled,
      ipv6Enabled,
      localIpAddress,
      subnetMask,
      gatewayAddress,
      dnsServer,
    },
    updateNetworkOptions,
  ] = useNetworkOptionsFragment<NetworkOptionsDhcpEnabledField_networkOptions$key>(
    graphql`
      fragment NetworkOptionsDhcpEnabledField_networkOptions on NetworkOptions {
        dhcpEnabled
        ipv6Enabled
        localIpAddress
        subnetMask
        gatewayAddress
        dnsServer
      }
    `
  );

  const fieldId = networkOptionsDhcpEnabledFieldId();
  const disabled = ipv6Enabled;
  const lookupOriginalData = useOriginalControlSystemData();
  const dnsIsEditableWithDhcpEnabled = useDnsIsEditableWithDhcpEnabled();
  const label = "DHCP";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="When set to YES, the panel operates using DHCP and does not use the Local IP Address number. 
      When the DHCP option is set to NO, the panel uses the IP address entered in Local IP Address."
    >
      <Switch
        label={label}
        id={fieldId}
        disabled={disabled}
        checked={dhcpEnabled}
        onChange={() => {
          const enabled = !dhcpEnabled;

          const {
            panel: { networkOptions: originalNetworkOptions },
          } = lookupOriginalData<NetworkOptionsDhcpEnabledField_controlSystem$key>(
            graphql`
              fragment NetworkOptionsDhcpEnabledField_controlSystem on ControlSystem {
                panel {
                  networkOptions {
                    localIpAddress
                    subnetMask
                    gatewayAddress
                    dnsServer
                  }
                }
              }
            `
          );

          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(enabled, "dhcpEnabled");

            if (
              enabled &&
              !localIpAddress &&
              originalNetworkOptions?.localIpAddress
            ) {
              recordProxy.setValue(
                originalNetworkOptions.localIpAddress,
                "localIpAddress"
              );
            }
            if (enabled && !subnetMask && originalNetworkOptions?.subnetMask) {
              recordProxy.setValue(
                originalNetworkOptions.subnetMask,
                "subnetMask"
              );
            }
            if (
              enabled &&
              !gatewayAddress &&
              originalNetworkOptions?.gatewayAddress
            ) {
              recordProxy.setValue(
                originalNetworkOptions.gatewayAddress,
                "gatewayAddress"
              );
            }
            if (
              enabled &&
              !dnsServer &&
              !dnsIsEditableWithDhcpEnabled &&
              originalNetworkOptions?.dnsServer
            ) {
              recordProxy.setValue(
                originalNetworkOptions.dnsServer,
                "dnsServer"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsDhcpEnabledField;
