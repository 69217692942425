/**
 * @generated SignedSource<<dedae84076899698203f212e61e220cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NetworkOptionsWifiSecurity = "NONE" | "UNSUPPORTED" | "WEP" | "WPA";
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly networkOptions: {
    readonly dhcpEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dnsServer: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly gatewayAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly ipv6Address: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Dns: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Enabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Gateway: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Prefix: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly listenPort734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly localIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly programmingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly subnetMask: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSecurity: {
      readonly data: NetworkOptionsWifiSecurity | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSsid: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TakeoverPanelNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TakeoverPanelNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TakeoverPanelNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "dbd38b338b4a7b15ce6c63c6f8475b76";

export default node;
