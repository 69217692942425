/**
 * @generated SignedSource<<16e8f404e92ad3eaa11ff75f63da7c48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTAreaInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly areas: {
      readonly __typename: "AreaConnection";
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "AreaEdge";
        readonly cursor: string | null;
        readonly node: {
          readonly __typename: string;
          readonly id: string;
          readonly isNew: boolean;
          readonly number: string;
          readonly " $fragmentSpreads": FragmentRefs<"XTAreaInformationProgrammingConceptFormInline_area">;
        } | null;
      }>;
    };
    readonly id: string;
  };
  readonly " $fragmentType": "XTAreaInformationProgrammingConceptFormInline_controlSystem";
};
export type XTAreaInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTAreaInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTAreaInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTAreaInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "6b8f8a2a8d65716929e9c480d02945c3";

export default node;
