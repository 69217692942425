import graphql from "babel-plugin-relay/macro";
import useToasts, { FADE_MS } from "hooks/use-toasts";
import React from "react";
import { createPortal } from "react-dom";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import { ToastsQuery } from "./__generated__/ToastsQuery.graphql";

const Wrapper = styled.div`
  transition opacity ${FADE_MS}ms ease;
`;

const Toasts = ({ environment }: any) => {
  const [_, deleteToast] = useToasts();

  const data = useLazyLoadQuery<ToastsQuery>(
    graphql`
      query ToastsQuery {
        myUser {
          toasts {
            id
            type
            text
            isVisible
          }
        }
      }
    `,
    {}
  );

  const portalTarget = document.getElementById("toast-container");

  return (
    <>
      {portalTarget &&
        createPortal(
          <>
            {data?.myUser?.toasts?.map((toast) => (
              <Wrapper
                key={toast.id}
                className={`toast toast-${toast.type}`}
                style={{ opacity: toast.isVisible ? 1 : 0 }}
                onClick={() => deleteToast(toast.id)}
              >
                <button className="toast-close-button">
                  <i className="icon-close_cancel icon-1x"></i>
                </button>
                <div className="toast-title">Attention!</div>
                <div className="toast-message">{toast.text}</div>
              </Wrapper>
            ))}
          </>,
          portalTarget
        )}
    </>
  );
};

export default Toasts;
