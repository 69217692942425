import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationCheckInMinutesField_communication$key } from "./__generated__/CommunicationCheckInMinutesField_communication.graphql";

export const communicationCheckInMinutesFieldId = () =>
  "communication-check-in-minutes";

function CommunicationCheckInMinutesField() {
  const [
    { comType, checkInMinutes, checkInMinutesValidValues },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationCheckInMinutesField_communication$key>(
    graphql`
      fragment CommunicationCheckInMinutesField_communication on Communication {
        comType
        checkInMinutes
        checkInMinutesValidValues
        failTime
      }
    `
  );

  const fieldId = communicationCheckInMinutesFieldId();
  const softwareVersion = useSoftwareVersion();
  const originalValue = React.useRef(checkInMinutes).current;
  const disabled = ["DD", "CID", "NONE"].includes(comType ?? "");

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={"Check-in Minutes"}
      tooltip="Number of minutes the System waits between sending Check-In messages to the Receiver."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={checkInMinutes}
        required
        disabled={disabled}
        inlineHelp={Number(softwareVersion) < 112 ? `0,15-240` : `0,3-240`}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "checkInMinutes"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : closest(valueAsNumber, checkInMinutesValidValues);
            recordProxy.setValue(Number(value), "checkInMinutes");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationCheckInMinutesField;
