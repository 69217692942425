import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  PanelProgrammingLanguage,
  SystemOptions,
} from "securecom-graphql/client";
import {
  ControlSystemContextProvider,
  useControlSystemFragment,
} from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { PanelContextProvider } from "../common/PanelContext";
import ProgrammingConceptForm from "../common/ProgrammingConceptForm";
import { SystemOptionsContextProvider } from "../common/SystemOptionsFields/SystemOptionsContext";
import SystemOptionsCrossZoneTimeField from "../common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import SystemOptionsDetectWirelessJammingField from "../common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import SystemOptionsHoursFromGMTField from "../common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import SystemOptionsInspectionReminderField from "../common/SystemOptionsFields/SystemOptionsInspectionReminderField";
import SystemOptionsLatchedSupervisoryZonesField from "../common/SystemOptionsFields/SystemOptionsLatchedSupervisoryZonesField";
import SystemOptionsPowerFailDelayField from "../common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import SystemOptionsPrimaryProgrammingLanguageField from "../common/SystemOptionsFields/SystemOptionsPrimaryProgrammingLanguageField";
import SystemOptionsPrimaryUserLanguageField from "../common/SystemOptionsFields/SystemOptionsPrimaryUserLanguageField";
import SystemOptionsSecondaryProgrammingLanguageField from "../common/SystemOptionsFields/SystemOptionsSecondaryProgrammingLanguageField";
import SystemOptionsSecondaryUserLanguageField from "../common/SystemOptionsFields/SystemOptionsSecondaryUserLanguageField";
import SystemOptionsSwingerBypassTripsField from "../common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import SystemOptionsTimeChangeField from "../common/SystemOptionsFields/SystemOptionsTimeChangeField";
import SystemOptionsWirelessAudiblesField from "../common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import SystemOptionsWirelessEncryption1100Field from "../common/SystemOptionsFields/SystemOptionsWirelessEncryption1100Field";
import SystemOptionsWirelessEncryption1100PassphraseField from "../common/SystemOptionsFields/SystemOptionsWirelessEncryption1100PassphraseField";
import SystemOptionsWirelessHouseCodeField from "../common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import ZoneRetardDelayField from "../common/SystemOptionsFields/SystemOptionsZoneRetardDelayField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XFSystemOptionsProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XFSystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XFSystemOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFSystemOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XFSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation,
} from "./__generated__/XFSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation.graphql";
import sendMutationConcreteRequest, {
  XFSystemOptionsProgrammingConceptFormSendSystemOptionsMutation,
  XFSystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
} from "./__generated__/XFSystemOptionsProgrammingConceptFormSendSystemOptionsMutation.graphql";
import { XFSystemOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XFSystemOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "System Options";
export const conceptId = "xf-system-options";

export const getState = (
  controlSystem: XFSystemOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFSystemOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          systemOptions {
            __typename
            id
            ... on XfSystemOptions {
              primaryProgrammingLanguage
              secondaryProgrammingLanguage
              primaryUserLanguage
              secondaryUserLanguage
              swingerBypassTrips
              crossZoneTime
              zoneRetardDelay
              powerFailDelay
              timeChange
              hoursFromGMT
              latchedSupervisoryZones
              houseCode
              detectJam
              inspectionReminder
              wirelessAudibles
              wirelessEncryption1100
              wirelessEncryption1100Passphrase
            }
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XFSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation(
    $systemId: ID!
  ) {
    refreshSystemOptions(systemId: $systemId) {
      ... on RefreshSystemOptionsSuccessResponse {
        __typename
        controlSystem {
          ...XFSystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XFSystemOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XFSystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            systemId: id,
          },
          onCompleted: (response) => {
            const { controlSystem, type } = response.refreshSystemOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "System Options Programming Retrieved From The System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSystemOptions: {
                    __typename: response.refreshSystemOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (type) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve System Options: ${hyphenScoreToTitleCase(
                      type
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve System Options",
                  });
                }
              }
              reject(type);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XFSystemOptionsProgrammingConceptFormSendSystemOptionsMutation(
    $systemId: ID!
    $xfSystemOptionsInput: XfSystemOptionsInput!
  ) {
    sendXfSystemOptionsProgramming(
      systemId: $systemId
      xfSystemOptionsInput: $xfSystemOptionsInput
    ) {
      ... on SendSystemOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...XFSystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSystemOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XFSystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXfSystemOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(sendMutationConcreteRequest, {
      id: response.sendXfSystemOptionsProgramming.controlSystem.id,
    });

    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        sendXtSystemOptionsProgramming: {
          __typename: response.sendXfSystemOptionsProgramming.__typename,
          controlSystem: getState(
            response.sendXfSystemOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFSystemOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XFSystemOptionsProgrammingConceptFormSendSystemOptionsMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise(async (resolve, reject) => {
        const {
          id: systemId,
          panel: { systemOptions },
        } = getState(props.controlSystem);

        if (systemOptions) {
          save({
            variables: {
              systemId,
              xfSystemOptionsInput: {
                crossZoneTime: systemOptions.crossZoneTime,
                powerFailDelay: systemOptions.powerFailDelay,
                timeChange: systemOptions.timeChange,
                hoursFromGMT: systemOptions.hoursFromGMT,
                houseCode: systemOptions.houseCode,
                detectJam: systemOptions.detectJam,
                inspectionReminder: systemOptions.inspectionReminder,
                wirelessAudibles: systemOptions.wirelessAudibles,
                primaryProgrammingLanguage:
                  systemOptions.primaryProgrammingLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                secondaryProgrammingLanguage:
                  systemOptions.secondaryProgrammingLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                primaryUserLanguage:
                  systemOptions.primaryUserLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                secondaryUserLanguage:
                  systemOptions.secondaryUserLanguage ??
                  PanelProgrammingLanguage.ENGLISH,
                wirelessEncryption1100: systemOptions.wirelessEncryption1100,
                wirelessEncryption1100Passphrase:
                  systemOptions.wirelessEncryption1100Passphrase,
                zoneRetardDelay: systemOptions.zoneRetardDelay,
                latchedSupervisoryZones: systemOptions.latchedSupervisoryZones,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXfSystemOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);

                updateOriginalControlSystem(response, parentRelayEnv);
              } else if (response.sendXfSystemOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXfSystemOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readSystemOptionsTemplateData = (
  programmingTemplateConcepts: XFSystemOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFSystemOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          timeChange {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          houseCode {
            included
            data
          }
          inspectionReminder {
            included
            data
          }
          detectJam {
            included
            data
          }
          latchedSupervisoryZones {
            included
            data
          }
          primaryProgrammingLanguage {
            included
            data
          }
          secondaryProgrammingLanguage {
            included
            data
          }
          primaryUserLanguage {
            included
            data
          }
          secondaryUserLanguage {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          zoneRetardDelay {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).systemOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XFSystemOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSystemOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const systemOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "systemOptions",
      "SystemOptions"
    ) as unknown as RecordProxy<SystemOptions>;

    applyTemplateScalarDataToRecordProxy(
      systemOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFSystemOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFSystemOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          __typename
          id
          panel {
            ...SystemOptionsSystemTypeField_panel
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseSoftwareVersion_panel
            systemOptions {
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsPrimaryProgrammingLanguageField_systemOptions
              ...SystemOptionsSecondaryProgrammingLanguageField_systemOptions
              ...SystemOptionsPrimaryUserLanguageField_systemOptions
              ...SystemOptionsSecondaryUserLanguageField_systemOptions
              ...SystemOptionsCrossZoneTimeField_systemOptions
              ...SystemOptionsZoneRetardDelayField_systemOptions
              ...SystemOptionsPowerFailDelayField_systemOptions
              ...SystemOptionsSwingerBypassTripsField_systemOptions
              ...SystemOptionsTimeChangeField_systemOptions
              ...SystemOptionsHoursFromGMTField_systemOptions
              ...SystemOptionsLatchedSupervisoryZonesField_systemOptions
              ...SystemOptionsWirelessHouseCodeField_systemOptions
              ...SystemOptionsDetectWirelessJammingField_systemOptions
              ...SystemOptionsWirelessEncryption1100Field_systemOptions
              ...SystemOptionsWirelessEncryption1100PassphraseField_systemOptions
              ...SystemOptionsWirelessAudiblesField_systemOptions
              ...SystemOptionsInspectionReminderField_systemOptions
            }
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem
        }
      `
    );

  const {
    panel: {
      systemOptions,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const softwareVersion = Number(controlSystem.panel.softwareVersion);
  return (
    <ControlSystemContextProvider controlSystem={controlSystem}>
      <PanelContextProvider panel={controlSystem.panel}>
        <SystemOptionsContextProvider systemOptions={systemOptions}>
          <ProgrammingConceptForm
            conceptId={conceptId}
            title="System Options"
            helpLink={`${programmingGuideUrl}#System%20Options`}
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
              controlSystem.panel.systemOptions
            )}
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <ProgrammingConceptForm.Fields>
                <SystemOptionsCrossZoneTimeField />
                <ZoneRetardDelayField />
                <SystemOptionsPowerFailDelayField />
                <SystemOptionsSwingerBypassTripsField />
                <SystemOptionsTimeChangeField />
                <SystemOptionsHoursFromGMTField />
                <SystemOptionsLatchedSupervisoryZonesField />
                <SystemOptionsPrimaryProgrammingLanguageField />
                <SystemOptionsSecondaryProgrammingLanguageField />
                <SystemOptionsPrimaryUserLanguageField />
                <SystemOptionsSecondaryUserLanguageField />
                <SystemOptionsWirelessHouseCodeField />
                <SystemOptionsDetectWirelessJammingField />
                <SystemOptionsWirelessAudiblesField />
                {softwareVersion >= 241 && (
                  <SystemOptionsInspectionReminderField />
                )}

                <SystemOptionsWirelessEncryption1100Field />
                <SystemOptionsWirelessEncryption1100PassphraseField />
              </ProgrammingConceptForm.Fields>
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </PanelContextProvider>
    </ControlSystemContextProvider>
  );
}
