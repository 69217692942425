import styled from "styled-components/macro";

const Panel = styled.div`
  width: 100%;
  background: white;
  border-radius: 0.6rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

export default Panel;
