/**
 * Created by hvulgamore on 8/9/2016.
 */

App.controller("StoreDashboardCtrl", [
  "$rootScope",
  "$scope",
  "$q",
  "$modal",
  "$window",
  "UserService",
  "StoreDashboardService",
  "CompanyStoreService",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "DTColumnBuilder",
  "$filter",
  "$compile",
  "Customer",
  "$sanitize",
  "DataTablesUtilsService",
  function (
    $rootScope,
    $scope,
    $q,
    $modal,
    $window,
    UserService,
    StoreDashboardService,
    CompanyStoreService,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    DTColumnBuilder,
    $filter,
    $compile,
    Customer,
    $sanitize,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;
    $scope.thisDate = new Date();

    init();
    function init() {
      $scope.salesData = {};
      var ytdPromise = StoreDashboardService.getSalesStatistics(
        UserService.dealer_id,
        "year"
      );
      var mtdPromise = StoreDashboardService.getSalesStatistics(
        UserService.dealer_id,
        "month"
      );
      $q.all([ytdPromise, mtdPromise])
        .then(
          function (data) {
            $scope.salesData.mtd_values = data[1].statistics[0];
            $scope.salesData.YTD_values = data[0].statistics[0];
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Could not get sales statistics.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Get the all the order data
     */
    function getOrders() {
      var _this = this;
      var deferred = $q.defer();
      StoreDashboardService.getOrdersForDealer(UserService.dealer_id)
        .then(
          function (data) {
            $scope.orderData = data.orders;
            angular.forEach($scope.orderData, function (item) {
              setLineItemImageSize(item);
            });
            deferred.resolve(data.orders);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Could not get order data.",
              json: error,
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * A promise based function to return the current set of Order data.  This is required because DataTables uses
     * promises to know when the data is ready to be rendered.
     */
    function getCurrentOrderData() {
      var deferred = $q.defer();
      if ($scope.orderData) {
        deferred.resolve($scope.orderData);
      }
      return deferred.promise;
    }

    /**
     * Sets the image size so that the part thumbnails work the first time you mouse over them.
     * @param item
     */
    function setLineItemImageSize(item) {
      angular.forEach(item.order_items, function (orderItem) {
        var imageToSize = new Image();
        imageToSize.onload = function () {
          orderItem.imgHeight = imageToSize.height / 3;
          orderItem.imgWidth = imageToSize.width / 3;
        };
        imageToSize.src = orderItem.image_url;
      });
    }

    /**
     * Creates and opens the View Order Details modal box
     * @param orderId
     */
    $scope.viewOrderDetails = function (orderId) {
      $scope.thisOrder = $filter("filter")(
        $scope.orderData,
        { id: orderId },
        true
      )[0];
      var viewOrderDetails = $modal.open({
        templateUrl: "viewOrderDetails.html",
        controller: "OrderDetailsModalCtrl",
        windowClass: "",
        size: "lg",
        backdrop: true,
        scope: $scope,
      });
    };

    /**
     *
     * @param order
     * @returns {*}
     */
    $scope.determinedStatus = function (order) {
      // Go from the end of the line, backwards.  Check the final possible statuses first,
      // so that you can eliminate them and use the earlier statuses if necessary.
      // If we have an AutoProgrammingStatus
      if (order.shipment_info.AutoProgrammingStatus) {
        return order.shipment_info.AutoProgrammingStatus;
      } else if (order.shipment_info) {
        return "Shipped";
      } else {
        return order.status;
      }
    };

    /**
     * Returns the correct verbiage, based on diy or secura
     * @param order
     * @returns {*}
     */
    $scope.applicationType = function (order) {
      //If we have DIY (Company Store) vs. Secura systems
      if (order.application === "diy") {
        return "Company Store";
      } else {
        return "Secura";
      }
    };

    // DATA TABLE BUILD //
    //List of columns, column properties, and custom methods used to display the data in the Data Table.
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      getOrders(),
      "Entries"
    );
    $scope.dtOptions.withOption("createdRow", createdRow);
    $scope.dtColumns = [
      DTColumnBuilder.newColumn("id").withTitle("Order #"),
      DTColumnBuilder.newColumn(null)
        .withTitle("Order Type")
        .withOption("sType", "asDashBoardType")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "application",
            "store_dash_order_type"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Serial #")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getShipmentInfo(row, "SerialNumber");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Name")
        .renderWith(function (data, type, row) {
          return navCustomerNameToControlSystem(row);
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Order Date")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "created_at",
            "date"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Last Updated")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "updated_at",
            "date"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Shipped")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "shipment_info.OrderCreatedAt",
            "date"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Online At")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "system_online_at",
            "date"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Status")
        .withOption("sType", "titleize")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "status",
            "titlecase"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Location")
        .withOption("sType", "titleize")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "system_location_type",
            "titlecase"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Total")
        .withClass("text-right pr-lg")
        .withOption("sType", "currency")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "total",
            "currency"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Sales Tax")
        .withClass("text-right pr-lg")
        .withOption("sType", "currency")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "sales_tax",
            "currency"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn("tracking_code").withTitle("Tracking #"),
      DTColumnBuilder.newColumn(null)
        .withTitle("Ship To Dealer")
        .withClass("text-center pr")
        .withOption("sType", "check")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.appliedColumnFilters(
            row,
            "ship_to_dealer",
            "checkmark"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .notSortable()
        .renderWith(function (data, type, row) {
          return toOrderDetails(row, "cms_order_number");
        }),
    ];

    var toOrderDetails = function (data) {
      var link =
        '<a class="pull-right mr link link-primary" ng-click="viewOrderDetails(' +
        data.id +
        ')"> Order Details </a>';
      return link;
    };

    function createdRow(row, data, dataIndex) {
      // Recompiling so we can bind Angular directive to the DT
      $compile(angular.element(row).contents())($scope);
      // Highlights row if the order is Company Store (diy) and unapproved
      if (data.status === "unapproved" && data.application === "diy") {
        angular.element(row).addClass("need-to-take-action-highlight");
      }
    }

    /**
     * Navigate to the control system
     * This function works for the Control System ID.
     * 1.)  If a control system ID is not available a '-' will be entered in its place.
     * 2.)  When clicking on the Control System ID the Link will take you to the Overview page for the system.
     * @param row
     * @returns {*}
     */
    var navCustomerNameToControlSystem = function (row) {
      var thisCustomer = "Testing";

      thisCustomer = {};
      var address = row.billing_address;
      thisCustomer.name = address.business_name
        ? address.business_name +
          " " +
          address.last_name +
          " " +
          address.first_name
        : address.last_name + " " + address.first_name;

      if (!row.shipment_info || !row.shipment_info.controlSystemId)
        return $sanitize(thisCustomer.name);
      else {
        var controlSystemId = row.shipment_info.ControlSystemId;
        var customerId = row.shipment_info.CustomerId;
        return (
          '<a ui-sref="app.control_system({customer_id:' +
          customerId +
          ", control_system_id:" +
          controlSystemId +
          '})">' +
          $sanitize(thisCustomer.name) +
          "</a>"
        );
      }
    };

    /**
     * Get Dealer Store settings from alarmstore server
     */
    CompanyStoreService.getDealerStoreSettings(UserService.dealer_id)
      .then(
        function (data) {
          if (data.hasOwnProperty("dealer")) {
            $scope.dealerStoreSettings = data.dealer;
            if (
              data.dealer.hasOwnProperty("require_company_store_order_approval")
            ) {
              $scope.approvalRequired =
                data.dealer.require_company_store_order_approval;
            }
          } else {
            console.warn(
              "StoreDashboardCtrl - Did not get a dealer from CompanyStoreService.getDealerStoreSettings()"
            );
          }
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Could not get Dealer Settings for Company Store.",
            json: error,
          });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    /**
     * Approve the order, then merge the approved (modified) order back into the order list
     * @param order
     */
    $scope.approveOrder = function (order) {
      StoreDashboardService.approveOrder(UserService.dealer_id, order.id)
        .then(
          function (data) {
            //Once the order is approved, merge the updated order data back into the order
            angular.merge(order, data.order);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error approving order.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Decline the order, then merge the declined (modified) order back into the order list
     * @param order
     */
    $scope.declineOrder = function (order) {
      StoreDashboardService.declineOrder(UserService.dealer_id, order.id)
        .then(
          function (data) {
            //Once the order is declined, merge the updated order data back into the order
            angular.merge(order, data.order);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error denying order.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.buildCustomerName = function (thisOrder) {
      if (thisOrder.shipment_info && thisOrder.shipment_info.CustomerId)
        $scope.thisCustomer = new Customer(thisOrder.shipment_info.CustomerId);
      else {
        $scope.thisCustomer = {};
        var address = thisOrder.billing_address;
        $scope.thisCustomer.name = address.business_name
          ? address.business_name +
            " " +
            address.last_name +
            " " +
            address.first_name
          : address.last_name + " " + address.first_name;
      }
    };
  },
]);

App.controller(
  "OrderDetailsModalCtrl",
  function ($scope, $modalInstance, Customer) {
    /**
     * Reserved function to do something with a returned value, such as go to a detail reports page.
     */

    if (
      $scope.thisOrder.shipment_info &&
      $scope.thisOrder.shipment_info.CustomerId
    )
      $scope.thisCustomer = new Customer(
        $scope.thisOrder.shipment_info.CustomerId
      );
    else {
      $scope.thisCustomer = {};
      var address = $scope.thisOrder.billing_address;
      $scope.thisCustomer.name = address.business_name
        ? address.business_name +
          " " +
          address.last_name +
          " " +
          address.first_name
        : address.last_name + " " + address.first_name;
    }

    $scope.close = function () {
      $modalInstance.dismiss(" ");
    };
  }
);
