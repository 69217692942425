import { PanelHardwareModel } from "components/FullProgramming/common/__generated__/PanelContextUseHardwareModel_panel.graphql";
import { resolvePanelType } from "components/FullProgramming/utils/panel";

export const resolveXrDeviceSetupNumberRules = (
  hardwareModel: PanelHardwareModel,
  supportsAxBus: boolean,
  supportsVplex: boolean
) => {
  const { isXr150, isXr350 } = resolvePanelType(hardwareModel);

  if (isXr150) {
    return {
      inlineHelp: supportsVplex ? "1-8, 501" : "1-8",
      pattern: supportsVplex ? "(0?[1-8]|501)" : "(0?[1-8])",
      validationMessage: supportsVplex
        ? "Valid values are 1-8 and 501."
        : "Valid values are 1-8.",
    };
  }
  if (isXr350) {
    return {
      inlineHelp: "1-16",
      pattern: "(0?[1-9]|1[0-6])",
      validationMessage: "Valid values are 1-16.",
    };
  }

  return {
    inlineHelp: supportsAxBus ? "1-16 or AX address" : "1-16",
    pattern: supportsAxBus
      ? "(0{0,2}[1-9]|0?1[0-6]|[5-9](01|05|09|13|17|21|25|29|33|37|41|45|49|53|57|61))"
      : "(0?[1-9]|1[0-6])",
    validationMessage: supportsAxBus
      ? "Valid values are 1-16 or AX addresses."
      : "Valid values are 1-16.",
  };
};

export const formatToAxDeviceNumber = (
  deviceNumber: number | undefined
): number | undefined => {
  if (deviceNumber) {
    if (deviceNumber > 0 && deviceNumber < 97) {
      return deviceNumber;
    } else if (deviceNumber > 500 && deviceNumber < 962) {
      const calculatedLxValue = //Calculates the AX device number from the LX number
        Math.floor((deviceNumber - 400) / 100) * 16 +
        Math.ceil((deviceNumber % 100) / 4);
      return calculatedLxValue;
    }
  } else return deviceNumber;
};

export const formatToLxDeviceNumber = (
  deviceNumber: number | undefined
): number | undefined => {
  if (deviceNumber) {
    if (deviceNumber < 17 || deviceNumber > 96) {
      return deviceNumber;
    } else {
      const calculatedLxValue = // Calculates the LX number from the number or AX number
        401 +
        100 * Math.floor((deviceNumber - 1) / 16) +
        ((deviceNumber - 1) % 16) * 4;
      return calculatedLxValue;
    }
  } else return deviceNumber;
};
