import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const StatusListDisplayContext = React.createContext<any>({});

export function StatusListDisplayContextProvider(props: {
  statusListDisplay: any;
  children: React.ReactNode;
}) {
  return (
    <StatusListDisplayContext.Provider value={props.statusListDisplay}>
      {props.children}
    </StatusListDisplayContext.Provider>
  );
}

export function useStatusListDisplayFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const statusListDisplay = React.useContext(StatusListDisplayContext);

  const data = useFragment(fragmentInput, statusListDisplay as TKey);
  const { id } = useFragment(
    graphql`
      fragment StatusListDisplayContext_statusListDisplay on StatusListDisplay {
        id
      }
    `,
    statusListDisplay as any
  );

  const update = (
    updater: (statusListDisplay: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
