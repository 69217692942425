App.factory("InstallCenter", [
  function () {
    var InstallCenter = function (install_center, dealer_id) {
      angular.extend(this, {
        installation_center: {
          id: null,
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          phone: null,
        },
        extendMe: function (installCenter) {
          angular.extend(this, installCenter);
        },
      });
      if (install_center != null && angular.isDefined(install_center)) {
        this.extendMe(install_center);
      }
    };
    return InstallCenter;
  },
]);
