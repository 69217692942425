import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsZoneActivityHoursField_systemOptions$key } from "./__generated__/SystemOptionsZoneActivityHoursField_systemOptions.graphql";

export const systemOptionsZoneActivityHoursFieldId = () =>
  "system-options-zone-activity-hours";

function ZoneActivityHoursField() {
  const [
    { zoneActivityHours, zoneActivityHoursMin, zoneActivityHoursMax },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsZoneActivityHoursField_systemOptions$key>(
    graphql`
      fragment SystemOptionsZoneActivityHoursField_systemOptions on SystemOptions {
        ... on XrSystemOptions {
          zoneActivityHours
          zoneActivityHoursMin
          zoneActivityHoursMax
        }
        ... on XtSystemOptions {
          zoneActivityHours
          zoneActivityHoursMin
          zoneActivityHoursMax
        }
        ... on Xt75SystemOptions {
          zoneActivityHours
          zoneActivityHoursMin
          zoneActivityHoursMax
        }
      }
    `
  );

  const fieldId = systemOptionsZoneActivityHoursFieldId();
  const originalValue = React.useRef(zoneActivityHours).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Zone Activity Hours"
      tooltip="Zone inactivity during a disarmed period generates a message to the Receiver if enabled in Communication Programming."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={zoneActivityHours?.toString()}
        required
        min={zoneActivityHoursMin}
        max={zoneActivityHoursMax}
        inlineHelp={`${zoneActivityHoursMin}–${zoneActivityHoursMax}`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "zoneActivityHours"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(
                    zoneActivityHoursMin,
                    zoneActivityHoursMax,
                    valueAsNumber
                  );
            recordProxy.setValue(Number(value), "zoneActivityHours");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneActivityHoursField;
