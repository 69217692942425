/**
 * @generated SignedSource<<59703bacb71cfd14a7559471b1528377>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteDiagnosticsControlSystemFragment_controlSystem$data = {
  readonly doors: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly siteDoorNumber: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsDoorFragment_door">;
  }>;
  readonly hasCell: boolean;
  readonly hasEasyConnect: boolean;
  readonly hasPendingChanges: boolean;
  readonly id: string;
  readonly isX1: boolean;
  readonly isX8: boolean;
  readonly name: string;
  readonly online: boolean;
  readonly serialNumber: string;
  readonly softwareDisplayName: string;
  readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsCellStatusFragment_controlSystem" | "SiteDiagnosticsCommunicationStatusFragment_controlSystem" | "SiteDiagnosticsEasyConnectStatusFragment_controlSystem">;
  readonly " $fragmentType": "SiteDiagnosticsControlSystemFragment_controlSystem";
};
export type SiteDiagnosticsControlSystemFragment_controlSystem$key = {
  readonly " $data"?: SiteDiagnosticsControlSystemFragment_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsControlSystemFragment_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteDiagnosticsControlSystemFragment_controlSystem",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softwareDisplayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isX1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isX8",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEasyConnect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingChanges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteDoor",
      "kind": "LinkedField",
      "name": "doors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "siteDoorNumber",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SiteDiagnosticsDoorFragment_door"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteDiagnosticsCommunicationStatusFragment_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteDiagnosticsEasyConnectStatusFragment_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteDiagnosticsCellStatusFragment_controlSystem"
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "10ffb10ff74c480f377af9e1c763ecad";

export default node;
