/**
 * @generated SignedSource<<8e530bc00453976d0c36dbd24092aa15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsUseBuiltIn1100WirelessField_systemOptions$data = {
  readonly houseCode?: string;
  readonly useBuiltIn1100Wireless?: boolean;
  readonly " $fragmentType": "SystemOptionsUseBuiltIn1100WirelessField_systemOptions";
};
export type SystemOptionsUseBuiltIn1100WirelessField_systemOptions$key = {
  readonly " $data"?: SystemOptionsUseBuiltIn1100WirelessField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsUseBuiltIn1100WirelessField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "useBuiltIn1100Wireless",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "houseCode",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsUseBuiltIn1100WirelessField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "dad079047aafdceda56d0d9f01ace76c";

export default node;
