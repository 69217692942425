/**
 * @generated SignedSource<<f91de65ec0646b33982db862d9c25eee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion$data = {
  readonly detectAnimals: boolean;
  readonly detectPeople: boolean;
  readonly detectVehicles: boolean;
  readonly drawRegionOnAlerts: boolean;
  readonly id: string;
  readonly index: number | null;
  readonly isNew: boolean | null;
  readonly loiteringPersonSeconds: number | null;
  readonly name: string;
  readonly slotNumber: number | null;
  readonly zone: {
    readonly area: {
      readonly id: string;
    } | null;
    readonly id: string;
    readonly isNew: boolean;
    readonly name: string;
    readonly number: string;
  } | null;
  readonly " $fragmentType": "RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion";
};
export type RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion$key = {
  readonly " $data"?: RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNew",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detectPeople",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detectAnimals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "detectVehicles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "drawRegionOnAlerts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loiteringPersonSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slotNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zone",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "area",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        (v2/*: any*/)
      ]
    }
  ],
  "type": "VarHubCameraDetectionRegion",
  "abstractKey": null
};
})();

(node as any).hash = "7e32dbb9594a3738b069c73946c0fa94";

export default node;
