/**
 * @ngdoc service
 * @name App.factory:DealerCustomersV2API
 *
 * @description
 * API factory for creating customers that belong to a specific dealer.
 *
 */
App.factory("DealerCustomersV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/dealers/:dealer_id/:customers/:new",
      {},
      {
        new: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            customers: "customers",
            new: "new",
          },
        },
        create: {
          method: "POST",
          params: { dealer_id: "@dealer_id", customers: "customers" },
        },
      }
    );
  },
]);
