import Modal from "components/Modal";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  asID,
  ControlSystem,
  fromCommunicationPathId,
  idAsString,
  toControlSystemId,
} from "securecom-graphql/client";
import styled from "styled-components/macro";

const ModalBody = styled.div`
  padding: 1rem 2rem;
`;
const Title = styled.h1`
  font-size: 28px;
`;
const Description = styled.div`
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;
const Button = styled.button`
  margin: 0.5em;
`;
const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const VarWifiWarningModal: React.FC<
  React.PropsWithChildren<{
    onClose: () => void;
    commPathId: string;
    overrideToType: "WIFI";
  }>
> = ({ onClose, commPathId, overrideToType }) => {
  const { systemId } = fromCommunicationPathId(asID(commPathId));
  const inverseCommType = (overrideToType: string) =>
    overrideToType === "WIFI" ? "NETWORK" : "WIFI";
  const useRelayEnv = useRelayEnvironment();

  const overrideCommTypes = (overrideToType: "WIFI" | "NETWORK") => {
    useRelayEnv.commitUpdate((store) => {
      const controlSystemRecord = store.get<ControlSystem>(
        idAsString(toControlSystemId(systemId))
      );
      const panelRecord = controlSystemRecord?.getLinkedRecord("panel");

      if (panelRecord) {
        const communicationPaths =
          panelRecord.getLinkedRecords("communicationPaths");
        const oldCommType = inverseCommType(overrideToType);

        if (communicationPaths) {
          const updatedCommunicationPaths = communicationPaths.map(
            (communicationPath) => {
              if (communicationPath.getValue("commType") === oldCommType)
                communicationPath.setValue(overrideToType, "commType");
              return communicationPath;
            }
          );
          panelRecord.setLinkedRecords(
            updatedCommunicationPaths,
            "communicationPaths"
          );
        }
      }
    });
  };

  return (
    <Modal onClickOutside={onClose}>
      <ModalBody>
        <Title>Communication Type Override</Title>
        <Description>
          <p>{`This path's Comm Type is specified as ${overrideToType} and will prevent your XV Gateway from communicating with the panel.`}</p>
          <p>
            {`Are you sure you want to change the Comm Type to ${overrideToType}? Note that selecting 'No' will change the Comm Type of the current path to ${inverseCommType(
              overrideToType
            )}.`}
          </p>
          <p>
            {`Changing the communication type to ${overrideToType} will also override any
            existing ${inverseCommType(overrideToType)} paths.`}
          </p>
          {overrideToType === "WIFI" && (
            <p>Additionally, IPv6 is not supported on WIFI.</p>
          )}
        </Description>

        <ButtonContainer>
          <Button
            className="btn btn-default"
            onClick={() => {
              overrideCommTypes(inverseCommType(overrideToType));
              onClose();
            }}
          >
            No
          </Button>
          <Button
            className="btn btn-dmp"
            onClick={() => {
              overrideCommTypes(overrideToType);
              onClose();
            }}
          >
            <ButtonTextWrapper>Yes</ButtonTextWrapper>
          </Button>
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};

export default VarWifiWarningModal;
