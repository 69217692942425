/**
 * @generated SignedSource<<943bed316c3994cbc3cfac870a63359f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsClosingWaitOutputField_outputOptions$data = {
  readonly closingWaitOutput: {
    readonly formattedNumber: string;
  };
  readonly id: string;
  readonly " $fragmentType": "OutputOptionsClosingWaitOutputField_outputOptions";
};
export type OutputOptionsClosingWaitOutputField_outputOptions$key = {
  readonly " $data"?: OutputOptionsClosingWaitOutputField_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsClosingWaitOutputField_outputOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsClosingWaitOutputField_outputOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "closingWaitOutput",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};

(node as any).hash = "21f0b62fcbf65fe9dcf8825f41773ded";

export default node;
