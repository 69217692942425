import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { areaArmedOutputNumberFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaArmedOutputNumberField";
import { areaAutoArmFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaAutoArmField";
import { areaAutoDisarmFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaAutoDisarmField";
import { areaBadZonesFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaBadZonesField";
import { areaLateArmDelayFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaLateArmDelayField";
import { areaLateOutputNumberFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaLateOutputNumberField";
import { areaNameFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaNameField";
import {
  areaListItemTemplateId,
  areaNumberFieldId,
} from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaNumberField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XT75AreaInformationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/XT75AreaInformationProgrammingConceptForm/__generated__/XT75AreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import * as React from "react";
import { readInlineData } from "react-relay";
import {
  BadZonesOption,
  Xt75ProgrammingTemplateAreaInformationInput,
} from "securecom-graphql/client";
import {
  getAreaState,
  getState as getAreasState,
} from "../../../XT75FullProgramming/XT75AreaInformationProgrammingConceptForm";
import { XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        areaInformations {
          id
          included
          number
          autoArmEnabled {
            data
            included
          }
          autoDisarmEnabled {
            data
            included
          }
          name {
            data
            included
          }
          badZonesOption {
            data
            included
          }
          armedOutputNumber {
            data
            included
          }
          lateOutputNumber {
            data
            included
          }
          lateArmDelay {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XT75AreaInformationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): Xt75ProgrammingTemplateAreaInformationInput[] => {
  const {
    panel: {
      areas: { edges: areaInformations },
    },
  } = getAreasState(controlSystem);

  return areaInformations
    ? areaInformations
        .map((area) => area.node)
        .filter(isNotNullOrUndefined)
        .map(getAreaState)
        .map((area) => ({
          included: includedFields.has(areaListItemTemplateId(area.number)),
          number: Number(area.number),
          autoArmEnabled: {
            data: area.autoArmEnabled,
            included: includedFields.has(areaAutoArmFieldId(area.number)),
          },
          autoDisarmEnabled: {
            data: area.autoDisarmEnabled,
            included: includedFields.has(areaAutoDisarmFieldId(area.number)),
          },
          name: {
            data: area.name,
            included: includedFields.has(areaNameFieldId(area.number)),
          },
          badZonesOption: {
            data: area.badZonesOption as BadZonesOption,
            included: includedFields.has(areaBadZonesFieldId(area.number)),
          },
          armedOutputNumber: {
            data: String(area.armedOutputNumber),
            included: includedFields.has(
              areaArmedOutputNumberFieldId(area.number)
            ),
          },
          lateOutputNumber: {
            data: String(area.lateOutputNumber),
            included: includedFields.has(
              areaLateOutputNumberFieldId(area.number)
            ),
          },
          lateArmDelay: {
            data: area.lateArmDelay,
            included: includedFields.has(areaLateArmDelayFieldId(area.number)),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: React.Dispatch<
    React.SetStateAction<IncludedTemplateFieldsType>
  >
) => {
  const { areaInformations } = getState(template);

  if (!areaInformations) {
    return;
  }

  areaInformations.filter(isNotNullOrUndefined).forEach((area) => {
    const number = String(area.number);
    if (area.included) {
      setIncludedFields(setToggle(areaListItemTemplateId(String(area.number))));
      setIncludedFields(setToggle(areaNumberFieldId(number)));
    }
    if (area.autoArmEnabled?.included) {
      setIncludedFields(setToggle(areaAutoArmFieldId(number)));
    }
    if (area.autoDisarmEnabled?.included) {
      setIncludedFields(setToggle(areaAutoDisarmFieldId(number)));
    }
    if (area.name?.included) {
      setIncludedFields(setToggle(areaNameFieldId(number)));
    }
    if (area.badZonesOption?.included) {
      setIncludedFields(setToggle(areaBadZonesFieldId(number)));
    }
    if (area.armedOutputNumber?.included) {
      setIncludedFields(setToggle(areaArmedOutputNumberFieldId(number)));
    }
    if (area.lateOutputNumber?.included) {
      setIncludedFields(setToggle(areaLateOutputNumberFieldId(number)));
    }
    if (area.lateArmDelay?.included) {
      setIncludedFields(setToggle(areaLateArmDelayFieldId(number)));
    }
  });
};
