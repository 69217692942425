/**
 * @ngdoc API Resource
 * @name App.factory:PanelOD
 *
 * @description
 * PanelOD API resource.  Holds On Demand Monitoring data for a single panel.
 *
 */
App.factory("PanelOD", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return (resource = $resource(
      PROPS.jobsApiUrl + "/api/v1/pod/PanelOD/:panel/:id",
      {},
      {
        get: {
          method: "GET",
          params: {
            panel: "panel",
          },
        },
        update: {
          method: "PUT",
        },
      }
    ));
  },
]);
