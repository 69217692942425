import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";
import ProgressCircle from "components/ProgressCircle";
import * as React from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { FirmwareUpdateBadge_siteControlSystem$key } from "./__generated__/FirmwareUpdateBadge_siteControlSystem.graphql";

const Container = styled.div`
  position: absolute;
  top: var(--measure-half);
  right: var(--measure-half);
  display: flex;
  align-items: center;
`;
const Label = styled.span`
  display: inline-block;
  margin-left: 0.2rem;
  font-size: var(--measure-font-10);
  font-weight: 600;
  color: var(--color-default-text);
`;

export default function FirmwareUpdateBadge(props: {
  controlSystem: FirmwareUpdateBadge_siteControlSystem$key;
}) {
  const data = useFragment(
    graphql`
      fragment FirmwareUpdateBadge_siteControlSystem on SiteControlSystem {
        firmwareStatus
        firmwareUpdateProgress
        firmwareUpdateDismissed
        site {
          firmwareUpdateDismissed
          controlSystems {
            firmwareStatus
            firmwareUpdateProgress
          }
        }
      }
    `,
    props.controlSystem
  );

  if (data.site?.firmwareUpdateDismissed || data.firmwareUpdateDismissed) {
    return null;
  }

  if (data.firmwareStatus === "UPDATES_IN_PROGRESS") {
    const progress = data.firmwareUpdateProgress ?? 0;
    return (
      <Container title={`Firmware update in progress: ${progress}%`}>
        <ProgressCircle percentage={progress} size="var(--measure-font-16)" />
        <Label>{progress}%</Label>
      </Container>
    );
  }

  if (data.firmwareStatus === "UPDATES_FAILED") {
    return (
      <Container title="Firmware update failed">
        <Icon name="radial_alert" color="var(--color-danger-700)" />
      </Container>
    );
  }

  if (data.firmwareStatus === "UPDATES_AVAILABLE") {
    return (
      <Container title="Firmware update available">
        <Icon name="radial_alert" color="var(--color-info-600)" />
      </Container>
    );
  }

  return null;
}
