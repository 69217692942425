import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { useHas1100T } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformation1100TField_deviceInformation$key } from "./__generated__/DeviceInformation1100TField_deviceInformation.graphql";

export const deviceInformation1100TFieldId = (number: string) =>
  `device-information-1100t-${number}`;

function DeviceInformation1100TField() {
  const [
    { id, door, number, network, wirelessTranslator1100t },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformation1100TField_deviceInformation$key>(
    graphql`
      fragment DeviceInformation1100TField_deviceInformation on DeviceInformation {
        id
        ... on XtDeviceInformation {
          door
          number
          network
          wirelessTranslator1100t
        }
      }
    `
  );

  const fieldId = deviceInformation1100TFieldId(String(number));
  const has1100t = useHas1100T();
  const disabled =
    !!door || !!network || (has1100t && !wirelessTranslator1100t);
  const label = "1100T";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        name={id}
        checked={wirelessTranslator1100t}
        disabled={disabled}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              !wirelessTranslator1100t,
              "wirelessTranslator1100t"
            );
            recordProxy.setValue(!wirelessTranslator1100t, "wireless");

            if (!wirelessTranslator1100t) {
              recordProxy.setValue("13", "serialNumber");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformation1100TField;
