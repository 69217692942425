/**
 * @ngdoc service
 * @name App.factory:SedonaIntegrationV1API
 *
 * @description
 * API factory for the Sedona endpoint on the Integration API
 *
 */
App.factory("IntegrationV1API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.integrationUrl + "/api/v1/:endpoint/:method/:integrationId",
      {},
      {
        getParts: {
          method: "GET",
          params: { endpoint: "parts", method: "search" },
          isArray: true,
        },
        updateParts: {
          method: "POST",
          params: { endpoint: "parts", method: "updateParts" },
        },
        updatePreferences: {
          method: "PATCH",
          params: { endpoint: "preferences" },
        },
        getIntegration: {
          method: "GET",
          params: { endpoint: "integrations", method: "search" },
        },
        getAllDealerIntegrations: {
          method: "GET",
          params: { endpoint: "integrations", method: "dealerIntegrations" },
          isArray: true,
        },
        createIntegration: {
          method: "POST",
          params: { endpoint: "integrations" },
        },
        updateIntegration: {
          method: "PATCH",
          params: { endpoint: "integrations" },
        },
      }
    );
  },
]);
