/**
 * @generated SignedSource<<dbdae24ec72b1dc0b17803e7a75a72dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SelectedJobGroupQueueDepthQuery$variables = {
  jobId: number;
  panelId: number;
};
export type SelectedJobGroupQueueDepthQuery$data = {
  readonly systemDiagnosticsJobDepth: {
    readonly queueDepth: number | null;
  };
};
export type SelectedJobGroupQueueDepthQuery = {
  response: SelectedJobGroupQueueDepthQuery$data;
  variables: SelectedJobGroupQueueDepthQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "panelId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "jobId",
        "variableName": "jobId"
      },
      {
        "kind": "Variable",
        "name": "panelId",
        "variableName": "panelId"
      }
    ],
    "concreteType": "SystemDiagnosticsPanelJobDepth",
    "kind": "LinkedField",
    "name": "systemDiagnosticsJobDepth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "queueDepth",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectedJobGroupQueueDepthQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectedJobGroupQueueDepthQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9cabb68981676be2a9e43c571f859e5b",
    "id": null,
    "metadata": {},
    "name": "SelectedJobGroupQueueDepthQuery",
    "operationKind": "query",
    "text": "query SelectedJobGroupQueueDepthQuery(\n  $panelId: Int!\n  $jobId: Int!\n) {\n  systemDiagnosticsJobDepth(panelId: $panelId, jobId: $jobId) {\n    queueDepth\n  }\n}\n"
  }
};
})();

(node as any).hash = "37bde46cdcc127fbf11f0f09e4fd712f";

export default node;
