import Flex from "common/components/web/Flex";
import NeutralText from "components/NeutralText";
import * as React from "react";
import styles from "./style.module.css";

function FormGridItems({
  style,
  children,
}: {
  style?: React.CSSProperties;
  children: React.ReactNode;
}) {
  return (
    <ul className={styles["form-grid-items"]} style={style}>
      {children}
    </ul>
  );
}

FormGridItems.Item = function FormGridItem({
  children,
  className,
  x1PreProgram = false,
}: {
  children: React.ReactNode;
  className?: string;
  x1PreProgram?: boolean;
}) {
  return (
    <li
      className={`${styles["form-grid-item"]} ${className}`}
      style={{ border: x1PreProgram ? "2px solid grey" : "1px solid #ccc" }}
    >
      {children}
    </li>
  );
};

FormGridItems.ItemContent = function FormGridItemContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={styles["form-grid-item__content"]}>{children}</div>;
};

FormGridItems.ItemPrefixer = function FormGridItemPrefixer({
  prefix,
  children,
}: {
  prefix: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <Flex alignItems="flex-start">
      <div className={styles["form-grid-item__prefix"]}>
        <FormGridItems.ItemTitle>
          <NeutralText>{prefix}</NeutralText>
        </FormGridItems.ItemTitle>
      </div>
      <div>{children}</div>
    </Flex>
  );
};

FormGridItems.ItemTitle = function FormGridItemTitle({
  children,
}: {
  children: React.ReactNode;
}) {
  return <span className={styles["form-grid-item__title"]}>{children}</span>;
};

FormGridItems.ItemSubtitle = function FormGridItemSubtitle({
  children,
}: {
  children: React.ReactNode;
}) {
  return <span className={styles["form-grid-item__subtitle"]}>{children}</span>;
};

FormGridItems.ClickableContent = function FormGridItemClickableContent({
  as = "button",
  children,
  onClick,
  href,
  ...rest
}: {
  as?: "button" | "a";
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  href?: string;
}) {
  return React.createElement(
    as,
    {
      ...rest,
      onClick,
      href,
      className: styles["form-grid-item__content--clickable"],
      type: as === "button" ? "button" : undefined,
    },
    <span className={styles["form-grid-item__content--clickable__inner"]}>
      <span className={styles["form-grid-item__content--clickable__content"]}>
        {children}
      </span>
      <span className={styles["form-grid-item__clickable-icon"]}>
        <i className="icon-control_right" />
      </span>
    </span>
  );
};

export default FormGridItems;
