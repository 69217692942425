/**
 * @generated SignedSource<<7a34641b58530b7b555a22cebbecb44d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsListenPort734NField_networkOptions$data = {
  readonly listenPort734N: string;
  readonly listenPort734NMax: number;
  readonly listenPort734NMin: number;
  readonly " $fragmentType": "NetworkOptionsListenPort734NField_networkOptions";
};
export type NetworkOptionsListenPort734NField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsListenPort734NField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsListenPort734NField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsListenPort734NField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "listenPort734N",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "listenPort734NMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "listenPort734NMax",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "1a83ad519240965ea1363e19a5691234";

export default node;
