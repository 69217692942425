import styled from "styled-components";

export const Row = styled.div<{
  direction?: string;
  wrap?: string;
  align?: string;
  justify?: string;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
`;
export const BstrapRow = styled.div`
  display: block;
  clear: both;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
`;

export const Col = styled.div<{
  size?: number;
  alignSelf?: string;
}>`
  //border: 1px solid red;
  flex: ${(props) => props.size || 1};
  align-self: ${(props) => props.alignSelf};
  &:not(last-child) {
    margin-right: 0.8rem;
  }
`;

export default { Row, Col, BstrapRow };
