import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import {
  DeviceInformationCommType,
  DeviceInformationType,
  DeviceInformationWirelessSupervisionTime,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationSupervisionTimeField_deviceInformation$key } from "./__generated__/DeviceInformationSupervisionTimeField_deviceInformation.graphql";

export const deviceInformationSupervisionTimeFieldId = (number: string) =>
  `device-information-supervision-time-${number}`;

function DeviceInformationSupervisionTimeField() {
  const [
    {
      number,
      wireless,
      supervisionTime,
      deviceCommunicationMethod,
      wirelessTranslator1100t,
      deviceType,
    },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationSupervisionTimeField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationSupervisionTimeField_deviceInformation on DeviceInformation {
        number
        supervisionTime
        ... on XtDeviceInformation {
          wireless
          wirelessTranslator1100t
        }
        ... on XrDeviceInformation {
          deviceCommunicationMethod
          supervisionTime
          deviceType
        }
        ... on Xt75DeviceInformation {
          deviceCommunicationMethod
          supervisionTime
          deviceType
        }
      }
    `
  );

  const fieldId = deviceInformationSupervisionTimeFieldId(String(number));
  const is1100t =
    wirelessTranslator1100t || deviceType === DeviceInformationType._1100T;
  const isWireless =
    wireless ||
    deviceCommunicationMethod === DeviceInformationCommType.WIRELESS ||
    is1100t;
  const CAMERA = deviceType === DeviceInformationType.CAMERA;
  const disabled = !isWireless || CAMERA;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Supervision Time"
      disabled={disabled}
      tooltip="If using check-in supervision ('None' is not selected), this represents the maximum duration a device can fail to check-in before going missing."
    >
      <Select
        id={fieldId}
        disabled={disabled}
        value={supervisionTime as DeviceInformationWirelessSupervisionTime}
        required
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              target.value as DeviceInformationWirelessSupervisionTime,
              "supervisionTime"
            );
          });
        }}
      >
        <Select.Option value={DeviceInformationWirelessSupervisionTime.NONE}>
          None
        </Select.Option>
        <Select.Option value={DeviceInformationWirelessSupervisionTime._60_MIN}>
          60 min
        </Select.Option>
        <Select.Option
          value={DeviceInformationWirelessSupervisionTime._240_MIN}
        >
          240 min
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationSupervisionTimeField;
