/**
 *
 * GarageIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const GarageIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path d="M62,0H2H0v64h2h60h2V0H62z M60,4v11H4V4H60z M4,30V19h56v11H4z M60,34v11H4V34H60z M4,60V49h56v11H4z" />
        <rect x="11" y="22" width="9" height="5" />
        <rect x="27" y="22" width="9" height="5" />
        <rect x="43" y="22" width="9" height="5" />
        <rect x="29" y="53" width="5" height="3" />
      </g>
    </g>
  </SvgIcon>
);

export default GarageIcon;
