/**
 * @generated SignedSource<<3da2d775109cca971ee9d41a54ded20c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly alarmReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly serviceReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TakeoverPanelRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TakeoverPanelRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TakeoverPanelRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "47bb88f0ab2a15168398b4540f035727";

export default node;
