App.controller("BulkRemoteUpdateCtrl", [
  "$q",
  "$rootScope",
  "$stateParams",
  "$scope",
  "$state",
  "UserService",
  "RemoteUpdateFactory",
  "RemoteUpdateService",
  "JobSchedulerService",
  "$sessionStorage",
  "REMOTE_UPDATE_VERSIONS",
  "$filter",
  "PanelFirmwaresAPI",
  "$interval",
  "$modal",
  "DataTablesUtilsService",
  "DTColumnBuilder",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "$sanitize",
  "dealer_id",
  function (
    $q,
    $rootScope,
    $stateParams,
    $scope,
    $state,
    UserService,
    RemoteUpdateFactory,
    RemoteUpdateService,
    JobSchedulerService,
    $sessionStorage,
    REMOTE_UPDATE_VERSIONS,
    $filter,
    PanelFirmwaresAPI,
    $interval,
    $modal,
    DataTablesUtilsService,
    DTColumnBuilder,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    $sanitize,
    dealer_id
  ) {
    //Update Dashboard Tab Variables and Functions
    var RemoteUpdateDash = this;

    $scope.reloadPage = reloadPage;
    RemoteUpdateDash.UserService = UserService;
    $scope.loadAllData = loadAllData;
    $scope.getAllPages = $stateParams.data_amount == "full" ? true : false;
    /**
     * @ngdoc object
     * @name getUpdates
     * @methodOf App.controller:RemoteUpdateDealerDashboardCtrl
     *
     * @description
     * Function to get the updates that should appear on this dashboard
     *
     * @returns {Promise} it's complicated
     *
     */
    var getUpdates = function () {
      var deferred = $q.defer();
      var pages = $scope.getAllPages ? null : 1;
      RemoteUpdateService.getAllUpdatesForDealer(dealer_id, pages)
        .then(
          function (data) {
            $scope.updates = data;
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    function loadAllData() {
      $state.go("app.dealer.remote_update_tools", {
        dealer_id: UserService.dealer_id,
        data_amount: "full",
        active_tab: "update_dashboard",
      });
    }

    function reloadPage() {
      $state.forceReload();
    }

    // Calling service to build Table
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(getUpdates());
    $scope.dtOptions.withOption("order", [5, "desc"]);
    $scope.dtOptions.withOption("createdRow", function (row) {
      DataTablesUtilsService.createdRow(row, $scope);
    });

    // List of columns, column properties, and custom methods used to display the data in the Data Table.
    RemoteUpdateDash.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.navToPanelFirmWareUpdatePage(row);
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Model")
        .renderWith(function (data, type, row) {
          return $filter("control_system_hardware_model")(
            DataTablesUtilsService.getGroupData(row, "hardware_model")
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupData(row, "customer_name");
        }),
      DTColumnBuilder.newColumn("Email").withTitle("Updated By"),
      DTColumnBuilder.newColumn(null)
        .withTitle("From / To")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getFirmwareUpdatePath(
            row,
            "from_version",
            "to_version"
          );
        }),
      // DTColumnBuilder.newColumn(null).withTitle('To').renderWith( function(data,type,row) { return DataTablesUtilsService.getGroupData(row, 'to_version');}),
      DTColumnBuilder.newColumn("CreatedAt")
        .withTitle("Started")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
      DTColumnBuilder.newColumn("CompletedAt")
        .withTitle("Completed")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
      DTColumnBuilder.newColumn(null)
        .withTitle("Status")
        // Display nothing if null
        .withOption("defaultContent", "")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asProgressBar(row, "id");
        }),
    ];

    //Bulk Remote Update Tab Variables and Functions
    $scope.UserService = UserService;
    $scope.customerData = UserService.customerInfo;
    $scope.affectedSystems = [];
    $scope.getSystems = getSystems;
    $scope.updateAffectedPanels = updateAffectedPanels;
    $scope.selectAllFiltered = selectAllFiltered;
    $scope.deselectAll = deselectAll;
    $scope.filterBySystemField = filterBySystemField;
    $scope.setActiveTab = setActiveTab;
    $scope.panelFirmwares = [];
    $scope.pagination = {};
    $scope.pagination.itemsPerPage = "10";
    $scope.currentPage = 1;
    $scope.isBusy = true;
    $scope.filters = {};
    $scope.showSearchOptions = true;
    $scope.toggleSearchFilters = toggleSearchFilters;
    $scope.modelList = [];
    $scope.connectionList = [];
    $scope.firmwareList = [];
    $scope.updatesInProgress = [];
    $scope.activeTab =
      $stateParams.active_tab === "update_dashboard"
        ? "update_dashboard"
        : "bulk_update";

    const X1_MODELS = ["X001"];
    const XF6_MODELS = ["XF6_100", "XF6_500"];
    const XT_MODELS = [
      "CellComEX",
      "CellComSL",
      "DualCom",
      "iComLNC",
      "iComSL",
      "MiniCellCom",
      "XT30",
      "XT50",
      "XTLP",
    ];
    const TMSENTRY_MODELS = ["TMS6"];
    const XT75_MODELS = ["XT75"];
    const XR_MODELS = ["XR150", "XR350", "XR550"];

    // Supported Bulk Update panel models
    const UPDATEABLE_PANEL_MODELS = [
      ...X1_MODELS,
      ...XF6_MODELS,
      ...XT_MODELS,
      ...XR_MODELS,
      ...TMSENTRY_MODELS,
      ...XT75_MODELS,
    ];

    // The minimum version supported on XT panels
    const UPDATE_MIN_XT_VERSION = "123";
    const UPDATE_MIN_SERIAL = REMOTE_UPDATE_VERSIONS.XT_MIN_SERIAL;
    const UPDATE_MIN_XR_WITH_CELL_VERSION = "112";

    function init() {
      getUpdatesInProgress().then(
        function (data) {
          getSystems();
        },
        function (error) {}
      );
    }

    function getPanelFirmwares() {
      var deferred = $q.defer();
      PanelFirmwaresAPI.query().$promise.then(
        function (data) {
          $scope.panelFirmwares = data;
          deferred.resolve(data);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error retrieving panel firmware versions",
            json: error,
          });
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function setActiveTab(tab) {
      $state.go("app.dealer.remote_update_tools", {
        dealer_id: UserService.dealer_id,
        data_amount: $stateParams.data_amount,
        active_tab: tab,
      });
    }

    function toggleSearchFilters() {
      if ($scope.showSearchOptions) $scope.filters = {};

      $scope.showSearchOptions = !$scope.showSearchOptions;
    }

    function getUpdatesInProgress() {
      var deferred = $q.defer();
      RemoteUpdateService.getAllUpdatesForDealer($stateParams.dealer_id, 1)
        .then(
          function (allUpdateJobs) {
            $scope.updatesInProgress = allUpdateJobs.filter((item) =>
              JobSchedulerService.isRunningStatus(item.GroupStatus)
            );
            deferred.resolve($scope.updatesInProgress);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving currently updating systems",
              json: error,
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function getSystems() {
      $scope.isBusy = true;
      var refresh = false;
      $scope.affectedSystems = [];
      var deferred = $q.defer();
      var _this = this;
      if (UserService.dealerInfo) {
        getPanelFirmwares().then(function () {
          UserService.dealerInfo
            .getDealerCustomerList(refresh)
            .then(
              function (data) {
                $scope.totalSystemsCount = data.length;
                parseSystemData(data);
                $scope.isBusy = false;
                deferred.resolve(data);
              },
              function (error) {
                $scope.isBusy = false;
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error retrieving systems list",
                  json: error,
                });
              },
              function (info) {}
            )
            .catch(function (error) {
              console.error(error);
              $scope.isBusy = false;
            });
        });
      } else {
      }

      return deferred.promise;
    }

    function parseSystemData(systems) {
      var deferred = $q.defer();
      var _this = this;
      var affectedPanelModels = UPDATEABLE_PANEL_MODELS;
      var returnData = [];

      for (var i = 0; i < systems.length; i++) {
        var system = systems[i].customer;
        if (
          system.panel_software_version_modifier == " " ||
          system.panel_software_version_modifier == null
        ) {
          //panel will return space if there is no version modifier, but the database will be have an empty string or null.  This makes them match.
          system.panel_software_version_modifier = "";
        }
        var panelSoftwareDate = dateTimeForceUTC(
          new Date(system.panel_software_date)
        );
        var thisVersion = $scope.panelFirmwares.filter(function (firmware) {
          //Checking the model and version modifier of the firmwares to ensure they match the panel
          if (
            firmware.model == system.panel_hardware_model &&
            firmware.version_modifier == system.panel_software_version_modifier
          ) {
            //Checking if the firmware is international or not and matching to appropriate panels based on their current firmware.
            if (system.panel_software_version < 600) {
              return firmware.version < 600;
            } else {
              return firmware.version >= 600;
            }
          }
        });

        if (thisVersion.length < 1) continue;
        var updateToVersion = thisVersion[0].version;
        var updateToDateCode = thisVersion[0].code_date;
        var updateDate = dateTimeForceUTC(new Date(updateToDateCode));
        var panelSoftwareVersion = parseInt(system.panel_software_version);

        var panelIsOlderThanUpdate =
          panelSoftwareVersion <= parseInt(updateToVersion) &&
          panelSoftwareDate < updateDate;
        var panelIsSupportedXT =
          XT_MODELS.includes(system.panel_hardware_model) && // XT Model
          panelSoftwareVersion >= parseInt(UPDATE_MIN_XT_VERSION) && // Is the minimum XT Firmware version
          parseInt(system.panel_serial_number, 16) >=
            parseInt(UPDATE_MIN_SERIAL, 16);

        const panelIsSupportedTMSENTRY = TMSENTRY_MODELS.includes(
          system.panel_hardware_model
        );
        const panelIsSupportedXT75 = XT75_MODELS.includes(
          system.panel_hardware_model
        );

        var panelIsSupportedXR =
          XR_MODELS.includes(system.panel_hardware_model) &&
          (system.panel_comm_type === "persistent" ||
            system.panel_comm_type === "persistent_w_cell_backup" ||
            system.panel_comm_type === "network" ||
            (system.panel_comm_type === "cell" &&
              panelSoftwareVersion >=
                parseInt(UPDATE_MIN_XR_WITH_CELL_VERSION)));
        var panelIsSupportedX1 = X1_MODELS.includes(
          system.panel_hardware_model
        );
        var panelIsSupportedXF6 = XF6_MODELS.includes(
          system.panel_hardware_model
        );
        var alreadyUpdating = panelIsUpdating(system.panel_id);

        if (
          affectedPanelModels.includes(system.panel_hardware_model) &&
          !alreadyUpdating &&
          panelIsOlderThanUpdate && //Only panels with firmware older than the update
          system.panel_software_date !== null &&
          system.panel_software_date !== "" && //Only panels that have a software date
          (panelIsSupportedXT || // Only remote updateable XT hardware
            panelIsSupportedXR || // OR ANY XR panel
            panelIsSupportedX1 || // OR ANY X1 panel
            panelIsSupportedXF6 || // OR ANY XF6 panel
            panelIsSupportedXT75 ||
            panelIsSupportedTMSENTRY) // OR ANY TMSENTRY PANEL
        ) {
          var affectedSystem = {
            SystemName: system.control_system_name,
            PanelId: system.panel_id,
            CustomerName: system.customer_name,
            PanelModel: $filter("control_system_hardware_model")(
              system.panel_hardware_model,
              system.panel_hardware_model_name
            ),
            PanelHardwareModel: system.panel_hardware_model,
            PanelConnType: $filter("control_system_comm_type")(
              system.panel_comm_type
            ),
            PanelHasConnected:
              system.panel_arming_system == null ? false : true,
            PanelVersion: system.panel_software_version_modifier
              ? system.panel_software_version +
                system.panel_software_version_modifier +
                " (" +
                system.panel_software_date +
                ")"
              : system.panel_software_version +
                " (" +
                system.panel_software_date +
                ")",
            UpdateVersion: thisVersion[0].version_modifier
              ? thisVersion[0].version +
                thisVersion[0].version_modifier +
                " (" +
                $filter("date")(updateDate, "MM/dd/yy") +
                ")"
              : thisVersion[0].version +
                " (" +
                $filter("date")(updateDate, "MM/dd/yy") +
                ")",
            UpdateVersionId: thisVersion[0].id,
          };
          $scope.affectedSystems.push(affectedSystem);
        }
      }

      // Save the select options if they are new/unique
      $scope.modelList = [
        ...new Set($scope.affectedSystems.map((x) => x.PanelModel)),
      ];
      $scope.connectionList = [
        ...new Set($scope.affectedSystems.map((x) => x.PanelConnType)),
      ];
      $scope.firmwareList = [
        ...new Set($scope.affectedSystems.map((x) => x.PanelVersion)),
      ];
      deferred.resolve(returnData);
      return deferred.promise;
    }

    function panelIsUpdating(panelId) {
      if ($scope.updatesInProgress.length == 0) {
        return false;
      } else {
        for (let update of $scope.updatesInProgress) {
          if (update.PanelId == panelId) {
            return true;
          }
        }
        return false;
      }
    }

    function updateAffectedPanels() {
      $scope.updateStarted = true;
      var updatePromises = [];
      $scope.submittedSystems = $scope.affectedSystems.filter(function (
        system
      ) {
        return system.sendUpdate;
      });
      if ($scope.submittedSystems.length > 0) openProgressModal();
      $scope.currentUpdateItem = 0;
      $interval(
        function () {
          var system = angular.copy(
            $scope.submittedSystems[$scope.currentUpdateItem++]
          );
          updatePromises.push(updateSinglePanel(system));
          if ($scope.currentUpdateItem === $scope.submittedSystems.length)
            $scope.updateStarted = false;
        },
        200,
        $scope.submittedSystems.length,
        true
      );
      $q.all(updatePromises).then(
        function (values) {
          $rootScope.alerts.push({
            type: "success",
            text: "Panel updates were successfully submitted.",
          });
          //$scope.updateStarted = false;
          //$state.go('app.dealer.remote_update_dealer_dashboard',{dealer_id: UserService.dealer_id, data_amount: 'light'})
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Some System Updates encountered errors.  Please refer to the Remote Update Dashboard for more information.",
          });
          //$scope.updateStarted = false;
          //$state.go('app.dealer.remote_update_dealer_dashboard',{dealer_id: UserService.dealer_id, data_amount: 'light'})
        }
      );
    }

    function updateSinglePanel(system) {
      var remoteUpdateFactory = new RemoteUpdateFactory();
      remoteUpdateFactory.initialize(system.PanelId);
      var thisVersion = $scope.panelFirmwares.filter(function (firmware) {
        return firmware.id == system.UpdateVersionId;
      })[0];
      var thisDateCode = dateTimeForceUTC(new Date(thisVersion.code_date));
      var thisVersionFormatted = thisVersion.version;
      if (thisVersion.version_modifier)
        thisVersionFormatted += thisVersion.version_modifier;
      thisVersionFormatted +=
        " (" + $filter("date")(thisDateCode, "MM/dd/yy") + ")";

      return remoteUpdateFactory.updatePanel(
        system.PanelVersion,
        thisVersionFormatted,
        system.panelModel,
        system.CustomerName,
        system.SystemName,
        thisVersion
      );
    }

    function selectAllFiltered(filteredSystems) {
      filteredSystems = filteredSystems.map(function (system) {
        system.sendUpdate = true;
      });
    }

    function deselectAll() {
      $scope.affectedSystems.map(function (system) {
        system.sendUpdate = false;
      });
    }

    function filterBySystemField(fieldName) {
      return function (system) {
        if (
          !$scope.filters ||
          !$scope.filters[fieldName] ||
          $scope.filters[fieldName].length == 0
        )
          return true;
        for (var i = 0; i < $scope.filters[fieldName].length; i++) {
          if ($scope.filters[fieldName][i] == system[fieldName]) return true;
        }
        return false;
      };
    }

    function openProgressModal() {
      var progressModal = $modal.open({
        templateUrl: "progressModal.html",
        controller: "BulkUpdateProgressModalCtrl",
        windowClass: "",
        size: "sm",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
      });

      progressModal.result
        .then(
          function (reason) {
            $state.go("app.dealer.remote_update_tools", {
              dealer_id: UserService.dealer_id,
              data_amount: "light",
              active_tab: "update_dashboard",
            });
          },
          function () {}
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    init();
  },
]);

App.controller(
  "BulkUpdateProgressModalCtrl",
  function ($scope, $modalInstance) {
    $scope.cancel = function () {
      $modalInstance.close("cancel");
    };
  }
);
