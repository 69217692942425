import React from "react";

class LastUpdated extends Map<string, string> {}

export const LastUpdatedContext = React.createContext<LastUpdated>(
  new LastUpdated()
);

export const SetLastUpdatedContext = React.createContext<
  React.Dispatch<React.SetStateAction<LastUpdated>>
>(() => {});

export const useLastUpdated = () => React.useContext(LastUpdatedContext);

export const useSetLastUpdated = () => React.useContext(SetLastUpdatedContext);

export const useResetLastUpdated = () => {
  const lastUpdated = useLastUpdated();
  const setLastUpdated = useSetLastUpdated();
  return (nodeId: string) => {
    const lastUpdatedClone = new LastUpdated(lastUpdated);
    lastUpdatedClone.set(nodeId, `${Date.now()}`);
    setLastUpdated(lastUpdatedClone);
  };
};

export const LastUpdatedContextProvider = React.memo(
  (props: { children: React.ReactNode }) => {
    const [lastUpdated, setLastUpdated] = React.useState<LastUpdated>(
      new LastUpdated()
    );

    return (
      <LastUpdatedContext.Provider value={lastUpdated}>
        <SetLastUpdatedContext.Provider value={setLastUpdated}>
          {props.children}
        </SetLastUpdatedContext.Provider>
      </LastUpdatedContext.Provider>
    );
  }
);

// We need this component to remount when the key changes.
// This is necessary because we are using refs to track
// changes and those refs are out of date when we get new
// data from the graphql server.
export const RemountOnUpdateContainer = ({
  nodeId,
  children,
}: {
  nodeId: string;
  children: React.ReactNode;
}) => {
  const currentDate = `${Date.now()}`;
  const lastUpdated = useLastUpdated();
  const setLastUpdated = useSetLastUpdated();

  if (!lastUpdated.has(nodeId)) {
    setLastUpdated(lastUpdated.set(nodeId, currentDate));
  }

  return (
    <React.Fragment key={lastUpdated.get(nodeId)}>
      {React.useMemo(() => children, [children])}
    </React.Fragment>
  );
};
