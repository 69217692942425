/**
 * @generated SignedSource<<e4d51333841f86d9ee74fe9f96ac1af4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly isTakeoverPanel: boolean;
  readonly panel: {
    readonly id: string;
    readonly maxOutputs: number;
    readonly outputInformations: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XFOutputInformationProgrammingConceptFormInline_output">;
    }>;
  };
  readonly " $fragmentType": "XFOutputInformationProgrammingConceptFormInline_controlSystem";
};
export type XFOutputInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFOutputInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "4c2ee2e83228b94c74da66cfbbb43461";

export default node;
