/**
 *
 * CloseCancelIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CloseCancelIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <polygon
        points="63.313,2.812 60.466,0 31.681,29.152 3.538,0.648 0.691,3.459 28.87,31.999 0.687,60.541 3.534,63.352 
    31.681,34.846 60.466,64 63.313,61.189 34.492,31.999   "
      />
    </g>
  </SvgIcon>
);

export default CloseCancelIcon;
