/**
 *
 * SleepLargeIcon
 * @author Chad Watson
 *
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SleepLargeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <title>sleep_large</title>
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          d="M64,20.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,0.5,0.2,1,0.6,1.4v5.2h-6c-9.1-8.7-21.6-13-34.1-11.7l-1,0.2v3
      c-1-0.7-2.2-1-3.4-1c-3.3,0-6,2.8-6,6.1c0,1.2,0.4,2.4,1.1,3.4H2.9V12.5c0.8-0.6,0.9-1.8,0.3-2.5S1.4,9.1,0.7,9.7
      C0.2,10,0,10.6,0,11.1c0,0.5,0.2,1,0.6,1.3v42.3h2.4V50H61v4.7h2.4V22.2C63.8,21.9,64,21.4,64,20.8z M22.3,17.8
      c11.6-0.8,23.1,3.4,31.5,11.5l0.3,0.3H61v17.9H3V29.7h17V41h2.4L22.3,17.8z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default SleepLargeIcon;
