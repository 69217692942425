/**
 *
 * CheckmarkIcon
 * @author Chad Watson
 *
 */
import * as React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CheckmarkIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <polygon points="19.48,55.29 0.355,35.53 3.229,32.748 19.541,49.601 60.83,8.71 63.645,11.552       " />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default React.memo(CheckmarkIcon);
