import React from "react";
import { useCountryCodes, useUSStateCodes } from "../EntryPointContext";
import { FieldLabel, FieldRow } from "../FormFields";
import { BillingAddress } from "../types";
import styles from "./BillingAddressForm.module.css";

export type BillingAddressState = BillingAddress;

export type Actions =
  | {
      type:
        | "ADDRESS1_CHANGED"
        | "ADDRESS2_CHANGED"
        | "CITY_CHANGED"
        | "STATE_CHANGED"
        | "COUNTRY_CHANGED"
        | "POSTAL_CODE_CHANGED";
      value: string;
    }
  | {
      type: "BILLING_ADDRESS_COPIED";
      billingAddress: BillingAddress;
    };

export function reducer<State extends BillingAddress>(
  state: State,
  action: Actions
): State {
  switch (action.type) {
    case "ADDRESS1_CHANGED":
      return { ...state, address1: action.value };
    case "ADDRESS2_CHANGED":
      return { ...state, address2: action.value };
    case "CITY_CHANGED":
      return { ...state, city: action.value };
    case "STATE_CHANGED":
      return { ...state, state: action.value };
    case "POSTAL_CODE_CHANGED":
      return { ...state, postalCode: action.value };
    case "COUNTRY_CHANGED":
      return { ...state, country: action.value };
    case "BILLING_ADDRESS_COPIED":
      return {
        ...state,
        ...action.billingAddress,
      };
    default:
      return state;
  }
}

export function ReadOnlyAddressFields<State extends BillingAddress>({
  state,
}: {
  state: State;
}) {
  const countryCodes = useCountryCodes();
  const usStateCodes = useUSStateCodes();

  return (
    <div>
      <div className={styles["copy-button-container"]}>
        <FieldRow>
          <FieldLabel className="control-label" htmlFor="address1">
            Address 1
          </FieldLabel>
          <input
            id="address1"
            className="form-control"
            type="text"
            value={state.address1}
            placeholder="Address 1"
            disabled
            aria-readonly={true}
          />
        </FieldRow>
        <FieldRow rowStyle="offset">
          <input
            id="address2"
            className="form-control"
            type="text"
            value={state.address2}
            placeholder="Address 2"
            aria-label="Address 2"
            disabled
            aria-readonly={true}
          />
        </FieldRow>
        <FieldRow>
          <FieldLabel className="control-label" htmlFor="city">
            Service City
          </FieldLabel>
          <input
            id="city"
            className="form-control"
            type="text"
            value={state.city}
            placeholder="City"
            disabled
            aria-readonly={true}
          />
        </FieldRow>
        <FieldRow>
          <FieldLabel className="control-label" htmlFor="state">
            Service State/Prov
          </FieldLabel>
          {state.country === "US" ? (
            <select
              className="form-control"
              name="state"
              id="state"
              value={state.state}
              disabled
              aria-readonly={true}
            >
              {Object.entries(usStateCodes).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          ) : (
            <input
              id="state"
              className="form-control"
              type="text"
              value={state.state}
              placeholder="State"
              disabled
              aria-readonly={true}
            />
          )}
        </FieldRow>
        <FieldRow>
          <FieldLabel className="control-label" htmlFor="postal-code">
            Service Postal Code
          </FieldLabel>
          <input
            id="postal-code"
            className="form-control"
            type="text"
            value={state.postalCode}
            placeholder="Postal Code"
            disabled
            aria-readonly={true}
          />
        </FieldRow>
        <FieldRow>
          <FieldLabel className="control-label" htmlFor="country">
            Service Country
          </FieldLabel>
          <select
            className="form-control"
            name="country"
            id="country"
            value={state.country}
            disabled
            aria-readonly={true}
          >
            {countryCodes.map(({ code, country }) => (
              <option key={code} value={code}>
                {country}
              </option>
            ))}
          </select>
        </FieldRow>
      </div>
    </div>
  );
}

export default function AddressFields<State extends BillingAddress>({
  billingAddress,
  state,
  dispatch,
}: {
  billingAddress: BillingAddress;
  state: State;
  dispatch: (action: Actions) => void;
}) {
  const countryCodes = useCountryCodes();
  const usStateCodes = useUSStateCodes();

  return (
    <div>
      <div className={styles["copy-button-container"]}>
        <button
          type="button"
          className="btn btn-xs btn-dmp"
          onClick={() => {
            dispatch({
              type: "BILLING_ADDRESS_COPIED",
              billingAddress,
            });
          }}
        >
          Copy From Billing Address
        </button>
      </div>

      <FieldRow>
        <FieldLabel className="control-label" htmlFor="address1">
          Address 1
        </FieldLabel>
        <input
          id="address1"
          className="form-control"
          type="text"
          value={state.address1}
          placeholder="Address 1"
          onChange={({ target }) => {
            dispatch({
              type: "ADDRESS1_CHANGED",
              value: target.value,
            });
          }}
        />
      </FieldRow>
      <FieldRow rowStyle="offset">
        <input
          id="address2"
          className="form-control"
          type="text"
          value={state.address2}
          placeholder="Address 2"
          aria-label="Address 2"
          onChange={({ target }) => {
            dispatch({
              type: "ADDRESS2_CHANGED",
              value: target.value,
            });
          }}
        />
      </FieldRow>
      <FieldRow>
        <FieldLabel className="control-label" htmlFor="city">
          Service City
        </FieldLabel>
        <input
          id="city"
          className="form-control"
          type="text"
          value={state.city}
          placeholder="City"
          onChange={({ target }) => {
            dispatch({
              type: "CITY_CHANGED",
              value: target.value,
            });
          }}
        />
      </FieldRow>
      <FieldRow>
        <FieldLabel className="control-label" htmlFor="state">
          Service State/Prov
        </FieldLabel>
        {state.country === "US" ? (
          <select
            className="form-control"
            name="state"
            id="state"
            value={state.state}
            onChange={({ target }) => {
              dispatch({
                type: "STATE_CHANGED",
                value: target.value,
              });
            }}
          >
            <option value="" disabled>
              Select a State
            </option>
            {Object.entries(usStateCodes).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        ) : (
          <input
            id="state"
            className="form-control"
            type="text"
            value={state.state}
            placeholder="State"
            onChange={({ target }) => {
              dispatch({
                type: "STATE_CHANGED",
                value: target.value,
              });
            }}
          />
        )}
      </FieldRow>
      <FieldRow>
        <FieldLabel className="control-label" htmlFor="postal-code">
          Service Postal Code
        </FieldLabel>
        <input
          id="postal-code"
          className="form-control"
          type="text"
          value={state.postalCode}
          placeholder="Postal Code"
          onChange={({ target }) => {
            dispatch({
              type: "POSTAL_CODE_CHANGED",
              value: target.value,
            });
          }}
        />
      </FieldRow>
      <FieldRow>
        <FieldLabel className="control-label" htmlFor="country">
          Service Country
        </FieldLabel>
        <select
          className="form-control"
          name="country"
          id="country"
          value={state.country}
          onChange={({ target }) => {
            dispatch({
              type: "COUNTRY_CHANGED",
              value: target.value,
            });
          }}
        >
          <option value="" disabled>
            Select a country
          </option>
          {countryCodes.map(({ code, country }) => (
            <option key={code} value={code}>
              {country}
            </option>
          ))}
        </select>
      </FieldRow>
    </div>
  );
}
