/**
 * @ngdoc service
 * @name App.factory:DealerODataAPI
 *
 * @description
 * OData API factory for accounting reports data
 *
 */
App.factory("AccountingReportsODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/AccountingReports.:endpoint",
      {},
      {
        getPayments: { method: "GET", params: { endpoint: "GetPayments()" } },
        getScheduledPayments: {
          method: "GET",
          params: { endpoint: "GetScheduledPayments()" },
        },
      }
    );
  },
]);
