import React from "react";

import AddressFields from "../CommonForms/BillingAddressForm";
import {
  Checkbox,
  FieldRow,
  InlineFieldInputContainer,
  InlineFieldLabel,
  OffsetFieldRow,
} from "../FormFields";
import SystemTypeSelector from "../FormFields/SystemTypeSelector";
import Accordion from "../Layout/Accordion";
import { Sheet } from "../Layout/Layout";
import Spacer from "../Layout/Spacer";
import { BillingAddress, GenericControlSystemState } from "../types";
import { Actions } from "./reducer";

export default function GenericControlSystemForm({
  state,
  dispatch,
  billingAddress,
}: {
  state: GenericControlSystemState;
  dispatch: React.Dispatch<Actions>;
  billingAddress: BillingAddress;
}) {
  return (
    <Sheet callout={true}>
      <Spacer size="6x" />
      <div className="row">
        <div className="col-sm-12">
          <FieldRow className="required" rowStyle="featured">
            <InlineFieldLabel htmlFor="name">System Name</InlineFieldLabel>
            <InlineFieldInputContainer>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Smith Home"
                value={state.name}
                required
                onChange={({ target }) => {
                  dispatch({
                    type: "SYSTEM_NAME_CHANGED",
                    value: target.value,
                  });
                }}
              />
            </InlineFieldInputContainer>
          </FieldRow>
          <FieldRow className="required" rowStyle="featured">
            <InlineFieldLabel htmlFor="system-type">
              System Type
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <SystemTypeSelector
                className="form-control"
                name="system-type"
                id="system-type"
                value={state.type}
                required
                onChange={({ currentTarget }) => {
                  dispatch({
                    type: "SYSTEM_TYPE_CHANGED",
                    value: currentTarget.value,
                  });
                }}
              />
            </InlineFieldInputContainer>
          </FieldRow>
          <OffsetFieldRow>
            <div>
              <div>
                <Checkbox
                  checked={state.useBillingAddress}
                  onChange={() => {
                    dispatch({
                      type: "USE_BILLING_ADDRESS_TOGGLED",
                      billingAddress,
                    });
                  }}
                  label={"Use Billing Address"}
                />
                <div className="clearfix" />
              </div>

              {!state.useBillingAddress && (
                <Accordion
                  initiallyOpen={true}
                  header={
                    <Accordion.ToggleableHeader>
                      Edit Address
                    </Accordion.ToggleableHeader>
                  }
                >
                  <AddressFields
                    state={state}
                    dispatch={dispatch}
                    billingAddress={billingAddress}
                  />
                </Accordion>
              )}
            </div>
          </OffsetFieldRow>
        </div>
      </div>
    </Sheet>
  );
}
