import { useRelayEnvironment } from "react-relay";
import { commitLocalUpdate } from "relay-runtime";
import { v4 as uuidv4 } from "uuid";

const ERROR_TOAST_TIMEOUT_MS = 60000;
const SUCCESS_TOAST_TIMEOUT_MS = 4000;
export const FADE_MS = 1000;

const useToasts = (): [
  (toastType: string, toastText: string) => void,
  (id: string) => void
] => {
  const environment = useRelayEnvironment();

  const createToast = (toastType: string, toastText: string) => {
    const id = uuidv4();
    commitLocalUpdate(environment, (store) => {
      const user = store.getRoot().getLinkedRecord("myUser");
      if (user) {
        const newToast = store.create(id, "Toast");
        newToast.setValue(id, "id");
        newToast.setValue(toastType, "type");
        newToast.setValue(toastText, "text");
        newToast.setValue(false, "isVisible");
        const records = user.getLinkedRecords("toasts") || [];
        const newRecords = [...records, newToast];
        user.setLinkedRecords(newRecords, "toasts");
      }
    });
    setTimeout(() => {
      commitLocalUpdate(environment, (store) => {
        const user = store.getRoot().getLinkedRecord("myUser");
        if (user) {
          const existingRecords = user.getLinkedRecords("toasts") || [];
          const newRecord = existingRecords.find(
            (r) => r.getValue("id") === id
          );
          if (newRecord) {
            newRecord.setValue(true, "isVisible");
          }
        }
      });
    }, 0);
    const timeout =
      toastType === "error" ? ERROR_TOAST_TIMEOUT_MS : SUCCESS_TOAST_TIMEOUT_MS;
    setTimeout(() => deleteToast(id), timeout);
  };

  const deleteToast = (id: string) => {
    commitLocalUpdate(environment, (store) => {
      const user = store.getRoot().getLinkedRecord("myUser");
      if (user) {
        const existingRecords = user.getLinkedRecords("toasts") || [];
        const recordToDelete = existingRecords.find(
          (r) => r.getValue("id") === id
        );
        if (recordToDelete) {
          recordToDelete.setValue(false, "isVisible");
          setTimeout(() => {
            commitLocalUpdate(environment, (store) => {
              const user = store.getRoot().getLinkedRecord("myUser");
              if (user) {
                const existingRecords = user.getLinkedRecords("toasts") || [];
                user.setLinkedRecords(
                  existingRecords.filter((r) => r.getValue("id") !== id),
                  "toasts"
                );
              }
            });
          }, FADE_MS);
        }
      }
    });
  };

  return [createToast, deleteToast];
};

export default useToasts;
