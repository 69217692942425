import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { SendLocalChangesType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsRemoteChangeIpAddressField_remoteOptions$key } from "./__generated__/RemoteOptionsRemoteChangeIpAddressField_remoteOptions.graphql";

export const remoteOptionsRemoteChangeIpAddressFieldId = () =>
  "remote-options-remote-change-ip-address";

function RemoteOptionsRemoteChangeIpAddressField() {
  const [{ sendLocalChanges, remoteChangesIpAddress }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsRemoteChangeIpAddressField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsRemoteChangeIpAddressField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            sendLocalChanges
            remoteChangesIpAddress
          }
        }
      `
    );

  const fieldId = remoteOptionsRemoteChangeIpAddressFieldId();
  const disabled = sendLocalChanges !== SendLocalChangesType.NETWORK;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Remote Change IP"
      tooltip="IP Address for programming change recipient."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={remoteChangesIpAddress || ""}
        maxLength={16}
        disabled={disabled}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        validationMessage="Please enter a valid IP address"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "remoteChangesIpAddress")
          );
        }}
        onBlur={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "remoteChangesIpAddress"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsRemoteChangeIpAddressField;
