import graphql from "babel-plugin-relay/macro";
import Space from "common/components/web/Space";
import { sequenceReducers } from "common/utils/universal/reducers";
import Alert from "components/Alert";
import Modal from "components/Modal";
import TagField from "components/TagField/TagField";
import { pick } from "ramda";
import React, { Suspense, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import Select from "react-select";
import { asID, fromSiteId } from "securecom-graphql/client";
import styled from "styled-components";
import { DMPTimezoneOffsetOptions } from "utils/dates";
import AddressFields, {
  Actions as BillingAddressActions,
  reducer as billingAddressReducer,
} from "../CommonForms/BillingAddressForm";
import {
  useCreateNotification,
  useGetDealerId,
  useTagsEnabled,
  useUserCanEditTags,
} from "../EntryPointContext";
import {
  Checkbox,
  FieldRow,
  InlineFieldInputContainer,
  InlineFieldLabel,
  OffsetFieldRow,
  Switch,
} from "../FormFields";
import TimezoneOffsetSelector from "../FormFields/TimezoneOffsetSelector";
import Accordion from "../Layout/Accordion";
import Spacer from "../Layout/Spacer";
import styles from "./SiteControlSystemsForm.module.css";
import { SiteEditModalUpdateSiteDetailsMutation } from "./__generated__/SiteEditModalUpdateSiteDetailsMutation.graphql";
import {
  SiteEditModal_site$data,
  SiteEditModal_site$key,
} from "./__generated__/SiteEditModal_site.graphql";

interface SiteEditModalProps {
  siteRef: SiteEditModal_site$key;
  setSiteName: (name: string) => void;
  onCancel: () => void;
}
function SiteEditModal(props: SiteEditModalProps) {
  const { siteRef, setSiteName, onCancel } = props;

  const data = useFragment<SiteEditModal_site$key>(
    graphql`
      fragment SiteEditModal_site on Site {
        id
        name
        nickname
        address1
        address2
        city
        state
        postalCode
        country
        observesDaylightSavingsTime
        timezoneOffset
        showDoors
        showElevators
        showOutputs
        showVideoServices
        alarmPanelX1Integration
        supportsXrCommunication
        xrCommunicationEnabled
        armablePanelId
        armablePanelSerialNumber
        sitePassphrase
        customerBillingAddress: customer {
          address1
          address2
          city
          state
          postalCode
          country
        }
        controlSystems {
          intendedUsage
        }
        outputModulesConnection(include: OUTPUTS) {
          nodes {
            id
            name
            address
            controlSystem {
              serialNumber
              doors {
                name
              }
            }
            outputsConnection {
              nodes {
                name
                relayNumber
                type
              }
            }
          }
        }
        videoEnabled
        securecomNvrEnabled
        hikvisionDoorbellEnabled
        openEyeEnabled
        hikvisionNvrEnabled
        eagleEyeEnabled
        digitalWatchdogSpectrumEnabled
        hanwhaWaveEnabled
      }
    `,
    siteRef
  );
  const [updateSiteDetails, updatingSiteDetails] =
    useMutation<SiteEditModalUpdateSiteDetailsMutation>(
      updateSiteDetailsMutation
    );
  const onSave = () => {
    updateSiteDetails({
      variables: {
        site: {
          ...pick(
            [
              "id",
              "name",
              "nickname",
              "address1",
              "address2",
              "city",
              "state",
              "postalCode",
              "country",
              "observesDaylightSavingsTime",
              "timezoneOffset",
              "showDoors",
              "showElevators",
              "showOutputs",
              "showVideoServices",
              "armablePanelSerialNumber",
            ],
            state
          ),
        },
      },
      onError: (_) => {
        setError({ type: "UNKNOWN" });
      },
      onCompleted: ({ updateSiteDetails: results }) => {
        switch (results.__typename) {
          case "Site": {
            createNotification({
              type: "success",
              text: "System was Updated.",
            });
            setSiteName(state.name); // This is needed to update the site name in the side menu.
            onCancel();
            break;
          }
          case "UpdateSiteDetailsFailedToSetSystemOptionsOnDoorsError": {
            setError({
              type: results.__typename,
              payload: results.totalFailures,
            });
            break;
          }
          default: {
            setError({ type: results.__typename });
          }
        }
      },
    });
  };
  const [state, dispatch] = React.useReducer(reducer, { ...data });
  const createNotification = useCreateNotification();
  const dealerId = useGetDealerId();
  const tagsAreEditable = useUserCanEditTags();
  const tagsEnabled = useTagsEnabled();
  const { customerId, siteId } = fromSiteId(asID(data.id));
  const [error, setError] = useState<null | Error>(null);
  const [missingRequiredField, setMissingRequiredField] = useState(false);
  const [badSerialNumber, setBadSerialNumber] = useState(false);
  const hasDoors = data.controlSystems.some(
    (system) => system.intendedUsage === "DOOR_ACCESS"
  );
  const hasElevators = data.controlSystems.some(
    (system) => system.intendedUsage === "ELEVATOR"
  );
  const hasOutputs = !!data.outputModulesConnection.nodes.length;
  const hasVideoServices =
    data.videoEnabled ||
    data.hikvisionDoorbellEnabled ||
    data.openEyeEnabled ||
    data.hikvisionNvrEnabled ||
    data.eagleEyeEnabled ||
    data.digitalWatchdogSpectrumEnabled ||
    data.hanwhaWaveEnabled;

  return (
    <Modal size="large">
      <Modal.Body>
        <div className={styles["form-header"]}>
          <div />
          <div className={styles.actions}>
            <button
              type="button"
              className="btn btn-sm btn-default"
              disabled={updatingSiteDetails}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-info"
              disabled={
                missingRequiredField ||
                updatingSiteDetails ||
                badSerialNumber ||
                (state.xrCommunicationEnabled &&
                  !state.armablePanelSerialNumber)
              }
              onClick={onSave}
            >
              {updatingSiteDetails ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
        {error ? (
          <>
            <Spacer size="1x" />
            <Alert type="error">
              {error.type ===
              `UpdateSiteDetailsFailedToSetSystemOptionsOnDoorsError`
                ? `Unable to connect to ${error.payload} door(s). Please ensure all doors are powered on and try again.`
                : error.type === "InvalidSerialsError"
                ? "XR Communication feature requires Version 231 or higher. Update the selected XR panel to Version 231 or higher to enable this feature."
                : error.type === "InvalidInputError"
                ? `XR Communication serial number not found. Please ensure serial number exists on this customer and try again.`
                : `An error occurred while updating the system details. Please try again.`}
            </Alert>
          </>
        ) : (
          <Spacer size="6x" />
        )}

        <Container>
          <FieldRow
            rowStyle="featured"
            className="required"
            error={missingRequiredField}
          >
            <InlineFieldLabel htmlFor="system-name">
              System Name
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <input
                name="system-name"
                id="system-name"
                className="form-control"
                value={state.name}
                required
                onChange={({ currentTarget }) => {
                  dispatch({
                    type: "NAME_CHANGED",
                    value: currentTarget.value,
                  });
                  setMissingRequiredField(currentTarget.value === "");
                }}
              />
              {missingRequiredField ? (
                <div className="has-error">System name is required</div>
              ) : null}
            </InlineFieldInputContainer>
          </FieldRow>
          <FieldRow rowStyle="featured">
            <InlineFieldLabel htmlFor="alternate-system-name">
              Alternate System Name
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <input
                name="alternate-system-name"
                id="alternate-system-name"
                className="form-control"
                value={state.nickname ?? undefined}
                onChange={({ currentTarget }) => {
                  dispatch({
                    type: "NICKNAME_CHANGED",
                    value: currentTarget.value.trim() ? currentTarget.value : null  ,
                  });
                }}
                
              />
            </InlineFieldInputContainer>
          </FieldRow>

          <OffsetFieldRow>
            <Accordion
              header={
                <Accordion.ToggleableHeader>
                  Edit Address
                </Accordion.ToggleableHeader>
              }
            >
              <AddressFields
                state={state}
                dispatch={dispatch}
                billingAddress={state.customerBillingAddress}
              />
            </Accordion>
          </OffsetFieldRow>

          <FieldRow className="required" rowStyle="featured">
            <InlineFieldLabel htmlFor="timezone-offset">
              Time Zone Offset
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <TimezoneOffsetSelector
                className="form-control"
                name="timezone-offset"
                id="timezone-offset"
                value={state.timezoneOffset}
                onChange={({ currentTarget }) => {
                  dispatch({
                    type: "SITE_TIMEZONE_CHANGED",
                    value: Number(
                      currentTarget.value
                    ) as DMPTimezoneOffsetOptions,
                  });
                }}
              />
            </InlineFieldInputContainer>
          </FieldRow>

          <OffsetFieldRow>
            <Checkbox
              checked={state.observesDaylightSavingsTime}
              onChange={() => {
                dispatch({
                  type: "USE_DAYLIGHT_SAVINGS_TIME_TOGGLED",
                });
              }}
              label={"Use Daylight Savings Time"}
            />
          </OffsetFieldRow>

          {tagsEnabled && (
            <FieldRow rowStyle="featured" showOverflow>
              <InlineFieldLabel htmlFor="tags">Tags</InlineFieldLabel>
              <InlineFieldInputContainer>
                <Suspense
                  fallback={<Select isDisabled={true} isLoading={true} />}
                >
                  <TagField
                    customerId={customerId}
                    dealerId={dealerId}
                    isEditable={tagsAreEditable}
                    type={"Sites"}
                    typeId={parseInt(siteId)}
                  />
                </Suspense>
              </InlineFieldInputContainer>
            </FieldRow>
          )}

          <FieldRow rowStyle="featured">
            <InlineFieldLabel htmlFor="site-configuration">
              Site Configuration
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <Checkbox
                checked={state.showDoors}
                onChange={() => dispatch({ type: "DOORS_TOGGLED" })}
                label={"Doors"}
                disabled={hasDoors}
              />
              <Space small />
              <Checkbox
                checked={state.showOutputs}
                onChange={() => dispatch({ type: "OUTPUTS_TOGGLED" })}
                label={"Outputs"}
                disabled={hasOutputs}
              />
            </InlineFieldInputContainer>
            <InlineFieldInputContainer>
              <Checkbox
                checked={state.showElevators}
                onChange={() => dispatch({ type: "ELEVATORS_TOGGLED" })}
                label={"Elevators"}
                disabled={hasElevators}
              />
              <Space small />
              <Checkbox
                checked={state.showVideoServices}
                onChange={() => dispatch({ type: "VIDEO_SERVICES_TOGGLED" })}
                label={"Video Services"}
                disabled={hasVideoServices}
              />
            </InlineFieldInputContainer>
          </FieldRow>

          <FieldRow rowStyle="featured">
            <InlineFieldLabel htmlFor="xr-communication-enabled">
              XR Communication
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <Switch
                checked={state.xrCommunicationEnabled}
                onChange={() =>
                  dispatch({
                    type: "XR_COMMUNICATION_ENABLED_TOGGLED",
                  })
                }
                label={"xr-communication-enabled"}
                disabled={
                  !state.supportsXrCommunication ||
                  state.alarmPanelX1Integration
                }
              />
              {state.supportsXrCommunication
                ? `Enable communication between X1s and an XR panel on the same network allowing users to arm and disarm areas automatically.`
                : "XR Communication is not supported. Please update to the latest firmware version to enable XR Communication."}
            </InlineFieldInputContainer>
          </FieldRow>

          {state.xrCommunicationEnabled ? (
            <>
              <FieldRow
                rowStyle="featured"
                className="required"
                error={badSerialNumber}
              >
                <InlineFieldLabel htmlFor="xr-serial-number">
                  XR Serial Number
                </InlineFieldLabel>
                <InlineFieldInputContainer>
                  <input
                    name="xr-serial-number"
                    id="xr-serial-number"
                    className="form-control"
                    value={state.armablePanelSerialNumber ?? ""}
                    required
                    maxLength={8}
                    pattern="^[0-9A-Fa-f]{8}$"
                    onChange={({ currentTarget }) => {
                      dispatch({
                        type: "XR_SERIAL_NUMBER_CHANGED",
                        value: currentTarget.value,
                      });
                      setBadSerialNumber(
                        badSerialNumber &&
                          currentTarget.validity.patternMismatch
                      );
                    }}
                    onBlur={({ currentTarget }) => {
                      setBadSerialNumber(
                        currentTarget.validity.valueMissing ||
                          currentTarget.validity.patternMismatch
                      );
                    }}
                  />
                  {badSerialNumber ? (
                    <div className="has-error">
                      {state.armablePanelSerialNumber
                        ? `Invalid serial number`
                        : `Serial number is required`}
                    </div>
                  ) : null}
                </InlineFieldInputContainer>
              </FieldRow>
              <FieldRow rowStyle="featured">
                <InlineFieldLabel htmlFor="x1-site-passphrase">
                  X1 Site Passphrase
                </InlineFieldLabel>
                <InlineFieldInputContainer>
                  <PassphraseContainer>
                    <PassphraseInput
                      name="x1-site-passphrase"
                      id="x1-site-passphrase"
                      className="form-control"
                      value={state.sitePassphrase}
                      disabled
                    />
                    <PassphraseButton
                      className="btn btn-sm btn-dmp pull-right"
                      onClick={() => {
                        navigator.clipboard.writeText(state.sitePassphrase);
                      }}
                    >
                      Copy&nbsp;Passphrase
                    </PassphraseButton>
                  </PassphraseContainer>
                  Used to encrypt communication to XR panel. Dealer Admin will
                  automatically program the passphrase in the XR entered above.
                </InlineFieldInputContainer>
              </FieldRow>
            </>
          ) : null}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

function Container(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div {...props} />
      </div>
    </div>
  );
}

type State = SiteEditModal_site$data;
type Actions =
  | { type: "USE_DAYLIGHT_SAVINGS_TIME_TOGGLED" }
  | { type: "SITE_TIMEZONE_CHANGED"; value: DMPTimezoneOffsetOptions }
  | { type: "NAME_CHANGED"; value: string }
  | { type: "NICKNAME_CHANGED"; value: string | null}
  | { type: "DOORS_TOGGLED" }
  | { type: "ELEVATORS_TOGGLED" }
  | { type: "OUTPUTS_TOGGLED" }
  | { type: "VIDEO_SERVICES_TOGGLED" }
  | { type: "XR_SERIAL_NUMBER_CHANGED"; value: string }
  | { type: "XR_COMMUNICATION_ENABLED_TOGGLED" }
  | BillingAddressActions;
type Error = {
  type: string;
  payload?: any;
};

const reducer = sequenceReducers([
  function reducer(state: State, action: Actions): State {
    switch (action.type) {
      case "NAME_CHANGED":
        return { ...state, name: action.value };
      case "NICKNAME_CHANGED":
        return { ...state, nickname: action.value };
      case "SITE_TIMEZONE_CHANGED":
        return { ...state, timezoneOffset: action.value };
      case "USE_DAYLIGHT_SAVINGS_TIME_TOGGLED":
        return {
          ...state,
          observesDaylightSavingsTime: !state.observesDaylightSavingsTime,
        };
      case "DOORS_TOGGLED":
        return { ...state, showDoors: !state.showDoors };
      case "ELEVATORS_TOGGLED":
        return { ...state, showElevators: !state.showElevators };
      case "OUTPUTS_TOGGLED":
        return { ...state, showOutputs: !state.showOutputs };
      case "VIDEO_SERVICES_TOGGLED":
        return { ...state, showVideoServices: !state.showVideoServices };
      case "XR_SERIAL_NUMBER_CHANGED":
        return { ...state, armablePanelSerialNumber: action.value };
      case "XR_COMMUNICATION_ENABLED_TOGGLED":
        return {
          ...state,
          xrCommunicationEnabled: !state.xrCommunicationEnabled,
          armablePanelSerialNumber: "",
        };
      default:
        return state;
    }
  },
  billingAddressReducer,
]);

const updateSiteDetailsMutation = graphql`
  mutation SiteEditModalUpdateSiteDetailsMutation(
    $site: UpdateSiteDetailsInput!
  ) {
    updateSiteDetails(site: $site) {
      __typename
      ... on Site {
        id
        ...SiteEditModal_site
      }
      ... on UpdateSiteDetailsFailedToSetSystemOptionsOnDoorsError {
        totalFailures
      }
    }
  }
`;

export default SiteEditModal;

const PassphraseContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PassphraseInput = styled.input`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const PassphraseButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
