/**
 * @ngdoc service
 * @name App.factory:ServiceRequestService
 *
 * @description
 * Gets, modifies, and submits data for the service request feature
 *
 */
App.factory("ServiceRequestService", [
  "ServiceRequestAPI",
  "$q",
  "UserService",
  "$filter",
  function (ServiceRequestAPI, $q, UserService, $filter) {
    return {
      /**
       * Look into putting catch for when there are more results than can be returned in a single call
       * (what I did for Assign Cameras To Zones Service)
       *
       */
      createServiceRequest: function (body) {
        var deferred = $q.defer();
        ServiceRequestAPI.createServiceRequest(
          angular.toJson(body),
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getDealerServiceRequest: function (dealerId) {
        var deferred = $q.defer();
        ServiceRequestAPI.getDealerServiceRequest(
          { dealer_id: dealerId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getTechServiceRequest: function (techId) {
        var deferred = $q.defer();
        ServiceRequestAPI.getTechServiceRequest(
          { technician_id: techId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getServiceRequestById: function (requestId) {
        var deferred = $q.defer();
        ServiceRequestAPI.getServiceRequestById(
          { request_id: requestId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      editServiceRequest: function (requestId, body) {
        var deferred = $q.defer();
        ServiceRequestAPI.editServiceRequest(
          { request_id: requestId },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      deleteServiceRequest: function (requestId) {
        var deferred = $q.defer();
        ServiceRequestAPI.deleteServiceRequest(
          { request_id: requestId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      addNotes: function (request, notesToAdd) {
        let deferred = $q.defer();
        // let requestBody = { ...request };
        let requestBody = {};
        angular.copy(request, requestBody);
        let nameParam =
          UserService.user.first_name && UserService.user.last_name
            ? `${$filter("titlecase")(UserService.user.first_name)} ${$filter(
                "titlecase"
              )(UserService.user.last_name)}`
            : UserService.user.email;
        let dateVal = $filter("date")(new Date(), "short");

        if (notesToAdd) {
          if (requestBody.Notes) {
            // requestBody.Notes = requestBody.Notes + `\n\n${nameParam} ${dateVal} \n${notesToAdd}`;
            requestBody.Notes =
              requestBody.Notes +
              `<div class="service-request__note"><div class="service-request__note__id-line"><span class="service-request__note__id-line-name">${nameParam}</span>- <span class="service-request__note__id-line-date">${dateVal}</span></div> <div class="service-request__note__body">${notesToAdd}</div></div>`;
          } else {
            // requestBody.Notes = `${nameParam} ${dateVal} \n${notesToAdd}`;
            requestBody.Notes = `<div class="service-request__note"><div class="service-request__note__id-line"><span class="service-request__note__id-line-name">${nameParam}</span>- <span class="service-request__note__id-line-date">${dateVal}</span></div> <div class="service-request__note__body">${notesToAdd}</div></div>`;
          }
        }

        this.editServiceRequest(request.Id, requestBody)
          .then(
            function (success) {
              request.Notes = requestBody.Notes; //update the view
              deferred.resolve();
            },
            function (error) {
              console.error(error);
              deferred.error(error);
            }
          )
          .catch(function (error) {
            console.error(error);
            deferred.error(error);
          });

        return deferred.promise;
      },
    };
  },
]);
