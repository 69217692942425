/**
 * @generated SignedSource<<795e0a951286ad1f47c9bbf6aa70deee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommPathTransmissionDelayField_panel$data = {
  readonly communicationPaths: ReadonlyArray<{
    readonly id: string;
    readonly transmissionDelay: number;
    readonly transmissionDelayValidValues: ReadonlyArray<number>;
  }>;
  readonly id: string;
  readonly " $fragmentType": "CommPathTransmissionDelayField_panel";
};
export type CommPathTransmissionDelayField_panel$key = {
  readonly " $data"?: CommPathTransmissionDelayField_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathTransmissionDelayField_panel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathTransmissionDelayField_panel",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CommunicationPath",
      "kind": "LinkedField",
      "name": "communicationPaths",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transmissionDelay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transmissionDelayValidValues",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "6fbfe69ba700b357fa4130a238a887c5";

export default node;
