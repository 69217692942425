/**
 * @generated SignedSource<<628f93d143873bcdc0d5b46806a8cbe2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NacProtocols = "DMP" | "GENTEX" | "SYSTEM_SENSOR" | "UNKNOWN" | "WHEELOCK";
import { FragmentRefs } from "relay-runtime";
export type BellOptionsNac2SyncProtocolField_bellOptions$data = {
  readonly id: string;
  readonly nac2SyncProtocol: NacProtocols | null;
  readonly " $fragmentType": "BellOptionsNac2SyncProtocolField_bellOptions";
};
export type BellOptionsNac2SyncProtocolField_bellOptions$key = {
  readonly " $data"?: BellOptionsNac2SyncProtocolField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsNac2SyncProtocolField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsNac2SyncProtocolField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nac2SyncProtocol",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "50326227e90c0fb6bc8295e7f1830919";

export default node;
