import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationCellTestDaysField_communication$key } from "./__generated__/CommunicationCellTestDaysField_communication.graphql";

export const communicationCellTestDaysFieldId = () =>
  "communication-cell-test-days";

function CommunicationCellTestDaysField() {
  const [
    { comType, cellTestDays, cellTestDaysMin, cellTestDaysMax, backupCell },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationCellTestDaysField_communication$key>(
    graphql`
      fragment CommunicationCellTestDaysField_communication on Communication {
        comType
        cellTestDays
        cellTestDaysMin
        cellTestDaysMax
        backupCell
      }
    `
  );

  const fieldId = communicationCellTestDaysFieldId();
  const { current: originalValue } = React.useRef(cellTestDays);
  const disabled = !backupCell && comType !== "CELL";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Cell Test Days"
      disabled={disabled}
      tooltip="Number of days the System waits between communication tests to the Receiver. (performed at the programmed Test Time)"
    >
      <NumericInput
        id={fieldId}
        value={cellTestDays}
        required
        disabled={disabled}
        min={cellTestDaysMin}
        max={cellTestDaysMax}
        inlineHelp={`${cellTestDaysMin}–${cellTestDaysMax}`}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "cellTestDays"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(cellTestDaysMin, cellTestDaysMax, valueAsNumber);
            recordProxy.setValue(Number(value), "cellTestDays");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationCellTestDaysField;
