/**** Diagnostic Search Component  ****/

/*
 *  This component contains a search bar that the user can input the panel serial number,
 *  control system name, account number, control system ID, customer ID, SIM number,
 *  communication phone number, or any combination of these to search for a panel/system.
 *
 *  The query string will be passed to an API that will return the top candidates for a match,
 *  which will be displayed in a table.
 */

import graphql from "babel-plugin-relay/macro";
import { Panel, Table } from "components/DaStyledElements";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import styled from "styled-components";
import { DiagnosticSearchQuery } from "./__generated__/DiagnosticSearchQuery.graphql";
import { DiagnosticSearchRefreshQuery } from "./__generated__/DiagnosticSearchRefreshQuery.graphql";
import { DiagnosticSearch_query$key } from "./__generated__/DiagnosticSearch_query.graphql";

const Search = styled.input`
  display: inline;
  width: 20%;
  margin-left: 20px;
  margin-right: 20px;
`;

const ErrorText = styled.div`
  width: 20%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, minmax(0, max-content));
  grid-template-areas: "cred-config" "auto-refill" "history";
  grid-row-gap: 1.6rem;
  max-width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, minmax(0, max-content));
    grid-template-areas: "cred-config auto-refill auto-refill" "history history history";
    grid-gap: 1.6rem;
  }
`;
const GridHistory = styled.div`
  grid-area: history;
`;
const TableHeaderSorting = styled.th`
  color: #888 !important;
  position: sticky !important;
  top: 0;
`;

const TableRow = styled.tr`
  cursor: pointer;
  &&:hover {
    background-color: #edf0f4;
  }
`;

const query = graphql`
  query DiagnosticSearchQuery($queryString: String!, $useWildCard: String!) {
    ...DiagnosticSearch_query
  }
`;

function DiagnosticSearch({
  setSearchString,
  setSelectedResult,
  setSearchStatus,
  searchString,
}: {
  setSearchString: (searchString: string) => void;
  setSelectedResult: (res: any) => void;
  setSearchStatus: (status: string) => void;
  searchString: string;
}) {
  const data = useLazyLoadQuery<DiagnosticSearchQuery>(query, {
    queryString: searchString,
    useWildCard: "false",
  });

  // set input is kind of an intermediate value before setting the search string
  // this allows us to maintain the state of the search string while not searching everytime it changes
  const [input, setInput] = React.useState("");
  const [wildCard, setWildCard] = React.useState(false);

  const [searchResults, refetch] = useRefetchableFragment<
    DiagnosticSearchRefreshQuery,
    DiagnosticSearch_query$key
  >(
    graphql`
      fragment DiagnosticSearch_query on Query
      @refetchable(queryName: "DiagnosticSearchRefreshQuery") {
        systemDiagnosticsPanelSearch(
          queryString: $queryString
          useWildCard: $useWildCard
        ) {
          panelSerialNumber
          controlSystemName
          accountNumber
          controlSystemId
          customerId
          simIdentifier
          panelCommAddress
          hardwareModel
          panelSoftwareVersion
          panelSoftwareDate
          panelArmingSystem
          commType
        }
      }
    `,
    data
  );

  function selectResult(result: any) {
    setSelectedResult(result);
    setSearchStatus("selected");
  }

  return (
    <>
      <div className="panel pad-0 pad-v-24" style={{ height: "130px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchString(input);
            refetch(
              {
                queryString: searchString,
                useWildCard: wildCard.toString(),
              },
              { fetchPolicy: "network-only" }
            );
          }}
        >
          <Search
            name="queryInput"
            id="queryInput"
            placeholder="Search..."
            className="form-control input-sm"
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button
            className="btn btn-primary btn-sm"
            style={{ background: "white" }}
            disabled={input.length == 0}
            type="submit"
          >
            Search
          </button>
          <div
            className="col-sm-10"
            style={{ marginTop: "10px", marginLeft: "5px" }}
          >
            <label className="switch">
              <input
                type="checkbox"
                checked={!wildCard}
                onChange={() => setWildCard(!wildCard)}
              />
              <span></span>
            </label>
            <span style={{ marginLeft: "10px", verticalAlign: "top" }}>
              Exact Search
            </span>
          </div>
        </form>
        {searchString &&
          searchResults &&
          searchResults.systemDiagnosticsPanelSearch.length == 0 && (
            <ErrorText>
              <p>No results for that search string...</p>
            </ErrorText>
          )}
      </div>
      {searchString &&
        searchResults &&
        searchResults.systemDiagnosticsPanelSearch.length > 0 && (
          <Grid>
            <GridHistory>
              <Panel.Table style={{ maxHeight: "30em", overflow: "scroll" }}>
                <Table>
                  {searchString && (
                    <caption>Displaying Results for: "{searchString}"</caption>
                  )}
                  <thead>
                    <tr>
                      <TableHeaderSorting>Serial Number</TableHeaderSorting>
                      <TableHeaderSorting>
                        Control System Name
                      </TableHeaderSorting>
                      <TableHeaderSorting>Account Number</TableHeaderSorting>
                      <TableHeaderSorting>Control System ID</TableHeaderSorting>
                      <TableHeaderSorting>Customer ID</TableHeaderSorting>
                      <TableHeaderSorting>SIM</TableHeaderSorting>
                      <TableHeaderSorting>
                        Communicator Phone Number
                      </TableHeaderSorting>
                    </tr>
                  </thead>
                  <React.Suspense fallback={<GenericPageFallback />}>
                    <tbody>
                      {searchResults &&
                        searchResults.systemDiagnosticsPanelSearch.length > 0 &&
                        searchResults.systemDiagnosticsPanelSearch.map(
                          (result) => {
                            return (
                              <TableRow
                                key={result.controlSystemId}
                                onClick={() => {
                                  selectResult(result);
                                }}
                              >
                                <td>{result.panelSerialNumber}</td>
                                <td>{result.controlSystemName}</td>
                                <td>{result.accountNumber}</td>
                                <td>{result.controlSystemId}</td>
                                <td>{result.customerId}</td>
                                <td>{result.simIdentifier}</td>
                                <td>{result.panelCommAddress}</td>
                              </TableRow>
                            );
                          }
                        )}
                    </tbody>
                  </React.Suspense>
                </Table>
              </Panel.Table>
            </GridHistory>
          </Grid>
        )}
    </>
  );
}

export default DiagnosticSearch;
