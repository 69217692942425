import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsIpv6DnsServerField_networkOptions$key } from "./__generated__/NetworkOptionsIpv6DnsServerField_networkOptions.graphql";

export const networkOptionsIpv6DnsServerFieldId = () =>
  "network-options-ipv6-dns-server";

function NetworkOptionsIpv6DnsServerField() {
  const [{ ipv6Dns, ipv6Enabled, dhcpEnabled }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsIpv6DnsServerField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsIpv6DnsServerField_networkOptions on NetworkOptions {
          ipv6Dns
          ipv6Enabled
          dhcpEnabled
        }
      `
    );

  const fieldId = networkOptionsIpv6DnsServerFieldId();
  const disabled = !ipv6Enabled || dhcpEnabled;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="IPv6 DNS Server"
      disabled={!ipv6Enabled}
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={ipv6Dns}
        required
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "ipv6Dns");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsIpv6DnsServerField;
