import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { systemAreaInformationAnyBypassFieldId } from "components/FullProgramming/common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationAnyBypassField";
import { systemAreaInformationAreaSchedulesFieldId } from "components/FullProgramming/common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationAreaSchedulesField";
import { systemAreaInformationBurglaryBellOutputNumberFieldId } from "components/FullProgramming/common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationBurglaryBellOutputNumberField";
import { systemAreaInformationClosingCheckFieldId } from "components/FullProgramming/common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationClosingCheckField";
import { systemAreaInformationClosingCodeFieldId } from "components/FullProgramming/common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationClosingCodeField";
import { systemAreaInformationExitDelayFieldId } from "components/FullProgramming/common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationExitDelayField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XT75AreaInformationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/XT75AreaInformationProgrammingConceptForm/__generated__/XT75AreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { Xt75ProgrammingTemplateSystemAreaInformationInput } from "securecom-graphql/client";
import { getState as getSystemAreaInformationState } from "../../../XT75FullProgramming/XT75AreaInformationProgrammingConceptForm";
import { XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        systemAreaInformation {
          id
          included
          exitDelay {
            data
            included
          }
          openClosingReports {
            data
            included
          }
          burglaryBellOutputNumber {
            data
            included
          }
          closingCheck {
            data
            included
          }
          closingCode {
            data
            included
          }
          anyBypass {
            data
            included
          }
          areaSchedules {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XT75AreaInformationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): Xt75ProgrammingTemplateSystemAreaInformationInput => {
  const {
    panel: { systemAreaInformation },
  } = getSystemAreaInformationState(controlSystem);

  if (!systemAreaInformation) {
    return { included: false };
  }

  const systemAreaInformationInputFields = {
    exitDelay: {
      data: systemAreaInformation.exitDelay,
      included: includedFields.has(systemAreaInformationExitDelayFieldId()),
    },

    closingCheck: {
      data: systemAreaInformation.closingCheck,
      included: includedFields.has(systemAreaInformationClosingCheckFieldId()),
    },
    closingCode: {
      data: systemAreaInformation.closingCode,
      included: includedFields.has(systemAreaInformationClosingCodeFieldId()),
    },
    anyBypass: {
      data: systemAreaInformation.anyBypass,
      included: includedFields.has(systemAreaInformationAnyBypassFieldId()),
    },
    areaSchedules: {
      data: systemAreaInformation.areaSchedules,
      included: includedFields.has(systemAreaInformationAreaSchedulesFieldId()),
    },
  };

  const systemAreaInformationIncluded = Object.values(
    systemAreaInformationInputFields
  ).some(({ included }) => included);

  return {
    included: systemAreaInformationIncluded,
    ...systemAreaInformationInputFields,
  };
};

export const setIncludedFields = (
  template: XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemAreaInformation } = getState(template);

  if (systemAreaInformation?.exitDelay?.included) {
    setIncludedFields(setToggle(systemAreaInformationExitDelayFieldId()));
  }
  if (systemAreaInformation?.openClosingReports?.included) {
    setIncludedFields(setToggle(systemAreaInformationExitDelayFieldId()));
  }
  if (systemAreaInformation?.burglaryBellOutputNumber?.included) {
    setIncludedFields(
      setToggle(systemAreaInformationBurglaryBellOutputNumberFieldId())
    );
  }
  if (systemAreaInformation?.closingCheck?.included) {
    setIncludedFields(setToggle(systemAreaInformationClosingCheckFieldId()));
  }
  if (systemAreaInformation?.closingCode?.included) {
    setIncludedFields(setToggle(systemAreaInformationClosingCodeFieldId()));
  }
  if (systemAreaInformation?.anyBypass?.included) {
    setIncludedFields(setToggle(systemAreaInformationAnyBypassFieldId()));
  }
  if (systemAreaInformation?.areaSchedules?.included) {
    setIncludedFields(setToggle(systemAreaInformationAreaSchedulesFieldId()));
  }
};
