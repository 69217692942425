import * as React from "react";

const useTicker = (frequencyInMs: number) => {
  const [value, setValue] = React.useState(Symbol(""));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setValue(Symbol(""));
    }, frequencyInMs);

    return () => {
      clearInterval(interval);
    };
  }, [frequencyInMs, value]);

  return value;
};

export default useTicker;
