/**=========================================================
 * Collapse panels * [panel-do-collapse]
 =========================================================*/
App.directive("daPanelDoCollapse", [
  "$timeout",
  function ($timeout) {
    "use strict";

    var storageKeyName = "panelCollapseState",
      storage;

    return {
      restrict: "A",
      // transclude: true,
      controller: function ($scope, $element) {
        // Prepare the panel to be collapsible
        var $elem = $($element),
          parent = $elem.closest(".panel"), // find the first parent panel
          panelID = parent.attr("id");

        storage = $scope.$storage;

        // Load the saved state if exists
        var currentState = loadPanelState(panelID);
        if (typeof currentState !== undefined) {
          $timeout(function () {
            $scope[panelID] = currentState;
          }, 10);
        }

        // bind events to switch icons
        $element.bind("click", function () {
          savePanelState(panelID, !$scope[panelID]);
        });
      },
    };

    function savePanelState(id, state) {
      if (!id) return false;
      var data = angular.fromJson(storage[storageKeyName]);
      if (!data) {
        data = {};
      }
      data[id] = state;
      storage[storageKeyName] = angular.toJson(data);
    }

    function loadPanelState(id) {
      if (!id) return false;
      var data = angular.fromJson(storage[storageKeyName]);
      if (data) {
        return data[id];
      }
    }
  },
]);
