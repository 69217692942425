import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from ".././ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationSendCommunicationTroubleField_communication$key } from "./__generated__/CommunicationSendCommunicationTroubleField_communication.graphql";

export const communicationSendCommunicationTroubleFieldId = () =>
  "communication-send-communication-trouble";

function CommunicationSendCommunicationTroubleField() {
  const [{ netTrouble }, updateCommunication] =
    useCommunicationFragment<CommunicationSendCommunicationTroubleField_communication$key>(
      graphql`
        fragment CommunicationSendCommunicationTroubleField_communication on Communication {
          netTrouble
        }
      `
    );

  const fieldId = communicationSendCommunicationTroubleFieldId();
  const label = "Send Communication Trouble";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Displays for each path and determines if and how communication trouble on the path is sent to the receiver"
    >
      <Switch
        id={fieldId}
        label={label}
        checked={netTrouble}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(!netTrouble, "netTrouble");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationSendCommunicationTroubleField;
