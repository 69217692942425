import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { CommunicationPathType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathAlarmReportsField_communicationPath$key } from "./__generated__/CommPathAlarmReportsField_communicationPath.graphql";

export const commPathAlarmReportsFieldId = (number: string) =>
  `comm-path-alarm-reports-${number}`;

function CommPathAlarmReportsField() {
  const [{ type, number, alarmReports }, updateCommPath] =
    useCommPathFragment<CommPathAlarmReportsField_communicationPath$key>(
      graphql`
        fragment CommPathAlarmReportsField_communicationPath on CommunicationPath {
          number
          alarmReports
          type
        }
      `
    );

  const fieldId = commPathAlarmReportsFieldId(String(number));
  const disabled = type === CommunicationPathType.BACKUP;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Alarm Reports"
      tooltip="Turns on Zone Alarm reports and enables Alarm Restore, Bypass, and Reset reports if programmed in System Reports. (for all Zones or just Fire / Fire Verify Zones)"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        value={alarmReports}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "alarmReports");
          });
        }}
      >
        <Select.Option value="YES">Yes</Select.Option>
        <Select.Option value="NO">No</Select.Option>
        <Select.Option value="FIRE">Fire</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathAlarmReportsField;
