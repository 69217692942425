import { Z_INDEX } from "common/utils/styles";
import { mergeAll } from "ramda";
import React from "react";
import { animated, config, useTransition } from "react-spring";
import styled, { css, useTheme } from "styled-components/macro";
import { themeGrayDark, themePanelBorder, themeTrueWhite } from "../Theme";

type TooltipTheme = "dark" | "warning" | "error" | "none";
type TooltipPosition = "top" | "bottom";

export type TooltipProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  position?: TooltipPosition;
  tooltipTheme?: TooltipTheme;
  style?: React.CSSProperties;
};

const PlainTooltip = ({
  children,
  isOpen,
  position,
  tooltipTheme,
  style: propStyles,
}: TooltipProps) => {
  const themeContext = useTheme();
  const messageTheme = React.useMemo(
    () => createTheme(tooltipTheme)(themeContext),
    [tooltipTheme, themeContext]
  );

  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  return (
    <>
      {transitions(
        (style, isOpenVal) =>
          isOpenVal && (
            <>
              <TooltipModalDiv
                style={propStyles ? mergeAll([style, propStyles]) : style}
                tooltipTheme={tooltipTheme ?? "none"}
                position={position}
              >
                {children}
                <Arrow viewBox={"0 0 80 40"} position={position}>
                  <path
                    fill={messageTheme.arrowFill}
                    stroke={messageTheme.arrowStrokeColor}
                    strokeWidth={messageTheme.arrowStrokeWidth}
                    d={"M5 40 L40 5 L75 40"}
                  />
                </Arrow>
              </TooltipModalDiv>
            </>
          )
      )}
    </>
  );
};

export default PlainTooltip;

export const POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
};

const createTheme = (themeName: string | undefined) => (theme: any) => {
  switch (themeName) {
    case "dark":
      return {
        ...theme,
        padding: 0,
        backgroundColor: theme.grayDark,
        textColor: "white",
        borderColor: "none",
        borderWidth: "0",
        arrowFill: theme.grayDark,
        arrowStrokeColor: undefined,
        arrowStrokeWidth: undefined,
      };
    case "warning":
      return {
        ...theme,
        padding: "1rem 2rem 1rem 1.5rem",
        backgroundColor: "white",
        textColor: theme.grayDark,
        borderColor: theme.warning,
        borderWidth: "8px 1px 1px",
        arrowFill: theme.warning,
        arrowStrokeColor: undefined,
        arrowStrokeWidth: undefined,
      };
    case "error":
      return {
        ...theme,
        padding: "1rem 2rem 1rem 1.5rem",
        backgroundColor: "white",
        textColor: theme.grayDark,
        borderColor: theme.red600,
        borderWidth: "8px 1px 1px",
        arrowFill: theme.red600,
        arrowStrokeColor: undefined,
        arrowStrokeWidth: undefined,
        fontWeight: 600,
        lineHeight: 1.2,
      };
    default:
      return {
        ...theme,
        padding: 0,
        backgroundColor: "white",
        textColor: theme.grayDark,
        borderColor: theme.panelBorderColor,
        borderWidth: "1px",
        arrowFill: "white",
        arrowStrokeColor: theme.panelBorderColor,
        arrowStrokeWidth: 5,
      };
  }
};

const TooltipModalDiv = styled(animated.div as any)`
  display: flex;
  justify-content: center;
  width: auto;
  position: absolute;
  z-index: ${Z_INDEX.TOOLTIP};
  background-color: ${({ tooltipTheme }) =>
    tooltipTheme && tooltipTheme === "dark" ? themeGrayDark : themeTrueWhite};
  color: ${({ tooltipTheme }) =>
    tooltipTheme && tooltipTheme === "dark" ? themeTrueWhite : themeGrayDark};
  border: ${themePanelBorder};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.elevation300};

  ${({ position }) => {
    switch (position) {
      case POSITIONS.TOP:
        return css`
          transform: translateY(-2.5rem);
        `;
      case POSITIONS.BOTTOM:
        return css`
          transform: translateY(2.5rem);
        `;

      default:
        //TOP
        return css`
          transform: translateY(-2.5rem);
        `;
    }
  }};
`;

const Arrow = styled.svg<any>`
  position: absolute;

  ${({ position }) => {
    switch (position) {
      case POSITIONS.BOTTOM:
        return css`
          top: 1px;
          left: 50%;
          transform: translate(-50%, -100%);
          width: 1em;
          height: 0.5em;
          margin-top: -1px;
        `;
      case POSITIONS.TOP:
        return css`
          bottom: 1px;
          left: 50%;
          transform: translate(-50%, 100%) rotate(180deg);
          width: 1em;
          height: 0.5em;
          margin-bottom: -1px;
        `;

      default:
        //TOP
        return css`
          bottom: 1px;
          left: 50%;
          transform: translate(-50%, 100%) rotate(180deg);
          width: 1em;
          height: 0.5em;
          margin-bottom: -1px;
        `;
    }
  }};
`;
