/**
 * @generated SignedSource<<d950cc734247405ae1303a6c3976ffe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRPcLogReportsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly pcLogReports: {
      readonly __typename: "PcLogReports";
      readonly armDisarmReportsEnabled: boolean;
      readonly doorAccessReportsEnabled: boolean;
      readonly id: string;
      readonly logRealTimeStatusEnabled: boolean;
      readonly networkIpAddress: string;
      readonly networkPort: string;
      readonly supervisoryReportsEnabled: boolean;
      readonly userCommandReportsEnabled: boolean;
      readonly zonesReportsEnabled: boolean;
    } | null;
  };
  readonly " $fragmentType": "XRPcLogReportsProgrammingConceptFormInline_controlSystem";
};
export type XRPcLogReportsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRPcLogReportsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRPcLogReportsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRPcLogReportsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "caaf17f55fa74b7f3e5ae74a26d09fa0";

export default node;
