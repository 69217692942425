import graphql from "babel-plugin-relay/macro";
import Modal from "components/Modal";
import VideoSelection from "components/SiteForm/ExistingSite/VideoForm/Components/VideoSelection";
import { pick } from "ramda";
import React, { useReducer } from "react";
import { useMutation } from "react-relay/hooks";
import { SheetButton } from "../../Layout/Layout";
import { VideoFormModalUpdateSiteVideoIntegrationsMutation } from "./__generated__/VideoFormModalUpdateSiteVideoIntegrationsMutation.graphql";

type Props = {
  siteId: string;
  videoEnabled: boolean;
  securecomNvrEnabled: boolean;
  hikvisionDoorbellEnabled: boolean;
  openEyeEnabled: boolean;
  hikvisionNvrEnabled: boolean;
  eagleEyeEnabled: boolean;
  digitalWatchdogSpectrumEnabled: boolean;
  hanwhaWaveEnabled: boolean;
  onCancel: () => void;
};

export type Actions = {
  type:
    | "SECURE_COM_NVR_VIDEO_TOGGLED"
    | "HIKVISION_DOORBELL_TOGGLED"
    | "OPEN_EYE_ENABLED_TOGGLED"
    | "HIK_VISION_NVR_ENABLED_TOGGLED"
    | "EAGLE_EYE_ENABLED_TOGGLED"
    | "DIGITAL_WATCHDOG_SPECTRUM_ENABLED_TOGGLE"
    | "RING_DOORBELL_ENABLED_TOGGLE"
    | "HANWHA_WAVE_ENABLED_TOGGLE";
};

export type VideoState = {
  videoEnabled: boolean;
  securecomNvrEnabled: boolean;
  hikvisionDoorbellEnabled: boolean;
  openEyeEnabled: boolean;
  hikvisionNvrEnabled: boolean;
  eagleEyeEnabled: boolean;
  digitalWatchdogSpectrumEnabled: boolean;
  hanwhaWaveEnabled: boolean;
};

function initState(props: Props): VideoState {
  return pick(
    [
      "videoEnabled",
      "securecomNvrEnabled",
      "hikvisionDoorbellEnabled",
      "openEyeEnabled",
      "hikvisionNvrEnabled",
      "eagleEyeEnabled",
      "digitalWatchdogSpectrumEnabled",
      "hanwhaWaveEnabled",
    ],
    props
  );
}

export function reducer(state: VideoState, action: Actions): VideoState {
  switch (action.type) {
    case "SECURE_COM_NVR_VIDEO_TOGGLED": {
      const isEnabled = !state.securecomNvrEnabled;
      return {
        ...state,
        securecomNvrEnabled: isEnabled,
      };
    }
    case "HIKVISION_DOORBELL_TOGGLED": {
      const isEnabled = !state.hikvisionDoorbellEnabled;
      return {
        ...state,
        hikvisionDoorbellEnabled: isEnabled,
      };
    }
    case "OPEN_EYE_ENABLED_TOGGLED":
      return {
        ...state,
        openEyeEnabled: !state.openEyeEnabled,
      };
    case "HIK_VISION_NVR_ENABLED_TOGGLED":
      return {
        ...state,
        hikvisionNvrEnabled: !state.hikvisionNvrEnabled,
      };
    case "EAGLE_EYE_ENABLED_TOGGLED":
      return {
        ...state,
        eagleEyeEnabled: !state.eagleEyeEnabled,
      };
    case "DIGITAL_WATCHDOG_SPECTRUM_ENABLED_TOGGLE":
      return {
        ...state,
        digitalWatchdogSpectrumEnabled: !state.digitalWatchdogSpectrumEnabled,
      };
    case "HANWHA_WAVE_ENABLED_TOGGLE":
      return {
        ...state,
        hanwhaWaveEnabled: !state.hanwhaWaveEnabled,
      };
    default:
      return state;
  }
}

export default function VideoFormModal(props: Props) {
  const [state, dispatch] = useReducer(reducer, props, initState);

  const [updateSiteVideoIntegrations, updating] =
    useMutation<VideoFormModalUpdateSiteVideoIntegrationsMutation>(
      graphql`
        mutation VideoFormModalUpdateSiteVideoIntegrationsMutation(
          $siteId: ID!
          $videoIntegrations: SiteVideoIntegrations!
        ) {
          results: updateSiteVideoIntegrations(
            siteId: $siteId
            videoIntegrations: $videoIntegrations
          ) {
            ... on NotFoundError {
              errorType: type
            }
            ... on Site {
              id
              ...SiteVideoIntegrationsSection_site
            }
          }
        }
      `
    );

  return (
    <Modal>
      <div className={"flex--row--between--center"}></div>
      <Modal.Header>
        <h3 className="mar-0">
          Select Video Services
          <div className={"pull-right"}>
            <button
              type="button"
              className={"btn btn-sm btn-default mar-r-8"}
              onClick={props.onCancel}
              disabled={updating}
            >
              Close
            </button>
            <button
              type="button"
              className={"btn btn-sm btn-dmp"}
              disabled={updating}
              onClick={() =>
                updateSiteVideoIntegrations({
                  variables: {
                    siteId: props.siteId,
                    videoIntegrations: state,
                  },
                  onCompleted: props.onCancel,
                })
              }
            >
              {updating ? (
                <>
                  <i className="fa fa-spin fa-spinner"></i> <span>Saving</span>
                </>
              ) : (
                <>Save</>
              )}
            </button>
          </div>
        </h3>
      </Modal.Header>
      <Modal.Body>
        <SheetButton
          onClick={() => {
            dispatch({ type: "SECURE_COM_NVR_VIDEO_TOGGLED" });
          }}
        >
          <VideoSelection>
            <VideoSelection.Icon>
              <i className="icon-camera"></i>
            </VideoSelection.Icon>
            <VideoSelection.Description
              title={<>Cameras &amp; NVR</>}
              action={
                <>
                  <VideoSelection.Checkbox
                    checked={state.securecomNvrEnabled}
                  />
                </>
              }
            >
              Stream live video from up to two eight-channel NVRs. Or view
              recorded clips from up to 16 cameras.
            </VideoSelection.Description>
          </VideoSelection>
        </SheetButton>
        <SheetButton
          onClick={() => {
            // The SecureCom DMP in essence is a brand of Hikvision doorbell
            dispatch({ type: "HIKVISION_DOORBELL_TOGGLED" });
          }}
        >
          {/* <!--SECURECOM VIDEO DOORBELL --> */}

          <VideoSelection>
            <VideoSelection.Icon>
              <i className="icon-camera"></i>
            </VideoSelection.Icon>
            <VideoSelection.Description
              title={<>Video Doorbell</>}
              action={
                <>
                  <VideoSelection.Checkbox
                    checked={state.hikvisionDoorbellEnabled}
                  />
                </>
              }
            >
              Enables video doorbell features.
            </VideoSelection.Description>
          </VideoSelection>
        </SheetButton>
        <SheetButton
          onClick={() => {
            dispatch({ type: "OPEN_EYE_ENABLED_TOGGLED" });
          }}
        >
          {/* <!--OPENEYE --> */}{" "}
          <VideoSelection>
            <VideoSelection.Icon>
              <img
                className={"video-icon"}
                src="assets/img/third-party-logos/logo-OpenEye.svg"
                alt="OpenEye logo"
              />
            </VideoSelection.Icon>
            <VideoSelection.Description
              title={<>OpenEye&reg;</>}
              action={
                <>
                  <VideoSelection.Checkbox checked={state.openEyeEnabled} />
                </>
              }
            >
              Enable OpenEye Cloud-to-Cloud integration.
            </VideoSelection.Description>
          </VideoSelection>
        </SheetButton>
        <SheetButton
          onClick={() => {
            dispatch({ type: "HIK_VISION_NVR_ENABLED_TOGGLED" });
          }}
        >
          {/* <!--HIKVISION NVR --> */}

          <VideoSelection>
            <VideoSelection.Icon>
              <img
                className={"video-icon"}
                src="assets/img/third-party-logos/logo-hikvision.svg"
                alt="Hikvision logo"
              />
            </VideoSelection.Icon>
            <VideoSelection.Description
              title={<>Hikvision&reg; NVR</>}
              action={
                <>
                  <VideoSelection.Checkbox
                    checked={state.hikvisionNvrEnabled}
                  />
                </>
              }
            >
              Enable Hikvision NVR integration.
            </VideoSelection.Description>
          </VideoSelection>
        </SheetButton>
        <SheetButton
          onClick={() => {
            dispatch({ type: "EAGLE_EYE_ENABLED_TOGGLED" });
          }}
        >
          {/* <!--Eagle Eye --> */}

          <VideoSelection>
            <VideoSelection.Icon>
              <img
                className={"video-icon"}
                src="assets/img/third-party-logos/logo-eagle-eye-bird.svg"
                alt="EagleEye logo"
              />
            </VideoSelection.Icon>
            <VideoSelection.Description
              title={<>EagleEye&reg;</>}
              action={
                <>
                  <VideoSelection.Checkbox checked={state.eagleEyeEnabled} />
                </>
              }
            >
              Enable EagleEye Cloud-to-Cloud integration.
            </VideoSelection.Description>
          </VideoSelection>
        </SheetButton>
        <SheetButton
          onClick={() => {
            dispatch({
              type: "DIGITAL_WATCHDOG_SPECTRUM_ENABLED_TOGGLE",
            });
          }}
        >
          <VideoSelection>
            <VideoSelection.Icon>
              <img
                src="assets/img/third-party-logos/logo-digital-watchdog.svg"
                alt="Digital Watchdog Logo"
              />
            </VideoSelection.Icon>
            <VideoSelection.Description
              title={<>Digital Watchdog Spectrum&reg; IPVMS</>}
              action={
                <>
                  <VideoSelection.Checkbox
                    checked={state.digitalWatchdogSpectrumEnabled}
                  />
                </>
              }
            >
              Enables Digital Watchdog Blackjack or DW Cloud integration.
            </VideoSelection.Description>
          </VideoSelection>
        </SheetButton>
        <SheetButton
          onClick={() => {
            dispatch({
              type: "HANWHA_WAVE_ENABLED_TOGGLE",
            });
          }}
        >
          <VideoSelection>
            <VideoSelection.Icon>
              <img
                src="assets/img/third-party-logos/logo-hanwha-wave.svg"
                alt="Hanwha Wave logo"
              />
            </VideoSelection.Icon>
            <VideoSelection.Description
              title={<>Hanwha Wave&reg;</>}
              action={
                <VideoSelection.Checkbox checked={state.hanwhaWaveEnabled} />
              }
            >
              Enables Hanwha Wave Cloud-to-Cloud integration.
            </VideoSelection.Description>
          </VideoSelection>
        </SheetButton>
      </Modal.Body>
    </Modal>
  );
}
