import { App } from "app-module";
import { defaultTheme } from "app/common/DaColors";
import Modal from "common/components/web/Modal";
import useForceUpdate from "common/hooks/useForceUpdate";
import { ChangePasswordModal } from "components/ChangePasswordModal";
import { GenericPageFallback } from "components/GenericPageFallback";
import { UserServiceType } from "components/SiteForm/types";
import React, { useState } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { react2angular } from "react2angular";
import { ThemeProvider } from "styled-components";
import { AlertsContextProvider } from "../../contexts/AlertsContext";
import TwoFASetup from "../TwoFASetup";

export default function TwoFaRoot({
  RelayService,
  UserService,
  $rootScope,
}: any) {
  const environment = RelayService.getEnvironment();

  const forceUpdate = useForceUpdate();
  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <TwoFaList
            UserService={UserService}
            environment={environment}
            forceUpdate={forceUpdate}
          />
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}
type Props = {
  UserService: any;
  environment: any;
  forceUpdate: () => void;
};
export const TwoFaList = (props: Props) => {
  return (
    <>
      <ChangePasswordModal {...props} forceUpdate={props.forceUpdate} />
      <TwoFa UserService={props.UserService} environment={props.environment} />
    </>
  );
};

export function TwoFa({
  UserService,
}: {
  UserService: UserServiceType & { dealer_id: string };
  environment: any;
}) {
  const [settingsOpen, setSettingsOpen] = useState(false);
  return (
    <>
      <button
        className="line-item-button bb"
        onClick={() => {
          setSettingsOpen(true);
        }}
      >
        Two Factor Authentication
      </button>
      {settingsOpen && (
        <ThemeProvider theme={defaultTheme}>
          <Modal>
            <Modal.Body>
              <TwoFASetup closeModal={() => setSettingsOpen(false)} />
            </Modal.Body>
          </Modal>
        </ThemeProvider>
      )}
    </>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "twoFaRoot",
    react2angular(
      TwoFaRoot,
      [],
      ["$rootScope", "UserService", "CustomerReferralsService", "RelayService"]
    )
  );
}
