/**
 * @ngdoc service
 * @name App.factory:ThirdPartyVideoAPI
 *
 * @description
 * API factory for Third Party Video
 *
 */

App.factory("ThirdPartyVideoAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.thirdPartyVideo +
        "/api/v1/video_devices/:type/:video_device_id/:action",
      {},
      {
        connectDevice: { method: "POST", params: { type: "connect" } },
        refreshDevice: {
          method: "POST",
          params: {
            action: "refresh_channels",
            video_device_id: "@video_device_id",
          },
        },
        getDevicesList: { method: "GET", params: {}, isArray: true },
        getSingleDevice: {
          method: "GET",
          params: { video_device_id: "@video_device_id" },
        },
        destroyDevice: {
          method: "DELETE",
          params: { video_device_id: "@video_device_id" },
          isArray: true,
        },
        updateDevice: {
          method: "POST",
          params: { video_device_id: "@video_device_id" },
        },
        updateCredentials: {
          method: "POST",
          params: {
            action: "credentials",
            video_device_id: "@video_device_id",
          },
        },
        getVideoConnectionInformation: {
          method: "GET",
          params: { type: "connection_info" },
        },
        createOrUpdateVideoConnectionInformation: {
          method: "POST",
          params: { type: "connection_info" },
        },
        deleteVideoConnectionInformation: {
          method: "DELETE",
          params: { type: "connection_info" },
        },
      }
    );
  },
]);

App.factory("HikvisionDoorbellApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.thirdPartyVideo + "/api/v1/hikvision_doorbells/:id/:group/:action",
      {},
      {
        delete: { method: "DELETE" },

        // Firmware
        getFirmwareInfo: {
          method: "GET",
          params: { group: "firmware", action: "info" },
        },
        getFirmwareUpgradeStatus: {
          method: "GET",
          params: { group: "firmware", action: "upgrade/status" },
        },
        initiateFirmwareUpgrade: {
          method: "POST",
          params: { id: "@id", group: "firmware", action: "upgrade" },
        },
      }
    );
  },
]);
