import graphql from "babel-plugin-relay/macro";
import LoadingSpinner from "common/components/web/LoadingSpinner";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { useMutation } from "react-relay/hooks";
import styled from "styled-components";
import { PutSystemOnTestModalMutation } from "./__generated__/PutSystemOnTestModalMutation.graphql";

const ModalBody = styled.div`
  padding: 1rem 2rem;
`;
const Title = styled.h1`
  font-size: 28px;
`;
const Description = styled.p`
  font-size: 14px;
`;
const SelectWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 1rem;
`;
const Label = styled.label`
  flex-grow: 0;
  padding: 0;
  margin: 0;
  padding-right: 1em;
  white-space: nowrap;
`;
const Select = styled.select``;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;
const Button = styled.button`
  margin: 0.5em;
`;
const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

enum Duration {
  OneHour = 60,
  TwoHours = 120,
  ThreeHours = 180,
  FourHours = 240,
}

const mutation = graphql`
  mutation PutSystemOnTestModalMutation($systemId: ID!, $minutes: Int!) {
    putSystemOnTest(systemId: $systemId, minutes: $minutes) {
      ... on PutSystemOnTestSuccessResponse {
        integration {
          id
          systemTestExpiresAt
        }
      }
      ... on PutSystemOnTestErrorResponse {
        error {
          type
        }
      }
    }
  }
`;

const PutSystemOnTestModal: React.FC<
  React.PropsWithChildren<{
    onClose: () => void;
    systemId: string;
  }>
> = ({ onClose, systemId }) => {
  const [putSystemOnTest, isPending] =
    useMutation<PutSystemOnTestModalMutation>(mutation);
  const [duration, setDuration] = React.useState<Duration>(Duration.OneHour);

  const showAlert = useShowAlert();

  function showFailureAlert() {
    showAlert({
      type: "error",
      text: "An error occurred when attempting to put system on test. Please check your credentials are valid and try again.",
    });
  }

  return (
    <Modal onClickOutside={onClose}>
      <ModalBody>
        <Title>Place System On Test</Title>
        <Description>
          During a system test, the alarm system will continue to send messages
          to the monitoring center, but emergency services will not be
          contacted.
        </Description>

        <SelectWrapper>
          <Label className="control-label" htmlFor="duration-select">
            Test Duration
          </Label>
          <Select
            id="duration-select"
            className="form-control"
            value={duration}
            onChange={(event) => {
              setDuration(Number(event.target.value) as Duration);
            }}
          >
            <option value={Duration.OneHour}>1 hour</option>
            <option value={Duration.TwoHours}>2 hours</option>
            <option value={Duration.ThreeHours}>3 hours</option>
            <option value={Duration.FourHours}>4 hours</option>
          </Select>
        </SelectWrapper>

        <ButtonContainer>
          <Button className="btn btn-default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn btn-dmp"
            onClick={() => {
              putSystemOnTest({
                variables: {
                  systemId,
                  minutes: duration,
                },
                onCompleted: ({ putSystemOnTest }) => {
                  if ("error" in putSystemOnTest) {
                    showFailureAlert();
                  } else {
                    onClose();
                  }
                },
                onError: showFailureAlert,
              });
            }}
          >
            <ButtonTextWrapper>
              Confirm
              {isPending && (
                <div style={{ display: "inline-block", padding: "0 0.5em" }}>
                  <LoadingSpinner />
                </div>
              )}
            </ButtonTextWrapper>
          </Button>
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};

export default PutSystemOnTestModal;
