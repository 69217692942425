import styled from "styled-components/macro";
import Button from "./Button";

const PrimaryButton = styled(Button)`
  --bg-color: var(--color-primary-500);
  --border-color: var(--bg-color);
  --text-color: white;

  &:hover {
    --bg-color: var(--color-primary-600);
  }
  &:active {
    --bg-color: var(--color-primary-700);
  }
  &:not(:enabled) {
    --bg-color: var(--color-neutral-300);
    --border-color: var(--color-neutral-400);
    --text-color: var(--color-neutral-600);
  }
`;

export default PrimaryButton;
