import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  Zone,
  ZonePetImmunity,
  ZonePirPulseCount,
  ZonePirSensitivity,
  ZoneSupervisionTime,
  ZoneTransmitterContact,
} from "securecom-graphql/client";
import {
  useControlSystem,
  useIsTakeoverPanelWithEcpOrDscEnabled,
} from "../ControlSystemContext";
import {
  useBuiltIn1100WirelessEnabled,
  useHardwareModel,
  usePanelFragment,
  useSoftwareVersion,
  useWirelessZoneNumberRange,
  useZoneNumberRange,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { resolveZoneFieldRules } from "./utils";
import { zoneInformationAreaFieldId } from "./ZoneInformationAreaField";
import { zoneInformationArmAreasFieldId } from "./ZoneInformationArmAreasField";
import { zoneInformationArmedOpenMessageFieldId } from "./ZoneInformationArmedOpenMessageField";
import { zoneInformationArmedOpenOutputActionFieldId } from "./ZoneInformationArmedOpenOutputActionField";
import { zoneInformationArmedOpenOutputNumberFieldId } from "./ZoneInformationArmedOpenOutputNumberField";
import { zoneInformationArmedShortMessageFieldId } from "./ZoneInformationArmedShortMessageField";
import { zoneInformationArmedShortOutputActionFieldId } from "./ZoneInformationArmedShortOutputActionField";
import { zoneInformationArmedShortOutputNumberFieldId } from "./ZoneInformationArmedShortOutputNumberField";
import { zoneInformationArmingStyleFieldId } from "./ZoneInformationArmingStyleField";
import { zoneInformationChimeSoundFieldId } from "./ZoneInformationChimeSoundField";
import { zoneInformationCompetitorWirelessFieldId } from "./ZoneInformationCompetitorWirelessField";
import { zoneInformationContactFieldId } from "./ZoneInformationContactField";
import { zoneInformationContactNormallyOpenFieldId } from "./ZoneInformationContactNormallyOpenField";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { zoneInformationCrossZoneFieldId } from "./ZoneInformationCrossZoneField";
import { zoneInformationDisarmDisableFieldId } from "./ZoneInformationDisarmDisableField";
import { zoneInformationDisarmedOpenMessageFieldId } from "./ZoneInformationDisarmedOpenMessageField";
import { zoneInformationDisarmedOpenOutputActionFieldId } from "./ZoneInformationDisarmedOpenOutputActionField";
import { zoneInformationDisarmedOpenOutputNumberFieldId } from "./ZoneInformationDisarmedOpenOutputNumberField";
import { zoneInformationDisarmedShortMessageFieldId } from "./ZoneInformationDisarmedShortMessageField";
import { zoneInformationDisarmedShortOutputActionFieldId } from "./ZoneInformationDisarmedShortOutputActionField";
import { zoneInformationDisarmedShortOutputNumberFieldId } from "./ZoneInformationDisarmedShortOutputNumberField";
import { zoneInformationDMPWirelessFieldId } from "./ZoneInformationDMPWirelessField";
import { zoneInformationEntryDelayFieldId } from "./ZoneInformationEntryDelayField";
import { zoneInformationFastResponseFieldId } from "./ZoneInformationFastResponseField";
import { zoneInformationFirePanelSlaveInputFieldId } from "./ZoneInformationFirePanelSlaveInputField";
import { zoneInformationFollowAreaFieldId } from "./ZoneInformationFollowAreaField";
import { zoneInformationLEDOperationFieldId } from "./ZoneInformationLEDOperationField";
import { zoneInformationLockdownFieldId } from "./ZoneInformationLockdownField";
import { zoneInformationNameFieldId } from "./ZoneInformationNameField";
import { zoneInformationNumberOfEolsFieldId } from "./ZoneInformationNumberOfEolsField";
import { zoneInformationPIRPetImmunityFieldId } from "./ZoneInformationPIRPetImmunityField";
import { zoneInformationPIRPulseCountFieldId } from "./ZoneInformationPIRPulseCountField";
import { zoneInformationPIRSensitivityFieldId } from "./ZoneInformationPIRSensitivityField";
import { zoneInformationPresignalKeypadsFieldId } from "./ZoneInformationPresignalKeypadsField";
import { zoneInformationPrewarnKeypadsFieldId } from "./ZoneInformationPrewarnKeypadsField";
import { zoneInformationPriorityZoneFieldId } from "./ZoneInformationPriorityZoneField";
import { zoneInformationReceiverRoutingFieldId } from "./ZoneInformationReceiverRoutingField";
import { zoneInformationReportWithAccountNumberFieldId } from "./ZoneInformationReportWithAccountNumberField";
import { zoneInformationSensorTypeFieldId } from "./ZoneInformationSensorTypeField";
import { zoneInformationSupervisionTimeFieldId } from "./ZoneInformationSupervisionTimeField";
import { zoneInformationSwingerBypassFieldId } from "./ZoneInformationSwingerBypassField";
import { zoneInformationTrafficCountFieldId } from "./ZoneInformationTrafficCountField";
import { zoneInformationTypeFieldId } from "./ZoneInformationTypeField";
import { zoneInformationZoneAuditDaysFieldId } from "./ZoneInformationZoneAuditDaysField";
import { zoneInformationZoneRealTimeStatusFieldId } from "./ZoneInformationZoneRealTimeStatus";
import { zoneInformationZoneRetardDelayFieldId } from "./ZoneInformationZoneRetardDelayField";
import { ZoneInformationNumberField_panel$key } from "./__generated__/ZoneInformationNumberField_panel.graphql";
import { ZoneInformationNumberField_zone$key } from "./__generated__/ZoneInformationNumberField_zone.graphql";

export const zoneListItemTemplateId = (number: string) => `zone-${number}`;
export const ZONE_INFORMATION_NUMBER = "zone-information-number-field-";
export const zoneInformationNumberFieldId = (number: string) =>
  ZONE_INFORMATION_NUMBER + number;
export const ZONE_IDS = [
  zoneListItemTemplateId,
  zoneInformationNumberFieldId,
  zoneInformationNameFieldId,
  zoneInformationTypeFieldId,
  zoneInformationAreaFieldId,
  zoneInformationFollowAreaFieldId,
  zoneInformationReportWithAccountNumberFieldId,
  zoneInformationArmAreasFieldId,
  zoneInformationDisarmedOpenMessageFieldId,
  zoneInformationDisarmedOpenOutputNumberFieldId,
  zoneInformationDisarmedOpenOutputActionFieldId,
  zoneInformationDisarmedShortMessageFieldId,
  zoneInformationDisarmedShortOutputNumberFieldId,
  zoneInformationDisarmedShortOutputActionFieldId,
  zoneInformationArmedOpenMessageFieldId,
  zoneInformationArmedOpenOutputNumberFieldId,
  zoneInformationArmedOpenOutputActionFieldId,
  zoneInformationArmedShortMessageFieldId,
  zoneInformationArmingStyleFieldId,
  zoneInformationArmedShortOutputNumberFieldId,
  zoneInformationArmedShortOutputActionFieldId,
  zoneInformationSwingerBypassFieldId,
  zoneInformationZoneRetardDelayFieldId,
  zoneInformationFastResponseFieldId,
  zoneInformationPrewarnKeypadsFieldId,
  zoneInformationPresignalKeypadsFieldId,
  zoneInformationCrossZoneFieldId,
  zoneInformationEntryDelayFieldId,
  zoneInformationPriorityZoneFieldId,
  zoneInformationFirePanelSlaveInputFieldId,
  zoneInformationZoneRealTimeStatusFieldId,
  zoneInformationTrafficCountFieldId,
  zoneInformationZoneAuditDaysFieldId,
  zoneInformationDMPWirelessFieldId,
  zoneInformationContactFieldId,
  zoneInformationSupervisionTimeFieldId,
  zoneInformationLEDOperationFieldId,
  zoneInformationDisarmDisableFieldId,
  zoneInformationPIRPulseCountFieldId,
  zoneInformationPIRSensitivityFieldId,
  zoneInformationPIRPetImmunityFieldId,
  zoneInformationChimeSoundFieldId,
  zoneInformationLockdownFieldId,
  zoneInformationContactNormallyOpenFieldId,
  zoneInformationCompetitorWirelessFieldId,
  zoneInformationSensorTypeFieldId,
  zoneInformationReceiverRoutingFieldId,
  zoneInformationNumberOfEolsFieldId,
];

function ZoneInformationNumberField() {
  const [{ number, id, isECP, isNew }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationNumberField_zone$key>(
      graphql`
        fragment ZoneInformationNumberField_zone on Zone {
          id
          number
          number
          isECP
          wireless
          competitorWireless
          isNew
        }
      `
    );

  const [{ zoneInformations }] =
    usePanelFragment<ZoneInformationNumberField_panel$key>(graphql`
      fragment ZoneInformationNumberField_panel on Panel {
        zoneInformations {
          number
        }
      }
    `);

  const duplicateKeyfobsExist =
    zoneInformations.filter(
      ({ number: zoneNumber }) => Number(zoneNumber) === Number(number)
    ).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      ZONE_IDS,
      duplicateKeyfobsExist,
      String(number),
      zoneListItemTemplateId
    );

  const fieldId = zoneInformationNumberFieldId(String(number));
  const zoneNumberRange = useZoneNumberRange();
  const softwareVersion = useSoftwareVersion();
  const hardwareModel = useHardwareModel();
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();
  const hasBuiltInWirelessEnabled = useBuiltIn1100WirelessEnabled();
  const isTakeoverPanelWithEcpOrDscEnabled =
    useIsTakeoverPanelWithEcpOrDscEnabled();
  const wirelessZones = useWirelessZoneNumberRange();
  const disabled = !isNew || isECP;
  const { isCellComEx, isTMSentry } = resolvePanelType(hardwareModel);

  const supportedZoneNumbers =
    isCellComEx && isTakeoverPanelWithEcpOrDscEnabled
      ? [201]
      : isTakeoverPanelWithEcpOrDscEnabled
      ? range(201, 204)
      : isTMSentry
      ? range(1, 7)
      : zoneNumberRange;

  const { NUMBER: RULES } = resolveZoneFieldRules(
    hardwareModel,
    softwareVersion,
    hasBuiltInWirelessEnabled,
    isTakeoverPanelWithEcpOrDscEnabled
  );

  // fix: DA-3740
  const ZONE_NUMBER_PLACEHOLDER_TEXT = RULES.INLINE_HELP.slice(0, 10) + " ...";

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Zone Number">
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={number}
        pattern={RULES.PATTERN}
        inlineHelp={RULES.INLINE_HELP}
        placeholderText={ZONE_NUMBER_PLACEHOLDER_TEXT}
        required
        disabled={disabled}
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "number");
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate zone/output/keyfob numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = Number(number);
          relayEnv.commitUpdate((store) => {
            const controlSystemRecord = store.get(controlSystem.id);
            const isXr = controlSystemRecord?.getValue("isXr");
            const currentZone = store.get(id);
            if (currentZone) {
              const panelRecord = controlSystemRecord?.getLinkedRecord("panel");
              const zoneInformationRecords =
                panelRecord?.getLinkedRecords("zoneInformations");

              const outputInformationConflicts = !isXr
                ? panelRecord
                    ?.getLinkedRecords("outputInformations")
                    ?.map((outputs) =>
                      [
                        31, 32, 33, 34, 41, 42, 43, 44, 51, 52, 53, 54, 61, 62,
                        63, 64,
                      ].includes(Number(outputs.getValue("number")))
                        ? outputs.getValue("number")?.toString()
                        : "0"
                    )
                : [];

              const keyfobConflicts = !isXr
                ? panelRecord
                    ?.getLinkedRecords("keyfobs")
                    ?.map((keyfob) => keyfob.getValue("number")?.toString())
                : [];

              if (zoneInformationRecords) {
                const takenNumbers = new Set(
                  zoneInformationRecords
                    .map((zone) =>
                      zone.getValue("id") !== id
                        ? zone.getValue("number")?.toString()
                        : "0"
                    )
                    .concat(outputInformationConflicts)
                    .concat(keyfobConflicts)
                );
                const availableNumbers = supportedZoneNumbers.filter(
                  (num) => !takenNumbers.has(num.toString())
                );

                const valueAsNumber = Number(target.value);
                const value = availableNumbers.length
                  ? closest(
                      !target.value || isNaN(valueAsNumber) ? 1 : valueAsNumber,
                      availableNumbers
                    )
                  : valueAsNumber;
                currentZone.setValue(value.toString(), "number");
                blurredNumber = value;

                if (!wirelessZones.includes(value)) {
                  currentZone.setValue(false, "wireless");
                  currentZone.setValue(false, "competitorWireless");
                  defaultZoneInformationsWirelessOptions(
                    currentZone as RecordProxy<Zone>
                  );
                }
              }
            }
          });
          onBlurNumberUpdate(String(blurredNumber));
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

const defaultZoneInformationsWirelessOptions = (zone: RecordProxy<Zone>) => {
  zone.setValue("", "serialNumber");
  zone.setValue(ZoneTransmitterContact.INTERNAL, "contactNumber");
  zone.setValue(false, "wirelessContactNormallyOpen");
  zone.setValue(ZoneSupervisionTime._240_MIN, "supervisionTime");
  zone.setValue(false, "wirelessLedEnabled");
  zone.setValue(true, "wirelessDisarmDisableEnabled");
  zone.setValue(ZonePirPulseCount.FOUR, "wirelessPirPulseCount");
  zone.setValue(ZonePirSensitivity.LOW, "wirelessPirSensitivity");
  zone.setValue(ZonePetImmunity.OFF, "wirelessPetImmunity");
};

export default ZoneInformationNumberField;
