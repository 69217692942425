/**
 * @generated SignedSource<<52273a5b91354d15a9f9af28eadafee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverAreaInformationTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverLockoutCodeTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverNetworkOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverOutputOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverRemoteOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverSystemOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverSystemReportsTemplateDataInline_takeoverProgrammingTemplateConcepts" | "TakeoverZoneInformationTemplateDataInline_takeoverProgrammingTemplateConcepts">;
  readonly " $fragmentType": "TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts";
};
export type TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "65056c114bbe60fa1d32062e898dd1e3";

export default node;
