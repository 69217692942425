import graphql from "babel-plugin-relay/macro";

import { useStateDotGo } from "components/SystemDiagnostics/AngularStateProvider";
import React from "react";
import { useRefetchableFragment } from "react-relay";
import {
  asID,
  ConnectionStatus,
  fromSiteControlSystemId,
} from "securecom-graphql/client";
import styled from "styled-components";
import { SiteControlSystemOnlineStatusBadge_siteControlSystem$key } from "./__generated__/SiteControlSystemOnlineStatusBadge_siteControlSystem.graphql";
import { SiteControlSystemOnlineStatusBadge_siteControlSystemQuery } from "./__generated__/SiteControlSystemOnlineStatusBadge_siteControlSystemQuery.graphql";

enum TotalOnlineStatus {
  ONLINE = "online",
  OFFLINE = "offline",
  PARTIAL = "partial",
  PENDING = "pending",
}

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NonClickableSpacer = styled.div`
  height: 1.2rem;
  width: auto;
  margin-left: auto;
`;

const OnlineStatusBadge = styled.div<{ onlineStatus: TotalOnlineStatus }>`
  height: 1.2rem;
  color: ${({ onlineStatus }) =>
    onlineStatus === TotalOnlineStatus.ONLINE
      ? "mediumseagreen"
      : onlineStatus === TotalOnlineStatus.PARTIAL
      ? "orange"
      : onlineStatus === TotalOnlineStatus.PENDING
      ? "grey"
      : "red"};
  display: flex;
  justify-content: auto;
  align-items: center;
  margin-bottom: 0.3rem;
  font-weight: 400;
  transition: color 0.2s ease-in-out;
  &:hover {
    filter: brightness(85%);
  }
`;

const OnlineStatusBadgeDot = styled.div<{ onlineStatus: TotalOnlineStatus }>`
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 50%;
  align-self: center;
  background-color: ${({ onlineStatus }) =>
    onlineStatus === TotalOnlineStatus.ONLINE
      ? "mediumseagreen"
      : onlineStatus === TotalOnlineStatus.PARTIAL
      ? "orange"
      : onlineStatus === TotalOnlineStatus.PENDING
      ? "grey"
      : "red"};
  margin-right: 0.4rem;
  margin-bottom: 0.1rem;
  transition: color 0.2s ease-in-out;
  &:hover {
    filter: brightness(85%);
  }
`;

export const SiteControlSystemOnlineStatusBadge = ({
  siteControlSystem,
}: {
  siteControlSystem: SiteControlSystemOnlineStatusBadge_siteControlSystem$key;
}) => {
  const [data] = useRefetchableFragment<
    SiteControlSystemOnlineStatusBadge_siteControlSystemQuery,
    SiteControlSystemOnlineStatusBadge_siteControlSystem$key
  >(
    graphql`
      fragment SiteControlSystemOnlineStatusBadge_siteControlSystem on SiteControlSystem
      @refetchable(
        queryName: "SiteControlSystemOnlineStatusBadge_siteControlSystemQuery"
      ) {
        id
        hasCell
        hasEasyConnect
        online
        cellStatus {
          status
        }
        easyConnectStatus {
          status
        }
      }
    `,
    siteControlSystem
  );

  const { siteId, customerId } = fromSiteControlSystemId(asID(data.id));

  const totalConnectionStatus = React.useMemo(() => {
    switch (true) {
      case !data.online:
        return TotalOnlineStatus.PENDING;
      case data.hasCell && data.hasEasyConnect:
        switch (true) {
          case data.cellStatus?.status === ConnectionStatus.ONLINE &&
            data.easyConnectStatus?.status === ConnectionStatus.ONLINE:
            return TotalOnlineStatus.ONLINE;
          case data.cellStatus?.status === ConnectionStatus.OFFLINE &&
            data.easyConnectStatus?.status === ConnectionStatus.OFFLINE:
            return TotalOnlineStatus.OFFLINE;
          default:
            return TotalOnlineStatus.PARTIAL;
        }
      case data.hasEasyConnect && !data.hasCell:
        return data.easyConnectStatus?.status === ConnectionStatus.ONLINE
          ? TotalOnlineStatus.ONLINE
          : TotalOnlineStatus.OFFLINE;
      case data.hasCell && !data.hasEasyConnect:
        return data.cellStatus?.status === ConnectionStatus.ONLINE
          ? TotalOnlineStatus.ONLINE
          : TotalOnlineStatus.OFFLINE;
      default:
        return TotalOnlineStatus.OFFLINE;
    }
  }, [data]);

  const nav = useStateDotGo();

  return (
    <BadgeContainer>
      <OnlineStatusBadge
        onlineStatus={totalConnectionStatus}
        onClick={(event) => {
          if (data.online) {
            nav(
              "app.sites.diagnostics",
              {
                customer_id: customerId,
                site_id: siteId,
              },
              { reload: true }
            );
            event.stopPropagation();
          }
        }}
      >
        <OnlineStatusBadgeDot onlineStatus={totalConnectionStatus} />{" "}
        {totalConnectionStatus === TotalOnlineStatus.OFFLINE
          ? "Offline"
          : totalConnectionStatus === TotalOnlineStatus.PENDING
          ? "Pending"
          : "Online"}
      </OnlineStatusBadge>
      <NonClickableSpacer />
    </BadgeContainer>
  );
};
