/**
 * @generated SignedSource<<824883a3d900fd1027e50ba3e52a3eab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusBadgeFragment$data = {
  readonly centralStationAutomationIntegration: {
    readonly id: string;
    readonly name: string;
    readonly systemTestExpiresAt: string | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "StatusBadgeFragment";
};
export type StatusBadgeFragment$key = {
  readonly " $data"?: StatusBadgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusBadgeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusBadgeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemCentralStationAutomationIntegration",
      "kind": "LinkedField",
      "name": "centralStationAutomationIntegration",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTestExpiresAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "50c757158c9919a978fdfad3e7923d4a";

export default node;
