/**
 * @ngdoc service
 * @name App.factory:SevenInchKeypadService
 *
 * @description
 * API Interface service for Video.
 *
 */
App.factory("SevenInchKeypadService", [
  "$q",
  "PROPS",
  "SevenInchKeypadAPI",
  function ($q, PROP, SevenInchKeypadAPI) {
    return {
      getKeypadsForControlSystem: function (control_system_id) {
        var deferred = $q.defer();
        SevenInchKeypadAPI.getKeypadsForControlSystem(
          { control_system_id: control_system_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      getKeypadsForDealer: function (dealer_id) {
        var deferred = $q.defer();
        SevenInchKeypadAPI.getKeypadsForDealer(
          { dealer_id: dealer_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      getDoesDealerHave7inchKeypads: function (dealer_id) {
        var deferred = $q.defer();
        this.getKeypadsForDealer(dealer_id).then(
          function (data) {
            if (data.length > 0) {
              deferred.resolve(true);
            } else {
              deferred.resolve(false);
            }
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
