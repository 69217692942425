import graphql from "babel-plugin-relay/macro";
import { useUserCanEditVideoDevices } from "components/SiteForm/EntryPointContext";
import * as React from "react";
import { useFragment } from "react-relay/hooks";
import Sections, { SectionHeader } from "../Layout/Layout";
import DigitalWatchdogSpectrum from "./DigitalWatchdogSpectrum";
import HikvisionNvrsForm from "./HikvisionNvrsForm";
import SecureComDoorbellForm from "./SecureComDoorbellForm";
import SecureComVideoForm from "./SecureComVideoForm";
import EagleEyeBridges from "./VideoForm/EagleEye";
import HanwhaNvrs from "./VideoForm/HanwhaNvrs";
import OpenEyeNvrs from "./VideoForm/OpenEyeNvrs";
import VideoFormModal from "./VideoForm/VideoFormModal";
import { SiteVideoIntegrationsSection_site$key } from "./__generated__/SiteVideoIntegrationsSection_site.graphql";

function SiteVideoIntegrationsSection({
  siteRef,
}: {
  siteRef: SiteVideoIntegrationsSection_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment SiteVideoIntegrationsSection_site on Site {
        ...SecureComVideoForm_site
        ...SecureComDoorbellForm_site
        ...OpenEyeNvrs_site
        ...HikvisionNvrsForm_site
        ...EagleEye_site
        ...DigitalWatchdogSpectrum_site
        ...HanwhaNvrs_site
        videoEnabled
        securecomNvrEnabled
        hikvisionDoorbellEnabled
        openEyeEnabled
        hikvisionNvrEnabled
        eagleEyeEnabled
        digitalWatchdogSpectrumEnabled
        hanwhaWaveEnabled
        billingControlSystemId
        id
      }
    `,
    siteRef
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  const userCanEditVideoDevices = useUserCanEditVideoDevices();

  if (!userCanEditVideoDevices || !data.billingControlSystemId) {
    return null;
  }

  return (
    <>
      <Sections.Section>
        <SectionHeader>
          <SectionHeader.Title>Video Services</SectionHeader.Title>
          <SectionHeader.Add
            disabled={false}
            onClick={() => {
              setModalOpen(true);
            }}
          />
        </SectionHeader>
        <div className="drop-in-and-expand-ngif">
          <div className="control-system-edit-view__grid__row-3">
            {data.videoEnabled && <SecureComVideoForm siteRef={data} />}
            {data.hikvisionDoorbellEnabled && (
              <SecureComDoorbellForm siteRef={data} />
            )}
            {data.hikvisionNvrEnabled && <HikvisionNvrsForm siteRef={data} />}
            {data.openEyeEnabled && <OpenEyeNvrs siteRef={data} />}
            {data.eagleEyeEnabled && <EagleEyeBridges siteRef={data} />}
            {data.digitalWatchdogSpectrumEnabled && (
              <DigitalWatchdogSpectrum siteRef={data} />
            )}
            {data.hanwhaWaveEnabled && <HanwhaNvrs siteRef={data} />}
          </div>
        </div>
      </Sections.Section>
      {modalOpen && (
        <VideoFormModal
          siteId={data.id}
          videoEnabled={data.videoEnabled}
          securecomNvrEnabled={data.securecomNvrEnabled}
          hikvisionDoorbellEnabled={data.hikvisionDoorbellEnabled}
          openEyeEnabled={data.openEyeEnabled}
          hikvisionNvrEnabled={data.hikvisionNvrEnabled}
          eagleEyeEnabled={data.eagleEyeEnabled}
          digitalWatchdogSpectrumEnabled={data.digitalWatchdogSpectrumEnabled}
          hanwhaWaveEnabled={!!data.hanwhaWaveEnabled}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

export default SiteVideoIntegrationsSection;
