/**
 * @generated SignedSource<<231b8a913bfadc44fca29ff53fb44444>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationDoorField_deviceInformation$data = {
  readonly door?: boolean;
  readonly id: string;
  readonly number: number;
  readonly wirelessTranslator1100t?: boolean;
  readonly " $fragmentType": "DeviceInformationDoorField_deviceInformation";
};
export type DeviceInformationDoorField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationDoorField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationDoorField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationDoorField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "door",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wirelessTranslator1100t",
          "storageKey": null
        }
      ],
      "type": "XtDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "4d1be3c23e5992dab17c70b423374a54";

export default node;
