App.factory("CustomItem", [
  function () {
    var CustomItem = function (custom_item, dealer_id) {
      angular.extend(this, {
        title: null,
        price: null,
        available: null,
        billing_type: null,
        sales_tax_type: "taxable",
        extendMe: function (customItem) {
          angular.extend(this, customItem);
        },
      });
      if (custom_item != null && angular.isDefined(custom_item)) {
        this.extendMe(custom_item);
      }
    };
    return CustomItem;
  },
]);
