import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions$key } from "./__generated__/RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions.graphql";

export const remoteOptionsAlarmReceiverAuthorizationFieldId = () =>
  "remote-options-alarm-receiver-authorization";

function RemoteOptionsAlarmReceiverAuthorizationField() {
  const [{ alarmReceiverAuthorization }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions on RemoteOptions {
          ... on XtRemoteOptions {
            alarmReceiverAuthorization
          }
          ... on XrRemoteOptions {
            alarmReceiverAuthorization
          }
        }
      `
    );

  const fieldId = remoteOptionsAlarmReceiverAuthorizationFieldId();
  const label = "Alarm Receiver Authorization";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables remote programming and commands from the SCS-1R Receiver."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={!!alarmReceiverAuthorization}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !alarmReceiverAuthorization,
              "alarmReceiverAuthorization"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsAlarmReceiverAuthorizationField;
