/**
 * @generated SignedSource<<7bc7f6f85796a14b4f23831b38c1e44b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AreaArmedStatusEnum = "ARMED" | "ARMING" | "DISARMED" | "DISARMING" | "PENDING" | "UNKNOWN";
export type ArmedStatusEnum = "ARMED" | "ARMED_ALL" | "ARMED_AWAY" | "ARMED_EVERYTHING" | "ARMED_HOME" | "ARMED_PERIMETER" | "ARMED_SLEEP" | "ARMED_SOME" | "ARMING_ALL" | "ARMING_AWAY" | "ARMING_HOME" | "ARMING_PERIMETER" | "ARMING_SLEEP" | "DISARMED" | "DISARMING" | "DISARMING_ALL" | "DISARMING_AWAY" | "DISARMING_EVERYTHING" | "DISARMING_HOME" | "DISARMING_PERIMETER" | "DISARMING_SLEEP" | "DISARMING_SOME" | "PENDING" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type HomeAwayArming_controlSystem$data = {
  readonly areas: {
    readonly nodes: ReadonlyArray<{
      readonly armedStatus: AreaArmedStatusEnum;
      readonly id: string;
      readonly isInAlarm: boolean;
      readonly name: string;
      readonly number: string;
    }>;
  };
  readonly armedStatus: ArmedStatusEnum;
  readonly id: string;
  readonly isAreaSystem: boolean;
  readonly isArmed: boolean;
  readonly isDSC: boolean;
  readonly isECP: boolean;
  readonly isHASystem: boolean;
  readonly isHSASystem: boolean;
  readonly isInAlarm: boolean;
  readonly isSingleAreaSystem: boolean;
  readonly name: string;
  readonly " $fragmentType": "HomeAwayArming_controlSystem";
};
export type HomeAwayArming_controlSystem$key = {
  readonly " $data"?: HomeAwayArming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeAwayArming_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInAlarm",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "armedStatus",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeAwayArming_controlSystem",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArmed",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isECP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDSC",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSingleAreaSystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHSASystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHASystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAreaSystem",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sort",
          "value": {
            "keys": [
              "number"
            ],
            "order": "ASC"
          }
        }
      ],
      "concreteType": "AreaConnection",
      "kind": "LinkedField",
      "name": "areas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "areas(sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "b99f8f82a254730a974161bb1d74cffc";

export default node;
