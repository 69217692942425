import MobileCredentials from "components/MobileCredentials";
import { react2angular } from "react2angular";

App.component(
  "mobileCredentials",
  react2angular(
    MobileCredentials,
    [],
    ["$stateParams", "RelayService", "UserService", "$rootScope", "$state"]
  )
);
