import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsApn2Field_remoteOptions$key } from "./__generated__/RemoteOptionsApn2Field_remoteOptions.graphql";

export const remoteOptionsApn2FieldId = () => "remote-options-apn2";

function RemoteOptionsApn2Field() {
  const [{ secondApn, allowCellularRemoteProgramming }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsApn2Field_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsApn2Field_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            secondApn
            allowCellularRemoteProgramming
          }
        }
      `
    );

  const fieldId = remoteOptionsApn2FieldId();
  const disabled = !allowCellularRemoteProgramming;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Second APN"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={secondApn || "SECURECOM400"}
        pattern="^.*$"
        disabled={disabled}
        maxLength={32}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "secondApn")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsApn2Field;
