/**
 * @generated SignedSource<<71299250aae4c047c16a9b70c61832ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cameraCtrlQuery$variables = {
  siteId: string;
};
export type cameraCtrlQuery$data = {
  readonly site: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SecureComCameras_site">;
  } | null;
};
export type cameraCtrlQuery = {
  response: cameraCtrlQuery$data;
  variables: cameraCtrlQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cameraCtrlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SecureComCameras_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cameraCtrlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billingControlSystemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SecureComCamera",
            "kind": "LinkedField",
            "name": "securecomCameras",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOnline",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "manufacturer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "channelId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a82232f2164c53cbe604bf14e5319e1b",
    "id": null,
    "metadata": {},
    "name": "cameraCtrlQuery",
    "operationKind": "query",
    "text": "query cameraCtrlQuery(\n  $siteId: ID!\n) {\n  site(id: $siteId) {\n    id\n    ...SecureComCameras_site\n  }\n}\n\nfragment SecureComCameras_secureComCamera on SecureComCamera {\n  id\n  name\n  status\n  isOnline\n  manufacturer\n  channelId\n}\n\nfragment SecureComCameras_site on Site {\n  id\n  billingControlSystemId\n  securecomCameras {\n    id\n    ...SecureComCameras_secureComCamera\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4e1cdb47c24dbc45a6070180cc6e362";

export default node;
