import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaBankSafeVaultField_xrArea$key } from "./__generated__/AreaBankSafeVaultField_xrArea.graphql";

export const areaBankSafeVaultFieldId = (number: string) =>
  `area-bank-safe-vault-${number}`;

function AreaBankSafeVaultField() {
  const [{ number, bankSafeVault }, updateArea] =
    useAreaFragment<AreaBankSafeVaultField_xrArea$key>(
      graphql`
        fragment AreaBankSafeVaultField_xrArea on XrArea {
          number
          bankSafeVault
        }
      `
    );

  const fieldId = areaBankSafeVaultFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Bank Safe and Vault"
      tooltip="When enabled, while the Area is armed, schedules and System time cannot be changed."
    >
      <Switch
        label="Bank Safe and Vault"
        id={fieldId}
        checked={bankSafeVault}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!bankSafeVault, "bankSafeVault");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaBankSafeVaultField;
