import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsSensorResetOutputField_outputOptions$key } from "./__generated__/OutputOptionsSensorResetOutputField_outputOptions.graphql";

export const outputOptionsSensorResetOutputFieldId = () =>
  "output-options-sensor-reset-output";

function OutputOptionsSensorResetOutputField() {
  const [{ sensorResetOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsSensorResetOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsSensorResetOutputField_outputOptions on OutputOptions {
          id
          sensorResetOutput {
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsSensorResetOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const { current: originalValue } = React.useRef(
    sensorResetOutput.formattedNumber
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Sensor Reset Output"
      tooltip="This output turns on when a Sensor Reset is performed. Turns off 5 seconds after it is turned on."
    >
      <TextInput
        id={fieldId}
        value={sensorResetOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) => {
          onChange(outputOptionsId, target.value, "sensorResetOutput");
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "sensorResetOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsSensorResetOutputField;
