/**
 * @generated SignedSource<<089574e01a8d59c0e93de8d6262aa38f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type SecureComDoorbellFirmwareStatus = "DEVICE_REBOOT" | "ERROR_UNKNOWN" | "FAILED" | "UPGRADED" | "UPGRADING";
import { FragmentRefs } from "relay-runtime";
export type SecureComDoorbells_doorbell$data = {
  readonly controlSystemId: number;
  readonly deviceType: string;
  readonly firmwareVersion: string;
  readonly id: string;
  readonly isUpdating: boolean;
  readonly latestFirmwareVersion: string;
  readonly manufacturer: string;
  readonly model: string;
  readonly name: string;
  readonly serialNumber: string;
  readonly status: string;
  readonly updateAvailable: boolean;
  readonly updateProgress: number;
  readonly updateStatus: SecureComDoorbellFirmwareStatus;
  readonly verificationCode: string;
  readonly " $fragmentType": "SecureComDoorbells_doorbell";
};
export type SecureComDoorbells_doorbell$key = {
  readonly " $data"?: SecureComDoorbells_doorbell$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComDoorbells_doorbell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SecurecomDoorbellRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "SecureComDoorbells_doorbell",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firmwareVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestFirmwareVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updateStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updateProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUpdating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "controlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updateAvailable",
      "storageKey": null
    }
  ],
  "type": "SecureComDoorbell",
  "abstractKey": null
};

(node as any).hash = "bef935792c1eb5d55951aa02275847d5";

export default node;
