App.controller("ProfileEditCtrl", [
  "$rootScope",
  "$scope",
  "$q",
  "$state",
  "$filter",
  "UserService",
  "profile_number",
  "PanelCapabilitiesService",
  "PanelProgArchiveService",
  "CustomerLinkedProfilesService",
  function (
    $rootScope,
    $scope,
    $q,
    $state,
    $filter,
    UserService,
    profile_number,
    PanelCapabilitiesService,
    PanelProgArchiveService,
    CustomerLinkedProfilesService
  ) {
    $scope.isBusy = true;
    $scope.controlSystem = UserService.controlSystem;
    $scope.nextProfileNumber = 0;
    $scope._family =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].hardware_family;
    $scope.panel_model =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].hardware_model;
    $scope.panel_version =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].software_version;
    $scope.userCodeSupportsTempOption =
      PanelCapabilitiesService.userCodeSupportsTempOption(
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
      );
    $scope.PanelProgArchiveService = PanelProgArchiveService;
    $scope.showPrivateDoors = PanelCapabilitiesService.supportsPrivateDoors(
      $scope.controlSystem.panels[$scope.controlSystem.panel_index]
    );

    function initPage() {
      if ($scope.conceptPromise) {
        $scope.conceptPromise
          .then(function () {
            if ($state.is("app.panel_programming.profiles_new")) {
              // Creating a new profile
              $scope.pageType = "new";
              $scope.isBusy = true;
              addNewProfile()
                .then(
                  function (profileData) {
                    $scope.profileToEdit = profileData;
                    initializeConcepts();
                    $scope.isBusy = false;
                  },
                  function () {
                    $scope.isBusy = false;
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else if ($state.is("app.panel_programming.profiles_edit")) {
              // Editing an existing profile
              $scope.pageType = "edit";
              var profileData = $filter("filter")(
                $scope.Panel["profiles"],
                { number: profile_number },
                true
              )[0];
              $scope.profileToEdit = profileData;
              initializeConcepts();

              if ($scope.showPrivateDoors) {
                $scope.profileToEdit.private_doors =
                  $scope.profileToEdit.private_doors.filter(function (door) {
                    return (
                      $scope.privateDoors.filter(
                        (panelDoor) => parseInt(panelDoor.ax_number) === door
                      ).length > 0
                    );
                  });
              }

              $scope.isBusy = false;
            }
            $scope.isBusy = false;
          })
          .catch(function (error) {
            console.error(error);
          });
      }
      getLinkedProfiles();
    }
    /**
     * Function to get all the linked profiles for this customer and also set the hasLinkedProfiles variable if any are present.
     * @returns [Array] linkedProfiles an array of linked profiles for this customer
     * @sets $scope.hasLinkedProfiles to true if there are any linked profiles for this customer
     */
    function getLinkedProfiles() {
      var deferred = $q.defer();
      CustomerLinkedProfilesService.getLinkedProfiles(UserService.customer_id)
        .then(
          function (linkedProfiles) {
            $scope.hasLinkedProfiles =
              $scope.Panel["profiles"].filter((data) => data.linked_profile_id)
                .length > 0;

            deferred.resolve(linkedProfiles);
          },
          function (error) {
            $scope.hasLinkedProfiles = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function initializeConcepts() {
      $scope.timeSchedules = $scope.Panel["time_schedules"];
      $scope.accessibleAreas = $scope.Panel["area_informations"];
      $scope.armableAreas = $scope.Panel["area_informations"];
      if ($scope.showPrivateDoors)
        $scope.privateDoors = $filter("filter")(
          $scope.Panel["device_informations"],
          { tipe: 1, private_door: "Y" }
        ); // Only show door type devices
    }

    /**
     * Function to get all the profile numbers in the profiles list
     * @returns [Array] profileNumbers an array of profile numbers
     */
    function getProfileNumbers(profiles) {
      var profileNumbers = [];
      if (profiles) {
        // make sure it's not undefined
        for (var i = 0; i < profiles.length; i++) {
          profileNumbers.push(+profiles[i].number);
        }
      }
      profileNumbers.sort(function (a, b) {
        return a - b;
      });
      return profileNumbers;
    }

    function addNewProfile() {
      var deferred = $q.defer();
      // Get a new Profile
      $scope.Panel.newItem("profiles")
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when creating new Profile template.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * Function to check if we've used up all the profiles on this panel
     * Used to prevent showing the "New" button
     */
    function canCreateNewProfile() {
      // Determine the next available profile.NUMBER
      var profileNumbers = getProfileNumbers($scope.Panel["profiles"]);
      var profileLimit = 99;
      var nextNumber = new nextManUp(profileNumbers, profileLimit).getNext();
      $scope.nextProfileNumber = nextNumber;
      return !!nextNumber; //  convert to boolean value
    }

    /**
     * Function that returns true when the New button for the user page should be shown
     */
    $scope.readyToShowNewButton = function () {
      return $scope.profiles && canCreateNewProfile() && !$scope.Panel.isBusy();
    };

    $scope.goToNewProfile = function () {
      $state.go("^.profiles_new", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
        panel_id: UserService.panel_id,
      });
    };

    $scope.retrieveAllData = function () {
      $scope.isBusy = true;
      $scope.Panel.refresh()
        .then(
          function () {
            initPage();
          },
          function (error) {
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    function goToProfileList() {
      $state.go("^.profiles", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
        panel_id: UserService.panel_id,
      });
    }

    $scope.disablePrivateDoor = (door) =>
      !$scope.profileToEdit.private_doors.includes(door) &&
      $scope.profileToEdit.private_doors.length >= 4;

    $scope.saveNewProfile = function () {
      $scope.isBusy = true;
      // Profiles are assumed to be "in sync" with the panel so set the refreshPristine flag to false

      $scope.Panel.saveProgramming()
        .then(
          function () {
            $scope.isBusy = false;
            goToProfileList();
          },
          function (error) {
            $scope.isBusy = false;
            console.error(
              "Error occurred when attempting to save programming to Dealer Admin: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.sendProfileChanges = function () {
      $scope.isBusy = true;
      // Profiles are assumed to be "in sync" with the panel so set the refreshPristine flag to false

      $scope.Panel.sendProgramming(
        "profiles",
        true,
        false,
        $scope.profileToEdit.number
      )
        .then(
          function () {
            $scope.isBusy = false;
            goToProfileList();
          },
          function (error) {
            $scope.isBusy = false;
            console.error(
              "Error occurred when attempting to Send programming to the system: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.deleteProfile = function (profile) {
      $scope.isBusy = true;
      if (typeof profile === "undefined") profile = $scope.profileToEdit;
      $scope.Panel.deleteItem("profiles", profile)
        .then(
          function () {
            $scope.isBusy = false;
            goToProfileList();
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to Delete a profile from the system.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.cancelEdit = function () {
      //$scope.Panel.deleteItem(PANEL_CONCEPTS.PROFILES, $scope.profileToEdit, $scope.Panel[PANEL_CONCEPTS.PROFILES.indexOf($scope.profileToEdit)]);
      $scope.Panel.cancelEdit("profiles");
      goToProfileList();
    };

    initPage();

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     */
    $scope.$on("all_concepts_retrieved", function () {
      initPage();
    });
  },
]);
