import styled from "styled-components";
import { media } from "common/utils/styles";
import { widgetPaddingX } from "../Widget";
import {
  CONTENT_HEIGHT_MOBILE_IN_PX,
  CONTENT_HEIGHT_DESKTOP_IN_PX,
} from "./constants";

const BasicArmingWrapper = styled.div`
  ${widgetPaddingX};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${CONTENT_HEIGHT_MOBILE_IN_PX}px;
  margin-bottom: 20px;

  ${media.xsmall`
    height: ${CONTENT_HEIGHT_DESKTOP_IN_PX}px;
  `};
`;

export default BasicArmingWrapper;
