/**
 * @ngdoc object
 * @name App.controller:OnDemandPricingCtrl
 * @description
 *  Controller for the OnDemandPricing
 */
App.controller("OnDemandSettingsCtrl", [
  "$scope",
  "$q",
  "$rootScope",
  "$state",
  "$modal",
  "OnDemandService",
  "UserService",
  "CompanyStoreService",
  "$location",
  "stripe_success",
  "$window",
  "$stateParams",
  function (
    $scope,
    $q,
    $rootScope,
    $state,
    $modal,
    OnDemandService,
    UserService,
    CompanyStoreService,
    $location,
    stripe_success,
    $window,
    $stateParams
  ) {
    const numOfOptions = 3;
    $scope.numOfOptions = numOfOptions;
    if ($state.is("app.dealer.ondemand_settings_edit")) {
      $scope.state = "edit";
    } //Set state to edit if if the onDemand_pricing_edit state
    else {
      $scope.state = "view";
    }
    $scope.stateIsEdit = function () {
      return $scope.state == "edit";
    }; //Function to determine if in Edit State
    $scope.UserService = UserService;

    init();
    function init() {
      if (stripe_success) {
        $rootScope.alerts.push({
          type: "success",
          text: "Stripe Account Connected!",
        });
      }
      $scope.isBusy = true;
      $scope.tierData = [];
      //Load Tier Data
      var onDemandTierPromise = OnDemandService.getOnDemandTierData(
        UserService.dealer_id
      );
      var onDemandCommunicationPromise = OnDemandService.getCommuncationOptions(
        UserService.dealer_id
      );

      $q.all([onDemandTierPromise, onDemandCommunicationPromise])
        .then(
          function (data) {
            if (data[0]) {
              $scope.tierData = data[0];
            }
            //Communication Option Load
            if (data[1]) {
              $scope.communications_object = data[1];
            }
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Could not get dealer data.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      if (
        UserService.isDealerAccessible() ||
        UserService.isSupervisorAccessible()
      ) {
        statusPromise = CompanyStoreService.getStripeConnectedStatus(
          UserService.dealer_id
        );
        statusPromise
          .then(
            function (data) {
              if (data) {
                $scope.stripeStatus = data.dealer.stripe_connected;
              } else {
                $scope.unableToGetStripeStatus = true;
              }
            },
            function (error) {
              if (error.status == 404) {
                // A 404 could mean that the account needs to be created. Show the connect stripe button
                $scope.stripeStatus = false;
              } else {
                // Display any error besides a 404
                $rootScope.alerts.push({
                  type: "error",
                  text: "Could not get dealer data.",
                  json: error,
                });
              }
              $scope.isBusy = false;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    }
    /*
     * Function to add an OnDemand Option.
     */
    $scope.addTier = function () {
      $scope.isBusy = true;
      OnDemandService.getNewDealerCharge(UserService.dealer_id)
        .then(
          function (newCharge) {
            //Grab New Dealer Charge from DB
            newCharge.Enabled = true;
            OnDemandService.createOnDemandTier(newCharge)
              .then(
                function (createdNewCharge) {
                  //Create New Dealer Charge with Value from DB
                  $scope.tierData.push(createdNewCharge);
                  $scope.isBusy = false;
                },
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Could not create new option.",
                    json: error,
                  });
                  $scope.error = false;
                  $scope.isBusy = false;
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $scope.error = error;
            $rootScope.alerts.push({
              type: "error",
              text: "Cannot create a new option.",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    var myUrl = $location.absUrl();
    var returnUri = myUrl + "?stripe_success=true";
    if (stripe_success) {
      $rootScope.alerts.push({
        type: "success",
        text: "Stripe Account Connected!",
      });
    }
    //Connect Stripe
    $scope.connectStripe = function () {
      $window.location.href = CompanyStoreService.getStripeURI(returnUri);
    };

    /**
     * Flag invalid settings before saving
     */
    $scope.validateSettings = function () {
      angular.forEach($scope.tiersForm.$error, function (errorType) {
        angular.forEach(errorType, function (field) {
          field.$setTouched();
        });
      });
    };

    $scope.clickTheSaveButton = function (formInvalid) {
      if (
        formInvalid == false &&
        $scope.stripeStatus &&
        !$scope.unableToGetStripeStatus
      ) {
        $scope.submitTiers();
      } else {
        $scope.validateSettings();
        $scope.stripeTouched = true;
      }
    };

    //Submitting tiers to updated.
    $scope.submitTiers = function () {
      $scope.isBusy = true;
      var communicationPutPromise = OnDemandService.updateCommunicationOptions(
        $scope.communications_object
      );
      var tierPutPromise = OnDemandService.updateOnDemandTiers($scope.tierData);
      $q.all([communicationPutPromise, tierPutPromise])
        .then(
          function (data) {
            $rootScope.alerts.push({
              type: "success",
              text: "Price options updated.",
            });
            $rootScope.alerts.push({
              type: "success",
              text: "Report options updated.",
            });
            $state.go(
              "app.dealer.ondemand_settings_view",
              { dealer_id: UserService.dealerInfo.id },
              { reload: true }
            );
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
  },
]);
