import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { curry } from "ramda";
import * as React from "react";
import { KeyfobButtonType, KeyfobPressTime } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobButtonNumberType } from "./utils";
import { KeyfobButtonPressTimeField_keyfob$key } from "./__generated__/KeyfobButtonPressTimeField_keyfob.graphql";

export const keyfobButtonPressTimeFieldId = curry(
  (buttonNumber: KeyfobButtonNumberType, number: string) =>
    `keyfob-button-press-time-button-number-${buttonNumber.toLowerCase()}-${number}`
);

function KeyfobButtonPressTimeField(props: {
  buttonNumber: KeyfobButtonNumberType;
}) {
  const [
    {
      number,
      buttonOneAction,
      buttonTwoAction,
      buttonThreeAction,
      buttonFourAction,
      buttonOnePressTime,
      buttonTwoPressTime,
      buttonThreePressTime,
      buttonFourPressTime,
    },
    updateKeyfob,
  ] = useKeyfobFragment<KeyfobButtonPressTimeField_keyfob$key>(
    graphql`
      fragment KeyfobButtonPressTimeField_keyfob on Keyfob {
        number
        buttonOneAction
        buttonTwoAction
        buttonThreeAction
        buttonFourAction
        buttonOnePressTime
        buttonTwoPressTime
        buttonThreePressTime
        buttonFourPressTime
      }
    `
  );

  const { buttonNumber } = props;
  const fieldId = keyfobButtonPressTimeFieldId(buttonNumber, String(number));

  const shortPressDisableButtonTypes = [
    KeyfobButtonType.PANIC,
    KeyfobButtonType.PANIC_TWO_BUTTON,
    KeyfobButtonType.EMERGENCY,
    KeyfobButtonType.EMERGENCY_TWO_BUTTON,
  ];

  const shortPressDisabled =
    (shortPressDisableButtonTypes.includes(
      buttonOneAction as KeyfobButtonType
    ) &&
      buttonNumber === "One") ||
    (shortPressDisableButtonTypes.includes(
      buttonTwoAction as KeyfobButtonType
    ) &&
      buttonNumber === "Two") ||
    (shortPressDisableButtonTypes.includes(
      buttonThreeAction as KeyfobButtonType
    ) &&
      buttonNumber === "Three") ||
    (shortPressDisableButtonTypes.includes(
      buttonFourAction as KeyfobButtonType
    ) &&
      buttonNumber === "Four");

  const disabled =
    (buttonOneAction === KeyfobButtonType.UNUSED && buttonNumber === "One") ||
    (buttonTwoAction === KeyfobButtonType.UNUSED && buttonNumber === "Two") ||
    (buttonThreeAction === KeyfobButtonType.UNUSED &&
      buttonNumber === "Three") ||
    (buttonFourAction === KeyfobButtonType.UNUSED && buttonNumber === "Four");

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Press Time"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        disabled={disabled || shortPressDisabled}
        value={
          buttonNumber === "Two"
            ? buttonTwoPressTime
            : buttonNumber === "Three"
            ? buttonThreePressTime
            : buttonNumber === "Four"
            ? buttonFourPressTime
            : buttonOnePressTime
        }
        required
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(
              target.value,
              `button${buttonNumber}PressTime`
            );
          });
        }}
      >
        {!shortPressDisabled && (
          <Select.Option value={KeyfobPressTime.SHORT}>Short</Select.Option>
        )}
        <Select.Option value={KeyfobPressTime.LONG}>Long</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobButtonPressTimeField;
