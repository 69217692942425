import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationSiteCode7Field_deviceInformation$key } from "./__generated__/DeviceInformationSiteCode7Field_deviceInformation.graphql";

export const deviceInformationSiteCode7FieldId = (number: string) =>
  `device-information-site-code-7-${number}`;

function DeviceInformationSiteCode7Field() {
  const [{ id, number, device734, deviceType }] =
    useDeviceInformationFragment<DeviceInformationSiteCode7Field_deviceInformation$key>(
      graphql`
        fragment DeviceInformationSiteCode7Field_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            deviceType
            device734 {
              ... on Device734 {
                siteCode7
                enforceSiteCode
              }
            }
          }
          ... on Xt75DeviceInformation {
            deviceType
            device734 {
              ... on Device734 {
                siteCode7
                enforceSiteCode
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationSiteCode7FieldId(String(number));
  const { current: originalValue } = React.useRef(device734?.siteCode7);
  const disabled =
    !device734?.enforceSiteCode || deviceType !== DeviceInformationType.DOOR;
  const relayEnv = useRelayEnvironment();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Site Code 7"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        pattern="(0?[0-9]{0,7}|1[0-5][0-9]{6}|16[0-6][0-9]{5}|167[0-6][0-9]{4}|1677[0-6][0-9]{3}|16777[0-1][0-9]{2}|1677720[0-9]|1677721[0-4])"
        validationMessage="Valid values are 0-16777214."
        value={device734?.siteCode7 ?? ""}
        inlineHelp="0-16777214"
        min={0}
        max={16777214}
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                emptyStringOrNumber(target.value),
                "siteCode7"
              );
            }
          });
        }}
        onBlur={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              const valueAsNumber = Number(target.value);
              const value = isNaN(valueAsNumber)
                ? originalValue
                : clamp(0, 16777214, valueAsNumber);
              device734Options.setValue(String(value), "siteCode7");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationSiteCode7Field;
