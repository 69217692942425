/**
 * @generated SignedSource<<f39a34e55878ff56b039b0cc7eecaa73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatTypeField_cardFormat$data = {
  readonly number: string;
  readonly siteCodeLength: number;
  readonly siteCodePosition: number;
  readonly type: number;
  readonly userCodeDigits: number;
  readonly userCodeLength: number;
  readonly userCodePosition: number;
  readonly wiegandLength: number;
  readonly " $fragmentType": "CardFormatTypeField_cardFormat";
};
export type CardFormatTypeField_cardFormat$key = {
  readonly " $data"?: CardFormatTypeField_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatTypeField_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatTypeField_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wiegandLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodePosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodeLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCodePosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCodeLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCodeDigits",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "3d5c521acc833e284d2d984f4105f17b";

export default node;
