import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { resolveDeviceInformationAreaListRules } from "./utils";
import { DeviceInformationAccessAreasField_deviceInformation$key } from "./__generated__/DeviceInformationAccessAreasField_deviceInformation.graphql";

export const deviceInformationAccessAreasFieldId = (number: string) =>
  `device-information-access-areas-${number}`;

function DeviceInformationAccessAreasField() {
  const [
    { number, accessAreas, privateDoor, deviceType },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationAccessAreasField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationAccessAreasField_deviceInformation on DeviceInformation {
        number
        ... on XrDeviceInformation {
          deviceType
          accessAreas
          privateDoor
        }
      }
    `
  );

  const fieldId = deviceInformationAccessAreasFieldId(String(number));
  const { pattern, inlineHelp, validationMessage } =
    resolveDeviceInformationAreaListRules(useHardwareModel());
  const disabled = privateDoor || deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Access Areas"
      disabled={disabled}
      tooltip="Users require a profile with a matching Access Area to gain access unless no Areas are entered, in which case any Users with a profile containing Door Access Authority will be granted access."
    >
      <TextInput
        id={fieldId}
        pattern={pattern}
        value={accessAreas}
        disabled={disabled}
        validationMessage={validationMessage}
        inlineHelp={inlineHelp}
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(target.value, "accessAreas");
          });
        }}
        onBlur={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "accessAreas"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationAccessAreasField;
