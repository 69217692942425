/**
 * @generated SignedSource<<e06194c62215eb16e35cd8bfff09775f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFDeviceSetupProgrammingConceptFormNavButton_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly deviceInformations: ReadonlyArray<{
      readonly isNew?: boolean;
    }>;
  };
  readonly " $fragmentType": "XFDeviceSetupProgrammingConceptFormNavButton_controlSystem";
};
export type XFDeviceSetupProgrammingConceptFormNavButton_controlSystem$key = {
  readonly " $data"?: XFDeviceSetupProgrammingConceptFormNavButton_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceSetupProgrammingConceptFormNavButton_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFDeviceSetupProgrammingConceptFormNavButton_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "deviceInformations",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isNew",
                  "storageKey": null
                }
              ],
              "type": "XfDeviceInformation",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "e2595aebc1b28ec3870cd962020e78d8";

export default node;
