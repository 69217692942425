/**
 * @generated SignedSource<<eb76624cecf839d0b35e789a6c530647>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly securityGrade: {
    readonly grade: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "df4d73b13f26e2a0225a52ac3f837567";

export default node;
