import { toTitleCase } from "common/utils/universal/string";

/**
 * @name App.factory:scheduleUtilService
 *
 * @description Utility service for Schedules.
 *
 */
App.factory("scheduleUtilService", scheduleUtilService);

scheduleUtilService.$inject = ["$filter", "DAY_NOT_ENCODED_SCHEDULE_CONCEPTS"];
function scheduleUtilService($filter, DAY_NOT_ENCODED_SCHEDULE_CONCEPTS) {
  var days = [];
  var holidays = [];
  var twilightData = {};

  var utils = {
    days: days,
    holidays: holidays,
    twilightData: twilightData,
    setPeriod: setPeriod,
    setOffset: setOffset,
    initTwilightData: initTwilightData,
    setAnyChecked: setAnyChecked,
    finalizeSchedule: finalizeSchedule,
    manuallySetDays: manuallySetDays,
    formatScheduleUseArray: formatScheduleUseArray,
  };

  initData();
  return utils;

  // Public functions

  function setPeriod(beginTime, selection, schedule) {
    var _this = this;
    if (beginTime) {
      if (twilightData.beginPeriodDropdown != selection) {
        angular.forEach(schedule.days, function (day) {
          schedule[day.beginField + "_twilight_period"] = selection;
          schedule[day.beginField + "_twilight_offset"] = 0;
        });
        twilightData.beginPeriodDropdown = selection;
        twilightData.beginOffsetDropdown = "At " + selection;
      }
    } else {
      if (twilightData.endPeriodDropdown != selection) {
        angular.forEach(schedule.days, function (day) {
          schedule[day.endField + "_twilight_period"] = selection;
          schedule[day.endField + "_twilight_offset"] = 0;
        });
        twilightData.endPeriodDropdown = selection;
        twilightData.endOffsetDropdown = "At " + selection;
      }
    }
    updatePhrases(schedule);
  }

  function setOffset(beginTime, offset, schedule) {
    var _this = this;
    if (beginTime) {
      angular.forEach(schedule.days, function (day) {
        schedule[day.beginField + "_twilight_offset"] = offset;
      });
      twilightData.beginOffsetDropdown = setOffsetDropdown(
        offset,
        twilightData.beginPeriodDropdown
      );
    } else {
      angular.forEach(schedule.days, function (day) {
        schedule[day.endField + "_twilight_offset"] = offset;
      });
      twilightData.endOffsetDropdown = setOffsetDropdown(
        offset,
        twilightData.endPeriodDropdown
      );
    }
    updatePhrases(schedule);
  }

  function initTwilightData(schedule) {
    var _this = this;
    _this.setAnyChecked(schedule);
    var iData = {
      beginField: schedule.days[0].beginField,
      beginOffset: 0,
      beginPeriod: twilightData.defaultBeginPeriod,
      endField: schedule.days[0].endField,
      endOffset: 0,
      endPeriod: twilightData.defaultEndPeriod,
    };
    prepInitializationData(schedule, iData);
    initDropdowns(schedule, iData);
    initTwilightsNotUsed(schedule, iData);
    updatePhrases(schedule);
  }

  function setAnyChecked(schedule) {
    var tempCheck = false;
    angular.forEach(schedule.days, function (day) {
      if (schedule[day.beginField + "_use_twilight_scheduling"])
        tempCheck = true;
    });
    twilightData.anyBeginCheck = tempCheck;

    tempCheck = false;
    angular.forEach(schedule.days, function (day) {
      if (schedule[day.endField + "_use_twilight_scheduling"]) tempCheck = true;
    });
    twilightData.anyEndCheck = tempCheck;
  }

  function finalizeSchedule(schedule) {
    angular.forEach(schedule.days, function (day) {
      if (
        angular.isDefined(
          schedule[day.beginField + "_use_twilight_scheduling"]
        ) &&
        schedule[day.beginField + "_use_twilight_scheduling"] == false
      ) {
        schedule[day.beginField + "_twilight_offset"] = null;
        schedule[day.beginField + "_twilight_period"] = null;
      }
      if (
        angular.isDefined(day.endField) &&
        angular.isDefined(
          schedule[day.endField + "_use_twilight_scheduling"]
        ) &&
        schedule[day.endField + "_use_twilight_scheduling"] == false
      ) {
        schedule[day.endField + "_twilight_offset"] = null;
        schedule[day.endField + "_twilight_period"] = null;
      }
    });
    delete schedule.isBusy;
    delete schedule.isOpen;
    delete schedule.panelFamily;
    delete schedule.beginFieldName;
    delete schedule.endFieldName;
    delete schedule.days;
    delete schedule.holidays;
    delete schedule.beginColumn;
    delete schedule.endColumn;
    delete schedule.doors;
    delete schedule.areas;
    delete schedule.outputs;
    delete schedule.favorites;
  }

  // Private functions

  function initData() {
    days.push(
      { prefix: "sun", name: "Sunday", date: "01/02/2000 12:00", abbr: "sun" }, // FIX: DA-3652 This 12:00 time is to prevent positive time zone offsets (BELGIUM) from showing the wrong day on schedules.
      { prefix: "mon", name: "Monday", date: "01/03/2000 12:00", abbr: "mon" },
      { prefix: "tue", name: "Tuesday", date: "01/04/2000 12:00", abbr: "tue" },
      {
        prefix: "wed",
        name: "Wednesday",
        date: "01/05/2000 12:00",
        abbr: "wed",
      },
      {
        prefix: "thu",
        name: "Thursday",
        date: "01/06/2000 12:00",
        abbr: "thu",
      },
      { prefix: "fri", name: "Friday", date: "01/07/2000 12:00", abbr: "fri" },
      { prefix: "sat", name: "Saturday", date: "01/08/2000 12:00", abbr: "sat" }
    );
    holidays.push(
      { prefix: "hola", name: "Holiday A", abbr: "hol a" },
      { prefix: "holb", name: "Holiday B", abbr: "hol b" },
      { prefix: "holc", name: "Holiday C", abbr: "hol c" }
    );
    twilightData.offsets = [5, 10, 15, 30, 45, 60];
    twilightData.defaultBeginPeriod = "sunrise";
    twilightData.defaultEndPeriod = "sunset";
    twilightData.beginPeriodDropdown = twilightData.defaultBeginPeriod;
    twilightData.beginOffsetDropdown = "at " + twilightData.defaultBeginPeriod;
    twilightData.anyBeginCheck = false;
    twilightData.endPeriodDropdown = twilightData.defaultEndPeriod;
    twilightData.endOffsetDropdown = "at " + twilightData.defaultEndPeriod;
    twilightData.anyEndCheck = false;
    twilightData.phrases = [[], []];
  }

  function updatePhrases(schedule) {
    angular.forEach(schedule.days, function (day, idx) {
      twilightData.phrases[0][idx] =
        $filter("twilightOffsetPhrase")(
          schedule[day.beginField + "_twilight_offset"]
        ) +
        " " +
        schedule[day.beginField + "_twilight_period"];
      twilightData.phrases[1][idx] =
        $filter("twilightOffsetPhrase")(
          schedule[day.endField + "_twilight_offset"]
        ) +
        " " +
        schedule[day.endField + "_twilight_period"];
    });
  }

  function prepInitializationData(schedule, iData) {
    angular.forEach(schedule.days, function (day) {
      if (schedule[day.beginField + "_use_twilight_scheduling"] == true) {
        iData.beginOffset = schedule[day.beginField + "_twilight_offset"];
        iData.beginPeriod = schedule[day.beginField + "_twilight_period"];
      }
      if (
        angular.isDefined(day.endField) &&
        schedule[day.endField + "_use_twilight_scheduling"] == true
      ) {
        iData.endOffset = schedule[day.endField + "_twilight_offset"];
        iData.endPeriod = schedule[day.endField + "_twilight_period"];
      }
    });
  }

  function initDropdowns(schedule, iData) {
    twilightData.beginPeriodDropdown = iData.beginPeriod;
    twilightData.beginOffsetDropdown =
      $filter("twilightOffsetPhrase")(iData.beginOffset) +
      " " +
      iData.beginPeriod;
    twilightData.endPeriodDropdown = iData.endPeriod;
    twilightData.endOffsetDropdown =
      $filter("twilightOffsetPhrase")(iData.endOffset) + " " + iData.endPeriod;
  }

  function initTwilightsNotUsed(schedule, iData) {
    angular.forEach(schedule.days, function (day) {
      if (schedule[day.beginField + "_use_twilight_scheduling"] == false) {
        schedule[day.beginField + "_twilight_offset"] = iData.beginOffset;
        schedule[day.beginField + "_twilight_period"] = iData.beginPeriod;
      }
      if (
        angular.isDefined(day.endField) &&
        schedule[day.endField + "_use_twilight_scheduling"] == false
      ) {
        schedule[day.endField + "_twilight_offset"] = iData.endOffset;
        schedule[day.endField + "_twilight_period"] = iData.endPeriod;
      }
    });
  }

  function setOffsetDropdown(offset, periodDropdown) {
    var phrase = "";
    if (offset == 0) return "At " + periodDropdown;
    else
      return offset < 0
        ? Math.abs(offset) + " Min. Before"
        : offset + " Min. After";
  }

  function manuallySetDays(scheduleConcept, schedules) {
    if (DAY_NOT_ENCODED_SCHEDULE_CONCEPTS.includes(scheduleConcept)) {
      if (angular.isArray(schedules)) {
        var daySuffixes;
        switch (scheduleConcept) {
          case "favorite_schedules":
            daySuffixes = ["_a_time"];
            break;
          case "output_schedules":
            daySuffixes = ["_on", "_off"];
            break;
          default:
            console.warn(
              "scheduleUtilService->manuallySetDays - unsupported concept: " +
                scheduleConcept
            );
            return;
        }
        // Expects utc encoded format like: 2000-01-01T09:00:00Z
        const utcDayIndex = 8;
        angular.forEach(schedules, function (schedule) {
          angular.forEach(daySuffixes, function (suffix) {
            angular.forEach(days, function (dayOfWeek) {
              var time = schedule[dayOfWeek.abbr + suffix];
              // If a time is set for that day...
              if (time) {
                var day = time.substring(utcDayIndex, utcDayIndex + 2);
                // If the day of that time is 01, it is not encoded properly. SCAPI time encoded days go from 02-08
                if (day === "01") {
                  var dateParts = dayOfWeek.date.split("/");
                  schedule[dayOfWeek.abbr + suffix] =
                    time.substring(0, utcDayIndex) +
                    dateParts[1] +
                    time.substring(utcDayIndex + 2, time.length);
                }
              }
            });
          });
        });
      } else {
        console.warn(
          "scheduleUtilService->manuallySetDays - not an array of schedules"
        );
      }
    } else {
      console.warn(
        "scheduleUtilService->manuallySetDays called for unnecessary concept: " +
          scheduleConcept
      );
    }
  }

  function formatScheduleUseArray(scheduleUse) {
    let count = 0;
    const maxCount = 3;
    const formattedScheduleUseArray =
      !!scheduleUse && !!Object.keys(scheduleUse)
        ? Object.keys(scheduleUse)
            .filter((key) => Array.isArray(scheduleUse?.[key]))
            .map((key) => {
              return scheduleUse?.[key].map((item) => {
                count++;
                return count <= maxCount
                  ? `${item.name}&nbsp(${toTitleCase(key.slice(0, -1))})`
                  : null;
              });
            })
            .flat()
            .filter((item) => item)
            .concat(count > maxCount ? [`and ${count - maxCount} more`] : [])
        : [];

    return formattedScheduleUseArray;
  }
}
