import React from "react";

interface ButtonBarProps {
  title: object;
  statToDisplay: string;
  setStatToDisplay: Function;
  dataEntrySample?: object;
}

function DiagnosticGraphButtonBar(props: ButtonBarProps) {
  const { title, statToDisplay, setStatToDisplay, dataEntrySample } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        width: "50%",
        margin: "20px auto",
      }}
    >
      {Object.entries(title).map((entry) => {
        if (
          (dataEntrySample &&
            dataEntrySample[entry[0] as keyof typeof dataEntrySample]) ||
          !dataEntrySample
        ) {
          return (
            <button
              onClick={() => setStatToDisplay(entry[0])}
              className="btn btn-primary btn-sm"
              style={{
                fontWeight: entry[0] === statToDisplay ? "bold" : "normal",
                borderWidth: entry[0] === statToDisplay ? "medium" : "thin",
              }}
            >
              {entry[1].title}
            </button>
          );
        }
      })}
    </div>
  );
}

export default DiagnosticGraphButtonBar;
