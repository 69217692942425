/**
 * @ngdoc service
 * @name App.factory:CustomerListAbbreviatedAPI
 *
 * @description
 * API factory for Customer Score
 *
 */
App.factory("CustomerListAbbreviatedAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/dealers/:dealer_id/customers/list",

      {},
      {
        getAbbreviatedCustomerList: {
          method: "GET",
          isArray: true,
        },
      }
    );
  },
]);
