import styled from "styled-components";

const NakedButton = styled.button`
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  box-shadow: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  font-weight: inherit;
  line-height: 1;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;

export default NakedButton;
