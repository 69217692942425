import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsEntryDelay4Field_systemOptions$key } from "./__generated__/SystemOptionsEntryDelay4Field_systemOptions.graphql";

export const systemOptionsEntryDelay4FieldId = () =>
  "system-options-entry-delay-4";

function EntryDelay4Field() {
  const [{ entryDelay4, entryDelayMin, entryDelayMax }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsEntryDelay4Field_systemOptions$key>(
      graphql`
        fragment SystemOptionsEntryDelay4Field_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            entryDelay4
            entryDelayMin
            entryDelayMax
          }
        }
      `
    );

  const fieldId = systemOptionsEntryDelay4FieldId();
  const originalValue = React.useRef(entryDelay4).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entry Delay 4"
      tooltip="Set the duration for Entry Delay 4 in seconds. Used in Zone Information Programming for Exit Zones."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={entryDelay4?.toString()}
        required
        inlineHelp={`${entryDelayMin}–${entryDelayMax}`}
        min={entryDelayMin}
        max={entryDelayMax}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "entryDelay4"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(entryDelayMin, entryDelayMax, valueAsNumber);
            recordProxy.setValue(value, "entryDelay4");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default EntryDelay4Field;
