/**
 * @generated SignedSource<<b14d67fdad7b75d0f2ceb510b2e8a206>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type BellOptionsSupervisoryBellActionField_bellOptions$data = {
  readonly supervisoryBellAction: BellActions;
  readonly " $fragmentType": "BellOptionsSupervisoryBellActionField_bellOptions";
};
export type BellOptionsSupervisoryBellActionField_bellOptions$key = {
  readonly " $data"?: BellOptionsSupervisoryBellActionField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsSupervisoryBellActionField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsSupervisoryBellActionField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supervisoryBellAction",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "5277dfc26e56297cadfe07a54a233a04";

export default node;
