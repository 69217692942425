App.directive("daRemoteResetButton", [
  "UserService",
  function (UserService) {
    return {
      restrict: "E",
      template: "",

      controller: function (
        $scope,
        $modal,
        ControlSystemService,
        DealerService,
        PanelProgrammingService,
        Customer,
        $q
      ) {},
    };
  },
]);
