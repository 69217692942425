/**
 * @generated SignedSource<<ba6cc6864e3eb6240e41318e6776d211>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XT75OutputInformationProgrammingConceptFormInline_output$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly isWireless: boolean;
  readonly name: string;
  readonly number: number;
  readonly outputSupervision: OutputSupervision | null;
  readonly realTimeStatus: boolean | null;
  readonly serialNumber: string | null;
  readonly tripWithPanelBell: boolean;
  readonly " $fragmentType": "XT75OutputInformationProgrammingConceptFormInline_output";
};
export type XT75OutputInformationProgrammingConceptFormInline_output$key = {
  readonly " $data"?: XT75OutputInformationProgrammingConceptFormInline_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75OutputInformationProgrammingConceptFormInline_output">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75OutputInformationProgrammingConceptFormInline_output"
};

(node as any).hash = "40c88279518d2909af5e5fb337d041d6";

export default node;
