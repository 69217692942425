/**
 * @generated SignedSource<<6ba54b018af27299eea61d578e1e8586>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathEncryptionField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly encryptionEnabled: boolean;
  readonly id: string;
  readonly number: number;
  readonly " $fragmentType": "CommPathEncryptionField_communicationPath";
};
export type CommPathEncryptionField_communicationPath$key = {
  readonly " $data"?: CommPathEncryptionField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathEncryptionField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathEncryptionField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "encryptionEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "0d24d81c1d58a43d2cfab28e6771a034";

export default node;
