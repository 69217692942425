/**
 * @generated SignedSource<<a4de0c3a01193af93a0967cef757fb0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsHoursFromGMTField_systemOptions$data = {
  readonly hoursFromGMT: number;
  readonly hoursFromGMTMax: number;
  readonly hoursFromGMTMin: number;
  readonly " $fragmentType": "SystemOptionsHoursFromGMTField_systemOptions";
};
export type SystemOptionsHoursFromGMTField_systemOptions$key = {
  readonly " $data"?: SystemOptionsHoursFromGMTField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsHoursFromGMTField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsHoursFromGMTField_systemOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursFromGMT",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursFromGMTMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursFromGMTMax",
      "storageKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "57d9fd41ea2a0134c239c79bd4364549";

export default node;
