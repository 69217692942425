/**
 * Component: TableHeaderSortComponent
 *
 * Description:
 * The `TableHeaderSortComponent` is a React functional component used in table headers for
 * sortable columns. It provides interactive sorting functionality for each column in a table,
 * allowing users to sort the table data by clicking on the respective column header.
 *
 * Props:
 * - `columnName`: The name of the column to be displayed in the header.
 * - `sortKey`: A unique key associated with the column, used for sorting logic.
 * - `paginationState`: An object containing the current state of pagination and sorting, including
 *   which column is currently being sorted and the sorting order (ascending or descending).
 * - `dispatch`: A dispatch function for triggering actions, for updating the sorting state.
 *
 * Behavior:
 * - When the column header is clicked, the component dispatches an action to update the sorting state.
 * - If the clicked column is already the active sorted column, it toggles the sorting order
 *   (ascending to descending or vice versa).
 * - If the clicked column is not the current sorted column, it sets this column as the new sorting key.
 * - The component conditionally applies CSS classes based on whether its column is the current sorted
 *   column and the sorting order, to visually indicate the sorting state (e.g., showing an up or down arrow).
 *
 * Styling:
 * - The component uses a combination of static and conditional CSS classes to manage its visual presentation.
 * - The `addClassesIf` utility function is used to dynamically add CSS classes based on the current sorting state.
 *
 * Usage:
 * This component is intended to be used in tables where sorting functionality is required. It abstracts
 * the sorting logic and visual representation, making it reusable for any column in a sortable table.
 */

import { addClassesIf } from "common/utils/web/css";
import {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { TableHeaderSorting } from "components/MobileCredentials/TableStyledComponents";
import React from "react";
import { SortKey } from "./CustomerSummarySystemTableComponent";

interface TableHeaderSortComponentProps {
  columnName: string;
  sortKey: SortKey;
  paginationState: any;
  dispatch: React.Dispatch<any>;
}

const TableHeaderSortComponent: React.FC<TableHeaderSortComponentProps> = ({
  columnName,
  sortKey,
  paginationState,
  dispatch,
}) => {
  const handleClick = () => {
    if (paginationState.sortKey === sortKey) {
      dispatch({
        type: ActionType.SetSortOrder,
        sortOrder:
          paginationState.sortOrder === SortOrder.Ascending
            ? SortOrder.Descending
            : SortOrder.Ascending,
      });
    } else {
      dispatch({
        type: ActionType.SetSortKey,
        sortKey: sortKey,
      });
    }
  };

  // Determine class for sorting arrows only if this column is the active sorted column
  const sortOrderClass =
    paginationState.sortKey === sortKey
      ? paginationState.sortOrder === SortOrder.Descending
        ? "tablesort-desc"
        : "tablesort-asc"
      : "";

  return (
    <TableHeaderSorting
      className={addClassesIf(
        [
          //tablesort-sortable should always be added
          [true, "tablesort-sortable"],
          [sortOrderClass !== "", sortOrderClass],
        ],
        sortOrderClass ? "is-active" : ""
      )}
      onClick={handleClick}
    >
      {columnName}
    </TableHeaderSorting>
  );
};

export default TableHeaderSortComponent;
