/**
 * @ngdoc directive
 * @name App.direct:blurToCurrency
 *
 * @description
 *    On blur, a numeric value of an input field is run through a currency filter
 *
 */

App.directive("daCurrencyFormat", [
  "$filter",
  function ($filter) {
    return {
      require: "?ngModel",
      scope: { ngModel: "=" },
      link: function (scope, elem, attrs, ctrl) {
        if (!ctrl) return;

        ctrl.$parsers.push(function (data) {
          //First we remove anything that's not a digit, a "-" or a ".". Then we remove any "-" or "." at the end of the string.
          var plainNumber = elem
            .val()
            .replace(/[^\d|\-+|\.+]/g, "")
            .replace(/[\D]$/g, "");
          if (plainNumber == "") {
            plainNumber = null;
          }
          return plainNumber;
        });

        ctrl.$formatters.push(function (data) {
          //convert data from model format to view format
          var price = $filter("currency")(null);
          if (price == "") {
            price = null;
          }
          return price;
        });

        elem.bind("blur", function (event) {
          var plainNumber = elem
            .val()
            .replace(/[^\d|\-+|\.+]/g, "")
            .replace(/[\D]$/g, "");
          elem.val($filter("currency")(plainNumber));
          if (plainNumber == "") {
            elem.val("");
          }

          if (plainNumber == "" || $filter("currency")(plainNumber) == "") {
            scope.ngModel = undefined;
          }
          scope.$apply();
          ctrl.$validate();
        });

        ctrl.$validators.currencyerrors = function () {
          var plainNumber = elem
            .val()
            .replace(/[^\d|\-+|\.+]/g, "")
            .replace(/[\D]$/g, "");
          if (
            parseInt(plainNumber) < attrs.cfMin ||
            parseInt(plainNumber) > attrs.cfMax
          ) {
            return false;
          } else {
            return true;
          }
        };
      },
    };
  },
]);
