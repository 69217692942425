import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationFailTimeField_communication$key } from "./__generated__/CommunicationFailTimeField_communication.graphql";

export const communicationFailTimeFieldId = () => "communication-fail-time";

function CommunicationFailTimeField() {
  const [
    { comType, checkInMinutes, failTime, failTimeValidValues },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationFailTimeField_communication$key>(
    graphql`
      fragment CommunicationFailTimeField_communication on Communication {
        comType
        checkInMinutes
        failTime
        failTimeValidValues
      }
    `
  );

  const fieldId = communicationFailTimeFieldId();
  const softwareVersion = useSoftwareVersion();
  const { current: originalValue } = React.useRef(failTime);
  const disabled = ["DD", "CID", "NONE"].includes(comType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={"Fail Time (Minutes)"}
      tooltip="Receiver allows the System this amount of time at the programmed Check-In Time before causing a missing condition. Must be greater than Check-in Minutes."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={failTime}
        required
        disabled={disabled}
        min={checkInMinutes}
        inlineHelp={Number(softwareVersion) < 112 ? `0,15-240` : `0,3-240`}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(emptyStringOrNumber(target.value), "failTime");
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : closest(valueAsNumber, failTimeValidValues);
            recordProxy.setValue(Number(value), "failTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationFailTimeField;
