import React from "react";
import styled from "styled-components";

const StyledUL = styled.ul`
  padding-left: 0;
`;

const StyledLI = styled.li`
  margin-bottom: 2.4rem;
`;

const PanelList: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <StyledUL>{children}</StyledUL>;
};

const ListItem: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <StyledLI>{children}</StyledLI>;
};

export { ListItem, PanelList };
