import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useIsAreaSystem } from "../../SystemOptionsFields/SystemOptionsContext";
import { useAreaFragment } from "./AreaContext";
import { AreaNameField_area$key } from "./__generated__/AreaNameField_area.graphql";

export const areaNameFieldId = (number: string) => `area-name-field-${number}`;

function AreaNameField() {
  const [{ number, name, maxNameLength }, updateArea] =
    useAreaFragment<AreaNameField_area$key>(
      graphql`
        fragment AreaNameField_area on Area {
          name
          number
          maxNameLength
        }
      `
    );

  const fieldId = areaNameFieldId(number);
  const isAreaSystem = useIsAreaSystem();
  const disabled = !isAreaSystem;

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Area Name">
      <TextInput
        id={fieldId}
        name={fieldId}
        value={name}
        required
        maxLength={maxNameLength}
        disabled={disabled}
        pattern="^.*$"
        onChange={({ target }) => {
          updateArea((recordProxy) => {
            recordProxy.setValue(target.value, "name");
          });
        }}
        onBlur={({ target }) => {
          updateArea((recordProxy) => {
            recordProxy.setValue(target.value.trim().toUpperCase(), "name");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaNameField;
