import SchedulesHolidayDates from "components/SchedulesHolidayDates";
import { react2angular } from "react2angular";

App.component(
  "schedulesHolidayDates",
  react2angular(
    SchedulesHolidayDates,
    [],
    ["$stateParams", "RelayService", "UserService", "$rootScope", "$state"]
  )
);
