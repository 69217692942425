/**
 *
 * LightIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const LightIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M45.754,5.937C42.087,2.108,37.204,0,32.002,0c-5.203,0-10.087,2.106-13.754,5.932c-3.416,3.563-5.374,8.219-5.374,12.774
      c0,7.24,3.397,12.613,5.222,15.5c0.344,0.543,0.64,1.013,0.794,1.31c0.642,1.244,0.652,1.318,0.754,2.026
      c0.049,0.346,0.113,0.784,0.234,1.415c0.571,2.991,3.133,4.59,3.423,4.763C23.61,43.903,23.963,44,24.323,44h15.488
      c0.38,0,0.752-0.108,1.073-0.312c0.272-0.173,2.676-1.776,3.239-4.731c0.12-0.629,0.183-1.063,0.232-1.405
      c0.102-0.712,0.11-0.77,0.75-2.013c0.155-0.298,0.45-0.765,0.792-1.305c1.827-2.891,5.229-8.272,5.229-15.52
      C51.126,14.158,49.168,9.501,45.754,5.937z M42.516,32.097c-0.406,0.643-0.727,1.15-0.963,1.605
      c-0.864,1.677-0.99,2.119-1.157,3.283c-0.043,0.297-0.097,0.674-0.202,1.222c-0.155,0.812-0.697,1.439-1.078,1.793H24.978
      c-0.404-0.345-1.016-0.984-1.171-1.797c-0.106-0.55-0.16-0.93-0.204-1.23c-0.166-1.155-0.301-1.63-1.16-3.293
      c-0.234-0.452-0.557-0.963-0.966-1.61c-1.721-2.723-4.603-7.281-4.603-13.363C16.874,11.75,23.087,4,32.002,4
      c8.913,0,15.124,7.754,15.124,14.714C47.126,24.803,44.24,29.369,42.516,32.097z"
        />
        <path
          d="M38.677,45.737l-13.485,0.905c-1.102,0.074-1.936,1.027-1.862,2.129c0.071,1.057,0.95,1.866,1.994,1.866
      c0.045,0,0.09-0.001,0.136-0.004l13.485-0.905c1.102-0.074,1.936-1.027,1.862-2.129C40.732,46.497,39.778,45.665,38.677,45.737z"
        />
        <path
          d="M38.677,51.998l-13.485,0.905c-1.102,0.074-1.936,1.027-1.862,2.129c0.071,1.057,0.95,1.866,1.994,1.866
      c0.045,0,0.09-0.001,0.136-0.004l13.485-0.905c1.102-0.074,1.936-1.027,1.862-2.129S39.778,51.935,38.677,51.998z"
        />
        <path
          d="M33.889,59h-3.582c-0.723,0-1.39,0.39-1.744,1.021s-0.34,1.403,0.036,2.02l0.609,1C29.571,63.637,30.218,64,30.916,64
      h2.371c0.702,0,1.352-0.368,1.714-0.969l0.602-1c0.372-0.618,0.382-1.388,0.027-2.015C35.275,59.388,34.61,59,33.889,59z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default LightIcon;
