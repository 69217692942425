/**
 * @generated SignedSource<<3b3e6d0757f5a62c63d9598c7c215f7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaNameField_area$data = {
  readonly maxNameLength: number;
  readonly name: string;
  readonly number: string;
  readonly " $fragmentType": "AreaNameField_area";
};
export type AreaNameField_area$key = {
  readonly " $data"?: AreaNameField_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaNameField_area">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaNameField_area",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxNameLength",
      "storageKey": null
    }
  ],
  "type": "Area",
  "abstractKey": "__isArea"
};

(node as any).hash = "16c6376185f72d429c11b68ce9def300";

export default node;
