import graphql from "babel-plugin-relay/macro";
import useEffectOnce from "common/hooks/useEffectOnce";
import { DaSwitch } from "components/DaStyledElements";
import LoadingSpinner from "components/LoadingSpinner";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useEffect, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";
import { asID, fromControlSystemId } from "securecom-graphql/client";
import styled from "styled-components/macro";
import Icon from "../Icon";
import AddCamerasManuallyByRtspSection from "./AddCamerasManuallyByRtspSection";
import CameraListItem from "./CameraListItem";
import ConfirmationModal from "./ConfirmationModal";
import { RecorderAddCameraModalDealerChargesQuery } from "./__generated__/RecorderAddCameraModalDealerChargesQuery.graphql";
import { RecorderAddCameraModalMutation } from "./__generated__/RecorderAddCameraModalMutation.graphql";
import { RecorderAddCameraModalRefreshOnlyVarHubMutation } from "./__generated__/RecorderAddCameraModalRefreshOnlyVarHubMutation.graphql";
import { RecorderAddCameraModalScanForNewCamerasMutation } from "./__generated__/RecorderAddCameraModalScanForNewCamerasMutation.graphql";
import { RecorderAddCameraModalUpdateAutoScanMutation } from "./__generated__/RecorderAddCameraModalUpdateAutoScanMutation.graphql";
import { RecorderAddCameraModal_varHub$key } from "./__generated__/RecorderAddCameraModal_varHub.graphql";

export type CameraType = {
  addedToDB: boolean;
  camectCamId: string;
  camectHubId: string;
  cameraId?: number | null;
  cameraName: string;
  ipAddress: string;
  isEnabled: boolean;
  isScapiCamera: boolean;
  isStreaming: boolean;
  macAddress: string;
  needsCredential: boolean;
  playerAuthToken: string;
  playerUrl: string;
  megapixels: number | null;
  videoCodec: string;
  framesPerSecond: number;
  manuallyAdded: boolean;
  isHidden: boolean;
  scapiCameraId: number | null;
};

const RecorderAddCameraModal = ({
  setIsOpen,
  queryRef,
  systemId,
  dealerId,
  canViewPricing,
  userService,
  megapixelsEnabled,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  queryRef: RecorderAddCameraModal_varHub$key | null;
  systemId: string;
  dealerId: string;
  canViewPricing: boolean;
  userService: any;
  megapixelsEnabled: number;
}) => {
  const [showHiddenCameras, setShowHiddenCameras] = useState(false);
  const [isAddManuallyOpen, setIsAddManuallyOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [mpCount, setMpCount] = useState<number>(0);
  const [filteredNonHiddenCameras, setFilteredNonHiddenCameras] = useState<
    CameraType[]
  >([]);
  const [filteredHiddenCameras, setFilteredHiddenCameras] = useState<
    CameraType[]
  >([]);

  const updateAutoScanMutation = graphql`
    mutation RecorderAddCameraModalUpdateAutoScanMutation(
      $hub: UpdateVarHubInput!
    ) {
      updateVarHub(hub: $hub) {
        __typename
        ... on UpdateVarHubSuccessPayload {
          __typename
          status
        }
        ... on UpdateVarHubErrorPayload {
          __typename
          type
          message
        }
      }
    }
  `;

  const showAlert = useShowAlert();
  const confirmAfterWarning = () => {
    setIsConfirmationOpen(false);
    setIsOpen(false);
  };
  const cancelAfterWarning = () => setIsConfirmationOpen(false);

  const toggleHiddenCameras = () => {
    setShowHiddenCameras(!showHiddenCameras);
  };

  const dealerChargesData =
    useLazyLoadQuery<RecorderAddCameraModalDealerChargesQuery>(
      graphql`
        query RecorderAddCameraModalDealerChargesQuery($dealerId: String!) {
          dealerChargesQuery(dealerId: $dealerId) {
            ... on DealerCharges {
              AlarmVisionChannel
            }
            ... on UnknownError {
              type
            }
          }
        }
      `,
      { dealerId: dealerId }
    );

  const data = useFragment(
    graphql`
      fragment RecorderAddCameraModal_varHub on VarHub {
        camectHubId
        hubId
        hubName
        disableAutoAddCameras
        megapixelThresholdWarning
        megapixelThresholdCritical
        supportedMegapixels
        unsupportedFrameRate
        unsupportedCodec
        cameras {
          addedToDB
          camectCamId
          camectHubId
          cameraId
          cameraName
          ipAddress
          isEnabled
          isScapiCamera
          isStreaming
          macAddress
          needsCredential
          playerAuthToken
          playerUrl
          megapixels
          videoCodec
          framesPerSecond
          manuallyAdded
          isHidden
          scapiCameraId
        }
      }
    `,
    queryRef
  );

  const [refreshVarHub, refreshingVarHub] =
    useMutation<RecorderAddCameraModalRefreshOnlyVarHubMutation>(graphql`
      mutation RecorderAddCameraModalRefreshOnlyVarHubMutation(
        $systemId: String!
      ) {
        refreshOnlyVarHub(systemId: $systemId) {
          ... on RefreshOnlyVarHubSuccessPayload {
            hubs {
              camectHubId
              hubId
              disableAutoAddCameras
              cameras {
                addedToDB
                camectCamId
                camectHubId
                cameraId
                cameraName
                ipAddress
                isEnabled
                isScapiCamera
                isStreaming
                macAddress
                needsCredential
                playerAuthToken
                playerUrl
                isHidden
                scapiCameraId
              }
            }
            __typename
          }
          ... on RefreshOnlyVarHubErrorPayload {
            __typename
          }
        }
      }
    `);

  const [scanForNewCameras, scanningForNewCameras] =
    useMutation<RecorderAddCameraModalScanForNewCamerasMutation>(
      graphql`
        mutation RecorderAddCameraModalScanForNewCamerasMutation(
          $systemId: String!
          $hubId: String!
        ) {
          scanForNewCameras(systemId: $systemId, hubId: $hubId) {
            ... on ScanForNewCamerasSuccessPayload {
              hubs {
                disableAutoAddCameras
                cameras {
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraId
                  cameraName
                  ipAddress
                  isEnabled
                  isScapiCamera
                  isStreaming
                  macAddress
                  needsCredential
                  playerAuthToken
                  playerUrl
                  isHidden
                  scapiCameraId
                }
              }
              __typename
            }
            ... on ScanForNewCamerasErrorPayload {
              type
              __typename
            }
          }
        }
      `
    );

  const updateVarHubSettingsMutation = graphql`
    mutation RecorderAddCameraModalMutation(
      $hubSettings: UpdateVarHubSettingsInput!
      $hubId: String!
      $systemId: String!
    ) {
      updateVarHubSettings(
        hubSettings: $hubSettings
        hubId: $hubId
        systemId: $systemId
      ) {
        ... on UpdateVarHubSettingsSuccessPayload {
          __typename
          status
        }
        ... on UpdateVarHubSettingsErrorPayload {
          __typename
          type
          message
        }
      }
    }
  `;
  const [updateAutoScan, isUpdatingAutoScan] =
    useMutation<RecorderAddCameraModalUpdateAutoScanMutation>(
      updateAutoScanMutation
    );
  const [totalPrice, setTotalPrice] = useState<number>(0);

  // if the disableAutoAddCamera boolean is true autoAddCamerasToggle should be false and vice-versa
  const [autoAddCamerasToggle, setAutoAddCamerasToggle] = useState(
    !data?.disableAutoAddCameras ?? true
  );
  const [showAddManualButton, setShowAddManualButton] = useState(
    autoAddCamerasToggle ?? true
  );

  const handleAutoScanCameras = () => {
    if (autoAddCamerasToggle) {
      setAutoAddCamerasToggle(false);
      setIsAddManuallyOpen(true);
      setShowAddManualButton(false);
      if (isAddManuallyOpen) {
        setShowAddManualButton(false);
      }
    } else {
      setAutoAddCamerasToggle(true);
      setIsAddManuallyOpen(false);
      setShowAddManualButton(true);
    }

    const hubSettings = {
      hub: {
        systemId: fromControlSystemId(asID(systemId)).systemId,
        hubId: String(data?.hubId ?? ""),
        hubName: String(data?.hubName ?? ""),
        disableAutoAddCameras: autoAddCamerasToggle,
      },
    };

    updateAutoScan({
      variables: hubSettings,
      onCompleted: (response) => {
        if (response.updateVarHub.__typename === "UpdateVarHubSuccessPayload") {
          showAlert({
            type: "success",
            text: "Successfully updated Auto Discovery.",
          });
          refreshVarHub({
            variables: {
              systemId: String(systemId),
            },
          });
        } else {
          showAlert({
            type: "error",
            text: "Failed to update Auto Discovery.",
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Failed to update Auto Discovery.",
        });
      },
    });
  };

  const rolePreventsToggleAutoDiscovery = () => {
    return userService.isTechSupport() || userService.isAdmin();
  };

  // Updated useEffect to handle changes in the "showHiddenCameras" state
  useEffect(() => {
    if (data?.cameras) {
      // Separate non-hidden and hidden cameras
      const hiddenCameras = data.cameras.filter((camera) => camera.isHidden);
      const nonHiddenCameras = data.cameras.filter(
        (camera) => !camera.isHidden
      );

      // Set the state variables to update the UI
      setFilteredNonHiddenCameras(nonHiddenCameras);
      setFilteredHiddenCameras(hiddenCameras);

      // Calculate total price, megapixels, and camera count for non-hidden cameras
      // (Same calculations as before)
      const nonHiddenTotalPrice = nonHiddenCameras.reduce(
        (accumulator, camera) => calculateTotalPrice(accumulator, camera),
        0
      );
      const nonHiddenMpCount = nonHiddenCameras.reduce(
        (acc, camera) => calculateMegaPixelsOrCameras("MegaPixel", acc, camera),
        0
      );
      const nonHiddenCamCount = nonHiddenCameras.reduce(
        (acc, camera) => calculateMegaPixelsOrCameras("Camera", acc, camera),
        0
      );

      // Set the state variables for non-hidden cameras
      setTotalPrice(nonHiddenTotalPrice);
      setMpCount(nonHiddenMpCount);
    }
  }, [data?.cameras, showHiddenCameras]);

  useEffect(() => {
    if (data?.cameras) {
      // add the number of cameras where addedToDB is true
      const price = data.cameras.reduce(
        (accumulator, camera) => calculateTotalPrice(accumulator, camera),
        0
      );
      setTotalPrice(price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.cameras]);

  const CAMERA_PRICE =
    dealerChargesData?.dealerChargesQuery?.AlarmVisionChannel;

  const calculateTotalPrice = (accumulator: number, camera: CameraType) => {
    return camera.addedToDB && camera.isEnabled
      ? CAMERA_PRICE
        ? (accumulator += Number(CAMERA_PRICE))
        : (accumulator += 0)
      : accumulator;
  };

  const calculateMegaPixelsOrCameras = (
    typeofCalculation: string,
    accumulator: number,
    camera: CameraType
  ) => {
    return typeofCalculation === "MegaPixel"
      ? camera.addedToDB && camera.megapixels
        ? accumulator +
          (camera.megapixels < 1 ? 1 : Math.round(camera.megapixels))
        : accumulator
      : camera.addedToDB
      ? accumulator + 1
      : accumulator;
  };

  const disableDiscoveryWarning =
    "By disabling auto-discovery currently associated cameras will not be able to repair their connection to the Gateway if their IP address changes.";

  const megapixelThresholdWarning = data?.megapixelThresholdWarning ?? false;
  const megapixelThresholdCritical = data?.megapixelThresholdCritical ?? false;
  const supportedMegapixels = data?.supportedMegapixels ?? 24;
  const unsupportedFrameRate = data?.unsupportedFrameRate ?? false;
  const unsupportedCodec = data?.unsupportedCodec ?? false;

  const refreshCameras = () => {
    if (autoAddCamerasToggle === true) {
      scanForNewCameras({
        variables: {
          hubId: String(data?.hubId),
          systemId: systemId,
        },
        onCompleted() {
          //This is to refresh the list of cameras after scanning for new cameras and make sure snapshots are updated
          refreshVarHub({
            variables: {
              systemId: String(systemId),
            },
          });
        },
      });
    }
    refreshVarHub({
      variables: {
        systemId: String(systemId),
      },
      onError(error) {
        showAlert({
          type: "error",
          text: `Error refreshing list.`,
        });
      },
      onCompleted(response) {
        if (
          response.refreshOnlyVarHub.__typename ===
          "RefreshOnlyVarHubErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: `Error Refreshing List.`,
          });
        } else {
          showAlert({
            type: "success",
            text: `Successfully refreshed list.`,
          });
        }
      },
    });
  };

  useEffect(() => {
    if (data?.cameras) {
      setMpCount(
        data?.cameras?.reduce(
          (acc, camera) =>
            calculateMegaPixelsOrCameras("MegaPixel", acc, camera),
          0
        )
      );
    }
  }, [data]);

  const sortVarHubCameras = (cameras: any) => {
    const sortHelperFunction = (array: CameraType[]) =>
      array.sort((a: CameraType, b: CameraType) => {
        return (
          a.cameraName.localeCompare(b.cameraName) ||
          a.macAddress.localeCompare(b.macAddress)
        );
      });

    const camerasAddedToDB: CameraType[] = sortHelperFunction(
      cameras.filter((camera: CameraType) => camera.addedToDB)
    );

    const camerasNotAddedToDB: CameraType[] = sortHelperFunction(
      cameras.filter((camera: CameraType) => !camera.addedToDB)
    );

    return [...camerasAddedToDB, ...camerasNotAddedToDB];
  };
  const [updateAutoAddCameraSetting, isUpdatingAutoAddCameraSetting] =
    useMutation<RecorderAddCameraModalMutation>(updateVarHubSettingsMutation);

  useEffectOnce(() => {
    if (autoAddCamerasToggle === true) refreshCameras(); // runs a refresh on the first render of the modal to scan for new cameras
  });
  if (!data) return null;
  return (
    <Modal size="large" onClickOutside={() => setIsOpen(false)}>
      {isConfirmationOpen && (
        <ConfirmationModal
          mpCount={mpCount}
          supportedMegapixels={supportedMegapixels}
          megapixelThresholdCritical={megapixelThresholdCritical}
          unsupportedFrameRate={unsupportedFrameRate}
          unsupportedCodec={unsupportedCodec}
          confirm={confirmAfterWarning}
          cancel={cancelAfterWarning}
        />
      )}
      <Modal.Header>
        <HeaderContent>
          <h4 style={{ margin: 0 }}>
            <span className="mar-r-8">XV Gateway Cameras</span>
            {isUpdatingAutoAddCameraSetting ||
            scanningForNewCameras ||
            refreshingVarHub ? (
              <LoadingSpinner />
            ) : null}
          </h4>
          <InnerHeaderContainer>
            <DaSwitch
              id={`auto-discover-cameras`}
              label="Auto Discover Cameras"
              checked={autoAddCamerasToggle}
              labelPosition="left"
              onChange={handleAutoScanCameras}
              disabled={rolePreventsToggleAutoDiscovery()}
            />
            <button
              className="btn btn-default btn-sm"
              onClick={refreshCameras}
              disabled={autoAddCamerasToggle === false}
            >
              {refreshingVarHub || scanningForNewCameras ? (
                <LoadingSpinner style={{ marginRight: "5px" }} />
              ) : null}{" "}
              Refresh
            </button>

            <button
              onClick={() =>
                megapixelThresholdWarning || megapixelThresholdCritical
                  ? setIsConfirmationOpen(true)
                  : setIsOpen(false)
              }
              className="btn btn-dmp btn-sm"
              disabled={isUpdatingAutoScan}
            >
              Close
            </button>
          </InnerHeaderContainer>
        </HeaderContent>

        {autoAddCamerasToggle && !isAddManuallyOpen ? (
          <HeaderNote>
            Cameras are automatically discovered if they exist on the same
            subnet as the XV Gateway. Keep in mind that the camera performance
            may vary with camera count, frame rate <em>(min: 15FPS)</em>, codec{" "}
            <em>(min: H.264)</em>, and motion detection.
          </HeaderNote>
        ) : null}
        {showAddManualButton || (autoAddCamerasToggle && !isAddManuallyOpen) ? (
          <button
            className="btn btn-default btn-sm"
            onClick={() => {
              setIsAddManuallyOpen(true);
              setShowAddManualButton(false);
            }}
          >
            + Add Manually
          </button>
        ) : null}
      </Modal.Header>

      <Modal.Body>
        {isAddManuallyOpen || !autoAddCamerasToggle ? (
          <AddCamerasManuallyByRtspSection
            setIsOpen={setIsAddManuallyOpen}
            hubId={data.hubId}
            systemId={systemId}
            supportedMegapixels={supportedMegapixels}
            megapixelThresholdCritical={megapixelThresholdCritical}
            megapixelsEnabled={megapixelsEnabled}
          />
        ) : null}

        {/* Non-hidden cameras section */}
        {filteredNonHiddenCameras.length === 0 ? (
          <NoCamerasInfo>
            <i className="icon-radial_info" color="gray">
              &nbsp;
            </i>
            Automatically discovered or manually added cameras will appear here.
            Click “Refresh” to display the latest list of cameras.
          </NoCamerasInfo>
        ) : (
          <>
            <GridContainer>
              <PreviewHeader>Preview</PreviewHeader>
              <NameHeader>Name</NameHeader>
              <IPHeader>IP Address</IPHeader>
              <MacHeader>MAC Address</MacHeader>
              <SettingsHeader>Settings</SettingsHeader>
              {canViewPricing ? (
                <PriceHeader>
                  Total {`$${Number(totalPrice).toFixed(2)}/mo`}
                </PriceHeader>
              ) : (
                <ActivationHeader>Activation</ActivationHeader>
              )}
              {sortVarHubCameras(filteredNonHiddenCameras).map((cam, index) => (
                <CameraListItem
                  key={cam.camectCamId}
                  camera={cam}
                  index={index}
                  systemId={systemId}
                  hubId={data?.hubId}
                  cameraPrice={CAMERA_PRICE ? CAMERA_PRICE : 0}
                  canViewPricing={canViewPricing}
                  autoAddCameras={autoAddCamerasToggle}
                />
              ))}
            </GridContainer>
          </>
        )}

        <CenteredButtonWrapper>
          <HiddenCamerasButton
            onClick={toggleHiddenCameras}
            showHiddenCameras={showHiddenCameras}
            className={
              filteredNonHiddenCameras.length === 0 ? "withPadding" : ""
            }
          >
            <Chevron
              name={showHiddenCameras ? "control_up" : "control_down"}
              onClick={toggleHiddenCameras}
            />
            Hidden Cameras
          </HiddenCamerasButton>
        </CenteredButtonWrapper>

        {/* Hidden cameras section (conditionally rendered) */}
        {showHiddenCameras && filteredHiddenCameras.length === 0 && (
          <NoHiddenCamerasInfo>
            <i className="icon-radial_info" color="gray">
              &nbsp;
            </i>
            No hidden cameras. Select options in camera row to hide.
          </NoHiddenCamerasInfo>
        )}
        {showHiddenCameras && filteredHiddenCameras.length > 0 && (
          <GridContainer>
            <PreviewHeader>Preview</PreviewHeader>
            <NameHeader>Name</NameHeader>
            <IPHeader>IP Address</IPHeader>
            <MacHeader>MAC Address</MacHeader>
            <SettingsHeader>Settings</SettingsHeader>
            <ActivationHeader>Activation</ActivationHeader>
            {sortVarHubCameras(filteredHiddenCameras).map((cam, index) => (
              <CameraListItem
                key={cam.camectCamId}
                camera={cam}
                index={index}
                systemId={systemId}
                hubId={data?.hubId}
                cameraPrice={CAMERA_PRICE ? CAMERA_PRICE : 0}
                canViewPricing={canViewPricing}
                autoAddCameras={autoAddCamerasToggle}
              />
            ))}
          </GridContainer>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RecorderAddCameraModal;

const PreviewHeader = styled.span`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NameHeader = styled.span`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderNote = styled.div`
  width: 100%;
  margin-left: 5px;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
`;
const RecommendedSettingsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-left: 5px;
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  font-weight: 600;
`;
const RecommendedSettings = styled.div`
  display: flex;
  gap: 6.8rem;
  margin-left: 5px;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;
const RecommendedSetting = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
const InnerHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
const IPHeader = styled.span`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MacHeader = styled.span`
  grid-column: 4 / 5;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SettingsHeader = styled.span`
  grid-column: 5 / 6;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PriceHeader = styled.span`
  grid-column: 6 / 7;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;
const ActivationHeader = styled.span`
  grid-column: 6 / 7;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GridContainer = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: [first] 154px 210px repeat(4, 125px) [end];
  grid-auto-rows: minmax(min-content, max-content);
  overflow-x: scroll;
  font-size: 1.4rem;
`;
const ModalHeader = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3rem;
  margin-left: 5px;
  margin-bottom: 1.6rem;
`;
const HeaderContent = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3rem;
  margin-left: 5px;
  margin-bottom: 0.8rem;
`;
const NoCamerasInfo = styled.div`
  color: gray;
  display: flex;
  align-items: center;
`;
const NoHiddenCamerasInfo = styled.div`
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
`;
type HiddenCamerasButtonProps = {
  showHiddenCameras: boolean;
};
const HiddenCamerasButton = styled.button<HiddenCamerasButtonProps>`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.3rem;
  color: #3c85a3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  margin-bottom: ${(props) => (props.showHiddenCameras ? "2rem" : "0")};
  &.withPadding {
    margin-top: 2rem;
  }
`;
const CenteredButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
const Chevron = styled(Icon)`
  margin-right: 0.4rem;
  font-size: 1.2rem;
  cursor: pointer;
`;
