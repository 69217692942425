import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { formatListOfNumbers } from "../../utils/format";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationPresignalKeypadsField_zone$key } from "./__generated__/ZoneInformationPresignalKeypadsField_zone.graphql";

export const zoneInformationPresignalKeypadsFieldId = (number: string) =>
  `zone-information-presignal-keypads-${number}`;

function ZoneInformationPresignalKeypadsField() {
  const [
    { number, presignalKeypads, type, retardDelayEnabled },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationPresignalKeypadsField_zone$key>(
    graphql`
      fragment ZoneInformationPresignalKeypadsField_zone on Zone {
        number
        presignalKeypads
        type
        retardDelayEnabled
      }
    `
  );

  const fieldId = zoneInformationPresignalKeypadsFieldId(String(number));
  const hardwareModel = useHardwareModel();

  const validZoneTypes = [
    ZoneType.FIRE,
    ZoneType.PANIC,
    ZoneType.SUPERVISORY,
    ZoneType.CARBON_MONOXIDE,
    ZoneType.AUXILIARY_1,
    ZoneType.AUXILIARY_2,
  ].includes(type as ZoneType);

  const disabled = !validZoneTypes || (validZoneTypes && !retardDelayEnabled);

  const { PRESIGNAL_KEYPADS: RULES } = resolveZoneFieldRules(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Presignal Keypads"
      tooltip="List all Keypad addresses that should signal a Zone Short during the Zone Retard Delay."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        value={presignalKeypads ?? ""}
        pattern={RULES?.PATTERN}
        inlineHelp={RULES?.INLINE_HELP}
        validationMessage={RULES?.VALIDATION_MSG}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "presignalKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "presignalKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationPresignalKeypadsField;
