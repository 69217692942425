/**
 * @generated SignedSource<<303920de2e9aa0ded45e5be2f3f6eafd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DigitalWatchdogSpectrum_site$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DigitalWatchdogSpectrumNvrs_site" | "DigitalWatchdogSpectrumTypeButton_site">;
  readonly " $fragmentType": "DigitalWatchdogSpectrum_site";
};
export type DigitalWatchdogSpectrum_site$key = {
  readonly " $data"?: DigitalWatchdogSpectrum_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"DigitalWatchdogSpectrum_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DigitalWatchdogSpectrum_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DigitalWatchdogSpectrumNvrs_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DigitalWatchdogSpectrumTypeButton_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "07ab569ae6b5c845850f9454410498c9";

export default node;
