/**
 * @generated SignedSource<<da16f142e86e1a6bd5d45efa54ad0896>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTOutputInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly isTakeoverPanel: boolean;
  readonly panel: {
    readonly id: string;
    readonly maxOutputs: number;
    readonly outputInformations: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XTOutputInformationProgrammingConceptFormInline_output">;
    }>;
  };
  readonly " $fragmentType": "XTOutputInformationProgrammingConceptFormInline_controlSystem";
};
export type XTOutputInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTOutputInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTOutputInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTOutputInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "e80c077681f4358c2c252b1b3975e841";

export default node;
