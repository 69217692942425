import * as React from "react";
import styled from "styled-components";
import { prop } from "ramda";
import { FormattedMessage } from "react-intl";
import NakedList from "../NakedList";
import NakedButton from "../NakedButton";
import Zone, { zoneListItemStyle, TitleColumn, Columns, Column } from "./Zone";
import { Zone_zone } from "../ArmingWidget/__generated__/zone_zone.graphql";
import { ZoneStatuses_controlSystem$data } from "../ArmingWidget/__generated__/ZoneStatuses_controlSystem.graphql";

const Header = styled.header`
  ${zoneListItemStyle};
  font-weight: bold;
  min-height: 50px;
  max-height: 50px;
  padding-left: 20px;
  line-height: 1.1;
`;
const ColumnTitle = styled.span`
  font-size: 0.875em;
  line-height: 1.1;
`;
const BypassAllButton = styled(NakedButton)`
  display: block;
  margin: 0 auto;
  color: ${({ theme }) => theme.secondary};
  font-weight: bold;
  font-size: 0.8571428571em;
`;

type Props = {
  title: React.ReactNode;
  zones: ZoneStatuses_controlSystem$data["zones"];
  canBypassZones: boolean;
};

const ZonesList = ({ title, zones, canBypassZones }: Props) => {
  return (
    <React.Fragment>
      <Header>
        <TitleColumn>{title}</TitleColumn>
        <Columns hasBypass={canBypassZones}>
          <Column minWidth={80}>
            <ColumnTitle>
              <FormattedMessage
                description="ZonesList number title"
                defaultMessage="Number"
              />
            </ColumnTitle>
          </Column>

          <Column minWidth={80}>
            <ColumnTitle>
              <FormattedMessage
                description="ZonesList status title"
                defaultMessage="Status"
              />
            </ColumnTitle>
          </Column>
          {canBypassZones && (
            <Column minWidth={80}>
              <ColumnTitle>
                <FormattedMessage
                  description="ZonesList bypass title"
                  defaultMessage="Bypass"
                />
              </ColumnTitle>
            </Column>
          )}
        </Columns>
      </Header>
      <NakedList>
        {zones.map((zone: any) => {
          return (
            <Zone canBypassZones={canBypassZones} zone={zone} key={zone.id} />
          );
        })}
      </NakedList>
    </React.Fragment>
  );
};

export default ZonesList;
