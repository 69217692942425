import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsDoorAccessReportsEnabledField_pcLogReports$key } from "./__generated__/PcLogReportsDoorAccessReportsEnabledField_pcLogReports.graphql";

export const pcLogReportsDoorAccessReportsEnabledFieldId = () =>
  "pc-log-reports-door-access-reports-enabled";

function PcLogReportsDoorAccessReportsEnabledField() {
  const [{ doorAccessReportsEnabled }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsDoorAccessReportsEnabledField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsDoorAccessReportsEnabledField_pcLogReports on PcLogReports {
          doorAccessReportsEnabled
        }
      `
    );

  const fieldId = pcLogReportsDoorAccessReportsEnabledFieldId();
  const label = "Door Access Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Sends door access activity: door number, user number and name, and time and date."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={!!doorAccessReportsEnabled}
        onChange={() => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(
              !doorAccessReportsEnabled,
              "doorAccessReportsEnabled"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsDoorAccessReportsEnabledField;
