import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  CommPathTrouble,
  PanelProgrammingLanguage,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import Select from "../Select";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { StatusListDisplayCommPathTroubleField_statusListDisplay$key } from "./__generated__/StatusListDisplayCommPathTroubleField_statusListDisplay.graphql";

export const statusListDisplayCommPathTroubleFieldId = () =>
  "status-list-display-comm-path-trouble";

function StatusListDisplayCommPathTroubleField() {
  const [{ commPathTrouble }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplayCommPathTroubleField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplayCommPathTroubleField_statusListDisplay on StatusListDisplay {
          commPathTrouble
        }
      `
    );

  const fieldId = statusListDisplayCommPathTroubleFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Comm Path Trouble"
      tooltip="Specifies if System Trouble Status Monitor keypads will also show Communication Path Troubles and if All Paths must fail before annunciation."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={commPathTrouble ?? PanelProgrammingLanguage.ENGLISH}
        required
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "commPathTrouble");
          });
        }}
      >
        <Select.Option value={CommPathTrouble.YES}>Yes</Select.Option>
        <Select.Option value={CommPathTrouble.NO}>No</Select.Option>
        <Select.Option value={CommPathTrouble.ALL}>All</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplayCommPathTroubleField;
