import NakedButton from "common/components/web/Button/NakedButton";
import { IconSvg } from "components/Icon";
import { CSSProperties } from "react";
import styled from "styled-components";

const PillButton = styled(NakedButton)<{
  marginLeft?: CSSProperties["marginLeft"];
  marginRight?: CSSProperties["marginRight"];
  marginTop?: CSSProperties["marginTop"];
  marginBottom?: CSSProperties["marginBottom"];
  marginX?: CSSProperties["marginLeft"];
  marginY?: CSSProperties["marginTop"];
}>`
  border-radius: 9999px;
  border: 1px solid var(--border-color);
  padding: var(--measure-half) var(--measure-12);
  margin-left: ${({ marginLeft, marginX }) => marginLeft ?? marginX ?? 0};
  margin-right: ${({ marginRight, marginX }) => marginRight ?? marginX ?? 0};
  margin-top: ${({ marginTop, marginY }) => marginTop ?? marginY ?? 0};
  margin-bottom: ${({ marginBottom, marginY }) => marginBottom ?? marginY ?? 0};
  color: var(--color);
  background-color: var(--bg-color);
  white-space: nowrap;
  transition: 0.3s all cubic-bezier(0.25, 0.8, 0.25, 1);

  & ${IconSvg} {
    font-size: 0.8em;
  }

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
`;

export const PrimaryWhitePillButton = styled(PillButton)`
  --border-color: var(--color-info-400);
  --color: var(--color-info-800);
  --bg-color: white;

  &:hover {
    --border-color: var(--color-info-700);
    --bg-color: var(--color-info-600);
    --color: white;
  }
`;

export default PrimaryWhitePillButton;

export const MutedNeutralPillButton = styled(PillButton)`
  --bg-color: #c0c0c0;
  --border-color: var(--bg-color);
  --color: var(--color-neutral-900);

  &:hover {
    --bg-color: var(--color-neutral-600);
    --color: black;
  }
`;
