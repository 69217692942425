/**
 * @generated SignedSource<<ef3141e7f9150bb34a65b182519b171f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "TakeoverPanelLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TakeoverPanelLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TakeoverPanelLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "0a92376fae07513c7882bcbeb8e5c4ba";

export default node;
