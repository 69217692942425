/**
 * @ngdoc directive
 * @name App.directive:formInput
 * @restrict E
 * @description
 * Provides a programming form input.  It will display the correct input based on the type of object passed in.
 *
 * @param {Object} ngModel the model that this input will set
 * @param {Object} ngModelErrors the object that contains any API errors for the specified ngModel
 * @param {Object} dynamicSelectValues the object that contains any values for this input.  If this is a defined object, the input field will be set to a select input type
 * @param {Object} dynamicDisabled the object that contains the rules that define when this field should be disabled
 * @param {Object} fieldObjectModel the object that contains this field, such as an item in an ng-repeat, or even a Panel.system_options
 * @param {Boolean} app Optional a boolean used to determine if app form input templates should be used
 * Example:
 * <pre> <form-input ng-model ng-model-errors dynamic-select-values ></form-input> </pre>
 */
App.directive("daFormInput", [
  "$compile",
  "$http",
  "$templateRequest",
  "$filter",
  "INPUT_PROPERTIES",
  "UserService",
  function (
    $compile,
    $http,
    $templateRequest,
    $filter,
    INPUT_PROPERTIES,
    UserService
  ) {
    return {
      replace: true,
      scope: {
        ngModel: "=",
        ngModelErrors: "=",
        fieldObjectModel: "=",
        panelModel: "=",
        item: "=",
        app: "<",
        concept: "<",
        form: "=",
        customDisable: "<",
        forceEnable: "<",
        forceDisable: "<",
      },
      templateUrl:
        "app/programming/partials/input-field-elements/prog-form-input-text.html",
      restrict: "E",
      link: function (scope, element, attrs) {
        scope["debounce_ms"] = INPUT_PROPERTIES.PROGRAMMING_DEBOUNCE_MS;
        scope["blur_ms"] = INPUT_PROPERTIES.PROGRAMMING_BLUR_MS;
        scope["UserService"] = UserService;
        var textTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-text.html";
        var passwordTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-password.html";
        var selectTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-select.html";
        var numberTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-number.html";
        var toggleTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-toggle.html";
        var emailTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-email.html";
        var timeTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-time.html";
        var hiddenTemplate =
          "app/programming/partials/input-field-elements/prog-form-input-hidden.html";

        var appTextTemplate =
          "templates/programming/partials/input-field-elements/prog-form-app-input-text.html";
        var appNumberTemplate =
          "templates/programming/partials/input-field-elements/prog-form-app-input-number.html";
        var appToggleTemplate =
          "templates/programming/partials/input-field-elements/prog-form-app-input-toggle.html";

        var getTemplate = function (inputType, isApp) {
          var template = "";
          switch (inputType) {
            case "String":
              template = isApp ? appTextTemplate : textTemplate;
              break;
            case "IP Address":
              template = textTemplate;
              break;
            case "Boolean":
              template = isApp ? appToggleTemplate : toggleTemplate;
              break;
            case "Select":
              template = selectTemplate;
              break;
            case "Numeric":
              template = isApp ? appNumberTemplate : numberTemplate;
              break;
            case "email":
              template = emailTemplate;
              break;
            case "UTC Time":
              template = timeTemplate;
              break;
            case "Hidden":
              template = hiddenTemplate;
              break;
            case "Password":
              template = passwordTemplate;
              break;
            default:
              template = textTemplate;
              break;
          }
          return template;
        };

        function update() {
          scope.opts = attrs;
          scope.dynamicSelectValues = scope.fieldObjectModel.VALUES;
          scope.dynamicPanelRules = scope.fieldObjectModel.RULES;
          if (scope.fieldObjectModel.hasOwnProperty("DISPLAY")) {
            scope.dynamicPattern = scope.fieldObjectModel.DISPLAY.PATTERN;
            scope.opts.placeholder = scope.fieldObjectModel.DISPLAY.PLACEHOLDER;
            scope.opts.maxLength = scope.fieldObjectModel.DISPLAY.MAXLENGTH;
            scope.opts.zeroPad = scope.fieldObjectModel.DISPLAY.ZEROPAD;
            scope.opts.isIpv6 = scope.fieldObjectModel.DISPLAY.ISIPV6;
          } else {
          }
          scope.opts.testid = scope.concept.key + "." + attrs.dynamicName;
          scope.opts.forceEnable = scope.forceEnable
            ? scope.forceEnable
            : false;
          scope.opts.forceDisable = scope.forceDisable
            ? scope.forceDisable
            : false;

          var inputType = DoesNestedPropertyExist(
            scope.fieldObjectModel,
            "DISPLAY.Data_Type"
          )
            ? scope.fieldObjectModel.DISPLAY.Data_Type
            : "String";
          var isApp = scope.app || false;
          var values = scope.fieldObjectModel.VALUES;
          if (angular.isDefined(values)) {
            inputType = "Select";
          }
          if (attrs.hidden) {
            inputType = "Hidden";
          }

          $templateRequest(getTemplate(inputType, isApp))
            .then(function (html) {
              element.html(html);
              $compile(element.contents())(scope);
            })
            .catch(function (error) {
              console.error(error);
            });
        }

        scope.$watch("customDisable", function (newValue) {
          if (newValue || !scope.fieldObjectModel.hasOwnProperty("DISPLAY")) {
            scope.dynamicPattern = "^.*$";
          } else {
            scope.dynamicPattern = scope.fieldObjectModel.DISPLAY.PATTERN;
          }
        });

        // Watch for future updates, only for fields with OVERRIDE rules
        var overrideRules = $filter("filter")(scope.fieldObjectModel.RULES, {
          TYPE: "OVERRIDE_FIELD",
        });
        if (overrideRules && overrideRules.length > 0) {
          scope.$watch(
            "fieldObjectModel.DISPLAY",
            function (newValue, oldValue) {
              update();
            }
          );
        }

        if (
          attrs.dynamicName === "fail_time" ||
          attrs.dynamicName === "ext_chkin" ||
          attrs.dynamicName === "chkin_min"
        ) {
          var comRef = "item." + attrs.dynamicName;
          scope.$watch(comRef, function (newValue, oldValue) {
            if (newValue !== oldValue) {
              var checkinFailValidationMessage =
                "Fail Time must be at least Check-in Time";
              var failTime = +scope.item.fail_time;
              var checkinTime = angular.isDefined(scope.item.ext_chkin)
                ? +scope.item.ext_chkin
                : +scope.item.chkin_min;
              var failTooLow = failTime < checkinTime;
              scope.Panel.setPanelErrors(
                scope.form,
                scope.concept.key,
                scope.item.number,
                "fail_time",
                failTooLow,
                checkinFailValidationMessage
              );
              if (scope.Panel.isXR550Family(scope.Panel.panel_model)) {
                scope.Panel.setPanelErrors(
                  scope.form,
                  scope.concept.key,
                  scope.item.number,
                  "ext_chkin",
                  failTooLow,
                  checkinFailValidationMessage
                );
              } else {
                scope.Panel.setPanelErrors(
                  scope.form,
                  scope.concept.key,
                  scope.item.number,
                  "chkin_min",
                  failTooLow,
                  checkinFailValidationMessage
                );
              }
            }
          });
        }

        // Watch for updates to Access or Egress Areas. They cannot share any of the same values.
        if (
          attrs.dynamicName === "acc_areas" ||
          attrs.dynamicName === "egr_areas"
        ) {
          var dvcRef = "item." + attrs.dynamicName;
          scope.$watch(dvcRef, function (newValue, oldValue) {
            if (newValue !== oldValue) {
              var accessEgressValidationMessage =
                "Access Areas and Egress Areas cannot contain any of the same areas.";
              var overlap = false;
              if (scope.item.acc_areas !== "" && scope.item.egr_areas !== "") {
                var accAreas = numberRangeToCSV(scope.item.acc_areas);
                var egrAreas = numberRangeToCSV(scope.item.egr_areas);
                for (var i = 0; i < accAreas.length; i++) {
                  if (egrAreas.indexOf(accAreas[i]) >= 0) {
                    overlap = true;
                    break;
                  }
                }
              }
              scope.Panel.setPanelErrors(
                scope.form,
                scope.concept.key,
                scope.item.number,
                "acc_areas",
                overlap,
                accessEgressValidationMessage
              );
              scope.Panel.setPanelErrors(
                scope.form,
                scope.concept.key,
                scope.item.number,
                "egr_areas",
                overlap,
                accessEgressValidationMessage
              );
            }
          });
        }

        // Call update to compile the formInput the first time
        update();
      },
      controller: function ($scope, $element, $attrs) {
        $scope.Panel = $scope.panelModel;
      },
    };
  },
]);
