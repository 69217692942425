import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationRelockOnZone2ChangeField_deviceInformation$key } from "./__generated__/DeviceInformationRelockOnZone2ChangeField_deviceInformation.graphql";

export const deviceInformationRelockOnZone2ChangeFieldId = (number: string) =>
  `device-information-relock-on-zone2-change-${number}`;

function DeviceInformationRelockOnZone2ChangeField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationRelockOnZone2ChangeField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationRelockOnZone2ChangeField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                zone2RelockOnStateChange
              }
            }
          }
          ... on Xt75DeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                zone2RelockOnStateChange
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationRelockOnZone2ChangeFieldId(String(number));
  const label = "Relock on Zone 2 Change";
  const relayEnv = useRelayEnvironment();
  const disabled =
    deviceType !== DeviceInformationType.DOOR || !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to relock the door when Zone 2 changes state. Disable to leave the relay on for the Door Access Time upon Zone 2 restoral."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        checked={device734?.zone2RelockOnStateChange ?? false}
        disabled={disabled}
        onChange={() => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                !device734?.zone2RelockOnStateChange,
                "zone2RelockOnStateChange"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationRelockOnZone2ChangeField;
