import { matchesModelPrefixes } from "utils/control-systems";

App.controller("SystemTestsCtrl", [
  "$rootScope",
  "$scope",
  "$stateParams",
  "UserService",
  "ControlSystemsService",
  "DealerService",
  "ZWaveService",
  "COMM_TEST_RESPONSE_MESSAGES",
  "PANEL_TEST_STATUSES",
  "PANEL_TEST_TYPES",
  "PANEL_TEST_VERSIONS",
  "XR_PANEL_COMM_TYPE",
  "PANEL_COMM_TYPE",
  "COMM_TEST_TYPES",
  "WALK_TEST_TYPE",
  "OnlinePanelService",
  "$filter",
  "$q",
  "PanelCapabilitiesService",
  function (
    $rootScope,
    $scope,
    $stateParams,
    UserService,
    ControlSystemsService,
    DealerService,
    ZWaveService,
    COMM_TEST_RESPONSE_MESSAGES,
    PANEL_TEST_STATUSES,
    PANEL_TEST_TYPES,
    PANEL_TEST_VERSIONS,
    XR_PANEL_COMM_TYPE,
    PANEL_COMM_TYPE,
    COMM_TEST_TYPES,
    WALK_TEST_TYPE,
    OnlinePanelService,
    $filter,
    $q,
    PanelCapabilitiesService
  ) {
    $scope.UserService = UserService;
    $scope.ControlSystemsService = ControlSystemsService;
    $scope.OnlinePanelService = OnlinePanelService;
    UserService.customer_id = $stateParams.customer_id;
    UserService.control_system_id = $stateParams.control_system_id;
    UserService.panel_id = $stateParams.panel_id;
    $scope.reportType = $stateParams.report_type;
    $scope.hardware_family =
      UserService.controlSystem.panels[0].hardware_family;

    // Reference to the current control system
    $scope.controlSystem = ControlSystemsService.currentControlSystem;
    // Get the panel firmware version
    $scope.firmware =
      ControlSystemsService.currentControlSystem.panels[0].software_version;
    // Get the Z-Wave enabled state
    $scope.zwaveEnabled;
    // Are the maximum number of Z-Wave devices selected from the list (Used to disable checkboxes in the device list)
    $scope.maxSelected = false;
    // Scope variable for exposing Panel Test Statuses constants
    $scope.PANEL_TEST_STATUSES = PANEL_TEST_STATUSES;
    $scope.PANEL_TEST_TYPES = PANEL_TEST_TYPES;
    $scope.COMM_TEST_RESPONSE_MESSAGES = COMM_TEST_RESPONSE_MESSAGES;
    // All panel tests that could be running
    $scope.test = {};
    $scope.canRunTests = false;
    $scope.panelConnected = UserService.panelConnected;
    $scope.testSelected = undefined;
    $scope.haveWirelessZones = false;
    $scope.haveSensors = false;
    $scope.haveZwave = false;
    $scope.pirWalkTestInitiated = false;

    $scope.page = {};
    $scope.page.showNetworkTest = false;
    $scope.page.showCellTest = false;
    $scope.panelSupportsPIRTest = false;

    var panelId = $scope.controlSystem.panels[0].id;
    var dealerService;

    function getPanelSupportsPirTest() {
      PanelCapabilitiesService.supportsPirWalkTest(
        UserService.controlSystem.panels[0]
      ).then(function (data) {
        $scope.panelSupportsPIRTest = data;
        if ($scope.panelSupportsPIRTest) {
          $scope.test.pirTest = $scope.getPanelTest(
            panelId,
            PANEL_TEST_TYPES.PIR_WALK_TEST,
            true
          );
          $scope.test.pirTest.testIcon = "icon-central_station_communication";
          $scope.test.pirTest.testTitle = "Wireless PIR Test";
          $scope.test.pirTest.testContent =
            "app/system-tests/test-partials/pir-test.html";
        }
      });
    }
    /**
     * Prepare the page for use
     */
    function init() {
      dealerService = new DealerService({
        dealer_id: UserService.dealerInfo.id,
      });
      $scope.canRunTests =
        UserService.canRunPanelTests() && $scope.panelConnected;
      if ($scope.canRunTests) {
        initTests();
      }
      getPanelSupportsPirTest();
      const { panel_model } = $scope.Panel;
      $scope.hardware_family = ["XR550", "XR350", "XR150", "XT75"].includes(
        panel_model
      )
        ? "XR550"
        : panel_model === "XF6_500"
        ? "XF6_500"
        : panel_model === "XF6_100"
        ? "XF6_100"
        : "XT30";

      getComInfo();
      if (
        ![
          "CellComSL",
          "CellComEX",
          "DualCom",
          "MiniCellCom",
          "iComSL",
          "iComLNC",
        ].includes(UserService.controlSystem.panels[0].hardware_model)
      ) {
        loadZones();
      }
      if ($scope.zwaveEnabled) {
        getZWaveDevices().then(function (data) {
          // Loop through all of the Z-Wave devices, adding any that don't exist in the panel test devices list
          loadDevices(data);
          // $scope.hideBusySpinner();
        });
      }
      $scope.setTest($scope.whatTestIsRunning());
    }

    $scope.setTest = function (testPicked) {
      let activeTest = undefined;
      for (let testType in $scope.test) {
        if ($scope.test[testType] && $scope.test[testType].active) {
          activeTest = $scope.test[testType].testType;
        }
      }
      testPicked
        ? ($scope.testSelected = testPicked)
        : ($scope.testSelected = activeTest);
    };

    /**
     * Returns the minimum firmware version required to run panel tests for the current panel
     * @returns {string}
     */
    $scope.getMinFirmwareVersion = function () {
      var activePanelFamily = $scope.hardware_family;
      switch (activePanelFamily) {
        case "XT30":
          return PANEL_TEST_VERSIONS.XT_MIN_VERSION;
        case "XR550":
          return PANEL_TEST_VERSIONS.XR_MIN_VERSION;
        case "XF6_100":
        case "XF6_500":
          return PANEL_TEST_VERSIONS.XF6_MIN_VERSION;
        default:
          return "125";
      }
    };

    $scope.versionSupportsTests = function () {
      let currentPanelVersion = $scope.Panel.panel_version;
      let minTestVersion = $scope.getMinFirmwareVersion();
      return currentPanelVersion >= minTestVersion;
    };

    $scope.disableTab = function (testType) {
      const canViewCommTest =
        testType === PANEL_TEST_TYPES.COMM_TEST &&
        !($scope.page.showNetworkTest || $scope.page.showCellTest);
      const canViewWalkTest =
        testType === PANEL_TEST_TYPES.SENSOR_TEST && !haveSensors();
      const canViewWirelessTest =
        testType === PANEL_TEST_TYPES.WIRELESS_TEST && !haveWirelessZones();
      const canViewZwave =
        (testType === PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST ||
          testType === PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION) &&
        !haveZwave();
      const canViewWirelessPirTest =
        testType === PANEL_TEST_TYPES.PIR_WALK_TEST && !haveWirelessZones();

      if (
        canViewCommTest ||
        canViewWalkTest ||
        canViewWirelessPirTest ||
        canViewWirelessTest ||
        canViewZwave
      ) {
        return true;
      } else {
        return false;
      }
    };

    function initTests() {
      // Get the Z-Wave enabled state
      $scope.zwaveEnabled =
        $scope.controlSystem.services_manager.zwave_node_edit_enabled;

      // Go out and check for any tests that are running
      $scope.test.commTest = $scope.getPanelTest(
        panelId,
        PANEL_TEST_TYPES.COMM_TEST,
        true
      );
      $scope.test.commTest.testIcon = "icon-central_station_communication";
      $scope.test.commTest.testTitle = "Communication Test";
      $scope.test.commTest.testContent =
        "app/system-tests/test-partials/communication-test.html";

      $scope.test.sensorTest = $scope.getPanelTest(
        panelId,
        PANEL_TEST_TYPES.SENSOR_TEST,
        true
      );
      $scope.test.sensorTest.testIcon = "icon-sensors_1106";
      $scope.test.sensorTest.testTitle = "Walk Test";
      $scope.test.sensorTest.testContent =
        "app/system-tests/test-partials/sensor-test.html";

      if (
        ![
          "CellComSL",
          "CellComEX",
          "DualCom",
          "MiniCellCom",
          "iComSL",
          "iComLNC",
          "TMS6",
        ].includes(UserService?.controlSystem?.panels?.[0].hardware_model)
      ) {
        const panel = UserService?.controlSystem?.panels?.[0];
        $scope.test.wirelessTest = $scope.getPanelTest(
          panelId,
          PANEL_TEST_TYPES.WIRELESS_TEST,
          true
        );
        $scope.test.wirelessTest.testIcon = "icon-wireless";
        $scope.test.wirelessTest.testTitle = "Wireless Test";
        $scope.test.wirelessTest.testContent =
          "app/system-tests/test-partials/wireless-test.html";

        $scope.test.wirelessTest.showLongWaitMessage = matchesModelPrefixes([
          "XR",
          "XT",
        ])(panel);
      }
      if ($scope.zwaveEnabled) {
        $scope.test.zWaveDiagnosticsTest = $scope.getPanelTest(
          panelId,
          PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST,
          true
        );
        $scope.test.zWaveDiagnosticsTest.testIcon = "icon-zwave";
        $scope.test.zWaveDiagnosticsTest.testTitle = "Z-Wave Test";
        $scope.test.zWaveDiagnosticsTest.testContent =
          "app/system-tests/test-partials/zwave-test.html";
        $scope.test.zWaveDeviceOptimization = $scope.getPanelTest(
          panelId,
          PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION,
          true
        );
        $scope.test.zWaveDeviceOptimization.testIcon = "icon-zwave";
        $scope.test.zWaveDeviceOptimization.testTitle = "Z-Wave Optimization";
        $scope.test.zWaveDeviceOptimization.testContent =
          "app/system-tests/test-partials/zwave-optimization.html";
      }
      if ($scope.panelSupportsPIRTest) {
        $scope.test.pirTest = $scope.getPanelTest(
          panelId,
          PANEL_TEST_TYPES.PIR_WALK_TEST,
          true
        );
        $scope.test.pirTest.testIcon = "icon-central_station_communication";
        $scope.test.pirTest.testTitle = "PIR Test";
        $scope.test.pirTest.testContent =
          "app/system-tests/test-partials/pir-test.html";
      }
    }

    function getComInfo() {
      if (
        $scope.hardware_family === "XR550" ||
        $scope.hardware_family === "XF6_500" ||
        $scope.hardware_family === "XF6_100"
      ) {
        var communicationTypes = [];
        angular.forEach($scope.Panel.communication_paths, function (path) {
          communicationTypes.push(path.comm_type);
        });
        $scope.page.showNetworkTest =
          communicationTypes.includes(XR_PANEL_COMM_TYPE.NETWORK) ||
          communicationTypes.includes(XR_PANEL_COMM_TYPE.WIFI);
        $scope.page.showCellTest = communicationTypes.includes(
          XR_PANEL_COMM_TYPE.CELL
        );
      } else {
        var commType = $scope.Panel.communication.com_type;
        var backupCell = $scope.Panel.communication.back_cell;
        // Check to see what communication paths are selected for the panel
        $scope.page.showNetworkTest =
          commType === PANEL_COMM_TYPE.NETWORK ||
          commType === PANEL_COMM_TYPE.WIFI;
        $scope.page.showCellTest =
          commType === PANEL_COMM_TYPE.CELL || backupCell === "Y";
      }
    }

    /**
     * Loop through all panel zones creating any zones that don't exist in the panel test list
     * @param {boolean}
     */
    function loadZones() {
      // Loop through all of the panel zone_informations looking for zones that are missing from the test zones list
      angular.forEach($scope.Panel.zone_informations, function (zone) {
        var sensorResults = $filter("filter")(
          $scope.test.sensorTest.zones,
          { number: zone.number },
          true
        );
        var wirelessResults = $scope.test.wirelessTest
          ? $filter("filter")(
              $scope.test.wirelessTest.zones,
              { number: zone.number },
              true
            )
          : [];

        // If we didn't find a matching zone in our test zones list, add it
        // Only add to wirelessTest if the zone is wireless
        var sensor = angular.copy(zone);
        if (sensorResults.length == 0) {
          sensor.checkedIn = false;
          $scope.test.sensorTest.zones.push(angular.copy(sensor));
        }
        if (wirelessResults.length == 0) {
          if (sensor.wireless == "Y") {
            $scope.test.wirelessTest.zones.push(angular.copy(sensor));
          }
        }
      });
    }

    function haveSensors() {
      return $scope.test.sensorTest?.zones?.length;
    }

    function haveWirelessZones() {
      return $scope.test.wirelessTest?.zones?.length;
    }

    function haveZwave() {
      return $scope.test.zWaveDiagnosticsTest?.devices?.length;
    }
    /**
     * Load a list of all Z-Wave devices from the panel
     */
    function getZWaveDevices() {
      var deferred = $q.defer();

      ZWaveService.getDevices(panelId).then(
        function (data) {
          var zwaveList = [];
          angular.forEach(data, function (zwaveDevice) {
            zwaveList.push(zwaveDevice.zwave_setup);
          });
          deferred.resolve(zwaveList);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error getting Z-Wave device list",
            json: error,
          });
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Called when the state of a zwave checkbox changes
     */
    $scope.checkStateChanged = function () {
      // Only allow 10 Z-Wave devices to be selected at a time
      var checkedCount = $filter("filter")(
        $scope.test.zWaveDiagnosticsTest.devices,
        { testing: true }
      ).length;
      checkedCount >= 10
        ? ($scope.maxSelected = true)
        : ($scope.maxSelected = false);
    };

    /**
     * Loop through all Z-Wave devices creating any devices that don't exist in the panel test list
     */
    function loadDevices(zWaveDevices) {
      // Loop through all of the panel Z-Wave devices looking for devices that are missing from the test devices list
      angular.forEach(zWaveDevices, function (device) {
        var results = $filter("filter")(
          $scope.test.zWaveDiagnosticsTest.devices,
          { number: device.number },
          true
        );

        // If we didn't find a matching device in our test devices list, add it
        if (results.length == 0) {
          var zWaveDevice = angular.copy(device);
          zWaveDevice.status = "";
          zWaveDevice.requests = 0;
          zWaveDevice.replies = 0;
          zWaveDevice.isNew = true;
          $scope.test.zWaveDiagnosticsTest.devices.push(zWaveDevice);
          $scope.test.zWaveDeviceOptimization.devices.push(zWaveDevice);
        }
      });

      // Loop through all of the devices in the Z-Wave test and Optimize and remove any that are no longer in the panel
      var zWaveList = angular.copy($scope.test.zWaveDiagnosticsTest.devices); // Create a temp copy of the device list to iterate through
      angular.forEach(zWaveList, function (device) {
        var results = $filter("filter")(
          zWaveDevices,
          { number: device.number },
          true
        );

        // If we don't find a matching device, remove the device from the test list
        if (results.length == 0) {
          $scope.test.zWaveDiagnosticsTest.devices.splice(device, 1);
        }
      });

      var zWaveOptimizeList = angular.copy(
        $scope.test.zWaveDeviceOptimization.devices
      ); // Create a temp copy of the device list to iterate through
      angular.forEach(zWaveOptimizeList, function (device) {
        var results = $filter("filter")(
          zWaveDevices,
          { number: device.number },
          true
        );

        // If we don't find a matching device, remove the device from the test list
        if (results.length == 0) {
          $scope.test.zWaveDeviceOptimization.devices.splice(device, 1);
        }
      });
    }

    $scope.isTestSelected = function (testType) {
      return testType === $scope.testSelected;
    };

    /**
     * Begin the comm test
     */
    $scope.startTest = function () {
      switch ($scope.testSelected) {
        case PANEL_TEST_TYPES.COMM_TEST:
          var commTestPath = currentCommTestType();

          $scope.test.commTest.startCommTest(commTestPath, true).then(
            function (data) {
              // Successfully started test
              //$rootScope.hideBusySpinner();
            },
            function (error) {
              // Error starting test
              //$rootScope.hideBusySpinner();
              $rootScope.alerts.push({
                type: "error",
                text: "Error starting communication test.",
                json: error,
              });
            }
          );
          break;

        case PANEL_TEST_TYPES.SENSOR_TEST:
          //$scope.showBusySpinner("Starting walk test...", true, $scope, 'cancelTest()');
          $scope.test.sensorTest
            .startWalkTest(WALK_TEST_TYPE.STANDARD, true)
            .then(
              function (data) {
                // Successfully started test
                //  $rootScope.hideBusySpinner();
              },
              function (error) {
                // Error starting test
                //$rootScope.hideBusySpinner();
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error starting walk test.",
                  json: error,
                });
              }
            );
          break;

        case PANEL_TEST_TYPES.WIRELESS_TEST:
          //$scope.showBusySpinner("Starting walk test...", true, $scope, 'cancelTest()');
          $scope.test.wirelessTest
            .startWalkTest(WALK_TEST_TYPE.WIRELESS, true)
            .then(
              function (data) {
                // Successfully started test
                //  $rootScope.hideBusySpinner();
              },
              function (error) {
                // Error starting test
                //$rootScope.hideBusySpinner();
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error starting walk test.",
                  json: error,
                });
              }
            );
          break;

        case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
          //$scope.showBusySpinner("Starting Z-Wave Test...", true, $scope, 'cancelTest()');
          $scope.test.zWaveDiagnosticsTest.startZWaveTest(true).then(
            function (data) {
              // Successfully started test
              //$rootScope.hideBusySpinner();
            },
            function (error) {
              // Error starting test
              //$rootScope.hideBusySpinner();
              $rootScope.alerts.push({
                type: "error",
                text: "Error starting Z-Wave test.",
                json: error,
              });
            }
          );
          break;
        case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
          //$scope.showBusySpinner("Starting Z-Wave optimization...", true, $scope, 'cancelTest()');
          $scope.test.zWaveDeviceOptimization.startZWaveOptimize(true).then(
            function (data) {
              // Successfully started optimization
              //$rootScope.hideBusySpinner();
            },
            function (error) {
              // Error starting test
              //$rootScope.hideBusySpinner();
              //$rootScope.alerts.push({ "type": "error", "text": "Error starting Z-Wave optimization.", "json": error });
            }
          );
          break;
        case PANEL_TEST_TYPES.PIR_WALK_TEST:
          $scope.pirWalkTestInitiated = true;

          $scope.test.pirTest
            .startWalkTest(WALK_TEST_TYPE.PIR, false, false)
            .then(
              // we don't want to ask for job status on this call
              function (data) {
                // Successfully started optimization
                //$rootScope.hideBusySpinner();
              },
              function (error) {
                // Error starting test
                //$rootScope.hideBusySpinner();
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error starting Wireless PIR Walk Test.",
                });
                $scope.pirWalkTestInitiated = false;
              }
            );
          break;
        default:
          break;
      }
    };

    /**
     * Ends currently selected test for the panel
     */
    $scope.endTest = function () {
      //$scope.showBusySpinner("Ending communication test...", true, $scope, 'cancelTest()');
      for (let test in $scope.test) {
        if ($scope.test[test].testType == $scope.testSelected) {
          if (test == PANEL_TEST_TYPES.COMM_TEST) {
            var commTestPath = currentCommTestType();
            $scope.test.commTest.endTest(commTestPath).then(
              function (data) {
                //$rootScope.hideBusySpinner();
              },
              function (error) {
                //$rootScope.hideBusySpinner();
              }
            );
          } else if (test == "sensorTest") {
            $scope.test[test].endTest(false, "S").then(
              function (data) {
                //$rootScope.hideBusySpinner();
              },
              function (error) {
                //$rootScope.hideBusySpinner();
              }
            );
          } else if (test == "wirelessTest") {
            $scope.test[test].endTest(false, "W").then(
              function (data) {
                //$rootScope.hideBusySpinner();
              },
              function (error) {
                //$rootScope.hideBusySpinner();
              }
            );
          } else {
            $scope.test[test].endTest().then(
              function (data) {
                //$rootScope.hideBusySpinner();
              },
              function (error) {
                console.error(
                  "error stopping" + test + " " + angular.toJson(error)
                );
                //$rootScope.hideBusySpinner();
              }
            );
          }
        }
      }
    };

    /**
     * Cancel selected running test
     */
    $scope.cancelTest = function () {
      for (let test in $scope.test) {
        if ($scope.test[test].testType == $scope.testSelected) {
          if ($scope.test[test].status == PANEL_TEST_STATUSES.RUNNING) {
            $scope.test[test].status = PANEL_TEST_STATUSES.CANCELLED;
            $scope.test[test].endTest();
          }
        }
      }
    };

    function currentCommTestType() {
      // Convert user input into path_no_tt value for sending to the api
      if (
        $scope.hardware_family === "XT30" &&
        $scope.test.commTest.commPaths[0].testing &&
        $scope.test.commTest.commPaths[1].testing
      ) {
        return COMM_TEST_TYPES.All;
      } else {
        return $scope.test.commTest.commPaths[0].testing
          ? COMM_TEST_TYPES.Network
          : COMM_TEST_TYPES.Cellular;
      }
    }

    $scope.whatTestIsRunning = function () {
      testRunning = false;
      for (let test in $scope.test) {
        if (DoesNestedPropertyExist($scope.test[test], "status")) {
          if ($scope.test[test].status == PANEL_TEST_STATUSES.RUNNING) {
            testRunning = $scope.test[test].testType;
          }
        }
      }
      return testRunning;
    };

    /**
     * Determines if the current test is running
     * @returns {boolean}
     */
    $scope.testRunning = function () {
      for (let test in $scope.test) {
        if ($scope.test[test].testType == $scope.testSelected) {
          return $scope.test[test].status == PANEL_TEST_STATUSES.RUNNING;
        }
      }
    };

    /**
     * Determines if the current test is stopped
     * @returns {boolean}
     */
    $scope.testStopped = function () {
      for (let test in $scope.test) {
        if ($scope.test[test].testType == $scope.testSelected) {
          return $scope.test[test].status == PANEL_TEST_STATUSES.STOPPED;
        }
      }
    };

    /**
     * Determines if the specified communication is currently being tested
     * @param testData
     * @returns {boolean}
     * @constructor
     */
    $scope.testInProgress = function (testData) {
      switch ($scope.testSelected) {
        case PANEL_TEST_TYPES.COMM_TEST:
          let commPath = testData;
          if (commPath.connectionType == "N") {
            return (
              commPath.testing && commPath.status < 4 && $scope.testRunning()
            );
          } else {
            return (
              commPath.testing && commPath.status < 6 && $scope.testRunning()
            );
          }

        case PANEL_TEST_TYPES.SENSOR_TEST:
          let sensor = testData;
          return sensor.checkedIn == false && $scope.testRunning();

        case PANEL_TEST_TYPES.WIRELESS_TEST:
          let wirelessSensor = testData;
          return wirelessSensor.checkedIn == false && $scope.testRunning();

        case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
          let device = testData;
          return device.testing && device.replies < 10 && $scope.testRunning();

        case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
          let zwaveDevice = testData;
          return zwaveDevice.status == "" && $scope.testRunning();
        default:
          return false;
      }
    };

    /**
     * Determines if the specified communication passed the test
     * @param testData
     * @returns {boolean}
     * @constructor
     */
    $scope.testPassed = function (testData) {
      switch ($scope.testSelected) {
        case PANEL_TEST_TYPES.COMM_TEST:
          let commPath = testData;
          if (commPath.connectionType == "N") {
            return !commPath.isNew && commPath.status == 4;
          } else {
            return !commPath.isNew && commPath.status == 6;
          }
        case PANEL_TEST_TYPES.SENSOR_TEST:
          let sensor = testData;
          return sensor.checkedIn == true;

        case PANEL_TEST_TYPES.WIRELESS_TEST:
          let wirelessSensor = testData;
          return wirelessSensor.checkedIn == true;

        case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
          let device = testData;
          return !device.isNew && device.replies == 10;

        case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
          let zwaveDevice = testData;
          return zwaveDevice.status == "S";
        default:
          return false;
      }
    };

    /**
     * Determines if the specified communication failed the test
     * @param testData
     * @returns {boolean}
     * @constructor
     */
    $scope.testFailed = function (testData) {
      switch ($scope.testSelected) {
        case PANEL_TEST_TYPES.COMM_TEST:
          let commPath = testData;
          if (commPath.connectionType == "N") {
            return !commPath.isNew && commPath.status > 4;
          } else {
            return !commPath.isNew && commPath.status > 6;
          }

        case PANEL_TEST_TYPES.SENSOR_TEST:
          let sensor = testData;
          return sensor.checkedIn == false;

        case PANEL_TEST_TYPES.WIRELESS_TEST:
          let wirelessSensor = testData;
          return wirelessSensor.checkedIn == false;

        case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
          let device = testData;
          return !device.isNew && device.requests > 0 && device.replies < 10;

        case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
          let zwaveDevice = testData;
          return zwaveDevice.status == "F";
        default:
          return false;
      }
    };

    $scope.$on("destroy", function () {
      cancelTest();
    });

    $scope.$on("all_concepts_retrieved", function () {
      init();
    });

    $scope.setupTest = function () {
      cancelTest();
      init();
    };
  },
]);
