import { curry } from "ramda";

export const addParamToUrl = curry(
  (key: string, value: string | number, url: string) => {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.set(key, String(value));
    return parsedUrl.toString();
  }
);

/**
 * Adds object entries to url, when the value is undefined the param is not added.
 */
export const addParamsToUrl = curry(
  (obj: { [key: string]: string | undefined }, url: string) =>
    Object.entries(obj).reduce(
      (currentUrl, [key, value]) =>
        value === undefined || value === ""
          ? currentUrl
          : addParamToUrl(key, value, currentUrl),
      url
    )
);

export const urlIsValid = (link: string) =>
  link.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  );
