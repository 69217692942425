import * as React from "react";

export default class GenericErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback: React.ReactNode }>,
  {
    hasError: boolean;
  }
> {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? this.props.fallback : this.props.children;
  }
}
