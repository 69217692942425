App.factory("ControlSystemNotesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/ControlSystemNotes/:noteId/:controlSystemRoute/:controlSystemId/:newRoute",
      {},
      {
        getNotes: {
          method: "GET",
          params: { controlSystemRoute: "ControlSystems" },
          isArray: true,
        },
        getNote: { method: "GET" },
        update: { method: "PUT" },
        create: { method: "POST" },
        destroy: { method: "DELETE" },
        getNewNoteTemplate: {
          method: "GET",
          params: {
            controlSystemRoute: "ControlSystems",
            newRoute: "NewNoteTemplate",
          },
        },
      }
    );
  },
]);
