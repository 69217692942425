/**
 * @generated SignedSource<<50e33aecfc36ff066aa5eed6f23b68f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommunicationPathAlarmReports = "FIRE" | "NO" | "YES";
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathDayOfWeek = "FRIDAY" | "MONDAY" | "NONE" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY";
export type CommunicationPathDoorAccessReports = "DENY" | "NO" | "YES";
export type CommunicationPathEncryptionScheme = "_128_BIT" | "_256_BIT";
export type CommunicationPathProtocol = "TCP" | "UDP";
export type CommunicationPathSubstitutionCode = "NO" | "SHARED" | "YES";
export type CommunicationPathSupervisoryTroubleReports = "FIRE" | "NO" | "YES";
export type CommunicationPathTestFrequencyUnit = "DAYS" | "HOURS";
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XRCommunicationProgrammingConceptFormInline_communicationPath$data = {
  readonly __typename: "CommunicationPath";
  readonly accountNumber: string | null;
  readonly alarmReports: CommunicationPathAlarmReports;
  readonly alarmSwitchover: number;
  readonly alarmSwitchoverMax: number;
  readonly alarmSwitchoverMin: number;
  readonly apn: string;
  readonly apn2: string;
  readonly checkInMinutes: number;
  readonly checkInMinutesMax: number;
  readonly checkInMinutesMin: number;
  readonly commType: CommunicationPathCommType;
  readonly doorAccessReports: CommunicationPathDoorAccessReports;
  readonly duplicateAlarms: boolean;
  readonly encryptionEnabled: boolean;
  readonly encryptionScheme: CommunicationPathEncryptionScheme;
  readonly failTestHours: number;
  readonly failTime: number;
  readonly failTimeMax: number;
  readonly failTimeMin: number;
  readonly firstPhone: string;
  readonly id: string;
  readonly isNew: boolean;
  readonly number: number;
  readonly openCloseUserReports: boolean;
  readonly panicTest: boolean;
  readonly protocol: CommunicationPathProtocol;
  readonly receiverPort: string;
  readonly remoteIP: string;
  readonly remoteIPv6: string;
  readonly retryTime: number;
  readonly retryTimeMax: number;
  readonly retryTimeMin: number;
  readonly secondLinePrefixFor893A: string;
  readonly secondPhone: string;
  readonly sendFail: boolean;
  readonly sendPath: boolean;
  readonly substitutionCode: CommunicationPathSubstitutionCode;
  readonly supervisoryTroubleReports: CommunicationPathSupervisoryTroubleReports;
  readonly testDayOfWeek: CommunicationPathDayOfWeek;
  readonly testFrequencyNumber: number;
  readonly testFrequencyNumberMax: number;
  readonly testFrequencyNumberMin: number;
  readonly testFrequencyUnit: CommunicationPathTestFrequencyUnit;
  readonly testReport: CommunicationPathTestReport;
  readonly testTime: string;
  readonly transmissionDelay: number;
  readonly transmissionDelayValidValues: ReadonlyArray<number>;
  readonly type: CommunicationPathType;
  readonly use893A: boolean;
  readonly useCheckIn: CommunicationPathUseCheckIn;
  readonly useIPv6: boolean;
  readonly " $fragmentType": "XRCommunicationProgrammingConceptFormInline_communicationPath";
};
export type XRCommunicationProgrammingConceptFormInline_communicationPath$key = {
  readonly " $data"?: XRCommunicationProgrammingConceptFormInline_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCommunicationProgrammingConceptFormInline_communicationPath">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRCommunicationProgrammingConceptFormInline_communicationPath"
};

(node as any).hash = "ffe0dc4415151f77a55f7ddbb1fc3a0f";

export default node;
