/**
 * @generated SignedSource<<71be77578c741c7b36df93bea78efeab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VideoDeviceStatus = "ACTIVATING" | "ACTIVE" | "ERROR" | "INACCESSIBLE";
import { FragmentRefs } from "relay-runtime";
export type SecureComCameras_secureComCamera$data = {
  readonly channelId: number;
  readonly id: string;
  readonly isOnline: boolean;
  readonly manufacturer: string;
  readonly name: string;
  readonly status: VideoDeviceStatus;
  readonly " $fragmentType": "SecureComCameras_secureComCamera";
};
export type SecureComCameras_secureComCamera$key = {
  readonly " $data"?: SecureComCameras_secureComCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComCameras_secureComCamera">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecureComCameras_secureComCamera",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "channelId",
      "storageKey": null
    }
  ],
  "type": "SecureComCamera",
  "abstractKey": null
};

(node as any).hash = "6662e9093d1dd09a8c0aa4d09a5659e6";

export default node;
