import graphql from "babel-plugin-relay/macro";
import { GenericConfirmModal } from "components/Modal/ConfirmModal";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-relay";
import { TestConnectionModalTestConnectionMutation } from "./__generated__/TestConnectionModalTestConnectionMutation.graphql";

interface TestConnectionModalProps {
  cameraId: string;
  onCancel: () => void;
}
function TestConnectionModal(props: TestConnectionModalProps) {
  const { cameraId, onCancel } = props;
  const [test, isTesting] =
    useMutation<TestConnectionModalTestConnectionMutation>(
      testConnectionMutation
    );
  const [isOnline, setIsOnline] = useState(false);
  const textStyle = isOnline ? "text-success" : "text-danger";
  const icon = isOnline ? "icon-checkmark" : "icon-radial_alert";
  const text = isOnline ? "Online" : "Offline";

  useEffect(() => {
    test({
      variables: {
        id: cameraId,
      },
      onCompleted: (result) => {
        setIsOnline(
          result.testCameraConnection.__typename === "SecureComCamera" && result.testCameraConnection.isOnline
        );
      },
    });
  }, []);

  return (
    <GenericConfirmModal
      header="Connection Test"
      cancelText="Close"
      onCancel={onCancel}
      suppressConfirm={true}
    >
      {isTesting ? (
        <span className="font-sz-16">Testing your connection...</span>
      ) : (
        <span className={textStyle}>
          <i className={icon}></i>
          <span className="font-sz-16">&ensp;{text}</span>
        </span>
      )}
    </GenericConfirmModal>
  );
}

export default TestConnectionModal;

const testConnectionMutation = graphql`
  mutation TestConnectionModalTestConnectionMutation($id: ID!) {
    testCameraConnection(id: $id) {
      __typename
      ... on UnauthorizedError {
        errorType: type
      }
      ... on NotFoundError {
        errorType: type
      }
      ... on CameraOfflineError {
        errorType: type
      }
      ... on SecureComCamera {
        __typename
        id
        isOnline
      }
    }
  }
`;
