/**
 * @generated SignedSource<<637b016e05461f824b0694e7069a49d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly requireSiteCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode2: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode3: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode4: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode5: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode6: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode7: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCode8: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCodeLength: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly siteCodePosition: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly userCodeDigits: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly userCodeLength: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly userCodePosition: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly wiegandLength: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "d305fd9bf41e261e00eba6fe776e5f53";

export default node;
