/**
 * @ngdoc service
 * @name App.factory:LicenseManagementV2API
 *
 * @description
 * API factory that returns SIM Activation Licenses for a given dealer
 *
 */
App.factory("LicenseManagementV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.simsUrl + "/licenses/find_by_dealer_code/:dealer_code",
      {
        username: "api", //hard coded user id
        password: "MsovCQ5IHN0CmBArye1z", //hard coded password
        json: 1, //required flag to instruct the API to return a JSON structure (instead of xml)
      },
      {
        find_by_dealer_code: {
          method: "GET",
          isArray: true,
          params: {},
          headers: { Accept: "application/json" },
        }, //if (*/*) header is passed to the GET, the call will fail
      }
    );
  },
]);
