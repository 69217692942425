import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsBypassReportsField_systemReports$key } from "./__generated__/SystemReportsBypassReportsField_systemReports.graphql";

export const systemReportsBypassReportsFieldId = () =>
  "system-reports-bypass-reports";

function SystemReportsBypassReportsField() {
  const [{ bypassReports }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsBypassReportsField_systemReports$key>(
      graphql`
        fragment SystemReportsBypassReportsField_systemReports on SystemReports {
          bypassReports
        }
      `
    );

  const fieldId = systemReportsBypassReportsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Bypass Reports"
      tooltip="If enabled in Communication Programming, send messages when a Zone is Bypassed, Reset, or Force Armed."
    >
      <Switch
        label="Bypass Reports"
        id={fieldId}
        name={fieldId}
        checked={bypassReports}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(!bypassReports, "bypassReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsBypassReportsField;
