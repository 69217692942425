import { WebRtcPlayer } from "@iensoink/react-client-sdk";
import { generateIensoLiveViewSession } from "dealer-admin/src/apis/camect-api";
import { IensoLivestream } from "dealer-admin/src/apis/camect-api/ienso-livestream-parser";
import AuthContext from "dealer-admin/src/contexts/AuthContext";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import VideoLoadingOverlay from "./VideoLoadingOverlay";

type WebRtcPlayer = {
  getVideoPlayer: () => HTMLVideoElement;
  setAutoPlay: () => void;
  play: () => void;
  stop: () => void;
  setAutoConnect: () => void;
  sendToggle: () => boolean;
  sendPlay: () => boolean;
  sendStop: () => boolean;
};

const Wrapper = styled.div`
  position: relative;
  & .ienso-webrtc-player,
  & .ienso-webrtc-player__video-player {
    height: 100%;
    width: 100%;
    margin: 0;
  }
`;

type Props = {
  deviceId: string;
  width: number | undefined;
  height: number;
};

const IensoPlayer = forwardRef(function IensoPlayer(
  { deviceId, width, height }: Props,
  ref
) {
  const { authToken, controlSystemId, tokenType } = useContext(AuthContext);
  const [signalingUrl, setSignalingUrl] = useState("");
  const [signalingToken, setSignalingToken] = useState("");
  const [webrtcConfiguration, setWebrtcConfigration] = useState({});
  const webrtcPlayer = useRef<WebRtcPlayer>(null);
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);

  useImperativeHandle(ref, () => webrtcPlayer.current?.getVideoPlayer(), [
    webrtcPlayer,
    isPlayerLoaded,
  ]);

  const formatConfig = (iensoLivestream: IensoLivestream) => {
    return {
      iceServers: [
        {
          urls: iensoLivestream.turnUrls,
          username: iensoLivestream.username,
          credential: iensoLivestream.password,
        },
        { urls: iensoLivestream.stunUrl },
      ],
      iceCandidatePoolSize: 0,
    };
  };

  useEffect(() => {
    let ignore = false;

    const connect = async () => {
      const forMonitoring = tokenType === "monitoring";
      const data = await generateIensoLiveViewSession(
        authToken,
        controlSystemId,
        deviceId,
        forMonitoring
      );
      if (!ignore) {
        setSignalingToken(data.sessionToken);
        setSignalingUrl(data.signalingUrl);
        setWebrtcConfigration(formatConfig(data));
      }
    };

    connect();

    return () => {
      ignore = true;
    };
  }, [authToken, controlSystemId, deviceId]);

  const forceVideoToPlay = () => {
    if (webrtcPlayer.current) {
      const video = webrtcPlayer.current.getVideoPlayer();
      // If currentTime is zero, the video isn't playing yet
      if (!video.currentTime) {
        video.muted = true; // Mute to avoid autoPlay blocking
        video.play();
      }
    }
  };

  return (
    <Wrapper style={{ width, height }}>
      {!isPlayerLoaded && <VideoLoadingOverlay />}
      {signalingToken && signalingUrl && (
        <WebRtcPlayer
          autoPlay
          showVideoPlayer
          ref={webrtcPlayer}
          signalingUrl={signalingUrl}
          signalingToken={signalingToken}
          webrtcConfiguration={webrtcConfiguration}
          onWebrtcConnectionStateChange={(_: string, webrtcStatus: string) => {
            if (webrtcStatus === "streaming") {
              setIsPlayerLoaded(true);
              forceVideoToPlay();
            }
          }}
        />
      )}
    </Wrapper>
  );
});

export default IensoPlayer;
