import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { SendLocalChangesType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsSendLocalChangesField_remoteOptions$key } from "./__generated__/RemoteOptionsSendLocalChangesField_remoteOptions.graphql";

export const remoteOptionsSendLocalChangesFieldId = () =>
  "remote-options-send-local-changes";

function RemoteOptionsSendLocalChangesField() {
  const [{ sendLocalChanges }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsSendLocalChangesField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsSendLocalChangesField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            sendLocalChanges
          }
        }
      `
    );

  const fieldId = remoteOptionsSendLocalChangesFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Send Local Changes"
      tooltip="Enable to allows the System to automatically update Remote Link at the Central Station with any programming changes made."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={sendLocalChanges ?? undefined}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "sendLocalChanges");
          });
        }}
      >
        <Select.Option value={SendLocalChangesType.NONE}>None</Select.Option>
        <Select.Option value={SendLocalChangesType.NETWORK}>Net</Select.Option>
        <Select.Option value={SendLocalChangesType.DIGITAL_DIALER}>
          Digital Dialer
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsSendLocalChangesField;
