/**
 *
 * NightIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const NightIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M24.434,64c-5.394,0-10.731-1.353-15.438-3.912c-0.793-0.432-1.198-1.339-0.991-2.217
        s0.977-1.508,1.878-1.539c11.251-0.388,20.064-10.998,20.064-24.156c0-13.271-9.238-24.114-20.594-24.172
        C8.448,8,7.66,7.389,7.43,6.514S7.584,4.719,8.369,4.27C13.243,1.477,18.799,0,24.434,0C42.19,0,56.637,14.355,56.637,32
        C56.637,49.646,42.19,64,24.434,64z M16.907,58.986C19.349,59.655,21.884,60,24.434,60c15.551,0,28.203-12.561,28.203-28
        S39.984,4,24.434,4c-2.755,0-5.487,0.402-8.106,1.183c10.175,3.483,17.62,14.283,17.62,26.993
        C33.947,44.756,26.866,55.342,16.907,58.986z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default NightIcon;
