/**
 * Created by kweigand on 5/27/15.
 */
App.controller("SchedulesXTFavoriteCtrl", [
  "$scope",
  "$filter",
  "$stateParams",
  "xtFavoriteScheduleModel",
  "scheduleUtilService",
  "PanelProgArchiveService",
  function (
    $scope,
    $filter,
    $stateParams,
    xtFavoriteScheduleModel,
    scheduleUtilService,
    PanelProgArchiveService
  ) {
    $scope.favorite_number = $stateParams.number;
    $scope.favorites = [];
    $scope.PanelProgArchiveService = PanelProgArchiveService;

    // Filters used to modify how the multi-panel list operates
    $scope.multiPanelFilters.push({
      field: "ITEM.software_version",
      operator: ">=",
      value: "100", // This is set dynamically on load and when twilight values change
      action: "disable",
      displayTemplate:
        "Panel system version is less than {{ multiPanelFilterData.value }}.",
    });
    $scope.multiPanelFilters.push({
      field: "ITEM.hardware_family",
      operator: "==",
      value: "XT30",
      action: "hide",
    });

    function initPage() {
      $scope.base.isBusy = true;

      // Only proceed if the favorite_schedules concept has been loaded. Otherwise, we're waiting for all_concepts_retrieved to fire
      if (
        angular.isDefined($scope.Panel["favorite_schedules"]) &&
        angular.isDefined($scope.Panel["favorites"])
      ) {
        // Are we creating a new schedule or editing an existing
        if ($scope.base.mode == "New") {
          // Create a new favorite schedule, and update the Schedule Service's currentSchedule property
          $scope.Panel.newItem("favorite_schedules")
            .then(function (scheduleData) {
              buildSchedule(scheduleData);
              $scope.base.currentSchedule.number = undefined;
            })
            .catch(function (error) {
              console.error(error);
            });

          // Get a list of all favorites that aren't assigned to a schedule
          angular.forEach($scope.Panel["favorites"], function (fav, key) {
            var favorite = $filter("filter")(
              $scope.Panel["favorite_schedules"],
              { number: fav.number },
              true
            )[0];
            if (!favorite) {
              $scope.favorites.push(fav);
            }
          });

          $scope.base.isBusy = false;
        } else {
          // Get the current favorite schedule, and set our parent's schedule property
          var scheduleData = $filter("filter")(
            $scope.Panel["favorite_schedules"],
            { number: $scope.favorite_number },
            true
          )[0];
          buildSchedule(scheduleData);
          // We need to pad the schedule number since VK will return just the number
          $scope.base.currentSchedule.number = $filter("zpad")(
            $scope.base.currentSchedule.number,
            3
          );

          var favorite = $filter("filter")(
            $scope.Panel["favorites"],
            { number: scheduleData.number },
            true
          )[0];
          $scope.favorites.push(favorite);
          $scope.base.isBusy = false;
        }
      }
    }

    function buildSchedule(scheduleData) {
      $scope.base.currentSchedule = angular.extend(
        scheduleData,
        new xtFavoriteScheduleModel()
      );
      $scope.base.currentScheduleType = "favorite_schedule";
      $scope.base.currentSchedule.initSchedule();
      scheduleUtilService.initTwilightData($scope.base.currentSchedule);
      $scope.updateMultiPanelFilters();
    }

    initPage();

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     */
    $scope.$on("all_concepts_retrieved", function () {
      // Loop through the favorites and add a left zero...painful.
      angular.forEach($scope.Panel.favorites, function (favorite) {
        favorite.number = $filter("zpad")(favorite.number, 3);
      });
      initPage();
    });
  },
]);
