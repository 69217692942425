import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";

import React, { useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import { VarHubPanelConnectionStatus } from "securecom-graphql/client";
import styled from "styled-components";
import DaColors from "../../app/common/DaColors";
import { PanelConnectionBadge_varHub$key } from "./__generated__/PanelConnectionBadge_varHub.graphql";
import { PanelConnectionBadge_varHubQuery } from "./__generated__/PanelConnectionBadge_varHubQuery.graphql";

const Root = styled.div<{ status: string | null }>`
  background-color: ${({ status }) => {
    switch (status) {
      case VarHubPanelConnectionStatus.PENDING:
        return "orange";
      case VarHubPanelConnectionStatus.CONNECTED:
        return "mediumseagreen";
      case VarHubPanelConnectionStatus.DISCONNECTED:
        return "red";
      case VarHubPanelConnectionStatus.PARTIAL:
        return DaColors.Caution500;
      default:
        return "gray";
    }
  }};
  height: 3rem;
  width: ${({ status }) =>
    status === VarHubPanelConnectionStatus.CONNECTED ? "12rem" : "18rem"};
  color: ${({ status }) =>
    status === VarHubPanelConnectionStatus.PARTIAL ? "black" : "white"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  font-weight: 700;
  font-size: 1.4rem;
`;

const getStatusText = (status: string | null) => {
  switch (status) {
    case VarHubPanelConnectionStatus.PENDING:
      return "Connection Pending...";
    case VarHubPanelConnectionStatus.CONNECTED:
      return "Connected";
    case VarHubPanelConnectionStatus.DISCONNECTED:
      return "Disconnected";
    case VarHubPanelConnectionStatus.PARTIAL:
      return "Partial Connection";
    default:
      return "Connection Unknown";
  }
};

const PanelConnectionBadge = ({
  varHub,
}: {
  varHub: PanelConnectionBadge_varHub$key;
}) => {
  const [data, refetch] = useRefetchableFragment<
    PanelConnectionBadge_varHubQuery,
    PanelConnectionBadge_varHub$key
  >(
    graphql`
      fragment PanelConnectionBadge_varHub on VarHub
      @refetchable(queryName: "PanelConnectionBadge_varHubQuery") {
        panelConnectionStatus
        isOnline
      }
    `,
    varHub
  );

  const { isOnline, panelConnectionStatus } = data;

  useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (
      panelConnectionStatus !== VarHubPanelConnectionStatus.CONNECTED &&
      panelConnectionStatus !== VarHubPanelConnectionStatus.PARTIAL &&
      isOnline
    ) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetch({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(
            setTimeout(
              reloadData,
              panelConnectionStatus === "PENDING" ? 2000 : 5000
            )
          );
        }, 2000)
      );
    }
    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [isOnline, panelConnectionStatus, refetch]);

  return (
    <Root status={panelConnectionStatus}>
      {panelConnectionStatus === VarHubPanelConnectionStatus.PENDING || (
        <Icon
          name={
            panelConnectionStatus === VarHubPanelConnectionStatus.CONNECTED
              ? "radial_check"
              : "radial_alert"
          }
          size="2.2rem"
          style={{ paddingRight: "7px" }}
        />
      )}

      {getStatusText(panelConnectionStatus)}
    </Root>
  );
};

export default PanelConnectionBadge;
