/**
 * @generated SignedSource<<6ce81f22606177adbf04f0fa47413450>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type VarHubPanelConnectionStatus = "CONNECTED" | "DISCONNECTED" | "PARTIAL" | "PENDING" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type PanelConnectionBadge_varHub$data = {
  readonly id: string;
  readonly isOnline: boolean;
  readonly panelConnectionStatus: VarHubPanelConnectionStatus;
  readonly " $fragmentType": "PanelConnectionBadge_varHub";
};
export type PanelConnectionBadge_varHub$key = {
  readonly " $data"?: PanelConnectionBadge_varHub$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelConnectionBadge_varHub">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./PanelConnectionBadge_varHubQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "PanelConnectionBadge_varHub",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "panelConnectionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "VarHub",
  "abstractKey": null
};

(node as any).hash = "8cb76bf4fd5a1a600ed296b0db214983";

export default node;
