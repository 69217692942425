import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsWeatherPostalCodeField_systemOptions$key } from "./__generated__/SystemOptionsWeatherPostalCodeField_systemOptions.graphql";

export const systemOptionsWeatherPostalCodeFieldId = () =>
  "system-options-weather-postal-code";

function WeatherPostalCodeField() {
  const [{ weatherPostalCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsWeatherPostalCodeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsWeatherPostalCodeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            weatherPostalCode
          }
          ... on XtSystemOptions {
            weatherPostalCode
          }
        }
      `
    );

  const fieldId = systemOptionsWeatherPostalCodeFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Weather Postal Code"
      tooltip="Enter Postal Code used to obtain weather forecast data."
    >
      <TextInput
        type="text"
        id={fieldId}
        name={fieldId}
        // validationMessage="10 character maximum."
        pattern="^[0-9A-Z#\-\*\.]{1,10}$"
        maxLength={10}
        value={weatherPostalCode}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              target.value.toUpperCase(),
              "weatherPostalCode"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default WeatherPostalCodeField;
