/**
 * @ngdoc api
 * @name App.factory:BillingAPI
 *
 * @description
 * API factory for Billing
 *
 */

App.factory("BillingAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.billingUrl + "/api/v1/account/:dealer_code/payment/details",
      {},
      {
        getAccountSummaryCached: {
          method: "GET",
          params: { dealer_id: "@dealer_code" },
          isArray: false,
        },
        getAccountSummaryForced: {
          method: "POST",
          params: { dealer_id: "@dealer_code" },
          isArray: false,
        },
      }
    );
  },
]);
