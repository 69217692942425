import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsEntryCheckinProtectionField_systemReports$key } from "./__generated__/SystemReportsEntryCheckinProtectionField_systemReports.graphql";

export const systemReportsEntryCheckinProtectionFieldId = () =>
  "system-reports-entry-checkin-protection";

function SystemReportsEntryCheckinProtectionField() {
  const [{ entryCheckinProtection }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsEntryCheckinProtectionField_systemReports$key>(
      graphql`
        fragment SystemReportsEntryCheckinProtectionField_systemReports on SystemReports {
          entryCheckinProtection
        }
      `
    );

  const fieldId = systemReportsEntryCheckinProtectionFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entry Check-In Protection"
      tooltip="Enable to send a check-in message to the Receiver when the Entry Delay starts. A follow-up check-in is sent on Disarm."
    >
      <Switch
        label="Entry Check-In Protection"
        id={fieldId}
        name={fieldId}
        checked={entryCheckinProtection}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(
              !entryCheckinProtection,
              "entryCheckinProtection"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsEntryCheckinProtectionField;
