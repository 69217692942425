/**
 *
 * PerimeterLargeIcon
 * @author Chad Watson
 *
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const PerimeterLargeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          d="M61.8,0H2.2C1.4,0,0.7,0.6,0.7,1.5v48.1c0,0.8,0.7,1.4,1.5,1.4h16.8v11.5c0,0.8,0.6,1.5,1.4,1.5c0,0,0,0,0,0h41.4
      c0.8,0,1.4-0.6,1.4-1.5l0,0V1.5C63.3,0.6,62.6,0,61.8,0C61.8,0,61.8,0,61.8,0z M60.4,61.1H21.8V49.6c0-0.8-0.6-1.5-1.4-1.5
      c0,0,0,0,0,0H3.6V2.9h56.8V61.1z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default PerimeterLargeIcon;
