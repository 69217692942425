/**
 * @ngdoc service
 * @name App.factory:IpCheckerApi
 *
 * @description
 * API factory for checking if an IP address is valid and in the dealer whitelist
 *
 */
App.factory("IpCheckerAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/SecureCom/Checklist/:dealer_id_ip_address_base64",
      {},
      {
        isIpValid: {
          method: "POST",
          params: { dealer_id_ip_address_base64: "@id" },
        },
      }
    );
  },
]);
