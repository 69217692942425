/**
 * @ngdoc service
 * @name App.factory:CustomersV2API
 *
 * @description
 * API factory for Customer Information
 *
 */
App.factory("CustomersV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/customers/:customer_id/:users/:control_systems",
      {},
      {
        show: { method: "GET", params: { customer_id: "@customer_id" } },
        update: { method: "PUT", params: { customer_id: "@customer_id" } },
        destroy: { method: "DELETE", params: { customer_id: "@customer_id" } },
        getUsers: {
          method: "GET",
          params: { customer_id: "@customer_id", users: "users" },
          isArray: true,
        },
        getControlSystems: {
          method: "GET",
          params: {
            customer_id: "@customer_id",
            control_systems: "control_systems",
          },
          isArray: true,
        },
      }
    );
  },
]);
