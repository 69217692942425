/**
 *
 * PerformantTransitionWrapper - only renders when not animating
 * @author Chad Watson
 *
 */

import React from "react";
import PropTypes from "prop-types";
import withAncestorAnimatingState from "./withAncestorAnimatingState";

class PerformantTransitionWrapper extends React.Component {
  static propTypes = {
    animating: PropTypes.bool,
    children: PropTypes.node,
    shouldAllowRenderDuringTransition: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.isFirstRenderOfAnimation = props.animating;
    this.childrenIsLazy = !props.children;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.animating && !this.props.animating) {
      this.isFirstRenderOfAnimation = true;
      this.childrenIsLazy = !nextProps.children;
    }
  }

  shouldComponentUpdate(nextProps) {
    const { shouldAllowRenderDuringTransition } = nextProps;
    const { children } = this.props;

    return (
      !nextProps.animating ||
      (!!shouldAllowRenderDuringTransition &&
        shouldAllowRenderDuringTransition()) ||
      this.isFirstRenderOfAnimation ||
      (this.childrenIsLazy && !children && !!nextProps.children)
    );
  }

  componentDidUpdate() {
    if (this.isFirstRenderOfAnimation) {
      this.isFirstRenderOfAnimation = false;
    }
  }

  render() {
    return this.props.children;
  }
}

export default withAncestorAnimatingState(PerformantTransitionWrapper);
