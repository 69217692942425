App.factory("CustomRolesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/dealers/:dealer_id/roles/:role_id/:action/:data_type/:data_id/:data_type_2/:data_id_2/:action_2/:auth_id",
      {},
      {
        new: { method: "GET", params: { role_id: "new" } },
        create: { method: "POST" },
        update: { method: "PUT" },
        destroy: { method: "DELETE" },
        getRoles: { method: "GET", isArray: true },
        getRole: { method: "GET" },
        getTimeZones: {
          method: "GET",
          params: { data_type: "timezones" },
          isArray: true,
        },
        getRoleOfUser: { method: "GET", params: { data_type: "users" } },
        getRoleAssignees: {
          method: "GET",
          params: { action: "list", data_type: "users" },
          isArray: true,
        },
        assignRoleToUsers: {
          method: "PUT",
          params: { action: "add", data_type: "users" },
        },
        removeRoleFromUsers: {
          method: "PUT",
          params: { action: "remove", data_type: "users" },
        },
        newTime: {
          method: "GET",
          params: { data_type: "times", data_id: "new" },
        },
        // getTime: {method: "GET", params: {data_type: "times"}}
        createTime: { method: "POST", params: { data_type: "times" } },
        updateTime: { method: "PUT", params: { data_type: "times" } },
        destroyTime: { method: "DELETE", params: { data_type: "times" } },
        claimSystem: {
          method: "PUT",
          params: {
            data_type: "customers",
            data_type_2: "panels",
            action_2: "claim",
          },
        },
        claimedSystems: {
          method: "GET",
          params: {
            data_type: "users",
            data_type_2: "panels",
            action_2: "claims",
          },
          isArray: true,
        },
      }
    );
  },
]);
