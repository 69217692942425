export const join =
  (separator = ",") =>
  (...items: string[] | string[][]) => {
    return items.flat(Infinity).join(separator);
  };

export const joinSpaced = join(" ");

export const getAuthFromJWT = (token: string) =>
  JSON.parse(Buffer.from(token.split(".")[1], "base64").toString("ascii"))
    .auth_token;
