import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsBypassLimitField_systemOptions$key } from "./__generated__/SystemOptionsBypassLimitField_systemOptions.graphql";

export const systemOptionsBypassLimitFieldId = () =>
  "system-options-bypass-limit";

function BypassLimitField() {
  const [{ bypassLimit, bypassLimitMin, bypassLimitMax }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsBypassLimitField_systemOptions$key>(
      graphql`
        fragment SystemOptionsBypassLimitField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            bypassLimit
            bypassLimitMin
            bypassLimitMax
          }
          ... on Xt75SystemOptions {
            bypassLimit
            bypassLimitMin
            bypassLimitMax
          }
        }
      `
    );

  const fieldId = systemOptionsBypassLimitFieldId();
  const originalValue = React.useRef(bypassLimit).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      tooltip="Set the maximum number of Zones that can be bypassed in any single Area when arming from a Keypad."
      label="Bypass Limit"
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={bypassLimit?.toString()}
        required
        min={bypassLimitMin}
        max={bypassLimitMax}
        inlineHelp={`${bypassLimitMin}–${bypassLimitMax}`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "bypassLimit"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : valueAsNumber === 0
                ? valueAsNumber
                : clamp(bypassLimitMin, bypassLimitMax, valueAsNumber);
            recordProxy.setValue(Number(value), "bypassLimit");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default BypassLimitField;
