/**
 * @generated SignedSource<<ae78b1954c77eb1769440683e688d0df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "0e16a20245c1832232f842b1bf41b754";

export default node;
