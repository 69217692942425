import ImageUploader from "components/ImageUploader/ImageUploader";
import { GenericConfirmModal } from "components/Modal/ConfirmModal";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useState } from "react";
import { ImageListType } from "react-images-uploading";
interface UploadImageModalProps {
  authToken: string;
  controlSystemId: string;
  imageApiUrl: string;
  onCancel: () => void;
  refreshSystemImages: () => void;
}
function UploadImageModal(props: UploadImageModalProps) {
  const {
    authToken,
    controlSystemId,
    imageApiUrl,
    onCancel,
    refreshSystemImages,
  } = props;
  const showAlert = useShowAlert();
  const [images, setImages] = useState([] as ImageListType);
  const [isUploading, setIsUploading] = useState(false);
  const onChange = (imageList: ImageListType) => {
    setImages(imageList);
  };
  const onConfirm = () => {
    if (!images.length) {
      onCancel();
    } else {
      const formData = new FormData();

      formData.append("file", images[0].file!);

      setIsUploading(true);
      fetch(`${imageApiUrl}/api/v1/ControlSystems/${controlSystemId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      }).then(
        () => {
          showAlert({
            type: "success",
            text: "Image Added Successfully",
          });
          setIsUploading(false);
          refreshSystemImages();
          onCancel();
        },
        () => {
          showAlert({
            type: "error",
            text: "Error Creating System Image",
          });
          setIsUploading(false);
        }
      );
    }
  };

  return (
    <GenericConfirmModal
      header="Upload Image"
      confirmText="Upload"
      cancelText="Close"
      actionPending={isUploading}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <ImageUploader images={images} onChange={onChange} />
    </GenericConfirmModal>
  );
}

export default UploadImageModal;
