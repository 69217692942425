/**
 * @generated SignedSource<<cf438ea8e71e8060d4db3c5389d78326>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly outputInformations: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputSupervision: {
      readonly data: OutputSupervision | null;
      readonly included: boolean | null;
    } | null;
    readonly realTimeStatus: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly tripWithPanelBell: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "12ed41e43e31b499e32ebd347702a721";

export default node;
