import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  Device734CardOptions,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationRequireSiteCodeField_deviceInformation$key } from "./__generated__/DeviceInformationRequireSiteCodeField_deviceInformation.graphql";

export const deviceInformationRequireSiteCodeFieldId = (number: string) =>
  `device-information-require-site-code-${number}`;

function DeviceInformationRequireSiteCodeField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationRequireSiteCodeField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationRequireSiteCodeField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                enforceSiteCode
                cardFormatOptions
              }
            }
          }
          ... on Xt75DeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                enforceSiteCode
                cardFormatOptions
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationRequireSiteCodeFieldId(String(number));
  const label = "Require Site Code";
  const relayEnv = useRelayEnvironment();
  const disabled =
    device734?.cardFormatOptions !== Device734CardOptions.DMP ||
    deviceType !== DeviceInformationType.DOOR ||
    !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to allow verification of Site Code within the Wiegand Credential in addition to the User Code."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={device734?.enforceSiteCode ?? false}
        onChange={() => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                !device734?.enforceSiteCode,
                "enforceSiteCode"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationRequireSiteCodeField;
