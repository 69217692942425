App.controller("ModemReportsCtrl", [
  "$scope",
  "$q",
  "$compile",
  "$rootScope",
  "UserService",
  "DTColumnBuilder",
  "ModemScheduledUpgradesService",
  "DataTablesUtilsService",
  "DealerModemService",
  "DealerODataAPI",
  "$sanitize",
  "$modal",
  "$filter",
  function (
    $scope,
    $q,
    $compile,
    $rootScope,
    UserService,
    DTColumnBuilder,
    ModemScheduledUpgradesService,
    DataTablesUtilsService,
    DealerModemService,
    DealerODataAPI,
    $sanitize,
    $modal,
    $filter
  ) {
    $scope.UserService = UserService;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(getList(), "");
    $scope.dtColumns = DataTablesUtilsService.dtColumns;
    $scope.scheduledUpgrades = getSheduledUpgradesList();
    $scope.dtScheduledUpgradeOptions = DataTablesUtilsService.getDTOptions(
      $scope.scheduledUpgrades,
      ""
    );
    $scope.dtScheduledUpgradeOptions.withOption("order", [2, "desc"]);
    $scope.dtScheduledUpgradeOptions.withOption("createdRow", function (row) {
      // Recompiling so we can bind Angular directive to the DT
      DataTablesUtilsService.createdRow(row, $scope);
    });
    $scope.dtScheduledUpgradeOptions.withOption;
    $scope.dtScheduledUpgradesColumns =
      DataTablesUtilsService.dtScheduledUpgradesColumns;

    $scope.dtOptions.withDOM(
      '<"data-table__top  pad-24 pad-t-32" <"table-search"search"rf> <"column-selector" B>><t><"data-table__bottom pad-24 mar-b-40"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
    ); //<Tlpi>
    $scope.dtOptions.withOption("order", [0, "asc"]);
    $scope.dtOptions.withOption("createdRow", function (row) {
      // Recompiling so we can bind Angular directive to the DT
      $compile(angular.element(row).contents())($scope);
    });

    $scope.auditList = [];

    var dtIncludeButton = {
      extend: "collection",
      className: "btn-sm",
      text: "Include LTE",
      action: function (e, dt, node, config) {
        $scope.modemReport.dtInstance.changeData(getList(false, true));
      },
    };

    var dtLoadingButton = {
      extend: "collection",
      className: "btn-sm",
      text: "Loading...",
      action: function (e, dt, node, config) {},
    };

    var dtRemoveButton = {
      extend: "collection",
      className: "btn-sm",
      text: "Remove LTE",
      action: function (e, dt, node, config) {
        $scope.modemReport.dtInstance.changeData(getList(false, false));
      },
    };

    //store the buttons so we can insert them anytime
    var dtButtons = [
      {
        extend: "collection",
        className: "btn-sm",
        text: "Export",
        buttons: [
          "csv",
          "excel",
          {
            extend: "pdf",
            orientation: "landscape",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
      },
      {
        extend: "colvis",
        className: "btn-sm",
        text: "Show/Hide Columns",
      },
    ];

    var renderButtons = function (buttons) {
      $scope.dtOptions.withButtons(buttons);
    };

    //renderButtons(dtButtons.concat([dtIncludeButton]));

    $scope.auditList = [];

    $scope.modemReport = {
      dtInstance: "",
    };

    function getList(refresh, showAll) {
      refresh = typeof refresh == "undefined" ? false : refresh;
      var deferred = $q.defer();
      var _this = this;

      if (typeof showAll !== "undefined") {
        var buttonGroup = $("#CustomerModemDT_wrapper .dt-buttons");
        buttonGroup.html("Loading...");
      }

      //get the Modems for the dealer
      DealerModemService.getModems(UserService.dealer_id)
        .then(
          function (modems) {
            //get all the systems for the dealer
            getSystemsForCustomer(UserService.dealer_id).then(
              (controlSystems) => {
                var auditList = [];

                //map each system to a modem, and add the system name
                modems.map((x) => {
                  let system = controlSystems.value.find((a) => {
                    return a.control_system_id == x.ControlSystemId; //search by ControlSystemId
                  });

                  x.SystemName = system ? system.control_system_name : "";
                  x.SystemType = system ? system.panel_hardware_model : "";
                  return x;
                });

                if (!showAll) {
                  auditList = modems.filter((n) => n.Radio !== "LTE");
                } else {
                  auditList = modems;
                }

                $scope.auditList = auditList;

                if (typeof showAll !== "undefined") {
                  if (showAll) {
                    renderButtons(dtButtons.concat([dtRemoveButton]));
                  } else {
                    renderButtons(dtButtons.concat([dtIncludeButton]));
                  }
                }

                deferred.resolve(auditList);
              }
            );
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving modem list",
              json: error,
            });
          },
          function (info) {}
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    $scope.scheduledUpgrades = [];
    function getSheduledUpgradesList(refresh) {
      refresh = typeof refresh == "undefined" ? false : refresh;
      var deferred = $q.defer();

      //get the Modems for the dealer
      ModemScheduledUpgradesService.getScheduledUpgradesForDealer(
        UserService.dealer_id
      )
        .then(
          function (data) {
            $scope.scheduledUpgrades = data;
            deferred.resolve($scope.scheduledUpgrades);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving modem list",
              json: error,
            });
          },
          function (info) {}
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    addHtmlTitle = function (item) {
      if (item && !item.includes("title")) {
        let title = $sanitize(item).replace(/'/g, "");
        return `<span title='${title}'>${item}</span>`;
      } else return item;
    };

    getInDealerAdmin = function (id) {
      let audit = getAudit(id);

      //check for panelid to ensure system exists in dealer admin
      if (audit.ControlSystemId && UserService.canEditCustomers()) {
        return `<a class="btn btn-sm btn-dmp" style="position: absolute; top:50%; min-width: 10rem; left: 2.4rem; transform: translateY(-50%);" ng-click="$state.go('app.control_system', {customer_id: ${audit.CustomerId}, control_system_id:${audit.ControlSystemId} })">View System</a>`;
      } else {
        return `<a class="btn btn-sm btn-dmp" style="min-width: 10rem; position: absolute; top:50%; left: 2.4rem; transform: translateY(-50%);" ng-click="openNewSystemModal('${$sanitize(
          audit.Sim
        )}')">Add System</a>`;
      }
    };

    createDeleteServiceApptBtn = function () {
      return `<a class=" link link-danger icon-trash icon-link-hover mar-r-24 pull-right"  ng-click="{}"  location="left" confirm-append-to-body="true" message="Are you sure you want to delete this schedule?" yes="Confirm" no="Cancel"></a>`;
    };

    getAudit = function (id) {
      return $scope.auditList.find((data) => {
        return data["Id"] == id;
      });
    };

    formatDate = function (newDate) {
      return $filter("date")("newDate", "dd/MM/yyyy");
    };

    (getSystemsForCustomer = function (dealerID) {
      var deferred = $q.defer();
      DealerODataAPI.getDetailedCustomers(
        {
          dealer_id: dealerID,
          $select:
            "control_system_id, control_system_name, panel_hardware_model",
        },
        (data) => {
          deferred.resolve(data);
        },
        (error) => {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }),
      ($scope.openNewSystemModal = function (sim) {
        let newSystemModal = $modal.open({
          templateUrl: "app/dealer-reports/new-system-from-modem-modal.html",
          size: "md",
          controller: "NewSystemFromModemModalCtrl",
          backdrop: "static",
          scope: $scope,
          resolve: {
            simNumber: function () {
              return sim;
            },
          },
        });
      });

    $scope.deleteUpgrade = function (id) {
      ModemScheduledUpgradesService.deleteScheduledUpgradeForDealer(
        UserService.dealer_id,
        id
      ).then(
        function (data) {
          let index = $scope.scheduledUpgrades.findIndex((x) => x.Id === id);
          if (index > -1) {
            $scope.scheduledUpgrades.splice(index, 1);
          }
          //ScheduledUpgradesDT.rerender();
          var scheduleTable = angular.element(
            document.querySelector("#ScheduledUpgradesDT")
          );
          $compile(scheduleTable)($scope);
          $rootScope.alerts.push({
            type: "success",
            text: "The scheduled upgrade has been cancelled.",
          });
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error cancelling scheduled upgrade.",
            json: error,
          });
          deferred.reject(error);
        }
      );
    };

    //List of columns, column properties, and custom methods used to display the data in the Data Table.
    $scope.dtColumns = [
      DTColumnBuilder.newColumn("Carrier")
        .withTitle("Carrier")
        .renderWith((carrier) => addHtmlTitle(carrier)),
      DTColumnBuilder.newColumn("Msisdn")
        .withTitle("Phone Number")
        .renderWith((msisdn) => addHtmlTitle(msisdn)),
      DTColumnBuilder.newColumn("Sim")
        .withTitle("SIM Number")
        .renderWith((sim) => addHtmlTitle(sim)),
      DTColumnBuilder.newColumn("Model")
        .withTitle("Cell Model")
        .renderWith((cellModel) => addHtmlTitle(cellModel)),
      DTColumnBuilder.newColumn("Radio")
        .withTitle("Cell Network")
        .renderWith((radio) => addHtmlTitle(radio)),
      DTColumnBuilder.newColumn("SystemType")
        .withTitle("System Type")
        .renderWith((systemType) => addHtmlTitle(systemType)),
      DTColumnBuilder.newColumn("LastTransmitDate")
        .withTitle("Last Transmit Date")
        .renderWith(function (data, type, row) {
          // For sorting, return the raw date. Otherwise return the formatted date for display.
          return type == "sort"
            ? data
            : addHtmlTitle(
                DataTablesUtilsService.asDateTimeFormatted(
                  row,
                  "LastTransmitDate"
                )
              );
        }),
      DTColumnBuilder.newColumn("Description")
        .withTitle("Description")
        .renderWith((description) => addHtmlTitle(description)),
      DTColumnBuilder.newColumn("Id")
        .withTitle(" ")
        .withClass("no-ellipsis")
        .renderWith((id) => addHtmlTitle(getInDealerAdmin(id)))
        .notSortable(),
    ];

    //Customer name, system name, date, time, carrier
    $scope.dtScheduledUpgradesColumns = [
      DTColumnBuilder.newColumn("CustomerName")
        .withTitle("Customer Name")
        .renderWith((customerName) => addHtmlTitle(customerName)),
      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith((data, type, row) =>
          DataTablesUtilsService.navToSystemViewWithTitle(
            row,
            "CustomerId",
            "PanelId",
            "",
            "PanelName"
          )
        ),
      DTColumnBuilder.newColumn(null)
        .withTitle("Date")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return addHtmlTitle(
            DataTablesUtilsService.asDateTimeFormatted(row, "ScheduleDate")
          );
        }),
      DTColumnBuilder.newColumn("SimCarrier")
        .withTitle("Carrier")
        .renderWith((simCarrier) => addHtmlTitle(simCarrier)),
      DTColumnBuilder.newColumn("SoftwareVersion")
        .withTitle("Firmware Version")
        .renderWith((softwareVersion) => addHtmlTitle(softwareVersion)),
      DTColumnBuilder.newColumn(null)
        .notSortable()
        .withOption("width", "100")
        .renderWith(function (data, type, row) {
          return `<icon-button-borderless
                      class="icon-24 mar-r-8"
                      icon="icon-trash"
                      icon-color="failure"
                      da-confirm-button="deleteUpgrade(${row.Id})"
                      location='left'
                      confirm-append-to-body='true'
                      message='Are you sure you want to delete this schedule?' 
                      yes='Confirm' 
                      no='Cancel'
                      tooltip="Delete Upgrade"
                      tooltip-append-to-body="true"
                    ></icon-button-borderless>`;
        }),
    ];

    // TODO: This code, for some unknown reason, is making the refresh work after deleting a control system...
    $scope.dtScheduledUpgradeOptions.withButtons([
      // {extend: 'colvis', className: 'btn-sm', text: ''}
    ]);

    $scope.openSunsetDealerAvailabilityModal = function () {
      var customerScoreModal = $modal.open({
        templateUrl:
          "app/dealer-reports/sunset-dealer-availability/sunset-dealer-availability-modal.html",
        size: "md",
        controller: "sunsetDealerAvailabilityModalCtrl",
        backdrop: "static",
        scope: $scope,
      });
    };
  },
]);

/**
 * @ngdoc object
 * @name App.controller:NewSystemFromModemModalCtrl
 *
 *
 * @description
 *  Controller for the New System From Modem Modal.
 */
App.controller("NewSystemFromModemModalCtrl", [
  "$scope",
  "$modalInstance",
  "DashboardDataService",
  "UserService",
  "$filter",
  "$rootScope",
  "Customer",
  "$state",
  "simNumber",
  function (
    $scope,
    $modalInstance,
    DashboardDataService,
    UserService,
    $filter,
    $rootScope,
    Customer,
    $state,
    simNumber
  ) {
    $scope.customersForDealer = [];

    $scope.customer = new Customer(false, UserService.dealer_id);

    // create new system on new customer or existing
    $scope.createNewCustomer = true;

    $scope.createSystemInfo = {};

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    // Create the customer or navigate to existing customer, then create new system
    $scope.createSystem = function () {
      if ($scope.createNewCustomer) {
        // if making a new customer
        $scope.customer
          .create()
          .then(
            function (newCustomer) {
              $state.go("app.control_system_edit", {
                customer_id: newCustomer.customer.id,
                control_system_id: "new",
                simNumber: simNumber,
              });
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "error creating customer",
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        // if using an existing customer
        $state.go("app.control_system_edit", {
          customer_id: $scope.createSystemInfo.existingCustomer.id,
          control_system_id: "new",
          simNumber: simNumber,
        });
      }
    };

    function init() {
      // Grab the customers for the dealer
      DashboardDataService.getCustomersForDealer(UserService.dealer_id)
        .then(
          function (customers) {
            $scope.customersForDealer = customers;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error getting existing customers",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    init();
  },
]);
