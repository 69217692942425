import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { CommunicationPathType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathOpenCloseUserReportsField_communicationPath$key } from "./__generated__/CommPathOpenCloseUserReportsField_communicationPath.graphql";

export const commPathOpenCloseUserReportsFieldId = (number: string) =>
  `comm-path-open-close-user-reports-${number}`;

function CommPathOpenCloseUserReportsField() {
  const [{ id, number, openCloseUserReports, type }, updateCommPath] =
    useCommPathFragment<CommPathOpenCloseUserReportsField_communicationPath$key>(
      graphql`
        fragment CommPathOpenCloseUserReportsField_communicationPath on CommunicationPath {
          id
          number
          openCloseUserReports
          type
        }
      `
    );

  const fieldId = commPathOpenCloseUserReportsFieldId(String(number));
  const disabled = type === CommunicationPathType.BACKUP;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Opening/Closing & User Reports"
      tooltip="Turns on Schedule Change, and Holiday Change reports. Enables Opening, Closing, Bypass, Reset, Code Change reports if programmed in System Reports."
      disabled={disabled}
    >
      <Switch
        label="Opening/Closing & User Reports"
        id={fieldId}
        name={id}
        disabled={disabled}
        checked={openCloseUserReports}
        onChange={() => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(!openCloseUserReports, "openCloseUserReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathOpenCloseUserReportsField;
