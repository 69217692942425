/**
 *
 * DateIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DateIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M60.666,6H49V0h-4v6H18V0h-4v6H3.334C1.465,6,0,7.418,0,9.229v51.415C0,62.495,1.496,64,3.334,64h57.332
      C62.504,64,64,62.495,64,60.645V9.229C64,7.418,62.535,6,60.666,6z M60,60H4V10h10v7h4v-7h27v7h4v-7h11V60z"
        />
        <path
          d="M24,29h12.573c-2.239,2.881-4.057,5.797-5.281,8.689C29.815,41.17,29,45.081,29,49.312V52h4v-2.688
      c0-3.691,0.708-7.076,1.975-10.062c1.29-3.045,3.11-6.15,5.702-9.227L41,29.464V25H24V29z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default DateIcon;
