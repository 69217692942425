/**
 * @generated SignedSource<<22a4db958b09ad3bf74a8a3e8ba0059e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConnectionInformationStatisticsQuery$variables = {
  panelId: number;
};
export type ConnectionInformationStatisticsQuery$data = {
  readonly systemDiagnosticsPanelStatistics: ReadonlyArray<{
    readonly acVoltage: number | null;
    readonly actualCount: number | null;
    readonly cellRetries: number | null;
    readonly cellRoaming: boolean | null;
    readonly cellTowerId: number | null;
    readonly connectionType: string | null;
    readonly currentCellSignal: number | null;
    readonly dcVoltage: number | null;
    readonly deviceType: string | null;
    readonly maxCount: number | null;
    readonly networkRetries: number | null;
    readonly newSimIdentifier: string | null;
    readonly originalSimIdentifier: string | null;
    readonly pathNumber: number | null;
    readonly reportedAt: string;
    readonly statId: number | null;
    readonly status: string | null;
    readonly strongestCellSignal: number | null;
    readonly weakestCellSignal: number | null;
  }>;
};
export type ConnectionInformationStatisticsQuery = {
  response: ConnectionInformationStatisticsQuery$data;
  variables: ConnectionInformationStatisticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "panelId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "panelId",
        "variableName": "panelId"
      }
    ],
    "concreteType": "SystemDiagnosticsPanelStatistics",
    "kind": "LinkedField",
    "name": "systemDiagnosticsPanelStatistics",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reportedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acVoltage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dcVoltage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentCellSignal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "strongestCellSignal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weakestCellSignal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cellRetries",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cellRoaming",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "networkRetries",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pathNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "connectionType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cellTowerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "originalSimIdentifier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "newSimIdentifier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectionInformationStatisticsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConnectionInformationStatisticsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fef1842b5e363aba550150eafea9e4a7",
    "id": null,
    "metadata": {},
    "name": "ConnectionInformationStatisticsQuery",
    "operationKind": "query",
    "text": "query ConnectionInformationStatisticsQuery(\n  $panelId: Int!\n) {\n  systemDiagnosticsPanelStatistics(panelId: $panelId) {\n    reportedAt\n    statId\n    acVoltage\n    dcVoltage\n    currentCellSignal\n    strongestCellSignal\n    weakestCellSignal\n    cellRetries\n    cellRoaming\n    networkRetries\n    maxCount\n    actualCount\n    pathNumber\n    connectionType\n    status\n    cellTowerId\n    originalSimIdentifier\n    newSimIdentifier\n    deviceType\n  }\n}\n"
  }
};
})();

(node as any).hash = "940d1b8f82d3c8edcef35c5778106d28";

export default node;
