/**
 * @generated SignedSource<<ef0825b7213ec7c1398220e4432720e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFZoneInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly id: string;
    readonly totalWirelessZoneMax: number;
    readonly totalZonesMax: number;
    readonly zoneInformations: ReadonlyArray<{
      readonly __typename: "Zone";
      readonly id: string;
      readonly isNew: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"XFZoneInformationProgrammingConceptFormInline_zone">;
    }>;
    readonly zoneNumberRange: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "XFZoneInformationProgrammingConceptFormInline_controlSystem";
};
export type XFZoneInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFZoneInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFZoneInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFZoneInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "a1d8eef25355ef234c8d3bfa497ffc1f";

export default node;
