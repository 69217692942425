import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { fetchQuery, useFragment, useRefetchableFragment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { asString, ControlSystem, ID } from "securecom-graphql/client";
import { Concept } from "../common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../common/FullProgrammingVersionEntryPoint";
import * as AreaInformation from "./TMSentryAreaInformationProgrammingConceptForm";
import * as Communication from "./TMSentryCommunicationProgrammingConceptForm";
import * as LockoutCode from "./TMSentryLockoutCodeProgrammingConceptForm";
import * as NetworkOptions from "./TMSentryNetworkOptionsProgrammingConceptForm";
import * as OutputOptions from "./TMSentryOutputOptionsProgrammingConceptForm";
import * as RemoteOptions from "./TMSentryRemoteOptionsProgrammingConceptForm";
import * as SystemOptions from "./TMSentrySystemOptionsProgrammingConceptForm";
import * as SystemReports from "./TMSentrySystemReportsProgrammingConceptForm";
import * as ZoneInformations from "./TMSentryZoneInformationsProgrammingConceptForm";
import { TMSentryFullProgrammingContainer_controlSystem$key } from "./__generated__/TMSentryFullProgrammingContainer_controlSystem.graphql";
import { TMSentryFullProgrammingContainer_templateStatus_controlSystem$key } from "./__generated__/TMSentryFullProgrammingContainer_templateStatus_controlSystem.graphql";
import { TMSentryFullProgrammingContainer_templateStatus_controlSystemQuery } from "./__generated__/TMSentryFullProgrammingContainer_templateStatus_controlSystemQuery.graphql";
import { TMSentryFullProgrammingTemplateQuery } from "./__generated__/TMSentryFullProgrammingTemplateQuery.graphql";

export const createConcepts = ({
  softwareVersion,
  isCellComSl,
  isIComSl,
  isCellComEx,
  isTMSentry,
}: {
  softwareVersion: number;
  isCellComSl: boolean;
  isIComSl: boolean;
  isCellComEx: boolean;
  isTMSentry: boolean;
}) =>
  [
    Communication,
    NetworkOptions,
    RemoteOptions,
    SystemReports,
    SystemOptions,
    OutputOptions,
    AreaInformation,
    ZoneInformations,
    LockoutCode,
  ].filter(isNotNullOrUndefined) as Concept[];

export const tMSentryFullProgrammingControlSystem = graphql`
  fragment TMSentryFullProgramming_controlSystem on ControlSystem {
    id
    ...FullProgrammingForm_controlSystem
    ...ProgrammingTemplateForm_controlSystem
    ...ControlSystemContext_controlSystem
    ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
    ...TMSentryCommunicationProgrammingConceptForm_controlSystem
    ...TMSentryNetworkOptionsProgrammingConceptForm_controlSystem
    ...TMSentryLockoutCodeProgrammingConceptForm_controlSystem
    ...TMSentryRemoteOptionsProgrammingConceptForm_controlSystem
    ...TMSentrySystemReportsProgrammingConceptForm_controlSystem
    ...TMSentrySystemOptionsProgrammingConceptForm_controlSystem
    ...SystemOptionsKeypadInputField_controlSystem
    ...TMSentryOutputOptionsProgrammingConceptForm_controlSystem
    ...TMSentryAreaInformationProgrammingConceptForm_controlSystem
    ...TMSentryAreaInformationProgrammingConceptFormNavButton_controlSystem
    ...TMSentryZoneInformationsProgrammingConceptForm_controlSystem
    ...TMSentryZoneInformationsProgrammingConceptFormNavButton_controlSystem
  }
`;
export const tMSentryFullProgrammingInlineControlSystem = graphql`
  fragment TMSentryFullProgrammingInline_controlSystem on ControlSystem {
    ...TMSentryCommunicationProgrammingConceptFormInline_controlSystem
    ...TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem
    ...TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem
    ...TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem
    ...TMSentrySystemReportsProgrammingConceptFormInline_controlSystem
    ...TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem
    ...TMSentryOutputOptionsProgrammingConceptFormInline_controlSystem
    ...TMSentryAreaInformationProgrammingConceptFormInline_controlSystem
    ...TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem
  }
`;

function TMSentryFullProgrammingContainer(props: {
  dealerId: ID;
  systemId: string;
  controlSystem: TMSentryFullProgrammingContainer_controlSystem$key;
}) {
  const controlSystemFragment = useFragment(
    graphql`
      fragment TMSentryFullProgrammingContainer_controlSystem on ControlSystem {
        name
        isCellComSl
        isCellComEx
        isIComSl
        isTMSentry
        panel {
          softwareVersion
        }
        ...TMSentryFullProgrammingContainer_templateStatus_controlSystem
      }
    `,
    props.controlSystem
  );

  const { name, panel, isCellComSl, isCellComEx, isIComSl, isTMSentry } =
    controlSystemFragment;

  const softwareVersion = Number(panel?.softwareVersion);

  const concepts = createConcepts({
    softwareVersion,
    isCellComSl,
    isIComSl,
    isCellComEx,
    isTMSentry,
  });

  const [{ templatePreProgrammingJobStatus }, refetchJobStatus] =
    useRefetchableFragment<
      TMSentryFullProgrammingContainer_templateStatus_controlSystemQuery,
      TMSentryFullProgrammingContainer_templateStatus_controlSystem$key
    >(
      graphql`
        fragment TMSentryFullProgrammingContainer_templateStatus_controlSystem on ControlSystem
        @refetchable(
          queryName: "TMSentryFullProgrammingContainer_templateStatus_controlSystemQuery"
        ) {
          templatePreProgrammingJobStatus
        }
      `,
      controlSystemFragment
    );

  const jobPending = [
    "new",
    "created",
    "running",
    "acquired",
    "started",
  ].includes(templatePreProgrammingJobStatus ?? "");

  React.useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (jobPending) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetchJobStatus({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 5000));
        }, 5000)
      );
    }

    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [refetchJobStatus, jobPending]);

  return jobPending ? (
    <GenericPageFallback message={"Applying Template Programming..."} />
  ) : (
    <FullProgrammingVersionEntryPoint
      systemName={name}
      concepts={concepts}
      gqlQuery={graphql`
        query TMSentryFullProgrammingQuery($dealerId: ID!, $systemId: ID!) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: node(id: $systemId) {
            ... on ControlSystem {
              id
              name
              panel {
                hardwareModel
              }
              customer {
                id
              }
              ...ProgrammingTemplateForm_controlSystem
              ...TMSentryFullProgramming_controlSystem
              ...TMSentryCommunicationProgrammingConceptFormInline_controlSystem
              ...TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem
              ...NetworkOptionsDhcpEnabledField_controlSystem
              ...TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem
              ...TMSentryAreaInformationProgrammingConceptFormInline_controlSystem
              ...TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem
              ...TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem
              ...TMSentrySystemReportsProgrammingConceptFormInline_controlSystem
              ...TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem
              ...TMSentryOutputOptionsProgrammingConceptFormInline_controlSystem
              ...TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem
            }
          }
          controlSystemInline: node(id: $systemId) {
            ... on ControlSystem {
              id
              ...TMSentryFullProgrammingInline_controlSystem
            }
          }
        }
      `}
      gqlQueryVariables={{
        systemId: props.systemId,
        dealerId: asString(props.dealerId),
      }}
      gqlFormControlSystemFragment={tMSentryFullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={
        tMSentryFullProgrammingInlineControlSystem
      }
      applyTemplate={applyTemplate(props.systemId, concepts)}
    />
  );
}

export default TMSentryFullProgrammingContainer;

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<TMSentryFullProgrammingTemplateQuery>(
    environment,
    graphql`
      query TMSentryFullProgrammingTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on TakeoverProgrammingTemplateConcepts {
                ...TMSentryCommunicationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentryRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentrySystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentryOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentryAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentryZoneInformationsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
