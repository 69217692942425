import * as React from "react";
import styled from "styled-components";

const FullWidthTableSearchInput = styled.input`
  max-width: 100%;
  margin-bottom: var(--measure-2x);
  flex: 1 1;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.8;
  transition: all 0.4s;
  margin-right: var(--measure-1x);
  @media (min-width: 768px) {
    font-size: 1.4rem;
    line-height: 1;
    width: 100%;
    text-align: left;
    margin-bottom: 0;
  }
`;

const DataTableSearchInput = styled.input`
  max-width: 32rem;
  margin-bottom: var(--measure-2x);
  flex: 1 1;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.8;
  transition: all 0.4s;
  margin-right: var(--measure-1x);
  @media (min-width: 768px) {
    font-size: 1.4rem;
    line-height: 1;
    width: 25rem;
    text-align: left;
    margin-bottom: 0;
  }
`;
export const TextField = ({
  className,
  type = "text",
  ...rest
}: React.HTMLProps<HTMLInputElement>) => {
  return (
    <input
      className={className ? `form-control ${className}` : `form-control`}
      type={type}
      {...rest}
    />
  );
};

export const FullWidthTableSearch = ({
  className,
  value,
  onChange,
  ...rest
}: { value: string; onChange: (newValue: string) => void } & Omit<
  React.HTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
>) => {
  return (
    <FullWidthTableSearchInput
      id="search-input"
      placeholder="Search..."
      className="form-control input-sm"
      value={value}
      data-role="table-search"
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
};

export const TableSearch = ({
  className,
  value,
  onChange,
  ...rest
}: { value: string; onChange: (newValue: string) => void } & Omit<
  React.HTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
>) => {
  return (
    <DataTableSearchInput
      id="search-input"
      placeholder="Search..."
      className="form-control input-sm"
      value={value}
      data-role="table-search"
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
};
export const TextArea = ({
  className,
  ...rest
}: React.HTMLProps<HTMLTextAreaElement>) => {
  return (
    <textarea
      className={className ? `form-control ${className}` : `form-control`}
      {...rest}
    ></textarea>
  );
};

/**
 * Only works with <option> as children, resolving to
 * any other type will cause unintended consequences.
 */
export const Select = ({
  onChange,
  value,
  children,
  className,
  ...rest
}: {
  onChange: (newValue: string) => void;
  value: string;
  children: React.ReactNode;
} & Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "onChange">) => {
  return (
    <select
      className={className ? `form-control ${className}` : `form-control`}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      {...rest}
    >
      {children}
    </select>
  );
};

export const Checkbox: React.FC<
  React.PropsWithChildren<
    Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked"> & {
      checked: boolean;
      label: React.ReactNode;
    }
  >
> = ({ label, ...rest }) => {
  return (
    <>
      <div className="checkbox c-checkbox needsclick">
        <label className="needsclick">
          <input type="checkbox" {...rest} className="needsclick" />
          <span className="icon-checkmark"></span>
          {label}
        </label>
      </div>
    </>
  );
};

export const CheckBoxInline = ({
  label,
  ...rest
}: { label: React.ReactNode } & Omit<
  React.HTMLProps<HTMLInputElement>,
  "label"
>) => {
  return (
    <div className="checkbox-inline c-checkbox needsclick">
      <label className="needsclick">
        <input type="checkbox" {...rest} />
        <span className="icon-checkmark"></span>
        {label}
      </label>
    </div>
  );
};

export const Toggle = ({
  className,
  isChecked,
  ...rest
}: { isChecked: boolean } & React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>) => {
  return (
    <label className={className ? `switch ${className}` : `switch`} {...rest}>
      <input type="checkbox" checked={isChecked} />
      <span></span>
    </label>
  );
};

export default {
  TableSearch,
  TextField,
  TextArea,
  Select,
  Checkbox,
  CheckBoxInline,
  Toggle,
  FullWidthTableSearch,
};
