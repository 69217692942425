/**
 * @generated SignedSource<<d3e51a374849c2522c2ba50fd4e2ef47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type X1Type = "X1" | "X8";
export type NewSiteControlSystemElevatorFormX1TypeQuery$variables = {
  customerId: string;
  serialNumber: string;
};
export type NewSiteControlSystemElevatorFormX1TypeQuery$data = {
  readonly getX1TypeFromSerialNumber: {
    readonly x1Type?: X1Type;
  };
};
export type NewSiteControlSystemElevatorFormX1TypeQuery = {
  response: NewSiteControlSystemElevatorFormX1TypeQuery$data;
  variables: NewSiteControlSystemElevatorFormX1TypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serialNumber"
},
v2 = [
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customerId"
  },
  {
    "kind": "Variable",
    "name": "serialNumber",
    "variableName": "serialNumber"
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "x1Type",
      "storageKey": null
    }
  ],
  "type": "X1TypeFoundResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewSiteControlSystemElevatorFormX1TypeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getX1TypeFromSerialNumber",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewSiteControlSystemElevatorFormX1TypeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getX1TypeFromSerialNumber",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d749ee6273a28fd3b57291db6a7eeff5",
    "id": null,
    "metadata": {},
    "name": "NewSiteControlSystemElevatorFormX1TypeQuery",
    "operationKind": "query",
    "text": "query NewSiteControlSystemElevatorFormX1TypeQuery(\n  $serialNumber: String!\n  $customerId: ID!\n) {\n  getX1TypeFromSerialNumber(serialNumber: $serialNumber, customerId: $customerId) {\n    __typename\n    ... on X1TypeFoundResponse {\n      x1Type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe34f1aa5b808a1cb997c6e3f2ff1dc0";

export default node;
