/**
 * @generated SignedSource<<a4187f0ef4cc3b968458d68f6fa03191>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreVideoReportsField_remoteOptions$data = {
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly entreVideoReports?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsEntreVideoReportsField_remoteOptions";
};
export type RemoteOptionsEntreVideoReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreVideoReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreVideoReportsField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreVideoReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreVideoReports",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreConnection",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "67b8c1820cbbeaaea0f7bf86947a90a7";

export default node;
