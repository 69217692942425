/**
 * @generated SignedSource<<72f13b505923eaa46909085e6a3d135c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NacProtocols = "DMP" | "GENTEX" | "SYSTEM_SENSOR" | "UNKNOWN" | "WHEELOCK";
import { FragmentRefs } from "relay-runtime";
export type XFBellOptionsProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly bellOptions: {
      readonly id: string;
      readonly nac1SyncProtocol: NacProtocols | null;
      readonly nac2SyncProtocol: NacProtocols | null;
      readonly " $fragmentSpreads": FragmentRefs<"BellOptionsAutomaticBellTestField_bellOptions" | "BellOptionsAuxiliary1BellActionField_bellOptions" | "BellOptionsAuxiliary2BellActionField_bellOptions" | "BellOptionsBellCutoffTimeField_bellOptions" | "BellOptionsBellOutputField_bellOptions" | "BellOptionsBurglaryBellActionField_bellOptions" | "BellOptionsCarbonMonoxideBellActionField_bellOptions" | "BellOptionsContext_bellOptions" | "BellOptionsEmergencyBellActionField_bellOptions" | "BellOptionsFireBellActionField_bellOptions" | "BellOptionsNac1SyncProtocolField_bellOptions" | "BellOptionsNac2SyncProtocolField_bellOptions" | "BellOptionsPanicBellActionField_bellOptions" | "BellOptionsSelectiveSilenceField_bellOptions" | "BellOptionsStrobeField_bellOptions" | "BellOptionsSupervisoryBellActionField_bellOptions">;
    } | null;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XFBellOptionsProgrammingConceptForm_controlSystem";
};
export type XFBellOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XFBellOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFBellOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFBellOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BellOptions",
          "kind": "LinkedField",
          "name": "bellOptions",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nac1SyncProtocol",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nac2SyncProtocol",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsContext_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsBellCutoffTimeField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsNac1SyncProtocolField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsNac2SyncProtocolField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsAutomaticBellTestField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsBellOutputField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsFireBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsBurglaryBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsSupervisoryBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsPanicBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsEmergencyBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsAuxiliary1BellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsAuxiliary2BellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsCarbonMonoxideBellActionField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsStrobeField_bellOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BellOptionsSelectiveSilenceField_bellOptions"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "6cae668d1a1f235da9d2f36ef680d09b";

export default node;
