/**
 * @generated SignedSource<<fdb089f615cf2930a18b5723943abce7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathType = "BACKUP" | "PRIMARY";
import { FragmentRefs } from "relay-runtime";
export type CommPathTypeField_communicationPath$data = {
  readonly number: number;
  readonly type: CommunicationPathType;
  readonly " $fragmentType": "CommPathTypeField_communicationPath";
};
export type CommPathTypeField_communicationPath$key = {
  readonly " $data"?: CommPathTypeField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathTypeField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathTypeField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "95a68de00b3898ab2d8376167dadb264";

export default node;
