/**
 * @ngdoc directive
 * @name App.directive:validateUserNumber
 *
 * @description
 *   Checks to see if a user code number is already in use. If so, returns false
 *   Required Attributes:
 *   page-type: The current page type (ex: 'new', 'edit')
 *   user-codes: A list of user codes for the panel
 *   validate-user-number: the user code number to check for
 *   z-length: The amount of zero padding to apply to the value
 *
 */
App.directive("daValidateUserNumber", [
  "$filter",
  function ($filter) {
    return {
      require: "ngModel",
      link: function ($scope, element, attrs, ngModel) {
        ngModel.$validators.usernumbererrors = function (value) {
          if (attrs.pageType != "new") {
            return true;
          } // Only continue validation on new user code page
          var isValid = true;
          var userNumber = $filter("zpad")(value, attrs.zlength);
          // Convert the userNumber to an integer for comparison
          userNumber = parseInt(userNumber, 10);

          var userCodes = JSON.parse(attrs.userCodes);
          for (var i = 0; i < userCodes.length; i++) {
            if (
              parseInt(userCodes[i].number, 10) == userNumber &&
              userCodes[i].isNew !== true
            ) {
              return false;
            }
          }
          return isValid;
        };
      },
    };
  },
]);
