export const getSearchValue = (key: string) => (
  urlString: string
): string | null => {
  const url = new URL(urlString);
  return url?.searchParams.get(key);
};

export const fetchFileSrcFromUrlWithBearerAuth = async (
  url: string,
  auth_token: string
): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    });
    const blob = await response.blob();
    if (response.ok) {
      return URL.createObjectURL(blob);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const fetchFileSrcFromUrl = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: "GET",
    });
    const blob = await response.blob();
    if (response.ok) {
      return URL.createObjectURL(blob);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};
