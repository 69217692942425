import graphql from "babel-plugin-relay/macro";
import FadeInOut from "common/components/web/FadeInOut";
import LoadingSpinner from "common/components/web/LoadingSpinner";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { partitionFulfilled } from "common/utils/universal/promise";
import Icon from "components/Icon";
import { GenericConfirmModal } from "components/Modal/ConfirmModal";
import DefaultMoreButton from "components/MoreButton";
import Select from "components/Select";
import SidebarSubnav from "components/SidebarSubnav";
import {
  useCreateRootScopeListener,
  useShowAlert,
} from "contexts/AlertsContext";
import { useEnsureConnectionReady } from "contexts/InitialConnectContext";
import { useDaLoadingBar, useGoToRoute } from "contexts/RoutingContext";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, PanelHardwareModel } from "securecom-graphql/client";
import styled from "styled-components/macro";
import ConfirmActionModal from "../../ConfirmActionModal";
import { FORM_ID } from "../constants";
import ApplyTemplateModal from "../Templates/ApplyTemplateModal";
import { getFormElement, validateBeforeSave } from "../utils";
import { showFieldErrorAlerts } from "../utils/alerts";
import { ErrorType } from "../XRFullProgramming/XRZoneInformationProgrammingConceptForm/__generated__/XRZoneInformationProgrammingConceptFormZoneInformationSendMutation.graphql";
import ActiveConceptContext, {
  useSetActiveConcept,
} from "./ActiveConceptContext";
import {
  removeProgrammingConceptFromChangedProgrammingConcepts,
  removeProgrammingConceptsFromChangedProgrammingConcepts,
  useProgrammingConceptHasChanges,
  useSetChangedProgrammingConcepts,
} from "./ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "./ControlSystemContext";
import { useDealerFragment } from "./DealerContext";
import {
  InvalidFieldsContextProvider,
  InvalidFieldsType,
  removeProgrammingConceptFromInvalidFields,
  useProgrammingConceptHasInvalidFields,
  useSetInvalidFields,
} from "./InvalidFieldsContext";
import PrimaryButton from "./PrimaryButton";
import { ProgrammingConceptFormFallback } from "./ProgrammingConceptForm";
import {
  useBringPanelOnlineAndSendChanges,
  useConceptsAreLoading,
  useConceptsHaveChanges,
  useProgrammingActionsContext,
  useRetrieveAllProgramming,
  useRetrieveConcept,
  useSaveAllProgramming,
  useSendAllChanges,
  useSendAllProgramming,
  useSendConceptChanges,
} from "./ProgrammingContext";
import ProgrammingTemplateForm from "./ProgrammingTemplateForm";
import { useSearchContext } from "./SearchContext";
import { EditingFrom, useTemplateContext } from "./TemplateContext";
import { FullProgrammingForm_controlSystem$key } from "./__generated__/FullProgrammingForm_controlSystem.graphql";
import { FullProgrammingForm_dealer$key } from "./__generated__/FullProgrammingForm_dealer.graphql";

type NavButtonElement = React.ReactElement<
  React.ComponentProps<typeof ProgrammingConceptSidebarButton>
>;

export type OnSave = (
  showAlerts?: boolean,
  isSavingAllListItems?: boolean,
  isEcpDsc?: boolean
) => Promise<SaveErrors>;

export type SaveMutationHookResponse = [OnSave, boolean];

export type SaveErrors = {
  programmingConcept: string;
  errors: readonly {
    readonly type?: ErrorType | null | undefined;
    readonly invalidField?:
      | {
          readonly fieldName: string;
          readonly reason: string | null;
        }
      | null
      | undefined;
  }[];
  listItemNumber?: string;
}[];

export type Concept = {
  conceptId: string;
  title: string;
  useSaveMutation: <A>(props: { controlSystem: A }) => SaveMutationHookResponse;
  useRetrieveMutation: <A>(props: {
    controlSystem: A;
  }) => [(showAlerts: boolean) => Promise<void>, boolean];
  getState: <A, B>(controlSystem: A) => B;
  NavButton: () => NavButtonElement;
  Form: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  applyTemplateData: <A>(
    programmingTemplateConcepts: A,
    controlSystemRecordProxy: RecordProxy<ControlSystem>,
    store: RecordSourceProxy
  ) => void;
};

const inlineConceptListItemsBreakpoint = "44rem";

const Concepts = styled.div`
  margin-bottom: var(--measure-4x);

  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    display: flex;
    align-items: stretch;
    width: 100%;
  }
`;
const MobileConceptSelect = styled(Select)`
  display: block;

  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    display: none !important;
  }
`;
const ConceptsSidebar = styled(SidebarSubnav)`
  display: none;

  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    display: block;
  }
`;
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--measure-3x);
  min-height: 4.4rem;
  width: 100%;
  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    flex-direction: row;
  }
`;
const Title = styled.h1`
  flex: 1;
  font-size: var(--measure-font-24);
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-text-default);
  overflow: hidden;
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    margin: 0 var(--measure-half) 0 0;
  }
`;
const ConceptWrapper = styled.section<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  min-height: 100%;
`;
const ConceptsWrapper = styled.div`
  width: 100%;
  min-width: 0;
  margin-top: var(--measure-1x);

  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    margin-top: 0;
  }
`;

const PreProgrammingPill = styled.div`
  display: inline-block;
  background-color: #c0c0c0;
  margin-left: 0.6em;
  font-size: 1rem;
  padding: var(--measure-half) var(--measure-12);
  border: 1px solid #c0c0c0;
  border-radius: 9999px;
  margin-bottom: var(--measure-1x);
  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    margin-bottom: 0;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const PreProgrammingBanner = styled.div`
  background-color: var(--color-neutral-100);
  border: 2px solid var(--color-info-900);
  border-radius: 0.6rem;
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
`;

const StyledPreProgrammingButton = styled(PrimaryButton)`
  margin-bottom: 1rem;
  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    margin-bottom: 0;
    margin-right: 3px;
  }
`;

const StyledMoreButton = styled(DefaultMoreButton)`
  margin-right: 15px;
  @media screen and (min-width: ${inlineConceptListItemsBreakpoint}) {
    margin-right: 0;
  }
`;

export type ApplyTemplateFunc = (
  relayEnv: RelayModernEnvironment,
  templateId: string
) => Promise<void> | void;

const OnlineMoreMenu = React.memo(
  (props: {
    activeConcept: Concept;
    customerId: string;
    applyTemplate: ApplyTemplateFunc;
    hardwareModel: string;
  }) => {
    const relayEnv = useRelayEnvironment();
    const [
      sendConceptChanges,
      sendConceptChangesMessage,
      sendConceptChangesDisabled,
    ] = useSendConceptChanges(props.activeConcept);

    const [
      retrieveConceptChanges,
      retrieveConceptChangesMessage,
      retrieveConceptChangesDisabled,
    ] = useRetrieveConcept(props.activeConcept);
    const retrieveAllProgramming = useRetrieveAllProgramming();
    const conceptsAreLoading = useConceptsAreLoading();
    const sendAllProgramming = useSendAllProgramming();
    const [
      sendAllProgrammingConfirmModalIsOpen,
      setSendAllProgrammingConfirmModalIsOpen,
    ] = React.useState(false);
    const setActiveConcept = useSetActiveConcept();
    const { toggleIsEditing, isApplying, toggleIsApplying, isCreating } =
      useTemplateContext();
    const allowTemplateFromProgramming = [
      PanelHardwareModel.XTLP,
      PanelHardwareModel.XT30,
      PanelHardwareModel.XT50,
      PanelHardwareModel.XT75,
      PanelHardwareModel.XR150,
      PanelHardwareModel.XR550,
      PanelHardwareModel.CELLCOM_EX,
      PanelHardwareModel.CELLCOM_SL,
      PanelHardwareModel.DUALCOM,
      PanelHardwareModel.XF6_500,
      PanelHardwareModel.XF6_100,
      PanelHardwareModel.TMS6,
    ].includes(props.hardwareModel as PanelHardwareModel);
    const moreMenuProgrammingActionItems = [
      {
        message: sendConceptChangesMessage,
        onClick: sendConceptChanges,
        disabled: conceptsAreLoading || sendConceptChangesDisabled,
      },
      {
        message: retrieveConceptChangesMessage,
        onClick: retrieveConceptChanges,
        disabled: conceptsAreLoading || retrieveConceptChangesDisabled,
      },
      {
        message: "Send All Programming",
        onClick: () => {
          setSendAllProgrammingConfirmModalIsOpen(true);
        },
        disabled: conceptsAreLoading,
      },
      {
        message: "Retrieve All Programming",
        onClick: retrieveAllProgramming,
        disabled: conceptsAreLoading,
      },
    ];
    // Adds the template action items if the hardware model is allowed
    if (allowTemplateFromProgramming) {
      moreMenuProgrammingActionItems.push(
        {
          message: "Create Template from Programming",
          onClick: () => {
            toggleIsEditing();
            if (
              props.activeConcept.conceptId ===
              "takeover-panel-zone-informations"
            )
              setActiveConcept("takeover-panel-communication");
            else if (props.activeConcept.conceptId === "xr-feature-keys")
              setActiveConcept("xr-communication");
          },
          disabled: conceptsAreLoading || !!isCreating,
        },
        {
          message: "Apply Template",
          onClick: toggleIsApplying,
          disabled: conceptsAreLoading,
        }
      );
    }

    return (
      <>
        <StyledMoreButton
          items={
            props.activeConcept.conceptId === "xr-feature-keys"
              ? moreMenuProgrammingActionItems.slice(1)
              : moreMenuProgrammingActionItems
          }
        />
        {sendAllProgrammingConfirmModalIsOpen ? (
          <ConfirmActionModal
            onConfirm={() => {
              sendAllProgramming();
              setSendAllProgrammingConfirmModalIsOpen(false);
            }}
            onCancel={() => setSendAllProgrammingConfirmModalIsOpen(false)}
            confirmButtonTitle="Send"
            cancelButtonTitle="Cancel"
            message="This will send all programming to the panel and may take several minutes. Are you sure?"
          />
        ) : null}
        {isApplying && (
          <ApplyTemplateModal
            onCancel={toggleIsApplying}
            onSave={(templateId) => props.applyTemplate(relayEnv, templateId)}
            customerId={props.customerId}
            hardwareModel={props.hardwareModel as PanelHardwareModel}
          />
        )}
      </>
    );
  }
);

function Form(props: {
  setActiveConcept: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}) {
  const { children } = props;
  const { setSearchValues } = useSearchContext();
  const [invalidFields, setInvalidFields] = React.useState<InvalidFieldsType>(
    () => new Map()
  );

  const sendAllChanges = useSendAllChanges();

  return (
    <>
      <form
        id={FORM_ID}
        noValidate
        onSubmit={(event: React.ChangeEvent<HTMLFormElement>) => {
          event.preventDefault();
          // Need to clear all concepts' search so if there's an error, it can properly point to it.
          setSearchValues(new Map());
          sendAllChanges();
        }}
      >
        <InvalidFieldsContextProvider
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        >
          {React.useMemo(() => children, [children])}
        </InvalidFieldsContextProvider>
      </form>
    </>
  );
}

function SendAllChangesButton() {
  const disabled = useConceptsAreLoading();
  const { isSaving, isCreating, isApplying } = useTemplateContext();
  const conceptsHaveChanges = useConceptsHaveChanges();
  return !isSaving && !isCreating && !isApplying ? (
    <FadeInOut visible={conceptsHaveChanges}>
      <PrimaryButton disabled={disabled} onDoubleClick={() => null}>
        Send All Changes
      </PrimaryButton>
    </FadeInOut>
  ) : null;
}

const PreProgrammingApplyTemplateButton = React.memo(
  (props: {
    customerId: string;
    applyTemplate: ApplyTemplateFunc;
    hardwareModel: string;
  }) => {
    const disabled = useConceptsAreLoading();
    const relayEnv = useRelayEnvironment();
    const { isApplying, toggleIsApplying } = useTemplateContext();
    return (
      <>
        <StyledPreProgrammingButton
          type="button"
          onClick={() => toggleIsApplying()}
          disabled={disabled}
        >
          Apply Template
        </StyledPreProgrammingButton>

        {isApplying && (
          <ApplyTemplateModal
            onCancel={toggleIsApplying}
            onSave={(templateId) => props.applyTemplate(relayEnv, templateId)}
            customerId={props.customerId}
            hardwareModel={props.hardwareModel as PanelHardwareModel}
          />
        )}
      </>
    );
  }
);

function PreProgrammingSaveButton() {
  const disabled = useConceptsAreLoading();
  const saveAllProgramming = useSaveAllProgramming();
  return (
    <>
      <StyledPreProgrammingButton
        type="button"
        onClick={() => {
          saveAllProgramming();
        }}
        disabled={disabled}
      >
        Save to Dealer Admin
      </StyledPreProgrammingButton>
    </>
  );
}

function PreProgrammingSendButton({
  systemId,
  hardwareModel,
}: {
  systemId: string;
  hardwareModel: string;
}) {
  const disabled = useConceptsAreLoading();
  const bringPanelOnlineAndSendChanges = useBringPanelOnlineAndSendChanges(
    systemId,
    hardwareModel
  );
  const programmingContext = useProgrammingActionsContext();
  const saveAllProgramming = useSaveAllProgramming();
  const showAlert = useShowAlert();
  return (
    <>
      {programmingContext.isSendingPreProgramming ? (
        <PreProgrammingBanner>
          <LoadingSpinner /> Sending Programming to System
        </PreProgrammingBanner>
      ) : (
        <>
          <StyledPreProgrammingButton
            type="button"
            onClick={() => {
              saveAllProgramming()
                .then(() => {
                  bringPanelOnlineAndSendChanges();
                })
                .catch(() => {
                  programmingContext.setIsSendingPreProgramming(false);
                  showAlert({
                    type: "error",
                    text: "The programming failed to save before attempting initial connection.",
                  });
                });
            }}
            disabled={disabled}
          >
            Send To System
          </StyledPreProgrammingButton>
        </>
      )}
    </>
  );
}

export function FullProgrammingFormShell(props: {
  systemName: string;
  concepts: Concept[];
  headerRight?: React.ReactNode;
  subheader?: React.ReactNode;
  navButtons: NavButtonElement[];
  renderFields?: boolean;
  panelInPreProgramming?: boolean;
}) {
  const [activeConcept, setActiveConcept] =
    React.useContext(ActiveConceptContext);
  const initialActiveConcept = React.useRef(activeConcept).current;
  const conceptWrappers = React.useRef<{
    [conceptId: string]: HTMLElement | null;
  }>({});

  React.useLayoutEffect(() => {
    if (activeConcept !== initialActiveConcept) {
      const conceptWrapper = conceptWrappers.current[activeConcept];
      if (conceptWrapper) {
        const topbarHeight =
          document.getElementById("js-topnavbar")?.offsetHeight ?? 0;
        const { top } = conceptWrapper.getBoundingClientRect();
        if (top - topbarHeight < 0) {
          window.scrollTo({ top: topbarHeight });
        }
      }
    }
  }, [initialActiveConcept, activeConcept]);

  const { editingFrom, templateName, isLoadingEditPage } = useTemplateContext();

  return (
    <Form setActiveConcept={setActiveConcept}>
      <Header>
        <LeftContent>
          {editingFrom === EditingFrom.NEW_TEMPLATE_PAGE ? (
            <Title>New Template</Title>
          ) : editingFrom === EditingFrom.EDIT_TEMPLATE_PAGE ? (
            <Title>{templateName} - Edit</Title>
          ) : (
            <Title>{props.systemName} - Programming</Title>
          )}
          {props.panelInPreProgramming &&
            editingFrom === EditingFrom.PROGRAMMING_PAGE && (
              <PreProgrammingPill>
                <strong>PRE-PROGRAMMING</strong>
              </PreProgrammingPill>
            )}
        </LeftContent>
        <RightContent>{props.headerRight}</RightContent>
      </Header>

      {props.subheader}

      <Concepts>
        <MobileConceptSelect
          value={activeConcept}
          onChange={(event) => {
            setActiveConcept(event.target.value);
          }}
        >
          {props.concepts.map((concept, index) => {
            const { itemsCount, hasNewItems } = props.navButtons[index].props;

            return (
              <ProgrammingConceptOptions
                key={concept.conceptId}
                conceptId={concept.conceptId}
                title={concept.title}
                hasNewItems={hasNewItems}
                itemsCount={itemsCount}
              />
            );
          })}
        </MobileConceptSelect>
        <ConceptsSidebar>{props.navButtons}</ConceptsSidebar>
        <ConceptsWrapper>
          {props.concepts.map(({ conceptId, title, Form }) => {
            const fallback = (
              <ProgrammingConceptFormFallback
                conceptId={conceptId}
                title={title}
              />
            );
            return (
              <ConceptWrapper
                visible={activeConcept === conceptId}
                key={conceptId}
                ref={(node) => {
                  conceptWrappers.current[conceptId] = node;
                }}
              >
                {props.renderFields && !isLoadingEditPage ? (
                  <React.Suspense fallback={fallback}>
                    <Form />
                  </React.Suspense>
                ) : (
                  fallback
                )}
              </ConceptWrapper>
            );
          })}
        </ConceptsWrapper>
      </Concepts>
    </Form>
  );
}

function FullProgrammingForm(props: {
  concepts: Concept[];
  navButtons: NavButtonElement[];
  systemName: string;
  applyTemplate: ApplyTemplateFunc;
}) {
  const [activeConcept] = React.useContext(ActiveConceptContext);
  const programmingActionsContext = useProgrammingActionsContext();
  const [dealer] = useDealerFragment<FullProgrammingForm_dealer$key>(
    graphql`
      fragment FullProgrammingForm_dealer on Dealer {
        id
        name
        ...ProgrammingTemplateForm_dealer
      }
    `
  );
  const [controlSystem] =
    useControlSystemFragment<FullProgrammingForm_controlSystem$key>(graphql`
      fragment FullProgrammingForm_controlSystem on ControlSystem {
        customer {
          id
        }
        panel {
          id
          online
          hardwareModel
        }
        id
      }
    `);

  const conceptsById = React.useMemo(
    () =>
      new Map(props.concepts.map((concept) => [concept.conceptId, concept])),
    [props.concepts]
  );

  const context = useProgrammingActionsContext();
  const ensureConnectionReady = useEnsureConnectionReady();
  const showAlert = useShowAlert();
  const setChangedProgrammingConcepts = useSetChangedProgrammingConcepts();

  const { editingFrom, templateId, toggleIsLoadingEditPage, toggleIsSaving } =
    useTemplateContext();

  const relayEnv = useRelayEnvironment();

  React.useLayoutEffect(() => {
    async function applyTemplate() {
      if (editingFrom === EditingFrom.EDIT_TEMPLATE_PAGE && templateId) {
        try {
          await props.applyTemplate(relayEnv, templateId);
        } catch {}
        toggleIsSaving();
        toggleIsLoadingEditPage();
      }
    }
    applyTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Modal for confirming abandon changes
  const createRootScopeListener = useCreateRootScopeListener();
  const daLoadingBar = useDaLoadingBar();
  const goToRoute = useGoToRoute();
  const [
    confirmAbandonProgrammingChangesModal,
    setConfirmAbandonProgrammingChangesModal,
  ] = React.useState(false);

  const toStateRef = React.useRef<any>();
  const toParamsRef = React.useRef<any>();
  const fromStateRef = React.useRef<any>();
  const conceptsHaveChanges = useConceptsHaveChanges();

  React.useEffect(() => {
    const listener = createRootScopeListener(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams) {
        toStateRef.current = toState;
        toParamsRef.current = toParams;
        fromStateRef.current = fromState;
        if (conceptsHaveChanges) {
          daLoadingBar.complete();
          if (
            !confirmAbandonProgrammingChangesModal &&
            toState.name !== "page.login" &&
            fromState.name !== "app.dealer.new_template" &&
            fromState.name !== "app.dealer.template" &&
            !isEditingTemplate
          ) {
            event.preventDefault();
            setConfirmAbandonProgrammingChangesModal(true);
          }
        }
      }
    );
    return () => listener();
  });

  //SendAllChangesEffect
  React.useEffect(() => {
    if (context.isSendingAllChanges) {
      validateBeforeSave(
        getFormElement(),
        async () => {
          await ensureConnectionReady();

          try {
            const results = await Promise.allSettled(
              Object.entries(context.programmingConcepts)
                .filter(
                  ([, { hasChanges, suppressAreaInfoSave }]) =>
                    hasChanges && !suppressAreaInfoSave
                )
                .map(([key, { onSave }]) =>
                  onSave().then((errorList) => [key, errorList] as const)
                )
            );

            const { resolved, rejected } = partitionFulfilled(results);

            const errors = resolved.reduce(
              (previousValue, currentValue) =>
                previousValue.concat(currentValue[1]),
              [] as SaveErrors
            );

            const containsConnectionTimeout = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.type)
              .includes("CONNECTION_TIMEOUT");

            const containsPanelBusy = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.type)
              .includes("PANEL_BUSY");

            const containsOutputFirmwareError = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.invalidField)
              .flatMap((error) => error?.reason)
              .includes(
                "This wireless device requires a firmware update and should not be installed in its current configuration. Please contact Technical Support at 888-436-7832 for advance replacement."
              );

            if (containsConnectionTimeout) {
              showAlert({
                type: "error",
                text: "Error Saving Programming to the System - Connection Timed Out.",
              });
            } else if (containsPanelBusy) {
              showAlert({
                type: "error",
                text: "Error Saving Programming to the System - Panel Busy.",
              });
            } else {
              context.setShowOutputSerialNumberError(
                containsOutputFirmwareError
              );
              if (Array.isArray(errors)) {
                errors.forEach((error) => {
                  showFieldErrorAlerts(
                    error.programmingConcept,
                    error.errors,
                    showAlert,
                    error.listItemNumber
                  );
                });
              }
            }

            if (resolved.length) {
              setChangedProgrammingConcepts(
                removeProgrammingConceptsFromChangedProgrammingConcepts(
                  resolved
                    .filter((concept) => !concept[1].length)
                    .map(([programmingConceptId]) => programmingConceptId)
                )
              );
            }

            if (!errors.length && !rejected.length) {
              showAlert({
                type: "success",
                text: "Programming Saved to the System",
              });
            }
          } catch (e) {
            console.error("Error 1", e);
            showAlert({
              type: "error",
              text: "Error Saving Programming to the System",
            });
          } finally {
            context.setIsSendingAllChanges(false);
          }
        },
        () => context.setIsSendingAllChanges(false),
        (validating) => context.setIsValidatingProgramming(validating)
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isSendingAllChanges]);

  // Effect to Save All Programming offline
  React.useEffect(() => {
    if (context.isSavingAllProgramming) {
      validateBeforeSave(
        getFormElement(),
        async () => {
          try {
            const results = await Promise.allSettled(
              Object.entries(context.programmingConcepts).map(
                ([key, { onSave }]) =>
                  onSave(false, true).then(
                    (response) => [key, response] as const
                  )
              )
            );
            const { resolved, rejected } = partitionFulfilled(results);

            const errors = resolved.reduce(
              (previousValue, currentValue) =>
                previousValue.concat(currentValue[1]),
              [] as SaveErrors
            );

            const containsConnectionTimeout = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.type)
              .includes("CONNECTION_TIMEOUT");

            const containsPanelBusy = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.type)
              .includes("PANEL_BUSY");

            const containsOutputFirmwareError = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.invalidField)
              .flatMap((error) => error?.reason)
              .includes(
                "This wireless device requires a firmware update and should not be installed in its current configuration. Please contact Technical Support at 888-436-7832 for advance replacement."
              );

            if (containsConnectionTimeout) {
              showAlert({
                type: "error",
                text: "Error Saving Programming to the System - Connection Timed Out.",
              });
            } else if (containsPanelBusy) {
              showAlert({
                type: "error",
                text: "Error Saving Programming to the System - Panel Busy.",
              });
            } else {
              context.setShowOutputSerialNumberError(
                containsOutputFirmwareError
              );
              if (Array.isArray(errors)) {
                errors.forEach((error) => {
                  showFieldErrorAlerts(
                    error.programmingConcept,
                    error.errors,
                    showAlert,
                    error.listItemNumber
                  );
                });
              }
            }
            if (resolved.length && !rejected.length) {
              setChangedProgrammingConcepts(
                removeProgrammingConceptsFromChangedProgrammingConcepts(
                  resolved.map(([programmingConceptId]) => programmingConceptId)
                )
              );
            }

            if (!errors.length) {
              showAlert({
                type: "success",
                text: "Programming Saved to the System",
              });
            }
          } catch (e) {
            console.error("Error 2", e);
            showAlert({
              type: "error",
              text: "Error Saving Programming to the System",
            });
          } finally {
            context.setIsSavingAllProgramming(false);
          }
        },
        () => context.setIsSavingAllProgramming(false),
        (validating) => context.setIsValidatingProgramming(validating)
      );
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isSavingAllProgramming]);

  // Effect to Send All Programming and come online if offline
  React.useEffect(() => {
    if (context.isSendingAllProgramming) {
      validateBeforeSave(
        getFormElement(),
        async () => {
          await ensureConnectionReady();
          try {
            const results = await Promise.allSettled(
              Object.entries(context.programmingConcepts).map(
                ([key, { onSave }]) =>
                  onSave(false, true).then(
                    (response) => [key, response] as const
                  )
              )
            );
            const { resolved, rejected } = partitionFulfilled(results);
            const errors = resolved.reduce(
              (previousValue, currentValue) =>
                previousValue.concat(currentValue[1]),
              [] as SaveErrors
            );

            const containsConnectionTimeout = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.type)
              .includes("CONNECTION_TIMEOUT");

            const containsPanelBusy = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.type)
              .includes("PANEL_BUSY");

            const containsOutputFirmwareError = errors
              .flatMap((error) => error.errors)
              .flatMap((error) => error.invalidField)
              .flatMap((error) => error?.reason)
              .includes(
                "This wireless device requires a firmware update and should not be installed in its current configuration. Please contact Technical Support at 888-436-7832 for advance replacement."
              );

            if (containsPanelBusy) {
              showAlert({
                type: "error",
                text: "Error Saving Programming to the System - Panel Busy.",
              });
            } else if (containsConnectionTimeout) {
              showAlert({
                type: "error",
                text: "Error Saving Programming to the System - Connection Timed Out.",
              });
            } else {
              context.setShowOutputSerialNumberError(
                containsOutputFirmwareError
              );
              if (Array.isArray(errors)) {
                errors.forEach((error) => {
                  showFieldErrorAlerts(
                    error.programmingConcept,
                    error.errors,
                    showAlert,
                    error.listItemNumber
                  );
                });
              }
            }

            if (resolved.length) {
              setChangedProgrammingConcepts(
                removeProgrammingConceptsFromChangedProgrammingConcepts(
                  resolved
                    .filter((concept) => !concept[1].length)
                    .map(([programmingConceptId]) => programmingConceptId)
                )
              );
            }

            if (!errors?.length && !rejected.length) {
              showAlert({
                type: "success",
                text: "Programming Saved to the System",
              });
            }
          } catch (e) {
            console.error("Error 3", e);
            showAlert({
              type: "error",
              text: "Error Saving Programming to the System",
            });
          } finally {
            context.setIsSendingAllProgramming(false);
          }
        },
        () => context.setIsSendingAllProgramming(false),
        (validating) => context.setIsValidatingProgramming(validating)
      );
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isSendingAllProgramming]);

  // Effect to Send Concept Programming

  const setInvalidFields = useSetInvalidFields();
  React.useEffect(() => {
    if (context.isSendingConcept) {
      const { conceptId, title } = conceptsById.get(activeConcept)!;
      const conceptFieldset = document.getElementById(
        conceptId
      ) as HTMLFieldSetElement;
      if (conceptFieldset) {
        validateBeforeSave(
          conceptFieldset,
          async () => {
            await ensureConnectionReady();
            try {
              const actions = context.programmingConcepts[conceptId];
              const saveErrors = await actions.onSave(true);

              const containsConnectionTimeout = saveErrors
                .flatMap((error) => error.errors)
                .flatMap((error) => error.type)
                .includes("CONNECTION_TIMEOUT");

              const containsPanelBusy = saveErrors
                .flatMap((error) => error.errors)
                .flatMap((error) => error.type)
                .includes("PANEL_BUSY");

              if (!saveErrors.length) {
                setChangedProgrammingConcepts(
                  removeProgrammingConceptFromChangedProgrammingConcepts(
                    conceptId
                  )
                );
              } else if (containsPanelBusy) {
                showAlert({
                  type: "error",
                  text: `Error Saving ${title} - Panel Busy.`,
                });
              } else if (containsConnectionTimeout) {
                showAlert({
                  type: "error",
                  text: `Error Saving ${title} - Connection Timed Out.`,
                });
              } else {
                if (Array.isArray(saveErrors)) {
                  saveErrors.forEach((saveError) => {
                    showFieldErrorAlerts(
                      saveError.programmingConcept,
                      saveError.errors,
                      showAlert,
                      saveError.listItemNumber
                    );
                  });
                }
              }
              setInvalidFields(
                removeProgrammingConceptFromInvalidFields(conceptId)
              );
            } finally {
              context.setIsSendingConcept(false);
            }
          },
          () => context.setIsSendingConcept(false),
          (validating) => context.setIsValidatingProgramming(validating)
        );
      }
    } //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isSendingConcept]);

  const { isEditing: isEditingTemplate } = useTemplateContext();

  return (
    <>
      <FullProgrammingFormShell
        concepts={props.concepts}
        systemName={props.systemName}
        navButtons={props.navButtons}
        panelInPreProgramming={!controlSystem?.panel?.online}
        headerRight={
          isEditingTemplate ? (
            <ProgrammingTemplateForm dealer={dealer} />
          ) : controlSystem.panel.online ? (
            <>
              <SendAllChangesButton />
              <OnlineMoreMenu
                activeConcept={conceptsById.get(activeConcept)!}
                customerId={controlSystem.customer.id}
                applyTemplate={props.applyTemplate}
                hardwareModel={controlSystem.panel.hardwareModel}
              />
            </>
          ) : (
            <>
              {!programmingActionsContext.isSendingPreProgramming &&
                ["TMS6", "XT75"].includes(
                  controlSystem.panel.hardwareModel
                ) && (
                  <PreProgrammingApplyTemplateButton
                    customerId={controlSystem.customer.id}
                    applyTemplate={props.applyTemplate}
                    hardwareModel={controlSystem.panel.hardwareModel}
                  />
                )}
              <PreProgrammingSendButton
                systemId={controlSystem.id}
                hardwareModel={controlSystem.panel.hardwareModel}
              />
              {!programmingActionsContext.isSendingPreProgramming && (
                <PreProgrammingSaveButton />
              )}
            </>
          )
        }
        renderFields
      />

      {confirmAbandonProgrammingChangesModal ? ( //Show this modal if the user tries to leave the page with unsaved programming changes
        <GenericConfirmModal
          header="Unsaved Programming Changes"
          confirmText="Stay on this Page"
          cancelText="Leave this Page"
          onConfirm={() => {
            toParamsRef.current = undefined;
            toStateRef.current = undefined;
            fromStateRef.current = undefined;
            setConfirmAbandonProgrammingChangesModal(false);
          }}
          onCancel={() => {
            return goToRoute(
              toStateRef.current.name,
              { ...toParamsRef.current },
              {
                reload: true,
              }
            );
          }}
        >
          Are you sure you want to leave this page? You have programming changes
          that have not been sent to the panel.
        </GenericConfirmModal>
      ) : null}

      {context.showOutputSerialNumberError ? ( //Needed this to be outside full programming so that it would show up on any page that returned this error
        <GenericConfirmModal
          onCancel={() => {
            context.setShowOutputSerialNumberError(false);
          }}
          icon={
            <Icon
              style={{ fontSize: "3rem", marginRight: "8px" }}
              name="attention"
            />
          }
          suppressConfirm
          cancelText="Close"
          includeHorizontalRule
          header={<span style={{ fontSize: "3rem" }}>Cannot Add Device</span>}
          children="This wireless device requires a firmware update and should not be installed in its current configuration. Please contact Technical Support at 888-436-7832 for advance replacement."
        />
      ) : null}
    </>
  );
}

export default React.memo(FullProgrammingForm);

export function ProgrammingConceptSidebarButton(props: {
  conceptId: string;
  title: string;
  hasNewItems?: boolean;
  itemsCount?: number | null | undefined;
}) {
  const { conceptId, title, hasNewItems, itemsCount } = props;

  const [activeConcept, setActiveConcept] =
    React.useContext(ActiveConceptContext);

  const hasInvalidFields = useProgrammingConceptHasInvalidFields(conceptId);

  const hasChangedFields = useProgrammingConceptHasChanges(conceptId);

  const conceptActions =
    useProgrammingActionsContext().programmingConcepts[conceptId];
  const isLoading = conceptActions.isSaving || conceptActions.isRetrieving;
  const { isEditing } = useTemplateContext();

  return (
    <SidebarSubnav.Button
      as="button"
      selected={activeConcept === conceptId}
      onClick={() => {
        setActiveConcept(conceptId);
      }}
      indicator={
        isLoading || isEditing
          ? null
          : hasInvalidFields
          ? "WARNING"
          : hasChangedFields || hasNewItems
          ? "INFO"
          : undefined
      }
      count={itemsCount}
    >
      <SidebarSubnav.Button.Text>
        {title}
        {isLoading && (
          <>
            {"    "}
            <LoadingSpinner />
          </>
        )}
      </SidebarSubnav.Button.Text>
    </SidebarSubnav.Button>
  );
}

function ProgrammingConceptOptions(props: {
  conceptId: string;
  title: string;
  hasNewItems?: boolean;
  itemsCount?: number | null | undefined;
}) {
  const { conceptId, title, itemsCount } = props;

  return (
    <option value={conceptId}>
      {title}
      {isNotNullOrUndefined(itemsCount) ? ` (${itemsCount})` : null}
    </option>
  );
}
