/**
 * @generated SignedSource<<91caea9f17a88ac4c49e3665224b0e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type TMSentrySystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly systemReports: {
    readonly abortReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly accessKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ambushReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly bypassReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly codeChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly earlyToClose: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryCheckinProtection: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lateToOpen: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly openCloseEnable: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly scheduleChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly sendStoredMessage: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly videoSystemReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneRestoralReports: {
      readonly data: SystemReportsZoneRestoralReports | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentrySystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TMSentrySystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentrySystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentrySystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentrySystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "28fb8ad112c8e41b55f5ede260da489d";

export default node;
