/**
 * @generated SignedSource<<02b2b94f4377ba9e6916b013f5038e84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationBackupCellField_communication$data = {
  readonly backupCell: boolean;
  readonly comType: CommunicationComType;
  readonly " $fragmentType": "CommunicationBackupCellField_communication";
};
export type CommunicationBackupCellField_communication$key = {
  readonly " $data"?: CommunicationBackupCellField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationBackupCellField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationBackupCellField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backupCell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "477faec51f4caa9331baf528ec6a7f36";

export default node;
