/**
 * @ngdoc service
 * @name App.factory:ReportService
 *
 * @description
 * API factory for Report Data
 *
 */
App.factory("ReportService", [
  "$q",
  "$filter",
  "PROPS",
  "PanelODataAPI",
  "DealerODataAPI",
  "DealerODataEventReportsAPI",
  "UserService",
  "UsersReportAPI",
  "ProfilesReportAPI",
  "EventsReportAPI",
  "OdataPageService",
  function (
    $q,
    $filter,
    PROPS,
    PanelODataAPI,
    DealerODataAPI,
    DealerODataEventReportsAPI,
    UserService,
    UsersReportAPI,
    ProfilesReportAPI,
    EventsReportAPI,
    OdataPageService
  ) {
    return {
      /**
       * Attach the filters object to this service, for convenience
       */
      filters: {},

      // Cheap way to carry areas from report Config page to report Display page, saving a server hit.
      areas: undefined,

      /**
       * Gets the Panel info
       * @param panelID
       * @returns {*}
       */
      getPanel: function (panelID) {
        var deferred = $q.defer();
        var _this = this;
        PanelODataAPI.getPanel(
          { panel_id: panelID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the Profiles for a specific panel
       * @param panelID
       * @returns {*}
       */
      getProfiles: function (panelID) {
        var deferred = $q.defer();
        var _this = this;
        PanelODataAPI.getProfiles(
          { panel_id: panelID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the Users Report data for a specific panel
       * @note uses the this.filters object as it's POST body.
       * @returns {*}
       */
      getUsersReport: function () {
        var deferred = $q.defer();
        var _this = this;

        // First, get the panel information
        _this
          .getPanel(_this.filters.panel_id)
          .then(function (panelData) {
            // Panel data is available here
            const isXF6Family = panelData.value[0].hardware_family === "XF6";
            // Now, call the UsersReportAPI.getReport function using _this.filters
            UsersReportAPI.getReport(
              _this.filters,
              function (data) {
                // Success
                deferred.notify({
                  job_uuid: "n/a",
                  status: "success",
                  poll_count: 0,
                });
                delete _this.filters;
                // Modify the data based on the panel family (XF6 or not)
                if (isXF6Family) {
                  angular.forEach(data, function (value, key) {
                    if (value.profile1) {
                      value.profile1 === 1
                        ? (value.user_level = "STANDARD")
                        : (value.user_level = "MASTER");
                    }
                  });
                }

                deferred.resolve(data);
              },
              function (error) {
                // Failure
                deferred.notify({
                  job_uuid: "n/a",
                  status: "error",
                  poll_count: 0,
                });
                delete _this.filters;
                deferred.reject(error);
              }
            );
          })
          .catch(function (error) {
            // Handle the error from getPanel if it fails
            deferred.reject(error);
          });

        return deferred.promise;
      },

      /**
       * Gets the User Codes for a specific panel
       * @param panelID
       * @returns {*}
       */
      getUserCodes: function (panelID) {
        var deferred = $q.defer();
        var _this = this;
        PanelODataAPI.getUserCodes(
          { panel_id: panelID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the event subcategories for a specific panel
       * @param panelID
       * @returns {*}
       */
      getFilteredEventSubcategories: function (panelID) {
        var deferred = $q.defer();
        var _this = this;
        PanelODataAPI.getFilteredEventSubcategories(
          { panel_id: panelID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the areas for a specific panel
       * @param panelID
       * @returns {*}
       */
      getAreas: function (panelID) {
        var deferred = $q.defer();
        var _this = this;
        PanelODataAPI.getAreas(
          { panel_id: panelID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the zones for a specific panel
       * @param panelID
       * @returns {*}
       */
      getZones: function (panelID) {
        var deferred = $q.defer();
        var _this = this;
        PanelODataAPI.getZones(
          { panel_id: panelID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the Events Report data for a specific panel
       * @note uses the this.filters object as it's POST body.
       * @returns {*}
       */
      getEventsReport: function (dealerId) {
        var deferred = $q.defer();
        var _this = this;

        var queryFilter = "panel_id eq " + _this.filters.panel_id + " and ";

        //if the filter arrays have any items in them, they need to be grouped inside parentheses
        if (_this.filters.areas && _this.filters.areas.length > 0) {
          queryFilter = queryFilter + "( ";
          angular.forEach(_this.filters.areas, function (area) {
            queryFilter =
              queryFilter + "event_detail_area_number eq " + area + " or ";
          });
          queryFilter = queryFilter.replace(/ or $/, " ) and ");
        }

        if (_this.filters.devices && _this.filters.devices.length > 0) {
          queryFilter = queryFilter + "( ";
          angular.forEach(_this.filters.devices, function (device) {
            queryFilter =
              queryFilter + "event_detail_device_number eq " + device + " or ";
          });
          queryFilter = queryFilter.replace(/ or $/, " ) and ");
        }

        if (_this.filters.outputs && _this.filters.outputs.length > 0) {
          queryFilter = queryFilter + "( ";
          angular.forEach(_this.filters.outputs, function (output) {
            queryFilter =
              queryFilter + "event_detail_output_number eq " + output + " or ";
          });
          queryFilter = queryFilter.replace(/ or $/, " ) and ");
        }

        if (_this.filters.users && _this.filters.users.length > 0) {
          queryFilter = queryFilter + "( ";
          angular.forEach(_this.filters.users, function (user) {
            queryFilter =
              queryFilter + "event_detail_user_number eq " + user + " or ";
          });
          queryFilter = queryFilter.replace(/ or $/, " ) and ");
        }

        if (_this.filters.zones && _this.filters.zones.length > 0) {
          queryFilter = queryFilter + "( ";
          angular.forEach(_this.filters.zones, function (zone) {
            queryFilter =
              queryFilter + "event_detail_zone_number eq " + zone + " or ";
          });
          queryFilter = queryFilter.replace(/ or $/, " ) and ");
        }

        if (
          _this.filters.subcategory_ids &&
          _this.filters.subcategory_ids.length > 0
        ) {
          queryFilter = queryFilter + "( ";
          angular.forEach(
            _this.filters.subcategory_ids,
            function (subcategory_id) {
              queryFilter =
                queryFilter + "subcategory_id eq " + subcategory_id + " or ";
            }
          );
          queryFilter = queryFilter.replace(/ or $/, "");
          queryFilter = queryFilter + " ) and ";
        }

        if (_this.filters.event_date_low)
          queryFilter =
            queryFilter +
            "event_event_at gt " +
            _this.filters.event_date_low +
            " and ";
        if (_this.filters.event_date_high)
          queryFilter =
            queryFilter +
            "event_event_at lt " +
            _this.filters.event_date_high +
            " and ";

        queryFilter = queryFilter.replace(/ and $/, "");
        var queryParams = {};
        queryParams.dealer_id = dealerId;
        queryParams.$filter = queryFilter;
        queryParams.$select =
          "subcategory_display_name, event_history_message, event_event_at, event_created_at";

        var promise =
          DealerODataEventReportsAPI.getReportEvents(queryParams).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Gets the Profiles Report data for a specific panel
       * @note uses the this.filters object as it's POST body.
       * @returns {*}
       */
      getProfilesReport: function () {
        var deferred = $q.defer();
        var _this = this;
        ProfilesReportAPI.getReport(
          _this.filters,
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            if (_this.areas) {
              deferred.resolve(_this.mergeProfilesAndAreas(data, _this.areas));
            } else {
              deferred.reject();
            }
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            delete _this.filters;
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      mergeProfilesAndAreas: function (profiles, areas) {
        var newProfiles = [];
        angular.forEach(profiles, function (profile, key) {
          // If no access_areas, then no need to merge in the areas to this profile
          if (profile.access_areas.length == 0) {
            newProfiles.push({
              name: profile.name,
              number: profile.number,
              access_area_number: null,
              access_area_name: null,
            });
          } else {
            angular.forEach(profile.access_areas, function (areaNumber, key) {
              matchingArea = $filter("filter")(areas, function (a) {
                return a.record_number === areaNumber;
              })[0];
              newProfiles.push({
                name: profile.name,
                number: profile.number,
                access_area_number: areaNumber,
                access_area_name:
                  matchingArea == undefined ? null : matchingArea.name,
              });
            });
          }
        });
        return newProfiles;
      },

      /**
       * Handle any errors
       * @param errorData
       */
      handleError: function (errorData) {
        if (angular.isUndefined(this.errors)) {
          this.errors = {};
        }
        angular.extend(this.errors, errorData.data.errors);
      },
    };
  },
]);
