/**
 * @generated SignedSource<<d98ca0eb435fbb179b54cd312efbbb2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
export type DualAuthorityEnum = "ALL" | "ARM" | "DISARM" | "NO";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly areaInformations: ReadonlyArray<{
    readonly armFirstArea: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly autoArmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly autoDisarmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly badZonesOption: {
      readonly data: BadZonesOption | null;
      readonly included: boolean | null;
    } | null;
    readonly bankSafeVault: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cardPlusPin: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly commonArea: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dualAuthority: {
      readonly data: DualAuthorityEnum | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lateArmDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly lateOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly openClosingReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly systemAreaInformation: {
    readonly anyBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly areaSchedules: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCheck: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly morningAmbush: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly systemOptions: {
    readonly included: boolean;
    readonly systemType: {
      readonly data: SystemType | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "207f687b8ac382755f244fe9ae24cb53";

export default node;
