/**
 *
 * TransitionViewContext
 * @author Chad Watson
 *
 *
 */
import { createContext } from "react";
export default createContext({
  animating: false,
  active: false
});
