/**
 * @generated SignedSource<<3a9a8f15b2f75a8a1274be824b63a386>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTMessagingSetupProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly messagingSetup: {
      readonly enableMessaging: boolean;
      readonly firstEmailOrPhoneNumber: string;
      readonly id: string;
      readonly secondEmailOrPhoneNumber: string;
      readonly sendSmsOnOpeningAndClosing: boolean;
      readonly systemName: string;
      readonly thirdEmailOrPhoneNumber: string;
      readonly userNumberForFirstContact: string;
      readonly userNumberForSecondContact: string;
      readonly userNumberForThirdContact: string;
    } | null;
  };
  readonly " $fragmentType": "XTMessagingSetupProgrammingConceptFormInline_controlSystem";
};
export type XTMessagingSetupProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTMessagingSetupProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTMessagingSetupProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTMessagingSetupProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "4ce84f0acf1add36eed87a723fd9bd37";

export default node;
