/**==================================================================
 * Large Chart Directive - version 2
 ====================================================================*/
/**
 * @ngdoc directive
 * @name App.directive:chart2
 * @function
 * @author  Way
 *
 * @description
 * Renders a large chart in the view where it is used. This Directive uses data from the
 * calling DOM element to which the directive is applied.
 * This directive renders multiple charts based on supplied configuration parameters and chart data.
 */
App.directive("daChart2", [
  "$timeout",
  "$window",
  "PROPS",
  function ($timeout, $window, PROPS) {
    "use strict";

    return {
      restrict: "A",
      controller: function ($scope, $element) {
        var runChart = function () {
          initChart($scope, $element);
        };
        $timeout(runChart);
      },
    };

    function initChart($scope, $element) {
      var options = $element.data();

      if (!options.dataset || !options.chartOptions) {
        // Check to see if the chart type is supplied via html options data
        return;
      }

      $scope.$watch("chartOptions", function (theOptions) {
        // needs to watch a scope variable, not an html attribute
        if (!$scope.currentDataset[0].data || !$scope.chartOptions) {
          return;
        }
        $.plot($element, $scope.currentDataset, $scope.chartOptions);
        $element.show();
      });

      $scope.$watch("currentChartDataSegment", function (theOptions) {
        if (!$scope.currentDataset[0].data || !$scope.chartOptions) {
          return;
        }
        $.plot($element, $scope.currentDataset, $scope.chartOptions);
        $element.show();
      });

      $element.bind("plotclick", function (event, pos, item) {
        if (item) {
          $scope.jumpToHourlyView(item.datapoint[0]);
          $scope.$apply();
        }
      });
    }
  },
]);
