/**
 * @generated SignedSource<<6802fc79c3780d3fa4b850721cbe1a00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type TMSentryAreaInformationProgrammingConceptForm_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly copiedArea: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly areas: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly cursor: string | null;
        readonly node: {
          readonly autoArmEnabled: boolean;
          readonly autoDisarmEnabled: boolean;
          readonly badZonesOption: BadZonesOption;
          readonly hasChanges: boolean;
          readonly id: string;
          readonly isNew: boolean;
          readonly maxNameLength: number;
          readonly name: string;
          readonly number: string;
          readonly " $fragmentSpreads": FragmentRefs<"AreaAutoArmField_area" | "AreaAutoDisarmField_area" | "AreaBadZonesField_area" | "AreaContext_area" | "AreaNameField_area" | "AreaNumberField_area">;
        } | null;
      }>;
      readonly maxNumberOfAreas: number;
      readonly minNumberOfAreas: number;
    };
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly systemOptions: {
      readonly systemType?: SystemType;
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextHouseCode_systemOptions" | "SystemOptionsContextIsAreaSystem_systemOptions" | "SystemOptionsContextSystemType_systemOptions" | "SystemOptionsContext_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AreaNumberField_panel" | "PanelContext_panel" | "SystemOptionsSystemTypeField_panel">;
  };
  readonly " $fragmentType": "TMSentryAreaInformationProgrammingConceptForm_controlSystem";
};
export type TMSentryAreaInformationProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: TMSentryAreaInformationProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryAreaInformationProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryAreaInformationProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SystemOptionsSystemTypeField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AreaNumberField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 32
            },
            {
              "kind": "Literal",
              "name": "sort",
              "value": {
                "keys": [
                  "number"
                ],
                "order": "ASC"
              }
            }
          ],
          "concreteType": "AreaConnection",
          "kind": "LinkedField",
          "name": "areas",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AreaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "autoArmEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "badZonesOption",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "autoDisarmEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isNew",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasChanges",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "maxNameLength",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AreaContext_area"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AreaNumberField_area"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AreaNameField_area"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AreaAutoArmField_area"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AreaAutoDisarmField_area"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AreaBadZonesField_area"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxNumberOfAreas",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minNumberOfAreas",
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": "areas(first:32,sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "systemType",
                  "storageKey": null
                }
              ],
              "type": "XtSystemOptions",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContext_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextHouseCode_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextIsAreaSystem_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "copiedArea",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "4d1e0dd35a47e0df04c8bc4e7c93919c";

export default node;
