import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Wrapper = styled.div<Props>`
  ${(props) =>
    props.horizontal && `& > * + * { margin-left: ${props.horizontal} }`}
  ${(props) => props.vertical && `& > * + * { margin-top: ${props.vertical} }`}
`;

type Props = {
  horizontal?: string;
  vertical?: string;
};

const CleanSpaceBetween = ({
  horizontal,
  vertical,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Wrapper horizontal={horizontal} vertical={vertical}>
      {children}
    </Wrapper>
  );
};

export default CleanSpaceBetween;
