/**
 * @generated SignedSource<<c1fc9a5eddecc252df7840d11620ff4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HolidayDateClass = "A" | "B" | "C";
export type SchedulesHolidayDatesTableQuery$variables = {
  systemId: string;
};
export type SchedulesHolidayDatesTableQuery$data = {
  readonly controlSystem: {
    readonly holidayDates?: ReadonlyArray<{
      readonly class: HolidayDateClass;
      readonly date: string;
      readonly description: string;
      readonly globalHolidayId: number | null;
      readonly id: string;
      readonly isGlobalHoliday: boolean;
      readonly name: string;
    } | null>;
    readonly id?: string;
  } | null;
};
export type SchedulesHolidayDatesTableQuery = {
  response: SchedulesHolidayDatesTableQuery$data;
  variables: SchedulesHolidayDatesTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "systemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "HolidayDate",
  "kind": "LinkedField",
  "name": "holidayDates",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "class",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isGlobalHoliday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalHolidayId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchedulesHolidayDatesTableQuery",
    "selections": [
      {
        "alias": "controlSystem",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchedulesHolidayDatesTableQuery",
    "selections": [
      {
        "alias": "controlSystem",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9a3cdb908a3df9b55c252c9c6a7b2f7",
    "id": null,
    "metadata": {},
    "name": "SchedulesHolidayDatesTableQuery",
    "operationKind": "query",
    "text": "query SchedulesHolidayDatesTableQuery(\n  $systemId: ID!\n) {\n  controlSystem: node(id: $systemId) {\n    __typename\n    ... on ControlSystem {\n      id\n      holidayDates {\n        id\n        name\n        description\n        date\n        class\n        isGlobalHoliday\n        globalHolidayId\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "760d0932c3019a610c03b37adc4d5618";

export default node;
