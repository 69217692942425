/**
 * @ngdoc object
 * @name EdisonAPI.factory:ResetPasswordAPI
 * @function reset Reset the specified user's password
 *
 * @description
 * Service to reset the password for the specified user
 * @param {string} email The email address of the user
 * @return {promise} A promise, which will eventually turn into a data response. Must be handled with success/failure blocks.
 */
App.factory("ResetPasswordAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    var resource = $resource(
      PROPS.apiUrl + "/1/users/reset_password/",
      {},
      {
        reset: { method: "GET", params: { email: "@email" } },
      }
    );
    return resource;
  },
]);
