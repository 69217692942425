/**
 * @ngdoc service
 * @name App.factory:ScheduledJobFactory
 *
 * @description
 * Generic scheduled job factory.
 *
 */
App.factory("ScheduledJobService", [
  "$rootScope",
  "$q",
  "$http",
  "PROPS",
  "$filter",
  "JobSchedulerService",
  "$interval",
  "UserService",
  "Customer",
  "AvailableUpdates",
  "AXBusService",
  function (
    $rootScope,
    $q,
    $http,
    PROPS,
    $filter,
    JobSchedulerService,
    $interval,
    UserService,
    Customer,
    AvailableUpdates,
    AXBusService
  ) {
    return {
      /**
       * Local constants to define which jobType and jobGroupType we are using for remote Update jobs.
       */
      JOB_TYPE_NAME: "MONITORED_SCAPIV2",
      JOB_GROUP_TYPE_NAME: "NO_OVERWATCH",

      /**
       * Creates a scheduled JobGroup to update the realtime status of the given device
       * @returns {Promise} a promise, which will eventually resolve the JSON data of the JobGroup that is created.
       */
      enableDeviceRealtimeStatus: function (
        panelId,
        device,
        realTimeStatus = "Y"
      ) {
        var _this = this;
        var deferred = $q.defer();
        var job = JobSchedulerService.getJobTemplate();
        var jobGroup = JobSchedulerService.getJobGroupTemplate();

        // Determine which door number to use (ax or lx)
        let doorNumber = device.lx_number ? device.lx_number : device.number;

        jobGroup.PanelId = panelId;
        jobGroup.GroupClass = "MEDIUM";
        job.JobDesc = "Device Realtime Status Update - " + realTimeStatus;
        job.PanelId = panelId;
        job.RemoteServer = PROPS.apiUrl;
        job.RemoteUrl =
          "/v2/panels/" + panelId + "/device_informations/" + device.number;
        let deviceJson = {};
        deviceJson.device_information = {};
        deviceJson.device_information.number = device.number;
        deviceJson.device_information.door_rts = realTimeStatus;
        if (
          device.lx_number &&
          AXBusService.AXBusNumbers.includes(parseInt(device.lx_number))
        )
          deviceJson.device_information.disp_areas = "";
        job.RemoteJson = angular.toJson(deviceJson);
        job.RemoteAction = "PUT";

        var jobGroupTypePromise = JobSchedulerService.getJobGroupTypeByName(
          this.JOB_GROUP_TYPE_NAME
        );
        var jobTypePromise = JobSchedulerService.getJobTypeByName(
          this.JOB_TYPE_NAME
        );

        $q.all([jobGroupTypePromise, jobTypePromise])
          .then(
            function (values) {
              jobGroup.SchedulerJobGroupTypeId = values[0].Id;
              job.SchedulerJobTypeId = values[1].Id;
              jobGroup.GroupData = angular.toJson({
                GroupDesc: "Device Realtime Status Update",
              });

              jobGroup.SchedulerJobs.push(job);
              JobSchedulerService.createJobGroup(jobGroup)
                .then(
                  function (data) {
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      disableDeviceRealtimeStatus: function (panelId, device) {
        var deferred = $q.defer();
        this.enableDeviceRealtimeStatus(panelId, device, "N").then(function () {
          deferred.resolve();
        });
        return deferred.promise;
      },
    };
    return remoteUpdateFactory;
  },
]);
