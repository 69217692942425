export const DMPTimezoneOffsetMap = {
  "0": 0,
  "-1": 1,
  "-2": 2,
  "-3": 3,
  "-4": 4,
  "-5": 5,
  "-6": 6,
  "-7": 7,
  "-8": 8,
  "-9": 9,
  "-10": 10,
  "-11": 11,
  "12": 12,
  "11": 13,
  "10": 14,
  "9": 15,
  "8": 16,
  "7": 17,
  "6": 18,
  "5": 19,
  "4": 20,
  "3": 21,
  "2": 22,
  "1": 23,
} as const;

export type DMPTimezoneOffsetOptions =
  typeof DMPTimezoneOffsetMap[keyof typeof DMPTimezoneOffsetMap];

export function getDMPTimezoneOffset(year: number = new Date().getFullYear()) {
  const offset = Math.floor(
    (getStandardTimezoneOffset(year) / 60) * -1
  ).toString() as keyof typeof DMPTimezoneOffsetMap;
  return DMPTimezoneOffsetMap[offset];
}

export function getStandardTimezoneOffset(
  year: number = new Date().getFullYear()
) {
  const January = new Date(year, 0, 1);
  const July = new Date(year, 6, 1);

  return Math.max(January.getTimezoneOffset(), July.getTimezoneOffset());
}
export const seconds = (amount: number) => amount * 1000;

export function getIsDST(year: number = new Date().getFullYear()) {
  const January = new Date(year, 0, 1);
  const July = new Date(year, 6, 1);

  return January.getTimezoneOffset() !== July.getTimezoneOffset();
}

export function formatDateString(dateTimeString: string): string {
  // Parse the date string into a Date object.
  const date = new Date(dateTimeString);

  // Get the year, month, day, hour, minute, and second from the Date object.
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  // Format the date and time into a string.
  const formattedDateTime = `${month}/${day}/${year} ${hour}:${minute}:${second}`;

  // Return the formatted date and time.
  return formattedDateTime;
}
