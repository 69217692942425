/**
 * @generated SignedSource<<4db8a99115404eced4d38006aaaf763f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReceiverTableReceiver_receiver$data = {
  readonly automationPrefix: string;
  readonly description: string | null;
  readonly id: string;
  readonly ip: string;
  readonly number: number;
  readonly port: number;
  readonly " $fragmentSpreads": FragmentRefs<"EditReceiverFormReceiver_receiver">;
  readonly " $fragmentType": "ReceiverTableReceiver_receiver";
};
export type ReceiverTableReceiver_receiver$key = {
  readonly " $data"?: ReceiverTableReceiver_receiver$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReceiverTableReceiver_receiver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReceiverTableReceiver_receiver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automationPrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "port",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditReceiverFormReceiver_receiver"
    }
  ],
  "type": "Receiver",
  "abstractKey": null
};

(node as any).hash = "001134516b2d542c019011e72efb5a98";

export default node;
