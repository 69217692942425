/**
 * @generated SignedSource<<05adf4216c45230e9e157811045f1d33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRCommPathFields_communicationPath$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathAlarmReportsField_communicationPath" | "CommPathAlarmSwitchoverField_communicationPath" | "CommPathCellApn2Field_communicationPath" | "CommPathCellApnField_communicationPath" | "CommPathCheckInMinutesField_communicationPath" | "CommPathCommTypeField_communicationPath" | "CommPathContext_communicationPath" | "CommPathDoorAccessReportsField_communicationPath" | "CommPathDuplicateAlarmsField_communicationPath" | "CommPathEncryptionField_communicationPath" | "CommPathEncryptionSchemaField_communicationPath" | "CommPathFailTestHoursField_communicationPath" | "CommPathFailTimeField_communicationPath" | "CommPathFirstPhoneNumberField_communicationPath" | "CommPathNumberField_communicationPath" | "CommPathOpenCloseUserReportsField_communicationPath" | "CommPathPanicTestField_communicationPath" | "CommPathProtocolField_communicationPath" | "CommPathReceiverIpAddressField_communicationPath" | "CommPathReceiverIpv6AddressField_communicationPath" | "CommPathReceiverPortField_communicationPath" | "CommPathRetryTimeField_communicationPath" | "CommPathSecondLinePrefixFor893AField_communicationPath" | "CommPathSecondPhoneNumberField_communicationPath" | "CommPathSendFailField_communicationPath" | "CommPathSendPathField_communicationPath" | "CommPathSubstitutionCodeField_communicationPath" | "CommPathSupervisoryTroubleReportsField_communicationPath" | "CommPathTestFrequencyDayField_communicationPath" | "CommPathTestFrequencyNumberField_communicationPath" | "CommPathTestFrequencyUnitField_communicationPath" | "CommPathTestReportField_communicationPath" | "CommPathTestTimeField_communicationPath" | "CommPathTypeField_communicationPath" | "CommPathUse893AField_communicationPath" | "CommPathUseCheckInField_communicationPath" | "CommPathUseIPv6Field_communicationPath">;
  readonly " $fragmentType": "XRCommPathFields_communicationPath";
};
export type XRCommPathFields_communicationPath$key = {
  readonly " $data"?: XRCommPathFields_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCommPathFields_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRCommPathFields_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathContext_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathNumberField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCommTypeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTypeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestReportField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestFrequencyNumberField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestFrequencyUnitField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathFirstPhoneNumberField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSecondPhoneNumberField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathUse893AField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathAlarmSwitchoverField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathAlarmReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSupervisoryTroubleReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathOpenCloseUserReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathDoorAccessReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathPanicTestField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSendFailField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSendPathField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathUseCheckInField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCheckInMinutesField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathFailTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathUseIPv6Field_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathReceiverIpAddressField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathReceiverIpv6AddressField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathReceiverPortField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathProtocolField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathRetryTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSubstitutionCodeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCellApnField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCellApn2Field_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathEncryptionField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathEncryptionSchemaField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathFailTestHoursField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSecondLinePrefixFor893AField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestFrequencyDayField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathDuplicateAlarmsField_communicationPath"
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "2f031b86ce60a1ec10fb877e06959227";

export default node;
