/**
 * @generated SignedSource<<5677f0b48eba6a76ee60275a24d20de0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagingSetupSystemNameField_messagingSetup$data = {
  readonly enableMessaging: boolean;
  readonly systemName: string;
  readonly " $fragmentType": "MessagingSetupSystemNameField_messagingSetup";
};
export type MessagingSetupSystemNameField_messagingSetup$key = {
  readonly " $data"?: MessagingSetupSystemNameField_messagingSetup$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagingSetupSystemNameField_messagingSetup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagingSetupSystemNameField_messagingSetup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableMessaging",
      "storageKey": null
    }
  ],
  "type": "MessagingSetup",
  "abstractKey": null
};

(node as any).hash = "8327e3f72761ba192d30d44a267d2b9c";

export default node;
