/**
 * @generated SignedSource<<bc00d2305fab1ee4a78384241e41d187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseZoneNumberRange_panel$data = {
  readonly hardwareModel: PanelHardwareModel;
  readonly softwareVersion: string;
  readonly systemOptions: {
    readonly useBuiltIn1100Wireless?: boolean;
  };
  readonly " $fragmentType": "PanelContextUseZoneNumberRange_panel";
};
export type PanelContextUseZoneNumberRange_panel$key = {
  readonly " $data"?: PanelContextUseZoneNumberRange_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseZoneNumberRange_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseZoneNumberRange_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hardwareModel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softwareVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "systemOptions",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "useBuiltIn1100Wireless",
              "storageKey": null
            }
          ],
          "type": "XtSystemOptions",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "19e3fa9f37ae9f66b2a0715d30a889d0";

export default node;
