import graphql from "babel-plugin-relay/macro";
import { Flex } from "components/DaStyledElements";
import { NumericInput } from "components/Inputs/NumericInput";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useMutation } from "react-relay";
import styled from "styled-components";
import { ForgiveUserModalMutation } from "./__generated__/ForgiveUserModalMutation.graphql";

const ForgiveUserLoadingSpinner = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ForgiveUserModalSpan = styled.div`
  font-size: 16px;
  margin-left: 10px;
  vertical-align: text-bottom;
  margin-right: 10px;
`;

function ForgiveUserModal({
  setModalOpen,
  timezoneOffset,
  panelId,
}: {
  setModalOpen: (modalOpen: boolean) => void;
  timezoneOffset: string;
  panelId: string;
}) {
  const [userCode, setUserCode] = React.useState("");
  const showAlert = useShowAlert();

  const [forgiveUser, isForgivingUser] = useMutation<ForgiveUserModalMutation>(
    graphql`
      mutation ForgiveUserModalMutation($id: String!, $userCode: String!) {
        forgiveUser(id: $id, userCode: $userCode) {
          ... on ForgiveUserSuccessPayload {
            __typename
          }
          ... on Error {
            type
          }
        }
      }
    `
  );

  const forgiveTheUser = (panelId: string, userCode: string) => {
    forgiveUser({
      variables: {
        id: panelId,
        userCode: userCode,
      },
      onCompleted: () => {
        showAlert({
          type: "success",
          text: "Forgive User Sent",
        });
      },
    });
  };

  return (
    <Modal
      className="modal-grid__max-width"
      size="medium"
      onClickOutside={() => setModalOpen(false)}
    >
      <Modal.Header>
        <span className="h1">Forgive User</span>
      </Modal.Header>
      <Modal.Body className="modal-grid__padding-bottom">
        {isForgivingUser && (
          <ForgiveUserLoadingSpinner>
            <div className="csspinner traditional" />
          </ForgiveUserLoadingSpinner>
        )}
        {!isForgivingUser && (
          <>
            <ForgiveUserModalSpan>User Number</ForgiveUserModalSpan>
            <label style={{ paddingTop: "3px" }} className="">
              <NumericInput
                type="text"
                id="userCode"
                name="userCode"
                validationMessage="Please Enter A User Code"
                pattern="^[0-9]*$"
                onChange={({ target }) => {
                  setUserCode(target.value);
                }}
                onBlur={({ target }) => {
                  setUserCode(target.value);
                }}
              />
              <span></span>
            </label>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Flex.Row align="right" justify="right">
          <button
            className="btn btn-default btn-md"
            type="button"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-default btn-md color-primary-400"
            type="button"
            disabled={!userCode}
            onClick={() => {
              forgiveTheUser(panelId, userCode);
              setModalOpen(false);
            }}
          >
            Confirm
          </button>
        </Flex.Row>
      </Modal.Footer>
    </Modal>
  );
}

export default ForgiveUserModal;
