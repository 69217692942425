import graphql from "babel-plugin-relay/macro";
import { addClassesIf } from "common/utils/web/css";
import {
  Input,
  Panel,
  Table,
  TableFooter,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import ExportTableButtons from "components/ExportTableButtons";
import { format } from "date-fns";
import { pipe } from "fp-ts/function";
import * as R from "ramda";
import * as React from "react";
import { useFragment } from "react-relay/hooks";
import { objectSearch } from "search-filterer";
import {
  TableHeaderSorting,
  TableHeaderSortingTextRight,
} from "./TableStyledComponents";
import { HistoryTableFragment$key } from "./__generated__/HistoryTableFragment.graphql";

enum SortKey {
  Date,
  CustomerName,
  Quantity,
  PurchaseTotal,
  PurchasedBy,
  Notes,
}

export default function HistoryTable({
  dealerRef,
}: {
  dealerRef: HistoryTableFragment$key;
}) {
  const data = useFragment(
    graphql`
      fragment HistoryTableFragment on Dealer {
        mobileCredentialsHistoryConnection {
          nodes {
            id
            date
            by
            amount
            notes
            customerName
            cost
          }
        }
      }
    `,
    dealerRef
  );

  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Descending,
    sortKey: SortKey.Date,
  });

  // break these out so changing page size doesn't cause it to re-search etc...
  const searchedEvents = React.useMemo(
    () =>
      pipe(data.mobileCredentialsHistoryConnection.nodes, (events) =>
        objectSearch(
          paginationState.search,
          ["customerName", "by", "notes"],
          events
        )
      ),
    [data.mobileCredentialsHistoryConnection.nodes, paginationState.search]
  );

  const sortedEvents = React.useMemo(
    () =>
      R.sort(
        (paginationState.sortOrder === SortOrder.Ascending
          ? R.ascend
          : R.descend)((event) => {
          switch (paginationState.sortKey) {
            case SortKey.Quantity:
              return event.amount;
            case SortKey.PurchasedBy:
              return event.by;
            case SortKey.CustomerName:
              return event.customerName;
            case SortKey.Notes:
              return event.notes;
            default:
              return new Date(event.date).valueOf();
          }
        }),
        searchedEvents
      ),
    [paginationState.sortKey, paginationState.sortOrder, searchedEvents]
  );

  const pagedEvents = React.useMemo(
    () =>
      R.splitEvery(paginationState.pageSize, sortedEvents)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, sortedEvents]
  );

  const maxPage = Math.ceil(sortedEvents.length / paginationState.pageSize);
  return (
    <Panel.Table>
      <TableHeader>
        <TableHeader.Left>
          <label className="sr-only" htmlFor="search-input"></label>
          <Input.TableSearch
            id="search-input"
            value={paginationState.search}
            onChange={(search) => {
              dispatch({
                type: ActionType.SetSearch,
                search,
              });
            }}
          />
        </TableHeader.Left>

        <TableHeader.Right>
          <ExportTableButtons
            title="Mobile Credentials"
            columnTitles={[
              "Date",
              "Customer",
              "Amount",
              "Cost",
              "By",
              "Notes/PO",
            ]}
            widthPercentages={[20, 15, 10, 10, 25, 20]}
            data={React.useMemo(
              () =>
                R.sort(
                  R.descend((event) => new Date(event.date).valueOf()),
                  data.mobileCredentialsHistoryConnection.nodes
                ).map((event) => [
                  format(new Date(event.date), "M/d/u h:mm a"),
                  event.customerName,
                  String(event.amount),
                  `$${String(event.cost.toFixed(2))}`,
                  event.by,
                  event.notes,
                ]),
              [data.mobileCredentialsHistoryConnection.nodes]
            )}
          />
        </TableHeader.Right>
      </TableHeader>
      <Table>
        <thead>
          <tr>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Date, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Date) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Date,
                  });
                }
              }}
            >
              Date
            </TableHeaderSorting>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [
                    paginationState.sortKey === SortKey.CustomerName,
                    "is-active",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.CustomerName) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.CustomerName,
                  });
                }
              }}
            >
              Customer
            </TableHeaderSorting>
            <TableHeaderSortingTextRight
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Quantity, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Quantity) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Quantity,
                  });
                }
              }}
            >
              Quantity
            </TableHeaderSortingTextRight>
            <TableHeaderSortingTextRight
              className={addClassesIf(
                [
                  [
                    paginationState.sortKey === SortKey.PurchaseTotal,
                    "is-active",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.PurchaseTotal) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.PurchaseTotal,
                  });
                }
              }}
            >
              Purchase Total
            </TableHeaderSortingTextRight>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [
                    paginationState.sortKey === SortKey.PurchasedBy,
                    "is-active",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.PurchasedBy) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.PurchasedBy,
                  });
                }
              }}
            >
              Purchased By
            </TableHeaderSorting>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Notes, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable "
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Notes) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Notes,
                  });
                }
              }}
            >
              Notes/PO
            </TableHeaderSorting>
          </tr>
        </thead>

        <tbody>
          {!R.isEmpty(pagedEvents) ? (
            pagedEvents.map((event) => (
              <tr key={event.id}>
                <td>{format(new Date(event.date), "M/d/u h:mm a")}</td>
                <td>{event.customerName ?? null}</td>
                <td className="text-right">{event.amount}</td>
                <td className="text-right">${event.cost.toFixed(2)}</td>
                <td>{event.by}</td>
                <td>{event.notes}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>No Activity</td>
            </tr>
          )}
        </tbody>
      </Table>
      <TableFooter>
        <TableFooter.Left></TableFooter.Left>
        <TableFooter.Center>
          <Paginate.Paginator
            state={paginationState}
            dispatch={dispatch}
            maxPage={maxPage}
          />
        </TableFooter.Center>
        <TableFooter.Right>
          <Paginate.ItemsPerPage state={paginationState} dispatch={dispatch} />
        </TableFooter.Right>
      </TableFooter>
    </Panel.Table>
  );
}
