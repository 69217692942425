import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { useVplexNumbers } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationVplexSerialNumberField_zone$key } from "./__generated__/ZoneInformationVplexSerialNumberField_zone.graphql";

export const ZONE_INFORMATION_VPLEX_SERIAL_NUMBER =
  "zone-information-vplex-serial-number-";

export const zoneInformationVplexSerialNumberFieldId = (number: string) =>
  ZONE_INFORMATION_VPLEX_SERIAL_NUMBER + number;

function ZoneInformationVplexSerialNumberField() {
  const [
    { serialNumber, number, wireless, competitorWireless },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationVplexSerialNumberField_zone$key>(
    graphql`
      fragment ZoneInformationVplexSerialNumberField_zone on Zone {
        number
        serialNumber
        wireless
        competitorWireless
      }
    `
  );

  const fieldId = zoneInformationVplexSerialNumberFieldId(String(number));
  const vplexZones = useVplexNumbers();
  const disabled =
    wireless ||
    competitorWireless ||
    !number ||
    !vplexZones.includes(Number(number));

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="V-Plex Serial Number"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={!disabled ? serialNumber ?? "" : ""}
        disabled={disabled}
        required={!disabled}
        maxLength={8}
        pattern="([A,0]0[0-9][0-9][0-9][0-9][0-9][0-9]|[A,0]10[0-3][0-9][0-9][0-9][0-9]|[A,0]104[0-7][0-9][0-9][0-9]|[A,0]1048[0-4][0-9][0-9]|[A,0]10485[0-6][0-9]|[A,0]104857[0-5])"
        inlineHelp="A0000001-A1048575 or 00000001-01048575"
        title="A0000001-A1048575 or 00000001-01048575"
        validationMessage="Must be a valid V-Plex serial number"
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "serialNumber");
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? "00000000" : target.value,
              "serialNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationVplexSerialNumberField;
