/**
 * @generated SignedSource<<75064a96c459a02a7ee00d65a1a60fd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneOutputAction = "FOLLOW" | "MOMENTARY" | "NONE" | "PULSE" | "STEADY";
export type ZoneSupervisionTime = "NONE" | "_1440_MIN" | "_240_MIN" | "_3_MIN" | "_60_MIN" | "_720_MIN";
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly zoneInformations: ReadonlyArray<{
    readonly armedOpenActionMessage: {
      readonly data: ZoneMessage | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOpenOutputAction: {
      readonly data: ZoneOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOpenOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedShortActionMessage: {
      readonly data: ZoneMessage | null;
      readonly included: boolean | null;
    } | null;
    readonly armedShortOutputAction: {
      readonly data: ZoneOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly armedShortOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly contactNumber: {
      readonly data: ZoneTransmitterContact | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly fastResponseEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly fireBellOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly firePanelSlaveInput: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly presignalKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly prewarnKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly realTimeStatusEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly retardDelayEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisionTime: {
      readonly data: ZoneSupervisionTime | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly type: {
      readonly data: ZoneType | null;
      readonly included: boolean | null;
    } | null;
    readonly wireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFZoneInformationProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "79fbc675d3a39a1e589e6afa327e3489";

export default node;
