import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import {
  ControlSystem,
  ErrorType,
  Grades,
  SecurityGrade,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import { ProgrammingConceptSidebarButton } from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { SecurityGradeContextProvider } from "../common/SecurityGradeFields/SecurityGradeContext";
import SecurityGradeField from "../common/SecurityGradeFields/SecurityGradeField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XTSecurityGradeProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XTSecurityGradeProgrammingConceptFormInline_controlSystem.graphql";
import { XTSecurityGradeProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTSecurityGradeProgrammingConceptFormInline_xtProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XTSecurityGradeProgrammingConceptFormSecurityGradeRefreshMutation,
} from "./__generated__/XTSecurityGradeProgrammingConceptFormSecurityGradeRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XTSecurityGradeProgrammingConceptFormSecurityGradeSendMutation,
} from "./__generated__/XTSecurityGradeProgrammingConceptFormSecurityGradeSendMutation.graphql";
import { XTSecurityGradeProgrammingConceptForm_controlSystem$key } from "./__generated__/XTSecurityGradeProgrammingConceptForm_controlSystem.graphql";

export const title = "Security Grade";
export const conceptId = "xt-security-grade";

export const getState = (
  controlSystem: XTSecurityGradeProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTSecurityGradeProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          securityGrade {
            id
            grade
            updatedAt
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XTSecurityGradeProgrammingConceptFormSecurityGradeRefreshMutation(
    $id: ID!
  ) {
    refreshSecurityGrade(id: $id) {
      ... on RefreshSecurityGradeSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XTSecurityGradeProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XTSecurityGradeProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshSecurityGrade, isRefreshing] =
    useMutation<XTSecurityGradeProgrammingConceptFormSecurityGradeRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshSecurityGrade({
          variables: { id },
          onCompleted: (response) => {
            if (response.refreshSecurityGrade.controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Security Grade Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSecurityGrade: {
                    __typename: response.refreshSecurityGrade.__typename,
                    controlSystem: getState(
                      response.refreshSecurityGrade.controlSystem
                    ),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                showAlert({
                  type: "error",
                  text: "Unable to Retrieve Security Grade",
                });
              }
              reject();
            }
          },
          onError: () => {
            if (showAlerts) {
              showAlert({
                type: "error",
                text: "Unable to Retrieve Security Grade Programming From The System",
              });
            }
            reject();
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XTSecurityGradeProgrammingConceptFormSecurityGradeSendMutation(
    $systemId: ID!
    $grade: Grades!
  ) {
    sendSecurityGrade(systemId: $systemId, grade: $grade) {
      ... on SendSecurityGradeSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XTSecurityGradeProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSecurityGradeErrorPayload {
        error: type
      }
    }
  }
`;
export const useSaveMutation = (props: {
  controlSystem: XTSecurityGradeProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [sendSecurityGrade, isSendingSecurityGrade] =
    useMutation<XTSecurityGradeProgrammingConceptFormSecurityGradeSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { securityGrade },
        } = getState(props.controlSystem);
        sendSecurityGrade({
          variables: {
            systemId,
            grade: securityGrade?.grade ?? Grades.GRADE_0,
          },
          onCompleted: (response) => {
            if (response.sendSecurityGrade.controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Security Grade Programming Sent to the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                sendMutationConcreteRequest,
                { id: systemId }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSecurityGrade: {
                    __typename: response.sendSecurityGrade.__typename,
                    controlSystem: getState(
                      response.sendSecurityGrade.controlSystem
                    ),
                  },
                });
              }
              resolve();
            } else if ("error" in response.sendSecurityGrade) {
              if (
                response.sendSecurityGrade.error ===
                ErrorType.CONNECTION_TIMEOUT
              ) {
                if (showAlerts) {
                  showAlert({
                    type: "error",
                    text: "Error Saving Security Grade - Connection Timed Out",
                  });
                }
              } else if (
                response.sendSecurityGrade.error === ErrorType.PANEL_BUSY
              ) {
                if (showAlerts) {
                  showAlert({
                    type: "error",
                    text: "Error Saving Security Grade - Panel Busy",
                  });
                }
              }
              reject();
            } else {
              if (showAlerts) {
                showAlert({
                  type: "error",
                  text: "Unable to Send Security Grade - Unknown Error",
                });
              }
              reject();
            }
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSendingSecurityGrade,
  ];
};

const readSecurityGradeTemplateData = (
  programmingTemplateConcepts: XTSecurityGradeProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTSecurityGradeProgrammingConceptFormInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        securityGrade {
          included
          grade {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).securityGrade ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XTSecurityGradeProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSecurityGradeTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const securityGradeRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "securityGrade",
      "SecurityGrade"
    ) as unknown as RecordProxy<SecurityGrade>;

    applyTemplateScalarDataToRecordProxy(
      securityGradeRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XTSecurityGradeProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XTSecurityGradeProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            id
            securityGrade {
              id
              grade
              updatedAt
              ...SecurityGradeContext_securityGrade
              ...SecurityGradeField_securityGrade
            }
          }
        }
      `
    );

  const {
    helpFiles: { programmingGuideUrl },
  } = controlSystem.panel;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <SecurityGradeContextProvider
        securityGrade={controlSystem.panel.securityGrade}
      >
        <ProgrammingConceptForm
          key={controlSystem.panel.securityGrade?.updatedAt}
          conceptId={conceptId}
          title={title}
          helpLink={`${programmingGuideUrl}#EN%2050131%20Grade`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.securityGrade
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <SecurityGradeField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </SecurityGradeContextProvider>
    </PanelContextProvider>
  );
}
