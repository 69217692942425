import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { CommunicationComType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationBackupDialerField_communication$key } from "./__generated__/CommunicationBackupDialerField_communication.graphql";

export const communicationBackupDialerFieldId = () =>
  "communication-backup-dialer";

function CommunicationBackupDialerField() {
  const [{ backupDialer, comType }, updateCommunication] =
    useCommunicationFragment<CommunicationBackupDialerField_communication$key>(
      graphql`
        fragment CommunicationBackupDialerField_communication on Communication {
          backupDialer
          comType
        }
      `
    );

  const fieldId = communicationBackupDialerFieldId();
  const label = "Backup Dialer";
  const disabled = [
    CommunicationComType.DD,
    CommunicationComType.CID,
    CommunicationComType.CELL,
    CommunicationComType.NONE,
  ].includes(comType as CommunicationComType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables backup messages on the dialer. Tries to send the message over dialer after 60 seconds of unsuccessful network communication."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        checked={backupDialer}
        disabled={disabled}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(!backupDialer, "backupDialer");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationBackupDialerField;
