/**
 * @generated SignedSource<<2fc310910359632d95de32b071ae3819>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseAreaList_panel$data = {
  readonly areas: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly cursor: string | null;
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly number: string;
      } | null;
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "PanelContextUseAreaList_panel";
};
export type PanelContextUseAreaList_panel$key = {
  readonly " $data"?: PanelContextUseAreaList_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseAreaList_panel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseAreaList_panel",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 32
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": {
            "keys": [
              "number"
            ],
            "order": "ASC"
          }
        }
      ],
      "concreteType": "AreaConnection",
      "kind": "LinkedField",
      "name": "areas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AreaEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "areas(first:32,sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "b2a8c3bab8b8a6cf9f366a10f7654dae";

export default node;
