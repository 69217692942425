import { getEntries } from "common/utils/universal/object";
import { ascend } from "ramda";
import { RecordProxy } from "relay-runtime";
import { ControlSystem, Panel } from "securecom-graphql/client";

type TemplateData<A> = {
  readonly included?: boolean | null | undefined;
} & {
  readonly [K in keyof A]: {
    readonly included: boolean;
    readonly data: string | number | boolean | null;
  };
};

export function applyTemplateScalarDataToRecordProxy<
  A extends {},
  B extends RecordProxy<A>,
  C extends TemplateData<A>
>(recordProxy: B, templateData: C) {
  getEntries(templateData).forEach(([fieldKey, fieldData]) => {
    if (typeof fieldData === "object" && fieldData?.included) {
      recordProxy.setValue(fieldData.data, fieldKey);
    }
  });
}

export function selectPanelRecordProxy(
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  return controlSystemRecordProxy.getOrCreateLinkedRecord(
    "panel",
    "Panel"
  ) as unknown as RecordProxy<Panel>; // The current TS types are wrong for getOrCreateLinkedRecord
}

export function indexRecordProxiesByNumber(
  recordProxies: RecordProxy<{ number: number | string }>[]
) {
  return new Map(
    recordProxies.map((recordProxy) => [
      Number(recordProxy.getValue("number")),
      recordProxy,
    ])
  );
}

export function toSortedListItemsArray(
  recordProxiesByNumber: Map<number, RecordProxy<{ number: number | string }>>
) {
  return [...recordProxiesByNumber.values()].sort(
    ascend((recordProxy) => Number(recordProxy.getValue("number")))
  );
}
