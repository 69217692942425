/**
 * @generated SignedSource<<9da2465e6e9927b334bdc7388953f91c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Grades = "GRADE_0" | "GRADE_2" | "GRADE_3";
import { FragmentRefs } from "relay-runtime";
export type SecurityGradeField_securityGrade$data = {
  readonly grade: Grades;
  readonly id: string;
  readonly " $fragmentType": "SecurityGradeField_securityGrade";
};
export type SecurityGradeField_securityGrade$key = {
  readonly " $data"?: SecurityGradeField_securityGrade$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecurityGradeField_securityGrade">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecurityGradeField_securityGrade",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grade",
      "storageKey": null
    }
  ],
  "type": "SecurityGrade",
  "abstractKey": null
};

(node as any).hash = "9569bddfc379e2cdfd52634e88d4a4fe";

export default node;
