import DaColors from "app/common/DaColors";
import DmpIcon from "common/components/DmpIcon";
import CleanDotsSpinner from "dealer-admin/src/components/DaStyledElements/CleanDotsSpinner";
import useTogglePlay from "dealer-admin/src/hooks/useTogglePlay";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

const ContentWrapper = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  background-color: ${DaColors.Black};
`;
const LoadingMessageWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: ${DaColors.Black};
  color: ${DaColors.White};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ConnectingMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
const Video = styled.video`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: hsla(0, 0%, 80%, 0.6);
  backdrop-filter: blur(0.4rem);
  padding: 0.6rem 0;
`;
const TimingWrapper = styled.div`
  padding-bottom: 0.4rem;
  display: flex;
`;
const VideoTime = styled.div`
  padding: 0 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
`;
const ProgressBarWrapper = styled.div`
  flex-grow: 1;
  cursor: pointer;
`;
const ProgressBarOuter = styled.div`
  margin-top: 0.8rem;
  height: 0.4rem;
  background-color: ${DaColors.White};
`;
const ProgressBarInner = styled.div<{ progressPercent: number }>`
  height: 0.4rem;
  width: ${(props) => `${props.progressPercent}%`};
  background-color: ${DaColors.Primary500};
`;
const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding 0 1rem;
`;
const PlaybackSpeedButtons = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  & > * + * {
    margin-left: 1rem;
  }
`;
const FlatButton = styled.button`
  background: none;
  border: none;
  padding: 0;
`;
const PlaybackSpeedText = styled.span<{ isSelected: boolean }>`
  font-weight: ${(props) => (props.isSelected ? "bold" : "inherit")};
`;
const PlayPauseSkipButtons = styled.div`
  display: flex;
  justify-content: center;
  & > * + * {
    margin-left: 1.6rem;
  }
`;
const FullscreenButtons = styled.div`
  display: flex;
  justify-content: end;
`;

type ClipPlayerProps = {
  videoUrl: string;
  autoPlay?: boolean;
  thumbnails?: JSX.Element;
  isFullscreen?: boolean;
  enterFullscreen?: () => void;
  exitFullscreen?: () => void;
  skipButtonsSupported?: boolean;
};

const SKIP_TIME_SECONDS = 15;

const ClipPlayer = ({
  videoUrl,
  autoPlay,
  thumbnails,
  isFullscreen,
  enterFullscreen,
  exitFullscreen,
  skipButtonsSupported = true,
}: ClipPlayerProps) => {
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [selectedVideoDuration, setSelectedVideoDuration] = useState(0);
  const [selectedVideoPlaybackSpeed, setSelectedVideoPlaybackSpeed] =
    useState("1");
  const [isPlaying, videoRef, togglePlay] = useTogglePlay(autoPlay);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadError, setIsLoadError] = useState(false);

  const progressBarRef = useRef<HTMLDivElement | null>(null);

  const onMetadataLoaded = (): void => {
    if (videoRef.current) {
      setSelectedVideoDuration(videoRef.current.duration);
      handleProgress();
      setPlaybackRate("1");
      if (isPlaying !== autoPlay) {
        togglePlay();
      }
    }
  };

  const onCanPlay = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoadError(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (videoRef.current) {
      setIsLoading(true);
      setIsLoadError(false);
      videoRef.current.onloadedmetadata = onMetadataLoaded;
      videoRef.current.oncanplay = onCanPlay;
      videoRef.current.onerror = onError;
    }
  }, [videoUrl]);

  /**
   * Skips forward X seconds
   */
  const forward = (): void => {
    skip((currentProgress: number) => {
      return currentProgress + SKIP_TIME_SECONDS;
    });
  };

  /**
   * Skips backwards X seconds
   */
  const back = (): void => {
    skip((currentProgress: number) => {
      return currentProgress - SKIP_TIME_SECONDS;
    });
  };

  /**
   * Skips based on what the changeTime argument dictates
   *
   * @param changeTime fn (number) => number
   */
  const skip = (changeTime: (currentProgress: number) => number): void => {
    if (videoRef.current) {
      videoRef.current.currentTime = changeTime(videoRef.current.currentTime);
    }
  };

  //Speeds in strings so we can have things like .X
  const playbackSpeeds = [".5", "1", "2", "5"];

  /**
   * Sets playbackRate for video
   *
   * @param speed
   */
  const setPlaybackRate = (speed: string): void => {
    if (videoRef.current) {
      videoRef.current.playbackRate = Number(speed);
      setSelectedVideoPlaybackSpeed(speed);
    }
  };

  /**
   * sets 'setProgress' based on the current video's progress
   */
  const handleProgress = (): void => {
    const duration = videoRef.current?.duration ?? 0;
    const currentTime = videoRef.current?.currentTime ?? 0;
    const progress = (currentTime / duration) * 100;
    setCurrentTime(currentTime);
    setProgress(progress);
  };

  /**
   * Formats duration of video
   *
   * @param seconds
   * @returns
   */
  const displayTiming = (seconds = 0): string => {
    // seconds can be NaN if the video fails to load
    if (isNaN(seconds)) {
      return "0:00";
    }

    const duration = moment.duration({ seconds });
    return `${duration.minutes()}:${duration
      .seconds()
      .toString()
      .padStart(2, "0")}`;
  };

  // Keeping these buttons off unless needed keeps the display clearer for Operators.
  // We don't support the buttons for V-6000 cameras because we can't just get a straightforward
  // mp4 download from them.
  const showSkipButtons: boolean =
    skipButtonsSupported &&
    !!videoRef.current &&
    videoRef.current.duration >= SKIP_TIME_SECONDS;

  const handleProgressBarClick = debounce(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const leftBound =
        progressBarRef.current?.getBoundingClientRect().left ?? 0;

      //If the mouse hasn't moved, or is not within the usable range of the Progress Bar,
      //don't do anything
      if (progressBarRef.current && e.pageX >= leftBound) {
        let clickedProgress = Math.floor(
          ((e.pageX - leftBound) / progressBarRef.current.offsetWidth) * 100
        );

        //If you click too close to the end of a very short clip,
        //the looping kicks very quickly and just causes the video to reload
        if (clickedProgress > 95 && !showSkipButtons) {
          clickedProgress = 95;
        }

        if (videoRef.current) {
          setProgress(clickedProgress);
          const newTime = videoRef.current.duration * (clickedProgress / 100);
          videoRef.current.currentTime = Number(newTime.toFixed(2));
        }
      }
    },
    50
  );

  return (
    <>
      <ContentWrapper>
        {(isLoading || isLoadError) && (
          <LoadingMessageWrapper>
            {isLoadError ? (
              <div>Video failed to load. Please try again.</div>
            ) : (
              <ConnectingMessage>
                <CleanDotsSpinner size={16} color={DaColors.White} />
                <div>Connecting...</div>
              </ConnectingMessage>
            )}
          </LoadingMessageWrapper>
        )}
        <Video
          key={videoUrl}
          onTimeUpdate={handleProgress}
          ref={videoRef}
          autoPlay={autoPlay}
          loop
          muted
          src={videoUrl}
        />
      </ContentWrapper>

      <ControlsWrapper>
        {/* Top Row - Progress Bar & Timing Container */}
        <TimingWrapper>
          <VideoTime>{displayTiming(currentTime)}</VideoTime>
          {/* This wrapper div allows the progress bar to have a larger click-able area */}
          <ProgressBarWrapper
            onClick={handleProgressBarClick}
            ref={progressBarRef}
          >
            <ProgressBarOuter>
              <ProgressBarInner progressPercent={progress} />
            </ProgressBarOuter>
          </ProgressBarWrapper>
          <VideoTime>{displayTiming(selectedVideoDuration)}</VideoTime>
        </TimingWrapper>
        {/* Second Row - controls and playback speed */}
        <ButtonsWrapper>
          <PlaybackSpeedButtons>
            {playbackSpeeds.map((speed) => (
              <FlatButton
                onClick={() => {
                  setPlaybackRate(speed);
                }}
                key={speed}
                title="Playback speed"
              >
                <PlaybackSpeedText
                  isSelected={selectedVideoPlaybackSpeed === speed}
                >
                  {speed}x
                </PlaybackSpeedText>
              </FlatButton>
            ))}
          </PlaybackSpeedButtons>
          <PlayPauseSkipButtons>
            {showSkipButtons && (
              <FlatButton onClick={back} title="Go back 15 seconds">
                <DmpIcon
                  icon="back_15_seconds"
                  constrainToParent={false}
                  size={16}
                />
              </FlatButton>
            )}
            <FlatButton
              onClick={togglePlay}
              title={isPlaying ? "Pause" : "Play"}
            >
              {isPlaying ? (
                <DmpIcon
                  icon="pause_icon"
                  constrainToParent={false}
                  size={16}
                />
              ) : (
                <DmpIcon icon="play_icon" constrainToParent={false} size={16} />
              )}
            </FlatButton>
            {showSkipButtons && (
              <FlatButton onClick={forward} title="Go forward 15 seconds">
                <DmpIcon
                  icon="forward_15_seconds"
                  constrainToParent={false}
                  size={16}
                />
              </FlatButton>
            )}
          </PlayPauseSkipButtons>
          <FullscreenButtons>
            {!isFullscreen && enterFullscreen && (
              <FlatButton onClick={enterFullscreen} title="View fullscreen">
                <DmpIcon icon="resize" constrainToParent={false} size={16} />
              </FlatButton>
            )}
            {isFullscreen && exitFullscreen && (
              <FlatButton onClick={exitFullscreen} title="Exit fullscreen">
                <DmpIcon
                  icon="exit_fullscreen"
                  constrainToParent={false}
                  size={16}
                />
              </FlatButton>
            )}
          </FullscreenButtons>
        </ButtonsWrapper>
        {isFullscreen && thumbnails}
      </ControlsWrapper>
    </>
  );
};

export default ClipPlayer;
