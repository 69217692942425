/**
 * @generated SignedSource<<731cbde34d2efd49411b806f2b722b3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly areaInformations: ReadonlyArray<{
    readonly armedOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly autoArmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly autoDisarmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly badZonesOption: {
      readonly data: BadZonesOption | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lateArmDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly lateOutputNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly openClosingReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly systemAreaInformation: {
    readonly anyBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly areaSchedules: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCheck: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly systemOptions: {
    readonly included: boolean;
    readonly systemType: {
      readonly data: SystemType | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "7d531466b16760da343228ed1496d8a8";

export default node;
