import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsDisarmedAnswerRingsField_remoteOptions$key } from "./__generated__/RemoteOptionsDisarmedAnswerRingsField_remoteOptions.graphql";

export const remoteOptionsDisarmedAnswerRingsFieldId = () =>
  "remote-options-disarmed-answer-rings";

function RemoteOptionsDisarmedAnswerRingsField() {
  const [{ disarmedAnswerRings }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsDisarmedAnswerRingsField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsDisarmedAnswerRingsField_remoteOptions on RemoteOptions {
          ... on XtRemoteOptions {
            disarmedAnswerRings
          }
          ... on XrRemoteOptions {
            disarmedAnswerRings
          }
        }
      `
    );

  const fieldId = remoteOptionsDisarmedAnswerRingsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Disarmed Answer Rings"
      tooltip="The number of rings the System counts before answering a phone call while some areas are disarmed. "
    >
      <NumericInput
        id={fieldId}
        value={disarmedAnswerRings ?? 0}
        pattern="(0{0,1}[0-9]|1[0-5])"
        inlineHelp={"0-15"}
        max={15}
        min={0}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "disarmedAnswerRings");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(0, 15, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(value, "disarmedAnswerRings");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsDisarmedAnswerRingsField;
