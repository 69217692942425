/**
 * @generated SignedSource<<4d3f9146d56def0320b8936f219d1d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorDoorReportsField_remoteOptions$data = {
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly integratorDoorReports?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorDoorReportsField_remoteOptions";
};
export type RemoteOptionsIntegratorDoorReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorDoorReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorDoorReportsField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorDoorReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integratorDoorReports",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integratorConnection",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "14d859e71a3e71cdcf538da0253adbaf";

export default node;
