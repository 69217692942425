/**
 * @generated SignedSource<<8c7d76b917bec2d5f59e74779e2bf6b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatSiteCode5Field_cardFormat$data = {
  readonly number: string;
  readonly requireSiteCode: boolean;
  readonly siteCodes: ReadonlyArray<number | null>;
  readonly " $fragmentType": "CardFormatSiteCode5Field_cardFormat";
};
export type CardFormatSiteCode5Field_cardFormat$key = {
  readonly " $data"?: CardFormatSiteCode5Field_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatSiteCode5Field_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatSiteCode5Field_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requireSiteCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "17e218925a52c3baacd22cd012d2693a";

export default node;
