/**
 * @generated SignedSource<<49bf630ebf4e1d8b2b127588f7d6460f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntegrationGridView_query$data = {
  readonly dealer: {
    readonly __typename: "Dealer";
    readonly centralStationAutomationIntegrations: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"IntegrationGridView_centralStationAutomationIntegration">;
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"IntegrationForm_Query">;
  readonly " $fragmentType": "IntegrationGridView_query";
};
export type IntegrationGridView_query$key = {
  readonly " $data"?: IntegrationGridView_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"IntegrationGridView_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "dealerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IntegrationGridView_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IntegrationForm_Query"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "dealerId"
        }
      ],
      "concreteType": "Dealer",
      "kind": "LinkedField",
      "name": "dealer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CentralStationAutomationIntegration",
          "kind": "LinkedField",
          "name": "centralStationAutomationIntegrations",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "IntegrationGridView_centralStationAutomationIntegration"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5b0ddee3e3e1ae3403e87cbb6935d2d5";

export default node;
