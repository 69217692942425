/**
 * @generated SignedSource<<9d0dccaf98df26aad3ef9b62dc4d4ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecorderEditRestartVarHubMutation$variables = {
  hubId: string;
  systemId: string;
};
export type RecorderEditRestartVarHubMutation$data = {
  readonly restartVarHub: {
    readonly __typename: "RestartVarHubErrorPayload";
    readonly message: string | null;
    readonly type: string | null;
  } | {
    readonly __typename: "RestartVarHubSuccessPayload";
    readonly status: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type RecorderEditRestartVarHubMutation = {
  response: RecorderEditRestartVarHubMutation$data;
  variables: RecorderEditRestartVarHubMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hubId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hubId",
        "variableName": "hubId"
      },
      {
        "kind": "Variable",
        "name": "systemId",
        "variableName": "systemId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "restartVarHub",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "type": "RestartVarHubSuccessPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "RestartVarHubErrorPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderEditRestartVarHubMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderEditRestartVarHubMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6dd4462059886330ea7af40a0a41e19f",
    "id": null,
    "metadata": {},
    "name": "RecorderEditRestartVarHubMutation",
    "operationKind": "mutation",
    "text": "mutation RecorderEditRestartVarHubMutation(\n  $hubId: String!\n  $systemId: String!\n) {\n  restartVarHub(hubId: $hubId, systemId: $systemId) {\n    __typename\n    ... on RestartVarHubSuccessPayload {\n      status\n    }\n    ... on RestartVarHubErrorPayload {\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5ea71b853426e44c2b02553163e160d";

export default node;
