/**
 * @ngdoc service
 * @name App.factory:EventsReportAPI
 *
 * @description
 * API factory for Events Report data
 *
 */
App.factory("EventsReportAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.oDataEventReportsUrl + "/api/v1/EventsReport",
      {},
      {
        getReport: { method: "POST", isArray: true },
      }
    );
  },
]);
