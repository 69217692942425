import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { BadZonesOption } from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaAutoArmField_area$key } from "./__generated__/AreaAutoArmField_area.graphql";

export const areaAutoArmFieldId = (number: string) =>
  `area-auto-arm-field-${number}`;

function AreaAutoArmField() {
  const [{ number, autoArmEnabled }, updateArea] =
    useAreaFragment<AreaAutoArmField_area$key>(
      graphql`
        fragment AreaAutoArmField_area on Area {
          id
          number
          autoArmEnabled
        }
      `
    );

  const fieldId = areaAutoArmFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Automatic Arming"
      tooltip="If enabled, this Area will use the Opening and Closing Schedule. "
    >
      <Switch
        label="Automatic Arming"
        id={fieldId}
        checked={autoArmEnabled}
        onChange={() => {
          if (autoArmEnabled) {
            updateArea((recordProxy) => {
              recordProxy.setValue(BadZonesOption.BYPASS, "badZonesOption");
            });
          }
          updateArea((recordProxy) => {
            recordProxy.setValue(!autoArmEnabled, "autoArmEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaAutoArmField;
