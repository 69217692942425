import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import TextInput from "components/FullProgramming/common/TextInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { concat, range } from "ramda";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorCheckInMinutesField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorCheckInMinutesField_remoteOptions.graphql";

export const remoteOptionsIntegratorCheckInMinutesFieldId = () =>
  "remote-options-integrator-check-in-minutes";

function RemoteOptionsIntegratorCheckInMinutesField() {
  const [
    { integratorCheckInMinutes, integratorConnection },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsIntegratorCheckInMinutesField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsIntegratorCheckInMinutesField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          integratorCheckInMinutes
          integratorConnection
        }
      }
    `
  );

  const fieldId = remoteOptionsIntegratorCheckInMinutesFieldId();
  const originalValue = React.useRef(integratorCheckInMinutes).current;
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;
  const pattern =
    integratorConnection === RemoteOptionsConnectionType.CELL
      ? "(0{0,3}|0?[6-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)"
      : "(0{0,3}|0{0,2}[3-9]?|0?[1-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)";
  const inlineHelp =
    integratorConnection === RemoteOptionsConnectionType.CELL
      ? "0,60-240"
      : "0,3-240";
  const checkinValueRange =
    integratorConnection === RemoteOptionsConnectionType.CELL
      ? concat([0], range(60, 241))
      : concat([0], range(3, 241));
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Integrator Check-In Minutes"
      tooltip="Number of minutes the System waits between sending Check-In messages to the Integrator."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={integratorCheckInMinutes || ""}
        maxLength={16}
        inlineHelp={inlineHelp}
        pattern={pattern}
        validationMessage={`Valid values are ${inlineHelp}.`}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "integratorCheckInMinutes"
            )
          );
        }}
        onBlur={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : closest(valueAsNumber, checkinValueRange);
            recordProxy.setValue(Number(value), "integratorCheckInMinutes");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorCheckInMinutesField;
