/**
 *
 * ArmedShieldSolidIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ArmedShieldSolidIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <polygon points="32.009,62 32,61.999 31.991,62     " />
        <polygon points="31.991,2 32,2 32.009,2     " />
        <path
          d="M55.131,9.253c-0.12-0.965-0.957-3.162-5.342-4.804C46.337,3.156,40.799,2.001,32,2
      c-8.799,0.001-14.337,1.156-17.789,2.449C9.826,6.091,8.989,8.288,8.869,9.253c-0.09,0.726-0.186,2.975-0.186,2.975v22.058
      C8.684,50.062,30.26,61.968,32,61.999c1.74-0.03,23.316-11.936,23.316-27.712V12.229C55.316,12.229,55.221,9.979,55.131,9.253z
       M48.349,33.668c0,11.867-16.34,20.402-16.34,20.402s-16.325-8.536-16.325-20.402V12c0,0,1.313-3.032,16.325-3.032h0.015
      C47.035,8.968,48.349,12,48.349,12V33.668z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ArmedShieldSolidIcon;
