App.controller("ReportsManagementCtrl", [
  "$scope",
  "$modal",
  "DataTablesUtilsService",
  "$q",
  "CustomReportsService",
  "UserService",
  "$rootScope",
  "PersonUsersService",
  "$localStorage",
  "$state",
  "$window",
  function (
    $scope,
    $modal,
    DataTablesUtilsService,
    $q,
    CustomReportsService,
    UserService,
    $rootScope,
    PersonUsersService,
    $localStorage,
    $state,
    $window
  ) {
    console.info("In ReportsManagementCtrl");

    // Scoped variables
    $scope.dt = {
      custom: {
        data: [],
        options: {},
        show: false,
      },
      dealerAnalytics: {
        data: [],
        options: {},
        show: false,
      },
    };

    $scope.editReport = function (reportType, reportId) {
      var editModal = $modal.open({
        templateUrl:
          "app/dealer-dashboard/business-analytics-schedule-modal-tpl.html",
        windowClass: "",
        size: "lg",
        backdrop: true,
        controller: "BusinessAnalyticsScheduleModalCtrl",
        resolve: {
          report: {
            type: reportType,
            id: reportId,
          },
        },
      });
      editModal.result
        .then(
          function (reason) {
            refreshTableData();
          },
          function (reason) {}
        )
        .catch(function (error) {
          console.error(
            "Error getting editModal result: " + angular.toJson(error)
          );
        });
    };

    $scope.deleteReport = function (report) {
      report
        .delete()
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Report deleted successfully",
            });
            refreshTableData();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error deleting report",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.runReport = function (report) {
      $localStorage.thisCustomReport = report;
      $state.go("app.dealer.custom_reports_view");
    };

    // Local variables
    var localData = {
      reports: [],
      personnel: [],
    };

    // Local functions
    function init() {
      $q.all([getReportsData(), getPersonnel()]).then(function () {
        buildTableRows();
        Object.keys($scope.dt).map((k) => {
          initDtOptions(k);
          $scope.dt[k].show = true;
        });
      });
    }

    function initDtOptions(dataType) {
      $scope.dt[dataType].options =
        DataTablesUtilsService.getEmptyDTOptions("Reports");
      $scope.dt[dataType].options.withButtons([]);
      $scope.dt[dataType].options.withOption("order", [1, "asc"], [2, "asc"]);
      if ($scope.dt[dataType].data.length <= 10) {
        $scope.dt[dataType].options.withDOM(
          '<t><"data-table__bottom"<"data-table__bottom--left" i>>'
        );
      }
    }

    function getReportsData() {
      var deferred = $q.defer();
      CustomReportsService.getAllReportsForDealer(UserService.dealer_id).then(
        function (data) {
          localData.reports = data;
          deferred.resolve();
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error retrieving reports",
            json: error,
          });
          deferred.reject();
        }
      );
      return deferred.promise;
    }

    function getPersonnel() {
      var deferred = $q.defer();
      PersonUsersService.getPersonnel().then(
        function (data) {
          localData.personnel = data;
          deferred.resolve();
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error retrieving users",
            json: error,
          });
          deferred.reject();
        }
      );
      return deferred.promise;
    }

    function buildTableRows() {
      var reportsWithValidUsers = [];
      localData.reports.forEach((r) => {
        var personUser = localData.personnel.find(function (p) {
          return p.user_id === r.UserId;
        });
        if (personUser) {
          r.additionalProps = {
            creatorInfo: personUser,
          };
          reportsWithValidUsers.push(r);
        }
      });
      $scope.dt.custom.data = reportsWithValidUsers.filter(function (r) {
        return r.Custom === false;
      });
      var baReports = reportsWithValidUsers.filter(function (r) {
        return (
          r.Custom === true && r.CustomRequest.type === "business_analytics"
        );
      });
      baReports.forEach((r) => {
        r.additionalProps.recipients = r.EmailList.split(" ").join(", ");
      });
      $scope.dt.dealerAnalytics.data = baReports;
    }

    function refreshTableData() {
      getReportsData().then(function () {
        buildTableRows();
      });
    }

    init();
  },
]);
