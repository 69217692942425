import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { PanelHardwareModel as PanelContextHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";

const XR_OUTPUT_GROUP_FIELD_RULES = {
  XR550: {
    OUTPUT_NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-9][0-9]{2}|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20)|D[5-9](01|05|09|13|17|21|25|29|33|37|41|45|49|53|57|61))",
      INLINE_HELP:
        "1-6, 450-474, 480-999, D01-D16, G01-G20, F01-F20, AX address",
      VALIDATION_MESSAGE:
        "Valid values are 1-6, 450-474, 480-999, D01-D16, G01-G20, F01-F20, AX address",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?1[0-9]|0?20)",
      INLINE_HELP: "1-20",
      VALIDATION_MESSAGE: "Valid values are 1-20",
    },
  },
  XR350: {
    OUTPUT_NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-7][0-9]{2}|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 450-474, 480-799, D01-D16, G01-G20, F01-F20",
      VALIDATION_MESSAGE:
        "Valid values are 1-6, 450-474, 480-799, D01-D16, G01-G20, F01-F20",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?1[0-9]|0?20)",
      INLINE_HELP: "1-20",
      VALIDATION_MESSAGE: "Valid values are 1-20",
    },
  },
  XR150: {
    OUTPUT_NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-9]{2}|D0[1-8]|[FG](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 450-474, 480-599, D01-D08, G01-G20, F01-F20",
      VALIDATION_MESSAGE:
        "Valid values are 1-6, 450-474, 480-599, D01-D08, G01-G20, F01-F20",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?1[0-9]|0?20)",
      INLINE_HELP: "1-20",
      VALIDATION_MESSAGE: "Valid values are 1-20",
    },
  },
};

const XF_OUTPUT_GROUP_FIELD_RULES = {
  XF6_500: {
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,3}|0{0,2}[1-6]|[5-9][0-9][0-9]|[G](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 500-999, G01-G20",
      VALIDATION_MESSAGE: "Valid values are 1-6, 500-999, and G01-G20.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?1[0-9]|0?20)",
      INLINE_HELP: "1-20",
      VALIDATION_MESSAGE: "Valid values are 1-20",
    },
  },
  XF6_100: {
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,3}|0{0,2}[1-6]|[5][0-9][0-9]|[G](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 500-599, G01-G20",
      VALIDATION_MESSAGE: "Valid values are 1-6, 500-599, and G01-G20.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?1[0-9]|0?20)",
      INLINE_HELP: "1-20",
      VALIDATION_MESSAGE: "Valid values are 1-20",
    },
  },
};

type PanelOutputGroupFieldRules = {
  OUTPUT_NUMBER: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MESSAGE: string;
  };
  NAME: {
    PATTERN: string;
    VALIDATION_MESSAGE: string;
  };
  NUMBER: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MESSAGE: string;
  };
};

export const resolveOutputGroupFieldRules = (
  hardwareModel: PanelContextHardwareModel
): PanelOutputGroupFieldRules => {
  const { isXr, isXr150, isXr350, isXf6_500, isXf6_100 } =
    resolvePanelType(hardwareModel);

  return isXr
    ? isXr150
      ? XR_OUTPUT_GROUP_FIELD_RULES.XR150
      : isXr350
      ? XR_OUTPUT_GROUP_FIELD_RULES.XR350
      : XR_OUTPUT_GROUP_FIELD_RULES.XR550
    : isXf6_500
    ? XF_OUTPUT_GROUP_FIELD_RULES.XF6_500
    : isXf6_100
    ? XF_OUTPUT_GROUP_FIELD_RULES.XF6_100
    : XR_OUTPUT_GROUP_FIELD_RULES.XR550;
};
