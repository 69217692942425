/**
 *
 * RadialAlertIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialAlertIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect x="30" y="15" width="4" height="22" />
      <rect x="30" y="42" width="4" height="5" />
      <path
        d="M54.633,9.47c-6.049-6.035-14.087-9.358-22.646-9.358c-8.547,0-16.582,3.322-22.626,9.353
    C3.325,15.487,0.001,23.509,0,32.052c0,8.547,3.326,16.574,9.366,22.602C15.405,60.681,23.446,64,32.009,64h0.003
    c8.557,0,16.593-3.316,22.628-9.339C60.676,48.638,64,40.615,64,32.071C64,23.524,60.674,15.498,54.633,9.47z M51.813,51.83
    C46.534,57.099,39.502,60,32.012,60H32.01c-7.496,0-14.534-2.904-19.817-8.177S4,39.529,4,32.052
    c0.001-7.473,2.908-14.489,8.187-19.757c5.288-5.277,12.32-8.184,19.815-8.184c7.478,0,14.512,2.909,19.806,8.19
    C57.091,17.573,60,24.594,60,32.071C60,39.545,57.093,46.562,51.813,51.83z"
      />
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RadialAlertIcon;
