/**
 * @generated SignedSource<<3b1b2b4b6aa5c00765b4121b70bda378>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EagleEye_site$data = {
  readonly eagleEyeBridges: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"EagleEyeBridges_eagleEyeBridge">;
  }>;
  readonly " $fragmentType": "EagleEye_site";
};
export type EagleEye_site$key = {
  readonly " $data"?: EagleEye_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"EagleEye_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EagleEye_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EagleEyeBridge",
      "kind": "LinkedField",
      "name": "eagleEyeBridges",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EagleEyeBridges_eagleEyeBridge"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "e28632a5a84167434d038fe5d419b8ac";

export default node;
