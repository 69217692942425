/**
 * @generated SignedSource<<faf6097df943ab80849b72fc9e95af54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemReportsCodeChangeReportsField_systemReports$data = {
  readonly codeChangeReports: boolean;
  readonly " $fragmentType": "SystemReportsCodeChangeReportsField_systemReports";
};
export type SystemReportsCodeChangeReportsField_systemReports$key = {
  readonly " $data"?: SystemReportsCodeChangeReportsField_systemReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemReportsCodeChangeReportsField_systemReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemReportsCodeChangeReportsField_systemReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "codeChangeReports",
      "storageKey": null
    }
  ],
  "type": "SystemReports",
  "abstractKey": null
};

(node as any).hash = "3689cab65d18da245e3634fe0efd3bc1";

export default node;
