/**
 *
 * AttentionIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const AttentionIcon = (props: Parameters<typeof SvgIcon>[0])  => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M62.192,48.194L40.695,8.454C38.53,4.44,35.442,2.229,32,2.229c-3.444,0-6.536,2.212-8.706,6.228L1.812,48.206
      c-2.136,3.952-2.396,7.828-0.729,10.916C2.783,62.268,6.131,64,10.512,64h0l42.975-0.007c4.376,0,7.727-1.734,9.437-4.89
      C64.582,56.019,64.322,52.146,62.192,48.194z M59.404,57.203c-0.975,1.799-3.076,2.79-5.917,2.79L10.512,60
      c-2.842,0-4.941-0.987-5.91-2.778c-1.004-1.86-0.745-4.386,0.729-7.113l21.483-39.749c1.438-2.663,3.281-4.13,5.187-4.13
      c1.902,0,3.74,1.464,5.175,4.125l21.497,39.74C60.143,52.821,60.401,55.348,59.404,57.203z"
        />
        <rect x="30" y="47" width="4" height="5" />
        <rect x="30" y="23" width="4" height="18" />
      </g>
    </g>
  </SvgIcon>
);

export default AttentionIcon;
