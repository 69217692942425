import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsRemoteKeyField_remoteOptions$key } from "./__generated__/RemoteOptionsRemoteKeyField_remoteOptions.graphql";

export const remoteOptionsRemoteKeyFieldId = () => "remote-options-remote-key";

function RemoteOptionsRemoteKeyField() {
  const [{ remoteKey }] =
    useRemoteOptionsFragment<RemoteOptionsRemoteKeyField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsRemoteKeyField_remoteOptions on RemoteOptions {
          remoteKey
        }
      `
    );

  const fieldId = remoteOptionsRemoteKeyFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Remote Key"
      tooltip="Key used to authenticate remote communication to the System."
    >
      <TextInput
        id={fieldId}
        value={remoteKey} // You are never able to change the remote key from here.
        maxLength={16}
        disabled
        type="password"
        pattern="^.*$"
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsRemoteKeyField;
