/**
 * @ngdoc service
 * @name App.factory:CompanyStoreService
 *
 * @description
 * Holds the API and methods for the Company Store
 *
 */

App.factory("CompanyStoreService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "CompanyStoreAPI",
  "UserService",
  "PaymentServiceAPI",
  "MonitoringContract",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    CompanyStoreAPI,
    UserService,
    PaymentServiceAPI,
    MonitoringContract
  ) {
    return {
      customItemsPristine: [],
      //From DealerStoreSettingsAPI
      /**
       * @ngdoc object
       * @name method:getDealerStoreSettings
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns the dealer store settings that correspond to the supplied dealer id
       *
       * @return {promise} The promise of the requested Dealer Store Settings
       */
      getDealerStoreSettings: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;
        CompanyStoreAPI.showDealer(
          { dealer_id: dealer_id }, //params

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modifyDealerStoreSettings
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the update request to the supplied dealer id
       *
       * @return {promise} The promise of the updated Dealer Store Settings
       */
      modifyDealerStoreSettings: function (dealer_id, settings) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.dealer = settings;

        CompanyStoreAPI.updateDealer(
          { dealer_id: dealer_id }, //params

          body,

          function (data) {
            //success
            deferred.resolve(data.dealer);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      //From DealerItemsAPI
      /**
       * @ngdoc object
       * @name method:getDealerItems
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns an updated list of items (includes the dealer's previous updates to the item list) that correspond to the supplied dealer id
       *
       * @return {promise} The promise of the requested Dealer Items
       */
      getDealerItems: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;
        CompanyStoreAPI.showDealerItems(
          { dealer_id: dealer_id }, //params

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modifyItem
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the update request to the supplied dealer id
       *
       * @return {promise} The promise of the updated Item List
       */
      modifyItems: function (dealer_id, item_list) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.items = item_list;
        CompanyStoreAPI.updateItem(
          { dealer_id: dealer_id }, //params

          body,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      //From MonitoringContractAPI
      /**
       * @ngdoc object
       * @name method:newContract
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the create request to the supplied dealer id
       *
       * @return {promise} The promise of the created Monitoring Contract
       */
      createContract: function (dealer_id, contract) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.monitoring_contract = contract;

        CompanyStoreAPI.createContract(
          { dealer_id: dealer_id }, //params

          body,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getAllContracts
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns all monitoring contracts that correspond to the supplied dealer id
       *
       * @return {promise} The promise of the requested Monitoring Contracts
       */
      getAllContracts: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;

        CompanyStoreAPI.indexContracts(
          { dealer_id: dealer_id }, //params

          function (data) {
            //success
            deferred.resolve(data.monitoring_contracts);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modifyContract
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the update request to the supplied dealer id
       *
       * @return {promise} The promise of the updated Monitoring Contract
       */
      modifyContract: function (dealer_id, contract) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.monitoring_contract = contract;

        CompanyStoreAPI.updateContract(
          { dealer_id: dealer_id, item_id: contract.id }, //params

          body,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      //From InstallationCentersAPI
      /**
       * @ngdoc object
       * @name method:createInstallCenter
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the create request to the supplied dealer id
       *
       * @return {promise} The promise of the created install center
       */
      createInstallCenter: function (dealer_id, newAddress) {
        var deferred = $q.defer();
        var _this = this;

        CompanyStoreAPI.createInstallCenter(
          { dealer_id: dealer_id }, //params

          newAddress,

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:deleteInstallCenter
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the delete request to the supplied dealer id
       *
       * @return {promise} The promise of the deleted install center
       */
      deleteInstallCenter: function (dealer_id, item_id) {
        var deferred = $q.defer();
        var _this = this;
        CompanyStoreAPI.destroyInstallCenter(
          {
            dealer_id: dealer_id, //params
            item_id: item_id,
          },

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getAllInstallCenters
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns an array of Install Center objects
       *
       * @return {promise} The promise of the returned Install Center Objects
       */
      getAllInstallCenters: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;
        CompanyStoreAPI.indexInstallCenters(
          { dealer_id: dealer_id }, //params

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getInstallCenter
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns an Install Center object
       *
       * @return {promise} The promise of the requested Install Center Object
       */
      getInstallCenter: function (dealer_id, item_id) {
        var deferred = $q.defer();
        var _this = this;

        CompanyStoreAPI.showInstallCenter(
          {
            dealer_id: dealer_id, //params
            item_id: item_id,
          },

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modifyInstallCenter
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the update request to the supplied dealer id
       *
       * @return {promise} The promise of the updated Install Center
       */
      modifyInstallCenter: function (dealer_id, item_id, center_info) {
        var deferred = $q.defer();
        var _this = this;

        var body = center_info;

        CompanyStoreAPI.updateInstallCenter(
          {
            dealer_id: dealer_id, //params
            item_id: item_id,
          },

          body,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      //From PaymentServiceAPI
      /**
       * @ngdoc object
       * @name method:getStripeConnectedStatus
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns true or false depending on whether a dealer has connected
       *
       * @return {promise} The promise of the requested Stripe connection status
       */
      getStripeConnectedStatus: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;
        PaymentServiceAPI.show(
          { dealer_id: dealer_id }, //params

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            if (error.status == 404) {
              deferred.resolve({
                dealer: { securecom_id: dealer_id, stripe_connected: false },
              });
            } else {
              deferred.reject(error);
            }
          }
        );
        return deferred.promise;
      },

      //for Oauth controller
      /**
       * @ngdoc object
       * @name method:getStripeURI
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns the URI which must be passed to the API to redirect to the Stripe site
       * for the purpose of account connection
       *
       */
      getStripeURI: function (return_uri) {
        var the_uri =
          PROPS.paymentServiceApiUrl +
          "/oauth" +
          "?auth_token=" + // If we ever need to use this again, we need to replace it with the Bearer token method
          UserService.auth_token +
          "&redirect_uri=" +
          encodeURIComponent(return_uri);
        return the_uri;
      },

      //forSalesApp processes
      /**
       * @ngdoc object
       * @name method:getSalesAppItems
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns an array of Sales App Items
       *
       * @return {promise} The promise of the requested Items
       */
      getSalesAppItems: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;

        CompanyStoreAPI.showSalesAppItems(
          { dealer_id: dealer_id },

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modifySalesAppItems
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the update request to the supplied dealer id
       *
       * @return {promise} The promise of the updated Install Center
       */
      modifySalesAppItems: function (dealer_id, updatedItems) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.items = updatedItems;

        CompanyStoreAPI.updateSalesAppItems(
          { dealer_id: dealer_id },

          body,

          function (data) {
            _this.salesAppItems = data.items;
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:newSalesappCustomItem
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the create request to the supplied dealer id
       *
       * @return {promise} The promise of the created Custom Item
       */
      newSalesappCustomItem: function (dealer_id, newItem) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.custom_item = newItem;

        CompanyStoreAPI.createCustomItem(
          { dealer_id: dealer_id },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getSalesappCustomItems
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the index request to the supplied dealer id
       *
       * @return {promise} The promise of the requested Custom Items
       */
      getSalesappCustomItems: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;

        CompanyStoreAPI.indexCustomItems(
          { dealer_id: dealer_id },

          function (data) {
            _this.customItemsPristine = angular.copy(data);
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modifySalesappCustomItem
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the update request to the supplied dealer id
       *
       * @return {promise} The promise of the updated Custom Item
       */
      modifySalesappCustomItem: function (dealer_id, item_id, item) {
        var deferred = $q.defer();
        var _this = this;
        var body = item;

        CompanyStoreAPI.updateCustomItem(
          { dealer_id: dealer_id, item_id: item_id },

          body,

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:updateAllSalesappCustomItems
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the delete request to the supplied dealer id
       *
       * @return {promise} The promise of the deleted Custom Item
       */
      updateAllSalesappCustomItems: function (dealer_id, customItemList) {
        var deferred = $q.defer();
        var itemsToBeSaved = [];
        var thisPristineItem = undefined;
        var _this = this;

        angular.forEach(customItemList, function (item, index) {
          var pristineItemArray = $filter("filter")(
            _this.customItemsPristine.custom_items,
            { id: item.id }
          );

          if (pristineItemArray.length === 0) {
            itemsToBeSaved.push(
              _this.modifySalesappCustomItem(dealer_id, item.id, item)
            );
          } else {
            thisPristineItem = pristineItemArray[0];
            if (thisPristineItem == item) {
              return true;
            } else {
              itemsToBeSaved.push(
                _this.modifySalesappCustomItem(dealer_id, item.id, item)
              );
            }
          }
        });
        $q.all(itemsToBeSaved)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:removeSalesappCustomItem
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the delete request to the supplied dealer id
       *
       * @return {promise} The promise of the deleted Custom Item
       */
      removeSalesappCustomItem: function (dealer_id, item_id) {
        var deferred = $q.defer();
        var _this = this;

        CompanyStoreAPI.deleteCustomItem(
          { dealer_id: dealer_id, item_id: item_id },

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:createSalesAppContract
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the create request to the supplied dealer id
       *
       * @return {promise} The promise of the created Install Center
       */
      createSalesAppContract: function (dealer_id, newContract) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.monitoring_contract = newContract;

        CompanyStoreAPI.createSalesAppContract(
          { dealer_id: dealer_id },

          body,

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getAllSalesAppContracts
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns an array of Sales App Contract Objects
       *
       * @return {promise} The promise of the requested Contracts
       */
      getAllSalesAppContracts: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;

        CompanyStoreAPI.indexSalesAppContracts(
          { dealer_id: dealer_id },

          function (data) {
            deferred.resolve(data.monitoring_contracts);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:modifySalesAppContract
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the update request to the supplied dealer id
       *
       * @return {promise} The promise of the updated Sales App Contract
       */
      modifySalesAppContract: function (dealer_id, updatedContract) {
        var deferred = $q.defer();
        var _this = this;
        var body = {};
        body.monitoring_contract = updatedContract;

        CompanyStoreAPI.updateSalesAppContract(
          { dealer_id: dealer_id, item_id: updatedContract.id },

          body,

          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:companyStoreGetAll
       * @methodOf App.factory:CompanyStoreService
       *
       * @description
       * Returns a response status that corresponds to the success or failure
       * of the get requests to the supplied dealer id
       *
       * @return {promise} The promise of multiple requests for info
       */
      companyStoreGetAll: function (dealer_id, tool) {
        var deferred = $q.defer();
        var _this = this;
        var companyStoreData = {};
        var itemsPromise;
        var customItemsPromise;
        var contractsPromise;

        var dealerDataPromise = _this.getDealerStoreSettings(dealer_id);
        if (UserService.isDealerAccessible()) {
          var statusPromise = _this.getStripeConnectedStatus(dealer_id);
        }
        var installCenterPromise = _this.getAllInstallCenters(dealer_id);

        if (tool == "costore") {
          itemsPromise = _this.getDealerItems(dealer_id);
          customItemsPromise = undefined;
          contractsPromise = _this.getAllContracts(dealer_id);
        } else if (tool == "secura") {
          itemsPromise = _this.getSalesAppItems(dealer_id);
          customItemsPromise = _this.getSalesappCustomItems(dealer_id);
          contractsPromise = _this.getAllSalesAppContracts(dealer_id);
        } else {
          itemsPromise = undefined;
          customItemsPromise = undefined;
          contractsPromise = undefined;
        }

        $q.all([
          dealerDataPromise,
          statusPromise,
          installCenterPromise,
          itemsPromise,
          customItemsPromise,
          contractsPromise,
        ])
          .then(
            function (data) {
              companyStoreData.dealerData = data[0];
              companyStoreData.stripeData = data[1];
              companyStoreData.installCenterData = data[2];
              companyStoreData.itemsData = data[3];
              companyStoreData.customItemsData = data[4];
              companyStoreData.contractsData = data[5];

              deferred.resolve(companyStoreData);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
    };
  },
]);
