/**
 * @generated SignedSource<<177c891931422641bc9c38a86dcd9620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NetworkOptionsWifiSecurity = "NONE" | "UNSUPPORTED" | "WEP" | "WPA";
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsWifiSecurityField_networkOptions$data = {
  readonly wifiSecurity: NetworkOptionsWifiSecurity;
  readonly " $fragmentType": "NetworkOptionsWifiSecurityField_networkOptions";
};
export type NetworkOptionsWifiSecurityField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsWifiSecurityField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsWifiSecurityField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsWifiSecurityField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wifiSecurity",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "b2c836b41e9d4807470ac6eb686e3683";

export default node;
