import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import TextInput from "components/FullProgramming/common/TextInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { concat, range } from "ramda";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreCheckInMinutesField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreCheckInMinutesField_remoteOptions.graphql";

export const remoteOptionsEntreCheckInMinutesFieldId = () =>
  "remote-options-entre-check-in-minutes";

function RemoteOptionsEntreCheckInMinutesField() {
  const [{ entreCheckInMinutes, entreConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreCheckInMinutesField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreCheckInMinutesField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreCheckInMinutes
            entreConnection
          }
          ... on XfRemoteOptions {
            entreCheckInMinutes
            entreConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreCheckInMinutesFieldId();
  const originalValue = React.useRef(entreCheckInMinutes).current;
  const disabled = entreConnection === RemoteOptionsConnectionType.NONE;
  const pattern =
    entreConnection === RemoteOptionsConnectionType.CELL
      ? "(0{0,3}|0?[6-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)"
      : "(0{0,3}|0{0,2}[3-9]?|0?[1-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)";
  const inlineHelp =
    entreConnection === RemoteOptionsConnectionType.CELL
      ? "0,60-240"
      : "0,3-240";
  const checkinValueRange =
    entreConnection === RemoteOptionsConnectionType.CELL
      ? concat([0], range(60, 241))
      : concat([0], range(3, 241));

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré Check-In Minutes"
      disabled={disabled}
      tooltip="Number of minutes the System waits between sending Check-In messages to the Entré."
    >
      <TextInput
        id={fieldId}
        value={entreCheckInMinutes ?? ""}
        disabled={disabled}
        maxLength={16}
        inlineHelp={inlineHelp}
        pattern={pattern}
        validationMessage={`Valid values are ${inlineHelp}.`}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "entreCheckInMinutes"
            )
          );
        }}
        onBlur={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : closest(valueAsNumber, checkinValueRange);
            recordProxy.setValue(Number(value), "entreCheckInMinutes");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreCheckInMinutesField;
