interface RawAlarmZoneCamera {
  channel_id: number;
}

interface RawAlarmZoneCamerasData {
  cameras: RawAlarmZoneCamera[];
}

export type NonAlarmVisionAlarmZoneCamera = {
  cameraId: string;
};

export const parseNonAlarmVisionAlarmZoneCameras = (
  rawAlarmZoneCamerasData: RawAlarmZoneCamerasData
): NonAlarmVisionAlarmZoneCamera[] => {
  return rawAlarmZoneCamerasData.cameras.map((c) => ({
    cameraId: c.channel_id.toString(),
  }));
};
