import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from ".././ProgrammingConceptForm";
import { useMessagingSetupFragment } from "./MessagingSetupContext";
import { MessagingSetupMessagingEnabledField_messagingSetup$key } from "./__generated__/MessagingSetupMessagingEnabledField_messagingSetup.graphql";

export const messagingSetupMessagingEnabledFieldId = () =>
  "messaging-setup-messaging-enabled";

function MessagingSetupMessagingEnabledField() {
  const [{ enableMessaging }, updateMessagingSetup] =
    useMessagingSetupFragment<MessagingSetupMessagingEnabledField_messagingSetup$key>(
      graphql`
        fragment MessagingSetupMessagingEnabledField_messagingSetup on MessagingSetup {
          enableMessaging
        }
      `
    );

  const fieldId = messagingSetupMessagingEnabledFieldId();
  const label = "Enable Messaging";

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={label}>
      <Switch
        id={fieldId}
        label={label}
        checked={enableMessaging}
        onChange={() => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(!enableMessaging, "enableMessaging");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MessagingSetupMessagingEnabledField;
