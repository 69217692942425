import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { fetchQuery, useFragment, useRefetchableFragment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { asString, ControlSystem, ID } from "securecom-graphql/client";
import { Concept } from "../common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../common/FullProgrammingVersionEntryPoint";
import * as AreaInformation from "./TakeoverPanelAreaInformationProgrammingConceptForm";
import * as Communication from "./TakeoverPanelCommunicationProgrammingConceptForm";
import * as LockoutCode from "./TakeoverPanelLockoutCodeProgrammingConceptForm";
import * as MessagingSetup from "./TakeoverPanelMessagingSetupProgrammingConceptForm";
import * as NetworkOptions from "./TakeoverPanelNetworkOptionsProgrammingConceptForm";
import * as OutputOptions from "./TakeoverPanelOutputOptionsProgrammingConceptForm";
import * as RemoteOptions from "./TakeoverPanelRemoteOptionsProgrammingConceptForm";
import * as SystemOptions from "./TakeoverPanelSystemOptionsProgrammingConceptForm";
import * as SystemReports from "./TakeoverPanelSystemReportsProgrammingConceptForm";
import * as ZoneInformations from "./TakeoverPanelZoneInformationsProgrammingConceptForm";
import { TakeoverPanelFullProgrammingContainer_controlSystem$key } from "./__generated__/TakeoverPanelFullProgrammingContainer_controlSystem.graphql";
import { TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem$key } from "./__generated__/TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem.graphql";
import { TakeoverPanelFullProgrammingContainer_templateStatus_controlSystemQuery } from "./__generated__/TakeoverPanelFullProgrammingContainer_templateStatus_controlSystemQuery.graphql";
import { TakeoverPanelFullProgrammingTemplateQuery } from "./__generated__/TakeoverPanelFullProgrammingTemplateQuery.graphql";

export const createConcepts = ({
  softwareVersion,
  isCellComSl,
  isIComSl,
  isCellComEx,
  isTMSentry,
}: {
  softwareVersion: number;
  isCellComSl: boolean;
  isIComSl: boolean;
  isCellComEx: boolean;
  isTMSentry: boolean;
}) =>
  [
    Communication,
    isCellComSl || isCellComEx ? null : NetworkOptions,
    softwareVersion < 202 && !isIComSl ? MessagingSetup : null,
    RemoteOptions,
    SystemReports,
    SystemOptions,
    OutputOptions,
    AreaInformation,
    ZoneInformations,
    LockoutCode,
  ].filter(isNotNullOrUndefined) as Concept[];

export const takeoverFullProgrammingControlSystem = graphql`
  fragment TakeoverPanelFullProgramming_controlSystem on ControlSystem {
    id
    ...FullProgrammingForm_controlSystem
    ...ProgrammingTemplateForm_controlSystem
    ...ControlSystemContext_controlSystem
    ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
    ...TakeoverPanelCommunicationProgrammingConceptForm_controlSystem
    ...TakeoverPanelNetworkOptionsProgrammingConceptForm_controlSystem
    ...TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem
    ...TakeoverPanelLockoutCodeProgrammingConceptForm_controlSystem
    ...TakeoverPanelRemoteOptionsProgrammingConceptForm_controlSystem
    ...TakeoverPanelSystemReportsProgrammingConceptForm_controlSystem
    ...TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem
    ...SystemOptionsKeypadInputField_controlSystem
    ...TakeoverPanelOutputOptionsProgrammingConceptForm_controlSystem
    ...TakeoverPanelAreaInformationProgrammingConceptForm_controlSystem
    ...TakeoverPanelAreaInformationProgrammingConceptFormNavButton_controlSystem
    ...TakeoverPanelZoneInformationsProgrammingConceptForm_controlSystem
    ...TakeoverPanelZoneInformationsProgrammingConceptFormNavButton_controlSystem
  }
`;
export const takeoverFullProgrammingInlineControlSystem = graphql`
  fragment TakeoverPanelFullProgrammingInline_controlSystem on ControlSystem {
    ...TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelSystemOptionsProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem
    ...TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem
  }
`;

function TakeoverPanelFullProgrammingContainer(props: {
  dealerId: ID;
  systemId: string;
  controlSystem: TakeoverPanelFullProgrammingContainer_controlSystem$key;
}) {
  const controlSystemFragment = useFragment(
    graphql`
      fragment TakeoverPanelFullProgrammingContainer_controlSystem on ControlSystem {
        name
        isCellComSl
        isCellComEx
        isIComSl
        isTMSentry
        panel {
          softwareVersion
        }
        ...TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem
      }
    `,
    props.controlSystem
  );

  const { name, panel, isCellComSl, isCellComEx, isIComSl, isTMSentry } =
    controlSystemFragment;

  const softwareVersion = Number(panel?.softwareVersion);

  const concepts = createConcepts({
    softwareVersion,
    isCellComSl,
    isIComSl,
    isCellComEx,
    isTMSentry,
  });

  const [{ templatePreProgrammingJobStatus }, refetchJobStatus] =
    useRefetchableFragment<
      TakeoverPanelFullProgrammingContainer_templateStatus_controlSystemQuery,
      TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem$key
    >(
      graphql`
        fragment TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem on ControlSystem
        @refetchable(
          queryName: "TakeoverPanelFullProgrammingContainer_templateStatus_controlSystemQuery"
        ) {
          templatePreProgrammingJobStatus
        }
      `,
      controlSystemFragment
    );

  const jobPending = [
    "new",
    "created",
    "running",
    "acquired",
    "started",
  ].includes(templatePreProgrammingJobStatus ?? "");

  React.useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (jobPending) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetchJobStatus({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 5000));
        }, 5000)
      );
    }

    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [refetchJobStatus, jobPending]);

  return jobPending ? (
    <GenericPageFallback message={"Applying Template Programming..."} />
  ) : (
    <FullProgrammingVersionEntryPoint
      systemName={name}
      concepts={concepts}
      gqlQuery={graphql`
        query TakeoverPanelFullProgrammingQuery(
          $dealerId: ID!
          $systemId: ID!
        ) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: node(id: $systemId) {
            ... on ControlSystem {
              id
              name
              panel {
                hardwareModel
              }
              customer {
                id
              }
              ...ProgrammingTemplateForm_controlSystem
              ...TakeoverPanelFullProgramming_controlSystem
              ...TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelAreaInformationProgrammingConceptFormOriginalAreas_controlSystem
              ...NetworkOptionsDhcpEnabledField_controlSystem
              ...TakeoverPanelZoneInformationsProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelNetworkOptionsProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelSystemReportsProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelSystemOptionsProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem
              ...TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem
            }
          }
          controlSystemInline: node(id: $systemId) {
            ... on ControlSystem {
              id
              ...TakeoverPanelFullProgrammingInline_controlSystem
            }
          }
        }
      `}
      gqlQueryVariables={{
        systemId: props.systemId,
        dealerId: asString(props.dealerId),
      }}
      gqlFormControlSystemFragment={takeoverFullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={
        takeoverFullProgrammingInlineControlSystem
      }
      applyTemplate={applyTemplate(props.systemId, concepts)}
    />
  );
}

export default TakeoverPanelFullProgrammingContainer;

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<TakeoverPanelFullProgrammingTemplateQuery>(
    environment,
    graphql`
      query TakeoverPanelFullProgrammingTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on TakeoverProgrammingTemplateConcepts {
                ...TakeoverPanelCommunicationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelRemoteOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelSystemReportsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelSystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelZoneInformationsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
                ...TakeoverPanelLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
