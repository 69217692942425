import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import XTReceiver1ProgrammingFields from "components/XTReceiver1ProgrammingFields";
import XTReceiver2ProgrammingFields from "components/XTReceiver2ProgrammingFields";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  Communication,
  ControlSystem,
  PanelHardwareModel,
} from "securecom-graphql/client";
import CommunicationAccountNumberField from "../common/CommunicationFields/CommunicationAccountNumberField";
import CommunicationBackupCellField from "../common/CommunicationFields/CommunicationBackupCellField";
import CommunicationBackupCheckInMinutesField from "../common/CommunicationFields/CommunicationBackupCheckInMinutes";
import CommunicationBackupFailTimeMinutesField from "../common/CommunicationFields/CommunicationBackupFailTimeMinutes";
import CommunicationCellTestDaysField from "../common/CommunicationFields/CommunicationCellTestDaysField";
import CommunicationCheckInMinutesField from "../common/CommunicationFields/CommunicationCheckInMinutesField";
import { CommunicationContextProvider } from "../common/CommunicationFields/CommunicationContext";
import CommunicationFailTimeField from "../common/CommunicationFields/CommunicationFailTimeField";
import CommunicationFirstApnField from "../common/CommunicationFields/CommunicationFirstApnField";
import CommunicationNetTestDaysField from "../common/CommunicationFields/CommunicationNetTestDaysField";
import CommunicationReceiver1AlarmReportsField from "../common/CommunicationFields/CommunicationReceiver1AlarmReportsField";
import CommunicationReceiver1FirstIpAddressField from "../common/CommunicationFields/CommunicationReceiver1FirstIpAddressField";
import CommunicationReceiver1FirstIpPortField from "../common/CommunicationFields/CommunicationReceiver1FirstIpPortField";
import CommunicationReceiver1OpenCloseUserReportsField from "../common/CommunicationFields/CommunicationReceiver1OpenCloseUserReportsField";
import CommunicationReceiver1SecondIpAddressField from "../common/CommunicationFields/CommunicationReceiver1SecondIpAddressField";
import CommunicationReceiver1SecondIpPortField from "../common/CommunicationFields/CommunicationReceiver1SecondIpPortField";
import CommunicationReceiver1SupervisoryTroubleReportsField from "../common/CommunicationFields/CommunicationReceiver1SupervisoryTroubleReportsField";
import CommunicationReceiver1TestReportsField from "../common/CommunicationFields/CommunicationReceiver1TestReportsField";
import CommunicationReceiver2AlarmReportsField from "../common/CommunicationFields/CommunicationReceiver2AlarmReportsField";
import CommunicationReceiver2BackupField from "../common/CommunicationFields/CommunicationReceiver2BackupField";
import CommunicationReceiver2FirstIpAddressField from "../common/CommunicationFields/CommunicationReceiver2FirstIpAddressField";
import CommunicationReceiver2FirstIpPortField from "../common/CommunicationFields/CommunicationReceiver2FirstIpPortField";
import CommunicationReceiver2OpenCloseUserReportsField from "../common/CommunicationFields/CommunicationReceiver2OpenCloseUserReportsField";
import CommunicationReceiver2SecondIpAddressField from "../common/CommunicationFields/CommunicationReceiver2SecondIpAddressField";
import CommunicationReceiver2SecondIpPortField from "../common/CommunicationFields/CommunicationReceiver2SecondIpPortField";
import CommunicationReceiver2SupervisoryTroubleReportsField from "../common/CommunicationFields/CommunicationReceiver2SupervisoryTroubleReportsField";
import CommunicationReceiver2TestReportsField from "../common/CommunicationFields/CommunicationReceiver2TestReportsField";
import CommunicationSendCommunicationTroubleField from "../common/CommunicationFields/CommunicationSendCommunicationTroubleField";
import CommunicationTestTimeField from "../common/CommunicationFields/CommunicationTestTimeField";
import CommunicationTransmissionDelayField from "../common/CommunicationFields/CommunicationTransmissionDelayField";
import CommunicationTypeField from "../common/CommunicationFields/CommunicationTypeField";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { panelVersionGTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import refreshMutationConcreteRequest, {
  TakeoverPanelCommunicationProgrammingConceptFormCommunicationRefreshMutation,
} from "./__generated__/TakeoverPanelCommunicationProgrammingConceptFormCommunicationRefreshMutation.graphql";
import {
  TakeoverPanelCommunicationProgrammingConceptFormCommunicationSendMutation,
  TakeoverPanelCommunicationProgrammingConceptFormCommunicationSendMutation$data,
} from "./__generated__/TakeoverPanelCommunicationProgrammingConceptFormCommunicationSendMutation.graphql";
import {
  TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$data,
  TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem.graphql";
import { TakeoverPanelCommunicationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TakeoverPanelCommunicationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts.graphql";
import { TakeoverPanelCommunicationProgrammingConceptForm_controlSystem$key } from "./__generated__/TakeoverPanelCommunicationProgrammingConceptForm_controlSystem.graphql";

export const title = "Communication";
export const conceptId = "takeover-panel-communication";

export const getState = (
  controlSystem: TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          hardwareModel
          softwareVersion
          communication {
            id
            accountNumber
            backupCell
            backupCheckinMinutes
            backupDialer
            backupFailTimeMinutes
            cellApn1
            cellApn2
            cellTestDays
            cellTestDaysMax
            cellTestDaysMin
            checkInMinutes
            checkInMinutesValidValues
            comType
            dialerTestDays
            failTime
            failTimeValidValues
            netTrouble
            networkTestDays
            networkTestDaysMax
            networkTestDaysMin
            receiver1AlarmReports
            receiver1Ip
            receiver1Ip2
            receiver1OpenCloseUserReports
            receiver1Port
            receiver1Port2
            receiver1PrimaryPhoneNumber
            receiver1SecondaryPhoneNumber
            receiver1SupervisoryTroubleReports
            receiver1TestReports
            receiver2AlarmReports
            receiver2Backup
            receiver2Ip
            receiver2Ip2
            receiver2OpenCloseUserReports
            receiver2Port
            receiver2Port2
            receiver2PrimaryPhoneNumber
            receiver2SecondaryPhoneNumber
            receiver2SupervisoryTroubleReports
            receiver2TestReports
            testTime
            transmissionDelay
            transmissionDelayValidValues
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation TakeoverPanelCommunicationProgrammingConceptFormCommunicationRefreshMutation(
    $id: ID!
  ) {
    refreshCommunication(id: $id) {
      ... on RefreshCommunicationSuccessPayload {
        __typename
        controlSystem {
          ...TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TakeoverPanelCommunicationProgrammingConceptFormCommunicationRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshCommunication;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Communication Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshCommunication: {
                    __typename: response.refreshCommunication.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Communication: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Communication",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation TakeoverPanelCommunicationProgrammingConceptFormCommunicationSendMutation(
    $systemId: ID!
    $communication: CommunicationInput!
  ) {
    sendCommunication(systemId: $systemId, communication: $communication) {
      ... on SendCommunicationSuccessPayload {
        __typename
        controlSystem {
          id
          ...TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendCommunicationErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: TakeoverPanelCommunicationProgrammingConceptFormCommunicationSendMutation$data,
  originalControlSystemData: TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendCommunication.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshCommunication: {
          __typename: "RefreshCommunicationSuccessPayload",
          controlSystem: getState(response.sendCommunication.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TakeoverPanelCommunicationProgrammingConceptFormCommunicationSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { communication, hardwareModel },
        } = getState(props.controlSystem);
        if (communication) {
          save({
            variables: {
              systemId,
              communication: {
                accountNumber: communication.accountNumber,
                comType: communication.comType,
                transmissionDelay: communication.transmissionDelay,
                netTrouble: communication.netTrouble,
                backupCell: communication.backupCell,
                backupDialer: communication.backupDialer,
                testTime: String(communication.testTime).trim(),
                networkTestDays: communication.networkTestDays,
                cellTestDays: communication.cellTestDays,
                dialerTestDays: communication.dialerTestDays,
                checkInMinutes: communication.checkInMinutes,
                failTime: communication.failTime,
                receiver1AlarmReports: communication.receiver1AlarmReports,
                receiver1SupervisoryTroubleReports:
                  communication.receiver1SupervisoryTroubleReports,
                receiver1OpenCloseUserReports:
                  communication.receiver1OpenCloseUserReports,
                receiver1TestReports: communication.receiver1TestReports,
                receiver1Ip: communication.receiver1Ip,
                receiver1Port: communication.receiver1Port,
                receiver1Ip2: communication.receiver1Ip2,
                receiver1Port2: communication.receiver1Port2,
                receiver1PrimaryPhoneNumber:
                  communication.receiver1PrimaryPhoneNumber,
                receiver1SecondaryPhoneNumber:
                  communication.receiver1SecondaryPhoneNumber,

                receiver2Backup: communication.receiver2Backup,
                receiver2AlarmReports: communication.receiver2AlarmReports,
                receiver2SupervisoryTroubleReports:
                  communication.receiver2SupervisoryTroubleReports,
                receiver2OpenCloseUserReports:
                  communication.receiver2OpenCloseUserReports,
                receiver2TestReports: communication.receiver2TestReports,
                receiver2Ip: communication.receiver2Ip,
                receiver2Port: communication.receiver2Port,
                receiver2Ip2: communication.receiver2Ip2,
                receiver2Port2: communication.receiver2Port2,
                receiver2PrimaryPhoneNumber:
                  communication.receiver2PrimaryPhoneNumber,
                receiver2SecondaryPhoneNumber:
                  communication.receiver2SecondaryPhoneNumber,
                ...(hardwareModel === PanelHardwareModel.DUALCOM
                  ? {
                      backupCheckinMinutes: communication?.backupCheckinMinutes,
                      backupFailTimeMinutes:
                        communication?.backupFailTimeMinutes,
                      cellApn1: communication?.cellApn1,
                    }
                  : {}),
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendCommunication.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Communication Programming Sent to the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendCommunication.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendCommunication.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readCommunicationTemplateData = (
  programmingTemplateConcepts: TakeoverPanelCommunicationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelCommunicationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        communication {
          included
          comType {
            included
            data
          }
          transmissionDelay {
            included
            data
          }
          backupCell {
            included
            data
          }
          backupCheckinMinutes {
            included
            data
          }
          backupFailTimeMinutes {
            included
            data
          }
          backupDialer {
            included
            data
          }
          netTrouble {
            included
            data
          }

          testTime {
            included
            data
          }
          networkTestDays {
            included
            data
          }
          cellTestDays {
            included
            data
          }
          dialerTestDays {
            included
            data
          }
          cellApn1 {
            included
            data
          }
          cellApn2 {
            included
            data
          }
          checkInMinutes {
            included
            data
          }
          failTime {
            included
            data
          }
          receiver1AlarmReports {
            included
            data
          }
          receiver1SupervisoryTroubleReports {
            included
            data
          }
          receiver1OpenCloseUserReports {
            included
            data
          }
          receiver1TestReports {
            included
            data
          }
          receiver1Ip {
            included
            data
          }
          receiver1Port {
            included
            data
          }
          receiver1Ip2 {
            included
            data
          }
          receiver1Port2 {
            included
            data
          }
          receiver1PrimaryPhoneNumber {
            included
            data
          }
          receiver1SecondaryPhoneNumber {
            included
            data
          }
          receiver2Backup {
            included
            data
          }
          receiver2AlarmReports {
            included
            data
          }
          receiver2SupervisoryTroubleReports {
            included
            data
          }
          receiver2OpenCloseUserReports {
            included
            data
          }
          receiver2TestReports {
            included
            data
          }
          receiver2Ip {
            included
            data
          }
          receiver2Port {
            included
            data
          }
          receiver2Ip2 {
            included
            data
          }
          receiver2Port2 {
            included
            data
          }
          receiver2PrimaryPhoneNumber {
            included
            data
          }
          receiver2SecondaryPhoneNumber {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).communication ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: TakeoverPanelCommunicationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readCommunicationTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const communicationRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "communication",
      "Communication"
    ) as unknown as RecordProxy<Communication>;

    applyTemplateScalarDataToRecordProxy(
      communicationRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<TakeoverPanelCommunicationProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment TakeoverPanelCommunicationProgrammingConceptForm_controlSystem on ControlSystem {
          isCellComEx
          isCellComSl
          isDualCom
          isMiniCellCom
          panel {
            hardwareModel
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            communication {
              ...CommunicationAccountNumberField_communication
              ...CommunicationBackupCellField_communication
              ...CommunicationBackupCheckInMinutesField_communication
              ...CommunicationBackupFailTimeMinutesField_communication
              ...CommunicationCellTestDaysField_communication
              ...CommunicationCheckInMinutesField_communication
              ...CommunicationContext_communication
              ...CommunicationFailTimeField_communication
              ...CommunicationFirstApnField_communication
              ...CommunicationNetTestDaysField_communication
              ...CommunicationReceiver1AlarmReportsField_communication
              ...CommunicationReceiver1FirstIpAddressField_communication
              ...CommunicationReceiver1FirstIpPortField_communication
              ...CommunicationReceiver1OpenCloseUserReportsField_communication
              ...CommunicationReceiver1SecondIpAddressField_communication
              ...CommunicationReceiver1SecondIpPortField_communication
              ...CommunicationReceiver1SupervisoryTroubleReportsField_communication
              ...CommunicationReceiver1TestReportsField_communication
              ...CommunicationReceiver2AlarmReportsField_communication
              ...CommunicationReceiver2BackupField_communication
              ...CommunicationReceiver2FirstIpAddressField_communication
              ...CommunicationReceiver2FirstIpPortField_communication
              ...CommunicationReceiver2OpenCloseUserReportsField_communication
              ...CommunicationReceiver2SecondIpAddressField_communication
              ...CommunicationReceiver2SecondIpPortField_communication
              ...CommunicationReceiver2SupervisoryTroubleReportsField_communication
              ...CommunicationReceiver2TestReportsField_communication
              ...CommunicationSendCommunicationTroubleField_communication
              ...CommunicationSendCommunicationTroubleField_communication
              ...CommunicationTestTimeField_communication
              ...CommunicationTransmissionDelayField_communication
              ...CommunicationTypeField_communication
              ...CommunicationFirstApnField_communication
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseSoftwareDate_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    isCellComEx,
    isCellComSl,
    isDualCom,
    isMiniCellCom,
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <CommunicationContextProvider
        communication={controlSystem.panel.communication}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Communication`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.communication
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <CommunicationAccountNumberField />
              <CommunicationTypeField />
              <CommunicationTransmissionDelayField />
              {isDualCom && (
                <>
                  <CommunicationSendCommunicationTroubleField />
                  <CommunicationBackupCellField />
                  <CommunicationBackupCheckInMinutesField />
                  <CommunicationBackupFailTimeMinutesField />
                </>
              )}
              {isCellComEx || isCellComSl || isDualCom || isMiniCellCom ? (
                <CommunicationFirstApnField />
              ) : null}
              <CommunicationTestTimeField />
              <CommunicationNetTestDaysField />
              <CommunicationCellTestDaysField />
              <CommunicationCheckInMinutesField />
              <CommunicationFailTimeField />
            </ProgrammingConceptForm.Fields>

            <XTReceiver1ProgrammingFields>
              <ProgrammingConceptForm.Fields>
                <CommunicationReceiver1AlarmReportsField />
                <CommunicationReceiver1SupervisoryTroubleReportsField />
                <CommunicationReceiver1OpenCloseUserReportsField />
                <CommunicationReceiver1TestReportsField />
                <CommunicationReceiver1FirstIpAddressField />
                <CommunicationReceiver1FirstIpPortField />
                <CommunicationReceiver1SecondIpAddressField />
                <CommunicationReceiver1SecondIpPortField />
              </ProgrammingConceptForm.Fields>
            </XTReceiver1ProgrammingFields>
            <XTReceiver2ProgrammingFields>
              <ProgrammingConceptForm.Fields>
                {panelVersionGTOE(182, controlSystem.panel.softwareVersion) && (
                  <CommunicationReceiver2BackupField />
                )}
                <CommunicationReceiver2AlarmReportsField />
                <CommunicationReceiver2SupervisoryTroubleReportsField />
                <CommunicationReceiver2OpenCloseUserReportsField />
                <CommunicationReceiver2TestReportsField />
                <CommunicationReceiver2FirstIpAddressField />
                <CommunicationReceiver2FirstIpPortField />
                <CommunicationReceiver2SecondIpAddressField />
                <CommunicationReceiver2SecondIpPortField />
              </ProgrammingConceptForm.Fields>
            </XTReceiver2ProgrammingFields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </CommunicationContextProvider>
    </PanelContextProvider>
  );
}
