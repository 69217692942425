import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsXrX1PassphraseField_networkOptions$key } from "./__generated__/NetworkOptionsXrX1PassphraseField_networkOptions.graphql";

export const networkOptionsXrX1PassphraseFieldId = () =>
  "network-options-xrx1-passphrase";

function NetworkOptionsXrX1PassphraseField() {
  const [{ ddpPassphrase, enableDdp }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsXrX1PassphraseField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsXrX1PassphraseField_networkOptions on NetworkOptions {
          ddpPassphrase
          enableDdp
        }
      `
    );

  const fieldId = networkOptionsXrX1PassphraseFieldId();
  const disabled = !enableDdp;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="XR/X1 Passphrase"
      tooltip="Message encryption requires an 8-16 alphanumeric passphrase. Must be the same passphrase programmed in each XR or X1 site for proper connection."
      disabled={!enableDdp}
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={ddpPassphrase}
        pattern={"([A-Z0-9 .*#\\-]{8,16}|)"}
        inlineHelp="8-16 Characters A-Z 0-9 .*#-"
        maxLength={16}
        validationMessage="8-16 Characters A-Z 0-9 .*#-"
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "ddpPassphrase");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsXrX1PassphraseField;
