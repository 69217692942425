import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsArmDisarmReportsEnabledField_pcLogReports$key } from "./__generated__/PcLogReportsArmDisarmReportsEnabledField_pcLogReports.graphql";

export const pcLogReportsArmDisarmReportsEnabledFieldId = () =>
  "pc-log-reports-arm-disarm-reports-enabled";

function PcLogReportsArmDisarmReportsEnabledField() {
  const [{ armDisarmReportsEnabled }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsArmDisarmReportsEnabledField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsArmDisarmReportsEnabledField_pcLogReports on PcLogReports {
          armDisarmReportsEnabled
        }
      `
    );

  const fieldId = pcLogReportsArmDisarmReportsEnabledFieldId();
  const label = "Arm/Disarm Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Sends arming, disarming and Late to Close events. Includes the area number, 
      name and action, the user number and name, and the time and date."
    >
      <Switch
        id={fieldId}
        label={label}
        default={false}
        checked={!!armDisarmReportsEnabled}
        onChange={() => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(
              !armDisarmReportsEnabled,
              "armDisarmReportsEnabled"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsArmDisarmReportsEnabledField;
