/**
 * @generated SignedSource<<52368d6b93699317bd3429d346e2bb1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ValidationMethod = "EMAIL" | "NONE" | "SMS";
import { FragmentRefs } from "relay-runtime";
export type TwoFASetup_person$data = {
  readonly appUser: {
    readonly twoFactorSettings: {
      readonly enabled: boolean;
      readonly phoneNumber: string | null;
      readonly requiredByDealer: boolean;
      readonly validationMethod: ValidationMethod;
    } | null;
  } | null;
  readonly emailAddress: string;
  readonly id: string;
  readonly " $fragmentType": "TwoFASetup_person";
};
export type TwoFASetup_person$key = {
  readonly " $data"?: TwoFASetup_person$data;
  readonly " $fragmentSpreads": FragmentRefs<"TwoFASetup_person">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TwoFASetup_person",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "appUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TwoFactorSettings",
          "kind": "LinkedField",
          "name": "twoFactorSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requiredByDealer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "validationMethod",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": "__isPerson"
};

(node as any).hash = "388f6fb3eeb9e6c008c8973042b984fb";

export default node;
