/**
 * @generated SignedSource<<e6a3c578e976145661fcaee59f81b357>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75AreaInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly areas: {
      readonly __typename: "AreaConnection";
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "AreaEdge";
        readonly cursor: string | null;
        readonly node: {
          readonly __typename: string;
          readonly id: string;
          readonly isNew: boolean;
          readonly number: string;
          readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationProgrammingConceptFormInline_area">;
        } | null;
      }>;
      readonly totalCount: number;
    };
    readonly id: string;
    readonly systemAreaInformation: {
      readonly __typename: "SystemAreaInformation";
      readonly anyBypass: boolean;
      readonly areaSchedules: boolean;
      readonly closingCheck: boolean;
      readonly closingCode: boolean;
      readonly exitDelay: number;
      readonly morningAmbush: number;
      readonly supportsOpenClosingReports: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationProgrammingConceptFormInline_systemAreaInformation">;
    } | null;
  };
  readonly supportsBurglaryBellOutput: boolean;
  readonly supportsCardPlusPin: boolean;
  readonly supportsDualAuthority: boolean;
  readonly supportsOpenClosingReports: boolean;
  readonly supportsTwoManRule: boolean;
  readonly " $fragmentType": "XT75AreaInformationProgrammingConceptFormInline_controlSystem";
};
export type XT75AreaInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75AreaInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75AreaInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "39ee37b65f147fdd9d1c84f51bdb2f60";

export default node;
