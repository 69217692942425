App.directive(
  "daFilterTag",
  function (
    control_system_comm_typeFilter,
    control_system_hardware_modelFilter
  ) {
    return {
      restrict: "E",
      scope: {
        filterKey: "=",
        filterValue: "=",
        filterGroup: "=",
        filterBooleans: "=",
      },
      template:
        ' <span class="animated fadeIn filter-tag" id="{{filterId}}"><i class="filter-tag__closer icon-close_cancel"></i>{{ filterName | titleize }}</span>',

      link: function (scope, element, attrs) {
        if (scope.filterBooleans.includes(scope.filterKey)) {
          scope.filterId = scope.filterKey;
          scope.filterName = scope.filterKey;
        } else {
          if (scope.filterKey == "hardware_model")
            scope.filterName = control_system_hardware_modelFilter(
              scope.filterValue
            );
          else if (scope.filterKey == "comm_type")
            scope.filterName = control_system_comm_typeFilter(
              scope.filterValue
            );
          else scope.filterName = scope.filterValue;
          scope.filterId = scope.filterKey + "-" + scope.filterName;
        }

        element.on("click", function () {
          var selectedTag = scope.filterId;
          angular.element(document.querySelector("#" + selectedTag)).remove();
        });
      },
    };
  }
);
