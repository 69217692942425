/**
 * @generated SignedSource<<7d8624fb1afd9e9f093d6eeba51a262e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneStatus = "BYPASSED" | "DEFAULT" | "LOW_BATTERY" | "MISSING" | "NORMAL" | "OPEN" | "SHORT" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneStatuses_controlSystem$data = {
  readonly zones: ReadonlyArray<{
    readonly area: {
      readonly id: string;
    } | null;
    readonly canBypass: boolean;
    readonly id: string;
    readonly isFaulted: boolean;
    readonly lockdownEnabled: boolean;
    readonly number: string;
    readonly status: ZoneStatus | null;
    readonly " $fragmentSpreads": FragmentRefs<"Zone_zone">;
  }>;
  readonly " $fragmentType": "ZoneStatuses_controlSystem";
};
export type ZoneStatuses_controlSystem$key = {
  readonly " $data"?: ZoneStatuses_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneStatuses_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneStatuses_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zones",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFaulted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBypass",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockdownEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "area",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Zone_zone"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "9e5d193aa578ede57bb5fda1a416715b";

export default node;
