/**
 * @generated SignedSource<<f7243056809f46dca9c323ff7b721506>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LockoutCodeContext_lockoutCode$data = {
  readonly id: string;
  readonly " $fragmentType": "LockoutCodeContext_lockoutCode";
};
export type LockoutCodeContext_lockoutCode$key = {
  readonly " $data"?: LockoutCodeContext_lockoutCode$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockoutCodeContext_lockoutCode">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockoutCodeContext_lockoutCode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "LockoutCode",
  "abstractKey": null
};

(node as any).hash = "4ad8bfe997b81ae396d8161325355d81";

export default node;
