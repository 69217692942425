/**
 *
 * SunnyIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SunnyIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M61.5,30c-1.376,0-5.564-2.211-7.137-3.521c0.001-0.022,0.002-0.045,0.002-0.066c0-0.172-0.022-0.348-0.069-0.521
        c-0.053-0.195-0.132-0.377-0.234-0.54c0.707-1.922,3.229-5.931,4.419-6.618c0.642-0.371,1.001-1.043,1-1.734
        c0-0.34-0.086-0.684-0.268-0.998c-0.553-0.957-1.777-1.287-2.732-0.732c-0.493,0.285-2.411,0.686-5.131,0.686
        c-1.286,0-2.233-0.093-2.822-0.189c-0.09-0.162-0.202-0.314-0.34-0.451c-0.144-0.144-0.304-0.261-0.474-0.352
        c-0.349-2.018-0.17-6.75,0.519-7.941C48.414,6.705,48.5,6.361,48.5,6.022c0.001-0.692-0.358-1.364-1-1.735
        c-0.956-0.555-2.18-0.224-2.732,0.732c-0.689,1.195-4.725,3.73-6.608,4.425c-0.166-0.104-0.351-0.187-0.551-0.241
        c-0.196-0.052-0.39-0.066-0.586-0.066C35.712,7.564,33.5,3.375,33.5,2c0-1.104-0.896-2-2-2s-2,0.896-2,2
        c0,1.375-2.212,5.564-3.522,7.137c-0.195-0.004-0.391,0.014-0.586,0.066c-0.196,0.053-0.378,0.133-0.541,0.235
        C22.93,8.732,18.92,6.211,18.232,5.02c-0.554-0.956-1.777-1.287-2.732-0.732c-0.642,0.371-1.001,1.043-1,1.735
        c0,0.339,0.086,0.683,0.268,0.997c0.688,1.191,0.868,5.924,0.519,7.941c-0.17,0.091-0.33,0.208-0.474,0.352
        c-0.138,0.137-0.251,0.289-0.34,0.451c-0.589,0.097-1.536,0.189-2.822,0.189c-2.72,0-4.638-0.4-5.131-0.686
        C5.56,14.713,4.34,15.043,3.787,16c-0.182,0.314-0.268,0.658-0.268,0.998c-0.001,0.691,0.358,1.363,1,1.734
        c1.191,0.688,3.713,4.696,4.419,6.617c-0.102,0.164-0.182,0.346-0.234,0.541c-0.047,0.174-0.069,0.35-0.069,0.521
        c0,0.021,0.001,0.044,0.002,0.066C7.064,27.789,2.876,30,1.5,30c-1.104,0-2,0.896-2,2s0.896,2,2,2
        c1.376,0,5.564,2.211,7.137,3.522c-0.001,0.021-0.002,0.044-0.002,0.065c0,0.172,0.022,0.348,0.069,0.521
        c0.053,0.195,0.132,0.377,0.234,0.54C8.231,40.571,5.71,44.58,4.52,45.268c-0.642,0.371-1.001,1.044-1,1.734
        c0,0.34,0.086,0.684,0.268,0.998c0.553,0.955,1.775,1.285,2.732,0.732c0.493-0.285,2.411-0.686,5.131-0.686
        c1.286,0,2.233,0.093,2.822,0.189c0.09,0.162,0.202,0.314,0.34,0.451c0.144,0.144,0.304,0.261,0.474,0.352
        c0.349,2.018,0.17,6.75-0.519,7.941c-0.182,0.314-0.268,0.658-0.268,0.998c-0.001,0.691,0.358,1.363,1,1.734
        c0.955,0.553,2.179,0.224,2.732-0.732c0.689-1.195,4.725-3.73,6.608-4.425c0.166,0.104,0.351,0.187,0.551,0.241
        c0.197,0.053,0.395,0.072,0.586,0.066C27.288,56.437,29.5,60.625,29.5,62c0,1.104,0.896,2,2,2s2-0.896,2-2
        c0-1.375,2.212-5.563,3.522-7.137c0.193,0.003,0.39-0.014,0.586-0.066s0.378-0.133,0.541-0.235
        c1.921,0.706,5.931,3.228,6.618,4.419c0.553,0.956,1.776,1.285,2.732,0.732c0.642-0.371,1.001-1.043,1-1.734
        c0-0.34-0.086-0.684-0.268-0.998c-0.688-1.191-0.868-5.924-0.519-7.941c0.17-0.091,0.33-0.208,0.474-0.352
        c0.138-0.137,0.251-0.289,0.34-0.451c0.589-0.097,1.536-0.189,2.822-0.189c2.72,0,4.638,0.4,5.131,0.686
        c0.958,0.553,2.18,0.223,2.732-0.732c0.182-0.314,0.268-0.658,0.268-0.998c0.001-0.69-0.358-1.363-1-1.734
        c-1.191-0.688-3.713-4.696-4.419-6.617c0.102-0.164,0.182-0.346,0.234-0.541c0.047-0.174,0.069-0.35,0.069-0.521
        c0-0.021-0.001-0.044-0.002-0.065C55.936,36.211,60.124,34,61.5,34c1.104,0,2-0.896,2-2S62.604,30,61.5,30z M50,36.973
        c0,0.002,0,0.004,0,0.007c0,0.099-0.037,0.202-0.066,0.31c-0.383,1.433,0.891,4.262,2.486,6.776
        c-0.418-0.015-0.784-0.019-1.07-0.019c-4.207,0-5.655,0.707-6.276,1.573c-0.235,0.185-0.465,0.438-0.664,0.782
        c-0.686,1.188-0.956,3.842-0.854,6.477c-2.231-1.404-4.665-2.498-6.036-2.498c-0.4,0-0.735,0.075-1.012,0.187
        c-0.768,0.06-2.161,0.558-4.564,4.761c-0.124,0.217-0.275,0.49-0.442,0.803c-0.167-0.312-0.318-0.586-0.442-0.803
        c-2.403-4.203-3.798-4.701-4.564-4.761c-0.277-0.111-0.61-0.187-1.011-0.187c-1.371,0-3.805,1.094-6.036,2.498
        c0.102-2.635-0.167-5.289-0.853-6.477c-0.199-0.345-0.427-0.598-0.662-0.782c-0.621-0.866-2.065-1.573-6.271-1.573
        c-0.286,0-0.643,0.004-1.061,0.019c1.596-2.515,2.85-5.344,2.467-6.776C13.037,37.182,13,37.079,13,36.98
        c0-0.003,0-0.005,0-0.008c0-1.61-2.797-3.549-5.542-4.973C10.203,30.576,13,28.638,13,27.027c0-0.002,0-0.004,0-0.007
        c0-0.099,0.037-0.202,0.066-0.31c0.383-1.433-0.891-4.262-2.486-6.776c0.418,0.015,0.784,0.019,1.07,0.019
        c4.207,0,5.655-0.707,6.276-1.573c0.235-0.185,0.465-0.438,0.664-0.782c0.686-1.188,0.956-3.842,0.854-6.477
        c2.231,1.404,4.665,2.498,6.036,2.498c0.4,0,0.734-0.075,1.012-0.187c0.768-0.06,2.162-0.558,4.564-4.761
        c0.124-0.217,0.275-0.489,0.442-0.803c0.167,0.312,0.318,0.586,0.442,0.803c2.403,4.203,3.798,4.701,4.564,4.761
        c0.277,0.111,0.61,0.187,1.011,0.187c1.371,0,3.805-1.094,6.036-2.498c-0.102,2.635,0.167,5.289,0.853,6.477
        c0.199,0.345,0.427,0.598,0.662,0.782c0.621,0.866,2.065,1.573,6.271,1.573c0.286,0,0.643-0.004,1.061-0.019
        c-1.596,2.515-2.85,5.344-2.467,6.776C49.963,26.818,50,26.921,50,27.02c0,0.003,0,0.005,0,0.008c0,1.61,2.797,3.549,5.542,4.973
        C52.797,33.424,50,35.362,50,36.973z"
          />
          <path
            d="M31.5,17.569c-7.957,0-14.431,6.474-14.431,14.431S23.543,46.431,31.5,46.431S45.931,39.957,45.931,32
        S39.457,17.569,31.5,17.569z M31.5,42.431c-5.752,0-10.431-4.679-10.431-10.431S25.748,21.569,31.5,21.569
        S41.931,26.248,41.931,32S37.252,42.431,31.5,42.431z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default SunnyIcon;
