import * as R from "ramda";
import { useEffect, useState } from "react";
import { isNumeric } from "./numbers";

type DelayCache<A> =
  | {
      value: A;
    }
  | undefined;

export function delay<T, R>(fn: (...args: T[]) => R) {
  let cache: DelayCache<R> = undefined;
  return (...args: T[]) => {
    if (cache === undefined) {
      cache = {
        value: fn(...args),
      };
    }

    return cache.value;
  };
}

export const cond = <A, B>(fns: [(arg: A) => boolean, (arg: A) => B][]) => (
  v: A
) => {
  for (let i = 0; i < fns.length; i++) {
    const [predicate, value] = fns[i];
    if (predicate(v)) {
      return value(v);
    }
  }
};

export const neither = <A>(
  f1: (arg: A) => boolean,
  f2: (arg: A) => boolean
) => (arg: A) => !f1(arg) && !f2(arg);

export const nor = R.curry((x: boolean, y: boolean) => !x && !y);

export const isNotUndefined = <A>(x: A | undefined): x is A => x !== undefined;

export const isNotNull = <A>(x: A | null): x is A => x !== null;

export const isNotNullOrUndefined = <A>(x: A | null | undefined): x is A =>
  isNotUndefined(x) && isNotNull(x);

export const isNotNullOrUndefinedOrEmpty = <A>(
  x: A | null | undefined | string
): x is A => isNotNullOrUndefined(x) && x !== "";

export const isUndefined = <A>(x: A | undefined): x is undefined =>
  x === undefined;

export const isNull = <A>(x: A | null): x is null => x === null;

export const isNullOrUndefined = <A>(
  x: A | null | undefined
): x is null | undefined => R.isNil(x);

export const isNullOrUndefinedOrEmpty = <A>(
  x: A | null | undefined | string
): x is null | undefined | string => isNullOrUndefined(x) || R.isEmpty(x);

export const applyOrUndefined = <A, B>(
  f: (x: A) => B,
  x: A | null | undefined
) => (isNotNullOrUndefined(x) ? f(x) : undefined);

//returns the value with the optional function applied to it or null if the value is undefined or null
export const nullOrApply = <A, B>(x: A | null | undefined, f?: (x: A) => B) =>
  isNotNullOrUndefined(x) ? (f ? f(x) : x) : null;

export const useSetTimeout = (delay: number, callback: Function = () => {}) => {
  const [isRunning, setIsRunning] = useState(true);
  useEffect(() => {
    let timerId: any;
    if (isRunning) {
      // returns a positive integer ID which identifies the timer
      timerId = setTimeout(() => {
        callback();
        setIsRunning(false);
      }, delay);
    }
    // clear the timer
    return () => clearTimeout(timerId);
  }, [delay, callback, isRunning]);
  return [isRunning, setIsRunning];
};

export const isNumericallyEquivalent = R.curry(
  (
    v1: string | number | null | undefined,
    v2: string | number | null | undefined
  ) => isNumeric(v1) && isNumeric(v2) && Number(v1) === Number(v2)
);

type PropIsNumericallyEquivalent = (<T>(
  prop: keyof T,
  value: Parameters<typeof isNumericallyEquivalent>[0],
  obj: T
) => boolean) &
  (<T>(
    prop: keyof T,
    value: Parameters<typeof isNumericallyEquivalent>[0]
  ) => (obj: T) => boolean) &
  (<T>(
    prop: keyof T
  ) => (
    value: Parameters<typeof isNumericallyEquivalent>[0]
  ) => (obj: T) => boolean) &
  (<T>(
    prop: keyof T
  ) => (
    value: Parameters<typeof isNumericallyEquivalent>[0],
    obj: T
  ) => boolean);
export const propIsNumericallyEquivalent = R.curry(
  (
    prop: string,
    value: Parameters<typeof isNumericallyEquivalent>[0],
    obj: any
  ) => isNumericallyEquivalent(obj[prop], value)
) as PropIsNumericallyEquivalent;

type OnTrueOrFalse = (<A, B>(
  onTrue: () => A,
  onFalse: () => B,
  value: boolean
) => A | B) &
  (<A, B>(onTrue: () => A, onFalse: () => B) => (value: boolean) => A | B) &
  (<A>(onTrue: () => A) => <B>(onFalse: () => B) => (value: boolean) => A | B) &
  (<A>(onTrue: () => A) => <B>(onFalse: () => B, value: boolean) => A | B);

export const onTrueOrFalse: OnTrueOrFalse = R.curry(
  <A, B>(onTrue: () => A, onFalse: () => B, value: boolean) =>
    value ? onTrue() : onFalse()
);
