/**
 *
 * RadialEmptyIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialEmptyIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M32.012,64h-0.003c-8.562,0-16.604-3.319-22.643-9.346C3.326,48.626,0,40.599,0,32.052
        c0.001-8.543,3.325-16.564,9.36-22.588c6.044-6.031,14.079-9.353,22.626-9.353c8.56,0,16.598,3.324,22.646,9.358
        C60.674,15.498,64,23.524,64,32.071c0,8.544-3.324,16.566-9.36,22.59C48.604,60.684,40.568,64,32.012,64z M32.002,4.111
        c-7.495,0-14.527,2.907-19.815,8.184C6.908,17.563,4.001,24.579,4,32.052c0,7.477,2.909,14.499,8.192,19.771S24.514,60,32.01,60
        h0.002c7.49,0,14.522-2.901,19.802-8.17C57.093,46.562,60,39.545,60,32.071c0-7.478-2.909-14.498-8.192-19.77
        C46.514,7.02,39.479,4.111,32.002,4.111z"
          />
        </g>
      </g>
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RadialEmptyIcon;
