/**
 *
 * AwayLargeIcon
 * @author Chad Watson
 *
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const AwayLargeIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <title>away_large</title>
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path
          d="M31.8,22.8c4.3,0,15.1-0.4,15.6-0.5l2-0.1l-4.5-7.5l-0.3-0.2c-0.1-0.1-3.6-1.8-12.8-1.8S20,14.4,19.7,14.6l-0.2,0.1
      l-4.6,7.5l2.1,0.1C17.4,22.3,27.5,22.8,31.8,22.8z"
        />
        <path d="M8.6,34.6v4.1c0,0.8,0.8,1.2,1.6,1.2h7.2c0.9,0,1.1,0,1.1-0.9v-1.9C18.6,36.3,8.6,34.6,8.6,34.6z" />
        <path
          d="M62.4,40.8c0.2-2.6,0.7-8.8,0-11.7c-0.3-1.2-0.9-2.4-1.7-3.4c2-0.2,3.2-2.5,3.2-5.7c0-3-1.1-6.1-3.7-6.1h-4.8
      c-1.2,0.2-2.2,0.9-2.8,1.9l-3.8-5.6l-0.3-0.2c-3.3-2.2-9.2-3.5-16.5-3.5H32c-7.3,0-13.1,1.2-16.5,3.5l-0.2,0.2l-3.9,5.5
      c-0.6-1-1.6-1.6-2.8-1.8H3.7c-1.4,0-2.6,0.9-3,2.2C0.2,17.4,0,18.7,0,20c0,3.3,1.3,5.6,3.2,5.8c-0.8,1-1.4,2.2-1.6,3.4
      c-0.7,2.9-0.2,9.1,0,11.7c-0.8,0.5-1.4,1.4-1.4,2.3v0.2c0.1,0.9,0.2,1.7,0.3,2.6c0.1,0.9,0.2,1.8,0.3,2.8c0,0.6,0.3,1.2,0.7,1.6
      C1.9,50.7,2.4,51,3,51.1V55c0,1.3,1,2.3,2.3,2.4h8.2c1.2,0,2.2-1.1,2.1-2.3c0,0,0-0.1,0-0.1v-3.6h32.6V55
      c-0.1,1.2,0.8,2.3,2.1,2.4c0,0,0.1,0,0.1,0h8.2c1.3,0,2.3-1.1,2.3-2.4v-3.9c0.5-0.1,1-0.3,1.4-0.7c0.4-0.5,0.7-1,0.7-1.7l0.2-1.2
      c0.2-1.3,0.3-2.7,0.5-4.1v-0.2C63.7,42.2,63.2,41.3,62.4,40.8z M60.9,47.2l-0.2,1.3v0.2c0,0,0,0.1,0,0.1c-0.6,0-1.2,0.2-1.6,0.5
      l-0.5,0.4V55h-7.9v-6H13.2v6H5.4v-5.4L5,49.2c-0.5-0.3-1.1-0.5-1.7-0.5l-0.1-0.1v-0.1c-0.1-1-0.2-1.9-0.3-2.9S2.7,44,2.6,43.2
      c0,0,0-0.1,0.1-0.1h0.2h1.4l-0.2-1.4c-0.3-2.8-0.8-9.5-0.1-12c0.5-1.7,1.5-3.1,3-4.1l3.6-2.2h-7c-0.4,0-1.1-1.3-1.1-3.3
      s0.7-3.6,1.3-3.6h4.6c0.4,0.2,0.7,0.5,0.9,0.9v2.5h2.1l5.4-7.7c1.5-1,5.7-3,15-3h0.1c9.3,0,13.5,2,15,3l5.3,7.7h2.3v-2.5
      c0.3-0.4,0.6-0.7,1-0.9h4.7c0.5,0,1.3,1.4,1.3,3.6s-0.7,3.3-1.1,3.3h-7l3.6,2.2c1.4,1,2.5,2.4,3,4.1c0.6,2.7,0.1,9.8-0.2,12
      L59.7,43h1.4h0.2c0,0.1,0.1,0.1,0.1,0.2C61.2,44.5,61,45.8,60.9,47.2L60.9,47.2z"
        />
        <rect x="22.5" y="36.5" width="18.7" height="3.5" />
        <path d="M45.1,37.2v2c0,0.9,0.3,0.9,1.2,0.9h7.2c0.8,0,1.6-0.4,1.6-1.2v-4.2C55.1,34.6,45.1,36.3,45.1,37.2z" />
      </g>
    </g>
  </SvgIcon>
);

export default AwayLargeIcon;
