import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZoneChimeSound, ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationChimeSoundField_zone$key } from "./__generated__/ZoneInformationChimeSoundField_zone.graphql";

export const zoneInformationChimeSoundFieldId = (number: string) =>
  `zone-information-chime-sound-${number}`;

function ZoneInformationChimeSoundField() {
  const [{ number, chimeSound, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationChimeSoundField_zone$key>(
      graphql`
        fragment ZoneInformationChimeSoundField_zone on Zone {
          number
          type
          chimeSound
        }
      `
    );

  const fieldId = zoneInformationChimeSoundFieldId(String(number));
  const disabled = ![
    ZoneType.NIGHT,
    ZoneType.EXIT,
    ZoneType.FINAL_EXIT,
    ZoneType.INSTANT,
  ].includes(type as ZoneType);
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Chime Sound"
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={chimeSound === "NONE" ? "OFF" : chimeSound ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "chimeSound");
          });
        }}
      >
        <Select.Option value={ZoneChimeSound.OFF}>None</Select.Option>
        <Select.Option value={ZoneChimeSound.DESCEND}>Descend</Select.Option>
        <Select.Option value={ZoneChimeSound.ASCEND}>Ascend</Select.Option>
        <Select.Option value={ZoneChimeSound.DOORBELL}>Doorbell</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationChimeSoundField;
