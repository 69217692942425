import CommonTooltip, {
  TooltipTheme,
  TooltipThemeProvider as DefaultTooltipThemeProvider,
} from "common/components/web/Tooltip";
import * as React from "react";
export { MessagePosition } from "common/components/web/Tooltip";

const darkTheme: TooltipTheme = {
  padding: 0,
  backgroundColor: "var(--color-neutral-1000)",
  textColor: "white",
  textColorHover: "white",
  borderColor: "none",
  borderWidth: "0",
  caretFill: "var(--color-neutral-1000)",
  caretStrokeColor: undefined,
  caretStrokeWidth: undefined,
};

const warningTheme: TooltipTheme = {
  padding: "1em 2em 1em 1.5em",
  backgroundColor: "white",
  textColor: "var(--color-text-default)",
  textColorHover: "var(--color-text-default)",
  borderColor: "var(--color-warning-500)",
  borderWidth: "8px 1px 1px",
  caretFill: "var(--color-warning-500)",
  caretStrokeColor: undefined,
  caretStrokeWidth: undefined,
};

const errorTheme: TooltipTheme = {
  padding: "1em 2em 1em 1.5em",
  backgroundColor: "white",
  textColor: "var(--color-text-default)",
  textColorHover: "var(--color-text-default)",
  borderColor: "var(--color-failure-600)",
  borderWidth: "8px 1px 1px",
  caretFill: "var(--color-failure-600)",
  caretStrokeColor: undefined,
  caretStrokeWidth: undefined,
};

const defaultTheme: TooltipTheme = {
  padding: 0,
  backgroundColorHover: "var(--color-info-800)",
  backgroundColor: "white",
  textColor: "var(--color-text-default)",
  textColorHover: "white",
  borderColor: "var(--color-neutral-400)",
  caretFill: "white",
  borderWidth: "1px",
  caretStrokeColor: "var(--color-neutral-400)",
  caretStrokeWidth: 5,
};

export const DaTooltipThemes = {
  darkTheme,
  warningTheme,
  errorTheme,
  defaultTheme,
} as const;

export type DaTooltipTheme = "dark" | "warning" | "error" | "default";

export function TooltipThemeProvider(props: {
  theme?: DaTooltipTheme;
  children: React.ReactNode;
}) {
  const { theme, children } = props;
  return (
    <DefaultTooltipThemeProvider
      theme={
        theme === "dark"
          ? darkTheme
          : theme === "warning"
          ? warningTheme
          : theme === "error"
          ? errorTheme
          : defaultTheme
      }
    >
      {children}
    </DefaultTooltipThemeProvider>
  );
}

export default function Tooltip(
  props: Omit<React.ComponentProps<typeof CommonTooltip>, "baseFontSize"> & {
    theme?: DaTooltipTheme;
  }
) {
  const { theme, zIndex = 200, ...otherProps } = props;
  return (
    <TooltipThemeProvider theme={theme}>
      <CommonTooltip {...otherProps} zIndex={zIndex} baseFontSize="1.6rem" />
    </TooltipThemeProvider>
  );
}
