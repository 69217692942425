import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationOpenClosingReportsField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationOpenClosingReportsField_systemAreaInformation.graphql";

export const systemAreaInformationOpenClosingReportsFieldId = () =>
  `system-area-information-open-closing-reports`;

function SystemAreaInformationOpenClosingReportsField() {
  const [{ openClosingReports }, updateArea] =
    useSystemAreaInformationFragment<SystemAreaInformationOpenClosingReportsField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationOpenClosingReportsField_systemAreaInformation on SystemAreaInformation {
          openClosingReports
        }
      `
    );

  const fieldId = systemAreaInformationOpenClosingReportsFieldId();
  const disabled = false;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Opening / Closing Reports"
      disabled={disabled}
      tooltip="If enabled, each area will send Opening and Closing reports on and paths with those reports programmed."
    >
      <Switch
        id={fieldId}
        label="Opening / Closing Reports"
        disabled={disabled}
        checked={openClosingReports}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!openClosingReports, "openClosingReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationOpenClosingReportsField;
