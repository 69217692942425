/**
 * @ngdoc service
 * @name App.factory:BatchImportCredentialsService
 *
 * @description
 * Manages the panel card import jobs
 *
 */
App.factory("BatchImportUsersService", [
  "$rootScope",
  "$q",
  "$http",
  "PROPS",
  "$filter",
  "JobSchedulerService",
  "$interval",
  "UserService",
  "Customer",
  function (
    $rootScope,
    $q,
    $http,
    PROPS,
    $filter,
    JobSchedulerService,
    $interval,
    UserService,
    Customer
  ) {
    return {
      /**
       * Local constants to define which jobType and jobGroupType we are using for batch import jobs.
       */
      JOB_TYPE_NAME: "BATCH_USER_CODE_IMPORT",
      JOB_GROUP_TYPE_NAME: "BATCH_IMPORT",

      /**
       * The array of Import job groups that are in the current context (one panel)
       */
      importJobGroups: [],

      /**
       * An object with attributes representing the possible statuses of an import
       */
      importStatuses: {
        IMPORT_UNKNOWN: "",
        IMPORT_IN_PROGRESS: "importInProgress",
        IMPORT_COMPLETE: "importComplete",
        IMPORT_FAILED: "importFailed",
      },

      /**
       * String to hold the current status.  It will be one of the importStatuses
       */
      currentStatus: "",

      /**
       * The current percent complete for _this_ import.
       */
      importPercent: 0,

      /**
       * The panel that we are updating or getting status of
       */
      activePanelId: null,

      jobOutput: {},

      /**
       * Initialize this service.
       *
       * @param panelID
       */
      initialize: function (panelID) {
        this.activePanelId = panelID;
        this.stopImportWatcher(this.activeImportWatcher);
        this.currentStatus = "";
        this.importPercent = 0;
      },

      /**
       * Set the currentStatus attribute
       * @param status
       */
      setStatus: function (status) {
        this.currentStatus = status;
      },

      setJobOutput: function (jobOutput) {
        this.jobOutput = jobOutput;
      },

      setCurrentJobGroup: function (jobGroup) {
        jobGroup.SchedulerJobs[0].JobOutput = angular.fromJson(
          jobGroup.SchedulerJobs[0].JobOutput
        );
        this.currentJobGroup = jobGroup;
      },

      /**
       * Creates a scheduled JobGroup to import the card credentials defined in the data
       * @returns {Promise} a promise, which will eventually resolve the JSON data of the JobGroup that is created.
       * credentialData is a base64 encoded string
       */
      import: function (credentialData, fileName, fileImportType) {
        var _this = this;
        var deferred = $q.defer();
        this.setStatus(_this.importStatuses.IMPORT_IN_PROGRESS);
        var job = JobSchedulerService.getJobTemplate();
        var jobGroup = JobSchedulerService.getJobGroupTemplate();

        var jobGroupTypePromise = JobSchedulerService.getJobGroupTypeByName(
          this.JOB_GROUP_TYPE_NAME
        );
        var jobTypePromise = JobSchedulerService.getJobTypeByName(
          this.JOB_TYPE_NAME
        );

        $q.all([jobGroupTypePromise, jobTypePromise])
          .then(
            function (values) {
              jobGroup.SchedulerJobGroupTypeId = values[0].Id;
              job.SchedulerJobTypeId = values[1].Id;
              // jobGroup.GroupData = _this.getGroupData(credentialData);
              jobGroup.PanelId = _this.activePanelId;
              job.PanelId = _this.activePanelId;
              job.JobData = credentialData;
              job.ReferenceDesc = fileImportType;
              //jobGroup.GroupClass = 'DEBUG';

              // Currently, we only send the MAX version, which is sorted to the top.  In the future, we may allow the user to choose.
              job.SelectedItem = fileName;

              jobGroup.SchedulerJobs.push(job);
              JobSchedulerService.createJobGroup(jobGroup)
                .then(
                  function (data) {
                    _this.importJobGroups.push(data);
                    _this.startImportWatcher(data.Id);
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              _this.setStatus(_this.importStatuses.IMPORT_UNKNOWN);
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the status of that specific Job Group ID
       * @param jobID
       */
      getImportStatus: function (jobGroupID) {
        var deferred = $q.defer();
        JobSchedulerService.getJobGroup(jobGroupID)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all imports for activePanel
       *
       * @returns {*}
       */
      getAllImportForPanel: function () {
        var _this = this;
        var deferred = $q.defer();
        // JobSchedulerService.activePanelId = ControlSystemService.controlSystem.panels[0].id;
        JobSchedulerService.getJobGroupTypeByName(_this.JOB_GROUP_TYPE_NAME)
          .then(
            function (data) {
              var jobGroupTypeID = data.Id;

              JobSchedulerService.getJobGroupsByPanel(
                jobGroupTypeID,
                _this.activePanelId
              )
                .then(
                  function (data) {
                    delete _this.importJobGroups;
                    _this.importJobGroups = data;
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Get any Running imports for activePanel (should only ever be 0 or 1, but you know how that goes)
       * @returns {*}
       */
      getRunningImportsForPanel: function () {
        var _this = this;
        var deferred = $q.defer();
        // We do a full get everytime, because it's a cheap call, and it has to be right and fresh
        this.getAllImportForPanel()
          .then(
            function (data) {
              var runningImports = _this.importJobGroups.filter(function (job) {
                return (
                  job.GroupStatus === JobSchedulerService.jobStatuses.RUNNING ||
                  job.GroupStatus === JobSchedulerService.jobStatuses.STARTED
                );
              });
              deferred.resolve(runningImports);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * A watcher to check a specific job on an interval, stopping when complete or when stopped manually.
       * @param jobID
       */
      startImportWatcher: function (jobID) {
        var _this = this;
        this.importPercent = 0;
        this.activeImportWatcher = $interval(function () {
          _this
            .getImportStatus(jobID)
            .then(
              function (data) {
                _this.setCurrentJobGroup(data);
                _this.setJobOutput(data.SchedulerJobs[0].JobOutput);
                // We check for Complete or Unknown status because Unknown could mean it was successful.
                if (
                  JobSchedulerService.isCompleteStatus(
                    data.SchedulerJobs[0].JobStatus
                  )
                ) {
                  _this.setStatus(_this.importStatuses.IMPORT_COMPLETE);
                  //_this.jobOutput = angular.fromJson(data.SchedulerJobs[0].JobOutput);
                  _this.stopImportWatcher(_this.activeImportWatcher);
                  // Send event to trigger controller to reload the route
                  $rootScope.$broadcast("event-userCodeImportComplete");
                } else if (
                  JobSchedulerService.isUnknownStatus(
                    data.SchedulerJobs[0].JobStatus
                  )
                ) {
                  _this.setStatus(_this.importStatuses.IMPORT_UNKNOWN);
                  _this.stopImportWatcher(_this.activeImportWatcher);
                  // Send event to trigger controller to reload the route
                  $rootScope.$broadcast("event-userCodeImportComplete");
                } else if (
                  JobSchedulerService.isRunningStatus(
                    data.SchedulerJobs[0].JobStatus
                  )
                ) {
                  // var progress = angular.fromJson(data.SchedulerJobs[0].ScapiStatus);
                  // if (progress && progress.details.completion_percentage) {
                  //   _this.importPercent = Number(progress.details.completion_percentage);
                  // }
                  _this.setStatus(_this.importStatuses.IMPORT_IN_PROGRESS);
                } else {
                  // Failed status
                  _this.setStatus(_this.importStatuses.IMPORT_FAILED);
                  _this.stopImportWatcher(_this.activeImportWatcher);
                  // Send event to trigger controller to reload the route
                  $rootScope.$broadcast("event-userCodeImportComplete");
                  var importError = data.SchedulerJobs[0].JobOutput
                    ? data.SchedulerJobs[0].JobOutput
                    : "Error completing user code import for system";
                  $rootScope.alerts.push({
                    type: "error",
                    text: importError.message,
                  });
                }
              },
              function (error) {
                _this.setStatus(_this.importStatuses.IMPORT_UNKNOWN);
                _this.stopImportWatcher(_this.activeImportWatcher);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }, PROPS.importWatcherInterval);
      },

      /**
       * Stop the activeImportWatcher interval import watcher
       * @param promise
       */
      stopImportWatcher: function (promise) {
        // If a promise is passed in, use that, otherwise, use the local promise
        promise =
          typeof promise === "undefined" ? this.activeImportWatcher : promise;
        //this.activeImportWatcher
        if (angular.isDefined(promise)) {
          $interval.cancel(promise);
          promise = undefined;
        }
      },

      /**
       * Function to set the GroupData field on the Job Group
       * @param fromVersion
       * @param toVersion
       * @returns {string|undefined|string}
       */
      getGroupData: function (credentialData) {
        var groupData = {};
        return angular.toJson(groupData);
      },
    };
  },
]);
