import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneSensorType, ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationDisarmDisableField_zone$key } from "./__generated__/ZoneInformationDisarmDisableField_zone.graphql";

export const zoneInformationDisarmDisableFieldId = (number: string) =>
  `zone-information-disarm-disable-${number}`;

function ZoneInformationDisarmDisableField() {
  const [
    { number, type, wireless, wirelessDisarmDisableEnabled, sensorType },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationDisarmDisableField_zone$key>(
    graphql`
      fragment ZoneInformationDisarmDisableField_zone on Zone {
        number
        type
        wireless
        wirelessDisarmDisableEnabled
        sensorType
      }
    `
  );

  const fieldId = zoneInformationDisarmDisableFieldId(String(number));
  const disabled =
    !wireless ||
    ![ZoneType.NIGHT, ZoneType.EXIT, ZoneType.INSTANT].includes(
      type as ZoneType
    ) ||
    ![
      ZoneSensorType.UNIVERSAL_TRANSMITTER,
      ZoneSensorType.WIRELESS_PIR,
    ].includes(sensorType as ZoneSensorType);
  const label = "Disarm Disable";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Disables Zone Tripped messages from this Zone when the System is Disarmed. 1103 transmitters still report Zone Trips if off-normal for 20 seconds."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={wirelessDisarmDisableEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              !wirelessDisarmDisableEnabled,
              "wirelessDisarmDisableEnabled"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationDisarmDisableField;
