import { not } from "ramda";
import React, { createContext, useContext, useRef, useState } from "react";
import { PanelHardwareModel } from "securecom-graphql/client";

export enum EditingFrom {
  PROGRAMMING_PAGE,
  NEW_TEMPLATE_PAGE,
  EDIT_TEMPLATE_PAGE,
}

type TemplateContextInput = {
  templateId?: string | null;
  editingFrom: EditingFrom;
  hardwareModel: PanelHardwareModel;
  customerId: string;
  templateName: string;
  isEditing: boolean;
  isApplying: boolean;
  isCreating: boolean;
  isSaving: boolean;
  isLoadingEditPage: boolean;
  isInternational: boolean;
};

type TemplateContextData = {
  templateId?: string | null;
  editingFrom: EditingFrom;
  hardwareModel: PanelHardwareModel;
  setHardwareModel: (
    hardwareModel: PanelHardwareModel,
    initialTemplateName?: string
  ) => void;
  customerId: string;
  templateName?: string | null;
  isEditing: boolean;
  toggleIsEditing: () => void;
  isApplying: boolean;
  toggleIsApplying: () => void;
  isCreating: boolean;
  toggleIsCreating: () => void;
  isSaving: boolean;
  toggleIsSaving: () => void;
  isLoadingEditPage: boolean;
  toggleIsLoadingEditPage: () => void;
  allFields: Set<string>;
  isInternational: boolean;
};

export const TemplateContext = createContext<TemplateContextData>({
  templateId: "",
  editingFrom: EditingFrom.PROGRAMMING_PAGE,
  hardwareModel: PanelHardwareModel.XT30,
  setHardwareModel: (
    hardwareModel: PanelHardwareModel,
    initialTemplateName?: string
  ) => {},
  customerId: "",
  templateName: "",
  isEditing: false,
  toggleIsEditing: () => {},
  isApplying: false,
  toggleIsApplying: () => {},
  isCreating: false,
  toggleIsCreating: () => {},
  isSaving: false,
  toggleIsSaving: () => {},
  isLoadingEditPage: false,
  toggleIsLoadingEditPage: () => {},
  allFields: new Set(),
  isInternational: false,
});

const TemplateContextProvider = (props: {
  settings: TemplateContextInput;
  children: React.ReactNode;
}) => {
  const [isApplying, setIsApplying] = useState(props.settings.isApplying);
  const [isEditing, setIsEditing] = useState(props.settings.isEditing);
  const [isCreating, setIsCreating] = useState(props.settings.isCreating);
  const [isSaving, setIsSaving] = useState(props.settings.isSaving);
  const [hardwareModel, setHardwareModel] = useState(
    props.settings.hardwareModel
  );
  const [templateName, setTemplateName] = useState(props.settings.templateName);
  const [isLoadingEditPage, setIsLoadingEditPage] = useState(
    props.settings.isLoadingEditPage
  );
  const allFields = useRef(new Set<string>());
  const [isInternational, _] = useState(props.settings.isInternational);

  return (
    <TemplateContext.Provider
      value={{
        templateId: props.settings.templateId,
        editingFrom: props.settings.editingFrom,
        hardwareModel,
        setHardwareModel: (
          hardwareModel: PanelHardwareModel,
          initialTemplateName?: string
        ) => {
          setHardwareModel(hardwareModel);
          setTemplateName(initialTemplateName ?? "");
        },
        customerId: props.settings.customerId,
        templateName,
        isEditing,
        toggleIsEditing: () => setIsEditing(not),
        isApplying,
        toggleIsApplying: () => setIsApplying(not),
        isCreating,
        toggleIsCreating: () => setIsCreating(not),
        isSaving,
        toggleIsSaving: () => setIsSaving(not),
        isLoadingEditPage,
        toggleIsLoadingEditPage: () => setIsLoadingEditPage(not),
        allFields: allFields.current,
        isInternational,
      }}
    >
      {props.children}
    </TemplateContext.Provider>
  );
};

export default TemplateContextProvider;
export const useTemplateContext = () => useContext(TemplateContext);
