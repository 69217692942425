App.controller("ServerStatusCtrl", [
  "$scope",
  "$rootScope",
  "$q",
  "PulseService",
  "$state",
  "UserService",
  function ($scope, $rootScope, $q, PulseService, $state, UserService) {
    $scope.serverState = PulseService.serverState;
    $scope.productImage = "assets/img/DA-logo.svg";
    function init() {
      PulseService.getServerStatus().then(
        function (serverState) {
          serverState.client_message = serverState.client_message.replace(
            "[Product_Name]",
            "Dealer Admin"
          );
          $scope.serverState = serverState;
          if (!serverState.outage) {
            let dealer_id = UserService.dealer_id;
            if (dealer_id) {
              $state.go("app.dealer.dashboard", { dealer_id: dealer_id });
            } else {
              $state.go("page.login");
            }
          } else {
            recheckServerStatus();
          }
        },
        function (error) {}
      );
    }

    function recheckServerStatus() {
      setTimeout(function () {
        init();
      }, 30000);
    }

    init();
  },
]);
