import React from "react";
import { joinSpaced } from "utils/string";
import styles from "./VideoSelection.module.css";

export default function VideoSelection({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={styles["container"]}>{children}</div>;
}

VideoSelection.Section = ({ children }: { children: React.ReactNode }) => {
  return <section className={styles["section"]}>{children}</section>;
};

VideoSelection.Icon = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles["icon"]}>
      <div className="feature__icon">{children}</div>
    </div>
  );
};

VideoSelection.Description = ({
  title,
  action = "",
  children = "",
}: {
  title: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <div className={styles["description"]}>
        <div className={styles["header"]}>
          <div className="feature__title">{title}</div>
          <div className={styles["action"]}>{action}</div>
        </div>
        <div className="feature__short-description">{children}</div>
      </div>
    </>
  );
};

VideoSelection.Checkbox = ({
  checked,
  ...props
}: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={joinSpaced(
        styles["checkbox"],
        checked ? styles["checkbox-checked"] : ""
      )}
    >
      <span
        className={joinSpaced(
          "icon-checkmark",
          styles["checkmark"],
          checked ? styles["checkmark-checked"] : ""
        )}
      ></span>
    </div>
  );
};
