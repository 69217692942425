/**
 * @generated SignedSource<<249aa72bb6c68b28e5808fe8d4e98257>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagingSetupDestination3UserField_messagingSetup$data = {
  readonly enableMessaging: boolean;
  readonly thirdEmailOrPhoneNumber: string;
  readonly userNumberForThirdContact: string;
  readonly " $fragmentType": "MessagingSetupDestination3UserField_messagingSetup";
};
export type MessagingSetupDestination3UserField_messagingSetup$key = {
  readonly " $data"?: MessagingSetupDestination3UserField_messagingSetup$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagingSetupDestination3UserField_messagingSetup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagingSetupDestination3UserField_messagingSetup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userNumberForThirdContact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thirdEmailOrPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableMessaging",
      "storageKey": null
    }
  ],
  "type": "MessagingSetup",
  "abstractKey": null
};

(node as any).hash = "3a1a4482abd1f90800c4127257d444bc";

export default node;
