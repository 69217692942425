import { GenericPageFallback } from "components/GenericPageFallback";
import React, { Suspense } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import GroupEdit from "./GroupEdit";

const GroupEditAngularEntryPoint = ({ RelayService, $stateParams }: any) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<GenericPageFallback />}>
        <GroupEdit
          key={`group-edit-${$stateParams.group_number}`}
          customerId={$stateParams.customer_id}
          controlSystemId={$stateParams.control_system_id}
          groupNumber={$stateParams.group_number}
        />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "groupEdit",
    react2angular(
      GroupEditAngularEntryPoint,
      [],
      ["RelayService", "$stateParams"]
    )
  );
};
