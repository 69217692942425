/**
 * @ngdoc service
 * @name App.factory:PanelTestService
 *
 * @description
 * API Interface service for panel tests.
 *
 */
App.factory("PanelTestService", [
  "$rootScope",
  "$q",
  "JobService",
  "PanelTestV2API",
  "PANEL_TEST_TYPES",
  "DealerSettingsAPI",
  "Panel",
  function (
    $rootScope,
    $q,
    JobService,
    PanelTestV2API,
    PANEL_TEST_TYPES,
    DealerSettingsAPI,
    Panel
  ) {
    return {
      /**
       * A list of PanelTests for the current session
       */
      tests: [],

      /**
       * Makes an API call to one of the test APIs.
       * @param {String} panelId The panel to send the test call to
       * @param {String} testType The type of test we're calling (PANEL_TEST_TYPES)
       * @param {String} testMethod The type of test we're calling ('begin', 'end')
       * @param {Object} requestBody (Optional) parameters to send with the request
       * @returns {type} Deferred Promise
       */
      callTestAPI: function (
        panelId,
        testType,
        testMethod,
        requestBody,
        requestJobStatus = true
      ) {
        var deferred = $q.defer();
        var _this = this;

        // Parse our testType
        switch (testType) {
          case PANEL_TEST_TYPES.SENSOR_TEST:
          case PANEL_TEST_TYPES.WIRELESS_TEST:
          case PANEL_TEST_TYPES.PIR_WALK_TEST:
            testType = "walk_test";
            break;
        }

        PanelTestV2API.callAPI(
          {
            //params
            panel_id: panelId,
            test: testType,
            method: testMethod,
          },
          requestBody,
          function (data) {
            if (requestJobStatus) {
              // we don't want to request job status on a PIR walk test
              JobService.process(data.job.uuid)
                .then(
                  function (jobResult) {
                    // JobService process completed
                    if (jobResult.job.details.code == "602") {
                      new Panel(panelId).disconnect();
                    }
                    deferred.resolve(jobResult);
                  },
                  function (error) {
                    // An error occurred getting the job status
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              deferred.resolve();
            }
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      /**
       * Makes an API call to get test status messages for the given dealer, panel, and test type.
       * @param {String} dealerId The dealer associated with the panel
       * @param {String} panelId The panel to get test events for
       * @param {String} testType The type of test we're getting events for (PANEL_TEST_TYPES)
       * @returns {type} Deferred Promise A PanelTestStatus object from the Dealer API
       */
      getTestEvents: function (dealerId, panelId, testType) {
        var deferred = $q.defer();
        var _this = this;
        var subcategory = "";

        // Convert our panel test type to a SEMPro event subcategory
        switch (testType) {
          case PANEL_TEST_TYPES.COMM_TEST:
            subcategory =
              "Event::Subcategory::SystemMessage::CommTestFinalResult";
            break;
          case PANEL_TEST_TYPES.SENSOR_TEST:
            subcategory = "Event::Subcategory::SystemMessage::WalkTestZoneTrip";
            break;
          case PANEL_TEST_TYPES.WIRELESS_TEST:
            subcategory =
              "Event::Subcategory::SystemMessage::WalkTestWirelessCheckIn";
            break;
          case PANEL_TEST_TYPES.ZWAVE_DIAGNOSTICS_TEST:
            subcategory = "Event::Subcategory::SystemMessage::ZwaveTestCheckIn";
            break;
          case PANEL_TEST_TYPES.ZWAVE_OPTIMIZATION:
            subcategory =
              "Event::Subcategory::SystemMessage::ZwaveOptimizeDeviceStatus";
            break;
        }

        DealerSettingsAPI.getTestEvents(
          {
            //params
            dealer_id: dealerId,
            id: panelId,
            subcategory: subcategory,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },
    };
  },
]);
