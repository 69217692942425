import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { MessagingSetupContextProvider } from "../common/MessagingSetupFields/MessagingSetupContext";
import MessagingSetupDestination1Field from "../common/MessagingSetupFields/MessagingSetupDestination1Field";
import MessagingSetupDestination1UserField from "../common/MessagingSetupFields/MessagingSetupDestination1UserField";
import MessagingSetupDestination2Field from "../common/MessagingSetupFields/MessagingSetupDestination2Field";
import MessagingSetupDestination2UserField from "../common/MessagingSetupFields/MessagingSetupDestination2UserField";
import MessagingSetupDestination3Field from "../common/MessagingSetupFields/MessagingSetupDestination3Field";
import MessagingSetupDestination3UserField from "../common/MessagingSetupFields/MessagingSetupDestination3UserField";
import MessagingSetupMessagingEnabledField from "../common/MessagingSetupFields/MessagingSetupMessagingEnabledField";
import MessagingSetupSendSmsOnOpeningAndClosingField from "../common/MessagingSetupFields/MessagingSetupSendSmsOnOpeningAndClosingField";
import MessagingSetupSystemNameField from "../common/MessagingSetupFields/MessagingSetupSystemNameField";
import {
  TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem$data,
  TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem.graphql";
import refreshMutationConcreteRequest, {
  TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupRefreshMutation,
} from "./__generated__/TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupRefreshMutation.graphql";
import {
  TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupSendMutation,
  TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupSendMutation$data,
} from "./__generated__/TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupSendMutation.graphql";
import { TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem$key } from "./__generated__/TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem.graphql";

export const title = "Messaging Setup";
export const conceptId = "xt-messaging-setup";

export const getState = (
  controlSystem: TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          messagingSetup {
            id
            enableMessaging
            systemName
            firstEmailOrPhoneNumber
            secondEmailOrPhoneNumber
            thirdEmailOrPhoneNumber
            userNumberForFirstContact
            userNumberForSecondContact
            userNumberForThirdContact
            sendSmsOnOpeningAndClosing
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupRefreshMutation(
    $id: ID!
  ) {
    refreshMessagingSetup(id: $id) {
      ... on RefreshMessagingSetupSuccessPayload {
        __typename
        controlSystem {
          ...TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, __typename, error } =
              response.refreshMessagingSetup;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Messaging Setup Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshMessagingSetup: {
                    __typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Messaging Setup: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Messaging Setup",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupSendMutation(
    $systemId: ID!
    $messagingSetup: MessagingSetupInput!
  ) {
    sendMessagingSetupProgramming(
      systemId: $systemId
      messagingSetup: $messagingSetup
    ) {
      ... on SendMessagingSetupProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendMessagingSetupProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupSendMutation$data,
  originalControlSystemData: TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendMessagingSetupProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshMessagingSetup: {
          __typename: "RefreshMessagingSetupSuccessPayload",
          controlSystem: getState(
            response.sendMessagingSetupProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: TakeoverPanelMessagingSetupProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TakeoverPanelMessagingSetupProgrammingConceptFormMessagingSetupSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { messagingSetup },
        } = getState(props.controlSystem);
        if (messagingSetup) {
          save({
            variables: {
              systemId,
              messagingSetup: {
                enableMessaging: messagingSetup.enableMessaging,
                systemName: messagingSetup.systemName,
                firstEmailOrPhoneNumber: messagingSetup.firstEmailOrPhoneNumber,
                secondEmailOrPhoneNumber:
                  messagingSetup.secondEmailOrPhoneNumber,
                thirdEmailOrPhoneNumber: messagingSetup.thirdEmailOrPhoneNumber,
                userNumberForFirstContact:
                  messagingSetup.userNumberForFirstContact,
                userNumberForSecondContact:
                  messagingSetup.userNumberForSecondContact,
                userNumberForThirdContact:
                  messagingSetup.userNumberForThirdContact,
                sendSmsOnOpeningAndClosing:
                  messagingSetup.sendSmsOnOpeningAndClosing,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendMessagingSetupProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);

                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendMessagingSetupProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendMessagingSetupProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [{ panel }] =
    useControlSystemFragment<TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem on ControlSystem {
          panel {
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            messagingSetup {
              ...MessagingSetupContext_messagingSetup
              ...MessagingSetupMessagingEnabledField_messagingSetup
              ...MessagingSetupSystemNameField_messagingSetup
              ...MessagingSetupDestination1Field_messagingSetup
              ...MessagingSetupDestination1UserField_messagingSetup
              ...MessagingSetupDestination2Field_messagingSetup
              ...MessagingSetupDestination2UserField_messagingSetup
              ...MessagingSetupDestination3Field_messagingSetup
              ...MessagingSetupDestination3UserField_messagingSetup
              ...MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseSoftwareDate_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    messagingSetup,
    helpFiles: { programmingGuideUrl },
  } = panel;
  //The help link for messaging only goes the the base installation manual. Messaging Setup is a concept available for old firmware.
  // Mention of it can only be found in discontinued documentation.
  return (
    <PanelContextProvider panel={panel}>
      <MessagingSetupContextProvider messagingSetup={messagingSetup}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(messagingSetup)}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <MessagingSetupMessagingEnabledField />
              <MessagingSetupSystemNameField />
              <MessagingSetupDestination1Field />
              <MessagingSetupDestination1UserField />
              <MessagingSetupDestination2Field />
              <MessagingSetupDestination2UserField />
              <MessagingSetupDestination3Field />
              <MessagingSetupDestination3UserField />
              <MessagingSetupSendSmsOnOpeningAndClosingField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </MessagingSetupContextProvider>
    </PanelContextProvider>
  );
}
