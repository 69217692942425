/**
 * @generated SignedSource<<7f5b5a383733ccd598cb8edf5f8c2c4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type ZoneRemoteZoneType = "LOCAL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type XT75ZoneInformationsProgrammingConceptForm_controlSystem$data = {
  readonly copiedZoneInformation: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly newZone: {
      readonly id: string;
      readonly isNew: boolean;
      readonly number: string;
      readonly remoteZoneType: ZoneRemoteZoneType | null;
      readonly " $fragmentSpreads": FragmentRefs<"XT75ZoneInformationsFields_zone">;
    } | null;
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly useBuiltIn1100Wireless?: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextHouseCode_systemOptions" | "SystemOptionsContextSystemType_systemOptions" | "SystemOptionsContext_systemOptions">;
    };
    readonly totalWirelessZoneMax: number;
    readonly totalZonesMax: number;
    readonly zoneInformations: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly name: string;
      readonly number: string;
      readonly remoteZoneType: ZoneRemoteZoneType | null;
      readonly " $fragmentSpreads": FragmentRefs<"XT75ZoneInformationsFields_zone" | "XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone">;
    }>;
    readonly zoneNumberRange: ReadonlyArray<number>;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextHelpFiles_panel" | "PanelContextUseAreaList_panel" | "PanelContextUseBuiltIn1100WirelessEnabled_panel" | "PanelContextUseHardwareModel_panel" | "PanelContextUseHas1100T_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContextUseSupports1100T_panel" | "PanelContextUseSupportsVplex_panel" | "PanelContextUseSupportsXR550_panel" | "PanelContextUseVplexNumbers_panel" | "PanelContextUseWirelessZoneNumberRange_panel" | "PanelContextUseZoneIsWirelessOnly_panel" | "PanelContextUseZoneNumberRange_panel" | "PanelContext_panel" | "ZoneInformationNumberField_panel" | "ZoneInformationSerialNumberField_ZoneList_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem">;
  readonly " $fragmentType": "XT75ZoneInformationsProgrammingConceptForm_controlSystem";
};
export type XT75ZoneInformationsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75ZoneInformationsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75ZoneInformationsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNew",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "XT75ZoneInformationsFields_zone"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remoteZoneType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75ZoneInformationsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zoneNumberRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalWirelessZoneMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalZonesMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ZoneInformationNumberField_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "zoneInformations",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "XT75ZoneInformationsProgrammingConceptForm_ZoneListItemLabel_zone"
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "newZone",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "useBuiltIn1100Wireless",
                  "storageKey": null
                }
              ],
              "type": "XrSystemOptions",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContext_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextHouseCode_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseZoneIsWirelessOnly_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHas1100T_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseZoneNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseVplexNumbers_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsVplex_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupports1100T_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsXR550_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseWirelessZoneNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseBuiltIn1100WirelessEnabled_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseAreaList_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextHelpFiles_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ZoneInformationSerialNumberField_ZoneList_panel"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "copiedZoneInformation",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "ab8b8d53f636abded8655c3f39910424";

export default node;
