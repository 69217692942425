/**
 * @ngdoc directive
 * @name App.directive:rotateFlip
 *
 * @description
 *   toggles a rotate class on an element
 *   used to toggle the rotation of a dropdown list icon
 *
 */

App.directive("daRotateFlip", function () {
  var first = true;
  return {
    restrict: "A",
    scope: {
      flag: "=daRotateFlip",
    },
    link: function (scope, element) {
      scope.$watch("flag", function () {
        element.toggleClass("rotated", scope.flag);
        first = true;
      });
    },
  };
});
