import DaColors from "app/common/DaColors";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  min-height: 100%;
  width: 100%
  background-color: ${DaColors.Neutral250};
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 4.8rem;
`;
const Content = styled.div`
  border-radius: 0.4rem;
  border: 0.1rem solid ${DaColors.Neutral25};
  background-color: ${DaColors.White};
  padding: 1.6rem 5.6rem 3.2rem 5.6rem;
`;
const Title = styled.div`
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: ${DaColors.Failure500};
`;
const Body = styled.div`
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

type Props = {
  title: string;
  text: string;
};

const SystemNotAvailable = ({ title, text }: Props) => (
  <Wrapper>
    <Content>
      <Title>{title}</Title>
      <Body>{text}</Body>
    </Content>
  </Wrapper>
);

export default SystemNotAvailable;
