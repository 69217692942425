/**
 * @generated SignedSource<<3865d8fed82d156eee499d063591f248>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InspectionReminder = "NONE" | "ONE_YEAR" | "SIX_MONTHS" | "THREE_MONTHS";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsInspectionReminderField_systemOptions$data = {
  readonly inspectionReminder?: InspectionReminder | null;
  readonly " $fragmentType": "SystemOptionsInspectionReminderField_systemOptions";
};
export type SystemOptionsInspectionReminderField_systemOptions$key = {
  readonly " $data"?: SystemOptionsInspectionReminderField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsInspectionReminderField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsInspectionReminderField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inspectionReminder",
          "storageKey": null
        }
      ],
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "fa535a8b6d3654e9dae140de11f9c105";

export default node;
