import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { lockoutCodeFieldId } from "components/FullProgramming/common/LockoutCodeFields/LockoutCodeField";
import { XFLockoutCodeProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XFFullProgramming/__generated__/XFLockoutCodeProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getLockoutCodeState } from "../../../XFFullProgramming/XFLockoutCodeProgrammingConceptForm";
import { ProgrammingTemplateLockoutCodeInput } from "../Create/__generated__/XFTemplateSaveMutation.graphql";
import { XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        lockoutCode {
          id
          included
          code {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XFLockoutCodeProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateLockoutCodeInput => {
  const {
    panel: { lockoutCode },
  } = getLockoutCodeState(controlSystem);

  if (!lockoutCode) {
    return { included: false };
  }

  return {
    included: includedFields.has(lockoutCodeFieldId()),
    code: {
      included: includedFields.has(lockoutCodeFieldId()),
      data: lockoutCode.code,
    },
  };
};

export const setIncludedFields = (
  template: XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { lockoutCode } = getState(template);

  if (lockoutCode?.code?.included) {
    setIncludedFields(setToggle(lockoutCodeFieldId()));
  }
};
