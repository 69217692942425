import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

export type BaseInputStylesProps = { isInvalid?: boolean };

export const baseInputStyles = css<BaseInputStylesProps>`
  ${({ isInvalid }) =>
    isInvalid
      ? css`
          border-color: var(--color-state-warning) !important;
        `
      : ""};
`;

export const BaseInput = styled.input<BaseInputStylesProps>`
  ${baseInputStyles};
`;

export type BaseInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  BaseInputStylesProps;
