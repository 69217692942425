import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsClosingCodeField_systemOptions$key } from "./__generated__/SystemOptionsClosingCodeField_systemOptions.graphql";

export const systemOptionsClosingCodeFieldId = () =>
  "system-options-closing-code";

function ClosingCodeField() {
  const [{ closingCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsClosingCodeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsClosingCodeField_systemOptions on SystemOptions {
          ... on XtSystemOptions {
            closingCode
          }
          ... on TMSentrySystemOptions {
            closingCode
          }
        }
      `
    );

  const fieldId = systemOptionsClosingCodeFieldId();
  const label = "Closing Code";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="If enabled, a User Code is required to arm the System."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={closingCode}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!closingCode, "closingCode");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ClosingCodeField;
