/**
 * @generated SignedSource<<cb932772eddbb6b27d0df6ae237bd272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseSupportsPrivateDoor_panel$data = {
  readonly supportsPrivateDoor: boolean;
  readonly " $fragmentType": "PanelContextUseSupportsPrivateDoor_panel";
};
export type PanelContextUseSupportsPrivateDoor_panel$key = {
  readonly " $data"?: PanelContextUseSupportsPrivateDoor_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseSupportsPrivateDoor_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseSupportsPrivateDoor_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportsPrivateDoor",
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "89f67abaae056431b3ffcb5dc4580edf";

export default node;
