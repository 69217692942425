/**
 * @generated SignedSource<<134d764456eb87bcba37662f5fda8be1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay$data = {
  readonly burglaryZoneKeypads: string;
  readonly " $fragmentType": "StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay";
};
export type StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay$key = {
  readonly " $data"?: StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "burglaryZoneKeypads",
      "storageKey": null
    }
  ],
  "type": "StatusListDisplay",
  "abstractKey": null
};

(node as any).hash = "35afa39abbf5d1547e91d1408ff8b2e6";

export default node;
