/**
 *
 * @ngdoc service
 * @name App.factory:store-dashboard-service.js
 *
 * @description
 * API Interface service for store-dashboard.
 *
 **/

App.factory("StoreDashboardService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "UserService",
  "StoreOrdersAPI",
  "DealerSettingsAPI",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    UserService,
    StoreOrdersAPI,
    DealerSettingsAPI
  ) {
    return {
      /**
       * Get orders and order data from Company Store (ordered) and Dealer API (shipped)
       * @param dealerId
       */
      getOrdersForDealer: function (dealerId) {
        var deferred = $q.defer();
        var createdPromise = this.getCreatedOrders(dealerId);
        var shippedPromise = this.getShippedOrders(dealerId);

        // Now we merge the shippedOrders into the createdOrders so
        // that we have all current orders to pull data from.

        $q.all([createdPromise, shippedPromise])
          .then(
            function (values) {
              // Creates an array for the orders
              var createdOrders = values[0];
              var shippedOrders = values[1];
              angular.forEach(createdOrders.orders, function (order) {
                for (var i = 0; i < shippedOrders.length; i++) {
                  if (shippedOrders[i].AlarmStoreOrderId == order.id) {
                    order.shipment_info = shippedOrders[i];
                    break;
                  }
                }
              });
              deferred.resolve(createdOrders);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * retrieves created orders from Company Store API
       * @param dealerId
       */
      getCreatedOrders: function (dealerId) {
        var deferred = $q.defer();
        StoreOrdersAPI.indexOrders(
          { dealer_id: dealerId, status: "all", per_page: 100000 },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * retrieves shippedOrders from Dealer Settings API
       * @param dealerId
       */
      getShippedOrders: function (dealerId) {
        var deferred = $q.defer();
        DealerSettingsAPI.getOrdersSummary(
          { dealer_id: dealerId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * retrieves a single order from Company Store API
       * @param dealerId
       * @param orderId
       */
      getCreatedOrder: function (dealerId, orderId) {
        var deferred = $q.defer();
        StoreOrdersAPI.indexOrders(
          { dealer_id: dealerId, order_id: orderId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Approve order from Company Store or Secura
       * @param dealerId
       * @param orderId
       */
      approveOrder: function (dealerId, orderId) {
        var deferred = $q.defer();
        StoreOrdersAPI.approveOrder(
          { dealer_id: dealerId, order_id: orderId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Decline order from Company Store or Secura
       * @param dealerId
       * @param orderId
       */
      declineOrder: function (dealerId, orderId) {
        var deferred = $q.defer();
        StoreOrdersAPI.declineOrder(
          { dealer_id: dealerId, order_id: orderId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Get Sales statistics from the Company Store
       * @param dealerId
       * @param time_grouping: 'year' or 'month'
       * @param monthRange: {beginMonth: 1, endMonth: 3, beginYear: 2016, endYear: 2017} --Used for returning multiple months can be used in a dashboard of sales later on. *OPTIONAL*
       */
      getSalesStatistics: function (dealerId, time_grouping, monthRange) {
        if (time_grouping == "month" && monthRange) {
          var begin = new Date(
            monthRange.beginYear,
            monthRange.beginMonth - 1,
            1
          )
            .toISOString()
            .split("T")[0];
          var end = new Date(monthRange.endYear, monthRange.endMonth - 1, 0)
            .toISOString()
            .split("T")[0];
        } else if (time_grouping == "month") {
          var begin = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          )
            .toISOString()
            .split("T")[0];
          var end = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          )
            .toISOString()
            .split("T")[0];
        } else if (time_grouping == "year") {
          var begin = new Date(new Date().getFullYear(), 0, 1)
            .toISOString()
            .split("T")[0];
          var end = new Date(new Date().getFullYear(), 11, 31)
            .toISOString()
            .split("T")[0];
        }
        var deferred = $q.defer();
        StoreOrdersAPI.getSalesStatistics(
          {
            dealer_id: dealerId,
            begin: begin,
            end: end,
            time_grouping: time_grouping,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
