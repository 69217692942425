import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { commPathAlarmReportsFieldId } from "components/FullProgramming/common/CommPathFields/CommPathAlarmReportsField";
import { commPathAlarmSwitchoverFieldId } from "components/FullProgramming/common/CommPathFields/CommPathAlarmSwitchoverField";
import { commPathCellApn2FieldId } from "components/FullProgramming/common/CommPathFields/CommPathCellApn2Field";
import { commPathCellApnFieldId } from "components/FullProgramming/common/CommPathFields/CommPathCellApnField";
import { commPathCheckInMinutesFieldId } from "components/FullProgramming/common/CommPathFields/CommPathCheckInMinutesField";
import { commPathCommTypeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathCommTypeField";
import { commPathDoorAccessReportsFieldId } from "components/FullProgramming/common/CommPathFields/CommPathDoorAccessReportsField";
import { commPathDuplicateAlarmsFieldId } from "components/FullProgramming/common/CommPathFields/CommPathDuplicateAlarmsField";
import { commPathEncryptionFieldId } from "components/FullProgramming/common/CommPathFields/CommPathEncryptionField";
import { commPathEncryptionSchemaFieldId } from "components/FullProgramming/common/CommPathFields/CommPathEncryptionSchemaField";
import { commPathFailTestHoursFieldId } from "components/FullProgramming/common/CommPathFields/CommPathFailTestHoursField";
import { commPathFailTimeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathFailTimeField";
import { commPathFirstPhoneNumberFieldId } from "components/FullProgramming/common/CommPathFields/CommPathFirstPhoneNumberField";
import {
  commPathListItemTemplateId,
  commPathNumberFieldId,
} from "components/FullProgramming/common/CommPathFields/CommPathNumberField";
import { commPathOpenCloseUserReportsFieldId } from "components/FullProgramming/common/CommPathFields/CommPathOpenCloseUserReportsField";
import { commPathPanicTestFieldId } from "components/FullProgramming/common/CommPathFields/CommPathPanicTestField";
import { commPathProtocolFieldId } from "components/FullProgramming/common/CommPathFields/CommPathProtocolField";
import { commPathReceiverIpAddressFieldId } from "components/FullProgramming/common/CommPathFields/CommPathReceiverIpAddressField";
import { commPathReceiverIpv6AddressFieldId } from "components/FullProgramming/common/CommPathFields/CommPathReceiverIpv6AddressField";
import { commPathReceiverPortFieldId } from "components/FullProgramming/common/CommPathFields/CommPathReceiverPortField";
import { commPathRetryTimeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathRetryTimeField";
import { commPathSecondLinePrefixFor893AFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSecondLinePrefixFor893AField";
import { commPathSecondPhoneNumberFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSecondPhoneNumberField";
import { commPathSendFailFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSendFailField";
import { commPathSendPathFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSendPathField";
import { commPathSubstitutionCodeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSubstitutionCodeField";
import { commPathSupervisoryTroubleReportsFieldId } from "components/FullProgramming/common/CommPathFields/CommPathSupervisoryTroubleReportsField";
import { commPathTestFrequencyDayFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTestFrequencyDayField";
import { commPathTestFrequencyNumberFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTestFrequencyNumberField";
import { commPathTestFrequencyUnitFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTestFrequencyUnitField";
import { commPathTestReportFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTestReportField";
import { commPathTestTimeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTestTimeField";
import { commPathTransmissionDelayFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTransmissionDelayField";
import { commPathTypeFieldId } from "components/FullProgramming/common/CommPathFields/CommPathTypeField";
import { commPathUse893AFieldId } from "components/FullProgramming/common/CommPathFields/CommPathUse893AField";
import { commPathUseCheckInFieldId } from "components/FullProgramming/common/CommPathFields/CommPathUseCheckInField";
import { commPathUseIPv6FieldId } from "components/FullProgramming/common/CommPathFields/CommPathUseIPv6Field";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XRCommunicationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/XRCommunicationProgrammingConceptForm/__generated__/XRCommunicationProgrammingConceptFormInline_controlSystem.graphql";
import * as React from "react";
import { readInlineData } from "react-relay";
import {
  getCommunicationPathState,
  getState as getCommunicationState,
} from "../../../XRFullProgramming/XRCommunicationProgrammingConceptForm";
import { XrProgrammingTemplateCommunicationPathInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRCommunicationPathTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRCommunicationPathTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  communicationPath: XRCommunicationPathTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRCommunicationPathTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        communication {
          id
          included
          number
          commType {
            included
            data
          }
          type {
            included
            data
          }
          transmissionDelay {
            included
            data
          }
          testReport {
            included
            data
          }
          testFrequencyNumber {
            included
            data
          }
          testFrequencyUnit {
            included
            data
          }
          testTime {
            included
            data
          }
          testDayOfWeek {
            included
            data
          }
          firstPhone {
            included
            data
          }
          secondPhone {
            included
            data
          }
          use893A {
            included
            data
          }
          secondLinePrefixFor893A {
            included
            data
          }
          alarmSwitchover {
            included
            data
          }
          alarmReports {
            included
            data
          }
          supervisoryTroubleReports {
            included
            data
          }
          openCloseUserReports {
            included
            data
          }
          doorAccessReports {
            included
            data
          }
          panicTest {
            included
            data
          }
          sendFail {
            included
            data
          }
          sendPath {
            included
            data
          }
          useCheckIn {
            included
            data
          }
          checkInMinutes {
            included
            data
          }
          failTime {
            included
            data
          }
          useIPv6 {
            included
            data
          }
          remoteIP {
            included
            data
          }
          remoteIPv6 {
            included
            data
          }
          receiverPort {
            included
            data
          }
          protocol {
            included
            data
          }
          retryTime {
            included
            data
          }
          substitutionCode {
            included
            data
          }
          apn {
            included
            data
          }
          apn2 {
            included
            data
          }
          duplicateAlarms {
            included
            data
          }
          failTestHours {
            included
            data
          }
          encryptionEnabled {
            included
            data
          }
          encryptionScheme {
            included
            data
          }
        }
      }
    `,
    communicationPath
  );

export const getInput = (
  controlSystem: XRCommunicationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateCommunicationPathInput[] => {
  const {
    panel: { communicationPaths },
  } = getCommunicationState(controlSystem);

  return communicationPaths
    ? communicationPaths
        .filter(isNotNullOrUndefined)
        .map(getCommunicationPathState)
        .map((communicationPath) => ({
          included: includedFields.has(
            commPathListItemTemplateId(String(communicationPath.number))
          ),
          number: communicationPath.number,
          commType: {
            included: includedFields.has(
              commPathCommTypeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.commType,
          },
          type: {
            included: includedFields.has(
              commPathTypeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.type,
          },
          transmissionDelay: {
            included: includedFields.has(commPathTransmissionDelayFieldId()),
            data: communicationPath.transmissionDelay,
          },
          testReport: {
            included: includedFields.has(
              commPathTestReportFieldId(String(communicationPath.number))
            ),
            data: communicationPath.testReport,
          },
          testFrequencyNumber: {
            included: includedFields.has(
              commPathTestFrequencyNumberFieldId(
                String(communicationPath.number)
              )
            ),
            data: communicationPath.testFrequencyNumber,
          },
          testFrequencyUnit: {
            included: includedFields.has(
              commPathTestFrequencyUnitFieldId(String(communicationPath.number))
            ),
            data: communicationPath.testFrequencyUnit,
          },
          testTime: {
            included: includedFields.has(
              commPathTestTimeFieldId(String(communicationPath.number))
            ),
            data: String(communicationPath.testTime).trim(),
          },
          testDayOfWeek: {
            included: includedFields.has(
              commPathTestFrequencyDayFieldId(String(communicationPath.number))
            ),
            data: communicationPath.testDayOfWeek,
          },
          firstPhone: {
            included: includedFields.has(
              commPathFirstPhoneNumberFieldId(String(communicationPath.number))
            ),
            data: communicationPath.firstPhone,
          },
          secondPhone: {
            included: includedFields.has(
              commPathSecondPhoneNumberFieldId(String(communicationPath.number))
            ),
            data: communicationPath.secondPhone,
          },
          use893A: {
            included: includedFields.has(
              commPathUse893AFieldId(String(communicationPath.number))
            ),
            data: communicationPath.use893A,
          },
          secondLinePrefixFor893A: {
            included: includedFields.has(
              commPathSecondLinePrefixFor893AFieldId(
                String(communicationPath.number)
              )
            ),
            data: communicationPath.secondLinePrefixFor893A,
          },
          alarmSwitchover: {
            included: includedFields.has(
              commPathAlarmSwitchoverFieldId(String(communicationPath.number))
            ),
            data: communicationPath.alarmSwitchover,
          },
          alarmReports: {
            included: includedFields.has(
              commPathAlarmReportsFieldId(String(communicationPath.number))
            ),
            data: communicationPath.alarmReports,
          },
          supervisoryTroubleReports: {
            included: includedFields.has(
              commPathSupervisoryTroubleReportsFieldId(
                String(communicationPath.number)
              )
            ),
            data: communicationPath.supervisoryTroubleReports,
          },
          openCloseUserReports: {
            included: includedFields.has(
              commPathOpenCloseUserReportsFieldId(
                String(communicationPath.number)
              )
            ),
            data: communicationPath.openCloseUserReports,
          },
          doorAccessReports: {
            included: includedFields.has(
              commPathDoorAccessReportsFieldId(String(communicationPath.number))
            ),
            data: communicationPath.doorAccessReports,
          },
          panicTest: {
            included: includedFields.has(
              commPathPanicTestFieldId(String(communicationPath.number))
            ),
            data: communicationPath.panicTest,
          },
          sendFail: {
            included: includedFields.has(
              commPathSendFailFieldId(String(communicationPath.number))
            ),
            data: communicationPath.sendFail,
          },
          sendPath: {
            included: includedFields.has(
              commPathSendPathFieldId(String(communicationPath.number))
            ),
            data: communicationPath.sendPath,
          },
          useCheckIn: {
            included: includedFields.has(
              commPathUseCheckInFieldId(String(communicationPath.number))
            ),
            data: communicationPath.useCheckIn,
          },
          checkInMinutes: {
            included: includedFields.has(
              commPathCheckInMinutesFieldId(String(communicationPath.number))
            ),
            data: communicationPath.checkInMinutes,
          },
          failTime: {
            included: includedFields.has(
              commPathFailTimeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.failTime,
          },
          useIPv6: {
            included: includedFields.has(
              commPathUseIPv6FieldId(String(communicationPath.number))
            ),
            data: communicationPath.useIPv6,
          },
          remoteIP: {
            included: includedFields.has(
              commPathReceiverIpAddressFieldId(String(communicationPath.number))
            ),
            data: communicationPath.remoteIP,
          },
          remoteIPv6: {
            included: includedFields.has(
              commPathReceiverIpv6AddressFieldId(
                String(communicationPath.number)
              )
            ),
            data: communicationPath.remoteIPv6,
          },
          receiverPort: {
            included: includedFields.has(
              commPathReceiverPortFieldId(String(communicationPath.number))
            ),
            data: communicationPath.receiverPort,
          },
          protocol: {
            included: includedFields.has(
              commPathProtocolFieldId(String(communicationPath.number))
            ),
            data: communicationPath.protocol,
          },
          retryTime: {
            included: includedFields.has(
              commPathRetryTimeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.retryTime,
          },
          substitutionCode: {
            included: includedFields.has(
              commPathSubstitutionCodeFieldId(String(communicationPath.number))
            ),
            data: communicationPath.substitutionCode,
          },
          apn: {
            included: includedFields.has(
              commPathCellApnFieldId(String(communicationPath.number))
            ),
            data: communicationPath.apn,
          },
          apn2: {
            included: includedFields.has(
              commPathCellApn2FieldId(String(communicationPath.number))
            ),
            data: communicationPath.apn2,
          },
          duplicateAlarms: {
            included: includedFields.has(
              commPathDuplicateAlarmsFieldId(String(communicationPath.number))
            ),
            data: communicationPath.duplicateAlarms,
          },
          failTestHours: {
            included: includedFields.has(
              commPathFailTestHoursFieldId(String(communicationPath.number))
            ),
            data: communicationPath.failTestHours,
          },
          encryptionEnabled: {
            included: includedFields.has(
              commPathEncryptionFieldId(String(communicationPath.number))
            ),
            data: communicationPath.encryptionEnabled,
          },
          encryptionScheme: {
            included: includedFields.has(
              commPathEncryptionSchemaFieldId(String(communicationPath.number))
            ),
            data: communicationPath.encryptionScheme,
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XRCommunicationPathTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: React.Dispatch<
    React.SetStateAction<IncludedTemplateFieldsType>
  >
) => {
  const { communication } = getState(template);

  if (!communication) {
    return;
  }

  communication.filter(isNotNullOrUndefined).forEach((commPath, index) => {
    const number = String(commPath.number);
    if (commPath.transmissionDelay?.included && index === 0) {
      setIncludedFields(setToggle(commPathTransmissionDelayFieldId()));
    }
    if (commPath.included) {
      setIncludedFields(
        setToggle(commPathListItemTemplateId(String(commPath.number)))
      );
      setIncludedFields(setToggle(commPathNumberFieldId(number)));
    }
    if (commPath.commType?.included) {
      setIncludedFields(setToggle(commPathCommTypeFieldId(number)));
    }
    if (commPath.type?.included) {
      setIncludedFields(setToggle(commPathTypeFieldId(number)));
    }
    if (commPath.testReport?.included) {
      setIncludedFields(setToggle(commPathTestReportFieldId(number)));
    }
    if (commPath.testFrequencyNumber?.included) {
      setIncludedFields(setToggle(commPathTestFrequencyNumberFieldId(number)));
    }
    if (commPath.testFrequencyUnit?.included) {
      setIncludedFields(setToggle(commPathTestFrequencyUnitFieldId(number)));
    }
    if (commPath.testTime?.included) {
      setIncludedFields(setToggle(commPathTestTimeFieldId(number)));
    }
    if (commPath.testDayOfWeek?.included) {
      setIncludedFields(setToggle(commPathTestFrequencyDayFieldId(number)));
    }
    if (commPath.firstPhone?.included) {
      setIncludedFields(setToggle(commPathFirstPhoneNumberFieldId(number)));
    }
    if (commPath.secondPhone?.included) {
      setIncludedFields(setToggle(commPathSecondPhoneNumberFieldId(number)));
    }
    if (commPath.use893A?.included) {
      setIncludedFields(setToggle(commPathUse893AFieldId(number)));
    }
    if (commPath.secondLinePrefixFor893A?.included) {
      setIncludedFields(
        setToggle(commPathSecondLinePrefixFor893AFieldId(number))
      );
    }
    if (commPath.alarmSwitchover?.included) {
      setIncludedFields(setToggle(commPathAlarmSwitchoverFieldId(number)));
    }
    if (commPath.alarmReports?.included) {
      setIncludedFields(setToggle(commPathAlarmReportsFieldId(number)));
    }
    if (commPath.supervisoryTroubleReports?.included) {
      setIncludedFields(
        setToggle(commPathSupervisoryTroubleReportsFieldId(number))
      );
    }
    if (commPath.openCloseUserReports?.included) {
      setIncludedFields(setToggle(commPathOpenCloseUserReportsFieldId(number)));
    }
    if (commPath.doorAccessReports?.included) {
      setIncludedFields(setToggle(commPathDoorAccessReportsFieldId(number)));
    }
    if (commPath.panicTest?.included) {
      setIncludedFields(setToggle(commPathPanicTestFieldId(number)));
    }
    if (commPath.sendFail?.included) {
      setIncludedFields(setToggle(commPathSendFailFieldId(number)));
    }
    if (commPath.sendPath?.included) {
      setIncludedFields(setToggle(commPathSendPathFieldId(number)));
    }
    if (commPath.useCheckIn?.included) {
      setIncludedFields(setToggle(commPathUseCheckInFieldId(number)));
    }
    if (commPath.checkInMinutes?.included) {
      setIncludedFields(setToggle(commPathCheckInMinutesFieldId(number)));
    }
    if (commPath.failTime?.included) {
      setIncludedFields(setToggle(commPathFailTimeFieldId(number)));
    }
    if (commPath.useIPv6?.included) {
      setIncludedFields(setToggle(commPathUseIPv6FieldId(number)));
    }
    if (commPath.remoteIP?.included) {
      setIncludedFields(setToggle(commPathReceiverIpAddressFieldId(number)));
    }
    if (commPath.remoteIPv6?.included) {
      setIncludedFields(setToggle(commPathReceiverIpv6AddressFieldId(number)));
    }
    if (commPath.receiverPort?.included) {
      setIncludedFields(setToggle(commPathReceiverPortFieldId(number)));
    }
    if (commPath.protocol?.included) {
      setIncludedFields(setToggle(commPathProtocolFieldId(number)));
    }
    if (commPath.retryTime?.included) {
      setIncludedFields(setToggle(commPathRetryTimeFieldId(number)));
    }
    if (commPath.substitutionCode?.included) {
      setIncludedFields(setToggle(commPathSubstitutionCodeFieldId(number)));
    }
    if (commPath.apn?.included) {
      setIncludedFields(setToggle(commPathCellApnFieldId(number)));
    }
    if (commPath.apn2?.included) {
      setIncludedFields(setToggle(commPathCellApn2FieldId(number)));
    }
    if (commPath.duplicateAlarms?.included) {
      setIncludedFields(setToggle(commPathDuplicateAlarmsFieldId(number)));
    }
    if (commPath.failTestHours?.included) {
      setIncludedFields(setToggle(commPathFailTestHoursFieldId(number)));
    }
    if (commPath.encryptionEnabled?.included) {
      setIncludedFields(setToggle(commPathEncryptionFieldId(number)));
    }
    if (commPath.encryptionScheme?.included) {
      setIncludedFields(setToggle(commPathEncryptionSchemaFieldId(number)));
    }
  });
};
