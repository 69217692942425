/**
 * @generated SignedSource<<105356ed38498c6a5fe30e3fb13bd535>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DealerActivityRootQuery$variables = {
  queryString: string;
};
export type DealerActivityRootQuery$data = {
  readonly searchDealerActivity: ReadonlyArray<{
    readonly advancedReports: number;
    readonly apps: number;
    readonly autoProgrammed: number;
    readonly cellular: number;
    readonly dealerId: number;
    readonly doorsOfAccess: number;
    readonly id: string;
    readonly mtdSystems: number;
    readonly mtdVarianceSystems: number;
    readonly name: string;
    readonly systems: number;
    readonly videoVerification: number;
    readonly ytdSales: number;
    readonly ytdSalesVariance: number;
    readonly ytdSystems: number;
    readonly ytdVarianceSystems: number;
  }>;
};
export type DealerActivityRootQuery = {
  response: DealerActivityRootQuery$data;
  variables: DealerActivityRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "queryString"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "queryString",
        "variableName": "queryString"
      }
    ],
    "concreteType": "DealerActivity",
    "kind": "LinkedField",
    "name": "searchDealerActivity",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dealerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ytdSales",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ytdSalesVariance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systems",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cellular",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apps",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorsOfAccess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "advancedReports",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "videoVerification",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "autoProgrammed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ytdSystems",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mtdSystems",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ytdVarianceSystems",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mtdVarianceSystems",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DealerActivityRootQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DealerActivityRootQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b0d12b082f660b288ed8ecb01c38c2b8",
    "id": null,
    "metadata": {},
    "name": "DealerActivityRootQuery",
    "operationKind": "query",
    "text": "query DealerActivityRootQuery(\n  $queryString: String!\n) {\n  searchDealerActivity(queryString: $queryString) {\n    id\n    dealerId\n    name\n    ytdSales\n    ytdSalesVariance\n    systems\n    cellular\n    apps\n    doorsOfAccess\n    advancedReports\n    videoVerification\n    autoProgrammed\n    ytdSystems\n    mtdSystems\n    ytdVarianceSystems\n    mtdVarianceSystems\n  }\n}\n"
  }
};
})();

(node as any).hash = "bece5b41913adf91e5431aa3f5c088d0";

export default node;
