/**
 * @generated SignedSource<<c94e1a1862faffec5110cf3606c80813>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DoorListFragment_controlSystemQuery$variables = {
  id: string;
};
export type DoorListFragment_controlSystemQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"DoorListFragment_controlSystem">;
  } | null;
};
export type DoorListFragment_controlSystemQuery = {
  response: DoorListFragment_controlSystemQuery$data;
  variables: DoorListFragment_controlSystemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DoorListFragment_controlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DoorListFragment_controlSystem"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DoorListFragment_controlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystemDoorsConnection",
                "kind": "LinkedField",
                "name": "doorsConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Door",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tracked",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "realTimeStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "strikeDelay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1583c5b266a67782082b2af598ef6e9",
    "id": null,
    "metadata": {},
    "name": "DoorListFragment_controlSystemQuery",
    "operationKind": "query",
    "text": "query DoorListFragment_controlSystemQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...DoorListFragment_controlSystem\n    id\n  }\n}\n\nfragment DoorListFragment_controlSystem on ControlSystem {\n  id\n  doorsConnection {\n    nodes {\n      id\n      name\n      ...DoorWidgetFragment_door\n    }\n  }\n}\n\nfragment DoorWidgetFragment_door on Door {\n  id\n  name\n  tracked\n  realTimeStatus\n  strikeDelay\n  number\n  isPublic\n  status\n}\n"
  }
};
})();

(node as any).hash = "880fc06e67d9fa6fb9a785c28fceb78b";

export default node;
