import React from "react";
import styles from "./Panel.module.css";

export default function Panel({
  header,
  children,
  maxHeight,
}: {
  header?: React.ReactNode;
  children?: React.ReactNode;
  maxHeight?: number;
}) {
  return (
    <section className={styles.panel} style={{ maxHeight: `${maxHeight}px` }}>
      {header ? (
        <header className={styles["panel-header"]}>{header}</header>
      ) : null}
      {!!children && (
        <article className={styles["panel-content"]}>{children}</article>
      )}
    </section>
  );
}
