/**
 * @generated SignedSource<<75d9f328da3bf265b5aa2e92f5c640c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75FullProgrammingContainer_controlSystem$data = {
  readonly name: string;
  readonly panel: {
    readonly softwareVersion: string;
    readonly supportsCustomCardFormats: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"XT75FullProgrammingContainer_templateStatus_controlSystem">;
  readonly " $fragmentType": "XT75FullProgrammingContainer_controlSystem";
};
export type XT75FullProgrammingContainer_controlSystem$key = {
  readonly " $data"?: XT75FullProgrammingContainer_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75FullProgrammingContainer_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75FullProgrammingContainer_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportsCustomCardFormats",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75FullProgrammingContainer_templateStatus_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "f932fc4bf5bdac1cf8a663f779ae2dd5";

export default node;
