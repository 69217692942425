/**
 * @generated SignedSource<<96085e11ecbd5f4d3e99fa007c504766>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type XFDeviceSetupProgrammingConceptForm_controlSystem$data = {
  readonly copiedDeviceInformation: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly deviceInformations: ReadonlyArray<{
      readonly deviceType?: DeviceInformationType;
      readonly id: string;
      readonly isNew: boolean;
      readonly lxNumber?: string;
      readonly name: string;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XFDeviceInformationsFields_deviceInformation">;
    }>;
    readonly deviceNumberRange: ReadonlyArray<number>;
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly keypadBusDevicesMax: number;
    readonly newDevice: {
      readonly deviceType?: DeviceInformationType;
      readonly id: string;
      readonly isNew: boolean;
      readonly lxNumber?: string;
      readonly name: string;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XFDeviceInformationsFields_deviceInformation">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly totalDevicesMax: number;
    readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationSerialNumberField_deviceSerialNumberList_panel" | "PanelContextGetAllDeviceIds_panel" | "PanelContextUseDeviceNumberRange_panel" | "PanelContextUseHardwareModel_panel" | "PanelContextUseHas1100T_panel" | "PanelContextUseRemoteZoneList_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContextUseSupports1100T_panel" | "PanelContextUseSupportsAxBus_panel" | "PanelContextUseSupportsCustomCardFormats_panel" | "PanelContextUseSupportsNetworkExpander_panel" | "PanelContextUseSupportsPrivateDoor_panel" | "PanelContextUseSupportsVplex_panel" | "PanelContextUseSupportsXR550_panel" | "PanelContextUseSupportsXVCamera_panel" | "PanelContext_panel" | "XFDeviceInformationNumberField_panel">;
  };
  readonly " $fragmentType": "XFDeviceSetupProgrammingConceptForm_controlSystem";
};
export type XFDeviceSetupProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XFDeviceSetupProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceSetupProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isNew",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "XFDeviceInformationsFields_deviceInformation"
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lxNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceType",
        "storageKey": null
      }
    ],
    "type": "XfDeviceInformation",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFDeviceSetupProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalDevicesMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keypadBusDevicesMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceNumberRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "XFDeviceInformationNumberField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseRemoteZoneList_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "deviceInformations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "newDevice",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseDeviceNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupports1100T_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsXR550_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHas1100T_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsNetworkExpander_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsXVCamera_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextGetAllDeviceIds_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsVplex_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsAxBus_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsPrivateDoor_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSupportsCustomCardFormats_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeviceInformationSerialNumberField_deviceSerialNumberList_panel"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "copiedDeviceInformation",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "1507de3d9ef8ed7091ca59260f9b4cda";

export default node;
