import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import AdvancedFields from "components/AdvancedFields";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, RemoteOptions } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import RemoteOptionsApnField from "../common/RemoteOptionsFields/RemoteOptionsApnField";
import RemoteOptionsAppKeyField from "../common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { RemoteOptionsContextProvider } from "../common/RemoteOptionsFields/RemoteOptionsContext";
import RemoteOptionsEntreBackupConnectionField from "../common/RemoteOptionsFields/RemoteOptionsEntreBackupConnectionField";
import RemoteOptionsEntreBackupIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsEntreBackupIpAddress";
import RemoteOptionsEntreBackupTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsEntreBackupTcpPortField";
import RemoteOptionsEntreCheckInMinutesField from "../common/RemoteOptionsFields/RemoteOptionsEntreCheckInMinutesField";
import RemoteOptionsEntreConnectionField from "../common/RemoteOptionsFields/RemoteOptionsEntreConnectionField";
import RemoteOptionsEntreIncomingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsEntreIncomingTcpPortField";
import RemoteOptionsEntreIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsEntreIpAddressField";
import RemoteOptionsEntreOutgoingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsEntreOutgoingTcpPortField";
import RemoteOptionsEntrePassphraseField from "../common/RemoteOptionsFields/RemoteOptionsEntrePassphraseField";
import RemoteOptionsEntreSupervisoryReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreSupervisoryReportsField";
import RemoteOptionsEntreUserCommandReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreUserCommandReportsField";
import RemoteOptionsEntreZoneReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreZoneReportsField";
import RemoteOptionsIntegratorBackupConnectionField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupConnectionField";
import RemoteOptionsIntegratorBackupIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupIpAddressField";
import RemoteOptionsIntegratorBackupTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupTcpPortField";
import RemoteOptionsIntegratorConnectionField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorConnectionField";
import RemoteOptionsIntegratorIncomingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorIncomingTcpPortField";
import RemoteOptionsIntegratorIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorIpAddressField";
import RemoteOptionsIntegratorOutgoingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorOutgoingTcpPortField";
import RemoteOptionsIntegratorPassphraseField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorPassphraseField";
import RemoteOptionsIntegratorSupervisoryReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorSupervisoryReportsField";
import RemoteOptionsIntegratorUserCommandReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorUserCommandReportsField";
import RemoteOptionsIntegratorZoneReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorZoneReportsField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XFRemoteOptionsProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XFRemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XFRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation,
} from "./__generated__/XFRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XFRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation,
  XFRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
} from "./__generated__/XFRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation.graphql";
import { XFRemoteOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XFRemoteOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Remote Options";
export const conceptId = "xf-remote-options";

export const getState = (
  controlSystem: XFRemoteOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFRemoteOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          remoteOptions {
            ... on XfRemoteOptions {
              __typename
              id
              remoteKey
              firstApn
              appKey
              entreConnection
              entreIncomingPort
              entreIpAddress
              entreZoneReports
              entreUserCommandReports
              entreSupervisoryReports
              entreBackupConnection
              entreOutgoingPort
              entreBackupIpAddress
              entreBackupIncomingPort
              entreCheckInMinutes
              validEntreCheckinMinutes
              entrePassphrase
              integratorConnection
              integratorIncomingPort
              integratorIpAddress
              integratorZoneReports
              integratorUserCommandReports
              integratorSupervisoryReports
              integratorOutgoingPort
              integratorBackupConnection
              integratorBackupIpAddress
              integratorBackupIncomingPort
              integratorPassphrase
            }
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XFRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshRemoteOptions(id: $id) {
      ... on RefreshRemoteOptionsSuccessPayload {
        __typename
        controlSystem {
          ...XFRemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XFRemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshRemoteOptions, isRefreshing] =
    useMutation<XFRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshRemoteOptions({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshRemoteOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Remote Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshRemoteOptions: {
                    __typename: response.refreshRemoteOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Remote Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Remote Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XFRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation(
    $systemId: ID!
    $remoteOptions: XfRemoteOptionsInput!
  ) {
    sendXfRemoteOptionsProgramming(
      systemId: $systemId
      remoteOptions: $remoteOptions
    ) {
      ... on SendRemoteOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XFRemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendRemoteOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XFRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXfRemoteOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(sendMutationConcreteRequest, {
      id: response.sendXfRemoteOptionsProgramming.controlSystem.id,
    });
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        sendXtRemoteOptionsProgramming: {
          __typename: response.sendXfRemoteOptionsProgramming.__typename,
          controlSystem: getState(
            response.sendXfRemoteOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFRemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendRemoteOptions, isSending] =
    useMutation<XFRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { remoteOptions },
        } = getState(props.controlSystem);
        if (remoteOptions?.__typename === "XfRemoteOptions") {
          sendRemoteOptions({
            variables: {
              systemId,
              remoteOptions: {
                appKey: remoteOptions.appKey,
                firstApn: remoteOptions.firstApn,
                entreConnection: remoteOptions.entreConnection,
                entreIncomingPort: remoteOptions.entreIncomingPort,
                entreIpAddress: remoteOptions.entreIpAddress,
                entreOutgoingPort: remoteOptions.entreOutgoingPort,
                entreBackupConnection: remoteOptions.entreBackupConnection,
                entreBackupIpAddress: remoteOptions.entreBackupIpAddress,
                entreBackupIncomingPort: remoteOptions.entreBackupIncomingPort,
                entreCheckInMinutes: remoteOptions.entreCheckInMinutes,
                entreZoneReports: remoteOptions.entreZoneReports,
                entreUserCommandReports: remoteOptions.entreUserCommandReports,
                entreSupervisoryReports: remoteOptions.entreSupervisoryReports,
                entrePassphrase: remoteOptions.entrePassphrase,
                integratorConnection: remoteOptions.integratorConnection,
                integratorIncomingPort: remoteOptions.integratorIncomingPort,
                integratorIpAddress: remoteOptions.integratorIpAddress,
                integratorZoneReports: remoteOptions.integratorZoneReports,
                integratorUserCommandReports:
                  remoteOptions.integratorUserCommandReports,
                integratorSupervisoryReports:
                  remoteOptions.integratorSupervisoryReports,
                integratorOutgoingPort: remoteOptions.integratorOutgoingPort,
                integratorBackupConnection:
                  remoteOptions.integratorBackupConnection,
                integratorBackupIpAddress:
                  remoteOptions.integratorBackupIpAddress,
                integratorBackupIncomingPort:
                  remoteOptions.integratorBackupIncomingPort,
                integratorPassphrase: remoteOptions.integratorPassphrase,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXfRemoteOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(response, parentRelayEnv);
              } else if (response.sendXfRemoteOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXfRemoteOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSending,
  ];
};

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

const readRemoteOptionsTemplateData = (
  programmingTemplateConcepts: XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          included
          remoteKey {
            included
            data
          }

          appKey {
            included
            data
          }
          firstApn {
            included
            data
          }
          entreConnection {
            included
            data
          }
          entreIncomingPort {
            included
            data
          }
          entreIpAddress {
            included
            data
          }
          entreOutgoingPort {
            included
            data
          }
          entreBackupConnection {
            included
            data
          }
          entreBackupIpAddress {
            included
            data
          }
          entreBackupIncomingPort {
            included
            data
          }
          entreCheckInMinutes {
            included
            data
          }
          entreZoneReports {
            included
            data
          }
          entreUserCommandReports {
            included
            data
          }
          entreSupervisoryReports {
            included
            data
          }
          validEntreCheckinMinutes {
            included
            data
          }
          entrePassphrase {
            included
            data
          }
          integratorConnection {
            included
            data
          }
          integratorIncomingPort {
            included
            data
          }
          integratorIpAddress {
            included
            data
          }
          integratorOutgoingPort {
            included
            data
          }
          integratorBackupConnection {
            included
            data
          }
          integratorBackupIpAddress {
            included
            data
          }
          integratorBackupIncomingPort {
            included
            data
          }
          integratorZoneReports {
            included
            data
          }
          integratorUserCommandReports {
            included
            data
          }
          integratorSupervisoryReports {
            included
            data
          }
          integratorPassphrase {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).remoteOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XFRemoteOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readRemoteOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const remoteOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "remoteOptions",
      "RemoteOptions"
    ) as unknown as RecordProxy<RemoteOptions>;

    applyTemplateScalarDataToRecordProxy(
      remoteOptionsRecordProxy,
      templateData
    );
  }
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFRemoteOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFRemoteOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            remoteOptions {
              __typename
              ... on XfRemoteOptions {
                integratorConnection
                integratorBackupConnection
                entreConnection
                entreBackupConnection
              }
              ...RemoteOptionsContext_remoteOptions
              ...RemoteOptionsRemoteKeyField_remoteOptions
              ...RemoteOptionsApnField_remoteOptions
              ...RemoteOptionsAppKeyField_remoteOptions
              # Integrator Section
              ...RemoteOptionsIntegratorConnectionField_remoteOptions
              ...RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions
              ...RemoteOptionsIntegratorIpAddressField_remoteOptions
              ...RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions
              ...RemoteOptionsIntegratorBackupConnectionField_remoteOptions
              ...RemoteOptionsIntegratorBackupIpAddressField_remoteOptions
              ...RemoteOptionsIntegratorBackupTcpPortField_remoteOptions
              ...RemoteOptionsIntegratorZoneReportsField_remoteOptions
              ...RemoteOptionsIntegratorUserCommandReportsField_remoteOptions
              ...RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions
              ...RemoteOptionsIntegratorPassphraseField_remoteOptions
              # Entré Section
              ...RemoteOptionsEntreConnectionField_remoteOptions
              ...RemoteOptionsEntreIncomingTcpPortField_remoteOptions
              ...RemoteOptionsEntreIpAddressField_remoteOptions
              ...RemoteOptionsEntreOutgoingTcpPortField_remoteOptions
              ...RemoteOptionsEntreBackupConnectionField_remoteOptions
              ...RemoteOptionsEntreBackupIpAddressField_remoteOptions
              ...RemoteOptionsEntreBackupTcpPortField_remoteOptions
              ...RemoteOptionsEntreZoneReportsField_remoteOptions
              ...RemoteOptionsEntreUserCommandReportsField_remoteOptions
              ...RemoteOptionsEntreSupervisoryReportsField_remoteOptions
              ...RemoteOptionsEntreCheckInMinutesField_remoteOptions
              ...RemoteOptionsEntrePassphraseField_remoteOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    panel: {
      remoteOptions,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <RemoteOptionsContextProvider
        remoteOptions={controlSystem.panel.remoteOptions}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Remote%20Options`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(remoteOptions)}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            {remoteOptions?.__typename === "XfRemoteOptions" && (
              <>
                <ProgrammingConceptForm.Fields>
                  <RemoteOptionsApnField />
                  <RemoteOptionsAppKeyField />
                </ProgrammingConceptForm.Fields>
                <AdvancedFields label="Integrator">
                  <RemoteOptionsIntegratorConnectionField />
                  <RemoteOptionsIntegratorIncomingTcpPortField />
                  <RemoteOptionsIntegratorIpAddressField />
                  <RemoteOptionsIntegratorOutgoingTcpPortField />
                  <RemoteOptionsIntegratorBackupConnectionField />
                  <RemoteOptionsIntegratorBackupIpAddressField />
                  <RemoteOptionsIntegratorBackupTcpPortField />
                  <RemoteOptionsIntegratorZoneReportsField />
                  <RemoteOptionsIntegratorUserCommandReportsField />
                  <RemoteOptionsIntegratorSupervisoryReportsField />
                  <RemoteOptionsIntegratorPassphraseField />
                </AdvancedFields>
                <AdvancedFields label="Entré">
                  <ProgrammingConceptForm.Fields>
                    <RemoteOptionsEntreConnectionField />
                    <RemoteOptionsEntreIncomingTcpPortField />
                    <RemoteOptionsEntreIpAddressField />
                    <RemoteOptionsEntreOutgoingTcpPortField />
                    <RemoteOptionsEntreBackupConnectionField />
                    <RemoteOptionsEntreBackupIpAddressField />
                    <RemoteOptionsEntreBackupTcpPortField />
                    <RemoteOptionsEntreZoneReportsField />
                    <RemoteOptionsEntreUserCommandReportsField />
                    <RemoteOptionsEntreSupervisoryReportsField />
                    <RemoteOptionsEntreCheckInMinutesField />
                    <RemoteOptionsEntrePassphraseField />
                  </ProgrammingConceptForm.Fields>
                </AdvancedFields>
              </>
            )}
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </RemoteOptionsContextProvider>
    </PanelContextProvider>
  );
}
