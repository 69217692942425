/**
 * @ngdoc api
 * @name App.factory:DealerInvoicesApi
 *
 * @description
 * API factory for Dealer Invoices
 *
 */

App.factory("DealerInvoicesApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/DealerInvoices/:type/:dealer_id/:dealer_code/:invoice_number",
      {},
      {
        getInvoices: { method: "GET", params: {}, isArray: true },
      }
    );
  },
]);
