/**
 * @generated SignedSource<<2df869091c9e7755b5222919e60a0566>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommPathSendFailField_communicationPath$data = {
  readonly number: number;
  readonly sendFail: boolean;
  readonly " $fragmentType": "CommPathSendFailField_communicationPath";
};
export type CommPathSendFailField_communicationPath$key = {
  readonly " $data"?: CommPathSendFailField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathSendFailField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathSendFailField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendFail",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "100067345f6b599868f4bfb9187123b8";

export default node;
