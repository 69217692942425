import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationClosingCheckField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationClosingCheckField_systemAreaInformation.graphql";

export const systemAreaInformationClosingCheckFieldId = () =>
  `system-area-information-closing-check`;

function SystemAreaInformationClosingCheckField() {
  const [{ closingCheck }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationClosingCheckField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationClosingCheckField_systemAreaInformation on SystemAreaInformation {
          closingCheck
        }
      `
    );

  const fieldId = systemAreaInformationClosingCheckFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Closing Check"
      tooltip="If enabled, the System will verify that all Areas are armed after permanent or extended Schedules expire."
    >
      <Switch
        label="Closing Check"
        id={fieldId}
        checked={closingCheck}
        onChange={() => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(!closingCheck, "closingCheck");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationClosingCheckField;
