/**
 * @generated SignedSource<<45395dd243f58de70dd0862492f702bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KeyfobSerialNumberField_keyfob$data = {
  readonly id: string;
  readonly keyfobSerialNumber: string;
  readonly number: number;
  readonly " $fragmentType": "KeyfobSerialNumberField_keyfob";
};
export type KeyfobSerialNumberField_keyfob$key = {
  readonly " $data"?: KeyfobSerialNumberField_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobSerialNumberField_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobSerialNumberField_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyfobSerialNumber",
      "storageKey": null
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "be66e5115b7263a013180957b5cb519a";

export default node;
