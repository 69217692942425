App.directive("daSliderMenuToggle", function () {
  return {
    restrict: "A",

    link: function (scope, element, attrs) {
      element.bind("click", function (e) {
        element.parent().toggleClass("toggled");
      });
    },
  };
});
