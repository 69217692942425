/**
 *
 * CycleClockwiseIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const CycleClockwiseIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M2.732,42.209c1.824,5.648,5.146,10.725,9.694,14.596l0.048-0.056C18.005,61.445,24.867,64,32.055,64
      c0.229,0,0.459-0.003,0.689-0.009c3.425-0.078,6.777-0.731,9.966-1.94l-1.418-3.74c-2.763,1.049-5.67,1.615-8.64,1.684
      c-7,0.131-13.674-2.469-18.819-7.393c-5.448-5.216-8.539-12.328-8.701-20.025c-0.003-0.149,0.003-0.3,0.002-0.449
      c-0.021-6.593,2.159-12.889,6.128-17.899l0.076,6.021l4-0.051L15.172,7.22L2.975,7.61l0.129,3.998l5.209-0.167
      C3.646,17.24,1.075,24.607,1.134,32.216c0.001,0.176-0.007,0.349-0.003,0.526c0.033,1.596,0.189,3.173,0.447,4.724
      c0.003,0.021,0.006,0.039,0.009,0.058c0.082,0.49,0.177,0.979,0.281,1.463c0.009,0.047,0.019,0.096,0.028,0.145
      c0.104,0.471,0.22,0.939,0.344,1.405c0.014,0.054,0.027,0.108,0.042,0.163c0.129,0.472,0.269,0.941,0.417,1.408
      C2.71,42.141,2.721,42.175,2.732,42.209z"
        />
        <path
          d="M52.79,43.752l-4,0.051l0.163,12.977l12.199-0.391l-0.127-3.998l-5.211,0.166c4.756-5.912,7.346-13.453,7.183-21.215
      c-0.187-8.769-3.714-16.877-9.937-22.832c-5.918-5.665-13.621-8.698-21.679-8.501c-3.475,0.078-6.875,0.749-10.104,1.993
      l1.438,3.732c2.798-1.078,5.744-1.66,8.757-1.729c7.016-0.144,13.676,2.468,18.822,7.394c5.449,5.215,8.539,12.328,8.701,20.025
      c0.143,6.729-2.066,13.263-6.129,18.413L52.79,43.752z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default CycleClockwiseIcon;
