/**
 * @generated SignedSource<<85451f5db4b0d2bbf58d02351daf05e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRLockoutCodeProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XRLockoutCodeProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRLockoutCodeProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRLockoutCodeProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRLockoutCodeProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRLockoutCodeProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "6fe6f3d474a777188f99fae38a606c28";

export default node;
