/**
 * rgba utils
 * @author: Chad Watson and Alex Matthews
 *
 */
import { curry } from "ramda";

const expandHexShorthand = hex =>
  hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => r + r + g + g + b + b
  );

const hexToRgb = hex => {
  const pattern = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = pattern.exec(expandHexShorthand(hex));
  return result ? result.slice(1).map(n => parseInt(n, 16)) : null;
};

const rgba = curry((a, color) => {
  const rgb = hexToRgb(color);
  return rgb ? `rgba(${rgb.join(",")},${a})` : color;
});
export default rgba;
