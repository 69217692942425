/**
 * @generated SignedSource<<5ed1b3df0221252499b763c9eee0a9cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteOutputModulesForm_OutputModuleForm_siteControlSystem$data = {
  readonly id: string;
  readonly maxOutputModules: number;
  readonly namedDoors: ReadonlyArray<{
    readonly name: string;
  }>;
  readonly outputModulesConnection: {
    readonly nodes: ReadonlyArray<{
      readonly address: number;
      readonly id: string;
    }>;
  };
  readonly serialNumber: string;
  readonly " $fragmentType": "SiteOutputModulesForm_OutputModuleForm_siteControlSystem";
};
export type SiteOutputModulesForm_OutputModuleForm_siteControlSystem$key = {
  readonly " $data"?: SiteOutputModulesForm_OutputModuleForm_siteControlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesForm_OutputModuleForm_siteControlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteOutputModulesForm_OutputModuleForm_siteControlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxOutputModules",
      "storageKey": null
    },
    {
      "alias": "namedDoors",
      "args": null,
      "concreteType": "SiteDoor",
      "kind": "LinkedField",
      "name": "doors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteOutputModulesConnection",
      "kind": "LinkedField",
      "name": "outputModulesConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteOutputModule",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "7fdc4c29c395601c16493ac8fe9aa4d9";

export default node;
