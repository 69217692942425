App.factory("BusinessAnalyticsODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/dealers(:dealer_id)/:concept",
      {},
      {
        getAccountOverviewData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetBaAccountOverviews()",
          },
        },
        getCellularData: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetBaCellulars()" },
        },
        getVirtualKeypadData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetBaVirtualKeyPads()",
          },
        },
        getSystemsData: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetBaSystems()" },
        },
        getAccountOverviewTotalsData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetBaAccountOverviewTotals()",
          },
        },
        getCellularTotalsData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetBaCellularTotals()",
          },
        },
        getVirtualKeypadTotalsData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetBaVirtualKeyPadTotals()",
          },
        },
        getSystemsTotalsData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetBaSystemTotals()",
          },
        },
      }
    );
  },
]);
