import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsZonesReportsEnabledField_pcLogReports$key } from "./__generated__/PcLogReportsZonesReportsEnabledField_pcLogReports.graphql";

export const pcLogReportsZonesReportsEnabledFieldId = () =>
  "pc-log-reports-zones-reports-enabled";

function PcLogReportsZoneReportsEnabledField() {
  const [{ zonesReportsEnabled }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsZonesReportsEnabledField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsZonesReportsEnabledField_pcLogReports on PcLogReports {
          zonesReportsEnabled
        }
      `
    );

  const fieldId = pcLogReportsZonesReportsEnabledFieldId();
  const label = "Zones Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Sends changes in the status of active zones. Includes the zone number, name, type, the 
      action, user number (if applicable), and area name."
    >
      <Switch
        label={label}
        id={fieldId}
        checked={!!zonesReportsEnabled}
        onChange={() => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(!zonesReportsEnabled, "zonesReportsEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsZoneReportsEnabledField;
