/**
 * @generated SignedSource<<37a4c30753c0cdcac48111d229e41fd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathReceiverIpAddressField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly remoteIP: string;
  readonly useIPv6: boolean;
  readonly " $fragmentType": "CommPathReceiverIpAddressField_communicationPath";
};
export type CommPathReceiverIpAddressField_communicationPath$key = {
  readonly " $data"?: CommPathReceiverIpAddressField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathReceiverIpAddressField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathReceiverIpAddressField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteIP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useIPv6",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "ed5a2834202ac11fb9dbad0d9cc24abe";

export default node;
