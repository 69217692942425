App.factory("CredentialsWalletApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl +
        "/v2/credentials/:credentialId/delete_digital_card_credential",
      {},
      {
        removeCard: {
          method: "DELETE",
          params: { credentialId: "@credentialId" },
          interceptor: {
            response: function (response) {
              return response;
            },
            responseError: function (rejection) {
              console.error("Failed to delete digital card:", rejection);
              return rejection;
            },
          },
        },
      }
    );
  },
]);
