/**
 * @generated SignedSource<<13cbd7f52e091ec96ad05a794aa3f1ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathFirstPhoneNumberField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly firstPhone: string;
  readonly number: number;
  readonly " $fragmentType": "CommPathFirstPhoneNumberField_communicationPath";
};
export type CommPathFirstPhoneNumberField_communicationPath$key = {
  readonly " $data"?: CommPathFirstPhoneNumberField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathFirstPhoneNumberField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathFirstPhoneNumberField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstPhone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "911b2ded873037582703653f39b5c221";

export default node;
