App.factory("ClientEventsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/client_events/:dataType/:dataId/:category",
      {},
      {
        getInitialConnectionSucceeded: {
          method: "GET",
          params: {
            dataType: "panels",
            dataId: "@panelId",
            category: "initial_connection",
          },
        },
        getChangeConnectionInfoSucceeded: {
          method: "GET",
          params: {
            dataType: "panels",
            dataId: "@panelId",
            category: "change_connection_info",
          },
        },
      }
    );
  },
]);
