/**
 * @ngdoc service
 * @name App.factory: RefreshSessionAPI
 *
 * @description
 * API factory for refreshing a login when the jwt expires
 *
 */
App.factory("RefreshSessionAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v4/refresh_session",
      {},
      {
        refresh: {
          method: "POST",
          isArray: false,
          headers: { "Accessible-Type": PROPS.authAccessTypeKey },
        },
      }
    );
  },
]);
