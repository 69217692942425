import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathCellApnField_communicationPath$key } from "./__generated__/CommPathCellApnField_communicationPath.graphql";

export const commPathCellApnFieldId = (number: string) =>
  `comm-path-cell-apn-${number}`;

function CommPathCellApnField() {
  const [{ number, commType, apn }, updateCommPath] =
    useCommPathFragment<CommPathCellApnField_communicationPath$key>(
      graphql`
        fragment CommPathCellApnField_communicationPath on CommunicationPath {
          number
          commType
          apn
        }
      `
    );

  const fieldId = commPathCellApnFieldId(String(number));
  const softwareVersion = useSoftwareVersion();
  const disabled = !["CELL"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Cell APN"
      tooltip={
        softwareVersion >= 193
          ? "Enter the APN (Access Point Name). This is shared for any Cellular paths."
          : "Enter the first APN (Access Point Name). This is shared for any Cellular paths."
      }
      disabled={disabled}
    >
      {/* Default apn value comes from CommPathCommTypeField */}
      <TextInput
        id={fieldId}
        value={apn.toString()}
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "apn");
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "apn");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathCellApnField;
