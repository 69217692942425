/**
 * @generated SignedSource<<fc5df2939cdbc056e1a4ab76018e4726>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateVarHubSettingsInput = {
  deviceId?: number | null;
  disableAutoAddCameras?: boolean | null;
  immixCams?: ReadonlyArray<UpdateHubSettingsImmixCamera | null> | null;
  immixEnabled?: boolean | null;
  panelIntegrationGatewayIpAddress?: string | null;
  panelIntegrationPanelDiscoveryEnabled?: boolean | null;
  panelIntegrationPanelIpAddress?: string | null;
  password?: string | null;
  recipientDomain?: string | null;
  sendAutoSuppression?: boolean | null;
  smtpPort?: number | null;
  smtpServer?: string | null;
  user?: string | null;
};
export type UpdateHubSettingsImmixCamera = {
  camName?: string | null;
  camectCamId?: string | null;
  deviceId?: number | null;
  immixInput?: number | null;
};
export type RecorderAddCameraModalMutation$variables = {
  hubId: string;
  hubSettings: UpdateVarHubSettingsInput;
  systemId: string;
};
export type RecorderAddCameraModalMutation$data = {
  readonly updateVarHubSettings: {
    readonly __typename: "UpdateVarHubSettingsErrorPayload";
    readonly message: string | null;
    readonly type: string | null;
  } | {
    readonly __typename: "UpdateVarHubSettingsSuccessPayload";
    readonly status: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type RecorderAddCameraModalMutation = {
  response: RecorderAddCameraModalMutation$data;
  variables: RecorderAddCameraModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubSettings"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "hubId",
    "variableName": "hubId"
  },
  {
    "kind": "Variable",
    "name": "hubSettings",
    "variableName": "hubSettings"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderAddCameraModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateVarHubSettings",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "UpdateVarHubSettingsSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "UpdateVarHubSettingsErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecorderAddCameraModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateVarHubSettings",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "UpdateVarHubSettingsSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "UpdateVarHubSettingsErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9281b494351d55514c9beae44a992c4",
    "id": null,
    "metadata": {},
    "name": "RecorderAddCameraModalMutation",
    "operationKind": "mutation",
    "text": "mutation RecorderAddCameraModalMutation(\n  $hubSettings: UpdateVarHubSettingsInput!\n  $hubId: String!\n  $systemId: String!\n) {\n  updateVarHubSettings(hubSettings: $hubSettings, hubId: $hubId, systemId: $systemId) {\n    __typename\n    ... on UpdateVarHubSettingsSuccessPayload {\n      __typename\n      status\n    }\n    ... on UpdateVarHubSettingsErrorPayload {\n      __typename\n      type\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e283b87abef20ba67f0c1efefbafffaa";

export default node;
