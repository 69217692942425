// UserIsTechSupportContext.tsx

import React, { createContext, ReactNode, useContext, useState } from "react";

// Defining the context type
interface UserIsTechSupportContextType {
  isTechSupport: boolean;
  setIsTechSupport: (isTech: boolean) => void;
}

// Creating the context with a default value
const UserIsTechSupportContext =
  createContext<UserIsTechSupportContextType | null>(null);

// Creating a Provider component
interface UserIsTechSupportProviderProps {
  children: ReactNode;
}

export const UserIsTechSupportProvider: React.FC<UserIsTechSupportProviderProps> =
  ({ children }) => {
    const [isTechSupport, setIsTechSupport] = useState<boolean>(false);

    return (
      <UserIsTechSupportContext.Provider
        value={{ isTechSupport, setIsTechSupport }}
      >
        {children}
      </UserIsTechSupportContext.Provider>
    );
  };

// Custom hook for consuming the context
export const useUserIsTechSupport = () => {
  const context = useContext(UserIsTechSupportContext);
  if (!context) {
    throw new Error(
      "useUserIsTechSupport must be used within a UserIsTechSupportProvider"
    );
  }
  return context;
};
