/**
 * @ngdoc directive
 * @name App.directive:videoError
 * @function
 *
 * @description
 * Inserted via the clipPlayer directive.  Monitors the HTML Video tag's ability to play the selected clip.  If the clip
 * generates an error or fails to confirm playback capability within 1 second, video tag is replaced with error image.
 *
 */
App.directive(
  "daVideoError",
  function ($timeout, $compile, browser, $interval) {
    var templates = {};
    return {
      restrict: "A",
      priority: 1,
      link: function (scope, element, attrs) {
        var errorMessage = function () {
          element.replaceWith(
            $compile(
              '<div class="embed-responsive-item"><img class="embed-responsive-item" style="background-size: contain;" src="assets/img/play-error.jpg" /></div>'
            )(scope)
          );
        };

        // start the UI update process; save the timeoutId for canceling
        timeoutId = $interval(function () {
          errorMessage(); // update DOM
        }, 2500); //updated from 1000 ms to 2500 to allow larger HikVision clips to be processed.

        element.bind("error", function () {
          $interval.cancel(timeoutId);
          errorMessage();
        });
        element.bind("canplay", function () {
          $interval.cancel(timeoutId);
        });
        element.bind("loadedmetadata", function () {
          $interval.cancel(timeoutId);
        });
      },
    };
  }
);
