/**
 * @generated SignedSource<<58c63b98be62be49591b6333031eaad5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE";
import { FragmentRefs } from "relay-runtime";
export type SiteDiagnosticsDoorFragment_door$data = {
  readonly connectionStatus: ConnectionStatus;
  readonly name: string;
  readonly number: number;
  readonly " $fragmentType": "SiteDiagnosticsDoorFragment_door";
};
export type SiteDiagnosticsDoorFragment_door$key = {
  readonly " $data"?: SiteDiagnosticsDoorFragment_door$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsDoorFragment_door">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteDiagnosticsDoorFragment_door",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "type": "SiteDoor",
  "abstractKey": null
};

(node as any).hash = "4d8e563c4eb03fa23cfb0f4e6e63f9d0";

export default node;
