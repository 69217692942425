import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsArmDisarmActivityDaysField_systemOptions$key } from "./__generated__/SystemOptionsArmDisarmActivityDaysField_systemOptions.graphql";

export const systemOptionsArmDisarmActivityDaysFieldId = () =>
  "system-options-arm-disarm-activity-days";

function ArmDisarmActivityDaysField() {
  const [
    {
      armDisarmActivityDays,
      armDisarmActivityDaysMin,
      armDisarmActivityDaysMax,
    },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsArmDisarmActivityDaysField_systemOptions$key>(
    graphql`
      fragment SystemOptionsArmDisarmActivityDaysField_systemOptions on SystemOptions {
        ... on XrSystemOptions {
          armDisarmActivityDays
          armDisarmActivityDaysMin
          armDisarmActivityDaysMax
        }
        ... on XtSystemOptions {
          armDisarmActivityDays
          armDisarmActivityDaysMin
          armDisarmActivityDaysMax
        }
      }
    `
  );

  const originalValue = React.useRef(armDisarmActivityDays).current;
  const fieldId = systemOptionsArmDisarmActivityDaysFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Arm / Disarm Activity Days"
      tooltip="If enabled in Communication Programming, a message is sent to the Receiver when no Arm / Disarm activity occurs in this period."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={armDisarmActivityDays}
        inlineHelp={`${armDisarmActivityDaysMin}-${armDisarmActivityDaysMax}`}
        validationMessage={`Valid values are ${armDisarmActivityDaysMin}-${armDisarmActivityDaysMax}.`}
        min={armDisarmActivityDaysMin}
        max={armDisarmActivityDaysMax}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "armDisarmActivityDays"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(
                    armDisarmActivityDaysMin,
                    armDisarmActivityDaysMax,
                    valueAsNumber
                  );
            recordProxy.setValue(Number(value), "armDisarmActivityDays");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ArmDisarmActivityDaysField;
