import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const MessagingSetupContext = React.createContext<any>({});

export function MessagingSetupContextProvider(props: {
  messagingSetup: any;
  children: React.ReactNode;
}) {
  return (
    <MessagingSetupContext.Provider value={props.messagingSetup}>
      {props.children}
    </MessagingSetupContext.Provider>
  );
}

export function useMessagingSetupFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const messagingSetup = React.useContext(MessagingSetupContext);

  const data = useFragment(fragmentInput, messagingSetup as TKey);

  const { id } = useFragment(
    graphql`
      fragment MessagingSetupContext_messagingSetup on MessagingSetup {
        id
      }
    `,
    messagingSetup as any
  );

  const update = (
    updater: (messagingSetup: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
