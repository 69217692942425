import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ZoneEntryDelayNumber, ZoneType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationEntryDelayField_zone$key } from "./__generated__/ZoneInformationEntryDelayField_zone.graphql";

export const zoneInformationEntryDelayFieldId = (number: string) =>
  `zone-information-entry-delay-${number}`;

function ZoneInformationEntryDelayField() {
  const [{ number, entryDelayNumber, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationEntryDelayField_zone$key>(
      graphql`
        fragment ZoneInformationEntryDelayField_zone on Zone {
          number
          type
          entryDelayNumber
        }
      `
    );

  const fieldId = zoneInformationEntryDelayFieldId(String(number));
  const disabled = ![ZoneType.EXIT, ZoneType.FINAL_EXIT].includes(
    type as ZoneType
  );
  const hardwareModel = useHardwareModel();
  const supportsFourExitDelays = resolvePanelType(hardwareModel).isXr;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entry Delay"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={entryDelayNumber ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "entryDelayNumber");
          });
        }}
      >
        <Select.Option value={ZoneEntryDelayNumber.ONE}>1</Select.Option>
        <Select.Option value={ZoneEntryDelayNumber.TWO}>2</Select.Option>
        {supportsFourExitDelays && (
          <>
            <Select.Option value={ZoneEntryDelayNumber.THREE}>3</Select.Option>
            <Select.Option value={ZoneEntryDelayNumber.FOUR}>4</Select.Option>
          </>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationEntryDelayField;
