/**
 * @generated SignedSource<<8db9ca02276e3ccb7f930fcbe6841c6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputInformationFields_output$data = {
  readonly id: string;
  readonly number: number;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationContext_output" | "OutputInformationNameField_output" | "OutputInformationNumberField_output" | "OutputInformationRealTimeStatusField_output">;
  readonly " $fragmentType": "XFOutputInformationFields_output";
};
export type XFOutputInformationFields_output$key = {
  readonly " $data"?: XFOutputInformationFields_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputInformationFields_output">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFOutputInformationFields_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationContext_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationNumberField_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationNameField_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationRealTimeStatusField_output"
    }
  ],
  "type": "Output",
  "abstractKey": null
};

(node as any).hash = "18fdb3926343378c130e7dfaa29155bf";

export default node;
