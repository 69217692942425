import React from "react";
import EditReceiverForm from "./EditReceiverForm";
import NewReceiverForm from "./NewReceiverForm";
import { EditReceiverFormReceiver_receiver$key } from "./__generated__/EditReceiverFormReceiver_receiver.graphql";
import { NewReceiverFormModalDealer_dealer$key } from "./__generated__/NewReceiverFormModalDealer_dealer.graphql";

export const ReceiverForm: React.FC<
  React.PropsWithChildren<{
    receiverRef?: EditReceiverFormReceiver_receiver$key;
    dealerRef?: NewReceiverFormModalDealer_dealer$key;
    initialNumber?: number;
    onCancel: () => void;
  }>
> = ({ receiverRef, dealerRef, initialNumber, onCancel }) => {
  return receiverRef ? (
    <EditReceiverForm receiverRef={receiverRef} onCancel={onCancel} />
  ) : dealerRef ? (
    <NewReceiverForm
      dealerRef={dealerRef}
      initialNumber={initialNumber}
      onCancel={onCancel}
    />
  ) : null;
};
