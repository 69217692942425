/**
 *
 * AllIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const AllIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M60.246,64H21c-1.104,0-2-0.896-2-2v-9H2c-1.104,0-2-0.896-2-2V4.632c0-1.064-0.001-1.984,0.426-2.848
        C0.829,0.971,1.733,0,3.754,0h56.491c2.021,0,2.926,0.971,3.329,1.784C64.001,2.647,64,3.567,64,4.632v54.736
        c0,1.064,0.001,1.984-0.426,2.848C63.171,63.029,62.267,64,60.246,64z M23,60h36.996C60,59.794,60,59.565,60,59.37V4.63
        c0-0.195,0-0.424-0.004-0.63H4.004C4,4.206,4,4.435,4,4.63V49h17c1.104,0,2,0.896,2,2V60z"
          />
        </g>
        <g>
          <path
            d="M13,9c-2.2,0-4,1.8-4,4v27c0,2.2,1.8,4,4,4h15v7c0,2.2,1.8,4,4,4h19c2.2,0,4-1.8,4-4v-8c0-2.2,0-4.9,0-6s0-3.8,0-6V13
        c0-2.2-1.8-4-4-4H13z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default AllIcon;
