import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { Svg } from "components/CameraEditCommon/CameraEditStyledComponents";
import {
  motionDetectionRegionsSvgId,
  viewBoxMaxHeight,
  viewBoxMaxWidth,
} from "components/CameraEditCommon/constants";
import { RegionBounds, ViewBox } from "components/CameraEditCommon/types";
import {
  getKeyboardTransformer,
  getMouseTransformer,
  getRegionColor,
  removeIndexes,
  renderXCoordinate,
  renderYCoordinate,
} from "components/CameraEditCommon/utils";
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import {
  AnalyticalDetectionRegionCoordinates,
  SecureComCamera,
} from "securecom-graphql/client";
import { isNullOrUndefined } from "util";
import {
  AnalyticalDetectionLinesWithClientSchemaData,
  AnalyticalDetectionRegionsWithClientSchemaData,
} from "./type";
import UniviewAnalyticalDetectionLine from "./UniviewAnalyticalDetectionLine";
import UniviewAnalyticalDetectionRegion from "./UniviewAnalyticalDetectionRegion";
import {
  moveAnalyticalDetectionPoint,
  moveAnalyticalPointForKeyPress,
  moveLinePoint,
  moveLinePointForKeyPress,
} from "./utils";
import { UniviewAnalyticalDetectionElements_secureComCamera$key } from "./__generated__/UniviewAnalyticalDetectionElements_secureComCamera.graphql";

interface UniviewAnalyticalDetectionElementsProps {
  aspectRatio: number;
  camera: UniviewAnalyticalDetectionElements_secureComCamera$key;
  isEditable: boolean;
}
function UniviewAnalyticalDetectionElements(
  props: UniviewAnalyticalDetectionElementsProps
) {
  const { aspectRatio, camera, isEditable } = props;
  const data = useFragment(
    graphql`
      fragment UniviewAnalyticalDetectionElements_secureComCamera on SecureComCamera {
        id

        analyticalDetectionLines {
          activeDetectionLineIndex
          mouseDown

          minCoordinatesX
          maxCoordinatesX
          minCoordinatesY
          maxCoordinatesY

          lines {
            id

            activePointIndex
            index
            sensitivity
            direction
            startX
            startY
            endX
            endY
            detectPerson
            detectVehicle
          }
        }

        minAnalyticalDetectionRegionPoints
        maxAnalyticalDetectionRegionPoints
        analyticalDetectionRegions {
          activeDetectionRegionIndex
          mouseDown

          regions {
            id

            activeCoordinateIndexes
            index
            sensitivity
            coordinates {
              x
              y
            }
          }
        }
      }
    `,
    camera
  );
  const {
    id,
    analyticalDetectionLines,
    maxAnalyticalDetectionRegionPoints,
    analyticalDetectionRegions,
  } = data;
  const {
    activeDetectionLineIndex,
    minCoordinatesX,
    maxCoordinatesX,
    minCoordinatesY,
    maxCoordinatesY,
  } = analyticalDetectionLines;
  const { activeDetectionRegionIndex } = analyticalDetectionRegions;
  const relayEnv = useRelayEnvironment();

  const bounds = useMemo(
    () => ({
      minX: minCoordinatesX,
      maxX: maxCoordinatesX,
      minY: minCoordinatesY,
      maxY: maxCoordinatesY,
    }),
    [minCoordinatesX, maxCoordinatesX, minCoordinatesY, maxCoordinatesY]
  );
  const viewBox = useMemo(
    (): ViewBox =>
      aspectRatio <= viewBoxMaxWidth / viewBoxMaxHeight
        ? [viewBoxMaxWidth * aspectRatio, viewBoxMaxHeight]
        : [viewBoxMaxWidth, viewBoxMaxHeight / aspectRatio],
    [aspectRatio]
  );
  const xCoordinateRenderer = useCallback(renderXCoordinate(bounds)(viewBox), [
    bounds,
    viewBox,
  ]);
  const yCoordinateRenderer = useCallback(renderYCoordinate(bounds)(viewBox), [
    bounds,
    viewBox,
  ]);

  useLayoutEffect(() => {
    relayEnv.commitUpdate((store) => {
      const camera = store.get(id) as RecordProxy<SecureComCamera>;
      const lines = camera
        .getLinkedRecord("analyticalDetectionLines")
        .getLinkedRecords("lines");
      lines.forEach((line, index) => {
        line.setValue(index, "index");
      });
      const regions = camera
        .getLinkedRecord("analyticalDetectionRegions")
        .getLinkedRecords("regions");
      regions.forEach((region, index) => {
        region.setValue(index, "index");
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentKeyDownUpdater(store, id, event, bounds);
      });
    }
    function handleMouseDown(event: MouseEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseDownUpdater(store, id, event);
      });
    }
    function handleMouseUp(event: MouseEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseUpUpdater(store, id);
      });
    }
    function handleMouseMove(event: MouseEvent) {
      if (
        isNotNullOrUndefined(activeDetectionLineIndex) ||
        isNotNullOrUndefined(activeDetectionRegionIndex)
      ) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseMoveUpdater(store, id, event, bounds);
      });
    }

    if (isEditable) {
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (isEditable) {
        document.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [
    relayEnv,
    id,
    activeDetectionLineIndex,
    activeDetectionRegionIndex,
    bounds,
    viewBox,
  ]);

  const lines = useMemo(() => {
    if (isNullOrUndefined(activeDetectionLineIndex)) {
      return analyticalDetectionLines.lines;
    }

    const linesWithActiveOnTop = [...analyticalDetectionLines.lines];
    return linesWithActiveOnTop.concat(
      linesWithActiveOnTop.splice(activeDetectionLineIndex, 1)
    );
  }, [activeDetectionLineIndex, analyticalDetectionLines.lines]);

  const regions = useMemo(() => {
    if (isNullOrUndefined(activeDetectionRegionIndex)) {
      return analyticalDetectionRegions.regions;
    }

    const regionsWithActiveOnTop = [...analyticalDetectionRegions.regions];
    return regionsWithActiveOnTop.concat(
      regionsWithActiveOnTop.splice(activeDetectionRegionIndex, 1)
    );
  }, [activeDetectionRegionIndex, analyticalDetectionRegions.regions]);

  return (
    <Svg
      id={motionDetectionRegionsSvgId}
      viewBox={`0 0 ${viewBox[0]} ${viewBox[1]}`}
    >
      {regions.map((region) => (
        <UniviewAnalyticalDetectionRegion
          key={region.id}
          color={getRegionColor(region.index ?? -1)}
          index={region.index ?? -1}
          renderX={xCoordinateRenderer}
          renderY={yCoordinateRenderer}
          coordinates={
            region.coordinates as AnalyticalDetectionRegionCoordinates[]
          }
          activeCoordinateIndexes={
            (region.activeCoordinateIndexes as number[]) ?? []
          }
          isActive={activeDetectionRegionIndex === region.index}
          sensitivity={region.sensitivity}
          bounds={bounds}
          cameraId={id}
          canAddPoints={
            region.coordinates.length < maxAnalyticalDetectionRegionPoints
          }
          mouseDown={analyticalDetectionRegions.mouseDown}
        />
      ))}
      {lines.map((line) => (
        <UniviewAnalyticalDetectionLine
          key={line.id}
          color={getRegionColor(line.index ?? -1)}
          renderX={xCoordinateRenderer}
          renderY={yCoordinateRenderer}
          line={line}
          isActive={activeDetectionLineIndex === line.index}
          mouseDown={analyticalDetectionLines.mouseDown}
        />
      ))}
    </Svg>
  );
}

export default UniviewAnalyticalDetectionElements;

function documentKeyDownUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: KeyboardEvent,
  bounds: RegionBounds
) {
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;

  const analyticalDetectionLines = camera.getLinkedRecord(
    "analyticalDetectionLines"
  ) as RecordProxy<AnalyticalDetectionLinesWithClientSchemaData>;
  const lines = analyticalDetectionLines.getLinkedRecords("lines");
  const activeDetectionLineIndex = analyticalDetectionLines.getValue(
    "activeDetectionLineIndex"
  );

  const analyticalDetectionRegions = camera.getLinkedRecord(
    "analyticalDetectionRegions"
  ) as RecordProxy<AnalyticalDetectionRegionsWithClientSchemaData>;
  const regions = analyticalDetectionRegions.getLinkedRecords("regions");
  const activeDetectionRegionIndex = analyticalDetectionRegions.getValue(
    "activeDetectionRegionIndex"
  );

  if (
    isNullOrUndefined(activeDetectionLineIndex) &&
    isNullOrUndefined(activeDetectionRegionIndex)
  ) {
    return;
  }

  if (isNotNullOrUndefined(activeDetectionLineIndex)) {
    if (event.key === "Escape") {
      analyticalDetectionLines.setValue(null, "activeDetectionLineIndex");
    } else if (event.key === "Tab") {
      analyticalDetectionLines.setValue(
        (activeDetectionLineIndex + 1) % lines.length,
        "activeDetectionLineIndex"
      );
    } else if (event.key === "Backspace") {
      // Deleting line
      const activeLineId = lines[activeDetectionLineIndex].getValue("id");
      const filteredLines = analyticalDetectionLines
        .getLinkedRecords("lines")
        .filter((line) => line.getValue("id") !== activeLineId);

      filteredLines.forEach((line, index) => line.setValue(index, "index")); // Resetting index for lines
      analyticalDetectionLines.setLinkedRecords(filteredLines, "lines");
      analyticalDetectionLines.setValue(null, "activeDetectionLineIndex");
    } else if (
      isNotNullOrUndefined(activeDetectionLineIndex) &&
      lines[activeDetectionLineIndex]
    ) {
      moveLinePointForKeyPress(getKeyboardTransformer(bounds)(event))(
        lines[activeDetectionLineIndex]
      );
    }
  }
  if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
    if (event.key === "Escape") {
      analyticalDetectionRegions.setValue(null, "activeDetectionRegionIndex");
    } else if (event.key === "Tab") {
      analyticalDetectionRegions.setValue(
        (activeDetectionRegionIndex + 1) % regions.length,
        "activeDetectionRegionIndex"
      );
    } else if (event.key === "Backspace") {
      const activeRegion = regions[activeDetectionRegionIndex];
      const coordinates = activeRegion.getLinkedRecords("coordinates");
      const activeCoordinateIndexes = activeRegion.getValue(
        "activeCoordinateIndexes"
      );

      // Delete point(s) if remaining point(s) is greater than 2 to maintain a plain. Otherwise, delete the entire region.
      if (
        activeCoordinateIndexes?.length &&
        coordinates.length - activeCoordinateIndexes.length >=
          camera.getValue("minAnalyticalDetectionRegionPoints")
      ) {
        // Deleting region point(s)
        const remainingCoordinates = removeIndexes([
          ...activeCoordinateIndexes,
        ])(coordinates);

        activeRegion.setLinkedRecords(remainingCoordinates, "coordinates");
        activeRegion.setValue([], "activeCoordinateIndexes");
      } else {
        // Deleting region
        const activeRegionId = activeRegion.getValue("id");
        const filteredRegions = analyticalDetectionRegions
          .getLinkedRecords("regions")
          .filter((region) => region.getValue("id") !== activeRegionId);

        filteredRegions.forEach((region, index) =>
          region.setValue(index, "index")
        ); // Resetting index for regions
        analyticalDetectionRegions.setLinkedRecords(filteredRegions, "regions");
        analyticalDetectionRegions.setValue(null, "activeDetectionLineIndex");
      }
    } else if (
      isNotNullOrUndefined(activeDetectionRegionIndex) &&
      regions[activeDetectionRegionIndex]
    ) {
      moveAnalyticalPointForKeyPress(getKeyboardTransformer(bounds)(event))(
        regions[activeDetectionRegionIndex]
      );
    }
  }
}

function documentMouseDownUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: MouseEvent
) {
  const { target } = event;
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;

  const analyticalDetectionLines = camera.getLinkedRecord(
    "analyticalDetectionLines"
  ) as RecordProxy<AnalyticalDetectionLinesWithClientSchemaData>;
  const lines = analyticalDetectionLines.getLinkedRecords("lines");
  const activeDetectionLineIndex = analyticalDetectionLines.getValue(
    "activeDetectionLineIndex"
  );

  const analyticalDetectionRegions = camera.getLinkedRecord(
    "analyticalDetectionRegions"
  ) as RecordProxy<AnalyticalDetectionRegionsWithClientSchemaData>;
  const regions = analyticalDetectionRegions.getLinkedRecords("regions");
  const activeDetectionRegionIndex = analyticalDetectionRegions.getValue(
    "activeDetectionRegionIndex"
  );

  // Selecting/Unselecting line
  if (
    target instanceof Element &&
    target.id.match(/analytical-detection-line-[0-9]+/)
  ) {
    const idParts = target.id.split("-");
    const activeLineIndex = Number(idParts[3]);
    const activeLine = lines[activeLineIndex];
    const activePointIndex = activeLine.getValue("activePointIndex");

    if (idParts[4] === "point") {
      activeLine.setValue(Number(idParts[5]), "activePointIndex");
      analyticalDetectionLines.setValue(true, "mouseDown");
    } else if (isNotNullOrUndefined(activePointIndex)) {
      activeLine.setValue(null, "activePointIndex");
    } else {
      analyticalDetectionLines.setValue(
        activeLineIndex,
        "activeDetectionLineIndex"
      );
      analyticalDetectionLines.setValue(true, "mouseDown");
    }
  } else if (isNotNullOrUndefined(activeDetectionLineIndex)) {
    const activeLine = lines[activeDetectionLineIndex];
    if (isNotNullOrUndefined(activeLine)) {
      activeLine.setValue(null, "activePointIndex");
      analyticalDetectionLines.setValue(null, "activeDetectionLineIndex");
    }
    analyticalDetectionLines.setValue(true, "mouseDown");
  } else {
    analyticalDetectionLines.setValue(true, "mouseDown");
  }

  // Selecting/Unselecting region
  if (
    target instanceof Element &&
    target.id.match(/motion-detection-region-[0-9]+/)
  ) {
    const idParts = target.id.split("-");
    const activeRegionIndex = Number(idParts[3]);
    const activeRegion = regions[activeRegionIndex];
    const activeCoordinateIndexes = activeRegion.getValue(
      "activeCoordinateIndexes"
    );

    if (idParts[4] === "point") {
      const activeCoordinates = event.shiftKey
        ? new Set(activeCoordinateIndexes)
        : new Set<number>();
      activeCoordinates.add(Number(idParts[5]));
      activeRegion.setValue([...activeCoordinates], "activeCoordinateIndexes");
      analyticalDetectionRegions.setValue(true, "mouseDown");
    } else if (activeCoordinateIndexes?.length) {
      activeRegion.setValue([], "activeCoordinateIndexes");
    } else {
      analyticalDetectionRegions.setValue(
        activeRegionIndex,
        "activeDetectionRegionIndex"
      );
      analyticalDetectionRegions.setValue(true, "mouseDown");
    }
  } else if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
    const activeRegion = regions[activeDetectionRegionIndex];
    if (isNotNullOrUndefined(activeRegion)) {
      activeRegion.setValue([], "activeCoordinateIndexes");
      analyticalDetectionRegions.setValue(null, "activeDetectionRegionIndex");
    }
    analyticalDetectionRegions.setValue(true, "mouseDown");
  } else {
    analyticalDetectionRegions.setValue(true, "mouseDown");
  }
}

function documentMouseUpUpdater(store: RecordSourceProxy, cameraId: string) {
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;
  const analyticalDetectionLines = camera.getLinkedRecord(
    "analyticalDetectionLines"
  ) as RecordProxy<AnalyticalDetectionLinesWithClientSchemaData>;
  const analyticalDetectionRegions = camera.getLinkedRecord(
    "analyticalDetectionRegions"
  ) as RecordProxy<AnalyticalDetectionRegionsWithClientSchemaData>;
  analyticalDetectionLines.setValue(false, "mouseDown");
  analyticalDetectionRegions.setValue(false, "mouseDown");
}

function documentMouseMoveUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: MouseEvent,
  bounds: RegionBounds
) {
  const svgElement = document.getElementById(motionDetectionRegionsSvgId);
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;

  const analyticalDetectionLines = camera.getLinkedRecord(
    "analyticalDetectionLines"
  ) as RecordProxy<AnalyticalDetectionLinesWithClientSchemaData>;
  const lineMouseDown = analyticalDetectionLines.getValue("mouseDown");
  const lines = analyticalDetectionLines.getLinkedRecords("lines");
  const activeDetectionLineIndex = analyticalDetectionLines.getValue(
    "activeDetectionLineIndex"
  );

  const analyticalDetectionRegions = camera.getLinkedRecord(
    "analyticalDetectionRegions"
  ) as RecordProxy<AnalyticalDetectionRegionsWithClientSchemaData>;
  const regionMouseDown = analyticalDetectionRegions.getValue("mouseDown");
  const regions = analyticalDetectionRegions.getLinkedRecords("regions");
  const activeDetectionRegionIndex = analyticalDetectionRegions.getValue(
    "activeDetectionRegionIndex"
  );

  if (
    svgElement &&
    lineMouseDown &&
    isNotNullOrUndefined(activeDetectionLineIndex) &&
    lines[activeDetectionLineIndex]
  ) {
    moveLinePoint(getMouseTransformer(bounds)(svgElement)(event))(
      lines[activeDetectionLineIndex]
    );
  }

  if (
    svgElement &&
    regionMouseDown &&
    isNotNullOrUndefined(activeDetectionRegionIndex) &&
    regions[activeDetectionRegionIndex]
  ) {
    moveAnalyticalDetectionPoint(
      getMouseTransformer(bounds)(svgElement)(event)
    )(regions[activeDetectionRegionIndex]);
  }
}
