App.factory("TechTrackingAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/TechLocations/:stops/:option/:date/:dealer/:dealer_id/:user/:user_id",
      {},
      {
        sendTechLocation: {
          method: "POST",
          params: { dealer: "Dealer", dealer_id: "@dealer_id" },
        },
        getLiveTechLocations: {
          method: "GET",
          isArray: true,
          params: { dealer: "Dealer", dealer_id: "@dealer_id", option: "Live" },
        },
        getSingleTechLocations: {
          method: "GET",
          params: {
            dealer: "Dealer",
            dealer_id: "@dealer_id",
            user_id: "@user_id",
            user: "User",
          },
        },
        getSingleTechStops: {
          method: "GET",
          params: { stops: "Stops", user_id: "@user_id", user: "User" },
        },
        getSingleTechHistoryLocations: {
          method: "GET",
          params: {
            user_id: "@user_id",
            option: "Date",
            date: "@date",
            user: "User",
            dealer: "Dealer",
            dealer_id: "@dealer_id",
          },
        },
        getSingleTechHistoryStops: {
          method: "GET",
          params: {
            user_id: "@user_id",
            stops: "Stops",
            option: "Date",
            date: "@date",
            user: "User",
          },
        },
      }
    );
  },
]);
