/**
 * @generated SignedSource<<34d694cecae8df7e3c6b0e1ad4f4cf6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagEditTagRelationsQuery$variables = {
  tagId: string;
};
export type TagEditTagRelationsQuery$data = {
  readonly getTagRelationsById: {
    readonly tagCustomRoles: ReadonlyArray<number | null> | null;
    readonly tagCustomers: ReadonlyArray<number | null> | null;
    readonly tagPanels: ReadonlyArray<number | null> | null;
    readonly tagSites: ReadonlyArray<number | null> | null;
    readonly tagUsers: ReadonlyArray<number | null> | null;
  };
};
export type TagEditTagRelationsQuery = {
  response: TagEditTagRelationsQuery$data;
  variables: TagEditTagRelationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "tagId"
      }
    ],
    "concreteType": "TagRelations",
    "kind": "LinkedField",
    "name": "getTagRelationsById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagCustomers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagCustomRoles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagPanels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagSites",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tagUsers",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagEditTagRelationsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagEditTagRelationsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98aaa415f90564c1e43deb442daad83b",
    "id": null,
    "metadata": {},
    "name": "TagEditTagRelationsQuery",
    "operationKind": "query",
    "text": "query TagEditTagRelationsQuery(\n  $tagId: ID!\n) {\n  getTagRelationsById(id: $tagId) {\n    tagCustomers\n    tagCustomRoles\n    tagPanels\n    tagSites\n    tagUsers\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d2b1adf5fe7f4216f8ee2ba146477b0";

export default node;
