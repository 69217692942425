/**
 * @generated SignedSource<<b56c4088c3a6343e7ff1775d0f52a9e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRCommunicationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly communicationPaths: ReadonlyArray<{
      readonly __typename: "CommunicationPath";
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XRCommunicationProgrammingConceptFormInline_communicationPath">;
    }>;
    readonly id: string;
    readonly maxCommunicationPaths: number;
  };
  readonly " $fragmentType": "XRCommunicationProgrammingConceptFormInline_controlSystem";
};
export type XRCommunicationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRCommunicationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCommunicationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRCommunicationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "033d982f99a15674896d7ed6420236eb";

export default node;
