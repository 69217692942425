import GlobalHolidayDates from "components/GlobalHolidayDates";
import { react2angular } from "react2angular";

App.component(
  "globalHolidayDates",

  react2angular(
    GlobalHolidayDates,
    [],
    ["$stateParams", "RelayService", "UserService", "$rootScope", "$state"],
    { controller: "GlobalHolidayDatesController" }
  )
);

App.controller("GlobalHolidayDatesController", [
  "$scope",
  "$stateParams",
  "$state",
  function ($scope, $stateParams, $state) {
    $scope.tabs = {
      createGlobalHoliday: false,
      sendGlobalHoliday: false,
    };

    // Set active tab based on URL parameter or default
    function setActiveTab() {
      var activeTab = $stateParams.active_tab || "createGlobalHoliday"; // Use `active_tab` to match state config
      Object.keys($scope.tabs).forEach((key) => {
        $scope.tabs[key] = false;
      });
      $scope.tabs[activeTab] = true;
    }

    // Initialize the active tab
    setActiveTab();

    $scope.changeTab = function (tab) {
      $state.go(
        "app.dealer.globalHolidayDates",
        { active_tab: tab },
        { notify: false, reload: false }
      );
    };

    $scope.setActiveTab = function (tab) {
      Object.keys($scope.tabs).forEach((key) => {
        $scope.tabs[key] = false;
      });
      $scope.tabs[tab] = true;
    };

    // Reinitialize active tab when state parameters change
    $scope.$on("$stateChangeSuccess", setActiveTab);
  },
]);
