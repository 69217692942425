/**
 * @generated SignedSource<<9cb77b4331bd50c6991740313d79e2ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsIpv6AddressField_networkOptions$data = {
  readonly dhcpEnabled: boolean;
  readonly ipv6Address: string;
  readonly ipv6Enabled: boolean;
  readonly " $fragmentType": "NetworkOptionsIpv6AddressField_networkOptions";
};
export type NetworkOptionsIpv6AddressField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsIpv6AddressField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsIpv6AddressField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsIpv6AddressField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipv6Address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipv6Enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dhcpEnabled",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "7a46357e7ab8f33779a13d74e40499bd";

export default node;
