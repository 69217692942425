/**
 * Created by jbrumfield on 11/12/15.
 */
App.factory("DashboardDataService", [
  "$q",
  "$filter",
  "DealerODataAPI",
  "DealerODataEventHistoriesAPI",
  "OdataPageService",
  "UserService",
  "DealerODataEventReportsAPI",
  "DealerODataFireInspectionReportsAPI",
  "PROPS",
  "CustomerListAbbreviatedAPI",

  function (
    $q,
    $filter,
    DealerODataAPI,
    DealerODataEventHistoriesAPI,
    OdataPageService,
    UserService,
    DealerODataEventReportsAPI,
    DealerODataFireInspectionReportsAPI,
    PROPS,
    CustomerListAbbreviatedAPI
  ) {
    var publicObjects = {
      /**
       * @param {string} dealerID
       */
      getNumberOfCustomersForDealer: function (dealerID) {
        var deferred = $q.defer();
        DealerODataAPI.getQuickCustomerCount(
          { dealer_id: dealerID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getAbbreviatedCustomerList: function (dealerId) {
        const deferred = $q.defer();
        CustomerListAbbreviatedAPI.getAbbreviatedCustomerList({
          dealer_id: dealerId,
        },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
            console.error("error in getAbbreviatedCustomerList", error);
          }
      )
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getCustomersForDealer: function (dealerID) {
        var deferred = $q.defer();
        DealerODataAPI.getCustomers(
          { dealer_id: dealerID }, //params
          function (data) {
            //success
            deferred.resolve(data.value);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getNumberOfPanelsForDealer: function (dealerID) {
        var deferred = $q.defer();
        DealerODataAPI.getPanels(
          { dealer_id: dealerID, $count: "true", $top: 0 }, //params
          function (data) {
            //success
            deferred.resolve(data["@odata.count"]);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @param {string} dealerID
       * Gets an array of all of the panels for a dealer that match the provided accountPrefix and AccountNumber
       */
      getPanelsByAccountNumberForDealer: function (
        dealerID,
        accountPrefix,
        accountNumber
      ) {
        var deferred = $q.defer();
        DealerODataAPI.getPanels(
          {
            dealer_id: dealerID,
            $select: "id",
            $filter: `account_prefix eq ${accountPrefix} and account_number eq ${accountNumber}`,
          }, //params
          function (data) {
            //success
            deferred.resolve(data.value);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       * Gets an array of all of the panels for a dealer that match the provided accountPrefix and AccountNumber
       */
      getPanelsBySerialNumberForDealer: function (dealerID, serialNumber) {
        var deferred = $q.defer();
        DealerODataAPI.getPanels(
          {
            dealer_id: dealerID,
            $filter: `serial_number eq '${serialNumber}'`,
          }, //params
          (data) => deferred.resolve(data.value),
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getNumberOfAppUsersForDealer: function (dealerID) {
        var deferred = $q.defer();
        DealerODataAPI.getCustomerUsers(
          { dealer_id: dealerID, $count: "true", $top: 0 }, //params
          function (data) {
            //success
            deferred.resolve(data["@odata.count"]);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getPanelsNeverConnectedForDealer: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getPanels({
          dealer_id: dealerID,
          $filter: "arming_system eq null",
          $expand:
            "control_system($select=id,name;$expand=customer($select=id,name))",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      getPanelsWithLowCellSignal: function (dealerID, ageInDays) {
        var deferred = $q.defer();
        ageInDays = typeof ageInDays !== "undefined" ? ageInDays : 30;
        var dateFilter = new Date();
        dateFilter.setDate(dateFilter.getDate() - ageInDays);
        var dateFilterStr = $filter("date")(
          dateFilter,
          "yyyy-MM-ddThh:mm:ss'Z'",
          "UTC"
        );
        var promise = DealerODataAPI.getPanels({
          dealer_id: dealerID,
          $select: "panel_statistics",
          $filter: "panel_statistics/any(p:p/weakest_cell_signal ge 90)",
          $expand:
            "control_system($select=name,postal_code,address_1,city,state,country;$expand=customer($select=name,email))," +
            "panel_statistics($select=panel_id,weakest_cell_signal, created_at;$filter=weakest_cell_signal ge 90;$orderby=created_at desc;$top=1)",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * @param {string} dealerID
       * @param {number} [ageInDays=30]
       */
      getPanelsNotConnectedInDaysForDealer: function (dealerID, ageInDays) {
        var deferred = $q.defer();
        ageInDays = typeof ageInDays !== "undefined" ? ageInDays : 30;
        var dateFilter = new Date();
        dateFilter.setDate(dateFilter.getDate() - ageInDays);
        var dateFilterStr = $filter("date")(
          dateFilter,
          "yyyy-MM-ddThh:mm:ss'Z'",
          "UTC"
        );
        var promise = DealerODataAPI.getPanels({
          dealer_id: dealerID,
          $expand:
            "control_system($select=id,name;$expand=customer($select=id,name))",
          $filter: "last_hold_at lt " + dateFilterStr,
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getEventsForDealer: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getEvents({
          dealer_id: dealerID,
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getSystemsForCustomer: function (dealerID, customerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getControlSystems({
          dealer_id: dealerID,
          $filter: "customer_id eq " + customerID,
          $expand: "panels",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getDealerSystemsForMassProg: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getDetailedCustomerList({
          dealer_id: dealerID,
          $select:
            "customer_name,customer_id,control_system_name,control_system_id,panel_hardware_model",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getDealerSystemsCreatedAtDate: function (dealerID, panelId) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getControlSystems({
          dealer_id: dealerID,
          $select: "id,customer_id,created_at",
          $filter: "id eq " + panelId,
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       * @param {number} [lastDays=1]
       */
      getTroubleEventsForDealer: function (dealerID, lastDays) {
        var deferred = $q.defer();
        // created_at gt 2015-10-01T00:00:00Z
        lastDays = typeof lastDays !== "undefined" ? lastDays : 1;
        var dateFilter = new Date();
        dateFilter.setDate(dateFilter.getDate() - lastDays);
        var dateFilterStr = $filter("date")(
          dateFilter,
          "yyyy-MM-ddThh:mm:ss'Z'",
          "UTC"
        );
        var promise = DealerODataEventHistoriesAPI.getEventHistories({
          dealer_id: dealerID,
          $filter:
            "_event/event_group/group eq 'Troubles' and _event/event_at gt " +
            dateFilterStr,
          $top: 100,
          $select: "message,created_at,updated_at",
          $expand:
            "_event($select=id,event_at),panel($select=name,id;$expand=control_system($select=id,name;$expand=customer($select=id,name)))",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getAlarmEventsForDealer: function (dealerID) {
        var deferred = $q.defer();
        var dateFilter = new Date();
        dateFilter.setDate(dateFilter.getDate() - 1);
        var dateFilterStr = $filter("date")(
          dateFilter,
          "yyyy-MM-ddThh:mm:ss'Z'",
          "UTC"
        );
        var promise = DealerODataEventHistoriesAPI.getEventHistories({
          dealer_id: dealerID,
          $filter:
            "_event/event_group/group eq 'Alarms' and _event/event_at gt " +
            dateFilterStr,
          $top: 100,
          $select: "message,created_at,updated_at",
          $expand:
            "_event($select=id,event_at),panel($select=name,id;$expand=control_system($select=id,name;$expand=customer($select=id,name)))",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      camerasNotOnlineNow: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getVideoDevices({
          dealer_id: dealerID,
          $select: "name,mac_address,model,checked_in_at,network_ready",
          $filter: "network_ready eq false",
          $expand:
            "control_system($select=name,postal_code,address_1,city,state,country;$expand=customer($select=name,email))",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      camerasOnlineNow: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getVideoDevices({
          dealer_id: dealerID,
          $select: "name,mac_address,model,checked_in_at,network_ready",
          $filter: "network_ready eq true",
          $expand:
            "control_system($select=name,postal_code,address_1,city,state,country;$expand=customer($select=name,email))",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      getVideoDevicesForDealer: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getVideoDevices({
          dealer_id: dealerID,
          $select: "name,mac_address,model,checked_in_at,network_ready",
          $expand:
            "control_system($select=name,postal_code,address_1,city,state,country;" +
            "$expand=customer($select=name,email))",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getPanelCountByModel: function (dealerID) {
        var deferred = $q.defer();
        DealerODataAPI.getPanelCountByModel(
          { dealer_id: dealerID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @param {string} dealerID
       */
      getPanelCountByConnectionType: function (dealerID) {
        var deferred = $q.defer();
        DealerODataAPI.getPanelCountByConnectionType(
          { dealer_id: dealerID }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * function calculating customers added for month
       */
      getCustomersAddedForMonth: function (dealerID, month, year) {
        var deferred = $q.defer();
        DealerODataAPI.getCustomers(
          {
            dealer_id: dealerID,
            $count: true,
            $top: 0,
            $filter:
              "month(created_at) eq " +
              month +
              "and year(created_at) eq " +
              year,
          },
          function (monthCount) {
            var monthCountObj = {
              month: month,
              year: year,
              count: monthCount["@odata.count"],
            };
            deferred.resolve(monthCountObj);
          },
          function (monthError) {
            deferred.reject(monthError);
          }
        );
        return deferred.promise;
      },
      //Function calculating systems added for month
      getSystemsAddedForMonth: function (dealerID, month, year) {
        var deferred = $q.defer();
        DealerODataAPI.getControlSystems(
          {
            dealer_id: dealerID,
            $count: true,
            $top: 0,
            $filter:
              "month(created_at) eq " +
              month +
              "and year(created_at) eq " +
              year,
          },
          function (monthCount) {
            var monthCountObj = {
              month: month,
              year: year,
              count: monthCount["@odata.count"],
            };
            deferred.resolve(monthCountObj);
          },
          function (monthError) {
            deferred.reject(monthError);
          }
        );
        return deferred.promise;
      },

      getSystemsAddedForDay: function (dealerID) {
        var deferred = $q.defer();
        var dateFilter = new Date();
        dateFilter.setHours(0);
        dateFilter.setMinutes(0);
        dateFilter.setSeconds(0);
        var dateFilterStr = $filter("date")(
          dateFilter,
          "yyyy-MM-ddThh:mm:ss'Z'",
          "UTC"
        );
        var promise = DealerODataAPI.getControlSystems({
          dealer_id: dealerID,
          $select: "postal_code,address_1,city,state,country,name,created_at",
          $expand: "customer($select=name,email)",
          $filter: "created_at ge " + dateFilterStr,
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getInstalls: function (dealerID, reportType) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getControlSystems({
          dealer_id: dealerID,
          $expand: "panels,customer($select=name,id)",
          $filter: "installed_at gt now(-28) and " + reportType + " ne null",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      //Function calculating users signed in for month
      getUsersSignedInForMonth: function (dealerID, month, year) {
        var deferred = $q.defer();
        DealerODataAPI.getCustomerUsers(
          {
            dealer_id: dealerID,
            $count: true,
            $top: 0,
            $filter:
              "month(last_sign_in_at) eq " +
              month +
              "and year(last_sign_in_at) eq " +
              year,
          },
          function (monthCount) {
            var monthCountObj = {
              month: month,
              year: year,
              count: monthCount["@odata.count"],
            };
            deferred.resolve(monthCountObj);
          },
          function (monthError) {
            deferred.reject(monthError);
          }
        );
        return deferred.promise;
      },

      // function calc. systems without app use in last 30 days
      getSystemsWithoutAppUse: function (dealerID, ageInDays) {
        var deferred = $q.defer();
        ageInDays = typeof ageInDays !== "undefined" ? ageInDays : 30;
        var dateFilter = new Date();
        dateFilter.setDate(dateFilter.getDate() - ageInDays);
        var dateFilterStr = $filter("date")(
          dateFilter,
          "yyyy-MM-ddThh:mm:ss'Z'",
          "UTC"
        );
        var promise = DealerODataAPI.getPanels({
          dealer_id: dealerID,
          $select: "panel_users",
          $filter:
            "not panel_users/any(p:p/last_sign_in_at gt " + dateFilterStr + ")",
          $expand:
            "control_system($select=name,postal_code,address_1,city,state,country;$expand=customer($select=name,email))," +
            "panel_users($select=last_sign_in_at;$top=1;$orderby=last_sign_in_at desc)",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getSystemsWithoutApps: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getDetailedCustomerList({
          dealer_id: dealerID,
          $select:
            "customer_name, control_system_name, customer_email, customer_postal_code, customer_address1, customer_city, customer_state_province, customer_country, customer_postal_code",
          $filter:
            "services_manager_full_app_enabled eq false and services_manager_arming_app_enabled eq false",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getSystemsWithArmingApp: function (dealerID) {
        var deferred = $q.defer();
        var promise = DealerODataAPI.getDetailedCustomerList({
          dealer_id: dealerID,
          $select:
            "customer_name, control_system_name, customer_email, customer_postal_code, customer_address1, customer_city, customer_state_province, customer_country, customer_postal_code",
          $filter:
            "services_manager_full_app_enabled eq false and services_manager_arming_app_enabled eq true",
        }).$promise;
        var ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getSystemsWithEventSubCategoryId: function (dealerID, subcategoryId) {
        const deferred = $q.defer();
        const promise = DealerODataEventReportsAPI.getReportEvents({
          dealer_id: dealerID,
          $filter: `subcategory_id eq ${subcategoryId}`,
        }).$promise;
        const ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getSystemsWithLockdownEvents: function (dealerID) {
        const deferred = $q.defer();
        const promise = DealerODataEventReportsAPI.getReportEvents({
          dealer_id: dealerID,
          $filter: `subcategory_id eq 87 or subcategory_id eq 88`,
        }).$promise;
        const ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getFireInspectionDueEvents: function (dealerID) {
        const deferred = $q.defer();
        const promise =
          DealerODataFireInspectionReportsAPI.getFireInspectionEvents({
            dealer_id: dealerID,
          }).$promise;
        const ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getAutomationSystemsAddedForMonth: function (dealerID, month, year) {
        var deferred = $q.defer();
        DealerODataAPI.getDetailedCustomers(
          {
            dealer_id: dealerID,
            $count: true,
            $top: 0,
            $filter:
              "month(control_system_created_at) eq " +
              month +
              "and year(control_system_created_at) eq " +
              year +
              " and services_manager_zwave_node_edit_enabled eq true",
          },
          function (monthCount) {
            var monthCountObj = {
              month: month,
              year: year,
              count: monthCount["@odata.count"],
            };
            deferred.resolve(monthCountObj);
          },
          function (monthError) {
            deferred.reject(monthError);
          }
        );
        return deferred.promise;
      },

      getTroublesForMonth: function (dealerID, month, year) {
        var deferred = $q.defer();
        DealerODataAPI.getEvents(
          {
            dealer_id: dealerID,
            $count: true,
            $top: 0,
            $filter:
              "month(created_at) eq " +
              month +
              "and year(created_at) eq " +
              year +
              " and event_group/group eq 'Troubles' and subcategory ne 'Event::Subcategory::SystemMessage::DailyReport' and subcategory ne 'Event::Subcategory::SystemMessage::ModemReport'",
          },
          function (monthCount) {
            var monthCountObj = {
              month: month,
              year: year,
              count: monthCount["@odata.count"],
            };
            deferred.resolve(monthCountObj);
          },
          function (monthError) {
            deferred.reject(monthError);
          }
        );
        return deferred.promise;
      },

      getCountForMonthPastRange: function (
        dealerID,
        noOfMonths,
        apiCall,
        concept,
        apiToReference
      ) {
        var deferred = $q.defer();
        var currentDate = new Date();
        var monthPromises = [];
        var monthPlotPoints = [];
        for (
          var monthIterator = 0;
          monthIterator < noOfMonths + 1;
          monthIterator++
        ) {
          var dataToPass = angular.copy(currentDate);
          dataToPass.setMonth(currentDate.getMonth() - monthIterator);
          monthPromises.push(
            apiCall(
              dealerID,
              dataToPass.getMonth() + 1,
              dataToPass.getFullYear(),
              concept,
              apiToReference
            )
          );
        }
        $q.all(monthPromises)
          .then(
            function (monthlyResolve) {
              for (
                var monthDataIterator = 0;
                monthDataIterator < monthlyResolve.length;
                monthDataIterator++
              ) {
                monthPlotPoints.push(monthlyResolve[monthDataIterator]);
              }
              deferred.resolve(monthPlotPoints);
            },
            function (monthlyError) {
              deferred.reject(monthlyError);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getConceptTotalCount: function (dealerID, concept) {
        var deferred = $q.defer();
        DealerODataAPI.getConceptTotalCount(
          { dealer_id: dealerID, concept: concept, $count: true, $top: 0 },
          function (totalCountData) {
            deferred.resolve(totalCountData["@odata.count"]);
          },
          function (totalErrorCount) {
            deferred.reject(totalErrorCount);
          }
        );
        return deferred.promise;
      },

      getDealersCustomerUsers: function (dealerID) {
        var deferred = $q.defer();
        DealerODataAPI.getCustomerUsers(
          {
            dealer_id: dealerID,
            $select: "app_used_at,last_sign_in_at,email,sign_in_count,hidden",
            $expand:
              "customer($select=name,email,address1,address2,city,state_province,postal_code,country,phone1,phone2)",
            $filter: "hidden eq false",
          },
          function (customerUsersArr) {
            deferred.resolve(customerUsersArr);
          },
          function (customerUsersErr) {
            deferred.reject(customerUsersErr);
          }
        );
        return deferred.promise;
      },

      getPanelsForDealer: function (dealerID) {
        var deferred = $q.defer();
        var _this = this;
        var promise = DealerODataAPI.getPanels({
          dealer_id: dealerID,
          $expand: "control_system($select=id,name,postal_code)",
        }).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              //success
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      forceDashboardReload: function () {
        this.reloadMapData = true;
      },

      isMapDataExpired: function () {
        return (
          Date.now() - UserService.dashboardData.dataAge >
          PROPS.mapDataExpTimeout
        );
      },

      /**
       * Get the detailed_panel_map_data view object for the given dealer
       * @param dealerId
       * @returns {*}
       */
      getDealerMapData: function (dealerId, forceReload) {
        var deferred = $q.defer();
        var _this = this;

        if (UserService.dashboardData) {
          var filteredDashboardDataLength = UserService.dashboardData.filter(
            (system) => system.dealer_id == dealerId
          ).length;
        }
        if (
          forceReload ||
          !filteredDashboardDataLength ||
          _this.reloadMapData ||
          _this.isMapDataExpired()
        ) {
          getPanelMapData(
            dealerId,
            { $orderby: "customer_name" },
            forceReload,
            true
          )
            .then(
              function (data) {
                UserService.setDealerDashboardData(data);
                _this.reloadMapData = false;
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              },
              function (partialData) {
                deferred.notify(partialData);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          var dateFilter = UserService.dashboardData.dataAge;
          var dateFilterStr = $filter("date")(
            dateFilter,
            "yyyy-MM-ddTHH:mm:ss'Z'",
            "UTC"
          );
          getPanelMapData(dealerId, {
            $orderby: "customer_name",
            $filter: "created_at ge " + dateFilterStr,
          })
            .then(
              function (data) {
                var mapData = {};
                mapData.mapData = UserService.dashboardData.filter(
                  (system) => system.dealer_id == dealerId
                );
                mapData.mapData.push.apply(mapData.mapData, data);
                deferred.resolve(mapData.mapData);
              },
              function (error) {
                deferred.reject(error);
              },
              function (partialData) {
                deferred.notify(partialData);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        return deferred.promise;
      },

      getDealerTableData: function (dealerId, forceReload) {
        var deferred = $q.defer();
        var _this = this;
        getPanelMapData(dealerId, { $orderby: "b" }, forceReload, false)
          .then(
            function (data) {
              UserService.setDealerDashboardData(data);
              _this.reloadMapData = false;
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            },
            function (partialData) {
              deferred.notify(partialData);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getMapDataTASearch: function (dealerId, searchFilter) {
        var deferred = $q.defer();
        var _this = this;
        searchFilter = searchFilter.replace(/'/g, "''");
        var promise = DealerODataAPI.getDetailedPanelMapData({
          dealer_id: dealerId,
          $filter:
            "contains(customer_name, '" +
            searchFilter +
            "') or contains(control_system_name, '" +
            searchFilter +
            "') or " +
            "contains(customer_dealer_account, '" +
            searchFilter +
            "') or contains(cast(account_number, 'Edm.String'), '" +
            searchFilter +
            "')",
          $select: "customer_dealer_account, customer_name, customer_id",
          $top: 100,
        }).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              //success
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the detailed_panel_map_data view object for the given customer
       * @param dealerId
       * @param customerId
       * @returns {*}
       */
      getCustomerMapData: function (dealerId, customerId) {
        var deferred = $q.defer();
        var filter = "customer_id eq " + customerId;
        var select = customerMapDataFields.join(",");
        var params = {
          $filter: filter,
          $select: select,
        };
        getPanelMapData(dealerId, params)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the detailed_panel_map_data view object for the given panel
       * @param dealerId
       * @param panelId
       * @returns {*}
       */
      getMapDataForPanel: function (dealerId, panelId) {
        var deferred = $q.defer();
        var filter = "panel_id eq " + panelId;
        var select = mapTroubleFields.join(",");
        var params = {
          $filter: filter,
          $select: select,
        };
        getPanelMapData(dealerId, params)
          .then(
            function (data) {
              deferred.resolve(data[0]);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the detailed_panel_map_data view object for the given panel with no filter
       * @param dealerId
       * @param panelId
       * @returns {*}
       */
      getUnfilteredMapDataForPanel: function (dealerId, panelId) {
        var deferred = $q.defer();
        var filter = "panel_id eq " + panelId;

        var params = {
          $filter: filter,
        };
        getPanelMapData(dealerId, params)
          .then(
            function (data) {
              deferred.resolve(data[0]);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {});
        return deferred.promise;
      },

      /**
       * Get the detailed_panel_map_data view object for the given customer with no filter
       * @param dealerId
       * @param customerId
       * @returns {*}
       */
      getUnfilteredMapDataForCustomer: function (dealerId, customerId) {
        var deferred = $q.defer();
        var filter = "customer_id eq " + customerId;

        var params = {
          $filter: filter,
        };
        getPanelMapData(dealerId, params)
          .then(
            function (data) {
              deferred.resolve(data[0]);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {});
        return deferred.promise;
      },
    };

    // Private functions and variables
    var selectFilter = "";

    // Properties used in TechAPP on the customer summary map
    var customerMapDataFields = [
      "hardware_model",
      "panel_id",
      "panel_account_prefix",
      "panel_account_number",
      "panel_serial_number",
      "latitude",
      "longitude",
      "control_system_id",
      "control_system_name",
      "postal_code",
      "control_system_address_1",
      "control_system_address_2",
      "control_system_city",
      "control_system_state",
      "arm_inactivity",
      "cell_signal",
      "trouble",
      "alarm",
      "low_battery",
      "ac_trouble",
    ];

    var mapTroubleFields = [
      "arm_inactivity",
      "cell_signal",
      "trouble",
      "alarm",
      "low_battery",
      "ac_trouble",
    ];

    /**
     * Get data used for the map, including
     * @param {int} dealerId
     * @param {{}} [additionalParams]
     * @returns {*}
     */
    function getPanelMapData(dealerId, additionalParams, map) {
      let deferred = $q.defer();
      const params = {
        dealer_id: dealerId,
        salt: Date.now(), // Prevent caching.
        ...additionalParams, // Spread syntax for merging objects.
      };

      const apiMethod = map
        ? DealerODataAPI.getDetailedPanelMapData
        : UserService.userHasSystemCheckInRestriction()
        ? DealerODataAPI.getRoleLimitedPanelData
        : DealerODataAPI.getDetailedPanelData;

      // Use the selected API method to get the data.
      const promise = apiMethod(params).$promise;
      const ODataPS = new OdataPageService();
      ODataPS.getAllPages(promise)
        .then(
          (data) => {
            //success
            var mapData = {};
            mapData.mapData = data;
            deferred.resolve(data);
          },
          (error) => {
            //failure
            deferred.reject(error);
          },
          (partialData) => {
            deferred.notify(partialData);
          }
        )
        .catch((error) => {
          console.error(error);
        });

      return deferred.promise;
    }

    return publicObjects;
  },
]);
