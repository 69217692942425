import graphql from "babel-plugin-relay/macro";
import DoorWidget from "common/components/web/DoorWidget";
import { SubHeader } from "components/DaStyledElements";
import LoadingSpinner from "components/LoadingSpinner";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useRefetchableFragment } from "react-relay/hooks";
import styled from "styled-components";
import { DoorListFragment_controlSystem$key } from "./__generated__/DoorListFragment_controlSystem.graphql";
import { DoorListRefreshMutation } from "./__generated__/DoorListRefreshMutation.graphql";

interface DoorListProps {
  isRefreshing: boolean;
  controlSystem: DoorListFragment_controlSystem$key;
}
export default function DoorList(props: DoorListProps) {
  const [data] = useRefetchableFragment(
    refetchableDoorListFragment,
    props.controlSystem
  );
  const hasDoors = !!data.doorsConnection?.nodes?.length;
  return hasDoors ? <ValidDoorList {...props} /> : null;
}

function ValidDoorList(props: DoorListProps) {
  const [data, refetch] = useRefetchableFragment(
    refetchableDoorListFragment,
    props.controlSystem
  );
  const [refreshDoors, refreshing] =
    useMutation<DoorListRefreshMutation>(refreshMutation);
  const showAlert = useShowAlert();
  const refresh = () => {
    refreshDoors({
      variables: {
        id: data.id,
      },
      onCompleted: (response) => {
        if (response.refreshDoors.error) {
          showAlert({
            type: "error",
            text: "Unable to Retrieve door statuses",
          });
        }
      },
    });
  };

  //Refetch door statuses
  React.useEffect(() => {
    const refreshPolls: Array<NodeJS.Timeout> = [];
    const pollingInterval = 30000;

    refreshPolls.push(
      setTimeout(function reloadData() {
        refetch({}, { fetchPolicy: "store-and-network" });
        refreshPolls.push(setTimeout(reloadData, pollingInterval));
      }, pollingInterval)
    );

    return () => {
      refreshPolls.forEach((id) => clearTimeout(id));
    };
  });

  return (
    <>
      <SubHeader.Title hasBorder>
        <SubHeader.Left>
          <TitleWrapper>
            <TitleText>
              <FormattedMessage
                description="DA door list title"
                defaultMessage="Doors"
              />
            </TitleText>
            {refreshing || (props.isRefreshing && <LoadingSpinner />)}
          </TitleWrapper>
        </SubHeader.Left>
        <SubHeader.Right>
          <></>
        </SubHeader.Right>
      </SubHeader.Title>
      <SystemStatusGridDoorsGrid>
        {data.doorsConnection?.nodes.map((door) => (
          <DoorWidget door={door} key={door.id} />
        )) ?? []}
      </SystemStatusGridDoorsGrid>
    </>
  );
}
const refetchableDoorListFragment = graphql`
  fragment DoorListFragment_controlSystem on ControlSystem
  @refetchable(queryName: "DoorListFragment_controlSystemQuery") {
    id
    doorsConnection {
      nodes {
        id
        name
        ...DoorWidgetFragment_door
      }
    }
  }
`;
const refreshMutation = graphql`
  mutation DoorListRefreshMutation($id: ID!) {
    refreshDoors(systemId: $id) {
      ... on RefreshDoorsMutationSuccessResponse {
        controlSystem {
          ...DoorListFragment_controlSystem
        }
      }
      ... on RefreshDoorsMutationFailedResponse {
        error {
          type
        }
      }
    }
  }
`;

const SystemStatusGridDoorsGrid = styled.div`
  display: grid;
  font-size: 16px;
  font-weight: 600;
  grid-template-columns: repeat(auto-fill, minmax(461px, 1fr));
  grid-gap: 1.6rem;
`;
const TitleWrapper = styled.span`
  display: flex;
  align-items: center;
`;
const TitleText = styled.span`
  margin-right: 0.35rem;
`;
