/**
 * @name App.controller:TechNotesCtrl
 *
 * @description Controller for the tech app notes feature
 */
App.controller("TechNotesCtrl", [
  "$scope",
  "$modal",
  "ControlSystemNotesService",
  "$rootScope",
  "UserService",
  "$sanitize",
  "$q",
  "PROPS",
  function (
    $scope,
    $modal,
    ControlSystemNotesService,
    $rootScope,
    UserService,
    $sanitize,
    $q,
    PROPS
  ) {
    $scope.UserService = UserService;
    $scope.notes = [];
    $scope.forms = {};

    /**
     * Get a new system note template and open the note edit modal
     */
    $scope.createSystemNote = function () {
      ControlSystemNotesService.getNewNoteTemplate()
        .then(
          function (newNoteTemplate) {
            showEditNoteModal(newNoteTemplate, true);
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "error getting new note template",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Edit an existing note
     * @param {*} note - the control system note to edit
     */
    $scope.editSystemNote = function (note) {
      showEditNoteModal(note, false);
    };

    /**
     * Delete a system note and remove it from the scoped list of notes
     * @param {*} note - the control system note to delete
     */
    $scope.deleteSystemNote = function (note) {
      ControlSystemNotesService.destroy(note)
        .then(
          function () {
            var noteIdx = $scope.notes.findIndex(function (n) {
              return n.Id === note.Id;
            });
            if (noteIdx > -1) {
              $scope.notes.splice(noteIdx, 1);
            }
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "error deleting note",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Wrapper function for ion refresher.
     */
    $scope.ionNotesRefresher = function () {
      refreshNotes()
        .then()
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.$broadcast("scroll.refreshComplete");
        });
    };

    /**
     * Clear then retrieve the scoped list of system notes
     * @returns promise
     */
    function refreshNotes() {
      var deferred = $q.defer();
      $scope.notes = [];
      ControlSystemNotesService.getNotes()
        .then(
          function (notes) {
            angular.forEach(notes, function (note) {
              // If the user has an imageUUID, create an image URL
              if (note.CreatorImageUUID !== null) {
                var timeStamp = new Date().getTime();
                note.CreatorImageURL =
                  PROPS.imageApiUrl +
                  "/Uploads/Users/" +
                  note.CreatorImageUUID +
                  "/" +
                  note.CreatorImageUUID +
                  "_100w_100h_max.jpg?" +
                  timeStamp;
              }
            });
            $scope.notes = notes;
            deferred.resolve();
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "error getting system notes",
            });
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * Show the edit note modal and process the response
     * @param {*} note - the control system note to edit
     * @param {boolean} isNew - the note is new (needs to be created)
     */
    function showEditNoteModal(note, isNew) {
      switch ($rootScope.appProperties.type) {
        case "dealerAdmin":
          var techNoteEditModal = $modal.open({
            templateUrl:
              "app/control_system/tech-notes/tech-note-edit-modal.html",
            controller: "TechNoteEditModalCtrl",
            size: "lg",
            backdrop: true,
            resolve: {
              note: function () {
                return note;
              },
            },
          });
          techNoteEditModal.result
            .then(
              function (reason) {
                switch (reason) {
                  case "save":
                    if (isNew) {
                      note.CreatorUserId = UserService.user.id;
                      note.ControlSystemId = UserService.controlSystem.id;
                    }
                    var saveAction = isNew
                      ? ControlSystemNotesService.create(note)
                      : ControlSystemNotesService.update(note);
                    saveAction
                      .then(
                        function () {
                          $rootScope.alerts.push({
                            type: "success",
                            text: "note saved",
                          });
                          refreshNotes();
                        },
                        function () {
                          $rootScope.alerts.push({
                            type: "error",
                            text: "error saving note",
                          });
                          refreshNotes();
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                    break;
                  case "delete":
                    $scope.deleteSystemNote(note);
                    break;
                  default:
                    console.warn(
                      "TechNotesCtrl->showEditNoteModal() - Unknown close modal reason."
                    );
                    break;
                }
              },
              function () {}
            )
            .catch(function (error) {
              console.error(error);
            });
          break;
        case "techApp":
          $modal
            .fromTemplateUrl(
              "templates/control_system/tech-notes/tech-note-edit-modal.html",
              {
                scope: $scope,
                animation: "animated bounceInRight",
              }
            )
            .then(function (modal) {
              let parser = new DOMParser();
              let parsedBody = parser.parseFromString(note.Body, "text/html")
                .body.innerHTML;
              modal.scope.UserService = UserService;
              modal.scope.note = {
                Title: note.Title,
                Body: note.Body ? parsedBody : note.Body,
              };
              modal.show();
              modal.scope.cancelNote = function () {
                closeModal();
              };
              modal.scope.saveNote = function () {
                note.Title = $sanitize(modal.scope.note.Title);
                note.Body = $sanitize(modal.scope.note.Body);
                if (isNew) {
                  note.CreatorUserId = UserService.user.id;
                  note.ControlSystemId = UserService.controlSystem.id;
                }
                var saveAction = isNew
                  ? ControlSystemNotesService.create(note)
                  : ControlSystemNotesService.update(note);
                saveAction
                  .then(
                    function () {
                      $rootScope.alerts.push({
                        type: "success",
                        text: "note saved",
                      });
                      refreshNotes();
                      closeModal();
                    },
                    function () {
                      $rootScope.alerts.push({
                        type: "error",
                        text: "error saving note",
                      });
                      refreshNotes();
                      closeModal();
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              };
              function closeModal() {
                modal.remove();
              }
            })
            .catch(function (error) {
              console.error(error);
            });
          break;
        default:
          console.warn("TechNotesCtrl->showEditNoteModal() - Unknown app type");
          break;
      }
    }

    function init() {
      refreshNotes();
    }

    init();
  },
]);

/**
 * Controller for the Dealer Admin tech note edit template
 */
App.controller("TechNoteEditModalCtrl", [
  "$scope",
  "$modalInstance",
  "note",
  "UserService",
  "$sanitize",
  function ($scope, $modalInstance, note, UserService, $sanitize) {
    let parser = new DOMParser();
    let parsedTitle = parser.parseFromString(note.Title, "text/html").body
      .innerHTML;
    let parsedBody = parser.parseFromString(note.Body, "text/html").body
      .innerHTML;

    $scope.UserService = UserService;
    $scope.note = {
      Title: note.Title ? parsedTitle : note.Title,
      Body: note.Body ? parsedBody : note.Body,
    };

    $scope.cancelNote = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.saveNote = function () {
      note.Title = $sanitize($scope.note.Title);
      note.Body = $sanitize($scope.note.Body);
      $modalInstance.close("save");
    };

    $scope.deleteNote = function () {
      $modalInstance.close("delete");
    };
  },
]);
