/**
 * @generated SignedSource<<f83e03b04cc15db169ecf6e15fa2a271>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFFullProgrammingContainer_controlSystem$data = {
  readonly name: string;
  readonly panel: {
    readonly softwareVersion: string;
    readonly supportsCustomCardFormats: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"XFFullProgrammingContainer_templateStatus_controlSystem">;
  readonly " $fragmentType": "XFFullProgrammingContainer_controlSystem";
};
export type XFFullProgrammingContainer_controlSystem$key = {
  readonly " $data"?: XFFullProgrammingContainer_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFFullProgrammingContainer_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFFullProgrammingContainer_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportsCustomCardFormats",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFFullProgrammingContainer_templateStatus_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "55a126fa49622f8c4de5f8cad8665add";

export default node;
