/**
 * @generated SignedSource<<fdb986de7245c85d0b1e138bb052c25f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type BellOptionsFireBellActionField_bellOptions$data = {
  readonly fireBellAction: BellActions;
  readonly " $fragmentType": "BellOptionsFireBellActionField_bellOptions";
};
export type BellOptionsFireBellActionField_bellOptions$key = {
  readonly " $data"?: BellOptionsFireBellActionField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsFireBellActionField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsFireBellActionField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fireBellAction",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "8006669112b54c0656d73ee6051c204a";

export default node;
