import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { CommunicationPathType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathDoorAccessReportsField_communicationPath$key } from "./__generated__/CommPathDoorAccessReportsField_communicationPath.graphql";

export const commPathDoorAccessReportsFieldId = (number: string) =>
  `comm-path-door-access-reports-${number}`;

function CommPathDoorAccessReportsField() {
  const [{ number, doorAccessReports, type }, updateCommPath] =
    useCommPathFragment<CommPathDoorAccessReportsField_communicationPath$key>(
      graphql`
        fragment CommPathDoorAccessReportsField_communicationPath on CommunicationPath {
          number
          doorAccessReports
          type
        }
      `
    );

  const fieldId = commPathDoorAccessReportsFieldId(String(number));
  const disabled = type === CommunicationPathType.BACKUP;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Door Access Reports"
      disabled={disabled}
      tooltip="Turns on Door Access reports on this path. When Deny is selected, only denial reports are sent on this path."
    >
      <Select
        id={fieldId}
        value={doorAccessReports}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "doorAccessReports");
          });
        }}
      >
        <Select.Option value="YES">Yes</Select.Option>
        <Select.Option value="NO">No</Select.Option>
        <Select.Option value="DENY">Deny</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathDoorAccessReportsField;
