import * as React from "react";

export const Title = (props: {
  hasBorder?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className={props.hasBorder ? `sub-header bb pad-b-8` : `sub-header`}>
      {props.children}
    </div>
  );
};

export const Left = (props: { children: React.ReactNode }) => {
  return (
    <div className="sub-header__left">
      <div className="sub-header__title">{props.children}</div>
    </div>
  );
};

export const Right = (props: { children: React.ReactNode }) => {
  return <div className="sub-header__right">{props.children}</div>;
};
export default { Title, Left, Right };
