/**
 * @generated SignedSource<<ae862bc813c1261889520dbcaad58691>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type BellOptionsAuxiliary2BellActionField_bellOptions$data = {
  readonly auxiliary2BellAction: BellActions;
  readonly " $fragmentType": "BellOptionsAuxiliary2BellActionField_bellOptions";
};
export type BellOptionsAuxiliary2BellActionField_bellOptions$key = {
  readonly " $data"?: BellOptionsAuxiliary2BellActionField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsAuxiliary2BellActionField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsAuxiliary2BellActionField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auxiliary2BellAction",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "a4ebcc5cf66feef2d31ccb5e5439de9b";

export default node;
