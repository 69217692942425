/**=================================================================
 * SparkLine Mini Chart Directive
 ====================================================================*/
/**
 * @ngdoc directive
 * @name App.directive:sparkline
 * @author Way
 * @function
 *
 * http://omnipotent.net/jquery.sparkline/
 *
 * @description
 * Renders a Sparkline mini chart in the view where it is used. This Directive uses data from the
 * calling DOM element to which the directive is applied.
 * This directive renders multiple charts based on supplied configuration parameters and chart data.
 *
 */
App.directive("daCellsparkline", [
  "$timeout",
  "$window",
  "PROPS",
  "APP_COLORS",
  function ($timeout, $window, PROPS, APP_COLORS) {
    return {
      restrict: "A",
      link: function (scope, $element, options) {
        // options are the element attributes

        options.$observe("chartData", function () {
          // https://docs.angularjs.org/api/ng/type/$compile.directive.Attributes

          if (!options.chartData || !options.chartName) return; // Exit and do  nothing if there is no data.

          var sparkNumDataPoints = -14; // passed to JS slice function to return last 14 elements (sorted chronologically ascending)

          /**************************************************************************************************************
           * This needs to be converted into an array as done below. If not, it will be interpreted as a string an the  *
           * array functions called on it will not work. It seems that attribute data passed from a directive's element *
           * default to string type.                                                                                    *
           **************************************************************************************************************/
          options.chartData = angular.fromJson(options.chartData);

          if (!options.chartData || !options.chartName) {
            // Check to see if the chart type is supplied via html options data
            $element.html("Data Unavailable");
            return;
          }

          // We need to sort data array ascending
          /*******************************************************************************************************************
           * Chrome Browser Bug Fix:                                                                                         *
           * This sort function and the call to sort currentChartData is a Chrome browser bug fix. In Chrome, dates can      *
           * become unsorted some how (the dates remain properly sorted in FF and Safari). Dates are sorted when the data    *
           * is retrieved by panelDataService and dates should be sorted automatically by FlotJS regardless, but this is not *
           * happening in Chrome. This manual re-sorting fixes the issue for Chrome.                                         *
           *******************************************************************************************************************/
          function compareDates(a, b) {
            //if (a[0] < b[0]) return -1;   // string comparison actually works but is not best practice given the format
            //if (a[0] > b[0]) return 1;
            if (new Date(a[0]) < new Date(b[0])) return -1;
            if (new Date(a[0]) > new Date(b[0])) return 1;
            return 0;
          }

          options.chartData.sort(compareDates); // Sort the data by date (ascending)

          var dataArray = [];
          dataArray = options.chartData.slice(sparkNumDataPoints); // uses only a subset of full dataset
          //var dataArray = options.chartData.slice(startIndex, endIndex);  // uses only a subset of full dataset
          if (dataArray === undefined) {
            // check to see if data for this chart type exists
            $element.html("Data Unavailable");
            return;
          }

          dataArray.sort(compareDates); // Sort the data by date (ascending)

          var sparklineData = []; // this will hold the sparkline chart data values (spark needs just the values, no time reference)
          for (var idx in dataArray) {
            //sparklineData.push(dataArray[idx][1]);
            /*********************************************************************************************************************
             * Sparkline charts have limited functionality for display of all negative values. We need the strongest signals to  *
             * show as the highest bars on the chart. Sparklines functionality does not allow for sufficient axis manipulation   *
             * which would allow us to leave the values as negative. The code below uses relative positive values to represent   *
             * the negative value data. The Sparkline tool tip, on hover, will be modified to display the correct negative value.*
             *********************************************************************************************************************/

            if (/Signal/i.test(options.chartName)) {
              // If this is a Cell Signal related Sparkline (they have negative values)
              sparklineData.push(dataArray[idx][1] + 130); // so that -55 will now be 45
            } else {
              sparklineData.push(dataArray[idx][1]); //  No change for non cellular data (i.e. positive numbers)
            }
          }

          if (/Signal/i.test(options.chartName)) {
            // If this is a Cell Signal related Sparkline (they have negative values)
            // Modify the tooltip to show the correct negative value
            options.tooltipFormatter = function (sp, options, fields) {
              return (
                '<span style="color: {{color}}">&#9679;</span> ' +
                parseInt(fields[0].value - 130)
              );
            };
          }

          if (sparklineData.length < 1) {
            // last check to verify data exists before rendering (so that an empty list does not render)
            $element.html("Data Unavailable");
            return;
          }

          options.type = options.type || "bar"; // default chart is bar
          options.disableHiddenCheck = true; // increases rendering performance. See docs - url given in initial block comment
          options.zeroColor = APP_COLORS.sparklineNormalColor;
          options.nullColor = APP_COLORS.sparklineNormalColor;

          /*******************************************************************************************************************
           * NOTE: There are cases where the panel will have cellular system analytics information but no SIM information.   *
           * This is the case where cellular is the secondary comm type, not the primary. We still need a carrier            *
           * reference so we can determine signal threshold information, which is carrier specific. In the case where we do  *
           * not have ther SIM info (and therefore do not have carrier info), we will default to Verizon and use Verizon's   *
           * signal threshold values for the Sparkline charts.                                                               *
           *******************************************************************************************************************/
          // If we have cellular signal analytics information but are missing SIM information, default carrier to Verizon
          if (
            /Signal/i.test(options.chartName) &&
            (!options.carrier || options.carrier === "")
          ) {
            options.carrier = "Verizon";
          }

          var obj = {};
          // Cell Signal Sparklines will specify a carrier and the useThreshold element attribute will be set to 'carrier'
          if (
            options.carrier &&
            options.carrier === "Verizon" &&
            options.useThreshold === "carrier"
          ) {
            var threshold = PROPS.signalThresholdVerizon - 1;
            if (/Signal/i.test(options.chartName)) {
              threshold = threshold + 130;
            }
            obj[":" + threshold] = APP_COLORS.chartThreshold; // threshold values of options.threshold and smaller will be colored red
            options.colorMap = obj;
          }
          // Cell Signal Sparklines will specify a carrier and the useThreshold element attribute will be set to 'carrier'
          if (
            options.carrier &&
            options.carrier === "ATT" &&
            options.useThreshold === "carrier"
          ) {
            var threshold = PROPS.signalThresholdATT - 1;
            if (/Signal/i.test(options.chartName)) {
              threshold = threshold + 130;
            }
            obj[":" + threshold] = APP_COLORS.chartThreshold; // threshold values of options.threshold and smaller will be colored red
            options.colorMap = obj;
          }

          /***************************************************************************************************************************
           * Set some sparkline thresholds for specific chart types amd specific Panels (driven by panel specific design parameters) *
           ***************************************************************************************************************************/
          // Set AC Voltage Threshold for XR150, XR350, XR550, XT30, XT50, we won't use any threshold passed in the directive html element
          if (
            /ACVoltage/i.test(options.chartName) &&
            /XR150|XR350|XR550|XT30|XT50/i.test(options.panelModel)
          ) {
            options.threshold = ":14";
          }
          // Set AC Voltage Threshold for XTL, XTLN, XTLN-WiFi, TMS6, DualCom, CellComSL, CellComEX, iComSL Will be displayed as DC Voltage on the website - see html markup
          if (
            /ACVoltage/i.test(options.chartName) &&
            /XTL|XTLN|XTLW|TMS6|DualCom|CellComSL|CellComEX|iComSL|iComLNC/i.test(
              options.panelModel
            )
          ) {
            options.threshold = ":10";
          }
          // Set Battery Voltage Threshold for XR150, XR350, XR550, XT30, XT50, we won't use any threshold passed in the directive html element
          if (
            /BatteryVoltage/i.test(options.chartName) &&
            /XR150|XR350|XR550|XT30|XT50/i.test(options.panelModel)
          ) {
            options.threshold = ":12";
          }
          // Set Battery Voltage Threshold for XTL, XTLN, XTLN-WiFi, TMS6, DualCom, CellComSL, CellComEX, iComSL
          if (
            /BatteryVoltage/i.test(options.chartName) &&
            /XTL|XTLN|XTLW|TMS6|DualCom|CellComSL|CellComEX|iComSL|iComLNC/i.test(
              options.panelModel
            )
          ) {
            options.threshold = ":3.7";
          }

          // Non Cell Signal Sparklines will specify the threshold value in the threshold element data attribute
          if (options.threshold) {
            obj[options.threshold] = APP_COLORS.chartThreshold; // threshold values of options.threshold and smaller will be colored red
            options.colorMap = obj;
          }

          $element.sparkline(sparklineData, options);

          if (options.resize) {
            $(window).resize(function () {
              $element.sparkline(sparklineData, options);
            });
          }
        });
      },
    };
  },
]);
