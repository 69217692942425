import graphql from "babel-plugin-relay/macro";
import { commitLocalUpdate } from "react-relay";
import { fromSiteId, toVideoRecorderId } from "securecom-graphql/client";

App.controller("NvrCtrl", [
  "$rootScope",
  "$scope",
  "$state",
  "$stateParams",
  "$http",
  "$timeout",
  "$window",
  "$modal",
  "$filter",
  "$q",
  "UserService",
  "TIME_ZONES",
  "TIME_ZONES_NVR2",
  "ControlSystemsService",
  "ControlSystemService",
  "VideoDevice",
  "VideoDeviceService",
  "device_id",
  "channel_id",
  "JobService",
  "VALIDATION_PATTERNS",
  "RelayService",
  "PanelCapabilitiesService",
  "SevenInchKeypadService",
  function (
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $http,
    $timeout,
    $window,
    $modal,
    $filter,
    $q,
    UserService,
    TIME_ZONES,
    TIME_ZONES_NVR2,
    ControlSystemsService,
    ControlSystemService,
    VideoDevice,
    VideoDeviceService,
    device_id,
    channel_id,
    JobService,
    VALIDATION_PATTERNS,
    RelayService,
    PanelCapabilitiesService,
    SevenInchKeypadService
  ) {
    var channelsRefreshed = false; // This is used to track if we've attempted to refresh video channels on load. Needed to prevent infinite loop during load

    $scope.bool_type = "check"; // Used for displaying boolean values on the view page
    $scope.VideoDeviceService = VideoDeviceService;
    $scope.ControlSystemsService = ControlSystemsService;
    $scope.PanelCapabilitiesService = PanelCapabilitiesService;
    $scope.Panel = ControlSystemsService.currentControlSystem.panels[0];
    $scope.UserService = UserService;
    $scope.isBusy = true;
    $scope.videoDevice = null;
    $scope.deviceType = null;
    $scope.videoChannel = null; // The current video channel
    $scope.timeZones = TIME_ZONES; // Get our time zone list from the constants file
    $scope.VALIDATION_PATTERNS = VALIDATION_PATTERNS;
    $scope.hasSevenInchKeypad = false;

    const getRelayNvrs = () => {
      RelayService.query(
        graphql`
          query nvrCtrlQuery($siteId: ID!) {
            site(id: $siteId) {
              id
              securecomNvrs {
                id
                ...SecureComNvrs_secureComNvr
              }
            }
          }
        `,
        { siteId: UserService.site.id }
      );
    };

    $scope.userServiceHasSite = !!UserService.site;
    if ($scope.userServiceHasSite) {
      const siteInfo = fromSiteId(UserService.site.id);
      $scope.siteIdFromUserService = siteInfo.siteId;
      $scope.customerIdFromUserService = siteInfo.customerId;
      getRelayNvrs();
    }

    const getSevenInchKeypads = async (panel) => {
      $scope.hasSevenInchKeypad = false;
      SevenInchKeypadService.getKeypadsForControlSystem(panel.id).then(
        (keypads) => {
          if (keypads.length > 0) {
            $scope.hasSevenInchKeypad = true;
          }
        }
      );
    };

    /**
     * @ngdoc object
     * @name method:getVideoDevice
     * @methodOf App.Controller:CameraCtrl
     *
     * @description
     * Gets the current video devices properties from the API
     */
    $scope.getVideoDevice = function () {
      $scope.isBusy = true;
      $scope.videoDevice = new VideoDevice();
      $scope.videoDevice
        .get(device_id)
        .then(
          function () {
            $scope.isBusy = false;
            $scope.videoDevice.device_type === "dvr"
              ? ($scope.deviceType = "Converter")
              : ($scope.deviceType = $scope.videoDevice.device_type);
            $scope.timeZones = $scope.videoDevice.isGen2NVR
              ? TIME_ZONES_NVR2
              : TIME_ZONES;

            // If a channel id was passed to the ctrl, set the current video channel
            getCurrentChannel();
            if (
              $scope.deviceType === "nvr" &&
              !channelsRefreshed &&
              !$scope.videoDevice.channels.length
            ) {
              $scope.refreshChannels();
            }
            $scope.$broadcast("videoDevice.loaded");
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text:
                "An error occurred while getting " +
                $scope.deviceType +
                " details.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    // Get the current video device and initialize the page
    $scope.getVideoDevice();
    if (PanelCapabilitiesService.supportsSevenInchKeypad($scope.Panel))
      getSevenInchKeypads($scope.Panel);

    /**
     * Save the current video device and then redirect if requested
     * @param redirect true if the page should redirect to a url
     */
    $scope.saveDevice = function (redirect, deleting) {
      var sendTimeZone = true;
      var forceSendTZ = $stateParams.force_send_tz;

      // Only send the time zone setting if the user has changed it
      if (angular.isDefined($scope.VideoDeviceForm) && !forceSendTZ) {
        sendTimeZone =
          !$scope.VideoDeviceForm.time_zone.$pristine ||
          !$scope.VideoDeviceForm.daylight_saving.$pristine;
      }

      $scope
        .save(sendTimeZone)
        .then(
          function () {
            if ($scope.userServiceHasSite) getRelayNvrs();
            $scope.saveVideoDeviceSuccess(redirect, sendTimeZone, deleting);
          },
          function (error) {
            $scope.saveVideoDeviceFailed(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.goBackToSystemOrSitePage = () => {
      if (!!UserService.site) {
        $state.go("app.sites.edit", {
          customer_id: UserService.customer_id,
          site_id: fromSiteId(UserService.site.id).siteId,
        });
      } else {
        $state.go("app.control_system", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
        });
      }
    };

    $scope.goToNvrEditPage = () => {
      if (!!UserService.site) {
        $state.go("app.edit-nvr-sites", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
          device_id: $scope.videoDevice.id,
          site_id: fromSiteId(UserService.site.id).siteId,
        });
      } else {
        $state.go("app.edit-nvr", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
          device_id: videoDevice.id,
        });
      }
    };

    $scope.goToSiteOrCS = () => {
      if ($scope.userServiceHasSite) {
        $state.go("app.sites.edit", {
          customer_id: $scope.customerIdFromUserService,
          site_id: $scope.siteIdFromUserService,
        });
      } else {
        $state.go("app.control_system", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
        });
      }
    };

    /**
     * @ngdoc object
     * @name method:save
     * @methodOf App.Controller:NvrCtrl
     *
     * @description
     * Save the current video device
     */
    $scope.save = function (sendTimeZone) {
      var deferred = $q.defer();
      $scope.isBusy = true;

      $scope.videoDevice
        .save(sendTimeZone)
        .then(
          function () {
            // Changes saved successfully
            deferred.resolve();
          },
          function (error) {
            // Failed to save the camera
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.isBusy = false;
        });

      return deferred.promise;
    };

    /**
     * @ngdoc object
     * @name method:saveVideoDeviceSuccess
     * @methodOf App.Controller:NvrCtrl
     *
     * @description
     * Notify the user that the NVR/Converter has been saved and navigate to either the control system or edit nvr
     */
    $scope.saveVideoDeviceSuccess = function (
      redirect,
      sentTimeZone,
      deleting
    ) {
      $scope.isBusy = false;
      // When we update the time zone on a converter or NVR, There's a chance that the device will restart or drop it's VPN connection.
      // This will warn the user that they may be unable to connect to the device immediately after saving changes.
      if (sentTimeZone) {
        $rootScope.alerts.push({
          type: "warning",
          text:
            "The " +
            $scope.deviceType +
            " is being updated with selected settings and may be unavailable for editing or viewing for 1 to 2 minutes.",
        });
      }

      // Redirect to the control system
      if (redirect) {
        $scope.goBackToSystemOrSitePage();
      } else {
        $scope.getVideoDevice(); // We're staying on the page so make sure the device we have in memory matches VK
      }
      if (deleting != true) {
        $rootScope.alerts.push({
          type: "success",
          text: $scope.deviceType + " Saved",
        });
      }
    };

    /**
     * @ngdoc object
     * @name method:saveVideoDeviceFailed
     * @methodOf App.Controller:NvrCtrl
     *
     * @description
     * Notify the user that an error occurred while saving the NVR/Converter
     */
    $scope.saveVideoDeviceFailed = function (error) {
      $scope.isBusy = false;
      $rootScope.alerts.push({
        type: "error",
        text: "An error occurred while saving the " + $scope.deviceType + ".",
        json: error,
      });
    };

    /**
     * @ngdoc object
     * @name method:getCurrentChannel
     * @methodOf App.Controller:NvrCtrl
     *
     * @description
     * If a channel id was passed to the ctrl, set the current video channel
     */
    function getCurrentChannel() {
      if (channel_id !== null) {
        $scope.videoChannel = $filter("filter")(
          $scope.videoDevice.channels,
          function (c) {
            return c.id == channel_id;
          }
        )[0];
      }
    }

    /**
     * @ngdoc object
     * @name method:refreshChannels
     * @methodOf App.Controller:NvrCtrl
     *
     * @description
     * Gets a list of the known NVR/DVR channels and, if there are changes, updates the video device object
     */
    $scope.refreshChannels = function () {
      channelsRefreshed = true;
      $scope.isBusy = true;
      // If the video device is null, redirect the user back to the control system page with an error
      if ($scope.videoDevice === null) {
        $scope.isBusy = false;
        $rootScope.alerts.push({
          type: "error",
          text:
            "An error occurred while getting channels for the " +
            $scope.deviceType +
            ".",
        });
        $state.goBackToSystemOrSitePage();
      }

      // Get any channel changes and update the video device
      $scope.videoDevice
        .refreshChannels()
        .then(
          function (changes) {
            $scope.isBusy = false;
            // If there were changes, reload the video device
            $scope.getVideoDevice();
            $scope.save(false);
            $rootScope.alerts.push({
              type: "success",
              text: "Channels refreshed",
            });
          },
          function (error) {
            $scope.isBusy = false;
            // if the error we received was a 409, device unavailable, display a more detailed error
            if (error.status == "409") {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to connect to device.",
              });
            } else {
              $rootScope.alerts.push({
                type: "error",
                text: "An error occurred while refreshing device channels.",
                json: error,
              });
            }
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.refreshSettings = function () {
      $scope.isBusy = true;
      $scope.videoDevice
        .refresh()
        .then(function (data) {
          JobService.process(data.job.uuid)
            .then(
              function (data) {
                $rootScope.alerts.push({
                  type: "success",
                  text: "Device settings retrieved.",
                });
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Unable to retrieve device settings.",
                });
              }
            )
            .catch(function (error) {
              console.error(error);
            })
            .finally(function () {
              $scope.isBusy = false;
              $scope.refreshChannels();
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc object
     * @name method:updateClipSettings
     * @methodOf App.Controller:NvrCtrl
     *
     * @description
     * Verify and update clip settings when record on alarm is changed
     */
    $scope.updateClipSettings = function (channel) {
      var roa = channel.record_on_alarm;
      var eventsEnabled = channel.events_enabled;

      if (roa === true) {
        // Clips cannot be scheduled when record on alarm is turned on.
        if (eventsEnabled == "scheduled") {
          channel.events_enabled = "off";
        }
      }
    };

    /**
     * @ngdoc function
     * @name deleteCamera()
     *
     * @description
     * Deletes a camera attatched to the Video Device
     */
    $scope.deleteCamera = function (camera, redirect) {
      $scope.isBusy = true;
      camera._destroy = true; // Mark the camera (channel) for deletion
      $scope.saveDevice(redirect, true);
    };

    /**
     * @ngdoc function
     * @name deleteUser()
     *
     * @description
     * Clears out the local user credentials for an NVR, which removes the local NVR user account.
     */
    $scope.deleteUser = function () {
      $scope.videoDevice.local_username = "";
      $scope.videoDevice.local_password = "";
      $scope.save(false);
    };

    /**
     * @ngdoc object
     * @name method:removeVideoDevice
     * @methodOf App.Controller:NVRCtrl
     *
     * @description
     * Attempts to deactivate and delete the current video device
     */
    $scope.removeVideoDevice = function () {
      var option = "delete";
      // Determine if we need to force delete the video device object
      if (VideoDeviceService.isDeleting($scope.videoDevice.id)) {
        option = "forceDelete";
      }
      VideoDeviceService[option]($scope.videoDevice.id)
        .then(
          function (data) {
            if (UserService.site) {
              commitLocalUpdate(RelayService.getEnvironment(), (store) => {
                const record = store.get(UserService.site.id);
                const nvrs = record.getLinkedRecords("securecomNvrs");
                const id = toVideoRecorderId(
                  "SecureComNvr",
                  $scope.videoDevice.id
                );
                if (nvrs && nvrs.length) {
                  record.setLinkedRecords(
                    nvrs.filter((nvr) => nvr.getDataID() !== id),
                    "securecomNvrs"
                  );
                }
              });
            }
            $scope.goBackToSystemOrSitePage();
            $rootScope.alerts.push({
              type: "success",
              text: "Removing " + $scope.deviceType,
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text:
                "An error occurred while removing the " +
                $scope.deviceType +
                ".",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.forceDeleteVideoDevice = function () {
      VideoDeviceService.forceDelete($scope.videoDevice.id)
        .then(
          function (data) {
            if (UserService.site) {
              commitLocalUpdate(RelayService.getEnvironment(), (store) => {
                const record = store.get(UserService.site.id);
                const nvrs = record.getLinkedRecords("securecomNvrs");
                const id = toVideoRecorderId(
                  "SecureComNvr",
                  $scope.videoDevice.id
                );
                if (nvrs && nvrs.length) {
                  record.setLinkedRecords(
                    nvrs.filter((nvr) => nvr.getDataID() !== id),
                    "securecomNvrs"
                  );
                }
              });
            }
            $scope.goBackToSystemOrSitePage();
            $rootScope.alerts.push({
              type: "success",
              text:
                $scope.deviceType + " " + $scope.videoDevice.name + " deleted.",
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text:
                "An error occurred while removing the " +
                $scope.deviceType +
                ".",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc Object
     *
     *  @name  openAddCameraScheduleModal()
     *
     * @element
     * creates a Schedule Modal
     *
     * @description
     * Instantiates a modal to create/edit a schedule for a VR camera
     */
    $scope.openAddCameraScheduleModal = function (camera) {
      // Create a pristine copy of the schedules array before editing
      camera.schedules_pristine = angular.copy(camera.schedules);
      $scope.AddCameraSchedule = $modal.open({
        templateUrl: "add-camera-schedule-modal.html",
        controller: "AddCameraScheduleModalCtrl",
        windowClass: "add-camera-schedule-modal",
        size: "md",
        backdrop: "static",
        scope: $scope,
        resolve: {
          camera: [
            "$stateParams",
            function ($stateParams) {
              return camera;
            },
          ],
        },
      });
    };

    /**
     * @ngdoc Controller
     *
     *  @name  AddCameraScheduleModalCtrl
     *
     * @element
     * Controller for Camera Schedule Modal
     *
     * @description
     * Instantiates a modal to create/edit a schedule for a VR camera
     */
    App.controller(
      "AddCameraScheduleModalCtrl",
      function (
        $scope,
        $modalInstance,
        $state,
        UserService,
        VideoSchedule,
        camera
      ) {
        $scope.camera = camera; // The current camera (channel)

        /**
         * @ngdoc object
         * @name method:addSchedule
         * @methodOf App.Controller:AddCameraScheduleModalCtrl
         *
         * @description
         * Add a schedule to the schedules array
         */
        $scope.addSchedule = function () {
          camera.schedules.push(new VideoSchedule());
        };

        /**
         * @ngdoc object
         * @name method:cancelSchedule
         * @methodOf App.Controller:AddCameraScheduleModalCtrl
         *
         * @description
         * Revert any changes made to the schedules array and dismiss the modal
         */
        $scope.cancelSchedule = function () {
          // Revert back to the pristine schedule
          camera.schedules = angular.copy(camera.schedules_pristine);
          delete camera.schedules_pristine;
          $modalInstance.dismiss("cancel");
        };

        /**
         * @ngdoc object
         * @name method:saveSchedule
         * @methodOf App.Controller:AddCameraScheduleModalCtrl
         *
         * @description
         * Store the changes made to the schedules array and dismiss the modal
         */
        $scope.saveSchedule = function () {
          // We no longer need the pristine schedules array so remove it
          delete camera.schedules_pristine;
          $modalInstance.dismiss();
        };

        /**
         * @ngdoc object
         * @name method:deleteSchedule
         * @methodOf App.Controller:AddCameraScheduleModalCtrl
         *
         * @description
         * Remove a schedule from the schedules array either by marking it for deletion or, if it's a newly created
         * schedule, removing it from the array
         */
        $scope.deleteSchedule = function (schedule) {
          // Check to see if the schedule is a newly created one
          if (schedule.isNew) {
            // This schedule exists locally only, remove it from the array
            var index = camera.schedules.indexOf(schedule);
            camera.schedules.splice(index, 1);
          } else {
            // This schedule was returned from the API, mark it for deletion
            schedule._destroy = true;
          }
        };

        /**
         * @ngdoc object
         * @name method:convertToScheduleTime
         * @methodOf App.Controller:AddCameraScheduleModalCtrl
         *
         * @description
         * Take the time that the user entered and break it into hour, minute, and AM/PM
         */
        $scope.convertToScheduleTime = function (schedule, timeField) {
          // Convert the date time to hours, minutes and am/pm
          var parsedTime = ScheduleTimeFromUTC(schedule["time_" + timeField]);

          // Break up the newly created time into it's elements
          schedule[timeField + "_hour"] = parsedTime[0];
          schedule[timeField + "_minute"] = parsedTime[1];
          schedule[timeField + "_am_pm"] = parsedTime[2];
        };
      }
    );

    /**
     * @ngdoc object
     * @name openAddOnvifCameraModal()
     * @element Modal
     *
     * @description
     * initializes a modal to add an Onvif Camera
     */
    $scope.openAddOnvifCameraModal = function (camera) {
      if (!isUndefinedOrNull(camera)) $scope.cameraToAdd = angular.copy(camera);
      $scope.AddOnvifCamera = $modal.open({
        templateUrl: "add-onvif-camera-modal.html",
        controller: "AddOnvifCameraModalCtrl",
        windowClass: "add-camera-schedule-modal",
        size: "md",
        backdrop: "static",
        scope: $scope,
        resolve: {
          videoDevice: [
            "$stateParams",
            function ($stateParams) {
              return $scope.videoDevice;
            },
          ],
        },
      });
    };

    // Initialize the view

    /**
     * @ngdoc Controller
     *
     *  @name  AddOnvifCameraModalCtrl
     *
     * @element
     * Controller for Add Onvif Schedule Modal
     *
     * @description
     * Instantiates a modal to create/edit a schedule for a VR camera
     */
    App.controller(
      "AddOnvifCameraModalCtrl",
      function ($scope, $modalInstance, $state, UserService, videoDevice) {
        $scope.onvif = {};
        if ($scope.cameraToAdd) $scope.onvif = $scope.cameraToAdd;

        $scope.saveOnvifCamera = function () {
          var parent = $scope.$parent;
          parent.isBusy = true;
          videoDevice
            .addRemoteChannel($scope.onvif)
            .then(
              function (data) {
                // Channels refresh complete
                $rootScope.alerts.push({
                  type: "success",
                  text: "ONVIF camera added.",
                });
                $modalInstance.dismiss("cancel");
                // Refresh channels to get our newly added channel back from VK
                videoDevice
                  .refreshChannels()
                  .then(
                    function (data) {
                      parent.isBusy = false;
                      parent.getVideoDevice();
                    },
                    function (error) {
                      // Channels refresh failed
                      parent.isBusy = false;
                      $rootScope.alerts.push({
                        type: "error",
                        text: "An error occurred while refreshing video channels.",
                        json: error,
                      });
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                // Add remote channel failed
                parent.isBusy = false;
                // if the error we received was a 409, device unavailable, display a more detailed error
                if (error.status == "409") {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Unable to connect to device.",
                  });
                } else {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "An error occurred while adding the ONVIF camera.",
                    json: error,
                  });
                }
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        };

        /**
       * @ngdoc function
       *
       * @name cancel()
       *
       * @description
       * routes user back to origin

       */
        $scope.cancel = function () {
          $scope.$parent.isBusy = false;
          $modalInstance.dismiss("cancel");
        };
      }
    );

    /**
     * @ngdoc object
     * @name openChangeNvrEndUserPasswordModal()
     * @element Modal
     *
     * @description
     * initializes a modal to change an end-user NVR password
     */
    $scope.openChangeNvrEndUserPasswordModal = function () {
      $scope.ChangeNvrPassword = $modal.open({
        templateUrl: "change-nvr-end-user-password.html",
        controller: "ChangeNvrEndUserPasswordModalCtrl",
        windowClass: "change-nvr-end-user-password-modal",
        size: "md",
        backdrop: "static",
        scope: $scope,
        resolve: {
          videoDevice: [
            "$stateParams",
            function ($stateParams) {
              return $scope.videoDevice;
            },
          ],
        },
      });
    };

    /**
     * @ngdoc Controller
     *
     *  @name  ChangeNvrEndUserPasswordModalCtrl
     *
     * @element
     * Controller for ChangeNvrEndUserPassword Modal
     *
     * @description
     * Instantiates a modal to create/edit a schedule for a VR camera
     */
    App.controller(
      "ChangeNvrEndUserPasswordModalCtrl",
      function (
        $scope,
        $modalInstance,
        $state,
        UserService,
        videoDevice,
        VideoDeviceService
      ) {
        videoDevice.temp_local_username = videoDevice.local_username;

        /**
         * @ngdoc function
         *
         * @name cancel()
         *
         * @description
         * routes user back to origin
         */
        $scope.cancel = function () {
          $modalInstance.dismiss("cancel");
        };

        $scope.saveNvrPassword = function () {
          // Save the NVR with the updated login info
          var curUsername = videoDevice.local_username;
          var curPassword = videoDevice.local_password;
          videoDevice.local_username = videoDevice.temp_local_username;
          videoDevice.local_password = videoDevice.temp_local_password;

          $scope.videoDevice
            .save()
            .then(
              function (data) {
                // Changes saved successfully
                $rootScope.alerts.push({
                  type: "success",
                  text: "Login Updated",
                });
                $modalInstance.dismiss("cancel");
              },
              function (error) {
                // Failed to save the camera, revert our login details
                videoDevice.local_username = curUsername;
                videoDevice.local_password = curPassword;
                $rootScope.alerts.push({
                  type: "error",
                  text: "An error occurred while saving the NVR login information.",
                  json: error,
                });
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        };
      }
    );

    $scope.openDeleteNVRModal = function () {
      $scope.deleteNVR = $modal.open({
        templateUrl: "deleteNVRModal.html",
        controller: "DeleteNVRModalCtrl",
        windowClass: "change-nvr-end-user-password-modal",
        size: "md",
        backdrop: "static",
        scope: $scope,
        resolve: {
          videoDevice: [
            "$stateParams",
            function ($stateParams) {
              return $scope.videoDevice;
            },
          ],
        },
      });
    };

    App.controller(
      "DeleteNVRModalCtrl",
      function (
        $scope,
        $modalInstance,
        $state,
        UserService,
        videoDevice,
        VideoDeviceService
      ) {
        $scope.cancel = function () {
          $modalInstance.dismiss("cancel");
        };
      }
    );
  },
]);
