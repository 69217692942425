import { GenericPageFallback } from "components/GenericPageFallback";
import React from "react";
import { react2angular } from "react2angular";
import BarCodeScanner from "./MobileCameraCheck.tsx";

export const mobileCameraRoot = ({ $scope }) => {
  $scope.$applyAsync();
  return (
    <React.Suspense fallback={<GenericPageFallback />}>
      <BarCodeWrapper />
    </React.Suspense>
  );
};

export function dangerouslyAddToApp() {
  App.component(
    "mobileCameraComponent",
    react2angular(mobileCameraRoot, [], ["$scope"])
  );
}

const BarCodeWrapper = () => {
  return (
    <>
      <BarCodeScanner />
    </>
  );
};
