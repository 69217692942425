import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationActivateZone3RequestToExitField_deviceInformation$key } from "./__generated__/DeviceInformationActivateZone3RequestToExitField_deviceInformation.graphql";

export const deviceInformationActivateZone3RequestToExitFieldId = (
  number: string
) => `device-information-activate-zone3-request-to-exit-${number}`;

function DeviceInformationActivateZone3RequestToExitField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationActivateZone3RequestToExitField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationActivateZone3RequestToExitField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                enableZone3RequestToExit
              }
            }
          }
          ... on Xt75DeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                enableZone3RequestToExit
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationActivateZone3RequestToExitFieldId(
    String(number)
  );
  const label = "Activate Zone 3 Request To Exit";
  const relayEnv = useRelayEnvironment();
  const disabled =
    deviceType !== DeviceInformationType.DOOR || !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to allow a short condition on Zone 3 to unlock the door for a programmed duration (or a bypass if using the bypass-only option on the 734). Disable to allow standard operation of Zone 3."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        name={fieldId}
        checked={device734?.enableZone3RequestToExit ?? false}
        disabled={disabled}
        onChange={() => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                !device734?.enableZone3RequestToExit,
                "enableZone3RequestToExit"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationActivateZone3RequestToExitField;
