import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationPrivateDoorField_deviceInformation$key } from "./__generated__/DeviceInformationPrivateDoorField_deviceInformation.graphql";

export const deviceInformationPrivateDoorFieldId = (number: string) =>
  `device-information-private-door-${number}`;

function DeviceInformationPrivateDoorField() {
  const [{ number, privateDoor, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationPrivateDoorField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationPrivateDoorField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            privateDoor
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationPrivateDoorFieldId(String(number));
  const label = "Private Door";
  const disabled = deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Private Door allows specific door(s) to be assigned to a user for access"
    >
      <Switch
        label={label}
        id={fieldId}
        disabled={disabled}
        checked={!disabled && privateDoor}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!privateDoor, "privateDoor");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationPrivateDoorField;
