/**
 * @generated SignedSource<<55a9be1c39cf81dcb8d317d15cd9b1f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SiteControlSystemType = "DOOR_ACCESS" | "ELEVATOR";
export type SiteOutputType = "FLOORS" | "OUTPUTS";
import { FragmentRefs } from "relay-runtime";
export type SiteEditModal_site$data = {
  readonly address1: string;
  readonly address2: string;
  readonly alarmPanelX1Integration: boolean;
  readonly armablePanelId: string | null;
  readonly armablePanelSerialNumber: string | null;
  readonly city: string;
  readonly controlSystems: ReadonlyArray<{
    readonly intendedUsage: SiteControlSystemType;
  }>;
  readonly country: string;
  readonly customerBillingAddress: {
    readonly address1: string;
    readonly address2: string;
    readonly city: string;
    readonly country: string;
    readonly postalCode: string;
    readonly state: string;
  };
  readonly digitalWatchdogSpectrumEnabled: boolean;
  readonly eagleEyeEnabled: boolean;
  readonly hanwhaWaveEnabled: boolean;
  readonly hikvisionDoorbellEnabled: boolean;
  readonly hikvisionNvrEnabled: boolean;
  readonly id: string;
  readonly name: string;
  readonly nickname: string | null;
  readonly observesDaylightSavingsTime: boolean;
  readonly openEyeEnabled: boolean;
  readonly outputModulesConnection: {
    readonly nodes: ReadonlyArray<{
      readonly address: number;
      readonly controlSystem: {
        readonly doors: ReadonlyArray<{
          readonly name: string;
        }>;
        readonly serialNumber: string;
      } | null;
      readonly id: string;
      readonly name: string;
      readonly outputsConnection: {
        readonly nodes: ReadonlyArray<{
          readonly name: string;
          readonly relayNumber: number | null;
          readonly type: SiteOutputType;
        }>;
      };
    }>;
  };
  readonly postalCode: string;
  readonly securecomNvrEnabled: boolean;
  readonly showDoors: boolean;
  readonly showElevators: boolean;
  readonly showOutputs: boolean;
  readonly showVideoServices: boolean;
  readonly sitePassphrase: string;
  readonly state: string;
  readonly supportsXrCommunication: boolean;
  readonly timezoneOffset: number;
  readonly videoEnabled: boolean;
  readonly xrCommunicationEnabled: boolean;
  readonly " $fragmentType": "SiteEditModal_site";
};
export type SiteEditModal_site$key = {
  readonly " $data"?: SiteEditModal_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteEditModal_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address1",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address2",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteEditModal_site",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nickname",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "observesDaylightSavingsTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showDoors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showElevators",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOutputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showVideoServices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alarmPanelX1Integration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportsXrCommunication",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xrCommunicationEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armablePanelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armablePanelSerialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sitePassphrase",
      "storageKey": null
    },
    {
      "alias": "customerBillingAddress",
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "intendedUsage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "include",
          "value": "OUTPUTS"
        }
      ],
      "concreteType": "SiteOutputModulesConnection",
      "kind": "LinkedField",
      "name": "outputModulesConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteOutputModule",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteControlSystem",
              "kind": "LinkedField",
              "name": "controlSystem",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serialNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SiteDoor",
                  "kind": "LinkedField",
                  "name": "doors",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteOutputModuleOutputsConnection",
              "kind": "LinkedField",
              "name": "outputsConnection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SiteOutput",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relayNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "outputModulesConnection(include:\"OUTPUTS\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securecomNvrEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hikvisionDoorbellEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openEyeEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hikvisionNvrEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eagleEyeEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "digitalWatchdogSpectrumEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hanwhaWaveEnabled",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "d197f63410c325609dda5af700730b34";

export default node;
