/**
 * @generated SignedSource<<4e4f7aef2a7c7be9841e4f5747ce4482>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver1TestReportsField_communication$data = {
  readonly receiver1TestReports: boolean;
  readonly " $fragmentType": "CommunicationReceiver1TestReportsField_communication";
};
export type CommunicationReceiver1TestReportsField_communication$key = {
  readonly " $data"?: CommunicationReceiver1TestReportsField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver1TestReportsField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver1TestReportsField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver1TestReports",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "a65a5cf6fe8e1dd7cc15b0adb8f274ff";

export default node;
