/**
 * @generated SignedSource<<92fd8b3d463f6b284bc7805dd0d7ebb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathRetryTimeField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly retryTime: number;
  readonly retryTimeMax: number;
  readonly retryTimeMin: number;
  readonly " $fragmentType": "CommPathRetryTimeField_communicationPath";
};
export type CommPathRetryTimeField_communicationPath$key = {
  readonly " $data"?: CommPathRetryTimeField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathRetryTimeField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathRetryTimeField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retryTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retryTimeMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retryTimeMax",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "d4d73bc66c1e91cafe4707f6523144f3";

export default node;
