import { curry } from "ramda";

export const setAdd = <A>(value: A) => (set: Set<A>) => {
  const clone = new Set(set);
  clone.add(value);
  return clone;
};

export const setDelete = <A>(value: A) => (set: Set<A>) => {
  const clone = new Set(set);
  clone.delete(value);
  return clone;
};

/**
 * If the set has the value, it removes the value. Otherwise, the value is added
 */
export const setToggle = <A>(value: A) => (set: Set<A>) =>
  set.has(value) ? setDelete(value)(set) : setAdd(value)(set);

/**
 * Returns setB, without anything from setA
 */
export const setDifference = curry(
  <A>(setA: Set<A>, setB: Set<A>): Set<A> => {
    const difference = new Set(setB);
    for (const elem of setA) {
      difference.delete(elem);
    }
    return difference;
  }
);

/**
 * Returns setA and setB combined
 */
export const setUnion = curry(<A>(setA: Set<A>, setB: Set<A>) => {
  const difference = new Set(setB);
  for (const elem of setA) {
    difference.add(elem);
  }
  return difference;
});

/**
 * Returns a set with only the items that exist in both sets
 */
export const setIntersection = curry(<A>(setA: Set<A>, setB: Set<A>) => {
  if (setIsEmpty(setB)) {
    return setB;
  }

  const intersection = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) {
      intersection.add(elem);
    }
  }
  return intersection;
});

export const setFirst = <A>(set: Set<A>): A | undefined => [...set][0];

export const setLast = <A>(set: Set<A>): A | undefined =>
  [...set][set.size - 1];

export const setIsEmpty = <A>(set: Set<A>) => !set.size;

export const setIsNotEmpty = <A>(set: Set<A>) => !setIsEmpty(set);

export const setTake = curry(
  <A>(amount: number, set: Set<A>) => new Set([...set].slice(0, amount))
);

type SetHas = (<A>(set: Set<A>, value: A) => boolean) &
  (<A>(set: Set<A>) => (value: A) => boolean);
export const setHas: SetHas = curry(<A>(set: Set<A>, item: A) => set.has(item));
