import { getOdataEventHistoriesUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import { EventType, parseEventTypes } from "./event-types-parser";
import { parseSecurityEvents } from "./security-events-parser";

const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;
const ODATA_BASE_URL = getOdataEventHistoriesUrl(secureComEnv);

const getData = async (url: string) => {
  const response = await fetch(url);
  if (response.ok) {
    return response.json();
  } else {
    throw Error(`OData Error: ${response.status}:${response.statusText}`);
  }
};

const getEventTypes = async (scwToken: string) => {
  const path = "/api/v1/event_report_client_filters";
  const query = `?auth_token=${scwToken}`;
  const url = `${ODATA_BASE_URL}${path}${query}`;
  const responseData = await getData(url);
  return parseEventTypes(responseData.value);
};

const getSecurityEvents = async (
  scwToken: string,
  controlSystemId: number,
  eventTypes: EventType[],
  lastAlarmServerTime: Date
) => {
  const path = `/api/v1/panels(${controlSystemId})/GetCombinedEvents`;
  const query = `?auth_token=${scwToken}`;
  const url = `${ODATA_BASE_URL}${path}${query}`;
  const responseData = await getData(url);
  return parseSecurityEvents(
    responseData.Results,
    eventTypes,
    lastAlarmServerTime
  );
};

export { getEventTypes, getSecurityEvents };
