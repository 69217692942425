App.controller("TechTrackCtrl", [
  "$rootScope",
  "$scope",
  "UserService",
  "NgMap",
  "$interval",
  "TechTrackingService",
  "DashboardDataService",
  "MAP_SETTINGS",
  "REM_UNIT_RATIO",
  "$stateParams",
  "$filter",
  "$window",
  "DealerService",
  "$compile",
  function (
    $rootScope,
    $scope,
    UserService,
    NgMap,
    $interval,
    TechTrackingService,
    DashboardDataService,
    MAP_SETTINGS,
    REM_UNIT_RATIO,
    $stateParams,
    $filter,
    $window,
    DealerService,
    $compile
  ) {
    $scope.technicians = {};
    $scope.masterTechList = [];
    $scope.intervals = [];
    $scope.markers = [];
    $scope.systemMarkers = [];
    $scope.startMarker = null;
    $scope.finishMarker = null;
    $scope.singleTechStops = {};
    $scope.trackingInfo = {
      followTechnician: true,
      viewingSingleTech: false,
      selectedTech: undefined,
      startSelected: true,
      finishSelected: false,
    };
    $scope.showRecenterControl = "0";
    $scope.bigMap = false;
    $scope.mapInfo = {
      showPOI: true,
      showSystems: false,
      showRoute: false,
      searchFilter: "",
      techSelectedFilter: "",
    };
    $scope.stopDataIsLoading = false;
    $scope.dynamicPopover = {
      templateUrl: "myPopoverTemplate.html",
      title: "Customers",
    };

    const dealerService = new DealerService({
      dealer_id: parseInt($stateParams.dealer_id),
    });

    // Local containers
    let personnelList = {}; // personnel map to find users names
    let stopInfoWindowsList = {}; // containers to hold the tech info popovers
    let techInfoWindowsList = {}; // allows us to update them directly

    const SUM_HEIGHT_OF_HEADER_FOOTER = 12.5;
    const adjustedMapHeight = `${
      $window.innerHeight / REM_UNIT_RATIO.NUM - SUM_HEIGHT_OF_HEADER_FOOTER
    }rem`;

    $scope.sliderHeight = adjustedMapHeight;
    $scope.mapInfo["style"] = {
      height: adjustedMapHeight,
      position: "relative",
      overflow: "hidden",
    };

    $scope.mapTheme = MAP_SETTINGS.MAP_STYLES.LIGHT;
    $scope.mapTheme[7].stylers[0].visibility = "on";
    $scope.mapTheme[2].stylers[0].visibility = "on";
    $scope.lastSingleTechLocationPoll = 0;
    $scope.displayDayBack = "";
    $scope.stopIndex = -1;

    const mcOptions = {
      styles: MAP_SETTINGS.CLUSTER.STYLES,
      maxZoom: MAP_SETTINGS.CLUSTER.MAX_ZOOM,
      zoomOnClick: true,
      ignoreHidden: true,
      zIndex: 100,
    };

    /**
     * Returns a string showing ammount of time passed given a number of milliseconds
     */
    function getMinutesFromMillis(duration) {
      let minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hourString = hours ? hours + " hours, " : "";
      minuteString = minutes ? minutes + " minutes" : "";

      return hourString + minuteString;
    }

    /*
     * Generates the object for the technicians sidebar div to repeat over
     */
    $scope.filterTechs = function (techs) {
      // The technichian object to repeat over in the sidebar, we need to filter the list to only show the current technician being followed
      if (!$scope.mapInfo.techSelectedFilter) {
        return techs;
      } else {
        let returnTech = {}; // Do this so that the single tech data is in the same form as all techs data
        returnTech[$scope.mapInfo.techSelectedFilter] =
          techs[$scope.mapInfo.techSelectedFilter];
        return returnTech;
      }
    };

    /**
     * Search filter for the systems on the map
     */
    $scope.filterSystems = function () {
      // Get out if there is no panelMapData
      if (
        angular.isUndefined($scope.systemMarkers) ||
        $scope.systemMarkers.length === 0
      )
        return;
      // Get out if there is a dashboardSettings.searchFilter, but it's less than 3 characters.
      if (
        angular.isDefined($scope.mapInfo.searchFilter) &&
        $scope.mapInfo.searchFilter !== "" &&
        $scope.mapInfo.searchFilter.length < 3
      )
        return;

      if ($scope.markerClusterer) $scope.markerClusterer.clearMarkers();
      $scope.systemMarkers = [];

      filteredSystems = $filter("filter")(
        $scope.controlSystems,
        $scope.mapInfo.searchFilter
      );

      for (let system of filteredSystems) {
        if (system.latitude && system.longitude) {
          let marker = createSystemMarker(system);
          $scope.systemMarkers.push(marker);
        }
      }

      $scope.markerClusterer = new MarkerClusterer(
        $scope.map,
        $scope.systemMarkers,
        mcOptions
      );
    };

    $scope.showSystems = function () {
      if ($scope.mapInfo.showSystems) {
        $scope.markerClusterer = new MarkerClusterer(
          $scope.map,
          $scope.systemMarkers,
          mcOptions
        );
      } else {
        if ($scope.markerClusterer) $scope.markerClusterer.clearMarkers();
      }
    };

    $scope.followTech = function () {
      $scope.trackingInfo.followTechnician = true;
      $scope.map.panTo($scope.currentTech.position);
    };

    $scope.openSlider = function () {
      let targetSlider = angular.element(
        document.querySelector("#sliding-menu--tech-map")
      );
      targetSlider.addClass("toggled");
    };

    $scope.getTimePlusDuration = function (startTime, duration) {
      return getTimePlusDuration(startTime, duration);
    };

    $scope.panToStop = function (id, fromInfoWindow) {
      $scope.trackingInfo.followTechnician = false;
      $scope.map.panTo($scope.singleTechStops[id].position);
      $scope.trackingInfo.startSelected = false;
      $scope.trackingInfo.finishSelected = false;
      $scope.trackingInfo.selectedTech = id;
      if (fromInfoWindow) $scope.$apply(); // If we're moving to the stop from an infowindow then we have to run the cycle to update the html on the sidebar, otherwise Angular handles it

      for (let infoWindow in stopInfoWindowsList) {
        // Close open infoWindows
        stopInfoWindowsList[infoWindow].close();
      }

      stopInfoWindowsList[id].open($scope.map, $scope.singleTechStops[id]);

      $scope.stopIndex = Object.keys($scope.singleTechStops).indexOf(
        id.toString()
      ); // Update the stop index so we know where to navigate from when using previous/next buttons
    };

    $scope.panToStart = function (fromInfoWindow) {
      $scope.trackingInfo.followTechnician = false;
      $scope.trackingInfo.selectedTech = "";
      $scope.trackingInfo.startSelected = true;
      $scope.trackingInfo.finishSelected = false;
      if (fromInfoWindow) $scope.$apply(); // If we're moving to the stop from an infowindow then we have to run the cycle to update the html on the sidebar, otherwise Angular handles it
      $scope.map.panTo($scope.startMarker.position);
      for (let infoWindow in stopInfoWindowsList) {
        stopInfoWindowsList[infoWindow].close();
      }
      $scope.stopIndex = -1;
    };

    $scope.panToFinish = function (fromInfoWindow) {
      $scope.trackingInfo.followTechnician = false;
      $scope.trackingInfo.selectedTech = "";
      $scope.trackingInfo.startSelected = false;
      $scope.trackingInfo.finishSelected = true;
      if (fromInfoWindow) $scope.$apply(); // If we're moving to the stop from an infowindow then we have to run the cycle to update the html on the sidebar, otherwise Angular handles it
      $scope.map.panTo($scope.finishMarker.position);
      for (let infoWindow in stopInfoWindowsList) {
        stopInfoWindowsList[infoWindow].close();
      }
      $scope.stopIndex = Object.keys($scope.singleTechStops).length;
    };

    /*
     * Use this function to move around the map with the previous/next buttons
     * Determine what the index is based on where we've came and whether we're going higher or lower
     */
    $scope.updateStopIndex = function (modifier) {
      if (
        $scope.stopIndex + modifier ===
        Object.keys($scope.singleTechStops).length + 1
      ) {
        $scope.stopIndex = -1;
      } else if ($scope.stopIndex + modifier === -2) {
        if ($scope.finishMarker !== null) {
          $scope.stopIndex = Object.keys($scope.singleTechStops).length;
        } else {
          $scope.stopIndex = Object.keys($scope.singleTechStops).length - 1;
        }
      } else {
        if (
          $scope.stopIndex + modifier ===
            Object.keys($scope.singleTechStops).length &&
          $scope.finishMarker === null
        ) {
          $scope.stopIndex = -1; // if there is no finish spot, we need to skip straight to the beginning
        } else {
          $scope.stopIndex = $scope.stopIndex + modifier;
        }
      }

      // Once we know what the index is we can navigate to where we need (-1 is the start and any number higher than the highest index is the finish marker)
      if ($scope.stopIndex === -1) {
        $scope.panToStart();
      } else if (
        $scope.stopIndex < -1 ||
        $scope.stopIndex > Object.keys($scope.singleTechStops).length - 1
      ) {
        $scope.panToFinish();
      } else {
        $scope.panToStop(Object.keys($scope.singleTechStops)[$scope.stopIndex]);
      }
    };

    /**
     * Set up function to prepare the map for the single tech loop
     */
    $scope.viewTechnician = function (tech) {
      $scope.mapInfo.techSelectedFilter = tech.id;
      let todaysDate = new Date().getDate();
      removeStops();
      $scope.trackingInfo.viewingSingleTech = true;
      $scope.trackingInfo.followTechnician = true;

      // loop through all the technicians to find the user selected
      // if this tech has data for today show them on the map, otherwise hide them from the map
      for (let techId of Object.keys($scope.technicians)) {
        if (techId === tech.id.toString()) {
          if (
            new Date($scope.technicians[techId].lastUpdated).getDate() ===
            todaysDate
          ) {
            techInfoWindowsList[tech.id].open($scope.map, tech);
            $scope.technicians[techId].setMap($scope.map);
          }
          $scope.technicians[techId].selected = true;
          $scope.currentTech = $scope.technicians[techId];
        } else {
          $scope.technicians[techId].setMap(null);
          $scope.technicians[techId].selected = false;
        }
      }
      runSingleTechloop(tech);
    };

    $scope.allowMap = function () {
      let currentHour = new Date().getHours();

      // return between(currentHour, 8, 16); // return true if dealer settings allow and the user is viewing the page between 8 and 5;
      return true;
    };

    /**
     * The main function called when we want to view all technicians live locations
     */
    $scope.runMasterLoop = function () {
      clearIntervals();
      removeLines();
      removeStops();

      $scope.mapInfo.techSelectedFilter = "";

      for (let infoWindow in techInfoWindowsList) {
        techInfoWindowsList[infoWindow].close();
      }

      $scope.trackingInfo.viewingSingleTech = false;

      function trackAllTechs(setDefaults) {
        // Param setDefaults will be a callback function on first call (called manually) then the iteration on subsequent calls (called by $interval)
        clearMarkers();

        TechTrackingService.getLiveTechLocations(
          parseInt($stateParams.dealer_id)
        )
          .then(
            function (technicians) {
              if (!$scope.trackingInfo.viewingSingleTech) {
                $scope.masterTechList = [...technicians];
                setMarker(setDefaults);
                if (typeof setDefaults === "function") $scope.openSlider();
              }
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to get Tech Locations.",
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }

      trackAllTechs(function () {
        setMapDefaults(); // Send callback function to set the default map position as soon as we have the values
      });
      $scope.intervals.push($interval(trackAllTechs, 3000));
    };

    $scope.displayRoute = function () {
      if ($scope.techPath) {
        // if ($scope.mapInfo.showRoute) {
        //   $scope.techPath.setMap($scope.map);
        // } else {
        //   $scope.techPath.setMap(null);
        // }
      }
    };

    /**
     * View an individual day of stops (called through the date dropdown on an individual technician)
     */
    $scope.showIndividualDay = function (day, tech) {
      clearIntervals();
      removeStops();
      $scope.stopIndex = -1;
      stopInfoWindowsList = {};

      if ($scope.techPath) $scope.techPath.setMap(null);

      let trackingDay = new Date(
        new Date().setDate(new Date().getDate() - parseInt(day))
      );

      let dateString = `${
        trackingDay.getMonth() + 1
      }-${trackingDay.getDate()}-${trackingDay.getFullYear()}`;

      switch (
        day // this is used to show which day we're on inside the dropdown
      ) {
        case 0:
          $scope.displayDayBack = "Today";
          break;
        case 1:
          $scope.displayDayBack = "Yesterday";
          break;
        default:
          $scope.displayDayBack = $filter("date")(trackingDay, "EEE M/d/yy");
      }

      // If the user is looking at today, start the loop so that we can keep asking for new data
      // otherwise, get the data for that single day and display it.
      if (day == 0) {
        tech.setMap($scope.map);
        runSingleTechloop(tech);
      } else {
        tech.setMap(null);
        $scope.stopDataIsLoading = true;
        TechTrackingService.getSingleTechHistoryLocations(
          tech.id,
          dateString,
          $stateParams.dealer_id
        )
          .then(
            function (locationInfo) {
              plotLines(locationInfo.TechLocations, tech, true);
              showStops(locationInfo.Stops);
              $scope.stopDataIsLoading = false;
            },
            function (error) {
              $scope.stopDataIsLoading = false;
            }
          )
          .catch(function (error) {
            $scope.stopDataIsLoading = false;
          });
      }
    };

    function removeLines() {
      if ($scope.techPath) {
        $scope.techPath.setMap(null);
        delete $scope.techPath;
      }

      if ($scope.startMarker !== null) $scope.startMarker.setMap(null);
      if ($scope.finishMarker !== null) $scope.finishMarker.setMap(null);

      $scope.startMarker = null;
      $scope.finishMarker = null;
    }

    /**
     * Draws the lines based on a technician's location history
     */
    function plotLines(
      locations,
      tech,
      isDayInHistory,
      isInitialLoadSingleUser
    ) {
      // The function that plots the route lines on the mapp
      let techPathCoordinates = [];

      for (let techLocation of locations) {
        techPathCoordinates.push({
          lat: techLocation.Latitude,
          lng: techLocation.Longitude,
        });
      }

      if (isInitialLoadSingleUser === true || isDayInHistory) {
        // If this is the first load or it's a day in history then we want to add a start marker
        removeLines();
        if (locations.length > 0) {
          let startLatLng = new google.maps.LatLng(
            techPathCoordinates[0].lat,
            techPathCoordinates[0].lng
          );

          $scope.startMarker = new google.maps.Marker({
            position: startLatLng,
            icon: "assets/img/pin-start.png",
            title: `Start`,
            name: "Start",
            deviceLocalTime: locations[0].DeviceLocalTime,
          });
          $scope.startMarker.setMap($scope.map);

          google.maps.event.addListener(
            $scope.startMarker,
            "click",
            function () {
              $scope.panToStart(true);
            }
          );

          if (isDayInHistory) {
            //Only show a finish marker if this is a day in history, we don't want to show the stop on a current live day.
            let stopLatLng = new google.maps.LatLng(
              techPathCoordinates[techPathCoordinates.length - 1].lat,
              techPathCoordinates[techPathCoordinates.length - 1].lng
            );

            $scope.finishMarker = new google.maps.Marker({
              position: stopLatLng,
              icon: "assets/img/pin-stop-flag.png",
              title: `Stop`,
              name: "Finish",
              deviceLocalTime: locations[locations.length - 1].DeviceLocalTime,
            });
            $scope.finishMarker.setMap($scope.map);

            google.maps.event.addListener(
              $scope.finishMarker,
              "click",
              function () {
                $scope.panToFinish(true);
              }
            );
          }

          $scope.techPath = new google.maps.Polyline({
            path: techPathCoordinates,
            geodesic: true,
            strokeColor: "#3e85a1",
            strokeOpacity: 0.7,
            strokeWeight: 4,
            icons: [
              {
                icon: $scope.lineSymbol,
                offset: "5%",
                repeat: "250px",
              },
            ],
          });
        }
      }

      if (!isDayInHistory && isInitialLoadSingleUser !== true) {
        if (locations.length > 0) {
          let latLng = new google.maps.LatLng(
            locations[locations.length - 1].Latitude,
            locations[locations.length - 1].Longitude
          );
          let path = $scope.techPath.getPath();
          path.push(latLng);
          tech.setMap($scope.map);
          tech.setPosition(latLng);
        }
      }

      if ($scope.trackingInfo.followTechnician) {
        $scope.map.panTo(tech.position);
      }

      // $scope.displayRoute()
    }

    function removeStops() {
      for (let stop in $scope.singleTechStops) {
        $scope.singleTechStops[stop].setMap(null);
        delete $scope.singleTechStops[stop];
      }
    }

    /**
     * Plot the technicians stops on the map
     */
    function showStops(stops, viewingLive) {
      let bounds = new google.maps.LatLngBounds();

      for ([index, stop] of stops.entries()) {
        // use this syntax because we want the index of the item.
        let latLng = new google.maps.LatLng(stop.Latitude, stop.Longitude);
        bounds.extend(latLng);

        let marker = new google.maps.Marker({
          position: latLng,
          icon: "assets/img/pushpin.png",
          id: stop.Id,
          title: `Stop #${index + 1}`,
          name: stop.DeviceDisplayName,
          deviceLocalTime: stop.DeviceLocalTime,
          duration: stop.Duration,
          customerList: stop.CustomerList,
        });

        let customersString = "";
        if (stop.CustomerList.length > 1) {
          let customers = "";
          for (let customer of stop.CustomerList) {
            if (customers === "") customers = customer.name;
            else customers = customers + "<br>" + customer.name;
          }
          customersString = customers;
        }

        // No customers show unknown
        // One customer show their name
        // Multiple customers show customers
        let potentialCustomersString = "";
        switch (stop.CustomerList.length) {
          case 0:
            potentialCustomersString = "Unknown";
            break;
          case 1:
            potentialCustomersString = stop.CustomerList[0].name;
            break;
          default:
            potentialCustomersString = "Customers";
            break;
        }

        let contentString = `<div id="tech-info">
                              <div class="tech-info__heading bb mar-b-8">Stop &#35;${
                                index + 1
                              } - ${potentialCustomersString}
                              </div>
                              <div id="tech-info__body">
                                <div class="tech-info__list ">
                                  <div class="tech-info__list__item--column mar-b-8">
                                    <div class="tech-info__list__item--data--column">${customersString}
                                      <div></div>
                                    </div>
                                  </div>
                                  <div class="tech-info__list__item">
                                    <div class="tech-info__list__item--label">Time: </div>
                                    <div class="tech-info__list__item--data">${$filter(
                                      "lowercase"
                                    )(
                                      $filter("date")(
                                        stop.DeviceLocalTime,
                                        "h:mma"
                                      )
                                    )} - ${$filter("lowercase")(
          $filter("date")(
            getTimePlusDuration(stop.DeviceLocalTime, stop.Duration),
            "h:mma"
          )
        )}</div>
                                  </div>
                                  <div class="tech-info__list__item">
                                    <div class="tech-info__list__item--label">Duration: </div>
                                    <div class="tech-info__list__item--data">${getMinutesFromMillis(
                                      stop.Duration
                                    )}</div>
                                  </div>

                                </div>
                              </div>
                            </div>
                             `;

        let stopInfoWindow = new google.maps.InfoWindow({
          content: contentString,
          position: latLng,
          disableAutoPan: true,
        });

        stopInfoWindowsList[stop.Id] = stopInfoWindow;

        marker.setMap($scope.map);
        $scope.singleTechStops[marker.id] = marker;

        google.maps.event.addListener(marker, "click", function () {
          stopInfoWindowsList[this.id].open($scope.map, marker);
          $scope.panToStop(this.id, true);
        });
      }

      if (stops.length > 0 && !viewingLive) {
        $scope.map.fitBounds(bounds);
      }
    }

    $scope.openInfoWindow = function (id) {
      stopInfoWindowsList[id].open($scope.map, $scope.singleTechStops[id]);
    };

    /**
     * Repeatedly ask the api for location updates for the selected technician
     */
    function runSingleTechloop(tech) {
      removeLines();
      removeStops();
      clearIntervals();
      $scope.displayDayBack = "";

      function trackSingleTech(firstTime) {
        // When we're in the loop asking for a single tech's data we need to start by asking for all data (fromTime = 0),
        // otherwise, we need to get the between now and the last time we asked (to eliminate asking for duplicate data)
        let fromTime =
          firstTime === true ? 0 : $scope.lastSingleTechLocationPoll;
        let nonDigits = /[-:]/g;

        let techPersonnel = personnelList[tech.id];

        techInfoWindowsList[tech.id].setContent(`<div id="tech-info">
                                                                <div id="firstHeading" class="tech-info__heading">${
                                                                  techPersonnel.displayName
                                                                }</div>
                                                                <div id="tech-info__body">
                                                                  <div class="tech-info__list">
                                                                    <div class="tech-info__list__item">
                                                                      <div class="tech-info__list__item--label">Last Update: </div>
                                                                      <div class="tech-info__list__item--data">${$filter(
                                                                        "date"
                                                                      )(
                                                                        tech.lastUpdated,
                                                                        "EEE M/d/yy h:mm a"
                                                                      )}</div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>`);

        TechTrackingService.getSingleTechLocations(
          tech.id,
          fromTime,
          $stateParams.dealer_id
        )
          .then(
            function (locations) {
              plotLines(locations.TechLocations, tech, false, firstTime);
              showStops(locations.Stops, true);

              // get the dateTime and format it for the api
              let todayIso = new Date().toISOString();
              todayIso = todayIso.slice(0, todayIso.length - 5) + "Z";
              todayIso = todayIso.replace(nonDigits, "");

              $scope.lastSingleTechLocationPoll = todayIso;

              if (locations && locations.TechLocations.length > 0) {
                tech.lastUpdated =
                  locations.TechLocations[
                    locations.TechLocations.length - 1
                  ].DeviceLocalTime; // update the markers last updated time
              }

              if (firstTime === true)
                $scope.intervals.push($interval(trackSingleTech, 1000));
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to get Tech Location Data.",
              });
            }
          )
          .catch(function (error) {});
      }

      trackSingleTech(true);
    }

    // The callback function that sets the map defaults on page initial load, once you've gotten the tech data
    function setMapDefaults() {
      let bounds = new google.maps.LatLngBounds();
      for (let techId of Object.keys($scope.technicians)) {
        bounds.extend($scope.technicians[techId].position);
      }
      $scope.map.fitBounds(bounds);
    }

    /**
     * Create the markers for the technicians and draw on the map
     */
    function setMarker(setDefaults) {
      let todaysDate = new Date().getDate();
      if ($scope.techPath) $scope.techPath.setMap(null);

      for (let technician of $scope.masterTechList) {
        let techPersonnel = personnelList[technician.UserId];

        let latLng = new google.maps.LatLng(
          technician.Latitude,
          technician.Longitude
        );

        if ($scope.technicians[technician.UserId]) {
          if (new Date(technician.DeviceLocalTime).getDate() === todaysDate) {
            $scope.technicians[technician.UserId].setMap($scope.map);
          }
          $scope.technicians[technician.UserId].setPosition(latLng);
          $scope.technicians[technician.UserId].selected = false;
          $scope.technicians[technician.UserId].lastUpdated =
            technician.DeviceLocalTime;

          techInfoWindowsList[technician.UserId]
            .setContent(`<div id="tech-info">
                                                                <div id="firstHeading" class="tech-info__heading">${
                                                                  techPersonnel.displayName
                                                                }</div>
                                                                <div id="tech-info__body">
                                                                  <div class="tech-info__list">
                                                                    <div class="tech-info__list__item">
                                                                      <div class="tech-info__list__item--label">Last Update: </div>
                                                                      <div class="tech-info__list__item--data">${$filter(
                                                                        "date"
                                                                      )(
                                                                        technician.DeviceLocalTime,
                                                                        "EEE M/d/yy h:mm a"
                                                                      )}</div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>`);
        } else {
          let techIcon = {
            url: technician.UserImageUrl || "assets/img/user.png",
            //state your size parameters in terms of pixels
            size: new google.maps.Size(50, 60),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            zIndex: 105,
          };

          let techLabelStyle = {
            color: "#3d85a2",
            fontWeight: "600",
            fontSize: "16px",
            text: techPersonnel.displayName,
            textAlign: "center",
            labelClass: "techIcon",
            labelInBackground: true,
          };

          let techInfoString = `<div id="tech-info">
                                <div id="firstHeading" class="tech-info__heading">${
                                  techPersonnel.displayName
                                }</div>
                                <div id="tech-info__body">
                                  <div class="tech-info__list">
                                    <div class="tech-info__list__item">
                                      <div class="tech-info__list__item--label">Last Update: </div>
                                      <div class="tech-info__list__item--data">${$filter(
                                        "date"
                                      )(
                                        technician.DeviceLocalTime,
                                        "EEE M/d/yy h:mm a"
                                      )}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>`;

          let techInfoWindow = new google.maps.InfoWindow({
            content: techInfoString,
            position: latLng,
            disableAutoPan: true,
          });

          let marker = new google.maps.Marker({
            position: latLng,
            title: techPersonnel.displayName,
            icon: techIcon,
            //label: techLabelStyle,
            id: technician.UserId,
            imgSrc: technician.UserImageUrl,
            name: techPersonnel.displayName,
            selected: false,
            // animation: google.maps.Animation.DROP,
            cursor: "hand",
            // optimized: false,
            lastUpdated: technician.DeviceLocalTime,
          });

          if (new Date(marker.lastUpdated).getDate() === todaysDate) {
            marker.setMap($scope.map);
          }

          google.maps.event.addListener(marker, "click", function () {
            $scope.viewTechnician(this);
            $scope.openSlider();
            techInfoWindow.open($scope.map, marker);
          });

          $scope.technicians[technician.UserId] = marker;
          techInfoWindowsList[technician.UserId] = techInfoWindow;
        }
      }

      // We want to wait to set the bounds until we have the data for the live locations.
      // $interval seems to pass the loop iteration as the first parameter, so we want to set the defaults
      // when the function is called manually but not when the function is called from the interval
      if (typeof setDefaults === "function") setDefaults();

      // $scope.bounds = new google.maps.LatLngBounds();
    }

    function clearMarkers() {
      for (let marker of $scope.markers) {
        marker.setMap(null);
      }
    }

    function clearIntervals() {
      for (let interval of $scope.intervals) {
        $interval.cancel(interval);
      }

      $scope.intervals = [];
    }

    /**
     * Create the markers for systems
     */
    function createSystemMarker(system) {
      // randomly move systems a tiny ammount so that we can see systems stacked on top of each other without the need of spiderfy
      let plusOrMinusX = Math.random() < 0.5 ? -1 : 1;
      let plusOrMinusY = Math.random() < 0.5 ? -1 : 1;
      let xOffset = (Math.random() / 10000) * plusOrMinusX;
      let yOffset = (Math.random() / 10000) * plusOrMinusY;

      let latLng = new google.maps.LatLng(
        system.latitude + xOffset,
        system.longitude + yOffset
      );

      let systemInfoString = `<div id="system-info">
                                <div  class="system-info__heading mar-b-10">${
                                  system.customer_name
                                }</div>
                                <div id="system-info__body">
                                  <div class="system-info__list">
                                    <div class="system-info__list__item">
                                      <div class="system-info__list__item--label"><i class="icon-armed_shield_solid mar-r-4 font-sz-14 color-primary-600"></i><span>System Info</span></div>
                                    </div>
                                    <div class="system-info__list__item">
                                      <div class="system-info__list__item--data"><a href="/#/app/customers/${
                                        system.customer_id
                                      }/control_systems/${
        system.control_system_id
      }">${system.control_system_name}</a></div>
                                    </div>
                                    <div class="system-info__list__item">
                                      <div class="system-info__list__item--data">${
                                        system.hardware_model
                                      }</div>
                                    </div>
                                    <div class="system-info__list__item">
                                      <div class="system-info__list__item--data mar-b-10">${
                                        system.comm_type
                                      }</div>
                                    </div>
                                     <div class="system-info__list__item">
                                      <div class="system-info__list__item--label"><i class="icon-home mar-r-4 font-sz-14 color-primary-600"></i>Address</div>
                                    </div>
                                     <div class="system-info__list__item">
                                      <div class="system-info__list__item--data">${
                                        system.control_system_address_1
                                      } ${
        system.control_system_address_2 ? system.control_system_address_2 : ""
      }</div>
                                    </div>
                                  
                                    
                                     <div class="system-info__list__item">
                                      <div class="system-info__list__item--data">${
                                        system.control_system_city
                                      } ${system.control_system_state} ${
        system.postal_code
      }</div>
                                    </div>
                                    <div class="system-info__list__item">
                                      <div class="system-info__list__item--data">${
                                        system.control_system_country
                                      }</div>
                                    </div>
                                  </div>
                                </div>
                              </div>`;

      let systemInfoWindow = new google.maps.InfoWindow({
        content: systemInfoString,
        position: latLng,
        disableAutoPan: true,
      });

      let marker = new google.maps.Marker({
        position: latLng,
        title: system.panel_name,
        icon: "/assets/img/googlemaps-markers/dmp-map-marker.png",
      });

      google.maps.event.addListener(marker, "click", function () {
        systemInfoWindow.open($scope.map, marker);
      });

      return marker;
    }

    function getControlSystems() {
      DashboardDataService.getDealerMapData(parseInt($stateParams.dealer_id))
        .then(
          function (systems) {
            $scope.controlSystems = [...systems];
            for (let system of systems) {
              if (system.latitude && system.longitude) {
                let marker = createSystemMarker(system);
                $scope.systemMarkers.push(marker);
              }
            }
          },
          function (error) {}
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function init() {
      NgMap.getMap(`track-my-tech-${$stateParams.dealer_id}`).then(
        function (map) {
          $scope.map = map;
          $scope.map.addListener("dragstart", function () {
            if ($scope.trackingInfo.viewingSingleTech) {
              $scope.trackingInfo.followTechnician = false;
            }
          });

          $scope.lineSymbol = {
            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          };

          dealerService
            .getPersonnel(parseInt($stateParams.dealer_id))
            .then(
              function (personnel) {
                for (let person of personnel) {
                  personnelList[person.user.id] = {
                    displayName:
                      person.user.first_name && person.user.last_name
                        ? `${person.user.first_name} ${person.user.last_name}`
                        : person.user.email,
                  };
                }
                $scope.runMasterLoop();
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Unable to get Personnel.",
                });
              }
            )
            .catch(function (error) {});

          getControlSystems();

          // I create an OverlayView, and set it to add the "markerLayer" class to the markerLayer DIV
          var myoverlay = new google.maps.OverlayView();
          myoverlay.draw = function () {
            this.getPanes().markerLayer.id = "markerLayer";
          };
          myoverlay.setMap(map);
        },
        function (error) {
          console.error(error);
        }
      );
    }

    angular.element($window).bind("resize", function () {
      $scope.mapInfo.style["height"] = `${
        window.innerHeight / 10 - SUM_HEIGHT_OF_HEADER_FOOTER
      }rem`;
      $scope.sliderHeight = $scope.mapInfo.style["height"];
    });

    $scope.$on("$destroy", function () {
      clearIntervals();
    });

    init();
  },
]);
