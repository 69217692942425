import React from "react";
import styled from "styled-components";

const CameraEditOptions = ({
  label,
  info,
  children,
}: {
  label?: string;
  info?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Root>
      <TextContainer>
        <div>
          <Label>{label}</Label>
        </div>
        <Description>{info}</Description>
      </TextContainer>
      {children}
    </Root>
  );
};

export default CameraEditOptions;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 2rem;
  justify-content: space-between;
`;
const TextContainer = styled.div`
  margin-right: 2.5rem;
`;
const Label = styled.span`
  font-weight: bold;
  margin-bottom: 1rem;
`;
const Description = styled.span``;
const CheckBox = styled.input`
  &:checked {
    accent-color: var(--color-info-600) !important;
  }
  margin: 0 1.3rem 0 auto !important;
  width: 2.7rem;
  height: 2.7rem;
`;
