/**
 * @generated SignedSource<<a2f0c0dce57b0359e7fb59e3d6e207de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabsetDownloadableFile_downloadableFile$data = {
  readonly createdAt: string;
  readonly downloadUrl: string;
  readonly id: string;
  readonly infoUrl: string;
  readonly isPublic: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"EditFileModalDownloadableFile_downloadableFile">;
  readonly " $fragmentType": "TabsetDownloadableFile_downloadableFile";
};
export type TabsetDownloadableFile_downloadableFile$key = {
  readonly " $data"?: TabsetDownloadableFile_downloadableFile$data;
  readonly " $fragmentSpreads": FragmentRefs<"TabsetDownloadableFile_downloadableFile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TabsetDownloadableFile_downloadableFile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "infoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditFileModalDownloadableFile_downloadableFile"
    }
  ],
  "type": "DownloadableFile",
  "abstractKey": null
};

(node as any).hash = "53505f41af5382068f412516f4138e5e";

export default node;
