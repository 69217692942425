import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { OutputSupervision } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputInformationFragment } from "./OutputInformationContext";
import { serialNumberIsValidWirelessBell } from "./utils";
import { OutputInformationOutputSupervisionField_output$key } from "./__generated__/OutputInformationOutputSupervisionField_output.graphql";

export const outputInformationOutputSupervisionFieldId = (number: string) =>
  `output-information-output-supervision-${number}`;

function OutputInformationOutputSupervisionField() {
  const [
    { number, outputSupervision, isWireless, serialNumber },
    updateOutputInformation,
  ] = useOutputInformationFragment<OutputInformationOutputSupervisionField_output$key>(
    graphql`
      fragment OutputInformationOutputSupervisionField_output on Output {
        id
        number
        outputSupervision
        isWireless
        serialNumber
      }
    `
  );

  const fieldId = outputInformationOutputSupervisionFieldId(String(number));
  const isWirelessBellOutput = serialNumberIsValidWirelessBell(
    serialNumber ?? ""
  );
  const disabled = !isWireless;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Supervision Time"
      disabled={disabled}
      tooltip="If using check-in supervision ('None' is not selected), this represents the maximum duration a device can fail to check-in before going missing."
    >
      <Select
        id={fieldId}
        disabled={disabled}
        value={outputSupervision ?? undefined}
        required
        onChange={({ target }) => {
          updateOutputInformation((recordProxy) => {
            recordProxy.setValue(target.value, "outputSupervision");
          });
        }}
      >
        <Select.Option value={OutputSupervision.NONE}>None</Select.Option>
        {isWirelessBellOutput && (
          <Select.Option value={OutputSupervision.THREE_MINUTES}>
            3 min
          </Select.Option>
        )}
        <Select.Option value={OutputSupervision.ONE_HOUR}>60 min</Select.Option>
        <Select.Option value={OutputSupervision.FOUR_HOURS}>
          240 min
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default OutputInformationOutputSupervisionField;
