/**
 * @generated SignedSource<<83670f06c6cef80ab2454b98264faf43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryFullProgrammingContainer_templateStatus_controlSystem$data = {
  readonly id: string;
  readonly templatePreProgrammingJobStatus: string | null;
  readonly " $fragmentType": "TMSentryFullProgrammingContainer_templateStatus_controlSystem";
};
export type TMSentryFullProgrammingContainer_templateStatus_controlSystem$key = {
  readonly " $data"?: TMSentryFullProgrammingContainer_templateStatus_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryFullProgrammingContainer_templateStatus_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TMSentryFullProgrammingContainer_templateStatus_controlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "TMSentryFullProgrammingContainer_templateStatus_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatePreProgrammingJobStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "75552146d0adade0537f76ac98befc56";

export default node;
