/**
 * @generated SignedSource<<a3cd3ea7ccb80d25c919420ead03908e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ControlSystemKeypadsQuery$variables = {
  id: string;
};
export type ControlSystemKeypadsQuery$data = {
  readonly controlSystemKeypadsAPI: ReadonlyArray<{
    readonly controlSystemId: number;
    readonly firmwareVersion: string;
    readonly keypadSerial: string;
    readonly model: string;
    readonly panelName: string;
    readonly recordNumber: number;
    readonly remoteUpdateTimestamp: string | null;
    readonly updateAvailable: boolean | null;
  } | null> | null;
};
export type ControlSystemKeypadsQuery = {
  response: ControlSystemKeypadsQuery$data;
  variables: ControlSystemKeypadsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "controlSystemId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keypadSerial",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "model",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firmwareVersion",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "panelName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recordNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "controlSystemId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remoteUpdateTimestamp",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateAvailable",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ControlSystemKeypadsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "KeypadsOverview",
        "kind": "LinkedField",
        "name": "controlSystemKeypadsAPI",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ControlSystemKeypadsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "KeypadsOverview",
        "kind": "LinkedField",
        "name": "controlSystemKeypadsAPI",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "684f8ca54d9378a4ed476d56c7d28832",
    "id": null,
    "metadata": {},
    "name": "ControlSystemKeypadsQuery",
    "operationKind": "query",
    "text": "query ControlSystemKeypadsQuery(\n  $id: String!\n) {\n  controlSystemKeypadsAPI(controlSystemId: $id) {\n    keypadSerial\n    model\n    firmwareVersion\n    panelName\n    recordNumber\n    controlSystemId\n    remoteUpdateTimestamp\n    updateAvailable\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3abca51cb8876646b2af2936d5823994";

export default node;
