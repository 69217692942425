App.service("CredentialsService", [
  "$q",
  "CredentialsApi",
  "ControlSystemsCredentialsApi",
  "PersonsCredentialsApi",

  function (
    $q,
    CredentialsApi,
    ControlSystemsCredentialsApi,
    PersonsCredentialsApi,
    CredentialsByIdApi
  ) {
    var _this = this;

    // Accessors
    _this.get = get;
    // _this.update = update;
    _this.destroy = destroy;
    // _this.createForControlSystem = createForControlSystem;
    _this.getForControlSystem = getForControlSystem;
    // _this.createForPerson = createForPerson;
    // _this.getForPerson = getForPerson;
    // _this.applyToUserCode = applyToUserCode;

    /**
     * Get a list of credentials by ids
     * @param {[int]} credentialIds - comma-separated list of ids of credentials to retrieve
     * @returns {*}
     */
    function get(credentialIds) {
      var deferred = $q.defer();
      if (angular.isArray(credentialIds)) {
        var ids = [];
        // ensure each id is unique, non-zero, and an int
        angular.forEach(credentialIds, function (id) {
          if (+id > 0 && !ids.includes(+id)) {
            ids.push(+id);
          }
        });
        CredentialsApi.show_all(
          { locator: ids.join(",") },
          function (data) {
            var credentials = [];
            angular.forEach(data, function (record) {
              credentials.push(record.credential);
            });
            deferred.resolve(credentials);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        console.warn(
          "CredentialsService-get() called with a non-array parameter. Not supported."
        );
        deferred.reject();
      }
      return deferred.promise;
    }

    /**
     * Patch an existing credential
     * @param credential
     * @returns {*}
     */
    function update(credential) {
      var deferred = $q.defer();
      CredentialsApi.update(
        { locator: credential.id },
        angular.toJson(credential),
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Destroy a credential
     * @param credentialId
     * @returns {*}
     */
    function destroy(credentialId) {
      var deferred = $q.defer();
      CredentialsApi.destroy(
        { locator: credentialId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Create a new (unassigned) credential to be owned by the control system
     * @param controlSystemId
     * @param credential
     */
    function createForControlSystem(controlSystemId, credential) {
      console.warn(
        "CredentialsService->createForControlSystem() not implemented"
      );
    }

    /**
     * Data for queueEstablishJob()
     * @type {{inProgress: boolean, deferredObject: null, systemId: int}}
     * note: In testing, we noticed that if credentials retrieve calls are stacked, the resolves take exponentially
     *       longer
     */
    var credentialRetrieveJob = {
      inProgress: false,
      deferredObject: null,
      systemId: 0,
    };
    /**
     * Get the credentials owned by a specific control system.
     * @param controlSystemId
     * @param [status=all] - Restrict the set of owned credentials based on their status:
     *                           * assigned - user_codes array on credential obj has at least 1 value
     *                           * unassigned - user_codes array on credential obj is empty
     *                           * all (default).
     *                           note: The user_codes array on the credential object is built when the credential
     *                                 is retrieved
     */
    function getForControlSystem(controlSystemId, status) {
      if (credentialRetrieveJob.inProgress && credentialRetrieveJob.systemId) {
        return credentialRetrieveJob.deferredObject.promise;
      } else {
        var deferred = $q.defer();
        credentialRetrieveJob.inProgress = true;
        credentialRetrieveJob.deferredObject = deferred;
        credentialRetrieveJob.systemId = controlSystemId;
        var params = {
          controlSystemId: controlSystemId,
        };
        if (status) {
          params.status = status;
        }
        ControlSystemsCredentialsApi.index(
          params,
          function (data) {
            var credentials = [];
            angular.forEach(data, function (record) {
              credentials.push(record.credential);
            });
            deferred.resolve(credentials);
            credentialRetrieveJob.inProgress = false;
          },
          function (error) {
            deferred.reject(error);
            credentialRetrieveJob.inProgress = false;
          }
        );
        return deferred.promise;
      }
    }

    /**
     * Create a new credential for an existing person.
     * @param personId
     * @param credential
     */
    function createForPerson(personId, credential) {
      console.warn("CredentialsService->createForPerson() not implemented");
    }

    /**
     * Get the credential details of an existing person.
     * @param personId
     */
    function getForPerson(personId) {
      console.warn("CredentialsService->getForPerson() not implemented");
    }

    /**
     * Assign a credential to a user code
     * @param credential
     * @param userCodeId
     * @param panelId
     * @returns {*}
     */
    function applyToUserCode(credential, userCodeId, panelId) {
      var deferred = $q.defer();
      credential.user_codes.push({
        number: userCodeId,
        panel_id: panelId,
      });
      update(credential)
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }
  },
]);
