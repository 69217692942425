import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { Svg } from "components/CameraEditCommon/CameraEditStyledComponents";
import {
  motionDetectionRegionsSvgId,
  viewBoxMaxHeight,
  viewBoxMaxWidth,
} from "components/CameraEditCommon/constants";
import { RegionBounds, ViewBox } from "components/CameraEditCommon/types";
import {
  getKeyboardTransformer,
  getMouseTransformer,
  getRegionColor,
  renderXCoordinate,
  renderYCoordinate,
} from "components/CameraEditCommon/utils";
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import {
  MotionDetectionRegionCoordinates,
  SecureComCamera,
} from "securecom-graphql/client";
import { isNullOrUndefined } from "util";
import MotionDetectionRegion from "./MotionDetectionRegion";
import { MotionDetectionRegionsWithClientSchemaData } from "./type";
import { moveMotionDetectionPoint, moveRegionPointForKeyPress } from "./utils";
import { UniviewMotionDetectionRegions_secureComCamera$key } from "./__generated__/UniviewMotionDetectionRegions_secureComCamera.graphql";

interface UniviewMotionDetectionRegionsProps {
  aspectRatio: number;
  camera: UniviewMotionDetectionRegions_secureComCamera$key;
  isEditable: boolean;
}
function UniviewMotionDetectionRegions(
  props: UniviewMotionDetectionRegionsProps
) {
  const { aspectRatio, camera, isEditable } = props;
  const data = useFragment(
    graphql`
      fragment UniviewMotionDetectionRegions_secureComCamera on SecureComCamera {
        id

        motionDetectionRegions {
          activeDetectionRegionIndex
          mouseDown

          minCoordinatesX
          maxCoordinatesX
          minCoordinatesY
          maxCoordinatesY

          regions {
            id

            activeCoordinateIndexes
            index
            daySensitivityLevel
            coordinates {
              x
              y
            }
          }
        }
      }
    `,
    camera
  );
  const {
    activeDetectionRegionIndex,
    minCoordinatesX,
    maxCoordinatesX,
    minCoordinatesY,
    maxCoordinatesY,
  } = data.motionDetectionRegions;
  const relayEnv = useRelayEnvironment();

  const bounds = useMemo(
    () => ({
      minX: minCoordinatesX,
      maxX: maxCoordinatesX,
      minY: minCoordinatesY,
      maxY: maxCoordinatesY,
    }),
    [minCoordinatesX, maxCoordinatesX, minCoordinatesY, maxCoordinatesY]
  );
  const viewBox = useMemo(
    (): ViewBox =>
      aspectRatio <= viewBoxMaxWidth / viewBoxMaxHeight
        ? [viewBoxMaxWidth * aspectRatio, viewBoxMaxHeight]
        : [viewBoxMaxWidth, viewBoxMaxHeight / aspectRatio],
    [aspectRatio]
  );
  const xCoordinateRenderer = useCallback(renderXCoordinate(bounds)(viewBox), [
    bounds,
    viewBox,
  ]);
  const yCoordinateRenderer = useCallback(renderYCoordinate(bounds)(viewBox), [
    bounds,
    viewBox,
  ]);

  useLayoutEffect(() => {
    relayEnv.commitUpdate((store) => {
      const camera = store.get(data.id) as RecordProxy<SecureComCamera>;
      const regions = camera
        .getLinkedRecord("motionDetectionRegions")
        .getLinkedRecords("regions");
      regions.forEach((region, index) => {
        region.setValue(index, "index");
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentKeyDownUpdater(store, data.id, event, bounds);
      });
    }
    function handleMouseDown(event: MouseEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseDownUpdater(store, data.id, event);
      });
    }
    function handleMouseUp(event: MouseEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseUpUpdater(store, data.id, event);
      });
    }
    function handleMouseMove(event: MouseEvent) {
      if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
        event.preventDefault();
      }
      relayEnv.commitUpdate((store) => {
        documentMouseMoveUpdater(store, data.id, event, bounds);
      });
    }

    if (isEditable) {
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (isEditable) {
        document.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [relayEnv, data.id, activeDetectionRegionIndex, bounds, viewBox]);

  const regions = useMemo(() => {
    if (isNullOrUndefined(activeDetectionRegionIndex)) {
      return data.motionDetectionRegions.regions;
    }

    const regionsWithActiveOnTop = [...data.motionDetectionRegions.regions];
    return regionsWithActiveOnTop.concat(
      regionsWithActiveOnTop.splice(activeDetectionRegionIndex, 1)
    );
  }, [activeDetectionRegionIndex, data.motionDetectionRegions.regions]);

  return (
    <Svg
      id={motionDetectionRegionsSvgId}
      viewBox={`0 0 ${viewBox[0]} ${viewBox[1]}`}
    >
      {regions.map((region) => (
        <MotionDetectionRegion
          key={region.id}
          color={getRegionColor(region.index ?? -1)}
          index={region.index ?? -1}
          renderX={xCoordinateRenderer}
          renderY={yCoordinateRenderer}
          coordinates={region.coordinates as MotionDetectionRegionCoordinates[]}
          activeCoordinateIndexes={
            (region.activeCoordinateIndexes as number[]) ?? []
          }
          isActive={activeDetectionRegionIndex === region.index}
          sensitivityLevel={region.daySensitivityLevel}
          mouseDown={data.motionDetectionRegions.mouseDown}
        />
      ))}
    </Svg>
  );
}

export default UniviewMotionDetectionRegions;

function documentKeyDownUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: KeyboardEvent,
  bounds: RegionBounds
) {
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;
  const motionDetectionRegions = camera.getLinkedRecord(
    "motionDetectionRegions"
  ) as RecordProxy<MotionDetectionRegionsWithClientSchemaData>;
  const detectionRegions = motionDetectionRegions.getLinkedRecords("regions");
  const activeDetectionRegionIndex = motionDetectionRegions.getValue(
    "activeDetectionRegionIndex"
  );

  if (isNullOrUndefined(activeDetectionRegionIndex)) {
    return;
  }

  if (event.key === "Escape") {
    motionDetectionRegions.setValue(null, "activeDetectionRegionIndex");
  } else if (event.key === "Tab") {
    motionDetectionRegions.setValue(
      (activeDetectionRegionIndex + 1) % detectionRegions.length,
      "activeDetectionRegionIndex"
    );
  } else if (event.key === "Backspace") {
    // Deleting region
    const activeRegionId =
      detectionRegions[activeDetectionRegionIndex].getValue("id");
    const filteredRegions = motionDetectionRegions
      .getLinkedRecords("regions")
      .filter((region) => region.getValue("id") !== activeRegionId);

    filteredRegions.forEach((region, index) => region.setValue(index, "index")); // Resetting index for regions
    motionDetectionRegions.setLinkedRecords(filteredRegions, "regions");
    motionDetectionRegions.setValue(null, "activeDetectionRegionIndex");
  } else if (
    isNotNullOrUndefined(activeDetectionRegionIndex) &&
    detectionRegions[activeDetectionRegionIndex]
  ) {
    moveRegionPointForKeyPress(getKeyboardTransformer(bounds)(event))(
      detectionRegions[activeDetectionRegionIndex]
    );
  }
}

function documentMouseDownUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: MouseEvent
) {
  const { target } = event;
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;
  const motionDetectionRegions = camera.getLinkedRecord(
    "motionDetectionRegions"
  ) as RecordProxy<MotionDetectionRegionsWithClientSchemaData>;
  const detectionRegions = motionDetectionRegions.getLinkedRecords("regions");
  const activeDetectionRegionIndex = motionDetectionRegions.getValue(
    "activeDetectionRegionIndex"
  );

  if (
    target instanceof Element &&
    target.id.match(/motion-detection-region-[0-9]+/)
  ) {
    const idParts = target.id.split("-");
    const activeRegionIndex = Number(idParts[3]);
    const activeRegion = detectionRegions[activeRegionIndex];
    const activeCoordinateIndexes = activeRegion.getValue(
      "activeCoordinateIndexes"
    );

    if (idParts[4] === "point") {
      const activeCoordinates = event.shiftKey
        ? new Set(activeCoordinateIndexes)
        : new Set<number>();
      activeCoordinates.add(Number(idParts[5]));
      activeRegion.setValue([...activeCoordinates], "activeCoordinateIndexes");
      motionDetectionRegions.setValue(true, "mouseDown");
    } else if (idParts[4] === "edge") {
      activeRegion.setValue(Number(idParts[5]), "activeEdgeIndex");
      motionDetectionRegions.setValue(true, "mouseDown");
    } else if (activeCoordinateIndexes?.length) {
      activeRegion.setValue([], "activeCoordinateIndexes");
    } else {
      motionDetectionRegions.setValue(
        activeRegionIndex,
        "activeDetectionRegionIndex"
      );
      motionDetectionRegions.setValue(true, "mouseDown");
    }
  } else if (isNotNullOrUndefined(activeDetectionRegionIndex)) {
    const activeRegion = detectionRegions[activeDetectionRegionIndex];
    if (isNotNullOrUndefined(activeRegion)) {
      activeRegion.setValue([], "activeCoordinateIndexes");
      motionDetectionRegions.setValue(null, "activeDetectionRegionIndex");
    }
    motionDetectionRegions.setValue(true, "mouseDown");
  } else {
    motionDetectionRegions.setValue(true, "mouseDown");
  }
}

function documentMouseUpUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: MouseEvent
) {
  const { target } = event;
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;
  const motionDetectionRegions = camera.getLinkedRecord(
    "motionDetectionRegions"
  ) as RecordProxy<MotionDetectionRegionsWithClientSchemaData>;
  const detectionRegions = motionDetectionRegions.getLinkedRecords("regions");

  if (
    target instanceof Element &&
    target.id.match(/motion-detection-region-[0-9]+/)
  ) {
    const idParts = target.id.split("-");
    const activeRegionIndex = Number(idParts[3]);
    detectionRegions[activeRegionIndex].setValue(null, "activeEdgeIndex");
  }
  motionDetectionRegions.setValue(false, "mouseDown");
}

function documentMouseMoveUpdater(
  store: RecordSourceProxy,
  cameraId: string,
  event: MouseEvent,
  bounds: RegionBounds
) {
  const camera = store.get(cameraId) as RecordProxy<SecureComCamera>;
  const motionDetectionRegions = camera.getLinkedRecord(
    "motionDetectionRegions"
  ) as RecordProxy<MotionDetectionRegionsWithClientSchemaData>;
  const mouseDown = motionDetectionRegions.getValue("mouseDown");
  const detectionRegions = motionDetectionRegions.getLinkedRecords("regions");
  const activeDetectionRegionIndex = motionDetectionRegions.getValue(
    "activeDetectionRegionIndex"
  );
  const svgElement = document.getElementById(motionDetectionRegionsSvgId);

  if (
    svgElement &&
    mouseDown &&
    isNotNullOrUndefined(activeDetectionRegionIndex) &&
    detectionRegions[activeDetectionRegionIndex]
  ) {
    moveMotionDetectionPoint(getMouseTransformer(bounds)(svgElement)(event))(
      detectionRegions[activeDetectionRegionIndex]
    );
  }
}
