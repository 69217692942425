App.factory("TempAppUserAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.jobsApiUrl + "/api/v1/TempAppUser/:route/:groupId",
      {},
      {
        create: { method: "POST", params: { route: "Create" } },
        getStatus: { method: "GET", params: { route: "Status" } },
      }
    );
  },
]);
