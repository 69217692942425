import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorDoorReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorDoorReportsField_remoteOptions.graphql";

export const remoteOptionsIntegratorDoorReportsFieldId = () =>
  "remote-options-integrator-door-reports";

function RemoteOptionsIntegratorDoorReportsField() {
  const [{ integratorDoorReports, integratorConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsIntegratorDoorReportsField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsIntegratorDoorReportsField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            integratorDoorReports
            integratorConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsIntegratorDoorReportsFieldId();
  const label = "Integrator Door Reports";
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
    >
      <Switch
        label={label}
        disabled={disabled}
        id={fieldId}
        checked={!!integratorDoorReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !integratorDoorReports,
              "integratorDoorReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorDoorReportsField;
