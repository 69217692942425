/**
 * @generated SignedSource<<3de4942c9e56cccd6c0186e9c7e7d74c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvailableCredentialsTableFragment_dealer$data = {
  readonly credentialsSummaryConnection: {
    readonly nodes: ReadonlyArray<{
      readonly availableCredits: number;
      readonly customer: {
        readonly databaseId: number;
        readonly id: string;
        readonly name: string;
      } | null;
      readonly id: string;
      readonly totalMobileCredentials: number;
      readonly totalPurchased: number;
    }>;
  };
  readonly " $fragmentType": "AvailableCredentialsTableFragment_dealer";
};
export type AvailableCredentialsTableFragment_dealer$key = {
  readonly " $data"?: AvailableCredentialsTableFragment_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AvailableCredentialsTableFragment_dealer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AvailableCredentialsTableFragment_dealer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DealerCredentialsSummaryConnection",
      "kind": "LinkedField",
      "name": "credentialsSummaryConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CredentialSummary",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "availableCredits",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPurchased",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalMobileCredentials",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Customer",
              "kind": "LinkedField",
              "name": "customer",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "databaseId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};
})();

(node as any).hash = "ed080739ac011ac5c40e1b6f63c69648";

export default node;
