import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver2SupervisoryTroubleReportsField_communication$key } from "./__generated__/CommunicationReceiver2SupervisoryTroubleReportsField_communication.graphql";

export const communicationReceiver2SupervisoryTroubleReportsFieldId = () =>
  "communication-receiver-2-supervisory-trouble-reports";

function CommunicationReceiver2SupervisoryTroubleReportsField() {
  const [
    { receiver2Backup, receiver2SupervisoryTroubleReports },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationReceiver2SupervisoryTroubleReportsField_communication$key>(
    graphql`
      fragment CommunicationReceiver2SupervisoryTroubleReportsField_communication on Communication {
        receiver2Backup
        receiver2SupervisoryTroubleReports
      }
    `
  );

  const fieldId = communicationReceiver2SupervisoryTroubleReportsFieldId();
  const label = "Supervisory / Trouble Reports";
  const disabled = receiver2Backup === true;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Turns on Zone Trouble, Low Battery, Missing, Fault and System Trouble reports and associated restoral reports."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={receiver2SupervisoryTroubleReports}
        disabled={disabled}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              !receiver2SupervisoryTroubleReports,
              "receiver2SupervisoryTroubleReports"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver2SupervisoryTroubleReportsField;
