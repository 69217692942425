import { addClassesIf } from "common/utils/web/css";
import { Input, Panel, Table, TableHeader } from "components/DaStyledElements";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import styled from "styled-components";
import ResetTypeTable from "./ResetTypeTable";
import { Pages, SortKey } from "./utils";

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, minmax(0, max-content));
  grid-template-areas: "cred-config" "auto-refill" "history";
  grid-row-gap: 1.6rem;
  max-width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, minmax(0, max-content));
    grid-template-areas: "cred-config auto-refill auto-refill" "history history history";
    grid-gap: 1.6rem;
  }
`;
const GridHistory = styled.div`
  grid-area: history;
`;
const TableHeaderSorting = styled.th`
  cursor: pointer;
  color: #888 !important;
`;

function TableContainer({
  activePage,
  setSelectedDealer,
}: {
  activePage: Pages;
  setSelectedDealer: (databaseDealerId: number) => void;
}) {
  const [paginationState, dispatch] = usePaginationState<SortKey>({
    sortOrder: SortOrder.Ascending,
    sortKey: null,
    pageSize: 100,
  });

  return (
    <Grid>
      <GridHistory>
        <Panel.Table>
          <TableHeader>
            <TableHeader.Left>
              <label className="sr-only" htmlFor="search-input"></label>
              <Input.TableSearch
                id="search-input"
                value={paginationState.search}
                onChange={(search) => {
                  dispatch({
                    type: ActionType.SetSearch,
                    search,
                  });
                }}
              />
            </TableHeader.Left>
          </TableHeader>
          <Table>
            <thead>
              <tr>
                <TableHeaderSorting
                  className={addClassesIf(
                    {
                      "is-active":
                        paginationState.sortKey === SortKey.HardwareType,
                      "tablesort-desc":
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-asc":
                        paginationState.sortOrder === SortOrder.Ascending,
                    },
                    "tablesort-sortable"
                  )}
                  onClick={() => {
                    if (paginationState.sortKey === SortKey.HardwareType) {
                      dispatch({ type: ActionType.ToggleSortOrder });
                    } else {
                      dispatch({
                        type: ActionType.SetSortKey,
                        sortKey: SortKey.HardwareType,
                      });
                    }
                  }}
                >
                  Type
                </TableHeaderSorting>
                <TableHeaderSorting
                  className={addClassesIf(
                    {
                      "is-active": paginationState.sortKey === SortKey.Version,
                      "tablesort-desc":
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-asc":
                        paginationState.sortOrder === SortOrder.Ascending,
                    },
                    "tablesort-sortable"
                  )}
                  onClick={() => {
                    if (paginationState.sortKey === SortKey.Version) {
                      dispatch({ type: ActionType.ToggleSortOrder });
                    } else {
                      dispatch({
                        type: ActionType.SetSortKey,
                        sortKey: SortKey.Version,
                      });
                    }
                  }}
                >
                  Version
                </TableHeaderSorting>
                <TableHeaderSorting
                  className={addClassesIf(
                    {
                      "is-active": paginationState.sortKey === SortKey.Serial,
                      "tablesort-desc":
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-asc":
                        paginationState.sortOrder === SortOrder.Ascending,
                    },
                    "tablesort-sortable"
                  )}
                  onClick={() => {
                    if (paginationState.sortKey === SortKey.Serial) {
                      dispatch({ type: ActionType.ToggleSortOrder });
                    } else {
                      dispatch({
                        type: ActionType.SetSortKey,
                        sortKey: SortKey.Serial,
                      });
                    }
                  }}
                >
                  Serial
                </TableHeaderSorting>
                <TableHeaderSorting
                  className={addClassesIf(
                    {
                      "is-active":
                        paginationState.sortKey === SortKey.ConnectionType,
                      "tablesort-desc":
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-asc":
                        paginationState.sortOrder === SortOrder.Ascending,
                    },
                    "tablesort-sortable"
                  )}
                  onClick={() => {
                    if (paginationState.sortKey === SortKey.ConnectionType) {
                      dispatch({ type: ActionType.ToggleSortOrder });
                    } else {
                      dispatch({
                        type: ActionType.SetSortKey,
                        sortKey: SortKey.ConnectionType,
                      });
                    }
                  }}
                >
                  Connection Type
                </TableHeaderSorting>
                <TableHeaderSorting
                  className={addClassesIf(
                    {
                      "is-active": paginationState.sortKey === SortKey.Dealer,
                      "tablesort-desc":
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-asc":
                        paginationState.sortOrder === SortOrder.Ascending,
                    },

                    "tablesort-sortable "
                  )}
                  onClick={() => {
                    if (paginationState.sortKey === SortKey.Dealer) {
                      dispatch({ type: ActionType.ToggleSortOrder });
                    } else {
                      dispatch({
                        type: ActionType.SetSortKey,
                        sortKey: SortKey.Dealer,
                      });
                    }
                  }}
                >
                  Dealer
                </TableHeaderSorting>
                <TableHeaderSorting
                  className={addClassesIf(
                    {
                      "is-active": paginationState.sortKey === SortKey.Resets,
                      "tablesort-desc":
                        paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-asc":
                        paginationState.sortOrder === SortOrder.Ascending,
                    },

                    "tablesort-sortable "
                  )}
                  onClick={() => {
                    if (paginationState.sortKey === SortKey.Resets) {
                      dispatch({ type: ActionType.ToggleSortOrder });
                    } else {
                      dispatch({
                        type: ActionType.SetSortKey,
                        sortKey: SortKey.Resets,
                      });
                    }
                  }}
                >
                  Resets
                </TableHeaderSorting>
              </tr>
            </thead>

            <React.Suspense
              fallback={
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <GenericPageFallback />
                    </td>
                  </tr>
                </tbody>
              }
            >
              <ResetTypeTable
                paginationState={paginationState}
                dispatch={dispatch}
                activePage={activePage}
                setSelectedDealer={setSelectedDealer}
              />
            </React.Suspense>
          </Table>
        </Panel.Table>
      </GridHistory>
    </Grid>
  );
}

export default TableContainer;
