import React from "react";

type SevenInchLogoContextType = {
  imageSource: string;
  setImageSource: (source: string) => void;
  selectedFile: File | undefined | null;
  setSelectedFile: (file: File | undefined | null) => void;
};
const SevenInchLogoContext = React.createContext<SevenInchLogoContextType>({
  imageSource: "",
  setImageSource: () => {},
  selectedFile: null,
  setSelectedFile: () => {},
});

export { SevenInchLogoContext };
