App.directive("daToggleSort", function ($timeout) {
  return {
    restrict: "A",
    scope: {
      toggleSortColumn: "@",
      toggleSortPredicate: "=",
      toggleSortReverse: "=",
    },
    link: function ($scope, elem) {
      elem.bind("click", function () {
        if (
          angular.isUndefined($scope.toggleSortColumn) ||
          angular.isUndefined($scope.toggleSortPredicate) ||
          angular.isUndefined($scope.toggleSortReverse)
        )
          return;

        elem.removeClass("is-active tablesort-desc tablesort-asc");
        elem.siblings().removeClass("is-active tablesort-desc tablesort-asc");

        $scope.toggleSortPredicate = $scope.toggleSortColumn;

        $scope.toggleSortReverse = !$scope.toggleSortReverse;

        if ($scope.toggleSortReverse) {
          elem.addClass("is-active tablesort-asc");
        } else {
          elem.addClass("is-active tablesort-desc");
        }

        $timeout(function () {});
      });
      elem.on("mouseenter", function () {
        elem.addClass("hovered");
      });
      elem.on("mouseleave", function () {
        elem.removeClass("hovered");
      });
    },
  };
});
