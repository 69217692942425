App.controller("NewsItemsCtrl", [
  "$rootScope",
  "$scope",
  "$window",
  "$location",
  "$anchorScroll",
  "$http",
  "$timeout",
  "$filter",
  "focus",
  "$modal",
  "NewsItemsService",
  "NEWS_ITEMS",
  "UserService",
  function (
    $rootScope,
    $scope,
    $window,
    $location,
    $anchorScroll,
    $http,
    $timeout,
    $filter,
    focus,
    $modal,
    NewsItemsService,
    NEWS_ITEMS,
    UserService
  ) {
    $scope.format = "shortDate"; // Preferred date picker format
    $scope.state = "default"; // Used for tracking the state of the view
    $scope.curNewsItem = null; // A reference to the news item we're currently editing
    $scope.newsItems = null; // A list of news items
    $scope.datePickerOpen = { startDateOpened: false, endDateOpened: false }; // Switches for GUI for date pickers
    $scope.isBusy = false; // Used for toggling the css spinner on the form
    $scope.saving = false; // Used for toggling the css spinner on the save button
    $scope.UserService = UserService;
    $scope.dealerLevels = [
      { name: "D1", level: "1", enabled: false },
      { name: "D2", level: "2", enabled: false },
      { name: "D3", level: "3", enabled: false },
      { name: "D4", level: "4", enabled: false },
      { name: "D5", level: "5", enabled: false },
      { name: "D6", level: "6", enabled: false },
      { name: "NetOne", level: "O", enabled: false },
    ];
    const env = process.env.REACT_APP_SECURECOM_ENV;
    $scope.alertGraphicLink =
      env === "prod_eu"
        ? "https://logos.eu.securecomwireless.com/Uploads/alert/scw-alert-graphic.png"
        : "https://logos.securecomwireless.com/Uploads/alert/scw-alert-graphic.png"; //placeholder for the alert graphic url
    $scope.useAlertGraphic = false;
    $scope.readMore = {};
    $scope.gotoTop = function () {
      $location.hash("top");
      $anchorScroll();
    };

    $scope.willSendToAll = function (dealerLevels) {
      return !dealerLevels.some(function (level) {
        return level.enabled === true;
      });
    };

    $scope.enableSendToAll = function () {
      $scope.dealerLevels.forEach(function (level) {
        level.enabled = false;
      });
    };

    $scope.toggleAlertGraphic = function () {
      if ($scope.useAlertGraphic) {
        $scope.useAlertGraphic = false;
        $scope.curNewsItem.errors = "";
      } else {
        $scope.useAlertGraphic = true;
        $scope.curNewsItem.image_url = $scope.alertGraphicLink;
      }
    };

    const applyCurNewsItemToDealerLevels = () =>
      $scope.dealerLevels.forEach((level) => {
        level.enabled = $scope.curNewsItem?.dealer_levels
          ?.split(",")
          .includes(level.level);
      });

    const applyDealerLevelsToCurNewsItem = () =>
      ($scope.curNewsItem.dealer_levels = $scope.dealerLevels
        .map((level) => (level.enabled ? level.level : null))
        .filter((level) => level !== null)
        .join(","));

    /**
     * Get the news item from the server for editing
     * @param {*} newsItem
     */
    $scope.newsItemClick = function (newsItem) {
      if (UserService.canEditNewsItems()) {
        NewsItemsService.read(newsItem.id)
          .then(
            function (item) {
              $scope.curNewsItem = item;
              $scope.curNewsItem.start_date = new Date(
                $scope.curNewsItem.start_date
              );
              // see if the end date of the news item is greater than the year set
              // in NEWS_ITEMS.NO_END_DATE_EPOCH of Jan 1 3000
              const endDate = new Date($scope.curNewsItem.end_date);
              $scope.curNewsItem.end_date =
                endDate > new Date("1/1/2999") ? null : endDate;
              applyCurNewsItemToDealerLevels();
              $scope.state = "edit";
              $scope.gotoTop();
            },
            function (error) {
              $scope.state = "default";
              console.error(
                "NewsItemsCtrl->newsItemClick() - Error getting news item: " +
                  angular.toJson(error)
              );
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to get news item",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        if (newsItem.news_link) {
          $window.open(newsItem.news_link);
        } else {
          $scope.openNewsItemView(newsItem);
        }
      }
    };

    $scope.copyNewsItem = function (newsItem) {
      NewsItemsService.read(newsItem.id)
        .then(
          function (item) {
            $scope.curNewsItem = item;

            // Create a start and end date starting today and 00:00 and ending today at 11:59.59.999 PM
            var startDate = dateTimeForceUTC(new Date());
            startDate.setHours(0, 0, 0, 0);
            var endDate = null;

            $scope.curNewsItem.start_date = new Date(startDate);
            $scope.curNewsItem.end_date = null;
            applyCurNewsItemToDealerLevels();
            $scope.state = "new";
            $scope.gotoTop();
          },
          function (error) {
            $scope.state = "default";
            console.error(
              "NewsItemsCtrl->newsItemClick() - Error getting news item: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to get news item",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * A default image to use if one isn't designated
     * @type {string}
     */
    var defaultNewsThumbnail = "https://dmp.com/images/dmp_150_news_logo.jpg";

    /**
     * Save the current news item to the server
     */
    $scope.createNewsItem = function () {
      $scope.saving = true;
      if (!$scope.curNewsItem.image_url) {
        $scope.curNewsItem.image_url = defaultNewsThumbnail;
      }
      if ($scope.curNewsItem.end_date == null) {
        $scope.curNewsItem.end_date = new Date(NEWS_ITEMS.NO_END_DATE_EPOCH);
      }
      applyDealerLevelsToCurNewsItem();
      NewsItemsService.create($scope.curNewsItem)
        .then(
          function () {
            $scope.saving = false;
            $rootScope.alerts.push({
              type: "success",
              text: "Successfully created news item",
            });
            resetForm();
          },
          function (error) {
            $scope.saving = false;
            console.error(
              "NewsItemsCtrl->createNewsItem() - Error while creating a news item: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to create news item",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Delete the current news item from the server
     */
    $scope.deleteNewsItem = function () {
      NewsItemsService.delete($scope.curNewsItem.id)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Successfully deleted news item",
            });
            resetForm();
          },
          function (error) {
            console.error(
              "NewsItemsCtrl->deleteNewsItem() - Error while deleting the news item: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to delete news item",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Cancel the current edit by resetting the form
     */
    $scope.cancelNewsItemEdit = function () {
      resetForm();
    };

    $scope.newNewsItem = function () {
      initNewsItem();
      $scope.state = "new";
    };

    /**
     * Update the current (existing) news item
     */
    $scope.saveNewsItem = function () {
      $scope.saving = true;
      if ($scope.curNewsItem.end_date == null) {
        $scope.curNewsItem.end_date = new Date(NEWS_ITEMS.NO_END_DATE_EPOCH);
      }
      applyDealerLevelsToCurNewsItem();
      NewsItemsService.update($scope.curNewsItem)
        .then(
          function () {
            $scope.saving = false;
            $rootScope.alerts.push({
              type: "success",
              text: "Successfully saved news item",
            });
            resetForm();
          },
          function (error) {
            $scope.saving = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to save news item",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Start Date picker click function
     * @param $event
     */
    $scope.start_date_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.startDateOpened = true;
    };

    /**
     * End Date picker click function
     * @param $event
     */
    $scope.end_date_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.endDateOpened = true;
    };

    /**
     * Get all news items from the server
     * @param {boolean} [initial=false] - is being run from the init function
     */
    $scope.getNewsItems = function (initial) {
      $scope.isBusy = true;
      $scope.newsItems = [];

      var itemsPromise = {};
      if (UserService.canEditNewsItems()) {
        itemsPromise = NewsItemsService.read();
      } else {
        itemsPromise = NewsItemsService.getActive();
      }

      itemsPromise
        .then(
          function (items) {
            $scope.newsItems = items;
            $scope.isBusy = false;
            if (initial) {
              NewsItemsService.markAllRead();
            }
          },
          function (error) {
            $scope.isBusy = false;
            console.error(
              "NewsItemsCtrl->getNewsItems() - Error while getting today's news items:" +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to retrieve news.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Scope the form and initialize it - run from ng-init
     * @param {*} form - the news items form
     */
    $scope.initForm = function (form) {
      if (angular.isDefined(form)) {
        $scope["newsItemForm"] = form;
        resetForm();
      }
    };

    /**
     * Returns true if news items fields are editable
     * @returns {boolean}
     */
    $scope.disableNewsItemsFields = function () {
      return (
        !UserService.canEditNewsItems() ||
        ["new", "edit"].indexOf($scope.state) === -1
      );
    };

    /**
     * Resets the form to an untouched state and sets the curNewsItem to null
     */
    function resetForm() {
      if (angular.isDefined($scope.newsItemForm)) {
        $scope.newsItemForm.$setUntouched();
      }
      initNewsItem();
      $scope.state = "default";
      $scope.getNewsItems();
    }

    /**
     * Create a new news item object
     */
    function initNewsItem() {
      // Create a start and end date starting today and 00:00 and ending today at 11:59.59.999 PM
      let startDate = dateTimeForceUTC(new Date());
      startDate.setHours(0, 0, 0, 0);
      let endDate = null;

      $scope.curNewsItem = {};
      // Set the start and end date to today
      $scope.curNewsItem.start_date = startDate;
      $scope.curNewsItem.end_date = endDate;
      $scope.curNewsItem.dealerLevels = [];
      applyCurNewsItemToDealerLevels();
      $scope.message = null;
      $scope.curNewsItem.image_url = null;
      $scope.curNewsItem.news_link = null;
      $scope.curNewsItem.category = "NewsItem";
    }
    $scope.viewPopOver = function (st_date, alert_title, alert_message) {
      $rootScope.unreadNews = {
        start_date: st_date,
        title: alert_title,
        message: alert_message,
      };
    };

    /**
     * Initialize the NewsItemsCtrl
     */
    function init() {
      $scope.isBusy = true;
      // Get all news items from the server
      $scope.getNewsItems(true);
      if (UserService.canEditNewsItems()) {
        // Initialize a new news item so that the user can begin creating a news item immediately
        initNewsItem();
      }
    }

    $scope.openNewsItemView = function (myItem) {
      $scope.myObject = myItem;
      $scope.openNewsItemViewModal = $modal.open({
        templateUrl: "app/dashboard/news_items/news-item-view-modal.html",
        windowClass: "lg",
        controller: "newsItemViewModalCtrl",
        size: "lg",
        backdrop: "true",
        scope: $scope,
      });
    };

    init();
  },
]);
App.controller("newsItemViewModalCtrl", [
  "$scope",
  "$modalInstance",
  "$state",
  function ($scope, $modalInstance, $state) {
    $scope.dismissNewsItemViewModal = function () {
      $modalInstance.dismiss("cancel");
    };
  },
]);
