import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { idAsString, toControlSystemId } from "securecom-graphql/client";
import ApHealthAndDiagnostics from "./ApHealthAndDiagnostics";
import { VarDiagnosticsRootVARHubQuery } from "./__generated__/VarDiagnosticsRootVARHubQuery.graphql";

const VarDiagnosticsRoot = ({
  inputPanelId,
  authToken,
}: {
  inputPanelId: number;
  authToken: string;
}) => {
  const globalSystemId = idAsString(toControlSystemId(inputPanelId));

  const data = useLazyLoadQuery<VarDiagnosticsRootVARHubQuery>(
    graphql`
      query VarDiagnosticsRootVARHubQuery($systemId: ID!) {
        node(id: $systemId) {
          id
          __typename
          ... on ControlSystem {
            id
            varHubs {
              macAddress
              currentDiagnostics {
                data
              }
              recentDiagnostics {
                data
              }
            }
          }
        }
      }
    `,
    { systemId: globalSystemId },
    { fetchPolicy: "store-and-network" }
  );

  if (!!data?.node?.varHubs && data.node.varHubs.length > 0) {
    const macAddresses = data.node.varHubs.map((h) => h?.macAddress || "");

    return (
      <ApHealthAndDiagnostics
        authToken={authToken}
        macAddresses={macAddresses}
      />
    );
  } else {
    return (
      <>
        <p style={{ textAlign: "center" }}>
          Couldn't find any hubs for this panel.
        </p>
      </>
    );
  }
};

export default VarDiagnosticsRoot;
