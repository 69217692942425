/**
 * @ngdoc directive
 * @name App.directive:numberToString
 *
 * @description
 *   Converts a number input field to String in the model
 *
 * @todo
 *   This directive gets applied before validation in forms
 *   which causes an issue with min and max checks.  See if
 *   this can be applied after validation.
 *
 */
App.directive("daNumberToString", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, elem, attrs, ctrl) {
      if (attrs.type.toLowerCase() !== "number") {
        return;
      } //only augment number input!
      ctrl.$formatters.push(function (value) {
        return value ? parseFloat(value) : null;
      });
      ctrl.$parsers.push(function (value) {
        return value ? value.toString() : "";
      });
    },
  };
});
