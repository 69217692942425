import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { cardFormatNameFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatNameField";
import {
  cardFormatListItemTemplateId,
  cardFormatNumberFieldId,
} from "components/FullProgramming/common/CardFormatFields/CardFormatNumberField";
import { cardFormatRequireSiteCodeFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatRequireSiteCodeField";
import { cardFormatSiteCode1FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode1Field";
import { cardFormatSiteCode2FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode2Field";
import { cardFormatSiteCode3FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode3Field";
import { cardFormatSiteCode4FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode4Field";
import { cardFormatSiteCode5FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode5Field";
import { cardFormatSiteCode6FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode6Field";
import { cardFormatSiteCode7FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode7Field";
import { cardFormatSiteCode8FieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCode8Field";
import { cardFormatSiteCodeLengthFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCodeLengthField";
import { cardFormatSiteCodePositionFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatSiteCodePositionField";
import { cardFormatUserCodeDigitsFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatUserCodeDigitsField";
import { cardFormatUserCodeLengthFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatUserCodeLengthField";
import { cardFormatUserCodePositionFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatUserCodePositionField";
import { cardFormatWiegandCodeLengthFieldId } from "components/FullProgramming/common/CardFormatFields/CardFormatWiegandCodeLengthField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XRCardFormatsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRCardFormatsProgrammingConceptFormInline_controlSystem.graphql";
import * as React from "react";
import { readInlineData } from "react-relay";
import {
  getCardFormatState,
  getState as getCardFormatsState,
} from "../../../XRFullProgramming/XRCardFormatsProgrammingConceptForm";
import { XrProgrammingTemplateCardFormatInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  cardFormat: XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        cardFormats {
          id
          included
          number
          name {
            data
            included
          }
          wiegandLength {
            data
            included
          }
          siteCodePosition {
            data
            included
          }
          siteCodeLength {
            data
            included
          }
          userCodePosition {
            data
            included
          }
          userCodeLength {
            data
            included
          }
          userCodeDigits {
            data
            included
          }
          requireSiteCode {
            data
            included
          }
          siteCode1 {
            data
            included
          }
          siteCode2 {
            data
            included
          }
          siteCode3 {
            data
            included
          }
          siteCode4 {
            data
            included
          }
          siteCode5 {
            data
            included
          }
          siteCode6 {
            data
            included
          }
          siteCode7 {
            data
            included
          }
          siteCode8 {
            data
            included
          }
        }
      }
    `,
    cardFormat
  );

export const getInput = (
  controlSystem: XRCardFormatsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateCardFormatInput[] => {
  const {
    panel: { cardFormats },
  } = getCardFormatsState(controlSystem);

  return cardFormats
    ? cardFormats
        .filter(isNotNullOrUndefined)
        .map(getCardFormatState)
        .map((cardFormat) => ({
          included: includedFields.has(
            cardFormatListItemTemplateId(cardFormat.number)
          ),
          number: Number(cardFormat.number),
          name: {
            data: cardFormat.name,
            included: includedFields.has(
              cardFormatNameFieldId(cardFormat.number)
            ),
          },
          wiegandLength: {
            data: cardFormat.wiegandLength,
            included: includedFields.has(
              cardFormatWiegandCodeLengthFieldId(cardFormat.number)
            ),
          },
          siteCodePosition: {
            data: cardFormat.siteCodePosition,
            included: includedFields.has(
              cardFormatSiteCodePositionFieldId(cardFormat.number)
            ),
          },
          siteCodeLength: {
            data: cardFormat.siteCodeLength,
            included: includedFields.has(
              cardFormatSiteCodeLengthFieldId(cardFormat.number)
            ),
          },
          userCodePosition: {
            data: cardFormat.userCodePosition,
            included: includedFields.has(
              cardFormatUserCodePositionFieldId(cardFormat.number)
            ),
          },
          userCodeLength: {
            data: cardFormat.userCodeLength,
            included: includedFields.has(
              cardFormatUserCodeLengthFieldId(cardFormat.number)
            ),
          },
          userCodeDigits: {
            data: cardFormat.userCodeDigits,
            included: includedFields.has(
              cardFormatUserCodeDigitsFieldId(cardFormat.number)
            ),
          },
          requireSiteCode: {
            data: cardFormat.requireSiteCode,
            included: includedFields.has(
              cardFormatRequireSiteCodeFieldId(cardFormat.number)
            ),
          },
          siteCode1: {
            data: cardFormat.siteCodes[0],
            included: includedFields.has(
              cardFormatSiteCode1FieldId(cardFormat.number)
            ),
          },
          siteCode2: {
            data: cardFormat.siteCodes[1],
            included: includedFields.has(
              cardFormatSiteCode2FieldId(cardFormat.number)
            ),
          },
          siteCode3: {
            data: cardFormat.siteCodes[2],
            included: includedFields.has(
              cardFormatSiteCode3FieldId(cardFormat.number)
            ),
          },
          siteCode4: {
            data: cardFormat.siteCodes[3],
            included: includedFields.has(
              cardFormatSiteCode4FieldId(cardFormat.number)
            ),
          },
          siteCode5: {
            data: cardFormat.siteCodes[4],
            included: includedFields.has(
              cardFormatSiteCode5FieldId(cardFormat.number)
            ),
          },
          siteCode6: {
            data: cardFormat.siteCodes[5],
            included: includedFields.has(
              cardFormatSiteCode6FieldId(cardFormat.number)
            ),
          },
          siteCode7: {
            data: cardFormat.siteCodes[6],
            included: includedFields.has(
              cardFormatSiteCode7FieldId(cardFormat.number)
            ),
          },
          siteCode8: {
            data: cardFormat.siteCodes[7],
            included: includedFields.has(
              cardFormatSiteCode8FieldId(cardFormat.number)
            ),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: React.Dispatch<
    React.SetStateAction<IncludedTemplateFieldsType>
  >
) => {
  const { cardFormats } = getState(template);

  if (!cardFormats) {
    return;
  }

  cardFormats.filter(isNotNullOrUndefined).forEach((cardFormat) => {
    const number = String(cardFormat.number);
    if (cardFormat.included) {
      setIncludedFields(
        setToggle(cardFormatListItemTemplateId(String(cardFormat.number)))
      );
      setIncludedFields(setToggle(cardFormatNumberFieldId(number)));
    }
    if (cardFormat.name?.included) {
      setIncludedFields(setToggle(cardFormatNameFieldId(number)));
    }
    if (cardFormat.wiegandLength?.included) {
      setIncludedFields(setToggle(cardFormatWiegandCodeLengthFieldId(number)));
    }
    if (cardFormat.siteCodePosition?.included) {
      setIncludedFields(setToggle(cardFormatSiteCodePositionFieldId(number)));
    }
    if (cardFormat.siteCodeLength?.included) {
      setIncludedFields(setToggle(cardFormatSiteCodeLengthFieldId(number)));
    }
    if (cardFormat.userCodePosition?.included) {
      setIncludedFields(setToggle(cardFormatUserCodePositionFieldId(number)));
    }
    if (cardFormat.userCodeLength?.included) {
      setIncludedFields(setToggle(cardFormatUserCodeLengthFieldId(number)));
    }
    if (cardFormat.userCodeDigits?.included) {
      setIncludedFields(setToggle(cardFormatUserCodeDigitsFieldId(number)));
    }
    if (cardFormat.requireSiteCode?.included) {
      setIncludedFields(setToggle(cardFormatRequireSiteCodeFieldId(number)));
    }
    if (cardFormat.siteCode1?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode1FieldId(number)));
    }
    if (cardFormat.siteCode2?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode2FieldId(number)));
    }
    if (cardFormat.siteCode3?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode3FieldId(number)));
    }
    if (cardFormat.siteCode4?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode4FieldId(number)));
    }
    if (cardFormat.siteCode5?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode5FieldId(number)));
    }
    if (cardFormat.siteCode6?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode6FieldId(number)));
    }
    if (cardFormat.siteCode7?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode7FieldId(number)));
    }
    if (cardFormat.siteCode8?.included) {
      setIncludedFields(setToggle(cardFormatSiteCode8FieldId(number)));
    }
  });
};
