/**
 * @ngdoc api
 * @name App.factory:MoveSystemToCustomerAPI
 *
 * @description
 * API factory that moves a system from one customer to another
 *
 */
App.factory("MoveSystemToCustomerAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/ControlSystem/Move",
      {},
      {
        moveSystem: { method: "POST" },
      }
    );
  },
]);
