/**
 * @ngdoc service
 * @name App.factory:MonitoringContract
 *
 * @description
 * API factory for MonitoringContract
 *
 */
App.factory("MonitoringContract", [
  function () {
    var MonitoringContract = function (the_contract, dealer_id) {
      angular.extend(this, {
        id: null,

        available: false, // Default will be unchecked

        length_of_service: null,

        base_price: 0.0,

        additional_automation: 0.0,

        additional_cellular: 0.0,

        additional_cameras: 0.0,

        extendMe: function (monitoringContract) {
          angular.extend(this, monitoringContract);
        },
      });
      if (the_contract != null && angular.isDefined(the_contract)) {
        this.extendMe(the_contract, dealer_id);
      }
    };
    return MonitoringContract;
  },
]);
