import { BaseInput, BaseInputProps } from "components/Inputs/BaseInput";
import * as React from "react";

const PhoneNumberInput = React.forwardRef(
  (
    props: Omit<BaseInputProps, "type">,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { className, ...otherProps } = props;
    return (
      <BaseInput
        {...otherProps}
        type="text"
        className={`form-control ${className}`}
        ref={ref}
      />
    );
  }
);

export default PhoneNumberInput;
