import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
  useProgrammingConceptHasFieldChanges,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import { DEVICE_IDS } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNumberField";
import { isAXBusRange } from "components/FullProgramming/common/DeviceInformationFields/utils";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { omit, prop } from "ramda";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  Device734,
  DeviceInformation,
  DeviceInformationCommType,
  DeviceInformationType as DeviceInformationTypeEnum,
  FeatureKey,
  FeatureKeyEnum,
  fromControlSystemId,
  fromDeviceInformationsId,
  idAsString,
  Panel,
  PanelHardwareModel,
  toDevice734Id,
  toDeviceInformationsId,
  toGlobalId,
  XrDeviceInformation,
  XrDeviceInformationsType,
} from "securecom-graphql/client";
import { formatToAxDeviceNumber, formatToLxDeviceNumber } from "./utils";
import { xrDeviceListItemTemplateId } from "./XRDeviceInformationNumberField";
import XRDeviceInformationsFields from "./XRDeviceInformationsFields";
import { DeviceInformationType } from "./__generated__/XRDeviceInformationNumberField_deviceInformation.graphql";
import { XRDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation } from "./__generated__/XRDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XRDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation,
} from "./__generated__/XRDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation.graphql";
import {
  XRDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation,
  XRDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
} from "./__generated__/XRDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation.graphql";
import {
  XRDeviceSetupProgrammingConceptFormInline_controlSystem$data,
  XRDeviceSetupProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XRDeviceSetupProgrammingConceptFormInline_controlSystem.graphql";
import {
  XRDeviceSetupProgrammingConceptFormInline_deviceInformation$data,
  XRDeviceSetupProgrammingConceptFormInline_deviceInformation$key,
} from "./__generated__/XRDeviceSetupProgrammingConceptFormInline_deviceInformation.graphql";
import { XRDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XRDeviceSetupProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XRDeviceSetupProgrammingConceptFormNavButton_controlSystem.graphql";
import {
  XRDeviceSetupProgrammingConceptForm_controlSystem$data,
  XRDeviceSetupProgrammingConceptForm_controlSystem$key,
} from "./__generated__/XRDeviceSetupProgrammingConceptForm_controlSystem.graphql";

export const title = "Device Setup";
export const conceptId = "xr-device-setup";

export const getState = (
  controlSystem: XRDeviceSetupProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRDeviceSetupProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          totalDevicesMax
          keypadBusDevicesMax
          axBusDoorDevicesMax
          deviceNumberRange
          deviceInformations {
            __typename
            id
            number
            isNew
            ...XRDeviceSetupProgrammingConceptFormInline_deviceInformation
          }
        }
      }
    `,
    controlSystem
  );

export const getDeviceState = (
  device: XRDeviceSetupProgrammingConceptFormInline_deviceInformation$key
) =>
  readInlineData(
    graphql`
      fragment XRDeviceSetupProgrammingConceptFormInline_deviceInformation on DeviceInformation
      @inline {
        __typename
        id
        isNew
        ... on XrDeviceInformation {
          id
          number
          axNumber
          lxNumber
          name
          serialNumber
          supervisionTime
          wirelessTranslator1100tFrequency
          deviceType
          deviceCommunicationMethod
          accessAreas
          egressAreas
          displayAreas
          strikeTime
          strikeDelay
          fireExit
          publicDoor
          pinDisarm
          triggerOutputGroupWithAccess
          overrideScheduleWhenArmed
          autoForceArm
          doorRealTimeStatusMessages
          doorForceMessages
          remoteProgram734
          device734 {
            number
            zone2BypassOnRequestToExit
            zone2BypassTime
            zone2RelockOnStateChange
            enableZone3RequestToExit
            zone3RequestToExitTime
            enableOnboardSpeaker
            cardFormatOptions
            wiegandBitLength
            siteCodeStartBitPosition
            siteCodeBitLength
            userCodeStartBitPosition
            userCodeBitLength
            enforceSiteCode
            siteCode1
            siteCode2
            siteCode3
            siteCode4
            siteCode5
            siteCode6
            siteCode7
            siteCode8
            numberOfUserCodeDigits
            noCommWithPanelRelayAction
          }
          privateDoor
          wirelessTranslator1100t
        }
      }
    `,
    device
  );

const deleteMutation = graphql`
  mutation XRDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation(
    $deviceId: ID!
  ) {
    deleteDeviceInformation(deviceId: $deviceId) {
      ... on DeleteDeviceInformationSuccessPayload {
        __typename
        deletedDeviceId
      }
      ... on FailedToRemoveDeviceErrorPayload {
        error: type
      }
    }
  }
`;

const refreshMutation = graphql`
  mutation XRDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation(
    $id: ID!
  ) {
    refreshDeviceInformations(id: $id) {
      ... on RefreshDeviceInformationsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XRDeviceSetupProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XRDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation(
    $systemId: ID!
    $deviceInformations: [XrDeviceInput!]!
  ) {
    sendXrDeviceProgramming(
      systemId: $systemId
      deviceInformations: $deviceInformations
    ) {
      ... on Error {
        type
      }
      ... on SendDeviceProgrammingSuccessPayload {
        results {
          __typename
          ... on SendDeviceProgrammingDeviceSuccessPayload {
            deviceInformation {
              __typename
              id
              number
              ...XRDeviceSetupProgrammingConceptFormInline_deviceInformation
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;

const readDeviceInformationsTemplateData = (
  programmingTemplateConcepts: XRDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        deviceInformations {
          included
          number
          axNumber {
            included
            data
          }
          lxNumber {
            included
            data
          }
          name {
            included
            data
          }
          supervisionTime {
            included
            data
          }
          wirelessTranslator1100tFrequency {
            included
            data
          }
          deviceType {
            included
            data
          }
          deviceCommunicationMethod {
            included
            data
          }
          accessAreas {
            included
            data
          }
          egressAreas {
            included
            data
          }
          displayAreas {
            included
            data
          }
          strikeTime {
            included
            data
          }
          strikeDelay {
            included
            data
          }
          fireExit {
            included
            data
          }
          publicDoor {
            included
            data
          }
          triggerOutputGroupWithAccess {
            included
            data
          }
          overrideScheduleWhenArmed {
            included
            data
          }
          autoForceArm {
            included
            data
          }
          doorRealTimeStatusMessages {
            included
            data
          }
          doorForceMessages {
            included
            data
          }
          remoteProgram734 {
            included
            data
          }
          device734 {
            included
            data {
              zone2BypassOnRequestToExit {
                included
                data
              }
              zone2BypassTime {
                included
                data
              }
              zone2RelockOnStateChange {
                included
                data
              }
              enableZone3RequestToExit {
                included
                data
              }
              zone3RequestToExitTime {
                included
                data
              }
              enableOnboardSpeaker {
                included
                data
              }
              cardFormatOptions {
                included
                data
              }
              wiegandBitLength {
                included
                data
              }
              siteCodeStartBitPosition {
                included
                data
              }
              siteCodeBitLength {
                included
                data
              }
              userCodeStartBitPosition {
                included
                data
              }
              userCodeBitLength {
                included
                data
              }
              enforceSiteCode {
                included
                data
              }
              siteCode1 {
                included
                data
              }
              siteCode2 {
                included
                data
              }
              siteCode3 {
                included
                data
              }
              siteCode4 {
                included
                data
              }
              siteCode5 {
                included
                data
              }
              siteCode6 {
                included
                data
              }
              siteCode7 {
                included
                data
              }
              siteCode8 {
                included
                data
              }
              numberOfUserCodeDigits {
                included
                data
              }
              noCommWithPanelRelayAction {
                included
                data
              }
            }
          }
          privateDoor {
            included
            data
          }
          wirelessTranslator1100t {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).deviceInformations;

export function applyTemplateData(
  programmingTemplateConcepts: XRDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const hardwareModel = panelRecordProxy.getValue("hardwareModel");
  const deviceRecordProxies =
    panelRecordProxy.getLinkedRecords("deviceInformations") ?? [];
  const devicesByNumber = indexRecordProxiesByNumber(deviceRecordProxies);

  const devicesTemplateData =
    readDeviceInformationsTemplateData(programmingTemplateConcepts) ?? [];
  devicesTemplateData.forEach((deviceTemplateData) => {
    if (deviceTemplateData?.included) {
      let deviceRecordProxy = devicesByNumber.get(
        formatToLxDeviceNumber(deviceTemplateData.number) ?? 0
      );

      if (!deviceRecordProxy) {
        const newDeviceId = applyNewDeviceToDeviceInformationsList(
          {
            id: panelRecordProxy.getValue("id"),
            hardwareModel,
            deviceNumberRange: panelRecordProxy.getValue("deviceNumberRange"),
            newDevice: panelRecordProxy.getLinkedRecord("newDevice") && {
              id: panelRecordProxy.getLinkedRecord("newDevice").getValue("id"),
            },
            deviceInformations: deviceRecordProxies.map((recordProxy) => ({
              number:
                formatToLxDeviceNumber(recordProxy.getValue("number")) ?? 0,
            })),
          },
          store
        );
        if (newDeviceId) {
          deviceRecordProxy = store.get(
            newDeviceId
          ) as RecordProxy<DeviceInformation>;
          if (deviceRecordProxy) {
            deviceRecordProxy.setValue(
              formatToLxDeviceNumber(deviceTemplateData.number),
              "number"
            );
            if (
              hardwareModel === PanelHardwareModel.XR150 &&
              formatToLxDeviceNumber(deviceTemplateData.number) === 501
            ) {
              deviceRecordProxy.setValue(
                DeviceInformationTypeEnum.VPLEX,
                "deviceType"
              );
            }
          }
        }
      }

      if (!deviceRecordProxy) {
        return;
      }

      //Need to add these to the deviceRecordProxy as they are calculated fields

      deviceRecordProxy.setValue(
        formatToLxDeviceNumber(deviceTemplateData.number),
        "number"
      );

      deviceRecordProxy.setValue(
        formatToLxDeviceNumber(deviceTemplateData.number)?.toString(),
        "lxNumber"
      );
      deviceRecordProxy.setValue(
        formatToAxDeviceNumber(deviceTemplateData.number)?.toString(),
        "axNumber"
      );

      const serialNumber = deviceRecordProxy.getValue("serialNumber");

      if (
        deviceTemplateData.deviceType?.included &&
        deviceTemplateData.deviceType?.data === DeviceInformationTypeEnum._1100T
      ) {
        deviceRecordProxy.setValue(
          DeviceInformationCommType.KEYPAD_BUS,
          "deviceCommunicationMethod"
        );
        if (!(serialNumber?.toString() ?? "").match("13[0-9]{6}")) {
          deviceRecordProxy.setValue("13", "serialNumber");
        }
        deviceRecordProxy.setValue(
          DeviceInformationCommType.WIRELESS,
          "deviceCommunicationMethod"
        );
      }

      applyTemplateScalarDataToRecordProxy(
        deviceRecordProxy,
        omit(["device734"], deviceTemplateData)
      );

      if (
        deviceTemplateData.device734?.included &&
        deviceTemplateData.device734?.data
      ) {
        const device734RecordProxy = deviceRecordProxy.getOrCreateLinkedRecord(
          "device734",
          "Device734"
        );
        applyTemplateScalarDataToRecordProxy(
          device734RecordProxy,
          deviceTemplateData.device734.data
        );
      }

      if (
        !devicesByNumber.has(
          formatToLxDeviceNumber(deviceTemplateData.number) ?? 0
        )
      ) {
        devicesByNumber.set(
          formatToLxDeviceNumber(deviceTemplateData.number) ?? 0,
          deviceRecordProxy
        );
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(devicesByNumber),
    "deviceInformations"
  );
}

const mergeOldAndNewDevices = (
  response: XRDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
  originalControlSystemData: XRDeviceSetupProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendXrDeviceProgramming.results) {
    const successfulDevices = response.sendXrDeviceProgramming.results
      .map((device) => {
        if (device.__typename === "SendDeviceProgrammingDeviceSuccessPayload") {
          return device;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.deviceInformation)
      .map(getDeviceState);

    const mergedDevicesMap = new Map<
      string,
      XRDeviceSetupProgrammingConceptFormInline_deviceInformation$data
    >();

    originalControlSystemData.panel.deviceInformations
      .map(getDeviceState)
      .forEach((item) => mergedDevicesMap.set(item.id, item));

    successfulDevices.forEach((item) =>
      mergedDevicesMap.set(item.id, {
        ...mergedDevicesMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedDevicesMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XRDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
  originalControlSystemData: XRDeviceSetupProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedDevices = mergeOldAndNewDevices(
    response,
    originalControlSystemData
  );

  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshDeviceInformations: {
        __typename: "RefreshDeviceInformationsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            deviceInformations: mergedDevices,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XRDeviceSetupProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendDeviceInformations, isSendingDeviceInformations] =
    useMutation<XRDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const hasFieldChanges = useProgrammingConceptHasFieldChanges(conceptId);
  const changedDevices = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { deviceInformations },
        } = getState(props.controlSystem);
        sendDeviceInformations({
          variables: {
            systemId: systemId,
            deviceInformations: deviceInformations
              .map(getDeviceState)
              .filter(
                (device) =>
                  device.isNew ||
                  hasFieldChanges ||
                  (!!changedDevices &&
                    listItemHasChanged(device.id, changedDevices)) ||
                  isSavingAllListItems
              )
              .map((deviceInformation) => ({
                id: deviceInformation.id,
                number: deviceInformation.number!,
                name:
                  deviceInformation.name ??
                  `Device ${deviceInformation.number}`,
                axNumber: deviceInformation.axNumber,
                lxNumber: deviceInformation.lxNumber,
                serialNumber:
                  (deviceInformation.deviceCommunicationMethod ===
                    DeviceInformationCommType.NETWORK &&
                    deviceInformation.deviceType ===
                      DeviceInformationTypeEnum.XR550 &&
                    deviceInformation.serialNumber) ||
                  (deviceInformation.deviceCommunicationMethod ===
                    DeviceInformationCommType.WIRELESS &&
                    deviceInformation.serialNumber)
                    ? deviceInformation.serialNumber
                    : "",
                supervisionTime: deviceInformation.supervisionTime,
                wirelessTranslator1100tFrequency:
                  deviceInformation.wirelessTranslator1100tFrequency,
                deviceType:
                  deviceInformation.deviceType ??
                  DeviceInformationTypeEnum.KEYPAD,
                deviceCommunicationMethod:
                  deviceInformation.deviceCommunicationMethod,
                accessAreas: deviceInformation.accessAreas,
                egressAreas: deviceInformation.egressAreas,
                displayAreas: deviceInformation.displayAreas,
                pinDisarm: deviceInformation.pinDisarm,
                strikeTime: deviceInformation.strikeTime ?? 5,
                strikeDelay: deviceInformation.strikeDelay ?? 0,
                fireExit: deviceInformation.fireExit ?? false,
                publicDoor: deviceInformation.publicDoor ?? false,
                triggerOutputGroupWithAccess:
                  deviceInformation.triggerOutputGroupWithAccess ?? false,
                overrideScheduleWhenArmed:
                  deviceInformation.overrideScheduleWhenArmed ?? false,
                autoForceArm: deviceInformation.autoForceArm ?? false,
                doorRealTimeStatusMessages:
                  deviceInformation.doorRealTimeStatusMessages ?? false,
                doorForceMessages: deviceInformation.doorForceMessages ?? false,
                remoteProgram734: deviceInformation.remoteProgram734 ?? false,
                device734: deviceInformation.device734,
                privateDoor: deviceInformation.privateDoor ?? false,
                wirelessTranslator1100t:
                  deviceInformation.wirelessTranslator1100t ?? false,
                isNew: !!deviceInformation.isNew,
              })),
          },
          onCompleted: (response) => {
            const saveErrors: SaveErrors = [];
            if (response.sendXrDeviceProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendXrDeviceProgramming.results) {
              response.sendXrDeviceProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendDeviceProgrammingDeviceSuccessPayload"
                ) {
                  resetLastUpdated(response.deviceInformation.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  saveErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!saveErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
            }
            resolve(saveErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSendingDeviceInformations,
  ];
};

export const useRetrieveMutation = (props: {
  controlSystem: XRDeviceSetupProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshDeviceInformation, isRefreshing] =
    useMutation<XRDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshDeviceInformation({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshDeviceInformations;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Device Setup Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshDeviceInformations: {
                    __typename: response.refreshDeviceInformations.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Device Setup: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Device Setup",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XRDeviceSetupProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XRDeviceSetupProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            deviceInformations {
              ... on XrDeviceInformation {
                isNew
              }
            }
          }
        }
      `
    );
  const { deviceInformations } = controlSystem.panel;
  const itemsCount = deviceInformations.length;
  const hasNewItems =
    itemsCount > 0 && deviceInformations.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRDeviceSetupProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRDeviceSetupProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedDeviceInformation {
            id
          }
          panel {
            id
            totalDevicesMax
            keypadBusDevicesMax
            axBusDoorDevicesMax
            deviceNumberRange
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            hardwareModel
            softwareVersion
            ...XRDeviceInformationNumberField_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseRemoteZoneList_panel
            deviceInformations {
              id
              number
              name
              isNew
              ...XRDeviceInformationsFields_deviceInformation
              ... on XrDeviceInformation {
                lxNumber
                deviceType
              }
            }
            newDevice {
              id
              number
              name
              isNew
              ...XRDeviceInformationsFields_deviceInformation
              ... on XrDeviceInformation {
                lxNumber
                deviceType
              }
            }
            featureKeys {
              key
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseDeviceNumberRange_panel
            ...PanelContextGetAllDeviceIds_panel
            ...PanelContextUseSupports1100T_panel
            ...PanelContextUseSupportsXVCamera_panel
            ...PanelContextUseSupportsXR550_panel
            ...PanelContextUseHas1100T_panel
            ...PanelContextUseSupportsNetworkExpander_panel
            ...PanelContextUseSupportsVplex_panel
            ...PanelContextUseSupportsAxBus_panel
            ...PanelContextUseSupportsPrivateDoor_panel
            ...PanelContextUseSupportsCustomCardFormats_panel
            ...DeviceInformationSerialNumberField_deviceSerialNumberList_panel
          }
        }
      `
    );

  const {
    panel: {
      id: systemId,
      deviceInformations,
      featureKeys,
      newDevice,
      hardwareModel,
      softwareVersion,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    deviceInformations[0]?.id ?? null
  );

  const uncheckListItem = useUncheckListItem()(DEVICE_IDS);

  const [deleteDeviceInformation, isDeleting] =
    useMutation<XRDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const relayEnv = useRelayEnvironment();
  const { isEditing: templateIsEditing } = useTemplateContext();
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const remainingDevicesAvailable =
    hardwareModel === PanelHardwareModel.XR550 && Number(softwareVersion) < 213
      ? getRemainingDeviceSpacesAvailableForXr550VerLT213(
          featureKeys as FeatureKey[],
          availableNumbers.size
        )
      : availableNumbers.size;
  const canAdd = remainingDevicesAvailable > 0;
  const remainingAxDoorDevicesAvailable =
    hardwareModel === PanelHardwareModel.XR550
      ? getRemainingAxDoorDeviceSpacesAvailableForXr550(
          controlSystem.panel,
          featureKeys as FeatureKey[]
        )
      : undefined;
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
    isValidatingProgramming,
  } = useProgrammingActionsContext();

  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;

  const templatesContext = useTemplateContext();

  const removeSelectedDevice = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = deviceInformations.findIndex(
          (deviceInformation) => deviceInformation.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === deviceInformations.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return deviceInformations[newSelectedIndex]?.id ?? null;
      });
      const device = deviceInformations.find(
        (device) => device.id === selectedListItemId
      );
      uncheckListItem(String(device?.number));
      if (device?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "deviceInformations",
            systemId,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            XrDeviceInformationsType,
            fromControlSystemId(asID(controlSystem.id)).systemId,
            device?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteDeviceInformation({
          variables: {
            deviceId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "deviceInformations",
              systemId,
              store
            );
          },
          updater: (store, response) => {
            const { deletedDeviceId } = response.deleteDeviceInformation;
            if (deletedDeviceId) {
              showAlert({
                type: "success",
                text: "Device Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "deviceInformations",
                systemId,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedDeviceId, error } = response.deleteDeviceInformation;
            if (deletedDeviceId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedDeviceId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "deviceInformations",
                      systemId,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Device: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Device",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Device",
            });
          },
        });
      }
    }
  };

  const axDoorDevicesAmountAvailable =
    hardwareModel === PanelHardwareModel.XR550 && Number(softwareVersion) < 213
      ? undefined
      : remainingAxDoorDevicesAvailable;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Device%20Setup`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(deviceInformations)}
        amountAvailable={remainingDevicesAvailable}
        axDoorDevicesAmountAvailable={axDoorDevicesAmountAvailable}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newDeviceId = applyNewDeviceToDeviceInformationsList(
                  controlSystem.panel,
                  store,
                  axDoorDevicesAmountAvailable
                );
                if (newDeviceId) {
                  setSelectedListItemId(newDeviceId);
                }
              });
            }}
          >
            Add Device
          </ProgrammingConceptForm.AddButton>
        }
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          {(conceptId === activeConcept ||
            templatesContext.isApplying ||
            isSavingAll) && (
            <ProgrammingConceptForm.ListItemsContainer>
              <ProgrammingConceptForm.ListItemPicker
                selectedId={selectedListItemId}
                onChange={(id) => {
                  setSelectedListItemId(id);
                }}
                newItemId={newDevice?.id}
                items={deviceInformations.map((device) => ({
                  id: device.id,
                  templateListItemId: xrDeviceListItemTemplateId(
                    String(device.number)
                  ),
                  isnew: device.isNew,
                  label: `#${
                    device.lxNumber ? device.lxNumber : device.number
                  } ${device.name}`,
                }))}
              />
              <ProgrammingConceptForm.SelectedItemsContainer
                selectedListItemId={selectedListItemId}
                setSelectedListItemId={setSelectedListItemId}
              >
                {deviceInformations.map((deviceInformation) => {
                  const deviceIs1100t =
                    deviceInformation.deviceType ===
                    DeviceInformationTypeEnum._1100T;
                  const deviceIsVplexAndOutOfDevices =
                    deviceInformation.deviceType ===
                      DeviceInformationTypeEnum.VPLEX &&
                    !getFirstAvailableVplexNumber(controlSystem);
                  const deviceIsXR150Device501 =
                    deviceInformation.number === 501 &&
                    hardwareModel === PanelHardwareModel.XR150;
                  const isCamera = deviceInformation.deviceType === "CAMERA";

                  return (
                    (deviceInformation.id === selectedListItemId ||
                      templatesContext.isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                      ((isSavingAll || // If we are saving all or the concept, we want to render all the devices so we can check for errors
                        programmingConcepts[conceptId].isSaving) &&
                        isValidatingProgramming)) && (
                      <RemountOnUpdateContainer nodeId={deviceInformation.id}>
                        <ProgrammingConceptForm.SelectedItem
                          conceptId={conceptId}
                          isnew={!!deviceInformation.isNew}
                          visible={deviceInformation.id === selectedListItemId}
                          key={deviceInformation.id}
                          listItemId={deviceInformation.id}
                          templateListItemId={xrDeviceListItemTemplateId(
                            String(deviceInformation.number)
                          )}
                          isCamera={isCamera}
                          title={`# ${
                            deviceInformation.lxNumber
                              ? deviceInformation.lxNumber
                              : deviceInformation.number
                          } ${deviceInformation.name}`}
                          onDuplicate={
                            !isCamera &&
                            !deviceIs1100t &&
                            !deviceIsVplexAndOutOfDevices &&
                            canAdd &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const duplicateId =
                                  applyDuplicatedDeviceInformationToDeviceInformationsList(
                                    selectedListItemId,
                                    controlSystem,
                                    store
                                  );
                                if (duplicateId) {
                                  setSelectedListItemId(duplicateId);
                                }
                              });
                            })
                          }
                          onCopy={
                            !isCamera &&
                            !deviceIs1100t &&
                            !deviceIsXR150Device501 &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const controlSystemRecord = store.get(
                                  controlSystem.id
                                );
                                const deviceInformationRecord =
                                  store.get<XrDeviceInformation>(
                                    selectedListItemId
                                  );
                                if (
                                  controlSystemRecord &&
                                  deviceInformationRecord
                                ) {
                                  const tempRecord =
                                    store.get("copiedDeviceInformation") ??
                                    store.create(
                                      "copiedDeviceInformation",
                                      "XrDeviceInformation"
                                    );
                                  tempRecord.copyFieldsFrom(
                                    deviceInformationRecord
                                  );
                                  controlSystemRecord.setLinkedRecord(
                                    tempRecord,
                                    "copiedDeviceInformation"
                                  );
                                }
                              });
                            })
                          }
                          onPaste={
                            !!controlSystem.copiedDeviceInformation &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const deviceInformationRecord =
                                  store.get<XrDeviceInformation>(
                                    selectedListItemId
                                  );
                                const copiedDeviceInformationRecord =
                                  store.get<XrDeviceInformation>(
                                    "copiedDeviceInformation"
                                  );
                                if (
                                  deviceInformationRecord &&
                                  copiedDeviceInformationRecord
                                ) {
                                  remove1100tFromDevice(
                                    copiedDeviceInformationRecord
                                  );
                                  applyDeviceInformationProgrammingToDeviceInformation(
                                    copiedDeviceInformationRecord,
                                    deviceInformationRecord,
                                    controlSystem
                                  );
                                }
                              });
                            })
                          }
                          onRemove={removeSelectedDevice}
                        >
                          <XRDeviceInformationsFields
                            key={deviceInformation.id}
                            deviceInformation={deviceInformation}
                          />
                        </ProgrammingConceptForm.SelectedItem>
                      </RemountOnUpdateContainer>
                    )
                  );
                })}
              </ProgrammingConceptForm.SelectedItemsContainer>
            </ProgrammingConceptForm.ListItemsContainer>
          )}
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewDeviceToDeviceInformationsList = (
  panel: Parameters<typeof getAvailableNumbers>[0] & {
    readonly id: string;
    readonly hardwareModel: string;
    readonly newDevice: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy,
  axDoorDevicesAvailable?: number
) => {
  const { id, newDevice, hardwareModel } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newDeviceInformationTemplate =
      panelRecord.getLinkedRecord<DeviceInformation>("newDevice");
    const newDevice734Template =
      newDeviceInformationTemplate.getLinkedRecord<Device734>("device734");
    if (newDevice) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromDeviceInformationsId(
          asID(newDeviceInformationTemplate.getDataID())
        );
        const nextNewDeviceId = idAsString(
          toDeviceInformationsId("Xr", systemId, nextNumber)
        );
        const nextNewDevice734Id =
          idAsString(toDevice734Id(systemId, nextNumber)) + crypto.randomUUID();
        const nextNewDeviceInformation = store.create(
          nextNewDeviceId,
          "XrDeviceInformation"
        ) as RecordProxy<DeviceInformation>;
        const nextNewDevice734 = store.create(
          nextNewDevice734Id,
          "Device734"
        ) as RecordProxy<Device734>;

        nextNewDeviceInformation.copyFieldsFrom(newDeviceInformationTemplate!);
        nextNewDeviceInformation.setValue(nextNewDeviceId, "id");
        nextNewDeviceInformation.setValue(nextNumber, "number");
        nextNewDeviceInformation.setValue(true, "isNew");
        nextNewDevice734.copyFieldsFrom(newDevice734Template);
        nextNewDeviceInformation.setLinkedRecord(nextNewDevice734, "device734");
        if (nextNumber > 16) {
          nextNewDeviceInformation.setValue(
            isNotNullOrUndefined(axDoorDevicesAvailable) &&
              axDoorDevicesAvailable <= 0 // if this value exists and is at or below 0, we know that there are no more ax doors available and should set the device type to zone expander
              ? DeviceInformationTypeEnum.ZONE_EXPANDER
              : DeviceInformationTypeEnum.DOOR,
            "deviceType"
          );
          nextNewDeviceInformation.setValue(
            isNotNullOrUndefined(axDoorDevicesAvailable) &&
              axDoorDevicesAvailable <= 0 // if this value exists and is at or below 0, we know that there are no more ax doors available and should set the communication type to network
              ? DeviceInformationCommType.NETWORK
              : DeviceInformationCommType.KEYPAD_BUS,
            "deviceCommunicationMethod"
          );
        }
        nextNewDeviceInformation.setValue(
          formatToLxDeviceNumber(nextNumber)?.toString(),
          "lxNumber"
        );
        nextNewDeviceInformation.setValue(
          formatToAxDeviceNumber(nextNumber)?.toString(),
          "axNumber"
        );
        if (hardwareModel === PanelHardwareModel.XR150 && nextNumber === 501) {
          nextNewDeviceInformation.setValue(
            DeviceInformationTypeEnum.VPLEX,
            "deviceType"
          );
        }
        const deviceInformationRecords =
          panelRecord.getLinkedRecords("deviceInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...deviceInformationRecords, nextNewDeviceInformation],
          "deviceInformations"
        );
        return nextNewDeviceInformation.getValue("id");
      }
    }
  }
};

const applyDuplicatedDeviceInformationToDeviceInformationsList = (
  deviceInformationId: string,
  controlSystem: XRDeviceSetupProgrammingConceptForm_controlSystem$data,
  store: RecordSourceProxy
) => {
  const { id, newDevice } = controlSystem.panel;
  const panelRecord = store.get<Panel>(id);
  const deviceInformationRecord =
    store.get<DeviceInformation>(deviceInformationId);
  if (panelRecord && deviceInformationRecord) {
    const newDeviceRecord = panelRecord.getLinkedRecord("newDevice");
    if (newDevice) {
      const isVplex =
        deviceInformationRecord.getValue("deviceType") ===
        DeviceInformationTypeEnum.VPLEX;
      const nextNumber = isVplex
        ? getFirstAvailableVplexNumber(controlSystem)
        : getNextAvailableNumber(controlSystem.panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromDeviceInformationsId(
          asID(newDeviceRecord.getDataID())
        );
        const nextNewDeviceId = idAsString(
          toDeviceInformationsId("Xr", systemId, nextNumber)
        );
        const nextNewDevice = store.create(
          nextNewDeviceId,
          "XrDeviceInformation"
        ) as RecordProxy<DeviceInformation>;
        nextNewDevice.copyFieldsFrom(newDeviceRecord);
        nextNewDevice.setValue(nextNewDeviceId, "id");
        nextNewDevice.setValue(nextNumber, "number");
        nextNewDevice.setValue(true, "isNew");
        if (nextNumber > 16) {
          nextNewDevice.setValue(
            formatToLxDeviceNumber(nextNumber)?.toString(),
            "lxNumber"
          );
          nextNewDevice.setValue(
            formatToAxDeviceNumber(nextNumber)?.toString(),
            "axNumber"
          );
        }

        const duplicatedDeviceInformationRecord =
          applyDeviceInformationProgrammingToDeviceInformation(
            deviceInformationRecord,
            nextNewDevice,
            controlSystem
          );

        remove1100tFromDevice(duplicatedDeviceInformationRecord);

        const deviceInformationRecords =
          panelRecord.getLinkedRecords("deviceInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...deviceInformationRecords, duplicatedDeviceInformationRecord],
          "deviceInformations"
        );

        return duplicatedDeviceInformationRecord.getValue("id");
      }
    }
  }
};

const applyDeviceInformationProgrammingToDeviceInformation = (
  source: RecordProxy<DeviceInformation>,
  dest: RecordProxy<DeviceInformation>,
  controlSystem: XRDeviceSetupProgrammingConceptForm_controlSystem$data
) => {
  const hardwareModel = controlSystem.panel.hardwareModel;
  const destId = dest.getValue("id");
  const destNumber = dest.getValue("number");
  const destIsNew = dest.getValue("isNew");
  const destLxNumber = dest.getValue("lxNumber");
  const destAxNumber = dest.getValue("axNumber");

  const sourceNumber = source.getValue("number");

  dest.copyFieldsFrom(source);
  dest.setValue(destId, "id");
  dest.setValue(destIsNew, "isNew");
  dest.setValue(destNumber, "number");
  dest.setValue(destLxNumber, "lxNumber");
  dest.setValue(destAxNumber, "axNumber");
  if (isAXBusRange(destNumber)) {
    dest.setValue(false, "autoForceArm"); // If the device is an AX device, we have to default the autoForceArm to false
    dest.setValue("", "displayAreas"); // If the device is an AX device, we have to default the displayAreas to empty string
  }
  if (
    hardwareModel === PanelHardwareModel.XR150 &&
    (destNumber === 501 || destNumber === 17)
  ) {
    dest.setValue(DeviceInformationTypeEnum.VPLEX, "deviceType");
  }
  if (hardwareModel === PanelHardwareModel.XR550) {
    const isWireless =
      source.getValue("deviceCommunicationMethod") ===
        DeviceInformationCommType.WIRELESS ?? false;
    switch (source.getValue("deviceType")) {
      case DeviceInformationTypeEnum.DOOR: {
        if (sourceNumber < 17 && destNumber > 16 && isWireless) {
          dest.setValue(
            DeviceInformationCommType.KEYPAD_BUS,
            "deviceCommunicationMethod"
          );
        }
        break;
      }
      case DeviceInformationTypeEnum.FIRE:
      case DeviceInformationTypeEnum.KEYPAD: {
        if (sourceNumber < 17 && destNumber > 16) {
          dest.setValue(DeviceInformationTypeEnum.DOOR, "deviceType");
          if (isWireless) {
            dest.setValue(
              DeviceInformationCommType.KEYPAD_BUS,
              "deviceCommunicationMethod"
            );
          }
        }
        break;
      }
      case DeviceInformationTypeEnum.ZONE_EXPANDER: {
        if (sourceNumber < 17 && destNumber > 16) {
          dest.setValue(
            DeviceInformationCommType.NETWORK,
            "deviceCommunicationMethod"
          );
        }
        break;
      }
      case DeviceInformationTypeEnum.VPLEX: {
        dest.setValue(DeviceInformationTypeEnum.VPLEX, "deviceType");
        dest.setValue(
          DeviceInformationCommType.NONE,
          "deviceCommunicationMethod"
        );
        break;
      }
      default:
        break;
    }
  }

  return dest;
};

const getAvailableNumbers = (panel: {
  readonly deviceNumberRange: ReadonlyArray<number>;
  readonly deviceInformations: ReadonlyArray<{ readonly number: number }>;
  readonly hardwareModel: string;
}) => {
  const allPossibleNumbers = new Set(
    panel.hardwareModel === PanelHardwareModel.XR150 // XR150s have camera devices that are not included in the new device number range we need to filter them out
      ? panel.deviceNumberRange.filter((deviceNumber) => deviceNumber < 502)
      : panel.deviceNumberRange
  );

  const takenNumbers = new Set(
    panel.deviceInformations.map(({ number }) => formatToLxDeviceNumber(number))
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));

const getFirstAvailableVplexNumber = (
  controlSystem: XRDeviceSetupProgrammingConceptForm_controlSystem$data
) => {
  const hardwareModel = controlSystem.panel.hardwareModel;

  const allPossibleNumbers = new Set(
    hardwareModel === PanelHardwareModel.XR150
      ? [501]
      : hardwareModel === PanelHardwareModel.XR550
      ? [501, 601, 701, 801, 901]
      : []
  );

  const takenNumbers = new Set(
    controlSystem.panel.deviceInformations.map(({ number }) =>
      formatToLxDeviceNumber(number)
    )
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return setFirst(availableNumbers);
};

const getRemainingDeviceSpacesAvailableForXr550VerLT213 = (
  featureKeys: FeatureKey[],
  totalDevices: number
) => {
  // Applies to versions less than 213
  // XR550s have the space for 96 devices, but we only allow the user to have 32 programmed unless they have the `DOOR_ADD_ON` feature keys.
  // We need to remove 32 devices for each feature key the panel doesn't have.
  if (!featureKeys.map(prop("key")).includes(FeatureKeyEnum.DOOR_ADD_ON_A)) {
    totalDevices -= 32;
  }
  if (!featureKeys.map(prop("key")).includes(FeatureKeyEnum.DOOR_ADD_ON_B)) {
    totalDevices -= 32;
  }
  return totalDevices;
};

const getRemainingAxDoorDeviceSpacesAvailableForXr550 = (
  panel: {
    readonly deviceNumberRange: ReadonlyArray<number>;
    readonly deviceInformations: ReadonlyArray<{
      readonly number: number;
      readonly deviceType?: DeviceInformationType;
    }>;
  },
  featureKeys: FeatureKey[]
) => {
  const allPossibleNumbers = new Set(
    panel.deviceNumberRange.filter((number) => number > 16)
  );

  const takenDoorNumbers = new Set(
    panel.deviceInformations
      .filter(({ deviceType, number }) => deviceType === "DOOR" && number > 16)
      .map(({ number }) => formatToLxDeviceNumber(number))
  );

  const availableNumbers = setDifference(
    takenDoorNumbers,
    allPossibleNumbers
  ) as Set<number>;

  let totalDevices = availableNumbers.size;

  if (!featureKeys.map(prop("key")).includes(FeatureKeyEnum.DOOR_ADD_ON_A)) {
    totalDevices -= 32;
  }
  if (!featureKeys.map(prop("key")).includes(FeatureKeyEnum.DOOR_ADD_ON_B)) {
    totalDevices -= 32;
  }
  return totalDevices;
};

const remove1100tFromDevice = (
  deviceInformationRecord: RecordProxy<DeviceInformation>
) => {
  if (
    deviceInformationRecord.getValue("deviceType") ===
    DeviceInformationTypeEnum._1100T
  ) {
    deviceInformationRecord.setValue(
      DeviceInformationTypeEnum.DOOR,
      "deviceType"
    );
  }
};
