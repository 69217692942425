/**
 * @ngdoc service
 * @name App.factory:UserSettingsAPI
 *
 */
App.factory("UserSettingsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/Dealer/:dealer_id/:app_type/:concept/:person_id",
      {},
      {
        getUserSettings: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            app_type: "@app_type",
            person_id: "@person_id",
            concept: "SettingsJson",
          },
        },
        updateUserSettings: {
          method: "PATCH",
          params: {
            dealer_id: "@dealer_id",
            app_type: "@app_type",
            person_id: "@person_id",
            concept: "PersonAppSettings",
          },
        },
      }
    );
  },
]);
