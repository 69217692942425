import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { securityGradeFieldId } from "components/FullProgramming/common/SecurityGradeFields/SecurityGradeField";
import { XRSecurityGradeProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRSecurityGradeProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSecurityGradeState } from "../../../XRFullProgramming/XRSecurityGradeProgrammingConceptForm";
import { ProgrammingTemplateSecurityGradeInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        securityGrade {
          id
          included
          grade {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRSecurityGradeProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateSecurityGradeInput => {
  const {
    panel: { securityGrade },
  } = getSecurityGradeState(controlSystem);

  if (!securityGrade) {
    return { included: false };
  }

  return {
    included: true,
    grade: {
      data: securityGrade.grade,
      included: includedFields.has(securityGradeFieldId()),
    },
  };
};

export const setIncludedFields = (
  template: XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { securityGrade } = getState(template);

  if (securityGrade?.grade?.included) {
    setIncludedFields(setToggle(securityGradeFieldId()));
  }
};
