import * as React from "react";
import { ConnectionHandler } from "react-relay";
import { RecordProxy, RecordSourceProxy } from "relay-runtime";
import {
  AreaConnection,
  BadZonesOption,
  fromAreaId,
  ID,
  Panel,
} from "securecom-graphql/client";
import { FORM_ID } from "../constants";

export const addNewArea = (
  store: RecordSourceProxy,
  areasConnection: RecordProxy<AreaConnection>,
  id: ID,
  name?: string
) => {
  const newAreaId = `${id}`;
  const newAreaRecord = store.create(newAreaId, "GenericArea");
  const number = fromAreaId(id).number;
  newAreaRecord.setValue(newAreaId, "id");
  newAreaRecord.setValue(name ? name : `Area ${number}`, "name");
  newAreaRecord.setValue(`${number}`, "number");
  newAreaRecord.setValue(false, "autoArmEnabled");
  newAreaRecord.setValue(BadZonesOption.BYPASS, "badZonesOption");
  newAreaRecord.setValue(false, "autoDisarmEnabled");
  newAreaRecord.setValue(false, "autoDisarmEnabled");
  newAreaRecord.setValue(true, "isNew");

  const newEdge = ConnectionHandler.createEdge(
    store,
    areasConnection,
    newAreaRecord,
    "AreaEdge"
  );

  newEdge.setValue(`${id}`, "cursor");

  ConnectionHandler.insertEdgeAfter(areasConnection, newEdge);
};

export const addNewXrArea = (
  store: RecordSourceProxy,
  areasConnection: RecordProxy<AreaConnection>,
  id: ID,
  accountNumber: string,
  name?: string
) => {
  const newAreaId = `${id}`;
  const newAreaRecord = store.create(newAreaId, "XrArea");
  const number = fromAreaId(id).number;
  newAreaRecord.setValue(newAreaId, "id");
  newAreaRecord.setValue(name ? name : `Area ${number}`, "name");
  newAreaRecord.setValue(`${number}`, "number");
  newAreaRecord.setValue(false, "autoArmEnabled");
  newAreaRecord.setValue(BadZonesOption.BYPASS, "badZonesOption");
  newAreaRecord.setValue(false, "autoDisarmEnabled");
  newAreaRecord.setValue(false, "autoDisarmEnabled");
  newAreaRecord.setValue(accountNumber, "accountNumber");
  newAreaRecord.setValue(0, "armedOutputNumber");
  newAreaRecord.setValue(0, "lateOutputNumber");
  newAreaRecord.setValue("0", "burglaryBellOutputNumber");
  newAreaRecord.setValue(true, "openClosingReports");
  newAreaRecord.setValue(true, "cardPlusPin");
  newAreaRecord.setValue(true, "isNew");

  const newEdge = ConnectionHandler.createEdge(
    store,
    areasConnection,
    newAreaRecord,
    "AreaEdge"
  );

  newEdge.setValue(`${id}`, "cursor");

  ConnectionHandler.insertEdgeAfter(areasConnection, newEdge);
};

export const validateBeforeSave = async (
  element: HTMLFormElement | HTMLFieldSetElement,
  onSave: () => void,
  onInvalid: () => void,
  setConceptsAreValidating:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => {})
) => {
  setConceptsAreValidating(true);
  const firstInvalidField = [...element.elements]
    .filter(
      (element): element is HTMLInputElement | HTMLSelectElement =>
        element instanceof HTMLInputElement ||
        element instanceof HTMLSelectElement
    )
    .find((element) => !element.checkValidity());
  if (!firstInvalidField) {
    setConceptsAreValidating(false);
    onSave();
  } else {
    setConceptsAreValidating(false);
    onInvalid();
  }
};

export const getFormElement = () =>
  document.getElementById(FORM_ID) as HTMLFormElement;

export const findNearestFieldset = (
  element: Element
): HTMLFieldSetElement | null =>
  !element.parentElement
    ? null
    : element.parentElement instanceof HTMLFieldSetElement
    ? element.parentElement
    : findNearestFieldset(element.parentElement);

export const removeListItemFromStore = (
  listItemId: string,
  listName: string,
  panelId: string,
  store: RecordSourceProxy
) => {
  const panel = store.get<Panel>(panelId);
  if (panel) {
    const linkedRecords = panel.getLinkedRecords(listName);
    if (linkedRecords) {
      panel.setLinkedRecords(
        linkedRecords.filter((record) => record.getValue("id") !== listItemId),
        listName
      );
    }
  }
  store.delete(listItemId);
};

export const removeAreaFromStore = (
  connectionId: string,
  areaId: string,
  store: RecordSourceProxy
) => {
  const connection = store.get<AreaConnection>(connectionId);
  if (connection) {
    ConnectionHandler.deleteNode(connection, areaId);
  }
};
