/**
 * @generated SignedSource<<f422901c6919c287fa1f69174606dc6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EolResistorValue = "FOUR_POINT_SEVEN_THOUSAND" | "ONE_THOUSAND" | "THREE_POINT_THREE_THOUSAND" | "TWO_POINT_TWO_THOUSAND";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsEolResistorValueField_systemOptions$data = {
  readonly eolResistorValue?: EolResistorValue;
  readonly " $fragmentType": "SystemOptionsEolResistorValueField_systemOptions";
};
export type SystemOptionsEolResistorValueField_systemOptions$key = {
  readonly " $data"?: SystemOptionsEolResistorValueField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsEolResistorValueField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "eolResistorValue",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsEolResistorValueField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "4e6c143ca6b0711805fbccfaedd1ee4e";

export default node;
