/**
 *
 * RadialWarningIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialWarningIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <polygon
          points="48.368,18.108 45.522,15.298 31.841,29.153 18.48,15.622 15.633,18.433 29.029,32 15.632,45.567 18.478,48.378 
      31.84,34.846 45.521,48.702 48.369,45.892 34.651,31.999     "
        />
        <path
          d="M32,0.09C14.355,0.09,0,14.405,0,32s14.355,31.91,32,31.91S64,49.595,64,32S49.645,0.09,32,0.09z M32,59.91
      C16.561,59.91,4,47.39,4,32S16.561,4.09,32,4.09S60,16.61,60,32S47.439,59.91,32,59.91z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default RadialWarningIcon;
