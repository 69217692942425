import * as React from "react";
import styled from "styled-components";
import SidebarSubnavButton from "./SidebarSubnavButton";

const Aside = styled.aside`
  flex-basis: 29.4rem;
  padding: var(--measure-2x) 0;
  border-right: 1px solid var(--color-neutral-300);
`;
const Sticky = styled.div`
  position: sticky;
  top: 75px;
`;

function SidebarSubnav(props: React.ComponentProps<typeof Aside>) {
  const { children, ...otherProps } = props;
  return (
    <Aside {...otherProps}>
      <Sticky>{children}</Sticky>
    </Aside>
  );
}

SidebarSubnav.Button = SidebarSubnavButton;

export default SidebarSubnav;
