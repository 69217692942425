import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { CardType } from "components/SiteForm/ExistingSite/CardFormatForm";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatSiteCodeLengthField_cardFormat$key } from "./__generated__/CardFormatSiteCodeLengthField_cardFormat.graphql";

export const cardFormatSiteCodeLengthFieldId = (number: string) =>
  `card-format-site-code-length-${number}`;

function CardFormatSiteCodeLengthField() {
  const [{ siteCodeLength, number, type }, updateCardFormat] =
    useCardFormatFragment<CardFormatSiteCodeLengthField_cardFormat$key>(
      graphql`
        fragment CardFormatSiteCodeLengthField_cardFormat on CardFormat {
          siteCodeLength
          number
          type
        }
      `
    );

  const fieldId = cardFormatSiteCodeLengthFieldId(number);
  const { current: originalValue } = React.useRef(siteCodeLength);
  const disabled = type !== CardType.CUSTOM;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Site Code Length"
      tooltip="Length of the Site Code within the card format."
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        pattern={"(0?[1-9]|1[0-9]|2[0-4])"}
        validationMessage="Valid values are 1-24."
        value={siteCodeLength ?? 8}
        inlineHelp={"1-24"}
        min={1}
        max={24}
        required
        onChange={({ target }) => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "siteCodeLength"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCardFormat((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(1, 24, valueAsNumber);
            recordProxy.setValue(value, "siteCodeLength");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatSiteCodeLengthField;
