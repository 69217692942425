import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathTestFrequencyNumberField_communicationPath$key } from "./__generated__/CommPathTestFrequencyNumberField_communicationPath.graphql";

export const commPathTestFrequencyNumberFieldId = (number: string) =>
  `comm-path-test-frequency-number-${number}`;

function CommPathTestFrequencyNumberField() {
  const [
    {
      id,
      number,
      testReport,
      testFrequencyNumber,
      testFrequencyNumberMin,
      testFrequencyNumberMax,
    },
    updateCommPath,
  ] = useCommPathFragment<CommPathTestFrequencyNumberField_communicationPath$key>(
    graphql`
      fragment CommPathTestFrequencyNumberField_communicationPath on CommunicationPath {
        id
        number
        testReport
        testFrequencyNumber
        testFrequencyNumberMin
        testFrequencyNumberMax
      }
    `
  );

  const fieldId = commPathTestFrequencyNumberFieldId(String(number));
  const { current: originalValue } = React.useRef(testFrequencyNumber);
  const disabled = testReport === "NO";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Test Frequency Number"
      tooltip="The number of days or hours (as defined by Test Frequency Unit) between sending tests to the receiver."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        name={id}
        value={testFrequencyNumber.toString()}
        required
        disabled={disabled}
        inlineHelp={`${testFrequencyNumberMin}–${testFrequencyNumberMax}`}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "testFrequencyNumber"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(
                  testFrequencyNumberMin,
                  testFrequencyNumberMax,
                  valueAsNumber
                );
            recordProxy.setValue(value, "testFrequencyNumber");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathTestFrequencyNumberField;
