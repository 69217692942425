/**
 * @ngdoc service
 * @name App.factory:DealerDashboardSalespersonODataAPI
 *
 * @description
 * Returns  audit list for salesperson dashboard view
 *
 */
App.factory("DealerDashboardSalespersonODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl +
        "/api/v1/CustomReportDownloadsDetails?$filter=ReportType eq 'business_analytics'",
      {},
      {
        getSalespersonsData: { method: "GET" },
      }
    );
  },
]);
/**
 * @ngdoc service
 * @name App.factory:SalespersonTerritoriesODataAPI
 *
 * @description
 * Returns  a list of all sales territories
 *
 */
App.factory("SalespersonTerritoriesODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/DmpData.GetSalesTerritories()",
      {},
      {
        getSalespersonsTerritories: { method: "GET" },
      }
    );
  },
]);

/**
 * @ngdoc service
 * @name App.factory:DealerDashboardSalespersonODataAPI
 *
 * @description
 * OData API factory for accounting reports data
 *
 */
App.factory("DealerDashboardSalespersonODataAPI", [
  "$resource",
  "PROPS",
  "SalespersonTerritoriesODataAPI",
  function ($resource, PROPS, SalespersonTerritoriesODataAPI) {
    return $resource(
      PROPS.reportsApiUrl +
        "/api/v1/CustomReportDownloadsDetails?$filter=ReportType eq 'business_analytics'",
      {},
      {
        getSalespersonsData: { method: "GET" },
      }
    );
  },
]);

App.factory("DealersBySalesTerritoryAPI", [
  "$resource",
  "PROPS",
  ($resource, PROPS) => {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/DmpData.GetDealersForUserTerritories()",
      {},
      {
        getFilteredDealerList: {
          method: "GET",
          params: {
            territory_list: "@territory_list",
          },
        },
      }
    );
  },
]);
