/**
 * @ngdoc service
 * @name App.factory:getPanelMacAddress
 *
 * @description
 * Service for getting the mac address and serial # of a panel
 */
App.factory("PanelMacAddressService", [
  "PanelV2API",
  "$q",
  "JobService",
  function (PanelV2API, $q, JobService) {
    return {
      /**
       * function: refreshPanelMacAddress
       * desc: to refresh the panel mac address
       *
       * @param {string} panel_id
       *
       * @returns {object} promise
       */
      refresh(panel_id) {
        var deferred = $q.defer();
        PanelV2API.refreshMacAddress(
          { panel_id: panel_id },
          function (success) {
            JobService.process(success.job.uuid).then(
              function (jobData) {
                deferred.resolve(jobData);
              },
              function (jobError) {
                deferred.reject(jobError);
              },
              function (notify) {
                deferred.notify(notify);
              }
            );
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      get(panel_id) {
        var deferred = $q.defer();
        PanelV2API.getMacAddress(
          { panel_id: panel_id },
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
