/**
 * @ngdoc service
 * @name App.factory:PanelReportsAPI
 *
 * @description
 * API factory for Panel Report data
 *
 */
App.factory("PanelODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/panels(:panel_id)/:concept",
      {},
      {
        getPanel: { method: "GET", params: { panel_id: "@panel_id" } },
        getProfiles: {
          method: "GET",
          params: { panel_id: "@panel_id", concept: "profiles" },
        },
        getUserCodes: {
          method: "GET",
          params: { panel_id: "@panel_id", concept: "user_codes" },
        },
        getEventSubcategories: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            concept: "event_subcategories",
            $expand: "event_report_client_filter",
          },
        },
        getFilteredEventSubcategories: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            concept: "filtered_event_subcategories",
            $expand: "event_report_client_filter",
          },
        },
        getAreas: {
          method: "GET",
          params: { panel_id: "@panel_id", concept: "areas" },
        },
        getZones: {
          method: "GET",
          params: { panel_id: "@panel_id", concept: "zones" },
        },
        getPanelStatistics: {
          method: "GET",
          params: { panel_id: "@panel_id", concept: "vk.GetPanelStatistics()" },
        },
        getProgBackups: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            concept: "vk.GetPanelProgBackups()",
          },
        },
        getPanelEventHistories: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            concept: "event_histories",
            $orderby: "event_at desc",
            $filter: "camera_number ne null",
          },
        },
      }
    );
  },
]);

App.factory("PanelODataEventHistoriesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.oDataEventHistoriesUrl + "/api/v1/panels(:panel_id)/:concept",
      {},
      {
        getPanelEventHistories: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            concept: "event_histories",
            $orderby: "event_at desc",
            $filter: "camera_number ne null",
          },
        },
      }
    );
  },
]);

App.factory("PanelODataConnectionHistoriesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.oDataConnectionHistoriesUrl +
        "/api/v1/panels(:panel_id)/vk.GetConnectionHistories",
      {},
      {
        getPanelConnectionHistories: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            $orderby: "created_at desc",
            $top: 20,
          },
        },
      }
    );
  },
]);
