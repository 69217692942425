import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver1AlarmReportsField_communication$key } from "./__generated__/CommunicationReceiver1AlarmReportsField_communication.graphql";

export const communicationReceiver1AlarmReportsFieldId = () =>
  "communication-receiver-1-alarm-reports";

function CommunicationReceiver1AlarmReportsField() {
  const [{ receiver1AlarmReports }, updateCommunication] =
    useCommunicationFragment<CommunicationReceiver1AlarmReportsField_communication$key>(
      graphql`
        fragment CommunicationReceiver1AlarmReportsField_communication on Communication {
          receiver1AlarmReports
        }
      `
    );

  const fieldId = communicationReceiver1AlarmReportsFieldId();
  const id = "communication-receiver1-alarm-reports";
  const label = "Alarm Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Turns on Zone Alarm reports and enables Alarm Restore, Bypass, and Reset reports if programmed in System Reports."
    >
      <Switch
        id={fieldId}
        label={label}
        name={id}
        checked={receiver1AlarmReports}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              !receiver1AlarmReports,
              "receiver1AlarmReports"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver1AlarmReportsField;
