import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationFirePanelSlaveInputField_zone$key } from "./__generated__/ZoneInformationFirePanelSlaveInputField_zone.graphql";

export const zoneInformationFirePanelSlaveInputFieldId = (number: string) =>
  `zone-information-fire-panel-slave-input-${number}`;

function ZoneInformationFirePanelSlaveInputField() {
  const [{ number, firePanelSlaveInput, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationFirePanelSlaveInputField_zone$key>(
      graphql`
        fragment ZoneInformationFirePanelSlaveInputField_zone on Zone {
          number
          firePanelSlaveInput
          type
        }
      `
    );

  const fieldId = zoneInformationFirePanelSlaveInputFieldId(String(number));
  const disabled = type !== ZoneType.FIRE;
  const label = "Fire Panel Slave Input";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="	Enable to make the Zone automatically send a zone restore message and clear from the status list upon restoral (as if a Sensor Reset was performed)."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={firePanelSlaveInput}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!firePanelSlaveInput, "firePanelSlaveInput");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationFirePanelSlaveInputField;
