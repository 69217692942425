import * as React from "react";

type Size =
  | "half"
  | "1x"
  | "12"
  | "2x"
  | "20"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "7x"
  | "8x"
  | "9x"
  | "10x";

export default function Spacer({ size = "2x" }: { size?: Size }) {
  return (
    <div style={{ paddingTop: `var(--measure-${size})` }} aria-hidden="true" />
  );
}
