import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZoneOutputAction, ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { TwentyFourHourZoneTypes } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationDisarmedShortOutputActionField_zone$key } from "./__generated__/ZoneInformationDisarmedShortOutputActionField_zone.graphql";

export const zoneInformationDisarmedShortOutputActionFieldId = (
  number: string
) => `zone-information-disarmed-short-output-action-${number}`;

function ZoneInformationDisarmedShortOutputActionField() {
  const [{ number, disarmedShortOutputAction, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationDisarmedShortOutputActionField_zone$key>(
      graphql`
        fragment ZoneInformationDisarmedShortOutputActionField_zone on Zone {
          number
          type
          disarmedShortOutputAction
        }
      `
    );

  const fieldId = zoneInformationDisarmedShortOutputActionFieldId(
    String(number)
  );
  const disabled = [
    ...TwentyFourHourZoneTypes,
    ZoneType.ARMING,
    ZoneType.TAMPER,
  ].includes(type as ZoneType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Disarmed Short Output Action"
      tooltip="Assigns a cadence to the Disarmed Short Output."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={disarmedShortOutputAction ?? undefined}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "disarmedShortOutputAction");
          });
        }}
      >
        <Select.Option value={ZoneOutputAction.NONE}>None</Select.Option>
        <Select.Option value={ZoneOutputAction.STEADY}>Steady</Select.Option>
        <Select.Option value={ZoneOutputAction.PULSE}>Pulse</Select.Option>
        <Select.Option value={ZoneOutputAction.MOMENTARY}>
          Momentary
        </Select.Option>
        <Select.Option value={ZoneOutputAction.FOLLOW}>Follow</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationDisarmedShortOutputActionField;
