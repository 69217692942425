/**
 * @ngdoc service
 * @name App.factory:SedonaIntegrationV1API
 *
 * @description
 * API factory for the Sedona endpoint on the Integration API
 *
 */
App.factory("SedonaIntegrationV1API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.integrationUrl + "/api/v1/Sedona/:method",
      {},
      {
        getVersion: { method: "GET", params: { method: "version" } },
        getParts: { method: "GET", params: { method: "parts" }, isArray: true },
        getCustomerTypes: {
          method: "GET",
          params: { method: "customertypes" },
          isArray: true,
        },
        getTerms: { method: "GET", params: { method: "terms" }, isArray: true },
        getBranches: {
          method: "GET",
          params: { method: "branches" },
          isArray: true,
        },
        getSystemTypes: {
          method: "GET",
          params: { method: "systemtypes" },
          isArray: true,
        },
      }
    );
  },
]);
