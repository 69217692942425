/**
 * @generated SignedSource<<bbf8472041f30c42d94d6491a8e0b729>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
export type NacProtocols = "DMP" | "GENTEX" | "SYSTEM_SENSOR" | "UNKNOWN" | "WHEELOCK";
import { FragmentRefs } from "relay-runtime";
export type XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly bellOptions: {
    readonly automaticBellTest: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary1BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary2BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly bellCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly bellOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly emergencyBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly fireBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly nac1SyncProtocol: {
      readonly data: NacProtocols | null;
      readonly included: boolean | null;
    } | null;
    readonly nac2SyncProtocol: {
      readonly data: NacProtocols | null;
      readonly included: boolean | null;
    } | null;
    readonly panicBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly selectiveSilence: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFBellOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "48aca0cec5fe7852d2f3763f9a875a59";

export default node;
