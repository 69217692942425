/**
 * @generated SignedSource<<fd25216ad0fec0c01430cab53651045d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationTypeField_communication$data = {
  readonly checkInMinutes: number;
  readonly comType: CommunicationComType;
  readonly " $fragmentType": "CommunicationTypeField_communication";
};
export type CommunicationTypeField_communication$key = {
  readonly " $data"?: CommunicationTypeField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationTypeField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationTypeField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutes",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "eabca9eebc3ddca0231a9cce3025f7eb";

export default node;
