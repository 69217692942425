import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import { RecordProxy } from "relay-runtime";
import { Output } from "securecom-graphql/client";
import { PanelHardwareModel as PanelContextHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";

const outputSerialNumberRangeString = "15100000-15999999";
const outputSerialNumberRegEx = "(15[1-9][0-9]{5})";

const TMSENTRY_OUTPUT_FIELD_RULES = {
  NUMBER: {
    PATTERN: "(0{0,2}0|0{0,2}[1-2])",
    INLINE_HELP: "0, 1-2",
    VALIDATION_MESSAGE: "Valid values are 0,1-2",
    TOOL_TIP: "Slow (15s) Wireless: 51 - 54. Fast (1s) Wireless: 61 - 64.",
  },
  NAME: {
    PATTERN: "^.*$",
    VALIDATION_MESSAGE: "16 character maximum.",
  },
  SERIAL_NUMBER: {
    PATTERN: outputSerialNumberRegEx,
    INLINE_HELP: outputSerialNumberRangeString,
    VALIDATION_MESSAGE: `Valid values are ${outputSerialNumberRangeString}.`,
  },
};

const XTL_OUTPUT_FIELD_RULES = {
  NUMBER: {
    PATTERN: "(0{0,1}5[1-4]|0{0,1}6[1-4]|F0[1-9])",
    INLINE_HELP: "51-54, 61-64",
    VALIDATION_MESSAGE: "Valid values are 51-54, 61-64.",
    TOOL_TIP: "Slow (15s) Wireless: 51 - 54. Fast (1s) Wireless: 61 - 64.",
  },
  NAME: {
    PATTERN: "^.*$",
    VALIDATION_MESSAGE: "16 character maximum.",
  },
  SERIAL_NUMBER: {
    PATTERN: outputSerialNumberRegEx,
    INLINE_HELP: outputSerialNumberRangeString,
    VALIDATION_MESSAGE: `Valid values are ${outputSerialNumberRangeString}.`,
  },
};

const XT_OUTPUT_FIELD_RULES = {
  NUMBER: {
    PATTERN: "(0{0,2}[1-4]|0{0,1}3[1-4]|0{0,1}4[1-4])",
    INLINE_HELP: "1-4, 31-34, 41-44",
    VALIDATION_MESSAGE: "Valid values are 1-4, 31-34, 41-44.",
    TOOL_TIP:
      "Wired: 1-4. Slow (15s) Wireless: 31 - 34. Fast (1s) Wireless: 41 - 44.",
  },
  NAME: {
    PATTERN: "^.*$",
    VALIDATION_MESSAGE: "16 character maximum.",
  },
  SERIAL_NUMBER: {
    PATTERN: outputSerialNumberRegEx,
    INLINE_HELP: outputSerialNumberRangeString,
    VALIDATION_MESSAGE: `Valid values are ${outputSerialNumberRangeString}.`,
  },
};
const XT_75_OUTPUT_FIELD_RULES = {
  XT75: {
    NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-4]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-4][0-9])",
      INLINE_HELP: "1-4, 450-474, 480-549.",
      VALIDATION_MESSAGE: "Valid values are 1-4, 450-474, and 480-549.",
      TOOL_TIP:
        "Wired: 1-4. Slow wireless: 450-474. Fast Wireless: 480-499. LX Bux: 500-549.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "32 character maximum",
    },
    SERIAL_NUMBER: {
      PATTERN: outputSerialNumberRegEx,
      INLINE_HELP: outputSerialNumberRangeString,
      VALIDATION_MESSAGE: `Valid values are ${outputSerialNumberRangeString}.`,
    },
  },
};
const XR_OUTPUT_FIELD_RULES = {
  XR550: {
    NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-9][0-9]{2})",
      INLINE_HELP: "1-6, 450-474, 480-999",
      VALIDATION_MESSAGE: "Valid values are 1-6, 450-474, and 480-999.",
      TOOL_TIP:
        "Wired: 1-6. Slow Wireless: 450-474. Fast Wireless: 480-499. LX Bus: 500-999.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN: outputSerialNumberRegEx,
      INLINE_HELP: outputSerialNumberRangeString,
      VALIDATION_MESSAGE: `Valid values are ${outputSerialNumberRangeString}.`,
    },
  },
  XR350: {
    NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-7][0-9]{2})",
      INLINE_HELP: "1-6, 450-474, 480-799",
      VALIDATION_MESSAGE: "Valid values are 1-6, 450-474, and 480-799.",
      TOOL_TIP:
        "Wired: 1-6. Slow Wireless: 450-474. Fast Wireless: 480-499. LX Bus: 500-799.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN: outputSerialNumberRegEx,
      INLINE_HELP: outputSerialNumberRangeString,
      VALIDATION_MESSAGE: `Valid values are ${outputSerialNumberRangeString}.`,
    },
  },
  XR150: {
    NUMBER: {
      PATTERN: "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-9]{2})",
      INLINE_HELP: "1-6, 450-474, 480-599",
      VALIDATION_MESSAGE: "Valid values are 1-6, 450-474, and 480-599.",
      TOOL_TIP:
        "Wired: 1-6. Slow Wireless: 450-474. Fast Wireless: 480-499. LX Bus: 500-599.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN: outputSerialNumberRegEx,
      INLINE_HELP: outputSerialNumberRangeString,
      VALIDATION_MESSAGE: `Valid values are ${outputSerialNumberRangeString}.`,
    },
  },
};
const XF_OUTPUT_FIELD_RULES = {
  XF6_500: {
    NUMBER: {
      PATTERN: "(0{0,3}|0{0,2}[1-6]|[5-9][0-9][0-9])",
      INLINE_HELP: "1-6, 500-999",
      VALIDATION_MESSAGE: "Valid values are 1-6 and 500-999.",
      TOOL_TIP: "Wired: 1-6. LX Bus: 500-999.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
  },
  XF6_100: {
    NUMBER: {
      PATTERN: "(0{0,3}|0{0,2}[1-6]|[5][0-9][0-9])",
      INLINE_HELP: "1-6, 500-599",
      VALIDATION_MESSAGE: "Valid values are 1-6 and 500-599.",
      TOOL_TIP: "Wired: 1-6. LX Bus: 500-599.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MESSAGE: "16 character maximum.",
    },
  },
};

//The following ranges determine the actual valid output range
const XT75_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 5),
  WIRELESS: [range(450, 475), range(480, 500)],
  LX_BUS: range(500, 550),
};
const XR_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 7),
  WIRELESS: [range(450, 475), range(480, 500)],
  LX_BUS: {
    XR150: range(480, 600),
    XR350: range(480, 800),
    XR550: range(480, 1000),
  },
};
const XF6_500_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 7),
  LX_BUS: range(500, 1000),
};
const XF6_100_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 7),
  LX_BUS: range(500, 600),
};
const XTL_OUTPUT_NUMBERS = {
  WIRELESS: [range(51, 55), range(61, 65)],
};
const XT_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 5),
  WIRELESS: [range(31, 35), range(41, 45)],
};
const XT_75_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 5),
  WIRELESS: [range(450, 475), range(480, 500)],
  LX_BUS: range(500, 550),
};
const TAKEOVER_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 3),
};
const TMSENTRY_OUTPUT_NUMBERS = {
  ONBOARD: range(1, 3),
};
const CELLCOM_EX_OUTPUT_NUMBERS = {
  ONBOARD: [1],
};

export const resolveOutputNumbers = (
  hardwareModel: PanelContextHardwareModel,
  hasWireless: boolean
): number[] => {
  const {
    isTakeoverPanel,
    isXtl,
    isXtlN,
    isXtlW,
    isXtlPlus,
    isXt75,
    isXr,
    isXr150,
    isXr350,
    isCellComEx,
    isXf6_500,
    isXf6_100,
    isTMSentry,
  } = resolvePanelType(hardwareModel);

  return isCellComEx
    ? CELLCOM_EX_OUTPUT_NUMBERS.ONBOARD
    : isTakeoverPanel
    ? TAKEOVER_OUTPUT_NUMBERS.ONBOARD
    : isTMSentry
    ? TMSENTRY_OUTPUT_NUMBERS.ONBOARD
    : isXtl || isXtlN || isXtlW || isXtlPlus
    ? XTL_OUTPUT_NUMBERS.WIRELESS.flat()
    : isXt75
    ? [
        ...XT_75_OUTPUT_NUMBERS.ONBOARD,
        ...(hasWireless ? XT_75_OUTPUT_NUMBERS.WIRELESS.flat() : []),
        ...XT_75_OUTPUT_NUMBERS.LX_BUS,
      ]
    : isXr
    ? isXr150
      ? [
          ...XR_OUTPUT_NUMBERS.ONBOARD,
          ...(hasWireless ? XR_OUTPUT_NUMBERS.WIRELESS.flat() : []),
          ...XR_OUTPUT_NUMBERS.LX_BUS.XR150,
        ]
      : isXr350
      ? [
          ...XR_OUTPUT_NUMBERS.ONBOARD,
          ...(hasWireless ? XR_OUTPUT_NUMBERS.WIRELESS.flat() : []),
          ...XR_OUTPUT_NUMBERS.LX_BUS.XR350,
        ]
      : [
          ...XR_OUTPUT_NUMBERS.ONBOARD,
          ...(hasWireless ? XR_OUTPUT_NUMBERS.WIRELESS.flat() : []),
          ...XR_OUTPUT_NUMBERS.LX_BUS.XR550,
        ]
    : isXf6_500
    ? [...XF6_500_OUTPUT_NUMBERS.ONBOARD, ...XF6_500_OUTPUT_NUMBERS.LX_BUS]
    : isXf6_100
    ? [...XF6_100_OUTPUT_NUMBERS.ONBOARD, ...XF6_100_OUTPUT_NUMBERS.LX_BUS]
    : [
        ...XT_OUTPUT_NUMBERS.ONBOARD,
        ...(hasWireless ? XT_OUTPUT_NUMBERS.WIRELESS.flat() : []),
      ];
};

export const resolveWirelessOutputNumbers = (
  hardwareModel: PanelContextHardwareModel
): number[] => {
  const { isTakeoverPanel, isXtl, isXtlN, isXtlW, isXtlPlus, isXr, isXt75 } =
    resolvePanelType(hardwareModel);

  return isTakeoverPanel
    ? []
    : isXtl || isXtlN || isXtlW || isXtlPlus
    ? XTL_OUTPUT_NUMBERS.WIRELESS.flat()
    : isXr
    ? XR_OUTPUT_NUMBERS.WIRELESS.flat()
    : isXt75
    ? XT75_OUTPUT_NUMBERS.WIRELESS.flat()
    : XT_OUTPUT_NUMBERS.WIRELESS.flat();
};

type PanelOutputFieldRules = {
  NUMBER: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MESSAGE: string;
    TOOL_TIP: string;
  };
  NAME: {
    PATTERN: string;
    VALIDATION_MESSAGE: string;
  };
  SERIAL_NUMBER?: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MESSAGE: string;
  };
};

export const resolveOutputFieldRules = (
  hardwareModel: PanelContextHardwareModel
): PanelOutputFieldRules => {
  const {
    isTakeoverPanel,
    isXtl,
    isXtlN,
    isXtlW,
    isXtlPlus,
    isXr,
    isXr150,
    isXr350,
    isXf6_500,
    isXf6_100,
    isXt75,
  } = resolvePanelType(hardwareModel);

  return isTakeoverPanel
    ? XT_OUTPUT_FIELD_RULES
    : isXtl || isXtlN || isXtlW || isXtlPlus
    ? XTL_OUTPUT_FIELD_RULES
    : isXr
    ? isXr150
      ? XR_OUTPUT_FIELD_RULES.XR150
      : isXr350
      ? XR_OUTPUT_FIELD_RULES.XR350
      : XR_OUTPUT_FIELD_RULES.XR550
    : isXf6_500
    ? XF_OUTPUT_FIELD_RULES.XF6_500
    : isXf6_100
    ? XF_OUTPUT_FIELD_RULES.XF6_100
    : isXt75
    ? XT_75_OUTPUT_FIELD_RULES.XT75
    : XT_OUTPUT_FIELD_RULES;
};
export const serialNumberIsValidWirelessBell = (
  serialNumber: string
): boolean => new RegExp("(15[2-9][0-9]{5})", "u").test(serialNumber ?? "");

export const applyDupedOutputInformationProgrammingToOutputInformation = (
  source: RecordProxy<Output>,
  dest: RecordProxy<Output>,
  availableWirelessNumbers?: Set<number>
) => {
  const destId = dest.getValue("id");
  const destNumber = dest.getValue("number");
  const isNew = dest.getValue("isNew");
  const destSerialNumber = dest.getValue("serialNumber");
  const destTripWithPanelBell = dest.getValue("tripWithPanelBell");
  const destOutputSupervision = dest.getValue("outputSupervision");

  const canDestBeWireless =
    availableWirelessNumbers?.has(Number(destNumber)) ?? false;

  dest.copyFieldsFrom(source);
  dest.setValue(destId, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(destNumber, "number");
  dest.setValue(canDestBeWireless, "isWireless");
  if (!canDestBeWireless) {
    dest.setValue(destSerialNumber, "serialNumber");
    dest.setValue(destTripWithPanelBell, "tripWithPanelBell");
    dest.setValue(destOutputSupervision, "outputSupervision");
    dest.setValue(destOutputSupervision, "outputSupervision");
    dest.setValue(false, "realTimeStatus");
  }

  return dest;
};

export const applyCopiedOutputInformationProgrammingToOutputInformation = (
  source: RecordProxy<Output>,
  dest: RecordProxy<Output>,
  availableWirelessNumbers?: Set<number>
) => {
  const destId = dest.getValue("id");
  const destNumber = dest.getValue("number");
  const isNew = dest.getValue("isNew");
  const destSerialNumber = dest.getValue("serialNumber");
  const destTripWithPanelBell = dest.getValue("tripWithPanelBell");
  const destOutputSupervision = dest.getValue("outputSupervision");
  const destIsWireless = dest.getValue("isWireless");

  const srcNumber = source.getValue("number");

  dest.copyFieldsFrom(source);
  dest.setValue(destId, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(destNumber, "number");

  const canDestBeWireless =
    availableWirelessNumbers?.has(Number(destNumber)) ?? false;
  const canSrcBeWireless =
    availableWirelessNumbers?.has(Number(srcNumber)) ?? false;

  if ((!canSrcBeWireless && !canDestBeWireless) || !canDestBeWireless) {
    dest.setValue(destSerialNumber, "serialNumber");
    dest.setValue(destTripWithPanelBell, "tripWithPanelBell");
    dest.setValue(destOutputSupervision, "outputSupervision");
    dest.setValue(destOutputSupervision, "outputSupervision");
    dest.setValue(destIsWireless, "isWireless");
  }
  if (destIsWireless) {
    dest.setValue(false, "realTimeStatus");
  }

  return dest;
};
