/**
 * @ngdoc service
 * @name App.factory:CustomersV2API
 *
 * @description
 * API factory for Customer Information
 *
 */
App.factory("PartsCatalogAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/parts_catalog/:rand/details",
      {},
      {
        show: { method: "GET", params: { customer_id: "@rand" } },
      }
    );
  },
]);
