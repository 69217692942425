/**
 * @generated SignedSource<<8873cda893d129397b44834109bf93dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagingSetupDestination2UserField_messagingSetup$data = {
  readonly enableMessaging: boolean;
  readonly secondEmailOrPhoneNumber: string;
  readonly userNumberForSecondContact: string;
  readonly " $fragmentType": "MessagingSetupDestination2UserField_messagingSetup";
};
export type MessagingSetupDestination2UserField_messagingSetup$key = {
  readonly " $data"?: MessagingSetupDestination2UserField_messagingSetup$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagingSetupDestination2UserField_messagingSetup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagingSetupDestination2UserField_messagingSetup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userNumberForSecondContact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondEmailOrPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableMessaging",
      "storageKey": null
    }
  ],
  "type": "MessagingSetup",
  "abstractKey": null
};

(node as any).hash = "f59a47f4e08b8446902e5d350aca487d";

export default node;
