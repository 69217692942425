/**
 * @generated SignedSource<<4b30aa20143b531aec43a483d776b276>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemAreaInformationClosingCodeField_systemAreaInformation$data = {
  readonly closingCode: boolean;
  readonly " $fragmentType": "SystemAreaInformationClosingCodeField_systemAreaInformation";
};
export type SystemAreaInformationClosingCodeField_systemAreaInformation$key = {
  readonly " $data"?: SystemAreaInformationClosingCodeField_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemAreaInformationClosingCodeField_systemAreaInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemAreaInformationClosingCodeField_systemAreaInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closingCode",
      "storageKey": null
    }
  ],
  "type": "SystemAreaInformation",
  "abstractKey": null
};

(node as any).hash = "f35ea9cec4cbc3ac948ef39cc7ad88b3";

export default node;
