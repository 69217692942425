import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationPinDisarmField_deviceInformation$key } from "./__generated__/DeviceInformationPinDisarmField_deviceInformation.graphql";

export const deviceInformationPinDisarmFieldId = (number: string) =>
  `device-information-pin-disarm-${number}`;

function DeviceInformationPinDisarmField() {
  const [{ number, pinDisarm, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationPinDisarmField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationPinDisarmField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            pinDisarm
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationPinDisarmFieldId(String(number));
  const label = "PIN Disarm";
  const disabled = ![
    DeviceInformationType.DOOR,
    DeviceInformationType.KEYPAD,
  ].includes(deviceType as DeviceInformationType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="When disabled, Pin Disarm will require the user to disarm the system by means other than manually entering a code, e.g. Remote Fob, Access card swipe, or use of an Arming zone."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        disabled={disabled}
        label={label}
        name={fieldId}
        checked={pinDisarm ?? true}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!pinDisarm, "pinDisarm");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationPinDisarmField;
