import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  Device734CardOptions,
  DeviceInformation,
  DeviceInformationCommType,
  DeviceInformationType,
} from "securecom-graphql/client";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationUserCodeDigitsField_deviceInformation$key } from "./__generated__/DeviceInformationUserCodeDigitsField_deviceInformation.graphql";

export const deviceInformationUserCodeDigitsFieldId = (number: string) =>
  `device-information-user-code-digits-${number}`;

function DeviceInformationUserCodeDigitsField() {
  const [
    {
      id,
      number,
      device734,
      deviceCommunicationMethod,
      deviceType,
      remoteProgram734,
    },
  ] = useDeviceInformationFragment<DeviceInformationUserCodeDigitsField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationUserCodeDigitsField_deviceInformation on DeviceInformation {
        id
        number
        ... on XrDeviceInformation {
          remoteProgram734
          deviceType
          deviceCommunicationMethod
          device734 {
            ... on Device734 {
              numberOfUserCodeDigits
              cardFormatOptions
            }
          }
        }
        ... on Xt75DeviceInformation {
          remoteProgram734
          deviceType
          deviceCommunicationMethod
          device734 {
            ... on Device734 {
              numberOfUserCodeDigits
              cardFormatOptions
            }
          }
        }
      }
    `
  );

  const fieldId = deviceInformationUserCodeDigitsFieldId(String(number));
  const { current: originalValue } = React.useRef(
    device734?.numberOfUserCodeDigits
  );
  const disabled =
    device734?.cardFormatOptions !== Device734CardOptions.DMP ||
    deviceType !== DeviceInformationType.DOOR ||
    !remoteProgram734;
  const isKeypadBusDoor =
    deviceCommunicationMethod === DeviceInformationCommType.KEYPAD_BUS;
  const relayEnv = useRelayEnvironment();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="User Code Digits"
      tooltip="Length of the user code."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        pattern={isKeypadBusDoor ? "(0?[4-9]|1[0-2])" : "(0?[1-9]|1[0-2])"}
        value={device734?.numberOfUserCodeDigits ?? 5}
        inlineHelp={isKeypadBusDoor ? "4-12" : "1-12"}
        required
        min={isKeypadBusDoor ? 4 : 1}
        max={12}
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options && isNotNullOrUndefined(target.value)) {
              device734Options.setValue(
                emptyStringOrNumber(target.value),
                "numberOfUserCodeDigits"
              );
            }
          });
        }}
        onBlur={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options && isNotNullOrUndefined(target.value)) {
              const valueAsNumber = Number(target.value);
              const value = isNaN(valueAsNumber)
                ? originalValue
                : clamp(isKeypadBusDoor ? 4 : 1, 12, valueAsNumber);
              device734Options.setValue(
                Number(value),
                "numberOfUserCodeDigits"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationUserCodeDigitsField;
