import * as React from "react";
import styled from "styled-components/macro";
export const Default = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.AllHTMLAttributes<HTMLDivElement>["style"];
}) => {
  return (
    <div className={className ? `panel ${className}` : `panel`} style={style}>
      {children}
    </div>
  );
};

export const BorderlessTable = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.AllHTMLAttributes<HTMLDivElement>["style"];
}) => {
  return (
    <div
      className={
        className ? `panel panel-table ${className}` : `panel panel-table`
      }
      style={{ border: "none", ...style }}
    >
      {children}
    </div>
  );
};

export const Table = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.AllHTMLAttributes<HTMLDivElement>["style"];
}) => {
  return (
    <div
      className={
        className ? `panel panel-table ${className}` : `panel panel-table`
      }
      style={style}
    >
      {children}
    </div>
  );
};
const PanelHeader = ({ children }: { children: React.ReactNode }) => {
  return <div className="panel-heading">{children}</div>;
};

const PanelBody = ({ children }: { children: React.ReactNode }) => {
  return <div className="panel-body">{children}</div>;
};

export default { Default, BorderlessTable, Table, PanelHeader, PanelBody };

export const PanelTitle = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  margin-bottom: 0;
`;
