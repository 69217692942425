/**
 * @generated SignedSource<<9ff8cc210c574cd1c3a1b1f88e6ee83d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationEncryptionEnabledField_communication$data = {
  readonly encryptionEnabled: boolean;
  readonly " $fragmentType": "CommunicationEncryptionEnabledField_communication";
};
export type CommunicationEncryptionEnabledField_communication$key = {
  readonly " $data"?: CommunicationEncryptionEnabledField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationEncryptionEnabledField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationEncryptionEnabledField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "encryptionEnabled",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "d93a662c3f6097d645e408f22b67d889";

export default node;
