import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationTrafficCountField_zone$key } from "./__generated__/ZoneInformationTrafficCountField_zone.graphql";

export const zoneInformationTrafficCountFieldId = (number: string) =>
  `zone-information-traffic-count-${number}`;

function ZoneInformationTrafficCountField() {
  const [{ number, type, trafficCountEnabled }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationTrafficCountField_zone$key>(
      graphql`
        fragment ZoneInformationTrafficCountField_zone on Zone {
          number
          type
          trafficCountEnabled
        }
      `
    );

  const fieldId = zoneInformationTrafficCountFieldId(String(number));
  const disabled = ![
    ZoneType.NIGHT,
    ZoneType.EXIT,
    ZoneType.FINAL_EXIT,
    ZoneType.INSTANT,
  ].includes(type as ZoneType);
  const label = "Traffic Count";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Upon Arming, reports the number of Zone trips for the preceding Disarmed period for the 10 lowest numbered Traffic Count Zones."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={trafficCountEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!trafficCountEnabled, "trafficCountEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationTrafficCountField;
