/**
 * @ngdoc directive
 * @name App.directive:duplicateValueCheck
 *
 * @description
 *   Checks to see if the given value already exists in the specified object array
 *
 *   Required Attributes:
 *   panel: The panel object to pull concepts from
 *   concept: The concept to search for duplicate values
 *   prop: The property name to check in the values array
 *   da-duplicate-value-check: the value to check for in the values array
 *
 *   Optional Attributes:
 *   z-length: The amount of zero padding to apply to the value
 *   page-type: The current page type (ex: 'new', 'edit')
 *
 */
App.directive("daDuplicateValueCheck", [
  "$filter",
  "PANEL_CONCEPTS",
  "PANEL_SHARED_CONCEPTS",
  function ($filter, PANEL_CONCEPTS, PANEL_SHARED_CONCEPTS) {
    return {
      require: "ngModel",
      link: function ($scope, element, attrs, ngModel) {
        ngModel.$validators.duplicatevalueerror = function (value) {
          var panel = $scope.panelModel; // A reference to the passed in panel object
          var sourceObj = $scope.item; // The object we're comparing against
          var results = []; // Stores the list of results from the concept filter(s)
          var concept = $scope.concept; // The concept we're currently working with
          var concepts = [$scope.concept]; // The list of concepts to search for duplicate values in
          var prop = $scope.opts.dynamicName; // The property name to compare
          var zLength = $scope.Panel.getFieldMetaProperty(
            concept,
            prop,
            "ZEROPAD"
          ); // Get the field z-length value from panel def

          // Only continue if duplicate-check is true
          if ($scope.opts.duplicateCheck !== "true") return true;

          // Delete any existing errors
          if (
            DoesNestedPropertyExist(
              panel,
              "validation." + concept + "." + value + "." + prop
            )
          )
            delete panel.validation[concept][value][prop];

          // We can't continue validation if we don't have a concept
          if (!$scope.concept || concept === "") return true;

          // z-length is an optional field that allows us to pad our search value with zeroes
          if (zLength) value = $filter("zpad")(value, zLength);

          // If this concept has potentially overlapping concepts with a value in the overlap range, concatenate the
          // concepts (so that the concept in question is checked first)
          if (
            panel.isSharedConcept(concept) &&
            panel.getOverlapMask().indexOf(+value) > -1
          ) {
            angular.forEach(PANEL_SHARED_CONCEPTS, function (sharedConcept) {
              if (sharedConcept !== concept) {
                concepts.push(sharedConcept);
              }
            });
          }

          // Loop through all of our concepts searching for a matching property value
          for (var i = 0; i < concepts.length; i++) {
            var item = 0;
            results[concepts[i]] = $filter("filter")(
              panel[concepts[i]],
              function (item) {
                return item[prop] === value && item !== sourceObj;
              },
              true
            );

            // Check to see if we found a duplicate value
            if (results[concepts[i]] && results[concepts[i]].length > 0) {
              // Store the error message in panel
              if (!panel.validation) panel["validation"] = [];
              if (!panel.validation[concept]) panel.validation[concept] = {};
              if (!panel.validation[concept][value])
                panel.validation[concept][value] = {};
              panel.validation[concept][value][prop] =
                prop +
                " is already in use (" +
                PANEL_CONCEPTS[concepts[i]].single_name +
                ") " +
                results[concepts[i]][item].name;
              return false;

              // TODO: This code will use panel form errors for error logging. It's currently causing too many digest cycles
              // Build an error message to pass to the panel
              // var singularConcept = $filter('singularize')(concept);
              // var errorMsg = prop + " is already in use (" + PANEL_CONCEPTS[concepts[i]].single_name + ") " +
              //   results[concepts[i]][item].name;
              // panel.generatePanelError(concept, errorMsg, prop, sourceObj);
              // return false;
            }
          }

          // TODO: If panel form errors are used, this line needs to be uncommented
          // panel.clearErrors(concept, prop); // Clear out any existing errors for the concept
          return true;
        };
      },
    };
  },
]);
