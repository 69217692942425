/**
 * @generated SignedSource<<5c0d299af7a34419bfc1b8c03d1cd419>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BellOptionsBellOutputField_bellOptions$data = {
  readonly bellOutput: string;
  readonly " $fragmentType": "BellOptionsBellOutputField_bellOptions";
};
export type BellOptionsBellOutputField_bellOptions$key = {
  readonly " $data"?: BellOptionsBellOutputField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsBellOutputField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsBellOutputField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bellOutput",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "1d5fa98ecf2cfc468fd18ff27e69d08e";

export default node;
