/**
 * @generated SignedSource<<9fed0ff8d1f5562608a200268d6522e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelAreaInformationProgrammingConceptFormInline_area$data = {
  readonly __typename: string;
  readonly autoArmEnabled: boolean;
  readonly autoDisarmEnabled: boolean;
  readonly badZonesOption: BadZonesOption;
  readonly hasChanges: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: string;
  readonly " $fragmentType": "TakeoverPanelAreaInformationProgrammingConceptFormInline_area";
};
export type TakeoverPanelAreaInformationProgrammingConceptFormInline_area$key = {
  readonly " $data"?: TakeoverPanelAreaInformationProgrammingConceptFormInline_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelAreaInformationProgrammingConceptFormInline_area">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelAreaInformationProgrammingConceptFormInline_area"
};

(node as any).hash = "40352f30aa68643f4f09c45f8dd3b949";

export default node;
