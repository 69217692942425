import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsPassphraseField_networkOptions$key } from "./__generated__/NetworkOptionsPassphraseField_networkOptions.graphql";

export const networkOptionsPassphraseFieldId = () =>
  "network-options-passphrase";

function NetworkOptionsPassphraseField() {
  const [{ passphrase }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsPassphraseField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsPassphraseField_networkOptions on NetworkOptions {
          passphrase
        }
      `
    );

  const fieldId = networkOptionsPassphraseFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Encryption Passphrase"
    >
      <TextInput
        id={fieldId}
        maxLength={16}
        value={passphrase}
        pattern={"([A-Z0-9 .*#\\-]{8,16}|)"}
        inlineHelp="8-16 Characters A-Z 0-9 .*#-"
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value.toUpperCase(), "passphrase");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsPassphraseField;
