import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  Device734CardOptions,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationUserCodeLengthField_deviceInformation$key } from "./__generated__/DeviceInformationUserCodeLengthField_deviceInformation.graphql";

export const deviceInformationUserCodeLengthFieldId = (number: string) =>
  `device-information-user-code-length-${number}`;

function DeviceInformationUserCodeLengthField() {
  const [{ id, number, device734, deviceType }] =
    useDeviceInformationFragment<DeviceInformationUserCodeLengthField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationUserCodeLengthField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            deviceType
            device734 {
              ... on Device734 {
                userCodeBitLength
                cardFormatOptions
              }
            }
          }
          ... on Xt75DeviceInformation {
            deviceType
            device734 {
              ... on Device734 {
                userCodeBitLength
                cardFormatOptions
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationUserCodeLengthFieldId(String(number));
  const disabled =
    device734?.cardFormatOptions !== Device734CardOptions.CUSTOM ||
    deviceType !== DeviceInformationType.DOOR;
  const relayEnv = useRelayEnvironment();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="User Code Length"
      tooltip="Length of the User Code within the card format."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        pattern="(1[6-9]|[2-5][0-9]|6[0-4])"
        validationMessage="Valid values are 16-64."
        value={device734?.userCodeBitLength ?? 16}
        inlineHelp={"16-64"}
        min={16}
        max={64}
        required
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                Number(target.value),
                "userCodeBitLength"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationUserCodeLengthField;
