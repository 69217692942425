import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsOccupiedPremisesField_systemOptions$key } from "./__generated__/SystemOptionsOccupiedPremisesField_systemOptions.graphql";

export const systemOptionsOccupiedPremisesFieldId = () =>
  "system-options-occupied-premises";

function OccupiedPremisesField() {
  const [{ occupiedPremises }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsOccupiedPremisesField_systemOptions$key>(
      graphql`
        fragment SystemOptionsOccupiedPremisesField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            occupiedPremises
          }
          ... on XtSystemOptions {
            occupiedPremises
          }
          ... on Xt75SystemOptions {
            occupiedPremises
          }
        }
      `
    );

  const fieldId = systemOptionsOccupiedPremisesFieldId();
  const label = "Occupied Premises";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="If enabled, automatically disarms interior areas for A/P and H/S/A Systems when Armed All or Away and no Perimeter Zone is tripped during Exit."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={occupiedPremises}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!occupiedPremises, "occupiedPremises");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OccupiedPremisesField;
