import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { getClosestOutputValue } from "../OutputOptionsFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { resolveZoneFieldRules, TwentyFourHourZoneTypes } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationDisarmedOpenOutputNumberField_zone$key } from "./__generated__/ZoneInformationDisarmedOpenOutputNumberField_zone.graphql";

export const zoneInformationDisarmedOpenOutputNumberFieldId = (
  number: string
) => `zone-information-disarmed-open-output-number-${number}`;

function ZoneInformationDisarmedOpenOutputNumberField() {
  const [{ number, disarmedOpenOutputNumber, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationDisarmedOpenOutputNumberField_zone$key>(
      graphql`
        fragment ZoneInformationDisarmedOpenOutputNumberField_zone on Zone {
          number
          type
          disarmedOpenOutputNumber
        }
      `
    );

  const fieldId = zoneInformationDisarmedOpenOutputNumberFieldId(
    String(number)
  );
  const hardwareModel = useHardwareModel();
  const disabled = [
    ...TwentyFourHourZoneTypes,
    ZoneType.ARMING,
    ZoneType.TAMPER,
  ].includes(type as ZoneType);
  const { OUTPUT_NUMBER: RULES } = resolveZoneFieldRules(hardwareModel);
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const originalValue = React.useRef(disarmedOpenOutputNumber).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Disarmed Open Output"
      tooltip="Output turns on with the programmed cadence when this Zone is in an open condition while the Area is Disarmed.Turns off when Area is Disarmed next."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={disarmedOpenOutputNumber ?? ""}
        pattern={RULES.PATTERN}
        inlineHelp={RULES.INLINE_HELP}
        validationMessage={RULES.VALIDATION_MSG}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "disarmedOpenOutputNumber");
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                originalValue ?? "",
                hardwareModel,
                validNumericalRange
              ),
              "disarmedOpenOutputNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationDisarmedOpenOutputNumberField;
