/**
 * @ngdoc service
 * @name EdisonServices.factory:xtFavoriteScheduleModel
 * @function
 * @requires $q
 * @requires XTFavoriteSchedulesAPI
 * @requires UserService
 * @requires JobService
 *
 * @description
 * Service to hold XT Favorite Schedule Models.
 */
App.factory("xtFavoriteScheduleModel", [
  "$q",
  "UserService",
  "JobService",
  "$filter",
  "baseScheduleModel",
  function ($q, UserService, JobService, $filter, baseScheduleModel) {
    var xtFavoriteScheduleModel = function (time_schedule) {
      angular.extend(this, {
        isBusy: false, // Indicates if a long running API job is currently active on the current model
        panelFamily: "XT30",
        beginFieldName: "_a_time",

        /**
         * @ngdoc object
         * @name method:initSchedule
         * @methodOf EdisonServices.factory:xtFavoriteScheduleModel
         *
         * @description
         * Setup the default values for a current Favorite Schedule
         *
         */
        initSchedule: function () {
          this.beginColumn = this.beginFieldName;

          for (var i = 0; i < this.days.length; i++) {
            // Set the full field names
            this.days[i].beginField = this.days[i].prefix + this.beginColumn;
          }

          // Initialize days (Inherited from base schedule model)
          this.initDays("beginField", "begin_date");
        },

        /**
         * @ngdoc object
         * @name method:delete
         * @methodOf App.factory:xtArmingScheduleModel
         *
         * @description
         * Clear out all time fields since an XT arming schedule cannot be deleted
         */
        clear: function () {
          this.clearTimeFields(); // Inherited from baseScheduleModel
        },

        /**
         * @ngdoc object
         * @name method:extendMe
         * @methodOf App.factory:xtArmingScheduleModel
         *
         * @description
         * Extend the time schedule object with the sent data
         */
        extendMe: function (scheduleData) {
          angular.extend(this, scheduleData);
        },
      });

      this.extendMe(new baseScheduleModel()); // Extend the base schedule model
      if (time_schedule !== null && angular.isDefined(time_schedule)) {
        this.extendMe(time_schedule);
      }

      this.initSchedule();
    };
    return xtFavoriteScheduleModel;
  },
]);
