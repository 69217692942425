import graphql from "babel-plugin-relay/macro";
import DmpIcon, { DmpIconName } from "common/components/DmpIcon";
import { media } from "common/utils/styles";
import React, { forwardRef, useState } from "react";
import { FormattedMessage, IntlShape } from "react-intl";
import { useFragment } from "react-relay";
import { SystemStatuses } from "securecom-graphql/client";
import styled, { css, keyframes, useTheme } from "styled-components/macro";
import { ArmingShieldIcon } from "../Icons";
import Space from "../Space";
import { themeGray } from "../Theme";
import PlainTooltip, { TooltipProps } from "../Tooltip/PlainTooltip";
import Widget from "../Widget";
import { StatusWidget_controlSystem$key } from "./__generated__/StatusWidget_controlSystem.graphql";
import messages from "./messages";

interface StatusWidgetProps {
  controlSystem: StatusWidget_controlSystem$key;
  isLoading: boolean;
}
const StatusWidget = forwardRef((props: StatusWidgetProps, ref) => {
  const { controlSystem, isLoading } = props;
  const { systemStatuses } = useFragment(
    graphql`
      fragment StatusWidget_controlSystem on ControlSystem {
        systemStatuses {
          hasAlarm
          hasBatteryTrouble
          hasSupervisoryAlert
          hasTrouble
          isAcNormal
        }
      }
    `,
    controlSystem
  );

  return (
    <Wrapper ref={ref}>
      <Widget icon={<ArmingShieldIcon />} title="Status" loading={isLoading}>
        <WidgetBody systemStatuses={systemStatuses} />
      </Widget>
    </Wrapper>
  );
});

export default StatusWidget;

interface WidgetBodyProps {
  systemStatuses: SystemStatuses | null;
}
const WidgetBody = (props: WidgetBodyProps) => {
  const { systemStatuses } = props;
  return (
    <Body>
      {systemStatuses ? (
        <>
          <AlarmSection hasAlarm={systemStatuses.hasAlarm} />
          <SystemStatusSection systemStatuses={systemStatuses} />
        </>
      ) : (
        <>
          <FormattedMessage
            key="status-failed-message"
            {...messages.failedToRetrieveSystemStatuses}
          />
          <Space huge /> <Space small />
        </>
      )}
    </Body>
  );
};

interface AlarmSectionProps {
  hasAlarm: boolean;
}
const AlarmSection = (props: AlarmSectionProps) => {
  const { hasAlarm } = props;
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  return (
    <AlarmSectionWrapper>
      <AlarmIndicatorBase
        hasAlarm={hasAlarm}
        onMouseOver={() => setTooltipIsOpen(true)}
        onMouseLeave={() => setTooltipIsOpen(false)}
      >
        <HelpText
          message={hasAlarm ? messages.hasAlarm : messages.noAlarm}
          tooltipOpen={tooltipIsOpen}
        />
        <AlarmIndicatorIcon hasAlarm={hasAlarm} />
      </AlarmIndicatorBase>
      <AlarmText hasAlarm={hasAlarm}>
        <FormattedMessage {...messages.alarm} />
      </AlarmText>
    </AlarmSectionWrapper>
  );
};

interface AlarmIndicatorIconProps {
  hasAlarm: boolean;
}
const AlarmIndicatorIcon = (props: AlarmIndicatorIconProps) => {
  const { hasAlarm } = props;
  const theme = useTheme();
  return (
    <DmpIcon
      icon="fire"
      color={hasAlarm ? theme.trueWhite : theme.grayMedium}
      constrainToParent={false}
      size="10rem"
    />
  );
};

enum SystemStatusNames {
  BatteryTrouble = "hasBatteryTrouble",
  Power = "isAcNormal",
  Supervisory = "hasSupervisoryAlert",
  Trouble = "hasTrouble",
}
interface SystemStatusSectionProps {
  systemStatuses: SystemStatuses;
}
const SystemStatusSection = (props: SystemStatusSectionProps) => {
  const { systemStatuses } = props;
  const statuses: SystemStatusNames[] = [
    SystemStatusNames.Power,
    SystemStatusNames.BatteryTrouble,
    SystemStatusNames.Trouble,
    SystemStatusNames.Supervisory,
  ];

  return (
    <SystemStatusSectionWrapper>
      {statuses.map((statusName) =>
        StatusBase(statusName, systemStatuses[statusName])
      )}
    </SystemStatusSectionWrapper>
  );
};

enum StatusColor {
  Blue = "#38b6e0",
  Gray = "#cccccc",
  Green = "#7cc75a",
  Orange = "#ffb224",
}
const StatusBase = (statusName: SystemStatusNames, status: boolean) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  let icon: DmpIconName;
  let statusColor: StatusColor;
  let tooltipMessage: IntlShape["messages"];

  switch (statusName) {
    case SystemStatusNames.Power:
      icon = "power";
      statusColor = StatusColor.Green;
      tooltipMessage = status ? messages.isAcNormal : messages.powerFailure;
      break;
    case SystemStatusNames.BatteryTrouble:
      icon = "power";
      statusColor = StatusColor.Green;
      tooltipMessage = status ? messages.batteryFailure : messages.batteryOK;
      break;
    case SystemStatusNames.Trouble:
      icon = "attention";
      statusColor = StatusColor.Orange;
      tooltipMessage = status ? messages.hasTrouble : messages.noTroubles;
      break;
    case SystemStatusNames.Supervisory:
      icon = "installer";
      statusColor = StatusColor.Blue;
      tooltipMessage = status
        ? messages.hasSupervisoryAlert
        : messages.noSupervisoryTrouble;
      break;
  }
  return (
    <StatusItemBase
      key={statusName}
      status={status}
      statusColor={statusColor}
      statusName={statusName}
      onMouseOver={() => setTooltipIsOpen(true)}
      onMouseLeave={() => setTooltipIsOpen(false)}
    >
      <DmpIcon
        constrainToParent={false}
        icon={icon}
        size="2.5rem"
        style={{ paddingRight: ".5rem" }}
      />
      <FormattedMessage {...messages[statusName]} />
      <HelpText message={tooltipMessage} tooltipOpen={tooltipIsOpen} />
    </StatusItemBase>
  );
};

interface HelpTextProps {
  tooltipOpen: boolean;
  message: IntlShape["messages"];
  theme?: TooltipProps["tooltipTheme"];
  style?: React.CSSProperties;
  position?: TooltipProps["position"];
}
export const HelpText = (props: HelpTextProps) => {
  const { tooltipOpen, message, theme, style, position } = props;

  return (
    <PlainTooltip
      isOpen={tooltipOpen}
      tooltipTheme={theme}
      position={position ? position : "top"}
      style={style}
    >
      <TooltipBody>
        <FormattedMessage {...message} />
      </TooltipBody>
    </PlainTooltip>
  );
};

const TooltipBody = styled.div`
  padding: 0.5rem;
  white-space: pre-line;
  font-weight: normal;
  font-size: small;
`;

const Wrapper = styled.div<any>`
  position: relative;
`;

const PADDING_X = css`
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.medium`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `};
`;

const Body = styled.div`
  ${PADDING_X};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: ${themeGray};
`;

const AlarmSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 10rem;
  margin-bottom: 1.5rem;
`;

const AlarmIndicatorBase = styled.div<{ hasAlarm: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 14rem;
  background-color: ${({ hasAlarm, theme }) =>
    hasAlarm ? theme.danger : theme.grayXlight};
  border: ${({ theme }) => `1px solid ${theme.primary}`};
  cursor: help;
  border-radius: 0.25rem;
`;

const AlarmText = styled.div<{ hasAlarm: boolean }>`
  margin: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ hasAlarm, theme }) =>
    hasAlarm ? theme.danger : theme.grayMedium};
`;

const SystemStatusSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, [col-start] 1fr);
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`;

const pulse = keyframes`
  0%, 100% {
      color: ${StatusColor.Green};
      border-color: ${StatusColor.Green};
      border: .5rem solid;
  }
  50% {
      border: .1rem solid;
      border-color: ${StatusColor.Gray};
      color: ${StatusColor.Gray};
  }
`;

const StatusItemBase = styled.div<{
  status: boolean;
  statusColor: string;
  statusName: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ status }) => (status ? ".4rem solid" : ".1rem solid")};
  height: 4rem;
  font-size: 1.5rem;
  background-color: ${({ theme }) => theme.grayXlight};
  cursor: help;
  color: ${({ status, statusColor, theme }) =>
    status ? statusColor : theme.grayAccent};
  border-color: ${({ status, statusColor, theme }) =>
    status ? statusColor : theme.grayAccent};
  animation-name: ${pulse};
  animation-duration: ${({ status, statusName }) =>
    statusName === SystemStatusNames.BatteryTrouble && status ? "1s" : null};
  animation-iteration-count: infinite;
  border-radius: 0.25rem;
`;
