import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorIpAddressField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorIpAddressField_remoteOptions.graphql";

export const remoteOptionsIntegratorIpAddressFieldId = () =>
  "remote-options-integrator-ip-address";

function RemoteOptionsIntegratorIpAddressField() {
  const [{ integratorIpAddress, integratorConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsIntegratorIpAddressField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsIntegratorIpAddressField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            integratorIpAddress
            integratorConnection
          }
          ... on XfRemoteOptions {
            integratorIpAddress
            integratorConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsIntegratorIpAddressFieldId();
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Integrator Incoming IP Address"
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={integratorIpAddress || ""}
        maxLength={16}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        validationMessage="Please enter a valid IP address"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "integratorIpAddress")
          );
        }}
        onBlur={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "integratorIpAddress"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorIpAddressField;
