import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsNetworkProgrammingPortField_remoteOptions$key } from "./__generated__/RemoteOptionsNetworkProgrammingPortField_remoteOptions.graphql";

export const remoteOptionsNetworkProgrammingPortFieldId = () =>
  "remote-options-network-programming-port";

function RemoteOptionsNetworkProgrammingPortField() {
  const [{ networkProgrammingPort, allowNetworkRemote }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsNetworkProgrammingPortField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsNetworkProgrammingPortField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            networkProgrammingPort
            allowNetworkRemote
          }
        }
      `
    );

  const fieldId = remoteOptionsNetworkProgrammingPortFieldId();
  const disabled = !allowNetworkRemote;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Network Programming Port"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={networkProgrammingPort || ""}
        maxLength={16}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        disabled={disabled}
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "networkProgrammingPort");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(1, 65535, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(String(value), "networkProgrammingPort");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsNetworkProgrammingPortField;
