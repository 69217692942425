import * as R from "ramda";
import * as React from "react";
import { TransitionMotion, spring } from "react-motion";

const springConfig = {
  stiffness: 360,
  damping: 28,
};

export type Page<A = any> = {
  id: string;
  key?: string;
  data?: A;
  render: (data: A) => JSX.Element | null;
};

const activeViewStyle = {
  opacity: spring(1),
  x: spring(0, springConfig),
  visible: 1,
};

const usePreviousValue = (value: string) => {
  const prevValue = React.useRef(value);
  const currentValue = React.useRef(value);

  if (currentValue.current !== value) {
    prevValue.current = currentValue.current;
    currentValue.current = value;
  }

  return prevValue.current;
};

const PageStackView = ({
  pages,
  activeId,
}: {
  pages: Page[];
  activeId: string;
}) => {
  const pagesByKey = R.indexBy(
    (v) => v.id,
    pages.map((page, index) => ({ ...page, index }))
  );

  const previousKey = usePreviousValue(activeId);

  const prevIndex = pagesByKey[previousKey]?.index;
  const currentIndex = pagesByKey[activeId]?.index;
  const shouldSlideLeft = currentIndex < prevIndex;

  return (
    <TransitionMotion
      willEnter={() => ({
        opacity: 0,
        x: shouldSlideLeft ? -15 : 15,
        visible: 1,
      })}
      willLeave={() => ({
        opacity: spring(0),
        x: spring(shouldSlideLeft ? 15 : -15, springConfig),
        visible: 0,
      })}
      styles={[
        {
          key: `${pagesByKey[activeId]?.id}${pagesByKey[activeId]?.key}`,
          data: {
            ...pagesByKey[activeId]?.data,
            __PAGE_ID__: pagesByKey[activeId]?.id,
          },
          style: activeViewStyle,
        },
      ]}
    >
      {(interpolatedStyle) => (
        <>
          {interpolatedStyle.map(({ key, style, data }) => {
            const { __PAGE_ID__, ...rest } = data;
            return (
              <div
                key={key}
                style={{
                  position:
                    style.visible && style.x === 0 ? "static" : "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: style.opacity,
                  transform: style.x !== 0 ? `translateX(${style.x}%)` : "none",
                  pointerEvents: !style.visible ? "none" : "auto",
                }}
              >
                {pagesByKey[__PAGE_ID__].render &&
                  pagesByKey[__PAGE_ID__].render(rest)}
              </div>
            );
          })}
        </>
      )}
    </TransitionMotion>
  );
};

export default PageStackView;
