/**
 * @generated SignedSource<<00fb32418d1ea9b99d0a32c296c08cd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoFormHikvisionNvrsRefetchQuery$variables = {
  id: string;
};
export type VideoFormHikvisionNvrsRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"HikvisionNvrs_site">;
  } | null;
};
export type VideoFormHikvisionNvrsRefetchQuery = {
  response: VideoFormHikvisionNvrsRefetchQuery$data;
  variables: VideoFormHikvisionNvrsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoFormHikvisionNvrsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HikvisionNvrs_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoFormHikvisionNvrsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billingControlSystemId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HikvisionNvr",
                "kind": "LinkedField",
                "name": "hikvisionNvrs",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "host",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "streamingPort",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "managementPort",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serialNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "model",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HikvisionNvrVideoChannel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33ff8608773336c86bff865749b0268f",
    "id": null,
    "metadata": {},
    "name": "VideoFormHikvisionNvrsRefetchQuery",
    "operationKind": "query",
    "text": "query VideoFormHikvisionNvrsRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...HikvisionNvrs_site\n    id\n  }\n}\n\nfragment HikvisionNvrsNvr_site on Site {\n  billingControlSystemId\n}\n\nfragment HikvisionNvrs_hikvisionNvr on HikvisionNvr {\n  id\n  name\n  host\n  streamingPort\n  managementPort\n  status\n  serialNumber\n  model\n  channels {\n    id\n    name\n    number\n  }\n}\n\nfragment HikvisionNvrs_site on Site {\n  ...HikvisionNvrsNvr_site\n  billingControlSystemId\n  hikvisionNvrs {\n    id\n    ...HikvisionNvrs_hikvisionNvr\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "a8cb0044c51f233541a678b61efc07f5";

export default node;
