import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import {
  EditingFrom,
  useTemplateContext,
} from "components/FullProgramming/common/TemplateContext";
import { useStateDotGo } from "components/SystemDiagnostics/AngularStateProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { useFragment, useMutation } from "react-relay";
import {
  asID,
  CommunicationComType,
  fromDealerId,
} from "securecom-graphql/client";
import { getInput as getAreaInformationInput } from "../Data/XRAreaInformationTemplateData";
import { getInput as getBellOptionsInput } from "../Data/XRBellOptionsTemplateData";
import { getInput as getCardFormatsInput } from "../Data/XRCardFormatsTemplateData";
import { getInput as getCommunicationPathInput } from "../Data/XRCommunicationPathTemplateData";
import { getInput as getDeviceInformationsInput } from "../Data/XRDeviceInformationTemplateData";
import { getInput as getKeyfobsInput } from "../Data/XRKeyfobsTemplateData";
import { getInput as getLockoutCodeInput } from "../Data/XRLockoutCodeTemplateData";
import { getInput as getMenuDisplayInput } from "../Data/XRMenuDisplayTemplateData";
import { getInput as getNetworkOptionsInput } from "../Data/XRNetworkOptionsTemplateData";
import { getInput as getOutputGroupsInput } from "../Data/XROutputGroupsTemplateData";
import { getInput as getOutputInformationsInput } from "../Data/XROutputInformationTemplateData";
import { getInput as getOutputOptionsInput } from "../Data/XROutputOptionsTemplateData";
import { getInput as getPcLogReportsOptionsInput } from "../Data/XRPcLogReportsTemplateData";
import { getInput as getRemoteOptionsInput } from "../Data/XRRemoteOptionsTemplateData";
import { getInput as getSecurityGradeInput } from "../Data/XRSecurityGradeTemplateData";
import { getInput as getStatusListDisplayInput } from "../Data/XRStatusListDisplayTemplateData";
import { getInput as getSystemAreaInformationInput } from "../Data/XRSystemAreaInformationTemplateData";
import { getInput as getSystemOptionsInput } from "../Data/XRSystemOptionsTemplateData";
import { getInput as getSystemReportsInput } from "../Data/XRSystemReportsTemplateData";
import { getInput as getZoneInformationInput } from "../Data/XRZoneInformationTemplateData";
import { xrTemplateSaveMutation } from "./XRTemplateSaveMutation";
import { XRTemplateSaveHook_controlSystem$key } from "./__generated__/XRTemplateSaveHook_controlSystem.graphql";
import { XRTemplateSaveMutation } from "./__generated__/XRTemplateSaveMutation.graphql";

export const useXRTemplateSaveMutation = (
  controlSystem: XRTemplateSaveHook_controlSystem$key
): [
  (
    includedFields: IncludedTemplateFieldsType,
    templateName: string,
    customerId: string,
    dealerId: string
  ) => void,
  boolean
] => {
  const [saveXrTemplate, isSavingXRTemplate] =
    useMutation<XRTemplateSaveMutation>(xrTemplateSaveMutation);

  const data = useFragment(
    graphql`
      fragment XRTemplateSaveHook_controlSystem on ControlSystem {
        panel {
          hardwareModel
        }
        ...XRCommunicationProgrammingConceptFormInline_controlSystem
        ...XRNetworkOptionsProgrammingConceptFormInline_controlSystem
        ...XRDeviceSetupProgrammingConceptFormInline_controlSystem
        ...XRCardFormatsProgrammingConceptFormInline_controlSystem
        ...XRRemoteOptionsProgrammingConceptFormInline_controlSystem
        ...XRSystemReportsProgrammingConceptFormInline_controlSystem
        ...XRSystemOptionsProgrammingConceptFormInline_controlSystem
        ...XRBellOptionsProgrammingConceptFormInline_controlSystem
        ...XROutputInformationProgrammingConceptFormInline_controlSystem
        ...XROutputOptionsProgrammingConceptFormInline_controlSystem
        ...XROutputGroupsProgrammingConceptFormInline_controlSystem
        ...XRMenuDisplayProgrammingConceptFormInline_controlSystem
        ...XRStatusListDisplayProgrammingConceptFormInline_controlSystem
        ...XRAreaInformationProgrammingConceptFormInline_controlSystem
        ...XRZoneInformationProgrammingConceptFormInline_controlSystem
        ...XRKeyFobsProgrammingConceptFormInline_controlSystem
        ...XRLockoutCodeProgrammingConceptFormInline_controlSystem
        ...XRPcLogReportsProgrammingConceptForm_controlSystem
        ...XRFeatureKeysProgrammingConceptFormInline_controlSystem
        ...XRMessagingSetupProgrammingConceptFormInline_controlSystem
        ...XRSecurityGradeProgrammingConceptFormInline_controlSystem
        ...XRPcLogReportsProgrammingConceptFormInline_controlSystem
      }
    `,
    controlSystem
  );

  const templateData = useTemplateContext();
  const showAlert = useShowAlert();
  const nav = useStateDotGo();

  return [
    (
      includedFields: IncludedTemplateFieldsType,
      templateName: string,
      customerId: string,
      dealerId: string
    ) => {
      saveXrTemplate({
        variables: {
          dealerId: dealerId,
          templateData: {
            id: templateData.templateId ? templateData.templateId : null,
            name: templateName,
            hardwareModel: templateData.hardwareModel,
            dealerId: dealerId,
            receiverCommType: CommunicationComType.NONE,
            customerId: customerId,
            concepts: {
              areaInformations: getAreaInformationInput(data, includedFields),
              bellOptions: getBellOptionsInput(data, includedFields),
              cardFormats: getCardFormatsInput(data, includedFields),
              communication: getCommunicationPathInput(data, includedFields),
              deviceInformations: getDeviceInformationsInput(
                data,
                includedFields
              ),
              keyfobs: getKeyfobsInput(data, includedFields),
              lockoutCode: getLockoutCodeInput(data, includedFields),
              menuDisplay: getMenuDisplayInput(data, includedFields),
              networkOptions: getNetworkOptionsInput(data, includedFields),
              outputGroups: getOutputGroupsInput(data, includedFields),
              outputInformations: getOutputInformationsInput(
                data,
                includedFields
              ),
              outputOptions: getOutputOptionsInput(data, includedFields),
              pcLogReports: getPcLogReportsOptionsInput(data, includedFields),
              remoteOptions: getRemoteOptionsInput(data, includedFields),
              securityGrade: getSecurityGradeInput(data, includedFields),
              statusListDisplay: getStatusListDisplayInput(
                data,
                includedFields
              ),
              systemAreaInformation: getSystemAreaInformationInput(
                data,
                includedFields
              ),
              systemOptions: getSystemOptionsInput(data, includedFields),
              systemReports: getSystemReportsInput(data, includedFields),
              zoneInformations: getZoneInformationInput(data, includedFields),
            },
          },
          isInternational: templateData.isInternational,
        },
        onCompleted: (response) => {
          if (
            response.saveXrProgrammingTemplate?.__typename ===
            "SaveProgrammingTemplateSuccessResult"
          ) {
            showAlert({
              type: "success",
              text: "Successfully saved template.",
            });
            if (templateData.editingFrom === EditingFrom.NEW_TEMPLATE_PAGE) {
              nav(
                "app.dealer.default_programming",
                {
                  dealer_id: fromDealerId(asID(dealerId)).dealerId,
                },
                { reload: true }
              );
            } else if (
              templateData.editingFrom === EditingFrom.PROGRAMMING_PAGE
            ) {
              templateData.toggleIsEditing();
              nav("app.panel_programming.programming", {}, { reload: true });
            }
          } else {
            if (
              response.saveXrProgrammingTemplate?.__typename ===
              "SaveProgrammingTemplateErrorResult"
            ) {
              showAlert({
                type: "error",
                text: `Error saving template`,
              });
            }
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: `Error saving template`,
          });
        },
      });
    },
    isSavingXRTemplate,
  ];
};
