/**
 * @generated SignedSource<<5e864c07678e958c105d8b614f878851>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VarDiagnosticsRootVARHubQuery$variables = {
  systemId: string;
};
export type VarDiagnosticsRootVARHubQuery$data = {
  readonly node: {
    readonly __typename: string;
    readonly id: string;
    readonly varHubs?: ReadonlyArray<{
      readonly currentDiagnostics: {
        readonly data: string | null;
      } | null;
      readonly macAddress: string;
      readonly recentDiagnostics: {
        readonly data: string | null;
      } | null;
    }>;
  } | null;
};
export type VarDiagnosticsRootVARHubQuery = {
  response: VarDiagnosticsRootVARHubQuery$data;
  variables: VarDiagnosticsRootVARHubQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "systemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "data",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "VarHubCurrentDiagnostics",
  "kind": "LinkedField",
  "name": "currentDiagnostics",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "VarHubRecentDiagnostics",
  "kind": "LinkedField",
  "name": "recentDiagnostics",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VarDiagnosticsRootVARHubQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "varHubs",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VarDiagnosticsRootVARHubQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarHub",
                "kind": "LinkedField",
                "name": "varHubs",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91c50298caca9708abb4e2e005ae609e",
    "id": null,
    "metadata": {},
    "name": "VarDiagnosticsRootVARHubQuery",
    "operationKind": "query",
    "text": "query VarDiagnosticsRootVARHubQuery(\n  $systemId: ID!\n) {\n  node(id: $systemId) {\n    id\n    __typename\n    ... on ControlSystem {\n      id\n      varHubs {\n        macAddress\n        currentDiagnostics {\n          data\n        }\n        recentDiagnostics {\n          data\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "90d5681814272724d5f622df2dfcd23b";

export default node;
