import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { systemOptionsCelsiusFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCelsiusField";
import { systemOptionsCIDFormatFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCIDFormatField";
import { systemOptionsCrossZoneTimeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import { systemOptionsEcpPartitionFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEcpPartitionField";
import { systemOptionsEntryDelay1FieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import { systemOptionsExitDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsExitDelayField";
import { systemOptionsHoursFromGMTFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import { systemOptionsKeypadInputFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsKeypadInputField";
import { systemOptionsPowerFailDelayFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import { systemOptionsResetSwingerBypassFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import { systemOptionsSwingerBypassTripsFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import { systemOptionsTimeChangeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import { systemOptionsWeatherZipCodeFieldId } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import { TakeoverPanelSystemOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/TakeoverPanelFullProgramming/__generated__/TakeoverPanelSystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSystemOptionsState } from "../../../TakeoverPanelFullProgramming/TakeoverPanelSystemOptionsProgrammingConceptForm";
import { XtProgrammingTemplateSystemOptionsInput } from "../Create/__generated__/TakeoverTemplateSaveMutation.graphql";
import { TakeoverSystemOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TakeoverSystemOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts.graphql";

export const getState = (
  template: TakeoverSystemOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverSystemOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        systemOptions {
          id
          included
          systemType {
            included
            data
          }
          closingCode {
            included
            data
          }
          closingCheck {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          entryDelay2 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          zoneActivityHours {
            included
            data
          }
          armDisarmActivityDays {
            included
            data
          }
          timeChange {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          displayTime {
            included
            data
          }
          houseCode {
            included
            data
          }
          detectJam {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          useBuiltIn1100Wireless {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          occupiedPremises {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          iso2CountryCode {
            included
            data
          }
          weatherPostalCode {
            included
            data
          }
          primaryProgrammingLanguage {
            included
            data
          }
          secondaryProgrammingLanguage {
            included
            data
          }
          primaryUserLanguage {
            included
            data
          }
          secondaryUserLanguage {
            included
            data
          }
          wirelessEncryption1100 {
            included
            data
          }
          wirelessEncryption1100Passphrase {
            included
            data
          }
          eolResistorValue {
            included
            data
          }
          celsius {
            included
            data
          }
          exitDelay {
            included
            data
          }
          ecpPartition {
            included
            data
          }
          keypadInput {
            included
            data
          }
          captureFormat {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: TakeoverPanelSystemOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateSystemOptionsInput => {
  const {
    panel: { systemOptions },
  } = getSystemOptionsState(controlSystem);

  const systemOptionsInputFields = {
    entryDelay1: {
      included: includedFields.has(systemOptionsEntryDelay1FieldId()),
      data: systemOptions.entryDelay1,
    },
    crossZoneTime: {
      included: includedFields.has(systemOptionsCrossZoneTimeFieldId()),
      data: systemOptions.crossZoneTime,
    },
    powerFailDelay: {
      included: includedFields.has(systemOptionsPowerFailDelayFieldId()),
      data: systemOptions.powerFailDelay,
    },
    weatherZipCode: {
      included: includedFields.has(systemOptionsWeatherZipCodeFieldId()),
      data: systemOptions.weatherZipCode,
    },
    swingerBypassTrips: {
      included: includedFields.has(systemOptionsSwingerBypassTripsFieldId()),
      data: systemOptions.swingerBypassTrips,
    },
    resetSwingerBypass: {
      included: includedFields.has(systemOptionsResetSwingerBypassFieldId()),
      data: systemOptions.resetSwingerBypass,
    },
    timeChange: {
      included: includedFields.has(systemOptionsTimeChangeFieldId()),
      data: systemOptions.timeChange,
    },
    hoursFromGMT: {
      included: includedFields.has(systemOptionsHoursFromGMTFieldId()),
      data: systemOptions.hoursFromGMT,
    },
    celsius: {
      included: includedFields.has(systemOptionsCelsiusFieldId()),
      data: systemOptions.celsius,
    },
    exitDelay: {
      included: includedFields.has(systemOptionsExitDelayFieldId()),
      data: systemOptions.exitDelay,
    },
    ecpPartition: {
      included: includedFields.has(systemOptionsEcpPartitionFieldId()),
      data: systemOptions.ecpPartition,
    },
    keypadInput: {
      included: includedFields.has(systemOptionsKeypadInputFieldId()),
      data: systemOptions.keypadInput,
    },
    captureFormat: {
      included: includedFields.has(systemOptionsCIDFormatFieldId()),
      data: systemOptions.captureFormat,
    },
  };

  const systemOptionsIncluded = Object.values(systemOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: systemOptionsIncluded,
    ...systemOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: TakeoverSystemOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemOptions } = getState(template);

  if (!systemOptions) return;

  if (systemOptions.entryDelay1?.included) {
    setIncludedFields(setToggle(systemOptionsEntryDelay1FieldId()));
  }
  if (systemOptions.crossZoneTime?.included) {
    setIncludedFields(setToggle(systemOptionsCrossZoneTimeFieldId()));
  }
  if (systemOptions.powerFailDelay?.included) {
    setIncludedFields(setToggle(systemOptionsPowerFailDelayFieldId()));
  }
  if (systemOptions.swingerBypassTrips?.included) {
    setIncludedFields(setToggle(systemOptionsSwingerBypassTripsFieldId()));
  }
  if (systemOptions.resetSwingerBypass?.included) {
    setIncludedFields(setToggle(systemOptionsResetSwingerBypassFieldId()));
  }
  if (systemOptions.timeChange?.included) {
    setIncludedFields(setToggle(systemOptionsTimeChangeFieldId()));
  }
  if (systemOptions.hoursFromGMT?.included) {
    setIncludedFields(setToggle(systemOptionsHoursFromGMTFieldId()));
  }
  if (systemOptions.weatherZipCode?.included) {
    setIncludedFields(setToggle(systemOptionsWeatherZipCodeFieldId()));
  }
  if (systemOptions.celsius?.included) {
    setIncludedFields(setToggle(systemOptionsCelsiusFieldId()));
  }
  if (systemOptions.exitDelay?.included) {
    setIncludedFields(setToggle(systemOptionsExitDelayFieldId()));
  }
  if (systemOptions.ecpPartition?.included) {
    setIncludedFields(setToggle(systemOptionsEcpPartitionFieldId()));
  }
  if (systemOptions.keypadInput?.included) {
    setIncludedFields(setToggle(systemOptionsKeypadInputFieldId()));
  }
  if (systemOptions.captureFormat?.included) {
    setIncludedFields(setToggle(systemOptionsCIDFormatFieldId()));
  }
};
