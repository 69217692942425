App.controller("ArmingCtrl", [
  "$scope",
  "$rootScope",
  "$stateParams",
  "UserService",
  "ArmingService",
  "PROPS",
  "Panel",
  function (
    $scope,
    $rootScope,
    $stateParams,
    UserService,
    ArmingService,
    PROPS,
    Panel
  ) {
    $scope.controlSystemId = $stateParams.control_system_id;
    $scope.controlSystemName = UserService.controlSystem.name;
    $scope.dealer_id = UserService.dealer_id;

    $scope.pendingAreas = "";
    $scope.badZones = false;
    $scope.badZonesList = [];
    $scope.systemArmed = false;
    $scope.armedState = "";
    $scope.allAreas = "";
    $scope.isBusy = false;
    $scope.notificationString = "";
    $scope.areaSearchFilter = "";
    $scope.disableArmAll = false;
    $scope.disableDisarmAll = false;
    $scope.unknownStatus = false;
    $scope.hasGottenInitialArmedStatus = false;
    $scope.isEcpOrDsc = false;

    if (
      ["CellComSL", "CellComEX", "iComSL", "DualCom"].includes(
        UserService.controlSystem.panels[0].hardware_model
      )
    ) {
      const panel = new Panel(UserService.panel_id);
      panel.get("system_options").then(function (options) {
        $scope.isEcpOrDsc =
          options.kpad_input === "E" || options.kpad_input === "D";
      });
    }

    $scope.updateNotificationString = (mssg) => {
      $scope.notificationString = mssg;
    };

    $scope.updateNotificationStringMouseleave = (mssg) => {
      $scope.notificationString = mssg;
    };

    /**
     * Arm / disarm an individual area
     */
    $scope.toggleArmedStateArea = (area, action) => {
      area.isBusy = true;

      if (area.armed_status === "armed") {
        $scope.disarmSystem(area.number.toString());
      } else {
        $scope.armSystem(area.number.toString(), action);
      }
    };

    /**
     * Update the busy state for all areas when we arm/disarm all
     */
    function updateBusyStatusesForAllAreas(type) {
      for (let area of $scope.areaStatuses) {
        if (area.armed_status != type) {
          area.isBusy = true;
        }
      }
    }

    function clearBusyStatusesForAllAreas() {
      for (let area of $scope.areaStatuses) {
        area.isBusy = false;
      }
    }

    /**
     * Arm the system
     */
    $scope.armSystem = function (areasToArm, badZones, armAllArea = false) {
      $scope.pendingAreas = areasToArm; // We need to keep a record of what areas we were trying to arm incase there is a bad zone and we have to retry
      $scope.isBusy = true;
      $scope.notificationString = "Arming...";

      if (armAllArea) updateBusyStatusesForAllAreas("armed");

      ArmingService.armSystem({
        system_id: $scope.controlSystemId,
        areas: areasToArm,
        bad_zones: badZones,
      })
        .then(
          function (success) {
            $scope.clearPendingInfo();
            getArmedStatus();
            $scope.isBusy = false;
            $scope.notificationString = "The system is currently armed.";
          },
          function (error) {
            if (error.job && error.job.details.code === "104") {
              $scope.badZones = true;
              $scope.badZonesList = error.job.details.bad_zones;
            } else {
              $rootScope.alerts.push({
                type: "error",
                text: "Could Not Arm The System",
              });
              $scope.isBusy = false;
              $scope.clearPendingInfo();
            }
          }
        )
        .catch(function (error) {});
    };

    /**
     * Disarm the system
     */
    $scope.disarmSystem = function (areas, fromDisarmAll) {
      $scope.isBusy = true;

      if (fromDisarmAll) updateBusyStatusesForAllAreas("disarmed");

      if (areas) {
        $scope.notificationString = "Disarming...";
        ArmingService.disarmSystem({
          system_id: $scope.controlSystemId,
          areas: areas.toString(),
        }).then(
          function (success) {
            getArmedStatus();
            $scope.isBusy = false;
            $scope.clearPendingInfo();
            $scope.notificationString = "The system is currently disarmed.";
          },
          function (error) {
            console.error(error);
            getArmedStatus();
            clearBusyStatusesForAllAreas();
            $scope.clearPendingInfo();
            $rootScope.alerts.push({
              type: "error",
              text: "Could Not Disarm The System",
            });
            $scope.isBusy = false;
          }
        );
      } else {
        ArmingService.disarmSystem({ system_id: $scope.controlSystemId }).then(
          function (success) {
            // getArmedStatus()
            $scope.armedState = "disarmed";
            $scope.systemArmed = false;
            $scope.notificationString = "The system is currently disarmed.";
            $scope.isBusy = false;
            $scope.clearPendingInfo();
            setTimeout(getArmedStatus, 5000); // BANDAID - sometimes when we get a successful disarm for a non-area system the armed status call still thinks that the panel is armed, so we're waiting 5 seconds to ask for the armed status
            // $scope.isBusy = false;
            // $scope.notificationString = 'Disarmed';
          },
          function (error) {
            getArmedStatus();
            $scope.clearPendingInfo();
            $rootScope.alerts.push({
              type: "error",
              text: "Could Not Disarm The System",
            });
            $scope.isBusy = false;
          }
        );
      }
    };

    /**
     * Clear the pending info because the arm was successful
     */
    $scope.clearPendingInfo = function () {
      $scope.badZones = false;
      $scope.badZonesList = [];
      $scope.pendingAreas = "";
      $scope.isBusy = false;
      if (
        UserService.controlSystem.panels[0].arming_system === "AREA" ||
        UserService.controlSystem.panels[0].arming_system === "HSAWG"
      )
        clearBusyStatusesForAllAreas();
    };

    /**
     * Get the current armed status of the panel.
     * This is called after every interaction and on page load
     */
    function getArmedStatus(useCache = true, isInitialLoad = false) {
      ArmingService.getArmedStatus(
        { system_id: $scope.controlSystemId },
        useCache
      )
        .then(
          function (status) {
            $scope.hasGottenInitialArmedStatus = true;
            $scope.isBusy = false;

            if (status.response.armed_areas !== null) {
              $scope.systemArmed = true;
              $scope.notificationString = "The system is currently armed.";
            } else {
              $scope.systemArmed = false;
              $scope.notificationString = "The system is currently disarmed.";
            }

            // Determine the arming type
            switch (status.response.armed_mode) {
              case "OFF":
                $scope.armedState = "disarmed";
                break;

              case "HOME":
                $scope.armedState = "armed_home";
                break;

              case "SLEEP":
                $scope.armedState = "armed_sleep";
                break;

              case "AWAY":
                $scope.armedState = "armed_away";
                break;

              case "ALL":
                $scope.armedState = "armed_all";
                break;

              case "PERIMETER":
                $scope.armedState = "armed_perimiter";
                break;

              case "ARMED":
                $scope.armedState = "armed_areas";
                break;

              default:
                break;
            }

            // We need to have a list of all areas for an area system for arm / disarm all.
            // Each area also needs to have a status and busy state.
            if (
              status.response.arming_system === "AREA" ||
              status.response.arming_system === "HSAWG"
            ) {
              let areas = "";
              for (let i = 0; i < status.response.area_statuses.length; i++) {
                status.response.area_statuses[i].isArmed =
                  status.response.area_statuses[i].armed_status === "armed"
                    ? true
                    : false;
                if (i === status.response.area_statuses.length - 1) {
                  areas = areas + `${status.response.area_statuses[i].number}`;
                } else {
                  areas = areas + `${status.response.area_statuses[i].number},`;
                }
                status.response.area_statuses[i].isBusy = false;
              }
              $scope.allAreas = areas;
              $scope.areaStatuses = status.response.area_statuses;

              // If the armed areas are identical to total areas, we disable the arm all button (they're all armed already). Likewise, if no areas are armed we should disable the disarm all button. Otherwise, enable them both
              if (status.response.armed_areas == areas) {
                $scope.disableArmAll = true;
                $scope.disableDisarmAll = false;
              } else if (status.response.armed_areas == null) {
                $scope.disableDisarmAll = true;
                $scope.disableArmAll = false;
              } else {
                $scope.disableDisarmAll = false;
                $scope.disableArmAll = false;
              }
            }
          },
          function (error) {
            if (error.status == 404 && isInitialLoad) {
              getArmedStatus(false, false);
            } else {
              $scope.unknownStatus = true;
              $scope.isBusy = false;
              $rootScope.alerts.push({
                type: "error",
                text: "Could Not Get Armed Status",
              });
              $scope.hasGottenInitialArmedStatus = true;
            }
          },
          function (notify) {}
        )
        .catch(function (error) {});
    }

    function init() {
      $scope.isBusy = true;
      getArmedStatus(true, true);
    }

    init();
  },
]);
