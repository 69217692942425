/**
 * Find the nearest technician to a panel
 */
App.factory("NearestTechService", [
  "$q",
  "UserService",
  "NearestTechAPI",
  function ($q, UserService, NearestTechAPI) {
    return {
      retrieveNearestTech: function () {
        var deferred = $q.defer();
        NearestTechAPI.getTechs(
          {
            panel_id: UserService.panel_id,
            dealer_id: UserService.dealer_id,
          },
          function (technician) {
            deferred.resolve(technician);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "There was an error retriving nearest technician",
            });
          }
        );

        return deferred.promise;
      },
    };
  },
]);
