import graphql from "babel-plugin-relay/macro";
import { Flex, Table, Tooltip } from "components/DaStyledElements";
import Modal from "components/Modal";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import { DiagnosticModalQuery } from "./__generated__/DiagnosticModalQuery.graphql";

const TableDataToolTip = styled.td`
  overflow: visible !important;
  white-space: break-spaces !important;
`;

function DiagnosticModal({
  setModalOpen,
  panelId,
}: {
  setModalOpen: (modalOpen: boolean) => void;
  panelId: string;
}) {
  const data = useLazyLoadQuery<DiagnosticModalQuery>(
    graphql`
      query DiagnosticModalQuery($id: String!) {
        lxBusDiagnostic(id: $id) {
          lx_diagnostic {
            status
            number
          }
        }
      }
    `,
    { id: panelId },
    { fetchPolicy: "network-only" }
  );

  return (
    <Modal className="" size="large" onClickOutside={() => setModalOpen(false)}>
      <Modal.Header>
        <span className="h1">LX Bus Diagnostics</span>
      </Modal.Header>
      <Modal.Body className="modal-grid__padding-bottom">
        <Table>
          <thead>
            <tr>
              <td>Number</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {data.lxBusDiagnostic.map((item) => (
              <tr>
                <td>{item?.lx_diagnostic?.number}</td>

                <TableDataToolTip>
                  {item?.lx_diagnostic?.status === "missing" && (
                    <Tooltip
                      content="The zone expander is not responding to polling from the panel."
                      style={{ "max-width": "max-content" }}
                    >
                      Missing
                    </Tooltip>
                  )}

                  {item?.lx_diagnostic?.status === "extra" && (
                    <Tooltip content="Zones are detected that have not been programmed.">
                      Extra
                    </Tooltip>
                  )}
                  {item?.lx_diagnostic?.status === "overlap" && (
                    <Tooltip content="Two or more zones are sharing the same bus address.">
                      Overlap
                    </Tooltip>
                  )}
                </TableDataToolTip>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Flex.Row align="right" justify="right">
          <button
            className="btn btn-default btn-md"
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </button>
        </Flex.Row>
      </Modal.Footer>
    </Modal>
  );
}

export default DiagnosticModal;
