/**
 * @generated SignedSource<<1b9e5bb5897d65fc6273c19b434b63f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75FullProgramming_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHasNetworkCommType_panel" | "PanelContextUseHasWifiCommType_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem" | "FullProgrammingForm_controlSystem" | "ProgrammingTemplateForm_controlSystem" | "XT75AreaInformationProgrammingConceptFormNavButton_controlSystem" | "XT75AreaInformationProgrammingConceptForm_controlSystem" | "XT75BellOptionsProgrammingConceptForm_controlSystem" | "XT75CardFormatsProgrammingConceptFormNavButton_controlSystem" | "XT75CardFormatsProgrammingConceptForm_controlSystem" | "XT75CommunicationProgrammingConceptFormNavButton_controlSystem" | "XT75CommunicationProgrammingConceptForm_controlSystem" | "XT75DeviceSetupProgrammingConceptFormNavButton_controlSystem" | "XT75DeviceSetupProgrammingConceptForm_controlSystem" | "XT75KeyFobsProgrammingConceptFormNavButton_controlSystem" | "XT75KeyFobsProgrammingConceptForm_controlSystem" | "XT75LockoutCodeProgrammingConceptForm_controlSystem" | "XT75NetworkOptionsProgrammingConceptForm_controlSystem" | "XT75OutputInformationProgrammingConceptFormNavButton_controlSystem" | "XT75OutputInformationProgrammingConceptForm_controlSystem" | "XT75OutputOptionsProgrammingConceptForm_controlSystem" | "XT75RemoteOptionsProgrammingConceptForm_controlSystem" | "XT75SystemOptionsProgrammingConceptForm_controlSystem" | "XT75SystemReportsProgrammingConceptForm_controlSystem" | "XT75ZoneInformationsProgrammingConceptFormNavButton_controlSystem" | "XT75ZoneInformationsProgrammingConceptForm_controlSystem">;
  readonly " $fragmentType": "XT75FullProgramming_controlSystem";
};
export type XT75FullProgramming_controlSystem$key = {
  readonly " $data"?: XT75FullProgramming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75FullProgramming_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75FullProgramming_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FullProgrammingForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75CommunicationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75CommunicationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75NetworkOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75DeviceSetupProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75DeviceSetupProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75CardFormatsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75CardFormatsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75RemoteOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75SystemReportsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75SystemOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75BellOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75OutputInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75OutputInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75OutputOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75AreaInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75AreaInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75ZoneInformationsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75ZoneInformationsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75KeyFobsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75KeyFobsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XT75LockoutCodeProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasNetworkCommType_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasWifiCommType_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "b1b1405765e50ba0c3ec874d65076c8e";

export default node;
