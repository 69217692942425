import { App } from "app-module";
import { SmallButton } from "components/Button";
import { TextField } from "components/DaStyledElements/Input";
import Modal from "components/Modal";
import { FieldLabel } from "components/SiteForm/FormFields";
import { UserServiceType } from "components/SiteForm/types";
import React, { Fragment, useEffect, useState } from "react";
import { react2angular } from "react2angular";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
  grid-template-rows: 1fr;
  grid-template-areas: "left right";
`;
const GridLeft = styled.div`
  grid-area: "left";
`;
const GridRight = styled.div`
  grid-area: "right";
`;

const EmailField = styled(TextField)`
  :invalid {
    border: 1px solid red;
  }
`;

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const someEmailsInvalid = (notificationEmails: Map<string, string>) =>
  Array.from(notificationEmails.values()).some(
    (email) => !!email && !emailRegex.test(email)
  );

const formatEmailString = (emailString: string | null) => {
  const emailsArray = (emailString ?? "").split(",");
  return new Map(
    [...new Array(6)].map((v, i) => [`Email ${i + 1}`, emailsArray[i] ?? ""])
  );
};

function EmailList({
  notificationEmails,
  setNotificationEmails,
  setHasErrors,
  start,
  end,
}: {
  notificationEmails: Map<string, string>;
  setNotificationEmails: (emails: Map<string, string>) => void;
  setHasErrors: (hasErrors: boolean) => void;
  start?: number;
  end?: number;
}) {
  return (
    <>
      {Array.from(notificationEmails)
        .slice(start ?? 0, end ?? notificationEmails.size)
        .map(([label, email]) => (
          <Fragment key={`${label}-fragment`}>
            <FieldLabel key={`${label}-label`}>{label}</FieldLabel>
            <EmailField
              key={`${label}-email`}
              defaultValue={email}
              type="email"
              onChange={(e) => {
                const updatedEmails = notificationEmails;
                setNotificationEmails(
                  new Map(
                    updatedEmails.set(
                      label,
                      (e.target as HTMLTextAreaElement).value
                    )
                  )
                );
                setHasErrors(someEmailsInvalid(updatedEmails));
              }}
            />
          </Fragment>
        ))}{" "}
    </>
  );
}

function CustomerReferralSettingsModal({
  closeModal,
  onSave,
  toggleCompleted,
  showCompleted,
  notificationEmails,
  setNotificationEmails,
}: {
  closeModal: () => void;
  onSave: (emailString: string) => void;
  toggleCompleted: () => void;
  showCompleted: boolean;
  notificationEmails: Map<string, string>;
  setNotificationEmails: (emails: Map<string, string>) => void;
}) {
  const [showClosedReferrals, setShowClosedReferrals] = useState(showCompleted);
  const [hasErrors, setHasErrors] = useState(
    someEmailsInvalid(notificationEmails)
  );
  return (
    <Modal size="large">
      <Modal.Body>
        <h1 className="printed-concept-heading">Customer Referral Settings</h1>
        <h3>Referral Notifications</h3>
        <p>
          These are the email addresses that will be notified whenever a new
          customer response is received.
        </p>
        <Grid>
          <GridLeft>
            <EmailList
              notificationEmails={notificationEmails}
              setNotificationEmails={setNotificationEmails}
              setHasErrors={setHasErrors}
              start={0}
              end={3}
            />
          </GridLeft>
          <GridRight>
            <EmailList
              notificationEmails={notificationEmails}
              setNotificationEmails={setNotificationEmails}
              setHasErrors={setHasErrors}
              start={3}
            />
          </GridRight>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <hr style={{ marginBottom: "5px", marginTop: "5px" }} />
        <div style={{ display: "flex" }}>
          <div
            className="checkbox c-checkbox checkbox-inLine needsclick"
            style={{ width: "50%", textAlign: "initial" }}
          >
            <p style={{ fontSize: "2rem" }}>Display Settings</p>
            <label className="animated fadeInDown needsclick">
              <input
                type="checkbox"
                checked={showClosedReferrals}
                className="needsclick"
                onChange={() => {
                  setShowClosedReferrals(!showClosedReferrals);
                  toggleCompleted();
                }}
              />
              <span className="icon-checkmark"></span>Show Closed Customer
              Referrals
            </label>
          </div>
          <div style={{ width: "50%", alignSelf: "end" }}>
            <SmallButton type="reset" text="Cancel" onClick={closeModal} />
            <SmallButton
              type="primary"
              text="Save"
              disabled={hasErrors}
              onClick={() => {
                onSave(
                  Array.from(notificationEmails.values())
                    .filter(Boolean)
                    .join(",")
                );
              }}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

type TemplateType = { Id: string; NotificationEmail: string };

// Settings Button for Customer Referrals Page
// This handles the state and display for Settings Modal

export function CustomerReferralSettings({
  toggleCompleted,
  UserService,
  CustomerReferralsService,
  showCompleted,
  showSaveError,
}: {
  toggleCompleted: () => void;
  UserService: UserServiceType & { dealer_id: string };
  CustomerReferralsService: {
    changeReferralTpl: (
      id: string | number,
      body: TemplateType
    ) => Promise<void>;
    getExistingTpl: (id: string | number) => Promise<TemplateType>;
  };
  showCompleted: boolean;
  showSaveError: () => void;
}) {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [template, updateTemplate] = useState({
    Id: "",
    NotificationEmail: "",
  });
  const [notificationEmails, setNotificationEmails] = useState(new Map());
  useEffect(() => {
    CustomerReferralsService.getExistingTpl(UserService.dealer_id).then(
      (templateObj) => {
        updateTemplate(templateObj);
        setNotificationEmails(formatEmailString(templateObj.NotificationEmail));
      }
    );
  }, [UserService.dealer_id]);

  return (
    <>
      <SmallButton onClick={() => setSettingsOpen(true)} text="Settings" />
      {settingsOpen && (
        <CustomerReferralSettingsModal
          closeModal={() => setSettingsOpen(false)}
          notificationEmails={notificationEmails}
          setNotificationEmails={setNotificationEmails}
          toggleCompleted={toggleCompleted}
          showCompleted={showCompleted}
          onSave={(emailString: string) => {
            CustomerReferralsService.changeReferralTpl(template.Id, {
              ...template,
              NotificationEmail: emailString,
            }).then(
              () => {
                setSettingsOpen(false);
              },
              (error) => {
                showSaveError();
                setSettingsOpen(false);
              }
            );
          }}
        />
      )}
    </>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "customerReferralSettings",
    react2angular(
      CustomerReferralSettings,
      ["toggleCompleted", "showCompleted", "showSaveError"],
      ["UserService", "CustomerReferralsService"]
    )
  );
}
