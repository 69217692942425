/**
 * @generated SignedSource<<2de6ca597c80836c40f8a320d0680a14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data = {
  readonly systemReports: {
    readonly abortReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly accessKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ambushReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly bypassReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly codeChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly earlyToClose: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryCheckinProtection: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lateToOpen: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly openCloseEnable: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly scheduleChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly sendStoredMessage: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly videoSystemReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneRestoralReports: {
      readonly data: SystemReportsZoneRestoralReports | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts";
};
export type XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "a9443278f1073889743c3fda2d79de15";

export default node;
