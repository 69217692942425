/**
 *
 * TemperatureIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const TemperatureIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M41,31.912V6.557C41,3.063,37.786,0,34.122,0h-3.707C26.878,0,24,2.941,24,6.557v25.353
    c-5.227,3.116-8.541,8.835-8.541,14.901C15.459,56.289,23.003,64,32.277,64c9.395,0,17.038-7.711,17.038-17.189
    C49.314,40.69,46.089,34.976,41,31.912z M30.416,4h3.707C35.575,4,37,5.267,37,6.557V19h-9V6.557C28,5.146,29.083,4,30.416,4z
     M32.277,60c-7.068,0-12.818-5.917-12.818-13.189c0-4.982,2.919-9.665,7.437-11.93L28,34.327V23h9v11.327l1.104,0.554
    c4.38,2.195,7.21,6.878,7.21,11.93C45.314,54.083,39.466,60,32.277,60z"
      />
    </g>
  </SvgIcon>
);

export default TemperatureIcon;
