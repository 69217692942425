/**
 * @generated SignedSource<<4b5b6952b3fefcf880ba519a7a1b49c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelFirmwareStatus = "UPDATES_AVAILABLE" | "UPDATES_FAILED" | "UPDATES_IN_PROGRESS" | "UPDATES_SUCCESSFUL" | "UP_TO_DATE";
export type SiteControlSystemType = "DOOR_ACCESS" | "ELEVATOR";
import { FragmentRefs } from "relay-runtime";
export type SiteControlSystemsSection_site$data = {
  readonly controlSystems: ReadonlyArray<{
    readonly allDoors: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly firmwareStatus: PanelFirmwareStatus;
    readonly firmwareUpdateProgress: number | null;
    readonly id: string;
    readonly intendedUsage: SiteControlSystemType;
    readonly " $fragmentSpreads": FragmentRefs<"SiteControlSystemsSection_siteControlSystem">;
  }>;
  readonly id: string;
  readonly totalControlSystems: number;
  readonly " $fragmentSpreads": FragmentRefs<"NewSiteControlSystemForm_site">;
  readonly " $fragmentType": "SiteControlSystemsSection_site";
};
export type SiteControlSystemsSection_site$key = {
  readonly " $data"?: SiteControlSystemsSection_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteControlSystemsSection_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteControlSystemsSection_site",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "intendedUsage",
          "storageKey": null
        },
        {
          "alias": "allDoors",
          "args": [
            {
              "kind": "Literal",
              "name": "includeUnprogrammedDoors",
              "value": true
            }
          ],
          "concreteType": "SiteDoor",
          "kind": "LinkedField",
          "name": "doors",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": "doors(includeUnprogrammedDoors:true)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firmwareStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firmwareUpdateProgress",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SiteControlSystemsSection_siteControlSystem"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalControlSystems",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewSiteControlSystemForm_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "2a16bf4df6612798e525f3b19e6cb5ad";

export default node;
