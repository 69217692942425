/**
 * @generated SignedSource<<4130117696dd54b74351d6653ecf14fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type CameraTableQuery$variables = {
  systemId: string;
};
export type CameraTableQuery$data = {
  readonly controlSystem: {
    readonly id?: string;
    readonly name?: string;
    readonly panel?: {
      readonly hardwareModel: PanelHardwareModel;
      readonly softwareVersion: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"CameraMobileWidgetQuery_controlSystem" | "CameraTable_controlSystem">;
  } | null;
};
export type CameraTableQuery = {
  response: CameraTableQuery$data;
  variables: CameraTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "systemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hardwareModel",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareVersion",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CameraTableQuery",
    "selections": [
      {
        "alias": "controlSystem",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CameraTable_controlSystem"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CameraMobileWidgetQuery_controlSystem"
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CameraTableQuery",
    "selections": [
      {
        "alias": "controlSystem",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SecureComCamera",
                "kind": "LinkedField",
                "name": "secureComCameras",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceType",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastCheckIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOnline",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "model",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "formFactor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "macAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "networkReady",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manufacturer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "settingConfirmation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a091af4af6444ae157957d549774b0b1",
    "id": null,
    "metadata": {},
    "name": "CameraTableQuery",
    "operationKind": "query",
    "text": "query CameraTableQuery(\n  $systemId: ID!\n) {\n  controlSystem: node(id: $systemId) {\n    __typename\n    ... on ControlSystem {\n      id\n      name\n      panel {\n        hardwareModel\n        softwareVersion\n        id\n      }\n      ...CameraTable_controlSystem\n      ...CameraMobileWidgetQuery_controlSystem\n    }\n    id\n  }\n}\n\nfragment CameraMobileWidgetQuery_controlSystem on ControlSystem {\n  secureComCameras {\n    id\n    deviceType\n    name\n    lastCheckIn\n    isOnline\n    status\n    model\n    formFactor\n    imageSize\n    macAddress\n    networkReady\n    manufacturer\n  }\n  id\n}\n\nfragment CameraTable_controlSystem on ControlSystem {\n  secureComCameras {\n    id\n    deviceType\n    name\n    lastCheckIn\n    isOnline\n    status\n    model\n    formFactor\n    imageSize\n    macAddress\n    networkReady\n    manufacturer\n    settingConfirmation\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "2144b2508272571bbcf73a7c429abbc1";

export default node;
