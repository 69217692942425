/**==================================================================
 * Last 24 Hours SparkLine Chart Header Directive
 ====================================================================*/
/**
 * @ngdoc directive
 * @name App.directive:last24
 * @function
 *
 * @description
 * Displays the initial value in the sparkline chart data series as the header informtion for the chart
 * Also displays an optional unit description (e.g. 'dBm', etc.)
 */
App.directive("daLast24", [
  "$window",
  "PROPS",
  function ($window, PROPS) {
    return {
      restrict: "A",
      link: function (scope, $element, options) {
        // options are the element attributes
        /*
         options.$observe('value1', function() {

         if (!options.value1 || !options.chartName) {    // Check to see if the chart type is supplied via html options data
         return;
         }

         //var unitName = options.unit;
         var unitName = typeof options.unit !== 'undefined' ? options.unit : "";
         var headerValue = options.value1;

         if ( headerValue === undefined) {   // check to see if data for this chart type exists
         $element.html("");
         return;
         }

         //$element.html(headerValue + " " + unitName);
         $element.html(headerValue);


         });
         */

        /****************************************************************************************************************
         * The code above stopped correctly retrieving the valid latest data item after the panelstatistics api change  *
         * made by Brad R. on 4/7/2015. The code below, which looks at the same chart data and then processes it in a   *
         * similar way was implemented to solve the problem. It observes and uses the chart data as a whole, instead of *
         * the last index of the selected chart data (which is referenced in the template), which worked fine prior to  *
         * the api change.                                                                                              *
         * **************************************************************************************************************/
        options.$observe("chartData", function () {
          // https://docs.angularjs.org/api/ng/type/$compile.directive.Attributes

          if (!options.chartData) return; // Exit and do  nothing if there is no data.

          /**************************************************************************************************************
           * This needs to be converted into an array as done below. If not, it will be interpreted as a string an the  *
           * array functions called on it will not work. It seems that attribute data passed from a directive's element *
           * default to string type.                                                                                    *
           **************************************************************************************************************/
          options.chartData = angular.fromJson(options.chartData);

          if (!options.chartData || !options.chartName) {
            // Check to see if the chart type is supplied via html options data
            $element.html("");
            return;
          }

          // We need to sort data array ascending
          /*******************************************************************************************************************
           * Chrome Browser Bug Fix:                                                                                         *
           * This sort function and the call to sort currentChartData is a Chrome browser bug fix. In Chrome, dates can      *
           * become unsorted some how (the dates remain properly sorted in FF and Safari). Dates are sorted when the data    *
           * is retrieved by panelDataService and dates should be sorted automatically by FlotJS regardless, but this is not *
           * happening in Chrome. This manual re-sorting fixes the issue for Chrome.                                         *
           *******************************************************************************************************************/
          function compareDates(a, b) {
            if (new Date(a[0]) < new Date(b[0])) return -1;
            if (new Date(a[0]) > new Date(b[0])) return 1;
            return 0;
          }

          options.chartData.sort(compareDates); // Sort the data by date (ascending)
          var headerValue = options.chartData[options.chartData.length - 1][1];
          $element.html(headerValue);

          /*****************************************************************************************************************************
           * Conditional logic to add appropriate warning class based on panel model, last data value, panel threshold, and chart type *
           *****************************************************************************************************************************/
          if (
            /XR150|XR350|XR550|XT30|XT50/i.test(options.panelModel) &&
            /ACVoltage/i.test(options.chartName) &&
            headerValue <= 14
          ) {
            $element.addClass("cell-signal-widget-main-warning");
          }
          if (
            /XTL|XTLN|XTLW|DualCom|CellComSL|CellComEX|iComSL|iComLNC/i.test(
              options.panelModel
            ) &&
            /ACVoltage/i.test(options.chartName) &&
            headerValue <= 10
          ) {
            $element.addClass("cell-signal-widget-main-warning");
          }
          if (
            /XR150|XR350|XR550|XT30|XT50/i.test(options.panelModel) &&
            /BatteryVoltage/i.test(options.chartName) &&
            headerValue <= 12
          ) {
            $element.addClass("cell-signal-widget-main-warning");
          }
          if (
            /XTL|XTLN|XTLW|DualCom|CellComSL|CellComEX|iComSL|iComLNC/i.test(
              options.panelModel
            ) &&
            /BatteryVoltage/i.test(options.chartName) &&
            headerValue <= 3.7
          ) {
            $element.addClass("cell-signal-widget-main-warning");
          }
        });
      },
    };
  },
]);
