/**
 *
 * ExitIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ExitIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M62.115,29.555l-6.234-0.359l-4.941-6.641c-0.003-0.004-0.007-0.007-0.01-0.011c-0.002-0.017,0-0.033-0.002-0.05
      c-0.062-0.414-0.249-0.778-0.514-1.063c0.312,0.078,0.633,0.13,0.967,0.13c2.333,0,4.217-2.021,4.217-4.516
      c0-2.49-1.884-4.514-4.217-4.514s-4.224,2.023-4.224,4.514c0,1.564,0.743,2.939,1.872,3.75c-0.125-0.004-0.25,0-0.378,0.021
      l-9.95,1.504c-0.736,0.111-1.35,0.622-1.592,1.326l-3,8.703c-0.36,1.044,0.195,2.183,1.239,2.542
      c0.216,0.074,0.436,0.11,0.652,0.11c0.83,0,1.605-0.521,1.891-1.35l2.605-7.558l5.893-0.891L31.42,55.566
      c-0.488,0.991-0.081,2.19,0.91,2.679c0.284,0.14,0.586,0.206,0.883,0.206c0.738,0,1.447-0.409,1.795-1.115l8.697-17.642
      l6.445,6.485l3.945,11.471c0.285,0.829,1.061,1.351,1.891,1.351c0.215,0,0.435-0.035,0.65-0.109
      c1.044-0.359,1.6-1.497,1.241-2.542l-4.094-11.904c-0.098-0.285-0.26-0.545-0.472-0.76L45.575,35.9l4.109-8.335l3.546,4.767
      c0.353,0.475,0.898,0.769,1.489,0.803l7.166,0.414c1.098,0.05,2.048-0.778,2.112-1.882C64.061,30.564,63.218,29.619,62.115,29.555
      z"
        />
        <path
          d="M24.242,14.748L10.672,4H35v11c0,1.104,0.896,2,2,2s2-0.896,2-2V2c0-1.104-0.896-2-2-2H5C3.896,0,3,0.896,3,2v47.048
      c0,0.644,0.31,1.248,0.832,1.623l18,12.952C22.179,63.873,22.588,64,23,64c0.312,0,0.625-0.072,0.912-0.22
      C24.58,63.438,25,62.75,25,62V16.315C25,15.704,24.721,15.127,24.242,14.748z M21,58.097L7,48.023V6.194l14,11.088V58.097z"
        />
        <path d="M15,39c1.104,0,2-0.896,2-2v-4c0-1.104-0.896-2-2-2s-2,0.896-2,2v4C13,38.104,13.896,39,15,39z" />
      </g>
    </g>
  </SvgIcon>
);

export default ExitIcon;
