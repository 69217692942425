/**
 * @generated SignedSource<<55d5cdcfb83f4e9088eaabb6d16e1d6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceInformation1100TField_deviceInformation$data = {
  readonly door?: boolean;
  readonly id: string;
  readonly network?: boolean;
  readonly number?: number;
  readonly wirelessTranslator1100t?: boolean;
  readonly " $fragmentType": "DeviceInformation1100TField_deviceInformation";
};
export type DeviceInformation1100TField_deviceInformation$key = {
  readonly " $data"?: DeviceInformation1100TField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformation1100TField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformation1100TField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "door",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "network",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wirelessTranslator1100t",
          "storageKey": null
        }
      ],
      "type": "XtDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "869ee04bcd22d6d7378cfb7685bda86f";

export default node;
