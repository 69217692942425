App.factory("ModemScheduledUpgradesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/Dealers/:dealer_id/ModemScheduledUpgrades/:type/:panel/:panel_id/:id",
      {},
      {
        //dealer
        getScheduledUpgradesForDealer: {
          method: "GET",
          params: { dealer_id: "@dealer_id" },
          isArray: true,
        },
        deleteScheduledUpgradeForDealer: {
          method: "DELETE",
          params: { dealer_id: "@dealer_id", id: "@id" },
        },
      }
    );
  },
]);
