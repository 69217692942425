/**
 * @generated SignedSource<<367814d8a9941d450da091c6b018f2c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
export type GroupsListQuery$variables = {
  systemId: string;
};
export type GroupsListQuery$data = {
  readonly system: {
    readonly groupsConnection?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly number: string | null;
        };
      }>;
      readonly totalCount: number;
    };
    readonly id?: string;
    readonly name?: string;
    readonly panel?: {
      readonly systemOptions: {
        readonly systemType?: SystemType;
      };
    };
  } | null;
};
export type GroupsListQuery = {
  response: GroupsListQuery$data;
  variables: GroupsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "systemId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemType",
      "storageKey": null
    }
  ],
  "type": "Xt75SystemOptions",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "GroupsConnection",
  "kind": "LinkedField",
  "name": "groupsConnection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Group",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupsListQuery",
    "selections": [
      {
        "alias": "system",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "systemOptions",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupsListQuery",
    "selections": [
      {
        "alias": "system",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "systemOptions",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "057e8ad6a0db1ad69dcb5124ebaf8da1",
    "id": null,
    "metadata": {},
    "name": "GroupsListQuery",
    "operationKind": "query",
    "text": "query GroupsListQuery(\n  $systemId: ID!\n) {\n  system: node(id: $systemId) {\n    __typename\n    ... on ControlSystem {\n      panel {\n        systemOptions {\n          __typename\n          ... on Xt75SystemOptions {\n            systemType\n          }\n          id\n        }\n        id\n      }\n      id\n      name\n      groupsConnection {\n        totalCount\n        edges {\n          node {\n            id\n            number\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "89b1e0b501967f39b61d9ccea54b23e1";

export default node;
