/**
 * @name App.factory.PaymentsService
 *
 * Service for Payments.
 *
 */
App.factory("PaymentsService", [
  "$q",
  "PaymentsAPI",
  function ($q, PaymentsAPI) {
    return {
      getCustomerAccount: function (dealerCode) {
        var deffered = $q.defer();
        PaymentsAPI.getCustomerAccount(
          { dealerCode: dealerCode },
          function (data) {
            deffered.resolve(data);
          },
          function (error) {
            deffered.reject(error);
          }
        );

        return deffered.promise;
      },
      getAdjustedCost: function (amount, modifier) {
        var deffered = $q.defer();
        PaymentsAPI.getAdjustedCost(
          {},
          { amount: amount * 100, modifiers: modifier },
          function (data) {
            data.total = data.total / 100;
            deffered.resolve(data);
          },
          function (error) {
            deffered.reject(error);
          }
        );

        return deffered.promise;
      },
      getPaymentSources: function (dealer_id) {
        var deffered = $q.defer();
        PaymentsAPI.getPaymentSources(
          { dealer_id: dealer_id },
          function (data) {
            deffered.resolve(data);
          },
          function (error) {
            deffered.reject(error);
          }
        );

        return deffered.promise;
      },
      chargeAccount: function (paymentSourceId, amount, dealerId, userEmail) {
        var deffered = $q.defer();
        PaymentsAPI.chargeAccount(
          {},
          {
            payment_source_id: paymentSourceId,
            amount: amount,
            dealer_id: dealerId,
            description: "SECURECOM WEB PYMNT",
            email: userEmail,
          },
          function (data) {
            deffered.resolve(data);
          },
          function (error) {
            deffered.reject(error);
          }
        );

        return deffered.promise;
      },
      updateAutoPayment: function (dealerCode, enabled, paymentSourceId) {
        var deffered = $q.defer();
        PaymentsAPI.updateAutoPayment(
          {},
          {
            dealerCode: dealerCode,
            enabled: enabled,
            paymentSourceId: paymentSourceId,
          },
          function (data) {
            deffered.resolve(data);
          },
          function (error) {
            deffered.reject(error);
          }
        );

        return deffered.promise;
      },
      getAutoPayment: function (dealerCode) {
        var deffered = $q.defer();
        PaymentsAPI.getAutoPayment(
          { dealerCode: dealerCode },
          function (data) {
            deffered.resolve(data);
          },
          function (error) {
            deffered.reject(error);
          }
        );

        return deffered.promise;
      },
      getScheduledPayments: function (dealerCode) {
        var deffered = $q.defer();
        PaymentsAPI.getScheduledPayments(
          {},
          function (data) {
            deffered.resolve(data);
          },
          function (error) {
            deffered.reject(error);
          }
        );

        return deffered.promise;
      },
      deletePaymentSource: function (dealerCode, paymentSourceId) {
        var deferred = $q.defer();
        PaymentsAPI.deletePaymentSource(
          {
            dealerCode: dealerCode,
            paymentSourceId: paymentSourceId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },
      createPaymentSource: function (input) {
        const deferred = $q.defer();
        PaymentsAPI.createPaymentSource(
          input,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },
      updateUserEmail: function (billing_email) {
        const deferred = $q.defer();
        PaymentsAPI.updateUserEmail(
          billing_email,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
