import {
  Environment,
  FetchFunction,
  GraphQLResponse,
  GraphQLResponseWithoutData,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";
import { getGQLUrl } from "./utils/universal/securecom-urls";
import { EnsureFields, SecureComEnv } from "./utils/universal/types";

type Options = {
  env: SecureComEnv;
  authToken: string;
  userCode?: string;
  stagingBranch?: string;
  clientName?: string;
  clientVersion?: string;
  clientLoginFunction?: (...args: any[]) => void;
  clientLogoutFunction?: () => void;
  refreshToken?: string;
  rootScope?: any;
};

type Prerequisites = EnsureFields<Options, "env" | "authToken">;

const graphqlFetchFunction = async (
  operation: any,
  variables: any,
  options: Options
) =>
  await fetch(
    process.env.REACT_APP_GRAPHQL_ENDPOINT || getGQLUrl(options.env),
    {
      method: "POST",
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
      headers: JSON.parse(
        JSON.stringify({
          env: options.env,
          "auth-token": options.authToken,
          "user-code": options.userCode,
          "staging-branch": options.stagingBranch,
          "content-type": "application/json",
          "Accept-Encoding": "gzip",
          "apollographql-client-name": options.clientName,
          "apollographql-client-version": options.clientVersion,
        })
      ),
    }
  ).then((response) => response.json());

const createFetchRelay = (options: Options): FetchFunction => {
  return async function fetchRelay(
    operation,
    variables
  ): Promise<GraphQLResponse> {
    function logout() {
      if (options.clientLogoutFunction) options.clientLogoutFunction();
    }

    // Main Function
    // If there is no refreshPromise, then we can just call the graphqlFetchFunction
    // If there is a refreshPromise, then we need to wait for it to resolve before we call the graphqlFetchFunction
    const payload = !options.rootScope.refreshPromise
      ? await graphqlFetchFunction(operation, variables, options)
      : options.rootScope.refreshPromise instanceof Promise
      ? options.rootScope.refreshPromise.then(async (res: any) => {
          return await new Promise(async (resolve, _) => {
            return resolve(
              res.jwt
                ? await graphqlFetchFunction(operation, variables, {
                    ...options,
                    authToken: res.jwt,
                    refreshToken: res.refresh_token,
                  })
                : new Promise((_, reject) => {
                    logout();
                    reject("Token Expired");
                  })
            );
          });
        })
      : await graphqlFetchFunction(operation, variables, {
          ...options,
          authToken: options.rootScope.refreshPromise.jwt,
          refreshToken: options.rootScope.refreshPromise.refresh_token,
        });

    if ((payload as GraphQLResponseWithoutData).errors) {
      if (
        (payload as GraphQLResponseWithoutData).errors?.[0]?.message ===
        "Token Expired"
      ) {
        logout();
      } else {
        throw new Error(
          JSON.stringify(
            (payload as GraphQLResponseWithoutData).errors,
            null,
            2
          )
        );
      }
    }
    return JSON.parse(JSON.stringify(payload)) as GraphQLResponse;
  };
};
export default function createRelayEnvironment(options: Prerequisites) {
  const fetchRelay = createFetchRelay(options);

  return new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource(), {
      gcReleaseBufferSize: 10,
    }),
  });
}

/**
 * Creates a memoized relay environment using the SecureComEnv and authToken.
 */
export function getRelayEnvironment({
  env,
  authToken,
  cache,
  stagingBranch,
  clientName,
  clientVersion,
  resetEnvironment,
  clientLoginFunction,
  clientLogoutFunction,
  refreshToken,
  rootScope,
}: {
  env: SecureComEnv;
  authToken: string;
  cache: Map<string, Environment>;
  stagingBranch?: string;
  clientName?: string;
  clientVersion?: string;
  resetEnvironment?: boolean;
  clientLoginFunction?: (...args: any[]) => void;
  clientLogoutFunction?: () => void;
  refreshToken?: string;
  rootScope?: any;
}) {
  const key = `${env}-${authToken}-${stagingBranch ?? ""}`;

  if (resetEnvironment && cache.has(key)) {
    cache.delete(key);
  }

  const environment =
    cache.get(key) ||
    createRelayEnvironment({
      env,
      authToken,
      stagingBranch,
      clientName,
      clientVersion,
      clientLoginFunction,
      clientLogoutFunction,
      refreshToken,
      rootScope,
    });

  if (!cache.has(key)) {
    cache.set(key, environment);
  }

  return environment;
}
