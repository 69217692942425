/**
 * @generated SignedSource<<fc310be2c744c0d4183b2e6678739a4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly outputGroups: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputNumber1: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber3: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber4: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber5: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber6: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber7: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber8: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputGroupsProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "99ca8d8b629ff75aa8e923e53e4efcde";

export default node;
