/**
 * @generated SignedSource<<170b8c4713a94ad924e61c7d61d4698e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type KeypadUpdateButtonMutation$variables = {
  keypadId: string;
};
export type KeypadUpdateButtonMutation$data = {
  readonly keypadUpdate: {
    readonly details: {
      readonly code: string | null;
      readonly message: string | null;
    } | null;
    readonly status: string;
    readonly uuid: string;
  } | null;
};
export type KeypadUpdateButtonMutation = {
  response: KeypadUpdateButtonMutation$data;
  variables: KeypadUpdateButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "keypadId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "keypadId",
        "variableName": "keypadId"
      }
    ],
    "concreteType": "KeypadProgramming",
    "kind": "LinkedField",
    "name": "keypadUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ScapiV2JobErrorDetails",
        "kind": "LinkedField",
        "name": "details",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KeypadUpdateButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KeypadUpdateButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "03080d3eac2aca7fbb7a25ffbdb21437",
    "id": null,
    "metadata": {},
    "name": "KeypadUpdateButtonMutation",
    "operationKind": "mutation",
    "text": "mutation KeypadUpdateButtonMutation(\n  $keypadId: ID!\n) {\n  keypadUpdate(keypadId: $keypadId) {\n    status\n    uuid\n    details {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9a1412e54d033a041c251be6bee736d";

export default node;
