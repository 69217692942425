/**
 * @generated SignedSource<<f562d212456fd08ed49e095c04f300e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FullProgrammingForm_dealer$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ProgrammingTemplateForm_dealer">;
  readonly " $fragmentType": "FullProgrammingForm_dealer";
};
export type FullProgrammingForm_dealer$key = {
  readonly " $data"?: FullProgrammingForm_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FullProgrammingForm_dealer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FullProgrammingForm_dealer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_dealer"
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};

(node as any).hash = "d21897a067f74c03ac2a3489a18c8588";

export default node;
