/**
 * @generated SignedSource<<aeaa16563210f1abef2a6db7c8e8c5b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExistingSiteControlSystemFormRefetch_siteControlSystemQuery$variables = {
  id: string;
};
export type ExistingSiteControlSystemFormRefetch_siteControlSystemQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ExistingSiteControlSystemForm_siteControlSystem">;
  } | null;
};
export type ExistingSiteControlSystemFormRefetch_siteControlSystemQuery = {
  response: ExistingSiteControlSystemFormRefetch_siteControlSystemQuery$data;
  variables: ExistingSiteControlSystemFormRefetch_siteControlSystemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "intendedUsage",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v9 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firmwareUpdateDismissed",
      "storageKey": null
    }
  ]
},
v10 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "outputIntendedUsage",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isAvailableForAlarmIntegration",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExistingSiteControlSystemFormRefetch_siteControlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExistingSiteControlSystemForm_siteControlSystem"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExistingSiteControlSystemFormRefetch_siteControlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serialNumber",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "softwareVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initialConnectionStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isBillingControlSystem",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxOutputModules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availableOutputModulesCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasCell",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasEasyConnect",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystemCellStatus",
                "kind": "LinkedField",
                "name": "cellStatus",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystemEasyConnectStatus",
                "kind": "LinkedField",
                "name": "easyConnectStatus",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "online",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteOutputModulesConnection",
                "kind": "LinkedField",
                "name": "outputModulesConnection",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteOutputModule",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteOutputModuleOutputsConnection",
                        "kind": "LinkedField",
                        "name": "outputsConnection",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteOutput",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "relayNumber",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeUnprogrammedDoors",
                    "value": true
                  }
                ],
                "concreteType": "SiteDoor",
                "kind": "LinkedField",
                "name": "doors",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "strikeTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "strikeDelay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fireZone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fireExit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "includeInLockdown",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doorPropTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "osdpOrWiegand",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doorSensor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doorForce",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requestToExit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unlockOnRex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buzzer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "led",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": "doors(includeUnprogrammedDoors:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasFireZone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alarmPanelX1Integration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "armablePanelSerialNumber",
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteOutput",
                "kind": "LinkedField",
                "name": "alarmPanelIntegrationOutput",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteOutput",
                "kind": "LinkedField",
                "name": "onboardOutput1",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteOutput",
                "kind": "LinkedField",
                "name": "onboardOutput2",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPendingChanges",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasFailedPreProgrammingJob",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "replacingPanelJobGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasReplaceX1Backup",
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "type": "SiteControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86a4fb791185723d254b08cd08903b84",
    "id": null,
    "metadata": {},
    "name": "ExistingSiteControlSystemFormRefetch_siteControlSystemQuery",
    "operationKind": "query",
    "text": "query ExistingSiteControlSystemFormRefetch_siteControlSystemQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ExistingSiteControlSystemForm_siteControlSystem\n    id\n  }\n}\n\nfragment ExistingSiteControlSystemForm_siteControlSystem on SiteControlSystem {\n  id\n  name\n  serialNumber\n  type\n  softwareVersion\n  initialConnectionStatus\n  isBillingControlSystem\n  intendedUsage\n  maxOutputModules\n  availableOutputModulesCount\n  hasCell\n  hasEasyConnect\n  cellStatus {\n    status\n  }\n  easyConnectStatus {\n    status\n  }\n  commType\n  online\n  outputModulesConnection {\n    totalCount\n    nodes {\n      id\n      name\n      address\n      intendedUsage\n      outputsConnection {\n        totalCount\n        nodes {\n          id\n          name\n          type\n          relayNumber\n        }\n      }\n    }\n  }\n  doors(includeUnprogrammedDoors: true) {\n    id\n    name\n    active\n    strikeTime\n    strikeDelay\n    fireZone\n    fireExit\n    includeInLockdown\n    doorPropTime\n    osdpOrWiegand\n    doorSensor\n    doorForce\n    requestToExit\n    unlockOnRex\n    buzzer\n    led\n    number\n  }\n  site {\n    id\n    hasFireZone\n    alarmPanelX1Integration\n    armablePanelSerialNumber\n  }\n  alarmPanelIntegrationOutput {\n    id\n    number\n  }\n  onboardOutput1 {\n    id\n    name\n    outputIntendedUsage\n    isAvailableForAlarmIntegration\n  }\n  onboardOutput2 {\n    id\n    name\n    outputIntendedUsage\n    isAvailableForAlarmIntegration\n  }\n  hasPendingChanges\n  hasFailedPreProgrammingJob\n  replacingPanelJobGroupId\n  hasReplaceX1Backup\n  ...SiteControlSystemJobStatusBadge_siteControlSystem\n}\n\nfragment SiteControlSystemJobStatusBadge_siteControlSystem on SiteControlSystem {\n  id\n  type\n  hasPendingChanges\n  hasFailedPreProgrammingJob\n  online\n}\n"
  }
};
})();

(node as any).hash = "61a2e1afa0c2109cbe92a84f252b92e0";

export default node;
