App.controller("StyleguideMenuCtrl", StyleguideMenuCtrl);

StyleguideMenuCtrl.$inject = ["$rootScope", "$scope", "StyleDataAccessService"];

function StyleguideMenuCtrl($rootScope, $scope, StyleguideDataAccessService) {
  init();

  function init() {
    $scope.menu_items = [
      { name: "home", homeIsCollapsed: true },
      { name: "color", colorIsCollapsed: true },
      { name: "typo", typoIsCollapsed: true },
      { name: "butt", buttIsCollapsed: true },
      { name: "layout", layoutIsCollapsed: true },
      { name: "forms", formsIsCollapsed: true },
      { name: "notification", notificationIsCollapsed: true },
      { name: "icons", iconsIsCollapsed: true },
      { name: "components", componentsIsCollapsed: true },
      { name: "directives", directivesIsCollapsed: true },
      { name: "utilities", utilitiesIsCollapsed: true },
    ];

    //color data
    StyleguideDataAccessService.getStyleData("color-data.json")
      .then(
        function (colorData) {
          $scope.ColorChipFamilies = colorData;
        },
        function (colorError) {
          $rootScope.alerts.push({ type: "error", message: colorError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    //typography data
    StyleguideDataAccessService.getStyleData("typography-data.json")
      .then(
        function (typoData) {
          $scope.TypoFamilies = typoData;
        },
        function (typoError) {
          $rootScope.alerts.push({ type: "error", message: typoError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    //layout data
    StyleguideDataAccessService.getStyleData("layout-data.json")
      .then(
        function (layoutData) {
          $scope.LayoutData = layoutData;
        },
        function (layoutError) {
          $rootScope.alerts.push({ type: "error", message: layoutError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    //notification data
    StyleguideDataAccessService.getStyleData("notification-data.json")
      .then(
        function (notificationData) {
          $scope.NotificationData = notificationData;
        },
        function (notificationError) {
          $rootScope.alerts.push({ type: "error", message: notificationError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    //form data
    StyleguideDataAccessService.getStyleData("form-data.json")
      .then(
        function (formData) {
          $scope.FormData = formData;
        },
        function (formError) {
          $rootScope.alerts.push({ type: "error", message: formError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    $scope.collapseOtherMenuItems = function (openItem) {
      angular.forEach($scope.menu_items, function (value) {
        if (value.name == openItem) {
          if (value[value.name + "IsCollapsed"] == false) {
            value[value.name + "IsCollapsed"] = true;
          } else {
            value[value.name + "IsCollapsed"] = false;
          }
        } else {
          value[value.name + "IsCollapsed"] = true;
        }
      });
    };
  }
}
