/**
 * @ngdoc service
 * @name App.factory:PanelV2API
 *
 * @description
 * API factory for Panel Programming
 *
 */
App.factory("PanelV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl +
        "/v2/panels/:panel_id/:concept/:item_id/:mac_address/:action/",
      {},
      {
        index: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            concept: "@concept",
            page: "@page",
            page_size: "@page_size",
          },
          transformResponse: function (data) {
            if (data && data.trim().length) {
              var response = angular.fromJson(data);
              if (Array.isArray(response)) {
                return { array_data: response };
              } else {
                return response;
              }
            }
          },
        },
        show: {
          method: "GET",
          params: { panel_id: "@panel_id", concept: "@concept" },
        },
        refresh: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            concept: "@concept",
            action: "refresh",
          },
        },
        update: {
          method: "PUT",
          params: {
            panel_id: "@panel_id",
            concept: "@concept",
            item_id: "@item_id",
          },
        },
        new: {
          method: "GET",
          params: { panel_id: "@panel_id", concept: "@concept", action: "new" },
        },
        create: {
          method: "POST",
          params: { panel_id: "@panel_id", concept: "@concept" },
        },
        destroy: {
          method: "DELETE",
          params: {
            panel_id: "@panel_id",
            concept: "@concept",
            item_id: "@item_id",
          },
        },
        disconnect: {
          method: "POST",
          params: { panel_id: "@panel_id", concept: "disconnect" },
        },
        restore: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            concept: "@concept",
            action: "restore",
          },
        },
        refreshMacAddress: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            mac_address: "panel_mac_and_serial",
            action: "refresh",
          },
        },
        getMacAddress: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            mac_address: "panel_mac_and_serial",
          },
        },
        getScheduleUse: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            concept: "time_schedules",
            item_id: "@item_id",
            action: "associations",
          },
        },
        sendAppFeatures: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            concept: "app_features",
            action: "push",
          },
          transformRequest: function (data) {
            return "";
          },
        },
      }
    );
  },
]);
