import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsRemoteArmingOutputField_outputOptions$key } from "./__generated__/OutputOptionsRemoteArmingOutputField_outputOptions.graphql";

export const outputOptionsRemoteArmingOutputFieldId = () =>
  "output-options-remote-arming-output";

function OutputOptionsRemoteArmingOutputField() {
  const [{ remoteArmingOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsRemoteArmingOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsRemoteArmingOutputField_outputOptions on OutputOptions {
          id
          remoteArmingOutput {
            id
            number
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsRemoteArmingOutputFieldId();
  const hardwareModel = useHardwareModel();
  const houseCode = useHouseCode();

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const { current: originalValue } = React.useRef(
    remoteArmingOutput.formattedNumber
  );
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Remote Arming Output"
      tooltip="This output is momentarily activated each time the System is Armed or Disarmed remotely or by schedule"
    >
      <TextInput
        id={fieldId}
        value={remoteArmingOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "remoteArmingOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "remoteArmingOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsRemoteArmingOutputField;
