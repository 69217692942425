import { GenericPageFallback } from "components/GenericPageFallback";
import React, { Suspense } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import Toasts from ".";

const ToastsAngularEntryPoint = ({ RelayService }: any) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<GenericPageFallback />}>
        <Toasts environment={environment} />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "toasts",
    react2angular(ToastsAngularEntryPoint, [], ["RelayService"])
  );
};
