App.directive("daDynamicElement", [
  "$compile",
  function ($compile) {
    return {
      restrict: "E",
      scope: {
        message: "=",
      },
      replace: true,
      link: function (scope, element, attrs) {
        var template = $compile(scope.message)(scope);
        element.replaceWith(template);
      },
    };
  },
]);
