import * as React from "react";
import styles from "./style.module.css";

type Props = {
  variant?: "neutral" | "success" | "danger" | "info";
  as?: keyof React.ReactHTML;
  className?: string;
  children?: React.ReactNode;
};

export default function Tag({
  variant = "neutral",
  as = "div",
  className,
  children,
  ...rest
}: Props) {
  return React.createElement(
    as,
    {
      ...rest,
      className: `${styles[`tag--${variant}`]} ${className}`,
    },
    children
  );
}

export function NeutralTag(props: Props) {
  return <Tag {...props} variant="neutral" />;
}

export function SuccessTag(props: Props) {
  return <Tag {...props} variant="success" />;
}

export function DangerTag(props: Props) {
  return <Tag {...props} variant="danger" />;
}

export function InfoTag(props: Props) {
  return <Tag {...props} variant="info" />;
}
