import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import TextInput from "components/FullProgramming/common/TextInput";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import BarCodeScanner from "components/MobileCamera/MobileCameraCheck";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import {
  ZoneSensorType,
  ZoneTransmitterContact,
} from "securecom-graphql/client";
import {
  useHardwareModel,
  usePanelFragment,
  useSoftwareVersion,
  useWirelessZoneNumberRange,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import {
  isNumberWithinIndexRange,
  isNumberWithinRange,
  resolveZoneFieldRules,
} from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationSerialNumberField_zone$key } from "./__generated__/ZoneInformationSerialNumberField_zone.graphql";
import { ZoneInformationSerialNumberField_ZoneList_panel$key } from "./__generated__/ZoneInformationSerialNumberField_ZoneList_panel.graphql";

export const ZONE_INFORMATION_SERIAL_NUMBER = "zone-information-serial-number-";

export const zoneInformationSerialNumberFieldId = (number: string) =>
  ZONE_INFORMATION_SERIAL_NUMBER + number;

//Calculates the Sensor Type based on the SN range if it is unknown it returns Unknown Device
const calculateSensorType = (serialNumber: string): ZoneSensorType => {
  switch (serialNumber) {
    case serialNumber.match(/^[0][1]\d{6}?$/)?.input: //01000000-01999999
      return ZoneSensorType.UNIVERSAL_TRANSMITTER;
    case serialNumber.match(/^[0][2]\d{6}?$/)?.input: //02000000-02999999
      return ZoneSensorType.PIR_OR_RECESSED_DOOR;
    case serialNumber.match(/^[0][3]\d{6}?$/)?.input: //03000000-03999999
      return ZoneSensorType.GLASSBREAK;
    case serialNumber.match(/^[0][4]\d{6}?$/)?.input: //04000000-04999999
      return ZoneSensorType.HOLD_UP;
    case serialNumber.match(/^[0][67]\d{6}?$/)?.input: //06000000-07999999
      return ZoneSensorType.SMOKE;
    case serialNumber.match(/^[0][8]\d{6}?$/)?.input: //08000000-08999999
      return ZoneSensorType.WIRELESS_FOUR_ZONE_EXPANDER;
    case serialNumber.match(/^[0][9][1-9]\d{5}?$/)?.input: //09100000-09999999
      return ZoneSensorType.WIRELESS_PIR;
    case serialNumber.match(/^[1][3]\d{6}?$/)?.input: //13000000-13999999
      return ZoneSensorType.REPEATER;
    default:
      return ZoneSensorType.UNKNOWN_DEVICE;
  }
};

function ZoneInformationSerialNumberField() {
  const [
    {
      serialNumber,
      wireless,
      competitorWireless,
      wirelessDisarmDisableEnabled,
      id,
      number,
    },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationSerialNumberField_zone$key>(
    graphql`
      fragment ZoneInformationSerialNumberField_zone on Zone {
        id
        serialNumber
        number
        wireless
        competitorWireless
        wirelessDisarmDisableEnabled
        contactNumber
      }
    `
  );

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const hardwareModel = useHardwareModel();
  const showAlert = useShowAlert();
  const softwareVersion = useSoftwareVersion();
  const supports1168 = panelVersionGTOE(192, softwareVersion);
  const wirelessZoneNumberRange = useWirelessZoneNumberRange();

  const [panel] =
    usePanelFragment<ZoneInformationSerialNumberField_ZoneList_panel$key>(
      graphql`
        fragment ZoneInformationSerialNumberField_ZoneList_panel on Panel {
          zoneInformations {
            id
            number
            serialNumber
            wireless
            contactNumber
          }
        }
      `
    );

  const fieldId = zoneInformationSerialNumberFieldId(String(number));
  const disabled = !wireless && !competitorWireless;

  const {
    SERIAL_NUMBER: RULES,
    COMPETITOR_WIRELESS_SERIAL_NUMBER: COMPETITOR_WIRELESS_RULES,
  } = resolveZoneFieldRules(hardwareModel);

  return (
    <>
      <ProgrammingConceptForm.Field
        fieldId={fieldId}
        label="Serial Number"
        disabled={disabled}
      >
        <TextInput
          id={fieldId}
          name={fieldId}
          value={wireless || competitorWireless ? serialNumber : ""}
          disabled={disabled}
          required={!disabled}
          className={`zone-${number}-textbox`}
          pattern={
            competitorWireless
              ? COMPETITOR_WIRELESS_RULES?.PATTERN
              : RULES.PATTERN
          }
          inlineHelp={
            competitorWireless
              ? COMPETITOR_WIRELESS_RULES?.INLINE_HELP
              : RULES.INLINE_HELP
          }
          validationMessage={
            competitorWireless
              ? COMPETITOR_WIRELESS_RULES?.VALIDATION_MSG
              : RULES.VALIDATION_MSG
          }
          maxLength={8}
          onChange={({ target }) => {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(target.value, "serialNumber");
            });

            const sensorType = calculateSensorType(target.value);
            //Update the Sensor Type and check for duplicates and updates the contact number
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(sensorType, "sensorType");
              if (wirelessDisarmDisableEnabled) {
                recordProxy.setValue(
                  [
                    ZoneSensorType.UNIVERSAL_TRANSMITTER,
                    ZoneSensorType.WIRELESS_PIR,
                  ].includes(sensorType),
                  "wirelessDisarmDisableEnabled"
                );
              }
              //If ZONE_TYPE.GLASSBREAK set contact to external
              if (sensorType === ZoneSensorType.GLASSBREAK) {
                recordProxy.setValue(
                  ZoneTransmitterContact.EXTERNAL,
                  "contactNumber"
                );
              } else {
                recordProxy.setValue(
                  ZoneTransmitterContact.INTERNAL,
                  "contactNumber"
                );
              }
              if (sensorType !== ZoneSensorType.HOLD_UP) {
                recordProxy.setValue(false, "wirelessLedEnabled");
              }
              if (
                !competitorWireless &&
                ([
                  ZoneSensorType.UNIVERSAL_TRANSMITTER,
                  ZoneSensorType.WIRELESS_FOUR_ZONE_EXPANDER,
                ].includes(sensorType) ||
                  target.value.match(/^[0][7]\d{6}?$/)) //1168 transmitters have multiple contacts and need to be checked
              ) {
                const matchingZones = panel.zoneInformations.filter(
                  (zone) => zone.id !== id && zone.serialNumber === target.value
                );
                if (matchingZones.length > 0) {
                  const contactsList = new Set(
                    matchingZones
                      .filter((zone) =>
                        isNotNullOrUndefined(zone.contactNumber)
                      )
                      .map((zone) => zone.contactNumber!)
                  );
                  const matchingZoneNumbers = matchingZones.map((zone) =>
                    Number(zone.number)
                  );
                  const setSerialNumberToBlank = () =>
                    recordProxy.setValue("", "serialNumber");
                  recordProxy.setValue(
                    ZoneSensorType.UNKNOWN_DEVICE,
                    "sensorType"
                  );
                  // Takes care of 01 Universal Transmitter Duplicates
                  if (sensorType === ZoneSensorType.UNIVERSAL_TRANSMITTER) {
                    if (matchingZones.length >= 2) {
                      //too many of this SN for the # of contacts
                      setSerialNumberToBlank();
                      showAlert({
                        type: "error",
                        text: "Zones have the same serial number.  Contacts must be different.",
                      });
                    } else if (
                      //SN that is duplicated is not within range of this zone
                      !isNumberWithinRange(
                        Number(number),
                        matchingZoneNumbers,
                        2
                      )
                    ) {
                      setSerialNumberToBlank();
                      showAlert({
                        type: "error",
                        text: "Zones for type 01 devices with matching serial numbers must be sequential.",
                      });
                    } else {
                      const nextAvailableContact = setFirst(
                        setDifference(
                          contactsList,
                          new Set([
                            ZoneTransmitterContact.INTERNAL,
                            ZoneTransmitterContact.EXTERNAL,
                          ])
                        )
                      ) as ZoneTransmitterContact;
                      recordProxy.setValue(
                        nextAvailableContact,
                        "contactNumber"
                      );
                    }
                  }
                  // Takes care of 08 Wireless 4 Zone Transmitters
                  else if (
                    sensorType === ZoneSensorType.WIRELESS_FOUR_ZONE_EXPANDER
                  ) {
                    if (matchingZones.length >= 4) {
                      //too many of this SN for the # of contacts
                      setSerialNumberToBlank();
                      showAlert({
                        type: "error",
                        text: "Zones have the same serial number.  Contacts must be different.",
                      });
                    } else if (
                      //SN that is duplicated is not within range of this zone
                      !isNumberWithinRange(
                        Number(number),
                        matchingZoneNumbers,
                        4
                      )
                    ) {
                      setSerialNumberToBlank();
                      showAlert({
                        type: "error",
                        text: "Zones for type 08 devices with matching serial numbers must be sequential.",
                      });
                    } else {
                      const nextAvailableContact = setFirst(
                        setDifference(
                          contactsList,
                          new Set([
                            ZoneTransmitterContact.INTERNAL,
                            ZoneTransmitterContact.EXTERNAL,
                            ZoneTransmitterContact.CONTACT_3,
                            ZoneTransmitterContact.CONTACT_4,
                          ])
                        )
                      ) as ZoneTransmitterContact;
                      recordProxy.setValue(
                        nextAvailableContact,
                        "contactNumber"
                      );
                    }
                  }
                  //Takes care of 1168 wireless contacts
                  else if (target.value.match(/^[0][7]\d{6}?$/)) {
                    if (supports1168) {
                      //What to do if 1168 is supported
                      if (matchingZones.length >= 3) {
                        //Too many of this SN
                        setSerialNumberToBlank();
                        showAlert({
                          type: "error",
                          text: "Zones have the same serial number.  Contacts must be different.",
                        });
                      } else if (
                        //Out of range of existing SN
                        !isNumberWithinIndexRange(
                          Number(number),
                          matchingZoneNumbers,
                          wirelessZoneNumberRange,
                          3
                        )
                      ) {
                        setSerialNumberToBlank();
                        showAlert({
                          type: "error",
                          text: "Zones for type 07 devices with matching serial numbers must be sequential.",
                        });
                      } else {
                        //In the right zone.  Now what to select?
                        if (
                          //If this is the fist of this SN or If this is a lower number than all of the other uses of this SN set to Internal contact
                          matchingZoneNumbers.length === 0 ||
                          matchingZoneNumbers.every(
                            (num) => Number(number) < num
                          )
                        ) {
                          recordProxy.setValue(
                            ZoneTransmitterContact.INTERNAL,
                            "contactNumber"
                          );
                        } else {
                          //If it is not the first use of this SN then pick the first available contact that is left
                          const nextAvailableContact = setFirst(
                            setDifference(
                              contactsList,
                              new Set([
                                ZoneTransmitterContact.INTERNAL,
                                ZoneTransmitterContact.EXTERNAL,
                                ZoneTransmitterContact.CONTACT_3,
                              ])
                            )
                          ) as ZoneTransmitterContact;
                          recordProxy.setValue(
                            nextAvailableContact,
                            "contactNumber"
                          );
                        }
                      }
                    } // What to do if 1168 is not supported. Treat it like any other fire zone.
                    else
                      recordProxy.setValue(
                        ZoneTransmitterContact.INTERNAL,
                        "contactNumber"
                      );
                  }
                }
              }
            });
          }}
          onBlur={({ target }) => {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(target.value, "serialNumber");
            });
            //Update the Sensor Type
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(
                calculateSensorType(target.value),
                "sensorType"
              );
            });
          }}
        />
        {windowWidth <= 800 || window.navigator.userAgent.includes("iPad") ? (
          <BarCodeScanner
            updateZoneInformation={updateZoneInformation}
            wireless={wireless}
          />
        ) : null}
      </ProgrammingConceptForm.Field>
    </>
  );
}

export default ZoneInformationSerialNumberField;
