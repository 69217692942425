import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreIpv6AddressField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreIpv6AddressField_remoteOptions.graphql";

export const remoteOptionsEntreIpv6AddressFieldId = () =>
  "remote-options-entre-ipv6-address";

function RemoteOptionsEntreIpv6AddressField() {
  const [
    { entreIpv6Address, entreConnection, entreIpv6 },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsEntreIpv6AddressField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsEntreIpv6AddressField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          entreIpv6Address
          entreConnection
          entreIpv6
        }
      }
    `
  );

  const fieldId = remoteOptionsEntreIpv6AddressFieldId();
  const disabled =
    entreConnection === RemoteOptionsConnectionType.NONE || !entreIpv6;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré IPv6 Address"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={entreIpv6Address || ""}
        maxLength={39}
        pattern="(([0-9a-fA-F]{4}:){7}[0-9a-fA-F]{4})"
        validationMessage="Please enter a valid IPv6 address"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "entreIpv6Address")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreIpv6AddressField;
