import { RadialInfoIcon } from "common/components/web/Icons";
import { SmallButton } from "components/Button";
import { Flex } from "components/DaStyledElements";
import Modal from "components/Modal";
import React from "react";
import styled from "styled-components";

const MessageParagraph = styled.div`
  font-size: 1.4rem;
  line-height: 1.8;
  color: var(--color-neutral-900);
`;

const RemoteLinkMessage = () => {
  return (
    <>
      <p>
        <strong>
          If using the Link Server application, update Link Server before
          completing the System Link update on attached client computers.
        </strong>
      </p>
      <p>
        <strong>
          Remote Link Version 1.41 and higher require Windows 2000 or later
          Operating System to support Cellular activations.
        </strong>
      </p>
      <ol>
        <li className="pad-b-12">
          Download the Remote or System Link Update file from DMP Dealer Direct
          Software Downloads.
        </li>
        <li className="pad-b-12">
          On the client computer, double click on the LinkUpdate.exe file to run
          the automatic extraction utility.
        </li>
        <li
          className="
          pad-b-12"
        >
          When prompted where to install, choose the directory where Remote or
          System Link is currently installed (Ex: C:Link)
        </li>
        <li className="pad-b-12">
          When setup is complete, the client computer is now updated.
        </li>
      </ol>
    </>
  );
};

const RemoteLinkInfoModal: React.FC<
  React.PropsWithChildren<{
    closeModal: () => void;
  }>
> = ({ closeModal }) => {
  return (
    <Modal size="medium">
      <Flex.Row align={"center"} justify={"flex-start"}>
        <Flex.Col size={1} className="h3 pad-h-16">
          <RadialInfoIcon color="var(--color-info-500)" />
          <span className="mar-l-8 color-primary-500">Notice</span>
        </Flex.Col>
      </Flex.Row>
      <hr className="mar-t-0" />

      <Modal.Body className="pad-h-24">
        <Flex.Row>
          <MessageParagraph>{RemoteLinkMessage()}</MessageParagraph>
        </Flex.Row>
      </Modal.Body>
      <Modal.Footer>
        <Flex.Row justify={"flex-end"}>
          <SmallButton type="reset" text="Close" onClick={closeModal} />
        </Flex.Row>
      </Modal.Footer>
    </Modal>
  );
};
export default RemoteLinkInfoModal;
