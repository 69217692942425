/**
 * @generated SignedSource<<61f90a8f222e96aef19a11d8bee6a9dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly outputGroups: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputNumber1: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber3: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber4: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber5: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber6: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber7: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber8: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "0ef759f617842bb409b0f1d3ba0e91c3";

export default node;
