/**
 * Move systems between customers
 */
App.factory("MoveSystemToCustomerService", [
  "$q",
  "UserService",
  "MoveSystemToCustomerAPI",
  function ($q, UserService, MoveSystemToCustomerAPI) {
    return {
      moveSystem: function (currentCustomerId, nextCustomerId, systemId) {
        let deferred = $q.defer();

        MoveSystemToCustomerAPI.moveSystem(
          {
            controlSystemId: systemId,
            oldCustomerId: currentCustomerId,
            newCustomerId: nextCustomerId,
            dealerId: UserService.dealerInfo.id,
          },
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },
    };
  },
]);
