import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { remoteOptionsApnFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsApnField";
import { remoteOptionsAppKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { remoteOptionsEntreBackupConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupConnectionField";
import { remoteOptionsEntreBackupIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupIpAddress";
import { remoteOptionsEntreBackupTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreBackupTcpPortField";
import { remoteOptionsEntreCheckInMinutesFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreCheckInMinutesField";
import { remoteOptionsEntreConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreConnectionField";
import { remoteOptionsEntreIncomingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIncomingTcpPortField";
import { remoteOptionsEntreIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreIpAddressField";
import { remoteOptionsEntreOutgoingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreOutgoingTcpPortField";
import { remoteOptionsEntrePassphraseFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntrePassphraseField";
import { remoteOptionsEntreSupervisoryReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreSupervisoryReportsField";
import { remoteOptionsEntreUserCommandReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreUserCommandReportsField";
import { remoteOptionsEntreZoneReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsEntreZoneReportsField";
import { remoteOptionsIntegratorBackupConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupConnectionField";
import { remoteOptionsIntegratorBackupIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupIpAddressField";
import { remoteOptionsIntegratorBackupTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorBackupTcpPortField";
import { remoteOptionsIntegratorConnectionFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorConnectionField";
import { remoteOptionsIntegratorIncomingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorIncomingTcpPortField";
import { remoteOptionsIntegratorIpAddressFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorIpAddressField";
import { remoteOptionsIntegratorOutgoingTcpPortFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorOutgoingTcpPortField";
import { remoteOptionsIntegratorPassphraseFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorPassphraseField";
import { remoteOptionsIntegratorSupervisoryReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorSupervisoryReportsField";
import { remoteOptionsIntegratorUserCommandReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorUserCommandReportsField";
import { remoteOptionsIntegratorZoneReportsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsIntegratorZoneReportsField";
import { remoteOptionsRemoteKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteKeyField";
import { XFRemoteOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XFFullProgramming/__generated__/XFRemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getRemoteOptionsState } from "../../../XFFullProgramming/XFRemoteOptionsProgrammingConceptForm";
import { XfProgrammingTemplateRemoteOptionsInput } from "../Create/__generated__/XFTemplateSaveMutation.graphql";
import { XFRemoteOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFRemoteOptionsTemplateDataInline_xfProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XFRemoteOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFRemoteOptionsTemplateDataInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          id
          included
          remoteKey {
            data
            included
          }
          appKey {
            data
            included
          }
          firstApn {
            data
            included
          }
          entreConnection {
            data
            included
          }
          entreIncomingPort {
            data
            included
          }
          entreIpAddress {
            data
            included
          }
          entreOutgoingPort {
            data
            included
          }
          entreBackupConnection {
            data
            included
          }
          entreBackupIpAddress {
            data
            included
          }
          entreBackupIncomingPort {
            data
            included
          }
          entreCheckInMinutes {
            data
            included
          }
          entreZoneReports {
            data
            included
          }
          entreUserCommandReports {
            data
            included
          }
          entreSupervisoryReports {
            data
            included
          }
          validEntreCheckinMinutes {
            data
            included
          }
          entrePassphrase {
            data
            included
          }
          integratorConnection {
            data
            included
          }
          integratorIncomingPort {
            data
            included
          }
          integratorIpAddress {
            data
            included
          }
          integratorOutgoingPort {
            data
            included
          }
          integratorBackupConnection {
            data
            included
          }
          integratorBackupIpAddress {
            data
            included
          }
          integratorBackupIncomingPort {
            data
            included
          }
          integratorZoneReports {
            data
            included
          }
          integratorUserCommandReports {
            data
            included
          }
          integratorSupervisoryReports {
            data
            included
          }
          integratorPassphrase {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XFRemoteOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XfProgrammingTemplateRemoteOptionsInput => {
  const {
    panel: { remoteOptions },
  } = getRemoteOptionsState(controlSystem);

  if (!remoteOptions) {
    return { included: false };
  }

  const remoteOptionsInputFields =
    remoteOptions?.__typename === "XfRemoteOptions"
      ? {
          remoteKey: {
            data: remoteOptions.remoteKey,
            included: includedFields.has(remoteOptionsRemoteKeyFieldId()),
          },
          appKey: {
            data: remoteOptions.appKey,
            included: includedFields.has(remoteOptionsAppKeyFieldId()),
          },
          firstApn: {
            data: remoteOptions.firstApn,
            included: includedFields.has(remoteOptionsApnFieldId()),
          },
          entreConnection: {
            data: remoteOptions.entreConnection,
            included: includedFields.has(remoteOptionsEntreConnectionFieldId()),
          },
          entreIncomingPort: {
            data: remoteOptions.entreIncomingPort,
            included: includedFields.has(
              remoteOptionsEntreIncomingTcpPortFieldId()
            ),
          },
          entreIpAddress: {
            data: remoteOptions.entreIpAddress,
            included: includedFields.has(remoteOptionsEntreIpAddressFieldId()),
          },
          entreOutgoingPort: {
            data: remoteOptions.entreOutgoingPort,
            included: includedFields.has(
              remoteOptionsEntreOutgoingTcpPortFieldId()
            ),
          },
          entreBackupConnection: {
            data: remoteOptions.entreBackupConnection,
            included: includedFields.has(
              remoteOptionsEntreBackupConnectionFieldId()
            ),
          },
          entreBackupIpAddress: {
            data: remoteOptions.entreBackupIpAddress,
            included: includedFields.has(
              remoteOptionsEntreBackupIpAddressFieldId()
            ),
          },
          entreBackupIncomingPort: {
            data: remoteOptions.entreBackupIncomingPort,
            included: includedFields.has(
              remoteOptionsEntreBackupTcpPortFieldId()
            ),
          },
          entreCheckInMinutes: {
            data: remoteOptions.entreCheckInMinutes,
            included: includedFields.has(
              remoteOptionsEntreCheckInMinutesFieldId()
            ),
          },
          entreZoneReports: {
            data: remoteOptions.entreZoneReports,
            included: includedFields.has(
              remoteOptionsEntreZoneReportsFieldId()
            ),
          },
          entreUserCommandReports: {
            data: remoteOptions.entreUserCommandReports,
            included: includedFields.has(
              remoteOptionsEntreUserCommandReportsFieldId()
            ),
          },
          entreSupervisoryReports: {
            data: remoteOptions.entreSupervisoryReports,
            included: includedFields.has(
              remoteOptionsEntreSupervisoryReportsFieldId()
            ),
          },
          entrePassphrase: {
            data: remoteOptions.entrePassphrase,
            included: includedFields.has(remoteOptionsEntrePassphraseFieldId()),
          },
          integratorConnection: {
            data: remoteOptions.integratorConnection,
            included: includedFields.has(
              remoteOptionsIntegratorConnectionFieldId()
            ),
          },
          integratorIncomingPort: {
            data: remoteOptions.integratorIncomingPort,
            included: includedFields.has(
              remoteOptionsIntegratorIncomingTcpPortFieldId()
            ),
          },
          integratorIpAddress: {
            data: remoteOptions.integratorIpAddress,
            included: includedFields.has(
              remoteOptionsIntegratorIpAddressFieldId()
            ),
          },
          integratorOutgoingPort: {
            data: remoteOptions.integratorOutgoingPort,
            included: includedFields.has(
              remoteOptionsIntegratorOutgoingTcpPortFieldId()
            ),
          },
          integratorBackupConnection: {
            data: remoteOptions.integratorBackupConnection,
            included: includedFields.has(
              remoteOptionsIntegratorBackupConnectionFieldId()
            ),
          },
          integratorBackupIpAddress: {
            data: remoteOptions.integratorBackupIpAddress,
            included: includedFields.has(
              remoteOptionsIntegratorBackupIpAddressFieldId()
            ),
          },
          integratorBackupIncomingPort: {
            data: remoteOptions.integratorBackupIncomingPort,
            included: includedFields.has(
              remoteOptionsIntegratorBackupTcpPortFieldId()
            ),
          },
          integratorZoneReports: {
            data: remoteOptions.integratorZoneReports,
            included: includedFields.has(
              remoteOptionsIntegratorZoneReportsFieldId()
            ),
          },
          integratorUserCommandReports: {
            data: remoteOptions.integratorUserCommandReports,
            included: includedFields.has(
              remoteOptionsIntegratorUserCommandReportsFieldId()
            ),
          },
          integratorSupervisoryReports: {
            data: remoteOptions.integratorSupervisoryReports,
            included: includedFields.has(
              remoteOptionsIntegratorSupervisoryReportsFieldId()
            ),
          },
          integratorPassphrase: {
            data: remoteOptions.integratorPassphrase,
            included: includedFields.has(
              remoteOptionsIntegratorPassphraseFieldId()
            ),
          },
        }
      : {};

  const remoteOptionsIncluded = Object.values(remoteOptionsInputFields).some(
    (field) => field?.included
  );

  return { included: remoteOptionsIncluded, ...remoteOptionsInputFields };
};

export const setIncludedFields = (
  template: XFRemoteOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { remoteOptions } = getState(template);

  if (!remoteOptions) return;

  if (remoteOptions.remoteKey?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteKeyFieldId()));
  }
  if (remoteOptions.appKey?.included) {
    setIncludedFields(setToggle(remoteOptionsAppKeyFieldId()));
  }
  if (remoteOptions.firstApn?.included) {
    setIncludedFields(setToggle(remoteOptionsApnFieldId()));
  }
  if (remoteOptions.entreConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreConnectionFieldId()));
  }
  if (remoteOptions.entreIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreIncomingTcpPortFieldId()));
  }
  if (remoteOptions.entreIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreIpAddressFieldId()));
  }
  if (remoteOptions.entreOutgoingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreOutgoingTcpPortFieldId()));
  }
  if (remoteOptions.entreBackupConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupConnectionFieldId()));
  }
  if (remoteOptions.entreBackupIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupIpAddressFieldId()));
  }
  if (remoteOptions.entreBackupIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreBackupTcpPortFieldId()));
  }
  if (remoteOptions.entreCheckInMinutes?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreCheckInMinutesFieldId()));
  }
  if (remoteOptions.entreZoneReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreZoneReportsFieldId()));
  }
  if (remoteOptions.entreUserCommandReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreUserCommandReportsFieldId()));
  }
  if (remoteOptions.entreSupervisoryReports?.included) {
    setIncludedFields(setToggle(remoteOptionsEntreSupervisoryReportsFieldId()));
  }
  if (remoteOptions.entrePassphrase?.included) {
    setIncludedFields(setToggle(remoteOptionsEntrePassphraseFieldId()));
  }
  if (remoteOptions.integratorConnection?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorConnectionFieldId()));
  }
  if (remoteOptions.integratorIncomingPort?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorIncomingTcpPortFieldId())
    );
  }
  if (remoteOptions.integratorIpAddress?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorIpAddressFieldId()));
  }
  if (remoteOptions.integratorOutgoingPort?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorOutgoingTcpPortFieldId())
    );
  }
  if (remoteOptions.integratorBackupConnection?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorBackupConnectionFieldId())
    );
  }
  if (remoteOptions.integratorBackupIpAddress?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorBackupIpAddressFieldId())
    );
  }
  if (remoteOptions.integratorBackupIncomingPort?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorBackupTcpPortFieldId()));
  }
  if (remoteOptions.integratorZoneReports?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorZoneReportsFieldId()));
  }
  if (remoteOptions.integratorUserCommandReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorUserCommandReportsFieldId())
    );
  }
  if (remoteOptions.integratorSupervisoryReports?.included) {
    setIncludedFields(
      setToggle(remoteOptionsIntegratorSupervisoryReportsFieldId())
    );
  }
  if (remoteOptions.integratorPassphrase?.included) {
    setIncludedFields(setToggle(remoteOptionsIntegratorPassphraseFieldId()));
  }
};
