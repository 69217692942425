/**
 * @ngdoc service
 * @name App.factory:VideoService
 *
 * @description
 * API Interface service for Video.
 *
 */
App.factory("VideoService", [
  "$q",
  "$http",
  "PROPS",
  "VideoV2API",
  "VarVideoVerificationAPI",
  "CombinedEventsAPI",
  function (
    $q,
    $http,
    PROPS,
    VideoV2API,
    VarVideoVerificationAPI,
    CombinedEventsAPI
  ) {
    function separateTimeAndMessage(fullMessageString) {
      const substrings = fullMessageString.split("\r\n");
      const timeSubstring = substrings[0];
      const messageSubstring = substrings[1];
      const messageSplitSubstring = messageSubstring.split(" on ");
      const detectedSubstring = messageSplitSubstring[0];
      const nameSubstring = messageSplitSubstring[1];
      return {
        timeSubstring: timeSubstring,
        nameSubstring: nameSubstring,
        detectedSubstring: detectedSubstring,
      };
    }
    return {
      index: function (control_system_id) {
        var deferred = $q.defer();
        var _this = this;
        VideoV2API.index(
          { control_system_id: control_system_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      testConnection: function (camera_id) {
        var deferred = $q.defer();
        var _this = this;
        VideoV2API.test(
          { camera_id: camera_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      //get the xv cameras formerly known as var cameras
      getVarVVCameras: function (controlSystemId) {
        const deferred = $q.defer();
        VarVideoVerificationAPI.getCameras(
          { control_system_id: controlSystemId },
          (data) => {
            deferred.resolve(data);
          },
          (error) => {
            deferred.error(error);
          }
        );

        return deferred.promise;
      },
      //get the v-6000 camera events raw without parsing
      getV6000Events: function (panelId, event_at) {
        const deferred = $q.defer();
        CombinedEventsAPI.getV6000Events(
          { panel_id: panelId, event_at: event_at },
          (data) => {
            deferred.resolve(data);
          },
          (error) => {
            deferred.error(error);
          }
        );

        return deferred.promise;
      },
      //get the v-6000 camera events with modified data
      getV6000EventsParsed: function (panelId, event_at) {
        const deferred = $q.defer();
        CombinedEventsAPI.getV6000Events(
          { panel_id: panelId, event_at: event_at },
          (data) => {
            const v6000Clips = data.value
              .map((v6000Clip) => ({
                ...v6000Clip,
              }))
              .map((v6000Clip) => {
                const { timeSubstring, nameSubstring, detectedSubstring } =
                  separateTimeAndMessage(v6000Clip.message);
                const wealthObject = JSON.parse(v6000Clip.wealth);
                const eventStreamUrl = wealthObject.event_stream_url;
                const startTimestamp = wealthObject.start_ts_ms;
                return {
                  ...v6000Clip,
                  time: timeSubstring,
                  camera_name: nameSubstring,
                  origin_event_type: "MANUAL",
                  object_detected: detectedSubstring,
                  event_stream_url: eventStreamUrl,
                  start_ts_ms: startTimestamp,
                };
              });
            deferred.resolve(v6000Clips);
          },
          (error) => {
            deferred.error(error);
          }
        );

        return deferred.promise;
      },
      // destroy HLS stream link
      destroyVidproxHlsStream: (hlsURL) => {
        $http.delete(hlsURL);
      },
    };
  },
]);
