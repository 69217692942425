/**
 * @generated SignedSource<<fb1cc9ac6d5ef5e8249ed1b3a7e44a88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationContext_deviceInformation$data = {
  readonly id: string;
  readonly " $fragmentType": "DeviceInformationContext_deviceInformation";
};
export type DeviceInformationContext_deviceInformation$key = {
  readonly " $data"?: DeviceInformationContext_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationContext_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationContext_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "885ad5924771e8a038e4d047da5ea2d6";

export default node;
