/**
 * @generated SignedSource<<2d3e8570bf0edbb52c8d62508fe9fad8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationSecondApnField_communication$data = {
  readonly cellApn2: string;
  readonly " $fragmentType": "CommunicationSecondApnField_communication";
};
export type CommunicationSecondApnField_communication$key = {
  readonly " $data"?: CommunicationSecondApnField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationSecondApnField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationSecondApnField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cellApn2",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "4c597e4a8f5b37c5b113058632a8e7d6";

export default node;
