/**
 * @generated SignedSource<<43e9e5748d059e7ac26980e713e92c10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathCommTypeField_panel$data = {
  readonly communicationPaths: ReadonlyArray<{
    readonly commType: CommunicationPathCommType;
    readonly number: number;
  }>;
  readonly " $fragmentType": "CommPathCommTypeField_panel";
};
export type CommPathCommTypeField_panel$key = {
  readonly " $data"?: CommPathCommTypeField_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathCommTypeField_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathCommTypeField_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CommunicationPath",
      "kind": "LinkedField",
      "name": "communicationPaths",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "a022336912f3c277b7251ace3f0ef4c7";

export default node;
