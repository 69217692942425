/**
 * @generated SignedSource<<be655eeef9b1d2345325a03b90c4779a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$data = {
  readonly integratorArmDisarmReports?: boolean | null;
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions";
};
export type RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integratorArmDisarmReports",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integratorConnection",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "2f0c9ed4444bb06ee1ec8017b0101bab";

export default node;
