import { useRef, useState } from "react";

const useFullscreen = (): [
  boolean,
  React.RefObject<HTMLDivElement>,
  () => void,
  () => void
] => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const enterFullscreen = () => {
    wrapperRef.current?.requestFullscreen();
    wrapperRef.current?.addEventListener(
      "fullscreenchange",
      handleFullscreenChange
    );
  };

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  const exitFullscreen = () => document.exitFullscreen();

  return [isFullscreen, wrapperRef, enterFullscreen, exitFullscreen];
};

export default useFullscreen;
