App.controller("PasswordChangeCtrl", [
  "$scope",
  "$rootScope",
  "$q",
  "UsersV2API",
  "UserService",
  "LoginService",
  "$state",
  "$modalInstance",
  function (
    $scope,
    $rootScope,
    $q,
    UsersV2API,
    UserService,
    LoginService,
    $state,
    $modalInstance
  ) {
    $scope.current_password_type = "password";
    $scope.new_password_type = "password";
    $scope.confirm_password_type = "password";

    $scope.passwordShowHide = function (type) {
      if (type === "current")
        $scope.current_password_type === "password"
          ? ($scope.current_password_type = "text")
          : ($scope.current_password_type = "password");
      if (type === "new")
        $scope.new_password_type === "password"
          ? ($scope.new_password_type = "text")
          : ($scope.new_password_type = "password");
      if (type === "confirm")
        $scope.confirm_password_type === "password"
          ? ($scope.confirm_password_type = "text")
          : ($scope.confirm_password_type = "password");
    };

    $scope.updateClicked = updateClicked;
    // currentUser is html model
    $scope.currentUser = angular.copy(UserService.user);

    $scope.currentPasswordChange = function () {
      delete $scope.confirmError;
    };
    $scope.formFields = {};

    function updateClicked(isMobile) {
      var user = {
        user: {},
      };
      $scope.confirmError = undefined;
      $scope.errors = undefined;
      if ($scope.currentUser.email !== UserService.user.email) {
        user.user.email = $scope.currentUser.email;
      }
      if ($scope.formFields.newPassword) {
        user.user.password = $scope.formFields.newPassword;
      }
      if (user.user.email || user.user.password) {
        confirmCurrentPassword()
          .then(
            function () {
              updateUser(user)
                .then(
                  function () {
                    if (isMobile) {
                      addAuthToDB();
                      $state
                        .go("dealer.home", { dealer_id: UserService.dealer_id })
                        .then(function () {
                          $rootScope.alerts.push({
                            type: "critical",
                            title: "Password Change",
                            text: "Your password was successfully changed.",
                          });
                        })
                        .catch(function (error) {
                          console.error(error);
                        });
                    } else {
                      $rootScope.alerts.push({
                        type: "success",
                        text: "Password Changed",
                      });
                      LoginService.DALoginRouter(UserService.user)
                        .then(function (route) {
                          $state.go(route.to, route.toParams);
                        })
                        .catch(function (error) {
                          console.error(error);
                        });
                    }
                  },

                  function (error) {
                    $scope.errors = handleErrors(error);
                    //$scope.newPassword = '';
                    //$scope.confirmPassword = '';
                    //$scope.currentPassword = '';
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function () {
              $scope.confirmError = "Current password is incorrect";
              $scope.formFields.currentPassword = "";
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    }

    function addAuthToDB() {
      let query;

      if (!$rootScope.keepMeSignedIn.persist_email) {
        // If they enable Keep Me Signed In for the first time, add it to the DB. Otherwise, update with their choice
        query = "INSERT INTO KMSI (auth,username) VALUES (?,?)";
      } else {
        query = "UPDATE KMSI SET auth = (?) WHERE username = (?)";
      }
      $rootScope.db.transaction(
        function (tx) {
          tx.executeSql(
            query,
            [UserService.auth_token, UserService.email],
            function (tx, res) {},
            function (tx, error) {}
          );
        },
        function (error) {},
        function () {}
      );
    }

    function confirmCurrentPassword() {
      var deferred = $q.defer();
      LoginService.signIn(
        UserService.user.email,
        $scope.formFields.currentPassword
      )
        .then(
          function (loginData) {
            deferred.resolve(loginData);
          },
          function (loginError) {
            deferred.reject(loginError);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function updateUser(user) {
      var deferred = $q.defer();
      UsersV2API.update(
        {
          user_id: $scope.currentUser.id,
        }, //params
        user,
        function (success) {
          //Login again to get the most current auth token
          if (
            success.user.password ||
            (success.user.email && success.user.password)
          ) {
            LoginService.signIn($scope.currentUser.email, success.user.password)
              .then(
                function (loginData) {
                  UserService.login(
                    loginData.user.email,
                    loginData.user["authentication_token"]
                  );
                  deferred.resolve("success");
                },
                function (loginError) {
                  deferred.reject(loginError);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            deferred.resolve("success");
          }
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function handleErrors(errors) {
      if (angular.isUndefined(this.errors)) {
        this.errors = {};
      }
      return angular.extend(this.errors, errors.data.errors);
    }

    $scope.cancel = function () {
      //Close the dialog
      $modalInstance.dismiss("cancel");
    };
  },
]);
