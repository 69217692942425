/**
 * @generated SignedSource<<0fab8d715512a7ca056a0ca5df9188d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UniviewAnalyticalDetectionElements_secureComCamera$data = {
  readonly analyticalDetectionLines: {
    readonly activeDetectionLineIndex: number | null;
    readonly lines: ReadonlyArray<{
      readonly activePointIndex: number | null;
      readonly detectPerson: boolean;
      readonly detectVehicle: boolean;
      readonly direction: number;
      readonly endX: number;
      readonly endY: number;
      readonly id: string;
      readonly index: number | null;
      readonly sensitivity: number;
      readonly startX: number;
      readonly startY: number;
    }>;
    readonly maxCoordinatesX: number;
    readonly maxCoordinatesY: number;
    readonly minCoordinatesX: number;
    readonly minCoordinatesY: number;
    readonly mouseDown: boolean | null;
  };
  readonly analyticalDetectionRegions: {
    readonly activeDetectionRegionIndex: number | null;
    readonly mouseDown: boolean | null;
    readonly regions: ReadonlyArray<{
      readonly activeCoordinateIndexes: ReadonlyArray<number> | null;
      readonly coordinates: ReadonlyArray<{
        readonly x: number;
        readonly y: number;
      }>;
      readonly id: string;
      readonly index: number | null;
      readonly sensitivity: number;
    }>;
  };
  readonly id: string;
  readonly maxAnalyticalDetectionRegionPoints: number;
  readonly minAnalyticalDetectionRegionPoints: number;
  readonly " $fragmentType": "UniviewAnalyticalDetectionElements_secureComCamera";
};
export type UniviewAnalyticalDetectionElements_secureComCamera$key = {
  readonly " $data"?: UniviewAnalyticalDetectionElements_secureComCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"UniviewAnalyticalDetectionElements_secureComCamera">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sensitivity",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mouseDown",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UniviewAnalyticalDetectionElements_secureComCamera",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalyticalDetectionLines",
      "kind": "LinkedField",
      "name": "analyticalDetectionLines",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minCoordinatesX",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxCoordinatesX",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minCoordinatesY",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxCoordinatesY",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalyticalDetectionLine",
          "kind": "LinkedField",
          "name": "lines",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "direction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startX",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startY",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endX",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endY",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "detectPerson",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "detectVehicle",
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activePointIndex",
                  "storageKey": null
                },
                (v2/*: any*/)
              ]
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activeDetectionLineIndex",
              "storageKey": null
            },
            (v3/*: any*/)
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minAnalyticalDetectionRegionPoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxAnalyticalDetectionRegionPoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalyticalDetectionRegions",
      "kind": "LinkedField",
      "name": "analyticalDetectionRegions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalyticalDetectionRegion",
          "kind": "LinkedField",
          "name": "regions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AnalyticalDetectionRegionCoordinates",
              "kind": "LinkedField",
              "name": "coordinates",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "x",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "y",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activeCoordinateIndexes",
                  "storageKey": null
                },
                (v2/*: any*/)
              ]
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activeDetectionRegionIndex",
              "storageKey": null
            },
            (v3/*: any*/)
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SecureComCamera",
  "abstractKey": null
};
})();

(node as any).hash = "2a5675ffa10f3c214feb36424ad5d847";

export default node;
