import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { CommunicationComType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationBackupCellField_communication$key } from "./__generated__/CommunicationBackupCellField_communication.graphql";

export const communicationBackupCellFieldId = () => "communication-backup-cell";

function CommunicationBackupCellField() {
  const [{ backupCell, comType }, updateCommunication] =
    useCommunicationFragment<CommunicationBackupCellField_communication$key>(
      graphql`
        fragment CommunicationBackupCellField_communication on Communication {
          backupCell
          comType
        }
      `
    );

  const fieldId = communicationBackupCellFieldId();
  const label = "Backup Cell";
  const disabled = [
    CommunicationComType.CELL,
    CommunicationComType.NONE,
  ].includes(comType as CommunicationComType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables backup messages on cell. Tries to send the message over cell after 60 seconds of unsuccessful network communication or 10 dialer attempts."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        checked={backupCell}
        disabled={disabled}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(!backupCell, "backupCell");
            if (backupCell) {
              recordProxy.setValue(0, "backupCheckinMinutes");
              recordProxy.setValue(0, "backupFailTimeMinutes");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationBackupCellField;
