App.service("CredentialsWalletService", [
  "$q",
  "CredentialsWalletApi",
  "JobService",

  function ($q, CredentialsWalletApi, JobService) {
    let _this = this;

    // Accessors
    _this.removeCard = removeCard;

    /**
     * Deletes a digital card credential and removes the card
     * @param {*} credentialId
     * @returns Promise
     */
    function removeCard(credentialId) {
      var deferred = $q.defer();
      CredentialsWalletApi.removeCard(
        { credentialId: credentialId },

        function (success) {
          JobService.process(success.job.uuid).then(
            function (jobData) {
              if (jobData.status === 202 && jobData.uuid) {
                deferred.resolve(jobData);
              }
            },
            function (jobError) {
              deferred.reject(jobError);
            },
            function (notify) {
              deferred.notify(notify);
            }
          );
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
  },
]);
