/**
 * @ngdoc service
 * @name App.factory:PanelsDiscoveryV1API
 *
 * @description
 * API factory that returns a list of panels associated to the dealer
 *
 */
App.factory("ModemFirmwareAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/modem_firmware/:action",
      {},
      {
        updateFirmware: {
          method: "POST",
          params: { panel_id: "@panel_id", action: "update" },
        },
      }
    );
  },
]);
