/**
 * @generated SignedSource<<96f9743a8701cac53785e1e2ec184d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PcLogReportsNetworkIpAddressField_pcLogReports$data = {
  readonly networkIpAddress: string;
  readonly " $fragmentType": "PcLogReportsNetworkIpAddressField_pcLogReports";
};
export type PcLogReportsNetworkIpAddressField_pcLogReports$key = {
  readonly " $data"?: PcLogReportsNetworkIpAddressField_pcLogReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"PcLogReportsNetworkIpAddressField_pcLogReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PcLogReportsNetworkIpAddressField_pcLogReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkIpAddress",
      "storageKey": null
    }
  ],
  "type": "PcLogReports",
  "abstractKey": null
};

(node as any).hash = "be8c98a6fd2b3593f8a3a1647977375b";

export default node;
