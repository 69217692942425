/**
 * @ngdoc service
 * @name App.factory:CombinedEventsAPI
 * @path projects/dealer-admin/src/app/common/api/combined-events-api.js
 * @description
 * API Interface service for CombinedEvents.
 * @requires $resource
 * @requires PROPS
 * */

App.factory("CombinedEventsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.oDataEventHistoriesUrl +
        "/api/v1/panels(:panel_id)/event_histories? &$filter=event_source eq 'v-6000' and created_at ge :event_at &$orderby=created_at desc &$top=100",
      {},
      {
        getV6000Events: {
          method: "GET",
          params: {
            panel_id: "@panel_id",
            event_at: "@event_at",
          },
        },
      }
    );
  },
]);
