/**
 * @generated SignedSource<<e33596e6d2069e889dfff87e64642ffa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteOutputModulesFormX1Selector_site$data = {
  readonly x1DoorAccessControlSystems: ReadonlyArray<{
    readonly availableOutputModulesCount: number;
    readonly id: string;
    readonly namedDoors: ReadonlyArray<{
      readonly name: string;
    }>;
    readonly outputModulesConnection: {
      readonly totalCount: number;
    };
    readonly serialNumber: string;
    readonly supportsOutputModules: boolean;
  }>;
  readonly " $fragmentType": "SiteOutputModulesFormX1Selector_site";
};
export type SiteOutputModulesFormX1Selector_site$key = {
  readonly " $data"?: SiteOutputModulesFormX1Selector_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesFormX1Selector_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteOutputModulesFormX1Selector_site",
  "selections": [
    {
      "alias": "x1DoorAccessControlSystems",
      "args": [
        {
          "kind": "Literal",
          "name": "include",
          "value": "DOOR_ACCESS"
        }
      ],
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": "namedDoors",
          "args": null,
          "concreteType": "SiteDoor",
          "kind": "LinkedField",
          "name": "doors",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportsOutputModules",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availableOutputModulesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteOutputModulesConnection",
          "kind": "LinkedField",
          "name": "outputModulesConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "controlSystems(include:\"DOOR_ACCESS\")"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "cd830d1a44e75b8c209d9a718e502c6f";

export default node;
