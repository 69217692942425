import graphql from "babel-plugin-relay/macro";
import { camelCaseToTitleCase } from "common/utils";
import Icon from "components/Icon";
import { GenericConfirmModal } from "components/Modal/ConfirmModal";
import { CameraStatusBadge } from "components/VarStatusBadge";
import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { InputLabel, ModalInputContainer } from "./CameraEditStyledComponents";
import { CameraDetailsModal_sdCard_Query } from "./__generated__/CameraDetailsModal_sdCard_Query.graphql";

interface CameraDetailsModalProps {
  cameraId: string;
  status: boolean;
  cameraName: string;
  macAddress: string;
  serialNumber?: string | null;
  ipAddress?: string;
  resolution?: number;
  userName?: string;
  password?: string;
  activationStatus?: string;
  timeZone?: string;
  daylightSavingsTime?: boolean;
  lastCheckIn?: string | null;
  wirelessSignalStrength?: number | null;
  firmwareVersion?: string | null;
  onFormatSdCard?: () => void;
  onCancel: () => void;
  ableToFormatSdCard?: boolean;
}
function CameraDetailsModal(props: CameraDetailsModalProps) {
  const {
    cameraId,
    status,
    userName,
    onCancel,
    onFormatSdCard,
    ableToFormatSdCard,
  } = props;

  return (
    <>
      <GenericConfirmModal
        header="Camera Details"
        cancelText="Close"
        onCancel={onCancel}
        suppressConfirm={true}
      >
        <ModalInputContainer>
          <CameraStatusBadge status={status} />
        </ModalInputContainer>

        {Object.entries(props).map((field) => {
          let title = camelCaseToTitleCase(field[0]);
          let value = field[1] || "";
          let placeholder = "";
          let show = true;
          let type = "text";

          switch (field[0]) {
            case "status":
            case "onCancel":
              show = false;
              break;
            case "macAddress":
              title = "MAC Address";
              placeholder = "MAC Address Not Available";
              break;
            case "ipAddress":
              title = "IP Address";
              break;
            case "resolution":
              value = `${value} MP`;
              placeholder = "0 MP";
              break;
            case "userName":
              show = field[1] !== "";
              break;
            case "password":
              show = userName !== "";
              break;
            case "daylightSavingsTime":
              value = value.toString().toUpperCase();
              break;
            case "lastCheckIn":
              value = value.slice(0, -6); // Dropping timezone info from datetime string
              type = "datetime-local";
              break;
            case "wirelessSignalStrength":
              value = value === "" ? "Wireless Not Connected" : (value += "%");
              break;
            case "onFormatSdCard":
              show = false;
              break;
            case "ableToFormatSdCard":
              show = false;
              break;
            default:
              break;
          }

          return (
            show && (
              <ModalInputContainer>
                <div className="col-xs-4">
                  <InputLabel>{title}</InputLabel>
                </div>
                <div className="col-xs-8">
                  <input
                    type={type}
                    className="form-control"
                    disabled
                    value={value}
                    placeholder={placeholder}
                  />
                </div>
              </ModalInputContainer>
            )
          );
        })}
        <ModalInputContainer>
          <Suspense fallback={<SdCardStatusSuspense />}>
            <SdCardStatusField
              cameraId={cameraId}
              onFormatSdCard={onFormatSdCard}
              onCancel={onCancel}
              ableToFormatSdCard={ableToFormatSdCard}
            />
          </Suspense>
        </ModalInputContainer>
      </GenericConfirmModal>
    </>
  );
}

interface SdCardStatusProps {
  cameraId: string;
  onFormatSdCard?: () => void;
  onCancel: () => void;
  ableToFormatSdCard?: boolean;
}

function SdCardStatusField(props: SdCardStatusProps) {
  const { cameraId, onFormatSdCard, onCancel, ableToFormatSdCard } = props;

  const [formatSdCardModalIsOpen, setFormatSdCardModalIsOpen] =
    React.useState(false);

  const sdCardData = useLazyLoadQuery<CameraDetailsModal_sdCard_Query>(
    graphql`
      query CameraDetailsModal_sdCard_Query($cameraId: ID!) {
        camera: node(id: $cameraId) {
          ... on SecureComCamera {
            sdCardStatus
          }
        }
      }
    `,
    {
      cameraId: cameraId,
    },
    { fetchPolicy: "network-only" }
  );

  return (
    <>
      <div className="col-xs-4">
        <InputLabel>{"SD Card Status"}</InputLabel>
      </div>
      <div className="col-xs-8">
        <div className="input-group ">
          <input
            type="text"
            className="form-control"
            disabled
            value={
              sdCardData.camera?.sdCardStatus
                ? sdCardData.camera.sdCardStatus.toUpperCase()
                : "SD Card Not Available"
            }
          />
          {ableToFormatSdCard ? (
            <span className="input-group-btn">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => setFormatSdCardModalIsOpen(true)}
              >
                Format
              </button>
            </span>
          ) : null}
        </div>
      </div>

      {formatSdCardModalIsOpen && (
        <GenericConfirmModal
          header={"Format SD Card"}
          children={
            "Are you sure you want to format the SD Card? Any existing video recordings will be deleted. This action cannot be undone."
          }
          confirmText={"Proceed"}
          cancelText={"Cancel"}
          pendingText={"Working..."}
          onConfirm={() => {
            onFormatSdCard && onFormatSdCard();
            setFormatSdCardModalIsOpen(false);
            onCancel();
          }}
          onCancel={() => setFormatSdCardModalIsOpen(false)}
          icon={<Icon name="radial_info" />}
        />
      )}
    </>
  );
}

function SdCardStatusSuspense() {
  return (
    <>
      <div className="col-xs-4">
        <InputLabel>{"SD Card Status"}</InputLabel>
      </div>
      <div className="col-xs-8">
        <div className="input-group ">
          <input
            type="text"
            className="form-control"
            disabled
            value={"Status Loading..."}
          />
        </div>
      </div>
    </>
  );
}

export default CameraDetailsModal;
