App.controller("SystemAddedTodayCtrl", [
  "$scope",
  "$q",
  "$filter",
  "$compile",
  "$rootScope",
  "UserService",
  "DashboardDataService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "DealerODataAPI",
  "DataTablesUtilsService",
  function (
    $scope,
    $q,
    $filter,
    $compile,
    $rootScope,
    UserService,
    DashboardDataService,
    DTOptionsBuilder,
    DTColumnBuilder,
    DealerODataAPI,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      getSystemsAddedToday()
    );
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption("order", [0, "asc"]);

    function getSystemsAddedToday() {
      var deferred = $q.defer();
      DashboardDataService.getSystemsAddedForDay($scope.dealerID)
        .then(
          function (newUser) {
            deferred.resolve(newUser);
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    $scope.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "customer.name");
        }),
      ,
      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, "name");
        }),
      ,
      DTColumnBuilder.newColumn("customer.email").withTitle("Customer Email"),
      DTColumnBuilder.newColumn("postal_code")
        .withTitle("Postal Code")
        .notVisible(),
      DTColumnBuilder.newColumn("address_1").withTitle("Address").notVisible(),
      DTColumnBuilder.newColumn("city").withTitle("City").notVisible(),
      DTColumnBuilder.newColumn("state").withTitle("State").notVisible(),
      DTColumnBuilder.newColumn("country").withTitle("Country").notVisible(),
      DTColumnBuilder.newColumn("panels[0].panel_users[0].email")
        .withTitle("Email")
        .notVisible(),
    ];
  },
]);
