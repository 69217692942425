App.controller("SchedulesXRViewCtrl", [
  "$rootScope",
  "$scope",
  "$filter",
  "UserService",
  "SCHEDULES",
  "MultiPanelService",
  "scheduleUtilService",
  "PanelProgArchiveService",
  "ScheduleUseService",
  function (
    $rootScope,
    $scope,
    $filter,
    UserService,
    SCHEDULES,
    MultiPanelService,
    scheduleUtilService,
    PanelProgArchiveService,
    ScheduleUseService
  ) {
    //Used for showing the spinner overlay
    $scope.isBusy = false;

    // Set the local variables to the new control system values
    //$scope.tracked_outputs = UserService.controlSystem.panels[UserService.controlSystem.panel_index].tracked_outputs;
    $scope.family =
      UserService.controlSystem.panels[
        UserService.controlSystem.panel_index
      ].hardware_family;

    //Get the max schedule number for the current panel family
    $scope.maxScheduleNumber = SCHEDULES[$scope.family + "_MAX_SCHEDULES"];
    $scope.scheduledJobs = []; // Updated in initPage()
    $scope.scheduledJobCount = 0; // Used to track changes in the amount of scheduled jobs
    $scope.PanelProgArchiveService = PanelProgArchiveService;
    $scope.maxXt75ScheduleNumber = 10;
    $scope.model =
      UserService.controlSystem.panels[
        UserService.controlSystem.panel_index
      ].hardware_model;
    //Get a reference for each day of the week
    $scope.dayPrefixes = [];
    angular.forEach(scheduleUtilService.days, function (day) {
      $scope.dayPrefixes.push(day.prefix);
    });
    $scope.holidays = angular.copy(scheduleUtilService.holidays);

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:initialize
     *
     * @description
     * Initialize the control system
     */
    function initialize() {
      $scope.isBusy = true;

      // Initialize the multi-panel service, clearing out any existing interval timers that are running.
      MultiPanelService.initialize();

      if (angular.isDefined($scope.Panel["time_schedules"])) {
        // Make sure that all of our concept numbers are uniformly formatted (ex. 001)
        angular.forEach($scope.Panel.area_informations, function (area) {
          area.number = $filter("zpad")(area.number, 3);
        });
        angular.forEach($scope.Panel.output_informations, function (output) {
          output.number = $filter("zpad")(output.number, 3);
        });
        angular.forEach($scope.Panel.device_informations, function (device) {
          device.number = $filter("zpad")(device.number, 3);
        });
        angular.forEach($scope.Panel.favorites, function (favorite) {
          favorite.number = $filter("zpad")(favorite.number, 3);
        });

        // Populate Warning array
        angular.forEach($scope.Panel.time_schedules, async function (schedule) {
          const warningArray = await $scope.scheduleUseArray(schedule);
          schedule.warningArray = warningArray;
          $scope.$applyAsync();
        });

        // Checking to see if there are scheduled jobs for this panel
        MultiPanelService.getActiveJobsForPanelConcept(
          MultiPanelService.XR_SCHEDULE_JOBS
        )
          .then(function (jobs) {
            // If we have at least one pending job, start a watcher to get updates
            if (jobs.length > 0) {
              MultiPanelService.startMultiPanelWatcher(
                MultiPanelService.XR_SCHEDULE_JOBS
              );
            }

            // Parse the RemoteJson returned from the job scheduler service for display
            angular.forEach(jobs, function (job) {
              if (job.RemoteJson !== null) {
                job.RemoteJson = angular.fromJson(job.RemoteJson);
              }
              job.RemoteJson === null
                ? (job.number = job.SelectedItem)
                : (job.number = job.RemoteJson.time_schedule.number);
            });
            $scope.scheduledJobs = jobs;
            $scope.scheduledJobCount = jobs.length;
          })
          .catch(function (error) {
            console.error(error);
          });

        $scope.isBusy = false;
      }
    }

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:refresh
     *
     * @description
     * Updates the required concepts from the panel
     *
     */
    $scope.refresh = function () {
      $scope.isBusy = true;
      $scope.Panel.refresh()
        .then(
          function (data) {
            //Success
            initialize();
            $scope.isBusy = false;
          },
          function (error) {
            //Failure
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:findItemName
     *
     * @description
     * This function filters a concept array and returns the name property value of the first matching concept
     *
     * @param {object} obj The object array to filter
     * @param {int} number The number to search for
     *
     * @returns {string} The value of the name property
     *
     */
    $scope.findItemName = function (obj, number) {
      number = $filter("zpad")(number, 3);

      var result = $filter("filter")(obj, { number: number }, true);
      return result.length > 0 ? result[0].name : "UNKNOWN";
    };

    $scope.findDoorName = function (obj, axNumber) {
      var result = $filter("filter")(obj, { ax_number: axNumber });
      return result.length > 0 ? result[0].name : "UNKNOWN";
    };

    $scope.findDoorNumber = function (obj, axNumber) {
      var result = $filter("filter")(obj, { ax_number: axNumber });
      return result.length > 0 ? result[0].number : "";
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:deleteSchedule
     *
     * @description
     * Removes the specified schedule from the panel then calls goToSchedules
     *
     * @param {time_schedule} scheduleToDelete The schedule to remove from the panel
     */
    $scope.deleteSchedule = function (scheduleToDelete) {
      $scope.isBusy = true;
      $scope.Panel.deleteItem("time_schedules", scheduleToDelete)
        .then(function () {
          $scope.isBusy = false;
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc
     * @name SchedulesXRViewCtrl:scheduleUseArray
     * @methodOf SchedulesXRViewCtrl
     * @description
     * Checks to see if the schedule is in use and returns an array of strings to display in the warning message
     * @param {time_schedule} scheduleToDelete The schedule to remove from the panel
     * @returns {[string]} an array of strings to display in the warning message
     * */
    $scope.scheduleUseArray = async function (scheduleToDelete) {
      var useArray = "";
      if (scheduleToDelete && $scope.Panel.panel_id) {
        const scheduleUseData = {
          ...(await ScheduleUseService.getScheduleUse(
            $scope.Panel.panel_id,
            scheduleToDelete.number
          )),
        };
        useArray = scheduleUtilService.formatScheduleUseArray(scheduleUseData);
      }
      return useArray;
    };

    /**
     * Check to see if a pending job exists for the given item
     */
    $scope.pendingJobExists = function (number) {
      var result = $filter("filter")($scope.scheduledJobs, { number: number });
      if (result.length > 0) {
        return result[0];
      }
      return false;
    };

    $scope.filterAddJobs = function (job) {
      return job.JobDesc.includes("Add");
    };

    initialize();
    /**
     * Listen for the multiPanelStatusUpdate event, to handle any pending multi-panel jobs
     */
    $scope.$on("event-multiPanelStatusUpdate", function (evt, data) {
      $scope.scheduledJobs = data; // Update our scheduled jobs list

      // Parse the RemoteJson returned from the job scheduler service for display
      angular.forEach(data, function (job) {
        if (job.RemoteJson !== null) {
          job.RemoteJson = angular.fromJson(job.RemoteJson);
        }
        job.RemoteJson === null
          ? (job.number = job.SelectedItem)
          : (job.number = job.RemoteJson.time_schedule.number);
      });

      // If there are no more multi-panel jobs pending, or if we've recently completed a job, refresh the page
      if (data.length == 0 || $scope.scheduledJobCount > data.length) {
        $scope.refresh();
      }

      $scope.scheduledJobCount = data.length; // Update our scheduled job count with the latest number
    });

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     */
    $scope.$on("all_concepts_retrieved", function () {
      initialize();
    });
  },
]);
