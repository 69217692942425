import { RelayServiceType } from "app/common/services/relay-service";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import SendAllUserCodesButton from "./SendAllUserCodesButton";

interface UserServiceType {
  control_system_id: string;
  auth_token: string;
  canViewSendAllUsersButton: () => boolean;
}

interface SendAllUserCodesButtonRootProps {
  $rootScope: any;
  UserService: UserServiceType;
  RelayService: RelayServiceType;
}

/**
 * Root component for the SendAllUserCodesButton, integrating React with AngularJS.
 */
const SendAllUserCodesButtonRoot = ({
  $rootScope,
  UserService,
  RelayService,
}: SendAllUserCodesButtonRootProps) => {
  return (
    <RelayEnvironmentProvider environment={RelayService.getEnvironment()}>
      <AlertsContextProvider $rootScope={$rootScope}>
        <SendAllUserCodesButton
          panelId={UserService.control_system_id}
          authToken={UserService.auth_token}
          isHidden={!UserService.canViewSendAllUsersButton()}
        />
      </AlertsContextProvider>
    </RelayEnvironmentProvider>
  );
};

/**
 * Function to integrate the SendAllUserCodesButtonRoot component into the AngularJS application.
 */
export function dangerouslyAddToApp() {
  App.component(
    "sendAllUserCodesButton",
    react2angular(
      SendAllUserCodesButtonRoot,
      [], // Dependencies can be specified here if needed
      ["$rootScope", "UserService", "RelayService"]
    )
  );
}
