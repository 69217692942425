import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { CommunicationPathType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathUseCheckInField_communicationPath$key } from "./__generated__/CommPathUseCheckInField_communicationPath.graphql";

export const commPathUseCheckInFieldId = (number: string) =>
  `comm-path-use-check-in-${number}`;

function CommPathUseCheckInField() {
  const [{ id, number, useCheckIn, commType, type }, updateCommPath] =
    useCommPathFragment<CommPathUseCheckInField_communicationPath$key>(
      graphql`
        fragment CommPathUseCheckInField_communicationPath on CommunicationPath {
          id
          number
          useCheckIn
          commType
          type
        }
      `
    );

  const fieldId = commPathUseCheckInFieldId(String(number));
  const disabled = ["CID", "DD"].includes(commType);
  const pathIsPrimary = type === CommunicationPathType.PRIMARY;
  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Check-in"
      tooltip="Determines the strategy the System uses to check in with the receiver."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={id}
        value={useCheckIn}
        required
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "useCheckIn");
          });
        }}
        disabled={disabled}
      >
        <Select.Option value="YES">Yes</Select.Option>
        <Select.Option value="NO">No</Select.Option>
        {!isXf && <Select.Option value="RANDOM">Random</Select.Option>}
        {!pathIsPrimary && !isXf && (
          <Select.Option value="ADAPTIVE">Adaptive</Select.Option>
        )}
        {!pathIsPrimary && (
          <Select.Option value="ADAPTIVE3">Adaptive 3</Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathUseCheckInField;
