/**
 * @generated SignedSource<<a2dfbabc0af19f8226a912b7d0be4c28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EagleEyeBridges_eagleEyeBridge$data = {
  readonly channels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly installerEmail: string | null;
  readonly name: string;
  readonly serialNumber: string;
  readonly " $fragmentType": "EagleEyeBridges_eagleEyeBridge";
};
export type EagleEyeBridges_eagleEyeBridge$key = {
  readonly " $data"?: EagleEyeBridges_eagleEyeBridge$data;
  readonly " $fragmentSpreads": FragmentRefs<"EagleEyeBridges_eagleEyeBridge">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EagleEyeBridges_eagleEyeBridge",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "installerEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EagleEyeBridgeVideoChannel",
      "kind": "LinkedField",
      "name": "channels",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EagleEyeBridge",
  "abstractKey": null
};
})();

(node as any).hash = "42588e6f59331e6856c5aa68759ffef2";

export default node;
