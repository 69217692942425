import graphql from "babel-plugin-relay/macro";
import { titleCase } from "common/utils";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { ZoneSensorType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationSensorTypeField_zone$key } from "./__generated__/ZoneInformationSensorTypeField_zone.graphql";

export const ZONE_INFORMATION_SENSOR_TYPE_FIELD =
  "zone-information-sensor-type-";

export const zoneInformationSensorTypeFieldId = (number: string) =>
  ZONE_INFORMATION_SENSOR_TYPE_FIELD + number;

const formatSensorTypeText = (sensorType: ZoneSensorType): string => {
  switch (sensorType) {
    case ZoneSensorType.PIR_OR_RECESSED_DOOR:
      return "PIR / Recessed Door";
    case ZoneSensorType.REPEATER:
      return "1100R Repeater";
    case ZoneSensorType.WIRELESS_PIR:
      return "Wireless PIR";
    case ZoneSensorType.HOLD_UP:
      return "Hold-Up";
    default:
      return titleCase(sensorType.split("_").join(" "));
  }
};

function ZoneInformationSensorTypeField() {
  const [{ number, wireless, sensorType, competitorWireless }] =
    useZoneInformationFragment<ZoneInformationSensorTypeField_zone$key>(
      graphql`
        fragment ZoneInformationSensorTypeField_zone on Zone {
          number
          wireless
          sensorType
          serialNumber
          competitorWireless
        }
      `
    );

  const fieldId = zoneInformationSensorTypeFieldId(String(number));
  const disabled = !wireless;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Sensor Type"
      disabled={disabled}
    >
      {
        <TextInput
          id={fieldId}
          name={fieldId}
          value={
            !!competitorWireless
              ? ""
              : formatSensorTypeText(sensorType as ZoneSensorType)
          }
          disabled={true}
          onChange={() => {}}
        />
      }
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationSensorTypeField;
