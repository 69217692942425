import DaColors from "app/common/DaColors";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: normal;
`;
const HiddenInput = styled.input`
  appearance: none;
`;
const FauxCheckbox = styled.div`
  height: 2rem;
  width: 2rem;
  border: 0.1rem solid ${DaColors.Neutral450};
  border-radius: 0.2rem;
  margin-right: 0.5rem;

  input[type="checkbox"]:checked + & {
    border-color: ${DaColors.Info500};
    background-color: ${DaColors.InfoBase20Percent};

    &::before {
      content: "\\e978";
      color: ${DaColors.Info500};
      font-family: "dmp_icon_font";
      margin-left: 0.3rem;
    }
  }

  input[type="checkbox"]:disabled + & {
    background-color: ${DaColors.Neutral450};
    border-color: ${DaColors.Neutral450};
  }

  input[type="checkbox"]:not(:checked):not(:disabled) + &:hover {
    border-color: ${DaColors.Info500};
  }
`;

type Props = {
  labelText: string;
} & InputHTMLAttributes<HTMLInputElement>;

const CleanCheckbox = ({ labelText, ...htmlProps }: Props) => {
  return (
    <div>
      <Label>
        <HiddenInput type="checkbox" {...htmlProps} />
        <FauxCheckbox />
        {labelText}
      </Label>
    </div>
  );
};

export default CleanCheckbox;
