/**
 * @ngdoc service
 * @name App.factory:MotionDetectionRegion
 *
 * @description
 * API factory for a Motion Detection Region
 *
 */
App.factory("MotionDetectionRegion", [
  "$q",
  "VIDEO_MOTION_DETECTION",
  "VIDEO_DEVICE_MODELS",
  function ($q, VIDEO_MOTION_DETECTION, VIDEO_DEVICE_MODELS) {
    /**
     * Initializes a new MotionDetectionRegion object.
     * @param {string} deviceModel The model of the video device that owns this region.
     */
    var MotionDetectionRegion = function (deviceModel) {
      angular.extend(this, {
        day_sensitivity_level: VIDEO_MOTION_DETECTION.nvr_day_sensitivity_level,
        night_sensitivity_level:
          VIDEO_MOTION_DETECTION.nvr_night_sensitivity_level,
        day_object_size: VIDEO_MOTION_DETECTION.day_object_size,
        night_object_size: VIDEO_MOTION_DETECTION.night_object_size,
        coordinates: [],
      });

      // New Hikvision cameras (May 2020 release) should have day/night object size set to 0
      // https://jira.dmp.com/browse/DA-2003
      if (isHikvisionMay2020(deviceModel)) {
        this.day_object_size =
          VIDEO_MOTION_DETECTION.day_object_size_hikvision_2020;
        this.night_object_size =
          VIDEO_MOTION_DETECTION.night_object_size_hikvision_2020;
      }

      // Add some default motion detection coordinates
      this.coordinates.push(new coordinate(0, 0));
      this.coordinates.push(new coordinate(0, 1000));
      this.coordinates.push(new coordinate(1000, 1000));
      this.coordinates.push(new coordinate(1000, 0));
    };

    function coordinate(x, y) {
      this.x = x;
      this.y = y;
    }

    /**
     * Checks a device model string to see if it is a new Hikvision camera (May 2020).
     * @param {string} deviceModel The model of the video device to check.
     */
    function isHikvisionMay2020(deviceModel) {
      var newHikModels = [
        VIDEO_DEVICE_MODELS.V052D,
        VIDEO_DEVICE_MODELS.V4022C,
        VIDEO_DEVICE_MODELS.DS2CD2443G0IW,
        VIDEO_DEVICE_MODELS.DS2CD2123G0I,
        VIDEO_DEVICE_MODELS.DS2CD2123G0IS,
        VIDEO_DEVICE_MODELS.DS2CD2523G0IWS,
      ];

      return newHikModels.includes(deviceModel);
    }

    return MotionDetectionRegion;
  },
]);
