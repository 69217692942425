import { fetchImageSrcFromUrl } from "common/utils/web/image";

/**
 * @ngdoc service
 * @name App.factory:LogoService
 *
 * @description
 * Is responsible for uploading images to and retrieving images from the logo service
 *
 */
App.factory("LogoService", [
  "$q",
  "PROPS",
  "$http",
  "UserService",
  function ($q, PROPS, $http, UserService) {
    return {
      /**
       * Uploads the specified logo to the logo service
       * A quick explanation on why we're using Content-Type undefined
       * from: http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
       * The cool part is the undefined content-type and the transformRequest: angular.identity
       * that give at the $http the ability to choose the right "content-type" and manage the boundary
       * needed when handling multipart data.
       */
      createLogos: function (dealer_id, logo_type, file) {
        var deferred = $q.defer();
        var _this = this;
        var fd = new FormData();

        fd.append("file", file);
        $http
          .post(this.buildUrl("CreateDealerLogos", dealer_id, logo_type), fd, {
            transformRequest: angular.identity,
            headers: {
              "Content-Type": undefined,
            },
          })
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      buildUrl: function (apiMethod, dealer_id, logo_type) {
        return (
          PROPS.imageApiUrl +
          "/api/v1/DealerLogo/" +
          apiMethod +
          "?dealer_id=" +
          dealer_id +
          "&logo_type=" +
          logo_type
        );
      },

      /**
       * Gets the url for the specified dealer logo
       * @returns {string} The logo url or undefined if the image doesn't exist
       */
      getLogo: function (dealer_id, logo_type, width, height) {
        var deferred = $q.defer();
        var suffix = ["light_type", "dark_type"].includes(logo_type)
          ? "_" + logo_type.substr(0, 1)
          : "";
        var url =
          PROPS.imageApiUrl +
          "/Uploads/dealer_logos/" +
          dealer_id +
          "/" +
          logo_type +
          "/" +
          dealer_id +
          "_" +
          width +
          "x" +
          height +
          suffix +
          ".png";
        url = url + "?refresh=" + new Date().getTime(); // Add epoch milliseconds as a param to force a refresh

        fetchImageSrcFromUrl(url)
          .then(
            function (data) {
              return deferred.resolve(data);
            },
            function (error) {
              return deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },
      getAllDbCustomGraphics: function () {
        let deferred = $q.defer();
        let url = PROPS.imageApiUrl + "/api/v1/CustomKeypadGraphics/list";
        fetch(url, {
          method: "GET",
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            Authorization: `Bearer ${UserService.auth_token}`,
          },
        })
          .then(
            function (data) {
              return deferred.resolve(data.json());
            },
            function (error) {
              return deferred.reject(error);
            }
          )
          .catch((error) => console.error(error));
        return deferred.promise;
      },
      getCustomGraphicById: function (logoId) {
        let deferred = $q.defer();
        let url =
          PROPS.imageApiUrl + "/api/v1/CustomKeypadGraphics/" + String(logoId);

        fetch(url, {
          method: "GET",
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            Authorization: `Bearer ${UserService.auth_token}`,
          },
        })
          .then(
            function (data) {
              return deferred.resolve(data.json());
            },
            function (error) {
              return deferred.reject(error);
            }
          )
          .catch((error) => console.error(error));
        return deferred.promise;
      },
    };
  },
]);
