import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsListenPort734NField_networkOptions$key } from "./__generated__/NetworkOptionsListenPort734NField_networkOptions.graphql";

export const networkOptionsListenPort734NFieldId = () =>
  "network-options-listen-port-734-n";

function NetworkOptionsListenPort734NField() {
  const [
    { listenPort734N, listenPort734NMin, listenPort734NMax },
    updateNetworkOptions,
  ] = useNetworkOptionsFragment<NetworkOptionsListenPort734NField_networkOptions$key>(
    graphql`
      fragment NetworkOptionsListenPort734NField_networkOptions on NetworkOptions {
        listenPort734N
        listenPort734NMin
        listenPort734NMax
      }
    `
  );

  const fieldId = networkOptionsListenPort734NFieldId();
  const { current: originalValue } = React.useRef(listenPort734N);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="734N Listen Port"
      tooltip="Network port the System uses to communicate with 734N or 734N-WiFi devices. Must be the same as the 'Panel IP Port' programmed in the 734N or 734N-WiFi."
    >
      <TextInput
        id={fieldId}
        value={listenPort734N.toString()}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        required
        inlineHelp={`${listenPort734NMin}–${listenPort734NMax}`}
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(
              target.value
                .split("")
                .filter((c) => /[0-9]/.test(c))
                .join(""),
              "listenPort734N"
            );
          });
        }}
        onBlur={({ target }) => {
          const valueAsNumber = Number(target.value);
          if (
            valueAsNumber > listenPort734NMax ||
            valueAsNumber < listenPort734NMin
          ) {
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(listenPort734NMin, listenPort734NMax, valueAsNumber);
            updateNetworkOptions((recordProxy) => {
              recordProxy.setValue(String(value), "listenPort734N");
            });
          }
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsListenPort734NField;
