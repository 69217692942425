/**
 * @generated SignedSource<<cbf747d0bca18419fee047b981cc9901>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFNetworkOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly networkOptions: {
      readonly dhcpEnabled: boolean;
      readonly dnsServer: string;
      readonly gatewayAddress: string;
      readonly id: string;
      readonly localIpAddress: string;
      readonly subnetMask: string;
    } | null;
  };
  readonly " $fragmentType": "XFNetworkOptionsProgrammingConceptFormInline_controlSystem";
};
export type XFNetworkOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFNetworkOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFNetworkOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFNetworkOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "a00c9fe6a926c7baa04c6e5e72891ff8";

export default node;
