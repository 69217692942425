/**
 * Service to pull data from the OData server
 */
App.factory("ODataService", [
  "$q",
  "$filter",
  "PROPS",
  "UserService",
  "DealerODataAPI",
  "OdataPageService",
  "AccountingReportsODataAPI",
  function (
    $q,
    $filter,
    PROPS,
    UserService,
    DealerODataAPI,
    OdataPageService,
    AccountingReportsODataAPI
  ) {
    return {
      getCustomersForDealer: function (dealerID, searchFilter) {
        var deferred = $q.defer();
        var _this = this;
        searchFilter = searchFilter.replace(/'/g, "''");
        var promise = DealerODataAPI.getCustomers({
          dealer_id: dealerID,
          $filter:
            "contains(name,'" +
            searchFilter +
            "') or contains(dealer_account,'" +
            searchFilter +
            "')",
          $top: 100,
        }).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get control systems for the dealer along with panel info
       * @param dealerId
       * @param customerId
       */
      getControlSystems: function (dealerId, customerId) {
        var deferred = $q.defer();
        var controlSystemPromise = DealerODataAPI.getControlSystems({
          dealer_id: dealerId,
          $filter: "customer_id eq " + customerId,
          $expand:
            "panels($select=id,account_prefix,account_number,hardware_model,serial_number,software_version)",
          $select: "id,name,address_1,city,state,postal_code,site_id",
        }).$promise;
        var oDataPageService = new OdataPageService();
        oDataPageService
          .getAllPages(controlSystemPromise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getPersonnelIds: function () {
        return getPersonnel(UserService.dealer_id, "id");
      },

      getCustomerIdsAndControlSystemIds: function () {
        return getCustomers(
          UserService.dealer_id,
          "id",
          "control_systems($select=id)"
        );
      },

      getCustomersForTemplateCreation: function (
        dealerId,
        selectStatement,
        expand
      ) {
        return getCustomers(dealerId, selectStatement, expand);
      },

      getCustomersForModemUpgrade: function (
        dealerId,
        selectStatement,
        expand
      ) {
        return getCustomers(dealerId, selectStatement, expand);
      },

      getSystemPeripheralsCounts: function (DealerId, ControlSystemId) {
        var deferred = $q.defer();
        DealerODataAPI.getSystemPeripheralsCount(
          {
            dealer_id: DealerId,
            control_system_id: ControlSystemId,
          },
          function (data) {
            delete data.$promise;
            delete data.$resolved;
            delete data["@odata.context"];
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      accountingReports: {
        /**
         * Gets list of completed payments.
         * @param {string} selectStatement OData select.
         * @param {string} expandStatement OData expand.
         */
        getPayments: function (selectStatement, expandStatement) {
          var deferred = $q.defer();
          var select = selectStatement || null;
          var expand = expandStatement || null;

          var paymentsPromise = AccountingReportsODataAPI.getPayments({
            $select: select,
            $expand: expand,
          }).$promise;

          var oDataPageService = new OdataPageService();
          oDataPageService
            .getAllPages(paymentsPromise)
            .then(
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                console.error(
                  "ODataService->accountingReports.getPayments() error: " +
                    angular.toJson(error)
                );
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },

        /**
         * Get list of scheduled payments.
         * @param {string} selectStatement OData select.
         * @param {string} expandStatement OData expand.
         */
        getScheduledPayments: function (selectStatement, expandStatement) {
          var deferred = $q.defer();
          var select = selectStatement || null;
          var expand = expandStatement || null;

          var paymentsPromise = AccountingReportsODataAPI.getScheduledPayments({
            $select: select,
            $expand: expand,
          }).$promise;

          var oDataPageService = new OdataPageService();
          oDataPageService
            .getAllPages(paymentsPromise)
            .then(
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                console.error(
                  "ODataService->accountingReports.getScheduledPayments() error: " +
                    angular.toJson(error)
                );
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
      },
    };

    /**
     * Get dealer personnel (dealer_users)
     * @param {int} dealerId
     * @param {string} [select_statement]
     */
    function getPersonnel(dealerId, select_statement) {
      var deferred = $q.defer();
      var select = select_statement || "*";
      var personnelPromise = DealerODataAPI.getPersonnel({
        dealer_id: dealerId,
        $select: select,
      }).$promise;
      var oDataPageService = new OdataPageService();
      oDataPageService
        .getAllPages(personnelPromise)
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "ODataService->getPersonnel() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * Get dealer customers
     * @param {int} dealerId
     * @param {string} [select_statement]
     * @param {string} [expand_statement]
     */
    function getCustomers(dealerId, select_statement, expand_statement) {
      var deferred = $q.defer();
      var select = select_statement || "*";
      var expand = expand_statement || "";
      var customersPromise = DealerODataAPI.getCustomers({
        dealer_id: dealerId,
        $select: select,
        $expand: expand,
      }).$promise;
      var oDataPageService = new OdataPageService();
      oDataPageService
        .getAllPages(customersPromise)
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "ODataService->getCustomers() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }
  },
]);
