import DaColors from "app/common/DaColors";
import { getVideoMonitoringCameras } from "dealer-admin/src/apis/camect-api";
import {
  CameraType,
  StreamableCamera,
} from "dealer-admin/src/apis/camect-api/streamable-camera";
import { getAlarmZoneCameras } from "dealer-admin/src/apis/dealer-settings-api";
import { NonAlarmVisionAlarmZoneCamera } from "dealer-admin/src/apis/dealer-settings-api/alarm-zone-cameras-parser";
import { getEventTypes, getSecurityEvents } from "dealer-admin/src/apis/odata";
import { EventType } from "dealer-admin/src/apis/odata/event-types-parser";
import {
  EventSource,
  SecurityEvent,
} from "dealer-admin/src/apis/odata/security-events-parser";
import {
  getLegacyClips,
  getVideoVerificationInfo,
} from "dealer-admin/src/apis/scapi";
import { VideoVerificationInfo } from "dealer-admin/src/apis/scapi/video-verification-info-parser";
import AuthContext from "dealer-admin/src/contexts/AuthContext";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { react2angular } from "react2angular";
import styled from "styled-components/macro";
import { getAuthFromJWT } from "utils/string";
import EventList from "./EventList";
import EventThumbnails from "./EventThumbnails";
import EventViewer from "./EventViewer";
import Header from "./Header";
import LiveStream from "./LiveStream";
import LiveStreamThumbnails from "./LiveStreamThumbnails";
import SystemNotAvailable from "./SystemNotAvailable";

export type AlarmZoneCamera = {
  cameraId: string;
  cameraType: CameraType;
};

// The top-level absolute positioning here is so that we take up the whole screen and ignore DA's
// top-level padding.
// The min-h-[101vh] is to force the page to always need a vertical scrollbar. This is to avoid
// the scenario where the page is right at the height where it almost needs a scrollbar, and on
// macOS, if scrollbars are set to hide when not needed, the page can get stuck in an auto-resize
// loop. It goes something like 1) video player loads, making the page slightly longer and
// triggering the showing of the scrollbar, 2) the page is now narrower because the scrollbar is
// taking up horizontal space, 3) the video player resizes to be smaller, triggering the hiding
// of the scrollbar, and 4) the video player resizes to be larger again. Setting the min-height
// to 101% of the available view height forces the scrollbar to always be there.
const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  min-height: 101vh;
  width: 100%;
  background-color: ${DaColors.Neutral250};
  font-size: 1.4rem;
  line-height: 2rem;
`;
const Main = styled.div`
  display: grid;
  grid-template-columns: 37% 37% 26%;
  align-items: start;
  gap: 3.2rem;
  padding: 1.6rem 14.4rem;
`;
const ThumbnailsWrapper = styled.div`
  overflow: hidden;
  border-radius: 0.4rem;
  background-color: ${DaColors.White};
`;
const EventViewerWrapper = styled.div`
  margin-bottom: 3.2rem;
  border-radius: 0.4rem;
  border: 4px solid ${DaColors.Primary500};
  background-color: ${DaColors.White};
`;
const LiveStreamWrapper = styled.div`
  overflow: hidden;
  border-radius: 0.4rem;
  background-color: ${DaColors.White};
`;

export type Props = {
  page: "video-verification";
  scwToken: string;
  dealerId: number;
  controlSystemId: number;
};

export const VarVVRoot = ({
  authToken,
  dealerId,
  controlSystemId,
}: {
  authToken: string;
  dealerId: number;
  controlSystemId: number;
}) => {
  const [videoVerificationInfo, setVideoVerificationInfo] =
    useState<VideoVerificationInfo | null>(null);
  const [cameras, setCameras] = useState<StreamableCamera[]>([]);
  const [alarmZoneCameras, setAlarmZoneCameras] = useState<AlarmZoneCamera[]>(
    []
  );
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [securityEvents, setSecurityEvents] = useState<SecurityEvent[]>([]);
  const [legacyClipEvents, setLegacyClipEvents] = useState<SecurityEvent[]>([]);
  const [eventSource, setEventSource] = useState(EventSource.Events);
  const [dataLoadCount, setDataLoadCount] = useState(0);
  const [selectedCameraId, setSelectedCameraId] = useState<string | null>(null);
  const [selectedCameraType, setSelectedCameraType] =
    useState<CameraType | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<SecurityEvent | null>(
    null
  );
  const [viewedEventIds, setViewedEventIds] = useState<number[]>([]);
  //If there are any issues pulling video related data, we want to just
  //simply send that to the components to allow them to
  //pass "there was an error on our end" back to the users
  const [isCamerasError, setIsCamerasError] = useState(false);
  const [isVideoVerificationInfoError, setIsVideoVerificationInfoError] =
    useState(false);
  const [isSecurityEventsError, setIsSecurityEventsError] = useState(false);

  const scwToken =
    authToken.length > 40 ? getAuthFromJWT(authToken) : authToken;
  const authData = {
    authToken: scwToken,
    controlSystemId,
    tokenType: "monitoring",
  };
  const displayedEvents =
    eventSource === EventSource.Events ? securityEvents : legacyClipEvents;

  const findCamera = (
    cameraId: string | null,
    cameraType: CameraType | null
  ) => {
    return (
      cameras.find(
        (c) => c.cameraId === cameraId && c.cameraType === cameraType
      ) || null
    );
  };

  // We are keeping track of the selected camera's ID and type in state variables, rather than the
  // camera itself, so that e.g. if the camera's status changes to offline via an update from the
  // back end, that change will be reflected in what is rendered.
  const setSelectedCamera = (camera: StreamableCamera | null) => {
    if (camera) {
      setSelectedCameraId(camera.cameraId);
      setSelectedCameraType(camera.cameraType);
    } else {
      setSelectedCameraId(null);
      setSelectedCameraType(null);
    }
  };

  function onSelectEvent(selectedEvent: SecurityEvent | null) {
    setSelectedEvent(selectedEvent);
    if (selectedEvent) {
      setViewedEventIds([...viewedEventIds, selectedEvent.id]);
      if (
        selectedEvent.cameraId &&
        selectedEvent.cameraType &&
        selectedEvent.videoUrl
      ) {
        setSelectedCamera(
          findCamera(selectedEvent.cameraId, selectedEvent.cameraType) || null
        );
      }
    }
  }

  const defaultCameraSelection = () => {
    if (
      selectedCameraId === null ||
      selectedCameraType === null ||
      !findCamera(selectedCameraId, selectedCameraType)
    ) {
      setSelectedCamera(cameras[0] || null);
    }
  };

  const selectedCamera = findCamera(selectedCameraId, selectedCameraType);

  const defaultEventSelection = () => {
    if (
      selectedEvent === null ||
      !displayedEvents.map((e) => e.id).includes(selectedEvent.id)
    ) {
      const firstVideoEvent = displayedEvents.find((e) => e.videoUrl !== null);
      onSelectEvent(firstVideoEvent || displayedEvents[0] || null);
    }
  };

  const getCombinedAlarmZoneCameras = (
    nonAlarmVisionAlarmZoneCameras: NonAlarmVisionAlarmZoneCamera[],
    nonAlarmVisionCameras: StreamableCamera[],
    securityEvents: SecurityEvent[]
  ) => {
    const alarmZoneCameras: AlarmZoneCamera[] = [];

    // For non-AlarmVision cameras, we get data from the Dealer Settings API
    // about which cameras are currently in alarm. We only care about the ones
    // that are in our list of streamable cameras, and we need to look in that
    // list to get the cameraType values.
    nonAlarmVisionAlarmZoneCameras.forEach((azc) => {
      const camera = nonAlarmVisionCameras.find(
        (c) => c.cameraId === azc.cameraId
      );
      if (camera) {
        alarmZoneCameras.push({
          cameraId: camera.cameraId,
          cameraType: camera.cameraType,
        });
      }
    });

    // For AlarmVision, since the cameras are the source of the alarm events
    // (rather than being arbitrarily associated with zones), we can look at the
    // list of alarm events and pull out the unique cameraId/cameraType combos.
    securityEvents
      .filter((securityEvent) => securityEvent.isAlarm)
      .forEach((securityEvent) => {
        if (securityEvent.cameraId && securityEvent.cameraType) {
          const alreadyIncluded = alarmZoneCameras.find(
            (azc) =>
              azc.cameraId === securityEvent.cameraId &&
              azc.cameraType == securityEvent.cameraType
          );
          if (!alreadyIncluded) {
            alarmZoneCameras.push({
              cameraId: securityEvent.cameraId,
              cameraType: securityEvent.cameraType,
            });
          }
        }
      });
    return alarmZoneCameras;
  };

  useEffect(() => {
    let ignore = false;

    const startFetching = async () => {
      const newVideoVerificationInfo = await getVideoVerificationInfo(
        scwToken,
        controlSystemId
      ).catch(() => {
        //TODO: Log error properly
        setIsVideoVerificationInfoError(true);
        return null;
      });

      if (!ignore) {
        if (newVideoVerificationInfo) {
          const newNonAlarmVisionCameras = newVideoVerificationInfo.cameras;
          setVideoVerificationInfo(newVideoVerificationInfo);

          const legacyCamerasPresent = newNonAlarmVisionCameras.some(
            (c) => c.cameraType === "legacy"
          );
          // If AlarmVision (Camect and/or iENSO) is enabled, fetch camera data from camect-api
          const shouldFetchAlarmVisionCameras =
            newVideoVerificationInfo.alarmVisionEnabled;
          // Always fetch event data from OData. Even on a system that only has legacy cameras, it
          // takes an alarm event to make the Video Verification page accessible in the first place
          const shouldFetchEvents = true;
          // If there are V-6000 cameras or legacy cameras, fetch streaming details from Video Proxy
          // const shouldFetchNonAlarmVisionCameras = v6000CamerasPresent || legacyCamerasPresent;
          // If there are legacy cameras, fetch clips from SCAPI
          const shouldFetchLegacyClips = legacyCamerasPresent;

          let newEventTypes: EventType[] = [];
          if (shouldFetchEvents && !eventTypes.length) {
            // We only need to load event types once
            newEventTypes = await getEventTypes(scwToken).catch(() => {
              setIsSecurityEventsError(true);
              return [];
            });
            if (!ignore) {
              setEventTypes(newEventTypes);
            }
          }

          let alarmVisionCamerasPromise: Promise<StreamableCamera[]> =
            Promise.resolve([]);
          if (shouldFetchAlarmVisionCameras) {
            alarmVisionCamerasPromise = getVideoMonitoringCameras(
              scwToken,
              controlSystemId
            ).catch(() => {
              //TODO: Log error properly
              setIsCamerasError(true);
              return [];
            });
          }

          const nonAlarmVisionAlarmZoneCamerasPromise = getAlarmZoneCameras(
            scwToken,
            dealerId,
            controlSystemId
          );

          let securityEventsPromise: Promise<SecurityEvent[]> = Promise.resolve(
            []
          );
          if (shouldFetchEvents) {
            // If we just retrieved the event types in this render cycle, the state hasn't been
            // updated yet
            const eventTypesToUse =
              newEventTypes.length > 0 ? newEventTypes : eventTypes;
            securityEventsPromise = getSecurityEvents(
              scwToken,
              controlSystemId,
              eventTypesToUse,
              newVideoVerificationInfo.lastAlarmAt
            ).catch(() => {
              //TODO: Log error properly
              setIsSecurityEventsError(true);
              return [];
            });
          }

          let legacyClipEventsPromise: Promise<SecurityEvent[]> =
            Promise.resolve([]);
          if (shouldFetchLegacyClips) {
            legacyClipEventsPromise = getLegacyClips(
              scwToken,
              newNonAlarmVisionCameras,
              newVideoVerificationInfo.lastAlarmAt
            ).catch(() => {
              // If there's a problem with legacy clips, don't shut down the event list
              return [];
            });
          }
          const [
            newAlarmVisionCameras,
            newNonAlarmVisionAlarmZoneCameras,
            newSecurityEvents,
            newLegacyClipEvents,
          ] = await Promise.all([
            alarmVisionCamerasPromise,
            nonAlarmVisionAlarmZoneCamerasPromise,
            securityEventsPromise,
            legacyClipEventsPromise,
          ]);

          if (!ignore) {
            const allCameras = [
              ...newNonAlarmVisionCameras,
              ...newAlarmVisionCameras,
            ];
            setCameras(allCameras);
            defaultCameraSelection();

            setAlarmZoneCameras(
              getCombinedAlarmZoneCameras(
                newNonAlarmVisionAlarmZoneCameras,
                newNonAlarmVisionCameras,
                newSecurityEvents
              )
            );

            setSecurityEvents(newSecurityEvents);
            defaultEventSelection();

            setLegacyClipEvents(newLegacyClipEvents);

            // If we only have legacy cameras, and we have clips from them, default to showing the
            // Clips tab
            if (
              allCameras.every((camera) => camera.cameraType === "legacy") &&
              newLegacyClipEvents.length > 0 &&
              dataLoadCount === 0
            ) {
              setEventSource(EventSource.Clips);
            }

            // We don't want to start the data-loading timer cycle until we get through the first
            // complete round of loading data. Setting dataLoadCount to 1 will cause this effect to
            // fire again, and then the timer will be started.
            if (dataLoadCount === 0) {
              setDataLoadCount(1);
            }
          }
        } else {
          setVideoVerificationInfo(null);
        }
      }
    };

    // Start out reloading the data once every three seconds. Every 20 loads, add a second to the
    // delay. Max out at 30 seconds.
    const DATA_LOAD_DELAY_GROUP_SIZE = 20;
    const DATA_LOAD_DELAY_INCREMENT_MS = 1000;
    const INITIAL_DATA_LOAD_DELAY_MS = 3000;
    const MAX_DATA_LOAD_DELAY_MS = 30000;
    const dataLoadDelay = () => {
      const delay =
        INITIAL_DATA_LOAD_DELAY_MS +
        Math.floor(dataLoadCount / DATA_LOAD_DELAY_GROUP_SIZE) *
          DATA_LOAD_DELAY_INCREMENT_MS;
      return Math.min(delay, MAX_DATA_LOAD_DELAY_MS);
    };

    startFetching();
    if (dataLoadCount > 0) {
      // The initial data load is complete. Start a timer to auto-reload the data.
      setTimeout(() => setDataLoadCount(dataLoadCount + 1), dataLoadDelay());
    }

    return () => {
      ignore = true;
    };
  }, [scwToken, controlSystemId, dataLoadCount]);

  const getCountdownTargetDate = () => {
    if (!videoVerificationInfo) return new Date();

    const lastAlarmAtDateTime = moment(videoVerificationInfo?.lastAlarmAt);
    return lastAlarmAtDateTime
      .add({ minutes: videoVerificationInfo?.videoVerificationDuration })
      .toDate();
  };

  if (videoVerificationInfo) {
    return (
      <AuthContext.Provider value={authData}>
        <Wrapper>
          <Header videoVerificationInfo={videoVerificationInfo} />
          <Main>
            <ThumbnailsWrapper>
              <LiveStreamThumbnails
                cameras={cameras}
                alarmZoneCameras={alarmZoneCameras}
                selectedCamera={selectedCamera}
                setSelectedCamera={setSelectedCamera}
              />
            </ThumbnailsWrapper>
            <div>
              <EventViewerWrapper>
                <EventViewer
                  event={selectedEvent}
                  isNetworkError={isCamerasError || isSecurityEventsError}
                  thumbnails={
                    <EventThumbnails
                      events={displayedEvents}
                      eventSource={eventSource}
                      selectedEventId={selectedEvent?.id}
                      onSelectEvent={onSelectEvent}
                    />
                  }
                />
              </EventViewerWrapper>
              {selectedCamera && (
                <LiveStreamWrapper>
                  <LiveStream
                    // The status is included in the key to force the whole thing to reload when an
                    // offline camera comes back online.
                    key={`${selectedCameraType}-${selectedCameraId}-${selectedCamera.status}`}
                    camera={selectedCamera}
                    countdownTargetDate={getCountdownTargetDate()}
                    showControls={true}
                  ></LiveStream>
                </LiveStreamWrapper>
              )}
            </div>
            <EventList
              events={displayedEvents}
              eventSource={eventSource}
              hasLegacyClips={legacyClipEvents.length > 0}
              isNetworkError={isCamerasError || isSecurityEventsError}
              onSelectEvent={onSelectEvent}
              onSelectEventSource={setEventSource}
              selectedEventId={selectedEvent?.id}
              viewedEventIds={viewedEventIds}
            />
          </Main>
        </Wrapper>
      </AuthContext.Provider>
    );
  } else if (isVideoVerificationInfoError) {
    return (
      <SystemNotAvailable
        title="We are currently experiencing networking issues"
        text="Please try refreshing this page."
      />
    );
  } else {
    return (
      <SystemNotAvailable
        title="The selected system is currently not in alarm"
        text="Please verify the URL or try refreshing this page."
      />
    );
  }
};

export const dangerouslyAddToApp = () => {
  App.component(
    "varVv",
    react2angular(VarVVRoot, ["authToken", "dealerId", "controlSystemId"])
  );
};
