/**
 * @generated SignedSource<<d02f08e040e6918317e009e6d4c8ff16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BadZonesAlert_controlSystem$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "BadZonesAlert_controlSystem";
};
export type BadZonesAlert_controlSystem$key = {
  readonly " $data"?: BadZonesAlert_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"BadZonesAlert_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BadZonesAlert_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "3539e0d3507bd35abbbdbb34e79cb09e";

export default node;
