/**
 * @generated SignedSource<<bcc4560e3e6d99a96ec4d2bdefac521c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsWirelessHouseCodeField_systemOptions$data = {
  readonly houseCode?: string;
  readonly houseCodeMax?: number;
  readonly houseCodeMin?: number;
  readonly wirelessEncryption1100?: WirelessEncryption1100;
  readonly wirelessEncryption1100Passphrase?: string;
  readonly " $fragmentType": "SystemOptionsWirelessHouseCodeField_systemOptions";
};
export type SystemOptionsWirelessHouseCodeField_systemOptions$key = {
  readonly " $data"?: SystemOptionsWirelessHouseCodeField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsWirelessHouseCodeField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "houseCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "houseCodeMin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "houseCodeMax",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "wirelessEncryption1100",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "wirelessEncryption1100Passphrase",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsWirelessHouseCodeField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "42d708e45bd3bf9dc96710e485d61a9a";

export default node;
