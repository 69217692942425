import { IconButtonThatLooksLikeLink } from "components/DaStyledElements/ClickableElements";
import React from "react";
import TagsSettingsTable from "./TagsSettingsTable";

interface TagsSettingsProps {
  dealerId: number;
  state: any;
}
function TagsSettings(props: TagsSettingsProps) {
  const { state } = props;
  return (
    <>
      <div className="row">
        <div className="sub-header">
          <div className="sub-header__left">
            <div className="sub-header__title">Tags</div>
            <IconButtonThatLooksLikeLink
              className="btn btn-sm btn-add ml"
              onClick={() =>
                state.go("app.dealer.tag-create", {
                  tag_id: "0",
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <TagsSettingsTable {...props} />
      </div>
    </>
  );
}

export default TagsSettings;
