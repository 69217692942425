/**
 * @generated SignedSource<<c3606169e06e190b24ea8393f9cfd824>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly alarmReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly serviceReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts";
};
export type XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "4fed96bd6290341f673a7772c9755e82";

export default node;
