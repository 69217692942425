import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { CardType } from "components/SiteForm/ExistingSite/CardFormatForm";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatUserCodeDigitsField_cardFormat$key } from "./__generated__/CardFormatUserCodeDigitsField_cardFormat.graphql";

export const cardFormatUserCodeDigitsFieldId = (number: string) =>
  `card-format-user-code-digits-${number}`;

function CardFormatUserCodeDigitsField() {
  const [{ userCodeDigits, number, type }, updateCardFormat] =
    useCardFormatFragment<CardFormatUserCodeDigitsField_cardFormat$key>(
      graphql`
        fragment CardFormatUserCodeDigitsField_cardFormat on CardFormat {
          userCodeDigits
          number
          type
        }
      `
    );

  const fieldId = cardFormatUserCodeDigitsFieldId(number);
  const { current: originalValue } = React.useRef(userCodeDigits);
  const disabled = type !== CardType.CUSTOM;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="User Code Digits"
      tooltip="Length of the user code."
    >
      <NumericInput
        id={fieldId}
        required
        value={userCodeDigits}
        min={4}
        max={12}
        pattern="(0?[4-9]|1[0-2])"
        inlineHelp="4-12"
        validationMessage="Valid values are 4-12."
        disabled={disabled}
        onChange={({ target }) => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "userCodeDigits"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCardFormat((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(4, 12, valueAsNumber);
            recordProxy.setValue(value, "userCodeDigits");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatUserCodeDigitsField;
