import styled from "styled-components";

export const TableHeaderSorting = styled.th`
  cursor: pointer;
  color: #888 !important;
`;
export const TableHeaderStandard = styled.th`
  color: #888 !important;
  font-weight: 300;
  font-size: 1.4rem;
`;
export const TableHeaderSortingTextRight = styled.th`
  cursor: pointer;
  color: #888 !important;
  text-align: right !important;
`;
