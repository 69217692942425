/**
 * @ngdoc service
 * @name App.factory:JobSchedulerService
 *
 * @description
 * Manages scheduled jobs submitted to the Job Scheduling Server.  This service should be able to be used by anything that
 * needs to create long running or scheduled Jobs.
 *
 */
App.factory("JobSchedulerService", [
  "$rootScope",
  "$q",
  "PROPS",
  "$filter",
  "SchedulerJobGroup",
  "SchedulerJobGroupsRunning",
  "SchedulerJobGroupTypes",
  "SchedulerJobTypes",
  "SchedulerJobGroupOdata",
  "SchedulerJobOdata",
  "UserService",
  "OdataPageService",
  "SchedulerJobGroupAbort",
  "AutoProgramTaskDetailsOdata",
  function (
    $rootScope,
    $q,
    PROPS,
    $filter,
    SchedulerJobGroup,
    SchedulerJobGroupsRunning,
    SchedulerJobGroupTypes,
    SchedulerJobTypes,
    SchedulerJobGroupOdata,
    SchedulerJobOdata,
    UserService,
    OdataPageService,
    SchedulerJobGroupAbort,
    AutoProgramTaskDetailsOdata
  ) {
    return {
      /**
       * Cached Job Groups
       */
      jobGroups: [],

      /**
       * Cached Job Group Types
       */
      jobGroupTypes: [],

      /**
       * Cached Job Types
       */
      jobTypes: [],

      /**
       * Constants for the Job Statuses
       */
      jobStatuses: {
        NEW: "new",
        CREATED: "created",
        ACQUIRED: "acquired",
        STARTED: "started",
        RUNNING: "running",
        COMPLETE: "completed",
        SUCCESS: "success",
        FAILED: "failed",
        FAIL: "fail",
        ERROR: "error",
        UNKNOWN: "unknown",
      },

      /**
       * Convenience method for the Unknown statuses
       * @param status
       * @returns {boolean}
       */
      isUnknownStatus: function (status) {
        return status == this.jobStatuses.UNKNOWN;
      },

      /**
       * Convenience method for the Pending statuses
       * @param status
       * @returns {boolean}
       */
      isPendingStatus: function (status) {
        return (
          status == this.jobStatuses.ACQUIRED || status == this.jobStatuses.NEW
        );
      },

      /**
       * Convenience method for the Running statuses
       * @param status
       * @returns {boolean}
       */
      isRunningStatus: function (status) {
        return (
          status == this.jobStatuses.NEW ||
          status == this.jobStatuses.ACQUIRED ||
          status == this.jobStatuses.CREATED ||
          status == this.jobStatuses.RUNNING ||
          status == this.jobStatuses.STARTED
        );
      },

      /**
       * Convenience method for the Complete statuses
       * @param status
       * @returns {boolean}
       */
      isCompleteStatus: function (status) {
        return (
          status == this.jobStatuses.COMPLETE ||
          status == this.jobStatuses.SUCCESS
        );
      },

      /**
       * Convenience method for the Failed statuses
       * @param status
       * @returns {boolean}
       */
      isFailedStatus: function (status) {
        return (
          status == this.jobStatuses.FAILED ||
          status == this.jobStatuses.ERROR ||
          status == this.jobStatuses.FAIL
        );
      },

      /**
       * Get a specific Job Group by ID
       * @param jobId
       * @returns {*}
       */
      getJobGroup: function (jobGroupId) {
        var deferred = $q.defer();
        SchedulerJobGroup.get(
          { id: jobGroupId },
          function (data) {
            // Data was returned
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Get a specific Job Group by ID using Odata
       * @param jobId
       * @returns {*}
       */
      getJobGroupReportData: function (jobGroupId) {
        var _this = this;
        var deferred = $q.defer();
        var filter =
          typeof jobGroupId !== "undefined"
            ? { $filter: "Id eq " + jobGroupId }
            : null;
        var promise = SchedulerJobGroupOdata.get(filter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Job Groups (constrained by user's auth_token)
       * @param jobGroupTypeId The ID of the Job Group Type to filter, optional
       * @returns {*}
       */
      getJobGroups: function (jobGroupTypeId) {
        var _this = this;
        var deferred = $q.defer();
        // Default the groupType to get ALL Job Group types if no ID is passed in
        var filter =
          typeof jobGroupTypeId !== "undefined"
            ? { $filter: "SchedulerJobGroupTypeId eq " + jobGroupTypeId }
            : null;
        var promise = SchedulerJobGroupOdata.get(filter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /**
       * Get all Pending Job Groups (constrained by user's auth_token)
       * @param jobGroupTypeId The ID of the Job Group Type to filter, optional
       * @returns {*}
       */
      getPendingJobGroups: function (jobGroupTypeId) {
        const deferred = $q.defer();
        // Default the groupType to get ALL Job Group types if no ID is passed in
        const filter =
          typeof jobGroupTypeId !== "undefined"
            ? {
                $filter: `SchedulerJobGroupTypeId eq ${jobGroupTypeId} and (GroupStatus eq '${this.jobStatuses.NEW}' or GroupStatus eq '${this.jobStatuses.ACQUIRED}' or GroupStatus eq '${this.jobStatuses.CREATED}' or GroupStatus eq '${this.jobStatuses.RUNNING}' or GroupStatus eq '${this.jobStatuses.STARTED}')`,
              }
            : null;
        const promise = SchedulerJobGroupOdata.get(filter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Job Groups (constrained by user's auth_token)
       * @param jobGroupTypeId The ID of the Job Group Type to filter, optional
       * @returns {*}
       */
      getJobGroupsByDealerId: function (jobGroupTypeId, dealerId, pages) {
        var _this = this;
        var deferred = $q.defer();
        // Default the groupType to get ALL Job Group types if no ID is passed in
        var filter =
          typeof jobGroupTypeId !== "undefined"
            ? {
                $filter:
                  "SchedulerJobGroupTypeId eq " +
                  jobGroupTypeId +
                  " and DealerId eq " +
                  dealerId,
              }
            : null;
        filter.$orderby = "Id desc";
        var promise = SchedulerJobGroupOdata.get(filter).$promise;
        if (isUndefinedOrNull(pages) || pages > 1) {
          ODPS = new OdataPageService();
          ODPS.getAllPages(promise)
            .then(
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          // just return the first page
          promise
            .then(
              function (data) {
                deferred.resolve(data.value);
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }

        return deferred.promise;
      },

      /**
       * Get all Job Groups (constrained by the user's auth_token)
       * @param jobGroupTypeId The ID of the Job Group Type to filter, optional
       * @param panelId The ID of the Panel to filter, optional
       * @returns {*}
       */
      getJobGroupsByPanel: function (jobGroupTypeId, panelId) {
        var _this = this;
        var deferred = $q.defer();
        // Default the groupType to get ALL Job Group types if no ID is passed in
        var filter =
          typeof jobGroupTypeId !== "undefined"
            ? {
                $filter:
                  "SchedulerJobGroupTypeId eq " +
                  jobGroupTypeId +
                  " and PanelId eq " +
                  panelId,
              }
            : null;
        var promise = SchedulerJobGroupOdata.get(filter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Jobs (constrained by the user's auth_token)
       * @param jobs The list of job names to filter
       * @param panelId The ID of the Panel to filter, optional
       * @returns {*}
       */
      getJobsListByPanel: function (jobTypeList, panelId) {
        var _this = this;
        var deferred = $q.defer();
        var filter = "(";
        // Build our filter from the array of job types passed in
        angular.forEach(jobTypeList, function (jobType, index) {
          filter += "SchedulerJobType/JobType eq '" + jobType + "'";
          if (index + 1 < jobTypeList.length) {
            filter += " or ";
          }
          if (index + 1 == jobTypeList.length) {
            filter += ") ";
          }
        });
        filter += "and PanelId eq " + panelId; // Tack on our panelId to the filter
        filter +=
          "and JobStatus ne 'success' and JobStatus ne 'fail' and JobStatus ne 'unknown'"; // Tack on our active jobs to the filter
        var oDataFilter = { $filter: filter };
        var promise = SchedulerJobOdata.get(oDataFilter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Jobs of the jobTypeID(constrained by the user's auth_token)
       * @param jobTypeID The list of job names to filter
       * @param dealerId The ID of the Panel to filter, optional
       * @returns {*}
       */
      getJobListByDealerId: function (jobTypeID, dealerId) {
        var _this = this;
        var deferred = $q.defer();
        var filter = "";
        // Build our filter from the jobTypeID and dealerId passed in
        filter +=
          "SchedulerJobTypeId eq " + jobTypeID + " and DealerId eq " + dealerId; // Tack on our dealerId to the filter
        var oDataFilter = { $filter: filter };
        var promise = SchedulerJobOdata.get(oDataFilter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all jobs that have failed with of the jobTypeID(constrained by the user's auth_token)
       * @param jobTypeID The list of job names to filter
       * @param dealerId The ID of the Panel to filter, optional
       * @returns {*}
       */
      getAutoProgrammingJobs: function (filter, orderby, select) {
        const deferred = $q.defer();
        const oDataFilter = {
          $filter: filter,
          $orderby: orderby,
          $select: select,
        };
        const promise = AutoProgramTaskDetailsOdata.get(oDataFilter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(deferred.resolve, deferred.reject)
          .catch(function (error) {
            deferred.reject(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Jobs in a particular jobGroupID (constrained by the user's auth_token)
       * @param jobGroupId
       * @returns {*}
       */
      getJobsListByJobGroupId: function (jobGroupId) {
        var _this = this;
        var deferred = $q.defer();
        // Default the groupType to get ALL Job Group types if no ID is passed in
        var filter = { $filter: "SchedulerJobGroupId eq " + jobGroupId };
        var promise = SchedulerJobOdata.get(filter).$promise;
        ODPS = new OdataPageService();
        ODPS.getAllPages(promise)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Create a new Job Group
       * @param jobGroupJson
       * @returns {*}
       */
      createJobGroup: function (jobGroupJson) {
        var _this = this;
        var deferred = $q.defer();
        var jobGroup = new SchedulerJobGroup();

        jobGroup = angular.extend(jobGroup, jobGroupJson);

        jobGroup.$save(
          function (success) {
            // Return the jobgroup to the caller
            deferred.resolve(success);
          },
          function (error) {
            //Data was NOT saved
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Get the Job Group Types
       * @returns {*}
       */
      getJobGroupTypes: function () {
        var _this = this;
        var deferred = $q.defer();
        if (_this.jobGroupTypes.length > 0) {
          deferred.resolve(_this.jobGroupTypes);
        } else {
          SchedulerJobGroupTypes.query(
            function (data) {
              //Job Group Types list was retrieved
              _this.jobGroupTypes = data;
              deferred.resolve(_this.jobGroupTypes);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        }
        return deferred.promise;
      },

      /**
       * Get a specific Job Group Type by Name
       * @param jobGroupType
       * @returns {*}
       */
      getJobGroupTypeByName: function (jobGroupType) {
        var _this = this;
        var deferred = $q.defer();
        if (this.jobGroupTypes.length > 0) {
          deferred.resolve(
            $filter("filter")(_this.jobGroupTypes, {
              GroupType: jobGroupType,
            })[0]
          );
        } else {
          _this
            .getJobGroupTypes()
            .then(
              function () {
                deferred.resolve(
                  $filter("filter")(_this.jobGroupTypes, {
                    GroupType: jobGroupType,
                  })[0]
                );
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        return deferred.promise;
      },

      /**
       * Get the Job Types
       * @returns {*}
       */
      getJobTypes: function () {
        var _this = this;
        var deferred = $q.defer();
        if (_this.jobTypes.length > 0) {
          deferred.resolve(_this.jobTypes);
        } else {
          SchedulerJobTypes.query(
            function (data) {
              //Job Group Types list was retrieved
              _this.jobTypes = data;
              deferred.resolve(_this.jobTypes);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        }
        return deferred.promise;
      },

      /**
       * Get a specific Job Type by Name
       * @param jobType
       * @returns {*}
       */
      getJobTypeByName: function (jobType) {
        var _this = this;
        var deferred = $q.defer();
        if (this.jobTypes.length > 0) {
          deferred.resolve(
            $filter("filter")(_this.jobTypes, { JobType: jobType })[0]
          );
        } else {
          _this
            .getJobTypes()
            .then(
              function () {
                deferred.resolve(
                  $filter("filter")(_this.jobTypes, { JobType: jobType })[0]
                );
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        return deferred.promise;
      },

      /**
       * A basic template of a Job Group object
       * @returns {{SchedulerJobGroupTypeId: number, GroupDesc: string, Email: *, GroupOutput: null, ReferenceId: null, ReferenceDesc: null, SchedulerJobs: Array}}
       */
      getJobGroupTemplate: function () {
        return {
          SchedulerJobGroupTypeId: 1,
          Email: UserService.email,
          GroupOutput: null,
          ReferenceId: null,
          ReferenceDesc: null,
          SchedulerJobs: [],
        };
      },

      /**
       * A basic template of a Job object
       * @returns {{JobDesc: string, SchedulerJobTypeId: number, PanelId: null, RemoteJson: string, RemoteAction: string, ReferenceId: null, ReferenceDesc: string}}
       */
      getJobTemplate: function () {
        return {
          SchedulerJobTypeId: 1,
          PanelId: null,
          RemoteJson: "{test: 'testing'}",
          RemoteAction: "POST",
          ReferenceId: null,
          ReferenceDesc: "Test Reference Description",
        };
      },

      abortJobGroup: function (jobID) {
        var _this = this;
        var deferred = $q.defer();
        var abort = new SchedulerJobGroupAbort();

        abort.$save(
          { id: jobID },
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
