/**
 *
 * SunriseSunsetIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const SunriseSunsetIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M62.551,50.601c-0.267-0.076-3.22-0.911-7.71-1.819c-0.016-0.042-0.023-0.086-0.042-0.128
    c-0.094-0.215-0.167-0.396-0.224-0.549c0.096-0.157,0.171-0.331,0.221-0.519c0.053-0.197,0.074-0.395,0.067-0.588
    c1.572-1.31,5.761-3.521,7.137-3.521c1.104,0,2-0.896,2-2s-0.896-2-2-2c-1.376,0-5.564-2.211-7.137-3.521
    c0.001-0.022,0.001-0.045,0.002-0.066c0-0.172-0.022-0.348-0.069-0.521c-0.053-0.195-0.133-0.377-0.234-0.541
    c0.706-1.921,3.228-5.93,4.419-6.617c0.642-0.371,1.001-1.043,1-1.734c0-0.34-0.086-0.684-0.268-0.998
    c-0.554-0.957-1.777-1.287-2.732-0.732c-1.19,0.688-5.927,0.867-7.941,0.519c-0.091-0.171-0.208-0.33-0.352-0.474
    s-0.304-0.261-0.474-0.352c-0.35-2.018-0.17-6.75,0.519-7.941C48.914,16.182,49,15.838,49,15.498c0.001-0.691-0.358-1.363-1-1.734
    c-0.956-0.554-2.18-0.225-2.732,0.732c-0.688,1.191-4.697,3.713-6.618,4.419c-0.163-0.102-0.345-0.183-0.541-0.235
    c-0.196-0.052-0.389-0.07-0.586-0.066C36.212,17.041,34,12.852,34,11.477c0-1.104-0.896-2-2-2s-2,0.896-2,2
    c0,1.375-2.212,5.564-3.522,7.137c-0.195-0.004-0.39,0.014-0.586,0.066s-0.378,0.134-0.541,0.235
    c-1.921-0.706-5.931-3.228-6.618-4.419C18.18,13.539,16.955,13.21,16,13.764c-0.642,0.371-1.001,1.043-1,1.734
    c0,0.34,0.086,0.684,0.268,0.998c0.688,1.191,0.868,5.924,0.519,7.941c-0.17,0.091-0.33,0.208-0.474,0.352
    s-0.261,0.303-0.352,0.474c-2.018,0.347-6.751,0.169-7.941-0.519c-0.96-0.555-2.18-0.225-2.732,0.732
    c-0.182,0.314-0.268,0.658-0.268,0.998c-0.001,0.691,0.358,1.363,1,1.734c1.191,0.688,3.713,4.696,4.419,6.617
    c-0.102,0.164-0.182,0.346-0.234,0.541c-0.047,0.174-0.069,0.35-0.069,0.521c0.001,0.021,0.001,0.044,0.002,0.066
    C7.564,37.266,3.376,39.477,2,39.477c-1.104,0-2,0.896-2,2s0.896,2,2,2c1.376,0,5.564,2.212,7.137,3.521
    c-0.007,0.193,0.015,0.391,0.067,0.588c0.051,0.188,0.126,0.361,0.222,0.52c-0.063,0.17-0.147,0.377-0.258,0.625
    c-0.019,0.041-0.026,0.083-0.041,0.125c-4.476,0.876-7.387,1.665-7.656,1.739c-1.065,0.292-1.691,1.394-1.399,2.458
    c0.293,1.064,1.394,1.689,2.458,1.399c0.148-0.041,15.052-4.079,30.232-4.079c14.271,0,28.546,4.033,28.688,4.073
    c0.184,0.053,0.368,0.077,0.551,0.077c0.87,0,1.671-0.572,1.922-1.45C64.227,52.012,63.612,50.904,62.551,50.601z M13.312,46.437
    c-0.225-1.608-2.908-3.54-5.645-4.96C10.413,40.053,13,38.114,13,36.504c0-0.002,0-0.004,0-0.006c0-0.001,0.212-0.002,0.213-0.004
    c0.622-1.501-0.438-4.473-2.103-7.078c3.083,0.143,6.297-0.246,7.286-1.537c0.002-0.002,0.006-0.004,0.007-0.006
    c1.289-0.989,1.675-4.197,1.536-7.287c2.604,1.665,5.574,2.937,7.078,2.313c0.003-0.002,0.007-0.002,0.01-0.002
    c1.61-0.213,3.551-2.798,4.975-5.543c1.424,2.745,3.364,5.33,4.975,5.543c0.003,0,0.011,0,0.013,0.002
    c1.499,0.618,4.479-0.647,7.084-2.313c-0.139,3.09,0.261,6.298,1.55,7.287c0.003,0.002,0.032,0.004,0.033,0.006
    c0.989,1.291,4.252,1.678,7.339,1.537c-1.665,2.605-2.829,5.577-2.208,7.078C50.789,36.496,51,36.497,51,36.498
    c0,0.002,0,0.004,0,0.006c0,1.61,2.587,3.549,5.332,4.973c-2.736,1.42-5.523,3.35-5.75,4.958c-0.205,0.471-0.235,0.989-0.167,1.53
    c-1.688-0.283-3.495-0.553-5.389-0.787c0.922-1.93,1.404-4.043,1.404-6.214c0-7.957-6.474-14.431-14.431-14.431
    s-14.431,6.474-14.431,14.431c0,2.208,0.498,4.354,1.451,6.31c-1.953,0.241-3.81,0.514-5.535,0.797
    C13.562,47.49,13.529,46.937,13.312,46.437z M23.364,46.812c-1.173-1.729-1.795-3.748-1.795-5.848
    c0-5.751,4.679-10.431,10.431-10.431s10.431,4.68,10.431,10.431c0,2.062-0.602,4.054-1.737,5.762
    c-2.575-0.219-5.244-0.353-7.932-0.353C29.557,46.373,26.388,46.543,23.364,46.812z"
      />
    </g>
  </SvgIcon>
);

export default SunriseSunsetIcon;
