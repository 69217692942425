import React, { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.header`
  display: flex;
  margin: 3rem 0 3rem;
  align-items: center;
  gap: 1rem;

  & h2 {
    margin: 0;
    font-size: 2rem;
  }
`;

type Props = {
  content: ReactNode;
};

const CleanPageSubHeader = ({ content }: Props) => {
  return <Wrapper>{content}</Wrapper>;
};

export default CleanPageSubHeader;
