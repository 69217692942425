import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationZone2BypassTimeField_deviceInformation$key } from "./__generated__/DeviceInformationZone2BypassTimeField_deviceInformation.graphql";

export const deviceInformationZone2BypassTimeFieldId = (number: string) =>
  `device-information-zone2-bypass-time-${number}`;

function DeviceInformationZone2BypassTimeField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationZone2BypassTimeField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationZone2BypassTimeField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                zone2BypassOnRequestToExit
                zone2BypassTime
              }
            }
          }
          ... on Xt75DeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                zone2BypassOnRequestToExit
                zone2BypassTime
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationZone2BypassTimeFieldId(String(number));
  const relayEnv = useRelayEnvironment();
  const originalValue = React.useRef(device734?.zone2BypassTime).current;
  const disabled =
    !device734?.zone2BypassOnRequestToExit ||
    deviceType !== DeviceInformationType.DOOR ||
    !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Zone 2 Bypass Time"
      tooltip="Seconds before the Zone 2 Bypass timer expires. If the door is still open at the end of the timer, Zone 2 reports the open/short condition."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        pattern="(0?[2-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)"
        value={device734?.zone2BypassTime ?? 40}
        inlineHelp="20-250"
        min={20}
        max={250}
        required
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options && isNotNullOrUndefined(target.value)) {
              device734Options.setValue(
                emptyStringOrNumber(target.value),
                "zone2BypassTime"
              );
            }
          });
        }}
        onBlur={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options && isNotNullOrUndefined(target.value)) {
              const valueAsNumber = Number(target.value);
              const value = isNaN(valueAsNumber)
                ? originalValue
                : clamp(20, 250, valueAsNumber);
              device734Options.setValue(Number(value), "zone2BypassTime");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationZone2BypassTimeField;
