App.directive("daLowerThan", [
  function () {
    var link = function ($scope, $element, $attrs, ctrl) {
      var validate = function (viewValue) {
        var comparisonModel = $scope.daLowerThan;

        if (!viewValue) {
          // It's valid because we have nothing to compare against
          ctrl.$setValidity("lowerThan", true); //
        } else {
          // It's valid if model is lower than the model we're comparing against
          ctrl.$setValidity(
            "lowerThan",
            parseInt(viewValue, 10) < parseInt(comparisonModel, 10)
          );
          //TODO: check if 'lowerThan' is tied to the directive name
        }

        return viewValue;
      };

      ctrl.$parsers.unshift(validate);
      ctrl.$formatters.push(validate);

      $scope.$watch("daLowerThan", function (comparisonModel) {
        // Whenever the comparison model changes we'll re-validate
        return validate(ctrl.$viewValue);
      });
    };

    return {
      require: "ngModel",
      link: link,
      scope: { daLowerThan: "=" },
    };
  },
]);
