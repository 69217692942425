import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import {
  CommunicationPathDayOfWeek,
  CommunicationPathTestFrequencyUnit,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathTestFrequencyDayField_communicationPath$key } from "./__generated__/CommPathTestFrequencyDayField_communicationPath.graphql";

export const commPathTestFrequencyDayFieldId = (number: string) =>
  `comm-path-test-frequency-day-${number}`;

function CommPathTestFrequencyDayField() {
  const [
    { number, testDayOfWeek, testFrequencyUnit, testFrequencyNumber },
    updateCommPath,
  ] = useCommPathFragment<CommPathTestFrequencyDayField_communicationPath$key>(
    graphql`
      fragment CommPathTestFrequencyDayField_communicationPath on CommunicationPath {
        number
        testDayOfWeek
        testFrequencyUnit
        testFrequencyNumber
      }
    `
  );

  const fieldId = commPathTestFrequencyDayFieldId(String(number));
  const disabled =
    testFrequencyUnit !== CommunicationPathTestFrequencyUnit.DAYS ||
    testFrequencyNumber === 0 ||
    testFrequencyNumber % 7 !== 0;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Test Day"
      tooltip={
        "Determines which day of the week the test report will be sent for this path."
      }
      disabled={disabled}
    >
      <Select
        id={fieldId}
        value={
          testDayOfWeek === CommunicationPathDayOfWeek.NONE ? "" : testDayOfWeek
        }
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "testDayOfWeek");
          });
        }}
      >
        <Select.Option disabled value={""}>
          Please Select a Day
        </Select.Option>
        <Select.Option value={CommunicationPathDayOfWeek.SUNDAY}>
          Sunday
        </Select.Option>
        <Select.Option value={CommunicationPathDayOfWeek.MONDAY}>
          Monday
        </Select.Option>
        <Select.Option value={CommunicationPathDayOfWeek.TUESDAY}>
          Tuesday
        </Select.Option>
        <Select.Option value={CommunicationPathDayOfWeek.WEDNESDAY}>
          Wednesday
        </Select.Option>
        <Select.Option value={CommunicationPathDayOfWeek.THURSDAY}>
          Thursday
        </Select.Option>
        <Select.Option value={CommunicationPathDayOfWeek.FRIDAY}>
          Friday
        </Select.Option>
        <Select.Option value={CommunicationPathDayOfWeek.SATURDAY}>
          Saturday
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathTestFrequencyDayField;
