/**
 * @generated SignedSource<<3c2cc84addefe691b46e4fd934f9396f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFLockoutCodeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly lockoutCode: {
      readonly code: string;
      readonly id: string;
    } | null;
  };
  readonly " $fragmentType": "XFLockoutCodeProgrammingConceptFormInline_controlSystem";
};
export type XFLockoutCodeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFLockoutCodeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFLockoutCodeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFLockoutCodeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "cb45e32d5441c481ff018536d931fde6";

export default node;
