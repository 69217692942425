import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel, useHasWifiCommType } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsWifiSsidField_networkOptions$key } from "./__generated__/NetworkOptionsWifiSsidField_networkOptions.graphql";

export const networkOptionsWifiSsidFieldId = () => "network-options-wifi-ssid";

function NetworkOptionsWifiSsidField() {
  const [{ wifiSsid }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsWifiSsidField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsWifiSsidField_networkOptions on NetworkOptions {
          wifiSsid
        }
      `
    );

  const fieldId = networkOptionsWifiSsidFieldId();
  const hasWifi = useHasWifiCommType();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);
  const disabled = !isXr && !hasWifi;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Wi-Fi SSID"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={wifiSsid}
        maxLength={32}
        pattern="^((?=.*\S$).*)$"
        inlineHelp="32 character maximum with no trailing spaces."
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "wifiSsid");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsWifiSsidField;
