import { css } from "styled-components/macro";

export type IndicatorState = "WARNING" | "INFO";

export const indicatorStyles = css<{
  indicator?: IndicatorState | null | undefined;
}>`
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.5em;
  background-color: ${({ indicator }) => {
    switch (indicator) {
      case "WARNING":
        return "var(--color-state-warning)";
      default:
        return "var(--color-info-900)";
    }
  }};
  border-radius: 9999px;
  opacity: ${({ indicator }) => (indicator ? 1 : 0)};
`;
