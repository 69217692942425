import Tooltip from "components/DaStyledElements/Tooltip";
import Icon from "components/Icon";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
`;
const Label = styled.label`
  margin: 0;
  font-weight: 200;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  color: gray;
  max-width: 10rem;
  user-select: none;
  line-height: 1.1;
  text-align: right;

  @media (min-width: 600px) {
    display: -webkit-box;
    max-width: 26rem;
  }
  @media (min-width: 704px) {
    max-width: 20rem;
  }
  @media (min-width: 800px) {
    max-width: 26rem;
  }
  @media (min-width: 960px) {
    max-width: 20rem;
  }
  @media (min-width: 1024px) {
    max-width: 26rem;
  }
  @media (min-width: 1200px) {
    max-width: 20rem;
  }
  @media (min-width: 1300px) {
    max-width: 30rem;
  }
  @media (min-width: 1400px) {
    max-width: 40rem;
  }
  @media (min-width: 1500px) {
    max-width: 50rem;
  }
  @media (min-width: 1600px) {
    max-width: 60rem;
  }
  @media (min-width: 1700px) {
    max-width: 70rem;
  }
  @media (min-width: 1800px) {
    max-width: 80rem;
  }
`;
const MobileIcon = styled(Icon)`
  color: var(--color-neutral-600);

  &:hover {
    color: var(--color-neutral-800);
  }

  @media (min-width: 600px) {
    display: none;
  }
`;

function InlineHelp({
  placeholder,
  children,
  ...rest
}: React.ComponentProps<typeof Label>) {
  if (placeholder) {
    return (
      <Container>
        <Label title={children} {...rest}>
          {placeholder}
        </Label>
        <MobileIcon name="radial_question" />
      </Container>
    );
  } else {
    return (
      <Container>
        <Tooltip content={children}>
          <Label {...rest}>{children}</Label>
          <MobileIcon name="radial_question" />
        </Tooltip>
      </Container>
    );
  }
}

export default InlineHelp;
