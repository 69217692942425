/**
 *
 * MovieNightIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const MovieNightIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M53.711,9.238c-0.385-3.465-3.32-6.166-6.873-6.166c-0.537,0-1.071,0.062-1.59,0.185c-1.514-1.56-3.595-2.456-5.818-2.456
    c-1.154,0-2.271,0.238-3.298,0.697C34.92,0.541,33.41,0,31.854,0c-1.534,0-2.994,0.504-4.186,1.416
    c-0.977-0.405-2.026-0.615-3.1-0.615c-2.222,0-4.303,0.896-5.816,2.456c-0.519-0.122-1.053-0.185-1.588-0.185
    c-3.554,0-6.49,2.702-6.875,6.166c-2.63,1.385-4.314,4.127-4.314,7.219c0,1.561,0.435,3.786,1.38,5.326l4.953,39.455
    C12.496,62.813,13.832,64,15.416,64h33.166c1.579,0,2.916-1.184,3.109-2.75l4.954-39.466c0.945-1.54,1.38-3.765,1.38-5.327
    C58.025,13.366,56.342,10.624,53.711,9.238z M12.866,12.482l1.573-0.484l-0.173-1.637c-0.013-0.115-0.021-0.232-0.021-0.349
    c0-1.622,1.309-2.94,2.918-2.94c0.473,0,0.92,0.107,1.329,0.321l1.62,0.845l0.987-1.537c0.765-1.189,2.061-1.9,3.468-1.9
    c0.828,0,1.628,0.248,2.313,0.715l1.535,1.049l1.157-1.455C30.128,4.415,30.979,4,31.854,4c0.909,0,1.785,0.446,2.343,1.193
    l1.193,1.601l1.603-1.191c0.705-0.524,1.549-0.802,2.438-0.802c1.408,0,2.705,0.711,3.47,1.9l0.988,1.537l1.62-0.846
    c0.408-0.213,0.855-0.32,1.33-0.32c1.607,0,2.916,1.318,2.916,2.94c0,0.117-0.008,0.233-0.021,0.349l-0.173,1.637l1.572,0.484
    c1.73,0.534,2.893,2.131,2.893,3.975c0,0.802-0.198,1.799-0.47,2.543H27.659c-0.702-0.277-1.585-1.907-1.585-3.4
    c0-1.2,0.968-2.177,2.158-2.177c0.522,0,1.031,0.196,1.432,0.555c0.655,0.586,1.614,0.673,2.366,0.22
    c0.422-0.254,0.893-0.384,1.399-0.384c1.68,0,2.247,0.524,3.143,1.722c0.66,0.883,1.914,1.066,2.8,0.403
    c0.884-0.661,1.064-1.915,0.403-2.8c-1.203-1.608-2.785-3.325-6.346-3.325c-0.766,0-1.52,0.129-2.23,0.378
    c-0.905-0.501-1.924-0.769-2.967-0.769c-3.396,0-6.158,2.771-6.158,6.177c0,1.051,0.251,2.262,0.724,3.4H10.444
    c-0.271-0.744-0.47-1.741-0.47-2.543C9.975,14.613,11.137,13.016,12.866,12.482z M34,60V45.338
    c3.358-0.891,5.845-3.958,5.845-7.605c0-3.646-2.486-6.714-5.845-7.604V23h9.933l-4.045,37H34z M23.425,60l-4.052-37h7.776
    c0.229,0.036,0.465,0.057,0.709,0.057c0.157,0,0.31-0.022,0.456-0.057H30v7.128c-3.358,0.891-5.845,3.958-5.845,7.604
    c0,3.647,2.486,6.715,5.845,7.605V60H23.425z M32,41.607c-2.12,0-3.845-1.738-3.845-3.875c0-2.136,1.725-3.874,3.845-3.874
    s3.845,1.738,3.845,3.874C35.845,39.869,34.12,41.607,32,41.607z M11.539,23h3.811L19.4,60h-3.218L11.539,23z M47.817,60h-3.906
    l4.045-37h4.505L47.817,60z"
      />
    </g>
  </SvgIcon>
);

export default MovieNightIcon;
