import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationZoneRetardDelayField_zone$key } from "./__generated__/ZoneInformationZoneRetardDelayField_zone.graphql";

export const zoneInformationZoneRetardDelayFieldId = (number: string) =>
  `zone-information-zone-retard-delay-${number}`;

function ZoneInformationZoneRetardDelayField() {
  const [
    { number, type, retardDelayEnabled, crossZoneEnabled },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationZoneRetardDelayField_zone$key>(
    graphql`
      fragment ZoneInformationZoneRetardDelayField_zone on Zone {
        number
        retardDelayEnabled
        type
        crossZoneEnabled
      }
    `
  );

  const fieldId = zoneInformationZoneRetardDelayFieldId(String(number));
  const label = "Zone Retard Delay";
  const disabled =
    ![
      ZoneType.FIRE,
      ZoneType.PANIC,
      ZoneType.SUPERVISORY,
      ZoneType.CARBON_MONOXIDE,
      ZoneType.AUXILIARY_1,
      ZoneType.AUXILIARY_2,
    ].includes(type as ZoneType) || crossZoneEnabled;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Allows this Zone to have the Zone Retard Delay programmed in System Options applied. The Zone must be shorted for this duration before an alarm is sent."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={retardDelayEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!retardDelayEnabled, "retardDelayEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationZoneRetardDelayField;
