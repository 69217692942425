App.service("ControlSystemNotesService", [
  "ControlSystemNotesAPI",
  "$q",
  "UserService",
  function (ControlSystemNotesAPI, $q, UserService) {
    // Public accessors
    this.getNotes = getNotes;
    this.getNewNoteTemplate = getNewNoteTemplate;
    this.create = create;
    this.update = update;
    this.destroy = destroy;

    // Private functions and variables
    /**
     * Get system notes for the current system (UserService.controlSystem)
     * @returns {*}
     */
    function getNotes() {
      var deferred = $q.defer();
      ControlSystemNotesAPI.getNotes(
        { controlSystemId: UserService.controlSystem.id },
        function (notes) {
          deferred.resolve(notes);
        },
        function (error) {
          console.error(
            "ControlSystemNotesAPI->getNotes() error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getNewNoteTemplate() {
      var deferred = $q.defer();
      ControlSystemNotesAPI.getNewNoteTemplate(
        { controlSystemId: UserService.controlSystem.id },
        function (newNoteTemplate) {
          deferred.resolve(newNoteTemplate);
        },
        function (error) {
          console.error(
            "ControlSystemNotesAPI->getNewNoteTemplate() error: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function create(note) {
      var deferred = $q.defer();
      ControlSystemNotesAPI.create(
        {},
        angular.toJson(note),
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "ControlSystemNotesAPI->create() error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function update(note) {
      var deferred = $q.defer();
      ControlSystemNotesAPI.update(
        { noteId: note.Id },
        angular.toJson(note),
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "ControlSystemNotesAPI->create() update: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function destroy(note) {
      var deferred = $q.defer();
      ControlSystemNotesAPI.destroy(
        { noteId: note.Id },
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "ControlSystemNotesAPI->destroy() error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
  },
]);
