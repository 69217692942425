import graphql from "babel-plugin-relay/macro";
import { GenericPageFallback } from "components/GenericPageFallback";
import RelayEnvironmentCloneProvider from "components/RelayEnvironmentCloneProvider";
import { $RootScope, AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import {
  Environment,
  RelayEnvironmentProvider,
  useLazyLoadQuery,
} from "react-relay";
import { react2angular } from "react2angular";
import {
  fromSiteId,
  idAsString,
  toVideoChannelId,
} from "securecom-graphql/client";
import UniviewCameraEditForm from "./UniviewCameraEditForm";
import { UniviewCameraEditQuery } from "./__generated__/UniviewCameraEditQuery.graphql";

interface UniviewCameraEditProps {
  cameraId: string;
  channelId: string;
  $state: any;
  UserService: any;
  TIME_ZONES: string[];
  $rootScope: any;
}
const UniviewCameraEdit = (props: UniviewCameraEditProps) => {
  const { cameraId, channelId, $state, UserService, TIME_ZONES, $rootScope } =
    props;
  const data = useLazyLoadQuery<UniviewCameraEditQuery>(
    graphql`
      query UniviewCameraEditQuery($cameraId: ID!) {
        camera: node(id: $cameraId) {
          ... on SecureComCamera {
            ...UniviewCameraEditForm_secureComCamera
          }
        }
      }
    `,
    {
      cameraId: idAsString(
        toVideoChannelId("SecureComCamera", cameraId, channelId)
      ),
    },
    { fetchPolicy: "network-only" }
  );
  const goToControlSystemRoute = () => {
    if (!!UserService.site) {
      $state.go("app.sites.edit", {
        customer_id: UserService.customer_id,
        site_id: fromSiteId(UserService.site.id).siteId,
      });
    } else {
      $state.go("app.control_system", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
      });
    }
  };

  React.useEffect(() => {
    if (!data.camera || !UserService.control_system_id) {
      goToControlSystemRoute();
    }
    // eslint-disable-next-line
  }, [UserService.control_system_id]);

  return data.camera && UserService.control_system_id ? (
    <RelayEnvironmentCloneProvider>
      <UniviewCameraEditForm
        camera={data.camera}
        canDelete={UserService.canDeleteVideoDevice()}
        controlSystemId={UserService.control_system_id}
        dealerId={UserService.dealer_id}
        isEditable={UserService.canEditVideoDevice()}
        isSupervisor={UserService.isSupervisorAccessible()}
        isX1={!!UserService.site}
        onCancel={goToControlSystemRoute}
        timeZones={TIME_ZONES}
        canViewPricing={UserService.canViewSecureComPricing()}
        userService={UserService}
        rootScope={$rootScope}
        canFormatSdCard={UserService.canFormatSdCard()}
      />
    </RelayEnvironmentCloneProvider>
  ) : null;
};

function UniviewCameraEditRoot(
  props: React.ComponentProps<typeof UniviewCameraEdit> & {
    RelayService: { getEnvironment: () => Environment };
    $rootScope: $RootScope;
  }
) {
  return (
    <React.Suspense fallback={<GenericPageFallback />}>
      <RelayEnvironmentProvider
        environment={props.RelayService.getEnvironment()}
      >
        <AlertsContextProvider $rootScope={props.$rootScope}>
          <UniviewCameraEdit {...props} />
        </AlertsContextProvider>
      </RelayEnvironmentProvider>
    </React.Suspense>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "univiewCameraEdit",
    react2angular(
      UniviewCameraEditRoot,
      ["cameraId", "channelId"],
      [
        "$rootScope",
        "$scope",
        "$state",
        "UserService",
        "RelayService",
        "TIME_ZONES",
      ]
    )
  );
}
