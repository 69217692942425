import GenericSwitch from "components/Switch";
import * as React from "react";
import { useFieldChangesAndValidity } from "../hooks/use-field-changes-and-validity";

const Switch = React.forwardRef(
  (
    props: { id: string } & Omit<
      React.ComponentProps<typeof GenericSwitch>,
      "id"
    > & {
        getValidationMessage?: (input: HTMLInputElement) => string;
      },
    ref: React.ForwardedRef<HTMLInputElement | null>
  ) => {
    const {
      id,
      type,
      value,
      checked,
      onChange,
      onFocus,
      onBlur,
      onInvalid,
      getValidationMessage,
      ...rest
    } = props;
    return (
      <GenericSwitch
        {...rest}
        {...useFieldChangesAndValidity({
          ref,
          id,
          type: "checkbox",
          value,
          checked,
          onChange,
          onFocus,
          onBlur,
          onInvalid,
          getValidationMessage,
        })}
      />
    );
  }
);

export default Switch;
