import DaColors from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import { DateIcon } from "common/components/web/Icons";
import { addClassesIf } from "common/utils/web/css";
import {
  CleanConfirmingDelete,
  Input,
  Panel,
  Table,
  TableFooter,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import Icon from "components/Icon";
import { useShowAlert } from "contexts/AlertsContext";
import { pipe } from "fp-ts/function";
import moment from "moment";
import * as R from "ramda";
import * as React from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import { objectSearch } from "search-filterer";
import { HolidayDateClass, toControlSystemId } from "securecom-graphql/client";
import styled from "styled-components/macro";
import SchedulesHolidayDatesModal, {
  holidayDateListItem,
} from "./SchedulesHolidayDatesModal";
import { SchedulesHolidayDatesTableDeleteMutation } from "./__generated__/SchedulesHolidayDatesTableDeleteMutation.graphql";
import { SchedulesHolidayDatesTableQuery } from "./__generated__/SchedulesHolidayDatesTableQuery.graphql";

enum SortKey {
  Name,
  Date,
  Class,
}

export default function SchedulesHolidayDatesTable({
  systemId,
}: {
  systemId: number;
}) {
  const data = useLazyLoadQuery<SchedulesHolidayDatesTableQuery>(
    graphql`
      query SchedulesHolidayDatesTableQuery($systemId: ID!) {
        controlSystem: node(id: $systemId) {
          ... on ControlSystem {
            id
            holidayDates {
              id
              name
              description
              date
              class
              isGlobalHoliday
              globalHolidayId
            }
          }
        }
      }
    `,
    { systemId: String(toControlSystemId(systemId)) },
    { fetchPolicy: "network-only" }
  );
  const [deleteHolidayDate] =
    useMutation<SchedulesHolidayDatesTableDeleteMutation>(deleteMutation);
  const showAlert = useShowAlert();
  const [selectedHoliday, setSelectedHoliday] =
    React.useState<holidayDateListItem>({
      clazz: HolidayDateClass.A,
      date: "",
      description: "",
      id: "",
      name: "",
      globalHolidayId: null,
    });
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Ascending,
    sortKey: SortKey.Date,
  });
  const holidayDates = data.controlSystem?.holidayDates!;
  // break these out so changing page size doesn't cause it to re-search etc...
  const searchedHolidays = React.useMemo(
    () =>
      pipe(holidayDates, (holidays) =>
        objectSearch(paginationState.search, ["name"], holidays)
      ),
    [holidayDates, paginationState.search]
  );

  const sortedHolidays = React.useMemo(
    () =>
      R.sort(
        (paginationState.sortOrder === SortOrder.Ascending
          ? R.ascend
          : R.descend)((holiday) => {
          if (holiday) {
            switch (paginationState.sortKey) {
              case SortKey.Name:
                return holiday.name;
              case SortKey.Date:
                return holiday.date;
              case SortKey.Class:
                return holiday.class;
              default:
                return holiday.date;
            }
          }
        }),
        searchedHolidays
      ),
    [paginationState.sortKey, paginationState.sortOrder, searchedHolidays]
  );

  const pagedHolidays = React.useMemo(
    () =>
      R.splitEvery(paginationState.pageSize, sortedHolidays)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, sortedHolidays]
  );

  const maxPage = Math.ceil(sortedHolidays.length / paginationState.pageSize);
  return (
    <Panel.Table>
      <TableHeader>
        <TableHeader.Left>
          <label className="sr-only" htmlFor="search-input"></label>
          <Input.TableSearch
            id="search-input"
            value={paginationState.search}
            onChange={(search) => {
              dispatch({
                type: ActionType.SetSearch,
                search,
              });
            }}
          />
        </TableHeader.Left>
      </TableHeader>
      <Table>
        <thead>
          <tr>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Name, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Name) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Name,
                  });
                }
              }}
            >
              Name
            </TableHeaderSorting>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Date, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Date) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Date,
                  });
                }
              }}
            >
              Date
            </TableHeaderSorting>
            <TableHeaderSorting
              className={addClassesIf(
                [
                  [paginationState.sortKey === SortKey.Class, "is-active"],
                  [
                    paginationState.sortOrder === SortOrder.Descending,
                    "tablesort-desc",
                  ],
                  [
                    paginationState.sortOrder === SortOrder.Ascending,
                    "tablesort-asc",
                  ],
                ],
                "tablesort-sortable"
              )}
              onClick={() => {
                if (paginationState.sortKey === SortKey.Class) {
                  dispatch({
                    type: ActionType.SetSortOrder,
                    sortOrder:
                      paginationState.sortOrder === SortOrder.Ascending
                        ? SortOrder.Descending
                        : SortOrder.Ascending,
                  });
                } else {
                  dispatch({
                    type: ActionType.SetSortKey,
                    sortKey: SortKey.Class,
                  });
                }
              }}
            >
              Class
            </TableHeaderSorting>
          </tr>
        </thead>

        <tbody>
          {!R.isEmpty(pagedHolidays) ? (
            // eslint-disable-next-line array-callback-return
            pagedHolidays.map((holiday) => {
              if (holiday) {
                return (
                  <tr key={holiday.id}>
                    <td>
                      {holiday.isGlobalHoliday ? <DateIcon /> : <IconSpacer />}
                      &nbsp;
                      {holiday.name ?? null}
                    </td>
                    <td>{moment(holiday.date).format("MM/DD")}</td>
                    <td>{holiday.class}</td>
                    <TableDataCell>
                      <ButtonsContainer>
                        <TableItemIconButton
                          name="settings"
                          color="var(--color-primary-500)"
                          size="2.2rem"
                          onClick={() => {
                            setSelectedHoliday({
                              clazz: holiday.class as HolidayDateClass,
                              date: holiday.date,
                              description: holiday.description,
                              id: holiday.id,
                              name: holiday.name,
                              globalHolidayId: holiday.globalHolidayId,
                            });
                            setIsEditModalOpen(true);
                          }}
                        />
                        <CleanConfirmingDelete
                          clickReceiver={(onClick) => (
                            <Icon
                              name="trash"
                              size="2.4rem"
                              color={DaColors.Failure500}
                              onClick={onClick}
                            />
                          )}
                          onConfirm={() =>
                            deleteHolidayDate({
                              variables: {
                                encodedHolidayId: holiday.id,
                              },
                              onCompleted: (res) => {
                                if (res.deleteHolidayDate.errorMessage) {
                                  showAlert({
                                    type: "error",
                                    text: res.deleteHolidayDate.errorMessage,
                                  });
                                } else {
                                  showAlert({
                                    type: "success",
                                    text: "Successfully deleted the holiday.",
                                  });
                                }
                              },
                              onError: () => {
                                showAlert({
                                  type: "error",
                                  text: "An unknown error has occurred while attempting to delete the holiday.",
                                });
                              },
                            })
                          }
                          message={
                            "Are you sure you would like to delete this holiday from the panel?"
                          }
                        />
                      </ButtonsContainer>
                    </TableDataCell>
                  </tr>
                );
              }
            })
          ) : (
            <tr>
              <td colSpan={4}>No holidays added.</td>
            </tr>
          )}
        </tbody>
        {isEditModalOpen ? (
          <SchedulesHolidayDatesModal
            closeModal={() => setIsEditModalOpen(false)}
            systemId={systemId}
            holidaySelectionList={[selectedHoliday]}
          />
        ) : null}
      </Table>
      <TableFooter>
        <TableFooter.Left></TableFooter.Left>
        <TableFooter.Center>
          <Paginate.Paginator
            state={paginationState}
            dispatch={dispatch}
            maxPage={maxPage}
          />
        </TableFooter.Center>
        <TableFooter.Right>
          <Paginate.ItemsPerPage state={paginationState} dispatch={dispatch} />
        </TableFooter.Right>
      </TableFooter>
    </Panel.Table>
  );
}

const deleteMutation = graphql`
  mutation SchedulesHolidayDatesTableDeleteMutation($encodedHolidayId: ID!) {
    deleteHolidayDate(encodedHolidayId: $encodedHolidayId) {
      ... on DeleteHolidayDateSuccessResponse {
        controlSystem {
          holidayDates {
            id
            name
            date
            description
            class
            isGlobalHoliday
          }
        }
      }
      ... on DeleteHolidayDateErrorResponse {
        errorMessage
      }
    }
  }
`;

const TableHeaderSorting = styled.th`
  cursor: pointer;
  color: #888 !important;
`;
const TableDataCell = styled.td`
  display: flex;
  justify-content: flex-end;
`;
const ButtonsContainer = styled.div`
  width: 5.8rem;
  display: flex;
  justify-content: space-between;
`;
const TableItemIconButton = styled(Icon)`
  cursor: pointer;
`;
const IconSpacer = styled.div`
  width: 1em;
  display: inline-block;
`;
