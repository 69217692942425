import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { setIncludedFields as setAreaInformationIncludedFields } from "./Data/XTAreaInformationTemplateData";
import { setIncludedFields as setBellOptionsIncludedFields } from "./Data/XTBellOptionsTemplateData";
import { setIncludedFields as setCommunicationIncludedFields } from "./Data/XTCommunicationTemplateData";
import { setIncludedFields as setDeviceInformationIncludedFields } from "./Data/XTDeviceInformationTemplateData";
import { setIncludedFields as setKeyfobsIncludedFields } from "./Data/XTKeyfobsTemplateData";
import { setIncludedFields as setLockoutCodeIncludedFields } from "./Data/XTLockoutCodeTemplateData";
import { setIncludedFields as setNetworkOptionsIncludedFields } from "./Data/XTNetworkOptionsTemplateData";
import { setIncludedFields as setOutputInformationsIncludedFields } from "./Data/XTOutputInformationTemplateData";
import { setIncludedFields as setOutputOptionsIncludedFields } from "./Data/XTOutputOptionsTemplateData";
import { setIncludedFields as setRemoteOptionsIncludedFields } from "./Data/XTRemoteOptionsTemplateData";
import { setIncludedFields as setSecurityGradeIncludedFields } from "./Data/XTSecurityGradeTemplateData";
import { setIncludedFields as setSystemOptionsIncludedFields } from "./Data/XTSystemOptionsTemplateData";
import { setIncludedFields as setSystemReportsIncludedFields } from "./Data/XTSystemReportsTemplateData";
import { setIncludedFields as setZoneInformationIncludedFields } from "./Data/XTZoneInformationTemplateData";
import { XTTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XTTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        ...XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTBellOptionsTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTCommunicationTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTKeyfobsTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTNetworkOptionsTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTOutputInformationTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTRemoteOptionsTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTSystemOptionsTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTSystemReportsTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTZoneInformationTemplateDataInline_xtProgrammingTemplateConcepts
        ...XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts
      }
    `,
    template
  );

export const setAllIncludedFields = (
  template: XTTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const templateData = getState(template);

  setAreaInformationIncludedFields(templateData, setIncludedFields);
  setBellOptionsIncludedFields(templateData, setIncludedFields);
  setCommunicationIncludedFields(templateData, setIncludedFields);
  setDeviceInformationIncludedFields(templateData, setIncludedFields);
  setKeyfobsIncludedFields(templateData, setIncludedFields);
  setLockoutCodeIncludedFields(templateData, setIncludedFields);
  setNetworkOptionsIncludedFields(templateData, setIncludedFields);
  setOutputOptionsIncludedFields(templateData, setIncludedFields);
  setOutputInformationsIncludedFields(templateData, setIncludedFields);
  setRemoteOptionsIncludedFields(templateData, setIncludedFields);
  setSystemOptionsIncludedFields(templateData, setIncludedFields);
  setSystemReportsIncludedFields(templateData, setIncludedFields);
  setZoneInformationIncludedFields(templateData, setIncludedFields);
  setSecurityGradeIncludedFields(templateData, setIncludedFields);
};
