/**
 * @generated SignedSource<<b01c173531c606066a71106975ca972a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaAutoDisarmField_area$data = {
  readonly autoDisarmEnabled: boolean;
  readonly id: string;
  readonly number: string;
  readonly " $fragmentType": "AreaAutoDisarmField_area";
};
export type AreaAutoDisarmField_area$key = {
  readonly " $data"?: AreaAutoDisarmField_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaAutoDisarmField_area">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaAutoDisarmField_area",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoDisarmEnabled",
      "storageKey": null
    }
  ],
  "type": "Area",
  "abstractKey": "__isArea"
};

(node as any).hash = "5d02594e1df3721dda8226b58f1ef6b0";

export default node;
