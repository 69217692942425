/**
 * @generated SignedSource<<83d1e93860bdf844ba125d50dadedddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type PanelResetStatisticConnectionType = "CELL" | "EASY_CONNECT" | "EASY_CONNECT_WITH_CELL_BACKUP" | "NETWORK" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ResetTypeTable$data = {
  readonly amount: number;
  readonly id: string;
  readonly panelStatisticsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly amountOfResets: number;
        readonly connectionType: PanelResetStatisticConnectionType;
        readonly controlSystem: {
          readonly customer: {
            readonly databaseId: number;
            readonly dealer: {
              readonly databaseId: number;
              readonly id: string;
            };
            readonly id: string;
          };
          readonly databaseId: number;
          readonly site: {
            readonly databaseId: number;
          } | null;
        } | null;
        readonly dealer: {
          readonly databaseId: number;
        } | null;
        readonly dealerName: string;
        readonly hardwareType: string;
        readonly id: string;
        readonly serial: string;
        readonly version: string;
      };
    }>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "ResetTypeTable";
};
export type ResetTypeTable$key = {
  readonly " $data"?: ResetTypeTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResetTypeTable">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "panelStatisticsConnection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ResetTypeTablePaginationFragment.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ResetTypeTable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": "panelStatisticsConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        }
      ],
      "concreteType": "PanelResetStatisticsConnection",
      "kind": "LinkedField",
      "name": "__PanelResetStatistics_panelStatisticsConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PanelResetStatisticEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PanelResetStatistic",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ControlSystem",
                  "kind": "LinkedField",
                  "name": "controlSystem",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Site",
                      "kind": "LinkedField",
                      "name": "site",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Customer",
                      "kind": "LinkedField",
                      "name": "customer",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Dealer",
                          "kind": "LinkedField",
                          "name": "dealer",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hardwareType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "version",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serial",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "connectionType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Dealer",
                  "kind": "LinkedField",
                  "name": "dealer",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dealerName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amountOfResets",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "PanelResetInformation",
  "abstractKey": null
};
})();

(node as any).hash = "e37a7aa1cc2261cb73b7a38c0c97c4ab";

export default node;
