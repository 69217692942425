/**
 * @generated SignedSource<<d849058f4d5b2d9d8574431b894c67ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MenuDisplayContext_menuDisplay$data = {
  readonly id: string;
  readonly " $fragmentType": "MenuDisplayContext_menuDisplay";
};
export type MenuDisplayContext_menuDisplay$key = {
  readonly " $data"?: MenuDisplayContext_menuDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"MenuDisplayContext_menuDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MenuDisplayContext_menuDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MenuDisplay",
  "abstractKey": null
};

(node as any).hash = "84309433a5376fb4e8406f85a07141a8";

export default node;
