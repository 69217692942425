/**
 * @generated SignedSource<<f78e37864c091170965f25ef9f775eb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TimeSetModalDateTimeMutation$variables = {
  dateTime: string;
  panelId: string;
};
export type TimeSetModalDateTimeMutation$data = {
  readonly sendDateTime: string;
};
export type TimeSetModalDateTimeMutation = {
  response: TimeSetModalDateTimeMutation$data;
  variables: TimeSetModalDateTimeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "panelId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dateTime",
        "variableName": "dateTime"
      },
      {
        "kind": "Variable",
        "name": "panelId",
        "variableName": "panelId"
      }
    ],
    "kind": "ScalarField",
    "name": "sendDateTime",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeSetModalDateTimeMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimeSetModalDateTimeMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c2120827a70bf92ffafab63428876457",
    "id": null,
    "metadata": {},
    "name": "TimeSetModalDateTimeMutation",
    "operationKind": "mutation",
    "text": "mutation TimeSetModalDateTimeMutation(\n  $panelId: String!\n  $dateTime: String!\n) {\n  sendDateTime(panelId: $panelId, dateTime: $dateTime)\n}\n"
  }
};
})();

(node as any).hash = "2c6943ea9887dd3a1189120daed96129";

export default node;
