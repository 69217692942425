import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationCrossZoneField_zone$key } from "./__generated__/ZoneInformationCrossZoneField_zone.graphql";

export const zoneInformationCrossZoneFieldId = (number: string) =>
  `zone-information-cross-zone-${number}`;

function ZoneInformationCrossZoneField() {
  const [
    { number, type, crossZoneEnabled, retardDelayEnabled },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationCrossZoneField_zone$key>(
    graphql`
      fragment ZoneInformationCrossZoneField_zone on Zone {
        number
        type
        crossZoneEnabled
        retardDelayEnabled
      }
    `
  );

  const fieldId = zoneInformationCrossZoneFieldId(String(number));
  const disabled =
    [
      ZoneType.ARMING,
      ZoneType.DOORBELL,
      ZoneType.FIRE_VERIFY,
      ZoneType.TAMPER,
    ].includes(type as ZoneType) || retardDelayEnabled;
  const label = "Cross Zone";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Enables cross-zoning for this Zone. Requires two Cross Zone trips within the Cross Zone Time. "
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={crossZoneEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!crossZoneEnabled, "crossZoneEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationCrossZoneField;
