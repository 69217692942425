import React from "react";

interface TableProps {
  data: {
    name: string;
    value: string | number;
  }[];
}

function DiagnosticNameValueTable(props: TableProps) {
  const { data } = props;

  return (
    <div className="table-condensed">
      <table className="table table-striped table-fixed--not-mobile">
        <tbody>
          {data.map(({ name, value }) => {
            return (
              <tr>
                <td className="text-bold">{name}</td>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DiagnosticNameValueTable;
