/**
 * @ngdoc service
 * @name App.factory:PanelsDiscoveryV1API
 *
 * @description
 * API factory that returns a list of panels associated to the dealer
 *
 */
App.factory("PanelsDiscoveryV1API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/1/panels/discover/",
      { safe: true }, //prevents api from wiping the panel data.  Kind of important!
      {
        get: { method: "GET", params: { page_size: 100 } },
      }
    );
  },
]);
