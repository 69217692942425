/**
 * @ngdoc service
 * @name App.factory:NearestTechAPI
 *
 * @description
 * API factory that returns a list of nearest technians to a panel
 *
 */
App.factory("NearestTechAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/TechLocations/NearestTech/Dealer/:dealer_id/Panel/:panel_id",
      {},
      {
        getTechs: {
          method: "GET",
          params: { panel_id: "@panel_id", dealer_id: "@dealer_id" },
        },
      }
    );
  },
]);
