import { getDealerUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import { parseNonAlarmVisionAlarmZoneCameras } from "./alarm-zone-cameras-parser";
import { parseDealerPricing } from "./dealer-pricing-parser";

const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;
const DEALER_SETTINGS_API_BASE_URL = getDealerUrl(secureComEnv);

const getDealerPricing = async (scwToken: string, dealerId: number) => {
  const path = `/api/v1/DealerCharges/List/${dealerId}`;
  const query = `?auth_token=${scwToken}`;
  const url = `${DEALER_SETTINGS_API_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseDealerPricing(responseJson);
  } else {
    throw Error(
      `Dealer Settings API Error: ${response.status}:${response.statusText}`
    );
  }
};

const getAlarmZoneCameras = async (
  scwToken: string,
  dealerId: number,
  controlSystemId: number
) => {
  const path = `/api/v1/vv/${dealerId}/id/${controlSystemId}`;
  const query = `?auth_token=${scwToken}`;
  const url = `${DEALER_SETTINGS_API_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseNonAlarmVisionAlarmZoneCameras(responseJson);
  } else {
    throw Error(
      `Dealer Settings API Error: ${response.status}:${response.statusText}`
    );
  }
};

export { getAlarmZoneCameras, getDealerPricing };
