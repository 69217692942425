import { cleanUpDateString, getTimeFormat } from "common/utils/universal/date";
import * as React from "react";
import { BaseInput, BaseInputProps } from "./BaseInput";
import InlineHelp from "./InlineHelp";
import Label from "./Label";

type TimeInputProps = Omit<
  BaseInputProps,
  "value" | "min" | "max" | "step" | "pattern" | "onChange"
> & {
  value: string;
  inlineHelp?: React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

/**
 * Time Input Component is used to ensure a a loosely formatted
 * datestring is converted into an ISO 8601 valid date string
 *
 * E.G.:
 * - "1 a" -> "01:00 AM"
 */
export const TimeInput = React.forwardRef(
  (
    {
      value,
      inlineHelp,
      className,
      onBlur,
      onChange,
      ...otherProps
    }: TimeInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [internalValue, setInternalValue] = React.useState(
      formatValue(value ?? "")
    );

    React.useEffect(() => {
      setInternalValue(formatValue(value ?? ""));
    }, [value]);

    return (
      <Label>
        <BaseInput
          {...otherProps}
          ref={ref}
          type="text"
          value={internalValue}
          className={`form-control ${className}`}
          pattern="^((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))$"
          onChange={(event) => setInternalValue(event.target.value)}
          onBlur={(event) => {
            const nextInternalValue = formatValue(event.target.value);
            setInternalValue(nextInternalValue);
            const nextValue = nextInternalValue
              ? deformatValue(nextInternalValue)
              : "";
            if (nextValue !== value) {
              onChange({
                target: { value: nextValue },
              } as any);
            }

            if (onBlur) {
              onBlur(event);
            }
          }}
        />
        {inlineHelp && <InlineHelp>{inlineHelp}</InlineHelp>}
      </Label>
    );
  }
);

const formatValue = (value: string): string => {
  const cleanedValue = cleanUpDateString(value ? value.trim() : "");
  const [hours, minutes, period] = cleanedValue.split(/[: ]/);

  switch (getTimeFormat(cleanedValue)) {
    case "24hr": {
      if (Number(hours) === 12) {
        return createFormattedTimeString(12, minutes, "PM");
      } else if (Number(hours) === 24) {
        return createFormattedTimeString(24, minutes, "AM");
      } else if (Number(hours) === 0) {
        return createFormattedTimeString(12, minutes, "AM");
      } else if (Number(hours) > 12) {
        return createFormattedTimeString(Number(hours) - 12, minutes, "PM");
      } else {
        return createFormattedTimeString(hours, minutes, "AM");
      }
    }
    case "12hr":
      return createFormattedTimeString(hours, minutes, period);
    default:
      return value;
  }
};

const deformatValue = (value: string): string => {
  const [hours, minutes, period] = value.split(/[: ]/);
  const am = createDeformattedTimeString(hours, minutes);
  const pm = createDeformattedTimeString(Number(hours) + 12, minutes);
  const midnight = createDeformattedTimeString(0, minutes);
  switch (period) {
    case "AM": {
      if (hours === "12") {
        return midnight;
      } else {
        return am;
      }
    }
    case "PM": {
      if (hours === "12") {
        return am;
      } else {
        return pm;
      }
    }
  }
  return value;
};

const createDeformattedTimeString = (
  hours: string | number,
  minutes: string | number
) =>
  `${(hours ?? "").toString().padStart(2, "0")}${(minutes ?? "")
    .toString()
    .padStart(2, "0")}`;

const createFormattedTimeString = (
  hours: string | number,
  minutes: string | number,
  period: string
) =>
  `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;
