import React from "react";
import styled from "styled-components";
import NakedButton from "../NakedButton";

const Button = styled(NakedButton)`
  width: 100%;
  min-width: 6.875rem;
  padding: 0.5em 0.75em;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.backgroundColor};
  font-size: 0.8em;
  line-height: 0.8em;
  white-space: pre;
  text-align: left;
  border-radius: 0.4em;
  opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  user-select: none;

  &:enabled:hover {
    background: ${({ theme }) => theme.backgroundColorHover};
    color: ${({ theme }) => theme.textColorHover};
  }
`;
const Wrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
`;
const IconWrapper = styled(Wrapper)`
  display: inline-block;
  margin-right: 7px;
`;
const TextWrapper = styled(Wrapper)`
  font-weight: 500;
`;

export type MenuItemProps = {
  message: React.ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

function PopUpMenuItem({ message, icon, ...rest }: MenuItemProps) {
  return (
    <Button type="button" {...rest}>
      <span>
        {!!icon && <IconWrapper>{icon}</IconWrapper>}
        <TextWrapper>{message}</TextWrapper>
      </span>
    </Button>
  );
}

export default PopUpMenuItem;
