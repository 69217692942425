import React, { useState } from "react";
//import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { not } from "ramda";
import { themeGrayAccent } from "../Theme";
import NakedButton from "../Button/NakedButton";
import VisuallyHidden from "../VisuallyHidden";
import PopUpMenu, { PopUpMenuProps } from "../PopUpMenu";
import messages from "./messages";

export { default as MenuItem } from "../PopUpMenu/MenuItem";

const Toggle = styled(NakedButton)`
  position: relative;
  width: 1em;
  height: 1em;
  color: ${themeGrayAccent};

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 1em;
    height: 1em;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid;
    font-size: 0.75em;
    transform: rotate(45deg) translate(-50%, -50%);
  }
`;

type Props = {
  items: PopUpMenuProps["items"];
  className?: string;
};

function CaretMenu({ items, className }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <PopUpMenu
      anchor={
        <Toggle
          onClick={() => {
            setOpen(not);
          }}
        >
          <VisuallyHidden>
            foo
            {/*  <FormattedMessage {...messages.menu} /> */}
          </VisuallyHidden>
        </Toggle>
      }
      className={className}
      items={items}
      onDismiss={() => {
        setOpen(false);
      }}
      open={open}
    />
  );
}

export default CaretMenu;
