/**
 * @generated SignedSource<<ded490205f17dc94895d5083eb959098>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly areas: {
      readonly __typename: "AreaConnection";
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "AreaEdge";
        readonly cursor: string | null;
        readonly node: {
          readonly __typename: string;
          readonly id: string;
          readonly isNew: boolean;
          readonly number: string;
          readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelAreaInformationProgrammingConceptFormInline_area">;
        } | null;
      }>;
    };
    readonly id: string;
  };
  readonly " $fragmentType": "TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem";
};
export type TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "d6dda6207b4966a5988c81744752cc87";

export default node;
