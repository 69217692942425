App.factory("AuthInterceptor", [
  "$q",
  "PROPS",
  "UserService",
  "$rootScope",

  function ($q, PROPS, UserService, $rootScope) {
    function logout() {
      $rootScope.logout();
    }

    return {
      request: function (config) {
        config.params = config.params || {};
        config.headers = config.headers || {};

        if (config.url.indexOf(PROPS.pulseApiUrl) >= 0) {
          //pulse api does not require auth
          return config || $q.when(config);
        }

        if (config.url.indexOf("about_me") > 0) {
          config.params = {}; //remove the token without disturbing the DA service. This could be synced back up when DA supports new auth
        }
        if (
          (UserService.auth_token &&
            config.url.indexOf(PROPS.apiUrl) >= 0 &&
            config.url.indexOf("v2") &&
            config.url.indexOf("sign_in") < 0) ||
          (config.url.indexOf("v4") && config.url.indexOf("login") < 0) ||
          (config.url.indexOf(PROPS.jobsApiUrl) >= 0 &&
            config.url.indexOf("$skip") < 0) ||
          (config.url.indexOf(PROPS.reportsApiUrl) >= 0 &&
            config.url.indexOf("$skip") < 0) ||
          (config.url.indexOf(PROPS.oDataEventReportsUrl) >= 0 &&
            config.url.indexOf("$skip") < 0) ||
          (config.url.indexOf(PROPS.oDataEventHistoriesUrl) >= 0 &&
            config.url.indexOf("$skip") < 0) ||
          config.url.indexOf(PROPS.companyStoreApiUrl) >= 0 ||
          config.url.indexOf(PROPS.paymentServiceApiUrl) >= 0 ||
          config.url.indexOf(PROPS.imageApiUrl) >= 0 ||
          config.url.indexOf(PROPS.dealerApiUrl) >= 0 ||
          config.url.indexOf(PROPS.integrationUrl) >= 0 ||
          config.url.indexOf(PROPS.thirdPartyVideo) >= 0 ||
          config.url.indexOf(PROPS.billingUrl) >= 0 ||
          config.url.indexOf(PROPS.paymentsUrl) >= 0 ||
          config.url.indexOf(PROPS.dealerInvUrl) >= 0 ||
          config.url.indexOf(PROPS.vidprox) >= 0
        ) {
          if (UserService.auth_token && UserService.auth_token.length === 20) {
            config.params.auth_token = UserService.auth_token;
          } else if (
            UserService.auth_token &&
            UserService.auth_token.length === 32 &&
            config.url.indexOf("videoPlayback") < 0 //local host video playback does not require auth
          ) {
            // if there is no refresh promise, or if the refresh promise is not pending, then we can use the auth token
            if (!$rootScope.refreshPromise) {
              config.params.auth_token = UserService.auth_token; //this is for video verification service that uses a 32 char token
            } else {
              //if there is a refresh promise, then we need to wait for it to complete before we can use the auth token if it errored out log the user out
              $rootScope.refreshPromise.then(
                function (res) {
                  if (res) {
                    config.params.auth_token = UserService.auth_token;
                  }
                },
                function (error) {
                  logout();
                  return $q.reject(error);
                }
              );
            }
          } else {
            const token = $rootScope.single_use_token
              ? $rootScope.single_use_token
              : UserService.auth_token;
            $rootScope.single_use_token = null;
            config.headers.Authorization = "Bearer " + token;
          }
        }

        return config || $q.when(config);
      },

      requestError: function (error) {
        return $q.reject(error);
      },

      responseError: (error) => {
        return $q.reject(error);
      },
    };
  },
]);

// Register the previously created AuthInterceptor.
App.config(function ($httpProvider) {
  $httpProvider.interceptors.push("AuthInterceptor");
});
