/**
 * @generated SignedSource<<9e2ddb85dc684ff501a7f2fec4ea27ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: string;
      readonly alarmReceiverAuthorization?: boolean | null;
      readonly appKey?: string | null;
      readonly armedAnswerRings?: number | null;
      readonly disarmedAnswerRings?: number | null;
      readonly id: string;
      readonly remoteDisarm?: boolean;
      readonly remoteKey?: string;
      readonly serviceReceiverAuthorization?: boolean | null;
    } | null;
  };
  readonly " $fragmentType": "TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem";
};
export type TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelRemoteOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "c203cb0e7ea0855cf5794799103ea4ac";

export default node;
