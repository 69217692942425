/**
 * @generated SignedSource<<8bf9ad31252f30c5c6815fbf65b05d4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFCommunicationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly communicationPaths: ReadonlyArray<{
      readonly __typename: "CommunicationPath";
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XFCommunicationProgrammingConceptFormInline_communicationPath">;
    }>;
    readonly id: string;
    readonly maxCommunicationPaths: number;
  };
  readonly " $fragmentType": "XFCommunicationProgrammingConceptFormInline_controlSystem";
};
export type XFCommunicationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFCommunicationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFCommunicationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFCommunicationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "0e04613fd108fcfc6b31b7a1d462510c";

export default node;
