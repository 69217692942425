/*One person, one piece*/

App.controller("StoreCtrl", [
  "$http",
  "$q",
  "$filter",
  "$rootScope",
  "$scope",
  "$state",
  "$modal",
  "PROPS",
  "CompanyStoreService",
  "DealerService",
  "UserService",
  "OnDemandService",
  "dealer_id",
  "stripe_success",
  "$location",
  "$window",
  "LogoService",
  "Receiver",
  "STATE_CODES",
  "MonitoringContract",
  "CustomItem",
  "VALIDATION_PATTERNS",
  function (
    $http,
    $q,
    $filter,
    $rootScope,
    $scope,
    $state,
    $modal,
    PROPS,
    CompanyStoreService,
    DealerService,
    UserService,
    OnDemandService,
    dealer_id,
    stripe_success,
    $location,
    $window,
    LogoService,
    Receiver,
    STATE_CODES,
    MonitoringContract,
    CustomItem,
    VALIDATION_PATTERNS
  ) {
    $scope.VALIDATION_PATTERNS = VALIDATION_PATTERNS;
    var MAX_MONITORING_OPTIONS = 3;
    if ($state.is("app.dealer.store_edit")) {
      $scope.state = "edit";
      $scope.tool = "costore";
    } else if ($state.is("app.dealer.store_view")) {
      $scope.state = "view";
      $scope.tool = "costore";
    } else if ($state.is("app.dealer.salesapp_edit")) {
      $scope.state = "edit";
      $scope.tool = "secura";
    } else if ($state.is("app.dealer.salesapp_view")) {
      $scope.state = "view";
      $scope.tool = "secura";
    }

    //EXTERNAL VARIABLES... ish?
    $scope.UserService = UserService;
    $scope.dealer = $scope.UserService.dealerInfo;
    $scope.dealer_id = $scope.UserService.dealer_id;
    var dealerId = $scope.dealer_id;
    $scope.isSupervisor = $scope.UserService.isSupervisorAccessible();
    $scope.STATE_CODES = STATE_CODES;
    $scope.monitoring_terms = [
      { term: null, label: "Unused" },
      { term: 1, label: "Monthly" },
      { term: 12, label: "1-year" },
      { term: 24, label: "2-year" },
      { term: 36, label: "3-year" },
      { term: 48, label: "4-year" },
      { term: 60, label: "5-year" },
    ];
    $scope.monitoring_options = [];
    $scope.inputDisabled =
      $scope.state != "edit" ||
      ($scope.tool == "costore" && !UserService.canEditDealerStore()) ||
      ($scope.tool == "secura" && !UserService.canEditSalesApp());

    //INSTANTIATE LOCAL VARIABLES
    var returnUri = $location.absUrl() + "?stripe_success=true";
    if (stripe_success) {
      $rootScope.alerts.push({
        type: "success",
        text: "Stripe Account Connected!",
      });
    }
    $scope.company_store_logo = undefined;
    $scope.costore_logo = undefined;
    $scope.secura_logo = undefined;
    $scope.uploadingWeb = false;
    $scope.uploadingApp = false;
    $scope.addingNewItem = false;
    $scope.addingNewCharge = false;
    $scope.newCustomItem = {};
    $scope.newCustomCharge = {};
    $scope.triedToSave = false;
    $scope.costoreVerified =
      $scope.UserService.canEditDealerStore() && $scope.tool == "costore";
    $scope.securaVerified =
      $scope.UserService.canEditSalesApp() && $scope.tool == "secura";
    $scope.checkTheBox = false;

    init();
    function checkThePkgSubcategoryItems() {
      $scope.packagesWithSubitems = []; //an array of all objects which contain a subcategory and subitems
      for (var j = 0; j < $scope.dealerItemList.length; j++) {
        if ($scope.dealerItemList[j].subcategory) {
          $scope.packagesWithSubitems.push($scope.dealerItemList[j]);
        } //populate the subitems array
      }
      $scope.subcategories = $filter("groupBy")(
        $scope.packagesWithSubitems,
        "subcategory"
      ); //an array of all subcategories, including the subitems within the subcategory USED TO BE theOne
      angular.forEach(
        $scope.subcategories,
        function (subcategoryOfItems, subcategoryIndex) {
          $scope.subcatUsed = $filter("some")(subcategoryOfItems, "available");
          $scope.subcategories[subcategoryIndex] = {
            checked: $scope.subcatUsed,
          };
        }
      );
    }

    function init() {
      $scope.fileName = $scope.tool == "secura" ? "secura" : "company_store";
      $scope.isBusy = true;
      var companyStoreDataPromise = CompanyStoreService.companyStoreGetAll(
        dealerId,
        $scope.tool
      );
      var onDemandPromise =
        $scope.tool == "costore"
          ? OnDemandService.getOnDemandTierData(UserService.dealer_id)
          : undefined;
      var onDemandCommunicationPromise =
        $scope.tool == "costore"
          ? OnDemandService.getCommuncationOptions(UserService.dealer_id)
          : undefined;
      $q.all([
        companyStoreDataPromise,
        onDemandPromise,
        onDemandCommunicationPromise,
      ])
        .then(
          function (data) {
            $scope.dealerStoreSettings = data[0].dealerData.dealer;
            $scope.namingConventions =
              data[0].dealerData.customer_name_conventions;
            $scope.themeOptions = data[0].dealerData.themes;
            $scope.stripeStatus = data[0].stripeData
              ? data[0].stripeData.dealer.stripe_connected
              : null;
            $scope.unableToGetStripeStatus = data[0].stripeData ? false : true;
            $scope.installCenterList =
              data[0].installCenterData.installation_centers;
            $scope.installCentersExist = $scope.installCenterList.length;
            $scope.dealerItemList = data[0].itemsData.items;
            $scope.customItemList =
              $scope.tool == "secura"
                ? data[0].customItemsData.custom_items
                : [];
            $scope.customBillingTypes =
              $scope.tool == "secura"
                ? data[0].customItemsData.billing_types
                : [];
            $scope.customTaxStatuses =
              $scope.tool == "secura"
                ? data[0].customItemsData.sales_tax_types
                : [];
            $scope.monitoringContractList = data[0].contractsData;
            // $scope.monitoringContractList = [];
            $scope.monitoring_terms_copy = angular.copy(
              $scope.monitoring_terms
            );
            $scope.monitoring_terms_copy.splice(0, 1);
            // ^^ we use the monitoring_terms array to populate the labels if we aren't editing
            //and remove the null value term object so that it doesn't appear in the select options

            /**
             * Okay, so the Monitoring Contract portion requires a LOT of comments:
             *
             * The on_demand contract only available for company store (NOT for salesapp)
             * so if we on costore, we find on_demand contract, get it index in monitoring contract list
             * and remove it. We can display [up to] three regular monitoring contracts correctly.
             * NOTE: We save the on_demand contract in our variable, onDemandContract.
             *
             * Then, whether we on costore or salesapp, we iterate through our three regular monitoring contracts.
             * The API place a constraint on us to where we can't save duplicate term,
             * so if a dealer want to switch around the order of his contracts
             * (though to him, it look perfectly fine and legal), the API prolly reject the request.
             * Our very bad UX fix go like this:
             *
             * If a contract already exist, we remove its matching object from monitoring_options.
             * If there is an empty slot (aka a dealer previously saved less than 3 monitoring contracts [cumulatively for all time]),
             * we create a new one to avoid any awkward value appearing on the store page.
             *
             * Then, for each contract, we create a array that includes the unused term objects in monitoring_options
             * AND the term that already assigned to that slot.
             *
             * Then, after we do all that jazz, if we on costore, we add back the on_demand contract
             * from the variable we save earlier, onDemandContract. The end (hopefully)
             *
             */

            if ($scope.tool == "costore") {
              var od_index = $scope.monitoringContractList
                .map(function (obj) {
                  //Finding the index of the onDemand Contract in the original array
                  return obj.on_demand;
                })
                .indexOf(true);
              var onDemandContract = $scope.monitoringContractList.splice(
                od_index,
                1
              )[0]; //Removing the onDemand Contract from the original array, and saving the onDemand Contract to a variable
            }

            for (var j = 0; j < MAX_MONITORING_OPTIONS; j++) {
              //Creating the list of available options and adding new contracts if applicable
              if ($scope.monitoringContractList[j]) {
                //Cannot read property "length_of_service" of undefined...
                var obj = $filter("filter")($scope.monitoring_terms_copy, {
                  term: $scope.monitoringContractList[j].length_of_service,
                })[0];
                var optIndex = $scope.monitoring_terms_copy.indexOf(obj);
                $scope.monitoring_terms_copy.splice(optIndex, 1);
              } else {
                var newContract = new MonitoringContract({}, dealerId);
                $scope.monitoringContractList.push(newContract);
              }
            }

            for (var i = 0; i < $scope.monitoringContractList.length; i++) {
              //Finalizing the array of monitoring_options that will be used in the HTML -
              var contractOptions = angular.copy($scope.monitoring_terms_copy); //make a copy of the array of available terms
              if ($scope.monitoringContractList[i].length_of_service != null) {
                var term_index = $scope.monitoring_terms
                  .map(function (obj) {
                    return (
                      obj.term ==
                      $scope.monitoringContractList[i].length_of_service
                    );
                  })
                  .indexOf(true);
                contractOptions.splice(
                  0,
                  0,
                  $scope.monitoring_terms[term_index]
                ); //add this term to the front of the array of available terms
                $scope.monitoring_options.push(contractOptions); //push this version of the array of available terms to the array of arrays of available terms for use on each select option (so it shows the available ones AND this one)
              } else {
                $scope.monitoring_options.push(
                  angular.copy($scope.monitoring_terms_copy)
                );
              }
            }

            if ($scope.tool == "costore")
              $scope.monitoringContractList.push(
                onDemandContract
                  ? onDemandContract
                  : new MonitoringContract(
                      { length_of_service: 0, on_demand: true },
                      dealerId
                    )
              ); //setting the onDemand contract to be the last one in the list

            $scope.odOptions =
              $scope.tool == "costore"
                ? $filter("filter")(data[1], { Enabled: true })
                : null;
            $scope.communicationsObject =
              $scope.tool == "costore" ? data[2] : null;
            if ($scope.odOptions && $scope.odOptions.length < 1) {
              $scope.selectedCharge = null;
              $scope.communicationsObject.CSDealerChargeId = null;
              // Get the monitoringContract for on_demand and set it to not available, since there are no On Demand options
              var onDemandContract =
                $scope.monitoringContractList[
                  $scope.monitoringContractList.length - 1
                ];
              onDemandContract.available = false;
            } else {
              $scope.thisCharge =
                $scope.tool == "costore"
                  ? $filter("filter")($scope.odOptions, {
                      Id: $scope.communicationsObject.CSDealerChargeId,
                    })
                  : null;
              $scope.selectedCharge =
                $scope.tool == "costore" ? $scope.thisCharge[0] : null;
            }
            checkThePkgSubcategoryItems();
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Could not get dealer data",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }
    /**
     * General Tab
     * @method refreshCenterList()
     * @description Refreshes the list of installation centers after an add or delete
     */
    $scope.refreshCenterList = function () {
      $scope.isBusy = true;
      CompanyStoreService.getAllInstallCenters(dealerId)
        .then(
          function (data) {
            $scope.installCenterList = data.installation_centers;
            $scope.installCentersExist = $scope.installCenterList.length;
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error: ",
              json: error,
            });
            $scope.installCentersExist = 0;
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    /**
     * General Tab
     * @param item
     * @description Deletes a specified installation center
     */
    $scope.deleteCenter = function (item) {
      $scope.isBusy = true;
      CompanyStoreService.deleteInstallCenter(UserService.dealer_id, item.id)
        .then(
          function () {
            $scope.refreshCenterList();
          },
          function (error) {
            $rootScope.alerts.push({
              type: error,
              text: "Error: ",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    /**
     * General Tab
     * @param item
     * @description Opens the modal for adding/editing a specified installation center
     */
    $scope.openInstallCenterModal = function (item) {
      $scope.currentItem = item;
      var installCenterModal = $modal.open({
        templateUrl: "installCenterModal.html",
        controller: "InstallCenterModalCtrl",
        scope: $scope,
      });
    };
    /**
     * Items Tab
     * @param thisSubcategory
     * @description On Salesapp, items within packages are selected as a group, instead of individually
     *              This function ensures the individual items within the package are available: true
     *              when the package is marked available
     */
    $scope.syncSubItemsCheck = function (thisSubcategory) {
      for (var k = 0; k < $scope.dealerItemList.length; k++) {
        if ($scope.dealerItemList[k].subcategory == thisSubcategory) {
          $scope.dealerItemList[k].available =
            $scope.subcategories[thisSubcategory].checked;
        }
      }
    };
    /**
     * Items Tab/Monitoring Tab
     * @param billing_type
     * @param newItem
     *
     * @description Adds a new custom item; billing type determines whether is a part or a charge
     */
    $scope.addNewItem = function (billing_type, newItem) {
      var newCustomItem = new CustomItem(newItem, dealerId);
      newCustomItem.billing_type = billing_type;
      newCustomItem.sales_tax_type =
        newCustomItem.billing_type == "recurring"
          ? "non_taxable"
          : newCustomItem.sales_tax_type;

      //Validation before sending new item to be added
      if (billing_type == "once") {
        if (
          $scope.costoreSettingsForm.itemsTabForm.newCustItem_title.$invalid
        ) {
          $scope.costoreSettingsForm.itemsTabForm.newCustItem_title.$setTouched();
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to add new item - Invalid form entries",
          });
        } else if (
          $scope.costoreSettingsForm.itemsTabForm.newCustItem_salesTax.$invalid
        ) {
          $scope.costoreSettingsForm.itemsTabForm.newCustItem_salesTax.$setTouched();
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to add new item - Invalid form entries",
          });
        } else if (
          $scope.costoreSettingsForm.itemsTabForm.newCustItem_price.$invalid
        ) {
          $scope.costoreSettingsForm.itemsTabForm.newCustItem_price.$setTouched();
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to add new item - Invalid form entries",
          });
        } else {
          $scope.isBusy = true;
          CompanyStoreService.newSalesappCustomItem(dealerId, newCustomItem)
            .then(
              function (data) {
                $scope.refreshCustomItemList();
                $rootScope.alerts.push({
                  type: "success",
                  text: "Custom Item Added!",
                });
                billing_type == "once"
                  ? ($scope.addingNewItem = false)
                  : ($scope.addingNewCharge = false);
                billing_type == "once"
                  ? ($scope.newCustomItem = {})
                  : ($scope.newCustomCharge = {});
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Unable to add custom item ",
                  json: error,
                });
                $scope.isBusy = false;
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
      } else if (billing_type == "recurring") {
        if (
          $scope.costoreSettingsForm.monitoringTabForm.newCustCharge_title
            .$invalid
        ) {
          $scope.costoreSettingsForm.monitoringTabForm.newCustCharge_title.$setTouched();
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to add new item - Invalid form entries",
          });
        } else if (
          $scope.costoreSettingsForm.monitoringTabForm.newCustCharge_price
            .$invalid
        ) {
          $scope.costoreSettingsForm.monitoringTabForm.newCustCharge_price.$setTouched();
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to add new item - Invalid form entries",
          });
        } else {
          $scope.isBusy = true;
          CompanyStoreService.newSalesappCustomItem(dealerId, newCustomItem)
            .then(
              function (data) {
                $scope.refreshCustomItemList();
                $rootScope.alerts.push({
                  type: "success",
                  text: "Custom Item Added!",
                });
                billing_type == "once"
                  ? ($scope.addingNewItem = false)
                  : ($scope.addingNewCharge = false);
                billing_type == "once"
                  ? ($scope.newCustomItem = {})
                  : ($scope.newCustomCharge = {});
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Unable to add custom item ",
                  json: error,
                });
                $scope.isBusy = false;
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
      }
    };
    /**
     * Items Tab/Monitoring Tab
     * @description Refreshes the list of custom items
     */
    $scope.refreshCustomItemList = function () {
      $scope.isBusy = true;
      CompanyStoreService.getSalesappCustomItems(dealerId)
        .then(
          function (data) {
            $scope.customItemList = data.custom_items;
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: error,
              text: "Error: ",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    /**
     * Items Tab/Monitoring Tab
     * @param billing_type
     */
    $scope.cancelNewCustomItem = function (billing_type) {
      if (billing_type == "once") {
        $scope.costoreSettingsForm.itemsTabForm.newCustItem_title.$setUntouched();
        $scope.costoreSettingsForm.itemsTabForm.newCustItem_salesTax.$setUntouched();
        $scope.costoreSettingsForm.itemsTabForm.newCustItem_price.$setUntouched();
        $scope.newCustomItem = {};
        $scope.addingNewItem = false;
      } else if (billing_type == "recurring") {
        $scope.costoreSettingsForm.monitoringTabForm.newCustCharge_title.$setUntouched();
        $scope.costoreSettingsForm.monitoringTabForm.newCustCharge_price.$setUntouched();
        $scope.newCustomCharge = {};
        $scope.addingNewCharge = false;
      }
    };
    /**
     * Items Tab/Monitoring Tab
     * @param customItem_id
     * @description Deletes a specified custom item
     */
    $scope.deleteCustomItem = function (customItem_id) {
      $scope.isBusy = true;
      CompanyStoreService.removeSalesappCustomItem(dealerId, customItem_id)
        .then(
          function (data) {
            $rootScope.alerts.push({
              type: "success",
              text: "Custom Item Deleted",
            });
            $scope.refreshCustomItemList();
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    /**
     * Items Tab/Monitoring Tab
     * @param billing_type
     * @description Changes the boolean which hides the "Add New" fields;
     *              billing_type param determines which tab to alter
     */
    $scope.showAddNew = function (billing_type) {
      if (billing_type == "once") {
        $scope.addingNewItem = true;
        $scope.newCustomItem.available = true;
      } else {
        $scope.addingNewCharge = true;
        $scope.newCustomCharge.available = true;
      }
    };
    /**
     * Monitoring Tab
     * @param new_state
     * @description Switches the type of hardware discount between 'dollars' and 'percent'
     */
    $scope.toggleDiscountDisplay = function (new_state) {
      $scope.dealerStoreSettings.discount_type = new_state;
    };
    /**
     * Monitoring Tab
     * @param currentValue
     * @returns {function} which evaluates whether this term option has already been used
     */
    $scope.omitSelectedContracts = function (currentValue) {
      return function (thisOption) {
        if (!$scope.monitoringContractList) return true;
        return (
          $filter("filter")(
            $scope.monitoringContractList,
            { length_of_service: thisOption.term },
            true
          ).length < 1 || currentValue.length_of_service == thisOption.term
        );
      };
    };
    /**
     * Monitoring Tab
     * function to modify the communication option if the on-demand monitoring contract is available or not. This is to stop stale data from being shown.
     */
    $scope.modifyCSDealerChargeID = function () {
      $scope.communicationsObject.CSDealerChargeId = $scope
        .monitoringContractList[$scope.monitoringContractList.length - 1]
        .available
        ? $scope.communicationsObject.CSDealerChargeId
        : undefined;
    };
    /**
     * Monitoring Tab
     * Function to update the selected charge tag on select change
     */
    $scope.updateSelectedCharge = function () {
      $scope.selectedCharge = $filter("filter")($scope.odOptions, {
        Id: $scope.communicationsObject.CSDealerChargeId,
      })[0];
    };
    /**
     * PreValidateContracts()
     *
     */
    $scope.preValidateContracts = function () {
      for (var k = 0; k < $scope.monitoringContractList.length; k++) {
        var lengthOfServiceFieldName = "length_of_service_" + k;
        if (!$scope.monitoringContractList[k].available) {
          initializeContractFields("base_price", k);
          initializeContractFields("additional_automation", k);
          initializeContractFields("additional_cameras", k);
          initializeContractFields("additional_cellular", k);
        }

        if (
          $scope.costoreSettingsForm.monitoringTabForm[lengthOfServiceFieldName]
        ) {
          // If the row isn't available, clear any validation errors
          $scope.costoreSettingsForm.monitoringTabForm[
            lengthOfServiceFieldName
          ].$touched = false;
          $scope.costoreSettingsForm.monitoringTabForm[
            lengthOfServiceFieldName
          ].$untouched = true;
        }
      }

      function initializeContractFields(fieldName, index) {
        if (!$scope.monitoringContractList[index][fieldName]) {
          var fullFieldName = fieldName + "_" + index;
          $scope.costoreSettingsForm.monitoringTabForm[fullFieldName].$error =
            {};
          $scope.monitoringContractList[index][fullFieldName] = 0;
        }
      }
    };
    /**
     * Receiver Tab
     * @param receiver
     * @description Reset the receiver by calling the reset function in the Receiver model
     */
    $scope.resetReceiver = function (receiver) {
      receiver.reset();
    };
    /**
     * Page
     * @description Flag any errors or invalid fields before saving
     */
    $scope.validateSettings = function () {
      angular.forEach(
        $scope.costoreSettingsForm.settingsTabForm.$error,
        function (errorType) {
          angular.forEach(errorType, function (field) {
            field.$setTouched();
          });
        }
      );
      angular.forEach(
        $scope.costoreSettingsForm.itemsTabForm.$error,
        function (errorType) {
          angular.forEach(errorType, function (field) {
            field.$setTouched();
          });
        }
      );
      angular.forEach(
        $scope.monitoringContractList,
        function (contract, index) {
          var fieldName;
          if (!$scope.monitoringContractList[index].available) {
            fieldName = "length_of_service_" + index;
            if (
              $scope.costoreSettingsForm.monitoringTabForm.$error[fieldName] !=
              undefined
            );
          }
        }
      );
      angular.forEach(
        $scope.costoreSettingsForm.monitoringTabForm.$error,
        function (errorType) {
          angular.forEach(errorType, function (field) {
            field.$setTouched();
          });
        }
      );
      angular.forEach(
        $scope.costoreSettingsForm.receiversTabForm.$error,
        function (errorType) {
          angular.forEach(errorType, function (field) {
            field.$setTouched();
          });
        }
      );

      if (
        $scope.costoreSettingsForm.$invalid ||
        $scope.installCenterList.length < 1
      ) {
        $scope.triedToSave = true;
        $rootScope.alerts.push({ type: "error", text: "Invalid form entries" });
      }
    };
    /**
     * Page
     * @param originButton
     * @description Saves all settings on page
     */
    $scope.saveAllStoreSettings = function (originButton) {
      var saveFunctions = [];
      saveFunctions.push($scope.dealer.updateDealer()); //Updating the receiver tab
      saveFunctions.push(
        CompanyStoreService.modifyDealerStoreSettings(
          dealer_id,
          $scope.dealerStoreSettings
        )
      );
      if ($scope.tool == "costore") {
        saveFunctions.push(
          CompanyStoreService.modifyItems(dealer_id, $scope.dealerItemList)
        );
        if ($scope.monitoringContractList[3]) {
          $scope.monitoringContractList[3].length_of_service = 0;
        } //Can this be done in the model?
        for (let i = 0; i < $scope.monitoringContractList.length; i++) {
          if ($scope.monitoringContractList[i].id) {
            saveFunctions.push(
              CompanyStoreService.modifyContract(
                dealer_id,
                $scope.monitoringContractList[i]
              )
            );
          } //existing contract
          else if ($scope.monitoringContractList[i].available) {
            saveFunctions.push(
              CompanyStoreService.createContract(
                dealer_id,
                $scope.monitoringContractList[i]
              )
            );
          } //new contract
        }
        saveFunctions.push(
          OnDemandService.updateCommunicationOptions(
            $scope.communicationsObject
          )
        );
      } else if ($scope.tool == "secura") {
        saveFunctions.push(
          CompanyStoreService.modifySalesAppItems(
            dealer_id,
            $scope.dealerItemList
          )
        );
        saveFunctions.push(
          CompanyStoreService.updateAllSalesappCustomItems(
            UserService.dealer_id,
            $scope.customItemList
          )
        );
        for (var i = 0; i < $scope.monitoringContractList.length; i++) {
          if ($scope.monitoringContractList[i].id) {
            saveFunctions.push(
              CompanyStoreService.modifySalesAppContract(
                dealer_id,
                $scope.monitoringContractList[i]
              )
            );
          } //existing contract
          else if ($scope.monitoringContractList[i].available) {
            saveFunctions.push(
              CompanyStoreService.createSalesAppContract(
                dealer_id,
                $scope.monitoringContractList[i]
              )
            );
          } //new contract
        }
      }
      //after they all finish completing
      $q.all(saveFunctions)
        .then(
          function (data) {
            if (originButton == "stripe") {
              $window.location.href =
                CompanyStoreService.getStripeURI(returnUri);
            } else if (originButton == "save") {
              if ($scope.tool == "costore") {
                $state.go(
                  "app.dealer.store_view",
                  { dealer_id: dealer_id },
                  { reload: true }
                );
              } //LOSE THE DUMMY
              else if ($scope.tool == "secura") {
                $state.go(
                  "app.dealer.salesapp_view",
                  { dealer_id: dealer_id },
                  { reload: true }
                );
              }
            }
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    /**
     * Page
     * @description Cancels editing and returns to view state
     */
    $scope.cancel = function () {
      $scope.tool == "costore"
        ? $state.go(
            "app.dealer.store_view",
            { dealer_id: dealerId },
            { reload: true }
          ) //LOSE THE DUMMY
        : $state.go(
            "app.dealer.salesapp_view",
            { dealer_id: dealerId },
            { reload: true }
          );
    };
    /**
     * Page
     * @param formInvalid
     * @param installCenterListLength
     * @description Validates the form and saves the settings
     */
    $scope.clickTheSaveButton = function (
      formInvalid,
      installCenterListLength
    ) {
      formInvalid == false && installCenterListLength > 0
        ? $scope.saveAllStoreSettings("save")
        : $scope.validateSettings();
    };
  },
]);

App.controller("InstallCenterModalCtrl", [
  "$scope",
  "$rootScope",
  "$modal",
  "$modalInstance",
  "$state",
  "UserService",
  "focus",
  "$timeout",
  "InstallCenter",
  "STATE_CODES",
  "CompanyStoreService",
  function (
    $scope,
    $rootScope,
    $modal,
    $modalInstance,
    $state,
    UserService,
    focus,
    $timeout,
    InstallCenter,
    STATE_CODES,
    CompanyStoreService
  ) {
    $scope.currentItem == null
      ? ($scope.addressIsNew = true)
      : ($scope.addressIsNew = false);
    $scope.currentItem == null
      ? ($scope.newCenter = new InstallCenter({}, UserService.dealer_id))
      : ($scope.newCenter = angular.copy($scope.currentItem));
    $scope.STATE_CODES = STATE_CODES;
    $scope.cancel = function () {
      $scope.newCenter = {};
      $modalInstance.dismiss("cancel");
    };
    $scope.saveAddress = function () {
      if ($scope.addressIsNew) {
        $scope.theNewCenter = {};
        $scope.theNewCenter.installation_center = $scope.newCenter;
        CompanyStoreService.createInstallCenter(
          UserService.dealer_id,
          $scope.theNewCenter
        )
          .then(
            function () {
              $scope.refreshCenterList();
              $modalInstance.close();
            },
            function (error) {
              $rootScope.alerts.push({ type: "error", text: error });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        CompanyStoreService.modifyInstallCenter(
          UserService.dealer_id,
          $scope.newCenter.id,
          $scope.newCenter
        )
          .then(
            function () {
              $scope.refreshCenterList();
              $modalInstance.close();
            },
            function (error) {
              $rootScope.alerts.push({ type: "error", text: error });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };
  },
]);
