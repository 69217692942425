/**
 * @generated SignedSource<<01290dc7a57ee5f488d82dedd5a01da8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatSiteCode2Field_cardFormat$data = {
  readonly number: string;
  readonly requireSiteCode: boolean;
  readonly siteCodes: ReadonlyArray<number | null>;
  readonly " $fragmentType": "CardFormatSiteCode2Field_cardFormat";
};
export type CardFormatSiteCode2Field_cardFormat$key = {
  readonly " $data"?: CardFormatSiteCode2Field_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatSiteCode2Field_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatSiteCode2Field_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requireSiteCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "0d1917562c3dba98fe394905dd4f1391";

export default node;
