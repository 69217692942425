import { RelayServiceType } from "app/common/services/relay-service";
import * as React from "react";
import { RelayEnvironmentProvider } from "react-relay";

function RelayEnvironmentProviderDA(props: {
  RelayService: RelayServiceType;
  children: React.ReactNode;
}) {
  const { RelayService, children } = props;
  return (
    <RelayEnvironmentProvider
      environment={React.useMemo(
        () => RelayService.getEnvironment(),
        [RelayService]
      )}
    >
      {children}
    </RelayEnvironmentProvider>
  );
}

export default RelayEnvironmentProviderDA;
