/**
 * @generated SignedSource<<d87c79d90302c49d9a6a818c5f8cd931>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationSerialNumberField_zone$data = {
  readonly competitorWireless: boolean | null;
  readonly contactNumber: ZoneTransmitterContact | null;
  readonly id: string;
  readonly number: string;
  readonly serialNumber: string;
  readonly wireless: boolean;
  readonly wirelessDisarmDisableEnabled: boolean;
  readonly " $fragmentType": "ZoneInformationSerialNumberField_zone";
};
export type ZoneInformationSerialNumberField_zone$key = {
  readonly " $data"?: ZoneInformationSerialNumberField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationSerialNumberField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationSerialNumberField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competitorWireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wirelessDisarmDisableEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactNumber",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "fd04b305c3164ecc8781db1ea5f54355";

export default node;
