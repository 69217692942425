import * as React from "react";

export default function useKeypress(key: string, action: () => void) {
  React.useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === key) action();
    }

    window.addEventListener("keydown", onKeyup);

    return () => window.removeEventListener("keydown", onKeyup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
