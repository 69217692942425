/**
 * @generated SignedSource<<b3bbea0f25461e751aae8f26533d2d63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DownloadableFileType = "REMOTE_LINK";
export type TabsetQuery$variables = {
  downloadableFileTypes: ReadonlyArray<DownloadableFileType>;
  id: string;
};
export type TabsetQuery$data = {
  readonly dealer: {
    readonly downloadableFiles: {
      readonly nodes: ReadonlyArray<{
        readonly createdAt: string;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"EditFileModalDownloadableFile_downloadableFile" | "TabsetDownloadableFile_downloadableFile">;
      }>;
    };
    readonly id: string;
  } | null;
};
export type TabsetQuery = {
  response: TabsetQuery$data;
  variables: TabsetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "downloadableFileTypes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "downloadableFileTypes",
    "variableName": "downloadableFileTypes"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": {
      "keys": [
        "createdAt"
      ],
      "order": "DESC"
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabsetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "DownloadableFileConnection",
            "kind": "LinkedField",
            "name": "downloadableFiles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DownloadableFile",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditFileModalDownloadableFile_downloadableFile"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TabsetDownloadableFile_downloadableFile"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TabsetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "DownloadableFileConnection",
            "kind": "LinkedField",
            "name": "downloadableFiles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DownloadableFile",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPublic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "infoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "downloadUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "091c75d01a8e84bd6ce4e4fa436a30aa",
    "id": null,
    "metadata": {},
    "name": "TabsetQuery",
    "operationKind": "query",
    "text": "query TabsetQuery(\n  $id: ID!\n  $downloadableFileTypes: [DownloadableFileType!]!\n) {\n  dealer(id: $id) {\n    id\n    downloadableFiles(sort: {keys: [\"createdAt\"], order: DESC}, downloadableFileTypes: $downloadableFileTypes) {\n      nodes {\n        id\n        createdAt\n        ...EditFileModalDownloadableFile_downloadableFile\n        ...TabsetDownloadableFile_downloadableFile\n      }\n    }\n  }\n}\n\nfragment EditFileModalDownloadableFile_downloadableFile on DownloadableFile {\n  id\n  name\n  isPublic\n  infoUrl\n  createdAt\n  fileName\n}\n\nfragment TabsetDownloadableFile_downloadableFile on DownloadableFile {\n  id\n  name\n  downloadUrl\n  isPublic\n  infoUrl\n  createdAt\n  ...EditFileModalDownloadableFile_downloadableFile\n}\n"
  }
};
})();

(node as any).hash = "607a351d8ab4921ad46dfaa057c8cf92";

export default node;
