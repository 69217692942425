import DaColors from "app/common/DaColors";
import Button from "components/Button";
import Tooltip, { MessagePosition } from "components/Tooltip";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";

const AnchorWrapper = styled.div`
  cursor: pointer;
`;
const TooltipContainer = styled.div`
  min-width: 20rem;
  font-size: 1.4rem;
`;
const ConfirmHeader = styled.h3`
  background-color: ${DaColors.Neutral30};
  font-size: 1.4rem;
  margin: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.8rem 1.4rem;
`;
const PopoverContent = styled.div`
  padding: 0.8rem 1.4rem;
`;
const PopoverMessage = styled.div`
  margin-bottom: 1rem;
`;
const PopoverButtons = styled.div`
  display: flex;
  gap: 0.8rem;
`;

type Props = {
  onConfirm: () => void;
  message: string;
  clickReceiver: (onClick: () => void) => ReactNode;
};

const CleanConfirmingDelete = ({
  onConfirm,
  message,
  clickReceiver,
}: Props) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <Tooltip
      anchor={({ registerAnchor }) => {
        const onClick = () => {
          if (!pickerOpen) {
            setPickerOpen(true);
          }
        };

        return (
          <AnchorWrapper ref={registerAnchor as any}>
            {clickReceiver(onClick)}
          </AnchorWrapper>
        );
      }}
      position={MessagePosition.Left}
      open={pickerOpen}
      onDismiss={() => setPickerOpen(false)}
      zIndex={5000}
      children={
        <TooltipContainer>
          <ConfirmHeader>Confirm</ConfirmHeader>
          <PopoverContent>
            <PopoverMessage>{message}</PopoverMessage>
            <PopoverButtons>
              <Button
                size="xs"
                design="alert"
                onClick={() => {
                  onConfirm();
                  setPickerOpen(false);
                }}
              >
                OK
              </Button>
              <Button
                size="xs"
                design="primary"
                onClick={() => {
                  setPickerOpen(false);
                }}
              >
                Cancel
              </Button>
            </PopoverButtons>
          </PopoverContent>
        </TooltipContainer>
      }
    ></Tooltip>
  );
};

export default CleanConfirmingDelete;
