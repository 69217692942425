/**
 * @generated SignedSource<<f8e08575be7da8b4993512ad05f9fc27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTLockoutCodeTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "01d2700121b770609b8d2ed2b1666d21";

export default node;
