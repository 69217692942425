App.service("AuthorizeTechSupportService", [
  "DmpTempUserAuthAPI",
  "$q",

  "TEMP_DEALER_APP_USER",
  function (DmpTempUserAuthAPI, $q) {
    // function: authorizeTechSupport
    // @desc: Authorize tech support to login to VK as the customer
    // @param: controlSystemId
    // @returns example:
    //  {
    //    "Id": 14,
    //    "ControlSystemId": 71954,
    //    "ExpiresAt": "2023-08-29T20:52:53.0638191Z",
    //    "GrantorName": "Ken Weigand",
    //    "GrantorEmail": "kmweigand1970@gmail.com",
    //    "CreatedAt": "2023-08-29T20:22:53.0638191Z",
    //    "UpdatedAt": "2023-08-29T20:22:53.0638191Z"
    //    }

    this.authorizeTechSupport = function (controlSystemId) {
      var deferred = $q.defer();
      DmpTempUserAuthAPI.create(
        { id: controlSystemId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "TempAppUserService->authorizeTechSupport() error: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    // funtion: extendTechSupportAuthorization
    // @desc: Extends time 1hr for tech support to login to VK as the customer
    // @param: controlSystemId
    // @returns example:
    //  {
    //    "Id": 14,
    //    "ControlSystemId": 71954,
    //    "ExpiresAt": "2023-08-29T21:52:53.0638191Z",
    //    "GrantorName": "Ken Weigand",
    //    "GrantorEmail": "kmweigand1970@gmail.com",
    //    "CreatedAt": "2023-08-29T20:22:53.0638191Z",
    //    "UpdatedAt": "2023-08-29T20:22:53.0638191Z"
    //    }
    this.extendTechSupportAuthorization = function (controlSystemId) {
      var deferred = $q.defer();
      DmpTempUserAuthAPI.extend(
        { id: controlSystemId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "TempAppUserService->extendTechSupportAuthorization() error: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
    this.revokeTechSupportAuthorization = function (controlSystemId) {
      var deferred = $q.defer();
      DmpTempUserAuthAPI.delete(
        { id: controlSystemId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "TempAppUserService->revokeTechSupportAuthorization() error: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    //get the granted permission for tech support
    // @param: controlSystemId
    // @returns example:
    //  {
    //  "Id": 19,
    //  "ControlSystemId": 71954,
    //  "ExpiresAt": "2023-08-30T19:24:44",
    //  "GrantorName": "Ken Weigand",
    //  "GrantorEmail": "kmweigand1970@gmail.com",
    //  "CreatedAt": "2023-08-30T18:54:44",
    //  "UpdatedAt": "2023-08-30T18:54:44"
    // }
    this.getTechSupportAuthorization = function (controlSystemId) {
      var deferred = $q.defer();
      DmpTempUserAuthAPI.get(
        { id: controlSystemId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "TempAppUserService->getTechSupportAuthorization() error: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  },
]);
