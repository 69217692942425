App.factory("FavoritesService", [
  "$q",
  "FavoritesAPI",
  "JobService",
  function ($q, FavoritesAPI, JobService) {
    return {
      favorites: [],

      /**
       * Get the devices, return them in a promise
       */
      getFavorites: function (panelId) {
        var deferred = $q.defer();
        var _this = this;
        FavoritesAPI.index(
          { panel_id: panelId },
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      refresh: function (panelId) {
        var deferred = $q.defer();
        var _this = this;
        FavoritesAPI.refresh(
          { panel_id: panelId },
          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  deferred.reject(jobError);
                },
                function (notify) {
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
