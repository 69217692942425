/**
 * @generated SignedSource<<7ac06a691ef34b88edee5f4fa7c14ef1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
import { FragmentRefs } from "relay-runtime";
export type TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data = {
  readonly systemOptions: {
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly displayTime: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly enableKeypadPanicKeys: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly hoursFromGMT: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly houseCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly powerFailDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly resetSwingerBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassTrips: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly timeChange: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly useFalseAlarmQuestion: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherZipCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessAudibles: {
      readonly data: WirelessAudibles | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts";
};
export type TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts"
};

(node as any).hash = "30ec8441622559cb787579fad0667994";

export default node;
