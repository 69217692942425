/**
 * @generated SignedSource<<3bc8980b36b2a77d31a3af0fd3df52c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE";
export type ControlSystemCommType = "CELL" | "NETWORK" | "PERSISTENT" | "PERSISTENT_WITH_CELL_BACKUP" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type SiteDiagnosticsCellStatusFragment_controlSystem$data = {
  readonly cellStatus: {
    readonly lastCheckIn: string | null;
    readonly status: ConnectionStatus;
  } | null;
  readonly commType: ControlSystemCommType;
  readonly hasCell: boolean;
  readonly id: string;
  readonly online: boolean;
  readonly " $fragmentType": "SiteDiagnosticsCellStatusFragment_controlSystem";
};
export type SiteDiagnosticsCellStatusFragment_controlSystem$key = {
  readonly " $data"?: SiteDiagnosticsCellStatusFragment_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsCellStatusFragment_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteDiagnosticsCellStatusFragment_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemCellStatus",
      "kind": "LinkedField",
      "name": "cellStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastCheckIn",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};

(node as any).hash = "e98fb06ca1522686f64b5627a763480e";

export default node;
