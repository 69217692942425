/*
 * SearchField Messages
 *
 * This contains all the text for the SearchField.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  removeSearchTerm: {
    id: "app.components.SearchField.removeSearchTerm",
    defaultMessage: "Remove search term"
  }
});
