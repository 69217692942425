App.controller("NotificationCtrl", InformationalCtrl);

InformationalCtrl.$inject = ["$rootScope", "$scope", "StyleDataAccessService"];

function InformationalCtrl($rootScope, $scope, StyleDataAccessService) {
  init();

  //alerts
  $scope.alerts = [
    { type: "danger", msg: "Something is very wrong." },
    { type: "success", msg: "Your action has succeeded." },
    { type: "warning", msg: "You have been warned." },
  ];

  //popovers
  $scope.dynamicPopover = {
    content: "Hello from a basic popover!",
    templateUrl: "myPopoverTemplate.html",
    title: "Title",
  };

  $scope.addAlert = function () {
    $scope.alerts.push({ type: "success", msg: "Another alert!" });
  };

  $scope.closeAlert = function (index) {
    $scope.alerts.splice(index, 1);
  };

  //tooltips
  $scope.tooltipText =
    "Here is a detailed explanation for this label. It can be rather long. It will stay above the element that it is called on.";

  //progressbar
  $scope.random = function () {
    var value = Math.floor(Math.random() * 100 + 1);

    $scope.progress = value;
  };

  $scope.random();

  //Toaster
  $scope.popAToaster = function (title, mssg, type) {
    $rootScope.alerts.push({
      title: title,
      type: type,
      text: mssg,
    });
  };

  function init() {
    StyleDataAccessService.getStyleData("notification-data.json")
      .then(
        function (notificationData) {
          $scope.NotificationData = notificationData;
        },
        function (notificationError) {
          $rootScope.alerts.push({ type: "error", message: notificationError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });
  }
}
