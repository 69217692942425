import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZonePirPulseCount, ZoneSensorType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { wirelessPirPulseCountEnabledSerialRange } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationPIRPulseCountField_zone$key } from "./__generated__/ZoneInformationPIRPulseCountField_zone.graphql";

export const zoneInformationPIRPulseCountFieldId = (number: string) =>
  `zone-information-pir-pulse-count-${number}`;

function ZoneInformationPIRPulseCountField() {
  const [
    { number, wireless, wirelessPirPulseCount, sensorType, serialNumber },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationPIRPulseCountField_zone$key>(
    graphql`
      fragment ZoneInformationPIRPulseCountField_zone on Zone {
        number
        wireless
        wirelessPirPulseCount
        sensorType
        serialNumber
      }
    `
  );
  const fieldId = zoneInformationPIRPulseCountFieldId(String(number));
  const serialNumberSetValid =
    Number(serialNumber) >= wirelessPirPulseCountEnabledSerialRange.min &&
    Number(serialNumber) <= wirelessPirPulseCountEnabledSerialRange.max;
  const disabled = !(
    wireless &&
    ZoneSensorType.WIRELESS_PIR === sensorType &&
    serialNumberSetValid
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="PIR Pulse Count"
      tooltip="Select the number of infrared pulses the PIR will count within 25 seconds before sending a short message."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={wirelessPirPulseCount ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "wirelessPirPulseCount");
          });
        }}
      >
        <Select.Option value={ZonePirPulseCount.TWO}>Two</Select.Option>
        <Select.Option value={ZonePirPulseCount.FOUR}>Four</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationPIRPulseCountField;
