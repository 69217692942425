import { Flex } from "components/DaStyledElements";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useEffect, useState } from "react";
import FileUploader from "./FileUploader";
import {
  DealerExampleLogo,
  DealerLogoArea,
  FormLogoUploadArea,
  SevenInchKeyPadLogoPreviewArea,
  TitlePreviewArea,
} from "./SevenInchKeypadStyles";
import { SevenInchLogoContext } from "./SevenInchLogoContext";

type SevenInchLogoEditProps = {
  dealerId: string;
  stateIsEdit: boolean;
  authToken: string;
  imageUrl: string;
};

const SevenInchLogoInfoEdit = ({
  dealerId,
  stateIsEdit,
  authToken,
  imageUrl,
}: SevenInchLogoEditProps) => {
  const showAlert = useShowAlert();

  const { imageSource, setImageSource } =
    React.useContext(SevenInchLogoContext);

  const handleImageSourceChange = (source: string) => {
    setImageSource(source);
  };

  //On initial page load get an existing logoUrl if it exists
  // else set the logoUrl to the default logo
  const getSavedLogoUrl = async () => {
    let imageLink = "";
    try {
      const response = await fetch(
        `${imageUrl}/api/v1/KeypadLogo/${dealerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        //suceess returns a string with a tilde at the beginning
        // so it needs to be removed before appending to the image url
        const linkSuffixToAppend = data.replace("~", "");
        // append the link suffix to the image url to get the image link
        // add a refresh query param to the end of the image link
        imageLink = `${imageUrl}/${linkSuffixToAppend}?refresh=${Date.now()}`;
      } else {
        // if there is no logo, set the image link to the default logo
        imageLink = "/assets/img/7in-keypad/your_logo_here.png";
      }
    } catch (error) {
      console.error(error);
      // if there is an error, set the image link to the default logo
      imageLink = "/assets/img/7in-keypad/your_logo_here.png";
    }
    return imageLink;
  };

  // state to disable the upload button
  const [isSavingLogo, setIsSavingLogo] = useState(false);

  // function to take a url and check if it is a valid image
  // if it is a valid image, set the logoUrl to the saved logo
  // else set the logoUrl to the default logo
  // return the correct image link as imageSource
  const checkImageLinkForValidity = (url: string) => {
    const image = new Image();
    image.onload = () => {
      handleImageSourceChange(url);
    };
    image.onerror = () => {
      handleImageSourceChange("/assets/img/7in-keypad/your_logo_here.png");
    };
    image.src = url;
    return url;
  };

  const fetchData = async () => {
    // on page load, run getSavedLogoUrl to set the savedLogoUrl
    const data = await getSavedLogoUrl();
    // check if the savedLogoUrl is a valid image
    const imgLink = await checkImageLinkForValidity(data);
    // set the logoUrl to the saved logo context to update the preview in the two sibling components
    handleImageSourceChange(imgLink);
  };
  useEffect(() => {
    // Call the fetchData function
    fetchData();
    // empty dependency array means this effect will only run on initial page load
  }, []);

  const handleLogoSave = (file: File) => {
    // create a new image
    const image = new Image();
    // check the image dimensions
    image.onload = () => {
      const { width, height } = image;
      // if the image is not the correct dimensions, show an error
      // and do not upload the image
      if (width !== 270 || height !== 110) {
        showAlert({
          type: "error",
          text: `Logo must be 270px wide by 110px tall`,
        });
      } else {
        // if the image is the correct dimensions
        // check that the file type is png
        // before uploading the file
        if (file && file.type === "image/png") {
          const formData = new FormData();
          formData.append("file", file);
          // set isSavingLogo to true to show the loading spinner
          //and disable the upload button
          setIsSavingLogo(true);
          fetch(
            `${imageUrl}/api/v1/KeypadLogo/${dealerId}`, // ${rootUrl}
            {
              method: "POST",
              body: formData,
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              //success returns a string with a tilde at the beginning
              // remove the tilde from the data
              const linkSuffixToAppend = data.replace("~", "");
              // append the link suffix to the image url to get the image link
              const img = document.createElement("img");
              img.onload = () => {
                let imgLink = `${imageUrl}/${linkSuffixToAppend}?refresh=${Date.now()}`;
                handleImageSourceChange(imgLink);
              };

              img.src = imageSource;
              showAlert({
                type: "success",
                text: `Logo Uploaded`,
              });

              setIsSavingLogo(false);
            })
            .catch((error) => {
              console.error(error);
              showAlert({
                type: "error",
                text: `Error uploading logo`,
              });
              setIsSavingLogo(false);
            });
        }
      }
    };
    image.src = URL.createObjectURL(file);
  };

  return (
    <>
      <TitlePreviewArea>
        <h4>Preview</h4>
      </TitlePreviewArea>
      <SevenInchKeyPadLogoPreviewArea>
        <img // this image is the background image for the preview area
          src="/assets/img/7in-keypad/main_screen.png"
          alt="logo"
          style={{ width: "100%", height: "auto" }}
        />
        <DealerLogoArea>
          <DealerExampleLogo
            src={imageSource} // this image is the logo
          />
        </DealerLogoArea>
      </SevenInchKeyPadLogoPreviewArea>
      <div>
        <FormLogoUploadArea>
          <Flex.Row justify="flex-start" align="center">
            <FileUploader
              dealerId={dealerId}
              requiredWidth={270}
              requiredHeight={110}
              isUploading={isSavingLogo}
              fileUploadFunction={handleLogoSave}
              isDisabled={!stateIsEdit || isSavingLogo}
            />
          </Flex.Row>
        </FormLogoUploadArea>
      </div>
    </>
  );
};

export default SevenInchLogoInfoEdit;
