import DaColors from "app/common/DaColors";
import DmpIcon from "common/components/DmpIcon";
import {
  EventSource,
  SecurityEvent,
} from "dealer-admin/src/apis/odata/security-events-parser";
import CleanDotsSpinner from "dealer-admin/src/components/DaStyledElements/CleanDotsSpinner";
import CleanLink from "dealer-admin/src/components/DaStyledElements/CleanLink";
import AuthContext from "dealer-admin/src/contexts/AuthContext";
import useFullscreen from "hooks/useFullscreen";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import AttentionWarning from "./AttentionWarning";
import ClipPlayer from "./ClipPlayer";
import { cameraPrefix } from "./utils/const";

const DownloadButtonHoverShadow = styled.div`
  &:hover {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }
`;
const Wrapper = styled.div`
  position: relative;
`;
const EventHeaderWrapper = styled.div`
  display: flex;
  height: 8rem;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  background-color: ${DaColors.White};
  padding: 1rem;
  ${Wrapper}:fullscreen & {
    position: absolute;
    top: 0px;
    z-index: 10;
    background-color: hsla(0, 0%, 80%, 0.6);
    backdrop-filter: blur(0.4rem);
  }
`;
const EventHeaderDetails = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media (min-width: 1536px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;
const EventHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: bold;
`;
const EventHeaderTime = styled.span`
  font-weight: bold;
`;
const EventHeaderButtons = styled.div`
  display: flex;
  gap: 0.8rem;
`;
const EventSourceBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  border-radius: 0.4rem;
  background-color: ${DaColors.Primary500};
  padding: 0.4rem 0.8rem;
  color: ${DaColors.White};
  font-weight: bold;
`;
const EventHeaderSpacer = styled.div`
  height: 8rem;
`;
const NoVideoMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  width: 100%;
  background-color: ${DaColors.White};
  color: ${DaColors.Neutral20};
`;
const VideoUnavailableTitle = styled.div`
  height: 8rem;
  padding: 1.6rem;
  font-weight: bold;
`;
const VideoUnavailableMessageBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  width: 100%;
  text-align: center;
  background-color: ${DaColors.Black};
  color: ${DaColors.White};
`;

const getAugmentedVideoUrl = (
  event: SecurityEvent | null,
  authToken: string
): string | null => {
  if (!event) {
    return null;
  }

  if (event.cameraType === "v-6000" && event.videoUrl) {
    return `${event.videoUrl}&uriParams.auth_token=${authToken}`;
  }

  return event.videoUrl;
};

type DownloadButtonProps = {
  event: SecurityEvent;
};

const DownloadButton = ({ event }: DownloadButtonProps) => {
  const { authToken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const augmentedVideoUrl = getAugmentedVideoUrl(event, authToken);

  const downloadVideoFile = async (urlString: string) => {
    setIsLoading(true);
    const response = await fetch(urlString);
    // In case we were redirected, find out where we ended up
    let finalUrl = new URL(urlString);
    if (response.url) {
      finalUrl = new URL(response.url);
    }
    // Get the last segment of the path to use as the filename
    let filename = finalUrl.pathname.split("/").at(-1) || "download";
    if (!filename.includes(".")) {
      filename = `${filename}.mp4`;
    }
    // Create a an objectURL from the response data
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    // Create a link to the objectURL
    const anchorElement = document.createElement("a");
    anchorElement.href = objectUrl;
    // Use the download attribute to force download and specify the filename
    anchorElement.download = filename;
    // Trigger the download
    anchorElement.click();
    setIsLoading(false);
    // Destroy the objectURL to free up resources
    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
  };

  return augmentedVideoUrl ? (
    <CleanLink onClick={() => downloadVideoFile(augmentedVideoUrl)}>
      {isLoading ? (
        <CleanDotsSpinner size={24} color={DaColors.Primary500} />
      ) : (
        <DownloadButtonHoverShadow>
          <DmpIcon icon="cloud_download" constrainToParent={false} size={24} />
        </DownloadButtonHoverShadow>
      )}
    </CleanLink>
  ) : null;
};

type Props = {
  event: SecurityEvent | null;
  isNetworkError?: boolean;
  thumbnails?: JSX.Element;
};

const EventViewer = ({ event, isNetworkError, thumbnails }: Props) => {
  const { authToken } = useContext(AuthContext);
  const [isFullscreen, wrapperRef, enterFullscreen, exitFullscreen] =
    useFullscreen();
  const augmentedVideoUrl = getAugmentedVideoUrl(event, authToken);

  if (event && !isNetworkError) {
    return augmentedVideoUrl ? (
      <Wrapper ref={wrapperRef}>
        <EventHeaderWrapper>
          <EventHeaderDetails>
            <EventHeaderTitle>
              {event.isAlarm && <AttentionWarning />}
              {event.eventTitle}
            </EventHeaderTitle>

            <div>
              <EventHeaderTime>Event Time: {event.displayTime}</EventHeaderTime>{" "}
              | {event.timeDistanceFromAlarm}
            </div>
            <div>
              {cameraPrefix}
              {event.cameraName}
            </div>
          </EventHeaderDetails>
          <EventHeaderButtons>
            <DownloadButton event={event} />
            <EventSourceBadge>
              <DmpIcon
                icon={
                  event.eventSource === EventSource.Events
                    ? "history"
                    : "clips_with_play"
                }
                constrainToParent={false}
                size={22}
              />
              {event.eventSource === EventSource.Events ? "Event" : "Clip"}
            </EventSourceBadge>
          </EventHeaderButtons>
        </EventHeaderWrapper>
        <ClipPlayer
          videoUrl={augmentedVideoUrl}
          autoPlay
          thumbnails={thumbnails}
          isFullscreen={isFullscreen}
          enterFullscreen={enterFullscreen}
          exitFullscreen={exitFullscreen}
          skipButtonsSupported={event.cameraType !== "v-6000"}
        />
      </Wrapper>
    ) : (
      <div>
        {/* This empty div is to simulate the space taken up by the event header */}
        <EventHeaderSpacer />
        <NoVideoMessage>
          <p>No video associated with this event</p>
          {/* And this empty div is to keep the no-video text centered */}
          <EventHeaderSpacer />
        </NoVideoMessage>
      </div>
    );
  } else if (isNetworkError) {
    return (
      <div>
        <VideoUnavailableTitle>Event Video Unavailable</VideoUnavailableTitle>
        <VideoUnavailableMessageBody>
          Event Video Unavailable. Please try again later.
        </VideoUnavailableMessageBody>
      </div>
    );
  } else {
    return (
      <div>
        <VideoUnavailableTitle>No Recent Events</VideoUnavailableTitle>
        <VideoUnavailableMessageBody>
          PLAYBACK UNAVAILABLE
        </VideoUnavailableMessageBody>
      </div>
    );
  }
};

export default EventViewer;
