/**
 * @generated SignedSource<<8b5121ea10e03f0b54c5e8e44de59ef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationContext_zone$data = {
  readonly id: string;
  readonly " $fragmentType": "ZoneInformationContext_zone";
};
export type ZoneInformationContext_zone$key = {
  readonly " $data"?: ZoneInformationContext_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationContext_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationContext_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "0098ab0089b850b8dcd1d81cf7d5b726";

export default node;
