import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaAutoDisarmField_area$key } from "./__generated__/AreaAutoDisarmField_area.graphql";

export const areaAutoDisarmFieldId = (number: string) =>
  `area-auto-disarm-field-${number}`;

function AreaAutoDisarmField() {
  const [{ number, autoDisarmEnabled }, updateArea] =
    useAreaFragment<AreaAutoDisarmField_area$key>(
      graphql`
        fragment AreaAutoDisarmField_area on Area {
          id
          number
          autoDisarmEnabled
        }
      `
    );

  const fieldId = areaAutoDisarmFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Automatic Disarming"
      tooltip="Enables automatic Disarming by Opening and Closing Schedule. Cannot be used for ANSI / SIA CP-01 installations."
    >
      <Switch
        label="Automatic Arming"
        id={fieldId}
        checked={autoDisarmEnabled}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!autoDisarmEnabled, "autoDisarmEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaAutoDisarmField;
