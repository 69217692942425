/**
 * @generated SignedSource<<5cf0f8bfade5bfde933897c36bc206ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type KeyfobButtonType = "ARMING" | "DISARMING" | "EMERGENCY" | "EMERGENCY_TWO_BUTTON" | "OUTPUT" | "PANIC" | "PANIC_TWO_BUTTON" | "SENSOR_RESET" | "STATUS" | "TOGGLE_ARM_DISARM" | "UNUSED";
export type KeyfobNumberOfButtons = "FOUR" | "ONE" | "TWO" | "ZERO";
export type KeyfobOutputAction = "MOMENTARY" | "OFF" | "PULSE" | "STEADY" | "TOGGLE";
export type KeyfobPressTime = "LONG" | "SHORT";
export type KeyfobSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly keyfobs: ReadonlyArray<{
    readonly buttonFourAction: {
      readonly data: KeyfobButtonType | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonFourAreas: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonFourOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonFourOutputAction: {
      readonly data: KeyfobOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonFourPressTime: {
      readonly data: KeyfobPressTime | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonOneAction: {
      readonly data: KeyfobButtonType | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonOneAreas: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonOneOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonOneOutputAction: {
      readonly data: KeyfobOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonOnePressTime: {
      readonly data: KeyfobPressTime | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonThreeAction: {
      readonly data: KeyfobButtonType | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonThreeAreas: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonThreeOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonThreeOutputAction: {
      readonly data: KeyfobOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonThreePressTime: {
      readonly data: KeyfobPressTime | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonTwoAction: {
      readonly data: KeyfobButtonType | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonTwoAreas: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonTwoOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonTwoOutputAction: {
      readonly data: KeyfobOutputAction | null;
      readonly included: boolean | null;
    } | null;
    readonly buttonTwoPressTime: {
      readonly data: KeyfobPressTime | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly keyfobSupervisionTime: {
      readonly data: KeyfobSupervisionTime | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly numberOfButtons: {
      readonly data: KeyfobNumberOfButtons | null;
      readonly included: boolean | null;
    } | null;
    readonly userNumber: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "7a894680ce3358ce7942733a34643926";

export default node;
