import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsTimeChangeField_systemOptions$key } from "./__generated__/SystemOptionsTimeChangeField_systemOptions.graphql";

export const systemOptionsTimeChangeFieldId = () =>
  "system-options-time-change";

function TimeChangeField() {
  const [{ timeChange }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsTimeChangeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsTimeChangeField_systemOptions on SystemOptions {
          timeChange
        }
      `
    );

  const fieldId = systemOptionsTimeChangeFieldId();
  const label = "Time Change";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="If enabled, requests time changes from DMP monitoring center receivers."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={timeChange}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!timeChange, "timeChange");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default TimeChangeField;
