/**
 *Directive that shows or closes NewsItems
 */
App.directive("daPushDownAlert", function () {
  var controller = [
    "$rootScope",
    "$scope",
    "$state",
    function ($rootScope, $scope, $state) {
      function init() {
        $scope.closeMe = function () {
          $rootScope.unreadNews = null;
          var child = angular.element(
            document.getElementsByClassName("push-down-alert")
          );
          child.slideUp("fast", function () {
            child.remove();
          });
        };

        $scope.seeMore = function () {
          $scope.closeMe();
          $state.go("app.newsitems");
        };
      }
      init();
    },
  ];
  return {
    restrict: "E",
    templateUrl: "app/common/templates/push-down-alert-tpl.html",
    controller: controller,
  };
});

/**
 *Directive that shows or closes NewsItems
 */
App.directive("daPushDownAlertCustomText", function () {
  var controller = [
    "$rootScope",
    "$scope",
    "$state",
    function ($rootScope, $scope, $state) {
      function init() {
        $scope.closeMe = function () {
          $rootScope.unreadNews = null;
          var child = angular.element(
            document.getElementsByClassName("push-down-alert--custom")
          );
          child.slideUp("fast", function () {
            child.remove();
          });
        };

        $scope.seeMore = function () {
          $scope.closeMe();
        };
      }
      init();
    },
  ];
  return {
    restrict: "E",
    transclude: true,
    templateUrl: "app/common/templates/push-down-alert-custom-text-tpl.html",
    controller: controller,
  };
});
