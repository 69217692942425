import graphql from "babel-plugin-relay/macro";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useState } from "react";
import { useMutation } from "react-relay";
import styled from "styled-components";
import { SendAllUserCodesButtonMutation } from "./__generated__/SendAllUserCodesButtonMutation.graphql";

interface SendAllUserCodesButtonProps {
  authToken: string;
  panelId: string;
  isHidden: boolean;
}

const sendAllUsersMutation = graphql`
  mutation SendAllUserCodesButtonMutation($systemId: ID!) {
    sendAllUserCodes(systemId: $systemId) {
      ... on SendAllUserCodesErrorResponse {
        __typename
        message
      }
      ... on SendAllUserCodesSuccessResponse {
        __typename
      }
    }
  }
`;

// create a custom event to trigger the busy indicator on the page
// this is needed because the busy indicator is not a react component
const triggerBusyUpdate = (isBusy: boolean) => {
  const event = new CustomEvent("updateIsBusy", { detail: { isBusy } });
  window.dispatchEvent(event);
};

function SendAllUserCodesButton({
  authToken,
  panelId,
  isHidden,
}: SendAllUserCodesButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const showAlert = useShowAlert();
  const [sendAllCodes, sendingAllCodes] =
    useMutation<SendAllUserCodesButtonMutation>(sendAllUsersMutation);

  const handleSendAllUsers = () => {
    triggerBusyUpdate(true); // trigger busy indicator
    sendAllCodes({
      variables: { systemId: panelId },
      onCompleted: (data) => {
        triggerBusyUpdate(false);
        if (
          data.sendAllUserCodes.__typename === "SendAllUserCodesErrorResponse"
        ) {
          showAlert({
            // show error alert
            type: "error",
            text:
              data.sendAllUserCodes.message || "Usercodes not sent to Panel",
          });
        } else {
          showAlert({
            // show success alert
            type: "success",
            text: "All users sent successfully",
          });
        }
      },
      onError: (error) => {
        // handle error
        triggerBusyUpdate(false);
        showAlert({
          type: "error",
          text: error.message || "An unexpected error occurred",
        });
      },
    });
    setShowModal(false); // close modal
  };

  return (
    <>
      <button
        className="btn btn-sm btn-dmp"
        onClick={() => setShowModal(true)}
        disabled={sendingAllCodes}
      >
        Send All Users
      </button>

      {showModal ? (
        <Modal size="medium" className="modal-content">
          <SendModalHeader>
            <SendModalTitle>Send All Users</SendModalTitle>
          </SendModalHeader>
          <SendModalBody>
            <p>Are you sure you want to send all users to the panel?</p>
            <p>This may take several minutes to complete.</p>
            <ModalFooterContainer>
              <button
                className="btn btn-dmp btn-sm mar-r-8"
                onClick={handleSendAllUsers}
                disabled={sendingAllCodes}
              >
                Yes
              </button>
              <button
                className="btn btn-default btn-sm"
                onClick={() => setShowModal(false)}
              >
                No
              </button>
            </ModalFooterContainer>
          </SendModalBody>
        </Modal>
      ) : null}
    </>
  );
}

//styled-components

const SendModalHeader = styled.div`
  border: 1px solid var(--color-neutral-200);
  padding: 1.5rem 2rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: var(--color-primary-300);
`;
const SendModalTitle = styled.div`
  font-size: 2rem;
  font-weight: var(--font-weight-semibold);
  color: white;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const SendModalBody = styled.div`
  background-color: white;
  border: 1px solid var(--color-neutral-200);
  padding: 1.5rem 2rem;
  border-radius: 0 0 0.5rem 0.5rem;
  & > p {
    margin: 0;
    font-size: 1.6rem;
  }
`;

//styled-components
const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export default SendAllUserCodesButton;
