/**
 *
 * BatteryIcon
 * @author Chad Watson
 *
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
import { useDanger, useCaution, useSuccess } from "../Theme";

const Empty = () => (
  <g>
    <rect fill="none" width="64" height="64" />
    <g>
      <g>
        <g>
          <g>
            <path
              d="M57.354,44h-0.231L2.696,44.001C0.568,44.001,0,42.453,0,41.538V23.069C0,21.406,1.342,20,2.932,20h54.191
            C58.709,20,60,21.377,60,23.069V25h1.069C62.686,25,64,26.323,64,27.95v8.707C64,38.023,62.957,40,61.069,40H60v1.538
            C60,42.453,59.443,44,57.354,44z M4,40h52v-2c0-1.104,0.896-2,2-2h2v-7h-2c-1.104,0-2-0.896-2-2v-3H4V40z"
            />
          </g>
        </g>
      </g>
    </g>
  </g>
);

const Low = () => (
  <g>
    <rect fill="none" width="64" height="64" />
    <g>
      <g>
        <rect x="8" y="27" width="8" height="10" />
        <path
          d="M61.069,25H60v-1.931C60,21.377,58.709,20,57.123,20H2.932C1.342,20,0,21.406,0,23.069v18.469
        C0,42.453,0.568,44,2.696,44h54.427h0.231C59.443,44,60,42.453,60,41.538V40h1.069C62.957,40,64,38.023,64,36.657V27.95
        C64,26.323,62.686,25,61.069,25z M60,36h-2c-1.104,0-2,0.896-2,2v2H4V24h52v3c0,1.104,0.896,2,2,2h2V36z"
        />
      </g>
    </g>
  </g>
);

const Half = () => (
  <g>
    <rect fill="none" width="64" height="64" />
    <g>
      <g>
        <rect x="8" y="27" width="8" height="10" />
        <rect x="20" y="27" width="8" height="10" />
        <path
          d="M61.069,25H60v-1.93c0-1.693-1.291-3.07-2.877-3.07H2.932C1.342,20,0,21.406,0,23.07v18.469
        c0,0.914,0.567,2.463,2.692,2.463L57.123,44l0.235,0.002c2.085,0,2.642-1.549,2.642-2.463V40h1.069
        C62.957,40,64,38.023,64,36.658v-8.707C64,26.324,62.686,25,61.069,25z M60,36h-2c-1.104,0-2,0.896-2,2v2H4V24h52v3
        c0,1.104,0.896,2,2,2h2V36z"
        />
      </g>
    </g>
  </g>
);

const AlmostFull = () => (
  <g>
    <rect fill="none" width="64" height="64" />
    <g>
      <g>
        <rect x="8" y="27" width="8" height="10" />
        <rect x="20" y="27" width="8" height="10" />
        <rect x="32" y="27" width="8" height="10" />
        <path
          d="M61.069,25H60v-1.929C60,21.378,58.709,20,57.123,20H2.932C1.342,20,0,21.406,0,23.071V41.54
        c0,0.914,0.566,2.462,2.688,2.462L57.123,44l0.239,0.002c2.083,0,2.638-1.548,2.638-2.462V40h1.069
        C62.796,40,64,38.239,64,36.659v-8.707C64,26.324,62.686,25,61.069,25z M60,36h-2c-1.104,0-2,0.896-2,2v2H4V24h52v3
        c0,1.104,0.896,2,2,2h2V36z"
        />
      </g>
    </g>
  </g>
);

const Full = () => (
  <g>
    <rect fill="none" width="64" height="64" />
    <g>
      <g>
        <rect x="8" y="28" width="8" height="10" />
        <rect x="20" y="28" width="8" height="10" />
        <rect x="32" y="28" width="8" height="10" />
        <rect x="44" y="28" width="8" height="10" />
        <path
          d="M61.069,26H60v-1.928C60,22.378,58.709,21,57.123,21H2.932C1.342,21,0,22.407,0,24.072v18.469
        c0,0.914,0.565,2.46,2.685,2.46L57.123,45l0.243,0.001c2.08,0,2.634-1.546,2.634-2.46V41h1.069C62.796,41,64,39.24,64,37.66
        v-8.707C64,27.325,62.686,26,61.069,26z M60,37h-2c-1.104,0-2,0.896-2,2v2H4V25h52v3c0,1.104,0.896,2,2,2h2V37z"
        />
      </g>
    </g>
  </g>
);

const VIEW_SIZE = 64;

const BatteryIcon = ({ level = 0, style = {}, ...rest }) => {
  const STATUS_RANGES = [
    [0, useDanger(), Empty],
    [25, useDanger(), Low],
    [50, useCaution(), Half],
    [75, useSuccess(), AlmostFull],
    [100, useSuccess(), Full],
  ];
  const status = STATUS_RANGES.find(([rangeMax]) => level <= rangeMax);
  const statusColor = status[1];
  const Icon = status[2];
  return (
    <SvgIcon
      {...rest}
      viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
      enableBackground={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
      style={{
        ...style,
        color: statusColor,
      }}
    >
      <Icon />
    </SvgIcon>
  );
};

export default BatteryIcon;
