/**
 * @generated SignedSource<<888ccfea0b74762e77a458ed6b8ffe71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly areaInformations: ReadonlyArray<{
    readonly autoArmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly autoDisarmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly badZonesOption: {
      readonly data: BadZonesOption | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
  } | null> | null;
  readonly " $fragmentType": "XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTAreaInformationTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "1ef1edb7c674cab69bbb1d7969cf2495";

export default node;
