import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaCardPlusPinField_xrArea$key } from "./__generated__/AreaCardPlusPinField_xrArea.graphql";

export const areaCardPlusPinFieldId = (number: string) =>
  `area-card-plus-pin-${number}`;

function AreaCardPlusPinField() {
  const [{ number, cardPlusPin }, updateArea] =
    useAreaFragment<AreaCardPlusPinField_xrArea$key>(
      graphql`
        fragment AreaCardPlusPinField_xrArea on XrArea {
          number
          cardPlusPin
        }
      `
    );

  const fieldId = areaCardPlusPinFieldId(number);

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Card Plus PIN">
      <Switch
        label="Card Plus PIN"
        id={fieldId}
        checked={cardPlusPin ?? false}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!cardPlusPin, "cardPlusPin");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaCardPlusPinField;
