import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplayFireZoneKeypadsField_statusListDisplay$key } from "./__generated__/StatusListDisplayFireZoneKeypadsField_statusListDisplay.graphql";

export const statusListDisplayFireZoneKeypadsFieldId = () =>
  "status-list-display-fire-zone-keypads";

function StatusListDisplayFireZoneKeypadsField() {
  const [{ fireZoneKeypads }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplayFireZoneKeypadsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplayFireZoneKeypadsField_statusListDisplay on StatusListDisplay {
          fireZoneKeypads
        }
      `
    );

  const fieldId = statusListDisplayFireZoneKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Fire Zone Keypads"
      tooltip="Configure which keypad addresses show Fire Zone Alarms and Troubles. "
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={fireZoneKeypads}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "fireZoneKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "fireZoneKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplayFireZoneKeypadsField;
