import DaColors from "app/common/DaColors";
import DmpIcon from "common/components/DmpIcon";
import {
  CameraStatus,
  StreamableCamera,
} from "dealer-admin/src/apis/camect-api/streamable-camera";
import CleanVideoPlayer from "dealer-admin/src/components/DaStyledElements/CleanVideoPlayer";
import useDebouncedResizeObserver from "dealer-admin/src/hooks/useDebouncedResizeObserver";
import useFullscreen from "dealer-admin/src/hooks/useFullscreen";
import useTogglePlay from "dealer-admin/src/hooks/useTogglePlay";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cameraPrefix } from "./utils/const";
import { friendlyDateTimeString } from "./utils/time-parser";

const Wrapper = styled.div`
  position: relative;
`;
const Header = styled.div`
  height: 8rem;
  width: 100%;
  background-color: ${DaColors.White};
  padding: 1rem;
  ${Wrapper}:fullscreen & {
    position: absolute;
    top: 0;
    z-index: 1001;
    background-color: hsla(0, 0%, 80%, 0.6);
    backdrop-filter: blur(0.4rem);
  }
`;
const StatusBadge = styled.div`
  position: absolute;
  right: 0;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border-radius: 0.4rem;
  background-color: ${DaColors.Neutral25};
  padding: 0.4rem 0.8rem;
`;
const StatusDot = styled.div<{ isOnline: boolean }>`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 100%;
  background-color: ${(props) =>
    props.isOnline ? DaColors.Success500 : DaColors.Failure500};
`;
const StatusText = styled.div`
  font-weight: bold;
  text-transform: capitalize;
`;
const CameraName = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: bold;
  @media (min-width: 1536px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;
const CountdownTimerWrapper = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media (min-width: 1536px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;
const CountdownTimerTime = styled.span`
  color: ${DaColors.Primary500};
  ${Wrapper}:fullscreen & {
    color: inherit;
  }
`;
const LastCheckin = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media (min-width: 1536px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;
const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: hsla(0, 0%, 80%, 0.6);
  padding: 1.6rem 1rem;
  backdrop-filter: blur(0.4rem);
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;
const PlayOrPause = styled.div`
  grid-column-start: 2;
  display: flex;
  justify-content: center;
`;
const FullscreenButtons = styled.div`
  display: flex;
  justify-content: end;
`;
const FlatButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  & svg {
    display: block !important; // react-icomoon uses inline styles
  }
`;

type CountdownTimerProps = {
  targetDate: Date;
};

const CountdownTimer = ({ targetDate }: CountdownTimerProps) => {
  const [currentMoment, setCurrentMoment] = useState(moment());
  const targetDateTime = moment(targetDate);
  const diff = moment.duration(targetDateTime.diff(currentMoment));
  const hours = diff.hours().toString();
  const minutes = diff.minutes().toString().padStart(2, "0");
  const seconds = diff.seconds().toString().padStart(2, "0");

  useEffect(() => {
    const timerId = setInterval(() => setCurrentMoment(moment()), 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
    <CountdownTimerWrapper>
      <span>Verification Time Limit: </span>
      <CountdownTimerTime>
        {hours}:{minutes}:{seconds}
      </CountdownTimerTime>
    </CountdownTimerWrapper>
  );
};

type Props = {
  camera: StreamableCamera;
  countdownTargetDate: Date;
  showControls?: boolean;
};

const LiveStream = ({ camera, countdownTargetDate, showControls }: Props) => {
  const { ref: resizeRef, width } = useDebouncedResizeObserver();
  const [isFullscreen, fullscreenRef, enterFullscreen, exitFullscreen] =
    useFullscreen();
  const [isPlaying, videoRef, togglePlay] = useTogglePlay(true);

  //we always want the height to be a function of width for the video player
  const ASPECT_RATIO = 16 / 9;
  const height = width ? width / ASPECT_RATIO : 0;

  return (
    <div ref={resizeRef}>
      <Wrapper ref={fullscreenRef}>
        <Header>
          <StatusBadge>
            <StatusDot isOnline={camera.status === CameraStatus.Online} />
            <StatusText>{camera.status}</StatusText>
          </StatusBadge>
          <CameraName>
            {cameraPrefix}
            {camera.name}
          </CameraName>
          <CountdownTimer targetDate={countdownTargetDate} />
          {camera.status === CameraStatus.Offline && camera.lastCheckin && (
            <LastCheckin>
              Last Check-in: {friendlyDateTimeString(camera.lastCheckin)}
            </LastCheckin>
          )}
        </Header>
        <CleanVideoPlayer
          ref={videoRef}
          camera={camera}
          width={isFullscreen ? window.innerWidth : width}
          height={isFullscreen ? window.innerHeight : height}
        />
        {showControls && (
          <ControlsWrapper>
            <PlayOrPause>
              <FlatButton
                onClick={togglePlay}
                title={isPlaying ? "Pause" : "Play"}
              >
                {isPlaying ? (
                  <DmpIcon
                    icon="pause_icon"
                    constrainToParent={false}
                    size={16}
                  />
                ) : (
                  <DmpIcon
                    icon="play_icon"
                    constrainToParent={false}
                    size={16}
                  />
                )}
              </FlatButton>
            </PlayOrPause>
            <FullscreenButtons>
              {!isFullscreen && (
                <FlatButton onClick={enterFullscreen} title="View fullscreen">
                  <DmpIcon icon="resize" constrainToParent={false} size={16} />
                </FlatButton>
              )}
              {isFullscreen && (
                <FlatButton onClick={exitFullscreen} title="Exit fullscreen">
                  <DmpIcon
                    icon="exit_fullscreen"
                    constrainToParent={false}
                    size={16}
                  />
                </FlatButton>
              )}
            </FullscreenButtons>
          </ControlsWrapper>
        )}
      </Wrapper>
    </div>
  );
};

export default LiveStream;
