/**** Selected Job Group Component ****/

/*
 *  After the user selects a job group, the group ID and panel ID are passed into
 *  this component, which will display all jobs for a given group in a table with some
 *  information about each job. We use 2 API calls here: one to grab the jobs, and one
 *  to grab the queue depth for each job.
 */

import graphql from "babel-plugin-relay/macro";
import LoadingSpinner from "components/LoadingSpinner";
import React from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import styled from "styled-components";
import { SelectedJobGroupQuery } from "./__generated__/SelectedJobGroupQuery.graphql";
import { SelectedJobGroupQueueDepthQuery } from "./__generated__/SelectedJobGroupQueueDepthQuery.graphql";
import { SelectedJobGroupRefreshQuery } from "./__generated__/SelectedJobGroupRefreshQuery.graphql";
import { SelectedJobGroup_query$key } from "./__generated__/SelectedJobGroup_query.graphql";

const WrapCell = styled.td`
  overflow: visible !important;
  text-overflow: visible !important;
  word-wrap: break-word !important;
  white-space: normal !important;
`;

const StatusIndicator = styled.span`
  font-size: 2em !important;
  vertical-align: top !important;
  line-height: 0.6em !important;
  margin-left: 5px !important;
  display: inline-block !important;
  color: ${(props) => props.color || "var(--color-warning-500)"} !important;
`;

const jobsFromGroupQuery = graphql`
  query SelectedJobGroupQuery($panelId: Int!, $groupId: Int!) {
    ...SelectedJobGroup_query
  }
`;

const queueDepthQuery = graphql`
  query SelectedJobGroupQueueDepthQuery($panelId: Int!, $jobId: Int!) {
    systemDiagnosticsJobDepth(panelId: $panelId, jobId: $jobId) {
      queueDepth
    }
  }
`;

function copyJob(result: any) {
  const padNum = 25;
  const copyText =
    "Job Id:".padEnd(padNum - 1) +
    (result.jobId ? result.jobId : "-") +
    "\nStatus:".padEnd(padNum) +
    (result.jobStatus ? result.jobStatus : "-") +
    "\nJob Description:".padEnd(padNum) +
    (result.jobDesc ? result.jobDesc : "-") +
    "\nJob Message:".padEnd(padNum) +
    (result.jobMessage ? result.jobMessage : "-") +
    "\nCreated:".padEnd(padNum) +
    (result.createdAt ? formatDateString(result.createdAt) : "-") +
    "\nUpdated:".padEnd(padNum) +
    (result.updatedAt ? formatDateString(result.updatedAt) : "-") +
    "\nCompleted:".padEnd(padNum) +
    (result.completedAt ? formatDateString(result.completedAt) : "-") +
    "\n";

  return copyText;
}

function formatDateString(dateString: string) {
  // small function to format dates from "YYYY-MM-DDTHH:MM:SS-5:00" to something more readable
  const dateObj = new Date(dateString);
  return `${dateObj.getMonth()}/${dateObj.getDay()}/${dateObj.getFullYear()} at ${dateObj
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${dateObj.getSeconds().toString().padStart(2, "0")}`;
}

function QueueDepth({
  inputPanelId,
  inputJobId,
}: {
  inputPanelId: number;
  inputJobId: number;
}) {
  const data = useLazyLoadQuery<SelectedJobGroupQueueDepthQuery>(
    queueDepthQuery,
    {
      panelId: inputPanelId,
      jobId: inputJobId,
    },
    {
      fetchPolicy: "network-only",
    }
  );

  return (
    <>
      {data.systemDiagnosticsJobDepth.queueDepth == -1 && "N/A"}
      {data.systemDiagnosticsJobDepth.queueDepth != -1 &&
        data.systemDiagnosticsJobDepth.queueDepth}
    </>
  );
}

function SelectedJobGroup({
  inputPanelId,
  inputJobGroupId,
}: {
  inputPanelId: number;
  inputJobGroupId: number;
}) {
  const data = useLazyLoadQuery<SelectedJobGroupQuery>(jobsFromGroupQuery, {
    panelId: inputPanelId,
    groupId: inputJobGroupId,
  });

  // made refetchable to allow refreshes
  const [refreshedJobs, refetch] = useRefetchableFragment<
    SelectedJobGroupRefreshQuery,
    SelectedJobGroup_query$key
  >(
    graphql`
      fragment SelectedJobGroup_query on Query
      @refetchable(queryName: "SelectedJobGroupRefreshQuery") {
        systemDiagnosticsJobListFromGroup(
          panelId: $panelId
          groupId: $groupId
        ) {
          jobId
          jobDesc
          jobStatus
          jobMessage
          createdAt
          updatedAt
          completedAt
          schedulerJobType
        }
      }
    `,
    data
  );

  return (
    <>
      {refreshedJobs.systemDiagnosticsJobListFromGroup.length == 0 && (
        <p style={{ textAlign: "center" }}>
          Couldn't find any jobs for that group.
        </p>
      )}
      {refreshedJobs.systemDiagnosticsJobListFromGroup.length > 0 && (
        <div className="table-condensed">
          <table className="table table-striped table-fixed--not-mobile">
            <caption>
              <button
                onClick={() => {
                  refetch({}, { fetchPolicy: "network-only" });
                }}
                className="link link-primary color-primary-600"
                style={{ textDecoration: "none", marginLeft: "20px" }}
              >
                <strong>Refresh</strong>
              </button>
            </caption>
            <thead>
              <tr>
                <th style={{ width: "5%", textAlign: "center" }}></th>
                <th>Job ID</th>
                <th>Status</th>
                <th>Description</th>
                <th>Message</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Completed</th>
                <th>Scheduler Job Type</th>
                <th>Queue Depth</th>
              </tr>
            </thead>
            <tbody>
              {refreshedJobs.systemDiagnosticsJobListFromGroup.map((job) => {
                return (
                  <tr key={job.jobId}>
                    <td>
                      <button
                        title="Copy to Clipboard"
                        className="link link-primary color-primary-600"
                        style={{ textDecoration: "none", zIndex: "1000" }}
                        onClick={() =>
                          navigator.clipboard.writeText(copyJob(job))
                        }
                      >
                        <i
                          style={{
                            fontSize: "1.8em",
                            verticalAlign: "middle",
                          }}
                          className="icon-doc"
                        ></i>
                      </button>
                    </td>
                    <td>{job.jobId}</td>
                    <WrapCell>
                      {job.jobStatus}
                      {job.jobStatus == "fail" && (
                        <StatusIndicator color="var(--color-danger-500)">
                          &bull;
                        </StatusIndicator>
                      )}
                      {job.jobStatus == "success" && (
                        <StatusIndicator color="var(--color-success-500)">
                          &bull;
                        </StatusIndicator>
                      )}
                      {job.jobStatus != "fail" &&
                        job.jobStatus != "success" && (
                          <StatusIndicator>&bull;</StatusIndicator>
                        )}
                    </WrapCell>
                    <WrapCell>{job.jobDesc}</WrapCell>
                    <WrapCell>{job.jobMessage}</WrapCell>
                    <WrapCell>
                      {job.createdAt && formatDateString(job.createdAt)}
                    </WrapCell>
                    <WrapCell>
                      {job.updatedAt ? formatDateString(job.updatedAt) : "-"}
                    </WrapCell>
                    <WrapCell>
                      {job.completedAt
                        ? formatDateString(job.completedAt)
                        : "-"}
                    </WrapCell>
                    <WrapCell>{job.schedulerJobType}</WrapCell>
                    <WrapCell>
                      <React.Suspense fallback={<LoadingSpinner />}>
                        <QueueDepth
                          inputPanelId={inputPanelId}
                          inputJobId={job.jobId}
                        ></QueueDepth>
                      </React.Suspense>
                    </WrapCell>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default SelectedJobGroup;
