import * as React from "react";
import Switch from "../Switch";
import { Disposable, useFragment, UseMutationConfig } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { MutationParameters } from "relay-runtime";
import { Zone_zone$key } from "./__generated__/Zone_zone.graphql";

type Props = {
  zone: Zone_zone$key;
  bypassZone: (config: UseMutationConfig<MutationParameters>) => Disposable;
  resetBypassZone: (config: UseMutationConfig<MutationParameters>) => Disposable;
};

const ZoneBypassSwitch = (props: Props) => {
  const data = useFragment(
    graphql`
      fragment ZoneBypassSwitch_zone on Zone {
        id
        status
        canBypass
      }
    `,
    props.zone
  );

  const isBypassed = data.status === "BYPASSED";

  return (
    <Switch
      checked={isBypassed}
      disabled={!data.canBypass}
      id={`zone-${data.id}-bypassed`}
      onChange={() => {
        const bypassMethod = isBypassed ? "resetBypassZone" : "bypassZone";

        props[bypassMethod]({
          variables: {
            id: data.id,
          },
          updater: (store, response: any) => {
            const zoneRecord = store.get(data.id);
            zoneRecord?.setValue(response[bypassMethod].zone.status, "status");
          }
        });
      }}
      small
    />
  );
};

export default ZoneBypassSwitch;
