/**
 * @ngdoc service
 * @name App.factory:PulseService
 *
 * @description
 * Gets, modifies, and submits data for the service request feature
 *
 */
App.factory("PulseService", [
  "PulseApi",
  "$q",
  function (PulseApi, $q) {
    return {
      getServerStatus: function (version) {
        var deferred = $q.defer();
        var checkVersion = version ? version : "1.0";
        PulseApi.getServerStatus(
          { datecode: new Date().getTime() },
          function (data) {
            this.serverState = data[checkVersion];
            deferred.resolve(this.serverState);
          },
          function (error) {
            this.serverState = {
              "1.0": {
                outage: false,
                client_message:
                  "Dealer Admin is currently unavailable. This does not affect your alarm communication. We are working on it and will be back very soon...",
              },
            };
            deferred.reject(this.serverState);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
