/**=========================================================
 * Refresh panels
 * [panel-do-refresh] * [data-spinner="standard"]
 =========================================================*/
App.directive("daPanelDoRefresh", function () {
  "use strict";

  return {
    restrict: "A",
    controller: function ($scope, $element) {
      var refreshEvent = "panel-refresh",
        csspinnerClass = "csspinner",
        defaultSpinner = "standard";

      // method to clear the spinner when done
      function removeSpinner() {
        this.removeClass(csspinnerClass);
      }

      // catch clicks to toggle panel refresh
      $element.on("click", function () {
        var $this = $(this),
          panel = $this.parents(".panel").eq(0),
          spinner = $this.data("spinner") || defaultSpinner;
        // start showing the spinner
        panel.addClass(csspinnerClass + " " + spinner);

        // attach as public method
        panel.removeSpinner = removeSpinner;

        // Trigger the event and send the panel object
        $this.trigger(refreshEvent, [panel]);
      });
    },
  };
});
