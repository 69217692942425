/**
 * @generated SignedSource<<b2342cea652f3e453dcdfe942f87dfe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationTestTimeField_communication$data = {
  readonly testTime: string;
  readonly " $fragmentType": "CommunicationTestTimeField_communication";
};
export type CommunicationTestTimeField_communication$key = {
  readonly " $data"?: CommunicationTestTimeField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationTestTimeField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationTestTimeField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testTime",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "d4fc95a168dacc4476bebd36c71e2cd0";

export default node;
