import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports$key } from "./__generated__/PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports.graphql";

export const pcLogReportsLogRealTimeStatusReportsEnabledFieldId = () =>
  "pc-log-reports-log-real-time-status-reports-enabled";

function PcLogReportsLogRealTimeStatusReportsEnabledField() {
  const [{ logRealTimeStatusEnabled }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports on PcLogReports {
          logRealTimeStatusEnabled
        }
      `
    );

  const fieldId = pcLogReportsLogRealTimeStatusReportsEnabledFieldId();
  const label = "Log Real Time Status Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Sends Real-Time Status reports for zones, doors, and outputs. The specific reports 
      must also be selected by individual zone or output."
    >
      <Switch
        label={label}
        id={fieldId}
        checked={!!logRealTimeStatusEnabled}
        onChange={() => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(
              !logRealTimeStatusEnabled,
              "logRealTimeStatusEnabled"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsLogRealTimeStatusReportsEnabledField;
