/**
 * @generated SignedSource<<eeb928643b879da1b7eadcee0fa78547>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRCardFormatsProgrammingConceptFormInline_cardFormat$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: string;
  readonly requireSiteCode: boolean;
  readonly siteCodeLength: number;
  readonly siteCodePosition: number;
  readonly siteCodes: ReadonlyArray<number | null>;
  readonly type: number;
  readonly userCodeDigits: number;
  readonly userCodeLength: number;
  readonly userCodePosition: number;
  readonly wiegandLength: number;
  readonly " $fragmentType": "XRCardFormatsProgrammingConceptFormInline_cardFormat";
};
export type XRCardFormatsProgrammingConceptFormInline_cardFormat$key = {
  readonly " $data"?: XRCardFormatsProgrammingConceptFormInline_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCardFormatsProgrammingConceptFormInline_cardFormat">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRCardFormatsProgrammingConceptFormInline_cardFormat"
};

(node as any).hash = "eb06bbaa110ff1c83b04b0898641995b";

export default node;
