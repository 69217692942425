import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationSecondApnField_communication$key } from "./__generated__/CommunicationSecondApnField_communication.graphql";

export const communicationSecondApnFieldId = () => "communication-second-apn";

function CommunicationSecondApnField() {
  const [{ cellApn2 }, updateCommunication] =
    useCommunicationFragment<CommunicationSecondApnField_communication$key>(
      graphql`
        fragment CommunicationSecondApnField_communication on Communication {
          cellApn2
        }
      `
    );

  const fieldId = communicationSecondApnFieldId();
  const label = "Second APN";

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={label}>
      <TextInput
        id={fieldId}
        value={String(cellApn2 ?? "")}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "cellApn2");
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "cellApn2");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationSecondApnField;
