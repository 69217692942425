/**
 * Component: SystemFirmwareComponent
 *
 * Description:
 * The `SystemFirmwareComponent` is a React functional component used for displaying the software version
 * and date of a system's firmware. It is designed to provide a concise and clear presentation of firmware
 * information in the user interface.
 */

import React from "react";

interface SystemFirmwareComponentProps {
  softwareDate: string | null;
  softwareVersion: string;
}

const SystemFirmwareComponent: React.FC<SystemFirmwareComponentProps> = ({
  softwareDate,
  softwareVersion,
}) => {
  return (
    <div>
      {softwareVersion}
      <span className="table-deemphasis">
        {softwareDate ? <span> ({softwareDate})</span> : null}
      </span>
    </div>
  );
};

export default SystemFirmwareComponent;
