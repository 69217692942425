/**
 * @generated SignedSource<<1a0ec438718697f90af1bf6cf5fd316b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationPinDisarmField_deviceInformation$data = {
  readonly deviceType?: DeviceInformationType;
  readonly number: number;
  readonly pinDisarm?: boolean | null;
  readonly " $fragmentType": "DeviceInformationPinDisarmField_deviceInformation";
};
export type DeviceInformationPinDisarmField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationPinDisarmField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationPinDisarmField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationPinDisarmField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinDisarm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        }
      ],
      "type": "XrDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "718d33c539b97f7df3e1939fcdc62866";

export default node;
