import { GenericSuspenseFallback } from "components/SiteForm/SuspenseFallback";
import React, { Suspense } from "react";

export default function SuspenseBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Suspense fallback={<GenericSuspenseFallback />}>{children}</Suspense>;
}
