/**
 *
 * LeafIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const LeafIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M62.698,7.818c-0.864-0.322-1.83-0.013-2.35,0.745c-0.02,0.029-2.163,2.985-7.442,4.334
    c-1.863,0.475-4.539,0.699-7.637,0.96c-6.771,0.568-15.199,1.275-23.255,5.02C9.936,24.49,8.676,34.711,9.697,39.331
    c0.184,0.829,0.53,1.581,1.014,2.255C7.248,42.879,2.18,45.902,0.086,52.81c-0.158,0.521-0.097,1.084,0.17,1.56
    c0.267,0.475,0.716,0.82,1.242,0.957l3.543,0.918c0.224,0.057,0.454,0.09,0.682,0.09c0.499,0,0.981-0.154,1.325-0.525
    c0.5-0.54,0.699-1.169,0.461-1.865c-0.005-0.036-0.354-3.456,6.585-8.598c1.407,2.312,4.021,5.121,9.899,5.492
    C24.035,50.84,24.077,51,24.119,51c0.016,0,0.03,0,0.046,0c34.878-1,39.776-40.523,39.822-40.924
    C64.091,9.164,63.56,8.139,62.698,7.818z M53.823,28.044c-6.624,12.04-16.603,18.311-29.663,18.638
    c-4.808-0.325-6.211-2.393-7.188-4.413c1.2-2.249,5.656-9.097,16.082-10.566c7.861-1.108,9.604-2.204,11.621-3.473
    c0.771-0.485,1.501-0.944,2.734-1.472c1.016-0.434,1.488-1.608,1.055-2.624c-0.435-1.015-1.61-1.487-2.625-1.054
    c-1.525,0.65-2.465,1.242-3.294,1.764c-1.695,1.066-3.035,1.908-10.05,2.897c-10.919,1.538-16.314,8.11-18.451,11.592
    c-0.21-0.267-0.372-0.55-0.441-0.865c-0.022-0.103-2.119-10.286,10.097-15.964c7.412-3.444,15.447-4.119,21.904-4.661
    c3.275-0.274,6.104-0.513,8.291-1.069c2.03-0.52,3.708-1.242,5.064-2.007C58.068,18.307,56.495,23.188,53.823,28.044z"
      />
    </g>
  </SvgIcon>
);

export default LeafIcon;
