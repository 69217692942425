import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsZoneRetardDelayField_systemOptions$key } from "./__generated__/SystemOptionsZoneRetardDelayField_systemOptions.graphql";

export const systemOptionsZoneRetardDelayFieldId = () =>
  "system-options-zone-retard-delay";

function SystemOptionsZoneRetardDelayField() {
  const [
    { zoneRetardDelay, zoneRetardDelayMin, zoneRetardDelayMax },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsZoneRetardDelayField_systemOptions$key>(
    graphql`
      fragment SystemOptionsZoneRetardDelayField_systemOptions on SystemOptions {
        ... on XrSystemOptions {
          zoneRetardDelay
          zoneRetardDelayMin
          zoneRetardDelayMax
        }
        ... on XfSystemOptions {
          zoneRetardDelay
          zoneRetardDelayMin
          zoneRetardDelayMax
        }
      }
    `
  );

  const fieldId = systemOptionsZoneRetardDelayFieldId();
  const originalValue = React.useRef(zoneRetardDelay).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Zone Retard Delay"
      tooltip="Set the Zone Retard Delay in seconds to delay reporting a short condition on Fire, Supervisory, Aux 1, Aux 2 and Panic Zone types."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={zoneRetardDelay?.toString()}
        required
        min={zoneRetardDelayMin ?? 0}
        max={zoneRetardDelayMax}
        inlineHelp={`${zoneRetardDelayMin}–${zoneRetardDelayMax}`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "zoneRetardDelay"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(zoneRetardDelayMin, zoneRetardDelayMax, valueAsNumber);
            recordProxy.setValue(Number(value), "zoneRetardDelay");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsZoneRetardDelayField;
