import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationPriorityZoneField_zone$key } from "./__generated__/ZoneInformationPriorityZoneField_zone.graphql";

export const zoneInformationPriorityZoneFieldId = (number: string) =>
  `zone-information-priority-zone-${number}`;

function ZoneInformationPriorityZoneField() {
  const [{ number, type, priorityZone }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationPriorityZoneField_zone$key>(
      graphql`
        fragment ZoneInformationPriorityZoneField_zone on Zone {
          number
          type
          priorityZone
        }
      `
    );

  const fieldId = zoneInformationPriorityZoneFieldId(String(number));
  const disabled = [ZoneType.ARMING, ZoneType.DOORBELL].includes(
    type as ZoneType
  );
  const label = "Priority Zone";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Enable to require this Zone to be in a normal condition before the assigned Area can be armed. Cannot be bypassed."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={priorityZone}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!priorityZone, "priorityZone");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationPriorityZoneField;
