import { getCamectUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
// import { setCorrectDefaultsForAdvancedSettings } from "src/components/CameraSettingsPage/defaults";
import { parseDealerVideoAccess } from "./dealer-video-access-parser";
import {
  IensoCamera,
  parseIensoCameras,
  // parseRawIensoCameraSettings,
  transformRawIensoCamera,
} from "./ienso-cameras-parser";
import { parseIensoLivestream } from "./ienso-livestream-parser";
import { parseMonitoringCameras } from "./monitoring-cameras-parser";

const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;
const CAMECT_API_BASE_URL = getCamectUrl(secureComEnv);

// In any interaction with camect-api or other external systems, we want to send and receive serial
// numbers without dashes.
export const removeDashes = (string: string) => {
  return string.replaceAll("-", "");
};

const getVideoMonitoringCameras = async (
  scwToken: string,
  controlSystemId: number
) => {
  const path = `/api/v1/monitoring/${controlSystemId}/cameras`;
  const query = `?auth_token=${scwToken}`;
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseMonitoringCameras(responseJson.cameras);
  } else {
    throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
  }
};

const getDealerVideoAccess = async (
  scwToken: string,
  controlSystemId: number
) => {
  const path = `/api/v1/camect/${controlSystemId}/dealer-access`;
  const query = `?auth_token=${scwToken}`;
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseDealerVideoAccess(responseJson);
  } else {
    throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
  }
};

const generateIensoLiveViewSession = async (
  scwToken: string,
  controlSystemId: number,
  deviceId: string,
  forMonitoring = false
) => {
  const basePath = `/api/v1/ienso/${controlSystemId}/cameras/${deviceId}`;
  const path = forMonitoring
    ? `${basePath}/monitoring/livestream`
    : `${basePath}/livestream`;
  const query = `?auth_token=${scwToken}`;
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseIensoLivestream(responseJson);
  } else {
    throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
  }
};

const getIensoCameras = async (
  scwToken: string,
  controlSystemId: number
): Promise<IensoCamera[]> => {
  const path = `/api/v1/ienso/${controlSystemId}/cameras`;
  const query = `?auth_token=${scwToken}`;
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseIensoCameras(responseJson);
  } else {
    throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
  }
};

const getIensoCamera = async (
  scwToken: string,
  controlSystemId: number,
  serialNumber: string
): Promise<IensoCamera> => {
  const path = `/api/v1/ienso/${controlSystemId}/cameras/${removeDashes(
    serialNumber
  )}`;
  const query = `?auth_token=${scwToken}`;
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return transformRawIensoCamera(responseJson);
  } else {
    throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
  }
};

const deleteIensoCamera = async (
  scwToken: string,
  controlSystemId: number,
  serialNumber: string
) => {
  const path = `/api/v1/ienso/${controlSystemId}/cameras/${removeDashes(
    serialNumber
  )}`;
  const query = `?auth_token=${scwToken}`;
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url, { method: "DELETE" });
  if (!response.ok) {
    throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
  }
};

// const getIensoCameraSettings = async (
//   serialNumber: string,
//   scwToken: string,
//   controlSystemId: number
// ) => {
//   const path = `/api/v1/ienso/${controlSystemId}/cameras/${removeDashes(
//     serialNumber
//   )}/settings`;
//   const query = `?auth_token=${scwToken}`;
//   const url = `${CAMECT_API_BASE_URL}${path}${query}`;
//   const response = await fetch(url);
//   if (response.ok) {
//     const responseJson = await response.json();
//     return parseRawIensoCameraSettings(responseJson);
//   } else {
//     throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
//   }
// };

// const getConfigurableCameraSettings = async (
//   iensoCamera: IensoCamera,
//   scwToken: string,
//   controlSystemId: number
// ) => {
//   const iensoSettings = await getIensoCameraSettings(
//     removeDashes(iensoCamera.serialNumber),
//     scwToken,
//     controlSystemId
//   );
//   const activeInterface = getActiveIensoInterface(
//     iensoCamera.networkInterfaces
//   );
//   const cameraMac = activeInterface?.macAddress || "-";
//   const ipAddress = activeInterface?.ipAddress || "-";

//   return {
//     authToken: "",
//     cameraId: iensoCamera.deviceId,
//     cameraType: "ienso",
//     firmwareVersion: iensoCamera.firmware.version,
//     ipAddress: ipAddress,
//     livePreview: true,
//     name: iensoCamera.deviceName,
//     serial: iensoCamera.serialNumber,
//     mac: cameraMac,
//     resolution: iensoCamera.stream.resolutionName,
//     settings: iensoSettings,
//     status:
//       iensoCamera.status === "DEVICE_CONNECTED"
//         ? CameraStatus.Online
//         : CameraStatus.Offline,
//     env: "",
//     hubId: "",
//     validationErrors: { name: [] },
//     panelDeviceNumber: iensoCamera.panelDeviceNumber,
//   } as ConfigurableCamera;
// };

// const getConfigurableCamera = async (
//   scwToken: string,
//   controlSystemId: number,
//   serialNumber: string
// ): Promise<ConfigurableCamera> => {
//   const iensoCamera = await getIensoCamera(
//     scwToken,
//     controlSystemId,
//     removeDashes(serialNumber)
//   );
//   const uiCamera = getConfigurableCameraSettings(
//     iensoCamera,
//     scwToken,
//     controlSystemId
//   );
//   return uiCamera;
// };

// const getConfigurableCameras = async (
//   scwToken: string,
//   controlSystemId: number
// ): Promise<ConfigurableCamera[]> => {
//   const allIensoCameras = await getIensoCameras(scwToken, controlSystemId);
//   const uiCameras = allIensoCameras.map((iensoCamera) =>
//     getConfigurableCameraSettings(iensoCamera, scwToken, controlSystemId)
//   );
//   return Promise.all(uiCameras);
// };

const registerIensoCamera = async (
  scwToken: string,
  controlSystemId: number,
  deviceType: string,
  serialNumber: string
) => {
  const path = `/api/v1/ienso/${controlSystemId}/cameras`;
  const query = `?auth_token=${scwToken}`;
  const body = {
    device_type: deviceType,
    serial_number: removeDashes(serialNumber),
  };
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
  });
  if (response.ok) {
    const responseJson = await response.json();
    return {
      registrationRequestId: responseJson.registration_request_id,
      registrationRequestToken: responseJson.registration_request_token,
      encryptionKey: responseJson.encryption_key,
      countryCode: responseJson.country_code,
    };
  } else {
    const responseText = await response.text();
    throw Error(
      `Camect API Error: ${response.status}:${response.statusText}:${responseText}`
    );
  }
};

const enableAlarmVisionForIensoCamera = async (
  scwToken: string,
  controlSystemId: number,
  serialNumber: string,
  panelDeviceNumber: number
) => {
  const path = `/api/v1/ienso/${controlSystemId}/cameras/${removeDashes(
    serialNumber
  )}/enable-alarm-vision`;
  const query = `?auth_token=${scwToken}`;
  const body = {
    panel_device_number: panelDeviceNumber,
    panel_device_name: serialNumber, // Not removing dashes because we're using it as a name
  };
  const url = `${CAMECT_API_BASE_URL}${path}${query}`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
  }
};

// const setIensoCameraSettings = async (
//   scwToken: string,
//   controlSystemId: number,
//   camera: ConfigurableCamera
// ) => {
//   //We need to translate this back to iENSO's format
//   const correctSettings = setCorrectDefaultsForAdvancedSettings(
//     camera.settings
//   );
//   const iensoSettings = serializeConfigurableCameraSettings(correctSettings);
//   const path = `/api/v1/ienso/${controlSystemId}/cameras/${removeDashes(
//     camera.serial
//   )}/settings`;
//   const query = `?auth_token=${scwToken}`;
//   const url = `${CAMECT_API_BASE_URL}${path}${query}`;
//   const response = await fetch(url, {
//     method: "POST",
//     body: JSON.stringify(iensoSettings),
//   });
//   if (!response.ok) {
//     throw Error(`Camect API Error: ${response.status}:${response.statusText}`);
//   }
// };

export {
  deleteIensoCamera,
  enableAlarmVisionForIensoCamera,
  generateIensoLiveViewSession,
  // getConfigurableCamera,
  // getConfigurableCameras,
  getDealerVideoAccess,
  // getIensoCameraSettings,
  getIensoCameras,
  getVideoMonitoringCameras,
  registerIensoCamera,
};
