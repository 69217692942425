/**
 * @generated SignedSource<<b9c3b78a3bc3c5e21c60273c81c85c68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusListDisplayFireZoneKeypadsField_statusListDisplay$data = {
  readonly fireZoneKeypads: string;
  readonly " $fragmentType": "StatusListDisplayFireZoneKeypadsField_statusListDisplay";
};
export type StatusListDisplayFireZoneKeypadsField_statusListDisplay$key = {
  readonly " $data"?: StatusListDisplayFireZoneKeypadsField_statusListDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplayFireZoneKeypadsField_statusListDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusListDisplayFireZoneKeypadsField_statusListDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fireZoneKeypads",
      "storageKey": null
    }
  ],
  "type": "StatusListDisplay",
  "abstractKey": null
};

(node as any).hash = "6886c4d458912310d8e8f269ad1d3bf0";

export default node;
