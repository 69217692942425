/**
 *
 * TravelIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const TravelIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <path
        d="M59,10H43V5c0-2.757-2.243-5-5-5H25c-2.757,0-5,2.243-5,5v5H5c-2.757,0-5,2.243-5,5v38c0,2.757,2.243,5,5,5h7v4
    c0,1.104,0.896,2,2,2s2-0.896,2-2v-4h32v4c0,1.104,0.896,2,2,2s2-0.896,2-2v-4h7c2.757,0,5-2.243,5-5V15C64,12.243,61.757,10,59,10
    z M24,5c0-0.552,0.448-1,1-1h13c0.552,0,1,0.448,1,1v5H24V5z M48,14v40H16V14H48z M4,53V15c0-0.552,0.448-1,1-1h7v40H5
    C4.448,54,4,53.552,4,53z M60,53c0,0.552-0.448,1-1,1h-7V14h7c0.552,0,1,0.448,1,1V53z"
      />
    </g>
  </SvgIcon>
);

export default TravelIcon;
