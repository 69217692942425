/**
 * @generated SignedSource<<70de3491b260d491d0813116bc08ecd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationProgrammingConceptFormInline_systemAreaInformation$data = {
  readonly __typename: "SystemAreaInformation";
  readonly anyBypass: boolean;
  readonly areaSchedules: boolean;
  readonly burglaryBellOutputNumber: string;
  readonly closingCheck: boolean;
  readonly closingCode: boolean;
  readonly exitDelay: number;
  readonly morningAmbush: number;
  readonly openClosingReports: boolean;
  readonly supportsOpenClosingReports: boolean;
  readonly " $fragmentType": "XRAreaInformationProgrammingConceptFormInline_systemAreaInformation";
};
export type XRAreaInformationProgrammingConceptFormInline_systemAreaInformation$key = {
  readonly " $data"?: XRAreaInformationProgrammingConceptFormInline_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptFormInline_systemAreaInformation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRAreaInformationProgrammingConceptFormInline_systemAreaInformation"
};

(node as any).hash = "c8a5fb536811aa1409c197eb7b64bcc4";

export default node;
