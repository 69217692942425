import * as React from "react";
import styled from "styled-components";

const Container = styled.div<{ fontSize?: React.CSSProperties["fontSize"] }>`
  position: relative;
  font-size: ${({ fontSize }) => fontSize ?? "9rem"};
`;
const Svg = styled.svg`
  display: block;
  width: 1em;
  height: 1em;
`;
const PercentageCircle = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
const Percentage = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  letter-spacing: -0.04em;
  font-weight: 200;
  font-size: 0.3em;
  color: var(--color-text-default);
  line-height: 1;
`;

export default function ProgressCircle(props: {
  percentage: number;
  size?: React.CSSProperties["fontSize"];
  withText?: boolean;
}) {
  const { percentage } = props;
  const size = 120;
  const strokeWidth = 12;
  const r = size / 2 - strokeWidth;
  const c = r * 2 * Math.PI;

  return (
    <Container fontSize={props.size}>
      <Svg viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={r}
          fill="transparent"
          stroke="var(--color-neutral-200)"
          strokeWidth={strokeWidth}
        />
        <PercentageCircle
          cx={size / 2}
          cy={size / 2}
          r={r}
          fill="transparent"
          stroke="var(--color-info-400)"
          strokeWidth={strokeWidth}
          strokeDashoffset={c - (percentage / 100) * c}
          strokeDasharray={`${c} ${c}`}
        />
      </Svg>
      {!!props.withText && <Percentage>{percentage}%</Percentage>}
    </Container>
  );
}
