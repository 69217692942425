/**
 * @generated SignedSource<<77ffb9670633c788ab3aeb45fcacf223>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsEnableKeypadPanicKeysField_systemOptions$data = {
  readonly enableKeypadPanicKeys?: boolean;
  readonly " $fragmentType": "SystemOptionsEnableKeypadPanicKeysField_systemOptions";
};
export type SystemOptionsEnableKeypadPanicKeysField_systemOptions$key = {
  readonly " $data"?: SystemOptionsEnableKeypadPanicKeysField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsEnableKeypadPanicKeysField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enableKeypadPanicKeys",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsEnableKeypadPanicKeysField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentrySystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "8c94b48c70e6caab5ea76e31b1e669f3";

export default node;
