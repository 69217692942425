/**
 *
 * AnimatedGarageDoorIcon
 * @author Chad Watson
 *
 */

import React from "react";
import styled from "styled-components";
import SvgIcon from "./SvgIcon";
import { ZwaveBarrierOperatorStatus } from "securecom-graphql/client";

const VIEW_SIZE = 512;
const OPEN_Y = 200;

const DoorElement = styled.rect`
  transition: 1s y;
`;

const getY = (status: ZwaveBarrierOperatorStatus, closedY: number) =>
  ({
    [ZwaveBarrierOperatorStatus.OPEN]: OPEN_Y,
    [ZwaveBarrierOperatorStatus.CLOSED]: closedY,
    [ZwaveBarrierOperatorStatus.STOPPED]: (closedY + OPEN_Y) / 2,
  }[status] ?? OPEN_Y);

const CLIP_MASK_ID = "door-mask";

const AnimatedGarageDoorIcon = ({
  lineColor = "currentColor",
  status,
  ...rest
}: {
  status: ZwaveBarrierOperatorStatus;
  lineColor?: string;
}) => (
  <SvgIcon
    {...rest}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <defs>
      <clipPath id={CLIP_MASK_ID}>
        <rect x="126.7" y="276.8" width="254.4" height="184" />
      </clipPath>
    </defs>
    {status === ZwaveBarrierOperatorStatus.UNKNOWN ? (
      <text
        x={VIEW_SIZE / 2}
        y={VIEW_SIZE * 0.82}
        fontSize={VIEW_SIZE * 0.35}
        fontWeight={500}
        textAnchor="middle"
      >
        ?
      </text>
    ) : (
      <g clipPath={`url(#${CLIP_MASK_ID})`}>
        <DoorElement
          fill={lineColor}
          x="126.7"
          y={getY(status, 276.8)}
          width="254.4"
          height="16.3"
        />
        <DoorElement
          fill={lineColor}
          x="126.7"
          y={getY(status, 335.5)}
          width="254.4"
          height="16.3"
        />
        <DoorElement
          fill={lineColor}
          x="126.7"
          y={getY(status, 390.9)}
          width="254.4"
          height="16.3"
        />
        <DoorElement
          fill={lineColor}
          x="126.7"
          y={getY(status, 446.3)}
          width="254.4"
          height="14.5"
        />
        <g>
          <DoorElement
            fill={lineColor}
            x="175.6"
            y={getY(status, 306.1)}
            width="35.9"
            height="19.6"
          />
          <DoorElement
            fill={lineColor}
            x="237.6"
            y={getY(status, 306.1)}
            width="35.9"
            height="19.6"
          />
          <DoorElement
            fill={lineColor}
            x="299.6"
            y={getY(status, 306.1)}
            width="32.6"
            height="19.6"
          />
        </g>
        <DoorElement
          fill={lineColor}
          x="240.8"
          y={getY(status, 420.3)}
          width="22.8"
          height="13"
        />
      </g>
    )}
    <path d="M475.1,202.4l27.4,18.3l9.5-14.3L255.4,34.9L0,206.4l9.6,14.2L40.3,200v260H4.2v17.2h503.7V460h-32.8V202.4z M57.5,188.5 l197.9-133l202.5,135.4V460H398V217.3H109.7V460H57.5V188.5z M380.9,460h-254V234.5h254V460z" />
  </SvgIcon>
);

export default AnimatedGarageDoorIcon;
