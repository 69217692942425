/**
 * @ngdoc directive
 * @name App.directive:zeroPadIpv6
 *
 * @description
 *   Pads an IPV6 input field value with zeros
 *
 */
App.directive("daZeroPadIpv6", function ($filter) {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, elem, attrs, ctrl) {
      var applyPad = attrs.zpadipv6;
      if (applyPad !== "true") return;

      //view -> model
      ctrl.$parsers.unshift(function (value) {
        var paddedValue = $filter("zpadIPV6")(value);
        return paddedValue;
      });

      elem.bind("blur", function () {
        ctrl.$viewValue = $filter("zpadIPV6")(elem.val());
        ctrl.$render();
      });

      // This will override the pattern directive for any fields that use the zero-pad directive.
      ctrl.$validators.pattern = function (modelValue, viewValue) {
        if (attrs.pattern == undefined || !attrs.pattern) return true;
        var refPattern = new RegExp("^" + attrs.pattern + "$");
        return refPattern.test(modelValue);
      };
    },
  };
});
