/**
 * @generated SignedSource<<b6c1dab5573a0c191050c7b69e20219f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HolidayDateClass = "A" | "B" | "C";
export type GlobalHolidayDatesTableBodyDeleteMutation$variables = {
  dealerId: number;
  holidayId: number;
};
export type GlobalHolidayDatesTableBodyDeleteMutation$data = {
  readonly deleteGlobalHolidayDate: {
    readonly dealer?: {
      readonly globalHolidayDates: ReadonlyArray<{
        readonly clazz: HolidayDateClass;
        readonly date: string;
        readonly description: string;
        readonly id: string;
        readonly name: string;
        readonly scapiId: number;
      }>;
    } | null;
    readonly errorMessage?: string;
  };
};
export type GlobalHolidayDatesTableBodyDeleteMutation = {
  response: GlobalHolidayDatesTableBodyDeleteMutation$data;
  variables: GlobalHolidayDatesTableBodyDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dealerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "holidayId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "dealerId",
    "variableName": "dealerId"
  },
  {
    "kind": "Variable",
    "name": "holidayId",
    "variableName": "holidayId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "GlobalHolidayDate",
  "kind": "LinkedField",
  "name": "globalHolidayDates",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scapiId",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clazz",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    }
  ],
  "type": "DeleteGlobalHolidayDateErrorResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalHolidayDatesTableBodyDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteGlobalHolidayDate",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeleteGlobalHolidayDateSuccessResponse",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GlobalHolidayDatesTableBodyDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteGlobalHolidayDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeleteGlobalHolidayDateSuccessResponse",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8cb878e8ebe5fde1ec5ee625d02c781",
    "id": null,
    "metadata": {},
    "name": "GlobalHolidayDatesTableBodyDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation GlobalHolidayDatesTableBodyDeleteMutation(\n  $holidayId: Int!\n  $dealerId: Int!\n) {\n  deleteGlobalHolidayDate(holidayId: $holidayId, dealerId: $dealerId) {\n    __typename\n    ... on DeleteGlobalHolidayDateSuccessResponse {\n      dealer {\n        globalHolidayDates {\n          scapiId\n          id\n          name\n          description\n          date\n          clazz\n        }\n        id\n      }\n    }\n    ... on DeleteGlobalHolidayDateErrorResponse {\n      errorMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7bcf6ce32aa3e09c10cd85d45ce7f585";

export default node;
