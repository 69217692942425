/**
 * @generated SignedSource<<77d55a822deac8e3b7521dddd8b22487>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type FeatureKeyEnum = "ALL_NO_YES_OPTION" | "ALL_USER_CODE_CHANGE" | "BANAMEX_MANTRAP" | "BANK_OF_AMERICA" | "CANADIAN" | "DOOR_ADD_ON_A" | "DOOR_ADD_ON_B" | "DUAL_NETWORK_PATH" | "DURESS_PLUS_2" | "EMAIL_MESSAGING" | "ENCRYPTION" | "EUROPEAN_FEATURES" | "FE_MORAN" | "IGNORE_BROADCAST" | "MINIMUM_USER_NAME" | "NATIONAL_ACCOUNT" | "PRINTER" | "RESTRICT_PROFILES" | "SERVICE_USER_AUTHENTICATION" | "SMITH_SOUTHERN" | "SMITH_WESSON" | "STARBUCKS" | "SUPPORT_200_KEY_FOBS" | "WELLS_FARGO" | "XR150" | "XR350";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type XRFeatureKeysProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly featureKeys: ReadonlyArray<{
      readonly key: FeatureKeyEnum;
    }>;
    readonly hardwareModel: PanelHardwareModel;
    readonly id: string;
  };
  readonly " $fragmentType": "XRFeatureKeysProgrammingConceptFormInline_controlSystem";
};
export type XRFeatureKeysProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRFeatureKeysProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRFeatureKeysProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRFeatureKeysProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "5c6e68ccc597fa40f44a13fedb572258";

export default node;
