/**
 * @generated SignedSource<<b99f460b1cb5bea64f6c12af6b4ca5b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SortOrder = "ASC" | "DESC";
export type SearchInput = {
  keys?: ReadonlyArray<string> | null;
  sortByScore?: boolean | null;
  term: string;
};
export type SortInput = {
  keys: ReadonlyArray<string>;
  order?: SortOrder | null;
};
export type ResetTypeTableQuery$variables = {
  after?: string | null;
  brownOutReset: boolean;
  first: number;
  j16Reset: boolean;
  lockupReset: boolean;
  powerReset: boolean;
  search?: SearchInput | null;
  sort?: SortInput | null;
  systemReset: boolean;
  watchdogReset: boolean;
};
export type ResetTypeTableQuery$data = {
  readonly systemDiagnostic: {
    readonly brownOutReset: {
      readonly " $fragmentSpreads": FragmentRefs<"ResetTypeTable">;
    };
    readonly j16Reset: {
      readonly " $fragmentSpreads": FragmentRefs<"ResetTypeTable">;
    };
    readonly lockupReset: {
      readonly " $fragmentSpreads": FragmentRefs<"ResetTypeTable">;
    };
    readonly powerReset: {
      readonly " $fragmentSpreads": FragmentRefs<"ResetTypeTable">;
    };
    readonly systemReset: {
      readonly " $fragmentSpreads": FragmentRefs<"ResetTypeTable">;
    };
    readonly watchdogReset: {
      readonly " $fragmentSpreads": FragmentRefs<"ResetTypeTable">;
    };
  };
};
export type ResetTypeTableQuery = {
  response: ResetTypeTableQuery$data;
  variables: ResetTypeTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brownOutReset"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "j16Reset"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lockupReset"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "powerReset"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemReset"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "watchdogReset"
},
v10 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ResetTypeTable"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v14 = [
  (v13/*: any*/),
  (v11/*: any*/)
],
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v12/*: any*/),
    "concreteType": "PanelResetStatisticsConnection",
    "kind": "LinkedField",
    "name": "panelStatisticsConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PanelResetStatisticEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetStatistic",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "controlSystem",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Dealer",
                        "kind": "LinkedField",
                        "name": "dealer",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hardwareType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "connectionType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": (v14/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dealerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amountOfResets",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v12/*: any*/),
    "filters": [
      "search",
      "sort"
    ],
    "handle": "connection",
    "key": "PanelResetStatistics_panelStatisticsConnection",
    "kind": "LinkedHandle",
    "name": "panelStatisticsConnection"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetTypeTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemDiagnostic",
        "kind": "LinkedField",
        "name": "systemDiagnostic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "brownOutReset",
            "plural": false,
            "selections": [
              {
                "condition": "brownOutReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v10/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "j16Reset",
            "plural": false,
            "selections": [
              {
                "condition": "j16Reset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v10/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "lockupReset",
            "plural": false,
            "selections": [
              {
                "condition": "lockupReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v10/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "powerReset",
            "plural": false,
            "selections": [
              {
                "condition": "powerReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v10/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "systemReset",
            "plural": false,
            "selections": [
              {
                "condition": "systemReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v10/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "watchdogReset",
            "plural": false,
            "selections": [
              {
                "condition": "watchdogReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v10/*: any*/)
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "ResetTypeTableQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemDiagnostic",
        "kind": "LinkedField",
        "name": "systemDiagnostic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "brownOutReset",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "condition": "brownOutReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v15/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "j16Reset",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "condition": "j16Reset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v15/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "lockupReset",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "condition": "lockupReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v15/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "powerReset",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "condition": "powerReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v15/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "systemReset",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "condition": "systemReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v15/*: any*/)
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "watchdogReset",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "condition": "watchdogReset",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v15/*: any*/)
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35d78a7fdc8e85ef19bb55f3e4117200",
    "id": null,
    "metadata": {},
    "name": "ResetTypeTableQuery",
    "operationKind": "query",
    "text": "query ResetTypeTableQuery(\n  $first: Int!\n  $after: ID\n  $search: SearchInput\n  $sort: SortInput\n  $brownOutReset: Boolean!\n  $j16Reset: Boolean!\n  $lockupReset: Boolean!\n  $powerReset: Boolean!\n  $systemReset: Boolean!\n  $watchdogReset: Boolean!\n) {\n  systemDiagnostic {\n    brownOutReset {\n      ...ResetTypeTable @include(if: $brownOutReset)\n      id\n    }\n    j16Reset {\n      ...ResetTypeTable @include(if: $j16Reset)\n      id\n    }\n    lockupReset {\n      ...ResetTypeTable @include(if: $lockupReset)\n      id\n    }\n    powerReset {\n      ...ResetTypeTable @include(if: $powerReset)\n      id\n    }\n    systemReset {\n      ...ResetTypeTable @include(if: $systemReset)\n      id\n    }\n    watchdogReset {\n      ...ResetTypeTable @include(if: $watchdogReset)\n      id\n    }\n  }\n}\n\nfragment ResetTypeTable on PanelResetInformation {\n  amount\n  panelStatisticsConnection(first: $first, after: $after, search: $search, sort: $sort) {\n    edges {\n      node {\n        id\n        controlSystem {\n          databaseId\n          site {\n            databaseId\n            id\n          }\n          customer {\n            id\n            databaseId\n            dealer {\n              id\n              databaseId\n            }\n          }\n          id\n        }\n        hardwareType\n        version\n        serial\n        connectionType\n        dealer {\n          databaseId\n          id\n        }\n        dealerName\n        amountOfResets\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "9ce5842ef9dc80d114064b1c161c44f2";

export default node;
