/**
 *
 * PanelCommunicationIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const PanelCommunicationIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M60.979,28H21.784C20.132,28,18,29.82,18,31.753v28.883C18,62.72,20.204,64,21.784,64h39.194
      C62.701,64,64,62.554,64,60.636V31.753C64,29.908,62.87,28,60.979,28z M22,36.319c2.323,0.93,6.592,3.312,6.592,8.293
      c0,4.566-3.829,8.131-6.592,10.113V36.319z M60,60H22v-0.501c2.83-1.64,10.592-6.869,10.592-14.886
      c0-7.588-6.639-11.411-10.592-12.535v-0.01c0.024-0.023,0.052-0.046,0.079-0.068H60V60z"
        />
        <rect x="47.277" y="48.986" width="9.593" height="5.482" />
        <path
          d="M29.535,9.473l-3.89-0.822c-0.954,4.511-3.585,8.661-7.222,11.391c-4.368,3.325-10.234,4.588-15.695,3.379l-0.854,3.871
      c6.586,1.458,13.674-0.072,18.951-4.087C25.272,19.864,28.367,14.987,29.535,9.473z"
        />
        <path
          d="M15.886,15.279c3.174-2.584,5.349-6.243,6.121-10.299l-3.906-0.743c-0.59,3.097-2.312,6-4.737,7.973
      c-3.117,2.567-7.556,3.563-11.594,2.6l-0.92,3.858C6.151,19.934,11.775,18.664,15.886,15.279z"
        />
        <path
          d="M10.166,7.911c2.261-1.823,3.792-4.498,4.209-7.333L10.442,0C10.17,1.862,9.158,3.622,7.669,4.823
      C5.857,6.281,3.296,6.817,0.986,6.226L0,10.067C3.551,10.979,7.35,10.171,10.166,7.911z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default PanelCommunicationIcon;
