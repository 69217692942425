import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathCellApn2Field_communicationPath$key } from "./__generated__/CommPathCellApn2Field_communicationPath.graphql";

export const commPathCellApn2FieldId = (number: string) =>
  `comm-path-cell-apn2-${number}`;

function CommPathCellApn2Field() {
  const [{ number, commType, apn2 }, updateCommPath] =
    useCommPathFragment<CommPathCellApn2Field_communicationPath$key>(
      graphql`
        fragment CommPathCellApn2Field_communicationPath on CommunicationPath {
          number
          commType
          apn2
        }
      `
    );

  const fieldId = commPathCellApn2FieldId(String(number));
  const disabled = !["CELL"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Second Cell APN"
      tooltip="Enter the second APN (Access Point Name). This is shared for any Cellular paths."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={apn2.toString()}
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "apn2");
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "apn2");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathCellApn2Field;
