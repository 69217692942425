import * as React from "react";

export const Table = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
} & React.TableHTMLAttributes<HTMLTableElement>) => {
  return (
    <div className="table-responsive">
      <table
        {...rest}
        className={"table table-striped table-condensed".concat(
          rest.className ? ` ${rest.className}` : ""
        )}
      >
        {children}
      </table>
    </div>
  );
};

//Table Header
export const TableHeaderLeft = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className="data-table__top--left">{children}</div>;

export const TableHeaderCenter = ({
  children,
}: {
  children?: React.ReactNode;
}) => <div className="data-table__top--center">{children}</div>;

const TableHeaderRight = ({ children }: { children?: React.ReactNode }) => (
  <div className="data-table__top--right">{children}</div>
);

export function TableHeader({ children }: { children?: React.ReactNode }) {
  return <div className="data-table__top">{children}</div>;
}
TableHeader.Left = TableHeaderLeft;
TableHeader.Center = TableHeaderCenter;
TableHeader.Right = TableHeaderRight;

//TableFooter
export const TableFooterLeft = ({
  children,
}: {
  children?: React.ReactNode;
}) => <div className="data-table__bottom--left">{children}</div>;

export const TableFooterCenter = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className="data-table__bottom--center">{children}</div>;

const TableFooterRight = ({ children }: { children?: React.ReactNode }) => (
  <div className="data-table__bottom--right">{children}</div>
);
export function TableFooter({ children }: { children?: React.ReactNode }) {
  return <div className="data-table__bottom">{children}</div>;
}

export function TableFooterReducedPadding({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div
      className="data-table__bottom"
      style={{ paddingTop: "1.8rem", paddingBottom: "1.8rem" }}
    >
      {children}
    </div>
  );
}

TableFooter.Left = TableFooterLeft;
TableFooter.Center = TableFooterCenter;
TableFooter.Right = TableFooterRight;
