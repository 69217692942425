import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { idAsString, toDealerId } from "securecom-graphql/client";
import styled from "styled-components";
import TagsSettingsTableItem from "./TagsSettingsTableItem";
import { TagsSettingsTableQuery } from "./__generated__/TagsSettingsTableQuery.graphql";

interface TagsSettingsTableProps {
  dealerId: number;
  state: any;
}
function TagsSettingsTable(props: TagsSettingsTableProps) {
  const { dealerId, state } = props;
  const { getDealerTagsRelationsCounts: tagsData } =
    useLazyLoadQuery<TagsSettingsTableQuery>(
      graphql`
        query TagsSettingsTableQuery($dealerId: ID!) {
          getDealerTagsRelationsCounts(id: $dealerId) {
            dealerTag {
              id
              name
              description
            }
            tagCustomerCount
            tagPanelCount
          }
        }
      `,
      { dealerId: idAsString(toDealerId(dealerId)) },
      { fetchPolicy: "network-only" }
    );

  return (
    <PanelTable className="panel panel-table">
      <div className="panel-body">
        <div className="table-responsive">
          <TableBr className="table table-striped table-fixed--not-mobile">
            <thead>
              <tr>
                <th>Name</th>
                <th>Systems Added</th>
                <th>Customers Added</th>
                <th>Description</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {tagsData.length > 0 ? (
                tagsData.map((tag) => {
                  return (
                    tag && (
                      <TagsSettingsTableItem
                        key={tag.dealerTag.id}
                        state={state}
                        tag={tag.dealerTag}
                        customersCount={tag.tagCustomerCount}
                        systemsCount={tag.tagPanelCount}
                      />
                    )
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>No tags created...</td>
                </tr>
              )}
            </tbody>
            <tfoot>&nbsp;</tfoot>
          </TableBr>
        </div>
      </div>
    </PanelTable>
  );
}

const PanelTable = styled.div`
  border-radius: 0.4rem;
`;
const TableBr = styled.table`
  border-radius: 0.4rem;
`;

export default TagsSettingsTable;
