/**
 * @generated SignedSource<<625c2e7d79a44661403c8b727d115937>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts";
};
export type TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts"
};

(node as any).hash = "373a17c734d548b9d188d4870e13d094";

export default node;
