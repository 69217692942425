/**
 * @generated SignedSource<<a88bc8dcde1e11d548f7b4a00a80bf10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DeviceInformation1100TWirelessFrequencyType = "DSC" | "HONEYWELL" | "INTERLOGIX" | "NONE" | "_2GIG";
export type DeviceInformationWirelessSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly axNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly door: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lxNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly network: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly supervisionTime: {
      readonly data: DeviceInformationWirelessSupervisionTime | null;
      readonly included: boolean | null;
    } | null;
    readonly wireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessTranslator1100t: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessTranslator1100tFrequency: {
      readonly data: DeviceInformation1100TWirelessFrequencyType | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "795b1ff43736966cddedad241651f631";

export default node;
