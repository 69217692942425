/**
 * App.service.DealerReceiversService
 *
 * Service for the user-initiated event auditing
 */

App.service("DealerReceiversService", [
  "DealerReceiversAPI",
  "$q",
  "UserService",
  function (DealerReceiversAPI, $q, UserService) {
    var _this = this;

    _this.create = function (receiver) {
      var deferred = $q.defer();
      DealerReceiversAPI.create(
        { route: "dealers", dealer_id: UserService.dealer_id },
        angular.toJson(receiver),
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    _this.destroy = function (receiver_id) {
      var deferred = $q.defer();
      DealerReceiversAPI.destroy(
        { receiver_id: receiver_id },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    _this.get = function (receiver_id) {
      var deferred = $q.defer();
      index()
        .then(
          function (data) {
            var receiver = data.find(function (r) {
              return r.id === +receiver_id;
            });
            if (receiver) {
              deferred.resolve(receiver);
            } else {
              deferred.reject("Not found");
            }
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    _this.getAll = function () {
      return index();
    };

    _this.getNew = function () {
      var deferred = $q.defer();
      DealerReceiversAPI.new(
        {
          route: "dealers",
          dealer_id: UserService.dealer_id,
          receiver_id: "new",
        },
        function (data) {
          deferred.resolve(data.receiver);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    _this.update = function (receiver) {
      var deferred = $q.defer();
      DealerReceiversAPI.update(
        { receiver_id: receiver.id },
        angular.toJson(receiver),
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    function index() {
      var deferred = $q.defer();
      DealerReceiversAPI.index(
        { route: "dealers", dealer_id: UserService.dealer_id, page_size: 100 },
        function (data) {
          var receivers = [];
          angular.forEach(data, function (obj) {
            if (obj.hasOwnProperty("receiver")) {
              receivers.push(obj.receiver);
            }
          });
          // Sort by receiver_number ascending
          receivers.sort(function (a, b) {
            return a.receiver_number - b.receiver_number;
          });
          deferred.resolve(receivers);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
  },
]);

App.factory("DealerReceiversAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/:route/:dealer_id/receivers/:receiver_id",
      {},
      {
        create: { method: "POST" },
        destroy: { method: "DELETE" },
        index: { method: "GET", isArray: true },
        new: { method: "GET" },
        update: { method: "PATCH" },
      }
    );
  },
]);
