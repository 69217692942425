import graphql from "babel-plugin-relay/macro";

export const xtTemplateSaveMutation = graphql`
  mutation XTTemplateSaveMutation(
    $dealerId: ID!
    $templateData: XtProgrammingTemplateInput!
    $isInternational: Boolean!
  ) {
    saveXtProgrammingTemplate(
      dealerId: $dealerId
      templateData: $templateData
      isInternational: $isInternational
    ) {
      ... on SaveProgrammingTemplateErrorResult {
        error
        messages
      }
      ... on SaveProgrammingTemplateSuccessResult {
        programmingTemplate {
          concepts {
            ...XTTemplateDataInline_xtProgrammingTemplateConcepts
          }
        }
      }
      __typename
    }
  }
`;
