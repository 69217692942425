/**
 * @generated SignedSource<<3ab9493e4bf1c299da81edf0c8009672>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatSiteCode1Field_cardFormat$data = {
  readonly number: string;
  readonly requireSiteCode: boolean;
  readonly siteCodes: ReadonlyArray<number | null>;
  readonly " $fragmentType": "CardFormatSiteCode1Field_cardFormat";
};
export type CardFormatSiteCode1Field_cardFormat$key = {
  readonly " $data"?: CardFormatSiteCode1Field_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatSiteCode1Field_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatSiteCode1Field_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requireSiteCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "1ea3b7e522d66cf5c199b4189119fe53";

export default node;
