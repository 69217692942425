/**
 * @generated SignedSource<<6d1c81be6e963a9b25e5e155d61e36b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZonePirPulseCount = "FOUR" | "NONE" | "TWO";
export type ZoneSensorType = "GLASSBREAK" | "HOLD_UP" | "PIR_OR_RECESSED_DOOR" | "REPEATER" | "SMOKE" | "UNIVERSAL_TRANSMITTER" | "UNKNOWN_DEVICE" | "WIRELESS_FOUR_ZONE_EXPANDER" | "WIRELESS_PIR";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationPIRPulseCountField_zone$data = {
  readonly number: string;
  readonly sensorType: ZoneSensorType;
  readonly serialNumber: string;
  readonly wireless: boolean;
  readonly wirelessPirPulseCount: ZonePirPulseCount | null;
  readonly " $fragmentType": "ZoneInformationPIRPulseCountField_zone";
};
export type ZoneInformationPIRPulseCountField_zone$key = {
  readonly " $data"?: ZoneInformationPIRPulseCountField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationPIRPulseCountField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationPIRPulseCountField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wirelessPirPulseCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sensorType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "41a5bddb38a5ad269c66037c51f6d348";

export default node;
