/**
 * @ngdoc service
 * @name App.factory:SitesService
 *
 * @description
 * API Interface service for Sites.
 *
 */
App.factory("SitesService", [
  "$q",
  "SitesODataAPI",
  function ($q, SitesODataAPI) {
    return {
      /**
       * @ngdoc array
       * @name method:getX1CheckInStatuses
       * @methodOf App.factory:SitesService
       *
       * @description
       * X1CheckinStatus returned.
       *
       * @param {String} siteId
       * @return {promise} The promise from the Sites API 'getX1CheckInStatuses'.
       */
      getX1CheckInStatuses: function (siteId) {
        const deferred = $q.defer();

        SitesODataAPI.getX1CheckInStatuses(
          { siteId: siteId }, //params
          function (data) {
            //success
            deferred.resolve(data.value);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
