App.controller("AccountingReportsCtrl", [
  "$rootScope",
  "$scope",
  "$q",
  "$filter",
  "UserService",
  "ODataService",
  "DataTablesUtilsService",
  "DTColumnBuilder",
  "$modal",
  function (
    $rootScope,
    $scope,
    $q,
    $filter,
    UserService,
    ODataService,
    DataTablesUtilsService,
    DTColumnBuilder,
    $modal
  ) {
    let ctrl = this; // ControllerAs
    ctrl.userService = UserService;

    if (!UserService.canViewAccountingReports()) {
      return;
    }

    /* ----------------------------------------------------
    Scheduled payments table
    ---------------------------------------------------- */

    /**
     * Gets list of scheduled payments.
     */
    function getScheduledPayments() {
      let deferred = $q.defer();

      ODataService.accountingReports
        .getScheduledPayments()
        .then(
          (payments) => {
            deferred.resolve(payments);
          },
          (error) => {
            console.error(
              "AccountingReportsCtrl->getScheduledPayments() error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Error getting scheduled payments",
            });
            deferred.reject(error);
          }
        )
        .catch((error) => {
          console.error(error);
        });

      return deferred.promise;
    }

    /*
    Setup scheduled payments data table

    No table filtering, buttons, or pagination. Only expecting a max of
    one scheduled payment for now.
    */
    let scheduledPaymentsPromise = getScheduledPayments();
    ctrl.scheduledPaymentsDtOptions = DataTablesUtilsService.getDTOptions(
      scheduledPaymentsPromise
    );
    ctrl.scheduledPaymentsDtOptions.dtColumnDefs = [
      DTColumnBuilder.newColumn(null)
        .withTitle("Schedule")
        .renderWith(function (data, type, row) {
          return row.CronDescription;
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Account")
        .renderWith(function (data, type, row) {
          return row.DealerCode;
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Account Name")
        .renderWith(function (data, type, row) {
          return row.AccountName1;
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Dealer")
        .renderWith(function (data, type, row) {
          return row.DealerName;
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Payment Method")
        .renderWith(function (data, type, row) {
          let underscoresRemoved = $filter("hyphenscoretospaces")(
            row.SourceSubType
          );
          return $filter("titlecase")(underscoresRemoved);
        }),
    ];

    /* ----------------------------------------------------
    Payment history table
    ---------------------------------------------------- */

    // Index of the date column for payment history table
    const DATE_COL_INDEX = 1;

    /**
     * Gets list of completed payments.
     */
    function getPayments() {
      let deferred = $q.defer();

      ODataService.accountingReports
        .getPayments()
        .then(
          (payments) => {
            deferred.resolve(payments);
          },
          (error) => {
            console.error(
              "AccountingReportsCtrl->getPayments() error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Error getting payments",
            });
            deferred.reject(error);
          }
        )
        .catch((error) => {
          console.error(error);
        });

      return deferred.promise;
    }

    /**
     * Processes a date input value. Returns either a valid Date object or null.
     */
    function processDate(dateInput) {
      // Don't try to convert these to Date().
      if (dateInput == null || dateInput == "") {
        return null;
      }

      // For "Invalid Date", return null
      let date = new Date(dateInput);
      return isNaN(date) ? null : date;
    }

    /*
    Setup payments date picker filters
    */
    ctrl.paymentsDatePicker = {};
    ctrl.paymentsDatePicker.startDate = null;
    ctrl.paymentsDatePicker.startDateStr = "";
    ctrl.paymentsDatePicker.isStartDateOpen = false;
    ctrl.paymentsDatePicker.isStartDateValid = true;
    ctrl.paymentsDatePicker.endDate = null;
    ctrl.paymentsDatePicker.endDateStr = "";
    ctrl.paymentsDatePicker.isEndDateOpen = false;
    ctrl.paymentsDatePicker.isEndDateValid = true;
    ctrl.paymentsDatePicker.openStartDate = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      ctrl.paymentsDatePicker.isStartDateOpen = true;

      // Hide the end date selector if open
      ctrl.paymentsDatePicker.isEndDateOpen = false;
    };
    ctrl.paymentsDatePicker.openEndDate = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      ctrl.paymentsDatePicker.isEndDateOpen = true;

      // Hide the start date selector if open
      ctrl.paymentsDatePicker.isStartDateOpen = false;
    };
    ctrl.paymentsDatePicker.options = {
      formatYear: "yyyy",
      startingDay: 1,
    };

    /*
    Setup payments data table
    */

    // Setup a callback to get a reference to the datatable instance
    ctrl.paymentsDtInstance = null;
    ctrl.paymentsDtIntanceCallback = function (instance) {
      ctrl.paymentsDtInstance = instance;
    };

    // Setup data table
    let paymentsPromise = getPayments();
    ctrl.paymentsDtOptions = DataTablesUtilsService.getDTOptions(
      paymentsPromise
    ).withOption("order", [DATE_COL_INDEX, "desc"]);

    // Setup columns
    ctrl.paymentsDtOptions.dtColumnDefs = [
      DTColumnBuilder.newColumn(null)
        .notSortable()
        .withOption("width", "10px")
        .withOption("class", "error-col")
        .renderWith(function (data, type, row) {
          if (row.Error != null) {
            return '<i class="fa fa-exclamation-circle" aria-hidden="true"></i>';
          } else {
            return "";
          }
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Date")
        .renderWith(function (data, type, row) {
          return $filter("date")(row.CreatedAt, "medium");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Account")
        .renderWith(function (data, type, row) {
          return row.DealerCode;
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Account Name")
        .renderWith(function (data, type, row) {
          return row.AccountName1;
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Dealer")
        .renderWith(function (data, type, row) {
          return row.DealerName;
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Payment Type")
        .renderWith(function (data, type, row) {
          return $filter("paymentDescription")(row.Description);
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Payment Method")
        .renderWith(function (data, type, row) {
          let underscoresRemoved = $filter("hyphenscoretospaces")(
            row.SourceSubType
          );
          return $filter("titlecase")(underscoresRemoved);
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Amount")
        .renderWith(function (data, type, row) {
          return $filter("currency")(row.Amount * 0.01, "$", 2);
        }),
    ];

    // Handle when data table is initialized
    $("#PaymentsDataTable").on("init.dt", function () {
      // Move the date filter controls into position.
      // A tempoaray style attribute is used to hide the filters until the table is loaded,
      // so remove that too.
      $("#PaymentsDataTable_filter").append($("#PaymentsStartDateFilter"));
      $("#PaymentsStartDateFilter").attr("style", "");
      $("#PaymentsDataTable_filter").append($("#PaymentsEndDateFilter"));
      $("#PaymentsEndDateFilter").attr("style", "");

      // Watch for changes in the date filters and redraw the table to apply the filters
      $scope.$watch(
        "ctrl.paymentsDatePicker.startDateStr",
        function (newvalue, oldvalue) {
          // An empty value is valid, but have to go directly to the input box value to check this
          let strVal = $("#PaymentsStartDateTextBox").val();
          ctrl.paymentsDatePicker.startDate = processDate(newvalue);
          ctrl.paymentsDatePicker.isStartDateValid =
            strVal == "" || ctrl.paymentsDatePicker.startDate != null;
          ctrl.paymentsDtInstance.DataTable.draw();
        }
      );
      $scope.$watch(
        "ctrl.paymentsDatePicker.endDateStr",
        function (newvalue, oldvalue) {
          // An empty value is valid, but have to go directly to the input box value to check this
          let strVal = $("#PaymentsEndDateTextBox").val();
          ctrl.paymentsDatePicker.endDate = processDate(newvalue);
          ctrl.paymentsDatePicker.isEndDateValid =
            strVal == "" || ctrl.paymentsDatePicker.endDate != null;
          ctrl.paymentsDtInstance.DataTable.draw();
        }
      );
    });

    // Add a filter for start/end date
    $.fn.dataTableExt.afnFiltering.push(function (
      oSettings,
      aData,
      iDataIndex
    ) {
      // Index of the date field
      let minDate = ctrl.paymentsDatePicker.startDate;
      let maxDate = ctrl.paymentsDatePicker.endDate;
      let date = processDate(aData[DATE_COL_INDEX]);

      // Add 1 day to the end date to include records that occur on the end date
      if (maxDate) {
        maxDate = new Date(maxDate.getTime() + 60 * 60 * 24 * 1000);
      }

      // If no filters set, include this entry
      if (!minDate && !maxDate) {
        return true;
      }

      // Get date for this row - if no date, exclude the entry
      if (!date) {
        return false;
      }

      // Compare to filters
      if (minDate && date < minDate) {
        return false;
      }
      if (maxDate && date > maxDate) {
        return false;
      }

      // Date is within filters, include
      return true;
    });

    // Display a modal popup when a payment entry is clicked
    $("#PaymentsDataTable").on("click", "tbody tr", function () {
      // Get details for payment that was clicked and save in scope so modal can display it
      $scope.selectedPaymentDetails = $("#PaymentsDataTable")
        .DataTable()
        .row(this)
        .data();

      let modal = $modal.open({
        templateUrl: "app/accounting-reports/payment-details-modal.html",
        controller: "PaymentDetailsModalInstanceCtrl",
        windowClass: "",
        size: "md",
        backdrop: true,
        scope: $scope,
      });

      // Show modal
      modal.result.then(
        () => {},
        () => {}
      );
    });
  },
]);
