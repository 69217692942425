/**
 * @generated SignedSource<<1010a5a69c861f967bc1ecb20712d98c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecureComVideoLevel = "NONE" | "STANDARD" | "STANDARD_PLUS_EIGHT" | "STANDARD_PLUS_FOUR";
export type SecureComVideoFormSetVideoLevelMutation$variables = {
  siteId: string;
  videoLevel: SecureComVideoLevel;
};
export type SecureComVideoFormSetVideoLevelMutation$data = {
  readonly setSecureComVideoLevelForSite: {
    readonly " $fragmentSpreads": FragmentRefs<"SecureComVideoForm_site">;
  };
};
export type SecureComVideoFormSetVideoLevelMutation = {
  response: SecureComVideoFormSetVideoLevelMutation$data;
  variables: SecureComVideoFormSetVideoLevelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "videoLevel"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "siteId",
    "variableName": "siteId"
  },
  {
    "kind": "Variable",
    "name": "videoLevel",
    "variableName": "videoLevel"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SecureComVideoFormSetVideoLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setSecureComVideoLevelForSite",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SecureComVideoForm_site"
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SecureComVideoFormSetVideoLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setSecureComVideoLevelForSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "securecomVideoLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "securecomNvrEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billingControlSystemId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SecureComCamera",
                "kind": "LinkedField",
                "name": "securecomCameras",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOnline",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manufacturer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "channelId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SecureComNvr",
                "kind": "LinkedField",
                "name": "securecomNvrs",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SecureComNvrVideoChannel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Site",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55a9ff194b864c0c338c5207e3d0b730",
    "id": null,
    "metadata": {},
    "name": "SecureComVideoFormSetVideoLevelMutation",
    "operationKind": "mutation",
    "text": "mutation SecureComVideoFormSetVideoLevelMutation(\n  $siteId: ID!\n  $videoLevel: SecureComVideoLevel!\n) {\n  setSecureComVideoLevelForSite(siteId: $siteId, videoLevel: $videoLevel) {\n    __typename\n    ... on Site {\n      ...SecureComVideoForm_site\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment SecureComCameras_secureComCamera on SecureComCamera {\n  id\n  name\n  status\n  isOnline\n  manufacturer\n  channelId\n}\n\nfragment SecureComCameras_site on Site {\n  id\n  billingControlSystemId\n  securecomCameras {\n    id\n    ...SecureComCameras_secureComCamera\n  }\n}\n\nfragment SecureComNvrs_secureComNvr on SecureComNvr {\n  id\n  name\n  status\n  channels {\n    id\n    name\n  }\n}\n\nfragment SecureComNvrs_site on Site {\n  id\n  billingControlSystemId\n  customer {\n    id\n  }\n  securecomNvrs {\n    id\n    ...SecureComNvrs_secureComNvr\n  }\n}\n\nfragment SecureComVideoForm_site on Site {\n  id\n  securecomVideoLevel\n  securecomNvrEnabled\n  ...SecureComCameras_site\n  ...SecureComNvrs_site\n}\n"
  }
};
})();

(node as any).hash = "0ceba1bf5d3bee7730136cbb2cc8b926";

export default node;
