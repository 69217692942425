import React, { useContext, useState } from "react";

interface SearchContextData {
  searchValues: Map<string, string>;
  setSearchValues: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  forceClear: boolean;
  setForceClear: React.Dispatch<React.SetStateAction<boolean>>;
  forceNav: boolean;
  toggleForceNav: () => void;
}
const SearchContext = React.createContext<SearchContextData>({
  searchValues: new Map<string, string>(),
  setSearchValues: () => {},
  forceClear: false,
  setForceClear: () => {},
  forceNav: false,
  toggleForceNav: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

interface SearchProviderProps {
  children: React.ReactNode;
}
export function SearchProvider(props: SearchProviderProps) {
  const { children } = props;
  const [searchValues, setSearchValues] = useState(new Map<string, string>());
  const [forceClear, setForceClear] = useState(false);
  const [forceNav, setForceNav] = useState(false);

  return (
    <SearchContext.Provider
      value={{
        searchValues: searchValues,
        setSearchValues: setSearchValues,
        forceClear: forceClear,
        setForceClear: setForceClear,
        forceNav: forceNav,
        toggleForceNav: () => setForceNav(!forceNav),
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
