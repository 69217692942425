import { motionDetectionRegionsSvgId } from "components/CameraEditCommon/constants";
import { Coordinates, RegionBounds } from "components/CameraEditCommon/types";
import {
  CoordinateRenderer,
  getClosestPoint,
  getEdgePoints,
  getPointForSlopeGivenX,
  getPointForSlopeGivenY,
  normalizePxX,
  normalizePxY,
  renderCoordinates,
  slope,
} from "components/CameraEditCommon/utils";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import styled from "styled-components";
import { VarHubCameraWithClientSchemaData } from "./types";

function CornerPoint(props: {
  x: number;
  y: number;
  active?: boolean;
  index: number;
  regionIndex: number;
  mouseDown: boolean;
}) {
  return (
    <g>
      <circle
        r={5}
        cx={props.x}
        cy={props.y}
        fill={props.active ? "blue" : "white"}
        stroke="blue"
        strokeWidth={2}
      />
      <circle
        id={`motion-detection-region-${props.regionIndex}-point-${props.index}`}
        r={10}
        cx={props.x}
        cy={props.y}
        fill="transparent"
        style={{ cursor: props.mouseDown ? "grabbing" : "grab" }}
      />
    </g>
  );
}

type Point = [number, number];

function Edge(props: {
  point1: Coordinates;
  point2: Coordinates;
  index: number;
  regionIndex: number;
  cameraId: string;
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  bounds: RegionBounds;
}) {
  const { point1, point2, bounds } = props;
  const relayEnv = useRelayEnvironment();
  const [x1, y1] = point1;
  const [x2, y2] = point2;
  const [newPointCoordinates, setNewPointCoordinates] =
    React.useState<Point | null>(null);
  const s = React.useMemo(() => slope([x1, y1])([x2, y2]), [x1, y1, x2, y2]);

  return (
    <>
      {newPointCoordinates && (
        <circle
          id={`motion-detection-region-${props.index}-new-point`}
          r={5}
          cx={props.renderX(newPointCoordinates[0])}
          cy={props.renderY(newPointCoordinates[1])}
          fill="white"
          stroke="blue"
          strokeWidth={2}
          opacity={0.8}
        />
      )}
      <line
        id={`motion-detection-region-${props.regionIndex}-edge-${props.index}`}
        x1={props.renderX(x1)}
        y1={props.renderY(y1)}
        x2={props.renderX(x2)}
        y2={props.renderY(y2)}
        strokeWidth={25}
        stroke="transparent"
        style={{ cursor: "copy" }}
        onMouseMove={(event) => {
          const { clientX, clientY } = event.nativeEvent;
          const svg = document.getElementById(motionDetectionRegionsSvgId);
          if (!svg) {
            return;
          }

          const svgRect = svg.getBoundingClientRect();
          const cursorX = normalizePxX(bounds)(svg)(clientX - svgRect.x);
          const cursorY = normalizePxY(bounds)(svg)(clientY - svgRect.y);

          if (s === Infinity) {
            setNewPointCoordinates([x1, cursorY]);
          } else {
            const p1 = getPointForSlopeGivenX([x1, y1])(s)(cursorX);
            const p2 = getPointForSlopeGivenY([x1, y1])(s)(cursorY);
            setNewPointCoordinates(getClosestPoint([cursorX, cursorY])(p1)(p2));
          }
        }}
        onMouseLeave={() => {
          setNewPointCoordinates(null);
        }}
        onMouseDown={(event) => {
          if (newPointCoordinates) {
            event.nativeEvent.stopPropagation();
            relayEnv.commitUpdate((store) => {
              const camera = store.get(
                props.cameraId
              ) as RecordProxy<VarHubCameraWithClientSchemaData>;
              const region =
                camera.getLinkedRecords("detectionRegions")[props.regionIndex];
              const geometry = region.getLinkedRecord("geometry");
              const coordinates = [...geometry.getValue("coordinates")[0]];
              const newIndex = props.index + 1;
              coordinates.splice(newIndex, 0, newPointCoordinates);
              geometry.setValue([coordinates], "coordinates");
              region.setValue([newIndex], "activeCoordinateIndexes");
              camera.setValue(true, "mouseDown");
            });
          }
        }}
      />
    </>
  );
}

function DetectionRegion(props: {
  cameraId: string;
  isActive: boolean;
  mouseDown: boolean;
  aspectRatio: number;
  color: string;
  coordinates: Coordinates[];
  index: number;
  activeCoordinateIndexes: number[];
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  bounds: RegionBounds;
}) {
  const {
    cameraId,
    isActive,
    color,
    coordinates,
    index,
    activeCoordinateIndexes,
    renderX,
    renderY,
    bounds,
    mouseDown,
  } = props;
  // GeoJSON requires a Polygon's first and last points to be identical,
  // which is inconvenient for SVGs. So we can just ignore the last point when rendering.
  const coordinatesToRender = coordinates.slice(0, coordinates.length - 1);
  const renderedPoints = coordinatesToRender.map(
    renderCoordinates(renderX)(renderY)
  );

  return (
    <g>
      <RegionPath
        id={`motion-detection-region-${index}`}
        fill={color}
        opacity={0.8}
        d={`${renderedPoints.reduce((d, [x, y], index) => {
          return index === 0 ? `M ${x} ${y}` : `${d} L ${x} ${y}`;
        }, "")} Z`}
        isActive={isActive}
      />
      {isActive && (
        <>
          {!mouseDown &&
            getEdgePoints(coordinatesToRender).map(
              ([point1, point2], edgeIndex) => (
                <Edge
                  key={`edge-${edgeIndex}`}
                  point1={point1}
                  point2={point2}
                  index={edgeIndex}
                  regionIndex={index}
                  renderX={renderX}
                  renderY={renderY}
                  bounds={bounds}
                  cameraId={cameraId}
                />
              )
            )}
          {renderedPoints.map(([x, y], pointIndex) => (
            <CornerPoint
              x={x}
              y={y}
              key={`corner-${pointIndex}`}
              active={new Set(activeCoordinateIndexes).has(pointIndex)}
              index={pointIndex}
              regionIndex={index}
              mouseDown={mouseDown}
            />
          ))}
        </>
      )}
    </g>
  );
}

export default DetectionRegion;

const RegionPath = styled.path<{ isActive: boolean }>`
  cursor: ${({ isActive }) => (isActive ? "move" : "default")};
`;
