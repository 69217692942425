App.controller("MassProgrammingCtrl", [
  "$rootScope",
  "$scope",
  "$state",
  "$stateParams",
  "UserService",
  "DashboardDataService",
  "Panel",
  "MassProgrammingService",
  "PanelDefinitionService",
  "$modal",
  "DealerODataAPI",
  "$q",
  "$timeout",
  "TIMEOUTS",
  function (
    $rootScope,
    $scope,
    $state,
    $stateParams,
    UserService,
    DashboardDataService,
    Panel,
    MassProgrammingService,
    PanelDefinitionService,
    $modal,
    DealerODataAPI,
    $q,
    $timeout,
    TIMEOUTS
  ) {
    // deciding programming state
    if ($state.is("app.dealer.mass_programming_new")) {
      $scope.state = "new";
    } else {
      $scope.state = "view";
    }
    $scope.reloadPage = reloadPage;
    $scope.isBusy = true;

    $scope.enabled = {};
    $scope.selectAllSystems = selectAllSystems;
    $scope.deselectAllSystems = deselectAllSystems;
    $scope.xtFamily = false;
    $scope.xt75Family = false;
    $scope.xrFamily = false;
    $scope.xfFamily = false;
    $scope.cellComFamily = false;
    $scope.tms6Family = false;
    $scope.panelProg = {};
    $scope.mass_programming_data = {};
    $scope.selectedSystems = [];
    $scope.customers = {};
    $scope.jobGroupPanelData = {};

    $scope.Panel = {};

    $scope.pagination = {};
    $scope.pagination.itemsPerPage = 30;
    $scope.totalDealerSystems = {
      count: 0,
    };

    const isInternational = UserService.enabledDistributionSubscriber();

    let PanelDefs = {
      XT: {
        baseModel: isInternational ? "XT30" : "XT50",
        PDS: null,
      },
      XR: {
        baseModel: "XR550",
        PDS: null,
      },
      CELLCOM: {
        baseModel: "DualCom",
        PDS: null,
      },
      XF6: {
        baseModel: "XF6_500",
        PDS: null,
      },
      XT75: {
        baseModel: "XT75",
        PDS: null,
      },
      TMS6: {
        baseModel: "TMS6",
        PDS: null,
      },
    };

    $scope.setFamily = function (type) {
      let list_of_families = [
        $scope.xtFamily,
        $scope.xt75Family,
        $scope.xrFamily,
        $scope.xfFamily,
        $scope.cellComFamily,
        $scope.tms6Family,
      ];

      list_of_families.fill(false); // Verify each family is set to false initially

      for (let i = 0; i < list_of_families.length; i++) {
        if (type === "XT") {
          $scope.xtFamily = true;
          break;
        } else if (type === "XT75") {
          $scope.xt75Family = true;
          break;
        } else if (type === "XR") {
          $scope.xrFamily = true;
          break;
        } else if (type === "XF") {
          $scope.xfFamily = true;
          break;
        } else if (type === "CELLCOM") {
          $scope.cellComFamily = true;
          break;
        } else if (type === "TMS6") {
          $scope.cellComFamily = true;
          break;
        }
      }
    };

    $scope.setStatusColorClass = function (status) {
      let added_class = "";
      switch (status) {
        case "fail":
          added_class = "text-danger";
          break;
        case "success":
          added_class = "text-success";
          break;
        default:
          added_class = "text-information";
          break;
      }
      return added_class;
    };

    function getDealerSystems() {
      let dealerId = UserService.dealer_id;
      DashboardDataService.getDealerSystemsForMassProg(dealerId)
        .then(
          // using this call because we need the customer name associated with the systems
          function (data) {
            let allDealerCustomers = [];
            for (let i in data) {
              data[i].isChecked = false;
              if (
                data[i].panel_hardware_model === "XR150" ||
                data[i].panel_hardware_model === "XR350" ||
                data[i].panel_hardware_model === "XR550"
              ) {
                data[i]["panel_hardware_class"] = "XR";
                allDealerCustomers.push(data[i]);
              }
              if (
                data[i].panel_hardware_model === "XTLP" ||
                data[i].panel_hardware_model === "XT30" ||
                data[i].panel_hardware_model === "XT50"
              ) {
                data[i]["panel_hardware_class"] = "XT";
                allDealerCustomers.push(data[i]);
              }
              if (
                data[i].panel_hardware_model === "CellComSL" ||
                data[i].panel_hardware_model === "CellComEX" ||
                data[i].panel_hardware_model === "DualCom" ||
                data[i].panel_hardware_model === "iComSL"
              ) {
                data[i]["panel_hardware_class"] = "CELLCOM";
                allDealerCustomers.push(data[i]);
              }
              if (
                data[i].panel_hardware_model === "XF6_500" ||
                data[i].panel_hardware_model === "XF6_100"
              ) {
                data[i]["panel_hardware_class"] = "XF6";
                allDealerCustomers.push(data[i]);
              }
              if (data[i].panel_hardware_model === "XT75") {
                data[i]["panel_hardware_class"] = "XT75";
                allDealerCustomers.push(data[i]);
              }
              if (data[i].panel_hardware_model === "TMS6") {
                data[i]["panel_hardware_class"] = "TMS6";
                allDealerCustomers.push(data[i]);
              }
            }
            $scope.customers.groupedDealerCustomers = allDealerCustomers.reduce(
              function (newArray, currentSystem) {
                let customerNameExist = newArray.findIndex(function (item) {
                  return (
                    item.systems[0].customer_name ===
                    currentSystem.customer_name
                  );
                });
                // if the arrays don't have matching customer_name, create a new one
                if (customerNameExist === -1) {
                  let newCustomerMatch = {
                    customer_name: currentSystem.customer_name,
                    systems: [currentSystem],
                    isChecked: false,
                  };
                  newArray.push(newCustomerMatch);
                  // if customer_name is the same, add new system to it
                } else {
                  newArray[customerNameExist].systems.push(currentSystem);
                }
                return newArray;
              },
              []
            );
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to get Systems for Dealer",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.shownFullConcept = function (concept) {
      return $scope.concepts.includes(concept.key);
    };

    $scope.xtORxr = function (type) {
      deselectAllSystems($scope.customers.groupedDealerCustomers); // Reset customer_name to false when user selects different panel type
      if (type) {
        $scope.xrFamily =
          $scope.xtFamily =
          $scope.xt75Family =
          $scope.cellComFamily =
          $scope.xfFamily =
          $scope.tms6Family =
            false;
        switch (type) {
          case "XT":
            $scope.concepts = [
              "communication",
              "system_options",
              "remote_options",
              "system_reports",
              "bell_options",
              "output_options",
              "lockout_code",
              "security_grade",
            ];
            $scope.setFamily(type);
            break;
          case "XR":
            $scope.concepts = [
              "communication_paths",
              "system_options",
              "remote_options",
              "system_reports",
              "bell_options",
              "output_options",
              "status_list",
              "lockout_code",
              "security_grade",
            ];
            $scope.setFamily(type);
            break;
          case "CELLCOM":
            $scope.concepts = [
              "communication",
              "remote_options",
              "system_reports",
              "system_options",
              "output_options",
              "lockout_code",
            ];
            $scope.setFamily(type);
            break;
          case "TMS6":
            $scope.concepts = [
              "communication",
              "remote_options",
              "system_reports",
              "system_options",
              "output_options",
              "lockout_code",
            ];
            $scope.setFamily(type);
            break;
          case "XF6":
            $scope.concepts = [
              "communication_paths",
              "system_options",
              "remote_options",
              "bell_options",
              "output_options",
              "lockout_code",
            ];
            $scope.setFamily(type);
            break;
          case "XT75":
            $scope.concepts = [
              "communication_paths",
              "system_options",
              "remote_options",
              "bell_options",
              "output_options",
              "lockout_code",
              "system_reports",
            ];
            $scope.setFamily(type);
            break;
          default:
            $scope.concepts = [];
            $scope.setFamily(type);
            break;
        }
        $scope.Panel = new Panel(
          null,
          PanelDefs[type].baseModel,
          PanelDefs[type].PDS.firmwareVersion,
          $scope.concepts,
          PanelDefs[type].PDS
        );
        $scope.Panel.panel_hardware_class = type;
        if (type === "XR" || type === "XF6" || type === "XT75") {
          $scope.Panel.communication_paths =
            type === "XT75"
              ? [
                  { number: "01" },
                  { number: "02" },
                  { number: "03" },
                  { number: "04" },
                ]
              : [
                  { number: "01" },
                  { number: "02" },
                  { number: "03" },
                  { number: "04" },
                  { number: "05" },
                  { number: "06" },
                  { number: "07" },
                  { number: "08" },
                ];
          $scope.Panel.communication_paths.isArray = true;
        }
      }
    };

    /**
     * Delete or initialize the value of the referenced field from the conceptObject, will delete if initialize value is false
     * @param conceptObject
     * @param number
     * @param field
     * @param defaultValue
     * @param initialize
     *
     */

    $scope.deleteOrInitializeMassProgrammingValue = function (
      conceptObject,
      field,
      defaultValue,
      initialize,
      number
    ) {
      if (number) {
        number = number - 1; //to have com path number match with array item.
        if (initialize) {
          if (!$scope.Panel[conceptObject]) {
            $scope.Panel[conceptObject] = {};
          }
          if (!$scope.Panel[conceptObject][number]) {
            $scope.Panel[conceptObject][number] = {};
          }

          $scope.Panel[conceptObject][number][field] = defaultValue;
        } else {
          delete $scope.Panel[conceptObject][number][field];
        }
      } else {
        if (initialize) {
          if (!$scope.Panel[conceptObject]) {
            $scope.Panel[conceptObject] = {};
          }
          $scope.Panel[conceptObject][field] = defaultValue;
        } else {
          delete $scope.Panel[conceptObject][field];
        }
      }
    };

    function reloadPage() {
      $state.forceReload();
    }

    $scope.openCustomerSelectModal = function () {
      $scope.isBusy = true;
      // $scope.selectedSystems = [];
      $scope.customerSelectModal = $modal.open({
        templateUrl:
          "app/dealer/mass-programming/mass-prog-customer-select-modal.html",
        controller: "CustomerSelectModalCtrl",
        size: "md",
        scope: $scope,
      });
      $scope.customerSelectModal.result
        .then(
          function (reason) {
            $scope.isBusy = false;
          },
          function () {
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.omitConceptDisplay = function (field) {
      return field.$key === "DISPLAY";
    };

    $scope.omitAccountNumber = function (field) {
      return field.$key === "acct_num";
    };

    $scope.omitRemoteKey = function (field) {
      return field.$key === "crypt_key";
    };

    $scope.omitArmingModeCellCom = function (field) {
      if ($scope.Panel.panel_model === "DualCom") {
        return field.$key === "arm_mode";
      }
    };

    $scope.omitCustomerName = function (customers) {
      let xrIsIncluded = customers.systems.findIndex(function (c) {
        return c.panel_hardware_class === "XR";
      });
      let xtIsIncluded = customers.systems.findIndex(function (c) {
        return c.panel_hardware_class === "XT";
      });
      let cellComIsIncluded = customers.systems.findIndex(function (c) {
        return c.panel_hardware_class === "CELLCOM";
      });
      let xfIsIncluded = customers.systems.findIndex(function (c) {
        return c.panel_hardware_class === "XF6";
      });
      let xt75IsIncluded = customers.systems.findIndex(function (c) {
        return c.panel_hardware_class === "XT75";
      });
      let tms6IsIncluded = customers.systems.findIndex(function (c) {
        return c.panel_hardware_class === "TMS6";
      });
      for (let cust of customers.systems) {
        if ($scope.xrFamily) {
          if (xrIsIncluded === -1 && cust.panel_hardware_class !== "XR") {
            return customers.customer_name;
          }
        }
        if ($scope.xtFamily) {
          if (xtIsIncluded === -1 && cust.panel_hardware_class !== "XT") {
            return customers.customer_name;
          }
        }
        if ($scope.xt75Family) {
          if (xt75IsIncluded === -1 && cust.panel_hardware_class !== "XT75") {
            return customers.customer_name;
          }
        }
        if ($scope.cellComFamily) {
          if (
            cellComIsIncluded === -1 &&
            cust.panel_hardware_class !== "CELLCOM"
          ) {
            return customers.customer_name;
          }
        }
        if ($scope.xfFamily) {
          if (xfIsIncluded === -1 && cust.panel_hardware_class !== "XF6") {
            return customers.customer_name;
          }
        }
        if ($scope.tms6Family) {
          if (tms6IsIncluded === -1 && cust.panel_hardware_class !== "TMS6") {
            return customers.customer_name;
          }
        }
      }
    };

    $scope.panelTypeFilter = function (system) {
      if ($scope.xrFamily)
        return (
          system.panel_hardware_model === "XR150" ||
          system.panel_hardware_model === "XR350" ||
          system.panel_hardware_model === "XR550"
        );
      if ($scope.xtFamily)
        return (
          system.panel_hardware_model === "XTLP" ||
          system.panel_hardware_model === "XT30" ||
          system.panel_hardware_model === "XT50"
        );
      if ($scope.cellComFamily)
        return (
          system.panel_hardware_model === "CellComSL" ||
          system.panel_hardware_model === "CellComEX" ||
          system.panel_hardware_model === "DualCom" ||
          system.panel_hardware_model === "iComSL"
        );
      if ($scope.xfFamily)
        return (
          system.panel_hardware_model === "XF6_500" ||
          system.panel_hardware_model === "XF6_100"
        );
      if ($scope.xt75Family) return system.panel_hardware_model === "XT75";
      if ($scope.tms6Family) return system.panel_hardware_model === "TMS6";
    };

    $scope.selectSystemsForCustomer = function (customer, customerCheck) {
      for (let system of customer) {
        if ($scope.xrFamily) {
          if (system.panel_hardware_class === "XR") {
            system.isChecked = customerCheck;
          } else {
            system.isChecked = false;
          }
        }
        if ($scope.xtFamily) {
          if (system.panel_hardware_class === "XT") {
            system.isChecked = customerCheck;
          } else {
            system.isChecked = false;
          }
        }
        if ($scope.cellComFamily) {
          if (system.panel_hardware_class === "CELLCOM") {
            system.isChecked = customerCheck;
          } else {
            system.isChecked = false;
          }
        }
        if ($scope.xfFamily) {
          if (system.panel_hardware_class === "XF6") {
            system.isChecked = customerCheck;
          } else {
            system.isChecked = false;
          }
        }
        if ($scope.xt75Family) {
          if (system.panel_hardware_class === "XT75") {
            system.isChecked = customerCheck;
          } else {
            system.isChecked = false;
          }
        }
        if ($scope.tms6Family) {
          if (system.panel_hardware_class === "TMS6") {
            system.isChecked = customerCheck;
          } else {
            system.isChecked = false;
          }
        }
      }
    };

    function selectAllSystems(controlSystemList) {
      for (let customerGroup of controlSystemList) {
        for (let system of customerGroup.systems) {
          if ($scope.xrFamily) {
            if (system.panel_hardware_class === "XR") {
              system.isChecked = true;
              customerGroup.isChecked = true;
            } else {
              system.isChecked = false;
            }
          }
          if ($scope.xtFamily) {
            if (system.panel_hardware_class === "XT") {
              system.isChecked = true;
              customerGroup.isChecked = true;
            } else {
              system.isChecked = false;
            }
          }
          if ($scope.cellComFamily) {
            if (system.panel_hardware_class === "CELLCOM") {
              system.isChecked = true;
              customerGroup.isChecked = true;
            } else {
              system.isChecked = false;
            }
          }
          if ($scope.xfFamily) {
            if (system.panel_hardware_class === "XF6") {
              system.isChecked = true;
              customerGroup.isChecked = true;
            } else {
              system.isChecked = false;
            }
          }
          if ($scope.xt75Family) {
            if (system.panel_hardware_class === "XT75") {
              system.isChecked = true;
              customerGroup.isChecked = true;
            } else {
              system.isChecked = false;
            }
          }
          if ($scope.tms6Family) {
            if (system.panel_hardware_class === "TMS6") {
              system.isChecked = true;
              customerGroup.isChecked = true;
            } else {
              system.isChecked = false;
            }
          }
        }
      }
    }

    function deselectAllSystems(controlSystems) {
      if (controlSystems) {
        for (let controlSystem of controlSystems) {
          for (let system of controlSystem.systems) {
            system.isChecked = false;
            controlSystem.isChecked = false;
          }
        }
      }
    }

    $scope.anySystemSelected = function (controlSystems) {
      if (controlSystems) {
        for (let controlSystem of controlSystems) {
          for (let system of controlSystem.systems) {
            if (system.isChecked) {
              return system.isChecked;
            }
          }
        }
      }
      return false;
    };

    $scope.anyProgrammingSelected = function () {
      return $scope.enabled.Panel;
    };
    $scope.parseCaptureToCidAndHostFormat = function (capture) {
      //this is the key in which we can use to find the correct values for what's chosen
      //the possible values of each capture format options are:
      // DMP: "0",
      // CID: "1",
      // 4-2: "2",
      // SIA: "3"
      //These values will correspond to the key array below based on index
      const captureFormatKey = [
        { cid: "0", host: "0" },
        { cid: "1", host: "0" },
        { cid: "0", host: "1" },
        { cid: "0", host: "2" },
      ];
      return captureFormatKey[capture];
    };
    $scope.isCidFormatAndHostCommFormat = function (field) {
      return field.$key === "host_comm_format" || field.$key === "cid_format";
    };
    $scope.sendMassUpdate = function (controlSystems) {
      var deferred = $q.defer();
      let systemIds = [];
      for (let customer of controlSystems) {
        for (let system of customer.systems) {
          if (system.isChecked) {
            //if the system is a cellcom and not cell only and the user makes changes to capture format
            //then we have to manually add the fields for cid and host comm format

            if (
              system.panel_hardware_class === "CELLCOM" &&
              $scope?.enabled?.Panel?.system_options?.capture_format === true
            ) {
              $scope.Panel.system_options.cid_format =
                $scope.parseCaptureToCidAndHostFormat(
                  $scope.Panel.system_options.capture_format
                ).cid;
              $scope.Panel.system_options.host_comm_format =
                $scope.parseCaptureToCidAndHostFormat(
                  $scope.Panel.system_options.capture_format
                ).host;
              $scope.enabled.Panel.system_options.cid_format = true;
              $scope.enabled.Panel.system_options.host_comm_format = true;
            }
            systemIds.push(system.control_system_id);
          }
        }
      }
      MassProgrammingService.massProgramPanels(
        $scope.Panel,
        systemIds,
        $scope.enabled
      )
        .then(
          function (data) {
            $timeout(function () {
              MassProgrammingService.getAllMassProgrammingForDealer(
                UserService.dealer_id
              ).then(
                function () {
                  $rootScope.alerts.push({
                    type: "success",
                    text: "Mass Programming Change Submitted",
                  });
                  $state.go("app.dealer.mass_programming", {
                    dealer_id: UserService.dealer_id,
                  });
                },
                function () {}
              );
            }, TIMEOUTS.REDUNDANCY);

            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Submitting Mass Programming Change",
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    function getMassProgrammingViewData() {
      //Loads the data for a view
      if ($scope.state == "view") {
        MassProgrammingService.getMassProgrammingStatus($stateParams.job_id)
          .then(
            function (data) {
              $scope.massProgrammingViewData = data[0];
              $scope.groupOutput = angular.fromJson(
                $scope.massProgrammingViewData.GroupOutput
              );

              $scope.groupData = angular.fromJson(
                $scope.massProgrammingViewData.GroupData
              );

              let basePanel = $scope.groupData.panel_model;
              let version = $scope.groupData.panel_version;
              let concepts = $scope.groupData.concepts;

              $scope.Panel = new Panel(
                null,
                basePanel,
                version,
                concepts,
                PanelDefs[$scope.groupData.panel_hardware_class].PDS
              );

              $scope.Panel = MassProgrammingService.applyGroupDataToPanel(
                $scope.Panel,
                $scope.groupData
              );
            },
            function (error) {}
          )
          .catch(function (error) {
            console.error(error);
          });

        MassProgrammingService.getMassProgrammingJobPanelsStatus(
          $stateParams.job_id
        )
          .then(
            function (jobGroupPanelData) {
              for (let job in jobGroupPanelData) {
                job_data = angular.fromJson(jobGroupPanelData[job].JobData);

                jobGroupPanelData[job].customer_name = job_data.customer_name;
                jobGroupPanelData[job].system_name = job_data.system_name;
              }
              $scope.jobGroupPanelData = jobGroupPanelData;
            },
            function (error) {}
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    }

    function initPanelDefs() {
      angular.forEach(Object.keys(PanelDefs), function (series) {
        initPanelDef(series, UserService.enabledDistributionSubscriber());
      });
    }

    function initPanelDef(series, international) {
      PanelDefinitionService.generateHighestVersion(
        PanelDefs[series].baseModel,
        international
      )
        .then(
          function (data) {
            PanelDefs[series].PDS = data;
            if (PanelDefs[series].baseModel === "DualCom") {
              PanelDefs[series].PDS.panel_def.CONCEPTS.system_options[
                "capture_format"
              ] = {
                VALUES: {
                  DMP: "0",
                  CID: "1",
                  "4-2": "2",
                  SIA: "3",
                },
                DISPLAY: {
                  Data_Type: "List",
                  NAME: "Capture Format",
                  ORDER: 29,
                  TOOLTIP:
                    "Allows selection of DMP or Capture format in system options of Comm Series Panels.",
                },
              };
            }
            if (data.hardwareProperties.Model !== PanelDefs[series].baseModel) {
              console.warn(
                `PanelDefs[${series}].baseModel (${PanelDefs[series].baseModel}) does not match compiled panel def model (${data.hardwareProperties.Model})`
              );
            }
          },
          function (error) {
            console.error(
              `error generating ${series} panel definition service: ${angular.toJson(
                error
              )}`
            );
            $rootScope.alerts.push({
              type: "error",
              text: `error generating ${series} panel definition`,
            });
          }
        )
        .catch(function (error) {
          console.error(`Caught error: ${error}`);
        });
    }

    function init() {
      getDealerSystems();
      getMassProgrammingViewData();
      initPanelDefs();
    }

    init();
  },
]);

App.controller("CustomerSelectModalCtrl", [
  "$scope",
  "$modalInstance",
  "$sanitize",
  function ($scope, $modalInstance, $sanitize) {
    let initialGroupedDealerCustomers = angular.copy(
      $scope.customers.groupedDealerCustomers
    );
    $scope.selectedDealerSystems =
      $scope.customers.groupedDealerCustomers.length;
    $scope.totalDealerSystems.count = initialGroupedDealerCustomers.length;

    $scope.saveSelections = function () {
      $modalInstance.close();
    };

    $scope.cancelSelections = function () {
      $scope.customers.groupedDealerCustomers = initialGroupedDealerCustomers;
      $modalInstance.close();
    };
  },
]);
