/**
 * @generated SignedSource<<9bc412823b6a4ec1f186bfccb499a392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTFullProgramming_controlSystem$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem" | "FullProgrammingForm_controlSystem" | "ProgrammingTemplateForm_controlSystem" | "XTAreaInformationProgrammingConceptFormNavButton_controlSystem" | "XTAreaInformationProgrammingConceptForm_controlSystem" | "XTBellOptionsProgrammingConceptForm_controlSystem" | "XTCommunicationProgrammingConceptForm_controlSystem" | "XTDeviceSetupProgrammingConceptFormNavButton_controlSystem" | "XTDeviceSetupProgrammingConceptForm_controlSystem" | "XTKeyfobProgrammingConceptFormNavButton_controlSystem" | "XTKeyfobProgrammingConceptForm_controlSystem" | "XTLockoutCodeProgrammingConceptForm_controlSystem" | "XTMessagingSetupProgrammingConceptForm_controlSystem" | "XTNetworkOptionsProgrammingConceptForm_controlSystem" | "XTOutputInformationProgrammingConceptFormNavButton_controlSystem" | "XTOutputInformationProgrammingConceptForm_controlSystem" | "XTOutputOptionsProgrammingConceptForm_controlSystem" | "XTRemoteOptionsProgrammingConceptForm_controlSystem" | "XTSecurityGradeProgrammingConceptForm_controlSystem" | "XTSystemOptionsProgrammingConceptForm_controlSystem" | "XTSystemReportsProgrammingConceptForm_controlSystem" | "XTZoneInformationsProgrammingConceptFormNavButton_controlSystem" | "XTZoneInformationsProgrammingConceptForm_controlSystem">;
  readonly " $fragmentType": "XTFullProgramming_controlSystem";
};
export type XTFullProgramming_controlSystem$key = {
  readonly " $data"?: XTFullProgramming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTFullProgramming_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTFullProgramming_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FullProgrammingForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTCommunicationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTNetworkOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTDeviceSetupProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTDeviceSetupProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTLockoutCodeProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTRemoteOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTMessagingSetupProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTSystemReportsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTSystemOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTOutputOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTOutputInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTOutputInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTBellOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTAreaInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTAreaInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTZoneInformationsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTZoneInformationsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTKeyfobProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTKeyfobProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTSecurityGradeProgrammingConceptForm_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "847dd7abe7ffb1af253f65ef4f92260a";

export default node;
