import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathSendPathField_communicationPath$key } from "./__generated__/CommPathSendPathField_communicationPath.graphql";

export const commPathSendPathFieldId = (number: string) =>
  `comm-path-send-path-${number}`;

function CommPathSendPathField() {
  const [{ id, number, sendPath, commType }, updateCommPath] =
    useCommPathFragment<CommPathSendPathField_communicationPath$key>(
      graphql`
        fragment CommPathSendPathField_communicationPath on CommunicationPath {
          id
          number
          sendPath
          commType
        }
      `
    );

  const fieldId = commPathSendPathFieldId(String(number));
  const disabled = commType === "CID";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Send Path Information"
      tooltip="Send information about the path with each message sent over this path."
      disabled={disabled}
    >
      <Switch
        label="Send Path Information"
        id={fieldId}
        name={id}
        checked={sendPath}
        disabled={disabled}
        onChange={() => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(!sendPath, "sendPath");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathSendPathField;
