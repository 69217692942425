App.factory("ModemUpgradeStatsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/ModemUpgrade/Statistics",
      {},
      {
        getModemUpgradeStats: { method: "GET" },
      }
    );
  },
]);
