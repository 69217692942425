import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const PcLogReportsContext = React.createContext<any>({});

export function PcLogReportsContextProvider(props: {
  pcLogReports: any;
  children: React.ReactNode;
}) {
  return (
    <PcLogReportsContext.Provider value={props.pcLogReports}>
      {props.children}
    </PcLogReportsContext.Provider>
  );
}

export function usePcLogReportsFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const pcLogReports = React.useContext(PcLogReportsContext);

  const data = useFragment(fragmentInput, pcLogReports as TKey);

  const { id } = useFragment(
    graphql`
      fragment PcLogReportsContext_pcLogReports on PcLogReports {
        id
      }
    `,
    pcLogReports as any
  );

  const update = (
    updater: (pcLogReports: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
