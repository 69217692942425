// styles
import styled from "styled-components/macro";

const SettingsGridKeypad = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, min-content);
  grid-template-areas:
    "title--preview-area"
    "img--logo-preview-area"
    "form--logo-upload-area"
    "title--contact-screen-area"
    "img--contact-preview-area"
    "form--contact-edit-area";
  width: 100%;
  min-height: 0%;
  grid-gap: 2.4rem;
  overflow: hidden;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);
    grid-template-areas:
      "title--preview-area title--contact-screen-area"
      "img--logo-preview-area img--contact-preview-area"
      "form--logo-upload-area form--contact-edit-area";
  }
`;
const TitlePreviewArea = styled.div`
  grid-area: title--preview-area;
  display: flex;
  justify-content: flex-start;
`;

const FormLogoUploadArea = styled.div`
  grid-area: form--logo-upload-area;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
const TitleContactScreenArea = styled.div`
  grid-area: title--contact-screen-area;
  display: flex;
  justify-content: flex-start;
`;

const FormContactEditArea = styled.div`
  grid-area: form--contact-edit-area;
  justify-content: center;
  align-items: flex-start;
`;

const FormContactEdit = styled.form`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 1.528571429;
  color: #3a3f51;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dde6e9;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

const FormGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.8rem;
`;

const Label = styled.label`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1.6rem;
  width: max-content;
  white-space: nowrap;
`;

const SevenInchKeyPadLogoPreviewArea = styled.div`
  position: relative;
  grid-area: img--logo-preview-area;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  max-height: 100%;
`;

const SevenInchKeyPadContactPreviewAreaSample = styled.div`
  position: relative;
  grid-area: img--contact-preview-area;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  max-height: 100%;
`;

const InfoText = styled.em`
  font-size: 1.2rem;
  color: #3a3f51;
`;

const DealerLogoArea = styled.div`
  position: absolute;
  top: 0.4vh;
  left: 1vw;
  width: 26.2%;
  height: auto;
  z-index: 1;
  @media (min-width: 768px) {
    left: 0.4vw;
  }
  @media (min-width: 1200px) {
    left: 0.3vw;
  }
`;

const DealerExampleLogo = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
`;

const ContactInformationArea = styled.div`
  position: absolute;
  overflow: hidden;
  top: 9.7vw;
  left: 0;
  width: 100%;
  min-height: 0%;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, min-content);
  justify-content: center;
  align-items: center;
  grid-gap: 0.2rem;
  background-color: transparent;
  font-size: 2.7vw;
  @media (min-width: 445px) {
    top: 9.9vw;
    font-size: 3.2vw;
  }
  @media (min-width: 768px) {
    top: 5.2vw;
    font-size: 1.7vw;
  }
  @media (min-width: 1200px) {
    top: 4vw;
    font-size: 1.4vw;
  }
`;

const ContactText = styled.div`
  display: block;

  color: white;
  text-align: center;
`;

const ContactTextEmail = styled.div`
  display: block;

  color: white;
  text-align: center;
  margin-bottom: 1rem;
`;

const AddressTextArea = styled.div`
  display: block;
  margin-top: 0rem;
`;

const AddressLine = styled.div`
  display: block;

  color: white;
  text-align: center;
  margin-bottom: -0.1em;
`;

// export the styles for the component
export {
  AddressLine,
  AddressTextArea,
  ContactInformationArea,
  ContactText,
  ContactTextEmail,
  DealerExampleLogo,
  DealerLogoArea,
  FormContactEdit,
  FormContactEditArea,
  FormGroup,
  FormLogoUploadArea,
  InfoText,
  Input,
  Label,
  SettingsGridKeypad,
  SevenInchKeyPadContactPreviewAreaSample,
  SevenInchKeyPadLogoPreviewArea,
  TitleContactScreenArea,
  TitlePreviewArea,
};
