import graphql from "babel-plugin-relay/macro";
import DefaultAdvancedFields from "components/AdvancedFields";
import CommPathNumberField from "components/FullProgramming/common/CommPathFields/CommPathNumberField";
import CommPathReceiverIpAddressField from "components/FullProgramming/common/CommPathFields/CommPathReceiverIpAddressField";
import CommPathReceiverPortField from "components/FullProgramming/common/CommPathFields/CommPathReceiverPortField";
import * as React from "react";
import { useFragment } from "react-relay";
import styled from "styled-components/macro";
import CommPathCheckInMinutesField from "../../common/CommPathFields/CommPathCheckInMinutesField";
import CommPathCommTypeField from "../../common/CommPathFields/CommPathCommTypeField";
import { CommPathContextProvider } from "../../common/CommPathFields/CommPathContext";
import CommPathDuplicateAlarmsField from "../../common/CommPathFields/CommPathDuplicateAlarmsField";
import CommPathFailTestHoursField from "../../common/CommPathFields/CommPathFailTestHoursField";
import CommPathFailTimeField from "../../common/CommPathFields/CommPathFailTimeField";
import CommPathProtocolField from "../../common/CommPathFields/CommPathProtocolField";
import CommPathRetryTimeField from "../../common/CommPathFields/CommPathRetryTimeField";
import CommPathSendPathField from "../../common/CommPathFields/CommPathSendPathField";
import CommPathTestReportField from "../../common/CommPathFields/CommPathTestReportField";
import CommPathTestTimeField from "../../common/CommPathFields/CommPathTestTimeField";
import CommPathTypeField from "../../common/CommPathFields/CommPathTypeField";
import CommPathUseCheckInField from "../../common/CommPathFields/CommPathUseCheckInField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { XFCommPathFields_communicationPath$key } from "./__generated__/XFCommPathFields_communicationPath.graphql";

const AdvancedFields = styled(DefaultAdvancedFields)`
  margin-top: var(--measure-3x);
`;

function XFCommPathFields(props: {
  commPath: XFCommPathFields_communicationPath$key;
}) {
  const data = useFragment(
    graphql`
      fragment XFCommPathFields_communicationPath on CommunicationPath {
        id
        ...CommPathContext_communicationPath
        ...CommPathNumberField_communicationPath
        ...CommPathCommTypeField_communicationPath
        ...CommPathTypeField_communicationPath
        ...CommPathTestReportField_communicationPath
        ...CommPathTestTimeField_communicationPath
        ...CommPathSendPathField_communicationPath
        ...CommPathUseCheckInField_communicationPath
        ...CommPathCheckInMinutesField_communicationPath
        ...CommPathFailTimeField_communicationPath
        ...CommPathProtocolField_communicationPath
        ...CommPathRetryTimeField_communicationPath
        ...CommPathFailTestHoursField_communicationPath
        ...CommPathDuplicateAlarmsField_communicationPath
        ...CommPathReceiverIpAddressField_communicationPath
        ...CommPathReceiverPortField_communicationPath
      }
    `,
    props.commPath
  );

  return (
    <CommPathContextProvider commPath={data}>
      <ProgrammingConceptForm.Fields>
        <CommPathNumberField />
        <CommPathCommTypeField />
        <CommPathTypeField />
        <CommPathTestReportField />
        <CommPathTestTimeField />
        <CommPathUseCheckInField />
        <CommPathCheckInMinutesField />
        <CommPathFailTimeField />
        <CommPathReceiverIpAddressField />
        <CommPathReceiverPortField />
      </ProgrammingConceptForm.Fields>
      <AdvancedFields>
        <ProgrammingConceptForm.Fields>
          <CommPathFailTestHoursField />
          <CommPathProtocolField />
          <CommPathRetryTimeField />
          <CommPathDuplicateAlarmsField />
          <CommPathSendPathField />
        </ProgrammingConceptForm.Fields>
      </AdvancedFields>
    </CommPathContextProvider>
  );
}

export default XFCommPathFields;
