App.factory("VarVideoVerificationAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.camectUrl +
        "/api/v1/camect/:control_system_id/cameras/video-verification",
      {},
      {
        getCameras: {
          method: "GET",
          params: {
            control_system_id: "@control_system_id",
          },
        },
      }
    );
  },
]);
