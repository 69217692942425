/**
 * @generated SignedSource<<3f6a931f179ef2eac867bc06af8d851f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobGroupList_query$data = {
  readonly systemDiagnosticsPanelJobGroups: ReadonlyArray<{
    readonly completedAt: string | null;
    readonly createdAt: string | null;
    readonly groupClass: string | null;
    readonly groupDesc: string | null;
    readonly groupId: number;
    readonly groupMessage: string | null;
    readonly groupStatus: string | null;
    readonly updatedAt: string | null;
  }>;
  readonly " $fragmentType": "JobGroupList_query";
};
export type JobGroupList_query$key = {
  readonly " $data"?: JobGroupList_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobGroupList_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "panelId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./JobGroupListRefreshQuery.graphql')
    }
  },
  "name": "JobGroupList_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "panelId",
          "variableName": "panelId"
        }
      ],
      "concreteType": "SystemDiagnosticsPanelJobGroup",
      "kind": "LinkedField",
      "name": "systemDiagnosticsPanelJobGroups",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groupId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groupDesc",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groupStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groupMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groupClass",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "94fbee651c19d1b3eb986485c7de33ae";

export default node;
