/**
 * @generated SignedSource<<d224cca9b4d38ad2e6d9743b94331424>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiagnosticSearch_query$data = {
  readonly systemDiagnosticsPanelSearch: ReadonlyArray<{
    readonly accountNumber: number | null;
    readonly commType: string | null;
    readonly controlSystemId: number | null;
    readonly controlSystemName: string | null;
    readonly customerId: number | null;
    readonly hardwareModel: string | null;
    readonly panelArmingSystem: string | null;
    readonly panelCommAddress: string | null;
    readonly panelSerialNumber: string | null;
    readonly panelSoftwareDate: string | null;
    readonly panelSoftwareVersion: string | null;
    readonly simIdentifier: string | null;
  }>;
  readonly " $fragmentType": "DiagnosticSearch_query";
};
export type DiagnosticSearch_query$key = {
  readonly " $data"?: DiagnosticSearch_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiagnosticSearch_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "queryString"
    },
    {
      "kind": "RootArgument",
      "name": "useWildCard"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./DiagnosticSearchRefreshQuery.graphql')
    }
  },
  "name": "DiagnosticSearch_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "queryString",
          "variableName": "queryString"
        },
        {
          "kind": "Variable",
          "name": "useWildCard",
          "variableName": "useWildCard"
        }
      ],
      "concreteType": "SystemDiagnosticsPanel",
      "kind": "LinkedField",
      "name": "systemDiagnosticsPanelSearch",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "panelSerialNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "controlSystemName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "controlSystemId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "simIdentifier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "panelCommAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "panelSoftwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "panelSoftwareDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "panelArmingSystem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b74bf9cf18975ba9f8c8537b55b9fca2";

export default node;
