/**
 * @generated SignedSource<<8c933a881f17e7ebf80374587eb8e968>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryAreaInformationTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentryCommunicationTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentryOutputOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentrySystemReportsTemplateDataInline_tMSentryProgrammingTemplateConcepts" | "TMSentryZoneInformationTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
  readonly " $fragmentType": "TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts";
};
export type TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts"
};

(node as any).hash = "687ab18e89d79a49524042da0b479a18";

export default node;
