import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver1OpenCloseUserReportsField_communication$key } from "./__generated__/CommunicationReceiver1OpenCloseUserReportsField_communication.graphql";

export const communicationReceiver1OpenCloseUserReportsFieldId = () =>
  "communication-receiver-1-open-close-user-reports";

function CommunicationReceiver1OpenCloseUserReportsField() {
  const [{ receiver1OpenCloseUserReports }, updateCommunication] =
    useCommunicationFragment<CommunicationReceiver1OpenCloseUserReportsField_communication$key>(
      graphql`
        fragment CommunicationReceiver1OpenCloseUserReportsField_communication on Communication {
          receiver1OpenCloseUserReports
        }
      `
    );

  const fieldId = communicationReceiver1OpenCloseUserReportsFieldId();
  const label = "Opening / Closing & User Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Turns on Schedule Change, and Holiday Change reports. Enables Opening, Closing, Bypass, Reset, Code Change reports if programmed in System Reports."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={receiver1OpenCloseUserReports}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              !receiver1OpenCloseUserReports,
              "receiver1OpenCloseUserReports"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver1OpenCloseUserReportsField;
