/**
 Third Party Video Service
 */

App.factory("ThirdPartyVideoService", [
  "$q",
  "ThirdPartyVideoAPI",
  "HikvisionDoorbellApi",
  function ($q, ThirdPartyVideoAPI, HikvisionDoorbellApi) {
    return {
      connectDevice: function (provider, controlSystemId, body) {
        var deferred = $q.defer();
        var _this = this;
        ThirdPartyVideoAPI.connectDevice(
          {
            //parameters
            provider: provider,
            control_system: controlSystemId,
          },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      refreshDevice: function (videoDeviceId) {
        var deferred = $q.defer();
        var _this = this;
        ThirdPartyVideoAPI.refreshDevice(
          {
            //parameters
            video_device_id: videoDeviceId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      getDevices: function (controlSystemId) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.getDevicesList(
          {
            control_system: controlSystemId,
            bypass_person_filter: true,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      getDevicesByManufacturer: function (
        manufacturer,
        controlSystemId,
        deviceType
      ) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.getDevicesList(
          {
            manufacturer: manufacturer,
            control_system: controlSystemId,
            device_type: deviceType,
            bypass_person_filter: true,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      getSingleDevice: function (videoDeviceId) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.getSingleDevice(
          {
            video_device_id: videoDeviceId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      destroyDevice: function (videoDeviceId) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.destroyDevice(
          {
            video_device_id: videoDeviceId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      updateDevice: function (videoDeviceId, body) {
        var deferred = $q.defer();
        var _this = this;
        ThirdPartyVideoAPI.updateDevice(
          {
            video_device_id: videoDeviceId,
          },
          body,
          function (data) {
            _this.getSingleDevice(videoDeviceId);
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      updateCredentials: function (videoDeviceId, body) {
        var deferred = $q.defer();
        var _this = this;
        ThirdPartyVideoAPI.updateCredentials(
          {
            video_device_id: videoDeviceId,
          },
          body,
          function (data) {
            _this.getSingleDevice(videoDeviceId);
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      hikvisionDoorbells: {
        /**
         * Possible firmware status codes returned from getFirmwareInfo.
         */
        firmwareStatusCodes: {
          UPGRADING: 0,
          DEVICE_REBOOT: 1,
          UPGRADED: 2,
          FAILED: 3,
        },

        /**
         * Delete a doorbell.
         */
        destroy: destroyHikvisionDoorbell,

        /**
         * Get details about the current firmware on a device.
         * @param {int} id The id of the video device.
         * @param {int} controlSystemId The id of the control system.
         */
        getFirmwareInfo: function (id, controlSystemId) {
          var deferred = $q.defer();

          HikvisionDoorbellApi.getFirmwareInfo(
            {
              id: id,
              control_system_id: controlSystemId,
            },
            (data) => deferred.resolve(data),
            (error) => deferred.reject(error)
          );

          return deferred.promise;
        },

        /**
         * Check the firmware update status on a device.
         * @param {int} id The id of the video device.
         * @param {int} controlSystemId The id of the control system.
         */
        getFirmwareUpgradeStatus: function (id, controlSystemId) {
          var deferred = $q.defer();

          HikvisionDoorbellApi.getFirmwareUpgradeStatus(
            {
              id: id,
              control_system_id: controlSystemId,
            },
            (data) => deferred.resolve(data),
            (error) => deferred.reject(error)
          );

          return deferred.promise;
        },

        /**
         * Trigger a firmware update on a device.
         * @param {int} id The id of the video device.
         */
        initiateFirmwareUpgrade: function (id) {
          var deferred = $q.defer();

          HikvisionDoorbellApi.initiateFirmwareUpgrade(
            {
              id: id,
            },
            (data) => deferred.resolve(data),
            (error) => deferred.reject(error)
          );

          return deferred.promise;
        },
      },

      getVideoConnectionInformation: function (control_system_id, provider) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.getVideoConnectionInformation(
          {
            control_system_id: control_system_id,
            provider: provider,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      createOrUpdateVideoConnectionInformation: function (
        control_system_id,
        host,
        port,
        provider
      ) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.createOrUpdateVideoConnectionInformation(
          {
            control_system_id: control_system_id,
          },
          (body = {
            name: "DADefaultName",
            host: host,
            management_port: port,
            provider: provider,
          }),
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      deleteVideoConnectionInformation: function (control_system_id, provider) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.deleteVideoConnectionInformation(
          {
            control_system_id: control_system_id,
            provider: provider,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      destroy: function (id, controlSystemId) {
        var deferred = $q.defer();
        ThirdPartyVideoAPI.getDevicesList(
          {
            control_system: controlSystemId,
            bypass_person_filter: true,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };

    function destroyHikvisionDoorbell(id, controlSystemId) {
      var deferred = $q.defer();
      HikvisionDoorbellApi.delete(
        {
          id: id,
          control_system_id: controlSystemId,
        },
        function () {
          deferred.resolve();
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
  },
]);
