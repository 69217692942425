import graphql from "babel-plugin-relay/macro";
import { LegaleseModal } from "components/LegaleseModal";
import React, { useCallback } from "react";
import { useMutation } from "react-relay";
import {
  LoginFormReducer,
  TwoFactorCodeDeliveryMethods,
} from "./LoginFormReducer";
import { EulaAgreementModalAgreeToEulaMutation } from "./__generated__/EulaAgreementModalAgreeToEulaMutation.graphql";

const agreeToEulaMutation = graphql`
  mutation EulaAgreementModalAgreeToEulaMutation {
    agreeToEula {
      hasAgreed
    }
  }
`;

const EulaAgreementModal = ({
  $state,
  dealerId,
  RelayService,
}: {
  $state: any;
  dealerId: string;
  RelayService: any;
}) => {
  const [state, dispatch] = React.useReducer(LoginFormReducer, {
    showLogin: true,
    showTwoFAAuthSMS: false,
    showTwoFAAuthEmail: false,
    showTwoFASetup: false,
    showTwoFASetupSMS: false,
    authCode: "",
    userEmail: "",
    userEmailBlurred: false,
    password: "",
    passwordBlurred: false,
    passwordVisibility: false,
    uuid: "",
    sanitizedContactInfo: "",
    token: "",
    method: TwoFactorCodeDeliveryMethods.NONE,
    phoneNumber: "",
    phoneNumberBlurred: false,
    errorMessageLogin: "",
    errorMessageTwoFASetup: "",
    errorMessageTwoFAAuth: "",
    showQrCameraModal: false,
    showEulaModal: false,
    eulaAgreementSigned: false,
  });

  const [agreeToEula, agreeingToEula] =
    useMutation<EulaAgreementModalAgreeToEulaMutation>(agreeToEulaMutation);

  const handleLogOut = useCallback(() => {
    RelayService.logout();
    reLogin();
  }, []);

  const redirect = () =>
    $state.go("app.dealer.dealer-invoices-download", {
      dealer_id: dealerId,
    });

  const reLogin = () => $state.go("page.login");

  const handleLogIn = useCallback(() => {
    agreeToEula({
      variables: {},
      onCompleted: ({ agreeToEula }) => {
        if (agreeToEula.hasAgreed) {
          redirect();
        } else {
          handleLogOut();
        }
      },
      onError: () => {
        handleLogOut();
      },
    });
  }, []);

  const handleCloseModal = () =>
    dispatch({ type: "SHOW_EULA_MODAL", value: false });
  // this return utilizes a local asset and will be changed when the new html link is available
  return (
    <LegaleseModal
      modalTitle=" Scroll to the bottom for acknowledgement"
      titleColor={"DaColors.Neutral200"}
      srcFile="assets/img/legal-docs/LT-2911_GlobalDealer-ServicesAgreement_24362.html"
      handleCloseModal={handleLogOut}
      hasAgreementCheckbox
      handleSubmit={handleLogIn}
      loginText="Sign In"
    />
  );
};

export default EulaAgreementModal;
