/**
 * @generated SignedSource<<a860d945fadbbde5352cc792419a1ddd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneStatus = "BYPASSED" | "DEFAULT" | "LOW_BATTERY" | "MISSING" | "NORMAL" | "OPEN" | "SHORT" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type SystemStatus_controlSystem$data = {
  readonly doorsConnection: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
  readonly id: string;
  readonly isDualCom: boolean;
  readonly isTMSentry: boolean;
  readonly isXf: boolean;
  readonly isXr: boolean;
  readonly isXt: boolean;
  readonly name: string;
  readonly timezoneOffset: string;
  readonly zones: ReadonlyArray<{
    readonly area: {
      readonly id: string;
      readonly number: string;
    } | null;
    readonly canBypass: boolean;
    readonly id: string;
    readonly isFaulted: boolean;
    readonly lockdownEnabled: boolean;
    readonly number: string;
    readonly status: ZoneStatus | null;
    readonly " $fragmentSpreads": FragmentRefs<"Zone_zone">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ArmingWidget_controlSystem" | "DoorListFragment_controlSystem" | "StatusBadgeFragment" | "StatusWidget_controlSystem" | "SystemTestButton_controlSystem" | "ZoneStatuses_controlSystem">;
  readonly " $fragmentType": "SystemStatus_controlSystem";
};
export type SystemStatus_controlSystem$key = {
  readonly " $data"?: SystemStatus_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemStatus_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemStatus_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXf",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTMSentry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDualCom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zones",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFaulted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBypass",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockdownEnabled",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "area",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Zone_zone"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemDoorsConnection",
      "kind": "LinkedField",
      "name": "doorsConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Door",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneStatuses_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArmingWidget_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StatusWidget_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DoorListFragment_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SystemTestButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StatusBadgeFragment"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "e2d615e4471b41f181b64688e871c427";

export default node;
