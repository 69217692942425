/**
 * @generated SignedSource<<9e2aafe06bead0683ea6567d493e2205>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsLocalIpAddressField_networkOptions$data = {
  readonly dhcpEnabled: boolean;
  readonly localIpAddress: string;
  readonly " $fragmentType": "NetworkOptionsLocalIpAddressField_networkOptions";
};
export type NetworkOptionsLocalIpAddressField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsLocalIpAddressField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsLocalIpAddressField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsLocalIpAddressField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localIpAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dhcpEnabled",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "4869996f66e289bfd0786ce3803d42c0";

export default node;
