/**
 *
 * useEffectOnce
 * @author Chad Watson
 *
 *
 */
import { useEffect } from "react";
export default function useEffectOnce(effect) {
  useEffect(effect, []);
}
