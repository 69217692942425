/**
 * @generated SignedSource<<618f7d432e0f12b843c9b64d33778693>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneArmingStyle = "ARM" | "DISARM" | "MAINTAIN" | "NONE" | "STEP" | "TOGGLE";
export type ZoneChimeSound = "ASCEND" | "DESCEND" | "DOORBELL" | "NONE" | "OFF";
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationTypeField_zone$data = {
  readonly armedOpenActionMessage: ZoneMessage | null;
  readonly armedShortActionMessage: ZoneMessage | null;
  readonly armingStyle: ZoneArmingStyle | null;
  readonly chimeSound: ZoneChimeSound | null;
  readonly disarmedOpenActionMessage: ZoneMessage | null;
  readonly disarmedShortActionMessage: ZoneMessage | null;
  readonly id: string;
  readonly isECP: boolean;
  readonly isNew: boolean;
  readonly number: string;
  readonly swingerBypassEnabled: boolean;
  readonly type: ZoneType;
  readonly " $fragmentType": "ZoneInformationTypeField_zone";
};
export type ZoneInformationTypeField_zone$key = {
  readonly " $data"?: ZoneInformationTypeField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationTypeField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationTypeField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swingerBypassEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disarmedOpenActionMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disarmedShortActionMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedShortActionMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedOpenActionMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chimeSound",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armingStyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isECP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "7e05ba9f55bf3c81f8f8802c62cf7047";

export default node;
