/**
 * @generated SignedSource<<e7ae2258687678e11f375146a9f19c0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly lockoutCode: {
      readonly code: string;
      readonly id: string;
    } | null;
  };
  readonly " $fragmentType": "TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem";
};
export type TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelLockoutCodeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "fc1784fe96548ed188d33a1af0453bdc";

export default node;
