import graphql from "babel-plugin-relay/macro";
import { Flex } from "components/DaStyledElements";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import moment from "moment";
import React from "react";
import { useMutation } from "react-relay";
import { TimeSetModalDateTimeMutation } from "./__generated__/TimeSetModalDateTimeMutation.graphql";

const setDateTimeMutation = graphql`
  mutation TimeSetModalDateTimeMutation($panelId: String!, $dateTime: String!) {
    sendDateTime(panelId: $panelId, dateTime: $dateTime)
  }
`;

// sends two times, current time with DST and current time without DST
// return format: [nonDST: Date, withDST: Date]
function getTimesDST(currentDateTime: Date) {
  const newDate = new Date(currentDateTime.valueOf());
  const isDST = moment(newDate).isDST();

  if (isDST) {
    newDate.setHours(newDate.getHours() - 1);
    return [newDate, currentDateTime];
  } else {
    newDate.setHours(newDate.getHours() + 1);
    return [currentDateTime, newDate];
  }
}

function TimeSetModal({
  setModalOpen,
  timezoneOffset,
  panelId,
}: {
  setModalOpen: (modalOpen: boolean) => void;
  timezoneOffset: string;
  panelId: string;
}) {
  const regularDateTime = new Date();
  const [nonDSTDateTime, dateTimeDST] = getTimesDST(regularDateTime);
  const isCurrentDateDST = moment(regularDateTime).isDST();
  const [useDST, setUseDST] = React.useState(isCurrentDateDST);
  const dsTimezoneOffset = isCurrentDateDST
    ? Number(timezoneOffset) - 1
    : Number(timezoneOffset);
  const adjustedTimezoneOffset =
    Number(dsTimezoneOffset) === 24 ? 0 : dsTimezoneOffset;

  const displayTimeOffset = () => {
    if (Number(adjustedTimezoneOffset) === 0) {
      //zero case
      return 0;
    } else if (Number(adjustedTimezoneOffset) <= 12) {
      //negative case
      return -(Number(adjustedTimezoneOffset) * 60);
    } else {
      //positive case
      return (24 - Number(adjustedTimezoneOffset)) * 60;
    }
  };

  const timeToDisplay = moment(useDST ? dateTimeDST : nonDSTDateTime).utcOffset(
    displayTimeOffset()
  );

  const timeToSend = moment(useDST ? dateTimeDST : nonDSTDateTime)
    .utcOffset(displayTimeOffset())
    .add(isCurrentDateDST ? 1 : 0, "hours");
  const displayDate = timeToDisplay.format("dddd, ll");
  const displayTime = timeToDisplay.format("LT");

  const showAlert = useShowAlert();

  const [commitSetDateTimeMutation, settingDateTimeMutation] =
    useMutation<TimeSetModalDateTimeMutation>(setDateTimeMutation);

  return (
    <Modal
      className="modal-grid__max-width"
      size="medium"
      onClickOutside={() => setModalOpen(false)}
    >
      <Modal.Header>
        <span className="h1">Set Time</span>
      </Modal.Header>
      <Modal.Body className="modal-grid__padding-bottom">
        {settingDateTimeMutation && (
          <div
            style={{
              width: "100%",
              height: "100%",
              minHeight: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="csspinner traditional" />
          </div>
        )}
        {!settingDateTimeMutation && (
          <>
            <p style={{ fontSize: "15px" }}>
              This system's time and date will be set to:
            </p>
            <p>
              <strong style={{ fontSize: "15px" }}>
                {displayDate}
                <br />
                {displayTime}
              </strong>
            </p>
            <label style={{ paddingTop: "3px" }} className="switch">
              <input
                type="checkbox"
                checked={useDST}
                onChange={() => setUseDST(!useDST)}
              />
              <span></span>
            </label>
            <span
              style={{
                fontSize: "16px",
                marginLeft: "10px",
                verticalAlign: "top",
              }}
            >
              Apply Daylight Savings Time
            </span>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Flex.Row align="right" justify="right">
          <button
            className="btn btn-default btn-md color-danger-400"
            type="button"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-default btn-md color-primary-400"
            type="button"
            disabled={settingDateTimeMutation}
            onClick={() => {
              commitSetDateTimeMutation({
                variables: {
                  panelId: panelId,
                  dateTime: timeToSend.format(),
                },
                onCompleted: (result) => {
                  if (result.sendDateTime === "success")
                    showAlert({
                      type: "success",
                      text: "Successfully updated panel time.",
                    });
                  else
                    showAlert({
                      type: "error",
                      text: "Could not update panel time.",
                    });
                  setModalOpen(false);
                },
                onError: (error) => {
                  showAlert({
                    type: "error",
                    text: "There was an error sending the request.",
                  });
                  setModalOpen(false);
                },
              });
            }}
          >
            Confirm
          </button>
        </Flex.Row>
      </Modal.Footer>
    </Modal>
  );
}

export default TimeSetModal;
