import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZoneArmingStyle, ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationArmingStyleField_zone$key } from "./__generated__/ZoneInformationArmingStyleField_zone.graphql";

export const zoneInformationArmingStyleFieldId = (number: string) =>
  `zone-information-arming-style-${number}`;

function ZoneInformationArmingStyleField() {
  const [{ number, armingStyle, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationArmingStyleField_zone$key>(
      graphql`
        fragment ZoneInformationArmingStyleField_zone on Zone {
          number
          type
          armingStyle
        }
      `
    );

  const fieldId = zoneInformationArmingStyleFieldId(String(number));
  const disabled = ZoneType.ARMING !== type;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Arming Style"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={armingStyle ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "armingStyle");
          });
        }}
      >
        <Select.Option value={ZoneArmingStyle.TOGGLE}>Toggle</Select.Option>
        <Select.Option value={ZoneArmingStyle.ARM}>Arm</Select.Option>
        <Select.Option value={ZoneArmingStyle.DISARM}>Disarm</Select.Option>
        <Select.Option value={ZoneArmingStyle.STEP}>Step</Select.Option>
        <Select.Option value={ZoneArmingStyle.MAINTAIN}>Maintain</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationArmingStyleField;
