/**
 * @generated SignedSource<<323652e8507c11a9e3039633dfe3cd2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsDnsServerField_networkOptions$data = {
  readonly dhcpEnabled: boolean;
  readonly dnsServer: string;
  readonly " $fragmentType": "NetworkOptionsDnsServerField_networkOptions";
};
export type NetworkOptionsDnsServerField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsDnsServerField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsDnsServerField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsDnsServerField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dnsServer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dhcpEnabled",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "541e5a573f29f108d3e6017fce0d9c35";

export default node;
