import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, LockoutCode } from "securecom-graphql/client";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { LockoutCodeContextProvider } from "../common/LockoutCodeFields/LockoutCodeContext";
import LockoutCodeField from "../common/LockoutCodeFields/LockoutCodeField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$data,
  TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem.graphql";
import { TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  TMSentryLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation,
} from "./__generated__/TMSentryLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation.graphql";
import {
  TMSentryLockoutCodeProgrammingConceptFormLockoutCodeSendMutation,
  TMSentryLockoutCodeProgrammingConceptFormLockoutCodeSendMutation$data,
} from "./__generated__/TMSentryLockoutCodeProgrammingConceptFormLockoutCodeSendMutation.graphql";
import { TMSentryLockoutCodeProgrammingConceptForm_controlSystem$key } from "./__generated__/TMSentryLockoutCodeProgrammingConceptForm_controlSystem.graphql";

export const title = "Lockout Code";
export const conceptId = "tmsentry-lockout-code";

export const getState = (
  controlSystem: TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          lockoutCode {
            id
            code
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation TMSentryLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation(
    $id: ID!
  ) {
    refreshLockoutCode(id: $id) {
      ... on RefreshLockoutCodeSuccessPayload {
        __typename
        controlSystem {
          ...TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TMSentryLockoutCodeProgrammingConceptFormLockoutCodeRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, __typename, error } =
              response.refreshLockoutCode;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Lockout Code Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshLockoutCode: {
                    __typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Lockout Code: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Lockout Code",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};
const saveMutation = graphql`
  mutation TMSentryLockoutCodeProgrammingConceptFormLockoutCodeSendMutation(
    $systemId: ID!
    $code: String!
  ) {
    sendLockoutCodeProgramming(systemId: $systemId, code: $code) {
      ... on SendLockoutCodeProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendLockoutCodeProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: TMSentryLockoutCodeProgrammingConceptFormLockoutCodeSendMutation$data,
  originalControlSystemData: TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendLockoutCodeProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshLockoutCode: {
          __typename: "RefreshCommunicationSuccessPayload",
          controlSystem: getState(
            response.sendLockoutCodeProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TMSentryLockoutCodeProgrammingConceptFormLockoutCodeSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { lockoutCode },
        } = getState(props.controlSystem);
        save({
          variables: {
            systemId,
            code: lockoutCode?.code ?? "00000",
          },
          onCompleted: (response) => {
            const sendErrors: SaveErrors = [];
            if (response.sendLockoutCodeProgramming.controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Lockout Code Programming Sent to the System",
                });
              }
              resetLastUpdated(conceptId);
              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );
            } else if (response.sendLockoutCodeProgramming.errors) {
              sendErrors.push({
                programmingConcept: title,
                errors: response.sendLockoutCodeProgramming.errors,
              });
            }
            resolve(sendErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSaving,
  ];
};

const readLockoutCodeTemplateData = (
  programmingTemplateConcepts: TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        lockoutCode {
          included
          code {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).lockoutCode ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readLockoutCodeTemplateData(programmingTemplateConcepts);

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const lockoutCodeRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "lockoutCode",
      "LockoutCode"
    ) as unknown as RecordProxy<LockoutCode>;

    applyTemplateScalarDataToRecordProxy(lockoutCodeRecordProxy, templateData);
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [{ panel }] =
    useControlSystemFragment<TMSentryLockoutCodeProgrammingConceptForm_controlSystem$key>(graphql`
      fragment TMSentryLockoutCodeProgrammingConceptForm_controlSystem on ControlSystem {
        panel {
          ...PanelContext_panel
          ...PanelContextUseHardwareModel_panel
          helpFiles {
            programmingGuideUrl
            installGuideUrl
          }
          lockoutCode {
            ...LockoutCodeContext_lockoutCode
            ...LockoutCodeField_lockoutCode
          }
          systemOptions {
            ...SystemOptionsContextSystemType_systemOptions
          }
        }
      }
    `);

  const {
    lockoutCode,
    helpFiles: { programmingGuideUrl },
  } = panel;

  return (
    <PanelContextProvider panel={panel}>
      <LockoutCodeContextProvider lockoutCode={lockoutCode}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          title={title}
          helpLink={`${programmingGuideUrl}#Set%20Lockout%20Code`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(lockoutCode)}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <LockoutCodeField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </LockoutCodeContextProvider>
    </PanelContextProvider>
  );
}
