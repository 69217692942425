/**
 * @generated SignedSource<<d516a10a77401d64c27a8ae0d2c70cea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
import { FragmentRefs } from "relay-runtime";
export type TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly systemOptions: {
      readonly __typename: "TMSentrySystemOptions";
      readonly closingCode?: boolean;
      readonly crossZoneTime: number;
      readonly enableKeypadPanicKeys?: boolean;
      readonly entryDelay1?: number;
      readonly exitDelay?: number;
      readonly hoursFromGMT: number;
      readonly id: string;
      readonly powerFailDelay: number;
      readonly resetSwingerBypass?: boolean;
      readonly swingerBypassTrips: number;
      readonly timeChange: boolean;
      readonly useFalseAlarmQuestion?: boolean;
      readonly weatherZipCode?: string;
      readonly wirelessAudibles?: WirelessAudibles | null;
    };
  };
  readonly " $fragmentType": "TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem";
};
export type TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "572fa7755733b47ead38ce5570d65ae7";

export default node;
