import React, { useCallback } from "react";
import { isNil } from "ramda";
import styled, { css, useTheme } from "styled-components";
import { BORDER_RADIUS } from "../constants";
import { themeGrayDark, themeRgba } from "../Theme";
import { noSelection } from "../../../utils/styles";
import rgba from "../../../utils/universal/rgba";
import VisuallyHidden from "../VisuallyHidden";

export enum COLOR_THEME_OPTIONS {
  IDLE = "idle",
  PRIMARY = "primary",
  PURPLE = "purple",
  COOL = "cool",
  WARM = "warm",
  DARK = "dark",
  SIMPLE = "simple",
}

const optionId = (id: string, index: number) => `${id}__option${index || ""}`;

const PillSwitch = ({
  className,
  disabled,
  id,
  onChange,
  onMouseEnter,
  onMouseLeave,
  options,
  selectedIndex,
  vertical,
  colorTheme,
}: {
  className?: string;
  disabled?: boolean;
  id: string;
  onChange?: (index: number) => void;
  onMouseEnter?: (index: number) => void;
  onMouseLeave?: (index: number) => void;
  options: { label: React.ReactNode; disabled?: boolean }[];
  selectedIndex?: number;
  vertical?: boolean;
  colorTheme?: COLOR_THEME_OPTIONS;
}) => {
  const theme = useTheme();
  const color =
    colorTheme === "idle"
      ? theme.grayAccent
      : colorTheme === "primary"
      ? theme.secondary
      : colorTheme === "purple"
      ? theme.primary
      : colorTheme === "cool"
      ? theme.coolBlue
      : colorTheme === "warm"
      ? theme.orange
      : colorTheme === "dark"
      ? theme.grayDark
      : theme.grayXlight;
  const newTheme = {
    ...theme,
    borderColor:
      colorTheme === "simple" || !colorTheme ? theme.grayAccent : color,
    background:
      colorTheme === "simple" || !colorTheme
        ? theme.grayXlight
        : `linear-gradient(to right, ${rgba(0.2, color)}, ${rgba(0.4, color)})`,
    textColor: ["idle", "primary", "purple", "cool", "warm", "dark"].includes(
      colorTheme
    )
      ? theme.trueWhite
      : theme.gray,
    activeTextColor:
      colorTheme && colorTheme !== "idle" && colorTheme !== "simple"
        ? color
        : theme.grayDark,
    indicatorColor: theme.trueWhite,
  };
  const onMouseEnterSelected = useCallback(() => {
    if (onMouseEnter && !isNil(selectedIndex)) {
      onMouseEnter(selectedIndex);
    }
  }, [onMouseEnter, selectedIndex]);
  return (
    <Wrapper
      className={className}
      background={newTheme.background}
      borderColor={newTheme.borderColor}
      vertical={vertical}
    >
      {options.map((option, i) => (
        <Option
          key={optionId(id, i)}
          htmlFor={optionId(id, i)}
          onClick={(event) => option.disabled && event.preventDefault()}
          borderColor={newTheme.borderColor}
          vertical={vertical}
          onMouseEnter={() => onMouseEnter && onMouseEnter(i)}
          onMouseLeave={onMouseLeave}
          disabled={disabled}
          width={100 / options.length}
        >
          <VisuallyHidden>
            <input
              type="radio"
              id={optionId(id, i)}
              name={id}
              value={option.label}
              checked={i === selectedIndex}
              onChange={() => !option.disabled && onChange(i)}
              disabled={disabled}
              data-testid="pill-switch-option-input"
            />
          </VisuallyHidden>
          <OptionLabel
            selected={selectedIndex === i}
            vertical={vertical}
            color={newTheme.textColor}
            activeColor={newTheme.activeTextColor}
          >
            {option.label}
          </OptionLabel>
        </Option>
      ))}
      {!isNil(selectedIndex) && (
        <Indicator
          htmlFor={optionId(id, selectedIndex)}
          optionsCount={options.length}
          selectedIndex={selectedIndex}
          borderColor={newTheme.borderColor}
          vertical={vertical}
          color={newTheme.indicatorColor}
          onMouseEnter={onMouseEnterSelected}
          onMouseLeave={onMouseLeave}
          data-testid="pill-switch-indicator"
        />
      )}
    </Wrapper>
  );
};

export default PillSwitch;

const ITEM_HEIGHT_IN_EM = 3;
const Wrapper = styled.div<{
  borderColor: string;
  background: string;
  vertical?: boolean;
}>`
  position: relative;
  display: flex;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ background }) => background};
  font-size: 0.75rem;
  height: ${({ vertical }) =>
    vertical ? ITEM_HEIGHT_IN_EM * 2 : ITEM_HEIGHT_IN_EM}em;
  z-index: 1;

  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
    `};
`;
const Option = styled.label<{
  vertical?: boolean;
  width: number;
  borderColor: string;
  disabled?: boolean;
}>`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${({ vertical }) => (vertical ? "50%" : "100%")};
  min-width: 50px;
  width: ${({ vertical, width }) => (vertical ? 100 : width)}%;
  padding: 0 ${({ vertical }) => (vertical ? 1.25 : 0.5)}em;
  ${({ vertical }) => (vertical ? "border-bottom" : "border-right")} 1px solid
    ${({ borderColor }) => rgba(0.2, borderColor)};
  font-size: 1em;
  line-height: 1;
  text-transform: uppercase;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:last-child {
    border-right: none;
  }

  &:hover {
    background: ${themeRgba(0.05, themeGrayDark)};
  }
`;
const OptionLabel = styled.span<{ selected?: boolean; activeColor: string }>`
  ${noSelection};
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  color: ${({ color }) => color};
  font-weight: 700;
  letter-spacing: 0.04em;
  width: 100%;

  ${({ selected, activeColor }) =>
    selected &&
    css`
      z-index: 1;
      color: ${activeColor};
    `};
`;
const Indicator = styled.label<{
  vertical?: boolean;
  optionsCount: number;
  selectedIndex: number;
}>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ vertical, optionsCount }) =>
    vertical ? 100 : 100 / optionsCount}%;
  height: ${({ vertical }) => (vertical ? "50%" : "100%")};
  border-radius: ${BORDER_RADIUS};
  box-shadow: ${({ theme }) => theme.elevation200};
  transform: ${({ vertical, selectedIndex }) => {
      const offset = 100 * selectedIndex;
      return vertical ? `translateY(${offset}%)` : `translateX(${offset}%)`;
    }}
    scale(1.05, 1.2);
  background: ${({ color }) => color};
  transition: 0.6s transform;
  cursor: pointer;
`;
