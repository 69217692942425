import styled from "styled-components";

type Props = {
  small?: boolean;
  large?: boolean;
  horizontal?: boolean;
  huge?: boolean;
};

const Space = styled.span<Props>`
  display: ${({ horizontal }) => (horizontal ? "inline-block" : "block")};
  width: ${({ horizontal, small, large, huge }) =>
    !horizontal
      ? "auto"
      : small
      ? "0.5rem"
      : large
      ? "2rem"
      : huge
      ? "100%"
      : "1.5rem"};
  height: ${({ horizontal, small, large, huge }) =>
    horizontal
      ? "auto"
      : small
      ? "0.5rem"
      : large
      ? "2rem"
      : huge
      ? "20rem"
      : "1.5rem"};
`;

export default Space;
