import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import BarCodeScanner from "components/MobileCamera/MobileCameraCheck";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputInformationFragment } from "./OutputInformationContext";
import {
  resolveOutputFieldRules,
  serialNumberIsValidWirelessBell,
} from "./utils";
import { OutputInformationSerialNumberField_output$key } from "./__generated__/OutputInformationSerialNumberField_output.graphql";

export const OUTPUT_INFORMATION_SERIAL_NUMBER =
  "output-information-serial-number-";

export const outputInformationSerialNumberFieldId = (number: string) =>
  OUTPUT_INFORMATION_SERIAL_NUMBER + number;

function OutputInformationSerialNumberField() {
  const [{ number, serialNumber, isWireless }, updateOutputInformation] =
    useOutputInformationFragment<OutputInformationSerialNumberField_output$key>(
      graphql`
        fragment OutputInformationSerialNumberField_output on Output {
          number
          serialNumber
          isWireless
        }
      `
    );
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const fieldId = outputInformationSerialNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { SERIAL_NUMBER: RULES } = resolveOutputFieldRules(hardwareModel);
  const disabled = !isWireless;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Serial Number"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={serialNumber ?? ""}
        className={`output-${number}-textbox`}
        pattern={RULES?.PATTERN}
        inlineHelp={RULES?.INLINE_HELP}
        required
        maxLength={8}
        onChange={({ target }) => {
          updateOutputInformation((recordProxy) => {
            recordProxy.setValue(target.value, "serialNumber");
            recordProxy.setValue(
              serialNumberIsValidWirelessBell(target.value),
              "tripWithPanelBell"
            );
          });
        }}
      />
      {windowWidth <= 800 || window.navigator.userAgent.includes("iPad") ? (
        <BarCodeScanner updateOutputInformation={updateOutputInformation} />
      ) : null}
    </ProgrammingConceptForm.Field>
  );
}

export default OutputInformationSerialNumberField;
