/**
 * @generated SignedSource<<7762b65dff56bceca72f27726302c688>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FullProgrammingVersionEntryPoint_dealer$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DealerContext_dealer" | "FullProgrammingForm_dealer" | "ProgrammingTemplateForm_dealer">;
  readonly " $fragmentType": "FullProgrammingVersionEntryPoint_dealer";
};
export type FullProgrammingVersionEntryPoint_dealer$key = {
  readonly " $data"?: FullProgrammingVersionEntryPoint_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FullProgrammingVersionEntryPoint_dealer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FullProgrammingVersionEntryPoint_dealer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DealerContext_dealer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FullProgrammingForm_dealer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_dealer"
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};

(node as any).hash = "0b21539e2d34586c7a7824d334bee016";

export default node;
