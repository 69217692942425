/**
 * @generated SignedSource<<473e0b3db902426252addcdb3327edd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports$data = {
  readonly logRealTimeStatusEnabled: boolean;
  readonly " $fragmentType": "PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports";
};
export type PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports$key = {
  readonly " $data"?: PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logRealTimeStatusEnabled",
      "storageKey": null
    }
  ],
  "type": "PcLogReports",
  "abstractKey": null
};

(node as any).hash = "35310c33dc3638d0ed27ee22aee6b653";

export default node;
