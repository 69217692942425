/**
 * @generated SignedSource<<ce9b35ffe2c0aee4ac27a8e4d19608c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendAllUserCodesButtonMutation$variables = {
  systemId: string;
};
export type SendAllUserCodesButtonMutation$data = {
  readonly sendAllUserCodes: {
    readonly __typename: "SendAllUserCodesErrorResponse";
    readonly message: string | null;
  } | {
    readonly __typename: "SendAllUserCodesSuccessResponse";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SendAllUserCodesButtonMutation = {
  response: SendAllUserCodesButtonMutation$data;
  variables: SendAllUserCodesButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendAllUserCodesButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendAllUserCodes",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "SendAllUserCodesErrorResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "SendAllUserCodesSuccessResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendAllUserCodesButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendAllUserCodes",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "SendAllUserCodesErrorResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b784903ef0647467e48104087b3cd33c",
    "id": null,
    "metadata": {},
    "name": "SendAllUserCodesButtonMutation",
    "operationKind": "mutation",
    "text": "mutation SendAllUserCodesButtonMutation(\n  $systemId: ID!\n) {\n  sendAllUserCodes(systemId: $systemId) {\n    __typename\n    ... on SendAllUserCodesErrorResponse {\n      __typename\n      message\n    }\n    ... on SendAllUserCodesSuccessResponse {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd5027cbcadd0ffe7df7d25852b8e7fe";

export default node;
