/**
 * @generated SignedSource<<43c94e3561cd6ed0e6ce4c1f503f456e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathReceiverIpv6AddressField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly id: string;
  readonly number: number;
  readonly remoteIPv6: string;
  readonly useIPv6: boolean;
  readonly " $fragmentType": "CommPathReceiverIpv6AddressField_communicationPath";
};
export type CommPathReceiverIpv6AddressField_communicationPath$key = {
  readonly " $data"?: CommPathReceiverIpv6AddressField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathReceiverIpv6AddressField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathReceiverIpv6AddressField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteIPv6",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useIPv6",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "fa0b31bc002ec5aa5cfa8a543d40b5a4";

export default node;
