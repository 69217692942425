import graphql from "babel-plugin-relay/macro";
import { Badge, Flex, Form, Input } from "components/DaStyledElements";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { CentralStationAutomationIntegrationStatus } from "securecom-graphql/client";
import styled from "styled-components";
import { SmallButton } from "../../Button";
import {
  IntegrationFormDispatch,
  IntegrationFormState,
} from "./IntegrationFormReducer";
import { FormDealerIntegrationRequestMutation } from "./__generated__/FormDealerIntegrationRequestMutation.graphql";
import { FormIntegrationBaseFragment$key } from "./__generated__/FormIntegrationBaseFragment.graphql";

import Icon from "components/Icon";
import InlineTooltip from "components/InlineTooltip";
import { useUserIsTechSupport } from "../UserIsTechSupportContext";
import CentralStationVideoLink, {
  centralStationVideoHref,
} from "./CentralStationVideoLink";

export const TitleHr = styled.hr`
  margin: 0;
`;
const MessageParagraph = styled.div`
  font-size: 1.6rem;
  line-height: 1.5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--color-neutral-900);
  border-radius: 1.2rem;
  border: 0.1rem solid var(--color-neutral-600);
  background-color: var(--color-neutral-100);
  padding: var(--measure-2x);
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: var(--measure-1x);
`;

export const MessageParagraphsColorless = styled(MessageParagraph)`
  display: flex;
  flex-flow: column nowrap;
  background-color: transparent;
  border: none;
  padding: 0 0 var(--measure-2x);
  line-height: 1.4;
  font-size: 1.4em;
  p {
    text-align: left;
  }
`;

const InlineFlexCol = styled.div`
  display: inline-flex;
  margin-bottom: 0.8rem;
`;

const sendIntegrationRequest = graphql`
  mutation FormDealerIntegrationRequestMutation {
    sendContactMeEmailForCentralStationAutomationIntegration {
      ... on SendContactMeEmailForCentralStationAutomationIntegrationErrorResponse {
        __typename
        error {
          ... on Error {
            errorType: type
          }
        }
      }
      ... on SendContactMeEmailForCentralStationAutomationIntegrationSuccessResponse {
        __typename
        dealer {
          id
          name
          email
        }
      }
    }
  }
`;

export const IntegrationForm: React.FC<
  React.PropsWithChildren<{
    state: IntegrationFormState;
    baseFormDataFragment: FormIntegrationBaseFragment$key;
    dispatch: IntegrationFormDispatch;
    onCancel: () => void;
    onSave: () => void;
    isSavingIntegration: boolean;
    displayedName?: string;
  }>
> = ({
  state,
  baseFormDataFragment,
  dispatch,
  onCancel,
  onSave,
  isSavingIntegration,
  displayedName,
}) => {
  const {
    passwordBlurred,
    usernameBlurred,
    centralStationName,
    centralStationCode,
    username,
    password,
    emergencyContactsEnabled,
    systemTestsEnabled,
    cmsDealerNumber,
  } = state;

  const formData = useFragment(
    graphql`
      fragment FormIntegrationBaseFragment on Query {
        centralStationIntegrationNames {
          displayName
          value
          __typename
        }
        dealer(id: $dealerId) {
          id
          centralStationAutomationIntegrations {
            __typename
            id
            name
            status
            emergencyContactsEnabled
            systemTestsEnabled
          }
        }
      }
    `,
    baseFormDataFragment
  );

  const [inputPasswordStyle, setInputPasswordStyle] =
    React.useState("password");

  const [credentialInputsVisible, setCredentialInputsVisible] =
    React.useState(false);

  const setInputType = () => {
    inputPasswordStyle === "text"
      ? setInputPasswordStyle("password")
      : setInputPasswordStyle("text");
  };

  const [sendContactEmail, isSendingContactEmail] =
    useMutation<FormDealerIntegrationRequestMutation>(sendIntegrationRequest);

  const showAlert = useShowAlert();

  const dealer = formData.dealer;

  const thisIntegration = React.useMemo(
    () =>
      dealer?.centralStationAutomationIntegrations.find(
        (integration) => integration.id === state.id
      ) ?? null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dealer?.centralStationAutomationIntegrations, state.id]
  );

  const isNew = !thisIntegration;
  const { isTechSupport } = useUserIsTechSupport();

  return dealer ? (
    <Modal size="medium">
      <Modal.Header className="pad-b-0">
        <Flex.Row justify="space-between" align="center">
          <span className="font-sz-20">
            {isNew ? `New Monitoring Center` : `Editing ${displayedName}`}
          </span>
          {!isNew && (
            <Badge
              stateColor={
                thisIntegration?.status ===
                CentralStationAutomationIntegrationStatus.CONNECTED
                  ? "success"
                  : "danger"
              }
              message={
                thisIntegration?.status ===
                CentralStationAutomationIntegrationStatus.CONNECTED
                  ? "CONNECTED"
                  : "NOT CONNECTED"
              }
            />
          )}
        </Flex.Row>
        <TitleHr />
      </Modal.Header>
      <form>
        <Modal.Body>
          <Flex.Row>
            <Flex.Col size={1}>
              {isNew && (
                <>
                  <MessageParagraphsColorless>
                    <p>
                      Adding a Monitoring Center allows you to link a system
                      between Dealer Admin and automation. Dealer Admin
                      personnel can place a system on test for up to four hours
                      from Dealer Admin or the Tech APP. Customers can edit
                      emergency contacts and place their system on test from
                      Virtual Keypad.
                    </p>
                    <p>
                      Add a Monitoring Center by selecting your provider and
                      entering your username and password. Then, add Receivers
                      to define the account number prefixes configured in
                      automation.
                    </p>
                    <CentralStationVideoLink link={centralStationVideoHref} />
                  </MessageParagraphsColorless>

                  <Form.Group className="required">
                    <Form.Label>Monitoring Center</Form.Label>
                    <select
                      className="form-control"
                      name="integrations"
                      id="integrations"
                      disabled={!isNew}
                      value={centralStationName}
                      onChange={(event) =>
                        dispatch({
                          type: "NAME_CHANGED",
                          value: event.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        Choose Integration
                      </option>
                      {[
                        ...formData.centralStationIntegrationNames,
                        { displayName: "Other", value: "other" },
                      ].map(({ displayName, value }) => {
                        return (
                          <option key={value} value={value}>
                            {displayName}
                          </option>
                        );
                      })}
                    </select>
                  </Form.Group>
                </>
              )}
            </Flex.Col>
          </Flex.Row>
          {centralStationName === "other" && isNew && (
            <>
              <Flex.Row>
                <Flex.Col size={1}>
                  <Form.Group className="required">
                    <Form.Label>Monitoring Center Code</Form.Label>
                    <input
                      className="form-control"
                      type="text"
                      name="centralStationCode"
                      id="centralStationCode"
                      value={centralStationCode}
                      onBlur={(event) => dispatch({ type: "USERNAME_BLURRED" })}
                      onChange={(event) =>
                        dispatch({
                          type: "CENTRAL_STATION_CODE_CHANGED",
                          value: event.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Flex.Col>
              </Flex.Row>
              <Flex.Row>
                <Flex.Col size={1}>
                  <MessageParagraph>
                    <Icon
                      size="var(--measure-font-24)"
                      style={{ width: "6em" }}
                      className="mar-r-8"
                      name="radial_info"
                      color="var(--color-primary)"
                    />
                    <span>
                      If you own your monitoring center, we’ll need additional
                      information to integrate with your platform. Select “Send
                      Request” to be contacted by a member of SecureCom Wireless
                      to gather more information. If your platform is
                      compatible, SecureCom Wireless will send you a code to
                      enter below that will allow Dealer Admin to integrate with
                      your monitoring center.
                      <div>
                        <button
                          className="btn btn-sm btn-dmp pull-right mar-t-8"
                          disabled={isSendingContactEmail}
                          onClick={() => {
                            sendContactEmail({
                              variables: {},
                              onCompleted: (response) => {
                                if (
                                  "error" in
                                  response.sendContactMeEmailForCentralStationAutomationIntegration
                                ) {
                                  showAlert({
                                    type: "error",
                                    text: `Failed to send email`,
                                  });
                                } else {
                                  showAlert({
                                    type: "success",
                                    text: `Email Sent`,
                                  });
                                  onCancel();
                                }
                              },
                            });
                          }}
                        >
                          Send Request
                        </button>
                      </div>
                    </span>
                  </MessageParagraph>
                </Flex.Col>
              </Flex.Row>
            </>
          )}

          {((!!centralStationName && credentialInputsVisible) ||
            !isNew ||
            centralStationName === "other") && (
            <>
              <Flex.Row>
                <Flex.Col size={1}>
                  <Form.Group className={isNew ? `required` : ""}>
                    <Form.Label>Username</Form.Label>
                    <input
                      className="form-control"
                      type="text"
                      name="username"
                      id="username"
                      disabled={isTechSupport}
                      value={username}
                      maxLength={255}
                      onBlur={(event) => dispatch({ type: "USERNAME_BLURRED" })}
                      onChange={(event) =>
                        dispatch({
                          type: "USERNAME_CHANGED",
                          value: event.target.value,
                        })
                      }
                    />
                    {isNew && !username && usernameBlurred && (
                      <div className="error-container">
                        <div
                          className="has-error"
                          style={{ color: "var(--color-danger-500)" }}
                        >
                          A username is required.
                        </div>
                      </div>
                    )}
                  </Form.Group>
                </Flex.Col>
              </Flex.Row>
              <Flex.Row>
                <Flex.Col size={1}>
                  <Form.PasswordRevealGroup
                    className={isNew ? `required` : ""}
                    setInputStyle={setInputType}
                    eyeIsVisible={!!password}
                  >
                    <Form.Label>Password</Form.Label>
                    <input
                      className="form-control"
                      type={inputPasswordStyle}
                      name="password"
                      id="password"
                      placeholder={!isNew ? "******" : ""}
                      disabled={isTechSupport}
                      value={password}
                      maxLength={255}
                      onBlur={() => dispatch({ type: "PASSWORD_BLURRED" })}
                      onChange={(event) =>
                        dispatch({
                          type: "PASSWORD_CHANGED",
                          value: event.target.value,
                        })
                      }
                    />
                    {!password && passwordBlurred && isNew && (
                      <div className="error-container">
                        <div
                          className="has-error"
                          style={{ color: "var(--color-danger-500)" }}
                        >
                          A password is required.
                        </div>
                      </div>
                    )}
                  </Form.PasswordRevealGroup>
                </Flex.Col>
              </Flex.Row>

              {["cms", "nmc"].includes(centralStationName.toLowerCase()) && (
                <Flex.Row>
                  <Flex.Col size={1}>
                    <Form.Group>
                      <Form.Label>
                        {centralStationName.toLowerCase() === "nmc"
                          ? "NMC"
                          : "CMS"}{" "}
                        Dealer Number
                      </Form.Label>
                      <input
                        className="form-control"
                        type="text"
                        name="cms-dealer-number"
                        id="cms-dealer-number"
                        value={cmsDealerNumber}
                        disabled={isTechSupport}
                        onChange={(event) =>
                          dispatch({
                            type: "CMS_DEALER_NUMBER_CHANGED",
                            value: event.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Flex.Col>
                </Flex.Row>
              )}

              {centralStationName !== "other" && (
                <>
                  <Flex.Row>
                    <Flex.Col size={1}>
                      <h3>Options</h3>
                    </Flex.Col>
                  </Flex.Row>
                  <Flex.Row>
                    <Flex.Col size={1}>
                      <InlineFlexCol>
                        <Input.CheckBoxInline
                          label="Emergency Contacts"
                          checked={emergencyContactsEnabled}
                          disabled={isTechSupport}
                          onChange={() =>
                            dispatch({
                              type: "ALLOW_EDIT_CONTACT_LIST_CHANGED",
                            })
                          }
                        />
                        <InlineTooltip message="Allows Virtual Keypad users to edit their Emergency Contact list." />
                      </InlineFlexCol>
                    </Flex.Col>
                  </Flex.Row>
                  <Flex.Row>
                    <Flex.Col size={1}>
                      <InlineFlexCol>
                        <Input.CheckBoxInline
                          style={{ marginBottom: ".8rem" }}
                          label="Place System on Test"
                          checked={systemTestsEnabled}
                          disabled={isTechSupport}
                          onChange={() =>
                            dispatch({
                              type: "ALLOW_PLACE_SYSTEM_ON_TEST_CHANGED",
                            })
                          }
                        />
                        <InlineTooltip message="Allows Dealer Admin, Tech APP, or Virtual Keypad to place the system on test." />
                      </InlineFlexCol>
                    </Flex.Col>
                  </Flex.Row>
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <SmallButton
            style={{ marginRight: "0.8rem" }}
            type="reset"
            text="Cancel"
            onClick={() => onCancel()}
          />
          {isTechSupport ? null : centralStationName !== "other" ? (
            credentialInputsVisible || !isNew ? (
              <SmallButton
                type="primary"
                text="Save"
                disabled={isSavingIntegration}
                onClick={onSave}
              />
            ) : (
              <SmallButton
                type="primary"
                text="Add"
                disabled={!centralStationName}
                onClick={() => setCredentialInputsVisible(true)}
              />
            )
          ) : (
            <SmallButton
              type="primary"
              text="Save"
              disabled={!(username && password) || isSavingIntegration}
              onClick={onSave}
            />
          )}
        </Modal.Footer>
      </form>
    </Modal>
  ) : null;
};
