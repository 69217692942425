import { defineMessages } from "react-intl";
//import { STATUSES } from "models/zoneInformation";
export default defineMessages({
  retrieving: {
    id: "app.containers.ArmingWidget.retrieving",
    defaultMessage: "Retrieving Armed Status...",
  },
  armingTitle: {
    id: "app.containers.ArmingWidget.armingTitle",
    defaultMessage: "Arming",
  },
  areasTitle: {
    id: "app.containers.ArmingWidget.areasTitle",
    defaultMessage: "Areas",
  },
  clickInstructions: {
    id: "app.containers.ArmingWidget.clickInstructions",
    defaultMessage: "Click to {instruction}.",
  },
  holdForInstant: {
    id: "app.containers.ArmingWidget.holdForInstant",
    defaultMessage: "Click and hold to arm instant",
  },
  arm: {
    id: "app.containers.ArmingWidget.arm",
    defaultMessage: "Arm",
  },
  armed: {
    id: "app.containers.ArmingWidget.armed",
    defaultMessage: "Armed",
  },
  arming: {
    id: "app.containers.ArmingWidget.arming",
    defaultMessage: "Arming...",
  },
  armingArea: {
    id: "app.containers.ArmingWidget.armingArea",
    defaultMessage: "Arming {area}...",
  },
  armingAreaInstant: {
    id: "app.containers.ArmingWidget.armingAreaInstant",
    defaultMessage: "Arming {area} instant...",
  },
  armArea: {
    id: "app.containers.ArmingWidget.armArea",
    defaultMessage: "Arm {area}",
  },
  disarm: {
    id: "app.containers.ArmingWidget.disarm",
    defaultMessage: "Disarm",
  },
  disarmed: {
    id: "app.containers.ArmingWidget.disarmed",
    defaultMessage: "Disarmed",
  },
  disarming: {
    id: "app.containers.ArmingWidget.disarming",
    defaultMessage: "Disarming...",
  },
  disarmArea: {
    id: "app.containers.ArmingWidget.disarmArea",
    defaultMessage: "Disarm {area}",
  },
  cancel: {
    id: "app.containers.ArmingWidget.cancel",
    defaultMessage: "Stop",
  },
  armAll: {
    id: "app.containers.ArmingWidget.armAll",
    defaultMessage: "Arm All",
  },
  disarmAll: {
    id: "app.containers.ArmingWidget.disarmAll",
    defaultMessage: "Disarm All",
  },
  home: {
    id: "app.containers.ArmingWidget.home",
    defaultMessage: "Home",
  },
  stay: {
    id: "app.containers.ArmingWidget.stay",
    defaultMessage: "Stay",
  },
  sleep: {
    id: "app.containers.ArmingWidget.sleep",
    defaultMessage: "Sleep",
  },
  away: {
    id: "app.containers.ArmingWidget.away",
    defaultMessage: "Away",
  },
  all: {
    id: "app.containers.ArmingWidget.all",
    defaultMessage: "All",
  },
  perimeter: {
    id: "app.containers.ArmingWidget.perimeter",
    defaultMessage: "Perimeter",
  },
  statusMessage: {
    id: "app.containers.ArmingWidget.statusMessage",
    defaultMessage: "Your system is currently {status}.",
  },
  bypass: {
    id: "app.containers.ArmingWidget.bypass",
    defaultMessage: "Bypass",
  },
  bypassFull: {
    id: "app.containers.ArmingWidget.bypassFull",
    defaultMessage: "Bypass - Ignore while armed",
  },
  okay: {
    id: "app.containers.ArmingWidget.okay",
    defaultMessage: "Okay",
  },
  okayFull: {
    id: "app.containers.ArmingWidget.okayFull",
    defaultMessage: "Okay - Include when restored",
  },
  badZonesHeadline: {
    id: "app.containers.ArmingWidget.badZonesHeadline",
    defaultMessage:
      "You have {count, number} Faulted {count, plural, one {Zone} other {Zones}}",
  },
  badZonesWithNameHeadline: {
    id: "app.containers.ArmingWidget.badZonesWithNameHeadline",
    defaultMessage:
      "The {system} has {count, number} Faulted {count, plural, one {Zone} other {Zones}}",
  },
  inAlarm: {
    id: "app.containers.ArmingWidget.inAlarm",
    defaultMessage: "in alarm",
  },
  alarm: {
    id: "app.containers.ArmingWidget.alarm",
    defaultMessage: "alarm",
  },
  findArea: {
    id: "app.containers.ArmingWidget.findArea",
    defaultMessage: "Find area...",
  },
  zoneStatus: {
    id: "app.containers.ArmingWidget.zoneStatus",
    defaultMessage: "Zone Status",
  },
  closeZonesWidget: {
    id: "app.containers.ArmingWidget.closeZonesWidget",
    defaultMessage: "Close",
  },
  zoneName: {
    id: "app.containers.ArmingWidget.zoneName",
    defaultMessage: "Name",
  },
  statusOfZone: {
    id: "app.containers.ArmingWidget.statusOfZone",
    defaultMessage: "Status",
  },
  // [STATUSES.MISSING]: {
  //   id: "app.containers.ArmingWidget.statusMissing",
  //   defaultMessage: "Missing",
  // },
  // [STATUSES.LOW_BATTERY]: {
  //   id: "app.containers.ArmingWidget.statusLowBattery",
  //   defaultMessage: "Low Battery",
  // },
  // [STATUSES.NORMAL]: {
  //   id: "app.containers.ArmingWidget.statusNormal",
  //   defaultMessage: "Normal",
  // },
  // [STATUSES.SHORT]: {
  //   id: "app.containers.ArmingWidget.statusShort",
  //   defaultMessage: "Short",
  // },
  // [STATUSES.OPEN]: {
  //   id: "app.containers.ArmingWidget.statusOpen",
  //   defaultMessage: "Open",
  // },
  // [STATUSES.BYPASSED]: {
  //   id: "app.containers.ArmingWidget.statusBypass",
  //   defaultMessage: "Bypassed",
  // },
  noFaultedZones: {
    id: "app.containers.ArmingWidget.noFaultedZones",
    defaultMessage: "No faulted zones",
  },
  faultedZones: {
    id: "app.containers.ArmingWidget.faultedZones",
    defaultMessage: "Faulted Zones",
  },
  okZones: {
    id: "app.containers.ArmingWidget.okZones",
    defaultMessage: "OK Zones",
  },
  bypassAll: {
    id: "app.containers.ArmingWidget.bypassAll",
    defaultMessage: "Bypass All",
  },
  zoneArmedCannotBypass: {
    id: "app.containers.ArmingWidget.zoneArmedCannotBypass",
    defaultMessage: "Zone is currently armed. Cannot change bypass.",
  },
  failedToRetrieveArmedStatus: {
    id: "app.containers.ArmingWidget.failedToRetreiveArmedStatus",
    defaultMessage: "Failed to retrieve armed status.",
  },
});
