import { castAs } from "common/utils/universal/types";
import { split } from "ramda";

/**
 * In Relay the ID is a unique identifier for any Node.
 * The GraphQL layer generate ID's with metadata
 * to make the ID unique and refetchable.
 *
 * The ID type is actually a string, however the clients
 * are not fully using the GraphQL server for every call.
 * This ID provides clients the ability to know they are
 * explicilty working with a GraphQL specific ID.
 */
export type ID = { __ID_PLACEHOLDER_FOR_CLIENT: string };

/**
 * Force casts ID type as string.
 * Note: the Relay ID is actually a string.
 */
export const asString = (id: any) => castAs<string>(id);

/**
 * Alias of asString
 */
export const idAsString = asString;

/**
 * Force casts an id as an ID.
 */
export const asID = (id: string | number | ID) => castAs<ID>(id);

export const toGlobalId = (...args: (string | number)[]): ID =>
  asID(Buffer.from(args.join(":")).toString("base64"));

export const fromGlobalId = (id: ID) =>
  split(":", Buffer.from(asString(id), "base64").toString("ascii"));
