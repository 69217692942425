/**
 * @generated SignedSource<<46cc2166bc336e66a4b8b464d07b7458>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75DeviceSetupProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly axBusDoorDevicesMax: number;
    readonly deviceInformations: ReadonlyArray<{
      readonly __typename: string;
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XT75DeviceSetupProgrammingConceptFormInline_deviceInformation">;
    }>;
    readonly deviceNumberRange: ReadonlyArray<number>;
    readonly id: string;
    readonly keypadBusDevicesMax: number;
    readonly totalDevicesMax: number;
  };
  readonly " $fragmentType": "XT75DeviceSetupProgrammingConceptFormInline_controlSystem";
};
export type XT75DeviceSetupProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75DeviceSetupProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75DeviceSetupProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75DeviceSetupProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "5930ccbc1a8ee3eb733ac16588d3f16e";

export default node;
