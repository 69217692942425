import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsExitDelayField_systemOptions$key } from "./__generated__/SystemOptionsExitDelayField_systemOptions.graphql";

export const systemOptionsExitDelayFieldId = () => "system-options-exit-delay";

function ExitDelayField() {
  const [{ exitDelay, exitDelayMin, exitDelayMax }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsExitDelayField_systemOptions$key>(
      graphql`
        fragment SystemOptionsExitDelayField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            exitDelay
            exitDelayMin
            exitDelayMax
          }
          ... on XtSystemOptions {
            exitDelay
            exitDelayMin
            exitDelayMax
          }
          ... on TMSentrySystemOptions {
            exitDelay
            exitDelayMin
            exitDelayMax
          }
        }
      `
    );

  const fieldId = systemOptionsExitDelayFieldId();
  const originalValue = React.useRef(exitDelay).current;
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Exit Delay"
      tooltip="Set the duration for Exit Delay in seconds. Applies to all Exit Zones."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={exitDelay}
        min={exitDelayMin}
        max={exitDelayMax}
        inlineHelp={`${exitDelayMin}-${exitDelayMax}`}
        validationMessage={`Valid values are ${exitDelayMin}-${exitDelayMax}`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "exitDelay"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(exitDelayMin, exitDelayMax, valueAsNumber);
            recordProxy.setValue(value, "exitDelay");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ExitDelayField;
