/**
 * @generated SignedSource<<1989393af4757d64ca5b787d65f86c1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaArmFirstAreaField_xrArea$data = {
  readonly armFirstArea: boolean;
  readonly number: string;
  readonly " $fragmentType": "AreaArmFirstAreaField_xrArea";
};
export type AreaArmFirstAreaField_xrArea$key = {
  readonly " $data"?: AreaArmFirstAreaField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaArmFirstAreaField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaArmFirstAreaField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armFirstArea",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "acbe474a560c3e3dbce79e540e670fdd";

export default node;
