App.factory("ModemUpgradeSettingsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/Dealers/:dealer_id/ModemUpgradeSettings",
      {},
      {
        getModemUpgradeSettings: {
          method: "GET",
          params: { dealer_id: "@dealer_id" },
        },
        createModemUpgradeSettings: {
          method: "POST",
          params: { dealer_id: "@dealer_id" },
        },
        updateModemUpgradeSettings: {
          method: "PUT",
          params: { dealer_id: "@dealer_id" },
        },
      }
    );
  },
]);
