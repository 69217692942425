import graphql from "babel-plugin-relay/macro";
import { Badge } from "components/DaStyledElements";
import GenericSuspenseFallback from "components/GenericSuspenseFallback";
import React from "react";
import {
  commitLocalUpdate,
  fetchQuery,
  RelayEnvironmentProvider,
  useFragment,
  useLazyLoadQuery,
  useRelayEnvironment,
} from "react-relay";
import { react2angular } from "react2angular";
import {
  idAsString,
  toControlSystemId,
  toCustomerId,
} from "securecom-graphql/client";
import styled from "styled-components";
import { StatusBadgeAllBadgesQuery } from "./__generated__/StatusBadgeAllBadgesQuery.graphql";
import { StatusBadgeFragment$key } from "./__generated__/StatusBadgeFragment.graphql";

const FlexContainer = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
`;

export const StatusBadge: React.FC<
  React.PropsWithChildren<{
    controlSystem: StatusBadgeFragment$key;
  }>
> = ({ controlSystem: fragmentRef }) => {
  const controlSystem = useFragment(
    graphql`
      fragment StatusBadgeFragment on ControlSystem {
        id
        centralStationAutomationIntegration {
          id
          name
          systemTestExpiresAt
        }
      }
    `,
    fragmentRef
  );

  const systemData = controlSystem?.centralStationAutomationIntegration;

  return (
    <React.Suspense fallback={<GenericSuspenseFallback />}>
      <FlexContainer>
        {systemData?.systemTestExpiresAt ? (
          <Badge stateColor="warning" message="ON TEST" />
        ) : systemData ? (
          <Badge stateColor="success" message="CONNECTED" />
        ) : null}
      </FlexContainer>
    </React.Suspense>
  );
};

const query = graphql`
  query StatusBadgeAllBadgesQuery($customerId: ID!) {
    node(id: $customerId) {
      id
      ... on Customer {
        controlSystems {
          nodes {
            __typename
            id
            ...StatusBadgeFragment
          }
        }
      }
    }
  }
`;

const BatchCustomerStatusBadges: React.FC<
  React.PropsWithChildren<{
    customerId: string;
    systemId: string;
    environment: any;
  }>
> = ({ customerId, systemId, environment }) => {
  const data = useLazyLoadQuery<StatusBadgeAllBadgesQuery>(query, {
    customerId,
  });

  const controlSystem =
    data?.node?.controlSystems?.nodes?.find(
      (controlSystem) => controlSystem.id === systemId
    ) ?? null;

  React.useEffect(() => {
    // if there is no control system, it might be because we have created a new
    // control system or something, so just try fetching it again and it should
    // update the data above if it comes back on that
    if (!controlSystem) {
      fetchQuery<StatusBadgeAllBadgesQuery>(
        environment,
        query,
        { customerId },
        { fetchPolicy: "network-only" }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlSystem]);

  return controlSystem ? <StatusBadge controlSystem={controlSystem} /> : null;
};

//We only need to use this function below to render on an Angular page/component. Likely will not be used later, so up for possible deletion.
export const StatusBadgeRoot: React.FC<
  React.PropsWithChildren<{
    customerId: number;
    systemId: number;
    RelayService: any;
  }>
> = ({ customerId, systemId, RelayService }) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense
        fallback={
          <React.Suspense fallback={<GenericSuspenseFallback />}>
            <FlexContainer>
              <Badge message="LOADING..." />
            </FlexContainer>
          </React.Suspense>
        }
      >
        <BatchCustomerStatusBadges
          systemId={idAsString(toControlSystemId(systemId))}
          customerId={idAsString(toCustomerId(customerId))}
          environment={environment}
        ></BatchCustomerStatusBadges>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "statusBadge",
    react2angular(StatusBadgeRoot, ["systemId", "customerId"], ["RelayService"])
  );
};

export const resetControlSystemIntegrations = (relayEnv: any) => {
  commitLocalUpdate(relayEnv, (store) => {
    const ids: string[] =
      relayEnv.getStore()?.getSource()?.getRecordIDs() ?? [];

    ids.forEach((id) => {
      const record = store.get(id);

      if (record?.getValue("__typename") === "ControlSystem") {
        record.invalidateRecord();
      }
    });
  });
};

export const useResetControlSystemIntegrations = () => {
  const relayEnv = useRelayEnvironment();

  return () => resetControlSystemIntegrations(relayEnv);
};
