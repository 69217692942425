App.factory("DealersBySalesTerritoryAPI", [
  "$resource",
  "PROPS",
  ($resource, PROPS) => {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/DmpData.GetDealersForUserTerritories()",
      {},
      {
        getFilteredDealerList: {
          method: "GET",
          params: {
            territory_list: "@territory_list",
          },
        },
      }
    );
  },
]);
