/**
 * @generated SignedSource<<0d2d197823a97188b4e3359216d502c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRFullProgramming_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHasNetworkCommType_panel" | "PanelContextUseHasWifiCommType_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem" | "FullProgrammingForm_controlSystem" | "ProgrammingTemplateForm_controlSystem" | "XRAreaInformationProgrammingConceptFormNavButton_controlSystem" | "XRAreaInformationProgrammingConceptForm_controlSystem" | "XRBellOptionsProgrammingConceptForm_controlSystem" | "XRCardFormatsProgrammingConceptFormNavButton_controlSystem" | "XRCardFormatsProgrammingConceptForm_controlSystem" | "XRCommunicationProgrammingConceptFormNavButton_controlSystem" | "XRCommunicationProgrammingConceptForm_controlSystem" | "XRDeviceSetupProgrammingConceptFormNavButton_controlSystem" | "XRDeviceSetupProgrammingConceptForm_controlSystem" | "XRFeatureKeysProgrammingConceptForm_controlSystem" | "XRKeyFobsProgrammingConceptFormNavButton_controlSystem" | "XRKeyFobsProgrammingConceptForm_controlSystem" | "XRLockoutCodeProgrammingConceptForm_controlSystem" | "XRMenuDisplayProgrammingConceptForm_controlSystem" | "XRMessagingSetupProgrammingConceptForm_controlSystem" | "XRNetworkOptionsProgrammingConceptForm_controlSystem" | "XROutputGroupsProgrammingConceptFormNavButton_controlSystem" | "XROutputGroupsProgrammingConceptForm_controlSystem" | "XROutputInformationProgrammingConceptFormNavButton_controlSystem" | "XROutputInformationProgrammingConceptForm_controlSystem" | "XROutputOptionsProgrammingConceptForm_controlSystem" | "XRPcLogReportsProgrammingConceptForm_controlSystem" | "XRRemoteOptionsProgrammingConceptForm_controlSystem" | "XRSecurityGradeProgrammingConceptForm_controlSystem" | "XRStatusListDisplayProgrammingConceptForm_controlSystem" | "XRSystemOptionsProgrammingConceptForm_controlSystem" | "XRSystemReportsProgrammingConceptForm_controlSystem" | "XRZoneInformationProgrammingConceptFormNavButton_controlSystem" | "XRZoneInformationProgrammingConceptForm_controlSystem">;
  readonly " $fragmentType": "XRFullProgramming_controlSystem";
};
export type XRFullProgramming_controlSystem$key = {
  readonly " $data"?: XRFullProgramming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRFullProgramming_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRFullProgramming_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FullProgrammingForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRCommunicationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRCommunicationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRNetworkOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRDeviceSetupProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRDeviceSetupProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRCardFormatsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRCardFormatsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRRemoteOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRSystemReportsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRSystemOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRBellOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XROutputInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRPcLogReportsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XROutputInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XROutputOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XROutputGroupsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XROutputGroupsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRMenuDisplayProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRStatusListDisplayProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRAreaInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRAreaInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRZoneInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRZoneInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRKeyFobsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRKeyFobsProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRLockoutCodeProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRFeatureKeysProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRMessagingSetupProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRSecurityGradeProgrammingConceptForm_controlSystem"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasNetworkCommType_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasWifiCommType_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "0596e0309ce205e2582766515f469f60";

export default node;
