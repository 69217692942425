import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  createPlaceholderFromRange,
  findNearestValueInRange,
} from "../../utils/display";
import { useHardwareModel, usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { CommPathTransmissionDelayField_panel$key } from "./__generated__/CommPathTransmissionDelayField_panel.graphql";

export const commPathTransmissionDelayFieldId = () =>
  "comm-path-transmission-delay";

function CommPathTransmissionDelayField() {
  const [{ id: panelId, communicationPaths }] =
    usePanelFragment<CommPathTransmissionDelayField_panel$key>(
      graphql`
        fragment CommPathTransmissionDelayField_panel on Panel {
          id
          communicationPaths {
            id
            transmissionDelay
            transmissionDelayValidValues
          }
        }
      `
    );

  const fieldId = commPathTransmissionDelayFieldId();
  const relayEnv = useRelayEnvironment();

  const { current: originalValue } = React.useRef(
    communicationPaths[0]?.transmissionDelay ?? 30
  );

  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);

  if (!communicationPaths[0] || isXf) {
    return null;
  }

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Transmission Delay"
      tooltip="Number of seconds the System waits before sending a Burglary Alarm to the Receiver."
    >
      <NumericInput
        id={fieldId}
        value={communicationPaths?.[0]?.transmissionDelay?.toString()}
        required
        inlineHelp={createPlaceholderFromRange(
          communicationPaths?.[0]?.transmissionDelayValidValues
            ? communicationPaths?.[0]?.transmissionDelayValidValues
            : []
        )}
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const panelRecord = store.get(panelId);
            if (panelRecord) {
              const commPathRecords =
                panelRecord?.getLinkedRecords("communicationPaths");
              if (commPathRecords) {
                commPathRecords.map((commPaths) =>
                  commPaths.setValue(
                    emptyStringOrNumber(target.value),
                    "transmissionDelay"
                  )
                );
              }
            }
          });
        }}
        onBlur={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : findNearestValueInRange(
                    valueAsNumber,
                    communicationPaths[0].transmissionDelayValidValues
                  );
            const panelRecord = store.get(panelId);
            if (panelRecord) {
              const commPathRecords =
                panelRecord?.getLinkedRecords("communicationPaths");
              if (commPathRecords) {
                commPathRecords.map((commPaths) =>
                  commPaths.setValue(Number(value), "transmissionDelay")
                );
              }
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathTransmissionDelayField;
