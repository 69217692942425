import React from "react";
import { joinSpaced } from "utils/string";
import styles from "./VideoSection.module.css";

export default function Grid(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={joinSpaced(props.className || "", styles["grid"])}
    />
  );
}

Grid.Header = function GridHeader(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={joinSpaced(
        props.className || "",
        styles["grid-content-header"]
      )}
    />
  );
};
