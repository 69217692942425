/**
 * App.service.PersonsService
 *
 * Service for persons.
 *
 * A person is the top level object, representing an individual. A person can have many Dealer users (personnel),
 * customer users (app users) or credentials associated with them.
 */
App.service("PersonsService", [
  "$q",
  "PersonsAPI",
  function ($q, PersonsAPI) {
    // Public accessors
    this.addLogin = addLogin;
    this.update = update;
    this.resetAccess = resetAccess;

    // Private functions and variables
    /**
     * Get a list of persons
     * @param {[int]} personIds
     * @returns {*}
     */
    function get(personIds) {
      var deferred = $q.defer();
      var personCSV = angular.isArray(personIds)
        ? personIds.join(",")
        : personIds;
      PersonsAPI.list(
        { idList: personCSV },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "PersonsService->get() - error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Update an existing person
     * @param {{}} person
     * @returns {*}
     */
    function update(person) {
      var deferred = $q.defer();
      var SCAPIPerson = {
        person: person,
      };
      PersonsAPI.update(
        { id: person.id },
        angular.toJson(SCAPIPerson),
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "PersonsService->update() - error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Create a new person
     * @param {{}} person
     * @returns {*}
     */
    function create(person) {
      var deferred = $q.defer();
      PersonsAPI.create(
        {},
        angular.toJson(person),
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "PersonsService->create() - error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Merge persons together.
     * @param {int} acquirerId - The id of the person that will remain after the merge
     * @param {[*]} toBeMerged - An array of personUsers to be merged under the acquirer
     * @returns {*}
     */
    function merge(acquirerId, toBeMerged) {
      var deferred = $q.defer();
      var body = { merge_ids: [] };
      angular.forEach(toBeMerged, function (personUser) {
        if (
          !isUndefinedOrNull(personUser) &&
          personUser.hasOwnProperty("person_id")
        ) {
          var personId = +personUser.person_id;
          // Users selected may already have the same person_id. Only need 1 record of each.
          if (personId > 0 && body.merge_ids.indexOf(personId) === -1) {
            body.merge_ids.push(personId);
          }
        }
      });
      PersonsAPI.merge(
        { id: acquirerId },
        angular.toJson(body),
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "PersonsService->merge() - error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Reset access for a person with a primary user locked
     * @param id - person id
     * @param emailAddress - person email_address
     * @returns {*}
     */
    function resetAccess(id, emailAddress) {
      var deferred = $q.defer();
      PersonsAPI.resetAccess(
        { id: id, email: emailAddress },
        {},
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "PersonsService->resetAccess() - error: " + angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Function to create a user, dealer (personnel) or customer (app user), and add it to a person
     * @param {*} user - The user to be created, built from a DA model
     */
    function addLogin(user) {
      var deferred = $q.defer();
      user.email = null;
      user.hidden = true;
      user.password = null;
      user.passwordConfirm = null;
      user
        .create(true)
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "PersonsService->addLogin() - create user error: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }
  },
]);
