import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { CommunicationPathType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathDuplicateAlarmsField_communicationPath$key } from "./__generated__/CommPathDuplicateAlarmsField_communicationPath.graphql";

export const commPathDuplicateAlarmsFieldId = (number: string) =>
  `comm-path-duplicate-alarms-${number}`;

function CommPathDuplicateAlarmsField() {
  const [{ number, duplicateAlarms, type }, updateCommPath] =
    useCommPathFragment<CommPathDuplicateAlarmsField_communicationPath$key>(
      graphql`
        fragment CommPathDuplicateAlarmsField_communicationPath on CommunicationPath {
          number
          duplicateAlarms
          type
        }
      `
    );

  const fieldId = commPathDuplicateAlarmsFieldId(String(number));
  const disabled = type !== CommunicationPathType.BACKUP;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Duplicate Alarms"
      tooltip={
        "Send duplicate alarm messages for any alarm messages occurring on the associated primary path."
      }
      disabled={disabled}
    >
      <Switch
        label="Use 893A"
        id={fieldId}
        checked={duplicateAlarms}
        disabled={disabled}
        onChange={() => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(!duplicateAlarms, "duplicateAlarms");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathDuplicateAlarmsField;
