/**
 * @generated SignedSource<<4a4e8c45402083ec0e18ffe2d2cbcae2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type XTAreaInformationProgrammingConceptFormInline_area$data = {
  readonly __typename: string;
  readonly autoArmEnabled: boolean;
  readonly autoDisarmEnabled: boolean;
  readonly badZonesOption: BadZonesOption;
  readonly hasChanges: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: string;
  readonly " $fragmentType": "XTAreaInformationProgrammingConceptFormInline_area";
};
export type XTAreaInformationProgrammingConceptFormInline_area$key = {
  readonly " $data"?: XTAreaInformationProgrammingConceptFormInline_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTAreaInformationProgrammingConceptFormInline_area">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTAreaInformationProgrammingConceptFormInline_area"
};

(node as any).hash = "546b729da71b468acfe8363e4d5c0de4";

export default node;
