import DaColors from "app/common/DaColors";
import styled from "styled-components/macro";

const FlexDisplayStandard = `display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > *:not(:last-child) {
    margin-bottom: var(--measure-1x);
    margin-right: 0;
  }
  @media (min-width: 76.8rem) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: var(--measure-1x);
    }
  }`;
const ConfirmButtonStandard = `  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > *:not(:last-child) {
    margin-bottom: var(--measure-1x);
    margin-right: 0;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: var(--measure-half);
    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0;
    }
  }`;

export const PageHeader = styled.div`
  margin-top: 0;
  padding: 1.6rem 0;
  border-bottom: 1px solid ${DaColors.Neutral25};
  margin-bottom: var(--measure-3x);
  width: 100%;
  ${FlexDisplayStandard}
`;

export const PageHeaderLeft = styled.div`
  ${FlexDisplayStandard}
  flex: 0 0 1;
`;

export const PageHeaderRight = styled.div`
  ${FlexDisplayStandard}
  flex: 0 0 1;
`;

export const PageHeaderConfirm = styled.div`
  ${ConfirmButtonStandard}
`;

export const PageHeaderTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--measure-3x);
  line-height: 1;
  > *:first-child {
    padding-right: var(--measure-1x);
  }
  @media print {
    padding-left: var(--measure-3x);
  }
`;
