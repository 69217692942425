/**
 * @ngdoc object
 * @name App.controller:DealerCustomers
 * @description
 *  Controller for the Dealer's Customer List
 */
App.controller("DealerCustomers", [
  "UserService",
  "ControlSystemsService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$filter",
  "$scope",
  "$compile",
  "$q",
  "$rootScope",
  "$window",
  "$sanitize",
  "DataTablesUtilsService",
  function (
    UserService,
    ControlSystemsService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $filter,
    $scope,
    $compile,
    $q,
    $rootScope,
    $window,
    $sanitize,
    DataTablesUtilsService
  ) {
    //set the local scope to match the DOM scope name
    var customer_list = this;
    //Expose the User Service to the DOM
    $scope.UserService = UserService;

    /**
     * @ngdoc object
     * @name clearTable
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Clears the list of customers from the User Service cache, resets the filter values, and rerenders the grid.
     */
    $scope.clearTable = function () {
      localStorage.removeItem("DataTables_CustomerDT_/");
      UserService.customerListCached = undefined;
      //$scope.dtInstance.changeData(getList(true));
      //$scope.dtInstance.rerender();
    };

    /**
     * @ngdoc object
     * @name getList
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns a the list of customers for the selected Dealer.  (Called from the DTOptionsBuilder.)
     *
     * @returns Promise A Promise of the list of dealer customers.
     *
     */
    var getList = function (refresh) {
      refresh = typeof refresh == "undefined" ? false : refresh;
      var deferred = $q.defer();
      var _this = this;
      UserService.dealerInfo
        .getDealerCustomerList(refresh)
        .then(
          function (data) {
            $scope.detailedCustomerList = data;
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving customer list",
              json: error,
            });
          },
          function (info) {}
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    //Defines the options/features for the Customer List grid
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(getList());
    $scope.dtOptions.withOption("createdRow", function (row) {
      DataTablesUtilsService.createdRow(row, $scope);
    });
    $scope.dtOptions.withOption("order", [0, "asc"]);
    //List of columns, column properties, and custom methods used to display the data in the Data Table.
    //****************NOTE************* IF A NEW COLUMN IS ADDED TO THIS GRID YOU MUST UPDATE THE USER SERVICE TO UPDATE THE CACHED VERSION OF THIS DATA
    $scope.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.navToCustomerSummary(
            row.customer,
            "customer_dealer_id",
            "customer_id",
            "customer_name"
          );
        })
        .withOption("aDataSort", [0, 1]),
      DTColumnBuilder.newColumn(null)
        .withTitle("Account")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.navToSystemView(
            row.customer,
            "customer_id",
            "control_system_id",
            "panel_account_prefix",
            "panel_account_number"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row.customer,
            "control_system_name"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("System Type")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asHardwareModel(
            row.customer,
            "panel_hardware_model",
            "panel_hardware_model_name"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("System Version")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row.customer,
            "panel_software_version"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Software Date")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asFirmwareDate(
            row.customer,
            "panel_software_date"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Connection Type")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.commType(
            row.customer,
            "panel_comm_type"
          );
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("App")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.hasApp(row.customer);
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Network Address")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.commIPAddress(
            row.customer,
            "panel_comm_address"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn("customer.panel_last_connected_at")
        .withTitle("Last Connection")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn())
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Arming Type")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row.customer,
            "panel_arming_system"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Serial Number")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row.customer,
            "panel_serial_number"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Cellular Number")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.commCellNumber(
            row.customer,
            "panel_comm_address"
          );
        })
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Account")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(
            row.customer,
            "customer_dealer_account"
          );
        })
        .notVisible(),
      //****************NOTE************* IF A NEW COLUMN IS ADDED TO THIS GRID YOU MUST UPDATE THE USER SERVICE TO UPDATE THE CACHED VERSION OF THIS DATA
    ];
  },
]);
