/**
 * @generated SignedSource<<2f894ea4d9583f8e823361c4f446bcf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseHasNetworkCommType_panel$data = {
  readonly communication: {
    readonly xtCommType: CommunicationComType;
  } | null;
  readonly communicationPaths: ReadonlyArray<{
    readonly xfCommType: CommunicationPathCommType;
    readonly xrCommType: CommunicationPathCommType;
  }>;
  readonly " $fragmentType": "PanelContextUseHasNetworkCommType_panel";
};
export type PanelContextUseHasNetworkCommType_panel$key = {
  readonly " $data"?: PanelContextUseHasNetworkCommType_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHasNetworkCommType_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseHasNetworkCommType_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CommunicationPath",
      "kind": "LinkedField",
      "name": "communicationPaths",
      "plural": true,
      "selections": [
        {
          "alias": "xrCommType",
          "args": null,
          "kind": "ScalarField",
          "name": "commType",
          "storageKey": null
        },
        {
          "alias": "xfCommType",
          "args": null,
          "kind": "ScalarField",
          "name": "commType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Communication",
      "kind": "LinkedField",
      "name": "communication",
      "plural": false,
      "selections": [
        {
          "alias": "xtCommType",
          "args": null,
          "kind": "ScalarField",
          "name": "comType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "d4e6f34fcc925ac22ac1dd605bfb637f";

export default node;
