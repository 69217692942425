import GenericSelect, { SelectProps } from "components/Select";
import * as React from "react";
import { useFieldChangesAndValidity } from "../hooks/use-field-changes-and-validity";

const Select = React.forwardRef(
  (
    props: Omit<SelectProps, "id"> & {
      id: string;
      getValidationMessage?: (input: HTMLSelectElement) => string;
    },
    ref: React.ForwardedRef<HTMLSelectElement | null>
  ) => {
    const {
      id,
      value,
      onChange,
      onFocus,
      onBlur,
      onInvalid,
      getValidationMessage,
      ...rest
    } = props;
    return (
      <GenericSelect
        {...rest}
        {...useFieldChangesAndValidity({
          ref,
          id,
          value,
          onChange,
          onFocus,
          onBlur,
          onInvalid,
          getValidationMessage,
        })}
      />
    );
  }
);

export default Object.assign(Select, {
  Option: GenericSelect.Option,
});
