/**
 * @generated SignedSource<<4091e1897e51e768b3ec819f0428fe6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type DigitalWatchdogSpectrumType = "BLACKJACK" | "CLOUD";
import { FragmentRefs } from "relay-runtime";
export type DigitalWatchdogSpectrumTypeButton_site$data = {
  readonly billingControlSystemId: string;
  readonly digitalWatchdogSpectrumNvrs: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly digitalWatchdogSpectrumType: DigitalWatchdogSpectrumType;
  readonly id: string;
  readonly " $fragmentType": "DigitalWatchdogSpectrumTypeButton_site";
};
export type DigitalWatchdogSpectrumTypeButton_site$key = {
  readonly " $data"?: DigitalWatchdogSpectrumTypeButton_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"DigitalWatchdogSpectrumTypeButton_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./DigitalWatchdogSpectrumTypeRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "DigitalWatchdogSpectrumTypeButton_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingControlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "digitalWatchdogSpectrumType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DigitalWatchdogSpectrumNvr",
      "kind": "LinkedField",
      "name": "digitalWatchdogSpectrumNvrs",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "8d43dd299f51d4f3d65f8a8a4d04bac6";

export default node;
