import { HikDoorbellStates } from "./cs-hik-doorbell-states";
/**
 * Contains details about a doorbell's firmware update.
 */
export class HikDoorbellUpdateStatus {
  /**
   * Initializes a new instance.
   * @param {*} $log The logger.
   * @param {*} thirdPartyVideo The third-party video service.
   */
  constructor($log, thirdPartyVideo) {
    this.$log = $log;
    this.thirdPartyVideo = thirdPartyVideo;

    let defaultCode =
      this.thirdPartyVideo.hikvisionDoorbells.firmwareStatusCodes.UPGRADED;

    /* ----------------------------------------------------
    PROPERTIES
    ---------------------------------------------------- */

    /** Whether the user can trigger a firmware update. */
    this.canUpdate = false;

    /** Whether the user can refresh the device status. Example: device is offline or there was and unknown error. */
    this.canRefresh = false;

    /** Whether an error (of any kind) occurred. */
    this.isError = false;

    /** Whether Dealer Admin is busy checking/waiting/monitoring something for this device.
        Used to show a busy indicator and disable buttons. */
    this.isBusy = false;

    /** Whether a firmware update is available. */
    this.isUpdateAvailable = false;

    /** Whether an update is in progress (or device is rebooting). */
    this.isUpdateInProgress = false;

    /** A message to display for update status. */
    this.message = "";

    /** The progress of the update [0, 100] (only valid if status is UPGRADING). */
    this.progress = 0;

    /** The device state used by Dealer Admin. */
    this.state = HikDoorbellStates.UP_TO_DATE;

    /** The status code returned from the third-party video API. */
    this.updateStatusCode = defaultCode;
  }

  /* ------------------------------------------------------
  METHODS
  ------------------------------------------------------ */

  /**
   * Sets the device state and sets the update status based on the new state.
   * @param {int} state The device state (HikDoorbellStates).
   */
  setState(state) {
    this.state = state;
    this.isInProgress = false;
    this.isError = false;
    this.isBusy = false;

    // If checking, don't change canRefresh, canUpdate
    if (state == HikDoorbellStates.CHECKING) {
      this.message = "Checking for updates...";
      this.isBusy = true;
      return;
    }

    // Available actions
    this.canRefresh = false;
    this.canUpdate = false;

    // Set the update status message and check if in progress
    switch (state) {
      case HikDoorbellStates.UPDATING:
        this.message = `Updating...${this.progress}%`;
        this.isInProgress = true;
        this.isBusy = true;
        this.canUpdate = true;
        break;
      case HikDoorbellStates.REBOOTING:
        this.message = "Device rebooting";
        this.isInProgress = true;
        this.isBusy = true;
        this.canUpdate = true;
        break;
      case HikDoorbellStates.UP_TO_DATE:
        this.message = "Up-to-date";
        break;
      case HikDoorbellStates.OUT_OF_DATE:
        this.message = "Update available";
        this.canUpdate = true;
        break;
      case HikDoorbellStates.ERROR_FAILED:
        this.message = "Update failed";
        this.isError = true;
        this.canUpdate = true;
        break;
      case HikDoorbellStates.ERROR_OFFLINE:
        this.message = "Device offline";
        this.isError = true;
        this.canRefresh = true;
        break;
      case HikDoorbellStates.ERROR_UNKNOWN:
        this.message = "Error";
        this.isError = true;
        this.canRefresh = true;
        break;
      default:
        this.state = HikDoorbellStates.ERROR_UNKNOWN;
        this.message = "Error";
        this.isError = true;
        this.canRefresh = true;
        break;
    }
  }
}
