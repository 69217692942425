/**
 * @generated SignedSource<<d40ff4c7203fb67fc9f9abe4a6a0adcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathAlarmSwitchoverField_communicationPath$data = {
  readonly alarmSwitchover: number;
  readonly alarmSwitchoverMax: number;
  readonly alarmSwitchoverMin: number;
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly " $fragmentType": "CommPathAlarmSwitchoverField_communicationPath";
};
export type CommPathAlarmSwitchoverField_communicationPath$key = {
  readonly " $data"?: CommPathAlarmSwitchoverField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathAlarmSwitchoverField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathAlarmSwitchoverField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alarmSwitchover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alarmSwitchoverMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alarmSwitchoverMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "caad31852a07df8c7bba3e2c8efb185a";

export default node;
