/**
 * @generated SignedSource<<924fbc7dec676e5e661566f0d8e108c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFDeviceInformationNumberField_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly number: number;
  }>;
  readonly " $fragmentType": "XFDeviceInformationNumberField_panel";
};
export type XFDeviceInformationNumberField_panel$key = {
  readonly " $data"?: XFDeviceInformationNumberField_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceInformationNumberField_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFDeviceInformationNumberField_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "a0956549ca2a15d35fbd17da6e3b6a02";

export default node;
