//function takes in a url for an image and fetches the image data and returns a local url

export const fetchImageSrcFromUrlWithBearerAuth = async (
  url: string,
  auth_token: string
): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    });
    const blob = await response.blob();
    if (response.ok) {
      return URL.createObjectURL(blob);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};
// use this for all static image resources
// a bearer token forces will not work for static img assets

export const fetchImageSrcFromUrl = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: "GET",
    });
    const blob = await response.blob();
    if (response.ok) {
      return URL.createObjectURL(blob);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};
