import * as React from "react";
import { RelayEnvironmentProvider, useRelayEnvironment } from "react-relay";
import { KeyType } from "react-relay/relay-hooks/helpers";
import {
  Environment,
  getFragment,
  getSingularSelector,
  GraphQLTaggedNode,
  RecordSource,
  Snapshot,
  Store,
} from "relay-runtime";

export default function RelayEnvironmentCloneProvider(props: {
  children: React.ReactNode;
}) {
  const relayEnv = useRelayEnvironment();
  return (
    <RelayEnvironmentProvider
      environment={React.useMemo(
        () =>
          new Environment({
            store: new Store(
              new RecordSource(relayEnv.getStore().getSource().toJSON())
            ),
            network: relayEnv.getNetwork(),
          }),
        [relayEnv]
      )}
    >
      <ParentRelayEnvironmentContext.Provider value={relayEnv}>
        {props.children}
      </ParentRelayEnvironmentContext.Provider>
    </RelayEnvironmentProvider>
  );
}

export const ParentRelayEnvironmentContext = React.createContext<ReturnType<
  typeof useRelayEnvironment
> | null>(null);

export const useParentRelayEnvironment = () =>
  React.useContext(ParentRelayEnvironmentContext);

export const useParentRelayEnvironmentLookupFragment = () => {
  const parentRelayEnv = useParentRelayEnvironment();

  return <TKey extends KeyType>(
    fragmentInput: GraphQLTaggedNode,
    fragmentRef: TKey
  ): Snapshot | null => {
    if (parentRelayEnv) {
      return parentRelayEnv.lookup(
        getSingularSelector(getFragment(fragmentInput), fragmentRef)
      );
    }
    return null;
  };
};
