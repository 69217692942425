import graphql from "babel-plugin-relay/macro";

export const takeoverTemplateSaveMutation = graphql`
  mutation TakeoverTemplateSaveMutation(
    $dealerId: ID!
    $templateData: TakeoverProgrammingTemplateInput!
  ) {
    saveTakeoverProgrammingTemplate(
      dealerId: $dealerId
      templateData: $templateData
    ) {
      ... on SaveProgrammingTemplateErrorResult {
        error
        messages
      }
      ... on SaveProgrammingTemplateSuccessResult {
        programmingTemplate {
          concepts {
            ...TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts
          }
        }
      }
      __typename
    }
  }
`;
