/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJobGroup
 *
 * @description
 * SchedulerJobGroup API resource
 *
 */
App.factory("SchedulerJobGroup", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/SchedulerJobGroups/:id");
  },
]);

/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJobGroupsRunning
 *
 * @description
 * SchedulerJobGroupsRunning API resource
 *
 */
App.factory("SchedulerJobGroupsRunning", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/SchedulerJobGroups/Running");
  },
]);

/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJobGroupAbort
 *
 * @description
 * Resource to abort Job Group
 *
 */
App.factory("SchedulerJobGroupAbort", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/SchedulerJobGroups/:id/Abort");
  },
]);

/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJobGroupTypes
 *
 * @description
 * SchedulerJobGroupTypes API resource
 *
 */
App.factory("SchedulerJobGroupTypes", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/SchedulerJobGroupTypes/:id");
  },
]);

/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJobTypes
 *
 * @description
 * SchedulerJobTypes API resource
 *
 */
App.factory("SchedulerJobTypes", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/SchedulerJobTypes/:id");
  },
]);

/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJobGroupOdata
 *
 * @description
 * SchedulerJobGroup Odata API resource
 *
 */
App.factory("SchedulerJobGroupOdata", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/oSchedulerJobGroups");
  },
]);

/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJo
 *
 * @description
 * SchedulerJobGroup Odata API resource
 *
 */
App.factory("AutoProgramTaskDetailsOdata", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/oAutoProgramTaskDetails");
  },
]);

/**
 * @ngdoc API Resource
 * @name App.factory:SchedulerJobOdata
 *
 * @description
 * SchedulerJob Odata API resource
 *
 */
App.factory("SchedulerJobOdata", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/oSchedulerJobs");
  },
]);
