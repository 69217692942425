/**
 * @generated SignedSource<<6f648fc8354ad877b7d6ffd6700406df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryFullProgramming_controlSystem$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem" | "FullProgrammingForm_controlSystem" | "ProgrammingTemplateForm_controlSystem" | "SystemOptionsKeypadInputField_controlSystem" | "TMSentryAreaInformationProgrammingConceptFormNavButton_controlSystem" | "TMSentryAreaInformationProgrammingConceptForm_controlSystem" | "TMSentryCommunicationProgrammingConceptForm_controlSystem" | "TMSentryLockoutCodeProgrammingConceptForm_controlSystem" | "TMSentryNetworkOptionsProgrammingConceptForm_controlSystem" | "TMSentryOutputOptionsProgrammingConceptForm_controlSystem" | "TMSentryRemoteOptionsProgrammingConceptForm_controlSystem" | "TMSentrySystemOptionsProgrammingConceptForm_controlSystem" | "TMSentrySystemReportsProgrammingConceptForm_controlSystem" | "TMSentryZoneInformationsProgrammingConceptFormNavButton_controlSystem" | "TMSentryZoneInformationsProgrammingConceptForm_controlSystem">;
  readonly " $fragmentType": "TMSentryFullProgramming_controlSystem";
};
export type TMSentryFullProgramming_controlSystem$key = {
  readonly " $data"?: TMSentryFullProgramming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryFullProgramming_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryFullProgramming_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FullProgrammingForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryCommunicationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryNetworkOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryLockoutCodeProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryRemoteOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentrySystemReportsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentrySystemOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SystemOptionsKeypadInputField_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryOutputOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryAreaInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryAreaInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryZoneInformationsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryZoneInformationsProgrammingConceptFormNavButton_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "0ec8f9fa0bf51841697dbc989da12906";

export default node;
