/**
 * @ngdoc API Resource
 * @name App.factory:PanelFirmwares
 *
 * @description
 * PanelFirmwares API resource
 *
 */
App.factory("PanelFirmwaresAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.jobsApiUrl + "/api/v1/panelfirmwares");
  },
]);
