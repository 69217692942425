/**
 * @ngdoc service
 * @name App.factory:UsersV2API
 *
 * @description
 * API factory for VK Users
 *
 */
App.factory("UsersV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/users/:user_id/:upload_image/:reset_access/",
      {},
      {
        show: { method: "GET", params: { user_id: "@user_id" } },
        update: { method: "PUT", params: { user_id: "@user_id" } },
        uploadImage: {
          method: "POST",
          params: { user_id: "@user_id", upload_image: "upload_image" },
        },
        resetAccess: {
          method: "POST",
          params: { user_id: "@user_id", reset_access: "reset_access" },
        },
        resetAccessEmail: {
          method: "POST",
          params: { reset_access: "reset_access", email: "@email" },
        },
        destroy: { method: "DELETE", params: { user_id: "@user_id" } },
      }
    );
  },
]);
