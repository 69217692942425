/**
 * @generated SignedSource<<231d8f1815c7f8dc145d7eb31c61c37c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeyfobNumberOfButtons = "FOUR" | "ONE" | "TWO" | "ZERO";
import { FragmentRefs } from "relay-runtime";
export type KeyfobFields_keyfob$data = {
  readonly id: string;
  readonly number: number;
  readonly numberOfButtons: KeyfobNumberOfButtons;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobButtonActionField_keyfob" | "KeyfobButtonAreasField_keyfob" | "KeyfobButtonOutputActionField_keyfob" | "KeyfobButtonOutputField_keyfob" | "KeyfobButtonPressTimeField_keyfob" | "KeyfobContext_keyfob" | "KeyfobNumberField_keyfob" | "KeyfobNumberOfButtonsField_keyfob" | "KeyfobSerialNumberField_keyfob" | "KeyfobSupervisionTimeField_keyfob" | "KeyfobUserNumberField_keyfob" | "KeyfobUsernameField_keyfob">;
  readonly " $fragmentType": "KeyfobFields_keyfob";
};
export type KeyfobFields_keyfob$key = {
  readonly " $data"?: KeyfobFields_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobFields_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobFields_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfButtons",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobContext_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobNumberField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobNumberOfButtonsField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobUserNumberField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobUsernameField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobSerialNumberField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobSupervisionTimeField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobButtonActionField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobButtonPressTimeField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobButtonOutputField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobButtonOutputActionField_keyfob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyfobButtonAreasField_keyfob"
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "aea92b1d22615aeed28bc68fee08f470";

export default node;
