import * as React from "react";

/**
 * Performs a shallow merge between current state and next state
 */
export default function useLegacyState<State>(initialState: State) {
  const [state, setState] = React.useReducer(
    (a: State, b: Partial<State>) => ({
      ...a,
      ...b,
    }),
    initialState
  );

  return [state, setState] as [typeof state, typeof setState];
}
