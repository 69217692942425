import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationNetTestDaysField_communication$key } from "./__generated__/CommunicationNetTestDaysField_communication.graphql";

export const communicationNetTestDaysFieldId = () =>
  "communication-net-test-days";

function CommunicationNetTestDaysField() {
  const [
    { comType, networkTestDays, networkTestDaysMin, networkTestDaysMax },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationNetTestDaysField_communication$key>(
    graphql`
      fragment CommunicationNetTestDaysField_communication on Communication {
        comType
        networkTestDays
        networkTestDaysMin
        networkTestDaysMax
      }
    `
  );

  const fieldId = communicationNetTestDaysFieldId();
  const { current: originalValue } = React.useRef(networkTestDays);
  const disabled = ["NONE", "DD", "CID", "CELL"].includes(comType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Net Test Days"
      tooltip="Number of days the System waits between communication tests to the Receiver. (performed at the programmed Test Time)"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={networkTestDays}
        min={networkTestDaysMin}
        max={networkTestDaysMax}
        required
        disabled={disabled}
        inlineHelp={`${networkTestDaysMin}–${networkTestDaysMax}`}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "networkTestDays"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(networkTestDaysMin, networkTestDaysMax, valueAsNumber);
            recordProxy.setValue(Number(value), "networkTestDays");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationNetTestDaysField;
