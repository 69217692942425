/**
 * @generated SignedSource<<829d197243bf5a9c65a3d0170e5775d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeyfobButtonType = "ARMING" | "DISARMING" | "EMERGENCY" | "EMERGENCY_TWO_BUTTON" | "OUTPUT" | "PANIC" | "PANIC_TWO_BUTTON" | "SENSOR_RESET" | "STATUS" | "TOGGLE_ARM_DISARM" | "UNUSED";
import { FragmentRefs } from "relay-runtime";
export type KeyfobButtonAreasField_keyfob$data = {
  readonly buttonFourAction: KeyfobButtonType;
  readonly buttonFourAreas: string;
  readonly buttonOneAction: KeyfobButtonType;
  readonly buttonOneAreas: string;
  readonly buttonThreeAction: KeyfobButtonType;
  readonly buttonThreeAreas: string;
  readonly buttonTwoAction: KeyfobButtonType;
  readonly buttonTwoAreas: string;
  readonly id: string;
  readonly number: number;
  readonly " $fragmentType": "KeyfobButtonAreasField_keyfob";
};
export type KeyfobButtonAreasField_keyfob$key = {
  readonly " $data"?: KeyfobButtonAreasField_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobButtonAreasField_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobButtonAreasField_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOneAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreeAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonOneAreas",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonTwoAreas",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonThreeAreas",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFourAreas",
      "storageKey": null
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "c5f10317a0f72f6d56c17d7d043840a1";

export default node;
