App.factory("VideoVerificationV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl +
        "/v2/:control_systems/:control_system_id/cameras/:camera_id/:test",
      {},
      {
        create: {
          method: "POST",
          params: {
            camera_id: "@camera_id",
            control_system_id: "@control_system_id",
          },
        },
        index: {
          method: "GET",
          params: {
            control_systems: "control_systems",
            control_system_id: "@control_system_id",
          },
          isArray: true,
        },
        test: {
          method: "POST",
          params: { camera_id: "@camera_id", test: "test_connection" },
        },
        update: {
          method: "PUT",
          params: {
            camera_id: "@customer_id",
            control_system_id: "@control_system_id",
          },
        },
      }
    );
  },
]);
