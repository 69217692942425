/**
 * @generated SignedSource<<c628b56668daab693add0c7608a7c623>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EulaAgreementModalAgreeToEulaMutation$variables = {};
export type EulaAgreementModalAgreeToEulaMutation$data = {
  readonly agreeToEula: {
    readonly hasAgreed: boolean;
  };
};
export type EulaAgreementModalAgreeToEulaMutation = {
  response: EulaAgreementModalAgreeToEulaMutation$data;
  variables: EulaAgreementModalAgreeToEulaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EulaStatus",
    "kind": "LinkedField",
    "name": "agreeToEula",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAgreed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EulaAgreementModalAgreeToEulaMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EulaAgreementModalAgreeToEulaMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3c6cb1d0eb396cfdb9a527ac755fd248",
    "id": null,
    "metadata": {},
    "name": "EulaAgreementModalAgreeToEulaMutation",
    "operationKind": "mutation",
    "text": "mutation EulaAgreementModalAgreeToEulaMutation {\n  agreeToEula {\n    hasAgreed\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ee53a807f4f9eb044b8cd10becf8736";

export default node;
