/**
 *
 * DoorWidget
 * @author Chad Watson, Ken Weigand
 *
 *
 */
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment, useMutation } from "react-relay/hooks";
import { DoorStatus } from "securecom-graphql/client";
import DummyDoorWidget, { Door } from "../DummyDoorWidget";
import { DoorWidgetAccessDoorMutation } from "./__generated__/DoorWidgetAccessDoorMutation.graphql";
import { DoorWidgetFragment_door$key } from "./__generated__/DoorWidgetFragment_door.graphql";
import { DoorWidgetLockDoorMutation } from "./__generated__/DoorWidgetLockDoorMutation.graphql";
import { DoorWidgetUnlockDoorMutation } from "./__generated__/DoorWidgetUnlockDoorMutation.graphql";

const door = graphql`
  fragment DoorWidgetFragment_door on Door {
    id
    name
    tracked
    realTimeStatus
    strikeDelay
    number
    isPublic
    status
  }
`;

const unlockDoorMutation = graphql`
  mutation DoorWidgetUnlockDoorMutation($id: ID!) {
    unlockDoor(id: $id) {
      __typename
      ... on UnlockDoorMutationFailedResponse {
        error {
          type
        }
      }
      ... on UnlockDoorMutationSuccessResponse {
        door {
          id
          status
        }
      }
    }
  }
`;

const lockDoorMutation = graphql`
  mutation DoorWidgetLockDoorMutation($id: ID!) {
    lockDoor(id: $id) {
      __typename
      ... on LockDoorMutationFailedResponse {
        error {
          type
        }
      }
      ... on LockDoorMutationSuccessResponse {
        door {
          id
          status
        }
      }
    }
  }
`;

const AccessDoorMutation = graphql`
  mutation DoorWidgetAccessDoorMutation($id: ID!) {
    accessDoor(id: $id) {
      __typename
      ... on AccessDoorMutationFailedResponse {
        error {
          type
        }
      }
      ... on AccessDoorMutationSuccessResponse {
        door {
          id
          status
        }
      }
    }
  }
`;

function relayDoorToGQLDoor(door: DoorWidgetFragment_door$key[" $data"]): Door {
  return {
    id: door?.id ?? "",
    name: door?.name ?? "",
    status: door?.status as DoorStatus,
  };
}

const DoorWidget = ({
  door: doorRef,
}: {
  door: DoorWidgetFragment_door$key;
}) => {
  const data = useFragment(door, doorRef);
  const [lockDoor, isLocking] = useMutation<DoorWidgetLockDoorMutation>(
    lockDoorMutation
  );
  const [unlockDoor, isUnlocking] = useMutation<DoorWidgetUnlockDoorMutation>(
    unlockDoorMutation
  );

  const [accessDoor] = useMutation<DoorWidgetAccessDoorMutation>(
    AccessDoorMutation
  );

  return (
    <DummyDoorWidget
      pendingStatus={
        isLocking ? DoorStatus.LOCKED : isUnlocking ? DoorStatus.UNLOCKED : null
      }
      door={relayDoorToGQLDoor(data)}
      lockDoor={() => lockDoor({ variables: { id: data.id } })}
      unlockDoor={() => unlockDoor({ variables: { id: data.id } })}
      title={data.name}
      key={data.id}
      globalControls={[]}
      onAccess={() => {
        accessDoor({ variables: { id: data.id } });
      }}
    />
  );
};

export default DoorWidget;
