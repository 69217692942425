import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { bellOptionsAutomaticBellTestFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsAutomaticBellTestField";
import { bellOptionsAuxiliary1BellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsAuxiliary1BellActionField";
import { bellOptionsAuxiliary2BellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsAuxiliary2BellActionField";
import { bellOptionsBellCutoffTimeFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsBellCutoffTimeField";
import { bellOptionsBellOutputFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsBellOutputField";
import { bellOptionsBurglaryBellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsBurglaryBellActionField";
import { bellOptionsCarbonMonoxideBellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsCarbonMonoxideBellActionField";
import { bellOptionsEmergencyBellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsEmergencyBellActionField";
import { bellOptionsFireBellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsFireBellAction";
import { bellOptionsNac1SyncProtocolFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsNac1SyncProtocolField";
import { bellOptionsNac2SyncProtocolFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsNac2SyncProtocolField";
import { bellOptionsPanicBellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsPanicBellActionField";
import { bellOptionsSelectiveSilenceFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsSelectiveSilenceField";
import { bellOptionsSupervisoryBellActionFieldId } from "components/FullProgramming/common/BellOptionsFields/BellOptionsSupervisoryBellActionField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XFBellOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XFFullProgramming/__generated__/XFBellOptionsProgrammingConceptFormInline_controlSystem.graphql";
import * as React from "react";
import { readInlineData } from "react-relay";
import { getState as getBellOptionsState } from "../../../XFFullProgramming/XFBellOptionsProgrammingConceptForm";
import { ProgrammingTemplateBellOptionsInput } from "../Create/__generated__/XFTemplateSaveMutation.graphql";
import { XFBellOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFBellOptionsTemplateDataInline_xfProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XFBellOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFBellOptionsTemplateDataInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        bellOptions {
          id
          included
          bellCutoffTime {
            data
            included
          }
          nac1SyncProtocol {
            data
            included
          }
          nac2SyncProtocol {
            data
            included
          }
          selectiveSilence {
            data
            included
          }
          automaticBellTest {
            data
            included
          }
          bellOutput {
            data
            included
          }
          fireBellAction {
            data
            included
          }
          burglaryBellAction {
            data
            included
          }
          supervisoryBellAction {
            data
            included
          }
          panicBellAction {
            data
            included
          }
          emergencyBellAction {
            data
            included
          }
          auxiliary1BellAction {
            data
            included
          }
          auxiliary2BellAction {
            data
            included
          }
          carbonMonoxideBellAction {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XFBellOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateBellOptionsInput => {
  const {
    panel: { bellOptions },
  } = getBellOptionsState(controlSystem);

  if (!bellOptions) {
    return { included: false };
  }

  const bellOptionsInputFields = {
    bellCutoffTime: {
      data: bellOptions.bellCutoffTime,
      included: includedFields.has(bellOptionsBellCutoffTimeFieldId()),
    },
    automaticBellTest: {
      data: bellOptions.automaticBellTest,
      included: includedFields.has(bellOptionsAutomaticBellTestFieldId()),
    },
    bellOutput: {
      data: bellOptions.bellOutput,
      included: includedFields.has(bellOptionsBellOutputFieldId()),
    },
    nac1SyncProtocol: {
      data: bellOptions.nac1SyncProtocol,
      included: includedFields.has(bellOptionsNac1SyncProtocolFieldId()),
    },
    nac2SyncProtocol: {
      data: bellOptions.nac2SyncProtocol,
      included: includedFields.has(bellOptionsNac2SyncProtocolFieldId()),
    },
    selectiveSilence: {
      data: bellOptions.selectiveSilence,
      included: includedFields.has(bellOptionsSelectiveSilenceFieldId()),
    },
    fireBellAction: {
      data: bellOptions.fireBellAction,
      included: includedFields.has(bellOptionsFireBellActionFieldId()),
    },
    burglaryBellAction: {
      data: bellOptions.burglaryBellAction,
      included: includedFields.has(bellOptionsBurglaryBellActionFieldId()),
    },
    supervisoryBellAction: {
      data: bellOptions.supervisoryBellAction,
      included: includedFields.has(bellOptionsSupervisoryBellActionFieldId()),
    },
    panicBellAction: {
      data: bellOptions.panicBellAction,
      included: includedFields.has(bellOptionsPanicBellActionFieldId()),
    },
    emergencyBellAction: {
      data: bellOptions.emergencyBellAction,
      included: includedFields.has(bellOptionsEmergencyBellActionFieldId()),
    },
    auxiliary1BellAction: {
      data: bellOptions.auxiliary1BellAction,
      included: includedFields.has(bellOptionsAuxiliary1BellActionFieldId()),
    },
    auxiliary2BellAction: {
      data: bellOptions.auxiliary2BellAction,
      included: includedFields.has(bellOptionsAuxiliary2BellActionFieldId()),
    },
    carbonMonoxideBellAction: {
      data: bellOptions.carbonMonoxideBellAction,
      included: includedFields.has(
        bellOptionsCarbonMonoxideBellActionFieldId()
      ),
    },
  };

  const bellOptionsIncluded = Object.values(bellOptionsInputFields).some(
    ({ included }) => included
  );

  return { included: bellOptionsIncluded, ...bellOptionsInputFields };
};

export const setIncludedFields = (
  template: XFBellOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key,
  setIncludedFields: React.Dispatch<
    React.SetStateAction<IncludedTemplateFieldsType>
  >
) => {
  const { bellOptions: bellOptionsData } = getState(template);

  if (bellOptionsData?.bellCutoffTime?.included) {
    setIncludedFields(setToggle(bellOptionsBellCutoffTimeFieldId()));
  }
  if (bellOptionsData?.nac1SyncProtocol?.included) {
    setIncludedFields(setToggle(bellOptionsNac1SyncProtocolFieldId()));
  }
  if (bellOptionsData?.nac2SyncProtocol?.included) {
    setIncludedFields(setToggle(bellOptionsNac2SyncProtocolFieldId()));
  }
  if (bellOptionsData?.selectiveSilence?.included) {
    setIncludedFields(setToggle(bellOptionsSelectiveSilenceFieldId()));
  }
  if (bellOptionsData?.automaticBellTest?.included) {
    setIncludedFields(setToggle(bellOptionsAutomaticBellTestFieldId()));
  }
  if (bellOptionsData?.bellOutput?.included) {
    setIncludedFields(setToggle(bellOptionsBellOutputFieldId()));
  }
  if (bellOptionsData?.fireBellAction?.included) {
    setIncludedFields(setToggle(bellOptionsFireBellActionFieldId()));
  }
  if (bellOptionsData?.burglaryBellAction?.included) {
    setIncludedFields(setToggle(bellOptionsBurglaryBellActionFieldId()));
  }
  if (bellOptionsData?.supervisoryBellAction?.included) {
    setIncludedFields(setToggle(bellOptionsSupervisoryBellActionFieldId()));
  }
  if (bellOptionsData?.panicBellAction?.included) {
    setIncludedFields(setToggle(bellOptionsPanicBellActionFieldId()));
  }
  if (bellOptionsData?.emergencyBellAction?.included) {
    setIncludedFields(setToggle(bellOptionsEmergencyBellActionFieldId()));
  }
  if (bellOptionsData?.auxiliary1BellAction?.included) {
    setIncludedFields(setToggle(bellOptionsAuxiliary1BellActionFieldId()));
  }
  if (bellOptionsData?.auxiliary2BellAction?.included) {
    setIncludedFields(setToggle(bellOptionsAuxiliary2BellActionFieldId()));
  }
  if (bellOptionsData?.carbonMonoxideBellAction?.included) {
    setIncludedFields(setToggle(bellOptionsCarbonMonoxideBellActionFieldId()));
  }
};
