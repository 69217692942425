/**
 * @generated SignedSource<<bc1b2601b2bcafe7db853793df2cb8a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly communication: {
      readonly accountNumber: string;
      readonly backupCell: boolean;
      readonly backupCheckinMinutes: number | null;
      readonly backupDialer: boolean;
      readonly backupFailTimeMinutes: number | null;
      readonly cellApn1: string;
      readonly cellApn2: string;
      readonly cellTestDays: number;
      readonly cellTestDaysMax: number;
      readonly cellTestDaysMin: number;
      readonly checkInMinutes: number;
      readonly checkInMinutesValidValues: ReadonlyArray<number>;
      readonly comType: CommunicationComType;
      readonly dialerTestDays: number;
      readonly failTime: number;
      readonly failTimeValidValues: ReadonlyArray<number>;
      readonly id: string;
      readonly netTrouble: boolean;
      readonly networkTestDays: number;
      readonly networkTestDaysMax: number;
      readonly networkTestDaysMin: number;
      readonly receiver1AlarmReports: boolean;
      readonly receiver1Ip: string;
      readonly receiver1Ip2: string;
      readonly receiver1OpenCloseUserReports: boolean;
      readonly receiver1Port: string;
      readonly receiver1Port2: string;
      readonly receiver1PrimaryPhoneNumber: string;
      readonly receiver1SecondaryPhoneNumber: string;
      readonly receiver1SupervisoryTroubleReports: boolean;
      readonly receiver1TestReports: boolean;
      readonly receiver2AlarmReports: boolean;
      readonly receiver2Backup: boolean;
      readonly receiver2Ip: string;
      readonly receiver2Ip2: string;
      readonly receiver2OpenCloseUserReports: boolean;
      readonly receiver2Port: string;
      readonly receiver2Port2: string;
      readonly receiver2PrimaryPhoneNumber: string;
      readonly receiver2SecondaryPhoneNumber: string;
      readonly receiver2SupervisoryTroubleReports: boolean;
      readonly receiver2TestReports: boolean;
      readonly testTime: string;
      readonly transmissionDelay: number;
      readonly transmissionDelayValidValues: ReadonlyArray<number>;
    } | null;
    readonly hardwareModel: PanelHardwareModel;
    readonly id: string;
    readonly softwareVersion: string;
  };
  readonly " $fragmentType": "TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem";
};
export type TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelCommunicationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "2b9030646b36bfc8809beeca308fb45c";

export default node;
