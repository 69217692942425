import DaColors from "app/common/DaColors";
import { SecurityEvent } from "dealer-admin/src/apis/odata/security-events-parser";
import React from "react";
import styled from "styled-components";
import AttentionWarning from "./AttentionWarning";
import EventThumbnail, { ThumbnailSize } from "./EventThumbnail";
import { cameraPrefix } from "./utils/const";

const WrapperOuter = styled.div`
  position: relative;
  border-bottom: 0.1rem solid ${DaColors.Neutral25};
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
`;
const HoverOverlay = styled.div`
  position: absolute;
  inset: 0px;
  z-index: 10;
  ${WrapperOuter}:hover & {
    background-color: hsla(194, 64%, 56%, 0.2);
  }
`;
const Header = styled.div<{
  isSelected: boolean;
  isAlarm: boolean;
  isViewed: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.8rem;
  width: 100%;
  padding: 0 0.4rem 0.4rem 0.4rem;
  border-style: solid;
  border-width: 0.4rem 0.4rem 0 0.4rem;
  border-color: ${(props) =>
    props.isSelected ? DaColors.Primary500 : "transparent"};
  background-color: ${(props) => {
    if (props.isAlarm) {
      return DaColors.Info500;
    } else {
      return props.isViewed ? DaColors.Neutral400 : DaColors.Neutral25;
    }
  }};
  font-weight: ${(props) => (props.isViewed ? "inherit" : "bold")};
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
const Body = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 0.4rem;
  border-style: solid;
  border-width: 0 0.4rem 0.4rem 0.4rem;
  border-color: ${(props) =>
    props.isSelected ? DaColors.Primary500 : "transparent"};
  & > * + * {
    margin-left: 0.8rem;
  }
`;
const Details = styled.div`
  padding: 0.4rem;
`;

type Props = {
  event: SecurityEvent;
  selectedEventId?: number;
  viewedEventIds: number[];
  onClick: (selectedEvent: SecurityEvent) => void;
};

const Event = ({ event, selectedEventId, viewedEventIds, onClick }: Props) => {
  return (
    <WrapperOuter onClick={() => onClick(event)}>
      <WrapperInner>
        <Header
          isSelected={event.id === selectedEventId}
          isAlarm={event.isAlarm}
          isViewed={viewedEventIds.includes(event.id)}
        >
          <Title>
            {event.isAlarm && <AttentionWarning />}
            <span>{event.eventTitle}</span>
          </Title>
          <div>{event.displayTime}</div>
        </Header>
        <Body isSelected={event.id === selectedEventId}>
          <EventThumbnail event={event} size={ThumbnailSize.Large} />
          <Details>
            {event.cameraName && (
              <div>
                {cameraPrefix}
                {event.cameraName}
              </div>
            )}
            <div>{event.timeDistanceFromAlarm}</div>
          </Details>
        </Body>
      </WrapperInner>
      <HoverOverlay />
    </WrapperOuter>
  );
};

export default Event;
