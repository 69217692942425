import DaColors from "app/common/DaColors";
import DaFontWeight from "app/common/DaFontWeight";
import graphql from "babel-plugin-relay/macro";
import DmpIcon from "common/components/DmpIcon";
import Tooltip from "components/DaStyledElements/Tooltip";
import TextInput from "components/Inputs/TextInput";
import LoadingSpinner from "components/LoadingSpinner";
import GenericSelect from "components/Select";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useState } from "react";
import { useMutation } from "react-relay";
import styled from "styled-components/macro";
import { AddCamerasManuallyByRtspSectionMutation } from "./__generated__/AddCamerasManuallyByRtspSectionMutation.graphql";

const AddCamerasManuallyByRtspSection = ({
  setIsOpen,
  hubId,
  systemId,
  supportedMegapixels,
  megapixelThresholdCritical,
  megapixelsEnabled,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hubId: number;
  systemId: string;
  supportedMegapixels: number;
  megapixelThresholdCritical: boolean;
  megapixelsEnabled: number;
}) => {
  const regexPattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  );
  const [input, setInput] = useState("");
  const [inputType, setInputType] = useState("rtsp");
  const showAlert = useShowAlert();

  const prependHttp = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    let string = e.target.value;
    //check if string is url
    if (!regexPattern.test(string)) {
      ///clear http && https from string
      string = string.replace("https://", "").replace("http://", "");
      //add https to string
      string = `http://${string}`;
    }
    setInput(string);
  };

  const prependRtsp = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    let string = e.target.value;
    //check if string is url
    if (!regexPattern.test(string)) {
      ///clear http && https from string
      string = string
        .replace("https://", "")
        .replace("http://", "")
        .replace("rtsp://", "");
      //add rtsp to string
      string = `rtsp://${string}`;
    }
    setInput(string);
  };

  const [addCamera, addingCamera] =
    useMutation<AddCamerasManuallyByRtspSectionMutation>(graphql`
      mutation AddCamerasManuallyByRtspSectionMutation(
        $systemId: String!
        $newCamera: AddCameraByRtspInput!
      ) {
        addCameraByRtsp(systemId: $systemId, newCamera: $newCamera) {
          ... on AddCameraByRtspSuccessPayload {
            system {
              varHubs {
                cameras {
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraId
                  cameraName
                  ipAddress
                  isEnabled
                  isStreaming
                  macAddress
                  needsCredential
                  playerAuthToken
                  playerUrl
                  snapshot
                }
              }
            }
            __typename
          }
          ... on AddCameraByRtspErrorPayload {
            type
            __typename
          }
        }
      }
    `);

  const handelRtspErrorMessage = () => {
    showAlert({
      type: "error",
      text: `Failed to add camera: Could not connect to camera stream please check the URL and try again.`,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addCamera({
      variables: {
        systemId: systemId,
        newCamera: {
          hubId: hubId,
          make: inputType === "rtsp" ? 0 : 1,
          url: input,
        },
      },
      onCompleted: (res) => {
        if (res.addCameraByRtsp?.__typename === "AddCameraByRtspErrorPayload") {
          handelRtspErrorMessage();
        } else {
          showAlert({
            type: "success",
            text: `Successfully added camera.`,
          });
        }
        setIsOpen(false);
      },
      onError: (error) => {
        handelRtspErrorMessage();
      },
    });
  };

  const handleInputFocusBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (inputType === "onvif") {
      prependHttp(e);
    } else if (inputType === "rtsp") {
      prependRtsp(e);
    }
  };

  //TODO: We should maybe probably type the input we are sending
  //and maybe validate it better
  return (
    <Wrapper>
      <AddCameraInfo>
        Manually add cameras below by selecting the Stream type and adding the
        IP Address, Keep in mind that the camera performance may vary with
        camera count, frame rate <em>(min: 15FPS)</em>, codec{" "}
        <em>(min: H.264)</em>, and motion detection.
      </AddCameraInfo>
      <MainContainer onSubmit={(e) => handleSubmit(e)}>
        <Title>
          Add Camera
          <MegapixelText>
            {megapixelsEnabled}/{supportedMegapixels} MP
          </MegapixelText>
        </Title>
        <InputsContainer>
          <InputsContainerGrid>
            <InputGroup>
              <StyledLabel>
                <span>Stream Type</span>
                <Tooltip content="RTSP(Real-Time Streaming Protocol  ONVIF(Open Network Video Interface Forum">
                  <DmpIcon
                    icon="radial_info"
                    size="1.6rem"
                    constrainToParent={false}
                    color={DaColors.Info500}
                  />
                </Tooltip>
              </StyledLabel>
              <WiderTypeDropDown
                value={inputType}
                required
                disabled={addingCamera}
                onChange={(target) => {
                  setInput("");
                  setInputType(target.target.value);
                }}
              >
                <TypeDropDown.Option value="rtsp">
                  RTSP Stream
                </TypeDropDown.Option>
                <TypeDropDown.Option value="onvif">ONVIF</TypeDropDown.Option>
              </WiderTypeDropDown>
            </InputGroup>
            <InputGroup>
              <StyledLabel>
                <span>IP Address</span>
                <Tooltip content="Refer to the camera's manual to determine the full RTSP or streaming URL. A static IP address will ensure that your camera stays connected to the system.">
                  <DmpIcon
                    icon="radial_info"
                    size="1.6rem"
                    constrainToParent={false}
                    color={DaColors.Info500}
                  />
                </Tooltip>
              </StyledLabel>
              <InputAndTextContainer>
                <WiderAddInput
                  value={input}
                  required
                  disabled={addingCamera}
                  onFocus={handleInputFocusBlur}
                  onBlur={handleInputFocusBlur}
                  onChange={(e) => setInput(e.target.value.trim())}
                  placeholder={
                    inputType === "onvif"
                      ? "http://192.168.1.64/"
                      : "rtsp://user:pass@192.168.1.64/"
                  }
                />
              </InputAndTextContainer>
            </InputGroup>
          </InputsContainerGrid>
        </InputsContainer>
        <ButtonsContainer>
          <button
            type="button"
            className="btn btn-dmp btn-sm btn-no-border"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
          <button
            disabled={addingCamera}
            style={{ marginLeft: "5px" }}
            className="btn btn-primary--solid btn-sm"
            type="submit"
          >
            {addingCamera ? (
              <>
                <LoadingSpinner style={{ marginRight: "5px" }} />
                Adding
              </>
            ) : (
              "Add"
            )}
          </button>
        </ButtonsContainer>
      </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 2.4rem;
`;
const MainContainer = styled.form`
  border-radius: 0.5rem;
  background-color: #f5f7f9;
  padding: 0.8rem 1.6rem;
  font-size: 1.4rem;
`;
const StaticIPWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 1.6rem;
`;
const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 0;
  width: 100%;
`;

const InputsContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 0.33fr 0.66fr;
  grid-auto-rows: 1fr;
  grid-gap: 0.8rem;
  width: 100%;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const TypeDropDown = styled(GenericSelect)`
  width: 15rem;
  margin-right: 2rem;
`;
const WiderTypeDropDown = styled(TypeDropDown)`
  width: 100%;
  margin-right: 2rem;
`;
const AddInput = styled(TextInput)`
  width: 45rem;
`;

const WiderAddInput = styled(TextInput)`
  width: 100%;
`;
const InputAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const HelperText = styled.span`
  color: gray;
  margin: 3px 0 0 3px;
`;

const Title = styled.span`
  margin: 0;
  font-size: 1.4rem;
  font-weight: ${DaFontWeight.Bold};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLabel = styled.label`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  font-weight: ${DaFontWeight.Regular};
  & span {
    margin-right: 0.8rem;
  }
`;

const AddCameraInfo = styled.div`
  font-size: 1.4rem;
  text-align: left;
  margin-bottom: 1.6rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;

const InputGroupFullWidth = styled(InputGroup)`
  width: 100%;
`;

const MegapixelText = styled.span`
  font-size: 1.2rem;
  color: ${DaColors.Neutral700};
`;
export default AddCamerasManuallyByRtspSection;
