/**
 Third Party Video Service
 */

App.factory("ZoneCameraMappingsService", [
  "$q",
  "ZoneCameraMappingsAPI",
  function ($q, ZoneCameraMappingsAPI) {
    return {
      getMappings: function (panel_id, pageDefer, pageNum, pageData) {
        var deferred = pageDefer !== undefined ? pageDefer : $q.defer();
        pageNum = pageNum ? pageNum : 1;
        pageData = pageData !== undefined ? pageData : [];
        var _this = this;
        ZoneCameraMappingsAPI.getMappings(
          {
            panel_id: panel_id,
            page: pageNum,
          },
          function (data) {
            if (data != "") {
              // if success returns empty string we've reached the last page
              pageData = pageData.concat(data); // add this page's data on to the end of the data array
              _this
                .getMappings(panel_id, deferred, ++pageNum, pageData)
                .then(
                  // call get devices again passing in the promise, the data, and the next page
                  function () {
                    deferred.resolve();
                  },
                  function (error) {
                    //If an error occurs, bail.
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              // last page - resolve all of the data
              deferred.resolve(pageData);
            }
          },
          function (error) {
            //no return: throw error
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      updateMappings: function (panel_id, body) {
        var deferred = $q.defer();
        ZoneCameraMappingsAPI.updateMappings(
          {
            panel_id: panel_id,
          },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
