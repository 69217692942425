import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaCommonAreaField_xrArea$key } from "./__generated__/AreaCommonAreaField_xrArea.graphql";

export const areaCommonAreaFieldId = (number: string) =>
  `area-common-area-${number}`;

function AreaCommonAreaField() {
  const [{ number, commonArea }, updateArea] =
    useAreaFragment<AreaCommonAreaField_xrArea$key>(
      graphql`
        fragment AreaCommonAreaField_xrArea on XrArea {
          number
          commonArea
        }
      `
    );

  const fieldId = areaCommonAreaFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Common Area"
      tooltip="Common Areas follow the armed state of non-common Areas. The Area is armed when the last non-common Area in the System is armed and is disarmed when any non-common Area is disarmed."
    >
      <Switch
        label="Common Area"
        id={fieldId}
        checked={commonArea}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!commonArea, "commonArea");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaCommonAreaField;
