import { GenericPageFallback } from "components/GenericPageFallback";
import React, { Suspense } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import GroupNew from "./GroupNew";

const GroupNewAngularEntryPoint = ({ RelayService, $stateParams }: any) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<GenericPageFallback />}>
        <GroupNew
          customerId={$stateParams.customer_id}
          controlSystemId={$stateParams.control_system_id}
        />
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "groupNew",
    react2angular(
      GroupNewAngularEntryPoint,
      [],
      ["RelayService", "$stateParams"]
    )
  );
};
