import { Flex } from "components/DaStyledElements";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { InfoText } from "./SevenInchKeypadStyles";
import { SevenInchLogoContext } from "./SevenInchLogoContext";

interface FileUploadProps {
  isUploading: boolean;
  dealerId: string;
  requiredWidth: number;
  requiredHeight: number;
  isDisabled: boolean;
  fileUploadFunction: (file: File) => void;
}

const FileUploader: React.FC<FileUploadProps> = ({
  isUploading,
  fileUploadFunction,
  isDisabled,
}) => {
  const { selectedFile, setSelectedFile } =
    React.useContext(SevenInchLogoContext);

  const handleFileUpload = () => {
    if (selectedFile) {
      fileUploadFunction(selectedFile);
      setSelectedFile(null); // reset selected file
    }
  };
  // Drag and drop handlers
  const [dragOver, setDragOver] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
    const newFile = e.dataTransfer.files[0];
    setSelectedFile(newFile);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files && e.target.files[0];
    if (newFile && newFile.type === "image/png") {
      setSelectedFile(newFile);
    }
  };

  const handleFileReset = () => {
    setSelectedFile(null);
  };

  return (
    <div
      style={{
        border: dragOver
          ? ".4rem dashed var(--color-primary-300)"
          : " 2px dashed #ccc",
        padding: "2rem",
        textAlign: "center",
        transition: "border-color 0.3s ease-in-out",
        borderRadius: ".4rem",
      }}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {selectedFile ? (
        <>
          <Flex.Row justify="center" align="center">
            <div
              style={{
                position: "relative",
                marginBottom: ".8rem",
                maxHeight: "200px",
                maxWidth: "200px",
              }}
            >
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Preview"
                style={{ maxHeight: "200px", maxWidth: "200px" }}
              />
              <CancelImageButton onClick={() => handleFileReset()}>
                X
              </CancelImageButton>
            </div>
          </Flex.Row>

          <input
            type="file"
            accept=".png"
            className="form-control"
            onChange={() => handleFileChange}
            id="input-app-logo-dnd"
            style={{ position: "absolute", clip: "rect(0 0 0 0)" }} // hide input
            disabled={isUploading}
          />
          <button
            className="btn btn-primary pad-t-8"
            onClick={() => {
              fileUploadFunction(selectedFile);
            }}
            disabled={isUploading}
          >
            {selectedFile ? (
              <i className="icon-button icon-spin icon-reset mar-r-8"></i>
            ) : (
              <i className="icon-button icon-folder mar-r-8"></i>
            )}
            <span className="buttonText">
              {isUploading ? "Uploading" : "Upload"}
            </span>
          </button>

          <p className="file-upload-instructions">
            Drag and drop a PNG file here or click to browse.
          </p>
          <InfoText>
            (Logo must be a <strong>270x110</strong> png file suitable for a
            dark background.)
          </InfoText>
        </>
      ) : (
        <>
          {!isDisabled ? (
            <>
              <input
                type="file"
                accept=".png"
                className="form-control"
                onChange={handleFileChange}
                id="input-app-logo2"
                style={{ position: "absolute", clip: "rect(0 0 0 0)" }}
                disabled={isUploading}
              />

              <div className="bootstrap-filestyle  mar-b-8">
                <span className="group-span-filestyle">
                  <label
                    className="btn btn-primary"
                    htmlFor="input-app-logo2"
                    style={{ fontSize: "1.4rem" }}
                  >
                    {selectedFile ? (
                      <i className="icon-button icon-spin icon-reset mar-r-8"></i>
                    ) : (
                      <i className="icon-button icon-folder mar-r-8"></i>
                    )}
                    <span className="buttonText">
                      {isUploading ? "Uploading" : "Upload"}
                    </span>
                  </label>
                </span>
              </div>
            </>
          ) : (
            <>
              <button
                className="btn btn-primary mar-b-8"
                style={{ cursor: "not-allowed !important" }}
                disabled
              >
                <i className="icon-button icon-folder mar-r-8"></i>Upload
              </button>
            </>
          )}

          <p className="file-upload-instructions">
            Drag and drop a PNG file here or click to browse.
          </p>
          <InfoText>
            (Logo must be a <strong>270x110</strong> png file suitable for a
            dark background.)
          </InfoText>
        </>
      )}
    </div>
  ); //styles
};

const CancelImageButton = styled.button`
  border: none;
  color: var(--color-neutral-500);
  font-weight: 700;
  background: none;
  font-size: 2.4rem;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: var(--color-neutral-800);
  }
`;

export default FileUploader;
