import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";
import { OutputInformationContextProvider } from "../../common/OutputInformationFields/OutputInformationContext";
import OutputInformationNameField from "../../common/OutputInformationFields/OutputInformationNameField";
import OutputInformationNumberField from "../../common/OutputInformationFields/OutputInformationNumberField";
import OutputInformationOutputSupervisionField from "../../common/OutputInformationFields/OutputInformationOutputSupervisionField";
import OutputInformationRealTimeStatusField from "../../common/OutputInformationFields/OutputInformationRealTimeStatusField";
import OutputInformationSerialNumberField from "../../common/OutputInformationFields/OutputInformationSerialNumberField";
import OutputInformationTripWithPanelBellField from "../../common/OutputInformationFields/OutputInformationTripWithPanelBellField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { XT75OutputInformationFields_output$key } from "./__generated__/XT75OutputInformationFields_output.graphql";

function XT75OutputInformationFields(props: {
  outputInformation: XT75OutputInformationFields_output$key;
}) {
  const data = useFragment(
    graphql`
      fragment XT75OutputInformationFields_output on Output {
        id
        number
        ...OutputInformationContext_output
        ...OutputInformationNumberField_output
        ...OutputInformationNameField_output
        ...OutputInformationOutputSupervisionField_output
        ...OutputInformationSerialNumberField_output
        ...OutputInformationTripWithPanelBellField_output
        ...OutputInformationRealTimeStatusField_output
      }
    `,
    props.outputInformation
  );

  return (
    <OutputInformationContextProvider outputInformation={data}>
      <ProgrammingConceptForm.Fields>
        <OutputInformationNumberField />
        <OutputInformationNameField />
        <OutputInformationSerialNumberField />
        <OutputInformationOutputSupervisionField />
        <OutputInformationTripWithPanelBellField />
        <OutputInformationRealTimeStatusField />
      </ProgrammingConceptForm.Fields>
    </OutputInformationContextProvider>
  );
}

export default XT75OutputInformationFields;
