App.controller(
  "AutoPaymentModalInstanceCtrl",
  function ($rootScope, $scope, PaymentsService, $modalInstance) {
    $scope.saveAutoPayment = function () {
      PaymentsService.updateAutoPayment(
        $scope.billingAccount.dealerCode,
        $scope.billingAccount.isAutoPayment,
        $scope.billingAccount.paymentSource.id
      ).then(
        function (data) {},
        function (error) {}
      );
    };

    $scope.flipAutoPaymentState = function (currentStatus) {
      currentStatus = !currentStatus;
      $scope.billingAccount.isAutoPayment = currentStatus;
      $scope.saveAutoPayment();
      $scope.billingAccount.isAutoPayment == true
        ? $scope.popAToaster("Autopay", "Autopay has been scheduled.", "info")
        : $scope.popAToaster("Autopay", "Autopay has been canceled.", "info");
    };

    $scope.popAToaster = function (title, mssg, type) {
      $rootScope.alerts.push({
        title: title,
        type: type,
        text: mssg,
      });
    };

    $scope.getBillingCycle = function () {
      if ($scope.billingAccount) {
        switch ($scope.billingAccount.billingCycle) {
          case 1:
            return {
              runDate: "4th",
              dipDate: "7th",
            };
          case 15:
            return {
              runDate: "18th",
              dipDate: "21st",
            };
          case 22:
            return {
              runDate: "25th",
              dipDate: "28th",
            };
          default:
            return {
              runDate: 0,
              dipDate: 0,
            };
        }
      } else {
        return {
          runDate: 0,
          dipDate: 0,
        };
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };
  }
);
