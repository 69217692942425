import Icon from "components/Icon";
import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import styled from "styled-components";

interface ImageUploaderProps {
  images: any[];
  imageWidth?: number;
  onChange: (imageList: ImageListType) => void;
}
function ImageUploader(props: ImageUploaderProps) {
  const { images, imageWidth = 400, onChange } = props;
  return (
    <>
      <ImageUploading value={images} onChange={onChange}>
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <>
            {!imageList.length && (
              <ImageUploadContainer isDragging={isDragging} {...dragProps}>
                Drag and drop file here
                <br />
                or
                <button
                  className="btn btn-dmp btn-sm"
                  onClick={
                    !!images.length ? onImageUpload : () => onImageUpdate(0)
                  }
                >
                  Browse
                </button>
              </ImageUploadContainer>
            )}
            {imageList[0]?.dataURL && (
              <>
                <PreviewText>Preview</PreviewText>
                <ImageContainer>
                  <img src={imageList[0].dataURL} alt="" width={imageWidth} />
                  <DeleteButton
                    name="close_cancel"
                    onClick={() => onImageRemove(0)}
                  />
                </ImageContainer>
                <FileText>{imageList[0].file?.name}</FileText>
              </>
            )}
          </>
        )}
      </ImageUploading>
    </>
  );
}

const ImageUploadContainer = styled.div<{ isDragging: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: ${({ isDragging }) => (isDragging ? ".4rem" : ".2rem")} dashed
    ${({ isDragging }) => (isDragging ? "lightgreen" : "#ccc")};
  color: #ccc;
  border-radius: 1.2rem;
  height: 10rem;
  padding: 8rem 0rem;
  flex-flow: column;
  font-size: 2rem;
  transition: border-width 0.2s, border-color 0.2s ease-in-out;
  &:hover {
    border-width: ${({ isDragging }) => (isDragging ? ".4rem" : ".2rem")};
    border-color: ${({ isDragging }) => (isDragging ? "lightgreen" : "#ccc")};
  }
`;
const PreviewText = styled.h1`
  font-weight: bold;
  font-size: var(--measure-font-18);
  text-align: center;
`;
const ImageContainer = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
`;
const FileText = styled.div`
  font-weight: bold;
  text-align: center;
`;
const DeleteButton = styled(Icon)`
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  padding: 2px 2px;
  opacity: 0.2;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
    opacity: 1;
  }
`;

export default ImageUploader;
