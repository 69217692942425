import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import { ControlSystem, MenuDisplay } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import MenuDisplayArmDisarmDisplayKeypadsField from "../common/MenuDisplayFields/MenuDisplayArmDisarmDisplayKeypadsField";
import MenuDisplayArmedStatusDisplayKeypadsField from "../common/MenuDisplayFields/MenuDisplayArmedStatusDisplayKeypadsField";
import { MenuDisplayContextProvider } from "../common/MenuDisplayFields/MenuDisplayContext";
import MenuDisplayTimeDisplayKeypadsField from "../common/MenuDisplayFields/MenuDisplayTimeDisplayKeypadsField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XRMenuDisplayProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XRMenuDisplayProgrammingConceptFormInline_controlSystem.graphql";
import { XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XRMenuDisplayProgrammingConceptFormMenuDisplayRefreshMutation,
} from "./__generated__/XRMenuDisplayProgrammingConceptFormMenuDisplayRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XRMenuDisplayProgrammingConceptFormMenuDisplaySendMutation,
} from "./__generated__/XRMenuDisplayProgrammingConceptFormMenuDisplaySendMutation.graphql";
import { XRMenuDisplayProgrammingConceptForm_controlSystem$key } from "./__generated__/XRMenuDisplayProgrammingConceptForm_controlSystem.graphql";

export const title = "Menu Display";
export const conceptId = "xr-menu-display";

export const getState = (
  controlSystem: XRMenuDisplayProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRMenuDisplayProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          menuDisplay {
            id
            armedStatusDisplayKeypads
            timeDisplayKeypads
            armDisarmDisplayKeypads
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XRMenuDisplayProgrammingConceptFormMenuDisplayRefreshMutation(
    $systemId: ID!
  ) {
    refreshMenuDisplay(systemId: $systemId) {
      __typename
      ... on RefreshMenuDisplaySuccessResponse {
        controlSystem {
          id
          ...XRMenuDisplayProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshMenuDisplayErrorResponse {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XRMenuDisplayProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshMenuDisplay, isRefreshing] =
    useMutation<XRMenuDisplayProgrammingConceptFormMenuDisplayRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshMenuDisplay({
          variables: {
            systemId: id,
          },
          onCompleted: (response) => {
            if (
              response.refreshMenuDisplay.__typename ===
              "RefreshMenuDisplaySuccessResponse"
            ) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Menu Display refreshed successfully",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshMenuDisplay: {
                    __typename: response.refreshMenuDisplay.__typename,
                    controlSystem: getState(
                      response.refreshMenuDisplay.controlSystem
                    ),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                showAlert({
                  type: "error",
                  text: "Error refreshing Menu Display",
                });
              }
              reject();
            }
          },
          onError: () => {
            if (showAlerts) {
              showAlert({
                type: "error",
                text: "Unable to Retrieve Remote Options Programming From The System",
              });
            }
            reject();
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XRMenuDisplayProgrammingConceptFormMenuDisplaySendMutation(
    $systemId: ID!
    $menuDisplay: MenuDisplayInput!
  ) {
    updateMenuDisplay(systemId: $systemId, menuDisplay: $menuDisplay) {
      ... on UpdateMenuDisplaySuccessResponse {
        __typename
        controlSystem {
          id
          ...XRMenuDisplayProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendMenuDisplayProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;
export const useSaveMutation = (props: {
  controlSystem: XRMenuDisplayProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendMenuDisplay, isSending] =
    useMutation<XRMenuDisplayProgrammingConceptFormMenuDisplaySendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { menuDisplay },
        } = getState(props.controlSystem);

        if (menuDisplay) {
          sendMenuDisplay({
            variables: {
              systemId,
              menuDisplay: {
                armedStatusDisplayKeypads:
                  menuDisplay.armedStatusDisplayKeypads,
                timeDisplayKeypads: menuDisplay.timeDisplayKeypads,
                armDisarmDisplayKeypads: menuDisplay.armDisarmDisplayKeypads,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.updateMenuDisplay.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);
                // Update original data store
                const operation = createOperationDescriptor(
                  sendMutationConcreteRequest,
                  { id: systemId }
                );
                if (parentRelayEnv) {
                  parentRelayEnv.commitPayload(operation, {
                    updateMenuDisplay: {
                      __typename: response.updateMenuDisplay.__typename,
                      controlSystem: getState(
                        response.updateMenuDisplay.controlSystem
                      ),
                    },
                  });
                }
              } else if (response.updateMenuDisplay.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.updateMenuDisplay.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        } else {
          if (showAlerts) {
            showAlert({
              type: "warning",
              text: "Unable to send Menu Display Programming",
            });
          }
          reject();
        }
      }),
    isSending,
  ];
};

const readMenuDisplayTemplateData = (
  programmingTemplateConcepts: XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        menuDisplay {
          included
          armedStatusDisplayKeypads {
            included
            data
          }
          timeDisplayKeypads {
            included
            data
          }
          armDisarmDisplayKeypads {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).menuDisplay ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readMenuDisplayTemplateData(programmingTemplateConcepts);

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const menuDisplayRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "menuDisplay",
      "MenuDisplay"
    ) as unknown as RecordProxy<MenuDisplay>;

    applyTemplateScalarDataToRecordProxy(menuDisplayRecordProxy, templateData);
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRMenuDisplayProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRMenuDisplayProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            menuDisplay {
              id
              ...MenuDisplayContext_menuDisplay
              ...MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay
              ...MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay
              ...MenuDisplayTimeDisplayKeypadsField_menuDisplay
            }
          }
        }
      `
    );
  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <MenuDisplayContextProvider menuDisplay={controlSystem.panel.menuDisplay}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Menu%20Display`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.menuDisplay
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <MenuDisplayArmedStatusDisplayKeypadsField />
              <MenuDisplayTimeDisplayKeypadsField />
              <MenuDisplayArmDisarmDisplayKeypadsField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </MenuDisplayContextProvider>
    </PanelContextProvider>
  );
}
