import { RelayServiceType } from "app/common/services/relay-service";
import { GenericPageFallback } from "components/GenericPageFallback";
import RedirectTo404 from "components/RedirectTo404";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import TagsSettings from "./TagsSettings";

interface TagsSettingsRootProps {
  $rootScope: any;
  UserService: any;
  RelayService: RelayServiceType;
  $state: any;
}
function TagsSettingsRoot(props: TagsSettingsRootProps) {
  const { $rootScope, UserService, RelayService, $state } = props;
  const hasAccess = UserService.canEditTags() && UserService.tagsEnabled();

  return hasAccess ? (
    <React.Suspense fallback={<GenericPageFallback />}>
      <RelayEnvironmentProvider environment={RelayService.getEnvironment()}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <TagsSettings dealerId={UserService.dealer_id} state={$state} />
        </AlertsContextProvider>
      </RelayEnvironmentProvider>
    </React.Suspense>
  ) : (
    <RedirectTo404 />
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "tagsSettings",
    react2angular(
      TagsSettingsRoot,
      [],
      ["$rootScope", "UserService", "RelayService", "$state"]
    )
  );
}
