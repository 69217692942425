/**
 * @generated SignedSource<<ccdc2530fa5a64e36d65fa414ca823e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemReportsBypassReportsField_systemReports$data = {
  readonly bypassReports: boolean;
  readonly " $fragmentType": "SystemReportsBypassReportsField_systemReports";
};
export type SystemReportsBypassReportsField_systemReports$key = {
  readonly " $data"?: SystemReportsBypassReportsField_systemReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemReportsBypassReportsField_systemReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemReportsBypassReportsField_systemReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bypassReports",
      "storageKey": null
    }
  ],
  "type": "SystemReports",
  "abstractKey": null
};

(node as any).hash = "8daa81f5f2b7ee0a0a992646670f387b";

export default node;
