/**
 * @generated SignedSource<<1b593426b9937687393b1a5e51e66c6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsEntryDelay4Field_systemOptions$data = {
  readonly entryDelay4?: number;
  readonly entryDelayMax?: number;
  readonly entryDelayMin?: number;
  readonly " $fragmentType": "SystemOptionsEntryDelay4Field_systemOptions";
};
export type SystemOptionsEntryDelay4Field_systemOptions$key = {
  readonly " $data"?: SystemOptionsEntryDelay4Field_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsEntryDelay4Field_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsEntryDelay4Field_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entryDelay4",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entryDelayMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entryDelayMax",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "2fdb563e64352290eb9c13b629d49281";

export default node;
