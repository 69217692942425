import useOnClickOutside from "common/react-hooks/use-on-click-outside";
import { always } from "ramda";
import * as React from "react";
import styled from "styled-components";

type PositionStyle = Pick<
  React.CSSProperties,
  "position" | "top" | "right" | "bottom" | "left" | "transform" | "zIndex"
>;
type ArrowPosition = "top" | "bottom" | "left" | "right";

type Positioner = (
  element: HTMLDivElement,
  document: Document
) => [ArrowPosition, PositionStyle];

const absoluteRightPosition = always({
  display: "block",
  position: "absolute",
  left: "100%",
  top: "50%",
  transform: "translateY(-50%)",
  marginTop: 0,
  marginBottom: 0,
} as PositionStyle);

const absoluteLeftPosition = always({
  display: "block",
  position: "absolute",
  left: 0,
  top: "50%",
  transform: "translate(-100%, -50%)",
  marginTop: 0,
  marginBottom: 0,
} as PositionStyle);

const absoluteTopPosition = always({
  display: "block",
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translate(-50%, -100%)",
  marginLeft: 0,
  marginRight: 0,
} as PositionStyle);

const absoluteBottomPosition = always({
  display: "block",
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translate(-50%, 100%)",
  marginLeft: 0,
  marginRight: 0,
} as PositionStyle);

function Popover(props: {
  title: React.ReactNode;
  buttons?: React.ReactNode;
  children: React.ReactNode;
  positioner: Positioner;
  style?: React.CSSProperties;
  onDismiss: () => void;
}) {
  const { positioner, onDismiss } = props;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const [arrowPosition, setArrowPosition] =
    React.useState<ArrowPosition>("left");
  const [positionStyle, setPositionStyle] = React.useState<PositionStyle>(
    absoluteRightPosition()
  );

  useOnClickOutside(ref, onDismiss);

  React.useLayoutEffect(() => {
    if (ref.current) {
      const [nextArrowPosition, nextPositionStyle] = positioner(
        ref.current,
        document
      );
      setArrowPosition(nextArrowPosition);
      setPositionStyle(nextPositionStyle);
    }
  }, [positioner]);

  React.useEffect(() => {
    const handleScroll = () => {
      onDismiss();
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [onDismiss]);

  return (
    <div
      className={`popover ${
        arrowPosition === "left"
          ? "right"
          : arrowPosition === "right"
          ? "left"
          : arrowPosition === "top"
          ? "bottom"
          : "top"
      }`}
      role="tooltip"
      ref={ref}
      style={{ ...props.style, ...positionStyle }}
    >
      <div className="arrow" />
      <h3 className="popover-title">{props.title}</h3>
      <div className="popover-content">
        {props.children}
        {!!props.buttons && <Buttons>{props.buttons}</Buttons>}
      </div>
    </div>
  );
}

export default Popover;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--measure-12);
  margin-bottom: var(--measure-half);

  & button {
    min-width: 6rem;

    &:not(:last-child) {
      margin-right: var(--measure-1x);
    }
  }
`;

export function HorizontalPopover(
  props: Omit<React.ComponentProps<typeof Popover>, "positioner">
) {
  const positioner: Positioner = (element, document) => {
    const { left, width } = element.getBoundingClientRect();

    if (left + width > document.body.clientWidth) {
      return ["right", absoluteLeftPosition()];
    } else {
      return ["left", absoluteRightPosition()];
    }
  };

  return <Popover {...props} positioner={React.useCallback(positioner, [])} />;
}

export function VerticalPopover(
  props: Omit<React.ComponentProps<typeof Popover>, "positioner">
) {
  const positioner: Positioner = (element, document) => {
    const { top, height } = element.getBoundingClientRect();

    if (top + height > document.body.clientHeight) {
      return ["bottom", absoluteTopPosition()];
    } else {
      return ["top", absoluteBottomPosition()];
    }
  };

  return <Popover {...props} positioner={React.useCallback(positioner, [])} />;
}
