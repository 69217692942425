//// Styleguide Directives
App.directive("codeViewPane", function () {
  return {
    restrict: "E",
    scope: {
      codeExamples: "<",
    },
    templateUrl:
      "app/styleguide/partials/directive-templates/code-view-pane-tpl.html",
  };
});

// Styleguide Directives
App.directive("codeViewPaneTranscluded", function () {
  return {
    restrict: "E",
    transclude: true,
    scope: {},
    templateUrl:
      "app/styleguide/partials/directive-templates/code-view-pane-transcluded-tpl.html",
  };
});

App.directive("bindUnsafeHtml", [
  "$compile",
  function ($compile) {
    return function (scope, element, attrs) {
      scope.$watch(
        function (scope) {
          // watch the 'bindUnsafeHtml' expression for changes
          return scope.$eval(attrs.bindUnsafeHtml);
        },
        function (value) {
          // when the 'bindUnsafeHtml' expression changes
          // assign it into the current DOM
          element.html(value);

          // compile the new DOM and link it to the current
          // scope.
          // NOTE: we only compile .childNodes so that
          // we don't get into infinite loop compiling ourselves
          $compile(element.contents())(scope);
        }
      );
    };
  },
]);

App.filter("escapeHtml", function () {
  var entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&quot;",
    "/": "&#x2F;",
  };

  return function (str) {
    return String(str).replace(/[&<>"'\/]/g, function (s) {
      return entityMap[s];
    });
  };
});

App.directive("multiPanelList", [
  "$interpolate",
  "$filter",
  function () {
    return {
      scope: {
        panels: "<", // A list of panels that will be filtered and displayed according to the filter attribute
        filters: "<", // An array of filter options used to limit the panels displayed in the list. Filter options have three
        // parts. field, operator, value
        // Field: The field to apply the filter against
        // Operator: The comparison operator to use when comparing the field to the value (ie: ==, <=, >=, !=)
        // Value: The value to compare the field against
        options: "<", // A key value list of options objects. Supported options (nestedList, duplicateCheck)
        isBusy: "<", // Used to notify the component if information is currently loading
        nextManUp: "<", // Boolean to identify if the user wants the next object in the list
        selectionChanged: "&", // Callback used to notify when the list of selected panels has changed
        panelListChanged: "&", // Callback used to notify when the list of visible panels has changed
      },
      restrict: "E",
      templateUrl:
        "/app/programming/partials/multi-panel/multi-panel-list.html",
      controller: function ($scope, $filter, $interpolate) {
        $scope.visiblePanels = [];

        // Converts a string to a comparison operator and uses is to compare two values
        $scope.evalComparison = {
          "===": function (val1, val2) {
            return val1 === val2;
          },
          "==": function (val1, val2) {
            return val1 == val2;
          },
          "!==": function (val1, val2) {
            return val1 !== val2;
          },
          "!=": function (val1, val2) {
            return val1 != val2;
          },
          "<=": function (val1, val2) {
            return val1 <= val2;
          },
          ">=": function (val1, val2) {
            return val1 >= val2;
          },
          "<": function (val1, val2) {
            return val1 < val2;
          },
          ">": function (val1, val2) {
            return val1 > val2;
          },
        };

        $scope.evalComparisonArray = {
          "==": function (value, array) {
            return array.includes(value);
          },
          "!=": function (value, array) {
            return !array.includes(value);
          },
        };

        $scope.$watch(
          "panels",
          function (newValue, oldValue) {
            applyFilters();
            parseOptions();
            adjustForOfflinePanels();
          },
          true
        );

        // Watch for any changes on the filters object and reapply filters when changes occur
        $scope.$watch(
          "filters",
          function (newValue, oldValue) {
            applyFilters();
            adjustForOfflinePanels();
          },
          true
        );

        // Watch for any changes on the options object and reapply the options when changes occur
        $scope.$watch(
          "options",
          function (newValue, oldValue) {
            parseOptions();
          },
          true
        );

        $scope.$watch("nextManUp", function (newValue, oldValue) {
          if (!newValue) {
            angular.forEach(
              $scope.panels,
              function (panel) {
                panel.nestedListApplied = false;
              },
              true
            );
          }
        });

        // Used to parse template strings that contain placeholders
        $scope.interpolateValue = function (string, scope) {
          return $interpolate(string)(scope);
        };

        // Helper function used to determine if the drop-down toggle should be visible
        $scope.isToggleVisible = function (panel) {
          return (
            (panel.hasNestedList && panel.nestedList.length > 0) ||
            panel.hasDuplicate ||
            (panel.disabled && panel.filterMessage)
          );
          //&& !$scope.nextManUp;
        };

        // Update the list of selected panels and call selectionChanged
        $scope.updateSelectedPanels = function (panel) {
          var selectedPanels = $filter("filter")(this.panels, {
            selected: true,
            display: true,
          }); // Get a list of currently selected panels
          // Fire the selectionChanged callback to update any component users that the selected panels have changed
          this.selectionChanged({ selectedPanels: selectedPanels });
          if (
            angular.isDefined(panel) &&
            panel.selected &&
            panel.hasDuplicate
          ) {
            panel.isToggled = true;
          }
        };

        // Used with the nestedList option to disable rows once the max number of selected items has been reached
        $scope.selectionLimitReached = function (list, limit, item) {
          var selected = $filter("filter")(list, { selected: true }).length;
          return !item.selected && selected >= limit;
        };

        // Loops through the options array and calls applyOption for each one
        function parseOptions() {
          // Loop through all options storing their settings locally and applying them with applyOption
          for (var option in $scope.options) {
            if (!$scope.options.hasOwnProperty(option)) {
              return;
            }
            $scope[option + "Options"] = $scope.options[option]; // Used by interpolateValue
            applyOption(option, $scope[option + "Options"]);
          }
        }

        // Check or uncheck each system in the multi-panel list except for the template system which will always be checked
        $scope.setSelected = function (selectedState) {
          angular.forEach($scope.panels, function (panel) {
            panel.templateSystem
              ? (panel.selected = true)
              : (panel.selected = selectedState);
          });
          $scope.updateSelectedPanels();
        };

        // Apply all filters in the filters array to the panels array
        function applyFilters() {
          var _this = this;
          // Loop through the filters array and apply each filter to the panels array
          angular.forEach($scope.panels, function (panel) {
            panel.display = true;
            panel.disabled = false;
          });
          angular.forEach($scope.filters, function (filter) {
            angular.forEach($scope.panels, function (panel) {
              var fieldData = filter.field.split(".");
              var fieldType = fieldData[0];

              var field =
                fieldType == "ITEM"
                  ? panel[fieldData[1]]
                  : panel[fieldData[1][fieldData[2]]];

              // Determine what type of comparison to use based on if the filter value is an array or not
              var evaluator = Array.isArray(filter.value)
                ? "evalComparisonArray"
                : "evalComparison";
              var filtered = !$scope[evaluator][filter.operator](
                field,
                filter.value
              );

              // Filter out the current panel from the list
              if (filtered) {
                switch (filter.action) {
                  case "hide":
                    panel.display = false;
                    break;
                  case "disable":
                    panel.disabled = true;
                    panel.selected = false;
                    panel.multiPanelFilterData = filter;
                    panel.filterMessage = filter.displayTemplate;
                    break;
                  default:
                    panel.display = false;
                }
              }
            });
          });

          // Check to see if our list of visible panels has changed. If so, fire panelListChanged
          var panelList = $filter("filter")($scope.panels, { display: true }); // Get a list of currently visible panels
          if (!angular.equals(panelList, $scope.visiblePanels)) {
            $scope.visiblePanels = panelList;
            // Fire the panelListChanged callback to update any component users that the list of visible panels has changed
            $scope.panelListChanged({ panels: panelList });
          }

          $scope.updateSelectedPanels();
        }

        /**
         * Hides offline panels other than the selected panel
         */
        function adjustForOfflinePanels() {
          if (
            DoesNestedPropertyExist($scope, "panels.length") &&
            $scope.panels.length > 0
          ) {
            var selectedPanel = $scope.panels.find(function (p) {
              return p.selected;
            });
            if (selectedPanel) {
              if (selectedPanel.online) {
                // Hide all offline panels
                angular.forEach($scope.panels, function (panel) {
                  if (!panel.online) {
                    panel.display = false;
                  }
                });
              } else {
                // Hide all panels other than the selected one
                angular.forEach($scope.panels, function (panel) {
                  if (panel.id !== selectedPanel.id) {
                    panel.display = false;
                  }
                });
              }
            } else {
              console.warn("multiPanelList directive - no selected panel");
            }
          }
        }

        // Apply an option rule against the panels list
        function applyOption(optionName, optionData) {
          var _this = this;
          $scope.duplicateCheck = optionData;

          // Loop through all filtered panels and determine if they should have a nested list based on optionData.conditions
          angular.forEach($scope.panels, function (panel) {
            // If the panel has been filtered out, don't bother with checking to see if it should have a nested list
            if (!panel.display) {
              return;
            }

            if (optionName === "duplicateCheck" && optionData === undefined) {
              panel.hasDuplicate = undefined;
              //panel.hasNestedList = undefined;
              return;
            }

            // Apply each nested list option condition to the current panel. Only proceed if all conditions are true
            var conditionResult = evalOptionConditions(panel, optionData);
            if (conditionResult) {
              switch (optionName) {
                case "nestedList":
                  // Set hasNestedList and the nested list array for the current panel
                  panel.hasNestedList = panel.hasOwnProperty(
                    optionData.property
                  );
                  panel.nestedList = panel[optionData.property];
                  // Only set any currently selected items if we haven't already done so
                  if (
                    angular.isDefined(panel.nestedList) &&
                    angular.isDefined(panel.nestedListData) &&
                    !panel.nestedList.isBusy &&
                    !panel.nestedListApplied
                  ) {
                    angular.forEach(panel.nestedList, function (listItem) {
                      if ($scope.nextManUp && !panel.selected) {
                        listItem.selected = false;
                      } else if (
                        panel.nestedListData.includes(
                          listItem[optionData.field]
                        )
                      ) {
                        listItem.selected = true;
                      }
                    });
                    panel.nestedListApplied = true;
                  }
                  break;
                case "duplicateCheck":
                  // Set the hasDuplicate value for the current panel
                  panel.hasDuplicate = true;
                  panel.duplicateCheckData = conditionResult;
                  break;
              }
            } else {
              // Current panel doesn't meet all conditions for the current option. Reset values to default
              switch (optionName) {
                case "nestedList":
                  panel.hasNestedList = undefined;
                  panel.nestedList = undefined;
                  break;
                case "duplicateCheck":
                  panel.hasDuplicate = undefined;
                  break;
              }
            }
          });
        }

        // Uses evalComparison to evaluate option conditions
        function evalOptionConditions(panel, option) {
          var returnData = true;
          var fieldValue;
          var conditionValue;
          // Loop through all conditions for the current option
          for (var i = 0; i < option.conditions.length; i++) {
            var result = false;
            var condition = option.conditions[i];
            var fieldData = condition.field.split(".");
            var fieldType = fieldData[0];
            // Get a reference to the condition's field property
            if (fieldType == "ITEM") {
              // ITEM
              var referenceField = fieldData[1];
              var field = panel[referenceField];
              fieldValue = isNaN(Number(field)) ? field : Number(field);
              conditionValue = isNaN(Number(condition.value))
                ? condition.value
                : Number(condition.value);
              result = $scope.evalComparison[condition.operator](
                conditionValue,
                fieldValue
              );
              if (condition.storeMatchingValue) {
                returnData = panel[concept][x][referenceField];
              }
            } else if (fieldType == "CONCEPTS") {
              // CONCEPTS
              var concept = fieldData[1];
              var referenceField = fieldData[2];
              if (!angular.isDefined(panel[concept])) {
                break;
              }
              if (Array.isArray(panel[concept])) {
                // Loop through all objects in the current concept and evaluate them with the current condition
                // If ANY object in the array evaluates to true, the condition is treated as having been met.
                for (var x = 0; x < panel[concept].length; x++) {
                  var field = panel[concept][x][referenceField];
                  fieldValue = isNaN(Number(field)) ? field : Number(field);
                  conditionValue = isNaN(Number(condition.value))
                    ? condition.value
                    : Number(condition.value);
                  result = $scope.evalComparison[condition.operator](
                    conditionValue,
                    fieldValue
                  );
                  if (result === true) {
                    // We found a matching value, check to see if we need to store the matching object, then break out of the loop
                    if (condition.storeMatchingValue) {
                      returnData = panel[concept][x];
                    }
                    break;
                  }
                }
              } else {
                var field = panel[concept][referenceField];
                fieldValue = isNaN(Number(field)) ? field : Number(field);
                conditionValue = isNaN(Number(condition.value))
                  ? condition.value
                  : Number(condition.value);
                result = $scope.evalComparison[condition.operator](
                  conditionValue,
                  fieldValue
                );
                if (condition.storeMatchingValue) {
                  returnData = field;
                }
              }
            } else {
              // Shouldn't get here.
              return false;
            }

            // The current condition evaluated to false, no need to continue checking since conditions are all or nothing
            if (result === false) {
              return false;
            }
          }

          // This is typically just "true" but if storeMatchingValue was set on a condition, the results will be saved here
          return returnData;
        }
      },
    };
  },
]);

App.directive("copyToClipboard", [
  "$rootScope",
  function ($rootScope) {
    return {
      restrict: "A",
      link: function (scope, elem, attrs) {
        elem.click(function () {
          if (attrs.copyToClipboard) {
            var $temp_input = $("<input>");
            $("body").append($temp_input);
            $temp_input.val(attrs.copyToClipboard).select();
            document.execCommand("copy");
            $rootScope.alerts.push({
              type: "success",
              text: "Copied to Clipboard",
            });
            $temp_input.remove();
          }
        });
      },
    };
  },
]);

/**
 * Directive: daBusySpinner
 * desc: A custom DMP loading spinner that fills its parent element
 *       and blocks access to underlying controls
 *  variable: showWhen - type boolean - true == show the spinner
 *  variable: detail - (optional) string - appends the Loading ... string
 */

App.directive("daBusySpinner", function () {
  return {
    restrict: "E",
    scope: {
      showWhen: "=showWhen",
      detail: "@detail",
    },
    templateUrl: "app/common/templates/da-busy-spinner-tpl.html",
  };
});

App.directive("checkInnerWindowSize", [
  "$window",
  function ($window) {
    return {
      restrict: "A",
      link: function link(scope, element, attrs) {
        angular.element($window).on(
          "resize",
          setTimeout(function () {
            scope.$apply(function () {
              scope.isXtraSmall = $window.innerWidth < 768;
              scope.isSmall =
                $window.innerWidth >= 768 && $window.innerWidth < 992;
              scope.isMed =
                $window.innerWidth >= 992 && $window.innerWidth < 1200;
              scope.isLarge = $window.innerWidth > 1200;
            });
          }, 0)
        );
      },
    };
  },
]);

/**
 * @directive -- repositionTooltip
 * @desc -- Add to the Bootstrap Tooltip to adjust positioning.
 * @desc -- If the tooltip is positioned offscreen left, set its left to 0
 */

App.directive("repositionTooltip", [
  "$timeout",
  function ($timeout) {
    return {
      restrict: "A",
      scope: false,
      link: function (scope, element, attrs) {
        let el, arrow, offset;
        element.bind("mouseenter", function () {
          $timeout(function () {
            el = element.next();
            if (el[0].offsetLeft < 0) {
              offset = el[0].offsetLeft;
              el.css("left", 0);
              arrow = el[0].querySelector(".tooltip-arrow");
              arrow = angular.element(arrow);
              arrow.css("margin-left", offset + -5 + "px");
            }
          });
        });
      },
    };
  },
]);
