/**
 * @generated SignedSource<<1420f5c06c5a8a791f5854033e4d9695>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecureComDoorbells_site$data = {
  readonly securecomDoorbells: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SecureComDoorbells_doorbell">;
  }>;
  readonly " $fragmentType": "SecureComDoorbells_site";
};
export type SecureComDoorbells_site$key = {
  readonly " $data"?: SecureComDoorbells_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComDoorbells_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecureComDoorbells_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecureComDoorbell",
      "kind": "LinkedField",
      "name": "securecomDoorbells",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecureComDoorbells_doorbell"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "fd7f805054ec0530dbcd5fa2a2fe3bb3";

export default node;
