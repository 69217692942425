App.service("AXBusService", function () {
  var _this = this;

  /**
   * This is a list of AX Bus addresses.
   */
  this.AXBusNumbers = [
    501, 505, 509, 513, 517, 521, 525, 529, 533, 537, 541, 545, 549, 553, 557,
    561, 601, 605, 609, 613, 617, 621, 625, 629, 633, 637, 641, 645, 649, 653,
    657, 661, 701, 705, 709, 713, 717, 721, 725, 729, 733, 737, 741, 745, 749,
    753, 757, 761, 801, 805, 809, 813, 817, 821, 825, 829, 833, 837, 841, 845,
    849, 853, 857, 861, 901, 905, 909, 913, 917, 921, 925, 929, 933, 937, 941,
    945, 949, 953, 957, 961,
  ];

  /**
   * Maps the LX value to it's corresponding AX value
   */
  this.AXMap = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16, // Devices under 16 remain the same
    17: 501,
    18: 505,
    19: 509,
    20: 513,
    21: 517,
    22: 521,
    23: 525,
    24: 529,
    25: 533,
    26: 537,
    27: 541,
    28: 545,
    29: 549,
    30: 553,
    31: 557,
    32: 561,
    33: 601,
    34: 605,
    35: 609,
    36: 613,
    37: 617,
    38: 621,
    39: 625,
    40: 629,
    41: 633,
    42: 637,
    43: 641,
    44: 645,
    45: 649,
    46: 653,
    47: 657,
    48: 661,
    49: 701,
    50: 705,
    51: 709,
    52: 713,
    53: 717,
    54: 721,
    55: 725,
    56: 729,
    57: 733,
    58: 737,
    59: 741,
    60: 745,
    61: 749,
    62: 753,
    63: 757,
    64: 761,
    65: 801,
    66: 805,
    67: 809,
    68: 813,
    69: 817,
    70: 821,
    71: 825,
    72: 829,
    73: 833,
    74: 837,
    75: 841,
    76: 845,
    77: 849,
    78: 853,
    79: 857,
    80: 861,
    81: 901,
    82: 905,
    83: 909,
    84: 913,
    85: 917,
    86: 921,
    87: 925,
    88: 929,
    89: 933,
    90: 937,
    91: 941,
    92: 945,
    93: 949,
    94: 953,
    95: 957,
    96: 961,
  };

  /**
   * This function returns the number of AX Bus devices, given the status of AX bus Add-on feature keys
   * @param feature_key_add_on_A - boolean indicating if add-on A is active on the panel
   * @param feature_key_add_on_B - boolean indicating if add-on B is active on the panel
   * @returns {number}
   */
  this.getNumDevices = function (feature_key_add_on_A, feature_key_add_on_B) {
    var numAXDevices = 16;
    if (feature_key_add_on_A === true) {
      numAXDevices += 32;
    }
    if (feature_key_add_on_B === true) {
      numAXDevices += 32;
    }
    return numAXDevices;
  };

  this.getLxNum = function (lxNum) {
    if (this.AXMap[lxNum]) {
      return this.AXMap[lxNum];
    } else {
      return lxNum;
    }
  };
});
