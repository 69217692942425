/**
 * @generated SignedSource<<b4e42227120cdf704809861a82f5d6fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecureComDoorbellFormSetTotalMutation$variables = {
  siteId: string;
  total: number;
};
export type SecureComDoorbellFormSetTotalMutation$data = {
  readonly setSecureComDoorbellTotalForSite: {
    readonly " $fragmentSpreads": FragmentRefs<"SecureComDoorbellForm_site">;
  };
};
export type SecureComDoorbellFormSetTotalMutation = {
  response: SecureComDoorbellFormSetTotalMutation$data;
  variables: SecureComDoorbellFormSetTotalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "total"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "siteId",
    "variableName": "siteId"
  },
  {
    "kind": "Variable",
    "name": "total",
    "variableName": "total"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SecureComDoorbellFormSetTotalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setSecureComDoorbellTotalForSite",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SecureComDoorbellForm_site"
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SecureComDoorbellFormSetTotalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setSecureComDoorbellTotalForSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hikvisionDoorbellTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SecureComDoorbell",
                "kind": "LinkedField",
                "name": "securecomDoorbells",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "model",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firmwareVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestFirmwareVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serialNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updateStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updateProgress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUpdating",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "controlSystemId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manufacturer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verificationCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updateAvailable",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Site",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9d1b828449869a7322741610014aae4",
    "id": null,
    "metadata": {},
    "name": "SecureComDoorbellFormSetTotalMutation",
    "operationKind": "mutation",
    "text": "mutation SecureComDoorbellFormSetTotalMutation(\n  $siteId: ID!\n  $total: Int!\n) {\n  setSecureComDoorbellTotalForSite(siteId: $siteId, total: $total) {\n    __typename\n    ... on Site {\n      ...SecureComDoorbellForm_site\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment SecureComDoorbellForm_site on Site {\n  id\n  hikvisionDoorbellTotal\n  ...SecureComDoorbells_site\n}\n\nfragment SecureComDoorbells_doorbell on SecureComDoorbell {\n  id\n  name\n  model\n  firmwareVersion\n  latestFirmwareVersion\n  serialNumber\n  updateStatus\n  updateProgress\n  isUpdating\n  controlSystemId\n  deviceType\n  manufacturer\n  status\n  verificationCode\n  updateAvailable\n}\n\nfragment SecureComDoorbells_site on Site {\n  securecomDoorbells {\n    ...SecureComDoorbells_doorbell\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6513a5ee2877de2b8f38a7f6891d11d";

export default node;
