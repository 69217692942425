/**
 * @generated SignedSource<<b11691c9da4a9ccac9aa2eee78517eaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CentralStationAutomationIntegrationStatus = "CONNECTED" | "NOT_CONNECTED";
import { FragmentRefs } from "relay-runtime";
export type IntegrationGridView_centralStationAutomationIntegration$data = {
  readonly displayedName: string;
  readonly emergencyContactsEnabled: boolean;
  readonly id: string;
  readonly name: string;
  readonly status: CentralStationAutomationIntegrationStatus;
  readonly systemTestsEnabled: boolean;
  readonly " $fragmentType": "IntegrationGridView_centralStationAutomationIntegration";
};
export type IntegrationGridView_centralStationAutomationIntegration$key = {
  readonly " $data"?: IntegrationGridView_centralStationAutomationIntegration$data;
  readonly " $fragmentSpreads": FragmentRefs<"IntegrationGridView_centralStationAutomationIntegration">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IntegrationGridView_centralStationAutomationIntegration",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emergencyContactsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemTestsEnabled",
      "storageKey": null
    }
  ],
  "type": "CentralStationAutomationIntegration",
  "abstractKey": null
};

(node as any).hash = "2e92900dd6ae782a9703df82c589922a";

export default node;
