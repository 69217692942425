/**
 * TakeoverPanelSetup
 *
 * In DA212 the ability to setup and retrieve zones for ECP and DSC
 * panels was added. This is the entrypoint for that functionality.
 */

import { App } from "app-module";
import graphql from "babel-plugin-relay/macro";
import { sleep } from "common/utils/universal/promise";
import { AnyFunction } from "common/utils/universal/types";
import Alert from "components/Alert";
import { removeProgrammingConceptFromChangedProgrammingConcepts } from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { SaveErrors } from "components/FullProgramming/common/FullProgrammingForm";
import { useResetLastUpdated } from "components/FullProgramming/common/LastUpdatedContext";
import { conceptId as takeoverPanelSystemOptionsID } from "components/FullProgramming/TakeoverPanelFullProgramming/TakeoverPanelSystemOptionsProgrammingConceptForm";
import { NumericInput } from "components/Inputs/NumericInput";
import Modal from "components/Modal";
import NeutralText from "components/NeutralText";
import RedirectTo404 from "components/RedirectTo404";
import React, { useMemo } from "react";
import { RelayEnvironmentProvider, useMutation } from "react-relay/hooks";
import { react2angular } from "react2angular";
import Relay from "relay-runtime";
import {
  idAsString,
  TakeoverPanelType,
  toControlSystemId,
} from "securecom-graphql/client";
import {
  TakeoverPanelSetupEventMutation,
  TakeoverPanelSetupEventMutation$data,
} from "./__generated__/TakeoverPanelSetupEventMutation.graphql";
import {
  TakeoverPanelSetupGetZonesEventMutation,
  TakeoverPanelSetupGetZonesEventMutation$data,
} from "./__generated__/TakeoverPanelSetupGetZonesEventMutation.graphql";
import {
  TakeoverPanelSetupGetZonesMutation,
  TakeoverPanelSetupGetZonesMutation$variables,
} from "./__generated__/TakeoverPanelSetupGetZonesMutation.graphql";
import {
  TakeoverPanelSetupSetupPanelMutation,
  TakeoverPanelSetupSetupPanelMutation$variables,
} from "./__generated__/TakeoverPanelSetupSetupPanelMutation.graphql";

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback: (error: any) => React.ReactNode }>,
  { error: any }
> {
  state = { error: null };
  componentDidCatch(error: any) {
    this.setState({ error });
  }
  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;
    if (error) {
      return fallback(this.state);
    }
    return children;
  }
}

type Props = {
  /**
   * - E is ECP
   * - D is DSC
   */
  panelType: "E" | "D";
  UserService: {
    customer_id: string | number;
    control_system_id: string | number;
  };
  RelayService: { getEnvironment: () => Relay.Environment };
  panel: {
    sendProgramming: (
      concept: string,
      suppressToast: boolean,
      forceSend: boolean
    ) => Promise<SaveErrors>;
  };
};

type State = {
  isVista128Panel: boolean;
  installerKey: string;
  submissionState:
    | "IDLE"
    | "SUBMITTING"
    | "GETTING_ZONES"
    | "ERROR"
    | "SUCCESS";
  zoneCount: number;
};

type Action =
  | { type: "SUBMITTED" }
  | { type: "REQUEST_ZONES" }
  | { type: "REQUEST_FAILED" }
  | { type: "REQUEST_SUCCEEDED" }
  | { type: "RETRY" }
  | { type: "TOGGLED_IS_VISTA_128_PANEL" }
  | { type: "NO_EVENT_AVAILABLE" }
  | { type: "UPDATED_INSTALLER"; payload: string }
  | { type: "ZONE_COUNT_RECEIVED"; payload: number };

const initialState: State = {
  isVista128Panel: false,
  installerKey: "",
  submissionState: "IDLE",
  zoneCount: 0,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SUBMITTED":
      return {
        ...state,
        submissionState: "SUBMITTING",
      };
    case "REQUEST_ZONES":
      return {
        ...state,
        submissionState: "GETTING_ZONES",
        zoneCount: 0,
      };
    case "NO_EVENT_AVAILABLE":
      return {
        ...state,
        submissionState: "GETTING_ZONES",
      };
    case "ZONE_COUNT_RECEIVED":
      return {
        ...state,
        zoneCount: action.payload,
      };
    case "REQUEST_FAILED":
      return {
        ...state,
        submissionState: "ERROR",
      };
    case "REQUEST_SUCCEEDED":
      return {
        ...state,
        submissionState: "SUCCESS",
      };
    case "TOGGLED_IS_VISTA_128_PANEL":
      return {
        ...state,
        isVista128Panel: !state.isVista128Panel,
      };
    case "UPDATED_INSTALLER":
      return {
        ...state,
        installerKey: action.payload,
      };
    default:
      return { ...state };
  }
};

const TakeoverPanelMutation = graphql`
  mutation TakeoverPanelSetupGetZonesMutation(
    $systemId: ID!
    $installerCode: String!
    $panelType: TakeoverPanelType!
  ) {
    takeoverPanelGetZones(
      systemId: $systemId
      installerCode: $installerCode
      panelType: $panelType
    ) {
      __typename
      ... on TakeoverPanelGetZonesSuccess {
        controlSystem {
          panel {
            zoneInformations {
              id
              name
              number
              isNew
              isECP
              ...TakeoverPanelZoneInformationsFields_zone
            }
          }
        }
      }
    }
  }
`;

function usePerformSetupPanel(
  variables: TakeoverPanelSetupSetupPanelMutation$variables
) {
  const [setupPanel] =
    useMutation<TakeoverPanelSetupSetupPanelMutation>(graphql`
      mutation TakeoverPanelSetupSetupPanelMutation(
        $systemId: ID!
        $vistaPanel: Boolean!
        $installerCode: String!
        $panelType: TakeoverPanelType!
      ) {
        setupTakeoverPanel(
          systemId: $systemId
          vistaPanel: $vistaPanel
          installerCode: $installerCode
          panelType: $panelType
        ) {
          __typename
        }
      }
    `);

  const [getSetupPanelEvents] =
    useMutation<TakeoverPanelSetupEventMutation>(graphql`
      mutation TakeoverPanelSetupEventMutation($systemId: ID!) {
        takeoverPanelSetupEvent(systemId: $systemId) {
          status
        }
      }
    `);

  const beginSetupPanel = () =>
    new Promise((resolve, reject) => {
      setupPanel({
        variables,
        onCompleted: (result) => {
          if (
            result.setupTakeoverPanel.__typename === "TakeoverPanelSetupSuccess"
          ) {
            resolve(result);
          } else {
            reject();
          }
        },
        onError: () => {
          reject();
        },
      });
    });

  const pollForSetupPanelEvent = () => {
    const startGetSetupPanelEvents = () =>
      new Promise<TakeoverPanelSetupEventMutation$data>((resolve, reject) => {
        try {
          getSetupPanelEvents({
            variables: { systemId: variables.systemId },
            onCompleted: resolve,
            onError: reject,
          });
        } catch {
          reject();
        }
      });

    return new Promise<void>(async (resolve, reject) => {
      const MAX_ATTEMPTS = 300; // 5 Minutes
      let attempts = 0;

      while (attempts <= MAX_ATTEMPTS) {
        await sleep(1000);
        const results = await startGetSetupPanelEvents();

        if (results.takeoverPanelSetupEvent.status === "SUCCESS") {
          return resolve();
        } else if (results.takeoverPanelSetupEvent.status === "FAILED") {
          return reject();
        }

        attempts += 1;
      }

      // Timeout
      reject();
    });
  };

  return async () => {
    await beginSetupPanel();
    await pollForSetupPanelEvent();
  };
}

function usePerformGetZones(
  variables: TakeoverPanelSetupGetZonesMutation$variables
) {
  let subscriber: AnyFunction | null = null;

  const [getGetZonesEvents] =
    useMutation<TakeoverPanelSetupGetZonesEventMutation>(graphql`
      mutation TakeoverPanelSetupGetZonesEventMutation($systemId: ID!) {
        takeoverPanelGetZonesEvent(systemId: $systemId) {
          status
          zones
        }
      }
    `);

  const [getZones] = useMutation<TakeoverPanelSetupGetZonesMutation>(
    TakeoverPanelMutation
  );

  const beginGetZones = () =>
    new Promise((resolve, reject) => {
      getZones({
        variables,
        onCompleted: (result) => {
          if (
            result.takeoverPanelGetZones.__typename ===
            "TakeoverPanelGetZonesSuccess"
          ) {
            resolve(result.takeoverPanelGetZones);
          } else {
            reject(result);
          }
        },
        onError: reject,
      });
    });

  const pollForGetZonesEvents = async () => {
    const MAX_ATTEMPTS = 300; // 5 Minutes
    let attempts = 0;

    const startGetSetupPanelEvents = () =>
      new Promise<TakeoverPanelSetupGetZonesEventMutation$data>(
        (resolve, reject) => {
          try {
            getGetZonesEvents({
              variables: { systemId: variables.systemId },
              onCompleted: resolve,
              onError: reject,
            });
          } catch (error) {
            reject(error);
          }
        }
      );

    while (attempts <= MAX_ATTEMPTS) {
      await sleep(1000);
      const nextEvent = await startGetSetupPanelEvents();

      if (subscriber) {
        subscriber(nextEvent);
      }

      if (nextEvent.takeoverPanelGetZonesEvent.status === "COMPLETED") {
        return;
      } else if (nextEvent.takeoverPanelGetZonesEvent.status === "FAILED") {
        throw new Error("getting zones from SEMPro failed");
      }

      attempts += 1;
    }

    throw new Error("Timed out getting zones from SEMPro.");
  };

  return async (
    cb: (event: TakeoverPanelSetupGetZonesEventMutation$data) => void
  ) => {
    try {
      subscriber = cb;
      await beginGetZones();
      await pollForGetZonesEvents();
    } finally {
      subscriber = null;
    }
  };
}

function SetupModalContent({
  panelType,
  systemID,
  closeModal,
  sendSystemOptions,
}: {
  panelType: "DSC" | "ECP";
  systemID: string;
  closeModal: () => void;
  sendSystemOptions: () => Promise<SaveErrors>;
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const resetLastUpdated = useResetLastUpdated();

  const submissionIsComplete =
    state.submissionState === "SUCCESS" || state.submissionState === "ERROR";
  const submissionIsLoading =
    state.submissionState === "SUBMITTING" ||
    state.submissionState === "GETTING_ZONES";

  const performSetupPanel = usePerformSetupPanel({
    panelType: panelType as TakeoverPanelType,
    systemId: systemID,
    vistaPanel: state.isVista128Panel,
    installerCode: state.installerKey,
  });

  const performGetZones = usePerformGetZones({
    panelType: panelType as TakeoverPanelType,
    systemId: systemID,
    installerCode: state.installerKey,
  });

  return (
    <Modal size="medium">
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          event.stopPropagation();

          if (submissionIsLoading) {
            return;
          }

          dispatch({ type: "SUBMITTED" });

          try {
            await sendSystemOptions();
            await performSetupPanel();
            dispatch({ type: "REQUEST_ZONES" });
            await performGetZones(({ takeoverPanelGetZonesEvent: event }) => {
              if (
                event.status === "PROCESSING" ||
                event.status === "COMPLETED"
              ) {
                dispatch({ type: "ZONE_COUNT_RECEIVED", payload: event.zones });
              }
            });
            dispatch({ type: "REQUEST_SUCCEEDED" });
          } catch {
            dispatch({ type: "REQUEST_FAILED" });
            return;
          }
        }}
      >
        <div className="status-modal modal-header">
          <h3 className="modal-title">{panelType} Setup</h3>
        </div>

        <div className="modal-body">
          {state.submissionState === "ERROR" ? (
            <Alert
              type="error"
              icon={<i className="icon-attention text-danger"></i>}
            >
              The {panelType} Setup process was unsuccessful. Double-check your
              Installer Code and try again.
            </Alert>
          ) : null}

          {state.submissionState === "IDLE" ||
          state.submissionState === "ERROR" ? (
            <>
              {panelType === "ECP" ? (
                <div className="form-group">
                  <div className="col-12">
                    <label className="switch switch-lg switch-text-middle">
                      VISTA 128?
                      <input
                        type="checkbox"
                        className="form-control"
                        id="isVista128Panel"
                        name="isVista128Panel"
                        checked={state.isVista128Panel}
                        onChange={() => {
                          dispatch({ type: "TOGGLED_IS_VISTA_128_PANEL" });
                        }}
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
              ) : null}

              <div className="form-group">
                <div className="col-12">
                  <label>
                    {panelType === "ECP" ? "VISTA" : ""} Installer Code
                  </label>
                  <NumericInput
                    className="form-control"
                    required
                    value={state.installerKey}
                    minLength={4}
                    maxLength={4}
                    inlineHelp="(0000-9999)"
                    onChange={(event) => {
                      dispatch({
                        type: "UPDATED_INSTALLER",
                        payload: event.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}

          {state.submissionState === "SUCCESS" ? (
            <Alert
              type="success"
              icon={<i className="icon-radial_check text-success"></i>}
            >
              {panelType} Setup Complete.{" "}
              {`Retrieved ${state.zoneCount} Zone${
                state.zoneCount !== 1 ? "s" : ""
              }`}
              .
            </Alert>
          ) : null}

          {submissionIsLoading ? (
            <>
              <Alert
                type="info"
                icon={<i className="fa fa-spin fa-spinner"></i>}
              >
                {state.submissionState === "SUBMITTING"
                  ? `Running ${panelType} Setup`
                  : state.zoneCount === 0
                  ? `Getting Zones`
                  : `Retrieved ${state.zoneCount} Zone${
                      state.zoneCount !== 1 ? "s" : ""
                    }`}
              </Alert>
              <NeutralText value={700}>
                Please do not leave or refresh this page while in progress.
              </NeutralText>
            </>
          ) : null}
        </div>

        <div className="modal-footer">
          {state.submissionState === "IDLE" ? (
            <button type="submit" className="btn btn-dmp">
              Begin
            </button>
          ) : null}
          {state.submissionState === "IDLE" ? (
            <button onClick={closeModal} className="btn btn-default">
              Cancel
            </button>
          ) : null}

          {submissionIsLoading ? (
            <button
              type="button"
              onClick={() => {
                removeProgrammingConceptFromChangedProgrammingConcepts(
                  takeoverPanelSystemOptionsID
                );
              }}
              className="btn btn-dmp"
              disabled={true}
            >
              Finish
            </button>
          ) : null}

          {submissionIsComplete ? (
            <>
              {state.submissionState === "ERROR" ? (
                <button type="submit" className="btn btn-dmp">
                  Retry
                </button>
              ) : null}
              <button
                className="btn btn-dmp"
                onClick={() => {
                  if (state.submissionState !== "ERROR") {
                    removeProgrammingConceptFromChangedProgrammingConcepts(
                      takeoverPanelSystemOptionsID
                    );
                    resetLastUpdated(takeoverPanelSystemOptionsID);
                  }
                  closeModal();
                }}
              >
                {state.submissionState === "ERROR" ? "Done" : "Finish"}
              </button>
            </>
          ) : null}
        </div>
      </form>
    </Modal>
  );
}

function GetZonesModalContent({
  closeModal,
  systemID,
  panelType,
  sendSystemOptions,
  refreshZoneInformations,
}: {
  systemID: string;
  panelType: "ECP" | "DSC";
  closeModal: () => void;
  sendSystemOptions: () => Promise<SaveErrors>;
  refreshZoneInformations?: () => Promise<void>;
}) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const performGetZones = usePerformGetZones({
    panelType: panelType as TakeoverPanelType,
    systemId: systemID,
    installerCode: state.installerKey,
  });

  const submissionIsComplete =
    state.submissionState === "SUCCESS" || state.submissionState === "ERROR";
  const submissionIsLoading =
    state.submissionState === "SUBMITTING" ||
    state.submissionState === "GETTING_ZONES";

  return (
    <Modal
      size="medium"
      onClickOutside={
        ["SUCCESS", "ERROR", "IDLE"].includes(state.submissionState)
          ? closeModal
          : undefined
      }
    >
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          event.stopPropagation();

          if (submissionIsLoading) {
            return;
          }

          try {
            dispatch({ type: "REQUEST_ZONES" });
            await sendSystemOptions();
            await performGetZones(({ takeoverPanelGetZonesEvent: event }) => {
              if (
                event.status === "PROCESSING" ||
                event.status === "COMPLETED"
              ) {
                dispatch({ type: "ZONE_COUNT_RECEIVED", payload: event.zones });
              }
            });
            if (refreshZoneInformations) await refreshZoneInformations();
            dispatch({ type: "REQUEST_SUCCEEDED" });
          } catch {
            dispatch({ type: "REQUEST_FAILED" });
            return;
          }
        }}
      >
        <div className="status-modal modal-header">
          <h3 className="modal-title">Getting Zones</h3>
        </div>

        <div className="modal-body">
          {state.submissionState === "ERROR" ? (
            <Alert
              type="error"
              icon={<i className="icon-attention text-danger"></i>}
            >
              The Get Zones process was unsuccessful. Double-check your
              Installer Code and try again.
            </Alert>
          ) : null}

          {state.submissionState === "IDLE" ||
          state.submissionState === "ERROR" ? (
            <>
              <div className="form-group">
                <div className="col-12">
                  <label>
                    {panelType === "ECP" ? "VISTA" : ""} Installer Code
                  </label>
                  <NumericInput
                    className="form-control"
                    required
                    value={state.installerKey}
                    minLength={4}
                    maxLength={4}
                    inlineHelp="(0000-9999)"
                    onChange={(event) => {
                      dispatch({
                        type: "UPDATED_INSTALLER",
                        payload: event.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}

          {state.submissionState === "SUCCESS" ? (
            <Alert
              type="success"
              icon={<i className="icon-radial_check text-success"></i>}
            >
              Get Zones Successful.{" "}
              {`Retrieved ${state.zoneCount} Zone${
                state.zoneCount !== 1 ? "s" : ""
              }`}
              .
            </Alert>
          ) : null}

          {submissionIsLoading ? (
            <>
              <Alert
                type="info"
                icon={<i className="fa fa-spin fa-spinner"></i>}
              >
                {state.zoneCount === 0
                  ? `Getting Zones`
                  : `Retrieved ${state.zoneCount} Zone${
                      state.zoneCount !== 1 ? "s" : ""
                    }`}
              </Alert>
              <NeutralText value={700}>
                Please do not leave or refresh this page while in progress.
              </NeutralText>
            </>
          ) : null}
        </div>

        <div className="modal-footer">
          {state.submissionState === "IDLE" ? (
            <button type="submit" className="btn btn-dmp">
              Begin
            </button>
          ) : null}

          {submissionIsLoading ? (
            <button type="button" className="btn btn-dmp" disabled={true}>
              Finish
            </button>
          ) : null}

          {submissionIsComplete ? (
            <>
              {state.submissionState === "ERROR" ? (
                <button type="submit" className="btn btn-dmp">
                  Retry
                </button>
              ) : null}
              <button className="btn btn-dmp" onClick={closeModal}>
                {state.submissionState === "ERROR" ? "Done" : "Finish"}
              </button>
            </>
          ) : null}
        </div>
      </form>
    </Modal>
  );
}

function TakeoverPanelSetup({
  panelType,
  systemID,
  sendSystemOptions,
}: {
  panelType: "DSC" | "ECP";
  systemID: string;
  sendSystemOptions: () => Promise<SaveErrors>;
}) {
  const [activeModal, setActiveModal] = React.useState<
    "SETUP" | "GET_ZONES" | null
  >(null);
  return (
    <>
      <div className="form-group">
        <div className="col-md-4"></div>
        <div className="col-md-8">
          {panelType === "ECP" ? (
            <button
              className="btn btn-dmp btn-xs"
              disabled={!!activeModal}
              onClick={() => {
                setActiveModal("SETUP");
              }}
            >
              Begin ECP Setup
            </button>
          ) : (
            <button
              className="btn btn-dmp btn-xs"
              disabled={!!activeModal}
              onClick={() => {
                setActiveModal("SETUP");
              }}
            >
              Begin DSC Setup
            </button>
          )}{" "}
          <button
            className="btn btn-dmp btn-xs"
            disabled={!!activeModal}
            onClick={() => {
              setActiveModal("GET_ZONES");
            }}
          >
            Get Zones
          </button>
        </div>
      </div>
      {activeModal === "SETUP" ? (
        <SetupModalContent
          panelType={panelType}
          systemID={systemID}
          sendSystemOptions={sendSystemOptions}
          closeModal={() => {
            setActiveModal(null);
          }}
        />
      ) : activeModal === "GET_ZONES" ? (
        <GetZonesModalContent
          systemID={systemID}
          panelType={panelType}
          sendSystemOptions={sendSystemOptions}
          closeModal={() => {
            setActiveModal(null);
          }}
        />
      ) : null}
    </>
  );
}
export function ExportableTakeoverPanelSetup({
  panelType,
  systemID,
  sendSystemOptions,
  refreshZoneInformations,
}: {
  panelType: "DSC" | "ECP";
  systemID: string;
  sendSystemOptions: () => Promise<SaveErrors>;
  refreshZoneInformations: () => Promise<void>;
}) {
  const [activeModal, setActiveModal] = React.useState<
    "SETUP" | "GET_ZONES" | null
  >(null);
  const resetLastUpdated = useResetLastUpdated();
  return (
    <>
      <div className="form-group">
        <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
          {panelType === "ECP" ? (
            <button
              className="btn btn-dmp btn-xs"
              disabled={!!activeModal}
              onClick={() => {
                setActiveModal("SETUP");
              }}
            >
              Begin ECP Setup
            </button>
          ) : (
            <button
              className="btn btn-dmp btn-xs"
              disabled={!!activeModal}
              onClick={() => {
                setActiveModal("SETUP");
              }}
            >
              Begin DSC Setup
            </button>
          )}{" "}
          <button
            className="btn btn-dmp btn-xs"
            disabled={!!activeModal}
            onClick={() => {
              setActiveModal("GET_ZONES");
            }}
          >
            Get Zones
          </button>
        </div>
      </div>
      {activeModal === "SETUP" ? (
        <SetupModalContent
          panelType={panelType}
          systemID={systemID}
          sendSystemOptions={sendSystemOptions}
          closeModal={() => {
            setActiveModal(null);
            resetLastUpdated(takeoverPanelSystemOptionsID);
          }}
        />
      ) : activeModal === "GET_ZONES" ? (
        <GetZonesModalContent
          systemID={systemID}
          panelType={panelType}
          sendSystemOptions={sendSystemOptions}
          refreshZoneInformations={refreshZoneInformations}
          closeModal={() => {
            setActiveModal(null);
            resetLastUpdated(takeoverPanelSystemOptionsID);
          }}
        />
      ) : null}
    </>
  );
}
export function AngularEntryPoint({
  panelType,
  RelayService,
  UserService,
  panel,
}: Props) {
  const environment = useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  const systemID = idAsString(toControlSystemId(UserService.control_system_id));

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <ErrorBoundary fallback={() => <RedirectTo404 />}>
        <React.Suspense fallback={null}>
          <TakeoverPanelSetup
            panelType={panelType === "D" ? "DSC" : ("ECP" as "DSC" | "ECP")}
            systemID={systemID}
            sendSystemOptions={() =>
              new Promise((resolve, reject) => {
                panel
                  .sendProgramming("system_options", false, true)
                  .then(() => resolve([]))
                  .catch(reject);
              })
            }
          />
        </React.Suspense>
      </ErrorBoundary>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "takeoverPanelSetup",
    react2angular(
      AngularEntryPoint,
      ["panelType", "panel"],
      ["RelayService", "UserService", "$rootScope", "$state"]
    )
  );
}
