import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { range } from "ramda";
import * as React from "react";
import { WirelessEncryption1100 } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsWirelessEncryption1100PassphraseField_systemOptions$key } from "./__generated__/SystemOptionsWirelessEncryption1100PassphraseField_systemOptions.graphql";

export const systemOptionsWirelessEncryption1100PassphraseFieldId = () =>
  "system-options-wireless-encryption-1100-passphrase";

function WirelessEncryption1100PassphraseField() {
  const [
    { wirelessEncryption1100, wirelessEncryption1100Passphrase, houseCode },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsWirelessEncryption1100PassphraseField_systemOptions$key>(
    graphql`
      fragment SystemOptionsWirelessEncryption1100PassphraseField_systemOptions on SystemOptions {
        ... on XrSystemOptions {
          wirelessEncryption1100
          wirelessEncryption1100Passphrase
          houseCode
        }
        ... on XtSystemOptions {
          wirelessEncryption1100
          wirelessEncryption1100Passphrase
          houseCode
        }
        ... on Xt75SystemOptions {
          wirelessEncryption1100
          wirelessEncryption1100Passphrase
          houseCode
        }
        ... on XfSystemOptions {
          wirelessEncryption1100
          wirelessEncryption1100Passphrase
          houseCode
        }
      }
    `
  );

  const fieldId = systemOptionsWirelessEncryption1100PassphraseFieldId();
  const houseCodeRange = range(1, 51);
  const disabled =
    wirelessEncryption1100 === WirelessEncryption1100.NONE ||
    !houseCodeRange.includes(Number(houseCode));

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="1100 Wireless Encryption Passphrase"
      disabled={disabled}
    >
      <TextInput
        type="text"
        id={fieldId}
        name={fieldId}
        validationMessage="Valid values are 00000000-FFFFFFFF."
        pattern="([0-9,A,B,C,D,E,F]{8})"
        maxLength={8}
        minLength={8}
        disabled={disabled}
        inlineHelp="00000000-FFFFFFFF"
        required={!disabled}
        value={disabled ? "" : wirelessEncryption1100Passphrase}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              target.value,
              "wirelessEncryption1100Passphrase"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default WirelessEncryption1100PassphraseField;
