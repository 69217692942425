/**
 * @ngdoc service
 * @name App.factory:VideoDeviceService
 *
 * @description
 * API Interface service for Video Devices.
 *
 */
App.factory("VideoDeviceService", [
  "$rootScope",
  "$q",
  "UserService",
  "PROPS",
  "$filter",
  "VideoDevicesV2API",
  "VideoDevice",
  "VideoChannel",
  "ControlSystemService",
  "ControlSystemsService",
  "VIDEO",
  "JobService",
  "$timeout",
  function (
    $rootScope,
    $q,
    UserService,
    PROP,
    $filter,
    VideoDevicesV2API,
    VideoDevice,
    VideoChannel,
    ControlSystemService,
    ControlSystemsService,
    VIDEO,
    JobService,
    $timeout
  ) {
    return {
      isBusy: false,
      deleting: [], // An array of video device ids that are currently being deleted. Used for showing force delete
      canAddChannels: true,

      /**
       * Helper function to determine if the user has any free video channel slots available.
       * @returns {bool} True if the max number of channels hasn't been reached; else false.
       */

      updateCanAddChannels: function (videoDevices) {
        var maxChannels = 0;

        switch (
          ControlSystemsService.currentControlSystem.services_manager
            .video_service_level
        ) {
          case "no_video":
            maxChannels = 0;
            break;
          case "standard":
            maxChannels = 8;
            break;
          case "standard_plus_four":
            maxChannels = 12;
            break;
          case "standard_plus_eight":
            maxChannels = 16;
            break;
          default:
            maxChannels = 8;
        }

        var channelCount = 0;

        angular.forEach(videoDevices, function (device) {
          channelCount += $filter("filter")(device.channels, {
            placeholder: false,
          }).length;
        });

        this.canAddChannels = maxChannels > channelCount;
        return this.canAddChannels;
      },

      /**
       * @ngdoc object
       * @name method:getCurVideoDevice
       * @methodOf App.factory:getVideoDevices
       *
       * @description
       * Gets a list of video devices for the current control system either by reading from a cache
       * or by getting them from the server
       *
       * @return {bool} True if video devices were retrieved, regardless of the method, else, false
       */
      getVideoDevices: function () {
        var deferred = $q.defer();
        var _this = this;
        var controlSystem = ControlSystemsService.currentControlSystem;

        controlSystem
          .getVideoDevices(controlSystem.id)
          .then(
            function (data) {
              var videoDevices = {};
              videoDevices.cameras = _this.getCameras(data); // Separate the cameras out from the returned video devices
              videoDevices.nvrs = _this.getNVRs(data); // Separate the NVRs from the returned video devices
              videoDevices.dvrs = _this.getDVRs(data); // Separate the DVRs from the returned video devices
              _this.updateCanAddChannels(data);
              deferred.resolve(videoDevices);
            },
            function (error) {
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Helper function to get the control system camera list
       * @param data The video devices array returned from the server
       * @returns {Array} video_device array filtered to cameras
       */
      getCameras: function (data) {
        data = $filter("filter")(data, { device_type: "camera" });
        var cameras = [];

        // Loop through the returned data and create new video device objects
        angular.forEach(data, function (camera, key) {
          cameras.push(new VideoDevice(camera));
        });

        return cameras;
      },

      /**
       * Helper function to get the control system NVR list
       * @param data The video devices array returned from the server
       * @returns {Array} video_device array filtered to NVRs
       */
      getNVRs: function (data) {
        data = $filter("filter")(data, { device_type: "nvr" });
        var nvrs = [];

        // Loop through the returned data and create new nvr objects
        angular.forEach(data, function (nvr, key) {
          nvrs.push(new VideoDevice(nvr));
        });

        return nvrs;
      },

      /**
       * Helper function to get the control system DVR list
       * @param data The video devices array returned from the server
       * @returns {Array} video_device array filtered to DVRs
       */
      getDVRs: function (data) {
        data = $filter("filter")(data, { device_type: "dvr" });
        var dvrs = [];

        // Loop through the returned data and create new nvr objects
        angular.forEach(data, function (dvr, key) {
          dvrs.push(new VideoDevice(dvr));
        });

        return dvrs;
      },

      /**
       * @ngdoc object
       * @name method:save
       * @methodOf App.Controller:VideoDeviceService
       *
       * @description
       * Initializes the video device by creating channels if necessary and associating it with a control system.
       * This method also calls the createVideoDevice method which is responsible for saving the video device object
       */
      save: function (videoDevice) {
        var deferred = $q.defer();

        switch (videoDevice.device_type) {
          case "camera":
            // If this is a camera, create a new video channel for it
            videoDevice.channels.length = 0;
            var channel = new VideoChannel();
            channel.device_type = videoDevice.device_type;
            videoDevice.channels.push(channel);
            break;
          case "dvr":
            // If this is a DVR, create 4 new channels for it
            videoDevice.channels.length = 0;
            for (var i = 1; i <= 4; i++) {
              var ch = new VideoChannel();
              ch.number = i;
              ch.name = "Camera 0" + i;
              videoDevice.channels.push(ch);
            }
            break;
        }

        //Set our video device control_system to the current control system
        videoDevice.control_system_id = UserService.control_system_id;
        ControlSystemService.createVideoDevice(
          UserService.control_system_id,
          videoDevice.toJson()
        )
          .then(
            function (data) {
              // If this is a generation 2 NVR, we need to do a device refresh
              if (
                data.video_device.device_type === "nvr" &&
                data.video_device.generation >= 2
              ) {
                var newVideoDevice = new VideoDevice(data.video_device);
                newVideoDevice
                  .refresh()
                  .then(function (data) {
                    JobService.process(data.job.uuid)
                      .then(
                        function (data) {
                          deferred.resolve();
                        },
                        function (error) {
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
              }
              deferred.resolve(data);
            },
            function (error) {
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:get
       * @methodOf App.factory:VideoDeviceService
       *
       * @description
       * Returns the available video device if one was found
       *
       * @return {promise} The promise from the Video Devices API 'new'.
       */
      searchAvailable: function (identifier) {
        var deferred = $q.defer();
        var _this = this;
        VideoDevicesV2API.search_available(
          {
            //params
            identifier: identifier,
          },
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(_this.initVideoDevice(data.video_device));
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:delete
       * @methodOf App.factory:VideoDeviceService
       *
       * @description
       * Deletes and Deactivates the video device
       *
       * @return {promise} The promise from the Video Devices API 'delete'.
       */
      delete: function (id) {
        var deferred = $q.defer();
        var _this = this;
        _this.deleting.push(id); // Add the current video device ID to the list of devices being deleted. Used for toggling "force delete" option
        VideoDevicesV2API.delete(
          { video_device_id: id },
          function (success) {
            // Success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(success);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:forceDelete
       * @methodOf App.factory:VideoDeviceService
       *
       * @description
       * remove the video device from the database
       *
       * @return {promise} The promise from the Video Devices API 'forceDelete'.
       */
      forceDelete: function (id) {
        var deferred = $q.defer();
        var _this = this;
        VideoDevicesV2API.forceDelete(
          {
            video_device_id: id,
            force: true,
          },
          function (success) {
            if (!UserService.site) {
              // Successfully deleted the object, refresh our video device list
              _this
                .getVideoDevices(true)
                .then(
                  function (data) {
                    deferred.notify({
                      job_uuid: "n/a",
                      status: "success",
                      poll_count: 0,
                    });
                    deferred.resolve(success);
                  },
                  function (error) {
                    deferred.notify({
                      job_uuid: "n/a",
                      status: "error",
                      poll_count: 0,
                    });
                    deferred.resolve(success); // Even though this call failed, the delete operation was successful
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              deferred.resolve(success);
            }
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      isDeleting: function (id) {
        return this.deleting.indexOf(id) != -1 || false;
      },

      /**
       * @ngdoc object
       * @name method:get
       * @methodOf App.factory:VideoDeviceService
       *
       * @description
       * Returns a new video device initialized with the specified properties
       *
       * @return {VideoDevice} A new video device object with the specified properties
       */
      initVideoDevice: function (deviceProperties) {
        return new VideoDevice(deviceProperties);
      },
    };
  },
]);
