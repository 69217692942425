import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { range } from "ramda";
import * as React from "react";
import { PanelHardwareModel } from "securecom-graphql/client";
import { getClosestOutputValue } from "../OutputOptionsFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsBellOutputField_bellOptions$key } from "./__generated__/BellOptionsBellOutputField_bellOptions.graphql";

export const bellOptionsBellOutputFieldId = () => "bell-options-bell-test";

const getInlineHelp = (hardwareModel: PanelHardwareModel) => {
  switch (hardwareModel) {
    case PanelHardwareModel.XR550:
      return "1-6, 450-474, 480-999, D01-D16, G01-G20, and F01-F20";
    case PanelHardwareModel.XT75:
      return "0, 1-4, 450-474, 480-499, 500-549, D01-D08 and F01-F20";
    case PanelHardwareModel.XF6_100:
      return "1-6, 500-599, and G01-G20";
    case PanelHardwareModel.XF6_500:
      return "1-6, 500-999, and G01-G20";
    case PanelHardwareModel.XR350:
      return "1-6, 450-474, 480-799, D01-D16, G01-G20, and F01-F20";
    case PanelHardwareModel.XR150:
      return "1-6, 450-474, 480-599, D01-D08, G01-G20, and F01-F20";
    case PanelHardwareModel.XTLP:
      return "0, 51-54, 61-64, F01-F20";
    default:
      return "0, 1-4, 31-34, 41-44, F01-F20";
  }
};

const getPattern = (hardwareModel: PanelHardwareModel) => {
  switch (hardwareModel) {
    case PanelHardwareModel.XR550:
      return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-9][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))";
    case PanelHardwareModel.XT75:
      return "(0{0,3}|0{0,2}[1-4]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-4][0-9]|D0[1-8]|[F](0[1-9]|1[0-9]|20))";
    case PanelHardwareModel.XF6_100:
      return "(0{0,3}|0{0,2}[1-6]|5[0-9][0-9]|[G](0[1-9]|1[0-9]|20))";
    case PanelHardwareModel.XF6_500:
      return "(0{0,3}|0{0,2}[1-6]|[5-9][0-9][0-9]|[G](0[1-9]|1[0-9]|20))";
    case PanelHardwareModel.XR350:
      return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-7][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))";
    case PanelHardwareModel.XR150:
      return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-9][0-9]|D0[1-8]|[FG](0[1-9]|1[0-9]|20))";
    case PanelHardwareModel.XTLP:
      return "(0{0,2}0|0{0,1}5[1-4]|0{0,1}6[1-4]|F0[1-9]|F1[0-9]|F20)";
    default:
      return "(0{0,2}0|0{0,2}[1-4]|0{0,1}3[1-4]|0{0,1}4[1-4]|F0[1-9]|F1[0-9]|F20)";
  }
};

const getValidRange = (hardwareModel: PanelHardwareModel) => {
  switch (hardwareModel) {
    case PanelHardwareModel.XR550:
      return range(0, 7).concat(range(450, 475)).concat(range(480, 1000));
    case PanelHardwareModel.XT75:
      return range(0, 1)
        .concat(range(1, 5))
        .concat(range(450, 475))
        .concat(range(480, 550));
    case PanelHardwareModel.XR350:
      return range(0, 7).concat(range(450, 475)).concat(range(480, 800));
    case PanelHardwareModel.XR150:
      return range(0, 7).concat(range(450, 475)).concat(range(480, 600));
    case PanelHardwareModel.XTLP:
      return range(0, 1).concat(range(51, 55)).concat(range(61, 65));
    case PanelHardwareModel.XF6_500:
      return range(0, 7).concat(range(500, 1000));
    case PanelHardwareModel.XF6_100:
      return range(0, 7).concat(range(500, 600));
    default:
      return range(0, 5).concat(range(31, 35)).concat(range(41, 45));
  }
};

function BellOptionsBellOutputField() {
  const [{ bellOutput }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsBellOutputField_bellOptions$key>(
      graphql`
        fragment BellOptionsBellOutputField_bellOptions on BellOptions {
          bellOutput
        }
      `
    );

  const fieldId = bellOptionsBellOutputFieldId();
  const hardwareModel = useHardwareModel();
  const inlineHelp = getInlineHelp(hardwareModel as PanelHardwareModel);
  const pattern = getPattern(hardwareModel as PanelHardwareModel);
  const originalValue = React.useRef(bellOutput).current;
  const validRange = getValidRange(hardwareModel as PanelHardwareModel);

  //Corrected values and input validation is based on patterns in OutputOptionsFields/utils.
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Bell Output"
      tooltip="This Output will follow the operation of the System's Bell Output."
    >
      <TextInput
        id={fieldId}
        inlineHelp={inlineHelp}
        value={bellOutput}
        pattern={pattern}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(target.value, "bellOutput");
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                originalValue,
                hardwareModel,
                validRange
              ),
              "bellOutput"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsBellOutputField;
