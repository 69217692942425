/**
 * @generated SignedSource<<122075dd2b6b5f7b77a83d7c76ef484f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly axNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceCommunicationMethod: {
      readonly data: DeviceInformationCommType | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceType: {
      readonly data: DeviceInformationType | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lxNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
  } | null> | null;
  readonly " $fragmentType": "XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "8d1dae35ccc4cf4112b886b5ae6e5a3d";

export default node;
