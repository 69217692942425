/**
 * @generated SignedSource<<5b64b72b9979007be17d24d2b086c907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplatesListQuery$variables = {
  dealerId: string;
};
export type TemplatesListQuery$data = {
  readonly dealer: {
    readonly id: string;
    readonly programmingTemplates: {
      readonly totalCount: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"TemplatesList_dealer">;
  } | null;
};
export type TemplatesListQuery = {
  response: TemplatesListQuery$data;
  variables: TemplatesListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dealerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplatesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgrammingTemplatesConnection",
            "kind": "LinkedField",
            "name": "programmingTemplates",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TemplatesList_dealer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplatesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dealer",
        "kind": "LinkedField",
        "name": "dealer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgrammingTemplatesConnection",
            "kind": "LinkedField",
            "name": "programmingTemplates",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProgrammingTemplateEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProgrammingTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hardwareModel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b5393cf3b6c1c450237e59b26cdc82db",
    "id": null,
    "metadata": {},
    "name": "TemplatesListQuery",
    "operationKind": "query",
    "text": "query TemplatesListQuery(\n  $dealerId: ID!\n) {\n  dealer(id: $dealerId) {\n    id\n    programmingTemplates {\n      totalCount\n    }\n    ...TemplatesList_dealer\n  }\n}\n\nfragment TemplatesList_dealer on Dealer {\n  programmingTemplates {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        hardwareModel\n        customer {\n          id\n          name\n        }\n      }\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "31a7d27fcf6d56006008d936cd3a68e8";

export default node;
