import Flex from "common/components/web/Flex";
import {
  FieldLabelCell,
  FieldLabelContainer,
  FieldLabelText,
  FieldValueCell,
  RegionSettingsDeleteButton,
  RegionSettingsHeaderText,
  RegionsSettingsRoot,
  RegionsSettingsRow,
  RegionsSettingsTable,
  SensitivityValue,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import Icon from "components/Icon";
import React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  MotionDetectionRegion,
  SecureComCamera,
} from "securecom-graphql/client";
import { UniviewCameraEditForm_secureComCamera$data } from "./__generated__/UniviewCameraEditForm_secureComCamera.graphql";

interface UniviewMotionDetectionRegionSettingsProps {
  index: number;
  data: UniviewCameraEditForm_secureComCamera$data;
  isEditable: boolean;
  motionDetectionRegion: MotionDetectionRegion;
}
function UniviewMotionDetectionRegionSettings(
  props: UniviewMotionDetectionRegionSettingsProps
) {
  const { index, data, isEditable, motionDetectionRegion } = props;
  const relayEnv = useRelayEnvironment();
  return (
    <RegionsSettingsRoot>
      <RegionsSettingsTable>
        <thead>
          <RegionsSettingsRow>
            <th colSpan={2}>
              <Flex alignItems="center" justifyContent="space-between">
                <RegionSettingsHeaderText
                  index={index}
                  onMouseEnter={() => {
                    // brings the region to the top of the pile
                    relayEnv.commitUpdate((store) => {
                      const camera = store.get(
                        data.id
                      ) as RecordProxy<SecureComCamera>;
                      if (camera && isEditable) {
                        camera
                          .getLinkedRecord("motionDetectionRegions")
                          .setValue(index, "activeDetectionRegionIndex");
                      }
                    });
                  }}
                >
                  Region #{index + 1}
                </RegionSettingsHeaderText>
                {isEditable && (
                  <RegionSettingsDeleteButton
                    type="button"
                    onClick={() => {
                      relayEnv.commitUpdate((store) => {
                        const camera = store.get(
                          data.id
                        ) as RecordProxy<SecureComCamera>;
                        const motionDetectionRegions = camera.getLinkedRecord(
                          "motionDetectionRegions"
                        );
                        const regions = motionDetectionRegions
                          .getLinkedRecords("regions")
                          .filter(
                            (region) =>
                              region.getValue("id") !== motionDetectionRegion.id
                          );

                        regions.forEach((region, index) =>
                          region.setValue(index, "index")
                        ); // Resetting index for regions
                        motionDetectionRegions.setLinkedRecords(
                          regions,
                          "regions"
                        );
                        motionDetectionRegions.setValue(
                          null,
                          "activeDetectionRegionIndex"
                        );
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </RegionSettingsDeleteButton>
                )}
              </Flex>
            </th>
          </RegionsSettingsRow>
        </thead>
        <tbody>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${index}-sensitivity`}
                >
                  Sensitivity
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <input
                id={`detection-region-${index}-sensitivity`}
                name={`detection-region-${index}-sensitivity`}
                type="range"
                min="1"
                max="100"
                value={motionDetectionRegion.daySensitivityLevel}
                onChange={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const value = Number(event.target.value);
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<SecureComCamera>;
                    camera
                      .getLinkedRecord("motionDetectionRegions")
                      .getLinkedRecords("regions")
                      [index].setValue(value, "daySensitivityLevel");
                    camera
                      .getLinkedRecord("motionDetectionRegions")
                      .getLinkedRecords("regions")
                      [index].setValue(value, "nightSensitivityLevel");
                  });
                }}
              />
              <SensitivityValue>
                {motionDetectionRegion.daySensitivityLevel}%
              </SensitivityValue>
            </FieldValueCell>
          </RegionsSettingsRow>
        </tbody>
      </RegionsSettingsTable>
    </RegionsSettingsRoot>
  );
}

export default UniviewMotionDetectionRegionSettings;
