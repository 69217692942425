/**
 * @generated SignedSource<<402682d345427f9d8fdad71d4207f900>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationCheckInMinutesField_communication$data = {
  readonly checkInMinutes: number;
  readonly checkInMinutesValidValues: ReadonlyArray<number>;
  readonly comType: CommunicationComType;
  readonly failTime: number;
  readonly " $fragmentType": "CommunicationCheckInMinutesField_communication";
};
export type CommunicationCheckInMinutesField_communication$key = {
  readonly " $data"?: CommunicationCheckInMinutesField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationCheckInMinutesField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationCheckInMinutesField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInMinutesValidValues",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTime",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "5b78bab64e202b9787b38b5b34fee2dc";

export default node;
