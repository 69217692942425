/**
 * @name App.factory.BillingService
 *
 * Service for Billing.
 *
 */
App.factory("BillingService", [
  "$q",
  "BillingAPI",
  function ($q, BillingAPI) {
    return {
      getAccountSummary: function (dealerCode, isCached = true) {
        var defferred = $q.defer();

        if (isCached) {
          BillingAPI.getAccountSummaryCached(
            { dealer_code: dealerCode },
            function (data) {
              defferred.resolve(data);
            },
            function (error) {}
          );
        }

        return defferred.promise;
      },
    };
  },
]);
