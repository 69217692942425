App.directive("daServiceRequest", [
  "UserService",
  "$rootScope",
  function (UserService, $rootScope) {
    return {
      restrict: "A",
      scope: {
        selectedTech: "=",
        serviceRequest: "=",
        onSave: "&",
        reopenRequest: "&",
        controlSystemId: "=",
        controlSystemName: "@controlSystemName",
      },
      link: function (scope, element) {
        element.on("click", function () {
          scope.openServiceRequestModal();
        });
      },

      controller: function ($scope, $modal) {
        $scope.openServiceRequestModal = function () {
          $scope.serviceRequestModal = $modal.open({
            templateUrl: "app/common/templates/service-request.html",
            size: "md",
            backdrop: "static",
            // show: 'true',
            scope: $scope,
            controller: "ServiceRequestModalCtrl",
          });
        };
      },
    };
  },
]);

App.controller(
  "ServiceRequestModalCtrl",
  function (
    $scope,
    $modalInstance,
    ServiceRequestService,
    UserService,
    $q,
    $timeout,
    $rootScope,
    $filter,
    NearestTechService
  ) {
    $scope.isBusy = true;

    if (!$scope.serviceRequest) {
      // Need to initialize SR OBJ if there is no data
      $scope.serviceRequest = {
        TechnicianId: "",
        isNew: true,
        ControlSystemName: $scope.controlSystemName,
      };
      $scope.appendNotes = false;
    } else {
      $scope.serviceRequest.isNew = false;
      $scope.appendNotes = true;
    }

    $scope.addNotes = function () {
      if ($scope.appendNotes) $scope.appendNotes = false;
      else $scope.appendNotes = true;
    };

    //if ($scope.serviceRequest.Status === 'closed') $scope.reopenRequest($scope.serviceRequest); // Fn from service-request-dashboard

    $scope.service_request_start = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datePickerOpen.serviceRequestStartDate = true;
    };

    $scope.close = function () {
      $scope.serviceRequestModal.close();
    };

    function getDealerPersonnel() {
      UserService.dealerInfo.getPersonnel().then(
        function (data) {
          $scope.dealerPersonnel = data;
          $scope.isBusy = false;
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to Retrieve Personnel",
            json: error,
          });
        }
      );
    }

    $scope.submitServiceRequest = function () {
      var deferred = $q.defer();
      $scope.serviceRequest.DealerId = UserService.dealerInfo.id;
      if (!$scope.serviceRequest.isNew) {
        if ($scope.serviceRequest.AdditionalNotes) {
          ServiceRequestService.addNotes(
            $scope.serviceRequest,
            $scope.serviceRequest.AdditionalNotes
          ).then(
            function () {
              $scope.serviceRequest.AdditionalNotes = "";
              $scope.onSave();
              $rootScope.alerts.push({
                type: "success",
                text: "Success Adding Notes",
              });
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error Adding Notes",
                json: error,
              });
            }
          );
        } else {
          ServiceRequestService.editServiceRequest(
            $scope.serviceRequest.Id,
            $scope.serviceRequest
          ).then(
            function (data) {
              $scope.onSave();
              $rootScope.alerts.push({
                type: "success",
                text: "Success Editing Service Request",
              });
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error Editing Service Request",
                json: error,
              });
            }
          );
        }
      } else {
        if (!$scope.controlSystemId) {
          // for move of modal to Customer page. We pass in controlSystemId there, but everywhere else we set it within directive
          $scope.serviceRequest.ControlSystemId = parseInt(
            UserService.controlSystem.control_system_id
          );
        } else {
          $scope.serviceRequest.ControlSystemId = $scope.controlSystemId;
        }
        $scope.serviceRequest.Type = "service";
        ServiceRequestService.createServiceRequest($scope.serviceRequest).then(
          function (data) {
            $timeout(function () {
              $scope.someData = data;
              deferred.resolve(data);
            }, 1000);
            $rootScope.alerts.push({
              type: "success",
              text: "New Service Request Created",
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Creating Service Request",
              json: error,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      }
    };

    $scope.updateRequestStatus = function (type) {
      $scope.serviceRequest.Status = type;
      var filteredDate = $filter("date")(
        $scope.serviceRequest.StartDate,
        "yyyy-MM-ddTHH:mm:ssZ",
        "UTC"
      );
      filteredDate = `${filteredDate.slice(
        0,
        filteredDate.length - 2
      )}:${filteredDate.slice(filteredDate.length - 2, filteredDate.length)}`;
      $scope.serviceRequest.StartDate = filteredDate;
      if ($scope.serviceRequest.AdditionalNotes) {
        ServiceRequestService.addNotes(
          $scope.serviceRequest,
          $scope.serviceRequest.AdditionalNotes
        ).then(
          function () {
            $scope.serviceRequest.AdditionalNotes = "";
            if (type === "closed") {
              $scope.onSave();
            }

            $rootScope.alerts.push({
              type: "success",
              text: "Success Adding Notes",
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Adding Notes",
              json: error,
            });
          }
        );
      } else {
        ServiceRequestService.editServiceRequest(
          $scope.serviceRequest.Id,
          $scope.serviceRequest
        ).then(
          function (data) {
            if (type === "closed") {
              $scope.onSave();
            }

            $rootScope.alerts.push({
              type: "success",
              text: "Service Request Successfully " + type,
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Closing Service Request",
              json: error,
            });
          }
        );
      }
    };

    function getNearestTech() {
      if ($scope.serviceRequest.isNew) {
        NearestTechService.retrieveNearestTech().then(function (technician) {
          if (technician.id) {
            $scope.serviceRequest.TechnicianId = technician.person_id;
          }
        });
      }
    }

    function init() {
      $scope.datePickerOpen = {};
      $scope.dateFormat = "MM-dd-yyyy";
      getDealerPersonnel();
      getNearestTech();
      if ($scope.serviceRequest.StartDate)
        $scope.serviceRequest.StartDate = new Date(
          $scope.serviceRequest.StartDate
        );
    }
    init();
  }
);
