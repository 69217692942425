/**
 * @generated SignedSource<<d02064ff58e72a140d8de10ec5b6eaec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFDeviceSetupProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly deviceInformations: ReadonlyArray<{
      readonly __typename: string;
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XFDeviceSetupProgrammingConceptFormInline_deviceInformation">;
    }>;
    readonly deviceNumberRange: ReadonlyArray<number>;
    readonly id: string;
    readonly keypadBusDevicesMax: number;
    readonly totalDevicesMax: number;
  };
  readonly " $fragmentType": "XFDeviceSetupProgrammingConceptFormInline_controlSystem";
};
export type XFDeviceSetupProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFDeviceSetupProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceSetupProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFDeviceSetupProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "2681042fef6128761c87a429023938d1";

export default node;
