/**
 * @generated SignedSource<<3406d7882f0857c40979b30a41ee79ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type BellOptionsCarbonMonoxideBellActionField_bellOptions$data = {
  readonly carbonMonoxideBellAction: BellActions;
  readonly " $fragmentType": "BellOptionsCarbonMonoxideBellActionField_bellOptions";
};
export type BellOptionsCarbonMonoxideBellActionField_bellOptions$key = {
  readonly " $data"?: BellOptionsCarbonMonoxideBellActionField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsCarbonMonoxideBellActionField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsCarbonMonoxideBellActionField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carbonMonoxideBellAction",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "b98fe9e380598279a5ac18a03ceeae5a";

export default node;
