App.controller("ServiceRequestDashboardCtrl", [
  "$scope",
  "$rootScope",
  "$q",
  "ServiceRequestService",
  "UserService",
  "$timeout",
  "ControlSystemsService",
  "$filter",
  function (
    $scope,
    $rootScope,
    $q,
    ServiceRequestService,
    UserService,
    $timeout,
    ControlSystemsService,
    $filter
  ) {
    init();

    /**
     * Add the GET call to view the Service Request
     * TODO: Either wrap this in a function and call in init,
     * or leave at the top of page
     */
    $scope.getDealerServiceRequest = function () {
      var deferred = $q.defer();
      ServiceRequestService.getDealerServiceRequest($scope.dealer_id).then(
        function (data) {
          $scope.numOpenRequests = 0;
          $scope.serviceRequests = data;
          groupJobsByDay();
          getNumOpenRequests($scope.serviceRequests);
          $scope.updateView();
          deferred.resolve(data);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error Getting Service Requests",
            json: error,
          });
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
    $scope.getDealerServiceRequest();

    $scope.deleteServiceRequest = function (request) {
      ServiceRequestService.deleteServiceRequest(request.Id).then(
        function (data) {
          $scope.getDealerServiceRequest();
          $rootScope.alerts.push({
            type: "success",
            text: "Service Request Delete Successful",
          });
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error Deleting Service Request",
            json: error,
          });
        }
      );
    };

    $scope.reopenServiceRequest = function (request) {
      let request_id = request.Id;
      request.Status = "open";

      ServiceRequestService.editServiceRequest(request_id, request).then(
        function () {
          $scope.getDealerServiceRequest();
          $rootScope.alerts.push({
            type: "success",
            text: "Service Request Reopen Successful",
          });
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error Closing Service Request",
            json: error,
          });
        }
      );
    };

    function groupJobsByDay() {
      function compare(a, b) {
        dateA = new Date(a.StartDate);
        dateB = new Date(b.StartDate);

        if (dateA > dateB) {
          return 1;
        } else {
          return -1;
        }
      }

      $scope.serviceRequests.sort(compare);

      $scope.jobsByDay = [];
      let i = 0;
      while (i < $scope.serviceRequests.length) {
        let currentDayJobs = [];

        currentDayJobs.push($scope.serviceRequests[i]);
        i++;

        let refDay = new Date(currentDayJobs[0].StartDate).getDate();
        let refMonth = new Date(currentDayJobs[0].StartDate).getMonth();

        while (
          i < $scope.serviceRequests.length &&
          refDay === new Date($scope.serviceRequests[i].StartDate).getDate() &&
          refMonth === new Date($scope.serviceRequests[i].StartDate).getMonth()
        ) {
          currentDayJobs.push($scope.serviceRequests[i]);
          i++;
        }

        $scope.jobsByDay.push(currentDayJobs);
      }
    }

    function getNumOpenRequests(requests) {
      for (let request of requests) {
        if (request.Status === "open") {
          $scope.numOpenRequests++;
        }
      }
    }

    $scope.toggleCompleted = function () {
      $scope.showCompleted
        ? ($scope.showCompleted = false)
        : ($scope.showCompleted = true);
      $scope.updateView();
    };

    $scope.updateView = function () {
      $scope.filteredJobs = [...$scope.jobsByDay];

      for (let day in $scope.filteredJobs) {
        $scope.filteredJobs[day] = $scope.filteredJobs[day].filter((job) => {
          let returnStatement = $scope.showCompleted
            ? true
            : job.Status === "open";
          return returnStatement;
        });
      }
    };

    function init() {
      $scope.showCompleted = false;
      $scope.closedRequests = [];
      $scope.dealer_id = UserService.dealer_id;
    }
  },
]);
