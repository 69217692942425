/**
 * @generated SignedSource<<cb92fbd4e639567b02c75f5af1a79a04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsCoolSaverTemperatureField_outputOptions$data = {
  readonly coolSaverTemperature: string;
  readonly id: string;
  readonly " $fragmentType": "OutputOptionsCoolSaverTemperatureField_outputOptions";
};
export type OutputOptionsCoolSaverTemperatureField_outputOptions$key = {
  readonly " $data"?: OutputOptionsCoolSaverTemperatureField_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsCoolSaverTemperatureField_outputOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsCoolSaverTemperatureField_outputOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coolSaverTemperature",
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};

(node as any).hash = "fb23d25fe443b3c5bc4865e55d3a35b7";

export default node;
