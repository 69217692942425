import GenericTextInput from "components/Inputs/TextInput";
import * as React from "react";
import { useFieldChangesAndValidity } from "../hooks/use-field-changes-and-validity";

const TextInput = React.forwardRef(
  (
    props: {
      id: string;
      getValidationMessage?: (input: HTMLInputElement) => string;
    } & Omit<React.ComponentProps<typeof GenericTextInput>, "id">,
    ref: React.ForwardedRef<HTMLInputElement | null>
  ) => {
    const {
      id,
      value,
      onChange,
      onFocus,
      onBlur,
      onInvalid,
      getValidationMessage,
      ...rest
    } = props;
    return (
      <GenericTextInput
        {...rest}
        {...useFieldChangesAndValidity({
          ref,
          id,
          value,
          onChange,
          onFocus,
          onBlur,
          onInvalid,
          getValidationMessage,
        })}
      />
    );
  }
);

export default TextInput;
