import graphql from "babel-plugin-relay/macro";
import { MessagePosition } from "common/components/web/Tooltip";
import InlineTooltip from "components/InlineTooltip";
import RelayEnvironmentCloneProvider from "components/RelayEnvironmentCloneProvider";
import React from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import Select, {
  ActionMeta,
  components,
  OptionsType,
  OptionTypeBase,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { RecordProxy } from "relay-runtime";
import {
  idAsString,
  Tag as TagProxy,
  toControlSystemId,
  toCustomerId,
  toDealerId,
  toGlobalId,
  toSiteId,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import { TagFieldAddTagMutation } from "./__generated__/TagFieldAddTagMutation.graphql";
import { TagFieldCreateTagMutation } from "./__generated__/TagFieldCreateTagMutation.graphql";
import { TagFieldQuery } from "./__generated__/TagFieldQuery.graphql";
import { TagFieldRemoveTagMutation } from "./__generated__/TagFieldRemoveTagMutation.graphql";
import { TagFieldValueQuery } from "./__generated__/TagFieldValueQuery.graphql";

interface Tag {
  readonly id: number;
  readonly label: string;
  readonly value: string;
}
interface TagFieldProps {
  customerId?: string;
  dealerId: number;
  isEditable: boolean;
  type: string;
  typeId: number;
}
function TagField(props: TagFieldProps) {
  const { customerId, dealerId, isEditable, type, typeId } = props;
  let encodedTypeId: string;

  switch (type) {
    case "Panels":
      encodedTypeId = idAsString(toControlSystemId(typeId));
      break;
    case "Customers":
      encodedTypeId = idAsString(toCustomerId(typeId));
      break;
    case "CustomRoles":
      encodedTypeId = idAsString(toGlobalId(type, typeId));
      break;
    case "Sites":
      encodedTypeId = customerId
        ? idAsString(toSiteId(customerId, typeId))
        : "";
      break;
    default:
      encodedTypeId = "";
  }

  const data = useLazyLoadQuery<TagFieldQuery>(
    graphql`
      query TagFieldQuery($dealerId: ID!) {
        dealer: node(id: $dealerId) {
          ... on Dealer {
            tags {
              id
              label
              value
            }
          }
        }
      }
    `,
    { dealerId: idAsString(toDealerId(dealerId)) }
  );
  const tagFieldValue = useLazyLoadQuery<TagFieldValueQuery>(
    graphql`
      query TagFieldValueQuery($typeId: ID!) {
        getTagsByType(id: $typeId) {
          tags {
            id
            isFixed
            label
            value
          }
        }
      }
    `,
    { typeId: encodedTypeId },
    { fetchPolicy: "network-only" }
  );
  const [addTag, isAddingTag] =
    useMutation<TagFieldAddTagMutation>(addTagMutation);
  const [removeTag, isRemovingTag] =
    useMutation<TagFieldRemoveTagMutation>(removeTagMutation);
  const [createTag, isCreatingTag] =
    useMutation<TagFieldCreateTagMutation>(createTagMutation);
  const isLoading = isAddingTag || isRemovingTag || isCreatingTag;
  const isSystemType = type === "Panels" || type === "Sites";

  const handleOnChange = (
    _value: OptionsType<OptionTypeBase>,
    actionMeta: ActionMeta<OptionTypeBase>
  ) => {
    switch (actionMeta.action) {
      case "select-option":
        if (actionMeta.option?.id) {
          addTag({
            variables: {
              encodedTagId: actionMeta.option.id,
              tagType: type,
              typeId: typeId,
            },
            updater: (store, results) => {
              if (
                results.addTag.status === "SUCCESS" &&
                results.addTag.addedTagId
              ) {
                const tagQueryResults = store
                  .getRoot()
                  .getLinkedRecord("getTagsByType", { id: encodedTypeId });
                const usedTags =
                  tagQueryResults?.getLinkedRecords("tags") || [];
                const addedTag = store.get(
                  results.addTag.addedTagId
                ) as RecordProxy<TagProxy>;

                usedTags.push(addedTag);
                tagQueryResults?.setLinkedRecords(usedTags, "tags");
              }
            },
          });
        }
        break;
      case "remove-value":
        if (actionMeta.removedValue?.id) {
          removeTag({
            variables: {
              encodedTagId: actionMeta.removedValue.id,
              tagType: type,
              typeId: typeId,
            },
            updater: (store, results) => {
              if (results.removeTag.status === "SUCCESS") {
                const tagQueryResults = store
                  .getRoot()
                  .getLinkedRecord("getTagsByType", { id: encodedTypeId });
                let usedTags = tagQueryResults?.getLinkedRecords("tags") || [];

                usedTags = usedTags.filter(
                  (tagProxy) =>
                    tagProxy.getValue("id") !== results.removeTag.removedTagId
                );
                tagQueryResults?.setLinkedRecords(usedTags, "tags");
              }
            },
          });
        }
        break;
    }
  };
  const handleOnCreate = (inputValue: string) => {
    createTag({
      variables: {
        dealerId: dealerId.toString(),
        name: inputValue,
        tagType: type,
        typeId: typeId,
      },
      updater: (store, results) => {
        if (
          results.createTag.status === "SUCCESS" &&
          results.createTag.newTagId
        ) {
          const tagQueryResults = store
            .getRoot()
            .getLinkedRecord("getTagsByType", { id: encodedTypeId });
          const usedTags = tagQueryResults?.getLinkedRecords("tags") || [];
          const createdTag = store.get(
            results.createTag.newTagId
          ) as RecordProxy<TagProxy>;

          usedTags.push(createdTag);
          tagQueryResults?.setLinkedRecords(usedTags, "tags");
        }
      },
    });
  };
  const styles = {
    menu: (baseStyles: any) => ({
      ...baseStyles,
      zIndex: 110,
    }),
    multiValue: (baseStyles: any, state: any) => {
      return state.data.isFixed && isSystemType
        ? { ...baseStyles, backgroundColor: "gray" }
        : { ...baseStyles, display: "flex" };
    },
    multiValueLabel: (baseStyles: any, state: any) => {
      return state.data.isFixed && isSystemType
        ? {
            ...baseStyles,
            fontWeight: "bold",
            fontSize: "100%",
            color: "white",
            paddingRight: 6,
          }
        : { ...baseStyles, fontSize: "100%" };
    },
    multiValueRemove: (baseStyles: any, state: any) => {
      return state.data.isFixed && isSystemType
        ? { ...baseStyles, color: "white" }
        : baseStyles;
    },
  };
  const Input = (props: any) => <components.Input {...props} maxLength={30} />;
  const MultiValueRemove = (props: any) => {
    return (
      <>
        {props.data.isFixed && isSystemType ? (
          <RemoveIconContainer>
            <InlineTooltip
              message="This tag is applied to the customer and cannot be removed."
              position={MessagePosition.Top}
              icon={RemoveIcon()}
            />
          </RemoveIconContainer>
        ) : (
          <components.MultiValueRemove {...props} />
        )}
      </>
    );
  };
  const NoOptionsMessage = (props: any) => (
    <components.NoOptionsMessage {...props}>
      No Tags
    </components.NoOptionsMessage>
  );

  return (
    <RelayEnvironmentCloneProvider>
      {type === "CustomRoles" ? (
        <Select
          components={{ Input, NoOptionsMessage }}
          isClearable={false}
          isDisabled={!isEditable || isLoading}
          isLoading={isLoading}
          isMulti
          menuPlacement="auto"
          onChange={handleOnChange}
          options={data.dealer?.tags as unknown as Tag[]}
          styles={styles}
          value={tagFieldValue.getTagsByType?.tags || []}
        />
      ) : (
        <CreatableSelect
          components={{ Input, MultiValueRemove, NoOptionsMessage }}
          isClearable={false}
          isDisabled={!isEditable || isLoading}
          isLoading={isLoading}
          isMulti
          menuPlacement="auto"
          onChange={handleOnChange}
          onCreateOption={handleOnCreate}
          options={data.dealer?.tags as unknown as Tag[]}
          styles={styles}
          value={tagFieldValue.getTagsByType?.tags || []}
        />
      )}
    </RelayEnvironmentCloneProvider>
  );
}

const RemoveIcon = () => {
  return (
    <Svg
      height="14"
      width="14"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </Svg>
  );
};
const RemoveIconContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Svg = styled.svg`
  display: inline-block;
  fill: white;
  line-height: 1;
  stroke: white;
  stroke-width: 0;
  cursor: default;
`;

const addTagMutation = graphql`
  mutation TagFieldAddTagMutation(
    $encodedTagId: ID!
    $tagType: String!
    $typeId: Int!
  ) {
    addTag(encodedTagId: $encodedTagId, tagType: $tagType, typeId: $typeId) {
      ... on AddTagSuccessPayload {
        addedTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;
const removeTagMutation = graphql`
  mutation TagFieldRemoveTagMutation(
    $encodedTagId: ID!
    $tagType: String!
    $typeId: Int!
  ) {
    removeTag(encodedTagId: $encodedTagId, tagType: $tagType, typeId: $typeId) {
      ... on RemoveTagSuccessPayload {
        removedTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;
const createTagMutation = graphql`
  mutation TagFieldCreateTagMutation(
    $dealerId: String!
    $name: String!
    $tagType: String!
    $typeId: Int!
  ) {
    createTag(
      dealerId: $dealerId
      name: $name
      tagType: $tagType
      typeId: $typeId
    ) {
      ... on CreateTagSuccessPayload {
        dealer {
          tags {
            id
            label
            value
          }
        }
        newTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;

export default TagField;
