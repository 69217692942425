/**
 * @generated SignedSource<<e7da254fc3ab9ef57b23c1555e453e72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CameraMobileWidgetQuery_controlSystemQuery$variables = {
  id: string;
};
export type CameraMobileWidgetQuery_controlSystemQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CameraMobileWidgetQuery_controlSystem">;
  } | null;
};
export type CameraMobileWidgetQuery_controlSystemQuery = {
  response: CameraMobileWidgetQuery_controlSystemQuery$data;
  variables: CameraMobileWidgetQuery_controlSystemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CameraMobileWidgetQuery_controlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CameraMobileWidgetQuery_controlSystem"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CameraMobileWidgetQuery_controlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SecureComCamera",
                "kind": "LinkedField",
                "name": "secureComCameras",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastCheckIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOnline",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "model",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "formFactor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageSize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "macAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "networkReady",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manufacturer",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "987781fa7394bdad1d48e9aaa770453d",
    "id": null,
    "metadata": {},
    "name": "CameraMobileWidgetQuery_controlSystemQuery",
    "operationKind": "query",
    "text": "query CameraMobileWidgetQuery_controlSystemQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CameraMobileWidgetQuery_controlSystem\n    id\n  }\n}\n\nfragment CameraMobileWidgetQuery_controlSystem on ControlSystem {\n  secureComCameras {\n    id\n    deviceType\n    name\n    lastCheckIn\n    isOnline\n    status\n    model\n    formFactor\n    imageSize\n    macAddress\n    networkReady\n    manufacturer\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "79daf3b61a38e6d9427fee6b59b405b1";

export default node;
