import graphql from "babel-plugin-relay/macro";
import { useSetHasChanges } from "contexts/HasChangesContext";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";
import { InitialConnectionStatus } from "securecom-graphql/client";
import { ControlSystemContextUseControlSystemCapabilities_controlSystem$key } from "./__generated__/ControlSystemContextUseControlSystemCapabilities_controlSystem.graphql";
import { ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem$key } from "./__generated__/ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem.graphql";
import { ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem$key } from "./__generated__/ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem.graphql";
import { ControlSystemContextUseVarEnabled_controlSystem$key } from "./__generated__/ControlSystemContextUseVarEnabled_controlSystem.graphql";

const ControlSystemContext = React.createContext<any>({});

export function ControlSystemContextProvider(props: {
  controlSystem: any;
  children: React.ReactNode;
}) {
  return (
    <ControlSystemContext.Provider value={props.controlSystem}>
      {props.children}
    </ControlSystemContext.Provider>
  );
}

export const useControlSystem = () => React.useContext(ControlSystemContext);

export function useControlSystemFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();

  const data = useFragment(fragmentInput, controlSystem as TKey);

  const { id } = useFragment(
    graphql`
      fragment ControlSystemContext_controlSystem on ControlSystem {
        id
      }
    `,
    controlSystem as any
  );

  const setHasChanges = useSetHasChanges();

  const update = (
    updater: (controlSystem: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
    setHasChanges(true);
  };

  return [data, update] as const;
}

export function useIsTakeoverPanelWithEcpOrDscEnabled() {
  const [data] =
    useControlSystemFragment<ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem$key>(
      graphql`
        fragment ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem on ControlSystem {
          isECP
          isDSC
        }
      `
    );

  return data.isECP || data.isDSC;
}

export function useControlSystemCapabilities() {
  const [data] =
    useControlSystemFragment<ControlSystemContextUseControlSystemCapabilities_controlSystem$key>(
      graphql`
        fragment ControlSystemContextUseControlSystemCapabilities_controlSystem on ControlSystem {
          isECP
          isDSC
          isXt30
          isXt30L
          isXt50
          isXt50L
          isXtl
          isXtlN
          isXtlW
          isXtlPlus
          isXr150
          isXr350
          isXr550
          isXr
          isXt
          isXt75
          isIComSl
          isCellComSl
          isCellComEx
          isDualCom
          isTakeoverPanel
        }
      `
    );

  return data;
}

export function useInitialConnectHasBeenEstablished() {
  const [data] =
    useControlSystemFragment<ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem$key>(
      graphql`
        fragment ControlSystemContextUseInitialConnectHasBeenEstablished_controlSystem on ControlSystem {
          initialConnectionStatus
        }
      `
    );
  return data.initialConnectionStatus === InitialConnectionStatus.COMPLETE;
}

export function useVarEnabled() {
  const [data] =
    useControlSystemFragment<ControlSystemContextUseVarEnabled_controlSystem$key>(
      graphql`
        fragment ControlSystemContextUseVarEnabled_controlSystem on ControlSystem {
          varEnabled
        }
      `
    );
  return data.varEnabled;
}
