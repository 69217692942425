/**
 * @generated SignedSource<<6768c4c762c4604e08236d4ce52ad305>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem$data = {
  readonly panel: {
    readonly areas: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly number: string;
        } | null;
      }>;
    };
  };
  readonly " $fragmentType": "TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem";
};
export type TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem$key = {
  readonly " $data"?: TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryAreaInformationProgrammingConceptFormOriginalAreas_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 32
            },
            {
              "kind": "Literal",
              "name": "sort",
              "value": {
                "keys": [
                  "number"
                ],
                "order": "ASC"
              }
            }
          ],
          "concreteType": "AreaConnection",
          "kind": "LinkedField",
          "name": "areas",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AreaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "number",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": "areas(first:32,sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "58b289473b0ddb36e428159f0a77c3dd";

export default node;
