/**
 * @generated SignedSource<<fd253f5da140dba7d67cf15600ed52be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AreaArmedStatusEnum = "ARMED" | "ARMING" | "DISARMED" | "DISARMING" | "PENDING" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ArmingSlider_area$data = {
  readonly armedStatus: AreaArmedStatusEnum;
  readonly id: string;
  readonly isArmable: boolean;
  readonly isDisarmable: boolean;
  readonly isInAlarm: boolean;
  readonly " $fragmentType": "ArmingSlider_area";
};
export type ArmingSlider_area$key = {
  readonly " $data"?: ArmingSlider_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArmingSlider_area">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArmingSlider_area",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInAlarm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArmable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDisarmable",
      "storageKey": null
    }
  ],
  "type": "Area",
  "abstractKey": "__isArea"
};

(node as any).hash = "f2b46dbeb3d2fdeccf2e7902dd928f56";

export default node;
