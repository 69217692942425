/**
 * @generated SignedSource<<36ac6f6a336a473a85af6e4f4e0652e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommPathTrouble = "ALL" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly statusListDisplay: {
    readonly auxiliary1ZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary2ZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly commPathTrouble: {
      readonly data: CommPathTrouble | null;
      readonly included: boolean | null;
    } | null;
    readonly emergencyZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly panicZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryZoneKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly systemTroubleStatusMonitors: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "22382bd62eaf6d451298f4c0bf99a267";

export default node;
