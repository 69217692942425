App.directive("daAuthorizeTempAppUser", [
  "AuthorizeTechSupportService",
  "$rootScope",
  "$modal",
  "$q",
  function (AuthorizeTechSupportService, $rootScope, $modal, $q) {
    return {
      restrict: "C",
      controller: function ($scope) {
        $scope.authorizeTempAppUser = function (systemId) {
          $scope.openAuthorizeTempAppUserModal(systemId).then(
            function () {},
            function () {}
          );
        };

        let authorizeTempAppUserModal = {};
        /**
         * Creates and opens a modal to display status messages during first connect
         **/
        $scope.openAuthorizeTempAppUserModal = function (systemId) {
          let deferred = $q.defer();
          switch ($rootScope.appProperties.type) {
            case "dealerAdmin":
              authorizeTempAppUserModal = $modal.open({
                templateUrl:
                  "app/common/templates/authorize-temp-user-modal-tpl.html",
                controller: "AuthorizeTempUserModalCtrl",
                size: "md",
                backdrop: "static",
                resolve: {
                  systemId: function () {
                    return systemId;
                  },
                },
              });

              break;
            case "techApp":
              console.warn("daTempAppUser directive does not support TechApp");
              deferred.reject();
              break;
            default:
              console.warn(
                " daAuthorizeTempApp directive does not support $rootScope.appProperties.type: " +
                  $rootScope.appProperties.type
              );
              deferred.reject();
              break;
          }
          return deferred.promise;
        };
      },
    };
  },
]);

/**
 * A controller specifically for status while attempting to retrieve programming
 */
App.controller("AuthorizeTempUserModalCtrl", [
  "$scope",
  "$modalInstance",
  "PROPS",
  "AuthorizeTechSupportService",
  "UserService",
  "$rootScope",
  "systemId",
  "ControlSystemsService",
  "controlSystemModel",
  "$q",
  "LoginService",
  "GoogleAnalyticsService",
  function (
    $scope,
    $modalInstance,
    PROPS,
    AuthorizeTechSupportService,
    UserService,
    $rootScope,
    systemId,
    ControlSystemsService,
    controlSystemModel,
    $q
  ) {
    let _this = this;

    $scope.system = {};
    $scope.UserService = UserService;

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.grantTechSupportAuthorization = function () {
      // use AuthorizeTechSupportService.authorizeTechSupport to grant tech support authorization
      AuthorizeTechSupportService.authorizeTechSupport(systemId).then(
        function (data) {
          // pop success message
          $rootScope.alerts.push({
            type: "success",
            text: "Successfully granted tech support authorization",
          });
          $scope.cancel();
          // return the data as json
          return angular.toJson(data);
        },
        function (error) {
          // pop error message
          $rootScope.alerts.push({
            type: "error",
            text: "Error granting tech support authorization",
          });
          $scope.cancel();
          // return the error as json
          return angular.toJson(error);
        }
      );
    };

    function init() {
      getDesiredSystem().then(function (systemId) {
        $scope.systemId = systemId;
      });
    }

    function getDesiredSystem() {
      let deferred = $q.defer();
      if (+systemId > 0) {
        let system = new controlSystemModel({
          customer_id: UserService.customer_id,
          control_system_id: +systemId,
        });
        system.get().then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "TempUserModalCtrl->getDesiredSystem() error: " +
                angular.toJson(error)
            );
            deferred.reject();
          }
        );
      } else {
        deferred.resolve(ControlSystemsService.currentControlSystem);
      }
      return deferred.promise;
    }

    init();
  },
]);
