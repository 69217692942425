App.controller("IntegrationSettingsCtrl", [
  "$scope",
  "$rootScope",
  "$q",
  "$filter",
  "$state",
  "$modal",
  "CompanyStoreService",
  "UserService",
  "IntegrationService",
  function (
    $scope,
    $rootScope,
    $q,
    $filter,
    $state,
    $modal,
    CompanyStoreService,
    UserService,
    IntegrationService
  ) {
    $scope.dealerIntegrationInfo = undefined;
    $scope.integrationMappingParts = undefined;
    $scope.salesAppItems = undefined;
    $scope.salesAppCustomParts = undefined;
    $scope.salesAppCategories = undefined;
    $scope.dealer_id = UserService.dealer_id;
    $scope.state = {};
    $scope.sedona = {};
    $scope.forms = {};
    $scope.triedToSave = false;
    $scope.showItemsTab = false;
    $scope.testingSedonaConnection = false;
    $scope.integrationSettings = {
      sedonaEnabled: UserService.dealerInfo.sedonaIntegrationEnabled,
      sedonaChanged: false,
    };

    var salesAppPartsLoaded = false;
    var dealerIntegrationLoaded = false;
    var sedonaDataLoaded = false;
    var salesAppExcludedCategories = ["Packages"];
    var pristineItems = undefined;

    // Only bother loading the page if the user should be here
    if (UserService.isAdmin() || $scope.integrationSettings.sedonaEnabled) {
      init();
    }

    function init() {
      // Determine our controller state
      $scope.isBusy = true;
      $scope.state.isEditing = $state.is("app.dealer.integration_settings_edit")
        ? true
        : false;

      // Get all of the Sales App items for the dealer
      getSalesAppItems();
      getDealerIntegration()
        .then(function () {
          getSedonaData();
          $scope.showItemsTab =
            $scope.dealerIntegrationInfo &&
            $scope.dealerIntegrationInfo.SedonaApiAuthToken &&
            $scope.dealerIntegrationInfo.SedonaApiUrl;
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function getDealerIntegration() {
      var deferred = $q.defer();

      // Get the dealer integration preferences for Sedona
      IntegrationService.getDealerIntegrationPreferences(
        $scope.dealer_id,
        "sedona"
      )
        .then(
          function (data) {
            // Successfully retrieved dealer integration information
            $scope.dealerIntegrationInfo = data;
            dealerIntegrationLoaded = true;
            deferred.resolve();
          },
          function (error) {
            // Error getting Sedona parts
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred when retrieving integration parts.",
              json: error,
            });
            $scope.isBusy = false;
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    }

    function getSedonaData() {
      // If the user hasn't specified both a Sedona URL and Sedona Auth Token, don't bother trying to get data
      if (
        !$scope.dealerIntegrationInfo ||
        !$scope.dealerIntegrationInfo.SedonaApiAuthToken ||
        !$scope.dealerIntegrationInfo.SedonaApiUrl
      ) {
        // We mark these as loaded to notify the busy spinner that all data has loaded, even though there's nothing to get
        sedonaDataLoaded = true;
        return true;
      }

      var id = $scope.dealerIntegrationInfo.IntegrationId;
      var promises = [
        IntegrationService.getSedonaCustomerTypes(id),
        IntegrationService.getSedonaTermCodes(id),
        IntegrationService.getSedonaBranches(id),
        IntegrationService.getSedonaSystemTypes(id),
        IntegrationService.getSedonaParts(id),
      ];

      $q.all(promises)
        .then(
          function (data) {
            // Successfully retrieved all item data
            $scope.sedona.customerTypes = data[0];
            $scope.sedona.termCodes = data[1];
            $scope.sedona.branchCodes = data[2];
            $scope.sedona.systemTypes = data[3];
            $scope.sedona.integrationParts = data[4];
            sedonaDataLoaded = true;
            onAllDataLoaded();
          },
          function (error) {
            // Error getting Sedona data
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred while retrieving Sedona data.",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getSalesAppItems() {
      var promises = [
        CompanyStoreService.getSalesAppItems($scope.dealer_id),
        CompanyStoreService.getSalesappCustomItems($scope.dealer_id),
      ];

      $q.all(promises)
        .then(
          function (data) {
            // Successfully retrieved all item data
            $scope.salesAppItems = data[0].items;
            $scope.salesAppCustomParts = data[1].custom_items;

            // Loop through the salesAppItem packages and pull out unique items
            var packageItems = [];
            $scope.salesAppItems.forEach(function (item) {
              if (item.category == "Packages" && item.available == true) {
                if (item.package_item_details) {
                  item.package_item_details.forEach(function (packageItem) {
                    if (
                      !$filter("filter")(packageItems, {
                        part_number: packageItem.part_number,
                      })[0] &&
                      !$filter("filter")($scope.salesAppItems, {
                        part_number: packageItem.part_number,
                      })[0]
                    ) {
                      packageItem.available = true;
                      packageItem.category = "Package Items";
                      packageItems.push(packageItem);
                    }
                  });
                }
              }
            });

            // Add the custom items to the list of sales app items.
            // We need a part_number to create the integration mapping. Currently we're using the custom item title
            $scope.salesAppCustomParts.forEach(function (item) {
              if (
                item.application === "sales_app" &&
                item.billing_type === "once"
              ) {
                item.part_number = item.title;
                item.category = "Custom Parts";
                $scope.salesAppItems.push(item);
              }
            });

            // Remove any packages from the items array
            $scope.salesAppItems = $filter("filter")(
              $scope.salesAppItems,
              function (item) {
                return item.category !== "Packages";
              }
            );

            // Add the package items into the items array
            $scope.salesAppItems = $scope.salesAppItems.concat(packageItems);

            salesAppPartsLoaded = true;
            onAllDataLoaded();
          },
          function (error) {
            // Error getting Sales App items
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred when retrieving Secura parts.",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * This function waits until data from the Integration API and the Sales APP API have been loaded
     * before merging the data together.
     */
    function onAllDataLoaded() {
      if (
        !dealerIntegrationLoaded ||
        !salesAppPartsLoaded ||
        !sedonaDataLoaded
      ) {
        return;
      }

      // If we don't have dealerIntegrationInfo, there's no sense in continuing.
      if (!$scope.dealerIntegrationInfo) {
        $scope.isBusy = false;
        return;
      }

      IntegrationService.getIntegrationParts(
        $scope.dealerIntegrationInfo.IntegrationId,
        "IntegrationId"
      )
        .then(
          function (parts) {
            $scope.integrationMappingParts = parts;
            parseMappings();
            createPristineCopy();
            $scope.isBusy = false;
          },
          function (error) {
            // Error getting Integration part mappings
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred when retrieving integration part mappings.",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function parseMappings() {
      $scope.salesAppItems.forEach(function (item) {
        var mappingItem = $filter("filter")($scope.integrationMappingParts, {
          PartNumber: item.part_number,
        })[0];
        if (mappingItem) {
          item.integrationPart = mappingItem.IntegrationPartNumber;
          item.integrationPartData = mappingItem;
        }
      });
    }

    function createPristineCopy() {
      pristineItems = angular.copy($scope.salesAppItems);
    }

    function getModifiedStoreItems() {
      var modifiedIntegrationParts = [];
      if (!Array.isArray(pristineItems)) {
        return modifiedIntegrationParts;
      }

      var modifiedItems = $filter("filter")(
        $scope.salesAppItems,
        function (item, index) {
          if (!angular.equals(item, pristineItems[index])) {
            return true;
          }
        }
      );

      // for each modified item, get the integration part information
      modifiedItems.forEach(function (item) {
        if (item.integrationPartData === undefined) {
          item.integrationPartData = IntegrationService.buildIntegrationPart(
            $scope.dealerIntegrationInfo.IntegrationId,
            item.part_number,
            item.integrationPart
          );
        }

        item.integrationPartData.IntegrationPartNumber = item.integrationPart;
        modifiedIntegrationParts.push(item.integrationPartData);
      });

      return modifiedIntegrationParts;
    }

    $scope.testSedonaSettings = function () {
      if (!$scope.dealerIntegrationInfo) {
        $rootScope.alerts.push({
          type: "error",
          text: "Error Loading Integration Settings.",
        });
        return false;
      }

      $scope.testingSedonaConnection = true;
      IntegrationService.getSedonaVersion(
        $scope.dealerIntegrationInfo.SedonaApiUrl,
        $scope.dealerIntegrationInfo.SedonaApiAuthToken
      )
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Sedona Connection Successful!",
            });
            $scope.testingSedonaConnection = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Connecting to Sedona. Please Check Your URL and Credentials.",
              json: error,
            });
            $scope.testingSedonaConnection = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.sendIntegrationChanges = function () {
      $scope.isBusy = true;
      var updatedItems = getModifiedStoreItems();

      var promises = [];

      if ($scope.dealerIntegrationInfo) {
        promises.push(
          IntegrationService.updateIntegrationPreferences(
            $scope.dealerIntegrationInfo.IntegrationId,
            $scope.dealerIntegrationInfo
          )
        );
        promises.push(
          IntegrationService.updateIntegrationParts(
            $scope.dealerIntegrationInfo.IntegrationId,
            updatedItems
          )
        );
      }

      // Check to see if any integrations were enabled or disabled
      if ($scope.integrationSettings.sedonaChanged) {
        promises.push(
          IntegrationService.createOrUpdateDealerIntegration(
            $scope.dealer_id,
            "sedona",
            $scope.integrationSettings.sedonaEnabled
          )
        );
      }

      $q.all(promises)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Integration Settings Saved.",
            });
            $scope.triedToSave = false;
            $scope.isBusy = false;
            // Update our integrations info on our dealer object
            UserService.dealerInfo
              .getDealerIntegrations($scope.dealer_id)
              .then(
                function () {
                  $state.go(
                    "app.dealer.integration_settings_view",
                    { dealer_id: $scope.dealer_id },
                    { reload: true }
                  );
                },
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error Updating Dealer Integration Info.",
                    json: error,
                  });
                  $scope.triedToSave = true;
                  $scope.isBusy = false;
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error Saving Integration Settings",
              json: error,
            });
            $scope.triedToSave = true;
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.validateIntegrationParts = function (form, item, fieldIndex) {
      // Check to see if we have a valid part number
      if (
        $filter("filter")(
          $scope.sedona.integrationParts,
          { PartCode: item.integrationPart },
          true
        )[0]
      ) {
        form["part_" + fieldIndex].$setValidity("invalidPartCode", true);
      } else {
        form["part_" + fieldIndex].$setValidity("invalidPartCode", false);
      }
    };

    /**
     * Creates and opens the change credentials modal
     */
    $scope.openChangeCredentialsModal = function () {
      $modal.open({
        templateUrl: "changeCredentialsModal.html",
        controller: "ChangeCredentialsModalCtrl",
        windowClass: "",
        size: "md",
        backdrop: true,
        scope: $scope,
      });
    };
  },
]);

/**
 * A controller for the change credentials modal
 */
App.controller("ChangeCredentialsModalCtrl", [
  "$scope",
  "$rootScope",
  "$modalInstance",
  "$state",
  "UserService",
  "focus",
  "$timeout",
  function (
    $scope,
    $rootScope,
    $modalInstance,
    $state,
    UserService,
    focus,
    $timeout
  ) {
    $scope.credentials = {};

    //Set focus on the user field after a 500ms delay
    $timeout(function () {
      focus("user");
    }, 500);

    $scope.cancel = function () {
      //Close the dialog and clear out any local data
      $modalInstance.dismiss("cancel");
      $scope.credentials = {};
    };

    //Create a new Sedona auth token from the supplied credentials
    $scope.updateCredentials = function () {
      var authString =
        $scope.credentials.user + ":" + $scope.credentials.password;
      $scope.dealerIntegrationInfo.SedonaApiAuthToken = btoa(authString);
      $rootScope.alerts.push({
        type: "success",
        text: "Sedona Auth Token Updated.",
      });
      $modalInstance.dismiss();
    };
  },
]);
