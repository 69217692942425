import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationNameField_deviceInformation$key } from "./__generated__/DeviceInformationNameField_deviceInformation.graphql";

export const deviceInformationNameFieldId = (number: string) =>
  `device-information-name-${number}`;

function DeviceInformationNameField() {
  const [{ number, name }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationNameField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationNameField_deviceInformation on DeviceInformation {
          number
          name
        }
      `
    );

  const fieldId = deviceInformationNameFieldId(String(number));
  const { isXr, isXf } = resolvePanelType(useHardwareModel());
  const disabled = false;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Device Name"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        required
        value={`${name}`}
        disabled={disabled}
        maxLength={isXr || isXf ? 32 : 16}
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(target.value, "name");
          });
        }}
        onBlur={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(target.value.trim().toUpperCase(), "name");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationNameField;
