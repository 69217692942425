App.factory("ZWaveService", [
  "$q",
  "ZWaveAPI",
  "JobService",
  function ($q, ZWaveAPI, JobService) {
    return {
      status: undefined,

      stage: undefined,

      jobData: undefined,

      details: undefined,

      init: function () {
        this.status = undefined;
        this.stage = undefined;
        this.jobData = undefined;
        this.details = undefined;
      },
      /**
       * Get the devices, return them in a promise
       */
      getDevices: function (panelId, pageDefer, pageNum, pageData) {
        var deferred = pageDefer !== undefined ? pageDefer : $q.defer();
        pageNum = pageNum ? pageNum : 1;
        pageData = pageData !== undefined ? pageData : [];

        var _this = this;

        ZWaveAPI.indexZwave(
          { panel_id: panelId, page: pageNum },
          function (data) {
            if (data != "") {
              // if success returns empty string we've reached the last page
              pageData = pageData.concat(data); // add this page's data on to the end of the data array
              _this.getDevices(panelId, deferred, ++pageNum, pageData).then(
                // call get devices again passing in the promise, the data, and the next page
                function () {
                  deferred.resolve();
                },
                function (error) {
                  //If an error occurs, bail.
                  deferred.reject(error);
                }
              );
            } else {
              // last page - resolve all of the data
              deferred.resolve(pageData);
            }
          },
          function (error) {
            //no return: throw error
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },
      refreshDevices: function (panelId) {
        var deferred = $q.defer();
        var _this = this;
        this.init();
        ZWaveAPI.refreshZwave(
          { panel_id: panelId },
          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  deferred.reject(jobError);
                },
                function (notify) {
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      addDevice: function (panelId, deviceName) {
        var deferred = $q.defer();
        var _this = this;
        this.init();
        var device = new ZWaveAPI();
        var zwave_setup = {};
        if (typeof deviceName === "undefined" || !deviceName) deferred.reject();
        zwave_setup.name = deviceName;
        device.zwave_setup = zwave_setup;
        device.$save(
          { panel_id: panelId },
          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  _this.status = jobData.job.status;
                  _this.details = jobData.job.details;
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  _this.status = jobError.status;
                  _this.details = jobError.details;
                  deferred.reject(jobError);
                },
                function (notify) {
                  _this.status = notify.status;
                  _this.details = notify.details;
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      removeDevice: function (panelId) {
        var deferred = $q.defer();
        var _this = this;
        var device = new ZWaveAPI();
        device.$delete(
          { panel_id: panelId },
          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  _this.status = jobData.job.status;
                  _this.details = jobData.job.details;
                  //_this.details = jobData.details;
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  _this.status = jobError.status;
                  _this.details = jobError.details;
                  deferred.reject(jobError);
                },
                function (notify) {
                  _this.status = notify.status;
                  _this.details = notify.details;
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      forceRemoveDevice: function (panelId, deviceId) {
        var deferred = $q.defer();
        var _this = this;
        var device = new ZWaveAPI();
        device.$delete(
          { panel_id: panelId, device_id: deviceId },

          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  deferred.reject(jobError);
                },
                function (notify) {
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      renameDevice: function (panelId, deviceId, deviceName) {
        var deferred = $q.defer();
        var _this = this;
        var zwave_device = {};
        zwave_device.zwave_setup = {};
        if (typeof deviceName === "undefined" || !deviceName) deferred.reject();
        zwave_device.zwave_setup.name = deviceName;
        ZWaveAPI.updateZwave(
          { panel_id: panelId, device_id: deviceId },
          zwave_device,
          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  _this.status = jobData.status;
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  _this.status = jobError.status;
                  deferred.reject(jobError);
                },
                function (notify) {
                  _this.status = notify.status;
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
