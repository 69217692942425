import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  KeyfobButtonType,
  KeyfobNumberOfButtons,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { getKeyfobButtonDefaults } from "./utils";
import { KeyfobNumberOfButtonsField_keyfob$key } from "./__generated__/KeyfobNumberOfButtonsField_keyfob.graphql";

export const keyfobNumberOfButtonsFieldId = (number: string) =>
  `keyfob-number-of-buttons-${number}`;

function KeyfobNumberOfButtonsField() {
  const [{ id, number, numberOfButtons }, updateKeyfob] =
    useKeyfobFragment<KeyfobNumberOfButtonsField_keyfob$key>(
      graphql`
        fragment KeyfobNumberOfButtonsField_keyfob on Keyfob {
          id
          number
          numberOfButtons
        }
      `
    );

  const fieldId = keyfobNumberOfButtonsFieldId(String(number));
  const relayEnv = useRelayEnvironment();
  const defaultOneButtonValue = getKeyfobButtonDefaults(
    KeyfobButtonType.PANIC,
    "One"
  );
  const defaultTwoButtonValue = {
    ...getKeyfobButtonDefaults(KeyfobButtonType.ARMING, "One"),
    ...getKeyfobButtonDefaults(KeyfobButtonType.DISARMING, "Four"),
  };
  const defaultFourButtonValue = {
    ...getKeyfobButtonDefaults(KeyfobButtonType.ARMING, "One"),
    ...getKeyfobButtonDefaults(KeyfobButtonType.PANIC, "Two"),
    ...getKeyfobButtonDefaults(KeyfobButtonType.ARMING, "Three"),
    buttonThreeAreas: "1",
    ...getKeyfobButtonDefaults(KeyfobButtonType.DISARMING, "Four"),
  };

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Number of Buttons">
      <Select
        id={fieldId}
        value={numberOfButtons}
        required
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(target.value, "numberOfButtons");
          });
          relayEnv.commitUpdate((store) => {
            const keyfobRecord = store.get(id);
            if (keyfobRecord) {
              switch (target.value) {
                case KeyfobNumberOfButtons.ONE: {
                  keyfobRecord.setValue(
                    defaultOneButtonValue.buttonOnePressTime,
                    "buttonOnePressTime"
                  );
                  keyfobRecord.setValue(
                    defaultOneButtonValue.buttonOneAreas,
                    "buttonOneAreas"
                  );
                  keyfobRecord.setValue(
                    defaultOneButtonValue.buttonOneAction,
                    "buttonOneAction"
                  );
                  keyfobRecord.setValue(
                    defaultOneButtonValue.buttonOneOutput,
                    "buttonOneOutput"
                  );
                  keyfobRecord.setValue(
                    defaultOneButtonValue.buttonOneOutputAction,
                    "buttonOneOutputAction"
                  );
                  break;
                }
                case KeyfobNumberOfButtons.TWO: {
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonOnePressTime,
                    "buttonOnePressTime"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonOneAreas,
                    "buttonOneAreas"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonOneAction,
                    "buttonOneAction"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonOneOutput,
                    "buttonOneOutput"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonOneOutputAction,
                    "buttonOneOutputAction"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonFourPressTime,
                    "buttonFourPressTime"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonFourAreas,
                    "buttonFourAreas"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonFourAction,
                    "buttonFourAction"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonFourOutput,
                    "buttonFourOutput"
                  );
                  keyfobRecord.setValue(
                    defaultTwoButtonValue.buttonFourOutputAction,
                    "buttonFourOutputAction"
                  );
                  break;
                }
                case KeyfobNumberOfButtons.FOUR:
                default: {
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonOnePressTime,
                    "buttonOnePressTime"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonOneAreas,
                    "buttonOneAreas"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonOneAction,
                    "buttonOneAction"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonOneOutput,
                    "buttonOneOutput"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonOneOutputAction,
                    "buttonOneOutputAction"
                  );

                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonTwoPressTime,
                    "buttonTwoPressTime"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonTwoAreas,
                    "buttonTwoAreas"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonTwoAction,
                    "buttonTwoAction"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonTwoOutput,
                    "buttonTwoOutput"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonTwoOutputAction,
                    "buttonTwoOutputAction"
                  );

                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonThreePressTime,
                    "buttonThreePressTime"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonThreeAreas,
                    "buttonThreeAreas"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonThreeAction,
                    "buttonThreeAction"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonThreeOutput,
                    "buttonThreeOutput"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonThreeOutputAction,
                    "buttonThreeOutputAction"
                  );

                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonFourPressTime,
                    "buttonFourPressTime"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonFourAreas,
                    "buttonFourAreas"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonFourAction,
                    "buttonFourAction"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonFourOutput,
                    "buttonFourOutput"
                  );
                  keyfobRecord.setValue(
                    defaultFourButtonValue.buttonFourOutputAction,
                    "buttonFourOutputAction"
                  );
                  break;
                }
              }
            }
          });
        }}
      >
        <Select.Option value={""} disabled>
          Please Select a Value
        </Select.Option>
        <Select.Option value={KeyfobNumberOfButtons.ONE}>1</Select.Option>
        <Select.Option value={KeyfobNumberOfButtons.TWO}>2</Select.Option>
        <Select.Option value={KeyfobNumberOfButtons.FOUR}>4</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobNumberOfButtonsField;
