/**
 * @generated SignedSource<<32658faa4a29c93830d68a4c759b7a16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputInformationNumberField_panel$data = {
  readonly outputInformations: ReadonlyArray<{
    readonly number: number;
  }>;
  readonly " $fragmentType": "OutputInformationNumberField_panel";
};
export type OutputInformationNumberField_panel$key = {
  readonly " $data"?: OutputInformationNumberField_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationNumberField_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputInformationNumberField_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Output",
      "kind": "LinkedField",
      "name": "outputInformations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "dd37985dbb87762a5065ccf41b591cab";

export default node;
