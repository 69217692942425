import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment, useMutation } from "react-relay/hooks";
import { Sheet } from "../Layout/Layout";
import VideoSelection from "./VideoForm/Components/VideoSelection";
import SecureComDoorbells from "./VideoForm/SecureComDoorbells";
import { SecureComDoorbellForm_site$key } from "./__generated__/SecureComDoorbellForm_site.graphql";

export default function SecureComDoorbellForm({
  siteRef,
}: {
  siteRef: SecureComDoorbellForm_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment SecureComDoorbellForm_site on Site {
        id
        hikvisionDoorbellTotal
        ...SecureComDoorbells_site
      }
    `,
    siteRef
  );

  const [setDoorbellTotal] = useMutation(graphql`
    mutation SecureComDoorbellFormSetTotalMutation($siteId: ID!, $total: Int!) {
      setSecureComDoorbellTotalForSite(siteId: $siteId, total: $total) {
        ... on Site {
          ...SecureComDoorbellForm_site
        }
      }
    }
  `);

  return (
    <Sheet>
      <VideoSelection>
        <VideoSelection.Description title={<>Video Doorbell</>} />

        <div className="feature__read-only-data drop-in-and-expand-ngif">
          <div className="form-inline">
            <label className="tiny-label mar-r-8">Number of Doorbells</label>
            <select
              className="form-control input-sm"
              value={data.hikvisionDoorbellTotal}
              onChange={({ target }) => {
                const total = Number(target.value);
                setDoorbellTotal({
                  variables: {
                    siteId: data.id,
                    total,
                  },
                  optimisticUpdater: (store) => {
                    const siteRecord = store.get(data.id);
                    if (siteRecord) {
                      siteRecord.setValue(total, "hikvisionDoorbellTotal");
                    }
                  },
                  // TODO: Handle edge case error
                });
              }}
            >
              <option value="1">1 Doorbell</option>
              <option value="2">2 Doorbells</option>
              <option value="3">3 Doorbells</option>
              <option value="4">4 Doorbells</option>
              <option value="5">5 Doorbells</option>
              <option value="6">6 Doorbells</option>
              <option value="7">7 Doorbells</option>
              <option value="8">8 Doorbells</option>
            </select>
          </div>
        </div>
      </VideoSelection>

      <VideoSelection.Section>
        <SecureComDoorbells siteRef={data} />
      </VideoSelection.Section>
    </Sheet>
  );
}
