import { EventEmitter } from "events";
import { fromSiteId } from "securecom-graphql/client";
import { HikDoorbellUtil } from "./cs-hik-doorbell-util";

App.controller("CSVideoDevicesCtrl", [
  "$scope",
  "$window",
  "$modal",
  "PROPS",
  "VideoDeviceService",
  "ControlSystemsService",
  "UserService",
  "$log",
  "ThirdPartyVideoService",
  "JobService",
  "$rootScope",
  "$q",
  "PersonUsersService",
  "PricingAPI",
  function (
    $scope,
    $window,
    $modal,
    PROPS,
    VideoDeviceService,
    ControlSystemsService,
    UserService,
    $log,
    ThirdPartyVideoService,
    JobService,
    $rootScope,
    $q,
    PersonUsersService,
    PricingAPI
  ) {
    $scope.camerasBusy = true;
    $scope.nvrsBusy = true;
    $scope.VideoDeviceService = VideoDeviceService;
    $scope.UserService = UserService;
    $scope.refreshVideoDevices = refreshVideoDevices;
    $scope.refreshDeviceSettings = refreshDeviceSettings;
    $scope.videoDeviceServiceIsBusy = VideoDeviceService.isBusy;
    $scope.reactivateVideoDevice = reactivateVideoDevice;
    $scope.addCamera = addCamera;
    $scope.openNvrModal = openNvrModal;
    $scope.openCameraModal = openCameraModal;
    $scope.controlSystem = ControlSystemsService.currentControlSystem;
    $scope.nvrModal;
    $scope.cameraModal;
    $scope.hikDoorbells = new HikDoorbellUtil($log, ThirdPartyVideoService);
    $scope.apUiRefreshId = new Date().getTime(); // Update this to reload the ap-ui micro frontend

    PricingAPI.getPricing(
      { dealer_id: UserService.dealer_id },
      function (pricingInfo) {
        $scope.pricing = angular.copy(pricingInfo);
      },
      function (error) {}
    );

    // Function to check the browser width and set the appropriate flag
    function checkBrowserWidth() {
      $scope.isSmallScreen = $window.innerWidth < 768; // Example width for small screens
    }

    // Listen to the 'resize' event to update the flag when the browser is resized
    angular.element($window).on("resize", function () {
      $scope.$apply(function () {
        checkBrowserWidth();
      });
    });

    refreshVideoDevices();
    // Call the function on controller initialization
    checkBrowserWidth();

    async function refreshVideoDevices() {
      $scope.videoDeviceServiceIsBusy = true;
      await getVideoDevices();
      await getHikVisionDevices();
      await getOpenEyeDevices();
      await getEagleEyeDevices();
      await getHikDoorbell();
      await getDWConnectionInfo();
      await getHanwhaDevices();
      $scope.videoDeviceServiceIsBusy = false;
      $scope.apUiRefreshId = new Date().getTime();
    }

    function getVideoDevices() {
      VideoDeviceService.isBusy = true;
      VideoDeviceService.getVideoDevices(true)
        .then(
          function (videoDevices) {
            $scope.cameras = videoDevices.cameras; // Separate the cameras out from the returned video devices
            $scope.nvrList = videoDevices.nvrs; // Separate the NVRs from the returned video devices
            $scope.dvrList = videoDevices.dvrs; // Separate the DVRs from the returned video devices
            VideoDeviceService.isBusy = false;
          },
          function (error) {
            VideoDeviceService.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getHikVisionDevices() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "hikvision",
        UserService.control_system_id,
        "NVR"
      )
        .then(
          function (data) {
            $scope.hikVisionDevices = data;
          },
          function (error) {
            console.error(
              "Error retrieving HikVision video devices: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function getPersonUserName(device) {
      PersonUsersService.getByPersonId(device.person_id)
        .then(
          function (person) {
            device.person_email = person[0].person_email_address;
          },
          function (error) {}
        )
        .catch(function (error) {});
    }

    function getOpenEyeDeviceUserNames() {
      for (let device of $scope.openEyeDevices) {
        getPersonUserName(device);
      }
    }
    function getHanwhaDeviceUserNames() {
      for (let device of $scope.hanwhaDevices) {
        getPersonUserName(device);
      }
    }
    function getEagleEyeDeviceUserNames() {
      for (let device of $scope.eagleEyeDevices) {
        getPersonUserName(device);
      }
    }

    function getOpenEyeDevices() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "openeye",
        UserService.control_system_id,
        "DVR"
      )
        .then(
          function (data) {
            $scope.openEyeDevices = data;
            getOpenEyeDeviceUserNames();
          },
          function (error) {
            console.error(
              "Error retrieving OpenEye video devices: " + angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }
    function getHanwhaDevices() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "hanwha",
        UserService.control_system_id,
        "server"
      )
        .then(
          function (data) {
            $scope.hanwhaDevices = data;
            getHanwhaDeviceUserNames();
          },
          function (error) {
            console.error(
              "Error retrieving Hanwha video devices: " + angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }
    function getEagleEyeDevices() {
      ThirdPartyVideoService.getDevicesByManufacturer(
        "eagle_eye",
        UserService.control_system_id
      )
        .then(
          function (data) {
            $scope.eagleEyeDevices = data;
            getEagleEyeDeviceUserNames();
          },
          function (error) {
            console.error(
              "Error retrieving Eagle Eye video devices: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }
    function getHikDoorbell() {
      $scope.hikDoorbells
        .refreshDeviceList($scope.controlSystemId)
        // For each device, check if an update is in progress or if an update is available
        .then((data) => {
          data.forEach((doorbell) => {
            $scope.hikDoorbells.checkForUpdates(doorbell);
          });
        })
        .then((data) => {
          for (let i = 0; i < data.length; ++i) {
            $scope.hikDoorbells.monitorUpdate(data[i]);
          }
        })
        .catch((error) => {});
    }

    function getDWConnectionInfo() {
      ThirdPartyVideoService.getVideoConnectionInformation(
        $scope.controlSystemId,
        "DIGITAL_WATCHDOG"
      ).then(
        function (data) {
          $scope.dwCloud = false;
          $scope.dw_ip_add = data.host;
          $scope.dw_port = data.management_port;
        },
        function (error) {
          $scope.dwCloud = true;
          $scope.DWDelete = false;
        }
      );
    }

    function refreshDeviceSettings(videoDevice) {
      VideoDeviceService.isBusy = true;
      videoDevice
        .refresh()
        .then(function (data) {
          JobService.process(data.job.uuid)
            .then(
              function (data) {
                $rootScope.alerts.push({
                  type: "success",
                  text: "Device settings retrieved.",
                });
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Unable to retrieve device settings.",
                });
              }
            )
            .catch(function (error) {
              console.error(error);
            })
            .finally(function () {
              VideoDeviceService.isBusy = false;
              getVideoDevices();
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function reactivateVideoDevice(videoDevice) {
      videoDevice
        .reactivate()
        .then(
          function (data) {
            $rootScope.alerts.push({
              type: "success",
              text: "Reactivating " + videoDevice.device_type,
            });
            $scope.getVideoDevices();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function addCamera() {
      var uri =
        PROPS.apiUrl +
        "/control_systems/" +
        UserService.control_system_id +
        "/cameras/new";
      $window.location.href = encodeURI(uri);
    }

    function openNvrModal() {
      $scope.nvrModal = $modal.open({
        templateUrl: "nvrModalContent.html",
        controller: "VideoDeviceModalCtrl",
        windowClass: "nvr-modal-content",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });

      function reactivateVideoDevice(videoDevice) {
        videoDevice
          .reactivate()
          .then(
            function (data) {
              $rootScope.alerts.push({
                type: "success",
                text: "Reactivating " + videoDevice.device_type,
              });
              $scope.getVideoDevices();
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      $scope.nvrModal.result
        .then(
          function (result) {},
          function () {}
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function openCameraModal() {
      $scope.cameraModal = $modal.open({
        templateUrl: "cameraModalContent.html",
        controller: "VideoDeviceModalCtrl",
        windowClass: "camera-modal-content",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });

      getThirdPartyDevices();
      function getThirdPartyDevices() {
        ThirdPartyVideoService.getDevices($scope.controlSystemId)
          .then(
            function (data) {
              $scope.thirdPartyDevices = data;
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error getting Third Party Devices",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      $scope.nvrModal.result
        .then(
          function (result) {},
          function () {}
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Shows a confirmation popup to allow the user to confirm they want to start
     * the firmware update.
     * @param {*} device The device to update.
     */
    $scope.updateDoorbell = function (device) {
      // Setup modal
      var modal = $modal.open({
        templateUrl:
          "app/control_system/video_devices/hik-doorbell-update-modal.html",
        controller: "HikDoorbellUpdateModalCtrl",
        windowClass: "",
        size: "sm",
        backdrop: true,
        scope: $scope,
      });

      // Show modal
      modal.result.then(
        () => {
          // User confirmed; start update
          $scope.hikDoorbells.startUpdate(device);
        },
        () => {
          /* User canceled, do nothing */
        }
      );
    };

    $scope.deleteDoorbell = function (device) {
      let devIndex = 0;
      devIndex = $scope.hikDoorbells.devices.indexOf(device);

      if (device.device_type === "HIKVISION-DOORBELL") {
        ThirdPartyVideoService.hikvisionDoorbells
          .destroy(device.id, device.control_system_id)
          .then(
            function (data) {
              // This will remove it from the display array.
              $scope.hikDoorbells.devices.splice(devIndex, 1);
              $rootScope.alerts.push({
                type: "success",
                text: "Doorbell was deleted",
              });
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Doorbell was not deleted",
              });
            }
          );
      } else {
        ThirdPartyVideoService.destroyDevice(device.id).then(
          function (data) {
            // This will remove it from the display array
            $scope.hikDoorbells.devices.splice(devIndex, 1);
            $rootScope.alerts.push({
              type: "success",
              text: "Doorbell was deleted",
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Doorbell was not deleted",
            });
          }
        );
      }

      $scope.deleteDoorbell = function (device) {
        devIndex = $scope.hikDoorbell.indexOf(device);

        if (device.device_type === "HIKVISION-DOORBELL") {
          ThirdPartyVideoService.hikvisionDoorbells
            .destroy(device.id, device.control_system_id)
            .then(
              function (data) {
                // This will remove it from the display array.
                $scope.hikDoorbell.splice(devIndex, 1);
                $rootScope.alerts.push({
                  type: "success",
                  text: "Doorbell was deleted",
                });
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Doorbell was not deleted",
                });
              }
            );
        } else {
          ThirdPartyVideoService.destroyDevice(device.id).then(
            function (data) {
              // This will remove it from the display array
              $scope.hikDoorbell.splice(devIndex, 1);
              $rootScope.alerts.push({
                type: "success",
                text: "Doorbell was deleted",
              });
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Doorbell was not deleted",
              });
            }
          );
        }
      };
    };
  },
]);

App.controller("VideoDeviceModalCtrl", [
  "$scope",
  "$modalInstance",
  "$state",
  "UserService",
  "VideoDevice",
  "VideoDeviceService",
  "$timeout",
  "$rootScope",
  "RelayService",
  function (
    $scope,
    $modalInstance,
    $state,
    UserService,
    VideoDevice,
    VideoDeviceService,
    $timeout,
    $rootScope,
    RelayService
  ) {
    $scope.searchState = "idle"; // This variable is used to control displaying the progress indicator on the modal
    $scope.searchError = "Device Offline"; // Generic error message used if we don't get a specific one back from the server
    $scope.videoDevice = false;
    $scope.searchAvailable = searchAvailable;
    $scope.addVideoDevice = addVideoDevice;
    $scope.cancel = cancel;
    $scope.eventEmitter = new EventEmitter();
    $scope.userServiceHasSite = !!UserService.site;
    if ($scope.userServiceHasSite) {
      const siteInfo = fromSiteId(UserService.site.id);
      $scope.siteIdFromUserService = siteInfo.siteId;
      $scope.customerIdFromUserService = siteInfo.customerId;
    }

    function searchAvailable(deviceType, identifier) {
      identifier = identifier.replace(/:/g, "");
      $scope.searchState = "searching";
      VideoDeviceService.searchAvailable(identifier)
        .then(
          function (videoDevice) {
            // Make sure that the device we found matches the device type that we want
            var connection = videoDevice.getConnectionStatus();
            if (
              deviceType.indexOf(videoDevice.device_type) != -1 &&
              connection.connected
            ) {
              $scope.videoDevice = videoDevice;
              $scope.searchState = "idle";
              if (videoDevice.device_type == "camera") {
                $scope.addVideoDevice();
              } // else, it was an NVR, no more work to be done yet.
            } else {
              // We found a device, but it isn't the video device type that we want or the device cannot connect
              $scope.searchState = "error";
              if (!connection.connected) {
                $scope.searchError = connection.message;
              } else {
                $scope.searchError = "Unsupported Device Type";
              }
            }
          },
          function (error) {
            $scope.searchState = "error";
            if (error.hasOwnProperty("statusText")) {
              // Display the specific error
              $scope.searchError = error.statusText;
            } else {
              // Display a generic error12345
              $scope.searchError = "Device Offline";
            }
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function addVideoDevice() {
      var deviceType = $scope.videoDevice.device_type;
      if (deviceType === "dvr") {
        deviceType = "nvr";
      } // DVRs use the same edit page as NVR
      // Save the video device then, upon success, redirect to the appropriate edit page
      $scope.isSaving = true;
      VideoDeviceService.save($scope.videoDevice)
        .then(
          function (videoDevice) {
            $timeout(function () {
              $scope.isSaving = false;
              // Video Device Saved successfully, go to the edit page
              if ($scope.userServiceHasSite) {
                const app =
                  $scope.videoDevice.manufacturer === "Uniview"
                    ? "app.uniview-camera-edit-sites"
                    : `app.edit-${deviceType}-sites`;
                $state.go(app, {
                  customer_id: $scope.customerIdFromUserService,
                  site_id: $scope.siteIdFromUserService,
                  device_id: $scope.videoDevice.id,
                  channel_id: videoDevice.video_device.channels[0]?.id,
                  force_send_tz: true,
                });
              } else {
                const app =
                  $scope.videoDevice.manufacturer === "Uniview"
                    ? "app.uniview-camera-edit"
                    : `app.edit-${deviceType}`;
                $state.go(app, {
                  customer_id: UserService.customer_id,
                  control_system_id: UserService.control_system_id,
                  device_id: $scope.videoDevice.id,
                  channel_id: videoDevice.video_device.channels[0]?.id,
                  force_send_tz: true,
                });
              }

              $modalInstance.close("Success");
            }, 5000);
          },
          function (error) {
            // An error occurred saving the video device
            $scope.isSaving = false;
            $rootScope.alerts.push({
              type: "error",
              json: error,
            });
            $scope.eventEmitter.emit("securecom-error", error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function cancel() {
      $modalInstance.dismiss("cancel");
    }
  },
]);
