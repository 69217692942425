/**
 *
 * BurglaryIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const BurglaryIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M59.119,23.074c-0.013,0.001-0.07,0.005-0.083,0.006c-0.429-3.598-1.595-8.445-4.533-12.736
      C49.84,3.53,42.238,0.227,31.931,0.227C11.156,0.227,6.354,15,5.293,23H5.149l-1.208,0.188C2.11,24.088,2,27.492,2,27.644v6.874
      c0,1.417,0.977,2.683,2.25,3.129l0.205,0.068c3.689,1.57,13.786,5.223,27.612,5.223c13.831,0,23.8-3.655,27.439-5.227l0.213-0.053
      C61.027,37.183,62,35.921,62,34.518v-8.209C62,26.309,61.657,22.956,59.119,23.074z M55.145,24.765
      c-1.891,0.846-4.211,1.677-7.034,2.372c0.285-4.124,0.388-13.047-3.092-20.16C52.608,11.137,54.717,18.934,55.145,24.765z
       M34,4.136c1.735,0.093,3.332,0.3,4.799,0.607l-0.055,0.053c6.311,6.601,5.603,19.66,5.282,23.175
      C41.111,28.459,37.792,28.8,34,28.9V4.136z M25.135,4.746C26.62,4.43,28.236,4.216,30,4.127v24.785
      c-3.935-0.063-7.274-0.346-10.079-0.766c-0.313-3.23-1.171-16.618,5.266-23.351L25.135,4.746z M18.867,7.073
      c-3.498,7.231-3.326,16.303-3.031,20.3c-3.047-0.719-5.229-1.584-6.766-2.375C9.442,19.184,11.438,11.311,18.867,7.073z
       M58,34.086c-4,1.496-12.963,4.852-25.912,4.852C19.138,38.938,10,35.581,6,34.082v-6.105c4,2.282,11.604,4.957,25.609,4.957
      C44.358,32.934,53,30.413,58,27.932V34.086z"
        />
        <path
          d="M57.907,44.09c-0.143-0.148-0.342-0.228-0.546-0.201c-0.541,0.062-13.94,1.809-17.373,3.729
      c-0.896,0.501-7.366,3.841-7.366,3.841s-6.312-3.444-7.317-4.012c-3.426-1.918-16.801-3.494-17.342-3.558
      c-0.201-0.021-0.404,0.053-0.545,0.201c-0.142,0.154-0.203,0.36-0.17,0.57l0.01,0.052C7.337,45.133,8.456,52.688,13.852,56
      c5.296,3.252,12.264,3.316,13.02,2.974c0,0,5.735,3.026,5.751,3.026c0.068,0,0.238-0.031,0.238-0.031l5.562-2.995
      c0.752,0.343,6.621,1.341,12.997-2.974c5.244-3.547,6.568-11.396,6.65-11.829l0.008-0.04
      C58.109,43.921,58.043,44.242,57.907,44.09z M25.832,55.04c-2.841,0-9.525-0.603-12.067-6.011
      c1.531,0.088,5.075,0.449,8.011,1.918c2.671,1.335,3.943,3.147,4.457,4.089C26.112,55.038,25.978,55.04,25.832,55.04z
       M38.168,55.04L38.168,55.04c-0.146,0-0.281-0.002-0.401-0.004c0.514-0.941,1.786-2.754,4.457-4.089
      c2.937-1.469,6.48-1.83,8.011-1.918C47.693,54.437,41.01,55.04,38.168,55.04z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default BurglaryIcon;
