/**
 * @ngdoc service
 * @name App.factory:IpWhitelistService
 *
 * @description
 * Holds the API and methods for the Dealer IP Whitelist
 *
 */
App.factory("IpWhitelistService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "IpCheckerAPI",
  "IpWhitelistAPI",
  "UserService",
  "IpResolverService",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    IpCheckerAPI,
    IpWhitelistAPI,
    UserService,
    IpResolverService
  ) {
    return {
      getDealerIpWhitelist: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;
        IpWhitelistAPI.getWhitelist(
          {
            dealer_id: dealer_id,
          }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets a default template for the specified resource (resource)
       * @param dealer_id string The dealer id to get a whitelist IP template for
       * @returns {*}
       */
      newDealerWhitelistIp: function (dealer_id) {
        var deferred = $q.defer();

        IpWhitelistAPI.newWhitelistIp(
          {
            dealer_id: dealer_id,
          }, //params

          function (data) {
            //success
            data.isNew = true; // Set an isNew flag on our item template
            data.DealerId = dealer_id;
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Gets the specified dealer whitelist IP. An id is passed in to get a specific default.
       * @param dealer_id string The dealer id get whitelist IP from
       * @param whitelist_ip_id string The id of the Whitelist IP to get
       * @returns {*}
       */
      getDealerIpWhitelistIp: function (dealer_id, whitelist_ip_id) {
        var deferred = $q.defer();

        IpWhitelistAPI.getWhitelistIp(
          {
            dealer_id: dealer_id,
            id: whitelist_ip_id,
          }, //params

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Creates the specified dealer whitelist IP.
       * @param whitelist_ip_obj Object The whitelist IP to be created
       * @returns {*}
       */
      createDealerWhitelistIp: function (whitelist_ip_obj) {
        var deferred = $q.defer();

        IpWhitelistAPI.createWhitelistIP(
          {
            dealer_id: whitelist_ip_obj.DealerId,
          }, //params

          whitelist_ip_obj,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Updates the specified dealer whitelist IP.
       * @param whitelist_ip_obj Object The whitelist IP to be updated
       * @returns {*}
       */
      updateDealerWhitelistIp: function (whitelist_ip_obj) {
        var deferred = $q.defer();

        IpWhitelistAPI.updateWWhitelistIP(
          {
            dealer_id: whitelist_ip_obj.DealerId,
            id: whitelist_ip_obj.Id,
          }, //params

          whitelist_ip_obj,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Deletes the specified dealer whitelist IP.
       * @param whitelist_ip_obj Object The whitelist IP to be delete
       * @returns {*}
       */
      deleteDealerWhitelistIp: function (whitelist_ip_obj) {
        var deferred = $q.defer();

        IpWhitelistAPI.destroyWhitelistIP(
          {
            dealer_id: whitelist_ip_obj.DealerId,
            id: whitelist_ip_obj.Id,
          }, //params

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * Checks the local IP against the dealer whitelist IP.
       * @param dealer_id Object The dealer_id of the whitelist to check
       * @returns {*}
       */
      validateDealerWhitelistIp: function (dealer_id) {
        var deferred = $q.defer();
        let plaintextIp, encodedIp;

        if ($rootScope.localIP.ip) {
          plaintextIp = `${$rootScope.localIP.ip}\\${dealer_id}`;
          encodedIp = btoa(plaintextIp);
          IpCheckerAPI.isIpValid(
            {
              id: encodedIp,
            }, //params

            function (data) {
              //success
              if (data) {
                deferred.resolve(true);
              } else {
                deferred.resolve(false);
              }
            },
            function (error) {
              deferred.reject(false);
            }
          );
        } else {
          IpResolverService.getIP().then(
            function (ip) {
              plaintextIp = `${ip}\\${dealer_id}`;
              encodedIp = btoa(plaintextIp);
              IpCheckerAPI.isIpValid(
                {
                  id: encodedIp,
                }, //params

                function (data) {
                  //success
                  if (data) {
                    deferred.resolve(true);
                  } else {
                    deferred.resolve(false);
                  }
                },
                function (error) {
                  deferred.reject(false);
                }
              );
            },
            function (error) {
              deferred.reject(false);
            }
          );
        }
        return deferred.promise;
      },
    };
  },
]);
