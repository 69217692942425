import { useState, useEffect } from "react";

export default function useWindowDimensions() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const handleResize = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setTimeout(() => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 50);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return dimensions;
}
