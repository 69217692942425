export const uploadWhitelist = [
  "lbremenkamp@dmp.com",
  "bhillenburg@dmp.com",
  "tgarcia@dmp.com",
  "cmahana@dmp.com",
  "mobile+pegboard@dmp.com",
  "kmweigand1970+all-powerful@gmail.com",
];

export const PROGRAMMING_TIMEOUT_REFRESH = 540000;
