import graphql from "babel-plugin-relay/macro";
import PasswordInput from "components/FullProgramming/common/PasswordInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel, useHasWifiCommType } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsWifiPassphraseField_networkOptions$key } from "./__generated__/NetworkOptionsWifiPassphraseField_networkOptions.graphql";

export const networkOptionsWifiPassphraseFieldId = () =>
  "network-options-wifi-passphrase";

function NetworkOptionsWifiPassphraseField() {
  const [{ wifiPassphrase }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsWifiPassphraseField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsWifiPassphraseField_networkOptions on NetworkOptions {
          wifiPassphrase
        }
      `
    );

  const fieldId = networkOptionsWifiPassphraseFieldId();
  const hasWifi = useHasWifiCommType();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);
  const disabled = !isXr && !hasWifi;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Wi-Fi Passphrase"
      disabled={disabled}
    >
      <PasswordInput
        id={fieldId}
        maxLength={32}
        type="password"
        disabled={disabled}
        autoComplete="network-options-wifi-passphrase one-time-code" //This is to fool chrome into thinking this is a one-time-code field so that it will not auto-fill.
        value={wifiPassphrase}
        pattern={disabled ? undefined : "^((?=.*\\S$).*)$"}
        inlineHelp="32 character maximum with no trailing spaces."
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "wifiPassphrase");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsWifiPassphraseField;
