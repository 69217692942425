/**
 * @ngdoc service
 * @name App.factory:ModemUpgradeSettingsService
 *
 * @description
 * Holds the API and methods for the Modem Upgrade Settings
 *
 */
App.factory("ModemUpgradeSettingsService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "ModemUpgradeSettingsAPI",
  "UserService",
  "IpResolverService",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    ModemUpgradeSettingsAPI,
    UserService,
    IpResolverService
  ) {
    return {
      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeSettingsService
       *
       * @description
       * This gets the settings for a dealer.
       * It does not include the unavailable dates or chosen customers.
       * See the $scope.modemUpgradeSettings object in the sunset-dealer-availability-ctrl.js file, it is the exact model.
       *
       * @param {Promise} data- promise response
       */
      getModemUpgradeSettings: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;
        ModemUpgradeSettingsAPI.getModemUpgradeSettings(
          {
            dealer_id: dealer_id,
          }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeSettingsService
       *
       * @description
       * This creates the settings for a dealer.
       *
       * @param {Promise} data- promise response
       */
      createModemUpgradeSettings: function (modem_upgrade_settings) {
        var deferred = $q.defer();

        ModemUpgradeSettingsAPI.createModemUpgradeSettings(
          {
            dealer_id: modem_upgrade_settings.DealerId,
          }, //params

          modem_upgrade_settings,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeSettingsService
       *
       * @description
       * This updates the settings for a dealer.
       *
       * @param {Promise} data- promise response
       */
      updateModemUpgradeSettings: function (modem_upgrade_settings) {
        var deferred = $q.defer();

        ModemUpgradeSettingsAPI.updateModemUpgradeSettings(
          {
            dealer_id: modem_upgrade_settings.DealerId,
          }, //params

          modem_upgrade_settings,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
