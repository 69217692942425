/**
 * @generated SignedSource<<b06ab6ea9221645a5f2dd97fdd9739b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay$data = {
  readonly systemTroubleStatusMonitors: string;
  readonly " $fragmentType": "StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay";
};
export type StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay$key = {
  readonly " $data"?: StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemTroubleStatusMonitors",
      "storageKey": null
    }
  ],
  "type": "StatusListDisplay",
  "abstractKey": null
};

(node as any).hash = "7d6f1be6cea473e164f1e52e51ed200e";

export default node;
