/**
 * @generated SignedSource<<c8744cc12fd0df7fbfa65ce284c0a9fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type CommPathTestTimeField_communicationPath$data = {
  readonly id: string;
  readonly number: number;
  readonly testReport: CommunicationPathTestReport;
  readonly testTime: string;
  readonly " $fragmentType": "CommPathTestTimeField_communicationPath";
};
export type CommPathTestTimeField_communicationPath$key = {
  readonly " $data"?: CommPathTestTimeField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathTestTimeField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathTestTimeField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testTime",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "aee102e9dc26570d93e3b80eaf3ffc8b";

export default node;
