/**
 * @generated SignedSource<<8bc93f6e1a83511e5a997d85e8fa2408>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type EolResistorValue = "FOUR_POINT_SEVEN_THOUSAND" | "ONE_THOUSAND" | "THREE_POINT_THREE_THOUSAND" | "TWO_POINT_TWO_THOUSAND";
export type KeypadArmedLed = "ALL" | "ANY";
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly systemOptions: {
    readonly allowOwnUserCodeChange: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly armDisarmActivityDays: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly bypassLimit: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly celsius: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCheck: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingWait: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly crossZoneTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly detectJam: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly displayTime: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly enableKeypadPanicKeys: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly enhancedZoneTest: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay1: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay2: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay3: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelay4: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly eolResistorValue: {
      readonly data: EolResistorValue | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly holdupConfirmationTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly hoursFromGMT: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly houseCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly instantArming: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly intruderConfirmationTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly iso2CountryCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly keypadArmedLed: {
      readonly data: KeypadArmedLed | null;
      readonly included: boolean | null;
    } | null;
    readonly latchedSupervisoryZones: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly occupiedPremises: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly panicSupervision: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly powerFailDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly primaryProgrammingLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly primaryUserLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly resetSwingerBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly secondaryProgrammingLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly secondaryUserLanguage: {
      readonly data: PanelProgrammingLanguage | null;
      readonly included: boolean | null;
    } | null;
    readonly send16CharacterNames: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly swingerBypassTrips: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly systemType: {
      readonly data: SystemType | null;
      readonly included: boolean | null;
    } | null;
    readonly timeChange: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly useBuiltIn1100Wireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly useFalseAlarmQuestion: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherPostalCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly weatherZipCode: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessAudibles: {
      readonly data: WirelessAudibles | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100: {
      readonly data: WirelessEncryption1100 | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessEncryption1100Passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneActivityHours: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneRetardDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "ba0c5ff89ba7e516dc3af1b1588a9d68";

export default node;
