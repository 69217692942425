/**
 * @generated SignedSource<<989d1e90ccb8ccbe005eb87871795705>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsSubnetMaskField_networkOptions$data = {
  readonly dhcpEnabled: boolean;
  readonly subnetMask: string;
  readonly " $fragmentType": "NetworkOptionsSubnetMaskField_networkOptions";
};
export type NetworkOptionsSubnetMaskField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsSubnetMaskField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsSubnetMaskField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsSubnetMaskField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subnetMask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dhcpEnabled",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "f1e9424448913bce4b5924064c195aba";

export default node;
