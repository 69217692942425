import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay$key } from "./__generated__/StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay.graphql";

export const statusListDisplayEmergencyZoneKeypadsFieldId = () =>
  "status-list-display-emergency-zone-keypads";

function StatusListDisplayEmergencyZoneKeypadsField() {
  const [{ emergencyZoneKeypads }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay on StatusListDisplay {
          emergencyZoneKeypads
        }
      `
    );

  const fieldId = statusListDisplayEmergencyZoneKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Emergency Zone Keypads"
      tooltip="Configure which keypad addresses show Emergency Zone Alarms and Troubles."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={emergencyZoneKeypads}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "emergencyZoneKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "emergencyZoneKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplayEmergencyZoneKeypadsField;
