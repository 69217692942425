/**
 * Theme
 * @author Alex Matthews
 *
 */
import React, { useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  path,
  curry,
  compose,
  converge,
  call,
  splitEvery,
  curryN,
} from "ramda";
import { ThemeProvider, ThemeContext } from "styled-components";
import rgba from "../../../../utils/universal/rgba";

export const ThemePropType = PropTypes.shape({
  // utility colors
  textColor: PropTypes.string.isRequired,
  primaryBackgroundColor: PropTypes.string.isRequired,
  secondaryBackgroundColor: PropTypes.string.isRequired,
  tertiaryBackgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  panelBorderColor: PropTypes.string.isRequired,
  // constant colors
  trueWhite: PropTypes.string.isRequired,
  trueBlack: PropTypes.string.isRequired,
  // theme specific colors
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  secondaryDark: PropTypes.string.isRequired,
  secondaryLight: PropTypes.string.isRequired,
  primary100: PropTypes.string.isRequired,
  primary200: PropTypes.string.isRequired,
  primary300: PropTypes.string.isRequired,
  primary400: PropTypes.string.isRequired,
  primary500: PropTypes.string.isRequired,
  primary600: PropTypes.string.isRequired,
  primary700: PropTypes.string.isRequired,
  primary800: PropTypes.string.isRequired,
  primary900: PropTypes.string.isRequired,
  primary1000: PropTypes.string.isRequired,
  // semantic colors
  success: PropTypes.string.isRequired,
  save: PropTypes.string.isRequired,
  caution: PropTypes.string.isRequired,
  warning: PropTypes.string.isRequired,
  failure: PropTypes.string.isRequired,
  danger: PropTypes.string.isRequired,
  // regular colors
  grayXlight: PropTypes.string.isRequired,
  grayLight: PropTypes.string.isRequired,
  grayMediumLight: PropTypes.string.isRequired,
  grayMedium: PropTypes.string.isRequired,
  gray: PropTypes.string.isRequired,
  grayDark: PropTypes.string.isRequired,
  grayAccent: PropTypes.string.isRequired,
  red: PropTypes.string.isRequired,
  orange: PropTypes.string.isRequired,
  yellow: PropTypes.string.isRequired,
  yellowLight: PropTypes.string.isRequired,
  yellow100: PropTypes.string.isRequired,
  yellow200: PropTypes.string.isRequired,
  yellow300: PropTypes.string.isRequired,
  yellow400: PropTypes.string.isRequired,
  yellow500: PropTypes.string.isRequired,
  yellow600: PropTypes.string.isRequired,
  yellow700: PropTypes.string.isRequired,
  yellow800: PropTypes.string.isRequired,
  yellow900: PropTypes.string.isRequired,
  yellow1000: PropTypes.string.isRequired,
  green: PropTypes.string.isRequired,
  green100: PropTypes.string.isRequired,
  green200: PropTypes.string.isRequired,
  green300: PropTypes.string.isRequired,
  green400: PropTypes.string.isRequired,
  green500: PropTypes.string.isRequired,
  green600: PropTypes.string.isRequired,
  green700: PropTypes.string.isRequired,
  green800: PropTypes.string.isRequired,
  green900: PropTypes.string.isRequired,
  green1000: PropTypes.string.isRequired,
  blueDark: PropTypes.string.isRequired,
  coolBlue: PropTypes.string.isRequired,
  purple: PropTypes.string.isRequired,
  // theme utilities
  boxShadow: PropTypes.func.isRequired,
  panelBorder: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  fallbackFontFamily: PropTypes.string.isRequired,
  primaryFontFamily: PropTypes.string.isRequired,
  elevation0: PropTypes.string.isRequired,
  elevation100: PropTypes.string.isRequired,
  elevation200: PropTypes.string.isRequired,
  elevation300: PropTypes.string.isRequired,
  elevation400: PropTypes.string.isRequired,
  elevation500: PropTypes.string.isRequired,
});

const Theme = ({ children, defaultTheme, themes }) => {
  const [currentTheme, setTheme] = useState(themes.get(defaultTheme));
  return (
    <ThemeProvider
      theme={useMemo(
        () => ({
          ...currentTheme,
          setTheme,
          themes: themes.keySeq().toSet(),
        }),
        [currentTheme, themes]
      )}
    >
      {children}
    </ThemeProvider>
  );
};

export default Theme;

export const useTheme = () => useContext(ThemeContext);
export const useTextColor = () => useTheme().textColor;
export const themeTextColor = path(["theme", "textColor"]);
export const usePrimaryBackgroundColor = () =>
  useTheme().primaryBackgroundColor;
export const themePrimaryBackgroundColor = path([
  "theme",
  "primaryBackgroundColor",
]);
export const useSecondaryBackgroundColor = () =>
  useTheme().secondaryBackgroundColor;
export const themeSecondaryBackgroundColor = path([
  "theme",
  "secondaryBackgroundColor",
]);
export const useTertiaryBackgroundColor = () =>
  useTheme().tertiaryBackgroundColor;
export const themeTertiaryBackgroundColor = path([
  "theme",
  "tertiaryBackgroundColor",
]);
export const useBorderColor = () => useTheme().borderColor;
export const themeBorderColor = path(["theme", "borderColor"]);
export const useTrueWhite = () => useTheme().trueWhite;
export const themeTrueWhite = path(["theme", "trueWhite"]);
export const useTrueBlack = () => useTheme().trueBlack;
export const themeTrueBlack = path(["theme", "trueBlack"]);
export const usePrimary = (value = "") => {
  const theme = useTheme();
  return theme[`primary${value}`] ?? theme.primary;
};
export const themePrimary = path(["theme", "primary"]);
export const themePrimaryValue = curry(
  (value, { theme }) => theme[`primary${value}`] ?? theme.primary
);
export const useSecondary = () => useTheme().secondary;
export const themeSecondary = path(["theme", "secondary"]);
export const useSecondaryDark = () => useTheme().secondaryDark; // TODO: make this capital in all locations (themeSecondaryDark)

export const themeSecondaryDark = path(["theme", "secondaryDark"]);
export const useSecondaryLight = () => useTheme().secondaryLight; // TODO: make this capital in all locations (themeSecondaryLight)

export const themeSecondaryLight = path(["theme", "secondaryLight"]);
export const useGrayXlight = () => useTheme().grayXlight;
export const themeGrayXlight = path(["theme", "grayXlight"]);
export const useGrayLight = () => useTheme().grayLight;
export const themeGrayLight = path(["theme", "grayLight"]);
export const useGrayMedium = () => useTheme().grayMedium;
export const themeGrayMediumLight = path(["theme", "grayMediumLight"]);
export const useGrayMediumLight = () => useTheme().grayMediumLight;
export const themeGrayMedium = path(["theme", "grayMedium"]);
export const useGray = () => useTheme().gray;
export const themeGray = path(["theme", "gray"]);
export const useGrayDark = () => useTheme().grayDark;
export const themeGrayDark = path(["theme", "grayDark"]);
export const useGrayAccent = () => useTheme().grayAccent;
export const themeGrayAccent = path(["theme", "grayAccent"]);
export const useRed = (value = "") => {
  const theme = useTheme();
  return theme[`red${value}`] ?? theme.red;
};
export const themeRed = path(["theme", "red"]);
export const useOrange = () => useTheme().orange;
export const themeOrange = path(["theme", "orange"]);
export const useYellow = (value = "") => {
  const theme = useTheme();
  return theme[`yellow${value}`] ?? theme.yellow;
};
export const themeYellow = path(["theme", "yellow"]);
export const useYellowLight = () => useTheme().yellowLight;
export const themeYellowLight = path(["theme", "yellowLight"]);
export const useGreen = (value = "") => {
  const theme = useTheme();
  return theme[`green${value}`] ?? theme.green;
};
export const themeGreen = path(["theme", "green"]);
export const themeGreenValue = curry(
  (value, { theme }) => theme[`green${value}`] ?? theme.green
);
export const useBlueDark = () => useTheme().blueDark;
export const themeBlueDark = path(["theme", "blueDark"]);
export const useCoolBlue = () => useTheme().coolBlue;
export const themeCoolBlue = path(["theme", "coolBlue"]);
export const usePurple = () => useTheme().purple;
export const themePurple = path(["theme", "purple"]);
export const useBoxShadow = () => useTheme().boxShadow;
export const themeBoxShadow = (colorGetter = path(["theme", "grayAccent"])) =>
  converge(call, [path(["theme", "boxShadow"]), colorGetter]);
export const themeRgba = curry((alpha, colorGetter) =>
  compose(rgba(alpha), colorGetter)
);
export const defaultThemeBoxShadow = themeBoxShadow(
  themeRgba(0.25, themeTrueBlack)
);
export const usePanelBorderColor = () => useTheme().panelBorderColor;
export const themePanelBorderColor = path(["theme", "panelBorderColor"]);
export const usePanelBorder = () => useTheme().panelBorder;
export const themePanelBorder = path(["theme", "panelBorder"]);
export const useHighlightColor = () => useTheme().highlightColor;
export const themeHighlightColor = path(["theme", "highlightColor"]);
export const useFallbackFontFamily = () => useTheme().fallbackFontFamily;
export const themeFallbackFontFamily = path(["theme", "fallbackFontFamily"]);
export const usePrimaryFontFamily = () => useTheme().primaryFontFamily;
export const themePrimaryFontFamily = path(["theme", "primaryFontFamily"]);
export const useColor = () => useTheme().color;
export const themeColor = path(["theme", "color"]);
export const useBackgroundColor = () => useTheme().backgroundColor;
export const themeBackgroundColor = path(["theme", "backgroundColor"]);
export const useSuccess = () => useTheme().success;
export const themeSuccess = path(["theme", "success"]);
export const useSave = () => useTheme().save;
export const themeSave = path(["theme", "save"]);
export const useCaution = () => useTheme().caution;
export const themeCaution = path(["theme", "caution"]);
export const useWarning = () => useTheme().warning;
export const themeWarning = path(["theme", "warning"]);
export const useFailure = () => useTheme().failure;
export const themeFailure = path(["theme", "failure"]);
export const useDanger = () => useTheme().danger;
export const themeDanger = path(["theme", "danger"]);
export const elevation = curry(
  (value, { theme }) => theme[`elevation${value}`] ?? theme.elevation100
);
export const hexToRGB = curryN(
  2,
  (colorFunc, ...args) =>
    `rgb(${splitEvery(2, colorFunc(...args).replace(/#/gim, ""))
      .map((twoDigitColorString) => parseInt(twoDigitColorString, 16))
      .join(",")})`
);
export const hexToRGBA = curryN(
  3,
  (colorFunc, alpha, ...args) =>
    `rgba(${splitEvery(2, colorFunc(...args).replace(/#/gim, ""))
      .map((twoDigitColorString) => parseInt(twoDigitColorString, 16))
      .join(",")}, ${alpha})`
);
