App.factory("TechSupportApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/:suffix/:error_id/:type_id",
      {},
      {
        getErrorTypes: {
          method: "GET",
          params: { suffix: "troubleshootingmsgTypes" },
          isArray: true,
        },
        getErrorInformation: {
          method: "GET",
          params: { suffix: "troubleshootingmsgs", error_id: "@error_id" },
        },
        getErrorMessagesByType: {
          method: "GET",
          params: {
            suffix: "troubleshootingmsgs",
            error_id: "type",
            type_id: "@type_id",
          },
          isArray: true,
        },
      }
    );
  },
]);
