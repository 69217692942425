import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay$key } from "./__generated__/StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay.graphql";

export const statusListDisplayAuxiliary1ZoneKeypadsFieldId = () =>
  "status-list-display-auxiliary-1-zone-keypads";

function StatusListDisplayAuxiliary1ZoneKeypadsField() {
  const [{ auxiliary1ZoneKeypads }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay on StatusListDisplay {
          auxiliary1ZoneKeypads
        }
      `
    );

  const fieldId = statusListDisplayAuxiliary1ZoneKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Auxiliary 1 Zone Keypads"
      tooltip="Configure which keypad addresses show Auxiliary 1 Zone Alarms and Troubles."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={auxiliary1ZoneKeypads}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "auxiliary1ZoneKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "auxiliary1ZoneKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplayAuxiliary1ZoneKeypadsField;
