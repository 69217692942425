/**
 * @generated SignedSource<<90b3ea84e8375b72c261736b5e46f16b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorBackupIpAddressField_remoteOptions$data = {
  readonly integratorBackupConnection?: RemoteOptionsConnectionType | null;
  readonly integratorBackupIpAddress?: string | null;
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorBackupIpAddressField_remoteOptions";
};
export type RemoteOptionsIntegratorBackupIpAddressField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorBackupIpAddressField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorBackupIpAddressField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorBackupIpAddress",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorConnection",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorBackupConnection",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorBackupIpAddressField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "fd3920e9108ad058fedf1b7195bc3a97";

export default node;
