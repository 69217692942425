import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import {
  outputInformationListItemTemplateId,
  OUTPUT_INFORMATION_IDS,
} from "components/FullProgramming/common/OutputInformationFields/OutputInformationNumberField";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { PanelHardwareModel } from "components/FullProgramming/common/__generated__/PanelContextUseHardwareModel_panel.graphql";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  fromControlSystemId,
  fromOutputId,
  idAsString,
  Output,
  OutputSupervision,
  Panel,
  toGlobalId,
  toOutputId,
} from "securecom-graphql/client";
import { useShowAlert } from "../../../../contexts/AlertsContext";
import { useParentRelayEnvironment } from "../../../RelayEnvironmentCloneProvider";
import {
  applyCopiedOutputInformationProgrammingToOutputInformation,
  applyDupedOutputInformationProgrammingToOutputInformation,
  resolveOutputNumbers,
  resolveWirelessOutputNumbers,
} from "../../common/OutputInformationFields/utils";
import { PanelContextProvider } from "../../common/PanelContext";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import XROutputInformationFields from "./XROutputInformationFields";
import {
  XROutputInformationProgrammingConceptFormInline_controlSystem$data,
  XROutputInformationProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XROutputInformationProgrammingConceptFormInline_controlSystem.graphql";
import {
  XROutputInformationProgrammingConceptFormInline_output$data,
  XROutputInformationProgrammingConceptFormInline_output$key,
} from "./__generated__/XROutputInformationProgrammingConceptFormInline_output.graphql";
import { XROutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XROutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XROutputInformationProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XROutputInformationProgrammingConceptFormNavButton_controlSystem.graphql";
import { XROutputInformationProgrammingConceptFormOutputInformationDeleteMutation } from "./__generated__/XROutputInformationProgrammingConceptFormOutputInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XROutputInformationProgrammingConceptFormOutputInformationRefreshMutation,
} from "./__generated__/XROutputInformationProgrammingConceptFormOutputInformationRefreshMutation.graphql";
import {
  XROutputInformationProgrammingConceptFormOutputInformationSendMutation,
  XROutputInformationProgrammingConceptFormOutputInformationSendMutation$data,
} from "./__generated__/XROutputInformationProgrammingConceptFormOutputInformationSendMutation.graphql";
import { XROutputInformationProgrammingConceptForm_controlSystem$key } from "./__generated__/XROutputInformationProgrammingConceptForm_controlSystem.graphql";

export const title = "Output Information";
export const conceptId = "xr-output-information";

export const getState = (
  controlSystem: XROutputInformationProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XROutputInformationProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        isTakeoverPanel
        panel {
          id
          outputInformations {
            id
            number
            isNew
            ...XROutputInformationProgrammingConceptFormInline_output
          }
          maxOutputs
        }
      }
    `,
    controlSystem
  );

export const getOutputState = (
  output: XROutputInformationProgrammingConceptFormInline_output$key
) =>
  readInlineData(
    graphql`
      fragment XROutputInformationProgrammingConceptFormInline_output on Output
      @inline {
        id
        number
        name
        isNew
        serialNumber
        outputSupervision
        tripWithPanelBell
        realTimeStatus
        isWireless
      }
    `,
    output
  );

const deleteMutation = graphql`
  mutation XROutputInformationProgrammingConceptFormOutputInformationDeleteMutation(
    $outputId: ID!
  ) {
    deleteSystemOutput(outputId: $outputId) {
      ... on DeleteSystemOutputSuccessPayload {
        __typename
        deletedOutputId
      }
      ... on FailedToRemoveOutputErrorPayload {
        error: type
      }
    }
  }
`;

const refreshMutation = graphql`
  mutation XROutputInformationProgrammingConceptFormOutputInformationRefreshMutation(
    $id: ID!
  ) {
    refreshAllSystemOutputs(systemId: $id) {
      ... on RefreshAllSystemOutputsSuccessResponse {
        __typename
        controlSystem: system {
          __typename
          ...XROutputInformationProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshAllSystemOutputsErrorResponse {
        error {
          type
        }
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XROutputInformationProgrammingConceptFormOutputInformationSendMutation(
    $systemId: ID!
    $outputInformations: [OutputProgrammingInput!]!
  ) {
    sendOutputsProgramming(
      systemId: $systemId
      outputInformations: $outputInformations
    ) {
      ... on Error {
        type
      }
      ... on SendOutputsProgrammingSuccessPayload {
        results {
          __typename
          ... on SendOutputsProgrammingOutputSuccessPayload {
            output {
              __typename
              id
              number
              ...XROutputInformationProgrammingConceptFormInline_output
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;

const mergeOldAndNewOutputs = (
  response: XROutputInformationProgrammingConceptFormOutputInformationSendMutation$data,
  originalControlSystemData: XROutputInformationProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendOutputsProgramming.results) {
    const successfulOutputs = response.sendOutputsProgramming.results
      .map((output) => {
        if (
          output.__typename === "SendOutputsProgrammingOutputSuccessPayload"
        ) {
          return output;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.output)
      .map(getOutputState);

    const mergedOutputsMap = new Map<
      string,
      XROutputInformationProgrammingConceptFormInline_output$data
    >();

    originalControlSystemData.panel.outputInformations
      .map(getOutputState)
      .forEach((item) => mergedOutputsMap.set(item.id, item));

    successfulOutputs.forEach((item) =>
      mergedOutputsMap.set(item.id, {
        ...mergedOutputsMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedOutputsMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XROutputInformationProgrammingConceptFormOutputInformationSendMutation$data,
  originalControlSystemData: XROutputInformationProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedOutputs = mergeOldAndNewOutputs(
    response,
    originalControlSystemData
  );

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshAllSystemOutputs: {
        __typename: "RefreshAllSystemOutputsSuccessResponse",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            outputs: mergedOutputs,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XROutputInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendOutputInformation, isSending] =
    useMutation<XROutputInformationProgrammingConceptFormOutputInformationSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedOutputs = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { outputInformations },
        } = getState(props.controlSystem);
        if (outputInformations) {
          sendOutputInformation({
            variables: {
              systemId,
              outputInformations: outputInformations
                .filter(
                  (output) =>
                    output.isNew ||
                    (!!changedOutputs &&
                      listItemHasChanged(output.id, changedOutputs)) ||
                    isSavingAllListItems
                )
                .map(getOutputState)
                .map((output) => ({
                  id: output.id,
                  name: output.name,
                  number: output.number.toString(),
                  serialNumber: output.serialNumber,
                  outputSupervision: output.outputSupervision,
                  tripWithPanelBell: !!output.tripWithPanelBell,
                  realTimeStatus: !!output.realTimeStatus,
                  isNew: output.isNew,
                })),
            },
            onCompleted: (response) => {
              const saveErrors: SaveErrors = [];
              if (response.sendOutputsProgramming.type && showAlerts) {
                showAlert({
                  type: "error",
                  text: `Error Sending ${title} - Panel Not Found`,
                });
              } else if (response.sendOutputsProgramming.results) {
                response.sendOutputsProgramming.results.forEach((response) => {
                  if (
                    response.__typename ===
                    "SendOutputsProgrammingOutputSuccessPayload"
                  ) {
                    resetLastUpdated(response.output.id);
                  } else if (
                    response.__typename === "SendListItemsErrorPayload"
                  ) {
                    saveErrors.push({
                      programmingConcept: title,
                      errors: response.errors,
                      listItemNumber: response.number,
                    });
                  }
                });

                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );

                if (!saveErrors.length && showAlerts) {
                  showAlert({
                    type: "success",
                    text: `Successfully Updated ${title}`,
                  });
                }
              }
              resolve(saveErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSending,
  ];
};
export const useRetrieveMutation = (props: {
  controlSystem: XROutputInformationProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshOutputInformation, isRefreshing] =
    useMutation<XROutputInformationProgrammingConceptFormOutputInformationRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshOutputInformation({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshAllSystemOutputs;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Output Information Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, response);
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Output Information: ${hyphenScoreToTitleCase(
                      error.type ?? "UNKNOWN_ERROR"
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Output Information",
                  });
                }
              }
              reject(error?.type);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XROutputInformationProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XROutputInformationProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            outputInformations {
              isNew
            }
          }
        }
      `
    );
  const { outputInformations } = controlSystem.panel;
  const itemsCount = outputInformations.length;
  const hasNewItems =
    itemsCount > 0 && outputInformations.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

const readOutputInformationTemplateData = (
  programmingTemplateConcepts: XROutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XROutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        outputInformations {
          id
          included
          number
          name {
            included
            data
          }
          outputSupervision {
            included
            data
          }
          tripWithPanelBell {
            included
            data
          }
          realTimeStatus {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).outputInformations;

export function applyTemplateData(
  programmingTemplateConcepts: XROutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);

  const outputInformationsRecordProxies =
    panelRecordProxy.getLinkedRecords("outputInformations") ?? [];
  const outputInformationsByNumber = indexRecordProxiesByNumber(
    outputInformationsRecordProxies
  );
  const hardwareModel = panelRecordProxy.getValue("hardwareModel");

  const outputInformationsTemplateData =
    readOutputInformationTemplateData(programmingTemplateConcepts) ?? [];

  outputInformationsTemplateData.forEach((outputInformationTemplateData) => {
    if (outputInformationTemplateData?.included) {
      let outputInformationRecordProxy = outputInformationsByNumber.get(
        outputInformationTemplateData.number
      );
      if (!outputInformationRecordProxy) {
        const newOutputInformationId =
          applyNewOutputInformationToOutputInformationsList(
            {
              id: panelRecordProxy.getValue("id"),
              hardwareModel,
              newOutputInformation: panelRecordProxy.getLinkedRecord(
                "newOutputInformation"
              ) && {
                id: panelRecordProxy
                  .getLinkedRecord("newOutputInformation")
                  .getValue("id"),
              },
              outputInformations: outputInformationsRecordProxies.map(
                (recordProxy) => ({
                  number: recordProxy.getValue("number"),
                })
              ),
            },
            store
          );
        if (newOutputInformationId) {
          outputInformationRecordProxy = store.get(
            newOutputInformationId
          ) as RecordProxy<Output>;
          if (outputInformationRecordProxy) {
            outputInformationRecordProxy.setValue(
              outputInformationTemplateData.number,
              "number"
            );
          }
        }
      }

      if (!outputInformationRecordProxy) {
        return;
      }

      const isWireless = new Set(
        resolveWirelessOutputNumbers(hardwareModel)
      ).has(outputInformationTemplateData.number);

      outputInformationRecordProxy.setValue(isWireless, "isWireless");

      if (isWireless) {
        const serialNumber = outputInformationRecordProxy
          .getValue("serialNumber")
          ?.toString();

        outputInformationRecordProxy.setValue(
          new RegExp("(15[1-9][0-9]{5})").test(serialNumber ?? "")
            ? serialNumber
            : "15",
          "serialNumber"
        );
      }

      applyTemplateScalarDataToRecordProxy(
        outputInformationRecordProxy,
        outputInformationTemplateData
      );

      if (
        !outputInformationsByNumber.has(outputInformationTemplateData.number)
      ) {
        outputInformationsByNumber.set(
          outputInformationTemplateData.number,
          outputInformationRecordProxy
        );
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(outputInformationsByNumber),
    "outputInformations"
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XROutputInformationProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XROutputInformationProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedOutputInformation {
            id
          }
          isTakeoverPanel
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...OutputInformationNumberField_panel
            outputInformations {
              id
              number
              name
              isNew
              ...XROutputInformationFields_output
            }
            newOutputInformation {
              id
              number
              name
              isNew
              ...XROutputInformationFields_output
            }
            hardwareModel
            systemOptions {
              ... on XrSystemOptions {
                houseCode
              }
              ...SystemOptionsContextSystemType_systemOptions
            }
            maxOutputs
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(OUTPUT_INFORMATION_IDS);
  const {
    outputInformations,
    newOutputInformation,
    hardwareModel,
    helpFiles: { programmingGuideUrl },
  } = controlSystem.panel;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    controlSystem.panel.outputInformations[0]?.id ?? null
  );

  const [deleteOutputInformation, isDeleting] =
    useMutation<XROutputInformationProgrammingConceptFormOutputInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedOutput = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.outputInformations.findIndex(
          (outputInformation) => outputInformation.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.outputInformations.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return (
          controlSystem.panel.outputInformations[newSelectedIndex]?.id ?? null
        );
      });
      const output = outputInformations.find(
        (output) => output.id === selectedListItemId
      );
      uncheckListItem(String(output?.number));
      if (output?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "outputInformations",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Output",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            output?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteOutputInformation({
          variables: {
            outputId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "outputInformations",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedOutputId } = response.deleteSystemOutput;
            if (deletedOutputId) {
              showAlert({
                type: "success",
                text: "Output Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "outputInformations",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedOutputId, error } = response.deleteSystemOutput;
            if (deletedOutputId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedOutputId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "outputInformations",
                      controlSystem.panel.id,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Output: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Output",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Output",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const canAdd = availableNumbers.size > 0;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Output%20Information`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.outputInformations
        )}
        amountAvailable={availableNumbers.size}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newOutputInformationId =
                  applyNewOutputInformationToOutputInformationsList(
                    controlSystem.panel,
                    store
                  );
                if (newOutputInformationId) {
                  setSelectedListItemId(newOutputInformationId);
                }
              });
            }}
          >
            Add Output
          </ProgrammingConceptForm.AddButton>
        }
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          {(conceptId === activeConcept || isApplying || isSavingAll) && (
            <ProgrammingConceptForm.ListItemsContainer>
              <ProgrammingConceptForm.ListItemPicker
                selectedId={selectedListItemId}
                onChange={(id) => {
                  setSelectedListItemId(id);
                }}
                newItemId={newOutputInformation?.id}
                items={outputInformations.map((outputInformation) => ({
                  id: outputInformation.id,
                  templateListItemId: outputInformationListItemTemplateId(
                    String(outputInformation.number)
                  ),
                  isnew: outputInformation.isNew,
                  label: `#${outputInformation.number} ${outputInformation.name}`,
                }))}
              />
              <ProgrammingConceptForm.SelectedItemsContainer
                selectedListItemId={selectedListItemId}
                setSelectedListItemId={setSelectedListItemId}
              >
                {outputInformations.map(
                  (outputInformation) =>
                    (outputInformation.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                      programmingConcepts[conceptId].isSaving ||
                      isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                      isSavingAll) && (
                      <RemountOnUpdateContainer nodeId={outputInformation.id}>
                        <ProgrammingConceptForm.SelectedItem
                          conceptId={conceptId}
                          isnew={outputInformation.isNew}
                          visible={outputInformation.id === selectedListItemId}
                          key={outputInformation.id}
                          listItemId={outputInformation.id}
                          templateListItemId={outputInformationListItemTemplateId(
                            String(outputInformation.number)
                          )}
                          title={`Output #${outputInformation.number}`}
                          onDuplicate={
                            canAdd &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const duplicateId =
                                  applyDuplicatedOutputInformationToOutputInformationsList(
                                    selectedListItemId,
                                    controlSystem.panel,
                                    store
                                  );
                                if (duplicateId) {
                                  setSelectedListItemId(duplicateId);
                                }
                              });
                            })
                          }
                          onCopy={() => {
                            relayEnv.commitUpdate((store) => {
                              const controlSystemRecord = store.get(
                                controlSystem.id
                              );
                              const outputInformationRecord =
                                store.get<Output>(selectedListItemId);
                              if (
                                controlSystemRecord &&
                                outputInformationRecord
                              ) {
                                const tempRecord =
                                  store.get("copiedOutputInformation") ??
                                  store.create(
                                    "copiedOutputInformation",
                                    "OutputInformation"
                                  );
                                tempRecord.copyFieldsFrom(
                                  outputInformationRecord
                                );
                                controlSystemRecord.setLinkedRecord(
                                  tempRecord,
                                  "copiedOutputInformation"
                                );
                              }
                            });
                          }}
                          onPaste={
                            !!controlSystem.copiedOutputInformation &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const outputInformationRecord =
                                  store.get<Output>(selectedListItemId);
                                const copiedOutputInformationRecord =
                                  store.get<Output>("copiedOutputInformation");
                                const allPossibleWirelessOutputNumbers =
                                  new Set(
                                    resolveWirelessOutputNumbers(hardwareModel)
                                  );

                                if (
                                  outputInformationRecord &&
                                  copiedOutputInformationRecord
                                ) {
                                  applyCopiedOutputInformationProgrammingToOutputInformation(
                                    copiedOutputInformationRecord,
                                    outputInformationRecord,
                                    allPossibleWirelessOutputNumbers
                                  );
                                }
                              });
                            })
                          }
                          onRemove={removeSelectedOutput}
                        >
                          <XROutputInformationFields
                            key={outputInformation.id}
                            outputInformation={outputInformation}
                          />
                        </ProgrammingConceptForm.SelectedItem>
                      </RemountOnUpdateContainer>
                    )
                )}
              </ProgrammingConceptForm.SelectedItemsContainer>
            </ProgrammingConceptForm.ListItemsContainer>
          )}
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewOutputInformationToOutputInformationsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly hardwareModel: PanelHardwareModel;
    readonly newOutputInformation: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newOutputInformation } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newOutputInformationTemplate = panelRecord.getLinkedRecord<Output>(
      "newOutputInformation"
    );
    if (newOutputInformation) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromOutputId(
          asID(newOutputInformationTemplate.getDataID())
        );
        const nextNewOutputInformationId = idAsString(
          toOutputId(systemId, nextNumber)
        );
        const nextNewOutputInformation = store.create(
          nextNewOutputInformationId,
          "OutputInformation"
        ) as RecordProxy<Output>;
        const isWireless = resolveWirelessOutputNumbers(
          panel.hardwareModel
        ).includes(nextNumber);
        nextNewOutputInformation.copyFieldsFrom(newOutputInformationTemplate!);
        nextNewOutputInformation.setValue(nextNewOutputInformationId, "id");
        nextNewOutputInformation.setValue(nextNumber, "number");
        nextNewOutputInformation.setValue(isWireless, "isWireless");
        nextNewOutputInformation.setValue(true, "isNew");
        if (isWireless) {
          nextNewOutputInformation.setValue(
            OutputSupervision.FOUR_HOURS,
            "outputSupervision"
          );
          nextNewOutputInformation.setValue("15", "serialNumber");
        } else {
          nextNewOutputInformation.setValue("--------", "serialNumber");
        }

        const outputInformationRecords =
          panelRecord.getLinkedRecords("outputInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...outputInformationRecords, nextNewOutputInformation],
          "outputInformations"
        );

        return nextNewOutputInformation.getValue("id");
      }
    }
  }
};

const applyDuplicatedOutputInformationToOutputInformationsList = (
  outputInformationId: string,
  panel: Parameters<typeof getNextAvailableNumber>[0] &
    Parameters<typeof getNextAvailableWirelessNumber>[0] &
    Parameters<typeof getNextAvailableWiredNumber>[0] & {
      readonly id: string;
      readonly hardwareModel: PanelHardwareModel;
    },
  store: RecordSourceProxy
) => {
  const { id } = panel;
  const panelRecord = store.get<Panel>(id);
  const outputInformationRecord = store.get<Output>(outputInformationId);
  const allPossibleWirelessOutputNumbers = new Set(
    resolveWirelessOutputNumbers(panel.hardwareModel)
  );

  if (panelRecord && outputInformationRecord) {
    const newOutputInformationTemplate = panelRecord.getLinkedRecord<Output>(
      "newOutputInformation"
    );
    if (newOutputInformationTemplate) {
      const isWireless = allPossibleWirelessOutputNumbers.has(
        outputInformationRecord.getValue("number")
      );
      const nextWirelessNumber = getNextAvailableWirelessNumber(panel);
      if (isWireless && isNotNullOrUndefined(nextWirelessNumber)) {
        return setOutputRecordForDuplicating(
          newOutputInformationTemplate,
          nextWirelessNumber,
          store,
          outputInformationRecord,
          panelRecord,
          allPossibleWirelessOutputNumbers
        );
      } else {
        const nextWiredNumber = getNextAvailableWiredNumber(panel);
        const nextNumber = isNotNullOrUndefined(nextWiredNumber)
          ? nextWiredNumber
          : getNextAvailableNumber(panel);
        if (isNotNullOrUndefined(nextNumber)) {
          return setOutputRecordForDuplicating(
            newOutputInformationTemplate,
            nextNumber,
            store,
            outputInformationRecord,
            panelRecord,
            allPossibleWirelessOutputNumbers
          );
        }
      }
    }
  }
};

const setOutputRecordForDuplicating = (
  newOutputInformationTemplate: RecordProxy<Output>,
  nextNumber: number,
  store: RecordSourceProxy,
  outputInformationRecord: RecordProxy<Output>,
  panelRecord: RecordProxy<Panel>,
  allPossibleWirelessNumbers: Set<number>
) => {
  const { systemId } = fromOutputId(
    asID(newOutputInformationTemplate.getDataID())
  );
  const nextNewOutputInformationId = idAsString(
    toOutputId(systemId, nextNumber)
  );
  const nextNewOutPutIsWireless = allPossibleWirelessNumbers.has(nextNumber);
  const nextNewOutputInformation = store.create(
    nextNewOutputInformationId,
    "OutputInformation"
  ) as RecordProxy<Output>;
  nextNewOutputInformation.copyFieldsFrom(newOutputInformationTemplate);
  nextNewOutputInformation.setValue(nextNewOutputInformationId, "id");
  nextNewOutputInformation.setValue(nextNumber, "number");
  nextNewOutputInformation.setValue(nextNewOutPutIsWireless, "isWireless");
  nextNewOutputInformation.setValue(true, "isNew");

  const duplicatedOutputInformationRecord =
    applyDupedOutputInformationProgrammingToOutputInformation(
      outputInformationRecord,
      nextNewOutputInformation,
      allPossibleWirelessNumbers
    );
  const outputInformationRecords =
    panelRecord.getLinkedRecords("outputInformations") ?? [];
  panelRecord.setLinkedRecords(
    [...outputInformationRecords, duplicatedOutputInformationRecord],
    "outputInformations"
  );

  return duplicatedOutputInformationRecord.getValue("id");
};

const getAvailableNumbers = (panel: {
  readonly hardwareModel: PanelHardwareModel;
  readonly outputInformations: ReadonlyArray<{
    readonly number: number;
  }>;
}) => {
  const allPossibleNumbers = new Set(
    resolveOutputNumbers(panel.hardwareModel, true)
  );
  const takenNumbers = new Set(
    panel.outputInformations.map(({ number }) => number)
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));

const getAvailableWirelessNumbers = (panel: {
  readonly hardwareModel: PanelHardwareModel;
  readonly outputInformations: ReadonlyArray<{
    readonly number: number;
  }>;
}) => {
  const allPossibleNumbers = new Set(
    resolveWirelessOutputNumbers(panel.hardwareModel)
  );
  const takenNumbers = new Set(
    panel.outputInformations.map(({ number }) => number)
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableWirelessNumber = (
  panel: Parameters<typeof getAvailableWirelessNumbers>[0]
) => setFirst(getAvailableWirelessNumbers(panel));

const getNextAvailableWiredNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => {
  const allAvailableNumbers = getAvailableNumbers(panel);
  const allAvailableWirelessNumbers = getAvailableWirelessNumbers(panel);
  const availableWiredNumbers = setDifference(
    allAvailableWirelessNumbers,
    allAvailableNumbers
  ) as Set<number>;

  return setFirst(availableWiredNumbers);
};
