import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsArmedAnswerRingsField_remoteOptions$key } from "./__generated__/RemoteOptionsArmedAnswerRingsField_remoteOptions.graphql";

export const remoteOptionsArmedAnswerRingsFieldId = () =>
  "remote-options-armed-answer-rings";

function RemoteOptionsArmedAnswerRingsField() {
  const [{ armedAnswerRings }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsArmedAnswerRingsField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsArmedAnswerRingsField_remoteOptions on RemoteOptions {
          ... on XtRemoteOptions {
            armedAnswerRings
          }
          ... on XrRemoteOptions {
            armedAnswerRings
          }
        }
      `
    );

  const fieldId = remoteOptionsArmedAnswerRingsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Armed Answer Rings"
      tooltip="The number of rings the System counts before answering a phone call while all areas are armed. "
    >
      <NumericInput
        id={fieldId}
        value={armedAnswerRings ?? 0}
        pattern="(0{0,1}[0-9]|1[0-5])"
        inlineHelp={"0-15"}
        max={15}
        min={0}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "armedAnswerRings");
          });
        }}
        onBlur={({ target }) => {
          const value = clamp(0, 15, Number(target.value));
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(value, "armedAnswerRings");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsArmedAnswerRingsField;
