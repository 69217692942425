import * as React from "react";

export type PageSize = 5 | 10 | 25 | 30 | 50 | 100;

export enum SortOrder {
  Ascending,
  Descending,
}

export enum ActionType {
  SetPageSize,
  SetCurrentPage,
  SetSearch,
  SetSortKey,
  SetSortOrder,
  ToggleSortOrder,
}

export type State<A> = {
  pageSize: PageSize;
  currentPage: number;
  search: string;
  sortKey: A | null;
  sortOrder: SortOrder;
};

export type Action<A> =
  | { type: ActionType.SetPageSize; pageSize: PageSize }
  | { type: ActionType.SetCurrentPage; currentPage: number }
  | { type: ActionType.SetSearch; search: string }
  | { type: ActionType.SetSortKey; sortKey: A | null }
  | { type: ActionType.SetSortOrder; sortOrder: SortOrder }
  | { type: ActionType.ToggleSortOrder };

const usePaginationState = <A extends any>({
  pageSize,
  currentPage,
  search,
  sortKey,
  sortOrder,
}: {
  pageSize?: PageSize;
  currentPage?: number;
  search?: string;
  sortKey?: A | null;
  sortOrder?: SortOrder;
}) => {
  const reducer = (state: State<A>, action: Action<A>) => {
    switch (action.type) {
      case ActionType.SetPageSize:
        return { ...state, pageSize: action.pageSize, currentPage: 1 };
      case ActionType.SetCurrentPage:
        return { ...state, currentPage: action.currentPage };
      case ActionType.SetSearch:
        return { ...state, search: action.search, currentPage: 1 };
      case ActionType.SetSortKey:
        return {
          ...state,
          sortKey: action.sortKey,
          sortOrder: SortOrder.Ascending,
        };
      case ActionType.SetSortOrder:
        return { ...state, sortOrder: action.sortOrder };
      case ActionType.ToggleSortOrder:
        return {
          ...state,
          sortOrder:
            state.sortOrder === SortOrder.Ascending
              ? SortOrder.Descending
              : SortOrder.Ascending,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = React.useReducer(reducer, {
    pageSize: pageSize ?? 10,
    currentPage: currentPage ?? 1,
    search: search ?? "",
    sortKey: sortKey ?? null,
    sortOrder: sortOrder ?? SortOrder.Ascending,
  });

  return [state, dispatch] as const;
};

export default usePaginationState;
