import DaColors from "app/common/DaColors";
import styled from "styled-components";

export const Subtitle = styled.div`
  align-items: center;
  color: ${DaColors.Neutral2000};
  display: flex;
  flex-flow: row wrap;
  font-size: 1.6rem;
  font-size: 2rem;
  justify-content: flex-start;
  line-height: 1.5;
  line-height: var(--measure-3x);
  margin-bottom: 0.8rem;

  & > *:first-child {
    margin-right: 1rem;
  }
`;
