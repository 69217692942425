/**
 * @generated SignedSource<<ba45f86dcce9f85811973da817d76db3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputGroupNumberField_outputGroup$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly number: number;
  readonly " $fragmentType": "OutputGroupNumberField_outputGroup";
};
export type OutputGroupNumberField_outputGroup$key = {
  readonly " $data"?: OutputGroupNumberField_outputGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputGroupNumberField_outputGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputGroupNumberField_outputGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    }
  ],
  "type": "OutputGroup",
  "abstractKey": null
};

(node as any).hash = "af016f5ae037267a39557f279fe8e46b";

export default node;
