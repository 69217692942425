import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { KeypadInput } from "securecom-graphql/client";
import { useControlSystemFragment } from "../ControlSystemContext";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsKeypadInputField_systemOptions$key } from "./__generated__/SystemOptionsKeypadInputField_systemOptions.graphql";

export const systemOptionsKeypadInputFieldId = () =>
  "system-options-keypad-input";

function SystemOptionsKeypadInputField() {
  const [, updateControlSystem] = useControlSystemFragment(
    graphql`
      fragment SystemOptionsKeypadInputField_controlSystem on ControlSystem {
        isECP
        isDSC
      }
    `
  );
  const [{ keypadInput }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsKeypadInputField_systemOptions$key>(
      graphql`
        fragment SystemOptionsKeypadInputField_systemOptions on SystemOptions {
          ... on XtSystemOptions {
            keypadInput
          }
        }
      `
    );

  const fieldId = systemOptionsKeypadInputFieldId();
  const softwareVersion = useSoftwareVersion();

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Keypad Input">
      <Select
        id={fieldId}
        name={fieldId}
        value={keypadInput ?? undefined}
        required
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "keypadInput");
          });
          updateControlSystem((recordProxy) => {
            recordProxy.setValue(target.value === KeypadInput.ECP, "isECP");
          });
          updateControlSystem((recordProxy) => {
            recordProxy.setValue(target.value === KeypadInput.DSC, "isDSC");
          });
        }}
      >
        <Select.Option value={KeypadInput.NONE}>None</Select.Option>
        <Select.Option value={KeypadInput.ECP}>ECP</Select.Option>
        {softwareVersion >= 201 && (
          <Select.Option value={KeypadInput.DSC}>DSC</Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsKeypadInputField;
