/**
 * @generated SignedSource<<211aa346a8f21937e4f891503d85eeda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75CardFormatsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly cardFormats: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: string;
      readonly " $fragmentSpreads": FragmentRefs<"XT75CardFormatsProgrammingConceptFormInline_cardFormat">;
    }>;
    readonly id: string;
  };
  readonly " $fragmentType": "XT75CardFormatsProgrammingConceptFormInline_controlSystem";
};
export type XT75CardFormatsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75CardFormatsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CardFormatsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75CardFormatsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "f3412ec6e1ca592249ff32260193c631";

export default node;
