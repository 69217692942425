import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsAutomaticBellTestField_bellOptions$key } from "./__generated__/BellOptionsAutomaticBellTestField_bellOptions.graphql";

export const bellOptionsAutomaticBellTestFieldId = () =>
  "bell-options-automatic-bell-test";

function BellOptionsAutomaticBellTestField() {
  const [{ automaticBellTest }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsAutomaticBellTestField_bellOptions$key>(
      graphql`
        fragment BellOptionsAutomaticBellTestField_bellOptions on BellOptions {
          automaticBellTest
        }
      `
    );

  const fieldId = bellOptionsAutomaticBellTestFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Automatic Bell Test"
      tooltip="When enabled, the Bell Output is turned on for 2 seconds when all Areas are Armed from a Keypad."
    >
      <Switch
        id={fieldId}
        label="Automatic Bell Test"
        checked={automaticBellTest}
        onChange={() => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(!automaticBellTest, "automaticBellTest");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsAutomaticBellTestField;
