/**
 * @generated SignedSource<<07c75885aadf92d577300ed25ad557d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RecorderAddModalQuery$variables = {
  systemId: string;
};
export type RecorderAddModalQuery$data = {
  readonly node: {
    readonly __typename: string;
    readonly id: string;
    readonly name?: string;
    readonly varHubs?: ReadonlyArray<{
      readonly id: string;
      readonly isOnline: boolean;
      readonly panelConnectionEnabled: boolean | null;
    }>;
  } | null;
};
export type RecorderAddModalQuery = {
  response: RecorderAddModalQuery$data;
  variables: RecorderAddModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "systemId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VarHub",
            "kind": "LinkedField",
            "name": "varHubs",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOnline",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "panelConnectionEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "ControlSystem",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderAddModalQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderAddModalQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "dd3aa1111db4652c281c2564f93ddc62",
    "id": null,
    "metadata": {},
    "name": "RecorderAddModalQuery",
    "operationKind": "query",
    "text": "query RecorderAddModalQuery(\n  $systemId: ID!\n) {\n  node(id: $systemId) {\n    id\n    __typename\n    ... on ControlSystem {\n      id\n      name\n      varHubs {\n        id\n        isOnline\n        panelConnectionEnabled\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0846875cac24cb854c3ec72fd4139c22";

export default node;
