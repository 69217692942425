import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneSensorType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationLEDOperationField_zone$key } from "./__generated__/ZoneInformationLEDOperationField_zone.graphql";

export const zoneInformationLEDOperationFieldId = (number: string) =>
  `zone-information-led-operation-${number}`;

function ZoneInformationLEDOperationField() {
  const [
    { number, wireless, wirelessLedEnabled, sensorType },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationLEDOperationField_zone$key>(
    graphql`
      fragment ZoneInformationLEDOperationField_zone on Zone {
        number
        wireless
        wirelessLedEnabled
        sensorType
      }
    `
  );

  const fieldId = zoneInformationLEDOperationFieldId(String(number));
  const disabled = !wireless || sensorType !== ZoneSensorType.HOLD_UP;
  const label = "LED Operation";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Enable or Disable the LED during normal operation. The LED always operates if tampering is detected."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={wirelessLedEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!wirelessLedEnabled, "wirelessLedEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationLEDOperationField;
