import Icon from "components/Icon";
import * as React from "react";
import styled from "styled-components/macro";

function BigBlueAddButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <Root onClick={() => onClick()} disabled={disabled}>
      <Icon name="add" color="white" />
    </Root>
  );
}
const Root = styled.button`
  height: 3.2rem;
  width: 3.2rem;
  background-color: var(--color-info-500);
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  &:hover {
    background-color: #49b6d7;
    color: #ffffff;
    opacity: 0.8;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-transform: translateY(-0.01rem);
    -moz-transform: translateY(-0.01rem);
    -ms-transform: translateY(-0.01rem);
    -o-transform: translateY(-0.01rem);
    transform: translateY(-0.01rem);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &:focus {
    background: #268ead;
    color: #ffffff;
    box-shadow: none;
    -webkit-transform: translateY(0.01rem);
    -moz-transform: translateY(0.01rem);
    -ms-transform: translateY(0.01rem);
    -o-transform: translateY(0.01rem);
    transform: translateY(0.01rem);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &:disabled {
    background-color: var(--color-info-200);
    cursor: not-allowed;
    mouse-pointer-events: none;
  }
`;
export default BigBlueAddButton;
