/**
 * @generated SignedSource<<ce2f353d9fb4cb78cb83912de3d29a3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseIsInternational_panel$data = {
  readonly softwareVersion: string;
  readonly " $fragmentType": "PanelContextUseIsInternational_panel";
};
export type PanelContextUseIsInternational_panel$key = {
  readonly " $data"?: PanelContextUseIsInternational_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseIsInternational_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseIsInternational_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softwareVersion",
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "6206204934c4818a5f6e16a45b0275c9";

export default node;
