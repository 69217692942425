/**
 * @ngdoc service
 * @name App.factory:SendSemproMessageV2API
 *
 * @description
 * Queues up a job to have a remote panel send a SEMPro message to the specified location.
 *
 */
App.factory("SendSemproMessageV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/send_sempro_message",
      {},
      {
        sendSemproMessage: {
          method: "POST",
          params: { panel_id: "@panel_id" },
        },
      }
    );
  },
]);
