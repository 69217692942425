import { format } from "date-fns";
import React from "react";
import { DateFormatter, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import styled from "styled-components/macro";

const DatePickerCalendar = ({
  selectedDate,
  setSelectedDate,
  closeDatePickerModal,
}: {
  selectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  closeDatePickerModal?: () => void;
}) => {
  const formatDateMMDDToNormal = (date: string) => {
    return date ? new Date(date) : new Date();
  };

  //This is just to format the calendar to not show the year next to the month
  const formatCaption: DateFormatter = (month, options) => {
    return (
      <MonthCaption>
        {format(month, "LLLL", { locale: options?.locale })}
      </MonthCaption>
    );
  };

  return (
    <DayPickerContainer>
      <DayPicker
        mode="single"
        selected={formatDateMMDDToNormal(selectedDate)}
        onSelect={(date: Date | undefined) => {
          date && setSelectedDate(format(date, "MM/dd"));
          if (closeDatePickerModal) {
            closeDatePickerModal();
          }
        }}
        defaultMonth={
          selectedDate.length
            ? formatDateMMDDToNormal(selectedDate)
            : new Date()
        }
        formatters={{ formatCaption }}
      />
    </DayPickerContainer>
  );
};

export default DatePickerCalendar;

const DayPickerContainer = styled.div``;
const MonthCaption = styled.div``;
