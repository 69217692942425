/**
 * @generated SignedSource<<d3eb26738416a3bac4625af7527c9709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathType = "BACKUP" | "PRIMARY";
import { FragmentRefs } from "relay-runtime";
export type CommPathFailTestHoursField_communicationPath$data = {
  readonly failTestHours: number;
  readonly number: number;
  readonly type: CommunicationPathType;
  readonly " $fragmentType": "CommPathFailTestHoursField_communicationPath";
};
export type CommPathFailTestHoursField_communicationPath$key = {
  readonly " $data"?: CommPathFailTestHoursField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathFailTestHoursField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathFailTestHoursField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTestHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "5b81e998546d711a178c3edaaa775692";

export default node;
