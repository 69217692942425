import noop from "common/utils/universal/noop";
import * as React from "react";

type InitialConnectContextType = {
  ensureConnectionReady: () => void;
};

const InitialConnectContext = React.createContext<InitialConnectContextType>({
  ensureConnectionReady: noop,
});

type AlertsContextProps = {
  children: React.ReactNode;
  InitialConnectionService: {
    ensureConnectionReady: () => void;
  };
};

export const InitialConnectContextProvider = ({
  children,
  InitialConnectionService,
}: AlertsContextProps) => {
  return (
    <InitialConnectContext.Provider
      value={React.useMemo(
        () => ({
          ensureConnectionReady: () =>
            InitialConnectionService.ensureConnectionReady(),
        }),

        [InitialConnectionService]
      )}
    >
      {children}
    </InitialConnectContext.Provider>
  );
};

export const useInitialConnectContext = () =>
  React.useContext(InitialConnectContext);

export const useEnsureConnectionReady = () =>
  useInitialConnectContext().ensureConnectionReady;
