/**
 * @generated SignedSource<<9bf27d43d349e05f5b034d692b1f806c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddCameraByRtspInput = {
  hubId: number;
  make: number;
  url: string;
};
export type AddCamerasManuallyByRtspSectionMutation$variables = {
  newCamera: AddCameraByRtspInput;
  systemId: string;
};
export type AddCamerasManuallyByRtspSectionMutation$data = {
  readonly addCameraByRtsp: {
    readonly __typename: "AddCameraByRtspErrorPayload";
    readonly type: string | null;
  } | {
    readonly __typename: "AddCameraByRtspSuccessPayload";
    readonly system: {
      readonly varHubs: ReadonlyArray<{
        readonly cameras: ReadonlyArray<{
          readonly addedToDB: boolean;
          readonly camectCamId: string;
          readonly camectHubId: string;
          readonly cameraId: number | null;
          readonly cameraName: string;
          readonly ipAddress: string;
          readonly isEnabled: boolean;
          readonly isStreaming: boolean;
          readonly macAddress: string;
          readonly needsCredential: boolean;
          readonly playerAuthToken: string;
          readonly playerUrl: string;
          readonly snapshot: string | null;
        }>;
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type AddCamerasManuallyByRtspSectionMutation = {
  response: AddCamerasManuallyByRtspSectionMutation$data;
  variables: AddCamerasManuallyByRtspSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newCamera"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "newCamera",
    "variableName": "newCamera"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedToDB",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectCamId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "camectHubId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cameraName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipAddress",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStreaming",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "needsCredential",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerAuthToken",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playerUrl",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "snapshot",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCamerasManuallyByRtspSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addCameraByRtsp",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHub",
                    "kind": "LinkedField",
                    "name": "varHubs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VarHubCamera",
                        "kind": "LinkedField",
                        "name": "cameras",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "type": "AddCameraByRtspSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v17/*: any*/),
              (v16/*: any*/)
            ],
            "type": "AddCameraByRtspErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddCamerasManuallyByRtspSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addCameraByRtsp",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VarHub",
                    "kind": "LinkedField",
                    "name": "varHubs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VarHubCamera",
                        "kind": "LinkedField",
                        "name": "cameras",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "AddCameraByRtspSuccessPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v17/*: any*/)
            ],
            "type": "AddCameraByRtspErrorPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1190fa8f009aeff2d87a8f89663feaad",
    "id": null,
    "metadata": {},
    "name": "AddCamerasManuallyByRtspSectionMutation",
    "operationKind": "mutation",
    "text": "mutation AddCamerasManuallyByRtspSectionMutation(\n  $systemId: String!\n  $newCamera: AddCameraByRtspInput!\n) {\n  addCameraByRtsp(systemId: $systemId, newCamera: $newCamera) {\n    __typename\n    ... on AddCameraByRtspSuccessPayload {\n      system {\n        varHubs {\n          cameras {\n            addedToDB\n            camectCamId\n            camectHubId\n            cameraId\n            cameraName\n            ipAddress\n            isEnabled\n            isStreaming\n            macAddress\n            needsCredential\n            playerAuthToken\n            playerUrl\n            snapshot\n            id\n          }\n          id\n        }\n        id\n      }\n      __typename\n    }\n    ... on AddCameraByRtspErrorPayload {\n      type\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c0ca682f9c7a378da7367e4aa623548";

export default node;
