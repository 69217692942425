import * as React from "react";

const HasChangesContext = React.createContext<
  [boolean, (hasChanges: boolean) => void]
>([false, () => {}]);

export function HasChangesContextProvider(props: {
  hasChanges: boolean;
  setHasChanges: (hasChanges: boolean) => void;
  children: React.ReactNode;
}) {
  const { hasChanges, setHasChanges, children } = props;
  return (
    <HasChangesContext.Provider
      value={React.useMemo(
        () => [hasChanges, setHasChanges],
        [hasChanges, setHasChanges]
      )}
    >
      {children}
    </HasChangesContext.Provider>
  );
}

export const useHasChanges = () => {
  const [hasChanges] = React.useContext(HasChangesContext);
  return hasChanges;
};

export const useSetHasChanges = () => {
  const [, setHasChanges] = React.useContext(HasChangesContext);
  return setHasChanges;
};
