/**
 * @generated SignedSource<<719a3423731ec443a2a5cb4ff456a0b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TwoFASetupUserQuery$variables = {};
export type TwoFASetupUserQuery$data = {
  readonly myPerson: {
    readonly " $fragmentSpreads": FragmentRefs<"TwoFASetup_person">;
  } | null;
};
export type TwoFASetupUserQuery = {
  response: TwoFASetupUserQuery$data;
  variables: TwoFASetupUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TwoFASetupUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "myPerson",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TwoFASetup_person"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TwoFASetupUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "myPerson",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isPerson"
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "appUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TwoFactorSettings",
                "kind": "LinkedField",
                "name": "twoFactorSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requiredByDealer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "validationMethod",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae22689459af0ac1f232b15883188474",
    "id": null,
    "metadata": {},
    "name": "TwoFASetupUserQuery",
    "operationKind": "query",
    "text": "query TwoFASetupUserQuery {\n  myPerson {\n    __typename\n    ...TwoFASetup_person\n    id\n  }\n}\n\nfragment TwoFASetup_person on Person {\n  __isPerson: __typename\n  id\n  emailAddress\n  appUser {\n    __typename\n    twoFactorSettings {\n      requiredByDealer\n      enabled\n      validationMethod\n      phoneNumber\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "956c3b785340ed2c21ea5d1c73cbb99b";

export default node;
