/**
 *
 * AwayIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const AwayIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M47.315,21.814c0.362-0.909,0.161-1.945-0.515-2.652l-3.222-3.372c-0.069-0.072-0.143-0.141-0.22-0.204
      c-0.477-0.392-1.926-1.583-11.556-1.583c-8.42,0-10.42,0.86-11.212,1.979l-3.056,3.173c-0.678,0.704-0.884,1.739-0.526,2.649
      c0.358,0.91,1.213,1.528,2.189,1.581c0.339,0.019,8.338,0.455,12.604,0.455c4.261,0,12.949-0.436,13.316-0.454
      C46.095,23.337,46.954,22.723,47.315,21.814z"
        />
        <path
          d="M11.392,32.724c-1.706,0-2.325,1.257-2.323,2.186l0.1,2.36c0,1.557,1.249,2.73,2.905,2.73h4.25
      C18.297,40,19,38.68,19,37.444v-1.146C19,33.215,11.468,32.724,11.392,32.724z"
        />
        <path
          d="M52.973,32.724c-0.07,0-6.973,0.604-6.973,3.574v1.026c-0.243,0.883,0.085,1.516,0.308,1.82
      C46.937,40,47.889,40,48.202,40h3.814c1.593,0,2.81-1.085,2.901-2.553l0.325-2.346l0.019-0.275
      C55.261,33.781,54.475,32.724,52.973,32.724z"
        />
        <path
          d="M38.859,33H25.907C24.168,33,23,34.65,23,36.191v1.082C23,38.854,24.223,40,25.907,40h12.952
      C40.679,40,42,38.854,42,37.273v-1.082C42,34.551,40.474,33,38.859,33z"
        />
        <path
          d="M62.45,28.413c-0.241-1.047-0.66-1.971-1.144-2.763C62.964,24.83,64,22.622,64,19.652C64,15.673,62.223,13,59.579,13
      H54.85c-0.85,0-1.817,0.554-2.569,1.324l-3.038-4.284C49.1,9.84,48.861,9.627,48.657,9.491C46.694,8.188,41.931,6,32.079,6h-0.129
      c-9.855,0-14.625,2.187-16.633,3.519c-0.212,0.138-0.396,0.314-0.542,0.52l-3.046,4.284C11.003,13.562,10.066,13,9.179,13H4.45
      C1.788,13,0,15.672,0,19.648c0,2.975,1.045,5.186,2.718,6.005c-0.483,0.791-0.902,1.714-1.142,2.76
      C0.934,31.204,1.296,36.9,1.532,39.71c-0.797,0.642-1.291,1.676-1.321,2.667c-0.003,0.099,0.001,0.199,0.013,0.297
      c0.098,0.819,0.191,1.635,0.284,2.451c0.103,0.901,0.206,1.803,0.314,2.708c0.031,0.742,0.356,1.487,0.91,2.071
      C2.096,50.288,2.534,50.577,3,50.751v3.168C3,55.618,4.351,57,6.012,57h8.01C15.692,57,17,55.646,17,53.919V51h30v2.919
      C47,55.618,48.349,57,50.006,57h8.01C59.689,57,61,55.646,61,53.919v-3.168c0.458-0.169,0.883-0.452,1.244-0.833
      c0.557-0.589,0.883-1.345,0.913-2.1l0.623-5.168c0.012-0.098,0.016-0.197,0.013-0.296c-0.029-0.991-0.512-2.025-1.307-2.672
      C62.719,36.913,63.09,31.2,62.45,28.413z M55.995,25.667c0.02,0.014,2.043,1.409,2.557,3.642c0.542,2.361,0.105,8.776-0.19,11.513
      c-0.062,0.576,0.128,1.151,0.522,1.576c0.23,0.249,0.518,0.432,0.832,0.537l-0.49,4.067c-0.707,0.115-1.14,0.347-1.359,0.497
      C57.324,47.872,57,48.488,57,49.146V53h-6v-4c0-1.104-0.896-2-2-2H15c-1.104,0-2,0.896-2,2v4H7v-3.854
      c0-0.65-0.316-1.26-0.848-1.635c-0.265-0.187-0.706-0.405-1.401-0.511c-0.093-0.777-0.181-1.553-0.269-2.328
      c-0.062-0.545-0.125-1.09-0.188-1.636c0.319-0.104,0.61-0.289,0.844-0.54c0.394-0.425,0.585-1,0.522-1.576
      c-0.286-2.648-0.73-9.249-0.186-11.613c0.511-2.225,2.548-3.635,2.559-3.642c0.736-0.488,1.065-1.399,0.811-2.244
      C8.589,22.578,7.811,22,6.929,22H4.473c-0.217-0.344-0.518-1.312-0.467-2.646C4.053,18.103,4.388,17.266,4.601,17h4.141
      C8.831,17.084,8.927,17.189,9,17.282v1.515c0,0.87,0.562,1.641,1.392,1.905c0.828,0.264,1.734-0.036,2.238-0.747l5.179-7.283
      C19.308,11.761,23.324,10,31.949,10h0.129c8.508,0,12.524,1.714,14.13,2.677l5.16,7.276c0.504,0.71,1.411,1.012,2.238,0.749
      C54.437,20.438,55,19.667,55,18.797v-1.503c0.078-0.097,0.182-0.208,0.276-0.293h4.134c0.211,0.276,0.538,1.117,0.584,2.362
      c0.049,1.32-0.243,2.286-0.457,2.638H57.1c-0.882,0-1.66,0.578-1.915,1.422C54.931,24.267,55.259,25.179,55.995,25.667z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default AwayIcon;
