import Icon from "components/Icon";
import React from "react";
import styled from "styled-components";

// both uses of this link are using the same url for now.
export const centralStationVideoHref = "https://vimeo.com/653780091";

const VideoAnchor = styled.a`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
  font-weight: var(--font-weight-medium);
  color: var(--color-info-500);
  &:hover {
    text-decoration: none;
  }
`;

const CentralStationVideoLink: React.FC<
  React.PropsWithChildren<{ link: string }>
> = ({ link }) => {
  return (
    <VideoAnchor href={link} target="blank">
      <Icon name="clips_with_play" className="mar-r-8" color="black" />
      Watch this video to learn more.
    </VideoAnchor>
  );
};

export default CentralStationVideoLink;
