import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsScheduleChangeReportsField_systemReports$key } from "./__generated__/SystemReportsScheduleChangeReportsField_systemReports.graphql";

export const systemReportsScheduleChangeReportsFieldId = () =>
  "system-reports-schedule-change-reports";

function SystemReportsScheduleChangeReportsField() {
  const [{ scheduleChangeReports }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsScheduleChangeReportsField_systemReports$key>(
      graphql`
        fragment SystemReportsScheduleChangeReportsField_systemReports on SystemReports {
          scheduleChangeReports
        }
      `
    );

  const fieldId = systemReportsScheduleChangeReportsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Schedule Change Reports"
      tooltip="Enables the System to send schedule changes to the receiver if programmed in Communication Programming."
    >
      <Switch
        label="Schedule Change Reports"
        id={fieldId}
        name={fieldId}
        checked={scheduleChangeReports}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(
              !scheduleChangeReports,
              "scheduleChangeReports"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsScheduleChangeReportsField;
