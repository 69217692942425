/**
 * @generated SignedSource<<8acd62aa5db7b6338eb828205f86510f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type XTRemoteOptionsProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly remoteOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions" | "RemoteOptionsAppKeyField_remoteOptions" | "RemoteOptionsArmedAnswerRingsField_remoteOptions" | "RemoteOptionsContext_remoteOptions" | "RemoteOptionsDisarmedAnswerRingsField_remoteOptions" | "RemoteOptionsRemoteDisarmField_remoteOptions" | "RemoteOptionsRemoteKeyField_remoteOptions" | "RemoteOptionsServiceReceiverAuthorizationField_remoteOptions">;
    } | null;
    readonly softwareVersion: string;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel">;
  };
  readonly " $fragmentType": "XTRemoteOptionsProgrammingConceptForm_controlSystem";
};
export type XTRemoteOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XTRemoteOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTRemoteOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTRemoteOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "remoteOptions",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsContext_remoteOptions"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsRemoteKeyField_remoteOptions"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsRemoteDisarmField_remoteOptions"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsArmedAnswerRingsField_remoteOptions"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsDisarmedAnswerRingsField_remoteOptions"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsServiceReceiverAuthorizationField_remoteOptions"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RemoteOptionsAppKeyField_remoteOptions"
                }
              ],
              "type": "XtRemoteOptions",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "2c6d35bd897d9d227f5b7e740500a891";

export default node;
