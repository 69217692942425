/**
 * @ngdoc service
 * @name App.factory:SimManagementV1API
 *
 * @description
 * API factory for finding existing, activating, modifying, deactivating, and returning history/logs SIMS.
 *
 */
App.factory("SimManagementV1API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.simsUrl +
        "/api/v1/dealers/:dealer_id/sims/:find/:activate/:modify/:deactivate/:history/:log/:sim_id",
      {},
      {
        activate: {
          method: "POST",
          params: { activate: "activate", dealer_id: "@dealer_id" },
          headers: { Accept: "application/json" },
        }, //if (*/*) header is passed to the GET, the call will fail
        modify: {
          method: "POST",
          params: { modify: "modify", dealer_id: "@dealer_id" },
          headers: { Accept: "application/json" },
        }, //if (*/*) header is passed to the GET, the call will fail
        deactivate: {
          method: "POST",
          params: { deactivate: "deactivate", dealer_id: "@dealer_id" },
          headers: { Accept: "application/json" },
        }, //if (*/*) header is passed to the GET, the call will fail
        //find_by_identifier: {method: 'GET',params: {find: 'find_by_identifier', sim_id: '@sim_id'}, headers: {Accept: 'application/json'}},//deprecated
        history: {
          method: "GET",
          params: {
            history: "history",
            key: "@key",
            identifier: "@meid",
            dealer_id: "@dealer_id",
          },
          headers: { Accept: "application/json" },
        },
        log: {
          method: "GET",
          params: {
            log: "log",
            key: "@key",
            id: "@id",
            dealer_id: "@dealer_id",
          },
          isArray: true,
          headers: { Accept: "application/json" },
        },
      }
    );
  },
]);
/**
 * @ngdoc service
 * @name App.factory:SimManagementV2API
 *
 * @description
 * API factory for finding existing SIMS.
 *
 */
App.factory("SimManagementV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.simsUrl + "/api/v1/dealers/:dealer_id/sims/:search",
      {},
      {
        search: {
          method: "GET",
          params: {
            search: "search",
            sim_id: "@sim_id",
            dealer_id: "@dealer_id",
          },
          headers: { Accept: "application/json" },
        }, //if (*/*) header is passed to the GET, the call will fail
      }
    );
  },
]);

/**
 * @ngdoc service
 * @name App.factory:SimManagementV2API
 *
 * @description
 * API factory for finding existing SIMS.
 *
 */
App.factory("ScheduledServiceAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.scheduledServiceUrl +
        "/api/v1/ScheduledService/:dealer_code/wireless/:panel_id/:search/:sim_id/:activate",
      {},
      {
        search: {
          method: "GET",
          params: {
            activate: "activate",
            panel_id: "@panel_id",
            dealer_code: "@dealer_code",
          },
          headers: { Accept: "application/json" },
        }, //if (*/*) header is passed to the GET, the call will fail
        activate: {
          method: "POST",
          params: {
            dealer_code: "@dealer_code",
            panel_id: "@panel_id",
            activate: "activate",
          },
          headers: { Accept: "application/json" },
        },
        deactivate: {
          method: "DELETE",
          params: {
            dealer_code: "@dealer_code",
            panel_id: "@panel_id",
            activate: "activate",
          },
          headers: { Accept: "application/json" },
        },
      }
    );
  },
]);
