App.controller("CommunicationPathsCtrl", [
  "$scope",
  "$q",
  "$modal",
  "$rootScope",
  "PANEL_CONCEPTS",
  function ($scope, $q, $modal, $rootScope, PANEL_CONCEPTS) {
    $scope.commPathMeta = [];
    $scope.commTypeModalOpen = false;

    var watchListeners = [];
    var MAX_COMM_PATHS = 8; // TODO: This should probably be dynamically set. For now it's always 8
    var commPathModal = {};
    var networkPaths = ["W", "N"];
    var commPathNumbers = [];

    // Create a new communication path and update our meta data array used for saving
    $scope.createNewCommPath = function () {
      // Create an array of empty objects used for tracking comm path properties
      // that are tied to the path number and not the path object (ex. isNew flag)
      if (
        !angular.isDefined($scope.commPathMeta) ||
        $scope.commPathMeta.length != MAX_COMM_PATHS
      ) {
        $scope.commPathMeta = [];
        for (var i = 0; i < MAX_COMM_PATHS; i++) {
          $scope.commPathMeta.push({});
        }
      }

      // Create the new comm path
      $scope.Panel.newItem("communication_paths")
        .then(function (item) {
          // Successfully created a new item. Update our comm path meta
          $scope.commPathMeta[item.number - 1].isNew = true;
          refreshWatchers();
          $scope.toggleAccordion(item);
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    // Drag-and-Drop move complete event handler
    $scope.reorderCommPaths = function (index, item, external, type) {
      // If the item we're moving is new, keep track of the isNew index for later use
      if (item.isNew) {
        delete item.isNew;
      }
      return item;
    };

    // Drag-and-Drop item moved event handler
    $scope.commPathMoved = function (index) {
      $scope.Panel.communication_paths.splice(index, 1);
    };

    $scope.commPathSelected = function () {};

    // We're beginning a drag/drop event. Prepare for updating comm path numbers
    $scope.commPathDragStart = function () {
      // Store an array of all the comm path numbers that we'll use on drag end to update comm path numbers
      commPathNumbers = [];
      $scope.Panel.communication_paths.forEach(function (commPath) {
        commPathNumbers.push(commPath.number);
        $scope.id = "#draggable-" + commPath.number;
        angular.element(document.querySelector($scope.id)).removeClass("test");
      });
    };

    // Drag-and-Drop drag end event handler
    $scope.commPathDragEnd = function (item) {
      angular.forEach($scope.Panel.communication_paths, function (item, index) {
        angular.extend(item, $scope.commPathMeta[index]);
        item.number = commPathNumbers[index];
        // Make sure that comm path 1 is set to primary
        if (parseInt(item.number) === 1) {
          item.path_type = "P";
        }
      });
      refreshWatchers();

      $scope.id = "#draggable-" + item.number;

      // need new index #
      angular.element(document.querySelector($scope.id)).addClass("test");
    };

    $scope.sortCommPaths = function (commPath) {
      return angular.isDefined(commPath)
        ? parseInt(commPath.number)
        : undefined;
    };

    // Deletes the specified communication path and updates the comm path meta
    $scope.destroyPath = function (item) {
      // Delete the comm path
      var index = $scope.Panel.communication_paths.indexOf(item);
      $scope.Panel.deleteItem("communication_paths", item)
        .then(function () {
          if (!item.isNew) {
            // Successfully deleted the comm path. Reset comm path meta for this path number
            refreshWatchers();
            if (
              angular.isDefined($scope.commPathMeta) &&
              angular.isDefined($scope.commPathMeta[index])
            ) {
              $scope.commPathMeta[index] = {};
            }
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.refreshCommPaths = function () {
      $scope.Panel.refresh("communication_paths")
        .then(
          function () {
            // Successfully refreshed comm paths. Reset our meta data
            $scope.commPathMeta = [];
            refreshWatchers();
          },
          function (error) {
            console.error(
              "CommunicationPathsCtrl->refreshCommPaths() - error: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    // Save the list of communication paths
    $scope.saveCommPaths = function (forceSend) {
      let suppressToast = true;
      $scope.Panel.sendProgramming(
        "communication_paths",
        suppressToast,
        forceSend
      )
        .then(
          function () {
            // Successfully refreshed comm paths. Reset comm path meta
            $scope.commPathMeta = [];
            refreshWatchers();
          },
          function (error) {
            console.error(
              "CommunicationPathsCtrl->saveCommPaths() - Error: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.validateCommPath = function (commPath, newValue, oldValue) {
      // Only continue if our new path is network or wifi and the modal is not already being displayed
      if (!networkPaths.includes(newValue) || newValue === oldValue) {
        return;
      }
      if ($scope.commTypeModalOpen) {
        return;
      }
      var willOverride = false;

      // Check to see if the current selection will override any existing path comm types
      if (newValue == "N" || newValue == "W") {
        // Loop through all existing comm paths looking for a value that would be overridden
        angular.forEach($scope.Panel.communication_paths, function (path) {
          if (
            (path.comm_type == "N" || path.comm_type == "W") &&
            path.comm_type != newValue
          ) {
            willOverride = true;
          }
        });
      }

      if (willOverride) {
        $scope.openCommPathModal(commPath, newValue, oldValue);
      }
    };

    // Collapse all other accordions when expanding one. Necessary since drag and drop requires wrapping content in a ul
    $scope.toggleAccordion = function (commPath) {
      if (commPath.isOpen === true) {
        $scope.Panel.communication_paths.forEach(function (path) {
          if (path !== commPath) {
            path.isOpen = false;
          }
        });
      }
    };

    /**
     * Creates and opens a modal to display a comm path warning if the selection will overwrite existing settings
     **/
    $scope.openCommPathModal = function (commPath, newCommType, oldCommType) {
      $scope.commTypeModalOpen = true;
      commPathModal = $modal.open({
        templateUrl: "changeCommTypeModal.html",
        controller: "changeCommTypeModalCtrl",
        size: "md",
        backdrop: "static",
        scope: $scope,
        resolve: {
          commPath: function () {
            return commPath;
          },
          newCommType: function () {
            return newCommType;
          },
          oldCommType: function () {
            return oldCommType;
          },
        },
      });
      commPathModal.result
        .then(
          function (reason) {
            $scope.commTypeModalOpen = false;
          },
          function () {
            $scope.commTypeModalOpen = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Loop through the communication paths and setup necessary watches
     */
    function setupWatchers() {
      angular.forEach($scope.Panel.communication_paths, function (commPath) {
        watchListeners.push(
          $scope.$watch(
            function () {
              return commPath.comm_type;
            },
            function (newValue, oldValue) {
              $scope.validateCommPath(commPath, newValue, oldValue);
            }
          )
        );
      });
    }

    function clearWatchers() {
      for (var i = 0; i < watchListeners.length; i++) {
        listener = watchListeners[i];
        listener();
      }
    }

    function refreshWatchers() {
      clearWatchers();
      setupWatchers();
    }

    function init() {
      refreshWatchers();
    }

    $scope.$on("all_concepts_retrieved", function () {
      init();
    });

    init();
  },
]);

/**
 * A controller specifically for confirm initial connect
 */
App.controller(
  "changeCommTypeModalCtrl",
  function ($scope, $modalInstance, commPath, newCommType, oldCommType) {
    $scope.display = {};
    $scope.display.newCommType = newCommType;

    /**
     * Loop through all existing comm paths and update any conflicting comm types
     */
    $scope.updateCommType = function () {
      // Loop through all existing comm paths looking for a value that would be overridden
      angular.forEach($scope.Panel.communication_paths, function (path) {
        if (
          (path.comm_type == "N" || path.comm_type == "W") &&
          path.comm_type != newCommType
        ) {
          path.comm_type = newCommType;
          if (newCommType === "W") path.ipv6 = "N";
        }
      });

      // Close the modal
      $modalInstance.close("update");
    };

    /**
     * Get the inverse of the new comm type value
     * Used for display purposes
     * @returns {string}
     */
    $scope.inverseCommType = function () {
      return newCommType === "W" ? "N" : "W";
    };

    /**
     * Cancel pending changes
     */
    $scope.cancel = function () {
      // Revert pending changes
      commPath.comm_type = $scope.inverseCommType();
      $modalInstance.dismiss("cancel");
    };
  }
);
