import styled from "styled-components/macro";
import { focusRing } from "utils/styles";

const Button = styled.button<{ size?: "default" | "small" }>`
  appearance: none;
  padding: ${({ size }) =>
    size === "small" ? "0.8rem 1.2rem" : "1.2rem 1.6rem"};
  border-radius: 0.6rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: var(--bg-color);
  color: var(--text-color);
  font-size: ${({ size }) => (size ? "1.2rem" : "1.4rem")};
  font-weight: 500;
  line-height: ${({ size }) => (size ? "1.4rem" : "1.6rem")};
  white-space: nowrap;

  &:focus {
    ${focusRing};
  }

  &:not(:enabled) {
    cursor: not-allowed;
  }
`;

export default Button;
