import graphql from "babel-plugin-relay/macro";
import { sleep } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";

import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { fetchQuery, useFragment, useRefetchableFragment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { asString, ControlSystem, ID } from "securecom-graphql/client";
import { Concept } from "../common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../common/FullProgrammingVersionEntryPoint";
import * as AreaInformation from "./XRAreaInformationProgrammingConceptForm";
import * as BellOptions from "./XRBellOptionsProgrammingConceptForm";
import * as CardFormats from "./XRCardFormatsProgrammingConceptForm";
import * as Communication from "./XRCommunicationProgrammingConceptForm";
import * as DeviceSetup from "./XRDeviceSetupProgrammingConceptForm";
import * as FeatureKeys from "./XRFeatureKeysProgrammingConceptForm";
import * as KeyFobs from "./XRKeyFobsProgrammingConceptForm";
import * as LockoutCode from "./XRLockoutCodeProgrammingConceptForm";
import * as MenuDisplay from "./XRMenuDisplayProgrammingConceptForm";
import * as MessagingSetup from "./XRMessagingSetupProgrammingConceptForm";
import * as NetworkOptions from "./XRNetworkOptionsProgrammingConceptForm";
import * as OutputGroups from "./XROutputGroupsProgrammingConceptForm";
import * as OutputInformation from "./XROutputInformationProgrammingConceptForm";
import * as OutputOptions from "./XROutputOptionsProgrammingConceptForm";
import * as PcLogReports from "./XRPcLogReportsProgrammingConceptForm";
import * as RemoteOptions from "./XRRemoteOptionsProgrammingConceptForm";
import * as SecurityGrade from "./XRSecurityGradeProgrammingConceptForm";
import * as StatusListDisplay from "./XRStatusListDisplayProgrammingConceptForm";
import * as SystemOptions from "./XRSystemOptionsProgrammingConceptForm";
import * as SystemReports from "./XRSystemReportsProgrammingConceptForm";
import * as ZoneInformation from "./XRZoneInformationProgrammingConceptForm";
import { XRFullProgrammingContainer_controlSystem$key } from "./__generated__/XRFullProgrammingContainer_controlSystem.graphql";
import { XRFullProgrammingContainer_templateStatus_controlSystem$key } from "./__generated__/XRFullProgrammingContainer_templateStatus_controlSystem.graphql";
import { XRFullProgrammingContainer_templateStatus_controlSystemQuery } from "./__generated__/XRFullProgrammingContainer_templateStatus_controlSystemQuery.graphql";
import { XRFullProgrammingTemplateQuery } from "./__generated__/XRFullProgrammingTemplateQuery.graphql";

export const createConcepts = ({
  softwareVersion,
  supportsCustomCardFormats,
}: {
  softwareVersion: number;
  supportsCustomCardFormats: boolean;
}) =>
  [
    Communication,
    NetworkOptions,
    softwareVersion < 202 ? MessagingSetup : null,
    DeviceSetup,
    supportsCustomCardFormats ? CardFormats : null,
    RemoteOptions,
    SystemReports,
    SystemOptions,
    BellOptions,
    OutputInformation,
    OutputOptions,
    OutputGroups,
    MenuDisplay,
    StatusListDisplay,
    PcLogReports,
    AreaInformation,
    ZoneInformation,
    KeyFobs,
    LockoutCode,
    FeatureKeys,
    softwareVersion >= 607 ? SecurityGrade : null,
  ]
    .filter(isNotNullOrUndefined)
    .map(({ Form, ...rest }) => ({
      ...rest,
      Form: React.memo(Form),
    })) as Concept[];

export const xrFullProgrammingControlSystem = graphql`
  fragment XRFullProgramming_controlSystem on ControlSystem {
    id
    ...FullProgrammingForm_controlSystem
    ...ProgrammingTemplateForm_controlSystem
    ...ControlSystemContext_controlSystem
    ...XRCommunicationProgrammingConceptForm_controlSystem
    ...XRCommunicationProgrammingConceptFormNavButton_controlSystem
    ...XRNetworkOptionsProgrammingConceptForm_controlSystem
    ...XRDeviceSetupProgrammingConceptForm_controlSystem
    ...XRDeviceSetupProgrammingConceptFormNavButton_controlSystem
    ...XRCardFormatsProgrammingConceptForm_controlSystem
    ...XRCardFormatsProgrammingConceptFormNavButton_controlSystem
    ...XRRemoteOptionsProgrammingConceptForm_controlSystem
    ...XRSystemReportsProgrammingConceptForm_controlSystem
    ...XRSystemOptionsProgrammingConceptForm_controlSystem
    ...XRBellOptionsProgrammingConceptForm_controlSystem
    ...XROutputInformationProgrammingConceptForm_controlSystem
    ...XRPcLogReportsProgrammingConceptForm_controlSystem
    ...XROutputInformationProgrammingConceptFormNavButton_controlSystem
    ...XROutputOptionsProgrammingConceptForm_controlSystem
    ...XROutputGroupsProgrammingConceptForm_controlSystem
    ...XROutputGroupsProgrammingConceptFormNavButton_controlSystem
    ...XRMenuDisplayProgrammingConceptForm_controlSystem
    ...XRStatusListDisplayProgrammingConceptForm_controlSystem
    ...XRAreaInformationProgrammingConceptForm_controlSystem
    ...XRAreaInformationProgrammingConceptFormNavButton_controlSystem
    ...XRZoneInformationProgrammingConceptForm_controlSystem
    ...XRZoneInformationProgrammingConceptFormNavButton_controlSystem
    ...XRKeyFobsProgrammingConceptForm_controlSystem
    ...XRKeyFobsProgrammingConceptFormNavButton_controlSystem
    ...XRLockoutCodeProgrammingConceptForm_controlSystem
    ...XRFeatureKeysProgrammingConceptForm_controlSystem
    ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
    ...XRMessagingSetupProgrammingConceptForm_controlSystem
    ...XRSecurityGradeProgrammingConceptForm_controlSystem

    panel {
      ...PanelContextUseHasNetworkCommType_panel
      ...PanelContextUseHasWifiCommType_panel
    }
  }
`;

export const xrFullProgrammingInlineControlSystem = graphql`
  fragment XRFullProgrammingInline_controlSystem on ControlSystem
  @refetchable(queryName: "XRFullProgrammingInlineQuery") {
    ...XRCommunicationProgrammingConceptFormInline_controlSystem
    ...XRNetworkOptionsProgrammingConceptFormInline_controlSystem
    ...XRDeviceSetupProgrammingConceptFormInline_controlSystem
    ...XRCardFormatsProgrammingConceptFormInline_controlSystem
    ...XRRemoteOptionsProgrammingConceptFormInline_controlSystem
    ...XRSystemReportsProgrammingConceptFormInline_controlSystem
    ...XRSystemOptionsProgrammingConceptFormInline_controlSystem
    ...XRBellOptionsProgrammingConceptFormInline_controlSystem
    ...XROutputInformationProgrammingConceptFormInline_controlSystem
    ...XROutputOptionsProgrammingConceptFormInline_controlSystem
    ...XROutputGroupsProgrammingConceptFormInline_controlSystem
    ...XRMenuDisplayProgrammingConceptFormInline_controlSystem
    ...XRStatusListDisplayProgrammingConceptFormInline_controlSystem
    ...XRAreaInformationProgrammingConceptFormInline_controlSystem
    ...XRZoneInformationProgrammingConceptFormInline_controlSystem
    ...XRKeyFobsProgrammingConceptFormInline_controlSystem
    ...XRLockoutCodeProgrammingConceptFormInline_controlSystem
    ...XRPcLogReportsProgrammingConceptForm_controlSystem
    ...XRFeatureKeysProgrammingConceptFormInline_controlSystem
    ...XRMessagingSetupProgrammingConceptFormInline_controlSystem
    ...XRSecurityGradeProgrammingConceptFormInline_controlSystem
    ...XRPcLogReportsProgrammingConceptFormInline_controlSystem
  }
`;

function XRFullProgrammingContainer(props: {
  dealerId: ID;
  systemId: string;
  controlSystem: XRFullProgrammingContainer_controlSystem$key;
}) {
  const controlSystemFragment = useFragment(
    graphql`
      fragment XRFullProgrammingContainer_controlSystem on ControlSystem {
        name
        panel {
          softwareVersion
          supportsCustomCardFormats
        }
        ...XRFullProgrammingContainer_templateStatus_controlSystem
      }
    `,
    props.controlSystem
  );
  const { name, panel } = controlSystemFragment;
  const softwareVersion = Number(panel?.softwareVersion);
  const { supportsCustomCardFormats } = panel;
  const concepts = React.useMemo(
    () => createConcepts({ softwareVersion, supportsCustomCardFormats }),
    [softwareVersion, supportsCustomCardFormats]
  );

  const [{ templatePreProgrammingJobStatus }, refetchJobStatus] =
    useRefetchableFragment<
      XRFullProgrammingContainer_templateStatus_controlSystemQuery,
      XRFullProgrammingContainer_templateStatus_controlSystem$key
    >(
      graphql`
        fragment XRFullProgrammingContainer_templateStatus_controlSystem on ControlSystem
        @refetchable(
          queryName: "XRFullProgrammingContainer_templateStatus_controlSystemQuery"
        ) {
          templatePreProgrammingJobStatus
        }
      `,
      controlSystemFragment
    );

  const jobPending = [
    "new",
    "created",
    "running",
    "acquired",
    "started",
  ].includes(templatePreProgrammingJobStatus ?? "");

  React.useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (jobPending) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetchJobStatus({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 5000));
        }, 5000)
      );
    }

    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [refetchJobStatus, jobPending]);

  return jobPending ? (
    <GenericPageFallback message={"Applying Template Programming..."} />
  ) : (
    <FullProgrammingVersionEntryPoint
      systemName={name}
      concepts={concepts}
      gqlQuery={graphql`
        query XRFullProgrammingQuery($dealerId: ID!, $systemId: ID!) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: node(id: $systemId) {
            ... on ControlSystem {
              id
              name
              templatePreProgrammingJobStatus
              panel {
                hardwareModel
              }
              customer {
                id
              }
              ...XRFullProgramming_controlSystem
              ...NetworkOptionsDhcpEnabledField_controlSystem
              ...XRAreaInformationProgrammingConceptFormOriginalAreas_controlSystem
              ...XRSystemOptionsProgrammingConceptFormInline_controlSystem
              ...XRNetworkOptionsProgrammingConceptFormInline_controlSystem
              ...XRRemoteOptionsProgrammingConceptFormInline_controlSystem
              ...XRSystemReportsProgrammingConceptFormInline_controlSystem
              ...XROutputOptionsProgrammingConceptFormInline_controlSystem
              ...XRMenuDisplayProgrammingConceptFormInline_controlSystem
              ...XRStatusListDisplayProgrammingConceptFormInline_controlSystem
              ...XRPcLogReportsProgrammingConceptFormInline_controlSystem
              ...XRLockoutCodeProgrammingConceptFormInline_controlSystem
              ...XRFeatureKeysProgrammingConceptFormInline_controlSystem
              ...XRMessagingSetupProgrammingConceptFormInline_controlSystem
              ...XRSecurityGradeProgrammingConceptFormInline_controlSystem
              ...XRBellOptionsProgrammingConceptFormInline_controlSystem
              ...XRZoneInformationProgrammingConceptFormInline_controlSystem
              ...XRCommunicationProgrammingConceptFormInline_controlSystem
              ...XRDeviceSetupProgrammingConceptFormInline_controlSystem
              ...XRCardFormatsProgrammingConceptFormInline_controlSystem
              ...XROutputInformationProgrammingConceptFormInline_controlSystem
              ...XROutputGroupsProgrammingConceptFormInline_controlSystem
              ...XRAreaInformationProgrammingConceptFormInline_controlSystem
              ...XRKeyFobsProgrammingConceptFormInline_controlSystem
            }
          }
          controlSystemInline: node(id: $systemId) {
            ... on ControlSystem {
              id
              ...XRFullProgrammingInline_controlSystem
            }
          }
        }
      `}
      gqlQueryVariables={{
        systemId: props.systemId,
        dealerId: asString(props.dealerId),
      }}
      gqlFormControlSystemFragment={xrFullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={xrFullProgrammingInlineControlSystem}
      applyTemplate={applyTemplate(props.systemId, concepts)}
    />
  );
}

export default XRFullProgrammingContainer;

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<XRFullProgrammingTemplateQuery>(
    environment,
    graphql`
      query XRFullProgrammingTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on XrProgrammingTemplateConcepts {
                systemOptions {
                  included
                  systemType {
                    included
                    data
                  }
                }
                ...XRCommunicationProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRNetworkOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRCardFormatsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRSystemReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRSystemOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XROutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XROutputOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XROutputGroupsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRStatusListDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRPcLogReportsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRZoneInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRKeyFobsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRLockoutCodeProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XRSecurityGradeProgrammingConceptFormInline_xrProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      if (
        data.concepts?.systemOptions?.included &&
        data.concepts?.systemOptions?.systemType?.included
      ) {
        environment.commitUpdate((store) => {
          const controlSystem =
            store.get<ControlSystem>(systemId) ??
            (store.create(
              systemId,
              "ControlSystem"
            ) as RecordProxy<ControlSystem>);
          if (
            controlSystem
              .getLinkedRecord("systemOptions")
              ?.getValue("systemType") !==
            data.concepts?.systemOptions?.systemType?.data
          ) {
            concepts.forEach((concept) => {
              if (concept.applyTemplateData) {
                if (concept.conceptId === "xr-system-options") {
                  concept.applyTemplateData(
                    data.concepts,
                    controlSystem,
                    store
                  );
                }
              }
            });
          }
        });
        await sleep(50);
      }
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
