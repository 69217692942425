import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useSetHasChanges } from "contexts/HasChangesContext";
import { prop, range } from "ramda";
import * as React from "react";
import { useMemo } from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";
import {
  DeviceInformationType,
  FeatureKeyEnum,
  PanelHardwareModel,
  ZoneRemoteZoneType,
} from "securecom-graphql/client";
import { resolvePanelType } from "../utils/panel";
import { getKeyfobNumberRange } from "./KeyfobFields/utils";
import {
  getWirelessOnlyZoneNumberRange,
  getWirelessZoneNumberRange,
  getZoneNumberRange,
} from "./ZoneInformationFields/utils";
import { PanelContextGetAllDeviceIds_panel$key } from "./__generated__/PanelContextGetAllDeviceIds_panel.graphql";
import { PanelContextHelpFiles_panel$key } from "./__generated__/PanelContextHelpFiles_panel.graphql";
import { PanelContextUseAreaList_panel$key } from "./__generated__/PanelContextUseAreaList_panel.graphql";
import { PanelContextUseBuiltIn1100WirelessEnabled_panel$key } from "./__generated__/PanelContextUseBuiltIn1100WirelessEnabled_panel.graphql";
import { PanelContextUseCommPaths_panel$key } from "./__generated__/PanelContextUseCommPaths_panel.graphql";
import { PanelContextUseDeviceNumberRange_panel$key } from "./__generated__/PanelContextUseDeviceNumberRange_panel.graphql";
import { PanelContextUseFeatureKeyEnabled_panel$key } from "./__generated__/PanelContextUseFeatureKeyEnabled_panel.graphql";
import { PanelContextUseHardwareModel_panel$key } from "./__generated__/PanelContextUseHardwareModel_panel.graphql";
import { PanelContextUseHas1100T_panel$key } from "./__generated__/PanelContextUseHas1100T_panel.graphql";
import { PanelContextUseHasNetworkCommType_panel$key } from "./__generated__/PanelContextUseHasNetworkCommType_panel.graphql";
import { PanelContextUseHasVplexDevices_panel$key } from "./__generated__/PanelContextUseHasVplexDevices_panel.graphql";
import { PanelContextUseHasWifiCommType_panel$key } from "./__generated__/PanelContextUseHasWifiCommType_panel.graphql";
import { PanelContextUseKeyfobNumberRange_panel$key } from "./__generated__/PanelContextUseKeyfobNumberRange_panel.graphql";
import { PanelContextUseRemoteZoneList_panel$key } from "./__generated__/PanelContextUseRemoteZoneList_panel.graphql";
import { PanelContextUseSoftwareDate_panel$key } from "./__generated__/PanelContextUseSoftwareDate_panel.graphql";
import { PanelContextUseSoftwareVersion_panel$key } from "./__generated__/PanelContextUseSoftwareVersion_panel.graphql";
import { PanelContextUseSupports1100T_panel$key } from "./__generated__/PanelContextUseSupports1100T_panel.graphql";
import { PanelContextUseSupportsAxBus_panel$key } from "./__generated__/PanelContextUseSupportsAxBus_panel.graphql";
import { PanelContextUseSupportsCustomCardFormats_panel$key } from "./__generated__/PanelContextUseSupportsCustomCardFormats_panel.graphql";
import { PanelContextUseSupportsNetworkExpander_panel$key } from "./__generated__/PanelContextUseSupportsNetworkExpander_panel.graphql";
import { PanelContextUseSupportsPrivateDoor_panel$key } from "./__generated__/PanelContextUseSupportsPrivateDoor_panel.graphql";
import { PanelContextUseSupportsVplex_panel$key } from "./__generated__/PanelContextUseSupportsVplex_panel.graphql";
import { PanelContextUseSupportsXR550_panel$key } from "./__generated__/PanelContextUseSupportsXR550_panel.graphql";
import { PanelContextUseSupportsXVCamera_panel$key } from "./__generated__/PanelContextUseSupportsXVCamera_panel.graphql";
import { PanelContextUseSupportsZoneExpanderSerialNumber_panel$key } from "./__generated__/PanelContextUseSupportsZoneExpanderSerialNumber_panel.graphql";
import { PanelContextUseSystemOptionsSystemType_panel$key } from "./__generated__/PanelContextUseSystemOptionsSystemType_panel.graphql";
import { PanelContextUseUserList_panel$key } from "./__generated__/PanelContextUseUserList_panel.graphql";
import { PanelContextUseVplexNumbers_panel$key } from "./__generated__/PanelContextUseVplexNumbers_panel.graphql";
import { PanelContextUseWirelessZoneNumberRange_panel$key } from "./__generated__/PanelContextUseWirelessZoneNumberRange_panel.graphql";
import { PanelContextUseZoneIsWirelessOnly_panel$key } from "./__generated__/PanelContextUseZoneIsWirelessOnly_panel.graphql";
import { PanelContextUseZoneNumberRange_panel$key } from "./__generated__/PanelContextUseZoneNumberRange_panel.graphql";
import panelContextFragment, {
  PanelContext_panel$key,
} from "./__generated__/PanelContext_panel.graphql";

const PanelContext = React.createContext<any>({});

export function PanelContextProvider(props: {
  panel: any;
  children: React.ReactNode;
}) {
  return (
    <PanelContext.Provider value={props.panel}>
      {props.children}
    </PanelContext.Provider>
  );
}

export function usePanelFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const panel = React.useContext(PanelContext);

  const data = useFragment(fragmentInput, panel as TKey);

  const { id } = useFragment(
    graphql`
      fragment PanelContext_panel on Panel {
        id
      }
    `,
    panel as PanelContext_panel$key
  );

  const setHasChanges = useSetHasChanges();

  const update = (updater: (panel: RecordProxy<KeyTypeData<TKey>>) => void) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
    setHasChanges(true);
  };

  return [data, update] as const;
}

export function useSoftwareVersion() {
  const [data] = usePanelFragment<PanelContextUseSoftwareVersion_panel$key>(
    graphql`
      fragment PanelContextUseSoftwareVersion_panel on Panel {
        softwareVersion
      }
    `
  );
  return Number(data.softwareVersion);
}

export function useSupportsXVCamera() {
  const [data] = usePanelFragment<PanelContextUseSupportsXVCamera_panel$key>(
    graphql`
      fragment PanelContextUseSupportsXVCamera_panel on Panel {
        softwareVersion
        hardwareModel
      }
    `
  );
  return (
    Number(data.softwareVersion) >= 241 &&
    [
      PanelHardwareModel.XR150,
      PanelHardwareModel.XR550,
      PanelHardwareModel.XT75,
    ].includes(data.hardwareModel as PanelHardwareModel)
  );
}

export function useIsInternational() {
  const [data] = usePanelFragment<PanelContextUseSoftwareVersion_panel$key>(
    graphql`
      fragment PanelContextUseIsInternational_panel on Panel {
        softwareVersion
      }
    `
  );
  return Number(data.softwareVersion) > 600;
}

export function useHardwareModel() {
  const [data] = usePanelFragment<PanelContextUseHardwareModel_panel$key>(
    graphql`
      fragment PanelContextUseHardwareModel_panel on Panel {
        hardwareModel
      }
    `
  );
  return data.hardwareModel;
}

export function useSoftwareDate() {
  const [data] = usePanelFragment<PanelContextUseSoftwareDate_panel$key>(
    graphql`
      fragment PanelContextUseSoftwareDate_panel on Panel {
        softwareDate
      }
    `
  );
  return data.softwareDate;
}

export function useDeviceNumberRange() {
  const [data] = usePanelFragment<PanelContextUseDeviceNumberRange_panel$key>(
    graphql`
      fragment PanelContextUseDeviceNumberRange_panel on Panel {
        deviceNumberRange
      }
    `
  );
  return data.deviceNumberRange;
}

export function useGetAllDeviceIds() {
  const [data] = usePanelFragment<PanelContextGetAllDeviceIds_panel$key>(
    graphql`
      fragment PanelContextGetAllDeviceIds_panel on Panel {
        id
        deviceInformations {
          id
          ... on XrDeviceInformation {
            id
            deviceType
            number
          }
          ... on Xt75DeviceInformation {
            id
            deviceType
            number
          }
        }
      }
    `
  );
  return data;
}

export function useZoneNumberRange() {
  const [data] = usePanelFragment<PanelContextUseZoneNumberRange_panel$key>(
    graphql`
      fragment PanelContextUseZoneNumberRange_panel on Panel {
        hardwareModel
        softwareVersion
        systemOptions {
          ... on XtSystemOptions {
            useBuiltIn1100Wireless
          }
        }
      }
    `
  );
  return getZoneNumberRange(
    data.hardwareModel,
    Number(data.softwareVersion),
    !!data.systemOptions?.useBuiltIn1100Wireless
  );
}

export function useWirelessZoneNumberRange() {
  const [data] =
    usePanelFragment<PanelContextUseWirelessZoneNumberRange_panel$key>(
      graphql`
        fragment PanelContextUseWirelessZoneNumberRange_panel on Panel {
          hardwareModel
          softwareVersion
          systemOptions {
            ... on XtSystemOptions {
              useBuiltIn1100Wireless
            }
          }
        }
      `
    );

  return getWirelessZoneNumberRange(
    data.hardwareModel,
    Number(data.softwareVersion),
    !!data.systemOptions?.useBuiltIn1100Wireless
  );
}

export function useBuiltIn1100WirelessEnabled() {
  const [data] =
    usePanelFragment<PanelContextUseBuiltIn1100WirelessEnabled_panel$key>(
      graphql`
        fragment PanelContextUseBuiltIn1100WirelessEnabled_panel on Panel {
          systemOptions {
            ... on XtSystemOptions {
              useBuiltIn1100Wireless
            }
            ... on Xt75SystemOptions {
              useBuiltIn1100Wireless
            }
          }
        }
      `
    );

  return !!data.systemOptions?.useBuiltIn1100Wireless;
}
export function useZoneIsWirelessOnly() {
  const [data] = usePanelFragment<PanelContextUseZoneIsWirelessOnly_panel$key>(
    graphql`
      fragment PanelContextUseZoneIsWirelessOnly_panel on Panel {
        hardwareModel
        softwareVersion
        systemOptions {
          ... on XtSystemOptions {
            useBuiltIn1100Wireless
          }
        }
      }
    `
  );
  const { hardwareModel, softwareVersion, systemOptions } = data;
  return (zoneNumber: number | string) =>
    getWirelessOnlyZoneNumberRange(
      hardwareModel,
      Number(softwareVersion),
      systemOptions?.useBuiltIn1100Wireless || false
    ).includes(Number(zoneNumber));
}
export function useSupports1100T() {
  const [data] = usePanelFragment<PanelContextUseSupports1100T_panel$key>(
    graphql`
      fragment PanelContextUseSupports1100T_panel on Panel {
        supports1100T
      }
    `
  );

  return !!data?.supports1100T;
}

export function useSupportsXR550() {
  const [data] = usePanelFragment<PanelContextUseSupportsXR550_panel$key>(
    graphql`
      fragment PanelContextUseSupportsXR550_panel on Panel {
        supportsXR550
      }
    `
  );

  return !!data?.supportsXR550;
}

export function useSupportsNetworkExpander() {
  const [data] =
    usePanelFragment<PanelContextUseSupportsNetworkExpander_panel$key>(
      graphql`
        fragment PanelContextUseSupportsNetworkExpander_panel on Panel {
          supportsNetworkExpander
        }
      `
    );

  return !!data?.supportsNetworkExpander;
}
export function useSupportsVplex() {
  const [data] = usePanelFragment<PanelContextUseSupportsVplex_panel$key>(
    graphql`
      fragment PanelContextUseSupportsVplex_panel on Panel {
        supportsVplex
      }
    `
  );

  return !!data?.supportsVplex;
}
export function useVplexNumbers() {
  const [data] =
    usePanelFragment<PanelContextUseVplexNumbers_panel$key>(graphql`
      fragment PanelContextUseVplexNumbers_panel on Panel {
        supportsVplex
        deviceInformations {
          ... on XrDeviceInformation {
            number
            lxNumber
            deviceType
          }
          ... on Xt75DeviceInformation {
            number
            lxNumber
            deviceType
          }
        }
      }
    `);
  return data.supportsVplex
    ? (data.deviceInformations ?? [])
        .filter((device) => device.deviceType === DeviceInformationType.VPLEX)
        .map((device) => {
          switch (Number(device.lxNumber)) {
            case 501:
              return range(500, 596);
            case 601:
              return range(600, 696);
            case 701:
              return range(700, 796);
            case 801:
              return range(800, 896);
            case 901:
              return range(900, 996);
            default:
              return [];
          }
        })
        .flat()
    : [];
}
export function useHasVplexDevices() {
  const [data] =
    usePanelFragment<PanelContextUseHasVplexDevices_panel$key>(graphql`
      fragment PanelContextUseHasVplexDevices_panel on Panel {
        supportsVplex
        deviceInformations {
          ... on XrDeviceInformation {
            number
            lxNumber
            deviceType
          }
          ... on Xt75DeviceInformation {
            number
            lxNumber
            deviceType
          }
        }
      }
    `);
  return data.deviceInformations.some(
    (device) => device.deviceType === DeviceInformationType.VPLEX
  );
}
export function useSupportsAxBus() {
  const [data] = usePanelFragment<PanelContextUseSupportsAxBus_panel$key>(
    graphql`
      fragment PanelContextUseSupportsAxBus_panel on Panel {
        supportsAxBus
      }
    `
  );

  return !!data?.supportsAxBus;
}
export function useSupportsPrivateDoor() {
  const [data] = usePanelFragment<PanelContextUseSupportsPrivateDoor_panel$key>(
    graphql`
      fragment PanelContextUseSupportsPrivateDoor_panel on Panel {
        supportsPrivateDoor
      }
    `
  );
  return !!data?.supportsPrivateDoor;
}
export function useSupportsCustomCardFormats() {
  const [data] =
    usePanelFragment<PanelContextUseSupportsCustomCardFormats_panel$key>(
      graphql`
        fragment PanelContextUseSupportsCustomCardFormats_panel on Panel {
          supportsCustomCardFormats
        }
      `
    );
  return !!data?.supportsCustomCardFormats;
}
export function useHas1100T() {
  const [data] = usePanelFragment<PanelContextUseHas1100T_panel$key>(
    graphql`
      fragment PanelContextUseHas1100T_panel on Panel {
        deviceInformations {
          ... on XtDeviceInformation {
            wirelessTranslator1100t
          }
          ... on XrDeviceInformation {
            deviceType
          }
          ... on Xt75DeviceInformation {
            deviceType
          }
        }
      }
    `
  );

  return useMemo(
    () =>
      !!data?.deviceInformations.find(
        (device) =>
          device.wirelessTranslator1100t ||
          device.deviceType === DeviceInformationType._1100T
      ),
    [data]
  );
}

export function useSupportsZoneExpanderSerialNumber() {
  const [data] =
    usePanelFragment<PanelContextUseSupportsZoneExpanderSerialNumber_panel$key>(
      graphql`
        fragment PanelContextUseSupportsZoneExpanderSerialNumber_panel on Panel {
          supportsZoneExpanderSerialNumber
        }
      `
    );

  return !!data?.supportsZoneExpanderSerialNumber;
}

export const useAreaList = () => {
  const [panel] = usePanelFragment<PanelContextUseAreaList_panel$key>(
    graphql`
      fragment PanelContextUseAreaList_panel on Panel {
        id
        areas(first: 32, sort: { keys: ["number"], order: ASC }) {
          __id
          edges {
            cursor
            node {
              id
              number
              name
            }
          }
        }
      }
    `
  );

  return panel.areas.edges
    .map((edge) => edge.node)
    .filter(isNotNullOrUndefined);
};
export function useHelpFiles() {
  const [data] = usePanelFragment<PanelContextHelpFiles_panel$key>(
    graphql`
      fragment PanelContextHelpFiles_panel on Panel {
        id
        helpFiles {
          programmingGuideUrl
          installGuideUrl
        }
      }
    `
  );
  return data.helpFiles;
}

export function useKeyfobNumberRange() {
  const [data] = usePanelFragment<PanelContextUseKeyfobNumberRange_panel$key>(
    graphql`
      fragment PanelContextUseKeyfobNumberRange_panel on Panel {
        hardwareModel
        softwareVersion
      }
    `
  );
  return getKeyfobNumberRange(data.hardwareModel);
}

export function useUserList() {
  const [data] = usePanelFragment<PanelContextUseUserList_panel$key>(
    graphql`
      fragment PanelContextUseUserList_panel on Panel {
        userCodes {
          id
          number
          name
        }
      }
    `
  );
  return data;
}

export function useSystemOptionsSystemType() {
  const [data] =
    usePanelFragment<PanelContextUseSystemOptionsSystemType_panel$key>(
      graphql`
        fragment PanelContextUseSystemOptionsSystemType_panel on Panel {
          systemOptions {
            ... on XrSystemOptions {
              systemType
            }
            ... on XtSystemOptions {
              systemType
            }
            ... on Xt75SystemOptions {
              systemType
            }
          }
        }
      `
    );
  return data.systemOptions?.systemType;
}

export function useHasWifiCommType() {
  const [data] = usePanelFragment<PanelContextUseHasWifiCommType_panel$key>(
    graphql`
      fragment PanelContextUseHasWifiCommType_panel on Panel {
        communicationPaths {
          xrCommType: commType
        }
        communication {
          xtCommType: comType
        }
      }
    `
  );
  const hasWifi =
    data.communication?.xtCommType === "WIFI" ||
    data.communicationPaths.some(({ xrCommType }) => xrCommType === "WIFI");

  return hasWifi;
}

export function useHasNetworkCommType() {
  const [data] = usePanelFragment<PanelContextUseHasNetworkCommType_panel$key>(
    graphql`
      fragment PanelContextUseHasNetworkCommType_panel on Panel {
        communicationPaths {
          xrCommType: commType
          xfCommType: commType
        }
        communication {
          xtCommType: comType
        }
      }
    `
  );
  const hasNetwork =
    data.communication?.xtCommType === "NETWORK" ||
    data.communicationPaths.some(
      ({ xrCommType, xfCommType }) =>
        xrCommType === "NETWORK" || xfCommType === "NETWORK"
    );

  return hasNetwork;
}

export function useCommPaths() {
  const [data] = usePanelFragment<PanelContextUseCommPaths_panel$key>(
    graphql`
      fragment PanelContextUseCommPaths_panel on Panel {
        communicationPaths {
          xrCommType: commType
          xfCommType: commType
        }
      }
    `
  );

  return data.communicationPaths;
}

export function useFeatureKeyEnabled(featureKey: FeatureKeyEnum): boolean {
  const [data] = usePanelFragment<PanelContextUseFeatureKeyEnabled_panel$key>(
    graphql`
      fragment PanelContextUseFeatureKeyEnabled_panel on Panel {
        featureKeys {
          key
        }
      }
    `
  );

  return data.featureKeys.map(prop("key")).includes(featureKey);
}

export function useRemoteZoneList(): number[] {
  const [data] = usePanelFragment<PanelContextUseRemoteZoneList_panel$key>(
    graphql`
      fragment PanelContextUseRemoteZoneList_panel on Panel {
        hardwareModel
        zoneInformations {
          number
          remoteZoneType
        }
      }
    `
  );

  return resolvePanelType(data.hardwareModel).isXr
    ? data.zoneInformations
        .filter((zone) => zone.remoteZoneType === ZoneRemoteZoneType.REMOTE)
        .map((zone) => Number(zone.number))
    : [];
}

export const usePanelId = () =>
  useFragment(
    panelContextFragment,
    React.useContext(PanelContext) as PanelContext_panel$key
  ).id;
