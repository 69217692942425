/**
 * @generated SignedSource<<634cd1b8b5abb11b8fcf3101e8a22279>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
export type SendLocalChangesType = "DIGITAL_DIALER" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly alarmReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly allowCellularRemoteProgramming: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly allowNetworkRemote: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entreArmDisarmReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreBackupIpv6Address: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreCheckInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entreConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly entreDoorReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIpv6: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreIpv6Address: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreOutgoingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entrePassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entreSupervisoryReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreUserCommandReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreVideoReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly entreZoneReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly firstApn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly integratorArmDisarmReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorBackupIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorCheckInMinutes: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorConnection: {
      readonly data: RemoteOptionsConnectionType | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorDoorReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorIncomingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorOutgoingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorSupervisoryReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorUserCommandReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorVideoReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly integratorZoneReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly networkProgrammingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly pcModem: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteChangesIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteChangesPhoneNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteChangesPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly secondApn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sendLocalChanges: {
      readonly data: SendLocalChangesType | null;
      readonly included: boolean | null;
    } | null;
    readonly serviceReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly validEntreCheckinMinutes: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "2ec595b442711d0592f325f3e9f28283";

export default node;
