/**
 * @generated SignedSource<<2debec1335b6390d3eae902efb3e857d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathEncryptionScheme = "_128_BIT" | "_256_BIT";
import { FragmentRefs } from "relay-runtime";
export type CommPathEncryptionSchemaField_communicationPath$data = {
  readonly encryptionEnabled: boolean;
  readonly encryptionScheme: CommunicationPathEncryptionScheme;
  readonly number: number;
  readonly " $fragmentType": "CommPathEncryptionSchemaField_communicationPath";
};
export type CommPathEncryptionSchemaField_communicationPath$key = {
  readonly " $data"?: CommPathEncryptionSchemaField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathEncryptionSchemaField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathEncryptionSchemaField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "encryptionScheme",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "encryptionEnabled",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "50135aea3b4a50ce90990bf050d6e823";

export default node;
