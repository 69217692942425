import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver2SecondIpPortField_communication$key } from "./__generated__/CommunicationReceiver2SecondIpPortField_communication.graphql";

export const communicationReceiver2SecondIpPortFieldId = () =>
  "communication-receiver-2-second-ip-port";

function CommunicationReceiver2SecondIpPortField() {
  const [{ receiver2Port2 }, updateCommunication] =
    useCommunicationFragment<CommunicationReceiver2SecondIpPortField_communication$key>(
      graphql`
        fragment CommunicationReceiver2SecondIpPortField_communication on Communication {
          receiver2Port2
        }
      `
    );

  const fieldId = communicationReceiver2SecondIpPortFieldId();
  const { current: originalValue } = React.useRef(receiver2Port2);

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Second IP Port">
      <NumericInput
        id={fieldId}
        value={String(receiver2Port2 ?? "")}
        pattern="((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])"
        inlineHelp="1-65535"
        min={1}
        max={65535}
        validationMessage="Valid values are 1-65535."
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "receiver2Port2"
            );
          });
        }}
        onBlur={({ target }) => {
          const valueAsNumber = Number(target.value);
          const value = isNaN(valueAsNumber)
            ? originalValue
            : clamp(1, 65535, valueAsNumber);
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              String(value).padStart(5, `0`),
              "receiver2Port2"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver2SecondIpPortField;
