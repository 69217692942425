/**
 * @ngdoc object
 * @name App.factory:baseScheduleModel
 *
 * @description
 * This model serves as a base object for panel specific schedule models. This should be extended by a specific schedule model
 * To extend the model there are 3 local variables required:
 * panelFamily: (string) The panel family that this schedule is associated with XR550, XT30
 * beginFieldName: (string) The field suffix to use for schedule begin fields. ex: _open, _a_time, _on
 * endFieldName: (string) The field suffix to use for schedule end fields. ex: _close
 *
 */
App.factory("baseScheduleModel", [
  "scheduleUtilService",
  "$filter",
  function (scheduleUtilService, $filter) {
    var baseScheduleModel = function () {
      // Setup any init value and methods
      angular.extend(this, {
        days: angular.copy(scheduleUtilService.days),

        /**
         * @ngdoc object
         * @name method:initEndDays
         * @methodOf App.factory:timeScheduleModel
         *
         * @description
         * Initialize specified date field based on data returned from API.
         * Should ONLY be called after API data is loaded.
         * For example, a sun_close can have an endDay of Tuesday, represented by the day date falling on a Tuesday.
         *
         */
        initDays: function (fieldName, dateField) {
          // loop through all the days
          for (var x = 0; x < this.days.length; x++) {
            // Set the day.date equal to the date of the endField (eg., sun_close)
            // Only reset the date if the newDate exists (would be empty on a blank time field, undefined on new)
            if (
              angular.isDefined(this[this.days[x][fieldName]]) &&
              this[this.days[x][fieldName]] != ""
            ) {
              var dt = new Date(this[this.days[x][fieldName]]);
              var filteredDate =
                $filter("date")(dateTimeForceUTC(dt), "MM/dd/yyyy ") + " 12:00"; // FIX: DA-3652 This is to prevent positive time zone offsets (BELGIUM) from showing the wrong day on schedules.

              this.days[x][dateField] = filteredDate;
            }
          }
        },

        /**
         * @ngdoc object
         * @name method:prepareToSendMultiPanel
         * @methodOf App.factory:baseScheduleModel
         *
         * @description
         * Prepare the schedule to be sent to multiple panels
         */
        prepareToSendMultiPanel: function (
          sendTimes,
          sendAreas,
          sendDoors,
          sendOutputs,
          sendFavorites
        ) {
          var schedule = angular.copy(this); // Create a copy of the current schedule

          if (!sendTimes) {
            schedule.removeTimeFields();
          } // Clear time fields
          if (!sendAreas) {
            delete schedule.area_ids;
          } // clear areas
          if (!sendOutputs) {
            delete schedule.output_ids;
          } // clear outputs
          if (!sendFavorites) {
            delete schedule.favorite_ids;
          } // clear favorites
          if (schedule.panelFamily == "XR550" && !sendDoors) {
            delete schedule.door_ids;
          } // clear doors

          return schedule;
        },

        /**
         * @ngdoc object
         * @name method:clearTimeFields
         * @methodOf App.factory:baseScheduleModel
         *
         * @description
         * Clear all time fields for the schedule
         */
        removeTimeFields: function () {
          if (this.hasOwnProperty("days")) {
            for (var x = 0; x < this.days.length; x++) {
              if (angular.isDefined(this[this.days[x].beginField])) {
                delete this[this.days[x].beginField];
              }
              if (angular.isDefined(this[this.days[x].endField])) {
                delete this[this.days[x].endField];
              }
            }
          }
        },

        /**
         * @ngdoc object
         * @name method:clearTimeFields
         * @methodOf App.factory:baseScheduleModel
         *
         * @description
         * Clear all time fields for the schedule
         */
        clearTimeFields: function () {
          for (var x = 0; x < this.days.length; x++) {
            if (angular.isDefined(this[this.days[x].beginField])) {
              this[this.days[x].beginField] = "";
            }
            if (angular.isDefined(this[this.days[x].endField])) {
              this[this.days[x].endField] = "";
            }
          }
        },

        /**
         * @ngdoc object
         * @name method:initializeSchedule
         * @methodOf App.factory:baseScheduleModel
         *
         * @description
         * Prepare the base schedule object for use
         */
        initializeSchedule: function () {
          // Copy the days.date attributes to begin_date and end_date, so we have some default values
          angular.forEach(this.days, function (day) {
            day.begin_date = day.date;
            day.end_date = day.date;
          });
        },
      });

      this.initializeSchedule();
    };

    return baseScheduleModel;
  },
]);
