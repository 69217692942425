/**
 * @generated SignedSource<<a14df3c3468f91d18ca6c297d047b22c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BellOptionsAutomaticBellTestField_bellOptions$data = {
  readonly automaticBellTest: boolean;
  readonly " $fragmentType": "BellOptionsAutomaticBellTestField_bellOptions";
};
export type BellOptionsAutomaticBellTestField_bellOptions$key = {
  readonly " $data"?: BellOptionsAutomaticBellTestField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsAutomaticBellTestField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsAutomaticBellTestField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automaticBellTest",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "14af1f7aa01801ba7cdad2505564218e";

export default node;
