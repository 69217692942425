import moment from "moment";
import { CameraType } from "../camect-api/streamable-camera";
import { EventType } from "./event-types-parser";

// This value should not be treated as meaningful. It's used to derive a sequence value from
// things that look like UTC dates but aren't.
const FAKE_EPOCH = 304092660000;
// These are event types that are in the Alarms group, but we don't want to treat them as alarms.
const NOT_REALLY_ALARMS_SUBCATEGORY_IDS = [
  13, // Alarm Canceled
  14, // Alarm Verified
];
const EVENT_TRIGGERED_RECORDING_SUBCATEGORY_IDS = [
  139, // AlarmVision Device Event
  143, // Uniview Manual Record
  156, // XV Device Event
];
const MESSAGE_PARSING_SUBCATEGORY_IDS = [
  89, // Custom Action Activated
];
const MESSAGE_TIME_PREFIX_REGEX = /\d\d:\d\d (A|P)M\r\n/;
const CAMECT_CAMERA_SUBCATEGORY_IDS = [
  133, // AlarmVision Object Detected
  137, // AlarmVision Camera Offline
  138, // AlarmVision Camera Online
  139, // AlarmVision Device Event
];
const V_6000_CAMERA_SUBCATEGORY_IDS = [
  141, // Uniview Object Detected
  142, // Uniview Motion Detected
  143, // Uniview Manual Record
];
const IENSO_CAMERA_SUBCATEGORY_IDS = [
  153, // XV Object Detected
  154, // XV Camera Offline
  155, // XV Camera Online
  156, // XV Device Event
];
const CORRELATED_VIDEO_EVENT_SUBCATEGORY_IDS = [
  133, // AlarmVision Object Detected
  139, // AlarmVision Device Event [video action]
  153, // XV Object Detected
  156, // XV Device Event [video action]
];
const EVENT_WINDOW_BEFORE_ALARM_MS = 10 * 60 * 1000;

interface RawSecurityEvent {
  id: number;
  id_type: string;
  camera_number: number | null;
  created_at: string;
  event_at: string;
  subcategory_id: number;
  message: string;
  person_detected: boolean | null;
  animal_detected: boolean | null;
  vehicle_detected: boolean | null;
  wealth: string | null;
}

interface VideoDetails {
  camect_camera_id?: string;
  camera_number?: number | null;
  id_type?: string;
  image_url?: string;
  camera_name?: string; // The "wealth" has a "camera_name" for Camect/iENSO events and a "name" for V-6000
  name?: string;
  video_url?: string; // The "wealth" has a "video_url" for Camect/iENSO events and an "event_stream_url" for V-6000
  event_stream_url?: string;
}

export interface RawCombinedSecurityEvent {
  _base: RawSecurityEvent;
  CorrelatedEvents: RawSecurityEvent[];
}

export enum EventSource {
  Events = "Events",
  Clips = "Clips",
}

export interface SecurityEvent {
  id: number;
  eventSource: EventSource;
  displayDate: string;
  displayTime: string;
  sequenceMS: number;
  isAlarm: boolean;
  eventTitle: string;
  cameraId: string | null;
  cameraType: CameraType | null;
  cameraName: string | null;
  imageUrl: string | null;
  videoUrl: string | null;
  timeDistanceFromAlarm: string | null;
}

// The OData event_at field represents the time the event happened from the perspective of the
// panel's local time (sort of--it injects seconds from the server's time). But event_at is
// formatted as if it were an absolute datetime in UTC. We strip off the "Z" at the end and create
// a new JavaScript Date. That Date will be based on the current user's local time zone, which may
// or may not be the same as the panel's time zone. We are using that somewhat bogus date to get
// formatted date and time strings to display to the current user, because we want everyone
// (including a monitoring center in a different time zone) to see dates and times in the panel's
// local time.
//
// It's important not to try to use event_at as anything other than a relative timestamp from the
// panel's perspective. E.g., it should not be compared against current time, or a server time such
// as the last_alarm_at value we get from SCAPI.
const interpretAsLocalTime = (timeWithBogusZone: string) => {
  const panelLocalDateTimeString = timeWithBogusZone.replace("Z", "");
  return new Date(panelLocalDateTimeString);
};

// Event date, *in the panel's local time*, formatted like "Wednesday, August 16, 2023"
const displayDate = (timeWithBogusZone: string) => {
  const eventAtAsUserLocalTime = interpretAsLocalTime(timeWithBogusZone);
  return eventAtAsUserLocalTime.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// Event time, *in the panel's local time*, formatted like "9:01 AM"
const displayTime = (timeWithBogusZone: string) => {
  const eventAtAsUserLocalTime = interpretAsLocalTime(timeWithBogusZone);
  return eventAtAsUserLocalTime.toLocaleTimeString("en-US", {
    timeStyle: "short",
  });
};

// The purpose of this value is to be able to compare two events and determine how many milliseconds
// apart they are. It intentionally uses an offset so that it cannot be confused with a timestamp.
const sequenceMS = (timeWithBogusZone: string) => {
  return Date.parse(timeWithBogusZone) - FAKE_EPOCH;
};

const isAlarmType = (rawEvent: RawSecurityEvent, eventTypes: EventType[]) => {
  if (NOT_REALLY_ALARMS_SUBCATEGORY_IDS.includes(rawEvent.subcategory_id)) {
    return false;
  }

  const eventType = eventTypes.find(
    (eventType) => eventType.id === rawEvent.subcategory_id
  );
  return eventType?.group === "Alarms";
};

const eventTitle = (rawEvent: RawSecurityEvent, eventTypes: EventType[]) => {
  const eventType = eventTypes.find(
    (eventType) => eventType.id === rawEvent.subcategory_id
  );
  let title = eventType?.displayName;
  switch (true) {
    case rawEvent.person_detected:
      title = "Person Detected";
      break;
    case rawEvent.animal_detected:
      title = "Animal Detected";
      break;
    case rawEvent.vehicle_detected:
      title = "Vehicle Detected";
      break;
    case EVENT_TRIGGERED_RECORDING_SUBCATEGORY_IDS.includes(
      rawEvent.subcategory_id
    ):
      title = "Event-Triggered Recording";
      break;
    case MESSAGE_PARSING_SUBCATEGORY_IDS.includes(rawEvent.subcategory_id):
      title = rawEvent.message.replace(MESSAGE_TIME_PREFIX_REGEX, "");
      break;
  }

  return title || "Unknown";
};

const cameraType = (subcategoryId: number) => {
  switch (true) {
    case V_6000_CAMERA_SUBCATEGORY_IDS.includes(subcategoryId):
      return "v-6000";
      break;
    case CAMECT_CAMERA_SUBCATEGORY_IDS.includes(subcategoryId):
      return "camect";
      break;
    case IENSO_CAMERA_SUBCATEGORY_IDS.includes(subcategoryId):
      return "ienso";
      break;
    default:
      return null;
  }
};

const timeDistanceFromAlarm = (
  event: SecurityEvent,
  latestAlarmEvent: SecurityEvent
) => {
  if (event.id == latestAlarmEvent.id) return "Latest Alarm";

  const diffMS = event.sequenceMS - latestAlarmEvent.sequenceMS;
  const isBefore = diffMS < 0;
  const diffMinutes =
    Math.floor(event.sequenceMS / 1000 / 60) -
    Math.floor(latestAlarmEvent.sequenceMS / 1000 / 60);
  const absDiffMinutes = Math.abs(diffMinutes);
  let diffString = "";
  if (absDiffMinutes === 1) {
    diffString = "1 minute";
  } else {
    diffString = `${absDiffMinutes} minutes`;
  }

  return `${diffString} ${isBefore ? "before" : "after"} latest alarm`;
};

const extractCameraId = (videoDetails: VideoDetails) => {
  let cameraId: string | null = null;
  switch (videoDetails.id_type) {
    case "rich_camect_alerts":
    case "rich_ienso_alerts":
      cameraId = videoDetails.camect_camera_id || null;
      break;
    case "rich_uniview_alerts":
      cameraId = videoDetails.camera_number?.toString() || null;
      break;
  }
  return cameraId;
};

const extractCameraName = (videoDetails: VideoDetails) => {
  let cameraName: string | null = null;
  switch (videoDetails.id_type) {
    case "rich_camect_alerts":
    case "rich_ienso_alerts":
      cameraName = videoDetails.camera_name || null;
      break;
    case "rich_uniview_alerts":
      cameraName = videoDetails.name || null;
      break;
  }
  return cameraName;
};

const extractVideoUrl = (videoDetails: VideoDetails) => {
  let videoUrl: string | null = null;
  switch (videoDetails.id_type) {
    case "rich_camect_alerts":
    case "rich_ienso_alerts":
      videoUrl = videoDetails.video_url || null;
      break;
    case "rich_uniview_alerts":
      videoUrl = videoDetails.event_stream_url || null;
      if (videoUrl) {
        // OData gives us a URL for streaming recordings from the camera via HLS or RTSP. We want
        // a video file we can use directly as the `src` for a `<video>` element, so we switch to
        // the `clip` endpoint. The vidprox service still has to pull the recording from the
        // camera, but it returns it to us as a video file instead of a stream.
        videoUrl = videoUrl.replace("/recorded", "/clip");
      }
      break;
  }
  return videoUrl;
};

const buildSecurityEvent = (
  rawSecurityEvent: RawSecurityEvent,
  videoDetails: VideoDetails,
  subcategoryIdForCameraType: number,
  eventTypes: EventType[]
): SecurityEvent => {
  return {
    id: rawSecurityEvent.id,
    eventSource: EventSource.Events,
    displayDate: displayDate(rawSecurityEvent.event_at),
    displayTime: displayTime(rawSecurityEvent.event_at),
    sequenceMS: sequenceMS(rawSecurityEvent.event_at),
    isAlarm: isAlarmType(rawSecurityEvent, eventTypes),
    eventTitle: eventTitle(rawSecurityEvent, eventTypes),
    cameraId: extractCameraId(videoDetails),
    cameraType: cameraType(subcategoryIdForCameraType),
    cameraName: extractCameraName(videoDetails),
    imageUrl: videoDetails.image_url || null,
    videoUrl: extractVideoUrl(videoDetails),
    timeDistanceFromAlarm: null,
  };
};

const extractVideoDetails = (rawSecurityEvent: RawSecurityEvent) => {
  const wealthData = JSON.parse(rawSecurityEvent.wealth || "{}");
  return {
    id_type: rawSecurityEvent.id_type,
    camera_number: rawSecurityEvent.camera_number,
    ...wealthData,
  };
};

export const parseSecurityEvents = (
  rawCombinedSecurityEvents: RawCombinedSecurityEvent[],
  eventTypes: EventType[],
  lastAlarmServerTime: Date
): SecurityEvent[] => {
  const securityEvents: SecurityEvent[] = [];
  const eventTypeIds = eventTypes.map((eventType) => eventType.id);
  const eventWindowStartDate = moment(lastAlarmServerTime)
    .subtract({ milliseconds: EVENT_WINDOW_BEFORE_ALARM_MS })
    .toDate();

  const relevantRawCombinedSecurityEvents = rawCombinedSecurityEvents.filter(
    (rawCombinedEvent) => {
      const isRelevantType = eventTypeIds.includes(
        rawCombinedEvent._base.subcategory_id
      );

      // Since we have to compare against an absolute server time (the last_alarm_at from SCAPI), we
      // use created_at, which is the time our server processed the event, not the time the panel
      // says the event happened. So we are using different values for filtering events based on time
      // and for displaying event times. This is not ideal, but we don't seem to have a better option
      // at this point.
      const isInEventWindow =
        new Date(rawCombinedEvent._base.created_at) > eventWindowStartDate;

      return isRelevantType && isInEventWindow;
    }
  );
  relevantRawCombinedSecurityEvents.forEach((rawCombinedEvent) => {
    const rawPrimaryEvent = rawCombinedEvent._base;
    const rawCorrelatedEvents = [...rawCombinedEvent.CorrelatedEvents];
    let primaryVideoDetails: VideoDetails = {};
    let subcategoryIdForCameraType = rawPrimaryEvent.subcategory_id;

    // If the primary event has video, use it. If not, try to find a video event in the
    // correlated events. If one is found, remove it from the list and use its video as the primary
    // event's video. Also use its subcategory_id to determine camera type.
    if (rawPrimaryEvent.wealth) {
      primaryVideoDetails = extractVideoDetails(rawPrimaryEvent);
    } else {
      const videoEventIndex = rawCorrelatedEvents.findIndex(
        (rawCorrelatedEvent) =>
          CORRELATED_VIDEO_EVENT_SUBCATEGORY_IDS.includes(
            rawCorrelatedEvent.subcategory_id
          ) && rawCorrelatedEvent.wealth
      );
      if (videoEventIndex !== -1) {
        const rawCorrelatedVideoEvent = rawCorrelatedEvents.splice(
          videoEventIndex,
          1
        )[0];
        primaryVideoDetails = extractVideoDetails(rawCorrelatedVideoEvent);
        subcategoryIdForCameraType = rawCorrelatedVideoEvent.subcategory_id;
      }
    }

    const augmentedPrimaryEvent = buildSecurityEvent(
      rawPrimaryEvent,
      primaryVideoDetails,
      subcategoryIdForCameraType,
      eventTypes
    );
    securityEvents.push(augmentedPrimaryEvent);

    // Treat remaining correlated events the same as the primary events--display them in the list.
    rawCorrelatedEvents.forEach((rawCorrelatedEvent) => {
      const videoDetails = extractVideoDetails(rawCorrelatedEvent);
      const correlatedEvent = buildSecurityEvent(
        rawCorrelatedEvent,
        videoDetails,
        rawCorrelatedEvent.subcategory_id,
        eventTypes
      );
      securityEvents.push(correlatedEvent);
    });
  });

  const alarmEvents = securityEvents.filter((event) => event.isAlarm);
  if (alarmEvents.length > 0) {
    const latestAlarm = alarmEvents.reduce((prev, current) => {
      return prev.sequenceMS > current.sequenceMS ? prev : current;
    });

    securityEvents.forEach((event) => {
      event.timeDistanceFromAlarm = timeDistanceFromAlarm(event, latestAlarm);
    });
  }

  // Make sure the final list is sorted in descending chronological order
  return securityEvents.sort((a, b) => {
    return a.sequenceMS < b.sequenceMS ? 1 : -1;
  });
};
