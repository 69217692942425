/**
 * @generated SignedSource<<9a158949a0d42af65f122976d225b785>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly securityGrade: {
    readonly grade: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "12e62c604b11560198a91467fabefe2c";

export default node;
