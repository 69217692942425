/**
 * @generated SignedSource<<5393ea45c658d218afb7c937d9237a25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly lockoutCode: {
      readonly code: string;
      readonly id: string;
    } | null;
  };
  readonly " $fragmentType": "TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem";
};
export type TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "b957b19de0d00096f4f397848faed4fc";

export default node;
