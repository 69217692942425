import { downloadImage } from "common/utils/web/download";
/**
 * @name App.controller:SystemImageCtrl
 *
 * @description Controller for the System Image Component
 * */
App.controller("SystemImageCtrl", [
  "$scope",
  "$rootScope",
  "$modal",
  "IMAGE_SIZES",
  "ImagesService",
  "ControlSystemsService",
  function (
    $scope,
    $rootScope,
    $modal,
    IMAGE_SIZES,
    ImagesService,
    ControlSystemsService
  ) {
    $scope.galleryImages = {
      size: IMAGE_SIZES.tablet.URLParameter,
      urls: [],
    };

    function init() {
      $scope.getSystemImages();
    }

    /**
     * Populate system images
     */
    $scope.getSystemImages = () => {
      ImagesService.ControlSystems.GetImageUrls(
        ControlSystemsService.currentControlSystem.id,
        $scope.galleryImages.size
      )
        .then(
          function (data) {
            $scope.galleryImages.urls = data;
          },
          function (error) {
            console.warn(
              `Error getting system images: ${angular.toJson(error)}`
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Open the image gallery modal
     * @param url
     */
    $scope.openGalleryModal = function (url) {
      var galleryModal = $modal.open({
        templateUrl: "app/control_system/system-images/system-image-modal.html",
        controller: "SystemImagesModalCtrl",
        size: "lg",
        backdrop: true,
        resolve: {
          currentImageIdx: function () {
            var i = $scope.galleryImages.urls.indexOf(url);
            i = i === -1 ? 0 : i;
            return i;
          },
          refreshImages: function () {
            return $scope.getSystemImages;
          },
        },
      });
      galleryModal.result
        .then(
          function (reason) {
            console.warn("Unknown close galleryModal reason: " + reason);
            galleryModal();
          },
          function () {}
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    init();
  },
]);

App.controller("SystemImagesModalCtrl", [
  "$scope",
  "$rootScope",
  "$modalInstance",
  "IMAGE_SIZES",
  "ImagesService",
  "ControlSystemsService",
  "currentImageIdx",
  "refreshImages",
  "UserService",
  "$http",
  function (
    $scope,
    $rootScope,
    $modalInstance,
    IMAGE_SIZES,
    ImagesService,
    ControlSystemsService,
    currentImageIdx,
    refreshImages,
    UserService,
    $http
  ) {
    $scope.UserService = UserService;
    $scope.modalImages = {
      rotateInterval: 50000,
      size: IMAGE_SIZES.large.URLParameter,
      slides: [],
      currentImageIdx: currentImageIdx,
    };

    function init() {
      ImagesService.ControlSystems.GetImageUrls(
        ControlSystemsService.currentControlSystem.id,
        $scope.modalImages.size
      )
        .then(
          function (data) {
            angular.forEach(data, function (url) {
              var slide = {
                url: url,
                active: false,
              };
              $scope.modalImages.slides.push(slide);
            });
            $scope.modalImages.slides[
              $scope.modalImages.currentImageIdx
            ].active = true;
          },
          function () {
            $rootScope.alerts.push({
              type: "error",
              text: "Error getting system images",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Cancel button click
     */
    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    /**
     * Download the active image
     */
    $scope.downloadImage = async function () {
      const activeImage = $scope.modalImages.slides.find(function (slide) {
        return slide.active;
      });
      const largestFile = getLargestFile(activeImage.url);
      downloadImage(largestFile.url, largestFile.name);
    };

    /**
     * Delete the active image
     */
    $scope.deleteImage = () => {
      const activeImage = $scope.modalImages.slides.find(
        (slide) => slide.active
      );
      const largestFile = getLargestFile(activeImage.url);

      ImagesService.ControlSystems.DestroyImage(
        ControlSystemsService.currentControlSystem.id,
        largestFile.url
      ).then(
        () => {
          const activeImageIndex = $scope.modalImages.slides.findIndex(
            (slide) => slide.active
          );

          // Refreshing images on System Information page
          refreshImages();

          if ($scope.modalImages.slides.length === 1) {
            // Last image is being deleted so close the modal.
            $scope.cancel();
          } else {
            // Slide to next available image and remove deleted slide.
            const nextImageIndex =
              (activeImageIndex + 1) % $scope.modalImages.slides.length;

            $scope.modalImages.slides[nextImageIndex].active = true;

            // Delay for slide transition to complete before deleting slide to prevent image flicker
            setTimeout(() => {
              $scope.modalImages.slides.splice(activeImageIndex, 1);
            }, 200);
          }

          $rootScope.alerts.push({
            type: "success",
            text: "System Image Deleted Successfully",
          });
        },
        () => {
          $rootScope.alerts.push({
            type: "error",
            text: "Error Deleting System Image",
          });
        }
      );
    };

    /**
     * Build the largest image name and URL of a given file URL
     * @param imageUrl
     * @returns {{name: string, url: *}}
     */
    function getLargestFile(imageUrl) {
      var urlParts = imageUrl.split("/");
      var filename = urlParts[urlParts.length - 1];
      var filenameParts = filename.split(".");
      var extension = filenameParts[filenameParts.length - 1];
      var filenameBase = urlParts[urlParts.length - 2];
      urlParts.splice(-1, 1);
      urlParts.push(filenameBase + "." + extension);
      var largestFileURL = urlParts.join("/");
      return {
        name: filenameBase + "." + extension,
        url: largestFileURL,
      };
    }

    init();
  },
]);
