/**
 *
 * RadialBanIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialBanIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M54.633,9.47c-0.012-0.011-0.023-0.021-0.034-0.032c-0.003-0.003-0.005-0.007-0.008-0.01
    c-0.005-0.005-0.011-0.008-0.016-0.013C48.533,3.416,40.52,0.111,31.986,0.111c-8.547,0-16.582,3.322-22.626,9.353
    C3.325,15.487,0.001,23.509,0,32.052c0,8.546,3.326,16.573,9.365,22.601l0,0l0,0l0.001,0.001C15.405,60.681,23.446,64,32.009,64
    h0.003c8.557,0,16.593-3.316,22.628-9.339C60.676,48.638,64,40.615,64,32.071C64,23.524,60.674,15.498,54.633,9.47z M12.187,12.295
    c5.288-5.277,12.32-8.184,19.815-8.184c6.785,0,13.2,2.402,18.286,6.791L10.834,50.356C6.417,45.281,4,38.858,4,32.052
    C4.001,24.579,6.908,17.563,12.187,12.295z M51.813,51.83C46.534,57.099,39.502,60,32.012,60H32.01
    c-6.821,0-13.259-2.412-18.345-6.818l39.461-39.461C57.568,18.803,60,25.245,60,32.071C60,39.545,57.093,46.562,51.813,51.83z"
      />
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RadialBanIcon;
