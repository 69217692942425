import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { setIncludedFields as setAreaInformationIncludedFields } from "./Data/TakeoverAreaInformationTemplateData";
import { setIncludedFields as setCommunicationIncludedFields } from "./Data/TakeoverCommunicationTemplateData";
import { setIncludedFields as setLockoutCodeIncludedFields } from "./Data/TakeoverLockoutCodeTemplateData";
import { setIncludedFields as setNetworkOptionsIncludedFields } from "./Data/TakeoverNetworkOptionsTemplateData";
import { setIncludedFields as setOutputOptionsIncludedFields } from "./Data/TakeoverOutputOptionsTemplateData";
import { setIncludedFields as setRemoteOptionsIncludedFields } from "./Data/TakeoverRemoteOptionsTemplateData";
import { setIncludedFields as setSystemOptionsIncludedFields } from "./Data/TakeoverSystemOptionsTemplateData";
import { setIncludedFields as setSystemReportsIncludedFields } from "./Data/TakeoverSystemReportsTemplateData";
import { setIncludedFields as setZoneIncludedFields } from "./Data/TakeoverZoneInformationTemplateData";
import { TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts.graphql";

export const getState = (
  template: TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        ...TakeoverAreaInformationTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverCommunicationTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverLockoutCodeTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverNetworkOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverOutputOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverRemoteOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverSystemOptionsTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverSystemReportsTemplateDataInline_takeoverProgrammingTemplateConcepts
        ...TakeoverZoneInformationTemplateDataInline_takeoverProgrammingTemplateConcepts
      }
    `,
    template
  );

export const setAllIncludedFields = (
  template: TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const templateData = getState(template);

  setAreaInformationIncludedFields(templateData, setIncludedFields);
  setCommunicationIncludedFields(templateData, setIncludedFields);
  setLockoutCodeIncludedFields(templateData, setIncludedFields);
  setNetworkOptionsIncludedFields(templateData, setIncludedFields);
  setOutputOptionsIncludedFields(templateData, setIncludedFields);
  setRemoteOptionsIncludedFields(templateData, setIncludedFields);
  setSystemOptionsIncludedFields(templateData, setIncludedFields);
  setSystemReportsIncludedFields(templateData, setIncludedFields);
  setZoneIncludedFields(templateData, setIncludedFields);
};
