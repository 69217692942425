/**
 * @generated SignedSource<<fee48d9f4ba00919058205bfa1f2550d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly systemReports: {
    readonly abortReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly accessKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ambushReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly bypassReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly codeChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly earlyToClose: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryCheckinProtection: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly lateToOpen: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly openCloseEnable: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly scheduleChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly sendStoredMessage: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly videoSystemReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneRestoralReports: {
      readonly data: SystemReportsZoneRestoralReports | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "be9d7853ec0d278cbeaf5eac96201e44";

export default node;
