import { useRef, useCallback } from "react";
import { noop } from "../utils";
import useEffectOnce from "./useEffectOnce";

export default function useDebounce<A extends (...args: any[]) => void>(
  f: A = noop as A,
  ms = 500
) {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffectOnce(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  });

  return useCallback(
    (...args: any[]) => {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => f(...args), ms);
    },
    [f, ms]
  ) as A;
}
