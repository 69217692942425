/**
 * @ngdoc directive
 * @name App.directive:vvErrSrc
 * @function
 *
 * @description
 * Linked to Video Verification Camera Model (app/video/camera.js).  Loops camera models through individual JPEG images
 * at max rate of 4fps (once every .250 seconds).
 *
 * @example
 * <pre>  da-vv-err-src="assets/img/Unable_to_load_image.png" camera="camera" ng-src={{camera.jpeg_url}}/></pre>
 *
 */
App.directive("daVvErrSrc", function ($timeout) {
  return {
    restrict: "A",
    scope: { camera: "=camera" },
    link: function (scope, element, attrs) {
      scope.$watch(
        function () {
          return attrs["ngSrc"];
        },
        function (value) {
          if (!value) {
            element.attr("src", undefined); //if html loads before URL is generated, this clears the image.
          }
        }
      );

      element.bind("load", function () {
        scope.$apply(function () {
          // Function to convert the imageUrl to base64
          // More info: https://jsfiddle.net/handtrix/YvQ5y/
          //            https://stackoverflow.com/questions/8499633/how-to-display-base64-images-in-html
          //            https://stackoverflow.com/questions/22172604/convert-image-url-to-base64
          function convertImgToDataURLviaCanvas(url, callback) {
            var img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
              var canvas = document.createElement("CANVAS");
              var ctx = canvas.getContext("2d");
              var dataURL;
              canvas.height = this.height;
              canvas.width = this.width;
              ctx.drawImage(this, 0, 0);
              dataURL = canvas.toDataURL();
              callback(dataURL);
              canvas = null;
            };

            img.src = url;
          }

          scope.camera.showing_jpeg = true;
          var refreshTime = scope.camera.device_type === "nvr" ? 5000 : 250;
          if (!scope.camera.available) {
            scope.camera.fps = 0;
            return; //if camera is marked off-line, error source image is displayed, and directive exits.
          }

          $timeout(function () {
            scope.camera.refreshSnapshotUrl();
            scope.camera.fps =
              1000 / (new Date().getTime() - scope.camera.jpeg_retry);
            scope.camera.fps = scope.camera.fps.toFixed(2);
            scope.camera.jpeg_retry = new Date().getTime(); //get new image from camera
            element.attr("src", scope.camera.jpeg_retry);

            // Get the base64 string to show if camera fails
            convertImgToDataURLviaCanvas(
              scope.camera.snapshot_url,
              function (base64Img) {
                scope.camera.base64Img = base64Img;
              }
            );
          }, refreshTime);
        });
      });
      element.bind("error", function () {
        scope.$apply(function () {
          // scope.camera.showing_jpeg = false;
          element.attr("src", scope.camera.base64Img);
          // element.attr('src', attrs.daVvErrSrc);

          // if ((!scope.camera.available) || (new Date().getTime() - scope.camera.jpeg_retry > 20000)) {
          if (!scope.camera.available) {
            scope.camera.snapshot_url = scope.camera.error_url;
            scope.camera.available = false;
            element.attr("src", attrs.daVvErrSrc); //if after two session refresh retries and 20 seconds, error image is shown.
          } else if (
            new Date().getTime() - scope.camera.jpeg_retry > 5000 &&
            scope.camera.retry_count <= 2
          ) {
            scope.camera.retry_count++;
            scope.camera.get(); // after 5 seconds of retries, attempts to refresh Video Server session.
          } else {
            $timeout(function () {
              scope.camera.refreshSnapshotUrl(); // updates timestamp on end of url and retries after .5 seconds.
            }, 500);
            //this short timeout solved an issue with Chrome getting too many 404 errors too quickly
          }
        });
      });
    },
  };
});
