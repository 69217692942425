import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import BarCodeScanner from "components/MobileCamera/MobileCameraCheck";
import * as React from "react";
import { useHardwareModel, useVplexNumbers } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { getOnBoardZoneNumberRange, resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationExpanderSerialNumberField_zone$key } from "./__generated__/ZoneInformationExpanderSerialNumberField_zone.graphql";

export const ZONE_INFORMATION_EXPANDER_SERIAL_NUMBER =
  "zone-information-expander-serial-number-";

export const zoneInformationExpanderSerialNumberFieldId = (number: string) =>
  ZONE_INFORMATION_EXPANDER_SERIAL_NUMBER + number;

function ZoneInformationExpanderSerialNumberField() {
  const [
    { number, expanderSerialNumber, wireless, competitorWireless },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationExpanderSerialNumberField_zone$key>(
    graphql`
      fragment ZoneInformationExpanderSerialNumberField_zone on Zone {
        number
        expanderSerialNumber
        wireless
        competitorWireless
      }
    `
  );

  const numberTypeNumber = Number(number);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const fieldID = zoneInformationExpanderSerialNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const onboardZones = getOnBoardZoneNumberRange(hardwareModel);
  const vplexNumbers = useVplexNumbers();
  const disabled =
    wireless ||
    competitorWireless ||
    !number ||
    onboardZones.includes(numberTypeNumber) ||
    vplexNumbers.includes(numberTypeNumber);
  const { EXPANDER_SERIAL_NUMBER: RULES } =
    resolveZoneFieldRules(hardwareModel);
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldID}
      label="Expander Serial Number"
      disabled={disabled}
    >
      <div>
        <TextInput
          id={fieldID}
          name={fieldID}
          value={expanderSerialNumber ?? ""}
          className={`expander-${number}-textbox`}
          disabled={disabled}
          maxLength={10}
          pattern={RULES.PATTERN}
          inlineHelp={RULES.INLINE_HELP}
          title={RULES.INLINE_HELP}
          validationMessage={RULES.VALIDATION_MSG}
          onChange={({ target }) => {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(target.value, "expanderSerialNumber");
            });
          }}
          onBlur={({ target }) => {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(
                target.value === "" ? "0000000000" : target.value,
                "expanderSerialNumber"
              );
            });
          }}
        />
        {windowWidth <= 800 || window.navigator.userAgent.includes("iPad") ? (
          <BarCodeScanner updateZoneInformation={updateZoneInformation} />
        ) : null}
      </div>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationExpanderSerialNumberField;
