import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, NetworkOptions } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { NetworkOptionsContextProvider } from "../common/NetworkOptionsFields/NetworkOptionsContext";
import NetworkOptionsDhcpEnabledField from "../common/NetworkOptionsFields/NetworkOptionsDhcpEnabledField";
import NetworkOptionsDnsServerField from "../common/NetworkOptionsFields/NetworkOptionsDnsServerField";
import NetworkOptionsGatewayAddressField from "../common/NetworkOptionsFields/NetworkOptionsGatewayAddressField";
import NetworkOptionsLocalIpAddressField from "../common/NetworkOptionsFields/NetworkOptionsLocalIpAddressField";
import NetworkOptionsPassphraseField from "../common/NetworkOptionsFields/NetworkOptionsPassphraseField";
import NetworkOptionsSubnetMaskField from "../common/NetworkOptionsFields/NetworkOptionsSubnetMaskField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem$data,
  TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation,
} from "./__generated__/TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation.graphql";
import {
  TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation,
  TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation$data,
} from "./__generated__/TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation.graphql";
import { TMSentryNetworkOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/TMSentryNetworkOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Network Options";
export const conceptId = "tmsentry-network-options";

export const getState = (
  controlSystem: TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          networkOptions {
            __typename
            id
            wifiSsid
            wifiSecurity
            wifiPassphrase
            wifiPassphraseMaxLength
            dhcpEnabled
            localIpAddress
            gatewayAddress
            subnetMask
            dnsServer
            listenPort734N
            listenPort734NMin
            listenPort734NMax
            passphrase734N
            ipv6Enabled
            ipv6Address
            ipv6Gateway
            ipv6Prefix
            ipv6Dns
            programmingPort
            passphrase
            enableDdp
            ddpPassphrase
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshNetworkOptions(id: $id) {
      ... on RefreshNetworkOptionsSuccessPayload {
        __typename
        controlSystem {
          ...TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshNetworkOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Network Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshNetworkOptions: {
                    __typename: response.refreshNetworkOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Network Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Network Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation(
    $systemId: ID!
    $networkOptions: NetworkOptionsInput!
  ) {
    sendNetworkOptions(systemId: $systemId, networkOptions: $networkOptions) {
      ... on SendNetworkOptionsSuccessPayload {
        __typename
        controlSystem {
          id
          ...TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendNetworkOptionsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation$data,
  originalControlSystemData: TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendNetworkOptions.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshNetworkOptions: {
          __typename: "RefreshNetworkOptionsSuccessPayload",
          controlSystem: getState(response.sendNetworkOptions.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TMSentryNetworkOptionsProgrammingConceptFormNetworkOptionsSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { networkOptions },
        } = getState(props.controlSystem);
        if (networkOptions) {
          save({
            variables: {
              systemId,
              networkOptions: {
                wifiSsid: networkOptions.wifiSsid,
                wifiSecurity: networkOptions.wifiSecurity,
                wifiPassphrase: networkOptions.wifiPassphrase,
                dhcpEnabled: networkOptions.dhcpEnabled,
                localIpAddress: networkOptions.localIpAddress,
                gatewayAddress: networkOptions.gatewayAddress,
                subnetMask: networkOptions.subnetMask,
                dnsServer: networkOptions.dnsServer,
                listenPort734N: networkOptions.listenPort734N,
                passphrase734N: networkOptions.passphrase734N,
                ipv6Enabled: networkOptions.ipv6Enabled,
                ipv6Address: networkOptions.ipv6Address,
                ipv6Gateway: networkOptions.ipv6Gateway,
                ipv6Prefix: networkOptions.ipv6Prefix,
                ipv6Dns: networkOptions.ipv6Dns,
                programmingPort: networkOptions.programmingPort,
                enableDdp: networkOptions.enableDdp,
                ddpPassphrase: networkOptions.ddpPassphrase,
                passphrase: networkOptions.passphrase,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendNetworkOptions.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Network Options Programming Saved To the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendNetworkOptions.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendNetworkOptions.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readNetworkOptionsTemplateData = (
  programmingTemplateConcepts: TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        networkOptions {
          included
          dhcpEnabled {
            included
            data
          }
          localIpAddress {
            included
            data
          }
          gatewayAddress {
            included
            data
          }
          subnetMask {
            included
            data
          }
          dnsServer {
            included
            data
          }
          listenPort734N {
            included
            data
          }
          passphrase734N {
            included
            data
          }
          ipv6Enabled {
            included
            data
          }
          ipv6Address {
            included
            data
          }
          ipv6Gateway {
            included
            data
          }
          ipv6Prefix {
            included
            data
          }
          ipv6Dns {
            included
            data
          }
          passphrase {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).networkOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: TMSentryNetworkOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readNetworkOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const networkOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "networkOptions",
      "NetworkOptions"
    ) as unknown as RecordProxy<NetworkOptions>;

    applyTemplateScalarDataToRecordProxy(
      networkOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<TMSentryNetworkOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment TMSentryNetworkOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          panel {
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            networkOptions {
              ...NetworkOptionsContext_networkOptions
              ...NetworkOptionsDhcpEnabledField_networkOptions
              ...NetworkOptionsDnsServerField_networkOptions
              ...NetworkOptionsGatewayAddressField_networkOptions
              ...NetworkOptionsIpv6AddressField_networkOptions
              ...NetworkOptionsIpv6DnsServerField_networkOptions
              ...NetworkOptionsIpv6GatewayField_networkOptions
              ...NetworkOptionsIpv6PrefixBitsField_networkOptions
              ...NetworkOptionsListenPort734NField_networkOptions
              ...NetworkOptionsLocalIpAddressField_networkOptions
              ...NetworkOptionsPassphraseField_networkOptions
              ...NetworkOptionsPassphrase734NField_networkOptions
              ...NetworkOptionsSubnetMaskField_networkOptions
              ...NetworkOptionsUseIpv6Field_networkOptions
              ...NetworkOptionsXrX1PassphraseField_networkOptions
              ...NetworkOptionsXrX1CommunicationField_networkOptions
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHasWifiCommType_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseSoftwareDate_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    panel: {
      softwareVersion,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <NetworkOptionsContextProvider
        networkOptions={controlSystem.panel.networkOptions}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Network%20Options`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.networkOptions
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <NetworkOptionsDhcpEnabledField />
              <NetworkOptionsLocalIpAddressField />
              <NetworkOptionsGatewayAddressField />
              <NetworkOptionsSubnetMaskField />
              <NetworkOptionsDnsServerField />
              <NetworkOptionsPassphraseField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </NetworkOptionsContextProvider>
    </PanelContextProvider>
  );
}
