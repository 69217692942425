/**
 * @generated SignedSource<<8f4a68d077766c9ac671ccc77753ea28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly areas: {
      readonly __typename: "AreaConnection";
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "AreaEdge";
        readonly cursor: string | null;
        readonly node: {
          readonly __typename: string;
          readonly id: string;
          readonly isNew: boolean;
          readonly number: string;
          readonly " $fragmentSpreads": FragmentRefs<"TMSentryAreaInformationProgrammingConceptFormInline_area">;
        } | null;
      }>;
    };
    readonly id: string;
  };
  readonly " $fragmentType": "TMSentryAreaInformationProgrammingConceptFormInline_controlSystem";
};
export type TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryAreaInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryAreaInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "5b79db6875d12d69987e836bafa22cd8";

export default node;
