/**
 * @generated SignedSource<<73242a558801120bd3c1b13a87ff2e30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaBurglaryBellOutputNumberField_area$data = {
  readonly burglaryBellOutputNumber?: string;
  readonly number?: string;
  readonly " $fragmentType": "AreaBurglaryBellOutputNumberField_area";
};
export type AreaBurglaryBellOutputNumberField_area$key = {
  readonly " $data"?: AreaBurglaryBellOutputNumberField_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaBurglaryBellOutputNumberField_area">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "burglaryBellOutputNumber",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaBurglaryBellOutputNumberField_area",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrArea",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75Area",
      "abstractKey": null
    }
  ],
  "type": "Area",
  "abstractKey": "__isArea"
};
})();

(node as any).hash = "32b27cad88313d4271a57fbfbf9086ec";

export default node;
