/**
 * @generated SignedSource<<f732bb6dc3b439feea5d20e3220b3e3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation$data = {
  readonly morningAmbush: number;
  readonly " $fragmentType": "SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation";
};
export type SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation$key = {
  readonly " $data"?: SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "morningAmbush",
      "storageKey": null
    }
  ],
  "type": "SystemAreaInformation",
  "abstractKey": null
};

(node as any).hash = "af07c590085bb93928fe893027ec8872";

export default node;
