App.directive("daAutoProgStatus", [
  "$q",
  "OnlinePanelService",
  "UserService",
  "$interval",
  "$filter",
  "JobSchedulerService",

  function daAutoProgStatus(
    $q,
    OnlinePanelService,
    UserService,
    $interval,
    $filter,
    JobSchedulerService
  ) {
    return {
      restrict: "E",

      scope: {
        panelId: "=",
      },
      link: function (scope, element, attrs) {
        scope.checkAutoProgStatus = checkAutoProgStatus;
        scope.abortAutoProgramming = abortAutoProgramming;
        scope.programmingAlert = programmingAlert;

        scope.stopChecking = stopChecking;

        //Function to determine the state of the programming status alert
        function programmingAlert(status) {
          var alert = {};

          switch (status) {
            case "new":
              alert.type = "info";
              alert.icon = "dmp-icon-radial_info";
              alert.status = "initiated";
              break;
            case "scheduled":
              alert.type = "info";
              alert.icon = "dmp-icon-time_clock";
              alert.status = "scheduled";
              break;
            case "running":
              alert.type = "info";
              alert.icon = "dmp-icon-radial_info";
              alert.status = "in progress";
              break;
            case "retry":
              alert.type = "warning";
              alert.icon = "dmp-icon-cycle icon-spin";
              alert.status = "retrying";
              break;
            case "complete":
              alert.type = "success";
              alert.icon = "dmp-icon-radial_check";
              alert.status = "complete";
              break;
            case "fail":
              alert.type = "danger";
              alert.icon = "dmp-icon-radial_alert";
              alert.status = "failed";
              break;
          }
          return alert;
        }

        var AUTO_PROG_CHECK_TIMES = 200;

        /**
         * Attempts to kill the programming job
         */
        function abortAutoProgramming() {
          scope.autoProgrammingCanceled = true;
          if (
            !scope.autoProgStatus ||
            !scope.autoProgStatus.SchedulerJobGroupId
          )
            return;

          JobSchedulerService.abortJobGroup(
            scope.autoProgStatus.SchedulerJobGroupId
          )
            .then(
              function () {
                //scope.autoProgStatus = {};
                scope.checkAutoProgStatus();
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error Canceling Auto Programming",
                  json: error,
                });
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        var checker; //Variable to keep track of counting times

        /**
         * Function to check Auto Programming status.  Checks every 3 seconds until 100 times or you get a terminal
         * status (fail, retry, complete).
         * @returns {*}
         */
        function checkAutoProgStatus() {
          scope.stopChecking();
          var deferred = $q.defer();
          // Don't start a new checker if we are already checking
          if (angular.isDefined(checker)) return;
          var timesChecked = 0;

          UserService.dealerInfo
            .getAutoProgStatus(scope.panelId)
            .then(
              function (data) {
                scope.autoProgStatus = data;
                var nextAttemptDate = new Date(
                  scope.autoProgStatus.NextAttempt
                );
                scope.showStatus =
                  new Date() <
                  new Date(
                    nextAttemptDate.getFullYear(),
                    nextAttemptDate.getMonth(),
                    nextAttemptDate.getDate() + 1,
                    nextAttemptDate.getHours(),
                    nextAttemptDate.getMinutes(),
                    nextAttemptDate.getSeconds(),
                    nextAttemptDate.getMilliseconds()
                  );
                checker = $interval(function () {
                  if (
                    (scope.autoProgStatus.Status === "scheduled" ||
                      scope.autoProgStatus.Status === "running" ||
                      scope.autoProgStatus.Status === "retry" ||
                      scope.autoProgStatus.Status === "new") &&
                    timesChecked < AUTO_PROG_CHECK_TIMES
                  ) {
                    timesChecked++;
                    UserService.dealerInfo
                      .getAutoProgStatus(scope.panelId)
                      .then(
                        function (data) {
                          scope.autoProgStatus = data;
                        },
                        function (error) {
                          scope.stopChecking();
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  } else if (timesChecked === AUTO_PROG_CHECK_TIMES) {
                    scope.stopChecking();
                  } else {
                    // Reset the canceled flag so that the real status appears in the app
                    scope.stopChecking();
                    if (scope.autoProgStatus.Status === "complete")
                      deferred.resolve();
                    else deferred.reject();
                  }
                }, 3000);
              },
              function (error) {
                scope.autoProgStatus = [];
                scope.autoProgStatus.Status = "unknown";
                scope.stopChecking();
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        }

        /**
         * Function to stop checking Auto Prog status
         */
        function stopChecking() {
          //scope.autoProgStatus = undefined;
          if (angular.isDefined(checker)) {
            $interval.cancel(checker);
            checker = undefined;
          }
        }
        checkAutoProgStatus();
      },

      templateUrl: "/app/common/templates/auto-prog-status.html",
    };
  },
]);
