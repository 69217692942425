/**
 * @generated SignedSource<<14cce5e08dc589f6169865d662c5ee78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsOutputCutoffTimeField_outputOptions$data = {
  readonly outputCutoffTime: number;
  readonly outputCutoffTimeMax: number;
  readonly outputCutoffTimeMin: number;
  readonly " $fragmentType": "OutputOptionsOutputCutoffTimeField_outputOptions";
};
export type OutputOptionsOutputCutoffTimeField_outputOptions$key = {
  readonly " $data"?: OutputOptionsOutputCutoffTimeField_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsOutputCutoffTimeField_outputOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsOutputCutoffTimeField_outputOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputCutoffTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputCutoffTimeMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputCutoffTimeMax",
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};

(node as any).hash = "b2c66d6edc5795eb0f5603477a57b813";

export default node;
