/**
 * @generated SignedSource<<023818b11b897577275868353b47bdbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly outputOptions: {
    readonly acFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ambushOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAllOrAwayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedHomeOrPerimeterOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedSleepOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly beginExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly closingWaitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly communicationFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly coolSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cutoffOutputs: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly endExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly heatSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lateToCloseOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly lockdownOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly panicAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly readyOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteArmingOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sensorResetOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly telephoneTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneMonitorOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "05d25e13b4204f377a822b9351a6c490";

export default node;
