/**
 * @ngdoc service
 * @name App.factory:BayAlarmBannerApi
 *
 * @description
 * API factory for Third Party Video
 *
 */

App.factory("BayAlarmBannerApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/keypads/:keypad_num/:type/:clear",
      {},
      {
        bannerSend: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            keypad_num: "@keypad",
            type: "banner",
          },
        },
        bannerClear: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            keypad_num: "@keypad",
            type: "banner",
            clear: "clear",
          },
        },
        messageSend: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            keypad_num: "@keypad",
            type: "message",
          },
        },
        messageClear: {
          method: "POST",
          params: {
            panel_id: "@panel_id",
            keypad_num: "@keypad",
            type: "message",
            clear: "clear",
          },
        },
      }
    );
  },
]);
