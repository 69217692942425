/**
 * @generated SignedSource<<afbf73016b30c29f30eda71b23a50869>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TakeoverPanelSetupEventStatus = "FAILED" | "NO_EVENT_AVAILABLE" | "SUCCESS";
export type TakeoverPanelSetupEventMutation$variables = {
  systemId: string;
};
export type TakeoverPanelSetupEventMutation$data = {
  readonly takeoverPanelSetupEvent: {
    readonly status: TakeoverPanelSetupEventStatus;
  };
};
export type TakeoverPanelSetupEventMutation = {
  response: TakeoverPanelSetupEventMutation$data;
  variables: TakeoverPanelSetupEventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "systemId",
        "variableName": "systemId"
      }
    ],
    "concreteType": "TakeoverPanelSetupEventResult",
    "kind": "LinkedField",
    "name": "takeoverPanelSetupEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TakeoverPanelSetupEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TakeoverPanelSetupEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b39aa6c8e727fb83636cc01f3f840029",
    "id": null,
    "metadata": {},
    "name": "TakeoverPanelSetupEventMutation",
    "operationKind": "mutation",
    "text": "mutation TakeoverPanelSetupEventMutation(\n  $systemId: ID!\n) {\n  takeoverPanelSetupEvent(systemId: $systemId) {\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "a18252160f2d8a31a82d868571e371f3";

export default node;
