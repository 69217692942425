/**
 * @generated SignedSource<<9627883150fc625c761e54759f4f475d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts" | "XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts" | "XRCardFormatsTemplateDataInline_xrProgrammingTemplateConcepts" | "XRCommunicationPathTemplateDataInline_xrProgrammingTemplateConcepts" | "XRDeviceInformationTemplateDataInline_xrProgrammingTemplateConcepts" | "XRKeyfobsTemplateDataInline_xrProgrammingTemplateConcepts" | "XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts" | "XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts" | "XRNetworkOptionsTemplateDataInline_xrProgrammingTemplateConcepts" | "XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts" | "XROutputInformationTemplateDataInline_xrProgrammingTemplateConcepts" | "XROutputOptionsTemplateDataInline_xrProgrammingTemplateConcepts" | "XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts" | "XRRemoteOptionsTemplateDataInline_xrProgrammingTemplateConcepts" | "XRSecurityGradeTemplateDataInline_xrProgrammingTemplateConcepts" | "XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts" | "XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts" | "XRSystemOptionsTemplateDataInline_XrProgrammingTemplateConcepts" | "XRSystemReportsTemplateDataInline_xrProgrammingTemplateConcepts" | "XRZoneInformationTemplateDataInline_xrProgrammingTemplateConcepts">;
  readonly " $fragmentType": "XRTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "55dad06921a2e11d642e32b42c71a59b";

export default node;
