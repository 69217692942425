import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import EditIntegrationForm from "./EditIntegrationForm";
import { NewIntegrationForm } from "./NewIntegrationForm";
import { IntegrationForm_Query$key } from "./__generated__/IntegrationForm_Query.graphql";

const IntegrationModal: React.FC<
  React.PropsWithChildren<{
    id?: string;
    queryRef: IntegrationForm_Query$key;
    onCancel: () => void;
  }>
> = ({ id, queryRef, onCancel }) => {
  const dealer = useFragment(
    graphql`
      fragment IntegrationForm_Query on Query {
        ...NewIntegrationForm_Query
        ...EditIntegrationForm_query
      }
    `,
    queryRef
  );

  return id ? (
    <EditIntegrationForm id={id} queryRef={dealer} onCancel={onCancel} />
  ) : (
    <NewIntegrationForm queryRef={dealer} onCancel={onCancel} />
  );
};

export default IntegrationModal;
