/**
 * @generated SignedSource<<81f48e5ff88c5e1834c8810eff689fde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputGroupsProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly maxOutputGroups: number;
    readonly outputGroups: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XFOutputGroupsProgrammingConceptFormInline_outputGroup">;
    }>;
    readonly outputGroupsRange: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "XFOutputGroupsProgrammingConceptFormInline_controlSystem";
};
export type XFOutputGroupsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFOutputGroupsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputGroupsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputGroupsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "4512860be382a42506647ebec7046421";

export default node;
