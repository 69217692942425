import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsIntegratorZoneReportsField_remoteOptions$key } from "./__generated__/RemoteOptionsIntegratorZoneReportsField_remoteOptions.graphql";

export const remoteOptionsIntegratorZoneReportsFieldId = () =>
  "remote-options-integrator-zone-reports";

function RemoteOptionsIntegratorZoneReportsField() {
  const [{ integratorZoneReports, integratorConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsIntegratorZoneReportsField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsIntegratorZoneReportsField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            integratorZoneReports
            integratorConnection
          }
          ... on XfRemoteOptions {
            integratorZoneReports
            integratorConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsIntegratorZoneReportsFieldId();
  const label = "Integrator Zone Reports";
  const disabled = integratorConnection === RemoteOptionsConnectionType.NONE;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
    >
      <Switch
        disabled={disabled}
        label={label}
        id={fieldId}
        checked={!!integratorZoneReports}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !integratorZoneReports,
              "integratorZoneReports"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsIntegratorZoneReportsField;
