/**
 * @generated SignedSource<<fcc893a12d1571dafa136400da0298ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HanwhaNvrs_site$data = {
  readonly hanwhaNvrs: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HanwhaNvrs_hanwhaNvr">;
  }>;
  readonly " $fragmentType": "HanwhaNvrs_site";
};
export type HanwhaNvrs_site$key = {
  readonly " $data"?: HanwhaNvrs_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"HanwhaNvrs_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HanwhaNvrs_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HanwhaNvr",
      "kind": "LinkedField",
      "name": "hanwhaNvrs",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HanwhaNvrs_hanwhaNvr"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "3edea6c6f6bb2ac14e9ddf88e585dec1";

export default node;
