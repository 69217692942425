import graphql from "babel-plugin-relay/macro";
import Button from "components/Button";
import { Flex } from "components/DaStyledElements";
import Modal from "components/Modal";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import styled from "styled-components/macro";
import { PingPanelQuery } from "./__generated__/PingPanelQuery.graphql";

type PingPanelType = {
  systemId: string;
  hubId: string;
  hubName?: string;
  setIsPingPanelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPingPanelModalOpen: boolean;
  spacerRight?: boolean;
};

const PingPanel = ({
  hubId,
  hubName,
  systemId,
  setIsPingPanelModalOpen,
  isPingPanelModalOpen,
  spacerRight,
}: PingPanelType) => {
  return (
    <>
      <Button
        className={spacerRight ? "mar-r-8" : ""}
        design="default"
        type="button"
        onClick={() => {
          setIsPingPanelModalOpen(true);
        }}
      >
        XR Panel Ping
      </Button>

      {isPingPanelModalOpen ? (
        <PingPanelModal
          setIsPingPanelModalOpen={setIsPingPanelModalOpen}
          hubId={hubId}
          hubName={hubName ? hubName : ""}
          systemId={systemId}
        />
      ) : null}
    </>
  );
};

type PingPanelModalTypes = {
  setIsPingPanelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hubId: string;
  hubName: string;
  systemId: string;
};
const PingPanelModal = ({
  setIsPingPanelModalOpen,
  hubId,
  hubName,
  systemId,
}: PingPanelModalTypes) => {
  const data = useLazyLoadQuery<PingPanelQuery>(
    graphql`
      query PingPanelQuery($systemId: String!, $hubId: String!) {
        getCamectPingPanel(systemId: $systemId, hubId: $hubId) {
          ... on CamectPingPanelSuccessPayload {
            success
            error
            result
          }
          ... on CamectPingPanelErrorPayload {
            error
          }
        }
      }
    `,
    { systemId: systemId, hubId: hubId }
  );

  const dateTimeStamp = new Date().toISOString();

  const pingJson = JSON.stringify(data, null, 2);

  return (
    <Modal size="large" onClickOutside={() => setIsPingPanelModalOpen(false)}>
      <Modal.Header>
        <Flex.Row justify="space-between">
          <h4>Ping for {hubName ? hubName : "Gateway"}</h4>
          <span>
            <button
              className="btn btn-default btn-sm mar-l-8"
              onClick={() => setIsPingPanelModalOpen(false)}
            >
              Close
            </button>
          </span>
        </Flex.Row>
      </Modal.Header>
      <ScrollingModalBody>
        <pre>{pingJson}</pre>
        <CloseButtonContainer></CloseButtonContainer>
      </ScrollingModalBody>
    </Modal>
  );
};

const ScrollingModalBody = styled(Modal.Body)`
  max-height: 60rem;
  overflow-y: scroll;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export default PingPanel;
