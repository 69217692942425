import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsCrossZoneTimeField_systemOptions$key } from "./__generated__/SystemOptionsCrossZoneTimeField_systemOptions.graphql";

export const systemOptionsCrossZoneTimeFieldId = () =>
  "system-options-cross-zone-time";

function CrossZoneTimeField() {
  const [
    { crossZoneTime, crossZoneTimeMin, crossZoneTimeMax },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsCrossZoneTimeField_systemOptions$key>(
    graphql`
      fragment SystemOptionsCrossZoneTimeField_systemOptions on SystemOptions {
        crossZoneTime
        crossZoneTimeMin
        crossZoneTimeMax
      }
    `
  );

  const fieldId = systemOptionsCrossZoneTimeFieldId();
  const originalValue = React.useRef(crossZoneTime).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Cross Zone Time"
      tooltip="Set the Cross Zone duration in seconds. Applies to any Zones programmed to use the Cross Zone feature."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={crossZoneTime?.toString()}
        required
        inlineHelp={`0, ${crossZoneTimeMin}–${crossZoneTimeMax}`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "crossZoneTime"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : valueAsNumber === 0
                ? valueAsNumber
                : clamp(crossZoneTimeMin, crossZoneTimeMax, valueAsNumber);
            recordProxy.setValue(Number(value), "crossZoneTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CrossZoneTimeField;
