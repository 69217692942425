/**
 * @generated SignedSource<<05d30e9bc9d0d71afacd6e720f1e6394>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputInformationNameField_output$data = {
  readonly name: string;
  readonly number: number;
  readonly " $fragmentType": "OutputInformationNameField_output";
};
export type OutputInformationNameField_output$key = {
  readonly " $data"?: OutputInformationNameField_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationNameField_output">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputInformationNameField_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Output",
  "abstractKey": null
};

(node as any).hash = "71de93c2b774bc1e477442336d98f6c3";

export default node;
