import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, RemoteOptions } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import RemoteOptionsAlarmReceiverAuthorizationField from "../common/RemoteOptionsFields/RemoteOptionsAlarmReceiverAuthorizationField";
import RemoteOptionsAppKeyField from "../common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import RemoteOptionsArmedAnswerRingsField from "../common/RemoteOptionsFields/RemoteOptionsArmedAnswerRingsField";
import { RemoteOptionsContextProvider } from "../common/RemoteOptionsFields/RemoteOptionsContext";
import RemoteOptionsDisarmedAnswerRingsField from "../common/RemoteOptionsFields/RemoteOptionsDisarmedAnswerRingsField";
import RemoteOptionsRemoteDisarmField from "../common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import RemoteOptionsRemoteKeyField from "../common/RemoteOptionsFields/RemoteOptionsRemoteKeyField";
import RemoteOptionsServiceReceiverAuthorizationField from "../common/RemoteOptionsFields/RemoteOptionsServiceReceiverAuthorizationField";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  XTRemoteOptionsProgrammingConceptFormInline_controlSystem$data,
  XTRemoteOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XTRemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XTRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation,
} from "./__generated__/XTRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation.graphql";
import {
  XTRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation,
  XTRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
} from "./__generated__/XTRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation.graphql";
import { XTRemoteOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XTRemoteOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Remote Options";
export const conceptId = "xt-remote-options";

export const getState = (
  controlSystem: XTRemoteOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTRemoteOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          remoteOptions {
            id
            ... on XtRemoteOptions {
              __typename
              remoteKey
              remoteDisarm
              appKey
              armedAnswerRings
              disarmedAnswerRings
              alarmReceiverAuthorization
              serviceReceiverAuthorization
            }
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XTRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshRemoteOptions(id: $id) {
      ... on RefreshRemoteOptionsSuccessPayload {
        __typename
        controlSystem {
          ...XTRemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XTRemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XTRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshRemoteOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Remote Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshRemoteOptions: {
                    __typename: response.refreshRemoteOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Remote Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Remote Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XTRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation(
    $systemId: ID!
    $remoteOptions: XtRemoteOptionsInput!
  ) {
    sendXtRemoteOptionsProgramming(
      systemId: $systemId
      remoteOptions: $remoteOptions
    ) {
      ... on SendRemoteOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...XTRemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendRemoteOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XTRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
  originalControlSystemData: XTRemoteOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXtRemoteOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshRemoteOptions: {
          __typename: "RefreshRemoteOptionsSuccessPayload",
          controlSystem: getState(
            response.sendXtRemoteOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XTRemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XTRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { remoteOptions },
        } = getState(props.controlSystem);
        if (remoteOptions) {
          save({
            variables: {
              systemId,
              remoteOptions: {
                remoteDisarm: !!remoteOptions.remoteDisarm,
                appKey: remoteOptions.appKey,
                armedAnswerRings: remoteOptions.armedAnswerRings?.toString(),
                disarmedAnswerRings:
                  remoteOptions.disarmedAnswerRings?.toString(),
                alarmReceiverAuthorization:
                  remoteOptions.alarmReceiverAuthorization,
                serviceReceiverAuthorization:
                  remoteOptions.serviceReceiverAuthorization,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXtRemoteOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Remote Options Programming Saved To the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendXtRemoteOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXtRemoteOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readRemoteOptionsTemplateData = (
  programmingTemplateConcepts: XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          included
          remoteDisarm {
            included
            data
          }
          appKey {
            included
            data
          }
          armedAnswerRings {
            included
            data
          }
          disarmedAnswerRings {
            included
            data
          }
          alarmReceiverAuthorization {
            included
            data
          }
          serviceReceiverAuthorization {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).remoteOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readRemoteOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const remoteOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "remoteOptions",
      "RemoteOptions"
    ) as unknown as RecordProxy<RemoteOptions>;

    applyTemplateScalarDataToRecordProxy(
      remoteOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [{ panel }] =
    useControlSystemFragment<XTRemoteOptionsProgrammingConceptForm_controlSystem$key>(graphql`
      fragment XTRemoteOptionsProgrammingConceptForm_controlSystem on ControlSystem {
        panel {
          softwareVersion
          hardwareModel
          helpFiles {
            programmingGuideUrl
            installGuideUrl
          }
          remoteOptions {
            ... on XtRemoteOptions {
              ...RemoteOptionsContext_remoteOptions
              ...RemoteOptionsRemoteKeyField_remoteOptions
              ...RemoteOptionsRemoteDisarmField_remoteOptions
              ...RemoteOptionsArmedAnswerRingsField_remoteOptions
              ...RemoteOptionsDisarmedAnswerRingsField_remoteOptions
              ...RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions
              ...RemoteOptionsServiceReceiverAuthorizationField_remoteOptions
              ...RemoteOptionsAppKeyField_remoteOptions
            }
          }
          ...PanelContextUseHardwareModel_panel
        }
      }
    `);

  const {
    remoteOptions,
    hardwareModel,
    helpFiles: { programmingGuideUrl },
  } = panel;

  const supportsDialer = ["XT30", "XT30L", "XT50", "XT50L"].includes(
    hardwareModel
  );

  return (
    <PanelContextProvider panel={panel}>
      <RemoteOptionsContextProvider remoteOptions={remoteOptions}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Remote%20Options`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(remoteOptions)}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <RemoteOptionsRemoteKeyField />
              <RemoteOptionsRemoteDisarmField />
              {supportsDialer && (
                <>
                  <RemoteOptionsArmedAnswerRingsField />
                  <RemoteOptionsDisarmedAnswerRingsField />
                  <RemoteOptionsAlarmReceiverAuthorizationField />
                  <RemoteOptionsServiceReceiverAuthorizationField />
                </>
              )}
              <RemoteOptionsAppKeyField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </RemoteOptionsContextProvider>
    </PanelContextProvider>
  );
}
