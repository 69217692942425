import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { remoteOptionsApnFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsApnField";
import { remoteOptionsAppKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { remoteOptionsRemoteDisarmFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import { XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/__generated__/XT75RemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getRemoteOptionsState } from "../../../XT75FullProgramming/XT75RemoteOptionsProgrammingConceptForm";

import { Xt75ProgrammingTemplateRemoteOptionsInput } from "../Create/__generated__/XT75TemplateSaveMutation.graphql";
import { XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          id
          included
          remoteDisarm {
            data
            included
          }
          appKey {
            data
            included
          }
          firstApn {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): Xt75ProgrammingTemplateRemoteOptionsInput => {
  const {
    panel: { remoteOptions },
  } = getRemoteOptionsState(controlSystem);

  if (!remoteOptions) {
    return { included: false };
  }

  const remoteOptionsInputFields =
    remoteOptions?.__typename === "Xt75RemoteOptions"
      ? {
          remoteDisarm: {
            data: remoteOptions.remoteDisarm,
            included: includedFields.has(remoteOptionsRemoteDisarmFieldId()),
          },
          appKey: {
            data: remoteOptions.appKey,
            included: includedFields.has(remoteOptionsAppKeyFieldId()),
          },
          firstApn: {
            data: remoteOptions.firstApn,
            included: includedFields.has(remoteOptionsApnFieldId()),
          },
        }
      : {};

  const remoteOptionsIncluded = Object.values(remoteOptionsInputFields).some(
    (field) => field?.included
  );

  return { included: remoteOptionsIncluded, ...remoteOptionsInputFields };
};

export const setIncludedFields = (
  template: XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { remoteOptions } = getState(template);

  if (!remoteOptions) return;

  if (remoteOptions.remoteDisarm?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteDisarmFieldId()));
  }
  if (remoteOptions.appKey?.included) {
    setIncludedFields(setToggle(remoteOptionsAppKeyFieldId()));
  }
  if (remoteOptions.firstApn?.included) {
    setIncludedFields(setToggle(remoteOptionsApnFieldId()));
  }
};
