/**
 * @generated SignedSource<<526cd8a22402e8b770c456c421ebe211>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationZoneAuditDaysField_zone$data = {
  readonly number: string;
  readonly type: ZoneType;
  readonly zoneAuditDays: string;
  readonly " $fragmentType": "ZoneInformationZoneAuditDaysField_zone";
};
export type ZoneInformationZoneAuditDaysField_zone$key = {
  readonly " $data"?: ZoneInformationZoneAuditDaysField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationZoneAuditDaysField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationZoneAuditDaysField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zoneAuditDays",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "871c12056164837e30b9acaa8f14f206";

export default node;
