/**
 * @generated SignedSource<<f30fa14fec2a6bbecdd5d0621f2a365a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneTransmitterContact = "CONTACT_3" | "CONTACT_4" | "EXTERNAL" | "INTERNAL";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationSerialNumberField_ZoneList_panel$data = {
  readonly zoneInformations: ReadonlyArray<{
    readonly contactNumber: ZoneTransmitterContact | null;
    readonly id: string;
    readonly number: string;
    readonly serialNumber: string;
    readonly wireless: boolean;
  }>;
  readonly " $fragmentType": "ZoneInformationSerialNumberField_ZoneList_panel";
};
export type ZoneInformationSerialNumberField_ZoneList_panel$key = {
  readonly " $data"?: ZoneInformationSerialNumberField_ZoneList_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationSerialNumberField_ZoneList_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationSerialNumberField_ZoneList_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zoneInformations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wireless",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contactNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "967a495af5ff3fc37d480321aa6cbcfa";

export default node;
