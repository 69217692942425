import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { formatListOfNumbers } from "../../utils/format";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationArmAreasField_zone$key } from "./__generated__/ZoneInformationArmAreasField_zone.graphql";

export const zoneInformationArmAreasFieldId = (number: string) =>
  `zone-information-arm-areas-${number}`;

function ZoneInformationArmAreasField() {
  const [{ number, armedAreasForArmingZone, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationArmAreasField_zone$key>(
      graphql`
        fragment ZoneInformationArmAreasField_zone on Zone {
          number
          armedAreasForArmingZone
          type
        }
      `
    );

  const fieldId = zoneInformationArmAreasFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const disabled = type !== ZoneType.ARMING;
  const { ARMED_AREAS: RULES } = resolveZoneFieldRules(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Arm Areas"
      disabled={disabled}
      tooltip="Select which Area(s) will Arm when this Arming Zone is activated."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        value={armedAreasForArmingZone}
        pattern={RULES?.PATTERN}
        inlineHelp={RULES?.INLINE_HELP}
        validationMessage={RULES?.VALIDATION_MSG}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(`${target.value}`, "armedAreasForArmingZone");
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(`${target.value}`),
              "armedAreasForArmingZone"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationArmAreasField;
