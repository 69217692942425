import noop from "common/utils/universal/noop";
import * as React from "react";

const RefreshSpecifiedConceptsContext =
  React.createContext<(concepts: []) => void>(noop);

export const useRefreshSpecifiedConceptsContext = () =>
  React.useContext(RefreshSpecifiedConceptsContext);

export default RefreshSpecifiedConceptsContext;
