import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZoneSupervisionTime, ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationSupervisionTimeField_zone$key } from "./__generated__/ZoneInformationSupervisionTimeField_zone.graphql";

export const zoneInformationSupervisionTimeFieldId = (number: string) =>
  `zone-information-supervision-time-${number}`;

function ZoneInformationSupervisionTimeField() {
  const [
    { number, wireless, competitorWireless, supervisionTime, type },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationSupervisionTimeField_zone$key>(
    graphql`
      fragment ZoneInformationSupervisionTimeField_zone on Zone {
        number
        type
        wireless
        competitorWireless
        supervisionTime
      }
    `
  );

  const fieldId = zoneInformationSupervisionTimeFieldId(String(number));
  const disabled = !wireless && !competitorWireless;
  //Competitor Wireless gets the 720 and 1440 min options
  const hasCompetitorWireless = !!competitorWireless;
  //Fire and Supervisory Zones get a 3 minute option
  const hasThreeMinuteCheckinEnabled = [
    ZoneType.FIRE,
    ZoneType.FIRE_VERIFY,
    ZoneType.SUPERVISORY,
  ].includes(type as ZoneType);
  const competitorWirelessHasFourHourCheckin =
    hasCompetitorWireless &&
    [ZoneType.FIRE, ZoneType.FIRE_VERIFY, ZoneType.SUPERVISORY].includes(
      type as ZoneType
    );
  const supervisionTimeOptions = !hasCompetitorWireless
    ? // DMP Wireless Options
      [
        [ZoneSupervisionTime.NONE, "None"],
        hasThreeMinuteCheckinEnabled || type === ZoneType.CARBON_MONOXIDE
          ? [ZoneSupervisionTime._3_MIN, "3 min"]
          : null,
        [ZoneSupervisionTime._60_MIN, "60 min"],
        [ZoneSupervisionTime._240_MIN, "240 min"],
      ]
    : // Competitor Wireless Options
      [
        [ZoneSupervisionTime.NONE, "None"],
        competitorWirelessHasFourHourCheckin
          ? [ZoneSupervisionTime._240_MIN, "4 hour"]
          : null,
        [ZoneSupervisionTime._720_MIN, "12 hour"],
        [ZoneSupervisionTime._1440_MIN, "24 hour"],
      ];

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Supervision Time"
      tooltip="If using check-in supervision ('None' is not selected), this represents the maximum duration this Zone can fail to check-in before going missing."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={supervisionTime ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "supervisionTime");
          });
        }}
      >
        {supervisionTimeOptions
          .filter(isNotNullOrUndefined)
          .map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationSupervisionTimeField;
