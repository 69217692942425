/**
 * @generated SignedSource<<d9a2ac2cc494a13ca60dda5e5ff62e3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryLockoutCodeProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "e218a5d722a3d323847229f41fcfccf5";

export default node;
