import graphql from "babel-plugin-relay/macro";
import Alert from "components/Alert";
import Icon from "components/Icon";
import React from "react";
import { useRefetchableFragment } from "react-relay";
import { VideoDeviceSettingConfirmation } from "securecom-graphql/client";
import styled from "styled-components";
import { OutOfSyncBanner_secureComCamera$key } from "./__generated__/OutOfSyncBanner_secureComCamera.graphql";
import { OutOfSyncBanner_secureComCameraQuery } from "./__generated__/OutOfSyncBanner_secureComCameraQuery.graphql";

export function OutOfSyncBanner(props: {
  secureComCameraFragment: OutOfSyncBanner_secureComCamera$key;
}) {
  const [{ settingConfirmation }, refetchJobStatus] = useRefetchableFragment<
    OutOfSyncBanner_secureComCameraQuery,
    OutOfSyncBanner_secureComCamera$key
  >(
    graphql`
      fragment OutOfSyncBanner_secureComCamera on SecureComCamera
      @refetchable(queryName: "OutOfSyncBanner_secureComCameraQuery") {
        settingConfirmation
      }
    `,
    props.secureComCameraFragment
  );

  return settingConfirmation &&
    ![
      VideoDeviceSettingConfirmation.PENDING,
      VideoDeviceSettingConfirmation.SENT,
      VideoDeviceSettingConfirmation.CONFIRMED,
    ].includes(settingConfirmation as VideoDeviceSettingConfirmation) ? (
    <VideoOutOfSyncAlert>
      <Alert type="info" centerContent icon={<Icon name="radial_info"></Icon>}>
        <>NOTE: Saving this page will resync camera analytic regions.</>
      </Alert>
    </VideoOutOfSyncAlert>
  ) : null;
}

const VideoOutOfSyncAlert = styled.div`
  width: 33%;
  margin: auto;
`;
