/**
 * @generated SignedSource<<b676094911af7c54a77899b0f6ce41be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type ZoneRemoteZoneType = "LOCAL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseRemoteZoneList_panel$data = {
  readonly hardwareModel: PanelHardwareModel;
  readonly zoneInformations: ReadonlyArray<{
    readonly number: string;
    readonly remoteZoneType: ZoneRemoteZoneType | null;
  }>;
  readonly " $fragmentType": "PanelContextUseRemoteZoneList_panel";
};
export type PanelContextUseRemoteZoneList_panel$key = {
  readonly " $data"?: PanelContextUseRemoteZoneList_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseRemoteZoneList_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseRemoteZoneList_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hardwareModel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zoneInformations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remoteZoneType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "c3a75fed7fe25f28ea63264d49f6b449";

export default node;
