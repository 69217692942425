/**
 * @ngdoc directive
 * @name EdisonDirectives.directive:confirmButton
 * @function
 *
 * @description
 * Provides a confirmation popup dialog on any form input (button, input, etc).
 *
 * Example:
 * <pre> <button class="btn btn-small btn-secondary btn-caution" da-confirm-button="_deleteUserAssociation(_userCodes[_userToEdit])"
 * message="Are you sure you want to delete this user?" yes="Confirm" no="Cancel">Delete Login</button>  </pre>
 */
App.directive(
  "daConfirmButton",
  function ($document, $parse, $rootScope, $sanitize) {
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        let buttonId,
          html,
          message,
          nope,
          title,
          yep,
          placement,
          extraClasses,
          confirmAppendToBody;

        buttonId = Math.floor(Math.random() * 10000000000);

        attrs.buttonId = buttonId;

        message = $sanitize(attrs.message) || "Are you sure?";
        yep = $sanitize(attrs.yes) || "Yes";
        nope = $sanitize(attrs.no) || "No";
        title = $sanitize(attrs.title) || "Confirm";
        btnClassYes = $sanitize(attrs.btnClassYes) || "btn-alert";
        btnClassNo = $sanitize(attrs.btnClassNo) || "btn-dmp";
        confirmAppendToBody = $sanitize(attrs.confirmAppendToBody) || false;
        placement = attrs.location || "top";

        html =
          '<div id="button-' +
          buttonId +
          '" >\n  <p class="confirmbutton-msg">' +
          message +
          '</p>\n<p><button class="confirmbutton-yes btn ' +
          btnClassYes +
          ' btn-xs">' +
          yep +
          '</button>&nbsp;&nbsp;<button class="confirmbutton-no btn ' +
          btnClassNo +
          ' btn-xs">' +
          nope +
          "</button></p>\n</div>";

        element.popover({
          content: html,
          html: true,
          trigger: "manual",
          title: title,
          placement: placement,
          animation: false,
          container: confirmAppendToBody ? "body" : null,
        });
        return element.bind("click", function (e) {
          var dontBubble, pop;
          dontBubble = true;

          e.stopPropagation();

          element.popover("show");

          pop = $("#button-" + buttonId);

          pop.closest(".popover").click(function (e) {
            if (dontBubble) {
              e.stopPropagation();
            }
          });

          pop.find(".confirmbutton-yes").click(function (e) {
            dontBubble = true;
            e.stopPropagation();

            element.popover("hide");

            var func = $parse(attrs.daConfirmButton);
            func(scope);
          });

          pop.find(".confirmbutton-no").click(function (e) {
            dontBubble = false;

            $document.off("click.confirmbutton." + buttonId);

            element.popover("hide");
          });

          $document.on(
            "click.confirmbutton." + buttonId,
            ":not(.popover, .popover *)",
            function () {
              $document.off("click.confirmbutton." + buttonId);
              element.popover("hide");
            }
          );
          const locationChangeListener = $rootScope.$on(
            "$locationChangeStart",
            () => {
              element.popover("hide"); // Close the popup if navigating away from the page.
              locationChangeListener(); // This unregisters the listener.
            }
          );
        });
      },
    };
  }
);
