import { useEffect, useState, useLayoutEffect, MutableRefObject } from "react";
import useDebounce from "./useDebounce";

type Rect = Pick<
  DOMRect,
  "top" | "right" | "bottom" | "left" | "width" | "height" | "x" | "y"
>;

const equals = (a: Rect, b: Rect) =>
  (
    ["top", "right", "bottom", "left", "width", "height", "x", "y"] as const
  ).every((prop) => a[prop] === b[prop]);

// The DOMRect constructor doesn't have great browser support yet,
// so this function just takes all of the values from a DOMRect and
// places them in an object literal with like keys.
const objectifyDomRect = (rect: Rect) => ({
  top: rect.top,
  right: rect.right,
  bottom: rect.bottom,
  left: rect.left,
  width: rect.width,
  height: rect.height,
  x: rect.x,
  y: rect.y,
});

export default function useBoundingClientRect(
  ref: MutableRefObject<HTMLElement | null>
) {
  const [rect, setRect] = useState<Rect>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const measure = useDebounce(() => {
    if (ref.current) {
      const nextRect = ref.current.getBoundingClientRect();

      if (!equals(nextRect, rect)) {
        setRect(objectifyDomRect(nextRect));
      }
    }
  }, 100);

  useLayoutEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setRect(objectifyDomRect(rect));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("resize", measure);
    return () => {
      window.removeEventListener("resize", measure);
    };
  }, [measure]);

  return rect;
}
