import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  Device734CardOptions,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationSiteCodeLengthField_deviceInformation$key } from "./__generated__/DeviceInformationSiteCodeLengthField_deviceInformation.graphql";

export const deviceInformationSiteCodeLengthFieldId = (number: string) =>
  `device-information-site-code-length-${number}`;

function DeviceInformationSiteCodeLengthField() {
  const [{ id, number, device734, deviceType }] =
    useDeviceInformationFragment<DeviceInformationSiteCodeLengthField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationSiteCodeLengthField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            deviceType
            device734 {
              ... on Device734 {
                siteCodeBitLength
                cardFormatOptions
              }
            }
          }
          ... on Xt75DeviceInformation {
            deviceType
            device734 {
              ... on Device734 {
                siteCodeBitLength
                cardFormatOptions
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationSiteCodeLengthFieldId(String(number));
  const disabled =
    device734?.cardFormatOptions !== Device734CardOptions.CUSTOM ||
    deviceType !== DeviceInformationType.DOOR;
  const relayEnv = useRelayEnvironment();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Site Code Length"
      tooltip="Length of the Site Code within the card format."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        pattern="(0?[1-9]|1[0-9]|2[0-4])"
        validationMessage="Valid values are 1-24."
        value={device734?.siteCodeBitLength ?? 8}
        inlineHelp="1-24"
        min={1}
        max={24}
        required
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                Number(target.value),
                "siteCodeBitLength"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationSiteCodeLengthField;
