App.factory("OnDemandService", [
  "$q",
  "OnDemandTierAPI",
  function ($q, OnDemandTierAPI) {
    return {
      tierData: [],

      /*
       *Function that returns a promise for all of the OnDemandOptions for a dealer.
       * @param dealer_id
       */
      getOnDemandTierData: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;
        OnDemandTierAPI.showOnDemandPricing(
          { dealer_id: dealer_id },

          function (data) {
            tierData = angular.copy(data);
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /*
       *Function that returns a promise after the creation of an OnDemandOption.
       * @param tierObj - Tier Object to be created
       */
      createOnDemandTier: function (tierObj) {
        var deferred = $q.defer();
        var _this = this;
        OnDemandTierAPI.createOnDemandPricingTier(
          {},
          tierObj,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /*
       *Function that updates the OnDemand Price Options. Only updates if they're changed. Returns a promise.
       * @param newTierData - All the tier data whether changed or not.
       */
      updateOnDemandTiers: function (newTierData) {
        var deferred = $q.defer();
        var _this = this;
        var originalTierData = [];
        var tierDataPromises = [];

        originalTierData = tierData;
        for (var tier = 0; tier < newTierData.length; tier++) {
          if (!angular.equals(newTierData[tier], originalTierData[tier])) {
            tierDataPromises.push(
              OnDemandTierAPI.updateOnDemandPricingTier(
                { id: newTierData[tier].Id },
                newTierData[tier]
              )
            );
          }
        }
        $q.all(tierDataPromises)
          .then(
            function (dataArray) {
              deferred.resolve(dataArray);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
      /*
       * Function that returns a promise containing the CommunicationOptions for a dealer.
       * @param dealer_id - Dealer ID of the dealer you wish to retrieve Communication Options for.
       */
      getCommuncationOptions: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;

        OnDemandTierAPI.showCommunicationOptions(
          { dealer_id: dealer_id },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /*
       * Function to update Communication Options for a dealer.
       * @updateBody - CommunicationOption with update
       */
      updateCommunicationOptions: function (updateBody) {
        var deferred = $q.defer();
        var _this = this;

        OnDemandTierAPI.updateCommunicationOptions(
          { id: updateBody.Id },
          updateBody,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /*
       * Function to return a a new dealer charge created by the API
       * @param dealer_id
       */
      getNewDealerCharge: function (dealer_id) {
        var deferred = $q.defer();
        var _this = this;

        OnDemandTierAPI.newDealerCharge(
          { dealer_id: dealer_id },
          function (data) {
            tierData = angular.copy(data);
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
