import styled from "styled-components";
import { CONTENT_HEIGHT_DESKTOP_IN_PX } from "./constants";

const BasicArmingShieldWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${CONTENT_HEIGHT_DESKTOP_IN_PX * 0.85}px;
  line-height: 1;
`;

export default BasicArmingShieldWrap;
