import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
  useProgrammingConceptHasFieldChanges,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "components/FullProgramming/common/ControlSystemContext";
import { DEVICE_IDS } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNumberField";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  DeviceInformation,
  DeviceInformationCommType,
  DeviceInformationType,
  fromControlSystemId,
  fromDeviceInformationsId,
  idAsString,
  Panel,
  toDeviceInformationsId,
  toGlobalId,
  XfDeviceInformation,
  XfDeviceInformationsType,
} from "securecom-graphql/client";
import { formatToAxDeviceNumber, formatToLxDeviceNumber } from "./utils";
import { xfDeviceListItemTemplateId } from "./XFDeviceInformationNumberField";
import XFDeviceInformationsFields from "./XFDeviceInformationsFields";
import { XFDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation } from "./__generated__/XFDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XFDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation,
} from "./__generated__/XFDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation.graphql";
import {
  XFDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation,
  XFDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
} from "./__generated__/XFDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation.graphql";
import {
  XFDeviceSetupProgrammingConceptFormInline_controlSystem$data,
  XFDeviceSetupProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XFDeviceSetupProgrammingConceptFormInline_controlSystem.graphql";
import {
  XFDeviceSetupProgrammingConceptFormInline_deviceInformation$data,
  XFDeviceSetupProgrammingConceptFormInline_deviceInformation$key,
} from "./__generated__/XFDeviceSetupProgrammingConceptFormInline_deviceInformation.graphql";
import { XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import { XFDeviceSetupProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XFDeviceSetupProgrammingConceptFormNavButton_controlSystem.graphql";
import {
  XFDeviceSetupProgrammingConceptForm_controlSystem$data,
  XFDeviceSetupProgrammingConceptForm_controlSystem$key,
} from "./__generated__/XFDeviceSetupProgrammingConceptForm_controlSystem.graphql";

export const title = "Device Setup";
export const conceptId = "xf-device-setup";

export const getState = (
  controlSystem: XFDeviceSetupProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFDeviceSetupProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          totalDevicesMax
          keypadBusDevicesMax
          deviceNumberRange
          deviceInformations {
            __typename
            id
            number
            isNew
            ...XFDeviceSetupProgrammingConceptFormInline_deviceInformation
          }
        }
      }
    `,
    controlSystem
  );

export const getDeviceState = (
  device: XFDeviceSetupProgrammingConceptFormInline_deviceInformation$key
) =>
  readInlineData(
    graphql`
      fragment XFDeviceSetupProgrammingConceptFormInline_deviceInformation on DeviceInformation
      @inline {
        __typename
        id
        isNew
        ... on XfDeviceInformation {
          id
          number
          axNumber
          lxNumber
          name
          serialNumber
          supervisionTime
          deviceType
          deviceCommunicationMethod
        }
      }
    `,
    device
  );

const deleteMutation = graphql`
  mutation XFDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation(
    $deviceId: ID!
  ) {
    deleteDeviceInformation(deviceId: $deviceId) {
      ... on DeleteDeviceInformationSuccessPayload {
        __typename
        deletedDeviceId
      }
      ... on FailedToRemoveDeviceErrorPayload {
        error: type
      }
    }
  }
`;

const refreshMutation = graphql`
  mutation XFDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation(
    $id: ID!
  ) {
    refreshDeviceInformations(id: $id) {
      ... on RefreshDeviceInformationsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFDeviceSetupProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XFDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation(
    $systemId: ID!
    $deviceInformations: [XfDeviceInput!]!
  ) {
    sendXfDeviceProgramming(
      systemId: $systemId
      deviceInformations: $deviceInformations
    ) {
      ... on Error {
        type
      }
      ... on SendDeviceProgrammingSuccessPayload {
        results {
          __typename
          ... on SendDeviceProgrammingDeviceSuccessPayload {
            deviceInformation {
              __typename
              id
              number
              ...XFDeviceSetupProgrammingConceptFormInline_deviceInformation
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;

const readDeviceInformationsTemplateData = (
  programmingTemplateConcepts: XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        deviceInformations {
          included
          number
          axNumber {
            included
            data
          }
          lxNumber {
            included
            data
          }
          name {
            included
            data
          }
          deviceType {
            included
            data
          }
          deviceCommunicationMethod {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).deviceInformations;

export function applyTemplateData(
  programmingTemplateConcepts: XFDeviceSetupProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const hardwareModel = panelRecordProxy.getValue("hardwareModel");
  const deviceRecordProxies =
    panelRecordProxy.getLinkedRecords("deviceInformations") ?? [];
  const devicesByNumber = indexRecordProxiesByNumber(deviceRecordProxies);

  const devicesTemplateData =
    readDeviceInformationsTemplateData(programmingTemplateConcepts) ?? [];
  devicesTemplateData.forEach((deviceTemplateData) => {
    if (deviceTemplateData?.included) {
      let deviceRecordProxy = devicesByNumber.get(
        formatToLxDeviceNumber(deviceTemplateData.number) ?? 0
      );

      if (!deviceRecordProxy) {
        const newDeviceId = applyNewDeviceToDeviceInformationsList(
          {
            id: panelRecordProxy.getValue("id"),
            hardwareModel,
            deviceNumberRange: panelRecordProxy.getValue("deviceNumberRange"),
            newDevice: panelRecordProxy.getLinkedRecord("newDevice") && {
              id: panelRecordProxy.getLinkedRecord("newDevice").getValue("id"),
            },
            deviceInformations: deviceRecordProxies.map((recordProxy) => ({
              number:
                formatToLxDeviceNumber(recordProxy.getValue("number")) ?? 0,
            })),
          },
          store
        );
        if (newDeviceId) {
          deviceRecordProxy = store.get(
            newDeviceId
          ) as RecordProxy<DeviceInformation>;
          if (deviceRecordProxy) {
            deviceRecordProxy.setValue(
              formatToLxDeviceNumber(deviceTemplateData.number),
              "number"
            );
          }
        }
      }

      if (!deviceRecordProxy) {
        return;
      }

      //Need to add these to the deviceRecordProxy as they are calculated fields
      deviceRecordProxy.setValue(
        formatToLxDeviceNumber(deviceTemplateData.number),
        "number"
      );

      deviceRecordProxy.setValue(
        formatToLxDeviceNumber(deviceTemplateData.number)?.toString(),
        "lxNumber"
      );
      deviceRecordProxy.setValue(
        formatToAxDeviceNumber(deviceTemplateData.number)?.toString(),
        "axNumber"
      );

      applyTemplateScalarDataToRecordProxy(
        deviceRecordProxy,
        deviceTemplateData
      );

      if (
        !devicesByNumber.has(
          formatToLxDeviceNumber(deviceTemplateData.number) ?? 0
        )
      ) {
        devicesByNumber.set(
          formatToLxDeviceNumber(deviceTemplateData.number) ?? 0,
          deviceRecordProxy
        );
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(devicesByNumber),
    "deviceInformations"
  );
}

const mergeOldAndNewDevices = (
  response: XFDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
  originalControlSystemData: XFDeviceSetupProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendXfDeviceProgramming.results) {
    const successfulDevices = response.sendXfDeviceProgramming.results
      .map((device) => {
        if (device.__typename === "SendDeviceProgrammingDeviceSuccessPayload") {
          return device;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.deviceInformation)
      .map(getDeviceState);

    const mergedDevicesMap = new Map<
      string,
      XFDeviceSetupProgrammingConceptFormInline_deviceInformation$data
    >();

    originalControlSystemData.panel.deviceInformations
      .map(getDeviceState)
      .forEach((item) => mergedDevicesMap.set(item.id, item));

    successfulDevices.forEach((item) =>
      mergedDevicesMap.set(item.id, {
        ...mergedDevicesMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedDevicesMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XFDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
  originalControlSystemData: XFDeviceSetupProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedDevices = mergeOldAndNewDevices(
    response,
    originalControlSystemData
  );

  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshDeviceInformations: {
        __typename: "RefreshDeviceInformationsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            deviceInformations: mergedDevices,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFDeviceSetupProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendDeviceInformations, isSendingDeviceInformations] =
    useMutation<XFDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const hasFieldChanges = useProgrammingConceptHasFieldChanges(conceptId);
  const changedDevices = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { deviceInformations },
        } = getState(props.controlSystem);
        sendDeviceInformations({
          variables: {
            systemId: systemId,
            deviceInformations: deviceInformations
              .map(getDeviceState)
              .filter(
                (device) =>
                  device.isNew ||
                  hasFieldChanges ||
                  (!!changedDevices &&
                    listItemHasChanged(device.id, changedDevices)) ||
                  isSavingAllListItems
              )
              .map((deviceInformation) => ({
                id: deviceInformation.id,
                number: deviceInformation.number!,
                name:
                  deviceInformation.name ??
                  `Device ${deviceInformation.number}`,
                axNumber: deviceInformation.axNumber ?? "",
                lxNumber: deviceInformation.lxNumber ?? "",
                serialNumber: deviceInformation.serialNumber ?? "",
                supervisionTime: deviceInformation.supervisionTime,
                deviceType:
                  deviceInformation.deviceType ?? DeviceInformationType.KEYPAD,
                deviceCommunicationMethod:
                  deviceInformation.deviceCommunicationMethod,
                isNew: !!deviceInformation.isNew,
              })),
          },
          onCompleted: (response) => {
            const saveErrors: SaveErrors = [];
            if (response.sendXfDeviceProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendXfDeviceProgramming.results) {
              response.sendXfDeviceProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendDeviceProgrammingDeviceSuccessPayload"
                ) {
                  resetLastUpdated(response.deviceInformation.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  saveErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!saveErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
            }
            resolve(saveErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSendingDeviceInformations,
  ];
};

export const useRetrieveMutation = (props: {
  controlSystem: XFDeviceSetupProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshDeviceInformation, isRefreshing] =
    useMutation<XFDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshDeviceInformation({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshDeviceInformations;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Device Setup Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshDeviceInformations: {
                    __typename: response.refreshDeviceInformations.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Device Setup: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Device Setup",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XFDeviceSetupProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XFDeviceSetupProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            deviceInformations {
              ... on XfDeviceInformation {
                isNew
              }
            }
          }
        }
      `
    );
  const { deviceInformations } = controlSystem.panel;
  const itemsCount = deviceInformations.length;
  const hasNewItems =
    itemsCount > 0 && deviceInformations.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFDeviceSetupProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFDeviceSetupProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedDeviceInformation {
            id
          }
          panel {
            id
            totalDevicesMax
            keypadBusDevicesMax
            deviceNumberRange
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            hardwareModel
            ...XFDeviceInformationNumberField_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseRemoteZoneList_panel
            deviceInformations {
              id
              number
              name
              isNew
              ...XFDeviceInformationsFields_deviceInformation
              ... on XfDeviceInformation {
                lxNumber
                deviceType
              }
            }
            newDevice {
              id
              number
              name
              isNew
              ...XFDeviceInformationsFields_deviceInformation
              ... on XfDeviceInformation {
                lxNumber
                deviceType
              }
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseDeviceNumberRange_panel
            ...PanelContextUseSupports1100T_panel
            ...PanelContextUseSupportsXR550_panel
            ...PanelContextUseHas1100T_panel
            ...PanelContextUseSupportsNetworkExpander_panel
            ...PanelContextUseSupportsXVCamera_panel
            ...PanelContextGetAllDeviceIds_panel
            ...PanelContextUseSupportsVplex_panel
            ...PanelContextUseSupportsAxBus_panel
            ...PanelContextUseSupportsPrivateDoor_panel
            ...PanelContextUseSupportsCustomCardFormats_panel
            ...DeviceInformationSerialNumberField_deviceSerialNumberList_panel
          }
        }
      `
    );

  const {
    panel: {
      id: systemId,
      deviceInformations,
      newDevice,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    deviceInformations[0]?.id ?? null
  );

  const uncheckListItem = useUncheckListItem()(DEVICE_IDS);

  const [deleteDeviceInformation, isDeleting] =
    useMutation<XFDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const relayEnv = useRelayEnvironment();
  const { isEditing: templateIsEditing } = useTemplateContext();
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const remainingDevicesAvailable = availableNumbers.size;
  const canAdd = remainingDevicesAvailable > 0;

  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
    isValidatingProgramming,
  } = useProgrammingActionsContext();

  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;

  const templatesContext = useTemplateContext();

  const removeSelectedDevice = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = deviceInformations.findIndex(
          (deviceInformation) => deviceInformation.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === deviceInformations.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return deviceInformations[newSelectedIndex]?.id ?? null;
      });
      const device = deviceInformations.find(
        (device) => device.id === selectedListItemId
      );
      uncheckListItem(String(device?.number));
      if (device?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "deviceInformations",
            systemId,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            XfDeviceInformationsType,
            fromControlSystemId(asID(controlSystem.id)).systemId,
            device?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteDeviceInformation({
          variables: {
            deviceId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "deviceInformations",
              systemId,
              store
            );
          },
          updater: (store, response) => {
            const { deletedDeviceId } = response.deleteDeviceInformation;
            if (deletedDeviceId) {
              showAlert({
                type: "success",
                text: "Device Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "deviceInformations",
                systemId,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedDeviceId, error } = response.deleteDeviceInformation;
            if (deletedDeviceId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedDeviceId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "deviceInformations",
                      systemId,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Device: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Device",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Device",
            });
          },
        });
      }
    }
  };

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Device%20Setup`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(deviceInformations)}
        amountAvailable={remainingDevicesAvailable}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newDeviceId = applyNewDeviceToDeviceInformationsList(
                  controlSystem.panel,
                  store
                );
                if (newDeviceId) {
                  setSelectedListItemId(newDeviceId);
                }
              });
            }}
          >
            Add Device
          </ProgrammingConceptForm.AddButton>
        }
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          {(conceptId === activeConcept ||
            templatesContext.isApplying ||
            isSavingAll) && (
            <ProgrammingConceptForm.ListItemsContainer>
              <ProgrammingConceptForm.ListItemPicker
                selectedId={selectedListItemId}
                onChange={(id) => {
                  setSelectedListItemId(id);
                }}
                newItemId={newDevice?.id}
                items={deviceInformations.map((device) => ({
                  id: device.id,
                  templateListItemId: xfDeviceListItemTemplateId(
                    String(device.number)
                  ),
                  isnew: device.isNew,
                  label: `#${
                    device.lxNumber ? device.lxNumber : device.number
                  } ${device.name}`,
                }))}
              />
              <ProgrammingConceptForm.SelectedItemsContainer
                selectedListItemId={selectedListItemId}
                setSelectedListItemId={setSelectedListItemId}
              >
                {deviceInformations.map((deviceInformation) => {
                  return (
                    (deviceInformation.id === selectedListItemId ||
                      templatesContext.isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                      ((isSavingAll || // If we are saving all or the concept, we want to render all the devices so we can check for errors
                        programmingConcepts[conceptId].isSaving) &&
                        isValidatingProgramming)) && (
                      <RemountOnUpdateContainer nodeId={deviceInformation.id}>
                        <ProgrammingConceptForm.SelectedItem
                          conceptId={conceptId}
                          isnew={!!deviceInformation.isNew}
                          visible={deviceInformation.id === selectedListItemId}
                          key={deviceInformation.id}
                          listItemId={deviceInformation.id}
                          templateListItemId={xfDeviceListItemTemplateId(
                            String(deviceInformation.number)
                          )}
                          isCamera={deviceInformation.deviceType === "CAMERA"}
                          title={`# ${
                            deviceInformation.lxNumber
                              ? deviceInformation.lxNumber
                              : deviceInformation.number
                          } ${deviceInformation.name}`}
                          onDuplicate={
                            canAdd &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const duplicateId =
                                  applyDuplicatedDeviceInformationToDeviceInformationsList(
                                    selectedListItemId,
                                    controlSystem,
                                    store
                                  );
                                if (duplicateId) {
                                  setSelectedListItemId(duplicateId);
                                }
                              });
                            })
                          }
                          onCopy={() => {
                            relayEnv.commitUpdate((store) => {
                              const controlSystemRecord = store.get(
                                controlSystem.id
                              );
                              const deviceInformationRecord =
                                store.get<XfDeviceInformation>(
                                  selectedListItemId
                                );
                              if (
                                controlSystemRecord &&
                                deviceInformationRecord
                              ) {
                                const tempRecord =
                                  store.get("copiedDeviceInformation") ??
                                  store.create(
                                    "copiedDeviceInformation",
                                    "XfDeviceInformation"
                                  );
                                tempRecord.copyFieldsFrom(
                                  deviceInformationRecord
                                );
                                controlSystemRecord.setLinkedRecord(
                                  tempRecord,
                                  "copiedDeviceInformation"
                                );
                              }
                            });
                          }}
                          onPaste={
                            !!controlSystem.copiedDeviceInformation &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const deviceInformationRecord =
                                  store.get<XfDeviceInformation>(
                                    selectedListItemId
                                  );
                                const copiedDeviceInformationRecord =
                                  store.get<XfDeviceInformation>(
                                    "copiedDeviceInformation"
                                  );
                                if (
                                  deviceInformationRecord &&
                                  copiedDeviceInformationRecord
                                ) {
                                  applyDeviceInformationProgrammingToDeviceInformation(
                                    copiedDeviceInformationRecord,
                                    deviceInformationRecord,
                                    controlSystem
                                  );
                                }
                              });
                            })
                          }
                          onRemove={
                            deviceInformation.number > 1
                              ? removeSelectedDevice
                              : undefined
                          } //Dealer shouldn't be able to delete the built-in keypad (Device 1)
                        >
                          <XFDeviceInformationsFields
                            key={deviceInformation.id}
                            deviceInformation={deviceInformation}
                          />
                        </ProgrammingConceptForm.SelectedItem>
                      </RemountOnUpdateContainer>
                    )
                  );
                })}
              </ProgrammingConceptForm.SelectedItemsContainer>
            </ProgrammingConceptForm.ListItemsContainer>
          )}
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewDeviceToDeviceInformationsList = (
  panel: Parameters<typeof getAvailableNumbers>[0] & {
    readonly id: string;
    readonly hardwareModel: string;
    readonly newDevice: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newDevice } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newDeviceInformationTemplate =
      panelRecord.getLinkedRecord<DeviceInformation>("newDevice");

    if (newDevice) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromDeviceInformationsId(
          asID(newDeviceInformationTemplate.getDataID())
        );
        const nextNewDeviceId = idAsString(
          toDeviceInformationsId("Xf", systemId, nextNumber)
        );
        const nextNewDeviceInformation = store.create(
          nextNewDeviceId,
          "XfDeviceInformation"
        ) as RecordProxy<DeviceInformation>;

        nextNewDeviceInformation.copyFieldsFrom(newDeviceInformationTemplate!);
        nextNewDeviceInformation.setValue(nextNewDeviceId, "id");
        nextNewDeviceInformation.setValue(nextNumber, "number");
        nextNewDeviceInformation.setValue(true, "isNew");

        const deviceInformationRecords =
          panelRecord.getLinkedRecords("deviceInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...deviceInformationRecords, nextNewDeviceInformation],
          "deviceInformations"
        );
        return nextNewDeviceInformation.getValue("id");
      }
    }
  }
};

const applyDuplicatedDeviceInformationToDeviceInformationsList = (
  deviceInformationId: string,
  controlSystem: XFDeviceSetupProgrammingConceptForm_controlSystem$data,
  store: RecordSourceProxy
) => {
  const { id, newDevice } = controlSystem.panel;
  const panelRecord = store.get<Panel>(id);
  const deviceInformationRecord =
    store.get<DeviceInformation>(deviceInformationId);
  if (panelRecord && deviceInformationRecord) {
    const newDeviceRecord = panelRecord.getLinkedRecord("newDevice");
    if (newDevice) {
      const nextNumber = getNextAvailableNumber(controlSystem.panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromDeviceInformationsId(
          asID(newDeviceRecord.getDataID())
        );
        const nextNewDeviceId = idAsString(
          toDeviceInformationsId("Xf", systemId, nextNumber)
        );
        const nextNewDevice = store.create(
          nextNewDeviceId,
          "XfDeviceInformation"
        ) as RecordProxy<DeviceInformation>;
        nextNewDevice.copyFieldsFrom(newDeviceRecord);
        nextNewDevice.setValue(nextNewDeviceId, "id");
        nextNewDevice.setValue(nextNumber, "number");
        nextNewDevice.setValue(true, "isNew");

        const duplicatedDeviceInformationRecord =
          applyDeviceInformationProgrammingToDeviceInformation(
            deviceInformationRecord,
            nextNewDevice,
            controlSystem
          );

        const deviceInformationRecords =
          panelRecord.getLinkedRecords("deviceInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...deviceInformationRecords, duplicatedDeviceInformationRecord],
          "deviceInformations"
        );

        return duplicatedDeviceInformationRecord.getValue("id");
      }
    }
  }
};

const applyDeviceInformationProgrammingToDeviceInformation = (
  source: RecordProxy<DeviceInformation>,
  dest: RecordProxy<DeviceInformation>,
  controlSystem: XFDeviceSetupProgrammingConceptForm_controlSystem$data
) => {
  const destId = dest.getValue("id");
  const destNumber = dest.getValue("number");
  const destIsNew = dest.getValue("isNew");
  const destLxNumber = dest.getValue("lxNumber");
  const destAxNumber = dest.getValue("axNumber");

  dest.copyFieldsFrom(source);
  dest.setValue(destId, "id");
  dest.setValue(destIsNew, "isNew");
  dest.setValue(destNumber, "number");
  dest.setValue(destLxNumber, "lxNumber");
  dest.setValue(destAxNumber, "axNumber");

  switch (source.getValue("deviceType")) {
    case DeviceInformationType.FIRE: {
      dest.setValue(DeviceInformationType.FIRE, "deviceType");
      break;
    }
    case DeviceInformationType.ZONE_EXPANDER: {
      dest.setValue(
        DeviceInformationCommType.NETWORK,
        "deviceCommunicationMethod"
      );
      break;
    }
    default:
      break;
  }

  return dest;
};

const getAvailableNumbers = (panel: {
  readonly deviceNumberRange: ReadonlyArray<number>;
  readonly deviceInformations: ReadonlyArray<{ readonly number: number }>;
}) => {
  const allPossibleNumbers = new Set(panel.deviceNumberRange);

  const takenNumbers = new Set(
    panel.deviceInformations.map(({ number }) => formatToLxDeviceNumber(number))
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));
