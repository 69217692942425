/**=========================================================
 * Module: main.js
 * Main Application Controller
 =========================================================*/

App.controller("AppController", [
  "$rootScope",
  "$scope",
  "$state",
  "$translate",
  "$window",
  "$localStorage",
  "$timeout",
  "$anchorScroll",
  "toggleStateService",
  "colors",
  "cfpLoadingBar",
  "PROPS",
  "UserService",
  "$location",
  "NewsItemsService",
  "TEMP_DEALER_APP_USER",
  "ControlSystemsService",
  "OnlinePanelService",
  "PanelProgArchiveService",
  "HOST_NODE_ID",
  function (
    $rootScope,
    $scope,
    $state,
    $translate,
    $window,
    $localStorage,
    $timeout,
    $anchorScroll,
    toggle,
    colors,
    cfpLoadingBar,
    PROPS,
    UserService,
    $location,
    NewsItemsService,
    TEMP_DEALER_APP_USER,
    ControlSystemsService,
    OnlinePanelService,
    PanelProgArchiveService,
    HOST_NODE_ID
  ) {
    "use strict";
    $scope.HOST_NODE_ID = HOST_NODE_ID;
    $scope.TEMP_DEALER_APP_USER = TEMP_DEALER_APP_USER;
    $rootScope.headless = $location.search().headless;

    $scope.localStorage = $localStorage;

    $scope.uiRouterState = $state; //set to allow route checks globally
    $scope.PROPS = PROPS;
    $scope.UserService = UserService;
    $scope.ControlSystemsService = ControlSystemsService;
    $scope.OnlinePanelService = OnlinePanelService;
    $scope.PanelProgArchiveService = PanelProgArchiveService;
    // Loading bar transition
    // -----------------------------------
    var thBar;
    $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams) {
        if ($(".wrapper > section").length)
          // check if bar container exists
          thBar = $timeout(function () {
            cfpLoadingBar.start();
          }, 0); // sets a latency Threshold
      }
    );
    $rootScope.$on("cfpLoadingBar:completed", function () {
      // hide loading bar on completion
      $timeout.cancel(thBar);
    });

    $rootScope.$on(
      "$stateChangeSuccess",
      function (event, toState, toParams, fromState, fromParams) {
        event.targetScope.$watch("$viewContentLoaded", function () {
          $timeout.cancel(thBar);
          cfpLoadingBar.complete();
        });
      }
    );

    // Hook not found
    $rootScope.$on("$stateNotFound", function (event, unfoundState) {});

    // Hook success
    $rootScope.$on("$stateChangeSuccess", function () {
      // display new view from top
      $window.scrollTo(0, 0);
      // Save the route title
      $rootScope.currTitle = $state.current.title;
    });

    $rootScope.currTitle = $state.current.title;
    $rootScope.pageTitle = function () {
      return (
        $rootScope.app.name +
        " - " +
        ($rootScope.currTitle || $rootScope.app.description)
      );
    };

    // iPad may presents ghost click issues
    // if( ! browser.ipad )
    // FastClick.attach(document.body);

    // Close submenu when sidebar change from collapsed to normal
    $rootScope.$watch("app.layout.isCollapsed", function (newValue, oldValue) {
      if (newValue === false) {
        $rootScope.$broadcast("expandSidebarMenu");
      } else if (newValue === true) {
        $rootScope.$broadcast("collapseSidebarMenu");
      }
    });

    // Restore layout settings
    if (angular.isDefined($localStorage.layout)) {
      $scope.app.layout = $localStorage.layout;
    } else {
      $localStorage.layout = $scope.app.layout;
    }

    $rootScope.$watch(
      "app.layout",
      function () {
        $localStorage.layout = $scope.app.layout;
      },
      true
    );

    // Allows to use branding color with interpolation
    // {{ colorByName('primary') }}
    $scope.colorByName = colors.byName;

    // Hides/show user avatar on sidebar
    $scope.toggleUserBlock = function () {
      $scope.$broadcast("toggleUserBlock");
    };

    // Internationalization
    // ----------------------

    $scope.language = {
      // Handles language dropdown
      listIsOpen: false,
      // list of available languages
      available: {
        en: "English",
        es_AR: "Español",
      },
      // display always the current ui language
      init: function () {
        var proposedLanguage =
          $translate.proposedLanguage() || $translate.use();
        var preferredLanguage = $translate.preferredLanguage(); // we know we have set a preferred one in app.config
        $scope.language.selected =
          $scope.language.available[proposedLanguage || preferredLanguage];
      },
      set: function (localeId, ev) {
        // Set the new idiom
        $translate.use(localeId);
        // save a reference for the current language
        $scope.language.selected = $scope.language.available[localeId];
        // finally toggle dropdown
        $scope.language.listIsOpen = !$scope.language.listIsOpen;
      },
    };

    //Setup for menu collapse toggle
    $rootScope.mainMenu = { status: "closed" };
    $scope.language.init();

    // Restore application classes state
    toggle.restoreState($(document.body));

    // Applies animation to main view for the next pages to load
    $timeout(function () {
      $rootScope.mainViewAnimation = $rootScope.app.viewAnimation;
    });

    //

    $rootScope.uncollapsePanel = function (id) {
      $timeout(function () {
        angular.element("#" + id + " > a").trigger("click");
      }, 0);
    };

    $anchorScroll.yOffset = 160;

    $scope.convertToInt = function (aString) {
      return parseInt(aString, 10);
    };

    $scope.logoClick = function () {
      if (UserService.isParentDealerLogin() && UserService.onParentDealer()) {
        $state.go("app.dealer.dealers", { dealer_id: UserService.dealer_id });
      } else {
        if (
          UserService.isSupervisorAccessible() &&
          angular.isDefined(UserService.selectedDealer)
        ) {
          $state.go("app.dealer.dashboard", {
            dealer_id: UserService.dealer_id,
          });
        } else if (
          UserService.user &&
          UserService.user.accessible_type.toLowerCase() === "dealer" &&
          !UserService.isAccountant()
        ) {
          $state.go("app.dealer.dashboard", {
            dealer_id: UserService.user.accessible_id,
          });
        }
      }
    };

    $rootScope.unreadNews = null;
    NewsItemsService.getNewestUnseenActiveItem()
      .then(
        function (item) {
          if (item !== null) {
            $rootScope.unreadNews = item;
            NewsItemsService.addToShownItems(item);
          }
        },
        function (error) {
          console.error("Error getting news items: " + angular.toJson(error));
        }
      )
      .catch(function (error) {
        console.error(error);
      });
  },
]);
