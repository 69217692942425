/**
 * @generated SignedSource<<bcff9165fcbe2edc84f6df44e6ce9457>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type TMSentryCommunicationProgrammingConceptForm_controlSystem$data = {
  readonly isDualCom: boolean;
  readonly panel: {
    readonly communication: {
      readonly " $fragmentSpreads": FragmentRefs<"CommunicationAccountNumberField_communication" | "CommunicationBackupCellField_communication" | "CommunicationBackupCheckInMinutesField_communication" | "CommunicationBackupFailTimeMinutesField_communication" | "CommunicationCellTestDaysField_communication" | "CommunicationCheckInMinutesField_communication" | "CommunicationContext_communication" | "CommunicationEncryptionEnabledField_communication" | "CommunicationFailTimeField_communication" | "CommunicationFirstApnField_communication" | "CommunicationNetTestDaysField_communication" | "CommunicationReceiver1AlarmReportsField_communication" | "CommunicationReceiver1FirstIpAddressField_communication" | "CommunicationReceiver1FirstIpPortField_communication" | "CommunicationReceiver1OpenCloseUserReportsField_communication" | "CommunicationReceiver1SecondIpAddressField_communication" | "CommunicationReceiver1SecondIpPortField_communication" | "CommunicationReceiver1SupervisoryTroubleReportsField_communication" | "CommunicationReceiver1TestReportsField_communication" | "CommunicationReceiver2AlarmReportsField_communication" | "CommunicationReceiver2BackupField_communication" | "CommunicationReceiver2FirstIpAddressField_communication" | "CommunicationReceiver2FirstIpPortField_communication" | "CommunicationReceiver2OpenCloseUserReportsField_communication" | "CommunicationReceiver2SecondIpAddressField_communication" | "CommunicationReceiver2SecondIpPortField_communication" | "CommunicationReceiver2SupervisoryTroubleReportsField_communication" | "CommunicationReceiver2TestReportsField_communication" | "CommunicationSendCommunicationTroubleField_communication" | "CommunicationTestTimeField_communication" | "CommunicationTransmissionDelayField_communication" | "CommunicationTypeField_communication">;
    } | null;
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareDate_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "TMSentryCommunicationProgrammingConceptForm_controlSystem";
};
export type TMSentryCommunicationProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: TMSentryCommunicationProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryCommunicationProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryCommunicationProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDualCom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Communication",
          "kind": "LinkedField",
          "name": "communication",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationAccountNumberField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationBackupCellField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationBackupCheckInMinutesField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationBackupFailTimeMinutesField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationCellTestDaysField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationCheckInMinutesField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationContext_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationEncryptionEnabledField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationFailTimeField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationNetTestDaysField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1AlarmReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1FirstIpAddressField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1FirstIpPortField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1OpenCloseUserReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1SecondIpAddressField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1SecondIpPortField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1SupervisoryTroubleReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver1TestReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2AlarmReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2BackupField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2FirstIpAddressField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2FirstIpPortField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2OpenCloseUserReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2SecondIpAddressField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2SecondIpPortField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2SupervisoryTroubleReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationReceiver2TestReportsField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationSendCommunicationTroubleField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationTestTimeField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationTransmissionDelayField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationTypeField_communication"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CommunicationFirstApnField_communication"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareDate_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "0f82df4e3726e6534c9da91a958d562b";

export default node;
