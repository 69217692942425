App.factory("ProgrammingTemplateAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/dealers/:dealer_id/:resource/:id",
      {},
      {
        newProgTpl: {
          method: "GET",
          params: { resource: "@resource", dealer_id: "@dealer_id", id: "new" },
        },
        createProgTpl: {
          method: "POST",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
        },
        getProgTpls: {
          method: "GET",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
          isArray: true,
        },
        updateProgTpl: {
          method: "PUT",
          params: { resource: "@resource", dealer_id: "@dealer_id" },
          isArray: true,
        },
        destroyProgTpl: {
          method: "DELETE",
          params: { resource: "@resource", dealer_id: "@dealer_id", id: "@id" },
        },
      }
    );
  },
]);
