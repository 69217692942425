/**
 * @generated SignedSource<<2bd7633803d0ea635e8d3b18b060f137>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly alarmReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedAnswerRings: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly serviceReceiverAuthorization: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts";
};
export type TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts"
};

(node as any).hash = "2e2f45787dfe9f0c9fc388c262e258cc";

export default node;
