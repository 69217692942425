/**
 * @generated SignedSource<<9037cc92bf96f656f59409a500d544fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedJobGroupQuery$variables = {
  groupId: number;
  panelId: number;
};
export type SelectedJobGroupQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SelectedJobGroup_query">;
};
export type SelectedJobGroupQuery = {
  response: SelectedJobGroupQuery$data;
  variables: SelectedJobGroupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "panelId"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectedJobGroupQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SelectedJobGroup_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectedJobGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "groupId",
            "variableName": "groupId"
          },
          {
            "kind": "Variable",
            "name": "panelId",
            "variableName": "panelId"
          }
        ],
        "concreteType": "SystemDiagnosticsPanelJob",
        "kind": "LinkedField",
        "name": "systemDiagnosticsJobListFromGroup",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedulerJobType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12b3c4762277712628651d759c7a66c8",
    "id": null,
    "metadata": {},
    "name": "SelectedJobGroupQuery",
    "operationKind": "query",
    "text": "query SelectedJobGroupQuery(\n  $panelId: Int!\n  $groupId: Int!\n) {\n  ...SelectedJobGroup_query\n}\n\nfragment SelectedJobGroup_query on Query {\n  systemDiagnosticsJobListFromGroup(panelId: $panelId, groupId: $groupId) {\n    jobId\n    jobDesc\n    jobStatus\n    jobMessage\n    createdAt\n    updatedAt\n    completedAt\n    schedulerJobType\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9bf4994b316418aab0a4dd66153c05d";

export default node;
