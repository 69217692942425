/**
 * @generated SignedSource<<a368c2dd4318f04d86f8a7113d100057>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NetworkOptionsWifiSecurity = "NONE" | "UNSUPPORTED" | "WEP" | "WPA";
import { FragmentRefs } from "relay-runtime";
export type TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data = {
  readonly networkOptions: {
    readonly dhcpEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dnsServer: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly gatewayAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly ipv6Address: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Dns: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Enabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Gateway: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Prefix: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly listenPort734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly localIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly programmingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly subnetMask: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSecurity: {
      readonly data: NetworkOptionsWifiSecurity | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSsid: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts";
};
export type TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts"
};

(node as any).hash = "54cf7614793245ea48338ddb2af4cfb1";

export default node;
