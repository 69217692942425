import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationFastResponseField_zone$key } from "./__generated__/ZoneInformationFastResponseField_zone.graphql";

export const zoneInformationFastResponseFieldId = (number: string) =>
  `zone-information-fast-response-${number}`;

function ZoneInformationFastResponseField() {
  const [{ number, type, fastResponseEnabled }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationFastResponseField_zone$key>(
      graphql`
        fragment ZoneInformationFastResponseField_zone on Zone {
          number
          fastResponseEnabled
          type
        }
      `
    );

  const fieldId = zoneInformationFastResponseFieldId(String(number));
  const disabled = type === ZoneType.ARMING || Number(number) > 164;
  const label = "Fast Response";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Requires only 1 sample to be off-normal to report a change in state. Zones are sampled every 250 milliseconds."
      disabled={disabled}
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={fastResponseEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!fastResponseEnabled, "fastResponseEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationFastResponseField;
