/**
 * @generated SignedSource<<369d1d8e28f96d3435fc70344496da2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem$data = {
  readonly id: string;
  readonly templatePreProgrammingJobStatus: string | null;
  readonly " $fragmentType": "TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem";
};
export type TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem$key = {
  readonly " $data"?: TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TakeoverPanelFullProgrammingContainer_templateStatus_controlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatePreProgrammingJobStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "9a8cda80deba5ba046485eeee2ab4042";

export default node;
