import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathReceiverIpAddressField_communicationPath$key } from "./__generated__/CommPathReceiverIpAddressField_communicationPath.graphql";

export const commPathReceiverIpAddressFieldId = (number: string) =>
  `comm-path-receiver-ip-address-${number}`;

function CommPathReceiverIpAddressField() {
  const [{ number, commType, remoteIP, useIPv6 }, updateCommPath] =
    useCommPathFragment<CommPathReceiverIpAddressField_communicationPath$key>(
      graphql`
        fragment CommPathReceiverIpAddressField_communicationPath on CommunicationPath {
          number
          commType
          remoteIP
          useIPv6
        }
      `
    );

  const fieldId = commPathReceiverIpAddressFieldId(String(number));
  const disabled = useIPv6 || !["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Receiver IP Address"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={remoteIP.toString()}
        disabled={disabled}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        inlineHelp="Ex. 0.0.0.0"
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "remoteIP");
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "remoteIP"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathReceiverIpAddressField;
