import React, { useState } from "react";
import styled from "styled-components";
import Measure from "react-measure";
import { BORDER_RADIUS } from "common/components/web/constants/index";
import {
  themePrimary,
  themeSecondary,
  themeRgba,
} from "common/components/web/Theme";
import {
  AttentionIcon,
  RadialCheckIcon,
  RadialEmptyIcon,
  RadialWarningIcon,
} from "common/components/web/Icons";
import NakedList from "common/components/web/NakedList";
import NakedButton from "common/components/web/NakedButton";
import TransitionView from "common/components/web/TransitionView";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { BadZonesAlert_controlSystem$key } from "./__generated__/BadZonesAlert_controlSystem.graphql";
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";
import { BadZonesOption, BadZone } from "securecom-graphql/client";
import { hslToHsla } from "../../../utils/universal/color";
const LIST_ITEM_HEIGHT_IN_PX = 40;
const BORDER_BOTTOM_WIDTH_IN_PX = 1;

const BadZonesAlert = (props: {
  controlSystem: BadZonesAlert_controlSystem$key;
  respondToBadZones: Function;
  badZones: BadZone[];
  isDSC: boolean;
  isECP: boolean;
  isAreaSystem: boolean;
  setBadZonesMethod: Function;
  setBadZonesActions: Function;
  setStatusMessage: Function;
  setIsLoading: Function;
}) => {
  const system = useFragment(
    graphql`
      fragment BadZonesAlert_controlSystem on ControlSystem {
        id
        name
      }
    `,
    props.controlSystem
  );

  const [listAtBottom, setListAtBottom] = useState(false);
  const handleListScroll = ({ target }) => {
    const { scrollTop, offsetHeight } = target;
    const scrollBottom = scrollTop + offsetHeight + BORDER_BOTTOM_WIDTH_IN_PX;

    if (scrollBottom >= listItemsHeight && !listAtBottom) {
      setListAtBottom(true);
    } else if (listAtBottom) {
      setListAtBottom(false);
    }
  };
  const intl = useIntl();
  const forceBadZones = () => {
    props.setBadZonesMethod(null);
    props.respondToBadZones(BadZonesOption.FORCE);
  };
  const bypassBadZones = () => {
    props.setBadZonesMethod(null);
    props.respondToBadZones(BadZonesOption.BYPASS);
  };
  const cancel = () => {
    props.setBadZonesMethod(null);
    if (!props.isAreaSystem) {
      props.setStatusMessage({
        ...messages.statusMessage,
        values: {
          status: intl.formatMessage(messages.disarmed).toLowerCase(),
        },
      });
    }
    props.setIsLoading(false);
    props.setBadZonesActions({
      force: forceBadZones,
      bypass: bypassBadZones,
      cancel: cancel,
    });
  };

  const listItemsHeight = () => {
    return props.badZones.length * LIST_ITEM_HEIGHT_IN_PX;
  };

  return (
    <Root>
      <Header>
        <StyledAttentionIcon />
        <Headline>
          {system.name ? (
            <FormattedMessage
              {...messages.badZonesWithNameHeadline}
              values={{
                count: props.badZones.length,
                system: system.name,
              }}
            />
          ) : (
            <FormattedMessage
              {...messages.badZonesHeadline}
              values={{
                count: props.badZones.length,
              }}
            />
          )}
        </Headline>
      </Header>
      <Main>
        <Measure whitelist={["height"]}>
          {({ height }) => (
            <ListWrapper>
              <ScrollWrapper onScroll={handleListScroll}>
                <NakedList>
                  {props.badZones.map((zone) => (
                    <ListItem key={zone.number}>
                      {zone.name} ({zone.number})
                    </ListItem>
                  ))}
                </NakedList>
              </ScrollWrapper>
              <TransitionView
                type="fade"
                active={listItemsHeight > height && !listAtBottom}
                springConfig={{
                  stiffness: 250,
                }}
              >
                {(inlineStyle) => <ListScrollIndicator style={inlineStyle} />}
              </TransitionView>
            </ListWrapper>
          )}
        </Measure>
        <Actions>
          {!props.isECP && !props.isDSC && (
            <Button
              onClick={forceBadZones}
              title={intl.formatMessage(messages.okayFull)}
            >
              <ButtonWrapper>
                <ButtonIconWrapper>
                  <RadialCheckIcon />
                </ButtonIconWrapper>
                <FormattedMessage {...messages.okay} />
              </ButtonWrapper>
            </Button>
          )}
          <Button
            onClick={bypassBadZones}
            title={intl.formatMessage(messages.bypassFull)}
          >
            <ButtonWrapper>
              <ButtonIconWrapper>
                <RadialEmptyIcon />
              </ButtonIconWrapper>
              <FormattedMessage {...messages.bypass} />
            </ButtonWrapper>
          </Button>
          <Button onClick={cancel} title={intl.formatMessage(messages.cancel)}>
            <ButtonWrapper>
              <ButtonIconWrapper>
                <RadialWarningIcon />
              </ButtonIconWrapper>
              <FormattedMessage {...messages.cancel} />
            </ButtonWrapper>
          </Button>
        </Actions>
      </Main>
    </Root>
  );
};

export default BadZonesAlert;
const lightBorder = `${BORDER_BOTTOM_WIDTH_IN_PX}px solid rgba(255,255,255,0.2)`;
const Root = styled.aside`
  height: 100%;
  border-radius: ${BORDER_RADIUS};
  background: white;
  background-image: linear-gradient(
    to right top,
    ${themePrimary},
    ${({ theme }) => hslToHsla(0.2, themePrimary({ theme }))}
  );
  color: white;
  z-index: 10;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 18px 20px;
  border-bottom: ${lightBorder};
  background: rgba(255, 255, 255, 0.1);
  color: ${themeSecondary};
  height: 66px;
`;
const Headline = styled.h1`
  margin: 0;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
`;
const StyledAttentionIcon = styled(AttentionIcon)`
  display: block;
  margin-right: 0.3em;
  font-size: 1.8em;
  line-height: 1;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: calc(100% - 66px);
`;
const ListWrapper = styled.div`
  position: relative;
  flex: 3;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.08);
`;
const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;
const ListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${LIST_ITEM_HEIGHT_IN_PX}px;
  padding: 5px;
  border-bottom: ${lightBorder};
  font-weight: 400;
  text-align: center;
  &:first-child {
    padding-top: 10px;
  }
`;
const ListScrollIndicator = styled.i`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2em;
  background: ${themePrimary};
  font-size: 10px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg) translate(-50%, -50%);
    font-size: 10px;
  }
`;
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: ${lightBorder};
  background: rgba(255, 255, 255, 0.1);
`;
const Button = styled(NakedButton)`
  flex: 1;
  padding: 0.6em 10px 0.8em;
  border-right: ${lightBorder};
  color: white;
  font-weight: 300;
  background: rgba(255, 255, 255, 0);
  &:last-child {
    border-right: none;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
  &:active {
    background: rgba(255, 255, 255, 0.2);
  }
`;
const ButtonWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonIconWrapper = styled.span`
  display: block;
  margin-right: 0.35em;
  font-size: 1.25em;
  line-height: 1;
`;
