App.directive("daEquals", function () {
  return {
    require: "ngModel",
    link: function (scope, elem, attrs, ctrl) {
      var validate = function () {
        //get the value of the first field
        var val1 = scope.$eval(attrs.ngModel);

        //get the value of the second field
        var val2 = scope.$eval(attrs.daEquals);

        //compare the two fields for equality
        return val1 == val2;
      };

      scope.$watch(validate, function (isValid) {
        ctrl.$setValidity("equals", isValid);
      });
    },
  };
});
