App.factory("DmpTempUserAuthAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/dealers/DMPTempUserAuthorization/control_system/:id/:action",
      {},
      {
        get: { method: "GET", params: { id: "@id", action: "" } },
        create: { method: "POST", params: { id: "@id", action: "" } },
        extend: { method: "POST", params: { id: "@id", action: "extend" } },
        delete: { method: "DELETE", params: { id: "@id", action: "revoke" } },
      }
    );
  },
]);
// Path: projects/dealer-admin/src/app/common/api/dmp-temp-user-api.js
