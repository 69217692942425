/**
 * @ngdoc service
 * @name App.factory:PulseApi
 *
 * @description
 * API factory for getting information about SecureCom server statuses.
 *
 */
App.factory("PulseApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.pulseApiUrl + "/pulse/pulse.json",
      {},
      {
        getServerStatus: {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
          timeout: 250,
        },
      }
    );
  },
]);
