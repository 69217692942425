/**
 *
 * ChildIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ChildIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M58.202,43.612c-0.085-0.33-0.172-0.66-0.241-1.004c-2.168-10.844-6.83-14.635-11.105-15.636
        c0.832-1.663,1.521-4,1.475-7.145c0.604-0.191,1.124-0.425,1.532-0.623c3.901-1.884,3.667-6.544,3.58-8.296
        c-0.171-3.484-1.542-6.575-3.765-8.481c-1.784-1.529-3.924-2.258-6.244-2.063c-1.57,0.134-3.115,0.717-4.467,1.671
        c-4.406-2.252-9.878-2.228-14.257,0.056c-1.369-0.987-2.942-1.59-4.552-1.728c-2.28-0.185-4.456,0.536-6.238,2.065
        c-2.219,1.905-3.591,4.995-3.766,8.479c-0.086,1.753-0.316,6.411,3.581,8.297c0.416,0.201,0.937,0.434,1.539,0.624
        c-0.243,2.886,0.458,5.21,1.343,6.926c-4.054,0.865-8.758,4.561-11.558,15.761l-0.07,0.28c-0.43,1.702-1.019,4.033-0.038,6.192
        c0.301,0.663,0.743,1.193,1.281,1.56C5.98,51.49,5.76,52.541,5.76,53.325c0,5.711,5.284,10.358,11.78,10.358
        c5.73,0,10.061-2.619,11.367-6.684h5.253c1.307,4.064,5.636,6.684,11.366,6.684c5.285,0,9.769-3.077,11.256-7.3
        c0.123-0.202,0.212-0.428,0.258-0.673c0.043-0.235,0.074-0.473,0.1-0.711c0.102-0.546,0.168-1.103,0.168-1.674
        c0-0.807,0-1.673-0.154-2.554c0.59-0.458,1.075-1.151,1.305-2.187C58.944,46.471,58.532,44.885,58.202,43.612z M15.478,15.604
        c-0.749-0.362-1.492-1.18-1.328-4.498c0.12-2.392,0.985-4.448,2.376-5.642c0.976-0.838,2.136-1.21,3.297-1.115
        c1.168,0.1,2.33,0.668,3.272,1.6c0.669,0.662,1.708,0.769,2.499,0.253c1.874-1.221,4.051-1.868,6.297-1.868
        c2.205,0,4.352,0.625,6.207,1.806c0.781,0.497,1.798,0.394,2.462-0.247c0.933-0.899,2.074-1.447,3.21-1.544
        c1.206-0.101,2.328,0.277,3.305,1.115c1.391,1.193,2.256,3.248,2.373,5.642c0.166,3.318-0.576,4.135-1.328,4.498
        c-0.494,0.24-1.236,0.562-1.978,0.597c-0.547,0.026-1.061,0.275-1.419,0.689s-0.531,0.958-0.478,1.504
        c0.625,6.379-2.983,9.297-3.116,9.707c-8.414,3.791-15.447,1.6-18.593,0.163c-0.099-0.422-4.295-4.098-3.037-9.618
        c0.135-0.592-0.006-1.212-0.383-1.688s-0.949-0.753-1.556-0.757C16.793,16.197,16.004,15.858,15.478,15.604z M8.94,43.485
        c1.477-5.908,4.372-12.95,9.779-12.95c0.587,0,1.096,0.077,1.517,0.152c0.154,0.028,0.308,0.031,0.46,0.022
        c2.044,1.005,5.938,2.51,10.93,2.51c3.285,0,7.047-0.657,11.068-2.532c0.157,0.026,0.316,0.04,0.48,0.026
        c0.28-0.02,0.621-0.043,1.009-0.043c4.852,0,8.167,4.28,9.855,12.722c0.083,0.419,0.188,0.822,0.291,1.224
        c0.137,0.524,0.254,0.98,0.311,1.445c-1.461-1.559-4.104-3.426-8.441-3.479l-1.153-5.706c-0.218-1.082-1.271-1.782-2.356-1.563
        c-1.082,0.219-1.783,1.273-1.563,2.356l1.082,5.354c-6.275,1.414-8.232,6.245-8.473,9.977h-4.38
        c-0.199-3.562-1.955-8.156-7.626-9.788l0.92-5.615c0.179-1.09-0.561-2.118-1.65-2.297c-1.093-0.181-2.118,0.56-2.297,1.65
        l-0.928,5.662c-4.895-0.195-7.808,1.846-9.343,3.513c0.037-0.738,0.239-1.574,0.436-2.351L8.94,43.485z M17.54,59.684
        c-3.611,0-6.646-2.024-7.52-4.755c-0.308-1.769,0.316-4.208,0.632-5.152c0.001-0.003,0.002-0.005,0.003-0.009
        c0.296-0.54,2.526-4.11,8.943-2.953c6.384,1.149,5.829,7.041,5.702,7.961c-0.001,0.013-0.006,0.024-0.007,0.037
        C24.957,58.408,20.906,59.684,17.54,59.684z M45.526,59.684c-3.366,0-7.416-1.275-7.753-4.87
        c-0.002-0.017-0.008-0.032-0.01-0.049c-0.115-1.001-0.508-6.819,5.771-7.949c6.388-1.15,8.645,2.421,8.945,2.958
        c0.297,0.89,0.857,3.09,0.672,4.82C52.431,57.494,49.286,59.684,45.526,59.684z"
          />
          <path
            d="M28.204,23.105c-0.492-0.661-1.425-0.802-2.091-0.315c-0.668,0.49-0.812,1.428-0.323,2.096
        c0.748,1.021,2.176,2.089,3.803,2.089c0.728,0,1.495-0.214,2.26-0.741c0.052-0.036,0.103-0.073,0.152-0.11
        c0.021,0.016,0.04,0.033,0.061,0.049c2.196,1.75,4.813,0.112,6.015-1.132c0.573-0.594,0.556-1.538-0.036-2.114
        c-0.592-0.577-1.539-0.564-2.119,0.026c-0.347,0.354-1.512,1.253-1.99,0.873c-0.12-0.095-0.216-0.208-0.294-0.333
        c0.225-1.133,0.036-2.262-0.229-3c-0.221-0.617-0.812-1.006-1.471-0.993c-0.654,0.026-1.217,0.473-1.389,1.105
        c-0.19,0.697-0.305,1.763-0.089,2.845c-0.081,0.117-0.185,0.224-0.316,0.314C29.271,24.37,28.439,23.415,28.204,23.105z"
          />
          <path
            d="M18.998,14c0.734,0,1.44-0.405,1.791-1.106l1-2c0.494-0.988,0.094-2.189-0.895-2.684
        c-0.99-0.495-2.189-0.092-2.684,0.895l-1,2c-0.494,0.988-0.094,2.189,0.895,2.684C18.393,13.933,18.698,14,18.998,14z"
          />
          <path
            d="M43.211,12.895C43.562,13.595,44.268,14,45.002,14c0.3,0,0.605-0.068,0.893-0.211c0.988-0.494,1.389-1.695,0.895-2.684
        l-1-2c-0.494-0.987-1.693-1.39-2.684-0.895c-0.988,0.494-1.389,1.695-0.895,2.684L43.211,12.895z"
          />
          <path
            d="M23.306,18.307c0.219,0.117,0.456,0.172,0.69,0.172c0.532,0,1.052-0.285,1.329-0.777C25.395,17.579,26.061,16.5,28,16.5
        c0.828,0,1.5-0.671,1.5-1.5s-0.672-1.5-1.5-1.5c-3.1,0-4.816,1.778-5.342,2.829C22.295,17.057,22.587,17.924,23.306,18.307z"
          />
          <path
            d="M35,16.5c1.92,0,2.591,1.056,2.672,1.198c0.268,0.509,0.788,0.803,1.329,0.803c0.226,0,0.454-0.051,0.67-0.159
        c0.741-0.371,1.041-1.271,0.671-2.013C39.816,15.278,38.1,13.5,35,13.5c-0.828,0-1.5,0.671-1.5,1.5S34.172,16.5,35,16.5z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ChildIcon;
