import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationSendDoorForcedMessageField_deviceInformation$key } from "./__generated__/DeviceInformationSendDoorForcedMessageField_deviceInformation.graphql";

export const deviceInformationSendDoorForcedMessageFieldId = (number: string) =>
  `device-information-send-door-forced-message-${number}`;

function DeviceInformationSendDoorForcedMessageField() {
  const [{ number, doorForceMessages, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationSendDoorForcedMessageField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationSendDoorForcedMessageField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            doorForceMessages
            deviceType
          }
          ... on Xt75DeviceInformation {
            doorForceMessages
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationSendDoorForcedMessageFieldId(String(number));
  const label = "Send Door Forced Message";
  const disabled = deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables real-time 'Forced Open' messages for this door. Messages are sent to Entré and PC Log reporting."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        checked={doorForceMessages}
        disabled={disabled}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!doorForceMessages, "doorForceMessages");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationSendDoorForcedMessageField;
