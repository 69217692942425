import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { BadZonesOption } from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaBadZonesField_area$key } from "./__generated__/AreaBadZonesField_area.graphql";

export const areaBadZonesFieldId = (number: string) =>
  `area-bad-zones-${number}`;

function AreaBadZonesField() {
  const [{ number, badZonesOption, autoArmEnabled }, updateArea] =
    useAreaFragment<AreaBadZonesField_area$key>(
      graphql`
        fragment AreaBadZonesField_area on Area {
          id
          number
          badZonesOption
          autoArmEnabled
        }
      `
    );

  const fieldId = areaBadZonesFieldId(number);
  const disabled = !autoArmEnabled;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Bad Zones"
      disabled={disabled}
      tooltip="This option determines how the System handles off-normal Zones when automatically Arming the System with the Opening and Closing Schedule."
    >
      <Select
        id={fieldId}
        name="Bad Zones"
        value={badZonesOption}
        disabled={disabled}
        onChange={React.useCallback(
          ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
            updateArea((recordProxy) => {
              recordProxy.setValue(target.value, "badZonesOption");
            });
          },
          [updateArea]
        )}
      >
        <Select.Option value={BadZonesOption.BYPASS}>Bypass</Select.Option>
        <Select.Option value={BadZonesOption.FORCE}>Force Arm</Select.Option>
        <Select.Option value={BadZonesOption.REFUSE}>Refuse Arm</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default AreaBadZonesField;
