/**
 * @generated SignedSource<<8a3b987dfa0beae76e99c3e2c4141fa0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneSensorType = "GLASSBREAK" | "HOLD_UP" | "PIR_OR_RECESSED_DOOR" | "REPEATER" | "SMOKE" | "UNIVERSAL_TRANSMITTER" | "UNKNOWN_DEVICE" | "WIRELESS_FOUR_ZONE_EXPANDER" | "WIRELESS_PIR";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationLEDOperationField_zone$data = {
  readonly number: string;
  readonly sensorType: ZoneSensorType;
  readonly wireless: boolean;
  readonly wirelessLedEnabled: boolean;
  readonly " $fragmentType": "ZoneInformationLEDOperationField_zone";
};
export type ZoneInformationLEDOperationField_zone$key = {
  readonly " $data"?: ZoneInformationLEDOperationField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationLEDOperationField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationLEDOperationField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wirelessLedEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sensorType",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "f86a57439bd603f53c201d249202fdbd";

export default node;
