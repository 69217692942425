App.controller("SchedulesXTArmingCtrl", [
  "$scope",
  "$filter",
  "xtArmingScheduleModel",
  "scheduleUtilService",
  "PanelProgArchiveService",
  function (
    $scope,
    $filter,
    xtArmingScheduleModel,
    scheduleUtilService,
    PanelProgArchiveService
  ) {
    $scope.defaultDays = angular.copy(scheduleUtilService.days);
    $scope.PanelProgArchiveService = PanelProgArchiveService;

    $scope.multiPanelFilters.push({
      field: "ITEM.hardware_family",
      operator: "==",
      value: $scope.Panel.panel_model === "TMS6" ? "TMSentry" : "XT30",
      action: "hide",
    });

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:getAreaWithNumber
     *
     * @param {int} number The area number to search for
     *
     * @description
     * Finds the area with the given number
     *
     * @returns {area_information} The area with a matching number
     *
     */
    function getAreaWithNumber(number) {
      return $filter("filter")($scope.Panel.area_informations, {
        number: number,
      })[0];
    }

    /**
     * @ngdoc object
     * @name _setAutoDisarm
     * @methodOf EdisonControllers.controller:XTSchedulesController
     *
     * @description
     * Insures that the proper Auto Disarm checkboxes are selected when updating particular areas
     *
     * @param {object} schedule The area related to the checkbox that was modified on the view
     *
     */
    $scope.setAutoDisarm = function (area) {
      if (
        $scope.Panel.system_options.arm_mode == "H" ||
        $scope.Panel.system_options.arm_mode == "A"
      ) {
        switch (parseInt(area.number, 10)) {
          case 1: // PERIMETER
            if (getAreaWithNumber(1).auto_disrm === "Y") {
              getAreaWithNumber(2).auto_disrm = area.auto_disrm; // INTERIOR
              if (getAreaWithNumber(3)) {
                getAreaWithNumber(3).auto_disrm = area.auto_disrm;
              } // BEDROOMS
            }
            break;
          case 2: // INTERIOR
            if (getAreaWithNumber(2).auto_disrm === "Y") {
              if (getAreaWithNumber(3)) {
                getAreaWithNumber(3).auto_disrm = area.auto_disrm;
              } // BEDROOMS
            } else {
              getAreaWithNumber(1).auto_disrm = area.auto_disrm; // PERIMETER
            }
            break;
          case 3: // BEDROOMS
            if (getAreaWithNumber(3).auto_disrm === "N") {
              getAreaWithNumber(1).auto_disrm = area.auto_disrm; // PERIMETER
              getAreaWithNumber(2).auto_disrm = area.auto_disrm; // INTERIOR
            }
            break;
        }
      }
    };

    /**
     * @ngdoc object
     * @name _setAutoArm
     * @methodOf EdisonControllers.controller:XTSchedulesController
     *
     * @description
     * Insures that the proper Auto Arm checkboxes are selected when updating particular areas
     *
     * @param {object} schedule The area related to the checkbox that was modified on the view
     *
     */
    $scope.setAutoArm = function (area) {
      if (
        $scope.Panel.system_options.arm_mode == "H" ||
        $scope.Panel.system_options.arm_mode == "A"
      ) {
        switch (parseInt(area.number, 10)) {
          case 1: // PERIMETER
            if (getAreaWithNumber(1).auto_arm === "N") {
              getAreaWithNumber(2).auto_arm = area.auto_arm;
              if (getAreaWithNumber(3)) {
                getAreaWithNumber(3).auto_arm = area.auto_arm;
              }
            }
            break;
          case 2: // INTERIOR
            if (getAreaWithNumber(2).auto_arm === "N") {
              if (getAreaWithNumber(3)) {
                getAreaWithNumber(3).auto_arm = area.auto_arm;
              }
            } else {
              getAreaWithNumber(1).auto_arm = area.auto_arm; // PERIMETER
            }
            break;
          case 3: // BEDROOMS
            if (getAreaWithNumber(3).auto_arm === "Y") {
              getAreaWithNumber(1).auto_arm = area.auto_arm; // PERIMETER
              getAreaWithNumber(2).auto_arm = area.auto_arm; // INTERIOR
            }
            break;
        }
      }
    };

    /**
     * @ngdoc object
     * @name initPage
     * @methodOf EdisonControllers.controller:XTSchedulesController
     *
     * @description
     * Initialize the page by loading schedule information
     *
     */
    function initPage() {
      $scope.base.isBusy = true;

      // Only proceed if the schedules concept has been loaded. Otherwise, we're waiting for all_concepts_retrieved to fire
      if (angular.isDefined($scope.Panel["schedules"])) {
        // Get the current arming schedule, and set our parent's schedule property
        var scheduleData = $scope.Panel["schedules"][0]; // Get the existing schedule data (Only one arming schedule can exist so this will always be [0])
        $scope.base.currentSchedule = angular.extend(
          scheduleData,
          new xtArmingScheduleModel()
        );
        $scope.base.currentScheduleType = "schedule";
        $scope.base.currentSchedule.initSchedule();
        $scope.base.isBusy = false;
      }
    }

    initPage();

    // Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
    $scope.$on("all_concepts_retrieved", function () {
      initPage();
    });
  },
]);
