/**
 * @generated SignedSource<<13f4134ed5a63a7077af0a1081385063>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccessibleType = "CUSTOMER" | "DEALER" | "SUPERVISOR";
export type ClientApplication = "DEALER_ADMIN" | "VIRTUAL_KEYPAD";
export type DealerRole = "ACCOUNTANT" | "ADMIN" | "OPERATOR" | "SALES_MANAGER" | "SALES_PERSON" | "TECHNICIAN" | "VIDEO_VERIFIER";
export type ManagePasswordUpdatesChangePasswordMutation$variables = {
  clientApplication: ClientApplication;
  isV4Login?: boolean | null;
  newPassword: string;
  tempAuthToken?: string | null;
};
export type ManagePasswordUpdatesChangePasswordMutation$data = {
  readonly changePassword: {
    readonly __typename: "ChangePasswordErrorResponse";
    readonly __typename: "ChangePasswordErrorResponse";
    readonly error: {
      readonly __typename: string;
    } | null;
  } | {
    readonly __typename: "ChangePasswordSuccessResponse";
    readonly __typename: "ChangePasswordSuccessResponse";
    readonly users: ReadonlyArray<{
      readonly authToken: string;
      readonly user: {
        readonly accessibleType: AccessibleType;
        readonly email: string;
        readonly role?: DealerRole;
      };
    }>;
  } | {
    readonly __typename: "ChangePasswordSuccessResponseWithBearerToken";
    readonly __typename: "ChangePasswordSuccessResponseWithBearerToken";
    readonly users: ReadonlyArray<{
      readonly bearerToken: string;
      readonly refreshToken: string;
      readonly user: {
        readonly accessibleType: AccessibleType;
        readonly email: string;
        readonly role?: DealerRole;
      };
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type ManagePasswordUpdatesChangePasswordMutation = {
  response: ManagePasswordUpdatesChangePasswordMutation$data;
  variables: ManagePasswordUpdatesChangePasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientApplication"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isV4Login"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newPassword"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tempAuthToken"
},
v4 = [
  {
    "kind": "Variable",
    "name": "clientApplication",
    "variableName": "clientApplication"
  },
  {
    "kind": "Variable",
    "name": "isV4Login",
    "variableName": "isV4Login"
  },
  {
    "kind": "Variable",
    "name": "newPassword",
    "variableName": "newPassword"
  },
  {
    "kind": "Variable",
    "name": "tempAuthToken",
    "variableName": "tempAuthToken"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bearerToken",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refreshToken",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessibleType",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "DealerUser",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authToken",
  "storageKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ChangePasswordErrorResponse",
  "abstractKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagePasswordUpdatesChangePasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "changePassword",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthenticatedV4UserPayload",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ChangePasswordSuccessResponseWithBearerToken",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthenticateUserPayload",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ChangePasswordSuccessResponse",
            "abstractKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ManagePasswordUpdatesChangePasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "changePassword",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthenticatedV4UserPayload",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ChangePasswordSuccessResponseWithBearerToken",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthenticateUserPayload",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ChangePasswordSuccessResponse",
            "abstractKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94e967448cef97db873f54af48b64b39",
    "id": null,
    "metadata": {},
    "name": "ManagePasswordUpdatesChangePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ManagePasswordUpdatesChangePasswordMutation(\n  $newPassword: String!\n  $clientApplication: ClientApplication!\n  $tempAuthToken: String\n  $isV4Login: Boolean\n) {\n  changePassword(newPassword: $newPassword, clientApplication: $clientApplication, tempAuthToken: $tempAuthToken, isV4Login: $isV4Login) {\n    __typename\n    ... on ChangePasswordSuccessResponseWithBearerToken {\n      users {\n        bearerToken\n        refreshToken\n        user {\n          __typename\n          email\n          accessibleType\n          ... on DealerUser {\n            role\n          }\n          id\n        }\n      }\n      __typename\n    }\n    ... on ChangePasswordSuccessResponse {\n      users {\n        authToken\n        user {\n          __typename\n          email\n          accessibleType\n          ... on DealerUser {\n            role\n          }\n          id\n        }\n      }\n      __typename\n    }\n    ... on ChangePasswordErrorResponse {\n      error {\n        __typename\n      }\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "db155acf5a2f82561bc276332d90cb0d";

export default node;
