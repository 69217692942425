import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import {
  Action,
  ActionType,
  SortOrder,
  State,
} from "components/DaStyledElements/Paginator/usePaginationState";
import * as R from "ramda";
import * as React from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay/hooks";
import { PanelResetStatisticConnectionType } from "securecom-graphql/client";
import { useStateDotGo } from "./AngularStateProvider";
import { Pages, SortKey } from "./utils";
import { ResetTypeTable$key } from "./__generated__/ResetTypeTable.graphql";
import { ResetTypeTableQuery } from "./__generated__/ResetTypeTableQuery.graphql";

const fragment = graphql`
  fragment ResetTypeTable on PanelResetInformation
  @refetchable(queryName: "ResetTypeTablePaginationFragment") {
    amount
    panelStatisticsConnection(
      first: $first
      after: $after
      search: $search
      sort: $sort
    ) @connection(key: "PanelResetStatistics_panelStatisticsConnection") {
      edges {
        node {
          id
          controlSystem {
            databaseId
            site {
              databaseId
            }
            customer {
              id
              databaseId
              dealer {
                id
                databaseId
              }
            }
          }
          hardwareType
          version
          serial
          connectionType
          dealer {
            databaseId
          }
          dealerName
          amountOfResets
        }
      }
      totalCount
    }
  }
`;

function ResetTypeTableRoot({
  activePage,
  paginationState,
  dispatch,
  setSelectedDealer,
}: {
  activePage: Pages;
  paginationState: State<SortKey>;
  dispatch: (action: Action<SortKey>) => void;
  setSelectedDealer: (databaseDealerId: number) => void;
}) {
  const [searchToSearchWith, setSearchToSearchWith] = React.useState(
    paginationState.search
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchToSearchWith(paginationState.search);
      dispatch({ type: ActionType.SetSortKey, sortKey: null });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [dispatch, paginationState.search]);

  const data = useLazyLoadQuery<ResetTypeTableQuery>(
    graphql`
      query ResetTypeTableQuery(
        $first: Int!
        $after: ID
        $search: SearchInput
        $sort: SortInput
        $brownOutReset: Boolean!
        $j16Reset: Boolean!
        $lockupReset: Boolean!
        $powerReset: Boolean!
        $systemReset: Boolean!
        $watchdogReset: Boolean!
      ) {
        systemDiagnostic {
          brownOutReset {
            ...ResetTypeTable @include(if: $brownOutReset)
          }
          j16Reset {
            ...ResetTypeTable @include(if: $j16Reset)
          }
          lockupReset {
            ...ResetTypeTable @include(if: $lockupReset)
          }
          powerReset {
            ...ResetTypeTable @include(if: $powerReset)
          }
          systemReset {
            ...ResetTypeTable @include(if: $systemReset)
          }
          watchdogReset {
            ...ResetTypeTable @include(if: $watchdogReset)
          }
        }
      }
    `,
    {
      first: paginationState.pageSize,
      after: null,
      search: searchToSearchWith
        ? {
            term: searchToSearchWith,
            keys: [
              "hardwareType",
              "version",
              "serial",
              "connectionType",
              "dealerName",
            ],
          }
        : null,
      sort: isNotNullOrUndefined(paginationState.sortKey)
        ? {
            keys:
              paginationState.sortKey === SortKey.ConnectionType
                ? ["connectionType"]
                : paginationState.sortKey === SortKey.Dealer
                ? ["dealerName"]
                : paginationState.sortKey === SortKey.Resets
                ? ["amountOfResets"]
                : paginationState.sortKey === SortKey.Version
                ? ["version"]
                : paginationState.sortKey === SortKey.Serial
                ? ["serial"]
                : ["hardwareType"],
            order:
              paginationState.sortOrder === SortOrder.Ascending
                ? ("ASC" as const)
                : ("DESC" as const),
          }
        : null,
      brownOutReset: activePage === Pages.BrownOutReset,
      j16Reset: activePage === Pages.J16Reset,
      lockupReset: activePage === Pages.LockupReset,
      powerReset: activePage === Pages.PowerReset,
      systemReset: activePage === Pages.SystemReset,
      watchdogReset: activePage === Pages.WatchdogReset,
    }
  );

  const correctFragment = !R.isEmpty(data.systemDiagnostic.brownOutReset)
    ? data.systemDiagnostic.brownOutReset
    : !R.isEmpty(data.systemDiagnostic.j16Reset)
    ? data.systemDiagnostic.j16Reset
    : !R.isEmpty(data.systemDiagnostic.lockupReset)
    ? data.systemDiagnostic.lockupReset
    : !R.isEmpty(data.systemDiagnostic.powerReset)
    ? data.systemDiagnostic.powerReset
    : !R.isEmpty(data.systemDiagnostic.systemReset)
    ? data.systemDiagnostic.systemReset
    : !R.isEmpty(data.systemDiagnostic.watchdogReset)
    ? data.systemDiagnostic.watchdogReset
    : null;

  return correctFragment ? (
    <ResetTypeTable
      paginationState={paginationState}
      fragmentRef={
        !R.isEmpty(data.systemDiagnostic.brownOutReset)
          ? data.systemDiagnostic.brownOutReset
          : !R.isEmpty(data.systemDiagnostic.j16Reset)
          ? data.systemDiagnostic.j16Reset
          : !R.isEmpty(data.systemDiagnostic.lockupReset)
          ? data.systemDiagnostic.lockupReset
          : !R.isEmpty(data.systemDiagnostic.powerReset)
          ? data.systemDiagnostic.powerReset
          : !R.isEmpty(data.systemDiagnostic.systemReset)
          ? data.systemDiagnostic.systemReset
          : data.systemDiagnostic.watchdogReset
      }
      setSelectedDealer={setSelectedDealer}
    />
  ) : null;
}

function ResetTypeTable({
  fragmentRef,
  paginationState,
  setSelectedDealer,
}: {
  fragmentRef: ResetTypeTable$key;
  paginationState: State<SortKey>;
  setSelectedDealer: (databaseDealerId: number) => void;
}) {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment(
    fragment,
    fragmentRef
  );

  const results = React.useMemo(
    () => data?.panelStatisticsConnection?.edges?.map(R.prop("node")) ?? [],
    [data]
  );

  React.useEffect(() => {
    function handleScroll() {
      const distanceToBottom =
        document.body.scrollHeight - (window.innerHeight + window.scrollY);

      if (hasNext && !isLoadingNext && distanceToBottom < 400) {
        loadNext(paginationState.pageSize);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasNext, isLoadingNext, loadNext, paginationState.pageSize]);

  const nav = useStateDotGo();

  return (
    <tbody>
      {!R.isEmpty(results) ? (
        results.map((panel) => (
          <tr key={panel.id}>
            {panel.controlSystem?.databaseId ? (
              <td>
                <a
                  onClick={(e) => {
                    e.preventDefault();

                    if (!panel.controlSystem || !panel.dealer) {
                      return;
                    }

                    const dealerId = panel.dealer.databaseId;
                    const customerId = panel.controlSystem.customer.databaseId;
                    const panelId = panel.controlSystem.databaseId;
                    const siteId = panel.controlSystem.site?.databaseId;

                    setSelectedDealer(dealerId);

                    if (siteId) {
                      nav(
                        "app.sites.edit",
                        {
                          customer_id: customerId,
                          site_id: siteId,
                        },
                        { reload: false }
                      );
                    } else {
                      nav(
                        "app.control_system",
                        {
                          customer_id: customerId,
                          control_system_id: panelId,
                        },
                        { reload: false }
                      );
                    }
                  }}
                  href={
                    // this is not actually used. This is just for accessibility sake. These routes are not guaranteed to be accurate
                    panel.controlSystem.site?.databaseId
                      ? `/#/app/customers/${panel.controlSystem.customer.databaseId}/sites/${panel.controlSystem.site.databaseId}`
                      : `/#/app/customers/${panel.controlSystem.customer.databaseId}/control_systems/${panel.controlSystem.databaseId}`
                  }
                  className="link link-primary"
                >
                  <strong>{panel.hardwareType}</strong>
                </a>
              </td>
            ) : (
              <td>{panel.hardwareType}</td>
            )}
            <td>{panel.version}</td>
            <td>{panel.serial}</td>
            <td>
              {panel.connectionType === PanelResetStatisticConnectionType.CELL
                ? "Cell"
                : panel.connectionType ===
                  PanelResetStatisticConnectionType.NETWORK
                ? "Network"
                : panel.connectionType ===
                  PanelResetStatisticConnectionType.EASY_CONNECT_WITH_CELL_BACKUP
                ? "EASYconnect + Cell Backup"
                : panel.connectionType ===
                  PanelResetStatisticConnectionType.EASY_CONNECT
                ? "EASYconnect"
                : "Unknown"}
            </td>
            {panel.dealer?.databaseId && panel.controlSystem ? (
              <td>
                <a
                  onClick={() =>
                    panel.dealer?.databaseId &&
                    setSelectedDealer(panel.dealer.databaseId)
                  }
                  href={`/#/app/dealers/${panel.controlSystem.customer.dealer.databaseId}/settings`}
                  className="link link-primary"
                >
                  <strong>{panel.dealerName}</strong>
                </a>
              </td>
            ) : (
              <td>{panel.dealerName}</td>
            )}
            <td>{panel.amountOfResets}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6}>No Activity</td>
        </tr>
      )}
    </tbody>
  );
}

export default ResetTypeTableRoot;
