import React, { useState } from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import styled from "styled-components";
import CustomerListItem from "./CustomerListItem";

interface CustomerListProps {
  cache: React.MutableRefObject<CellMeasurerCache>;
  customersData: {
    customerId: number;
    name: string;
    sites: Map<
      number,
      {
        name: string;
      }
    >;
    systems: Map<
      number,
      {
        name: string;
      }
    >;
    isOpen: boolean;
  }[];
  tagId: string;
  tagRelationsMap: Map<string, Set<number>>;
}
function CustomerList(props: CustomerListProps) {
  const { cache, customersData, tagId, tagRelationsMap } = props;
  const [cacheReset, setCacheReset] = useState(false);
  const handleSystemExpand = (index: number) => {
    cache.current.clear(index, 0); // Clearing the height cached for the indexed row
    customersData[index].isOpen = !customersData[index].isOpen; // Toggling system expand
    setCacheReset(!cacheReset); // Toggling state to force re-render and calculated new height for customer systems
  };
  interface rowRendererProps {
    key: string;
    index: number;
    style: any;
    parent: any;
  }
  const rowRenderer = ({ key, index, style, parent }: rowRendererProps) => {
    return (
      <CellMeasurer
        key={key}
        cache={cache.current}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>
          <CustomerListItem
            customer={customersData[index]}
            index={index}
            onSystemExpand={handleSystemExpand}
            tagId={tagId}
            tagRelationsMap={tagRelationsMap}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <CustomersContainer>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            deferredMeasurementCache={cache.current}
            rowHeight={cache.current.rowHeight}
            rowCount={customersData.length}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </CustomersContainer>
  );
}

const CustomersContainer = styled.div`
  height: 70vh;
  overflow-y: auto;
`;

export default CustomerList;
