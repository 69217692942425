/**
 * @generated SignedSource<<5e9aad780b2bc802abde9f4b91161052>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelArmingSystem = "AP" | "AREA" | "HA" | "HSA" | "HSAWG";
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
import { FragmentRefs } from "relay-runtime";
export type XT75OutputOptionsProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly armingSystem: PanelArmingSystem;
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly outputOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsAmbushOutputField_outputOptions" | "OutputOptionsArmAlarmOutputField_outputOptions" | "OutputOptionsArmedAllOrAwayOutputField_outputOptions" | "OutputOptionsArmedHomeOrPerimeterOutputField_outputOptions" | "OutputOptionsArmedOutputField_outputOptions" | "OutputOptionsArmedSleepOutputField_outputOptions" | "OutputOptionsBeginExitOutputField_outputOptions" | "OutputOptionsBurglaryOutputField_outputOptions" | "OutputOptionsCarbonMonoxideOutputField_outputOptions" | "OutputOptionsClosingWaitOutputField_outputOptions" | "OutputOptionsCommunicationFailOutputField_outputOptions" | "OutputOptionsContext_outputOptions" | "OutputOptionsCoolSaverTemperatureField_outputOptions" | "OutputOptionsCutoffOutputsField_outputOptions" | "OutputOptionsDeviceFailOutputField_outputOptions" | "OutputOptionsDisarmedOutputField_outputOptions" | "OutputOptionsEndExitOutputField_outputOptions" | "OutputOptionsEntryDelayOutputField_outputOptions" | "OutputOptionsFireAlarmOutputField_outputOptions" | "OutputOptionsFireTroubleOutputField_outputOptions" | "OutputOptionsHeatSaverTemperatureField_outputOptions" | "OutputOptionsLateToCloseOutputField_outputOptions" | "OutputOptionsLockdownOutputField_outputOptions" | "OutputOptionsOutputCutoffTimeField_outputOptions" | "OutputOptionsPanicAlarmOutputField_outputOptions" | "OutputOptionsReadyOutputField_outputOptions" | "OutputOptionsSensorResetOutputField_outputOptions" | "OutputOptionsSupervisoryAlarmOutputField_outputOptions" | "OutputOptionsZoneMonitorOutputField_outputOptions">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextHouseCode_systemOptions" | "SystemOptionsContextSystemType_systemOptions" | "SystemOptionsContext_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XT75OutputOptionsProgrammingConceptForm_controlSystem";
};
export type XT75OutputOptionsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75OutputOptionsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75OutputOptionsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75OutputOptionsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "armingSystem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OutputOptions",
          "kind": "LinkedField",
          "name": "outputOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsContext_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsCutoffOutputsField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsOutputCutoffTimeField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsCommunicationFailOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsFireAlarmOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsFireTroubleOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsPanicAlarmOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsAmbushOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsEntryDelayOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsBeginExitOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsEndExitOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsReadyOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsArmedOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsDisarmedOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsBurglaryOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsLateToCloseOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsArmAlarmOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsHeatSaverTemperatureField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsCoolSaverTemperatureField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsArmedHomeOrPerimeterOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsArmedAllOrAwayOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsArmedSleepOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsCarbonMonoxideOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsZoneMonitorOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsDeviceFailOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsSensorResetOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsClosingWaitOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsSupervisoryAlarmOutputField_outputOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OutputOptionsLockdownOutputField_outputOptions"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContext_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextHouseCode_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "e7f3c67f73efc87743a1df9f2dada361";

export default node;
