/**
 *
 * The configurations for the `type` prop of TransitionView
 * @author Chad Watson
 *
 */

import { spring } from "react-motion";

const PUSH_SPRING_CONFIG = {
  stiffness: 400,
  damping: 25
};
export const push = (springConfig = PUSH_SPRING_CONFIG) => ({
  defaultStyle: {
    scale: 1,
    opacity: 1
  },
  style: {
    scale: spring(1, springConfig),
    opacity: spring(1)
  },
  willEnter: () => ({
    scale: 0.8,
    opacity: 0
  }),
  willLeave: () => ({
    scale: spring(0.8, springConfig),
    opacity: spring(0)
  }),
  getInlineStyle: ({ scale, opacity }) => ({
    transform: `scale(${scale})`,
    opacity
  })
});

export const fade = springConfig => ({
  defaultStyle: { opacity: 1 },
  style: { opacity: spring(1, springConfig) },
  willEnter: () => ({ opacity: 0 }),
  willLeave: () => ({ opacity: spring(0, springConfig) }),
  getInlineStyle: ({ opacity }) => ({ opacity })
});

export const slideUp = (springConfig = { stiffness: 195, damping: 22 }) => ({
  defaultStyle: { y: 0 },
  style: { y: spring(0, springConfig) },
  willEnter: () => ({ y: 100 }),
  willLeave: () => ({ y: spring(100, springConfig) }),
  getInlineStyle: ({ y }) => ({ transform: `translateY(${y}%)` })
});

export const slideDown = (springConfig = { stiffness: 195, damping: 22 }) => ({
  defaultStyle: { y: -100 },
  style: { y: spring(0, springConfig) },
  willEnter: () => ({ y: -100 }),
  willLeave: () => ({ y: spring(-100, springConfig) }),
  getInlineStyle: ({ y }) => ({ transform: `translateY(${y}%)` })
});

export const slideUpOut = (springConfig = { stiffness: 190, damping: 22 }) => ({
  defaultStyle: { y: 0 },
  style: { y: spring(0, springConfig) },
  willEnter: () => ({ y: -100 }),
  willLeave: () => ({ y: spring(-100, springConfig) }),
  getInlineStyle: ({ y }) => ({ transform: `translateY(${y}%)` })
});

export const slideX = (springConfig = { stiffness: 195, damping: 22 }) => ({
  defaultStyle: { x: 0 },
  style: { x: spring(0, springConfig) },
  willEnter: () => ({ x: -100 }),
  willLeave: () => ({ x: spring(-100, springConfig) }),
  getInlineStyle: ({ x }) => ({ transform: `translateX(${x}%)` })
});

export const slideFadeFromRight = (
  springConfig = { stiffness: 190, damping: 22 }
) => ({
  defaultStyle: {
    opacity: 1,
    x: 0
  },
  style: {
    opacity: spring(1, springConfig),
    x: spring(0, springConfig)
  },
  willEnter: () => ({
    opacity: 0,
    x: 100
  }),
  willLeave: () => ({
    opacity: spring(0, springConfig),
    x: spring(100, springConfig)
  }),
  getInlineStyle: ({ opacity, x }) => ({
    opacity,
    transform: `translateX(${x}%)`
  })
});

export const slideFadeFromLeft = (
  springConfig = { stiffness: 190, damping: 22 }
) => ({
  defaultStyle: {
    opacity: 1,
    x: 0
  },
  style: {
    opacity: spring(1, springConfig),
    x: spring(0, springConfig)
  },
  willEnter: () => ({
    opacity: 0,
    x: -100
  }),
  willLeave: () => ({
    opacity: spring(0, springConfig),
    x: spring(-100, springConfig)
  }),
  getInlineStyle: ({ opacity, x }) => ({
    opacity,
    transform: `translateX(${x}%)`
  })
});

export const parentPageSwipe = (
  springConfig = { stiffness: 206, damping: 21 }
) => ({
  defaultStyle: {
    opacity: 1,
    x: 0
  },
  style: {
    opacity: spring(1, springConfig),
    x: spring(0, springConfig)
  },
  willEnter: () => ({
    opacity: 0,
    x: -25
  }),
  willLeave: () => ({
    opacity: spring(0, springConfig),
    x: spring(-25, springConfig)
  }),
  getInlineStyle: ({ opacity, x }) => ({
    opacity,
    transform: `translateX(${x}%)`
  })
});

export const childPageSwipe = (
  springConfig = { stiffness: 206, damping: 21 }
) => ({
  defaultStyle: {
    opacity: 1,
    x: 0
  },
  style: {
    opacity: spring(1, springConfig),
    x: spring(0, springConfig)
  },
  willEnter: () => ({
    opacity: 0,
    x: 25
  }),
  willLeave: () => ({
    opacity: spring(0, springConfig),
    x: spring(25, springConfig)
  }),
  getInlineStyle: ({ opacity, x }) => ({
    opacity,
    transform: `translateX(${x}%)`
  })
});
