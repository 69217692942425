/**
 * Hikvision doorbell states used by Dealer Admin. Used to indicate if
 * a firmware update is available, if the device is updating, etc.
 */
export const HikDoorbellStates = Object.freeze({
  /** Dealer admin is checking the doorbell's firmware status. */
  CHECKING: 1,

  /** The device's firmware is being updated. */
  UPDATING: 2,

  /** The device is rebooting. */
  REBOOTING: 3,

  /** Firmware is up-to-date. */
  UP_TO_DATE: 4,

  /** A newer firmware version is available. */
  OUT_OF_DATE: 5,

  /** The firmware update failed. */
  ERROR_FAILED: 6,

  /** The device is offline. */
  ERROR_OFFLINE: 7,

  /** An unknown error occurred. */
  ERROR_UNKNOWN: 8,
});
