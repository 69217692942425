/**
 * @generated SignedSource<<3cc61fc14f80942df9372c9058d1c21f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRMenuDisplayProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly menuDisplay: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay" | "MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay" | "MenuDisplayContext_menuDisplay" | "MenuDisplayTimeDisplayKeypadsField_menuDisplay">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XRMenuDisplayProgrammingConceptForm_controlSystem";
};
export type XRMenuDisplayProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XRMenuDisplayProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRMenuDisplayProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRMenuDisplayProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MenuDisplay",
          "kind": "LinkedField",
          "name": "menuDisplay",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MenuDisplayContext_menuDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MenuDisplayTimeDisplayKeypadsField_menuDisplay"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "60b75af01160b1b873945b6fd509de65";

export default node;
