import * as React from "react";
import { DoorStatus } from "securecom-graphql/client";
import styled, { DefaultTheme } from "styled-components";
import rgba from "../../../utils/universal/rgba";
import { LockLockedIcon, LockUnlockedIcon } from "../Icons";
import LargePillSwitch from "../LargePillSwitch";
import { COLOR_THEME_OPTIONS } from "../PillSwitch";
import VisuallyHidden from "../VisuallyHidden";

const Switch = styled(LargePillSwitch)`
  width: 100%;
`;

const OptionLabel = styled.span<{
  status: string;
  currentStatus: DoorStatus | null;
  isHovered: boolean;
  theme: DefaultTheme;
}>`
  display: inline-block;
  color: ${({ status, currentStatus, isHovered, theme }) => {
    let color;

    if (!currentStatus || currentStatus === DoorStatus.UNKNOWN) {
      color = theme.grayDark;
    } else if (isHovered && status !== currentStatus) {
      color = theme.gray;
    } else if (status === currentStatus) {
      color = theme.grayDark;
    } else if (!isHovered) {
      color = rgba(0.5, theme.grayAccent);
    } else {
      color = theme.gray;
    }

    return color;
  }};
  font-size: 1.8em;
  line-height: 0;
  transform: ${({ status, currentStatus }) =>
    status === currentStatus ? "scale(1.25)" : "scale(1)"};
`;
export const STATUS_OPTIONS = [DoorStatus.UNLOCKED, DoorStatus.LOCKED] as const;

function LockSwitch(
  props: {
    currentStatus: DoorStatus | null;
    onChange: (status: DoorStatus.LOCKED | DoorStatus.UNLOCKED) => void;
    onMouseEnter?: (status: DoorStatus.LOCKED | DoorStatus.UNLOCKED) => void;
    onMouseLeave?: (status: DoorStatus.LOCKED | DoorStatus.UNLOCKED) => void;
  } & Omit<
    Parameters<typeof LargePillSwitch>[0],
    "onMouseEnter" | "onMouseLeave" | "onChange"
  >
) {
  const [hoveredOption, setHoveredOption] = React.useState<number | null>(null);

  const { currentStatus } = props;
  const selectedIndex = (STATUS_OPTIONS as readonly DoorStatus[]).indexOf(
    currentStatus!
  );

  return (
    <Switch
      id={props.id}
      className={props.className}
      disabled={props.disabled}
      vertical={props.vertical}
      options={STATUS_OPTIONS.map((status, index) => ({
        label: (
          <OptionLabel
            status={status}
            currentStatus={currentStatus}
            isHovered={hoveredOption === index}
          >
            {status === DoorStatus.UNLOCKED ? (
              <LockUnlockedIcon />
            ) : (
              <LockLockedIcon />
            )}
            <VisuallyHidden>
              <span>
                {status === DoorStatus.UNLOCKED ? "UNLOCKED" : "LOCKED"}
              </span>
            </VisuallyHidden>
          </OptionLabel>
        ),
        value: status,
      }))}
      selectedIndex={selectedIndex >= 0 ? selectedIndex : undefined}
      onMouseEnter={(optionIndex) => {
        if (props.onMouseEnter && STATUS_OPTIONS[optionIndex]) {
          props.onMouseEnter(STATUS_OPTIONS[optionIndex]);
        }
        setHoveredOption(optionIndex);
      }}
      onMouseLeave={() => {
        if (props.onMouseLeave) {
          props.onMouseLeave(null);
        }
        setHoveredOption(null);
      }}
      onChange={(optionIndex) => {
        if (props.onChange && STATUS_OPTIONS[optionIndex]) {
          props.onChange(STATUS_OPTIONS[optionIndex]);
        }
      }}
      colorTheme={COLOR_THEME_OPTIONS.SIMPLE}
    />
  );
}

export default LockSwitch;
