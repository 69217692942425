import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useMessagingSetupFragment } from "./MessagingSetupContext";
import { MessagingSetupDestination1Field_messagingSetup$key } from "./__generated__/MessagingSetupDestination1Field_messagingSetup.graphql";

export const messagingSetupDestination1FieldId = () =>
  "messaging-setup-destination-1";

function MessagingSetupDestination1Field() {
  const [{ firstEmailOrPhoneNumber, enableMessaging }, updateMessagingSetup] =
    useMessagingSetupFragment<MessagingSetupDestination1Field_messagingSetup$key>(
      graphql`
        fragment MessagingSetupDestination1Field_messagingSetup on MessagingSetup {
          firstEmailOrPhoneNumber
          enableMessaging
        }
      `
    );

  const fieldId = messagingSetupDestination1FieldId();
  const disabled = !enableMessaging;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Destination 1"
      disabled={disabled}
      tooltip="Cellular phone number that will receive SMS messages from this System."
    >
      <TextInput
        id={fieldId}
        maxLength={48}
        disabled={disabled}
        value={firstEmailOrPhoneNumber}
        inlineHelp="Email or Phone Number"
        pattern="^.*$"
        onChange={({ target }) => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(target.value, "firstEmailOrPhoneNumber");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MessagingSetupDestination1Field;
