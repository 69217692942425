import { curry, range, sortBy, prop } from "ramda";
import { filter } from "fp-ts/Array";
import { isNotNullOrUndefined } from "./function";

export function extractIds<A>(items: readonly { id: A }[]): A[] {
  return items.map(({ id }) => id);
}

export function isInArray<A>(list: A[]) {
  return (item: A) => list.includes(item);
}

export function findLast<A>(fn: (item: A) => boolean) {
  return (list: A[]) => {
    for (let i = list.length - 1; i > 0; i--) {
      if (fn(list[i])) {
        return list[i];
      }
    }
  };
}

export function hasDuplicates<A>(array: A[]) {
  return array.length > new Set(array).size;
}

export function hasDuplicate<A>(array: A[]) {
  return (item: A) => {
    let amount = 0;
    for (let current of array) {
      if (current === item) {
        amount = amount + 1;
      }

      if (amount > 1) {
        return true;
      }
    }

    return false;
  };
}

export const join =
  (separator = ",") =>
  (...items: string[] | string[][]) => {
    return items.flat(Infinity).join(separator);
  };

export const rangeInclusive = curry((start: number, end: number) =>
  range(start, end + 1)
);

export const sortByName = sortBy(prop("name"));

export const keepNonNullable = filter(isNotNullOrUndefined);

export const headAndLast = <A>(xs: A[]): [] | [A] | [A, A] => {
  if (xs.length > 1) {
    return [xs[0], xs[xs.length - 1]];
  }

  if (xs.length === 1) {
    return [xs[0]];
  }

  return [];
};
export const sort = <A>(items: A[]) => items.sort();

export function readonlyArraySplice(
  array: readonly any[] | any[],
  start: number,
  deleteCount: number,
  item?: any
) {
  const newArray = [...array];
  newArray.splice(start, deleteCount, item);
  return newArray;
}
