/**
 * @generated SignedSource<<dda782ce3586751069e4d4904f53923c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE";
import { FragmentRefs } from "relay-runtime";
export type SiteDiagnosticsCommunicationStatusFragment_controlSystem$data = {
  readonly cellStatus: {
    readonly status: ConnectionStatus;
  } | null;
  readonly easyConnectStatus: {
    readonly status: ConnectionStatus;
  } | null;
  readonly hasCell: boolean;
  readonly hasEasyConnect: boolean;
  readonly id: string;
  readonly online: boolean;
  readonly " $fragmentType": "SiteDiagnosticsCommunicationStatusFragment_controlSystem";
};
export type SiteDiagnosticsCommunicationStatusFragment_controlSystem$key = {
  readonly " $data"?: SiteDiagnosticsCommunicationStatusFragment_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteDiagnosticsCommunicationStatusFragment_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteDiagnosticsCommunicationStatusFragment_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEasyConnect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemEasyConnectStatus",
      "kind": "LinkedField",
      "name": "easyConnectStatus",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemCellStatus",
      "kind": "LinkedField",
      "name": "cellStatus",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "0dbc7c39def35b94e3d284f041be33f3";

export default node;
