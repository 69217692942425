App.controller("DefaultProgrammingListCtrl", [
  "$q",
  "$scope",
  "$state",
  "$rootScope",
  "$stateParams",
  "$filter",
  "DealerDefaultsService",
  "UserService",
  "DEALER_API_DEFAULTS",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "DTColumnBuilder",
  "$compile",
  "$sanitize",
  "DataTablesUtilsService",
  "ProgrammingTemplateService",
  function (
    $q,
    $scope,
    $state,
    $rootScope,
    $stateParams,
    $filter,
    DealerDefaultsService,
    UserService,
    DEALER_API_DEFAULTS,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    DTColumnBuilder,
    $compile,
    $sanitize,
    DataTablesUtilsService,
    ProgrammingTemplateService
  ) {
    $scope.UserService = UserService;
    $scope.thisDealer = UserService.dealerInfo;
    $scope.canEditDealerSettings = UserService.canEditDealerSettings();
    $scope.canDeleteDealerSettings = UserService.canDeleteDealerSettings();
    $scope.services_defaults = undefined;
    $scope.app_user_defaults = undefined;
    $scope.xrDefaults = {};

    $scope.panel = $stateParams.panel;
    $scope.comm = $stateParams.comm;
    init();

    /*DTInstances.getLast().then(function (dtInstance) {
      $scope.dtInstance = dtInstance;
    });*/

    /**
     * Initialize the controller
     */
    function init() {
      getVkDefaults(DEALER_API_DEFAULTS.SERVICES_DEFAULTS)
        .then(
          function (data) {
            $scope.services_defaults = data;
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      getVkDefaults(DEALER_API_DEFAULTS.APP_USER_DEFAULTS)
        .then(
          function (data) {
            $scope.app_user_defaults = data;
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.showHardwareName = function (hardwareName) {
      if (hardwareName === "XR550") return "XR150/XR550 Series";
      if (hardwareName === "CELLCOM")
        return "DualCom/CellComSL/CellComEX/iComSL";
      if (hardwareName === "XTLP") return "XTLplus/XTLtouch";
      else return (hardwareName = hardwareName);
    };

    /**
     * Gets existing defaults to populate the page
     */
    function pullDefaults(resource) {
      var _this = this;
      var deferred = $q.defer();

      //$scope.isBusy = true;
      DealerDefaultsService.getAllDefaults(UserService.dealer_id, resource)
        .then(
          function (data) {
            // $scope.saved_defaults = data;
            ProgrammingTemplateService.getProgTpls(
              UserService.dealer_id,
              DEALER_API_DEFAULTS.XR_DEFAULTS
            ).then(
              function (xrDefaults) {
                $scope.saved_defaults = [...data, ...xrDefaults];
                deferred.resolve($scope.saved_defaults);
              },
              function (error) {
                deferred.reject(error);
              }
            );
            // deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    /**
     * Load the specified resource from the Dealer API
     */
    function getVkDefaults(resource) {
      var deferred = $q.defer();

      DealerDefaultsService.getDealerDefaults(
        UserService.dealer_id,
        resource,
        null
      )
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            // If the error is 404, try getting a default template for the requested default
            if (error.status == "404") {
              createVkDefault("services_defaults")
                .then(
                  function (data) {
                    deferred.resolve(data);
                  },
                  function (error) {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Error loading defaults",
                      json: error,
                    });
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              deferred.reject(error);
            }
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    }

    /**
     * Deletes a specified default
     * @param default_id Number Id of the default to be deleted
     */
    $scope.deleteThisProgDefault = function (defaultObject) {
      if (
        defaultObject.hardware_model !== "XR550" &&
        defaultObject.hardware_model !== "CELLCOM"
      ) {
        DealerDefaultsService.deleteDealerDefaults(
          UserService.dealer_id,
          DEALER_API_DEFAULTS.XT_DEFAULTS,
          defaultObject.Id
        )
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Default Deleted!",
              });
              pullDefaults(DEALER_API_DEFAULTS.XT_DEFAULTS);
              return true;
            },
            function (error) {
              $rootScope.alerts.push({ type: "error", json: error });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        ProgrammingTemplateService.destroyProgTpl(
          UserService.dealer_id,
          defaultObject.Id,
          DEALER_API_DEFAULTS.XR_DEFAULTS
        ).then(
          function (deleted) {
            pullDefaults(DEALER_API_DEFAULTS.XT_DEFAULTS);
          },
          function (nope) {}
        );
      }
    };

    /**
     * Creates a new VK default for the specified resource
     * @param resource String The name of the resource to create default for (ex. services_defaults, app_user_defaults)
     */
    function createVkDefault(resource) {
      var deferred = $q.defer();
      $scope.isBusy = true;
      DealerDefaultsService.newDealerDefaults(UserService.dealer_id, resource)
        .then(
          function (data) {
            deferred.resolve(data);
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error getting default template",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      return deferred.promise;
    }

    /**
     * Save VK Defaults and App User Defaults
     */
    $scope.saveVkDefaults = function () {
      $scope.isBusy = true;
      var saveVkDefaults = $scope.services_defaults.isNew
        ? DealerDefaultsService.createDealerDefaults(
            UserService.dealer_id,
            "services_defaults",
            $scope.services_defaults
          )
        : DealerDefaultsService.updateDealerDefaults(
            UserService.dealer_id,
            "services_defaults",
            $scope.services_defaults
          );

      var saveAppUserDefaults = $scope.app_user_defaults.isNew
        ? DealerDefaultsService.createDealerDefaults(
            UserService.dealer_id,
            "app_user_defaults",
            $scope.app_user_defaults
          )
        : DealerDefaultsService.updateDealerDefaults(
            UserService.dealer_id,
            "app_user_defaults",
            $scope.app_user_defaults
          );

      $q.all([saveVkDefaults, saveAppUserDefaults])
        .then(
          function (data) {
            $rootScope.alerts.push({
              type: "success",
              text: "Virtual Keypad Defaults Saved",
            });
            $scope.services_defaults = data[0];
            $scope.app_user_defaults = data[1];
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error saving Virtual Keypad defaults",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Removes the VK Defaults and App User Defaults for the current dealer
     */
    $scope.deleteVkDefaults = function () {
      $scope.isBusy = true;
      var defaultPromises = [];

      if (!$scope.services_defaults || $scope.services_defaults.isNew) {
        $scope.services_defaults = undefined;
      } else {
        defaultPromises.push(
          DealerDefaultsService.deleteDealerDefaults(
            UserService.dealer_id,
            "services_defaults"
          )
        );
      }

      if (!$scope.app_user_defaults || $scope.app_user_defaults.isNew) {
        $scope.app_user_defaults = undefined;
      } else {
        defaultPromises.push(
          DealerDefaultsService.deleteDealerDefaults(
            UserService.dealer_id,
            "app_user_defaults"
          )
        );
      }

      if (defaultPromises.length < 1) {
        $rootScope.alerts.push({
          type: "success",
          text: "Virtual Keypad Defaults Cleared",
        });
        $state.go(
          "app.dealer.default_programming",
          { dealer_id: UserService.dealer_id },
          { reload: true }
        );
      } else {
        $q.all(defaultPromises)
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Virtual Keypad Defaults Cleared",
              });
              $state.go(
                "app.dealer.default_programming",
                { dealer_id: UserService.dealer_id },
                { reload: true }
              );
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Error clearing Virtual Keypad defaults",
                json: error,
              });
              $scope.isBusy = false;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    // Calling service to build Table
    $scope.defaultsListdtOptions = DataTablesUtilsService.getDTOptions(
      pullDefaults(DEALER_API_DEFAULTS.XT_DEFAULTS),
      "Templates"
    );
    //TODO: Find out why error scope.$watch is not a function is displaying
    $scope.dtColumnDefs = [
      DTColumnDefBuilder.newColumnDef(0),
      DTColumnDefBuilder.newColumnDef(1),
      DTColumnDefBuilder.newColumnDef(2),
      DTColumnDefBuilder.newColumnDef(3).notSortable(),
    ];
    $scope.defaultsListdtOptions.withButtons([]);
  },
]);
