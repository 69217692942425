/**
 *
 * AnimatedCycleIcon
 * @author Chad Watson
 *
 */

import styled, { keyframes, css } from "styled-components";
import CycleIcon from "./CycleIcon";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const AnimatedCycleIcon = styled(CycleIcon)`
  ${({ animated }) =>
    animated &&
    css`
      animation: ${rotate} 1.5s linear infinite;
    `};
`;

export default AnimatedCycleIcon;
