/**
 * @generated SignedSource<<643b25a1bbc5c38772db6826fd36fc70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AreaArmedStatusEnum = "ARMED" | "ARMING" | "DISARMED" | "DISARMING" | "PENDING" | "UNKNOWN";
export type ZoneStatus = "BYPASSED" | "DEFAULT" | "LOW_BATTERY" | "MISSING" | "NORMAL" | "OPEN" | "SHORT" | "UNKNOWN";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type Zone_zone$data = {
  readonly area: {
    readonly armedStatus: AreaArmedStatusEnum;
    readonly id: string;
    readonly number: string;
  } | null;
  readonly canBypass: boolean;
  readonly controlSystem: {
    readonly isDSC: boolean;
    readonly isECP: boolean;
    readonly userCanBypassZones: boolean;
  } | null;
  readonly id: string;
  readonly is24HrZone: boolean;
  readonly name: string;
  readonly number: string;
  readonly status: ZoneStatus | null;
  readonly type: ZoneType;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneBypassSwitch_zone" | "ZoneStatusIndicator_zone">;
  readonly " $fragmentType": "Zone_zone";
};
export type Zone_zone$key = {
  readonly " $data"?: Zone_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"Zone_zone">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Zone_zone",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canBypass",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is24HrZone",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "area",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "armedStatus",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystem",
      "kind": "LinkedField",
      "name": "controlSystem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isECP",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDSC",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userCanBypassZones",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneStatusIndicator_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneBypassSwitch_zone"
    }
  ],
  "type": "Zone",
  "abstractKey": null
};
})();

(node as any).hash = "e9c4de9d1b7d4bf9cef08310b4febce0";

export default node;
