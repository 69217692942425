import graphql from "babel-plugin-relay/macro";
import { sleep } from "common/utils/universal/promise";
import BigBlueAddButton from "components/BigBlueAddButton";
import { GenericPageFallback } from "components/GenericPageFallback";
import { AlertsContextProvider } from "contexts/AlertsContext";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { idAsString, toDealerId } from "securecom-graphql/client";
import styled from "styled-components/macro";
import SchedulesHolidayDatesModal, {
  holidayDateListItem,
} from "./SchedulesHolidayDatesModal";
import SchedulesHolidayDatesTable from "./SchedulesHolidayDatesTable";
import { SchedulesHolidayDatesQuery } from "./__generated__/SchedulesHolidayDatesQuery.graphql";

const suspendUntilAngularDataIsPresent = (data: any) => {
  const dataIsStillMissing = data === null || data === undefined;

  if (dataIsStillMissing) {
    throw sleep(50);
  }
};

export default function SchedulesHolidayDatesRoot({
  RelayService,
  $stateParams,
  UserService,
  $rootScope,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <SchedulesHolidayDates
            dealerId={UserService.dealer_id}
            systemId={$stateParams.control_system_id}
            userService={UserService}
          />
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}
function SchedulesHolidayDates({
  userService,
  systemId,
  dealerId,
}: {
  userService: any;
  systemId: number;
  dealerId: number;
}) {
  suspendUntilAngularDataIsPresent(userService.user);

  const { dealer } = useLazyLoadQuery<SchedulesHolidayDatesQuery>(
    graphql`
      query SchedulesHolidayDatesQuery($dealerId: ID!) {
        dealer(id: $dealerId) {
          id
          globalHolidayDates {
            scapiId
            id
            name
            description
            date
            clazz
          }
        }
      }
    `,
    { dealerId: idAsString(toDealerId(dealerId)) },
    { fetchPolicy: "network-only" }
  );
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);

  // Making a copy of globalHolidayDates due to graphql only returning readonly data
  // and sort the holiday by dates.
  const globalHolidayDates =
    dealer?.globalHolidayDates
      .map(({ scapiId, ...holiday }) => {
        const holidayListItem = {
          globalHolidayId: scapiId ?? null,
          ...holiday,
        };
        return holidayListItem as holidayDateListItem;
      })
      .sort((a, b) => {
        if (a.date! < b.date!) return -1;
        if (a.date! > b.date!) return 1;
        return 0;
      }) ?? [];

  return (
    <Root>
      <Header>
        <HeaderTitle>Holiday Dates</HeaderTitle>
        <BigBlueAddButton onClick={openAddModal} />
      </Header>
      <MainContent>
        <SchedulesHolidayDatesTable systemId={systemId} />
      </MainContent>
      {isAddModalOpen ? (
        <SchedulesHolidayDatesModal
          closeModal={closeAddModal}
          systemId={systemId}
          holidaySelectionList={globalHolidayDates}
          isNew={true}
        />
      ) : null}
    </Root>
  );
}
const Root = styled.div`
  margin: 0 -1.5rem;
`;
const Header = styled.div`
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  padding: 1.5rem 0;
`;
const HeaderTitle = styled.span`
  margin-right: 1rem;
`;
const MainContent = styled.span``;
