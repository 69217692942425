/**
 * @ngdoc service
 * @name App.factory:ModemUpgradeCustomersService
 *
 * @description
 * Holds the API and methods for the Modem Upgrade Customers
 *
 */
App.factory("ModemUpgradeStatsService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "ModemUpgradeStatsAPI",
  "UserService",
  "IpResolverService",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    ModemUpgradeStatsAPI,
    UserService,
    IpResolverService
  ) {
    return {
      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeStatsService
       *
       * @description
       * Get statistics of customers who scheduled upgrades.
       *
       * @param {Promise} data- promise response
       */
      getModemUpgradeStats: function () {
        var deferred = $q.defer();
        ModemUpgradeStatsAPI.getModemUpgradeStats(
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
