/**
 * @generated SignedSource<<9a01a6ea1af65bda3651a0bb65bb318d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ControlSystemCommType = "CELL" | "NETWORK" | "PERSISTENT" | "PERSISTENT_WITH_CELL_BACKUP" | "UNKNOWN";
export type X1Type = "X1" | "X8";
import { FragmentRefs } from "relay-runtime";
export type SiteElevatorSystemsSection_siteControlSystem$data = {
  readonly commType: ControlSystemCommType;
  readonly doors: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly hasPendingChanges: boolean;
  readonly id: string;
  readonly name: string;
  readonly online: boolean;
  readonly serialNumber: string;
  readonly type: X1Type;
  readonly " $fragmentSpreads": FragmentRefs<"FirmwareUpdateBadge_siteControlSystem" | "SiteControlSystemJobStatusBadge_siteControlSystem" | "SiteControlSystemOnlineStatusBadge_siteControlSystem">;
  readonly " $fragmentType": "SiteElevatorSystemsSection_siteControlSystem";
};
export type SiteElevatorSystemsSection_siteControlSystem$key = {
  readonly " $data"?: SiteElevatorSystemsSection_siteControlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteElevatorSystemsSection_siteControlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteElevatorSystemsSection_siteControlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingChanges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeUnprogrammedDoors",
          "value": true
        }
      ],
      "concreteType": "SiteDoor",
      "kind": "LinkedField",
      "name": "doors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": "doors(includeUnprogrammedDoors:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FirmwareUpdateBadge_siteControlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteControlSystemJobStatusBadge_siteControlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteControlSystemOnlineStatusBadge_siteControlSystem"
    }
  ],
  "type": "SiteControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "a2af26cf98c6fe976e2d77c332bbc75a";

export default node;
