/**
 * @generated SignedSource<<b18ca4ccb79f9b93a0deac2e6d0eabb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTLockoutCodeProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly lockoutCode: {
      readonly " $fragmentSpreads": FragmentRefs<"LockoutCodeContext_lockoutCode" | "LockoutCodeField_lockoutCode">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XTLockoutCodeProgrammingConceptForm_controlSystem";
};
export type XTLockoutCodeProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XTLockoutCodeProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTLockoutCodeProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTLockoutCodeProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LockoutCode",
          "kind": "LinkedField",
          "name": "lockoutCode",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LockoutCodeContext_lockoutCode"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LockoutCodeField_lockoutCode"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "ded32b88ae66aae1c1c8cd3f1ca62fd9";

export default node;
