/**
 * @generated SignedSource<<25def0f3d0d9d761f7f1ad1c79d9a0f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationBackupFailTimeMinutesField_communication$data = {
  readonly backupCell: boolean;
  readonly backupCheckinMinutes: number | null;
  readonly backupFailTimeMinutes: number | null;
  readonly failTimeValidValues: ReadonlyArray<number>;
  readonly " $fragmentType": "CommunicationBackupFailTimeMinutesField_communication";
};
export type CommunicationBackupFailTimeMinutesField_communication$key = {
  readonly " $data"?: CommunicationBackupFailTimeMinutesField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationBackupFailTimeMinutesField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationBackupFailTimeMinutesField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backupCheckinMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backupFailTimeMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failTimeValidValues",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backupCell",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "8927ed9738165f6ffc1d86a678cce9f8";

export default node;
