import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { BellActions } from "securecom-graphql/client";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsFireBellActionField_bellOptions$key } from "./__generated__/BellOptionsFireBellActionField_bellOptions.graphql";

export const bellOptionsFireBellActionFieldId = () =>
  "bell-options-fire-bell-action";

function BellOptionsFireBellActionField() {
  const [{ fireBellAction }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsFireBellActionField_bellOptions$key>(
      graphql`
        fragment BellOptionsFireBellActionField_bellOptions on BellOptions {
          fireBellAction
        }
      `
    );

  const fieldId = bellOptionsFireBellActionFieldId();
  const softwareVersion = useSoftwareVersion();
  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Fire Bell Action"
      tooltip={`${
        isXf ? "Applies only to NAC cicuits using DMP protocol: " : ""
      } Set the cadence the Bell Output uses for alarms on Fire and Fire Verify Zone types.`}
    >
      <Select
        id={fieldId}
        value={fireBellAction}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(target.value, "fireBellAction");
          });
        }}
      >
        <Select.Option value={BellActions.STEADY}>Steady</Select.Option>
        <Select.Option value={BellActions.PULSE}>Pulse</Select.Option>
        <Select.Option value={BellActions.TEMPORAL}>Temporal</Select.Option>
        <Select.Option value={BellActions.NONE}>None</Select.Option>
        {softwareVersion >= 183 || isXf ? (
          <>
            <Select.Option value={BellActions.TEMPORAL_3}>
              Temporal 3
            </Select.Option>
            <Select.Option value={BellActions.TEMPORAL_4}>
              Temporal 4
            </Select.Option>
          </>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsFireBellActionField;
