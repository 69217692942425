/**
 * @generated SignedSource<<403f14c8f080220a8c1e717008dc13b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PcLogReportsUserCommandReportsEnabledField_pcLogReports$data = {
  readonly userCommandReportsEnabled: boolean;
  readonly " $fragmentType": "PcLogReportsUserCommandReportsEnabledField_pcLogReports";
};
export type PcLogReportsUserCommandReportsEnabledField_pcLogReports$key = {
  readonly " $data"?: PcLogReportsUserCommandReportsEnabledField_pcLogReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"PcLogReportsUserCommandReportsEnabledField_pcLogReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PcLogReportsUserCommandReportsEnabledField_pcLogReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCommandReportsEnabled",
      "storageKey": null
    }
  ],
  "type": "PcLogReports",
  "abstractKey": null
};

(node as any).hash = "20f92fd2c712ba83bb0c0395d97f6dc1";

export default node;
