/**
 * @generated SignedSource<<fb02ab146bd10e89e5f102f8c5d21ab8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsDisplayTimeField_systemOptions$data = {
  readonly displayTime?: boolean;
  readonly " $fragmentType": "SystemOptionsDisplayTimeField_systemOptions";
};
export type SystemOptionsDisplayTimeField_systemOptions$key = {
  readonly " $data"?: SystemOptionsDisplayTimeField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsDisplayTimeField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayTime",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsDisplayTimeField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "39405695b758d55d721446cdba93b6f4";

export default node;
