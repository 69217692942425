import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatNumberField_cardFormat$key } from "./__generated__/CardFormatNumberField_cardFormat.graphql";

export const cardFormatListItemTemplateId = (number: string) =>
  `card-format-${number}`;
export const CARD_FORMAT_NUMBER = `card-format-number-`;
export const cardFormatNumberFieldId = (number: string) =>
  `${CARD_FORMAT_NUMBER}${number}`;

function CardFormatNumberField() {
  const [{ number }] =
    useCardFormatFragment<CardFormatNumberField_cardFormat$key>(
      graphql`
        fragment CardFormatNumberField_cardFormat on CardFormat {
          number
        }
      `
    );

  const fieldId = cardFormatNumberFieldId(number);
  const disabled = true; //This field should not be updatable from DA

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Card Format Number">
      <NumericInput id={fieldId} value={number ?? ""} disabled={disabled} />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatNumberField;
