/**
 * @ngdoc directive
 * @name App.directive:zeroPad
 *
 * @description
 *   Pads an input field value with zeros, to the length specified in the zlength attribute
 *
 */
App.directive("daZeroPad", function ($filter) {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, elem, attrs, ctrl) {
      var length = attrs.zlength;
      if (!length) return;

      //view -> model
      ctrl.$parsers.unshift(function (value) {
        length = attrs.zlength;
        var paddedValue = $filter("zpad")(value, length);
        return paddedValue;
      });

      elem.bind("blur", function () {
        length = attrs.zlength;
        ctrl.$viewValue = $filter("zpad")(elem.val(), length);
        ctrl.$render();
      });

      // This will override the pattern directive for any fields that use the zero-pad directive.
      ctrl.$validators.pattern = function (modelValue, viewValue) {
        if (attrs.pattern == undefined || !attrs.pattern) return true;
        var refPattern = new RegExp("^" + attrs.pattern + "$");
        return refPattern.test(modelValue);
      };
    },
  };
});
