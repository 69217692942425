/**
 * Component: CustomerSummaryTableHeader
 *
 * Description:
 * The `CustomerSummaryTableHeader` component is a React functional component that renders
 * the header row of a table, specifically for the customer summary view. It dynamically creates
 * table headers based on provided columns and includes sorting functionality.
 *
 * Props:
 * - `columns`: An array of `TableColumn` objects, each containing a `columnName` and a `sortKey`.
 *   These define the headers and sorting behavior for each column in the table.
 * - `paginationState`: An object containing the current state of pagination and sorting in the table.
 * - `dispatch`: A dispatch function used to handle actions like sorting when a column header is clicked.
 * - `userCanCreateTempDealerCustomerUser`: A boolean indicating whether the current user has permissions
 *   to create temporary dealer customer users. This affects the rendering of an additional header column.
 *
 * Behavior:
 * - The component maps over the `columns` prop to generate a `TableHeaderSortComponent` for each column.
 * - Each `TableHeaderSortComponent` is responsible for rendering the column header and handling the sorting logic.
 * - Conditionally renders an additional table header cell if the `userCanCreateTempDealerCustomerUser` prop is true.
 * - Includes a fixed-width header cell for additional actions like 'Edit'.
 *
 * Usage:
 * This component is designed to be used in the customer summary view where a sortable table is required.
 * It provides a flexible and reusable way to render table headers with integrated sorting functionality.
 */

import React from "react";
import { SortKey } from "./CustomerSummarySystemTableComponent";
import TableHeaderSortComponent from "./TableHeaderSortComponent";

interface TableColumn {
  columnName: string;
  sortKey: SortKey;
}

interface CustomerSummaryTableHeaderProps {
  columns: TableColumn[];
  paginationState: any;
  dispatch: React.Dispatch<any>;
  userCanCreateTempDealerCustomerUser: boolean;
}

const CustomerSummaryTableHeader: React.FC<CustomerSummaryTableHeaderProps> = ({
  columns,
  paginationState,
  dispatch,
  userCanCreateTempDealerCustomerUser,
}) => {
  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <TableHeaderSortComponent
            key={column.columnName}
            columnName={column.columnName}
            sortKey={column.sortKey}
            paginationState={paginationState}
            dispatch={dispatch}
          />
        ))}
        {/**Login as Customer Column Heading */}
        <th></th>
        {/**Edit Column Heading */}
        <th style={{ width: "10px" }}></th>
      </tr>
    </thead>
  );
};

export default CustomerSummaryTableHeader;
