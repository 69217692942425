import DangerText from "components/DangerText";
import NeutralText from "components/NeutralText";
import SuccessText from "components/SuccessText";
import * as React from "react";

const iconStyle = {
  bottom: 0,
  verticalAlign: "baseline",
  paddingRight: "0",
  width: "1.6rem",
};

export default function VideoDeviceStatus({
  status,
  isOnline,
}: {
  isOnline?: boolean;
  status: string;
}) {
  switch (status) {
    case "ACTIVE":
      return isOnline === undefined ? (
        <SuccessText value={600}>
          <i className="icon-checkmark font-sz-12" style={iconStyle} />
          Active
        </SuccessText>
      ) : isOnline ? (
        <SuccessText value={600}>
          <i className="icon-checkmark font-sz-12" style={iconStyle} />
          Online
        </SuccessText>
      ) : (
        <NeutralText>
          {" "}
          <i className="icon-radial_alert font-sz-12" style={iconStyle} />
          Offline
        </NeutralText>
      );
    case "INACCESSIBLE":
      return <DangerText>Inaccessible</DangerText>;
    case "ERROR":
      return (
        <DangerText>
          <i className="icon-close_cancel font-sz-12" style={iconStyle} />
          Error
        </DangerText>
      );
    case "ACTIVATING":
      return <NeutralText>Activating...</NeutralText>;
    default:
      return <NeutralText>{status}</NeutralText>;
  }
}
