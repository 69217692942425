/**
 * @generated SignedSource<<be89cd7496d00c83e921ec07a1c52ae9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver1OpenCloseUserReportsField_communication$data = {
  readonly receiver1OpenCloseUserReports: boolean;
  readonly " $fragmentType": "CommunicationReceiver1OpenCloseUserReportsField_communication";
};
export type CommunicationReceiver1OpenCloseUserReportsField_communication$key = {
  readonly " $data"?: CommunicationReceiver1OpenCloseUserReportsField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver1OpenCloseUserReportsField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver1OpenCloseUserReportsField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver1OpenCloseUserReports",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "bdfe32df0c6b1fdd74351fafd5ffa068";

export default node;
