import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useMessagingSetupFragment } from "./MessagingSetupContext";
import { MessagingSetupDestination2Field_messagingSetup$key } from "./__generated__/MessagingSetupDestination2Field_messagingSetup.graphql";

export const messagingSetupDestination2FieldId = () =>
  "messaging-setup-destination-2";

function MessagingSetupDestination2Field() {
  const [{ secondEmailOrPhoneNumber, enableMessaging }, updateMessagingSetup] =
    useMessagingSetupFragment<MessagingSetupDestination2Field_messagingSetup$key>(
      graphql`
        fragment MessagingSetupDestination2Field_messagingSetup on MessagingSetup {
          secondEmailOrPhoneNumber
          enableMessaging
        }
      `
    );

  const fieldId = messagingSetupDestination2FieldId();
  const disabled = !enableMessaging;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Destination 2"
      disabled={disabled}
      tooltip="Cellular phone number that will receive SMS messages from this System."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={secondEmailOrPhoneNumber}
        validationMessage="48 character maximum."
        pattern="^.*$"
        onChange={({ target }) => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(target.value, "secondEmailOrPhoneNumber");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MessagingSetupDestination2Field;
