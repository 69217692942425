import * as React from "react";

type Props = {
  value?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000;
  as?: keyof React.ReactHTML;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

export default function NeutralText({
  value = 800,
  as = "span",
  style = {},
  children,
  className,
  ...rest
}: Props) {
  return React.createElement(
    as,
    {
      ...rest,
      className,
      style: { ...style, color: `var(--color-neutral-${value})` },
    },
    children
  );
}
