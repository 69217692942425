import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { range } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { useControlSystem } from "../ControlSystemContext";
import NumericInput from "../NumericInput";
import { useHardwareModel, usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputGroupFragment } from "./OutputGroupContext";
import { outputGroupNameFieldId } from "./OutputGroupNameField";
import { outputGroupOutputNumberFieldId } from "./OutputGroupOutputNumberField";
import { resolveOutputGroupFieldRules } from "./utils";
import { OutputGroupNumberField_outputGroup$key } from "./__generated__/OutputGroupNumberField_outputGroup.graphql";
import { OutputGroupNumberField_panel$key } from "./__generated__/OutputGroupNumberField_panel.graphql";

export const outputGroupListItemTemplateId = (number: string) =>
  `output-group-${number}`;
export const OUTPUT_GROUP_NUMBER = "output-group-number-field-";
export const outputGroupNumberFieldId = (number: string) =>
  OUTPUT_GROUP_NUMBER + number;

export const OUTPUT_GROUP_IDS = [
  outputGroupListItemTemplateId,
  outputGroupNumberFieldId,
  outputGroupNameFieldId,
  outputGroupOutputNumberFieldId("1"),
  outputGroupOutputNumberFieldId("2"),
  outputGroupOutputNumberFieldId("3"),
  outputGroupOutputNumberFieldId("4"),
  outputGroupOutputNumberFieldId("5"),
  outputGroupOutputNumberFieldId("6"),
  outputGroupOutputNumberFieldId("7"),
  outputGroupOutputNumberFieldId("8"),
];

function OutputGroupNumberField() {
  const [{ id, number, isNew }, updateOutputGroup] =
    useOutputGroupFragment<OutputGroupNumberField_outputGroup$key>(
      graphql`
        fragment OutputGroupNumberField_outputGroup on OutputGroup {
          id
          number
          isNew
        }
      `
    );
  const [{ outputGroups }] =
    usePanelFragment<OutputGroupNumberField_panel$key>(graphql`
      fragment OutputGroupNumberField_panel on Panel {
        outputGroups {
          number
        }
      }
    `);

  const duplicateOutputGroupsExist =
    outputGroups.filter(
      ({ number: outputNumber }) => Number(outputNumber) === Number(number)
    ).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      OUTPUT_GROUP_IDS,
      duplicateOutputGroupsExist,
      String(number),
      outputGroupListItemTemplateId
    );

  const fieldId = outputGroupNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();
  const { NUMBER: RULES } = resolveOutputGroupFieldRules(hardwareModel);
  const disabled = !isNew;

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Output Group Number">
      <NumericInput
        id={fieldId}
        disabled={disabled}
        inlineHelp={RULES.INLINE_HELP}
        value={number}
        pattern={RULES.PATTERN}
        required={!disabled}
        min={1}
        max={20}
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateOutputGroup((recordProxy) => {
            recordProxy.setValue(target.value, "number");
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate output group numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = number;
          relayEnv.commitUpdate((store) => {
            const controlSystemRecord = store.get(controlSystem.id);
            const currentOutputGroup = store.get(id);
            if (currentOutputGroup) {
              const panelRecord = controlSystemRecord?.getLinkedRecord("panel");
              const outputGroupRecords =
                panelRecord?.getLinkedRecords("outputGroups");

              if (outputGroupRecords) {
                const takenNumbers = new Set(
                  outputGroupRecords.map((outputGroupRecord) =>
                    outputGroupRecord.getValue("id") !== id
                      ? outputGroupRecord.getValue("number")
                      : 0
                  )
                );

                const valueAsNumber = Number(target.value);
                const value = closest(
                  !target.value || isNaN(valueAsNumber) ? 1 : valueAsNumber,
                  range(1, 21).filter((num) => !takenNumbers.has(num))
                );
                currentOutputGroup.setValue(value, "number");
                blurredNumber = value;
              }
            }
          });
          onBlurNumberUpdate(String(blurredNumber));
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputGroupNumberField;
