import graphql from "babel-plugin-relay/macro";
import FormGridItems from "components/FormGridItems";
import {
  useEventEmitter,
  useGetCameraUrl,
  useOpenSecurecomCameraModal,
} from "components/SiteForm/EntryPointContext";
import Spacer from "components/SiteForm/Layout/Spacer";
import React, { useEffect } from "react";
import { useFragment, useRefetchableFragment } from "react-relay/hooks";
import { asID, fromVideoChannelId } from "securecom-graphql/client";
import VideoDeviceStatus from "../VideoDeviceStatus";
import Icon from "./Components/Icon";
import { PillButton } from "./Components/PillButton";
import Grid from "./VideoSection";
import styles from "./VideoSection.module.css";
import { SecureComCameras_secureComCamera$key } from "./__generated__/SecureComCameras_secureComCamera.graphql";
import { SecureComCameras_site$key } from "./__generated__/SecureComCameras_site.graphql";

export const TestConnectionMutation = graphql`
  mutation SecureComCamerasTestConnectionMutation($id: ID!) {
    testCameraConnection(id: $id) {
      ... on NotFoundError {
        errorType: type
      }
      ... on UnauthorizedError {
        errorType: type
      }
      ... on SecureComCamera {
        id
        isOnline
      }
    }
  }
`;

export default function SecureComCameras({
  siteRef,
}: {
  siteRef: SecureComCameras_site$key;
}) {
  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment SecureComCameras_site on Site
      @refetchable(queryName: "SecurecomCamerasRefetchQuery") {
        id
        billingControlSystemId
        securecomCameras {
          id
          ...SecureComCameras_secureComCamera
        }
      }
    `,
    siteRef
  );

  const openSecureComCameraModal = useOpenSecurecomCameraModal();
  const getCameraUrl = useGetCameraUrl(data.billingControlSystemId, data.id);
  const secureComCameraEmitter = useEventEmitter();

  useEffect(() => {
    function refresh() {
      refetch({}, { fetchPolicy: "network-only" });
    }
    if (secureComCameraEmitter) {
      secureComCameraEmitter.on("securecom-changed", refresh);
      return () => {
        secureComCameraEmitter.off("securecom-changed", refresh);
      };
    }
  }, [refetch, secureComCameraEmitter]);

  return (
    <Grid>
      <Grid.Header>
        <Icon className="icon-camera" />
        Cameras
        <div className={styles["securecom-add-button"]}>
          <PillButton
            onClick={() =>
              openSecureComCameraModal(data.billingControlSystemId)
            }
          />
        </div>
      </Grid.Header>
      {data.securecomCameras.length > 0 && (
        <>
          <FormGridItems>
            {data.securecomCameras.map((secureComCamera) => (
              <SecureComCamera
                key={secureComCamera.id}
                secureComCameraRef={secureComCamera}
                getCameraUrl={getCameraUrl}
              />
            ))}
          </FormGridItems>
          <Spacer />
        </>
      )}
    </Grid>
  );
}

function SecureComCamera({
  secureComCameraRef,
  getCameraUrl,
}: {
  secureComCameraRef: SecureComCameras_secureComCamera$key;
  getCameraUrl: (
    cameraId: string,
    manufacturer: string,
    channelId: number
  ) => string;
}) {
  const camera = useFragment(
    graphql`
      fragment SecureComCameras_secureComCamera on SecureComCamera {
        id
        name
        status
        isOnline
        manufacturer
        channelId
      }
    `,
    secureComCameraRef
  );

  const { deviceId } = fromVideoChannelId(asID(camera.id));

  return (
    <FormGridItems.Item>
      <FormGridItems.ClickableContent
        as="a"
        href={getCameraUrl(deviceId, camera.manufacturer, camera.channelId)}
      >
        <FormGridItems.ItemTitle>{camera.name}</FormGridItems.ItemTitle>
        <VideoDeviceStatus isOnline={camera.isOnline} status={camera.status} />
      </FormGridItems.ClickableContent>
    </FormGridItems.Item>
  );
}
