/**
 * @ngdoc service
 * @name App.factory:VerizonV2API
 *
 * @description
 * API factory for retrieving Verizon cellular information from Verizon.
 *
 */
App.factory("VerizonV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    // See existing API documentation on line below
    // http://jenkins.dmp.com:8080/view/Ohm%20%28WS%29/job/ohm-v2_nphase/ws/doc/Api/V1/Nphase/DeviceServiceController.html
    return $resource(
      PROPS.simsUrl +
        "/api/v1/dealers/:dealer_id/sims/nphase/device_service/get_device_information",
      {
        json: 1, //required flag to instruct the API to return a JSON structure (instead of xml)
      },
      {
        get_device_information: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            device_identifier: "@device_identifier",
            device_identifier_kind: "@device_identifier_kind",
          },
          headers: { Accept: "application/json" },
        },
      }
    );
  },
]);

App.factory("VerizonV2ConnectionsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    // See existing API documentation on line below
    // http://jenkins.dmp.com:8080/view/Ohm%20%28WS%29/job/ohm-v2_nphase/ws/doc/Api/V1/Nphase/DeviceServiceController.html
    return $resource(
      PROPS.simsUrl +
        "/api/v1/dealers/:dealer_id/sims/nphase/get_device_connection_history/get_device_information",
      {
        json: 1, //required flag to instruct the API to return a JSON structure (instead of xml)
      },
      {
        get_device_connections: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            device_identifier: "@device_identifier",
            device_identifier_kind: "@device_identifier_kind",
            start_date: "@start_date",
            end_date: "@end_date",
          },
          headers: { Accept: "application/json" },
        },
      }
    );
  },
]);
