App.controller("CustomReportCtrl", [
  "$scope",
  "$rootScope",
  "$window",
  "$state",
  "$http",
  "$timeout",
  "$compile",
  "$filter",
  "$q",
  "focus",
  "DashboardDataService",
  "UserService",
  "customReport",
  "CustomReportsService",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "DTColumnBuilder",
  "$localStorage",
  "DataTablesUtilsService",
  "JobSchedulerService",
  "Customer",
  "ControlSystemsService",
  function (
    $scope,
    $rootScope,
    $window,
    $state,
    $http,
    $timeout,
    $compile,
    $filter,
    $q,
    focus,
    DashboardDataService,
    UserService,
    customReport,
    CustomReportsService,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    DTColumnBuilder,
    $localStorage,
    DataTablesUtilsService,
    JobSchedulerService,
    Customer,
    ControlSystemsService
  ) {
    $scope.UserService = UserService;
    $scope.dealer_id = UserService.dealer_id;
    $scope.dtColumns = [];
    if ($localStorage.thisCustomReport) {
      $scope.customReport = new customReport($localStorage.thisCustomReport);
    } else {
      $rootScope.alerts.push({
        type: "error",
        text: "Something went wrong.  Please try to run the report again.",
      });
      //$window.close();
    }

    $scope.dtOptions = DataTablesUtilsService.getDTOptions(getReportData(), "");

    function getReportData() {
      var deferred = $q.defer();
      $scope.customReport
        .getData()
        .then(function (data) {
          deferred.resolve(data);
        })
        .catch(function (error) {
          console.error(error);
        });
      // This must be outside of the promise, since only the DATA is promise based...we need column info immediately.
      getColumns();
      return deferred.promise;
    }

    function getColumns() {
      // Define datatable columns
      angular.forEach($scope.customReport.HeaderMap, function (column) {
        var dtColumn = DTColumnBuilder.newColumn(column.field).withTitle(
          column.name
        );
        if (column.visible === false) dtColumn.notVisible();
        dtColumn = formatColumn(dtColumn, column);
        $scope.dtColumns.push(dtColumn);
      });
    }

    function formatColumn(dtColumn, column) {
      if (column.type && column.type.includes("date")) {
        if (column.type.includes("datetime_raw")) {
          dtColumn = dtColumn.renderWith(
            DataTablesUtilsService.asLiteralDateTimeRenderFn(column.field)
          );
        } else {
          dtColumn = dtColumn.renderWith(
            DataTablesUtilsService.asDateTimeRenderFn()
          );
        }
      } else if (column.type === "boolean") {
        dtColumn = dtColumn.renderWith(function (data, type, row) {
          return DataTablesUtilsService.asBoolean(row, column.field);
        });
      } else if (column.type === "hardware_model") {
        dtColumn = dtColumn.renderWith(function (data, type, row) {
          return DataTablesUtilsService.asHardwareModel(row, column.field);
        });
      } else if (column.type === "string_titlecase") {
        dtColumn = dtColumn.renderWith(function (data, type, row) {
          return DataTablesUtilsService.stringTitlecase(row, column.field);
        });
      } else if (column.type === "comm_type") {
        dtColumn = dtColumn.renderWith(function (data, type, row) {
          return DataTablesUtilsService.commType(row, column.field);
        });
      } else {
        dtColumn = dtColumn.renderWith(function (data, type, row) {
          return DataTablesUtilsService.dashProperty(row, column.field);
        });
      }
      return dtColumn;
    }

    if ($scope.customReport.ConfigData.report_type === "new_systems") {
      $scope.dtOptions.withOption("order", [6, "desc"]);
    }
  },
]);
