/**
 * @generated SignedSource<<499b6ec49d1360074bec49582c225693>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2TestReportsField_communication$data = {
  readonly receiver2TestReports: boolean;
  readonly " $fragmentType": "CommunicationReceiver2TestReportsField_communication";
};
export type CommunicationReceiver2TestReportsField_communication$key = {
  readonly " $data"?: CommunicationReceiver2TestReportsField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2TestReportsField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2TestReportsField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2TestReports",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "a4bbdb097cf21fb7ccd84dce9d7dcd53";

export default node;
