import { Dispatch } from "react";

export type IntegrationFormState = {
  readonly id: string | null;
  readonly centralStationName: string;
  readonly username: string;
  readonly password: string;
  readonly emergencyContactsEnabled: boolean;
  readonly systemTestsEnabled: boolean;
  readonly usernameBlurred: boolean;
  readonly passwordBlurred: boolean;
  readonly centralStationCode: string;
  readonly cmsDealerNumber: string;
};

type Action =
  | { type: "NAME_CHANGED"; value: string }
  | { type: "USERNAME_CHANGED"; value: string }
  | { type: "PASSWORD_CHANGED"; value: string }
  | { type: "USERNAME_BLURRED" }
  | { type: "PASSWORD_BLURRED" }
  | { type: "ALLOW_EDIT_CONTACT_LIST_CHANGED" }
  | { type: "ALLOW_PLACE_SYSTEM_ON_TEST_CHANGED" }
  | { type: "CENTRAL_STATION_CODE_CHANGED"; value: string }
  | { type: "CMS_DEALER_NUMBER_CHANGED"; value: string };

export type IntegrationFormDispatch = Dispatch<Action>;

export const reducer = (
  state: IntegrationFormState,
  action: Action
): IntegrationFormState => {
  switch (action.type) {
    case "NAME_CHANGED":
      return {
        ...state,
        centralStationName: action.value,
      };
    case "USERNAME_CHANGED":
      return {
        ...state,
        username: action.value,
      };
    case "PASSWORD_CHANGED":
      return {
        ...state,
        password: action.value,
      };
    case "CENTRAL_STATION_CODE_CHANGED":
      return {
        ...state,
        centralStationCode: action.value,
      };
    case "ALLOW_EDIT_CONTACT_LIST_CHANGED":
      return {
        ...state,
        emergencyContactsEnabled: !state.emergencyContactsEnabled,
      };
    case "ALLOW_PLACE_SYSTEM_ON_TEST_CHANGED":
      return {
        ...state,
        systemTestsEnabled: !state.systemTestsEnabled,
      };
    case "USERNAME_BLURRED":
      return {
        ...state,
        usernameBlurred: true,
      };
    case "PASSWORD_BLURRED":
      return {
        ...state,
        passwordBlurred: true,
      };
    case "CMS_DEALER_NUMBER_CHANGED":
      return {
        ...state,
        cmsDealerNumber: action.value,
      };

    default:
      return state;
  }
};
