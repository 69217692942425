import { RelayServiceType } from "app/common/services/relay-service";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import ClaimSystemModalButton from "./ClaimSystemModalButton";
/**
 * this is the legend for the system object
 *
 * @alias customer-id="system.a"
 * @alias dealer-id="system.d"
 * @alias panel-id="system.e"
 * @alias system-name="system.f"
 * @alias panel-account="system.p"
 * @alias panel-serial-number="system.s"
 * @alias panel-account-number="system.q"
 */

interface ClaimSystemModalRootProps {
  $rootScope: any;
  $scope: any;
  $state: any;
  UserService: any;
  RelayService: RelayServiceType;
  PROPS: any;
  ControlSystemService: any;
  systemClaimed: boolean;
  system: {
    a: string;
    d: string;
    e: string;
    f: string;
    p: string;
    s: string;
    q: string;
  };
}

function ClaimSystemModalRoot(props: ClaimSystemModalRootProps) {
  const {
    $rootScope,
    $scope,
    $state,
    UserService,
    RelayService,
    ControlSystemService,
    system,
    systemClaimed,
  } = props;

  const mappedSystem = {
    dealerId: system.d,
    customerId: system.a,
    panelId: system.e,
    systemName: system.f,
    panelAccount: system.p,
    panelAccountNumber: system.q,
    panelSerialNumber: system.s,
  };

  return (
    <RelayEnvironmentProvider environment={RelayService.getEnvironment()}>
      <AlertsContextProvider $rootScope={$rootScope}>
        <ClaimSystemModalButton
          $rootScope={$rootScope}
          $state={$state}
          UserService={UserService}
          $scope={$scope}
          controlSystemService={ControlSystemService}
          system={mappedSystem}
        />
      </AlertsContextProvider>
    </RelayEnvironmentProvider>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "claimSystemModal",
    react2angular(
      ClaimSystemModalRoot,
      ["system", "systemClaimed"],
      [
        "$rootScope",
        "$scope",
        "$state",
        "UserService",
        "RelayService",
        "PROPS",
        "ControlSystemService",
      ]
    )
  );
}
