/**
 * @directive Beacon Notifier -- passed as attribute to a containing element
 * @desc --Used to pass information to a user.
 * @desc --Created to be used on a DOM object with a class of beacon.
 * @desc -- beacon class element -- The beacon is an inline pulsating dot; the pulse animation serves as an attention grabbing element.
 * @var -- beaconTitle - string - can be sent inline or dynamic
 * @var -- beaconContent - string - can be sent inline or dynamic - may contain html
 * @var -- beaconPlacement - Defaults to 'top' position of popover in relation to its containing element
 * @var -- beaconAppendToBody - defaults to false. It can be either 'body' or false - use body if the notification popover is being clipped by its parent element. The popover will maintain the same position but be a child of <body>.
 *
 * @example -- <span class="beacon && ['' || 'beacon--success' || 'beacon--warning' || 'beacon--danger' :: (will change header color and icon)]" da-beacon-notifier beacon-content="{{popoverHTML.content}}" beacon-title="{{popoverHTML.title}}"  beacon-placement="left" beacon-append-to-body="body" beacon-type="danger"></span>
 */
App.directive("daBeaconNotifier", function ($document, $parse, $sanitize) {
  return {
    restrict: "A",

    link: function (scope, element, attr) {
      let html,
        beaconId = Math.floor(Math.random() * 1000000),
        beaconTitle,
        beaconContent,
        beaconType,
        beaconPlacement,
        beaconAppendToBody;

      beaconTitle = $sanitize(attr.beaconTitle) || "Default Title";
      beaconContent = attr.beaconContent || "Default Content";
      beaconPlacement = attr.beaconPlacement || "top";
      beaconAppendToBody = attr.beaconAppendToBody || false;
      beaconType = attr.beaconType || "";

      html = `<div id="beacon-popup-${beaconId}" style="margin: -9px -14px;">

    <div class="popover-title--beacon popover-title--beacon--${beaconType}">
      ${beaconTitle}
      <div class="popover-title--beacon-closer" style="margin-left: auto;" ng-click="closePop()"></div>
    </div>
    <div class="popover-content">${beaconContent}</div>
  </div>`;

      element.popover({
        content: html,
        html: true,
        trigger: "manual",
        placement: beaconPlacement,
        animation: true,
        container: beaconAppendToBody ? "body" : null,
      });
      return element.bind("click", function (e) {
        var dontBubble, pop;
        dontBubble = true;

        e.stopPropagation();

        element.popover("show");

        pop = $("#beacon-popup-" + beaconId);

        pop.closest(".popover").click(function (e) {
          if (dontBubble) {
            e.stopPropagation();
          }
        });

        pop.find(".popover-title--beacon-closer").click(function (e) {
          dontBubble = true;
          e.stopPropagation();

          element.popover("hide");
        });

        $document.on(
          "click.confirmbutton." + beaconId,
          ":not(.popover, .popover *)",
          function () {
            $document.off("click.confirmbutton." + beaconId);
            element.popover("hide");
          }
        );
      });
    },
  };
});
