import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  Area,
  asID,
  fromZoneId,
  idAsString,
  ProgrammingTemplate,
  toAreaId,
  Zone,
  ZoneType,
} from "securecom-graphql/client";
import { useAreaList } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useTemplateContext } from "../TemplateContext";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationReportWithAccountNumberField_zone$key } from "./__generated__/ZoneInformationReportWithAccountNumberField_zone.graphql";

export const zoneInformationReportWithAccountNumberFieldId = (number: string) =>
  `zone-information-report-with-account-number-${number}`;

function ZoneInformationReportWithAccountNumberField() {
  const [{ number, reportWithAccountNumberForArea, id, type }] =
    useZoneInformationFragment<ZoneInformationReportWithAccountNumberField_zone$key>(
      graphql`
        fragment ZoneInformationReportWithAccountNumberField_zone on Zone {
          id
          number
          type
          reportWithAccountNumberForArea {
            id
            number
            name
          }
        }
      `
    );

  const fieldId = zoneInformationReportWithAccountNumberFieldId(String(number));
  const disabled = ![
    ZoneType.FIRE,
    ZoneType.FIRE_VERIFY,
    ZoneType.PANIC,
    ZoneType.EMERGENCY,
    ZoneType.SUPERVISORY,
    ZoneType.CARBON_MONOXIDE,
  ].includes(type as ZoneType);
  const areaList = useAreaList();
  const relayEnv = useRelayEnvironment();

  const { templateId } = useTemplateContext();

  const onChangeClicked = React.useRef(false);

  React.useEffect(() => {
    let areaNumber = reportWithAccountNumberForArea?.number ?? "NONE";
    if (templateId && !onChangeClicked.current) {
      relayEnv.commitUpdate((store) => {
        const template = store.get<ProgrammingTemplate>(templateId);
        areaNumber = String(
          template
            ?.getLinkedRecord("concepts")
            ?.getLinkedRecords("zoneInformations")
            .find((zone) => Number(zone.getValue("number")) === Number(number))
            ?.getLinkedRecord("reportWithAccountNumberForArea")
            ?.getValue("data") ?? ""
        );
      });
    }
    const selectedId = areaList.find(
      (areaInList) => Number(areaInList.number) === Number(areaNumber)
    );
    relayEnv.commitUpdate((store) => {
      const zone = store.get<Zone>(id);
      const area = store.get<Area>(selectedId?.id ?? "");
      if (zone && area) {
        zone.setLinkedRecord(area, "reportWithAccountNumberForArea");
      } else if (!area && zone) {
        let unselectedArea = store.get<Area>(
          idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0"))
        );
        if (!unselectedArea) {
          unselectedArea = store.create(
            idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0")),
            "Area"
          ) as RecordProxy<Area>;
        }

        if (unselectedArea) {
          unselectedArea.setValue("0", "number");
          zone.setLinkedRecord(
            unselectedArea,
            "reportWithAccountNumberForArea"
          );
        }
      }
    });
  }, [
    reportWithAccountNumberForArea,
    areaList,
    number,
    relayEnv,
    templateId,
    id,
  ]);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Report with Account Number"
      tooltip="Attach an Account Number from an Area for this Zone with messages sent to a Receiver."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={
          reportWithAccountNumberForArea?.number === "0" ||
          reportWithAccountNumberForArea === null
            ? "NONE"
            : reportWithAccountNumberForArea?.number
        }
        disabled={disabled}
        onChange={({ target }) => {
          onChangeClicked.current = true;
          const selectedId = areaList.find(
            (areaInList) => areaInList.number === target.value
          );
          relayEnv.commitUpdate((store) => {
            const zone = store.get<Zone>(id);
            const area = store.get<Area>(selectedId?.id ?? "");
            if (area && zone) {
              zone.setLinkedRecord(area, "reportWithAccountNumberForArea");
            } else if (!area && zone) {
              let unselectedArea = store.get<Area>(
                idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0"))
              );
              if (!unselectedArea) {
                unselectedArea = store.create(
                  idAsString(toAreaId(fromZoneId(asID(id)).systemId, "0")),
                  "Area"
                ) as RecordProxy<Area>;
              }

              if (unselectedArea) {
                unselectedArea.setValue("0", "number");
                zone.setLinkedRecord(
                  unselectedArea,
                  "reportWithAccountNumberForArea"
                );
              }
            }
          });
        }}
      >
        <Select.Option value={"NONE"} key={"NONE"}>
          -
        </Select.Option>
        {areaList.map((area) => (
          <Select.Option key={area.id} value={area.number}>
            {area.name}
          </Select.Option>
        ))}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationReportWithAccountNumberField;
