import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { statusListDisplayAuxiliary1ZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayAuxiliary1ZoneKeypadsField";
import { statusListDisplayAuxiliary2ZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayAuxiliary2ZoneKeypadsField";
import { statusListDisplayBurglaryZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayBurglaryZoneKeypadsField";
import { statusListDisplayCarbonMonoxideZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayCarbonMonoxideZoneKeypadsField";
import { statusListDisplayCommPathTroubleFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayCommPathTroubleField";
import { statusListDisplayEmergencyZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayEmergencyZoneKeypadsField";
import { statusListDisplayFireZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayFireZoneKeypadsField";
import { statusListDisplayPanicZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplayPanicZoneKeypadsField";
import { statusListDisplaySupervisoryZoneKeypadsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplaySupervisoryZoneKeypadsField";
import { statusListDisplaySystemTroubleStatusMonitorsFieldId } from "components/FullProgramming/common/StatusListDisplayFields/StatusListDisplaySystemTroubleStatusMonitorsField";
import { XRStatusListDisplayProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRStatusListDisplayProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getStatusListDisplayState } from "../../../XRFullProgramming/XRStatusListDisplayProgrammingConceptForm";
import { XrProgrammingTemplateStatusListDisplayInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        statusListDisplay {
          id
          included
          systemTroubleStatusMonitors {
            data
            included
          }
          fireZoneKeypads {
            data
            included
          }
          burglaryZoneKeypads {
            data
            included
          }
          supervisoryZoneKeypads {
            data
            included
          }
          panicZoneKeypads {
            data
            included
          }
          emergencyZoneKeypads {
            data
            included
          }
          auxiliary1ZoneKeypads {
            data
            included
          }
          auxiliary2ZoneKeypads {
            data
            included
          }
          carbonMonoxideZoneKeypads {
            data
            included
          }
          commPathTrouble {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRStatusListDisplayProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateStatusListDisplayInput => {
  const {
    panel: { statusListDisplay },
  } = getStatusListDisplayState(controlSystem);

  if (!statusListDisplay) {
    return { included: false };
  }

  const statusListDisplayInputFields = {
    systemTroubleStatusMonitors: {
      data: statusListDisplay.systemTroubleStatusMonitors,
      included: includedFields.has(
        statusListDisplaySystemTroubleStatusMonitorsFieldId()
      ),
    },
    fireZoneKeypads: {
      data: statusListDisplay.fireZoneKeypads,
      included: includedFields.has(statusListDisplayFireZoneKeypadsFieldId()),
    },
    burglaryZoneKeypads: {
      data: statusListDisplay.burglaryZoneKeypads,
      included: includedFields.has(
        statusListDisplayBurglaryZoneKeypadsFieldId()
      ),
    },
    supervisoryZoneKeypads: {
      data: statusListDisplay.supervisoryZoneKeypads,
      included: includedFields.has(
        statusListDisplaySupervisoryZoneKeypadsFieldId()
      ),
    },
    panicZoneKeypads: {
      data: statusListDisplay.panicZoneKeypads,
      included: includedFields.has(statusListDisplayPanicZoneKeypadsFieldId()),
    },
    emergencyZoneKeypads: {
      data: statusListDisplay.emergencyZoneKeypads,
      included: includedFields.has(
        statusListDisplayEmergencyZoneKeypadsFieldId()
      ),
    },
    auxiliary1ZoneKeypads: {
      data: statusListDisplay.auxiliary1ZoneKeypads,
      included: includedFields.has(
        statusListDisplayAuxiliary1ZoneKeypadsFieldId()
      ),
    },
    auxiliary2ZoneKeypads: {
      data: statusListDisplay.auxiliary2ZoneKeypads,
      included: includedFields.has(
        statusListDisplayAuxiliary2ZoneKeypadsFieldId()
      ),
    },
    carbonMonoxideZoneKeypads: {
      data: statusListDisplay.carbonMonoxideZoneKeypads,
      included: includedFields.has(
        statusListDisplayCarbonMonoxideZoneKeypadsFieldId()
      ),
    },
    commPathTrouble: {
      data: statusListDisplay.commPathTrouble,
      included: includedFields.has(statusListDisplayCommPathTroubleFieldId()),
    },
  };

  const statusListDisplayIncluded = Object.values(
    statusListDisplayInputFields
  ).some(({ included }) => included);

  return {
    included: statusListDisplayIncluded,
    ...statusListDisplayInputFields,
  };
};

export const setIncludedFields = (
  template: XRStatusListDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { statusListDisplay } = getState(template);

  if (!statusListDisplay) return;

  if (statusListDisplay.systemTroubleStatusMonitors?.included) {
    setIncludedFields(
      setToggle(statusListDisplaySystemTroubleStatusMonitorsFieldId())
    );
  }
  if (statusListDisplay.fireZoneKeypads?.included) {
    setIncludedFields(setToggle(statusListDisplayFireZoneKeypadsFieldId()));
  }

  if (statusListDisplay.burglaryZoneKeypads?.included) {
    setIncludedFields(setToggle(statusListDisplayBurglaryZoneKeypadsFieldId()));
  }
  if (statusListDisplay.supervisoryZoneKeypads?.included) {
    setIncludedFields(
      setToggle(statusListDisplaySupervisoryZoneKeypadsFieldId())
    );
  }
  if (statusListDisplay.panicZoneKeypads?.included) {
    setIncludedFields(setToggle(statusListDisplayPanicZoneKeypadsFieldId()));
  }
  if (statusListDisplay.emergencyZoneKeypads?.included) {
    setIncludedFields(
      setToggle(statusListDisplayEmergencyZoneKeypadsFieldId())
    );
  }
  if (statusListDisplay.auxiliary1ZoneKeypads?.included) {
    setIncludedFields(
      setToggle(statusListDisplayAuxiliary1ZoneKeypadsFieldId())
    );
  }
  if (statusListDisplay.auxiliary2ZoneKeypads?.included) {
    setIncludedFields(
      setToggle(statusListDisplayAuxiliary2ZoneKeypadsFieldId())
    );
  }
  if (statusListDisplay.carbonMonoxideZoneKeypads?.included) {
    setIncludedFields(
      setToggle(statusListDisplayCarbonMonoxideZoneKeypadsFieldId())
    );
  }
  if (statusListDisplay.commPathTrouble?.included) {
    setIncludedFields(setToggle(statusListDisplayCommPathTroubleFieldId()));
  }
};
