/**
 * @generated SignedSource<<54c16ae5af4d4b657718f399bc1b3cdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputInformationSerialNumberField_output$data = {
  readonly isWireless: boolean;
  readonly number: number;
  readonly serialNumber: string | null;
  readonly " $fragmentType": "OutputInformationSerialNumberField_output";
};
export type OutputInformationSerialNumberField_output$key = {
  readonly " $data"?: OutputInformationSerialNumberField_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationSerialNumberField_output">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputInformationSerialNumberField_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWireless",
      "storageKey": null
    }
  ],
  "type": "Output",
  "abstractKey": null
};

(node as any).hash = "afe7a2ee8370d2565f5dc73058df8064";

export default node;
