/**
 * @generated SignedSource<<2e1c79dca5c0084b801ac34fb16e562c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75KeyFobsProgrammingConceptForm_controlSystem$data = {
  readonly copiedKeyfob: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly keyfobs: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly keyfobSerialNumber: string;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"KeyfobFields_keyfob">;
    }>;
    readonly keyfobsRange: ReadonlyArray<number> | null;
    readonly maxKeyfobs: number | null;
    readonly newKeyfob: {
      readonly id: string;
      readonly keyfobSerialNumber: string;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"KeyfobFields_keyfob">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"KeyfobNumberField_panel" | "KeyfobSerialNumberField_SerialNumberList_panel" | "PanelContextUseHardwareModel_panel" | "PanelContextUseKeyfobNumberRange_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContextUseSystemOptionsSystemType_panel" | "PanelContextUseUserList_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem">;
  readonly " $fragmentType": "XT75KeyFobsProgrammingConceptForm_controlSystem";
};
export type XT75KeyFobsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75KeyFobsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75KeyFobsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyfobSerialNumber",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "KeyfobFields_keyfob"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75KeyFobsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyfobNumberField_panel"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keyfobsRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxKeyfobs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Keyfob",
          "kind": "LinkedField",
          "name": "keyfobs",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isNew",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Keyfob",
          "kind": "LinkedField",
          "name": "newKeyfob",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseKeyfobNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseUserList_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSystemOptionsSystemType_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyfobSerialNumberField_SerialNumberList_panel"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Keyfob",
          "kind": "LinkedField",
          "name": "copiedKeyfob",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "a1d3462613a62f2f710e34ed178b9cfd";

export default node;
