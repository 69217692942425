/**
 * @generated SignedSource<<a9059a1039a707a3be50d24cbb159592>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2BackupField_communication$data = {
  readonly receiver2Backup: boolean;
  readonly " $fragmentType": "CommunicationReceiver2BackupField_communication";
};
export type CommunicationReceiver2BackupField_communication$key = {
  readonly " $data"?: CommunicationReceiver2BackupField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2BackupField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2BackupField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2Backup",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "b029896b8331f959d0b6d15bcaa16472";

export default node;
