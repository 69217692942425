export const listOfSiteCodesToObject = (
  siteCodes: readonly (number | null)[]
) => {
  const [
    siteCode1,
    siteCode2,
    siteCode3,
    siteCode4,
    siteCode5,
    siteCode6,
    siteCode7,
    siteCode8,
  ] = siteCodes;
  return {
    siteCode1,
    siteCode2,
    siteCode3,
    siteCode4,
    siteCode5,
    siteCode6,
    siteCode7,
    siteCode8,
  };
};
