import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import { OutputOptionsCutoffOutputsField_outputOptions$key } from "./__generated__/OutputOptionsCutoffOutputsField_outputOptions.graphql";

export const outputOptionsCutoffOutputsFieldId = () =>
  "output-options-cutoff-outputs";

function OutputOptionsCutoffOutputsField() {
  const [{ cutoffOutputs }, updateOutputOptions] =
    useOutputOptionsFragment<OutputOptionsCutoffOutputsField_outputOptions$key>(
      graphql`
        fragment OutputOptionsCutoffOutputsField_outputOptions on OutputOptions {
          cutoffOutputs
        }
      `
    );

  const fieldId = outputOptionsCutoffOutputsFieldId();
  const { isTakeoverPanel, isXr, isCellComEx, isXf, isTMSentry } =
    resolvePanelType(useHardwareModel());

  const pattern = isCellComEx
    ? "[1]{0,1}"
    : isTakeoverPanel || isTMSentry
    ? "([1-2]([\\,\\-][1-2]){0,1}){0,1}"
    : isXr
    ? "([1-6]([\\,\\-][1-6]){0,5}){0,1}"
    : isXf
    ? "([1-6]([\\,\\-][1-6]){0,5}){0,1}"
    : "([1-4]([\\,\\-][1-4]){0,3}){0,1}";
  const inlineHelp = isCellComEx
    ? "1"
    : isTakeoverPanel || isTMSentry
    ? "1,2"
    : isXr
    ? "1,2,3,4,5,6"
    : isXf
    ? "1,2,3,4,5,6"
    : "1,2,3,4";
  const validationMessage = isCellComEx
    ? "Valid values are 1."
    : isTakeoverPanel || isTMSentry
    ? "Valid values are 1, 2."
    : isXr
    ? "Valid values are any combination of 1,2,3,4,5,6."
    : isXf
    ? "Valid values are any combination of 1,2,3,4,5,6."
    : "Valid values are 1, 2, 3, 4.";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Cutoff Outputs"
      tooltip="Configure which wired outputs apply the Output Cutoff Time."
    >
      <TextInput
        id={fieldId}
        value={cutoffOutputs}
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) => {
          updateOutputOptions((recordProxy) => {
            recordProxy.setValue(target.value, "cutoffOutputs");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsCutoffOutputsField;
