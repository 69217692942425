/**
 * @generated SignedSource<<a9100cebefa8b69507b102f2879a9e75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsContext_remoteOptions$data = {
  readonly id: string;
  readonly " $fragmentType": "RemoteOptionsContext_remoteOptions";
};
export type RemoteOptionsContext_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsContext_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsContext_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsContext_remoteOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "6df81a55835c0e77aaeca5a6bff0d75b";

export default node;
