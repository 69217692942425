/**
 * @generated SignedSource<<a4f2260cec65d51261d7eca4bff33ae0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsKeypadInputField_controlSystem$data = {
  readonly isDSC: boolean;
  readonly isECP: boolean;
  readonly " $fragmentType": "SystemOptionsKeypadInputField_controlSystem";
};
export type SystemOptionsKeypadInputField_controlSystem$key = {
  readonly " $data"?: SystemOptionsKeypadInputField_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsKeypadInputField_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsKeypadInputField_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isECP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDSC",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "69746464cae3f877d97a6da47ad7d572";

export default node;
