/**
 * @generated SignedSource<<78f245f8bbe5da8ac213c3bd188b2682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsTimeChangeField_systemOptions$data = {
  readonly timeChange: boolean;
  readonly " $fragmentType": "SystemOptionsTimeChangeField_systemOptions";
};
export type SystemOptionsTimeChangeField_systemOptions$key = {
  readonly " $data"?: SystemOptionsTimeChangeField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsTimeChangeField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsTimeChangeField_systemOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeChange",
      "storageKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "7848de443a543f8252bf04379c95d13e";

export default node;
