App.factory("PanelDefsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/PanelDefs/:route",
      {},
      {
        get: {
          method: "GET",
          params: {
            hardware_model: "@hardware_model",
            firmware_version: "firmware_version",
          },
        },
        highestVersion: {
          method: "GET",
          params: {
            hardware_model: "@hardware_model",
            route: "HighestVersion",
            international: "@international",
          },
        },
        options: { method: "GET", params: { route: "Options" } },
      }
    );
  },
]);
