App.factory("PanelProgArchiveJobsApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.jobsApiUrl +
        "/api/v1/PanelProgArchive/Panels/:panelId/:concept/:conceptId/:action",
      {},
      {
        qualifies: { method: "GET", params: { concept: "Qualifies" } },
        createBackup: {
          method: "POST",
          params: { concept: "Backups", action: "Create" },
        },
        deleteBackups: { method: "DELETE", params: { concept: "Backups" } },
        setProtection: {
          method: "POST",
          params: { concept: "Backups", action: "SetProtection" },
        },
        // Route exists on API but is not used
        // deleteBackup: { method: "DELETE", params: { concept: "Backups" } },
        getSchedule: { method: "GET", params: { concept: "Schedule" } },
        deleteSchedule: { method: "DELETE", params: { concept: "Schedule" } },
        finalizeRestoral: {
          method: "POST",
          params: { concept: "Backups", action: "FinalizeRestoral" },
        },
      }
    );
  },
]);

App.factory("PanelProgArchiveODataApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/PanelProgBackups(:id)",
      {},
      {
        getBackup: { method: "GET" },
      }
    );
  },
]);
