import { App } from "app-module";
import graphql from "babel-plugin-relay/macro";
import { GenericPageFallback } from "components/GenericPageFallback";
import RedirectTo404 from "components/RedirectTo404";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React, { useMemo } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { react2angular } from "react2angular";
import {
  DownloadableFileType,
  idAsString,
  toDealerId,
} from "securecom-graphql/client";
import { DownloadsTabset } from "./Tabset";
import UploadFileModal from "./UploadFileModal";
import { DownloadsQuery } from "./__generated__/DownloadsQuery.graphql";

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback: (error: any) => React.ReactNode }>,
  { error: any }
> {
  state = { error: null };
  componentDidCatch(error: any) {
    this.setState({ error });
  }
  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;
    if (error) {
      return fallback(this.state);
    }
    return children;
  }
}

const Downloads: React.FC<
  React.PropsWithChildren<{
    authToken: string;
    dealerId: string;
  }>
> = ({ authToken, dealerId }) => {
  const data = useLazyLoadQuery<DownloadsQuery>(
    graphql`
      query DownloadsQuery {
        myPerson {
          appUser {
            ... on SupervisorUser {
              canUploadFiles
            }
          }
        }
      }
    `,
    {
      id: idAsString(toDealerId(dealerId)),
      downloadableFileTypes: [DownloadableFileType.REMOTE_LINK],
    }
  );
  const isWhitelisted = data.myPerson?.appUser?.canUploadFiles;
  const [uploadModalOpen, setUploadModalOpen] = React.useState(false);

  function openUploadModal() {
    setUploadModalOpen(true);
  }

  return (
    <>
      <div className="row">
        <div className="page-header">
          <div className="page-header__left">
            <div className="page-header__title">Downloads</div>
          </div>
          <div className="page-header__right">
            {isWhitelisted && (
              <button
                className="btn btn-primary btn-sm btn-dmp"
                onClick={() => {
                  openUploadModal();
                }}
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <DownloadsTabset
          dealerId={dealerId}
          authToken={authToken}
          isWhitelisted={!!isWhitelisted}
        />
      </div>

      {uploadModalOpen && (
        <UploadFileModal
          authToken={authToken}
          closeModal={() => setUploadModalOpen(false)}
          dealerId={dealerId}
          isWhitelisted={!!isWhitelisted}
        />
      )}
    </>
  );
};

export const AngularEntryPoint: React.FC<React.PropsWithChildren<any>> = ({
  RelayService,
  UserService,
  $state,
  $rootScope,
}) => {
  const environment = useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <ErrorBoundary fallback={() => <RedirectTo404 />}>
        <React.Suspense fallback={<GenericPageFallback />}>
          <AlertsContextProvider $rootScope={$rootScope}>
            <Downloads
              dealerId={$state.params.dealer_id}
              authToken={UserService.auth_token}
            />
          </AlertsContextProvider>
        </React.Suspense>
      </ErrorBoundary>
    </RelayEnvironmentProvider>
  );
};

export function dangerouslyAddToApp() {
  App.component(
    "downloads",
    react2angular(
      AngularEntryPoint,
      [],
      ["RelayService", "UserService", "$state", "$rootScope"]
    )
  );
}
