/**
 * @name App.directive:daInfoAlert
 * @desc Creates a popup to present the message
 * @property message: string - the message to present.
 *
 * @example '<da-info-alert message="Hello World!"></da-info-alert>
 */

App.directive("daInfoAlert", daInfoAlert);

function daInfoAlert() {
  return {
    restrict: "E",

    scope: {
      message: "@",
    },

    template:
      '<alert class="animated fade-in" type="info">' +
      '<i class="icon-radial_info" style="font-size: 2.0em; color: #fff; vertical-align:center;">&nbsp;</i>' +
      "{{message}}" +
      "</alert>",
  };
}
