import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsSendStoredMessageField_systemReports$key } from "./__generated__/SystemReportsSendStoredMessageField_systemReports.graphql";

export const systemReportsSendStoredMessageFieldId = () =>
  "system-reports-send-stored-message";

function SystemReportsSendStoredMessageField() {
  const [{ sendStoredMessage }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsSendStoredMessageField_systemReports$key>(
      graphql`
        fragment SystemReportsSendStoredMessageField_systemReports on SystemReports {
          sendStoredMessage
        }
      `
    );

  const fieldId = systemReportsSendStoredMessageFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Send Stored Messages"
      tooltip="When a communication failure restores, the System will send messages that were stored during the failure. Prepends the 'Minutes Ago' field to Serial 3 messages to the receiver."
    >
      <Switch
        label="Send Stored Messages"
        id={fieldId}
        name={fieldId}
        checked={sendStoredMessage}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(!sendStoredMessage, "sendStoredMessage");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsSendStoredMessageField;
