/**
 * @generated SignedSource<<102535405d43a7c3f4c43cb6a34e352b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DeviceInformation1100TWirelessFrequencyType = "DSC" | "HONEYWELL" | "INTERLOGIX" | "NONE" | "_2GIG";
export type DeviceInformationWirelessSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly axNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly door: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lxNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly network: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly supervisionTime: {
      readonly data: DeviceInformationWirelessSupervisionTime | null;
      readonly included: boolean | null;
    } | null;
    readonly wireless: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessTranslator1100t: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly wirelessTranslator1100tFrequency: {
      readonly data: DeviceInformation1100TWirelessFrequencyType | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts";
};
export type XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "4515ba9af59e5c914e1e665fd5018d21";

export default node;
