import * as React from "react";

const OriginalDealerContext = React.createContext<any>({});

export function OriginalDealerContextProvider(props: {
  dealer: any;
  children: React.ReactNode;
}) {
  return (
    <OriginalDealerContext.Provider value={props.dealer}>
      {props.children}
    </OriginalDealerContext.Provider>
  );
}

export const useOriginalDealer = () => React.useContext(OriginalDealerContext);
