/**
 * @generated SignedSource<<c121c6fd36a817411ceee1d8db1e091a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeyfobSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type KeyfobSupervisionTimeField_keyfob$data = {
  readonly keyfobSupervisionTime: KeyfobSupervisionTime;
  readonly number: number;
  readonly " $fragmentType": "KeyfobSupervisionTimeField_keyfob";
};
export type KeyfobSupervisionTimeField_keyfob$key = {
  readonly " $data"?: KeyfobSupervisionTimeField_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobSupervisionTimeField_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobSupervisionTimeField_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyfobSupervisionTime",
      "storageKey": null
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "b9a649b7e352c1622fcd85fbe8e5bb75";

export default node;
