/**
 * @ngdoc directive
 * @name App.directive:zeroPad
 *
 * @description
 *   Pads an input field value with zeros, to the length specified in the zlength attribute
 *
 */
App.directive("daOutputPad", function ($filter) {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, elem, attrs, ctrl) {
      var zlength = attrs.zlength;
      if (!zlength) return;

      //view -> model
      ctrl.$parsers.unshift(function (value) {
        value = value.toUpperCase();
        if (value.length < 3 && zlength == 3) {
          var firstChar = value.charAt(0);
          var letters = /^[a-z]+$/i;
          if (firstChar.match(letters)) {
            //Put a zero in the middle
            return [value.slice(0, 1), "0", value.slice(1)].join("");
          } else {
            return value ? $filter("zpad")(value, zlength) : "";
          }
        } else {
          var paddedValue = $filter("zpad")(value, zlength);
          return paddedValue;
        }
      });

      ctrl.$validators.validOutput = function (value) {
        return true;
      };

      elem.bind("blur", function () {
        var myval = elem.val();
        zlength = attrs.zlength;
        if (myval.length < 3 && zlength == 3) {
          var firstChar = myval.charAt(0);
          var letters = /^[a-z]+$/i;
          if (firstChar.match(letters)) {
            //Put a zero in the middle
            ctrl.$viewValue = [myval.slice(0, 1), "0", myval.slice(1)]
              .join("")
              .toUpperCase();
          } else {
            ctrl.$viewValue = $filter("zpad")(myval, zlength).toUpperCase();
          }
        } else {
          ctrl.$viewValue = $filter("zpad")(elem.val(), zlength);
        }
        ctrl.$render();
      });
    },
  };
});
