import graphql from "babel-plugin-relay/macro";
import { useResetControlSystemIntegrations } from "components/StatusBadge";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { useFragment, useMutation } from "react-relay";
import ReceiverFormModal from "./ReceiverFormModal";
import { ReceiverFormState, reducer } from "./ReceiverFormReducer";
import { EditReceiverFormEditReceiverMutation } from "./__generated__/EditReceiverFormEditReceiverMutation.graphql";
import {
  EditReceiverFormReceiver_receiver$data,
  EditReceiverFormReceiver_receiver$key,
} from "./__generated__/EditReceiverFormReceiver_receiver.graphql";

const initState = (
  receiver: EditReceiverFormReceiver_receiver$data
): ReceiverFormState => ({
  id: receiver.id ?? -1,
  automationPrefix: receiver.automationPrefix ?? "",
  number: receiver.number ?? 1,
  ipAddress: receiver.ip ?? "",
  port: receiver.port ?? 1,
  description: receiver.description ?? "",
  centralStationAutomationIntegrationId:
    receiver.centralStationAutomationIntegration?.id ?? null,
  automationPrefixBlurred: false,
  numberBlurred: false,
  ipAddressBlurred: false,
  portBlurred: false,
  cancelButtonClicked: false,
});

const editReceiverMutation = graphql`
  mutation EditReceiverFormEditReceiverMutation($receiver: EditReceiverInput!) {
    editReceiver(receiver: $receiver) {
      ... on EditReceiverErrorPayload {
        __typename
        type
      }
      ... on EditReceiverSuccessPayload {
        __typename
        dealer {
          id
          receivers(sort: { keys: ["number"], order: ASC }) {
            nodes {
              ...ReceiverTableReceiver_receiver
            }
          }
          uninitializedReceiverNumbers
        }
      }
    }
  }
`;

export const EditReceiverForm: React.FC<
  React.PropsWithChildren<{
    receiverRef: EditReceiverFormReceiver_receiver$key;
    onCancel: () => void;
  }>
> = ({ receiverRef, onCancel }) => {
  const receiver = useFragment(
    graphql`
      fragment EditReceiverFormReceiver_receiver on Receiver {
        id
        automationPrefix
        number
        ip
        port
        description
        centralStationAutomationIntegration {
          id
        }
        dealer {
          id
          ...ReceiverFormModalDealer_dealer
        }
      }
    `,
    receiverRef
  );
  const [editReceiver, isUpdatingReceiver] =
    useMutation<EditReceiverFormEditReceiverMutation>(editReceiverMutation);
  const [state, dispatch] = React.useReducer(reducer, receiver, initState);
  const showAlert = useShowAlert();

  const resetControlSystemIntegrations = useResetControlSystemIntegrations();

  return receiver.dealer && receiver.dealer.id ? (
    <ReceiverFormModal
      state={state}
      dealerRef={receiver.dealer}
      dispatch={dispatch}
      onCancel={onCancel}
      isSaving={() => isUpdatingReceiver}
      onSave={() => {
        if (state.ipAddress && state.port && state.number) {
          editReceiver({
            variables: {
              receiver: {
                receiverId: receiver.id,
                ip: state.ipAddress,
                port: state.port,
                automationPrefix: state.automationPrefix,
                number: state.number,
                description: state.description,
                centralStationAutomationIntegrationId:
                  state.centralStationAutomationIntegrationId,
              },
            },
            onCompleted: (response) => {
              if (
                response.editReceiver.__typename ===
                "EditReceiverSuccessPayload"
              ) {
                showAlert({
                  type: "success",
                  text: "Updated receiver",
                });
                onCancel();
                resetControlSystemIntegrations();
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to update receiver",
                });
              }
            },
          });
        }
      }}
    />
  ) : null;
};
export default EditReceiverForm;
