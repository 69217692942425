/**
 * @generated SignedSource<<883c42d5c451459097d04575bd195385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75SystemReportsProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly softwareVersion: string;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly systemReports: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemReportsAbortReportsField_systemReports" | "SystemReportsAmbushReportsField_systemReports" | "SystemReportsBypassReportsField_systemReports" | "SystemReportsCodeChangeReportsField_systemReports" | "SystemReportsContext_systemReports" | "SystemReportsEarlyToCloseField_systemReports" | "SystemReportsLateToOpenField_systemReports" | "SystemReportsScheduleChangeReportsField_systemReports" | "SystemReportsVideoReportsField_systemReports" | "SystemReportsZoneRestoralReportsField_systemReports">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XT75SystemReportsProgrammingConceptForm_controlSystem";
};
export type XT75SystemReportsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75SystemReportsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75SystemReportsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75SystemReportsProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SystemReports",
          "kind": "LinkedField",
          "name": "systemReports",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsContext_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsAbortReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsBypassReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsScheduleChangeReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsCodeChangeReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsAmbushReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsVideoReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsZoneRestoralReportsField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsLateToOpenField_systemReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemReportsEarlyToCloseField_systemReports"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "df9c430e6aee176a46031491195f9d82";

export default node;
