import styled, { css } from "styled-components";
import { media } from "../../../utils/styles";

export const AreaButtonIcon = styled.span<{
  burgAlarm: boolean;
  armed: boolean;
}>`
  display: block;
  margin: 0 10px 5px 0;
  color: ${({ burgAlarm, theme }) =>
    burgAlarm ? theme.danger : theme.primary};
  font-size: 2.8em;
  line-height: 0;

  ${({ armed }) =>
    armed &&
    css`
      margin-right: 0;
      margin-bottom: 20px;
    `}
  ${media.medium`
    margin: 0 0 10px;
    font-size: 3.2em;
  `};
`;
