/**
 * @ngdoc service
 * @name App.factory:VideoChannelService
 *
 * @description
 * API factory for Video Devices
 *
 */

App.factory("VideoChannelV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/video_channels/:video_channel_id/:function",
      {},
      {
        getStreamingDetails: {
          method: "GET",
          params: {
            video_channel_id: "@video_channel_id",
            function: "streaming_details",
          },
        },
      }
    );
  },
]);
