import React, { useState } from "react";
import ExistingSite from "./ExistingSite";
import NewSite from "./NewSite";

export default function FormEntry({
  initialSiteId,
  setSiteName,
}: {
  initialSiteId?: string;
  setSiteName: () => void;
}) {
  const [id, setSiteId] = useState(initialSiteId);

  return id ? (
    <ExistingSite
      id={id}
      isNewlyCreated={initialSiteId !== id}
      setSiteName={setSiteName}
    />
  ) : (
    <NewSite setSiteId={setSiteId} />
  );
}
