import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { SendLocalChangesType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsRemotePhoneNumberField_remoteOptions$key } from "./__generated__/RemoteOptionsRemotePhoneNumberField_remoteOptions.graphql";

export const remoteOptionsRemotePhoneNumberFieldId = () =>
  "remote-options-remote-phone-number";

function RemoteOptionsRemotePhoneNumberField() {
  const [{ remoteChangesPhoneNumber, sendLocalChanges }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsRemotePhoneNumberField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsRemotePhoneNumberField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            remoteChangesPhoneNumber
            sendLocalChanges
          }
        }
      `
    );

  const fieldId = remoteOptionsRemotePhoneNumberFieldId();
  const disabled = sendLocalChanges !== SendLocalChangesType.DIGITAL_DIALER;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Remote Phone Number"
      tooltip="Phone number for programming change recipient."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={remoteChangesPhoneNumber || ""}
        maxLength={32}
        disabled={disabled}
        pattern="([*,#,D,P,0-9]*( )*)"
        validationMessage="Valid digits are P, D, *, #, and 0-9."
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "remoteChangesPhoneNumber")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsRemotePhoneNumberField;
