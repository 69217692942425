/**
 * @ngdoc service
 * @name App.factory:PanelCapabilities
 *
 * @description
 * Service for determining the capabilities of a panel
 *
 */

App.factory("PanelCapabilitiesService", [
  "ControlSystemsService",
  "PanelProgrammingService",
  "SevenInchKeypadService",
  "$q",
  function (
    ControlSystemsService,
    PanelProgrammingService,
    SevenInchKeypadService,
    $q
  ) {
    return {
      canSendCodeToLocks: canSendCodeToLocks,
      canSendAmbushNotifications: canSendAmbushNotifications,
      getNumWirelessZones: getNumWirelessZones,
      canShowWirelessDoorTypes: canShowWirelessDoorTypes,
      userCodeSupportsTempOption: userCodeSupportsTempOption,
      supports16DoorSchedules: supports16DoorSchedules,
      canHaveWirelessDoors: canHaveWirelessDoors,
      supportsHourlyData: supportsHourlyData,
      supports1168: supports1168,
      supportsVplex: supportsVplex,
      supportsZoneExpander: supportsZoneExpander,
      isCellComECP: isCellComECP,
      supportsECPZones: supportsECPZones,
      supportsNetworkKeypad: supportsNetworkKeypad,
      isCellComDSC: isCellComDSC,
      supportsDSCZones: supportsDSCZones,
      supportsVideoAnalytics: supportsVideoAnalytics,
      supportsRemotePanic: supportsRemotePanic,
      supportsPirWalkTest: supportsPirWalkTest,
      supportsXTDoors: supportsXTDoors,
      supportsPrivateDoors: supportsPrivateDoors,
      supportsNetworkDevice: supportsNetworkDevice,
      isXTLPlus: isXTLPlus,
      supports1100T: supports1100T,
      supportsSevenInchKeypad: supportsSevenInchKeypad,
      getUserCodeMetaData: getUserCodeMetaData,
    };

    /**
     * Determines if the panel has the capability to send the requested user code to locks
     */
    function canSendCodeToLocks(panel, userCode) {
      if (
        !panel ||
        !DoesNestedPropertyExist(
          panel,
          "capabilities.send_codes_to_locks.user_number_range"
        ) ||
        !userCode.hasOwnProperty("number") ||
        panel.capabilities.send_codes_to_locks.user_number_range === null
      ) {
        return false;
      }
      var minUserNumber =
        panel.capabilities.send_codes_to_locks.user_number_range.min;
      var maxUserNumber =
        panel.capabilities.send_codes_to_locks.user_number_range.max;
      var locksEnabled =
        ControlSystemsService.currentControlSystem.services_manager
          .lock_control_enabled;
      var userNumber = parseInt(userCode.number);

      return (
        panel.capabilities.send_codes_to_locks.capable == true &&
        locksEnabled &&
        userNumber >= minUserNumber &&
        userNumber <= maxUserNumber
      );
    }

    /**

     * Gets the user code meta data including max and min digits, regex pattern, placeholder, and validation message

     * @panel The Panel that contains PDS and capabilities (note the capital)
     * @returns {min: int, max: int, pattern: string, placeholder: string, validation_message: string }

     */

    function getUserCodeMetaData(panel) {
      if (
        !panel ||
        !DoesNestedPropertyExist(panel, "capabilities.user_code_digits")
      ) {
        return {};
      }
      const { min, max } = panel.capabilities.user_code_digits;

      const pattern = `^(?!0+$)\\d{${min ?? 4},${max ?? 4}}$`;
      const placeholder = "*".repeat(max ?? 4);
      const validation_message = `Valid values are ${"0".repeat(
        (min ?? 4) - 1
      )}1-${"9".repeat(max ?? 4)}.`;

      return {
        min,
        max,
        pattern,
        placeholder,
        validation_message,
      };
    }

    function canSendAmbushNotifications(panel) {
      return (
        panel.hardware_model === "XR550" &&
        ((191 <= panel.software_version && panel.software_version <= 599) ||
          691 <= panel.software_version)
      );
    }

    function canShowWirelessDoorTypes(panel) {
      return (
        panel.hardware_family === "XR550" &&
        ((191 <= panel.software_version && panel.software_version <= 599) ||
          691 <= panel.software_version)
      );
    }

    function userCodeSupportsTempOption(panel) {
      return (
        panel.hardware_family === "XR550" &&
        ((191 <= panel.software_version && panel.software_version <= 599) ||
          691 <= panel.software_version)
      );
    }

    function supports16DoorSchedules(panel) {
      return (
        panel.hardware_family === "XR550" &&
        ((191 <= panel.software_version && panel.software_version <= 599) ||
          691 <= panel.software_version)
      );
    }

    function canHaveWirelessDoors(panel) {
      return (
        panel.hardware_family === "XR550" &&
        ((191 <= panel.software_version && panel.software_version <= 599) ||
          691 <= panel.software_version)
      );
    }

    function supportsHourlyData(panel) {
      return (
        (191 <= panel.software_version && panel.software_version <= 599) ||
        691 <= panel.software_version
      );
    }

    function supports1168(panel) {
      return (
        (192 <= panel.software_version && panel.software_version <= 599) ||
        692 <= panel.software_version
      );
    }

    function supportsVplex(panel) {
      return (
        panel.hardware_family === "XR550" &&
        (between(panel.software_version, 191, 599) ||
          between(panel.software_version, 691, Infinity))
      );
    }

    function supportsZoneExpander(panel) {
      return (
        panel.hardware_family === "XR550" &&
        (between(panel.software_version, 193, 599) ||
          between(panel.software_version, 693, Infinity))
      );
    }

    function supportsECPZones(hardwareModel, softwareVersion) {
      return (
        ["CellComSL", "CellComEX", "iComSL", "DualCom"].includes(
          hardwareModel
        ) &&
        (between(softwareVersion, 193, 599) ||
          between(softwareVersion, 693, Infinity))
      );
    }

    function supportsVideoAnalytics(panel) {
      return (
        panel.hardware_family === "XR550" &&
        (between(panel.software_version, 193, 599) ||
          between(panel.software_version, 693, Infinity))
      );
    }

    function supportsNetworkKeypad(panel) {
      return (
        panel.hardware_family === "XR550" &&
        (between(panel.software_version, 201, 599) ||
          between(panel.software_version, 701, Infinity))
      );
    }

    function supportsDSCZones(hardwareModel, softwareVersion) {
      return (
        ["CellComSL", "CellComEX", "iComSL", "DualCom"].includes(
          hardwareModel
        ) &&
        (between(softwareVersion, 201, 599) ||
          between(softwareVersion, 701, Infinity))
      );
    }

    function supportsRemotePanic(panel) {
      return (
        (panel.software_version >= 193 && panel.software_version <= 600) ||
        panel.software_version >= 692
      );
    }

    function supportsPirWalkTest(panel) {
      var deferred = $q.defer();
      if (
        panel.id != null &&
        (panel.hardware_family === "XR550" ||
          panel.hardware_family === "XT30") &&
        (between(panel.software_version, 202, 599) ||
          between(panel.software_version, 702, Infinity))
      ) {
        PanelProgrammingService.get(
          "system_options",
          true,
          PanelProgrammingService.getSessionKey()
        ).then(
          function (data) {
            deferred.resolve(Number(data.system_options.house_code) > 0);
          },
          function (nope) {
            deferred.reject(nope);
          }
        );
        return deferred.promise;
      }
      deferred.resolve(false);
      return deferred.promise;
    }

    function supportsXTDoors(panel) {
      return (
        panel.hardware_family === "XT30" &&
        (between(panel.software_version, 202, 599) ||
          between(panel.software_version, 702, Infinity))
      );
    }

    function supportsPrivateDoors(panel) {
      return (
        panel.hardware_model !== "XT75" &&
        panel.hardware_family === "XR550" &&
        (between(panel.software_version, 202, 599) ||
          between(panel.software_version, 702, Infinity))
      );
    }

    function supportsNetworkDevice(panel) {
      return (
        panel.hardware_family === "XT30" &&
        (between(panel.software_version, 202, 599) ||
          between(panel.software_version, 702, Infinity))
      );
    }

    function isXTLPlus(panel) {
      return panel.hardware_model === "XTLP";
    }

    function supports1100T(panel) {
      return (
        (panel.hardware_family === "XR550" ||
          panel.hardware_family === "XT30") &&
        between(panel.software_version, 211, 599)
      );
    }

    function supportsSevenInchKeypad(panel) {
      return (
        (panel.hardware_family === "XR550" ||
          panel.hardware_family === "XT30") &&
        (between(panel.software_version, 221, 599) ||
          between(panel.software_version, 721, Infinity))
      );
    }

    /**
     * This is not a perfect situation, but because we aren't guaranteed to have the system_options.kpad_input attribute, particularly during the
     * early phases of system creation, we need something else to tell us if this is a CellCom ECP panel
     * @param {panel} panel a Panel object, returned from SCAPI, with the attached sensor_activity_zones array.
     */
    function isCellComECP(panel) {
      if (
        ["CellComSL", "CellComEX", "iComSL", "DualCom"].includes(
          panel.hardware_model
        )
      ) {
        let zone5 = panel.sensor_activity_zones.filter(
          (zone) => zone.number == 5
        );
        if (zone5.length > 0) return true;
        else return false;
      } else {
        return false;
      }
    }

    // DSC Panels have 'hidden' zones, so we are unable to tell DSC from DMP, so we are going strictly off kpad_input
    function isCellComDSC(panel) {
      let deferred = $q.defer();
      if (panel.id != null) {
        PanelProgrammingService.get(
          "system_options",
          "",
          PanelProgrammingService.getSessionKey()
        ).then(
          function (data) {
            let kpad_input = data.system_options.kpad_input;
            if (
              ["CellComSL", "CellComEX", "iComSL", "DualCom"].includes(
                panel.hardware_model
              ) &&
              kpad_input &&
              kpad_input === "D"
            ) {
              deferred.resolve(true);
            } else {
              deferred.resolve(false);
            }
          },
          function (nope) {
            deferred.reject(nope);
          }
        );
        return deferred.promise;
      }
    }

    function getNumWirelessZones(panel) {
      // var wirelessDevices = 0;
      // for(var i =0; i < panel.device_informations.length; i++){
      //     if(panel.device_informations[i].comm_type === 'W'){
      //       wirelessDevices++;
      //     }
      //   }
    }
  },
]);
