/**
 * @generated SignedSource<<8b6daaa385597c2be5e5573ef537e10c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HolidayDateClass = "A" | "B" | "C";
export type UpdateGlobalHolidayDateForMassPanelsInput = {
  clazz: HolidayDateClass;
  date: string;
  dealerId: number;
  description: string;
  name: string;
  panelAssociation: ReadonlyArray<number | null>;
  scapiId: number;
};
export type GlobalHolidayDatesModalFormUpdateMutation$variables = {
  input: UpdateGlobalHolidayDateForMassPanelsInput;
};
export type GlobalHolidayDatesModalFormUpdateMutation$data = {
  readonly updateGlobalHolidayDateForMassPanels: {
    readonly dealer?: {
      readonly globalHolidayDates: ReadonlyArray<{
        readonly clazz: HolidayDateClass;
        readonly date: string;
        readonly description: string;
        readonly id: string;
        readonly name: string;
        readonly scapiId: number;
      }>;
    } | null;
    readonly errorMessage?: string;
  };
};
export type GlobalHolidayDatesModalFormUpdateMutation = {
  response: GlobalHolidayDatesModalFormUpdateMutation$data;
  variables: GlobalHolidayDatesModalFormUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "GlobalHolidayDate",
  "kind": "LinkedField",
  "name": "globalHolidayDates",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scapiId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clazz",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    }
  ],
  "type": "UpdateGlobalHolidayDateForMassPanelsErrorResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalHolidayDatesModalFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateGlobalHolidayDateForMassPanels",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateGlobalHolidayDateForMassPanelsSuccessResponse",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalHolidayDatesModalFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateGlobalHolidayDateForMassPanels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateGlobalHolidayDateForMassPanelsSuccessResponse",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "444294b4b2961cbf52b0b1b50289b8bd",
    "id": null,
    "metadata": {},
    "name": "GlobalHolidayDatesModalFormUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation GlobalHolidayDatesModalFormUpdateMutation(\n  $input: UpdateGlobalHolidayDateForMassPanelsInput!\n) {\n  updateGlobalHolidayDateForMassPanels(input: $input) {\n    __typename\n    ... on UpdateGlobalHolidayDateForMassPanelsSuccessResponse {\n      dealer {\n        globalHolidayDates {\n          name\n          date\n          description\n          id\n          scapiId\n          clazz\n        }\n        id\n      }\n    }\n    ... on UpdateGlobalHolidayDateForMassPanelsErrorResponse {\n      errorMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de6bb2bc95968812ba330b835cf010fb";

export default node;
