import DaColors from "app/common/DaColors";
import {
  EventSource,
  SecurityEvent,
} from "dealer-admin/src/apis/odata/security-events-parser";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Event from "./Event";
import TabGroup, { TabData } from "./TabGroup";
import ToggleButton from "./ToggleButton";

const Wrapper = styled.div`
  width: 34rem;
`;
const TabContent = styled.div`
  position: relative;
  background-color: ${DaColors.White};
`;
const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  border-left: 0.1rem solid ${DaColors.Neutral25};
  border-right: 0.1rem solid ${DaColors.Neutral25};
  background-color: ${DaColors.White};
  padding: 0.8rem;
`;
const FilterWrapper = styled.div`
  position: relative;
  width: max-content;
`;
const AlarmCountBadge = styled.div`
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  border-radius: 0.4rem;
  background-color: ${DaColors.Info1000};
  padding: 0 0.4rem;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${DaColors.White};
`;
const EventsUnavailable = styled.div`
  text-align: center;
`;
const EventsWrapper = styled.div`
  max-height: 82vh;
  overflow-y: auto;
`;
const DateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${DaColors.Primary500};
  padding: 0.4rem 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bold;
  color: ${DaColors.White};
`;

type EventListProps = {
  events: SecurityEvent[];
  selectedEventId?: number;
  viewedEventIds: number[];
  isNetworkError?: boolean;
  onSelectEvent: (selectedEvent: SecurityEvent) => void;
  hasLegacyClips: boolean;
  eventSource: EventSource;
  onSelectEventSource: (eventSource: EventSource) => void;
};

const EventList = ({
  events,
  selectedEventId,
  viewedEventIds,
  isNetworkError,
  onSelectEvent,
  hasLegacyClips,
  eventSource,
  onSelectEventSource,
}: EventListProps) => {
  const [isFilterToggledOn, setIsFilterToggledOn] = useState<boolean>(false);
  let groupDateString: string | null = null;
  const alarmEvents: SecurityEvent[] = events.filter((e) => e.isAlarm);
  const displayedEvents = isFilterToggledOn ? alarmEvents : events;

  const tabData: TabData[] = [
    {
      displayName: "Events",
      iconName: "history",
      title: "System and Video Analytic Events",
      value: EventSource.Events,
    },
  ];
  if (hasLegacyClips) {
    tabData.push({
      displayName: "Clips",
      iconName: "clips_with_play",
      title: "Motion-Activated Recordings",
      value: EventSource.Clips,
    });
  }

  return (
    <Wrapper>
      <TabGroup
        data={tabData}
        activeValue={String(eventSource)}
        onSelectTab={(value) => onSelectEventSource(value as EventSource)}
      />
      <TabContent>
        <Header>
          {alarmEvents.length > 0 && (
            <FilterWrapper>
              <ToggleButton
                offLabel={`All ${eventSource}`}
                onLabel="Alarms"
                isOn={isFilterToggledOn}
                onToggle={setIsFilterToggledOn}
              ></ToggleButton>
              <AlarmCountBadge>{alarmEvents.length.toString()}</AlarmCountBadge>
            </FilterWrapper>
          )}
        </Header>
        {isNetworkError ? (
          <EventsUnavailable>
            Events Unavailable: Please try again later
          </EventsUnavailable>
        ) : (
          <EventsWrapper>
            {[...displayedEvents].map((event) => {
              const isNewDateGroup = event.displayDate !== groupDateString;
              groupDateString = event.displayDate;

              return (
                <Fragment key={event.id}>
                  {isNewDateGroup && (
                    <DateHeader>
                      <div>{groupDateString}</div>
                      <div>
                        {eventSource === EventSource.Events
                          ? "Panel Time"
                          : "Local Time"}
                      </div>
                    </DateHeader>
                  )}
                  <Event
                    event={event}
                    selectedEventId={selectedEventId}
                    viewedEventIds={viewedEventIds}
                    onClick={onSelectEvent}
                  />
                </Fragment>
              );
            })}
          </EventsWrapper>
        )}
      </TabContent>
    </Wrapper>
  );
};

export default EventList;
