/**
 * @generated SignedSource<<9abe984486b5313216c84be0da3fe91f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly outputGroups: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputNumber1: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber3: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber4: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber5: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber6: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber7: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber8: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XROutputGroupsTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "c12d7b45f2e718a5561b2b17c4293ba0";

export default node;
