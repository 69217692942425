/**
 * @generated SignedSource<<87886520a5b2a6efd8f1f598d19df51d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecureComCameras_site$data = {
  readonly billingControlSystemId: string;
  readonly id: string;
  readonly securecomCameras: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SecureComCameras_secureComCamera">;
  }>;
  readonly " $fragmentType": "SecureComCameras_site";
};
export type SecureComCameras_site$key = {
  readonly " $data"?: SecureComCameras_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComCameras_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SecurecomCamerasRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "SecureComCameras_site",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingControlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SecureComCamera",
      "kind": "LinkedField",
      "name": "securecomCameras",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecureComCameras_secureComCamera"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "028447d919f5f433c708a01fdeae197e";

export default node;
