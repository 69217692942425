/**
 *
 * TimeClockIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const TimeClockIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <polygon points="32,17 28,17 28,37 46,37 46,33 32,33     " />
        <path
          d="M54.625,9.481C48.581,3.45,40.546,0.129,31.998,0.129C14.354,0.129,0,14.455,0,32.064S14.354,64,31.998,64
      C49.644,64,64,49.674,64,32.064C64,23.531,60.67,15.511,54.625,9.481z M31.998,60C16.56,60,4,47.468,4,32.064
      S16.56,4.129,31.998,4.129c7.48,0,14.513,2.906,19.802,8.184c5.288,5.274,8.2,12.289,8.2,19.752C60,47.468,47.438,60,31.998,60z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default TimeClockIcon;
