import { Dispatch } from "react";

export type CustomFirmwareUploadFormState = {
  readonly name: string;
  readonly version: string;
  readonly passcode: string;
  readonly file: File | undefined | null;
  readonly showError: boolean;
  readonly errorMessage: string;
  readonly uploadingFile: boolean;
};

type Action =
  | { type: "UPDATE_NAME"; value: string }
  | { type: "UPDATE_VERSION"; value: string }
  | { type: "UPDATE_PASSCODE"; value: string }
  | { type: "UPDATE_FILE"; value: File | undefined | null }
  | { type: "SHOW_ERROR"; value: boolean }
  | { type: "SET_ERROR_MESSAGE"; value: string }
  | { type: "UPLOADING_FILE"; value: boolean };

export type CustomFirmwareUploadFormDispatch = Dispatch<Action>;

export const CustomFirmwareUploadFormReducer = (
  state: CustomFirmwareUploadFormState,
  action: Action
): CustomFirmwareUploadFormState => {
  switch (action.type) {
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.value,
      };
    case "UPDATE_VERSION": {
      return {
        ...state,
        version: action.value,
      };
    }
    case "UPDATE_PASSCODE": {
      return {
        ...state,
        passcode: action.value,
      };
    }
    case "UPDATE_FILE": {
      return {
        ...state,
        file: action.value,
      };
    }
    case "SHOW_ERROR": {
      return {
        ...state,
        showError: action.value,
      };
    }
    case "SET_ERROR_MESSAGE": {
      return {
        ...state,
        errorMessage: action.value,
      };
    }
    case "UPLOADING_FILE": {
      return {
        ...state,
        uploadingFile: action.value,
      };
    }
    default:
      return state;
  }
};
