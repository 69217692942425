import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useMessagingSetupFragment } from "./MessagingSetupContext";
import { MessagingSetupDestination3UserField_messagingSetup$key } from "./__generated__/MessagingSetupDestination3UserField_messagingSetup.graphql";

export const messagingSetupDestination3UserFieldId = () =>
  "messaging-setup-destination-3-user";

function MessagingSetupDestination3UserField() {
  const [
    { userNumberForThirdContact, thirdEmailOrPhoneNumber, enableMessaging },
    updateMessagingSetup,
  ] = useMessagingSetupFragment<MessagingSetupDestination3UserField_messagingSetup$key>(
    graphql`
      fragment MessagingSetupDestination3UserField_messagingSetup on MessagingSetup {
        userNumberForThirdContact
        thirdEmailOrPhoneNumber
        enableMessaging
      }
    `
  );

  const fieldId = messagingSetupDestination3UserFieldId();
  const disabled =
    !enableMessaging ||
    !new RegExp(
      "(0{0,4}[2-9][0-9]{6}|0{0,3}[1-9][0-9]{7}|0{0,2}[1-9][0-9]{8}|0?[1-9][0-9]{9}|1[0-9][0-8][0-9]{8})"
    ).test(thirdEmailOrPhoneNumber);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Destination 3 User"
      disabled={disabled}
      tooltip="User number to associate with messages received from the Destination 3 phone number."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        value={userNumberForThirdContact}
        validationMessage={"Valid values are 0-99."}
        pattern="([0]{0,3}[0-9]|[3-9][0-9])"
        inlineHelp="0-99"
        onChange={({ target }) => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(target.value, "userNumberForThirdContact");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MessagingSetupDestination3UserField;
