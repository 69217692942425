import MassProgrammingGlobalHolidayDatesView from "components/MassProgrammingGlobalHolidayDatesView";
import { react2angular } from "react2angular";

App.component(
  "massProgrammingGlobalHolidayDatesView",
  react2angular(
    MassProgrammingGlobalHolidayDatesView,
    [],
    ["$stateParams", "RelayService", "$state"]
  )
);
