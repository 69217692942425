import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import {
  DeviceInformation1100TWirelessFrequencyType,
  DeviceInformationType,
} from "securecom-graphql/client";
import { useHardwareModel, useHas1100T } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformation1100TWirelessFrequencyField_deviceInformation$key } from "./__generated__/DeviceInformation1100TWirelessFrequencyField_deviceInformation.graphql";

export const deviceInformation1100TWirelessFrequencyFieldId = (
  number: string
) => `device-information-1100t-wireless-frequency-${number}`;

function DeviceInformation1100TWirelessFrequencyField() {
  const [
    {
      number,
      wirelessTranslator1100tFrequency,
      deviceType,
      wirelessTranslator1100t,
    },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformation1100TWirelessFrequencyField_deviceInformation$key>(
    graphql`
      fragment DeviceInformation1100TWirelessFrequencyField_deviceInformation on DeviceInformation {
        id
        number

        ... on XrDeviceInformation {
          deviceType
          wirelessTranslator1100tFrequency
        }
        ... on Xt75DeviceInformation {
          deviceType
          wirelessTranslator1100tFrequency
        }
        ... on XtDeviceInformation {
          wirelessTranslator1100t
          wirelessTranslator1100tFrequency
        }
      }
    `
  );

  const fieldId = deviceInformation1100TWirelessFrequencyFieldId(
    String(number)
  );
  const has1100t = useHas1100T();
  const { isXr, isXt75 } = resolvePanelType(useHardwareModel());

  const disabledByDeviceType = isXr || isXt75;

  const disabled = disabledByDeviceType
    ? (has1100t && deviceType !== DeviceInformationType._1100T) || !has1100t
    : !wirelessTranslator1100t;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="1100T Wireless Frequency"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={
          wirelessTranslator1100tFrequency as DeviceInformation1100TWirelessFrequencyType
        }
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              target.value as DeviceInformation1100TWirelessFrequencyType,
              "wirelessTranslator1100tFrequency"
            );
          });
        }}
      >
        <Select.Option
          value={DeviceInformation1100TWirelessFrequencyType.HONEYWELL}
        >
          HONEYWELL
        </Select.Option>
        <Select.Option
          value={DeviceInformation1100TWirelessFrequencyType._2GIG}
        >
          2GIG
        </Select.Option>
        <Select.Option
          value={DeviceInformation1100TWirelessFrequencyType.INTERLOGIX}
        >
          INTERLOGIX
        </Select.Option>
        <Select.Option value={DeviceInformation1100TWirelessFrequencyType.DSC}>
          DSC
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformation1100TWirelessFrequencyField;
