/** Given an enum, get the enum key from the provided value. */
export const getEnumKeyFromValue = <EnumType extends Record<string, string>>(
  enumObject: EnumType,
  enumValue: string
) => {
  let keys = Object.keys(enumObject).filter(
    (key) => enumObject[key] === enumValue
  );
  return keys.length > 0 ? keys[0] : "";
};
