import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { remoteOptionsAlarmReceiverAuthorizationFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAlarmReceiverAuthorizationField";
import { remoteOptionsAppKeyFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import { remoteOptionsArmedAnswerRingsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsArmedAnswerRingsField";
import { remoteOptionsDisarmedAnswerRingsFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsDisarmedAnswerRingsField";
import { remoteOptionsRemoteDisarmFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import { remoteOptionsServiceReceiverAuthorizationFieldId } from "components/FullProgramming/common/RemoteOptionsFields/RemoteOptionsServiceReceiverAuthorizationField";
import { XTRemoteOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XTFullProgramming/__generated__/XTRemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getRemoteOptionsState } from "../../../XTFullProgramming/XTRemoteOptionsProgrammingConceptForm";
import { XtProgrammingTemplateRemoteOptionsInput } from "../Create/__generated__/XTTemplateSaveMutation.graphql";
import { XTRemoteOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTRemoteOptionsTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XTRemoteOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTRemoteOptionsTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          id
          included
          remoteDisarm {
            included
            data
          }
          appKey {
            included
            data
          }
          armedAnswerRings {
            included
            data
          }
          disarmedAnswerRings {
            included
            data
          }
          alarmReceiverAuthorization {
            included
            data
          }
          serviceReceiverAuthorization {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XTRemoteOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateRemoteOptionsInput => {
  const {
    panel: { remoteOptions },
  } = getRemoteOptionsState(controlSystem);

  if (!remoteOptions) {
    return { included: false };
  }

  const remoteOptionsInputFields = {
    remoteDisarm: {
      included: includedFields.has(remoteOptionsRemoteDisarmFieldId()),
      data: remoteOptions.remoteDisarm,
    },
    appKey: {
      included: includedFields.has(remoteOptionsAppKeyFieldId()),
      data: remoteOptions.appKey,
    },
    armedAnswerRings: {
      included: includedFields.has(remoteOptionsArmedAnswerRingsFieldId()),
      data: Number(remoteOptions.armedAnswerRings),
    },
    disarmedAnswerRings: {
      included: includedFields.has(remoteOptionsDisarmedAnswerRingsFieldId()),
      data: Number(remoteOptions.disarmedAnswerRings),
    },
    alarmReceiverAuthorization: {
      included: includedFields.has(
        remoteOptionsAlarmReceiverAuthorizationFieldId()
      ),
      data: remoteOptions.alarmReceiverAuthorization,
    },
    serviceReceiverAuthorization: {
      included: includedFields.has(
        remoteOptionsServiceReceiverAuthorizationFieldId()
      ),
      data: remoteOptions.serviceReceiverAuthorization,
    },
  };

  const remoteOptionsIncluded = Object.values(remoteOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: remoteOptionsIncluded,
    ...remoteOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XTRemoteOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { remoteOptions } = getState(template);

  if (!remoteOptions) return;

  if (remoteOptions.remoteDisarm?.included) {
    setIncludedFields(setToggle(remoteOptionsRemoteDisarmFieldId()));
  }
  if (remoteOptions.appKey?.included) {
    setIncludedFields(setToggle(remoteOptionsAppKeyFieldId()));
  }
  if (remoteOptions.armedAnswerRings?.included) {
    setIncludedFields(setToggle(remoteOptionsArmedAnswerRingsFieldId()));
  }
  if (remoteOptions.disarmedAnswerRings?.included) {
    setIncludedFields(setToggle(remoteOptionsDisarmedAnswerRingsFieldId()));
  }
  if (remoteOptions.alarmReceiverAuthorization?.included) {
    setIncludedFields(
      setToggle(remoteOptionsAlarmReceiverAuthorizationFieldId())
    );
  }
  if (remoteOptions.serviceReceiverAuthorization?.included) {
    setIncludedFields(
      setToggle(remoteOptionsServiceReceiverAuthorizationFieldId())
    );
  }
};
