/**
 * @generated SignedSource<<fc877f6cc0b730007ee72cb0120aad26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatFormExistingModal_site$data = {
  readonly cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly wiegandLength: number;
  }>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatForm_site">;
  readonly " $fragmentType": "CardFormatFormExistingModal_site";
};
export type CardFormatFormExistingModal_site$key = {
  readonly " $data"?: CardFormatFormExistingModal_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatFormExistingModal_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatFormExistingModal_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CardFormatForm_site"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CardFormat",
      "kind": "LinkedField",
      "name": "cardFormats",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wiegandLength",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "7e72401d6b48f9e34bbd8cbfdf6ed2a4";

export default node;
