import React from "react";
import Sections, { Sheet } from "./Layout/Layout";
import styles from "./SuspenseFallback.module.css";

export default function SuspenseFallback() {
  return (
    <Sections>
      <Sections.Section>
        <Sheet callout={true}>
          <div className={styles.skeleton}>
            <div className="csspinner traditional" />
          </div>
        </Sheet>
      </Sections.Section>
    </Sections>
  );
}

export { default as GenericSuspenseFallback } from "components/GenericSuspenseFallback";
