import * as React from "react";
import { useIntl } from "react-intl";
import { DoorStatus, Door as GqlDoor } from "securecom-graphql/client";
import styled from "styled-components";
import { DoorIcon } from "../Icons";
import LockSwitch from "../LockSwitch";
import Widget from "../Widget";
import AccessButton from "./AccessButton";

const TEMPORARY_ACCESS_LENGTH_IN_MS = 5000;

export type Door = Pick<GqlDoor, "name" | "status"> & { id: string };

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 190px;
  width: 100%;
  min-height: 90px;
  height: inherit;
  margin: 0 auto;
`;

function DummyDoorWidget({
  door,
  pendingStatus,
  lockDoor,
  unlockDoor,
  globalControls,
  onAccess,
  title,
}: {
  door: Door;
  pendingStatus?: DoorStatus | null;
  lockDoor: () => void;
  unlockDoor: () => void;
  globalControls: [];
  onAccess?: () => void;
  title?: string;
}) {
  const [accessDisabled, setAccessDisabled] = React.useState(false);
  const [unlockButtonHovered, setUnlockButtonHovered] = React.useState(false);
  const [lockButtonHovered, setLockButtonHovered] = React.useState(false);
  const [accessButtonHovered, setAccessButtonHovered] = React.useState(false);

  const { formatMessage } = useIntl();
  const isUnlocking = pendingStatus === DoorStatus.UNLOCKED;
  const isLocking = pendingStatus === DoorStatus.LOCKED;

  const accessTimeout = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  React.useEffect(() => {
    return () => {
      if (accessTimeout.current) {
        clearTimeout(accessTimeout.current);
      }
    };
  }, []);

  return (
    <Widget
      key={door.id}
      title={door.name}
      globalControls={globalControls}
      icon={<DoorIcon />}
      statusMessage={
        lockButtonHovered ? (
          formatMessage({
            defaultMessage: "Click to lock",
            description: "Click to lock",
          })
        ) : unlockButtonHovered ? (
          formatMessage({
            defaultMessage: "Click to unlock",
            description: "Click to unlock",
          })
        ) : accessDisabled ? (
          formatMessage({
            defaultMessage: "Door is temporarily accessible",
            description: "Door is temporarily accessible",
          })
        ) : accessButtonHovered ? (
          formatMessage({
            defaultMessage: "Allow temporary access",
            description: "Allow temporary access",
          })
        ) : isUnlocking ? (
          formatMessage({
            defaultMessage: "Unlocking",
            description: "unlocking",
          })
        ) : isLocking ? (
          formatMessage({ defaultMessage: "Locking", description: "locking" })
        ) : door.status === DoorStatus.UNLOCKED ? (
          formatMessage({ defaultMessage: "Unlocked", description: "unlocked" })
        ) : door.status === DoorStatus.LOCKED ? (
          formatMessage({ defaultMessage: "Locked", description: "locked" })
        ) : (
          <>&nbsp;</>
        )
      }
      loading={!!pendingStatus}
    >
      <Wrapper>
        <LockSwitch
          id={door.id}
          currentStatus={
            accessDisabled
              ? DoorStatus.UNLOCKED
              : door.status === DoorStatus.UNKNOWN
              ? null
              : door.status
          }
          onMouseEnter={(status) => {
            if (status === DoorStatus.LOCKED) {
              setLockButtonHovered(true);
              setUnlockButtonHovered(false);
            } else if (status === DoorStatus.UNLOCKED) {
              setLockButtonHovered(false);
              setUnlockButtonHovered(true);
            }
          }}
          onMouseLeave={() => {
            setLockButtonHovered(false);
            setUnlockButtonHovered(false);
          }}
          onChange={(status) => {
            if (status === DoorStatus.LOCKED) {
              lockDoor();
            } else if (status === DoorStatus.UNLOCKED) {
              unlockDoor();
            }
          }}
        />

        {onAccess && (
          <AccessButton
            onClick={() => {
              onAccess();
              setAccessDisabled(true);
              setAccessButtonHovered(false);
              accessTimeout.current = setTimeout(() => {
                setAccessDisabled(false);
              }, TEMPORARY_ACCESS_LENGTH_IN_MS);
            }}
            accessMessage={formatMessage({
              defaultMessage: "Access",
              description: "Access",
            })}
            onMouseEnter={() => {
              setAccessButtonHovered(true);
            }}
            onMouseLeave={() => {
              setAccessButtonHovered(false);
            }}
            disabled={accessDisabled}
          />
        )}
      </Wrapper>
    </Widget>
  );
}

export default DummyDoorWidget;
