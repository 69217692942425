import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import {
  EditingFrom,
  useTemplateContext,
} from "components/FullProgramming/common/TemplateContext";
import { useStateDotGo } from "components/SystemDiagnostics/AngularStateProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { useFragment, useMutation } from "react-relay";
import {
  asID,
  CommunicationComType,
  fromDealerId,
} from "securecom-graphql/client";
import { getState as getCommunicationState } from "../../../TMSentryFullProgramming/TMSentryCommunicationProgrammingConceptForm";
import { getInput as getAreaInput } from "../Data/TMSentryAreaInformationTemplateData";
import { getInput as getCommunicationInput } from "../Data/TMSentryCommunicationTemplateData";
import { getInput as getLockoutCodeInput } from "../Data/TMSentryLockoutCodeTemplateData";
import { getInput as getNetworkOptionsInput } from "../Data/TMSentryNetworkOptionsTemplateData";
import { getInput as getOutputOptionsInput } from "../Data/TMSentryOutputOptionsTemplateData";
import { getInput as getRemoteOptionsInput } from "../Data/TMSentryRemoteOptionsTemplateData";
import { getInput as getSystemOptionsInput } from "../Data/TMSentrySystemOptionsTemplateData";
import { getInput as getSystemReportsInput } from "../Data/TMSentrySystemReportsTemplateData";
import { getInput as getZoneInformationsInput } from "../Data/TMSentryZoneInformationTemplateData";
import { tMSentryTemplateSaveMutation } from "./TMSentryTemplateSaveMutation";
import { TMSentryTemplateSaveHook_controlSystem$key } from "./__generated__/TMSentryTemplateSaveHook_controlSystem.graphql";
import { TMSentryTemplateSaveMutation } from "./__generated__/TMSentryTemplateSaveMutation.graphql";

export const useTMSentryTemplateSaveMutation = (
  controlSystem: TMSentryTemplateSaveHook_controlSystem$key
): [
  (
    includedFields: IncludedTemplateFieldsType,
    templateName: string,
    customerId: string,
    dealerId: string
  ) => void,
  boolean
] => {
  const [saveTMSTemplate, isSavingTMSTemplate] =
    useMutation<TMSentryTemplateSaveMutation>(tMSentryTemplateSaveMutation);

  const data = useFragment(
    graphql`
      fragment TMSentryTemplateSaveHook_controlSystem on ControlSystem {
        panel {
          hardwareModel
        }
        ...TMSentryCommunicationProgrammingConceptFormInline_controlSystem
        ...TMSentryNetworkOptionsProgrammingConceptFormInline_controlSystem
        ...TMSentryLockoutCodeProgrammingConceptFormInline_controlSystem
        ...TMSentryRemoteOptionsProgrammingConceptFormInline_controlSystem
        ...TMSentrySystemReportsProgrammingConceptFormInline_controlSystem
        ...TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem
        ...TMSentryOutputOptionsProgrammingConceptFormInline_controlSystem
        ...TMSentryAreaInformationProgrammingConceptFormInline_controlSystem
        ...TMSentryZoneInformationsProgrammingConceptFormInline_controlSystem
      }
    `,
    controlSystem
  );

  const templateData = useTemplateContext();

  const showAlert = useShowAlert();
  const nav = useStateDotGo();

  return [
    (
      includedFields: IncludedTemplateFieldsType,
      templateName: string,
      customerId: string,
      dealerId: string
    ) => {
      const {
        panel: { communication },
      } = getCommunicationState(data);
      saveTMSTemplate({
        variables: {
          dealerId: dealerId,
          templateData: {
            id: templateData.templateId ? templateData.templateId : null,
            name: templateName,
            hardwareModel: templateData.hardwareModel,
            dealerId: dealerId,
            receiverCommType:
              communication?.comType ?? CommunicationComType.NONE,
            customerId: customerId,
            concepts: {
              areaInformations: getAreaInput(data, includedFields),
              communication: getCommunicationInput(data, includedFields),
              lockoutCode: getLockoutCodeInput(data, includedFields),
              networkOptions: getNetworkOptionsInput(data, includedFields),
              outputOptions: getOutputOptionsInput(data, includedFields),
              remoteOptions: getRemoteOptionsInput(data, includedFields),
              systemOptions: getSystemOptionsInput(data, includedFields),
              systemReports: getSystemReportsInput(data, includedFields),
              zoneInformations: getZoneInformationsInput(data, includedFields),
            },
          },
        },
        onCompleted: (response) => {
          if (
            response.saveTakeoverProgrammingTemplate?.__typename ===
            "SaveProgrammingTemplateSuccessResult"
          ) {
            showAlert({
              type: "success",
              text: "Successfully saved template.",
            });
            if (templateData.editingFrom === EditingFrom.NEW_TEMPLATE_PAGE) {
              nav(
                "app.dealer.default_programming",
                {
                  dealer_id: fromDealerId(asID(dealerId)).dealerId,
                },
                { reload: true }
              );
            } else if (
              templateData.editingFrom === EditingFrom.PROGRAMMING_PAGE
            ) {
              templateData.toggleIsEditing();
              nav("app.panel_programming.programming", {}, { reload: true });
            }
          } else {
            showAlert({
              type: "error",
              text: `Error saving template`,
            });
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: `Error saving template`,
          });
        },
      });
    },
    isSavingTMSTemplate,
  ];
};
