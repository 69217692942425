/**
 *
 * GameIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const GameIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M63.353,41.817L57.8,27.442c-0.006-0.016-0.563-1.445-0.952-2.203c-2.162-6.589-6.951-10.072-15.055-10.955
        c-4.114-0.45-8.414-0.369-12.882,0.268L25.9,15.061c-1.077,0.211-2.155,0.459-3.232,0.732C20.917,15.146,17,13.229,17,9
        c0-3.021-0.322-8.863-0.336-9.11L12.67,0.11C12.674,0.17,13,6.089,13,9c0,3.848,2.01,6.586,4.349,8.387
        c-2.13,0.737-4.25,1.584-6.349,2.551c-9.712,4.47-10.265,13.51-10.009,17.408c-0.08,0.711-0.087,1.366-0.021,1.994l0.691,15.262
        l0.01,0.121c0.527,4.95,4.689,8.684,9.682,8.684c0.338,0,0.678-0.017,1.025-0.054c5.3-0.565,9.17-5.293,8.674-10.564
        l-0.045-8.612c6.907-2.361,14.085-3.742,21.378-4.114l2.748,8.608l0.039,0.112c1.438,3.722,5.093,6.223,9.096,6.223
        c1.189,0,2.36-0.217,3.481-0.645c2.427-0.93,4.346-2.745,5.404-5.112C64.213,46.88,64.284,44.242,63.353,41.817z M59.502,47.615
        c-0.622,1.394-1.753,2.462-3.181,3.01c-0.661,0.252-1.353,0.38-2.053,0.38c-2.344,0-4.484-1.449-5.344-3.612l-3.651-11.439
        l-1.51,0.048c-8.701,0.277-17.262,1.92-25.444,4.88l-1.327,0.48l0.062,11.542l0.011,0.197c0.327,3.124-1.965,5.939-5.104,6.274
        c-0.204,0.021-0.405,0.031-0.606,0.031c-2.923,0-5.362-2.168-5.698-5.052l-0.691-15.26l-0.011-0.137
        c-0.045-0.389-0.035-0.804,0.03-1.304l0.026-0.205l-0.016-0.205c-0.278-3.659,0.35-10.3,7.679-13.674
        c4.613-2.125,9.323-3.667,13.945-4.575l2.906-0.491c4.049-0.574,8.051-0.657,11.835-0.243c8.281,0.902,10.579,4.738,11.734,8.373
        l0.183,0.408c0.129,0.228,0.568,1.262,0.792,1.84l5.552,14.373C60.166,44.677,60.124,46.225,59.502,47.615z"
          />
          <polygon
            points="19.257,28.143 16.186,29.371 15.024,26.113 11.257,27.457 12.47,30.857 9.257,32.143 10.743,35.857 
        13.814,34.629 14.976,37.887 18.743,36.543 17.53,33.143 20.743,31.857       "
          />
          <path d="M31,23c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S33.206,23,31,23z" />
          <path d="M43,23c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S45.206,23,43,23z" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default GameIcon;
