import { findLast } from "common/utils/universal/array";
import { expandRange, isNumeric } from "common/utils/universal/numbers";
import React, { useEffect, useMemo, useRef } from "react";
import styled, { css, StyledComponent } from "styled-components";
import { joinSpaced } from "utils/string";
import styles from "./index.module.css";

/**
 * A list of all special command keys for inputs.
 * https://www.w3.org/TR/uievents-key/#key-attribute-value
 */
const commandKeys = [
  "Accept",
  "Again",
  "AllCandidates",
  "Alphanumeric",
  "Alt",
  "AltGraph",
  "AppSwitch",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "Attn",
  "AudioBalanceLeft",
  "AudioBalanceRight",
  "AudioBassBoostDown",
  "AudioBassBoostToggle",
  "AudioBassBoostUp",
  "AudioFaderFront",
  "AudioFaderRear",
  "AudioSurroundModeNext",
  "AudioTrebleDown",
  "AudioTrebleUp",
  "AudioVolumeDown",
  "AudioVolumeMute",
  "AudioVolumeUp",
  "AVRInput",
  "AVRPower",
  "Backspace",
  "BrightnessDown",
  "BrightnessUp",
  "BrowserBack",
  "BrowserFavorites",
  "BrowserForward",
  "BrowserHome",
  "BrowserRefresh",
  "BrowserSearch",
  "BrowserStop",
  "Call",
  "Camera",
  "CameraFocus",
  "Cancel",
  "CapsLock",
  "ChannelDown",
  "ChannelUp",
  "Clear",
  "Close",
  "ClosedCaptionToggle",
  "CodeInput",
  "ColorF0Red",
  "ColorF1Green",
  "ColorF2Yellow",
  "ColorF3Blue",
  "ColorF4Grey",
  "ColorF5Brown",
  "Compose",
  "ContextMenu",
  "Control",
  "Convert",
  "Copy",
  "CrSel",
  "Cut",
  "Dead",
  "Delete",
  "Dimmer",
  "DisplaySwap",
  "DVR",
  "Eisu",
  "Eject",
  "End",
  "EndCall",
  "Enter",
  "EraseEof",
  "Escape",
  "Execute",
  "Exit",
  "ExSel",
  "F1",
  "F10",
  "F11",
  "F12",
  "F2",
  "F24",
  "F3",
  "F4",
  "F5",
  "F6",
  "F7",
  "F8",
  "F9",
  "FavoriteClear0",
  "FavoriteClear1",
  "FavoriteClear2",
  "FavoriteClear3",
  "FavoriteRecall0",
  "FavoriteRecall1",
  "FavoriteRecall2",
  "FavoriteRecall3",
  "FavoriteStore0",
  "FavoriteStore1",
  "FavoriteStore2",
  "FavoriteStore3",
  "FinalMode",
  "Find",
  "Fn",
  "FnLock",
  "GoBack",
  "GoHome",
  "GroupFirst",
  "GroupLast",
  "GroupNext",
  "GroupPrevious",
  "Guide",
  "GuideNextDay",
  "GuidePreviousDay",
  "HangulMode",
  "HanjaMode",
  "Hankaku",
  "HeadsetHook",
  "Help",
  "Hibernate",
  "Hiragana",
  "HiraganaKatakana",
  "Home",
  "Hyper",
  "Info",
  "Insert",
  "InstantReplay",
  "JunjaMode",
  "KanaMode",
  "KanjiMode",
  "Katakana",
  "Key11",
  "Key12",
  "LastNumberRedial",
  "LaunchApplication1",
  "LaunchApplication2",
  "LaunchCalendar",
  "LaunchContacts",
  "LaunchMail",
  "LaunchMediaPlayer",
  "LaunchMusicPlayer",
  "LaunchPhone",
  "LaunchScreenSaver",
  "LaunchSpreadsheet",
  "LaunchWebBrowser",
  "LaunchWebCam",
  "LaunchWordProcessor",
  "Link",
  "ListProgram",
  "LiveContent",
  "Lock",
  "LogOff",
  "MailForward",
  "MailReply",
  "MailSend",
  "MannerMode",
  "MediaApps",
  "MediaAudioTrack",
  "MediaClose",
  "MediaFastForward",
  "MediaLast",
  "MediaNextTrack",
  "MediaPause",
  "MediaPlay",
  "MediaPlayPause",
  "MediaPreviousTrack",
  "MediaRecord",
  "MediaRewind",
  "MediaSkipBackward",
  "MediaSkipForward",
  "MediaStop",
  "MediaTopMenu",
  "MediaTrackNext",
  "MediaTrackPrevious",
  "Meta",
  "MicrophoneToggle",
  "MicrophoneVolumeDown",
  "MicrophoneVolumeMute",
  "MicrophoneVolumeUp",
  "ModeChange",
  "NavigateIn",
  "NavigateNext",
  "NavigateOut",
  "NavigatePrevious",
  "New",
  "NextCandidate",
  "NextFavoriteChannel",
  "NextUserProfile",
  "NonConvert",
  "Notification",
  "NumLock",
  "OnDemand",
  "Open",
  "PageDown",
  "PageUp",
  "Pairing",
  "Paste",
  "Pause",
  "PinPDown",
  "PinPMove",
  "PinPToggle",
  "PinPUp",
  "Play",
  "PlaySpeedDown",
  "PlaySpeedReset",
  "PlaySpeedUp",
  "Power",
  "PowerOff",
  "PreviousCandidate",
  "Print",
  "PrintScreen",
  "Process",
  "Props",
  "RandomToggle",
  "RcLowBattery",
  "RecordSpeedNext",
  "Redo",
  "RfBypass",
  "Romaji",
  "Save",
  "ScanChannelsToggle",
  "ScreenModeNext",
  "ScrollLock",
  "Select",
  "Settings",
  "Shift",
  "SingleCandidate",
  "Soft1",
  "Soft2",
  "Soft3",
  "Soft4",
  "Soft8",
  "SpeechCorrectionList",
  "SpeechInputToggle",
  "SpellCheck",
  "SplitScreenToggle",
  "Standby",
  "STBInput",
  "STBPower",
  "Subtitle",
  "Super",
  "Symbol",
  "SymbolLock",
  "Tab",
  "Teletext",
  "TV",
  "TV3DMode",
  "TVAntennaCable",
  "TVAudioDescription",
  "TVAudioDescriptionMixDown",
  "TVAudioDescriptionMixUp",
  "TVContentsMenu",
  "TVDataService",
  "TVInput",
  "TVInputComponent1",
  "TVInputComponent2",
  "TVInputComposite1",
  "TVInputComposite2",
  "TVInputHDMI1",
  "TVInputHDMI2",
  "TVInputHDMI3",
  "TVInputHDMI4",
  "TVInputVGA1",
  "TVMediaContext",
  "TVNetwork",
  "TVNumberEntry",
  "TVPower",
  "TVRadioService",
  "TVSatellite",
  "TVSatelliteBS",
  "TVSatelliteCS",
  "TVSatelliteToggle",
  "TVTerrestrialAnalog",
  "TVTerrestrialDigital",
  "TVTimer",
  "Undo",
  "Unidentified",
  "VideoModeNext",
  "VoiceDial",
  "WakeUp",
  "Wink",
  "Zenkaku",
  "ZenkakuHankaku",
  "ZoomIn",
  "ZoomOut",
  "ZoomToggle",
];

function manuallyTriggerInputChange(
  input: HTMLInputElement,
  value: string
): void {
  const set = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  )?.set;

  if (set) {
    set.call(input, value);
    input.dispatchEvent(new Event("input", { bubbles: true }));
  }
}

export function OffsetFieldRow({
  className = "",
  error,
  ...rest
}: {
  className?: string;
  error?: any;
  rowStyle?: "full-width" | "featured" | "default";
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <FieldRow rowStyle="full-width">
      <div
        {...rest}
        className={joinSpaced("col-sm-9 col-sm-offset-3", className)}
      />
    </FieldRow>
  );
}

export function FieldRow({
  className = "",
  error,
  rowStyle = "default",
  showOverflow,
  ...rest
}: {
  className?: string;
  error?: any;
  rowStyle?: "full-width" | "featured" | "default" | "offset";
  showOverflow?: boolean;
} & React.HTMLProps<HTMLDivElement>) {
  const style = showOverflow ? { overflow: "visible" } : { overflow: "hidden" };
  return (
    <div
      {...rest}
      className={joinSpaced(
        "form-group",
        styles["field"],
        styles[`${rowStyle}-field`],
        className,
        !!error ? "has-error" : ""
      )}
      style={style}
    />
  );
}

export function FieldLabel({
  className = "",
  ...rest
}: { className?: string } & React.HTMLProps<HTMLLabelElement>) {
  return (
    <label
      {...rest}
      className={joinSpaced("control-label", styles["label"], className)}
    />
  );
}

export function InlineFieldLabel({
  className = "",
  ...rest
}: { className?: string } & React.HTMLProps<HTMLLabelElement>) {
  return (
    <FieldLabel
      {...rest}
      className={joinSpaced("col-sm-3", styles["inline-label"], className)}
    />
  );
}

export function InlineFieldInputContainer({
  className = "",
  ...rest
}: { className?: string } & React.HTMLProps<HTMLDivElement>) {
  return <div {...rest} className={joinSpaced("col-sm-9", className)} />;
}

export function ReadOnlyInput(props: React.HTMLProps<HTMLInputElement>) {
  return (
    <input
      disabled={true}
      {...props}
      type="text"
      className={`form-control ${props.className ?? ""}`}
      readOnly={true}
    />
  );
}

export function Switch({
  label,
  ...rest
}: { label: string } & React.HTMLProps<HTMLInputElement>) {
  return (
    <label
      className={joinSpaced("switch switch-md", styles["switch-overrides"])}
      aria-labelledby={label}
    >
      <input {...rest} className="form-control" type="checkbox" />
      <span aria-hidden="true"></span>
    </label>
  );
}

export function Checkbox({
  label,
  infoText,
  ...rest
}: { label: React.ReactNode; infoText?: string } & Omit<
  React.HTMLProps<HTMLInputElement>,
  "label"
>) {
  return (
    <div className="checkbox c-checkbox needsclick">
      <label className="needsclick">
        <input type="checkbox" {...rest} />
        <span className="icon-checkmark"></span>
        {label}
      </label>
      {infoText ? <CheckboxInfoText>{infoText}</CheckboxInfoText> : null}
    </div>
  );
}

type NumberInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  "value" | "min" | "max" | "step" | "pattern"
> & {
  ranges?: Array<[number, number] | [number]>;
  value: string | number;
  min?: number;
  max?: number;
  step?: number;
  pattern?: RegExp;
  inlineHelp?: React.ReactNode;
  cycle?: boolean;
};

export function NaturalNumberInput({
  min = 0,
  max = Infinity,
  ...props
}: Omit<NumberInputProps, "step">) {
  return (
    <NumberInput {...props} min={min} max={max} step={1} pattern={/[0-9]/} />
  );
}

export function FieldErrorMessage({ ...props }) {
  return <div {...props} style={{ marginTop: "5px" }}></div>;
}

export function FieldInputContainer({ ...props }) {
  return <div {...props} style={{ width: "100%" }}></div>;
}

function isEmptyNumberValue(value: any): value is null | undefined | "" {
  return value === "" || value === undefined || value === null;
}

export function NumberInput({
  min = -Infinity,
  max = Infinity,
  step = 1,
  value,
  pattern,
  inlineHelp = isFinite(min) && isFinite(max) ? `(${min}-${max})` : undefined,
  ranges,
  cycle = true,
  ...props
}: NumberInputProps) {
  const ref = useRef<HTMLInputElement>(null);

  /**
   * Expanded unique numbers from ranges constrained with items higher than min filtered
   */
  const rangeConstraints = useMemo(
    () =>
      ranges
        ? Array.from(
            new Set(
              ranges
                .map(expandRange)
                .flat()
                .sort((a, b) => a - b)
            )
          ).filter(
            (number) => (!min || number >= min) && (!max || number <= max)
          )
        : ranges,
    [max, min, ranges]
  );

  // Ensure input is invalid on invalid values
  useEffect(() => {
    const input = ref.current;

    if (isNumeric(value) && input) {
      const number = Number(value);

      if (number < min) {
        input.setCustomValidity(`Value must be equal to or larger than ${min}`);
      } else if (number > max) {
        input.setCustomValidity(`Value must be equal to or less than ${max}`);
      } else {
        input.setCustomValidity("");
      }
    }
  }, [max, min, value]);

  return (
    <label style={{ position: "relative", width: "100%" }}>
      <input
        {...props}
        type="text"
        ref={ref}
        onKeyDown={(event) => {
          const input = ref.current;
          const key = event.key;

          if (pattern && !key.match(pattern) && !commandKeys.includes(key)) {
            event.preventDefault();
            return false;
          }

          type VerticalDirectionArrowKeys = "ArrowUp" | "ArrowDown";

          const getNextValue = (
            key: VerticalDirectionArrowKeys,
            currentValue: string | number
          ) => {
            const lowest = rangeConstraints ? rangeConstraints[0] : min;
            const highest = rangeConstraints
              ? rangeConstraints[rangeConstraints.length - 1]
              : max;

            const numberValue = Number(currentValue);
            const isEmpty = isEmptyNumberValue(currentValue);

            if (key === "ArrowUp") {
              const nextStep = numberValue + step;

              if (isEmpty) {
                return lowest;
              }

              if (rangeConstraints) {
                return rangeConstraints.includes(nextStep)
                  ? nextStep
                  : rangeConstraints.find((number) => number >= nextStep) ??
                      lowest;
              }

              return nextStep <= highest ? nextStep : cycle ? lowest : highest;
            } else if (key === "ArrowDown") {
              const previousStep = numberValue - step;

              return isEmpty
                ? highest
                : rangeConstraints
                ? rangeConstraints.includes(previousStep)
                  ? previousStep
                  : findLast<number>((number) => number <= previousStep)(
                      rangeConstraints
                    ) ?? highest
                : previousStep >= lowest
                ? previousStep
                : cycle
                ? highest
                : lowest;
            } else {
              return currentValue;
            }
          };

          if (["ArrowUp", "ArrowDown"].includes(key) && input) {
            event.preventDefault();

            const nextValue = getNextValue(
              key as VerticalDirectionArrowKeys,
              value
            ).toString();

            if (nextValue !== value) {
              manuallyTriggerInputChange(input, nextValue);
            }
          }
        }}
        value={isEmptyNumberValue(value) ? "" : value}
      />
      {inlineHelp && (
        <span
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "50%",
            transform: "translateY(-50%)",
            padding: "0.5rem",
            fontWeight: 200,
            color: "gray",
          }}
        >
          {inlineHelp}
        </span>
      )}
    </label>
  );
}

/**
 * Creates a typed compound component for styled components
 *
 * const Component = createCompoundStyledComponent(
 *   styled(...),
 *   {
 *     SomeExtendedFileName: styled(...)
 *   }
 * )
 *
 * ```
 * import { Component } from "...Component";
 *
 * ...
 * return (
 *  <Component>
 *    <Component.SomeExtendedFieldName />
 *  </Component>
 * )
 * ```
 */
function createCompoundStyledComponent<
  Component extends StyledComponent<any, any, any, any>,
  Extension extends { [key: string]: any }
>(Base: Component, fields: Extension): Component & Extension {
  const Result: Component & Extension = Base;

  Object.entries(fields).forEach(([key, value]) => {
    (Result as any)[key] = value;
  });

  return Result;
}

export const InlineField = createCompoundStyledComponent(
  styled.div<{ fieldStyle?: "default" | "centered" }>`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-areas: "label" "input";
    row-gap: 2px;
    align-items: center;

    ${({ fieldStyle }) =>
      fieldStyle === "centered"
        ? css`
            margin: 0 auto;
          `
        : ``}

    @media (min-width: 768px) {
      grid-template-columns: ${({ fieldStyle }) =>
        fieldStyle === "centered" ? `auto 1fr` : `var(--measure-7x) 1fr`};
      grid-template-areas: "label input";
      column-gap: var(--measure-2x);
    }
  `,
  {
    Label: styled.label`
      grid-area: label;
      margin: 0;

      @media (min-width: 768px) {
        text-align: right;
      }
    `,
    Input: styled.div`
      grid-area: input;
    `,
  }
);

const CheckboxInfoText = styled.div`
  color: gray;
  cursor: default;
  font-size: 1rem;
  padding-left: 25px;
`;
