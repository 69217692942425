/**
 * @generated SignedSource<<2492e6da064d17cd5c4c26b814644231>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsWifiPassphraseField_networkOptions$data = {
  readonly wifiPassphrase: string;
  readonly " $fragmentType": "NetworkOptionsWifiPassphraseField_networkOptions";
};
export type NetworkOptionsWifiPassphraseField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsWifiPassphraseField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsWifiPassphraseField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsWifiPassphraseField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wifiPassphrase",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "572abea8e8ec62ec16ee697e5e3f9170";

export default node;
