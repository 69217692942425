App.controller("FormsCtrl", FormsCtrl);

FormsCtrl.$inject = [
  "$rootScope",
  "$scope",
  "StyleDataAccessService",
  "TIME_ZONES",
];

function FormsCtrl($rootScope, $scope, StyleDataAccessService, TIME_ZONES) {
  init();

  $scope.timeZones = TIME_ZONES; // Get our time zone list from the constants file

  function init() {
    StyleDataAccessService.getStyleData("form-data.json")
      .then(
        function (formData) {
          $scope.FormData = formData;
          Prism.highlightAll();
        },
        function (formError) {
          $rootScope.alerts.push({ type: "error", message: formError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    //datepicker
    $scope.dateFilters = {};
    $scope.datePickerOpen = {};
    $scope.currentDate = new Date();
    $scope.today = function () {
      $scope.dt = new Date();
    };
    $scope.today();

    $scope.clear = function () {
      $scope.dt = null;
    };

    $scope.date_low_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.dateLowOpened = true;
    };

    $scope.date_high_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.dateHighOpened = true;
    };

    $scope.dateOptions = {
      formatYear: "yy",
      startingDay: 1,
    };

    $scope.formats = [
      "dd-MMMM-yyyy",
      "yyyy/MM/dd",
      "dd.MM.yyyy",
      "MM/dd/yy",
      "shortDate",
    ];
    $scope.format = $scope.formats[4];

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 2);
    $scope.events = [
      {
        date: tomorrow,
        status: "full",
      },
      {
        date: afterTomorrow,
        status: "partially",
      },
    ];

    $scope.getDayClass = function (date, mode) {
      if (mode === "day") {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }

      return "";
    };

    //dropdown
    $scope.items = ["Users", "Cameras", "Customers"];

    $scope.status = {
      isopen: false,
    };
  }
}
