/**
 * @generated SignedSource<<3a98c6aeab593917504eb22df675c9d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelContext_panel$data = {
  readonly id: string;
  readonly " $fragmentType": "PanelContext_panel";
};
export type PanelContext_panel$key = {
  readonly " $data"?: PanelContext_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContext_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContext_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "2bac3290f466c66a7143758b563d808b";

export default node;
