/**
 * @generated SignedSource<<3f747b5a4f84692070843223b99edc4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsIpv6DnsServerField_networkOptions$data = {
  readonly dhcpEnabled: boolean;
  readonly ipv6Dns: string;
  readonly ipv6Enabled: boolean;
  readonly " $fragmentType": "NetworkOptionsIpv6DnsServerField_networkOptions";
};
export type NetworkOptionsIpv6DnsServerField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsIpv6DnsServerField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsIpv6DnsServerField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsIpv6DnsServerField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipv6Dns",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipv6Enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dhcpEnabled",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "02ec7b31c835f42e3eb5f76d1ccdc53b";

export default node;
