/**
 * @ngdoc service
 * @name App.factory:ControlSystemsV2API
 *
 * @description
 * API factory for Control System Information
 *
 */
App.factory("ControlSystemsV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/control_systems/:control_system_id/:vv",
      {},
      {
        destroy: {
          method: "DELETE",
          params: { control_system_id: "@control_system_id" },
        },
        show: {
          method: "GET",
          params: { control_system_id: "@control_system_id" },
        },
        video_verification_info: {
          method: "GET",
          params: {
            control_system_id: "@control_system_id",
            vv: "video_verification_info",
          },
        },
      }
    );
  },
]);
