import { motionDetectionRegionsSvgId } from "components/CameraEditCommon/constants";
import { Coordinates, RegionBounds } from "components/CameraEditCommon/types";
import {
  CoordinateRenderer,
  getClosestPoint,
  getEdgePoints,
  getPointForSlopeGivenX,
  getPointForSlopeGivenY,
  normalizePxX,
  normalizePxY,
  slope,
} from "components/CameraEditCommon/utils";
import React, { useMemo, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  AnalyticalDetectionRegionCoordinates,
  SecureComCamera,
} from "securecom-graphql/client";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

interface UniviewAnalyticalDetectionRegionProps {
  color: string;
  index: number;
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  coordinates: AnalyticalDetectionRegionCoordinates[];
  activeCoordinateIndexes: number[];
  isActive: boolean;
  sensitivity: number;
  bounds: RegionBounds;
  cameraId: string;
  canAddPoints: boolean;
  mouseDown: boolean | null;
}
function UniviewAnalyticalDetectionRegion(
  props: UniviewAnalyticalDetectionRegionProps
) {
  const {
    color,
    index,
    renderX,
    renderY,
    coordinates,
    activeCoordinateIndexes,
    isActive,
    sensitivity,
    bounds,
    cameraId,
    canAddPoints,
    mouseDown,
  } = props;
  const coordinatesToRender = coordinates.map(({ x, y }) => [x, y]);
  const renderedPoints = coordinates.map(({ x, y }) => [
    renderX(x),
    renderY(y),
  ]);

  return (
    <g>
      <RegionPath
        id={`motion-detection-region-${index}`}
        fill={color}
        opacity={sensitivity / 150 + 0.2}
        d={`${renderedPoints.reduce((d, [x, y], index) => {
          return index === 0 ? `M ${x} ${y}` : `${d} L ${x} ${y}`;
        }, "")} Z`}
        isActive={isActive}
      />
      {isActive && (
        <>
          {!mouseDown &&
            canAddPoints &&
            getEdgePoints(coordinatesToRender as Coordinates[]).map(
              ([point1, point2], edgeIndex) => (
                <Edge
                  key={`edge-${edgeIndex}`}
                  point1={point1}
                  point2={point2}
                  index={edgeIndex}
                  regionIndex={index}
                  renderX={renderX}
                  renderY={renderY}
                  bounds={bounds}
                  cameraId={cameraId}
                  canAddPoints={canAddPoints}
                />
              )
            )}
          {renderedPoints.map(([x, y], pointIndex) => (
            <CornerPoint
              key={`corner-${pointIndex}`}
              x={x}
              y={y}
              active={new Set(activeCoordinateIndexes).has(pointIndex)}
              index={pointIndex}
              regionIndex={index}
              mouseDown={mouseDown}
            />
          ))}
        </>
      )}
    </g>
  );
}

export default UniviewAnalyticalDetectionRegion;

const RegionPath = styled.path<{ isActive: boolean }>`
  cursor: ${({ isActive }) => (isActive ? "move" : "default")};
`;

function CornerPoint(props: {
  x: number;
  y: number;
  active?: boolean;
  index: number;
  regionIndex: number;
  mouseDown: boolean | null;
}) {
  const { x, y, active, index, regionIndex, mouseDown } = props;
  return (
    <g>
      <circle
        r={5}
        cx={x}
        cy={y}
        fill={active ? "blue" : "white"}
        stroke="blue"
        strokeWidth={2}
      />
      <circle
        id={`motion-detection-region-${regionIndex}-point-${index}`}
        r={10}
        cx={x}
        cy={y}
        fill="transparent"
        style={{ cursor: mouseDown ? "grabbing" : "grab" }}
      />
    </g>
  );
}

function Edge(props: {
  point1: Coordinates;
  point2: Coordinates;
  index: number;
  regionIndex: number;
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  bounds: RegionBounds;
  cameraId: string;
  canAddPoints: boolean;
}) {
  const {
    point1,
    point2,
    index,
    regionIndex,
    renderX,
    renderY,
    bounds,
    cameraId,
    canAddPoints,
  } = props;
  const relayEnv = useRelayEnvironment();
  const [x1, y1] = point1;
  const [x2, y2] = point2;
  const [newPointCoordinates, setNewPointCoordinates] = useState<
    [number, number] | null
  >(null);
  const s = useMemo(() => slope([x1, y1])([x2, y2]), [x1, y1, x2, y2]);
  return (
    <>
      {newPointCoordinates && canAddPoints && (
        <circle
          id={`motion-detection-region-${index}-new-point`}
          r={5}
          cx={renderX(newPointCoordinates[0])}
          cy={renderY(newPointCoordinates[1])}
          fill="white"
          stroke="blue"
          strokeWidth={2}
          opacity={0.8}
        />
      )}
      <line
        id={`motion-detection-region-${regionIndex}-edge-${index}`}
        x1={renderX(x1)}
        y1={renderY(y1)}
        x2={renderX(x2)}
        y2={renderY(y2)}
        strokeWidth={25}
        stroke="transparent"
        style={{ cursor: "copy" }}
        onMouseMove={(event) => {
          if (canAddPoints) {
            const { clientX, clientY } = event.nativeEvent;
            const svg = document.getElementById(motionDetectionRegionsSvgId);
            if (!svg) {
              return;
            }

            const svgRect = svg.getBoundingClientRect();
            const cursorX = normalizePxX(bounds)(svg)(clientX - svgRect.x);
            const cursorY = normalizePxY(bounds)(svg)(clientY - svgRect.y);

            if (s === Infinity) {
              setNewPointCoordinates([x1, cursorY]);
            } else {
              const p1 = getPointForSlopeGivenX([x1, y1])(s)(cursorX);
              const p2 = getPointForSlopeGivenY([x1, y1])(s)(cursorY);
              setNewPointCoordinates(
                getClosestPoint([cursorX, cursorY])(p1)(p2)
              );
            }
          }
        }}
        onMouseLeave={() => {
          setNewPointCoordinates(null);
        }}
        onMouseDown={(event) => {
          if (newPointCoordinates && canAddPoints) {
            event.nativeEvent.stopPropagation();
            relayEnv.commitUpdate((store) => {
              const [x, y] = newPointCoordinates;
              const camera = store.get(
                cameraId
              ) as RecordProxy<SecureComCamera>;
              const analyticalDetectionRegions = camera.getLinkedRecord(
                "analyticalDetectionRegions"
              );
              const region =
                analyticalDetectionRegions.getLinkedRecords("regions")[
                  regionIndex
                ];
              const coordinates = region.getLinkedRecords("coordinates");
              const newIndex = index + 1;
              const newCoordinate = store.create(
                uuidv4(),
                "AnalyticalDetectionRegionCoordinates"
              ) as RecordProxy<AnalyticalDetectionRegionCoordinates>;

              newCoordinate.setValue(Math.round(x), "x");
              newCoordinate.setValue(Math.round(y), "y");

              coordinates.splice(newIndex, 0, newCoordinate);

              region.setLinkedRecords(coordinates, "coordinates");
              region.setValue([newIndex], "activeCoordinateIndexes");
              analyticalDetectionRegions.setValue(true, "mouseDown");
            });
          }
        }}
      />
    </>
  );
}
