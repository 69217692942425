/**
 *
 * PowerIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const PowerIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <rect x="30" width="4" height="33" />
        <path
          d="M54.625,9.464c-2.848-2.844-6.153-5.097-9.826-6.698l-1.598,3.667c3.212,1.4,6.104,3.372,8.599,5.862
      c5.288,5.275,8.2,12.292,8.2,19.758C60,47.463,47.438,60,31.999,60C16.561,60,4,47.463,4,32.053
      c0-11.089,6.595-21.14,16.802-25.605l-1.604-3.665C7.536,7.886,0,19.375,0,32.053C0,49.668,14.354,64,31.999,64
      C49.645,64,64,49.668,64,32.053C64,23.518,60.67,15.495,54.625,9.464z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default PowerIcon;
