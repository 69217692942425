App.controller("CustomRolesEditCtrl", [
  "$q",
  "$scope",
  "$rootScope",
  "$state",
  "UserService",
  "role_id",
  "CustomRolesService",
  "$filter",
  "dealerUser",
  function (
    $q,
    $scope,
    $rootScope,
    $state,
    UserService,
    role_id,
    CustomRolesService,
    $filter,
    dealerUser
  ) {
    $scope.addingNewRole = function () {
      return $state.is("app.dealer.personnel_custom_roles_new");
    };
    $scope.editingRole = function () {
      return $state.is("app.dealer.personnel_custom_roles_edit");
    };
    $scope.role = {};
    $rootScope.eligiblePersonnel = [];
    $rootScope.assignRole = [];
    $rootScope.removeRole = [];
    $rootScope.assignedRoleOnLoad = [];
    $rootScope.canEditPersonnel = UserService.canEditPersonnel();
    $scope.display = false;

    $scope.saveRole = function () {
      if (UserService.canEditPersonnel()) {
        var createOrUpdateRole = $scope.role.isNew
          ? CustomRolesService.createRole($scope.role)
          : CustomRolesService.updateRole($scope.role);
        var successActionText = $scope.role.isNew ? "Created" : "Updated";
        var failActionText = $scope.role.isNew ? "create" : "update";
        createOrUpdateRole
          .then(
            function (role) {
              $rootScope.alerts.push({
                type: "success",
                text: successActionText + " role: " + role.name,
              });
              var assignTo = [];
              var removeFrom = [];
              angular.forEach($rootScope.assignRole, function (person) {
                if (
                  $scope.role.isNew ||
                  person.customRoleId == null ||
                  person.customRoleId !== role.id
                ) {
                  var assignedUser = new dealerUser();
                  assignedUser.get(person.id).then(() => {
                    if (role.parent_user_auth_id == 3) {
                      assignedUser.role = "admin";
                    } else if (role.parent_user_id == 1) {
                      assignedUser.role = "technician";
                    }
                    assignedUser.update();
                  });
                  assignTo.push(person);
                }

                if ($rootScope.assignedRoleOnLoad.indexOf(person.id) > -1) {
                  removeFrom.push(person);
                }
              });
              angular.forEach($rootScope.removeRole, function (person) {
                removeFrom.push(person);
              });

              CustomRolesService.assignRoleToUsers(role, assignTo)
                .then(
                  function () {
                    CustomRolesService.removeRoleFromUsers(role, removeFrom)
                      .then(
                        function () {
                          if (validPersonnelBreadcrumb()) {
                            var roleRemoved = removeFrom.some(function (
                              person
                            ) {
                              return (
                                person.id ===
                                CustomRolesService.breadcrumb.personnel.id
                              );
                            });
                            if (roleRemoved) {
                              delete CustomRolesService.breadcrumb
                                .selectedRoleId;
                            }
                            $state.go($rootScope.stateChange.current.name, {
                              user_id:
                                CustomRolesService.breadcrumb.personnel.id,
                            });
                          } else {
                            $state.go(
                              "app.dealer.personnel_custom_roles_list",
                              { dealer_id: UserService.dealerInfo.id }
                            );
                          }
                        },
                        function (error) {
                          console.error(
                            "CustomRolesEditCtrl->saveRole() - Error removing role from personnel: " +
                              angular.toJson(error)
                          );
                          $rootScope.alerts.push({
                            type: "error",
                            text:
                              "Unable to " +
                              failActionText +
                              " role: " +
                              $scope.role.name,
                            json: error,
                          });
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    console.error(
                      "CustomRolesEditCtrl->saveRole() - Error assigning role to personnel: " +
                        angular.toJson(error)
                    );
                    $rootScope.alerts.push({
                      type: "error",
                      text:
                        "Unable to " +
                        failActionText +
                        " role: " +
                        $scope.role.name,
                      json: error,
                    });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              console.error(
                "CustomRolesEditCtrl->saveRole() - Error creating / updating role: " +
                  angular.toJson(error)
              );
              $rootScope.alerts.push({
                type: "error",
                text:
                  "Unable to " + failActionText + " role: " + $scope.role.name,
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    $scope.cancelRoleEdit = function () {
      if (validPersonnelBreadcrumb()) {
        $state.go($rootScope.stateChange.current.name, {
          user_id: CustomRolesService.breadcrumb.personnel.id,
        });
      } else {
        $state.go("app.dealer.personnel_custom_roles_list", {
          dealer_id: UserService.dealer_id,
        });
      }
    };

    $scope.showPersonnel = function () {
      $scope.display = !$scope.display;
    };

    var validPersonnelReturnStates = [
      "app.dealer.personnel_new",
      "app.dealer.personnel_edit",
    ];
    function validPersonnelBreadcrumb() {
      return (
        CustomRolesService.hasOwnProperty("breadcrumb") &&
        validPersonnelReturnStates.indexOf(
          $rootScope.stateChange.current.name
        ) !== -1
      );
    }

    function init() {
      UserService.dealerInfo
        .getPersonnel()
        .then(
          function (personnel) {
            if ($scope.addingNewRole()) {
              CustomRolesService.getNewRoleTemplate(UserService.dealer_id, 3)
                .then(
                  function (newRoleTemplate) {
                    $scope.role = {};
                    // Because the template is cached on the service, you want to create a copy
                    angular.merge($scope.role, newRoleTemplate);
                  },
                  function (error) {
                    console.error(
                      "CustomRolesEditCtrl->init() - Error getting new role template: " +
                        angular.toJson(error)
                    );
                    $state.go("app.dealer.personnel_custom_roles_list", {
                      dealer_id: UserService.dealerInfo.id,
                    });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else if ($scope.editingRole()) {
              CustomRolesService.getRole(UserService.dealer_id, role_id)
                .then(
                  function (requestedRole) {
                    $scope.role = {};
                    // Because the role is cached on the service, you want to create a copy
                    angular.merge($scope.role, requestedRole);
                    CustomRolesService.getRoleAssignees($scope.role)
                      .then(
                        function (assignees) {
                          angular.forEach(assignees, function (assignee) {
                            $rootScope.assignedRoleOnLoad.push(assignee);
                          });
                        },
                        function (error) {
                          console.error(
                            "CustomRolesEditCtrl->init() - Error getting assignees of role: " +
                              angular.toJson(error)
                          );
                          $rootScope.alerts.push({
                            type: "error",
                            text: "Unable to get users of role",
                          });
                          $state.go("app.dealer.personnel_custom_roles_list", {
                            dealer_id: UserService.dealerInfo.id,
                          });
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    console.error(
                      "CustomRolesEditCtrl->init() - Error getting role: " +
                        angular.toJson(error)
                    );
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Unable to get role",
                    });
                    $state.go("app.dealer.personnel_custom_roles_list", {
                      dealer_id: UserService.dealerInfo.id,
                    });
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            }
          },
          function (error) {
            console.error(
              "CustomRolesEditCtrl->init() - Error getting personnel: " +
                angular.toJson(error)
            );
            $state.go("app.dealer.personnel_custom_roles_list", {
              dealer_id: UserService.dealerInfo.id,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    init();
  },
]);
