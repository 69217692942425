/**
 *
 * StatusIndicators
 * @author Chad Watson
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LoadingSpinner from "../LoadingSpinner";
import { RadialAlertIcon, RadialCheckIcon, AnimatedCycleIcon } from "../Icons";
import Indicator from "./Indicator";
export { default as Indicator } from "./Indicator";

const StyledRadialAlertIcon = styled(RadialAlertIcon)`
  color: ${({ theme }) => theme.warning};
`;

const GreenRadialCheckIcon = styled(RadialCheckIcon)`
  color: ${({ theme }) => theme.success};
`;

const LightRadialCheckIcon = styled(RadialCheckIcon)`
  color: ${({ theme }) => theme.pending};
`;

const STATUSES = {
  ERROR: "error",
  LOADING: "loading",
  SUCCESS: "success",
  SUCCESS_PENDING: "successPending",
  FALLBACK: "fallback",
  RELOADING: "reloading",
};

const ReloadingIcon = () => <AnimatedCycleIcon animated />;

const STATUS_ICONS = {
  [STATUSES.ERROR]: StyledRadialAlertIcon,
  [STATUSES.LOADING]: LoadingSpinner,
  [STATUSES.SUCCESS]: GreenRadialCheckIcon,
  [STATUSES.SUCCESS_PENDING]: LightRadialCheckIcon,
  [STATUSES.RELOADING]: ReloadingIcon,
};

const Indicators = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const getIndicators = (props) =>
  Object.keys(props)
    .filter((key) => !!props[key] && !!STATUS_ICONS[key])
    .map((key) => (
      <Indicator
        key={key}
        icon={STATUS_ICONS[key]}
        message={
          React.isValidElement(props[key]) || typeof props[key] === "string"
            ? props[key]
            : null
        }
      />
    ));

/** @type {(props: {
  className?: string;
  message?: React.ReactNode;
  error?: boolean;
  loading?: boolean;
  success?: boolean;
  successPending?: boolean;
  fallback?: boolean;
  reloading?: boolean;
}) => JSX.Element} */
const StatusIndicators = ({ className, message, ...rest }) => {
  const indicators = getIndicators(rest);

  return (
    <Indicators className={className}>
      {!!indicators.length && indicators}
      {!indicators.length && rest[STATUSES.FALLBACK] && (
        <Indicator icon={rest[STATUSES.FALLBACK]} message={message} />
      )}
    </Indicators>
  );
};

StatusIndicators.propTypes = {
  ...Object.keys(STATUSES).reduce(
    (propTypes, key) => ({
      ...propTypes,
      [key]: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    }),
    {}
  ),
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default StatusIndicators;
