App.factory("PaymentServiceAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.paymentServiceApiUrl + "/api/v1/dealers/:dealer_id",
      {},
      {
        show: { method: "GET", params: { dealer_id: "@dealer_id" } },
      }
    );
  },
]);
