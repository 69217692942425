import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { PanelHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";

export const resolveStatusListDisplayRules = (
  hardwareModel: PanelHardwareModel
) => {
  const { isXr550, isXr350 } = resolvePanelType(hardwareModel);
  const inlineHelp =
    isXr550 || isXr350
      ? "1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16"
      : "1, 2, 3, 4, 5, 6, 7, 8";
  const validationMessage =
    isXr550 || isXr350
      ? "Valid values are any combination of 1-16."
      : "Valid values are any combination of 1-8.";
  const pattern =
    isXr550 || isXr350
      ? "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})"
      : "([1-8]([\\,\\-][1-8]){0,7})";

  return {
    inlineHelp,
    validationMessage,
    pattern,
  };
};
