/**
 * @generated SignedSource<<8e7bfe4cc2d9f22ca660e03cae8b047e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTZoneInformationsFields_zone$data = {
  readonly id: string;
  readonly number: string;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationAreaField_zone" | "ZoneInformationArmAreasField_zone" | "ZoneInformationArmedOpenMessageField_zone" | "ZoneInformationArmedOpenOutputActionField_zone" | "ZoneInformationArmedOpenOutputNumberField_zone" | "ZoneInformationArmedShortMessageField_zone" | "ZoneInformationArmedShortOutputActionField_zone" | "ZoneInformationArmedShortOutputNumberField_zone" | "ZoneInformationArmingStyleField_zone" | "ZoneInformationChimeSoundField_zone" | "ZoneInformationCompetitorWirelessField_zone" | "ZoneInformationContactField_zone" | "ZoneInformationContactNormallyOpenField_zone" | "ZoneInformationContext_zone" | "ZoneInformationCrossZoneField_zone" | "ZoneInformationDMPWirelessField_zone" | "ZoneInformationDisarmDisableField_zone" | "ZoneInformationDisarmedOpenMessageField_zone" | "ZoneInformationDisarmedOpenOutputActionField_zone" | "ZoneInformationDisarmedOpenOutputNumberField_zone" | "ZoneInformationDisarmedShortMessageField_zone" | "ZoneInformationDisarmedShortOutputActionField_zone" | "ZoneInformationDisarmedShortOutputNumberField_zone" | "ZoneInformationEntryDelayField_zone" | "ZoneInformationExpanderSerialNumberField_zone" | "ZoneInformationLEDOperationField_zone" | "ZoneInformationNameField_zone" | "ZoneInformationNumberField_zone" | "ZoneInformationPIRPetImmunityField_zone" | "ZoneInformationPIRPulseCountField_zone" | "ZoneInformationPIRSensitivityField_zone" | "ZoneInformationPresignalKeypadsField_zone" | "ZoneInformationPrewarnKeypadsField_zone" | "ZoneInformationPriorityZoneField_zone" | "ZoneInformationReceiverRoutingField_zone" | "ZoneInformationSensorTypeField_zone" | "ZoneInformationSerialNumberField_zone" | "ZoneInformationSupervisionTimeField_zone" | "ZoneInformationSwingerBypassField_zone" | "ZoneInformationTamperField_zone" | "ZoneInformationTrafficCountField_zone" | "ZoneInformationTypeField_zone" | "ZoneInformationZoneAuditDaysField_zone">;
  readonly " $fragmentType": "XTZoneInformationsFields_zone";
};
export type XTZoneInformationsFields_zone$key = {
  readonly " $data"?: XTZoneInformationsFields_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTZoneInformationsFields_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTZoneInformationsFields_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationContext_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationNameField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationTypeField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationAreaField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationChimeSoundField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSensorTypeField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationExpanderSerialNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDMPWirelessField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSerialNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationContactField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationContactNormallyOpenField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSupervisionTimeField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationLEDOperationField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPIRPulseCountField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPIRSensitivityField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPIRPetImmunityField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPrewarnKeypadsField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPresignalKeypadsField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationSwingerBypassField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationCrossZoneField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationEntryDelayField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationPriorityZoneField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationTrafficCountField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationTamperField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationZoneAuditDaysField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationReceiverRoutingField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmAreasField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedOpenMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedOpenOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedOpenOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedShortMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedShortOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmedShortOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedOpenOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortMessageField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortOutputNumberField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmedShortOutputActionField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationArmingStyleField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationDisarmDisableField_zone"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZoneInformationCompetitorWirelessField_zone"
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "974662e9c54f16d2941c0e2e113ed3e6";

export default node;
