import graphql from "babel-plugin-relay/macro";
import { BaseButton } from "common/components/web/Button";
import Modal from "common/components/web/Modal";
import { Flex, Form } from "components/DaStyledElements";
import { ErrorMsg } from "components/DaStyledElements/Form";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useMutation } from "react-relay";
import { react2angular } from "react2angular";
import { ClientApplication } from "securecom-graphql/client";
import styled from "styled-components";
import { ChangePasswordFormReducer } from "./ChangePasswordReducer";
import { ChangePasswordModalMutation } from "./__generated__/ChangePasswordModalMutation.graphql";

const BaseButtonPrimary = styled(BaseButton)`
  color: var(--color-primary-600);
  background-color: #def1f7;
  border-color: var(--color-primary-500);
  &:hover {
    color: var(--color-primary-600);
  }
`;

export const ChangePasswordModal: React.FC<React.PropsWithChildren<any>> = ({
  onCancel,
  UserService,
  RelayService,
  forceUpdate,
}) => {
  const [state, dispatch] = React.useReducer(ChangePasswordFormReducer, {
    currentPassword: "",
    showCurrentPasswordError: false,
    newPassword: "",
    showNewPasswordError: false,
    confirmPassword: "",
    showConfirmPasswordError: false,
    passwordsDontMatch: false,
    currentPasswordVisibility: false,
    newPasswordVisibility: false,
    changePasswordError: "",
  });
  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    React.useState(false);

  const showAlert = useShowAlert();

  const [makeChangePasswordModalMutation] =
    useMutation<ChangePasswordModalMutation>(
      graphql`
        mutation ChangePasswordModalMutation(
          $oldPassword: String!
          $newPassword: String!
          $clientApplication: ClientApplication!
          $isV4Login: Boolean
        ) {
          changePassword(
            oldPassword: $oldPassword
            newPassword: $newPassword
            clientApplication: $clientApplication
            isV4Login: $isV4Login
          ) {
            __typename
            ... on ChangePasswordSuccessResponseWithBearerToken {
              users {
                bearerToken
                refreshToken
                user {
                  email
                  accessibleType
                  ... on DealerUser {
                    role
                  }
                }
              }
              __typename
            }
            ... on ChangePasswordSuccessResponse {
              users {
                authToken
                user {
                  email
                }
              }
              __typename
            }
            ... on ChangePasswordErrorResponse {
              error {
                __typename
              }
              __typename
            }
          }
        }
      `
    );

  return (
    <>
      <button
        className="line-item-button "
        onClick={() => setChangePasswordModalOpen(true)}
      >
        Change Password
      </button>
      {changePasswordModalOpen && (
        <Modal size={"medium"} backdrop={true} animate={true}>
          <Modal.Header>
            <h1 className="printed-concept-heading">Change Password</h1>
          </Modal.Header>
          <form>
            <Modal.Body>
              {/* CURRENT PASSWORD */}
              <Flex.Row>
                <Flex.Col size={1}>
                  <Form.PasswordRevealGroup
                    className={`required`}
                    setInputStyle={() =>
                      dispatch({
                        type: "TOGGLE_CURRENT_PASSWORD_VISIBILITY",
                      })
                    }
                    eyeIsVisible={!!state.currentPassword}
                  >
                    <Form.Label htmlFor={"currentPassword"}>
                      Current Password
                    </Form.Label>
                    <input
                      name="currentPassword"
                      id="currentPassword"
                      value={state.currentPassword}
                      type={
                        state.currentPasswordVisibility ? "text" : "password"
                      }
                      className="form-control"
                      onBlur={(event) =>
                        dispatch({
                          type: "CURRENT_PASSWORD_BLURRED",
                          value: true,
                        })
                      }
                      onFocus={(event) =>
                        dispatch({
                          type: "CURRENT_PASSWORD_FOCUSED",
                          value: false,
                        })
                      }
                      onChange={(event) =>
                        dispatch({
                          type: "CURRENT_PASSWORD_CHANGED",
                          value: event.target.value,
                        })
                      }
                      required
                    />
                    {state.showCurrentPasswordError &&
                      !state.currentPassword && (
                        <ErrorMsg>Current password is required.</ErrorMsg>
                      )}
                  </Form.PasswordRevealGroup>
                </Flex.Col>
              </Flex.Row>
              {/* NEW PASSWORD */}
              <Flex.Row>
                <Flex.Col size={1}>
                  <Form.PasswordRevealGroup
                    className={`required`}
                    showPassword={state.newPasswordVisibility}
                    setInputStyle={() =>
                      dispatch({
                        type: "TOGGLE_NEW_PASSWORD_VISIBILITY",
                      })
                    }
                    eyeIsVisible={!!state.newPassword}
                  >
                    <Form.Label htmlFor="newPassword">New Password</Form.Label>
                    <input
                      type={state.newPasswordVisibility ? "text" : "password"}
                      className="form-control"
                      name="newPassword"
                      id="newPassword"
                      value={state.newPassword}
                      onBlur={(event) =>
                        dispatch({
                          type: "NEW_PASSWORD_BLURRED",
                        })
                      }
                      onFocus={(event) =>
                        dispatch({
                          type: "NEW_PASSWORD_FOCUSED",
                        })
                      }
                      onChange={(event) => {
                        dispatch({
                          type: "NEW_PASSWORD_CHANGED",
                          value: event.target.value,
                        });
                      }}
                      required
                    />
                    {state.showNewPasswordError && !state.newPassword && (
                      <ErrorMsg>New password is required.</ErrorMsg>
                    )}
                    {state.confirmPassword !== state.newPassword && (
                      <ErrorMsg>Passwords do not match.</ErrorMsg>
                    )}
                  </Form.PasswordRevealGroup>
                </Flex.Col>
              </Flex.Row>
              {/* CONFIRM NEW PASSWORD */}
              <Flex.Row>
                <Flex.Col size={1}>
                  <Form.PasswordRevealGroup
                    className={`required`}
                    showPassword={state.newPasswordVisibility}
                    setInputStyle={() => {
                      dispatch({
                        type: "TOGGLE_NEW_PASSWORD_VISIBILITY",
                      });
                    }}
                    eyeIsVisible={!!state.confirmPassword}
                  >
                    <Form.Label htmlFor={"confirmPassword"}>
                      New Password Confirmation
                    </Form.Label>
                    <input
                      name="confirmPassword"
                      type={state.newPasswordVisibility ? "text" : "password"}
                      id="confirmPassword"
                      value={state.confirmPassword}
                      className="form-control"
                      onBlur={(event) => {
                        dispatch({
                          type: "CONFIRM_PASSWORD_BLURRED",
                          value: true,
                        });
                      }}
                      onFocus={(event) => {
                        dispatch({
                          type: "CONFIRM_PASSWORD_BLURRED",
                          value: false,
                        });
                      }}
                      onChange={(event) => {
                        dispatch({
                          type: "CONFIRM_PASSWORD_CHANGED",
                          value: event.target.value,
                        });
                      }}
                      required
                    />
                    {state.showConfirmPasswordError &&
                      !state.confirmPassword && (
                        <ErrorMsg>Confirm password is required.</ErrorMsg>
                      )}
                    {state.confirmPassword !== state.newPassword && (
                      <ErrorMsg>Passwords do not match.</ErrorMsg>
                    )}
                    {state.changePasswordError && (
                      <ErrorMsg>{state.changePasswordError}</ErrorMsg>
                    )}
                  </Form.PasswordRevealGroup>
                </Flex.Col>
              </Flex.Row>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-sm-12">
                <BaseButton
                  className="btn btn-default mar-r-8"
                  onClick={() => {
                    setChangePasswordModalOpen(false);
                    dispatch({ type: "CHANGE_PASSWORD_MODAL_CLOSED" });
                  }}
                >
                  Cancel
                </BaseButton>
                <BaseButtonPrimary
                  type="button"
                  disabled={
                    !!state.currentPassword &&
                    !!state.newPassword &&
                    !!state.confirmPassword &&
                    state.confirmPassword !== state.newPassword
                  }
                  onClick={() => {
                    makeChangePasswordModalMutation({
                      variables: {
                        oldPassword: state.currentPassword,
                        newPassword: state.newPassword,
                        clientApplication: ClientApplication.DEALER_ADMIN,
                        isV4Login: true,
                      },
                      onCompleted: (data) => {
                        if ("users" in data.changePassword) {
                          const newLoggedInUserData =
                            data.changePassword.users.map((user) => {
                              if (user?.user.email === UserService.email)
                                return user;
                            });
                          UserService.login(
                            newLoggedInUserData[0]?.user.email,
                            newLoggedInUserData[0] &&
                              "bearerToken" in newLoggedInUserData[0]
                              ? newLoggedInUserData[0]?.bearerToken
                              : newLoggedInUserData[0]?.authToken,
                            newLoggedInUserData[0] &&
                              "refreshToken" in newLoggedInUserData[0]
                              ? newLoggedInUserData[0]?.refreshToken
                              : ""
                          );
                          forceUpdate();
                          dispatch({ type: "CHANGE_PASSWORD_MODAL_CLOSED" });
                          setChangePasswordModalOpen(false);
                          showAlert({
                            type: "success",
                            text: "Password Changed",
                            json: "",
                          });
                        }
                        if ("error" in data.changePassword) {
                          dispatch({
                            type: "CHANGE_PASSWORD_ERRORED",
                            error: data.changePassword.error?.__typename ?? "",
                          });
                        }
                      },
                      onError: (error) => {
                        dispatch({
                          type: "CHANGE_PASSWORD_ERRORED",
                          error: error.message ?? "",
                        });
                      },
                    });
                  }}
                >
                  Submit
                </BaseButtonPrimary>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
};

export function dangerouslyAddToApp() {
  App.component(
    "changePasswordModal",
    react2angular(
      ChangePasswordModal,
      ["onCancel"],
      ["$rootScope", "$state", "$scope", "UserService", "RelayService"]
    )
  );
}
