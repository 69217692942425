/**
 * @generated SignedSource<<abfc0412b23f2a9629104eee378c1d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRKeyFobsProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly id: string;
    readonly keyfobs: ReadonlyArray<{
      readonly __typename: "Keyfob";
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XRKeyFobsProgrammingConceptFormInline_keyfob">;
    }>;
  };
  readonly " $fragmentType": "XRKeyFobsProgrammingConceptFormInline_controlSystem";
};
export type XRKeyFobsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRKeyFobsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRKeyFobsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRKeyFobsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "da551b5e9301ba2df7f2595e59d9b898";

export default node;
