/**
 * @generated SignedSource<<2c27aed777f7f908157d9b736f469949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsZoneRetardDelayField_systemOptions$data = {
  readonly zoneRetardDelay?: number;
  readonly zoneRetardDelayMax?: number;
  readonly zoneRetardDelayMin?: number | null;
  readonly " $fragmentType": "SystemOptionsZoneRetardDelayField_systemOptions";
};
export type SystemOptionsZoneRetardDelayField_systemOptions$key = {
  readonly " $data"?: SystemOptionsZoneRetardDelayField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsZoneRetardDelayField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zoneRetardDelay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zoneRetardDelayMin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zoneRetardDelayMax",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsZoneRetardDelayField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "fc0bce3635b4954ea4be9ae9c86d5b82";

export default node;
