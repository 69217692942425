App.factory("ArmingAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:system_id/:action",
      {},
      {
        armSystem: {
          method: "POST",
          params: { system_id: "@system_id", action: "arm" },
        },
        disarmSystem: {
          method: "POST",
          params: { system_id: "@system_id", action: "disarm" },
        },
        getArmedStatus: {
          method: "POST",
          params: { system_id: "@system_id", action: "armed_status" },
        },
      }
    );
  },
]);
