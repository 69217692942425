/**
 * @generated SignedSource<<a91f6ee86c493e9aca5c21cea9caae40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationNameField_deviceInformation$data = {
  readonly name: string;
  readonly number: number;
  readonly " $fragmentType": "DeviceInformationNameField_deviceInformation";
};
export type DeviceInformationNameField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationNameField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationNameField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationNameField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "577f28ab09ec05cd3bc55f5fe73a3f09";

export default node;
