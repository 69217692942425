import DefaultPopUpMenu from "common/components/web/PopUpMenu";
import * as React from "react";
import { TooltipThemeProvider } from "../Tooltip";

export default function PopUpMenu(
  props: React.ComponentProps<typeof DefaultPopUpMenu>
) {
  return (
    <TooltipThemeProvider>
      <DefaultPopUpMenu {...props} baseFontSize="1.6rem" zIndex={200} />
    </TooltipThemeProvider>
  );
}
