import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import { ifNotNumeric } from "common/utils/universal/numbers";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { range } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { usePanelFragment } from "../../PanelContext";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useIsAreaSystem } from "../../SystemOptionsFields/SystemOptionsContext";
import { areaArmedOutputNumberFieldId } from "./AreaArmedOutputNumberField";
import { areaArmFirstAreaFieldId } from "./AreaArmFirstAreaField";
import { areaAutoArmFieldId } from "./AreaAutoArmField";
import { areaAutoDisarmFieldId } from "./AreaAutoDisarmField";
import { areaBadZonesFieldId } from "./AreaBadZonesField";
import { areaBankSafeVaultFieldId } from "./AreaBankSafeVaultField";
import { areaBurglaryBellOutputNumberFieldId } from "./AreaBurglaryBellOutputNumberField";
import { areaCardPlusPinFieldId } from "./AreaCardPlusPinField";
import { areaCommonAreaFieldId } from "./AreaCommonAreaField";
import { useAreaFragment } from "./AreaContext";
import { areaDualAuthorityFieldId } from "./AreaDualAuthorityField";
import { areaLateArmDelayFieldId } from "./AreaLateArmDelayField";
import { areaLateOutputNumberFieldId } from "./AreaLateOutputNumberField";
import { areaNameFieldId } from "./AreaNameField";
import { areaOpenClosingReportsFieldId } from "./AreaOpenClosingReportsField";
import { areaTwoManRuleFieldId } from "./AreaTwoManRuleField";
import { AreaNumberField_area$key } from "./__generated__/AreaNumberField_area.graphql";
import { AreaNumberField_panel$key } from "./__generated__/AreaNumberField_panel.graphql";

export const areaListItemTemplateId = (number: string) => `area-${number}`;
export const AREA_NUMBER = "area-number-";
export const areaNumberFieldId = (number: string) => AREA_NUMBER + number;
export const AREA_IDS = [
  areaListItemTemplateId,
  areaNumberFieldId,
  areaAutoArmFieldId,
  areaAutoDisarmFieldId,
  areaNameFieldId,
  areaBadZonesFieldId,
  areaArmedOutputNumberFieldId,
  areaLateOutputNumberFieldId,
  areaLateArmDelayFieldId,
  areaBankSafeVaultFieldId,
  areaCommonAreaFieldId,
  areaArmFirstAreaFieldId,
  areaTwoManRuleFieldId,
  areaDualAuthorityFieldId,
  areaOpenClosingReportsFieldId,
  areaBurglaryBellOutputNumberFieldId,
  areaCardPlusPinFieldId,
];

function AreaNumberField() {
  const [{ id, number, isNew }, updateArea] =
    useAreaFragment<AreaNumberField_area$key>(
      graphql`
        fragment AreaNumberField_area on Area {
          id
          number
          isNew
        }
      `
    );

  const [
    {
      areas,
      areas: { maxNumberOfAreas },
    },
  ] = usePanelFragment<AreaNumberField_panel$key>(graphql`
    fragment AreaNumberField_panel on Panel {
      areas(first: 32, sort: { keys: ["number"], order: ASC }) {
        __id
        maxNumberOfAreas
        edges {
          node {
            id
            number
          }
        }
      }
    }
  `);

  const fieldId = areaNumberFieldId(number);
  const isAreaSystem = useIsAreaSystem();
  const relayEnv = useRelayEnvironment();
  const disabled = !isAreaSystem || (isAreaSystem && !isNew);
  const label = isAreaSystem ? "Area Number" : "Area";
  const inlineHelp = `1 - ${maxNumberOfAreas}`;

  const duplicateAreasExist =
    areas.edges.filter(({ node }) => node?.number === number).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      AREA_IDS,
      duplicateAreasExist,
      number,
      areaListItemTemplateId
    );

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={label}>
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={emptyStringOrNumber(number)}
        required
        disabled={disabled}
        min={1}
        max={maxNumberOfAreas}
        inlineHelp={inlineHelp}
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateArea((recordProxy) => {
            recordProxy.setValue(target.value, "number");
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate area numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = Number(number);
          relayEnv.commitUpdate((store) => {
            const currentArea = store.get(id);
            if (currentArea) {
              const areaRecords = areas.edges;
              if (areaRecords) {
                const takenNumbers = new Set(
                  areaRecords.map(({ node }) =>
                    node?.id !== id
                      ? ifNotNumeric(0, node?.number).toString()
                      : "0"
                  )
                );

                const valueAsNumber = Number(target.value);
                const value = closest(
                  !target.value || isNaN(valueAsNumber) ? 1 : valueAsNumber,
                  range(1, maxNumberOfAreas + 1).filter(
                    (num) => !takenNumbers.has(num.toString())
                  )
                );
                currentArea.setValue(value.toString(), "number");
                blurredNumber = value;
              }
              onBlurNumberUpdate(blurredNumber.toString());
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaNumberField;
