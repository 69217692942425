/**
 * @ngdoc api
 * @name App.factory:DealerModemApi
 *
 * @description
 * API factory for Dealer Modem reporting
 *
 */

App.factory("DealerModemApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/Modems/List/:dealer_id",
      {},
      {
        getModems: { method: "GET", params: {}, isArray: true },
      }
    );
  },
]);

App.factory("DealerModemSunsetApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/Modems/Dealers/:dealer_id/SunsetModemsCount",
      {},
      {
        getModems: { method: "GET", params: {}, isArray: false },
      }
    );
  },
]);
