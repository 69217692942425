/**** Connection Information Component ****/

/*
 *  Similar to the panelstatistics page on the AngularJS side, this component uses
 *  the panelstatistics API (in graphql) to get various connection information with dates.
 *  The list of objects returned is then used to generate a google chart column graph.
 */

import graphql from "babel-plugin-relay/macro";
import React from "react";
import Chart from "react-google-charts";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import DiagnosticGraphButtonBar from "./DiagnosticGraphButtonBar";
import { GraphTitle, PanelStat } from "./utils";
import {
  ConnectionInformationStatisticsQuery,
  ConnectionInformationStatisticsQuery$data,
} from "./__generated__/ConnectionInformationStatisticsQuery.graphql";

const statsQuery = graphql`
  query ConnectionInformationStatisticsQuery($panelId: Int!) {
    systemDiagnosticsPanelStatistics(panelId: $panelId) {
      reportedAt
      statId
      acVoltage
      dcVoltage
      currentCellSignal
      strongestCellSignal
      weakestCellSignal
      cellRetries
      cellRoaming
      networkRetries
      maxCount
      actualCount
      pathNumber
      connectionType
      status
      cellTowerId
      originalSimIdentifier
      newSimIdentifier
      deviceType
    }
  }
`;

const DateRangeLink = styled.button`
  margin-left: 20px;
  text-decoration: ${(props) => props.value || "none"} !important;
`;

// converts our selected stat name to a string for the unit type (we'll keep the chart title here too)
const vTitle: GraphTitle = {
  acVoltage: { unit: "Voltage (V)", title: "Primary Voltage" },
  dcVoltage: { unit: "Voltage (V)", title: "Battery Voltage" },
  currentCellSignal: { unit: "Signal (dBM)", title: "Current Cell Signal" },
  strongestCellSignal: { unit: "Signal (dBM)", title: "Best Cell Signal" },
  weakestCellSignal: { unit: "Signal (dBM)", title: "Worst Cell Signal" },
  cellRetries: { unit: "Retries", title: "Cell Retries" },
  networkRetries: { unit: "Retries", title: "Network Retries" },
};

// these two values are used to block off days from the datepicker that aren't in the data
function getCalendarMin(data: ConnectionInformationStatisticsQuery$data) {
  return data.systemDiagnosticsPanelStatistics.length > 0
    ? new Date(data.systemDiagnosticsPanelStatistics[0].reportedAt)
        .toISOString()
        .slice(0, 10)
    : "";
}

function getCalendarMax(data: ConnectionInformationStatisticsQuery$data) {
  return data.systemDiagnosticsPanelStatistics.length > 0
    ? new Date(
        data.systemDiagnosticsPanelStatistics[
          data.systemDiagnosticsPanelStatistics.length - 1
        ].reportedAt
      )
        .toISOString()
        .slice(0, 10)
    : "";
}

function ConnectionInformation({ inputPanelId }: { inputPanelId: number }) {
  const data = useLazyLoadQuery<ConnectionInformationStatisticsQuery>(
    statsQuery,
    {
      panelId: inputPanelId,
    }
  );

  // these values are for the date range displayed in the chart
  // the date range, with a default of 7, is the amount of days we show in the chart
  const [dateRange, setDateRange] = React.useState(7);

  const calendarMin = getCalendarMin(data);
  const calendarMax = getCalendarMax(data);

  // max date uses the last element of the sorted stats array to pull the date and converts it to a
  // format that the datepicker can use
  const [maxDate, setMaxDate] = React.useState(
    new Date(calendarMax + "T23:59:00")
  );

  // min date takes the max date, duplicates it, then subtracts the date range specified
  // this gives us a lower bound to display on the graph
  const minDate = new Date(maxDate);
  minDate.setDate(minDate.getDate() - dateRange);

  // statToDisplay is which field from the statistics API we want to show in the graph
  const [statToDisplay, setStatToDisplay] = React.useState<string>("acVoltage");

  // using the object above, we can build out the graph data here based on the selected
  // field; this prevents us from needing to use something like a switch case in displaying the chart
  const displayData: (number | Date | string | boolean)[][] = [
    ["Reported", vTitle[statToDisplay].unit] as (
      | number
      | Date
      | string
      | boolean
    )[],
  ].concat(
    data.systemDiagnosticsPanelStatistics.map((stats) => [
      new Date(stats.reportedAt),
      (stats as PanelStat)[statToDisplay] || 0,
    ])
  );

  return (
    <>
      {data.systemDiagnosticsPanelStatistics.length == 0 && (
        <p style={{ textAlign: "center" }}>
          Couldn't find any statistics for this panel.
        </p>
      )}
      {data.systemDiagnosticsPanelStatistics.length > 0 && (
        <>
          <DateRangeLink
            onClick={() => setDateRange(1)}
            className="link link-primary color-primary-600"
            value={dateRange == 1 ? "underline" : "none"}
          >
            Day
          </DateRangeLink>
          <DateRangeLink
            onClick={() => setDateRange(7)}
            className="link link-primary color-primary-600"
            value={dateRange == 7 ? "underline" : "none"}
          >
            Week
          </DateRangeLink>
          <DateRangeLink
            onClick={() => setDateRange(30)}
            className="link link-primary color-primary-600"
            value={dateRange == 30 ? "underline" : "none"}
          >
            Month
          </DateRangeLink>
          <br />
          <span
            style={{ marginLeft: "20px", marginTop: "10px", display: "block" }}
          >
            Ending on:
            <input
              type="date"
              style={{ marginLeft: "10px" }}
              value={maxDate.toLocaleDateString("en-CA")}
              onChange={(e) => {
                setMaxDate(new Date(Date.parse(e.target.value + "T23:59:00")));
              }}
              min={calendarMin}
              max={calendarMax}
            />
          </span>
          <Chart
            chartType="ColumnChart"
            height="20em"
            width="100%"
            options={{
              title: vTitle[statToDisplay].title,
              hAxis: {
                title: "Date",
                viewWindow: {
                  min: minDate,
                  max: maxDate,
                },
              },
              vAxis: {
                title: vTitle[statToDisplay].unit,
              },
            }}
            data={displayData}
          />
          <DiagnosticGraphButtonBar
            title={vTitle}
            statToDisplay={statToDisplay}
            setStatToDisplay={setStatToDisplay}
            dataEntrySample={data.systemDiagnosticsPanelStatistics[0]}
          />
        </>
      )}
    </>
  );
}

export default ConnectionInformation;
