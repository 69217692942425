/**
 * @generated SignedSource<<0e16f5e24a85b667424df812a7c6066a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KeyfobNumberField_keyfob$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly number: number;
  readonly " $fragmentType": "KeyfobNumberField_keyfob";
};
export type KeyfobNumberField_keyfob$key = {
  readonly " $data"?: KeyfobNumberField_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyfobNumberField_keyfob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyfobNumberField_keyfob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNew",
      "storageKey": null
    }
  ],
  "type": "Keyfob",
  "abstractKey": null
};

(node as any).hash = "4b230aeb97ce9b05aae9d66cd3b0088d";

export default node;
