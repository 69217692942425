/**
 * @ngdoc service
 * @name App.factory:CustomerUsersV2API
 *
 * @description
 * API factory for creating users that belong to a specific customer (app users).
 *
 */
App.factory("CustomerUsersV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/customers/:customer_id/:users/:new",
      {},
      {
        new: {
          method: "GET",
          params: { customer_id: "@customer_id", users: "users", new: "new" },
        },
        create: {
          method: "POST",
          params: { customer_id: "@customer_id", users: "users" },
        },
      }
    );
  },
]);
