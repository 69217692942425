import * as React from "react";
import PutSystemOnTestModal from "./PutSystemOnTestModal";

const PutSystemOnTestButton: React.FC<
  React.PropsWithChildren<{
    systemId: string;
  }>
> = ({ systemId }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <button className="btn btn-sm btn-dmp" onClick={() => setModalOpen(true)}>
        Put System On Test
      </button>
      {modalOpen && (
        <PutSystemOnTestModal
          systemId={systemId}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default PutSystemOnTestButton;
