/**
 * @generated SignedSource<<919991e51329ad8793f79c4671d7c19a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly firstApn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "3e4e5633f68cd43e25a7c036f0560033";

export default node;
