import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationOutputGroupField_deviceInformation$key } from "./__generated__/DeviceInformationOutputGroupField_deviceInformation.graphql";

export const deviceInformationOutputGroupFieldId = (number: string) =>
  `device-information-output-group-${number}`;

function DeviceInformationOutputGroupField() {
  const [
    { number, triggerOutputGroupWithAccess, deviceType },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationOutputGroupField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationOutputGroupField_deviceInformation on DeviceInformation {
        number
        ... on XrDeviceInformation {
          triggerOutputGroupWithAccess
          deviceType
        }
      }
    `
  );

  const fieldId = deviceInformationOutputGroupFieldId(String(number));
  const label = "Output Group";
  const disabled = deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Allows the output group (relays) assigned to the User Profile to turn on when the device relay is activated for the programmed strike time."
    >
      <Switch
        label={label}
        id={fieldId}
        disabled={disabled}
        checked={triggerOutputGroupWithAccess}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              !triggerOutputGroupWithAccess,
              "triggerOutputGroupWithAccess"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationOutputGroupField;
