/**
 * @generated SignedSource<<e4c7bdd96a94ca610c5bf656ae55b3ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemReportsEntryCheckinProtectionField_systemReports$data = {
  readonly entryCheckinProtection: boolean;
  readonly " $fragmentType": "SystemReportsEntryCheckinProtectionField_systemReports";
};
export type SystemReportsEntryCheckinProtectionField_systemReports$key = {
  readonly " $data"?: SystemReportsEntryCheckinProtectionField_systemReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemReportsEntryCheckinProtectionField_systemReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemReportsEntryCheckinProtectionField_systemReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entryCheckinProtection",
      "storageKey": null
    }
  ],
  "type": "SystemReports",
  "abstractKey": null
};

(node as any).hash = "de7fdaded6832d3e754a9111c551107c";

export default node;
