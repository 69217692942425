/**
 * Created by kweigand on 6/12/15.
 */

App.controller("ReportDisplayController", [
  "$rootScope",
  "$scope",
  "$stateParams",
  "UserService",
  "Customer",
  "CustomerUser",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "DTColumnBuilder",
  "$state",
  "controlSystemModel",
  "$http",
  "ReportService",
  "$resource",
  "$window",
  "$filter",
  "$q",
  "DataTablesUtilsService",
  function (
    $rootScope,
    $scope,
    $stateParams,
    UserService,
    Customer,
    CustomerUser,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    DTColumnBuilder,
    $state,
    controlSystemModel,
    $http,
    ReportService,
    $resource,
    $window,
    $filter,
    $q,
    DataTablesUtilsService
  ) {
    var reportDisplayCtrl = this; //ControllerAs

    if (!ReportService.filters) {
      $state.go("app.report-gen");
    }

    UserService.customer_id = $stateParams.customer_id;
    UserService.control_system_id = $stateParams.control_system_id;
    UserService.panel_id = $stateParams.panel_id;
    $scope.reportType = $stateParams.report_type;
    $scope.hardware_family =
      UserService.controlSystem.panels[0].hardware_family;
    $scope.dataFailed = false;
    $scope.hardware_model = UserService.controlSystem.panels[0].hardware_model;

    var getUsersReport = function () {
      var deferred = $q.defer();
      ReportService.getUsersReport()
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to Retrieve Report Data.  Please try refining your search criteria or report this issue to support.",
              json: error,
            });
            $scope.dataFailed = true;
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    var getEventsReport = function () {
      var deferred = $q.defer();
      ReportService.getEventsReport(UserService.dealerInfo.id)
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to Retrieve Report Data.  Please try refining your search criteria or report this issue to support.",
              json: error,
            });
            $scope.dataFailed = true;
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    var getProfilesReport = function () {
      var deferred = $q.defer();
      ReportService.getProfilesReport()
        .then(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to Retrieve Report Data.  Please try refining your search criteria or report this issue to support.",
              json: error,
            });
            $scope.dataFailed = true;
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    // selected report type creating matching table
    switch ($scope.reportType) {
      case "UsersReport":
        reportDisplayCtrl.dtOptions = DataTablesUtilsService.getDTOptions(
          getUsersReport(),
          "Users"
        );
        break;
      case "EventsReport":
        reportDisplayCtrl.dtOptions = DataTablesUtilsService.getDTOptions(
          getEventsReport(),
          "Events"
        );
        break;
      case "ProfilesReport":
        reportDisplayCtrl.dtOptions = DataTablesUtilsService.getDTOptions(
          getProfilesReport(),
          "Profiles"
        );
        break;
      default:
        break;
    }

    console.clear();

    reportDisplayCtrl.selectedReport = $scope.reportType;

    //Removes "Report" from the reportType so it can be used in the title.
    reportDisplayCtrl.ReportTitle = reportDisplayCtrl.selectedReport.replace(
      "Report",
      " "
    );

    reportDisplayCtrl.myButtonClick = reportDisplayCtrl.reportData;

    if (reportDisplayCtrl.selectedReport === "UsersReport") {
      if (
        $scope.hardware_family === "XR550" &&
        $scope.hardware_model !== "XT75"
      ) {
        reportDisplayCtrl.dtColumns = [
          DTColumnBuilder.newColumn("record_number")
            .withTitle("User Number")
            .withOption("aDataSort", [0, 1])
            .withOption("width", "10%")
            .withOption("sClass", "align-right-table"),
          DTColumnBuilder.newColumn("name")
            .withTitle("User Name")
            .withOption("width", "18%"),

          DTColumnBuilder.newColumn(null)
            .withTitle("Profile 1")
            .withOption("width", "18%")
            .renderWith(function (data, type, row) {
              return DataTablesUtilsService.dashProperty(row, "profile1_name");
            }),

          DTColumnBuilder.newColumn("profile2_name")
            .withTitle("Profile 2")
            .withOption("width", "18%")
            .renderWith(function (data, type, row) {
              return DataTablesUtilsService.dashProperty(row, "profile2_name");
            }),
          DTColumnBuilder.newColumn("profile3_name")
            .withTitle("Profile 3")
            .withOption("width", "18%")
            .renderWith(function (data, type, row) {
              return DataTablesUtilsService.dashProperty(row, "profile3_name");
            }),
          DTColumnBuilder.newColumn("profile4_name")
            .withTitle("Profile 4")
            .renderWith(function (data, type, row) {
              return DataTablesUtilsService.dashProperty(row, "profile4_name");
            }),
        ];
      } else if (
        $scope.hardware_family === "XR550" &&
        $scope.hardware_model === "XT75"
      ) {
        reportDisplayCtrl.dtColumns = [
          DTColumnBuilder.newColumn("record_number")
            .withTitle("User Number")
            .withOption("aDataSort", [0, 1])
            .withOption("width", "10%")
            .withOption("sClass", "align-right-table"),
          DTColumnBuilder.newColumn("name")
            .withTitle("User Name")
            .withOption("width", "18%"),

          DTColumnBuilder.newColumn(null)
            .withTitle("Group")
            .withOption("width", "18%")
            .renderWith(function (data, type, row) {
              return DataTablesUtilsService.dashProperty(row, "profile1_name");
            }),
        ];
      } else {
        reportDisplayCtrl.dtColumns = [
          DTColumnBuilder.newColumn("record_number")
            .withTitle("User Number")
            .withOption("aDataSort", [0, 1])
            .withOption("width", "10%")
            .withOption("sClass", "align-right-table"),
          DTColumnBuilder.newColumn("name")
            .withTitle("User Name")
            .withOption("width", "10%"),
          DTColumnBuilder.newColumn(null)
            .withTitle("User Level")
            .withOption("width", "80%")
            .renderWith(function (data, type, row) {
              return DataTablesUtilsService.dashProperty(row, "user_level");
            }),
        ];
      }
      reportDisplayCtrl.dtOptions.withOption("order", [0, "asc"]);
    } else if (reportDisplayCtrl.selectedReport === "EventsReport") {
      reportDisplayCtrl.dtColumns = [
        DTColumnBuilder.newColumn("subcategory_display_name")
          .withTitle("Event")
          .withOption("width", "30%"),
        DTColumnBuilder.newColumn("event_history_message").withTitle("Message"),
        DTColumnBuilder.newColumn(null)
          .withTitle("Event Time")
          .withOption("width", "20%")
          .renderWith(function (data, type, row) {
            if (row.event_event_at) {
              return DataTablesUtilsService.asLiteralDateTime(
                row,
                "event_event_at"
              );
            } else {
              return DataTablesUtilsService.asDateTime(row, "event_created_at");
            }
          }),
      ];
      reportDisplayCtrl.dtOptions.withOption("order", [2, "desc"]);
    } else if (reportDisplayCtrl.selectedReport === "ProfilesReport") {
      reportDisplayCtrl.dtColumns = [
        DTColumnBuilder.newColumn("name")
          .withTitle("Profile Name")
          .withOption("width", "10%"),
        DTColumnBuilder.newColumn("number")
          .withTitle("Profile Number")
          .withOption("sClass", "align-right-table")
          .withOption("width", "10%"),
        DTColumnBuilder.newColumn(null)
          .withTitle("Access Area Number")
          .withOption("width", "10%")
          .withOption("sClass", "align-right-table")
          .renderWith(function (data, type, row) {
            return DataTablesUtilsService.dashProperty(
              row,
              "access_area_number"
            );
          }),
        DTColumnBuilder.newColumn(null)
          .withTitle("Access Area Name")
          .renderWith(function (data, type, row) {
            return DataTablesUtilsService.dashProperty(row, "access_area_name");
          }),
      ];
      reportDisplayCtrl.dtOptions.withOption("order", [0, "asc"]);
    }
  },
]);
