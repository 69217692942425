import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationAnyBypassField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationAnyBypassField_systemAreaInformation.graphql";

export const systemAreaInformationAnyBypassFieldId = () =>
  `system-area-information-any-bypass`;

function SystemAreaInformationAnyBypassField() {
  const [{ anyBypass }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationAnyBypassField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationAnyBypassField_systemAreaInformation on SystemAreaInformation {
          anyBypass
        }
      `
    );

  const fieldId = systemAreaInformationAnyBypassFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Any Bypass"
      tooltip="If enabled, Zones can be Bypassed during arming without entering a User Code."
    >
      <Switch
        label="Any Bypass"
        id={fieldId}
        checked={anyBypass}
        onChange={() => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(!anyBypass, "anyBypass");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationAnyBypassField;
