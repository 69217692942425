/**
 * @generated SignedSource<<4e6bc4906b7e87a7a6f0155fb2a38f28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsPcModemField_remoteOptions$data = {
  readonly pcModem?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsPcModemField_remoteOptions";
};
export type RemoteOptionsPcModemField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsPcModemField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsPcModemField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsPcModemField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pcModem",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "3c7974268f6d2daf22bd6a209ce1672e";

export default node;
