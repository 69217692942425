/**
 * @generated SignedSource<<4e0b2346c271c0b2e9c9caf4d94bfa5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorConnectionField_remoteOptions$data = {
  readonly integratorBackupConnection?: RemoteOptionsConnectionType | null;
  readonly integratorBackupIpAddress?: string | null;
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly integratorIpAddress?: string | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorConnectionField_remoteOptions";
};
export type RemoteOptionsIntegratorConnectionField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorConnectionField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorConnectionField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorConnection",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorBackupConnection",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorIpAddress",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integratorBackupIpAddress",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorConnectionField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "dabda0ce8a1cea66fc6959ebbb2aa8dd";

export default node;
