import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { OutputSupervision } from "securecom-graphql/client";
import { useControlSystem } from "../ControlSystemContext";
import { useHardwareModel, usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputInformationFragment } from "./OutputInformationContext";
import { outputInformationNameFieldId } from "./OutputInformationNameField";
import { outputInformationOutputSupervisionFieldId } from "./OutputInformationOutputSupervisionField";
import { outputInformationRealTimeStatusFieldId } from "./OutputInformationRealTimeStatusField";
import { outputInformationTripWithPanelBellFieldId } from "./OutputInformationTripWithPanelBellField";
import {
  resolveOutputFieldRules,
  resolveOutputNumbers,
  resolveWirelessOutputNumbers,
} from "./utils";
import { OutputInformationNumberField_output$key } from "./__generated__/OutputInformationNumberField_output.graphql";
import { OutputInformationNumberField_panel$key } from "./__generated__/OutputInformationNumberField_panel.graphql";

export const outputInformationListItemTemplateId = (number: string) =>
  ` output-${number}`;
export const OUTPUT_INFORMATION_NUMBER = "output-information-number-";
export const outputInformationNumberFieldId = (number: string) =>
  OUTPUT_INFORMATION_NUMBER + number;
export const OUTPUT_INFORMATION_IDS = [
  outputInformationListItemTemplateId,
  outputInformationNumberFieldId,
  outputInformationNameFieldId,
  outputInformationOutputSupervisionFieldId,
  outputInformationTripWithPanelBellFieldId,
  outputInformationRealTimeStatusFieldId,
];

function OutputInformationNumberField() {
  const [{ id, number, serialNumber, isNew }, updateOutputInformation] =
    useOutputInformationFragment<OutputInformationNumberField_output$key>(
      graphql`
        fragment OutputInformationNumberField_output on Output {
          id
          number
          serialNumber
          isNew
        }
      `
    );
  const [{ outputInformations }] =
    usePanelFragment<OutputInformationNumberField_panel$key>(graphql`
      fragment OutputInformationNumberField_panel on Panel {
        outputInformations {
          number
        }
      }
    `);

  const duplicateOutputInformationsExist =
    outputInformations.filter(
      ({ number: outputInformationNumber }) =>
        outputInformationNumber === number
    ).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      OUTPUT_INFORMATION_IDS,
      duplicateOutputInformationsExist,
      String(number),
      outputInformationListItemTemplateId
    );

  const fieldId = outputInformationNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();
  const { NUMBER: RULES } = resolveOutputFieldRules(hardwareModel);
  const disabled = !isNew;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Output Number"
      tooltip={RULES.TOOL_TIP}
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        inlineHelp={RULES.INLINE_HELP}
        value={number}
        pattern={RULES.PATTERN}
        required
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateOutputInformation((recordProxy) => {
            const isWireless =
              resolveWirelessOutputNumbers(hardwareModel).includes(
                Number(target.value)
              ) ?? false;
            recordProxy.setValue(emptyStringOrNumber(target.value), "number");
            recordProxy.setValue(isWireless, "isWireless");
            recordProxy.setValue(
              isWireless
                ? OutputSupervision.FOUR_HOURS
                : OutputSupervision.NONE,
              "outputSupervision"
            );
            recordProxy.setValue(
              isWireless //if wireless checks if there is already a SN and keeps it if so
                ? serialNumber === "" || serialNumber === "--------"
                  ? "15"
                  : serialNumber
                : "--------",
              "serialNumber"
            );
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate zone/output/keyfob numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = number;
          relayEnv.commitUpdate((store) => {
            const controlSystemRecord = store.get(controlSystem.id);
            const isXr = controlSystemRecord?.getValue("isXr");
            const currentOutput = store.get(id);
            if (currentOutput) {
              const panelRecord = controlSystemRecord?.getLinkedRecord("panel");
              const outputInformationRecords =
                panelRecord?.getLinkedRecords("outputInformations");

              const zoneInformationConflicts = !isXr
                ? panelRecord
                    ?.getLinkedRecords("zoneInformations")
                    ?.map((zones) =>
                      [
                        31, 32, 33, 34, 41, 42, 43, 44, 51, 52, 53, 54, 61, 62,
                        63, 64,
                      ].includes(Number(zones.getValue("number")))
                        ? zones.getValue("number")?.toString()
                        : "0"
                    )
                : [];

              const keyfobConflicts = !isXr
                ? panelRecord
                    ?.getLinkedRecords("keyfobs")
                    ?.map((keyfob) => keyfob.getValue("number")?.toString())
                : [];

              if (outputInformationRecords) {
                const takenNumbers = new Set(
                  outputInformationRecords
                    .map((zone) =>
                      zone.getValue("id") !== id
                        ? zone.getValue("number")?.toString()
                        : "0"
                    )
                    .concat(zoneInformationConflicts)
                    .concat(keyfobConflicts)
                );

                const valueAsNumber = Number(target.value);
                const value = closest(
                  !target.value || isNaN(valueAsNumber) ? 1 : valueAsNumber,
                  resolveOutputNumbers(hardwareModel, true).filter(
                    (num) => !takenNumbers.has(num.toString())
                  )
                );
                currentOutput.setValue(value, "number");
                blurredNumber = value;
                const isWireless =
                  resolveWirelessOutputNumbers(hardwareModel).includes(
                    Number(value)
                  ) ?? false;
                currentOutput.setValue(isWireless, "isWireless");
                currentOutput.setValue(
                  isWireless
                    ? OutputSupervision.FOUR_HOURS
                    : OutputSupervision.NONE,
                  "outputSupervision"
                );
              }
            }
          });
          onBlurNumberUpdate(String(blurredNumber));
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputInformationNumberField;
