/**
 * @generated SignedSource<<e3cf99edb4e18654fb0dbf54da3ff086>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecurityGradeContext_securityGrade$data = {
  readonly id: string;
  readonly " $fragmentType": "SecurityGradeContext_securityGrade";
};
export type SecurityGradeContext_securityGrade$key = {
  readonly " $data"?: SecurityGradeContext_securityGrade$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecurityGradeContext_securityGrade">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecurityGradeContext_securityGrade",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SecurityGrade",
  "abstractKey": null
};

(node as any).hash = "25c2ad0d4a96fe3bcf7583d265731ac1";

export default node;
