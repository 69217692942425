import * as Utils from "./utils/";

// Currently all utils and the App are on the window object
// Over time these should be replaced to where they are no
// longer on the window scope, but for now to improve
// interoperability leaving them on the window scope.

Object.entries(Utils).forEach(([name, fn]) => {
  window[name] = fn;
});
