/**
 * @ngdoc service
 * @name App.factory:TagsAPI
 *
 * @description
 * API factory for Tags Information
 *
 */
App.factory("TagsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/DealerTags/:type/:typeId/:action/:actionOn",
      {},
      {
        getDealerTags: {
          method: "GET",
          params: {
            type: "Dealer",
            typeId: "@typeId",
          },
          isArray: true,
        },
        getPanelTags: {
          method: "GET",
          params: {
            type: "Panel",
            typeId: "@typeId",
          },
        },
        getCustomerTags: {
          method: "GET",
          params: {
            type: "Customer",
            typeId: "@typeId",
          },
          isArray: true,
        },
        getCustomRoleTags: {
          method: "GET",
          params: {
            type: "CustomRole",
            typeId: "@typeId",
          },
          isArray: true,
        },
        getCustomerRelations: {
          method: "GET",
          params: {
            type: "Dealer",
            typeId: "@dealerId",
            action: "Relations",
            actionOn: "Customers",
          },
          isArray: true,
        },
        getPanelRelations: {
          method: "GET",
          params: {
            type: "Dealer",
            typeId: "@dealerId",
            action: "Relations",
            actionOn: "Panels",
          },
          isArray: true,
        },
      }
    );
  },
]);
