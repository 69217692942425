/*
 * OnDemandTierAPI resource to access the API route for OnDemandTier
 * @param :action - DealerCharges or DealerODDefault
 * @param :type - 'dealer'
 */
App.factory("OnDemandTierAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.jobsApiUrl + "/api/v1/pod/:action/:type/:dealer_id/:id/:new",
      {},
      {
        showOnDemandPricing: {
          method: "GET",
          params: {
            type: "dealer",
            dealer_id: "@dealer_id",
            action: "DealerCharges",
          },
          isArray: true,
        },
        createOnDemandPricingTier: {
          method: "POST",
          params: { action: "DealerCharges" },
        },
        updateOnDemandPricingTier: {
          method: "PUT",
          params: { id: "@id", action: "DealerCharges" },
        },
        showCommunicationOptions: {
          method: "GET",
          params: {
            action: "DealerODDefault",
            dealer_id: "dealer_id",
            type: "dealer",
          },
        },
        updateCommunicationOptions: {
          method: "PUT",
          params: { action: "DealerODDefault", id: "@id" },
        },
        newDealerCharge: {
          method: "GET",
          params: {
            action: "DealerCharges",
            dealer_id: "@dealer_id",
            new: "new",
            type: "dealer",
          },
        },
      }
    );
  },
]);
