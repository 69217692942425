import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { areaAutoArmFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaAutoArmField";
import { areaAutoDisarmFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaAutoDisarmField";
import { areaBadZonesFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaBadZonesField";
import { areaNameFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaNameField";
import {
  areaListItemTemplateId,
  areaNumberFieldId,
} from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaNumberField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/TakeoverPanelFullProgramming/__generated__/TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  BadZonesOption,
  XtProgrammingTemplateAreaInformationInput,
} from "securecom-graphql/client";
import {
  getAreaState,
  getState as getAreasState,
} from "../../../TakeoverPanelFullProgramming/TakeoverPanelAreaInformationProgrammingConceptForm";
import { TakeoverAreaInformationTemplateDataInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TakeoverAreaInformationTemplateDataInline_takeoverProgrammingTemplateConcepts.graphql";

export const getState = (
  template: TakeoverAreaInformationTemplateDataInline_takeoverProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TakeoverAreaInformationTemplateDataInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        areaInformations {
          id
          included
          number
          name {
            data
            included
          }
          autoArmEnabled {
            data
            included
          }
          autoDisarmEnabled {
            data
            included
          }
          badZonesOption {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: TakeoverPanelAreaInformationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateAreaInformationInput[] => {
  const {
    panel: {
      areas: { edges: areaInformations },
    },
  } = getAreasState(controlSystem);

  return areaInformations
    ? areaInformations
        .map((area) => area.node)
        .filter(isNotNullOrUndefined)
        .map(getAreaState)
        .map((area) => ({
          included: includedFields.has(areaListItemTemplateId(area.number)),
          number: Number(area.number),
          autoArmEnabled: {
            data: area.autoArmEnabled,
            included: includedFields.has(areaAutoArmFieldId(area.number)),
          },
          autoDisarmEnabled: {
            data: area.autoDisarmEnabled,
            included: includedFields.has(areaAutoDisarmFieldId(area.number)),
          },
          name: {
            data: area.name,
            included: includedFields.has(areaNameFieldId(area.number)),
          },
          badZonesOption: {
            data: area.badZonesOption as BadZonesOption,
            included: includedFields.has(areaBadZonesFieldId(area.number)),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: TakeoverAreaInformationTemplateDataInline_takeoverProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { areaInformations } = getState(template);

  if (!areaInformations) {
    return;
  }

  areaInformations.filter(isNotNullOrUndefined).forEach((area) => {
    const number = String(area.number);
    if (area.included) {
      setIncludedFields(setToggle(areaListItemTemplateId(String(area.number))));
      setIncludedFields(setToggle(areaNumberFieldId(number)));
    }
    if (area.autoArmEnabled?.included) {
      setIncludedFields(setToggle(areaAutoArmFieldId(number)));
    }
    if (area.autoDisarmEnabled?.included) {
      setIncludedFields(setToggle(areaAutoDisarmFieldId(number)));
    }
    if (area.name?.included) {
      setIncludedFields(setToggle(areaNameFieldId(number)));
    }
    if (area.badZonesOption?.included) {
      setIncludedFields(setToggle(areaBadZonesFieldId(number)));
    }
  });
};
