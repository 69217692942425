/**
 * @ngdoc API Resource
 * @name App.factory:DealerCharges
 *
 * @description
 * DealerCharges API resource.  Holds On Demand Monitoring data for Dealer Charges
 *
 */
App.factory("DealerCharges", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return (resource = $resource(
      PROPS.jobsApiUrl + "/api/v1/pod/DealerCharges/:dealer/:id",
      {},
      {
        query: {
          method: "GET",
          isArray: true,
          params: {
            dealer: "dealer",
          },
        },
      }
    ));
  },
]);
