import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsPcModemField_remoteOptions$key } from "./__generated__/RemoteOptionsPcModemField_remoteOptions.graphql";

export const remoteOptionsPcModemFieldId = () => "remote-options-pc-modem";

function RemoteOptionsPcModemField() {
  const [{ pcModem }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsPcModemField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsPcModemField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            pcModem
          }
        }
      `
    );

  const fieldId = remoteOptionsPcModemFieldId();
  const label = "PC Modem";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to allow the System to answer the telco link and connect with Remote Link through the PC Modem at 2400 baud."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={!!pcModem}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(!pcModem, "pcModem")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsPcModemField;
