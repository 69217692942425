/**
 * @generated SignedSource<<0f164de408b37feafd34fba971c56ddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly remoteOptions: {
    readonly appKey: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly firstApn: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly remoteDisarm: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "b524b11893d7c8ded5d73822c8e2031c";

export default node;
