/**
 * @generated SignedSource<<1a6605398fbfb48a45670baa6927aaa7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XROutputInformationProgrammingConceptFormInline_output$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly isWireless: boolean;
  readonly name: string;
  readonly number: number;
  readonly outputSupervision: OutputSupervision | null;
  readonly realTimeStatus: boolean | null;
  readonly serialNumber: string | null;
  readonly tripWithPanelBell: boolean;
  readonly " $fragmentType": "XROutputInformationProgrammingConceptFormInline_output";
};
export type XROutputInformationProgrammingConceptFormInline_output$key = {
  readonly " $data"?: XROutputInformationProgrammingConceptFormInline_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"XROutputInformationProgrammingConceptFormInline_output">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XROutputInformationProgrammingConceptFormInline_output"
};

(node as any).hash = "e7948e615bea8246b38c3105f5700cc6";

export default node;
