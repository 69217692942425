import { ControlSystemCommType } from "securecom-graphql/client";

/**
 * Determines if the panel meets or exceeds a specific firmware version
 *
 * @param version the firmware version to check against
 */
export function meetsOrExceedsFirmware(version: number) {
  return (panel: any) => Number(panel?.software_version ?? 0) >= version;
}

/**
 * Determines if the panel model contains a partial or full match with
 * any of the provided options. Both will be normalized (uppercased) in
 * the check.
 *
 * @param options list of desired prefix strings to search against
 */
export function matchesModelPrefixes(options: string[]) {
  return (panel: any) =>
    !!options
      .map((option) => option.toUpperCase())
      .find(
        (option) =>
          (panel?.hardware_model ?? "").toUpperCase().indexOf(option) === 0
      );
}

export const validSerialRegex = /^[A-F0-9]{8}$/i;

export const systemIsOnline = (controlSystem?: {
  panels: [{ online: boolean }];
}) => {
  return controlSystem?.panels[0].online ?? false;
};

export const systemWillBeAutoProgrammed = (controlSystem?: {
  panels: [{ auto_program: boolean }];
}) => {
  return controlSystem?.panels[0].auto_program ?? false;
};

export const systemIsScheduledForFutureInstall = (controlSystem: {
  installed_at: string;
}) => {
  return (
    new Date().setHours(0, 0, 0, 0) <
    new Date(controlSystem.installed_at).setHours(0, 0, 0, 0)
  );
};

export const systemIsSite = (controlSystem?: { site_id: string | null }) => {
  return controlSystem?.site_id && !isNaN(parseInt(controlSystem.site_id, 10));
};

export function mapCommTypeToLabel(commType: string) {
  switch (commType) {
    case ControlSystemCommType.NETWORK:
      return "Network";
    case ControlSystemCommType.PERSISTENT:
      return "EASYConnect";
    case ControlSystemCommType.CELL:
      return "Cell";
    case ControlSystemCommType.PERSISTENT_WITH_CELL_BACKUP:
      return "EASYConnect w/ Cell Bkup";
    default:
      return null;
  }
}
