import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const LockoutCodeContext = React.createContext<any>({});

export function LockoutCodeContextProvider(props: {
  lockoutCode: any;
  children: React.ReactNode;
}) {
  return (
    <LockoutCodeContext.Provider value={props.lockoutCode}>
      {props.children}
    </LockoutCodeContext.Provider>
  );
}

export function useLockoutCodeFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const lockoutCode = React.useContext(LockoutCodeContext);

  const data = useFragment(fragmentInput, lockoutCode as TKey);

  const { id } = useFragment(
    graphql`
      fragment LockoutCodeContext_lockoutCode on LockoutCode {
        id
      }
    `,
    lockoutCode as any
  );

  const update = (
    updater: (lockoutCode: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
