/**
 * @ngdoc service
 * @name App.factory:DealerModemService
 *
 * @description
 * Factory for working with the Dealer Modem reporting
 *
 */
App.factory("DealerModemService", [
  "UserService",
  "DealerModemApi",
  "DealerModemSunsetApi",
  "$q",
  function (UserService, DealerModemApi, DealerModemSunsetApi, $q) {
    return {
      getModems: function (dealerId) {
        var defferred = $q.defer();
        DealerModemApi.getModems(
          { type: "List", dealer_id: dealerId },
          function (data) {
            function compare(a, b) {
              dateA = a.ShipDate;
              dateB = b.ShipDate;

              if (dateA < dateB) {
                return 1;
              } else {
                return -1;
              }
            }

            data.sort(compare);

            defferred.resolve(data);
          },
          function (error) {
            defferred.reject();
          }
        );

        return defferred.promise;
      },
      getSunsetModems: function (dealerId) {
        const deferred = $q.defer();

        DealerModemSunsetApi.getModems(
          { dealer_id: dealerId },
          (data) => {
            deferred.resolve(data.SunSetModemsCount);
          },
          (error) => {
            deferred.reject();
          }
        );
        return deferred.promise;
      },
    };
  },
]);
