/**
 * @generated SignedSource<<711534a360d3d5122541e75a4f6b829d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly systemAreaInformation: {
    readonly anyBypass: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly areaSchedules: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCheck: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly closingCode: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly exitDelay: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly morningAmbush: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRSystemAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "a294650c37b91822644bad29ba1b221c";

export default node;
