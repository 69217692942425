/**
 * @generated SignedSource<<a719e3581448211c7d844cdf34eb3d19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTLockoutCodeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly lockoutCode: {
      readonly code: string;
      readonly id: string;
    } | null;
  };
  readonly " $fragmentType": "XTLockoutCodeProgrammingConceptFormInline_controlSystem";
};
export type XTLockoutCodeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTLockoutCodeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTLockoutCodeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTLockoutCodeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "25120df14044c4e9f7afb8c9ad7f0e29";

export default node;
