/**
 *
 * TransitionView
 * A React Component for handling rendering transitions from presets
 * @author Chad Watson
 *
 *
 */
import React, { useCallback, useMemo, useRef } from "react";
import { Motion } from "react-motion";
import useLockedValue from "../../../hooks/useLockedValue";
import Context from "./Context";
import { useTransitionViewContext } from "./hooks";
import * as types from "./types";
import useForceUpdate from "../../../hooks/useForceUpdate";
const CUSTOM_TYPE = "custom";

/** @param {{
  type: string,
springConfig?: {stiffness?: number, damping?: number} | {in: any, out: any},
  data?: any,
  active: boolean,
  children: (inlineStyle: any, data: any) => JSX.Element,
  onRest?: () => void,
  config?: any,
}} props */
function TransitionView({
  type,
  springConfig,
  data,
  active,
  children,
  onRest,
  config,
}) {
  const dangerouslyForceRerender = useForceUpdate();
  const activeSpringConfig =
    (active && springConfig && springConfig.in) ||
    (!active && springConfig && springConfig.out) ||
    springConfig;
  const preset =
    type === CUSTOM_TYPE ? config : types[type](activeSpringConfig);
  const prevActive = useRef(active);
  const animating = useRef(false);
  const transitionData = useRef(data);
  const context = useTransitionViewContext();

  if (prevActive.current !== active) {
    animating.current = true;
  }

  if (active) {
    transitionData.current = data;
  }

  prevActive.current = active;
  const getInlineStyle = useLockedValue(
    preset.getInlineStyle,
    !animating.current
  );
  const providerValue = useMemo(
    () => ({
      animating: animating.current || context.animating,
      active,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, animating.current, context.animating]
  );
  return (
    <Motion
      defaultStyle={active ? preset.defaultStyle : preset.willEnter()}
      style={active ? preset.style : preset.willLeave()}
      onRest={useCallback(() => {
        animating.current = false;
        dangerouslyForceRerender();

        if (onRest) {
          onRest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [animating.current])}
    >
      {(interpolatedStyle) => {
        if (!active && !animating.current) {
          return null;
        }

        return (
          <Context.Provider value={providerValue}>
            {children(
              getInlineStyle(interpolatedStyle),
              transitionData.current,
              animating.current
            )}
          </Context.Provider>
        );
      }}
    </Motion>
  );
}

export default TransitionView;
