/**
 *Directive that selects what data to display in drop-down
 */
App.directive("daDropdownSelect", function () {
  return {
    restrict: "E",
    templateUrl: "app/common/templates/da-dropdown-select-tpl.html",
    scope: {
      selectTitle: "@",
      closeType: "@",
    },
    transclude: true,
    compile: function (element, attrs) {
      if (!attrs.selectTitle) {
        attrs.selectTitle = "Select";
      }
      if (!attrs.closeType) {
        attrs.closeType = "always";
      }
    },
  };
});
