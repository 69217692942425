import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import { CommunicationPathType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathFailTestHoursField_communicationPath$key } from "./__generated__/CommPathFailTestHoursField_communicationPath.graphql";

export const commPathFailTestHoursFieldId = (number: string) =>
  `comm-path-fail-test-hours-${number}`;

function CommPathFailTestHoursField() {
  const [{ number, failTestHours, type }, updateCommPath] =
    useCommPathFragment<CommPathFailTestHoursField_communicationPath$key>(
      graphql`
        fragment CommPathFailTestHoursField_communicationPath on CommunicationPath {
          number
          failTestHours
          type
        }
      `
    );

  const fieldId = commPathFailTestHoursFieldId(String(number));
  const { current: originalValue } = React.useRef(failTestHours);
  const disabled = type !== CommunicationPathType.BACKUP;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Fail Test Hours"
      tooltip={
        "The number of days or hours (as defined by Test Frequency Unit) between sending tests to the receiver."
      }
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={failTestHours}
        required
        disabled={disabled}
        min={0}
        max={24}
        inlineHelp={`0-24`}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "failTestHours"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(0, 24, valueAsNumber);
            recordProxy.setValue(Number(value), "failTestHours");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathFailTestHoursField;
