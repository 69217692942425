/**
 * @generated SignedSource<<afc24c4d3510ce9dc99d83480b342ccd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputGroupOutputNumberField_outputGroup$data = {
  readonly id: string;
  readonly number: number;
  readonly outputNumber1: string;
  readonly outputNumber2: string;
  readonly outputNumber3: string;
  readonly outputNumber4: string;
  readonly outputNumber5: string;
  readonly outputNumber6: string;
  readonly outputNumber7: string;
  readonly outputNumber8: string;
  readonly " $fragmentType": "OutputGroupOutputNumberField_outputGroup";
};
export type OutputGroupOutputNumberField_outputGroup$key = {
  readonly " $data"?: OutputGroupOutputNumberField_outputGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputGroupOutputNumberField_outputGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputGroupOutputNumberField_outputGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber3",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber4",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber5",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber6",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber7",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputNumber8",
      "storageKey": null
    }
  ],
  "type": "OutputGroup",
  "abstractKey": null
};

(node as any).hash = "98062f7ff6100f329019c5815ed14699";

export default node;
