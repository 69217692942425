App.controller(
  "PaymentModalInstanceCtrl",
  function ($scope, $rootScope, $modalInstance, UserService, PaymentsService) {
    $scope.priceInfo = {
      charge: 0,
    };

    $scope.dealerCode = 0;
    $scope.showPastDueMessage = false;
    $scope.adjustedAmount = 0;
    $scope.isChargeGreaterThanBalance = false;
    $scope.isLoading = false;

    $scope.popAToaster = function (title, mssg, type) {
      $rootScope.alerts.push({
        title: title,
        type: type,
        text: mssg,
      });
    };

    function init() {}

    $scope.makePayment = function () {
      if (
        validateCharge(
          $scope.priceInfo.charge,
          $scope.billingAccount.currentBalance
        )
      ) {
        applyCharge($scope.priceInfo.charge, UserService.email);
      } else {
        $scope.isChargeGreaterThanBalance = true;
      }
    };

    var adjustedAmountTimeout = {};

    $scope.onAmountChange = function () {
      $scope.isLoading = true;
      clearTimeout(adjustedAmountTimeout);
      adjustedAmountTimeout = setTimeout(adjustedCost, 200);
    };

    function adjustedCost() {
      if (+$scope.priceInfo.charge > 0) {
        var modifier = {
          past_due: $scope.billingAccount.pastDueCharges * 100,
          account_type: $scope.billingAccount.accountSubType,
        };

        PaymentsService.getAdjustedCost($scope.priceInfo.charge, modifier).then(
          function (data) {
            $scope.adjustedAmount = data.total;
            $scope.isLoading = false;
          },
          function (error) {
            console.error(error);
            $scope.popAToaster(
              "Payment",
              `An error occurred in retrieiving adjusted amount.`,
              "error"
            );
          }
        );
      }
    }

    function applyCharge(charge, userEmail) {
      $scope.billingAccount.chargeAccount(charge, userEmail).then(
        function (data) {
          $scope.billingAccount
            .getScheduledPayments()
            .then(
              function (data) {
                $scope.billingAccount.scheduledPayments = data;
              },
              function (error) {
                console.error(error);
              }
            )
            .catch((e) => console.error(e));
          $modalInstance.dismiss("cancel");
          $scope.popAToaster(
            "Payment",
            `Successfully made payment of $${$scope.adjustedAmount}.`,
            "success"
          );
        },
        function (error) {
          $scope.popAToaster("Payment", "Unable to process payment.", "error");
        }
      );
    }

    function validateCharge(charge, totalAmountBalance) {
      if (+charge > +totalAmountBalance) {
        return true;
      }

      return true;
    }

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    init();
  }
);
