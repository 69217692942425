import { Checkbox } from "components/DaStyledElements";
import React from "react";
import { ControlSystem, Customer } from ".";

interface HolidaySystemSelectorLineItemProps {
  customer: Customer;
  onUpdateSystemList: (
    system: ControlSystem,
    customer: Customer,
    isChecked: boolean
  ) => void;
  isSelected: (id: string) => boolean;
  currentHolidayPanelIds: Set<number>;
}

const HolidaySystemSelectorLineItem: React.FC<HolidaySystemSelectorLineItemProps> =
  ({ customer, onUpdateSystemList, isSelected, currentHolidayPanelIds }) => {
    // Function to check if all systems are checked
    const customerIsChecked = (customer: Customer) => {
      return customer.controlSystems.every((system) =>
        isSelected(system.controlSystemId)
      );
    };

    // Function to toggle all systems
    const toggleAllSystems = (customer: Customer, isChecked: boolean) => {
      if (isChecked) {
        customer.controlSystems.forEach((system) =>
          onUpdateSystemList(system, customer, true)
        );
      } else {
        customer.controlSystems.forEach((system) =>
          onUpdateSystemList(system, customer, false)
        );
      }
    };
    return (
      <div>
        <Checkbox
          id={customer.customerId}
          checked={customerIsChecked(customer)}
          label={customer.customerName}
          parentCheckbox={true}
          onChange={() => {
            toggleAllSystems(customer, !customerIsChecked(customer));
          }}
        />

        <ul>
          {customer.controlSystems.map((system) => (
            <li key={system.controlSystemId}>
              <Checkbox
                id={system.controlSystemId}
                checked={isSelected(system.controlSystemId)}
                label={system.controlSystemName}
                onChange={() =>
                  onUpdateSystemList(
                    system,
                    customer,
                    !isSelected(system.controlSystemId)
                  )
                }
              />
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default HolidaySystemSelectorLineItem;
