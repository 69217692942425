import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { menuDisplayArmDisarmDisplayKeypadsFieldId } from "components/FullProgramming/common/MenuDisplayFields/MenuDisplayArmDisarmDisplayKeypadsField";
import { menuDisplayArmedStatusDisplayKeypadsFieldId } from "components/FullProgramming/common/MenuDisplayFields/MenuDisplayArmedStatusDisplayKeypadsField";
import { menuDisplayTimeDisplayKeypadsFieldId } from "components/FullProgramming/common/MenuDisplayFields/MenuDisplayTimeDisplayKeypadsField";
import { XRMenuDisplayProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRMenuDisplayProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getMenuDisplayState } from "../../../XRFullProgramming/XRMenuDisplayProgrammingConceptForm";
import { XrProgrammingTemplateMenuDisplayInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        menuDisplay {
          id
          included
          armedStatusDisplayKeypads {
            included
            data
          }
          timeDisplayKeypads {
            included
            data
          }
          armDisarmDisplayKeypads {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRMenuDisplayProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateMenuDisplayInput => {
  const {
    panel: { menuDisplay },
  } = getMenuDisplayState(controlSystem);

  if (!menuDisplay) {
    return { included: false };
  }

  const menuDisplayInputFields = {
    armedStatusDisplayKeypads: {
      included: includedFields.has(
        menuDisplayArmedStatusDisplayKeypadsFieldId()
      ),
      data: menuDisplay.armedStatusDisplayKeypads,
    },
    timeDisplayKeypads: {
      included: includedFields.has(menuDisplayTimeDisplayKeypadsFieldId()),
      data: menuDisplay.timeDisplayKeypads,
    },
    armDisarmDisplayKeypads: {
      included: includedFields.has(
        menuDisplayArmedStatusDisplayKeypadsFieldId()
      ),
      data: menuDisplay.armDisarmDisplayKeypads,
    },
  };

  const menuDisplayIncluded = Object.values(menuDisplayInputFields).some(
    ({ included }) => included
  );

  return {
    included: menuDisplayIncluded,
    ...menuDisplayInputFields,
  };
};

export const setIncludedFields = (
  template: XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { menuDisplay } = getState(template);

  if (menuDisplay?.armedStatusDisplayKeypads?.included) {
    setIncludedFields(setToggle(menuDisplayArmedStatusDisplayKeypadsFieldId()));
  }
  if (menuDisplay?.timeDisplayKeypads?.included) {
    setIncludedFields(setToggle(menuDisplayTimeDisplayKeypadsFieldId()));
  }
  if (menuDisplay?.armDisarmDisplayKeypads?.included) {
    setIncludedFields(setToggle(menuDisplayArmDisarmDisplayKeypadsFieldId()));
  }
};
