/**
 * @generated SignedSource<<502acc6c595dc45bf1b2d957414fa328>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
export type RecorderCameraDetectionRegionSettingsZonesQuery$variables = {
  systemId: string;
};
export type RecorderCameraDetectionRegionSettingsZonesQuery$data = {
  readonly controlSystem: {
    readonly panel?: {
      readonly deviceInformations: ReadonlyArray<{
        readonly axNumber?: string;
        readonly deviceCommunicationMethod?: DeviceInformationCommType;
        readonly deviceType?: DeviceInformationType;
      }>;
    };
    readonly varHubDetectionRegionZoneNumberMax?: number | null;
    readonly varHubDetectionRegionZoneNumberMin?: number | null;
    readonly zones?: ReadonlyArray<{
      readonly number: string;
    }>;
  } | null;
};
export type RecorderCameraDetectionRegionSettingsZonesQuery = {
  response: RecorderCameraDetectionRegionSettingsZonesQuery$data;
  variables: RecorderCameraDetectionRegionSettingsZonesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "systemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "varHubDetectionRegionZoneNumberMin",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "varHubDetectionRegionZoneNumberMax",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceCommunicationMethod",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "axNumber",
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "type": "XrDeviceInformation",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "type": "Xt75DeviceInformation",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderCameraDetectionRegionSettingsZonesQuery",
    "selections": [
      {
        "alias": "controlSystem",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "deviceInformations",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Zone",
                "kind": "LinkedField",
                "name": "zones",
                "plural": true,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderCameraDetectionRegionSettingsZonesQuery",
    "selections": [
      {
        "alias": "controlSystem",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Panel",
                "kind": "LinkedField",
                "name": "panel",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "deviceInformations",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Zone",
                "kind": "LinkedField",
                "name": "zones",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ControlSystem",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "900c6acc112fd8a4660afb60666e32bd",
    "id": null,
    "metadata": {},
    "name": "RecorderCameraDetectionRegionSettingsZonesQuery",
    "operationKind": "query",
    "text": "query RecorderCameraDetectionRegionSettingsZonesQuery(\n  $systemId: ID!\n) {\n  controlSystem: node(id: $systemId) {\n    __typename\n    ... on ControlSystem {\n      varHubDetectionRegionZoneNumberMin\n      varHubDetectionRegionZoneNumberMax\n      panel {\n        deviceInformations {\n          __typename\n          ... on XrDeviceInformation {\n            axNumber\n            deviceType\n            deviceCommunicationMethod\n          }\n          ... on Xt75DeviceInformation {\n            deviceType\n            deviceCommunicationMethod\n          }\n          id\n        }\n        id\n      }\n      zones {\n        number\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7cd14bf740f825212111dafbf55adec";

export default node;
