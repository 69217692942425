const DaFontWeight = {
  UltraLight: "200",
  Thin: "300",
  Regular: "400",
  Medium: "500",
  Semibold: "600",
  Bold: "700",
  ExtraBold: "800",
  Black: "900",
};

export default DaFontWeight;
