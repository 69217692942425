import { isNotNullOrUndefined } from "common/utils/universal/function";
import {
  Coordinates,
  CoordinatesTransformer,
} from "components/CameraEditCommon/types";
import { CoordinateRenderer, slope } from "components/CameraEditCommon/utils";
import { RecordProxy } from "relay-runtime";
import {
  AnalyticalRegionWithClientSchemaData,
  LineWithClientSchemaData,
  RegionWithClientSchemaData,
} from "./type";

export const moveRegionPointForKeyPress =
  (transformer: CoordinatesTransformer) =>
  (region: RecordProxy<RegionWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      region.getValue("activeCoordinateIndexes") ?? []
    );
    const coordinatesLinkedRecords = region.getLinkedRecords("coordinates");
    const coordinates = coordinatesLinkedRecords.map((coordinateRecord) => [
      coordinateRecord.getValue("x"),
      coordinateRecord.getValue("y"),
    ]) as Coordinates[];

    if (activeCoordinates.size) {
      activeCoordinates.forEach((coordinateIndex) => {
        if (coordinateIndex in coordinates) {
          const xy = transformer(coordinates[coordinateIndex]);
          syncCoordinates(coordinates, coordinateIndex, xy);
        }
      });
      coordinates.forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    } else {
      coordinates.map(transformer).forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    }
  };

export const moveMotionDetectionPoint =
  (transformer: (coordinates: Coordinates[]) => Coordinates[]) =>
  (region: RecordProxy<RegionWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      region.getValue("activeCoordinateIndexes") ?? []
    );
    const coordinatesLinkedRecords = region.getLinkedRecords("coordinates");
    const coordinates = coordinatesLinkedRecords.map((coordinateRecord) => [
      coordinateRecord.getValue("x"),
      coordinateRecord.getValue("y"),
    ]) as Coordinates[];
    const activeEdgeIndex = region.getValue("activeEdgeIndex");

    if (isNotNullOrUndefined(activeEdgeIndex)) {
      // Move edge

      // Sending adjacent point of edge to capture movement coordinates
      const [x, y] = transformer([coordinates[activeEdgeIndex]])[0];

      switch (activeEdgeIndex) {
        case 0:
          coordinates[0] = [coordinates[0][0], y];
          coordinates[1] = [coordinates[1][0], y];
          break;
        case 1:
          coordinates[1] = [x, coordinates[1][1]];
          coordinates[2] = [x, coordinates[2][1]];
          break;
        case 2:
          coordinates[2] = [coordinates[2][0], y];
          coordinates[3] = [coordinates[3][0], y];
          break;
        case 3:
          coordinates[3] = [x, coordinates[3][1]];
          coordinates[0] = [x, coordinates[0][1]];
          break;
      }
      coordinates.forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    } else if (activeCoordinates.size) {
      // Move corner point
      const activeCoordinatesArray = [...activeCoordinates];
      const translatedCoordinates = transformer(
        activeCoordinatesArray.map((index) => coordinates[index])
      );
      translatedCoordinates.forEach((xy, index) => {
        syncCoordinates(coordinates, activeCoordinatesArray[index], xy);
      });
      coordinates.forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    } else {
      // Move entire region
      transformer(coordinates).forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    }
  };

/**
 * Sets tranformed coordinate and sync adjacent coordinates
 *
 * @param coordinates current coordinates
 * @param activeCoordinateIndex active coordinate's index
 * @param xy new value for the active coordinate
 */
function syncCoordinates(
  coordinates: Coordinates[],
  activeCoordinateIndex: number,
  [x, y]: Coordinates
) {
  switch (activeCoordinateIndex) {
    case 0:
      coordinates[1] = [coordinates[1][0], y];
      coordinates[3] = [x, coordinates[3][1]];
      break;
    case 1:
      coordinates[0] = [coordinates[0][0], y];
      coordinates[2] = [x, coordinates[2][1]];
      break;
    case 2:
      coordinates[3] = [coordinates[3][0], y];
      coordinates[1] = [x, coordinates[1][1]];
      break;
    case 3:
      coordinates[2] = [coordinates[2][0], y];
      coordinates[0] = [x, coordinates[0][1]];
      break;
  }
  coordinates[activeCoordinateIndex] = [x, y];
}

export const moveLinePointForKeyPress =
  (transformer: CoordinatesTransformer) =>
  (line: RecordProxy<LineWithClientSchemaData>) => {
    const activePoint = line.getValue("activePointIndex");
    const coordinates = [
      [line.getValue("startX"), line.getValue("startY")],
      [line.getValue("endX"), line.getValue("endY")],
    ] as Coordinates[];

    if (isNotNullOrUndefined(activePoint)) {
      // Moving endpoint
      const xy = transformer(coordinates[activePoint]);
      moveEndpoint(line, activePoint, xy);
    } else {
      // Moving entire line
      coordinates
        .map(transformer)
        .forEach((xy, index) => moveEndpoint(line, index, xy));
    }
  };

export const moveLinePoint =
  (transformer: (coordinates: Coordinates[]) => Coordinates[]) =>
  (line: RecordProxy<LineWithClientSchemaData>) => {
    const activePoint = line.getValue("activePointIndex");
    const coordinates = [
      [line.getValue("startX"), line.getValue("startY")],
      [line.getValue("endX"), line.getValue("endY")],
    ] as Coordinates[];

    if (isNotNullOrUndefined(activePoint)) {
      // Moving endpoint
      const xy = transformer([coordinates[activePoint]])[0];
      moveEndpoint(line, activePoint, xy);
    } else {
      // Moving entire line
      transformer(coordinates).forEach((xy, index) =>
        moveEndpoint(line, index, xy)
      );
    }
  };

/**
 * moves the endpoint of the line
 *
 * @param line selected active line
 * @param movePointIndex point index of the line that is being moved
 * @param xy new values for the x y coordinates
 */
function moveEndpoint(
  line: RecordProxy<LineWithClientSchemaData>,
  movePointIndex: number,
  [x, y]: Coordinates
) {
  if (movePointIndex === 0) {
    line.setValue(Math.round(x), "startX");
    line.setValue(Math.round(y), "startY");
  } else {
    line.setValue(Math.round(x), "endX");
    line.setValue(Math.round(y), "endY");
  }
}

export const getDirectionLineEndpoints = (
  coordinates: Coordinates[],
  renderX: CoordinateRenderer,
  renderY: CoordinateRenderer
) => {
  const [startX, startY] = coordinates[0];
  const [endX, endY] = coordinates[1];
  const [midX, midY] = [(startX + endX) / 2, (startY + endY) / 2];
  const perpendicularSlope =
    startX === endX
      ? 0
      : -1 /
        slope([renderX(startX), renderY(startY)])([
          renderX(endX),
          renderY(endY),
        ]);
  const offset = 15;
  const deltaX = Math.cos(Math.atan(perpendicularSlope));
  const deltaY = Math.sin(Math.atan(perpendicularSlope));
  const yAdjuster = startY - endY <= 0 ? -1 : 1;

  const aX = renderX(midX) - offset * deltaX * yAdjuster;
  const aY = renderY(midY) - offset * deltaY * yAdjuster;
  const bX = renderX(midX) + offset * deltaX * yAdjuster;
  const bY = renderY(midY) + offset * deltaY * yAdjuster;

  return [aX, aY, bX, bY];
};

export const moveAnalyticalDetectionPoint =
  (transformer: (coordinates: Coordinates[]) => Coordinates[]) =>
  (region: RecordProxy<AnalyticalRegionWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      region.getValue("activeCoordinateIndexes") ?? []
    );
    const coordinatesLinkedRecords = region.getLinkedRecords("coordinates");
    const coordinates = coordinatesLinkedRecords.map((coordinateRecord) => [
      coordinateRecord.getValue("x"),
      coordinateRecord.getValue("y"),
    ]) as Coordinates[];

    if (activeCoordinates.size) {
      // Move point
      const activeCoordinatesArray = [...activeCoordinates];
      const translatedCoordinates = transformer(
        activeCoordinatesArray.map((index) => coordinates[index])
      );
      translatedCoordinates.forEach((xy, index) => {
        coordinates[activeCoordinatesArray[index]] = xy;
      });
      coordinates.forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    } else {
      // Move entire region
      transformer(coordinates).forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    }
  };

export const moveAnalyticalPointForKeyPress =
  (transformer: CoordinatesTransformer) =>
  (region: RecordProxy<AnalyticalRegionWithClientSchemaData>) => {
    const activeCoordinates = new Set(
      region.getValue("activeCoordinateIndexes") ?? []
    );
    const coordinatesLinkedRecords = region.getLinkedRecords("coordinates");
    const coordinates = coordinatesLinkedRecords.map((coordinateRecord) => [
      coordinateRecord.getValue("x"),
      coordinateRecord.getValue("y"),
    ]) as Coordinates[];

    if (activeCoordinates.size) {
      activeCoordinates.forEach((coordinateIndex) => {
        if (coordinateIndex in coordinates) {
          const [x, y] = transformer(coordinates[coordinateIndex]);
          region
            .getLinkedRecords("coordinates")
            [coordinateIndex].setValue(Math.round(x), "x");
          region
            .getLinkedRecords("coordinates")
            [coordinateIndex].setValue(Math.round(y), "y");
        }
      });
    } else {
      coordinates.map(transformer).forEach(([x, y], index) => {
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(x), "x");
        region
          .getLinkedRecords("coordinates")
          [index].setValue(Math.round(y), "y");
      });
    }
  };
