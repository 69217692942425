import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { clamp } from "ramda";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathFailTimeField_communicationPath$key } from "./__generated__/CommPathFailTimeField_communicationPath.graphql";

export const commPathFailTimeFieldId = (number: string) =>
  `comm-path-fail-time-${number}`;

function CommPathFailTimeField() {
  const [
    {
      number,
      useCheckIn,
      commType,
      checkInMinutes,
      failTime,
      failTimeMin,
      failTimeMax,
    },
    updateCommPath,
  ] = useCommPathFragment<CommPathFailTimeField_communicationPath$key>(
    graphql`
      fragment CommPathFailTimeField_communicationPath on CommunicationPath {
        number
        useCheckIn
        commType
        checkInMinutes
        failTime
        failTimeMin
        failTimeMax
      }
    `
  );

  const fieldId = commPathFailTimeFieldId(String(number));
  const originalValue = React.useRef(failTime).current;
  const { isXt75 } = resolvePanelType(useHardwareModel());
  const disabled =
    ["CID", "DD"].includes(commType) ||
    (!isXt75 && useCheckIn !== "YES") ||
    (isXt75 && checkInMinutes === 0);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Fail Time"
      tooltip="Receiver allows the System this amount of time at the programmed Check-In Time before causing a missing condition. Must be greater than Check-in ."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={failTime.toString()}
        required
        disabled={disabled}
        min={checkInMinutes}
        max={failTimeMax}
        inlineHelp={`${failTimeMin}–${failTimeMax}`}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(emptyStringOrNumber(target.value), "failTime");
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(checkInMinutes, failTimeMax, valueAsNumber);
            recordProxy.setValue(Number(value), "failTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathFailTimeField;
