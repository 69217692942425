/**
 * @generated SignedSource<<fd2095fbb7952f8a02331e172c9e9809>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathProtocol = "TCP" | "UDP";
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
export type CommunicationPathUseCheckIn = "ADAPTIVE" | "ADAPTIVE3" | "NO" | "RANDOM" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XFCommunicationProgrammingConceptFormInline_communicationPath$data = {
  readonly __typename: "CommunicationPath";
  readonly accountNumber: string | null;
  readonly apn: string;
  readonly apn2: string;
  readonly checkInMinutes: number;
  readonly checkInMinutesMax: number;
  readonly checkInMinutesMin: number;
  readonly commType: CommunicationPathCommType;
  readonly duplicateAlarms: boolean;
  readonly failTestHours: number;
  readonly failTime: number;
  readonly failTimeMax: number;
  readonly failTimeMin: number;
  readonly firstPhone: string;
  readonly id: string;
  readonly isNew: boolean;
  readonly number: number;
  readonly protocol: CommunicationPathProtocol;
  readonly receiverPort: string;
  readonly remoteIP: string;
  readonly retryTime: number;
  readonly retryTimeMax: number;
  readonly retryTimeMin: number;
  readonly secondPhone: string;
  readonly sendPath: boolean;
  readonly testReport: CommunicationPathTestReport;
  readonly testTime: string;
  readonly type: CommunicationPathType;
  readonly useCheckIn: CommunicationPathUseCheckIn;
  readonly " $fragmentType": "XFCommunicationProgrammingConceptFormInline_communicationPath";
};
export type XFCommunicationProgrammingConceptFormInline_communicationPath$key = {
  readonly " $data"?: XFCommunicationProgrammingConceptFormInline_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFCommunicationProgrammingConceptFormInline_communicationPath">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFCommunicationProgrammingConceptFormInline_communicationPath"
};

(node as any).hash = "b5d579e32200ae3930fa6fdb574d0d2b";

export default node;
