import React from "react";

const RadialUser = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 41H9C9 41 12.5321 50 26.7471 50C40.9622 50 44 41 44 41Z"
      fill="#3C85A3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.75 21.9697C30.4345 21.9697 34.232 18.1709 34.232 13.4848C34.232 8.7988 30.4345 5 25.75 5C21.0656 5 17.2681 8.7988 17.2681 13.4848C17.2681 18.1709 21.0656 21.9697 25.75 21.9697ZM18.1881 22.3232C24.1961 26.2121 27.7302 26.2121 33.7383 22.3232C33.7383 22.3232 39.8862 23.7374 42.3599 27.6263C44.8337 31.5152 43.9997 42.5 43.9997 42.5L8.99999 43C8.99999 43 8.43217 31.1616 10.1994 27.6263C11.9666 24.0909 18.1881 22.3232 18.1881 22.3232Z"
      fill="#3C85A3"
    />
    <circle cx="26" cy="26" r="25" stroke="#888888" strokeWidth="2" />
    <circle cx="26" cy="26" r="23" stroke="white" strokeWidth="2" />
  </svg>
);

export default RadialUser;
