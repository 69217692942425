/**
 * @generated SignedSource<<0e6e98fdc9a212eba4fa49251bef3a4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75FullProgrammingContainer_templateStatus_controlSystem$data = {
  readonly id: string;
  readonly templatePreProgrammingJobStatus: string | null;
  readonly " $fragmentType": "XT75FullProgrammingContainer_templateStatus_controlSystem";
};
export type XT75FullProgrammingContainer_templateStatus_controlSystem$key = {
  readonly " $data"?: XT75FullProgrammingContainer_templateStatus_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75FullProgrammingContainer_templateStatus_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./XT75FullProgrammingContainer_templateStatus_controlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "XT75FullProgrammingContainer_templateStatus_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatePreProgrammingJobStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "2d6ab7a0536cc06b9449670a1d8e4ae9";

export default node;
