/**
 * @generated SignedSource<<a7216c9fa828b1999ac1e659808df74c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomRolesQuery$variables = {
  dealerId: string;
};
export type CustomRolesQuery$data = {
  readonly dealerCustomRolePersonnel: ReadonlyArray<{
    readonly customRoleId: string | null;
    readonly customRoleName: string | null;
    readonly email: string;
    readonly id: string;
    readonly role: string;
  } | null> | null;
};
export type CustomRolesQuery = {
  response: CustomRolesQuery$data;
  variables: CustomRolesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dealer_id",
        "variableName": "dealerId"
      }
    ],
    "concreteType": "DealerPersonnelItem",
    "kind": "LinkedField",
    "name": "dealerCustomRolePersonnel",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customRoleId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customRoleName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomRolesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomRolesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6328692e72e6d2e9a482cc26b9716616",
    "id": null,
    "metadata": {},
    "name": "CustomRolesQuery",
    "operationKind": "query",
    "text": "query CustomRolesQuery(\n  $dealerId: String!\n) {\n  dealerCustomRolePersonnel(dealer_id: $dealerId) {\n    id\n    email\n    role\n    customRoleId\n    customRoleName\n  }\n}\n"
  }
};
})();

(node as any).hash = "eef647efd5eeab1ef9499980a8c15441";

export default node;
