import NakedButton from "common/components/web/NakedButton";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import * as React from "react";
import styled from "styled-components";

const ToggleButton = styled(NakedButton)<{ open: boolean }>`
  font-size: var(--measure-font-12);
  font-weight: bold;
  vertical-align: middle;
  border-radius: 0.4rem;
  color: ${({ open }) =>
    open ? "var(--color-default-text)" : "var(--color-neutral-900)"};

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px,
      var(--color-info-400) 0px 0px 0px 4px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  &::after {
    content: "";
    display: inline-block;
    margin-left: 0.5em;
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    ${({ open }) =>
      open
        ? `
      border-bottom: 0.5em solid currentColor
    `
        : `
      border-top: 0.5em solid currentColor
    `};
  }

  &:hover {
    color: var(--color-default-text);
  }
`;
const FieldsWrapper = styled.div`
  width: 100%;
  margin-top: var(--measure-1x);
  padding: var(--measure-half) var(--measure-12);
  border-radius: 0.6rem;
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-100);
`;

function KeyfobButtonProgrammingFields(props: {
  buttonTitle: "Button" | "Top" | "Right" | "Left" | "Bottom";
  children: React.ReactNode;
  className?: string;
}) {
  const [open, setOpen] = React.useState(false);

  const {
    isSaving: isSavingTemplate,
    isApplying: isApplyingTemplate,
    isEditing,
  } = useTemplateContext();

  React.useEffect(() => {
    setOpen(isSavingTemplate || isApplyingTemplate || isEditing);
  }, [isSavingTemplate, isApplyingTemplate, isEditing]);

  return (
    <div className={props.className}>
      <ToggleButton
        type="button"
        open={open}
        onClick={() => {
          setOpen(!open);
        }}
        title={`${open ? "Hide" : "Show"} ${props.buttonTitle}`}
      >
        {props.buttonTitle}
      </ToggleButton>
      {open && <FieldsWrapper>{props.children}</FieldsWrapper>}
    </div>
  );
}

export default KeyfobButtonProgrammingFields;
