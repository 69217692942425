/**
 * App.factory.PersonsAPI
 *
 * SCAPI persons API resource.
 *
 * Persons are the top layer objects, representing a human. They do not belong to a dealer
 * and can have multiple app users, personnel, credentials etc. attached to them
 */
App.factory("PersonsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/persons/:id/:action",
      {},
      {
        list: { method: "GET", isArray: true },
        update: { method: "PATCH" },
        create: { method: "POST" },
        merge: { method: "POST", params: { action: "merge" } },
        resetAccess: {
          method: "POST",
          params: { id: "@id", action: "reset_access" },
        },
      }
    );
  },
]);
