import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsHoursFromGMTField_systemOptions$key } from "./__generated__/SystemOptionsHoursFromGMTField_systemOptions.graphql";

export const systemOptionsHoursFromGMTFieldId = () =>
  "system-options-hours-from-gmt";

function HoursFromGMTField() {
  const [
    { hoursFromGMT, hoursFromGMTMin, hoursFromGMTMax },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsHoursFromGMTField_systemOptions$key>(
    graphql`
      fragment SystemOptionsHoursFromGMTField_systemOptions on SystemOptions {
        hoursFromGMT
        hoursFromGMTMin
        hoursFromGMTMax
      }
    `
  );

  const fieldId = systemOptionsHoursFromGMTFieldId();
  const originalValue = React.useRef(hoursFromGMT).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Hours from GMT"
      tooltip="Enter the System's Time Zone in terms of how many hours it is behind GMT."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={hoursFromGMT?.toString()}
        required
        inlineHelp={`${hoursFromGMTMin}–${hoursFromGMTMax}`}
        min={hoursFromGMTMin}
        max={hoursFromGMTMax}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "hoursFromGMT"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : valueAsNumber === 0
                ? valueAsNumber
                : clamp(hoursFromGMTMin, hoursFromGMTMax, valueAsNumber);
            recordProxy.setValue(value, "hoursFromGMT");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default HoursFromGMTField;
