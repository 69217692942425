/**
 * @generated SignedSource<<3c2c14918ee98fe90eb6ea9ce6bbab91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteVideoIntegrationsSection_site$data = {
  readonly billingControlSystemId: string;
  readonly digitalWatchdogSpectrumEnabled: boolean;
  readonly eagleEyeEnabled: boolean;
  readonly hanwhaWaveEnabled: boolean;
  readonly hikvisionDoorbellEnabled: boolean;
  readonly hikvisionNvrEnabled: boolean;
  readonly id: string;
  readonly openEyeEnabled: boolean;
  readonly securecomNvrEnabled: boolean;
  readonly videoEnabled: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DigitalWatchdogSpectrum_site" | "EagleEye_site" | "HanwhaNvrs_site" | "HikvisionNvrsForm_site" | "OpenEyeNvrs_site" | "SecureComDoorbellForm_site" | "SecureComVideoForm_site">;
  readonly " $fragmentType": "SiteVideoIntegrationsSection_site";
};
export type SiteVideoIntegrationsSection_site$key = {
  readonly " $data"?: SiteVideoIntegrationsSection_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteVideoIntegrationsSection_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteVideoIntegrationsSection_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SecureComVideoForm_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SecureComDoorbellForm_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OpenEyeNvrs_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HikvisionNvrsForm_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EagleEye_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DigitalWatchdogSpectrum_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HanwhaNvrs_site"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securecomNvrEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hikvisionDoorbellEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openEyeEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hikvisionNvrEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eagleEyeEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "digitalWatchdogSpectrumEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hanwhaWaveEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingControlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "dbd42ae358548fb22f7e49da6c8470f8";

export default node;
