/**
 * @generated SignedSource<<73ccd6ddefe416d0ae29372daf15079b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeatureKeyEnum = "ALL_NO_YES_OPTION" | "ALL_USER_CODE_CHANGE" | "BANAMEX_MANTRAP" | "BANK_OF_AMERICA" | "CANADIAN" | "DOOR_ADD_ON_A" | "DOOR_ADD_ON_B" | "DUAL_NETWORK_PATH" | "DURESS_PLUS_2" | "EMAIL_MESSAGING" | "ENCRYPTION" | "EUROPEAN_FEATURES" | "FE_MORAN" | "IGNORE_BROADCAST" | "MINIMUM_USER_NAME" | "NATIONAL_ACCOUNT" | "PRINTER" | "RESTRICT_PROFILES" | "SERVICE_USER_AUTHENTICATION" | "SMITH_SOUTHERN" | "SMITH_WESSON" | "STARBUCKS" | "SUPPORT_200_KEY_FOBS" | "WELLS_FARGO" | "XR150" | "XR350";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseFeatureKeyEnabled_panel$data = {
  readonly featureKeys: ReadonlyArray<{
    readonly key: FeatureKeyEnum;
  }>;
  readonly " $fragmentType": "PanelContextUseFeatureKeyEnabled_panel";
};
export type PanelContextUseFeatureKeyEnabled_panel$key = {
  readonly " $data"?: PanelContextUseFeatureKeyEnabled_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseFeatureKeyEnabled_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseFeatureKeyEnabled_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureKey",
      "kind": "LinkedField",
      "name": "featureKeys",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "099741517e4bf5cd9df298db78b488eb";

export default node;
