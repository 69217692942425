import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";
import { DeleteConfirmModal } from "components/Modal/ConfirmModal";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { useMutation } from "react-relay";
import styled from "styled-components/macro";
import EditGlobalHolidayDatesModal from "./EditGlobalHolidayDatesModal";
import { HolidayCopy } from "./GlobalHolidayDatesTable";
import { GlobalHolidayDatesTableBodyDeleteMutation } from "./__generated__/GlobalHolidayDatesTableBodyDeleteMutation.graphql";

export default function GlobalHolidayDatesTableBody({
  holiday,
  dealerId,
}: {
  holiday: HolidayCopy;
  dealerId: string;
}) {
  const showAlert = useShowAlert();

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const [deleteHolidayMutation, deletingHoliday] =
    useMutation<GlobalHolidayDatesTableBodyDeleteMutation>(graphql`
      mutation GlobalHolidayDatesTableBodyDeleteMutation(
        $holidayId: Int!
        $dealerId: Int!
      ) {
        deleteGlobalHolidayDate(holidayId: $holidayId, dealerId: $dealerId) {
          ... on DeleteGlobalHolidayDateSuccessResponse {
            dealer {
              globalHolidayDates {
                scapiId
                id
                name
                description
                date
                clazz
              }
            }
          }
          ... on DeleteGlobalHolidayDateErrorResponse {
            errorMessage
          }
        }
      }
    `);

  return (
    <>
      <TableRow key={holiday.id}>
        <NameCell>{holiday.name ?? null}</NameCell>
        <DateCell>{holiday.formattedDate}</DateCell>
        <DescriptionCell>{holiday.description}</DescriptionCell>
        <TableDataCell>
          <ButtonsContainer>
            <TableItemIconButton
              name="settings"
              color="var(--color-primary-500)"
              onClick={openEditModal}
              size="2.2rem"
              style={{ marginRight: ".8rem" }}
            />
            <TableItemIconButton
              name="trash"
              color="red"
              size="2.2rem"
              onClick={() => setIsDeleteModalOpen(true)}
            />
          </ButtonsContainer>
        </TableDataCell>
        <TableBodyContainer>
          {isDeleteModalOpen ? (
            <DeleteConfirmModal
              actionPending={deletingHoliday}
              header="Delete Global Holiday"
              cancelText="Cancel"
              confirmText="Delete"
              pendingText="Deleting..."
              onConfirm={() => {
                deleteHolidayMutation({
                  variables: {
                    holidayId: Number(holiday.scapiId),
                    dealerId: Number(dealerId),
                  },
                  onCompleted: (successRes) => {
                    if (successRes.deleteGlobalHolidayDate.errorMessage) {
                      showAlert({
                        type: "error",
                        text: successRes.deleteGlobalHolidayDate.errorMessage,
                      });
                    } else {
                      showAlert({
                        type: "success",
                        text: "Successfully deleted holiday.",
                      });
                    }
                    setIsDeleteModalOpen(false);
                  },
                  onError: (errorRes) => {
                    setIsDeleteModalOpen(false);
                    showAlert({
                      type: "error",
                      text: "Error deleting holiday.",
                    });
                  },
                });
              }}
              onCancel={() => {
                setIsDeleteModalOpen(false);
              }}
            >
              Are you sure you would like to delete this global holiday?
            </DeleteConfirmModal>
          ) : null}
        </TableBodyContainer>
      </TableRow>
      {isEditModalOpen ? (
        <React.Suspense fallback={<></>}>
          <EditGlobalHolidayDatesModal
            closeModal={closeEditModal}
            holidayId={holiday.id}
            dealerId={dealerId}
          />
        </React.Suspense>
      ) : null}
    </>
  );
}

const TableBodyContainer = styled.div`
  background-color: white;
`;
const TableDataCell = styled.td`
  display: flex;
  justify-content: flex-end;
`;
const ButtonsContainer = styled.div`
  width: 5.8rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`;
const TableItemIconButton = styled(Icon)`
  cursor: pointer;
`;
const TableRow = styled.tr``;
const NameCell = styled.td`
  width: 40%;
  max-width: 10rem;
`;
const DateCell = styled.td`
  width: 10%;
`;
const DescriptionCell = styled.td`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 40%;
  max-width: 10rem;
`;
