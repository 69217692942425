import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputInformationFragment } from "./OutputInformationContext";
import { OutputInformationRealTimeStatusField_output$key } from "./__generated__/OutputInformationRealTimeStatusField_output.graphql";

export const outputInformationRealTimeStatusFieldId = (number: string) =>
  `output-information-real-time-status-${number}`;

function OutputInformationRealTimeStatusField() {
  const [{ number, realTimeStatus, isWireless }, updateOutputInformation] =
    useOutputInformationFragment<OutputInformationRealTimeStatusField_output$key>(
      graphql`
        fragment OutputInformationRealTimeStatusField_output on Output {
          number
          realTimeStatus
          isWireless
        }
      `
    );

  const fieldId = outputInformationRealTimeStatusFieldId(String(number));
  const disabled = isWireless;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Real-time Status"
      disabled={disabled}
      tooltip="Enables real-time messages to be sent for this output."
    >
      <Switch
        id={fieldId}
        label="Real-time Status"
        disabled={disabled}
        checked={!!realTimeStatus ?? false}
        onChange={() => {
          updateOutputInformation((recordProxy) => {
            recordProxy.setValue(!realTimeStatus, "realTimeStatus");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputInformationRealTimeStatusField;
