App.factory("DealerKeypadCustomGraphicApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/Dealer/:dealer_id/Keypad/CustomGraphics",
      {},
      {
        getAssociatedCustomGraphic: {
          method: "GET",
          params: { dealer_id: "@dealer_id" },
        },
        createCustomGraphicAssociation: {
          method: "POST",
          params: { dealer_id: "@dealer_id" },
        },
        deleteCustomGraphicAssociation: {
          method: "DELETE",
          params: { dealer_id: "@dealer_id" },
        },
      }
    );
  },
]);
