/**
 * @generated SignedSource<<85faf5ebe15d8bfbca68129584a9491f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteControlSystemOnlineStatusBadge_siteControlSystemQuery$variables = {
  id: string;
};
export type SiteControlSystemOnlineStatusBadge_siteControlSystemQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SiteControlSystemOnlineStatusBadge_siteControlSystem">;
  } | null;
};
export type SiteControlSystemOnlineStatusBadge_siteControlSystemQuery = {
  response: SiteControlSystemOnlineStatusBadge_siteControlSystemQuery$data;
  variables: SiteControlSystemOnlineStatusBadge_siteControlSystemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteControlSystemOnlineStatusBadge_siteControlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SiteControlSystemOnlineStatusBadge_siteControlSystem"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteControlSystemOnlineStatusBadge_siteControlSystemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasCell",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasEasyConnect",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "online",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystemCellStatus",
                "kind": "LinkedField",
                "name": "cellStatus",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystemEasyConnectStatus",
                "kind": "LinkedField",
                "name": "easyConnectStatus",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "type": "SiteControlSystem",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c93e0cf15ee1a5753dd87ab38b3de11",
    "id": null,
    "metadata": {},
    "name": "SiteControlSystemOnlineStatusBadge_siteControlSystemQuery",
    "operationKind": "query",
    "text": "query SiteControlSystemOnlineStatusBadge_siteControlSystemQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SiteControlSystemOnlineStatusBadge_siteControlSystem\n    id\n  }\n}\n\nfragment SiteControlSystemOnlineStatusBadge_siteControlSystem on SiteControlSystem {\n  id\n  hasCell\n  hasEasyConnect\n  online\n  cellStatus {\n    status\n  }\n  easyConnectStatus {\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "d494317a0cce997e948dcda3169ca0de";

export default node;
