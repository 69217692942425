import * as React from "react";

function CSSIcon(props: {
  name:
    | "arming_shield"
    | "armed_shield_solid"
    | "home"
    | "sleep"
    | "away"
    | "all"
    | "perimeter"
    | "burglary"
    | "fire"
    | "police"
    | "lock_unlocked"
    | "lock_locked"
    | "door_chime"
    | "door"
    | "light"
    | "light_solid"
    | "therm_auto"
    | "therm_cool"
    | "therm_heat"
    | "fan"
    | "question"
    | "exclamation"
    | "secura_unlocked"
    | "secura_locked"
    | "bell_chime"
    | "temperature"
    | "favorite"
    | "edit"
    | "sign"
    | "key"
    | "garage_closed"
    | "garage_1"
    | "garage_2"
    | "garage_3"
    | "garage_4"
    | "garage_open"
    | "outlet_output"
    | "rooms"
    | "attention"
    | "exit"
    | "user"
    | "users"
    | "headset_monitoring"
    | "fingerprint"
    | "like"
    | "automation"
    | "sort_vertical"
    | "sort_horizontal"
    | "id_card_profiles"
    | "id_badge_profiles"
    | "date"
    | "date_time"
    | "timer"
    | "box_checked"
    | "box_unchecked"
    | "trash"
    | "credit_card_front"
    | "credit_card_back"
    | "wifi"
    | "id_cards_profiles"
    | "location"
    | "location_solid"
    | "pointer"
    | "pointer_solid"
    | "graph_line"
    | "play"
    | "mail"
    | "magnify_right"
    | "magnify_left"
    | "settings"
    | "gauge"
    | "download"
    | "history"
    | "reset"
    | "power"
    | "brightness_dim"
    | "target"
    | "search_right"
    | "search_left"
    | "brightness"
    | "radial_alert"
    | "radial_question"
    | "radial_check"
    | "radial_ban"
    | "radial_empty"
    | "radial_info"
    | "pie_chart"
    | "cycle"
    | "radial_button"
    | "radial_solid"
    | "radial_add"
    | "radial_subtract"
    | "radial_warning"
    | "time_clock"
    | "more"
    | "menu_list"
    | "radial_mail"
    | "radial_facebook"
    | "radial_twitter"
    | "world"
    | "diagnostics_1"
    | "diagnostics_2"
    | "screen_resize"
    | "personnel"
    | "action"
    | "sd_card"
    | "camera"
    | "zwave"
    | "installer"
    | "plug"
    | "menu"
    | "menu_2"
    | "resize"
    | "switch"
    | "cleaning"
    | "download2"
    | "device"
    | "bar_graph"
    | "line_graph"
    | "delivery"
    | "checkmark"
    | "close_cancel"
    | "add"
    | "subtract"
    | "arrow_back"
    | "arrow_forward"
    | "control_right"
    | "control_left"
    | "control_up"
    | "control_down"
    | "battery_full_horiz"
    | "battery_3_horiz"
    | "battery_4_horiz"
    | "battery_low_horiz"
    | "battery_empty_horiz"
    | "battery_full_vert"
    | "battery_3_vert"
    | "battery_4_vert"
    | "battery_low_vert"
    | "battery_empty_vert"
    | "folder"
    | "doc"
    | "doc_pdf"
    | "doc_file"
    | "news"
    | "manual"
    | "camera2"
    | "cart"
    | "cloud_download"
    | "cloud_upload"
    | "sunrise_sunset"
    | "sunny"
    | "night"
    | "foggy"
    | "partly_cloudy"
    | "night_partly_cloudy"
    | "rainy"
    | "storm"
    | "snow"
    | "desk"
    | "dinner"
    | "leaf"
    | "christmas_tree"
    | "arming_favorite"
    | "child"
    | "travel"
    | "game"
    | "mood"
    | "music"
    | "clip"
    | "christmas_bulb"
    | "movie_night"
    | "box_cancel"
    | "box_small_cancel"
    | "circle_cancel"
    | "bolt"
    | "menu_sliders"
    | "exterior_light"
    | "speaker_full"
    | "speaker_2"
    | "speaker_1"
    | "speaker_off"
    | "speaker_aligned_right"
    | "edit_2"
    | "troubleshooting"
    | "bubble_question"
    | "bubble_exclamation"
    | "bubble_blank"
    | "doorbell"
    | "shipping_box"
    | "microphone"
    | "microphone_mute"
    | "phone"
    | "logger"
    | "city"
    | "server_1"
    | "server_2"
    | "chime"
    | "keyfob_1"
    | "keyfob_2"
    | "circle_arrow_left"
    | "circle_arrow_right"
    | "circle_arrow_up"
    | "circle_arrow_down"
    | "circle_pointer_right"
    | "circle_pointer_left"
    | "circle_pointer_up"
    | "circle_pointer_down"
    | "bypass"
    | "government"
    | "bank"
    | "jewelry_store"
    | "residential"
    | "phone_x"
    | "graphics_keypad"
    | "secura_keypad"
    | "company_store_keypad"
    | "thinline_keypad"
    | "security_command_keypad"
    | "keypad_screen"
    | "security_panel"
    | "arming_shield_new_outline"
    | "cell_signal-full"
    | "cell_signal_4"
    | "cell_signal_5"
    | "cell_signal_6"
    | "cell_signal_7"
    | "favorite_anim_1"
    | "favorite_anim_2"
    | "favorite_anim_3"
    | "favorite_anim_4"
    | "map"
    | "filter"
    | "skip_up"
    | "skip_down"
    | "power_off"
    | "globe_with_pointer"
    | "computer_pointer"
    | "computer_w_pointer"
    | "installer_vertical"
    | "cloud"
    | "globe_lined"
    | "quick_exit"
    | "fast_start_program"
    | "sales_team"
    | "HR"
    | "cycle_clockwise"
    | "fitness"
    | "cell_tower"
    | "spotlight"
    | "training_v2"
    | "arm_inactivity"
    | "security_gateway"
    | "voice_integration"
    | "network"
    | "phone_hang_up"
    | "small_biz"
    | "speaker_mute"
    | "no_cell_signal"
    | "xtl_plus"
    | "share_1"
    | "share_2"
    | "dmp_guide"
    | "install_guide"
    | "programming-guide"
    | "security_keypad"
    | "security_siren"
    | "security_sensor"
    | "visibility"
    | "man_with_tie"
    | "map_dealer_base"
    | "sensors_1106"
    | "low_cell_signal"
    | "rotate_left"
    | "rotate_right"
    | "play_circle"
    | "pause_circle"
    | "play_icon"
    | "pause_icon"
    | "value_feature"
    | "check_in_protection"
    | "central_station"
    | "central_station_communication"
    | "panel_communication"
    | "communication_tower_right"
    | "communication_tower_left"
    | "laptop"
    | "user_codes_finger"
    | "arming_area"
    | "megaphone"
    | "instant_arming"
    | "picture_placeholder"
    | "user_photo_placeholder"
    | "brochure"
    | "invoice_stuffer"
    | "upload"
    | "power_appliance"
    | "modem"
    | "cd"
    | "wireless"
    | "encrypted_wireless"
    | "motion"
    | "critical_thinking"
    | "interview"
    | "tool_hammer"
    | "dmpmoji_smile"
    | "binoculars"
    | "award_ribbon"
    | "dollar_bill"
    | "public_speaker"
    | "handshake"
    | "apartment"
    | "measurement_yard"
    | "security_sign"
    | "playbook"
    | "camera_flip"
    | "stop"
    | "reorder"
    | "service_request"
    | "new_install"
    | "new_tab"
    | "ez_connect"
    | "ez_connect_with_cell"
    | "flash_flood"
    | "tornado"
    | "brain"
    | "alarm_bell_2"
    | "alarm_bell_1"
    | "dmp_building"
    | "tshirt"
    | "wildfire_warning"
    | "residential_lockdown"
    | "remote_update"
    | "door_lockdown"
    | "poe_power_over_ethernet"
    | "4_wire"
    | "confluence_home"
    | "confluence_index"
    | "handshake_dealer_admin"
    | "appliances_off"
    | "appliances_on"
    | "school"
    | "office_building"
    | "345_truck"
    | "346_crosshairs"
    | "output_options"
    | "output_groups"
    | "visibility_off"
    | "all_solid"
    | "vault"
    | "syringe"
    | "wash_hands"
    | "no_touching"
    | "stay_home"
    | "sneeze"
    | "dispose_trash"
    | "hand_sanitizer"
    | "no_dishes_cutlery"
    | "hands_free_open_door"
    | "paper_towel_dispenser"
    | "brightness_volume"
    | "wifi_setting_0_secure"
    | "wifi_setting_1_secure"
    | "wifi_setting_2_secure"
    | "wifi_setting_3_secure"
    | "wifi_setting_4_secure"
    | "wifi_setting_5_secure"
    | "wifi_setting_1"
    | "wifi_setting_2"
    | "wifi_setting_3"
    | "wifi_setting_4"
    | "move_system"
    | "wifi_disconnected"
    | "university"
    | "heart_checkmark"
    | "xtltouch"
    | "xtltouch_wall"
    | "cyber_security"
    | "automation_software"
    | "insurance"
    | "distributor"
    | "compliance_services"
    | "authority_stamp"
    | "legal_services"
    | "pers"
    | "shield_clean"
    | "clips_with_play"
    | "back_to_camera"
    | "back_to_list"
    | "honeywell_panel"
    | "card_reader_1"
    | "card_reader_2"
    | "usa"
    | "support"
    | "church"
    | "family"
    | "hotspot"
    | "military"
    | "takeover"
    | "door_closed"
    | "overview"
    | "sales"
    | "top_ten_ribbon"
    | "top_ten_circle"
    | "new_products__services"
    | "dealer_information"
    | "securecom"
    | "success"
    | "skills"
    | "behavior"
    | "performance"
    | "goals"
    | "carousel"
    | "sa_jacket_reward"
    | "sa_backpack_reward"
    | "sa_coin_reward"
    | "sa_card_holder_reward"
    | "sa_watch_reward"
    | "sa_briefcase_reward"
    | "sa_notepad_reward"
    | "mobile_credentials"
    | "credential_reader";
  className?: string;
}) {
  const { name, className, ...rest } = props;
  return <i {...rest} className={`icon-${name} ${className}`} />;
}

export default CSSIcon;
