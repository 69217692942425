/**
 * @generated SignedSource<<37c7b639873c58e484bfb701771c1e2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly outputInformations: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputSupervision: {
      readonly data: OutputSupervision | null;
      readonly included: boolean | null;
    } | null;
    readonly realTimeStatus: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly tripWithPanelBell: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "6410dfaca0994c1d49f84ee357804e82";

export default node;
