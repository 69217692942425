/**
 * @generated SignedSource<<209a4f15bdc5a66d1b99fb4763dd854b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly bellOptions: {
    readonly automaticBellTest: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary1BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary2BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly bellCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly bellOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly emergencyBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly fireBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly panicBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "8f4fa005e9c0d032fb6081ec9a9d7329";

export default node;
