//Installs By Type Controller
App.controller("InstallsByTypeCtrl", [
  "$scope",
  "$q",
  "$filter",
  "$compile",
  "$rootScope",
  "UserService",
  "DashboardDataService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "PanelDataService",
  "DataTablesUtilsService",
  function (
    $scope,
    $q,
    $filter,
    $compile,
    $rootScope,
    UserService,
    DashboardDataService,
    DTOptionsBuilder,
    DTColumnBuilder,
    PanelDataService,
    DataTablesUtilsService
  ) {
    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(getInstallsByType());
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption("order", [0, "asc"]);

    function getInstallsByType() {
      var deferred = $q.defer();
      DashboardDataService.getInstalls($scope.dealerID, "installation_type")
        .then(
          function (newUser) {
            deferred.resolve(newUser);
          },
          function (error) {
            $rootScope.alerts.push({ type: "error", json: error });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("installation_type").withTitle(
        "Installation Type"
      ),
      DTColumnBuilder.newColumn("primary_installer_full_name").withTitle(
        "Installer"
      ),
      DTColumnBuilder.newColumn("primary_installer_email_address")
        .withTitle("Installer Email")
        .notVisible(),
      DTColumnBuilder.newColumn("sales_person_full_name").withTitle(
        "Salesperson"
      ),
      DTColumnBuilder.newColumn("sales_person_email_address")
        .withTitle("Salesperson Email")
        .notVisible(),
      DTColumnBuilder.newColumn("name").withTitle("System Name"),
      DTColumnBuilder.newColumn(null)
        .withTitle("System Type")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asHardwareModel(
            row,
            "panels[0].hardware_model",
            "panels[0].hardware_model_name"
          );
        }),
      DTColumnBuilder.newColumn("panels[0].comm_type").withTitle(
        "Connection Type"
      ),
      DTColumnBuilder.newColumn("customer.name").withTitle("Customer"),
      DTColumnBuilder.newColumn("panels[0].account_number").withTitle(
        "Account Number"
      ),
      DTColumnBuilder.newColumn("panels[0].serial_number")
        .withTitle("Serial")
        .notVisible(),
      DTColumnBuilder.newColumn("created_at").withTitle("Created").notVisible(),
      DTColumnBuilder.newColumn("postal_code")
        .withTitle("Postal Code")
        .notVisible(),
      DTColumnBuilder.newColumn("address_1").withTitle("Address").notVisible(),
      DTColumnBuilder.newColumn("address_2")
        .withTitle("Address 2")
        .notVisible(),
      DTColumnBuilder.newColumn("city").withTitle("City").notVisible(),
      DTColumnBuilder.newColumn("state").withTitle("State").notVisible(),
      DTColumnBuilder.newColumn("country").withTitle("Country").notVisible(),
    ];
  },
]);
