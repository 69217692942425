import React from "react";
import { joinSpaced } from "utils/string";
import styles from "./PillButton.module.css";

export function PillButtons({ children }: { children: React.ReactNode }) {
  return <div className={styles["pill-buttons"]}>{children}</div>;
}

export function PillButton({
  children,
  ...props
}: { children?: React.ReactNode } & React.HTMLProps<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type="button"
      className={joinSpaced(
        "btn btn-sm btn-add btn-add-custom",
        styles["pill-button"]
      )}
    >
      {children ? (
        <span className={styles["pill-button__text"]}>{children}</span>
      ) : null}
    </button>
  );
}
