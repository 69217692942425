/**
 * @generated SignedSource<<5dc6be570e8cc0db659220b6c1b298a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly outputOptions: {
      readonly ambushOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armAlarmOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedAllOrAwayOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedHomeOrPerimeterOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedSleepOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly beginExitOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly burglaryOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly carbonMonoxideOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly communicationFailOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly coolSaverTemperature: string;
      readonly cutoffOutputs: string;
      readonly disarmedOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly endExitOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly entryDelayOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly fireAlarmOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly fireTroubleOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly heatSaverTemperature: string;
      readonly id: string;
      readonly lateToCloseOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly outputCutoffTime: number;
      readonly outputCutoffTimeMax: number;
      readonly outputCutoffTimeMin: number;
      readonly panicAlarmOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly readyOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly remoteArmingOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly zoneMonitorOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
    } | null;
  };
  readonly " $fragmentType": "TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem";
};
export type TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelOutputOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "4d8a86cc0ca04b6320f6a572cd8f13e4";

export default node;
