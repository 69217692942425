/**
 * @generated SignedSource<<7770f224a28dc41f57f982eb282ecba2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRMenuDisplayProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly menuDisplay: {
      readonly armDisarmDisplayKeypads: string;
      readonly armedStatusDisplayKeypads: string;
      readonly id: string;
      readonly timeDisplayKeypads: string;
    } | null;
  };
  readonly " $fragmentType": "XRMenuDisplayProgrammingConceptFormInline_controlSystem";
};
export type XRMenuDisplayProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRMenuDisplayProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRMenuDisplayProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRMenuDisplayProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "01f67407d8ff80e416e2c6f573f47cb1";

export default node;
