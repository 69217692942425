App.factory("PricingAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/DealerCharges/list/:dealer_id",
      {},
      {
        getPricing: { method: "GET", params: { dealer_id: "@dealer_id" } },
      }
    );
  },
]);
