/**
 * @generated SignedSource<<703a64eeeb5b9e5c66b18e103d07b7da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemAreaInformationClosingCheckField_systemAreaInformation$data = {
  readonly closingCheck: boolean;
  readonly " $fragmentType": "SystemAreaInformationClosingCheckField_systemAreaInformation";
};
export type SystemAreaInformationClosingCheckField_systemAreaInformation$key = {
  readonly " $data"?: SystemAreaInformationClosingCheckField_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemAreaInformationClosingCheckField_systemAreaInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemAreaInformationClosingCheckField_systemAreaInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closingCheck",
      "storageKey": null
    }
  ],
  "type": "SystemAreaInformation",
  "abstractKey": null
};

(node as any).hash = "e7b0cd438d209954588781c53913a42f";

export default node;
