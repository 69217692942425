import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  fetchQuery,
  useFragment,
  useRelayEnvironment,
} from "react-relay/hooks";
import PutSystemOnTestButton from "./PutSystemOnTestButton";
import TakeSystemOffTestButton from "./TakeSystemOffTestButton";
import { SystemTestButton_controlSystem$key } from "./__generated__/SystemTestButton_controlSystem.graphql";

const SystemTestButton: React.FC<
  React.PropsWithChildren<{
    controlSystem: SystemTestButton_controlSystem$key;
  }>
> = ({ controlSystem: fragmentRef }) => {
  const { id, currentPersonCan, centralStationAutomationIntegration } =
    useFragment(
      graphql`
        fragment SystemTestButton_controlSystem on ControlSystem {
          id
          currentPersonCan {
            placeSystemOnTest
          }
          centralStationAutomationIntegration {
            id
            systemTestExpiresAt
            systemTestsEnabled
          }
        }
      `,
      fragmentRef
    );
  const env = useRelayEnvironment();

  React.useEffect(() => {
    /*  
      even though this is prefetched in the index file, we also want to always
      want to manually refresh it so the data is not stale if they navigate
      away and come back
    */
    fetchQuery(
      env,
      graphql`
        query SystemTestQuery($systemId: ID!) {
          node(id: $systemId) {
            ... on ControlSystem {
              ...SystemTestButton_controlSystem
            }
          }
        }
      `,
      { systemId: id },
      { fetchPolicy: "network-only" }
    ).toPromise();
  }, [env, id]);

  return centralStationAutomationIntegration?.systemTestsEnabled ? (
    centralStationAutomationIntegration.systemTestExpiresAt ? (
      <TakeSystemOffTestButton
        systemId={id}
        systemTestExpiresAt={
          centralStationAutomationIntegration.systemTestExpiresAt
        }
        integrationId={centralStationAutomationIntegration.id}
      />
    ) : (
      <PutSystemOnTestButton systemId={id} />
    )
  ) : null;
};

export default SystemTestButton;
