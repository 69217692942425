/**
 * @generated SignedSource<<d7dd3073b8b94d063a59e695993372a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemAreaInformationOpenClosingReportsField_systemAreaInformation$data = {
  readonly openClosingReports: boolean;
  readonly " $fragmentType": "SystemAreaInformationOpenClosingReportsField_systemAreaInformation";
};
export type SystemAreaInformationOpenClosingReportsField_systemAreaInformation$key = {
  readonly " $data"?: SystemAreaInformationOpenClosingReportsField_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemAreaInformationOpenClosingReportsField_systemAreaInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemAreaInformationOpenClosingReportsField_systemAreaInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openClosingReports",
      "storageKey": null
    }
  ],
  "type": "SystemAreaInformation",
  "abstractKey": null
};

(node as any).hash = "c35f2ad78f322f11bfa286331452ac60";

export default node;
