/**
 *
 * HomeIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const HomeIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M63.064,23.154L33.067,4.307c-0.024-0.015-0.053-0.02-0.078-0.034c-0.125-0.071-0.254-0.128-0.39-0.171
      c-0.026-0.008-0.05-0.019-0.076-0.025c-0.339-0.092-0.699-0.092-1.047,0.003c-0.025,0.007-0.049,0.012-0.073,0.02
      c-0.161,0.051-0.318-0.045-0.467,0.049L30.917,4c0,0-0.001,0-0.001,0L30.9,4.169l-9.9,6.38V8c0-1.104-0.896-2-2-2h-9
      C8.896,6,8,6.896,8,8v10.716l-6.972,4.38l-0.087,0.054c0,0-0.001,0.001-0.002,0.001l-0.003,0.002
      c-0.135,0.084-0.251,0.186-0.358,0.294c-0.03,0.031-0.056,0.065-0.084,0.098c-0.074,0.086-0.141,0.176-0.198,0.271
      c-0.025,0.041-0.048,0.081-0.07,0.124c-0.054,0.105-0.097,0.214-0.131,0.328c-0.011,0.034-0.025,0.065-0.033,0.1
      c-0.037,0.148-0.056,0.301-0.058,0.454C0.003,24.831,0,24.839,0,24.848V30c0,1.104,0.896,2,2,2h3v23c0,1.104,0.896,2,2,2h50.896
      c1.102,0,1.996-0.891,2-1.992L59.992,32H62c1.104,0,2-0.896,2-2v-5.152C64,24.16,63.646,23.52,63.064,23.154z M12,10h5v3.062
      l-5,3.142V10z M60,28h-2c-1.102,0-1.996,0.891-2,1.992L55.905,53H37V36.5c0-1.381-1.119-2.5-2.5-2.5h-4
      c-1.381,0-2.5,1.119-2.5,2.5V53H9V30c0-1.104-0.896-2-2-2H4v-2.043L32.014,8.369L60,25.953V28z"
        />
        <path
          d="M44.5,44h5c1.381,0,2.5-1.119,2.5-2.5v-5c0-1.381-1.119-2.5-2.5-2.5h-5c-1.381,0-2.5,1.119-2.5,2.5v5
      C42,42.881,43.119,44,44.5,44z"
        />
        <path
          d="M20.5,34h-5c-1.381,0-2.5,1.119-2.5,2.5v5c0,1.381,1.119,2.5,2.5,2.5h5c1.381,0,2.5-1.119,2.5-2.5v-5
      C23,35.119,21.881,34,20.5,34z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default HomeIcon;
