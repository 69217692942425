/**
 * @ngdoc filter
 * @name App.filter:zpad
 *
 * @description
 * Returns a left 0 padded version of the input param, padded to the given length
 *
 * @param {string} input String to be padded
 * @param {Number} n length of string after padding
 *
 * @returns {string} Zero Padded string
 *
 */
App.filter("zpad", function () {
  return function (input, n) {
    if (input === undefined) {
      input = "";
    }
    if (input.length >= n) {
      return input;
    }

    for (var zeros = ""; zeros.length < n; ) {
      zeros += "0";
    }
    return (zeros + input).slice(-1 * n);
  };
});

/**
 * @name App.filter: zpadIPV6
 *
 * @description
 * Takes an un-formatted IPV6 and returns each field with a left 0 pad up to 4 digits.
 *
 * @param {string} IPV6 to be padded
 * @param {Number} n length of string after padding
 *
 * @returns {string} Zero Padded IPV6
 *
 */
App.filter("zpadIPV6", function () {
  return function (input) {
    if (input === undefined || input === "") {
      return "";
    }

    let inputArr = input.split(":");
    let numEmptySubsets = 8 - inputArr.length;
    let doubleColonIndex = inputArr.indexOf("");

    if (doubleColonIndex >= 0) {
      inputArr.splice(doubleColonIndex, 1);
      for (let i = 0; i < numEmptySubsets + 1; i++) {
        inputArr.splice(doubleColonIndex + i, 0, "0000");
      }
    }

    for (let i = 0; i < inputArr.length; i++) {
      let numZeros = 4 - inputArr[i].length;
      if (numZeros < 4) {
        for (var zeros = ""; zeros.length < numZeros; ) {
          zeros += "0";
        }
        inputArr[i] = zeros + inputArr[i];
      }
    }
    return inputArr.join(":");
  };
});

App.filter("titlecase", function () {
  return function (input) {
    var smallWords =
      /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

    input = input || "";

    return input
      .toLowerCase()
      .replace(
        /[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g,
        function (match, index, title) {
          if (
            index > 0 &&
            index + match.length !== title.length &&
            match.search(smallWords) > -1 &&
            title.charAt(index - 2) !== ":" &&
            title.charAt(index - 2) !== "." &&
            (title.charAt(index + match.length) !== "-" ||
              title.charAt(index - 1) === "-") &&
            title.charAt(index - 1).search(/[^\s-]/) < 0
          ) {
            return match.toLowerCase();
          }

          return match.charAt(0).toUpperCase() + match.substr(1);
        }
      );
  };
});

/**
 * Filter : hyphenscoretospaces
 * function: takes a string that contains hyphens or underscores (for spaces) and returns a space instead
 * purpose: Created for text-formatting items out of the styleguide json.
 */

App.filter("hyphenscoretospaces", function () {
  return function (input) {
    if (input) {
      return input.replace(/[-]|[_]/g, " ");
    }
  };
});

App.filter("numOpenJobs", function () {
  return function (input) {
    if (input) {
      let numberOpenJobs = 0;
      for (let day of input) {
        for (let request of day) {
          if (request.Status === "open") {
            numberOpenJobs++;
          }
        }
      }
      return numberOpenJobs;
    } else {
      return;
    }
  };
});

App.filter("customerRating", function () {
  return function (input) {
    if (input) {
      if (input <= 5.5) {
        return "Below Average";
      } else if (input <= 6.5 && input > 5.5) {
        return "Average";
      } else if (input <= 7.5 && input > 6.5) {
        return "Good";
      } else if (input <= 8.5 && input > 7.5) {
        return "Great";
      } else {
        return "Excellent";
      }
    } else {
      return;
    }
  };
});

App.filter("custRoleType", function () {
  return function (input) {
    switch (input) {
      case 1:
        return "Technician";
      case 3:
        return "Custom";
    }
  };
});

App.filter("apostropheFromAscii", function () {
  return function (input) {
    if (input) {
      return input.replace(/(&apos;)/g, "'");
    }
  };
});

App.filter("control_system_comm_type", function () {
  return function (input) {
    if (typeof input === "undefined" || input === null) {
      return "";
    }
    if (input === "cell" || input === "Cellular") {
      return "Cellular"; //TODO: Internationalize These!
    } else if (input.toLowerCase() === "network") {
      return "Network";
    } else if (
      input === "persistent" ||
      input.toLowerCase() === "easyconnect"
    ) {
      return "EASYconnect";
    } else if (
      input === "persistent_w_cell_backup" ||
      input.toLowerCase() === "easyconnect + cell backup"
    ) {
      return "EASYconnect + Cell Backup";
    } else {
      return "Unknown";
    }
  };
});

App.filter("control_system_comm_type_reverse", function () {
  return function (input) {
    switch (input.toUpperCase()) {
      case "CELLULAR":
        return "cell";
      case "NETWORK":
        return "network";
      case "EASYCONNECT":
        return "persistent";
      case "EASYCONNECT + CELL BACKUP":
        return "persistent_w_cell_backup";
      default:
        return input;
    }
  };
});

App.filter("control_system_hardware_model", function () {
  return function (input, hardware_model_name) {
    if (
      typeof hardware_model_name === "undefined" ||
      hardware_model_name === null
    )
      hardware_model_name = "";
    switch (input) {
      case "XTLP":
        return hardware_model_name === "XTLtouch"
          ? hardware_model_name
          : "XTLplus";
      case "X001":
        return "X1";
      case "XF6_100":
      case "XF6_500":
        return input.replace("_", "-");
      case "TMS6":
        return "TMSentry";
      default:
        return input;
    }
  };
});
App.filter("control_system_country_code", function () {
  return function (input) {
    if (input == null || input == "") {
      return "United States (+1)"; //TODO: Internationalize These!
    } else if (input == "52") {
      return "Mexico (+52)";
    } else {
      return input;
    }
  };
});
App.filter("store_dash_order_type", function () {
  return function (input) {
    if (input == "diy") {
      return "Company Store";
    } else if (input == "secura") {
      return "Secura";
    } else if (input == "sales_app") {
      return "Sales App";
    } else {
      return input;
    }
  };
});
App.filter("checkmark_unicode", function () {
  return function getCheckmark(input) {
    return input ? "\u2714" : "";
  };
});
App.filter("dmp_access_type", function () {
  return function (input) {
    if (input == "dmp") {
      return "DMP";
    } else if (input == "secura") {
      return "Secura";
    } else if (input == "company_store") {
      return "Company Store";
    }
    return input;
  };
});

App.filter("customer_age", function () {
  return function (input) {
    if (typeof input == "undefined") return;
    let curr = dateTimeForceUTC(new Date());
    let createdAtDate = dateTimeForceUTC(new Date(Date.parse(input)));
    let diffDate = dateTimeForceUTC(new Date(curr - createdAtDate));
    if (diffDate.getUTCFullYear() === 1970) {
      return `${diffDate.getUTCMonth()}mo`;
    } else {
      return `${
        diffDate.getUTCFullYear() - 1970
      }yr ${diffDate.getUTCMonth()}mo`;
    }
  };
});

App.filter("carrier_codes", [
  function () {
    return function (input, carrier, type) {
      if (/Verizon/i.test(carrier)) {
        //if (input == 'true') {  // does not match when input contains string 'true',  double/single quotes and === all fail also
        //if (angular.equals('true', input)) {    // does not match when input contains string 'true' - commented for documentation purposes

        if (type == "connected" && /true/i.test(input)) return "CONNECTED";
        if (type == "connected" && !/true/i.test(input)) return "NOT CONNECTED";
        if (type == "status" && input == "active") return "Active";
        if (type == "status" && input != "active") return "Inactive";
        return input;
      }
    };
  },
]);

/**
 * @ngdoc filter
 * @name App.filter:unique_select_options
 *
 * @description
 * This is used when you have multiple select fields that are pulling options from 1 collection.  This will remove options
 * that match the given expression, and will also add back the current value to the list (so that it will appear as the
 * selected item in the select list.
 *
 * @param {Object} collection Object or Array that contains the options
 * @param {Expression} expression An expression that returns a boolean which determines whether to include the value or not
 * @param {string} current_value The current option that is chosen on _this_ select
 *
 * TODO: Works with an Array as the collection, but has issues with Objects.
 *
 */
App.filter("unique_select_options", [
  "$parse",
  function ($parse) {
    return function (collection, expression, current_value) {
      /**
       * @description
       * get an object and return array of values
       * @param object
       * @returns {Array}
       */
      function toArray(object) {
        return angular.isArray(object)
          ? object
          : Object.keys(object).map(function (key) {
              return object[key];
            });
      }

      collection = angular.isObject(collection)
        ? toArray(collection)
        : collection;

      if (!angular.isArray(collection) || angular.isUndefined(expression)) {
        return collection;
      }

      return collection.filter(function (elm) {
        return !$parse(expression)(elm) || elm == current_value;
      });
    };
  },
]);

App.filter("trusted", [
  "$sce",
  function ($sce) {
    return function (url) {
      return $sce.trustAsResourceUrl(url);
    };
  },
]);

App.filter("checkmark", function () {
  return function getCheckmark(input) {
    return input ? '<i class="text-success icon-checkmark"></i>' : "";
  };
});

App.filter("billing", [
  "$filter",
  function ($filter) {
    return function getCheckmark(input) {
      if (input === 0) {
        return "Free";
      } else if (!input) {
        return "";
      } else {
        return `${$filter("currency")(input)}/mo`;
      }
    };
  },
]);

App.filter("jobType", function () {
  return function getJobType(input) {
    switch (input) {
      case "install":
        return "New Install";
      case "service":
        return "Service Request";
      default:
        return input;
    }
  };
});

App.filter("replaceSingleQuotes", function () {
  var entityMap = {
    "'": '"',
    "&apos;": "'",
    "&#39;": "'",
    "&#x27;": "'",
  };

  return function (str) {
    return String(str).replace(
      /(')|(&apos;) |(&#39;) |(&#x27;)/g,
      function (s) {
        return entityMap[s];
      }
    );
  };
});

App.filter("reverse", function () {
  return function (items) {
    return items.slice().reverse();
  };
});

App.filter("twilightOffsetPhrase", function () {
  return function (offset) {
    if (offset < 0) {
      return Math.abs(offset) + " min. before";
    } else if (offset == 0 || offset == null) {
      return "at";
    } else {
      return Math.abs(offset) + " min. after";
    }
  };
});

App.filter("techTrackTechList", function () {
  return function (day) {
    let trackingDay = new Date(
      new Date().setDate(new Date().getDate() - parseInt(day))
    );
    return trackingDay;
  };
});

App.filter("comm_path_type", function () {
  return function (input) {
    if (input == "P") {
      return "Primary";
    } else if (input == "B") {
      return "Backup";
    } else {
      return "Unknown";
    }
  };
});

App.filter("vkAdditionalFeatures", function () {
  return function (input) {
    switch (input) {
      case "full_app_enabled":
        return "Standard App";
      case "arming_app_enabled":
        return "Arming App";
      case "automation_enabled":
        return "Home Automation";

      case "thermostat_control_enabled":
        return "Z-Wave Thermostat";

      // case 'basic_video_enabled':
      //   return null;

      case "output_option_edit_enabled":
        return "Visible Outputs";

      case "zwave_node_edit_enabled":
        return "Z-Wave Edit";

      case "favorite_edit_enabled":
        return "Favorites";

      case "sensor_activity_enabled":
        return "Sensor Activity";

      // case 'four_additional_video_channels_enabled':
      //   return null;
      case "zwave_sensor_enabled":
        return "Z-Wave Sensors";

      case "premium_video_enabled":
        return "Premium Video";

      case "light_control_enabled":
        return "Z-Wave Lights";

      case "lock_control_enabled":
        return "Z-Wave Locks";

      case "geofencing_enabled":
        return "Geofences";

      case "user_code_edit_enabled":
        return "User Code Mgt";

      case "schedule_edit_enabled":
        return "Schedule Mgt";

      case "door_edit_enabled":
        return "Door Control";

      case "traffic_count_enabled":
        return "Traffic Count";

      case "real_time_events_enabled":
        return "Advanced Rpt";

      case "store_user_codes":
        return "User Codes Mgt";

      case "standard_hikvision_nvr_enabled":
        return "Hikvision NVR";

      case "open_eye_enabled":
        return "OpenEye";

      case "hikvision_doorbell_enabled":
        return "SecureCom Vd Doorbell";

      case "eagle_eye_enabled":
        return "Eagle Eye";

      case "ring_enabled":
        return "Ring Doorbell";

      case "video_verification_enabled":
        return "Video Verification";

      case "standard":
        return "Video (8 Cameras)";

      case "standard_plus_four":
        return "Video (12 Cameras)";

      case "standard_plus_eight":
        return "Video (16 Cameras)";

      case "406":
        return "Rate Plan (406)";

      case "408":
        return "Rate Plan (408)";

      case "416":
        return "Rate Plan (416)";

      case "425":
        return "Rate Plan (425)";

      case "435":
        return "Rate Plan (435)";

      case "445":
        return "Rate Plan (445)";

      case "Backup":
        return "Rate Plan (Backup)";

      case "CellComSL":
        return "Rate Plan (CellComSL)";

      case "CellComEX":
        return "Rate Plan (CellComSL)";

      case "410":
        return "Rate Plan (Fire)";

      case "XR":
        return "Rate Plan (XR)";

      case "XT":
        return "Rate Plan (XT)";

      case "XTL":
        return "Rate Plan (XTL)";

      case "video_level":
        return "SecureCom Cameras & NVR";

      default:
        return input;
    }
  };
});

App.filter("device_comm_type", function () {
  return function (input) {
    if (input == "K") {
      return "Keypad Bus";
    } else if (input == "N") {
      return "Network";
    } else if (input == "W") {
      return "Wireless";
    } else {
      return "Unknown";
    }
  };
});

App.filter("comm_type", function () {
  return function (input) {
    if (input == "") {
      return "None";
    } else if (input == "W") {
      return "WIFI";
    } else if (input == "C") {
      return "CID";
    } else if (input == "D") {
      return "DD";
    } else if (input == "N") {
      return "NET";
    } else if (input == "L") {
      return "CELL";
    } else {
      return "Unknown";
    }
  };
});

App.filter("device_type", function () {
  return function (input) {
    switch (input) {
      case "1":
        return "Door";
      case "2":
        return "Fire";
      case "3":
        return "Keypad";
      case "4":
        return "Zone Expander";
      case "6":
        return "V-Plex";
      case "7":
        return "1100T";
      case "8":
        return "XR Panel";
      default:
        return "Unknown";
    }
  };
});

App.filter("telephone", function () {
  return function (input) {
    if (input) {
      switch (input.length) {
        case 5:
        case 6:
        case 7:
          return `${input.slice(0, input.length - 4)}-${input.slice(
            input.length - 4,
            input.length
          )}`;
        case 8:
        case 9:
        case 10:
          return `(${input.slice(0, input.length - 7)}) ${input.slice(
            input.length - 7,
            input.length - 4
          )}-${input.slice(input.length - 4, input.length)}`;
        case 11:
          return `${input.slice(0, input.length - 10)} (${input.slice(
            input.length - 10,
            input.length - 7
          )}) ${input.slice(input.length - 7, input.length - 4)}-${input.slice(
            input.length - 4,
            input.length
          )}`;
        default:
          return input;
      }
    } else {
      return;
    }
  };
});

/**
 * Converts a comma separate string of numbers to a ranged string of numbers
 * eg.: "1,2,3,7,8,10,11,12" -> "1-3,7-8,10-12"
 */
App.filter("commas_to_range", function () {
  return function (input) {
    if (angular.isDefined(input)) {
      var theCSV = input;
      if (theCSV.indexOf(",") < 0) return theCSV;

      function getRanges(array) {
        var ranges = [],
          rstart,
          rend;
        for (var i = 0; i < array.length; i++) {
          rstart = array[i];
          rend = rstart;
          while (array[i + 1] - array[i] == 1) {
            rend = array[i + 1]; // increment the index if the numbers sequential
            i++;
          }
          ranges.push(rstart == rend ? rstart + "" : rstart + "-" + rend);
        }
        return ranges;
      }

      //Convert the CSV to an array of values
      return getRanges(theCSV.split(",")).join(",");
    }
  };
});

App.filter("telephone", function () {
  return function (input) {
    if (angular.isDefined(input) && input !== null) {
      switch (input.length) {
        case 5:
        case 6:
        case 7:
          return `${input.slice(0, input.length - 4)}-${input.slice(
            input.length - 4,
            input.length
          )}`;
        case 8:
        case 9:
        case 10:
          return `(${input.slice(0, input.length - 7)}) ${input.slice(
            input.length - 7,
            input.length - 4
          )}-${input.slice(input.length - 4, input.length)}`;
        case 11:
          return `${input.slice(0, input.length - 10)} (${input.slice(
            input.length - 10,
            input.length - 7
          )}) ${input.slice(input.length - 7, input.length - 4)}-${input.slice(
            input.length - 4,
            input.length
          )}`;
        default:
          return input;
      }
    } else {
      return;
    }
  };
});

/**
 * returns a line of text that will truncate  on character count but break on words.
 Pass true to  break on exact char count
 */
App.filter("characters", function () {
  return function (input, chars, breakOnChar) {
    if (isNaN(chars)) return input;
    if (chars <= 0) return "";
    if (input && input.length > chars) {
      input = input.substring(0, chars);

      if (!breakOnChar) {
        var lastspace = input.lastIndexOf(" ");
        //get last space
        if (lastspace !== -1) {
          input = input.substr(0, lastspace);
        }
      } else {
        while (input.charAt(input.length - 1) === " ") {
          input = input.substr(0, input.length - 1);
        }
      }
      return input + "…";
    }
    return input;
  };
});

/**
 * returns a line of text that will truncate  on word count
 * */
App.filter("words", function () {
  return function (input, words) {
    if (isNaN(words)) return input;
    if (words <= 0) return "";
    if (input) {
      var inputWords = input.split(/\s+/);
      if (inputWords.length > words) {
        input = inputWords.slice(0, words).join(" ") + "…";
      }
    }
    return input;
  };
});

App.filter("authority_levels", function () {
  return function (input) {
    switch (input) {
      case null:
        return "Standard";
      case "admin":
        return "Administrator";
      case "technician":
        return "Technician";
      case "support":
        return "Customer Support";
      case "operator":
        return "Operator";
      case "sales_person":
        return "Sales Person";
      case "sales_manager":
        return "Sales Manager";
      case "video_verifier":
        return "Video Verifier";
      case "accountant":
        return "Accountant";
      case "access_only":
        return "Access Only";
      default:
        return input;
    }
  };
});

App.filter("chart_name", function () {
  return function (input) {
    switch (input) {
      case "LineChart":
        return "Line";
      case "AreaChart":
        return "Area";
      case "PieChart":
        return "Pie";
      case "BarChart":
        return "Bar";
      case "ColumnChart":
        return "Column";
      default:
        return input;
    }
  };
});

App.filter("unique", function () {
  return function (items, filterOn) {
    if (filterOn === false) {
      return items;
    }

    if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
      var hashCheck = {},
        newItems = [];

      var extractValueToCompare = function (item) {
        if (angular.isObject(item) && angular.isString(filterOn)) {
          return item[filterOn];
        } else {
          return item;
        }
      };

      angular.forEach(items, function (item) {
        var valueToCheck,
          isDuplicate = false;

        for (var i = 0; i < newItems.length; i++) {
          if (
            angular.equals(
              extractValueToCompare(newItems[i]),
              extractValueToCompare(item)
            )
          ) {
            isDuplicate = true;
            break;
          }
        }
        if (!isDuplicate) {
          newItems.push(item);
        }
      });
      items = newItems;
    }
    return items;
  };
});

App.filter("panelProgBackupType", function () {
  return function (type) {
    switch (type) {
      case "Original":
        return "Initial";
      case "Scheduled":
      case "Manual":
      default:
        return "Backup";
    }
  };
});

App.filter("splitPascalCase", [
  function () {
    return function (input) {
      if (typeof input !== "string") {
        return input;
      }

      return input
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase());
    };
  },
]);

/**
 * Converts a description from [scw_payment_api].[dbo].[payments].[description]
 * based on whether it's an auto-pay or one-time payment.
 */
App.filter("paymentDescription", function () {
  const AutoPayDescription = "SECURECOM AUTO PYMNT";
  const OneTimePayDescription = "SECURECOM WEB PYMNT";

  return function (input) {
    if (input === AutoPayDescription) {
      return "Auto-Pay";
    } else if (input === OneTimePayDescription) {
      return "One-Time";
    }

    // Unknown, just return description unchanged
    return input;
  };
});

App.filter("featureKeys", function ($filter) {
  return function (input) {
    if (typeof input === "string") {
      switch (input) {
        case "door_add_on_a":
          return "64 Doors";
        case "door_add_on_b":
          return "96 Doors";
        default:
          return input
            .split("_")
            .map((string) => $filter("titlecase")(string))
            .join(" ");
      }
    }
  };
});

App.filter("addSpaceAfterComma", function () {
  return function (str) {
    if (str) {
      return str.replace(/,/g, ", ");
    }
  };
});
App.filter("wireless_translator_1100t_frequency", function () {
  return function (input) {
    if (input === "1") {
      return "2GIG";
    } else if (input === "2") {
      return "INTERLOGIX";
    } else if (input === "3") {
      return "DSC";
    } else if (input === "0") {
      return "HONEYWELL";
    } else return "Unknown";
  };
});
