/**
 * @generated SignedSource<<02b8bde31da64fb127bcedcefe875fb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTOutputOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly outputOptions: {
      readonly __typename: "OutputOptions";
      readonly ambushOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armAlarmOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedAllOrAwayOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedHomeOrPerimeterOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly armedSleepOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly beginExitOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly burglaryOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly carbonMonoxideOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly closingWaitOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly communicationFailOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly coolSaverTemperature: string;
      readonly cutoffOutputs: string;
      readonly deviceFailOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly disarmedOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly endExitOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly entryDelayOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly fireAlarmOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly fireTroubleOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly heatSaverTemperature: string;
      readonly id: string;
      readonly lateToCloseOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly lockdownOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly outputCutoffTime: number;
      readonly outputCutoffTimeMax: number;
      readonly outputCutoffTimeMin: number;
      readonly panicAlarmOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly readyOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly remoteArmingOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly sensorResetOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly supervisoryAlarmOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly telephoneTroubleOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly zoneMonitorOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
    } | null;
  };
  readonly " $fragmentType": "XTOutputOptionsProgrammingConceptFormInline_controlSystem";
};
export type XTOutputOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTOutputOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTOutputOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTOutputOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "be0e6a7265b2dd5f5fe1d21af8bf7b95";

export default node;
