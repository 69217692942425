App.controller("ColorPaletteCtrl", ColorPaletteCtrl);

ColorPaletteCtrl.$inject = ["$rootScope", "$scope", "StyleDataAccessService"];

function ColorPaletteCtrl($rootScope, $scope, StyleDataAccessService) {
  init();
  function init() {
    StyleDataAccessService.getStyleData("color-data.json")
      .then(
        function (colorData) {
          $scope.ColorChipFamilies = colorData;
        },
        function (colorError) {
          $rootScope.alerts.push({ type: "error", message: colorError });
        }
      )
      .catch(function (error) {
        console.error(error);
      });
  }
}
