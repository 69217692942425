/**
 * Indicator
 * @author Chad Watson
 *
 */
import React from "react";
import styled from "styled-components";
import { seconds } from "../../../utils/dates";
import Tooltip from "../Tooltip";
const Root = styled.div`
  position: relative;
  width: 1em;
  cursor: ${({ hasMessage }) => (hasMessage ? "help" : "default")};
  pointer-events: ${({ canOpen }) => (canOpen ? "initial" : "none")};

  &:not(:last-child) {
    margin-right: 7px;
  }
`;
const Message = styled.div`
  width: ${({ width }) => width}px;
  padding: 0.5em 0.75em;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
`;
const IconWrapper = styled.span`
  display: inline-block;
`;

class Indicator extends React.Component {
  static defaultProps = {
    messageWidth: 140,
    message: null,
    className: "",
  };
  state = {
    open: false,
    canOpen: true,
  };
  timer = null;

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  close = () => {
    this.setState({
      open: false,
      canOpen: false,
    });
  };
  handleMouseEnter = () => {
    this.setState({
      open: true,
    });
  };
  handleMouseLeave = () => {
    this.close();
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(
      () =>
        this.setState({
          canOpen: true,
        }),
      seconds(0.2)
    );
  };
  handleTooltipClose = () =>
    this.setState({
      canOpen: true,
    });

  render() {
    const Icon = this.props.icon;
    return (
      <Root
        className={this.props.className}
        hasMessage={!!this.props.message}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        canOpen={this.state.canOpen}
      >
        <Tooltip
          anchor={({ registerAnchor }) => (
            <IconWrapper ref={registerAnchor}>
              <Icon />
            </IconWrapper>
          )}
          open={!!this.props.message && this.state.open}
          onDismiss={this.close}
          onClose={this.handleTooltipClose}
        >
          <Message width={this.props.messageWidth}>
            {this.props.message}
          </Message>
        </Tooltip>
      </Root>
    );
  }
}

export default Indicator;
