import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { keyfobButtonActionFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobButtonActionField";
import { keyfobButtonAreasFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobButtonAreasField";
import { keyfobButtonOutputActionFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobButtonOutputActionField";
import { keyfobButtonOutputFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobButtonOutputField";
import { keyfobButtonPressTimeFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobButtonPressTimeField";
import {
  keyfobListItemTemplateId,
  keyfobNumberFieldId,
} from "components/FullProgramming/common/KeyfobFields/KeyfobNumberField";
import { keyfobNumberOfButtonsFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobNumberOfButtonsField";
import { keyfobSupervisionTimeFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobSupervisionTimeField";
import { keyfobUserNumberFieldId } from "components/FullProgramming/common/KeyfobFields/KeyfobUserNumberField";
import { XTKeyfobProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XTFullProgramming/XTKeyfobProgrammingConceptForm/__generated__/XTKeyfobProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  getKeyfobState,
  getState as getKeyfobsState,
} from "../../../XTFullProgramming/XTKeyfobProgrammingConceptForm";
import { ProgrammingTemplateKeyfobInput } from "../Create/__generated__/XTTemplateSaveMutation.graphql";
import { XTKeyfobsTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTKeyfobsTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XTKeyfobsTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTKeyfobsTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        keyfobs {
          id
          included
          number
          userNumber {
            included
            data
          }
          buttonOneAction {
            included
            data
          }
          buttonTwoAction {
            included
            data
          }
          buttonThreeAction {
            included
            data
          }
          buttonFourAction {
            included
            data
          }
          buttonOneAreas {
            included
            data
          }
          buttonTwoAreas {
            included
            data
          }
          buttonThreeAreas {
            included
            data
          }
          buttonFourAreas {
            included
            data
          }
          numberOfButtons {
            included
            data
          }
          buttonOneOutputAction {
            included
            data
          }
          buttonTwoOutputAction {
            included
            data
          }
          buttonThreeOutputAction {
            included
            data
          }
          buttonFourOutputAction {
            included
            data
          }
          buttonOneOutput {
            included
            data
          }
          buttonTwoOutput {
            included
            data
          }
          buttonThreeOutput {
            included
            data
          }
          buttonFourOutput {
            included
            data
          }
          buttonOnePressTime {
            included
            data
          }
          buttonTwoPressTime {
            included
            data
          }
          buttonThreePressTime {
            included
            data
          }
          buttonFourPressTime {
            included
            data
          }
          keyfobSupervisionTime {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XTKeyfobProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateKeyfobInput[] => {
  const {
    panel: { keyfobs },
  } = getKeyfobsState(controlSystem);

  return keyfobs
    ? keyfobs
        .filter(isNotNullOrUndefined)
        .map(getKeyfobState)
        .map((keyfob) => ({
          included: includedFields.has(
            keyfobListItemTemplateId(String(keyfob.number))
          ),
          number: keyfob.number,
          userNumber: {
            included: includedFields.has(
              keyfobUserNumberFieldId(String(keyfob.number))
            ),
            data: keyfob.user?.number,
          },
          buttonOneAction: {
            included: includedFields.has(
              keyfobButtonActionFieldId("One", String(keyfob.number))
            ),
            data: keyfob.buttonOneAction,
          },
          buttonTwoAction: {
            included: includedFields.has(
              keyfobButtonActionFieldId("Two", String(keyfob.number))
            ),
            data: keyfob.buttonTwoAction,
          },
          buttonThreeAction: {
            included: includedFields.has(
              keyfobButtonActionFieldId("Three", String(keyfob.number))
            ),
            data: keyfob.buttonThreeAction,
          },
          buttonFourAction: {
            included: includedFields.has(
              keyfobButtonActionFieldId("Four", String(keyfob.number))
            ),
            data: keyfob.buttonFourAction,
          },
          buttonOneAreas: {
            included: includedFields.has(
              keyfobButtonAreasFieldId("One", String(keyfob.number))
            ),
            data: keyfob.buttonOneAreas,
          },
          buttonTwoAreas: {
            included: includedFields.has(
              keyfobButtonAreasFieldId("Two", String(keyfob.number))
            ),
            data: keyfob.buttonTwoAreas,
          },
          buttonThreeAreas: {
            included: includedFields.has(
              keyfobButtonAreasFieldId("Three", String(keyfob.number))
            ),
            data: keyfob.buttonThreeAreas,
          },
          buttonFourAreas: {
            included: includedFields.has(
              keyfobButtonAreasFieldId("Four", String(keyfob.number))
            ),
            data: keyfob.buttonFourAreas,
          },
          numberOfButtons: {
            included: includedFields.has(
              keyfobNumberOfButtonsFieldId(String(keyfob.number))
            ),
            data: keyfob.numberOfButtons,
          },
          buttonOneOutputAction: {
            included: includedFields.has(
              keyfobButtonOutputActionFieldId("One", String(keyfob.number))
            ),
            data: keyfob.buttonOneOutputAction,
          },
          buttonTwoOutputAction: {
            included: includedFields.has(
              keyfobButtonOutputActionFieldId("Two", String(keyfob.number))
            ),
            data: keyfob.buttonTwoOutputAction,
          },
          buttonThreeOutputAction: {
            included: includedFields.has(
              keyfobButtonOutputActionFieldId("Three", String(keyfob.number))
            ),
            data: keyfob.buttonThreeOutputAction,
          },
          buttonFourOutputAction: {
            included: includedFields.has(
              keyfobButtonOutputActionFieldId("Four", String(keyfob.number))
            ),
            data: keyfob.buttonFourOutputAction,
          },
          buttonOneOutput: {
            included: includedFields.has(
              keyfobButtonOutputFieldId("One", String(keyfob.number))
            ),
            data: keyfob.buttonOneOutput,
          },
          buttonTwoOutput: {
            included: includedFields.has(
              keyfobButtonOutputFieldId("Two", String(keyfob.number))
            ),
            data: keyfob.buttonTwoOutput,
          },
          buttonThreeOutput: {
            included: includedFields.has(
              keyfobButtonOutputFieldId("Three", String(keyfob.number))
            ),
            data: keyfob.buttonThreeOutput,
          },
          buttonFourOutput: {
            included: includedFields.has(
              keyfobButtonOutputFieldId("Four", String(keyfob.number))
            ),
            data: keyfob.buttonFourOutput,
          },
          buttonOnePressTime: {
            included: includedFields.has(
              keyfobButtonPressTimeFieldId("One", String(keyfob.number))
            ),
            data: keyfob.buttonOnePressTime,
          },
          buttonTwoPressTime: {
            included: includedFields.has(
              keyfobButtonPressTimeFieldId("Two", String(keyfob.number))
            ),
            data: keyfob.buttonTwoPressTime,
          },
          buttonThreePressTime: {
            included: includedFields.has(
              keyfobButtonPressTimeFieldId("Three", String(keyfob.number))
            ),
            data: keyfob.buttonThreePressTime,
          },
          buttonFourPressTime: {
            included: includedFields.has(
              keyfobButtonPressTimeFieldId("Four", String(keyfob.number))
            ),
            data: keyfob.buttonFourPressTime,
          },
          keyfobSupervisionTime: {
            included: includedFields.has(
              keyfobSupervisionTimeFieldId(String(keyfob.number))
            ),
            data: keyfob.keyfobSupervisionTime,
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XTKeyfobsTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { keyfobs } = getState(template);

  if (!keyfobs) return;

  keyfobs.filter(isNotNullOrUndefined).forEach((keyfob) => {
    const number = String(keyfob.number);
    if (keyfob.included) {
      setIncludedFields(setToggle(keyfobListItemTemplateId(number)));
      setIncludedFields(setToggle(keyfobNumberFieldId(number)));
    }
    if (keyfob.userNumber?.included) {
      setIncludedFields(setToggle(keyfobUserNumberFieldId(number)));
    }
    if (keyfob.buttonOneAction?.included) {
      setIncludedFields(setToggle(keyfobButtonActionFieldId("One", number)));
    }
    if (keyfob.buttonTwoAction?.included) {
      setIncludedFields(setToggle(keyfobButtonActionFieldId("Two", number)));
    }
    if (keyfob.buttonThreeAction?.included) {
      setIncludedFields(setToggle(keyfobButtonActionFieldId("Three", number)));
    }
    if (keyfob.buttonFourAction?.included) {
      setIncludedFields(setToggle(keyfobButtonActionFieldId("Four", number)));
    }
    if (keyfob.buttonOneAreas?.included) {
      setIncludedFields(setToggle(keyfobButtonAreasFieldId("One", number)));
    }
    if (keyfob.buttonTwoAreas?.included) {
      setIncludedFields(setToggle(keyfobButtonAreasFieldId("Two", number)));
    }
    if (keyfob.buttonThreeAreas?.included) {
      setIncludedFields(setToggle(keyfobButtonAreasFieldId("Three", number)));
    }
    if (keyfob.buttonFourAreas?.included) {
      setIncludedFields(setToggle(keyfobButtonAreasFieldId("Four", number)));
    }
    if (keyfob.numberOfButtons?.included) {
      setIncludedFields(setToggle(keyfobNumberOfButtonsFieldId(number)));
    }
    if (keyfob.buttonOneOutputAction?.included) {
      setIncludedFields(
        setToggle(keyfobButtonOutputActionFieldId("One", number))
      );
    }
    if (keyfob.buttonTwoOutputAction?.included) {
      setIncludedFields(
        setToggle(keyfobButtonOutputActionFieldId("Two", number))
      );
    }
    if (keyfob.buttonThreeOutputAction?.included) {
      setIncludedFields(
        setToggle(keyfobButtonOutputActionFieldId("Three", number))
      );
    }
    if (keyfob.buttonFourOutputAction?.included) {
      setIncludedFields(
        setToggle(keyfobButtonOutputActionFieldId("Four", number))
      );
    }
    if (keyfob.buttonOneOutput?.included) {
      setIncludedFields(setToggle(keyfobButtonOutputFieldId("One", number)));
    }
    if (keyfob.buttonTwoOutput?.included) {
      setIncludedFields(setToggle(keyfobButtonOutputFieldId("Two", number)));
    }
    if (keyfob.buttonThreeOutput?.included) {
      setIncludedFields(setToggle(keyfobButtonOutputFieldId("Three", number)));
    }
    if (keyfob.buttonFourOutput?.included) {
      setIncludedFields(setToggle(keyfobButtonOutputFieldId("Four", number)));
    }
    if (keyfob.buttonOnePressTime?.included) {
      setIncludedFields(setToggle(keyfobButtonPressTimeFieldId("One", number)));
    }
    if (keyfob.buttonTwoPressTime?.included) {
      setIncludedFields(setToggle(keyfobButtonPressTimeFieldId("Two", number)));
    }
    if (keyfob.buttonThreePressTime?.included) {
      setIncludedFields(
        setToggle(keyfobButtonPressTimeFieldId("Three", number))
      );
    }
    if (keyfob.buttonFourPressTime?.included) {
      setIncludedFields(
        setToggle(keyfobButtonPressTimeFieldId("Four", number))
      );
    }
    if (keyfob.keyfobSupervisionTime?.included) {
      setIncludedFields(setToggle(keyfobSupervisionTimeFieldId(number)));
    }
  });
};
