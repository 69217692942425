/**
 * @generated SignedSource<<0fb0ca9cbc3a3b7601d83aa38e1e0aaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly nonOutputs: {
    readonly coolSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly cutoffOutputs: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly heatSaverTemperature: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly outputOptions: {
    readonly included: boolean;
  } | null;
  readonly outputs: {
    readonly ambushOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedAllOrAwayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedHomeOrPerimeterOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedSleepOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly beginExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly closingWaitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly communicationFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly disarmedOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly endExitOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly entryDelayOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly lateToCloseOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly lockdownOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly panicAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly readyOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly remoteArmingOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sensorResetOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly telephoneTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneMonitorOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "TMSentryOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TMSentryOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TMSentryOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TMSentryOutputOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "52dfa759df7d24961f8a1aa969911d32";

export default node;
