import { union } from "ramda";

/**
 * @ngdoc service
 * @name App.factory:TagsService
 *
 * @description
 * API Interface service for Tags.
 *
 */
App.factory("TagsService", [
  "$q",
  "TagsAPI",
  function ($q, TagsAPI) {
    return {
      /**
       * @ngdoc array
       * @name method:getDealerTags
       * @methodOf App.factory:TagsService
       *
       * @description
       * Returns a list of Tags that belong to the target Dealer.
       *
       * @param {String} dealerId
       * @return {promise} The promise from the Tags API 'getDealerTags'.
       */
      getDealerTags: function (dealerId) {
        const deferred = $q.defer();

        TagsAPI.getDealerTags(
          { typeId: dealerId }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:getPanelTags
       * @methodOf App.factory:TagsService
       *
       * @description
       * Returns a list of Tags that belong to the target Panel.
       *
       * @param {String} controlSystemId
       * @return {promise} The promise from the Tags API 'getPanelTags'.
       */
      getPanelTags: function (controlSystemId) {
        const deferred = $q.defer();

        TagsAPI.getPanelTags(
          { typeId: controlSystemId }, //params
          function (data) {
            //success
            deferred.resolve(union(data.customerTags, data.panelTags));
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc array
       * @name method:getCustomerTags
       * @methodOf App.factory:TagsService
       *
       * @description
       * Returns a list of Tags that belong to the target Customer.
       *
       * @param {String} customerId
       * @return {promise} The promise from the Tags API 'getCustomerTags'.
       */
      getCustomerTags: function (customerId) {
        const deferred = $q.defer();

        TagsAPI.getCustomerTags(
          { typeId: customerId }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc array
       * @name method:getCustomRoleTags
       * @methodOf App.factory:TagsService
       *
       * @description
       * Returns a list of Tags that belong to the target Custom Role.
       *
       * @param {String} customRoleId
       * @return {promise} The promise from the Tags API 'getCustomRoleTags'.
       */
      getCustomRoleTags: function (customRoleId) {
        const deferred = $q.defer();

        TagsAPI.getCustomRoleTags(
          { typeId: customRoleId }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc array
       * @name method:getCustomerRelations
       * @methodOf App.factory:TagsService
       *
       * @description
       * Returns a list of all the Tags and the Customers that relate to each Tag for a Dealer.
       *
       * @param {String} dealerId
       * @return {promise} The promise from the Tags API 'getCustomerRelations'.
       */
      getCustomerRelations: function (dealerId) {
        const deferred = $q.defer();

        TagsAPI.getCustomerRelations(
          { typeId: dealerId }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc array
       * @name method:getPanelRelations
       * @methodOf App.factory:TagsService
       *
       * @description
       * Returns a list of all the Tags and the Panels/Customers/Sites that relate to each Tag for a Dealer.
       *
       * @param {String} dealerId
       * @return {promise} The promise from the Tags API 'getPanelRelations'.
       */
      getPanelRelations: function (dealerId) {
        const deferred = $q.defer();

        TagsAPI.getPanelRelations(
          { typeId: dealerId }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
