import { getVkOldUrl } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import { StreamableCamera } from "../camect-api/streamable-camera";
import { parseAreaInfo } from "./area-info-parser";
import { parseControlSystem } from "./control-system-parser";
import { parseLegacyClips } from "./legacy-clips-parser";
import { parsePanelDevices } from "./panel-devices-parser";
import { parseVideoVerificationInfo } from "./video-verification-info-parser";
import { parseZoneInfo } from "./zone-info-parser";

const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
  "production") as SecureComEnv;
const SCAPI_BASE_URL = getVkOldUrl(secureComEnv);

const getControlSystem = async (scwToken: string, controlSystemId: number) => {
  const path = `/v2/control_systems/${controlSystemId}`;
  const query = `?auth_token=${scwToken}`;
  const url = `${SCAPI_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseControlSystem(responseJson);
  } else {
    throw Error(`SCAPI Error: ${response.status}:${response.statusText}`);
  }
};

const getLegacyClips = async (
  scwToken: string,
  cameras: StreamableCamera[],
  lastAlarmServerTime: Date
) => {
  const promises = cameras.map((camera) => {
    if (camera.clipsPath) {
      return getLegacyClipsForChannel(scwToken, camera, lastAlarmServerTime);
    } else {
      return Promise.resolve([]);
    }
  });
  const arrayOfArrays = await Promise.all(promises);
  // Need to sort here since we're combining per-camera lists
  return arrayOfArrays.flat().sort((a, b) => {
    return a.sequenceMS < b.sequenceMS ? 1 : -1;
  });
};

const getLegacyClipsForChannel = async (
  scwToken: string,
  camera: StreamableCamera,
  lastAlarmServerTime: Date
) => {
  const query = `?auth_token=${scwToken}`;
  const url = `${SCAPI_BASE_URL}${camera.clipsPath}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseLegacyClips(responseJson, camera.name, lastAlarmServerTime);
  } else {
    throw Error(`SCAPI Error: ${response.status}:${response.statusText}`);
  }
};

const getVideoVerificationInfo = async (
  scwToken: string,
  controlSystemId: number
) => {
  const path = `/v2/control_systems/${controlSystemId}/video_verification_info`;
  const query = `?auth_token=${scwToken}`;
  const url = `${SCAPI_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseVideoVerificationInfo(responseJson);
    //404 is how Scapi reports "not in alarm", so that code isn't useful for errors
  } else if (response.status !== 404) {
    throw Error(`SCAPI Error: ${response.status}:${response.statusText}`);
  } else {
    return null;
  }
};

const getAreaInformation = async (
  scwToken: string,
  controlSystemId: number
) => {
  const path = `/v2/panels/${controlSystemId}/area_informations`;
  // According to Tony, you can only have 32 areas, so setting this to 33 will make sure
  // and get _all_ of the areas at once because SCAPI paginates at 16
  const query = `?auth_token=${scwToken}&page_size=33`;
  const url = `${SCAPI_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseAreaInfo(responseJson);
  } else {
    throw Error(`SCAPI Error: ${response.status}:${response.statusText}`);
  }
};

const getAvailablePanelDevices = async (
  scwToken: string,
  controlSystemId: number
) => {
  const path = `/v2/panels/${controlSystemId}/device_informations/available`;
  const query = `?auth_token=${scwToken}`;
  const url = `${SCAPI_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parsePanelDevices(responseJson);
  } else if (response.status === 404) {
    // SCAPI returns a 404 rather than an empty array if, e.g., the panel doesn't support devices
    return [];
  } else {
    throw Error(`SCAPI Error: ${response.status}:${response.statusText}`);
  }
};

const getZoneInformation = async (
  scwToken: string,
  controlSystemId: number
) => {
  const path = `/v2/panels/${controlSystemId}/zone_informations`;
  const query = `?auth_token=${scwToken}`;
  const url = `${SCAPI_BASE_URL}${path}${query}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseJson = await response.json();
    return parseZoneInfo(responseJson);
  } else {
    throw Error(`SCAPI Error: ${response.status}:${response.statusText}`);
  }
};

export {
  getAreaInformation,
  getAvailablePanelDevices,
  getControlSystem,
  getLegacyClips,
  getVideoVerificationInfo,
  getZoneInformation,
};
