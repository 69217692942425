/**
 * @generated SignedSource<<56f7fe686333ee77000a289456888fc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: "Xt75RemoteOptions";
      readonly appKey: string | null;
      readonly firstApn: string | null;
      readonly id: string;
      readonly remoteDisarm: boolean;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  };
  readonly " $fragmentType": "XT75RemoteOptionsProgrammingConceptFormInline_controlSystem";
};
export type XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75RemoteOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75RemoteOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75RemoteOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "08caf5e7386cfffc08280e591ee922a5";

export default node;
