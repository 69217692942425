/**
 * @generated SignedSource<<66bf68dfc0d6c5b261486480099d2d15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data = {
  readonly areaInformations: ReadonlyArray<{
    readonly autoArmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly autoDisarmEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly badZonesOption: {
      readonly data: BadZonesOption | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
  } | null> | null;
  readonly " $fragmentType": "TakeoverPanelAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts";
};
export type TakeoverPanelAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key = {
  readonly " $data"?: TakeoverPanelAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TakeoverPanelAreaInformationProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts"
};

(node as any).hash = "8c24f6699c3837ed80e2b5a622e1912b";

export default node;
