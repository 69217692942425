import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver1SecondIpAddressField_communication$key } from "./__generated__/CommunicationReceiver1SecondIpAddressField_communication.graphql";

export const communicationReceiver1SecondIpAddressFieldId = () =>
  "communication-receiver-1-second-ip-address";

function CommunicationReceiver1SecondIpAddressField() {
  const [{ receiver1Ip2 }, updateCommunication] =
    useCommunicationFragment<CommunicationReceiver1SecondIpAddressField_communication$key>(
      graphql`
        fragment CommunicationReceiver1SecondIpAddressField_communication on Communication {
          receiver1Ip2
        }
      `
    );

  const fieldId = communicationReceiver1SecondIpAddressFieldId();

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Second IP Address">
      <TextInput
        id={fieldId}
        value={String(receiver1Ip2 ?? "")}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        inlineHelp="Ex. 0.0.0.0"
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "receiver1Ip2");
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "receiver1Ip2"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver1SecondIpAddressField;
