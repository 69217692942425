// this is returned if the user is on a mobile device
// base: src/components/CameraTable/CameraTable.tsx

import graphql from "babel-plugin-relay/macro";
import AddCameraModal from "components/AddCameraModal/AddCameraModal";
import Icon from "components/Icon";
import Modal from "components/Modal";
import { CameraStatusPuckWithActivationStatus } from "components/VarStatusBadge";
import React, { useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import {
  asID,
  fromVideoChannelId,
  VideoDeviceStatus,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import {
  CameraButtonSpan,
  InnerAddCameraButtonContainer,
  NoCamerasContainer,
} from "./CameraTable";
import {
  CameraMobileWidgetContainer,
  CameraMobileWidgetHeader,
  CameraModel,
  CameraResolution,
  CameraTitle,
  TableButtons,
  VideoDeviceHeaderButtons,
} from "./styles";
import TestConnectionButton, { DeviceType } from "./TestConnectionButton";
import { CameraMobileWidgetQuery_controlSystem$key } from "./__generated__/CameraMobileWidgetQuery_controlSystem.graphql";
import { CameraMobileWidgetQuery_controlSystemQuery } from "./__generated__/CameraMobileWidgetQuery_controlSystemQuery.graphql";

// Path: src/components/CameraTable/CameraTable.tsx
// Compare this snippet from src/components/CameraTable/CameraMobileWidget.tsx:

export const CameraMobileWidget = ({
  systemRef,
  customerId,
  systemId,
  isRefreshing,
  state,
  userService,
  videoDeviceService,
  controlSystemService,
  scope,
}: {
  systemRef: CameraMobileWidgetQuery_controlSystem$key;
  customerId: string;
  systemId: string;
  isRefreshing: boolean;
  state: any;
  userService: any;
  videoDeviceService: any;
  controlSystemService: any;
  scope: any;
}) => {
  const [cameraList, refetchCameraList] = useRefetchableFragment<
    CameraMobileWidgetQuery_controlSystemQuery,
    CameraMobileWidgetQuery_controlSystem$key
  >(
    graphql`
      fragment CameraMobileWidgetQuery_controlSystem on ControlSystem
      @refetchable(queryName: "CameraMobileWidgetQuery_controlSystemQuery") {
        secureComCameras {
          id
          deviceType
          name
          lastCheckIn
          isOnline
          status
          model
          formFactor
          imageSize
          macAddress
          networkReady
          manufacturer
        }
      }
    `,
    systemRef
  );

  useEffect(() => {
    if (isRefreshing) {
      refetchCameraList({}, { fetchPolicy: "network-only" });
    }
  }, [isRefreshing, refetchCameraList]);

  const [modalIsOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <VideoDeviceWidgetHeader>
        <Icon name="camera" size="4rem" style={{ paddingRight: "1rem" }} />
        Cameras
        <VideoDeviceHeaderButtonsContainer>
          {userService.canEditVideoDevice() ? (
            <VideoDeviceHeaderButtons>
              <button
                onClick={() =>
                  (location.href = `/#/app/customers/${userService.customer_id}/control_systems/${userService.control_system_id}/customers/${userService.customer_id}/control_systems/${userService.control_system_id}/camera-mappings`)
                }
                className="btn btn-default btn-sm"
              >
                Assign Cameras to Zones
              </button>
              <button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className="btn btn-dmp btn-sm"
                disabled={!videoDeviceService.canAddChannels}
              >
                <InnerAddCameraButtonContainer>
                  <Icon name="add" size="1.6rem" color="primary" />
                  <CameraButtonSpan>Camera</CameraButtonSpan>
                </InnerAddCameraButtonContainer>
              </button>
            </VideoDeviceHeaderButtons>
          ) : null}
        </VideoDeviceHeaderButtonsContainer>
        {modalIsOpen ? (
          <Modal size="medium">
            <AddCameraModal
              handleModalClose={() => setIsModalOpen(false)}
              userService={userService}
              state={state}
              scope={scope}
              videoDeviceService={videoDeviceService}
              controlSystemService={controlSystemService}
            />
          </Modal>
        ) : null}
      </VideoDeviceWidgetHeader>
      {cameraList.secureComCameras.length ? (
        cameraList?.secureComCameras.map((camera) => {
          const { deviceId, videoChannelId } = fromVideoChannelId(
            asID(camera.id)
          );

          return (
            <CameraMobileWidgetContainer
              key={camera.id}
              id={`camera-${camera.id}`}
            >
              <CameraMobileWidgetHeader>
                <CameraTitle>
                  <CameraStatusPuckWithActivationStatus
                    activationStatus={
                      VideoDeviceStatus[camera.status as VideoDeviceStatus]
                    }
                    onlineStatus={
                      !!camera.isOnline ? camera.networkReady : null
                    }
                    lastCheckIn={
                      camera.lastCheckIn ? camera.lastCheckIn : undefined
                    }
                  />
                  <TableButtons
                    title={`${camera.name}\n${camera.macAddress}`}
                    onClick={(event) => {
                      event.preventDefault();
                      const app =
                        camera.manufacturer === "Uniview"
                          ? "app.uniview-camera-edit"
                          : "app.view-camera";
                      state.go(app, {
                        customer_id: customerId,
                        control_system_id: systemId,
                        device_id: deviceId,
                        channel_id: videoChannelId,
                      });
                    }}
                  >
                    {camera.name}
                  </TableButtons>
                </CameraTitle>

                <TestConnectionButton
                  deviceType={DeviceType.Camera}
                  deviceId={camera.id}
                  style={{ fontSize: "1.2rem" }}
                />
              </CameraMobileWidgetHeader>

              <CameraModel>
                Model
                <strong title={camera?.model ?? ""}>{camera?.model}</strong>
              </CameraModel>
              <CameraResolution>
                Resolution
                <strong>{camera.imageSize ? camera.imageSize : "1MP"}</strong>
              </CameraResolution>
            </CameraMobileWidgetContainer>
          );
        })
      ) : (
        <NoCamerasContainer>
          <span className="info-alert mar-l-24">
            <Icon name="radial_info" size="1.6rem" color="hsl(0, 0%, 65%)" />
            Camera service is enabled, but no camera devices have been
            configured
          </span>
        </NoCamerasContainer>
      )}
    </>
  );
};

export const VideoDeviceWidgetHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  padding: var(--measure-3x) var(--measure-3x) 0;
  margin-bottom: var(--measure-2x);
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const VideoDeviceHeaderButtonsContainer = styled.div`
  margin-left: auto;
  @media (max-width: 480px) {
    margin: auto;
  }
`;
