/**
 * Directive comparing itemsAvailable to 1, then displaying number listed
 */
App.directive("daAddConceptRecord", [
  "PANEL_CONCEPTS",
  "$filter",
  function (PANEL_CONCEPTS, filter) {
    return {
      restrict: "A",

      scope: {
        itemsAvailable: "=",
        conceptKey: "=",
        addClickFn: "&",
      },

      link: function (scope) {
        scope.panelConcepts = PANEL_CONCEPTS;
        scope.filter = filter;
      },

      templateUrl: "/app/common/templates/add-concept-record.html",
    };
  },
]);
