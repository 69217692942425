import * as React from "react";
import { BaseInput, BaseInputProps } from "./BaseInput";
import InlineHelp from "./InlineHelp";
import Label from "./Label";

/**
 * Numeric Input Component is used to ensure a string value
 * is always digits. The value is NOT converted to a number.
 *
 * E.G.:
 * - 0 padded strings: 0001, 03334
 */
export const NumericInput = React.forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > &
      BaseInputProps & {
        inlineHelp?: React.ReactNode;
        placeholderText?: string;
        validationMessage?: string;
      },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const localRef = React.useRef<HTMLInputElement | null>(null);
    const {
      value,
      placeholderText,
      inlineHelp,
      className,
      validationMessage,
      ...rest
    } = props;

    React.useEffect(() => {
      const input = localRef.current;
      if (!input || !value) {
        return;
      }

      if (!/^\d*$/.test(value.toString())) {
        input.setCustomValidity(
          `Value must only contain numeric digits (0-9).`
        );
      } else {
        input.setCustomValidity("");
      }
    }, [value]);

    return (
      <Label>
        <BaseInput
          {...rest}
          type="number"
          ref={(node) => {
            localRef.current = node;
            if (ref && typeof ref === "function") {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          value={value}
          className={`form-control ${className}`}
        />
        {inlineHelp && (
          <InlineHelp placeholder={placeholderText}>{inlineHelp}</InlineHelp>
        )}
      </Label>
    );
  }
);
