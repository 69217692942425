import graphql from "babel-plugin-relay/macro";
import PhoneNumberInput from "components/FullProgramming/common/PhoneNumberInput";
import * as React from "react";
import { CommunicationComType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver2SecondPhoneNumberField_communication$key } from "./__generated__/CommunicationReceiver2SecondPhoneNumberField_communication.graphql";

export const communicationReceiver2SecondPhoneNumberFieldId = () =>
  "communication-receiver2-second-phone-number";

function CommunicationReceiver2SecondPhoneNumberField() {
  const [{ receiver2SecondaryPhoneNumber, comType }, updateCommunication] =
    useCommunicationFragment<CommunicationReceiver2SecondPhoneNumberField_communication$key>(
      graphql`
        fragment CommunicationReceiver2SecondPhoneNumberField_communication on Communication {
          receiver2SecondaryPhoneNumber
          comType
        }
      `
    );

  const fieldId = communicationReceiver2SecondPhoneNumberFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Second Phone Number"
      disabled={comType === CommunicationComType.CELL}
    >
      <PhoneNumberInput
        id={fieldId}
        maxLength={32}
        value={String(receiver2SecondaryPhoneNumber ?? "")}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "receiver2SecondaryPhoneNumber");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver2SecondPhoneNumberField;
