import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { deviceInformation1100TFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TField";
import { deviceInformation1100TWirelessFrequencyFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TWirelessFrequencyField";
import { deviceInformationDoorFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDoorField";
import { deviceInformationNameFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNameField";
import { deviceInformationNetworkFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNetworkField";
import {
  deviceInformationNumberFieldId,
  deviceListItemTemplateId,
} from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNumberField";
import { deviceInformationSupervisionTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSupervisionTimeField";
import { deviceInformationWirelessFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationWirelessField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XTDeviceSetupProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XTFullProgramming/XTDeviceSetupProgrammingConceptForm/__generated__/XTDeviceSetupProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  getDeviceState,
  getState as getDevicesState,
} from "../../../XTFullProgramming/XTDeviceSetupProgrammingConceptForm";
import { XtProgrammingTemplateDeviceInformationInput } from "../Create/__generated__/XTTemplateSaveMutation.graphql";
import { XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        deviceInformations {
          id
          included
          number
          axNumber {
            data
            included
          }
          lxNumber {
            data
            included
          }
          name {
            data
            included
          }
          supervisionTime {
            data
            included
          }
          wirelessTranslator1100t {
            data
            included
          }
          wirelessTranslator1100tFrequency {
            data
            included
          }
          wireless {
            data
            included
          }
          door {
            data
            included
          }
          network {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XTDeviceSetupProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateDeviceInformationInput[] => {
  const {
    panel: { deviceInformations },
  } = getDevicesState(controlSystem);

  return deviceInformations
    ? deviceInformations
        .filter(isNotNullOrUndefined)
        .map(getDeviceState)
        .map((device) => ({
          included: includedFields.has(
            deviceListItemTemplateId(String(device.number))
          ),
          number: device.number,
          name: {
            data: device.name,
            included: includedFields.has(
              deviceInformationNameFieldId(String(device.number))
            ),
          },
          supervisionTime: {
            data: device.supervisionTime,
            included: includedFields.has(
              deviceInformationSupervisionTimeFieldId(String(device.number))
            ),
          },
          wirelessTranslator1100t: {
            data: device.wirelessTranslator1100t,
            included: includedFields.has(
              deviceInformation1100TFieldId(String(device.number))
            ),
          },
          wirelessTranslator1100tFrequency: {
            data: device.wirelessTranslator1100tFrequency,
            included: includedFields.has(
              deviceInformation1100TWirelessFrequencyFieldId(
                String(device.number)
              )
            ),
          },
          wireless: {
            data: device.wireless,
            included: includedFields.has(
              deviceInformationWirelessFieldId(String(device.number))
            ),
          },
          door: {
            data: device.door,
            included: includedFields.has(
              deviceInformationDoorFieldId(String(device.number))
            ),
          },
          network: {
            data: device.network,
            included: includedFields.has(
              deviceInformationNetworkFieldId(String(device.number))
            ),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XTDeviceInformationTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { deviceInformations } = getState(template);

  if (!deviceInformations) {
    return;
  }

  deviceInformations.filter(isNotNullOrUndefined).forEach((device) => {
    const number = String(device.number);

    if (device.included) {
      setIncludedFields(setToggle(deviceListItemTemplateId(number)));
      setIncludedFields(setToggle(deviceInformationNumberFieldId(number)));
    }
    if (device.name?.included) {
      setIncludedFields(setToggle(deviceInformationNameFieldId(number)));
    }
    if (device.supervisionTime?.included) {
      setIncludedFields(
        setToggle(deviceInformationSupervisionTimeFieldId(number))
      );
    }
    if (device.wirelessTranslator1100t?.included) {
      setIncludedFields(setToggle(deviceInformation1100TFieldId(number)));
    }
    if (device.wirelessTranslator1100tFrequency?.included) {
      setIncludedFields(
        setToggle(deviceInformation1100TWirelessFrequencyFieldId(number))
      );
    }
    if (device.wireless?.included) {
      setIncludedFields(setToggle(deviceInformationWirelessFieldId(number)));
    }
    if (device.door?.included) {
      setIncludedFields(setToggle(deviceInformationDoorFieldId(number)));
    }
    if (device.network?.included) {
      setIncludedFields(setToggle(deviceInformationNetworkFieldId(number)));
    }
  });
};
