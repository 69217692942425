/**
 * @generated SignedSource<<042b37a0c8e684c77306459cc12decf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTOutputInformationFields_output$data = {
  readonly id: string;
  readonly number: number;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationContext_output" | "OutputInformationNameField_output" | "OutputInformationNumberField_output" | "OutputInformationOutputSupervisionField_output" | "OutputInformationSerialNumberField_output" | "OutputInformationTripWithPanelBellField_output">;
  readonly " $fragmentType": "XTOutputInformationFields_output";
};
export type XTOutputInformationFields_output$key = {
  readonly " $data"?: XTOutputInformationFields_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTOutputInformationFields_output">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTOutputInformationFields_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationContext_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationNumberField_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationNameField_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationOutputSupervisionField_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationSerialNumberField_output"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputInformationTripWithPanelBellField_output"
    }
  ],
  "type": "Output",
  "abstractKey": null
};

(node as any).hash = "809fe3b7021121079c7d1871f3472549";

export default node;
