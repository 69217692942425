import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import * as React from "react";

import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useLockoutCodeFragment } from "./LockoutCodeContext";
import { LockoutCodeField_lockoutCode$key } from "./__generated__/LockoutCodeField_lockoutCode.graphql";

export const lockoutCodeFieldId = () => `lockout-code`;

function LockoutCodeField() {
  const [{ code }, updateLockoutCode] =
    useLockoutCodeFragment<LockoutCodeField_lockoutCode$key>(
      graphql`
        fragment LockoutCodeField_lockoutCode on LockoutCode {
          code
        }
      `
    );

  const fieldId = lockoutCodeFieldId();
  const { isXr, isXf, isXt75 } = resolvePanelType(useHardwareModel());

  const useXrXfXt75Range = isXr || isXt75 || isXf;

  // Valid range for lockout code is 0-65535, but for XR/XF/XT75 panels it's 0, 100-65535
  const validRange = useXrXfXt75Range
    ? range(0, 1).concat(range(100, 65536))
    : range(0, 65536);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Lockout Code"
      tooltip="Restricts access to panel programming from a keypad."
    >
      <TextInput
        id={fieldId}
        value={code}
        pattern={
          useXrXfXt75Range
            ? "(0{0,5}|0{0,2}[1-9][0-9]{2}|0?[1-9][0-9]{3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])"
            : "([0-9]{0,5}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])"
        }
        validationMessage={
          useXrXfXt75Range
            ? "Valid values are 0, 100-65535."
            : "Valid values are 0-65535."
        }
        inlineHelp={useXrXfXt75Range ? "0, 100-65535" : "0-65535"}
        onBlur={({ target }) => {
          const closestNumber = closest(Number(target.value), validRange);
          updateLockoutCode((recordProxy) => {
            recordProxy.setValue(
              String(closestNumber).padStart(5, `0`),
              "code"
            );
          });
        }}
        onChange={({ target }) => {
          if (isNaN(Number(target.value))) return;
          if (!validRange) return;

          updateLockoutCode((recordProxy) => {
            recordProxy.setValue(target.value, "code");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default LockoutCodeField;
