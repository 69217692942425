/**
 * @ngdoc service
 * @name App.factory:SevenInchKeypadAPI
 *
 * @description
 * API factory for seven inch keypads
 *
 */
App.factory("SevenInchKeypadAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/KeypadAPI/:control_system/:dealer/:control_system_id/:dealer_id/AllKeypads",
      {},
      {
        getKeypadsForControlSystem: {
          method: "GET",
          params: {
            control_system: "ControlSystem",
            control_system_id: "@control_system_id",
          },
          isArray: true,
        },
        getKeypadsForDealer: {
          method: "GET",
          params: {
            dealer: "Dealer",
            dealer_id: "@dealer_id",
          },
          isArray: true,
        },
      }
    );
  },
]);
