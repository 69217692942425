/**
 * @generated SignedSource<<49439c18423c5af61a1f16b32e9596f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelFullProgrammingContainer_controlSystem$data = {
  readonly isCellComEx: boolean;
  readonly isCellComSl: boolean;
  readonly isIComSl: boolean;
  readonly isTMSentry: boolean;
  readonly name: string;
  readonly panel: {
    readonly softwareVersion: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem">;
  readonly " $fragmentType": "TakeoverPanelFullProgrammingContainer_controlSystem";
};
export type TakeoverPanelFullProgrammingContainer_controlSystem$key = {
  readonly " $data"?: TakeoverPanelFullProgrammingContainer_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelFullProgrammingContainer_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TakeoverPanelFullProgrammingContainer_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComSl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComEx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isIComSl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTMSentry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelFullProgrammingContainer_templateStatus_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "4c406fdff1e2020ab05e2addee799254";

export default node;
