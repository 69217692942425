/**
 * @ngdoc service
 * @name App.factory:CombinedEventsService
 * @path projects/dealer-admin/src/app/common/services/combined-events-service.js
 * @description
 * API Interface service for CombinedEvents.
 * @requires $q
 * @requires PROPS
 * @requires CombinedEventsAPI
 **/

App.factory("CombinedEventsService", [
  "$q",
  "PROPS",
  "CombinedEventsAPI",
  function ($q, PROPS, CombinedEventsAPI) {
    return {
      getCombinedEvents: function (panel_id, event_at) {
        var deferred = $q.defer();
        var _this = this;
        CombinedEventsAPI.getCombinedEvents(
          { panel_id: panel_id, event_at: event_at }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
