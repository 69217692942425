import styled from "styled-components";
import { media } from "common/utils/styles";

const BasicArmingAreaButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.xsmall`
    flex-direction: column;
  `};
`;

export default BasicArmingAreaButtonContainer;
