/**
 * @generated SignedSource<<22dd360e41f1cc36454704c2032cf571>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsDhcpEnabledField_networkOptions$data = {
  readonly dhcpEnabled: boolean;
  readonly dnsServer: string;
  readonly gatewayAddress: string;
  readonly ipv6Enabled: boolean;
  readonly localIpAddress: string;
  readonly subnetMask: string;
  readonly " $fragmentType": "NetworkOptionsDhcpEnabledField_networkOptions";
};
export type NetworkOptionsDhcpEnabledField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsDhcpEnabledField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsDhcpEnabledField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsDhcpEnabledField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dhcpEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipv6Enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localIpAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subnetMask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatewayAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dnsServer",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "47d296625478126d4b8adcbe5d9fcf03";

export default node;
