import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationReceiver2OpenCloseUserReportsField_communication$key } from "./__generated__/CommunicationReceiver2OpenCloseUserReportsField_communication.graphql";

export const communicationReceiver2OpenCloseUserReportsFieldId = () =>
  "communication-receiver-2-open-close-user-reports";

function CommunicationReceiver2OpenCloseUserReportsField() {
  const [
    { receiver2Backup, receiver2OpenCloseUserReports },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationReceiver2OpenCloseUserReportsField_communication$key>(
    graphql`
      fragment CommunicationReceiver2OpenCloseUserReportsField_communication on Communication {
        receiver2Backup
        receiver2OpenCloseUserReports
      }
    `
  );

  const fieldId = communicationReceiver2OpenCloseUserReportsFieldId();
  const label = "Opening / Closing & User Reports";
  const disabled = receiver2Backup === true;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Turns on Schedule Change, and Holiday Change reports. Enables Opening, Closing, Bypass, Reset, Code Change reports if programmed in System Reports."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={receiver2OpenCloseUserReports}
        disabled={disabled}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              !receiver2OpenCloseUserReports,
              "receiver2OpenCloseUserReports"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationReceiver2OpenCloseUserReportsField;
