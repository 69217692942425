App.controller("IconsCtrl", [
  "$rootScope",
  "$scope",
  "StyleDataAccessService",
  function ($rootScope, $scope, StyleDataAccessService) {
    init();

    function init() {
      //Service call to get icon data
      StyleDataAccessService.getStyleData("icon-data.json")
        .then(
          function (iconData) {
            $scope.IconData = iconData;
          },
          function (iconError) {
            $rootScope.alerts.push({ type: "error", message: iconError });
          }
        )
        .catch(function (error) {
          console.error(error);
        });

      //Service call to get svg data
      StyleDataAccessService.getStyleData("svg-data.json")
        .then(
          function (svgData) {
            $scope.SvgData = svgData;
          },
          function (svgError) {
            $rootScope.alerts.push({ type: "error", message: svgError });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }
  },
]);
