import React, { useEffect } from "react";
import { joinSpaced } from "utils/string";
import { SavingState } from "../types";
import styles from "./SaveButton.module.css";

export function useDelayedResetSaveButton(
  state: SavingState,
  resetFn: () => void
) {
  useEffect(() => {
    if (state === SavingState.Success || state === SavingState.Errored) {
      const timeout = setTimeout(resetFn, 1500);
      return () => clearTimeout(timeout);
    }
  }, [resetFn, state]);
}

export function AddButton({
  savingState,
  type = "button",
  ...props
}: { savingState: SavingState } & React.HTMLProps<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={joinSpaced(
        "btn",
        styles["btn-override"],
        savingState === SavingState.Errored
          ? "btn-danger"
          : savingState === SavingState.Success
          ? "btn-success"
          : "btn-dmp"
      )}
    >
      {(() => {
        switch (savingState) {
          case SavingState.Errored:
            return (
              <span>
                <i
                  className={joinSpaced(
                    "icon-radial_alert",
                    styles["icon-override"]
                  )}
                ></i>{" "}
                <span>Failed to Add</span>
              </span>
            );
          case SavingState.Success:
            return (
              <span>
                <i
                  className={joinSpaced(
                    "icon-radial_check",
                    styles["icon-override"]
                  )}
                ></i>{" "}
                <span>Added</span>
              </span>
            );
          case SavingState.Saving:
            return (
              <span>
                <i
                  className={joinSpaced(
                    "fa fa-spin fa-spinner",
                    styles["icon-override"]
                  )}
                ></i>
                <span>Adding...</span>
              </span>
            );

          default:
            return <span>Add</span>;
        }
      })()}
    </button>
  );
}

export default function SaveButton({
  savingState,
  ...props
}: { savingState: SavingState } & React.HTMLProps<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type="button"
      className={joinSpaced(
        "btn btn-sm",
        styles["btn-override"],
        savingState === SavingState.Errored
          ? "btn-danger"
          : savingState === SavingState.Success
          ? "btn-success"
          : "btn-dmp"
      )}
    >
      {(() => {
        switch (savingState) {
          case SavingState.Errored:
            return (
              <span>
                <i
                  className={joinSpaced(
                    "icon-radial_alert",
                    styles["icon-override"]
                  )}
                ></i>{" "}
                <span>Failed to Save</span>
              </span>
            );
          case SavingState.Success:
            return (
              <span>
                <i
                  className={joinSpaced(
                    "icon-radial_check",
                    styles["icon-override"]
                  )}
                ></i>{" "}
                <span>Saved</span>
              </span>
            );
          case SavingState.Saving:
            return (
              <span>
                <i
                  className={joinSpaced(
                    "fa fa-spin fa-spinner",
                    styles["icon-override"]
                  )}
                ></i>
                <span>Saving...</span>
              </span>
            );

          default:
            return <span>Save</span>;
        }
      })()}
    </button>
  );
}
