import { curry } from "ramda";

export const expandHexShorthand = (hex: string) =>
  hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => r + r + g + g + b + b
  );

export const hexToRgb = (hex: string) => {
  const pattern = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const result = pattern.exec(expandHexShorthand(hex));
  return result ? result.slice(1).map((n) => parseInt(n, 16)) : null;
};
export const hslToHsla = (alpha: number, hsl: string) => {
  return `hsla(${hsl
    .replace("hsl(", "")
    .replace(")", "")
    .split(",")
    .map((v) => v.trim())
    .concat(String(alpha))
    .join(", ")})`;
};
export const rgba = curry((alpha: number, color: string) => {
  const rgb = hexToRgb(color);
  return rgb ? `rgba(${rgb.join(",")},${alpha})` : color;
});
