/**
 * @generated SignedSource<<074debc259ce7658ec27b3300982d8d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditReceiverFormReceiver_receiver$data = {
  readonly automationPrefix: string;
  readonly centralStationAutomationIntegration: {
    readonly id: string;
  } | null;
  readonly dealer: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ReceiverFormModalDealer_dealer">;
  } | null;
  readonly description: string | null;
  readonly id: string;
  readonly ip: string;
  readonly number: number;
  readonly port: number;
  readonly " $fragmentType": "EditReceiverFormReceiver_receiver";
};
export type EditReceiverFormReceiver_receiver$key = {
  readonly " $data"?: EditReceiverFormReceiver_receiver$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditReceiverFormReceiver_receiver">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditReceiverFormReceiver_receiver",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automationPrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "port",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CentralStationAutomationIntegration",
      "kind": "LinkedField",
      "name": "centralStationAutomationIntegration",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dealer",
      "kind": "LinkedField",
      "name": "dealer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReceiverFormModalDealer_dealer"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Receiver",
  "abstractKey": null
};
})();

(node as any).hash = "217e6b8598e0ea10aae19af000f826f2";

export default node;
