/**
 * @generated SignedSource<<aaefc6bdcc26612775f3504fefea45b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecorderCameraDetectionRegions_varHubCamera$data = {
  readonly activeDetectionRegionIndex: number | null;
  readonly detectionRegions: ReadonlyArray<{
    readonly activeCoordinateIndexes: ReadonlyArray<number> | null;
    readonly focus: boolean | null;
    readonly geometry: {
      readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
    };
    readonly id: string;
    readonly index: number | null;
    readonly slotNumber: number | null;
  }>;
  readonly id: string;
  readonly maxRegionX: number;
  readonly maxRegionY: number;
  readonly minRegionX: number;
  readonly minRegionY: number;
  readonly mouseDown: boolean | null;
  readonly " $fragmentType": "RecorderCameraDetectionRegions_varHubCamera";
};
export type RecorderCameraDetectionRegions_varHubCamera$key = {
  readonly " $data"?: RecorderCameraDetectionRegions_varHubCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecorderCameraDetectionRegions_varHubCamera">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecorderCameraDetectionRegions_varHubCamera",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VarHubCameraDetectionRegion",
      "kind": "LinkedField",
      "name": "detectionRegions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slotNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VarHubCameraRegionGeometry",
          "kind": "LinkedField",
          "name": "geometry",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coordinates",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "index",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activeCoordinateIndexes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "focus",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minRegionX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxRegionX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minRegionY",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxRegionY",
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activeDetectionRegionIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mouseDown",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "VarConnectedCamera",
  "abstractKey": null
};
})();

(node as any).hash = "21530921e6bf05c80c0272df5acff4a0";

export default node;
