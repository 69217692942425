import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsLateToOpenField_systemReports$key } from "./__generated__/SystemReportsLateToOpenField_systemReports.graphql";

export const systemReportsLateToOpenFieldId = () =>
  "system-reports-late-to-open";

function SystemReportsLateToOpenField() {
  const [{ lateToOpen, lateToOpenMin, lateToOpenMax }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsLateToOpenField_systemReports$key>(
      graphql`
        fragment SystemReportsLateToOpenField_systemReports on SystemReports {
          lateToOpen
          lateToOpenMin
          lateToOpenMax
        }
      `
    );

  const fieldId = systemReportsLateToOpenFieldId();
  const originalValue = React.useRef(lateToOpen).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Late to Open"
      tooltip="If enabled in Communication Path Programming, send a message when the System is Late to Open."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={lateToOpen.toString()}
        pattern="(0{0,2}[0-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)"
        max={lateToOpenMax}
        min={lateToOpenMin}
        required
        inlineHelp={`${lateToOpenMin}–${lateToOpenMax}`}
        onChange={({ target }) => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "lateToOpen"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemReports((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : valueAsNumber === 0
                ? valueAsNumber
                : clamp(lateToOpenMin, lateToOpenMax, valueAsNumber);
            recordProxy.setValue(Number(value), "lateToOpen");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsLateToOpenField;
