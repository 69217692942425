/**
 * @generated SignedSource<<26cb954d132378d6de4adbb2786acb84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type VideoDeviceSettingConfirmation = "API_ERROR" | "CONFIRMATION_ERROR" | "CONFIRMED" | "PENDING" | "SENT" | "TIMEOUT";
export type VideoDeviceStatus = "ACTIVATING" | "ACTIVE" | "ERROR" | "INACCESSIBLE";
export type VideoDeviceType = "BRIDGE" | "CAMERA" | "DVR" | "NVR";
import { FragmentRefs } from "relay-runtime";
export type CameraTable_controlSystem$data = {
  readonly id: string;
  readonly secureComCameras: ReadonlyArray<{
    readonly deviceType: VideoDeviceType;
    readonly formFactor: string | null;
    readonly id: string;
    readonly imageSize: string | null;
    readonly isOnline: boolean;
    readonly lastCheckIn: string | null;
    readonly macAddress: string;
    readonly manufacturer: string;
    readonly model: string;
    readonly name: string;
    readonly networkReady: boolean | null;
    readonly settingConfirmation: VideoDeviceSettingConfirmation | null;
    readonly status: VideoDeviceStatus;
  }>;
  readonly " $fragmentType": "CameraTable_controlSystem";
};
export type CameraTable_controlSystem$key = {
  readonly " $data"?: CameraTable_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"CameraTable_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CameraTable_controlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "CameraTable_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecureComCamera",
      "kind": "LinkedField",
      "name": "secureComCameras",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastCheckIn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOnline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formFactor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageSize",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "macAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "networkReady",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manufacturer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settingConfirmation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "ba4b24020619fbf6b36716da78ffc1eb";

export default node;
