import { RelayServiceType } from "app/common/services/relay-service";
import graphql from "babel-plugin-relay/macro";
import FadeInOut from "common/components/web/FadeInOut";
import { GenericPageFallback } from "components/GenericPageFallback";
import RelayEnvironmentProviderDA from "components/RelayEnvironmentProvider";
import { AlertObject, AlertsContextProvider } from "contexts/AlertsContext";
import { InitialConnectContextProvider } from "contexts/InitialConnectContext";
import { RoutingContextProvider } from "contexts/RoutingContext";
import * as React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { react2angular } from "react2angular";
import {
  ID,
  idAsString,
  PanelHardwareModel,
  toControlSystemId,
  toDealerId,
} from "securecom-graphql/client";
import { AngularStateProvider } from "../../components/SystemDiagnostics/AngularStateProvider";
import IncludedTemplateFieldsContextProvider from "./common/IncludedTemplateFieldsContext";
import { LastUpdatedContextProvider } from "./common/LastUpdatedContext";
import RefreshSpecifiedConceptsContext from "./common/ProgrammingAngularConnectionContext";
import TemplateContextProvider, { EditingFrom } from "./common/TemplateContext";
import TMSentryFullProgramming from "./TMSentryFullProgramming";
import { FullProgrammingQuery } from "./__generated__/FullProgrammingQuery.graphql";
import { FullProgramming_controlSystem$key } from "./__generated__/FullProgramming_controlSystem.graphql";

const XRFullProgramming = React.lazy(() => import("./XRFullProgramming"));
const XFFullProgramming = React.lazy(() => import("./XFFullProgramming"));
const XTFullProgramming = React.lazy(() => import("./XTFullProgramming"));
const XT75FullProgramming = React.lazy(() => import("./XT75FullProgramming"));
const TakeoverFullProgramming = React.lazy(
  () => import("./TakeoverPanelFullProgramming")
);

export function FullProgramming(props: {
  dealerId: ID;
  controlSystem: FullProgramming_controlSystem$key;
}) {
  const controlSystem = useFragment(
    graphql`
      fragment FullProgramming_controlSystem on ControlSystem {
        id
        name
        isXr
        isXt
        isTakeoverPanel
        isXf
        isTMSentry
        isXt75
        ...XT75FullProgrammingContainer_controlSystem
        ...XRFullProgrammingContainer_controlSystem
        ...XFFullProgrammingContainer_controlSystem
        ...TakeoverPanelFullProgrammingContainer_controlSystem
        ...XTFullProgrammingContainer_controlSystem
        ...TMSentryFullProgrammingContainer_controlSystem
      }
    `,
    props.controlSystem
  );
  const { isXr, isXt, isXt75, isTakeoverPanel, isXf, isTMSentry } =
    controlSystem;

  if (isTMSentry) {
    return (
      <TMSentryFullProgramming
        dealerId={props.dealerId}
        systemId={controlSystem.id}
        controlSystem={controlSystem}
      />
    );
  } else if (isTakeoverPanel) {
    return (
      <TakeoverFullProgramming
        dealerId={props.dealerId}
        systemId={controlSystem.id}
        controlSystem={controlSystem}
      />
    );
  } else if (isXr) {
    return (
      <XRFullProgramming
        dealerId={props.dealerId}
        systemId={controlSystem.id}
        controlSystem={controlSystem}
      />
    );
  } else if (isXt) {
    return (
      <XTFullProgramming
        dealerId={props.dealerId}
        systemId={controlSystem.id}
        controlSystem={controlSystem}
      />
    );
  } else if (isXt75) {
    return (
      <XT75FullProgramming
        dealerId={props.dealerId}
        systemId={controlSystem.id}
        controlSystem={controlSystem}
      />
    );
  } else if (isXf) {
    return (
      <XFFullProgramming
        dealerId={props.dealerId}
        systemId={controlSystem.id}
        controlSystem={controlSystem}
      />
    );
  } else return null;
}

function FullProgrammingContainer(props: {
  dealerId: ID;
  systemId: ID;
  isInternational: boolean;
}) {
  const data = useLazyLoadQuery<FullProgrammingQuery>(
    graphql`
      query FullProgrammingQuery($systemId: ID!) {
        controlSystem: node(id: $systemId) {
          ... on ControlSystem {
            id
            panel {
              hardwareModel
            }
            ...FullProgramming_controlSystem
          }
        }
      }
    `,
    {
      systemId: idAsString(props.systemId),
    }
  );

  return data.controlSystem?.id ? (
    <IncludedTemplateFieldsContextProvider>
      <LastUpdatedContextProvider>
        <TemplateContextProvider
          settings={{
            editingFrom: EditingFrom.PROGRAMMING_PAGE,
            hardwareModel: data.controlSystem.panel
              ?.hardwareModel as PanelHardwareModel,
            templateName: "",
            customerId: "",
            isEditing: false,
            isApplying: false,
            isCreating: false,
            isSaving: false,
            isLoadingEditPage: false,
            isInternational: props.isInternational,
          }}
        >
          <FullProgramming
            dealerId={props.dealerId}
            controlSystem={data.controlSystem}
          />
        </TemplateContextProvider>
      </LastUpdatedContextProvider>
    </IncludedTemplateFieldsContextProvider>
  ) : null;
}

export default FullProgrammingContainer;

// Angular integration. Eventually when we phase out Angular JS we will remove the rest of this file.
export function FullProgrammingAngularEntryPoint(props: {
  RelayService: RelayServiceType;
  $stateParams: {
    control_system_id: string;
  };
  UserService: {
    dealer_id: string;
    isParentDealerLogin(): boolean;
    isSupervisorAccessible(): boolean;
    controlSystem: {
      panels: [
        {
          online: boolean;
        }
      ];
    };
    enabledDistributionSubscriber(): boolean;
  };
  $rootScope: {
    alerts: AlertObject[];
    $digest: () => void;
    $on: (event: string, callback: () => void) => () => void;
  };
  $state: {
    go: (
      route: string,
      params: { [key: string]: string | number },
      options?: { reload?: boolean }
    ) => void;
  };
  InitialConnectionService: {
    ensureConnectionReady: () => void;
  };
  refreshPanelConcepts: (concepts: []) => void;
  GoogleAnalyticsService: {
    trackEvent: (category: string, action: string, label: string) => void;
  };
  cfpLoadingBar: {
    start: () => void;
    complete: () => void;
  };
}) {
  return (
    <FadeInOut visible>
      <div className="row">
        <RelayEnvironmentProviderDA RelayService={props.RelayService}>
          <React.Suspense fallback={<GenericPageFallback />}>
            <InitialConnectContextProvider
              InitialConnectionService={props.InitialConnectionService}
            >
              <RoutingContextProvider
                $state={props.$state}
                $stateParams={props.$stateParams}
                cfpLoadingBar={props.cfpLoadingBar}
              >
                <RefreshSpecifiedConceptsContext.Provider
                  value={props.refreshPanelConcepts}
                >
                  <AngularStateProvider state={props.$state}>
                    <AlertsContextProvider $rootScope={props.$rootScope}>
                      <FullProgrammingContainer
                        dealerId={toDealerId(props.UserService.dealer_id)}
                        systemId={toControlSystemId(
                          props.$stateParams.control_system_id
                        )}
                        isInternational={props.UserService.enabledDistributionSubscriber()}
                      />
                    </AlertsContextProvider>
                  </AngularStateProvider>
                </RefreshSpecifiedConceptsContext.Provider>
              </RoutingContextProvider>
            </InitialConnectContextProvider>
          </React.Suspense>
        </RelayEnvironmentProviderDA>
      </div>
    </FadeInOut>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "fullProgramming",
    react2angular(
      FullProgrammingAngularEntryPoint,
      ["refreshPanelConcepts"],
      [
        "RelayService",
        "UserService",
        "UserSettingsService",
        "$rootScope",
        "$state",
        "$stateParams",
        "InitialConnectionService",
        "GoogleAnalyticsService",
        "cfpLoadingBar",
      ]
    )
  );
}
