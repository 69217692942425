import * as R from "ramda";
import * as React from "react";
import {
  DefaultTheme,
  ThemeProvider as SCThemeProvider,
  useTheme,
} from "styled-components";
import { rgba } from "../../../utils/universal/color";

const ThemeProvider = <Themes extends { [key: string]: DefaultTheme }>({
  children,
  defaultTheme,
  themes,
}: {
  children: React.ReactNode;
  defaultTheme: keyof Themes;
  themes: Themes;
}) => {
  const [currentTheme, setTheme] = React.useState(themes[defaultTheme]);

  return (
    <SCThemeProvider
      theme={React.useMemo(
        () => ({
          ...currentTheme,
          setTheme: (newTheme: keyof Themes) => setTheme(themes[newTheme]),
          themes,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentTheme, themes]
      )}
    >
      {children}
    </SCThemeProvider>
  );
};

export default ThemeProvider;

type ThemeObject = { theme: DefaultTheme };
type KeyofTheme = keyof DefaultTheme;
type Intensity =
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "1000";
type ColorGetter = (themeObj: ThemeObject) => string;

export const useTextColor = () => useTheme().textColor;
export const themeTextColor = ({ theme }: ThemeObject) => theme.textColor;
export const usePrimaryBackgroundColor = () =>
  useTheme().primaryBackgroundColor;
export const themePrimaryBackgroundColor = R.path([
  "theme",
  "primaryBackgroundColor",
]);
export const useSecondaryBackgroundColor = () =>
  useTheme().secondaryBackgroundColor;
export const themeSecondaryBackgroundColor = R.path([
  "theme",
  "secondaryBackgroundColor",
]);
export const useTertiaryBackgroundColor = () =>
  useTheme().tertiaryBackgroundColor;
export const themeTertiaryBackgroundColor = R.path([
  "theme",
  "tertiaryBackgroundColor",
]);
export const useBorderColor = () => useTheme().borderColor;
export const themeBorderColor = ({ theme }: ThemeObject) => theme.borderColor;
export const useTrueWhite = () => useTheme().trueWhite;
export const themeTrueWhite = ({ theme }: ThemeObject) => theme.trueWhite;
export const useTrueBlack = () => useTheme().trueBlack;
export const themeTrueBlack = ({ theme }: ThemeObject) => theme.trueBlack;
export const usePrimary = (value: Intensity) => {
  const theme = useTheme();
  return theme[`primary${value}` as KeyofTheme] ?? theme.primary;
};
export const themePrimary = ({ theme }: ThemeObject) => theme.primary;
export const themePrimaryValue = R.curry(
  (value: Intensity, { theme }: ThemeObject) =>
    theme[`primary${value}` as KeyofTheme] ?? theme.primary
);
export const useSecondary = () => useTheme().secondary;
export const themeSecondary = ({ theme }: ThemeObject) => theme.secondary;
export const useSecondaryDark = () => useTheme().secondaryDark; // TODO: make this capital in all locations (themeSecondaryDark)

export const themeSecondaryDark = ({ theme }: ThemeObject) =>
  theme.secondaryDark;
export const useSecondaryLight = () => useTheme().secondaryLight; // TODO: make this capital in all locations (themeSecondaryLight)

export const themeSecondaryLight = ({ theme }: ThemeObject) =>
  theme.secondaryLight;
export const useGrayXlight = () => useTheme().grayXlight;
export const themeGrayXlight = ({ theme }: ThemeObject) => theme.grayXlight;
export const useGrayLight = () => useTheme().grayLight;
export const themeGrayLight = ({ theme }: ThemeObject) => theme.grayLight;
export const useGrayMedium = () => useTheme().grayMedium;
export const themeGrayMediumLight = ({ theme }: ThemeObject) =>
  theme.grayMediumLight;
export const useGrayMediumLight = () => useTheme().grayMediumLight;
export const themeGrayMedium = ({ theme }: ThemeObject) => theme.grayMedium;
export const useGray = () => useTheme().gray;
export const themeGray = ({ theme }: ThemeObject) => theme.gray;
export const useGrayDark = () => useTheme().grayDark;
export const themeGrayDark = ({ theme }: ThemeObject) => theme.grayDark;
export const useGrayAccent = () => useTheme().grayAccent;
export const themeGrayAccent = ({ theme }: ThemeObject) => theme.grayAccent;
export const useRed = (value = "") => {
  const theme = useTheme();
  return theme[`red${value}` as KeyofTheme] ?? theme.red;
};
export const themeRed = ({ theme }: ThemeObject) => theme.red;
export const useOrange = () => useTheme().orange;
export const themeOrange = ({ theme }: ThemeObject) => theme.orange;
export const useYellow = (value = "") => {
  const theme = useTheme();
  return theme[`yellow${value}` as KeyofTheme] ?? theme.yellow;
};
export const themeYellow = ({ theme }: ThemeObject) => theme.yellow;
export const useYellowLight = () => useTheme().yellowLight;
export const themeYellowLight = ({ theme }: ThemeObject) => theme.yellowLight;
export const useGreen = (value = "") => {
  const theme = useTheme();
  return theme[`green${value}` as KeyofTheme] ?? theme.green;
};
export const themeGreen = ({ theme }: ThemeObject) => theme.green;
export const themeGreenValue = R.curry(
  (value: Intensity, { theme }: ThemeObject) =>
    theme[`green${value}` as KeyofTheme] ?? theme.green
);
export const useBlueDark = () => useTheme().blueDark;
export const themeBlueDark = ({ theme }: ThemeObject) => theme.blueDark;
export const useCoolBlue = () => useTheme().coolBlue;
export const themeCoolBlue = ({ theme }: ThemeObject) => theme.coolBlue;
export const usePurple = () => useTheme().purple;
export const themePurple = ({ theme }: ThemeObject) => theme.purple;
export const useBoxShadow = () => useTheme().boxShadow;
export const themeBoxShadow = (
  colorGetter = ({ theme }: ThemeObject) => theme.grayAccent
) => (themeObj: ThemeObject) => themeObj.theme.boxShadow(colorGetter(themeObj));
export const themeRgba = R.curry((alpha: number, colorGetter: ColorGetter) =>
  R.compose(rgba(alpha), colorGetter)
);
export const defaultThemeBoxShadow = themeBoxShadow(
  themeRgba(0.25, themeTrueBlack)
);
export const usePanelBorderColor = () => useTheme().panelBorderColor;
export const themePanelBorderColor = ({ theme }: ThemeObject) =>
  theme.panelBorderColor;
export const usePanelBorder = () => useTheme().panelBorder;
export const themePanelBorder = ({ theme }: ThemeObject) => theme.panelBorder;
export const useHighlightColor = () => useTheme().highlightColor;
export const themeHighlightColor = ({ theme }: ThemeObject) =>
  theme.highlightColor;
export const useFallbackFontFamily = () => useTheme().fallbackFontFamily;
export const themeFallbackFontFamily = ({ theme }: ThemeObject) =>
  theme.fallbackFontFamily;
export const usePrimaryFontFamily = () => useTheme().primaryFontFamily;
export const themePrimaryFontFamily = ({ theme }: ThemeObject) =>
  theme.primaryFontFamily;
export const useColor = () => (useTheme() as any).color as string | undefined;
export const themeColor = ({ theme }: ThemeObject) =>
  (theme as any).color as string | undefined;
export const useBackgroundColor = () =>
  (useTheme() as any).backgroundColor as string | undefined;
export const themeBackgroundColor = ({ theme }: ThemeObject) =>
  (theme as any).backgroundColor as string | undefined;
export const useSuccess = () => useTheme().success;
export const themeSuccess = ({ theme }: ThemeObject) => theme.success;
export const useSave = () => useTheme().save;
export const themeSave = ({ theme }: ThemeObject) => theme.save;
export const useCaution = () => useTheme().caution;
export const themeCaution = ({ theme }: ThemeObject) => theme.caution;
export const useWarning = () => useTheme().warning;
export const themeWarning = ({ theme }: ThemeObject) => theme.warning;
export const useFailure = () => useTheme().failure;
export const themeFailure = ({ theme }: ThemeObject) => theme.failure;
export const useDanger = () => useTheme().danger;
export const themeDanger = ({ theme }: ThemeObject) => theme.danger;
export const elevation = R.curry(
  (value: Intensity, { theme }: ThemeObject) =>
    theme[`elevation${value}` as KeyofTheme] ?? theme.elevation100
);
export const hexToRGB = R.curryN(
  2,
  (colorFunc: (theme: ThemeObject) => string, theme: ThemeObject) =>
    `rgb(${R.splitEvery(2, colorFunc(theme).replace(/#/gim, ""))
      .map((twoDigitColorString) => parseInt(twoDigitColorString, 16))
      .join(",")})`
);
export const hexToRGBA = R.curryN(
  3,
  (colorFunc: (theme: ThemeObject) => string, alpha, theme: ThemeObject) =>
    `rgba(${R.splitEvery(2, colorFunc(theme).replace(/#/gim, ""))
      .map((twoDigitColorString) => parseInt(twoDigitColorString, 16))
      .join(",")}, ${alpha})`
);
