import graphql from "babel-plugin-relay/macro";
import { DaSolidButton, Flex, Form } from "components/DaStyledElements";
import GenericSuspenseFallback from "components/GenericSuspenseFallback";
import EulaAgreementModal from "components/LoginForm/EulaAgreementModal";
import { AlertsContextProvider } from "contexts/AlertsContext";
import React from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay";
import { react2angular } from "react2angular";
import styled from "styled-components";
import { EulaAgreementRedirectPageQuery } from "./__generated__/EulaAgreementRedirectPageQuery.graphql";

type EulaAgreementModalPageTypes = {
  $rootScope: any;
  $scope: any;
  UserService: any;
  RelayService: any;
  $state: any;
  VideoDeviceService: any;
  ControlSystemService: any;
};

export default function EulaAgreementRedirectPageRoot({
  $rootScope,
  $scope,
  UserService,
  RelayService,
  $state,
  VideoDeviceService,
  ControlSystemService,
}: EulaAgreementModalPageTypes) {
  const environment = RelayService.getEnvironment();
  const dealerId = UserService.dealer_id;
  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <React.Suspense fallback={<GenericSuspenseFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <EulaAgreementRedirectPage
            $state={$state}
            dealerId={dealerId}
            RelayService={RelayService}
          ></EulaAgreementRedirectPage>
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
}

const EulaAgreementRedirectPage = ({
  $state,
  dealerId,
  RelayService,
}: {
  $state: any;
  dealerId: string;
  RelayService: any;
}) => {
  const { getEulaStatus: eulaStatus } =
    useLazyLoadQuery<EulaAgreementRedirectPageQuery>(
      graphql`
        query EulaAgreementRedirectPageQuery {
          getEulaStatus {
            hasAgreed
          }
        }
      `,
      {},
      { fetchPolicy: "network-only" }
    );

  return (
    <>
      <EulaAgreementModal
        $state={$state}
        dealerId={dealerId}
        RelayService={RelayService}
      />
      <PicWrapper>
        <PageLoginWrapper>
          <FakeLoginModal></FakeLoginModal>
        </PageLoginWrapper>
      </PicWrapper>
      <BottomMatterRow>
        <DaSolidButton onClick={() => null}>Terms & Conditions</DaSolidButton>
        <DaSolidButton onClick={() => null}>Privacy Policy</DaSolidButton>
      </BottomMatterRow>
    </>
  );
};

const FakeLoginModal = () => {
  return (
    <>
      <div className="panel panel-login">
        <div className="panel-heading text-center">
          <img
            src="/assets/img/DealerAdmin_Color.svg"
            width="90%"
            alt="Dealer-Admin Logo"
          />
        </div>
        <div className="panel-body">
          <form>
            {/* USER NAME */}
            <Flex.Row>
              <Flex.Col size={1}>
                <Form.Group className={`required`}>
                  <input
                    name="userEmail"
                    id="userEmail"
                    type={"text"}
                    placeholder="Enter Email"
                    className="form-control"
                  />
                </Form.Group>
              </Flex.Col>
            </Flex.Row>
            {/* PASSWORD */}
            <Flex.Row>
              <Flex.Col size={1}>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder="Password"
                />
              </Flex.Col>
            </Flex.Row>
            <Flex.Row>
              <Flex.Col>
                <div className="text-center"></div>
                <div className="text-center mar-t-8">
                  <a onClick={() => null} className="text-muted text-underline">
                    Forgot your password?
                  </a>
                </div>
                <div className="text-center mar-t-8">
                  <a className="text-muted" onClick={() => null}>
                    Generate QR Code
                  </a>
                </div>
              </Flex.Col>
            </Flex.Row>
            <></>

            <input
              type="submit"
              id="sign-in-submit"
              disabled
              className="btn btn-block btn-dmp mt-lg"
              value="Sign In"
              onSubmit={() => {
                return null;
              }}
              onClick={() => null}
            />
          </form>
        </div>
      </div>
    </>
  );
};

const PageLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const PicWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("/assets/img/da-img-change2.jpg") center;
  background-image: (url("/assets/img/da-img-change2.jpg"));
  background-image: -webkit-image-set(url("/assets/img/da-img-change2.webp"));
  -webkit-background-size: cover;
  background-size: cover;
`;

const BottomMatterRow = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function dangerouslyAddToApp() {
  App.component(
    "eulaAgreementRedirectPageRoot",
    react2angular(
      EulaAgreementRedirectPageRoot,
      [],
      [
        "$rootScope",
        "$scope",
        "UserService",
        "RelayService",
        "$state",
        "VideoDeviceService",
        "ControlSystemService",
      ]
    )
  );
}
