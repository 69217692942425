// Styleguide Directives
App.directive("daCodeViewPane", function () {
  return {
    restrict: "E",
    transclude: true,
    scope: {},
    templateUrl:
      "app/styleguide/partials/directive-templates/code-view-pane-tpl.html",
  };
});
