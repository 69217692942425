App.directive("daIconButton", function () {
  return {
    restrict: "E",
    scope: {
      icon: "@",
      title: "@",
      clickEvent: "=",
    },

    templateUrl: "app/common/templates/da-icon-button-tpl.html",
  };
});

App.directive("daIconCloser", function () {
  return {
    restrict: "E",
    replace: true,
    scope: {
      clickEvent: "=",
      class: "@?",
      iconColor: "@?",
    },
    templateUrl: "app/common/templates/da-icon-closer.html",
    link: function (attrs) {
      if (attrs.class === "link icon-link-hover") {
        attrs.class = "icon-20 link icon-link-hover";
      }
      attrs.iconColor = attrs.iconColor || "neutral";
    },
  };
});
