/**
 * @generated SignedSource<<ef9896737fc33fee62bef557f0161b97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly pcLogReports: {
    readonly armDisarmReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly doorAccessReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly logRealTimeStatusEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly networkIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly networkPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly userCommandReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly zonesReportsEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "526c167fd3a80f974cccaafb3726bcfc";

export default node;
