/**=========================================================
 * Module: Panel Data Controller
 =========================================================*/
import { max } from "ramda";

App.controller("PanelDataController", [
  "$scope",
  "$state",
  "$stateParams",
  "$timeout",
  "PanelDataService",
  "APP_COLORS",
  "PROPS",
  "$rootScope",
  "UserService",
  "$filter",
  "$modal",
  "SimManagementService",
  "PanelCapabilitiesService",
  "ControlSystemsService",
  "SitesService",
  function (
    $scope,
    $state,
    $stateParams,
    $timeout,
    PanelDataService,
    APP_COLORS,
    PROPS,
    $rootScope,
    UserService,
    $filter,
    $modal,
    SimManagementService,
    PanelCapabilitiesService,
    ControlSystemsService,
    SitesService
  ) {
    $scope.noStatisticsMessage = ""; // message to display for the various cases when no statistics data is available
    $scope.panelStatisticsData = false; // flag for whether or not we have panel statistics data to display

    // line below will be removed once the panel statistics api provides the data. It will determine what is displayed.
    $scope.hasCellularData = false; // flag for whether or not we have cellular statistics data to display  - candidate for removal

    $scope.canGetDataFromCarrier = false; // flag for whether or not we have SIM identification data for calling Carrier APIs
    $scope.hasValidUser = false; // flag for whether or not we have a UserService object

    $scope.deviceIsBusy = false; // flag for displaying loading spinner for panel statistics api call

    $scope.active = ""; // Sparkline widget active class variable
    $scope.changeTimeframe = changeTimeframe;

    $scope.pageInitialLoad = true;
    $scope.controlSystem = $scope.ControlSystemsService.currentControlSystem;
    $scope.dayOfData = 0;
    $scope.today = new Date();
    $scope.today = dateTimeForceUTC($scope.today);
    $scope.dataDaysBack = "30"; // Default number of days back from current date to display in the chart
    $scope.selectedDayHasData = true; // If there is any hourly data we show it on page load. Therefore the selected day has to have data. (or else we wouldn't show hourly)

    const dayInMilliseconds = 86400000;
    const barWidth24Hours = 1840000;

    $scope.isBayAlarm = UserService.isBayAlarm();

    /**
     * DatePicker
     *
     */
    $scope.dateFilters = {};
    $scope.datePickerOpen = {};
    $scope.currentDate = new Date();

    $scope.clear = function () {
      $scope.dt = null;
    };

    $scope.dateOptions = {
      formatYear: "yy",
      startingDay: 1,
    };

    $scope.formats = [
      "dd-MMMM-yyyy",
      "yyyy/MM/dd",
      "dd.MM.yyyy",
      "MM/dd/yy",
      "shortDate",
    ];
    $scope.format = $scope.formats[4];

    $scope.hourly_date_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.hourlyDateOpened = true;
    };

    /*************************************************************************************************************
     * Determine if we have a UserService.controlSystem object from which to retrieve current user data.         *
     * If there is a problem on this level, then display an error message and stop since we cannot proceed.      *
     *************************************************************************************************************/
    if (typeof UserService === "undefined") {
      // $scope.panelStatisticsData is set to true so that the "no panel statistics" message is not displayed.
      // Panel Statistics data will not be displayed because this code block exits this controller.
      //$scope.panelStatisticsData = true;  // if this was false, a blank colored div would appear on the statistics page - no longer needed, candidate for removal
      $rootScope.alerts.push({
        type: "error",
        text: " User Data is Unavailable to the system",
      });
      $scope.hasValidUser = false;
      return;
    } else {
      $scope.hasValidUser = true;
    }
    $scope.panel_id = UserService.panel_id;

    // This is the panel index in the controlSystem panels property. It is used to reference the panel in the panels property array
    $scope.panel_index = UserService.controlSystem.panel_index;
    $scope.panel_model =
      UserService.controlSystem.panels[$scope.panel_index].hardware_model;
    $scope.hardware_family =
      UserService.controlSystem.panels[$scope.panel_index].hardware_family;
    $scope.isTMSentry = $scope.hardware_family === "TMSentry";
    $scope.isX1 = $scope.panel_model === "X001";
    $scope.title = $scope.isX1
      ? `SN: ${
          UserService.controlSystem.panels[$scope.panel_index].serial_number
        } Analytics`
      : "System Analytics";
    $scope.panelHeading = $scope.isX1 ? "X1 Information" : "System Information";
    $scope.lastCheckIn = "-";
    $scope.handleBackToDiagnosticsClick = () => {
      $state.go("app.sites.diagnostics", {
        customer_id: $stateParams.customer_id,
        site_id: $stateParams.site_id,
      });
    };

    if ($scope.isX1) {
      SitesService.getX1CheckInStatuses($stateParams.site_id).then((data) => {
        const { cell_checkin_at, network_checkin_at } = data.filter(
          (item) =>
            Number(item.control_system_id) ===
            Number($stateParams.control_system_id)
        )[0];
        if (cell_checkin_at && network_checkin_at) {
          $scope.lastCheckIn = max(cell_checkin_at, network_checkin_at);
        } else if (cell_checkin_at) {
          $scope.lastCheckIn = cell_checkin_at;
        } else if (network_checkin_at) {
          $scope.lastCheckIn = network_checkin_at;
        }
      });
    }

    // If we have something other than an IP address stored in the comm_address field, we can assume it is an MEID
    // TODO: Remove this when we separate MEID from the comm_address field
    if (
      !/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/i.test(
        UserService.controlSystem.panels[$scope.panel_index].comm_address
      )
    ) {
      SimManagementService.find(
        UserService.controlSystem.panels[$scope.panel_index].comm_address,
        UserService.dealerInfo.securecom_license,
        UserService.controlSystem,
        UserService.dealerInfo.dealer_code,
        UserService.dealerInfo.id
      )
        .then(
          function (data) {
            if (data.sim !== null) {
              UserService.controlSystem.panels[$scope.panel_index].sim =
                data.sim;
              UserService.controlSystem.panels[$scope.panel_index].sim.type =
                "MEID";
              // Determine the carrier
              switch (data.sim.rate_plan) {
                case "400V":
                  UserService.controlSystem.panels[
                    $scope.panel_index
                  ].sim.carrier = "Verizon";
                  break;
                case "400T":
                  UserService.controlSystem.panels[
                    $scope.panel_index
                  ].sim.carrier = "TMobile";
                  break;
                case "200":
                case "400":
                  UserService.controlSystem.panels[
                    $scope.panel_index
                  ].sim.carrier = "AT&T";
                  break;
                default:
                  UserService.controlSystem.panels[
                    $scope.panel_index
                  ].sim.carrier = "Unknown";
              }
            }

            /***************************************************************************************************
             * 1) Determine if a panel could have SEMPRO data. If so try to retrieve data. Display nothing if  *
             * data is unavailable. 2) Determine if panel could have Carrier Cellular data. If so try to       *
             * retrieve data. Display nothing if data is unavailable.                                          *
             ***************************************************************************************************/

            // If there is no SIM information at all or no SIM identifier, don't display the cellular statistics info
            // If both of these do exist, then set the cellular statistics information display flag to true
            if (
              !UserService.controlSystem.panels[$scope.panel_index].sim ||
              !UserService.controlSystem.panels[$scope.panel_index].sim
                .identifier
            ) {
              $scope.canGetDataFromCarrier = false;
            } else {
              $scope.canGetDataFromCarrier = true;
            }

            // Retrieve Carrier cellular information if we have SIM identifier information
            if ($scope.canGetDataFromCarrier) {
              $scope.panel_device_id =
                UserService.controlSystem.panels[
                  $scope.panel_index
                ].sim.identifier;
              // If LTE, device id type is ICCID. Otherwise, we can use the sim.type
              $scope.panel_device_id_type = UserService.controlSystem.panels[
                $scope.panel_index
              ].sim.isLTE
                ? "ICCID"
                : UserService.controlSystem.panels[$scope.panel_index].sim.type;

              // Use carrier information in application to determine which carrier API to call
              $scope.currentCarrier =
                UserService.controlSystem.panels[
                  $scope.panel_index
                ].sim.carrier;

              // Data for testing
              //$scope.panel_device_id = "A1990013E8A119";
              //$scope.panel_device_id = "A9999999999999";    // junk?
              //$scope.panel_device_id_type = "MEID123";      // junk

              /*****************************************************************************************
               * TO DO: Going forward, we will need logic to call different APIs based on the carrier  *
               * information that we have for a particular panel                                       *
               *****************************************************************************************/
              // Call API to retrieve carrier cellular information - This call is specific to Verizon as of now.
              $scope.carrierErrorMessage = ""; // populated by the getDeviceInformation API on error. Only way to pre-format this data since it is asynchronous
              $scope.carrierApiCallStatus = ""; // populated by the carrier api to indicating loading status on the statistics page, etc.

              // The three variables below are populated by panelDataService. This is necessary b/c the call is asynchronous and the returned
              // data needs to be processed and manipulated before being displayed in the view.
              $scope.carrierCellStatus = "";
              $scope.carrierLastConnection = "";
              $scope.carrierConnectionStatus = "";

              //$scope.currentCarrier = "ATT";  // For testing Non-Verizon carrier - we have no other apis currently
              /******************************************************************************************************************
               * The cell carrier api calls are carrier specific. So we need to specific what should be called for each carrier.*
               * Only Verizon apis are available currently.                                                                     *
               ******************************************************************************************************************/
              if (/Verizon/i.test($scope.currentCarrier)) {
                $scope.deviceInformation = angular.fromJson(
                  PanelDataService.getDeviceInformation(
                    $scope.panel_device_id,
                    $scope.panel_device_id_type,
                    $scope
                  )
                );
                $scope.carrierApiCallStatus =
                  "Retrieving Carrier Information... ";

                /*****************************************************************************************************
                 * Note: If deviceInformation is empty, panelDataService sets carrierErrorMessage to the appropriate *
                 * received message and the view will not display the data because the data does not exist.          *
                 *****************************************************************************************************/

                /*******************************************************************************************************************
                 * Verizon API Documentation:                                                                                      *
                 * M2M Developer Program Knowledge Center Home: Wireless Network Services > Error Messages > Synchronous Errors    *
                 * Look for the error code values because they will stay the same from release to release.                         *
                 * Don't write your code to match against the error message values because they contain dynamic data and may       *
                 * change in future releases. Don't write your code to match against the service names because the same error code *
                 * can be presented by more than one service. (It will have the same meaning in all situations.)                   *
                 * NOTE: SINCE THE API AUTOMATICALLY DETERMINES THE RETURN STATUS, THIS CODE DOES NOT EXAMINE RETURN CODES TO      *
                 * DETERMINE SUCCESS OR COMPLETION. A HIGH LEVEL STATUS OF 1 IS RETURNED ON SUCCESS, AND 2 ON ERROR. ERROR CASES   *
                 * ALSO CONTAIN A LOWER LEVEL STATUS CODE CORRESPONDING TO THE API ERROR. SUCCESSFUL API REQUESTS DO NOT HAVE THE  *
                 * LOWER LEVEL STATUS CODE.                                                                                        *
                 *******************************************************************************************************************/

                /**********************************************************************************************************************
                 * IMPORTANT NOTE: During development, the getDeviceInformation API call returned deviceInformation = Null            *
                 * ...which would suggest that the SIM was not found by the Verizon API. It was discovered that the WS API            *
                 * contained an error. The "activation_status" column was not recognized by WS. The issue is that device_information  *
                 * being null should indicate that the SIM was not found yet this is what was returned when the WS API had a critical *
                 * error. If this is how some errors are handled by the WS API, there is no way for this code to determine if a       *
                 * null device_information property means the SIM does not exists vs. the WS api generated an error!                  *
                 * Mar 6, 2015 From scrum discussion with Kevin E. - If the WS server has an error, this is an error that will get    *
                 * escalated very quickly since other applications/systems outside of this application use the WS api. So getting     *
                 * back null information will not be ambiguous if the WS API server is experiencing problems since WS API problems    *
                 * will be quickly escalated by other systems/applications.                                                           *
                 **********************************************************************************************************************/

                /*************************************************************************************************************
                 * Verizon getDeviceConnections api requires a start and end date range which is calculated below.           *
                 * Code uses getTime() and setTime() to add or subtract the time in a javascript Date object.                *
                 * Using setDate() and getDate() could lead to errors when reaching the limits of the months 1, 30, 31, etc..*
                 * A date range of today and 35 days prior today is used. Kevin E. said that this way we could at least      *
                 * capture end of month communication on a panel when search for last connect.                               *
                 * NOTE: The getDeviceConnections API is only used to return the most recent connection event so that we can *
                 * retrieve the base station identification information. nothing else is used from this API currently.       *
                 *************************************************************************************************************/
                // calculate dates to pass to API
                var end_date = new Date(); // latest date in the range - essentially today
                var start_date = new Date(); // earliest date in the range
                var previousDaysMs =
                  end_date.getTime() - 1000 * 60 * 60 * 24 * 35; // subtract 35 days (in milliseconds) from today's date
                start_date.setTime(previousDaysMs);

                var start_date_str = $filter("date")(start_date, "yyyy-MM-dd"); // pass through angular's date filter
                var end_date_str = $filter("date")(end_date, "yyyy-MM-dd"); // pass through angular's date filter

                $scope.baseStationBsid = ""; // complete base station id
                $scope.baseStationSid = ""; // base station system id
                $scope.baseStationNid = ""; // base station network id
                $scope.baseStationFullCell = ""; // The full base station Cellular Id which is composed of the Cell Id and the Sector Id
                $scope.baseStationCid = ""; // base station cellular Id
                $scope.baseStationSectorId = ""; // base station cellular sector

                // Call API to retrieve Base Station identification information - This call is specific to Verizon as of now.
                $scope.baseIDInformation = "";
                $scope.baseIDInformation = angular.fromJson(
                  PanelDataService.getDeviceConnections(
                    $scope.panel_device_id,
                    $scope.panel_device_id_type,
                    start_date_str,
                    end_date_str,
                    $scope
                  )
                );
                $scope.carrierApiCallStatus =
                  "Retrieving Carrier Information... ";
              }
            }
          },
          function (error) {}
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    // Do not show panel statistics data for panels that have no SEMPRO firmware
    if (
      !/XR550|XR350|XR150|XF6_100|XF6_500|X001|XT75/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_model
      ) &&
      !/XT/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_family
      )
    ) {
      $scope.panelStatisticsData = false; // hide blank statistics chart information
      $scope.noStatisticsMessage =
        "System Analytics displays historical system data for XR 550/350/150, XF6 Family, X1 Family, TMSentry Family, and XT Family systems. " +
        "Data shown includes cellular communicator information, connection statistics, and system voltage.";
    }

    // Panel statistics data will not be shown for XR 550/350/150 panels with software version below 109
    // Panel statistics data will not be shown for XT family panels with software version below 122
    if (
      (/XR550|XR350|XR150/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_model
      ) &&
        UserService.controlSystem.panels[$scope.panel_index].software_version <
          109) ||
      (/XT/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_family
      ) &&
        UserService.controlSystem.panels[$scope.panel_index].software_version <
          122)
    ) {
      $scope.panelStatisticsData = false; // hide EMPTY statistics chart information
      $scope.noStatisticsMessage =
        "A firmware update is required for System Analytics to show historical panel data. Data shown includes " +
        "cellular communicator information, connection statistics, and system voltage. Required Firmware: XR Family version 109, XT Family version 122";
    }

    // Panel statistics data will be shown for XR 550/350/150 panels with software version 109 and above (and all XT75)
    // Panel statistics data will be shown for XT family panels with software version 122 and above
    // Panel statistics data will be shown for XF family panels
    // Panel statistics data will be shown for X1 family panels
    if (
      (/XR550|XR350|XR150|XT75/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_model
      ) &&
        UserService.controlSystem.panels[$scope.panel_index].software_version >=
          109) ||
      (/XT/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_family
      ) &&
        UserService.controlSystem.panels[$scope.panel_index].software_version >=
          122) ||
      /XF6/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_family
      ) ||
      /X001/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_family
      ) ||
      /TMS/i.test(
        UserService.controlSystem.panels[$scope.panel_index].hardware_family
      )
    ) {
      $scope.panelStatisticsData = true; // statistics chart information will be shown
      $scope.noStatisticsMessage = "";
    }

    // These scope variables are declared here to avoid undefined errors later on it the code.
    $scope.jsonSignalData = {};
    $scope.jsonRetryData = {};
    $scope.jsonHardwareData = {};
    $scope.lastDataDate = "";
    $scope.realTimeStats = {}; // to hold data returned from the Refresh Panel Statistics API call.
    $scope.realTimeStatsLoading = false; // flag to display loading status to end user

    /*********************************************************************************************
     * This is where calls to VK apis are actually made to retrieve panel statistics information.*
     *********************************************************************************************/
    if ($scope.panelStatisticsData === true) {
      // For quick testing
      //$scope.jsonSignalData = angular.fromJson(PanelDataService.getSignalHistory());
      //$scope.jsonRetryData = angular.fromJson(PanelDataService.getRetryHistory());
      //$scope.jsonHardwareData = angular.fromJson(PanelDataService.getHardwareHistory());

      $scope.devicesIsBusy = true;

      $scope.panelStatistics = {}; // object to hold various panel statistic attributes
      $scope.panelStatisticsResults = angular.fromJson(
        PanelDataService.getPanelStatistics($scope.panel_id, $scope)
          .then(function (data) {
            if (
              PanelCapabilitiesService.supportsHourlyData(
                $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              )
            ) {
              // This gets the hourly Data. It has to run after the daily reports are retrieved incase there is no hourly data.
              // If no hourly data is present, we default to the last month of daily data.
              $scope.hourlyPanelInfoResults = PanelDataService.getHourlyData(
                $scope.panel_id,
                $scope
              )
                .then(
                  function (data) {
                    if (data.length > 0) {
                      $scope.hasHourlyData = true;
                      $scope.showHourlyData = true;
                      $scope.hourlyPanelData = data;
                      $scope.endHourlyData =
                        $scope.hourlyPanelData[$scope.dayOfData].currentDate;
                      $scope.beginHourlyData =
                        $scope.hourlyPanelData[$scope.dayOfData].dayBefore;
                      $scope.minDate = new Date(
                        $scope.hourlyPanelData[data.length - 1].signal[0][0] +
                          dayInMilliseconds * 2
                      ); // add two days in milliseconds to because we get the previous day's info
                      $scope.maxDate = new Date($scope.endHourlyData); // (two days because we're setting the day of data retrieved to a day back
                      $scope.changeTimeframe(1); //  because of how the data comes in from the panel)
                      $scope.clickable = true;
                    } else {
                      $scope.hasHourlyData = false;
                      $scope.showHourlyData = false;
                      $scope.changeTimeframe(30);
                      $scope.clickable = false;
                    }
                  },
                  function (error) {
                    $scope.hasHourlyData = false;
                    $scope.showHourlyData = false;
                    $scope.changeTimeframe(30);
                    console.error("Error  - " + error);
                    $scope.clickable = false;
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              $scope.hasHourlyData = false;
              $scope.showHourlyData = false;
              $scope.changeTimeframe(30);
              $scope.clickable = false;
            }
          })
          .catch(function (error) {
            console.error(error);
          })
      );

      $scope.hourlyPanelData = {};

      //$scope.carrierApiCallStatus = "Retrieving Carrier Information... ";
    }

    $scope.chartShow = true; // this variable is not used anywhere - candidate for removal
    //$scope.chartTitle = "Current Signal (dBm)"; // initial chart shown is current signal

    $scope.sparklineNormalColor = APP_COLORS.chartNormalColor;

    // Standard Threshold Plugin
    /********************************************************************************************
     * The Dataset property contains the threshold information that is applied to chart values. *
     * in the main flotjs chartIt also contains the actual data in th data sub property.        *
     ********************************************************************************************/
    $scope.currentDataset = [
      {
        data: $scope.currentChartData,
        //yaxis:0,
        label: "",
        color: APP_COLORS.chartNormalColor,
        threshold: {
          //below: -84,   // these are now set dynamically. just left here as an indication of these attributes
          //below: PROPS.signalThresholdVerizon,
          color: APP_COLORS.chartThreshold,
          fill: 1,
        },
      },
    ];

    /**********************************************************************************************************
     * These are the default bar chart options. These properties are overwritten based on the selected chart. *
     **********************************************************************************************************/
    $scope.barChartOptions = {
      xaxis: {
        mode: "time",
        minTickSize: [1, "hour"],
        twelveHourClock: true,
        tickLength: 0,
        timezone: "browser",
      },
      yaxis: {
        min: "-117",
        max: "-50",
        tickLength: 0,
      },
      grid: {
        hoverable: true,
        markings: [
          {
            yaxis: { from: "-85", to: "-85" },
            color: APP_COLORS.chartThreshold,
          },
        ],
        borderWidth: 0,
      },
      series: {
        bars: {
          show: true,
          color: APP_COLORS.chartNormalColor,
          barWidth: barWidth24Hours,
          fill: 0.8,
          align: "right",
        },
      },
      tooltip: true,
      tooltipOpts: {
        //content: 'Day %x : %ydBm'
        content: "%x : %y dBm",
      },
    };

    /***********************************************************************************************************
     * These are the default line chart options. These properties are overwritten based on the selected chart. *
     ***********************************************************************************************************/
    $scope.lineChartOptions = {
      xaxis: {
        mode: "time",
        minTickSize: [1, "hour"],
        twelveHourClock: true,
        timezone: "browser",
        //transform: function (v) { return -v; },
        //inverseTransform: function (v) { return -v; }
      },
      yaxis: {
        min: "-117",
        max: "-50",
        tickLength: 0,
      },
      grid: {
        hoverable: true,
        markings: [
          {
            yaxis: { from: "-85", to: "-85" },
            color: APP_COLORS.chartThreshold,
          },
        ],
        borderWidth: 0,
      },
      series: {
        lines: {
          show: true,
          color: APP_COLORS.chartNormalColor,
          lineWidth: 3,
          align: "centers",
        },
        points: { show: true },
      },
      tooltip: true,
      tooltipOpts: {
        //content: 'Day %x : %ydBm'
        content: "%x : %y dBm",
      },
    };

    $scope.chartOptions = $scope.barChartOptions;

    /*********************************************************************************************************
     * This function updates the main chart data when the user clicks on a specific Sparkline Chart          *
     * below the main chart. This function dynamically changes the underlying chart data to represent the    *
     * data of the clicked Sparkline chart. The directive watches the chart data for changes and replots the *
     * chart using the updated data.                                                                         *
     *********************************************************************************************************/
    //$scope.changeChart = function(chartName, data) {
    $scope.changeChart = function (chartName, data, widgetReference) {
      $scope.active = widgetReference; // sets the active class on the selected Sparkline Chart. example value = 'widget1'

      if ($scope.panelStatisticsData === false) return; // return if panelStatisticsData cannot be displayed
      if (typeof chartName === "undefined" && typeof data === "undefined")
        return; // return if chart name and chart data have not been provided

      // Check that valid chart data has been passed, data has to contain something
      if (typeof data === "undefined" || data.length <= 0) {
        $rootScope.alerts.push({
          type: "error",
          text: chartName + " Data Unavailable",
        });
        return;
      }

      $scope.chartTitle = chartName;
      var jsonName = chartName.replace(/\s|-/g, "");
      $scope.currentRawData = data;
      $scope.currentChartData = [];
      var chartType = ""; // we need to track the type of chart because some chart data requires additional processing

      // The delete below resets these 2 graph properties to a default starting state
      delete $scope.barChartOptions.yaxis.tickDecimals;
      delete $scope.lineChartOptions.yaxis.tickDecimals;

      /****************************************************************************************************************
       * This is where we set various large chart display options (axis value ranges, thresholds, color options, etc. *
       ****************************************************************************************************************/
      if (/Signal/i.test(chartName)) {
        // This block is triggered for all cell signal charts
        chartType = "Signal";
        $scope.barChartOptions.yaxis.min = "-117";
        $scope.barChartOptions.yaxis.max = "-50";
        $scope.barChartOptions.tooltipOpts.content = "%x : %y dBm";
        $scope.barChartOptions.yaxis.tickSize = "";
        $scope.lineChartOptions.yaxis.min = "-117";
        $scope.lineChartOptions.yaxis.max = "-50";
        $scope.lineChartOptions.tooltipOpts.content = "%x : %y dBm";
        $scope.lineChartOptions.yaxis.tickSize = "";
        $scope.currentDataset[0].color = APP_COLORS.chartNormalColor; // set normal data to the normal data color
        $scope.currentDataset[0].threshold.color = APP_COLORS.chartThreshold; // set threshold data to the threshold data color

        // set cell signal strength thresholds based on carrier
        if ($scope.currentCarrier === "Verizon") {
          $scope.currentDataset[0].threshold.below =
            PROPS.signalThresholdVerizon;
          $scope.barChartOptions.grid.markings[0].yaxis.from =
            PROPS.signalThresholdVerizon;
          $scope.barChartOptions.grid.markings[0].yaxis.to =
            PROPS.signalThresholdVerizon;
          $scope.lineChartOptions.grid.markings[0].yaxis.from =
            PROPS.signalThresholdVerizon;
          $scope.lineChartOptions.grid.markings[0].yaxis.to =
            PROPS.signalThresholdVerizon;
        } else {
          // **** This will need to change to reference each carrier specifically when additional carriers are available ****
          $scope.currentDataset[0].threshold.below = PROPS.signalThresholdATT;
          $scope.barChartOptions.grid.markings[0].yaxis.from =
            PROPS.signalThresholdATT;
          $scope.barChartOptions.grid.markings[0].yaxis.to =
            PROPS.signalThresholdATT;
          $scope.lineChartOptions.grid.markings[0].yaxis.from =
            PROPS.signalThresholdATT;
          $scope.lineChartOptions.grid.markings[0].yaxis.to =
            PROPS.signalThresholdATT;
        }
      }
      if (/Retries/i.test(chartName)) {
        chartType = "Retries";
        $scope.barChartOptions.yaxis.min = "0";
        $scope.barChartOptions.yaxis.max = "10";
        $scope.barChartOptions.tooltipOpts.content = "%x : %y Retries";
        $scope.barChartOptions.yaxis.tickSize = "";
        $scope.barChartOptions.grid.markings[0].yaxis.from = "5";
        $scope.barChartOptions.grid.markings[0].yaxis.to = "5";
        $scope.lineChartOptions.yaxis.min = "0";
        $scope.lineChartOptions.yaxis.max = "10";
        $scope.lineChartOptions.tooltipOpts.content = "%x : %y Retries";
        $scope.lineChartOptions.yaxis.tickSize = "";
        $scope.lineChartOptions.grid.markings[0].yaxis.from = "5";
        $scope.lineChartOptions.grid.markings[0].yaxis.to = "5";

        /********************************************************************************************************
         * FlotJS bug: Thresholds "above" a certain value is not implemented. We need to show retry values above *
         * 5 in red so we need to use the inverse logic to work around this bug. Inverse Logic: All values will  *
         * default to the threshold color and then all values below 5 will default to the normal data color.     *
         * Note: Thresholds using a "below: value work fine.                                                     *
         ********************************************************************************************************/
        $scope.currentDataset[0].color = APP_COLORS.chartThreshold; // set normal data to the threshold data color
        $scope.currentDataset[0].threshold.color = APP_COLORS.chartNormalColor; // set threshold data to the normal data color
        $scope.currentDataset[0].threshold.below = "4.9"; // set threshold using "below" instead of "above"
      }
      if (/AC Voltage/i.test(chartName)) {
        chartType = "Voltage";

        /********************************************
         * Set Panel model Specific Chart Metadata  *
         ********************************************/
        if (
          /XR150|XR350|XR550|XT30|XT50|XF6_100|XF6_500|X001/i.test(
            $scope.panel_model
          )
        ) {
          $scope.barChartOptions.yaxis.min = "0";
          $scope.barChartOptions.yaxis.max = "26";
          $scope.barChartOptions.yaxis.tickSize = "2";
          $scope.barChartOptions.yaxis.tickDecimals = "1";
          $scope.barChartOptions.grid.markings[0].yaxis.from = "14";
          $scope.barChartOptions.grid.markings[0].yaxis.to = "14";

          $scope.lineChartOptions.yaxis.min = "8";
          $scope.lineChartOptions.yaxis.max = "26";
          $scope.lineChartOptions.yaxis.tickSize = "2";
          $scope.lineChartOptions.yaxis.tickDecimals = "1";
          $scope.lineChartOptions.grid.markings[0].yaxis.from = "14";
          $scope.lineChartOptions.grid.markings[0].yaxis.to = "14";

          $scope.currentDataset[0].threshold.below = "14.1"; // set threshold using "below"
        }

        if (
          /XTL|XTLN|XTLW|XTLP|TMS6|DualCom|CellComSL|CellComEX|iComSL|iComLNC/i.test(
            $scope.panel_model
          )
        ) {
          // Change the Chart Title to "DC Voltage" for these Panel Models (instead of AC Voltage)
          $scope.chartTitle = "DC Voltage (V)";

          $scope.barChartOptions.yaxis.min = "0";
          $scope.barChartOptions.yaxis.max = "26";
          $scope.barChartOptions.yaxis.tickSize = "2";
          $scope.barChartOptions.yaxis.tickDecimals = "1";
          $scope.barChartOptions.grid.markings[0].yaxis.from = "10";
          $scope.barChartOptions.grid.markings[0].yaxis.to = "10";

          $scope.lineChartOptions.yaxis.min = "8";
          $scope.lineChartOptions.yaxis.max = "26";
          $scope.lineChartOptions.yaxis.tickSize = "2";
          $scope.lineChartOptions.yaxis.tickDecimals = "1";
          $scope.lineChartOptions.grid.markings[0].yaxis.from = "10";
          $scope.lineChartOptions.grid.markings[0].yaxis.to = "10";

          $scope.currentDataset[0].threshold.below = "10.1"; // set threshold using "below"
        }

        /*
          $scope.barChartOptions.yaxis.min = "0";
          $scope.barChartOptions.yaxis.max = "26";
          $scope.barChartOptions.yaxis.tickSize = "2";
          $scope.barChartOptions.yaxis.tickDecimals = "1";
          //$scope.barChartOptions.grid.markings[0].yaxis.from = "14";
          //$scope.barChartOptions.grid.markings[0].yaxis.to = "14";
          $scope.barChartOptions.grid.markings[0].yaxis.from = "12";
          $scope.barChartOptions.grid.markings[0].yaxis.to = "12";
          $scope.barChartOptions.tooltipOpts.content = '%x : %y Volts';
          $scope.lineChartOptions.yaxis.min = "8";
          $scope.lineChartOptions.yaxis.max = "26";
          $scope.lineChartOptions.yaxis.tickSize = "2";
          $scope.lineChartOptions.yaxis.tickDecimals = "1";
          //$scope.lineChartOptions.grid.markings[0].yaxis.from = "14";
          //$scope.lineChartOptions.grid.markings[0].yaxis.to = "14";
          $scope.lineChartOptions.grid.markings[0].yaxis.from = "12";
          $scope.lineChartOptions.grid.markings[0].yaxis.to = "12";
          $scope.lineChartOptions.tooltipOpts.content = '%x : %y Volts';
          */

        $scope.barChartOptions.tooltipOpts.content = "%x : %y Volts";
        $scope.lineChartOptions.tooltipOpts.content = "%x : %y Volts";
        $scope.currentDataset[0].color = APP_COLORS.chartNormalColor; // set normal data to the normal data color
        $scope.currentDataset[0].threshold.color = APP_COLORS.chartThreshold; // set threshold data to the threshold data color
        //$scope.currentDataset[0].threshold.below = "14.1";   // set threshold using "below"
        //$scope.currentDataset[0].threshold.below = "12.1";   // set threshold using "below"
      }
      if (/Battery Voltage/i.test(chartName)) {
        chartType = "Voltage";

        /********************************************
         * Set Panel model Specific Chart Metadata  *
         ********************************************/
        if (
          /XR150|XR350|XR550|XT30|XT50|XF6_100|XF6_500|X001/i.test(
            $scope.panel_model
          )
        ) {
          $scope.barChartOptions.yaxis.min = "0";
          $scope.barChartOptions.yaxis.max = "16";
          $scope.barChartOptions.yaxis.tickSize = "2";
          $scope.barChartOptions.yaxis.tickDecimals = "1";
          $scope.barChartOptions.grid.markings[0].yaxis.from = "12.0";
          $scope.barChartOptions.grid.markings[0].yaxis.to = "12.0";
          $scope.barChartOptions.tooltipOpts.content = "%x : %y Volts";

          $scope.lineChartOptions.yaxis.min = "8";
          $scope.lineChartOptions.yaxis.max = "16";
          $scope.lineChartOptions.yaxis.tickSize = "2";
          $scope.lineChartOptions.yaxis.tickDecimals = "1";
          $scope.lineChartOptions.grid.markings[0].yaxis.from = "12.0";
          $scope.lineChartOptions.grid.markings[0].yaxis.to = "12.0";

          $scope.currentDataset[0].threshold.below = "12.1"; // set threshold using "below"
        }

        if (
          /XTL|XTLN|XTLW|XTLP|TMS6|DualCom|CellComSL|CellComEX|iComSL|iComLNC/i.test(
            $scope.panel_model
          )
        ) {
          $scope.barChartOptions.yaxis.min = "0";
          $scope.barChartOptions.yaxis.max = "6";
          $scope.barChartOptions.yaxis.tickSize = "1";
          $scope.barChartOptions.yaxis.tickDecimals = "1";
          $scope.barChartOptions.grid.markings[0].yaxis.from = "3.7";
          $scope.barChartOptions.grid.markings[0].yaxis.to = "3.7";
          $scope.barChartOptions.tooltipOpts.content = "%x : %y Volts";

          $scope.lineChartOptions.yaxis.min = "0";
          $scope.lineChartOptions.yaxis.max = "6";
          $scope.lineChartOptions.yaxis.tickSize = "1";
          $scope.lineChartOptions.yaxis.tickDecimals = "1";
          $scope.lineChartOptions.grid.markings[0].yaxis.from = "3.7";
          $scope.lineChartOptions.grid.markings[0].yaxis.to = "3.7";

          $scope.currentDataset[0].threshold.below = "3.8"; // set threshold using "below"
        }
        /*
          $scope.barChartOptions.yaxis.min = "0";
          $scope.barChartOptions.yaxis.max = "16";
          $scope.barChartOptions.yaxis.tickSize = "2";
          $scope.barChartOptions.yaxis.tickDecimals = "1";
          $scope.barChartOptions.grid.markings[0].yaxis.from = "12.6";
          $scope.barChartOptions.grid.markings[0].yaxis.to = "12.6";
          $scope.barChartOptions.tooltipOpts.content = '%x : %y Volts';
          $scope.lineChartOptions.yaxis.min = "8";
          $scope.lineChartOptions.yaxis.max = "16";
          $scope.lineChartOptions.yaxis.tickSize = "2";
          $scope.lineChartOptions.yaxis.tickDecimals = "1";
          $scope.lineChartOptions.grid.markings[0].yaxis.from = "12.6";
          $scope.lineChartOptions.grid.markings[0].yaxis.to = "12.6";
          $scope.lineChartOptions.tooltipOpts.content = '%x : %y Volts';
          */
        $scope.barChartOptions.tooltipOpts.content = "%x : %y Volts";
        $scope.lineChartOptions.tooltipOpts.content = "%x : %y Volts";
        $scope.currentDataset[0].color = APP_COLORS.chartNormalColor; // set normal data to the normal data color
        $scope.currentDataset[0].threshold.color = APP_COLORS.chartThreshold; // set threshold data to the threshold data color
        //$scope.currentDataset[0].threshold.below = "12.7";   // set threshold using "below"
      }

      /****************************************************************************************
       * The charting plugin requires valid javascript dates for use in time series charts.   *
       * We need to process the data retrieved from the panel statistics api so that we have  *
       * javascript date objects.                                                             *
       ****************************************************************************************/
      angular.forEach(
        $scope.currentRawData,
        function (item) {
          item[0] = new Date(item[0]).getTime(); // convert the ISO time (e.g. 2015-03-24T11:39:34-05:00) received into Javascript time objects
          //item[0] = new Date.parse(item[0]).getTime();      // this is really commented - don't recomment

          /*************************************************************************
           * Depending on the chart type, we do additional data manipulation here. *
           *************************************************************************/
          // Charts with all negative values (e.g. signal strength) need some special treatment to display full length data bars on the chart
          if (chartType === "Signal") {
            item.push(item[2]);
            item[2] = "-150"; // flotjs plugin bug fix: add additional data value of -150 (or so) to show bar's full length on chart
            this.push(item);
          } else {
            this.push(item); // just use the given data series for non Signal charts
          }
        },
        $scope.currentChartData
      );

      /*******************************************************************************************************************
       * Chrome Browser Bug Fix:                                                                                         *
       * This sort function and the call to sort currentChartData is a Chrome browser bug fix. In Chrome, dates can      *
       * become unsorted some how (the dates remain properly sorted in FF and Safari). Dates are sorted when the data    *
       * is retrieved by panelDataService and dates should be sorted automatically by FlotJS regardless, but this is not *
       * happening in Chrome. This manual re-sorting fixes the issue for Chrome.                                         *
       *******************************************************************************************************************/
      function compareDates(a, b) {
        //if (a[0] < b[0]) return -1;   // string comparison actually works but is not best practice given the format
        //if (a[0] > b[0]) return 1;
        if (new Date(a[0]) < new Date(b[0])) return -1;
        if (new Date(a[0]) > new Date(b[0])) return 1;
        return 0;
      }

      $scope.currentChartData.sort(compareDates); // Sort the data by date (ascending)

      let today = new Date();
      var setRange = new Date();
      setRange.setDate(setRange.getDate() - $scope.dataDaysBack);
      $scope.currentChartDataSegment = $filter("filter")(
        $scope.currentChartData,
        function (record) {
          // if true
          var reportDate = new Date(record[0]); // comes back invalid
          return reportDate > setRange && reportDate <= today;
        }
      );

      if (chartName != "Current Signal (dBm)") {
        $scope.clickable = false;
        if ($scope.dataDaysBack == 1) {
          $scope.changeTimeframe(30);
        } else {
          $scope.changeTimeframe($scope.dataDaysBack);
        }
      } else {
        $scope.clickable = true;
        $scope.changeTimeframe($scope.dataDaysBack);
      }
    };

    /**
     * Called from the flot chart directive. Changes to hourly view if you click on a datapoint on week/month/year/all view
     */
    $scope.jumpToHourlyView = function (dayFromClick) {
      $scope.whichDay = new Date(dayFromClick);
      $scope.changeDayOfData();
    };

    function getDayIndex() {
      var index = undefined;

      function isTheDay(day) {
        return (
          day.currentDate.getFullYear() == $scope.whichDay.getFullYear() &&
          day.currentDate.getMonth() == $scope.whichDay.getMonth() &&
          day.currentDate.getDate() == $scope.whichDay.getDate()
        );
      }

      index = $scope.hourlyPanelData.findIndex(isTheDay);

      if (index > -1) $scope.selectedDayHasData = true;
      else index = undefined;

      return index;
    }

    /**
     * Change the day of data when in the hourly view
     */
    $scope.changeDayOfData = function () {
      $scope.selectedDayHasData = false;

      $scope.dayOfData = getDayIndex();

      if ($scope.selectedDayHasData) {
        $scope.endHourlyData =
          $scope.hourlyPanelData[$scope.dayOfData].currentDate;
        $scope.beginHourlyData =
          $scope.hourlyPanelData[$scope.dayOfData].dayBefore;
      } else {
        $scope.endHourlyData = $scope.whichDay;
        $scope.beginHourlyData = $scope.whichDay - dayInMilliseconds;
      }

      //$scope.currentDataset[0].data = $scope.hourlyPanelData[$scope.dayOfData].signal;
      $scope.changeTimeframe(1);
    };

    /****************************************************************************************************************
     * This function is executed when a user changes the data period of the main flotjs chart by clicking on one of *
     * the chart interval links in the chart controls section. It simply changes the underlying chart data by using *
     * a subset of the data or all the data and the chart automatically redraws itself.                             *
     ****************************************************************************************************************/
    function changeTimeframe(daysBack) {
      if (typeof daysBack === "undefined") {
        return;
      }
      $scope.dataDaysBack = daysBack;

      /*******************************************************************************************************************
       * Chrome Browser Bug Fix:                                                                                         *
       * This sort function and the call to sort currentChartData is a Chrome browser bug fix. In Chrome, dates can      *
       * become unsorted some how (the dates remain properly sorted in FF and Safari). Dates are sorted when the data    *
       * is retrieved by panelDataService and dates should be sorted automatically by FlotJS regardless, but this is not *
       * happening in Chrome. This manual re-sorting fixes the issue for Chrome.                                         *
       *******************************************************************************************************************/
      function compareDates(a, b) {
        //if (a[0] < b[0]) return -1;   // string comparison actually works but is not best practice given the format
        //if (a[0] > b[0]) return 1;
        if (new Date(a[0]) < new Date(b[0])) return -1;
        if (new Date(a[0]) > new Date(b[0])) return 1;
        return 0;
      }

      let today = new Date();
      var setRange = new Date();
      setRange.setDate(setRange.getDate() - $scope.dataDaysBack);
      $scope.currentChartDataSegment = $filter("filter")(
        $scope.currentChartData,
        function (record) {
          // if true
          var reportDate = new Date(record[0]);
          return reportDate > setRange && reportDate <= today;
        }
      );

      $scope.currentChartData.sort(compareDates); // Sort the data by date (ascending)

      if (daysBack === 1 && $scope.hasHourlyData) {
        $scope.showHourlyData = true;

        //we need to change the bar width to allow smaller bars.
        $scope.barChartOptions.series.bars.barWidth = barWidth24Hours;
        $scope.barChartOptions.xaxis.minTickSize = [1, "hour"];
        $scope.barChartOptions.grid.clickable = false;
        $scope.lineChartOptions.grid.clickable = false;
        $scope.lineChartOptions.xaxis.minTickSize = [1, "hour"];

        if ($scope.selectedDayHasData) {
          $scope.currentDataset[0].data =
            $scope.hourlyPanelData[$scope.dayOfData].signal;
        } else {
          $scope.currentDataset[0].data = [[null, null, "-150"]];
        }

        $scope.barChartOptions.tooltipOpts.content = function (
          label,
          xval,
          yval,
          flotItem
        ) {
          return (
            "Tower: " +
            $scope.hourlyPanelData[$scope.dayOfData].signal[
              flotItem.dataIndex
            ][3] +
            "<br> Time: %x <br> Signal: %y dBm "
          );
        };

        $scope.lineChartOptions.tooltipOpts.content = function (
          label,
          xval,
          yval,
          flotItem
        ) {
          return (
            "Tower: " +
            $scope.hourlyPanelData[$scope.dayOfData].signal[
              flotItem.dataIndex
            ][3] +
            "<br> Time: %x <br> Signal: %y dBm "
          );
        };
      } else {
        $scope.showHourlyData = false;
        $scope.barChartOptions.series.bars.barWidth = 24 * 60 * 60 * 600;
        $scope.currentDataset[0].data = $scope.currentChartDataSegment;
        $scope.barChartOptions.xaxis.minTickSize = [1, "day"];
        if ($scope.clickable) {
          $scope.barChartOptions.grid.clickable = true;
          $scope.lineChartOptions.grid.clickable = true;
          $scope.barChartOptions.tooltipOpts.content = "%x : %y dBm";
          $scope.lineChartOptions.tooltipOpts.content = "%x : %y dBm";
        }
        $scope.lineChartOptions.xaxis.minTickSize = [1, "day"];
      }
    }

    //$timeout($scope.changeChart);   // We won't call this now that the api call provides the data. The panel data service will call changeChart b/c apis are aynchronous

    /*******************************************************************************************
     * This function is triggered when the user click the 'Refresh statistics' button on the   *
     * System Analytics page. It retrieves real time statistics data from the associated panel.*
     *******************************************************************************************/
    $scope.refreshStatistics = function () {
      //$scope.panel_id
      $scope.realTimeStatsLoading = true;
      $scope.semproResults = angular.fromJson(
        PanelDataService.sendSemproMessage($scope.panel_id, $scope)
      );
      //$scope.carrierApiCallStatus = "Retrieving Carrier Information... ";
    };

    var statusModal = {};
    /**
     * Creates and opens a modal to display status messages during first connect
     **/
    $scope.openStatusModal = function () {
      statusModal = $modal.open({
        templateUrl: "refreshModalContent.html",
        controller: "RealtimeStatsModalCtrl",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });
      statusModal.result.catch(function (error) {
        console.error(error);
      });
    };

    var closeStatusModal = function () {
      try {
        statusModal.close("test");
      } catch (error) {}
    };

    /*****************************************************************************************
     * A controller specifically for displaying results after receiving real time statistics *
     *****************************************************************************************/
    App.controller("RealtimeStatsModalCtrl", function ($scope, $modalInstance) {
      $scope.reload = function () {
        $modalInstance.close("reload");
      };

      $scope.cancel = function () {
        $modalInstance.dismiss("cancel");
      };
    });
  },
]);
