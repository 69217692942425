import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { ZonePetImmunity, ZoneSensorType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { wirelessPetImmunityEnabledSerialRange } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationPIRPetImmunityField_zone$key } from "./__generated__/ZoneInformationPIRPetImmunityField_zone.graphql";

export const zoneInformationPIRPetImmunityFieldId = (number: string) =>
  `zone-information-pir-pet-immunity-${number}`;

function ZoneInformationPIRPetImmunityField() {
  const [
    { number, wireless, wirelessPetImmunity, sensorType, serialNumber },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationPIRPetImmunityField_zone$key>(
    graphql`
      fragment ZoneInformationPIRPetImmunityField_zone on Zone {
        number
        wireless
        wirelessPetImmunity
        sensorType
        serialNumber
      }
    `
  );
  const fieldId = zoneInformationPIRPetImmunityFieldId(String(number));
  const serialNumberSetValid =
    Number(serialNumber) >= wirelessPetImmunityEnabledSerialRange.min &&
    Number(serialNumber) <= wirelessPetImmunityEnabledSerialRange.max;
  const disabled = !(
    wireless &&
    ZoneSensorType.WIRELESS_PIR === sensorType &&
    serialNumberSetValid
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="PIR Pet Immunity"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={wirelessPetImmunity}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "wirelessPetImmunity");
          });
        }}
      >
        <Select.Option value={ZonePetImmunity.OFF}>Off</Select.Option>
        <Select.Option value={ZonePetImmunity.ON}>On</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationPIRPetImmunityField;
