import { ALL_AREAS_OPTION, ALL_DOORS_OPTION } from "./BaseGroupForm";
import validator from "./validator";

export type GroupValidationError = {
  fieldName: string;
  errorMessages: string[];
};

export type GroupFormState = {
  number?: string;
  name: string;
  doors: number[];
  areas: string[];
  schedules: string[];
  lockdown: boolean;
  schedulesEnabled: boolean;
  armAuthority: boolean;
  disarmAuthority: boolean;
  swipeTwiceToArm: boolean;
  userProgramming: boolean;
  groupsProgramming: boolean;
  auditDaysEnabled: boolean;
  auditDaysValue: string;
  persisted: boolean;
  validationErrors: GroupValidationError[];
  swipeTwiceDisabled: boolean;
  rawDoors: number[];
  rawAreas: number[];
};

export type GroupReducerAction =
  | { type: "update-number"; value: string }
  | { type: "update-name"; value: string }
  | { type: "set-all-doors" }
  | { type: "add-door"; value: number }
  | { type: "remove-door"; value: number }
  | { type: "set-all-areas" }
  | { type: "add-area"; value: string }
  | { type: "remove-area"; value: string }
  | { type: "add-schedule"; value: string }
  | { type: "remove-schedule"; value: string }
  | { type: "toggle-lockdown" }
  | { type: "toggle-schedules" }
  | { type: "toggle-arm-authority" }
  | { type: "toggle-disarm-authority" }
  | { type: "toggle-swipe-twice-to-arm" }
  | { type: "toggle-user-programming" }
  | { type: "toggle-groups-programming" }
  | { type: "toggle-audit-days" }
  | { type: "toggle-audit-days-value"; value: string }
  | { type: "disable-swipe-twice-to-arm"; value: boolean };

const reducer = (formState: GroupFormState, action: GroupReducerAction) => {
  const newFormState: GroupFormState = { ...formState };
  switch (action.type) {
    case "update-number":
      newFormState.number = action.value;
      break;
    case "update-name":
      newFormState.name = action.value;
      break;
    case "set-all-doors":
      newFormState.doors = [ALL_DOORS_OPTION.number];
      break;
    case "add-door":
      newFormState.doors = [...newFormState.doors, action.value];
      break;
    case "remove-door":
      newFormState.doors = formState.doors.filter((d) => d !== action.value);
      break;
    case "set-all-areas":
      newFormState.areas = [ALL_AREAS_OPTION.number];
      break;
    case "add-area":
      newFormState.areas = [...newFormState.areas, action.value];
      break;
    case "remove-area":
      newFormState.areas = formState.areas.filter((d) => d !== action.value);
      break;
    case "add-schedule":
      newFormState.schedules = [...newFormState.schedules, action.value];
      break;
    case "remove-schedule":
      newFormState.schedules = formState.schedules.filter(
        (s) => s !== action.value
      );
      break;
    case "toggle-lockdown":
      newFormState.lockdown = !formState.lockdown;
      break;
    case "toggle-arm-authority":
      newFormState.armAuthority = !formState.armAuthority;
      if (!newFormState.armAuthority) {
        newFormState.swipeTwiceDisabled = true;
        newFormState.swipeTwiceToArm = false;
      } else newFormState.swipeTwiceDisabled = false;
      break;
    case "toggle-disarm-authority":
      newFormState.disarmAuthority = !formState.disarmAuthority;
      break;
    case "toggle-schedules":
      newFormState.schedulesEnabled = !formState.schedulesEnabled;
      break;
    case "toggle-swipe-twice-to-arm":
      newFormState.swipeTwiceToArm = !formState.swipeTwiceToArm;
      break;
    case "toggle-user-programming":
      newFormState.userProgramming = !formState.userProgramming;
      break;
    case "toggle-groups-programming":
      newFormState.groupsProgramming = !formState.groupsProgramming;
      break;
    case "toggle-audit-days":
      newFormState.auditDaysEnabled = !formState.auditDaysEnabled;
      break;
    case "toggle-audit-days-value":
      newFormState.auditDaysValue = action.value;
      break;
    case "disable-swipe-twice-to-arm":
      newFormState.swipeTwiceDisabled = !newFormState.swipeTwiceDisabled;
      break;
  }

  return validator(newFormState);
};

export default reducer;
