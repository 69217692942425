/**
 * @generated SignedSource<<042941983660a58c3630e0021852a4d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DownloadsQuery$variables = {};
export type DownloadsQuery$data = {
  readonly myPerson: {
    readonly appUser: {
      readonly canUploadFiles?: boolean;
    } | null;
  } | null;
};
export type DownloadsQuery = {
  response: DownloadsQuery$data;
  variables: DownloadsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canUploadFiles",
      "storageKey": null
    }
  ],
  "type": "SupervisorUser",
  "abstractKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "myPerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "appUser",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DownloadsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "myPerson",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "appUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0c65992621aa1806bf140ef5dce0a39",
    "id": null,
    "metadata": {},
    "name": "DownloadsQuery",
    "operationKind": "query",
    "text": "query DownloadsQuery {\n  myPerson {\n    __typename\n    appUser {\n      __typename\n      ... on SupervisorUser {\n        canUploadFiles\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "400af6127323cd3ce4941e438e9e8781";

export default node;
