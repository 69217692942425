import { useRef } from "react";

export default function useLockedValue<T>(value: T, locked = true) {
  const cachedValue = useRef(value);

  if (locked) {
    return cachedValue.current;
  }

  cachedValue.current = value;
  return cachedValue.current;
}
