/**
 * @generated SignedSource<<4f68cc37bc2b1154f6d63c9dd6f4f863>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneSupervisionTime = "NONE" | "_1440_MIN" | "_240_MIN" | "_3_MIN" | "_60_MIN" | "_720_MIN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationCompetitorWirelessField_zone$data = {
  readonly competitorWireless: boolean | null;
  readonly id: string;
  readonly number: string;
  readonly supervisionTime: ZoneSupervisionTime | null;
  readonly wireless: boolean;
  readonly " $fragmentType": "ZoneInformationCompetitorWirelessField_zone";
};
export type ZoneInformationCompetitorWirelessField_zone$key = {
  readonly " $data"?: ZoneInformationCompetitorWirelessField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationCompetitorWirelessField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationCompetitorWirelessField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "competitorWireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supervisionTime",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "c2bbf4a702e23528da047e183cbbdb18";

export default node;
