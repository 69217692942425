/**
 * @name App.factory.PanelInteractionService
 *
 * Service for interacting directly with the system
 *
 */
App.factory("ArmingService", [
  "$q",
  "ArmingAPI",
  "JobService",
  "UserService",
  "PROPS",
  function ($q, ArmingAPI, JobService, UserService, PROPS) {
    return {
      /**
       * Arm the system
       */
      armSystem: function (params) {
        let deferred = $q.defer();

        ArmingAPI.armSystem(
          params,
          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  deferred.reject(jobError);
                },
                function (notify) {
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {}
        );
        return deferred.promise;
      },

      /**
       * Disarm the system
       */
      disarmSystem: function (params) {
        let deferred = $q.defer();
        ArmingAPI.disarmSystem(
          params,
          function (success) {
            JobService.process(success.job.uuid)
              .then(
                function (jobData) {
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  deferred.reject(jobError);
                },
                function (notify) {
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {}
        );
        return deferred.promise;
      },

      /**
       * Arm the system
       */
      getArmedStatus: function (params, useCache) {
        _this = this;
        let deferred = $q.defer();
        params["cached"] = useCache;
        ArmingAPI.getArmedStatus(
          params,
          function (success) {
            JobService.processV1(success.job.uuid)
              .then(
                function (jobData) {
                  deferred.resolve(jobData);
                },
                function (jobError) {
                  deferred.reject(jobError);
                },
                function (notify) {
                  deferred.notify(notify);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
