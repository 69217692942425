/**
 * @generated SignedSource<<2017668c37be17ed6f771fb53dddf8c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BellOptionsBellCutoffTimeField_bellOptions$data = {
  readonly bellCutoffTime: number;
  readonly bellCutoffTimeMax: number;
  readonly bellCutoffTimeMin: number;
  readonly " $fragmentType": "BellOptionsBellCutoffTimeField_bellOptions";
};
export type BellOptionsBellCutoffTimeField_bellOptions$key = {
  readonly " $data"?: BellOptionsBellCutoffTimeField_bellOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"BellOptionsBellCutoffTimeField_bellOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BellOptionsBellCutoffTimeField_bellOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bellCutoffTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bellCutoffTimeMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bellCutoffTimeMax",
      "storageKey": null
    }
  ],
  "type": "BellOptions",
  "abstractKey": null
};

(node as any).hash = "f06675ecc13acc4bc5c880369b7c959b";

export default node;
