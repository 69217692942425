App.directive("daSearchPopover", function ($compile) {
  var html =
    '<div id="panel-anim-bounceInLeft" class="search-box" style="padding: 0"><form role="form" style=""> <div class="form-group"> <label for="input-search" class="sr-only">Search</label> <input style="height: 30px;font-size: 1em"  id="input-search" type="search" placeholder="Search" height="30"  class="input-sm form-control sm-input-class"/> </div> <div class="form-group"></div> <select style="height: 30px; font-size: 1em;" name="Filter" class="form-control"> <option value="1">Customers</option> <option value="2">Users</option> <option value="3">Systems</option> <option value="4">Mac Addresses</option> </select> <button style=" float:right; padding: 4px;margin: 1em 0px 1em ;"type="search" class="btn btn-default btn-sm">Search</button> </form></div>';

  return {
    restrict: "A",
    transclude: true,
    template: "<span ng-transclude></span>",
    link: function (scope, element, attrs) {
      var popOverContent;
      //var html = template;
      popOverContent = $compile(html)(scope);

      var options = {
        content: popOverContent,
        placement: "left",
        html: true,
        title: scope.title,
        container: "body",
        animation: true,
      };
      $(element).popover(options);
    },
    scope: {
      items: "=",
      title: "@",
    },
  };
});
