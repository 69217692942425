import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsAcFailOutputField_outputOptions$key } from "./__generated__/OutputOptionsAcFailOutputField_outputOptions.graphql";

export const outputOptionsAcFailOutputFieldId = () =>
  `output-options-ac-fail-output`;

function OutputOptionsAcFailOutputField() {
  const [{ acFailOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsAcFailOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsAcFailOutputField_outputOptions on OutputOptions {
          id
          acFailOutput {
            id
            formattedNumber
            number
          }
        }
      `
    );

  const fieldId = outputOptionsAcFailOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs

  const tooltip =
    "This output will be turned on when an AC failure occurs. This output will be turned off when AC is restored.";

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const { current: originalValue } = React.useRef(acFailOutput.formattedNumber);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="AC Fail Output"
      tooltip={tooltip}
    >
      <TextInput
        id={fieldId}
        value={acFailOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "acFailOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "acFailOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsAcFailOutputField;
