import { useEffect } from "react";
import { useRedirectTo404 } from "./EntryPointContext";

export default function RedirectTo404() {
  const redirectTo404 = useRedirectTo404();

  useEffect(() => {
    redirectTo404();
  }, [redirectTo404]);

  return null;
}
