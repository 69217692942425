import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatRequireSiteCodeField_cardFormat$key } from "./__generated__/CardFormatRequireSiteCodeField_cardFormat.graphql";

export const cardFormatRequireSiteCodeFieldId = (number: string) =>
  `card-format-require-site-code-${number}`;

function CardFormatRequireSiteCodeField() {
  const [{ id, requireSiteCode, number }, updateCardFormat] =
    useCardFormatFragment<CardFormatRequireSiteCodeField_cardFormat$key>(
      graphql`
        fragment CardFormatRequireSiteCodeField_cardFormat on CardFormat {
          id
          requireSiteCode
          number
        }
      `
    );

  const fieldId = cardFormatRequireSiteCodeFieldId(number);
  const label = "Require Site Code";
  const disabled = false;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to allow verification of Site Code within the Wiegand Credential in addition to the User Code."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        name={id}
        disabled={disabled}
        checked={requireSiteCode ?? false}
        onChange={() => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(!requireSiteCode, "requireSiteCode");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatRequireSiteCodeField;
