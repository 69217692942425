/**
 * @generated SignedSource<<e4c8b3280a661903c27895f9a996ffce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatForm_site$data = {
  readonly cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly wiegandLength: number;
  }>;
  readonly " $fragmentType": "CardFormatForm_site";
};
export type CardFormatForm_site$key = {
  readonly " $data"?: CardFormatForm_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatForm_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatForm_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CardFormat",
      "kind": "LinkedField",
      "name": "cardFormats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wiegandLength",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "37e918fd7046849cd335f88774321311";

export default node;
