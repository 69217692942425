/**
 * @generated SignedSource<<07e077eb619966e65bc932467edccb04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Grades = "GRADE_0" | "GRADE_2" | "GRADE_3";
import { FragmentRefs } from "relay-runtime";
export type XRSecurityGradeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly securityGrade: {
      readonly grade: Grades;
      readonly id: string;
      readonly updatedAt: string;
    } | null;
  };
  readonly " $fragmentType": "XRSecurityGradeProgrammingConceptFormInline_controlSystem";
};
export type XRSecurityGradeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRSecurityGradeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRSecurityGradeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRSecurityGradeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "c1eaf8aeb31b88b62be50f0b1d52e188";

export default node;
