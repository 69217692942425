import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathSecondLinePrefixFor893AField_communicationPath$key } from "./__generated__/CommPathSecondLinePrefixFor893AField_communicationPath.graphql";

export const commPathSecondLinePrefixFor893AFieldId = (number: string) =>
  `comm-path-second-line-prefix-for-893a-${number}`;

function CommPathSecondLinePrefixFor893AField() {
  const [{ number, use893A, secondLinePrefixFor893A }, updateCommPath] =
    useCommPathFragment<CommPathSecondLinePrefixFor893AField_communicationPath$key>(
      graphql`
        fragment CommPathSecondLinePrefixFor893AField_communicationPath on CommunicationPath {
          number
          use893A
          secondLinePrefixFor893A
        }
      `
    );

  const fieldId = commPathSecondLinePrefixFor893AFieldId(String(number));
  const disabled = !use893A;
  const label = "Second Line Prefix For 893A";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Prefix added before the second phone number when using the 893A module."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={secondLinePrefixFor893A.toString()}
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "secondLinePrefixFor893A");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathSecondLinePrefixFor893AField;
