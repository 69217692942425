/**
 * @ngdoc service
 * @name App.factory:ControlSystemsVideoDevicesV2API
 *
 * @description
 * API factory for Control System Video Devices
 *
 */
App.factory("ControlSystemsVideoDevicesV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl +
        "/v2/control_systems/:control_system_id/video_devices/:video_device_id",
      {},
      {
        show: {
          method: "GET",
          params: { control_system_id: "@control_system_id" },
          isArray: true,
        },
        index: {
          method: "GET",
          params: {
            control_system_id: "@control_system_id",
            video_device_id: "@video_device_id",
          },
        },
        new: {
          method: "GET",
          params: {
            control_system_id: "@control_system_id",
            video_device_id: "new",
          },
        },
      }
    );
  },
]);
