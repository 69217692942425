import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useMenuDisplayFragment } from "./MenuDisplayContext";
import { resolveMenuDisplayRules } from "./utils";
import { MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay$key } from "./__generated__/MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay.graphql";

export const menuDisplayArmDisarmDisplayKeypadsFieldId = () =>
  "menu-display-arm-disarm-display-keypads";

function MenuDisplayArmDisarmDisplayKeypadsField() {
  const [{ armDisarmDisplayKeypads }, updateMenuDisplay] =
    useMenuDisplayFragment<MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay$key>(
      graphql`
        fragment MenuDisplayArmDisarmDisplayKeypadsField_menuDisplay on MenuDisplay {
          armDisarmDisplayKeypads
        }
      `
    );

  const fieldId = menuDisplayArmDisarmDisplayKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } = resolveMenuDisplayRules(
    useHardwareModel()
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Arm/Disarm Display Keypads"
      tooltip="Enter the keypad addresses from which users can arm and disarm areas."
    >
      <TextInput
        id={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={armDisarmDisplayKeypads}
        onChange={({ target }) => {
          updateMenuDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "armDisarmDisplayKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateMenuDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "armDisarmDisplayKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MenuDisplayArmDisarmDisplayKeypadsField;
