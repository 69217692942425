//  This is the root component for the Camera Table. It is responsible for
//  setting up the Relay environment and the AlertsContextProvider. It also
//  handles the Suspense fallback.
//  The Camera Table is a React component that is rendered by Angular. The Angular
//  component is defined in src/components/CameraTable/CameraTable.tsx. The
//  used on the System Information page.
//  Located in /dealer-admin/public/app/control_system/video_devices/video-control-system-page-view.html
// as  <camera-table>
// it is a replacement for the old Angular component located in /dealer-admin/public/app/control_system/video_devices/video-control-system-page-view.html

import graphql from "babel-plugin-relay/macro";
import { GenericPageFallback } from "components/GenericPageFallback";
import { AlertsContextProvider } from "contexts/AlertsContext";
import * as React from "react";
import { useMemo } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { react2angular } from "react2angular";
import { idAsString, toControlSystemId } from "securecom-graphql/client";
import { CameraMobileWidget } from "./CameraMobileWidget";
import { CameraTable } from "./CameraTable";
import { CameraTableQuery } from "./__generated__/CameraTableQuery.graphql";

const CameraTableView = ({
  state,
  stateParams,
  videoDeviceServiceIsBusy,
  userService,
  videoDeviceService,
  controlSystemService,
  scope,
}: {
  state: any;
  stateParams: {
    customer_id: string;
    control_system_id: string;
  };
  rootScope: any;
  videoDeviceServiceIsBusy: boolean;
  userService: any;
  videoDeviceService: any;
  controlSystemService: any;
  scope: any;
}) => {
  const globalSystemId = idAsString(
    toControlSystemId(stateParams.control_system_id)
  );

  const data = useLazyLoadQuery<CameraTableQuery>(
    graphql`
      query CameraTableQuery($systemId: ID!) {
        controlSystem: node(id: $systemId) {
          ... on ControlSystem {
            id
            name
            panel {
              hardwareModel
              softwareVersion
            }
            ...CameraTable_controlSystem
            ...CameraMobileWidgetQuery_controlSystem
          }
        }
      }
    `,
    { systemId: globalSystemId },
    { fetchPolicy: "store-and-network" }
  );

  const [isMobileView, setIsMobileView] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return data.controlSystem ? (
    <>
      {isMobileView ? (
        <CameraMobileWidget
          state={state}
          systemRef={data.controlSystem}
          customerId={stateParams.customer_id}
          systemId={stateParams.control_system_id}
          isRefreshing={videoDeviceServiceIsBusy}
          userService={userService}
          videoDeviceService={videoDeviceService}
          controlSystemService={controlSystemService}
          scope={scope}
        />
      ) : (
        <CameraTable
          state={state}
          systemRef={data.controlSystem}
          customerId={stateParams.customer_id}
          systemId={stateParams.control_system_id}
          isRefreshing={videoDeviceServiceIsBusy}
          userService={userService}
          videoDeviceService={videoDeviceService}
          controlSystemService={controlSystemService}
          scope={scope}
        />
      )}
    </>
  ) : null;
};

const CameraTableRoot = ({
  RelayService,
  $state,
  $stateParams,
  $rootScope,
  videoDeviceServiceIsBusy,
  UserService,
  VideoDeviceService,
  ControlSystemService,
  $scope,
}: any) => {
  const environment = useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  return (
    <RelayEnvironmentProvider environment={environment}>
      <React.Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <CameraTableView
            stateParams={$stateParams}
            videoDeviceServiceIsBusy={videoDeviceServiceIsBusy}
            state={$state}
            rootScope={$rootScope}
            userService={UserService}
            videoDeviceService={VideoDeviceService}
            controlSystemService={ControlSystemService}
            scope={$scope}
          />
        </AlertsContextProvider>
      </React.Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "cameraTable",
    react2angular(
      CameraTableRoot,
      ["videoDeviceServiceIsBusy"],
      [
        "RelayService",
        "$rootScope",
        "$scope",
        "$state",
        "$stateParams",
        "UserService",
        "VideoDeviceService",
        "ControlSystemService",
      ]
    )
  );
};
