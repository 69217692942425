/**
 *
 * DoorIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DoorIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M48,0H16c-1.104,0-2,0.896-2,2v47.048c0,0.644,0.31,1.248,0.832,1.624l18,12.952C33.179,63.873,33.588,64,34,64
      c0.312,0,0.625-0.073,0.912-0.22C35.58,63.438,36,62.75,36,62V51h12c1.104,0,2-0.896,2-2V2C50,0.896,49.104,0,48,0z M32,58.097
      L18,48.023V6.195l14,11.088V58.097z M46,47H36V16.315c0-0.611-0.279-1.188-0.758-1.568L21.672,4H46V47z"
        />
        <path d="M26,39c1.104,0,2-0.896,2-2v-4c0-1.104-0.896-2-2-2s-2,0.896-2,2v4C24,38.104,24.896,39,26,39z" />
      </g>
    </g>
  </SvgIcon>
);

export default DoorIcon;
