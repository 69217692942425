export type Notification = {
  type: "info" | "success" | "error" | "warning";
  text: string;
  title?: string;
  json?: any;
};

export type AngularBillingAddress = {
  address1?: string;
  address2?: string;
  city?: string;
  state_province?: string;
  postal_code?: string;
  country?: string;
};

export type AngularCountryCodes = {
  code: string;
  country: string;
}[];

export type AngularStateCodes = {
  [key: string]: string;
};

export type BillingAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export type GenericControlSystemState = {
  name: string;
  nickname: string | null;
  type: string;
  useBillingAddress: boolean;
} & BillingAddress;

export enum SavingState {
  Idle = "Idle",
  Saving = "Saving",
  Success = "Success",
  Errored = "Errored",
}

export type UserServiceType = {
  customerInfo: AngularBillingAddress;
  customer_id: string;
  canEditVideoDevice: () => boolean;
  canTestCameras: () => boolean;
  canUpdateHikDoorbellFirmware: () => boolean;
  canCreateTempDealerCustomerUser: () => boolean;
  dealerInfo: {
    id: number;
    allow_mini_cellcom?: false;
    allow_supercell?: false;
  };
  isAdmin: () => boolean;
  isDealerAdmin: () => boolean;
  isDealerTechnician: () => boolean;
  tagsEnabled(): boolean;
};

export type RecentActivityServiceType = {
  props: {
    storageKeys: {
      system: string;
    };
  };
  updateList: (
    key: any,
    recentSystem: {
      isSite: boolean;
      id: any;
      customer_id: any;
      customer_name: any;
      name: any;
    }
  ) => void;
};
