import * as React from "react";
import { useProgrammingConceptFormListItemIdContext } from "./ProgrammingConceptFormListItemIdContext";
import { useProgrammingConceptIdContext } from "./ProgrammingConceptIdContext";

type SupportedFieldProps =
  | React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  | React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >;

type FieldValue = SupportedFieldProps["value"] | boolean;

class ChangedFields extends Map<string, FieldValue> {}

type ChangedConceptFields = {
  changedFields: ChangedFields;
  listItems: Map<string, ChangedFields>;
};
export class ChangedProgrammingConcepts extends Map<
  string,
  ChangedConceptFields
> {}

export const SetChangedProgrammingConceptsContext = React.createContext<
  React.Dispatch<React.SetStateAction<ChangedProgrammingConcepts>>
>(() => {});

export const ChangedProgrammingConceptsContext =
  React.createContext<ChangedProgrammingConcepts>(
    new ChangedProgrammingConcepts()
  );

const ChangedProgrammingConceptContext =
  React.createContext<ChangedConceptFields>({
    changedFields: new ChangedFields(),
    listItems: new Map(),
  });

export const useSetChangedProgrammingConcepts = () =>
  React.useContext(SetChangedProgrammingConceptsContext);

export const useChangedProgrammingConcepts = () =>
  React.useContext(ChangedProgrammingConceptsContext);

export const useChangedProgrammingConcept = (
  programmingConceptId: string
): ChangedConceptFields => {
  const allChangedProgrammingConcepts = useChangedProgrammingConcepts();
  const concept = allChangedProgrammingConcepts.get(programmingConceptId);
  if (concept) {
    return concept;
  } else {
    return {
      changedFields: new ChangedFields(),
      listItems: new Map(),
    };
  }
};

const programmingConceptHasChanges = (concept: ChangedConceptFields) => {
  return !!concept.changedFields.size || !!concept.listItems.size;
};

const programmingConceptHasFieldChanges = (concept: ChangedConceptFields) => {
  return !!concept.changedFields.size;
};

export const useProgrammingConceptHasChanges = (id: string) => {
  const concept = useChangedProgrammingConcept(id);
  return programmingConceptHasChanges(concept);
};

export const useProgrammingConceptHasFieldChanges = (id: string) => {
  const concept = useChangedProgrammingConcept(id);
  return programmingConceptHasFieldChanges(concept);
};

export const listItemHasChanged = (
  listItemId: string,
  changedFields: ChangedConceptFields
) => {
  const listItem = changedFields.listItems.get(listItemId);
  return !!listItem?.size;
};

export const useListItemHasChanged = (listItemId: string) => {
  const conceptId = useProgrammingConceptIdContext();
  const programmingConcept = useChangedProgrammingConcept(conceptId);
  return listItemHasChanged(listItemId, programmingConcept);
};

export const fieldIsChanged = ({
  id,
  conceptId,
  changedProgrammingConcepts,
  listItemId,
}: {
  id: string;
  conceptId: string;
  changedProgrammingConcepts: ChangedProgrammingConcepts;
  listItemId?: string | null | undefined;
}) => {
  const concept = changedProgrammingConcepts.get(conceptId);
  if (listItemId) {
    const listItem = concept?.listItems.get(listItemId);
    return !!listItem?.has(id);
  } else {
    return !!concept?.changedFields.has(id);
  }
};

export const useFieldIsChanged = (id: string) => {
  const listItemId = useProgrammingConceptFormListItemIdContext();
  const changedProgrammingConcepts = useChangedProgrammingConcepts();
  const conceptId = useProgrammingConceptIdContext();

  return fieldIsChanged({
    id,
    conceptId,
    listItemId,
    changedProgrammingConcepts,
  });
};

export const modifyChangedFields = ({
  programmingConceptId,
  listItemId,
  allChangedConcepts,
  modifier,
}: {
  programmingConceptId: string;
  listItemId?: string | null | undefined;
  allChangedConcepts: ChangedProgrammingConcepts;
  modifier: (allChangedFields: ChangedFields) => ChangedFields;
}): ChangedProgrammingConcepts => {
  const allChangedConceptsClone = new ChangedProgrammingConcepts(
    allChangedConcepts
  );

  const conceptClone = (allChangedConceptsClone.get(programmingConceptId) ??
    allChangedConceptsClone
      .set(programmingConceptId, {
        changedFields: new ChangedFields(),
        listItems: new Map<string, ChangedFields>(),
      })
      .get(programmingConceptId))!;

  if (listItemId) {
    const listItemChangedFields =
      conceptClone.listItems.get(listItemId) ?? new ChangedFields();
    const modifiedListItemChangedFields = modifier(listItemChangedFields);

    if (modifiedListItemChangedFields !== listItemChangedFields) {
      conceptClone.listItems.set(listItemId, modifiedListItemChangedFields);
      allChangedConceptsClone.set(programmingConceptId, conceptClone);

      const conceptListItemChanges = Array.from(
        conceptClone.listItems.values()
      ).filter((changedFields) => !!changedFields.size);

      if (
        !modifiedListItemChangedFields.size &&
        conceptListItemChanges.length === 0
      ) {
        allChangedConceptsClone.delete(programmingConceptId);
      }

      return allChangedConceptsClone;
    } else {
      return allChangedConcepts;
    }
  } else {
    const modifiedChangedFields = modifier(conceptClone.changedFields);

    if (modifiedChangedFields !== conceptClone.changedFields) {
      if (!modifiedChangedFields.size && !conceptClone.listItems.size) {
        allChangedConceptsClone.delete(programmingConceptId);
      } else {
        conceptClone.changedFields = modifiedChangedFields;
        allChangedConceptsClone.set(programmingConceptId, conceptClone);
      }
      return allChangedConceptsClone;
    } else {
      return allChangedConcepts;
    }
  }
};

export const addToChangedProgrammingConceptsIfChanged =
  ({
    id,
    value,
    originalValue,
    programmingConceptId,
    listItemId,
  }: {
    id: string;
    value: FieldValue;
    originalValue: FieldValue;
    programmingConceptId: string;
    listItemId?: string | null | undefined;
  }) =>
  (allChangedConcepts: ChangedProgrammingConcepts) =>
    modifyChangedFields({
      programmingConceptId,
      listItemId,
      allChangedConcepts,
      modifier: (changedFields) => {
        if (!changedFields.has(id)) {
          const clone = new ChangedFields(changedFields);
          clone.set(id, originalValue);
          return clone;
        } else if (changedFields.get(id) === value) {
          const clone = new ChangedFields(changedFields);
          clone.delete(id);
          return clone;
        } else {
          return changedFields;
        }
      },
    });

export const removeProgrammingConceptFromChangedProgrammingConcepts =
  (programmingConceptId: string) =>
  (allChangedProgrammingConcepts: ChangedProgrammingConcepts) => {
    if (allChangedProgrammingConcepts.has(programmingConceptId)) {
      const clone = new ChangedProgrammingConcepts(
        allChangedProgrammingConcepts
      );
      clone.delete(programmingConceptId);
      return clone;
    } else {
      return allChangedProgrammingConcepts;
    }
  };

export const removeProgrammingConceptsFromChangedProgrammingConcepts =
  (programmingConceptIds: string[]) =>
  (allChangedProgrammingConcepts: ChangedProgrammingConcepts) => {
    const clone = new ChangedProgrammingConcepts(allChangedProgrammingConcepts);
    programmingConceptIds.forEach((conceptId) => {
      if (clone.has(conceptId)) {
        clone.delete(conceptId);
      }
    });
    return clone;
  };

export const ChangedProgrammingConceptsContextProvider = React.memo(
  (props: { children: React.ReactNode }) => {
    const [changedConcepts, setChangedConcepts] =
      React.useState<ChangedProgrammingConcepts>(
        new ChangedProgrammingConcepts()
      );

    return (
      <ChangedProgrammingConceptsContext.Provider value={changedConcepts}>
        <SetChangedProgrammingConceptsContext.Provider
          value={setChangedConcepts}
        >
          {props.children}
        </SetChangedProgrammingConceptsContext.Provider>
      </ChangedProgrammingConceptsContext.Provider>
    );
  }
);

export function ChangedProgrammingConceptContextProvider(props: {
  children: React.ReactNode;
}) {
  const programmingConceptId = useProgrammingConceptIdContext();
  const changedProgrammingConcepts = useChangedProgrammingConcepts();
  const changedProgrammingConcept =
    changedProgrammingConcepts.get(programmingConceptId);

  return (
    <ChangedProgrammingConceptContext.Provider
      value={React.useMemo(
        () =>
          changedProgrammingConcept ?? {
            changedFields: new ChangedFields(),
            listItems: new Map<string, ChangedFields>(),
          },
        [changedProgrammingConcept]
      )}
    >
      {React.useMemo(() => props.children, [props.children])}
    </ChangedProgrammingConceptContext.Provider>
  );
}
