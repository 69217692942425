App.factory("SensorResetAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/reset_sensors",
      {},
      {
        resetSensor: { method: "POST", params: { panel_id: "@panel_id" } },
      }
    );
  },
]);
