import Icon from "components/Icon";
import * as React from "react";
import { joinSpaced } from "utils/string";
import Modal from ".";
import styles from "./ConfirmModal.module.css";

/*
 * These are generalized components for confirmations, the goal is to keep them intentionally constrained
 * so it is easy to use them. If alterations
 */

type Props = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  confirmText?: React.ReactNode;
  cancelText?: React.ReactNode;
  actionPending?: boolean;
  pendingText?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  suppressCancel?: boolean;
  suppressConfirm?: boolean;
  icon?: React.ReactNode;
  includeHorizontalRule?: boolean;
  hrStyle?: React.CSSProperties;
};

export function DeleteConfirmModalBody({
  header = "Delete Confirmation",
  children = "Are you sure you would like to delete this item?",
  confirmText = "Yes",
  cancelText = "No",
  actionPending = false,
  pendingText = "Deleting...",
  onConfirm = () => {},
  onCancel = () => {},
  suppressCancel = false,
  suppressConfirm = false,
}: Props) {
  return (
    <>
      <div
        className={joinSpaced(
          styles["delete-header"],
          styles["confirm-header"]
        )}
      >
        <i className="icon-radial_info">&nbsp;</i>
        <span>{header}</span>
      </div>

      <div className={styles["confirm-body"]}>{children}</div>
      <div className={styles["confirm-footer"]}>
        {!suppressConfirm && (
          <button
            type="button"
            className={`btn btn-sm btn-alert ${styles["confirm-button"]}`}
            onClick={onConfirm}
            disabled={actionPending}
          >
            {actionPending ? pendingText : confirmText}
          </button>
        )}
        {!suppressCancel && (
          <button
            type="button"
            className={`btn btn-sm btn-dmp ${styles["confirm-button"]}`}
            onClick={onCancel}
            disabled={actionPending}
          >
            {cancelText}
          </button>
        )}
      </div>
    </>
  );
}

export function DeleteConfirmModalOverlay({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className={styles["confirm-overlay"]}>
      <div className={styles["confirm-overlay__container--delete"]}>
        <div className={styles["confirm-overlay__content"]}>{children}</div>
      </div>
    </div>
  );
}

export function DeleteConfirmModal(props: Props) {
  return (
    <Modal>
      <Modal.Body>
        <DeleteConfirmModalBody {...props} />
      </Modal.Body>
    </Modal>
  );
}

export function GenericConfirmModalBody({
  header = "Action Confirmation",
  children = "Are you sure you would like to complete this action?",
  confirmText = "Yes",
  cancelText = "No",
  actionPending = false,
  pendingText = "Working...",
  onConfirm = () => {},
  onCancel = () => {},
  suppressCancel = false,
  suppressConfirm = false,
  icon = <Icon name="radial_info" />,
  includeHorizontalRule = false,
  hrStyle = { background: "light-grey", height: "1px" },
}: Props) {
  return (
    <>
      <div className={joinSpaced(styles["confirm-header"])}>
        <span>{icon}&nbsp;</span>
        <span>{header}</span>
      </div>
      {includeHorizontalRule && <hr style={hrStyle} />}
      <div className={styles["confirm-body"]}>{children}</div>
      <div className={styles["confirm-footer"]}>
        {!suppressConfirm && (
          <button
            type="button"
            className={`btn btn-sm btn-dmp ${styles["confirm-button"]}`}
            onClick={onConfirm}
            disabled={actionPending}
          >
            {actionPending ? pendingText : confirmText}
          </button>
        )}
        {!suppressCancel && (
          <button
            type="button"
            className={`btn btn-sm btn-default ${styles["confirm-button"]}`}
            onClick={onCancel}
            disabled={actionPending}
          >
            {cancelText}
          </button>
        )}
      </div>
    </>
  );
}

export function ConfirmModalOverlay({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className={styles["confirm-overlay"]}>
      <div className={styles["confirm-overlay__container"]}>
        <div className={styles["confirm-overlay__content"]}>{children}</div>
      </div>
    </div>
  );
}

export function GenericConfirmModal(props: Props) {
  return (
    <Modal>
      <Modal.Body>
        <GenericConfirmModalBody {...props} />
      </Modal.Body>
    </Modal>
  );
}
