import DaColors from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import { Flex } from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import Modal from "components/Modal";
import React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { PanelHardwareModel } from "securecom-graphql/client";
import styled from "styled-components/macro";
import { ControlSystem, Customer } from ".";
import HolidaySystemSelectorLineItem from "./HolidaySystemSelectorLineItem";
import { HolidaySystemSelectorModalFragment_systemData$key } from "./__generated__/HolidaySystemSelectorModalFragment_systemData.graphql";
import { HolidaySystemSelectorModalQuery } from "./__generated__/HolidaySystemSelectorModalQuery.graphql";
interface HolidaySystemSelectorModalProps {
  systemListRef: HolidaySystemSelectorModalFragment_systemData$key;
  onUpdateSystemSelection: (
    system: ControlSystem,
    customer: Customer,
    isChecked: boolean
  ) => void;
  selectedSystemsList: ControlSystem[];
  onCloseModal: () => void;
  onCancelModal: () => void;
  holidayId: string;
  setSelectedSystems: React.Dispatch<React.SetStateAction<ControlSystem[]>>;
  revealModal: boolean;
}

const HolidaySystemSelectorModal: React.FC<HolidaySystemSelectorModalProps> = ({
  systemListRef,
  onUpdateSystemSelection,
  selectedSystemsList,
  onCloseModal,
  onCancelModal,
  holidayId,
  setSelectedSystems,
  revealModal,
}) => {
  // create a graphql fragment  to get the system data from the systemData Query on ./index.tsx
  const data = useFragment(
    graphql`
      fragment HolidaySystemSelectorModalFragment_systemData on MassProgramPanelQuickDetailedResponse {
        ... on MassProgramPanelQuickDetailedSuccessResponse {
          __typename
          customers {
            customerName
            customerId
            controlSystemName
            controlSystemId
            panelHardwareModel
          }
        }
        ... on MassProgramPanelQuickDetailedErrorResponse {
          __typename
          errorType
          errorMessage
        }
      }
    `,
    systemListRef
  );

  const holiday = useLazyLoadQuery<HolidaySystemSelectorModalQuery>(
    graphql`
      query HolidaySystemSelectorModalQuery($holidayId: ID!) {
        globalHolidayDateQuery(id: $holidayId) {
          panelAssociation
        }
      }
    `,
    { holidayId: holidayId },
    { fetchPolicy: "network-only" }
  );
  const currentPanelAssociationsSet = new Set(
    holiday.globalHolidayDateQuery?.panelAssociation
  );

  //  filter the customers to only include those with panelHardwareModel that isXr
  const rawCustomersArray =
    data?.__typename === "MassProgramPanelQuickDetailedSuccessResponse"
      ? data.customers.filter(
          (customer) =>
            customer &&
            customer.panelHardwareModel &&
            resolvePanelType(customer?.panelHardwareModel as PanelHardwareModel)
              .isXr
        ) // Ensure customer and panelHardwareModel are not null and is xr
      : [];

  const [paginationState, dispatch] = usePaginationState({
    pageSize: 30,
    currentPage: 1,
    search: "",
  });

  const globalHolidaySystemsMap = new Map();

  // Create a map of unique customers with their control systems
  const uniqueCustomers = rawCustomersArray.reduce((acc, customer) => {
    if (!customer) return acc;

    globalHolidaySystemsMap.set(customer.controlSystemId, {
      controlSystemId: customer.controlSystemId,
      controlSystemName: customer.controlSystemName,
      panelHardwareModel: customer.panelHardwareModel,
      customerName: customer.customerName,
      customerId: customer.customerId,
    });

    if (acc.has(customer.customerId)) {
      const existingCustomer = acc.get(customer.customerId);
      existingCustomer.controlSystems.push({
        controlSystemId: customer.controlSystemId,
        controlSystemName: customer.controlSystemName,
        panelHardwareModel: customer.panelHardwareModel,
      });
    } else {
      acc.set(customer.customerId, {
        customerId: customer.customerId,
        customerName: customer.customerName,
        controlSystems: [
          {
            controlSystemId: customer.controlSystemId,
            controlSystemName: customer.controlSystemName,
            panelHardwareModel: customer.panelHardwareModel,
          },
        ],
      });
    }
    return acc;
  }, new Map());

  const uniqueCustomersArray = React.useMemo(() => {
    return Array.from(uniqueCustomers.values());
  }, [uniqueCustomers]);

  const searchedCustomers = React.useMemo(() => {
    const searchLower = paginationState.search.toLowerCase();
    return uniqueCustomersArray.filter((customer) =>
      customer.customerName.toLowerCase().includes(searchLower)
    );
  }, [uniqueCustomersArray, paginationState.search]);

  const sortedCustomers = React.useMemo(() => {
    return [...searchedCustomers].sort((a, b) =>
      a.customerName.localeCompare(b.customerName)
    );
  }, [searchedCustomers]);

  const pagedCustomers = React.useMemo(() => {
    const startIndex =
      (paginationState.currentPage - 1) * paginationState.pageSize;
    const endIndex = startIndex + paginationState.pageSize;
    return sortedCustomers.slice(startIndex, endIndex);
  }, [sortedCustomers, paginationState.currentPage, paginationState.pageSize]);

  const maxPage = Math.ceil(sortedCustomers.length / paginationState.pageSize);

  const isSystemSelected = (panelId: string) =>
    selectedSystemsList.some((system) => system.controlSystemId === panelId);

  const originalSelectedSystemsList = React.useRef(selectedSystemsList);

  React.useEffect(() => {
    const newSelectedList: ControlSystem[] = [];
    currentPanelAssociationsSet.forEach((panelId) => {
      if (globalHolidaySystemsMap.has(String(panelId))) {
        newSelectedList.push(globalHolidaySystemsMap.get(String(panelId)));
      }
    });
    newSelectedList.forEach((system) => {
      const customer = uniqueCustomers.get(system.customerId) ?? {
        customerName: "",
        controlSystems: [],
      };
      onUpdateSystemSelection(system, customer, true);
    });
    if (originalSelectedSystemsList.current.length === 0) {
      originalSelectedSystemsList.current = newSelectedList;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return revealModal ? (
    <Modal className="pad-16" size="medium" backdrop="static" animate={true}>
      <Flex.Row justify="space-between" align={"center"}>
        <ModalTitle>Select Systems</ModalTitle>
      </Flex.Row>

      <Flex.Row justify="space-between" align="center" className="pad-16">
        <input
          id="search-input"
          type="text"
          className="form-control input-sm max-w-300 mar-r-8 mb-sm-mobile"
          value={paginationState.search}
          onChange={(e) => {
            const search = e.target.value;
            dispatch({
              type: ActionType.SetSearch,
              search,
            });
          }}
          placeholder="Search..."
        />
      </Flex.Row>

      <ListBody>
        <ul className="pad-l-0">
          {pagedCustomers.map((customer) => {
            return (
              <HolidaySystemSelectorLineItem
                key={customer.customerId}
                customer={customer}
                onUpdateSystemList={onUpdateSystemSelection}
                isSelected={isSystemSelected}
                currentHolidayPanelIds={currentPanelAssociationsSet}
              />
            );
          })}
        </ul>
      </ListBody>

      <Modal.Footer>
        {
          //only show pagination if there are more than 1 page
          maxPage > 1 && (
            <Flex.Row justify="center" align="center">
              <Paginate.Paginator
                state={paginationState}
                dispatch={dispatch}
                maxPage={maxPage}
              />
            </Flex.Row>
          )
        }
        <button
          className="btn btn-default btn-sm mar-r-8"
          onClick={() => {
            onCancelModal();
            setSelectedSystems(originalSelectedSystemsList.current);
          }}
        >
          Cancel
        </button>
        <button className="btn btn-primary btn-sm" onClick={onCloseModal}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

const ModalTitle = styled.h3`
  font-size: 2rem;
  font-weight: var(--font-weight-regular);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${DaColors.Neutral2000};
  line-height: 1.5;
  margin: 0;
  padding: 1.6rem 1.6rem 0;
`;

const ListBody = styled.div`
  overflow-y: auto;
  max-height: 35vh;
`;

export default HolidaySystemSelectorModal;
