/**
 * @generated SignedSource<<8bc8eef4e63d7f3a961d0a6f2b37eb32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeypadArmedLed = "ALL" | "ANY";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsKeypadArmedLedField_systemOptions$data = {
  readonly keypadArmedLed?: KeypadArmedLed;
  readonly systemType?: SystemType;
  readonly " $fragmentType": "SystemOptionsKeypadArmedLedField_systemOptions";
};
export type SystemOptionsKeypadArmedLedField_systemOptions$key = {
  readonly " $data"?: SystemOptionsKeypadArmedLedField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsKeypadArmedLedField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "systemType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "keypadArmedLed",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsKeypadArmedLedField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "71a2f743302889f180256e5b6bc03310";

export default node;
