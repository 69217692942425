import * as React from "react";
import {
  ArmedSolidShieldLargeIcon,
  BurglaryIcon,
  HomeLargeIcon,
  AwayLargeIcon,
} from "../Icons";
import Wrapper from "./BasicArmingWrapper";
import Areas from "./BasicArmingAreas";
import Area from "./BasicArmingArea";
import AreaButton from "./BasicArmingAreaButton";
import { AreaButtonIcon } from "./AreaButtonIcon";
import AreaButtonContainer from "./BasicArmingAreaButtonContainer";
import AreaButtonLabel from "./BasicArmingAreaButtonLabel";
import ShieldWrap from "./BasicArmingShieldWrap";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";
import { HomeAwayArming_controlSystem$key } from "./__generated__/HomeAwayArming_controlSystem.graphql";
import { BadZonesOption, ArmedStatusEnum } from "securecom-graphql/client";
import { seconds } from "../../../utils/dates";
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";
import { HomeAwayArmingArmHomeMutation } from "./__generated__/HomeAwayArmingArmHomeMutation.graphql";
import { HomeAwayArmingArmAwayMutation } from "./__generated__/HomeAwayArmingArmAwayMutation.graphql";
import { HomeAwayArmingDisarmMutation } from "./__generated__/HomeAwayArmingDisarmMutation.graphql";

enum ArmingType {
  HOME,
  AWAY,
  NONE,
}

const HomeAwayArming = (props: {
  controlSystem: HomeAwayArming_controlSystem$key;
  setStatusMessage: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBadZonesMethod: Function;
}) => {
  const system = useFragment(
    graphql`
      fragment HomeAwayArming_controlSystem on ControlSystem {
        id
        name
        isArmed
        isInAlarm
        isECP
        isDSC
        isSingleAreaSystem
        isHSASystem
        isHASystem
        isAreaSystem
        armedStatus
        areas(sort: { keys: ["number"], order: ASC }) {
          nodes {
            id
            name
            armedStatus
            isInAlarm
            number
          }
        }
      }
    `,
    props.controlSystem
  );

  const shouldShowStay = system.isECP || system.isDSC;

  const [
    disarmAllAreas,
    isDisarmingAllAreas,
  ] = useMutation<HomeAwayArmingDisarmMutation>(graphql`
    mutation HomeAwayArmingDisarmMutation($systemId: ID!) {
      disarmAllAreas(systemId: $systemId) {
        ... on MultipleAreasDisarmedSuccessPayload {
          controlSystem {
            armedStatus
            ...HomeAwayArming_controlSystem
          }
        }
        ... on Error {
          type
        }
      }
    }
  `);

  const [
    armHome,
    isArmingHome,
  ] = useMutation<HomeAwayArmingArmHomeMutation>(graphql`
    mutation HomeAwayArmingArmHomeMutation(
      $systemId: ID!
      $armInstant: Boolean
      $badZonesOption: BadZonesOption
    ) {
      armHome(
        systemId: $systemId
        armInstant: $armInstant
        badZonesOption: $badZonesOption
      ) {
        ... on MultipleAreasArmedSuccessPayload {
          controlSystem {
            armedStatus
            ...HomeAwayArming_controlSystem
          }
        }
        ... on BadZonesError {
          badZones {
            name
            number
          }
        }
        ... on Error {
          type
        }
      }
    }
  `);

  const [
    armAway,
    isArmingAway,
  ] = useMutation<HomeAwayArmingArmAwayMutation>(graphql`
    mutation HomeAwayArmingArmAwayMutation(
      $systemId: ID!
      $badZonesOption: BadZonesOption
    ) {
      armAway(systemId: $systemId, badZonesOption: $badZonesOption) {
        ... on MultipleAreasArmedSuccessPayload {
          controlSystem {
            armedStatus
            ...HomeAwayArming_controlSystem
          }
        }
        ... on BadZonesError {
          badZones {
            name
            number
          }
        }
        ... on Error {
          type
        }
      }
    }
  `);

  const intl = useIntl();
  const armedStatusMessage = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.armed).toLowerCase(),
    },
  };
  const disarmedStatusMessage = {
    ...messages.statusMessage,
    values: {
      status: intl.formatMessage(messages.disarmed).toLowerCase(),
    },
  };
  const disarmMessage = {
    ...messages.clickInstructions,
    values: {
      instruction: intl.formatMessage(messages.disarm).toLowerCase(),
    },
  };
  const armingHomeInstantStatusMessage = {
    ...messages.armingAreaInstant,
    values: {
      area: shouldShowStay
        ? intl.formatMessage(messages.stay).toLowerCase()
        : intl.formatMessage(messages.home).toLowerCase(),
    },
  };
  const armingHomeStatusMessage = {
    ...messages.armingArea,
    values: {
      area: shouldShowStay
        ? intl.formatMessage(messages.stay).toLowerCase()
        : intl.formatMessage(messages.home).toLowerCase(),
    },
  };
  const armingAwayStatusMessage = {
    ...messages.armingArea,
    values: {
      area: intl.formatMessage(messages.away).toLowerCase(),
    },
  };
  let armHomeInstantlyTimout: undefined | number = undefined;

  const armHomeInstantly = (badZonesOption = BadZonesOption.REFUSE) => {
    props.setIsLoading(true);
    props.setStatusMessage(armingHomeInstantStatusMessage);
    armHome({
      variables: {
        systemId: system.id,
        armInstant: true,
        badZonesOption,
      },
      onCompleted: (response) => {
        if (
          response.armHome?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_AWAY ||
          response.armHome?.controlSystem?.armedStatus ===
            ArmedStatusEnum.ARMED_HOME
        ) {
          props.setStatusMessage(armedStatusMessage);
        } else if (response.armHome?.badZones) {
          props.setBadZonesMethod({
            badZones: response.armHome.badZones,
            executeMutation: (badZonesOptionRetry: BadZonesOption) => {
              armHomeInstantly(badZonesOptionRetry);
            },
          });
        } else {
          props.setStatusMessage(disarmedStatusMessage);
        }
        props.setIsLoading(false);
      },
      onError: () => {
        props.setStatusMessage(disarmedStatusMessage);
        props.setIsLoading(false);
      },
    });
  };

  const handleAreaClick = (
    armingType: ArmingType,
    badZonesOption = BadZonesOption.REFUSE
  ) => {
    clearTimeout(armHomeInstantlyTimout);
    if (!system.isArmed && armingType === ArmingType.HOME) {
      props.setStatusMessage(armingHomeStatusMessage);
      props.setIsLoading(true);
      armHome({
        variables: {
          systemId: system.id,
          badZonesOption,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          systemRecord.setValue(ArmedStatusEnum.ARMING_HOME, "armedStatus");
        },
        onCompleted: (response) => {
          if (
            response.armHome?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_AWAY ||
            response.armHome?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_HOME
          ) {
            props.setStatusMessage(armedStatusMessage);
          } else if (response.armHome?.badZones) {
            props.setBadZonesMethod({
              badZones: response.armHome.badZones,
              executeMutation: (badZonesOptionRetry: BadZonesOption) => {
                handleAreaClick(ArmingType.HOME, badZonesOptionRetry);
              },
            });
          } else {
            props.setStatusMessage(disarmedStatusMessage);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(disarmedStatusMessage);
          props.setIsLoading(false);
        },
      });
    } else if (!system.isArmed && armingType === ArmingType.AWAY) {
      props.setIsLoading(true);
      props.setStatusMessage(armingAwayStatusMessage);
      armAway({
        variables: {
          systemId: system.id,
          badZonesOption,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          systemRecord.setValue(ArmedStatusEnum.ARMING_AWAY, "armedStatus");
        },
        onCompleted: (response) => {
          if (
            response.armAway?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_AWAY ||
            response.armAway?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_HOME
          ) {
            props.setStatusMessage(armedStatusMessage);
          } else if (response.armAway?.badZones) {
            props.setBadZonesMethod({
              badZones: response.armAway.badZones,
              executeMutation: (badZonesOptionRetry: BadZonesOption) => {
                handleAreaClick(ArmingType.AWAY, badZonesOptionRetry);
              },
            });
          } else {
            props.setStatusMessage(disarmedStatusMessage);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(disarmedStatusMessage);
          props.setIsLoading(false);
        },
      });
    } else {
      props.setIsLoading(true);
      props.setStatusMessage(messages.disarming);
      disarmAllAreas({
        variables: {
          systemId: system.id,
        },
        optimisticUpdater: (store) => {
          const systemRecord = store.get(system.id);
          const currentStatus = systemRecord.getValue("armedStatus");
          if (currentStatus === ArmedStatusEnum.ARMED_HOME) {
            systemRecord.setValue(
              ArmedStatusEnum.DISARMING_HOME,
              "armedStatus"
            );
          } else if (currentStatus === ArmedStatusEnum.ARMED_AWAY) {
            systemRecord.setValue(
              ArmedStatusEnum.DISARMING_AWAY,
              "armedStatus"
            );
          } else {
            systemRecord.setValue(ArmedStatusEnum.DISARMING, "armedStatus");
          }
        },
        onCompleted: (response) => {
          if (
            response.disarmAllAreas?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_AWAY ||
            response.disarmAllAreas?.controlSystem?.armedStatus ===
              ArmedStatusEnum.ARMED_HOME
          ) {
            props.setStatusMessage(armedStatusMessage);
          } else {
            props.setStatusMessage(disarmedStatusMessage);
          }
          props.setIsLoading(false);
        },
        onError: () => {
          props.setStatusMessage(armedStatusMessage);
          props.setIsLoading(false);
        },
      });
    }
  };

  const handleArmHomeMouseEnter = () => {
    if (system.isArmed) {
      props.setStatusMessage(disarmMessage);
    } else {
      props.setStatusMessage({
        ...messages.clickInstructions,
        values: {
          instruction: `arm. ${intl.formatMessage(messages.holdForInstant)}`,
        },
      });
    }
  };

  const handleArmAwayMouseEnter = () => {
    if (system.isArmed) {
      props.setStatusMessage(disarmMessage);
    } else {
      props.setStatusMessage({
        ...messages.clickInstructions,
        values: {
          instruction: intl.formatMessage(messages.arm).toLowerCase(),
        },
      });
    }
  };

  const handleAreaMouseLeave = () => {
    if (isDisarmingAllAreas || isArmingHome || isArmingAway) return;
    if (system.isArmed) {
      props.setStatusMessage(armedStatusMessage);
    } else if (!system.isArmed) {
      props.setStatusMessage(disarmedStatusMessage);
    }
  };

  const armHomeIsActive =
    system.armedStatus === ArmedStatusEnum.ARMED_HOME ||
    system.armedStatus === ArmedStatusEnum.ARMING_HOME ||
    system.armedStatus === ArmedStatusEnum.DISARMING_HOME ||
    isArmingHome;
  const armAwayIsActive =
    system.armedStatus === ArmedStatusEnum.ARMED_AWAY ||
    system.armedStatus === ArmedStatusEnum.ARMING_AWAY ||
    system.armedStatus === ArmedStatusEnum.DISARMING_AWAY ||
    isArmingAway;

  return (
    <Wrapper>
      <Areas>
        <Area
          key={1}
          index={0}
          count={2}
          active={armHomeIsActive}
          inactive={armAwayIsActive}
        >
          <AreaButton
            arming={isArmingHome}
            disarming={isDisarmingAllAreas}
            armed={system.armedStatus === ArmedStatusEnum.ARMED_HOME}
            onClick={() => handleAreaClick(ArmingType.HOME)}
            disabled={isArmingHome || isArmingAway || isDisarmingAllAreas}
            onMouseDown={() => {
              if (system.isArmed) return;

              if (armHomeInstantlyTimout !== undefined) {
                clearTimeout(armHomeInstantlyTimout);
              }

              armHomeInstantlyTimout = setTimeout(armHomeInstantly, seconds(1));
            }}
            onMouseEnter={handleArmHomeMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <AreaButtonIcon
                armed={system.armedStatus === ArmedStatusEnum.ARMED_HOME}
                burgAlarm={system.isInAlarm}
              >
                {system.isInAlarm ? <BurglaryIcon /> : <HomeLargeIcon />}
              </AreaButtonIcon>
              {(system.armedStatus === ArmedStatusEnum.DISARMED ||
                system.armedStatus === ArmedStatusEnum.ARMING_HOME) && (
                <AreaButtonLabel armed={false}>
                  <FormattedMessage
                    {...(shouldShowStay ? messages.stay : messages.home)}
                  />
                </AreaButtonLabel>
              )}
              <ShieldWrap>
                {(system.armedStatus === ArmedStatusEnum.DISARMING_HOME ||
                  system.armedStatus === ArmedStatusEnum.ARMED_HOME) && (
                  <ArmedSolidShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
        <Area
          key={2}
          index={1}
          count={2}
          active={armAwayIsActive}
          inactive={armHomeIsActive}
        >
          <AreaButton
            arming={isArmingAway}
            disarming={isDisarmingAllAreas}
            armed={system.armedStatus === ArmedStatusEnum.ARMED_AWAY}
            onClick={() => handleAreaClick(ArmingType.AWAY)}
            disabled={isArmingAway || isArmingHome || isDisarmingAllAreas}
            onMouseEnter={handleArmAwayMouseEnter}
            onMouseLeave={handleAreaMouseLeave}
            burgAlarm={system.isInAlarm}
          >
            <AreaButtonContainer>
              <AreaButtonIcon
                armed={system.armedStatus === ArmedStatusEnum.ARMED_AWAY}
                burgAlarm={system.isInAlarm}
              >
                {system.isInAlarm ? <BurglaryIcon /> : <AwayLargeIcon />}
              </AreaButtonIcon>
              {(system.armedStatus === ArmedStatusEnum.ARMING_AWAY ||
                system.armedStatus === ArmedStatusEnum.DISARMED) && (
                <AreaButtonLabel armed={false}>
                  {" "}
                  <FormattedMessage {...messages.away} />
                </AreaButtonLabel>
              )}
              <ShieldWrap>
                {(system.armedStatus === ArmedStatusEnum.ARMED_AWAY ||
                  system.armedStatus === ArmedStatusEnum.DISARMING_AWAY) && (
                  <ArmedSolidShieldLargeIcon valign="top" />
                )}
              </ShieldWrap>
            </AreaButtonContainer>
          </AreaButton>
        </Area>
      </Areas>
    </Wrapper>
  );
};

export default HomeAwayArming;
