import React from "react";

interface WrapperProps {
  children: React.ReactNode;
  title?: string;
}

function DiagnosticPanelWrapper(props: WrapperProps) {
  const { title, children } = props;
  return (
    <div className="panel pad-0">
      <div className="panel-heading">{title}</div>
      <div className="panel-wrapper">
        <div className="panel-body pt0">{children}</div>
      </div>
    </div>
  );
}

export default DiagnosticPanelWrapper;
