import { StreamableCamera } from "dealer-admin/src/apis/camect-api/streamable-camera";
import React, { ForwardedRef, forwardRef } from "react";
import CamectPlayer from "./CamectPlayer";
import IensoPlayer from "./IensoPlayer";
import SecurecomPlayer from "./SecurecomPlayer";

type Props = {
  camera: StreamableCamera;
  width: number | undefined;
  height: number;
};

const CleanVideoPlayer = forwardRef(function CleanVideoPlayer(
  { camera, width, height }: Props,
  ref: ForwardedRef<HTMLVideoElement>
) {
  switch (camera.cameraType) {
    case "legacy":
    case "v-6000":
      return (
        <SecurecomPlayer
          ref={ref}
          deviceId={camera.cameraId}
          width={width}
          height={height}
        />
      );
    case "ienso":
      return (
        <IensoPlayer
          ref={ref}
          deviceId={camera.cameraId}
          width={width}
          height={height}
        />
      );
    case "camect":
      return (
        <CamectPlayer
          ref={ref}
          key={width}
          hubId={camera.hubId}
          camId={camera.cameraId}
          authToken={camera.authToken}
          env={camera.env}
          width={width}
          height={height}
        />
      );
  }
});

export default CleanVideoPlayer;
