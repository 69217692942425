import DaColors from "app/common/DaColors";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.label<{ isInvalid?: boolean }>`
  color: ${(props) => (props.isInvalid ? DaColors.Failure500 : "inherit")};
`;
const Input = styled.input<{ isInvalid?: boolean }>`
  height: 3.5rem;
  font-size: 1.4rem;
  padding: 0.6rem 1.6rem;
  border: 0.1rem solid
    ${(props) => (props.isInvalid ? DaColors.Failure500 : DaColors.Primary200)};
  border-radius: 0.4rem;

  &:focus {
    border-color: ${(props) =>
      props.isInvalid ? DaColors.Failure500 : DaColors.Info500};
  }

  &:disabled {
    background-color: #edf1f2;
  }
`;
const ErrorMessage = styled.div`
  color: ${DaColors.Failure500};
`;

type Props = {
  errorMessage?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const CleanLabeledInput = ({ errorMessage, ...htmlProps }: Props) => {
  return (
    <Wrapper>
      <Label htmlFor={htmlProps.id} isInvalid={!!errorMessage}>
        {htmlProps.name}
      </Label>
      <Input {...htmlProps} isInvalid={!!errorMessage} />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default CleanLabeledInput;
