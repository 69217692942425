App.controller("NestedStateViewCtrl", [
  "$scope",
  "$rootScope",
  "$stateParams",
  "UserService",
  "$state",
  "SensorResetAPI",
  "PanelProgArchiveService",
  "RecentActivityService",
  "Customer",
  function (
    $scope,
    $rootScope,
    $stateParams,
    UserService,
    $state,
    SensorResetAPI,
    PanelProgArchiveService,
    RecentActivityService,
    Customer
  ) {
    $scope.customerId = UserService.customer_id;
    if ($stateParams.customer_id)
      UserService.customer_id = $stateParams.customer_id;
    $scope.controlSystemId = $stateParams.control_system_id;
    $scope.controlSystemName = UserService.controlSystem.name;
    $scope.customer_id = UserService.customer_id;
    $scope.dealer_id = UserService.dealer_id;

    $scope.navBack = function () {
      $state.go("app.customers.customersummary", {
        customer_id: UserService.customer_id,
        dealer_id: UserService.dealerInfo.id,
      });
    };

    async function addToRecentSystems() {
      const { customer } = await new Customer($scope.customer_id).get(
        $scope.customer_id
      );
      var recentSystem = {
        isSite: false,
        id: $scope.controlSystemId,
        customer_id: $scope.customerId,
        customer_name: customer.name,
        name: $scope.controlSystemName,
      };
      RecentActivityService.updateList(
        RecentActivityService.props.storageKeys.system,
        recentSystem
      );
    }

    $scope.sensorResetButton = function () {
      SensorResetAPI.resetSensor(
        {
          panel_id: UserService.panel_id,
        },
        function () {
          $rootScope.alerts.push({
            type: "success",
            text: "Sensor Reset Sent",
          });
        },
        function () {
          $rootScope.alerts.push({
            type: "error",
            text: "Error Sending Sensor Reset",
            json: error,
          });
        }
      );
    };

    function init() {
      PanelProgArchiveService.cache.backup.clear(false);
      addToRecentSystems();
    }

    init();
  },
]);
