App.directive("daCustomRoles", [
  "CustomRolesService",
  "UserService",
  function (CustomRolesService, UserService) {
    return {
      restrict: "E",

      scope: {
        role: "=",
        type: "=",
        disableFields: "=",
        showLabel: "=",
        tags: "=",
      },
      templateUrl: function (element, scope) {
        switch (scope.type) {
          case "technician":
            return "/app/personnel/custom-roles/da-technician-custom-roles-tpl.html";
          case "custom":
            return "/app/personnel/custom-roles/da-custom-roles-tpl.html";
          default:
            return "";
        }
      },
      link: function (scope, element, attrs, formCtrl) {
        scope.UserService = UserService;
        scope.ipWhitelistEnabled = UserService.enabledIpWhitelist();
        let pristineRole = {};

        scope.$watch("role.id", function (newValue) {
          if (angular.isDefined(newValue)) {
            pristineRole = angular.copy(scope.role);
            scope.validateDaySelection();
            if (scope.disableFields) {
              scope.displayStartTime = undoTimeZoneOffset(
                scope.role.start_time
              );
              scope.displayEndTime = undoTimeZoneOffset(scope.role.end_time);
            }
          }
        });

        scope.$watch("role.limit_entity", function (newValue) {
          scope.processLimitEntity(newValue);
        });

        scope.processLimitEntity = function (limitEntity) {
          const disableValues = limitEntity;
          scope.role.user_auth_permissions.move_systems = !disableValues;
          scope.role.user_auth_permissions.bulk_update_view = !disableValues;
          scope.role.user_auth_permissions.remote_update_dashboard_view = !disableValues;
          scope.role.user_auth_permissions.reporting_analytics_view = !disableValues;
          scope.role.user_auth_permissions.mass_programming_view = !disableValues;
          scope.role.manage_tags = !disableValues;
          scope.role.GUIPermissions.personnel = disableValues
            ? "Hidden"
            : "View, Edit & Delete";
          scope.disableFields = disableValues;
          scope.personnelHidden = disableValues;
          scope.fieldsDisabled = disableValues;
        };

        scope.updateCachedTime = function () {
          pristineRole.start_time = scope.role.start_time;
          pristineRole.end_time = scope.role.end_time;
        };

        scope.processAllDayCheck = function () {
          updateTime("start_time", "DAY_START_TIME");
          updateTime("end_time", "DAY_END_TIME");
        };

        scope.processEveryDayCheck = function () {
          [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ].forEach((day) => {
            scope.role[day] = scope.role.every_day;
          });
          scope.validateDaySelection();
        };

        function setEveryDayFlag() {
          scope.role.every_day = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ].every((day) => scope.role[day]);
        }

        scope.validateDaySelection = function () {
          const form = formCtrl.customRoleForm?.dayBlockForm;
          if (form) {
            form.$setValidity("noSelection", daySelectionValid(scope.role));
          }
        };

        scope.dropDownOptionsTechApp =
          CustomRolesService.dropDownOptionsTechApp;
        scope.dropDownOptionsCustom = CustomRolesService.dropDownOptionsCustom;

        function updateTime(timeSlot, limit) {
          const guiLimit = CustomRolesService.getGUIDateString(
            CustomRolesService[limit]
          );
          scope.role[timeSlot] = scope.role.all_day
            ? guiLimit
            : pristineRole.all_day && pristineRole[timeSlot] === guiLimit
            ? ""
            : pristineRole[timeSlot];
        }

        function undoTimeZoneOffset(dateString) {
          const date = new Date(dateString);
          const timeZoneOffset = date.getTimezoneOffset() * 60000;
          return new Date(date.getTime() + timeZoneOffset);
        }

        function daySelectionValid(role) {
          return [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ].some((day) => role[day]);
        }

        function init() {
          setEveryDayFlag();
          scope.timeZones = [];
          CustomRolesService.getTimeZones(UserService.dealer_id)
            .then((data) => {
              scope.timeZones = data.map((zone) => zone.name);
            })
            .catch(() => {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to get time zones",
              });
            });
        }

        init();
      },
    };
  },
]);
