import graphql from "babel-plugin-relay/macro";
import TimeInput from "components/FullProgramming/common/TimeInput";
import * as React from "react";
import ProgrammingConceptForm from ".././ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationTestTimeField_communication$key } from "./__generated__/CommunicationTestTimeField_communication.graphql";

export const communicationTestTimeFieldId = () => "communication-test-time";

function CommunicationTestTimeField() {
  const [{ testTime }, updateCommunication] =
    useCommunicationFragment<CommunicationTestTimeField_communication$key>(
      graphql`
        fragment CommunicationTestTimeField_communication on Communication {
          testTime
        }
      `
    );

  const fieldId = communicationTestTimeFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Test Time"
      tooltip="Time of day the System tests its communication to the Receiver. Tests are performed on programmed Test Days."
    >
      <TimeInput
        id={fieldId}
        value={testTime}
        inlineHelp="10:00 PM"
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "testTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationTestTimeField;
