/**
 * @generated SignedSource<<c6e04e2cf68db47914613b16ad6966b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatSiteCodePositionField_cardFormat$data = {
  readonly number: string;
  readonly siteCodePosition: number;
  readonly type: number;
  readonly " $fragmentType": "CardFormatSiteCodePositionField_cardFormat";
};
export type CardFormatSiteCodePositionField_cardFormat$key = {
  readonly " $data"?: CardFormatSiteCodePositionField_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatSiteCodePositionField_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatSiteCodePositionField_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteCodePosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "dadc603860ea6b10bb12ce4dbfed041a";

export default node;
