import graphql from "babel-plugin-relay/macro";
import React, { useMemo } from "react";
import { useRelayEnvironment } from "react-relay";
import { SelectComponents } from "react-select/src/components";
import { FixedSizeList as List } from "react-window";
import { useUserList } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import SelectSearchable from "../SelectSearchable";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobUsernameField_keyfob$key } from "./__generated__/KeyfobUsernameField_keyfob.graphql";

const customSelectStyles = {
  control: (base: any) => ({
    ...base,
    maxHeight: 34,
    minHeight: 34,
    border: "1px solid #dde6e9",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 5,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 5,
  }),

  valueContainer: (base: any) => ({
    ...base,
    padding: "0px 6px",
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};
/* List for React Window - used to improve search speed in react-select */
const listHeight = 35;
const listWidth = "100%";

const UserMenuList: SelectComponents<any, false>["MenuList"] = (props) => {
  const { options, children, getValue } = props;
  const [value] = getValue();

  const items = React.Children.toArray(children);
  const initialOffset =
    items.length - 3 < options.indexOf(value)
      ? (items.length - 3) * listHeight
      : options.indexOf(value) * listHeight;

  return (
    <List
      width={listWidth}
      height={items.length * listHeight > 300 ? 300 : items.length * listHeight}
      itemCount={items.length}
      itemSize={listHeight}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{items[index]}</div>}
    </List>
  );
};

export const KEYFOB_USER_NAME = `keyfob-username-`;

export const keyfobUsernameFieldId = (number: string) =>
  KEYFOB_USER_NAME + number;

function KeyfobUsernameField() {
  const [{ id, number, user }] =
    useKeyfobFragment<KeyfobUsernameField_keyfob$key>(
      graphql`
        fragment KeyfobUsernameField_keyfob on Keyfob {
          id
          number
          user {
            id
            number
            name
          }
        }
      `
    );

  const fieldId = keyfobUsernameFieldId(String(number));
  const userList = useUserList();
  const relayEnv = useRelayEnvironment();

  type UserOptions = {
    value: string | undefined;
    label: string;
  };
  const userOptions = useMemo(
    () =>
      userList.userCodes.map((userCode) => ({
        value: userCode?.id,
        label: `${userCode?.name}`,
      })),
    [userList.userCodes]
  );
  const compare = (a: UserOptions, b: UserOptions) => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  };

  const orderedUserOptions = userOptions.sort(compare);

  const selectedOption = React.useMemo(() => {
    if (user) {
      return userOptions.find((option) => option.value === user.id) ?? "";
    } else {
      return "";
    }
  }, [userOptions, user]);

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Username">
      <SelectSearchable
        id={fieldId}
        components={{ MenuList: UserMenuList }}
        styles={customSelectStyles}
        options={orderedUserOptions}
        isSearchable
        backspaceRemovesValue
        value={selectedOption}
        onChange={(option: { value: string }) => {
          relayEnv.commitUpdate((store) => {
            const keyfobRecord = store.get(id);
            const userRecord = store.get(option.value);
            if (keyfobRecord && userRecord) {
              keyfobRecord.setLinkedRecord(userRecord, "user");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobUsernameField;
