import { Dispatch } from "react";

import { numberCharactersOnly } from "common/utils";

export enum TwoFactorCodeDeliveryMethods {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

const phoneRegex = new RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
);

export type ChangeTwoFactorFormState = {
  readonly email: string;
  readonly emailEnabled: boolean;
  readonly emailSecurityCode: string;
  readonly emailSecurityCodeError: string | null;
  readonly emailSecurityCodeInvalid: boolean;
  readonly emailSecurityCodeVisible: boolean;
  readonly emailSetupSuccessViewVisible: boolean;
  readonly confirmToDisable: boolean;
  readonly emailSecurityCodeReplyViewShown: boolean;
  readonly phoneError: string | null;
  readonly smsSecurityCodeError: string | null;
  readonly securityCodeDeliveryMethod: TwoFactorCodeDeliveryMethods | null;
  readonly setupSuccessViewVisible: boolean;
  readonly smsEnabled: boolean;
  readonly smsPhoneViewShown: boolean;
  readonly smsSecurityCode: string;
  readonly smsSecurityCodeBlurred: boolean;
  readonly smsSecurityCodeInvalid: boolean;
  readonly smsSecurityCodeReplyViewShown: boolean;
  readonly smsSetupSuccessViewVisible: boolean;
  readonly smsViewShown: boolean;
  readonly twoFactorAuthEnabled: boolean;
  readonly twoFactorPhoneNumber: string;
  readonly twoFactorPhoneNumberChanged: boolean;
  readonly twoFaIsEnabledViewVisible: boolean;
  readonly twoFASetupShown: boolean;
  readonly uuid: string;
  readonly twoFaRequiredByDealer: boolean;
  readonly showTwoFAExplanation: boolean;
};

type Action =
  | { type: "CHANGE_PHONE_BUTTON_CLICKED" }
  | { type: "EMAIL_SETUP_BUTTON_CLICKED"; email: string; uuid: string }
  | { type: "EMAIL_SECURITY_CODE_CHANGED"; value: string }
  | { type: "SEND_NEW_SMS_SECURITY_CODE" }
  | { type: "SET_2FA_EMAIL_ENABLED"; value: boolean }
  | { type: "EMAIL_SECURITY_CODE_BLURRED"; value: boolean }
  | { type: "SET_EMAIL_SECURITY_CODE_INVALID"; value: boolean }
  | { type: "SET_EMAIL_SECURITY_CODE_VISIBLE"; value: boolean }
  | { type: "SET_EMAIL_VIEW_SHOWN"; value: boolean }
  | { type: "SET_SMS_VIEW_SHOWN"; value: boolean }
  | { type: "SET_TWO_FA_SETUP_SHOWN"; value: boolean }
  | { type: "SMS_SECURITY_CODE_BLURRED"; value: boolean }
  | { type: "SMS_SECURITY_CODE_CHANGED"; value: string }
  | {
      type: "SMS_SUBMIT_PHONE_NUMBER_BUTTON_CLICKED";
      value: boolean;
      uuid: string;
    }
  | { type: "TWO_FACTOR_AUTH_ENABLED_RETURNED"; value: boolean }
  | { type: "TWO_FACTOR_PHONE_NUMBER_BLURRED"; value: boolean }
  | { type: "TWO_FACTOR_PHONE_NUMBER_CHANGED"; value: string }
  | { type: "UUID_RETURNED"; value: string }
  | {
      type: "SET_SECURITY_CODE_DELIVERY_METHOD";
      value: TwoFactorCodeDeliveryMethods;
    }
  | {
      type: "SMS_SECURITY_CODE_SUBMIT_BUTTON_CLICKED";
      smsEnabled: boolean;
    }
  | {
      type: "EMAIL_SECURITY_CODE_SUBMIT_BUTTON_CLICKED";
      emailEnabled: boolean;
    }
  | { type: "SMS_SETUP_BUTTON_CLICKED"; value: boolean }
  | {
      type: "DISABLED_BUTTON_CLICKED";
      initialFormState: ChangeTwoFactorFormState;
      email: string;
    }
  | {
      type: "SMS_RESPOND_MAX_ATTEMPTS_REACHED";
    }
  | {
      type: "EMAIL_RESPOND_MAX_ATTEMPTS_REACHED";
    }
  | { type: "SHOW_CONFIRM_TO_DISABLE"; confirmToDisable: boolean }
  | { type: "TOGGLE_TWO_FA_EXPLANATION" };

export type ChangeTwoFactorFormStateDispatch = Dispatch<Action>;

export const TwoFASetupFormReducer = (
  state: ChangeTwoFactorFormState,
  action: Action
): ChangeTwoFactorFormState => {
  switch (action.type) {
    case "CHANGE_PHONE_BUTTON_CLICKED":
      return {
        ...state,
        smsPhoneViewShown: true,
        smsSetupSuccessViewVisible: false,
        smsEnabled: false,
        twoFaIsEnabledViewVisible: false,
        twoFactorAuthEnabled: true,
        securityCodeDeliveryMethod: TwoFactorCodeDeliveryMethods.SMS,
      };
    case "EMAIL_SETUP_BUTTON_CLICKED":
      return {
        ...state,
        email: action.email,
        uuid: action.uuid,
        emailSecurityCodeReplyViewShown: true,
        twoFASetupShown: false,
        securityCodeDeliveryMethod: TwoFactorCodeDeliveryMethods.EMAIL,
      };
    case "SMS_SETUP_BUTTON_CLICKED":
      return {
        ...state,
        smsPhoneViewShown: true,
        twoFASetupShown: false,
        securityCodeDeliveryMethod: TwoFactorCodeDeliveryMethods.SMS,
      };
    case "SMS_SUBMIT_PHONE_NUMBER_BUTTON_CLICKED":
      return {
        ...state,
        smsPhoneViewShown: false,
        smsSecurityCodeReplyViewShown: true,
        uuid: action.uuid,
      };
    case "SMS_SECURITY_CODE_SUBMIT_BUTTON_CLICKED":
      return {
        ...state,
        smsEnabled: action.smsEnabled,
        smsSetupSuccessViewVisible: true,
        smsSecurityCodeReplyViewShown: false,
        smsPhoneViewShown: false,
      };

    case "SET_EMAIL_SECURITY_CODE_VISIBLE":
      return {
        ...state,
        emailSecurityCodeVisible: action.value,
      };
    case "SMS_SECURITY_CODE_BLURRED":
      return {
        ...state,
        smsSecurityCodeError: action.value
          ? state.smsSecurityCode.length !== 6
            ? "You must enter your six digit security code."
            : null
          : null,
      };
    case "SMS_SECURITY_CODE_CHANGED":
      return {
        ...state,
        smsSecurityCode: numberCharactersOnly(action.value),
      };
    case "EMAIL_SECURITY_CODE_CHANGED":
      return {
        ...state,
        emailSecurityCode: numberCharactersOnly(action.value),
      };
    case "EMAIL_SECURITY_CODE_BLURRED":
      return {
        ...state,
        emailSecurityCodeError: action.value
          ? state.emailSecurityCode.length !== 6
            ? "You must enter your six digit security code."
            : null
          : null,
      };

    case "TWO_FACTOR_PHONE_NUMBER_CHANGED":
      return {
        ...state,
        twoFactorPhoneNumber: numberCharactersOnly(action.value),
      };
    case "TWO_FACTOR_PHONE_NUMBER_BLURRED":
      return {
        ...state,
        phoneError: action.value
          ? !phoneRegex.test(state.twoFactorPhoneNumber)
            ? "The phone number is invalid. "
            : null
          : null,
      };

    case "SET_2FA_EMAIL_ENABLED":
      return {
        ...state,
        emailEnabled: action.value,
      };
    case "SET_TWO_FA_SETUP_SHOWN":
      return {
        ...state,
        twoFASetupShown: action.value,
      };

    case "SEND_NEW_SMS_SECURITY_CODE":
      return {
        ...state,
      };
    case "SET_EMAIL_SECURITY_CODE_INVALID":
      return {
        ...state,
        emailSecurityCodeInvalid: action.value,
      };

    case "TWO_FACTOR_AUTH_ENABLED_RETURNED":
      return {
        ...state,
        twoFactorAuthEnabled: action.value,
      };
    case "EMAIL_SECURITY_CODE_SUBMIT_BUTTON_CLICKED":
      return {
        ...state,
        emailEnabled: action.emailEnabled,
        emailSetupSuccessViewVisible: true,
        emailSecurityCodeReplyViewShown: false,
      };

    case "DISABLED_BUTTON_CLICKED":
      return {
        ...action.initialFormState,
      };

    case "SMS_RESPOND_MAX_ATTEMPTS_REACHED":
      return {
        ...state,
        smsPhoneViewShown: true,
        smsSecurityCodeReplyViewShown: false,
        securityCodeDeliveryMethod: TwoFactorCodeDeliveryMethods.SMS,
      };
    case "EMAIL_RESPOND_MAX_ATTEMPTS_REACHED":
      return {
        ...state,
        emailSecurityCodeReplyViewShown: false,
        securityCodeDeliveryMethod: null,
      };
    case "SHOW_CONFIRM_TO_DISABLE":
      return {
        ...state,
        confirmToDisable: action.confirmToDisable,
      };
    case "TOGGLE_TWO_FA_EXPLANATION":
      return {
        ...state,
        showTwoFAExplanation: !state.showTwoFAExplanation,
      };

    default:
      return state;
  }
};
