/**
 * @ngdoc directive
 * @name App.directive:panelFormErrors
 *
 * @description
 *   Manages errors that may be received from an API on Panel Programming concepts.  These errors are stored on the Panel
 *   model, and this directive determines the validity of the input field that it's placed on based on those.
 *
 *
 */
App.directive("daPanelFormErrors", function () {
  return {
    require: "ngModel",
    scope: { ngModelErrors: "=" },
    link: function ($scope, element, attrs, ngModel) {
      $scope.$watch("ngModelErrors", function (newValue, oldValue) {
        if (newValue) {
          // Force the validations to fire on this element
          ngModel.$validate();
        }
      });

      element.bind("focus", function () {
        if (
          angular.isDefined($scope.ngModelErrors) &&
          $scope.ngModelErrors.length > 0
        ) {
          delete $scope.ngModelErrors;
        }
      });

      ngModel.$validators.panelerrors = function () {
        if (angular.isDefined($scope.ngModelErrors)) {
          return false;
        } else {
          return true;
        }
      };
    },
  };
});
