/**
 * @generated SignedSource<<3b1c5038aeaac63d4e8dca810705f0d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DualAuthorityEnum = "ALL" | "ARM" | "DISARM" | "NO";
import { FragmentRefs } from "relay-runtime";
export type AreaDualAuthorityField_xrArea$data = {
  readonly dualAuthority: DualAuthorityEnum | null;
  readonly number: string;
  readonly " $fragmentType": "AreaDualAuthorityField_xrArea";
};
export type AreaDualAuthorityField_xrArea$key = {
  readonly " $data"?: AreaDualAuthorityField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaDualAuthorityField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaDualAuthorityField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dualAuthority",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "3d0d421dfce05f01d60b8576b91194d0";

export default node;
