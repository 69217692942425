/**
 *
 * ThermHeatIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ThermHeatIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M49.751,28.727C46.366,10.512,26.799,0.62,25.967,0.208c-0.854-0.425-1.889-0.183-2.472,0.575
      c-0.582,0.757-0.546,1.822,0.084,2.54c0.049,0.057,4.902,5.688,2.783,11.706c-0.453,1.284-2.373,3.073-4.406,4.967
      c-7.439,6.929-19.89,18.525-4.655,43.059C17.68,63.665,18.333,64,19.002,64c0.028,0,0.057-0.01,0.085-0.012
      c0.164,0.013,0.33,0.012,0.499-0.019c1.087-0.187,1.818-1.221,1.632-2.309c-0.022-0.126-2.092-12.683,3.485-19.807
      c1.458-1.861,2.845-3.514,4.069-4.971c3.321-3.952,5.985-7.123,7.26-11.22c2.982,5.987,6.288,18.229-2.97,35.386
      c-0.524,0.973-0.162,2.186,0.811,2.71C34.175,63.923,34.5,64,34.821,64c0.028,0,0.055-0.01,0.083-0.012
      c0.572,0.027,1.152-0.181,1.569-0.636C47.353,51.509,51.82,39.858,49.751,28.727z M42.015,49.074
      c4.501-18.863-5.189-29.732-5.695-30.282c-0.556-0.604-1.425-0.806-2.191-0.512c-0.766,0.296-1.274,1.029-1.28,1.851
      c-0.044,5.736-2.564,8.735-7.139,14.18c-1.245,1.482-2.656,3.162-4.156,5.078c-3.32,4.24-4.421,9.791-4.677,14.373
      c-7.323-16.732,1.297-24.777,7.805-30.839c2.611-2.432,4.673-4.353,5.453-6.565c1.12-3.178,0.891-6.187,0.17-8.704
      c5.708,4.176,13.622,11.627,15.514,21.805C46.988,35.748,45.702,42.313,42.015,49.074z"
        />
        <path d="M34.781,37.063c0,0-8.019,9.231-8.756,14.231C25.286,56.292,26.645,62,26.645,62S36.584,50.512,34.781,37.063z" />
      </g>
    </g>
  </SvgIcon>
);

export default ThermHeatIcon;
