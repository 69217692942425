// create a spinner component
import styled, { keyframes } from "styled-components";
import DaColors from "../../app/common/DaColors";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
  animation: ${spin} 1s linear infinite;
  border: 0.2rem solid ${DaColors.Neutral700};
  border-left-color: black;
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
  display: inline-block;
  margin-right: 0.7rem;
`;

export default Spinner;
