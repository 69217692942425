import Flex from "common/components/web/Flex";
import NeutralText from "components/NeutralText";
import ProgressCircle from "components/ProgressCircle";
import * as React from "react";
import styled from "styled-components";

const ContentContainer = styled.div`
  margin-left: var(--measure-1x);
`;
const Title = styled.h1`
  margin: 0 0 var(--measure-half);
  color: var(--color-info-400);
  font-weight: bold;
  font-size: var(--measure-font-20);
`;
const Text = styled.p`
  margin: 0;
`;

export default function FirmwareUpdateProgress(props: { percentage: number }) {
  return (
    <Flex alignItems="center">
      <ProgressCircle percentage={props.percentage} withText />
      <ContentContainer>
        <Title>Updating...</Title>
        <Text>
          <NeutralText>
            The update will continue in the background if you need to navigate
            away from this page.
          </NeutralText>
        </Text>
      </ContentContainer>
    </Flex>
  );
}
