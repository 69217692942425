import graphql from "babel-plugin-relay/macro";
import { ipIsValid } from "common/utils/universal/ip-validation";
import { isNil } from "ramda";
import React from "react";
import { useFragment } from "react-relay";
import { SmallButton } from "../../Button";
import { Flex, Form } from "../../DaStyledElements";
import Modal from "../../Modal";
import { MessageParagraphsColorless, TitleHr } from "../IntegrationForm/Form";
import { useUserIsTechSupport } from "../UserIsTechSupportContext";
import { ReceiverFormDispatch, ReceiverFormState } from "./ReceiverFormReducer";
import { ReceiverFormModalDealer_dealer$key } from "./__generated__/ReceiverFormModalDealer_dealer.graphql";

const ReceiverForm: React.FC<
  React.PropsWithChildren<{
    state: ReceiverFormState;
    dealerRef: ReceiverFormModalDealer_dealer$key;
    dispatch: ReceiverFormDispatch;
    onCancel: () => void;
    onSave: () => void;
    isSaving: () => boolean;
  }>
> = ({ state, dealerRef, dispatch, onCancel, onSave, isSaving }) => {
  const { isTechSupport } = useUserIsTechSupport();

  const dealer = useFragment(
    graphql`
      fragment ReceiverFormModalDealer_dealer on Dealer {
        id
        receivers(sort: { keys: ["number"], order: ASC }) {
          nodes {
            id
            automationPrefix
            number
            ip
            port
            description
          }
        }
        centralStationAutomationIntegrations {
          id
          name
        }
      }
    `,
    dealerRef
  );

  const {
    automationPrefix,
    number,
    ipAddress,
    port,
    description,
    cancelButtonClicked,
    centralStationAutomationIntegrationId,
  } = state;

  const numberTaken = React.useMemo(
    () =>
      !!dealer.receivers.nodes.find(
        (receiver) =>
          receiver.number === state.number && receiver.id !== state.id
      ),
    [dealer.receivers.nodes, state.id, state.number]
  );

  const shouldShowNumberErrorMessage =
    shouldShowNumberErrorMessageWithNumberTaken(numberTaken);

  const isNew = isNil(state.id);

  return (
    <Modal size="medium">
      <Modal.Header className="pad-b-0">
        {isNew ? (
          <span className="h3">Add Receiver</span>
        ) : (
          <span className="h3">Edit Receiver</span>
        )}
        <TitleHr />
      </Modal.Header>
      <Modal.Body>
        <form>
          <MessageParagraphsColorless>
            <p>
              Adding a Receiver allows you to view the full account number,
              including the automation prefix for systems in Dealer Admin. When
              you configure a Receiver, any systems containing that Receiver
              Number will display the automation prefix in System Information.
            </p>
          </MessageParagraphsColorless>

          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label>Receiver Number (1-99)</Form.Label>
                <input
                  className="form-control"
                  required
                  type="number"
                  min={1}
                  max={99}
                  maxLength={2}
                  disabled={isTechSupport}
                  size={2}
                  value={number}
                  autoComplete="off"
                  onBlur={() => {
                    if (!cancelButtonClicked) {
                      dispatch({ type: "NUMBER_BLURRED" });
                    }
                  }}
                  onChange={(event) =>
                    dispatch({
                      type: "NUMBER_CHANGED",
                      value: event.target.valueAsNumber,
                    })
                  }
                />
                {shouldShowNumberErrorMessage(state) && (
                  <div className="error-container">
                    <div
                      className="has-error"
                      style={{ color: "var(--color-danger-500)" }}
                    >
                      {numberTaken
                        ? "This number is already in use."
                        : "A number is required."}
                    </div>
                  </div>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label>Automation Prefix</Form.Label>
                <input
                  id="title"
                  className="form-control"
                  type="text"
                  required
                  maxLength={6}
                  value={automationPrefix}
                  disabled={isTechSupport}
                  autoComplete="off"
                  onBlur={() => {
                    if (!cancelButtonClicked) {
                      dispatch({ type: "AUTOMATION_PREFIX_BLURRED" });
                    }
                  }}
                  onChange={(event) =>
                    dispatch({
                      type: "AUTOMATION_PREFIX_CHANGED",
                      value: event.target.value,
                    })
                  }
                />
                {shouldShowPrefixErrorMessage(state) && (
                  <div className="error-container">
                    <div
                      className="has-error"
                      style={{ color: "var(--color-danger-500)" }}
                    >
                      An automation prefix containing 6 alphanumeric characters
                      or dashes is required.
                    </div>
                  </div>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label>IP Address</Form.Label>
                <input
                  className="form-control"
                  required
                  type="text"
                  value={ipAddress}
                  disabled={isTechSupport}
                  autoComplete="off"
                  placeholder="xxx.xxx.xxx.xxx"
                  onBlur={() => {
                    if (!cancelButtonClicked) {
                      dispatch({ type: "IP_BLURRED" });
                    }
                  }}
                  onChange={(event) =>
                    dispatch({
                      type: "IP_CHANGED",
                      value: event.target.value,
                    })
                  }
                />
                {shouldShowIpErrorMessage(state) && (
                  <div className="error-container">
                    <div
                      className="has-error"
                      style={{ color: "var(--color-danger-500)" }}
                    >
                      {!ipAddress
                        ? "An IP Address is required."
                        : "Please provide a valid IPV6 or IPV4 address"}
                    </div>
                  </div>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label>Port</Form.Label>
                <input
                  className="form-control"
                  required
                  disabled={isTechSupport}
                  type="number"
                  value={port && !isNaN(port) ? port : ""}
                  min={0}
                  max={65535}
                  autoComplete="off"
                  onBlur={() => {
                    if (!cancelButtonClicked) {
                      dispatch({ type: "PORT_BLURRED" });
                    }
                  }}
                  onChange={(event) =>
                    dispatch({
                      type: "PORT_CHANGED",
                      value: event.target.valueAsNumber,
                    })
                  }
                />
                {shouldShowPortErrorMessage(state) && (
                  <div className="error-container">
                    <div
                      className="has-error"
                      style={{ color: "var(--color-danger-500)" }}
                    >
                      A valid port is required.
                    </div>
                  </div>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          {dealer.centralStationAutomationIntegrations.length > 0 && (
            <Flex.Row>
              <Flex.Col size={1}>
                <Form.Group>
                  <Form.Label>Monitoring Center</Form.Label>
                  <select
                    className="form-control"
                    value={centralStationAutomationIntegrationId ?? NONE_OPTION}
                    disabled={isTechSupport}
                    onChange={(event) =>
                      dispatch({
                        type: "CENTRAL_STATION_AUTOMATION_INTEGRATION_CHANGED",
                        value:
                          event.target.value === NONE_OPTION
                            ? null
                            : event.target.value,
                      })
                    }
                  >
                    <option value={NONE_OPTION}>None</option>
                    {dealer.centralStationAutomationIntegrations.map(
                      ({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      )
                    )}
                  </select>
                </Form.Group>
              </Flex.Col>
            </Flex.Row>
          )}
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <textarea
                  disabled={isTechSupport}
                  className="form-control"
                  value={description}
                  rows={5}
                  autoComplete="off"
                  onChange={(event) =>
                    dispatch({
                      type: "DESCRIPTION_CHANGED",
                      value: event.target.value,
                    })
                  }
                />
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row justify={"flex-end"}>
            <SmallButton
              style={{ marginRight: "0.8rem" }}
              type="reset"
              text="Cancel"
              onMouseDown={(event) => {
                dispatch({
                  type: "CANCEL_BUTTON_CLICKED",
                });
              }}
              onClick={onCancel}
            />
            {isTechSupport ? null : (
              <SmallButton
                type="primary"
                text="Save"
                disabled={
                  shouldShowNumberErrorMessage(state) ||
                  shouldShowPrefixErrorMessage(state) ||
                  shouldShowIpErrorMessage(state) ||
                  shouldShowPortErrorMessage(state) ||
                  isSaving()
                }
                onClick={onSave}
              />
            )}
          </Flex.Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default ReceiverForm;

const NONE_OPTION = "__NONE__";

const shouldShowPrefixErrorMessage = ({
  automationPrefix,
  automationPrefixBlurred,
}: ReceiverFormState) =>
  (!automationPrefix ||
    automationPrefix.length > 6 ||
    !automationPrefix.match(/^[a-z0-9-]+$/gi)) &&
  automationPrefixBlurred;

const shouldShowIpErrorMessage = ({
  ipAddress,
  ipAddressBlurred,
}: ReceiverFormState) =>
  (!ipAddress || !ipIsValid(ipAddress ?? "")) && ipAddressBlurred;

const shouldShowNumberErrorMessageWithNumberTaken =
  (numberTaken: boolean) =>
  ({ number, numberBlurred }: ReceiverFormState) =>
    (!number || numberTaken) && numberBlurred;

const shouldShowPortErrorMessage = ({ port, portBlurred }: ReceiverFormState) =>
  (!port || isNaN(port) || port < 0 || port > 65535) && portBlurred;
