/**
 *
 * LoadingStatusMessage
 * @author Chad Watson
 *
 */

import React from "react";
import styled from "styled-components";
import LoadingSpinner from "../LoadingSpinner";
import { MessageDescriptor } from "react-intl";

const Spinner = styled(LoadingSpinner)`
  margin-right: 5px;
`;
const Root = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingStatusMessage = ({ message }: { message: MessageDescriptor }) => (
  <Root>
    <Spinner />
    &nbsp;
    <span>message</span>
  </Root>
);

export default LoadingStatusMessage;
