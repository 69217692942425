/**
 * @ngdoc service
 * @name App.factory:CamerasInAlarm
 *
 * @description
 * API factory for Third Party Video
 *
 */

App.factory("CamerasInAlarmAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/vv/:dealer_id/id/:control_system_id",
      {},
      {
        getCamsInAlarm: {
          method: "GET",
          params: { dealer_id: "@dealer_id" },
          control_system_id: "control_system_id",
        },
      }
    );
  },
]);
