import { numberCharactersOnly } from "common/utils";
import { Dispatch } from "react";

export enum TwoFactorCodeDeliveryMethods {
  SMS = "SMS",
  EMAIL = "EMAIL",
  NONE = "NONE",
}

export type LoginFormState = {
  readonly showLogin: boolean;
  readonly showTwoFAAuthSMS: boolean;
  readonly showTwoFAAuthEmail: boolean;
  readonly showTwoFASetupSMS: boolean;
  readonly showTwoFASetup: boolean;
  readonly authCode: string;
  readonly userEmail: string;
  readonly userEmailBlurred: boolean;
  readonly password: string;
  readonly passwordBlurred: boolean;
  readonly passwordVisibility: boolean;
  readonly uuid: string;
  readonly sanitizedContactInfo: string;
  readonly token: string;
  readonly method: TwoFactorCodeDeliveryMethods;
  readonly phoneNumber: string;
  readonly phoneNumberBlurred: boolean;
  readonly errorMessageLogin: string | null;
  readonly errorMessageTwoFASetup: string | null;
  readonly errorMessageTwoFAAuth: string | null;
  readonly showQrCameraModal: boolean;
  readonly showEulaModal: boolean;
  readonly eulaAgreementSigned: boolean;
};

type Action =
  | { type: "USER_EMAIL_CHANGED"; value: string }
  | { type: "USER_EMAIL_BLURRED"; value: boolean }
  | { type: "PASSWORD_CHANGED"; value: string }
  | { type: "PASSWORD_BLURRED"; value: boolean }
  | { type: "LOG_IN_ERRORED"; value: string }
  | {
      type: "TWO_F_A_AUTH_CODE_NEEDED";
      uuid: string;
      method: TwoFactorCodeDeliveryMethods;
      sanitizedContactInfo: string;
    }
  | { type: "TWO_F_A_AUTH_CODE_CHANGED"; value: string }
  | { type: "TWO_F_A_AUTH_CODE_ERRORED"; value: string }
  | { type: "TWO_F_A_SETUP_NEEDED"; token: string }
  | { type: "TWO_F_A_SETUP_SMS" }
  | { type: "TWO_F_A_SETUP_EMAIL" }
  | { type: "TWO_F_A_SETUP_ERRORED"; value: string }
  | { type: "PHONE_NUMBER_CHANGED"; value: string }
  | { type: "PHONE_NUMBER_BLURRED"; value: boolean }
  | { type: "LOGGED_OUT" }
  | { type: "TOGGLE_PASSWORD_VISIBILITY" }
  | { type: "INVALID_CODE_SUBMITTED" }
  | { type: "SHOW_QR_CAMERA_MODAL" }
  | { type: "HIDE_QR_CAMERA_MODAL" }
  | { type: "SHOW_EULA_MODAL"; value: boolean };

export type LoginFormDispatch = Dispatch<Action>;

export const LoginFormReducer = (
  state: LoginFormState,
  action: Action
): LoginFormState => {
  switch (action.type) {
    case "USER_EMAIL_CHANGED":
      return {
        ...state,
        userEmail: action.value,
      };
    case "USER_EMAIL_BLURRED":
      return {
        ...state,
        userEmailBlurred: action.value,
      };
    case "PASSWORD_CHANGED":
      return {
        ...state,
        password: action.value,
      };
    case "PASSWORD_BLURRED":
      return {
        ...state,
        passwordBlurred: action.value,
      };
    case "PHONE_NUMBER_CHANGED":
      return {
        ...state,
        phoneNumber: numberCharactersOnly(action.value),
      };
    case "PHONE_NUMBER_BLURRED":
      return {
        ...state,
        phoneNumberBlurred: action.value,
      };
    case "LOG_IN_ERRORED":
      return {
        ...state,
        errorMessageLogin: action.value,
      };
    case "TWO_F_A_AUTH_CODE_ERRORED":
      return {
        ...state,
        errorMessageTwoFAAuth: action.value,
      };
    case "TWO_F_A_AUTH_CODE_CHANGED":
      return {
        ...state,
        authCode: numberCharactersOnly(action.value),
      };
    case "TWO_F_A_AUTH_CODE_NEEDED":
      return {
        ...state,
        showLogin: false,
        showTwoFASetup: false,
        showTwoFASetupSMS: false,
        uuid: action.uuid,
        sanitizedContactInfo: action.sanitizedContactInfo,
        showTwoFAAuthEmail:
          action.method === TwoFactorCodeDeliveryMethods.EMAIL,
        showTwoFAAuthSMS: action.method === TwoFactorCodeDeliveryMethods.SMS,
      };
    case "TWO_F_A_SETUP_NEEDED":
      return {
        ...state,
        token: action.token,
        showLogin: false,
        showTwoFASetup: true,
      };
    case "TWO_F_A_SETUP_SMS":
      return {
        ...state,
        showLogin: false,
        showTwoFASetup: false,
        showTwoFASetupSMS: true,
        method: TwoFactorCodeDeliveryMethods.SMS,
      };
    case "TWO_F_A_SETUP_EMAIL":
      return {
        ...state,
        showLogin: false,
        showTwoFASetup: true,
        showTwoFASetupSMS: false,
        phoneNumber: "",
        method: TwoFactorCodeDeliveryMethods.EMAIL,
      };
    case "INVALID_CODE_SUBMITTED":
      return {
        ...state,
        authCode: "",
      };
    case "LOGGED_OUT":
      return {
        ...state,
        showLogin: true,
        showTwoFAAuthEmail: false,
        showTwoFAAuthSMS: false,
        showTwoFASetup: false,
        showTwoFASetupSMS: false,
        errorMessageTwoFASetup: null,
        errorMessageTwoFAAuth: null,
        errorMessageLogin: null,
        phoneNumberBlurred: false,
        userEmailBlurred: false,
        passwordBlurred: false,
        passwordVisibility: false,
        authCode: "",
        uuid: "",
        token: "",
        userEmail: "",
        password: "",
        phoneNumber: "",
      };
    case "TOGGLE_PASSWORD_VISIBILITY":
      return {
        ...state,
        passwordVisibility: !state.passwordVisibility,
      };
    case "SHOW_QR_CAMERA_MODAL":
      return {
        ...state,
        showQrCameraModal: true,
        showLogin: false,
      };
    case "HIDE_QR_CAMERA_MODAL":
      return {
        ...state,
        showQrCameraModal: false,
        showLogin: true,
      };
    case "SHOW_EULA_MODAL":
      return {
        ...state,
        showEulaModal: action.value,
      };

    default:
      return state;
  }
};
