import graphql from "babel-plugin-relay/macro";
import Space from "common/components/web/Space";
import DeviceInformationActivateOnboardSpeakerField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateOnboardSpeakerField";
import DeviceInformationActivateZone2BypassField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone2BypassField";
import DeviceInformationActivateZone3RequestToExitField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone3RequestToExitField";
import DeviceInformationCardOptionsField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationCardOptionsField";
import DeviceInformationNoCommWithPanelField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationNoCommWithPanelField";
import DeviceInformationRelockOnZone2ChangeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRelockOnZone2ChangeField";
import DeviceInformationRequireSiteCodeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRequireSiteCodeField";
import DeviceInformationUserCodeDigitsField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeDigitsField";
import DeviceInformationZone2BypassTimeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone2BypassTimeField";
import DeviceInformationZone3RequestToExitTimeField from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone3RequestToExitTimeField";
import DeviceInformationDeviceCommunicationTypeField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceCommunicationTypeField";
import DeviceInformationDeviceTypeField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceTypeField";
import DeviceInformationDisplayAreasField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDisplayAreasField";
import DeviceInformationDoorRealTimeStatusField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDoorRealTimeStatusField";
import DeviceInformationFireExitReleaseField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationFireExitReleaseField";
import DeviceInformationProgram734OptionsField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationProgram734OptionsField";
import DeviceInformationPublicDoorField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPublicDoorField";
import DeviceInformationScheduleOverrideField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationScheduleOverrideField";
import DeviceInformationSendDoorForcedMessageField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSendDoorForcedMessageField";
import DeviceInformationStrikeDelayField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeDelayField";
import DeviceInformationStrikeTimeField from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeTimeField";
import * as React from "react";
import { useFragment } from "react-relay";
import DeviceInformation1100TWirelessFrequencyField from "../../common/DeviceInformationFields/DeviceInformation1100TWirelessFrequencyField";
import { DeviceInformationContextProvider } from "../../common/DeviceInformationFields/DeviceInformationContext";
import DeviceInformationNameField from "../../common/DeviceInformationFields/DeviceInformationNameField";
import DeviceInformationSerialNumberField from "../../common/DeviceInformationFields/DeviceInformationSerialNumberField";
import DeviceInformationSupervisionTimeField from "../../common/DeviceInformationFields/DeviceInformationSupervisionTimeField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import XT75DeviceInformationNumberField from "./XT75DeviceInformationNumberField";
import { XT75DeviceInformationsFields_deviceInformation$key } from "./__generated__/XT75DeviceInformationsFields_deviceInformation.graphql";

function XT75DeviceInformationsFields(props: {
  deviceInformation: XT75DeviceInformationsFields_deviceInformation$key;
}) {
  const data = useFragment(
    graphql`
      fragment XT75DeviceInformationsFields_deviceInformation on DeviceInformation {
        ... on Xt75DeviceInformation {
          id
          deviceType
          deviceCommunicationMethod
          remoteProgram734
        }
        ...DeviceInformationContext_deviceInformation
        ...XT75DeviceInformationNumberField_deviceInformation
        ...DeviceInformationNameField_deviceInformation
        ...DeviceInformationDeviceTypeField_deviceInformation
        ...DeviceInformationDeviceCommunicationTypeField_deviceInformation
        ...DeviceInformationDisplayAreasField_deviceInformation
        ...DeviceInformation1100TWirelessFrequencyField_deviceInformation
        ...DeviceInformationSerialNumberField_deviceInformation
        ...DeviceInformationSupervisionTimeField_deviceInformation
        ...DeviceInformationAccessAreasField_deviceInformation
        ...DeviceInformationStrikeTimeField_deviceInformation
        ...DeviceInformationStrikeDelayField_deviceInformation
        ...DeviceInformationFireExitReleaseField_deviceInformation
        ...DeviceInformationPublicDoorField_deviceInformation
        ...DeviceInformationScheduleOverrideField_deviceInformation
        ...DeviceInformationAutoForceArmField_deviceInformation
        ...DeviceInformationDoorRealTimeStatusField_deviceInformation
        ...DeviceInformationSendDoorForcedMessageField_deviceInformation
        ...DeviceInformationProgram734OptionsField_deviceInformation
        ...DeviceInformationActivateZone2BypassField_deviceInformation
        ...DeviceInformationZone2BypassTimeField_deviceInformation
        ...DeviceInformationRelockOnZone2ChangeField_deviceInformation
        ...DeviceInformationActivateZone3RequestToExitField_deviceInformation
        ...DeviceInformationZone3RequestToExitTimeField_deviceInformation
        ...DeviceInformationActivateOnboardSpeakerField_deviceInformation
        ...DeviceInformationCardOptionsField_deviceInformation
        ...DeviceInformationRequireSiteCodeField_deviceInformation
        ...DeviceInformationUserCodeDigitsField_deviceInformation
        ...DeviceInformationNoCommWithPanelField_deviceInformation
      }
    `,
    props.deviceInformation
  );

  return (
    <DeviceInformationContextProvider deviceInformation={data}>
      <ProgrammingConceptForm.Fields>
        <XT75DeviceInformationNumberField />
        <DeviceInformationNameField />
        <DeviceInformationDeviceTypeField />
        <DeviceInformationDeviceCommunicationTypeField />
        <DeviceInformationDisplayAreasField />
        <DeviceInformation1100TWirelessFrequencyField />
        <DeviceInformationSerialNumberField />
        <DeviceInformationSupervisionTimeField />
        <DeviceInformationStrikeTimeField />
        <DeviceInformationStrikeDelayField />
        <DeviceInformationFireExitReleaseField />
        <DeviceInformationPublicDoorField />
        <DeviceInformationScheduleOverrideField />
        <DeviceInformationDoorRealTimeStatusField />
        <DeviceInformationSendDoorForcedMessageField />
        <DeviceInformationProgram734OptionsField />
      </ProgrammingConceptForm.Fields>
      <>
        <Space />
        <h3>734 Options</h3>
        <ProgrammingConceptForm.Fields>
          <DeviceInformationActivateZone2BypassField />
          <DeviceInformationZone2BypassTimeField />
          <DeviceInformationRelockOnZone2ChangeField />
          <DeviceInformationActivateZone3RequestToExitField />
          <DeviceInformationZone3RequestToExitTimeField />
          <DeviceInformationActivateOnboardSpeakerField />
          <DeviceInformationCardOptionsField />
          <DeviceInformationRequireSiteCodeField />
          <DeviceInformationUserCodeDigitsField />
          <DeviceInformationNoCommWithPanelField />
        </ProgrammingConceptForm.Fields>
      </>
    </DeviceInformationContextProvider>
  );
}

export default XT75DeviceInformationsFields;
