/**
 * @ngdoc service
 * @name App.factory:DealerInvoicesService
 *
 * @description
 * Factory for working with the Dealer Invoices
 *
 */
App.factory("DealerInvoicesService", [
  "UserService",
  "DealerInvoicesApi",
  "$q",
  function (UserService, DealerInvoicesApi, $q) {
    return {
      getInvoices: function () {
        var defferred = $q.defer();
        DealerInvoicesApi.getInvoices(
          { type: "List", dealer_id: UserService.dealer_id },
          function (data) {
            function compare(a, b) {
              dateA = a.InvoiceDate;
              dateB = b.InvoiceDate;

              if (dateA < dateB) {
                return 1;
              } else {
                return -1;
              }
            }

            data.sort(compare);

            defferred.resolve(data);
          },
          function (error) {
            defferred.reject();
          }
        );

        return defferred.promise;
      },
    };
  },
]);
