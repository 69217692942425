import DaColors from "app/common/DaColors";
import DmpIcon from "common/components/DmpIcon";
import {
  CameraStatus,
  StreamableCamera,
} from "dealer-admin/src/apis/camect-api/streamable-camera";
import CleanVideoPlayer from "dealer-admin/src/components/DaStyledElements/CleanVideoPlayer";
import useDebouncedResizeObserver from "dealer-admin/src/hooks/useDebouncedResizeObserver";
import React, { useState } from "react";
import styled from "styled-components";
import { AlarmZoneCamera } from ".";
import ToggleButton from "./ToggleButton";

const Header = styled.div`
  height: 8rem;
  padding: 0.8rem;
  & > * + * {
    margin-top: 1.2rem;
  }
`;
const HeaderTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bold;
`;
const HeaderToggles = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ColumnsToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
const FieldName = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: bold;
`;
const ThumbnailGrid = styled.div<{ isThreeColumns: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.isThreeColumns ? "3" : "2"}, minmax(0, 1fr))`};
  gap: 0.4rem;
  max-height: 82vh;
  overflow-y: auto;
`;
const ThumbnailWrapper = styled.div`
  position: relative;
`;
const HoverAndSelectOverlay = styled.div<{ isSelected: boolean }>`
  position: absolute;
  inset: 0px;
  z-index: 1000;
  border-radius: 0.2rem;
  border: ${(props) =>
    props.isSelected ? `0.4rem solid ${DaColors.Info500}` : "none"};
  ${ThumbnailWrapper}:hover & {
    background-color: hsla(194, 64%, 56%, 0.2);
  }
`;
const ThumbnailMain = styled.div`
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: ${DaColors.Black};
`;
const ThumbnailHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${DaColors.Black};
  padding: 0 0.6rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
const CameraName = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${DaColors.White};
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StatusDot = styled.div<{ isOnline: boolean }>`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 100%;
  background-color: ${(props) =>
    props.isOnline ? DaColors.Success500 : DaColors.Failure500};
`;

type Props = {
  cameras: StreamableCamera[];
  alarmZoneCameras: AlarmZoneCamera[];
  selectedCamera: StreamableCamera | null;
  setSelectedCamera: (camera: StreamableCamera) => void;
};

const LiveStreamThumbnails = ({
  cameras,
  alarmZoneCameras,
  selectedCamera,
  setSelectedCamera,
}: Props) => {
  const ASPECT_RATIO = 16 / 9;

  const { ref: resizeRef, width: panelWidth } = useDebouncedResizeObserver();

  const alarmZoneCamerasPresent = alarmZoneCameras.length > 0;

  const [isFilterToggledOn, setIsFilterToggledOn] = useState<boolean>(
    alarmZoneCamerasPresent
  );
  const [isThreeColumns, setIsThreeColumns] = useState(false);

  let playerWidth: number;
  if (panelWidth) {
    if (isThreeColumns) {
      playerWidth = (panelWidth - 8) / 3; // Allow for two 4-pixel gaps and split up the rest
    } else {
      playerWidth = (panelWidth - 4) / 2; // Allow for one 4-pixel gap and split up the rest
    }
  } else {
    playerWidth = 0;
  }
  const playerHeight = playerWidth / ASPECT_RATIO;

  const displayedCameras = isFilterToggledOn
    ? cameras.filter((c) =>
        alarmZoneCameras.find(
          (azc) =>
            azc.cameraId === c.cameraId && azc.cameraType === c.cameraType
        )
      )
    : cameras;

  return (
    <div ref={resizeRef}>
      <Header>
        <HeaderTitle>Live Camera Views</HeaderTitle>
        <HeaderToggles>
          <div>
            {alarmZoneCamerasPresent && (
              <ToggleButton
                offLabel="All Cameras"
                onLabel="Alarm Zone"
                isOn={isFilterToggledOn}
                onToggle={setIsFilterToggledOn}
              />
            )}
          </div>
          <ColumnsToggleWrapper>
            <FieldName>View</FieldName>
            <ToggleButton
              offLabel={
                <DmpIcon
                  icon="sort_4_items"
                  constrainToParent={false}
                  size={20}
                />
              }
              onLabel={
                <DmpIcon
                  icon="sort_6_items"
                  constrainToParent={false}
                  size={20}
                />
              }
              isOn={isThreeColumns}
              onToggle={setIsThreeColumns}
            />
          </ColumnsToggleWrapper>
        </HeaderToggles>
      </Header>
      <ThumbnailGrid isThreeColumns={isThreeColumns}>
        {displayedCameras.map((camera) => {
          return (
            <ThumbnailWrapper
              // The status is included in the key to force the whole thing to reload when an
              // offline camera comes back online.
              key={`thumbnail-wrapper-${camera.cameraType}-${camera.cameraId}-${camera.status}`}
              onClick={() => setSelectedCamera(camera)}
            >
              <ThumbnailMain>
                <ThumbnailHeader style={{ width: playerWidth }}>
                  <CameraName>{camera.name}</CameraName>
                  <StatusDot isOnline={camera.status === CameraStatus.Online} />
                </ThumbnailHeader>
                <CleanVideoPlayer
                  camera={camera}
                  width={playerWidth}
                  height={playerHeight}
                />
              </ThumbnailMain>
              <HoverAndSelectOverlay
                isSelected={selectedCamera?.cameraId == camera.cameraId}
              />
            </ThumbnailWrapper>
          );
        })}
      </ThumbnailGrid>
    </div>
  );
};

export default LiveStreamThumbnails;
