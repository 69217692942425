/**
 * @generated SignedSource<<e83c4fd6d46f53129dcb37f698fa3dd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75ZoneInformationsProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly id: string;
    readonly totalWirelessZoneMax: number;
    readonly totalZonesMax: number;
    readonly zoneInformations: ReadonlyArray<{
      readonly __typename: "Zone";
      readonly id: string;
      readonly isNew: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"XT75ZoneInformationsProgrammingConceptFormInline_zone">;
    }>;
    readonly zoneNumberRange: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "XT75ZoneInformationsProgrammingConceptFormInline_controlSystem";
};
export type XT75ZoneInformationsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75ZoneInformationsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75ZoneInformationsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75ZoneInformationsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "97ea9ab53cb14d5ec69420ba0ae5f7b4";

export default node;
