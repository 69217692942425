import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { prop } from "ramda";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor } from "relay-runtime";
import {
  ErrorType,
  FeatureKeyEnum,
  PanelHardwareModel,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import FeatureKeysSendKeyField from "../common/FeatureKeysFields/FeatureKeysSendKeyField";
import {
  ProgrammingConceptSidebarButton,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { useTemplateContext } from "../common/TemplateContext";
import refreshMutationConcreteRequest, {
  XRFeatureKeysProgrammingConceptFormFeatureKeysRefreshMutation,
} from "./__generated__/XRFeatureKeysProgrammingConceptFormFeatureKeysRefreshMutation.graphql";
import updateMutationConcreteRequest, {
  XRFeatureKeysProgrammingConceptFormFeatureKeyUpdateMutation,
} from "./__generated__/XRFeatureKeysProgrammingConceptFormFeatureKeyUpdateMutation.graphql";
import { XRFeatureKeysProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XRFeatureKeysProgrammingConceptFormInline_controlSystem.graphql";
import { XRFeatureKeysProgrammingConceptForm_controlSystem$key } from "./__generated__/XRFeatureKeysProgrammingConceptForm_controlSystem.graphql";

export const title = "Feature Keys";
export const conceptId = "xr-feature-keys";

export const getState = (
  controlSystem: XRFeatureKeysProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRFeatureKeysProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          __typename
          id
          hardwareModel
          featureKeys {
            key
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XRFeatureKeysProgrammingConceptFormFeatureKeysRefreshMutation(
    $controlSystemId: ID!
  ) {
    refreshFeatureKeys(controlSystemId: $controlSystemId) {
      ... on RefreshFeatureKeysSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XRFeatureKeysProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshFeatureKeysError {
        error
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XRFeatureKeysProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshFeatureKeys, isRefreshing] =
    useMutation<XRFeatureKeysProgrammingConceptFormFeatureKeysRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshFeatureKeys({
          variables: {
            controlSystemId: id,
          },
          onCompleted: (response) => {
            if (response.refreshFeatureKeys.controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Feature Key Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, response);
              }
              resolve();
            } else {
              if (showAlerts) {
                showAlert({
                  type: "error",
                  text: "Unable to Retrieve Feature Keys",
                });
              }
              reject(response.refreshFeatureKeys.error);
            }
          },
          onError: () => {
            if (showAlerts) {
              showAlert({
                type: "error",
                text: "Unable to Retrieve Feature Keys Programming From The System",
              });
            }
            reject();
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XRFeatureKeysProgrammingConceptFormFeatureKeyUpdateMutation(
    $controlSystemId: ID!
    $featureKeyCode: String!
  ) {
    updateFeatureKey(
      controlSystemId: $controlSystemId
      featureKeyCode: $featureKeyCode
    ) {
      __typename
      ... on UpdateFeatureKeySuccessPayload {
        controlSystem {
          __typename
          id
          ...XRFeatureKeysProgrammingConceptFormInline_controlSystem
        }
      }

      ... on UpdateFeatureKeyError {
        error
      }
    }
  }
`;

export const useSaveMutation = (): SaveMutationHookResponse => {
  return [
    async (showAlerts = false) => new Promise((resolve) => resolve([])),
    false,
  ];
};

export function NavButton() {
  const { isEditing: templateIsEditing } = useTemplateContext();
  return templateIsEditing ? (
    <></>
  ) : (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRFeatureKeysProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRFeatureKeysProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            featureKeys {
              key
            }
            hardwareModel
            ...FeatureKeysSendKeyField_panel
            ...PanelContext_panel
          }
        }
      `
    );
  const { panel } = controlSystem;
  const {
    helpFiles: { programmingGuideUrl },
  } = panel;

  const defaultFeatureKeys = [
    FeatureKeyEnum.DOOR_ADD_ON_A,
    FeatureKeyEnum.DOOR_ADD_ON_B,
    FeatureKeyEnum.ENCRYPTION,
  ];

  const [sendFeatureKey, isSending] =
    useMutation<XRFeatureKeysProgrammingConceptFormFeatureKeyUpdateMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();

  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  const send = (featureKeyCode: string) => {
    sendFeatureKey({
      variables: {
        controlSystemId: controlSystem.id,
        featureKeyCode,
      },
      onCompleted: (response) => {
        if (
          response.updateFeatureKey.__typename ===
          "UpdateFeatureKeySuccessPayload"
        ) {
          showAlert({
            type: "success",
            text: "Feature Key Programming Sent to the System",
          });
          resetLastUpdated(conceptId);
          // Update original data store
          const operation = createOperationDescriptor(
            updateMutationConcreteRequest,
            {
              id: controlSystem.id,
            }
          );
          if (parentRelayEnv) {
            parentRelayEnv.commitPayload(operation, response);
          }
        } else if (
          "error" in response.updateFeatureKey &&
          response.updateFeatureKey.error === ErrorType.CONNECTION_TIMEOUT
        ) {
          showAlert({
            type: "error",
            text: "Error Saving Feature Key - Connection Timed Out",
          });
        } else {
          showAlert({
            type: "error",
            text: "Unable to Send Feature Key - Unknown Error",
          });
        }
      },
    });
  };

  return (
    <PanelContextProvider panel={panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        title={title}
        helpLink={`${programmingGuideUrl}#Feature%20Upgrade`}
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.featureKeys
        )}
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          <FeatureKeysSendKeyField
            updateFeatureKey={send}
            sending={isSending}
          />
          <div className="form-section__multi-field-group__title form-section__multi-field-group__title--keys">
            Keys
          </div>
          {panel.hardwareModel === PanelHardwareModel.XR550 &&
            defaultFeatureKeys.map((featureKey) => (
              <FeatureKeyListItem
                featureKey={featureKey}
                enabled={panel.featureKeys
                  .map(prop("key"))
                  .includes(featureKey)}
              />
            ))}
          {panel.featureKeys
            .filter(
              (featureKey) =>
                !defaultFeatureKeys.includes(featureKey.key as FeatureKeyEnum)
            )
            .map((featureKey) => (
              <FeatureKeyListItem
                key={featureKey.key}
                featureKey={featureKey.key as FeatureKeyEnum}
                enabled
              />
            ))}
          {panel.hardwareModel !== PanelHardwareModel.XR550 &&
            panel.featureKeys.length === 0 && <em>No Feature Keys Enabled</em>}
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const FeatureKeyListItem = ({
  featureKey,
  enabled = true,
}: {
  featureKey: FeatureKeyEnum;
  enabled: boolean;
}) => {
  const name = formatFeatureKeyName(featureKey);
  return (
    <div className="form-section__multi-field-group--feature-key-item">
      <strong>{name}:&nbsp;</strong>
      <em>{enabled ? "(Enabled)" : "(Disabled)"}</em>
    </div>
  );
};

const formatFeatureKeyName = (featureKey: FeatureKeyEnum): string => {
  switch (featureKey) {
    case FeatureKeyEnum.DOOR_ADD_ON_A:
      return "32 Door Add-On A";
    case FeatureKeyEnum.DOOR_ADD_ON_B:
      return "32 Door Add-On B";
    case FeatureKeyEnum.ENCRYPTION:
      return "Encryption";
    default:
      return featureKey;
  }
};
