/**
 * @generated SignedSource<<bcf006429df95788050a5015b3db6d96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsPassphraseField_networkOptions$data = {
  readonly passphrase: string;
  readonly " $fragmentType": "NetworkOptionsPassphraseField_networkOptions";
};
export type NetworkOptionsPassphraseField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsPassphraseField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsPassphraseField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsPassphraseField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passphrase",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "f4c0eddd8a6e6fd9f8b724765e32a44e";

export default node;
