import { pipe } from "fp-ts/lib/function";
import * as Option from "fp-ts/Option";
import { curry, complement, sum } from "ramda";

export function isNumeric(value: any) {
  if (typeof value === "number") {
    return !isNaN(value);
  }

  return !isNaN(parseFloat(value)) && isFinite(Number(value));
}

export const ifNotNumeric = curry((defaultValue: number, value: any) =>
  isNumeric(value) ? Number(value) : defaultValue
);

export type InclusiveNumberRange = [number, number] | [number];

/**
 * Determines if a number is in a specific **Inclusive** range.
 * @param range The inclusive range to check, if max is excluded, min is used as max
 * @param number The number to validate
 */
export function inRange(range: InclusiveNumberRange, number: number) {
  if (!isNumeric(number)) {
    return false;
  }

  const [min, max] = range;
  return number >= min && number <= (max || min);
}

/**
 * Determines if a number is in an array of specific **Inclusive** ranges.
 */
export function inRanges(ranges: InclusiveNumberRange[], number: number) {
  if (!isNumeric(number)) {
    return false;
  }

  return !!ranges.find((range) => inRange(range, number));
}

/**
 * Expands a range into the full set of integers max **Inclusive**
 */
export function expandRange(range: InclusiveNumberRange) {
  const [min, max] = range;
  return max
    ? new Array(max - min + 1).fill(min).map((_, i) => i + min)
    : [min];
}

export function inverse(number: number) {
  return -1 * number;
}

/**
 * Returns a random number between two numbers, the maximum is exclusive and the minimum is inclusive
 *
 * From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 * @returns
 */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export function safeToNumber(value: any) {
  return pipe(Number(value), Option.fromPredicate<number>(complement(isNaN)));
}

export const atMost = curry((max: number, value: number) =>
  value > max ? max : value
);

export const average = (...numbers: number[]) =>
  numbers.length ? sum(numbers) / numbers.length : 0;

export const roundToNearest = (min: number, max: number, x: number) =>
  Math.round((x - min) / (max - min)) ? max : min;
