/**
 * @generated SignedSource<<a21c3ecf40bd31847c85f4d5820fe4fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewSiteControlSystemElevatorForm_site$data = {
  readonly controlSystems: ReadonlyArray<{
    readonly id: string;
    readonly serialNumber: string;
  }>;
  readonly customer: {
    readonly id: string;
  };
  readonly id: string;
  readonly " $fragmentType": "NewSiteControlSystemElevatorForm_site";
};
export type NewSiteControlSystemElevatorForm_site$key = {
  readonly " $data"?: NewSiteControlSystemElevatorForm_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewSiteControlSystemElevatorForm_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewSiteControlSystemElevatorForm_site",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "2ba0ad0307ce5cd3d5d604fcd1d78646";

export default node;
