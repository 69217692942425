import { pdf } from "@react-pdf/renderer";
import useKeypress from "common/react-hooks/use-keypress";
import useOnClickOutside from "common/react-hooks/use-on-click-outside";
import Button from "components/Button";
import { saveAs } from "file-saver";
import * as React from "react";
import styled from "styled-components";
import XLSX from "xlsx";
import Pdf from "./Pdf";

const DropdownList = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  max-width: 20rem;
  background-color: white;
  border: 0.1rem solid #e1e1e1;
  border-radius: 0.4rem;
  z-index: 500;
  top: 4rem;
  left: -50%;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
`;

const DropdownWrapper = styled.div`
  position: relative;
  margin-top: 0.8rem;
  @media (min-width: 76.8rem) {
    margin-top: 0;
  }
`;
const DropdownLink = styled.div`
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.52857;
  color: #5f5f5f;
  white-space: nowrap;
  width: 100%;
  &:hover {
    text-decoration: none;
    background-color: rgba(73, 182, 215, 0.5);
    color: #ffffff;
    transition: all 0.2s ease-out;
  }
`;
// this function is mostly copy-pasta'd from https://redstapler.co/sheetjs-tutorial-create-xlsx/
const createXlsx = (
  title: string,
  columnTitles: string[],
  data: string[][]
) => {
  const workbook = XLSX.utils.book_new();
  workbook.Props = {
    Title: title,
    Subject: "data",
    Author: "dealer.securecomwireless.com",
    CreatedDate: new Date(),
  };

  workbook.SheetNames.push("data");

  workbook.Sheets["data"] = XLSX.utils.aoa_to_sheet(
    [columnTitles].concat(data)
  );

  const workbookBinary = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  function s2ab(s: any) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  saveAs(
    new Blob([s2ab(workbookBinary)], { type: "application/octet-stream" }),
    `${title}.xlsx`
  );
};

// this function is mostly copy-pasta'd from https://stackoverflow.com/a/14966131/13175625
const createCsv = (title: string, columnTitles: string[], data: string[][]) => {
  saveAs(
    encodeURI(
      "data:text/csv;charset=utf-8," +
        [columnTitles]
          .concat(data.map((datum) => datum.map((subDatum) => `"${subDatum}"`)))
          .map((row) => row.join(","))
          .join("\n")
    ),
    `${title}.csv`
  );
};

const createPdf = async (
  title: string,
  columnTitles: string[],
  widthPercentages: number[],
  data: string[][]
) => {
  const blob = await pdf(
    <Pdf
      title={title}
      columnTitles={columnTitles}
      data={data}
      widthPercentages={widthPercentages}
    />
  ).toBlob();
  saveAs(blob, `${title}.pdf`);
};

const ExportTableButtons = ({
  title,
  columnTitles,
  data,
  widthPercentages,
}: {
  title: string;
  columnTitles: string[];
  data: string[][];
  widthPercentages: number[];
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  useKeypress("Escape", () => {
    setIsOpen(false);
  });
  return (
    <DropdownWrapper ref={dropdownRef}>
      <Button
        className="dropdown-select"
        design="primary"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Export
      </Button>

      {isOpen ? (
        <DropdownList>
          <DropdownLink
            className="link primary-link"
            onClick={() => {
              createPdf(title, columnTitles, widthPercentages, data);
              setIsOpen(false);
            }}
          >
            Generate PDF
          </DropdownLink>
          <DropdownLink
            role="button"
            className="link primary-link"
            onClick={() => {
              createXlsx(title, columnTitles, data);
              setIsOpen(false);
            }}
          >
            Generate XLSX
          </DropdownLink>
          <DropdownLink
            className="link primary-link"
            onClick={() => {
              createCsv(title, columnTitles, data);
              setIsOpen(false);
            }}
          >
            Generate CSV
          </DropdownLink>
        </DropdownList>
      ) : null}
    </DropdownWrapper>
  );
};

export default ExportTableButtons;
