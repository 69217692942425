import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const SystemAreaInformationContext = React.createContext<any>({});

export function SystemAreaInformationContextProvider(props: {
  systemAreaInformation: any;
  children: React.ReactNode;
}) {
  return (
    <SystemAreaInformationContext.Provider value={props.systemAreaInformation}>
      {props.children}
    </SystemAreaInformationContext.Provider>
  );
}

export function useSystemAreaInformationFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const areaInformation = React.useContext(SystemAreaInformationContext);

  const data = useFragment(fragmentInput, areaInformation as TKey);

  const { id } = useFragment(
    graphql`
      fragment SystemAreaInformationContext_systemAreaInformation on SystemAreaInformation {
        id
      }
    `,
    areaInformation as any
  );

  const update = (
    updater: (areaInformation: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
