import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathTestFrequencyUnitField_communicationPath$key } from "./__generated__/CommPathTestFrequencyUnitField_communicationPath.graphql";

export const commPathTestFrequencyUnitFieldId = (number: string) =>
  `comm-path-test-frequency-unit-${number}`;

function CommPathTestFrequencyUnitField() {
  const [{ number, testReport, testFrequencyUnit }, updateCommPath] =
    useCommPathFragment<CommPathTestFrequencyUnitField_communicationPath$key>(
      graphql`
        fragment CommPathTestFrequencyUnitField_communicationPath on CommunicationPath {
          number
          testReport
          testFrequencyUnit
        }
      `
    );

  const fieldId = commPathTestFrequencyUnitFieldId(String(number));
  const disabled = testReport === "NO";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Test Frequency Unit"
      tooltip="Determines the unit of measurement for the Test Frequency Number. The combination of these fields determines how often this communication path is tested."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        value={testFrequencyUnit}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "testFrequencyUnit");
          });
        }}
      >
        <Select.Option value="DAYS">Days</Select.Option>
        <Select.Option value="HOURS">Hours</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathTestFrequencyUnitField;
