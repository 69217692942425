import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { formatListOfNumbers } from "../../utils/format";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsAccessKeypadsField_systemReports$key } from "./__generated__/SystemReportsAccessKeypadsField_systemReports.graphql";

export const systemReportsAccessKeypadsFieldId = () =>
  "system-reports-access-keypads";

function SystemReportsAccessKeypadsField() {
  const [{ accessKeypads }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsAccessKeypadsField_systemReports$key>(
      graphql`
        fragment SystemReportsAccessKeypadsField_systemReports on SystemReports {
          accessKeypads
        }
      `
    );
  const fieldId = systemReportsAccessKeypadsFieldId();
  const hardwareModel = useHardwareModel();
  const { isXr150 } = resolvePanelType(hardwareModel);

  const hasEightKeypads = isXr150;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Access Keypads"
      tooltip="Enables Keypads at individual addresses to send access reports to a primary communication path."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={accessKeypads}
        pattern={
          hasEightKeypads
            ? "([1-8]([\\,\\-][1-8]){0,7})"
            : "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})"
        }
        inlineHelp={
          hasEightKeypads
            ? "1,2,3,4,5,6,7,8"
            : "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16"
        }
        validationMessage={
          hasEightKeypads
            ? "Valid values are any combination of 1-8."
            : "Valid values are any combination of 1-16."
        }
        onChange={({ target }) => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(target.value, "accessKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "accessKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsAccessKeypadsField;
