import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { outputOptionsAmbushOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsAmbushOutputField";
import { outputOptionsArmAlarmOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsArmAlarmOutputField";
import { outputOptionsArmedAllOrAwayOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsArmedAllOrAwayOutputField";
import { outputOptionsArmedHomeOrPerimeterOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsArmedHomeOrPerimeterOutputField";
import { outputOptionsArmedOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsArmedOutputField";
import { outputOptionsArmedSleepOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsArmedSleepOutputField";
import { outputOptionsBeginExitOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsBeginExitOutputField";
import { outputOptionsBurglaryOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsBurglaryOutputField";
import { outputOptionsCarbonMonoxideOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsCarbonMonoxideOutputField";
import { outputOptionsClosingWaitOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsClosingWaitOutputField";
import { outputOptionsCommunicationFailOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsCommunicationFailOutputField";
import { outputOptionsCoolSaverTemperatureFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsCoolSaverTemperatureField";
import { outputOptionsCutoffOutputsFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsCutoffOutputsField";
import { outputOptionsDeviceFailOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsDeviceFailOutputField";
import { outputOptionsDisarmedOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsDisarmedOutputField";
import { outputOptionsEndExitOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsEndExitOutputField";
import { outputOptionsEntryDelayOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsEntryDelayOutputField";
import { outputOptionsFireAlarmOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsFireAlarmOutputField";
import { outputOptionsFireTroubleOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsFireTroubleOutputField";
import { outputOptionsHeatSaverTemperatureFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsHeatSaverTemperatureField";
import { outputOptionsLateToCloseOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsLateToCloseOutputField";
import { outputOptionsLockdownOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsLockdownOutputField";
import { outputOptionsOutputCutoffTimeFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsOutputCutoffTimeField";
import { outputOptionsPanicAlarmOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsPanicAlarmOutputField";
import { outputOptionsReadyOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsReadyOutputField";
import { outputOptionsRemoteArmingOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsRemoteArmingOutputField";
import { outputOptionsSensorResetOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsSensorResetOutputField";
import { outputOptionsSupervisoryAlarmOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsSupervisoryAlarmOutputField";
import { outputOptionsTelephoneTroubleOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsTelephoneTroubleOutputField";
import { outputOptionsZoneMonitorOutputFieldId } from "components/FullProgramming/common/OutputOptionsFields/OutputOptionsZoneMonitorOutputField";
import { XTOutputOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XTFullProgramming/__generated__/XTOutputOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getOutputOptionsState } from "../../../XTFullProgramming/XTOutputOptionsProgrammingConceptForm";
import { ProgrammingTemplateOutputOptionsInput } from "../Create/__generated__/XTTemplateSaveMutation.graphql";
import { XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        outputOptions {
          id
          included
          armedOutput {
            included
            data
          }
          burglaryOutput {
            included
            data
          }
          cutoffOutputs {
            included
            data
          }
          outputCutoffTime {
            included
            data
          }
          communicationFailOutput {
            included
            data
          }
          fireAlarmOutput {
            included
            data
          }
          fireTroubleOutput {
            included
            data
          }
          panicAlarmOutput {
            included
            data
          }
          ambushOutput {
            included
            data
          }
          entryDelayOutput {
            included
            data
          }
          beginExitOutput {
            included
            data
          }
          endExitOutput {
            included
            data
          }
          readyOutput {
            included
            data
          }
          lateToCloseOutput {
            included
            data
          }
          disarmedOutput {
            included
            data
          }
          armAlarmOutput {
            included
            data
          }
          heatSaverTemperature {
            included
            data
          }
          coolSaverTemperature {
            included
            data
          }
          armedHomeOrPerimeterOutput {
            included
            data
          }
          armedAllOrAwayOutput {
            included
            data
          }
          armedSleepOutput {
            included
            data
          }
          carbonMonoxideOutput {
            included
            data
          }
          zoneMonitorOutput {
            included
            data
          }
          remoteArmingOutput {
            included
            data
          }
          telephoneTroubleOutput {
            included
            data
          }
          deviceFailOutput {
            included
            data
          }
          sensorResetOutput {
            included
            data
          }
          closingWaitOutput {
            included
            data
          }
          supervisoryAlarmOutput {
            included
            data
          }
          lockdownOutput {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XTOutputOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateOutputOptionsInput => {
  const {
    panel: { outputOptions },
  } = getOutputOptionsState(controlSystem);

  if (!outputOptions) {
    return { included: false };
  }

  const outputOptionsInputFields = {
    armedOutput: {
      included: includedFields.has(outputOptionsArmedOutputFieldId()),
      data: `${outputOptions.armedOutput.number}`,
    },
    burglaryOutput: {
      included: includedFields.has(outputOptionsBurglaryOutputFieldId()),
      data: `${`${outputOptions.burglaryOutput.number}`}`,
    },
    cutoffOutputs: {
      included: includedFields.has(outputOptionsCutoffOutputsFieldId()),
      data: outputOptions.cutoffOutputs,
    },
    outputCutoffTime: {
      included: includedFields.has(outputOptionsOutputCutoffTimeFieldId()),
      data: outputOptions.outputCutoffTime,
    },
    communicationFailOutput: {
      included: includedFields.has(
        outputOptionsCommunicationFailOutputFieldId()
      ),
      data: `${outputOptions.communicationFailOutput.number}`,
    },
    fireAlarmOutput: {
      included: includedFields.has(outputOptionsFireAlarmOutputFieldId()),
      data: `${outputOptions.fireAlarmOutput.number}`,
    },
    fireTroubleOutput: {
      included: includedFields.has(outputOptionsFireTroubleOutputFieldId()),
      data: `${outputOptions.fireTroubleOutput.number}`,
    },
    panicAlarmOutput: {
      included: includedFields.has(outputOptionsPanicAlarmOutputFieldId()),
      data: `${outputOptions.panicAlarmOutput.number}`,
    },
    ambushOutput: {
      included: includedFields.has(outputOptionsAmbushOutputFieldId()),
      data: `${outputOptions.ambushOutput.number}`,
    },
    entryDelayOutput: {
      included: includedFields.has(outputOptionsEntryDelayOutputFieldId()),
      data: `${outputOptions.entryDelayOutput.number}`,
    },
    beginExitOutput: {
      included: includedFields.has(outputOptionsBeginExitOutputFieldId()),
      data: `${outputOptions.beginExitOutput.number}`,
    },
    endExitOutput: {
      included: includedFields.has(outputOptionsEndExitOutputFieldId()),
      data: `${outputOptions.endExitOutput.number}`,
    },
    readyOutput: {
      included: includedFields.has(outputOptionsReadyOutputFieldId()),
      data: `${outputOptions.readyOutput.number}`,
    },
    lateToCloseOutput: {
      included: includedFields.has(outputOptionsLateToCloseOutputFieldId()),
      data: `${outputOptions.lateToCloseOutput.number}`,
    },
    disarmedOutput: {
      included: includedFields.has(outputOptionsDisarmedOutputFieldId()),
      data: `${outputOptions.disarmedOutput.number}`,
    },
    armAlarmOutput: {
      included: includedFields.has(outputOptionsArmAlarmOutputFieldId()),
      data: `${outputOptions.armAlarmOutput.number}`,
    },
    heatSaverTemperature: {
      included: includedFields.has(outputOptionsHeatSaverTemperatureFieldId()),
      data: outputOptions.heatSaverTemperature,
    },
    coolSaverTemperature: {
      included: includedFields.has(outputOptionsCoolSaverTemperatureFieldId()),
      data: outputOptions.coolSaverTemperature,
    },
    armedHomeOrPerimeterOutput: {
      included: includedFields.has(
        outputOptionsArmedHomeOrPerimeterOutputFieldId()
      ),
      data: `${outputOptions.armedHomeOrPerimeterOutput.number}`,
    },
    armedAllOrAwayOutput: {
      included: includedFields.has(outputOptionsArmedAllOrAwayOutputFieldId()),
      data: `${outputOptions.armedAllOrAwayOutput.number}`,
    },
    armedSleepOutput: {
      included: includedFields.has(outputOptionsArmedSleepOutputFieldId()),
      data: `${outputOptions.armedSleepOutput.number}`,
    },
    carbonMonoxideOutput: {
      included: includedFields.has(outputOptionsCarbonMonoxideOutputFieldId()),
      data: `${outputOptions.carbonMonoxideOutput.number}`,
    },
    zoneMonitorOutput: {
      included: includedFields.has(outputOptionsZoneMonitorOutputFieldId()),
      data: `${outputOptions.zoneMonitorOutput.number}`,
    },
    remoteArmingOutput: {
      included: includedFields.has(outputOptionsRemoteArmingOutputFieldId()),
      data: `${outputOptions.remoteArmingOutput.number}`,
    },
    telephoneTroubleOutput: {
      included: includedFields.has(
        outputOptionsTelephoneTroubleOutputFieldId()
      ),
      data: `${outputOptions.telephoneTroubleOutput.number}`,
    },
    deviceFailOutput: {
      included: includedFields.has(outputOptionsDeviceFailOutputFieldId()),
      data: `${outputOptions.deviceFailOutput.number}`,
    },
    sensorResetOutput: {
      included: includedFields.has(outputOptionsSensorResetOutputFieldId()),
      data: `${outputOptions.sensorResetOutput.number}`,
    },
    closingWaitOutput: {
      included: includedFields.has(outputOptionsClosingWaitOutputFieldId()),
      data: `${outputOptions.closingWaitOutput.number}`,
    },
    supervisoryAlarmOutput: {
      included: includedFields.has(
        outputOptionsSupervisoryAlarmOutputFieldId()
      ),
      data: `${outputOptions.supervisoryAlarmOutput.number}`,
    },
    lockdownOutput: {
      included: includedFields.has(outputOptionsLockdownOutputFieldId()),
      data: `${outputOptions.lockdownOutput.number}`,
    },
  };

  const outputOptionsIncluded = Object.values(outputOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: outputOptionsIncluded,
    ...outputOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XTOutputOptionsTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { outputOptions } = getState(template);

  if (!outputOptions) return;

  if (outputOptions.armedOutput?.included) {
    setIncludedFields(setToggle(outputOptionsArmedOutputFieldId()));
  }
  if (outputOptions.burglaryOutput?.included) {
    setIncludedFields(setToggle(outputOptionsBurglaryOutputFieldId()));
  }
  if (outputOptions.cutoffOutputs?.included) {
    setIncludedFields(setToggle(outputOptionsCutoffOutputsFieldId()));
  }
  if (outputOptions.outputCutoffTime?.included) {
    setIncludedFields(setToggle(outputOptionsOutputCutoffTimeFieldId()));
  }
  if (outputOptions.communicationFailOutput?.included) {
    setIncludedFields(setToggle(outputOptionsCommunicationFailOutputFieldId()));
  }
  if (outputOptions.fireAlarmOutput?.included) {
    setIncludedFields(setToggle(outputOptionsFireAlarmOutputFieldId()));
  }
  if (outputOptions.fireTroubleOutput?.included) {
    setIncludedFields(setToggle(outputOptionsFireTroubleOutputFieldId()));
  }
  if (outputOptions.panicAlarmOutput?.included) {
    setIncludedFields(setToggle(outputOptionsPanicAlarmOutputFieldId()));
  }
  if (outputOptions.ambushOutput?.included) {
    setIncludedFields(setToggle(outputOptionsAmbushOutputFieldId()));
  }
  if (outputOptions.entryDelayOutput?.included) {
    setIncludedFields(setToggle(outputOptionsEntryDelayOutputFieldId()));
  }
  if (outputOptions.beginExitOutput?.included) {
    setIncludedFields(setToggle(outputOptionsBeginExitOutputFieldId()));
  }
  if (outputOptions.endExitOutput?.included) {
    setIncludedFields(setToggle(outputOptionsEndExitOutputFieldId()));
  }
  if (outputOptions.readyOutput?.included) {
    setIncludedFields(setToggle(outputOptionsReadyOutputFieldId()));
  }
  if (outputOptions.lateToCloseOutput?.included) {
    setIncludedFields(setToggle(outputOptionsLateToCloseOutputFieldId()));
  }
  if (outputOptions.disarmedOutput?.included) {
    setIncludedFields(setToggle(outputOptionsDisarmedOutputFieldId()));
  }
  if (outputOptions.armAlarmOutput?.included) {
    setIncludedFields(setToggle(outputOptionsArmAlarmOutputFieldId()));
  }
  if (outputOptions.heatSaverTemperature?.included) {
    setIncludedFields(setToggle(outputOptionsHeatSaverTemperatureFieldId()));
  }
  if (outputOptions.coolSaverTemperature?.included) {
    setIncludedFields(setToggle(outputOptionsCoolSaverTemperatureFieldId()));
  }
  if (outputOptions.armedHomeOrPerimeterOutput?.included) {
    setIncludedFields(
      setToggle(outputOptionsArmedHomeOrPerimeterOutputFieldId())
    );
  }
  if (outputOptions.armedAllOrAwayOutput?.included) {
    setIncludedFields(setToggle(outputOptionsArmedAllOrAwayOutputFieldId()));
  }
  if (outputOptions.armedSleepOutput?.included) {
    setIncludedFields(setToggle(outputOptionsArmedSleepOutputFieldId()));
  }
  if (outputOptions.carbonMonoxideOutput?.included) {
    setIncludedFields(setToggle(outputOptionsCarbonMonoxideOutputFieldId()));
  }
  if (outputOptions.zoneMonitorOutput?.included) {
    setIncludedFields(setToggle(outputOptionsZoneMonitorOutputFieldId()));
  }
  if (outputOptions.remoteArmingOutput?.included) {
    setIncludedFields(setToggle(outputOptionsRemoteArmingOutputFieldId()));
  }
  if (outputOptions.telephoneTroubleOutput?.included) {
    setIncludedFields(setToggle(outputOptionsTelephoneTroubleOutputFieldId()));
  }
  if (outputOptions.deviceFailOutput?.included) {
    setIncludedFields(setToggle(outputOptionsDeviceFailOutputFieldId()));
  }
  if (outputOptions.sensorResetOutput?.included) {
    setIncludedFields(setToggle(outputOptionsSensorResetOutputFieldId()));
  }
  if (outputOptions.closingWaitOutput?.included) {
    setIncludedFields(setToggle(outputOptionsClosingWaitOutputFieldId()));
  }
  if (outputOptions.supervisoryAlarmOutput?.included) {
    setIncludedFields(setToggle(outputOptionsSupervisoryAlarmOutputFieldId()));
  }
  if (outputOptions.lockdownOutput?.included) {
    setIncludedFields(setToggle(outputOptionsLockdownOutputFieldId()));
  }
};
