/**
 * @generated SignedSource<<f2367041d4380f7def569645b97e4422>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem$data = {
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly messagingSetup: {
      readonly " $fragmentSpreads": FragmentRefs<"MessagingSetupContext_messagingSetup" | "MessagingSetupDestination1Field_messagingSetup" | "MessagingSetupDestination1UserField_messagingSetup" | "MessagingSetupDestination2Field_messagingSetup" | "MessagingSetupDestination2UserField_messagingSetup" | "MessagingSetupDestination3Field_messagingSetup" | "MessagingSetupDestination3UserField_messagingSetup" | "MessagingSetupMessagingEnabledField_messagingSetup" | "MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup" | "MessagingSetupSystemNameField_messagingSetup">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareDate_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem";
};
export type TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MessagingSetup",
          "kind": "LinkedField",
          "name": "messagingSetup",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupContext_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupMessagingEnabledField_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupSystemNameField_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupDestination1Field_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupDestination1UserField_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupDestination2Field_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupDestination2UserField_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupDestination3Field_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupDestination3UserField_messagingSetup"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessagingSetupSendSmsOnOpeningAndClosingField_messagingSetup"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareDate_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "042a08bca5e3fe8e97e5b258614a0845";

export default node;
