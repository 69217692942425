import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationDoorRealTimeStatusField_deviceInformation$key } from "./__generated__/DeviceInformationDoorRealTimeStatusField_deviceInformation.graphql";

export const deviceInformationDoorRealTimeStatusFieldId = (number: string) =>
  `device-information-door-realTime-status-${number}`;

function DeviceInformationDoorRealTimeStatusField() {
  const [
    { number, doorRealTimeStatusMessages, deviceType },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationDoorRealTimeStatusField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationDoorRealTimeStatusField_deviceInformation on DeviceInformation {
        number
        ... on XrDeviceInformation {
          doorRealTimeStatusMessages
          deviceType
        }
        ... on Xt75DeviceInformation {
          doorRealTimeStatusMessages
          deviceType
        }
      }
    `
  );

  const fieldId = deviceInformationDoorRealTimeStatusFieldId(String(number));
  const label = "Door Real-Time Status";
  const disabled = deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables real-time status messages for this door. Messages are sent to Entré and PC Log reporting."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={doorRealTimeStatusMessages}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              !doorRealTimeStatusMessages,
              "doorRealTimeStatusMessages"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationDoorRealTimeStatusField;
