/**
 * @generated SignedSource<<967d43aa6a5718bf81b306a7f6bd96cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2SecondIpAddressField_communication$data = {
  readonly receiver2Ip2: string;
  readonly " $fragmentType": "CommunicationReceiver2SecondIpAddressField_communication";
};
export type CommunicationReceiver2SecondIpAddressField_communication$key = {
  readonly " $data"?: CommunicationReceiver2SecondIpAddressField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2SecondIpAddressField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2SecondIpAddressField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2Ip2",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "4684e8a59c27d4ab66a63c8aac8607e0";

export default node;
