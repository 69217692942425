/**
 * @generated SignedSource<<86ddaa76bafdc457ef2e1ffe8299d1a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WirelessEncryption1100 = "ALL" | "BOTH" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsWirelessEncryption1100PassphraseField_systemOptions$data = {
  readonly houseCode?: string;
  readonly wirelessEncryption1100?: WirelessEncryption1100;
  readonly wirelessEncryption1100Passphrase?: string;
  readonly " $fragmentType": "SystemOptionsWirelessEncryption1100PassphraseField_systemOptions";
};
export type SystemOptionsWirelessEncryption1100PassphraseField_systemOptions$key = {
  readonly " $data"?: SystemOptionsWirelessEncryption1100PassphraseField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsWirelessEncryption1100PassphraseField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "wirelessEncryption1100",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "wirelessEncryption1100Passphrase",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "houseCode",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsWirelessEncryption1100PassphraseField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "f69f157f6f653710285b3244c9e63d7f";

export default node;
