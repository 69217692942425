App.controller("sunsetDealerAvailabilityModalCtrl", [
  "$scope",
  "$rootScope",
  "$q",
  "$modalInstance",
  "ModemUpgradeSettingsService",
  "UserService",
  "ModemUpgradeCustomersService",
  "ModemUpgradeUnavailableDatesService",
  function (
    $scope,
    $rootScope,
    $q,
    $modalInstance,
    ModemUpgradeSettingsService,
    UserService,
    ModemUpgradeCustomersService,
    ModemUpgradeUnavailableDatesService
  ) {
    $scope.isNew = false;
    $scope.displayCustomers = [];
    $scope.standardCustomers = [];
    $scope.upgradeCustomers = [];
    $scope.selectedCustomers = [];
    $scope.selected = null;
    $scope.pagination = {};
    $scope.pagination.itemsPerPage = 7;
    $scope.unavailableDates = [];
    $scope.displayUnavailableDates = [];
    $scope.todaysDate = new Date();
    $scope.MAX_CHAR_COUNT = 640;

    //Defaults for modem upgrade settings
    $scope.modemUpgradeSettings = {
      DealerId: UserService.dealer_id,
      DaysAvailable: [
        { name: "SUN", storedName: "sun", value: false },
        { name: "MON", storedName: "mon", value: false },
        { name: "TUE", storedName: "tue", value: false },
        { name: "WED", storedName: "wed", value: false },
        { name: "THU", storedName: "thu", value: false },
        { name: "FRI", storedName: "fri", value: false },
        { name: "SAT", storedName: "sat", value: false },
      ],
      HoursAvailable: [
        { name: "7AM", storedName: "7", value: false },
        { name: "8AM", storedName: "8", value: false },
        { name: "9AM", storedName: "9", value: false },
        { name: "10AM", storedName: "10", value: false },
        { name: "11AM", storedName: "11", value: false },
        { name: "12PM", storedName: "12", value: false },
        { name: "1PM", storedName: "13", value: false },
        { name: "2PM", storedName: "14", value: false },
        { name: "3PM", storedName: "15", value: false },
        { name: "4PM", storedName: "16", value: false },
        { name: "5PM", storedName: "17", value: false },
        { name: "6PM", storedName: "18", value: false },
      ],
      MaxPerSlot: null,
      Email: null,
      UseStandardMessage: false,
      CustomMessage: "",
      DefaultMessage:
        "Your security system currently communicates using a technology which will no longer be available. Both Verizon and AT&T are shutting down this older cellular technology to make way for the future of their networks. It is important we update your alarm system so it will continue to communicate. Please schedule a time below so we can send one of our trained technicians out to upgrade the cellular communicator on your system. Thank you.",
      CreatedAt: null,
      UpdatedAt: null,
      CreatedBy: null,
      UpdatedBy: null,
    };

    let combineCustomerLists = (qualifiedCustomers, modemUpgradeCustomers) => {
      qualifiedCustomers.forEach((customer) => {
        let customerMatch = modemUpgradeCustomers.find(
          (x) => x.CustomerId === customer.id
        );
        if (customerMatch || $scope.isNew) {
          customer.isChecked = true;
          $scope.selectedCustomers.push({
            DealerId: customer.dealer_id,
            CustomerId: customer.id,
          });
        } else {
          customer.isChecked = false;
        }
        $scope.displayCustomers.push(customer);
      });
    };

    let setUnavailableDates = (dates) => {
      dates.forEach((date) => {
        $scope.unavailableDates.push({
          SelectedDate: new Date(date.UnavailableDate),
        });
        $scope.unavailableDates.sort(
          (a, b) => a.SelectedDate.getTime() - b.SelectedDate.getTime()
        );
        $scope.displayUnavailableDates.push(new Date(date.UnavailableDate));
        $scope.displayUnavailableDates.sort(
          (a, b) => a.getTime() - b.getTime()
        );
      });
    };

    let parseAndUpdateSettings = (data) => {
      $scope.modemUpgradeSettings.DaysAvailable = setDayValues(
        data.DaysAvailable
      );
      $scope.modemUpgradeSettings.HoursAvailable = setHourValues(
        data.HoursAvailable
      );
      $scope.modemUpgradeSettings.MaxPerSlot = data.MaxPerSlot;
      $scope.modemUpgradeSettings.Email = data.Email;
      $scope.modemUpgradeSettings.UseStandardMessage = data.UseStandardMessage;
      $scope.modemUpgradeSettings.CustomMessage = data.CustomMessage;
      $scope.modemUpgradeSettings.CreatedAt = data.CreatedAt;
      $scope.modemUpgradeSettings.UpdatedAt = data.UpdatedAt;
      $scope.modemUpgradeSettings.CreatedBy = data.CreatedBy;
      $scope.modemUpgradeSettings.UpdatedBy = data.UpdatedBy;
    };

    let setDayValues = (dayString) => {
      let dayArray = dayString
        .trim()
        .replace(/\s/g, "")
        .toLowerCase()
        .split(",");
      $scope.modemUpgradeSettings.DaysAvailable.forEach((day) => {
        if (dayArray.includes(day.storedName)) day.value = true;
        else day.value = false;
      });
      return $scope.modemUpgradeSettings.DaysAvailable;
    };

    let setHourValues = (hourString) => {
      let hourArray = hourString.trim().replace(/\s/g, "").split(",");
      $scope.modemUpgradeSettings.HoursAvailable.forEach((hour) => {
        if (hourArray.includes(hour.storedName)) hour.value = true;
        else hour.value = false;
      });
      return $scope.modemUpgradeSettings.HoursAvailable;
    };

    let hoursToStorageString = (hoursObj) => {
      let hoursString = "";
      hoursObj.forEach((hour) => {
        if (hour.value) hoursString += `${hour.storedName},`;
      });
      return hoursString;
    };

    let daysToStorageString = (daysObj) => {
      let daysString = "";
      daysObj.forEach((day) => {
        if (day.value) daysString += `${day.storedName},`;
      });
      return daysString;
    };

    let prepareSettingsObject = () => {
      if ($scope.modemUpgradeSettings.CustomMessage === "") {
        $scope.modemUpgradeSettings.CustomMessage =
          $scope.modemUpgradeSettings.DefaultMessage;
      }
      let settings = JSON.parse(JSON.stringify($scope.modemUpgradeSettings)); //Deep copy the settings
      settings.HoursAvailable = hoursToStorageString(settings.HoursAvailable);
      settings.DaysAvailable = daysToStorageString(settings.DaysAvailable);
      return settings;
    };

    let prepareCustomers = () => {
      let customersToAdd = [];
      $scope.displayCustomers.forEach((customer) => {
        if (customer.isChecked)
          customersToAdd.push({
            DealerId: customer.dealer_id,
            CustomerId: customer.id,
          });
      });
      return customersToAdd;
    };

    let handle404 = (error) => {
      if (error.status == 404) {
        $scope.isNew = true;
      } else {
        return $q.reject(error);
      }
    };

    $scope.selectAllCustomers = (customersList) => {
      customersList.forEach((customer) => {
        customer.isChecked = true;
      });
    };

    $scope.deselectAllCustomers = (customersList) => {
      customersList.forEach((customer) => {
        customer.isChecked = false;
      });
    };

    $scope.getAllData = () => {
      let neededDataPromises = [];
      neededDataPromises.push(
        ModemUpgradeCustomersService.getModemUpgradeQualifiedCustomers(
          UserService.dealer_id
        ),
        ModemUpgradeCustomersService.getModemUpgradeCustomers(
          UserService.dealer_id
        ),
        ModemUpgradeUnavailableDatesService.getModemUpgradeUnavailableDates(
          UserService.dealer_id
        ),
        ModemUpgradeSettingsService.getModemUpgradeSettings(
          UserService.dealer_id
        ).catch(handle404)
      );

      $q.all(neededDataPromises).then(
        function (neededData) {
          let qualifiedCustomers = neededData[0],
            modemUpgradeCustomers = neededData[1],
            unavailableDates = neededData[2],
            modemUpgradeSettings = neededData[3];

          combineCustomerLists(qualifiedCustomers, modemUpgradeCustomers);
          setUnavailableDates(unavailableDates);
          parseAndUpdateSettings(modemUpgradeSettings);
        },
        function (error) {
          console.error(`Customer lists error: ${angular.toJson(error)}`);
          deferred.reject(error);
        }
      );
    };

    $scope.getAllData();

    $scope.updateAll = () => {
      let neededDataPromises = [],
        customersToAdd = prepareCustomers(),
        settings = prepareSettingsObject();

      if ($scope.isNew) {
        neededDataPromises.push(
          ModemUpgradeSettingsService.createModemUpgradeSettings(settings)
        );
      } else {
        neededDataPromises.push(
          ModemUpgradeSettingsService.updateModemUpgradeSettings(settings)
        );
      }
      neededDataPromises.push(
        ModemUpgradeCustomersService.batchCreateModemUpgradeCustomers(
          customersToAdd,
          UserService.dealer_id
        )
      );
      neededDataPromises.push(
        ModemUpgradeUnavailableDatesService.batchCreateModemUpgradeUnavailableDates(
          $scope.unavailableDates,
          UserService.dealer_id
        )
      );

      $q.all(neededDataPromises).then(
        function () {
          $rootScope.alerts.push({
            type: "success",
            text: "Sunset Upgrade Settings have been saved!",
          });
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error saving Sunset Upgrade Settings.",
            json: error,
          });
          deferred.reject(error);
        }
      );
    };

    $scope.handleExcludedDate = (date, actionToTake) => {
      let index = $scope.displayUnavailableDates.findIndex(
        (arrayDate) => arrayDate.getTime() == new Date(date).getTime()
      );
      switch (actionToTake) {
        case "adopt":
          if (index > -1 || !date) return;
          $scope.unavailableDates.push({ SelectedDate: new Date(date) });
          $scope.unavailableDates.sort(
            (a, b) => a.SelectedDate.getTime() - b.SelectedDate.getTime()
          );
          $scope.displayUnavailableDates.push(new Date(date));
          $scope.displayUnavailableDates.sort(
            (a, b) => a.getTime() - b.getTime()
          );
          break;
        case "strangle":
          $scope.displayUnavailableDates.splice(index, 1);
          $scope.unavailableDates.splice(index, 1);
          break;
      }
    };

    $scope.cancel = () => {
      $modalInstance.dismiss("cancel");
    };

    $scope.save = () => {
      $scope.updateAll();
      $modalInstance.dismiss("cancel");
    };
  },
]);
