/**
 * @generated SignedSource<<2b554f32c75ddda6dfbf67296784e4bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SecureComVideoLevel = "NONE" | "STANDARD" | "STANDARD_PLUS_EIGHT" | "STANDARD_PLUS_FOUR";
import { FragmentRefs } from "relay-runtime";
export type SecureComVideoForm_site$data = {
  readonly id: string;
  readonly securecomNvrEnabled: boolean;
  readonly securecomVideoLevel: SecureComVideoLevel;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComCameras_site" | "SecureComNvrs_site">;
  readonly " $fragmentType": "SecureComVideoForm_site";
};
export type SecureComVideoForm_site$key = {
  readonly " $data"?: SecureComVideoForm_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComVideoForm_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecureComVideoForm_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securecomVideoLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securecomNvrEnabled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SecureComCameras_site"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SecureComNvrs_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "d94131024229e44e070948b829b437af";

export default node;
