/**
 * @generated SignedSource<<b84676fb866d68e489e921373209ef70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TakeoverPanelFullProgramming_controlSystem$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem" | "FullProgrammingForm_controlSystem" | "ProgrammingTemplateForm_controlSystem" | "SystemOptionsKeypadInputField_controlSystem" | "TakeoverPanelAreaInformationProgrammingConceptFormNavButton_controlSystem" | "TakeoverPanelAreaInformationProgrammingConceptForm_controlSystem" | "TakeoverPanelCommunicationProgrammingConceptForm_controlSystem" | "TakeoverPanelLockoutCodeProgrammingConceptForm_controlSystem" | "TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem" | "TakeoverPanelNetworkOptionsProgrammingConceptForm_controlSystem" | "TakeoverPanelOutputOptionsProgrammingConceptForm_controlSystem" | "TakeoverPanelRemoteOptionsProgrammingConceptForm_controlSystem" | "TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem" | "TakeoverPanelSystemReportsProgrammingConceptForm_controlSystem" | "TakeoverPanelZoneInformationsProgrammingConceptFormNavButton_controlSystem" | "TakeoverPanelZoneInformationsProgrammingConceptForm_controlSystem">;
  readonly " $fragmentType": "TakeoverPanelFullProgramming_controlSystem";
};
export type TakeoverPanelFullProgramming_controlSystem$key = {
  readonly " $data"?: TakeoverPanelFullProgramming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TakeoverPanelFullProgramming_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TakeoverPanelFullProgramming_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FullProgrammingForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgrammingTemplateForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelCommunicationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelNetworkOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelMessagingSetupProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelLockoutCodeProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelRemoteOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelSystemReportsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelSystemOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SystemOptionsKeypadInputField_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelOutputOptionsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelAreaInformationProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelAreaInformationProgrammingConceptFormNavButton_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelZoneInformationsProgrammingConceptForm_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelZoneInformationsProgrammingConceptFormNavButton_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "afcb907dcf9833b4eb2e449f583e651d";

export default node;
