/**
 * @generated SignedSource<<c45814a99281ecbb38fb2128f1be64ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputInformationRealTimeStatusField_output$data = {
  readonly isWireless: boolean;
  readonly number: number;
  readonly realTimeStatus: boolean | null;
  readonly " $fragmentType": "OutputInformationRealTimeStatusField_output";
};
export type OutputInformationRealTimeStatusField_output$key = {
  readonly " $data"?: OutputInformationRealTimeStatusField_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationRealTimeStatusField_output">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputInformationRealTimeStatusField_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realTimeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWireless",
      "storageKey": null
    }
  ],
  "type": "Output",
  "abstractKey": null
};

(node as any).hash = "47827cfdb08748be635b227313a6faaf";

export default node;
