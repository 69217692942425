/**
 * @generated SignedSource<<aaa715955a1dab5fc3730a1059ff1f24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemAreaInformationAreaSchedulesField_systemAreaInformation$data = {
  readonly areaSchedules: boolean;
  readonly id: string;
  readonly " $fragmentType": "SystemAreaInformationAreaSchedulesField_systemAreaInformation";
};
export type SystemAreaInformationAreaSchedulesField_systemAreaInformation$key = {
  readonly " $data"?: SystemAreaInformationAreaSchedulesField_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemAreaInformationAreaSchedulesField_systemAreaInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemAreaInformationAreaSchedulesField_systemAreaInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "areaSchedules",
      "storageKey": null
    }
  ],
  "type": "SystemAreaInformation",
  "abstractKey": null
};

(node as any).hash = "6aa1a88d067733a3d6ec5e7a06ae41a9";

export default node;
