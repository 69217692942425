/**
 * @generated SignedSource<<a29f6b1e2a5a4af67e2f41ce80323326>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay$data = {
  readonly auxiliary1ZoneKeypads: string;
  readonly " $fragmentType": "StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay";
};
export type StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay$key = {
  readonly " $data"?: StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusListDisplayAuxiliary1ZoneKeypadsField_statusListDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auxiliary1ZoneKeypads",
      "storageKey": null
    }
  ],
  "type": "StatusListDisplay",
  "abstractKey": null
};

(node as any).hash = "63e3215c25f2ff013ad16b71467a20b4";

export default node;
