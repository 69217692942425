/**
 * @ngdoc directive
 * @name App.directive:utctime
 * @function
 *
 * @description
 * Converts an ISO8601 date string model to a UTC TIME string view. It also converts the value
 * back onto the model. No intermediate formatting or processing is required...we handle it all right here.
 *
 * @example
 * <pre> 2000-01-02T09:00:00Z -> 9:00 AM </pre>
 *
 */
App.directive("daUtctime", function ($filter, $parse) {
  var temp12hr = "((0?[0-9])|(1[0-2]))(:|.|,|;|s)?([0-5][0-9])[ ]?[apAP][mM]",
    temp24hr = "([01]?[0-9]|2[0-3])[:;][0-5][0-9]";
  var timeTest12hr = new RegExp("^" + temp12hr + "$", ["i"]),
    timeTest24hr = new RegExp("^" + temp24hr + "$", ["i"]);
  return {
    require: "ngModel",
    scope: { ngModel: "=" },
    priority: 1,
    link: function (scope, element, attrs, modelCtrl) {
      // Is this being used on a HTML5 time input type?
      var isTimeType = attrs.type.toUpperCase() == "TIME";
      function getDefaultDate() {
        return new Date("01/01/2001");
      }

      function createDateFromTime(time, cdate) {
        if (isNaN(cdate)) {
          cdate = getDefaultDate();
        }
        var ct = checkTimeFormat(time),
          minutes,
          hours,
          AMPM,
          sHours,
          sMinutes;
        if (ct == "12hr") {
          hours = Number(time.match(/^(\d+)/)[1]);
          minutes = Number(time.match(/:(\d+)/)[1]);
          AMPM = time.match(/[apAP][mM]/)[0];
          if (AMPM.toUpperCase() == "PM" && hours < 12) hours = hours + 12;
          if (AMPM.toUpperCase() == "AM" && hours == 12) hours = hours - 12;
        } else if (ct == "24hr") {
          hours = time.split(/[;:]/)[0];
          minutes = time.split(/[;:]/)[1];
        } else {
          return "invalid";
        }
        sHours = hours.toString();
        sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        cdate.setHours(sHours, sMinutes);
        var newDate = new Date(cdate);
        var _userOffset = newDate.getTimezoneOffset() * 60 * 1000; // user's offset time, in minutes
        newDate = new Date(newDate.getTime() - _userOffset);
        return newDate.toISOString();
      }

      function checkTimeFormat(value) {
        if (timeTest12hr.test(value)) return "12hr";
        else if (timeTest24hr.test(value)) return "24hr";
        else return "invalid";
      }

      /**
       * This will update the model to use the new End Day whenever the model value for the specified endDate field changes
       * endDate refers to the model referenced by the end-date attribute of the input that this directive (utctime) exists on
       */
      attrs.$observe("endDate", function (newValue) {
        // If the viewValue is invalid, don't bother adjusting the model with a new endDate
        if (checkTimeFormat(modelCtrl.$viewValue) != "invalid") {
          // for example, createDateFromTime('05:00 PM', new Date('01/05/2000'))
          var newModel = createDateFromTime(
            modelCtrl.$viewValue,
            new Date(newValue)
          );
          // Set the scoped (two-way) ngModel value to equal the newModel Date you just created
          scope.ngModel = newModel;
        }
      });
      /**
       * Update the view value to be consistent format, not just a valid format.
       * 15:00 -> 03:00 PM
       * 7:00 -> 07:00 AM
       */
      element.bind("blur", function () {
        var cleanDate = cleanUpDateString(element.val().trim());
        if (checkTimeFormat(cleanDate) != "invalid") {
          var inputDate = createDateFromTime(
            cleanDate,
            new Date(attrs.endDate)
          );
          var newDate = new Date(inputDate);
          var _date = isTimeType
            ? $filter("date")(dateTimeForceUTC(newDate), "HH:mm").toUpperCase()
            : $filter("date")(
                dateTimeForceUTC(newDate),
                "hh:mm a"
              ).toUpperCase();
          modelCtrl.$viewValue = _date;
          modelCtrl.$render();
        }
      });
      /**
       * This method fires whenever the view value is updated. It changes the value to suit the model.
       * 9:00 AM -> 2000-01-02T09:00:00Z
       *
       */
      modelCtrl.$parsers.push(function (inputValue) {
        inputValue = cleanUpDateString(inputValue);
        //convert from view to model
        if (
          angular.isUndefined(inputValue) ||
          checkTimeFormat(inputValue) == "invalid"
        ) {
          // empty, or null or undefined is OK. It just means they haven't entered anything, or they've deleted it.
          if (
            angular.isUndefined(inputValue) ||
            inputValue == "" ||
            inputValue == null
          ) {
            // Set the form input to $isValid.
            modelCtrl.$setValidity("invalid", true);
            element.parent().removeClass("error");
            return "";
          } else {
            // Set the form input to $isInvalid.
            modelCtrl.$setValidity("invalid", false);
            element.parent().addClass("error");
            return "";
          }
        }
        // Set the form input to $isValid.
        modelCtrl.$setValidity("invalid", true);
        element.parent().removeClass("error");
        var _date = createDateFromTime(inputValue, new Date(attrs.endDate));
        return _date;
      });
      /**
       * This method fires whenever the model is updated. It changes the model value to a nicely formatted display.
       * 2000-01-02T09:00:00Z -> 09:00 AM
       */
      modelCtrl.$formatters.push(function (inputValue) {
        //convert data from model to view
        if (angular.isUndefined(inputValue) || inputValue == "") {
          return "";
        }
        var inputDate = new Date(inputValue);
        var _date = isTimeType
          ? $filter("date")(dateTimeForceUTC(inputDate), "HH:mm").toUpperCase()
          : $filter("date")(
              dateTimeForceUTC(inputDate),
              "hh:mm a"
            ).toUpperCase();
        return _date;
      });
    },
  };
});
