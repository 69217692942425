import graphql from "babel-plugin-relay/macro";
import Flex from "common/components/web/Flex";
import {
  useEventEmitter,
  useOpenDWSpectrumModal,
  useUserCanEditVideoDevices,
} from "components/SiteForm/EntryPointContext";
import { isEmpty } from "ramda";
import * as React from "react";
import { useFragment, useRefetchableFragment } from "react-relay/hooks";
import { Sheet } from "../Layout/Layout";
import SuspenseBoundary from "./VideoForm/Components/SuspenseBoundary";
import VideoSelection from "./VideoForm/Components/VideoSelection";
import { DigitalWatchdogSpectrumNvrs_site$key } from "./__generated__/DigitalWatchdogSpectrumNvrs_site.graphql";
import { DigitalWatchdogSpectrumTypeButton_site$key } from "./__generated__/DigitalWatchdogSpectrumTypeButton_site.graphql";
import { DigitalWatchdogSpectrum_site$key } from "./__generated__/DigitalWatchdogSpectrum_site.graphql";

function useDigitalWatchdogSpectrumTypeChanged(onChange: () => void) {
  const eventEmitter = useEventEmitter();

  React.useEffect(() => {
    eventEmitter.on("dw-changed", onChange);
    return () => {
      eventEmitter.off("dw-changed", onChange);
    };
  }, []); // eslint-disable-line
}

function DigitalWatchdogSpectrumNvrs({
  siteRef,
}: {
  siteRef: DigitalWatchdogSpectrumNvrs_site$key;
}) {
  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment DigitalWatchdogSpectrumNvrs_site on Site
      @refetchable(queryName: "DigitalWatchdogSpectrumRefetchQuery") {
        billingControlSystemId
        digitalWatchdogSpectrumNvrs {
          id
          name
          serialNumber
          installerEmail
          channels {
            id
            name
            number
          }
        }
      }
    `,
    siteRef
  );

  useDigitalWatchdogSpectrumTypeChanged(() => {
    refetch({}, { fetchPolicy: "network-only" });
  });

  return !isEmpty(data.digitalWatchdogSpectrumNvrs) ? (
    <div className="table-responsive">
      {data.digitalWatchdogSpectrumNvrs.map((device) => (
        <div className="x1-device-list--no-border mar-t-8">
          <div className="x1-device-list__list-item--digital-watchdog">
            <div className="x1-device-list__list-item--digital-watchdog__name">
              <span>
                NVR Name: <strong>{device.name}</strong>
              </span>
              <span>Serial Number: {device.serialNumber}</span>
              User: {device.installerEmail}
            </div>
            <div className="x1-device-list__list-item--digital-watchdog__subtitle">
              <div className="mar-r-8">Channels:</div>
            </div>
            <div className="x1-device-list__list-item--digital-watchdog__channels">
              {device.channels.map((channel) => (
                <div>
                  <div
                    className="tag-view__item tag-view__item--no-border tag-view__item tag-view__item--no-padding b0"
                    key={channel.number}
                  >
                    {channel.number} - {channel.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : null;
}

function DigitalWatchdogSpectrumTypeButton({
  siteRef,
}: {
  siteRef: DigitalWatchdogSpectrumTypeButton_site$key;
}) {
  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment DigitalWatchdogSpectrumTypeButton_site on Site
      @refetchable(queryName: "DigitalWatchdogSpectrumTypeRefetchQuery") {
        billingControlSystemId
        digitalWatchdogSpectrumType
        digitalWatchdogSpectrumNvrs {
          id
        }
      }
    `,
    siteRef
  );

  const openDigitalWatchdogModal = useOpenDWSpectrumModal();
  const userCanEditVideoDevices = useUserCanEditVideoDevices();

  useDigitalWatchdogSpectrumTypeChanged(() => {
    refetch({}, { fetchPolicy: "network-only" });
  });

  return (
    <Flex>
      <strong>
        {data.digitalWatchdogSpectrumType === "BLACKJACK"
          ? "Blackjack Enabled"
          : "DW Cloud Enabled"}
      </strong>
      {userCanEditVideoDevices && (
        <button
          type="button"
          className="btn btn-dmp btn-sm"
          onClick={() => openDigitalWatchdogModal(data.billingControlSystemId)}
          style={{ marginLeft: "var(--measure-2x)" }}
        >
          Change{" "}
          <i className="icon-control_right" style={{ fontSize: "1rem" }} />
        </button>
      )}
    </Flex>
  );
}

export default function DigitalWatchdogSpectrum({
  siteRef,
}: {
  siteRef: DigitalWatchdogSpectrum_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment DigitalWatchdogSpectrum_site on Site {
        ...DigitalWatchdogSpectrumNvrs_site
        ...DigitalWatchdogSpectrumTypeButton_site
      }
    `,
    siteRef
  );

  return (
    <Sheet>
      <VideoSelection>
        <VideoSelection.Description
          title={<>Digital Watchdog Spectrum&reg; IPVMS</>}
        />
        <React.Suspense fallback={null}>
          <DigitalWatchdogSpectrumTypeButton siteRef={data} />
        </React.Suspense>
      </VideoSelection>
      <SuspenseBoundary>
        <DigitalWatchdogSpectrumNvrs siteRef={data} />
      </SuspenseBoundary>
    </Sheet>
  );
}
