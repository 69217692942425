/**
 * @generated SignedSource<<ee0004d7b66dcc74fd3f9fa55993ba7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsCrossZoneTimeField_systemOptions$data = {
  readonly crossZoneTime: number;
  readonly crossZoneTimeMax: number;
  readonly crossZoneTimeMin: number;
  readonly " $fragmentType": "SystemOptionsCrossZoneTimeField_systemOptions";
};
export type SystemOptionsCrossZoneTimeField_systemOptions$key = {
  readonly " $data"?: SystemOptionsCrossZoneTimeField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsCrossZoneTimeField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsCrossZoneTimeField_systemOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "crossZoneTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "crossZoneTimeMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "crossZoneTimeMax",
      "storageKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "9e7ec2cf27f19252da79aafaf352be72";

export default node;
