/**
 * @ngdoc service
 * @name App.factory:AboutMeV2API
 *
 * @description
 * API factory for getting information about me (logged in user).
 *
 */
App.factory("AboutMeV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(PROPS.apiUrl + "/v2/about_me", {}, {});
  },
]);
