import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationZoneRealTimeStatusField_zone$key } from "./__generated__/ZoneInformationZoneRealTimeStatusField_zone.graphql";

export const zoneInformationZoneRealTimeStatusFieldId = (number: string) =>
  `zone-information-zone-real-time-status-${number}`;

function ZoneInformationZoneRealTimeStatusField() {
  const [{ number, realTimeStatusEnabled, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationZoneRealTimeStatusField_zone$key>(
      graphql`
        fragment ZoneInformationZoneRealTimeStatusField_zone on Zone {
          number
          realTimeStatusEnabled
          type
        }
      `
    );

  const fieldId = zoneInformationZoneRealTimeStatusFieldId(String(number));
  const disabled = ![
    ZoneType.EXIT,
    ZoneType.FINAL_EXIT,
    ZoneType.NIGHT,
    ZoneType.DAY,
    ZoneType.AUXILIARY_1,
    ZoneType.AUXILIARY_2,
    ZoneType.INSTANT,
    ZoneType.DOORBELL,
    ZoneType.TAMPER,
  ].includes(type as ZoneType);
  const label = "Zone Real-Time Status";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Enable to allow real-time status reports to be sent using PC Log reporting."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={realTimeStatusEnabled}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              !realTimeStatusEnabled,
              "realTimeStatusEnabled"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationZoneRealTimeStatusField;
