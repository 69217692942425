/**
 * @generated SignedSource<<56d05b3def2b6deb8ac68e01ccc0ef95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsIntruderConfirmationTimeField_systemOptions$data = {
  readonly intruderConfirmationTime?: number | null;
  readonly " $fragmentType": "SystemOptionsIntruderConfirmationTimeField_systemOptions";
};
export type SystemOptionsIntruderConfirmationTimeField_systemOptions$key = {
  readonly " $data"?: SystemOptionsIntruderConfirmationTimeField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsIntruderConfirmationTimeField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsIntruderConfirmationTimeField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "intruderConfirmationTime",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "d62dff5172d601fbee70371f34135803";

export default node;
