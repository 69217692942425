import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { resolveDeviceInformationAreaListRules } from "./utils";
import { DeviceInformationEgressAreasField_deviceInformation$key } from "./__generated__/DeviceInformationEgressAreasField_deviceInformation.graphql";

export const deviceInformationEgressAreasFieldId = (number: string) =>
  `device-information-egress-areas-${number}`;

function DeviceInformationEgressAreasField() {
  const [
    { number, egressAreas, privateDoor, deviceType },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationEgressAreasField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationEgressAreasField_deviceInformation on DeviceInformation {
        id
        number
        ... on XrDeviceInformation {
          egressAreas
          privateDoor
          deviceType
        }
      }
    `
  );

  const fieldId = deviceInformationEgressAreasFieldId(String(number));
  const { pattern, inlineHelp, validationMessage } =
    resolveDeviceInformationAreaListRules(useHardwareModel());
  const disabled = privateDoor || deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Egress Areas"
      disabled={disabled}
      tooltip="Use this option to detect passback conditions. Anti-passback requires a User to properly egress an Area they have previously accessed."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        pattern={pattern}
        value={egressAreas}
        disabled={disabled}
        validationMessage={validationMessage}
        inlineHelp={inlineHelp}
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(target.value, "egressAreas");
          });
        }}
        onBlur={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "egressAreas"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationEgressAreasField;
