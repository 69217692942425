import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { SystemOptionsContextProvider } from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsContext";
import SystemOptionsCrossZoneTimeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import SystemOptionsEntryDelay1Field from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import SystemOptionsExitDelayField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsExitDelayField";
import SystemOptionsHoursFromGMTField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import SystemOptionsPowerFailDelayField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import SystemOptionsResetSwingerBypassField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import SystemOptionsSwingerBypassTripsField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import SystemOptionsTimeChangeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import SystemOptionsWeatherZipCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, SystemOptions } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { PanelContextProvider } from "../common/PanelContext";
import ProgrammingConceptForm from "../common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "../common/ProgrammingContext";
import SystemOptionsClosingCodeField from "../common/SystemOptionsFields/SystemOptionsClosingCodeField";
import EnableKeypadPanicKeysField from "../common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import SystemOptionsUseFalseAlarmQuestionField from "../common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import SystemOptionsWirelessAudiblesField from "../common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import { useTemplateContext } from "../common/TemplateContext";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { conceptId as zoneInformationConceptId } from "./TMSentryZoneInformationsProgrammingConceptForm";
import { TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$key } from "./__generated__/TMSentryAreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import {
  TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$data,
  TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key } from "./__generated__/TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  TMSentrySystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation,
} from "./__generated__/TMSentrySystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation.graphql";
import {
  TMSentrySystemOptionsProgrammingConceptFormSendSystemOptionsMutation,
  TMSentrySystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
} from "./__generated__/TMSentrySystemOptionsProgrammingConceptFormSendSystemOptionsMutation.graphql";
import { TMSentrySystemOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/TMSentrySystemOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "System Options";
export const conceptId = "tmsentry-system-options";

export const getState = (
  controlSystem: TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          systemOptions {
            ... on TMSentrySystemOptions {
              __typename
              closingCode
              enableKeypadPanicKeys
              entryDelay1
              exitDelay
              resetSwingerBypass
              weatherZipCode
              wirelessAudibles
              useFalseAlarmQuestion
            }
            id
            crossZoneTime
            powerFailDelay
            swingerBypassTrips
            timeChange
            hoursFromGMT
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation TMSentrySystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation(
    $systemId: ID!
  ) {
    refreshSystemOptions(systemId: $systemId) {
      ... on RefreshSystemOptionsSuccessResponse {
        __typename
        controlSystem {
          ...TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<TMSentrySystemOptionsProgrammingConceptFormRefreshSystemOptionsMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: {
            systemId: id,
          },
          onCompleted: (response) => {
            const { controlSystem, type } = response.refreshSystemOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "System Options Programming Retrieved From The System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSystemOptions: {
                    __typename: response.refreshSystemOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (type) {
                showAlert({
                  type: "error",
                  text: `Unable to Retrieve System Options: ${hyphenScoreToTitleCase(
                    type
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Retrieve System Options",
                });
              }
              reject(type);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation TMSentrySystemOptionsProgrammingConceptFormSendSystemOptionsMutation(
    $systemId: ID!
    $xtSystemOptionsInput: XtSystemOptionsInput!
  ) {
    sendXtSystemOptionsProgramming(
      systemId: $systemId
      xtSystemOptionsInput: $xtSystemOptionsInput
    ) {
      ... on SendSystemOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          id
          ...TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSystemOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: TMSentrySystemOptionsProgrammingConceptFormSendSystemOptionsMutation$data,
  originalControlSystemData: TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXtSystemOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshSystemOptions: {
          __typename: "RefreshSystemOptionsSuccessPayload",
          controlSystem: getState(
            response.sendXtSystemOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: TMSentrySystemOptionsProgrammingConceptFormInline_controlSystem$key &
    TMSentryAreaInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<TMSentrySystemOptionsProgrammingConceptFormSendSystemOptionsMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (
      showAlerts = false,
      isSavingAllListItems = false,
      resetWhenComplete = true
    ) =>
      new Promise(async (resolve, reject) => {
        const {
          id: systemId,
          panel: { systemOptions },
        } = getState(props.controlSystem);

        if (systemOptions) {
          save({
            variables: {
              systemId,
              xtSystemOptionsInput: {
                closingCode: systemOptions.closingCode,
                enableKeypadPanicKeys: systemOptions.enableKeypadPanicKeys,
                wirelessAudibles: systemOptions.wirelessAudibles,
                entryDelay1: systemOptions.entryDelay1,
                crossZoneTime: systemOptions.crossZoneTime,
                powerFailDelay: systemOptions.powerFailDelay,
                swingerBypassTrips: systemOptions.swingerBypassTrips,
                resetSwingerBypass: systemOptions.resetSwingerBypass,
                timeChange: systemOptions.timeChange,
                hoursFromGMT: systemOptions.hoursFromGMT,
                weatherZipCode: systemOptions.weatherZipCode,
                useFalseAlarmQuestion: systemOptions.useFalseAlarmQuestion,
                exitDelay: systemOptions.exitDelay,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXtSystemOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }

                if (resetWhenComplete) {
                  resetLastUpdated(conceptId);
                }

                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendXtSystemOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXtSystemOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readSystemOptionsTemplateData = (
  programmingTemplateConcepts: TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key //TODO redo this when the template is available
) =>
  readInlineData(
    graphql`
      fragment TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        systemOptions {
          included
          closingCode {
            included
            data
          }
          entryDelay1 {
            included
            data
          }
          crossZoneTime {
            included
            data
          }
          powerFailDelay {
            included
            data
          }
          swingerBypassTrips {
            included
            data
          }
          resetSwingerBypass {
            included
            data
          }
          timeChange {
            included
            data
          }
          hoursFromGMT {
            included
            data
          }
          displayTime {
            included
            data
          }
          wirelessAudibles {
            included
            data
          }
          enableKeypadPanicKeys {
            included
            data
          }
          useFalseAlarmQuestion {
            included
            data
          }
          weatherZipCode {
            included
            data
          }
          exitDelay {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).systemOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: TMSentrySystemOptionsProgrammingConceptFormInline_takeoverProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSystemOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const systemOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "systemOptions",
      "SystemOptions"
    ) as unknown as RecordProxy<SystemOptions>;

    applyTemplateScalarDataToRecordProxy(
      systemOptionsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<TMSentrySystemOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment TMSentrySystemOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          isCellComEx
          panel {
            id
            hardwareModel
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            systemOptions {
              __typename
              ...SystemOptionsContextSystemType_systemOptions
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsClosingCodeField_systemOptions
              ...SystemOptionsEntryDelay1Field_systemOptions
              ...SystemOptionsCrossZoneTimeField_systemOptions
              ...SystemOptionsPowerFailDelayField_systemOptions
              ...SystemOptionsSwingerBypassTripsField_systemOptions
              ...SystemOptionsResetSwingerBypassField_systemOptions
              ...SystemOptionsTimeChangeField_systemOptions
              ...SystemOptionsHoursFromGMTField_systemOptions
              ...SystemOptionsWeatherZipCodeField_systemOptions
              ...SystemOptionsWirelessAudiblesField_systemOptions
              ...SystemOptionsUseFalseAlarmQuestionField_systemOptions
              ...SystemOptionsExitDelayField_systemOptions
              ...SystemOptionsEnableKeypadPanicKeysField_systemOptions
            }
            ...PanelContextUseSoftwareVersion_panel
          }
        }
      `
    );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
      systemOptions,
    },
  } = controlSystem;
  const programmingContext = useProgrammingActionsContext();
  const saveSystemOptions =
    programmingContext.programmingConcepts[conceptId].onSave;
  const retrieveZoneInformation =
    programmingContext.programmingConcepts[zoneInformationConceptId].onRetrieve;

  const { isEditing: templateIsEditing } = useTemplateContext();

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <SystemOptionsContextProvider
        systemOptions={controlSystem.panel.systemOptions}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          title="System Options"
          helpLink={`${programmingGuideUrl}#System%20Options`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.systemOptions
          )}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <ProgrammingConceptForm.Fields>
              <SystemOptionsClosingCodeField />
              <SystemOptionsEntryDelay1Field />
              <SystemOptionsExitDelayField />
              <SystemOptionsCrossZoneTimeField />
              <SystemOptionsPowerFailDelayField />
              <SystemOptionsSwingerBypassTripsField />
              <SystemOptionsResetSwingerBypassField />
              <SystemOptionsTimeChangeField />
              <SystemOptionsHoursFromGMTField />
              <SystemOptionsWirelessAudiblesField />
              <SystemOptionsUseFalseAlarmQuestionField />
              <EnableKeypadPanicKeysField />
              <SystemOptionsWeatherZipCodeField />
            </ProgrammingConceptForm.Fields>
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </SystemOptionsContextProvider>
    </PanelContextProvider>
  );
}
