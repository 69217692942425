import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
  useProgrammingConceptHasFieldChanges,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import CommPathAccountNumberField from "components/FullProgramming/common/CommPathFields/CommPathAccountNumberField";
import {
  commPathListItemTemplateId,
  COMM_PATH_IDS,
} from "components/FullProgramming/common/CommPathFields/CommPathNumberField";
import CommPathTransmissionDelayField from "components/FullProgramming/common/CommPathFields/CommPathTransmissionDelayField";
import {
  ControlSystemContextProvider,
  useControlSystemFragment,
} from "components/FullProgramming/common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import SecondaryFields from "components/SecondaryFields";
import Spacer from "components/SiteForm/Layout/Spacer";
import Tag from "components/Tag";
import { useShowAlert } from "contexts/AlertsContext";
import { ascend, range } from "ramda";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  CommunicationPath,
  CommunicationPathCommType,
  CommunicationPathType,
  ControlSystem,
  fromCommunicationPathId,
  fromControlSystemId,
  idAsString,
  Panel,
  toCommunicationPathId,
  toGlobalId,
} from "securecom-graphql/client";
import CommunicationTypeOverrideModal from "../../common/CommunicationTypeOverrideModal";
import XFCommPathFields from "./XFCommPathFields";
import { XFCommunicationProgrammingConceptFormCommunicationPathDeleteMutation } from "./__generated__/XFCommunicationProgrammingConceptFormCommunicationPathDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XFCommunicationProgrammingConceptFormCommunicationPathsRefreshMutation,
} from "./__generated__/XFCommunicationProgrammingConceptFormCommunicationPathsRefreshMutation.graphql";
import {
  XFCommunicationProgrammingConceptFormCommunicationPathsSendMutation,
  XFCommunicationProgrammingConceptFormCommunicationPathsSendMutation$data,
} from "./__generated__/XFCommunicationProgrammingConceptFormCommunicationPathsSendMutation.graphql";
import {
  XFCommunicationProgrammingConceptFormInline_communicationPath$data,
  XFCommunicationProgrammingConceptFormInline_communicationPath$key,
} from "./__generated__/XFCommunicationProgrammingConceptFormInline_communicationPath.graphql";
import {
  XFCommunicationProgrammingConceptFormInline_controlSystem$data,
  XFCommunicationProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XFCommunicationProgrammingConceptFormInline_controlSystem.graphql";
import { XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import { XFCommunicationProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XFCommunicationProgrammingConceptFormNavButton_controlSystem.graphql";
import { XFCommunicationProgrammingConceptForm_controlSystem$key } from "./__generated__/XFCommunicationProgrammingConceptForm_controlSystem.graphql";
export const title = "Communication";
export const conceptId = "xf-communication";

export const getState = (
  controlSystem: XFCommunicationProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFCommunicationProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          maxCommunicationPaths
          communicationPaths {
            __typename
            id
            number
            isNew
            ...XFCommunicationProgrammingConceptFormInline_communicationPath
          }
        }
      }
    `,
    controlSystem
  );

export const getCommunicationPathState = (
  communicationPath: XFCommunicationProgrammingConceptFormInline_communicationPath$key
) =>
  readInlineData(
    graphql`
      fragment XFCommunicationProgrammingConceptFormInline_communicationPath on CommunicationPath
      @inline {
        __typename
        id
        accountNumber
        commType
        type
        number
        testReport
        testTime
        useCheckIn
        checkInMinutes
        checkInMinutesMin
        checkInMinutesMax
        failTime
        failTimeMin
        failTimeMax
        remoteIP
        receiverPort
        firstPhone
        secondPhone
        retryTime
        retryTimeMin
        retryTimeMax
        apn
        apn2
        failTestHours
        protocol
        duplicateAlarms
        sendPath
        isNew
      }
    `,
    communicationPath
  );

const refreshMutation = graphql`
  mutation XFCommunicationProgrammingConceptFormCommunicationPathsRefreshMutation(
    $systemId: ID!
  ) {
    refreshCommunicationPaths(systemId: $systemId) {
      ... on RefreshCommunicationPathsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XFCommunicationProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

const deleteMutation = graphql`
  mutation XFCommunicationProgrammingConceptFormCommunicationPathDeleteMutation(
    $communicationPathId: ID!
  ) {
    deleteCommunicationPath(communicationPathId: $communicationPathId) {
      ... on DeleteCommunicationPathSuccessPayload {
        __typename
        deletedCommunicationPathId
      }
      ... on FailedToRemoveCommunicationPathErrorPayload {
        error: type
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XFCommunicationProgrammingConceptFormCommunicationPathsSendMutation(
    $systemId: ID!
    $communicationPaths: [CommPathProgrammingInput!]!
  ) {
    sendCommunicationPathsProgramming(
      systemId: $systemId
      communicationPaths: $communicationPaths
    ) {
      ... on Error {
        type
      }
      ... on SendCommunicationPathsProgrammingSuccessPayload {
        results {
          __typename
          ... on SendCommunicationPathProgrammingComPathSuccessPayload {
            communicationPath {
              __typename
              id
              number
              isNew
              ...XFCommunicationProgrammingConceptFormInline_communicationPath
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;

const readCommPathsTemplateData = (
  programmingTemplateConcepts: XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        communication {
          included
          commType {
            included
            data
          }
          type {
            included
            data
          }
          number
          testReport {
            included
            data
          }
          testTime {
            included
            data
          }
          firstPhone {
            included
            data
          }
          secondPhone {
            included
            data
          }
          sendPath {
            included
            data
          }
          useCheckIn {
            included
            data
          }
          checkInMinutes {
            included
            data
          }
          failTime {
            included
            data
          }
          remoteIP {
            included
            data
          }
          receiverPort {
            included
            data
          }
          protocol {
            included
            data
          }
          retryTime {
            included
            data
          }
          apn {
            included
            data
          }
          apn2 {
            included
            data
          }
          duplicateAlarms {
            included
            data
          }
          failTestHours {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).communication;

export function applyTemplateData(
  programmingTemplateConcepts: XFCommunicationProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const commPathRecordProxies =
    panelRecordProxy.getLinkedRecords("communicationPaths") ?? [];
  const commPathsByNumber = indexRecordProxiesByNumber(commPathRecordProxies);

  const commPathsTemplateData =
    readCommPathsTemplateData(programmingTemplateConcepts) ?? [];
  commPathsTemplateData.forEach((commPathTemplateData) => {
    if (commPathTemplateData?.included) {
      let commPathRecordProxy = commPathsByNumber.get(
        commPathTemplateData.number
      );
      if (!commPathRecordProxy) {
        const newCommPathId = applyNewCommPathToCommPathsList(
          {
            id: panelRecordProxy.getValue("id"),
            minCommunicationPathNumber: panelRecordProxy.getValue(
              "minCommunicationPathNumber"
            ),
            maxCommunicationPaths: panelRecordProxy.getValue(
              "maxCommunicationPaths"
            ),
            communicationPaths: commPathRecordProxies.map((recordProxy) => ({
              number: recordProxy.getValue("number"),
            })),
            newCommunicationPath: panelRecordProxy.getLinkedRecord(
              "newCommunicationPath"
            ) && {
              id: panelRecordProxy
                .getLinkedRecord("newCommunicationPath")
                .getValue("id"),
            },
          },
          store
        );
        if (newCommPathId) {
          commPathRecordProxy = store.get(
            newCommPathId
          ) as RecordProxy<CommunicationPath>;
          if (commPathRecordProxy) {
            commPathRecordProxy.setValue(commPathTemplateData.number, "number");
          }
        }
      }

      if (!commPathRecordProxy) {
        return;
      }
      // if communication type is changing then set some defaults unless they are included in the template data
      if (
        commPathTemplateData.commType?.included &&
        commPathTemplateData.commType.data !==
          commPathRecordProxy.getValue("type")
      ) {
        switch (commPathTemplateData.commType.data) {
          case CommunicationPathCommType.NETWORK:
            if (!commPathTemplateData.type?.included) {
              commPathRecordProxy.setValue(
                commPathTemplateData.number === 1
                  ? CommunicationPathType.PRIMARY
                  : CommunicationPathType.BACKUP,
                "type"
              );
            }
            if (!commPathTemplateData.checkInMinutes?.included) {
              commPathRecordProxy.setValue(200, "checkInMinutes");
            }
            if (!commPathTemplateData.failTime?.included) {
              commPathRecordProxy.setValue(240, "failTime");
            }
            if (!commPathTemplateData.useCheckIn?.included) {
              commPathRecordProxy.setValue("YES", "useCheckIn");
            }
            break;
          case CommunicationPathCommType.CELL:
            if (!commPathTemplateData.type?.included) {
              commPathRecordProxy.setValue(
                commPathTemplateData.number === 1
                  ? CommunicationPathType.PRIMARY
                  : CommunicationPathType.BACKUP,
                "type"
              );
            }
            if (!commPathTemplateData.checkInMinutes?.included) {
              commPathRecordProxy.setValue(0, "checkInMinutes");
            }
            if (!commPathTemplateData.failTime?.included) {
              commPathRecordProxy.setValue(0, "failTime");
            }
            if (!commPathTemplateData.useCheckIn?.included) {
              commPathRecordProxy.setValue("NO", "useCheckIn");
            }
            if (
              !commPathTemplateData.apn?.included &&
              commPathTemplateData.commType.data ===
                CommunicationPathCommType.CELL
            ) {
              commPathRecordProxy.setValue("SECURECOM400", "apn");
            }
            break;
          default:
            break;
        }
      }

      applyTemplateScalarDataToRecordProxy(
        commPathRecordProxy,
        commPathTemplateData
      );

      if (!commPathsByNumber.has(commPathTemplateData.number)) {
        commPathsByNumber.set(commPathTemplateData.number, commPathRecordProxy);
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    [...commPathsByNumber.values()].sort(
      ascend((recordProxy) => recordProxy.getValue("number"))
    ),
    "communicationPaths"
  );
}

const mergeOldAndNewCommunicationPaths = (
  response: XFCommunicationProgrammingConceptFormCommunicationPathsSendMutation$data,
  originalControlSystemData: XFCommunicationProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendCommunicationPathsProgramming.results) {
    const successfulCommPaths =
      response.sendCommunicationPathsProgramming.results
        .map((response) => {
          if (
            response.__typename ===
            "SendCommunicationPathProgrammingComPathSuccessPayload"
          ) {
            return response.communicationPath;
          } else {
            return null;
          }
        })
        .filter(isNotNullOrUndefined)
        .map(getCommunicationPathState);

    const mergedCommPathsMap = new Map<
      string,
      XFCommunicationProgrammingConceptFormInline_communicationPath$data
    >();

    originalControlSystemData.panel.communicationPaths
      .map(getCommunicationPathState)
      .forEach((item) => mergedCommPathsMap.set(item.id, item));

    successfulCommPaths.forEach((item) =>
      mergedCommPathsMap.set(item.id, {
        ...mergedCommPathsMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedCommPathsMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XFCommunicationProgrammingConceptFormCommunicationPathsSendMutation$data,
  originalControlSystemData: XFCommunicationProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedCommPaths = mergeOldAndNewCommunicationPaths(
    response,
    originalControlSystemData
  );

  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshCommunicationPaths: {
        __typename: "RefreshCommunicationPathsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            communicationPaths: mergedCommPaths,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFCommunicationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendCommunicationPaths, isSendingCommunicationPaths] =
    useMutation<XFCommunicationProgrammingConceptFormCommunicationPathsSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedCommPaths = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const hasFieldChanges = useProgrammingConceptHasFieldChanges(conceptId);
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { communicationPaths },
        } = getState(props.controlSystem);
        sendCommunicationPaths({
          variables: {
            systemId: systemId,
            communicationPaths: communicationPaths
              .filter(
                (commPath) =>
                  commPath.isNew ||
                  hasFieldChanges ||
                  (!!changedCommPaths &&
                    listItemHasChanged(commPath.id, changedCommPaths)) ||
                  isSavingAllListItems
              )
              .map(getCommunicationPathState)
              .map((commPath) => ({
                id: commPath.id,
                commType: commPath.commType,
                type: commPath.type,
                number: commPath.number,
                testReport: commPath.testReport,
                testTime: String(commPath.testTime).trim(),
                firstPhone: commPath.firstPhone,
                secondPhone: commPath.secondPhone,
                duplicateAlarms: commPath.duplicateAlarms,
                sendPath: commPath.sendPath,
                useCheckIn: commPath.useCheckIn,
                checkInMinutes: commPath.checkInMinutes,
                failTime: commPath.failTime,
                failTestHours: commPath.failTestHours,
                remoteIP: commPath.remoteIP,
                receiverPort: commPath.receiverPort,
                protocol: commPath.protocol,
                retryTime: commPath.retryTime,
                apn: commPath.apn,
                apn2: commPath.apn2,
                isNew: commPath.isNew,
              })),
          },
          onCompleted: (response) => {
            const saveErrors: SaveErrors = [];
            if (response.sendCommunicationPathsProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendCommunicationPathsProgramming.results) {
              response.sendCommunicationPathsProgramming.results.forEach(
                (response) => {
                  if (
                    response.__typename ===
                    "SendCommunicationPathProgrammingComPathSuccessPayload"
                  ) {
                    resetLastUpdated(response.communicationPath.id);
                  } else if (
                    response.__typename === "SendListItemsErrorPayload"
                  ) {
                    saveErrors.push({
                      programmingConcept: title,
                      errors: response.errors,
                      listItemNumber: response.number,
                    });
                  }
                }
              );

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!saveErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
            }
            resolve(saveErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSendingCommunicationPaths,
  ];
};
export const useRetrieveMutation = (props: {
  controlSystem: XFCommunicationProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshCommunicationPaths, isRefreshing] =
    useMutation<XFCommunicationProgrammingConceptFormCommunicationPathsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        refreshCommunicationPaths({
          variables: {
            systemId: systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshCommunicationPaths;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Communication Path Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshCommunicationPaths: {
                    __typename: response.refreshCommunicationPaths.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Communication Paths:
                    ${hyphenScoreToTitleCase(error)}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Communication Paths",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XFCommunicationProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XFCommunicationProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            communicationPaths {
              isNew
            }
          }
        }
      `
    );
  const { communicationPaths } = controlSystem.panel;
  const itemsCount = communicationPaths.length;
  const hasNewItems =
    itemsCount > 0 && communicationPaths.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFCommunicationProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFCommunicationProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedCommPath {
            id
            commType
          }
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            minCommunicationPathNumber
            maxCommunicationPaths
            ...CommPathNumberField_panel
            communicationPaths {
              id
              accountNumber
              commType
              type
              number
              isNew
              ...XFCommPathFields_communicationPath
            }
            newCommunicationPath {
              id
              accountNumber
              commType
              type
              number
              ...XFCommPathFields_communicationPath
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...CommPathCommTypeField_panel
            ...CommPathTransmissionDelayField_panel
            ...CommPathAccountNumberField_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseFeatureKeyEnabled_panel
            ...PanelContextUseCommPaths_panel
            ...PanelContextUseHasNetworkCommType_panel
            ...PanelContextUseHasWifiCommType_panel
            ...PanelContextUseSoftwareDate_panel
            ...PanelContextUseHardwareModel_panel
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseVarEnabled_controlSystem
        }
      `
    );

  const {
    panel,
    panel: {
      helpFiles: { programmingGuideUrl },
      communicationPaths,
      newCommunicationPath,
      maxCommunicationPaths,
    },
  } = controlSystem;

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const relayEnv = useRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [overrideCommType, setOverrideCommType] =
    React.useState<"NETWORK">("NETWORK");

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    communicationPaths[0]?.id ?? null
  );

  const uncheckListItem = useUncheckListItem()(COMM_PATH_IDS);

  const [deleteCommunicationPath, isDeleting] =
    useMutation<XFCommunicationProgrammingConceptFormCommunicationPathDeleteMutation>(
      deleteMutation
    );

  const removeSelectedCommunicationPath = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.communicationPaths.findIndex(
          (commPath) => commPath.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.communicationPaths.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return (
          controlSystem.panel.communicationPaths[newSelectedIndex]?.id ?? null
        );
      });
      const commPath = communicationPaths.find(
        (commPath) => commPath.id === selectedListItemId
      );
      uncheckListItem(String(commPath?.number) ?? "");
      if (commPath?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "communicationPaths",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "CommunicationPath",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            commPath?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteCommunicationPath({
          variables: {
            communicationPathId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "communicationPaths",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedCommunicationPathId } =
              response.deleteCommunicationPath;
            if (deletedCommunicationPathId) {
              showAlert({
                type: "success",
                text: "Communication Path Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "communicationPaths",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedCommunicationPathId, error } =
              response.deleteCommunicationPath;
            if (deletedCommunicationPathId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedCommunicationPathId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "communicationPaths",
                      controlSystem.panel.id,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Communication Path: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              }
              showAlert({
                type: "error",
                text: "Unable to Delete Communication Path",
              });
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Communication Path",
            });
          },
        });
      }
    }
  };

  return (
    <ControlSystemContextProvider controlSystem={controlSystem}>
      <PanelContextProvider panel={panel}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Communication`}
          title={title}
          deleting={isDeleting}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(communicationPaths)}
          amountAvailable={maxCommunicationPaths - communicationPaths.length}
          addButton={
            <ProgrammingConceptForm.AddButton
              onClick={() => {
                relayEnv.commitUpdate((store) => {
                  const newCommunicationPathId =
                    applyNewCommPathToCommPathsList(controlSystem.panel, store);
                  if (newCommunicationPathId) {
                    setSelectedListItemId(newCommunicationPathId);
                  }
                });
              }}
            >
              Add Communication Path
            </ProgrammingConceptForm.AddButton>
          }
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            <SecondaryFields>
              <ProgrammingConceptForm.Fields>
                <CommPathAccountNumberField />
                <CommPathTransmissionDelayField />
              </ProgrammingConceptForm.Fields>
            </SecondaryFields>
          </RemountOnUpdateContainer>
          <Spacer size="4x" />
          {(conceptId === activeConcept || isApplying || isSavingAll) && (
            <ProgrammingConceptForm.ListItemsContainer>
              <ProgrammingConceptForm.ListItemPicker
                selectedId={selectedListItemId}
                onChange={(id) => {
                  setSelectedListItemId(id);
                }}
                newItemId={newCommunicationPath?.id}
                items={communicationPaths.map((commPath) => ({
                  id: commPath.id,
                  templateListItemId: commPathListItemTemplateId(
                    String(commPath.number)
                  ),
                  isnew: commPath.isNew,
                  label: `#${commPath.number} ${mapCommTypeToLabel(
                    commPath.commType as
                      | CommunicationPathCommType.NETWORK
                      | CommunicationPathCommType.CELL
                  )}`,
                  rightContent: <Tag variant="success">{commPath.type}</Tag>,
                }))}
              />
              <ProgrammingConceptForm.SelectedItemsContainer
                selectedListItemId={selectedListItemId}
                setSelectedListItemId={setSelectedListItemId}
              >
                {communicationPaths.map(
                  (communicationPath) =>
                    (communicationPath.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                      programmingConcepts[conceptId].isSaving ||
                      isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                      isSavingAll) && (
                      <RemountOnUpdateContainer nodeId={communicationPath.id}>
                        <ProgrammingConceptForm.SelectedItem
                          conceptId={conceptId}
                          isnew={communicationPath.isNew}
                          visible={communicationPath.id === selectedListItemId}
                          key={communicationPath.id}
                          listItemId={communicationPath.id}
                          templateListItemId={commPathListItemTemplateId(
                            String(communicationPath.number)
                          )}
                          title={`Communication Path #${communicationPath.number}`}
                          onDuplicate={
                            maxCommunicationPaths - communicationPaths.length >
                              0 &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const duplicateId =
                                  applyDuplicatedCommPathToCommPathsList(
                                    selectedListItemId,
                                    controlSystem.panel,
                                    store
                                  );
                                if (duplicateId) {
                                  setSelectedListItemId(duplicateId);
                                }
                              });
                            })
                          }
                          onCopy={() => {
                            relayEnv.commitUpdate((store) => {
                              const controlSystemRecord = store.get(
                                controlSystem.id
                              );
                              const commPathRecord =
                                store.get<CommunicationPath>(
                                  selectedListItemId
                                );
                              if (controlSystemRecord && commPathRecord) {
                                const tempRecord =
                                  store.get("copiedCommPath") ??
                                  store.create(
                                    "copiedCommPath",
                                    "CommunicationPath"
                                  );
                                tempRecord.copyFieldsFrom(commPathRecord);
                                controlSystemRecord.setLinkedRecord(
                                  tempRecord,
                                  "copiedCommPath"
                                );
                              }
                            });
                          }}
                          onPaste={
                            !!controlSystem.copiedCommPath &&
                            (() => {
                              relayEnv.commitUpdate((store) => {
                                const commPathRecord =
                                  store.get<CommunicationPath>(
                                    selectedListItemId
                                  );
                                const copiedCommPathRecord =
                                  store.get<CommunicationPath>(
                                    "copiedCommPath"
                                  );
                                if (copiedCommPathRecord) {
                                  setOverrideCommType(
                                    copiedCommPathRecord.getValue(
                                      "commType"
                                    ) as "NETWORK"
                                  );
                                  setModalIsOpen(true);
                                }
                                if (commPathRecord && copiedCommPathRecord) {
                                  applyCommPathProgrammingToCommPath(
                                    copiedCommPathRecord,
                                    commPathRecord
                                  );
                                }
                              });
                            })
                          }
                          onRemove={removeSelectedCommunicationPath}
                        >
                          <XFCommPathFields
                            key={communicationPath.id}
                            commPath={communicationPath}
                          />
                        </ProgrammingConceptForm.SelectedItem>
                      </RemountOnUpdateContainer>
                    )
                )}
              </ProgrammingConceptForm.SelectedItemsContainer>
            </ProgrammingConceptForm.ListItemsContainer>
          )}
        </ProgrammingConceptForm>
        {modalIsOpen ? (
          <CommunicationTypeOverrideModal
            onClose={() => setModalIsOpen(false)}
            overrideToType={overrideCommType}
            commPathId={selectedListItemId}
          />
        ) : null}
      </PanelContextProvider>
    </ControlSystemContextProvider>
  );
}

const applyNewCommPathToCommPathsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newCommunicationPath: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newCommunicationPath } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const accountNumber = panelRecord.getValue("accountNumber");
    const commPaths = panelRecord.getLinkedRecords("communicationPaths");
    // Transmission Delay is set for all communication paths share the same value
    // set the Transmission Delay for every new path to the first communication path if it is exists,
    // otherwise set the Transmission Delay for every new path to the default value 30.
    const transmissionDelay = isNotNullOrUndefined(commPaths[0])
      ? commPaths[0].getValue("transmissionDelay")
      : 30;

    const newCommPathRecord = panelRecord.getLinkedRecord(
      "newCommunicationPath"
    );
    if (newCommunicationPath) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromCommunicationPathId(
          asID(newCommPathRecord.getDataID())
        );
        const nextNewCommPathId = idAsString(
          toCommunicationPathId(systemId, nextNumber)
        );
        const nextNewCommPath = store.create(
          nextNewCommPathId,
          "CommunicationPath"
        ) as RecordProxy<CommunicationPath>;

        nextNewCommPath.copyFieldsFrom(newCommPathRecord);
        nextNewCommPath.setValue(nextNewCommPathId, "id");
        nextNewCommPath.setValue(nextNumber, "number");
        nextNewCommPath.setValue(accountNumber, "accountNumber");
        nextNewCommPath.setValue(transmissionDelay, "transmissionDelay");
        nextNewCommPath.setValue(true, "isNew");

        nextNewCommPath.setValue(
          nextNumber === 1
            ? CommunicationPathType.PRIMARY
            : CommunicationPathType.BACKUP,
          "type"
        );
        const commPathRecords =
          panelRecord.getLinkedRecords("communicationPaths") ?? [];
        panelRecord.setLinkedRecords(
          [...commPathRecords, nextNewCommPath],
          "communicationPaths"
        );
        return nextNewCommPath.getValue("id");
      }
    }
  }
};

const applyDuplicatedCommPathToCommPathsList = (
  commPathId: string,
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newCommunicationPath: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newCommunicationPath } = panel;
  const panelRecord = store.get<Panel>(id);
  const commPathRecord = store.get<CommunicationPath>(commPathId);
  if (panelRecord && commPathRecord) {
    const accountNumber = panelRecord.getValue("accountNumber");
    const newCommPathRecord = panelRecord.getLinkedRecord(
      "newCommunicationPath"
    );
    if (newCommunicationPath) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromCommunicationPathId(
          asID(newCommPathRecord.getDataID())
        );
        const nextNewCommPathId = idAsString(
          toCommunicationPathId(systemId, nextNumber)
        );
        const nextNewCommPath = store.create(
          nextNewCommPathId,
          "CommunicationPath"
        ) as RecordProxy<CommunicationPath>;
        nextNewCommPath.copyFieldsFrom(newCommPathRecord);
        nextNewCommPath.setValue(nextNewCommPathId, "id");
        nextNewCommPath.setValue(nextNumber, "number");
        nextNewCommPath.setValue(accountNumber, "accountNumber");
        nextNewCommPath.setValue(true, "isNew");

        const duplicatedCommPathRecord = applyCommPathProgrammingToCommPath(
          commPathRecord,
          nextNewCommPath
        );
        const commPathRecords =
          panelRecord.getLinkedRecords("communicationPaths") ?? [];
        panelRecord.setLinkedRecords(
          [...commPathRecords, duplicatedCommPathRecord],
          "communicationPaths"
        );

        return duplicatedCommPathRecord.getValue("id");
      }
    }
  }
};

const applyCommPathProgrammingToCommPath = (
  source: RecordProxy<CommunicationPath>,
  dest: RecordProxy<CommunicationPath>
) => {
  const id = dest.getValue("id");
  const isNew = dest.getValue("isNew");
  const number = dest.getValue("number");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  if (Number(number) === 1)
    //Path one is always primary
    dest.setValue(CommunicationPathType.PRIMARY, "type");
  return dest;
};

const getNextAvailableNumber = (panel: {
  readonly minCommunicationPathNumber: number;
  readonly maxCommunicationPaths: number;
  readonly communicationPaths: ReadonlyArray<{ readonly number: number }>;
}) => {
  const {
    minCommunicationPathNumber,
    maxCommunicationPaths,
    communicationPaths,
  } = panel;

  const allPossibleNumbers = new Set(
    range(
      minCommunicationPathNumber,
      minCommunicationPathNumber + maxCommunicationPaths
    )
  );
  const takenNumbers = new Set(communicationPaths.map(({ number }) => number));
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return setFirst(availableNumbers);
};

const mapCommTypeToLabel = (
  commType: CommunicationPathCommType.CELL | CommunicationPathCommType.NETWORK
) => {
  switch (commType) {
    case CommunicationPathCommType.NETWORK:
      return "Network";
    case CommunicationPathCommType.CELL:
      return "Cell";
    default:
      return "Network";
  }
};
