/**
 *
 * KeyIcon
 * @author Chad Watson
 *
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const KeyIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <g>
        <g>
          <path
            d="M20.129,64.03C20.129,64.03,20.129,64.03,20.129,64.03c-2.014,0-3.99-0.311-5.873-0.923
        C5.935,60.62-0.08,52.613,0.001,44.083c-0.06-4.89,1.891-9.74,5.354-13.264c1.626-1.654,3.489-2.925,5.536-3.778
        c3.529-1.576,7.743-1.947,11.77-1.036l0.181-2.01c0.007-0.08,0.019-0.159,0.036-0.237c0.34-1.598,1.573-2.671,3.068-2.671
        c0.882,0,1.752,0.386,2.449,1.086l0.61,0.611L49.943,1.777c0.182-0.182,0.396-0.327,0.633-0.428
        c2.271-0.967,3.883-1.379,5.39-1.379c1.182,0,2.271,0.26,3.312,0.792c0.896,0.073,1.121,0.608,2.799,4.615l1.423,3.398
        c0.206,0.49,0.207,1.043,0.004,1.534L61.64,14.84c-0.1,0.243-0.248,0.465-0.434,0.651l-4.126,4.135
        c-0.551,0.554-1.373,0.733-2.109,0.463l-2.172-0.803l0.801,2.202c0.266,0.73,0.084,1.548-0.464,2.098l-4.448,4.452
        c-0.547,0.55-1.36,0.732-2.089,0.47l-2.779-0.995l0.717,2.972c0.148,0.614-0.003,1.262-0.407,1.748l-2.294,2.758l1.146,1.069
        c0.173,0.172,0.291,0.273,0.39,0.361c0.398,0.35,1.332,1.169,1.012,2.473c-0.324,1.319-1.609,1.666-2.275,1.829l-2.871,0.869
        c1.179,4.922-0.366,10.132-4.61,15.521C31.372,61.432,25.942,64.03,20.129,64.03z M18.262,29.512c-2.054,0-4,0.404-5.787,1.201
        c-1.598,0.667-3.019,1.64-4.269,2.91c-2.72,2.769-4.253,6.579-4.206,10.454c-0.063,6.722,4.857,13.242,11.447,15.212
        c1.529,0.496,3.088,0.741,4.681,0.741c4.564,0,8.8-2.003,11.33-5.359c3.975-5.047,5.08-9.503,3.404-13.656
        c-0.21-0.521-0.192-1.106,0.051-1.613c0.242-0.506,0.687-0.888,1.224-1.05l2.597-0.786L37.71,36.61
        c-0.397-0.371-0.626-0.888-0.635-1.431c-0.008-0.544,0.205-1.066,0.59-1.45l0.088-0.087l2.659-3.196l-1.35-5.597
        c-0.172-0.714,0.061-1.466,0.608-1.957c0.546-0.49,1.318-0.644,2.011-0.395l5.072,1.816l2.653-2.655l-1.84-5.062
        c-0.267-0.732-0.083-1.552,0.469-2.102s1.372-0.726,2.104-0.458l5.017,1.854l2.938-2.945l1.397-3.393
        c-0.557-1.331-1.696-4.052-2.23-5.323c-1.162-0.506-2.397-0.321-4.764,0.65L30.42,27.029c-0.75,0.752-2.082,0.752-2.833,0
        l-0.92-0.923l-0.241,2.676c-0.056,0.617-0.394,1.174-0.917,1.508c-0.522,0.332-1.171,0.405-1.753,0.195
        C21.961,29.839,20.113,29.512,18.262,29.512z"
          />
        </g>
      </g>
      <g>
        <path
          d="M16.613,53.356L16.613,53.356c-1.491,0-2.891-0.581-3.943-1.636c-2.172-2.182-2.167-5.734,0.011-7.921
      c1.06-1.062,2.467-1.647,3.963-1.647c1.492,0,2.894,0.582,3.947,1.639c2.174,2.179,2.169,5.729-0.011,7.916
      C19.52,52.771,18.11,53.356,16.613,53.356z M16.644,46.152c-0.426,0-0.828,0.167-1.13,0.471c-0.627,0.63-0.632,1.65-0.011,2.274
      c0.594,0.597,1.641,0.592,2.245-0.015c0.626-0.629,0.631-1.646,0.011-2.267C17.461,46.316,17.065,46.152,16.644,46.152z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default KeyIcon;
