import { hyphenScoreToTitleCase } from "common/utils";
import { AlertObject } from "contexts/AlertsContext";
import { ErrorType } from "../XRFullProgramming/XRZoneInformationProgrammingConceptForm/__generated__/XRZoneInformationProgrammingConceptFormZoneInformationSendMutation.graphql";

export const showFieldErrorAlerts = (
  programmingConcept: string,
  errors: readonly {
    readonly type?: ErrorType | null | undefined;
    readonly invalidField?:
      | {
          readonly fieldName: string;
          readonly reason: string | null;
        }
      | null
      | undefined;
  }[],
  showAlert: (alert: AlertObject) => void,
  listItemNumber = ""
) => {
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      if (error.type === "INVALID_INPUT") {
        showAlert({
          type: "error",
          text: `${error.invalidField?.fieldName} field Error on ${programmingConcept} ${listItemNumber}: ${error.invalidField?.reason}`,
        });
      } else {
        showAlert({
          type: "error",
          text: `Error Sending ${programmingConcept} Programming to the System. - ${hyphenScoreToTitleCase(
            error.type ?? "UNKNOWN_ERROR"
          )}`,
        });
      }
    });
  } else {
    showAlert({
      type: "error",
      text: `Error Sending  Programming to the System. - ${hyphenScoreToTitleCase(
        "UNKNOWN_ERROR"
      )}`,
    });
  }
};
