/**
 * @generated SignedSource<<bd47e8a6d5916ea5b30f10fc75622ec8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneChimeSound = "ASCEND" | "DESCEND" | "DOORBELL" | "NONE" | "OFF";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationChimeSoundField_zone$data = {
  readonly chimeSound: ZoneChimeSound | null;
  readonly number: string;
  readonly type: ZoneType;
  readonly " $fragmentType": "ZoneInformationChimeSoundField_zone";
};
export type ZoneInformationChimeSoundField_zone$key = {
  readonly " $data"?: ZoneInformationChimeSoundField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationChimeSoundField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationChimeSoundField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chimeSound",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "0d32850970b5b82c7d8369c3a17764fe";

export default node;
