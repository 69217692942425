App.factory("ModemUpgradeCustomersAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/Dealers/:dealer_id/:Type/:Select",
      {},
      {
        getModemUpgradeCustomers: {
          method: "GET",
          isArray: true,
          params: { dealer_id: "@dealer_id", Type: "ModemUpgradeCustomers" },
        },
        getModemUpgradeQualifiedCustomers: {
          method: "GET",
          isArray: true,
          params: {
            dealer_id: "@dealer_id",
            Type: "ModemUpgrade",
            Select: "QualifiedCustomers",
          },
        },
        batchCreateModemUpgradeCustomers: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            Type: "ModemUpgradeCustomers",
            Select: "Select",
          },
        },
      }
    );
  },
]);
