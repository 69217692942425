/**
 * @generated SignedSource<<735638305b754641c7bed1ecbfc0f8ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagEditCustomerListQuery$variables = {
  dealerId: string;
};
export type TagEditCustomerListQuery$data = {
  readonly getCustomerList: ReadonlyArray<{
    readonly controlSystemId: number | null;
    readonly controlSystemName: string | null;
    readonly customerId: number;
    readonly customerName: string;
    readonly siteId: number | null;
  } | null>;
};
export type TagEditCustomerListQuery = {
  response: TagEditCustomerListQuery$data;
  variables: TagEditCustomerListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dealerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "dealerId"
      }
    ],
    "concreteType": "CustomerListEntry",
    "kind": "LinkedField",
    "name": "getCustomerList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "controlSystemId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "controlSystemName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siteId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagEditCustomerListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagEditCustomerListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "abb15116e8f5433fd104b91ed752bcc1",
    "id": null,
    "metadata": {},
    "name": "TagEditCustomerListQuery",
    "operationKind": "query",
    "text": "query TagEditCustomerListQuery(\n  $dealerId: ID!\n) {\n  getCustomerList(id: $dealerId) {\n    customerId\n    customerName\n    controlSystemId\n    controlSystemName\n    siteId\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed891b2f9f700ddfc54e6d1b99121f1d";

export default node;
