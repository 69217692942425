import graphql from "babel-plugin-relay/macro";
import React from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../../app/common/DaColors";
import { AlertsContextProvider } from "../../contexts/AlertsContext";
import GenericSuspenseFallback from "../GenericSuspenseFallback";
import { KeypadUpdateButton } from "./KeypadUpdateButton";
import { KeypadsDealerQuery } from "./__generated__/KeypadsDealerQuery.graphql";

const KeypadsList: React.FC<
  React.PropsWithChildren<{
    dealerId: string;
  }>
> = ({ dealerId }) => {
  const data = useLazyLoadQuery<KeypadsDealerQuery>(
    graphql`
      query KeypadsDealerQuery($id: String!) {
        keypadsAPI(dealerId: $id) {
          customerName
          keypadSerial
          panelCommType
          recordNumber
          model
          firmwareVersion
          panelName
          panelId
          controlSystemId
          remoteUpdateTimestamp
        }
      }
    `,
    {
      id: dealerId,
    },
    { fetchPolicy: "store-and-network" }
  );

  return (
    <>
      {data?.keypadsAPI && data.keypadsAPI.length > 0 && (
        <div className="panel panel-table ">
          <div className="panel-title pad-l-24">Keypads</div>
          <div className="panel-body pad-b-24">
            <div className="table-responsive">
              {/*TODO: Will eventually need paging*/}
              <table className="display table table-striped table-hover table-responsive">
                <thead>
                  <tr>
                    <th>Device Name</th>
                    <th>Serial Number</th>
                    <th>Model</th>
                    <th>Current Firmware</th>
                    <th style={{ width: "31rem" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {data.keypadsAPI.map(
                    (keypad) =>
                      keypad && (
                        <tr key={window.crypto.randomUUID()}>
                          <td key="panel_name">{keypad.panelName}</td>
                          <td key="keypad_serial">{keypad.keypadSerial}</td>
                          <td key="model">{keypad.model}</td>
                          <td key="firmware_version">
                            {keypad.firmwareVersion}
                          </td>
                          <td>
                            <KeypadUpdateButton keypad={keypad} />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default function Keypads(props: any) {
  const environment = React.useMemo(
    () => props.RelayService.getEnvironment(),
    [props.RelayService]
  );

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <ThemeProvider theme={defaultTheme}>
        <React.Suspense fallback={<GenericSuspenseFallback />}>
          <AlertsContextProvider $rootScope={props.$rootScope}>
            <KeypadsList dealerId={String(props.UserService.dealer_id)} />
          </AlertsContextProvider>
        </React.Suspense>
      </ThemeProvider>
    </RelayEnvironmentProvider>
  );
}
