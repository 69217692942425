/**
 * @generated SignedSource<<24e3b8f51f8f839a093a95393e34b15e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationFirstApnField_communication$data = {
  readonly cellApn1: string;
  readonly " $fragmentType": "CommunicationFirstApnField_communication";
};
export type CommunicationFirstApnField_communication$key = {
  readonly " $data"?: CommunicationFirstApnField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationFirstApnField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationFirstApnField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cellApn1",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "357f3a94e5e4b43737f9a553109996fb";

export default node;
