/**
 * @generated SignedSource<<8b7ea1de7f980398f9e91070250eb931>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DealerContext_dealer$data = {
  readonly id: string;
  readonly " $fragmentType": "DealerContext_dealer";
};
export type DealerContext_dealer$key = {
  readonly " $data"?: DealerContext_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DealerContext_dealer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DealerContext_dealer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};

(node as any).hash = "3241c9b0f1662b4de1a58108c558f12a";

export default node;
