/**
 * @ngdoc API Resource
 * @name App.factory:SitesDapiAPI
 *
 * @description
 * API factory for Sites Information
 *
 */
App.factory("SitesDapiAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/sites/:siteId/:actionOn/:action",
      {},
      {
        getX1SimsInfo: {
          method: "GET",
          params: {
            siteId: "@siteId",
            actionOn: "sims",
            action: "search",
          },
        },
      }
    );
  },
]);
