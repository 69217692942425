import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsPowerFailDelayField_systemOptions$key } from "./__generated__/SystemOptionsPowerFailDelayField_systemOptions.graphql";

export const systemOptionsPowerFailDelayFieldId = () =>
  "system-options-power-fail-delay";

function PowerFailDelayField() {
  const [
    { powerFailDelay, powerFailDelayMin, powerFailDelayMax },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsPowerFailDelayField_systemOptions$key>(
    graphql`
      fragment SystemOptionsPowerFailDelayField_systemOptions on SystemOptions {
        powerFailDelay
        powerFailDelayMin
        powerFailDelayMax
      }
    `
  );

  const fieldId = systemOptionsPowerFailDelayFieldId();
  const originalValue = React.useRef(powerFailDelay).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Power Fail Delay"
      tooltip="Set the AC Power Fail Delay in hours. The System waits this amount of time before sending a power fail message to the Receiver."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={powerFailDelay?.toString()}
        required
        inlineHelp={`${powerFailDelayMin}–${powerFailDelayMax}`}
        min={powerFailDelayMin}
        max={powerFailDelayMax}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "powerFailDelay"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(powerFailDelayMin, powerFailDelayMax, valueAsNumber);
            recordProxy.setValue(
              target.value === "" ? 0 : Number(value),
              "powerFailDelay"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PowerFailDelayField;
