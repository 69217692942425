import { AddIcon } from "common/components/web/Icons";
import * as React from "react";
import styled from "styled-components";
import { focusRing } from "utils/styles";

const Button = styled.button`
  --bg-color: white;
  --border-color: var(--color-neutral-500);
  --text-color: var(--color-text-default);
  appearance: none;
  padding: 0.8rem 1.2rem;
  border-radius: 0.6rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: var(--bg-color);
  color: var(--text-color);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  white-space: nowrap;
  margin: 0rem 0.5rem;

  &:focus {
    ${focusRing};
  }

  &:not(:enabled) {
    cursor: not-allowed;
  }

  &:hover {
    --bg-color: var(--color-neutral-100);
  }
  &:active {
    --bg-color: var(--color-neutral-200);
  }
  &:not(:enabled) {
    --bg-color: var(--color-neutral-300);
    --border-color: var(--color-neutral-400);
    --text-color: var(--color-neutral-600);
  }
`;

const LoadingSpinner = styled.span.attrs({
  className: "csspinner traditional",
})`
  display: inline-block;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin-right: 0.25em;

  &::before {
    background-color: transparent;
  }
  &::after {
    width: 1em;
    height: 1em;
    margin: -0.5em 0 0 -0.5em;
    border-width: 2px !important;
  }
`;

export default function AddButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    loading?: boolean;
  }
) {
  const { children, loading, ...rest } = props;
  return (
    <Button
      type="button"
      disabled={loading}
      {...rest}
      className="styled-button"
    >
      {loading ? <LoadingSpinner /> : <AddIcon name="add" size="0.8em" />}
      &nbsp;
      <span>{children}</span>
    </Button>
  );
}
