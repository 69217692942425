import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, SystemReports } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import SystemReportsAbortReportsField from "../common/SystemReportsFields/SystemReportsAbortReportsField";
import SystemReportsAccessKeypadsField from "../common/SystemReportsFields/SystemReportsAccessKeypadsField";
import SystemReportsAmbushReportsField from "../common/SystemReportsFields/SystemReportsAmbushReportsField";
import SystemReportsBypassReportsField from "../common/SystemReportsFields/SystemReportsBypassReportsField";
import SystemReportsCodeChangeReportsField from "../common/SystemReportsFields/SystemReportsCodeChangeReportsField";
import { SystemReportsContextProvider } from "../common/SystemReportsFields/SystemReportsContext";
import SystemReportsEarlyToCloseField from "../common/SystemReportsFields/SystemReportsEarlyToCloseField";
import SystemReportsLateToOpenField from "../common/SystemReportsFields/SystemReportsLateToOpenField";
import SystemReportsScheduleChangeReportsField from "../common/SystemReportsFields/SystemReportsScheduleChangeReportsField";
import SystemReportsVideoReportsField from "../common/SystemReportsFields/SystemReportsVideoReportsField";
import SystemReportsZoneRestoralReportsField from "../common/SystemReportsFields/SystemReportsZoneRestoralReportsField";
import { panelVersionGTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XFSystemReportsProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XFSystemReportsProgrammingConceptFormInline_controlSystem.graphql";
import { XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XFSystemReportsProgrammingConceptFormSystemReportsRefreshMutation,
} from "./__generated__/XFSystemReportsProgrammingConceptFormSystemReportsRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XFSystemReportsProgrammingConceptFormSystemReportsSendMutation,
  XFSystemReportsProgrammingConceptFormSystemReportsSendMutation$data,
} from "./__generated__/XFSystemReportsProgrammingConceptFormSystemReportsSendMutation.graphql";
import { XFSystemReportsProgrammingConceptForm_controlSystem$key } from "./__generated__/XFSystemReportsProgrammingConceptForm_controlSystem.graphql";

export const title = "System Reports";
export const conceptId = "xf-system-reports";

export const getState = (
  controlSystem: XFSystemReportsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFSystemReportsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          systemReports {
            id
            abortReports
            accessKeypads
            ambushReports
            bypassReports
            codeChangeReports
            earlyToClose
            earlyToCloseMin
            earlyToCloseMax
            lateToOpen
            lateToOpenMin
            lateToOpenMax
            scheduleChangeReports
            videoSystemReports
            zoneRestoralReports
            openCloseEnable
            sendStoredMessage
            entryCheckinProtection
          }
        }
      }
    `,
    controlSystem
  );

const retrieveMutation = graphql`
  mutation XFSystemReportsProgrammingConceptFormSystemReportsRefreshMutation(
    $id: ID!
  ) {
    refreshSystemReports(id: $id) {
      ... on RefreshSystemReportsSuccessPayload {
        __typename
        controlSystem {
          ...XFSystemReportsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XFSystemReportsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XFSystemReportsProgrammingConceptFormSystemReportsRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        retrieve({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshSystemReports;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "System Reports Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshSystemReports: {
                    __typename: response.refreshSystemReports.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve System Reports: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve System Reports",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XFSystemReportsProgrammingConceptFormSystemReportsSendMutation(
    $systemId: ID!
    $systemReports: SystemReportsInput!
  ) {
    sendSystemReports(systemId: $systemId, systemReports: $systemReports) {
      ... on SendSystemReportsSuccessPayload {
        __typename
        controlSystem {
          id
          ...XFSystemReportsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendSystemReportsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XFSystemReportsProgrammingConceptFormSystemReportsSendMutation$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendSystemReports.controlSystem) {
    // Update original data store
    const operation = createOperationDescriptor(sendMutationConcreteRequest, {
      id: response.sendSystemReports.controlSystem.id,
    });
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        sendSystemReports: {
          __typename: response.sendSystemReports.__typename,
          controlSystem: getState(response.sendSystemReports.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFSystemReportsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XFSystemReportsProgrammingConceptFormSystemReportsSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { systemReports },
        } = getState(props.controlSystem);
        if (systemReports) {
          save({
            variables: {
              systemId,
              systemReports: {
                abortReports: systemReports.abortReports,
                accessKeypads: systemReports.accessKeypads,
                ambushReports: systemReports.ambushReports,
                bypassReports: systemReports.bypassReports,
                codeChangeReports: systemReports.codeChangeReports,
                earlyToClose: systemReports.earlyToClose,
                lateToOpen: systemReports.lateToOpen,
                scheduleChangeReports: systemReports.scheduleChangeReports,
                videoSystemReports: systemReports.videoSystemReports,
                zoneRestoralReports: systemReports.zoneRestoralReports,
                openCloseEnable: systemReports.openCloseEnable,
                sendStoredMessage: systemReports.sendStoredMessage,
                entryCheckinProtection: systemReports.entryCheckinProtection,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendSystemReports.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved to the System`,
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(response, parentRelayEnv);
              } else if (response.sendSystemReports.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendSystemReports.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSaving,
  ];
};

const readSystemReportsTemplateData = (
  programmingTemplateConcepts: XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        systemReports {
          included
          abortReports {
            included
            data
          }
          accessKeypads {
            included
            data
          }
          ambushReports {
            included
            data
          }
          bypassReports {
            included
            data
          }
          codeChangeReports {
            included
            data
          }
          earlyToClose {
            included
            data
          }
          lateToOpen {
            included
            data
          }
          scheduleChangeReports {
            included
            data
          }
          videoSystemReports {
            included
            data
          }
          zoneRestoralReports {
            included
            data
          }
          openCloseEnable {
            included
            data
          }
          sendStoredMessage {
            included
            data
          }
          entryCheckinProtection {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).systemReports ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XFSystemReportsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readSystemReportsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const systemReportsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "systemReports",
      "SystemReports"
    ) as unknown as RecordProxy<SystemReports>;

    applyTemplateScalarDataToRecordProxy(
      systemReportsRecordProxy,
      templateData
    );
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFSystemReportsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFSystemReportsProgrammingConceptForm_controlSystem on ControlSystem {
          panel {
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            systemReports {
              ...SystemReportsContext_systemReports
              ...SystemReportsAbortReportsField_systemReports
              ...SystemReportsBypassReportsField_systemReports
              ...SystemReportsScheduleChangeReportsField_systemReports
              ...SystemReportsCodeChangeReportsField_systemReports
              ...SystemReportsAmbushReportsField_systemReports
              ...SystemReportsVideoReportsField_systemReports
              ...SystemReportsZoneRestoralReportsField_systemReports
              ...SystemReportsAccessKeypadsField_systemReports
              ...SystemReportsLateToOpenField_systemReports
              ...SystemReportsEarlyToCloseField_systemReports
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    panel: {
      softwareVersion,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;
  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        title={title}
        helpLink={`${programmingGuideUrl}#System%20Reports`}
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.systemReports
        )}
      >
        <RemountOnUpdateContainer nodeId={conceptId}>
          <SystemReportsContextProvider
            systemReports={controlSystem.panel.systemReports}
          >
            <ProgrammingConceptForm.Fields>
              <SystemReportsAbortReportsField />
              <SystemReportsZoneRestoralReportsField />
              <SystemReportsBypassReportsField />
              <SystemReportsScheduleChangeReportsField />
              <SystemReportsCodeChangeReportsField />
              <SystemReportsAmbushReportsField />
              <SystemReportsAccessKeypadsField />
              {panelVersionGTOE(172, softwareVersion) && (
                <>
                  <SystemReportsLateToOpenField />
                  <SystemReportsEarlyToCloseField />
                </>
              )}
              {panelVersionGTOE(193, softwareVersion) && (
                <SystemReportsVideoReportsField />
              )}
            </ProgrammingConceptForm.Fields>
          </SystemReportsContextProvider>
        </RemountOnUpdateContainer>
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}
