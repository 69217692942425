import {
  Document as _Document,
  Page as _Page,
  StyleSheet,
  Text,
  View as _View,
} from "@react-pdf/renderer";
import * as React from "react";

function componentWithChildren<Props>(Component: React.ComponentType<Props>) {
  /**https://github.com/diegomura/react-pdf/issues/1779
   * This work around was needed to upgrade to React 18.  This pdf plugin did not address the breaking change for implicit children for the react18 types
   * **/
  return Component as React.ComponentType<
    Props & { children: React.ReactNode }
  >;
}
const Document = componentWithChildren(_Document);
const Page = componentWithChildren(_Page);
const View = componentWithChildren(_View);

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 21,
    textAlign: "center",
    marginBottom: 24,
    fontWeight: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  strong: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "auto",
    borderRadius: 6,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "grey",
    width: "100%",
  },
  tableHeaderRow: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomWidth: 2,
    borderBottomColor: "grey",
  },
  tableCol: {
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
  },
  tableHeaderCol: {
    justifyContent: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  tableCell: {
    fontSize: 8,
    fontWeight: "normal",
  },
  tableHeaderCell: {
    textTransform: "uppercase",
    color: "grey",
    letterSpacing: 0.75,
  },
});

function Pdf({
  title,
  columnTitles,
  data,
  widthPercentages,
}: {
  title: string;
  columnTitles: string[];
  data: string[][];
  widthPercentages: number[];
}) {
  return (
    <Document
      title={title}
      creator="dealer.securecomwireless.com"
      producer="dealer.securecomwireless.com"
    >
      <Page style={styles.body}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeaderRow]}>
            {columnTitles.map((column, index) => (
              <View
                key={column}
                style={[
                  styles.tableCol,
                  styles.tableHeaderCol,
                  { width: `${widthPercentages[index]}%` },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableHeaderCell,
                    styles.strong,
                  ]}
                >
                  {column}
                </Text>
              </View>
            ))}
          </View>
          {data.map((datum, rowIndex) => (
            <View style={styles.tableRow} key={rowIndex}>
              {datum.map((subDatum, index) => (
                <View
                  style={[
                    styles.tableCol,
                    { width: `${widthPercentages[index]}%` },
                  ]}
                >
                  <Text style={[styles.tableCell]}>{subDatum}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

export default Pdf;
