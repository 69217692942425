/**
 * @generated SignedSource<<19187ee84960b345d25f867a3c86959f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsDeviceFailOutputField_outputOptions$data = {
  readonly deviceFailOutput: {
    readonly formattedNumber: string;
  };
  readonly id: string;
  readonly " $fragmentType": "OutputOptionsDeviceFailOutputField_outputOptions";
};
export type OutputOptionsDeviceFailOutputField_outputOptions$key = {
  readonly " $data"?: OutputOptionsDeviceFailOutputField_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsDeviceFailOutputField_outputOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsDeviceFailOutputField_outputOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceFailOutput",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};

(node as any).hash = "8d4208690069a58ad782f7b04920001b";

export default node;
