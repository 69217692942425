import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputInformationFragment } from "./OutputInformationContext";
import { serialNumberIsValidWirelessBell } from "./utils";
import { OutputInformationTripWithPanelBellField_output$key } from "./__generated__/OutputInformationTripWithPanelBellField_output.graphql";

export const outputInformationTripWithPanelBellFieldId = (number: string) =>
  `output-information-trip-with-panel-bell-${number}`;

function OutputInformationTripWithPanelBellField() {
  const [
    { number, tripWithPanelBell, serialNumber, isWireless },
    updateOutputInformation,
  ] = useOutputInformationFragment<OutputInformationTripWithPanelBellField_output$key>(
    graphql`
      fragment OutputInformationTripWithPanelBellField_output on Output {
        number
        tripWithPanelBell
        serialNumber
        isWireless
      }
    `
  );

  const fieldId = outputInformationTripWithPanelBellFieldId(String(number));
  const validSerial = serialNumberIsValidWirelessBell(serialNumber ?? "");
  const disabled = !validSerial || !isWireless;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Trip With Panel Bell"
      disabled={disabled}
      tooltip="Force the 1135 wireless siren to follow the System's Bell Output."
    >
      <Switch
        id={fieldId}
        label="Trip With Panel Bell"
        checked={tripWithPanelBell && validSerial}
        disabled={disabled}
        onChange={() => {
          updateOutputInformation((recordProxy) => {
            recordProxy.setValue(!tripWithPanelBell, "tripWithPanelBell");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputInformationTripWithPanelBellField;
