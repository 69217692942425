import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  fromControlSystemId,
  fromKeyfobId,
  idAsString,
  Keyfob,
  Panel,
  toGlobalId,
  toKeyfobId,
} from "securecom-graphql/client";
import ActiveConceptContext from "../common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "../common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import KeyfobFields from "../common/KeyfobFields";
import {
  keyfobListItemTemplateId,
  KEYFOB_IDS,
} from "../common/KeyfobFields/KeyfobNumberField";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { useOriginalControlSystem } from "../common/OriginalControlSystemContext";
import { useProgrammingActionsContext } from "../common/ProgrammingContext";
import { useTemplateContext } from "../common/TemplateContext";
import { useUncheckListItem } from "../Templates/utils";
import { removeListItemFromStore } from "../utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "../utils/templates";
import {
  XRKeyFobsProgrammingConceptFormInline_controlSystem$data,
  XRKeyFobsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XRKeyFobsProgrammingConceptFormInline_controlSystem.graphql";
import {
  XRKeyFobsProgrammingConceptFormInline_keyfob$data,
  XRKeyFobsProgrammingConceptFormInline_keyfob$key,
} from "./__generated__/XRKeyFobsProgrammingConceptFormInline_keyfob.graphql";
import { XRKeyFobsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRKeyFobsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XRKeyFobsProgrammingConceptFormKeyfobDeleteMutation } from "./__generated__/XRKeyFobsProgrammingConceptFormKeyfobDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XRKeyFobsProgrammingConceptFormKeyfobRefreshMutation,
} from "./__generated__/XRKeyFobsProgrammingConceptFormKeyfobRefreshMutation.graphql";
import {
  XRKeyFobsProgrammingConceptFormKeyfobSendMutation,
  XRKeyFobsProgrammingConceptFormKeyfobSendMutation$data,
} from "./__generated__/XRKeyFobsProgrammingConceptFormKeyfobSendMutation.graphql";
import { XRKeyFobsProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XRKeyFobsProgrammingConceptFormNavButton_controlSystem.graphql";
import { XRKeyFobsProgrammingConceptForm_controlSystem$key } from "./__generated__/XRKeyFobsProgrammingConceptForm_controlSystem.graphql";

export const title = "Key Fobs";
export const conceptId = "xr-key-fobs";

export const getState = (
  controlSystem: XRKeyFobsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRKeyFobsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        panel {
          __typename
          id
          keyfobs {
            __typename
            id
            number
            isNew
            ...XRKeyFobsProgrammingConceptFormInline_keyfob
          }
        }
      }
    `,
    controlSystem
  );

export const getKeyfobState = (
  keyfob: XRKeyFobsProgrammingConceptFormInline_keyfob$key
) =>
  readInlineData(
    graphql`
      fragment XRKeyFobsProgrammingConceptFormInline_keyfob on Keyfob @inline {
        __typename
        id
        number
        keyfobSerialNumber
        numberOfButtons
        keyfobSupervisionTime
        buttonOneAction
        buttonTwoAction
        buttonThreeAction
        buttonFourAction
        buttonOneOutputAction
        buttonTwoOutputAction
        buttonThreeOutputAction
        buttonFourOutputAction
        buttonOneOutput
        buttonTwoOutput
        buttonThreeOutput
        buttonFourOutput
        buttonOnePressTime
        buttonTwoPressTime
        buttonThreePressTime
        buttonFourPressTime
        buttonOneAreas
        buttonTwoAreas
        buttonThreeAreas
        buttonFourAreas
        user {
          __typename
          id
          number
          name
        }
        isNew
      }
    `,
    keyfob
  );

const deleteMutation = graphql`
  mutation XRKeyFobsProgrammingConceptFormKeyfobDeleteMutation($keyfobId: ID!) {
    deleteKeyfob(keyfobId: $keyfobId) {
      ... on DeleteKeyfobSuccessPayload {
        __typename
        deletedKeyfobId
      }
      ... on FailedToRemoveKeyfobErrorPayload {
        error: type
      }
    }
  }
`;

const refreshMutation = graphql`
  mutation XRKeyFobsProgrammingConceptFormKeyfobRefreshMutation($id: ID!) {
    refreshKeyfobs(id: $id) {
      ... on RefreshKeyfobsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XRKeyFobsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XRKeyFobsProgrammingConceptFormKeyfobSendMutation(
    $systemId: ID!
    $keyfobs: [KeyfobProgrammingInput!]!
  ) {
    sendKeyfobsProgramming(systemId: $systemId, keyfobs: $keyfobs) {
      ... on Error {
        type
      }
      ... on SendKeyfobsProgrammingSuccessPayload {
        results {
          __typename
          ... on SendKeyfobsProgrammingKeyfobSuccessPayload {
            keyfob {
              __typename
              id
              number
              ...XRKeyFobsProgrammingConceptFormInline_keyfob
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XRKeyFobsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshKeyfobs, isRefreshing] =
    useMutation<XRKeyFobsProgrammingConceptFormKeyfobRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshKeyfobs({
          variables: {
            id,
          },
          onCompleted: (response) => {
            const { controlSystem, __typename, error } =
              response.refreshKeyfobs;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Key Fobs Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshKeyfobs: {
                    __typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Key Fobs: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Key Fobs",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

const mergeOldAndNewKeyfobs = (
  response: XRKeyFobsProgrammingConceptFormKeyfobSendMutation$data,
  originalControlSystemData: XRKeyFobsProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendKeyfobsProgramming.results) {
    const successfulKeyfobs = response.sendKeyfobsProgramming.results
      .map((keyfob) => {
        if (
          keyfob.__typename === "SendKeyfobsProgrammingKeyfobSuccessPayload"
        ) {
          return keyfob;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.keyfob)
      .map(getKeyfobState);

    const mergedKeyfobsMap = new Map<
      string,
      XRKeyFobsProgrammingConceptFormInline_keyfob$data
    >();

    originalControlSystemData.panel.keyfobs
      .map(getKeyfobState)
      .forEach((item) => mergedKeyfobsMap.set(item.id, item));

    successfulKeyfobs.forEach((item) =>
      mergedKeyfobsMap.set(item.id, {
        ...mergedKeyfobsMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedKeyfobsMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XRKeyFobsProgrammingConceptFormKeyfobSendMutation$data,
  originalControlSystemData: XRKeyFobsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedKeyfobs = mergeOldAndNewKeyfobs(
    response,
    originalControlSystemData
  );

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshKeyfobs: {
        __typename: "RefreshKeyfobsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            outputs: mergedKeyfobs,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XRKeyFobsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendKeyfobs, isSendingKeyfobs] =
    useMutation<XRKeyFobsProgrammingConceptFormKeyfobSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedKeyfobs = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { keyfobs },
        } = getState(props.controlSystem);
        sendKeyfobs({
          variables: {
            systemId,
            keyfobs: keyfobs
              .filter(
                (keyfob) =>
                  keyfob.isNew ||
                  (!!changedKeyfobs &&
                    listItemHasChanged(keyfob.id, changedKeyfobs)) ||
                  isSavingAllListItems
              )
              .map(getKeyfobState)
              .map((keyfob) => ({
                id: keyfob.id,
                number: keyfob.number,
                userNumber: keyfob.user?.number ?? 0,
                buttonOneAction: keyfob.buttonOneAction,
                buttonTwoAction: keyfob.buttonTwoAction,
                buttonThreeAction: keyfob.buttonThreeAction,
                buttonFourAction: keyfob.buttonFourAction,
                buttonOneAreas: keyfob.buttonOneAreas,
                buttonTwoAreas: keyfob.buttonTwoAreas,
                buttonThreeAreas: keyfob.buttonThreeAreas,
                buttonFourAreas: keyfob.buttonFourAreas,
                numberOfButtons: keyfob.numberOfButtons,
                keyfobSerialNumber: keyfob.keyfobSerialNumber,
                buttonOneOutputAction: keyfob.buttonOneOutputAction,
                buttonTwoOutputAction: keyfob.buttonTwoOutputAction,
                buttonThreeOutputAction: keyfob.buttonThreeOutputAction,
                buttonFourOutputAction: keyfob.buttonFourOutputAction,
                buttonOneOutput: keyfob.buttonOneOutput,
                buttonTwoOutput: keyfob.buttonTwoOutput,
                buttonThreeOutput: keyfob.buttonThreeOutput,
                buttonFourOutput: keyfob.buttonFourOutput,
                buttonOnePressTime: keyfob.buttonOnePressTime,
                buttonTwoPressTime: keyfob.buttonTwoPressTime,
                buttonThreePressTime: keyfob.buttonThreePressTime,
                buttonFourPressTime: keyfob.buttonFourPressTime,
                keyfobSupervisionTime: keyfob.keyfobSupervisionTime,
                isNew: keyfob.isNew,
              })),
          },
          onCompleted: (response) => {
            const saveErrors: SaveErrors = [];
            if (response.sendKeyfobsProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendKeyfobsProgramming.results) {
              response.sendKeyfobsProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendKeyfobsProgrammingKeyfobSuccessPayload"
                ) {
                  resetLastUpdated(response.keyfob.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  saveErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!saveErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
            }
            resolve(saveErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSendingKeyfobs,
  ];
};

const readKeyfobsTemplateData = (
  programmingTemplateConcepts: XRKeyFobsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRKeyFobsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        keyfobs {
          id
          included
          number
          userNumber {
            included
            data
          }
          buttonOneAction {
            included
            data
          }
          buttonTwoAction {
            included
            data
          }
          buttonThreeAction {
            included
            data
          }
          buttonFourAction {
            included
            data
          }
          buttonOneAreas {
            included
            data
          }
          buttonTwoAreas {
            included
            data
          }
          buttonThreeAreas {
            included
            data
          }
          buttonFourAreas {
            included
            data
          }
          numberOfButtons {
            included
            data
          }
          buttonOneOutputAction {
            included
            data
          }
          buttonTwoOutputAction {
            included
            data
          }
          buttonThreeOutputAction {
            included
            data
          }
          buttonFourOutputAction {
            included
            data
          }
          buttonOneOutput {
            included
            data
          }
          buttonTwoOutput {
            included
            data
          }
          buttonThreeOutput {
            included
            data
          }
          buttonFourOutput {
            included
            data
          }
          buttonOnePressTime {
            included
            data
          }
          buttonTwoPressTime {
            included
            data
          }
          buttonThreePressTime {
            included
            data
          }
          buttonFourPressTime {
            included
            data
          }
          keyfobSupervisionTime {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).keyfobs;

export function applyTemplateData(
  programmingTemplateConcepts: XRKeyFobsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);

  const keyfobRecordProxies =
    panelRecordProxy.getLinkedRecords("keyfobs") ?? [];
  const keyfobsByNumber = indexRecordProxiesByNumber(keyfobRecordProxies);

  const keyfobsTemplateData =
    readKeyfobsTemplateData(programmingTemplateConcepts) ?? [];

  keyfobsTemplateData.forEach((keyfobTemplateData) => {
    if (keyfobTemplateData?.included) {
      let keyfobRecordProxy = keyfobsByNumber.get(keyfobTemplateData.number);
      if (!keyfobRecordProxy) {
        const newKeyfobId = applyNewKeyfobToKeyfobsList(
          {
            id: panelRecordProxy.getValue("id"),
            keyfobsRange: panelRecordProxy.getValue("keyfobsRange") ?? null,
            newKeyfob: panelRecordProxy.getLinkedRecord("newKeyfob") && {
              id: panelRecordProxy.getLinkedRecord("newKeyfob").getValue("id"),
            },
            keyfobs: keyfobRecordProxies.map((recordProxy) => ({
              number: recordProxy.getValue("number"),
            })),
          },
          store
        );
        if (newKeyfobId) {
          keyfobRecordProxy = store.get(newKeyfobId) as RecordProxy<Keyfob>;
          if (keyfobRecordProxy) {
            keyfobRecordProxy.setValue(keyfobTemplateData.number, "number");
          }
        }
      }

      if (!keyfobRecordProxy) {
        return;
      }

      applyTemplateScalarDataToRecordProxy(
        keyfobRecordProxy,
        keyfobTemplateData
      );

      if (!keyfobsByNumber.has(keyfobTemplateData.number)) {
        keyfobsByNumber.set(keyfobTemplateData.number, keyfobRecordProxy);
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(keyfobsByNumber),
    "keyfobs"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XRKeyFobsProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XRKeyFobsProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            keyfobs {
              isNew
            }
          }
        }
      `
    );
  const { keyfobs } = controlSystem.panel;
  const itemsCount = keyfobs.length;
  const hasNewItems = itemsCount > 0 && keyfobs.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRKeyFobsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRKeyFobsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedKeyfob {
            id
          }
          panel {
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...KeyfobNumberField_panel
            keyfobsRange
            maxKeyfobs
            keyfobs {
              id
              number
              keyfobSerialNumber
              isNew
              ...KeyfobFields_keyfob
            }
            newKeyfob {
              id
              number
              keyfobSerialNumber
              ...KeyfobFields_keyfob
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseKeyfobNumberRange_panel
            ...PanelContextUseUserList_panel
            ...PanelContextUseSystemOptionsSystemType_panel
            ...KeyfobSerialNumberField_SerialNumberList_panel
          }
          ...ControlSystemContext_controlSystem
          ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
        }
      `
    );
  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(KEYFOB_IDS);
  const {
    keyfobs,
    newKeyfob,
    helpFiles: { programmingGuideUrl },
  } = controlSystem.panel;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    keyfobs[0]?.id ?? null
  );

  const [deleteKeyfob, isDeleting] =
    useMutation<XRKeyFobsProgrammingConceptFormKeyfobDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();

  const parentRelayEnv = useParentRelayEnvironment();
  const [activeConcept] = React.useContext(ActiveConceptContext);
  const templateContext = useTemplateContext();
  const {
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;

  const removeSelectedKeyfob = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.keyfobs.findIndex(
          (keyfobs) => keyfobs.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.keyfobs.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return controlSystem.panel.keyfobs[newSelectedIndex]?.id ?? null;
      });
      const keyfob = keyfobs.find((keyfob) => keyfob.id === selectedListItemId);
      uncheckListItem(String(keyfob?.number));
      if (keyfob?.isNew) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "keyfobs",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Keyfob",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            keyfob?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteKeyfob({
          variables: {
            keyfobId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "keyfobs",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedKeyfobId } = response.deleteKeyfob;
            if (deletedKeyfobId) {
              showAlert({
                type: "success",
                text: "Keyfob Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "keyfobs",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedKeyfobId, error } = response.deleteKeyfob;
            if (deletedKeyfobId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedKeyfobId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "keyfobs",
                      controlSystem.panel.id,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Keyfob: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Keyfob",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Keyfob",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const canAdd = availableNumbers.size > 0 && !!newKeyfob;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Zone%20Information`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.keyfobs
        )}
        amountAvailable={availableNumbers.size}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newKeyfobId = applyNewKeyfobToKeyfobsList(
                  controlSystem.panel,
                  store
                );
                if (newKeyfobId) {
                  setSelectedListItemId(newKeyfobId);
                }
              });
            }}
          >
            Add Keyfob
          </ProgrammingConceptForm.AddButton>
        }
      >
        {(conceptId === activeConcept ||
          templateContext.isApplying ||
          isSavingAll) && (
          <ProgrammingConceptForm.ListItemsContainer>
            <ProgrammingConceptForm.ListItemPicker
              selectedId={selectedListItemId}
              onChange={(id) => {
                setSelectedListItemId(id);
              }}
              newItemId={newKeyfob?.id}
              items={keyfobs.map((keyfob) => ({
                id: keyfob.id,
                templateListItemId: keyfobListItemTemplateId(
                  String(keyfob.number)
                ),
                isnew: keyfob.isNew,
                label: `#${keyfob.number} SN:${keyfob.keyfobSerialNumber}`,
              }))}
            />
            <ProgrammingConceptForm.SelectedItemsContainer
              selectedListItemId={selectedListItemId}
              setSelectedListItemId={setSelectedListItemId}
            >
              {keyfobs.map((keyfob) => (
                <RemountOnUpdateContainer nodeId={keyfob.id}>
                  <ProgrammingConceptForm.SelectedItem
                    conceptId={conceptId}
                    isnew={keyfob.isNew}
                    visible={keyfob.id === selectedListItemId}
                    key={keyfob.id}
                    listItemId={keyfob.id}
                    templateListItemId={keyfobListItemTemplateId(
                      String(keyfob.number)
                    )}
                    title={`# ${keyfob.number} Serial No: ${keyfob.keyfobSerialNumber}`}
                    onDuplicate={
                      canAdd &&
                      (() => {
                        relayEnv.commitUpdate((store) => {
                          const duplicateId =
                            applyDuplicatedKeyfobToKeyfobsList(
                              selectedListItemId,
                              controlSystem.panel,
                              store
                            );
                          if (duplicateId) {
                            setSelectedListItemId(duplicateId);
                          }
                        });
                      })
                    }
                    onCopy={() => {
                      relayEnv.commitUpdate((store) => {
                        const controlSystemRecord = store.get(controlSystem.id);
                        const keyfobRecord =
                          store.get<Keyfob>(selectedListItemId);
                        if (controlSystemRecord && keyfobRecord) {
                          const tempRecord =
                            store.get("copiedKeyfob") ??
                            store.create("copiedKeyfob", "Keyfob");
                          tempRecord.copyFieldsFrom(keyfobRecord);
                          controlSystemRecord.setLinkedRecord(
                            tempRecord,
                            "copiedKeyfob"
                          );
                        }
                      });
                    }}
                    onPaste={
                      !!controlSystem.copiedKeyfob &&
                      (() => {
                        relayEnv.commitUpdate((store) => {
                          const keyfobRecord =
                            store.get<Keyfob>(selectedListItemId);
                          const copiedKeyfobRecord =
                            store.get<Keyfob>("copiedKeyfob");
                          if (keyfobRecord && copiedKeyfobRecord) {
                            applyKeyfobProgrammingToKeyfob(
                              copiedKeyfobRecord,
                              keyfobRecord
                            );
                          }
                        });
                      })
                    }
                    onRemove={removeSelectedKeyfob}
                  >
                    <KeyfobFields key={keyfob.id} keyfob={keyfob} />
                  </ProgrammingConceptForm.SelectedItem>
                </RemountOnUpdateContainer>
              ))}
            </ProgrammingConceptForm.SelectedItemsContainer>
          </ProgrammingConceptForm.ListItemsContainer>
        )}
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewKeyfobToKeyfobsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newKeyfob: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newKeyfob } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newKeyfobTemplate = panelRecord.getLinkedRecord("newKeyfob");
    if (newKeyfob) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromKeyfobId(asID(newKeyfobTemplate.getDataID()));
        const nextNewKeyfobId = idAsString(toKeyfobId(systemId, nextNumber));
        const nextNewKeyfob = store.create(
          nextNewKeyfobId,
          "Keyfob"
        ) as RecordProxy<Keyfob>;
        nextNewKeyfob.copyFieldsFrom(newKeyfobTemplate);
        nextNewKeyfob.setValue(nextNewKeyfobId, "id");
        nextNewKeyfob.setValue(nextNumber, "number");
        nextNewKeyfob.setValue(true, "isNew");

        const keyfobRecords = panelRecord.getLinkedRecords("keyfobs") ?? [];
        panelRecord.setLinkedRecords(
          [...keyfobRecords, nextNewKeyfob],
          "keyfobs"
        );

        return nextNewKeyfob.getValue("id");
      }
    }
  }
};

const applyDuplicatedKeyfobToKeyfobsList = (
  keyfobID: string,
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newKeyfob: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newKeyfob } = panel;
  const panelRecord = store.get<Panel>(id);
  const keyfobRecord = store.get<Keyfob>(keyfobID);
  if (panelRecord && keyfobRecord) {
    const newKeyfobTemplate = panelRecord.getLinkedRecord("newKeyfob");
    if (newKeyfob) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromKeyfobId(asID(newKeyfobTemplate.getDataID()));
        const nextNewKeyfobId = idAsString(toKeyfobId(systemId, nextNumber));
        const nextNewKeyfob = store.create(
          nextNewKeyfobId,
          "Keyfob"
        ) as RecordProxy<Keyfob>;
        nextNewKeyfob.copyFieldsFrom(newKeyfobTemplate);
        nextNewKeyfob.setValue(nextNewKeyfobId, "id");
        nextNewKeyfob.setValue(nextNumber, "number");
        nextNewKeyfob.setValue(true, "isNew");

        const duplicatedKeyfobRecord = applyKeyfobProgrammingToKeyfob(
          keyfobRecord,
          nextNewKeyfob
        );
        const keyfobRecords = panelRecord.getLinkedRecords("keyfobs") ?? [];
        panelRecord.setLinkedRecords(
          [...keyfobRecords, duplicatedKeyfobRecord],
          "keyfobs"
        );

        return duplicatedKeyfobRecord.getValue("id");
      }
    }
  }
};

const applyKeyfobProgrammingToKeyfob = (
  source: RecordProxy<Keyfob>,
  dest: RecordProxy<Keyfob>
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  const isNew = dest.getValue("isNew");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  return dest;
};

const getAvailableNumbers = (panel: {
  readonly keyfobsRange: ReadonlyArray<number> | null;
  readonly keyfobs: ReadonlyArray<{ readonly number: number }>;
}) => {
  const allPossibleNumbers = new Set(panel.keyfobsRange);

  const takenNumbers = new Set(
    panel.keyfobs.map(({ number }) => Number(number))
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));
