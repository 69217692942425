/**
 * @generated SignedSource<<60caed668664eee5c8457bc2e98d6ed8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRZoneInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly id: string;
    readonly totalWirelessZoneMax: number;
    readonly totalZonesMax: number;
    readonly zoneInformations: ReadonlyArray<{
      readonly __typename: "Zone";
      readonly id: string;
      readonly isNew: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"XRZoneInformationProgrammingConceptFormInline_zone">;
    }>;
    readonly zoneNumberRange: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "XRZoneInformationProgrammingConceptFormInline_controlSystem";
};
export type XRZoneInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRZoneInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRZoneInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRZoneInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "b40d3bfb2b73837c02b45e27ff94ca27";

export default node;
