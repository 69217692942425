/**
 * @generated SignedSource<<8c58621c693c252841cef40d690a8f00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemReportsScheduleChangeReportsField_systemReports$data = {
  readonly scheduleChangeReports: boolean;
  readonly " $fragmentType": "SystemReportsScheduleChangeReportsField_systemReports";
};
export type SystemReportsScheduleChangeReportsField_systemReports$key = {
  readonly " $data"?: SystemReportsScheduleChangeReportsField_systemReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemReportsScheduleChangeReportsField_systemReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemReportsScheduleChangeReportsField_systemReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleChangeReports",
      "storageKey": null
    }
  ],
  "type": "SystemReports",
  "abstractKey": null
};

(node as any).hash = "43fa17b4c2906fb9a9790ed44d903337";

export default node;
