import * as React from "react";
import StatusIndicators from "../StatusIndicators";
import styled from "styled-components";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useTheme } from "styled-components";

import {
  CheckmarkIcon,
  AttentionIcon,
  RadialBanIcon,
  BatteryIcon,
  CircleCancelIcon,
  ArmedShieldSolidIcon,
} from "../Icons";

const StyledStatusIndicator = styled(StatusIndicators)`
  display: inline-block;
  margin-right: 0.35em;
`;

const MissingIcon = () => <CircleCancelIcon color={useTheme().danger} />;

const LowBatteryIcon = () => <BatteryIcon level={25} />;

const BypassedIcon = () => <RadialBanIcon color={useTheme().warning} />;

const NormalIcon = () => <CheckmarkIcon color={useTheme().success} />;

const ShortIcon = () => <AttentionIcon color={useTheme().warning} />;

const OpenIcon = () => <AttentionIcon color={useTheme().warning} />;

const DefaultIcon = () => <CircleCancelIcon color={useTheme().danger} />;

const getStatusIcon = (status: ZoneStatus) => {
  switch (status) {
    case "MISSING":
      return MissingIcon;
    case "LOW_BATTERY":
      return LowBatteryIcon;
    case "BYPASSED":
      return BypassedIcon;
    case "NORMAL":
      return NormalIcon;
    case "SHORT":
      return ShortIcon;
    case "OPEN":
      return OpenIcon;
    default:
      return DefaultIcon;
  }
};

type Props = {
  zone: any;
  isBypassingZone: boolean;
  isResetBypassingZone: boolean;
};

const ZoneStatusIndicator = (props: Props) => {
  const data = useFragment(
    graphql`
      fragment ZoneStatusIndicator_zone on Zone {
        status
      }
    `,
    props.zone
  );
  if (!data) {
    return null;
  } else {
    return (
      <StyledStatusIndicator
        loading={props.isBypassingZone || props.isResetBypassingZone}
        fallback={getStatusIcon(data.status)}
      />
    );
  }
};

export default ZoneStatusIndicator;
