import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useMessagingSetupFragment } from "./MessagingSetupContext";
import { MessagingSetupSystemNameField_messagingSetup$key } from "./__generated__/MessagingSetupSystemNameField_messagingSetup.graphql";

export const messagingSetupSystemNameFieldId = () =>
  "messaging-setup-system-name";

function MessagingSetupSystemNameField() {
  const [{ systemName, enableMessaging }, updateMessagingSetup] =
    useMessagingSetupFragment<MessagingSetupSystemNameField_messagingSetup$key>(
      graphql`
        fragment MessagingSetupSystemNameField_messagingSetup on MessagingSetup {
          systemName
          enableMessaging
        }
      `
    );

  const fieldId = messagingSetupSystemNameFieldId();
  const disabled = !enableMessaging;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="System Name"
      disabled={disabled}
      tooltip="This name will be used to identify this System in messages sent to the designated recipients."
    >
      <TextInput
        id={fieldId}
        value={systemName}
        disabled={disabled}
        validationMessage={"32 character maximum."}
        pattern="^.*$"
        inlineHelp="32 character maximum with no trailing spaces."
        onChange={({ target }) => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(target.value.toUpperCase(), "systemName");
          });
        }}
        onBlur={({ target }) => {
          updateMessagingSetup((recordProxy) => {
            recordProxy.setValue(target.value.trim(), "systemName");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MessagingSetupSystemNameField;
