import React, { useState } from "react";
import UploadImageModal from "./UploadImageModal";

interface AddSystemImageButtonProps {
  authToken: string;
  controlSystemId: string;
  imageCount: number;
  imageApiUrl: string;
  isHidden: boolean;
  refreshSystemImages: () => void;
}
function AddSystemImageButton(props: AddSystemImageButtonProps) {
  const { imageCount, isHidden, refreshSystemImages } = props;
  const maxImageCount = 4;
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);

  return (
    <>
      {!isHidden && (
        <button
          className="btn btn-sm btn-add"
          onClick={() => setUploadImageModalOpen(true)}
          type="button"
          disabled={imageCount >= maxImageCount}
        />
      )}
      {uploadImageModalOpen && (
        <UploadImageModal
          {...props}
          refreshSystemImages={refreshSystemImages}
          onCancel={() => setUploadImageModalOpen(false)}
        />
      )}
    </>
  );
}

export default AddSystemImageButton;
