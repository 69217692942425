/**
 * @generated SignedSource<<0801e12d984f3844f262388ea6106f78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly nonOutputs: {
    readonly cutoffOutputs: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly outputOptions: {
    readonly included: boolean;
  } | null;
  readonly outputs: {
    readonly acFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly communicationFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceFailOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly fireTroubleOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly sensorResetOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryAlarmOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "1f4e747503aee2882e1c5e729bc470f0";

export default node;
