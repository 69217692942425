/**
 * @ngdoc service
 * @name App.factory:DealerODataAPI
 *
 * @description
 * OData API factory for Dealer data
 *
 */
App.factory("DealerODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/dealers(:dealer_id)/:concept",
      {},
      {
        getDealer: { method: "GET", params: { dealer_id: "@dealer_id" } },
        getDetailedCustomers: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetCustomerList()" },
        },
        getDetailedCustomerList: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetDetailedCustomerList()",
          },
        },
        getCustomers: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "customers" },
        },
        getQuickCustomerCount: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetCustomerCount()",
          },
        },
        getCustomerUsers: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetCustomerUsers()" },
        },
        getPanels: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetPanels()" },
        },
        getEvents: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetEvents()" },
        },
        getEventHistories: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetEventHistories()",
          },
        },
        getReportEvents: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetReportEvents()" },
        },
        getFireInspectionEvents: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetXF6FireInspectionEvents()",
          },
        },
        getVideoDevices: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetVideoDevices" },
        },
        getControlSystems: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetControlSystems" },
        },
        getPanelCountByModel: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetPanelCountByModel()",
          },
        },
        getPanelCountByConnectionType: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetPanelCountByConnectionType()",
          },
        },
        getConceptTotalCount: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "@concept" },
        },
        getCountForMonth: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "@concept" },
        },
        getDetailedPanelMapData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetDetailedPanelMapData()",
          },
        },
        getDetailedPanelData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetCompactDetailedPanelDatav2",
          },
        },
        //Claim System Call
        getRoleLimitedPanelData: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetRecentSystemTests",
          },
        },
        getPersonnel: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "dealer_users" },
        },
        getAuditLogs: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetAuditLogs" },
        },
        getAuditLogsOfAppUsers: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetAuditLogsOfAppUsers",
          },
        },
        getAuditLogsOfPersonnel: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetAuditLogsOfPersonnel",
          },
        },
        getAuditLogsOfSystems: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetAuditLogsOfSystems",
          },
        },
        getSystemPeripheralsCount: {
          method: "GET",
          params: { concept: "vk.GetSystemPeripheralsCounts()" },
        },
        getPersonUsers: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetPersonUsers" },
        },
        getThirdPartyVideoDevices: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetThirdPartyVideoDevices",
          },
        },
      }
    );
  },
]);

App.factory("DealerODataEventHistoriesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.oDataEventHistoriesUrl + "/api/v1/dealers(:dealer_id)/:concept",
      {},
      {
        getEventHistories: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetEventHistories()",
          },
        },
      }
    );
  },
]);
App.factory("DealerODataEventReportsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.oDataEventReportsUrl + "/api/v1/dealers(:dealer_id)/:concept",
      {},
      {
        getReportEvents: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "vk.GetReportEvents()" },
        },
      }
    );
  },
]);

App.factory("DealerODataFireInspectionReportsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.oDataEventReportsUrl + "/api/v1/dealers(:dealer_id)/:concept",
      {},
      {
        getFireInspectionEvents: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "vk.GetXF6FireInspectionEvents()",
          },
        },
      }
    );
  },
]);

App.factory("ControlSystemODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/customers(:customer_id)/control_systems",
      {},
      {
        getPanelsForMultiPanelUser: {
          method: "GET",
          params: { customer_id: "@customer_id" },
        },
      }
    );
  },
]);

App.factory("GenericODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/:concept",
      {},
      {
        getCountForMonth: {
          method: "GET",
          params: { dealer_id: "@dealer_id", concept: "@concept" },
        },
      }
    );
  },
]);
