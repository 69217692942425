/**
 * @generated SignedSource<<e505d7bfbf016f024c0e183dd5940dae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PcLogReportsSupervisoryReportsEnabledField_pcLogReports$data = {
  readonly supervisoryReportsEnabled: boolean;
  readonly " $fragmentType": "PcLogReportsSupervisoryReportsEnabledField_pcLogReports";
};
export type PcLogReportsSupervisoryReportsEnabledField_pcLogReports$key = {
  readonly " $data"?: PcLogReportsSupervisoryReportsEnabledField_pcLogReports$data;
  readonly " $fragmentSpreads": FragmentRefs<"PcLogReportsSupervisoryReportsEnabledField_pcLogReports">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PcLogReportsSupervisoryReportsEnabledField_pcLogReports",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supervisoryReportsEnabled",
      "storageKey": null
    }
  ],
  "type": "PcLogReports",
  "abstractKey": null
};

(node as any).hash = "27657bb61e83780e385b67ef3cd73115";

export default node;
