import NakedButton from "common/components/web/Button/NakedButton";
import Icon from "components/Icon";
import * as React from "react";
import { react2angular } from "react2angular";
import styled, { css } from "styled-components";

const BridgeInfo = ({
  systemId,
  showCameras = true,
}: {
  systemId: number;
  showCameras: boolean;
}) => {
  const bridgeInfo = {
    name: "Bridge Name",
    connectedCameras: 1,
    status: "Connected",
    connectedStreams: "2 MP",
    mac: "12345789",
  };
  return (
    <>
      <HorizontalScrollWindow>
        <Table>
          <Header>
            <HeaderItem>Bridge</HeaderItem>
            <HeaderItem>Connected Cameras</HeaderItem>
            <HeaderItem>Status</HeaderItem>
            <HeaderItem>Connected Streams</HeaderItem>
            <HeaderItem></HeaderItem>
          </Header>
          <Row key={bridgeInfo.name}>
            <NameCell>
              <Name>{bridgeInfo.name}</Name>
              <Mac>{bridgeInfo.mac}</Mac>
            </NameCell>
            <Cell>{bridgeInfo.connectedCameras}</Cell>
            <Cell>{bridgeInfo.status}</Cell>
            <Cell>{bridgeInfo.connectedStreams}</Cell>
            <Cell>
              <RestartBridge>
                Restart <br /> Bridge
              </RestartBridge>
            </Cell>
          </Row>
          {showCameras && <CameraInfo systemId={1234} />}
        </Table>
      </HorizontalScrollWindow>
      {showCameras && (
        <InputContainer>
          <Input placeholder="Enter URL" /> <AddButton>Add</AddButton>
        </InputContainer>
      )}
    </>
  );
};

const CameraInfo = ({ systemId }: { systemId: number }) => {
  const cameras = [
    {
      name: "Main camera",
      mac: "12345679",
      model: "V-4030PT-1",
      resolution: "2 MP",
      securecomCam: true,
      connected: true,
    },
    {
      name: "Hall Camera",
      mac: "12345673",
      model: "V-2345PT-1",
      resolution: "2 MP",
      securecomCam: false,
      connected: false,
    },
    {
      name: "Front Door Camera",
      mac: "12345673",
      model: "V-2376PT-1",
      resolution: "3 MP",
      securecomCam: true,
      connected: false,
    },
  ];

  if (!cameras.length) {
    return null;
  }

  return (
    <>
      <Header>
        <Cell>Camera</Cell>
        <Cell>Model</Cell>
        <Cell>Resolution</Cell>
        <Cell>SecureCom&trade;</Cell>
        <Cell></Cell>
      </Header>
      {cameras.map((camera) => {
        return (
          <Row key={camera.name}>
            <NameCell>
              <Name>{camera.name}</Name>
              <Mac>{camera.mac}</Mac>
            </NameCell>
            <Cell>{camera.model}</Cell>
            <Cell>{camera.resolution}</Cell>
            <Cell>
              {camera.securecomCam ? (
                <Icon name={"checkmark"} color={"var(--color-success-500)"} />
              ) : (
                <Icon name={"close_cancel"} color={"var(--color-danger-500)"} />
              )}
            </Cell>
            <Cell>
              <ConnectDisconnectCamera>
                {camera.connected ? "Disconnect" : "Add"}
              </ConnectDisconnectCamera>
            </Cell>
          </Row>
        );
      })}
    </>
  );
};

const CamectBridgeTable = () => {
  type CamectBridgeTableState = {
    showCameras: boolean;
  };
  type CamectBridgeTableActions = {
    type: "SHOW_HIDE_CAMERAS_BUTTON_CLICKED";
    value: boolean;
  };

  const [state, dispatch] = React.useReducer(
    (state: CamectBridgeTableState, action: CamectBridgeTableActions) => {
      switch (action.type) {
        case "SHOW_HIDE_CAMERAS_BUTTON_CLICKED":
          return {
            ...state,
            showCameras: action.value,
          };
      }
    },
    { showCameras: false }
  );

  return (
    <Container>
      <ComponentHeader>
        <HeaderText fontSize={"var(--measure-3x)"}>Bridge</HeaderText>

        {state.showCameras ? (
          <HeaderText
            fontSize={"var(--measure-2x)"}
            colored={true}
            clickable={true}
            onClick={() =>
              dispatch({
                type: "SHOW_HIDE_CAMERAS_BUTTON_CLICKED",
                value: false,
              })
            }
          >
            Hide Cameras
          </HeaderText>
        ) : (
          <HeaderText
            fontSize={"var(--measure-2x)"}
            colored={true}
            clickable={true}
            onClick={() =>
              dispatch({
                type: "SHOW_HIDE_CAMERAS_BUTTON_CLICKED",
                value: true,
              })
            }
          >
            Show Cameras
          </HeaderText>
        )}
      </ComponentHeader>
      <BridgeInfo showCameras={state.showCameras} systemId={1234} />
    </Container>
  );
};

const CamectBridgeTableRoot = () => {
  return (
    <React.Suspense fallback="loading...">
      <CamectBridgeTable></CamectBridgeTable>
    </React.Suspense>
  );
};

const ColoredText = css`
  color: var(--color-primary-600);
`;

const PaddedRowStyle = css`
  :first-child {
    padding-left: var(--measure-3x);
  }
`;

const CellPadding = css`
  padding: var(--measure-2x) var(--measure-1x);
`;

const CursorPointerHoverable = css`
  :hover {
    cursor: pointer;
  }
`;

const InputClusterSharedBorderStyle = css`
  border: 1px solid var(--color-neutral-600);
  border-radius: 4px;
`;

const Container = styled.div`
  background-color: white;
  border: 1px solid var(--color-neutral-500);
  border-radius: 5px;
`;

const ComponentHeader = styled.div`
  padding: var(--measure-3x) var(--measure-3x) 0;
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.p<{
  fontSize?: string;
  colored?: boolean;
  clickable?: boolean;
}>`
  ${({ colored }) => colored && ColoredText}
  ${({ clickable }) => clickable && CursorPointerHoverable}
  font-size: ${({ fontSize }) => fontSize};
`;

const HorizontalScrollWindow = styled.div`
  overflow-y: hidden;
  //Edge, IE-10
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

const Table = styled.table`
  width: 100%;
`;

const Header = styled.tr`
  border-bottom: 3px solid var(--color-neutral-400);
`;

const HeaderItem = styled.th`
  ${CellPadding}
  ${PaddedRowStyle};
  font-size: 15px;
  line-height: 1.4;
`;

const Row = styled.tr`
  background-color: hsl(221, 28%, 97%);
  &:nth-child(odd) {
    background-color: white;
  }
`;

const Cell = styled.td`
  ${CellPadding}
  ${PaddedRowStyle};
  font-size: 15px;
  line-height: 1.4;
`;

const NameCell = styled(Cell)`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

const Name = styled.span`
  ${ColoredText};
  font-weight: var(--font-weight-semibold);
`;

const Mac = styled.span`
  ${ColoredText};
  font-weight: var(--font-weight-thin);
  font-size: 12px;
`;

const RestartBridge = styled.p`
  ${ColoredText}
`;

const ConnectDisconnectCamera = styled.p`
  ${ColoredText}
`;

const InputContainer = styled.div`
  padding: var(--measure-3x);
  display: flex;
`;

const Input = styled.input`
  ${InputClusterSharedBorderStyle}
  padding: var(--measure-1x);
  margin-right: var(--measure-3x);
  width: 66%;
`;

const AddButton = styled(NakedButton)`
  ${InputClusterSharedBorderStyle}
  background-color: white;
  padding: var(--measure-1x) var(--measure-3x);
`;

export function dangerouslyAddToApp() {
  App.component(
    "camectBridgeTable",
    react2angular(
      CamectBridgeTableRoot,
      [],
      ["$stateParams", "RelayService", "UserService", "$rootScope", "$state"]
    )
  );
}
