import graphql from "babel-plugin-relay/macro";
import FormGridItems from "components/FormGridItems";
import NeutralText from "components/NeutralText";
import { Sheet } from "components/SiteForm/Layout/Layout";
import { isEmpty } from "ramda";
import React from "react";
import { useFragment } from "react-relay/hooks";
import VideoSelection from "./Components/VideoSelection";
import { EagleEyeBridges_eagleEyeBridge$key } from "./__generated__/EagleEyeBridges_eagleEyeBridge.graphql";
import { EagleEye_site$key } from "./__generated__/EagleEye_site.graphql";

export default function EagleEyeBridges({
  siteRef,
}: {
  siteRef: EagleEye_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment EagleEye_site on Site {
        eagleEyeBridges {
          ...EagleEyeBridges_eagleEyeBridge
          id
        }
      }
    `,
    siteRef
  );
  return (
    <Sheet>
      <VideoSelection>
        <VideoSelection.Description title={<>Eagle Eye&reg;</>} />
      </VideoSelection>
      {data.eagleEyeBridges.length ? (
        <VideoSelection.Section>
          <FormGridItems
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(30rem, 1fr))",
            }}
          >
            {data.eagleEyeBridges.map((item) => (
              <EagleEyeBridge key={item.id} eagleEyeBridgeRef={item} />
            ))}
          </FormGridItems>
        </VideoSelection.Section>
      ) : (
        <VideoSelection.Section>
          <span className="info-alert">
            <i className="icon-radial_info"></i>
            No Eagle Eye devices have been added.
          </span>
        </VideoSelection.Section>
      )}
    </Sheet>
  );
}

function EagleEyeBridge({
  eagleEyeBridgeRef,
}: {
  eagleEyeBridgeRef: EagleEyeBridges_eagleEyeBridge$key;
}) {
  const data = useFragment(
    graphql`
      fragment EagleEyeBridges_eagleEyeBridge on EagleEyeBridge {
        name
        serialNumber
        installerEmail
        channels {
          name
          id
        }
      }
    `,
    eagleEyeBridgeRef
  );

  return (
    <FormGridItems.Item>
      <FormGridItems.ItemContent>
        <FormGridItems.ItemTitle>{data.name}</FormGridItems.ItemTitle>
        <FormGridItems.ItemSubtitle>
          Serial #: {data.serialNumber || "Unknown"}
        </FormGridItems.ItemSubtitle>
        <FormGridItems.ItemSubtitle>
          Added By: {data.installerEmail || "Unknown"}
        </FormGridItems.ItemSubtitle>
      </FormGridItems.ItemContent>
      {!isEmpty(data.channels) ? (
        data.channels.map((channel) => (
          <FormGridItems.ItemContent key={channel.id}>
            <FormGridItems.ItemTitle>{channel.name}</FormGridItems.ItemTitle>
          </FormGridItems.ItemContent>
        ))
      ) : (
        <FormGridItems.ItemContent>
          <em>
            <NeutralText>No channels have been added</NeutralText>
          </em>
        </FormGridItems.ItemContent>
      )}
    </FormGridItems.Item>
  );
}
