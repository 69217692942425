App.directive("daUserBlock", [
  "$rootScope",
  "$state",
  "UserService",
  function ($rootScope, $state, UserService) {
    return {
      restrict: "E",
      templateUrl: "app/common/templates/user-block-tpl.html",
      link: function (scope) {
        scope.settings = function () {
          var userEditReturnState = {
            name: $rootScope.stateChange.next.name,
            params: {},
          };
          angular.extend(
            userEditReturnState.params,
            $rootScope.stateChange.nextParams
          );
          UserService["settingsBreadcrumb"] = userEditReturnState;
          $state.go("app.dealer.personnel_edit", {
            dealer_id: UserService.dealer_id,
            user_id: UserService.user.id,
          });
        };
      },
    };
  },
]);

App.directive("newDaUserBlock", [
  "$rootScope",
  "$state",
  "UserService",
  function ($rootScope, $state, UserService) {
    return {
      restrict: "E",
      templateUrl: "app/common/templates/new-user-block-tpl.html",
      link: function (scope) {
        scope.userBlockToggle = function () {
          scope.userBlockIsOpen = !scope.userBlockIsOpen;
        };
        scope.settings = function () {
          var userEditReturnState = {
            name: $rootScope.stateChange.next.name,
            params: {},
          };
          angular.extend(
            userEditReturnState.params,
            $rootScope.stateChange.nextParams
          );
          UserService["settingsBreadcrumb"] = userEditReturnState;
          $state.go("app.dealer.personnel_edit", {
            dealer_id: UserService.dealer_id,
            user_id: UserService.user.id,
          });
        };
      },
    };
  },
]);
