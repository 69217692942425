import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsAllowCellularRemoteProgrammingField_remoteOptions$key } from "./__generated__/RemoteOptionsAllowCellularRemoteProgrammingField_remoteOptions.graphql";

export const remoteOptionsAllowCellularRemoteProgrammingFieldId = () =>
  "remote-options-allow-cellular-remote-programming";

function RemoteOptionsAllowCellularRemoteProgrammingField() {
  const [{ allowCellularRemoteProgramming }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsAllowCellularRemoteProgrammingField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsAllowCellularRemoteProgrammingField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            allowCellularRemoteProgramming
          }
        }
      `
    );

  const fieldId = remoteOptionsAllowCellularRemoteProgrammingFieldId();
  const label = "Allow Cellular Remote Programming";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables remote programming over the cellular."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={!!allowCellularRemoteProgramming}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !allowCellularRemoteProgramming,
              "allowCellularRemoteProgramming"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsAllowCellularRemoteProgrammingField;
