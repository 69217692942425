import * as React from "react";

const Context = React.createContext<any>(null);

export function AngularStateProvider({
  state,
  children,
}: {
  state: any;
  children: React.ReactNode;
}) {
  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export function useAngularState() {
  return React.useContext(Context);
}

export function useStateDotGo() {
  const state = useAngularState();

  return (
    ...args: [string, { [key: string]: string | number }, { reload: boolean }]
  ) => {
    state.go(...args);
  };
}
