import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathTypeField_communicationPath$key } from "./__generated__/CommPathTypeField_communicationPath.graphql";

export const commPathTypeFieldId = (number: string) =>
  `comm-path-type-${number}`;

function CommPathTypeField() {
  const [{ type, number }, updateCommPath] =
    useCommPathFragment<CommPathTypeField_communicationPath$key>(
      graphql`
        fragment CommPathTypeField_communicationPath on CommunicationPath {
          type
          number
        }
      `
    );

  const fieldId = commPathTypeFieldId(String(number));
  const disabled = number === 1;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Path Type"
      tooltip="Path priority for communications with the Central Station."
    >
      <Select
        id={fieldId}
        value={type}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "type");
          });
        }}
      >
        <Select.Option value="PRIMARY">Primary</Select.Option>
        <Select.Option value="BACKUP">Backup</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathTypeField;
