/**
 * @generated SignedSource<<a4468ff5f7b705db54400bf52d086a5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRFullProgrammingContainer_templateStatus_controlSystem$data = {
  readonly id: string;
  readonly templatePreProgrammingJobStatus: string | null;
  readonly " $fragmentType": "XRFullProgrammingContainer_templateStatus_controlSystem";
};
export type XRFullProgrammingContainer_templateStatus_controlSystem$key = {
  readonly " $data"?: XRFullProgrammingContainer_templateStatus_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRFullProgrammingContainer_templateStatus_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./XRFullProgrammingContainer_templateStatus_controlSystemQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "XRFullProgrammingContainer_templateStatus_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatePreProgrammingJobStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "0960845f2ba759b65dd10570d3fe760d";

export default node;
