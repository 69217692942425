/**
 * @generated SignedSource<<3feb0aed106c60b5e982549eea0bf39c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
export type DualAuthorityEnum = "ALL" | "ARM" | "DISARM" | "NO";
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationProgrammingConceptFormInline_area$data = {
  readonly __typename: string;
  readonly accountNumber?: string;
  readonly armFirstArea?: boolean;
  readonly armedOutputNumber?: string;
  readonly autoArmEnabled: boolean;
  readonly autoDisarmEnabled: boolean;
  readonly badZonesOption: BadZonesOption;
  readonly bankSafeVault?: boolean;
  readonly burglaryBellOutputNumber?: string;
  readonly cardPlusPin?: boolean | null;
  readonly commonArea?: boolean;
  readonly dualAuthority?: DualAuthorityEnum | null;
  readonly hasChanges: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly lateArmDelay?: number;
  readonly lateOutputNumber?: string;
  readonly name: string;
  readonly number: string;
  readonly openClosingReports?: boolean;
  readonly twoManRule?: boolean | null;
  readonly " $fragmentType": "XRAreaInformationProgrammingConceptFormInline_area";
};
export type XRAreaInformationProgrammingConceptFormInline_area$key = {
  readonly " $data"?: XRAreaInformationProgrammingConceptFormInline_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptFormInline_area">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRAreaInformationProgrammingConceptFormInline_area"
};

(node as any).hash = "c74878ad87cd83f98cbee8b36844e756";

export default node;
