import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathUse893AField_communicationPath$key } from "./__generated__/CommPathUse893AField_communicationPath.graphql";

export const commPathUse893AFieldId = (number: string) =>
  `comm-path-use-893a-${number}`;

function CommPathUse893AField() {
  const [{ number, use893A, commType }, updateCommPath] =
    useCommPathFragment<CommPathUse893AField_communicationPath$key>(
      graphql`
        fragment CommPathUse893AField_communicationPath on CommunicationPath {
          number
          use893A
          commType
        }
      `
    );

  const fieldId = commPathUse893AFieldId(String(number));
  const disabled = ["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Use 893A"
      tooltip="When an 893A module is used, the Test Report messages will be sent to the receiver at the programmed Test Frequency, alternating between phone lines."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label="Use 893A"
        checked={use893A}
        disabled={disabled}
        onChange={() => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(!use893A, "use893A");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathUse893AField;
