/**
 * @generated SignedSource<<9e97f00dcd5ff454f08826228bfc5ec7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsPowerFailDelayField_systemOptions$data = {
  readonly powerFailDelay: number;
  readonly powerFailDelayMax: number;
  readonly powerFailDelayMin: number;
  readonly " $fragmentType": "SystemOptionsPowerFailDelayField_systemOptions";
};
export type SystemOptionsPowerFailDelayField_systemOptions$key = {
  readonly " $data"?: SystemOptionsPowerFailDelayField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsPowerFailDelayField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsPowerFailDelayField_systemOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "powerFailDelay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "powerFailDelayMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "powerFailDelayMax",
      "storageKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "5edbe3455dad5754db92256c1afdceca";

export default node;
