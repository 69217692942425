import { createContext, useContext } from "react";

const ProgrammingConceptFormListItemIdContext = createContext<string | null>(
  null
);

export const useProgrammingConceptFormListItemIdContext = () =>
  useContext(ProgrammingConceptFormListItemIdContext);

export default ProgrammingConceptFormListItemIdContext;
