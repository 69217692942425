/**
 * @generated SignedSource<<4b2bad58d4d92d18f178e4f2189471c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type OutputInformationOutputSupervisionField_output$data = {
  readonly id: string;
  readonly isWireless: boolean;
  readonly number: number;
  readonly outputSupervision: OutputSupervision | null;
  readonly serialNumber: string | null;
  readonly " $fragmentType": "OutputInformationOutputSupervisionField_output";
};
export type OutputInformationOutputSupervisionField_output$key = {
  readonly " $data"?: OutputInformationOutputSupervisionField_output$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputInformationOutputSupervisionField_output">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputInformationOutputSupervisionField_output",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outputSupervision",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isWireless",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    }
  ],
  "type": "Output",
  "abstractKey": null
};

(node as any).hash = "f6eaf9771f795568326995e007ad4dcd";

export default node;
