/**
 * @generated SignedSource<<a99c72ff484868d4902baa9d161f32e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteOutputModulesFormExistingModule_siteOutputModule$data = {
  readonly address: number;
  readonly controlSystem: {
    readonly doors: ReadonlyArray<{
      readonly name: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesForm_OutputModuleForm_siteControlSystem">;
  } | null;
  readonly id: string;
  readonly name: string;
  readonly outputsConnection: {
    readonly nodes: ReadonlyArray<{
      readonly name: string;
      readonly relayNumber: number | null;
    }>;
  };
  readonly " $fragmentType": "SiteOutputModulesFormExistingModule_siteOutputModule";
};
export type SiteOutputModulesFormExistingModule_siteOutputModule$key = {
  readonly " $data"?: SiteOutputModulesFormExistingModule_siteOutputModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesFormExistingModule_siteOutputModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteOutputModulesFormExistingModule_siteOutputModule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteDoor",
          "kind": "LinkedField",
          "name": "doors",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SiteOutputModulesForm_OutputModuleForm_siteControlSystem"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteOutputModuleOutputsConnection",
      "kind": "LinkedField",
      "name": "outputsConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteOutput",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relayNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SiteOutputModule",
  "abstractKey": null
};
})();

(node as any).hash = "2b16befe4251a6bf549b80a92146d6e8";

export default node;
