import { fetchImageSrcFromUrlWithBearerAuth } from "common/utils/web/image";

App.directive("daCellCoverageMap", [
  "$modal",
  "$stateParams",
  "UserService",
  "NgMap",
  "MAP_SETTINGS",
  "$q",
  "DashboardDataService",
  "control_system_hardware_modelFilter",
  "control_system_comm_typeFilter",
  "$sanitize",
  "GeoLocationService",
  "PROPS",
  function (
    $modal,
    $stateParams,
    UserService,
    NgMap,
    MAP_SETTINGS,
    $q,
    DashboardDataService,
    control_system_hardware_modelFilter,
    control_system_comm_typeFilter,
    $sanitize,
    GeoLocationService,
    PROPS
  ) {
    return {
      restrict: "E",
      template:
        '<a class="btn btn-sm btn-dmp" ng-click="openCellCoverageMapModal()">Cellular Coverage Map</a>',

      link: function ($scope, $element, attrs, ngModel) {
        $scope.toggleLayer = toggleLayer;
        // $scope.controlSystem = attrs.controlSystem
        $scope.panelCellType = "verizon";
        $scope.layerSelector = "verizon";
        $scope.mapSettings = {};
        $scope.mapSettings.lightStyle = true;
        $scope.verizonCoverageDataAvailable = true;
        $scope.attCoverageDataAvailable = true;

        var showSpinner;
        var mapId =
          attrs.panelId != "new" ? "cellmap-" + attrs.panelId : "cellmap-null";
        var panelId = attrs.panelId;

        $scope.openCellCoverageMapModal = function () {
          $scope.checked = false;
          $scope.showModal = true;
          $scope.testConnModal = $modal.open({
            templateUrl: "app/common/templates/cell-coverage.map.html",
            size: "xl",
            backdrop: "static",
            scope: $scope,
          });
          checkForCellCoverageData();
          getAddress().then(
            function (data) {},
            function (error) {}
          );
          $scope.panelCellType = $scope.controlSystem?.panels?.["0"]
            ?.sim_carrier
            ? $scope.controlSystem.panels["0"].sim_carrier
                .toLowerCase()
                .replace("&", "")
            : "verizon";
          $scope.layerSelector = angular.copy($scope.panelCellType);
          toggleLayer($scope.layerSelector);
          setMapTheme();
        };

        /**
         *gathering data from dashboard and map,
         *then setting it to deferred.promise
         */
        function getAddress(useGeoLocation) {
          var deferred = $q.defer();
          $scope.panelMapData = {};
          if (panelId == "new" || useGeoLocation) {
            if (
              !$scope.controlSystem.address_1 ||
              !$scope.controlSystem.postal_code ||
              !$scope.controlSystem.country
            )
              copyAddress();
            if (
              $scope.controlSystem.address_1 &&
              $scope.controlSystem.postal_code &&
              $scope.controlSystem.country
            ) {
              var addressData = {
                Address: $scope.controlSystem.address_1,
                PostalCode: $scope.controlSystem.postal_code,
                Country: $scope.controlSystem.country,
              };
              GeoLocationService.requestLatLong(addressData)
                .then(
                  function (data) {
                    $scope.panelMapData = $scope.controlSystem;
                    $scope.panelMapData.latitude = data.Latitude;
                    $scope.panelMapData.longitude = data.Longitude;
                    setMarker(useGeoLocation);
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject();
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            }
          } else {
            DashboardDataService.getUnfilteredMapDataForPanel(
              UserService.dealerInfo.id,
              panelId
            )
              .then(
                function (data) {
                  $scope.panelMapData = data;
                  $scope.panelCount = $scope.panelMapData.length;
                  setMarker();
                  deferred.resolve(data);
                },
                function (error) {
                  deferred.reject();
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
          return deferred.promise;
        }

        function copyAddress() {
          $scope.controlSystem.address_1 = angular.copy(
            UserService.customerInfo.address1
          );
          $scope.controlSystem.address_2 = angular.copy(
            UserService.customerInfo.address2
          );
          $scope.controlSystem.city = angular.copy(
            UserService.customerInfo.city
          );
          $scope.controlSystem.state = angular.copy(
            UserService.customerInfo.state_province
          );
          $scope.controlSystem.postal_code = angular.copy(
            UserService.customerInfo.postal_code
          );
          $scope.controlSystem.country = angular.copy(
            UserService.customerInfo.country
          );
        }

        function setMarker(doNotRetryGeoLocation) {
          NgMap.getMap(mapId).then(
            function (map) {
              if (
                !$scope.panelMapData.longitude ||
                !$scope.panelMapData.latitude
              ) {
                var useGeoLocation = true;

                if (doNotRetryGeoLocation) return;
                else
                  getAddress(useGeoLocation)
                    .then(
                      function (data) {},
                      function (error) {}
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
              }
              if (
                !$scope.panelMapData.longitude ||
                !$scope.panelMapData.latitude
              )
                return;

              // TODO: change this back when we have REAL data...
              var latLng = new google.maps.LatLng(
                $scope.panelMapData.latitude,
                $scope.panelMapData.longitude
              );
              var marker = new google.maps.Marker({
                position: latLng,
                title: $scope.panelMapData.control_system_name,
                icon: "/assets/img/googlemaps-markers/dmp-map-marker.png",
              });
              var bounds = new google.maps.LatLngBounds();
              bounds.extend(marker.position);
              map.fitBounds(bounds);

              marker.setMap(map);
              setMapTheme();
              map.setOptions({
                styles: $scope.mapTheme,
              });
            },
            function (error) {
              console.error(error);
            }
          );
        }

        /**
         *
         * @param panel
         * @returns {string}
         */
        function formatMarkerInfoWindow(panel) {
          var postalAddress = formatPostalAddress(
            $sanitize(panel.control_system_address_1),
            $sanitize(panel.control_system_address_2),
            $sanitize(panel.control_system_city),
            $sanitize(panel.control_system_state),
            $sanitize(panel.postal_code)
          );
          var customerName = panel.customer_name;
          var hardware_model = control_system_hardware_modelFilter(
            panel.hardware_model,
            panel.hardware_model_name
          );
          var comm_type = control_system_comm_typeFilter(panel.comm_type);
          var info =
            customerName +
            "<br>" +
            "<a href='/#/app/customers/" +
            panel.customer_id +
            "/control_systems/" +
            panel.control_system_id +
            "'>" +
            panel.control_system_name +
            "</a><br>" +
            hardware_model +
            "<br>" +
            comm_type;
          info +=
            postalAddress.topLine || postalAddress.bottomLine ? "<br>" : "";
          info += postalAddress.topLine ? postalAddress.topLine + "<br>" : "";
          info += postalAddress.bottomLine ? postalAddress.bottomLine : "";

          return info;
        }

        function toggleLayer(layerType) {
          var opacity = layerType === "radar" ? "50" : "100";
          NgMap.getMap(mapId)
            .then(function (map) {
              map.overlayMapTypes.clear();

              if (layerType != "clear" && layerType) {
                var url = new google.maps.ImageMapType({
                  getTileUrl: function (coord, zoom) {
                    if (layerType == "verizon" || layerType == "att")
                      return fetchImageSrcFromUrlWithBearerAuth(
                        [
                          PROPS.coverageMapApiUrl,
                          "/api/v1/tiles/",
                          layerType,
                          "/",
                          zoom,
                          "/",
                          coord.x,
                          "/",
                          coord.y,
                        ].join(""),
                        UserService.auth_token
                      );
                    else
                      return [
                        "https://maps.aerisapi.com/4yEmQCJNh7eij7qndeGmn_YCiCSSt3xWHwYosdcCgfT0k1LaWDo66WihJJrhqy/" +
                          layerType +
                          ":" +
                          opacity,
                        "/",
                        zoom,
                        "/",
                        coord.x,
                        "/",
                        coord.y,
                        "/current.png",
                      ].join("");
                  },
                  tileSize: new google.maps.Size(256, 256),
                });

                map.overlayMapTypes.push(url);
              }
            })
            .catch(function (error) {
              console.error(error);
            });
        }

        function checkForCellCoverageData() {
          let verizonTestData = new Image();
          let attTestData = new Image();
          verizonTestData.onload = function () {
            // image exists and is loaded
            $scope.verizonCoverageDataAvailable = true;
          };
          verizonTestData.onerror = function () {
            // image did not load
            $scope.verizonCoverageDataAvailable = false;
          };

          attTestData.onload = function () {
            // image exists and is loaded
            $scope.attCoverageDataAvailable = true;
          };
          attTestData.onerror = function () {
            // image did not load
            $scope.attCoverageDataAvailable = false;
          };
          verizonTestData.src = fetchImageSrcFromUrlWithBearerAuth(
            `${PROPS.coverageMapApiUrl}/api/v1/tiles/verizon/3/1/2/)`,
            UserService.auth_token
          );
          attTestData.src = fetchImageSrcFromUrlWithBearerAuth(
            `${PROPS.coverageMapApiUrl}/api/v1/tiles/att/3/1/2/`,
            UserService.auth_token
          );
        }

        function setMapTheme() {
          $scope.mapTheme = $scope.mapSettings.lightStyle
            ? MAP_SETTINGS.MAP_STYLES.LIGHT
            : MAP_SETTINGS.MAP_STYLES.DARK;
        }

        // function to toggle the style
        $scope.changeStyle = function () {
          NgMap.getMap(mapId)
            .then(function (map) {
              $scope.mapSettings.lightStyle = !$scope.mapSettings.lightStyle;
              setMapTheme();
              map.setOptions({
                styles: $scope.mapTheme,
              });
            })
            .catch(function (error) {
              console.error(error);
            });
        };

        $scope.close = function () {
          $scope.testConnModal.close();
        };
      },
    };
  },
]);
