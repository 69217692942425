import graphql from "babel-plugin-relay/macro";
import { addClassesIf } from "common/utils/web/css";
import { TableDataCell } from "components/CameraTable/styles";
import {
  Input,
  Panel,
  Table,
  TableFooter,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { TableHeaderSorting } from "components/MobileCredentials/TableStyledComponents";
import { pipe } from "fp-ts/function";
import moment from "moment";
import * as R from "ramda";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { objectSearch } from "search-filterer";
import {
  asString,
  HolidayDateClass,
  toDealerId,
} from "securecom-graphql/client";
import GlobalHolidayDatesTableBody from "./GlobalHolidayDatesTableBody";
import { GlobalHolidayDatesTableQuery } from "./__generated__/GlobalHolidayDatesTableQuery.graphql";

enum SortKey {
  Name,
  Date,
  Description,
}
export interface HolidayCopy {
  clazz: HolidayDateClass;
  date: string;
  description: string;
  id: string;
  name: string;
  scapiId: number;
  formattedDate: string;
}

export default function GlobalHolidayDatesTable({
  dealerId,
}: {
  dealerId: string;
}) {
  const data = useLazyLoadQuery<GlobalHolidayDatesTableQuery>(
    graphql`
      query GlobalHolidayDatesTableQuery($dealerId: ID!) {
        dealer(id: $dealerId) {
          id
          globalHolidayDates {
            scapiId
            id
            name
            description
            date
            clazz
          }
        }
      }
    `,
    { dealerId: pipe(dealerId, toDealerId, asString) }
  );

  //making this copy so that it's not read only
  const holidays: HolidayCopy[] =
    data.dealer?.globalHolidayDates.map((holiday) => {
      const formattedDate = moment(holiday.date).format("MM/DD");
      return {
        ...holiday,
        clazz: holiday.clazz as HolidayDateClass,
        formattedDate: formattedDate,
      };
    }) ?? [];

  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Ascending,
    sortKey: SortKey.Date,
  });

  // break these out so changing page size doesn't cause it to re-search etc...
  const searchedEvents = React.useMemo(
    () =>
      pipe(holidays, (holiday) =>
        objectSearch(
          paginationState.search,
          ["name", "description", "formattedDate"],
          holiday
        )
      ),
    [holidays, paginationState.search]
  );

  const sortedEvents = React.useMemo(
    () =>
      R.sort(
        (paginationState.sortOrder === SortOrder.Ascending
          ? R.ascend
          : R.descend)((holiday) => {
          if (holiday) {
            switch (paginationState.sortKey) {
              case SortKey.Name:
                return holiday.name;
              case SortKey.Date:
                return holiday.date;
              case SortKey.Description:
                return holiday.description;
              default:
                return holiday.date;
            }
          }
        }),
        searchedEvents
      ),
    [paginationState.sortKey, paginationState.sortOrder, searchedEvents]
  );

  const pagedHolidays = React.useMemo(
    () =>
      R.splitEvery(paginationState.pageSize, sortedEvents)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, sortedEvents]
  );

  const maxPage = Math.ceil(sortedEvents.length / paginationState.pageSize);

  return (
    <>
      <Panel.BorderlessTable style={{ minHeight: "48rem" }}>
        <TableHeader>
          <TableHeader.Left>
            <label className="sr-only" htmlFor="search-input"></label>
            <Input.TableSearch
              id="search-input"
              value={paginationState.search}
              onChange={(search) => {
                dispatch({
                  type: ActionType.SetSearch,
                  search,
                });
              }}
            />
          </TableHeader.Left>
        </TableHeader>
        <Table>
          <thead>
            <tr>
              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [paginationState.sortKey === SortKey.Name, "is-active"],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Name) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Name,
                    });
                  }
                }}
              >
                Name
              </TableHeaderSorting>
              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [paginationState.sortKey === SortKey.Date, "is-active"],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Date) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Date,
                    });
                  }
                }}
              >
                Date
              </TableHeaderSorting>
              <TableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.Description,
                      "is-active",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Description) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Description,
                    });
                  }
                }}
              >
                Description
              </TableHeaderSorting>
            </tr>
          </thead>

          <tbody>
            {!R.isEmpty(pagedHolidays) ? (
              pagedHolidays.map((holiday) => (
                <GlobalHolidayDatesTableBody
                  holiday={holiday!!}
                  dealerId={dealerId}
                />
              ))
            ) : (
              <tr>
                <TableDataCell colSpan={4}>
                  No holiday dates available.
                </TableDataCell>
              </tr>
            )}
          </tbody>
        </Table>
        <TableFooter>
          <TableFooter.Left></TableFooter.Left>
          <TableFooter.Center>
            <Paginate.Paginator
              state={paginationState}
              dispatch={dispatch}
              maxPage={maxPage}
            />
          </TableFooter.Center>
          <TableFooter.Right>
            <Paginate.ItemsPerPage
              state={paginationState}
              dispatch={dispatch}
            />
          </TableFooter.Right>
        </TableFooter>
      </Panel.BorderlessTable>
    </>
  );
}
