import SystemDiagnostics from "components/SystemDiagnostics";
import { react2angular } from "react2angular";

App.component(
  "systemDiagnostics",
  react2angular(
    SystemDiagnostics,
    [],
    ["UserService", "RelayService", "DealerService", "$state"]
  )
);
