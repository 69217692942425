import noop from "common/utils/universal/noop";
import * as React from "react";

export type AlertObject = {
  type: "error" | "success" | "warning";
  text: string;
  json?: unknown;
  suppressTitleCasing?: boolean;
};

type AlertsContextType = {
  showAlert: (alert: AlertObject) => void;
  createRootScopeListener: (
    event: string,
    listenerFunction: (...arr: any) => void
  ) => () => void;
};

const AlertsContext = React.createContext<AlertsContextType>({
  showAlert: noop,
  createRootScopeListener: (...arr: any) => noop,
});

export type $RootScope = {
  alerts: AlertObject[];
  $digest: () => void;
  $on: (event: string, listenerFunction: (...arr: any) => void) => () => void;
};

type AlertsContextProps = {
  children: React.ReactNode;
  $rootScope: $RootScope;
};

export const AlertsContextProvider = ({
  children,
  $rootScope,
}: AlertsContextProps) => {
  return (
    <AlertsContext.Provider
      value={React.useMemo(
        () => ({
          showAlert: (alert: AlertObject) => {
            $rootScope.alerts.push(alert);
            $rootScope.$digest();
          },
          createRootScopeListener: (
            event: string,
            listenerFunction: (...arr: any) => void
          ) => {
            return $rootScope.$on(event, listenerFunction);
          },
        }),
        [$rootScope]
      )}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlertsContext = () => React.useContext(AlertsContext);

export const useShowAlert = () => useAlertsContext().showAlert;

export const useCreateRootScopeListener = () =>
  useAlertsContext().createRootScopeListener;
