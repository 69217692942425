/**
 * @generated SignedSource<<bb73d22772f53978c13190da8a3f01da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RecorderEditSettingsQuery$variables = {
  varHubSettingsId: string;
};
export type RecorderEditSettingsQuery$data = {
  readonly getVarHubSettings: {
    readonly varHubSettings?: {
      readonly __typename: "VarHubSettings";
      readonly hubId: number | null;
      readonly id: string;
      readonly settings: {
        readonly alertSettings: {
          readonly disableAlert: boolean | null;
          readonly disableAutoSuppression: boolean | null;
          readonly disableMultiNotifications: boolean | null;
          readonly disableObjectBox: boolean | null;
          readonly disableSending: boolean | null;
          readonly enableSensitiveMotion: boolean | null;
          readonly interestingObject: ReadonlyArray<string | null> | null;
          readonly monitoring: {
            readonly immix: {
              readonly deviceId: number | null;
              readonly immixCams: ReadonlyArray<{
                readonly camName: string | null;
                readonly camectCamId: string | null;
                readonly deviceId: number | null;
                readonly immixInput: number | null;
              } | null> | null;
              readonly password: string | null;
              readonly recipientDomain: string | null;
              readonly sendAutoSuppression: boolean | null;
              readonly smtpPort: number | null;
              readonly smtpServer: string | null;
              readonly user: string | null;
            } | null;
          } | null;
          readonly offlineAlertDelaySecs: number | null;
        } | null;
        readonly camectHubId: string | null;
        readonly hardwareModel: number | null;
        readonly hubName: string | null;
        readonly streamingSettings: {
          readonly enableAudio: boolean | null;
          readonly maxFootageLifeSecs: number | null;
          readonly saveAudio: boolean | null;
        } | null;
        readonly timeLocation: string | null;
      };
      readonly systemId: number | null;
    };
  };
};
export type RecorderEditSettingsQuery = {
  response: RecorderEditSettingsQuery$data;
  variables: RecorderEditSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "varHubSettingsId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "varHubSettingsId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "varHubSettings",
      "args": null,
      "concreteType": "VarHubSettings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hubId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VarHubSettingsData",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hardwareModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "camectHubId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hubName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timeLocation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VarHubSettingsStreamingSettings",
              "kind": "LinkedField",
              "name": "streamingSettings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enableAudio",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxFootageLifeSecs",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saveAudio",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VarHubSettingsAlert",
              "kind": "LinkedField",
              "name": "alertSettings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disableAlert",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disableAutoSuppression",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disableMultiNotifications",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disableObjectBox",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disableSending",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enableSensitiveMotion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "interestingObject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "offlineAlertDelaySecs",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "VarHubSettingsMonitoring",
                  "kind": "LinkedField",
                  "name": "monitoring",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "VarHubSettingsImmix",
                      "kind": "LinkedField",
                      "name": "immix",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VarHubSettingsImmixCamera",
                          "kind": "LinkedField",
                          "name": "immixCams",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "camectCamId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "camName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "immixInput",
                              "storageKey": null
                            },
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "password",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recipientDomain",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sendAutoSuppression",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "smtpPort",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "smtpServer",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "user",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "GetVarHubSettingsSuccessPayload",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecorderEditSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getVarHubSettings",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecorderEditSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getVarHubSettings",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfd4904bce8cd2e9b76ec82bdef3e77d",
    "id": null,
    "metadata": {},
    "name": "RecorderEditSettingsQuery",
    "operationKind": "query",
    "text": "query RecorderEditSettingsQuery(\n  $varHubSettingsId: ID!\n) {\n  getVarHubSettings(id: $varHubSettingsId) {\n    __typename\n    ... on GetVarHubSettingsSuccessPayload {\n      varHubSettings: settings {\n        id\n        systemId\n        hubId\n        settings {\n          hardwareModel\n          camectHubId\n          hubName\n          timeLocation\n          streamingSettings {\n            enableAudio\n            maxFootageLifeSecs\n            saveAudio\n          }\n          alertSettings {\n            disableAlert\n            disableAutoSuppression\n            disableMultiNotifications\n            disableObjectBox\n            disableSending\n            enableSensitiveMotion\n            interestingObject\n            offlineAlertDelaySecs\n            monitoring {\n              immix {\n                immixCams {\n                  camectCamId\n                  camName\n                  immixInput\n                  deviceId\n                }\n                deviceId\n                password\n                recipientDomain\n                sendAutoSuppression\n                smtpPort\n                smtpServer\n                user\n              }\n            }\n          }\n        }\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd9c9529399f0a5fb87f0848d5b23b12";

export default node;
