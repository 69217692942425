/**
 * @generated SignedSource<<c10ebbb6173c68cfea80e054e8b5c57e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SendLocalChangesType = "DIGITAL_DIALER" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsSendLocalChangesField_remoteOptions$data = {
  readonly sendLocalChanges?: SendLocalChangesType | null;
  readonly " $fragmentType": "RemoteOptionsSendLocalChangesField_remoteOptions";
};
export type RemoteOptionsSendLocalChangesField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsSendLocalChangesField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsSendLocalChangesField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsSendLocalChangesField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sendLocalChanges",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "29c726532adf1b1298ebc2caba7dce32";

export default node;
