/**
 * @ngdoc service
 * @name App.factory:RemoteUpdateService
 *
 * @description
 * Manages the panel remote update jobs
 *
 */
App.factory("RemoteUpdateService", [
  "$rootScope",
  "$q",
  "$http",
  "PROPS",
  "$filter",
  "JobSchedulerService",
  "$interval",
  "UserService",
  "Customer",
  "AvailableUpdates",
  function (
    $rootScope,
    $q,
    $http,
    PROPS,
    $filter,
    JobSchedulerService,
    $interval,
    UserService,
    Customer,
    AvailableUpdates
  ) {
    return {
      /**
       * Local constants to define which jobType and jobGroupType we are using for remote Update jobs.
       */
      JOB_TYPE_NAME: "PANEL_UPDATE",
      //JOB_TYPE_NAME: 'THREAD_TEST',
      JOB_GROUP_TYPE_NAME: "PANEL_UPDATE",

      /**
       * The array of Remote Update job groups that are in the current context (one panel)
       */
      updateJobGroups: [],

      /**
       * An object with attributes representing the possible statuses of an update
       */
      updateStatuses: {
        UPDATE_UNKNOWN: "",
        UPDATE_AVAILABLE: "updateAvailable",
        UPDATE_NOT_AVAILABLE: "updateNotAvailable",
        UPDATE_IN_PROGRESS: "updateInProgress",
        UPDATE_PENDING: "updatePending",
      },

      /**
       * String to hold the current status.  It will be one of the updateStatuses
       */
      currentStatus: "",

      /**
       * The current percent complete for _this_ update.
       */
      updatePercent: 0,

      /**
       * The panel that we are updating or getting status of
       */
      activePanelId: null,

      /**
       * Initialize this service.
       *
       * @param panelID
       */
      initialize: function (panelID) {
        this.activePanelId = panelID;
        this.stopUpdateWatcher(this.activeUpdateWatcher);
        this.currentStatus = "";
        this.updatePercent = 0;
        delete this.updateJobGroups;
      },

      /**
       * Set the currentStatus attribute
       * @param status
       */
      setStatus: function (status) {
        this.currentStatus = status;
      },

      /**
       * Creates a scheduled JobGroup to update the activePanel with the software version specified
       * @returns {Promise} a promise, which will eventually resolve the JSON data of the JobGroup that is created.
       */
      updatePanel: function (
        fromVersion,
        toVersion,
        hardwareModel,
        customerName,
        systemName,
        availableUpdateObject
      ) {
        var _this = this;
        var deferred = $q.defer();
        this.setStatus(_this.updateStatuses.UPDATE_PENDING);
        var job = JobSchedulerService.getJobTemplate();
        var jobGroup = JobSchedulerService.getJobGroupTemplate();

        var jobGroupTypePromise = JobSchedulerService.getJobGroupTypeByName(
          this.JOB_GROUP_TYPE_NAME
        );
        var jobTypePromise = JobSchedulerService.getJobTypeByName(
          this.JOB_TYPE_NAME
        );

        $q.all([jobGroupTypePromise, jobTypePromise])
          .then(
            function (values) {
              jobGroup.SchedulerJobGroupTypeId = values[0].Id;
              job.SchedulerJobTypeId = values[1].Id;
              jobGroup.GroupData = _this.getGroupData(
                fromVersion,
                toVersion,
                hardwareModel,
                customerName,
                systemName
              );
              jobGroup.PanelId = _this.activePanelId;
              job.PanelId = _this.activePanelId;

              if (availableUpdateObject)
                job.SelectedItem = angular.toJson(availableUpdateObject);
              else job.SelectedItem = angular.toJson(_this.availableUpdates[0]);

              jobGroup.SchedulerJobs.push(job);
              JobSchedulerService.createJobGroup(jobGroup)
                .then(
                  function (data) {
                    _this.updateJobGroups.push(data);
                    _this.startUpdateWatcher(data.Id);
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the status of that specific Job Group ID
       * @param jobID
       */
      getUpdateStatus: function (jobGroupID) {
        var deferred = $q.defer();
        JobSchedulerService.getJobGroup(jobGroupID)
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Updates for any panel that my auth_token has access to (Dealer?)
       * @returns {*}
       */
      getAllUpdates: function () {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupTypeByName(_this.JOB_GROUP_TYPE_NAME)
          .then(
            function (data) {
              var jobGroupTypeID = data.Id;
              JobSchedulerService.getJobGroups(jobGroupTypeID)
                .then(
                  function (data) {
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Get all updates for the specified Dealer
       * @param dealer_id
       * @returns {*}
       */
      getAllUpdatesForDealer: function (dealer_id, pages) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupTypeByName(_this.JOB_GROUP_TYPE_NAME)
          .then(
            function (data) {
              var jobGroupTypeID = data.Id;
              JobSchedulerService.getJobGroupsByDealerId(
                jobGroupTypeID,
                dealer_id,
                pages
              )
                .then(
                  function (data) {
                    _this.updateJobGroups = data;
                    deferred.resolve(_this.updateJobGroups);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Get all updates for activePanel
       *
       * @returns {*}
       */
      getUpdatesForPanel: function () {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupTypeByName(_this.JOB_GROUP_TYPE_NAME)
          .then(
            function (data) {
              var jobGroupTypeID = data.Id;

              JobSchedulerService.getJobGroupsByPanel(
                jobGroupTypeID,
                _this.activePanelId
              )
                .then(
                  function (data) {
                    delete _this.updateJobGroups;
                    _this.updateJobGroups = data;
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Get any Running updates for activePanel (should only ever be 0 or 1, but you know how that goes)
       * @returns {*}
       */
      getRunningUpdatesForPanel: function () {
        var deferred = $q.defer();
        // We do a full get every time, because it's a cheap call, and it has to be right and fresh
        this.getUpdatesForPanel()
          .then(
            function (data) {
              var runningUpdates = data?.filter((jobGroup) => {
                return JobSchedulerService.isRunningStatus(
                  jobGroup.GroupStatus
                );
              });
              deferred.resolve(runningUpdates);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * A watcher to check a specific job on an interval, stopping when complete or when stopped manually.
       * @param jobID
       */
      startUpdateWatcher: function (jobID) {
        var _this = this;
        this.updatePercent = 0;
        this.activeUpdateWatcher = $interval(function () {
          _this
            .getUpdateStatus(jobID)
            .then(
              function (data) {
                // We check for Complete or Unknown status because Unknown could mean it was successful.
                if (
                  JobSchedulerService.isCompleteStatus(
                    data.SchedulerJobs[0].JobStatus
                  ) ||
                  JobSchedulerService.isUnknownStatus(
                    data.SchedulerJobs[0].JobStatus
                  )
                ) {
                  _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
                  _this.stopUpdateWatcher(_this.activeUpdateWatcher);
                  // Send event to trigger controller to reload the route
                  $rootScope.$broadcast("event-panelUpdateComplete");
                } else if (
                  JobSchedulerService.isRunningStatus(
                    data.SchedulerJobs[0].JobStatus
                  )
                ) {
                  var progress = angular.fromJson(
                    data.SchedulerJobs[0].ScapiStatus
                  );
                  if (progress && progress.details.completion_percentage) {
                    _this.updatePercent = Number(
                      progress.details.completion_percentage
                    );
                  }
                  _this.setStatus(_this.updateStatuses.UPDATE_IN_PROGRESS);
                } else {
                  // Failed status
                  _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
                  _this.stopUpdateWatcher(_this.activeUpdateWatcher);
                  var updateError = data.SchedulerJobs[0].JobOutput
                    ? data.SchedulerJobs[0].JobOutput
                    : "Error completing remote firmware update for system";
                  $rootScope.alerts.push({ type: "error", text: updateError });
                  $rootScope.$broadcast("event-panelUpdateComplete");
                }
              },
              function (error) {
                _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
                _this.stopUpdateWatcher(_this.activeUpdateWatcher);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }, PROPS.updateWatcherInterval);
      },

      /**
       * A watcher to reload the Dashboard data for a dealer on an interval, stopping when stopped manually.
       * @param jobID
       */
      startDashboardWatcher: function (dealerId) {
        var _this = this;
        var deferred = $q.defer();
        this.activeUpdateWatcher = $interval(function () {
          _this
            .getAllUpdatesForDealer(dealerId)
            .then(
              function () {
                deferred.resolve(_this.updateJobGroups);
              },
              function (error) {
                _this.stopUpdateWatcher(_this.activeUpdateWatcher);
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }, PROPS.updateWatcherInterval);
        return deferred.promise;
      },

      /**
       * Stop the activeUpdateWatcher interval update watcher
       * @param promise
       */
      stopUpdateWatcher: function (promise) {
        // If a promise is passed in, use that, otherwise, use the local promise
        promise =
          typeof promise === "undefined" ? this.activeUpdateWatcher : promise;
        //this.activeUpdateWatcher
        if (angular.isDefined(promise)) {
          $interval.cancel(promise);
          this.getAvailableUpdates();
          promise = undefined;
        }
      },

      /**
       * Retrieve the available updates data from VK
       *
       */
      getAvailableUpdates: function (softwareVersion, softwareDate) {
        var deferred = $q.defer();
        var _this = this;
        AvailableUpdates.query(
          { id: _this.activePanelId },
          function (data) {
            if (data && data.length > 0) {
              // Loop through the data, reformat it and sort it
              var updates = [];
              angular.forEach(data, function (panel_firmware) {
                updates.push(panel_firmware.panel_firmware);
              });
              _this.availableUpdates = $filter("orderBy")(updates, [
                "-version",
                "-code_date",
              ]);
              var updateVersion = _this.availableUpdates[0].version;
              var updateCodeDate = dateTimeForceUTC(
                new Date(_this.availableUpdates[0].code_date)
              );

              if (softwareVersion) var myVersion = softwareVersion;
              else
                var myVersion = Number(
                  UserService.controlSystem.panels[0].software_version
                );

              if (softwareDate) var myDate = softwareDate;
              else
                var myDate = parsePanelDate(
                  UserService.controlSystem.panels[0].software_date
                );
              if (
                myVersion < updateVersion ||
                (myVersion == updateVersion && myDate < updateCodeDate)
              ) {
                _this.setStatus(_this.updateStatuses.UPDATE_AVAILABLE);
              } else {
                _this.setStatus(_this.updateStatuses.UPDATE_NOT_AVAILABLE);
              }
            } else {
              _this.setStatus(_this.updateStatuses.UPDATE_NOT_AVAILABLE);
            }

            deferred.resolve(_this.availableUpdates);
          },
          function (error) {
            //log if error
            _this.setStatus(_this.updateStatuses.UPDATE_UNKNOWN);
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**UserService.customerInfo
       * Function to set the GroupData field on the Job Group
       * @param fromVersion
       * @param toVersion
       * @returns {string|undefined|string}
       */
      getGroupData: function (
        fromVersion,
        toVersion,
        hardware_model,
        customerName,
        systemName
      ) {
        var groupData = {};
        if (UserService.customerInfo)
          groupData.customer_name = UserService.customerInfo.name;
        else if (customerName) groupData.customer_name = customerName;
        else groupData.customer_name = "-----";

        if (UserService.controlSystem)
          groupData.control_system_name = UserService.controlSystem.name;
        else if (systemName) groupData.control_system_name = systemName;
        else groupData.control_system_name = "-----";

        groupData.hardware_model = hardware_model;

        groupData.from_version = fromVersion;
        groupData.to_version = toVersion;
        return angular.toJson(groupData);
      },
    };
  },
]);
