/**
 * @generated SignedSource<<a79ce10ccfa81a59ac6845da84f30bff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type VideoDeviceSettingConfirmation = "API_ERROR" | "CONFIRMATION_ERROR" | "CONFIRMED" | "PENDING" | "SENT" | "TIMEOUT";
import { FragmentRefs } from "relay-runtime";
export type OutOfSyncBanner_secureComCamera$data = {
  readonly id: string;
  readonly settingConfirmation: VideoDeviceSettingConfirmation | null;
  readonly " $fragmentType": "OutOfSyncBanner_secureComCamera";
};
export type OutOfSyncBanner_secureComCamera$key = {
  readonly " $data"?: OutOfSyncBanner_secureComCamera$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutOfSyncBanner_secureComCamera">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./OutOfSyncBanner_secureComCameraQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "OutOfSyncBanner_secureComCamera",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingConfirmation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SecureComCamera",
  "abstractKey": null
};

(node as any).hash = "91e68ea0b705df4bd3eb58a1f5839ea9";

export default node;
