/**
 * @generated SignedSource<<5e67b3f6871961145751e614d17bf53d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRPcLogReportsProgrammingConceptForm_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly pcLogReports: {
      readonly " $fragmentSpreads": FragmentRefs<"PcLogReportsArmDisarmReportsEnabledField_pcLogReports" | "PcLogReportsContext_pcLogReports" | "PcLogReportsDoorAccessReportsEnabledField_pcLogReports" | "PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports" | "PcLogReportsNetworkIpAddressField_pcLogReports" | "PcLogReportsNetworkPortField_pcLogReports" | "PcLogReportsSupervisoryReportsEnabledField_pcLogReports" | "PcLogReportsUserCommandReportsEnabledField_pcLogReports" | "PcLogReportsZonesReportsEnabledField_pcLogReports">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHardwareModel_panel" | "PanelContextUseSoftwareDate_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentType": "XRPcLogReportsProgrammingConceptForm_controlSystem";
};
export type XRPcLogReportsProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XRPcLogReportsProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRPcLogReportsProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRPcLogReportsProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PcLogReports",
          "kind": "LinkedField",
          "name": "pcLogReports",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsContext_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsNetworkIpAddressField_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsNetworkPortField_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsArmDisarmReportsEnabledField_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsZonesReportsEnabledField_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsUserCommandReportsEnabledField_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsDoorAccessReportsEnabledField_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsSupervisoryReportsEnabledField_pcLogReports"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PcLogReportsLogRealTimeStatusReportsEnabledField_pcLogReports"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareDate_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "c042ca436fd2f6ac3f72217e8db63d7d";

export default node;
