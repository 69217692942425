/**
 *
 * RoomsIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RoomsIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M63.963,29.075c-0.26-2.659-1.944-4.575-4.678-5.424c-0.062-2.056-0.28-5.108-0.663-6.182
    c-2.011-5.599-6.764-8.951-12.712-8.97c-9.286-0.036-18.567-0.033-27.847,0c-5.913,0.018-10.795,3.479-12.739,9.03
    c-0.408,1.163-0.587,4.11-0.651,6.052c-2.488,0.733-4.288,2.704-4.611,5.313c-0.401,3.232,1.193,6.194,4.013,7.586
    c0.164,1.732,0.354,4.554,0.498,6.679c0.058,0.857,0.11,1.621,0.151,2.191c0.228,3.163,2.074,4.484,3.844,5.356
    C9.05,50.947,9.52,51.143,10,51.301v3.044C10,56.856,11.546,58,12.982,58h5.038C19.456,58,21,56.856,21,54.345v-2.454
    c7.538,0.004,14.46,0.006,22-0.002v2.456C43,56.87,44.472,58,45.931,58h5.038C52.725,58,54,56.463,54,54.345v-3.14
    c0.636-0.215,1.221-0.48,1.757-0.8c1.343-0.797,3.016-1.789,3.252-4.346c0.082-0.895,0.187-2.173,0.301-3.557
    c0.172-2.092,0.364-4.426,0.524-6.005C62.628,35.135,64.269,32.233,63.963,29.075z M9.098,18.854
    c1.389-3.966,4.745-6.341,8.978-6.354c9.273-0.032,18.544-0.035,27.82,0c4.257,0.013,7.524,2.317,8.955,6.302
    c0.173,0.566,0.339,2.741,0.416,4.396c-0.302,0.03-0.597,0.077-0.885,0.143c-3.07,0.697-5.478,2.675-6.965,5.72
    c-0.342,0.701-0.66,1.413-0.974,2.125c-2.541-0.557-5.054-0.758-7.232-0.878c-3.023-0.168-6.311-0.321-9.553-0.262
    c-4.221,0.075-8.312,0.477-12.167,1.193c-0.22-0.492-0.435-0.98-0.641-1.474c-1.519-3.633-4.448-5.904-8.172-6.405
    C8.752,21.589,8.925,19.392,9.098,18.854z M57.775,33.036c-1.048,0.4-1.722,1.262-1.847,2.365
    c-0.179,1.592-0.405,4.346-0.604,6.774c-0.113,1.368-0.216,2.633-0.298,3.517c-0.042,0.454-0.048,0.524-1.362,1.304
    c-0.514,0.307-1.197,0.541-2.031,0.696C50.687,47.869,50,48.695,50,49.658V54h-3v-4.113c0-0.531-0.211-0.983-0.586-1.358
    C46.039,48.153,45.53,48,45,48c0,0-0.001,0-0.002,0C36,48,27.999,48,19.001,48c0,0-0.001,0-0.001,0
    c-0.53,0-1.039,0.156-1.414,0.529C17.211,48.904,17,49.359,17,49.89V54h-3v-4.273c0-0.984-0.717-1.823-1.689-1.976
    c-0.732-0.115-1.301-0.297-1.971-0.629c-1.343-0.662-1.554-1.033-1.628-2.059c-0.041-0.567-0.092-1.323-0.149-2.173
    c-0.174-2.569-0.39-5.767-0.575-7.459c-0.172-1.547-1.215-2.165-1.821-2.396c-1.502-0.572-2.34-2.004-2.136-3.647
    c0.189-1.53,1.503-2.096,2.707-2.125c0.104,0.001,0.176-0.004,0.186-0.006l0.159-0.002c2.868,0,4.97,1.401,6.078,4.053
    c0.287,0.687,0.59,1.363,0.898,2.053l0.455,1.021c0.325,0.738,1.052,1.193,1.83,1.193c0.161,0,0.324-0.02,0.485-0.06l0.535-0.141
    c0.259-0.069,0.501-0.136,0.742-0.182c3.668-0.69,7.579-1.077,11.624-1.148c3.123-0.061,6.309,0.092,9.26,0.256
    c2.344,0.129,5.094,0.356,7.711,1.063c0.232,0.062,0.464,0.095,0.689,0.095c0.578,0,1.636-0.204,2.228-1.561l0.315-0.716
    c0.349-0.794,0.698-1.59,1.079-2.369c0.949-1.945,2.381-3.147,4.254-3.572c0.481-0.108,1.079-0.105,1.612,0.006
    c0.152,0.031,0.305,0.041,0.458,0.038c2.471,0.426,2.604,1.741,2.646,2.177C60.136,31.061,59.27,32.464,57.775,33.036z"
      />
    </g>
  </SvgIcon>
);

export default RoomsIcon;
