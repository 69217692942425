/**
 * @ngdoc service
 * @name App.factory:PlanManagementV1API
 *
 * @description
 * API factory that returns available Text Rate Plans
 *
 */
App.factory("PlanManagementV1API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.simsUrl + "/api/v1/dealers/:dealer_id/sims/:plan/",
      {},
      {
        text_plans: {
          method: "GET",
          isArray: true,
          params: { plan: "text_plans", dealer_id: "@dealer_id" },
          headers: { Accept: "application/json, text/plain" },
        }, //if (*/*) header is passed to the GET, the call will fail
      }
    );
  },
]);
/**
 * @ngdoc service
 * @name App.factory:PlanManagementV2API
 *
 * @description
 * API factory that returns available Rate Plans
 *
 */
App.factory("PlanManagementV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.simsUrl + "/api/v1/dealers/:dealer_id/sims/:plan/",
      {},
      {
        rate_plans: {
          method: "GET",
          isArray: true,
          params: { plan: "rate_plans", dealer_id: "@dealer_id" },
          headers: { Accept: "application/json" },
        }, //if (*/*) header is passed to the GET, the call will fail
      }
    );
  },
]);
