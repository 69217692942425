import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationFirstApnField_communication$key } from "./__generated__/CommunicationFirstApnField_communication.graphql";

export const communicationFirstApnFieldId = () => "communication-first-apn";

function CommunicationFirstApnField() {
  const [communication, updateCommunication] =
    useCommunicationFragment<CommunicationFirstApnField_communication$key>(
      graphql`
        fragment CommunicationFirstApnField_communication on Communication {
          cellApn1
        }
      `
    );

  const fieldId = communicationFirstApnFieldId();
  const { cellApn1 } = communication;

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={"First APN"}>
      <TextInput
        id={fieldId}
        maxLength={32}
        value={String(cellApn1 ?? "")}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "cellApn1");
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(target.value, "cellApn1");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationFirstApnField;
