import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreBackupIpv6AddressField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreBackupIpv6AddressField_remoteOptions.graphql";

export const remoteOptionsEntreBackupIpv6AddressFieldId = () =>
  "remote-options-entre-backup-ipv6-address";

function RemoteOptionsEntreBackupIpv6AddressField() {
  const [
    {
      entreBackupIpv6Address,
      entreConnection,
      entreBackupConnection,
      entreIpv6,
    },
    updateRemoteOptions,
  ] = useRemoteOptionsFragment<RemoteOptionsEntreBackupIpv6AddressField_remoteOptions$key>(
    graphql`
      fragment RemoteOptionsEntreBackupIpv6AddressField_remoteOptions on RemoteOptions {
        ... on XrRemoteOptions {
          entreBackupIpv6Address
          entreConnection
          entreBackupConnection
          entreIpv6
        }
      }
    `
  );

  const fieldId = remoteOptionsEntreBackupIpv6AddressFieldId();
  const disabled =
    entreConnection !== RemoteOptionsConnectionType.NETWORK ||
    entreBackupConnection !== RemoteOptionsConnectionType.NETWORK ||
    !entreIpv6;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré Backup IPv6 Address"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={entreBackupIpv6Address || ""}
        disabled={disabled}
        maxLength={39}
        pattern="(([0-9a-fA-F]{4}:){7}[0-9a-fA-F]{4})"
        validationMessage="Please enter a valid IPv6 address"
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "entreBackupIpv6Address")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreBackupIpv6AddressField;
