/**
 * @generated SignedSource<<2db28317ea1b8a7589b11a71140db03d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NetworkOptionsWifiSecurity = "NONE" | "UNSUPPORTED" | "WEP" | "WPA";
import { FragmentRefs } from "relay-runtime";
export type XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data = {
  readonly networkOptions: {
    readonly dhcpEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dnsServer: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly gatewayAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly ipv6Address: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Dns: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Enabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Gateway: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Prefix: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly listenPort734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly localIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly programmingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly subnetMask: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSecurity: {
      readonly data: NetworkOptionsWifiSecurity | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSsid: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts";
};
export type XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$key = {
  readonly " $data"?: XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts"
};

(node as any).hash = "7e552091f9e1e997c01a7043177ee33e";

export default node;
