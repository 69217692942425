/**
 * @generated SignedSource<<e1e1cb7a3aad698ae3eff868ef915928>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseBuiltIn1100WirelessEnabled_panel$data = {
  readonly systemOptions: {
    readonly useBuiltIn1100Wireless?: boolean;
  };
  readonly " $fragmentType": "PanelContextUseBuiltIn1100WirelessEnabled_panel";
};
export type PanelContextUseBuiltIn1100WirelessEnabled_panel$key = {
  readonly " $data"?: PanelContextUseBuiltIn1100WirelessEnabled_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseBuiltIn1100WirelessEnabled_panel">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "useBuiltIn1100Wireless",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseBuiltIn1100WirelessEnabled_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "systemOptions",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "XtSystemOptions",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Xt75SystemOptions",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "0a2b16a5755220c9761c077c417d9923";

export default node;
