/**
 * @generated SignedSource<<76b4c6f065e9fc82c4496ecf24805aaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XT75SystemReportsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly systemReports: {
      readonly abortReports: boolean;
      readonly ambushReports: boolean;
      readonly bypassReports: boolean;
      readonly codeChangeReports: boolean;
      readonly earlyToClose: number;
      readonly earlyToCloseMax: number;
      readonly earlyToCloseMin: number;
      readonly entryCheckinProtection: boolean;
      readonly id: string;
      readonly lateToOpen: number;
      readonly lateToOpenMax: number;
      readonly lateToOpenMin: number;
      readonly openCloseEnable: boolean;
      readonly scheduleChangeReports: boolean;
      readonly sendStoredMessage: boolean;
      readonly videoSystemReports: boolean;
      readonly zoneRestoralReports: SystemReportsZoneRestoralReports;
    } | null;
  };
  readonly " $fragmentType": "XT75SystemReportsProgrammingConceptFormInline_controlSystem";
};
export type XT75SystemReportsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75SystemReportsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75SystemReportsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75SystemReportsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "4d9dd5e5bb89c5730192e7c0c3ac653d";

export default node;
