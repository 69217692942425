/**
 * @generated SignedSource<<90d5f4939df240edd3c7abdd819043a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsContext_outputOptions$data = {
  readonly id: string;
  readonly " $fragmentType": "OutputOptionsContext_outputOptions";
};
export type OutputOptionsContext_outputOptions$key = {
  readonly " $data"?: OutputOptionsContext_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsContext_outputOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsContext_outputOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};

(node as any).hash = "fa78f993e46b5a43c3eba4892278072c";

export default node;
