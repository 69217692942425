/**
 * @generated SignedSource<<c6cd502be6be624ccfccabb6e1c92d39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly " $fragmentSpreads": FragmentRefs<"XFBellOptionsTemplateDataInline_xfProgrammingTemplateConcepts" | "XFCommunicationPathTemplateDataInline_xfProgrammingTemplateConcepts" | "XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts" | "XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts" | "XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts" | "XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts" | "XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts" | "XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts" | "XFRemoteOptionsTemplateDataInline_xfProgrammingTemplateConcepts" | "XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts" | "XFSystemReportsTemplateDataInline_xfProgrammingTemplateConcepts" | "XFZoneInformationTemplateDataInline_xfProgrammingTemplateConcepts">;
  readonly " $fragmentType": "XFTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "e4f15408dcc400508325b88a681a94c0";

export default node;
