/**
 * @ngdoc service
 * @name App.factory:ServiceRequestAPI
 *
 */
App.factory("ServiceRequestAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/ServiceRequests/:type/:dealer_id/:technician_id/:request_id",
      {},
      {
        createServiceRequest: { method: "POST" },
        getDealerServiceRequest: {
          method: "GET",
          params: { type: "dealers", dealer_id: "@dealer_id" },
          isArray: true,
        },
        getTechServiceRequest: {
          method: "GET",
          params: { type: "technicians" },
          isArray: true,
        },
        getServiceRequestById: {
          method: "GET",
          params: { request_id: "@request_id" },
        },
        editServiceRequest: {
          method: "PUT",
          params: { request_id: "@request_id" },
        },
        deleteServiceRequest: {
          method: "DELETE",
          params: { request_id: "@request_id" },
        },
      }
    );
  },
]);
