import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsSwingerBypassTripsField_systemOptions$key } from "./__generated__/SystemOptionsSwingerBypassTripsField_systemOptions.graphql";

export const systemOptionsSwingerBypassTripsFieldId = () =>
  "system-options-swinger-bypass-trips";

function SwingerBypassTripsField() {
  const [
    { swingerBypassTrips, swingerBypassTripsMin, swingerBypassTripsMax },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsSwingerBypassTripsField_systemOptions$key>(
    graphql`
      fragment SystemOptionsSwingerBypassTripsField_systemOptions on SystemOptions {
        swingerBypassTrips
        swingerBypassTripsMin
        swingerBypassTripsMax
      }
    `
  );

  const fieldId = systemOptionsSwingerBypassTripsFieldId();
  const originalValue = React.useRef(swingerBypassTrips).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Swinger Bypass Trips"
      tooltip="Set the swinger bypass trip number. Applies to all Zones with the Swinger Bypass feature enabled."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={swingerBypassTrips?.toString()}
        min={swingerBypassTripsMin}
        max={swingerBypassTripsMax}
        required
        inlineHelp={`${swingerBypassTripsMin}–${swingerBypassTripsMax}`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "swingerBypassTrips"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(
                    swingerBypassTripsMin,
                    swingerBypassTripsMax,
                    valueAsNumber
                  );
            recordProxy.setValue(Number(value), "swingerBypassTrips");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SwingerBypassTripsField;
