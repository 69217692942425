/**
 * @ngdoc service
 * @name App.factory:PanelProgrammingService
 *
 * @description
 * Service for creating, updating, and deleting Panel concepts
 *
 */
App.factory("PanelProgrammingService", [
  "$q",
  "PROPS",
  "PanelV2API",
  "JobService",
  "$filter",
  "PANEL_CONCEPTS",
  "InitialConnectionService",
  "ControlSystemsService",
  "OnlinePanelService",
  function (
    $q,
    PROP,
    PanelV2API,
    JobService,
    $filter,
    PANEL_CONCEPTS,
    InitialConnectionService,
    ControlSystemsService,
    OnlinePanelService
  ) {
    var panelService = {
      /**
       * @ngdoc object
       * @name property:panel_id
       * @type Number
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Holds the Panel ID Number.  (i.e. PanelProgrammingService.panel_id = xxxxxx)
       */
      panel_id: 0,
      sessionKey: 0,

      updateKey: function () {
        // The key is a random number (up to the maximum safe integer in JavaScript)
        this.sessionKey = Math.random() * Number.MAX_SAFE_INTEGER;
      },

      getSessionKey: function () {
        return this.sessionKey;
      },

      init: function () {
        this.updateKey();
      },

      /**
       * Set when panel is offline and either Send or Save All button is hit.
       * @type {boolean}
       */
      sendingAllProgramming: false,
      savingProgramming: false,

      /**
       * @ngdoc object
       * @name method:get
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Returns VK cache for requested V2 API Concept.  Both 'Show' and 'Index' V2 call use the Index API so that auto
       * paging can return all data in a single JSON block even if more than 100 items are returned.  Auto Paging can be
       * overridden using parameters listed below.  The 'notify' promise block will return the accumulated data up to the listed
       * page.  The status of 'paging' will be returned in the notify block to indicate that additional data may be available.
       * A 'success' status will return in the notify block when all pages have been returned.  The normal success block will
       * then return with all of the appended data.
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @param {Object} additionalParams Any additional parameters in a JSON object structure (i.e. {"include_24_hour_zones":true}).
       * @param {String} panelSessionKey - the key generated for this request
       * @param {Number} pageNumber (Optional:Default=1 or Internal) Can override the start page for the paging request.  Also used by
       *        the recursive call to track what page is next.
       * @param {Number} pageSize (Optional or Internal) Default is 101 to indicate auto paging.  Anything less, will disable
       *        auto paging.
       * @param {Number} pageData (Internal ONLY) Passes the individual page responses to the next recursion.
       * @param {Number} pageDefer (Internal ONLY) Passes the original promise to the recursive calls.
       *
       * @return {promise} The promise from the Panel API 'Index/Show'.
       */
      get: function (
        concept,
        additionalParams,
        panelSessionKey,
        pageNumber,
        pageSize,
        pageData,
        pageDefer
      ) {
        var _this = this;

        concept = PANEL_CONCEPTS[concept].api_name;
        var auto_page = true; //'true' by default.  All records are appended and returned.
        var currentPage = typeof pageNumber !== "undefined" ? pageNumber : 1; //starts at 1 and is automatically incremented.
        //Anything over 99 only returns 99 items.  Default of 100 currently used to switch auto paging process.
        pageSize = typeof pageSize !== "undefined" ? pageSize : 100;
        pageData = typeof pageData !== "undefined" ? pageData : []; //passes the accumulated data to each recursion
        var deferred =
          typeof pageDefer !== "undefined" ? pageDefer : $q.defer(); //passes the original promise to each recursion
        if (panelSessionKey !== _this.sessionKey) {
          auto_page = false;
          deferred.notify({ job_uuid: "n/a", status: "error", poll_count: 0 });
          // "STALE_STATE_CALL" indicates the $state has been changed since this request was initiated
          deferred.reject("STALE_STATE_CALL");
        }
        additionalParams =
          typeof additionalParams !== "undefined" ? additionalParams : {};
        var params = {
          panel_id: this.panel_id,
          concept: concept,
          page: currentPage,
          page_size: pageSize,
        }; //because additional params may be added, this is declared outside the API call
        for (let pname in additionalParams) {
          //additional parameters are added to the declared list
          params[pname] = additionalParams[pname];
        }
        if (
          concept.toLowerCase() === "device_informations" ||
          concept.toLowerCase() === "output_groups"
        ) {
          params.ax_door = true;
        }
        if (concept.toLowerCase() === "user_codes") {
          params.include_storage_details = true;
        }

        //TODO:Need a way to turn off auto-paging.  Purposed below:
        if (pageSize < 100) {
          auto_page = false;
        }

        PanelV2API.index(
          params, //params
          function (data) {
            //success
            if (data.array_data) {
              // Massage the array_data
              var convertedData = _this.removeConceptKeysFromArray(
                data.array_data,
                concept
              );
              pageData = pageData.concat(convertedData); //concatenates just the array part of the data
              if (data.array_data.length == pageSize && auto_page) {
                //if the array returned has the maximum number of elements, there could be more.
                deferred.notify({
                  job_uuid: "n/a",
                  status: "paging",
                  poll_count: 0,
                  page: currentPage,
                  data: (data[concept] = pageData),
                });
                _this
                  .get(
                    concept,
                    additionalParams,
                    panelSessionKey,
                    ++currentPage,
                    pageSize,
                    pageData,
                    deferred
                  )
                  .then(
                    //recursively calls 'get' to retrieve next page of data
                    function () {
                      deferred.resolve(); // the current data is appended to the 'pageData' array, so we don't resolve anything here;
                    },
                    function (nextPageError) {
                      //If an error occurs, bail.
                      deferred.notify({
                        job_uuid: "n/a",
                        status: "error",
                        poll_count: 0,
                        page: currentPage,
                      });
                      deferred.reject(nextPageError);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                //Last Page Combine data and resolve
                deferred.notify({
                  job_uuid: "n/a",
                  status: "success",
                  poll_count: 0,
                  page: currentPage,
                });
                data[concept] = pageData;
                delete data.array_data;
                deferred.resolve(data);
              }
            } else {
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
                page: currentPage,
              });
              deferred.resolve(data); //Selected concept does not return an array, or the 'auto_page' var is false.
            }
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //notify
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      removeConceptKeysFromArray: function (data, concept) {
        var convertedData = [];
        angular.forEach(data, function (value) {
          var singleItem = value[$filter("singularize")(concept)];
          convertedData.push(singleItem);
        });
        return convertedData;
      },

      /**
       * @ngdoc object
       * @name method:new
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * The 'new' API will return a set of default values including the next available sequenced number.  This only works
       * for concepts that include arrays of objects (i.e. zones, areas, etc.).
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @return {promise} The promise from the Panel API 'New'.
       */
      new: function (concept) {
        concept = PANEL_CONCEPTS[concept].api_name;
        var deferred = $q.defer();
        var _this = this;
        PanelV2API.new(
          { panel_id: _this.panel_id, concept: concept }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:refresh
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Sends command to panel to update SCAPI (cached) data from the panel.  If requested, the refreshed data is returned.
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @param {Boolean} returnResults (Optional:Default = true) A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {Object} additionalParams Any additional parameters in a JSON object structure (i.e. {"include_24_hour_zones":true}).
       * @param {String} panelSessionKey - the key generated for this request
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      refresh: function (
        concept,
        returnResults,
        additionalParams,
        panelSessionKey
      ) {
        var deferred = $q.defer();
        var _this = this;
        InitialConnectionService.ensureConnectionReady()
          .then(
            function () {
              var originalConcept = concept;
              concept = PANEL_CONCEPTS[concept].api_name;
              returnResults =
                typeof returnResults !== "undefined" ? returnResults : true;
              additionalParams =
                typeof additionalParams !== "undefined" ? additionalParams : {};
              var params = { panel_id: _this.panel_id, concept: concept }; //because additional params may be added, this is declared outside the API call
              //Additional parameters can be sent to 'refresh' (i.e. 'include_24_hour_zones'), this appends the additional params
              for (let pname in additionalParams) {
                //additional parameters are added to the declared list
                params[pname] = additionalParams[pname];
              }
              PanelV2API.refresh(
                params, //params
                function (JobId) {
                  //success
                  handleJob(
                    _this,
                    JobId,
                    originalConcept,
                    additionalParams,
                    panelSessionKey,
                    returnResults,
                    "refresh"
                  )
                    .then(
                      //The 'handleJob' function handles the Job Service processing
                      function (data) {
                        deferred.resolve(data);
                      },
                      function (error) {
                        deferred.reject(error);
                      },
                      function (info) {
                        deferred.notify(info);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  //failure
                  deferred.reject(error);
                }
              );
            },
            function (error) {
              console.error(
                "PanelService->refresh() - ensure initial connection established error: " +
                  angular.toJson(error)
              );
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:restore
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Sends command to VK to send the programming from its cache to the panel.
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      restore: function (concept) {
        var deferred = $q.defer();
        var _this = this;
        InitialConnectionService.ensureConnectionReady()
          .then(
            function () {
              concept = PANEL_CONCEPTS[concept].api_name;
              var params = { panel_id: _this.panel_id, concept: concept };
              PanelV2API.restore(
                params,
                function (data) {
                  JobService.process(data.job.uuid).then(
                    (success) => {
                      deferred.resolve(success);
                    },
                    (error) => {
                      deferred.reject(error);
                    },
                    (info) => {
                      deferred.notify(info);
                    }
                  );
                },
                function (error) {
                  deferred.reject(error);
                },
                function (info) {}
              );
            },
            function (error) {
              console.error(
                "PanelService->refresh() - ensure initial connection established error: " +
                  angular.toJson(error)
              );
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:delete
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Used to delete a item from a multi-item concept using the key identifier.
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @param {String} itemId The key necessary to identify the selected item.
       * @param {Boolean} returnResults (Optional:Default = true) A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {String} panelSessionKey - the key generated for this request
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      delete: function (concept, itemId, returnResults, panelSessionKey) {
        var originalConcept = concept;
        concept = PANEL_CONCEPTS[concept].api_name;
        returnResults =
          typeof returnResults !== "undefined" ? returnResults : true;
        var deferred = $q.defer();
        var _this = this;

        function destroyItem() {
          PanelV2API.destroy(
            { panel_id: _this.panel_id, concept: concept, item_id: itemId }, //params
            function (JobId) {
              //success
              if (!JobId.job) {
                //Delete calls to unconnected panels return 204 No Content, so just resolve here
                deferred.resolve(
                  returnResults
                    ? panelService.get(
                        originalConcept,
                        undefined,
                        panelSessionKey
                      )
                    : {
                        status: "success",
                        action: "delete",
                        concept: originalConcept,
                      }
                );
              } else {
                //All other delete calls return a job
                handleJob(
                  _this,
                  JobId,
                  originalConcept,
                  undefined,
                  panelSessionKey,
                  returnResults,
                  "delete"
                )
                  .then(
                    //The 'handleJob' function handles the Job Service processing
                    function (data) {
                      deferred.resolve(data);
                    },
                    function (error) {
                      deferred.reject(error);
                    },
                    function (info) {
                      deferred.notify(info);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              }
            },
            function (error) {
              //failure
              deferred.reject(error);
            }
          );
        }
        if (OnlinePanelService.isOffline()) {
          //Pre-programming, don't attempt initial connect
          destroyItem();
        } else {
          //Panel online, attempt initial connect
          InitialConnectionService.ensureConnectionReady()
            .then(destroyItem(), function (error) {
              console.error(
                "PanelService->delete() - ensure initial connection established error: " +
                  angular.toJson(error)
              );
              deferred.reject(error);
            })
            .catch(function (error) {
              console.error(error);
            });
        }

        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:update
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Used to update both single and multi-item concepts.  See Jenkins documentation for details on Post Body structure.
       * http://jenkins.dmp.com:8080/
       * If a particular value is invalid for the associated property, a 422-validation error will be returned.
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @param {Object} updateBody The properties and values to be updated.
       * @param {String} itemId (Optional:Default:'') The key necessary to identify the selected item.  Only required for multi-item concepts.
       * @param {Boolean} returnResults (Optional:Default = true) A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {String} panelSessionKey - the key generated for this request
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      update: function (
        concept,
        updateBody,
        itemId,
        returnResults,
        panelSessionKey
      ) {
        var originalConcept = concept;
        concept = PANEL_CONCEPTS[concept].api_name;
        itemId = typeof itemId !== "undefined" ? itemId : "";
        returnResults =
          typeof returnResults !== "undefined" ? returnResults : true;
        var deferred = $q.defer();
        var _this = this;
        if (_this.savingProgramming) {
          if (OnlinePanelService.isOffline()) {
            // If the panel is offline, data that is sent won't be sent to the panel.
            return performUpdate(
              _this,
              concept,
              itemId,
              updateBody,
              originalConcept,
              panelSessionKey,
              returnResults
            );
          } else {
            deferred.reject("system is not offline");
          }
        } else {
          // If the panel is online, make sure it has had an initial connection
          InitialConnectionService.ensureConnectionReady()
            .then(
              function () {
                performUpdate(
                  _this,
                  concept,
                  itemId,
                  updateBody,
                  originalConcept,
                  panelSessionKey,
                  returnResults
                )
                  .then(
                    function (data) {
                      deferred.resolve(data);
                    },
                    function (error) {
                      deferred.reject(error);
                    },
                    function (info) {
                      deferred.notify(info);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                console.error(
                  "PanelService->update() - ensure initial connection established error: " +
                    angular.toJson(error)
                );
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:create
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * Used to create both single and multi-item concepts.  See Jenkins documentation for details on Post Body structure.
       * http://jenkins.dmp.com:8080/
       * If a particular value is invalid for the associated property, a 422-validation error will be returned.
       *
       * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
       * @param {Object} newBody The properties and values to be created.
       * @param {Boolean} returnResults (Optional:Default = true) A boolean flag to indicate whether or not to return the cached data after the job completes.
       * @param {String} panelSessionKey - the key generated for this request
       * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
       */
      create: function (concept, newBody, returnResults, panelSessionKey) {
        var originalConcept = concept;
        concept = PANEL_CONCEPTS[concept].api_name;
        returnResults =
          typeof returnResults !== "undefined" ? returnResults : true;
        var deferred = $q.defer();
        var _this = this;
        if (_this.savingProgramming) {
          if (OnlinePanelService.isOffline()) {
            // If the panel is offline, data that is sent won't be sent to the panel.
            return performCreate(
              _this,
              concept,
              newBody,
              originalConcept,
              panelSessionKey,
              returnResults
            );
          } else {
            deferred.reject("system is not offline");
          }
        } else {
          // If the panel is online, make sure it has had an initial connection
          InitialConnectionService.ensureConnectionReady()
            .then(
              function () {
                performCreate(
                  _this,
                  concept,
                  newBody,
                  originalConcept,
                  panelSessionKey,
                  returnResults
                )
                  .then(
                    function (data) {
                      deferred.resolve(data);
                    },
                    function (error) {
                      deferred.reject(error);
                    },
                    function (info) {
                      deferred.notify(info);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              },
              function (error) {
                console.error(
                  "PanelService->create() - ensure initial connection established error: " +
                    angular.toJson(error)
                );
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:disconnect
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * The 'disconnect' API will force a panel disconnect
       *
       * @return {promise} The promise from the Panel API 'disconnect'.
       */
      disconnect: function () {
        var deferred = $q.defer();
        var _this = this;
        InitialConnectionService.ensureConnectionReady()
          .then(
            function () {
              PanelV2API.disconnect(
                { panel_id: _this.panel_id }, //params
                function (data) {
                  //success
                  JobService.process(data.job.uuid)
                    .then(
                      function (data) {
                        deferred.resolve(data);
                      },
                      function (error) {
                        deferred.reject(error);
                      },
                      function (info) {
                        deferred.notify(info);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  //failure
                  deferred.notify({
                    job_uuid: "n/a",
                    status: "error",
                    poll_count: 0,
                  });
                  deferred.reject(error);
                },
                function (info) {
                  //failure
                  deferred.notify(info);
                }
              );
            },
            function (error) {
              console.error(
                "PanelService->disconnect() - ensure initial connection established error: " +
                  angular.toJson(error)
              );
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:sendAppFeatures
       * @methodOf App.factory:PanelProgrammingService
       *
       * @description
       * The 'sendAppFeatures' API send the current app features string to the panel.
       *
       * @return {promise} The promise from the Panel API 'sendAppFeatures'.
       */
      sendAppFeatures: function () {
        var deferred = $q.defer();
        var _this = this;
        InitialConnectionService.ensureConnectionReady()
          .then(
            function () {
              PanelV2API.sendAppFeatures(
                { panel_id: _this.panel_id }, //params
                function (data) {
                  //success
                  JobService.process(data.job.uuid)
                    .then(
                      function (data) {
                        deferred.resolve(data);
                      },
                      function (error) {
                        deferred.reject(error);
                      },
                      function (info) {
                        deferred.notify(info);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  //failure
                  deferred.notify({
                    job_uuid: "n/a",
                    status: "error",
                    poll_count: 0,
                  });
                  deferred.reject(error);
                },
                function (info) {
                  //failure
                  deferred.notify(info);
                }
              );
            },
            function (error) {
              console.error(
                "PanelService->sendAppFeatures() - ensure initial connection established error: " +
                  angular.toJson(error)
              );
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },
    };

    /**
     * @ngdoc object
     * @name method:handleJob
     * @methodOf App.factory:PanelProgrammingService
     *
     * @description
     * Handles the call to the Job Service, checks for proper Job UUID and returns data based on parameters.
     *
     * @param {Object} data The data JSON containing the Job UUID.
     * @param {String} concept API Concept to program.  (i.e. zone_informations, remote_options, etc.) Use constants PANEL_CONCEPTS.xxxxxx
     * @param {Object} additionalParams Any additional parameters in a JSON object structure (i.e. {"include_24_hour_zones":true}).
     * @param {String} panelSessionKey - the key generated for this request
     * @param {Boolean} returnResults A boolean flag to indicate whether or not to return the cached data after the job completes.
     * @param {String} action The name of the method calling the handleJob function (i.e. 'update','delete', etc.).
     * @return {promise} Either the cached data for the requested concept or a status object (if 'returnResults' = false).
     */
    function handleJob(
      panelService,
      data,
      concept,
      additionalParams,
      panelSessionKey,
      returnResults,
      action
    ) {
      var originalConcept = concept;
      var deferred = $q.defer();
      if (angular.isDefined(data.job)) {
        //sanity check for job object
        if (data.job.status === "error") {
          //check status and bail if error is returned in the initial call
          deferred.reject(data);
        }
        // If the caller didn't provide a job.uuid, or provided an empty value, there's no point in going further.
        if (angular.isUndefined(data.job.uuid) || data.job.uuid.trim() === "") {
          deferred.reject("NO_JOB_ID_AVAILABLE"); //Returns i18n constant name for internationalization.
        }
        JobService.process(data.job.uuid)
          .then(
            function (jobData) {
              //once the JobService returns with a success, we either return the success status or the cache data
              deferred.resolve(
                returnResults
                  ? panelService.get(
                      originalConcept,
                      additionalParams,
                      panelSessionKey
                    )
                  : {
                      status: jobData.status,
                      action: action,
                      concept: concept,
                    }
              );
            },
            function (errorData) {
              deferred.reject(errorData);
            },
            function (info) {
              deferred.notify(info);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        deferred.reject(data);
      }
      return deferred.promise;
    }

    function performUpdate(
      panelService,
      concept,
      itemId,
      updateBody,
      originalConcept,
      panelSessionKey,
      returnResults
    ) {
      var deferred = $q.defer();
      var params = {
        panel_id: panelService.panel_id,
        concept: concept,
        item_id: itemId,
      };
      attachAnyExtraParams(concept, updateBody, params);
      PanelV2API.update(
        params,
        updateBody,
        function (JobId) {
          //success
          handleJob(
            panelService,
            JobId,
            originalConcept,
            undefined,
            panelSessionKey,
            returnResults,
            "update"
          )
            .then(
              //The 'handleJob' function handles the Job Service processing
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },
        function (error) {
          //failure
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function performCreate(
      panelService,
      concept,
      newBody,
      originalConcept,
      panelSessionKey,
      returnResults
    ) {
      var deferred = $q.defer();
      var params = {
        panel_id: panelService.panel_id,
        concept: concept,
      };
      attachAnyExtraParams(concept, newBody, params);
      PanelV2API.create(
        params,
        newBody,
        function (JobId) {
          //success
          handleJob(
            panelService,
            JobId,
            originalConcept,
            undefined,
            panelSessionKey,
            returnResults,
            "create"
          )
            .then(
              //The 'handleJob' function handles the Job Service processing
              function (data) {
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              },
              function (info) {
                deferred.notify(info);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },
        function (error) {
          //failure
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function attachAnyExtraParams(concept, entity, params) {
      // If creating a user_code and the setCredentialQueryFlag property is attached, attach a query flag and delete the property
      if (concept === "user_codes" && entity.user_code.setCredentialQueryFlag) {
        params.use_credential = true;
        delete entity.user_code.setCredentialQueryFlag;
      }
    }

    return panelService;
  },
]);
