import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { EolResistorValue } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsEolResistorValueField_systemOptions$key } from "./__generated__/SystemOptionsEolResistorValueField_systemOptions.graphql";

export const systemOptionsEolResistorValueFieldId = () =>
  "system-options-eol-resistor-value";

function EolResistorValueField() {
  const [{ eolResistorValue }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsEolResistorValueField_systemOptions$key>(
      graphql`
        fragment SystemOptionsEolResistorValueField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            eolResistorValue
          }
          ... on XtSystemOptions {
            eolResistorValue
          }
        }
      `
    );

  const fieldId = systemOptionsEolResistorValueFieldId();
  const softwareVersion = useSoftwareVersion();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="EOL Resistor Value"
      tooltip="Allows selection of the resistance value for on-board burglary zone circuits."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={eolResistorValue}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "eolResistorValue");
          });
        }}
      >
        <Select.Option value={EolResistorValue.ONE_THOUSAND}>1K</Select.Option>
        <Select.Option value={EolResistorValue.TWO_POINT_TWO_THOUSAND}>
          2.2K
        </Select.Option>
        {softwareVersion >= 693 && (
          <>
            <Select.Option value={EolResistorValue.THREE_POINT_THREE_THOUSAND}>
              3.3K
            </Select.Option>
            <Select.Option value={EolResistorValue.FOUR_POINT_SEVEN_THOUSAND}>
              4.7K
            </Select.Option>
          </>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default EolResistorValueField;
