/**
 * @generated SignedSource<<00456f9397f92783a1ee9f58506a7680>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSiteOutputInput = {
  delete?: boolean | null;
  name?: string | null;
  relayNumber: number;
};
export type SiteOutputModulesFormUpdateMutation$variables = {
  name: string;
  outputModule: string;
  outputs: ReadonlyArray<UpdateSiteOutputInput>;
};
export type SiteOutputModulesFormUpdateMutation$data = {
  readonly updateSiteControlSystemOutputModule: {
    readonly __typename: "UpdateSiteControlSystemOutputModuleSuccessResponse";
    readonly error?: {
      readonly __typename: string;
    };
    readonly outputModule?: {
      readonly " $fragmentSpreads": FragmentRefs<"SiteOutputModulesFormExistingModule_siteOutputModule">;
    };
  };
};
export type SiteOutputModulesFormUpdateMutation = {
  response: SiteOutputModulesFormUpdateMutation$data;
  variables: SiteOutputModulesFormUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "outputModule"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "outputs"
},
v3 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "outputModule",
    "variableName": "outputModule"
  },
  {
    "kind": "Variable",
    "name": "outputs",
    "variableName": "outputs"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateSiteControlSystemOutputModuleFailureResponse",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v9 = [
  (v7/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOutputModulesFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateSiteControlSystemOutputModule",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteOutputModule",
                "kind": "LinkedField",
                "name": "outputModule",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SiteOutputModulesFormExistingModule_siteOutputModule"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateSiteControlSystemOutputModuleSuccessResponse",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteOutputModulesFormUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateSiteControlSystemOutputModule",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteOutputModule",
                "kind": "LinkedField",
                "name": "outputModule",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteControlSystem",
                    "kind": "LinkedField",
                    "name": "controlSystem",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteDoor",
                        "kind": "LinkedField",
                        "name": "doors",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serialNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxOutputModules",
                        "storageKey": null
                      },
                      {
                        "alias": "namedDoors",
                        "args": null,
                        "concreteType": "SiteDoor",
                        "kind": "LinkedField",
                        "name": "doors",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteOutputModulesConnection",
                        "kind": "LinkedField",
                        "name": "outputModulesConnection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteOutputModule",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteOutputModuleOutputsConnection",
                    "kind": "LinkedField",
                    "name": "outputsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteOutput",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "relayNumber",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateSiteControlSystemOutputModuleSuccessResponse",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "622bdcad734f31abf1301d64c58fb210",
    "id": null,
    "metadata": {},
    "name": "SiteOutputModulesFormUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation SiteOutputModulesFormUpdateMutation(\n  $outputModule: ID!\n  $name: String!\n  $outputs: [UpdateSiteOutputInput!]!\n) {\n  updateSiteControlSystemOutputModule(outputModule: $outputModule, name: $name, outputs: $outputs) {\n    __typename\n    ... on UpdateSiteControlSystemOutputModuleSuccessResponse {\n      __typename\n      outputModule {\n        ...SiteOutputModulesFormExistingModule_siteOutputModule\n        id\n      }\n    }\n    ... on UpdateSiteControlSystemOutputModuleFailureResponse {\n      error {\n        __typename\n      }\n    }\n  }\n}\n\nfragment SiteOutputModulesFormExistingModule_siteOutputModule on SiteOutputModule {\n  id\n  name\n  address\n  controlSystem {\n    doors {\n      name\n      id\n    }\n    ...SiteOutputModulesForm_OutputModuleForm_siteControlSystem\n    id\n  }\n  outputsConnection {\n    nodes {\n      name\n      relayNumber\n      id\n    }\n  }\n}\n\nfragment SiteOutputModulesForm_OutputModuleForm_siteControlSystem on SiteControlSystem {\n  id\n  serialNumber\n  maxOutputModules\n  namedDoors: doors {\n    name\n    id\n  }\n  outputModulesConnection {\n    nodes {\n      id\n      address\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6bee8bfbc5a7eecffcd3715ebdc5b9e0";

export default node;
