//using the TMSentryTemplateSaveMutation because they are similar enough
import graphql from "babel-plugin-relay/macro";

export const tMSentryTemplateSaveMutation = graphql`
  mutation TMSentryTemplateSaveMutation(
    $dealerId: ID!
    $templateData: TakeoverProgrammingTemplateInput!
  ) {
    saveTakeoverProgrammingTemplate(
      dealerId: $dealerId
      templateData: $templateData
    ) {
      ... on SaveProgrammingTemplateErrorResult {
        error
        messages
      }
      ... on SaveProgrammingTemplateSuccessResult {
        programmingTemplate {
          concepts {
            ...TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts
          }
        }
      }
      __typename
    }
  }
`;
