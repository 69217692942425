/**
 * @generated SignedSource<<a8789a4fd1c5aaebaeee94db2929eb1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay$data = {
  readonly armedStatusDisplayKeypads: string;
  readonly " $fragmentType": "MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay";
};
export type MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay$key = {
  readonly " $data"?: MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MenuDisplayArmedStatusDisplayKeypadsField_menuDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedStatusDisplayKeypads",
      "storageKey": null
    }
  ],
  "type": "MenuDisplay",
  "abstractKey": null
};

(node as any).hash = "c6f9cd1f6bf386596380582aed99bd87";

export default node;
