/**
 * @generated SignedSource<<0d58a39af00519b886e9f8e04a733e2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneMessage = "ALARM" | "CANCEL_AMBUSH" | "DOOR_PROPPED_OPEN" | "LOCAL" | "NONE" | "SENSOR_RESET" | "TROUBLE";
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationPrewarnKeypadsField_zone$data = {
  readonly armedOpenActionMessage: ZoneMessage | null;
  readonly armedShortActionMessage: ZoneMessage | null;
  readonly disarmedOpenActionMessage: ZoneMessage | null;
  readonly disarmedShortActionMessage: ZoneMessage | null;
  readonly number: string;
  readonly prewarnKeypads: string;
  readonly type: ZoneType;
  readonly " $fragmentType": "ZoneInformationPrewarnKeypadsField_zone";
};
export type ZoneInformationPrewarnKeypadsField_zone$key = {
  readonly " $data"?: ZoneInformationPrewarnKeypadsField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationPrewarnKeypadsField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationPrewarnKeypadsField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prewarnKeypads",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disarmedShortActionMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disarmedOpenActionMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedShortActionMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedOpenActionMessage",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "2db988560bc8cd5c2f84bcbd89facac2";

export default node;
