/**
 * @ngdoc service
 * @name App.factory: AuthenticationAPI
 *
 * @description
 * API factory for getting persons and their users
 *
 */
App.factory("AuthenticationAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v3/authenticate",
      {},
      {
        authenticate: {
          method: "POST",
          isArray: true,
          headers: { "Accessible-Type": PROPS.authAccessTypeKey },
        },
      }
    );
  },
]);
