/**
 * @generated SignedSource<<f2c8b7a7dfdb14bf3ea15477ccf5bb3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DoorStatus = "LOCKED" | "UNKNOWN" | "UNLOCKED";
import { FragmentRefs } from "relay-runtime";
export type DoorWidgetFragment_door$data = {
  readonly id: string;
  readonly isPublic: boolean;
  readonly name: string;
  readonly number: number;
  readonly realTimeStatus: boolean;
  readonly status: DoorStatus;
  readonly strikeDelay: number;
  readonly tracked: boolean;
  readonly " $fragmentType": "DoorWidgetFragment_door";
};
export type DoorWidgetFragment_door$key = {
  readonly " $data"?: DoorWidgetFragment_door$data;
  readonly " $fragmentSpreads": FragmentRefs<"DoorWidgetFragment_door">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DoorWidgetFragment_door",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tracked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realTimeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "strikeDelay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Door",
  "abstractKey": null
};

(node as any).hash = "4381fe3dc64f3b90476b5f67b6844afd";

export default node;
