App.factory("DealerV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/dealers/:dealer_id/:customers",
      {},
      {
        index: {
          method: "GET",
          params: { customers: "customers" },
          isArray: true,
        },
        show: { method: "GET", params: {} },
        update: { method: "PUT", params: { dealer_id: "@dealer_id" } },
        create: { method: "POST" },
        new: { method: "GET", params: { dealer_id: "new" } },
        indexDealers: { method: "GET", isArray: true },
      }
    );
  },
]);
