/**
 * @generated SignedSource<<16746ce1d1ce41efc41a23f8203c4a52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsEntryDelay3Field_systemOptions$data = {
  readonly entryDelay3?: number;
  readonly entryDelayMax?: number;
  readonly entryDelayMin?: number;
  readonly " $fragmentType": "SystemOptionsEntryDelay3Field_systemOptions";
};
export type SystemOptionsEntryDelay3Field_systemOptions$key = {
  readonly " $data"?: SystemOptionsEntryDelay3Field_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsEntryDelay3Field_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsEntryDelay3Field_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entryDelay3",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entryDelayMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entryDelayMax",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "677b9711ff3067d2e6f152bd2cc3ad67";

export default node;
