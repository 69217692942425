import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { lockoutCodeFieldId } from "components/FullProgramming/common/LockoutCodeFields/LockoutCodeField";
import { XRLockoutCodeProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRLockoutCodeProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getLockoutCodeState } from "../../../XRFullProgramming/XRLockoutCodeProgrammingConceptForm";
import { ProgrammingTemplateLockoutCodeInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        lockoutCode {
          id
          included
          code {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRLockoutCodeProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateLockoutCodeInput => {
  const {
    panel: { lockoutCode },
  } = getLockoutCodeState(controlSystem);

  if (!lockoutCode) {
    return { included: false };
  }

  return {
    included: includedFields.has(lockoutCodeFieldId()),
    code: {
      included: includedFields.has(lockoutCodeFieldId()),
      data: lockoutCode.code,
    },
  };
};

export const setIncludedFields = (
  template: XRLockoutCodeTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { lockoutCode } = getState(template);

  if (lockoutCode?.code?.included) {
    setIncludedFields(setToggle(lockoutCodeFieldId()));
  }
};
