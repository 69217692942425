App.factory("DealerSettingsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/dealers/:dealer_id/:concept/:id",
      {},
      {
        nextAccountNumber: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "account_number",
            id: "@id",
          },
        },
        submitAutoProg: {
          method: "POST",
          params: {
            dealer_id: "@dealer_id",
            concept: "AutoActivate",
            id: "@id",
          },
        },
        checkEasyConnect: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "EasyConnectStatus",
            id: "@id",
          },
        },
        checkAutoProg: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "AutoActivateStatus",
            id: "@id",
          },
        },
        getOrdersSummary: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "OrdersSummary",
            id: "@id",
          },
          isArray: true,
        },
        checkModemStatus: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "ModemConnectStatus",
            id: "@id",
          },
        },
        getTestEvents: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            concept: "EventActions",
            id: "@id",
          },
          isArray: true,
        },
      }
    );
  },
]);
