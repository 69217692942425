/**
 * @generated SignedSource<<945ce5437d74f4c4eed8b42995b55607>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsInactiveUserCodeAuditField_systemOptions$data = {
  readonly inactiveUserCodeAudit?: number;
  readonly inactiveUserCodeAuditMax?: number;
  readonly inactiveUserCodeAuditMin?: number;
  readonly " $fragmentType": "SystemOptionsInactiveUserCodeAuditField_systemOptions";
};
export type SystemOptionsInactiveUserCodeAuditField_systemOptions$key = {
  readonly " $data"?: SystemOptionsInactiveUserCodeAuditField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsInactiveUserCodeAuditField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsInactiveUserCodeAuditField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inactiveUserCodeAudit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inactiveUserCodeAuditMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inactiveUserCodeAuditMax",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "9da7245f1dd02bb9463b3e2a6594df18";

export default node;
