import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { clamp } from "ramda";
import * as React from "react";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsInactiveUserCodeAuditField_systemOptions$key } from "./__generated__/SystemOptionsInactiveUserCodeAuditField_systemOptions.graphql";

export const systemOptionsInactiveUserCodeAuditFieldId = () =>
  "system-options-inactive-user-code-audit";

function SystemOptionsInactiveUserCodeAuditField() {
  const [
    {
      inactiveUserCodeAudit,
      inactiveUserCodeAuditMax,
      inactiveUserCodeAuditMin,
    },
    updateSystemOptions,
  ] = useSystemOptionsFragment<SystemOptionsInactiveUserCodeAuditField_systemOptions$key>(
    graphql`
      fragment SystemOptionsInactiveUserCodeAuditField_systemOptions on SystemOptions {
        ... on XrSystemOptions {
          inactiveUserCodeAudit
          inactiveUserCodeAuditMin
          inactiveUserCodeAuditMax
        }
      }
    `
  );

  const fieldId = systemOptionsInactiveUserCodeAuditFieldId();
  const softwareVersion = useSoftwareVersion();
  const originalValue = React.useRef(inactiveUserCodeAudit).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Inactive User Code Audit"
      tooltip="Send daily messages for any User Codes that have been inactive for more than the programmed number of days."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={inactiveUserCodeAudit?.toString()}
        required
        min={0}
        max={365}
        inlineHelp={`${inactiveUserCodeAuditMin}–${inactiveUserCodeAuditMax}`}
        pattern={
          softwareVersion >= 113
            ? "(([0-3]?[0-9]?|4[0-1])[0-9]|42[0-5])"
            : "(([0-2]?[0-9]?|3[0-5])[0-9]|36[0-5])"
        }
        validationMessage={
          softwareVersion >= 113
            ? "Valid values are 0-425."
            : "Valid values are 0-365."
        }
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "inactiveUserCodeAudit");
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(
                    inactiveUserCodeAuditMin,
                    inactiveUserCodeAuditMax,
                    valueAsNumber
                  );
            recordProxy.setValue(Number(value), "inactiveUserCodeAudit");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsInactiveUserCodeAuditField;
