import { always, isNil, prop } from "ramda";
import * as React from "react";
import ReactDOM from "react-dom";
import { Motion, spring } from "react-motion";
import ResizeObserver from "resize-observer-polyfill";
import styled, { css, ThemeProvider } from "styled-components";
import useLockedValue from "../../../react-hooks/use-locked-value";
import useOnClickOutside, {
  ClickOutsideEvent,
} from "../../../react-hooks/use-on-click-outside";
import useWindowDimensions from "../../../react-hooks/use-window-dimensions";
import noop from "../../../utils/universal/noop";
import { getElementOffsetTop } from "../../../utils/web/element";

export enum MessagePosition {
  Top = "top",
  Bottom = "bottom",
  Left = "left",
  Right = "right",
}

export type TooltipTheme = {
  padding: React.CSSProperties["padding"];
  backgroundColor: React.CSSProperties["backgroundColor"];
  backgroundColorHover?: React.CSSProperties["backgroundColor"];
  textColor: React.CSSProperties["color"];
  textColorHover: React.CSSProperties["color"];
  borderColor: React.CSSProperties["borderColor"];
  borderWidth: React.CSSProperties["borderWidth"];
  caretStrokeColor: React.CSSProperties["stroke"];
  caretFill: React.CSSProperties["fill"];
  caretStrokeWidth: React.CSSProperties["strokeWidth"];
};

type Dimensions = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
  x: number;
  y: number;
};

type Props = {
  children: React.ReactNode;
  anchor<T extends HTMLElement>(props: {
    registerAnchor: React.RefObject<T>;
  }): React.ReactNode;
  maxHeight?: number;
  animated?: boolean;
  open?: boolean;
  fixed?: boolean;
  style?: React.CSSProperties;
  onClose?: () => void;
  anchorX?: number;
  anchorY?: number;
  position?: MessagePosition;
  className?: string;
  onDismiss?: (event: ClickOutsideEvent) => void;
  zIndex?: number;
  baseFontSize?: React.CSSProperties["fontSize"];
};

function TooltipPortal({ children }: { children: React.ReactNode }) {
  const salt = Date.now();
  const node = React.useRef(document.getElementById(`tooltip-root-${salt}`));

  React.useLayoutEffect(() => {
    if (!node.current) {
      const root = document.createElement("div");
      root.id = `"tooltip-root-${salt}"`;
      node.current = root;
      document.body.appendChild(root);

      return () => {
        document.body.removeChild(root);
      };
    }
  }, []);

  return node.current && ReactDOM.createPortal(children, node.current);
}

const MESSAGE_OFFSET_IN_PX = 10;
const CARET_WIDTH = 16;

const isXPosition = (position: MessagePosition) =>
  [MessagePosition.Left, MessagePosition.Right].includes(position);

const isYPosition = (position: MessagePosition) =>
  [MessagePosition.Top, MessagePosition.Bottom].includes(position);

const springConfig = always({
  stiffness: 377,
  damping: 28,
});

function getMessageOffsetX({
  anchorDimensions,
  messagePosition,
  windowWidth,
  messageWidth,
}: {
  anchorDimensions: Dimensions;
  messagePosition: MessagePosition;
  windowWidth: number;
  messageWidth: number;
}) {
  if (isXPosition(messagePosition)) {
    return 0;
  }

  const anchorWidth = anchorDimensions.width || 0;

  if ((!anchorDimensions.left && !anchorWidth) || !messageWidth) {
    return 0;
  }

  const anchorMiddle = anchorDimensions.left + anchorWidth / 2;
  const messageLeft = anchorMiddle - messageWidth / 2;
  const messageRight = anchorMiddle + messageWidth / 2;
  const gutterLeft = 5;
  const gutterRight = windowWidth - 5;

  if (messageLeft < gutterLeft) {
    return gutterLeft - messageLeft;
  }

  if (messageRight > gutterRight) {
    return gutterRight - messageRight;
  }

  return 0;
}

export function TooltipThemeProvider(props: {
  theme?: TooltipTheme | ((parentTheme: any) => TooltipTheme);
  children: React.ReactNode;
}) {
  return <ThemeProvider theme={props.theme}>{props.children}</ThemeProvider>;
}

function Tooltip({
  children,
  anchor,
  maxHeight = 300,
  animated = true,
  open = false,
  fixed = false,
  style = {},
  onClose = noop,
  anchorX,
  anchorY,
  position,
  className,
  onDismiss,
  zIndex = 1,
  baseFontSize,
}: Props) {
  const anchorRef = React.useRef<HTMLElement | null>(null);
  const messageRef = React.useRef<HTMLDivElement | null>(null);
  const windowDimensions = useWindowDimensions();
  const [messageDimensions, setMessageDimensions] = React.useState<Dimensions>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const [anchorDimensions, setAnchorDimensions] = React.useState<Dimensions>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const [animating, setAnimating] = React.useState(false);
  const [windowScroll, setWindowScroll] = React.useState({
    x: window.scrollX,
    y: window.scrollY,
  });
  const cachedChildren = useLockedValue(children, !open);

  const bottomWouldOverflow =
    anchorDimensions.top +
      anchorDimensions.height +
      messageDimensions.height +
      MESSAGE_OFFSET_IN_PX >
    windowDimensions.height + windowScroll.y;

  const topWouldOverflow =
    anchorDimensions.top - messageDimensions.height - MESSAGE_OFFSET_IN_PX < 0;

  const leftWouldOverflow =
    anchorDimensions.left - messageDimensions.width - MESSAGE_OFFSET_IN_PX < 0;

  const rightWouldOverflow =
    anchorDimensions.left +
      anchorDimensions.width +
      messageDimensions.width +
      MESSAGE_OFFSET_IN_PX >
    windowDimensions.width;

  const messagePosition =
    position ||
    (!bottomWouldOverflow
      ? MessagePosition.Bottom
      : !topWouldOverflow
      ? MessagePosition.Top
      : !leftWouldOverflow
      ? MessagePosition.Left
      : !rightWouldOverflow
      ? MessagePosition.Right
      : MessagePosition.Bottom);

  const normalizeAnchorBoundingClientRect = React.useCallback(
    (rect) => {
      const top = !isNil(anchorY)
        ? anchorY
        : anchorRef.current
        ? getElementOffsetTop(anchorRef.current)
        : 0;
      return {
        top,
        right: rect.right,
        bottom: top + rect.height,
        left: !isNil(anchorX) ? anchorX : rect.left,
        width: rect.width,
        height: rect.height,
        x: rect.x,
        y: rect.y,
      };
    },
    [anchorX, anchorY]
  );

  React.useLayoutEffect(() => {
    if (open && anchorRef.current) {
      setAnchorDimensions(
        normalizeAnchorBoundingClientRect(
          anchorRef.current.getBoundingClientRect()
        )
      );
    }
    if (open && messageRef.current) {
      setMessageDimensions(messageRef.current.getBoundingClientRect());
    }
  }, [anchorX, anchorY, normalizeAnchorBoundingClientRect, open]);

  useOnClickOutside(
    messageRef,
    (event) => {
      if (
        onDismiss &&
        open &&
        !anchorRef.current?.contains(event.target as HTMLElement)
      ) {
        onDismiss(event);
      }
    },
    open
  );

  React.useLayoutEffect(() => {
    if (open) {
      setWindowScroll({
        x: window.scrollX,
        y: window.scrollY,
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (open) {
      const handleScroll = () => {
        setWindowScroll({
          x: window.scrollX,
          y: window.scrollY,
        });
      };

      const handleResize = () => {
        if (anchorRef.current) {
          setAnchorDimensions(
            normalizeAnchorBoundingClientRect(
              anchorRef.current.getBoundingClientRect()
            )
          );
        }
      };

      const resizeObserver = new ResizeObserver(([{ target }]) => {
        setAnchorDimensions(
          normalizeAnchorBoundingClientRect(target.getBoundingClientRect())
        );
      });

      if (anchorRef.current) {
        resizeObserver.observe(anchorRef.current);
      }

      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);

      return () => {
        resizeObserver.disconnect();
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [anchorX, anchorY, normalizeAnchorBoundingClientRect, open]);

  return (
    <Root className={className}>
      {anchor({ registerAnchor: anchorRef })}
      <Motion
        defaultStyle={{
          offset: 0,
          opacity: 0,
        }}
        style={
          open
            ? {
                offset: animated
                  ? spring(MESSAGE_OFFSET_IN_PX, springConfig())
                  : MESSAGE_OFFSET_IN_PX,
                opacity: animated ? spring(1, springConfig()) : 1,
              }
            : {
                offset: animated ? spring(0, springConfig()) : 0,
                opacity: animated ? spring(0, springConfig()) : 0,
              }
        }
        onRest={() => {
          setAnimating(false);
          onClose();
        }}
      >
        {({ offset, opacity }) => (
          <TooltipPortal>
            <MessageWrapper
              fontSize={baseFontSize}
              zIndex={zIndex}
              anchorDimensions={anchorDimensions}
              animating={animating}
              fixed={fixed}
              ref={messageRef}
              isOpen={open}
              position={messagePosition}
              style={{
                ...style,
                opacity,
                transform:
                  messagePosition === MessagePosition.Left
                    ? `translate(calc(-100% - ${offset}px), -50%)`
                    : messagePosition === MessagePosition.Right
                    ? `translate(${offset}px, -50%)`
                    : messagePosition === MessagePosition.Top
                    ? `translate(-50%, calc(-100% - ${offset}px))`
                    : `translate(-50%, ${offset}px)`,
              }}
            >
              <Caret
                viewBox={
                  isXPosition(messagePosition) ? "0 0 40 80" : "0 0 80 40"
                }
                position={messagePosition}
              >
                <CaretPath
                  d={
                    isXPosition(messagePosition)
                      ? "M5 5 L40 40 L5 75"
                      : "M5 40 L40 5 L75 40"
                  }
                />
              </Caret>
              <MessageContent
                maxHeight={maxHeight}
                closed={!open}
                offsetX={getMessageOffsetX({
                  anchorDimensions,
                  messagePosition,
                  windowWidth: windowDimensions.width,
                  messageWidth: messageDimensions.width,
                })}
              >
                {cachedChildren}
              </MessageContent>
            </MessageWrapper>
          </TooltipPortal>
        )}
      </Motion>
    </Root>
  );
}

Tooltip.borderRadius = "6px";

export default Tooltip;

const Root = styled.div`
  position: relative;
  line-height: 1;
`;

const MessageWrapper = styled.div<{
  fixed?: boolean;
  anchorDimensions: Dimensions;
  position: MessagePosition;
  isOpen: boolean;
  animating: boolean;
  zIndex: number;
  fontSize: React.CSSProperties["fontSize"];
}>`
  position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
  top: ${({ anchorDimensions, position }) =>
    isXPosition(position)
      ? anchorDimensions.top + anchorDimensions.height / 2
      : position === MessagePosition.Bottom
      ? anchorDimensions.bottom
      : anchorDimensions.top}px;
  left: ${({ anchorDimensions, position }) =>
    isYPosition(position)
      ? anchorDimensions.left + anchorDimensions.width / 2
      : position === MessagePosition.Right
      ? anchorDimensions.right
      : anchorDimensions.left}px;
  visibility: ${({ isOpen, animating }) =>
    isOpen || animating ? "visible" : "hidden"};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  color: ${({ theme }) => theme.textColor};
  z-index: ${prop("zIndex")};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
`;

const MessageContent = styled.div<{
  maxHeight: number | string;
  offsetX: number;
  closed: boolean;
}>`
  max-height: ${({ maxHeight }) =>
    typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight};
  padding: ${({ theme }) => theme.padding};
  transform: translateX(${prop("offsetX")}px);
  border-radius: ${Tooltip.borderRadius};
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  font-size: 0.875em;
  background: ${({ theme }) => theme.backgroundColor};
  border-color: ${({ theme }) => theme.borderColor};
  border-width: ${({ theme }) => theme.borderWidth};
  border-style: solid;

  ${({ closed }) =>
    closed &&
    css`
      & * {
        pointer-events: none;
      }
    `};
`;
const Caret = styled.svg<{ position: MessagePosition }>`
  position: absolute;
  font-size: ${CARET_WIDTH}px;
  z-index: 1;

  ${({ position }) => {
    if (position === MessagePosition.Bottom) {
      return css`
        top: 1px;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 1em;
        height: 0.5em;
      `;
    }
    if (position === MessagePosition.Top) {
      return css`
        bottom: 1px;
        left: 50%;
        transform: translate(-50%, 100%) rotate(180deg);
        width: 1em;
        height: 0.5em;
      `;
    }
    if (position === MessagePosition.Left) {
      return css`
        right: 2px;
        top: 50%;
        transform: translate(100%, -50%);
        width: 0.5em;
        height: 1em;
      `;
    }

    return css`
      left: 2px;
      top: 50%;
      transform: translate(-100%, -50%) rotate(180deg);
      width: 0.5em;
      height: 1em;
    `;
  }};
`;
const CaretPath = styled.path`
  fill: ${({ theme }) => theme.caretFill};
  stroke: ${({ theme }) => theme.caretStrokeColor};
  stroke-width: ${({ theme }) => theme.caretStrokeWidth};
`;
