import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationBackupFailTimeMinutesField_communication$key } from "./__generated__/CommunicationBackupFailTimeMinutesField_communication.graphql";

export const communicationBackupFailTimeMinutesFieldId = () =>
  "communication-backup-fail-time-minutes";

function CommunicationBackupFailTimeMinutesField() {
  const [
    {
      backupCheckinMinutes,
      backupFailTimeMinutes,
      failTimeValidValues,
      backupCell,
    },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationBackupFailTimeMinutesField_communication$key>(
    graphql`
      fragment CommunicationBackupFailTimeMinutesField_communication on Communication {
        backupCheckinMinutes
        backupFailTimeMinutes
        failTimeValidValues
        backupCell
      }
    `
  );

  const fieldId = communicationBackupFailTimeMinutesFieldId();
  const { current: originalValue } = React.useRef(backupFailTimeMinutes);
  const label = "Backup Fail Time (Minutes)";
  const disabled = !backupCell || !backupCheckinMinutes;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Receiver allows the System this amount of time at the programmed Check-In Time before causing a missing condition. Must be greater than Backup Check-in Minutes."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={backupFailTimeMinutes ?? ""}
        required
        disabled={disabled}
        min={backupCheckinMinutes ?? 0}
        validationMessage="Valid values are 0, 3-240."
        pattern="(0{0,3}|0{0,2}[3-9]|0?[1-9][0-9]|1[0-9]{2}|2[0-3][0-9]|240)"
        inlineHelp={"0,3-240"}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "backupFailTimeMinutes"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber) || !backupCheckinMinutes
                ? originalValue
                : closest(valueAsNumber, failTimeValidValues);
            recordProxy.setValue(Number(value), "backupFailTimeMinutes");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationBackupFailTimeMinutesField;
