/**
 * @generated SignedSource<<7aeed504ee597e9a2faf3d22aee34475>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsArmDisarmActivityDaysField_systemOptions$data = {
  readonly armDisarmActivityDays?: number;
  readonly armDisarmActivityDaysMax?: number;
  readonly armDisarmActivityDaysMin?: number;
  readonly " $fragmentType": "SystemOptionsArmDisarmActivityDaysField_systemOptions";
};
export type SystemOptionsArmDisarmActivityDaysField_systemOptions$key = {
  readonly " $data"?: SystemOptionsArmDisarmActivityDaysField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsArmDisarmActivityDaysField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "armDisarmActivityDays",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "armDisarmActivityDaysMin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "armDisarmActivityDaysMax",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsArmDisarmActivityDaysField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "3bc0d54cb11f631091e11955d1d513cf";

export default node;
