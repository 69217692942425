import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { range } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsIntruderConfirmationTimeField_systemOptions$key } from "./__generated__/SystemOptionsIntruderConfirmationTimeField_systemOptions.graphql";

export const systemOptionsIntruderConfirmationTimeFieldId = () =>
  "system-options-intruder-confirmation-time";

function SystemOptionsIntruderConfirmationTimeField() {
  const [{ intruderConfirmationTime }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsIntruderConfirmationTimeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsIntruderConfirmationTimeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            intruderConfirmationTime
          }
        }
      `
    );

  const fieldId = systemOptionsIntruderConfirmationTimeFieldId();
  const originalValue = React.useRef(intruderConfirmationTime).current;
  const validRange = [0, ...range(30, 61)];

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Intruder Confirmation Time"
      tooltip="Confirmation time requires two alarm signals to be received within a designated time frame in order to verify the alarm. Both Intruder and Holdup times require two zones of the same type to trip, with the second trip verifying that alarm."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={intruderConfirmationTime?.toString()}
        required
        min={0}
        max={60}
        inlineHelp={`0, 30-60 Minutes`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "intruderConfirmationTime"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = closest(
              !target.value || isNaN(valueAsNumber)
                ? originalValue ?? 0
                : valueAsNumber,
              validRange
            );
            recordProxy.setValue(Number(value), "intruderConfirmationTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsIntruderConfirmationTimeField;
