import { Checkbox } from "components/DaStyledElements";
import moment from "moment";
import React from "react";
import styled from "styled-components/macro";

interface GlobalHoliday {
  id: string;
  name: string;
  date: string;
}

interface GlobalHolidaySelectionProps {
  globalHoliday: GlobalHoliday;
  isChecked: boolean;
  onCheckboxChange:
    | (() => void)
    | ((e: { target: { checked: boolean } }) => void);
  isDisabled: boolean;
}

export const GlobalHolidaySelection: React.FC<GlobalHolidaySelectionProps> = ({
  globalHoliday,
  isChecked,
  onCheckboxChange,
  isDisabled,
}) => {
  const { name, date } = globalHoliday;

  // function to create a label for the checkbox
  // the label date will be the month/day of the holiday
  // the label name will be the name of the holiday and bold
  const createCheckboxLabel = () => {
    return (
      <Label>
        <LabelName>{name}</LabelName> {" - "}
        <LabelDate>{moment(date).format("MM/DD")}</LabelDate>
      </Label>
    );
  };

  return (
    <Checkbox
      checked={isChecked}
      label={createCheckboxLabel()}
      onChange={onCheckboxChange}
      disabled={isDisabled}
    />
  );
};

const LabelName = styled.span`
  font-weight: bold;
  cursor: pointer;
`;
const LabelDate = styled.span`
  font-weight: normal;
  cursor: pointer;
`;
const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0;
  font-weight: 700;
`;
