App.factory("DealerInfoV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/customers/:customer_id/dealer",
      {},
      {
        get: { method: "GET", params: {} },
      }
    );
  },
]);
