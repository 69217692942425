/**
 * @generated SignedSource<<d3951ed968a5ee9f5ed100524f18e6cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputGroupFields_outputGroup$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"OutputGroupContext_outputGroup" | "OutputGroupNameField_outputGroup" | "OutputGroupNumberField_outputGroup" | "OutputGroupOutputNumberField_outputGroup">;
  readonly " $fragmentType": "XFOutputGroupFields_outputGroup";
};
export type XFOutputGroupFields_outputGroup$key = {
  readonly " $data"?: XFOutputGroupFields_outputGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputGroupFields_outputGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFOutputGroupFields_outputGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupContext_outputGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupNameField_outputGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupNumberField_outputGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OutputGroupOutputNumberField_outputGroup"
    }
  ],
  "type": "OutputGroup",
  "abstractKey": null
};

(node as any).hash = "64c69b695db8c329c3b05d3474a36077";

export default node;
