App.directive("daTempAppUserPreauthorized", [
  "TempAppUserService",
  "$rootScope",
  "$modal",
  "$q",
  function (TempAppUserService, $rootScope, $modal, $q) {
    return {
      restrict: "C",
      controller: ($scope) => {
        $scope.createTempUser = (systemId) => {
          $scope.openStatusModal(systemId).then(
            () => {},
            () => {
              console.error(
                "daTempAppUserPreAuthorized didn't create temp user"
              );
            }
          );
        };

        let statusModal = {};
        /**
         * Creates and opens a modal to display status messages during first connect
         **/
        $scope.openStatusModal = function (systemId) {
          let deferred = $q.defer();
          switch ($rootScope.appProperties.type) {
            case "dealerAdmin":
              statusModal = $modal.open({
                templateUrl: "app/common/templates/temp-user-modal-tpl.html",
                controller: "TempUserModalCtrl",
                size: "md",
                backdrop: "static",
                resolve: {
                  systemId: function () {
                    return systemId;
                  },
                },
              });
              statusModal.result.then(
                function (result) {
                  if (result.createdTempUser) {
                    deferred.resolve();
                  } else {
                    deferred.reject();
                  }
                },
                function () {
                  deferred.reject();
                }
              );
              break;
            case "techApp":
              console.warn(
                "daTempAppUserPreAuthorized directive does not support TechApp"
              );
              deferred.reject();
              break;
            default:
              console.warn(
                "daTempAppUserPreAuthorized directive does not support $rootScope.appProperties.type: " +
                  $rootScope.appProperties.type
              );
              deferred.reject();
              break;
          }
          return deferred.promise;
        };
      },
    };
  },
]);

/**
 * A controller specifically for status while attempting to retrieve programming
 */
App.controller("TempUserModalCtrl", [
  "$interval",
  "$scope",
  "$modalInstance",
  "PROPS",
  "TempAppUserService",
  "UserService",
  "$rootScope",
  "systemId",
  "ControlSystemsService",
  "controlSystemModel",
  "$q",
  "LoginService",
  "AboutMeV2API",
  "GoogleAnalyticsService",
  function (
    $interval,
    $scope,
    $modalInstance,
    PROPS,
    TempAppUserService,
    UserService,
    $rootScope,
    systemId,
    ControlSystemsService,
    controlSystemModel,
    $q,
    LoginService,
    AboutMeV2API,
    GoogleAnalyticsService
  ) {
    let _this = this;

    $scope.system = {};
    $scope.tempUser = null;
    $scope.UserService = UserService;
    $scope.props = {
      title: UserService.customerInfo.name + " systems",
      constants: PROPS,
    };
    $scope.group = {
      statuses: {},
      step: 0,
      createJobSuccess: false,
      error: false,
    };

    $scope.cancel = () => {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.dismiss("cancel");
    };

    $scope.trackUser = () => {
      GoogleAnalyticsService.trackEvent(
        "control-system",
        "login-as-customer",
        "dealer-login"
      );
    };

    $scope.close = () => {
      $interval.cancel(_this.intervalWorker);
      $modalInstance.close({
        createdTempUser: $scope.group.createJobSuccess,
      });
    };

    const successfulJobStatuses = ["completed", "success"];
    const inProgressJobStatuses = [
      "new",
      "created",
      "acquired",
      "started",
      "running",
    ];
    const jobCompleted = (status) => !inProgressJobStatuses.includes(status);

    const didJobSucceed = (status) => successfulJobStatuses.includes(status);

    function init() {
      $scope.group.step = 1;
      getDesiredSystem().then(
        (system) => {
          $scope.system = system;
          TempAppUserService.create(system).then(
            (SchedulerJobGroup) => {
              console.debug(
                "TempUserModalCtrl->init() created temp user. Response data: " +
                  angular.toJson(SchedulerJobGroup)
              );
              startJobMonitor(SchedulerJobGroup.Id);
            },
            () => {
              errorCloseModal();
            }
          );
        },
        () => {
          errorCloseModal();
        }
      );
    }

    const getDesiredSystem = () => {
      let deferred = $q.defer();
      if (+systemId > 0) {
        let system = new controlSystemModel({
          customer_id: UserService.customer_id,
          control_system_id: +systemId,
        });
        system.get().then(
          (data) => {
            deferred.resolve(data);
          },
          (error) => {
            console.error(
              "TempUserModalCtrl->getDesiredSystem() error: " +
                angular.toJson(error)
            );
            deferred.reject();
          }
        );
      } else {
        deferred.resolve(ControlSystemsService.currentControlSystem);
      }
      return deferred.promise;
    };

    function errorCloseModal() {
      $rootScope.alerts.push({
        type: "error",
        text: `authority to log in as customer has not been approved. please try again.`,
      });
      $scope.close();
    }

    /**
     * Starts interval function that checks Initial Connection job group status,
     * updating scoped data for display in the initial connection modal
     */
    var startJobMonitor = function (groupId) {
      $scope.group.step = 2;
      $scope.group.refreshing = true;
      _this.intervalWorker = $interval(function () {
        TempAppUserService.getStatus(groupId).then(
          function (groupData) {
            $scope.group.statuses = groupData;
            if (jobCompleted(groupData.CreateJobStatus)) {
              $scope.group.step = 3;
              $interval.cancel(_this.intervalWorker);
              $scope.group.refreshing = false;
              $scope.group.createJobSuccess = didJobSucceed(
                $scope.group.statuses.CreateJobStatus
              );

              $rootScope.$broadcast("TempAppUserCreated");
            }
          },
          function () {
            failTempUser();
          }
        );
      }, PROPS.initialConnectionWatcherInterval);
    };

    function failTempUser() {
      $scope.group.step = 3;
      $interval.cancel(_this.intervalWorker);
      $scope.group.refreshing = false;
      $scope.group.error = true;
    }

    init();
  },
]);
