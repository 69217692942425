import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { getEntries } from "common/utils/universal/object";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  idAsString,
  OutputOptions,
  toUnprogrammedFavoriteId,
  toUnprogrammedOutputId,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import OutputOptionsAmbushOutputField from "../common/OutputOptionsFields/OutputOptionsAmbushOutputField";
import OutputOptionsArmAlarmOutputField from "../common/OutputOptionsFields/OutputOptionsArmAlarmOutputField";
import OutputOptionsArmedAllOrAwayOutputField from "../common/OutputOptionsFields/OutputOptionsArmedAllOrAwayOutputField";
import OutputOptionsArmedHomeOrPerimeterOutputField from "../common/OutputOptionsFields/OutputOptionsArmedHomeOrPerimeterOutputField";
import OutputOptionsArmedSleepOutputField from "../common/OutputOptionsFields/OutputOptionsArmedSleepOutputField";
import OutputOptionsBeginExitOutputField from "../common/OutputOptionsFields/OutputOptionsBeginExitOutputField";
import OutputOptionsCarbonMonoxideOutputField from "../common/OutputOptionsFields/OutputOptionsCarbonMonoxideOutputField";
import OutputOptionsClosingWaitOutputField from "../common/OutputOptionsFields/OutputOptionsClosingWaitOutputField";
import OutputOptionsCommunicationFailOutputField from "../common/OutputOptionsFields/OutputOptionsCommunicationFailOutputField";
import { OutputOptionsContextProvider } from "../common/OutputOptionsFields/OutputOptionsContext";
import OutputOptionsCoolSaverTemperatureField from "../common/OutputOptionsFields/OutputOptionsCoolSaverTemperatureField";
import OutputOptionsCutoffOutputsField from "../common/OutputOptionsFields/OutputOptionsCutoffOutputsField";
import OutputOptionsDeviceFailOutputField from "../common/OutputOptionsFields/OutputOptionsDeviceFailOutputField";
import OutputOptionsDisarmedOutputField from "../common/OutputOptionsFields/OutputOptionsDisarmedOutputField";
import OutputOptionsEndExitOutputField from "../common/OutputOptionsFields/OutputOptionsEndExitOutputField";
import OutputOptionsEntryDelayOutputField from "../common/OutputOptionsFields/OutputOptionsEntryDelayOutputField";
import OutputOptionsFireAlarmOutputField from "../common/OutputOptionsFields/OutputOptionsFireAlarmOutputField";
import OutputOptionsFireTroubleOutputField from "../common/OutputOptionsFields/OutputOptionsFireTroubleOutputField";
import OutputOptionsHeatSaverTemperatureField from "../common/OutputOptionsFields/OutputOptionsHeatSaverTemperatureField";
import OutputOptionsLateToCloseOutputField from "../common/OutputOptionsFields/OutputOptionsLateToCloseOutputField";
import OutputOptionsLockdownOutputField from "../common/OutputOptionsFields/OutputOptionsLockdownOutputField";
import OutputOptionsOutputCutoffTimeField from "../common/OutputOptionsFields/OutputOptionsOutputCutoffTimeField";
import OutputOptionsPanicAlarmOutputField from "../common/OutputOptionsFields/OutputOptionsPanicAlarmOutputField";
import OutputOptionsReadyOutputField from "../common/OutputOptionsFields/OutputOptionsReadyOutputField";
import OutputOptionsSensorResetOutputField from "../common/OutputOptionsFields/OutputOptionsSensorResetOutputField";
import OutputOptionsSupervisoryAlarmOutputField from "../common/OutputOptionsFields/OutputOptionsSupervisoryAlarmOutputField";
import OutputOptionsTelephoneTroubleOutputField from "../common/OutputOptionsFields/OutputOptionsTelephoneTroubleOutputField";
import OutputOptionsZoneMonitorOutputField from "../common/OutputOptionsFields/OutputOptionsZoneMonitorOutputField";
import { SystemOptionsContextProvider } from "../common/SystemOptionsFields/SystemOptionsContext";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  XROutputOptionsProgrammingConceptFormInline_controlSystem$data,
  XROutputOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XROutputOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XROutputOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XROutputOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XROutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation,
} from "./__generated__/XROutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation.graphql";
import {
  XROutputOptionsProgrammingConceptFormOutputOptionsSendMutation,
  XROutputOptionsProgrammingConceptFormOutputOptionsSendMutation$data,
} from "./__generated__/XROutputOptionsProgrammingConceptFormOutputOptionsSendMutation.graphql";
import { XROutputOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XROutputOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Output Options";
export const conceptId = "xr-output-options";

export const getState = (
  controlSystem: XROutputOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XROutputOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          outputOptions {
            id
            panicAlarmOutput {
              id
              number
              formattedNumber
            }
            ambushOutput {
              id
              number
              formattedNumber
            }
            entryDelayOutput {
              id
              number
              formattedNumber
            }
            beginExitOutput {
              id
              number
              formattedNumber
            }
            endExitOutput {
              id
              number
              formattedNumber
            }
            readyOutput {
              id
              number
              formattedNumber
            }
            lateToCloseOutput {
              id
              number
              formattedNumber
            }
            disarmedOutput {
              id
              number
              formattedNumber
            }
            armAlarmOutput {
              id
              number
              formattedNumber
            }
            heatSaverTemperature
            armedOutput {
              id
              number
              formattedNumber
            }
            coolSaverTemperature
            armedHomeOrPerimeterOutput {
              id
              number
              formattedNumber
            }
            armedAllOrAwayOutput {
              id
              number
              formattedNumber
            }
            armedSleepOutput {
              id
              number
              formattedNumber
            }
            carbonMonoxideOutput {
              id
              number
              formattedNumber
            }
            zoneMonitorOutput {
              id
              number
              formattedNumber
            }
            remoteArmingOutput {
              id
              number
              formattedNumber
            }
            burglaryOutput {
              id
              number
              formattedNumber
            }
            cutoffOutputs
            outputCutoffTime
            outputCutoffTimeMin
            outputCutoffTimeMax
            communicationFailOutput {
              id
              number
              formattedNumber
            }
            fireAlarmOutput {
              id
              number
              formattedNumber
            }
            fireTroubleOutput {
              id
              number
              formattedNumber
            }
            telephoneTroubleOutput {
              __typename
              id
              number
              formattedNumber
            }
            lateToCloseOutput {
              __typename
              id
              number
              formattedNumber
            }
            deviceFailOutput {
              __typename
              id
              number
              formattedNumber
            }
            sensorResetOutput {
              __typename
              id
              number
              formattedNumber
            }
            closingWaitOutput {
              __typename
              id
              number
              formattedNumber
            }
            supervisoryAlarmOutput {
              __typename
              id
              number
              formattedNumber
            }
            lockdownOutput {
              __typename
              id
              number
              formattedNumber
            }
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XROutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshOutputOptions(systemId: $id) {
      ... on RefreshOutputOptionsSuccessResponse {
        __typename
        controlSystem: system {
          __typename
          ...XROutputOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshOutputOptionsErrorResponse {
        error {
          type
        }
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XROutputOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshOutputOptions, isRefreshing] =
    useMutation<XROutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshOutputOptions({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshOutputOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Output Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshOutputOptions: {
                    __typename: response.refreshOutputOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Output Options: ${hyphenScoreToTitleCase(
                      error.type ?? "UNKNOWN_ERROR"
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Output Options",
                  });
                }
              }
              reject(error?.type);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XROutputOptionsProgrammingConceptFormOutputOptionsSendMutation(
    $systemId: ID!
    $outputOptions: OutputOptionsInput!
  ) {
    sendOutputOptions(systemId: $systemId, outputOptions: $outputOptions) {
      ... on SendOutputOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XROutputOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendOutputOptionsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XROutputOptionsProgrammingConceptFormOutputOptionsSendMutation$data,
  originalControlSystemData: XROutputOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendOutputOptions.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshOutputOptions: {
          __typename: "RefreshOutputOptionsSuccessPayload",
          controlSystem: getState(response.sendOutputOptions.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XROutputOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendOutputOptions, isSendingOutputOptions] =
    useMutation<XROutputOptionsProgrammingConceptFormOutputOptionsSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { outputOptions },
        } = getState(props.controlSystem);
        if (outputOptions) {
          sendOutputOptions({
            variables: {
              systemId,
              outputOptions: {
                armedOutput: outputOptions.armedOutput.formattedNumber,
                burglaryOutput: outputOptions.burglaryOutput.formattedNumber,
                cutoffOutputs: outputOptions.cutoffOutputs,
                outputCutoffTime: outputOptions.outputCutoffTime.toString(),
                communicationFailOutput:
                  outputOptions.communicationFailOutput.formattedNumber,
                fireAlarmOutput: outputOptions.fireAlarmOutput.formattedNumber,
                fireTroubleOutput:
                  outputOptions.fireTroubleOutput.formattedNumber,
                panicAlarmOutput:
                  outputOptions.panicAlarmOutput.formattedNumber,
                ambushOutput: outputOptions.ambushOutput.formattedNumber,
                entryDelayOutput:
                  outputOptions.entryDelayOutput.formattedNumber,
                beginExitOutput: outputOptions.beginExitOutput.formattedNumber,
                endExitOutput: outputOptions.endExitOutput.formattedNumber,
                readyOutput: outputOptions.readyOutput.formattedNumber,
                lateToCloseOutput:
                  outputOptions.lateToCloseOutput.formattedNumber,
                disarmedOutput: outputOptions.disarmedOutput.formattedNumber,
                armAlarmOutput: outputOptions.armAlarmOutput.formattedNumber,
                heatSaverTemperature: outputOptions.heatSaverTemperature,
                coolSaverTemperature: outputOptions.coolSaverTemperature,
                armedHomeOrPerimeterOutput:
                  outputOptions.armedHomeOrPerimeterOutput.formattedNumber,
                armedAllOrAwayOutput:
                  outputOptions.armedAllOrAwayOutput.formattedNumber,
                armedSleepOutput:
                  outputOptions.armedSleepOutput.formattedNumber,
                carbonMonoxideOutput:
                  outputOptions.carbonMonoxideOutput.formattedNumber,
                zoneMonitorOutput:
                  outputOptions.zoneMonitorOutput.formattedNumber,
                remoteArmingOutput:
                  outputOptions.remoteArmingOutput.formattedNumber,
                telephoneTroubleOutput:
                  outputOptions.telephoneTroubleOutput.formattedNumber,
                deviceFailOutput:
                  outputOptions.deviceFailOutput.formattedNumber,
                sensorResetOutput:
                  outputOptions.sensorResetOutput.formattedNumber,
                closingWaitOutput:
                  outputOptions.closingWaitOutput.formattedNumber,
                supervisoryAlarmOutput:
                  outputOptions.supervisoryAlarmOutput.formattedNumber,
                lockdownOutput: outputOptions.lockdownOutput.formattedNumber,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendOutputOptions.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Output Options Programming Saved to the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendOutputOptions.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendOutputOptions.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSendingOutputOptions,
  ];
};

const readOutputOptionsTemplateData = (
  programmingTemplateConcepts: XROutputOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XROutputOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        outputOptions {
          included
        }
        nonOutputs: outputOptions {
          cutoffOutputs {
            included
            data
          }
          outputCutoffTime {
            included
            data
          }
          heatSaverTemperature {
            included
            data
          }
          coolSaverTemperature {
            included
            data
          }
        }
        outputs: outputOptions {
          armedOutput {
            included
            data
          }
          burglaryOutput {
            included
            data
          }
          communicationFailOutput {
            included
            data
          }
          fireAlarmOutput {
            included
            data
          }
          fireTroubleOutput {
            included
            data
          }
          panicAlarmOutput {
            included
            data
          }
          ambushOutput {
            included
            data
          }
          entryDelayOutput {
            included
            data
          }
          beginExitOutput {
            included
            data
          }
          endExitOutput {
            included
            data
          }
          readyOutput {
            included
            data
          }
          lateToCloseOutput {
            included
            data
          }
          disarmedOutput {
            included
            data
          }
          armAlarmOutput {
            included
            data
          }
          armedHomeOrPerimeterOutput {
            included
            data
          }
          armedAllOrAwayOutput {
            included
            data
          }
          armedSleepOutput {
            included
            data
          }
          carbonMonoxideOutput {
            included
            data
          }
          zoneMonitorOutput {
            included
            data
          }
          remoteArmingOutput {
            included
            data
          }
          telephoneTroubleOutput {
            included
            data
          }
          deviceFailOutput {
            included
            data
          }
          sensorResetOutput {
            included
            data
          }
          closingWaitOutput {
            included
            data
          }
          supervisoryAlarmOutput {
            included
            data
          }
          lockdownOutput {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ) ?? { outputOptions: { included: false } };

export function applyTemplateData(
  programmingTemplateConcepts: XROutputOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const systemId = controlSystemRecordProxy.getValue("id");
  const templateData = readOutputOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData?.outputOptions?.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const outputOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "outputOptions",
      "OutputOptions"
    ) as unknown as RecordProxy<OutputOptions>;

    if (templateData.nonOutputs) {
      applyTemplateScalarDataToRecordProxy(
        outputOptionsRecordProxy,
        templateData.nonOutputs
      );
    }

    if (templateData.outputs) {
      getEntries(templateData.outputs).forEach(([fieldKey, fieldData]) => {
        if (
          typeof fieldData === "object" &&
          fieldData?.included &&
          fieldData.data
        ) {
          const isFavorite = fieldData.data.charAt(0).toUpperCase() === "F";
          const outputOptionId = idAsString(
            isFavorite
              ? toUnprogrammedFavoriteId(systemId, fieldData.data)
              : toUnprogrammedOutputId(systemId, fieldData.data)
          );
          const outputOptionRecordProxy =
            store.get(idAsString(outputOptionId)) ??
            store.create(
              outputOptionId,
              isFavorite ? "UnprogrammedFavorite" : "UnprogrammedOutput"
            );
          outputOptionRecordProxy.setValue(fieldData.data, "number");
          outputOptionRecordProxy.setValue(fieldData.data, "formattedNumber");
          outputOptionsRecordProxy.setLinkedRecord(
            outputOptionRecordProxy,
            fieldKey
          );
        }
      });
    }
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XROutputOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XROutputOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            hardwareModel
            armingSystem
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            outputOptions {
              ...OutputOptionsContext_outputOptions
              ...OutputOptionsCutoffOutputsField_outputOptions
              ...OutputOptionsOutputCutoffTimeField_outputOptions
              ...OutputOptionsCommunicationFailOutputField_outputOptions
              ...OutputOptionsFireAlarmOutputField_outputOptions
              ...OutputOptionsFireTroubleOutputField_outputOptions
              ...OutputOptionsPanicAlarmOutputField_outputOptions
              ...OutputOptionsAmbushOutputField_outputOptions
              ...OutputOptionsEntryDelayOutputField_outputOptions
              ...OutputOptionsBeginExitOutputField_outputOptions
              ...OutputOptionsEndExitOutputField_outputOptions
              ...OutputOptionsReadyOutputField_outputOptions
              ...OutputOptionsArmedOutputField_outputOptions
              ...OutputOptionsDisarmedOutputField_outputOptions
              ...OutputOptionsBurglaryOutputField_outputOptions
              ...OutputOptionsLateToCloseOutputField_outputOptions
              ...OutputOptionsArmAlarmOutputField_outputOptions
              ...OutputOptionsHeatSaverTemperatureField_outputOptions
              ...OutputOptionsCoolSaverTemperatureField_outputOptions
              ...OutputOptionsArmedHomeOrPerimeterOutputField_outputOptions
              ...OutputOptionsArmedAllOrAwayOutputField_outputOptions
              ...OutputOptionsArmedSleepOutputField_outputOptions
              ...OutputOptionsCarbonMonoxideOutputField_outputOptions
              ...OutputOptionsZoneMonitorOutputField_outputOptions
              ...OutputOptionsTelephoneTroubleOutputField_outputOptions
              ...OutputOptionsLateToCloseOutputField_outputOptions
              ...OutputOptionsDeviceFailOutputField_outputOptions
              ...OutputOptionsSensorResetOutputField_outputOptions
              ...OutputOptionsClosingWaitOutputField_outputOptions
              ...OutputOptionsSupervisoryAlarmOutputField_outputOptions
              ...OutputOptionsLockdownOutputField_outputOptions
            }
            systemOptions {
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsContextSystemType_systemOptions
              ...SystemOptionsContextHouseCode_systemOptions
            }
          }
        }
      `
    );

  const softwareVersion = Number(controlSystem.panel.softwareVersion);
  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <OutputOptionsContextProvider
        outputOptions={controlSystem.panel.outputOptions}
      >
        <SystemOptionsContextProvider
          systemOptions={controlSystem.panel.systemOptions}
        >
          <ProgrammingConceptForm
            conceptId={conceptId}
            title={title}
            helpLink={`${programmingGuideUrl}#Output%20Options`}
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
              controlSystem.panel.outputOptions
            )}
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <ProgrammingConceptForm.Fields>
                <OutputOptionsCutoffOutputsField />
                <OutputOptionsOutputCutoffTimeField />
                <OutputOptionsCommunicationFailOutputField />
                <OutputOptionsFireAlarmOutputField />
                <OutputOptionsFireTroubleOutputField />
                <OutputOptionsPanicAlarmOutputField />
                <OutputOptionsAmbushOutputField />
                <OutputOptionsEntryDelayOutputField />
                <OutputOptionsBeginExitOutputField />
                <OutputOptionsEndExitOutputField />
                <OutputOptionsReadyOutputField />
                <OutputOptionsDisarmedOutputField />
                <OutputOptionsTelephoneTroubleOutputField />
                <OutputOptionsLateToCloseOutputField />
                <OutputOptionsDeviceFailOutputField />
                <OutputOptionsSensorResetOutputField />
                <OutputOptionsClosingWaitOutputField />
                <OutputOptionsArmAlarmOutputField />
                <OutputOptionsSupervisoryAlarmOutputField />
                {softwareVersion >= 171 && (
                  <>
                    <OutputOptionsArmedHomeOrPerimeterOutputField />
                    <OutputOptionsArmedAllOrAwayOutputField />
                    <OutputOptionsArmedSleepOutputField />
                  </>
                )}
                <OutputOptionsHeatSaverTemperatureField />
                <OutputOptionsCoolSaverTemperatureField />
                {softwareVersion >= 183 && (
                  <OutputOptionsCarbonMonoxideOutputField />
                )}
                <OutputOptionsLockdownOutputField />
                {softwareVersion >= 192 && (
                  <OutputOptionsZoneMonitorOutputField />
                )}
              </ProgrammingConceptForm.Fields>
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </OutputOptionsContextProvider>
    </PanelContextProvider>
  );
}
