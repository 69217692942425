/**
 * @generated SignedSource<<9793aae7c95c4cf8bff50af20dc43843>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFOutputOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly outputOptions: {
      readonly acFailOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly carbonMonoxideOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly communicationFailOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly cutoffOutputs: string;
      readonly deviceFailOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly fireAlarmOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly fireTroubleOutput: {
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly id: string;
      readonly outputCutoffTime: number;
      readonly outputCutoffTimeMax: number;
      readonly outputCutoffTimeMin: number;
      readonly sensorResetOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
      readonly supervisoryAlarmOutput: {
        readonly __typename: string;
        readonly formattedNumber: string;
        readonly id: string;
        readonly number: number;
      };
    } | null;
  };
  readonly " $fragmentType": "XFOutputOptionsProgrammingConceptFormInline_controlSystem";
};
export type XFOutputOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XFOutputOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFOutputOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFOutputOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "d3fe44575b47cbabb385627ba18d7967";

export default node;
