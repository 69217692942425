/**
 * @generated SignedSource<<7f2c5046be0fbb716b1a24bf1d5a98f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsApnField_remoteOptions$data = {
  readonly allowCellularRemoteProgramming?: boolean | null;
  readonly firstApn?: string | null;
  readonly " $fragmentType": "RemoteOptionsApnField_remoteOptions";
};
export type RemoteOptionsApnField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsApnField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsApnField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstApn",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsApnField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "XfRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowCellularRemoteProgramming",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "Xt75RemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "2541fb1c018d57c2e04bc0dacb9a369c";

export default node;
