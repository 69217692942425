/**
 * @generated SignedSource<<db59e6083a6ceb67fe4c0e3d8119d4a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneStatus = "BYPASSED" | "DEFAULT" | "LOW_BATTERY" | "MISSING" | "NORMAL" | "OPEN" | "SHORT" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneBypassSwitch_zone$data = {
  readonly canBypass: boolean;
  readonly id: string;
  readonly status: ZoneStatus | null;
  readonly " $fragmentType": "ZoneBypassSwitch_zone";
};
export type ZoneBypassSwitch_zone$key = {
  readonly " $data"?: ZoneBypassSwitch_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneBypassSwitch_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneBypassSwitch_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canBypass",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "c464a373aaefe29cc53bcf0d68bcebed";

export default node;
