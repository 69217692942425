import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsStrobeField_bellOptions$key } from "./__generated__/BellOptionsStrobeField_bellOptions.graphql";

export const bellOptionsStrobeFieldId = () => "bell-options-strobe";

function BellOptionsStrobeField() {
  const [bellOptions, updateBellOptions] =
    useBellOptionsFragment<BellOptionsStrobeField_bellOptions$key>(
      graphql`
        fragment BellOptionsStrobeField_bellOptions on BellOptions {
          strobe
        }
      `
    );

  const fieldId = bellOptionsStrobeFieldId();
  const strobe = bellOptions.strobe ?? false;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Strobe"
      tooltip="Enable this option when connecting a strobe to the bell output."
    >
      <Switch
        label="Strobe"
        id={fieldId}
        checked={strobe}
        onChange={() => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(!strobe, "strobe");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsStrobeField;
