/**
 * @generated SignedSource<<ca5c648a9f494adfedb077f88660c1c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
export type DeviceInformationWirelessSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XFDeviceSetupProgrammingConceptFormInline_deviceInformation$data = {
  readonly __typename: string;
  readonly axNumber?: string;
  readonly deviceCommunicationMethod?: DeviceInformationCommType;
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly isNew: boolean;
  readonly lxNumber?: string;
  readonly name?: string;
  readonly number?: number;
  readonly serialNumber?: string;
  readonly supervisionTime?: DeviceInformationWirelessSupervisionTime | null;
  readonly " $fragmentType": "XFDeviceSetupProgrammingConceptFormInline_deviceInformation";
};
export type XFDeviceSetupProgrammingConceptFormInline_deviceInformation$key = {
  readonly " $data"?: XFDeviceSetupProgrammingConceptFormInline_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceSetupProgrammingConceptFormInline_deviceInformation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFDeviceSetupProgrammingConceptFormInline_deviceInformation"
};

(node as any).hash = "ad60f8af4e40a58e4640575e7dab2d52";

export default node;
