import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import AdvancedFields from "components/AdvancedFields";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import { createOperationDescriptor, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { ControlSystem, RemoteOptions } from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import RemoteOptionsAlarmReceiverAuthorizationField from "../common/RemoteOptionsFields/RemoteOptionsAlarmReceiverAuthorizationField";
import RemoteOptionsAllowCellularRemoteProgrammingField from "../common/RemoteOptionsFields/RemoteOptionsAllowCellularRemoteProgrammingField";
import RemoteOptionsAllowNetworkRemoteField from "../common/RemoteOptionsFields/RemoteOptionsAllowNetworkRemoteField";
import RemoteOptionsApn2Field from "../common/RemoteOptionsFields/RemoteOptionsApn2Field";
import RemoteOptionsApnField from "../common/RemoteOptionsFields/RemoteOptionsApnField";
import RemoteOptionsAppKeyField from "../common/RemoteOptionsFields/RemoteOptionsAppKeyField";
import RemoteOptionsArmedAnswerRingsField from "../common/RemoteOptionsFields/RemoteOptionsArmedAnswerRingsField";
import { RemoteOptionsContextProvider } from "../common/RemoteOptionsFields/RemoteOptionsContext";
import RemoteOptionsDisarmedAnswerRingsField from "../common/RemoteOptionsFields/RemoteOptionsDisarmedAnswerRingsField";
import RemoteOptionsEntreArmDisarmReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreArmDisarmReportsField";
import RemoteOptionsEntreBackupConnectionField from "../common/RemoteOptionsFields/RemoteOptionsEntreBackupConnectionField";
import RemoteOptionsEntreBackupIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsEntreBackupIpAddress";
import RemoteOptionsEntreBackupIpv6AddressField from "../common/RemoteOptionsFields/RemoteOptionsEntreBackupIpv6AddressField";
import RemoteOptionsEntreBackupTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsEntreBackupTcpPortField";
import RemoteOptionsEntreCheckInMinutesField from "../common/RemoteOptionsFields/RemoteOptionsEntreCheckInMinutesField";
import RemoteOptionsEntreConnectionField from "../common/RemoteOptionsFields/RemoteOptionsEntreConnectionField";
import RemoteOptionsEntreDoorReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreDoorReportsField";
import RemoteOptionsEntreIncomingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsEntreIncomingTcpPortField";
import RemoteOptionsEntreIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsEntreIpAddressField";
import RemoteOptionsEntreIpv6AddressField from "../common/RemoteOptionsFields/RemoteOptionsEntreIpv6AddressField";
import RemoteOptionsEntreIpv6Field from "../common/RemoteOptionsFields/RemoteOptionsEntreIpv6Field";
import RemoteOptionsEntreOutgoingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsEntreOutgoingTcpPortField";
import RemoteOptionsEntrePassphraseField from "../common/RemoteOptionsFields/RemoteOptionsEntrePassphraseField";
import RemoteOptionsEntreSupervisoryReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreSupervisoryReportsField";
import RemoteOptionsEntreUserCommandReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreUserCommandReportsField";
import RemoteOptionsEntreVideoReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreVideoReportsField";
import RemoteOptionsEntreZoneReportsField from "../common/RemoteOptionsFields/RemoteOptionsEntreZoneReportsField";
import RemoteOptionsIntegratorArmDisarmReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorArmDisarmReportsField";
import RemoteOptionsIntegratorBackupConnectionField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupConnectionField";
import RemoteOptionsIntegratorBackupIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupIpAddressField";
import RemoteOptionsIntegratorBackupTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorBackupTcpPortField";
import RemoteOptionsIntegratorCheckInMinutesField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorCheckInMinutesField";
import RemoteOptionsIntegratorConnectionField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorConnectionField";
import RemoteOptionsIntegratorDoorReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorDoorReportsField";
import RemoteOptionsIntegratorIncomingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorIncomingTcpPortField";
import RemoteOptionsIntegratorIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorIpAddressField";
import RemoteOptionsIntegratorOutgoingTcpPortField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorOutgoingTcpPortField";
import RemoteOptionsIntegratorPassphraseField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorPassphraseField";
import RemoteOptionsIntegratorSupervisoryReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorSupervisoryReportsField";
import RemoteOptionsIntegratorUserCommandReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorUserCommandReportsField";
import RemoteOptionsIntegratorVideoReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorVideoReportsField";
import RemoteOptionsIntegratorZoneReportsField from "../common/RemoteOptionsFields/RemoteOptionsIntegratorZoneReportsField";
import RemoteOptionsNetworkProgrammingPortField from "../common/RemoteOptionsFields/RemoteOptionsNetworkProgrammingPortField";
import RemoteOptionsPcModemField from "../common/RemoteOptionsFields/RemoteOptionsPcModemField";
import RemoteOptionsRemoteChangeIpAddressField from "../common/RemoteOptionsFields/RemoteOptionsRemoteChangeIpAddressField";
import RemoteOptionsRemoteChangePortField from "../common/RemoteOptionsFields/RemoteOptionsRemoteChangePortField";
import RemoteOptionsRemoteDisarmField from "../common/RemoteOptionsFields/RemoteOptionsRemoteDisarmField";
import RemoteOptionsRemoteKeyField from "../common/RemoteOptionsFields/RemoteOptionsRemoteKeyField";
import RemoteOptionsRemotePhoneNumberField from "../common/RemoteOptionsFields/RemoteOptionsRemotePhoneNumberField";
import RemoteOptionsSendLocalChangesField from "../common/RemoteOptionsFields/RemoteOptionsSendLocalChangesField";
import RemoteOptionsServiceReceiverAuthorizationField from "../common/RemoteOptionsFields/RemoteOptionsServiceReceiverAuthorizationField";
import { panelVersionGTOE, panelVersionLTOE } from "../utils/panel";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import {
  XRRemoteOptionsProgrammingConceptFormInline_controlSystem$data,
  XRRemoteOptionsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XRRemoteOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XRRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation,
} from "./__generated__/XRRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation.graphql";
import {
  XRRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation,
  XRRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
} from "./__generated__/XRRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation.graphql";
import { XRRemoteOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XRRemoteOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Remote Options";
export const conceptId = "xr-remote-options";

export const getState = (
  controlSystem: XRRemoteOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRRemoteOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          remoteOptions {
            ... on XrRemoteOptions {
              __typename
              id
              remoteKey
              remoteDisarm
              appKey
              armedAnswerRings
              disarmedAnswerRings
              alarmReceiverAuthorization
              serviceReceiverAuthorization
              pcModem
              allowNetworkRemote
              networkProgrammingPort
              allowCellularRemoteProgramming
              firstApn
              secondApn
              sendLocalChanges
              remoteChangesIpAddress
              remoteChangesPort
              remoteChangesPhoneNumber
              entreConnection
              entreIncomingPort
              entreIpAddress
              entreOutgoingPort
              entreIpv6
              entreIpv6Address
              entreBackupConnection
              entreBackupIpAddress
              entreBackupIncomingPort
              entreBackupIpv6Address
              entreCheckInMinutes
              entreArmDisarmReports
              entreZoneReports
              entreUserCommandReports
              entreDoorReports
              entreSupervisoryReports
              entrePassphrase
              entreVideoReports
              integratorConnection
              integratorIncomingPort
              integratorIpAddress
              integratorOutgoingPort
              integratorBackupConnection
              integratorBackupIpAddress
              integratorBackupIncomingPort
              integratorArmDisarmReports
              integratorZoneReports
              integratorUserCommandReports
              integratorDoorReports
              integratorSupervisoryReports
              integratorCheckInMinutes
              integratorPassphrase
              integratorVideoReports
            }
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XRRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshRemoteOptions(id: $id) {
      ... on RefreshRemoteOptionsSuccessPayload {
        __typename
        controlSystem {
          ...XRRemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XRRemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshRemoteOptions, isRefreshing] =
    useMutation<XRRemoteOptionsProgrammingConceptFormRemoteOptionsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshRemoteOptions({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshRemoteOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Remote Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshRemoteOptions: {
                    __typename: response.refreshRemoteOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Remote Options: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Remote Options",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XRRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation(
    $systemId: ID!
    $remoteOptions: XrRemoteOptionsInput!
  ) {
    sendXrRemoteOptionsProgramming(
      systemId: $systemId
      remoteOptions: $remoteOptions
    ) {
      ... on SendRemoteOptionsProgrammingSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XRRemoteOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendRemoteOptionsProgrammingErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XRRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation$data,
  originalControlSystemData: XRRemoteOptionsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendXrRemoteOptionsProgramming.controlSystem) {
    const operation = createOperationDescriptor(
      refreshMutationConcreteRequest,
      { id: originalControlSystemData.id }
    );
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        refreshRemoteOptions: {
          __typename: "RefreshRemoteOptionsSuccessPayload",
          controlSystem: getState(
            response.sendXrRemoteOptionsProgramming.controlSystem
          ),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XRRemoteOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendRemoteOptions, isSending] =
    useMutation<XRRemoteOptionsProgrammingConceptFormRemoteOptionsSendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { remoteOptions },
        } = getState(props.controlSystem);
        if (remoteOptions?.__typename === "XrRemoteOptions") {
          sendRemoteOptions({
            variables: {
              systemId,
              remoteOptions: {
                remoteDisarm: remoteOptions.remoteDisarm,
                appKey: remoteOptions.appKey,
                armedAnswerRings: remoteOptions.armedAnswerRings?.toString(),
                disarmedAnswerRings:
                  remoteOptions.disarmedAnswerRings?.toString(),
                alarmReceiverAuthorization:
                  remoteOptions.alarmReceiverAuthorization,
                serviceReceiverAuthorization:
                  remoteOptions.serviceReceiverAuthorization,
                pcModem: remoteOptions.pcModem,
                allowNetworkRemote: remoteOptions.allowNetworkRemote,
                networkProgrammingPort: remoteOptions.networkProgrammingPort,
                allowCellularRemoteProgramming:
                  remoteOptions.allowCellularRemoteProgramming,
                firstApn: remoteOptions.firstApn,
                secondApn: remoteOptions.secondApn,
                sendLocalChanges: remoteOptions.sendLocalChanges,
                remoteChangesIpAddress: remoteOptions.remoteChangesIpAddress,
                remoteChangesPort: remoteOptions.remoteChangesPort,
                remoteChangesPhoneNumber:
                  remoteOptions.remoteChangesPhoneNumber,
                entreConnection: remoteOptions.entreConnection,
                entreIncomingPort: remoteOptions.entreIncomingPort,
                entreIpAddress: remoteOptions.entreIpAddress,
                entreOutgoingPort: remoteOptions.entreOutgoingPort,
                entreIpv6: remoteOptions.entreIpv6,
                entreIpv6Address: remoteOptions.entreIpv6Address,
                entreBackupConnection: remoteOptions.entreBackupConnection,
                entreBackupIpAddress: remoteOptions.entreBackupIpAddress,
                entreBackupIncomingPort: remoteOptions.entreBackupIncomingPort,
                entreBackupIpv6Address: remoteOptions.entreBackupIpv6Address,
                entreCheckInMinutes: remoteOptions.entreCheckInMinutes,
                entreArmDisarmReports: remoteOptions.entreArmDisarmReports,
                entreZoneReports: remoteOptions.entreZoneReports,
                entreUserCommandReports: remoteOptions.entreUserCommandReports,
                entreDoorReports: remoteOptions.entreDoorReports,
                entreSupervisoryReports: remoteOptions.entreSupervisoryReports,
                entrePassphrase: remoteOptions.entrePassphrase,
                entreVideoReports: remoteOptions.entreVideoReports,
                integratorConnection: remoteOptions.integratorConnection,
                integratorIncomingPort: remoteOptions.integratorIncomingPort,
                integratorIpAddress: remoteOptions.integratorIpAddress,
                integratorOutgoingPort: remoteOptions.integratorOutgoingPort,
                integratorBackupConnection:
                  remoteOptions.integratorBackupConnection,
                integratorBackupIpAddress:
                  remoteOptions.integratorBackupIpAddress,
                integratorBackupIncomingPort:
                  remoteOptions.integratorBackupIncomingPort,
                integratorArmDisarmReports:
                  remoteOptions.integratorArmDisarmReports,
                integratorZoneReports: remoteOptions.integratorZoneReports,
                integratorUserCommandReports:
                  remoteOptions.integratorUserCommandReports,
                integratorDoorReports: remoteOptions.integratorDoorReports,
                integratorSupervisoryReports:
                  remoteOptions.integratorSupervisoryReports,
                integratorCheckInMinutes:
                  remoteOptions.integratorCheckInMinutes,
                integratorPassphrase: remoteOptions.integratorPassphrase,
                integratorVideoReports: remoteOptions.integratorVideoReports,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendXrRemoteOptionsProgramming.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: `${title} Programming Saved To the System`,
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(
                  response,
                  getState(originalControlSystem),
                  parentRelayEnv
                );
              } else if (response.sendXrRemoteOptionsProgramming.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendXrRemoteOptionsProgramming.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSending,
  ];
};

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

const readRemoteOptionsTemplateData = (
  programmingTemplateConcepts: XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        remoteOptions {
          included
          remoteKey {
            included
            data
          }
          remoteDisarm {
            included
            data
          }
          appKey {
            included
            data
          }
          armedAnswerRings {
            included
            data
          }
          disarmedAnswerRings {
            included
            data
          }
          alarmReceiverAuthorization {
            included
            data
          }
          serviceReceiverAuthorization {
            included
            data
          }
          pcModem {
            included
            data
          }
          allowNetworkRemote {
            included
            data
          }
          networkProgrammingPort {
            included
            data
          }
          allowCellularRemoteProgramming {
            included
            data
          }
          firstApn {
            included
            data
          }
          secondApn {
            included
            data
          }
          sendLocalChanges {
            included
            data
          }
          remoteChangesIpAddress {
            included
            data
          }
          remoteChangesPort {
            included
            data
          }
          remoteChangesPhoneNumber {
            included
            data
          }
          entreConnection {
            included
            data
          }
          entreIncomingPort {
            included
            data
          }
          entreIpAddress {
            included
            data
          }
          entreOutgoingPort {
            included
            data
          }
          entreIpv6 {
            included
            data
          }
          entreIpv6Address {
            included
            data
          }
          entreBackupConnection {
            included
            data
          }
          entreBackupIpAddress {
            included
            data
          }
          entreBackupIncomingPort {
            included
            data
          }
          entreBackupIpv6Address {
            included
            data
          }
          entreCheckInMinutes {
            included
            data
          }
          entreArmDisarmReports {
            included
            data
          }
          entreZoneReports {
            included
            data
          }
          entreUserCommandReports {
            included
            data
          }
          entreDoorReports {
            included
            data
          }
          entreSupervisoryReports {
            included
            data
          }
          validEntreCheckinMinutes {
            included
            data
          }
          entrePassphrase {
            included
            data
          }
          entreVideoReports {
            included
            data
          }
          integratorConnection {
            included
            data
          }
          integratorIncomingPort {
            included
            data
          }
          integratorIpAddress {
            included
            data
          }
          integratorOutgoingPort {
            included
            data
          }
          integratorBackupConnection {
            included
            data
          }
          integratorBackupIpAddress {
            included
            data
          }
          integratorBackupIncomingPort {
            included
            data
          }
          integratorArmDisarmReports {
            included
            data
          }
          integratorZoneReports {
            included
            data
          }
          integratorUserCommandReports {
            included
            data
          }
          integratorDoorReports {
            included
            data
          }
          integratorSupervisoryReports {
            included
            data
          }
          integratorCheckInMinutes {
            included
            data
          }
          integratorPassphrase {
            included
            data
          }
          integratorVideoReports {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).remoteOptions ?? { included: false };

export function applyTemplateData(
  programmingTemplateConcepts: XRRemoteOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>
) {
  const templateData = readRemoteOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const remoteOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "remoteOptions",
      "RemoteOptions"
    ) as unknown as RecordProxy<RemoteOptions>;

    applyTemplateScalarDataToRecordProxy(
      remoteOptionsRecordProxy,
      templateData
    );
  }
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRRemoteOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRRemoteOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            remoteOptions {
              __typename
              ... on XrRemoteOptions {
                integratorConnection
                integratorBackupConnection
                entreConnection
                entreIpv6
                entreBackupConnection
                allowCellularRemoteProgramming
                allowNetworkRemote
                sendLocalChanges
              }
              ...RemoteOptionsContext_remoteOptions
              ...RemoteOptionsRemoteKeyField_remoteOptions
              ...RemoteOptionsRemoteDisarmField_remoteOptions
              ...RemoteOptionsAppKeyField_remoteOptions
              ...RemoteOptionsArmedAnswerRingsField_remoteOptions
              ...RemoteOptionsDisarmedAnswerRingsField_remoteOptions
              ...RemoteOptionsPcModemField_remoteOptions
              ...RemoteOptionsAlarmReceiverAuthorizationField_remoteOptions
              ...RemoteOptionsServiceReceiverAuthorizationField_remoteOptions
              ...RemoteOptionsAllowNetworkRemoteField_remoteOptions
              ...RemoteOptionsNetworkProgrammingPortField_remoteOptions
              ...RemoteOptionsAllowCellularRemoteProgrammingField_remoteOptions
              ...RemoteOptionsApnField_remoteOptions
              ...RemoteOptionsApn2Field_remoteOptions
              ...RemoteOptionsSendLocalChangesField_remoteOptions
              ...RemoteOptionsRemotePhoneNumberField_remoteOptions
              ...RemoteOptionsRemoteChangeIpAddressField_remoteOptions
              ...RemoteOptionsRemoteChangePortField_remoteOptions
              # Integrator Section
              ...RemoteOptionsIntegratorConnectionField_remoteOptions
              ...RemoteOptionsIntegratorIncomingTcpPortField_remoteOptions
              ...RemoteOptionsIntegratorOutgoingTcpPortField_remoteOptions
              ...RemoteOptionsIntegratorIpAddressField_remoteOptions
              ...RemoteOptionsIntegratorBackupConnectionField_remoteOptions
              ...RemoteOptionsIntegratorArmDisarmReportsField_remoteOptions
              ...RemoteOptionsIntegratorCheckInMinutesField_remoteOptions
              ...RemoteOptionsIntegratorZoneReportsField_remoteOptions
              ...RemoteOptionsIntegratorUserCommandReportsField_remoteOptions
              ...RemoteOptionsIntegratorDoorReportsField_remoteOptions
              ...RemoteOptionsIntegratorSupervisoryReportsField_remoteOptions
              ...RemoteOptionsIntegratorPassphraseField_remoteOptions
              ...RemoteOptionsIntegratorVideoReportsField_remoteOptions
              ...RemoteOptionsIntegratorBackupIpAddressField_remoteOptions
              ...RemoteOptionsIntegratorBackupTcpPortField_remoteOptions
              # Entré Section
              ...RemoteOptionsEntreConnectionField_remoteOptions
              ...RemoteOptionsEntreIpv6Field_remoteOptions
              ...RemoteOptionsEntreIpv6AddressField_remoteOptions
              ...RemoteOptionsEntreIncomingTcpPortField_remoteOptions
              ...RemoteOptionsEntreOutgoingTcpPortField_remoteOptions
              ...RemoteOptionsEntreIpAddressField_remoteOptions
              ...RemoteOptionsEntreBackupConnectionField_remoteOptions
              ...RemoteOptionsEntreBackupIpAddressField_remoteOptions
              ...RemoteOptionsEntreBackupIpv6AddressField_remoteOptions
              ...RemoteOptionsEntreArmDisarmReportsField_remoteOptions
              ...RemoteOptionsEntreCheckInMinutesField_remoteOptions
              ...RemoteOptionsEntreZoneReportsField_remoteOptions
              ...RemoteOptionsEntreUserCommandReportsField_remoteOptions
              ...RemoteOptionsEntreDoorReportsField_remoteOptions
              ...RemoteOptionsEntreSupervisoryReportsField_remoteOptions
              ...RemoteOptionsEntrePassphraseField_remoteOptions
              ...RemoteOptionsEntreVideoReportsField_remoteOptions
              ...RemoteOptionsEntreBackupTcpPortField_remoteOptions
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
          }
        }
      `
    );

  const {
    panel: {
      remoteOptions,
      softwareVersion,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <RemoteOptionsContextProvider
        remoteOptions={controlSystem.panel.remoteOptions}
      >
        <ProgrammingConceptForm
          conceptId={conceptId}
          helpLink={`${programmingGuideUrl}#Remote%20Options`}
          title={title}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(remoteOptions)}
        >
          <RemountOnUpdateContainer nodeId={conceptId}>
            {remoteOptions?.__typename === "XrRemoteOptions" && (
              <>
                <ProgrammingConceptForm.Fields>
                  <RemoteOptionsRemoteKeyField />
                  <RemoteOptionsRemoteDisarmField />
                  <RemoteOptionsArmedAnswerRingsField />
                  <RemoteOptionsDisarmedAnswerRingsField />
                  <RemoteOptionsPcModemField />
                  <RemoteOptionsAlarmReceiverAuthorizationField />
                  <RemoteOptionsServiceReceiverAuthorizationField />
                  <RemoteOptionsAllowNetworkRemoteField />
                  <RemoteOptionsNetworkProgrammingPortField />
                  <RemoteOptionsAllowCellularRemoteProgrammingField />
                  <RemoteOptionsApnField />
                  {panelVersionLTOE(192, softwareVersion) && (
                    <RemoteOptionsApn2Field />
                  )}
                  <RemoteOptionsSendLocalChangesField />
                  <RemoteOptionsRemotePhoneNumberField />
                  <RemoteOptionsRemoteChangeIpAddressField />
                  <RemoteOptionsRemoteChangePortField />
                  <RemoteOptionsAppKeyField />
                </ProgrammingConceptForm.Fields>
                {panelVersionGTOE(192, softwareVersion) && (
                  <AdvancedFields label="Integrator">
                    <RemoteOptionsIntegratorConnectionField />
                    <RemoteOptionsIntegratorIncomingTcpPortField />
                    <RemoteOptionsIntegratorIpAddressField />
                    <RemoteOptionsIntegratorOutgoingTcpPortField />
                    <RemoteOptionsIntegratorBackupConnectionField />
                    <RemoteOptionsIntegratorBackupIpAddressField />
                    <RemoteOptionsIntegratorBackupTcpPortField />
                    <RemoteOptionsIntegratorArmDisarmReportsField />
                    <RemoteOptionsIntegratorZoneReportsField />
                    <RemoteOptionsIntegratorUserCommandReportsField />
                    <RemoteOptionsIntegratorDoorReportsField />
                    <RemoteOptionsIntegratorSupervisoryReportsField />
                    {/* This field was introduced in 192 with the other integration items, but removed in 201. */}
                    {panelVersionLTOE(193, softwareVersion) && (
                      <RemoteOptionsIntegratorCheckInMinutesField />
                    )}
                    <RemoteOptionsIntegratorPassphraseField />
                    {panelVersionLTOE(192, softwareVersion) && (
                      <RemoteOptionsIntegratorVideoReportsField />
                    )}
                    <RemoteOptionsIntegratorVideoReportsField />
                  </AdvancedFields>
                )}
                <AdvancedFields label="Entré">
                  <ProgrammingConceptForm.Fields>
                    <RemoteOptionsEntreConnectionField />
                    {panelVersionGTOE(191, softwareVersion) && (
                      <RemoteOptionsEntreIpv6Field />
                    )}
                    <RemoteOptionsEntreIncomingTcpPortField />
                    {panelVersionGTOE(191, softwareVersion) && (
                      <RemoteOptionsEntreIpv6AddressField />
                    )}
                    <RemoteOptionsEntreIpAddressField />
                    <RemoteOptionsEntreOutgoingTcpPortField />
                    <RemoteOptionsEntreBackupConnectionField />
                    {panelVersionGTOE(191, softwareVersion) && (
                      <RemoteOptionsEntreBackupIpv6AddressField />
                    )}
                    <RemoteOptionsEntreBackupIpAddressField />
                    <RemoteOptionsEntreBackupTcpPortField />
                    {panelVersionGTOE(192, softwareVersion) && (
                      <>
                        <RemoteOptionsEntreArmDisarmReportsField />
                        <RemoteOptionsEntreZoneReportsField />
                        <RemoteOptionsEntreUserCommandReportsField />
                        <RemoteOptionsEntreDoorReportsField />
                        <RemoteOptionsEntreSupervisoryReportsField />
                        <RemoteOptionsEntreCheckInMinutesField />
                      </>
                    )}
                    <RemoteOptionsEntrePassphraseField />
                    {panelVersionGTOE(193, softwareVersion) && (
                      <RemoteOptionsEntreVideoReportsField />
                    )}
                  </ProgrammingConceptForm.Fields>
                </AdvancedFields>
              </>
            )}
          </RemountOnUpdateContainer>
        </ProgrammingConceptForm>
      </RemoteOptionsContextProvider>
    </PanelContextProvider>
  );
}
