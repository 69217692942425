import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsApnField_remoteOptions$key } from "./__generated__/RemoteOptionsApnField_remoteOptions.graphql";

export const remoteOptionsApnFieldId = () => "remote-options-apn";

function RemoteOptionsApnField() {
  const [{ firstApn, allowCellularRemoteProgramming }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsApnField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsApnField_remoteOptions on RemoteOptions {
          ... on XfRemoteOptions {
            firstApn
          }
          ... on XrRemoteOptions {
            firstApn
            allowCellularRemoteProgramming
          }
          ... on Xt75RemoteOptions {
            firstApn
          }
        }
      `
    );

  const fieldId = remoteOptionsApnFieldId();
  const softwareVersion = useSoftwareVersion();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);

  const value = firstApn ?? "";
  const disabled = isXr ? !allowCellularRemoteProgramming : false;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={softwareVersion >= 193 ? "APN" : "First APN"}
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={value}
        pattern="^.*$"
        maxLength={32}
        disabled={disabled}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(target.value, "firstApn")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsApnField;
