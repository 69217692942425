import React from "react";
import { joinSpaced } from "utils/string";
import styles from "./Icon.module.css";

export default function Icon(props: React.HTMLProps<HTMLElement>) {
  return (
    <i
      {...props}
      className={joinSpaced(props.className || "", styles["icon"])}
    />
  );
}

export function InfoIcon(props: React.HTMLProps<HTMLElement>) {
  return (
    <i
      {...props}
      className={joinSpaced(
        props.className || "",
        styles["info-icon"],
        "icon-radial_info"
      )}
    />
  );
}
