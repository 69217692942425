/**
 * @generated SignedSource<<a59fbb5e0a23a18bbef26f87e2c0b30e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewReceiverFormModalDealer_dealer$data = {
  readonly centralStationAutomationIntegrations: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly id: string;
  readonly receivers: {
    readonly nodes: ReadonlyArray<{
      readonly number: number;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ReceiverFormModalDealer_dealer">;
  readonly " $fragmentType": "NewReceiverFormModalDealer_dealer";
};
export type NewReceiverFormModalDealer_dealer$key = {
  readonly " $data"?: NewReceiverFormModalDealer_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewReceiverFormModalDealer_dealer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewReceiverFormModalDealer_dealer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sort",
          "value": {
            "keys": [
              "number"
            ],
            "order": "ASC"
          }
        }
      ],
      "concreteType": "ReceiverConnection",
      "kind": "LinkedField",
      "name": "receivers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Receiver",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "receivers(sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CentralStationAutomationIntegration",
      "kind": "LinkedField",
      "name": "centralStationAutomationIntegrations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReceiverFormModalDealer_dealer"
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};
})();

(node as any).hash = "113ac0631c1baff3f561f9fe1aac7d29";

export default node;
