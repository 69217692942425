App.service("PanelProgArchiveService", [
  "PanelProgArchiveJobsApi",
  "$q",
  "PanelProgArchiveODataApi",
  "PanelODataAPI",
  "$state",
  "UserService",
  "$modal",
  "$rootScope",
  function (
    PanelProgArchiveJobsApi,
    $q,
    PanelProgArchiveODataApi,
    PanelODataAPI,
    $state,
    UserService,
    $modal,
    $rootScope
  ) {
    // Public accessors
    this.qualifies = qualifies;
    this.createBackup = createBackup;
    this.finalizeRestoral = finalizeRestoral;
    this.deleteBackups = deleteBackups;
    this.deleteSchedule = deleteSchedule;
    // this.deleteBackup = deleteBackup;
    this.getBackupsForPanel = getBackupsForPanel;
    this.restorePanel = restorePanel;
    this.restoringPanel = false;
    this.getSchedule = getSchedule;
    this.setProtection = setProtection;
    this.cache = {
      backup: {
        load: cacheBackup,
        clear: clearCachedBackup,
        data: null,
      },
    };

    // Private variables / functions
    let _this = this;

    // Listen for route changes that aren't modified for backups to clear the data
    let backupRoutes = [
      "app.control_system",
      "app.panel_programming.programming_print",
      "app.panel_programming.programming_full",
    ];
    $rootScope.$on("$stateChangeStart", function (event, next) {
      if (_this.cache.backup.data && !backupRoutes.includes(next.name)) {
        clearCachedBackup(false);
      }
    });

    function qualifies(panelId) {
      let deferred = $q.defer();
      if (panelId === null) {
        deferred.resolve(false);
      } else {
        PanelProgArchiveJobsApi.qualifies(
          { panelId: panelId },
          function (data) {
            deferred.resolve(data.Qualifies);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    }

    function createBackup(panelId) {
      let deferred = $q.defer();
      PanelProgArchiveJobsApi.createBackup(
        { panelId: panelId },
        {},
        function () {
          deferred.resolve();
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    /**
     * Perform any follow-up functions after sending all programming
     */
    function finalizeRestoral() {
      let deferred = $q.defer();
      if (_this.cache.backup.data.CodesStored) {
        PanelProgArchiveJobsApi.finalizeRestoral(
          {
            panelId: _this.cache.backup.data.PanelId,
            conceptId: _this.cache.backup.data.Id,
          },
          {},
          function () {
            deferred.resolve();
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    function deleteBackups(panelId) {
      let deferred = $q.defer();
      PanelProgArchiveJobsApi.deleteBackups(
        { panelId: panelId },
        function () {
          deferred.resolve();
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function deleteSchedule(panelId) {
      let deferred = $q.defer();
      PanelProgArchiveJobsApi.deleteSchedule(
        { panelId: panelId },
        function () {
          deferred.resolve();
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    // note: Route exists but is not used in code. Commenting out to remove from production code. Leaving for future use.
    // function deleteBackup(backup) {
    //   let deferred = $q.defer();
    //   if (backup.hasOwnProperty('Protected') && backup.Protected === false) {
    //     PanelProgArchiveJobsApi.deleteBackup(
    //       {
    //         panelId: backup.PanelId,
    //         conceptId: backup.Id
    //       },
    //       function() {
    //         deferred.resolve();
    //       },
    //       function(error) {
    //         deferred.reject(error);
    //       }
    //     )
    //   } else {
    //     deferred.reject('invalid backup format');
    //   }
    //   return deferred.promise;
    // }

    function getBackupsForPanel(panelId) {
      let deferred = $q.defer();
      PanelODataAPI.getProgBackups(
        { panel_id: panelId },
        function (data) {
          deferred.resolve(data.value);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function cacheBackup(backupId) {
      let deferred = $q.defer();
      PanelProgArchiveODataApi.getBackup(
        { id: backupId },
        function (data) {
          delete data.$promise;
          delete data.$resolved;
          data.Data = angular.fromJson(data.Data);
          _this.cache.backup.data = data;
          deferred.resolve();
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function clearCachedBackup(navigate) {
      _this.restoringPanel = false;
      _this.cache.backup.data = null;
      if (navigate) {
        $state.go("app.control_system", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
        });
      }
    }

    function restorePanel() {
      if (
        _this.cache.backup.data.FirmwareVersion ==
          UserService.controlSystem.panels[0].software_version &&
        _this.cache.backup.data.FirmwareDate ==
          UserService.controlSystem.panels[0].software_date
      ) {
        performRestore();
      } else {
        let firmwareWarningConfirmModal = $modal.open({
          templateUrl: "firmwareWarningModal.html",
          controller: "FirmwareWarningModalCtrl",
          size: "md",
          backdrop: "static",
        });
        firmwareWarningConfirmModal.result
          .then(
            function (reason) {
              performRestore();
            },
            function (error) {}
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    }

    function performRestore() {
      _this.restoringPanel = true;
      $state.go(
        "app.panel_programming.programming_full",
        {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
          panel_id: UserService.panel_id,
        },
        { reload: true }
      );
    }

    function getSchedule(panelId) {
      let deferred = $q.defer();
      PanelProgArchiveJobsApi.getSchedule(
        { panelId: panelId },
        function (data) {
          delete data.$promise;
          delete data.$resolved;
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function setProtection(backup) {
      let deferred = $q.defer();
      PanelProgArchiveJobsApi.setProtection(
        {
          panelId: backup.PanelId,
          conceptId: backup.Id,
          protect: backup.Protected,
        },
        {},
        function () {
          deferred.resolve();
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
  },
]);
