/**
 * Factory to set focus on a specific form element.
 */
App.factory("DateUtilsService", [
  "CustomRolesService",
  "TIME_ZONES_NAMES_MAP",
  "$q",
  function (CustomRolesService, TIME_ZONES_NAMES_MAP, $q) {
    return {
      serverTimeZones: [],

      getOffsetForNVRTimezone: function (nvrTimeZone) {
        var deferred = $q.defer();
        //Get the server name of the NVR's timezone:
        var serverTimeZone = TIME_ZONES_NAMES_MAP[nvrTimeZone];
        //Call the timezones API to get the current offsets
        this.getServerTimeZones()
          .then(
            function (serverTimeZones) {
              var foundTimeZone = serverTimeZones.find(function (zone) {
                return zone.name === serverTimeZone;
              });
              if (foundTimeZone)
                deferred.resolve(parseInt(foundTimeZone.current_utc_offset));
              else deferred.resolve(-5); // TODO: What is a better default?
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            deferred.reject(error);
          });
        return deferred.promise;
      },

      getServerTimeZones: function () {
        var deferred = $q.defer();
        var _this = this;
        if (this.serverTimeZones.length > 0)
          deferred.resolve(this.serverTimeZones);
        else {
          CustomRolesService.getTimeZones("1").then(
            function (data) {
              _this.serverTimeZones = data;
              deferred.resolve(_this.serverTimeZones);
            },
            function (error) {
              deferred.reject(error);
            }
          );
        }
        return deferred.promise;
      },
    };
  },
]);
