import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathReceiverIpv6AddressField_communicationPath$key } from "./__generated__/CommPathReceiverIpv6AddressField_communicationPath.graphql";

export const commPathReceiverIpv6AddressFieldId = (number: string) =>
  `comm-path-receiver-ipv6-address-${number}`;

function CommPathReceiverIpv6AddressField() {
  const [{ id, number, commType, remoteIPv6, useIPv6 }, updateCommPath] =
    useCommPathFragment<CommPathReceiverIpv6AddressField_communicationPath$key>(
      graphql`
        fragment CommPathReceiverIpv6AddressField_communicationPath on CommunicationPath {
          id
          number
          commType
          remoteIPv6
          useIPv6
        }
      `
    );

  const fieldId = commPathReceiverIpv6AddressFieldId(String(number));
  const disabled = !useIPv6 || !["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Receiver IPv6 Address"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={id}
        value={remoteIPv6.toString()}
        disabled={disabled}
        pattern="(([0-9a-fA-F]{4}:){7}[0-9a-fA-F]{4})"
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "remoteIPv6");
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "remoteIPv6");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathReceiverIpv6AddressField;
