App.factory("ControlSystemImagesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.imageApiUrl + "/api/v1/ControlSystems/:ControlSystemId/:route",
      {},
      {
        getUrls: { method: "GET", isArray: true },
        create: { method: "POST" },
        destroy: { method: "DELETE" },
      }
    );
  },
]);
