/**
 * @generated SignedSource<<7b5539577b4967e93a7f8deb7ee01595>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SystemDiagnosticsDirectLinkQuery$variables = {
  panelId: string;
};
export type SystemDiagnosticsDirectLinkQuery$data = {
  readonly systemDiagnosticsGetPanel: {
    readonly accountNumber: number | null;
    readonly commType: string | null;
    readonly controlSystemId: number | null;
    readonly controlSystemName: string | null;
    readonly customerId: number | null;
    readonly hardwareModel: string | null;
    readonly panelArmingSystem: string | null;
    readonly panelCommAddress: string | null;
    readonly panelSerialNumber: string | null;
    readonly panelSoftwareDate: string | null;
    readonly panelSoftwareVersion: string | null;
    readonly simIdentifier: string | null;
  } | null;
};
export type SystemDiagnosticsDirectLinkQuery = {
  response: SystemDiagnosticsDirectLinkQuery$data;
  variables: SystemDiagnosticsDirectLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "panelId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "panelId",
        "variableName": "panelId"
      }
    ],
    "concreteType": "SystemDiagnosticsPanel",
    "kind": "LinkedField",
    "name": "systemDiagnosticsGetPanel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "panelSerialNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "controlSystemName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "controlSystemId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "simIdentifier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "panelCommAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hardwareModel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "panelSoftwareVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "panelSoftwareDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "panelArmingSystem",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SystemDiagnosticsDirectLinkQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SystemDiagnosticsDirectLinkQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d9391b23ee5ab17205dd9ed053e21e9e",
    "id": null,
    "metadata": {},
    "name": "SystemDiagnosticsDirectLinkQuery",
    "operationKind": "query",
    "text": "query SystemDiagnosticsDirectLinkQuery(\n  $panelId: String!\n) {\n  systemDiagnosticsGetPanel(panelId: $panelId) {\n    panelSerialNumber\n    controlSystemName\n    accountNumber\n    controlSystemId\n    customerId\n    simIdentifier\n    panelCommAddress\n    hardwareModel\n    panelSoftwareVersion\n    panelSoftwareDate\n    panelArmingSystem\n    commType\n  }\n}\n"
  }
};
})();

(node as any).hash = "64435ff13c08bf15f938872435d0d275";

export default node;
