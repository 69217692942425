import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsEnhancedZoneTestField_systemOptions$key } from "./__generated__/SystemOptionsEnhancedZoneTestField_systemOptions.graphql";

export const systemOptionsEnhancedZoneTestFieldId = () =>
  "system-options-enhanced-zone-test";

function SystemOptionsEnhancedZoneTestField() {
  const [{ enhancedZoneTest }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsEnhancedZoneTestField_systemOptions$key>(
      graphql`
        fragment SystemOptionsEnhancedZoneTestField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            enhancedZoneTest
          }
        }
      `
    );

  const fieldId = systemOptionsEnhancedZoneTestFieldId();
  const label = "Enhanced Zone Test";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Allows scheduling Panic and Walk test, and sends more information (or sends information at different times) to receiver during System tests."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={enhancedZoneTest}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!enhancedZoneTest, "enhancedZoneTest");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsEnhancedZoneTestField;
