App.service("NewsItemsService", [
  "$rootScope",
  "$q",
  "NewsItems",
  "UserService",
  function ($rootScope, $q, NewsItems, UserService) {
    var _this = this;

    /**
     * The storage key the browser cache for an array of seen news item ids
     * @type {string}
     */
    var newsItemsStorageKey = "shownNewsItems";

    /**
     * Create a new news item
     * @param {*} newsItem
     */
    this.create = function (newsItem) {
      var deferred = $q.defer();
      NewsItems.post(
        {},
        angular.toJson(newsItem),
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "NewsItemsService->create() - Error creating news item: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Get one or all news items
     * @param {int} [id] - id of a news item. Include to retrieve a specific item.
     */
    this.read = function (id) {
      var deferred = $q.defer();
      if (angular.isDefined(id)) {
        NewsItems.getItem(
          { news_item_id: id },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "NewsItemsService->update() - Error getting news item: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        );
      } else {
        NewsItems.getAll(
          {},
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            console.error(
              "NewsItemsService->update() - Error getting all news items: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    };

    /**
     * Update an existing news item
     * @param {*} newsItem
     */
    this.update = function (newsItem) {
      var deferred = $q.defer();
      NewsItems.put(
        { news_item_id: newsItem.id },
        angular.toJson(newsItem),
        function () {
          deferred.resolve();
        },
        function (error) {
          console.error(
            "NewsItemsService->update() - Error updating news item: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Delete an existing news item
     * @param {int} id
     */
    this.delete = function (id) {
      var deferred = $q.defer();
      NewsItems.delete(
        { news_item_id: id },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          console.error(
            "NewsItemsService->delete() - Error deleting news item: " +
              angular.toJson(error)
          );
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * Get news items for which the current date is between the start and end date
     */
    this.getActive = function () {
      var deferred = $q.defer();
      this.read()
        .then(
          function (items) {
            var activeItems = [];
            angular.forEach(items, function (newsItem) {
              if (isActive(newsItem)) {
                activeItems.push(newsItem);
              }
            });
            if (activeItems.length > 0) {
              // Reverse sort by created date
              activeItems.sort(function (a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
              });
            }
            deferred.resolve(activeItems);
          },
          function (error) {
            console.error("NewsItemsService->getActive() - Error");
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Get the newest unseen active news item
     */
    this.getNewestUnseenActiveItem = function () {
      var deferred = $q.defer();
      if (!UserService.canReceiveNewsItems()) {
        // This is a custom method to check if the user can receive news items and suppress them if they are in the NO_NEWS constant
        console.log("User cannot receive news items");
        deferred.resolve(null);
      }
      this.read()
        .then(
          function (items) {
            var shownItemIds = getShownItemIds();
            var unseenActiveAlerts = [];
            angular.forEach(items, function (newsItem) {
              var notYetShown = shownItemIds.indexOf(newsItem.id) === -1;
              var active = isActive(newsItem);
              if (notYetShown && active) {
                unseenActiveAlerts.push(newsItem);
              }
            });
            if (unseenActiveAlerts.length === 0) {
              deferred.resolve(null);
            } else {
              // Reverse sort by created date
              unseenActiveAlerts.sort(function (a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
              });
              deferred.resolve(unseenActiveAlerts[0]);
            }
          },
          function () {
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    /**
     * Add a news item to the list of shown items in the browser cache
     * @param {*} newsItem
     */
    this.addToShownItems = function (newsItem) {
      var shownItemIds = getShownItemIds();
      if (shownItemIds.indexOf(newsItem.id) === -1) {
        shownItemIds.push(newsItem.id);
        setShownItemIds(shownItemIds);
      }
    };

    /**
     * Add all news items to the list of shown items in the browser cache
     */
    this.markAllRead = function () {
      this.read()
        .then(function (items) {
          angular.forEach(items, function (newsItem) {
            _this.addToShownItems(newsItem);
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Get a list of shown item ids from the browser cache
     * @returns {Array}
     */
    function getShownItemIds() {
      var storedShownIds =
        $rootScope.$storage.getItem(newsItemsStorageKey) || "";
      var shownItemIds = [];
      if (storedShownIds !== "") {
        if (storedShownIds.indexOf(",") > -1) {
          shownItemIds = storedShownIds.split(",").map(function (id) {
            return +id;
          });
        } else {
          shownItemIds = [+storedShownIds];
        }
      }
      return shownItemIds;
    }

    /**
     * Return true if the item has started but not ended
     * @param {*} newsItem
     * @returns {boolean}
     */
    function isActive(newsItem) {
      var started = new Date(newsItem.start_date) < Date.now();
      var stillValid = new Date(newsItem.end_date) > Date.now();
      return started && stillValid;
    }

    /**
     * Save an array of ids of shown item ids from to browser cache
     * @param {[int]} itemIds
     */
    function setShownItemIds(itemIds) {
      if (angular.isArray(itemIds)) {
        var uniqueIds = [];
        angular.forEach(itemIds, function (id) {
          if (uniqueIds.indexOf(+id) === -1) {
            uniqueIds.push(+id);
          }
        });
        $rootScope.$storage.setItem(newsItemsStorageKey, uniqueIds);
      }
    }
  },
]);
