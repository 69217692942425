import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationZoneAuditDaysField_zone$key } from "./__generated__/ZoneInformationZoneAuditDaysField_zone.graphql";

export const zoneInformationZoneAuditDaysFieldId = (number: string) =>
  `zone-information-zone-audit-days-${number}`;

function ZoneInformationZoneAuditDaysField() {
  const [{ number, type, zoneAuditDays }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationZoneAuditDaysField_zone$key>(
      graphql`
        fragment ZoneInformationZoneAuditDaysField_zone on Zone {
          number
          type
          zoneAuditDays
        }
      `
    );

  const fieldId = zoneInformationZoneAuditDaysFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const disabled = [
    ZoneType.FIRE,
    ZoneType.DOORBELL,
    ZoneType.FIRE_VERIFY,
    ZoneType.TAMPER,
  ].includes(type as ZoneType);
  const { isXr, isXt75 } = resolvePanelType(hardwareModel);
  const { ZONE_ACTIVITY_DAYS: RULES } = resolveZoneFieldRules(hardwareModel);
  const validZoneAuditDaysRange =
    isXr || isXt75 ? range(0, 366) : range(0, 100);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Zone Audit Days"
      tooltip="Maximum number of days this Zone can go without any trips before a fault message is sent to the Receiver."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={zoneAuditDays}
        required
        disabled={disabled}
        pattern={RULES?.PATTERN}
        min={0}
        max={isXr || isXt75 ? 365 : 99}
        inlineHelp={RULES?.INLINE_HELP}
        validationMessage={RULES?.VALIDATION_MSG}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "zoneAuditDays"
            );
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              closest(Number(target.value), validZoneAuditDaysRange).toString(),
              "zoneAuditDays"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationZoneAuditDaysField;
