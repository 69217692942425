import { Coordinates } from "components/CameraEditCommon/types";
import { CoordinateRenderer } from "components/CameraEditCommon/utils";
import React, { useMemo } from "react";
import { LineWithClientSchemaData } from "./type";
import { getDirectionLineEndpoints } from "./utils";

interface UniviewAnalyticalDetectionLineProps {
  color: string;
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  line: LineWithClientSchemaData;
  isActive: boolean;
  mouseDown: boolean | null;
}
function UniviewAnalyticalDetectionLine(
  props: UniviewAnalyticalDetectionLineProps
) {
  const { color, renderX, renderY, line, isActive, mouseDown } = props;
  const {
    index,
    startX,
    startY,
    endX,
    endY,
    activePointIndex,
    sensitivity,
    direction,
  } = line;
  const coordinates = [
    [startX, startY],
    [endX, endY],
  ] as Coordinates[];
  const [midX, midY] = useMemo(
    () => [(startX + endX) / 2, (startY + endY) / 2],
    [startX, startY, endX, endY]
  );
  const [aX, aY, bX, bY] = useMemo(
    () => getDirectionLineEndpoints(coordinates, renderX, renderY),
    [startX, startY, endX, endY, renderX, renderY]
  );
  const renderedPoints = coordinates.map(([x, y]) => [renderX(x), renderY(y)]);

  return (
    <g>
      <defs>
        <marker
          id="arrow"
          markerWidth="40"
          markerHeight="40"
          refX="0"
          refY="10"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path fill="Orange">
            <animate
              attributeName="d"
              from="M0,0 L0,20 L3,10 z"
              to="M5,0 L5,20 L8,10 z"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="opacity"
              from="0.8"
              to="0"
              dur="2s"
              repeatCount="indefinite"
            />
          </path>
        </marker>
      </defs>
      <line
        id={`analytical-detection-line-${index}`}
        x1={renderX(startX)}
        y1={renderY(startY)}
        x2={renderX(endX)}
        y2={renderY(endY)}
        opacity={sensitivity / 150 + 0.2}
        stroke={color}
        strokeWidth={isActive ? 10 : 5}
        style={isActive ? { cursor: "move" } : { cursor: "default" }}
      />
      {direction <= 1 && (
        <line
          x1={renderX(midX)}
          y1={renderY(midY)}
          x2={aX}
          y2={aY}
          stroke="transparent"
          strokeWidth="5"
          markerEnd="url(#arrow)"
        />
      )}
      {direction % 2 === 0 && (
        <line
          x1={renderX(midX)}
          y1={renderY(midY)}
          x2={bX}
          y2={bY}
          stroke="transparent"
          strokeWidth="5"
          markerEnd="url(#arrow)"
        />
      )}
      {isActive && (
        <>
          {renderedPoints.map(([x, y], pointIndex) => (
            <EndPoint
              key={`end-point-${pointIndex}`}
              x={x}
              y={y}
              active={activePointIndex === pointIndex}
              index={pointIndex}
              lineIndex={index}
              mouseDown={mouseDown}
            />
          ))}
        </>
      )}
    </g>
  );
}

export default UniviewAnalyticalDetectionLine;

function EndPoint(props: {
  x: number;
  y: number;
  active?: boolean;
  index: number;
  lineIndex: number | null;
  mouseDown: boolean | null;
}) {
  const { x, y, active, index, lineIndex, mouseDown } = props;
  return (
    <g>
      <circle
        r={5}
        cx={x}
        cy={y}
        fill={active ? "blue" : "white"}
        stroke="blue"
        strokeWidth={2}
      />
      <circle
        id={`analytical-detection-line-${lineIndex}-point-${index}`}
        r={10}
        cx={x}
        cy={y}
        fill="transparent"
        style={{ cursor: mouseDown ? "grabbing" : "grab" }}
      />
    </g>
  );
}
