import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationWirelessField_deviceInformation$key } from "./__generated__/DeviceInformationWirelessField_deviceInformation.graphql";

export const deviceInformationWirelessFieldId = (number: string) =>
  `device-information-wireless-${number}`;

function DeviceInformationWirelessField() {
  const [
    { number, network, wirelessTranslator1100t, wireless },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationWirelessField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationWirelessField_deviceInformation on DeviceInformation {
        number
        ... on XtDeviceInformation {
          network
          wirelessTranslator1100t
          wireless
        }
      }
    `
  );

  const fieldId = deviceInformationWirelessFieldId(String(number));
  const disabled = !!network;
  const label = "Wireless";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled || wirelessTranslator1100t}
    >
      <Switch
        id={fieldId}
        label={label}
        checked={wireless}
        disabled={disabled || wirelessTranslator1100t} //Will not allow you to change it if 1100T is true
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!wireless, "wireless");
            if (!wireless) {
              recordProxy.setValue("14", "serialNumber");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationWirelessField;
