/**
 *
 * General Utility Functions
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import Task from "data.task";
import { Map, Seq, Set } from "immutable";
import {
  F,
  T,
  __,
  always,
  applySpec,
  applyTo,
  assoc,
  complement,
  compose,
  concat,
  cond,
  converge,
  curry,
  dec,
  defaultTo,
  dissoc,
  divide,
  either,
  eqProps,
  equals,
  evolve,
  flip,
  fromPairs,
  groupWith,
  head,
  identity,
  ifElse,
  indexBy,
  init,
  invoker,
  is,
  isEmpty,
  isNil,
  join,
  keys,
  last,
  length,
  lens,
  map,
  memoizeWith,
  not,
  nth,
  objOf,
  pair,
  path,
  pathEq,
  prop,
  propSatisfies,
  reduce,
  reject,
  replace,
  set,
  sort,
  sortBy,
  split,
  subtract,
  sum,
  tail,
  tap,
  toLower,
  toPairs,
  toUpper,
  traverse,
  uniq,
  uniqWith,
  unless,
  when,
} from "ramda";
/**
 * Returns a value that is no more or less than the given max or min values, respectively
 * @param  {number} value the number to clamp
 * @param  {number} min   the minimum value allowed, inclusive
 * @param  {number} max   the maximum value allowed, inclusive
 * @return {number}       the clamped value
 */

export const clamp = (value, min, max) => {
  const absoluteMin = Math.min(min, max);
  const absoluteMax = Math.max(min, max);
  return Math.min(Math.max(absoluteMin, parseInt(value, 10)), absoluteMax);
};
export const toInteger = (x) => parseInt(x.toFixed(), 10);
/**
 * Returns true if all arguments evaluate to true
 * @return {boolean}
 */

export const all = (...conditionals) =>
  conditionals.every((condition) => condition);
/**
 * Checks that any argument evaluates to true
 * @return {boolean}
 */

export const any = (...conditionals) =>
  conditionals.some((condition) => condition);
/**
 * Returns the y position of the given element relative to the document
 * @param {Element} element a Javascript element
 * @return {number}
 */

export const offsetTop = (element) =>
  window.scrollY + element.getBoundingClientRect().top;
/**
 * Returns an array of integers for the given range
 * @param {Integer} begin
 * @param {Integer} end
 * @return {Array<Integer>}
 */

export const range = (begin, end) => {
  const list = [];

  for (let i = begin; i <= end; i += 1) {
    list.push(i);
  }

  return list;
};
/**
 * Returns the closest value to a number from an array of numbers
 * @param {Number} num
 * @param {readonlyArray<Number> | Array<Number>} arr
 * @return {Number}
 */

export const closest = (num, arr) => {
  let curr = arr[0];
  let diff = Math.abs(num - curr);
  arr.map((val) => {
    let newdiff = Math.abs(num - val);
    if (newdiff < diff) {
      diff = newdiff;
      curr = val;
    }
  });
  return curr;
};

export const pauseEvent = (event) => {
  event.stopPropagation();
  event.preventDefault();
};
export const addEventListeners = (element, eventNames, listener) => {
  eventNames
    .split(" ")
    .forEach((e) => element.addEventListener(e, listener, false));
};
export const removeEventListeners = (element, eventNames, listener) => {
  eventNames
    .split(" ")
    .forEach((e) => element.removeEventListener(e, listener, false));
};
export const throttle = (callback, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay, ...args);
  };
};
export const keyIn = (...ks) => {
  const keySet = Set(ks);
  return flip((k) => keySet.has(k));
};
export const keyInCollection = (collection) =>
  keyIn(...collection.valueSeq().filterNot(isNil).toArray());
export const capitalize = converge(concat, [compose(toUpper, head), tail]);
export const sleep = (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));
export const toInt = (x) => parseInt(x, 10);
export const safeToInt = compose(
  ifElse(isNaN, Maybe.Nothing, Maybe.Just),
  toInt
);
export const toDollars = invoker(1, "toFixed")(2);
export const snakeCaseToCamelCase = (string) =>
  string.replace(/_([a-z])/gi, (match, p1) => p1.toUpperCase());
export const camelCaseToSnakeCase = (string) =>
  string.replace(
    /([a-z])([A-Z])/g,
    (match, p1, p2) => `${p1}_${p2.toLowerCase()}`
  );
export const camelCaseToTitleCase = (string) => {
  const result = string.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
export const titleCase = compose(
  join(" "),
  map(compose(capitalize, toLower)),
  split(" ")
);
export const hyphenCaseToSnakeCase = (string) => string.replace(/-/g, "_");

/**
 * Removes hyphens and underscores from a string and
 * replaces them with a space.
 * @type {(value: string) => string}
 */

export const hyphenScoreToTitleCase = compose(titleCase, replace(/[-_]/g, " "));

/** Filters string to only allow numbers 0-9 useful for phone number fields
 *  @type {(value: string) => string}
 */

export const numberCharactersOnly = (string) => string.replace(/\D/g, "") ?? "";

/**
 * Removes an item in an object where the value is undefined
 *  @type {(value: string) => string}
 */

export const prune = reject(equals(undefined));
/**
 * Returns a function that turns an array of objects into an object with each item set by the key getter
 *  @type {(value: string) => string}
 */

export const toKeyed = curry((getKey, model) =>
  reduce(
    compose(
      converge(assoc, [compose(getKey, last), compose(model, last), head]),
      pair
    ),
    {}
  )
);
export const average = converge(divide, [sum, length]);
export const immutableGet = curry((key, collection) => collection.get(key));
export const immutableGetIn = invoker(1, "getIn");
export const immutableHas = invoker(1, "has");
export const immutableSet = invoker(2, "set");
export const immutableSetIn = invoker(2, "setIn");
export const immutableFirst = invoker(0, "first");
export const immutableLast = invoker(0, "last");
export const immutableIsEmpty = invoker(0, "isEmpty");
export const immutableIsNotEmpty = compose(not, immutableIsEmpty);
export const immutableAll = curry((f, x) => x.every(f));
export const immutableKeySeq = invoker(0, "keySeq");
export const immutableToSeq = invoker(0, "toSeq");
export const immutableValueSeq = invoker(0, "valueSeq");
export const immutableEntrySeq = invoker(0, "entrySeq");
export const immutableEntries = invoker(0, "entries");
export const immutableUpdate = invoker(2, "update");
export const immutableWithMutations = invoker(1, "withMutations");
export const immutableSort = invoker(1, "sort");
export const immutableSortBy = invoker(2, "sortBy");
export const immutableToArray = invoker(0, "toArray");
export const immutableToJS = invoker(0, "toJS");
export const immutableFlatten = invoker(1, "flatten");
export const immutableDelete = invoker(1, "delete");
export const immutableDeleteIn = invoker(1, "deleteIn");
export const immutableToList = invoker(0, "toList");
export const immutableToSet = invoker(0, "toSet");
export const immutableToOrderedSet = invoker(0, "toOrderedSet");
export const immutableToMap = invoker(0, "toMap");
export const immutableToObject = invoker(0, "toObject");
export const immutableMerge = invoker(1, "merge");
export const immutableIncludes = invoker(1, "includes");
export const immutableFilterNot = invoker(1, "filterNot");
export const immutableSome = invoker(1, "some");
export const immutableEvery = invoker(1, "every");
export const immutablePush = invoker(1, "push");
export const immutableTakeLast = invoker(1, "takeLast");
export const immutableFind = invoker(1, "find");
export const immutableReverse = invoker(0, "reverse");
export const immutableUnique = compose(
  invoker(0, "toList"),
  invoker(0, "toSet"),
  concat
);
export const immutableGroupBy = invoker(1, "groupBy");
export const immutableApplySpec = (spec) => compose(Map, applySpec(spec));
export const immutableIndexBy = (f) =>
  compose(Map, map(converge(Array.of, [f, identity])));
export const immutableEquals = invoker(1, "equals");
export const immutableReduce = invoker(2, "reduce");
export const immutableAdd = invoker(1, "add");
export const immutableIndexOf = invoker(1, "indexOf");
export const immutableUnshift = invoker(1, "unshift");
export const immutableClear = invoker(0, "clear");
export const immutableUpdateIn = invoker(2, "updateIn");
export const immutableMap = invoker(1, "map");
export const immutableFilter = invoker(1, "filter");
export const immutableToOrderedMap = invoker(0, "toOrderedMap");
export const immutableFindKey = invoker(1, "findKey");
export const immutableSubtract = invoker(1, "subtract");
export const immutableSkip = invoker(1, "skip");
export const immutableFlatMap = invoker(1, "flatMap");
export const immutableUnion = invoker(1, "union");
export const immutableMapKeys = invoker(1, "mapKeys");
export const lastImmutableIndex = compose(dec, prop("size"));
export const immutableEvolve = curry((evolver, collection) =>
  collection.map((value, key) => (evolver[key] ? evolver[key](value) : value))
);
export const immutableLensProp = (key) =>
  lens(immutableGet(key), immutableSet(key));
export const immutableLensPath = (path) =>
  lens(immutableGetIn(path), immutableSetIn(path));
export const immutableMapOf = (key) => compose(Map, objOf(key));
export const immutableSortByName = immutableSortBy(
  compose(unless(isNil, toLower), immutableGet("name")),
  null
);
export const immutableIndexById = immutableIndexBy(immutableGet("id"));
export const safeImmutableGet = curry(
  (key, collection) => collection && collection.get(key)
);
export const safeGetter = curry((getter, x) => x && getter(x));
export const safeBooleanGetter = curry((getter, x) => !!x && getter(x));
export const rangeStringFromList = ifElse(
  (list) => list.length > 1,
  compose(join("-"), converge(Array.of, [head, last])),
  head
);
export const toRangedList = compose(
  join(", "),
  map(rangeStringFromList),
  groupWith(compose(equals(1), flip(subtract))),
  uniq,
  sort(subtract),
  reject(isNil)
);
export const sortByName = sortBy(compose(toLower, prop("name")));
export const then = invoker(1, "then");
export const minZero = converge(Math.max, [always(0), identity]);
export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);
export const parseListOfStrings = (list) =>
  Seq(list).filterNot(either(isNil, isEmpty)).map(toInt).toArray();
export const parseStringList = (x) => x.split(/,\s?/);
export const objOfLens = (l) => set(l, __, {});
export const parseUrlParams = compose(
  fromPairs,
  map(split("=")),
  reject(isEmpty),
  split("&")
);
export const parseUrl = compose(
  applySpec({
    baseUrl: nth(0),
    params: compose(
      ifElse(either(isNil, isEmpty), always({}), parseUrlParams),
      nth(1)
    ),
  }),
  split("?")
);
const joinParsedUrlParams = compose(join("&"), map(join("=")), toPairs);
const addUrlParam = curry((key, value) =>
  compose(joinParsedUrlParams, assoc(key, value))
);

const removeUrlParam = (key) => compose(joinParsedUrlParams, dissoc(key));

export const addParamToUrl = curry((key, value, url) => {
  const { baseUrl, params } = parseUrl(url);
  return `${baseUrl}?${addUrlParam(key, value)(params)}`;
});
export const addParamsToUrl = curry((params, url) =>
  Seq(params)
    .filterNot(isNil)
    .reduce((acc, v, k) => addParamToUrl(k, v, acc), url)
);
export const removeParamFromUrl = curry((key, url) => {
  const { baseUrl, params } = parseUrl(url);
  return `${baseUrl}?${removeUrlParam(key)(params)}`;
});
export const removeParamsFromUrl = curry((keys, url) =>
  keys.reduce((acc, key) => removeParamFromUrl(key, acc), url)
);
export const debounce = curry((ms, f) => {
  let timer = null;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => f(...args), ms);
  };
});
export const immutableToRangedList = compose(toRangedList, immutableToArray);
export const ensureHttps = replace("http://", "https://");
export const hasTrailingSlash = compose(equals("/"), last);
export const ensureNoTrailingSlash = (string) =>
  last(string) === "/" ? init(string) : string;
export const cleanVideoUrl = (x) =>
  Maybe.fromNullable(x).map(compose(ensureHttps, ensureNoTrailingSlash));
export const safeNth = curry((n, list) =>
  Maybe.fromNullable(list).chain(compose(Maybe.fromNullable, nth(n)))
);
export const get = (maybe) => maybe.get();
export const getOrElse = (x) => (maybe) => maybe.getOrElse(x);
export const orElse = (f) => (maybe) => maybe.orElse(f);
export const hasOne = compose(equals(1), prop("size"));
export const notNil = compose(not, isNil);
export const notEmpty = compose(not, isEmpty);
export const safeJsonParse = (json) => {
  if (!json) {
    return Maybe.Nothing();
  }

  try {
    const parsed = JSON.parse(json);
    return Maybe.of(parsed);
  } catch (error) {
    return Maybe.Nothing();
  }
};
export const safeArrayOfInts = (x) =>
  Maybe.fromNullable(x)
    .chain(compose(traverse(Maybe.of, safeToInt), reject(isNil)))
    .getOrElse([]);
export const isInRange = curry((min, max, x) => x >= min && x <= max);
export const pad = curry((x, y) => `${x}${y}`);
export const padN = curry((n, x, y) =>
  n === 0 ? y : padN(n - 1, x, pad(x, y))
);
export const padToNLength = curry((n, x, y) =>
  padN(Math.max(0, n - y.length), x, y)
);
export const isFixed = pathEq(["style", "position"], "fixed");
export const getElementOffsetTop = (element, offsetTop = 0) => {
  if (
    isNil(element?.offsetParent) ||
    !(element.offsetParent instanceof HTMLElement)
  ) {
    return offsetTop;
  }

  return isFixed(element.offsetParent)
    ? offsetTop + element.getBoundingClientRect().top
    : getElementOffsetTop(
        element.offsetParent,
        !isNaN(element.offsetTop)
          ? offsetTop + element.offsetTop - element.scrollTop
          : offsetTop
      );
};
export const getUrlParam = curry((key, url) => {
  const parsedUrl = new URL(url);
  return Maybe.fromNullable(parsedUrl.searchParams.get(key));
});
export const parseLocationSearch = compose(
  prop("params"),
  parseUrl,
  decodeURIComponent,
  prop("search")
);

// monogram :: String -> String
export const monogram = compose(toUpper, head);

// inspect :: a -> a
export const inspect = tap(console.log);

// getFileExtension :: String -> String
export const getFileExtension = compose(defaultTo(""), last, split("."));

// noop :: void -> void
export const noop = () => {};

// taskify :: Promise a -> Task Error a
export const taskify = (promise) =>
  new Task((reject, resolve) => promise.then(resolve).catch(reject));

// apiBoolean :: any -> boolean
export const normalizeApiBoolean = compose(
  cond([
    [equals("0"), F],
    [equals("N"), F],
    [equals("false"), F],
    [T, Boolean],
  ]),
  when(is(String), toLower)
);
export const safePath = compose(Maybe.fromNullable, path);
export const safeProp = curry((key, x) => safePath([key], x));
export const safeFind = curry((f, findable) =>
  Maybe.fromNullable(findable.find(f))
);
export const addUniq = compose(uniq, concat);
export const replaceItem = curry((itemToReplace, itemToReplaceWith, list) => {
  const index = list.findIndex(equals(itemToReplace));
  return ~index
    ? list.slice(0, index).concat([itemToReplaceWith, ...list.slice(index + 1)])
    : list;
});
export const predicateToMaybe = curry((predicate, value) =>
  predicate(value) ? Maybe.of(value) : Maybe.Nothing()
);
/** @type {<T extends (params: any[]) => any>(fn: T) => T} */
export const memoizeLatest = (fn) => {
  let currentArgs = [];
  let currentValue;
  return (...args) => {
    if (
      currentValue === undefined ||
      args.some((arg, index) => arg !== currentArgs[index])
    ) {
      currentArgs = args;
      currentValue = fn(...args);
    }

    return currentValue;
  };
};
export const memoizeLatestBy = curry((createCacheKey, fn) => {
  const cachedArgs = {};
  const cachedValues = {};
  return (...args) => {
    const cacheKey = createCacheKey(...args);
    const currentArgs = cachedArgs[cacheKey];

    if (
      !(cacheKey in cachedValues) ||
      args.some((arg, index) => arg !== currentArgs[index])
    ) {
      cachedArgs[cacheKey] = args;
      cachedValues[cacheKey] = fn(...args);
    }

    return cachedValues[cacheKey];
  };
});
export const indexNotFound = (index) => equals(-1, index);
/** @type {<A>(collection: A) => A} */

export const flipImmutableMapKeys = (collection) =>
  collection.reduce((acc, v, k) => acc.set(k, v), collection.clear());
export const taskToPromise = (task) =>
  new Promise((resolve, reject) => {
    task.fork(reject, resolve);
  });
export const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const preload = new Image();

    preload.onload = function () {
      resolve(src);
    };

    preload.onerror = function () {
      reject(new Error(`Failed to preload image "${src}"`));
    };

    preload.src = src;
  });
export const findIndexOr = curry((predicate, defaultValue, list) => {
  const index = list.findIndex(predicate);
  return index >= 0 ? index : defaultValue;
});
export const union = curry((setA, setB) => {
  const result = new Set(setA);

  for (let elem of setB) {
    result.add(elem);
  }

  return result;
});
export const filterMap = curry((predicate, xf, list) => {
  const result = [];

  for (const item of list) {
    if (predicate(item)) {
      result.push(xf(item));
    }
  }

  return result;
});

export const mapFilter = curry((xf, predicate, list) => {
  const result = [];

  for (const item of list) {
    const xfed = xf(item);
    if (predicate(xfed)) {
      result.push(xfed);
    }
  }

  return result;
});

export const regexTestInput = (input) => {
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    input.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"),
    "i"
  );
  return (target) => regex.test(target);
};
export const findMap = curry((predicate, xf, list) => {
  const item = list.find(predicate);
  return !isNil(item) ? xf(item) : null;
});
export const indexByMap = curry((xf, getKey, list) => {
  const result = {};

  for (const item of list) {
    const b = xf(item);
    result[getKey(b)] = b;
  }

  return result;
});
export const toggleProp = curry((key, object) =>
  evolve(
    {
      [key]: not,
    },
    object
  )
);
export const notEqProps = complement(eqProps);
export const foldWith = (fns) => (...args) =>
  fns.map(applyTo(...args)).reduce(applyTo, {});
export const delay = (fn) => {
  let cache = undefined;
  return (...args) => {
    if (cache === undefined) {
      cache = {
        value: fn(...args),
      };
    }

    return cache.value;
  };
};
export const uniqByReference = uniqWith(Object.is);
export const memoizeWithId = memoizeWith(prop("id"));
export const propNotNil = propSatisfies(notNil);
export const indexById = indexBy(prop("id"));
