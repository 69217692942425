import graphql from "babel-plugin-relay/macro";
import DefaultAdvancedFields from "components/AdvancedFields";
import CommPathNumberField from "components/FullProgramming/common/CommPathFields/CommPathNumberField";
import * as React from "react";
import { useFragment } from "react-relay";
import { FeatureKeyEnum } from "securecom-graphql/client";
import styled from "styled-components/macro";
import CommPathAlarmReportsField from "../../common/CommPathFields/CommPathAlarmReportsField";
import CommPathAlarmSwitchoverField from "../../common/CommPathFields/CommPathAlarmSwitchoverField";
import CommPathCellApn2Field from "../../common/CommPathFields/CommPathCellApn2Field";
import CommPathCellApnField from "../../common/CommPathFields/CommPathCellApnField";
import CommPathCheckInMinutesField from "../../common/CommPathFields/CommPathCheckInMinutesField";
import CommPathCommTypeField from "../../common/CommPathFields/CommPathCommTypeField";
import { CommPathContextProvider } from "../../common/CommPathFields/CommPathContext";
import CommPathDoorAccessReportsField from "../../common/CommPathFields/CommPathDoorAccessReportsField";
import CommPathDuplicateAlarmsField from "../../common/CommPathFields/CommPathDuplicateAlarmsField";
import CommPathEncryptionField from "../../common/CommPathFields/CommPathEncryptionField";
import CommPathEncryptionSchemaField from "../../common/CommPathFields/CommPathEncryptionSchemaField";
import CommPathFailTestHoursField from "../../common/CommPathFields/CommPathFailTestHoursField";
import CommPathFailTimeField from "../../common/CommPathFields/CommPathFailTimeField";
import CommPathFirstPhoneNumberField from "../../common/CommPathFields/CommPathFirstPhoneNumberField";
import CommPathOpenCloseUserReportsField from "../../common/CommPathFields/CommPathOpenCloseUserReportsField";
import CommPathPanicTestField from "../../common/CommPathFields/CommPathPanicTestField";
import CommPathProtocolField from "../../common/CommPathFields/CommPathProtocolField";
import CommPathReceiverIpAddressField from "../../common/CommPathFields/CommPathReceiverIpAddressField";
import CommPathReceiverIpv6AddressField from "../../common/CommPathFields/CommPathReceiverIpv6AddressField";
import CommPathReceiverPortField from "../../common/CommPathFields/CommPathReceiverPortField";
import CommPathRetryTimeField from "../../common/CommPathFields/CommPathRetryTimeField";
import CommPathSecondLinePrefixFor893AField from "../../common/CommPathFields/CommPathSecondLinePrefixFor893AField";
import CommPathSecondPhoneNumberField from "../../common/CommPathFields/CommPathSecondPhoneNumberField";
import CommPathSendFailField from "../../common/CommPathFields/CommPathSendFailField";
import CommPathSendPathField from "../../common/CommPathFields/CommPathSendPathField";
import CommPathSubstitutionCodeField from "../../common/CommPathFields/CommPathSubstitutionCodeField";
import CommPathSupervisoryTroubleReportsField from "../../common/CommPathFields/CommPathSupervisoryTroubleReportsField";
import CommPathTestFrequencyDayField from "../../common/CommPathFields/CommPathTestFrequencyDayField";
import CommPathTestFrequencyNumberField from "../../common/CommPathFields/CommPathTestFrequencyNumberField";
import CommPathTestFrequencyUnitField from "../../common/CommPathFields/CommPathTestFrequencyUnitField";
import CommPathTestReportField from "../../common/CommPathFields/CommPathTestReportField";
import CommPathTestTimeField from "../../common/CommPathFields/CommPathTestTimeField";
import CommPathTypeField from "../../common/CommPathFields/CommPathTypeField";
import CommPathUse893AField from "../../common/CommPathFields/CommPathUse893AField";
import CommPathUseCheckInField from "../../common/CommPathFields/CommPathUseCheckInField";
import CommPathUseIPv6Field from "../../common/CommPathFields/CommPathUseIPv6Field";
import {
  useFeatureKeyEnabled,
  useSoftwareVersion,
} from "../../common/PanelContext";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { panelVersionGTOE, panelVersionLTOE } from "../../utils/panel";
import { XRCommPathFields_communicationPath$key } from "./__generated__/XRCommPathFields_communicationPath.graphql";

const AdvancedFields = styled(DefaultAdvancedFields)`
  margin-top: var(--measure-3x);
`;

function XRCommPathFields(props: {
  commPath: XRCommPathFields_communicationPath$key;
}) {
  const data = useFragment(
    graphql`
      fragment XRCommPathFields_communicationPath on CommunicationPath {
        id
        ...CommPathContext_communicationPath
        ...CommPathNumberField_communicationPath
        ...CommPathCommTypeField_communicationPath
        ...CommPathTypeField_communicationPath
        ...CommPathTestReportField_communicationPath
        ...CommPathTestFrequencyNumberField_communicationPath
        ...CommPathTestFrequencyUnitField_communicationPath
        ...CommPathTestTimeField_communicationPath
        ...CommPathFirstPhoneNumberField_communicationPath
        ...CommPathSecondPhoneNumberField_communicationPath
        ...CommPathUse893AField_communicationPath
        ...CommPathAlarmSwitchoverField_communicationPath
        ...CommPathAlarmReportsField_communicationPath
        ...CommPathSupervisoryTroubleReportsField_communicationPath
        ...CommPathOpenCloseUserReportsField_communicationPath
        ...CommPathDoorAccessReportsField_communicationPath
        ...CommPathPanicTestField_communicationPath
        ...CommPathSendFailField_communicationPath
        ...CommPathSendPathField_communicationPath
        ...CommPathUseCheckInField_communicationPath
        ...CommPathCheckInMinutesField_communicationPath
        ...CommPathFailTimeField_communicationPath
        ...CommPathUseIPv6Field_communicationPath
        ...CommPathReceiverIpAddressField_communicationPath
        ...CommPathReceiverIpv6AddressField_communicationPath
        ...CommPathReceiverPortField_communicationPath
        ...CommPathProtocolField_communicationPath
        ...CommPathRetryTimeField_communicationPath
        ...CommPathSubstitutionCodeField_communicationPath
        ...CommPathCellApnField_communicationPath
        ...CommPathCellApn2Field_communicationPath
        ...CommPathEncryptionField_communicationPath
        ...CommPathEncryptionSchemaField_communicationPath
        ...CommPathFailTestHoursField_communicationPath
        ...CommPathSecondLinePrefixFor893AField_communicationPath
        ...CommPathTestFrequencyDayField_communicationPath
        ...CommPathDuplicateAlarmsField_communicationPath
      }
    `,
    props.commPath
  );

  const softwareVersion = useSoftwareVersion();

  const encryptionFeatureKeyEnabled = useFeatureKeyEnabled(
    FeatureKeyEnum.ENCRYPTION
  );

  return (
    <CommPathContextProvider commPath={data}>
      <ProgrammingConceptForm.Fields>
        <CommPathNumberField />
        <CommPathCommTypeField />
        <CommPathTypeField />
        <CommPathTestReportField />
        <CommPathTestFrequencyNumberField />
        <CommPathTestFrequencyUnitField />
        <CommPathTestFrequencyDayField />
        <CommPathTestTimeField />
        <CommPathFirstPhoneNumberField />
        <CommPathSecondPhoneNumberField />
        <CommPathUseCheckInField />
        <CommPathCheckInMinutesField />
        <CommPathFailTimeField />
        {panelVersionGTOE(191, softwareVersion) && <CommPathUseIPv6Field />}
        <CommPathReceiverIpAddressField />
        {panelVersionGTOE(191, softwareVersion) && (
          <CommPathReceiverIpv6AddressField />
        )}
        <CommPathReceiverPortField />
        {encryptionFeatureKeyEnabled && (
          <>
            <CommPathEncryptionField />
            <CommPathEncryptionSchemaField />
          </>
        )}
      </ProgrammingConceptForm.Fields>
      <AdvancedFields>
        <ProgrammingConceptForm.Fields>
          <CommPathFailTestHoursField />
          <CommPathCellApnField />
          {panelVersionLTOE(192, softwareVersion) && <CommPathCellApn2Field />}
          <CommPathUse893AField />
          <CommPathSecondLinePrefixFor893AField />
          <CommPathProtocolField />
          <CommPathRetryTimeField />
          <CommPathSubstitutionCodeField />
          <CommPathAlarmSwitchoverField />
          <CommPathDuplicateAlarmsField />
          <CommPathAlarmReportsField />
          <CommPathSupervisoryTroubleReportsField />
          <CommPathOpenCloseUserReportsField />
          <CommPathDoorAccessReportsField />
          <CommPathPanicTestField />
          <CommPathSendFailField />
          <CommPathSendPathField />
        </ProgrammingConceptForm.Fields>
      </AdvancedFields>
    </CommPathContextProvider>
  );
}

export default XRCommPathFields;
