import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { KeypadArmedLed, SystemType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsKeypadArmedLedField_systemOptions$key } from "./__generated__/SystemOptionsKeypadArmedLedField_systemOptions.graphql";

export const systemOptionsKeypadArmedLedFieldId = () =>
  "system-options-keypad-armed-led";

function SystemOptionsKeypadArmedLedField() {
  const [{ keypadArmedLed, systemType }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsKeypadArmedLedField_systemOptions$key>(
      graphql`
        fragment SystemOptionsKeypadArmedLedField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            systemType
            keypadArmedLed
          }
          ... on Xt75SystemOptions {
            systemType
            keypadArmedLed
          }
        }
      `
    );

  const fieldId = systemOptionsKeypadArmedLedFieldId();
  const disabled = systemType !== SystemType.AREA;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Keypad Armed LED"
      tooltip="Set the armed LED on Keypads to indicate when any Keypad Display Area is Armed or when all Keypad Display Areas are Armed."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={keypadArmedLed}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "keypadArmedLed");
          });
        }}
      >
        <Select.Option value={KeypadArmedLed.ALL}>All</Select.Option>
        <Select.Option value={KeypadArmedLed.ANY}>Any</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsKeypadArmedLedField;
