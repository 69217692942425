/**
 *
 * ArmingShieldIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ArmingShieldIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path
        d="M57.314,12.144c-0.01-0.238-0.102-2.353-0.199-3.138c-0.155-1.245-1.111-4.365-6.625-6.43C45.929,0.867,39.709,0,32.002,0
    c-0.003,0.001-0.007,0-0.011,0C26.575,0.001,21.91,0.439,18.022,1.287l0,0c0,0-0.001,0-0.002,0.001
    c-1.641,0.357-3.157,0.781-4.511,1.288C7.995,4.641,7.04,7.761,6.884,9.008c-0.097,0.783-0.189,2.897-0.201,3.221v22.058
    c0,16.947,22.235,29.643,25.27,29.713L32,64l0.046-0.001c3.035-0.07,25.27-12.766,25.27-29.713L57.314,12.144z M32,4
    c7.227,0,12.976,0.781,17.087,2.322c3.734,1.398,4.037,3,4.059,3.177c0.054,0.438,0.125,1.769,0.163,2.559
    c-2.309-1.689-4.932-2.741-5.81-3.066c-2.476-1.09-7.057-2.023-15.49-2.023c-7.218,0-11.609,0.687-14.276,1.568
    c0.159-1.456,0.605-3.015,1.492-3.419C22.75,4.381,27.014,4,32,4z M32.009,51.772c-3.309-1.954-14.326-9.132-14.326-18.104v-0.73
    h0.001V12.889c1.019-0.569,4.474-1.921,14.339-1.921c9.855,0,13.308,1.352,14.325,1.922v20.778
    C46.349,42.641,35.32,49.819,32.009,51.772z M10.684,34.286l-0.002-21.973c0.036-0.839,0.114-2.344,0.172-2.813
    c0.02-0.161,0.28-1.499,3.128-2.791c-0.347,1.729-0.297,3.315-0.297,3.869v1.005l-0.001,0.002v22.083
    c0,9.561,9.121,16.899,14.163,20.227c-2.494,0.859-4.399,0.419-4.819,0.301C17.133,49.457,10.684,42.279,10.684,34.286z M32,59.935
    c-0.708-0.263-2.047-0.954-3.716-1.995c1.165-0.315,2.42-0.829,3.717-1.616l0.007,0.004l0.927-0.484
    c0.711-0.371,17.414-9.245,17.414-22.175V14.879c1.623,1.059,2.882,2.256,2.968,3.271v16.137C53.316,47.605,35.406,58.67,32,59.935
    z"
      />
    </g>
  </SvgIcon>
);

export default ArmingShieldIcon;
