/**
 * @generated SignedSource<<7344a0f73900a498403657164e0e0217>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly axNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly deviceType: {
      readonly data: DeviceInformationType | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lxNumber: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
  } | null> | null;
  readonly " $fragmentType": "XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "d388016f882ae568db71c3b831af19ef";

export default node;
