/**
 *Directive creating header used on each page
 */
App.directive("daViewHeader", function () {
  return {
    restrict: "E",
    scope: {
      title: "@",
    },
    templateUrl: "app/common/templates/view-header.html",
    transclude: true,
  };
});
