import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { getClosestOutputValue } from "../OutputOptionsFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationFireBellOutputNumberField_zone$key } from "./__generated__/ZoneInformationFireBellOutputNumberField_zone.graphql";

export const zoneInformationFireBellOutputNumberFieldId = (number: string) =>
  `zone-information-fire-bell-output-number-${number}`;

function ZoneInformationFireBellOutputNumberField() {
  const [{ number, fireBellOutputNumber, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationFireBellOutputNumberField_zone$key>(
      graphql`
        fragment ZoneInformationFireBellOutputNumberField_zone on Zone {
          number
          type
          fireBellOutputNumber
        }
      `
    );

  const fieldId = zoneInformationFireBellOutputNumberFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { OUTPUT_NUMBER: RULES } = resolveZoneFieldRules(hardwareModel);
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const originalValue = React.useRef(fireBellOutputNumber).current;
  const disabled = ![
    ZoneType.FIRE,
    ZoneType.FIRE_VERIFY,
    ZoneType.SUPERVISORY,
    ZoneType.CARBON_MONOXIDE,
  ].includes(type as ZoneType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Fire Bell Output Number"
      tooltip="Output turns on when the zone is placed in alarm."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={fireBellOutputNumber ?? "0"}
        pattern={RULES.PATTERN}
        inlineHelp={RULES.INLINE_HELP}
        validationMessage={RULES.VALIDATION_MSG}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "fireBellOutputNumber");
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                originalValue ?? "",
                hardwareModel,
                validNumericalRange
              ),
              "fireBellOutputNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationFireBellOutputNumberField;
