/**
 * @generated SignedSource<<b94658c88c5d9fa18cfd81d2d98a95f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type CommPathTestFrequencyNumberField_communicationPath$data = {
  readonly id: string;
  readonly number: number;
  readonly testFrequencyNumber: number;
  readonly testFrequencyNumberMax: number;
  readonly testFrequencyNumberMin: number;
  readonly testReport: CommunicationPathTestReport;
  readonly " $fragmentType": "CommPathTestFrequencyNumberField_communicationPath";
};
export type CommPathTestFrequencyNumberField_communicationPath$key = {
  readonly " $data"?: CommPathTestFrequencyNumberField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathTestFrequencyNumberField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathTestFrequencyNumberField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testFrequencyNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testFrequencyNumberMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testFrequencyNumberMax",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "8828d8e2ddc3e7108fab659e341e0ace";

export default node;
