import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { setIncludedFields as setAreaInformationIncludedFields } from "./Data/TMSentryAreaInformationTemplateData";
import { setIncludedFields as setCommunicationIncludedFields } from "./Data/TMSentryCommunicationTemplateData";
import { setIncludedFields as setLockoutCodeIncludedFields } from "./Data/TMSentryLockoutCodeTemplateData";
import { setIncludedFields as setNetworkOptionsIncludedFields } from "./Data/TMSentryNetworkOptionsTemplateData";
import { setIncludedFields as setOutputOptionsIncludedFields } from "./Data/TMSentryOutputOptionsTemplateData";
import { setIncludedFields as setRemoteOptionsIncludedFields } from "./Data/TMSentryRemoteOptionsTemplateData";
import { setIncludedFields as setSystemOptionsIncludedFields } from "./Data/TMSentrySystemOptionsTemplateData";
import { setIncludedFields as setSystemReportsIncludedFields } from "./Data/TMSentrySystemReportsTemplateData";
import { setIncludedFields as setZoneIncludedFields } from "./Data/TMSentryZoneInformationTemplateData";
import { TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts$key } from "./__generated__/TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts.graphql";

export const getState = (
  template: TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        ...TMSentryAreaInformationTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentryCommunicationTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentryLockoutCodeTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentryNetworkOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentryOutputOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentryRemoteOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentrySystemOptionsTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentrySystemReportsTemplateDataInline_tMSentryProgrammingTemplateConcepts
        ...TMSentryZoneInformationTemplateDataInline_tMSentryProgrammingTemplateConcepts
      }
    `,
    template
  );

export const setAllIncludedFields = (
  template: TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const templateData = getState(template);
  setAreaInformationIncludedFields(templateData, setIncludedFields);
  setCommunicationIncludedFields(templateData, setIncludedFields);
  setLockoutCodeIncludedFields(templateData, setIncludedFields);
  setNetworkOptionsIncludedFields(templateData, setIncludedFields);
  setOutputOptionsIncludedFields(templateData, setIncludedFields);
  setRemoteOptionsIncludedFields(templateData, setIncludedFields);
  setSystemOptionsIncludedFields(templateData, setIncludedFields);
  setSystemReportsIncludedFields(templateData, setIncludedFields);
  setZoneIncludedFields(templateData, setIncludedFields);
};
