import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";
import { OutputInformationContextProvider } from "../../common/OutputInformationFields/OutputInformationContext";
import OutputInformationNameField from "../../common/OutputInformationFields/OutputInformationNameField";
import OutputInformationNumberField from "../../common/OutputInformationFields/OutputInformationNumberField";
import OutputInformationRealTimeStatusField from "../../common/OutputInformationFields/OutputInformationRealTimeStatusField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { XFOutputInformationFields_output$key } from "./__generated__/XFOutputInformationFields_output.graphql";

function XFOutputInformationFields(props: {
  outputInformation: XFOutputInformationFields_output$key;
}) {
  const data = useFragment(
    graphql`
      fragment XFOutputInformationFields_output on Output {
        id
        number
        ...OutputInformationContext_output
        ...OutputInformationNumberField_output
        ...OutputInformationNameField_output
        ...OutputInformationRealTimeStatusField_output
      }
    `,
    props.outputInformation
  );

  return (
    <OutputInformationContextProvider outputInformation={data}>
      <ProgrammingConceptForm.Fields>
        <OutputInformationNumberField />
        <OutputInformationNameField />
        <OutputInformationRealTimeStatusField />
      </ProgrammingConceptForm.Fields>
    </OutputInformationContextProvider>
  );
}

export default XFOutputInformationFields;
