import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsCodeChangeReportsField_systemReports$key } from "./__generated__/SystemReportsCodeChangeReportsField_systemReports.graphql";

export const systemReportsCodeChangeReportsFieldId = () =>
  "system-reports-code-change-reports";

function SystemReportsCodeChangeReportsField() {
  const [{ codeChangeReports }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsCodeChangeReportsField_systemReports$key>(
      graphql`
        fragment SystemReportsCodeChangeReportsField_systemReports on SystemReports {
          codeChangeReports
        }
      `
    );

  const fieldId = systemReportsCodeChangeReportsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Code Change Reports"
      tooltip="If enabled in Communication Programming, send messages any User Code is added, changed, or deleted."
    >
      <Switch
        label="Code Change Reports"
        id={fieldId}
        name={fieldId}
        checked={codeChangeReports}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(!codeChangeReports, "codeChangeReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsCodeChangeReportsField;
