import graphql from "babel-plugin-relay/macro";
import NeutralText from "components/NeutralText";
import * as React from "react";
import { useFragment, useQueryLoader } from "react-relay/hooks";
import Sections, { SectionHeader } from "../Layout/Layout";
import { SiteControlSystemsSection_site$key } from "./__generated__/SiteControlSystemsSection_site.graphql";

import { noop } from "common/utils";
import FormGridItems from "components/FormGridItems";
import { mapCommTypeToLabel } from "utils/control-systems";
import { ExistingSiteControlSystemModal } from "./ExistingSiteControlSystemForm";
import FirmwareUpdateBadge from "./FirmwareUpdateBadge";
import NewSiteControlSystemForm from "./NewSiteControlSystemForm";
import { SiteControlSystemJobStatusBadgeWithAutoRefresh } from "./SiteControlSystemJobStatusBadge";
import { SiteControlSystemOnlineStatusBadge } from "./SiteControlSystemOnlineStatusBadge";
import existingControlSystemQuery, {
  ExistingSiteControlSystemFormQuery,
  ExistingSiteControlSystemFormQuery$variables,
} from "./__generated__/ExistingSiteControlSystemFormQuery.graphql";
import { SiteControlSystemsSection_siteControlSystem$key } from "./__generated__/SiteControlSystemsSection_siteControlSystem.graphql";

function DoorListItem({
  siteControlSystemRef,
  loadQuery,
  setInitialSelectedDoor,
}: {
  siteControlSystemRef: SiteControlSystemsSection_siteControlSystem$key;
  loadQuery: (
    variables: ExistingSiteControlSystemFormQuery$variables,
    options?: { fetchPolicy?: any }
  ) => void;
  setInitialSelectedDoor: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const data = useFragment(
    graphql`
      fragment SiteControlSystemsSection_siteControlSystem on SiteControlSystem {
        id
        serialNumber
        type
        commType
        hasPendingChanges
        online
        doors(includeUnprogrammedDoors: true) {
          id
          number
          name
          active
        }
        ...FirmwareUpdateBadge_siteControlSystem
        ...SiteControlSystemJobStatusBadge_siteControlSystem
        ...SiteControlSystemOnlineStatusBadge_siteControlSystem
      }
    `,
    siteControlSystemRef
  );

  return (
    <FormGridItems.Item x1PreProgram={!data.online}>
      <FormGridItems.ClickableContent
        onClick={() => {
          data.hasPendingChanges
            ? noop()
            : loadQuery(
                {
                  siteControlSystemId: data.id,
                },
                { fetchPolicy: "network-only" }
              );
        }}
      >
        <FormGridItems.ItemTitle>
          <SiteControlSystemOnlineStatusBadge siteControlSystem={data} />
          SN: {data.serialNumber}
        </FormGridItems.ItemTitle>

        <FormGridItems.ItemSubtitle>
          {mapCommTypeToLabel(data.commType)}
        </FormGridItems.ItemSubtitle>
        <React.Suspense fallback={null}>
          <SiteControlSystemJobStatusBadgeWithAutoRefresh
            siteControlSystem={data}
          />
          <FirmwareUpdateBadge controlSystem={data} />
        </React.Suspense>
      </FormGridItems.ClickableContent>
      {data.doors.map((door) =>
        Number(door.number) === 1 || door.active ? (
          <FormGridItems.ClickableContent
            key={door.id}
            onClick={(event) => {
              event.stopPropagation();
              setInitialSelectedDoor(door.id);
              data.hasPendingChanges
                ? noop()
                : loadQuery(
                    {
                      siteControlSystemId: data.id,
                    },
                    { fetchPolicy: "network-only" }
                  );
            }}
          >
            <FormGridItems.ItemTitle>
              {door.name || (
                <NeutralText value={500}>
                  <em>Unused Door</em>
                </NeutralText>
              )}
            </FormGridItems.ItemTitle>
          </FormGridItems.ClickableContent>
        ) : null
      )}
    </FormGridItems.Item>
  );
}

function SiteControlSystemsSection({
  siteRef,
  initiallyOpenFirstDoor,
}: {
  siteRef: SiteControlSystemsSection_site$key;
  initiallyOpenFirstDoor: boolean;
}) {
  const data = useFragment<SiteControlSystemsSection_site$key>(
    graphql`
      fragment SiteControlSystemsSection_site on Site {
        id
        controlSystems {
          id
          intendedUsage
          allDoors: doors(includeUnprogrammedDoors: true) {
            id
          }
          firmwareStatus
          firmwareUpdateProgress
          ...SiteControlSystemsSection_siteControlSystem
        }
        totalControlSystems
        ...NewSiteControlSystemForm_site
      }
    `,
    siteRef
  );

  const firstSystem = data.controlSystems?.[0];

  const [adding, setAdding] = React.useState(false);

  const [initialSelectedDoor, setInitialSelectedDoor] = React.useState<
    string | null
  >((initiallyOpenFirstDoor && firstSystem?.allDoors[0]?.id) || null);

  const [
    existingControlSystemQueryReference,
    loadExistingControlSystemQuery,
    disposeExistingControlSystemQuery,
  ] = useQueryLoader<ExistingSiteControlSystemFormQuery>(
    existingControlSystemQuery
  );

  const selectedControlSystem = React.useMemo(
    () =>
      existingControlSystemQueryReference &&
      (data.controlSystems.find(
        ({ id }) =>
          id ===
          existingControlSystemQueryReference.variables.siteControlSystemId
      ) ??
        null),
    [data.controlSystems, existingControlSystemQueryReference]
  );

  const siteHasMoreThanOneDoor = data.totalControlSystems > 1;

  const siteHasFirmwareUpdatesInProgress = data.controlSystems?.some(
    ({ firmwareStatus }) => firmwareStatus === "UPDATES_IN_PROGRESS"
  );

  function closeModal() {
    setAdding(false);
    setInitialSelectedDoor(null);
    disposeExistingControlSystemQuery();
  }

  React.useEffect(() => {
    if (initiallyOpenFirstDoor && firstSystem?.id) {
      loadExistingControlSystemQuery({
        siteControlSystemId: firstSystem?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* DOORS SECTION */}
      <Sections.Section>
        <SectionHeader>
          <SectionHeader.Title>Doors</SectionHeader.Title>
          <SectionHeader.Add
            onClick={() => {
              setAdding(true);
            }}
          />
        </SectionHeader>
        <FormGridItems>
          {data.controlSystems?.map(
            (controlSystem) =>
              controlSystem.intendedUsage === "DOOR_ACCESS" && (
                <DoorListItem
                  siteControlSystemRef={controlSystem}
                  key={controlSystem.id}
                  loadQuery={loadExistingControlSystemQuery}
                  setInitialSelectedDoor={setInitialSelectedDoor}
                />
              )
          )}
        </FormGridItems>
      </Sections.Section>

      {adding && (
        <NewSiteControlSystemForm
          siteRef={data}
          onCancel={closeModal}
          onSaved={(siteControlSystemId, firstDoorId) => {
            setAdding(false);
            setInitialSelectedDoor(firstDoorId);
            loadExistingControlSystemQuery({ siteControlSystemId });
          }}
        />
      )}

      {existingControlSystemQueryReference && selectedControlSystem && (
        <ExistingSiteControlSystemModal
          siteHasFirmwareUpdatesInProgress={siteHasFirmwareUpdatesInProgress}
          firmwareStatus={selectedControlSystem.firmwareStatus}
          firmwareUpdateProgress={selectedControlSystem.firmwareUpdateProgress}
          initialSelectedDoor={initialSelectedDoor}
          queryReference={existingControlSystemQueryReference}
          onSaved={closeModal}
          onDeleted={closeModal}
          onCancel={closeModal}
          canDelete={siteHasMoreThanOneDoor}
          key={
            existingControlSystemQueryReference.variables.siteControlSystemId
          }
        />
      )}
    </>
  );
}

export default SiteControlSystemsSection;
