import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import * as React from "react";
import {
  ZoneSensorType,
  ZoneTransmitterContact,
} from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationContactField_zone$key } from "./__generated__/ZoneInformationContactField_zone.graphql";

export const zoneInformationContactFieldId = (number: string) =>
  `zone-information-contact-${number}`;

function ZoneInformationContactField() {
  const [
    {
      number,
      contactNumber,
      wireless,
      sensorType,
      serialNumber,
      competitorWireless,
    },
    updateZoneInformation,
  ] = useZoneInformationFragment<ZoneInformationContactField_zone$key>(
    graphql`
      fragment ZoneInformationContactField_zone on Zone {
        number
        wireless
        sensorType
        competitorWireless
        serialNumber
        contactNumber
      }
    `
  );

  const fieldId = zoneInformationContactFieldId(String(number));
  const softwareVersion = useSoftwareVersion();
  const supportsFireCoAndTemp =
    sensorType === ZoneSensorType.SMOKE &&
    !!serialNumber.match(/^[0][7]\d{6}?$/);
  const supports1168 = panelVersionGTOE(192, softwareVersion);
  const disabled =
    !(
      wireless &&
      ([
        ZoneSensorType.WIRELESS_FOUR_ZONE_EXPANDER,
        ZoneSensorType.UNIVERSAL_TRANSMITTER,
      ].includes(sensorType as ZoneSensorType) ||
        (supportsFireCoAndTemp && supports1168))
    ) && !competitorWireless;

  const supportsFourContacts =
    sensorType === ZoneSensorType.WIRELESS_FOUR_ZONE_EXPANDER;
  const supportsCompetitorWirelessFourContacts = competitorWireless;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label="Contact"
      tooltip="Select Internal / External Contact if using a wireless transmitter. Select which Contact to be used for this Zone if using an 1114 Zone Expander."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={contactNumber ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "contactNumber");
          });
        }}
      >
        {!(
          supportsFourContacts ||
          supportsFireCoAndTemp ||
          supportsCompetitorWirelessFourContacts
        ) && (
          <>
            <Select.Option value={ZoneTransmitterContact.INTERNAL}>
              Internal
            </Select.Option>
            <Select.Option value={ZoneTransmitterContact.EXTERNAL}>
              External
            </Select.Option>
          </>
        )}
        {(supportsFourContacts || supportsCompetitorWirelessFourContacts) && (
          <>
            <Select.Option value={ZoneTransmitterContact.INTERNAL}>
              {supportsCompetitorWirelessFourContacts
                ? `Contact 1`
                : `Contact 1 (Internal)`}
            </Select.Option>
            <Select.Option value={ZoneTransmitterContact.EXTERNAL}>
              {supportsCompetitorWirelessFourContacts
                ? `Contact 2`
                : `Contact 2 (External)`}
            </Select.Option>
            <Select.Option value={ZoneTransmitterContact.CONTACT_3}>
              Contact 3
            </Select.Option>
            <Select.Option value={ZoneTransmitterContact.CONTACT_4}>
              Contact 4
            </Select.Option>
          </>
        )}
        {supportsFireCoAndTemp && supports1168 && (
          <>
            <Select.Option value={ZoneTransmitterContact.INTERNAL}>
              Fire
            </Select.Option>
            <Select.Option value={ZoneTransmitterContact.EXTERNAL}>
              Carbon Monoxide
            </Select.Option>
            <Select.Option value={ZoneTransmitterContact.CONTACT_3}>
              Low Temperature
            </Select.Option>
          </>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationContactField;
