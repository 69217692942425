import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { DualAuthorityEnum } from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import Select from "../../Select";
import { useAreaFragment } from "./AreaContext";
import { AreaDualAuthorityField_xrArea$key } from "./__generated__/AreaDualAuthorityField_xrArea.graphql";

export const areaDualAuthorityFieldId = (number: string) =>
  `area-dual-authority-${number}`;

function AreaDualAuthorityField() {
  const [{ number, dualAuthority }, updateArea] =
    useAreaFragment<AreaDualAuthorityField_xrArea$key>(
      graphql`
        fragment AreaDualAuthorityField_xrArea on XrArea {
          number
          dualAuthority
        }
      `
    );

  const fieldId = areaDualAuthorityFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={"Dual Authority"}
      tooltip={
        "When enabled, 2 valid User Codes are required to perform the specified action on the Area if the User has a profile containing Dual Authority."
      }
    >
      <Select
        id={fieldId}
        value={dualAuthority ?? DualAuthorityEnum.NO}
        onChange={({ target }) => {
          updateArea((recordProxy) => {
            recordProxy.setValue(
              target.value as DualAuthorityEnum,
              "dualAuthority"
            );
          });
        }}
      >
        <Select.Option value={DualAuthorityEnum.NO}>No</Select.Option>
        <Select.Option value={DualAuthorityEnum.ARM}>Arm</Select.Option>
        <Select.Option value={DualAuthorityEnum.DISARM}>Disarm</Select.Option>
        <Select.Option value={DualAuthorityEnum.ALL}>All</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default AreaDualAuthorityField;
