/**
 * @generated SignedSource<<ac07186609cc2435e124169b903a03cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OutputSupervision = "FOUR_HOURS" | "NONE" | "ONE_HOUR" | "THREE_MINUTES";
import { FragmentRefs } from "relay-runtime";
export type XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly outputInformations: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputSupervision: {
      readonly data: OutputSupervision | null;
      readonly included: boolean | null;
    } | null;
    readonly realTimeStatus: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly tripWithPanelBell: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "afb741e73f92037875c2e30b8efa136a";

export default node;
