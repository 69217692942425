/**
 * @ngdoc service
 * @name App.factory:CustomerLinkedProfilesV2API
 *
 * @description
 * API factory for checking for linked profiles on a customer.
 *
 */
App.factory("CustomerLinkedProfilesV2API", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/customers/:customer_id/:linked_profiles",
      {},
      {
        getLinkedProfiles: {
          method: "GET",
          params: {
            customer_id: "@customer_id",
            linked_profiles: "linked_profiles",
          },
          isArray: true,
        },
      }
    );
  },
]);
