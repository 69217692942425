import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsSupervisoryAlarmOutputField_outputOptions$key } from "./__generated__/OutputOptionsSupervisoryAlarmOutputField_outputOptions.graphql";

export const outputOptionsSupervisoryAlarmOutputFieldId = () =>
  "output-options-supervisory-alarm-output";

function OutputOptionsSupervisoryAlarmOutputField() {
  const [{ supervisoryAlarmOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsSupervisoryAlarmOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsSupervisoryAlarmOutputField_outputOptions on OutputOptions {
          id
          supervisoryAlarmOutput {
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsSupervisoryAlarmOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const originalValue = React.useRef(
    supervisoryAlarmOutput.formattedNumber
  ).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Supervisory Alarm Output"
      tooltip="This output turns on when a Supervisory Zone is placed in alarm. The output turns off when all Supervisory Zones are restored to normal."
    >
      <TextInput
        id={fieldId}
        value={supervisoryAlarmOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) => {
          onChange(outputOptionsId, target.value, "supervisoryAlarmOutput");
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "supervisoryAlarmOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsSupervisoryAlarmOutputField;
