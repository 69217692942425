/**
 * @generated SignedSource<<e1604e9b8b92ed74cdb45f96dd7fe39a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationNetTestDaysField_communication$data = {
  readonly comType: CommunicationComType;
  readonly networkTestDays: number;
  readonly networkTestDaysMax: number;
  readonly networkTestDaysMin: number;
  readonly " $fragmentType": "CommunicationNetTestDaysField_communication";
};
export type CommunicationNetTestDaysField_communication$key = {
  readonly " $data"?: CommunicationNetTestDaysField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationNetTestDaysField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationNetTestDaysField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkTestDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkTestDaysMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkTestDaysMax",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "905ed272994314ed81e9c88cbc212968";

export default node;
