/**
 * @generated SignedSource<<649aa713a2eaf14698900d99ad7af1c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly menuDisplay: {
    readonly armDisarmDisplayKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedStatusDisplayKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly timeDisplayKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRMenuDisplayTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "4ac6753d7151fd636f0cc1a98a4eca45";

export default node;
