App.directive("daFormGroup", function ($compile, $parse) {
  return {
    restrict: "E",
    scope: {
      tabForm: "=?", //object
      idName: "=", //string (must be encapsulated in quotes)
      labelText: "=", //string (must be encapsulated in quotes)
      tooltipText: "=", //string (must be encapsulated in quotes)
      errorMsg: "=", //string (must be encapsulated in quotes)
      labelCol: "=", //string (must be encapsulated in quotes)
      inputCol: "=", //string (must be encapsulated in quotes)
      minValue: "=",
      maxValue: "=",
    },
    require: "^form",
    replace: true,
    transclude: true,
    templateUrl: "app/common/templates/company-store-secura-form-group.html",
    link: function postLink(scope, element, attrs, formCtrl) {
      scope.tabForm = formCtrl;
    },
    controller: function ($scope, $attrs) {
      if ($scope.tabForm) {
        $scope.tabForm = $attrs.tabForm;
      }
      $scope.dynamicInputCol = $scope.inputCol ? $scope.inputCol : "col-sm-9";
      $scope.dynamicLabelCol = $scope.labelCol ? $scope.labelCol : "col-sm-3";
    },
  };
});
