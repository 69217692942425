/**
 * @ngdoc service
 * @name App.factory:PersonnelService
 *
 * @description
 * Holds the API, methods, and model for the Personnel Information
 *
 */
App.factory("dealerUser", [
  "$rootScope",
  "UserService",
  "PROPS",
  "$timeout",
  "$filter",
  "$q",
  "DealerUserAPI",
  "baseUser",
  function (
    $rootScope,
    UserService,
    PROPS,
    $timeout,
    $filter,
    $q,
    DealerUserAPI,
    baseUser
  ) {
    var dealerUser = function (dealerJsonObj) {
      angular.extend(this, dealerJsonObj, {
        new: function () {
          var deferred = $q.defer();
          var _this = this;

          DealerUserAPI.new(
            { dealer_id: UserService.dealerInfo.id },

            function (data) {
              _this.extendMe(data.user);
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        create: function () {
          var deferred = $q.defer();
          var _this = this;

          DealerUserAPI.create(
            { dealer_id: UserService.dealerInfo.id }, //params

            this.toJsonForCreate(),

            function (data) {
              _this.extendMe(data.user);
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        extendMe: function (userData) {
          angular.extend(this, userData);
        },
      });

      this.extendMe(new baseUser());
      if (dealerUser !== null && angular.isDefined(dealerUser)) {
        this.extendMe(dealerUser);
      }
    };
    return dealerUser;
  },
]);
