/**
 Third Party Video Service
 */

App.factory("TechTrackingService", [
  "$rootScope",
  "TechTrackingAPI",
  "UserService",
  "$q",
  "$http",
  function ($rootScope, TechTrackingAPI, UserService, $q, $http) {
    function snapPointsToRoads(points) {
      let pathValues = [];
      for (let i = 0; i < 4; i++) {
        pathValues.push(`${points[i].Latitude},${points[i].Longitude}`);
      }

      let param = pathValues.join("|");

      $http({
        method: "GET",
        url: `https://roads.googleapis.com/v1/snapToRoads?path=${param}&interpolate=true&key=AIzaSyC0tHgIytRCTOJq1TK7bJZsukfW6iPNDx8`,
      }).then(
        function (success) {},
        function (error) {}
      );
    }

    return {
      sendTechLocations: function (body) {
        let deferred = $q.defer();

        TechTrackingAPI.sendTechLocation(
          { dealer_id: UserService.dealer_id },
          body,
          function (success) {
            deferred.resolve(success);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      getLiveTechLocations: function (dealerId) {
        let deferred = $q.defer();

        TechTrackingAPI.getLiveTechLocations(
          { dealer_id: dealerId },
          function (technicians) {
            let modifiedData = [];
            for (let tech of technicians) {
              let singleTechInfo = {};
              angular.merge(singleTechInfo, tech.TechLocation);
              singleTechInfo["UserImageUrl"] = tech.UserImageUrl;
              modifiedData.push(singleTechInfo);
            }

            deferred.resolve(modifiedData);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      getSingleTechLocations: function (userId, timeStamp, dealerId) {
        let deferred = $q.defer();

        TechTrackingAPI.getSingleTechLocations(
          { user_id: userId, Date: timeStamp, dealer_id: dealerId },
          function (locations) {
            deferred.resolve(locations);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      getSingleTechStops: function (userId, timeStamp) {
        let deferred = $q.defer();

        TechTrackingAPI.getSingleTechStops(
          { user_id: userId, Date: timeStamp },
          function (locations) {
            deferred.resolve(locations);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      getSingleTechHistoryLocations: function (userId, dateString, dealerId) {
        let _this = this;
        let deferred = $q.defer();

        TechTrackingAPI.getSingleTechHistoryLocations(
          { date: dateString, user_id: userId, dealer_id: dealerId },
          function (locations) {
            // snapPointsToRoads(locations.TechLocations);
            deferred.resolve(locations);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },

      getSingleTechHistoryStops: function (userId, dateString) {
        let deferred = $q.defer();

        TechTrackingAPI.getSingleTechHistoryStops(
          { date: dateString, user_id: userId },
          function (stops) {
            deferred.resolve(stops);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },
    };
  },
]);
