/**
 *
 * LockLockedIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const LockLockedIcon = (props: Parameters<typeof SvgIcon>[0]) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M38.702,26H38V8.405C38,3.613,34.729,0,30.35,0H20.226C15.912,0,13,3.378,13,8.405V26h-0.634C7.753,26,4,29.944,4,34.578
      v21.018C4,60.23,7.753,64,12.366,64h26.336C43.313,64,47,60.23,47,55.596V34.578C47,29.944,43.313,26,38.702,26z M17,8.405
      C17,7.081,17.326,4,20.226,4H30.35C32.88,4,34,6.284,34,8.405V26H17V8.405z M43,55.596C43,58.024,41.108,60,38.702,60H12.366
      C9.958,60,8,58.024,8,55.596V34.578C8,32.149,9.958,30,12.366,30h26.336C41.108,30,43,32.149,43,34.578V55.596z"
        />
        <path
          d="M25.887,36.711c-2.865,0-5.131,2.322-5.131,5.188c0,2.156,1.244,4.003,3.244,4.785v5.371C24,53.143,24.73,54,25.817,54
      h0.062c1.087,0,2.12-0.857,2.12-1.945v-5.371c2-0.782,3.112-2.629,3.112-4.785C31.112,39.033,28.752,36.711,25.887,36.711z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default LockLockedIcon;
