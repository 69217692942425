App.factory("ZWaveAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/zwave_setups/:device_id",
      {},
      {
        updateZwave: {
          method: "PATCH",
          params: { panel_id: "@panel_id", device_id: "@device_id" },
        },
        indexZwave: {
          method: "GET",
          params: { panel_id: "@panel_id", page: "@page" },
          isArray: true,
        },
        refreshZwave: {
          method: "POST",
          params: { panel_id: "@panel_id", device_id: "refresh" },
        },
      }
    );
  },
]);
