/**
 * @generated SignedSource<<c3b71e3129b0b9b9ee9ca49e13b1154d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts$data = {
  readonly bellOptions: {
    readonly automaticBellTest: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary1BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly auxiliary2BellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly bellCutoffTime: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly bellOutput: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly burglaryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly carbonMonoxideBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly emergencyBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly fireBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly panicBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
    readonly supervisoryBellAction: {
      readonly data: BellActions | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts";
};
export type XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRBellOptionsTemplateDataInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "d316ce03ca857ab11ac93b6ab7725164";

export default node;
