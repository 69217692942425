/**
 * @ngdoc service
 * @name App.factory:CustomerReferralsService
 *
 * @description
 * Customer Referral Program Service
 *
 */
App.factory("CustomerReferralsService", [
  "CustomerReferralTplAPI",
  "$q",
  "$filter",
  "UserService",
  "$http",
  "PROPS",
  function (CustomerReferralTplAPI, $q, $filter, UserService, $http, PROPS) {
    return {
      getExistingTpl: function (dealerId) {
        var deferred = $q.defer();
        CustomerReferralTplAPI.getExistingTpl(
          {
            dealer_id: dealerId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      createReferralTpl: function (body) {
        var deferred = $q.defer();
        CustomerReferralTplAPI.createReferralTpl(
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      changeReferralTpl: function (templateId, body) {
        var deferred = $q.defer();
        CustomerReferralTplAPI.changeReferralTpl(
          {
            id: templateId,
          },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getCustomerReferral: function (dealerId) {
        var deferred = $q.defer();
        CustomerReferralTplAPI.getCustomerReferral(
          {
            dealer_id: dealerId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      deleteCustomerReferral: function (templateId, body) {
        var deferred = $q.defer();
        CustomerReferralTplAPI.deleteCustomerReferral(
          {
            id: templateId,
          },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      changeCustomerReferral: function (referralId, body) {
        var deferred = $q.defer();
        CustomerReferralTplAPI.changeCustomerReferral(
          {
            id: referralId,
          },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      uploadTplPhoto: function (file) {
        var deferred = $q.defer();
        var fd = new FormData();

        fd.append("file", file);
        $http
          .post(
            PROPS.imageApiUrl +
              "/api/v1/ReferralProgramImages/Dealers/" +
              UserService.dealer_id,
            fd,
            {
              transformRequest: angular.identity,
              headers: { "Content-Type": undefined },
            }
          )
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      deleteTplPhoto: function () {
        var deferred = $q.defer();
        var fd = new FormData();

        $http
          .delete(
            PROPS.imageApiUrl +
              "/api/v1/ReferralProgramImages/Dealers/" +
              UserService.dealer_id,
            fd,
            {
              transformRequest: angular.identity,
              headers: { "Content-Type": undefined },
            }
          )
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      getCustomerReferralsCount: function (dealerId) {
        var deferred = $q.defer();
        CustomerReferralTplAPI.getCustomerReferralsCount(
          {
            dealer_id: dealerId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      },
      getBase64Img: function (file) {
        let url = URL.createObjectURL(file);

        let deferred = $q.defer();

        function convertImgToDataURLviaCanvas(url, callback) {
          var img = new Image();
          img.crossOrigin = "Anonymous";
          img.onload = function () {
            var canvas = document.createElement("CANVAS");
            var ctx = canvas.getContext("2d");
            var dataURL;
            canvas.height = this.height;
            canvas.width = this.width;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL("image/jpeg", 0.2);
            callback(dataURL);
            canvas = null;
          };
          img.src = url;
        }

        convertImgToDataURLviaCanvas(url, function (base64Img) {
          deferred.resolve(base64Img);
        });

        return deferred.promise;
      },
    };
  },
]);
