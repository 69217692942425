import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useState } from "react";
import { useMutation } from "react-relay/hooks";
import { idAsString, toKeypadId } from "securecom-graphql/client";
import styled from "styled-components";
import { KeypadUpdateButtonMutation } from "./__generated__/KeypadUpdateButtonMutation.graphql";

export type KeypadType = {
  recordNumber: number;
  controlSystemId: number;
  remoteUpdateTimestamp?: string | null;
  updateAvailable?: boolean | null;
};

export const KeypadUpdateButton = ({ keypad }: { keypad: KeypadType }) => {
  const keypadUpdateButtonMutation = graphql`
    mutation KeypadUpdateButtonMutation($keypadId: ID!) {
      keypadUpdate(keypadId: $keypadId) {
        ... on KeypadProgramming {
          status
          uuid
          details {
            code
            message
          }
        }
      }
    }
  `;

  const [updateKeypad, keypadUpdating] =
    useMutation<KeypadUpdateButtonMutation>(keypadUpdateButtonMutation);

  const isUpdateAvailable = !!keypad.updateAvailable;

  const now = new Date();

  const thirtyMinutesAgoUTC =
    now.getTime() + now.getTimezoneOffset() * 1000 * 60 - 1000 * 60 * 30;

  const [updateOngoing, setUpdateOngoing] = useState(
    isNotNullOrUndefined(keypad.remoteUpdateTimestamp) &&
      new Date(keypad.remoteUpdateTimestamp).getTime() > thirtyMinutesAgoUTC
  );

  const showAlert = useShowAlert();

  const handleUpdateClick = (keypad: KeypadType) => {
    if (keypad && isUpdateAvailable) {
      updateKeypad({
        variables: {
          keypadId: idAsString(
            toKeypadId(keypad.recordNumber, keypad.controlSystemId)
          ),
        },
        onCompleted: (response) => {
          if (response.keypadUpdate) {
            if (response.keypadUpdate.status === "success") {
              showAlert({
                type: "success",
                text: "Keypad update request successful. This could take several minutes to complete",
              });
              setUpdateOngoing(true);
            } else if (response.keypadUpdate.status === "error") {
              const errorMessage =
                response.keypadUpdate.details?.message ===
                "Command not executed."
                  ? "Keypad update failed."
                  : response.keypadUpdate.details?.message ===
                    "Connection timed Out"
                  ? "Keypad update failed. Connection Timed Out."
                  : response.keypadUpdate.details?.message ===
                    "Panel busy. Try again later."
                  ? "Keypad update failed. Panel is busy. Please try again later."
                  : "Keypad update failed. " +
                    response.keypadUpdate.details?.message;

              showAlert({
                type: "error",
                text: errorMessage,
              });
            }
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: "Keypad update failed.",
          });
        },
      });
    }
  };

  return (
    <UpdateButtonContainer
      title={
        keypadUpdating
          ? "Requesting firmware update..."
          : updateOngoing
          ? "Keypad update request was successful. This could take several minutes to complete."
          : "Update keypad firmware"
      }
    >
      <button
        key="keypad_update"
        className="btn btn-dmp pad-t-0 pad-b-0"
        disabled={!isUpdateAvailable || keypadUpdating || updateOngoing}
        onClick={() => handleUpdateClick(keypad)}
      >
        {isUpdateAvailable
          ? keypadUpdating
            ? "Requesting Update..."
            : updateOngoing
            ? "Update Requested"
            : "Update"
          : "Up to Date"}
      </button>
    </UpdateButtonContainer>
  );
};

const UpdateButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 31rem;
`;
