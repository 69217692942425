/**
 * @generated SignedSource<<8272b1e843e1c9a82b76789179210e83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReceiverTableUninitializedReceiver_dealer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewReceiverFormModalDealer_dealer">;
  readonly " $fragmentType": "ReceiverTableUninitializedReceiver_dealer";
};
export type ReceiverTableUninitializedReceiver_dealer$key = {
  readonly " $data"?: ReceiverTableUninitializedReceiver_dealer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReceiverTableUninitializedReceiver_dealer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReceiverTableUninitializedReceiver_dealer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewReceiverFormModalDealer_dealer"
    }
  ],
  "type": "Dealer",
  "abstractKey": null
};

(node as any).hash = "93893e961f759b2a5df656692aba7b17";

export default node;
