App.factory("FavoritesAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:panel_id/favorites/:refresh",
      {},
      {
        index: {
          method: "GET",
          params: { panel_id: "@panel_id" },
          isArray: true,
        },
        refresh: {
          method: "POST",
          params: { panel_id: "@panel_id", refresh: "refresh" },
        },
      }
    );
  },
]);
