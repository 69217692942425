/**
 * @generated SignedSource<<1135339907d5e8290d219f3daa2de8c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedJobGroupRefreshQuery$variables = {
  groupId: number;
  panelId: number;
};
export type SelectedJobGroupRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SelectedJobGroup_query">;
};
export type SelectedJobGroupRefreshQuery = {
  response: SelectedJobGroupRefreshQuery$data;
  variables: SelectedJobGroupRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "panelId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectedJobGroupRefreshQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SelectedJobGroup_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectedJobGroupRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "groupId",
            "variableName": "groupId"
          },
          {
            "kind": "Variable",
            "name": "panelId",
            "variableName": "panelId"
          }
        ],
        "concreteType": "SystemDiagnosticsPanelJob",
        "kind": "LinkedField",
        "name": "systemDiagnosticsJobListFromGroup",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedulerJobType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5927cea576ffa7dc0f4fa9fbff087cf2",
    "id": null,
    "metadata": {},
    "name": "SelectedJobGroupRefreshQuery",
    "operationKind": "query",
    "text": "query SelectedJobGroupRefreshQuery(\n  $groupId: Int!\n  $panelId: Int!\n) {\n  ...SelectedJobGroup_query\n}\n\nfragment SelectedJobGroup_query on Query {\n  systemDiagnosticsJobListFromGroup(panelId: $panelId, groupId: $groupId) {\n    jobId\n    jobDesc\n    jobStatus\n    jobMessage\n    createdAt\n    updatedAt\n    completedAt\n    schedulerJobType\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a8a5097f363f134572ddab361ff1b9d";

export default node;
