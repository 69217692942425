/**
 * @generated SignedSource<<da60b6845a9282b9a69784870c5b29a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsIntegratorVideoReportsField_remoteOptions$data = {
  readonly integratorConnection?: RemoteOptionsConnectionType | null;
  readonly integratorVideoReports?: boolean | null;
  readonly " $fragmentType": "RemoteOptionsIntegratorVideoReportsField_remoteOptions";
};
export type RemoteOptionsIntegratorVideoReportsField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsIntegratorVideoReportsField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsIntegratorVideoReportsField_remoteOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsIntegratorVideoReportsField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integratorVideoReports",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integratorConnection",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};

(node as any).hash = "e5fcc2f6359f522e23d4561eae17fa32";

export default node;
