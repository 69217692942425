/**
 * @ngdoc directive
 * @name App.directive:stringToNumber
 *
 * @description
 *   Converts a string input field into a number
 *
 */
App.directive("daStringToNumber", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, elem, attrs, ctrl) {
      ctrl.$formatters.push(function (value) {
        return value ? parseInt(value) : null;
      });
    },
  };
});
