import noop from "common/utils/universal/noop";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

const ActiveConceptContext = createContext<
  [string, Dispatch<SetStateAction<string>>]
>(["", noop]);

export default ActiveConceptContext;

export const useActiveConcept = () => useContext(ActiveConceptContext)[0];

export const useSetActiveConcept = () => useContext(ActiveConceptContext)[1];

export const useConceptIsActive = (conceptId: string) =>
  useActiveConcept() === conceptId;
