/**
 * @generated SignedSource<<3d794170fd8f088eb55d6152bd974926>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type PanelFirmwareStatus = "UPDATES_AVAILABLE" | "UPDATES_FAILED" | "UPDATES_IN_PROGRESS" | "UPDATES_SUCCESSFUL" | "UP_TO_DATE";
import { FragmentRefs } from "relay-runtime";
export type SiteFirmwareUpdateDialogue_UpdateProgress_site$data = {
  readonly controlSystems: ReadonlyArray<{
    readonly firmwareStatus: PanelFirmwareStatus;
    readonly firmwareUpdateProgress: number | null;
  }>;
  readonly id: string;
  readonly " $fragmentType": "SiteFirmwareUpdateDialogue_UpdateProgress_site";
};
export type SiteFirmwareUpdateDialogue_UpdateProgress_site$key = {
  readonly " $data"?: SiteFirmwareUpdateDialogue_UpdateProgress_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteFirmwareUpdateDialogue_UpdateProgress_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SiteFirmwareUpdateDialogue_UpdateProgressQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "SiteFirmwareUpdateDialogue_UpdateProgress_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteControlSystem",
      "kind": "LinkedField",
      "name": "controlSystems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firmwareUpdateProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firmwareStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "80f08089f2ff26972d8cd84f0862e791";

export default node;
