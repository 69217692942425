import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { SystemType } from "securecom-graphql/client";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import {
  useHouseCode,
  useSystemType,
} from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsArmedOutputField_outputOptions$key } from "./__generated__/OutputOptionsArmedOutputField_outputOptions.graphql";

export const outputOptionsArmedOutputFieldId = () =>
  `output-options-armed-output`;

function OutputOptionsArmedOutputField() {
  const [{ armedOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsArmedOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsArmedOutputField_outputOptions on OutputOptions {
          id
          armedOutput {
            id
            number
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsArmedOutputFieldId();
  const softwareVersion = useSoftwareVersion();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const systemType = useSystemType();
  const { isTakeoverPanel, isTMSentry } = resolvePanelType(hardwareModel);

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const disabled =
    Number(softwareVersion) >= 171 &&
    systemType !== SystemType.AREA &&
    !(isTakeoverPanel || isTMSentry);

  const originalValue = React.useRef(armedOutput.formattedNumber).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Armed Output"
      tooltip="This output will be turned on when any Area is Armed. It turns off when all Areas are Disarmed.  See Programming Guide for information on Favorites."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={armedOutput.formattedNumber}
        disabled={disabled}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "armedOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "armedOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsArmedOutputField;
