import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsSend16CharacterNamesField_systemOptions$key } from "./__generated__/SystemOptionsSend16CharacterNamesField_systemOptions.graphql";

export const systemOptionsSend16CharacterNamesFieldId = () =>
  "system-options-send-16-character-names";

function SystemOptionsSend16CharacterNamesField() {
  const [{ send16CharacterNames }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsSend16CharacterNamesField_systemOptions$key>(
      graphql`
        fragment SystemOptionsSend16CharacterNamesField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            send16CharacterNames
          }
        }
      `
    );

  const fieldId = systemOptionsSend16CharacterNamesFieldId();
  const label = "Send 16 Character Names";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to send only the first 16 characters of names to the receiver. Otherwise, full names will be sent."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={send16CharacterNames}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!send16CharacterNames, "send16CharacterNames");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemOptionsSend16CharacterNamesField;
