import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { pcLogReportsArmDisarmReportsEnabledFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsArmDisarmReportsEnabledField";
import { pcLogReportsDoorAccessReportsEnabledFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsDoorAccessReportsEnabledField";
import { pcLogReportsLogRealTimeStatusReportsEnabledFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsLogRealTimeStatusReportsEnabledField";
import { pcLogReportsNetworkIpAddressFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsNetworkIpAddressField";
import { pcLogReportsNetworkPortFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsNetworkPortField";
import { pcLogReportsSupervisoryReportsEnabledFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsSupervisoryReportsEnabledField";
import { pcLogReportsUserCommandReportsEnabledFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsUserCommandReportsEnabledField";
import { pcLogReportsZonesReportsEnabledFieldId } from "components/FullProgramming/common/PcLogReportsFields/PcLogReportsZonesReportsEnabledField";
import { XRPcLogReportsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/__generated__/XRPcLogReportsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getPcLogReportsState } from "../../../XRFullProgramming/XRPcLogReportsProgrammingConceptForm";
import { XrProgrammingTemplatePcLogReportsInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        pcLogReports {
          id
          included
          networkIpAddress {
            data
            included
          }
          networkPort {
            data
            included
          }
          armDisarmReportsEnabled {
            data
            included
          }
          zonesReportsEnabled {
            data
            included
          }
          userCommandReportsEnabled {
            data
            included
          }
          doorAccessReportsEnabled {
            data
            included
          }
          supervisoryReportsEnabled {
            data
            included
          }
          logRealTimeStatusEnabled {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRPcLogReportsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplatePcLogReportsInput => {
  const {
    panel: { pcLogReports },
  } = getPcLogReportsState(controlSystem);

  if (!pcLogReports) {
    return { included: false };
  }

  const pcLogReportsInputFields = {
    networkIpAddress: {
      data: pcLogReports.networkIpAddress,
      included: includedFields.has(pcLogReportsNetworkIpAddressFieldId()),
    },
    networkPort: {
      data: pcLogReports.networkPort,
      included: includedFields.has(pcLogReportsNetworkPortFieldId()),
    },
    armDisarmReportsEnabled: {
      data: pcLogReports.armDisarmReportsEnabled,
      included: includedFields.has(
        pcLogReportsArmDisarmReportsEnabledFieldId()
      ),
    },
    zonesReportsEnabled: {
      data: pcLogReports.zonesReportsEnabled,
      included: includedFields.has(pcLogReportsZonesReportsEnabledFieldId()),
    },
    userCommandReportsEnabled: {
      data: pcLogReports.userCommandReportsEnabled,
      included: includedFields.has(
        pcLogReportsUserCommandReportsEnabledFieldId()
      ),
    },
    doorAccessReportsEnabled: {
      data: pcLogReports.doorAccessReportsEnabled,
      included: includedFields.has(
        pcLogReportsDoorAccessReportsEnabledFieldId()
      ),
    },
    supervisoryReportsEnabled: {
      data: pcLogReports.supervisoryReportsEnabled,
      included: includedFields.has(
        pcLogReportsSupervisoryReportsEnabledFieldId()
      ),
    },
    logRealTimeStatusEnabled: {
      data: pcLogReports.logRealTimeStatusEnabled,
      included: includedFields.has(
        pcLogReportsLogRealTimeStatusReportsEnabledFieldId()
      ),
    },
  };

  const pcLogReportsIncluded = Object.values(pcLogReportsInputFields).some(
    ({ included }) => included
  );

  return {
    included: pcLogReportsIncluded,
    ...pcLogReportsInputFields,
  };
};

export const setIncludedFields = (
  template: XRPcLogReportsTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { pcLogReports } = getState(template);

  if (pcLogReports?.networkIpAddress?.included) {
    setIncludedFields(setToggle(pcLogReportsNetworkIpAddressFieldId()));
  }
  if (pcLogReports?.networkPort?.included) {
    setIncludedFields(setToggle(pcLogReportsNetworkPortFieldId()));
  }
  if (pcLogReports?.armDisarmReportsEnabled?.included) {
    setIncludedFields(setToggle(pcLogReportsArmDisarmReportsEnabledFieldId()));
  }
  if (pcLogReports?.zonesReportsEnabled?.included) {
    setIncludedFields(setToggle(pcLogReportsZonesReportsEnabledFieldId()));
  }
  if (pcLogReports?.userCommandReportsEnabled?.included) {
    setIncludedFields(
      setToggle(pcLogReportsUserCommandReportsEnabledFieldId())
    );
  }
  if (pcLogReports?.doorAccessReportsEnabled?.included) {
    setIncludedFields(setToggle(pcLogReportsDoorAccessReportsEnabledFieldId()));
  }
  if (pcLogReports?.supervisoryReportsEnabled?.included) {
    setIncludedFields(
      setToggle(pcLogReportsSupervisoryReportsEnabledFieldId())
    );
  }
  if (pcLogReports?.logRealTimeStatusEnabled?.included) {
    setIncludedFields(
      setToggle(pcLogReportsLogRealTimeStatusReportsEnabledFieldId())
    );
  }
};
