import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay$key } from "./__generated__/StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay.graphql";

export const statusListDisplayBurglaryZoneKeypadsFieldId = () =>
  "status-list-display-burglary-zone-keypads";

function StatusListDisplayBurglaryZoneKeypadsField() {
  const [{ burglaryZoneKeypads }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplayBurglaryZoneKeypadsField_statusListDisplay on StatusListDisplay {
          burglaryZoneKeypads
        }
      `
    );

  const fieldId = statusListDisplayBurglaryZoneKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Burglary Zone Keypads"
      tooltip="Configure which keypad addresses show Burglary Zone Alarms and Troubles."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={burglaryZoneKeypads}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "burglaryZoneKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "burglaryZoneKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplayBurglaryZoneKeypadsField;
