import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsNetworkIpAddressField_pcLogReports$key } from "./__generated__/PcLogReportsNetworkIpAddressField_pcLogReports.graphql";

export const pcLogReportsNetworkIpAddressFieldId = () =>
  "pc-log-reports-network-ip-address";

function PcLogReportsNetworkIpAddressField() {
  const [{ networkIpAddress }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsNetworkIpAddressField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsNetworkIpAddressField_pcLogReports on PcLogReports {
          networkIpAddress
        }
      `
    );

  const fieldId = pcLogReportsNetworkIpAddressFieldId();
  const label = "Network IP Address";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enter the IP address containing up to 16 characters."
    >
      <TextInput
        id={fieldId}
        value={networkIpAddress?.toString()}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        inlineHelp="Ex. 0.0.0.0"
        onChange={({ target }) => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(target.value, "networkIpAddress");
          });
        }}
        onBlur={({ target }) => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "networkIpAddress"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsNetworkIpAddressField;
