import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { deviceInformationActivateOnboardSpeakerFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateOnboardSpeakerField";
import { deviceInformationActivateZone2BypassFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone2BypassField";
import { deviceInformationActivateZone3RequestToExitFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone3RequestToExitField";
import { deviceInformationCardOptionsFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationCardOptionsField";
import { deviceInformationNoCommWithPanelFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationNoCommWithPanelField";
import { deviceInformationRelockOnZone2ChangeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRelockOnZone2ChangeField";
import { deviceInformationRequireSiteCodeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRequireSiteCodeField";
import { deviceInformationUserCodeDigitsFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeDigitsField";
import { deviceInformationZone2BypassTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone2BypassTimeField";
import { deviceInformationZone3RequestToExitTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone3RequestToExitTimeField";
import { deviceInformation1100TFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TField";
import { deviceInformation1100TWirelessFrequencyFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TWirelessFrequencyField";
import { deviceInformationAutoForceArmFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationAutoForceArmField";
import { deviceInformationDeviceCommunicationTypeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceCommunicationTypeField";
import { deviceInformationDeviceTypeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceTypeField";
import { deviceInformationDisplayAreasFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDisplayAreasField";
import { deviceInformationDoorRealTimeStatusFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDoorRealTimeStatusField";
import { deviceInformationFireExitReleaseFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationFireExitReleaseField";
import { deviceInformationNameFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNameField";
import { deviceInformationProgram734OptionsFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationProgram734OptionsField";
import { deviceInformationPublicDoorFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPublicDoorField";
import { deviceInformationScheduleOverrideFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationScheduleOverrideField";
import { deviceInformationSendDoorForcedMessageFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSendDoorForcedMessageField";
import { deviceInformationStrikeDelayFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeDelayField";
import { deviceInformationStrikeTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeTimeField";
import { deviceInformationSupervisionTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSupervisionTimeField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import {
  xt75DeviceInformationNumberFieldId,
  xt75DeviceListItemTemplateId,
} from "components/FullProgramming/XT75FullProgramming/XT75DeviceSetupProgrammingConceptForm/XT75DeviceInformationNumberField";
import { XT75DeviceSetupProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/XT75DeviceSetupProgrammingConceptForm/__generated__/XT75DeviceSetupProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  getDeviceState,
  getState as getDevicesState,
} from "../../../XT75FullProgramming/XT75DeviceSetupProgrammingConceptForm";
import { Xt75ProgrammingTemplateDeviceInformationInput } from "../Create/__generated__/XT75TemplateSaveMutation.graphql";
import { XT75DeviceInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75DeviceInformationTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  device: XT75DeviceInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75DeviceInformationTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        deviceInformations {
          id
          included
          number
          axNumber {
            data
            included
          }
          lxNumber {
            data
            included
          }
          name {
            data
            included
          }
          supervisionTime {
            data
            included
          }
          wirelessTranslator1100tFrequency {
            data
            included
          }
          deviceType {
            data
            included
          }
          deviceCommunicationMethod {
            data
            included
          }
          displayAreas {
            data
            included
          }
          strikeTime {
            data
            included
          }
          strikeDelay {
            data
            included
          }
          fireExit {
            data
            included
          }
          publicDoor {
            data
            included
          }
          overrideScheduleWhenArmed {
            data
            included
          }
          doorRealTimeStatusMessages {
            data
            included
          }
          doorForceMessages {
            data
            included
          }
          remoteProgram734 {
            data
            included
          }
          device734 {
            data {
              number
              zone2BypassOnRequestToExit {
                data
                included
              }
              zone2BypassTime {
                data
                included
              }
              zone2RelockOnStateChange {
                data
                included
              }
              enableZone3RequestToExit {
                data
                included
              }
              zone3RequestToExitTime {
                data
                included
              }
              enableOnboardSpeaker {
                data
                included
              }
              cardFormatOptions {
                data
                included
              }
              enforceSiteCode {
                data
                included
              }
              numberOfUserCodeDigits {
                data
                included
              }
              noCommWithPanelRelayAction {
                data
                included
              }
            }
            included
          }
          wirelessTranslator1100t {
            data
            included
          }
        }
      }
    `,
    device
  );

export const getInput = (
  controlSystem: XT75DeviceSetupProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): Xt75ProgrammingTemplateDeviceInformationInput[] => {
  const {
    panel: { deviceInformations },
  } = getDevicesState(controlSystem);

  return deviceInformations
    ? deviceInformations
        .filter(isNotNullOrUndefined)
        .map(getDeviceState)
        .map((device) => ({
          included: includedFields.has(
            xt75DeviceListItemTemplateId(String(device.number))
          ),
          number: Number(device?.number ?? 0),
          name: {
            data: device.name,
            included: includedFields.has(
              deviceInformationNameFieldId(String(device.number))
            ),
          },
          supervisionTime: {
            data: device.supervisionTime,
            included: includedFields.has(
              deviceInformationSupervisionTimeFieldId(String(device.number))
            ),
          },
          wirelessTranslator1100tFrequency: {
            data: device.wirelessTranslator1100tFrequency,
            included: includedFields.has(
              deviceInformation1100TWirelessFrequencyFieldId(
                String(device.number)
              )
            ),
          },
          deviceType: {
            data: device.deviceType,
            included: includedFields.has(
              deviceInformationDeviceTypeFieldId(String(device.number))
            ),
          },
          deviceCommunicationMethod: {
            data: device.deviceCommunicationMethod,
            included: includedFields.has(
              deviceInformationDeviceCommunicationTypeFieldId(
                String(device.number)
              )
            ),
          },
          displayAreas: {
            data: device.displayAreas,
            included: includedFields.has(
              deviceInformationDisplayAreasFieldId(String(device.number))
            ),
          },
          strikeTime: {
            data: device.strikeTime,
            included: includedFields.has(
              deviceInformationStrikeTimeFieldId(String(device.number))
            ),
          },
          strikeDelay: {
            data: device.strikeDelay,
            included: includedFields.has(
              deviceInformationStrikeDelayFieldId(String(device.number))
            ),
          },
          fireExit: {
            data: device.fireExit,
            included: includedFields.has(
              deviceInformationFireExitReleaseFieldId(String(device.number))
            ),
          },
          publicDoor: {
            data: device.publicDoor,
            included: includedFields.has(
              deviceInformationPublicDoorFieldId(String(device.number))
            ),
          },
          overrideScheduleWhenArmed: {
            data: device.overrideScheduleWhenArmed,
            included: includedFields.has(
              deviceInformationScheduleOverrideFieldId(String(device.number))
            ),
          },
          doorRealTimeStatusMessages: {
            data: device.doorRealTimeStatusMessages,
            included: includedFields.has(
              deviceInformationDoorRealTimeStatusFieldId(String(device.number))
            ),
          },
          doorForceMessages: {
            data: device.doorForceMessages,
            included: includedFields.has(
              deviceInformationSendDoorForcedMessageFieldId(
                String(device.number)
              )
            ),
          },
          remoteProgram734: {
            data: device.remoteProgram734,
            included: includedFields.has(
              deviceInformationProgram734OptionsFieldId(String(device.number))
            ),
          },
          device734: {
            included:
              includedFields.has(
                deviceInformationProgram734OptionsFieldId(String(device.number))
              ) &&
              includedFields.has(
                xt75DeviceListItemTemplateId(String(device.number))
              ),
            data: {
              number: Number(device.device734?.number ?? 0),
              zone2BypassOnRequestToExit: {
                data: device.device734?.zone2BypassOnRequestToExit,
                included: includedFields.has(
                  deviceInformationActivateZone2BypassFieldId(
                    String(device.number)
                  )
                ),
              },
              zone2BypassTime: {
                data: device.device734?.zone2BypassTime,
                included: includedFields.has(
                  deviceInformationZone2BypassTimeFieldId(String(device.number))
                ),
              },
              zone2RelockOnStateChange: {
                data: device.device734?.zone2RelockOnStateChange,
                included: includedFields.has(
                  deviceInformationRelockOnZone2ChangeFieldId(
                    String(device.number)
                  )
                ),
              },
              enableZone3RequestToExit: {
                data: device.device734?.enableZone3RequestToExit,
                included: includedFields.has(
                  deviceInformationActivateZone3RequestToExitFieldId(
                    String(device.number)
                  )
                ),
              },
              zone3RequestToExitTime: {
                data: device.device734?.zone3RequestToExitTime,
                included: includedFields.has(
                  deviceInformationZone3RequestToExitTimeFieldId(
                    String(device.number)
                  )
                ),
              },
              enableOnboardSpeaker: {
                data: device.device734?.enableOnboardSpeaker,
                included: includedFields.has(
                  deviceInformationActivateOnboardSpeakerFieldId(
                    String(device.number)
                  )
                ),
              },
              cardFormatOptions: {
                data: device.device734?.cardFormatOptions,
                included: includedFields.has(
                  deviceInformationCardOptionsFieldId(String(device.number))
                ),
              },
              enforceSiteCode: {
                data: device.device734?.enforceSiteCode,
                included: includedFields.has(
                  deviceInformationRequireSiteCodeFieldId(String(device.number))
                ),
              },
              numberOfUserCodeDigits: {
                data: device.device734?.numberOfUserCodeDigits,
                included: includedFields.has(
                  deviceInformationUserCodeDigitsFieldId(String(device.number))
                ),
              },
              noCommWithPanelRelayAction: {
                data: device.device734?.noCommWithPanelRelayAction,
                included: includedFields.has(
                  deviceInformationNoCommWithPanelFieldId(String(device.number))
                ),
              },
            },
          },
          wirelessTranslator1100t: {
            data: device.wirelessTranslator1100t,
            included: includedFields.has(
              deviceInformation1100TFieldId(String(device.number))
            ),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XT75DeviceInformationTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { deviceInformations } = getState(template);

  if (!deviceInformations) {
    return;
  }

  deviceInformations.filter(isNotNullOrUndefined).forEach((device) => {
    const number = String(String(device.number));

    if (device.included) {
      setIncludedFields(setToggle(xt75DeviceListItemTemplateId(number)));
      setIncludedFields(setToggle(xt75DeviceInformationNumberFieldId(number)));
    }
    if (device.name?.included) {
      setIncludedFields(setToggle(deviceInformationNameFieldId(number)));
    }
    if (device.supervisionTime?.included) {
      setIncludedFields(
        setToggle(deviceInformationSupervisionTimeFieldId(number))
      );
    }
    if (device.wirelessTranslator1100tFrequency?.included) {
      setIncludedFields(
        setToggle(deviceInformation1100TWirelessFrequencyFieldId(number))
      );
    }
    if (device.deviceType?.included) {
      setIncludedFields(setToggle(deviceInformationDeviceTypeFieldId(number)));
    }
    if (device.deviceCommunicationMethod?.included) {
      setIncludedFields(
        setToggle(deviceInformationDeviceCommunicationTypeFieldId(number))
      );
    }
    if (device.displayAreas?.included) {
      setIncludedFields(
        setToggle(deviceInformationDisplayAreasFieldId(number))
      );
    }
    if (device.strikeTime?.included) {
      setIncludedFields(setToggle(deviceInformationStrikeTimeFieldId(number)));
    }
    if (device.strikeDelay?.included) {
      setIncludedFields(setToggle(deviceInformationStrikeDelayFieldId(number)));
    }
    if (device.fireExit?.included) {
      setIncludedFields(
        setToggle(deviceInformationFireExitReleaseFieldId(number))
      );
    }
    if (device.publicDoor?.included) {
      setIncludedFields(setToggle(deviceInformationPublicDoorFieldId(number)));
    }
    if (device.overrideScheduleWhenArmed?.included) {
      setIncludedFields(
        setToggle(deviceInformationScheduleOverrideFieldId(number))
      );
    }
    if (device.doorRealTimeStatusMessages?.included) {
      setIncludedFields(
        setToggle(deviceInformationDoorRealTimeStatusFieldId(number))
      );
    }
    if (device.doorForceMessages?.included) {
      setIncludedFields(
        setToggle(deviceInformationSendDoorForcedMessageFieldId(number))
      );
    }
    if (device.remoteProgram734?.included) {
      setIncludedFields(
        setToggle(deviceInformationProgram734OptionsFieldId(number))
      );
    }
    if (device.wirelessTranslator1100t?.included) {
      setIncludedFields(setToggle(deviceInformation1100TFieldId(number)));
    }
    if (device.device734?.data?.zone2BypassOnRequestToExit?.included) {
      setIncludedFields(
        setToggle(deviceInformationActivateZone2BypassFieldId(number))
      );
    }
    if (device.device734?.data?.zone2BypassTime?.included) {
      setIncludedFields(
        setToggle(deviceInformationZone2BypassTimeFieldId(number))
      );
    }
    if (device.device734?.data?.zone2RelockOnStateChange?.included) {
      setIncludedFields(
        setToggle(deviceInformationRelockOnZone2ChangeFieldId(number))
      );
    }
    if (device.device734?.data?.enableZone3RequestToExit?.included) {
      setIncludedFields(
        setToggle(deviceInformationActivateZone3RequestToExitFieldId(number))
      );
    }
    if (device.device734?.data?.zone3RequestToExitTime?.included) {
      setIncludedFields(
        setToggle(deviceInformationZone3RequestToExitTimeFieldId(number))
      );
    }
    if (device.device734?.data?.enableOnboardSpeaker?.included) {
      setIncludedFields(
        setToggle(deviceInformationActivateOnboardSpeakerFieldId(number))
      );
    }
    if (device.device734?.data?.cardFormatOptions?.included) {
      setIncludedFields(setToggle(deviceInformationCardOptionsFieldId(number)));
    }
    if (device.device734?.data?.enforceSiteCode?.included) {
      setIncludedFields(
        setToggle(deviceInformationRequireSiteCodeFieldId(number))
      );
    }
    if (device.device734?.data?.numberOfUserCodeDigits?.included) {
      setIncludedFields(
        setToggle(deviceInformationUserCodeDigitsFieldId(number))
      );
    }
    if (device.device734?.data?.noCommWithPanelRelayAction?.included) {
      setIncludedFields(
        setToggle(deviceInformationNoCommWithPanelFieldId(number))
      );
    }
  });
};
