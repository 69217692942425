/**
 * @generated SignedSource<<f5c5d2805f314ca368182633bac11b0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly areas: {
      readonly __typename: "AreaConnection";
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "AreaEdge";
        readonly cursor: string | null;
        readonly node: {
          readonly __typename: string;
          readonly id: string;
          readonly isNew: boolean;
          readonly number: string;
          readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptFormInline_area">;
        } | null;
      }>;
      readonly totalCount: number;
    };
    readonly id: string;
    readonly systemAreaInformation: {
      readonly __typename: "SystemAreaInformation";
      readonly anyBypass: boolean;
      readonly areaSchedules: boolean;
      readonly burglaryBellOutputNumber: string;
      readonly closingCheck: boolean;
      readonly closingCode: boolean;
      readonly exitDelay: number;
      readonly morningAmbush: number;
      readonly openClosingReports: boolean;
      readonly supportsOpenClosingReports: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptFormInline_systemAreaInformation">;
    } | null;
  };
  readonly supportsBurglaryBellOutput: boolean;
  readonly supportsCardPlusPin: boolean;
  readonly supportsDualAuthority: boolean;
  readonly supportsOpenClosingReports: boolean;
  readonly supportsTwoManRule: boolean;
  readonly " $fragmentType": "XRAreaInformationProgrammingConceptFormInline_controlSystem";
};
export type XRAreaInformationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRAreaInformationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRAreaInformationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "e5fd111d9764ec702c8962509a12854a";

export default node;
