import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "components/FullProgramming/common/ChangedProgrammingConceptsContext";
import { deviceListItemTemplateId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNumberField";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "components/FullProgramming/common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "components/FullProgramming/common/LastUpdatedContext";
import { useOriginalControlSystem } from "components/FullProgramming/common/OriginalControlSystemContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { removeListItemFromStore } from "components/FullProgramming/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  ControlSystem,
  DeviceInformation,
  DeviceInformation1100TWirelessFrequencyType,
  DeviceInformationWirelessSupervisionTime,
  fromControlSystemId,
  fromDeviceInformationsId,
  idAsString,
  Panel,
  toDeviceInformationsId,
  toGlobalId,
  XtDeviceInformationsType,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../../common/ControlSystemContext";
import XTDeviceInformationsFields from "./XTDeviceInformationsFields";
import { XTDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation } from "./__generated__/XTDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XTDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation,
} from "./__generated__/XTDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation.graphql";
import {
  XTDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation,
  XTDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
} from "./__generated__/XTDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation.graphql";
import {
  XTDeviceSetupProgrammingConceptFormInline_controlSystem$data,
  XTDeviceSetupProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XTDeviceSetupProgrammingConceptFormInline_controlSystem.graphql";
import {
  XTDeviceSetupProgrammingConceptFormInline_deviceInformation$data,
  XTDeviceSetupProgrammingConceptFormInline_deviceInformation$key,
} from "./__generated__/XTDeviceSetupProgrammingConceptFormInline_deviceInformation.graphql";
import { XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XTDeviceSetupProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XTDeviceSetupProgrammingConceptFormNavButton_controlSystem.graphql";
import { XTDeviceSetupProgrammingConceptForm_controlSystem$key } from "./__generated__/XTDeviceSetupProgrammingConceptForm_controlSystem.graphql";

export const title = "Device Setup";
export const conceptId = "xt-device-setup";

export const getState = (
  controlSystem: XTDeviceSetupProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XTDeviceSetupProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          totalDevicesMax
          keypadBusDevicesMax
          axBusDoorDevicesMax
          deviceNumberRange
          deviceInformations {
            id
            number
            isNew
            ...XTDeviceSetupProgrammingConceptFormInline_deviceInformation
          }
        }
      }
    `,
    controlSystem
  );

export const getDeviceState = (
  device: XTDeviceSetupProgrammingConceptFormInline_deviceInformation$key
) =>
  readInlineData(
    graphql`
      fragment XTDeviceSetupProgrammingConceptFormInline_deviceInformation on DeviceInformation
      @inline {
        id
        number
        name
        isNew
        ... on XtDeviceInformation {
          __typename
          axNumber
          lxNumber
          serialNumber
          supervisionTime
          wirelessTranslator1100tFrequency
          wireless
          door
          network
          wirelessTranslator1100t
        }
      }
    `,
    device
  );

const deleteMutation = graphql`
  mutation XTDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation(
    $deviceId: ID!
  ) {
    deleteDeviceInformation(deviceId: $deviceId) {
      ... on DeleteDeviceInformationSuccessPayload {
        __typename
        deletedDeviceId
      }
      ... on FailedToRemoveDeviceErrorPayload {
        error: type
      }
    }
  }
`;

const retrieveMutation = graphql`
  mutation XTDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation(
    $id: ID!
  ) {
    refreshDeviceInformations(id: $id) {
      ... on RefreshDeviceInformationsSuccessPayload {
        __typename
        controlSystem {
          ...XTDeviceSetupProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XTDeviceSetupProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XTDeviceSetupProgrammingConceptFormDeviceInformationsRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        retrieve({
          variables: {
            id: systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshDeviceInformations;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Device Setup Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshDeviceInformations: {
                    __typename: response.refreshDeviceInformations.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Device Setup: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Device Setup",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const saveMutation = graphql`
  mutation XTDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation(
    $systemId: ID!
    $deviceInformations: [XtDeviceInput!]!
  ) {
    sendXtDeviceProgramming(
      systemId: $systemId
      deviceInformations: $deviceInformations
    ) {
      ... on Error {
        type
      }
      ... on SendDeviceProgrammingSuccessPayload {
        results {
          __typename
          ... on SendDeviceProgrammingDeviceSuccessPayload {
            deviceInformation {
              __typename
              id
              number
              ...XTDeviceSetupProgrammingConceptFormInline_deviceInformation
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;

const mergeOldAndNewDevices = (
  response: XTDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
  originalControlSystemData: XTDeviceSetupProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendXtDeviceProgramming.results) {
    const successfulDevices = response.sendXtDeviceProgramming.results
      .map((device) => {
        if (device.__typename === "SendDeviceProgrammingDeviceSuccessPayload") {
          return device;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.deviceInformation)
      .map(getDeviceState);

    const mergedDevicesMap = new Map<
      string,
      XTDeviceSetupProgrammingConceptFormInline_deviceInformation$data
    >();

    originalControlSystemData.panel.deviceInformations
      .map(getDeviceState)
      .forEach((item) => mergedDevicesMap.set(item.id, item));

    successfulDevices.forEach((item) =>
      mergedDevicesMap.set(item.id, {
        ...mergedDevicesMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedDevicesMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XTDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation$data,
  originalControlSystemData: XTDeviceSetupProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedDevices = mergeOldAndNewDevices(
    response,
    originalControlSystemData
  );

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshDeviceInformations: {
        __typename: "RefreshDeviceInformationsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            deviceInformations: mergedDevices,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XTDeviceSetupProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XTDeviceSetupProgrammingConceptFormDeviceInformationsSendMutation>(
      saveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedDevices = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { deviceInformations },
        } = getState(props.controlSystem);
        save({
          variables: {
            systemId,
            deviceInformations: deviceInformations
              .filter(
                (device) =>
                  device.isNew ||
                  (!!changedDevices &&
                    listItemHasChanged(device.id, changedDevices)) ||
                  isSavingAllListItems
              )
              .map(getDeviceState)
              .map((device) => ({
                id: device.id,
                number: device.number,
                axNumber: device.axNumber ?? "",
                lxNumber: device.lxNumber ?? "",
                name: device.name,
                serialNumber: !device.wireless ? "" : device.serialNumber ?? "",
                supervisionTime: device.supervisionTime,
                wirelessTranslator1100tFrequency:
                  device.wirelessTranslator1100tFrequency ??
                  DeviceInformation1100TWirelessFrequencyType.NONE,
                wireless: device.wireless ?? false,
                door: device.door ?? false,
                network: device.network ?? false,
                wirelessTranslator1100t:
                  device.wirelessTranslator1100t ?? false,
                isNew: device.isNew,
              })),
          },
          onCompleted: (response) => {
            const sendErrors: SaveErrors = [];
            if (response.sendXtDeviceProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendXtDeviceProgramming.results) {
              response.sendXtDeviceProgramming.results.forEach((response) => {
                if (
                  response.__typename ===
                  "SendDeviceProgrammingDeviceSuccessPayload"
                ) {
                  resetLastUpdated(response.deviceInformation.id);
                } else if (
                  response.__typename === "SendListItemsErrorPayload"
                ) {
                  sendErrors.push({
                    programmingConcept: title,
                    errors: response.errors,
                    listItemNumber: response.number,
                  });
                }
              });

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!sendErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
            }
            resolve(sendErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSaving,
  ];
};

const readDeviceInformationsTemplateData = (
  programmingTemplateConcepts: XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        deviceInformations {
          included
          number
          axNumber {
            included
            data
          }
          lxNumber {
            included
            data
          }
          name {
            included
            data
          }
          supervisionTime {
            included
            data
          }
          wirelessTranslator1100tFrequency {
            included
            data
          }
          wireless {
            included
            data
          }
          door {
            included
            data
          }
          network {
            included
            data
          }
          wirelessTranslator1100t {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).deviceInformations;

export function applyTemplateData(
  programmingTemplateConcepts: XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const deviceRecordProxies =
    panelRecordProxy.getLinkedRecords("deviceInformations") ?? [];
  const devicesByNumber = indexRecordProxiesByNumber(deviceRecordProxies);

  const devicesTemplateData =
    readDeviceInformationsTemplateData(programmingTemplateConcepts) ?? [];
  devicesTemplateData.forEach((deviceTemplateData) => {
    if (deviceTemplateData?.included) {
      let deviceRecordProxy = devicesByNumber.get(deviceTemplateData.number);
      if (!deviceRecordProxy) {
        const newDeviceId = applyNewDeviceToDeviceInformationsList(
          {
            id: panelRecordProxy.getValue("id"),
            deviceNumberRange: panelRecordProxy.getValue("deviceNumberRange"),
            newDevice: panelRecordProxy.getLinkedRecord("newDevice") && {
              id: panelRecordProxy.getLinkedRecord("newDevice").getValue("id"),
            },
            deviceInformations: deviceRecordProxies.map((recordProxy) => ({
              number: recordProxy.getValue("number"),
            })),
          },
          store
        );
        if (newDeviceId) {
          deviceRecordProxy = store.get(
            newDeviceId
          ) as RecordProxy<DeviceInformation>;
          if (deviceRecordProxy) {
            deviceRecordProxy.setValue(deviceTemplateData.number, "number");
          }
        }
      }

      if (!deviceRecordProxy) {
        return;
      }

      const serialNumber = deviceRecordProxy.getValue("serialNumber");

      if (
        deviceTemplateData.wireless?.included &&
        deviceTemplateData.wireless?.data &&
        !(serialNumber?.toString() ?? "").match("14[0-9]{6}")
      ) {
        deviceRecordProxy.setValue("14", "serialNumber");
      }

      if (
        deviceTemplateData.wirelessTranslator1100t?.included &&
        deviceTemplateData.wirelessTranslator1100t?.data &&
        !(serialNumber?.toString() ?? "").match("13[0-9]{6}")
      ) {
        deviceRecordProxy.setValue("13", "serialNumber");
        deviceRecordProxy.setValue(true, "wireless");
      }

      applyTemplateScalarDataToRecordProxy(
        deviceRecordProxy,
        deviceTemplateData
      );

      if (!devicesByNumber.has(deviceTemplateData.number)) {
        devicesByNumber.set(deviceTemplateData.number, deviceRecordProxy);
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(devicesByNumber),
    "deviceInformations"
  );
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XTDeviceSetupProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XTDeviceSetupProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            deviceInformations {
              isNew
            }
          }
        }
      `
    );
  const { deviceInformations } = controlSystem.panel;
  const itemsCount = deviceInformations.length;
  const hasNewItems =
    itemsCount > 0 && deviceInformations.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XTDeviceSetupProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XTDeviceSetupProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedDeviceInformation {
            id
          }
          panel {
            id
            totalDevicesMax
            keypadBusDevicesMax
            axBusDoorDevicesMax
            deviceNumberRange
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHas1100T_panel
            ...DeviceInformationNumberField_panel
            ...PanelContextUseRemoteZoneList_panel
            deviceInformations {
              id
              number
              name
              isNew
              ...XTDeviceInformationsFields_deviceInformation
              ... on XtDeviceInformation {
                wirelessTranslator1100t
              }
            }
            newDevice {
              id
              number
              name
              isNew
              ...XTDeviceInformationsFields_deviceInformation
              ... on XtDeviceInformation {
                wirelessTranslator1100t
              }
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...PanelContextUseDeviceNumberRange_panel
            ...PanelContextUseSupports1100T_panel
            ...PanelContextUseSupportsXR550_panel
            ...DeviceInformationSerialNumberField_deviceSerialNumberList_panel
          }
        }
      `
    );
  const relayEnv = useRelayEnvironment();

  const { deviceInformations, newDevice } = controlSystem.panel;

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    deviceInformations[0]?.id ?? null
  );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const [deleteDeviceInformation, isDeleting] =
    useMutation<XTDeviceSetupProgrammingConceptFormDeviceInformationDeleteMutation>(
      deleteMutation
    );

  const showAlert = useShowAlert();

  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedDevice = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.deviceInformations.findIndex(
          (deviceInformation) => deviceInformation.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.deviceInformations.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return (
          controlSystem.panel.deviceInformations[newSelectedIndex]?.id ?? null
        );
      });
      const device = deviceInformations.find(
        (device) => device.id === selectedListItemId
      );
      if (device?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "deviceInformations",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            XtDeviceInformationsType,
            fromControlSystemId(asID(controlSystem.id)).systemId,
            device?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteDeviceInformation({
          variables: {
            deviceId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "deviceInformations",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedDeviceId } = response.deleteDeviceInformation;
            if (deletedDeviceId) {
              showAlert({
                type: "success",
                text: "Device Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "deviceInformations",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedDeviceId, error } = response.deleteDeviceInformation;
            if (deletedDeviceId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedDeviceId) {
                    removeListItemFromStore(
                      selectedListItemId,
                      "deviceInformations",
                      controlSystem.panel.id,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Device: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Device",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Device",
            });
          },
        });
      }
    }
  };

  const availableNumbers = getAvailableNumbers(controlSystem.panel);
  const canAdd = availableNumbers.size > 0 && !!newDevice;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <ProgrammingConceptForm
        conceptId={conceptId}
        helpLink={`${programmingGuideUrl}#Device%20Setup`}
        title={title}
        deleting={isDeleting}
        isArrayConcept
        initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
          controlSystem.panel.deviceInformations
        )}
        amountAvailable={availableNumbers.size}
        addButton={
          <ProgrammingConceptForm.AddButton
            onClick={() => {
              relayEnv.commitUpdate((store) => {
                const newDeviceId = applyNewDeviceToDeviceInformationsList(
                  controlSystem.panel,
                  store
                );
                if (newDeviceId) {
                  setSelectedListItemId(newDeviceId);
                }
              });
            }}
          >
            Add Device
          </ProgrammingConceptForm.AddButton>
        }
      >
        {(conceptId === activeConcept || isApplying || isSavingAll) && (
          <ProgrammingConceptForm.ListItemsContainer>
            <ProgrammingConceptForm.ListItemPicker
              selectedId={selectedListItemId}
              onChange={(id) => {
                setSelectedListItemId(id);
              }}
              newItemId={newDevice?.id}
              items={deviceInformations.map((device) => ({
                id: device.id,
                templateListItemId: deviceListItemTemplateId(
                  String(device.number)
                ),
                isnew: device.isNew,
                label: `#${device.number} ${device.name}`,
              }))}
            />
            <ProgrammingConceptForm.SelectedItemsContainer
              selectedListItemId={selectedListItemId}
              setSelectedListItemId={setSelectedListItemId}
            >
              {deviceInformations.map(
                (device) =>
                  (device.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                    programmingConcepts[conceptId].isSaving ||
                    isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                    isSavingAll) && (
                    <RemountOnUpdateContainer nodeId={conceptId}>
                      <ProgrammingConceptForm.SelectedItem
                        conceptId={conceptId}
                        isnew={device.isNew}
                        visible={device.id === selectedListItemId}
                        key={device.id}
                        listItemId={device.id}
                        templateListItemId={deviceListItemTemplateId(
                          String(device.number)
                        )}
                        title={`# ${device.number} ${device.name}`}
                        onDuplicate={
                          !device.wirelessTranslator1100t &&
                          canAdd &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const duplicateId =
                                applyDuplicatedDeviceInformationToDeviceInformationsList(
                                  selectedListItemId,
                                  controlSystem.panel,
                                  store
                                );
                              if (duplicateId) {
                                setSelectedListItemId(duplicateId);
                              }
                            });
                          })
                        }
                        onCopy={
                          !device.wirelessTranslator1100t &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const controlSystemRecord = store.get(
                                controlSystem.id
                              );
                              const deviceInformationRecord =
                                store.get<DeviceInformation>(
                                  selectedListItemId
                                );
                              if (
                                controlSystemRecord &&
                                deviceInformationRecord
                              ) {
                                const tempRecord =
                                  store.get("copiedDeviceInformation") ??
                                  store.create(
                                    "copiedDeviceInformation",
                                    "DeviceInformation"
                                  );
                                tempRecord.copyFieldsFrom(
                                  deviceInformationRecord
                                );
                                controlSystemRecord.setLinkedRecord(
                                  tempRecord,
                                  "copiedDeviceInformation"
                                );
                              }
                            });
                          })
                        }
                        onPaste={
                          !!controlSystem.copiedDeviceInformation &&
                          (() => {
                            relayEnv.commitUpdate((store) => {
                              const deviceInformationRecord =
                                store.get<DeviceInformation>(
                                  selectedListItemId
                                );
                              const copiedDeviceInformationRecord =
                                store.get<DeviceInformation>(
                                  "copiedDeviceInformation"
                                );
                              if (
                                deviceInformationRecord &&
                                copiedDeviceInformationRecord
                              ) {
                                remove1100tFromDevice(
                                  copiedDeviceInformationRecord
                                );
                                applyDeviceInformationProgrammingToDeviceInformation(
                                  copiedDeviceInformationRecord,
                                  deviceInformationRecord
                                );
                              }
                            });
                          })
                        }
                        onRemove={removeSelectedDevice}
                      >
                        <XTDeviceInformationsFields
                          key={device.id}
                          deviceInformation={device}
                        />
                      </ProgrammingConceptForm.SelectedItem>
                    </RemountOnUpdateContainer>
                  )
              )}
            </ProgrammingConceptForm.SelectedItemsContainer>
          </ProgrammingConceptForm.ListItemsContainer>
        )}
      </ProgrammingConceptForm>
    </PanelContextProvider>
  );
}

const applyNewDeviceToDeviceInformationsList = (
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newDevice: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newDevice } = panel;
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newDeviceRecord = panelRecord.getLinkedRecord("newDevice");
    if (newDevice) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromDeviceInformationsId(
          asID(newDeviceRecord.getDataID())
        );
        const nextNewDeviceId = idAsString(
          toDeviceInformationsId("Xt", systemId, nextNumber)
        );
        const nextNewDevice = store.create(
          nextNewDeviceId,
          "XtDeviceInformation"
        ) as RecordProxy<DeviceInformation>;
        nextNewDevice.copyFieldsFrom(newDeviceRecord);
        nextNewDevice.setValue(nextNewDeviceId, "id");
        nextNewDevice.setValue(nextNumber, "number");
        nextNewDevice.setValue(true, "isNew");
        // Device 1 is always a hardwired keypad
        if (nextNumber === 1) {
          nextNewDevice.setValue(false, "wireless");
          nextNewDevice.setValue("", "serialNumber");
          nextNewDevice.setValue(
            DeviceInformationWirelessSupervisionTime.NONE,
            "wirelessSupervisionTime"
          );
          remove1100tFromDevice(nextNewDevice);
        }
        const deviceInformationRecords =
          panelRecord.getLinkedRecords("deviceInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...deviceInformationRecords, nextNewDevice],
          "deviceInformations"
        );
        return nextNewDevice.getValue("id");
      }
    }
  }
};

const applyDuplicatedDeviceInformationToDeviceInformationsList = (
  deviceInformationId: string,
  panel: Parameters<typeof getNextAvailableNumber>[0] & {
    readonly id: string;
    readonly newDevice: {
      readonly id: string;
    } | null;
  },
  store: RecordSourceProxy
) => {
  const { id, newDevice } = panel;
  const panelRecord = store.get<Panel>(id);
  const deviceInformationRecord =
    store.get<DeviceInformation>(deviceInformationId);
  if (panelRecord && deviceInformationRecord) {
    const newDeviceRecord = panelRecord.getLinkedRecord("newDevice");
    if (newDevice) {
      const nextNumber = getNextAvailableNumber(panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromDeviceInformationsId(
          asID(newDeviceRecord.getDataID())
        );
        const nextNewDeviceId = idAsString(
          toDeviceInformationsId("Xt", systemId, nextNumber)
        );
        const nextNewDevice = store.create(
          nextNewDeviceId,
          "XtDeviceInformation"
        ) as RecordProxy<DeviceInformation>;
        nextNewDevice.copyFieldsFrom(newDeviceRecord);
        nextNewDevice.setValue(nextNewDeviceId, "id");
        nextNewDevice.setValue(nextNumber, "number");

        const duplicatedDeviceInformationRecord =
          applyDeviceInformationProgrammingToDeviceInformation(
            deviceInformationRecord,
            nextNewDevice
          );

        remove1100tFromDevice(duplicatedDeviceInformationRecord);
        // Device 1 is always a hardwired keypad
        if (nextNumber === 1) {
          nextNewDevice.setValue(false, "wireless");
          nextNewDevice.setValue("", "serialNumber");
          nextNewDevice.setValue(
            DeviceInformationWirelessSupervisionTime.NONE,
            "wirelessSupervisionTime"
          );
        }

        const deviceInformationRecords =
          panelRecord.getLinkedRecords("deviceInformations") ?? [];
        panelRecord.setLinkedRecords(
          [...deviceInformationRecords, duplicatedDeviceInformationRecord],
          "deviceInformations"
        );

        return duplicatedDeviceInformationRecord.getValue("id");
      }
    }
  }
};

const applyDeviceInformationProgrammingToDeviceInformation = (
  source: RecordProxy<DeviceInformation>,
  dest: RecordProxy<DeviceInformation>
) => {
  const id = dest.getValue("id");
  const isNew = dest.getValue("isNew");
  const number = dest.getValue("number");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  // Device 1 is always a hardwired keypad
  if (number === 1) {
    dest.setValue(false, "wireless");
    dest.setValue("", "serialNumber");
    dest.setValue(
      DeviceInformationWirelessSupervisionTime.NONE,
      "wirelessSupervisionTime"
    );
  }
  return dest;
};

const getAvailableNumbers = (panel: {
  readonly deviceNumberRange: ReadonlyArray<number>;
  readonly deviceInformations: ReadonlyArray<{
    readonly number: number;
  }>;
}) => {
  const { deviceNumberRange } = panel;

  const allPossibleNumbers = new Set(deviceNumberRange);

  const takenNumbers = new Set(
    panel.deviceInformations.map(({ number }) => number)
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;
  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getAvailableNumbers>[0]
) => setFirst(getAvailableNumbers(panel));

const remove1100tFromDevice = (
  deviceInformationRecord: RecordProxy<DeviceInformation>
) => {
  if (deviceInformationRecord.getValue("wirelessTranslator1100t")) {
    deviceInformationRecord.setValue(false, "wirelessTranslator1100t");
  }
};
