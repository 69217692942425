/**
 * @generated SignedSource<<f6efd9a5a6e25ed113b66621290e0ba2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GlobalHolidayDatesErrors = "BAD_INPUT" | "BAD_PARAMS" | "BAD_REQUEST" | "CONFLICT" | "NO_CONTROL_SYSTEM" | "UNKNOWN";
export type HolidayDateClass = "A" | "B" | "C";
export type UpdateGlobalHolidayDateForMassPanelsInput = {
  clazz: HolidayDateClass;
  date: string;
  dealerId: number;
  description: string;
  name: string;
  panelAssociation: ReadonlyArray<number | null>;
  scapiId: number;
};
export type MassProgrammingGlobalHolidayDatesJobNewRootSendMutation$variables = {
  input: UpdateGlobalHolidayDateForMassPanelsInput;
};
export type MassProgrammingGlobalHolidayDatesJobNewRootSendMutation$data = {
  readonly updateGlobalHolidayDateForMassPanels: {
    readonly __typename: "UpdateGlobalHolidayDateForMassPanelsErrorResponse";
    readonly errorMessage: string;
    readonly type: GlobalHolidayDatesErrors;
  } | {
    readonly __typename: "UpdateGlobalHolidayDateForMassPanelsSuccessResponse";
    readonly dealer: {
      readonly globalHolidayDateJobs: ReadonlyArray<{
        readonly completedAt: string;
        readonly createdAt: string;
        readonly failedToUpdate: number;
        readonly groupStatus: string;
        readonly jobStatus: string;
        readonly personWhoStartedJob: {
          readonly email: string | null;
          readonly firstName: string | null;
          readonly lastName: string | null;
          readonly userId: number | null;
        } | null;
        readonly scapiId: number;
        readonly schedulerJobTypeId: number;
        readonly successfullyUpdated: number;
        readonly totalSystems: number;
        readonly updateInProgress: number;
        readonly waitingForUpdate: number;
      } | null>;
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type MassProgrammingGlobalHolidayDatesJobNewRootSendMutation = {
  response: MassProgrammingGlobalHolidayDatesJobNewRootSendMutation$data;
  variables: MassProgrammingGlobalHolidayDatesJobNewRootSendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PersonWhoStartedJob",
  "kind": "LinkedField",
  "name": "personWhoStartedJob",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scapiId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupStatus",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobStatus",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSystems",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "successfullyUpdated",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateInProgress",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "waitingForUpdate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "failedToUpdate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schedulerJobTypeId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errorMessage",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MassProgrammingGlobalHolidayDatesJobNewRootSendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateGlobalHolidayDateForMassPanels",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulerJob",
                    "kind": "LinkedField",
                    "name": "globalHolidayDateJobs",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateGlobalHolidayDateForMassPanelsSuccessResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "type": "UpdateGlobalHolidayDateForMassPanelsErrorResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MassProgrammingGlobalHolidayDatesJobNewRootSendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateGlobalHolidayDateForMassPanels",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dealer",
                "kind": "LinkedField",
                "name": "dealer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulerJob",
                    "kind": "LinkedField",
                    "name": "globalHolidayDateJobs",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateGlobalHolidayDateForMassPanelsSuccessResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "type": "UpdateGlobalHolidayDateForMassPanelsErrorResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "091cd66b044eabc49c20167f9e6e04ce",
    "id": null,
    "metadata": {},
    "name": "MassProgrammingGlobalHolidayDatesJobNewRootSendMutation",
    "operationKind": "mutation",
    "text": "mutation MassProgrammingGlobalHolidayDatesJobNewRootSendMutation(\n  $input: UpdateGlobalHolidayDateForMassPanelsInput!\n) {\n  updateGlobalHolidayDateForMassPanels(input: $input) {\n    __typename\n    ... on UpdateGlobalHolidayDateForMassPanelsSuccessResponse {\n      __typename\n      dealer {\n        globalHolidayDateJobs {\n          personWhoStartedJob {\n            userId\n            firstName\n            lastName\n            email\n          }\n          scapiId\n          createdAt\n          completedAt\n          groupStatus\n          jobStatus\n          totalSystems\n          successfullyUpdated\n          updateInProgress\n          waitingForUpdate\n          failedToUpdate\n          schedulerJobTypeId\n          id\n        }\n        id\n      }\n    }\n    ... on UpdateGlobalHolidayDateForMassPanelsErrorResponse {\n      __typename\n      type\n      errorMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6016ff04a080d07e22df47ae8098b463";

export default node;
