import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";

const OutputInformationContext = React.createContext<any>({});

export function OutputInformationContextProvider(props: {
  outputInformation: any;
  children: React.ReactNode;
}) {
  return (
    <OutputInformationContext.Provider value={props.outputInformation}>
      {props.children}
    </OutputInformationContext.Provider>
  );
}

export function useOutputInformationFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const outputInformation = React.useContext(OutputInformationContext);

  const data = useFragment(fragmentInput, outputInformation as TKey);

  const { id } = useFragment(
    graphql`
      fragment OutputInformationContext_output on Output {
        id
      }
    `,
    outputInformation as any
  );

  const update = (
    updater: (outputInformation: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}
