/**
 * @name App.service:ReplacePanelService
 *
 * @description Utility service for storing / manipulating data needed to replace a panel
 */
App.service("ReplacePanelService", function () {
  var _this = this;

  var cleanData = {
    panelId: null,
    action: "",
    newSerialNumber: "",
    initialConnectionCompleted: false,
    cancelled: false,
  };

  _this.replacementPanelData = {};

  /**
   * Returns true if panel is being replaced; replacement was initiated via the replace panel modal
   * @returns {boolean}
   */
  _this.replacingPanel = function () {
    return ["push", "pull"].includes(_this.replacementPanelData.action);
  };

  _this.setReplacementData = function (
    panelId,
    action,
    newSerialNumber,
    canSaveUserCodes
  ) {
    _this.replacementPanelData.panelId = panelId;
    _this.replacementPanelData.action = action;
    _this.replacementPanelData.newSerialNumber = newSerialNumber;
    _this.replacementPanelData.initialConnectionCompleted = false;
    _this.replacementPanelData.cancelled = false;
    if (canSaveUserCodes) {
      _this.canRestoreUserCodes = true;
    }
  };

  _this.cancelPanelReplacement = function () {
    _this.init();
    _this.replacementPanelData.cancelled = true;
  };

  _this.init = function () {
    _this.replacementPanelData = {};
    angular.extend(_this.replacementPanelData, cleanData);
  };

  _this.init();
});
