import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsBellCutoffTimeField_bellOptions$key } from "./__generated__/BellOptionsBellCutoffTimeField_bellOptions.graphql";

export const bellOptionsBellCutoffTimeFieldId = () =>
  "bell-options-bell-cutoff-time";

function BellOptionsBellCutoffTimeField() {
  const [
    { bellCutoffTime, bellCutoffTimeMin, bellCutoffTimeMax },
    updateBellOptions,
  ] = useBellOptionsFragment<BellOptionsBellCutoffTimeField_bellOptions$key>(
    graphql`
      fragment BellOptionsBellCutoffTimeField_bellOptions on BellOptions {
        bellCutoffTime
        bellCutoffTimeMin
        bellCutoffTimeMax
      }
    `
  );

  const fieldId = bellOptionsBellCutoffTimeFieldId();
  const { current: originalValue } = React.useRef(bellCutoffTime);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Bell Cutoff Time"
      tooltip="The maximum time the Bell Output remains on."
    >
      <NumericInput
        id={fieldId}
        min={bellCutoffTimeMin}
        max={bellCutoffTimeMax}
        value={bellCutoffTime}
        inlineHelp={`${bellCutoffTimeMin}-${bellCutoffTimeMax}`}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "bellCutoffTime"
            );
          });
        }}
        onBlur={({ target }) => {
          updateBellOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : valueAsNumber === 0
                ? valueAsNumber
                : clamp(bellCutoffTimeMin, bellCutoffTimeMax, valueAsNumber);
            recordProxy.setValue(value, "bellCutoffTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsBellCutoffTimeField;
