/**
 *
 * EditIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const EditIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M43.703,11.901c-0.375-0.376-0.885-0.588-1.417-0.588s-1.042,0.212-1.417,0.588L9.868,43.015
      c-0.14,0.142-0.258,0.302-0.352,0.478L0.231,61.065c-0.412,0.779-0.265,1.736,0.362,2.356C0.978,63.802,1.486,64,2,64
      c0.325,0,0.653-0.079,0.953-0.242l17.397-9.432c0.17-0.092,0.327-0.209,0.463-0.346l31.001-31.112
      c0.778-0.781,0.778-2.043,0.001-2.824L43.703,11.901z M18.187,50.949L6.854,57.094l6.059-11.468l29.373-29.479l5.29,5.309
      L18.187,50.949z"
        />
        <path
          d="M62.502,6.509l-5.311-5.33C56.434,0.419,55.43,0,54.363,0c-1.053,0-2.041,0.409-2.782,1.152l-5.214,5.232
      c-0.777,0.781-0.777,2.043,0,2.824l8.113,8.142c0.375,0.376,0.885,0.588,1.416,0.588s1.041-0.212,1.417-0.588l5.214-5.233
      C64.061,10.578,64.049,8.062,62.502,6.509z M55.896,13.104l-5.294-5.312l3.754-3.79l5.337,5.292L55.896,13.104z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default EditIcon;
