import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay$key } from "./__generated__/StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay.graphql";

export const statusListDisplaySystemTroubleStatusMonitorsFieldId = () =>
  "status-list-display-system-trouble-status-monitors";

function StatusListDisplaySystemTroubleStatusMonitorsField() {
  const [{ systemTroubleStatusMonitors }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplaySystemTroubleStatusMonitorsField_statusListDisplay on StatusListDisplay {
          systemTroubleStatusMonitors
        }
      `
    );

  const fieldId = statusListDisplaySystemTroubleStatusMonitorsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="System Trouble Status Monitors"
      tooltip="Configure which keypad addresses show System Monitor troubles in the Status List."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={systemTroubleStatusMonitors}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "systemTroubleStatusMonitors");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "systemTroubleStatusMonitors"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplaySystemTroubleStatusMonitorsField;
