import React from "react";

export default function TimezoneOffsetSelector(
  props: React.HTMLProps<HTMLSelectElement>
) {
  return (
    <select {...props}>
      <option value="" disabled>
        Select a Timezone
      </option>
      <option value="0">
        (GMT +0) London, Monrovia, Lisbon, Dublin, Casablanca, Edinburgh
      </option>
      <option value="1">(GMT -1) Cape Verde Island, Azores</option>
      <option value="2">(GMT -2) Mid-Atlantic, Fernando de Noronha</option>
      <option value="3">
        (GMT -3) Buenos Aires, Georgetown, Brasilia, Rio de Janeiro
      </option>
      <option value="4">
        (GMT -4) Atlantic Time (Canada), Caracas, La Paz, Santiago
      </option>
      <option value="5">
        (GMT -5) Eastern Time (US, Canada) Bogota, Lima, Arequipa
      </option>
      <option value="6">
        (GMT -6) Central Time (US, Canada), Mexico City, Saskatchewan
      </option>
      <option value="7">(GMT -7) Mountain Time (US, Canada), Edmonton</option>
      <option value="8">(GMT -8) Pacific Time (US, Canada), Tijuana</option>
      <option value="9">(GMT -9) Alaska</option>
      <option value="10">(GMT -10) Hawaii</option>
      <option value="11">(GMT -11) Midway Island, Samoa</option>
      <option value="12">
        (GMT +12) Fiji, Marshall Island, Wellington, Auckland, Kwajalein,
        Kamchatka
      </option>
      <option value="13">(GMT +11) New Cadelonia</option>
      <option value="14">(GMT +10) Guam, Sydney</option>
      <option value="15">(GMT +9) Tokyo, Seoul</option>
      <option value="16">(GMT +8) Hong Kong, Singapore</option>
      <option value="17">(GMT +7) Bangkok, Hanoi</option>
      <option value="18">(GMT +6) Dhaka, Almaty</option>
      <option value="19">(GMT +5) Islamabad, Karachi</option>
      <option value="20">(GMT +4) Abu Dhabi, Kazan</option>
      <option value="21">(GMT +3) Moscow, Bagdad</option>
      <option value="22">(GMT +2) Eastern Europe</option>
      <option value="23">(GMT +1) Rome, Paris, Berlin</option>
    </select>
  );
}
