/**
 * @generated SignedSource<<eb8083350c4bf67e0c746b2988c02d74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GroupInput = {
  areas?: ReadonlyArray<number | null> | null;
  doors?: ReadonlyArray<number | null> | null;
  name?: string | null;
  number: string;
  options?: OptionsInput | null;
  schedules?: ReadonlyArray<number | null> | null;
};
export type OptionsInput = {
  armAuthority?: boolean | null;
  auditDaysEnabled?: boolean | null;
  auditDaysValue?: string | null;
  disarmAuthority?: boolean | null;
  groupsProgramming?: boolean | null;
  lockdown?: boolean | null;
  schedules?: boolean | null;
  swipeTwiceToArm?: boolean | null;
  userProgramming?: boolean | null;
};
export type BaseGroupFormUpdateGroupMutation$variables = {
  group: GroupInput;
  systemId: string;
};
export type BaseGroupFormUpdateGroupMutation$data = {
  readonly updateGroup: {
    readonly __typename: "UpdateGroupErrorResponse";
    readonly message: string | null;
  } | {
    readonly __typename: "UpdateGroupSuccessResponse";
    readonly system: {
      readonly doorsConnection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string;
            readonly number: number;
          } | null;
        }>;
      } | null;
      readonly groupsConnection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly accessibleAreas: ReadonlyArray<number | null> | null;
            readonly areas: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
              readonly number: string;
            } | null> | null;
            readonly armableAreas: ReadonlyArray<number | null> | null;
            readonly doors: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
              readonly number: number;
            } | null> | null;
            readonly id: string;
            readonly name: string | null;
            readonly number: string | null;
            readonly options: {
              readonly armAuthority: boolean | null;
              readonly auditDaysEnabled: boolean | null;
              readonly auditDaysValue: string | null;
              readonly disarmAuthority: boolean | null;
              readonly groupsProgramming: boolean | null;
              readonly lockdown: boolean | null;
              readonly schedules: boolean | null;
              readonly swipeTwiceToArm: boolean | null;
              readonly userProgramming: boolean | null;
            } | null;
            readonly schedules: ReadonlyArray<{
              readonly id: string;
              readonly name: string | null;
              readonly number: string;
            } | null> | null;
          };
        }>;
      };
      readonly id: string;
      readonly schedules: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly number: string;
      }>;
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type BaseGroupFormUpdateGroupMutation = {
  response: BaseGroupFormUpdateGroupMutation$data;
  variables: BaseGroupFormUpdateGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "group"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "profile",
    "variableName": "group"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "trackedOnly",
      "value": true
    }
  ],
  "concreteType": "ControlSystemDoorsConnection",
  "kind": "LinkedField",
  "name": "doorsConnection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemDoorEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Door",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "doorsConnection(trackedOnly:true)"
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Schedule",
  "kind": "LinkedField",
  "name": "schedules",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessibleAreas",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "armableAreas",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Door",
  "kind": "LinkedField",
  "name": "doors",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Options",
  "kind": "LinkedField",
  "name": "options",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armAuthority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disarmAuthority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swipeTwiceToArm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schedules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userProgramming",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupsProgramming",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auditDaysEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auditDaysValue",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UpdateGroupErrorResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseGroupFormUpdateGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupsConnection",
                    "kind": "LinkedField",
                    "name": "groupsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GroupEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Group",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v6/*: any*/),
                              (v5/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v9/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "areas",
                                "plural": true,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateGroupSuccessResponse",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BaseGroupFormUpdateGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ControlSystem",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupsConnection",
                    "kind": "LinkedField",
                    "name": "groupsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GroupEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Group",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v6/*: any*/),
                              (v5/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v9/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "areas",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "UpdateGroupSuccessResponse",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af5439ffd216fb1b3e73fb5ae5185336",
    "id": null,
    "metadata": {},
    "name": "BaseGroupFormUpdateGroupMutation",
    "operationKind": "mutation",
    "text": "mutation BaseGroupFormUpdateGroupMutation(\n  $systemId: ID!\n  $group: GroupInput!\n) {\n  updateGroup(systemId: $systemId, profile: $group) {\n    __typename\n    ... on UpdateGroupSuccessResponse {\n      system {\n        id\n        doorsConnection(trackedOnly: true) {\n          edges {\n            node {\n              id\n              name\n              number\n            }\n          }\n        }\n        schedules {\n          id\n          name\n          number\n        }\n        groupsConnection {\n          edges {\n            node {\n              id\n              number\n              name\n              accessibleAreas\n              armableAreas\n              schedules {\n                id\n                name\n                number\n              }\n              doors {\n                id\n                name\n                number\n              }\n              options {\n                armAuthority\n                disarmAuthority\n                swipeTwiceToArm\n                lockdown\n                schedules\n                userProgramming\n                groupsProgramming\n                auditDaysEnabled\n                auditDaysValue\n              }\n              areas {\n                __typename\n                id\n                name\n                number\n              }\n            }\n          }\n        }\n      }\n    }\n    ... on UpdateGroupErrorResponse {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0d61ff9d9a9a8190d0c60abebe46fe8";

export default node;
