/**
 * @generated SignedSource<<8eeacebce6625373981eaf806bd06874>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XTZoneInformationsProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly totalWirelessZoneMax: number;
    readonly totalZonesMax: number;
    readonly zoneInformations: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: string;
      readonly " $fragmentSpreads": FragmentRefs<"XTZoneInformationsProgrammingConceptFormInline_zone">;
    }>;
    readonly zoneNumberRange: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "XTZoneInformationsProgrammingConceptFormInline_controlSystem";
};
export type XTZoneInformationsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTZoneInformationsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTZoneInformationsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTZoneInformationsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "a14f84151d9e6e02e08dc13bd5c76598";

export default node;
