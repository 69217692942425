/**
 * @generated SignedSource<<010ebebdae2ed64be6b3e034faca0409>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NetworkOptionsXrX1PassphraseField_networkOptions$data = {
  readonly ddpPassphrase: string;
  readonly enableDdp: boolean;
  readonly " $fragmentType": "NetworkOptionsXrX1PassphraseField_networkOptions";
};
export type NetworkOptionsXrX1PassphraseField_networkOptions$key = {
  readonly " $data"?: NetworkOptionsXrX1PassphraseField_networkOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkOptionsXrX1PassphraseField_networkOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOptionsXrX1PassphraseField_networkOptions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ddpPassphrase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableDdp",
      "storageKey": null
    }
  ],
  "type": "NetworkOptions",
  "abstractKey": null
};

(node as any).hash = "f827f80a0a925386b15c961bedb4fb54";

export default node;
