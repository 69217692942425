import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathSubstitutionCodeField_communicationPath$key } from "./__generated__/CommPathSubstitutionCodeField_communicationPath.graphql";

export const commPathSubstitutionCodeFieldId = (number: string) =>
  `comm-path-substitution-code-${number}`;

function CommPathSubstitutionCodeField() {
  const [{ number, commType, substitutionCode }, updateCommPath] =
    useCommPathFragment<CommPathSubstitutionCodeField_communicationPath$key>(
      graphql`
        fragment CommPathSubstitutionCodeField_communicationPath on CommunicationPath {
          number
          commType
          substitutionCode
        }
      `
    );

  const fieldId = commPathSubstitutionCodeFieldId(String(number));
  const disabled = !["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Substitution Code"
      tooltip="Include a substitution code with messages on this path unless the message is from an area with a different account number. Select Shared to share a code with other paths."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        value={substitutionCode}
        required
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "substitutionCode");
          });
        }}
      >
        <Select.Option value="YES">Yes</Select.Option>
        <Select.Option value="NO">No</Select.Option>
        <Select.Option value="SHARED">Shared</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathSubstitutionCodeField;
