import SystemOptionsArmDisarmActivityDaysField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsArmDisarmActivityDaysField";
import SystemOptionsClosingCheckField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCheckField";
import SystemOptionsClosingCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsClosingCodeField";
import SystemOptionsCrossZoneTimeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsCrossZoneTimeField";
import SystemOptionsDetectWirelessJammingField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDetectWirelessJammingField";
import SystemOptionsDisplayTimeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsDisplayTimeField";
import SystemOptionsEnableKeypadPanicKeysField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEnableKeypadPanicKeysField";
import SystemOptionsEntryDelay1Field from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay1Field";
import SystemOptionsEntryDelay2Field from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsEntryDelay2Field";
import SystemOptionsExitDelayField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsExitDelayField";
import SystemOptionsHoursFromGMTField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsHoursFromGMTField";
import SystemOptionsOccupiedPremisesField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsOccupiedPremisesField";
import SystemOptionsPowerFailDelayField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsPowerFailDelayField";
import SystemOptionsResetSwingerBypassField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsResetSwingerBypassField";
import SystemOptionsSwingerBypassTripsField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSwingerBypassTripsField";
import SystemOptionsSystemTypeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsSystemTypeField";
import SystemOptionsTimeChangeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsTimeChangeField";
import SystemOptionsUseFalseAlarmQuestionField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsUseFalseAlarmQuestionField";
import SystemOptionsWeatherZipCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWeatherZipCodeField";
import SystemOptionsWirelessAudiblesField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessAudiblesField";
import SystemOptionsWirelessHouseCodeField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsWirelessHouseCodeField";
import SystemOptionsZoneActivityHoursField from "components/FullProgramming/common/SystemOptionsFields/SystemOptionsZoneActivityHoursField";
import React from "react";

const XTLSystemOptions = () => {
  return (
    <>
      <SystemOptionsSystemTypeField />
      <SystemOptionsClosingCodeField />
      <SystemOptionsClosingCheckField />
      <SystemOptionsEntryDelay1Field />
      <SystemOptionsEntryDelay2Field />
      <SystemOptionsExitDelayField />
      <SystemOptionsCrossZoneTimeField />
      <SystemOptionsPowerFailDelayField />
      <SystemOptionsSwingerBypassTripsField />
      <SystemOptionsResetSwingerBypassField />
      <SystemOptionsZoneActivityHoursField />
      <SystemOptionsArmDisarmActivityDaysField />
      <SystemOptionsTimeChangeField />
      <SystemOptionsHoursFromGMTField />
      <SystemOptionsDisplayTimeField />
      <SystemOptionsWirelessHouseCodeField />
      <SystemOptionsDetectWirelessJammingField />
      <SystemOptionsWirelessAudiblesField />
      <SystemOptionsEnableKeypadPanicKeysField />
      <SystemOptionsOccupiedPremisesField />
      <SystemOptionsUseFalseAlarmQuestionField />
      <SystemOptionsWeatherZipCodeField />
    </>
  );
};

export default XTLSystemOptions;
