/**
 * @generated SignedSource<<04c74c594fd30ca9892badc11f5faae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VarStatusBadge_varHub$data = {
  readonly id: string;
  readonly isOnline: boolean;
  readonly " $fragmentType": "VarStatusBadge_varHub";
};
export type VarStatusBadge_varHub$key = {
  readonly " $data"?: VarStatusBadge_varHub$data;
  readonly " $fragmentSpreads": FragmentRefs<"VarStatusBadge_varHub">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./VarStatusBadge_varHubQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "VarStatusBadge_varHub",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "VarHub",
  "abstractKey": null
};

(node as any).hash = "e780a5cd0c410c377cfe891ae618bee0";

export default node;
