/**
 * @generated SignedSource<<ef5876f1b621a885e9c8d58db6f4a61a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseCommPaths_panel$data = {
  readonly communicationPaths: ReadonlyArray<{
    readonly xfCommType: CommunicationPathCommType;
    readonly xrCommType: CommunicationPathCommType;
  }>;
  readonly " $fragmentType": "PanelContextUseCommPaths_panel";
};
export type PanelContextUseCommPaths_panel$key = {
  readonly " $data"?: PanelContextUseCommPaths_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseCommPaths_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseCommPaths_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CommunicationPath",
      "kind": "LinkedField",
      "name": "communicationPaths",
      "plural": true,
      "selections": [
        {
          "alias": "xrCommType",
          "args": null,
          "kind": "ScalarField",
          "name": "commType",
          "storageKey": null
        },
        {
          "alias": "xfCommType",
          "args": null,
          "kind": "ScalarField",
          "name": "commType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "544f711cdb1887b460c3497778e5e7ea";

export default node;
