import {
  CameraStatus,
  StreamableCamera,
} from "../camect-api/streamable-camera";

interface RawDealer {
  video_verification_duration: number;
  website_logo: string;
}

interface RawCustomer {
  name: string;
}

interface RawControlSystem {
  id: number;
  name: string;
  video_analytics_recorder_enabled: boolean;
  ienso_cameras_enabled: boolean;
}

interface RawPanel {
  account_prefix: number;
  account_number: number;
  last_alarm_at: string;
}

interface RawVideoDevice {
  channels: RawVideoChannel[];
  checked_in_at: string;
  device_type: string;
  manufacturer: string;
  var_id: number | null;
}

interface RawVideoChannel {
  id: number;
  channel_video_verification_path: string;
  clips_video_verification_path: string;
  name: string;
}

export interface RawVideoVerificationInfo {
  dealer: RawDealer;
  customer: RawCustomer;
  control_system: RawControlSystem;
  panels: RawPanel[];
  video_devices: RawVideoDevice[];
}

export type VideoVerificationInfo = {
  accountNumber: string;
  alarmVisionEnabled: boolean;
  cameras: StreamableCamera[];
  customerName: string;
  dealerLogoUrl: string;
  lastAlarmAt: Date;
  systemName: string;
  videoVerificationDuration: number;
};

const VIDEO_DEVICE_TYPES = new Set(["camera", "dvr", "nvr"]);
const V6000_MANUFACTURER_NAME = "uniview";
const CHECKIN_WINDOW_MS = 3 * 60 * 1000;

export const parseVideoVerificationInfo = ({
  dealer,
  customer,
  control_system,
  panels,
  video_devices,
}: RawVideoVerificationInfo): VideoVerificationInfo => {
  const panel = panels[0];
  const cameras = video_devices
    .map((rawVideoDevice) => {
      if (
        rawVideoDevice.var_id === null && // Exclude cameras behind a Camect hub
        VIDEO_DEVICE_TYPES.has(rawVideoDevice.device_type)
      ) {
        const cameraType =
          rawVideoDevice.manufacturer.toLowerCase() === V6000_MANUFACTURER_NAME
            ? "v-6000"
            : "legacy";
        const lastCheckin = new Date(rawVideoDevice.checked_in_at);
        const currentTime = new Date();
        const checkinDiffMs = +currentTime - +lastCheckin;
        const status =
          checkinDiffMs < CHECKIN_WINDOW_MS
            ? CameraStatus.Online
            : CameraStatus.Offline;
        return rawVideoDevice.channels.map((rawVideoChannel) => {
          return {
            authToken: "",
            cameraId: rawVideoChannel.id.toString(),
            cameraType,
            clipsPath: rawVideoChannel.clips_video_verification_path,
            controlSystemId: control_system.id,
            env: "",
            hubId: "",
            lastCheckin,
            name: rawVideoChannel.name,
            status,
          } as StreamableCamera;
        });
      } else {
        return [];
      }
    })
    .flat();

  return {
    accountNumber: `${panel.account_prefix}-${panel.account_number}`,
    alarmVisionEnabled:
      control_system.video_analytics_recorder_enabled ||
      control_system.ienso_cameras_enabled,
    cameras,
    customerName: customer.name,
    dealerLogoUrl: dealer.website_logo,
    lastAlarmAt: new Date(panel.last_alarm_at),
    systemName: control_system.name,
    videoVerificationDuration: dealer.video_verification_duration,
  };
};
