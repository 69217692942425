import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { getEntries } from "common/utils/universal/object";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { readInlineData, useMutation } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  idAsString,
  OutputOptions,
  toUnprogrammedOutputId,
} from "securecom-graphql/client";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import OutputOptionsAcFailOutputField from "../common/OutputOptionsFields/OutputOptionsAcFailOutputField";
import OutputOptionsCarbonMonoxideOutputField from "../common/OutputOptionsFields/OutputOptionsCarbonMonoxideOutputField";
import OutputOptionsCommunicationFailOutputField from "../common/OutputOptionsFields/OutputOptionsCommunicationFailOutputField";
import { OutputOptionsContextProvider } from "../common/OutputOptionsFields/OutputOptionsContext";
import OutputOptionsCutoffOutputsField from "../common/OutputOptionsFields/OutputOptionsCutoffOutputsField";
import OutputOptionsDeviceFailOutputField from "../common/OutputOptionsFields/OutputOptionsDeviceFailOutputField";
import OutputOptionsFireAlarmOutputField from "../common/OutputOptionsFields/OutputOptionsFireAlarmOutputField";
import OutputOptionsFireTroubleOutputField from "../common/OutputOptionsFields/OutputOptionsFireTroubleOutputField";
import OutputOptionsOutputCutoffTimeField from "../common/OutputOptionsFields/OutputOptionsOutputCutoffTimeField";
import OutputOptionsSensorResetOutputField from "../common/OutputOptionsFields/OutputOptionsSensorResetOutputField";
import OutputOptionsSupervisoryAlarmOutputField from "../common/OutputOptionsFields/OutputOptionsSupervisoryAlarmOutputField";
import { SystemOptionsContextProvider } from "../common/SystemOptionsFields/SystemOptionsContext";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "../utils/templates";
import { XFOutputOptionsProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XFOutputOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts.graphql";
import refreshMutationConcreteRequest, {
  XFOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation,
} from "./__generated__/XFOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation.graphql";
import sendMutationConcreteRequest, {
  XFOutputOptionsProgrammingConceptFormOutputOptionsSendMutation,
  XFOutputOptionsProgrammingConceptFormOutputOptionsSendMutation$data,
} from "./__generated__/XFOutputOptionsProgrammingConceptFormOutputOptionsSendMutation.graphql";
import { XFOutputOptionsProgrammingConceptForm_controlSystem$key } from "./__generated__/XFOutputOptionsProgrammingConceptForm_controlSystem.graphql";

export const title = "Output Options";
export const conceptId = "xf-output-options";

export const getState = (
  controlSystem: XFOutputOptionsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XFOutputOptionsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          outputOptions {
            id
            acFailOutput {
              id
              number
              formattedNumber
            }
            carbonMonoxideOutput {
              id
              number
              formattedNumber
            }
            cutoffOutputs
            outputCutoffTime
            outputCutoffTimeMin
            outputCutoffTimeMax
            communicationFailOutput {
              id
              number
              formattedNumber
            }
            fireAlarmOutput {
              id
              number
              formattedNumber
            }
            fireTroubleOutput {
              id
              number
              formattedNumber
            }
            deviceFailOutput {
              __typename
              id
              number
              formattedNumber
            }
            sensorResetOutput {
              __typename
              id
              number
              formattedNumber
            }
            supervisoryAlarmOutput {
              __typename
              id
              number
              formattedNumber
            }
          }
        }
      }
    `,
    controlSystem
  );

const refreshMutation = graphql`
  mutation XFOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation(
    $id: ID!
  ) {
    refreshOutputOptions(systemId: $id) {
      ... on RefreshOutputOptionsSuccessResponse {
        __typename
        controlSystem: system {
          __typename
          ...XFOutputOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on RefreshOutputOptionsErrorResponse {
        error {
          type
        }
      }
    }
  }
`;
export const useRetrieveMutation = (props: {
  controlSystem: XFOutputOptionsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshOutputOptions, isRefreshing] =
    useMutation<XFOutputOptionsProgrammingConceptFormOutputOptionsRefreshMutation>(
      refreshMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id } = getState(props.controlSystem);
        refreshOutputOptions({
          variables: { id },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshOutputOptions;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Output Options Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshOutputOptions: {
                    __typename: response.refreshOutputOptions.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Output Options: ${hyphenScoreToTitleCase(
                      error.type ?? "UNKNOWN_ERROR"
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Output Options",
                  });
                }
              }
              reject(error?.type);
            }
          },
        });
      }),
    isRefreshing,
  ];
};
const sendMutation = graphql`
  mutation XFOutputOptionsProgrammingConceptFormOutputOptionsSendMutation(
    $systemId: ID!
    $outputOptions: OutputOptionsInput!
  ) {
    sendOutputOptions(systemId: $systemId, outputOptions: $outputOptions) {
      ... on SendOutputOptionsSuccessPayload {
        __typename
        controlSystem {
          __typename
          id
          ...XFOutputOptionsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on SendOutputOptionsErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

const updateOriginalControlSystem = (
  response: XFOutputOptionsProgrammingConceptFormOutputOptionsSendMutation$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  if (response.sendOutputOptions.controlSystem) {
    const operation = createOperationDescriptor(sendMutationConcreteRequest, {
      id: response.sendOutputOptions.controlSystem.id,
    });
    if (parentRelayEnv) {
      parentRelayEnv.commitPayload(operation, {
        sendOutputOptions: {
          __typename: response.sendOutputOptions.__typename,
          controlSystem: getState(response.sendOutputOptions.controlSystem),
        },
      });
    }
  }
};

export const useSaveMutation = (props: {
  controlSystem: XFOutputOptionsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendOutputOptions, isSendingOutputOptions] =
    useMutation<XFOutputOptionsProgrammingConceptFormOutputOptionsSendMutation>(
      sendMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { outputOptions },
        } = getState(props.controlSystem);
        if (outputOptions) {
          sendOutputOptions({
            variables: {
              systemId,
              outputOptions: {
                cutoffOutputs: outputOptions.cutoffOutputs,
                outputCutoffTime: outputOptions.outputCutoffTime.toString(),
                communicationFailOutput:
                  outputOptions.communicationFailOutput.formattedNumber,
                fireAlarmOutput: outputOptions.fireAlarmOutput.formattedNumber,
                fireTroubleOutput:
                  outputOptions.fireTroubleOutput.formattedNumber,
                carbonMonoxideOutput:
                  outputOptions.carbonMonoxideOutput.formattedNumber,
                acFailOutput: outputOptions.acFailOutput.formattedNumber,
                deviceFailOutput:
                  outputOptions.deviceFailOutput.formattedNumber,
                sensorResetOutput:
                  outputOptions.sensorResetOutput.formattedNumber,
                supervisoryAlarmOutput:
                  outputOptions.supervisoryAlarmOutput.formattedNumber,
              },
            },
            onCompleted: (response) => {
              const sendErrors: SaveErrors = [];
              if (response.sendOutputOptions.controlSystem) {
                if (showAlerts) {
                  showAlert({
                    type: "success",
                    text: "Output Options Programming Saved to the System",
                  });
                }
                resetLastUpdated(conceptId);
                updateOriginalControlSystem(response, parentRelayEnv);
              } else if (response.sendOutputOptions.errors) {
                sendErrors.push({
                  programmingConcept: title,
                  errors: response.sendOutputOptions.errors,
                });
              }
              resolve(sendErrors);
            },
            onError: () => {
              reject();
            },
          });
        }
      }),
    isSendingOutputOptions,
  ];
};

const readOutputOptionsTemplateData = (
  programmingTemplateConcepts: XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        outputOptions {
          included
        }
        nonOutputs: outputOptions {
          cutoffOutputs {
            included
            data
          }
          outputCutoffTime {
            included
            data
          }
        }
        outputs: outputOptions {
          communicationFailOutput {
            included
            data
          }
          fireAlarmOutput {
            included
            data
          }
          fireTroubleOutput {
            included
            data
          }
          carbonMonoxideOutput {
            included
            data
          }
          acFailOutput {
            included
            data
          }
          deviceFailOutput {
            included
            data
          }
          sensorResetOutput {
            included
            data
          }
          supervisoryAlarmOutput {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ) ?? { outputOptions: { included: false } };

export function applyTemplateData(
  programmingTemplateConcepts: XFOutputOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const systemId = controlSystemRecordProxy.getValue("id");
  const templateData = readOutputOptionsTemplateData(
    programmingTemplateConcepts
  );

  if (templateData?.outputOptions?.included) {
    const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
    const outputOptionsRecordProxy = panelRecordProxy.getOrCreateLinkedRecord(
      "outputOptions",
      "OutputOptions"
    ) as unknown as RecordProxy<OutputOptions>;

    if (templateData.nonOutputs) {
      applyTemplateScalarDataToRecordProxy(
        outputOptionsRecordProxy,
        templateData.nonOutputs
      );
    }

    if (templateData.outputs) {
      getEntries(templateData.outputs).forEach(([fieldKey, fieldData]) => {
        if (
          typeof fieldData === "object" &&
          fieldData?.included &&
          fieldData.data
        ) {
          const outputOptionId = idAsString(
            toUnprogrammedOutputId(systemId, fieldData.data)
          );
          const outputOptionRecordProxy =
            store.get(idAsString(outputOptionId)) ??
            store.create(outputOptionId, "UnprogrammedOutput");
          outputOptionRecordProxy.setValue(fieldData.data, "number");
          outputOptionRecordProxy.setValue(fieldData.data, "formattedNumber");
          outputOptionsRecordProxy.setLinkedRecord(
            outputOptionRecordProxy,
            fieldKey
          );
        }
      });
    }
  }
}

export function NavButton() {
  return (
    <ProgrammingConceptSidebarButton conceptId={conceptId} title={title} />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XFOutputOptionsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XFOutputOptionsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          panel {
            id
            softwareVersion
            hardwareModel
            armingSystem
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            ...PanelContext_panel
            ...PanelContextUseSoftwareVersion_panel
            ...PanelContextUseHardwareModel_panel
            outputOptions {
              ...OutputOptionsContext_outputOptions
              ...OutputOptionsCutoffOutputsField_outputOptions
              ...OutputOptionsOutputCutoffTimeField_outputOptions
              ...OutputOptionsCommunicationFailOutputField_outputOptions
              ...OutputOptionsFireAlarmOutputField_outputOptions
              ...OutputOptionsFireTroubleOutputField_outputOptions
              ...OutputOptionsCarbonMonoxideOutputField_outputOptions
              ...OutputOptionsAcFailOutputField_outputOptions
              ...OutputOptionsDeviceFailOutputField_outputOptions
              ...OutputOptionsSensorResetOutputField_outputOptions
              ...OutputOptionsSupervisoryAlarmOutputField_outputOptions
            }
            systemOptions {
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsContextSystemType_systemOptions
              ...SystemOptionsContextHouseCode_systemOptions
            }
          }
        }
      `
    );

  const {
    panel: {
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <OutputOptionsContextProvider
        outputOptions={controlSystem.panel.outputOptions}
      >
        <SystemOptionsContextProvider
          systemOptions={controlSystem.panel.systemOptions}
        >
          <ProgrammingConceptForm
            conceptId={conceptId}
            title={title}
            helpLink={`${programmingGuideUrl}#Output%20Options`}
            initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
              controlSystem.panel.outputOptions
            )}
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <ProgrammingConceptForm.Fields>
                <OutputOptionsCutoffOutputsField />
                <OutputOptionsOutputCutoffTimeField />
                <OutputOptionsCommunicationFailOutputField />
                <OutputOptionsFireAlarmOutputField />
                <OutputOptionsFireTroubleOutputField />
                <OutputOptionsDeviceFailOutputField />
                <OutputOptionsSensorResetOutputField />
                <OutputOptionsSupervisoryAlarmOutputField />
                <OutputOptionsAcFailOutputField />
                <OutputOptionsCarbonMonoxideOutputField />
              </ProgrammingConceptForm.Fields>
            </RemountOnUpdateContainer>
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </OutputOptionsContextProvider>
    </PanelContextProvider>
  );
}
