import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { usePcLogReportsFragment } from "./PcLogReportsContext";
import { PcLogReportsSupervisoryReportsEnabledField_pcLogReports$key } from "./__generated__/PcLogReportsSupervisoryReportsEnabledField_pcLogReports.graphql";

export const pcLogReportsSupervisoryReportsEnabledFieldId = () =>
  "pc-log-reports-supervisory-reports-enabled";

function PcLogReportsSupervisoryReportsEnabledField() {
  const [{ supervisoryReportsEnabled }, updatePcLogReports] =
    usePcLogReportsFragment<PcLogReportsSupervisoryReportsEnabledField_pcLogReports$key>(
      graphql`
        fragment PcLogReportsSupervisoryReportsEnabledField_pcLogReports on PcLogReports {
          supervisoryReportsEnabled
        }
      `
    );

  const fieldId = pcLogReportsSupervisoryReportsEnabledFieldId();
  const label = "Supervisory Reports";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Sends system monitor reports, such as AC and battery, and system event reports."
    >
      <Switch
        label={label}
        id={fieldId}
        checked={!!supervisoryReportsEnabled}
        onChange={() => {
          updatePcLogReports((recordProxy) => {
            recordProxy.setValue(
              !supervisoryReportsEnabled,
              "supervisoryReportsEnabled"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default PcLogReportsSupervisoryReportsEnabledField;
