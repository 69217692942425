/**
 * @generated SignedSource<<ccdab977d86b4c65876f05cc098ab344>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BellActions = "NONE" | "PULSE" | "STEADY" | "TEMPORAL" | "TEMPORAL_3" | "TEMPORAL_4";
import { FragmentRefs } from "relay-runtime";
export type XRBellOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly bellOptions: {
      readonly automaticBellTest: boolean;
      readonly auxiliary1BellAction: BellActions;
      readonly auxiliary2BellAction: BellActions;
      readonly bellCutoffTime: number;
      readonly bellCutoffTimeMax: number;
      readonly bellCutoffTimeMin: number;
      readonly bellOutput: string;
      readonly burglaryBellAction: BellActions;
      readonly carbonMonoxideBellAction: BellActions;
      readonly emergencyBellAction: BellActions;
      readonly fireBellAction: BellActions;
      readonly id: string;
      readonly panicBellAction: BellActions;
      readonly strobe: boolean | null;
      readonly supervisoryBellAction: BellActions;
    } | null;
    readonly id: string;
  };
  readonly " $fragmentType": "XRBellOptionsProgrammingConceptFormInline_controlSystem";
};
export type XRBellOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRBellOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRBellOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRBellOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "8cbb249b67aa84af1387fe6f6f7775ad";

export default node;
