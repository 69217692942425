App.controller("KeyFobsCtrl", [
  "$scope",
  "$state",
  "UserService",
  function ($scope, $state, UserService) {
    $scope["control_system"] = UserService.controlSystem;
    $scope.keyfob_areas_values = {};

    // Home
    $scope.keyfob_areas_values["H"] = {
      VALUES: { Home: "1", Sleep: "1,2", Away: "1,2,3" },
      DISPLAY: {
        Data_Type: "Number List",
        NAME: "Areas",
        TOOLTIP:
          "Select which Area(s) will Arm/Disarm when this button is activated.",
        FORM_GROUP: "Top",
        ORDER: 3,
      },
    };
    // Away
    $scope.keyfob_areas_values["A"] = {
      VALUES: { All: "1,2", Perimeter: "1" },
      DISPLAY: {
        Data_Type: "Number List",
        TOOLTIP:
          "Select which Area(s) will Arm/Disarm when this button is activated.",
        NAME: "Areas",
        FORM_GROUP: "Top",
        ORDER: 3,
      },
    };
    // Area
    switch (
      $scope.controlSystem.panels[$scope.controlSystem.panel_index]
        .hardware_model
    ) {
      case "XR550":
        $scope.keyfob_areas_values["N"] = {
          DISPLAY: {
            Data_Type: "Number List",
            NAME: "Areas",
            TOOLTIP:
              "Select which Area(s) will Arm/Disarm when this button is activated.",
            PLACEHOLDER: "1,2,3,4,5,6,7...",
            PATTERN:
              "(([1-9]|[1-2][0-9]|3[0-2])(([\\,\\-])([1-9]|[1-2][0-9]|3[0-2])){0,31})",
            VALIDATION_MSG: "Valid values are any combination of 1-32.",
            FORM_GROUP: "Top",
            ORDER: 3,
          },
        };

        break;
      case "XR350":
        $scope.keyfob_areas_values["N"] = {
          DISPLAY: {
            Data_Type: "Number List",
            NAME: "Areas",
            TOOLTIP:
              "Select which Area(s) will Arm/Disarm when this button is activated.",
            PLACEHOLDER: "1,2,3,4,5,6,7...",
            PATTERN: "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})",
            VALIDATION_MSG: "Valid values are any combination of 1-16.",
            FORM_GROUP: "Top",
            ORDER: 3,
          },
        };
        break;
      case "XR150":
        $scope.keyfob_areas_values["N"] = {
          DISPLAY: {
            Data_Type: "Number List",
            NAME: "Areas",
            TOOLTIP:
              "Select which Area(s) will Arm/Disarm when this button is activated.",
            PLACEHOLDER: "1,2,3,4,5,6,7,8",
            PATTERN: "([1-8]([\\,\\-][1-8]){0,7})",
            VALIDATION_MSG:
              "Valid values are any combination of 1,2,3,4,5,6,7,8.",
            FORM_GROUP: "Top",
            ORDER: 3,
          },
        };
        break;
      default:
        $scope.keyfob_areas_values["N"] = {
          DISPLAY: {
            Data_Type: "Number List",
            NAME: "Areas",
            TOOLTIP:
              "Select which Area(s) will Arm/Disarm when this button is activated.",
            PLACEHOLDER: "1, 2, 3, 4, 5, 6",
            PATTERN: "([1-6]([\\,\\-][1-6]){0,5})",
            VALIDATION_MSG: "Valid values are any combination of 1,2,3,4,5,6.",
            FORM_GROUP: "Top",
            ORDER: 3,
          },
        };
        break;
    }
    // Home/Sleep/Away with Guest
    $scope.keyfob_areas_values["G"] = {
      VALUES: { Home: "1", Sleep: "1,2", Away: "1,2,3" },
      DISPLAY: {
        Data_Type: "Number List",
        NAME: "Areas",
        TOOLTIP:
          "Select which Area(s) will Arm/Disarm when this button is activated.",
        FORM_GROUP: "Top",
        ORDER: 3,
      },
    };

    keyFobDefaults = {
      H: {
        4: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "4",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2,3",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "05",
          press_tm2: "1",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "S",
          action_b3: "01",
          press_tm3: "0",
          areas_b3: "1",
          output_b3: "0",
          out_act_b3: "S",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2,3",
          output_b4: "0",
          out_act_b4: "S",
        },
        2: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "2",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2,3",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2,3",
          output_b4: "0",
          out_act_b4: "S",
        },
        1: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "1",
          action_b1: "05",
          press_tm1: "1",
          areas_b1: "",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "00",
          press_tm4: "0",
          areas_b4: "",
          output_b4: "0",
          out_act_b4: "O",
        },
      },
      A: {
        4: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "4",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "05",
          press_tm2: "1",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "S",
          action_b3: "01",
          press_tm3: "0",
          areas_b3: "1",
          output_b3: "0",
          out_act_b3: "S",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2",
          output_b4: "0",
          out_act_b4: "S",
        },
        2: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "2",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2",
          output_b4: "0",
          out_act_b4: "S",
        },
        1: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "1",
          action_b1: "05",
          press_tm1: "1",
          areas_b1: "",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "00",
          press_tm4: "0",
          areas_b4: "",
          output_b4: "0",
          out_act_b4: "O",
        },
      },
      N: {
        4: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "4",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2,3,4,5,6",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "05",
          press_tm2: "1",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "S",
          action_b3: "01",
          press_tm3: "0",
          areas_b3: "1",
          output_b3: "0",
          out_act_b3: "S",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2,3,4,5,6",
          output_b4: "0",
          out_act_b4: "S",
        },
        2: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "2",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2,3,4,5,6",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2,3,4,5,6",
          output_b4: "0",
          out_act_b4: "S",
        },
        1: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "1",
          action_b1: "05",
          press_tm1: "1",
          areas_b1: "",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "00",
          press_tm4: "0",
          areas_b4: "",
          output_b4: "0",
          out_act_b4: "O",
        },
      },
      G: {
        4: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "4",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2,3,4,5,6",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "05",
          press_tm2: "1",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "S",
          action_b3: "01",
          press_tm3: "0",
          areas_b3: "1",
          output_b3: "0",
          out_act_b3: "S",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2,3,4,5,6",
          output_b4: "0",
          out_act_b4: "S",
        },
        2: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "2",
          action_b1: "01",
          press_tm1: "0",
          areas_b1: "1,2,3,4,5,6",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "02",
          press_tm4: "0",
          areas_b4: "1,2,3,4,5,6",
          output_b4: "0",
          out_act_b4: "S",
        },
        1: {
          user_num: "",
          kf_serial: "05",
          sup_time: "0",
          buttons: "1",
          action_b1: "05",
          press_tm1: "1",
          areas_b1: "",
          output_b1: "0",
          out_act_b1: "S",
          action_b2: "00",
          press_tm2: "0",
          areas_b2: "",
          output_b2: "0",
          out_act_b2: "O",
          action_b3: "00",
          press_tm3: "0",
          areas_b3: "",
          output_b3: "0",
          out_act_b3: "O",
          action_b4: "00",
          press_tm4: "0",
          areas_b4: "",
          output_b4: "0",
          out_act_b4: "O",
        },
      },
    };

    $scope.addNewKeyFob = function () {
      $scope.Panel.newItem("keyfobs")
        .then(function (data) {
          angular.merge(
            data,
            keyFobDefaults[$scope.Panel.system_options.arm_mode][data.buttons]
          );
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.selectedUser = {};
    $scope.setUserName = function () {
      var usr = $scope.Panel.user_codes.find(function (code) {
        return +code.number === +$scope.currentFob.user_num;
      });
      $scope.selectedUser = usr ? usr : { name: "Unassigned" };
    };

    $scope.outputIsFavoriteButtons = {};
    $scope["watchers"] = {};
    $scope.currentFob = {};
    $scope.initOpenKeyFob = function (fob) {
      $scope.currentFob = fob;
      $scope.setUserName();
      setButtonsWatcher();
      for (var idx = 1; idx <= 4; idx++) {
        setupOutputIsFavoriteWatcher(idx);
      }
    };
    function setButtonsWatcher() {
      // Deregister a previously assigned watcher
      if ($scope.watchers["buttons"]) {
        $scope.watchers["buttons"]();
      }
      // Watch the number of buttons to set defaults if it changes
      $scope.watchers["buttons"] = $scope.$watch(
        "currentFob.buttons",
        function (newValue, oldValue) {
          if (newValue !== oldValue) {
            angular.merge(
              $scope.currentFob,
              keyFobDefaults[$scope.Panel.system_options.arm_mode][newValue]
            );
            $scope.setUserName();
          }
        }
      );
    }
    function setupOutputIsFavoriteWatcher(button) {
      // Deregister any previously assigned watchGroup
      if ($scope.watchers["outputWatcher_" + button]) {
        $scope.watchers["outputWatcher_" + button]();
      }
      // Create a watch group for the fields that are used to determine if a favorite is assigned as an output
      $scope.watchers["outputWatcher_" + button] = $scope.$watchGroup(
        ["currentFob.action_b" + button, "currentFob.output_b" + button],
        function () {
          $scope.updateOutputActionDropdownType($scope.currentFob, button);
        }
      );
    }
    $scope.updateOutputActionDropdownType = function (fob, buttonNum) {
      // 05 = Panic, 06 = Panic 2 Btn, 07 = Emergency, 08 = Emergency 2 Btn, 09 = Output
      $scope.outputIsFavoriteButtons["out_act_b" + buttonNum] =
        ["05", "06", "07", "08", "09"].indexOf(fob["action_b" + buttonNum]) >
          -1 && fob["output_b" + buttonNum].charAt(0) === "F";
      if ($scope.outputIsFavoriteButtons["out_act_b" + buttonNum]) {
        fob["out_act_b" + buttonNum] = "S";
      }
    };
  },
]);
