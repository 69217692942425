/**
 * @generated SignedSource<<b28af5f41cec397a2e6d5decf63426d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data = {
  readonly networkOptions: {
    readonly dhcpEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dnsServer: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly gatewayAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly localIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly subnetMask: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts";
};
export type XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFNetworkOptionsProgrammingConceptFormInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "82955a037f4d79cec13b1e69d6181e27";

export default node;
