import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import * as React from "react";
import {
  createPlaceholderFromRange,
  findNearestValueInRange,
} from "../../utils/display";
import ProgrammingConceptForm from ".././ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationTransmissionDelayField_communication$key } from "./__generated__/CommunicationTransmissionDelayField_communication.graphql";

export const communicationTransmissionDelayFieldId = () =>
  "communication-transmission-delay";

function CommunicationTransmissionDelayField() {
  const [
    { transmissionDelay, transmissionDelayValidValues },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationTransmissionDelayField_communication$key>(
    graphql`
      fragment CommunicationTransmissionDelayField_communication on Communication {
        transmissionDelay
        transmissionDelayValidValues
      }
    `
  );

  const fieldId = communicationTransmissionDelayFieldId();
  const { current: originalValue } = React.useRef(transmissionDelay);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Transmission Delay"
      tooltip="Number of seconds the System waits before sending a Burglary Alarm to the Receiver."
    >
      <NumericInput
        id={fieldId}
        value={transmissionDelay}
        required
        inlineHelp={createPlaceholderFromRange(transmissionDelayValidValues)}
        pattern="([0]{0,2}|1[5-9]|[2-3][0-9]|4[0-5])"
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "transmissionDelay"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : findNearestValueInRange(
                    valueAsNumber,
                    transmissionDelayValidValues
                  );
            recordProxy.setValue(Number(value), "transmissionDelay");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationTransmissionDelayField;
