/**
 * @generated SignedSource<<3ab0c31fb1444e8f08287b5b27939ef4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75AreaInformationProgrammingConceptFormInline_systemAreaInformation$data = {
  readonly __typename: "SystemAreaInformation";
  readonly anyBypass: boolean;
  readonly areaSchedules: boolean;
  readonly closingCheck: boolean;
  readonly closingCode: boolean;
  readonly exitDelay: number;
  readonly morningAmbush: number;
  readonly " $fragmentType": "XT75AreaInformationProgrammingConceptFormInline_systemAreaInformation";
};
export type XT75AreaInformationProgrammingConceptFormInline_systemAreaInformation$key = {
  readonly " $data"?: XT75AreaInformationProgrammingConceptFormInline_systemAreaInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationProgrammingConceptFormInline_systemAreaInformation">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75AreaInformationProgrammingConceptFormInline_systemAreaInformation"
};

(node as any).hash = "ca7df39df700102948e4d720679ce87d";

export default node;
