App.directive("daTestConnection", [
  "$modal",
  "$stateParams",
  "UserService",
  function ($modal, $stateParams, UserService) {
    return {
      restrict: "E",
      replace: true,
      template:
        '<div class="btn btn-sm btn-dmp btn-testConn" ng-click="openTestConnectionModal()">Test Connection</div>',

      controller: function (
        $scope,
        $modal,
        ControlSystemsService,
        DealerService,
        PanelProgrammingService,
        Customer,
        PanelConnectionHistoryService,
        UserService,
        $q
      ) {
        // initialize scope variables
        $scope.controlSystem = ControlSystemsService.currentControlSystem;
        $scope.systemId = $stateParams.systemId;

        $scope.isTechSupport = UserService.isTechSupport();
        $scope.showBusyRecentConnectionsSpinner = false;
        $scope.isSupervisorAccessible = UserService.isSupervisorAccessible();

        const dealerService = new DealerService({
          dealer_id: UserService.dealer_id,
        });
        $scope.showBusySpinner = false;
        let showSpinner;

        const ConnectionTypes = {
          EASY_CONNECT: "EASYconnect",
          CELLULAR: "cellular",
          NETWORK: "network",
          UNKNOWN: "Unknown",
        };

        const defaultMainValues = ($scope) => {
          $scope.panelDidConnect = null;
          $scope.panelConnectError = null;
          $scope.actualSuccessfulConnectionType = null;
        };

        // this object maps the connection status returned from the API call to the connection status key
        const setupConnectionStatusMap = ($scope) => {
          $scope.connectionStatusMapping = {
            "Persistent Connection Successful":
              "persistentConnectionSuccessful",
            "Network Connection Successful": "networkConnectionSuccessful",
            "Direct Cell Connection Successful":
              "directCellConnectionSuccessful",
            "Cellular Connection Successful": "cellularConnectionSuccessful",
          };
        };

        const initializeConnectionStatuses = ($scope) => {
          $scope.connectionStatuses = {
            persistentConnectionSuccessful: null,
            networkConnectionSuccessful: null,
            directCellConnectionSuccessful: null,
            cellularConnectionSuccessful: null,
          };
        };

        defaultMainValues($scope);
        initializeConnectionStatuses($scope);
        setupConnectionStatusMap($scope);

        //refresh system options
        $scope.refreshSystemOptions = function (
          controlSystemId,
          customerId,
          showSpinner = true
        ) {
          $scope.panelDidConnect = null;
          $scope.showBusyRecentConnectionsSpinner = true;
          $scope.systemOptionsIsBusy = showSpinner;

          PanelProgrammingService.panel_id = $scope.controlSystem.panels[0].id;

          return PanelProgrammingService.refresh(
            "system_options",
            true,
            { tracked: true },
            PanelProgrammingService.getSessionKey()
          )
            .then(() =>
              ControlSystemsService.getControlSystem(
                controlSystemId,
                customerId
              )
            )
            .then(() => {
              $scope.controlSystem = ControlSystemsService.currentControlSystem;
            })
            .catch((error) => {
              if (error !== "STALE_STATE_CALL") {
                ControlSystemsService.getControlSystem(
                  controlSystemId,
                  customerId
                );
              }
              console.error(error);
              throw error; // Re-throw the error to ensure it propagates.
            })
            .finally(() => {
              $scope.systemOptionsIsBusy = false;
              $scope.showBusyRecentConnectionsSpinner = false;
            });
        };

        // open the test connection modal
        $scope.openTestConnectionModal = function () {
          $scope.checked = false;
          $scope.showModal = true;
          $scope.testConnModal = $modal.open({
            templateUrl: "app/common/templates/test-connection.html",
            size: "md",
            backdrop: "static",
            scope: $scope,
          });

          // returns the connection type string
          // for display purposes
          $scope.getConnectionTypeStringFromCommType = function (
            connectionType
          ) {
            switch (connectionType) {
              case "cell":
                return "Cellular";
              case "persistent":
                return "EASYconnect";
              case "persistent_w_cell_backup":
                return "EASYconnect with Cell Backup";
              case "network":
                return "Network";
              default:
                return "Unknown";
            }
          };

          // this function takes the panelConnectionHistoryArray and sorts it by date
          $scope.sortRecentPanelConnections = (panelConnectionHistoryArray) => {
            return panelConnectionHistoryArray
              .slice()
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          };

          // this function checks the connection statuses
          // and sets the connection statuses object
          // based on the connection history
          $scope.checkConnectionStatuses = (connectionHistoryArray) => {
            const oneHourAgo = new Date(new Date() - 60 * 60 * 1000);
            $scope.lastFewConnections = connectionHistoryArray.filter(
              (item) => new Date(item.created_at) > oneHourAgo
            );

            !$scope.lastFewConnections.length
              ? Object.keys($scope.connectionStatuses).forEach(
                  (key) => ($scope.connectionStatuses[key] = false)
                )
              : $scope.updateConnectionStatuses($scope.lastFewConnections);
          };

          // this function sets the active connections
          // based on the connection statuses
          $scope.setActiveConnections = function () {
            $scope.isCellConnected =
              $scope.connectionStatuses.cellularConnectionSuccessful ||
              $scope.connectionStatuses.directCellConnectionSuccessful;
            $scope.isNetworkConnected =
              $scope.connectionStatuses.networkConnectionSuccessful;
            $scope.isEasyConnected =
              $scope.connectionStatuses.persistentConnectionSuccessful;
          };

          // this function determines the successful connection type based on the connection status key
          const determineSuccessfulConnectionType = (statusKey) => {
            switch (statusKey) {
              case "persistentConnectionSuccessful":
                return ConnectionTypes.EASY_CONNECT;
              case "directCellConnectionSuccessful":
                return ConnectionTypes.CELLULAR;
              case "networkConnectionSuccessful":
                return ConnectionTypes.NETWORK;
              // Add more cases as needed
              default:
                return ConnectionTypes.UNKNOWN;
            }
          };

          // this function updates the connection statuses based on the connection history
          $scope.updateConnectionStatuses = function (connectionArray) {
            connectionArray.some((connection) => {
              let statusKey =
                $scope.connectionStatusMapping[connection.message];
              if ($scope.connectionStatuses.hasOwnProperty(statusKey)) {
                $scope.connectionStatuses[statusKey] = true;
                $scope.actualSuccessfulConnectionType =
                  determineSuccessfulConnectionType(statusKey);
                return true; // Stop iterating after the first successful connection type is found
              }
              return false; // Continue iterating
            });

            $scope.setActiveConnections();
          };

          // this function gets the connection history for the specified panel
          $scope.getConnectionHistory = function (panelId) {
            $scope.panelConnectionHistoryIsBusy =
              $scope.showBusyRecentConnectionsSpinner = true;

            PanelConnectionHistoryService.getPanelConnectionHistories(panelId)
              .then((data) => {
                $scope.sortedConnections = $scope.sortRecentPanelConnections(
                  data.value
                );
                $scope.checkConnectionStatuses($scope.sortedConnections);
              })
              .catch((error) =>
                console.error("Error fetching connection history:", error)
              )
              .finally(() => {
                $scope.panelConnectionHistoryIsBusy =
                  $scope.showBusyRecentConnectionsSpinner = false;
              });
          };

          $scope.pushAppFeatures = () => {
            PanelProgrammingService.sendAppFeatures(
              $scope.controlSystem.panels[$scope.controlSystem.panel_index].id
            );
          };

          $scope.checkForSuccessfulConnectionByCommType = () => {
            if ($scope.panelDidConnect && !$scope.systemOptionsIsBusy) {
              return Object.values($scope.connectionStatuses).some(
                (status) => status === true
              );
            }
          };

          const handleRefreshSystemOptionsSuccess = () => {
            $scope.panelDidConnect = true;
          };

          // Handle error case for refreshSystemOptions
          const handleRefreshSystemOptionsError = (error) => {
            console.error("TestConnectionCtrl Error:", angular.toJson(error));
            $scope.showBusyRecentConnectionsSpinner = false;
            $scope.panelDidConnect = false; // Explicitly set to false on error

            if (error === "USER_CANCELLED") {
              $scope.close();
            } else {
              processErrorDetails(error);
            }
          };

          const processErrorDetails = (error) => {
            if (error.job?.details?.message) {
              $scope.panelConnectError = formatErrorMessage(
                error.job.details.message
              );
            } else if (
              error.data?.errors?.panels === "is invalid" &&
              "comm_address" in error.data.errors
            ) {
              $scope.panelConnectError =
                "Phone number is missing. Please close this window, then click the 'Get Status' button for the MEID/ICCID Number field, or manually enter a phone number.";
            } else {
              $scope.panelConnectError = "Error Connecting to the System.";
            }
            $scope.panelDidConnect = false;
          };

          // this function formats the error message
          //  so that it is more readable
          // and user-friendly and matches the format of the other error messages
          const formatErrorMessage = (errorMessage) =>
            errorMessage
              // we convert the entire string to lowercase,
              .toLowerCase()
              // then we replace the first letter of each word with its uppercase equivalent globally
              .replace(/\b[a-z]/g, (letter) => letter.toUpperCase())
              // then we remove all single and double quotes globally
              .replace(/['"]+/g, "")
              // then we remove all periods globally
              .replace(/\./g, "");

          const finalizeConnectionProcess = () => {
            const panelId =
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]?.id;
            if (panelId) {
              $scope.getConnectionHistory(panelId);
              $scope.checkForSuccessfulConnectionByCommType();
            }
          };

          // Refresh system options and handle the outcome
          $scope
            .refreshSystemOptions(
              UserService.controlSystem.control_system_id,
              UserService.controlSystem.customer_id,
              showSpinner
            )
            .then(handleRefreshSystemOptionsSuccess)
            .catch(handleRefreshSystemOptionsError)
            .finally(finalizeConnectionProcess);

          // Define the close function
          $scope.close = () => {
            $scope.pushAppFeatures();
            $scope.testConnModal.close();
          };
        };
      },
    };
  },
]);
