/**
 * @generated SignedSource<<c9d6f6638e37450f3667728dfb2ae706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75CommunicationProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly __typename: "Panel";
    readonly communicationPaths: ReadonlyArray<{
      readonly __typename: "CommunicationPath";
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XT75CommunicationProgrammingConceptFormInline_communicationPath">;
    }>;
    readonly id: string;
    readonly maxCommunicationPaths: number;
  };
  readonly " $fragmentType": "XT75CommunicationProgrammingConceptFormInline_controlSystem";
};
export type XT75CommunicationProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75CommunicationProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CommunicationProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75CommunicationProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "53e22fb09c1e0d25dc6545ef419fe795";

export default node;
