/**
 * @generated SignedSource<<0106f5018a0d105941b0c95eaf21dfe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathType = "BACKUP" | "PRIMARY";
import { FragmentRefs } from "relay-runtime";
export type CommPathPanicTestField_communicationPath$data = {
  readonly number: number;
  readonly panicTest: boolean;
  readonly type: CommunicationPathType;
  readonly " $fragmentType": "CommPathPanicTestField_communicationPath";
};
export type CommPathPanicTestField_communicationPath$key = {
  readonly " $data"?: CommPathPanicTestField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathPanicTestField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathPanicTestField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "panicTest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "04ec6352d2bb2f8aa03a20b9251186f8";

export default node;
