import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { communicationBackupCellFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationBackupCellField";
import { communicationBackupCheckInMinutesFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationBackupCheckInMinutes";
import { communicationBackupDialerFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationBackupDialerField";
import { communicationBackupFailTimeMinutesFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationBackupFailTimeMinutes";
import { communicationCellTestDaysFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationCellTestDaysField";
import { communicationCheckInMinutesFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationCheckInMinutesField";
import { communicationDialerTestDaysFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationDialerTestDaysField";
import { communicationFailTimeFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationFailTimeField";
import { communicationFirstApnFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationFirstApnField";
import { communicationNetTestDaysFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationNetTestDaysField";
import { communicationReceiver1AlarmReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1AlarmReportsField";
import { communicationReceiver1FirstIpAddressFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1FirstIpAddressField";
import { communicationReceiver1FirstIpPortFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1FirstIpPortField";
import { communicationReceiver1FirstPhoneNumberFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1FirstPhoneNumberField";
import { communicationReceiver1OpenCloseUserReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1OpenCloseUserReportsField";
import { communicationReceiver1SecondIpAddressFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1SecondIpAddressField";
import { communicationReceiver1SecondIpPortFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1SecondIpPortField";
import { communicationReceiver1SecondPhoneNumberFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1SecondPhoneNumberField";
import { communicationReceiver1SupervisoryTroubleReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1SupervisoryTroubleReportsField";
import { communicationReceiver1TestReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver1TestReportsField";
import { communicationReceiver2AlarmReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2AlarmReportsField";
import { communicationReceiver2BackupFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2BackupField";
import { communicationReceiver2FirstIpAddressFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2FirstIpAddressField";
import { communicationReceiver2FirstIpPortFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2FirstIpPortField";
import { communicationReceiver2FirstPhoneNumberFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2FirstPhoneNumberField";
import { communicationReceiver2OpenCloseUserReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2OpenCloseUserReportsField";
import { communicationReceiver2SecondIpAddressFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2SecondIpAddressField";
import { communicationReceiver2SecondIpPortFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2SecondIpPortField";
import { communicationReceiver2SecondPhoneNumberFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2SecondPhoneNumberField";
import { communicationReceiver2SupervisoryTroubleReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2SupervisoryTroubleReportsField";
import { communicationReceiver2TestReportsFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationReceiver2TestReportsField";
import { communicationSecondApnFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationSecondApnField";
import { communicationSendCommunicationTroubleFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationSendCommunicationTroubleField";
import { communicationTestTimeFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationTestTimeField";
import { communicationTransmissionDelayFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationTransmissionDelayField";
import { communicationTypeFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationTypeField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getCommunicationState } from "../../../TMSentryFullProgramming/TMSentryCommunicationProgrammingConceptForm";

import { communicationEncryptionEnabledFieldId } from "components/FullProgramming/common/CommunicationFields/CommunicationEncryptionEnabledField";
import { TMSentryCommunicationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/TMSentryFullProgramming/__generated__/TMSentryCommunicationProgrammingConceptFormInline_controlSystem.graphql";
import { XtProgrammingTemplateCommunicationInput } from "../Create/__generated__/TMSentryTemplateSaveMutation.graphql";
import { TMSentryCommunicationTemplateDataInline_tMSentryProgrammingTemplateConcepts$key } from "./__generated__/TMSentryCommunicationTemplateDataInline_tMSentryProgrammingTemplateConcepts.graphql";

export const getState = (
  template: TMSentryCommunicationTemplateDataInline_tMSentryProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment TMSentryCommunicationTemplateDataInline_tMSentryProgrammingTemplateConcepts on TakeoverProgrammingTemplateConcepts
      @inline {
        communication {
          id
          included
          comType {
            data
            included
          }
          transmissionDelay {
            data
            included
          }
          netTrouble {
            data
            included
          }
          backupCell {
            data
            included
          }
          backupCheckinMinutes {
            data
            included
          }
          backupFailTimeMinutes {
            data
            included
          }
          backupDialer {
            data
            included
          }
          testTime {
            data
            included
          }
          networkTestDays {
            data
            included
          }
          cellTestDays {
            data
            included
          }
          dialerTestDays {
            data
            included
          }
          encryptionEnabled {
            data
            included
          }
          cellApn1 {
            data
            included
          }
          cellApn2 {
            data
            included
          }
          checkInMinutes {
            data
            included
          }
          failTime {
            data
            included
          }
          receiver1AlarmReports {
            data
            included
          }
          receiver1SupervisoryTroubleReports {
            data
            included
          }
          receiver1OpenCloseUserReports {
            data
            included
          }
          receiver1TestReports {
            data
            included
          }
          receiver1Ip {
            data
            included
          }
          receiver1Port {
            data
            included
          }
          receiver1Ip2 {
            data
            included
          }
          receiver1Port2 {
            data
            included
          }
          receiver1PrimaryPhoneNumber {
            data
            included
          }
          receiver1SecondaryPhoneNumber {
            data
            included
          }
          receiver2Backup {
            data
            included
          }
          receiver2AlarmReports {
            data
            included
          }
          receiver2SupervisoryTroubleReports {
            data
            included
          }
          receiver2OpenCloseUserReports {
            data
            included
          }
          receiver2TestReports {
            data
            included
          }
          receiver2Ip {
            data
            included
          }
          receiver2Port {
            data
            included
          }
          receiver2Ip2 {
            data
            included
          }
          receiver2Port2 {
            data
            included
          }
          receiver2PrimaryPhoneNumber {
            data
            included
          }
          receiver2SecondaryPhoneNumber {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: TMSentryCommunicationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XtProgrammingTemplateCommunicationInput => {
  const {
    panel: { communication },
  } = getCommunicationState(controlSystem);

  if (!communication) {
    return { included: false };
  }

  const communicationInputFields = {
    comType: {
      included: includedFields.has(communicationTypeFieldId()),
      data: communication.comType,
    },
    transmissionDelay: {
      included: includedFields.has(communicationTransmissionDelayFieldId()),
      data: communication.transmissionDelay,
    },
    encryptionEnabled: {
      included: includedFields.has(communicationEncryptionEnabledFieldId()),
      data: communication.encryptionEnabled,
    },
    netTrouble: {
      included: includedFields.has(
        communicationSendCommunicationTroubleFieldId()
      ),
      data: communication.netTrouble,
    },
    backupCell: {
      included: includedFields.has(communicationBackupCellFieldId()),
      data: communication.backupCell,
    },
    backupCheckinMinutes: {
      included: includedFields.has(communicationBackupCheckInMinutesFieldId()),
      data: communication.backupCheckinMinutes,
    },
    backupFailTimeMinutes: {
      included: includedFields.has(communicationBackupFailTimeMinutesFieldId()),
      data: communication.backupFailTimeMinutes,
    },
    backupDialer: {
      included: includedFields.has(communicationBackupDialerFieldId()),
      data: communication.backupDialer,
    },
    testTime: {
      included: includedFields.has(communicationTestTimeFieldId()),
      data: String(communication.testTime).trim(),
    },
    networkTestDays: {
      included: includedFields.has(communicationNetTestDaysFieldId()),
      data: communication.networkTestDays,
    },
    cellTestDays: {
      included: includedFields.has(communicationCellTestDaysFieldId()),
      data: communication.cellTestDays,
    },
    dialerTestDays: {
      included: includedFields.has(communicationDialerTestDaysFieldId()),
      data: communication.dialerTestDays,
    },
    cellApn1: {
      included: includedFields.has(communicationFirstApnFieldId()),
      data: communication.cellApn1,
    },
    cellApn2: {
      included: includedFields.has(communicationSecondApnFieldId()),
      data: communication.cellApn2,
    },
    checkInMinutes: {
      included: includedFields.has(communicationCheckInMinutesFieldId()),
      data: communication.checkInMinutes,
    },
    failTime: {
      included: includedFields.has(communicationFailTimeFieldId()),
      data: communication.failTime,
    },

    receiver1AlarmReports: {
      included: includedFields.has(communicationReceiver1AlarmReportsFieldId()),
      data: communication.receiver1AlarmReports,
    },
    receiver1SupervisoryTroubleReports: {
      included: includedFields.has(
        communicationReceiver1SupervisoryTroubleReportsFieldId()
      ),
      data: communication.receiver1SupervisoryTroubleReports,
    },
    receiver1OpenCloseUserReports: {
      included: includedFields.has(
        communicationReceiver1OpenCloseUserReportsFieldId()
      ),
      data: communication.receiver1OpenCloseUserReports,
    },
    receiver1TestReports: {
      included: includedFields.has(communicationReceiver1TestReportsFieldId()),
      data: communication.receiver1TestReports,
    },
    receiver1Ip: {
      included: includedFields.has(
        communicationReceiver1FirstIpAddressFieldId()
      ),
      data: communication.receiver1Ip,
    },
    receiver1Port: {
      included: includedFields.has(communicationReceiver1FirstIpPortFieldId()),
      data: communication.receiver1Port,
    },
    receiver1Port2: {
      included: includedFields.has(communicationReceiver1SecondIpPortFieldId()),
      data: communication.receiver1Port2,
    },
    receiver1Ip2: {
      included: includedFields.has(
        communicationReceiver1SecondIpAddressFieldId()
      ),
      data: communication.receiver1Ip2,
    },
    receiver1PrimaryPhoneNumber: {
      included: includedFields.has(
        communicationReceiver1FirstPhoneNumberFieldId()
      ),
      data: communication.receiver1PrimaryPhoneNumber,
    },
    receiver1SecondaryPhoneNumber: {
      included: includedFields.has(
        communicationReceiver1SecondPhoneNumberFieldId()
      ),
      data: communication.receiver1SecondaryPhoneNumber,
    },
    receiver2Backup: {
      included: includedFields.has(communicationReceiver2BackupFieldId()),
      data: communication.receiver2Backup,
    },
    receiver2AlarmReports: {
      included: includedFields.has(communicationReceiver2AlarmReportsFieldId()),
      data: communication.receiver2AlarmReports,
    },
    receiver2SupervisoryTroubleReports: {
      included: includedFields.has(
        communicationReceiver2SupervisoryTroubleReportsFieldId()
      ),
      data: communication.receiver2SupervisoryTroubleReports,
    },
    receiver2OpenCloseUserReports: {
      included: includedFields.has(
        communicationReceiver2OpenCloseUserReportsFieldId()
      ),
      data: communication.receiver2OpenCloseUserReports,
    },
    receiver2TestReports: {
      included: includedFields.has(communicationReceiver2TestReportsFieldId()),
      data: communication.receiver2TestReports,
    },
    receiver2Ip: {
      included: includedFields.has(
        communicationReceiver2FirstIpAddressFieldId()
      ),
      data: communication.receiver2Ip,
    },
    receiver2Port: {
      included: includedFields.has(communicationReceiver2FirstIpPortFieldId()),
      data: communication.receiver2Port,
    },
    receiver2Ip2: {
      included: includedFields.has(
        communicationReceiver2SecondIpAddressFieldId()
      ),
      data: communication.receiver2Ip2,
    },
    receiver2Port2: {
      included: includedFields.has(communicationReceiver2SecondIpPortFieldId()),
      data: communication.receiver2Port2,
    },
    receiver2PrimaryPhoneNumber: {
      included: includedFields.has(
        communicationReceiver2FirstPhoneNumberFieldId()
      ),
      data: communication.receiver2PrimaryPhoneNumber,
    },
    receiver2SecondaryPhoneNumber: {
      included: includedFields.has(
        communicationReceiver2SecondPhoneNumberFieldId()
      ),
      data: communication.receiver2SecondaryPhoneNumber,
    },
  };

  const communicationIncluded = Object.values(communicationInputFields).some(
    ({ included }) => included
  );

  return {
    included: communicationIncluded,
    ...communicationInputFields,
  };
};

export const setIncludedFields = (
  template: TMSentryCommunicationTemplateDataInline_tMSentryProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { communication } = getState(template);
  if (!communication) return;

  if (communication.comType?.included) {
    setIncludedFields(setToggle(communicationTypeFieldId()));
  }
  if (communication.transmissionDelay?.included) {
    setIncludedFields(setToggle(communicationTransmissionDelayFieldId()));
  }
  if (communication.netTrouble?.included) {
    setIncludedFields(
      setToggle(communicationSendCommunicationTroubleFieldId())
    );
  }
  if (communication.backupCell?.included) {
    setIncludedFields(setToggle(communicationBackupCellFieldId()));
  }
  if (communication.backupCheckinMinutes?.included) {
    setIncludedFields(setToggle(communicationBackupCheckInMinutesFieldId()));
  }
  if (communication.backupFailTimeMinutes?.included) {
    setIncludedFields(setToggle(communicationBackupFailTimeMinutesFieldId()));
  }

  if (communication.encryptionEnabled?.included) {
    setIncludedFields(setToggle(communicationEncryptionEnabledFieldId()));
  }
  if (communication.testTime?.included) {
    setIncludedFields(setToggle(communicationTestTimeFieldId()));
  }
  if (communication.networkTestDays?.included) {
    setIncludedFields(setToggle(communicationNetTestDaysFieldId()));
  }
  if (communication.cellTestDays?.included) {
    setIncludedFields(setToggle(communicationCellTestDaysFieldId()));
  }
  if (communication.dialerTestDays?.included) {
    setIncludedFields(setToggle(communicationDialerTestDaysFieldId()));
  }
  if (communication.cellApn1?.included) {
    setIncludedFields(setToggle(communicationFirstApnFieldId()));
  }
  if (communication.cellApn2?.included) {
    setIncludedFields(setToggle(communicationSecondApnFieldId()));
  }
  if (communication.checkInMinutes?.included) {
    setIncludedFields(setToggle(communicationCheckInMinutesFieldId()));
  }
  if (communication.failTime?.included) {
    setIncludedFields(setToggle(communicationFailTimeFieldId()));
  }
  if (communication.receiver1AlarmReports?.included) {
    setIncludedFields(setToggle(communicationReceiver1AlarmReportsFieldId()));
  }
  if (communication.receiver1SupervisoryTroubleReports?.included) {
    setIncludedFields(
      setToggle(communicationReceiver1SupervisoryTroubleReportsFieldId())
    );
  }
  if (communication.receiver1OpenCloseUserReports?.included) {
    setIncludedFields(
      setToggle(communicationReceiver1OpenCloseUserReportsFieldId())
    );
  }
  if (communication.receiver1TestReports?.included) {
    setIncludedFields(setToggle(communicationReceiver1TestReportsFieldId()));
  }
  if (communication.receiver1Ip?.included) {
    setIncludedFields(setToggle(communicationReceiver1FirstIpAddressFieldId()));
  }
  if (communication.receiver1Port?.included) {
    setIncludedFields(setToggle(communicationReceiver1FirstIpPortFieldId()));
  }
  if (communication.receiver1Ip2?.included) {
    setIncludedFields(
      setToggle(communicationReceiver1SecondIpAddressFieldId())
    );
  }
  if (communication.receiver1Port2?.included) {
    setIncludedFields(setToggle(communicationReceiver1SecondIpPortFieldId()));
  }
  if (communication.receiver1PrimaryPhoneNumber?.included) {
    setIncludedFields(
      setToggle(communicationReceiver1FirstPhoneNumberFieldId())
    );
  }
  if (communication.receiver1SecondaryPhoneNumber?.included) {
    setIncludedFields(
      setToggle(communicationReceiver1SecondPhoneNumberFieldId())
    );
  }
  if (communication.receiver2Backup?.included) {
    setIncludedFields(setToggle(communicationReceiver2BackupFieldId()));
  }
  if (communication.receiver2AlarmReports?.included) {
    setIncludedFields(setToggle(communicationReceiver2AlarmReportsFieldId()));
  }
  if (communication.receiver2SupervisoryTroubleReports?.included) {
    setIncludedFields(
      setToggle(communicationReceiver2SupervisoryTroubleReportsFieldId())
    );
  }
  if (communication.receiver2OpenCloseUserReports?.included) {
    setIncludedFields(
      setToggle(communicationReceiver2OpenCloseUserReportsFieldId())
    );
  }
  if (communication.receiver2TestReports?.included) {
    setIncludedFields(setToggle(communicationReceiver2TestReportsFieldId()));
  }
  if (communication.receiver2Ip?.included) {
    setIncludedFields(setToggle(communicationReceiver2FirstIpAddressFieldId()));
  }
  if (communication.receiver2Port?.included) {
    setIncludedFields(setToggle(communicationReceiver2FirstIpPortFieldId()));
  }
  if (communication.receiver2Ip2?.included) {
    setIncludedFields(
      setToggle(communicationReceiver2SecondIpAddressFieldId())
    );
  }
  if (communication.receiver2Port2?.included) {
    setIncludedFields(setToggle(communicationReceiver2SecondIpPortFieldId()));
  }
  if (communication.receiver2PrimaryPhoneNumber?.included) {
    setIncludedFields(
      setToggle(communicationReceiver2FirstPhoneNumberFieldId())
    );
  }
  if (communication.receiver2SecondaryPhoneNumber?.included) {
    setIncludedFields(
      setToggle(communicationReceiver2SecondPhoneNumberFieldId())
    );
  }
};
