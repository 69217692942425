import { defineMessages } from "react-intl";

export default defineMessages({
  failedToRetrieveSystemStatuses: {
    id: "app.containers.StatusWidget.failedToRetrieveSystemStatuses",
    defaultMessage: "Failed to retrieve system statuses.",
  },
  hasAlarm: {
    id: "app.containers.StatusWidget.hasAlarm",
    defaultMessage: "Fire alarm activated",
  },
  noAlarm: {
    id: "app.containers.StatusWidget.noAlarm",
    defaultMessage: "No alarms",
  },
  alarm: {
    id: "app.containers.StatusWidget.alarm",
    defaultMessage: "Alarm",
  },
  power: {
    id: "app.containers.StatusWidget.power",
    defaultMessage: "Power",
  },
  isAcNormal: {
    id: "app.containers.StatusWidget.isAcNormal",
    defaultMessage: "Power OK",
  },
  powerFailure: {
    id: "app.containers.StatusWidget.powerFailure",
    defaultMessage: "Power Failure",
  },
  hasBatteryTrouble: {
    id: "app.containers.StatusWidget.hasBatteryTrouble",
    defaultMessage: "Battery Trouble",
  },
  batteryOK: {
    id: "app.containers.StatusWidget.batteryOK",
    defaultMessage: "Battery OK",
  },
  batteryFailure: {
    id: "app.containers.StatusWidget.batteryFailure",
    defaultMessage: "Battery Trouble Detected",
  },
  trouble: {
    id: "app.containers.StatusWidget.trouble",
    defaultMessage: "Trouble",
  },
  noTroubles: {
    id: "app.containers.StatusWidget.noTroubles",
    defaultMessage: "No Troubles",
  },
  hasTrouble: {
    id: "app.containers.StatusWidget.hasTrouble",
    defaultMessage: "Troubles Detected",
  },
  supervisory: {
    id: "app.containers.StatusWidget.supervisory",
    defaultMessage: "Supervisory",
  },
  noSupervisoryTrouble: {
    id: "app.containers.StatusWidget.noSupervisoryTrouble",
    defaultMessage: "No Supervisory Troubles",
  },
  hasSupervisoryAlert: {
    id: "app.containers.StatusWidget.hasSupervisoryAlert",
    defaultMessage: "Supervisory Alert Detected",
  },
});
