/**
 * @generated SignedSource<<0b3618b504a0018120b05e79b3ab4944>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
export type CommunicationPathType = "BACKUP" | "PRIMARY";
import { FragmentRefs } from "relay-runtime";
export type XT75CommunicationProgrammingConceptForm_controlSystem$data = {
  readonly copiedCommPath: {
    readonly commType: CommunicationPathCommType;
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly communicationPaths: ReadonlyArray<{
      readonly accountNumber: string | null;
      readonly commType: CommunicationPathCommType;
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly type: CommunicationPathType;
      readonly " $fragmentSpreads": FragmentRefs<"XT75CommPathFields_communicationPath">;
    }>;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly maxCommunicationPaths: number;
    readonly minCommunicationPathNumber: number;
    readonly newCommunicationPath: {
      readonly accountNumber: string | null;
      readonly commType: CommunicationPathCommType;
      readonly id: string;
      readonly number: number;
      readonly transmissionDelay: number;
      readonly transmissionDelayValidValues: ReadonlyArray<number>;
      readonly type: CommunicationPathType;
      readonly " $fragmentSpreads": FragmentRefs<"XT75CommPathFields_communicationPath">;
    } | null;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"CommPathAccountNumberField_panel" | "CommPathCommTypeField_panel" | "CommPathNumberField_panel" | "CommPathTransmissionDelayField_panel" | "PanelContextUseCommPaths_panel" | "PanelContextUseFeatureKeyEnabled_panel" | "PanelContextUseHardwareModel_panel" | "PanelContextUseHasNetworkCommType_panel" | "PanelContextUseHasWifiCommType_panel" | "PanelContextUseSoftwareDate_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseVarEnabled_controlSystem" | "ControlSystemContext_controlSystem">;
  readonly " $fragmentType": "XT75CommunicationProgrammingConceptForm_controlSystem";
};
export type XT75CommunicationProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XT75CommunicationProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CommunicationProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "XT75CommPathFields_communicationPath"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75CommunicationProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minCommunicationPathNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxCommunicationPaths",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommPathNumberField_panel"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CommunicationPath",
          "kind": "LinkedField",
          "name": "communicationPaths",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isNew",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CommunicationPath",
          "kind": "LinkedField",
          "name": "newCommunicationPath",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transmissionDelay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transmissionDelayValidValues",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommPathCommTypeField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommPathTransmissionDelayField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommPathAccountNumberField_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseFeatureKeyEnabled_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseCommPaths_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasNetworkCommType_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHasWifiCommType_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareDate_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseVarEnabled_controlSystem"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommunicationPath",
          "kind": "LinkedField",
          "name": "copiedCommPath",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "f85b7ef0b6bc344615920b5249a365f5";

export default node;
