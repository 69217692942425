/**
 * @generated SignedSource<<eda0b7f131c2e0a0857e82e1992b63cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsBypassLimitField_systemOptions$data = {
  readonly bypassLimit?: number;
  readonly bypassLimitMax?: number;
  readonly bypassLimitMin?: number;
  readonly " $fragmentType": "SystemOptionsBypassLimitField_systemOptions";
};
export type SystemOptionsBypassLimitField_systemOptions$key = {
  readonly " $data"?: SystemOptionsBypassLimitField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsBypassLimitField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bypassLimit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bypassLimitMin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bypassLimitMax",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsBypassLimitField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Xt75SystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "727a73de3c33003ac2ab3a6cdbcaf8b2";

export default node;
