/**
 * @ngdoc service
 * @name App.factory:ControlSystemService
 *
 * @description
 * API Interface service for Control Systems.
 *
 */
App.factory("ControlSystemService", [
  "$q",
  "CustomerControlSystemsV2API",
  "ControlSystemsV2API",
  "ControlSystemsVideoDevicesV2API",
  "ImagesService",
  "ThirdPartyVideoService",
  "UserService",
  "PanelProgArchiveService",
  "DashboardDataService",
  function (
    $q,
    CustomerControlSystemsV2API,
    ControlSystemsV2API,
    ControlSystemsVideoDevicesV2API,
    ImagesService,
    ThirdPartyVideoService,
    UserService,
    PanelProgArchiveService,
    DashboardDataService
  ) {
    return {
      /**
       * @ngdoc object
       * @name method:create
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Creates a new control system using the 'create' API.
       *
       * @param {String} customer_id Customer ID number
       * @param {Object} body JSON body of new control system
       * @return {promise} The promise from the Control Systems API 'create'.
       */
      create: function (customer_id, body) {
        var deferred = $q.defer();
        var _this = this;
        CustomerControlSystemsV2API.create(
          { customer_id: customer_id }, //params
          body,
          function (data) {
            //success
            DashboardDataService.forceDashboardReload();
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:edit
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Returns an existing control system using the 'edit' API
       *
       * @param {String} customer_id Customer ID number
       * @param {String} control_system_id Control System ID
       * @return {promise} The promise from the Control Systems API 'edit'.
       */
      edit: function (customer_id, control_system_id) {
        var deferred = $q.defer();
        var _this = this;
        CustomerControlSystemsV2API.edit(
          { customer_id: customer_id, control_system_id: control_system_id }, //params
          function (data) {
            //success
            DashboardDataService.forceDashboardReload();
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:new
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Returns a blank control system template used to create a new control system
       *
       * @param {String} customer_id Customer ID number
       * @return {promise} The promise from the Control Systems API 'new'.
       */
      new: function (customer_id) {
        var deferred = $q.defer();
        var _this = this;
        CustomerControlSystemsV2API.new(
          { customer_id: customer_id }, //params
          function (data) {
            //success

            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            data.control_system.panels[0].account_prefix = "1";
            data.control_system.country = angular.copy(
              UserService?.customerInfo?.country || ""
            );

            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });

            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:update
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Updates an existing control system using the 'update' API.
       *
       * @param {String} customer_id Customer ID number
       * @param {Object} body JSON body of updated control system
       * @return {promise} The promise from the Control Systems API 'update'.
       */
      update: function (customer_id, control_system_id, body) {
        var deferred = $q.defer();
        var _this = this;
        CustomerControlSystemsV2API.update(
          { customer_id: customer_id, control_system_id: control_system_id }, //params
          body,
          function (data) {
            //success
            //DashboardDataService.getDealerMapData(_this.dealer_id, true)
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:destroy
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Removes the selected control system from the database.
       * Ancillary devices and data must be deleted before the system. This function attempts to clean up cameras,
       * system images, etc. Regardless of the result of the extra deletes, this function will attempt to delete
       * the system.
       *
       * @param {String} control_system_id
       * @return {promise} The promise from the Control Systems API 'destroy'.
       */
      destroy: function (control_system_id) {
        UserService.isBusy = true;
        var deferred = $q.defer();
        // Gather any needed data first
        var neededData = {
          promises: [],
          thirdPartyVideoDevices: [],
        };
        neededData.promises.push(
          ThirdPartyVideoService.getDevices(control_system_id)
            .then(
              function (thirdPartyVideoDevices) {
                neededData.thirdPartyVideoDevices = thirdPartyVideoDevices;
              },
              function (error) {}
            )
            .catch(function (error) {})
        );
        $q.all(neededData.promises)
          .then()
          .finally(function () {
            // Destroy things that must be deleted before the control system is removed from the database
            var destroyPromises = [];
            destroyPromises.push(
              ImagesService.ControlSystems.DestroyAll(control_system_id)
                .then(
                  function () {},
                  function (error) {}
                )
                .catch(function (error) {})
            );
            angular.forEach(
              neededData.thirdPartyVideoDevices,
              function (device) {
                if (device.device_type === "HIKVISION-DOORBELL") {
                  destroyPromises.push(
                    ThirdPartyVideoService.hikvisionDoorbells.destroy(
                      device.id,
                      control_system_id
                    )
                  );
                } else {
                  destroyPromises.push(
                    ThirdPartyVideoService.destroyDevice(device.id)
                  );
                }
              }
            );
            destroyPromises.push(
              PanelProgArchiveService.deleteBackups(control_system_id)
                .then(
                  function () {},
                  function (error) {}
                )
                .catch(function (error) {})
            );
            destroyPromises.push(
              PanelProgArchiveService.deleteSchedule(control_system_id)
                .then(
                  function () {},
                  function (error) {}
                )
                .catch(function (error) {})
            );
            $q.all(destroyPromises)
              .then()
              .finally(function () {
                // Finally, regardless of the result of the ancillary data deletes, delete the system.
                ControlSystemsV2API.destroy(
                  { control_system_id: control_system_id }, //params
                  function (data) {
                    //success
                    DashboardDataService.forceDashboardReload();
                    deferred.notify({
                      job_uuid: "n/a",
                      status: "success",
                      poll_count: 0,
                    });
                    UserService.isBusy = false;
                    deferred.resolve();
                  },
                  function (error) {
                    //failure
                    deferred.notify({
                      job_uuid: "n/a",
                      status: "error",
                      poll_count: 0,
                    });
                    UserService.isBusy = false;
                    deferred.reject(error);
                  },
                  function (info) {
                    //failure
                    deferred.notify(info);
                  }
                );
              });
          });
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:show
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Returns the selected control system from the database for view only.
       *
       * @param {String} control_system_id
       * @return {promise} The promise from the Control Systems API 'show'.
       */
      show: function (customer_id, control_system_id) {
        var deferred = $q.defer();
        var _this = this;
        ControlSystemsV2API.show(
          { control_system_id: control_system_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:video_verification_info
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Returns the base information for Video Verification.  Returns 404 if panel is not in alarm or VV is disabled
       * on Control System.
       *
       * @param {String} control_system_id
       * @return {promise} The promise from the Control Systems API 'show'.
       */
      video_verification_info: function (control_system_id) {
        var deferred = $q.defer();
        var _this = this;
        ControlSystemsV2API.video_verification_info(
          { control_system_id: control_system_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:getVideoDevices
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Returns the list of video devices for the control system.
       *
       * @return {promise} The promise from the Control Systems Video Devices API 'show'.
       */
      getVideoDevices: function (control_system_id) {
        isBusy = true;
        var deferred = $q.defer();
        var _this = this;
        ControlSystemsVideoDevicesV2API.show(
          { control_system_id: control_system_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            //Parse the array to make it easier to consume
            var video_devices = [];
            angular.forEach(data, function (item) {
              video_devices.push(item.video_device);
            });
            deferred.resolve(video_devices);
            isBusy = false;
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
            isBusy = false;
          },
          function (info) {
            //failure
            deferred.notify(info);
            isBusy = false;
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:newVideoDevices
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Returns a video device template for creating a new video device
       *
       * @return {promise} The promise from the Control Systems Video Devices API 'new'.
       */
      newVideoDevice: function (control_system_id) {
        var deferred = $q.defer();
        var _this = this;
        ControlSystemsVideoDevicesV2API.new(
          { control_system_id: control_system_id }, //params
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          },
          function (info) {
            //failure
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      /**
       * @ngdoc object
       * @name method:createVideoDevices
       * @methodOf App.factory:ControlSystemService
       *
       * @description
       * Save the specified video device
       *
       * @return {promise} The promise from the Control Systems Video Devices API 'save'.
       */
      createVideoDevice: function (control_system_id, body) {
        var deferred = $q.defer();
        var _this = this;
        ControlSystemsVideoDevicesV2API.save(
          { control_system_id: control_system_id }, //params
          angular.extend(body, { tz_sync: false }),
          function (data) {
            //success
            deferred.notify({
              job_uuid: "n/a",
              status: "success",
              poll_count: 0,
            });
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.notify({
              job_uuid: "n/a",
              status: "error",
              poll_count: 0,
            });
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
