/**
 * @generated SignedSource<<f317408d682c3bffac8f10c8dfedfcc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecureComDoorbellForm_site$data = {
  readonly hikvisionDoorbellTotal: number;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComDoorbells_site">;
  readonly " $fragmentType": "SecureComDoorbellForm_site";
};
export type SecureComDoorbellForm_site$key = {
  readonly " $data"?: SecureComDoorbellForm_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"SecureComDoorbellForm_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SecureComDoorbellForm_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hikvisionDoorbellTotal",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SecureComDoorbells_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "2e3c8cdf300d9404626c1c4ebb8a4c6e";

export default node;
