/**
 * @generated SignedSource<<1c74b175d0cd4ac78fcf0f3689144a21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseSoftwareDate_panel$data = {
  readonly softwareDate: string;
  readonly " $fragmentType": "PanelContextUseSoftwareDate_panel";
};
export type PanelContextUseSoftwareDate_panel$key = {
  readonly " $data"?: PanelContextUseSoftwareDate_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseSoftwareDate_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseSoftwareDate_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softwareDate",
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "75101393aa2b483f2b30697bea6f5f38";

export default node;
