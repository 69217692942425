import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsAllowNetworkRemoteField_remoteOptions$key } from "./__generated__/RemoteOptionsAllowNetworkRemoteField_remoteOptions.graphql";

export const remoteOptionsAllowNetworkRemoteFieldId = () =>
  "remote-options-allow-network-remote";

function RemoteOptionsAllowNetworkRemoteField() {
  const [{ allowNetworkRemote }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsAllowNetworkRemoteField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsAllowNetworkRemoteField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            allowNetworkRemote
          }
        }
      `
    );

  const fieldId = remoteOptionsAllowNetworkRemoteFieldId();
  const label = "Allow Network Remote";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables remote programming over the network."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={!!allowNetworkRemote}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(!allowNetworkRemote, "allowNetworkRemote")
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsAllowNetworkRemoteField;
