/**
 *
 * DeskIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DeskIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M64,37H32v-4h-9v-6h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5H9C6.243,0,4,2.243,4,5v17c0,2.757,2.243,5,5,5h10v6h-9
        v4H0v18h8v9h4v-9h40v9h4v-9h8V37z M9,23c-0.552,0-1-0.449-1-1V5c0-0.551,0.448-1,1-1h24c0.552,0,1,0.449,1,1v17
        c0,0.551-0.448,1-1,1H9z M60,51H4V41h56V51z"
          />
          <rect x="11" y="7" width="20" height="12" />
          <rect x="40" y="44" width="13" height="4" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default DeskIcon;
