/**
 * @generated SignedSource<<a41a2ec3e0acb65ce02d9e819bfd9681>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsArmAlarmOutputField_outputOptions$data = {
  readonly armAlarmOutput: {
    readonly formattedNumber: string;
    readonly id: string;
    readonly number: number;
  };
  readonly id: string;
  readonly " $fragmentType": "OutputOptionsArmAlarmOutputField_outputOptions";
};
export type OutputOptionsArmAlarmOutputField_outputOptions$key = {
  readonly " $data"?: OutputOptionsArmAlarmOutputField_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsArmAlarmOutputField_outputOptions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsArmAlarmOutputField_outputOptions",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "armAlarmOutput",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};
})();

(node as any).hash = "64fb7bd21d73d5979ab593452330d71d";

export default node;
