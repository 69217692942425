/**
 *
 * FavoriteIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const FavoriteIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M48.702,62.557c-1.05,0-2.062-0.329-2.926-0.952L32,51.603l-13.772,9.998c-1.708,1.243-4.139,1.246-5.854,0.001
        c-1.758-1.29-2.482-3.528-1.811-5.576l5.263-16.172l-13.77-9.997c-1.75-1.269-2.478-3.427-1.812-5.487
        C0.921,22.304,2.824,21,4.982,21h17.026l5.25-16.252C27.899,2.787,29.76,1.483,32,1.483c2.238,0,4.099,1.319,4.74,3.271
        L41.995,21h17.028c2.158,0,4.061,1.304,4.735,3.361c0.669,2.066-0.057,4.265-1.807,5.534l-13.771,9.979l5.256,16.172
        c0.669,2.056-0.059,4.287-1.812,5.56C50.758,62.232,49.749,62.557,48.702,62.557z M32,46.659l16.121,11.705
        c0.343,0.247,0.796,0.258,1.157-0.003c0.336-0.244,0.483-0.692,0.354-1.088l-6.154-18.948L59.602,26.62
        c0.338-0.246,0.483-0.618,0.353-1.02C59.823,25.197,59.447,25,59.023,25H39.088L32.937,5.977C32.835,5.667,32.379,5.483,32,5.483
        c-0.292,0-0.819,0.156-0.939,0.523L24.915,25H4.982c-0.424,0-0.8,0.197-0.935,0.607c-0.129,0.399,0.014,0.802,0.358,1.051
        L20.53,38.346l-6.165,18.932c-0.133,0.404,0.015,0.84,0.365,1.098c0.273,0.198,0.626,0.369,1.146-0.008L32,46.659z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default FavoriteIcon;
