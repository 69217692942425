/**
 * @generated SignedSource<<23234e2f94f69fad0f02eeaca4f808d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type XT75AreaInformationProgrammingConceptFormInline_area$data = {
  readonly __typename: string;
  readonly armedOutputNumber?: string;
  readonly autoArmEnabled: boolean;
  readonly autoDisarmEnabled: boolean;
  readonly badZonesOption: BadZonesOption;
  readonly burglaryBellOutputNumber?: string;
  readonly hasChanges: boolean;
  readonly id: string;
  readonly isNew: boolean;
  readonly lateArmDelay?: number;
  readonly lateOutputNumber?: string;
  readonly name: string;
  readonly number: string;
  readonly openClosingReports?: boolean;
  readonly " $fragmentType": "XT75AreaInformationProgrammingConceptFormInline_area";
};
export type XT75AreaInformationProgrammingConceptFormInline_area$key = {
  readonly " $data"?: XT75AreaInformationProgrammingConceptFormInline_area$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75AreaInformationProgrammingConceptFormInline_area">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75AreaInformationProgrammingConceptFormInline_area"
};

(node as any).hash = "15bbe433714ccdc03c40c874f07f7535";

export default node;
