import React, { useRef, useLayoutEffect } from "react";
import ReactDOM from "react-dom";

export default function Portal({
  element,
  root = document.body,
  children,
}: {
  element?: Element | null;
  root?: Element;
  children: React.ReactNode;
}) {
  const elementRef = useRef(element || document.createElement("div"));

  useLayoutEffect(() => {
    const { current } = elementRef;

    if (!root.contains(current)) {
      root.appendChild(current);
      return () => {
        root.removeChild(current);
      };
    }
  }, []);

  return ReactDOM.createPortal(children, elementRef.current);
}
