/**
 * @ngdoc service
 * @name App.factory:SignInAPI
 * @function login Authenticate the user through the VK API
 * @requires angular:$resource
 * @requires PROPS
 *
 * @description
 * Service to sign in the user
 * @return {promise} A promise, which will eventually turn into a data response. Must be handled with success/failure blocks.
 */
App.factory("SignInAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/users/sign_in",
      {},
      {
        login: { method: "POST", params: { user: "@user" } },
      }
    );
  },
]);
