import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsCommunicationFailOutputField_outputOptions$key } from "./__generated__/OutputOptionsCommunicationFailOutputField_outputOptions.graphql";

export const outputOptionsCommunicationFailOutputFieldId = () =>
  `output-options-communication-fail-output`;

function OutputOptionsCommunicationFailOutputField() {
  const [{ communicationFailOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsCommunicationFailOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsCommunicationFailOutputField_outputOptions on OutputOptions {
          id
          communicationFailOutput {
            id
            formattedNumber
            number
          }
        }
      `
    );

  const fieldId = outputOptionsCommunicationFailOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs

  const { pattern, inlineHelp, validationMessage } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const { current: originalValue } = React.useRef(
    communicationFailOutput.formattedNumber
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Communication Fail Output"
      tooltip="This output will be turned on when a communication failure condition occurs."
    >
      <TextInput
        id={fieldId}
        value={communicationFailOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "communicationFailOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "communicationFailOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsCommunicationFailOutputField;
