import { Flex } from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState from "components/DaStyledElements/Paginator/usePaginationState";
import React from "react";
import styled from "styled-components/macro";

interface System {
  controlSystemId: string;
  customerName: string;
  controlSystemName: string;
}

interface SystemsPanelProps {
  selectedSystemsList: System[];
  areAnySystemsSelected: boolean;
}

const SystemsPanel: React.FC<SystemsPanelProps> = ({
  selectedSystemsList,
  areAnySystemsSelected,
}) => {
  const [paginationState, dispatch] = usePaginationState({
    pageSize: 25,
    currentPage: 1,
    search: "",
  });

  const searchedSystems = React.useMemo(() => {
    const searchLower = paginationState.search.toLowerCase();
    return selectedSystemsList.filter((customer) =>
      customer.customerName.toLowerCase().includes(searchLower)
    );
  }, [selectedSystemsList, paginationState.search]);

  const sortedSystems = React.useMemo(() => {
    return searchedSystems.sort((a, b) =>
      a.customerName.localeCompare(b.customerName)
    );
  }, [searchedSystems]);

  const pagedSystems = React.useMemo(() => {
    const startIndex =
      (paginationState.currentPage - 1) * paginationState.pageSize;
    const endIndex = startIndex + paginationState.pageSize;
    return sortedSystems.slice(startIndex, endIndex);
  }, [sortedSystems, paginationState.currentPage, paginationState.pageSize]);

  const maxPage = Math.ceil(sortedSystems.length / paginationState.pageSize);

  return (
    <div className="panel">
      <div className="flex-list__item flex-list__item--header">
        <span className="flex-list__item__header-text">Customer</span>
        <span className="flex-list__item__header-text">System</span>
      </div>
      <div className="panel-body">
        {areAnySystemsSelected ? (
          <FixedSizeList>
            <ul className="pad-0 no-m-block-end" style={{ width: "100%" }}>
              {pagedSystems.map((system) => (
                <li className="flex-list__item" key={system.controlSystemId}>
                  <span className="flex-list__item__body-text">
                    <strong>{system.customerName}</strong>
                  </span>
                  <span className="flex-list__item__body-text">
                    {system.controlSystemName}
                  </span>
                </li>
              ))}
            </ul>
          </FixedSizeList>
        ) : (
          <span className="flex-list__item__header-text">
            No systems have been selected
          </span>
        )}
        {selectedSystemsList.length >= 10 && (
          <div className="panel-footer" style={{ backgroundColor: "white" }}>
            <Flex.Row justify="center" align="center">
              <Paginate.Paginator
                state={paginationState}
                dispatch={dispatch}
                maxPage={maxPage}
              />
            </Flex.Row>
          </div>
        )}
      </div>
    </div>
  );
};

const FixedSizeList = styled.div`
  position: relative;
  max-height: 45rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export default SystemsPanel;
