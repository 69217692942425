/**
 * @ngdoc directive
 * @name App.directive:daSlidingMenu
 *
 * @description
 *   returns a sliding menu that nests inside
 *   parent element
 *
 */

App.directive("daSlidingMenu", function () {
  return {
    restrict: "E",

    require: "da-slider-menu-toggle",

    transclude: true,

    templateUrl: "app/common/templates/sliding-menu.html",

    scope: {
      toggleTitle: "@",
    },
  };
});

App.directive("daSlidingTechTrackMenu", function () {
  return {
    restrict: "E",

    require: "da-slider-menu-toggle",

    transclude: true,

    templateUrl: "app/common/templates/sliding-menu-tech-track.html",

    scope: {
      toggleTitle: "@",
    },
  };
});
