import DaColors from "app/common/DaColors";
import React, { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

const RoundedCorners = styled.div`
  overflow-y: hidden;
  border-radius: 0.4rem;
  border: 0.1rem solid ${DaColors.Neutral450};
`;
const Header = styled.div`
  padding: 2.4rem;
`;
const TableProper = styled.table`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;

  & thead {
    & tr {
      min-height: 4.4rem;
      background: ${DaColors.White};

      & th {
        padding: 1.6rem 2.4rem;
        font-size: 1.4rem;
        white-space: nowrap;
        font-weight: normal;
        color: ${DaColors.Neutral20};
      }
    }
  }

  & tbody {
    & tr {
      min-height: 4.4rem;
      background: ${DaColors.Neutral250};

      &:nth-child(even) {
        background: ${DaColors.White};
      }

      &:hover {
        background: ${DaColors.Neutral50};
      }

      & td {
        padding: 1.6rem 2.4rem;
        font-size: 1.4rem;
        white-space: nowrap;
      }
    }
  }

  @media screen and (min-width: 768px) {
    & > thead > tr > th,
    & > tbody > tr > td {
      white-space: normal;
    }
  }
`;
const Footer = styled.div`
  padding: 2.4rem;
`;

type Props = {
  header?: ReactNode;
  footer?: ReactNode;
};

const CleanTable = ({ header, children, footer }: PropsWithChildren<Props>) => {
  return (
    <RoundedCorners>
      {header ? <Header>{header}</Header> : null}
      <TableProper>{children}</TableProper>
      {footer ? <Footer>{footer}</Footer> : null}
    </RoundedCorners>
  );
};

export default CleanTable;
