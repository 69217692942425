/**
 * @generated SignedSource<<0d2ccbcd05c13e75747c402d98e97ba7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsEnhancedZoneTestField_systemOptions$data = {
  readonly enhancedZoneTest?: boolean;
  readonly " $fragmentType": "SystemOptionsEnhancedZoneTestField_systemOptions";
};
export type SystemOptionsEnhancedZoneTestField_systemOptions$key = {
  readonly " $data"?: SystemOptionsEnhancedZoneTestField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsEnhancedZoneTestField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsEnhancedZoneTestField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enhancedZoneTest",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "56e0ba2427bfbddb112c7a20c71de123";

export default node;
