/**
 * @generated SignedSource<<bd57afe7b5b492c642ccd753f5cc3b2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseHas1100T_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly deviceType?: DeviceInformationType;
    readonly wirelessTranslator1100t?: boolean;
  }>;
  readonly " $fragmentType": "PanelContextUseHas1100T_panel";
};
export type PanelContextUseHas1100T_panel$key = {
  readonly " $data"?: PanelContextUseHas1100T_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHas1100T_panel">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseHas1100T_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wirelessTranslator1100t",
              "storageKey": null
            }
          ],
          "type": "XtDeviceInformation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "XrDeviceInformation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Xt75DeviceInformation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "24780e3366750e7971f76e359c07fb13";

export default node;
