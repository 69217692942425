/**
 * @generated SignedSource<<3bc5b7ad058e6f6717d7734936b4a741>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay$data = {
  readonly emergencyZoneKeypads: string;
  readonly " $fragmentType": "StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay";
};
export type StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay$key = {
  readonly " $data"?: StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusListDisplayEmergencyZoneKeypadsField_statusListDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emergencyZoneKeypads",
      "storageKey": null
    }
  ],
  "type": "StatusListDisplay",
  "abstractKey": null
};

(node as any).hash = "e577c47c9582aebeaeee9b6973410e48";

export default node;
