/**
 * @ngdoc service
 * @name App.factory:JobService
 *
 * @description
 * Manages long running jobs submitted to the VK Server
 *
 */
App.factory("JobService", [
  "$rootScope",
  "$q",
  "JobsAPIV2",
  "PROPS",
  "$filter",
  "UserService",
  "JobsAPIV1",
  function ($rootScope, $q, JobsAPIV2, PROPS, $filter, UserService, JobsAPIV1) {
    return {
      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:JobService
       *
       * @description
       * Returns a new Deferred Promise and adds to the 'promises' array.  Calls itself recursively until the job is
       * complete, fails, or times out.  Notification promise events are also propagated up the promise chain.
       *
       * @param {String} jobUUID The job number linked to the requested job.
       * @param {Number} maxCount Optional Override the max number of requests for the status of the current job
       * @param {Number} count The current iteration
       * @param {promise} deferred Passed in on recursive calls to the process function.  Created new on the first call.
       * @return {promise} The promise from the Jobs API 'Show'.
       */
      process: function (jobUUID, maxCount, count, deferred) {
        deferred = typeof deferred === "undefined" ? $q.defer() : deferred;
        var _this = this;
        // Set maxCount to 0 if it's not being passed in. This signifies the max number of requests.
        maxCount =
          typeof maxCount === "undefined" ? PROPS.pollMaxTimes : maxCount;
        if (typeof count === "undefined") count = 0;

        JobsAPIV2.show(
          { job_number: jobUUID }, // Param
          function (data) {
            // Successful Request
            if (!data.job) {
              //if unknown structure is returned.
              deferred.notify({
                job_uuid: jobUUID,
                status: "Unknown",
                poll_count: count,
              });
              deferred.reject(data);
              return;
            }
            if (angular.equals(data.job.status, "success")) {
              // returned and status is 'success'
              if (data.job.details) {
                deferred.notify({
                  job_uuid: jobUUID,
                  status: data.job.status,
                  poll_count: count,
                  details: data.job.details,
                });
              } else {
                deferred.notify({
                  job_uuid: jobUUID,
                  status: data.job.status,
                  poll_count: count,
                });
              }
              deferred.resolve(data); //In some cases, this return contains arming data //TODO: Ask Devin if this is intentional?
              return;
            } else if (angular.equals(data.job.status, "error")) {
              // If the request was successful, but returned an error response, treat it as an error
              if (data.job.details) {
                deferred.notify({
                  job_uuid: jobUUID,
                  status: data.job.status,
                  poll_count: count,
                  details: data.job.details,
                });
              } else {
                deferred.notify({
                  job_uuid: jobUUID,
                  status: data.job.status,
                  poll_count: count,
                });
              }
              deferred.reject(data);
              return;
            }
            // Tick this many times
            if (count++ < maxCount) {
              setTimeout(function () {
                if (data.job.details) {
                  deferred.notify({
                    job_uuid: jobUUID,
                    status: data.job.status,
                    poll_count: count,
                    details: data.job.details,
                  });
                } else {
                  deferred.notify({
                    job_uuid: jobUUID,
                    status: data.job.status,
                    poll_count: count,
                  });
                }
                _this.process(jobUUID, maxCount, count, deferred);
              }, PROPS.pollWaitTime);
            } else {
              if (data.job.details) {
                deferred.notify({
                  job_uuid: jobUUID,
                  status: "error",
                  poll_count: count,
                  details: data.job.details,
                });
              } else {
                deferred.notify({
                  job_uuid: jobUUID,
                  status: "error",
                  poll_count: count,
                });
              }
              deferred.reject($filter("translate")("api.JOB_SERVICE_TIMEOUT")); //Process has timed out.  Returns i18n
            }
          },
          function (error) {
            // Failed Request
            deferred.notify({
              job_uuid: jobUUID,
              status: "error",
              poll_count: count,
            });
            deferred.reject(error);
          },
          function (info) {
            //Notification
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
      processV1: function (jobUUID, maxCount, count, deferred) {
        deferred = typeof deferred === "undefined" ? $q.defer() : deferred;
        var _this = this;
        // Set maxCount to 0 if it's not being passed in. This signifies the max number of requests.
        maxCount =
          typeof maxCount === "undefined" ? PROPS.pollMaxTimes : maxCount;
        if (typeof count === "undefined") count = 0;

        JobsAPIV1.show(
          { job_number: jobUUID, auth_token: UserService.auth_token }, // Param
          function (data) {
            // Successful Request
            if (!data.status) {
              //if unknown structure is returned.
              deferred.notify({
                job_uuid: jobUUID,
                status: "Unknown",
                poll_count: count,
              });
              deferred.reject(data);
              return;
            }
            if (angular.equals(data.status, "success")) {
              // returned and status is 'success'
              deferred.notify({
                job_uuid: jobUUID,
                status: data.status,
                poll_count: count,
              });
              deferred.resolve(data); //In some cases, this return contains arming data //TODO: Ask Devin if this is intentional?
              return;
            } else if (angular.equals(data.status, "error")) {
              // If the request was successful, but returned an error response, treat it as an error
              deferred.notify({
                job_uuid: jobUUID,
                status: data.status,
                poll_count: count,
              });
              deferred.reject(data);
              return;
            }
            // Tick this many times
            if (count++ < maxCount) {
              setTimeout(function () {
                deferred.notify({
                  job_uuid: jobUUID,
                  status: data.status,
                  poll_count: count,
                });
                _this.processV1(jobUUID, maxCount, count, deferred);
              }, PROPS.pollWaitTime);
            } else {
              deferred.notify({
                job_uuid: jobUUID,
                status: "error",
                poll_count: count,
              });
              deferred.reject($filter("translate")("api.JOB_SERVICE_TIMEOUT")); //Process has timed out.  Returns i18n
            }
          },
          function (error) {
            // Failed Request
            deferred.notify({
              job_uuid: jobUUID,
              status: "error",
              poll_count: count,
            });
            deferred.reject(error);
          },
          function (info) {
            //Notification
            deferred.notify(info);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
