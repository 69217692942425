/**
 * @generated SignedSource<<315067ff9726560418981fc9f01d2b75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArmingWidget_controlSystem$data = {
  readonly id: string;
  readonly isAPSystem: boolean;
  readonly isAreaSystem: boolean;
  readonly isArmed: boolean;
  readonly isDSC: boolean;
  readonly isECP: boolean;
  readonly isHASystem: boolean;
  readonly isHSAGSystem: boolean;
  readonly isHSASystem: boolean;
  readonly isInAlarm: boolean;
  readonly isSingleAreaSystem: boolean;
  readonly isTMSentry: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"AllPerimeterArming_controlSystem" | "AreaArming_controlSystem" | "BadZonesAlert_controlSystem" | "HomeAwayArming_controlSystem" | "HomeSleepAwayArming_controlSystem" | "SingleAreaArming_controlSystem">;
  readonly " $fragmentType": "ArmingWidget_controlSystem";
};
export type ArmingWidget_controlSystem$key = {
  readonly " $data"?: ArmingWidget_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArmingWidget_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArmingWidget_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArmed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInAlarm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isECP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDSC",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSingleAreaSystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHSASystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHSAGSystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHASystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAreaSystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAPSystem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTMSentry",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SingleAreaArming_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AreaArming_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AllPerimeterArming_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeAwayArming_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HomeSleepAwayArming_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BadZonesAlert_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "4ab272445f7d7eeb08561ed9ab3cd360";

export default node;
