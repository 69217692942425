/**
 * @generated SignedSource<<27a3e26bfd47dbb6920206026a5cdc14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRLockoutCodeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly lockoutCode: {
      readonly code: string;
      readonly id: string;
    } | null;
  };
  readonly " $fragmentType": "XRLockoutCodeProgrammingConceptFormInline_controlSystem";
};
export type XRLockoutCodeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRLockoutCodeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRLockoutCodeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRLockoutCodeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "5caf623178bb711618965ead749f0999";

export default node;
