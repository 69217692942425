/**
 * @generated SignedSource<<585057251d549b733d8ac9c0d804429c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewIntegrationForm_Query$data = {
  readonly dealer: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"FormIntegrationBaseFragment">;
  readonly " $fragmentType": "NewIntegrationForm_Query";
};
export type NewIntegrationForm_Query$key = {
  readonly " $data"?: NewIntegrationForm_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewIntegrationForm_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "dealerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewIntegrationForm_Query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormIntegrationBaseFragment"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "dealerId"
        }
      ],
      "concreteType": "Dealer",
      "kind": "LinkedField",
      "name": "dealer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "87b6eddb1ea703fced2c68f4586cc142";

export default node;
