import graphql from "babel-plugin-relay/macro";
import { GenericConfirmModal } from "components/Modal/ConfirmModal";
import Select from "components/Select";
import { useShowAlert } from "contexts/AlertsContext";
import moment from "moment";
import React from "react";
import { useMutation } from "react-relay";
import {
  asID,
  fromGlobalHolidayDateId,
  fromHolidayDateId,
  HolidayDateClass,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import { SchedulesHolidayDatesModalCreateMutation } from "./__generated__/SchedulesHolidayDatesModalCreateMutation.graphql";
import { SchedulesHolidayDatesModalUpdateMutation } from "./__generated__/SchedulesHolidayDatesModalUpdateMutation.graphql";

interface SchedulesHolidayDatesModalProps {
  closeModal: () => void;
  systemId: number;
  holidaySelectionList: holidayDateListItem[];
  isNew?: boolean;
}
function SchedulesHolidayDatesModal(props: SchedulesHolidayDatesModalProps) {
  const { closeModal, systemId, holidaySelectionList, isNew = false } = props;
  const [createHolidayDate, creatingHolidayDate] =
    useMutation<SchedulesHolidayDatesModalCreateMutation>(createMutation);
  const [updateHolidayDate, updatingHolidayDate] =
    useMutation<SchedulesHolidayDatesModalUpdateMutation>(updateMutation);
  const showAlert = useShowAlert();
  const formRef = React.useRef<HTMLFormElement>(null);

  // If isNew, set empty holiday else set to the holiday being editted
  const [selectedHoliday, setSelectedHoliday] =
    React.useState<holidayDateListItem>(
      isNew
        ? {
            clazz: HolidayDateClass.A,
            date: "",
            description: "",
            id: "",
            name: "",
            globalHolidayId: null,
          }
        : holidaySelectionList[0]
    );

  const holidayMap = React.useMemo(
    () =>
      holidaySelectionList.reduce(
        (acc: Map<string, holidayDateListItem>, holiday) => {
          acc.set(holiday.id, holiday);
          return acc;
        },
        new Map<string, holidayDateListItem>()
      ),
    holidaySelectionList
  );
  const handleHolidayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHoliday = holidayMap.get(event.target.value);

    if (selectedHoliday) {
      setSelectedHoliday(selectedHoliday);
    }
  };
  const handleClassChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as HolidayDateClass;
    setSelectedHoliday((prevState) => ({
      ...prevState,
      clazz: value,
    }));
  };
  const handleSave = () => {
    // Check if form is valid before saving
    if (!!formRef.current && formRef.current.reportValidity()) {
      if (isNew) {
        const { dealerId } = fromGlobalHolidayDateId(asID(selectedHoliday.id));
        createHolidayDate({
          variables: {
            input: {
              panelId: Number(systemId),
              dealerId: Number(dealerId),
              description: selectedHoliday.description,
              name: selectedHoliday.name,
              clazz: selectedHoliday.clazz as HolidayDateClass,
              date: moment(selectedHoliday.date).toISOString(),
              globalHolidayId: selectedHoliday.globalHolidayId,
            },
          },
          onCompleted: (res) => {
            if (res.createHolidayDate.errorMessage) {
              showAlert({
                type: "error",
                text: res.createHolidayDate.errorMessage,
              });
            } else {
              showAlert({
                type: "success",
                text: "Successfully associated the Global Holiday.",
              });
            }
            closeModal();
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "An unknown error has occurred while attempting to associate the Global Holiday.",
            });
          },
        });
      } else {
        const { number } = fromHolidayDateId(asID(selectedHoliday.id));
        updateHolidayDate({
          variables: {
            input: {
              panelId: Number(systemId),
              number: number,
              description: selectedHoliday.description,
              name: selectedHoliday.name,
              clazz: selectedHoliday.clazz,
              date: moment(selectedHoliday.date).toISOString(),
              globalHolidayId: selectedHoliday.globalHolidayId,
            },
          },
          onCompleted: (res) => {
            if (res.updateHolidayDate.errorMessage) {
              showAlert({
                type: "error",
                text: res.updateHolidayDate.errorMessage,
              });
            } else {
              showAlert({
                type: "success",
                text: "Successfully updated the holiday.",
              });
            }
            closeModal();
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "An unknown error has occurred while attempting to update the holiday.",
            });
          },
        });
      }
    }
  };
  const classSelectionList: HolidayDateClass[] = [
    HolidayDateClass.A,
    HolidayDateClass.B,
    HolidayDateClass.C,
  ];
  const pending = updatingHolidayDate || creatingHolidayDate;

  return (
    <GenericConfirmModal
      header={`${isNew ? "New" : "Edit"} Holiday Date`}
      confirmText={"Save"}
      cancelText={"Cancel"}
      actionPending={pending}
      pendingText={"Saving..."}
      onConfirm={handleSave}
      onCancel={closeModal}
      icon={null}
    >
      <form ref={formRef} className="form-horizontal">
        <label className="col-sm-3 control-label" htmlFor="holiday-date">
          Select Holiday <span className="text-danger">*</span>
        </label>
        <FieldContainer>
          <Select
            id="holiday-date"
            disabled={pending || !isNew}
            onChange={handleHolidayChange}
            value={selectedHoliday.id}
            required
          >
            {!selectedHoliday.id ? (
              <Select.Option value="" disabled>
                Select Holiday
              </Select.Option>
            ) : null}
            {holidaySelectionList.map((holiday) => (
              <Select.Option key={holiday.id} value={holiday.id}>
                {`${moment(holiday.date).format("MM/DD")} - ${holiday.name}`}
              </Select.Option>
            ))}
          </Select>
        </FieldContainer>
        <label className="col-sm-3 control-label" htmlFor="holiday-class">
          Class <span className="text-danger">*</span>
        </label>
        <FieldContainer>
          <Select
            id="holiday-class"
            disabled={pending || !selectedHoliday.id}
            onChange={handleClassChange}
            value={selectedHoliday.clazz}
            required
          >
            {classSelectionList.map((classSelection) => (
              <Select.Option key={classSelection} value={classSelection}>
                {classSelection}
              </Select.Option>
            ))}
          </Select>
        </FieldContainer>
      </form>
    </GenericConfirmModal>
  );
}

const createMutation = graphql`
  mutation SchedulesHolidayDatesModalCreateMutation(
    $input: CreateHolidayDateInput!
  ) {
    createHolidayDate(input: $input) {
      ... on CreateHolidayDateSuccessResponse {
        controlSystem {
          holidayDates {
            id
            name
            date
            description
            class
            isGlobalHoliday
            globalHolidayId
          }
        }
      }
      ... on CreateHolidayDateErrorResponse {
        errorMessage
      }
    }
  }
`;
const updateMutation = graphql`
  mutation SchedulesHolidayDatesModalUpdateMutation(
    $input: UpdatePanelHolidayDateInput!
  ) {
    updateHolidayDate(input: $input) {
      ... on UpdatePanelHolidayDateSuccessResponse {
        controlSystem {
          holidayDates {
            id
            name
            date
            description
            class
            isGlobalHoliday
            globalHolidayId
          }
        }
      }
      ... on UpdatePanelHolidayDateErrorResponse {
        errorMessage
      }
    }
  }
`;

const FieldContainer = styled.div`
  display: flex;
  margin: 0 2rem 2rem 0;
`;

export type holidayDateListItem = {
  clazz: HolidayDateClass;
  date: string;
  description: string;
  id: string;
  name: string;
  globalHolidayId: number | null;
};

export default SchedulesHolidayDatesModal;
