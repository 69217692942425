/**
 * @generated SignedSource<<d95901aa016e2ef6a18756ba87c626ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationReceiver2AlarmReportsField_communication$data = {
  readonly receiver2AlarmReports: boolean;
  readonly receiver2Backup: boolean;
  readonly " $fragmentType": "CommunicationReceiver2AlarmReportsField_communication";
};
export type CommunicationReceiver2AlarmReportsField_communication$key = {
  readonly " $data"?: CommunicationReceiver2AlarmReportsField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationReceiver2AlarmReportsField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationReceiver2AlarmReportsField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2Backup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiver2AlarmReports",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "07aca1e8c8b53b55ffbed7e74449cc3c";

export default node;
