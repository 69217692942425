/**
 * @generated SignedSource<<42fe2f27d16f692b7f30407074e25831>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunicationTransmissionDelayField_communication$data = {
  readonly transmissionDelay: number;
  readonly transmissionDelayValidValues: ReadonlyArray<number>;
  readonly " $fragmentType": "CommunicationTransmissionDelayField_communication";
};
export type CommunicationTransmissionDelayField_communication$key = {
  readonly " $data"?: CommunicationTransmissionDelayField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationTransmissionDelayField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationTransmissionDelayField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transmissionDelay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transmissionDelayValidValues",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "15a1825eb091424c85d42f7810e7cb5b";

export default node;
