import React, { createContext, useContext, useState } from "react";
import styles from "./Accordion.module.css";

function not(value: boolean) {
  return !value;
}

const AccordionContext = createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => false]);

export function Accordion({
  header,
  children,
  initiallyOpen,
  forceOpen,
}: {
  header: React.ReactNode;
  children: React.ReactNode;
  initiallyOpen?: boolean;
  forceOpen?: boolean;
}) {
  const [open, setOpen] = useState(initiallyOpen || false);
  return (
    <AccordionContext.Provider value={[forceOpen || open, setOpen]}>
      <AccordionEntryPoint header={header}>{children}</AccordionEntryPoint>
    </AccordionContext.Provider>
  );
}

export const useAccordionState = () => useContext(AccordionContext);

function AccordionEntryPoint({
  header,
  children,
}: {
  header: React.ReactNode;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useAccordionState();

  return (
    <div className={styles.accordion}>
      <section>
        <header className={styles["header"]}>
          {header}

          <button
            type="button"
            className={styles["toggle"]}
            onClick={() => void setOpen(not)}
            style={{ whiteSpace: "nowrap" }}
          >
            <em
              className={open ? "icon-dmp icon-subtract" : "icon-dmp icon-add"}
            />
          </button>
        </header>

        {open && <article className="pad-20 panel-content">{children}</article>}
      </section>
    </div>
  );
}

Accordion.Toggleable = function AccordionToggleable({
  ...props
}: React.HTMLProps<HTMLButtonElement>) {
  const [, setOpen] = useAccordionState();

  return (
    <button
      {...props}
      type="button"
      onClick={() => {
        setOpen(not);
      }}
      className={styles["toggleable"]}
    />
  );
};

Accordion.Header = function AccordionHeader(
  props: React.HTMLProps<HTMLDivElement>
) {
  return <div {...props} className={styles["header-content"]} />;
};

Accordion.ToggleableHeader = function AccordionHeaderToggle(
  props: React.HTMLProps<HTMLDivElement>
) {
  return (
    <Accordion.Toggleable>
      <Accordion.Header {...props} />
    </Accordion.Toggleable>
  );
};

export default Accordion;
