/**
 * @generated SignedSource<<05c0eb049c45bb53b33cbc4752b62f0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathCommType = "CELL" | "CID" | "DD" | "NETWORK" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommPathReceiverPortField_communicationPath$data = {
  readonly commType: CommunicationPathCommType;
  readonly number: number;
  readonly receiverPort: string;
  readonly " $fragmentType": "CommPathReceiverPortField_communicationPath";
};
export type CommPathReceiverPortField_communicationPath$key = {
  readonly " $data"?: CommPathReceiverPortField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathReceiverPortField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathReceiverPortField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiverPort",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "81ab5b14c1565c80af7ad363dfcbcb70";

export default node;
