import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsUseFalseAlarmQuestionField_systemOptions$key } from "./__generated__/SystemOptionsUseFalseAlarmQuestionField_systemOptions.graphql";

export const systemOptionsUseFalseAlarmQuestionFieldId = () =>
  "system-options-use-false-alarm-question";

function UseFalseAlarmQuestionField() {
  const [{ useFalseAlarmQuestion }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsUseFalseAlarmQuestionField_systemOptions$key>(
      graphql`
        fragment SystemOptionsUseFalseAlarmQuestionField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            useFalseAlarmQuestion
          }
          ... on XtSystemOptions {
            useFalseAlarmQuestion
          }
          ... on Xt75SystemOptions {
            useFalseAlarmQuestion
          }
          ... on TMSentrySystemOptions {
            useFalseAlarmQuestion
          }
        }
      `
    );

  const fieldId = systemOptionsUseFalseAlarmQuestionFieldId();
  const label = "Use False Alarm Question";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="If enabled, the question 'Is this a False Alarm? Yes / No' replaces the Cancel / Verify dialogue."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={useFalseAlarmQuestion}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              !useFalseAlarmQuestion,
              "useFalseAlarmQuestion"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default UseFalseAlarmQuestionField;
