import { Coordinates } from "components/CameraEditCommon/types";
import {
  CoordinateRenderer,
  getEdgePoints,
} from "components/CameraEditCommon/utils";
import React from "react";
import { MotionDetectionRegionCoordinates } from "securecom-graphql/client";
import styled from "styled-components";

interface MotionDetectionRegionProps {
  color: string;
  index: number;
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
  coordinates: MotionDetectionRegionCoordinates[];
  activeCoordinateIndexes: number[];
  isActive: boolean;
  sensitivityLevel: number;
  mouseDown: boolean | null;
}
function MotionDetectionRegion(props: MotionDetectionRegionProps) {
  const {
    color,
    index,
    renderX,
    renderY,
    coordinates,
    activeCoordinateIndexes,
    isActive,
    sensitivityLevel,
    mouseDown,
  } = props;
  const coordinatesToRender = coordinates.map(({ x, y }) => [x, y]);
  const renderedPoints = coordinates.map(({ x, y }) => [
    renderX(x),
    renderY(y),
  ]);

  return (
    <g>
      <RegionPath
        id={`motion-detection-region-${index}`}
        fill={color}
        opacity={sensitivityLevel / 150 + 0.2}
        d={`${renderedPoints.reduce((d, [x, y], index) => {
          return index === 0 ? `M ${x} ${y}` : `${d} L ${x} ${y}`;
        }, "")} Z`}
        isActive={isActive}
      />
      {isActive && (
        <>
          {getEdgePoints(coordinatesToRender as Coordinates[]).map(
            ([point1, point2], edgeIndex) => (
              <Edge
                key={`edge-${edgeIndex}`}
                point1={point1}
                point2={point2}
                index={edgeIndex}
                regionIndex={index}
                renderX={renderX}
                renderY={renderY}
              />
            )
          )}
          {renderedPoints.map(([x, y], pointIndex) => (
            <CornerPoint
              key={`corner-${pointIndex}`}
              x={x}
              y={y}
              active={new Set(activeCoordinateIndexes).has(pointIndex)}
              index={pointIndex}
              regionIndex={index}
              mouseDown={mouseDown}
            />
          ))}
        </>
      )}
    </g>
  );
}

export default MotionDetectionRegion;

const RegionPath = styled.path<{ isActive: boolean }>`
  cursor: ${({ isActive }) => (isActive ? "move" : "default")};
`;

function CornerPoint(props: {
  x: number;
  y: number;
  active?: boolean;
  index: number;
  regionIndex: number;
  mouseDown: boolean | null;
}) {
  const { x, y, active, index, regionIndex, mouseDown } = props;
  return (
    <g>
      <circle
        r={5}
        cx={x}
        cy={y}
        fill={active ? "blue" : "white"}
        stroke="blue"
        strokeWidth={2}
      />
      <circle
        id={`motion-detection-region-${regionIndex}-point-${index}`}
        r={10}
        cx={x}
        cy={y}
        fill="transparent"
        style={{ cursor: mouseDown ? "grabbing" : "grab" }}
      />
    </g>
  );
}

function Edge(props: {
  point1: Coordinates;
  point2: Coordinates;
  index: number;
  regionIndex: number;
  renderX: CoordinateRenderer;
  renderY: CoordinateRenderer;
}) {
  const { point1, point2, index, regionIndex, renderX, renderY } = props;
  const isHorizontalEdge = point1[1] === point2[1];
  const cursorStyle = isHorizontalEdge ? "ns-resize" : "ew-resize";
  const [x1, y1] = point1;
  const [x2, y2] = point2;

  return (
    <>
      <line
        id={`motion-detection-region-${regionIndex}-edge-${index}`}
        x1={renderX(x1)}
        y1={renderY(y1)}
        x2={renderX(x2)}
        y2={renderY(y2)}
        strokeWidth={25}
        stroke="transparent"
        style={{ cursor: cursorStyle }}
      />
    </>
  );
}
