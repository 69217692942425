import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { systemReportsAbortReportsFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsAbortReportsField";
import { systemReportsAmbushReportsFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsAmbushReportsField";
import { systemReportsBypassReportsFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsBypassReportsField";
import { systemReportsCodeChangeReportsFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsCodeChangeReportsField";
import { systemReportsEarlyToCloseFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsEarlyToCloseField";
import { systemReportsEntryCheckinProtectionFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsEntryCheckinProtectionField";
import { systemReportsLateToOpenFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsLateToOpenField";
import { systemReportsOpenCloseEnableFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsOpenCloseEnableField";
import { systemReportsScheduleChangeReportsFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsScheduleChangeReportsField";
import { systemReportsSendStoredMessageFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsSendStoredMessage";
import { systemReportsVideoReportsFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsVideoReportsField";
import { systemReportsZoneRestoralReportsFieldId } from "components/FullProgramming/common/SystemReportsFields/SystemReportsZoneRestoralReportsField";
import { XT75SystemReportsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/__generated__/XT75SystemReportsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSystemReportsState } from "../../../XT75FullProgramming/XT75SystemReportsProgrammingConceptForm";
import { ProgrammingTemplateSystemReportsInput } from "../Create/__generated__/XT75TemplateSaveMutation.graphql";
import { XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        systemReports {
          id
          included
          abortReports {
            included
            data
          }
          ambushReports {
            included
            data
          }
          bypassReports {
            included
            data
          }
          codeChangeReports {
            included
            data
          }
          earlyToClose {
            included
            data
          }
          lateToOpen {
            included
            data
          }
          scheduleChangeReports {
            included
            data
          }
          videoSystemReports {
            included
            data
          }
          zoneRestoralReports {
            included
            data
          }
          openCloseEnable {
            included
            data
          }
          sendStoredMessage {
            included
            data
          }
          entryCheckinProtection {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XT75SystemReportsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateSystemReportsInput => {
  const {
    panel: { systemReports },
  } = getSystemReportsState(controlSystem);

  if (!systemReports) {
    return { included: false };
  }

  const systemReportsInputFields = {
    abortReports: {
      included: includedFields.has(systemReportsAbortReportsFieldId()),
      data: systemReports.abortReports,
    },
    ambushReports: {
      included: includedFields.has(systemReportsAmbushReportsFieldId()),
      data: systemReports.ambushReports,
    },
    bypassReports: {
      included: includedFields.has(systemReportsBypassReportsFieldId()),
      data: systemReports.bypassReports,
    },
    codeChangeReports: {
      included: includedFields.has(systemReportsCodeChangeReportsFieldId()),
      data: systemReports.codeChangeReports,
    },
    earlyToClose: {
      included: includedFields.has(systemReportsEarlyToCloseFieldId()),
      data: systemReports.earlyToClose,
    },
    lateToOpen: {
      included: includedFields.has(systemReportsLateToOpenFieldId()),
      data: systemReports.lateToOpen,
    },
    scheduleChangeReports: {
      included: includedFields.has(systemReportsScheduleChangeReportsFieldId()),
      data: systemReports.scheduleChangeReports,
    },
    videoSystemReports: {
      included: includedFields.has(systemReportsVideoReportsFieldId()),
      data: systemReports.videoSystemReports,
    },
    zoneRestoralReports: {
      included: includedFields.has(systemReportsZoneRestoralReportsFieldId()),
      data: systemReports.zoneRestoralReports,
    },
    openCloseEnable: {
      included: includedFields.has(systemReportsOpenCloseEnableFieldId()),
      data: systemReports.openCloseEnable,
    },
    sendStoredMessage: {
      included: includedFields.has(systemReportsSendStoredMessageFieldId()),
      data: systemReports.sendStoredMessage,
    },
    entryCheckinProtection: {
      included: includedFields.has(
        systemReportsEntryCheckinProtectionFieldId()
      ),
      data: systemReports.entryCheckinProtection,
    },
  };

  const systemReportsIncluded = Object.values(systemReportsInputFields).some(
    ({ included }) => included
  );

  return {
    included: systemReportsIncluded,
    ...systemReportsInputFields,
  };
};

export const setIncludedFields = (
  template: XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { systemReports } = getState(template);

  if (systemReports?.abortReports?.included) {
    setIncludedFields(setToggle(systemReportsAbortReportsFieldId()));
  }
  if (systemReports?.ambushReports?.included) {
    setIncludedFields(setToggle(systemReportsAmbushReportsFieldId()));
  }
  if (systemReports?.bypassReports?.included) {
    setIncludedFields(setToggle(systemReportsBypassReportsFieldId()));
  }
  if (systemReports?.codeChangeReports?.included) {
    setIncludedFields(setToggle(systemReportsCodeChangeReportsFieldId()));
  }
  if (systemReports?.earlyToClose?.included) {
    setIncludedFields(setToggle(systemReportsEarlyToCloseFieldId()));
  }
  if (systemReports?.lateToOpen?.included) {
    setIncludedFields(setToggle(systemReportsLateToOpenFieldId()));
  }
  if (systemReports?.scheduleChangeReports?.included) {
    setIncludedFields(setToggle(systemReportsScheduleChangeReportsFieldId()));
  }
  if (systemReports?.videoSystemReports?.included) {
    setIncludedFields(setToggle(systemReportsVideoReportsFieldId()));
  }
  if (systemReports?.zoneRestoralReports?.included) {
    setIncludedFields(setToggle(systemReportsZoneRestoralReportsFieldId()));
  }
  if (systemReports?.openCloseEnable?.included) {
    setIncludedFields(setToggle(systemReportsOpenCloseEnableFieldId()));
  }
  if (systemReports?.sendStoredMessage?.included) {
    setIncludedFields(setToggle(systemReportsSendStoredMessageFieldId()));
  }
  if (systemReports?.entryCheckinProtection?.included) {
    setIncludedFields(setToggle(systemReportsEntryCheckinProtectionFieldId()));
  }
};
