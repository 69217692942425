import React, {
  forwardRef,
  useEffect,
  useRef,
  InputHTMLAttributes,
  FocusEvent,
} from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../../utils/styles';
import { themeGrayAccent, themePrimary } from '../Theme';
import { EditIcon, CheckmarkIcon } from '../Icons';
import NakedButton from '../NakedButton';
import messages from './messages';
import { useIntl } from 'react-intl';
import { isNil } from 'ramda';
import { ESCAPE, ENTER } from '../../../components/web/constants';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  defaultFocused?: boolean;
  onCommit?: (event: FocusEvent<HTMLInputElement>) => void;
};

const EditableLabel = forwardRef(
  (
    {
      id = 'label',
      className,
      value,
      placeholder,
      disabled,
      max,
      onChange,
      onCommit,
      defaultFocused,
      ...rest
    }: Props,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { formatMessage } = useIntl();

    useEffect(() => {
      if (defaultFocused && inputRef.current !== null) {
        inputRef.current?.focus();
      }
    }, []); // eslint-disable-line

    return (
      <Wrapper className={className}>
        <Spacer>{value || placeholder}</Spacer>
        <Input
          {...rest}
          id={id}
          ref={(node) => {
            inputRef.current = node;

            if (typeof ref === 'function') {
              ref(node);
            } else if (!isNil(ref)) {
              ref.current = node;
            }
          }}
          type="text"
          value={value}
          max={max}
          onChange={(event) => {
            const { target } = event;
            if (onChange !== undefined && (!max || target.value.length < max)) {
              onChange(event);
            }

            target.setSelectionRange(
              target.selectionStart ?? target.value.length,
              target.selectionStart ?? target.value.length
            );
          }}
          onFocus={({ target }) => {
            target.setSelectionRange(0, target.value.length);
          }}
          onKeyDown={({ which }) => {
            if ([ENTER, ESCAPE].includes(which) && inputRef.current !== null) {
              inputRef.current?.blur();
            }
          }}
          onBlur={onCommit}
          placeholder={placeholder}
          disabled={disabled}
        />
        <ButtonWrap>
          <DoneButton type="button" title={formatMessage(messages.done)}>
            <CheckmarkIcon />
          </DoneButton>
          <EditButton htmlFor={id} title={formatMessage(messages.edit)}>
            <EditIcon />
          </EditButton>
        </ButtonWrap>
      </Wrapper>
    );
  }
);

export default EditableLabel;

const Spacer = styled.span`
  display: inline-block;
  height: inherit;
  margin-right: 2px;
  vertical-align: middle;
  font-size: inherit;
  line-height: inherit;
  color: transparent;
  text-align: right;
`;
const ButtonWrap = styled.span`
  display: none;

  ${media.small`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5em;
    height: inherit;
    line-height: inherit;
    text-align: center;
  `};
`;
const buttonCss = css`
  display: none;
  position: relative;
  vertical-align: middle;
  color: ${themeGrayAccent};
  font-size: 0.8em;
  z-index: 1;

  &:hover {
    color: ${themePrimary};
  }
`;
const DoneButton = styled(NakedButton)`
  ${buttonCss};
`;
const EditButton = styled.label`
  ${buttonCss};
  cursor: pointer;
`;
const Wrapper = styled.span`
  position: relative;
  max-width: 100%;
  width: auto;
  height: 1.25em;
  padding-right: 1.5em;
  font-size: 1em;
  line-height: 1.25;
  text-align: left;

  &:enabled:hover ${EditButton} {
    display: inline-block;
  }
`;
const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-align: inherit;
  line-height: inherit;

  &[disabled] {
    cursor: not-allowed;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus + ${ButtonWrap} ${DoneButton} {
    display: inline-block;
  }

  &:focus + ${ButtonWrap} ${EditButton} {
    display: none;
  }
`;
