App.directive("daFaCheckbox", [
  "$compile",
  "$http",
  "$templateCache",
  "$templateRequest",
  function ($compile, $http, $templateCache, $templateRequest) {
    var defaultCheckboxTemplate = "app/common/templates/form-checkbox.html";
    var checklistCheckboxTemplate =
      "app/common/templates/form-checklist-checkbox.html";

    getCheckboxItemTemplate = function (checktype) {
      var template = "";
      var templateLoader;
      switch (checktype) {
        case "checklist":
          template = checklistCheckboxTemplate;
          break;

        case "include-item-control":
          template = defaultCheckboxTemplate;
          break;

        default:
          template = defaultCheckboxTemplate;
      }
      // extend out if needed for other templates

      //templateLoader = $http.get(template, {cache: $templateCache});
      return template;
    };

    var linker = function (scope, element, attrs) {
      scope.checktype = attrs.checktype;

      $templateRequest(getCheckboxItemTemplate(scope.checktype))
        .then(function (html) {
          element.html(html);
          $compile(element.contents())(scope);
        })
        .catch(function (error) {
          console.error(error);
        });
    };
    return {
      scope: {
        cbModel: "=",
        cbIdName: "=", //string (must be encapsulated in quotes)
        cbLabelText: "=", //string (must be encapsulated in quotes)
        cbDisabled: "=",
        cbTooltipText: "=", //string (must be encapsulated in quotes)
        cbChange: "&",
        clCbModel: "=",
        clCbValue: "=", //string (must be encapsulated in quotes)
      },
      transclude: true,
      restrict: "E",
      replace: true,
      link: linker,
    };
  },
]);
