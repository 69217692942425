import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { curry } from "ramda";
import * as React from "react";
import { KeyfobButtonType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { getKeyfobButtonDefaults, KeyfobButtonNumberType } from "./utils";
import { KeyfobButtonActionField_keyfob$key } from "./__generated__/KeyfobButtonActionField_keyfob.graphql";

export const keyfobButtonActionFieldId = curry(
  (buttonNumber: KeyfobButtonNumberType, number: string) =>
    `keyfob-button-action-button-number-${buttonNumber.toLowerCase()}-${number}`
);

function KeyfobButtonActionField(props: {
  buttonNumber: KeyfobButtonNumberType;
}) {
  const [
    {
      number,
      buttonOneAction,
      buttonTwoAction,
      buttonThreeAction,
      buttonFourAction,
    },
    updateKeyfob,
  ] = useKeyfobFragment<KeyfobButtonActionField_keyfob$key>(
    graphql`
      fragment KeyfobButtonActionField_keyfob on Keyfob {
        number
        buttonOneAction
        buttonTwoAction
        buttonThreeAction
        buttonFourAction
      }
    `
  );

  const { buttonNumber } = props;
  const fieldId = keyfobButtonActionFieldId(buttonNumber, String(number));

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Action">
      <Select
        id={fieldId}
        value={
          buttonNumber === "Two"
            ? buttonTwoAction
            : buttonNumber === "Three"
            ? buttonThreeAction
            : buttonNumber === "Four"
            ? buttonFourAction
            : buttonOneAction
        }
        required
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            //Gets the default value for the button Type and then sets those onChange
            const defaultButtonValue = getKeyfobButtonDefaults(
              target.value as KeyfobButtonType,
              buttonNumber
            );
            recordProxy.setValue(target.value, `button${buttonNumber}Action`);
            recordProxy.setValue(
              defaultButtonValue[`button${buttonNumber}Output`],
              `button${buttonNumber}Output`
            );
            recordProxy.setValue(
              defaultButtonValue[`button${buttonNumber}OutputAction`],
              `button${buttonNumber}OutputAction`
            );
            recordProxy.setValue(
              defaultButtonValue[`button${buttonNumber}Areas`],
              `button${buttonNumber}Areas`
            );
            recordProxy.setValue(
              defaultButtonValue[`button${buttonNumber}PressTime`],
              `button${buttonNumber}PressTime`
            );
          });
        }}
      >
        <Select.Option value={KeyfobButtonType.UNUSED}>Unused</Select.Option>
        <Select.Option value={KeyfobButtonType.ARMING}>Arming</Select.Option>
        <Select.Option value={KeyfobButtonType.DISARMING}>
          Disarming
        </Select.Option>
        <Select.Option value={KeyfobButtonType.TOGGLE_ARM_DISARM}>
          Toggle Arm/Disarm
        </Select.Option>
        <Select.Option value={KeyfobButtonType.STATUS}>Status</Select.Option>
        <Select.Option value={KeyfobButtonType.PANIC}>Panic</Select.Option>
        <Select.Option value={KeyfobButtonType.PANIC_TWO_BUTTON}>
          Panic 2 Btn
        </Select.Option>
        <Select.Option value={KeyfobButtonType.EMERGENCY}>
          Emergency
        </Select.Option>
        <Select.Option value={KeyfobButtonType.EMERGENCY_TWO_BUTTON}>
          Emergency 2 Btn
        </Select.Option>
        <Select.Option value={KeyfobButtonType.OUTPUT}>Output</Select.Option>
        <Select.Option value={KeyfobButtonType.SENSOR_RESET}>
          Sensor
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobButtonActionField;
