/**
 * @generated SignedSource<<d80c30f804b09f447effc7a3090031b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type XFDeviceInformationNumberField_deviceInformation$data = {
  readonly axNumber?: string;
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly isNew?: boolean;
  readonly lxNumber?: string;
  readonly number?: number;
  readonly " $fragmentType": "XFDeviceInformationNumberField_deviceInformation";
};
export type XFDeviceInformationNumberField_deviceInformation$key = {
  readonly " $data"?: XFDeviceInformationNumberField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFDeviceInformationNumberField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XFDeviceInformationNumberField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lxNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "axNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isNew",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        }
      ],
      "type": "XfDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "32f9e9398127a5f6aab789dbb32d6a4f";

export default node;
