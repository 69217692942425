import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobSerialNumberField_keyfob$key } from "./__generated__/KeyfobSerialNumberField_keyfob.graphql";
import { KeyfobSerialNumberField_SerialNumberList_panel$key } from "./__generated__/KeyfobSerialNumberField_SerialNumberList_panel.graphql";

export const KEYFOB_SERIAL_NUMBER = `keyfob-serial-number-`;

export const keyfobSerialNumberFieldId = (number: string) =>
  KEYFOB_SERIAL_NUMBER + number;

function KeyfobSerialNumberField() {
  const [{ id, number, keyfobSerialNumber }, updateKeyfob] =
    useKeyfobFragment<KeyfobSerialNumberField_keyfob$key>(
      graphql`
        fragment KeyfobSerialNumberField_keyfob on Keyfob {
          id
          number
          keyfobSerialNumber
        }
      `
    );

  const [keyfobList] =
    usePanelFragment<KeyfobSerialNumberField_SerialNumberList_panel$key>(
      graphql`
        fragment KeyfobSerialNumberField_SerialNumberList_panel on Panel {
          keyfobs {
            id
            keyfobSerialNumber
          }
        }
      `
    );

  const fieldId = keyfobSerialNumberFieldId(String(number));
  const otherKeyfobSerialNumberList = keyfobList.keyfobs
    .filter((fob) => fob.id !== id && fob.keyfobSerialNumber.length === 8)
    .map((fob) => fob.keyfobSerialNumber);

  const duplicateSerialNumberPattern = otherKeyfobSerialNumberList.join(`|`);

  const pattern = duplicateSerialNumberPattern
    ? `(?!${duplicateSerialNumberPattern})^05[0-9][0-9][0-9][0-9][0-9][0-9]$`
    : `^05[0-9][0-9][0-9][0-9][0-9][0-9]$`;

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Serial Number">
      <TextInput
        id={fieldId}
        required
        pattern={pattern}
        inlineHelp="050000000-05999999"
        value={keyfobSerialNumber ?? ""}
        maxLength={8}
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(target.value, "keyfobSerialNumber");
          });
        }}
        getValidationMessage={(input) => {
          if (
            input.validity.patternMismatch &&
            duplicateSerialNumberPattern !== ""
          ) {
            if (keyfobSerialNumber.match(duplicateSerialNumberPattern))
              return "Serial number already in use";
          }
          return "";
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobSerialNumberField;
