import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsWeatherZipCodeField_systemOptions$key } from "./__generated__/SystemOptionsWeatherZipCodeField_systemOptions.graphql";

export const systemOptionsWeatherZipCodeFieldId = () =>
  "system-options-weather-zip-code";

function WeatherZipCodeField() {
  const [{ weatherZipCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsWeatherZipCodeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsWeatherZipCodeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            weatherZipCode
          }
          ... on XtSystemOptions {
            weatherZipCode
          }
          ... on TMSentrySystemOptions {
            weatherZipCode
          }
          ... on Xt75SystemOptions {
            weatherZipCode
          }
        }
      `
    );

  const fieldId = systemOptionsWeatherZipCodeFieldId();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);
  const pattern = isXr ? "((?!.)|     |[0-9]{1,5})" : "((?!.)|[0-9]{1,5})";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Weather Zip Code"
      tooltip="Enter Zip Code used to obtain weather forecast data."
    >
      <TextInput
        type="text"
        id={fieldId}
        name={fieldId}
        validationMessage="5 character maximum."
        pattern={pattern}
        maxLength={5}
        value={weatherZipCode}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "weatherZipCode");
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              !isXr && target.value === "" ? "00000" : target.value,
              "weatherZipCode"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default WeatherZipCodeField;
