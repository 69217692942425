import * as React from "react";
import styled from "styled-components";

const Root = styled.div`
  min-height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
`;

const GenericSuspenseFallback = () => {
  return (
    <Root>
      <div className="csspinner traditional" />
    </Root>
  );
};

export default GenericSuspenseFallback;
