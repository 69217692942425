import { fetchImageSrcFromUrlWithBearerAuth } from "common/utils/web/image";

/**
 * @ngdoc service
 * @name App.factory:DealerService
 *
 * @description
 * Holds the API, methods, and model for the Dealer Information
 *
 * @param {Object} dealer_init_keys A object containing a Control System ID and Customer Number
 *                                  Because the dealer can be derived from the Customer Number or the Dealer ID we look
 *                                  at both to populate the dealer key.
 * <pre>
 *   {dealer_id:12345,customer_id:12345}
 * </pre>
 */

App.factory("DealerService", [
  "$rootScope",
  "UserService",
  "PROPS",
  "$timeout",
  "$filter",
  "$q",
  "DealerInfoV2API",
  "DealerV2API",
  "Receiver",
  "DealerSettingsAPI",
  "DealerODataAPI",
  "OdataPageService",
  "DashboardDataService",
  "DealerUserAPI",
  "$state",
  "IntegrationService",
  "CustomerReferralsService",
  "RelayService",
  "BillingAccount",
  function (
    $rootScope,
    UserService,
    PROPS,
    $timeout,
    $filter,
    $q,
    DealerInfoV2API,
    DealerV2API,
    Receiver,
    DealerSettingsAPI,
    DealerODataAPI,
    OdataPageService,
    DashboardDataService,
    DealerUserAPI,
    $state,
    IntegrationService,
    CustomerReferralsService,
    RelayService,
    BillingAccount
  ) {
    return function (dealer_init_keys) {
      angular.extend(this, {
        initKeys: dealer_init_keys,

        /**
         * @ngdoc object
         * @name method:get
         * @methodOf App.factory:DealerService
         *
         * @description
         * Returns a Promise of the dealer information using either the Dealer ID or Customer ID.
         *
         * @return {promise} The promise of the Dealer Information.
         */
        get: function () {
          var _this = this;
          var deferred = $q.defer();
          if (
            dealer_init_keys.dealer_id &&
            dealer_init_keys.dealer_id == "new"
          ) {
            this.new()
              .then(
                function (data) {
                  angular.extend(_this, data.dealer);
                  //$timeout(function(){deferred.resolve(data);},5000);
                  deferred.resolve(data);
                },
                function (error) {
                  console.error(
                    "DealerService->get() - error: " + angular.toJson(error)
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else if (dealer_init_keys.dealer_id) {
            this.getDealerFromDealerID(dealer_init_keys.dealer_id)
              .then(
                function (data) {
                  angular.extend(_this, data.dealer);
                  _this.parseDealerReceivers(_this.receiver_configurations);
                  //$timeout(function(){deferred.resolve(data);},5000);
                  deferred.resolve(data);
                },
                function (error) {
                  console.error(
                    "DealerService->get() - error: " + angular.toJson(error)
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else if (dealer_init_keys.customer_id) {
            this.getDealerFromCustomer(dealer_init_keys.customer_id)
              .then(
                function (data) {
                  angular.extend(_this, data.dealer);
                  //$timeout(function(){deferred.resolve(data);},5000);
                  deferred.resolve(data);
                },
                function (error) {
                  console.error(
                    "DealerService->get() - error: " + angular.toJson(error)
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
          return deferred.promise;
        },

        /**
         * Get a new, default dealer template object from VK
         */
        new: function () {
          var deferred = $q.defer();
          var _this = this;
          DealerV2API.new(
            //{dealer_id: dealerId}, //params
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              angular.extend(_this, data.dealer);
              _this.isNew = true;
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * Create the dealer in VK, only after calling this.new()
         */
        create: function () {
          var deferred = $q.defer();
          this.prepareNewDealerForSCAPI();
          var _this = this;
          DealerV2API.create(
            { dealer_id: _this.dealer_id }, //params
            this.toJson(),
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              angular.extend(_this, data.dealer);
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        /**
         * Trim / initialize properties that may cause SCAPI to throw errors
         */
        prepareNewDealerForSCAPI: function () {
          if (this.isNew) {
            // Receiver configurations are modified in company store settings; throw an error for a new dealer since
            // they're not configured when creating a new dealer
            delete this.receiver_configurations;
            // If there is no custom app, SCAPI will throw an error if you leave the (prototype) customer_app_details
            if (!this.custom_app) {
              delete this.customer_app_details;
            }
          }
        },

        getDealerFromDealerID: function (dealerID) {
          var deferred = $q.defer();
          DealerV2API.show(
            { dealer_id: dealerID }, //params
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //info
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:getDealerFromCustomer
         * @methodOf App.factory:DealerService
         *
         * @description
         * Returns the dealer information that corresponds to the supplied customer id. See API docs:
         * <a href='http://jenkins.dmp.com:8080/job/farad-programming_api/ws/doc/Api/V2/DealersController.html#show-instance_method'>
         *   http://jenkins.dmp.com:8080/job/farad-programming_api/ws/doc/Api/V2/DealersController.html#show-instance_method
         * </a>
         *
         *
         * @param {Number} customerID The customer ID which corresponds to the requested dealer.
         * @return {promise} The promise of the requested Dealer information.
         */
        getDealerFromCustomer: function (customerID) {
          var deferred = $q.defer();
          DealerInfoV2API.get(
            { customer_id: customerID }, //params
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //info
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * Gets a list of dealers that the logged in user has access to (based on auth_token)
         */
        getDealersForUser: function () {
          var deferred = $q.defer();
          DealerV2API.indexDealers(
            {},
            function (data) {
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              deferred.resolve(data);
            },
            function (error) {
              console.error(
                "DealerService->getDealersForUser() - Error getting dealers: " +
                  angular.toJson(error)
              );
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        getPersonnelNames: function (
          dealerId,
          pageNumber,
          pageSize,
          pageData,
          pageDefer
        ) {
          var dealId = dealerId ? dealerId : this.id;
          var auto_page = true; //'true' by default.  All records are appended and returned.
          var currentPage = typeof pageNumber !== "undefined" ? pageNumber : 1; //starts at 1 and is automatically incremented.
          //Anything over 99 only returns 99 items.  Default of 100 currently used to switch auto paging process.
          pageSize = typeof pageSize !== "undefined" ? pageSize : 100;
          pageData = typeof pageData !== "undefined" ? pageData : []; //passes the accumulated data to each recursion
          var deferred =
            typeof pageDefer !== "undefined" ? pageDefer : $q.defer(); //passes the original promise to each recursion
          var params = {
            dealer_id: dealId,
            page: currentPage,
            page_size: pageSize,
          }; //because additional params may be added, this is declared outside the API call
          var _this = this;
          if (pageSize < 100) {
            auto_page = false;
          }
          DealerUserAPI.getUsers(
            params, //params
            function (data) {
              //success
              for (let i = 0; i < data.length; i++) {
                //Object holding email, first name, and last name of user
                let display =
                  data[i].user.first_name && data[i].user.last_name
                    ? data[i].user.first_name + " " + data[i].user.last_name
                    : data[i].user.email;
                let Person = {
                  email: data[i].user.email,
                  first_name: data[i].user.first_name,
                  last_name: data[i].user.last_name,
                  id: data[i].user.person_id,
                  display: display,
                };

                pageData = pageData.concat(Person);
              }
              //pageData = pageData.concat(data); //concatenates just the array part of the data
              if (data.length === pageSize && auto_page) {
                //if the array returned has the maximum number of elements, there could be more.
                deferred.notify({
                  job_uuid: "n/a",
                  status: "paging",
                  poll_count: 0,
                  page: currentPage,
                });
                _this
                  .getPersonnelNames(
                    dealerId,
                    ++currentPage,
                    pageSize,
                    pageData,
                    deferred
                  )
                  .then(
                    //recursively calls 'get' to retrieve next page of data
                    function () {
                      deferred.resolve(); // the current data is appended to the 'pageData' array, so we don't resolve anything here;
                    },
                    function (nextPageError) {
                      //If an error occurs, bail.
                      deferred.notify({
                        job_uuid: "n/a",
                        status: "error",
                        poll_count: 0,
                        page: currentPage,
                      });
                      deferred.reject(nextPageError);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                deferred.notify({
                  job_uuid: "n/a",
                  status: "success",
                  poll_count: 0,
                  page: currentPage,
                });
                deferred.resolve(pageData);
              }
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //info
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        getPersonnel: function (
          dealerId,
          pageNumber,
          pageSize,
          pageData,
          pageDefer
        ) {
          var dealId = dealerId ? dealerId : this.id;
          var auto_page = true; //'true' by default.  All records are appended and returned.
          var currentPage = typeof pageNumber !== "undefined" ? pageNumber : 1; //starts at 1 and is automatically incremented.
          //Anything over 99 only returns 99 items.  Default of 100 currently used to switch auto paging process.
          pageSize = typeof pageSize !== "undefined" ? pageSize : 100;
          pageData = typeof pageData !== "undefined" ? pageData : []; //passes the accumulated data to each recursion
          var deferred =
            typeof pageDefer !== "undefined" ? pageDefer : $q.defer(); //passes the original promise to each recursion
          var params = {
            dealer_id: dealId,
            page: currentPage,
            page_size: pageSize,
          }; //because additional params may be added, this is declared outside the API call
          var _this = this;
          if (pageSize < 100) {
            auto_page = false;
          }
          DealerUserAPI.getUsers(
            params, //params
            function (data) {
              //success
              pageData = pageData.concat(data); //concatenates just the array part of the data
              if (data.length === pageSize && auto_page) {
                //if the array returned has the maximum number of elements, there could be more.
                deferred.notify({
                  job_uuid: "n/a",
                  status: "paging",
                  poll_count: 0,
                  page: currentPage,
                });
                _this
                  .getPersonnel(
                    dealerId,
                    ++currentPage,
                    pageSize,
                    pageData,
                    deferred
                  )
                  .then(
                    //recursively calls 'get' to retrieve next page of data
                    function () {
                      deferred.resolve(); // the current data is appended to the 'pageData' array, so we don't resolve anything here;
                    },
                    function (nextPageError) {
                      //If an error occurs, bail.
                      deferred.notify({
                        job_uuid: "n/a",
                        status: "error",
                        poll_count: 0,
                        page: currentPage,
                      });
                      deferred.reject(nextPageError);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                deferred.notify({
                  job_uuid: "n/a",
                  status: "success",
                  poll_count: 0,
                  page: currentPage,
                });
                deferred.resolve(pageData);
              }
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //info
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * Get the next account number for this dealer, starting at nextNumber
         * @param nextNumber
         * @returns {*}
         */
        getNextAccountNumber: function (nextNumber) {
          var deferred = $q.defer();
          var _this = this;
          var dealerId;
          if (_this.id) dealerId = _this.id;
          else dealerId = dealer_init_keys.dealer_id;
          DealerSettingsAPI.nextAccountNumber(
            { dealer_id: dealerId, id: nextNumber },
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        submitAutoProgramming: function (panel, selectedDefault) {
          return submitAutoProgramming(panel, selectedDefault, false);
        },

        /**
         * Service to submit an auto programming job, so that
         *
         * @param panel a Panel object, as retrieved from a control_system
         * @param selectedDefault
         * @param useAutoProgramTiming if true the system will be programmed with the Auto Programming Timing rather than the Inventory timing.
         * @returns {*|promise}
         */
        submitAutoProgramming: function (
          panel,
          selectedDefault,
          useAutoProgramTiming
        ) {
          var deferred = $q.defer();
          var _this = this;
          var dealerId = _this.id ? _this.id : dealer_init_keys.dealer_id;
          var jobData = {
            Active: true,
            DealerId: dealerId,
            ItemType: "panel",
            SerialNumber: panel["serial_number"],
            HardwareModel: panel.hardware_model,
            CommunicationType: panel.comm_type,
            PanelId: panel.id,
            OrderType: "INV",
            Status: "new",
            TemplateName: selectedDefault,
          };
          if (useAutoProgramTiming) jobData.OrderType = "AUTO";

          DealerSettingsAPI.submitAutoProg(
            { dealer_id: dealerId },
            jobData,

            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        getAutoProgStatus: function (panel_id) {
          var deferred = $q.defer();
          var _this = this;
          var dealerId = _this.id ? _this.id : dealer_init_keys.dealer_id;
          DealerSettingsAPI.checkAutoProg(
            { dealer_id: dealerId, id: panel_id },
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        checkEasyConnectStatus: function (panel_serial_number) {
          var deferred = $q.defer();
          var _this = this;
          var dealerId = _this.id ? _this.id : dealer_init_keys.dealer_id;
          DealerSettingsAPI.checkEasyConnect(
            { dealer_id: dealerId, id: panel_serial_number },
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        checkModemStatus: function (panel_id) {
          var deferred = $q.defer();
          var _this = this;
          var dealerId = _this.id ? _this.id : dealer_init_keys.dealer_id;
          DealerSettingsAPI.checkModemStatus(
            { dealer_id: dealerId, id: panel_id },
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        getDealerCustomerList: function (refresh) {
          var deferred = $q.defer();
          var _this = this;
          refresh = typeof refresh !== "undefined" ? refresh : false;

          if (
            !refresh &&
            UserService.customerListCached &&
            !$state.is("app.search")
          ) {
            deferred.resolve(UserService.customerListCached);
          } else {
            var promise = DealerODataAPI.getDetailedCustomers(
              { dealer_id: _this.id } //params
            ).$promise;
            var ODPS = new OdataPageService();
            ODPS.getAllPages(promise)
              .then(
                function (data) {
                  //Loop through the array and put each object in a customer wrapper
                  var theCustomers = [];
                  for (var i = 0; i < data.length; i++) {
                    theCustomers.push({ customer: data[i] });
                  }
                  //UserService.setCustomerListCache(theCustomers);
                  deferred.resolve(theCustomers);
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
          return deferred.promise;
        },

        getCustomerCount: function () {
          var deferred = $q.defer();
          var _this = this;
          var dealerId = _this.id ? _this.id : dealer_init_keys.dealer_id;
          if (dealerId) {
            DashboardDataService.getNumberOfCustomersForDealer(dealerId).then(
              function (data) {
                _this.numberOfCustomers = data.value;
                deferred.resolve(data);
              },
              function (error) {
                deferred.reject(error);
              }
            );
          } else {
            deferred.reject(false);
          }
          return deferred.promise;
        },

        getReferralsCount: function () {
          var deferred = $q.defer();
          var _this = this;
          var dealerId = _this.id ? _this.id : dealer_init_keys.dealer_id;
          if (dealerId) {
            CustomerReferralsService.getCustomerReferralsCount(dealerId).then(
              function (referrals) {
                _this.numberOfOpenReferrals = referrals.unredeemed;
                deferred.resolve(referrals);
              },
              function (error) {
                deferred.reject(error);
              }
            );
          } else {
            deferred.reject(false);
          }
          return deferred.promise;
        },

        getHasValidBillingInformation: function () {
          var deferred = $q.defer();
          var _this = this;
          var validPayment = false;
          var dealerId = _this.id ? _this.id : dealer_init_keys.dealer_id;
          var dealerCode = _this.dealer_code
            ? _this.dealer_code
            : dealer_init_keys.dealer_code;
          if (dealerId && dealerCode) {
            const account = new BillingAccount();
            account.get(dealerCode, dealerId).then(
              function (account) {
                for (let i = 0; i < account.length; i++) {
                  if (account[i].payment_sources) {
                    for (
                      let x = 0;
                      x < account[i].payment_sources.length;
                      x++
                    ) {
                      if (!account[i].payment_sources[x].is_deactivated) {
                        _this.valid_payment_source = true;
                        _this.updateDealer();
                        validPayment = true;
                      }
                    }
                  }
                }
                _this.hasValidBillingInformation = Boolean(validPayment);

                if (!validPayment) {
                  _this.valid_payment_source = false;
                  _this.updateDealer();
                }
                deferred.resolve(account);
              },
              function (error) {
                deferred.reject(error);
              }
            );
          } else {
            _this.valid_payment_source = false;
            _this.updateDealer();
            deferred.reject(false);
          }

          return deferred.promise;
        },

        getDealersUsersList: function (refresh) {
          var deferred = $q.defer();
          if (!refresh && UserService.appUserListCached) {
            deferred.resolve(UserService.appUserListCached);
          } else {
            DashboardDataService.getDealersCustomerUsers(this.id)
              .then(
                function (appUserList) {
                  //UserService.setAppUserListCache(appUserList.value);
                  deferred.resolve(appUserList.value);
                },
                function (appUserListError) {
                  deferred.reject(appUserListError);
                },
                function (appUserListNotify) {
                  //UserService.setAppUserListCache(appUserListNotify);
                  deferred.notify(appUserListNotify.value);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
          return deferred.promise;
        },

        /**
         * @ngdoc object
         * @name method:updateDealer
         * @methodOf App.factory:DealerServices
         *
         * @description
         * Returns a response status signalling either the success or failure of the update call. See API docs:
         * <a href="http://jenkins.dmp.com:8080/job/farad-dealer_store/ws/doc/Api/V2/DealersController.html#update-instance_method">
         *   http://jenkins.dmp.com:8080/job/farad-dealer_store/ws/doc/Api/V2/DealersController.html#update-instance_method
         * </a>
         *
         * @return {promise} The promise of the updated Dealer Information
         */
        updateDealer: function () {
          var deferred = $q.defer();
          var _this = this;
          DealerV2API.update(
            { dealer_id: _this.id }, //params

            _this.toJson(),

            function (updateData) {
              _this
                .get(updateData.dealer.id)
                .then(
                  function (getData) {
                    deferred.resolve(getData);
                  },
                  function (getError) {
                    deferred.reject(getError);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (updateError) {
              deferred.reject(updateError);
            }
          );

          return deferred.promise;
        },

        /**
         * Convert the object attributes into a suitable json format for updating to VK
         */
        toJson: function () {
          var json = {};
          json.dealer = this;
          json.dealer.receiver_configurations_attributes =
            json.dealer.receiver_configurations;
          delete json.dealer.receiver_configurations;
          json.dealer.master_identifiers_attributes =
            json.dealer["master_identifiers"];
          delete json.dealer["master_identifiers"];
          json.dealer.customer_app_details_attributes =
            json.dealer["customer_app_details"];
          delete json.dealer["customer_app_details"];

          return json;
        },

        /**
         * @ngdoc object
         * @name method:parseDealerReceivers
         * @methodOf App.factory:DealerServices
         *
         * @description
         * Populates an attribute of Dealer Info (receiver_configurations array)
         *
         * @return {promise} The promise of the updated Dealer Information
         */
        parseDealerReceivers: function (receivers) {
          var receiver_1_filter = $filter("filter")(
            receivers,
            { number: 1 },
            true
          )[0];
          var receiver_1 = receiver_1_filter
            ? new Receiver(receiver_1_filter, this.id)
            : new Receiver({ number: 1 }, this.id);
          var receiver_2_filter = $filter("filter")(
            receivers,
            { number: 2 },
            true
          )[0];
          var receiver_2 = receiver_2_filter
            ? new Receiver(receiver_2_filter, this.id)
            : new Receiver({ number: 2 }, this.id);

          this.receiver_configurations = [receiver_1, receiver_2];
        },

        getDealerLogo: async function (imgType, imgSize) {
          //format the image color
          var type = "";
          switch (imgType) {
            case "light":
              type = "light_type";
              break;
            case "dark":
              type = "dark_type";
              break;
            default:
              type = "light_type";
              break;
          }

          //format the size
          var size = "";
          switch (imgSize) {
            case "header":
              size = "240x72";
              break;
            case "mobile":
              size = "240x100";
              break;
            case "thumb":
              size = "100x100";
              break;
            default:
              size = "240x100";
              break;
          }

          var dealerLogoUrl =
            PROPS.imageApiUrl +
            "/Uploads/dealer_logos/" +
            this.id +
            "/" +
            type +
            "/" +
            this.id +
            "_" +
            size +
            ".png";

          const localLogoUrl = await fetchImageSrcFromUrlWithBearerAuth(
            dealerLogoUrl,
            UserService.auth_token
          ).then(
            function (data) {
              return data;
            },
            function (error) {
              return "";
            }
          );

          return localLogoUrl;
        },

        /**
         * Returns a list of integration objects (such as Sedona) for the current dealer ID
         */
        getDealerIntegrations: function (dealerId) {
          var deferred = $q.defer();

          var _this = this;
          _this.integrationsEnabled = false; // We default this to false.
          _this.sedonaIntegrationEnabled = false; // We default this to false
          IntegrationService.getAllDealerIntegrations(dealerId)
            .then(
              function (integrations) {
                // Retrieved integrations
                _this.integrations = integrations;

                // Parse the returned integrations
                if (Array.isArray(_this.integrations)) {
                  // Find any active integrations
                  _this.integrationsEnabled = _this.integrations.some(function (
                    integration
                  ) {
                    return integration.Active === true;
                  });

                  // Do we have an active Sedona integration?
                  _this.sedonaIntegrationEnabled = _this.integrations.some(
                    function (integration) {
                      return (
                        integration.ServiceType.toLowerCase() === "sedona" &&
                        integration.Active === true
                      );
                    }
                  );
                }
                deferred.resolve(integrations);
              },
              function (error) {
                deferred.resolve();
              }
            )
            .catch(function (error) {
              console.error(error);
            });

          return deferred.promise;
        },
      });
    };
  },
]);
