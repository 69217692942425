/**
 * @generated SignedSource<<65ee1e5fa2b04eeb4428963c1c7873c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PingPanelQuery$variables = {
  hubId: string;
  systemId: string;
};
export type PingPanelQuery$data = {
  readonly getCamectPingPanel: {
    readonly error?: string | null;
    readonly result?: string | null;
    readonly success?: boolean | null;
  } | null;
};
export type PingPanelQuery = {
  response: PingPanelQuery$data;
  variables: PingPanelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "hubId",
    "variableName": "hubId"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "success",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "result",
      "storageKey": null
    }
  ],
  "type": "CamectPingPanelSuccessPayload",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/)
  ],
  "type": "CamectPingPanelErrorPayload",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PingPanelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCamectPingPanel",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PingPanelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getCamectPingPanel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "315e37eab61dc07e9a706191d48b8994",
    "id": null,
    "metadata": {},
    "name": "PingPanelQuery",
    "operationKind": "query",
    "text": "query PingPanelQuery(\n  $systemId: String!\n  $hubId: String!\n) {\n  getCamectPingPanel(systemId: $systemId, hubId: $hubId) {\n    __typename\n    ... on CamectPingPanelSuccessPayload {\n      success\n      error\n      result\n    }\n    ... on CamectPingPanelErrorPayload {\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a87dde8c9a4bbb04ec45328ccdd82bd";

export default node;
