/**
 * @ngdoc API Resource
 * @name App.factory:SitesODataAPI
 *
 * @description
 * API factory for Sites Information
 *
 */
App.factory("SitesODataAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/sites/:siteId/:action",
      {},
      {
        getX1CheckInStatuses: {
          method: "GET",
          params: {
            siteId: "@siteId",
            action: "vk.GetX1CheckinStatuses()",
          },
        },
        getX1PanelStatistics: {
          method: "GET",
          params: {
            siteId: "@siteId",
            action: "vk.GetX1PanelStatistics()",
          },
        },
        getSiteLogs: {
          method: "GET",
          params: {
            siteId: "@siteId",
            action: "audit_logs",
          },
        },
      }
    );
  },
]);
