/**
 * @generated SignedSource<<096d0bb5a66510e24ff5730e4ece80cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HikvisionNvrs_site$data = {
  readonly billingControlSystemId: string;
  readonly hikvisionNvrs: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HikvisionNvrs_hikvisionNvr">;
  }>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"HikvisionNvrsNvr_site">;
  readonly " $fragmentType": "HikvisionNvrs_site";
};
export type HikvisionNvrs_site$key = {
  readonly " $data"?: HikvisionNvrs_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"HikvisionNvrs_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./VideoFormHikvisionNvrsRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "HikvisionNvrs_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HikvisionNvrsNvr_site"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingControlSystemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HikvisionNvr",
      "kind": "LinkedField",
      "name": "hikvisionNvrs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HikvisionNvrs_hikvisionNvr"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "a8cb0044c51f233541a678b61efc07f5";

export default node;
