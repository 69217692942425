/**
 * @generated SignedSource<<75191ed6d1717361f7ff655a99a2fa09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type KeyfobButtonType = "ARMING" | "DISARMING" | "EMERGENCY" | "EMERGENCY_TWO_BUTTON" | "OUTPUT" | "PANIC" | "PANIC_TWO_BUTTON" | "SENSOR_RESET" | "STATUS" | "TOGGLE_ARM_DISARM" | "UNUSED";
export type KeyfobNumberOfButtons = "FOUR" | "ONE" | "TWO" | "ZERO";
export type KeyfobOutputAction = "MOMENTARY" | "OFF" | "PULSE" | "STEADY" | "TOGGLE";
export type KeyfobPressTime = "LONG" | "SHORT";
export type KeyfobSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XRKeyFobsProgrammingConceptFormInline_keyfob$data = {
  readonly __typename: "Keyfob";
  readonly buttonFourAction: KeyfobButtonType;
  readonly buttonFourAreas: string;
  readonly buttonFourOutput: string;
  readonly buttonFourOutputAction: KeyfobOutputAction;
  readonly buttonFourPressTime: KeyfobPressTime;
  readonly buttonOneAction: KeyfobButtonType;
  readonly buttonOneAreas: string;
  readonly buttonOneOutput: string;
  readonly buttonOneOutputAction: KeyfobOutputAction;
  readonly buttonOnePressTime: KeyfobPressTime;
  readonly buttonThreeAction: KeyfobButtonType;
  readonly buttonThreeAreas: string;
  readonly buttonThreeOutput: string;
  readonly buttonThreeOutputAction: KeyfobOutputAction;
  readonly buttonThreePressTime: KeyfobPressTime;
  readonly buttonTwoAction: KeyfobButtonType;
  readonly buttonTwoAreas: string;
  readonly buttonTwoOutput: string;
  readonly buttonTwoOutputAction: KeyfobOutputAction;
  readonly buttonTwoPressTime: KeyfobPressTime;
  readonly id: string;
  readonly isNew: boolean;
  readonly keyfobSerialNumber: string;
  readonly keyfobSupervisionTime: KeyfobSupervisionTime;
  readonly number: number;
  readonly numberOfButtons: KeyfobNumberOfButtons;
  readonly user: {
    readonly __typename: string;
    readonly id: string;
    readonly name: string;
    readonly number: number;
  } | null;
  readonly " $fragmentType": "XRKeyFobsProgrammingConceptFormInline_keyfob";
};
export type XRKeyFobsProgrammingConceptFormInline_keyfob$key = {
  readonly " $data"?: XRKeyFobsProgrammingConceptFormInline_keyfob$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRKeyFobsProgrammingConceptFormInline_keyfob">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRKeyFobsProgrammingConceptFormInline_keyfob"
};

(node as any).hash = "659d62ed33986b6455b1d8a59cd5c4fe";

export default node;
