import { App } from "app-module";
import { RelayServiceType } from "app/common/services/relay-service";
import IncludedTemplateFieldsContextProvider from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { LastUpdatedContextProvider } from "components/FullProgramming/common/LastUpdatedContext";
import TemplateContextProvider, {
  EditingFrom,
} from "components/FullProgramming/common/TemplateContext";
import { GenericPageFallback } from "components/GenericPageFallback";
import { AngularStateProvider } from "components/SystemDiagnostics/AngularStateProvider";
import { Templates } from "components/Templates";
import { EditTemplate } from "components/Templates/EditTemplate";
import { AlertObject, AlertsContextProvider } from "contexts/AlertsContext";
import React, { Suspense, useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { react2angular } from "react2angular";
import { PanelHardwareModel, toDealerId } from "securecom-graphql/client";

type Props = {
  RelayService: RelayServiceType;
  $stateParams: {
    dealer_id: string;
    id: string;
    hardware_model: string;
  };
  UserService: {
    dealer_id: string;
    isParentDealerLogin(): boolean;
    isSupervisorAccessible(): boolean;
    enabledDistributionSubscriber(): boolean;
  };
  $rootScope: {
    alerts: AlertObject[];
    $digest: () => void;
    $on: (event: string, callback: (...arr: any) => void) => () => void;
  };
  $state: {
    go: (
      route: string,
      params: { [key: string]: string | number },
      options?: { reload?: boolean }
    ) => void;
  };
};

const TemplatesRoot: React.FC<Props> = ({
  RelayService,
  UserService,
  $stateParams,
  $state,
  $rootScope,
}) => {
  const environment = useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  const { id: templateId, dealer_id: dealerId } = $stateParams;

  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <AngularStateProvider state={$state}>
            <IncludedTemplateFieldsContextProvider>
              <LastUpdatedContextProvider>
                {!templateId ? (
                  <TemplateContextProvider
                    settings={{
                      editingFrom: EditingFrom.NEW_TEMPLATE_PAGE,
                      hardwareModel: PanelHardwareModel.XT30,
                      templateName: "",
                      customerId: "",
                      isEditing: true,
                      isApplying: false,
                      isCreating: true,
                      isSaving: false,
                      isLoadingEditPage: false,
                      isInternational:
                        UserService.enabledDistributionSubscriber(),
                    }}
                  >
                    <Templates dealerId={toDealerId(UserService.dealer_id)} />
                  </TemplateContextProvider>
                ) : (
                  <EditTemplate
                    templateId={templateId}
                    dealerId={dealerId}
                    isInternational={UserService.enabledDistributionSubscriber()}
                  />
                )}
              </LastUpdatedContextProvider>
            </IncludedTemplateFieldsContextProvider>
          </AngularStateProvider>
        </AlertsContextProvider>
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "templates",
    react2angular(
      TemplatesRoot,
      [],
      ["RelayService", "UserService", "$state", "$rootScope", "$stateParams"]
    )
  );
};
