/**
 * @generated SignedSource<<0d38fa4d4a67f2295c93e2d0fc741b22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75LockoutCodeProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly lockoutCode: {
      readonly code: string;
      readonly id: string;
    } | null;
  };
  readonly " $fragmentType": "XT75LockoutCodeProgrammingConceptFormInline_controlSystem";
};
export type XT75LockoutCodeProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XT75LockoutCodeProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75LockoutCodeProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75LockoutCodeProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "2977363ccce1b3fdfc8455c5e35652e9";

export default node;
