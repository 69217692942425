import DaColors from "app/common/DaColors";
import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Wrapper = styled.div<{ size: number; color: string }>`
  animation: ${spin} 1s linear infinite;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  color: ${(props) => props.color};
`;

type Props = {
  size: number;
  color?: string;
};

const CleanDotsSpinner = ({ size, color = DaColors.Info500 }: Props) => (
  <Wrapper size={size} color={color}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="13.6554" cy="5.46037" rx="2.30679" ry="2.24345" />
      <ellipse cx="9.76509" cy="1.86954" rx="1.92232" ry="1.86954" />
      <ellipse cx="4.30251" cy="2.58255" rx="1.53786" ry="1.49563" />
      <ellipse cx="1.15339" cy="6.84658" rx="1.15339" ry="1.12172" />
      <ellipse cx="1.94554" cy="11.845" rx="0.961161" ry="0.93477" />
      <ellipse cx="11.8101" cy="14.8009" rx="0.576696" ry="0.560862" />
      <ellipse cx="6.39051" cy="15.2522" rx="0.768928" ry="0.747816" />
      <ellipse cx="15.6154" cy="10.867" rx="0.384464" ry="0.373908" />
    </svg>
  </Wrapper>
);

export default CleanDotsSpinner;
