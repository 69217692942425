App.controller("SchedulesXTBaseCtrl", [
  "$q",
  "$rootScope",
  "$scope",
  "$state",
  "UserService",
  "MultiPanelService",
  "scheduleUtilService",
  "panel_id",
  "control_system_id",
  "schedule_type",
  "PANEL_CONCEPTS",
  "PanelProgArchiveService",
  function (
    $q,
    $rootScope,
    $scope,
    $state,
    UserService,
    MultiPanelService,
    scheduleUtilService,
    panel_id,
    control_system_id,
    schedule_type,
    PANEL_CONCEPTS,
    PanelProgArchiveService
  ) {
    $scope.base = {};
    $scope.base.type = schedule_type;
    $scope.base.isBusy = false;
    $scope.base.mode = "";
    $scope.base.currentSchedule = {};
    $scope.base.currentScheduleType = "";
    $scope.PanelProgArchiveService = PanelProgArchiveService;

    $scope.buttons = {
      destroyMessage:
        $scope.base.type.api_name === "schedules"
          ? "Are you sure you want to clear the schedule times?"
          : "Are you sure you want to delete this schedule?",
      destroyLabel: $scope.base.type.api_name === "schedules" ? "Clear" : null,
    };

    $scope.controlSystems = []; // Stores a list of all control systems the user has access to
    $scope.selectedSystems = []; // Stores a list of panels that have been selected in the multi-panel list
    $scope.panel_id = panel_id;

    $scope.multiPanelFilters = [];

    $scope.twilightData = scheduleUtilService.twilightData;
    $scope.setPeriod = function (beginTime, selection) {
      scheduleUtilService.setPeriod(
        beginTime,
        selection,
        $scope.base.currentSchedule
      );
    };
    $scope.setOffset = function (beginTime, offset) {
      scheduleUtilService.setOffset(
        beginTime,
        offset,
        $scope.base.currentSchedule
      );
    };
    $scope.setAnyChecked = function () {
      scheduleUtilService.setAnyChecked($scope.base.currentSchedule);
      $scope.updateMultiPanelFilters();
    };

    /**
     * Update our multi-panel-list filters
     */
    $scope.updateMultiPanelFilters = function () {
      // Update our multi-panel minimum software_version filter based on if any sunrise_sunset toggles are checked
      $scope.twilightData.anyBeginCheck || $scope.twilightData.anyEndCheck
        ? ($scope.multiPanelFilters[0].value = "171")
        : ($scope.multiPanelFilters[0].value = "100");
    };

    /**
     * @ngdoc
     * @name dateTimeForceUTC
     *
     * @description
     * Helper function for dateTimeForceUTC
     */
    $scope.dateTimeForceUTC = function (inDateStr) {
      var inDate = new Date(inDateStr);
      return dateTimeForceUTC(inDate);
    };

    /**
     * Callback for multi-panel-list directive selectionChanged event
     * @param selectedPanels {array} An array of currently selected panels in the multi-panel list
     */
    $scope.selectionChanged = function (selectedPanels) {
      $scope.selectedSystems = selectedPanels;
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:sendScheduleChanges
     *
     * @description
     * Save all changes that the user has made to panel programming then calls goToSchedules on success
     */
    $scope.sendScheduleChanges = function () {
      var curSchedule = angular.copy($scope.base.currentSchedule);
      $scope.base.isBusy = true;

      scheduleUtilService.finalizeSchedule(curSchedule);
      if ($scope.selectedSystems.length == 0) {
        return;
      }

      // If this is an arming schedule, we also need to send area informations
      if ($scope.base.type == PANEL_CONCEPTS.schedules) {
        saveAreaInformations()
          .then(function () {
            $scope.selectedSystems.length > 1
              ? sendMultiPanel()
              : sendSinglePanel(curSchedule);
          })
          .catch(function (error) {
            $scope.base.isBusy = false;
          });
      } else {
        // This isn't an arming schedule, only send the current concept
        $scope.selectedSystems.length > 1
          ? sendMultiPanel()
          : sendSinglePanel(curSchedule);
      }
    };

    /**
     * Save area information for the panel
     * @returns {promise}
     */
    function saveAreaInformations() {
      var deferred = $q.defer();
      $scope.Panel.sendProgramming(["area_informations", "system_options"])
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Area information saved.",
            });
            deferred.resolve();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to save area information.",
              json: error,
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function sendSinglePanel(scheduleToSave) {
      $scope.base.isBusy = true;
      $scope.Panel.sendProgramming(
        schedule_type.api_name,
        false,
        false,
        scheduleToSave.number
      )
        .then(
          function () {
            $scope.base.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "Schedule saved.",
            });
            goToSchedules();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to send the data to the system.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.base.isBusy = false;
        });
    }

    function sendMultiPanel() {
      $scope.base.isBusy = true;
      // Determine what type of job we need to run
      var jobType =
        $scope.base.mode == "Edit" ? calcJobType("UPDATE") : calcJobType("ADD");

      // Prepare our schedule data
      var jobData = {};
      var schedule = $scope.base.currentSchedule;
      jobData[$scope.base.currentScheduleType] = angular.copy(schedule);
      delete jobData[$scope.base.currentScheduleType].days;

      // Create our SchedulerJobs and JobGroup and schedule the group to run
      MultiPanelService.createMultiPanelJob(
        jobType,
        $scope.selectedSystems,
        jobData,
        schedule.number
      )
        .then(
          function () {
            $scope.base.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "XT Schedule changes have been queued up.",
            });
            $scope.Panel.cancelEdit(schedule_type.api_name); // Since we don't want to show changes until the scheduled job finishes, revert back to the pristine concept
            goToSchedules();
          },
          function (error) {
            $scope.base.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to schedule the multi-panel action.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Determine the multi panel job type based on the action and schedule type
     */
    function calcJobType(action) {
      var multiAction = schedule_type.api_name.substr(0, 3).toUpperCase();
      if (multiAction == "SCH") {
        multiAction = "ARM";
      } // VK Calls arming schedules "Schedules" Jobs calls them "Arming"
      return action + "_" + multiAction + "_SCHEDULE_V2";
    }

    /**
     * Removes the specified schedule from the panel then calls goToSchedules
     * @param schedule Optional The schedule to delete. If not specified, the current schedule will be deleted
     */
    $scope.deleteSchedule = function (schedule) {
      var item = angular.isDefined(schedule)
        ? schedule
        : $scope.base.currentSchedule;
      if ($scope.selectedSystems.length == 0) {
        return;
      }
      $scope.selectedSystems.length > 1
        ? deleteMultiPanel(item)
        : deleteSinglePanel(item);
    };

    function deleteSinglePanel(scheduleToDelete) {
      $scope.base.isBusy = true;
      $scope.Panel.deleteItem(schedule_type.api_name, scheduleToDelete)
        .then(function () {
          $scope.base.isBusy = false;
          goToSchedules();
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function deleteMultiPanel(scheduleToDelete) {
      $scope.base.isBusy = true;

      var jobType = calcJobType("DELETE");

      // Create our SchedulerJobs and JobGroup and schedule the group to run
      MultiPanelService.createMultiPanelJob(
        jobType,
        $scope.selectedSystems,
        null,
        scheduleToDelete.number
      )
        .then(
          function () {
            $scope.base.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "XT Schedule changes have been queued up.",
            });
            $scope.Panel.cancelEdit(schedule_type.api_name); // Since we don't want to show changes until the scheduled job finishes, revert back to the pristine concept
            goToSchedules();
          },
          function (error) {
            $scope.base.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to schedule the multi-panel action.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * @description
     * Determines which destroy function to call
     */
    $scope.destroyFunction = function () {
      $scope.base.type.api_name === "schedules"
        ? $scope.clearSchedule()
        : $scope.deleteSchedule();
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:clearSchedule
     *
     * @description
     * Clears the time fields for the schedule then saves changes.
     */
    $scope.clearSchedule = function () {
      $scope.base.currentSchedule.clear();
      clearAutoArming();
      $scope.sendScheduleChanges($scope.base.currentSchedule);
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:clearAutoArming
     *
     * @description
     * Clears the Auto arm/disarm and close check flags.
     */
    function clearAutoArming() {
      for (let i = 0; i < $scope.Panel.area_informations.length; i++) {
        $scope.Panel.area_informations[i].auto_arm = "N";
        $scope.Panel.area_informations[i].auto_disrm = "N";
      }

      $scope.Panel.system_options.close_chk = "N";
    }

    /**
     * @ngdoc
     * @name cancelEdit
     *
     * @description
     * Cancels any changes that have been made by restoring the concept's pristine state then calls goToSchedules
     */
    $scope.cancelEdit = function () {
      $scope.Panel.cancelEdit(schedule_type.api_name);
      goToSchedules();
    };

    /**
     * @ngdoc
     * @name goToSchedules
     *
     * @description
     * Loads the schedules view
     */
    function goToSchedules() {
      $state.go("^.schedules_list_xt", {
        customer_id: UserService.customer_id,
        control_system_id: control_system_id,
        panel_id: panel_id,
      });
    }

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:initialize
     *
     * @description
     * Initialize the control system
     */
    function initPage() {
      if ($state.current.name.includes("new")) {
        $scope.base.mode = "New";
      } else {
        $scope.base.mode = "Edit";
      }

      MultiPanelService.getPanels($state.params.customer_id, $scope.panel_id)
        .then(
          function (data) {
            $scope.controlSystems = data;
          },
          function (error) {
            $scope.multiBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred while retrieving the multi-panel list.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Cancel any pending changes when the controller is destroyed.
     * This prevents the list page from displaying incorrect data if the user presses the back button
     */
    $scope.$on("$destroy", function () {
      $scope.Panel.cancelEdit(schedule_type.api_name);
    });

    initPage();
  },
]);
