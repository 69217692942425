import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { setIncludedFields as setBellOptionsIncludedFields } from "./Data/XFBellOptionsTemplateData";
import { setIncludedFields as setCommunicationIncludedFields } from "./Data/XFCommunicationPathTemplateData";
import { setIncludedFields as setDeviceInformationIncludedFields } from "./Data/XFDeviceInformationTemplateData";
import { setIncludedFields as setLockoutCodeIncludedFields } from "./Data/XFLockoutCodeTemplateData";
import { setIncludedFields as setNetworkOptionsIncludedFields } from "./Data/XFNetworkOptionsTemplateData";
import { setIncludedFields as setOutputGroupsIncludedFields } from "./Data/XFOutputGroupsTemplateData";
import { setIncludedFields as setOutputInformationsIncludedFields } from "./Data/XFOutputInformationTemplateData";
import { setIncludedFields as setOutputOptionsIncludedFields } from "./Data/XFOutputOptionsTemplateData";
import { setIncludedFields as setRemoteOptionsIncludedFields } from "./Data/XFRemoteOptionsTemplateData";
import { setIncludedFields as setSystemOptionsIncludedFields } from "./Data/XFSystemOptionsTemplateData";
import { setIncludedFields as setSystemReportsIncludedFields } from "./Data/XFSystemReportsTemplateData";
import { setIncludedFields as setZoneInformationIncludedFields } from "./Data/XFZoneInformationTemplateData";
import { XFTemplateDataInline_xfProgrammingTemplateConcepts$key } from "./__generated__/XFTemplateDataInline_xfProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XFTemplateDataInline_xfProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XFTemplateDataInline_xfProgrammingTemplateConcepts on XfProgrammingTemplateConcepts
      @inline {
        ...XFBellOptionsTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFCommunicationPathTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFDeviceInformationTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFNetworkOptionsTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFOutputGroupsTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFOutputInformationTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFOutputOptionsTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFRemoteOptionsTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFSystemOptionsTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFSystemReportsTemplateDataInline_xfProgrammingTemplateConcepts
        ...XFZoneInformationTemplateDataInline_xfProgrammingTemplateConcepts
      }
    `,
    template
  );

export const setAllIncludedFields = (
  template: XFTemplateDataInline_xfProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const templateData = getState(template);

  setBellOptionsIncludedFields(templateData, setIncludedFields);
  setCommunicationIncludedFields(templateData, setIncludedFields);
  setDeviceInformationIncludedFields(templateData, setIncludedFields);
  setLockoutCodeIncludedFields(templateData, setIncludedFields);
  setNetworkOptionsIncludedFields(templateData, setIncludedFields);
  setOutputOptionsIncludedFields(templateData, setIncludedFields);
  setOutputGroupsIncludedFields(templateData, setIncludedFields);
  setOutputInformationsIncludedFields(templateData, setIncludedFields);
  setRemoteOptionsIncludedFields(templateData, setIncludedFields);
  setSystemOptionsIncludedFields(templateData, setIncludedFields);
  setSystemReportsIncludedFields(templateData, setIncludedFields);
  setZoneInformationIncludedFields(templateData, setIncludedFields);
};
