import { EventSource, SecurityEvent } from "../odata/security-events-parser";

interface RawLegacyClipDetail {
  created_at: string;
  id: number;
  origin_event_type: string;
  url: string;
  video_channel_id: number;
}

type RawLegacyClip = {
  clip: RawLegacyClipDetail;
};

const MANUAL_ORIGIN_TYPE = "MANUAL";
const ALARM_TITLE = "Alarm";
const MOTION_TITLE = "Motion";

// This is similar to the function of the same name in security-events-parser. But here we are
// comparing two server timestamps, whereas for events we compare the event times from the panel's
// perspective. The `createdAtDate` here is when our server received the clip, and
// `lastAlarmServerTime` is when our server received the most recent alarm event.
const timeDistanceFromAlarm = (
  createdAtDate: Date,
  lastAlarmServerTime: Date
) => {
  const createdAtMS = createdAtDate.valueOf();
  const lastAlarmMS = lastAlarmServerTime.valueOf();
  const diffMS = createdAtMS - lastAlarmMS;
  const isBefore = diffMS < 0;
  const diffMinutes =
    Math.floor(createdAtMS / 1000 / 60) - Math.floor(lastAlarmMS / 1000 / 60);
  const absDiffMinutes = Math.abs(diffMinutes);
  let diffString = "";
  if (absDiffMinutes === 1) {
    diffString = "1 minute";
  } else {
    diffString = `${absDiffMinutes} minutes`;
  }

  return `${diffString} ${isBefore ? "before" : "after"} latest alarm`;
};

export const parseLegacyClips = (
  rawClips: RawLegacyClip[],
  cameraName: string,
  lastAlarmServerTime: Date
): SecurityEvent[] => {
  const securityEvents: SecurityEvent[] = rawClips.map((rawClip) => {
    const detail = rawClip.clip;
    // We treat a clip as an "Alarm" clip if it has an `origin_event_type` of "MANUAL." That's
    // because clips from Video Actions are labeled as "MANUAL," in contrast to clips triggered
    // by motion detection. Unfortunately, that means that truly manual clips triggered by a user
    // clicking the record button in VK will be displayed as "Alarm" clips in the Video
    // Verification page. But we don't have a way to distinguish the Video Action clips from the
    // manual clips. In practice this probably isn't a big deal, and if someone happens to manually
    // trigger a recording during an alarm situation that a monitoring center is looking at, it's
    // probably worth checking out.
    const isAlarm =
      detail.origin_event_type.toUpperCase() === MANUAL_ORIGIN_TYPE;
    const createdAtDate = new Date(detail.created_at);
    const displayDate = createdAtDate.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const displayTime = createdAtDate.toLocaleTimeString("en-US", {
      timeStyle: "short",
    });

    return {
      cameraId: detail.video_channel_id.toString(),
      cameraName: cameraName,
      cameraType: "legacy",
      eventSource: EventSource.Clips,
      eventTitle: isAlarm ? ALARM_TITLE : MOTION_TITLE,
      id: detail.id,
      imageUrl: null,
      isAlarm,
      displayDate,
      displayTime,
      // The `sequenceMS` value for a legacy clip shouldn't be considered to have any relationship
      // to the `sequenceMS` value for an event from OData. Since we present events and clips to
      // the user separately, we only need to compare `sequenceMS` values within either events or
      // clips.
      sequenceMS: createdAtDate.valueOf(),
      timeDistanceFromAlarm: timeDistanceFromAlarm(
        createdAtDate,
        lastAlarmServerTime
      ),
      videoUrl: detail.url,
    };
  });

  return securityEvents.sort((a, b) => {
    return a.sequenceMS < b.sequenceMS ? 1 : -1;
  });
};
