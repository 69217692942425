import { useParentRelayEnvironmentLookupFragment } from "components/RelayEnvironmentCloneProvider";
import * as React from "react";
import { GraphQLTaggedNode } from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";

const OriginalControlSystemContext = React.createContext<any>({});

export function OriginalControlSystemContextProvider(props: {
  controlSystem: any;
  children: React.ReactNode;
}) {
  return (
    <OriginalControlSystemContext.Provider value={props.controlSystem}>
      {props.children}
    </OriginalControlSystemContext.Provider>
  );
}

export const useOriginalControlSystem = () =>
  React.useContext(OriginalControlSystemContext);

export function useOriginalControlSystemData() {
  const originalControlSystem = useOriginalControlSystem();
  const lookupFragment = useParentRelayEnvironmentLookupFragment();

  return function <TKey extends KeyType>(fragmentInput: GraphQLTaggedNode) {
    return lookupFragment(fragmentInput, originalControlSystem as TKey)
      ?.data as KeyTypeData<TKey>;
  };
}
