import graphql from "babel-plugin-relay/macro";
import { GenericSuspenseFallback } from "components/SiteForm/SuspenseFallback";
import React, { Suspense, useEffect, useState } from "react";

import noop from "common/utils/universal/noop";
import FormGridItems from "components/FormGridItems";
import Modal from "components/Modal";
import NeutralText from "components/NeutralText";
import { useUserCanUpdateHikvisionDoorbellFirmware } from "components/SiteForm/EntryPointContext";
import {
  RefetchFnDynamic,
  useFragment,
  useMutation,
  useRefetchableFragment,
} from "react-relay/hooks";
import { OperationType } from "relay-runtime";
import Grid from "./VideoSection";
import styles from "./VideoSection.module.css";
import { SecureComDoorbellsDeleteMutation } from "./__generated__/SecureComDoorbellsDeleteMutation.graphql";
import { SecureComDoorbellsUpdateMutation } from "./__generated__/SecureComDoorbellsUpdateMutation.graphql";
import {
  SecureComDoorbells_doorbell$data,
  SecureComDoorbells_doorbell$key,
} from "./__generated__/SecureComDoorbells_doorbell.graphql";
import { SecureComDoorbells_site$key } from "./__generated__/SecureComDoorbells_site.graphql";

export function DoorbellHeaderLabel({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles["video-device-title"]}>
      <span>{title}</span>
      <span className={styles["video-device-title-value"]}>{children}</span>
    </div>
  );
}

function SuspenseBoundary({ children }: { children: React.ReactNode }) {
  return <Suspense fallback={<GenericSuspenseFallback />}>{children}</Suspense>;
}

export default function SecureComDoorbells({
  siteRef,
}: {
  siteRef: SecureComDoorbells_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment SecureComDoorbells_site on Site {
        securecomDoorbells {
          ...SecureComDoorbells_doorbell
          id
        }
      }
    `,
    siteRef
  );

  return (
    <Grid>
      {data.securecomDoorbells.length ? (
        data.securecomDoorbells.map((securecomDoorbell) => (
          <SuspenseBoundary key={securecomDoorbell.id}>
            <FormGridItems>
              <SecureComDoorbell securecomDoorbellRef={securecomDoorbell} />
            </FormGridItems>
          </SuspenseBoundary>
        ))
      ) : (
        <div>
          <span className="info-alert">
            <i className="icon-radial_alert" />
            Video Doorbell service is enabled, but no doorbell devices have been
            configured.
          </span>
        </div>
      )}
    </Grid>
  );
}

function StatusMessage({
  doorbell,
}: {
  doorbell: SecureComDoorbells_doorbell$data;
}) {
  return (
    <>
      <span>
        {doorbell.isUpdating ? (
          <i className="fa fa-spin fa-spinner" />
        ) : doorbell.updateStatus === "FAILED" ? (
          <i className="icon-radial_alert" />
        ) : (
          ""
        )}
      </span>

      <span>
        {doorbell.updateStatus === "DEVICE_REBOOT" ? (
          "Device rebooting"
        ) : doorbell.updateStatus === "FAILED" ? (
          "Update failed"
        ) : doorbell.updateStatus === "ERROR_UNKNOWN" ? (
          "Unknown error"
        ) : doorbell.updateStatus === "UPGRADING" ? (
          `Updating ...${doorbell.updateProgress}`
        ) : !doorbell.updateAvailable ? (
          <>
            <i className="icon-checkmark font-sz-12 text-success mar-r-8"></i>
            <span className="text-success">Up to date</span>
          </>
        ) : doorbell.updateAvailable ? (
          "Update available"
        ) : (
          ""
        )}
      </span>
    </>
  );
}

function SecureComDoorbell({
  securecomDoorbellRef,
}: {
  securecomDoorbellRef: SecureComDoorbells_doorbell$key;
}) {
  const [doorbell, refetch] = useRefetchableFragment(
    graphql`
      fragment SecureComDoorbells_doorbell on SecureComDoorbell
      @refetchable(queryName: "SecurecomDoorbellRefetchQuery") {
        id
        name
        model
        firmwareVersion
        latestFirmwareVersion
        serialNumber
        updateStatus
        updateProgress
        isUpdating
        controlSystemId
        deviceType
        manufacturer
        status
        verificationCode
        updateAvailable
      }
    `,
    securecomDoorbellRef
  );

  const [doorbellModalOpen, setDoorbellModalOpen] =
    React.useState<React.ReactNode>(false);

  useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (
      doorbell.isUpdating ||
      doorbell.updateStatus === "UPGRADING" ||
      doorbell.updateStatus === "DEVICE_REBOOT" ||
      doorbell.updateStatus === "ERROR_UNKNOWN"
    ) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetch({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 10000));
        }, 10000)
      );
    }
    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [doorbell, refetch]);

  return (
    <>
      {doorbellModalOpen && (
        <SecureComDoorbellModal
          doorbell={doorbell}
          onClose={() => setDoorbellModalOpen(false)}
          refetch={refetch}
        />
      )}
      <FormGridItems.Item>
        <FormGridItems.ClickableContent
          as="button"
          onClick={() => {
            setDoorbellModalOpen(true);
          }}
        >
          <FormGridItems.ItemTitle>{doorbell.name}</FormGridItems.ItemTitle>
        </FormGridItems.ClickableContent>
      </FormGridItems.Item>
    </>
  );
}

const SecureComDoorbellModal = ({
  doorbell,
  onClose,
  refetch,
}: {
  doorbell: SecureComDoorbells_doorbell$data;
  onClose: () => void;
  refetch: RefetchFnDynamic<OperationType, SecureComDoorbells_doorbell$key>;
}) => {
  const [confirmingUpdate, setConfirmingUpdate] = useState(false);
  const [attemptingUpdate, setAttemptingUpdate] = useState(false);
  const [attemptingDelete, setAttemptingDelete] = useState(false);

  const [deleteDoorbell, deletingDoorbell] =
    useMutation<SecureComDoorbellsDeleteMutation>(graphql`
      mutation SecureComDoorbellsDeleteMutation($id: ID!) {
        deleteSecureComDoorbell(id: $id) {
          ... on FailedToDeleteSecureComDoorbellError {
            errorType: type
          }

          ... on DeleteSecureComDoorbellPayload {
            controlSystem {
              site {
                id
                ...SecureComDoorbells_site
              }
            }
          }
        }
      }
    `);

  const [updateDoorbell, updatingDoorbell] =
    useMutation<SecureComDoorbellsUpdateMutation>(graphql`
      mutation SecureComDoorbellsUpdateMutation($id: ID!) {
        updateSecureComDoorbell(id: $id) {
          ... on NotFoundError {
            errorType: type
          }

          ... on FailedToUpdateSecureComDoorbellError {
            errorType: type
          }

          ... on UpdateSecureComDoorbellPayload {
            controlSystem {
              site {
                id
              }
            }
            doorbell {
              ...SecureComDoorbells_doorbell
            }
          }
        }
      }
    `);

  const updateDevice = (id: string) => async () => {
    setConfirmingUpdate(false);
    setAttemptingUpdate(true);
    updateDoorbell({ variables: { id } });
    await new Promise((r) => setTimeout(r, 5000));
    refetch({}, { fetchPolicy: "network-only" });
    setAttemptingUpdate(false);
  };

  const deleteDevice = (id: string) => () => {
    deleteDoorbell({ variables: { id } });
  };

  const userCanUpdate = useUserCanUpdateHikvisionDoorbellFirmware();
  const readyToUpdateOrDelete =
    !deletingDoorbell && !updatingDoorbell && !attemptingUpdate;
  return (
    <Modal
      onClickOutside={() => {
        onClose();
      }}
    >
      <Modal.Header>
        <h3 className="mar-0">{doorbell.name}</h3>
      </Modal.Header>
      <Modal.Body className="pad-0">
        <table className="table table-striped p0">
          <tbody>
            <tr>
              <td style={{ fontWeight: 700 }}>Model</td>
              <td>
                <NeutralText>{doorbell.model}</NeutralText>
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 700 }}>Serial Number</td>
              <td>
                <NeutralText>{doorbell.serialNumber}</NeutralText>
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 700 }}>Firmware</td>
              <td>
                <NeutralText>{doorbell.firmwareVersion}</NeutralText>
                <div>
                  <NeutralText>{StatusMessage({ doorbell })}</NeutralText>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        {attemptingDelete && (
          <>
            Are you sure you want to delete this doorbell?
            <button
              style={{ marginLeft: "1rem" }}
              className="btn btn-sm btn-alert"
              onClick={deleteDevice(doorbell.id)}
            >
              Delete
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setAttemptingDelete(false)}
            >
              Cancel
            </button>
          </>
        )}
        {confirmingUpdate && (
          <>
            <div className="text-danger pad-b-16">
              <strong>Note:</strong> Powering down the Video Doorbell during the
              update can cause damage.
            </div>
            <span>Do you want to proceed with the update?</span>
            <button
              style={{ marginLeft: "1rem" }}
              className="btn btn-sm btn-dmp"
              onClick={updateDevice(doorbell.id)}
            >
              Update
            </button>
            <button
              className="btn btn-sm btn-default"
              onClick={() => setConfirmingUpdate(false)}
            >
              Cancel
            </button>
          </>
        )}
        {userCanUpdate &&
          doorbell.updateAvailable &&
          !attemptingDelete &&
          !confirmingUpdate && (
            <button
              className="btn btn-sm btn-primary"
              disabled={
                !readyToUpdateOrDelete ||
                attemptingUpdate ||
                doorbell.updateStatus === "UPGRADING" ||
                doorbell.updateStatus === "DEVICE_REBOOT" ||
                doorbell.updateStatus === "ERROR_UNKNOWN"
              }
              onClick={() => {
                if (readyToUpdateOrDelete) {
                  setConfirmingUpdate(true);
                  // setAttemptingUpdate(true);
                } else noop();
              }}
            >
              {updatingDoorbell ||
              attemptingUpdate ||
              doorbell.updateStatus === "UPGRADING" ||
              doorbell.updateStatus === "DEVICE_REBOOT" ? (
                <>
                  <i className="fa fa-spin fa-spinner" />
                  Updating
                </>
              ) : (
                "Update"
              )}
            </button>
          )}
        {!attemptingDelete && !confirmingUpdate && (
          <>
            <button
              className="btn btn-sm btn-alert"
              disabled={!readyToUpdateOrDelete}
              onClick={() => {
                readyToUpdateOrDelete ? setAttemptingDelete(true) : noop();
              }}
            >
              Delete
            </button>

            <button
              className="btn btn-sm btn-default"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
