/**
 *
 * DinnerIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DinnerIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <rect fill="none" width="64" height="64" />
      <g>
        <g>
          <path
            d="M59,53H4c-0.666,0-1.288,0.146-1.659,0.698c-0.372,0.552-0.444,1.16-0.193,1.777l3,7.325C5.454,63.554,6.187,64,7,64h49
        c0.813,0,1.546-0.492,1.853-1.246l3-7.371c0.251-0.617,0.179-1.133-0.193-1.685C60.288,53.146,59.666,53,59,53z M54.654,60H8.346
        l-1.372-3h49.053L54.654,60z"
          />
          <path
            d="M12.084,50h10.723c0,0,0.483-0.206-0.078-1.81c-0.373-1.061-1.17-2.094-2.4-1.606c-0.746-2.366-2.184-1.912-3.512-1.788
        c-1.697,0.149-2.734,0.259-2.576,2.291C12.809,47.012,11.316,48.511,12.084,50z"
          />
          <path d="M24,48c0,1.104,0.896,2,2,2h21c1.104,0,2-0.896,2-2s-0.896-2-2-2H26C24.896,46,24,46.896,24,48z" />
          <path
            d="M60.586,12.414C60.977,12.805,61.488,13,62,13s1.023-0.195,1.414-0.586c0.781-0.781,0.781-2.047,0-2.828l-3.429-3.43
        c0.044-0.562-0.141-1.14-0.571-1.57s-1.008-0.615-1.571-0.57l-3.429-3.43c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.047,0,2.828
        L54.672,6.5l-1.911,1.911c-5.314-3.565-10.883-5.394-16.584-5.394c-11.843,0-19.67,7.648-19.732,7.725
        c-0.676,0.836-0.569,2.057,0.239,2.764l38.272,33.443c0.364,0.318,0.821,0.47,1.282,0.47c0.562,0,1.13-0.227,1.536-0.653
        c0.064-0.069,0.129-0.141,0.187-0.213c0.037-0.047,0.071-0.094,0.104-0.144c7.811-11.819,6.946-24.29-2.338-35.309L57.5,9.328
        L60.586,12.414z M55.837,42.406L21.119,12.07c2.643-1.888,8.109-5.053,15.058-5.053c5.285,0,10.493,1.844,15.482,5.479
        C56.699,18.025,63.543,28.965,55.837,42.406z"
          />
          <path
            d="M20,31c6.743,0,6.994,7.178,7,8.006C27.003,40.107,27.897,41,29,41c1.104,0,2-0.896,2-2c0-4.15-2.299-12-11-12
        c-4.581,0-10.574-2.172-13.166-6.322c-1.614-2.586-1.627-5.547-0.037-8.8c0.485-0.992,0.073-2.189-0.919-2.675
        c-0.993-0.486-2.19-0.072-2.675,0.919c-2.785,5.7-1.408,10.038,0.238,12.675C6.96,28.433,14.491,31,20,31z"
          />
          <path
            d="M13.633,37.897c3.916-1.306,5.28,1.314,5.518,1.864C19.468,40.532,20.213,41,21.001,41c0.247,0,0.498-0.045,0.742-0.143
        c1.025-0.41,1.524-1.574,1.114-2.6c-0.935-2.336-4.326-6.207-10.49-4.155c-3.187,1.063-5.782,0.391-7.146-0.592
        C4.445,32.951,4,32.264,4,31.625c0-1.104-0.896-2-2-2s-2,0.896-2,2c0,1.941,1.051,3.812,2.882,5.131
        C5.689,38.779,9.708,39.206,13.633,37.897z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default DinnerIcon;
