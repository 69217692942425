import { setToggle } from "common/utils/universal/set";
import * as React from "react";

export type IncludedTemplateFieldsType = Set<string>;

const IncludedTemplateFieldsContext = React.createContext<
  [
    IncludedTemplateFieldsType,
    React.Dispatch<React.SetStateAction<IncludedTemplateFieldsType>>
  ]
>([new Set() as IncludedTemplateFieldsType, () => {}]);

export default function IncludedTemplateFieldsContextProvider(props: {
  children: React.ReactNode;
}) {
  const [state, setState] = React.useState<IncludedTemplateFieldsType>(
    () => new Set()
  );
  return (
    <IncludedTemplateFieldsContext.Provider
      value={React.useMemo(() => [state, setState], [state, setState])}
    >
      {props.children}
    </IncludedTemplateFieldsContext.Provider>
  );
}

export const useIncludedTemplateFields = () =>
  React.useContext(IncludedTemplateFieldsContext);

export const useIncludedTemplateField = (id: string) => {
  const [includedFields, setIncludedFields] = useIncludedTemplateFields();
  const included = includedFields.has(id);
  return [
    included,
    () => {
      setIncludedFields(setToggle(id));
    },
  ] as const;
};
