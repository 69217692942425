/**
 * @generated SignedSource<<8df5d0f7fac9e0f2393e5d20c2203c56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationCrossZoneField_zone$data = {
  readonly crossZoneEnabled: boolean;
  readonly number: string;
  readonly retardDelayEnabled: boolean;
  readonly type: ZoneType;
  readonly " $fragmentType": "ZoneInformationCrossZoneField_zone";
};
export type ZoneInformationCrossZoneField_zone$key = {
  readonly " $data"?: ZoneInformationCrossZoneField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationCrossZoneField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationCrossZoneField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "crossZoneEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retardDelayEnabled",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "99c19e3b0ee36e0efda2ffa14ef9c428";

export default node;
