import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { CommunicationPathType } from "securecom-graphql/client";
import { useControlSystem } from "../ControlSystemContext";
import { useHardwareModel, usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { commPathAlarmReportsFieldId } from "./CommPathAlarmReportsField";
import { commPathAlarmSwitchoverFieldId } from "./CommPathAlarmSwitchoverField";
import { commPathCellApn2FieldId } from "./CommPathCellApn2Field";
import { commPathCellApnFieldId } from "./CommPathCellApnField";
import { commPathCheckInMinutesFieldId } from "./CommPathCheckInMinutesField";
import { commPathCommTypeFieldId } from "./CommPathCommTypeField";
import { useCommPathFragment } from "./CommPathContext";
import { commPathDoorAccessReportsFieldId } from "./CommPathDoorAccessReportsField";
import { commPathDuplicateAlarmsFieldId } from "./CommPathDuplicateAlarmsField";
import { commPathEncryptionFieldId } from "./CommPathEncryptionField";
import { commPathEncryptionSchemaFieldId } from "./CommPathEncryptionSchemaField";
import { commPathFailTestHoursFieldId } from "./CommPathFailTestHoursField";
import { commPathFailTimeFieldId } from "./CommPathFailTimeField";
import { commPathFirstPhoneNumberFieldId } from "./CommPathFirstPhoneNumberField";
import { commPathOpenCloseUserReportsFieldId } from "./CommPathOpenCloseUserReportsField";
import { commPathPanicTestFieldId } from "./CommPathPanicTestField";
import { commPathProtocolFieldId } from "./CommPathProtocolField";
import { commPathReceiverIpAddressFieldId } from "./CommPathReceiverIpAddressField";
import { commPathReceiverIpv6AddressFieldId } from "./CommPathReceiverIpv6AddressField";
import { commPathReceiverPortFieldId } from "./CommPathReceiverPortField";
import { commPathRetryTimeFieldId } from "./CommPathRetryTimeField";
import { commPathSecondLinePrefixFor893AFieldId } from "./CommPathSecondLinePrefixFor893AField";
import { commPathSecondPhoneNumberFieldId } from "./CommPathSecondPhoneNumberField";
import { commPathSendFailFieldId } from "./CommPathSendFailField";
import { commPathSendPathFieldId } from "./CommPathSendPathField";
import { commPathSubstitutionCodeFieldId } from "./CommPathSubstitutionCodeField";
import { commPathSupervisoryTroubleReportsFieldId } from "./CommPathSupervisoryTroubleReportsField";
import { commPathTestFrequencyDayFieldId } from "./CommPathTestFrequencyDayField";
import { commPathTestFrequencyNumberFieldId } from "./CommPathTestFrequencyNumberField";
import { commPathTestFrequencyUnitFieldId } from "./CommPathTestFrequencyUnitField";
import { commPathTestReportFieldId } from "./CommPathTestReportField";
import { commPathTestTimeFieldId } from "./CommPathTestTimeField";
import { commPathTransmissionDelayFieldId } from "./CommPathTransmissionDelayField";
import { commPathTypeFieldId } from "./CommPathTypeField";
import { commPathUse893AFieldId } from "./CommPathUse893AField";
import { commPathUseCheckInFieldId } from "./CommPathUseCheckInField";
import { commPathUseIPv6FieldId } from "./CommPathUseIPv6Field";
import { CommPathNumberField_communicationPath$key } from "./__generated__/CommPathNumberField_communicationPath.graphql";
import { CommPathNumberField_panel$key } from "./__generated__/CommPathNumberField_panel.graphql";

export const commPathListItemTemplateId = (number: string) =>
  `comm-path-${number}`;
export const COMM_PATH_NUMBER = `comm-path-number-`;
export const commPathNumberFieldId = (number: string) =>
  COMM_PATH_NUMBER + number;
export const COMM_PATH_IDS = [
  commPathTransmissionDelayFieldId,
  commPathListItemTemplateId,
  commPathNumberFieldId,
  commPathCommTypeFieldId,
  commPathTypeFieldId,
  commPathTestReportFieldId,
  commPathTestFrequencyNumberFieldId,
  commPathTestFrequencyUnitFieldId,
  commPathTestTimeFieldId,
  commPathTestFrequencyDayFieldId,
  commPathFirstPhoneNumberFieldId,
  commPathSecondPhoneNumberFieldId,
  commPathUse893AFieldId,
  commPathSecondLinePrefixFor893AFieldId,
  commPathAlarmSwitchoverFieldId,
  commPathAlarmReportsFieldId,
  commPathSupervisoryTroubleReportsFieldId,
  commPathOpenCloseUserReportsFieldId,
  commPathDoorAccessReportsFieldId,
  commPathPanicTestFieldId,
  commPathSendFailFieldId,
  commPathSendPathFieldId,
  commPathUseCheckInFieldId,
  commPathCheckInMinutesFieldId,
  commPathFailTimeFieldId,
  commPathUseIPv6FieldId,
  commPathReceiverIpAddressFieldId,
  commPathReceiverIpv6AddressFieldId,
  commPathReceiverPortFieldId,
  commPathProtocolFieldId,
  commPathRetryTimeFieldId,
  commPathSubstitutionCodeFieldId,
  commPathCellApnFieldId,
  commPathCellApn2FieldId,
  commPathDuplicateAlarmsFieldId,
  commPathFailTestHoursFieldId,
  commPathEncryptionFieldId,
  commPathEncryptionSchemaFieldId,
];

function CommPathNumberField() {
  const [{ id, number, isNew }, updateCommPath] =
    useCommPathFragment<CommPathNumberField_communicationPath$key>(
      graphql`
        fragment CommPathNumberField_communicationPath on CommunicationPath {
          id
          number
          isNew
        }
      `
    );

  const [{ communicationPaths }] =
    usePanelFragment<CommPathNumberField_panel$key>(graphql`
      fragment CommPathNumberField_panel on Panel {
        communicationPaths {
          number
        }
      }
    `);

  const fieldId = commPathNumberFieldId(String(number));
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();
  const hardwareModel = useHardwareModel();
  const disabled = !isNew;
  const { isXt75 } = resolvePanelType(hardwareModel);
  const maxNumber = isXt75 ? 4 : 8;

  const duplicatePathsExist =
    communicationPaths.filter((path) => path.number === number).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      COMM_PATH_IDS,
      duplicatePathsExist,
      String(number),
      commPathListItemTemplateId
    );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Communication Path"
      tooltip={`Communication path to the Central Station. Up to ${maxNumber} paths may be programmed.`}
    >
      <NumericInput
        id={fieldId}
        value={number}
        required
        min={1}
        max={maxNumber}
        disabled={disabled}
        inlineHelp={`1-${maxNumber}`}
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateCommPath((recordProxy) => {
            recordProxy.setValue(emptyStringOrNumber(target.value), "number");
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate commPath numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = number;
          relayEnv.commitUpdate((store) => {
            const controlSystemRecord = store.get(controlSystem.id);
            const currentCommPath = store.get(id);
            if (currentCommPath) {
              const panelRecord = controlSystemRecord?.getLinkedRecord("panel");
              const communicationPathRecords =
                panelRecord?.getLinkedRecords("communicationPaths");

              if (communicationPathRecords) {
                const takenNumbers = new Set(
                  communicationPathRecords.map((outputGroupRecord) =>
                    outputGroupRecord.getValue("id") !== id
                      ? outputGroupRecord.getValue("number")?.toString()
                      : "0"
                  )
                );

                const valueAsNumber = Number(target.value);
                const value = closest(
                  !target.value || isNaN(valueAsNumber) ? 1 : valueAsNumber,
                  range(1, 9).filter((num) => !takenNumbers.has(num.toString()))
                );
                currentCommPath.setValue(value, "number");
                if (value === 1) {
                  currentCommPath.setValue(
                    CommunicationPathType.PRIMARY,
                    "type"
                  );
                }
                blurredNumber = value;
              }
            }
          });
          onBlurNumberUpdate(blurredNumber.toString());
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathNumberField;
