/**
 * Utils for working with React suspense
 */
import { curry } from "ramda";

type ResourceStatus = "pending" | "error" | "success";

export type Resource<A> = {
  key?: string;
  promise: Promise<A>;
  status: ResourceStatus;
  read: () => A | never;
};

export function makeSuspendable<A>(
  promise: Promise<A>,
  key?: string
): Resource<A> {
  let status: ResourceStatus = "pending";
  let result: A | Error | null = null;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    key,
    promise,
    get status() {
      return status;
    },
    read(): A | never {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      }

      return result as A;
    },
  };
}

export function createCachedResource<Result, Args extends any[]>(
  fetchData: (...args: Args) => Promise<Result>,
  createCacheKey = (...args: Args) => "INDEX"
) {
  const resources = new Map();
  const prevArgsByCacheKey = new Map();

  return (...args: Args) => {
    const cacheKey = createCacheKey(...args);
    const prevArgs = prevArgsByCacheKey.get(cacheKey);

    if (
      !resources.has(cacheKey) ||
      args.some((arg, index) => arg !== prevArgs[index])
    ) {
      const createResource = (timestamp: number) => ({
        ...makeSuspendable(fetchData(...args), cacheKey),
        timestamp,
        dispose() {
          resources.delete(cacheKey);
        },
        refresh() {
          resources.set(cacheKey, createResource(Date.now()));
          return resources.get(cacheKey).promise;
        },
      });

      resources.set(cacheKey, createResource(Date.now()));
    }

    prevArgsByCacheKey.set(cacheKey, args);
    return resources.get(cacheKey);
  };
}

export const readOr = curry((defaultValue, resource) => {
  try {
    return resource.read();
  } catch (error) {
    return defaultValue;
  }
});
