/**
 * @generated SignedSource<<adf6efb150ae16a35c4ff4b201a64a62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XROutputGroupsProgrammingConceptFormInline_controlSystem$data = {
  readonly __typename: "ControlSystem";
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly maxOutputGroups: number;
    readonly outputGroups: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"XROutputGroupsProgrammingConceptFormInline_outputGroup">;
    }>;
    readonly outputGroupsRange: ReadonlyArray<number>;
  };
  readonly " $fragmentType": "XROutputGroupsProgrammingConceptFormInline_controlSystem";
};
export type XROutputGroupsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XROutputGroupsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XROutputGroupsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XROutputGroupsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "fa9244f7b4aa9d26cdc1e8e1833f7a40";

export default node;
