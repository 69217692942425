import styled from "styled-components";

/**
 * Multiple column grid.
 *
 * Below 768px becomes a single column grid.
 */
export const SimpleGrid = styled.div<{
  rows?: number;
  columns?: number;
}>`
  display: grid;
  grid-row-gap: var(--measure-2x);
  grid-column-gap: var(--measure-2x);
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-auto-flow: column;
    grid-template-rows: repeat(${({ rows }) => rows ?? 1}, 1fr);
    grid-template-columns: repeat(${({ columns }) => columns ?? 1}, 1fr);
  }
`;
