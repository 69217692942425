/**
 * @ngdoc object
 * @name App.controller:ReplacePanelCtrl
 *
 *
 * @description
 *  Controller for the panel replacement modal
 */
App.controller("ReplacePanelCtrl", [
  "$scope",
  "$modalInstance",
  "ControlSystemsService",
  "Panel",
  "$state",
  "UserService",
  "controlSystemForm",
  "originalSerialNumber",
  "ReplacePanelService",
  "PanelProgArchiveService",
  "$filter",
  "$q",
  "$rootScope",
  function (
    $scope,
    $modalInstance,
    ControlSystemsService,
    Panel,
    $state,
    UserService,
    controlSystemForm,
    originalSerialNumber,
    ReplacePanelService,
    PanelProgArchiveService,
    $filter,
    $q,
    $rootScope
  ) {
    $scope["controlSystemForm"] = controlSystemForm;
    $scope["new_serial_number"] = "";
    $scope["hasPanelErrors"] = false;
    $scope["panelErrorMessages"] = [];
    $scope["panel"] =
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ];
    $scope["appKey"] = UserService.dealerInfo.app_key;
    $scope["progData"] = {
      backups: [],
      cached: false,
      initialized: false,
      selected: {
        type: null,
        id: null,
        model: null,
        set: function () {
          if (this.model) {
            let obj = angular.fromJson(this.model);
            this.type = obj.type;
            this.id = obj.id;
          }
        },
      },
    };

    var alreadyUsedSerialNumbers = [];

    $scope.init = function () {
      var panel = new Panel(UserService.panel_id);
      var promises = [];
      promises.push(
        panel
          .fetch("system_options")
          .then(
            function () {
              if ($scope.panel.hardware_family === "XF6") {
                $scope.progData.cached = true;
              } else {
                $scope.progData.cached = DoesNestedPropertyExist(
                  panel,
                  "system_options.arm_mode"
                );
              }
            },
            function (error) {}
          )
          .catch(function (error) {})
      );
      promises.push(
        PanelProgArchiveService.getBackupsForPanel(UserService.panel_id)
          .then(
            function (data) {
              $scope.progData.backups = data;
            },
            function (error) {}
          )
          .catch(function (error) {})
      );
      $q.all(promises).then(
        function () {
          $scope.progData.initialized = true;
        },
        function () {
          $rootScope.alerts.push({
            type: "error",
            text: "error retrieving data",
          });
        }
      );
    };

    $scope.formatOptionLabel = function (backup) {
      backup.optionLabel = `Backup from ${$filter("date")(
        backup.CreatedUtc,
        "short"
      )}`;
      if (backup.Type === "Original") {
        backup.optionLabel += " (original)";
      }
    };

    /**
     * Processed on serial number change
     * note: new_serial_number only has a value if it passes the ng-pattern regular expression, otherwise it is undefined
     */
    $scope.serialNumberChange = function () {
      if (angular.isDefined($scope.new_serial_number)) {
        // Check that new serial number is different from current
        if (originalSerialNumber === $scope.new_serial_number) {
          $scope.replacePanelForm.panel_swap_new_serial_number.$setValidity(
            "noChange",
            false
          );
        }
        // Check that new serial number is not in list of already used
        else if (
          alreadyUsedSerialNumbers.indexOf($scope.new_serial_number) > -1
        ) {
          $scope.hasPanelErrors = true;
        } else {
          $scope.replacePanelForm.panel_swap_new_serial_number.$setValidity(
            "noChange",
            true
          );
          $scope.hasPanelErrors = false;
        }
      } else {
        $scope.hasPanelErrors = false;
      }
    };

    /**
     * Watch for panelerrors (returned from SCAPI)
     */
    $scope.$watch("controlSystemForm.$error.panelerrors", function (errors) {
      if (angular.isDefined(errors) && angular.isArray(errors)) {
        var serialNumberErrors = errors.find(function (error) {
          return (
            error.hasOwnProperty("$name") &&
            error.$name === "panels_serial_number"
          );
        });
        if (
          angular.isDefined(serialNumberErrors) &&
          serialNumberErrors.hasOwnProperty("$viewValue")
        ) {
          if (
            alreadyUsedSerialNumbers.indexOf(serialNumberErrors.$viewValue) ===
            -1
          ) {
            alreadyUsedSerialNumbers.push($scope.new_serial_number);
          }
          //cache errors since they are cleared when the serial number on the control system is restored to its original value
          $scope.hasPanelErrors = true;
          $scope.panelErrorMessages =
            ControlSystemsService.currentControlSystem.errors.panels_serial_number;
          // restore the ControlSystemsService serial number so that everything displays correctly and new errors can
          // be picked up
          ControlSystemsService.currentControlSystem.panels[
            ControlSystemsService.currentControlSystem.panel_index
          ].serial_number = originalSerialNumber;
        }
      }
    });

    /**
     * When a push or pull option is selected from the panel replacement modal
     * @param dataAction
     */
    $scope.swapPanel = function (dataAction) {
      switch (dataAction) {
        case "push":
          switch ($scope.progData.selected.type) {
            case "cache":
              finalizeSwap(dataAction);
              break;
            case "backup":
              PanelProgArchiveService.cache.backup
                .load($scope.progData.selected.id)
                .then(
                  function () {
                    finalizeSwap(dataAction);
                  },
                  function (error) {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "error caching backup",
                    });
                  }
                )
                .catch(function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "caught error caching backup",
                  });
                });
              break;
          }
          break;
        case "pull":
          finalizeSwap(dataAction);
          break;
      }
    };

    function finalizeSwap(dataAction) {
      ReplacePanelService.setReplacementData(
        $scope.panel.id,
        dataAction,
        $scope.new_serial_number,
        ControlSystemsService.currentControlSystem.services_manager
          .store_user_codes && UserService.dealerInfo.store_user_codes
      );
      $modalInstance.dismiss("close");
      $state.go("app.panel_programming.programming_full", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
        panel_id: UserService.panel_id,
      });
    }

    /**
     * Close modal
     */
    $scope.close = function () {
      ControlSystemsService.currentControlSystem.panels[
        ControlSystemsService.currentControlSystem.panel_index
      ].serial_number = originalSerialNumber;
      $modalInstance.dismiss("close");
    };
  },
]);
