import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { range } from "ramda";
import * as React from "react";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation.graphql";

export const systemAreaInformationEarlyMorningAmbushFieldId = () =>
  `system-area-information-early-morning-ambush`;

function SystemAreaInformationEarlyMorningAmbushField() {
  const [{ morningAmbush }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation on SystemAreaInformation {
          morningAmbush
        }
      `
    );

  const fieldId = systemAreaInformationEarlyMorningAmbushFieldId();
  const validEarlyMorningAmbushRange = range(0, 16);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Early Morning Ambush"
      tooltip="Require a second User Code within a programmed number of minutes, otherwise an Ambush signal is sent to the Central Station."
    >
      <NumericInput
        id={fieldId}
        required
        pattern="(0?[0-9]|1[0-5])"
        validationMessage="Valid values are 0-15."
        inlineHelp="0-15"
        value={morningAmbush}
        min={0}
        max={15}
        onChange={({ target }) => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "morningAmbush"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemAreaInformation((recordProxy) => {
            const valueAsNumber = Number(target.value);
            recordProxy.setValue(
              closest(valueAsNumber, validEarlyMorningAmbushRange),
              "morningAmbush"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationEarlyMorningAmbushField;
