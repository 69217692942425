/**
 * @ngdoc service
 * @name App.factory:CustomerReferralAPI
 *
 * @description
 * API factory for Customer Referral Program
 *
 */

App.factory("CustomerReferralTplAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/:type/:Dealers/:dealer_id/:id",
      {},
      {
        getExistingTpl: {
          method: "GET",
          params: {
            type: "ReferralTemplates",
            Dealers: "Dealers",
            dealer_id: "@dealer_id",
          },
        },
        createReferralTpl: {
          method: "POST",
          params: { type: "ReferralTemplates" },
        },
        changeReferralTpl: {
          method: "PUT",
          params: { type: "ReferralTemplates", id: "@id" },
        },
        getCustomerReferral: {
          method: "GET",
          params: {
            type: "Referrals",
            Dealers: "Dealers",
            dealer_id: "@dealer_id",
          },
          isArray: true,
        },
        deleteCustomerReferral: {
          method: "DELETE",
          params: { type: "Referrals", id: "@id" },
        },
        changeCustomerReferral: {
          method: "PUT",
          params: { type: "Referrals", id: "@id" },
        },
        getCustomerReferralsCount: {
          method: "GET",
          params: {
            type: "Referrals",
            Dealers: "Dealers",
            dealer_id: "@dealer_id",
            id: "Data",
          },
        },
      }
    );
  },
]);
