/**
 * @name App.directive:daScheduleTimeSlot
 * @desc Creates a time entry slot / input for schedule programming
 * @property slotLabel: string - Label of directive of time entry. ex: Begin or End, On or Off, etc.
 * @property displayDay: Boolean - is the day shown for the slot
 * @property selectableDay: Boolean - can the day be edited for the slot
 * @property useTwilightTimes: Boolean - are the sunrise / sunset checkboxes shown
 * @property hintWord: string - Word used for placeholder text
 * @property form: the parent form (for field validation)
 * @property field: the field being manipulated
 * @property schedule: the schedule being manipulated
 * @property day: the day the slot represents
 * @property twilightPhrase: the phrase displayed if sunrise / sunset is selected
 * @property date: the date field being manipulated
 * @property processCheckFn: function that is processed when a sunrise / sunset checkbox is changed
 *
 * @example <da-schedule-time-slot slot-label="End" display-day="true" selectable-day="true" use-twilight-times="panel.capabilities.general_twilight_scheduling"
              hint-word="End" form="scheduleForm" field="day.endField" schedule="schedule" date="day.end_date" day="day"
              twilight-phrase="twilightData.phrases[1][$index]" process-check-fn="setAnyChecked()">
            </da-schedule-time-slot>
 */

App.directive("daScheduleTimeSlot", daScheduleTimeSlot);

daScheduleTimeSlot.$inject = ["scheduleUtilService"];
function daScheduleTimeSlot(scheduleUtilService) {
  return {
    restrict: "E",

    scope: {
      slotLabel: "@",
      displayDay: "=",
      selectableDay: "=",
      useTwilightTimes: "=",
      hintWord: "@",
      form: "=",
      field: "=",
      schedule: "=",
      day: "=",
      twilightPhrase: "=",
      date: "=",
      processCheckFn: "&",
      holiday: "=",
    },

    templateUrl: "/app/common/templates/schedule-time-slot.html",

    link: function (scope) {
      scope.days = scheduleUtilService.days;
      scope.sun = scope.schedule[scope.field + "_use_twilight_scheduling"];

      scope.check = function () {
        scope.schedule[scope.field + "_use_twilight_scheduling"] =
          !scope.schedule[scope.field + "_use_twilight_scheduling"];
        scope.sun = scope.schedule[scope.field + "_use_twilight_scheduling"];
        scope.processCheckFn();
      };

      scope.dateTimeForceUTC = function (inDateStr) {
        var inDate = new Date(inDateStr);
        return dateTimeForceUTC(inDate);
      };
    },
  };
}
