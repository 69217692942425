/**
 * @generated SignedSource<<30a8c88785a2c0b5a9bac5b2fcf538a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaCommonAreaField_xrArea$data = {
  readonly commonArea: boolean;
  readonly number: string;
  readonly " $fragmentType": "AreaCommonAreaField_xrArea";
};
export type AreaCommonAreaField_xrArea$key = {
  readonly " $data"?: AreaCommonAreaField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaCommonAreaField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaCommonAreaField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commonArea",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "e8372ac5998d0c1be12fa8e3d5e63bfc";

export default node;
