App.factory("AuditAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.reportsApiUrl + "/api/v1/audit_logs",
      {},
      {
        getLogs: { method: "GET" },
      }
    );
  },
]);
