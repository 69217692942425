/**
 * @generated SignedSource<<6767e85ad467021aa43e1665bc41c898>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CameraListItem_getCameraSnapshotMutation$variables = {
  cameraId: string;
  systemId: string;
};
export type CameraListItem_getCameraSnapshotMutation$data = {
  readonly takeVarCameraSnapshot: {
    readonly image_url?: string | null;
    readonly type?: string | null;
  };
};
export type CameraListItem_getCameraSnapshotMutation = {
  response: CameraListItem_getCameraSnapshotMutation$data;
  variables: CameraListItem_getCameraSnapshotMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cameraId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cameraId",
    "variableName": "cameraId"
  },
  {
    "kind": "Variable",
    "name": "systemId",
    "variableName": "systemId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_url",
      "storageKey": null
    }
  ],
  "type": "TakeVarCameraSnapshotSuccessPayload",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "TakeVarCameraSnapshotErrorPayload",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CameraListItem_getCameraSnapshotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "takeVarCameraSnapshot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CameraListItem_getCameraSnapshotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "takeVarCameraSnapshot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "212f30b35282d013936ac8a40e3e4dd0",
    "id": null,
    "metadata": {},
    "name": "CameraListItem_getCameraSnapshotMutation",
    "operationKind": "mutation",
    "text": "mutation CameraListItem_getCameraSnapshotMutation(\n  $cameraId: String!\n  $systemId: ID!\n) {\n  takeVarCameraSnapshot(cameraId: $cameraId, systemId: $systemId) {\n    __typename\n    ... on TakeVarCameraSnapshotSuccessPayload {\n      image_url\n    }\n    ... on TakeVarCameraSnapshotErrorPayload {\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f184cb8ddaf6366452f1e9f21e37747";

export default node;
