import { Flex, Table } from "components/DaStyledElements";
import Modal from "components/Modal";
import React from "react";

import LoadingSpinner from "components/LoadingSpinner";
import styled from "styled-components";

const InlineLoadingSpinner = styled.div`
  font-size: 20px;
  padding-left: 10px;
  display: inline-flex;
`;

//Set a modal that will display a loading symbol for LX diagnosics, but not run the gql query
function DiagnosticModal({
  setModalOpen,
}: {
  setModalOpen: (modalOpen: boolean) => void;
}) {
  return (
    <Modal className="" size="large" onClickOutside={() => setModalOpen(false)}>
      <Modal.Header>
        <span className="h1">LX Bus Diagnostics</span>
        <InlineLoadingSpinner>
          <LoadingSpinner />
        </InlineLoadingSpinner>
      </Modal.Header>
      <Modal.Body className="modal-grid__padding-bottom">
        <Table>
          <thead>
            <tr>
              <td>Number</td>
              <td>Status</td>
            </tr>
          </thead>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Flex.Row align="right" justify="right">
          <button
            className="btn btn-default btn-md"
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </button>
        </Flex.Row>
      </Modal.Footer>
    </Modal>
  );
}

export default DiagnosticModal;
