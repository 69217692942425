import * as React from "react";
import AnimatedCycleIcon from "../Icons/AnimatedCycleIcon";

interface DaRefreshButtonProps {
  isRefreshing?: boolean;
  showIcon?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const DaRefreshButton = ({ isRefreshing = false, showIcon = true, onClick }: DaRefreshButtonProps) => {
  return (
    <button
      className="btn btn-sm btn-dmp"
      disabled={isRefreshing}
      onClick={onClick}
    >
      {showIcon && (
        <AnimatedCycleIcon
          animated={isRefreshing}
          style={{ marginRight: "var(--measure-1x)" }}
        />
      )}
      Refresh
    </button>
  );
};


export default DaRefreshButton;
