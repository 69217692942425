import Keypads from "components/Keypads/ControlSystemKeypads";
import { react2angular } from "react2angular";

App.component(
  "controlsystemkeypads",
  react2angular(
    Keypads,
    [],
    [
      "RelayService",
      "$stateParams",
      "UserService",
      "$rootScope",
      "DealerService",
      "ControlSystemsService",
      "$state",
    ]
  )
);
