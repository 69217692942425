import { getSecureComUrlCreators } from "common/utils/universal/securecom-urls";
import { SecureComEnv } from "common/utils/universal/types";
import { urlIsValid } from "common/utils/universal/url";
import { SmallButton } from "components/Button";
import { Flex, Form } from "components/DaStyledElements";
import { ErrorMsg } from "components/DaStyledElements/Form";
import Modal from "components/Modal";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useState } from "react";
import { commitLocalUpdate, useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  Dealer,
  DownloadableFile,
  DownloadableFileType,
  idAsString,
  toDealerId,
  toDownloadableFileId,
} from "securecom-graphql/client";

const UploadFileModal: React.FC<
  React.PropsWithChildren<{
    authToken: string;
    dealerId: string;
    closeModal: () => void;
    isWhitelisted: boolean;
  }>
> = ({ authToken, dealerId, closeModal, isWhitelisted }) => {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState<File | undefined>(undefined);
  const [filePath] = useState<string | undefined>(undefined);
  const [isPublic, setIsPublic] = useState(true);
  const [titleVisited, setTitleVisited] = useState(false);
  const [linkFieldVisited, setLinkFieldVisited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const showAlert = useShowAlert();

  const relayEnv = useRelayEnvironment();

  const env = (process.env?.REACT_APP_SECURECOM_ENV ??
    "production") as SecureComEnv;

  const urls = getSecureComUrlCreators({ env });

  return (
    <Modal size="medium">
      <Modal.Header>
        <span className="h3">File Upload</span>
        <hr className="mar-t-0 mar-b-0" />
      </Modal.Header>
      <Modal.Body>
        <form>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label htmlFor="title">
                  Title (Version Number & Date)
                </Form.Label>
                <input
                  id="title"
                  className="form-control"
                  type="text"
                  required
                  maxLength={256}
                  value={title}
                  onBlur={() => {
                    setTitleVisited(true);
                  }}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
                {!title && titleVisited && (
                  <ErrorMsg>A title is required.</ErrorMsg>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label> Link to release notes</Form.Label>
                <input
                  className="form-control"
                  required
                  type="text"
                  value={link}
                  pattern="[A-Za-z]{3}"
                  onBlur={() => setLinkFieldVisited(true)}
                  onChange={(event) => {
                    setLink(event.target.value);
                  }}
                />
                {!urlIsValid(link) && linkFieldVisited && (
                  <ErrorMsg>
                    Please enter a valid link (example: https://dmp.com).
                  </ErrorMsg>
                )}
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <Flex.Col size={1}>
              <Form.Group className="required">
                <Form.Label>File</Form.Label>
                <input
                  className="form-control input-md"
                  style={{ width: "100%" }}
                  required
                  type="file"
                  value={filePath}
                  accept=".exe"
                  onChange={(event) => setFile(event.target.files?.[0])}
                />
              </Form.Group>
            </Flex.Col>
          </Flex.Row>
          <Flex.Row>
            <div className="checkbox c-checkbox checkbox-inLine needsclick">
              <label className="needsclick">
                <input
                  type="checkbox"
                  className="needsclick"
                  checked={isPublic}
                  onChange={(event) => setIsPublic(event.target.checked)}
                />
                <span className="icon-checkmark"></span>Public
              </label>
            </div>
          </Flex.Row>
          <Flex.Row justify={"flex-end"}>
            <SmallButton
              style={{ marginRight: "0.8rem" }}
              type="reset"
              text="Cancel"
              onClick={closeModal}
            />
            <SmallButton
              type="primary"
              text={isSaving ? "Saving" : "Save"}
              disabled={!title || !urlIsValid(link) || !file || isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  const formData = new FormData();
                  formData.append("title", title);
                  formData.append("link", link);
                  formData.append("file", file ?? "");
                  formData.append("public", isPublic.toString());
                  formData.append("type", "remote-link");
                  formData.append(
                    "file_name",
                    file?.name ?? `remote-link-${new Date()}`
                  );
                  const data: {
                    created_at: string;
                    guid: string;
                    link: string;
                    public: boolean;
                    title: string;
                    type: string;
                    url: string;
                  } = await fetch(urls.files(`/api/v1/files/upload`), {
                    method: "POST",
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    body: formData, // body data type must match "Content-Type" header
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  })
                    .then((response) => response.json())
                    .catch((error) => {
                      throw error;
                    });
                  commitLocalUpdate(relayEnv, (store) => {
                    const downloadableFiles = store
                      .get<Dealer>(idAsString(toDealerId(dealerId)))
                      ?.getLinkedRecord("downloadableFiles", {
                        sort: { keys: ["createdAt"], order: "DESC" },
                        downloadableFileTypes: [
                          DownloadableFileType.REMOTE_LINK,
                        ],
                      });
                    if (downloadableFiles) {
                      const nodes =
                        downloadableFiles.getLinkedRecords("nodes") ?? [];
                      const downloadableFile = store.create(
                        idAsString(
                          toDownloadableFileId(
                            dealerId,
                            data.guid,
                            "remote-link"
                          )
                        ),
                        "DownloadableFile"
                      ) as RecordProxy<DownloadableFile>;
                      downloadableFile.setValue(data.created_at, "createdAt");
                      downloadableFile.setValue(
                        idAsString(
                          toDownloadableFileId(
                            dealerId,
                            data.guid,
                            "remote-link"
                          )
                        ),
                        "id"
                      );
                      downloadableFile.setValue(data.public, "isPublic");
                      downloadableFile.setValue(data.title, "name");
                      downloadableFile.setValue(
                        DownloadableFileType.REMOTE_LINK,
                        "type"
                      );
                      downloadableFile.setValue(
                        data.url + authToken,
                        "downloadUrl"
                      );
                      downloadableFile.setValue(data.link, "infoUrl");
                      downloadableFile.setValue(
                        file?.name ?? `remote-link-${new Date()}`,
                        "fileName"
                      );
                      downloadableFiles.setLinkedRecords(
                        [downloadableFile, ...nodes],
                        "nodes"
                      );
                    }
                  });
                  if (data) {
                    showAlert({
                      type: "success",
                      text: "Created file: " + data.title,
                    });
                  }
                } catch {
                  showAlert({
                    type: "error",
                    text: "Unable to save file",
                  });
                }
                setIsSaving(false);
                closeModal();
              }}
            />
          </Flex.Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default UploadFileModal;
