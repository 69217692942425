const DaColors = {
  Black: "hsl(353, 67%, 2%)",
  White: "hsl(0, 0%, 100%)",

  Neutral20: "hsl(0, 0%, 40%)",
  Neutral25: "hsl(0, 0%, 80%)", //confirmingDelete component
  Neutral30: "hsl(0,0%, 98%)", //sidebar-bg-color
  Neutral40: "hsl(219, 17%, 43%)", //table-header-color
  Neutral50: "hsl(218, 29%, 95%)", //ahthens-gray
  Neutral100: "hsl(180, 17%, 98%)",
  Neutral200: "hsl(180, 23%, 95%)", //Zebra stripe dark color
  Neutral250: "hsl(210, 25%, 97%)",
  Neutral300: "hsl(186, 25%, 92%)",
  Neutral400: "hsl(186, 18%, 90%)",
  Neutral450: "hsl(0,0%, 80%)", //global-border-color
  Neutral500: "hsl(198, 16%, 80%)",
  Neutral600: "hsl(199, 8%, 70%)",
  Neutral700: "hsl(198, 14%, 60%)",
  Neutral800: "hsl(198, 8%, 50%)",
  Neutral900: "hsl(198, 0%, 40%)",
  Neutral1000: "hsl(208, 10%, 25%)",
  Neutral2000: "hsl(0, 0%, 20%)",

  PrimaryBase: "hsl(198, 45%, 47%)",
  Primary50: "hsl(193, 15%, 94%)",
  Primary100: "hsl(194, 20%, 95%)",
  Primary200: "hsl(195, 25%, 84%)",
  Primary300: "hsl(196, 35%, 68%)",
  Primary400: "hsl(197, 40%, 58%)",
  Primary500: "hsl(198, 45%, 47%)",
  Primary600: "hsl(199, 50%, 38%)",
  Primary700: "hsl(200, 55%, 30%)",
  Primary800: "hsl(201, 60%, 25%)",
  Primary900: "hsl(202, 65%, 20%)",
  Primary1000: "hsl(203, 70%, 10%)",

  InfoBase: "hsl(194, 64%, 56%)",
  InfoBase20Percent: "hsla(194, 64%, 56%, 0.2)", // for check boxes
  Info100: "hsl(190, 24%, 86%)",
  Info200: "hsl(191, 34%, 78%)",
  Info300: "hsl(192, 44%, 70%)",
  Info400: "hsl(193, 54%, 63%)",
  Info500: "hsl(194, 64%, 56%)",
  Info600: "hsl(195, 62%, 54%)",
  Info700: "hsl(196, 60%, 51%)",
  Info800: "hsl(196, 60%, 49%)",
  Info900: "hsl(198, 70%, 44%)",
  Info1000: "hsl(199, 80%, 24%)",

  SuccessBase: "hsl(167, 98%, 32%)",
  Success100: "hsl(171, 70%, 79%)",
  Success200: "hsl(170, 80%, 66%)",
  Success300: "hsl(169, 88%, 52%)",
  Success400: "hsl(168, 90%, 42%)",
  Success500: "hsl(167, 98%, 32%)",
  Success600: "hsl(166, 98%, 25%)",
  Success700: "hsl(166, 99%, 20%)",
  Success800: "hsl(164, 99%, 15%)",
  Success900: "hsl(160, 100%, 10%)",
  Success1000: "hsl(155, 100%, 5%)",
  SuccessBackground: "hsl(167,43%, 92%)",
  SuccessPillText: "hsl(160, 98%, 23%)",

  CautionBase: "hsl(49, 97%, 54%)",
  Caution100: "hsl(53, 67%, 86%)",
  Caution200: "hsl(52, 78%, 77%)",
  Caution300: "hsl(51, 88%, 68%)",
  Caution400: "hsl(50, 97%, 61%)",
  Caution500: "hsl(49, 98%, 54%)",
  Caution600: "hsl(48, 98%, 43%)",
  Caution700: "hsl(47, 99%, 32%)",
  Caution800: "hsl(46, 99%, 22%)",
  Caution900: "hsl(44, 100%, 10%)",
  Caution1000: "hsl(43, 100%, 5%)",

  WarningBase: "hsl(20, 100%, 60%)",
  Warning100: "hsl(19, 75%, 89%)",
  Warning200: "hsl(22, 80%, 90%)",
  Warning300: "hsl(21, 85%, 80%)",
  Warning400: "hsl(20, 97%, 70%)",
  Warning500: "hsl(20, 100%, 60%)",
  Warning600: "hsl(20, 90%, 50%)",
  Warning700: "hsl(19, 85%, 40%)",
  Warning800: "hsl(17, 70%, 30%)",
  Warning900: "hsl(16, 80%, 20%)",
  Warning1000: "hsl(15, 95%, 15%)",
  WarningLabel: "hsl(39,95%, 44%)",
  WarningBackground: "hsl(44,99%,62%)",

  FailureBase: "hsl(350, 87%, 62%)",
  Failure100: "hsl(347, 70%, 88%)",
  Failure200: "hsl(348, 80%, 81%)",
  Failure300: "hsl(349, 84%, 73%)",
  Failure400: "hsl(350, 85%, 67%)",
  Failure500: "hsl(350, 87%, 62%)",
  Failure600: "hsl(351, 88%, 40%)",
  Failure700: "hsl(351, 89%, 30%)",
  Failure800: "hsl(352, 90%, 20%)",
  Failure900: "hsl(353, 95%, 10%)",
  Failure1000: "hsl(360, 100%, 5%)",

  DefaultTextColor: "var(--color-text-default)",
  DmpButtonHover: "hsla(195, 64%, 56%, 0.2)",
};

export default DaColors;

export const defaultTheme = {
  textColor: DaColors.Neutral900,
  primaryBackgroundColor: DaColors.Neutral50,
  backgroundColor: DaColors.White,
  borderWidth: "1px",
  caretFill: DaColors.White,
  caretStrokeWidth: "4px",
  caretStrokeColor: DaColors.Neutral450,
  secondaryBackgroundColor: DaColors.White,
  tertiaryBackgroundColor: "#F9FAFF",
  borderColor: DaColors.Neutral450,
  panelBorderColor: DaColors.Neutral450,
  trueWhite: DaColors.White,
  trueBlack: DaColors.Neutral1000,
  primary: DaColors.PrimaryBase,
  primary100: DaColors.Primary100,
  primary200: DaColors.Primary200,
  primary300: DaColors.Primary300,
  primary400: DaColors.Primary400,
  primary500: DaColors.Primary500,
  primary600: DaColors.Primary600,
  primary700: DaColors.Primary700,
  primary800: DaColors.Primary800,
  primary900: DaColors.Primary900,
  primary1000: DaColors.Primary1000,
  secondary: "#22355A",
  secondaryDark: "#0A1C3B",
  secondaryLight: "#495C82",
  success: DaColors.SuccessBase,
  successBackground: DaColors.SuccessBackground,
  save: DaColors.SuccessBase,
  caution: DaColors.CautionBase,
  warning: DaColors.WarningBase,
  warningLabel: DaColors.WarningLabel,
  warningBackground: DaColors.WarningBackground,
  failure: DaColors.FailureBase,
  danger: DaColors.FailureBase,
  grayXlight: DaColors.Neutral100,
  grayLight: DaColors.Neutral50,
  grayMediumLight: DaColors.Neutral450,
  grayMedium: DaColors.Neutral800,
  gray: DaColors.Neutral1000,
  grayDark: "#161e2e",
  grayAccent: "#CCCCCC",
  red: DaColors.FailureBase,
  red100: DaColors.Failure100,
  red200: DaColors.Failure200,
  red300: DaColors.Failure300,
  red400: DaColors.Failure400,
  red500: DaColors.Failure500,
  red600: DaColors.Failure600,
  red700: DaColors.Failure700,
  red800: DaColors.Failure800,
  red900: DaColors.Failure900,
  red1000: DaColors.Failure1000,
  orange: DaColors.WarningBase,
  yellow: DaColors.CautionBase,
  yellowLight: DaColors.Caution100,
  yellow100: DaColors.Caution100,
  yellow200: DaColors.Caution200,
  yellow300: DaColors.Caution300,
  yellow400: DaColors.Caution400,
  yellow500: DaColors.Caution500,
  yellow600: DaColors.Caution600,
  yellow700: DaColors.Caution700,
  yellow800: DaColors.Caution800,
  yellow900: DaColors.Caution900,
  yellow1000: DaColors.Caution1000,
  green: DaColors.SuccessBase,
  green100: DaColors.Success100,
  green200: DaColors.Success200,
  green300: DaColors.Success300,
  green400: DaColors.Success400,
  green500: DaColors.Success500,
  green600: DaColors.Success600,
  green700: DaColors.Success700,
  green800: DaColors.Success800,
  green900: DaColors.Success900,
  green1000: DaColors.Success1000,
  greenSuccessPillText: DaColors.SuccessPillText,
  blueDark: DaColors.Primary800,
  coolBlue: DaColors.Info300,
  purple: DaColors.PrimaryBase,
  boxShadow: (color = "rgba(0,0,0,0.10)") => `0 1px 8px ${color}`,
  panelBorder: "1px solid #ccc",
  highlightColor: "#FFFBCC",
  fallbackFontFamily: "san-serif",
  primaryFontFamily:
    "Inter var,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  elevation0: "0 0 0 rgba(0,0,0,0)",
  elevation100: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.10)",
  elevation200: "0 3px 6px rgba(0,0,0,0.15), 0 2px 4px rgba(0,0,0,0.08)",
  elevation300: "0 10px 20px rgba(0,0,0,0.15), 0 3px 6px rgba(0,0,0,0.06)",
  elevation400: "0 15px 25px rgba(0,0,0,0.15), 0 5px 10px rgba(0,0,0,0.04)",
  elevation500: "0 20px 40px rgba(0,0,0,0.2)",
};
