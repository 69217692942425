/**
 * @generated SignedSource<<933cb7eee9e48acc614915f8411769a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClientApplication = "DEALER_ADMIN" | "VIRTUAL_KEYPAD";
export type ValidationMethod = "EMAIL" | "NONE" | "SMS";
export type LoginForm_TwoFALoginSetupMutation$variables = {
  clientApplication: ClientApplication;
  method: ValidationMethod;
  phoneNumber?: string | null;
  tempAuthToken?: string | null;
};
export type LoginForm_TwoFALoginSetupMutation$data = {
  readonly configureTwoFactorSettings: {
    readonly __typename: "ConfigureTwoFactorSettingsErrorResponse";
    readonly error: {
      readonly __typename: string;
    };
  } | {
    readonly __typename: "ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse";
    readonly sanitizedContactInfo: string;
    readonly uuid: string;
    readonly validationMethod: ValidationMethod;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type LoginForm_TwoFALoginSetupMutation = {
  response: LoginForm_TwoFALoginSetupMutation$data;
  variables: LoginForm_TwoFALoginSetupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientApplication"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "method"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneNumber"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tempAuthToken"
},
v4 = [
  {
    "kind": "Variable",
    "name": "clientApplication",
    "variableName": "clientApplication"
  },
  {
    "kind": "Variable",
    "name": "phoneNumber",
    "variableName": "phoneNumber"
  },
  {
    "kind": "Variable",
    "name": "tempAuthToken",
    "variableName": "tempAuthToken"
  },
  {
    "kind": "Variable",
    "name": "validationMethod",
    "variableName": "method"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validationMethod",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sanitizedContactInfo",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginForm_TwoFALoginSetupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "configureTwoFactorSettings",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "ConfigureTwoFactorSettingsErrorResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoginForm_TwoFALoginSetupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "configureTwoFactorSettings",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v9/*: any*/)
            ],
            "type": "ConfigureTwoFactorSettingsErrorResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61b9f016959f3b787800299b7e827884",
    "id": null,
    "metadata": {},
    "name": "LoginForm_TwoFALoginSetupMutation",
    "operationKind": "mutation",
    "text": "mutation LoginForm_TwoFALoginSetupMutation(\n  $method: ValidationMethod!\n  $phoneNumber: String\n  $tempAuthToken: String\n  $clientApplication: ClientApplication!\n) {\n  configureTwoFactorSettings(validationMethod: $method, phoneNumber: $phoneNumber, tempAuthToken: $tempAuthToken, clientApplication: $clientApplication) {\n    __typename\n    ... on ConfigureTwoFactorSettingsTwoFactorCodeRequiredResponse {\n      uuid\n      validationMethod\n      sanitizedContactInfo\n      __typename\n    }\n    ... on ConfigureTwoFactorSettingsErrorResponse {\n      __typename\n      error {\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3dea906b7d3ccce76315c6c6586c432a";

export default node;
