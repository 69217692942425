import graphql from "babel-plugin-relay/macro";
import PhoneNumberInput from "components/FullProgramming/common/PhoneNumberInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathFirstPhoneNumberField_communicationPath$key } from "./__generated__/CommPathFirstPhoneNumberField_communicationPath.graphql";

export const commPathFirstPhoneNumberFieldId = (number: string) =>
  `comm-path-first-phone-number-${number}`;

function CommPathFirstPhoneNumberField() {
  const [{ number, firstPhone, commType }, updateCommPath] =
    useCommPathFragment<CommPathFirstPhoneNumberField_communicationPath$key>(
      graphql`
        fragment CommPathFirstPhoneNumberField_communicationPath on CommunicationPath {
          number
          firstPhone
          commType
        }
      `
    );

  const fieldId = commPathFirstPhoneNumberFieldId(String(number));
  const disabled = ["NETWORK", "CELL", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="First Phone Number"
      disabled={disabled}
    >
      <PhoneNumberInput
        id={fieldId}
        maxLength={32}
        value={firstPhone.toString()}
        disabled={disabled}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(target.value, "firstPhone");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathFirstPhoneNumberField;
