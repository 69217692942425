/**
 * @generated SignedSource<<5121931c49f9abdfd3618d26510b1772>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type nvrCtrlQuery$variables = {
  siteId: string;
};
export type nvrCtrlQuery$data = {
  readonly site: {
    readonly id: string;
    readonly securecomNvrs: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SecureComNvrs_secureComNvr">;
    }>;
  } | null;
};
export type nvrCtrlQuery = {
  response: nvrCtrlQuery$data;
  variables: nvrCtrlQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "nvrCtrlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SecureComNvr",
            "kind": "LinkedField",
            "name": "securecomNvrs",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SecureComNvrs_secureComNvr"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "nvrCtrlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SecureComNvr",
            "kind": "LinkedField",
            "name": "securecomNvrs",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SecureComNvrVideoChannel",
                "kind": "LinkedField",
                "name": "channels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b0510b2db15d5d4535bd98b55dd3d8c",
    "id": null,
    "metadata": {},
    "name": "nvrCtrlQuery",
    "operationKind": "query",
    "text": "query nvrCtrlQuery(\n  $siteId: ID!\n) {\n  site(id: $siteId) {\n    id\n    securecomNvrs {\n      id\n      ...SecureComNvrs_secureComNvr\n    }\n  }\n}\n\nfragment SecureComNvrs_secureComNvr on SecureComNvr {\n  id\n  name\n  status\n  channels {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2cdb8c98b88a33e6fc64cab1f660bbe";

export default node;
