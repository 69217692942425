/**
 *Directive used to expand/collapse, close and refresh panel
 */
App.directive("daPaneltool", function () {
  var templates = {
    /* jshint multistr: true */
    collapse:
      "<a panel-do-collapse='' class='pull-right' data-toggle='tooltip' title='Expand/Collapse Panel' ng-click='{{panelID}} = !{{panelID}}' ng-init='{{panelID}}={{state}}'> \
                <i ng-if='{{panelID}}' class='icon-add'></i> \
                <i ng-if='!{{panelID}}' class='icon-subtract'></i> \
              </a>",
    dismiss:
      "<a panel-do-dismiss='' data-toggle='tooltip' title='Close Panel'>\
               <i class='icon-close_cancel''></i>\
             </a>",
    refresh:
      "<a panel-do-refresh='' data-toggle='tooltip' data-spinner='{{spinner}}' title='Refresh Panel'>\
               <i class='icon-cycle'></i>\
             </a>",
  };

  return {
    restrict: "EA",
    template: function (elem, attrs) {
      var temp = "";
      if (attrs.toolCollapse)
        temp += templates.collapse
          .replace(/{{panelID}}/g, elem.parent().parent().attr("id"))
          .replace(/{{state}}/g, attrs.toolCollapse);
      if (attrs.toolDismiss) temp += templates.dismiss;
      if (attrs.toolRefresh)
        temp += templates.refresh.replace(/{{spinner}}/g, attrs.toolRefresh);
      return temp;
    },
    // scope: true,
    // transclude: true,
    link: function (scope, element, attrs) {
      element.addClass("pull-right");
      element.css("font-size", "1rem", "position", "relative", "top", "1");
    },
  };
});

/**
 *Directive used to expand/collapse, close and refresh panel
 */
App.directive("daPaneltoolAbs", function () {
  var templates = {
    /* jshint multistr: true */
    collapse:
      "<a panel-do-collapse='' style='position: absolute; top: 50%; right: 1.6rem; transform: translateY(-50%)' data-toggle='tooltip' title='Expand/Collapse Panel' ng-click='{{panelID}} = !{{panelID}}' ng-init='{{panelID}}={{state}}'> \
                <i ng-if='{{panelID}}' class='icon-add'></i> \
                <i ng-if='!{{panelID}}' class='icon-subtract'></i> \
              </a>",
    dismiss:
      "<a panel-do-dismiss='' data-toggle='tooltip' title='Close Panel'>\
               <i class='icon-close_cancel''></i>\
             </a>",
    refresh:
      "<a panel-do-refresh='' data-toggle='tooltip' data-spinner='{{spinner}}' title='Refresh Panel'>\
               <i class='icon-cycle'></i>\
             </a>",
  };

  return {
    restrict: "EA",
    template: function (elem, attrs) {
      var temp = "";
      if (attrs.toolCollapse)
        temp += templates.collapse
          .replace(/{{panelID}}/g, elem.parent().parent().attr("id"))
          .replace(/{{state}}/g, attrs.toolCollapse);
      if (attrs.toolDismiss) temp += templates.dismiss;
      if (attrs.toolRefresh)
        temp += templates.refresh.replace(/{{spinner}}/g, attrs.toolRefresh);
      return temp;
    },
    // scope: true,
    // transclude: true,
    link: function (scope, element, attrs) {
      element.addClass("pull-right");
      element.css("font-size", "1rem", "position", "relative", "top", "1");
    },
  };
});
