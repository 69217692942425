import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationLockdownField_zone$key } from "./__generated__/ZoneInformationLockdownField_zone.graphql";

export const zoneInformationLockdownFieldId = (number: string) =>
  `zone-information-lockdown-${number}`;

function ZoneInformationLockdownField() {
  const [{ number, lockdownEnabled, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationLockdownField_zone$key>(
      graphql`
        fragment ZoneInformationLockdownField_zone on Zone {
          number
          lockdownEnabled
          type
        }
      `
    );

  const fieldId = zoneInformationLockdownFieldId(String(number));
  const disabled = type !== ZoneType.PANIC;
  const label = "Lockdown";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Enable to allow a Zone trip to initiate a Lockdown"
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        checked={!!lockdownEnabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(!lockdownEnabled, "lockdownEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationLockdownField;
