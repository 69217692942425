import graphql from "babel-plugin-relay/macro";
import { pick } from "ramda";
import React from "react";
import { useMutation } from "react-relay/hooks";
import {
  asID,
  ControlSystemCommType,
  ErrorType,
  fromSiteId,
  SecureComVideoLevel,
  SiteControlSystemIntendedUsage,
} from "securecom-graphql/client";
import styles from "./Walkthrough.module.css";

import { Sheet } from "../Layout/Layout";

import useLegacyState from "common/react-hooks/use-legacy-state";
import Alert from "components/Alert";
import { validSerialRegex } from "utils/control-systems";
import { DMPTimezoneOffsetOptions } from "utils/dates";
import AddressFields from "../CommonForms/BillingAddressForm";
import { useCustomerId } from "../EntryPointContext";
import {
  Checkbox,
  FieldRow,
  InlineFieldInputContainer,
  InlineFieldLabel,
  OffsetFieldRow,
} from "../FormFields";
import SaveButton, {
  useDelayedResetSaveButton,
} from "../FormFields/SaveButton";
import SystemTypeSelector from "../FormFields/SystemTypeSelector";
import TimezoneOffsetSelector from "../FormFields/TimezoneOffsetSelector";
import Accordion from "../Layout/Accordion";
import Spacer from "../Layout/Spacer";
import { BillingAddress, SavingState } from "../types";
import { Actions, State } from "./reducer";
import { WalkthroughAddSiteMutation } from "./__generated__/WalkthroughAddSiteMutation.graphql";

const useAddSite = ({
  dispatch,
  onSave,
}: {
  dispatch: React.Dispatch<Actions>;
  onSave: (siteId: string) => void;
}) => {
  const [graphqlCustomerId] = useCustomerId();

  const [addSite] = useMutation<WalkthroughAddSiteMutation>(
    graphql`
      mutation WalkthroughAddSiteMutation(
        $customerId: ID!
        $site: AddSiteInput!
      ) {
        addSite(customerId: $customerId, site: $site) {
          ... on AddSiteSuccessResponse {
            site {
              id
              ...ExistingSiteFormFragment_site
            }
          }
          ... on InvalidSerialsError {
            errorType: type
            failingSerials: controlSystemSerialNumbers
          }
          ... on NoControlSystemsProvidedError {
            errorType: type
          }
          ... on FailedToAddControlSystemsError {
            errorType: type
            failedControlSystems {
              serialNumber
              reason
            }
          }
          ... on DuplicateSerialNumberError {
            errorType: type
          }
          ... on FailedToCreateSiteError {
            errorType: type
          }
          ... on UnsupportedDeviceTypeError {
            errorType: type
          }
          ... on InitialConnectionFailure {
            errorType: type
            site {
              id
              ...ExistingSiteFormFragment_site
            }
          }
        }
      }
    `
  );

  return (state: State) => {
    dispatch({ type: "WALKTHROUGH_SUBMITTED" });

    addSite({
      variables: {
        customerId: graphqlCustomerId,
        site: {
          ...pick(
            [
              "name",
              "address1",
              "address2",
              "city",
              "state",
              "postalCode",
              "country",
              "observesDaylightSavingsTime",
              "timezoneOffset",
            ],
            state
          ),
          videoEnabled: false,
          securecomVideoLevel: SecureComVideoLevel.NONE,
          securecomNvrEnabled: false,
          hikvisionDoorbellEnabled: false,
          hikvisionDoorbellTotal: 0,
          openEyeEnabled: false,
          hikvisionNvrEnabled: false,
          eagleEyeEnabled: false,
          digitalWatchdogSpectrumEnabled: false,
          hanwhaWaveEnabled: false,
          dmpDoorbellEnabled: false,
          showDoors: true,
          showElevators: true,
          showOutputs: true,
          showVideoServices: true,
          cardFormats: [
            {
              name: "DMP",
              wiegandLength: 26,
              siteCodePosition: 1,
              siteCodeLength: 7,
              userCodePosition: 8,
              userCodeLength: 17,
              userCodeDigits: 5,
              requireSiteCode: false,
              siteCodes: [127, null, null, null, null, null, null, null],
            },
          ],
          controlSystems: [
            {
              serialNumber: state.firstDoorSerialNumber,
              intendedUsage: state.intendedUsage,
              addAsPreProgramming: state.preProgram,
              commType: state.connectionType,
            },
          ],
        },
      },
      updater: (store, result) => {
        if (result.addSite.site) {
          const siteRecord = store.get(result.addSite.site.id);
          if (siteRecord) {
            store.getRoot().setLinkedRecord(siteRecord, "node", {
              id: result.addSite.site.id,
            });
          }
        }
      },
      onError: (_) => {
        dispatch({ type: "WALKTHROUGH_ERRORED", error: { type: "UNKNOWN" } });
      },
      onCompleted: ({ addSite: results }) => {
        if (results.errorType) {
          switch (results.errorType) {
            case ErrorType.INVALID_SERIALS: {
              dispatch({
                type: "WALKTHROUGH_ERRORED",
                error: {
                  type: results.errorType,
                  payload: results.failingSerials,
                },
              });
              break;
            }
            case ErrorType.NO_CONTROL_SYSTEMS_PROVIDED: {
              dispatch({
                type: "WALKTHROUGH_ERRORED",
                error: {
                  type: results.errorType,
                },
              });
              break;
            }
            case ErrorType.FAILED_TO_ADD_CONTROL_SYSTEMS: {
              dispatch({
                type: "WALKTHROUGH_ERRORED",
                error: {
                  type: results.errorType,
                  payload: results.failedControlSystems,
                },
              });
              break;
            }
            case ErrorType.UNSUPPORTED_DEVICE_TYPE: {
              dispatch({
                type: "WALKTHROUGH_ERRORED",
                error: {
                  type: results.errorType,
                },
              });
              break;
            }
            case ErrorType.FAILED_TO_CREATE_SITE:
            default: {
              dispatch({
                type: "WALKTHROUGH_ERRORED",
                error: {
                  type: results.errorType,
                },
              });
            }
          }

          return;
        }

        if (results.site) {
          const siteId = results.site.id;

          dispatch({ type: "WALKTHROUGH_SAVED" });

          setTimeout(() => {
            onSave(fromSiteId(asID(siteId)).siteId);
          }, 1000);
        }
      },
    });
  };
};

const genericFirstDoorError = `An error occurred while setting up the first door. Please ensure the door is powered on and try again.`;

export default function NewSiteFormWalkthrough({
  state,
  dispatch,
  onSave,
  billingAddress,
}: {
  state: State;
  dispatch: React.Dispatch<Actions>;
  onSave: (siteId: string) => void;
  billingAddress: BillingAddress;
}) {
  const [showError, setShowError] = React.useState(true);
  const [blurredFields, setBlurredFields] = useLegacyState({
    name: false,
    firstDoorSerialNumber: false,
  });

  const validationErrorMessages = {
    name: !state.name ? "System name is required." : undefined,
    firstDoorSerialNumber: !state.firstDoorSerialNumber
      ? "First door serial number is required."
      : !state.firstDoorSerialNumber.match(validSerialRegex)
      ? "First door serial number must be a valid serial number."
      : undefined,
  };

  const saveEntireSite = useAddSite({ onSave, dispatch });

  useDelayedResetSaveButton(state.savingState, () =>
    dispatch({ type: "WALKTHROUGH_IDLED" })
  );

  return (
    <Sheet callout={true}>
      {showError && state.lastError ? (
        <Alert
          type="error"
          onClose={() => {
            setShowError(false);
          }}
        >
          {state.lastError.type === ErrorType.INVALID_SERIALS
            ? `Unable to add system, the first door serial number is not a valid door.`
            : state.lastError.type === ErrorType.NO_CONTROL_SYSTEMS_PROVIDED
            ? `Unable to add system, the first door serial is required.`
            : state.lastError.type === ErrorType.DUPLICATE_SERIAL_NUMBER
            ? `Unable to add system, the serial number used for the first door is already in use.`
            : state.lastError.type === ErrorType.UNSUPPORTED_DEVICE_TYPE
            ? "Unable to add X1-8 as an Elevator."
            : genericFirstDoorError}
        </Alert>
      ) : (
        <Spacer size="6x" />
      )}
      <>
        <div className="row">
          <div className="col-sm-12">
            <FieldRow
              rowStyle="featured"
              className="required"
              error={blurredFields.name && !!validationErrorMessages.name}
            >
              <InlineFieldLabel htmlFor="name">System Name</InlineFieldLabel>
              <InlineFieldInputContainer>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Smith Home"
                  maxLength={32}
                  value={state.name}
                  required
                  onChange={({ target }) => {
                    dispatch({
                      type: "SYSTEM_NAME_CHANGED",
                      value: target.value,
                    });
                  }}
                  onBlur={() => {
                    setBlurredFields({ name: true });
                  }}
                />
                {blurredFields.name && !!validationErrorMessages.name && (
                  <div className="has-error">
                    {validationErrorMessages.name}
                  </div>
                )}
              </InlineFieldInputContainer>
            </FieldRow>
            <FieldRow rowStyle="featured">
              <InlineFieldLabel htmlFor="name">
                Alternate System Name
              </InlineFieldLabel>
              <InlineFieldInputContainer>
                <input
                  type="text"
                  className="form-control"
                  id="alternate-system-name"
                  name="alternate-system-name"
                  maxLength={32}
                  value={state.nickname ?? undefined}
                  required
                  onChange={({ target }) => {
                    dispatch({
                      type: "SYSTEM_NICKNAME_CHANGED",
                      value: target.value.trim() ? target.value : null,
                    });
                  }}
                />
              </InlineFieldInputContainer>
            </FieldRow>

            <FieldRow rowStyle="featured" className="required">
              <InlineFieldLabel htmlFor="system-type">
                System Type
              </InlineFieldLabel>
              <InlineFieldInputContainer>
                <SystemTypeSelector
                  className="form-control"
                  name="system-type"
                  id="system-type"
                  value={state.type}
                  required
                  onChange={({ currentTarget }) => {
                    dispatch({
                      type: "SYSTEM_TYPE_CHANGED",
                      value: currentTarget.value,
                    });
                  }}
                />
              </InlineFieldInputContainer>
            </FieldRow>

            <OffsetFieldRow>
              <div>
                <div>
                  <Checkbox
                    checked={state.useBillingAddress}
                    onChange={() => {
                      dispatch({
                        type: "USE_BILLING_ADDRESS_TOGGLED",
                        billingAddress,
                      });
                    }}
                    label={"Use Billing Address"}
                  />
                  <div className="clearfix" />
                </div>

                {!state.useBillingAddress && (
                  <Accordion
                    initiallyOpen={true}
                    header={
                      <Accordion.ToggleableHeader>
                        Edit Address
                      </Accordion.ToggleableHeader>
                    }
                  >
                    <AddressFields
                      state={state}
                      dispatch={dispatch}
                      billingAddress={billingAddress}
                    />
                  </Accordion>
                )}
              </div>
            </OffsetFieldRow>

            <>
              <OffsetFieldRow>
                <Checkbox
                  checked={state.observesDaylightSavingsTime}
                  onChange={() => {
                    dispatch({
                      type: "USE_DAYLIGHT_SAVINGS_TIME_TOGGLED",
                    });
                  }}
                  label={"Use Daylight Savings Time"}
                />
              </OffsetFieldRow>

              <FieldRow className="required" rowStyle="featured">
                <InlineFieldLabel htmlFor="timezone-offset">
                  Timezone Offset
                </InlineFieldLabel>
                <InlineFieldInputContainer>
                  <TimezoneOffsetSelector
                    className="form-control"
                    name="timezone-offset"
                    id="timezone-offset"
                    value={state.timezoneOffset}
                    onChange={({ currentTarget }) => {
                      dispatch({
                        type: "SITE_TIMEZONE_CHANGED",
                        value: Number(
                          currentTarget.value
                        ) as DMPTimezoneOffsetOptions,
                      });
                    }}
                  />
                </InlineFieldInputContainer>
              </FieldRow>
            </>
          </div>
        </div>
      </>

      <div className="row">
        <div className="col-sm-12">
          <FieldRow
            rowStyle="featured"
            className="required"
            error={
              blurredFields.firstDoorSerialNumber &&
              !!validationErrorMessages.firstDoorSerialNumber
            }
          >
            <InlineFieldLabel htmlFor="name">
              First Door Serial Number
            </InlineFieldLabel>
            <InlineFieldInputContainer>
              <input
                type="text"
                className="form-control"
                id="firstDoorSerialNumber"
                name="firstDoorSerialNumber"
                pattern="[0-9A-Fa-f]{8}"
                maxLength={8}
                required
                value={state.firstDoorSerialNumber}
                placeholder="E.G.: 00000000"
                onChange={({ target }) => {
                  dispatch({
                    type: "SERIAL_NUMBER_UPDATED",
                    value: target.value,
                  });
                }}
                onBlur={() => {
                  setBlurredFields({ firstDoorSerialNumber: true });
                }}
              />
              {blurredFields.firstDoorSerialNumber &&
                !!validationErrorMessages.firstDoorSerialNumber && (
                  <div className="has-error">
                    {validationErrorMessages.firstDoorSerialNumber}
                  </div>
                )}
            </InlineFieldInputContainer>
          </FieldRow>
          <OffsetFieldRow>
            <div>
              <Checkbox
                checked={state.preProgram}
                onChange={() => {
                  dispatch({
                    type: "PRE_PROGRAM_TOGGLED",
                  });
                }}
                label="Pre-Program X1"
                infoText="Allows this X1 to be configured prior to installation and automatically programmed when it comes online."
              />
              <div className="clearfix" />
            </div>
          </OffsetFieldRow>
          <FieldRow className="required" rowStyle="featured">
            <InlineFieldLabel htmlFor="first-door-type">
              First Door Type
            </InlineFieldLabel>

            <InlineFieldInputContainer>
              <select
                name="first-door-type"
                id="first-door-type"
                className="form-control"
                value={state.intendedUsage}
                onChange={({ currentTarget }) => {
                  dispatch({
                    type: "INTENDED_USE_CHANGED",
                    value:
                      currentTarget.value as SiteControlSystemIntendedUsage,
                  });
                }}
              >
                <option value={SiteControlSystemIntendedUsage.DOOR_ACCESS}>
                  Door
                </option>
                <option value={SiteControlSystemIntendedUsage.ELEVATOR}>
                  Elevator
                </option>
              </select>
            </InlineFieldInputContainer>
          </FieldRow>
          {state.preProgram ? (
            <FieldRow className="required" rowStyle="featured">
              <InlineFieldLabel htmlFor="connection-type">
                Connection Type
              </InlineFieldLabel>

              <InlineFieldInputContainer>
                <select
                  name="connection-type"
                  id="connection-type"
                  className="form-control"
                  value={state.connectionType}
                  onChange={({ currentTarget }) => {
                    dispatch({
                      type: "CONNECTION_TYPE_CHANGED",
                      value: currentTarget.value as ControlSystemCommType,
                    });
                  }}
                >
                  <option value={ControlSystemCommType.PERSISTENT}>
                    EASYconnect
                  </option>
                  <option value={ControlSystemCommType.CELL}>Cell</option>
                  <option
                    value={ControlSystemCommType.PERSISTENT_WITH_CELL_BACKUP}
                  >
                    EASYconnect + Cell Backup
                  </option>
                </select>
              </InlineFieldInputContainer>
            </FieldRow>
          ) : null}
        </div>
      </div>

      <div className={styles["actions"]}>
        <SaveButton
          savingState={state.savingState}
          disabled={
            state.savingState !== SavingState.Idle ||
            !state.name ||
            !state.firstDoorSerialNumber.match(validSerialRegex)
          }
          onClick={() => {
            saveEntireSite(state);
            setShowError(true);
          }}
        />
      </div>
    </Sheet>
  );
}
