import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import {
  GraphQLTaggedNode,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { RecordProxy } from "relay-runtime";
import { SystemType } from "securecom-graphql/client";
import { SystemOptionsContextHouseCode_systemOptions$key } from "./__generated__/SystemOptionsContextHouseCode_systemOptions.graphql";
import { SystemOptionsContextSystemType_systemOptions$key } from "./__generated__/SystemOptionsContextSystemType_systemOptions.graphql";

const SystemOptionsContext = React.createContext<any>({});

export function SystemOptionsContextProvider(props: {
  systemOptions: any;
  children: React.ReactNode;
}) {
  return (
    <SystemOptionsContext.Provider value={props.systemOptions}>
      {props.children}
    </SystemOptionsContext.Provider>
  );
}

export function useSystemOptionsFragment<TKey extends KeyType>(
  fragmentInput: GraphQLTaggedNode
) {
  const relayEnv = useRelayEnvironment();
  const systemOptions = React.useContext(SystemOptionsContext);

  const data = useFragment(fragmentInput, systemOptions as TKey);

  const { id } = useFragment(
    graphql`
      fragment SystemOptionsContext_systemOptions on SystemOptions {
        id
      }
    `,
    systemOptions as any
  );

  const update = (
    updater: (systemOptions: RecordProxy<KeyTypeData<TKey>>) => void
  ) => {
    relayEnv.commitUpdate((store) => {
      const recordProxy = store.get<KeyTypeData<TKey>>(id);
      if (recordProxy) {
        updater(recordProxy);
      }
    });
  };

  return [data, update] as const;
}

export function useIsAreaSystem() {
  const [data] =
    useSystemOptionsFragment<SystemOptionsContextSystemType_systemOptions$key>(
      graphql`
        fragment SystemOptionsContextIsAreaSystem_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            systemType
          }
          ... on Xt75SystemOptions {
            systemType
          }
          ... on XtSystemOptions {
            systemType
          }
          ... on TMSentrySystemOptions {
            systemType
          }
        }
      `
    );
  return data.systemType === SystemType.AREA;
}

export function useSystemType() {
  const [data] =
    useSystemOptionsFragment<SystemOptionsContextSystemType_systemOptions$key>(
      graphql`
        fragment SystemOptionsContextSystemType_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            systemType
          }
          ... on Xt75SystemOptions {
            systemType
          }
          ... on XtSystemOptions {
            systemType
          }
          ... on Xt75SystemOptions {
            systemType
          }
        }
      `
    );
  return data.systemType;
}

export function useHouseCode() {
  const [data] =
    useSystemOptionsFragment<SystemOptionsContextHouseCode_systemOptions$key>(
      graphql`
        fragment SystemOptionsContextHouseCode_systemOptions on SystemOptions {
          ... on XtSystemOptions {
            houseCode
          }
          ... on Xt75SystemOptions {
            houseCode
          }
          ... on XrSystemOptions {
            houseCode
          }
          ... on XfSystemOptions {
            houseCode
          }
        }
      `
    );
  return data.houseCode;
}
