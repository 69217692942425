import graphql from "babel-plugin-relay/macro";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useFragment } from "react-relay";
import DeviceInformation1100TField from "../../common/DeviceInformationFields/DeviceInformation1100TField";
import DeviceInformation1100TWirelessFrequencyField from "../../common/DeviceInformationFields/DeviceInformation1100TWirelessFrequencyField";
import { DeviceInformationContextProvider } from "../../common/DeviceInformationFields/DeviceInformationContext";
import DeviceInformationDoorField from "../../common/DeviceInformationFields/DeviceInformationDoorField";
import DeviceInformationNameField from "../../common/DeviceInformationFields/DeviceInformationNameField";
import DeviceInformationNetworkField from "../../common/DeviceInformationFields/DeviceInformationNetworkField";
import DeviceInformationNumberField from "../../common/DeviceInformationFields/DeviceInformationNumberField";
import DeviceInformationSerialNumberField from "../../common/DeviceInformationFields/DeviceInformationSerialNumberField";
import DeviceInformationSupervisionTimeField from "../../common/DeviceInformationFields/DeviceInformationSupervisionTimeField";
import DeviceInformationWirelessField from "../../common/DeviceInformationFields/DeviceInformationWirelessField";
import {
  useSoftwareVersion,
  useSupports1100T,
} from "../../common/PanelContext";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { XTDeviceInformationsFields_deviceInformation$key } from "./__generated__/XTDeviceInformationsFields_deviceInformation.graphql";

function XTDeviceInformationsFields(props: {
  deviceInformation: XTDeviceInformationsFields_deviceInformation$key;
}) {
  const data = useFragment(
    graphql`
      fragment XTDeviceInformationsFields_deviceInformation on DeviceInformation {
        id
        number
        ...DeviceInformationContext_deviceInformation
        ...DeviceInformationNumberField_deviceInformation
        ...DeviceInformationNameField_deviceInformation
        ...DeviceInformationDoorField_deviceInformation
        ...DeviceInformationNetworkField_deviceInformation
        ...DeviceInformation1100TField_deviceInformation
        ...DeviceInformation1100TWirelessFrequencyField_deviceInformation
        ...DeviceInformationWirelessField_deviceInformation
        ...DeviceInformationSerialNumberField_deviceInformation
        ...DeviceInformationSupervisionTimeField_deviceInformation
      }
    `,
    props.deviceInformation
  );

  const softwareVersion = useSoftwareVersion();
  const supportsDoorField = panelVersionGTOE(203, softwareVersion);
  const supportsNetworkField = panelVersionGTOE(203, softwareVersion);
  const supports1100TField = useSupports1100T();
  const isNotDeviceOne = data.number !== 1;

  return (
    <DeviceInformationContextProvider deviceInformation={data}>
      <ProgrammingConceptForm.Fields>
        <DeviceInformationNumberField />
        <DeviceInformationNameField />
        {isNotDeviceOne && (
          <>
            {supportsDoorField && <DeviceInformationDoorField />}
            {supportsNetworkField && <DeviceInformationNetworkField />}
            {supports1100TField && (
              <>
                <DeviceInformation1100TField />
                <DeviceInformation1100TWirelessFrequencyField />
              </>
            )}
            <DeviceInformationWirelessField />
            <DeviceInformationSerialNumberField />
            <DeviceInformationSupervisionTimeField />
          </>
        )}
      </ProgrammingConceptForm.Fields>
    </DeviceInformationContextProvider>
  );
}

export default React.memo(XTDeviceInformationsFields);
