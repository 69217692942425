import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsSubnetMaskField_networkOptions$key } from "./__generated__/NetworkOptionsSubnetMaskField_networkOptions.graphql";

export const networkOptionsSubnetMaskFieldId = () =>
  "network-options-subnet-mask";

function NetworkOptionsSubnetMaskField() {
  const [{ subnetMask, dhcpEnabled }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsSubnetMaskField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsSubnetMaskField_networkOptions on NetworkOptions {
          subnetMask
          dhcpEnabled
        }
      `
    );

  const fieldId = networkOptionsSubnetMaskFieldId();
  const disabled = dhcpEnabled;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Subnet Mask"
      tooltip="Enter the local subnet mask assigned to the panel."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        required={!dhcpEnabled}
        value={subnetMask}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "subnetMask");
          });
        }}
        onBlur={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "subnetMask"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsSubnetMaskField;
