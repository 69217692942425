import { BaseButton } from "common/components/web/Button";
import Modal from "common/components/web/Modal";
import { Flex } from "components/DaStyledElements";
import React, { FC } from "react";
import styled from "styled-components";

const BaseButtonPrimary = styled(BaseButton)<{ makeButtonRed?: boolean }>`
  color: ${({ makeButtonRed }) =>
    makeButtonRed ? "var(--color-danger-600)" : "var(--color-primary-600)"};
  background-color: ${({ makeButtonRed }) =>
    makeButtonRed ? "var(--color-danger-50)" : "var(--color-primary-50)"};
  border-color: ${({ makeButtonRed }) =>
    makeButtonRed ? "var(--color-danger-500)" : "var(--color-primary-500)"};
  &:hover {
    color: ${({ makeButtonRed }) =>
      makeButtonRed ? "var(--color-danger-600)" : "var(--color-primary-600)"};
    background-color: ${({ makeButtonRed }) =>
      makeButtonRed ? "var(--color-danger-100)" : "var(--color-primary-100)"};
  }
`;

const ConfirmActionModal: FC<
  React.PropsWithChildren<{
    onConfirm: () => void;
    onCancel: () => void;
    title?: string;
    message: string;
    confirmButtonTitle?: string;
    cancelButtonTitle?: string;
    confirmButtonDisabled?: boolean;
    makeConfirmButtonRed?: boolean;
  }>
> = ({
  onConfirm,
  onCancel,
  title = "Confirm",
  message,
  confirmButtonTitle = "Yes",
  cancelButtonTitle = "No",
  confirmButtonDisabled = false,
  makeConfirmButtonRed = false,
}) => {
  return (
    <Modal>
      <h3 className="printed-concept-heading pad-16">{title}</h3>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Flex.Row justify={"end"} align={"center"}>
          <BaseButtonPrimary
            makeButtonRed={makeConfirmButtonRed}
            className=" mar-r-8"
            type="button"
            disabled={confirmButtonDisabled}
            onClick={onConfirm}
          >
            {confirmButtonTitle}
          </BaseButtonPrimary>
          <BaseButton className={"btn btn-default"} onClick={onCancel}>
            {cancelButtonTitle}
          </BaseButton>
        </Flex.Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmActionModal;
