import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from ".././ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationEncryptionEnabledField_communication$key } from "./__generated__/CommunicationEncryptionEnabledField_communication.graphql";

export const communicationEncryptionEnabledFieldId = () =>
  `communication-encryption-enabled`;

function CommunicationEncryptionEnabledField() {
  const [{ encryptionEnabled }, updateCommunication] =
    useCommunicationFragment<CommunicationEncryptionEnabledField_communication$key>(
      graphql`
        fragment CommunicationEncryptionEnabledField_communication on Communication {
          encryptionEnabled
        }
      `
    );

  const fieldId = communicationEncryptionEnabledFieldId();
  const label = "Enable Encryption";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables encrypted communications to the monitoring center over the programmed communication paths."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={encryptionEnabled}
        onChange={() => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(!encryptionEnabled, "encryptionEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationEncryptionEnabledField;
