import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { SystemType } from "securecom-graphql/client";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import {
  useHouseCode,
  useSystemType,
} from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsArmedAllOrAwayOutputField_outputOptions$key } from "./__generated__/OutputOptionsArmedAllOrAwayOutputField_outputOptions.graphql";

export const outputOptionsArmedAllOrAwayOutputFieldId = () =>
  `output-options-armed-all-or-away-output`;

function OutputOptionsArmedAllOrAwayOutputField() {
  const [{ armedAllOrAwayOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsArmedAllOrAwayOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsArmedAllOrAwayOutputField_outputOptions on OutputOptions {
          id
          armedAllOrAwayOutput {
            id
            number
            formattedNumber
          }
        }
      `
    );

  const fieldId = outputOptionsArmedAllOrAwayOutputFieldId();
  const houseCode = useHouseCode();
  const systemType = useSystemType();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const isArmedAllOutput = systemType === SystemType.ALL_PERIMETER;

  const tooltip = isArmedAllOutput
    ? "This output turns on steady when the System is Armed All. Alarms cause the output to pulse until 3 minutes after Disarming."
    : "This output turns on steady when the System is Armed Away. Alarms cause the output to pulse until 3 minutes after Disarming.";

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const disabled = !(
    systemType === SystemType.HOME_SLEEP_AWAY ||
    systemType === SystemType.ALL_PERIMETER
  );

  const { current: originalValue } = React.useRef(
    armedAllOrAwayOutput.formattedNumber
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={isArmedAllOutput ? "Armed All Output" : "Armed Away Output"}
      tooltip={tooltip}
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        value={armedAllOrAwayOutput.formattedNumber}
        disabled={disabled}
        required
        pattern={disabled ? undefined : pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "armedAllOrAwayOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "armedAllOrAwayOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsArmedAllOrAwayOutputField;
