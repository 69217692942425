import {
  motionDetectionRegionsSvgId,
  REGION_COLORS,
  viewBoxMaxX,
  viewBoxMaxY,
  viewBoxMinX,
  viewBoxMinY,
} from "./constants";
import {
  Coordinates,
  CoordinatesTransformer,
  RegionBounds,
  ViewBox,
} from "./types";

export const boundsWidth = (bounds: RegionBounds) => bounds.maxX - bounds.minX;

export const boundsHeight = (bounds: RegionBounds) => bounds.maxY - bounds.minY;

export function getEdgePoints(
  coordinates: Coordinates[]
): [Coordinates, Coordinates][] {
  const pairs = [] as [Coordinates, Coordinates][];
  coordinates.forEach((xy, index) => {
    pairs.push([xy, coordinates[(index + 1) % coordinates.length]]);
  });
  return pairs;
}

export const convertGqlXToViewboxX =
  (bounds: RegionBounds) => (gqlValue: number) =>
    (gqlValue / boundsWidth(bounds)) * (viewBoxMaxX - viewBoxMinX) +
    viewBoxMinX;

export const convertGqlYToViewboxY =
  (bounds: RegionBounds) => (gqlValue: number) =>
    (gqlValue / boundsHeight(bounds)) * (viewBoxMaxY - viewBoxMinY) +
    viewBoxMinY;

export const getRootSvgElementFromEvent = (
  event: MouseEvent | KeyboardEvent | React.MouseEvent
): SVGElement | null =>
  event.target instanceof SVGElement &&
  event.target.id === motionDetectionRegionsSvgId
    ? event.target
    : event.target instanceof SVGElement
    ? event.target.viewportElement
    : null;

export const findDetectionRegionsSvg = (
  element: HTMLElement
): HTMLElement | null =>
  element.id === motionDetectionRegionsSvgId
    ? element
    : element.parentElement
    ? findDetectionRegionsSvg(element.parentElement)
    : null;

export type CoordinateRenderer = (x: number) => number;

export const renderXCoordinate =
  (bounds: RegionBounds) =>
  ([viewBoxX]: ViewBox): CoordinateRenderer =>
  (x) =>
    viewBoxX * xCoordinatePercentage(bounds)(x);

export const renderYCoordinate =
  (bounds: RegionBounds) =>
  ([, viewBoxY]: ViewBox): CoordinateRenderer =>
  (y) =>
    viewBoxY * yCoordinatePercentage(bounds)(y);

export const xCoordinatePercentage = (bounds: RegionBounds) => (x: number) =>
  (x - bounds.minX) / boundsWidth(bounds);

export const yCoordinatePercentage = (bounds: RegionBounds) => (y: number) =>
  (y - bounds.minY) / boundsHeight(bounds);

export const renderCoordinates =
  (xCoordinateRenderer: (x: number) => number) =>
  (yCoordinateRenderer: (x: number) => number) =>
  ([x, y]: Coordinates): Coordinates =>
    [xCoordinateRenderer(x), yCoordinateRenderer(y)];

export const normalizePxX =
  (bounds: RegionBounds) => (svg: HTMLElement) => (px: number) => {
    return (boundsWidth(bounds) / svg.clientWidth) * px;
  };

export const normalizePxY =
  (bounds: RegionBounds) => (svg: HTMLElement) => (px: number) =>
    (boundsHeight(bounds) / svg.clientHeight) * px;

const clamp = (min: number) => (max: number) => (x: number) =>
  Math.max(min, Math.min(max, x));

export const clampViewboxCoordinateX = (x: number, bounds: RegionBounds) =>
  clamp(bounds.minX)(bounds.maxX)(x);

export const clampViewboxCoordinateY = (y: number, bounds: RegionBounds) =>
  clamp(bounds.minY)(bounds.maxY)(y);

export const transformX =
  (amount: number, bounds: RegionBounds): CoordinatesTransformer =>
  (coordinates) => {
    if (amount === 0) {
      return coordinates;
    }
    const [x, y] = coordinates;
    return [clampViewboxCoordinateX(x + amount, bounds), y];
  };

export const transformY =
  (amount: number, bounds: RegionBounds): CoordinatesTransformer =>
  (coordinates) => {
    if (amount === 0) {
      return coordinates;
    }
    const [x, y] = coordinates;
    return [x, clampViewboxCoordinateY(y + amount, bounds)];
  };

export const getKeyboardTransformer =
  (bounds: RegionBounds) =>
  (event: KeyboardEvent): CoordinatesTransformer => {
    const amountPercentage = event.shiftKey ? 0.01 : 0.001;
    switch (event.code) {
      case "ArrowDown":
        return transformY(boundsHeight(bounds) * amountPercentage, bounds);
      case "ArrowUp":
        return transformY(boundsHeight(bounds) * -amountPercentage, bounds);
      case "ArrowRight":
        return transformX(boundsWidth(bounds) * amountPercentage, bounds);
      case "ArrowLeft":
        return transformX(boundsWidth(bounds) * -amountPercentage, bounds);
      default:
        return (coordinates) => coordinates;
    }
  };

export const getMouseTransformer =
  (bounds: RegionBounds) =>
  (svgElement: HTMLElement) =>
  (event: MouseEvent) =>
  (activeCoordinates: Coordinates[]) => {
    const activeXs = activeCoordinates.map(([x]) => x);
    const activeYs = activeCoordinates.map(([, y]) => y);
    const maxLeftDistance = Math.max(
      bounds.minX,
      Math.min(...activeXs) - bounds.minX
    );
    const maxRightDistance = Math.min(
      bounds.maxX,
      bounds.maxX - Math.max(...activeXs)
    );
    const maxUpDistance = Math.max(
      bounds.minY,
      Math.min(...activeYs) - bounds.minY
    );
    const maxDownDistance = Math.min(
      bounds.maxY,
      bounds.maxY - Math.max(...activeYs)
    );
    const movementX = clamp(-maxLeftDistance)(maxRightDistance)(
      normalizePxX(bounds)(svgElement)(event.movementX)
    );
    const movementY = clamp(-maxUpDistance)(maxDownDistance)(
      normalizePxY(bounds)(svgElement)(event.movementY)
    );

    return activeCoordinates.map(
      ([x, y]): Coordinates => [x + movementX, y + movementY]
    );
  };

export const slope =
  ([x1, y1]: Coordinates) =>
  ([x2, y2]: Coordinates) =>
    x2 === x1 ? Infinity : (y2 - y1) / (x2 - x1);

export const square = (x: number) => Math.pow(x, 2);

export const distance =
  ([x1, y1]: Coordinates) =>
  ([x2, y2]: Coordinates) =>
    Math.sqrt(square(x2 - x1) + square(y2 - y1));

export const getPointForSlopeGivenX =
  ([x1, y1]: Coordinates) =>
  (slope: number) =>
  (x2: number): Coordinates =>
    [x2, slope * (x2 - x1) + y1];

export const getPointForSlopeGivenY =
  ([x1, y1]: Coordinates) =>
  (slope: number) =>
  (y2: number): Coordinates =>
    [(y2 - y1 + slope * x1) / slope, y2];

export const getClosestPoint =
  (from: Coordinates) => (p1: Coordinates) => (p2: Coordinates) => {
    const distanceTo = distance(from);
    const d1 = distanceTo(p1);
    const d2 = distanceTo(p2);
    return d1 < d2 ? p1 : p2;
  };

export const removeIndexes =
  (indexes: readonly number[]) =>
  <A>(xs: readonly A[]): A[] => {
    const copy = [...xs];
    indexes.forEach((index) => {
      copy.splice(index, 1);
    });
    return copy;
  };

export const getRegionColor = (index: number) =>
  REGION_COLORS[index] ?? "black";
