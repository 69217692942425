App.controller("MultiPanelDashboardCtrl", [
  "$q",
  "$filter",
  "$rootScope",
  "$scope",
  "$http",
  "$state",
  "$interval",
  "$window",
  "$compile",
  "UserService",
  "RemoteUpdateService",
  "JobSchedulerService",
  "dealer_id",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "DTColumnBuilder",
  "DataTablesUtilsService",
  function (
    $q,
    $filter,
    $rootScope,
    $scope,
    $http,
    $state,
    $interval,
    $window,
    $compile,
    UserService,
    RemoteUpdateService,
    JobSchedulerService,
    dealer_id,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    DTColumnBuilder,
    DataTablesUtilsService
  ) {
    var MultiPanelDash = this;

    MultiPanelDash.UserService = UserService;

    /**
     * @ngdoc object
     * @name getUpdates
     * @methodOf App.controller:RemoteUpdateDealerDashboardCtrl
     *
     * @description
     * Function to get the updates that should appear on this dashboard
     *
     * @returns {Promise} it's complicated
     *
     */
    var getUpdates = function () {
      var deferred = $q.defer();
      RemoteUpdateService.getAllUpdatesForDealer(dealer_id)
        .then(
          function (data) {
            $scope.updates = data;
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    //Defines the options/features for the Updates List grid

    RemoteUpdateService.dtOptions = DataTablesUtilsService.getDTOptions(
      getUpdates()
    );

    //List of columns, column properties, and custom methods used to display the data in the Data Table.
    RemoteUpdateDash.dtColumns = [
      DTColumnBuilder.newColumn(null)
        .withTitle("System Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.navToPanelFirmWareUpdatePage(row);
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Customer Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupData(row, "customer_name");
        }),
      DTColumnBuilder.newColumn("Email").withTitle("Updated By"),
      DTColumnBuilder.newColumn(null)
        .withTitle("From-To")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getFirmwareUpdatePath(
            row,
            "from_version",
            "to_version"
          );
        }),
      //DTColumnBuilder.newColumn(null).withTitle('To').renderWith( function(data,type,row) { return DataTablesUtilsService.getGroupData(row, 'to_version');}),
      DTColumnBuilder.newColumn(null)
        .withTitle("Started")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asDateTime(row, "CreatedAt");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Completed")
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asDateTime(row, "CompletedAt");
        }),
      DTColumnBuilder.newColumn(null)
        .withTitle("Status")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asProgressBar(row, "id");
        }),
    ];
  },
]);
