import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ZoneOutputAction, ZoneType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationArmedShortOutputActionField_zone$key } from "./__generated__/ZoneInformationArmedShortOutputActionField_zone.graphql";

export const zoneInformationArmedShortOutputActionFieldId = (number: string) =>
  `zone-information-armed-short-output-action-${number}`;

function ZoneInformationArmedShortOutputActionField() {
  const [{ number, armedShortOutputAction, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationArmedShortOutputActionField_zone$key>(
      graphql`
        fragment ZoneInformationArmedShortOutputActionField_zone on Zone {
          number
          type
          armedShortOutputAction
        }
      `
    );

  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);
  const fieldId = zoneInformationArmedShortOutputActionFieldId(String(number));
  const disabled = [ZoneType.ARMING, ZoneType.TAMPER].includes(
    type as ZoneType
  );
  const label = isXf ? "Short Output Action" : "Armed Short Output Action";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Assigns a cadence to the Armed Short Output."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={armedShortOutputAction ?? undefined}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "armedShortOutputAction");
          });
        }}
      >
        <Select.Option value={ZoneOutputAction.NONE}>None</Select.Option>
        <Select.Option value={ZoneOutputAction.STEADY}>Steady</Select.Option>
        <Select.Option value={ZoneOutputAction.PULSE}>Pulse</Select.Option>
        <Select.Option value={ZoneOutputAction.MOMENTARY}>
          Momentary
        </Select.Option>
        <Select.Option value={ZoneOutputAction.FOLLOW}>Follow</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationArmedShortOutputActionField;
