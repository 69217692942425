App.controller("MassProgrammingDashboardCtrl", [
  "$q",
  "$scope",
  "$state",
  "$stateParams",
  "$filter",
  "UserService",
  "dealer_id",
  "DTColumnBuilder",
  "DataTablesUtilsService",
  "MassProgrammingService",
  function (
    $q,
    $scope,
    $state,
    $stateParams,
    $filter,
    UserService,
    dealer_id,
    DTColumnBuilder,
    DataTablesUtilsService,
    MassProgrammingService
  ) {
    $scope.reloadPage = reloadPage;
    $scope.UserService = UserService;
    $scope.showTable = false;
    $scope.massProgramJobs = [];
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    /**
     * @ngdoc object
     * @name getUpdates
     * @methodOf App.controller:MassProgrammingDealerDashboardCtrl
     *
     * @description
     * Function to get the Mass Programming that should appear on this dashboard
     *
     * @returns {Promise} it's complicated
     *
     */
    var getMassProgrammingUpdates = function () {
      var deferred = $q.defer();
      MassProgrammingService.getAllMassProgrammingForDealer(dealer_id)
        .then(
          function (data) {
            $scope.massProgramJobs = data;
            $scope.showTable = $scope.massProgramJobs[0] ? true : false;

            deferred.resolve($scope.massProgramJobs);
          },
          function (error) {
            deferred.reject($scope.massProgramJobs);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    function reloadPage() {
      $state.forceReload();
    }

    function setActiveTab(tab) {
      $state.go("app.dealer.mass_programming", {
        dealer_id: UserService.dealer_id,
        active_tab: tab,
      });
    }

    $scope.setActiveTab = setActiveTab;
    $scope.activeTab =
      $stateParams.active_tab === "global-holiday-dates"
        ? "global-holiday-dates"
        : "main";

    // Calling service to build Table
    $scope.dtOptions = DataTablesUtilsService.getMassProgrammingDTOptions(
      getMassProgrammingUpdates()
    );
    $scope.dtOptions.withDOM(
      '<"data-table__top data-table__top--with-padding" <"table-search"search"rf> <"column-selector" B>><t><"data-table__bottom data-table__bottom--with-padding"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
    );
    $scope.dtOptions.withOption("order", [0, "desc"]);
    $scope.dtOptions.withOption("createdRow", function (row) {
      DataTablesUtilsService.createdRow(row, $scope);
    });

    //List of columns, column properties, and custom methods used to display the data in the Data Table.
    $scope.dtColumns = [
      DTColumnBuilder.newColumn("Id").withTitle("Id"),

      //DTColumnBuilder.newColumn('FirstName').withTitle('First Name'),
      //DTColumnBuilder.newColumn('LastName').withTitle('Last Name'),
      DTColumnBuilder.newColumn("Name")
        .withTitle("Created By")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getFormattedName(
            row,
            "FirstName",
            "LastName"
          );
        }),

      DTColumnBuilder.newColumn("Email")
        .withTitle("Email")
        .withOption("visible", false),
      DTColumnBuilder.newColumn("Type")
        .withTitle("Type")
        .renderWith(function (data, type, row) {
          return $filter("control_system_hardware_model")(
            DataTablesUtilsService.getGroupData(row, "panel_hardware_class")
          );
        }),
      DTColumnBuilder.newColumn("Reason")
        .withTitle("Reason")
        .withOption("visible", false)
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupData(row, "reason");
        }),
      DTColumnBuilder.newColumn("Total")
        .withOption("visible", false)
        .withTitle("Total")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupOutput(row, "total");
        }),
      DTColumnBuilder.newColumn("Running")
        .withOption("visible", false)
        .withTitle("Running")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupOutput(row, "running");
        }),
      DTColumnBuilder.newColumn("Pending")
        .withOption("visible", false)
        .withTitle("Pending")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupOutput(row, "pending");
        }),
      DTColumnBuilder.newColumn("Complete")
        .withOption("visible", false)
        .withTitle("Complete")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupOutput(row, "complete");
        }),
      DTColumnBuilder.newColumn("Failed")
        .withTitle("Failed")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.getGroupOutput(row, "failed");
        }),
      DTColumnBuilder.newColumn("GroupStatus")
        .withTitle("Status")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.massProgrammingAsPercentage(
            row,
            "GroupStatus"
          );
        }),
      // DTColumnBuilder.newColumn('GroupStatus').withTitle('Status').renderWith(function (data, type, row) { return DataTablesUtilsService.formatStatus(row, 'GroupStatus'); }),
      DTColumnBuilder.newColumn("CreatedAt")
        .withTitle("Started")
        .withOption("visible", false)
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asDateTimeFormatted(row, "CreatedAt");
        }),
      DTColumnBuilder.newColumn("CompletedAt")
        .withTitle("Completed")
        .withOption("visible", false)
        .withOption("sType", "date")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asDateTimeFormatted(row, "CompletedAt");
        }),
      DTColumnBuilder.newColumn("Id")
        .notSortable()
        .withTitle("")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.navToMassProgrammingView(
            row,
            "DealerId",
            "Id"
          );
        }),
      //DTColumnBuilder.newColumn('GroupOutput').withTitle('Output'),
    ];
  },
]);
