export const resolveXt75DeviceSetupNumberRules = () => {
  return {
    inlineHelp: "1-8",
    pattern: "(0?[1-8])",
    validationMessage: "Valid values are 1-8.",
  };
};

export const formatToAxDeviceNumber = (
  deviceNumber: number | undefined
): number | undefined => {
  if (deviceNumber) {
    if (deviceNumber > 0 && deviceNumber < 97) {
      return deviceNumber;
    } else if (deviceNumber > 500 && deviceNumber < 962) {
      const calculatedLxValue = //Calculates the AX device number from the LX number
        Math.floor((deviceNumber - 400) / 100) * 16 +
        Math.ceil((deviceNumber % 100) / 4);
      return calculatedLxValue;
    }
  } else return deviceNumber;
};

export const formatToLxDeviceNumber = (
  deviceNumber: number | undefined
): number | undefined => {
  if (deviceNumber) {
    if (deviceNumber < 17 || deviceNumber > 96) {
      return deviceNumber;
    } else {
      const calculatedLxValue = // Calculates the LX number from the number or AX number
        401 +
        100 * Math.floor((deviceNumber - 1) / 16) +
        ((deviceNumber - 1) % 16) * 4;
      return calculatedLxValue;
    }
  } else return deviceNumber;
};
