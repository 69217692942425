import graphql from "babel-plugin-relay/macro";
import { Panel, Table } from "components/DaStyledElements";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import styled from "styled-components";
import { Pages } from "./utils";
import { SystemDiagnosticRootQuery } from "./__generated__/SystemDiagnosticRootQuery.graphql";

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, minmax(0, max-content));
  grid-template-areas: "cred-config" "auto-refill" "history";
  grid-row-gap: 1.6rem;
  max-width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, minmax(0, max-content));
    grid-template-areas: "cred-config auto-refill auto-refill" "history history history";
    grid-gap: 1.6rem;
  }
`;
const GridHistory = styled.div`
  grid-area: history;
`;
const TableHeaderSorting = styled.th`
  cursor: pointer;
  color: #888 !important;
`;
const query = graphql`
  query SystemDiagnosticRootQuery {
    systemDiagnostic {
      j16Reset {
        amount
      }
      powerReset {
        amount
      }
      brownOutReset {
        amount
      }
      watchdogReset {
        amount
      }
      lockupReset {
        amount
      }
      systemReset {
        amount
      }
    }
  }
`;

function SystemDiagnosticsRoot({
  setActivePage,
}: {
  setActivePage: (page: Pages) => void;
}) {
  const data = useLazyLoadQuery<SystemDiagnosticRootQuery>(query, {});

  return (
    <Grid>
      <GridHistory>
        <Panel.Table>
          <Table>
            <thead>
              <tr>
                <TableHeaderSorting>Restart Cause</TableHeaderSorting>
                <TableHeaderSorting>Total</TableHeaderSorting>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <button
                    onClick={() => setActivePage(Pages.J16Reset)}
                    className="link link-primary color-primary-600"
                    style={{ textDecoration: "none" }}
                  >
                    <strong>J16 Reset</strong>
                  </button>
                </td>
                <td>{data.systemDiagnostic.j16Reset.amount}</td>
              </tr>
              <tr>
                <td>
                  <button
                    onClick={() => setActivePage(Pages.PowerReset)}
                    className="link link-primary color-primary-600"
                    style={{ textDecoration: "none" }}
                  >
                    <strong>Power Reset</strong>
                  </button>
                </td>
                <td>{data.systemDiagnostic.powerReset.amount}</td>
              </tr>
              <tr>
                <td>
                  <button
                    onClick={() => setActivePage(Pages.BrownOutReset)}
                    className="link link-primary color-primary-600"
                    style={{ textDecoration: "none" }}
                  >
                    <strong>Brown Out</strong>
                  </button>
                </td>
                <td>{data.systemDiagnostic.brownOutReset.amount}</td>
              </tr>
              <tr>
                <td>
                  <button
                    onClick={() => setActivePage(Pages.WatchdogReset)}
                    className="link link-primary color-primary-600"
                    style={{ textDecoration: "none" }}
                  >
                    <strong>Watchdog</strong>
                  </button>
                </td>
                <td>{data.systemDiagnostic.watchdogReset.amount}</td>
              </tr>
              <tr>
                <td>
                  <button
                    onClick={() => setActivePage(Pages.LockupReset)}
                    className="link link-primary color-primary-600"
                    style={{ textDecoration: "none" }}
                  >
                    <strong>Lock Up</strong>
                  </button>
                </td>
                <td>{data.systemDiagnostic.lockupReset.amount}</td>
              </tr>
              <tr>
                <td>
                  <button
                    onClick={() => setActivePage(Pages.SystemReset)}
                    className="link link-primary color-primary-600"
                    style={{ textDecoration: "none" }}
                  >
                    <strong>System</strong>
                  </button>
                </td>
                <td>{data.systemDiagnostic.systemReset.amount}</td>
              </tr>
            </tbody>
          </Table>
        </Panel.Table>
      </GridHistory>
    </Grid>
  );
}

export default SystemDiagnosticsRoot;
