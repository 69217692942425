import graphql from "babel-plugin-relay/macro";
import FormGridItems from "components/FormGridItems";
import NeutralText from "components/NeutralText";
import { isEmpty } from "ramda";
import React from "react";
import { useFragment } from "react-relay/hooks";
import { Sheet } from "../../Layout/Layout";
import VideoSelection from "./Components/VideoSelection";
import { HanwhaNvrs_hanwhaNvr$key } from "./__generated__/HanwhaNvrs_hanwhaNvr.graphql";
import { HanwhaNvrs_site$key } from "./__generated__/HanwhaNvrs_site.graphql";

export default function HanwhaNvrs({
  siteRef,
}: {
  siteRef: HanwhaNvrs_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment HanwhaNvrs_site on Site {
        hanwhaNvrs {
          ...HanwhaNvrs_hanwhaNvr
          id
        }
      }
    `,
    siteRef
  );
  return (
    <Sheet>
      <VideoSelection>
        <VideoSelection.Description title={<>Hanwha Wave&reg;</>} />
      </VideoSelection>
      {data.hanwhaNvrs.length ? (
        <VideoSelection.Section>
          <FormGridItems
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(30rem, 1fr))",
            }}
          >
            {data.hanwhaNvrs.map((item) => (
              <HanwhaNvr key={item.id} hanwhaNvrRef={item} />
            ))}
          </FormGridItems>
        </VideoSelection.Section>
      ) : (
        <VideoSelection.Section>
          <span className="info-alert">
            <i className="icon-radial_info"></i>
            No Hanwha Wave devices have been added.
          </span>
        </VideoSelection.Section>
      )}
    </Sheet>
  );
}

function HanwhaNvr({
  hanwhaNvrRef,
}: {
  hanwhaNvrRef: HanwhaNvrs_hanwhaNvr$key;
}) {
  const data = useFragment(
    graphql`
      fragment HanwhaNvrs_hanwhaNvr on HanwhaNvr {
        id
        name
        serialNumber
        installerEmail
        channels {
          id
          name
        }
      }
    `,
    hanwhaNvrRef
  );
  return (
    <FormGridItems.Item>
      <FormGridItems.ItemContent>
        <FormGridItems.ItemTitle>{data.name}</FormGridItems.ItemTitle>
        <FormGridItems.ItemSubtitle>
          Serial #: {data.serialNumber || "Unknown"}
        </FormGridItems.ItemSubtitle>
        <FormGridItems.ItemSubtitle>
          Added By: {data.installerEmail || "Unknown"}
        </FormGridItems.ItemSubtitle>
      </FormGridItems.ItemContent>
      {!isEmpty(data.channels) ? (
        data.channels.map((channel) => (
          <FormGridItems.ItemContent key={channel.id}>
            <FormGridItems.ItemTitle>{channel.name}</FormGridItems.ItemTitle>
          </FormGridItems.ItemContent>
        ))
      ) : (
        <FormGridItems.ItemContent>
          <em>
            <NeutralText>No channels have been added</NeutralText>
          </em>
        </FormGridItems.ItemContent>
      )}
    </FormGridItems.Item>
  );
}
