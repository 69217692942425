import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSecurityGradeFragment } from "./SecurityGradeContext";

import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { useHardwareModel } from "../PanelContext";

import { Grades } from "securecom-graphql/client";
import Select from "../Select";
import { SecurityGradeField_securityGrade$key } from "./__generated__/SecurityGradeField_securityGrade.graphql";

export const securityGradeFieldId = () => "security-grade-code";

function SecurityGradeField() {
  const [{ grade }, updateSecurityGrade] =
    useSecurityGradeFragment<SecurityGradeField_securityGrade$key>(
      graphql`
        fragment SecurityGradeField_securityGrade on SecurityGrade {
          id
          grade
        }
      `
    );

  const fieldId = securityGradeFieldId();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Security Grade">
      <Select
        id={fieldId}
        name={fieldId}
        value={grade}
        required
        onChange={({ target }) => {
          updateSecurityGrade((recordProxy) => {
            recordProxy.setValue(target.value, "grade");
          });
        }}
      >
        <Select.Option value={Grades.GRADE_0}>Grade 0</Select.Option>
        {!isXr && <Select.Option value={Grades.GRADE_2}>Grade 2</Select.Option>}
        {isXr && <Select.Option value={Grades.GRADE_3}>Grade 3</Select.Option>}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SecurityGradeField;
