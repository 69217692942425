import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { securityGradeFieldId } from "components/FullProgramming/common/SecurityGradeFields/SecurityGradeField";
import { XTSecurityGradeProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XTFullProgramming/__generated__/XTSecurityGradeProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getSecurityGradeState } from "../../../XTFullProgramming/XTSecurityGradeProgrammingConceptForm";
import { ProgrammingTemplateSecurityGradeInput } from "../Create/__generated__/XTTemplateSaveMutation.graphql";
import { XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts$key } from "./__generated__/XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts.graphql";

export const getState = (
  securityGrade: XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts on XtProgrammingTemplateConcepts
      @inline {
        securityGrade {
          id
          included
          grade {
            included
            data
          }
        }
      }
    `,
    securityGrade
  );

export const getInput = (
  controlSystem: XTSecurityGradeProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateSecurityGradeInput => {
  const {
    panel: { securityGrade },
  } = getSecurityGradeState(controlSystem);

  if (!securityGrade) {
    return { included: false };
  }

  return {
    included: includedFields.has(securityGradeFieldId()),
    grade: {
      included: includedFields.has(securityGradeFieldId()),
      data: securityGrade.grade,
    },
  };
};

export const setIncludedFields = (
  template: XTSecurityGradeTemplateDataInline_xtProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { securityGrade } = getState(template);

  if (securityGrade?.grade?.included) {
    setIncludedFields(setToggle(securityGradeFieldId()));
  }
};
