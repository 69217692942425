import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsEarlyToCloseField_systemReports$key } from "./__generated__/SystemReportsEarlyToCloseField_systemReports.graphql";

export const systemReportsEarlyToCloseFieldId = () =>
  "system-reports-early-to-close";

function SystemReportsEarlyToCloseField() {
  const [
    { earlyToClose, earlyToCloseMin, earlyToCloseMax },
    updateSystemReports,
  ] = useSystemReportsFragment<SystemReportsEarlyToCloseField_systemReports$key>(
    graphql`
      fragment SystemReportsEarlyToCloseField_systemReports on SystemReports {
        earlyToClose
        earlyToCloseMin
        earlyToCloseMax
      }
    `
  );

  const fieldId = systemReportsEarlyToCloseFieldId();
  const originalValue = React.useRef(earlyToClose).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Early to Close"
      tooltip="If enabled in Communication Path Programming, send a message when the System is Early to Close."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={earlyToClose.toString()}
        pattern="(0{0,2}[0-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)"
        required
        min={earlyToCloseMin}
        max={earlyToCloseMax}
        inlineHelp={`${earlyToCloseMin}–${earlyToCloseMax}`}
        onChange={({ target }) => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "earlyToClose"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemReports((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : valueAsNumber === 0
                ? valueAsNumber
                : clamp(earlyToCloseMin, earlyToCloseMax, valueAsNumber);
            recordProxy.setValue(Number(value), "earlyToClose");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsEarlyToCloseField;
