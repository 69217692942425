/**
 * @generated SignedSource<<dd0dee41d2767be7e62172629fbeccc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XROutputGroupsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly outputGroups: ReadonlyArray<{
    readonly id: string;
    readonly included: boolean;
    readonly name: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly number: number;
    readonly outputNumber1: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber2: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber3: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber4: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber5: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber6: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber7: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly outputNumber8: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "XROutputGroupsProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XROutputGroupsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XROutputGroupsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XROutputGroupsProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XROutputGroupsProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "ef0286e7d3cef4ea898485b3665ac4a0";

export default node;
