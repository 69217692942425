/**
 * @generated SignedSource<<89b4d04b9e81ac24073ff1e621f2e041>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRCommunicationProgrammingConceptFormNavButton_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly communicationPaths: ReadonlyArray<{
      readonly isNew: boolean;
    }>;
  };
  readonly " $fragmentType": "XRCommunicationProgrammingConceptFormNavButton_controlSystem";
};
export type XRCommunicationProgrammingConceptFormNavButton_controlSystem$key = {
  readonly " $data"?: XRCommunicationProgrammingConceptFormNavButton_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCommunicationProgrammingConceptFormNavButton_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRCommunicationProgrammingConceptFormNavButton_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommunicationPath",
          "kind": "LinkedField",
          "name": "communicationPaths",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isNew",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "f04f076c075eea38522ad5e2b3ef4007";

export default node;
