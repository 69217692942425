/**
 * @ngdoc service
 * @name App.factory:MassProgrammingService
 *
 * @description
 * Manages the  mass panel programming jobs
 *
 */
App.factory("MassProgrammingService", [
  "$q",
  "$stateParams",
  "JobSchedulerService",
  "UserService",
  function ($q, $stateParams, JobSchedulerService, UserService) {
    return {
      /**
       * Local constants to define which jobType and jobGroupType we are using for mass programming jobs.
       */
      JOB_TYPE_NAME: "MASS_PROG",
      //JOB_TYPE_NAME: 'THREAD_TEST',
      JOB_GROUP_TYPE_NAME: "MASS_PROG",

      /**
       * The array of mass programming job groups that are in the current context (one panel)
       */
      massProgrammingJobGroups: [],

      massProgrammingFormat: [
        { outer: "Communications", inner: "communication_paths" },
        { outer: "Communications", inner: "communication" },
        { outer: "RemoteOptions", inner: "remote_options" },
        { outer: "SystemOptions", inner: "system_options" },
        { outer: "SystemReports", inner: "system_reports" },
        { outer: "StatusList", inner: "status_list" },
        { outer: "BellOptions", inner: "bell_options" },
        { outer: "OutputOptions", inner: "output_options" },
        { outer: "LockoutCode", inner: "lockout_code" },
        { outer: "SecurityGrade", inner: "security_grade" },
      ],

      /**
       * Creates a scheduled JobGroup to programm the activePanel with the mass programming change specified
       * @returns {Promise} a promise, which will eventually resolve the JSON data of the JobGroup that is created.
       */
      //to do figure out what arguments to pass to the mass programming job to create the job
      massProgramPanels: function (panel, panelIds, enabled) {
        var _this = this;
        var deferred = $q.defer();
        var job = JobSchedulerService.getJobTemplate();
        var jobGroup = JobSchedulerService.getJobGroupTemplate();

        var jobGroupTypePromise = JobSchedulerService.getJobGroupTypeByName(
          this.JOB_GROUP_TYPE_NAME
        );
        var jobTypePromise = JobSchedulerService.getJobTypeByName(
          this.JOB_TYPE_NAME
        );

        $q.all([jobGroupTypePromise, jobTypePromise])
          .then(
            function (values) {
              jobGroup.SchedulerJobGroupTypeId = values[0].Id;
              job.SchedulerJobTypeId = values[1].Id;
              jobGroup.GroupData = angular.toJson(
                _this.getGroupData(panel, enabled)
              );
              jobGroup.DealerId = $stateParams.dealer_id;

              for (let pIds of panelIds) {
                let newJob = angular.copy(job);
                newJob.PanelId = pIds;
                newJob.ReferenceDesc = values[1].JobTypeDesc;
                delete newJob.RemoteJson;
                delete newJob.RemoteAction;
                jobGroup.SchedulerJobs.push(newJob);
              }
              JobSchedulerService.createJobGroup(jobGroup)
                .then(
                  function (data) {
                    _this.massProgrammingJobGroups.push(data);
                    deferred.resolve(data);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the status of that specific Job Group ID
       * @param jobID
       */
      getMassProgrammingStatus: function (jobGroupID) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupReportData(jobGroupID)
          .then(
            function (jobGroups) {
              UserService.dealerInfo
                .getPersonnel()
                .then(
                  function (persons) {
                    for (let jobGroup in jobGroups) {
                      jobGroups[jobGroup].FirstName = null;
                      jobGroups[jobGroup].LastName = null;
                      for (let person in persons) {
                        if (
                          jobGroups[jobGroup].UserId == persons[person].user.id
                        ) {
                          jobGroups[jobGroup].FirstName =
                            persons[person].user.first_name;
                          jobGroups[jobGroup].LastName =
                            persons[person].user.last_name;
                        }
                      }
                    }
                    deferred.resolve(jobGroups);
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get the status of that specific Job Group ID
       * @param jobID
       */
      getMassProgrammingJobPanelsStatus: function (jobGroupID) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobsListByJobGroupId(jobGroupID)
          .then(
            function (jobGroups) {
              deferred.resolve(jobGroups);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return deferred.promise;
      },

      /**
       * Get all Mass Programming for the specified Dealer
       * @param dealer_id
       * @returns {*}
       */
      getAllMassProgrammingForDealer: function (dealer_id) {
        var _this = this;
        var deferred = $q.defer();
        JobSchedulerService.getJobGroupTypeByName(_this.JOB_GROUP_TYPE_NAME)
          .then(
            function (data) {
              var jobGroupTypeID = data.Id;
              JobSchedulerService.getJobGroupsByDealerId(
                jobGroupTypeID,
                dealer_id
              )
                .then(
                  function (updatedJobGroups) {
                    //Apply the personnel names to the Job
                    UserService.dealerInfo
                      .getPersonnel()
                      .then(
                        function (persons) {
                          for (let jobGroup in updatedJobGroups) {
                            updatedJobGroups[jobGroup].FirstName = null;
                            updatedJobGroups[jobGroup].LastName = null;
                            for (let person in persons) {
                              if (
                                updatedJobGroups[jobGroup].UserId ==
                                persons[person].user.id
                              ) {
                                updatedJobGroups[jobGroup].FirstName =
                                  persons[person].user.first_name;
                                updatedJobGroups[jobGroup].LastName =
                                  persons[person].user.last_name;
                              }
                            }
                          }
                          deferred.resolve(updatedJobGroups);
                        },
                        function (error) {
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      /**
       * Function to set the GroupData field on the Job Group
       * @param panel
       */
      getGroupData: function (panel, enabled) {
        var _this = this;

        var groupData = {};

        for (let i in _this.massProgrammingFormat) {
          var outer = _this.massProgrammingFormat[i].outer;
          var inner = _this.massProgrammingFormat[i].inner;
          if (!groupData.hasOwnProperty(outer)) {
            groupData[outer] = {};
          }

          groupData[outer][inner] = inner;

          if (panel[inner]) {
            groupData[outer][inner] = panel[inner];
          } else {
            groupData[outer][inner] = null;
          }
        }

        //Cleanup for Communications paths that do not exist and putting them in the format that the API needs to be sent.

        if (groupData.hasOwnProperty("Communications")) {
          if (groupData.Communications.communication_paths) {
            for (let i in groupData.Communications.communication_paths) {
              var comPath = angular.copy(
                groupData.Communications.communication_paths[i]
              );
              //This is to kill off all of the Com Paths that have no enabled fields
              groupData.Communications.communication_paths[i] = {};

              if (enabled.Panel.hasOwnProperty("communication_paths")) {
                for (let enabledComPath in enabled.Panel.communication_paths) {
                  var includePath = false;
                  for (let field in enabled.Panel.communication_paths[
                    enabledComPath
                  ]) {
                    if (
                      field !== "isOpen" &&
                      field !== "number" &&
                      enabled.Panel.communication_paths[enabledComPath][field]
                    ) {
                      includePath = true;
                    }
                  }
                  if (enabledComPath == comPath.number && includePath) {
                    groupData.Communications.communication_paths[
                      i
                    ].communication_path = comPath;
                  }
                }
              }
            }
            var finalComPathData = groupData.Communications.communication_paths;
            groupData.Communications = finalComPathData;
          }
        }

        groupData.panel_id = panel.panel_id;
        groupData.panel_model = panel.panel_model;
        groupData.panel_version = panel.panel_version;
        groupData.concepts = panel.concepts;
        groupData.reason = panel.reason;
        groupData.panel_hardware_class = panel.panel_hardware_class;

        return groupData;
      },

      /**
       * Function to apply group data from a Mass Programming Group Job to a panel object
       * @param {*} panel
       * @param {*} groupData
       */

      applyGroupDataToPanel: function (panel, groupData) {
        var _this = this;

        for (let i in _this.massProgrammingFormat) {
          var outer = _this.massProgrammingFormat[i].outer;
          var inner = _this.massProgrammingFormat[i].inner;

          if (groupData.hasOwnProperty(outer)) {
            if (groupData[outer][inner]) {
              if (Object.keys(groupData[outer][inner]).length != 0) {
                panel[inner] = groupData[outer][inner];
              }
            }
            if (
              outer == "Communications" &&
              inner == "communication_paths" &&
              panel.panel_model == "XR550"
            ) {
              for (let i in groupData.Communications) {
                if (groupData.Communications[i].communication_path) {
                  if (!panel.hasOwnProperty("communication_paths")) {
                    panel.communication_paths = [];
                  }
                  panel.communication_paths.push(
                    groupData.Communications[i].communication_path
                  );
                }
              }
              if (panel.hasOwnProperty("communication_paths")) {
                panel.communication_paths.isArray = true;
              }
            }
          }
        }
        panel.panel_hardware_class = groupData.panel_hardware_class;

        return panel;
      },
    };
  },
]);
