import DaColors, { defaultTheme } from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import NakedButton from "common/components/web/Button/NakedButton";
import Icon from "components/Icon";
import TextInput from "components/Inputs/TextInput";
import LoadingSpinner from "components/LoadingSpinner";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-relay";
import styled from "styled-components";
import { focusRing } from "../../utils/styles";
import { FPS_RANGE } from "../Recorder/constants";
import { CameraType } from "./index";
import { CameraListItemAddToVarHubEnabledCameraListMutation } from "./__generated__/CameraListItemAddToVarHubEnabledCameraListMutation.graphql";
import { CameraListItemDeleteFromVarHubEnabledCameraListMutation } from "./__generated__/CameraListItemDeleteFromVarHubEnabledCameraListMutation.graphql";
import { CameraListItemHideFromVarHubEnabledCameraListMutation } from "./__generated__/CameraListItemHideFromVarHubEnabledCameraListMutation.graphql";
import { CameraListItemRemoveFromVarHubEnabledCameraListMutation } from "./__generated__/CameraListItemRemoveFromVarHubEnabledCameraListMutation.graphql";
import { CameraListItemUnhideFromVarHubEnabledCameraListMutation } from "./__generated__/CameraListItemUnhideFromVarHubEnabledCameraListMutation.graphql";
import { CameraListItemUpdateVarHubsCameraCredentialsMutation } from "./__generated__/CameraListItemUpdateVarHubsCameraCredentialsMutation.graphql";
import { CameraListItem_getCameraSnapshotMutation } from "./__generated__/CameraListItem_getCameraSnapshotMutation.graphql";

const CameraListItem = ({
  camera,
  systemId,
  hubId,
  index,
  cameraPrice,
  canViewPricing,
  autoAddCameras,
}: {
  camera?: CameraType | null;
  systemId: string;
  hubId: number | undefined;
  index: number;
  cameraPrice: number;
  canViewPricing: boolean;
  autoAddCameras: boolean;
}) => {
  const username = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const showAlert = useShowAlert();
  const [confirmingScapiToVarEnable, setConfirmingScapiToVarEnable] =
    useState<boolean>(false);

  const [enableCamera, enablingCamera] =
    useMutation<CameraListItemAddToVarHubEnabledCameraListMutation>(
      graphql`
        mutation CameraListItemAddToVarHubEnabledCameraListMutation(
          $systemId: String!
          $hubId: String!
          $addition: AddToVarHubEnabledCamerasListInput!
          $isScapiCamera: Boolean!
          $scapiCameraId: String
          $macAddress: String
          $camectCameraId: String
        ) {
          addToVarHubEnabledCamerasList(
            hubId: $hubId
            systemId: $systemId
            addition: $addition
            isScapiCamera: $isScapiCamera
            scapiCameraId: $scapiCameraId
            macAddress: $macAddress
            camectCameraId: $camectCameraId
          ) {
            ... on AddToVarHubEnabledCamerasListSuccessPayload {
              system {
                varHubs {
                  camectHubId
                  hubId
                  cameras {
                    addedToDB
                    camectCamId
                    camectHubId
                    cameraId
                    cameraName
                    ipAddress
                    isEnabled
                    isStreaming
                    isScapiCamera
                    macAddress
                    needsCredential
                    playerAuthToken
                    playerUrl
                    videoCodec
                    megapixels
                    framesPerSecond
                    isHidden
                    scapiCameraId
                  }
                }
                secureComCameras {
                  id
                  deviceType
                  name
                  lastCheckIn
                  isOnline
                  status
                  model
                  formFactor
                  imageSize
                  macAddress
                  networkReady
                  manufacturer
                }
              }
              __typename
            }
            ... on AddToVarHubEnabledCamerasListErrorPayload {
              type
              message
              __typename
            }
          }
        }
      `
    );

  const [disableCamera, disablingCamera] =
    useMutation<CameraListItemRemoveFromVarHubEnabledCameraListMutation>(
      graphql`
        mutation CameraListItemRemoveFromVarHubEnabledCameraListMutation(
          $systemId: String!
          $hubId: String!
          $removal: RemoveFromVarHubEnabledCamerasListInput!
        ) {
          removeFromVarHubEnabledCamerasList(
            hubId: $hubId
            systemId: $systemId
            removal: $removal
          ) {
            ... on RemoveFromVarHubEnabledCamerasListSuccessPayload {
              hubs {
                camectHubId
                hubId
                cameras {
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraId
                  cameraName
                  ipAddress
                  isEnabled
                  isStreaming
                  isScapiCamera
                  macAddress
                  needsCredential
                  playerAuthToken
                  playerUrl
                  videoCodec
                  megapixels
                  framesPerSecond
                  isHidden
                  scapiCameraId
                }
              }
              __typename
            }
            ... on RemoveFromVarHubEnabledCamerasListErrorPayload {
              type
              message
              __typename
            }
          }
        }
      `
    );

  const [hideCamera, hidingCamera] =
    useMutation<CameraListItemHideFromVarHubEnabledCameraListMutation>(
      graphql`
        mutation CameraListItemHideFromVarHubEnabledCameraListMutation(
          $systemId: String!
          $hubId: String!
          $hide: HideFromVarHubEnabledCamerasListInput!
        ) {
          hideFromVarHubEnabledCamerasList(
            hubId: $hubId
            systemId: $systemId
            hide: $hide
          ) {
            ... on HideFromVarHubEnabledCamerasListSuccessPayload {
              hubs {
                camectHubId
                hubId
                cameras {
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraId
                  cameraName
                  ipAddress
                  isEnabled
                  isStreaming
                  isScapiCamera
                  macAddress
                  needsCredential
                  playerAuthToken
                  playerUrl
                  videoCodec
                  megapixels
                  framesPerSecond
                  isHidden
                  scapiCameraId
                }
              }
              __typename
            }
            ... on HideFromVarHubEnabledCamerasListErrorPayload {
              type
              message
              __typename
            }
          }
        }
      `
    );

  const [unhideCamera, unhidingCamera] =
    useMutation<CameraListItemUnhideFromVarHubEnabledCameraListMutation>(
      graphql`
        mutation CameraListItemUnhideFromVarHubEnabledCameraListMutation(
          $systemId: String!
          $hubId: String!
          $unhide: UnhideFromVarHubEnabledCamerasListInput!
        ) {
          unhideFromVarHubEnabledCamerasList(
            hubId: $hubId
            systemId: $systemId
            unhide: $unhide
          ) {
            ... on UnhideFromVarHubEnabledCamerasListSuccessPayload {
              hubs {
                camectHubId
                hubId
                cameras {
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraId
                  cameraName
                  ipAddress
                  isEnabled
                  isStreaming
                  isScapiCamera
                  macAddress
                  needsCredential
                  playerAuthToken
                  playerUrl
                  videoCodec
                  megapixels
                  framesPerSecond
                  isHidden
                  scapiCameraId
                }
              }
              __typename
            }
            ... on UnhideFromVarHubEnabledCamerasListErrorPayload {
              type
              message
              __typename
            }
          }
        }
      `
    );

  const [deleteCamera, deletingCamera] =
    useMutation<CameraListItemDeleteFromVarHubEnabledCameraListMutation>(
      graphql`
        mutation CameraListItemDeleteFromVarHubEnabledCameraListMutation(
          $systemId: String!
          $hubId: String!
          $macAddress: String!
          $deletes: DeleteFromVarHubEnabledCamerasListInput!
        ) {
          deleteFromVarHubEnabledCamerasList(
            hubId: $hubId
            systemId: $systemId
            macAddress: $macAddress
            deletes: $deletes
          ) {
            ... on DeleteFromVarHubEnabledCamerasListSuccessPayload {
              hubs {
                camectHubId
                hubId
                cameras {
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraId
                  cameraName
                  ipAddress
                  isEnabled
                  isStreaming
                  isScapiCamera
                  macAddress
                  needsCredential
                  playerAuthToken
                  playerUrl
                  videoCodec
                  megapixels
                  framesPerSecond
                  isHidden
                  scapiCameraId
                }
              }
              __typename
            }
            ... on DeleteFromVarHubEnabledCamerasListErrorPayload {
              type
              message
              __typename
            }
          }
        }
      `
    );

  const [updateCameraCredentials, updatingCameraCredentials] =
    useMutation<CameraListItemUpdateVarHubsCameraCredentialsMutation>(
      graphql`
        mutation CameraListItemUpdateVarHubsCameraCredentialsMutation(
          $systemId: String!
          $hubId: String!
          $credentials: UpdateVarHubCameraCredentialsInput!
        ) {
          updateVarHubCameraCredentials(
            hubId: $hubId
            systemId: $systemId
            credentials: $credentials
          ) {
            ... on UpdateVarHubCameraCredentialsSuccessPayload {
              hubs {
                cameras {
                  addedToDB
                  camectCamId
                  camectHubId
                  cameraName
                  ipAddress
                  isEnabled
                  isStreaming
                  isScapiCamera
                  macAddress
                  needsCredential
                  playerAuthToken
                  playerUrl
                  videoCodec
                  megapixels
                  framesPerSecond
                  isHidden
                  scapiCameraId
                }
              }
              __typename
            }
            ... on UpdateVarHubCameraCredentialsErrorPayload {
              type
              message
              __typename
            }
          }
        }
      `
    );

  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const moreButtonRef = useRef<HTMLButtonElement | null>(null);
  const [morePopupPosition, setMoreMorePopupPosition] = useState({
    top: 0,
    left: 0,
  });
  const [forcePasswordChange, setForcePasswordChange] = useState(false);
  const [openCameraPopup, setOpenCameraPopup] = useState<CameraType | null>(
    null
  );
  const [removeCamera, setRemoveCamera] = useState(false);

  useEffect(() => {
    const updateMorePopupPosition = () => {
      if (moreButtonRef.current) {
        setMoreMorePopupPosition({
          top:
            moreButtonRef.current.offsetTop +
            moreButtonRef.current.offsetHeight,
          left: moreButtonRef.current.offsetLeft,
        });
      }
    };

    if (isMoreOpen) {
      updateMorePopupPosition();
    }
  }, [isMoreOpen]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleDocumentClick = (event: Event) => {
        closeMorePopup();
      };

      document.addEventListener("click", handleDocumentClick);

      return () => {
        document.removeEventListener("click", handleDocumentClick);
      };
    }
  }, [isMoreOpen]);

  const openMorePopup = (camera: CameraType) => {
    setOpenCameraPopup(camera);
    setIsMoreOpen(true);
  };

  const closeMorePopup = () => {
    setOpenCameraPopup(null);
    setIsMoreOpen(false);
  };

  const handleHide = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    closeMorePopup();
    handleHideCamera(camera, hubId, systemId);
  };

  const handleUnhide = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    closeMorePopup();
    handleUnhideCamera(camera, hubId, systemId);
  };

  const handleDelete = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    closeMorePopup();
    handleDeleteCamera(camera, hubId, systemId);
  };

  const handleRemoveCamera = () => {
    closeMorePopup();
    setForcePasswordChange(false);
    setRemoveCamera(true);
  };

  const handleUpdatePassword = () => {
    closeMorePopup();
    setRemoveCamera(false);
    setForcePasswordChange(true);
  };

  const handleDisableCamera = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    disableCamera({
      variables: {
        hubId: String(hubId),
        systemId: systemId,
        removal: { removeCamectCameraId: [camera.camectCamId] },
      },
      onCompleted: (res) => {
        if (
          res.removeFromVarHubEnabledCamerasList.__typename ===
          "RemoveFromVarHubEnabledCamerasListErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: "Error Updating Camera List.",
          });
        } else {
          showAlert({
            type: "success",
            text: "Successfully updated camera list.",
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Error updating camera List.",
        });
      },
    });
  };

  const handleEnableCamera = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    enableCamera({
      variables: {
        hubId: String(hubId),
        systemId: systemId,
        addition: { addCamectCameraId: [camera.camectCamId] },
        isScapiCamera: camera.isScapiCamera,
        scapiCameraId: camera.scapiCameraId
          ? String(camera.scapiCameraId)
          : null,
        macAddress: camera.macAddress,
        camectCameraId: camera.camectCamId ?? null,
      },
      onCompleted: (res) => {
        setConfirmingScapiToVarEnable(false);
        if (
          res.addToVarHubEnabledCamerasList.__typename ===
          "AddToVarHubEnabledCamerasListErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: "Error Updating Camera List.",
          });
        } else {
          showAlert({
            type: "success",
            text: "Successfully updated camera list.",
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Error updating camera List.",
        });
      },
    });
  };

  const handleHideCamera = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    hideCamera({
      variables: {
        hubId: String(hubId),
        systemId: systemId,
        hide: { hideCamectCameraId: [camera.camectCamId] },
      },
      onCompleted: (res) => {
        if (
          res.hideFromVarHubEnabledCamerasList.__typename ===
          "HideFromVarHubEnabledCamerasListErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: "Error Updating Camera List.",
          });
        } else {
          showAlert({
            type: "success",
            text: "Successfully updated camera list.",
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Error updating camera List.",
        });
      },
    });
  };

  const handleUnhideCamera = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    unhideCamera({
      variables: {
        hubId: String(hubId),
        systemId: systemId,
        unhide: { unhideCamectCameraId: [camera.camectCamId] },
      },
      onCompleted: (res) => {
        if (
          res.unhideFromVarHubEnabledCamerasList.__typename ===
          "UnhideFromVarHubEnabledCamerasListErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: "Error Updating Camera List.",
          });
        } else {
          showAlert({
            type: "success",
            text: "Successfully updated camera list.",
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Error updating camera List.",
        });
      },
    });
  };

  const handleDeleteCamera = (
    camera: CameraType,
    hubId: number | undefined,
    systemId: string
  ) => {
    deleteCamera({
      variables: {
        hubId: String(hubId),
        systemId: systemId,
        macAddress: camera.macAddress,
        deletes: { deleteCamectCameraId: [camera.camectCamId] },
      },
      onCompleted: (res) => {
        if (
          res.deleteFromVarHubEnabledCamerasList.__typename ===
          "DeleteFromVarHubEnabledCamerasListErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: "Error Updating Camera List.",
          });
        } else {
          showAlert({
            type: "success",
            text: "Successfully updated camera list.",
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Error updating camera List.",
        });
      },
    });
  };

  if (!camera || (camera.isEnabled == false && autoAddCameras === false))
    return null;
  return (
    <Root index={index}>
      <StreamPreview camera={camera} systemId={systemId} />
      <NameContainer>{camera.cameraName}</NameContainer>
      <IPContainer>{camera.ipAddress}</IPContainer>
      <MacContainer>{camera.macAddress || "N/A"}</MacContainer>
      <SettingsContainer>
        <span>
          {camera.addedToDB && camera.megapixels && camera.megapixels > 0
            ? `${camera.megapixels} MP`
            : "N/A"}
        </span>
        <span
          className={
            camera.addedToDB &&
            camera.framesPerSecond > 0 &&
            (camera.framesPerSecond < FPS_RANGE.MIN ||
              camera.framesPerSecond > FPS_RANGE.MAX)
              ? "text-danger"
              : ""
          }
        >
          {camera.addedToDB && camera.framesPerSecond > 0
            ? `${camera.framesPerSecond} FPS`
            : ""}
        </span>
        <span
          className={
            camera.addedToDB &&
            camera.videoCodec !== "" &&
            camera.videoCodec !== "H.264"
              ? "text-danger"
              : ""
          }
        >
          {camera.addedToDB && camera.videoCodec !== ""
            ? `${camera.videoCodec}`
            : ""}
        </span>
      </SettingsContainer>
      <ActivationAndPriceContainer>
        <MoreButton
          onClick={() => {
            openMorePopup(camera);
          }}
          type="button"
          title="More options"
          disabled={
            enablingCamera ||
            disablingCamera ||
            hidingCamera ||
            unhidingCamera ||
            deletingCamera
          }
          ref={moreButtonRef}
        >
          <MoreIcon viewBox="0 0 64 64">
            <circle r="8" cx="8" cy="32" fill="currentColor" />
            <circle r="8" cx="32" cy="32" fill="currentColor" />
            <circle r="8" cx="56" cy="32" fill="currentColor" />
          </MoreIcon>
        </MoreButton>
        {isMoreOpen && openCameraPopup?.cameraId === camera.cameraId ? (
          <Popup
            className="popup"
            style={{
              top: `${morePopupPosition.top}px`,
              left: `${morePopupPosition.left}px`,
            }}
            ref={containerRef}
            onBlur={() => setIsMoreOpen(false)}
          >
            {!camera.manuallyAdded ? (
              <>
                {!camera.isHidden ? (
                  <OptionButton
                    onClick={() => handleHide(camera, hubId, systemId)}
                  >
                    Hide Camera
                  </OptionButton>
                ) : null}
                {camera.isHidden ? (
                  <OptionButton
                    onClick={() => handleUnhide(camera, hubId, systemId)}
                  >
                    Unhide Camera
                  </OptionButton>
                ) : null}
                <OptionButton onClick={() => handleRemoveCamera()}>
                  Remove Camera
                </OptionButton>
                {camera.isEnabled ? (
                  <OptionButton onClick={() => handleUpdatePassword()}>
                    Enter Password
                  </OptionButton>
                ) : null}
              </>
            ) : null}
            {camera.manuallyAdded ? (
              <>
                {!camera.isHidden ? (
                  <OptionButton
                    onClick={() => handleHide(camera, hubId, systemId)}
                  >
                    Hide Camera
                  </OptionButton>
                ) : null}
                {camera.isHidden ? (
                  <OptionButton
                    onClick={() => handleUnhide(camera, hubId, systemId)}
                  >
                    Unhide Camera
                  </OptionButton>
                ) : null}
                <OptionButton
                  onClick={() => handleDelete(camera, hubId, systemId)}
                >
                  Delete Camera
                </OptionButton>
              </>
            ) : null}
          </Popup>
        ) : null}
        <ButtonContainer>
          <button
            className={`btn btn-${camera.addedToDB ? "danger" : "dmp"} btn-sm`}
            onClick={() => {
              if (camera.addedToDB) {
                handleDisableCamera(camera, hubId, systemId);
              } else if (!camera.addedToDB && camera.isScapiCamera) {
                setConfirmingScapiToVarEnable(true);
              } else {
                handleEnableCamera(camera, hubId, systemId);
              }
            }}
            disabled={
              enablingCamera ||
              disablingCamera ||
              hidingCamera ||
              unhidingCamera ||
              deletingCamera ||
              confirmingScapiToVarEnable
            }
          >
            {(enablingCamera && !camera.isScapiCamera) ||
            disablingCamera ||
            hidingCamera ||
            unhidingCamera ||
            deletingCamera ? (
              <LoadingSpinner style={{ marginRight: "5px" }} />
            ) : null}
            {hidingCamera
              ? "Hiding"
              : unhidingCamera
              ? "Unhiding"
              : deletingCamera && camera.manuallyAdded
              ? "Deleting"
              : deletingCamera
              ? "Removing"
              : camera.addedToDB
              ? "Disable"
              : "Enable"}
          </button>
        </ButtonContainer>
        {canViewPricing ? (
          <Price enabled={camera.addedToDB}>
            {`$${Number(cameraPrice).toFixed(2)}/mo`}
          </Price>
        ) : null}
      </ActivationAndPriceContainer>
      {removeCamera ? (
        <RemoveContainer>
          <>
            <span className="text-danger">
              Note: Removing a camera will delete all detection regions, zones,
              and video actions tied to this camera.
            </span>
            <br />
            <br />
            Remove Camera if you have made changes to your camera network. The
            camera will be disabled and removed from Dealer Admin. Cameras on
            the same subnet will be automatically discovered and added to the
            camera list. Click Remove to proceed.
          </>
          <ButtonsContainer>
            <button
              className="btn btn-default btn-sm"
              onClick={() => setRemoveCamera(false)}
            >
              Cancel
            </button>
            <button
              disabled={deletingCamera}
              style={{ marginLeft: "5px" }}
              className="btn btn-danger btn-sm"
              onClick={() => {
                setRemoveCamera(false);
                handleDelete(camera, hubId, systemId);
              }}
            >
              Remove
            </button>
          </ButtonsContainer>
        </RemoveContainer>
      ) : null}
      {forcePasswordChange || (camera.needsCredential && camera.addedToDB) ? (
        <CredsContainer>
          {forcePasswordChange ? (
            <div>
              Enter camera password to send to the XV Gateway to restore video
              stream. Ensure the camera has been rebooted after changing the
              password in the camera UI prior to sending updated credentials to
              the XV Gateway.
              <br />
              <br />
              Note: This does not change the camera's password; it only resends
              the camera password to the XV Gateway.
            </div>
          ) : (
            <div>
              The username and password are needed to add this camera. Enter
              them below to continue adding this camera.
            </div>
          )}
          <InputsContainer>
            Username
            <CredsTextInput
              ref={username}
              disabled={updatingCameraCredentials}
            />
            Password
            <CredsTextInput
              ref={password}
              disabled={updatingCameraCredentials}
            />
          </InputsContainer>
          <ButtonsContainer>
            {forcePasswordChange ? (
              <button
                disabled={updatingCameraCredentials}
                className="btn btn-dmp btn-sm"
                onClick={() => setForcePasswordChange(false)}
              >
                Cancel
              </button>
            ) : null}
            <button
              disabled={updatingCameraCredentials}
              style={{ marginLeft: "5px" }}
              className="btn btn-dmp btn-sm"
              onClick={() =>
                updateCameraCredentials({
                  variables: {
                    hubId: String(hubId),
                    systemId: systemId,
                    credentials: {
                      camectCamId: camera.camectCamId,
                      password: password.current?.value ?? "",
                      username: username.current?.value ?? "",
                    },
                  },
                  onCompleted: (res) => {
                    if (forcePasswordChange) {
                      setForcePasswordChange(false);
                    }
                    if (
                      res.updateVarHubCameraCredentials?.__typename ===
                      "UpdateVarHubCameraCredentialsErrorPayload"
                    ) {
                      showAlert({
                        type: "error",
                        text: "Error updating camera credentials.",
                      });
                    } else {
                      showAlert({
                        type: "success",
                        text: "Successfully updated camera credentials",
                      });
                    }
                  },
                  onError: (err) => {
                    showAlert({
                      type: "error",
                      text: "Error updating camera credentials.",
                    });
                  },
                })
              }
            >
              {updatingCameraCredentials ? (
                <LoadingSpinner style={{ marginRight: "5px" }} />
              ) : null}
              Send
            </button>
          </ButtonsContainer>
        </CredsContainer>
      ) : null}
      {confirmingScapiToVarEnable ? (
        <CredsContainer>
          <Icon
            name="radial_info"
            size="1.5rem"
            style={{ marginRight: "0.5rem" }}
          />
          This camera has been previously added to this system. By enabling this
          device, the XV hub will take over this camera. Are you sure you want
          to take this device over with the XV hub?
          <ButtonsContainer>
            <button
              disabled={enablingCamera}
              className="btn btn-danger btn-sm"
              onClick={() => setConfirmingScapiToVarEnable(false)}
            >
              Cancel
            </button>
            <button
              disabled={enablingCamera}
              style={{ marginLeft: "5px" }}
              className="btn btn-dmp btn-sm"
              onClick={() => handleEnableCamera(camera, hubId, systemId)}
            >
              {enablingCamera ? (
                <LoadingSpinner style={{ marginRight: "5px" }} />
              ) : null}
              Confirm
            </button>
          </ButtonsContainer>
        </CredsContainer>
      ) : null}
    </Root>
  );
};

const MoreButton = styled(NakedButton)`
  color: var(--color-neutral-800);
  font-size: var(--measure-font-20);

  &:hover {
    color: var(--color-text-default);
  }

  &:focus {
    ${focusRing};
  }
  align-self: flex-end;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
`;

const MoreIcon = styled.svg`
  width: 1em;
  height: 1em;
`;

const StreamPreview = ({
  camera,
  systemId,
}: {
  camera: { addedToDB: boolean; cameraId?: number | null; snapshot?: string };
  systemId: string;
}) => {
  const [getCameraSnapshot, gettingCameraSnapshot] =
    useMutation<CameraListItem_getCameraSnapshotMutation>(
      graphql`
        mutation CameraListItem_getCameraSnapshotMutation(
          $cameraId: String!
          $systemId: ID!
        ) {
          takeVarCameraSnapshot(cameraId: $cameraId, systemId: $systemId) {
            ... on TakeVarCameraSnapshotSuccessPayload {
              image_url
            }
            ... on TakeVarCameraSnapshotErrorPayload {
              type
            }
          }
        }
      `
    );
  const [snapshotPreview, setSnapshotPreview] = useState<
    string | null | undefined
  >(camera.snapshot ?? "");

  useEffect(() => {
    if (!camera.addedToDB) return;
    getCameraSnapshot({
      variables: {
        cameraId: camera?.cameraId?.toString() ?? "",
        systemId: systemId,
      },
      onCompleted: (res) => {
        if (res.takeVarCameraSnapshot.image_url) {
          setSnapshotPreview(() => res.takeVarCameraSnapshot.image_url);
        } else {
          setSnapshotPreview(() => null);
        }
      },
    });
  }, [camera, getCameraSnapshot, systemId]);

  return (
    <StreamContainer>
      {camera.addedToDB ? (
        snapshotPreview ? (
          <PreviewContainer>
            {gettingCameraSnapshot && !snapshotPreview ? (
              <LoadingSpinner />
            ) : (
              <Preview src={snapshotPreview} />
            )}
          </PreviewContainer>
        ) : (
          <PreviewContainer>
            {snapshotPreview === null ? (
              <>
                <Icon
                  name="radial_info"
                  size="1.5rem"
                  style={{ marginRight: "0.5rem" }}
                />
                No Preview Available
              </>
            ) : (
              <LoadingSpinner />
            )}
          </PreviewContainer>
        )
      ) : (
        <NoPreviewContainer>
          <Icon
            name="radial_info"
            size="1.5rem"
            style={{ marginRight: "0.5rem" }}
          />
          Enable to Preview
        </NoPreviewContainer>
      )}
    </StreamContainer>
  );
};

export default CameraListItem;

const Root = styled.div<{ index: number }>`
  border-radius: 0.5rem;
  background-color: ${(props) => (props.index % 2 === 0 ? "#f5f7f9" : "white")};
  grid-column: 1 / 7;
  display: grid;
  grid-template-columns: [first] 154px 210px repeat(4, 125px) [end];
  grid-auto-rows: minmax(5.35rem, max-content);
  align-items: center;
`;
const StreamContainer = styled.div`
  padding: 0.5rem;
  grid-column: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NameContainer = styled.span`
  grid-column: 2 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IPContainer = styled.span`
  grid-column: 3 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MacContainer = styled.span`
  grid-column: 4 / 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SettingsContainer = styled.span`
  grid-column: 5 / 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ActivationAndPriceContainer = styled.div`
  grid-column: 6 / 7;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
  min-height: 7.8rem;
`;
const Price = styled.div<{ enabled: boolean }>`
  color: ${(props) =>
    props.enabled ? defaultTheme.grayMedium : DaColors.Info500};
`;
const CredsContainer = styled.div`
  grid-column: 1 / 7;
  margin: 1rem;
  padding-left: 2.5rem;
`;
const InputsContainer = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
`;
const CredsTextInput = styled(TextInput)`
  width: 42rem;
`;
const RemoveContainer = styled.div`
  grid-column: 1 / 7;
  margin: 1rem;
  padding-left: 2.5rem;
`;
const ButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;
const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  text-align: center;
  width: 15rem;
  height: 9rem;
  background-color: ${defaultTheme.primary400};
`;
const Preview = styled.img`
  width: 100%;
  height: 100%;
`;
const NoPreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Popup = styled.div`
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  background-color: white;
  border: 0.1rem solid #ccc;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 13rem;
  border-radius: 0.2rem;
  transition: background-color 0.3s ease;

  button {
    /* Button styling */
  }
`;
const OptionButton = styled.button`
  outline: none;
  justify-content: flex-start;
  text-align: left;
  border: none;
  background-color: transparent;
  padding-left: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  overflow: hidden;
  border-radius: 0.2rem;

  &:hover {
    background-color: #e5e9f0;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
