import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { CardType } from "components/SiteForm/ExistingSite/CardFormatForm";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatSiteCodePositionField_cardFormat$key } from "./__generated__/CardFormatSiteCodePositionField_cardFormat.graphql";

export const cardFormatSiteCodePositionFieldId = (number: string) =>
  `card-format-site-code-position-${number}`;

function CardFormatSiteCodePositionField() {
  const [cardFormat, updateCardFormat] =
    useCardFormatFragment<CardFormatSiteCodePositionField_cardFormat$key>(
      graphql`
        fragment CardFormatSiteCodePositionField_cardFormat on CardFormat {
          siteCodePosition
          number
          type
        }
      `
    );

  const fieldId = cardFormatSiteCodePositionFieldId(cardFormat.number);
  const { siteCodePosition, type } = cardFormat;
  const { current: originalValue } = React.useRef(siteCodePosition);
  const disabled = type !== CardType.CUSTOM;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Site Code Position"
      tooltip="Position of the Site Code within the card format."
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        pattern={"([0-1]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])"}
        validationMessage="Valid values are 0-255."
        value={siteCodePosition ?? 1}
        inlineHelp={"0-255"}
        min={0}
        max={255}
        required
        onChange={({ target }) => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "siteCodePosition"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCardFormat((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(0, 255, valueAsNumber);
            recordProxy.setValue(value, "siteCodePosition");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatSiteCodePositionField;
