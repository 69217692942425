import styled from "styled-components/macro";
import Button from "./Button";

const StandardButton = styled(Button)`
  --bg-color: white;
  --border-color: var(--color-neutral-500);
  --text-color: var(--color-text-default);

  &:hover {
    --bg-color: var(--color-neutral-100);
  }
  &:active {
    --bg-color: var(--color-neutral-200);
  }
  &:not(:enabled) {
    --bg-color: var(--color-neutral-300);
    --border-color: var(--color-neutral-400);
    --text-color: var(--color-neutral-600);
  }
`;

export default StandardButton;
