import * as React from "react";
import { animated, useTransition, config } from "react-spring";

export default function FadeInOut(props: {
  visible: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) {
  const transitions = useTransition(props.visible, {
    from: { ...props.style, opacity: 0 },
    enter: { ...props.style, opacity: 1 },
    leave: { ...props.style, opacity: 0 },
    reverse: props.visible,
    config: config.stiff,
  });

  return transitions(
    (style, visible) =>
      visible && <animated.div style={style}>{props.children}</animated.div>
  );
}
