import React from "react";

const Badge: React.FC<
  React.PropsWithChildren<{
    stateColor?: "info" | "primary" | "success" | "warning" | "danger";
    message: string;
    fontSize?: string;
  }>
> = ({ stateColor, message, fontSize }) => {
  const colorClassList = () =>
    stateColor
      ? `badge-${stateColor} badge-${stateColor}--with-pulse`
      : `badge-info badge-info--with-pulse`;

  return (
    <span
      className={colorClassList()}
      style={{
        top: "0",
        fontSize: `1rem | ${fontSize}`,
        marginRight: ".8rem",
        width: "fit-content",
      }}
    >
      {message.toUpperCase()}
    </span>
  );
};

export default Badge;
