import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsServiceReceiverAuthorizationField_remoteOptions$key } from "./__generated__/RemoteOptionsServiceReceiverAuthorizationField_remoteOptions.graphql";

export const remoteOptionsServiceReceiverAuthorizationFieldId = () =>
  "remote-options-service-receiver-authorization";

function RemoteOptionsServiceReceiverAuthorizationField() {
  const [{ serviceReceiverAuthorization }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsServiceReceiverAuthorizationField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsServiceReceiverAuthorizationField_remoteOptions on RemoteOptions {
          ... on XtRemoteOptions {
            serviceReceiverAuthorization
          }
          ... on XrRemoteOptions {
            serviceReceiverAuthorization
          }
        }
      `
    );

  const fieldId = remoteOptionsServiceReceiverAuthorizationFieldId();
  const label = "Service Receiver Authorization";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enables remote programming and commands from a secondary service receiver."
    >
      <Switch
        id={fieldId}
        label={label}
        checked={!!serviceReceiverAuthorization}
        onChange={() => {
          updateRemoteOptions((recordProxy) =>
            recordProxy.setValue(
              !serviceReceiverAuthorization,
              "serviceReceiverAuthorization"
            )
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsServiceReceiverAuthorizationField;
