import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationExitDelayField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationExitDelayField_systemAreaInformation.graphql";

export const systemAreaInformationExitDelayFieldId = () =>
  `system-area-information-exit-delay-field`;

function SystemAreaInformationExitDelayField() {
  const [{ exitDelay }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationExitDelayField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationExitDelayField_systemAreaInformation on SystemAreaInformation {
          exitDelay
        }
      `
    );

  const fieldId = systemAreaInformationExitDelayFieldId();
  const { current: originalValue } = React.useRef(exitDelay);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Exit Delay"
      tooltip="During an arming sequence, the number of seconds a user has to exit this area before it is armed."
    >
      <NumericInput
        id={fieldId}
        required
        inlineHelp="1-250"
        pattern="(0{0,2}[1-9]|0?[1-9][0-9]|(1[0-9]|2[0-4])[0-9]|250)"
        validationMessage="Valid values are 1-250."
        value={exitDelay}
        min={1}
        max={250}
        onChange={({ target }) => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "exitDelay"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemAreaInformation((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(1, 250, valueAsNumber);
            recordProxy.setValue(value, "exitDelay");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationExitDelayField;
