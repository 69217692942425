/**
 * @ngdoc directive
 * @name App.directive:includeItemControl
 *
 * @description
 *   the contents of an include button that will display a checkmark or a circle/slash
 *   =fieldEnabled - the value of the target field's ng-disabled
 *
 */
App.directive("daIncludeItemControl", function () {
  return {
    restrict: "A",
    scope: {
      fieldEnabled: "=",
    },
    templateUrl: "app/common/templates/include-item-ctrl-tpl.html",
  };
});
