import {
  CameraStatus,
  CameraType,
  StreamableCamera,
} from "./streamable-camera";

export interface RawCamera {
  camect_auth_token: string;
  camect_hub_id: string;
  camera_id: string;
  camera_type: CameraType;
  env: string;
  last_checkin: string;
  name: string;
  status: string;
}

export const parseMonitoringCameras = (
  rawCameras: RawCamera[] | null
): StreamableCamera[] => {
  return (
    rawCameras?.map((c) => {
      return {
        authToken: c.camect_auth_token,
        cameraId: c.camera_id,
        cameraType: c.camera_type,
        env: c.env,
        hubId: c.camect_hub_id,
        lastCheckin: new Date(c.last_checkin),
        name: c.name,
        status:
          c.status === CameraStatus.Online
            ? CameraStatus.Online
            : CameraStatus.Offline,
      };
    }) || []
  );
};
