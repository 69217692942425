import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay$key } from "./__generated__/StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay.graphql";

export const statusListDisplayCarbonMonoxideZoneKeypadsFieldId = () =>
  "status-list-display-carbon-monoxide-zone-keypads";

function StatusListDisplayCarbonMonoxideZoneKeypadsField() {
  const [{ carbonMonoxideZoneKeypads }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay on StatusListDisplay {
          carbonMonoxideZoneKeypads
        }
      `
    );

  const fieldId = statusListDisplayCarbonMonoxideZoneKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Carbon Monoxide Zone Keypads"
      tooltip="Configure which keypad addresses show Carbon Monoxide Zone Alarms and Troubles."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={carbonMonoxideZoneKeypads}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "carbonMonoxideZoneKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "carbonMonoxideZoneKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplayCarbonMonoxideZoneKeypadsField;
