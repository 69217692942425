/**
 * @generated SignedSource<<7ede76cbff5cf2495649c6d076b95fe5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeyfobButtonType = "ARMING" | "DISARMING" | "EMERGENCY" | "EMERGENCY_TWO_BUTTON" | "OUTPUT" | "PANIC" | "PANIC_TWO_BUTTON" | "SENSOR_RESET" | "STATUS" | "TOGGLE_ARM_DISARM" | "UNUSED";
export type KeyfobNumberOfButtons = "FOUR" | "ONE" | "TWO" | "ZERO";
export type KeyfobOutputAction = "MOMENTARY" | "OFF" | "PULSE" | "STEADY" | "TOGGLE";
export type KeyfobPressTime = "LONG" | "SHORT";
export type KeyfobSupervisionTime = "NONE" | "_240_MIN" | "_60_MIN";
import { FragmentRefs } from "relay-runtime";
export type XTKeyfobProgrammingConceptForm_controlSystem$data = {
  readonly copiedKeyfob: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly keyfobs: ReadonlyArray<{
      readonly id: string;
      readonly isNew: boolean;
      readonly keyfobSerialNumber: string;
      readonly number: number;
      readonly " $fragmentSpreads": FragmentRefs<"KeyfobFields_keyfob">;
    }>;
    readonly keyfobsRange: ReadonlyArray<number> | null;
    readonly maxKeyfobs: number | null;
    readonly newKeyfob: {
      readonly buttonFourAction: KeyfobButtonType;
      readonly buttonFourAreas: string;
      readonly buttonFourOutput: string;
      readonly buttonFourOutputAction: KeyfobOutputAction;
      readonly buttonFourPressTime: KeyfobPressTime;
      readonly buttonOneAction: KeyfobButtonType;
      readonly buttonOneAreas: string;
      readonly buttonOneOutput: string;
      readonly buttonOneOutputAction: KeyfobOutputAction;
      readonly buttonOnePressTime: KeyfobPressTime;
      readonly buttonThreeAction: KeyfobButtonType;
      readonly buttonThreeAreas: string;
      readonly buttonThreeOutput: string;
      readonly buttonThreeOutputAction: KeyfobOutputAction;
      readonly buttonThreePressTime: KeyfobPressTime;
      readonly buttonTwoAction: KeyfobButtonType;
      readonly buttonTwoAreas: string;
      readonly buttonTwoOutput: string;
      readonly buttonTwoOutputAction: KeyfobOutputAction;
      readonly buttonTwoPressTime: KeyfobPressTime;
      readonly id: string;
      readonly isNew: boolean;
      readonly keyfobSerialNumber: string;
      readonly keyfobSupervisionTime: KeyfobSupervisionTime;
      readonly number: number;
      readonly numberOfButtons: KeyfobNumberOfButtons;
      readonly user: {
        readonly __typename: string;
        readonly id: string;
        readonly name: string;
        readonly number: number;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"KeyfobFields_keyfob">;
    } | null;
    readonly outputInformations: ReadonlyArray<{
      readonly id: string;
      readonly number: number;
    }>;
    readonly systemOptions: {
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextSystemType_systemOptions">;
    };
    readonly zoneInformations: ReadonlyArray<{
      readonly id: string;
      readonly number: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"KeyfobNumberField_panel" | "KeyfobSerialNumberField_SerialNumberList_panel" | "PanelContextUseHardwareModel_panel" | "PanelContextUseKeyfobNumberRange_panel" | "PanelContextUseSoftwareVersion_panel" | "PanelContextUseSystemOptionsSystemType_panel" | "PanelContextUseUserList_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem" | "ControlSystemContext_controlSystem">;
  readonly " $fragmentType": "XTKeyfobProgrammingConceptForm_controlSystem";
};
export type XTKeyfobProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XTKeyfobProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTKeyfobProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyfobSerialNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNew",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "KeyfobFields_keyfob"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XTKeyfobProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keyfobsRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxKeyfobs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Output",
          "kind": "LinkedField",
          "name": "outputInformations",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Zone",
          "kind": "LinkedField",
          "name": "zoneInformations",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Keyfob",
          "kind": "LinkedField",
          "name": "keyfobs",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Keyfob",
          "kind": "LinkedField",
          "name": "newKeyfob",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfButtons",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "keyfobSupervisionTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonOneAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonTwoAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonThreeAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonFourAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonOneOutputAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonTwoOutputAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonThreeOutputAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonFourOutputAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonOneOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonTwoOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonThreeOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonFourOutput",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonOnePressTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonTwoPressTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonThreePressTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonFourPressTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonOneAreas",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonTwoAreas",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonThreeAreas",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttonFourAreas",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSoftwareVersion_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseKeyfobNumberRange_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseUserList_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseSystemOptionsSystemType_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyfobSerialNumberField_SerialNumberList_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyfobNumberField_panel"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContext_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Keyfob",
          "kind": "LinkedField",
          "name": "copiedKeyfob",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "842ac5f61107ba44ee6b8433ac0f9bbf";

export default node;
