import graphql from "babel-plugin-relay/macro";
import { commitLocalUpdate } from "react-relay";
import { fromSiteId, toVideoChannelId } from "securecom-graphql/client";
/**
 * Created by kweigand on 9/28/15.
 */
App.controller("CameraCtrl", [
  "$rootScope",
  "$scope",
  "$q",
  "PROPS",
  "$http",
  "$state",
  "$stateParams",
  "$modal",
  "VideoDevice",
  "UserService",
  "Customer",
  "Camera",
  "VideoService",
  "VideoDeviceService",
  "ControlSystemsService",
  "ControlSystemService",
  "device_id",
  "VideoChannel",
  "VideoSchedule",
  "TIME_ZONES",
  "RelayService",
  "PanelCapabilitiesService",
  "SevenInchKeypadService",
  function (
    $rootScope,
    $scope,
    $q,
    PROPS,
    $http,
    $state,
    $stateParams,
    $modal,
    VideoDevice,
    UserService,
    Customer,
    Camera,
    VideoService,
    VideoDeviceService,
    ControlSystemsService,
    ControlSystemService,
    device_id,
    VideoChannel,
    VideoSchedule,
    TIME_ZONES,
    RelayService,
    PanelCapabilitiesService,
    SevenInchKeypadService
  ) {
    $scope.bool_type = "check"; // Used for displaying boolean values on the view page
    $scope.UserService = UserService;
    $scope.VideoDeviceService = VideoDeviceService;
    $scope.ControlSystemsService = ControlSystemsService;
    $scope.PanelCapabilitiesService = PanelCapabilitiesService;
    $scope.Panel = ControlSystemsService?.currentControlSystem?.panels?.[0];
    $scope.isBusy = false;
    $scope.videoDevice = null;
    $scope.timeZones = TIME_ZONES; // Get our time zone list from the constants file
    $scope.hasSevenInchKeypad = false;

    /**
     * @ngdoc object
     * @name method:getVideoDevice
     * @methodOf App.Controller:CameraCtrl
     *
     * @description
     * Gets the current video devices properties from the API
     */
    $scope.getVideoDevice = () => {
      $scope.isBusy = true;
      $scope.videoDevice = new VideoDevice();
      $scope.videoDevice
        .get(device_id)
        .then(
          function () {
            $scope.isBusy = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred while getting camera details.",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };
    // seven inch keypads are able to display video
    const getSevenInchKeypads = async (panel) => {
      $scope.hasSevenInchKeypad = false;
      SevenInchKeypadService.getKeypadsForControlSystem(panel.id).then(
        (keypads) => {
          if (keypads.length > 0) {
            $scope.hasSevenInchKeypad = true;
          }
        }
      );
    };

    /**
     * @description Updates the Relay Cache with the current SecureComCameras for a Site
     */
    const getRelayCameras = () => {
      RelayService.query(
        graphql`
          query cameraCtrlQuery($siteId: ID!) {
            site(id: $siteId) {
              id
              ...SecureComCameras_site
            }
          }
        `,
        { siteId: UserService.site.id }
      );
    };

    /**
     * @ngdoc object
     * @name method:save
     * @methodOf App.Controller:CameraCtrl
     *
     * @description
     * Save the current video device
     */
    $scope.save = function (timeZonePristine, dstPristine) {
      // Only send the time zone setting if the user has changed it
      var forceSendTZ = $stateParams.force_send_tz;
      var sendTimeZone = true;
      if (!forceSendTZ) {
        sendTimeZone = !timeZonePristine || !dstPristine;
      }
      $scope.isBusy = true;

      $scope.videoDevice
        .save(sendTimeZone)
        .then(
          function (data) {
            // Changes saved successfully
            $scope.isBusy = false;
            saveCameraSuccess();
          },
          function (error) {
            // Failed to save the camera
            $scope.isBusy = false;
            saveCameraFailed(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc object
     * @name method:saveCameraSuccess
     * @methodOf App.Controller:CameraCtrl
     *
     * @description
     * Notify the user that the camera has been saved and navigate back to control system view
     */
    function saveCameraSuccess() {
      if (!!UserService.site) getRelayCameras();
      $rootScope.alerts.push({ type: "success", text: "Camera Saved" });

      $scope.goBackToSystemOrSitePage();
    }

    $scope.goToCameraEditPage = () => {
      if (!!UserService.site) {
        $state.go("app.edit-camera-sites", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
          device_id: $scope.videoDevice.id,
          site_id: fromSiteId(UserService.site.id).siteId,
        });
      } else {
        $state.go("app.edit-camera", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
          device_id: $scope.videoDevice.id,
        });
      }
    };

    $scope.goBackToSystemOrSitePage = () => {
      if (!!UserService.site) {
        $state.go("app.sites.edit", {
          customer_id: UserService.customer_id,
          site_id: fromSiteId(UserService.site.id).siteId,
        });
      } else {
        $state.go("app.control_system", {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
        });
      }
    };

    $scope.reactivateVideoDevice = (videoDevice) => {
      videoDevice
        .reactivate()
        .then(
          function (data) {
            $rootScope.alerts.push({
              type: "success",
              text: "Reactivating " + videoDevice.device_type,
            });
            $scope.getVideoDevice();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc object
     * @name method:get
     * @methodOf App.Controller:CameraCtrl
     *
     * @description
     * Notify the user that an error occurred while saving the camera
     */
    function saveCameraFailed(error) {
      $rootScope.alerts.push({
        type: "error",
        text: "An error occurred while saving the camera.",
        json: error,
      });
    }

    /**
     * @ngdoc object
     * @name method:removeVideoDevice
     * @methodOf App.Controller:CameraCtrl
     *
     * @description
     * Attempts to deactivate and delete the current video device
     */
    $scope.removeVideoDevice = function () {
      var option = "delete";
      // Determine if we need to force delete the video device object
      if (VideoDeviceService.isDeleting($scope.videoDevice.id)) {
        option = "forceDelete";
      }
      VideoDeviceService[option]($scope.videoDevice.id)
        .then(
          function (data) {
            $scope.goBackToSystemOrSitePage();
            $rootScope.alerts.push({
              type: "success",
              text: "Removing Camera",
            });

            commitLocalUpdate(RelayService.getEnvironment(), (store) => {
              if (!UserService.site) return;
              const record = store.get(UserService.site.id);
              const cameras = record.getLinkedRecords("securecomCameras");
              const id = toVideoChannelId(
                "SecureComCamera",
                $scope.videoDevice.id,
                $scope.videoDevice.channels[0].id
              );
              if (cameras && cameras.length) {
                record.setLinkedRecords(
                  cameras.filter((camera) => camera.getDataID() !== id),
                  "securecomCameras"
                );
              }
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred while deleting the camera.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc object
     * @name method:updateClipSettings
     * @methodOf App.Controller:CameraCtrl
     *
     * @description
     * Verify and update clip settings when record on alarm is changed
     */
    $scope.updateClipSettings = function () {
      var roa = $scope.videoDevice.channels[0].record_on_alarm;
      var eventsEnabled = $scope.videoDevice.channels[0].events_enabled;

      if (roa === true) {
        // Clips cannot be scheduled when record on alarm is turned on.
        if (eventsEnabled == "scheduled") {
          $scope.videoDevice.channels[0].events_enabled = "off";
        }
      }
    };

    // Initialize the view
    $scope.getVideoDevice();
    if (!!UserService.site) getRelayCameras();
    if (
      $scope.Panel &&
      PanelCapabilitiesService.supportsSevenInchKeypad($scope.Panel)
    )
      getSevenInchKeypads($scope.Panel);

    $scope.openTestConnectionModal = function (size) {
      const TestConnectionModal = $modal.open({
        templateUrl: "connectionTestModal.html",
        size: size,
        backdrop: false,
        scope: $scope,
        controller: function ($scope, $modalInstance) {
          $scope.closeTestConnectionModal = function (e) {
            $modalInstance.dismiss();
          };
        },
      });
      TestConnectionModal.result.then($scope.videoDevice.testConnection());
    };
  },
]);
