import Portal from "common/components/web/Portal";
import noop from "common/utils/universal/noop";
import React, { useEffect, useRef } from "react";
import { joinSpaced } from "utils/string";

type Size = "small" | "medium" | "large" | "xlarge";

function sizeToBootstrap(size: Size) {
  switch (size) {
    case "small":
      return "sm";
    case "large":
      return "lg";
    case "xlarge":
      return "xl";
    case "medium":
    default:
      return "md";
  }
}

function ModalRoot({
  onClickOutside,
  children,
  contentRef,
  animate,
}: {
  onClickOutside: () => void;
  children: React.ReactNode;
  contentRef: React.RefObject<HTMLDivElement>;
  animate: boolean;
}) {
  const ref: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    function handleOnClickOutside(event: MouseEvent) {
      const { target } = event;

      if (
        ref.current &&
        contentRef.current &&
        target &&
        !contentRef.current.contains(target as Node)
      ) {
        onClickOutside();
        return;
      }
    }

    const current = ref.current;

    if (current) {
      current.addEventListener("click", handleOnClickOutside);
    }

    return () => {
      if (current) {
        current.removeEventListener("click", handleOnClickOutside);
      }
    };
  }, [contentRef, onClickOutside]);

  useEffect(() => {
    const current = ref.current;

    if (current) {
      current.classList.add("in");
    }

    return () => {
      if (current) {
        current.classList.remove("in");
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      className={joinSpaced("modal", animate ? "fade" : "")}
      style={{ zIndex: 1050, display: "block" }}
    >
      {children}
    </div>
  );
}

const ModalContent = React.forwardRef<
  HTMLDivElement,
  {
    size: Size;
    children: React.ReactNode;
    className?: string;
  }
>(function ModalContentInner({ size, children, className }, ref) {
  return (
    <div
      id={"modal-dialog"}
      ref={ref}
      className={joinSpaced(
        "modal-dialog",
        `modal-${sizeToBootstrap(size)}`,
        `${className}`
      )}
    >
      <div className="modal-content">{children}</div>
    </div>
  );
});

export default function Modal({
  size = "medium",
  backdrop = <Modal.Backdrop />,
  children,
  onClickOutside = noop,
  onTransitionEnd,
  animate = true,
  className,
}: {
  size?: Size;
  backdrop?: React.ReactNode;
  children: React.ReactNode;
  onClickOutside?: () => void;
  onTransitionEnd?: () => void;
  animate?: boolean;
  className?: string;
}) {
  const element: React.RefObject<HTMLDivElement> = useRef(
    document.createElement("div")
  );

  const contentRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    // In DA the children of the body are the content of the app
    const nodes = Array.from(document.querySelectorAll("body > *")).filter(
      (node) => node !== element.current
    );

    // Only do this if there are no other modals opened.
    if (document.querySelectorAll(".modal-dialog").length < 2) {
      document.body.classList.add("modal-open");
      nodes.forEach((node) => node.setAttribute("aria-hidden", "true"));
    }

    return () => {
      // Only do this if there are no other modals opened.
      if (!document.querySelector(".modal-dialog")) {
        document.body.classList.remove("modal-open");
        nodes.forEach((node) => node && node.removeAttribute("aria-hidden"));
      }
    };
  }, []);

  useEffect(() => {
    const contentNode = contentRef.current;
    if (animate && onTransitionEnd && contentNode) {
      contentNode.addEventListener("transitionend", onTransitionEnd);
      return () => {
        contentNode.removeEventListener("transitionend", onTransitionEnd);
      };
    } else if (!animate && onTransitionEnd && contentNode) {
      onTransitionEnd();
    }
  }, []);

  return (
    <Portal element={element.current}>
      <ModalRoot
        onClickOutside={onClickOutside}
        contentRef={contentRef}
        animate={animate}
      >
        <ModalContent ref={contentRef} size={size} className={className}>
          {children}
        </ModalContent>
      </ModalRoot>
      {backdrop}
    </Portal>
  );
}

/**
 * The visual only backdrop displayed behind the modal.
 * Note: this element has no behavior and does not acccept clicks.
 */
Modal.Backdrop = function ModalBackdrop(
  props: React.HTMLProps<HTMLDivElement>
) {
  const ref: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const current = ref.current;

    if (current) {
      current.classList.add("in");
    }

    return () => {
      if (current) {
        current.classList.remove("in");
      }
    };
  }, []);

  return (
    <div
      {...props}
      ref={ref}
      className="modal-backdrop fade"
      style={{ zIndex: 1040, width: "100%", border: 0 }}
    />
  );
};

Modal.Header = function ModalHeader({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={`modal-header bb0 ${className}`}>{children}</div>;
};

Modal.Body = function ModalBody({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={`modal-body ${className}`}>{children}</div>;
};

Modal.Footer = function ModalFooter({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={`modal-footer bt0 ${className}`}>{children}</div>;
};
