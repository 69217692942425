/**
 * @generated SignedSource<<18571816f7c7a0065aec853cf7eae264>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsSend16CharacterNamesField_systemOptions$data = {
  readonly send16CharacterNames?: boolean;
  readonly " $fragmentType": "SystemOptionsSend16CharacterNamesField_systemOptions";
};
export type SystemOptionsSend16CharacterNamesField_systemOptions$key = {
  readonly " $data"?: SystemOptionsSend16CharacterNamesField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsSend16CharacterNamesField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsSend16CharacterNamesField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "send16CharacterNames",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "2f2800c7c8951d4b4fd1d335e6d98b33";

export default node;
