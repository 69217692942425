/**
 * @generated SignedSource<<727c0deb586b35f54ad871d7f636d139>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SystemDiagnosticRootQuery$variables = {};
export type SystemDiagnosticRootQuery$data = {
  readonly systemDiagnostic: {
    readonly brownOutReset: {
      readonly amount: number;
    };
    readonly j16Reset: {
      readonly amount: number;
    };
    readonly lockupReset: {
      readonly amount: number;
    };
    readonly powerReset: {
      readonly amount: number;
    };
    readonly systemReset: {
      readonly amount: number;
    };
    readonly watchdogReset: {
      readonly amount: number;
    };
  };
};
export type SystemDiagnosticRootQuery = {
  response: SystemDiagnosticRootQuery$data;
  variables: SystemDiagnosticRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SystemDiagnosticRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemDiagnostic",
        "kind": "LinkedField",
        "name": "systemDiagnostic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "j16Reset",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "powerReset",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "brownOutReset",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "watchdogReset",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "lockupReset",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "systemReset",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SystemDiagnosticRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemDiagnostic",
        "kind": "LinkedField",
        "name": "systemDiagnostic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "j16Reset",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "powerReset",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "brownOutReset",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "watchdogReset",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "lockupReset",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PanelResetInformation",
            "kind": "LinkedField",
            "name": "systemReset",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af6dc7f0df32d449955167bb3881bfae",
    "id": null,
    "metadata": {},
    "name": "SystemDiagnosticRootQuery",
    "operationKind": "query",
    "text": "query SystemDiagnosticRootQuery {\n  systemDiagnostic {\n    j16Reset {\n      amount\n      id\n    }\n    powerReset {\n      amount\n      id\n    }\n    brownOutReset {\n      amount\n      id\n    }\n    watchdogReset {\n      amount\n      id\n    }\n    lockupReset {\n      amount\n      id\n    }\n    systemReset {\n      amount\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "809ca1a47d9d1cbc00e7552085cef6cd";

export default node;
