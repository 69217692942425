/**
 * @ngdoc directive
 * @name App.directive:utcdate
 * @function
 *
 * @description
 * Converts an ISO8601 date string model to a UTC DATE string view. It also converts the value
 * back onto the model.
 *
 * @example
 * <pre> 2000-01-02T09:00:00Z -> 01/02/2000 </pre>
 *
 */
App.directive("daUtcdate", function ($filter) {
  return {
    require: "?ngModel",
    scope: { ngModel: "=" },
    link: function (scope, elem, attrs, ctrl) {
      if (!ctrl) return;

      // View -> Model
      ctrl.$parsers.push(function (data) {
        if (new Date(elem.val()) == "Invalid Date") {
          ctrl.$setValidity("pattern", false);
          return "";
        } else {
          ctrl.$setValidity("pattern", true);
          return dateTimeStringToISOString(elem.val());
        }
      });
      // Model -> View
      ctrl.$formatters.push(function (data) {
        return $filter("date")(ctrl.$modelValue, "MM/dd/yyyy");
      });
    },
  };
});
