import graphql from "babel-plugin-relay/macro";
import { difference, indexBy, prop, update } from "ramda";
import * as React from "react";
import styled from "styled-components";

import Modal from "components/Modal";
import { ReadOnlyInput } from "../FormFields";

import Alert from "components/Alert";
import { SimpleGrid } from "components/DaStyledElements/SimpleGrid";
import {
  DeleteConfirmModalBody,
  DeleteConfirmModalOverlay,
} from "components/Modal/ConfirmModal";
import { useFragment, useMutation } from "react-relay/hooks";
import { useCreateNotification } from "../EntryPointContext";
import { InlineField } from "../FormFields/index";
import Spacer from "../Layout/Spacer";
import { SiteOutputModulesFormAddModuleMutation } from "./__generated__/SiteOutputModulesFormAddModuleMutation.graphql";
import { SiteOutputModulesFormExistingModule_siteOutputModule$key } from "./__generated__/SiteOutputModulesFormExistingModule_siteOutputModule.graphql";
import {
  SiteOutputModulesFormNewModule_site$data,
  SiteOutputModulesFormNewModule_site$key,
} from "./__generated__/SiteOutputModulesFormNewModule_site.graphql";
import { SiteOutputModulesFormRemoveMutation } from "./__generated__/SiteOutputModulesFormRemoveMutation.graphql";
import { SiteOutputModulesFormUpdateMutation } from "./__generated__/SiteOutputModulesFormUpdateMutation.graphql";

import { rangeInclusive } from "common/utils/universal/array";

import { SiteOutputModulesFormX1Selector_site$key } from "./__generated__/SiteOutputModulesFormX1Selector_site.graphql";

import { SiteOutputModulesForm_OutputModuleForm_siteControlSystem$key } from "./__generated__/SiteOutputModulesForm_OutputModuleForm_siteControlSystem.graphql";

// TODO: (after 213) - Switch to GQL for new and existing output modules
// Ideally tracked by the model or identifier of the output module
const OUTPUTS_PER_OUTPUT_MODULE = 10;

const Heading = styled.h2`
  font-size: var(--measure-font-14);
  font-weight: bold;
  margin-bottom: var(--measure-5x);
`;

const OutputHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "metadata actions";
  justify-content: space-between;
  align-items: center;
`;

const OutputHeaderMetadata = styled.div`
  display: grid;
  grid-area: metadata;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  row-gap: var(--measure-2x);

  @media (min-width: 500px) {
    grid-template-rows: auto;
    grid-template-columns: 200px 50px;
    column-gap: var(--measure-3x);
  }
`;

const OutputHeaderActions = styled.div`
  display: grid;
  grid-area: actions;
  row-gap: var(--measure-12);
  justify-content: end;

  @media (min-width: 768px) {
    column-gap: var(--measure-12);
    grid-template-columns: auto auto auto;
  }
`;

const X1Select = styled.select`
  max-width: 35em;
`;

export type OutputSiteCode = number | null;

interface X1SelectionFormElements extends HTMLFormControlsCollection {
  x1: HTMLSelectElement;
}
interface X1SelectionFormElement extends HTMLFormElement {
  readonly elements: X1SelectionFormElements;
}

export function X1SelectorForm({
  onSubmit,
  onCancel,
  fragmentRef,
}: {
  onSubmit: (event: React.FormEvent<X1SelectionFormElement>) => void;
  onCancel: () => void;
  fragmentRef: SiteOutputModulesFormX1Selector_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment SiteOutputModulesFormX1Selector_site on Site {
        x1DoorAccessControlSystems: controlSystems(include: DOOR_ACCESS) {
          id
          namedDoors: doors {
            name
          }
          serialNumber
          supportsOutputModules
          availableOutputModulesCount
          outputModulesConnection {
            totalCount
          }
        }
      }
    `,
    fragmentRef
  );

  const [selectedSystemId, setSelectedSystemId] = React.useState<string | null>(
    null
  );

  const selectedSystem = data.x1DoorAccessControlSystems.find(
    (system) => system.id === selectedSystemId
  );

  const selectRef = React.useRef<HTMLSelectElement>(null);

  React.useEffect(() => {
    const { current } = selectRef;

    if (current) {
      current.focus();
    }
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Modal.Header>
        <h3 className="mar-0">Add Outputs</h3>
        <p>Select the X1 the module is connected to.</p>
      </Modal.Header>
      <Modal.Body>
        <InlineField fieldStyle="centered" style={{ maxWidth: 400 }}>
          <InlineField.Label htmlFor="x1">X1</InlineField.Label>
          <InlineField.Input>
            <X1Select
              className="form-control"
              id="x1"
              name="x1"
              required
              value={selectedSystemId ?? ""}
              autoFocus
              ref={selectRef}
              onChange={({ currentTarget }) => {
                setSelectedSystemId(currentTarget.value);
              }}
            >
              <option value="" disabled>
                Select an X1
              </option>
              {data.x1DoorAccessControlSystems.map((system) => (
                <option
                  key={system.id}
                  value={system.id}
                  disabled={system.availableOutputModulesCount === 0}
                >
                  {system.namedDoors?.[0]?.name ??
                    `Unnamed #${system.serialNumber}`}{" "}
                  ({system.availableOutputModulesCount} available)
                </option>
              ))}
            </X1Select>
          </InlineField.Input>
        </InlineField>
        {selectedSystem && !selectedSystem.supportsOutputModules && (
          <>
            <Spacer size="3x" />
            <Alert type="warning">
              A firmware update is required to add outputs to this door. Please
              update the door to the latest firmware to proceed.
            </Alert>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default btn-sm"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-info btn-sm"
          disabled={!selectedSystem || !selectedSystem.supportsOutputModules}
        >
          Add
        </button>
      </Modal.Footer>
    </form>
  );
}

export function NewOutputModuleModal({
  site,
  onCancel,
  onSaved,
}: {
  site: SiteOutputModulesFormNewModule_site$key;
  onCancel: () => void;
  onSaved: () => void;
}) {
  const data = useFragment(
    graphql`
      fragment SiteOutputModulesFormNewModule_site on Site {
        doorAccessControlSystems: controlSystems(include: DOOR_ACCESS) {
          id
          maxOutputModules
          outputModulesConnection {
            nodes {
              address
            }
          }
          ...SiteOutputModulesForm_OutputModuleForm_siteControlSystem
        }
        ...SiteOutputModulesFormX1Selector_site
      }
    `,
    site
  );

  const systemsById = React.useMemo(
    () => indexBy(prop("id"), data.doorAccessControlSystems),
    [data.doorAccessControlSystems]
  );

  const [selectedX1, setSelectedX1] = React.useState<
    | null
    | SiteOutputModulesFormNewModule_site$data["doorAccessControlSystems"][number]
  >(null);

  const address = React.useMemo(
    () =>
      difference(
        rangeInclusive(1, selectedX1?.maxOutputModules ?? 0),
        selectedX1?.outputModulesConnection.nodes.map((node) => node.address) ??
          []
      )[0],
    [selectedX1]
  );

  return (
    <Modal>
      {!selectedX1 ? (
        <X1SelectorForm
          fragmentRef={data}
          onCancel={onCancel}
          onSubmit={(event: React.FormEvent<X1SelectionFormElement>) => {
            event.preventDefault();
            setSelectedX1(
              systemsById[event.currentTarget.elements.x1.value] ?? null
            );
          }}
        />
      ) : (
        <OutputModuleForm
          id="new"
          address={address}
          onCancel={onCancel}
          onSaved={onSaved}
          initialState={{
            name: "",
            outputs: new Array(OUTPUTS_PER_OUTPUT_MODULE).fill(""),
          }}
          siteControlSystem={selectedX1}
        />
      )}
    </Modal>
  );
}

export function ExistingOutputModuleModal({
  siteOutputModule,
  onSaved,
  onDeleted,
  onCancel,
}: {
  siteOutputModule: SiteOutputModulesFormExistingModule_siteOutputModule$key;
  onSaved: () => void;
  onDeleted: () => void;
  onCancel: () => void;
}) {
  const data = useFragment(
    graphql`
      fragment SiteOutputModulesFormExistingModule_siteOutputModule on SiteOutputModule {
        id
        name
        address
        controlSystem {
          doors {
            name
          }
          ...SiteOutputModulesForm_OutputModuleForm_siteControlSystem
        }
        outputsConnection {
          nodes {
            name
            relayNumber
          }
        }
      }
    `,
    siteOutputModule
  );

  return (
    <Modal>
      <OutputModuleForm
        id={data.id}
        address={data.address}
        siteControlSystem={data.controlSystem!}
        onCancel={onCancel}
        onSaved={onSaved}
        onDeleted={onDeleted}
        initialState={{
          name: data.name,
          outputs: data.outputsConnection.nodes.reduce(
            (acc, node) =>
              node.relayNumber
                ? update(node.relayNumber - 1, node.name, acc)
                : acc,
            new Array(OUTPUTS_PER_OUTPUT_MODULE).fill("")
          ),
        }}
      />
    </Modal>
  );
}

export type OutputFormValues = {
  name: string;
  outputs: string[];
};

type OutputFormState = {
  formValues: OutputFormValues;
  error: React.ReactNode;
  overlay: "NONE" | "DELETE_CONFIRMATION";
};

const outputFormReducer = (
  state: OutputFormState,
  action:
    | { type: "NAME_CHANGED"; payload: string }
    | { type: "OUTPUT_CHANGED"; payload: { index: number; value: string } }
    | { type: "ERROR_OCCURRED"; error: React.ReactNode }
    | { type: "DELETE_REQUESTED" }
    | { type: "DELETE_CANCELLED" }
): OutputFormState => {
  switch (action.type) {
    case "NAME_CHANGED":
      return {
        ...state,
        formValues: { ...state.formValues, name: action.payload },
      };
    case "OUTPUT_CHANGED":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          outputs: update(
            action.payload.index,
            action.payload.value,
            state.formValues.outputs
          ),
        },
      };
    case "DELETE_REQUESTED":
      return {
        ...state,
        overlay: "DELETE_CONFIRMATION",
      };
    case "DELETE_CANCELLED":
      return {
        ...state,
        overlay: "NONE",
      };
    case "ERROR_OCCURRED":
      return {
        ...state,
        error: action.error,
        overlay: "NONE",
      };
    default:
      return state;
  }
};

function OutputModuleForm({
  id,
  address,
  onCancel,
  onSaved,
  onDeleted,
  initialState,
  siteControlSystem,
}: {
  id: string;
  address: number;
  siteControlSystem: SiteOutputModulesForm_OutputModuleForm_siteControlSystem$key;
  onCancel: () => void;
  onSaved: () => void;
  onDeleted?: () => void;
  initialState: {
    name: string;
    outputs: string[];
  };
}) {
  const isNew = id === "new";

  const data = useFragment(
    graphql`
      fragment SiteOutputModulesForm_OutputModuleForm_siteControlSystem on SiteControlSystem {
        id
        serialNumber
        maxOutputModules
        namedDoors: doors {
          name
        }
        outputModulesConnection {
          nodes {
            id
            address
          }
        }
      }
    `,
    siteControlSystem
  );

  const [state, dispatch] = React.useReducer(outputFormReducer, {
    error: null,
    formValues: initialState,
    overlay: "NONE",
  });

  const createNotification = useCreateNotification();

  const [createOutputModule, isCreatingOutputModule] =
    useMutation<SiteOutputModulesFormAddModuleMutation>(
      graphql`
        mutation SiteOutputModulesFormAddModuleMutation(
          $controlSystemId: ID!
          $address: Int!
          $name: String!
          $outputs: [AddSiteOutputInput!]!
        ) {
          addSiteControlSystemOutputModule(
            controlSystemId: $controlSystemId
            address: $address
            name: $name
            outputs: $outputs
          ) {
            __typename
            ... on AddSiteControlSystemOutputModuleSuccessResponse {
              controlSystem {
                site {
                  ...SiteOutputModulesSection_site
                }
              }
            }
            ... on AddSiteControlSystemOutputModuleFailureResponse {
              error {
                __typename
              }
            }
          }
        }
      `
    );

  const [updateOutputModule, isUpdatingOutputModule] =
    useMutation<SiteOutputModulesFormUpdateMutation>(
      graphql`
        mutation SiteOutputModulesFormUpdateMutation(
          $outputModule: ID!
          $name: String!
          $outputs: [UpdateSiteOutputInput!]!
        ) {
          updateSiteControlSystemOutputModule(
            outputModule: $outputModule
            name: $name
            outputs: $outputs
          ) {
            ... on UpdateSiteControlSystemOutputModuleSuccessResponse {
              __typename
              outputModule {
                ...SiteOutputModulesFormExistingModule_siteOutputModule
              }
            }
            ... on UpdateSiteControlSystemOutputModuleFailureResponse {
              error {
                __typename
              }
            }
          }
        }
      `
    );

  const [deleteOutputModule, isDeletingOutputModule] =
    useMutation<SiteOutputModulesFormRemoveMutation>(
      graphql`
        mutation SiteOutputModulesFormRemoveMutation($outputModule: ID!) {
          removeSiteControlSystemOutputModule(outputModule: $outputModule) {
            ... on RemoveSiteControlSystemOutputModuleSuccessResponse {
              __typename
              controlSystem {
                site {
                  ...SiteOutputModulesSection_site
                }
              }
            }
            ... on RemoveSiteControlSystemOutputModuleFailureResponse {
              error {
                __typename
              }
            }
          }
        }
      `
    );

  const disabled =
    isDeletingOutputModule || isUpdatingOutputModule || isCreatingOutputModule;

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          if (disabled) {
            return;
          }

          if (isNew) {
            createOutputModule({
              variables: {
                controlSystemId: data.id,
                name: state.formValues.name,
                outputs: state.formValues.outputs
                  .map((output, index) => ({
                    name: output,
                    relayNumber: index + 1,
                  }))
                  .filter((output) => output.name !== ""),
                address,
              },
              onError: () => {
                dispatch({
                  type: "ERROR_OCCURRED",
                  error: "Failed to add outputs.",
                });
              },
              onCompleted({ addSiteControlSystemOutputModule }) {
                if (!("error" in addSiteControlSystemOutputModule)) {
                  onSaved();
                  createNotification({
                    type: "success",
                    text: "Outputs added.",
                  });
                } else {
                  dispatch({
                    type: "ERROR_OCCURRED",
                    error: "Failed to add outputs.",
                  });
                }
              },
            });
          } else {
            if (isUpdatingOutputModule) {
              return;
            }

            const outputs = state.formValues.outputs.reduce(
              (acc, value, index) => {
                const relayNumber = index + 1;

                const persistedName = initialState.outputs[index];
                const hasChanged = persistedName !== value;
                const isDeleted = persistedName !== "" && value === "";

                if (hasChanged) {
                  return [
                    ...acc,
                    isDeleted
                      ? { relayNumber, delete: true }
                      : { relayNumber, name: value },
                  ];
                }

                return acc;
              },
              new Array<
                | { relayNumber: number; name: string }
                | { relayNumber: number; delete: boolean }
              >()
            );

            updateOutputModule({
              variables: {
                outputModule: id,
                name: state.formValues.name,
                outputs,
              },
              onError: () => {
                dispatch({
                  type: "ERROR_OCCURRED",
                  error: "Failed to update outputs.",
                });
              },
              onCompleted({ updateSiteControlSystemOutputModule }) {
                if ("outputModule" in updateSiteControlSystemOutputModule) {
                  onSaved();
                  createNotification({
                    type: "success",
                    text: "Outputs updated.",
                  });
                } else {
                  dispatch({
                    type: "ERROR_OCCURRED",
                    error: "Failed to update outputs.",
                  });
                }
              },
            });
          }
        }}
      >
        <fieldset disabled={disabled}>
          <Modal.Header>
            <OutputHeaderGrid>
              <OutputHeaderMetadata>
                <div>
                  <label htmlFor="x1-serial-number">Connected To</label>
                  <ReadOnlyInput
                    id="x1-serial-number"
                    value={
                      data.namedDoors?.[0]?.name ??
                      `Unnamed #${data.serialNumber}`
                    }
                  />
                </div>
                <div>
                  <label htmlFor="next-module-address">Address</label>
                  <ReadOnlyInput id="next-module-address" value={address} />
                </div>
              </OutputHeaderMetadata>
              <OutputHeaderActions>
                <button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-info btn-sm">
                  Save
                </button>
                {!isNew && (
                  <button
                    type="button"
                    onClick={() => dispatch({ type: "DELETE_REQUESTED" })}
                    className="btn btn-danger btn-sm"
                  >
                    Delete
                  </button>
                )}
              </OutputHeaderActions>
            </OutputHeaderGrid>
          </Modal.Header>
          <Modal.Body>
            {state.error && (
              <>
                <Spacer />
                <Alert type="error">{state.error}</Alert>
              </>
            )}

            <>
              <Heading>
                {state.formValues.name ? state.formValues.name : <>&nbsp;</>}
              </Heading>
              <SimpleGrid columns={2}>
                <InlineField>
                  <InlineField.Label htmlFor="outputName">
                    Name
                  </InlineField.Label>
                  <InlineField.Input>
                    <input
                      id="outputName"
                      name="outputName"
                      className="form-control"
                      maxLength={32}
                      type="text"
                      required
                      value={state.formValues.name}
                      onChange={(event) => {
                        dispatch({
                          type: "NAME_CHANGED",
                          payload: event.currentTarget.value,
                        });
                      }}
                      autoFocus={isNew}
                    />
                  </InlineField.Input>
                </InlineField>
              </SimpleGrid>
              <Spacer size="5x" />
              <SimpleGrid
                columns={2}
                rows={Math.floor(OUTPUTS_PER_OUTPUT_MODULE / 2)}
              >
                {rangeInclusive(1, OUTPUTS_PER_OUTPUT_MODULE).map(
                  (number, index) => {
                    const label = `relayNumber${number}`;

                    return (
                      <InlineField key={number}>
                        <InlineField.Label htmlFor={label}>
                          Relay {number}
                        </InlineField.Label>
                        <InlineField.Input>
                          <input
                            id={label}
                            name={label}
                            type="text"
                            className="form-control"
                            value={state.formValues.outputs[index]}
                            onChange={({ currentTarget }) =>
                              dispatch({
                                type: "OUTPUT_CHANGED",
                                payload: {
                                  index,
                                  value: currentTarget.value,
                                },
                              })
                            }
                            maxLength={32}
                          />
                        </InlineField.Input>
                      </InlineField>
                    );
                  }
                )}
              </SimpleGrid>
              <Spacer size="5x" />
            </>
          </Modal.Body>
        </fieldset>
      </form>
      {state.overlay === "DELETE_CONFIRMATION" && (
        <DeleteConfirmModalOverlay>
          <DeleteConfirmModalBody
            actionPending={isDeletingOutputModule}
            onConfirm={() => {
              if (!isDeletingOutputModule) {
                deleteOutputModule({
                  variables: {
                    outputModule: id,
                  },
                  onError: () => {
                    dispatch({
                      type: "ERROR_OCCURRED",
                      error: "Failed to delete outputs.",
                    });
                  },
                  onCompleted({ removeSiteControlSystemOutputModule }) {
                    if (
                      "controlSystem" in removeSiteControlSystemOutputModule
                    ) {
                      if (onDeleted) {
                        onDeleted();
                      }

                      createNotification({
                        type: "success",
                        text: "Outputs deleted.",
                      });
                    } else {
                      dispatch({
                        type: "ERROR_OCCURRED",
                        error: "Failed to delete outputs.",
                      });
                    }
                  },
                });
              }
            }}
            onCancel={() => {
              dispatch({ type: "DELETE_CANCELLED" });
            }}
          >
            Are you sure you want to delete this output?
          </DeleteConfirmModalBody>
        </DeleteConfirmModalOverlay>
      )}
    </>
  );
}
