App.controller("DmpHealthReportCtrl", [
  "$scope",
  "$modal",
  "$window",
  "BusinessAnalyticsService",
  "ModemUpgradeStatsService",
  "$rootScope",
  "UserService",
  function (
    $scope,
    $modal,
    $window,
    BusinessAnalyticsService,
    ModemUpgradeStatsService,
    $rootScope,
    UserService
  ) {
    // today - variable to hold today's date
    var today = new Date();
    // months - variable to hold array of months
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    //  variable to hold the name of the last month
    var lastMonth =
      months[today.getMonth()] !== "January"
        ? months[today.getMonth() - 1]
        : "December";
    //$scope.viewWidth -- change graph to table in mobile view
    $scope.viewWidth = $window.innerWidth;

    $scope.modemUpgradeStats = {
      CustomersEnabledCount: null,
      CustomersWhoScheduledCount: null,
    };

    let getModemUpgradeStats = () => {
      ModemUpgradeStatsService.getModemUpgradeStats().then(
        function (data) {
          $scope.modemUpgradeStats.CustomersEnabledCount =
            data.CustomersEnabledCount;
          $scope.modemUpgradeStats.CustomersWhoScheduledCount =
            data.CustomersWhoScheduledCount;
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error retrieving modem upgrade stats",
            json: error,
          });
          $scope.data.modemUpgradeStats.retrieved = false;
        }
      );
    };

    //VARIABLES FOR USE ACROSS ALL CHARTS
    // chartStyle - used on style declaration for every google chart
    $scope.chartStyle =
      'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif!important; ' +
      "min-height: 475px; " +
      "height:auto; " +
      "width:100%; " +
      "max-height:100%;";
    var universalChartStyle = $scope.chartStyle;
    // universalChartValueColors - used on style declaration for every google chart
    var universalChartValueColors = [
      "#5091AB",
      "#A0CDDE",
      "#25627A",
      "#A0CDDE",
      "#639DB4",
      "#76A9BD",
      "#89B5C6",
      "#9CC1CF",
    ];
    // universalChartAnimation - transition animation used for every google chart that is not YTD
    var universalChartAnimation = {
      startup: "true",
      duration: 500,
      easing: "out",
    };
    // lineChartAnimation - transition animation used for every YTD google chart
    var lineChartAnimation = {
      startup: "true",
      duration: 200,
      easing: "in",
    };
    // tableCssClasses - unique table classes for overview charts (this month, last month)
    var tableCssClasses = {
      headerRow: "chart__header-row",
      tableRow: "chart__table-row",
      oddTableRow: "chart__oddTableRow",
      selectedTableRow: "chart__selectedTableRow",
      hoverTableRow: "chart__hoverTableRow",
      headerCell: "chart__headerCell",
      tableCell: "chart__tableCell",
      rowNumberCell: "chart__rowNumberCell",
    };
    // overviewTableCssClasses - table classes for  overview charts (this month, last month)
    var overviewTableCssClasses = {
      headerRow: "chart__header-row",
      tableRow: "chart__table-row",
      oddTableRow: "chart__oddTableRow",
      selectedTableRow: "chart__selectedTableRow--overview",
      hoverTableRow: "chart__hoverTableRow--overview",
      headerCell: "chart__headerCell--overview",
      tableCell: "chart__tableCell--overview",
      rowNumberCell: "chart__rowNumberCell",
    };
    // ytdTableCssClasses - table classes for  ytd charts
    var ytdTableCssClasses = {
      headerRow: "chart__header-row",
      tableRow: "chart__table-row",
      oddTableRow: "chart__oddTableRow",
      selectedTableRow: "chart__selectedTableRow",
      hoverTableRow: "chart__hoverTableRow",
      headerCell: "chart__headerCell--ytd",
      tableCell: "chart__tableCell--ytd",
      rowNumberCell: "chart__rowNumberCell",
    };
    // chartOptions - chart options used for most default view google charts
    var chartOptions = {
      title: "",
      width: "100%",
      height: "100%",
      padding: 20,
      fill: 20,
      displayExactValues: true,
      allowHtml: true,
      cssClassNames: tableCssClasses,
      displayAnnotations: true,
      colors: universalChartValueColors,
      animation: universalChartAnimation,
      vAxis: {
        title: "",
        format: "#,###",
        minValue: 1,
        maxValue: 4,
      },
      hAxis: {
        title: "",
        minValue: 1,
        maxValue: 4,
      },
      annotations: {
        textStyle: {
          fontSize: 12,
        },
      },
    };
    // chartOptions - chart options used for acct overview chart
    var acctOverviewChartOptions = {
      width: "100%",
      height: "100%",
      padding: 20,
      fill: 20,
      displayExactValues: true,
      allowHtml: true,
      cssClassNames: overviewTableCssClasses,
      displayAnnotations: true,
      colors: universalChartValueColors,
      animation: universalChartAnimation,
      vAxis: {
        title: "",
        format: "#,###",
        minValue: 0,
        maxValue: 4,
      },
      hAxis: {
        title: "",
      },
      annotations: {
        textStyle: {
          fontSize: 12,
        },
      },
    };
    //NOT WORKING YET -- SOMETHING TO WORK with In the future
    var chartTypeBasedOnView = function (viewPortWidth) {
      if (viewPortWidth > 500) {
        return "Table";
      } else {
        return "ColumnChart";
      }
    };
    /**
     *  @function - toggleDataView
     * @param chart - the chart to change viewState
     * @param chartType - the current viewState of the chart
     * @param chartUse - set variable that specifies how the chart is used and its view when it's not a table
     * @param-description chartUse values -  [overview-bar, overview-column, ytd-combo]
     */
    $scope.toggleDataView = function (chart, chartType, chartUse) {
      switch (chartUse) {
        case "ytd-combo":
          switch (chart.family) {
            case "cellular":
              $scope.changeCellReport("default", chart);
              break;
            case "vk":
              $scope.changeVkReport("default", chart);
              break;
            case "system":
              $scope.changeSystemReport("default", chart);
              break;
            case "customers":
              $scope.changeCustomersReport("default", chart);
              break;
            case "access-ctrl":
              $scope.changeAccessControlReport("default", chart);
              break;
            case "acct-overview":
              $scope.changeAcctOverviewReport("default", chart);
              break;
          }

          break;
        case "overview-bar":
          showBarOrTable(chart, chartType);
          break;
        case "overview-table":
          if (chart.family == "acct-overview") {
            showColumnOrTable(chart, chartType);
          } else {
            showBarOrTable(chart, chartType);
          }
          break;
        case "overview-column":
          showColumnOrTable(chart, chartType);
          break;
      }
    };
    /**
     * @function - showBarOrTable
     * @description toggles the state of a google chart between a BarChart and a Table
     * @param chart - chart the chart to change viewState
     * @param viewState - the current chart type
     */
    var showBarOrTable = function (chart, viewState) {
      if (viewState === "BarChart") {
        viewState = "Table";
        switch (chart.id) {
          case "accessControlChart":
            showSpecificColumnsForAccessControlTable(chart);
            break;
          case "systemsChart":
            showSpecificColumnsForSystemTable(chart);
            break;
          case "acctOverviewChart":
            showSpecificColumnsForAcctOverviewTable(chart);
            break;
        }
      } else {
        viewState = "BarChart";
        switch (chart.id) {
          case "accessControlChart":
            showSpecificColumnsForAccessControlGraph(chart);
            break;
          case "systemsChart":
            showSpecificColumnsForSystemTable(chart);
            break;
          case "acctOverviewChart":
            showSpecificColumnsForAcctOverviewGraph(chart);
            break;
        }
      }
      chart.type = viewState;
    };
    /**
     * @function - showColumnOrTable
     * @description - toggles the state of a google chart between a ColumnChart and a Table
     * @param chart - chart the chart to change viewState
     * @param viewState - the current chart type
     */
    var showColumnOrTable = function (chart, viewState) {
      if (viewState === "ColumnChart") {
        viewState = "Table";
        switch (chart.id) {
          case "virtualKeypadChart":
            addLoginForVKGraph(chart);
            showSpecificColumnsForVKTable(chart);
            break;
          case "systemsChart":
            showSpecificColumnsForSystemTable(chart);
            break;
          case "acctOverviewChart":
            showSpecificColumnsForAcctOverviewTable(chart);
            break;
        }
      } else {
        viewState = "ColumnChart";
        switch (chart.id) {
          case "virtualKeypadChart":
            removeLoginForVKGraph(chart);
            showSpecificColumnsForVKGraph(chart);
            break;
          case "systemsChart":
            showSpecificColumnsForSystemGraph(chart);
            break;
          case "acctOverviewChart":
            showSpecificColumnsForAcctOverviewGraph(chart);
            break;
        }
      }
      chart.type = viewState;
    };

    var showSpecificColumnsForAcctOverviewGraph = function (chart) {
      $scope.currentAcctOverviewChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };

    var showSpecificColumnsForAcctOverviewTable = function (chart) {
      $scope.currentAcctOverviewChart.view = {
        columns: [0, 1, 3, 5],
      };
    };

    var showSpecificColumnsForVKGraph = function (chart) {
      $scope.currentVkChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };

    var removeLoginForVKGraph = function (chart) {
      // Remove the Login Rate from the graph, if it's still there.
      if ($scope.currentVkChart.data.rows[8].c[0].v.startsWith("Login Rate")) {
        $scope.vkChartWithLoginData = angular.copy($scope.currentVkChart);
        $scope.currentVkChart.data.rows.splice(8, 1);
      }
    };

    var addLoginForVKGraph = function (chart) {
      // Put back the Login Rate data, if the file exists
      if ($scope.vkChartWithLoginData)
        $scope.currentVkChart = angular.copy($scope.vkChartWithLoginData);
    };

    var showSpecificColumnsForVKTable = function (chart) {
      $scope.currentVkChart.view = {
        columns: [0, 1, 2, 3, 4, 5],
      };
    };

    var showSpecificColumnsForSystemGraph = function (chart) {
      $scope.currentSystemsChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };

    var showSpecificColumnsForAccessControlGraph = function (chart) {
      $scope.currentAccessControlChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };

    var showSpecificColumnsForAccessControlTable = function (chart) {
      $scope.currentAccessControlChart.view = {
        columns: [0, 1, 2, 3, 4, 5],
      };
    };

    var showSpecificColumnsForSystemTable = function (chart) {
      $scope.currentSystemsChart.view = {
        columns: [0, 1, 2, 3, 4, 5],
      };
    };

    //ACCOUNT OVERVIEW CHART
    $scope.changeAcctOverviewReport = function (pressed, chart) {
      switch (pressed) {
        case "customers":
          $scope.currentAcctOverviewChart =
            $scope.acctOverviewCustomersYTDChart;
          $scope.acctOverviewYtdBtnTitle = "New Customers";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1],
          };
          break;
        case "systems":
          $scope.currentAcctOverviewChart = $scope.acctOverviewSystemsYTDChart;
          $scope.acctOverviewYtdBtnTitle = "Systems";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1],
          };
          break;
        case "systemsWithApp":
          $scope.currentAcctOverviewChart =
            $scope.acctOverviewSystemsWithAppYTDChart;
          $scope.acctOverviewYtdBtnTitle = "Systems w/ App";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1],
          };
          break;
        case "systemsWithCell":
          $scope.currentAcctOverviewChart =
            $scope.acctOverviewSystemsWithCellYTDChart;
          $scope.acctOverviewYtdBtnTitle = "Systems w/ Cell";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1],
          };
          break;
        case "cellularDevices":
          $scope.currentAcctOverviewChart =
            $scope.acctOverviewCellularDevicesYTDChart;
          $scope.acctOverviewYtdBtnTitle = "Cellular Devices";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1],
          };
          break;
        case "cameras":
          $scope.currentAcctOverviewChart = $scope.acctOverviewCamerasYTDChart;
          $scope.acctOverviewYtdBtnTitle = "Cameras";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1],
          };
          break;
        case "terminations":
          $scope.currentAcctOverviewChart =
            $scope.acctOverviewTerminationsYTDChart;
          $scope.acctOverviewYtdBtnTitle = "Terminations";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1],
          };
          break;
        default:
          $scope.currentAcctOverviewChart = $scope.acctOverviewChart;
          $scope.acctOverviewYtdBtnTitle = "Overview";
          $scope.currentAcctOverviewChart.type = "Table";
          $scope.currentAcctOverviewChart.view = {
            columns: [0, 1, 3, 5],
          };
          break;
      }
    };
    $scope.changeAcctOverviewGraph = function (pressed, chart) {
      $scope.currentAcctOverviewChart = $scope.acctOverviewChart;
      $scope.acctOverviewYtdBtnTitle = "Overview";
      $scope.currentAcctOverviewChart.type = "ColumnChart";
      $scope.currentAcctOverviewChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };
    $scope.acctOverviewYtdBtnTitle = "Overview";
    $scope.acctOverviewChart = {
      id: "acctOverviewChart",
      type: "Table",
      chartUse: "overview-table",
      family: "acct-overview",
      displayed: false,
      view: {
        columns: [0, 1, 3, 5],
      },
      data: {},
      options: acctOverviewChartOptions,
    };
    $scope.currentAcctOverviewChart = $scope.acctOverviewChart;
    //ACCOUNT OVERVIEW CHARTs -- YTD (COMBOCHART)
    $scope.acctOverviewCustomersYTDChart = {
      id: "acctOverviewCustomersYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "acct-overview",
      curveType: "function",
      title: "Customers YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Customers YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Customers",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.acctOverviewSystemsYTDChart = {
      id: "acctOverviewSystemsYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "acct-overview",
      curveType: "function",
      title: "Customers YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Sytems YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Systems YTD",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.acctOverviewSystemsWithAppYTDChart = {
      id: "acctOverviewSystemsWithAppYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "acct-overview",
      curveType: "function",
      title: "Systems w/ App YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Systems w/ App YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Systems w/ App YTD",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.acctOverviewSystemsWithCellYTDChart = {
      id: "acctOverviewSystemsWithCellYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "acct-overview",
      curveType: "function",
      title: "Systems w/ Cell YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Systems w/ Cell YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Systems w/ Cell YTD",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.acctOverviewCellularDevicesYTDChart = {
      id: "acctOverviewCellularDevicesYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "acct-overview",
      curveType: "function",
      title: "Cellular Devices YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Cellular Devices (LTE) YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Cellular Devices (LTE) YTD",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.acctOverviewCamerasYTDChart = {
      id: "acctOverviewCamerasYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "acct-overview",
      curveType: "function",
      title: "Cameras YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Cameras YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Cameras YTD",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.acctOverviewTerminationsYTDChart = {
      id: "acctOverviewTerminationsYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "acct-overview",
      curveType: "function",
      title: "Terminations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Terminations YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Terminations YTD",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };

    //CELLULAR CHARTS (BARCHART, COMBOCHART, TABLE)
    $scope.cellularYtdBtnTitle = "Overview";
    $scope.changeCellReport = function (pressed, chart) {
      switch (pressed) {
        case "activations":
          $scope.currentCellChart = $scope.activationsYTDChart;
          $scope.cellularYtdBtnTitle = "Total Activations";
          break;
        case "verizon":
          $scope.currentCellChart = $scope.verizonYTDChart;
          $scope.cellularYtdBtnTitle = "Verizon";
          break;
        case "att":
          $scope.currentCellChart = $scope.attYTDChart;
          $scope.cellularYtdBtnTitle = "AT&T";
          break;
        default:
          $scope.currentCellChart = $scope.cellularChart;
          $scope.cellularYtdBtnTitle = "Overview";
          $scope.currentCellChart.type = "Table";
          break;
      }
    };
    $scope.changeCellReportOverviewGraph = function (pressed, chart) {
      $scope.currentCellChart = $scope.cellularChart;
      $scope.cellularYtdBtnTitle = "Overview";
      $scope.currentCellChart.type = "BarChart";
    };

    $scope.cellularChart = {
      id: "cellularChart",
      type: "BarChart",
      chartUse: "overview-bar",
      family: "cellular",
      title: "Overview",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: chartOptions,
    };
    $scope.currentCellChart = $scope.cellularChart;
    //CELLULAR CHARTS -- YTD (COMBOCHART)
    $scope.activationsYTDChart = {
      id: "activationsYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "cellular",
      curveType: "function",
      title: "Total Activations",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Total Activations YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Total Activations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Month",
          gridlines: { count: 12 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.verizonYTDChart = {
      id: "verizonYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "cellular",
      curveType: "function",
      title: "Verizon",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Verizon Activations YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Activations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Month",
          gridlines: { count: 12 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.attYTDChart = {
      id: "attYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "cellular",
      curveType: "function",
      title: "AT&T Activations",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "AT&T Activations YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Activations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Month",
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };

    //VIRTUAL KEYPAD CHARTS (COLUMNCHART, COMBOCHART, TABLE)
    $scope.vkYtdBtnTitle = "Overview";
    var percentFormatters = {
      numberFormat: [
        {
          pattern: "#%",
          fractionDigits: 2,
        },
      ],
    };
    $scope.changeVkReport = function (pressed) {
      switch (pressed) {
        case "armOnly":
          $scope.currentVkChart = $scope.armOnlyYTDChart;
          $scope.vkYtdBtnTitle = "Arm Only App";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };

          break;
        case "fullApp":
          $scope.currentVkChart = $scope.fullAppYTDChart;
          $scope.vkYtdBtnTitle = "Standard App";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "cameras":
          $scope.currentVkChart = $scope.camerasYTDChart;
          $scope.vkYtdBtnTitle = "Cameras";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "zwaveLights":
          $scope.currentVkChart = $scope.zwaveLightsYTDChart;
          $scope.vkYtdBtnTitle = "Z-Wave Lights";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "zwaveLocks":
          $scope.currentVkChart = $scope.zwaveLocksYTDChart;
          $scope.vkYtdBtnTitle = "Z-Wave Locks";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "zwaveThermostats":
          $scope.currentVkChart = $scope.zwaveThermostatsYTDChart;
          $scope.vkYtdBtnTitle = "Z-Wave Thermostats";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "geofence":
          $scope.currentVkChart = $scope.geofenceYTDChart;
          $scope.vkYtdBtnTitle = "Geofence";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "trafficCount":
          $scope.currentVkChart = $scope.trafficCountYTDChart;
          $scope.vkYtdBtnTitle = "Traffic Count";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "login":
          $scope.currentVkChart = $scope.loginYTDChart;
          $scope.vkYtdBtnTitle = "Login Rate";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "doorbell":
          $scope.currentVkChart = $scope.doorbellYTDChart;
          $scope.vkYtdBtnTitle = "Video Doorbells";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "nvr":
          $scope.currentVkChart = $scope.nvrYTDChart;
          $scope.vkYtdBtnTitle = "Hikvision NVRs";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "openEye":
          $scope.currentVkChart = $scope.openEyeYTDChart;
          $scope.vkYtdBtnTitle = "OpenEye";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        case "videoVerification":
          $scope.currentVkChart = $scope.videoVerificationYTDChart;
          $scope.vkYtdBtnTitle = "Video Verification";
          $scope.currentVkChart.view = {
            columns: [0, 1],
          };
          break;
        default:
          $scope.currentVkChart = $scope.virtualKeypadChart;
          addLoginForVKGraph();
          $scope.vkYtdBtnTitle = "Overview";
          $scope.currentVkChart.type = "Table";
          $scope.currentVkChart.view = {
            columns: [0, 1, 2, 3, 4, 5],
          };
          break;
      }
    };
    $scope.changeVkReportOverviewGraph = function (pressed, chart) {
      $scope.currentVkChart = $scope.virtualKeypadChart;
      removeLoginForVKGraph(chart);
      $scope.vkYtdBtnTitle = "Overview";
      $scope.currentVkChart.type = "ColumnChart";
      $scope.currentVkChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };
    $scope.virtualKeypadChart = {
      id: "virtualKeypadChart",
      type: "Table",
      chartUse: "overview-column",
      family: "vk",
      title: "Virtual Keypad Chart",
      displayed: false,
      data: {},
      options: chartOptions,
    };

    //VIRTUAL KEYPAD CHARTS -- YTD (COMBOCHART)
    $scope.currentVkChart = $scope.virtualKeypadChart;
    $scope.armOnlyYTDChart = {
      id: "armOnlyYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Arm Only(YTD)",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Arm Only App Activations YTD",
        legend: "none",
        //'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Arm Only App Activations",
          format: "#,###",
          minValue: "0",
          maxValue: "4",
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.fullAppYTDChart = {
      id: "fullAppYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Standard App Activations",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Standard App Activations YTD",
        //   'smoothLine': true,
        legend: "none",
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Standard App Activations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.camerasYTDChart = {
      id: "camerasYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Cameras",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Cameras YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Cameras",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.zwaveLightsYTDChart = {
      id: "zwaveLightsYTDChart",
      type: "ComboChart",
      curveType: "function",
      chartUse: "ytd-combo",
      family: "vk",
      title: "Z-Wave Lights YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Z-Wave Lights YTD",
        legend: "none",
        //    'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Z-Wave Lights",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.zwaveLocksYTDChart = {
      id: "zwaveLocksYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Z-Wave Locks YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "Z-Wave Locks YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Z-Wave Locks",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.zwaveThermostatsYTDChart = {
      id: "zwaveThermostatsYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Z-Wave Thermostats YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Z-Wave Thermostats YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Z-Wave Thermostats",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.geofenceYTDChart = {
      id: "geofenceYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Geofence YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Geofence YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Geofence Activations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.trafficCountYTDChart = {
      id: "trafficCountYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Traffic Count Activations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Traffic Count Activations YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Traffic Count Activations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.loginYTDChart = {
      id: "loginYTDChart",
      type: "AreaChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Login Rate YTD (%)",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Login Rate YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Login Rate %",
          minValue: 0,
          maxValue: 100,
          format: "#'%'",
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 12 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },

        tooltip: {
          formatters: percentFormatters,
        },
      },
    };
    $scope.doorbellYTDChart = {
      id: "doorbellYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Video Doorbells YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Video Doorbells YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Video Doorbells",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.nvrYTDChart = {
      id: "nvrYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "NVR YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Hikvision NVRs YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Hikvision NVRs",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.openEyeYTDChart = {
      id: "openEyeYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "OpenEye Integrations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "OpenEye Integrations YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,

        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "OpenEye Integrations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.videoVerificationYTDChart = {
      id: "videoVerificationYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "vk",
      curveType: "function",
      title: "Video Verification YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},

      options: {
        title: "Video Verification YTD",
        legend: "none",
        //'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Video Verification",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };

    //SYSTEMS CHARTS (COLUMNCHART, COMBOCHART, TABLE)
    //TODO: Geter - do not show systems with null count
    $scope.systemsYtdBtnTitle = "Overview";
    $scope.changeSystemReport = function (pressed) {
      switch (pressed.toLowerCase()) {
        case "totalsystems":
          $scope.currentSystemsChart = $scope.totalSystemsYTDChart;
          $scope.systemsYtdBtnTitle = "All Systems";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "totalwithapp":
          $scope.currentSystemsChart = $scope.totalWithAppYTDChart;
          $scope.systemsYtdBtnTitle = "Systems w/ App";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "systemswithcell":
          $scope.currentSystemsChart = $scope.systemsWithCellYTDChart;
          $scope.systemsYtdBtnTitle = "Systems w/ Cell";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "xtltouch":
          $scope.currentSystemsChart = $scope.xtlTouchYTDChart;
          $scope.systemsYtdBtnTitle = "XTLtouch";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "xtlplus":
          $scope.currentSystemsChart = $scope.xtlPlusYTDChart;
          $scope.systemsYtdBtnTitle = "XTLplus";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "xt30":
          $scope.currentSystemsChart = $scope.xt30YTDChart;
          $scope.systemsYtdBtnTitle = "XT30";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "xt50":
          $scope.currentSystemsChart = $scope.xt50YTDChart;
          $scope.systemsYtdBtnTitle = "XT50";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "xr150":
          $scope.currentSystemsChart = $scope.xr150YTDChart;
          $scope.systemsYtdBtnTitle = "XR150";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "xr550":
          $scope.currentSystemsChart = $scope.xr550YTDChart;
          $scope.systemsYtdBtnTitle = "XR550";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "cellcom":
          $scope.currentSystemsChart = $scope.cellComYTDChart;
          $scope.systemsYtdBtnTitle = "CellCom";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "icom":
          $scope.currentSystemsChart = $scope.iComYTDChart;
          $scope.systemsYtdBtnTitle = "iCom";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        case "dualcom":
          $scope.currentSystemsChart = $scope.dualComYTDChart;
          $scope.systemsYtdBtnTitle = "DualCom";
          $scope.currentSystemsChart.view = {
            columns: [0, 1],
          };
          break;
        default:
          $scope.currentSystemsChart = $scope.systemsChart;
          $scope.systemsYtdBtnTitle = "Overview";
          $scope.currentSystemsChart.type = "Table";
          $scope.currentSystemsChart.view = {
            columns: [0, 1, 2, 3, 4, 5],
          };
          break;
      }
    };

    $scope.changeSystemReportOverviewGraph = function (pressed, chart) {
      $scope.currentSystemsChart = $scope.systemsChart;
      $scope.systemsYtdBtnTitle = "Overview";
      $scope.currentSystemsChart.type = "ColumnChart";
      $scope.currentSystemsChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };

    $scope.systemsChart = {
      id: "systemsChart",
      type: "ColumnChart",
      chartUse: "overview-column",
      family: "system",
      displayed: false,
      title: "Systems Overview",
      cssStyle: universalChartStyle,
      data: {},
      options: chartOptions,
      view: {
        columns: [0, 1, 2, 3, 4],
      },
    };

    $scope.currentSystemsChart = $scope.systemsChart;

    //SYSTEMS CHARTS - YTD (COMBOCHART)
    $scope.totalSystemsYTDChart = {
      id: "totalSystemsYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "All Systems YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "All Systems YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "All Systems",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 12 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.totalWithAppYTDChart = {
      id: "totalWithAppYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "Systems w/ App",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "Systems w/ App YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Systems w/ App",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.systemsWithCellYTDChart = {
      id: "systemsWithCellYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "Systems w/ Cell YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "Systems w/ Cell YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Systems w/ Cell",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.xtlTouchYTDChart = {
      id: "xtlTouchYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "XTLtouch Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "XTLtouch Installations YTD",
        legend: "none",
        //    'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "XTLtouch Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.xtlPlusYTDChart = {
      id: "xtlPlusYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "XTLplus Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "XTLplus Installations YTD",
        legend: "none",
        //    'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "XTLplus Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.xt30YTDChart = {
      id: "xt30YTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "XT30 Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "XT30 Installations YTD",
        legend: "none",
        //      'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "XT30 Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.xt50YTDChart = {
      id: "xt50YTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "XT50 Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "XT50 Installations YTD",
        legend: "none",
        //    'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "XT50 Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.xr150YTDChart = {
      id: "xr150YTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "XR150 Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "XR150 Installations YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "XR150 Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.xr550YTDChart = {
      id: "xr550YTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "XR550 Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "XR550 Installations YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "XR550 Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.cellComYTDChart = {
      id: "cellComYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "CellCom Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "CellCom Installations YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "CellCom Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.iComYTDChart = {
      id: "iComYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "iCom Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "iCom Installations YTD",
        legend: "none",
        //   'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "iCom Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.dualComYTDChart = {
      id: "dualComYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "system",
      curveType: "function",
      title: "DualCom Installations YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "DualCom Installations YTD",
        legend: "none",
        //  'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "DualCom Installations",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };

    //NEW CUSTOMERS CHART (BARCHART, COMBOCHART, TABLE)
    $scope.customersYtdBtnTitle = "Overview";
    $scope.changeCustomersReport = function (pressed) {
      switch (pressed) {
        case "totalNewCustomers":
          $scope.currentCustomerChart = $scope.customersYTDChart;
          $scope.customersYtdBtnTitle = "New Customers";
          $scope.currentCustomerChart.view = {
            columns: [0, 1],
          };
          break;
        default:
          $scope.currentCustomerChart = $scope.customersChart;
          $scope.customersYtdBtnTitle = "Overview";
          $scope.currentCustomerChart.type = "Table";
          $scope.currentCustomerChart.view = {
            columns: [0, 1, 2, 3, 4],
          };
          break;
      }
    };
    $scope.changeCustomersReportOverviewGraph = function (pressed, chart) {
      $scope.currentCustomerChart = $scope.customersChart;
      $scope.customersYtdBtnTitle = "Overview";
      $scope.currentCustomerChart.type = "BarChart";
      $scope.currentCustomerChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };
    $scope.customersChart = {
      id: "customersChart",
      type: "BarChart",
      chartUse: "overview-bar",
      family: "customers",
      title: "Customer Overview",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "",
        width: "100%",
        height: "25%",
        padding: 20,
        fill: 20,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: tableCssClasses,
        vAxis: {
          title: "",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "",
        },
        bar: {
          groupWidth: 95,
        },
        colors: universalChartValueColors,
        animation: universalChartAnimation,
      },
    };
    $scope.currentCustomerChart = $scope.customersChart;
    //NEW CUSTOMERS CHART -- YTD
    $scope.customersYTDChart = {
      id: "customersYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "customers",
      curveType: "function",
      title: "New Customers YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "New Customers YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "New Customers",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };

    //ACCESS CONTROL CHART (BARCHART, COMBOCHART, TABLE)
    $scope.accessControlYtdBtnTitle = "Overview";
    $scope.changeAccessControlReport = function (pressed) {
      switch (pressed) {
        case "advancedReportsYTDChart":
          $scope.currentAccessControlChart = $scope.advancedReportsYTDChart;
          $scope.accessControlYtdBtnTitle = "Advanced Reports";
          $scope.currentAccessControlChart.view = {
            columns: [0, 1],
          };
          break;
        case "doorsOfAccessYTDChart":
          $scope.currentAccessControlChart = $scope.doorsOfAccessYTDChart;
          $scope.accessControlYtdBtnTitle = "Doors of Access";
          $scope.currentAccessControlChart.view = {
            columns: [0, 1],
          };
          break;
        default:
          $scope.currentAccessControlChart = $scope.accessControlChart;
          $scope.accessControlYtdBtnTitle = "Overview";
          $scope.currentAccessControlChart.type = "Table";
          $scope.currentAccessControlChart.view = {
            columns: [0, 1, 2, 3, 4, 5],
          };
      }
    };
    $scope.changeAccessControlReportOverviewGraph = function (pressed, chart) {
      $scope.currentAccessControlChart = $scope.accessControlChart;
      $scope.accessControlYtdBtnTitle = "Overview";
      $scope.currentAccessControlChart.type = "BarChart";
      $scope.currentAccessControlChart.view = {
        columns: [0, 1, 2, 3, 4],
      };
    };
    $scope.accessControlChart = {
      id: "accessControlChart",
      type: "Table",
      chartUse: "overview-bar",
      family: "access-ctrl",
      title: "Access Control Overview",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "",
        width: "100%",
        height: "150%",
        padding: 80,
        fill: 20,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: tableCssClasses,
        vAxis: {
          title: "",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "",
        },
        colors: universalChartValueColors,
        animation: universalChartAnimation,
      },
    };
    $scope.currentAccessControlChart = $scope.accessControlChart;
    //ACCESS CONTROL CHART -- YTD
    $scope.advancedReportsYTDChart = {
      id: "advancedReportsYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "access-ctrl",
      curveType: "function",
      title: "Advanced Reports YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "Advanced Reports YTD",
        legend: "none",
        //    'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Advanced Reports",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };
    $scope.doorsOfAccessYTDChart = {
      id: "doorsOfAccessYTDChart",
      type: "ComboChart",
      chartUse: "ytd-combo",
      family: "access-ctrl",
      curveType: "function",
      title: "Doors of Access YTD",
      displayed: false,
      cssStyle: universalChartStyle,
      data: {},
      options: {
        title: "Doors of Access YTD",
        legend: "none",
        // 'smoothLine': true,
        width: "100%",
        height: "100%",
        padding: 20,
        fill: 20,
        pointsVisible: true,
        displayExactValues: true,
        allowHtml: true,
        cssClassNames: ytdTableCssClasses,
        displayAnnotations: true,
        colors: universalChartValueColors,
        animation: lineChartAnimation,
        vAxis: {
          title: "Doors of Access",
          format: "#,###",
          minValue: 0,
          maxValue: 4,
        },
        hAxis: {
          title: "Months",
          gridlines: { count: 10 },
        },
        annotations: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
    };

    //SCHEDULE A REPORT (MODAL)
    $scope.openSchedulingModal = function (size) {
      var schedulingModal = $modal.open({
        templateUrl:
          "app/dealer-dashboard/business-analytics-schedule-modal-tpl.html",
        windowClass: "",
        size: size ? size : "lg",
        backdrop: true,
        controller: "BusinessAnalyticsScheduleModalCtrl",
        resolve: {
          report: {
            type: "dealerAnalytics",
            id: null,
          },
        },
      });
      schedulingModal.result
        .then(
          function (reason) {},
          function (reason) {}
        )
        .catch(function (error) {
          console.error(
            "Error getting schedulingModal result: " + angular.toJson(error)
          );
        });
    };

    $scope.acctOverviewSelectHandler = function (selectedItem) {
      var rowArrayLocation = selectedItem.row;
      if (selectedItem.row !== null) {
        var value =
          $scope.currentAcctOverviewChart.data.rows[rowArrayLocation].c[0].v;
        switch (value) {
          case "Customers":
            $scope.changeAcctOverviewReport("customers");
            break;
          case "Systems":
            $scope.changeAcctOverviewReport("systems");
            break;
          case "Systems w/ Apps":
            $scope.changeAcctOverviewReport("systemsWithApp");
            break;
          case "Systems w/ Cell":
            $scope.changeAcctOverviewReport("systemsWithCell");
            break;
          case "Cellular Devices (LTE)":
            $scope.changeAcctOverviewReport("cellularDevices");
            break;
          case "Cameras":
            $scope.changeAcctOverviewReport("cameras");
            break;
          case "Terminations":
            $scope.changeAcctOverviewReport("terminations");
            break;
          default:
            $scope.changeAcctOverviewReport("default");
            break;
        }
      } else {
      }
    };

    $scope.cellSelectHandler = function (selectedItem) {
      var rowArrayLocation = selectedItem.row;
      if (selectedItem.row !== null) {
        var value = $scope.currentCellChart.data.rows[rowArrayLocation].c[0].v;
        switch (value) {
          case "Total Activations":
            $scope.changeCellReport("activations");
            break;
          case "Verizon":
            $scope.changeCellReport("verizon");
            break;
          case "AT&T":
            $scope.changeCellReport("att");
            break;
          default:
            changeCellReport("default");
            break;
        }
      } else {
      }
    };

    $scope.vkSelectHandler = function (selectedItem) {
      var rowArrayLocation = selectedItem.row;
      if (selectedItem.row !== null) {
        var value = $scope.currentVkChart.data.rows[rowArrayLocation].c[0].v;
        switch (value) {
          case "Arm Only App":
            $scope.changeVkReport("armOnly");
            break;
          case "Standard App":
            $scope.changeVkReport("fullApp");
            break;
          case "Cameras":
            $scope.changeVkReport("cameras");
            break;
          case "Z-Wave Lights":
            $scope.changeVkReport("zwaveLights");
            break;
          case "Z-Wave Locks":
            $scope.changeVkReport("zwaveLocks");
            break;
          case "Z-Wave Thermostats":
            $scope.changeVkReport("zwaveThermostats");
            break;
          case "Geofencing":
            $scope.changeVkReport("geofence");
            break;
          case "Traffic Count":
            $scope.changeVkReport("trafficCount");
            break;
          case "Login Rate (%)":
            $scope.changeVkReport("login");
            break;
          case "Video Doorbells":
            $scope.changeVkReport("doorbell");
            break;
          case "Hikvision NVRs":
            $scope.changeVkReport("nvr");
            break;
          case "OpenEye":
            $scope.changeVkReport("openEye");
            break;
          case "Video Verification":
            $scope.changeVkReport("videoVerification");
            break;
          default:
            $scope.changeVkReport("default");
            break;
        }
      } else {
      }
    };

    $scope.systemSelectHandler = function (selectedItem) {
      var rowArrayLocation = selectedItem.row;
      if (selectedItem.row !== null) {
        var value =
          $scope.currentSystemsChart.data.rows[rowArrayLocation].c[0].v;
        switch (value) {
          case "All Systems":
            $scope.changeSystemReport("totalSystems");
            break;
          case "Systems w/ App":
            $scope.changeSystemReport("totalWithApp");
            break;
          case "Systems w/ Cell":
            $scope.changeSystemReport("systemsWithCell");
            break;
          case "XTLtouch":
            $scope.changeSystemReport("xtlTouch");
            break;
          case "XTLplus":
            $scope.changeSystemReport("xtlPlus");
            break;
          case "XT30":
            $scope.changeSystemReport("xt30");
            break;
          case "XT50":
            $scope.changeSystemReport("xt50");
            break;
          case "XR150":
            $scope.changeSystemReport("xr150");
            break;
          case "XR550":
            $scope.changeSystemReport("xr550");
            break;
          case "CellCom":
            $scope.changeSystemReport("cellCom");
            break;
          case "iCom":
            $scope.changeSystemReport("iCom");
            break;
          case "DualCom":
            $scope.changeSystemReport("dualCom");
            break;
          default:
            $scope.changeSystemReport("default");
            break;
        }
      } else {
      }
    };
    $scope.customerSelectHandler = function (selectedItem) {
      var rowArrayLocation = selectedItem.row;
      if (selectedItem.row !== null) {
        var value =
          $scope.currentCustomerChart.data.rows[rowArrayLocation].c[0].v;
        switch (value) {
          case "New Customers":
            $scope.changeCustomersReport("totalNewCustomers");
            break;
          default:
            $scope.changeCustomersReport("default");
            break;
        }
      }
    };

    $scope.accessControlSelectHandler = function (selectedItem) {
      var rowArrayLocation = selectedItem.row;
      if (selectedItem.row !== null) {
        var value =
          $scope.currentAccessControlChart.data.rows[rowArrayLocation].c[0].v;
        switch (value) {
          case "Advanced Reports":
            $scope.changeAccessControlReport("advancedReportsYTDChart");
            break;
          case "Doors of Access":
            $scope.changeAccessControlReport("doorsOfAccessYTDChart");
            break;
          default:
            $scope.changeAccessControlReport("default");
            break;
        }
      }
    };

    function loadAccountOverviewData() {
      BusinessAnalyticsService.getAccountOverviewData(true).then(
        function (data) {
          console.debug("Got Account Overview data: " + angular.toJson(data));
          var acctOverviewTotal = data.find(function (o) {
            return o.year === 0 && o.month === 0;
          });
          var acctOverviewYearToDate = data.find(function (o) {
            return o.year === today.getFullYear() && o.month === 0;
          });
          var acctOverviewThisMonth = data.find(function (o) {
            return (
              o.year === today.getFullYear() && o.month === today.getMonth() + 1
            );
          });
          if (
            acctOverviewTotal &&
            acctOverviewYearToDate &&
            acctOverviewThisMonth
          ) {
            $scope.acctOverviewChart.data = {
              cols: [
                { id: "items", label: "", type: "string", sort: "disable" },
                { id: "this-month", label: "This Month", type: "number" },
                {
                  id: "month-current-anno",
                  type: "number",
                  role: "annotation",
                },
                { id: "year-to-date", label: "YTD", type: "number" },
                { id: "year-to-date-anno", type: "number", role: "annotation" },
                { id: "total", label: "Total", type: "number" },
                { id: "total-anno", type: "number", role: "annotation" },
              ],
              rows: [
                {
                  c: [
                    { v: "Customers" },
                    { v: acctOverviewThisMonth.customers },
                    { v: acctOverviewThisMonth.customers },
                    { v: acctOverviewYearToDate.customers },
                    { v: acctOverviewYearToDate.customers },
                    { v: acctOverviewTotal.customers },
                    { v: acctOverviewTotal.customers },
                  ],
                },
                {
                  c: [
                    { v: "Systems" },
                    { v: acctOverviewThisMonth.systems },
                    { v: acctOverviewThisMonth.systems },
                    { v: acctOverviewYearToDate.systems },
                    { v: acctOverviewYearToDate.systems },
                    { v: acctOverviewTotal.systems },
                    { v: acctOverviewTotal.systems },
                  ],
                },
                {
                  c: [
                    { v: "Systems w/ Apps" },
                    { v: acctOverviewThisMonth.systems_with_apps },
                    { v: acctOverviewThisMonth.systems_with_apps },
                    { v: acctOverviewYearToDate.systems_with_apps },
                    { v: acctOverviewYearToDate.systems_with_apps },
                    { v: acctOverviewTotal.systems_with_apps },
                    { v: acctOverviewTotal.systems_with_apps },
                  ],
                },
                {
                  c: [
                    { v: "Systems w/ Cell" },
                    { v: acctOverviewThisMonth.systems_with_cell },
                    { v: acctOverviewThisMonth.systems_with_cell },
                    { v: acctOverviewYearToDate.systems_with_cell },
                    { v: acctOverviewYearToDate.systems_with_cell },
                    { v: acctOverviewTotal.systems_with_cell },
                    { v: acctOverviewTotal.systems_with_cell },
                  ],
                },
                {
                  c: [
                    { v: "Cellular Devices (LTE)" },
                    { v: acctOverviewThisMonth.cellular_devices },
                    { v: acctOverviewThisMonth.cellular_devices },
                    { v: acctOverviewYearToDate.cellular_devices },
                    { v: acctOverviewYearToDate.cellular_devices },
                    { v: acctOverviewTotal.cellular_devices },
                    { v: acctOverviewTotal.cellular_devices },
                  ],
                },
                {
                  c: [
                    { v: "Cameras" },
                    { v: acctOverviewThisMonth.cameras },
                    { v: acctOverviewThisMonth.cameras },
                    { v: acctOverviewYearToDate.cameras },
                    { v: acctOverviewYearToDate.cameras },
                    { v: acctOverviewTotal.cameras },
                    { v: acctOverviewTotal.cameras },
                  ],
                },
                {
                  c: [
                    { v: "Terminations" },
                    { v: acctOverviewThisMonth.systems_deleted },
                    { v: acctOverviewThisMonth.systems_deleted },
                    { v: acctOverviewYearToDate.systems_deleted },
                    { v: acctOverviewYearToDate.systems_deleted },
                    { v: acctOverviewTotal.systems_deleted },
                    { v: acctOverviewTotal.systems_deleted },
                  ],
                },
              ],
            };
          } else {
            console.warn(
              "BusinessHealthReportCtrl->loadAccountOverviewData() - falsy data - acctOverviewThisMonth: " +
                angular.toJson(acctOverviewThisMonth) +
                " | acctOverviewYearToDate: " +
                angular.toJson(acctOverviewYearToDate) +
                " | acctOverviewTotal: " +
                angular.toJson(acctOverviewTotal)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error getting account overview data",
            });
          }
          // If it's January
          var acctOverviewLastMonth =
            today.getMonth() === 0
              ? // Get December of last year
                data.find(function (o) {
                  return o.year === today.getFullYear() - 1 && o.month === 12;
                })
              : // Otherwise, get last month
                data.find(function (o) {
                  return (
                    o.year === today.getFullYear() &&
                    o.month === today.getMonth()
                  );
                });
          if (acctOverviewThisMonth && acctOverviewLastMonth) {
            $scope.customersChart.data = {
              cols: [
                { id: "customers", label: "", type: "string" },
                { id: "month-current", label: "This Month", type: "number" },
                {
                  id: "month-current-anno",
                  type: "number",
                  role: "annotation",
                },
                { id: "month-last", label: lastMonth, type: "number" },
                { id: "month-last-anno", type: "number", role: "annotation" },
              ],
              rows: [
                {
                  c: [
                    { v: "New Customers" },
                    { v: acctOverviewThisMonth.customers },
                    { v: acctOverviewThisMonth.customers },
                    { v: acctOverviewLastMonth.customers },
                    { v: acctOverviewLastMonth.customers },
                  ],
                },
              ],
            };
          } else {
            console.warn(
              "BusinessHealthReportCtrl->loadAccountOverviewData() - falsy data - acctOverviewThisMonth: " +
                angular.toJson(acctOverviewThisMonth) +
                " | acctOverviewLastMonth: " +
                angular.toJson(acctOverviewLastMonth)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error getting customers data",
            });
          }
          var acctOverviewYtdMonths = data.filter(function (o) {
            return o.year === today.getFullYear() && o.month !== 0;
          });
          var acctOverviewCustomersYtdRows = [];
          var acctOverviewSystemsYtdRows = [];
          var acctOverviewSystemsWithAppYtdRows = [];
          var acctOverviewSystemsWithCellYtdRows = [];
          var acctOverviewCellularDevicesYtdRows = [];
          var acctOverviewCamerasYtdRows = [];
          var acctOverviewTerminationsYtdRows = [];
          angular.forEach(acctOverviewYtdMonths, function (month) {
            acctOverviewCustomersYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.customers },
              ],
            });
            acctOverviewSystemsYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.systems },
              ],
            });
            acctOverviewSystemsWithAppYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.systems_with_apps },
              ],
            });
            acctOverviewSystemsWithCellYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.systems_with_cell },
              ],
            });
            acctOverviewCellularDevicesYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.cellular_devices },
              ],
            });
            acctOverviewCamerasYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.customers },
              ],
            });
            acctOverviewTerminationsYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.systems_deleted },
              ],
            });
          });
          $scope.acctOverviewCustomersYTDChart.data =
            $scope.customersYTDChart.data = {
              cols: [
                { id: "month", label: "Months", type: "string" },
                { id: "items", label: "New Customers", type: "number" },
              ],
              rows: acctOverviewCustomersYtdRows,
            };
          $scope.acctOverviewSystemsYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Systems", type: "number" },
            ],
            rows: acctOverviewSystemsYtdRows,
          };
          $scope.acctOverviewSystemsWithAppYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Systems w/ App", type: "number" },
            ],
            rows: acctOverviewSystemsWithAppYtdRows,
          };
          $scope.acctOverviewSystemsWithCellYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Systems w/ Cell", type: "number" },
            ],
            rows: acctOverviewSystemsWithCellYtdRows,
          };
          $scope.acctOverviewCellularDevicesYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Cellular Devices", type: "number" },
            ],
            rows: acctOverviewCellularDevicesYtdRows,
          };
          $scope.acctOverviewCamerasYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Cameras", type: "number" },
            ],
            rows: acctOverviewCamerasYtdRows,
          };
          $scope.acctOverviewTerminationsYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Terminations", type: "number" },
            ],
            rows: acctOverviewTerminationsYtdRows,
          };
        },
        function () {
          $rootScope.alerts.push({
            type: "error",
            text: "error getting account overview data",
          });
        }
      );
    }

    function loadCellularData() {
      BusinessAnalyticsService.getCellularData(true).then(
        function (data) {
          console.debug("Got Cellular data: " + angular.toJson(data));
          var cellularThisMonth = data.find(function (o) {
            return (
              o.year === today.getFullYear() && o.month === today.getMonth() + 1
            );
          });
          // If it's January
          var cellularLastMonth =
            today.getMonth() === 0
              ? // Get December of last year
                data.find(function (o) {
                  return o.year === today.getFullYear() - 1 && o.month === 12;
                })
              : // Otherwise, get last month
                data.find(function (o) {
                  return (
                    o.year === today.getFullYear() &&
                    o.month === today.getMonth()
                  );
                });
          if (cellularThisMonth && cellularLastMonth) {
            $scope.cellularChart.data = {
              cols: [
                { id: "service", label: "Service", type: "string" },
                { id: "month-current", label: "This Month", type: "number" },
                {
                  id: "month-current-anno",
                  type: "number",
                  role: "annotation",
                },
                { id: "month-last", label: lastMonth, type: "number" },
                { id: "month-last-anno", type: "number", role: "annotation" },
              ],
              rows: [
                {
                  c: [
                    { v: "Total Activations" },
                    { v: cellularThisMonth.activations },
                    { v: cellularThisMonth.activations },
                    {
                      v: cellularLastMonth.activations,
                      className: "table-header__this-month",
                    },
                    { v: cellularLastMonth.activations },
                  ],
                },
                {
                  c: [
                    { v: "Verizon" },
                    { v: cellularThisMonth.verizon_lte_activations },
                    { v: cellularThisMonth.verizon_lte_activations },
                    { v: cellularLastMonth.verizon_lte_activations },
                    { v: cellularLastMonth.verizon_lte_activations },
                  ],
                },
                {
                  c: [
                    { v: "AT&T" },
                    { v: cellularThisMonth.att_lte_activations },
                    { v: cellularThisMonth.att_lte_activations },
                    { v: cellularLastMonth.att_lte_activations },
                    { v: cellularLastMonth.att_lte_activations },
                  ],
                },
              ],
            };
          } else {
            console.warn(
              "BusinessHealthReportCtrl->loadCellularData() - falsy data - cellularThisMonth: " +
                angular.toJson(cellularThisMonth) +
                " | cellularLastMonth: " +
                angular.toJson(cellularLastMonth)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error getting cellular data",
            });
          }
          var cellularYtdMonths = data.filter(function (o) {
            return o.year === today.getFullYear() && o.month !== 0;
          });
          var activationsYTDRows = [];
          var verizonYTDRows = [];
          var attYTDRows = [];
          angular.forEach(cellularYtdMonths, function (month) {
            activationsYTDRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.activations },
              ],
            });
            verizonYTDRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.verizon_lte_activations },
              ],
            });
            attYTDRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.att_lte_activations },
              ],
            });
          });
          $scope.activationsYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Activations", type: "number" },
            ],
            rows: activationsYTDRows,
          };
          $scope.verizonYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Verizon Activations", type: "number" },
            ],
            rows: verizonYTDRows,
          };
          $scope.attYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "AT&T Activations", type: "number" },
            ],
            rows: attYTDRows,
          };
        },
        function () {
          $rootScope.alerts.push({
            type: "error",
            text: "error getting cellular data",
          });
        }
      );
    }

    function loadVirtualKeypadData() {
      BusinessAnalyticsService.getVirtualKeypadData(true).then(
        function (data) {
          console.debug("Got Virtual Keypad data: " + angular.toJson(data));
          var virtualKeypadTotal = data.find(function (o) {
            return o.year === 0 && o.month === 0;
          });
          var virtualKeypadThisMonth = data.find(function (o) {
            return (
              o.year === today.getFullYear() && o.month === today.getMonth() + 1
            );
          });
          // If it's January
          var virtualKeypadLastMonth =
            today.getMonth() === 0
              ? // Get December of last year
                data.find(function (o) {
                  return o.year === today.getFullYear() - 1 && o.month === 12;
                })
              : // Otherwise, get last month
                data.find(function (o) {
                  return (
                    o.year === today.getFullYear() &&
                    o.month === today.getMonth()
                  );
                });
          if (virtualKeypadThisMonth && virtualKeypadLastMonth) {
            // if (virtualKeypadThisMonth.login_rate === 0 || virtualKeypadLastMonth.login_rate === 0){
            //   virtualKeypadThisMonth.login_rate = 'Testing';
            //   virtualKeypadLastMonth.login_rate = 'Testing again';
            // }
            $scope.virtualKeypadChart.data = {
              cols: [
                { id: "Feature", label: "Enabled Features", type: "string" },
                { id: "month-current", label: "This Month", type: "number" },
                {
                  id: "month-current-anno",
                  type: "number",
                  role: "annotation",
                },
                { id: "month-last", label: lastMonth, type: "number" },
                { id: "month-last-anno", type: "number", role: "annotation" },
                { id: "total-current", label: "Total", type: "number" },
                {
                  id: "total-current-anno",
                  type: "number",
                  role: "annotation",
                },
              ],
              rows: [
                {
                  c: [
                    { v: "Arm Only App" },
                    { v: virtualKeypadThisMonth.arm_only_app },
                    { v: virtualKeypadThisMonth.arm_only_app },
                    { v: virtualKeypadLastMonth.arm_only_app }, //data
                    { v: virtualKeypadLastMonth.arm_only_app }, //notification
                    { v: virtualKeypadTotal.arm_only_app },
                    { v: virtualKeypadTotal.arm_only_app },
                  ],
                },
                {
                  c: [
                    { v: "Standard App" },
                    { v: virtualKeypadThisMonth.full_app },
                    { v: virtualKeypadThisMonth.full_app },
                    { v: virtualKeypadLastMonth.full_app },
                    { v: virtualKeypadLastMonth.full_app },
                    { v: virtualKeypadTotal.full_app },
                    { v: virtualKeypadTotal.full_app },
                  ],
                },
                {
                  c: [
                    { v: "Cameras" },
                    { v: virtualKeypadThisMonth.cameras },
                    { v: virtualKeypadThisMonth.cameras },
                    { v: virtualKeypadLastMonth.cameras },
                    { v: virtualKeypadLastMonth.cameras },
                    { v: virtualKeypadTotal.cameras },
                    { v: virtualKeypadTotal.cameras },
                  ],
                },
                {
                  c: [
                    { v: "Z-Wave Lights" },
                    { v: virtualKeypadThisMonth.zwave_lights },
                    { v: virtualKeypadThisMonth.zwave_lights },
                    { v: virtualKeypadLastMonth.zwave_lights },
                    { v: virtualKeypadLastMonth.zwave_lights },
                    { v: virtualKeypadTotal.zwave_lights },
                    { v: virtualKeypadTotal.zwave_lights },
                  ],
                },
                {
                  c: [
                    { v: "Z-Wave Locks" },
                    { v: virtualKeypadThisMonth.zwave_locks },
                    { v: virtualKeypadThisMonth.zwave_locks },
                    { v: virtualKeypadLastMonth.zwave_locks },
                    { v: virtualKeypadLastMonth.zwave_locks },
                    { v: virtualKeypadTotal.zwave_locks },
                    { v: virtualKeypadTotal.zwave_locks },
                  ],
                },
                {
                  c: [
                    { v: "Z-Wave Thermostats" },
                    { v: virtualKeypadThisMonth.zwave_therms },
                    { v: virtualKeypadThisMonth.zwave_therms },
                    { v: virtualKeypadLastMonth.zwave_therms },
                    { v: virtualKeypadLastMonth.zwave_therms },
                    { v: virtualKeypadTotal.zwave_therms },
                    { v: virtualKeypadTotal.zwave_therms },
                  ],
                },
                {
                  c: [
                    { v: "Geofencing" },
                    { v: virtualKeypadThisMonth.geofencing },
                    { v: virtualKeypadThisMonth.geofencing },
                    { v: virtualKeypadLastMonth.geofencing },
                    { v: virtualKeypadLastMonth.geofencing },
                    { v: virtualKeypadTotal.geofencing },
                    { v: virtualKeypadTotal.geofencing },
                  ],
                },
                {
                  c: [
                    { v: "Traffic Count" },
                    { v: virtualKeypadThisMonth.traffic_count },
                    { v: virtualKeypadThisMonth.traffic_count },
                    { v: virtualKeypadLastMonth.traffic_count },
                    { v: virtualKeypadLastMonth.traffic_count },
                    { v: virtualKeypadTotal.traffic_count },
                    { v: virtualKeypadTotal.traffic_count },
                  ],
                },
                {
                  c: [
                    { v: "Login Rate (%)" },
                    { v: Math.ceil(virtualKeypadThisMonth.login_rate) },
                    { v: virtualKeypadThisMonth.login_rate },
                    { v: Math.ceil(virtualKeypadLastMonth.login_rate) },
                    { v: virtualKeypadLastMonth.login_rate },
                    {},
                    {},
                  ],
                },
                {
                  c: [
                    { v: "Video Doorbells" },
                    { v: virtualKeypadThisMonth.doorbell },
                    { v: virtualKeypadThisMonth.doorbell },
                    { v: virtualKeypadLastMonth.doorbell },
                    { v: virtualKeypadLastMonth.doorbell },
                    { v: virtualKeypadTotal.doorbell },
                    { v: virtualKeypadTotal.doorbell },
                  ],
                },
                {
                  c: [
                    { v: "Hikvision NVRs" },
                    { v: virtualKeypadThisMonth.nvr },
                    { v: virtualKeypadThisMonth.nvr },
                    { v: virtualKeypadLastMonth.nvr },
                    { v: virtualKeypadLastMonth.nvr },
                    { v: virtualKeypadTotal.nvr },
                    { v: virtualKeypadTotal.nvr },
                  ],
                },
                {
                  c: [
                    { v: "OpenEye" },
                    { v: virtualKeypadThisMonth.openeye },
                    { v: virtualKeypadThisMonth.openeye },
                    { v: virtualKeypadLastMonth.openeye },
                    { v: virtualKeypadLastMonth.openeye },
                    { v: virtualKeypadTotal.openeye },
                    { v: virtualKeypadTotal.openeye },
                  ],
                },
                {
                  c: [
                    { v: "Video Verification" },
                    { v: virtualKeypadThisMonth.video_ver },
                    { v: virtualKeypadThisMonth.video_ver },
                    { v: virtualKeypadLastMonth.video_ver },
                    { v: virtualKeypadLastMonth.video_ver },
                    { v: virtualKeypadTotal.video_ver },
                    { v: virtualKeypadTotal.video_ver },
                  ],
                },
              ],
            };

            $scope.accessControlChart.data = {
              cols: [
                { id: "accessItem", label: "Access Item", type: "string" },
                { id: "month-current", label: "This Month", type: "number" },
                {
                  id: "month-current-anno",
                  type: "number",
                  role: "annotation",
                },
                { id: "month-last", label: lastMonth, type: "number" },
                { id: "month-last-anno", type: "number", role: "annotation" },
                { id: "total-current", label: "Total", type: "number" },
              ],
              rows: [
                {
                  c: [
                    { v: "Advanced Reports" },
                    { v: virtualKeypadThisMonth.advanced_reports },
                    { v: virtualKeypadThisMonth.advanced_reports },
                    { v: virtualKeypadLastMonth.advanced_reports },
                    { v: virtualKeypadLastMonth.advanced_reports },
                    { v: virtualKeypadTotal.advanced_reports },
                  ],
                },
                {
                  c: [
                    { v: "Doors of Access" },
                    { v: virtualKeypadThisMonth.door_access },
                    { v: virtualKeypadThisMonth.door_access },
                    { v: virtualKeypadLastMonth.door_access },
                    { v: virtualKeypadLastMonth.door_access },
                    { v: virtualKeypadTotal.door_access },
                  ],
                },
              ],
            };
          } else {
            console.warn(
              "BusinessHealthReportCtrl->loadVirtualKeypadData() - falsy data - virtualKeypadThisMonth: " +
                angular.toJson(virtualKeypadThisMonth) +
                " | virtualKeypadLastMonth: " +
                angular.toJson(virtualKeypadLastMonth)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error getting virtual keypad data",
            });
          }
          var virtualKeypadYtdMonths = data.filter(function (o) {
            return o.year === today.getFullYear() && o.month !== 0;
          });
          var armOnlyYtdRows = [];
          var fullAppYtdRows = [];
          var camerasYtdRows = [];
          var zWaveLightsYtdRows = [];
          var zWaveLocksYtdRows = [];
          var zWaveThermostatsYtdRows = [];
          var geofenceYtdRows = [];
          var trafficCountYtdRows = [];
          var loginYtdRows = [];
          var doorbellYtdRows = [];
          var nvrYtdRows = [];
          var openEyeYtdRows = [];
          var videoVerificationYtdRows = [];
          var advancedReportsYtdRows = [];
          var doorsOfAccessYtdRows = [];
          angular.forEach(virtualKeypadYtdMonths, function (month) {
            armOnlyYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.arm_only_app },
              ],
            });
            fullAppYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.full_app },
              ],
            });
            camerasYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.cameras },
              ],
            });
            zWaveLightsYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.zwave_lights },
              ],
            });
            zWaveLocksYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.zwave_locks },
              ],
            });
            zWaveThermostatsYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.zwave_therms },
              ],
            });
            geofenceYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.geofencing },
              ],
            });
            trafficCountYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.traffic_count },
              ],
            });
            loginYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.login_rate },
              ],
            });
            doorbellYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.doorbell },
              ],
            });
            nvrYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.nvr },
              ],
            });
            openEyeYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.openeye },
              ],
            });
            videoVerificationYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.video_ver },
              ],
            });
            advancedReportsYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.advanced_reports },
              ],
            });
            doorsOfAccessYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.door_access },
              ],
            });
          });
          $scope.armOnlyYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              {
                id: "items",
                label: "Arm Only App Activations",
                type: "number",
              },
            ],
            rows: armOnlyYtdRows,
          };
          $scope.fullAppYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              {
                id: "items",
                label: "Standard App Activations",
                type: "number",
              },
            ],
            rows: fullAppYtdRows,
          };
          $scope.camerasYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Cameras", type: "number" },
            ],
            rows: camerasYtdRows,
          };
          $scope.zwaveLightsYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Z-Wave Lights", type: "number" },
            ],
            rows: zWaveLightsYtdRows,
          };
          $scope.zwaveLocksYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Z-Wave Locks", type: "number" },
            ],
            rows: zWaveLocksYtdRows,
          };
          $scope.zwaveThermostatsYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Z-Wave Thermostats", type: "number" },
            ],
            rows: zWaveThermostatsYtdRows,
          };
          $scope.geofenceYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Geofence Activations", type: "number" },
            ],
            rows: geofenceYtdRows,
          };
          $scope.trafficCountYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              {
                id: "items",
                label: "Traffic Count Activations",
                type: "number",
              },
            ],
            rows: trafficCountYtdRows,
          };
          $scope.loginYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Login Rate", type: "number" },
            ],
            rows: loginYtdRows,
          };
          $scope.doorbellYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Doorbells", type: "number" },
            ],
            rows: doorbellYtdRows,
          };
          $scope.nvrYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Hikvision NVRs", type: "number" },
            ],
            rows: nvrYtdRows,
          };
          $scope.openEyeYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "OpenEye Integrations", type: "number" },
            ],
            rows: openEyeYtdRows,
          };
          $scope.videoVerificationYTDChart.data = {
            cols: [
              { id: "month", label: "Month", type: "string" },
              { id: "items", label: "Video Verification", type: "number" },
            ],
            rows: videoVerificationYtdRows,
          };
          $scope.advancedReportsYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Advanced Reports", type: "number" },
            ],
            rows: advancedReportsYtdRows,
          };
          $scope.doorsOfAccessYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Doors of Access", type: "number" },
            ],
            rows: doorsOfAccessYtdRows,
          };
        },
        function () {
          $rootScope.alerts.push({
            type: "error",
            text: "error getting virtual keypad data",
          });
        }
      );
    }

    $scope.systemsWithSalesHistory = [];

    function loadSystemsData() {
      BusinessAnalyticsService.getSystemsData(true).then(
        function (data) {
          console.debug("Got Virtual Keypad data: " + angular.toJson(data));
          var systemProperties = [
            { name: "xtl_touch", display: "XTLtouch" },
            { name: "xtl_plus", display: "XTLplus" },
            { name: "xt_30", display: "XT30" },
            { name: "xt_50", display: "XT50" },
            { name: "xr_150", display: "XR150" },
            { name: "xr_550", display: "XR550" },
            { name: "cellcom", display: "CellCom" },
            { name: "icom", display: "iCom" },
            { name: "dualcom", display: "DualCom" },
          ];
          $scope.systemsWithSalesHistory = [];
          var systemsTotal = data.find(function (o) {
            return o.year === 0 && o.month === 0;
          });
          if (systemsTotal) {
            Object.keys(systemsTotal).forEach((key) => {
              var prop = systemProperties.find(function (p) {
                return p.name === key;
              });
              if (prop && parseInt(systemsTotal[key])) {
                $scope.systemsWithSalesHistory.push(prop.display);
              }
            });
          } else {
            console.warn(
              "BusinessHealthReportCtrl->loadSystemsData() - falsy data - systemsTotal"
            );
          }
          var systemsThisMonth = data.find(function (o) {
            return (
              o.year === today.getFullYear() && o.month === today.getMonth() + 1
            );
          });
          // If it's January
          var systemsLastMonth =
            today.getMonth() === 0
              ? // Get December of last year
                data.find(function (o) {
                  return o.year === today.getFullYear() - 1 && o.month === 12;
                })
              : // Otherwise, get last month
                data.find(function (o) {
                  return (
                    o.year === today.getFullYear() &&
                    o.month === today.getMonth()
                  );
                });
          if (systemsThisMonth && systemsLastMonth) {
            $scope.systemsChart.data = {
              cols: [
                { id: "month", label: "Systems", type: "string" },
                { id: "month-current", label: "This Month", type: "number" },
                {
                  id: "month-current-anno",
                  type: "number",
                  role: "annotation",
                },
                { id: "month-last", label: lastMonth, type: "number" },
                { id: "month-last-anno", type: "number", role: "annotation" },
                { id: "ytd-current-systems", label: "Total", type: "number" },
                {
                  id: "ytd-current-systems-anno",
                  type: "number",
                  role: "annotation",
                },
              ],
              rows: [
                {
                  c: [
                    { v: "All Systems" },
                    { v: systemsThisMonth.total },
                    { v: systemsThisMonth.total },
                    { v: systemsLastMonth.total },
                    { v: systemsLastMonth.total },
                    { v: systemsTotal.total },
                    { v: systemsTotal.total },
                  ],
                },
                {
                  c: [
                    { v: "Systems w/ App" },
                    { v: systemsThisMonth.total_w_app },
                    { v: systemsThisMonth.total_w_app },
                    { v: systemsLastMonth.total_w_app },
                    { v: systemsLastMonth.total_w_app },
                    { v: systemsTotal.total_w_app },
                    { v: systemsTotal.total_w_app },
                  ],
                },
                {
                  c: [
                    { v: "Systems w/ Cell" },
                    { v: systemsThisMonth.total_w_cell },
                    { v: systemsThisMonth.total_w_cell },
                    { v: systemsLastMonth.total_w_cell },
                    { v: systemsLastMonth.total_w_cell },
                    { v: systemsTotal.total_w_cell },
                    { v: systemsTotal.total_w_cell },
                  ],
                },
              ],
            };
            $scope.systemsWithSalesHistory.forEach((model) => {
              var prop = systemProperties.find(function (p) {
                return p.display === model;
              });
              if (prop) {
                $scope.systemsChart.data.rows.push({
                  c: [
                    { v: prop.display },
                    { v: systemsThisMonth[prop.name] },
                    { v: systemsThisMonth[prop.name] },
                    { v: systemsLastMonth[prop.name] },
                    { v: systemsLastMonth[prop.name] },
                    { v: systemsTotal[prop.name] },
                    { v: systemsTotal[prop.name] },
                  ],
                });
              }
            });
          } else {
            console.warn(
              "BusinessHealthReportCtrl->loadSystemsData() - falsy data - systemsThisMonth: " +
                angular.toJson(systemsThisMonth) +
                " | systemsLastMonth: " +
                angular.toJson(systemsLastMonth)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error getting virtual keypad data",
            });
          }
          var systemsYtdMonths = data.filter(function (o) {
            return o.year === today.getFullYear() && o.month !== 0;
          });
          var totalSystemsYtdRows = [];
          var totalSystemsWithAppYtdRows = [];
          var totalWithCellYtdRows = [];
          var xtlTouchYtdRows = [];
          var xtlPlusYtdRows = [];
          var xt30YtdRows = [];
          var xt50YtdRows = [];
          var xr150YtdRows = [];
          var xr550YtdRows = [];
          var cellComYtdRows = [];
          var iComYtdRows = [];
          var dualComYtdRows = [];
          angular.forEach(systemsYtdMonths, function (month) {
            totalSystemsYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.total },
              ],
            });
            totalSystemsWithAppYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.total_w_app },
              ],
            });
            totalWithCellYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.total_w_cell },
              ],
            });
            xtlTouchYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.xtl_touch },
              ],
            });
            xtlPlusYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.xtl_plus },
              ],
            });
            xt30YtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.xt_30 },
              ],
            });
            xt50YtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.xt_50 },
              ],
            });
            xr150YtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.xr_150 },
              ],
            });
            xr550YtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.xr_550 },
              ],
            });
            cellComYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.cellcom },
              ],
            });
            iComYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.icom },
              ],
            });
            dualComYtdRows.push({
              c: [
                { v: months[month.month - 1].substring(0, 3) },
                { v: month.dualcom },
              ],
            });
          });
          $scope.totalSystemsYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "All Systems YTD", type: "number" },
            ],
            rows: totalSystemsYtdRows,
          };
          $scope.totalWithAppYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Systems w/ App YTD", type: "number" },
            ],
            rows: totalSystemsWithAppYtdRows,
          };
          $scope.systemsWithCellYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "Systems w/ Cell YTD", type: "number" },
            ],
            rows: totalWithCellYtdRows,
          };
          $scope.xtlTouchYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "XTLtouch Installations", type: "number" },
            ],
            rows: xtlTouchYtdRows,
          };
          $scope.xtlPlusYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "XTLplus Installations", type: "number" },
            ],
            rows: xtlPlusYtdRows,
          };
          $scope.xt30YTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "XT30 Installations", type: "number" },
            ],
            rows: xt30YtdRows,
          };
          $scope.xt50YTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "XT50 Installations", type: "number" },
            ],
            rows: xt50YtdRows,
          };
          $scope.xr150YTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "XR150 Installations", type: "number" },
            ],
            rows: xr150YtdRows,
          };
          $scope.xr550YTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "XR550 Installations", type: "number" },
            ],
            rows: xr550YtdRows,
          };
          $scope.cellComYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "CellCom Installations", type: "number" },
            ],
            rows: cellComYtdRows,
          };
          $scope.iComYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "iCom Installations", type: "number" },
            ],
            rows: iComYtdRows,
          };
          $scope.dualComYTDChart.data = {
            cols: [
              { id: "month", label: "Months", type: "string" },
              { id: "items", label: "DualCom Installations", type: "number" },
            ],
            rows: dualComYtdRows,
          };
        },
        function () {
          $rootScope.alerts.push({
            type: "error",
            text: "error getting systems data",
          });
        }
      );
    }

    function init() {
      chartTypeBasedOnView($scope.viewWidth);
      loadAccountOverviewData();
      loadCellularData();
      loadVirtualKeypadData();
      loadSystemsData();
      getModemUpgradeStats();
    }

    init();
  },
]);
