/**
 * @generated SignedSource<<645c4b77b8ee6eb60789f1e7b2bc59ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay$data = {
  readonly supervisoryZoneKeypads: string;
  readonly " $fragmentType": "StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay";
};
export type StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay$key = {
  readonly " $data"?: StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supervisoryZoneKeypads",
      "storageKey": null
    }
  ],
  "type": "StatusListDisplay",
  "abstractKey": null
};

(node as any).hash = "dc77703d9f032e11953d8667806e8450";

export default node;
