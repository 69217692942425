/**
 * @generated SignedSource<<b84d0928f0e6ba7c21e93b900e832090>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NetworkOptionsWifiSecurity = "NONE" | "UNSUPPORTED" | "WEP" | "WPA";
import { FragmentRefs } from "relay-runtime";
export type XTNetworkOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly networkOptions: {
      readonly __typename: "NetworkOptions";
      readonly ddpPassphrase: string;
      readonly dhcpEnabled: boolean;
      readonly dnsServer: string;
      readonly enableDdp: boolean;
      readonly gatewayAddress: string;
      readonly id: string;
      readonly ipv6Address: string;
      readonly ipv6Dns: string;
      readonly ipv6Enabled: boolean;
      readonly ipv6Gateway: string;
      readonly ipv6Prefix: string;
      readonly listenPort734N: string;
      readonly listenPort734NMax: number;
      readonly listenPort734NMin: number;
      readonly localIpAddress: string;
      readonly passphrase: string;
      readonly passphrase734N: string;
      readonly programmingPort: string;
      readonly subnetMask: string;
      readonly wifiPassphrase: string;
      readonly wifiPassphraseMaxLength: number;
      readonly wifiSecurity: NetworkOptionsWifiSecurity;
      readonly wifiSsid: string;
    } | null;
  };
  readonly " $fragmentType": "XTNetworkOptionsProgrammingConceptFormInline_controlSystem";
};
export type XTNetworkOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTNetworkOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTNetworkOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTNetworkOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "69d2ccae1484cb872355c0fd171f9360";

export default node;
