import DaColors from "app/common/DaColors";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.6rem 0;
  border-bottom: 0.1rem solid ${DaColors.Neutral450};
  margin-bottom: 2.4rem;
  min-height: 4.4rem;
  width: 100%;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
const LeftContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & h1 {
    margin: 0;
    font-size: 2.4rem;
  }
`;
const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

type Props = {
  leftContent: ReactNode;
  rightContent?: ReactNode;
};

const CleanPageHeader = ({ leftContent, rightContent }: Props) => {
  return (
    <Wrapper>
      <LeftContentWrapper>{leftContent}</LeftContentWrapper>
      {rightContent ? (
        <RightContentWrapper>{rightContent}</RightContentWrapper>
      ) : null}
    </Wrapper>
  );
};

export default CleanPageHeader;
