/**
 * @ngdoc API Resource
 * @name App.factory:AvailableUpdates
 *
 * @description
 * AvailableUpdates API resource
 *
 */
App.factory("AvailableUpdates", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/panels/:id/available_firmwares?page_size=100"
    );
  },
]);
