import styled, { css } from "styled-components";
import { media } from "common/utils/styles";
import { AREA_MARGIN_IN_PX } from "./constants";

const BasicArmingArea = styled.li<{
  count: number;
  index: number;
  active: boolean;
  inactive: boolean;
}>`
  display: block;
  position: absolute;
  top: ${({ count, index }) =>
    `calc(${(100 / count) * index}% + ${AREA_MARGIN_IN_PX * index}px)`};
  bottom: ${({ count, index }) =>
    `calc(${(100 / count) * (count - 1 - index)}% + ${
      AREA_MARGIN_IN_PX * (count - 1 - index)
    }px)`};
  left: 0;
  right: 0;
  transition: 1.25s opacity, 1.25s visibility, 0.8s transform, left 0.5s,
    right 0.5s, top 0.5s, bottom 0.5s;
  opacity: 1;

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0;
      transform: scale(0.5);
      visibility: hidden;
    `}

  ${({ active }) =>
    active &&
    css`
      top: 0;
      bottom: 0;
    `}

  ${media.xsmall`
    height: 100%;
    top: 0;
    left: ${({ count, index }) =>
      `calc(${(100 / count) * index}% + ${AREA_MARGIN_IN_PX * index}px)`};
    right: ${({ count, index }) =>
      `calc(${(100 / count) * (count - 1 - index)}% + ${
        AREA_MARGIN_IN_PX * (count - 1 - index)
      }px)`};

    ${({ active }) =>
      active &&
      css`
        left: 0;
        right: 0;
      `}
  `}
`;
export default BasicArmingArea;
