import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { CommunicationComType } from "securecom-graphql/client";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationTypeField_communication$key } from "./__generated__/CommunicationTypeField_communication.graphql";

export const communicationTypeFieldId = () => "communication-type";

function CommunicationTypeField() {
  const [communication, updateCommunication] =
    useCommunicationFragment<CommunicationTypeField_communication$key>(
      graphql`
        fragment CommunicationTypeField_communication on Communication {
          comType
          checkInMinutes
        }
      `
    );

  const fieldId = communicationTypeFieldId();
  const softwareVersion = Number(useSoftwareVersion());
  const hardwareModel = useHardwareModel();
  const { isTakeoverPanel } = resolvePanelType(useHardwareModel());

  const hasDialer = ["XT30", "XT50"].includes(hardwareModel);

  const hasNetwork =
    [
      "XT30",
      "XT50",
      "ICOM",
      "DUALCOM",
      "ICOM_SL",
      "ICOM_E",
      "ICOM_LNC",
      "ICOM_NRL",
      "XTLN",
      "TMS6",
    ].includes(hardwareModel) ||
    (["XTLP"].includes(hardwareModel) && Number(softwareVersion) > 201);

  const hasCellular = ![
    "ICOM",
    "ICOM_SL",
    "ICOM_E",
    "ICOM_LNC",
    "ICOM_NRL",
  ].includes(hardwareModel);

  const allowsNone = [
    "XT30",
    "XT50",
    "XT30L",
    "XT50L",
    "XTLP",
    "XTLN",
    "XTLW",
    "XR550",
    "XR150",
    "XR350",
    "TMS6",
  ].includes(hardwareModel);

  const hasWifi =
    softwareVersion >= 124 &&
    hardwareModel !== "ICOM_SL" &&
    hardwareModel !== "CELLCOM_SL" &&
    hardwareModel !== "CELLCOM_EX" &&
    hardwareModel !== "TMS6";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Communication Type"
      tooltip="Communication type that the System uses to connect to the Receiver(s)"
    >
      <Select
        id={fieldId}
        value={communication?.comType as CommunicationComType}
        required
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              target.value as CommunicationComType,
              "comType"
            );
          });
          if (target.value === CommunicationComType.CELL && !isTakeoverPanel)
            updateCommunication((recordProxy) => {
              recordProxy.setValue(0, "checkInMinutes");
              recordProxy.setValue(0, "failTime");
            });
          if (target.value === CommunicationComType.CELL && isTakeoverPanel)
            updateCommunication((recordProxy) => {
              recordProxy.setValue(0, "checkInMinutes");
              recordProxy.setValue(240, "failTime");
            });
          else if (
            [CommunicationComType.NETWORK, CommunicationComType.WIFI].includes(
              target.value as CommunicationComType
            )
          ) {
            updateCommunication((recordProxy) => {
              recordProxy.setValue(200, "checkInMinutes");
              recordProxy.setValue(240, "failTime");
            });
          } else if (target.value === CommunicationComType.NONE) {
            updateCommunication((recordProxy) => {
              recordProxy.setValue(0, "checkInMinutes");
              recordProxy.setValue(0, "failTime");
            });
          }
        }}
      >
        {allowsNone && (
          <Select.Option value={CommunicationComType.NONE}>None</Select.Option>
        )}
        {hasDialer && (
          <>
            <Select.Option value={CommunicationComType.CID}>CID</Select.Option>
            <Select.Option value={CommunicationComType.DD}>DD</Select.Option>
          </>
        )}
        {hasNetwork && (
          <Select.Option value={CommunicationComType.NETWORK}>
            Network
          </Select.Option>
        )}
        {hasCellular && (
          <Select.Option value={CommunicationComType.CELL}>Cell</Select.Option>
        )}
        {hasWifi && (
          <Select.Option value={CommunicationComType.WIFI}>Wi-Fi</Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationTypeField;
