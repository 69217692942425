//possibly not used
App.directive("daBatchCredentialUpload", [
  "$http",
  "$q",
  "BatchImportCredentialsService",
  function ($http, $q, BatchImportCredentialsService) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        panelId: "=",
      },
      require: "?ngModel",
      templateUrl: "app/common/templates/batch-credential-upload.html",
      link: function (scope, element, attrs, ngModel) {
        var _this = this;
        BatchImportCredentialsService.initialize(scope.panelId);
        scope.BatchService = BatchImportCredentialsService;
        //scope.myFile = {};
        var droptarget = angular.element(
          element[0].querySelector(".batch-uploader__drag-target")
        );

        element.on("dragover", function (e) {
          e.preventDefault();
          e.stopPropagation();
          droptarget.addClass("drag-target-hit");
        });
        element.on("dragleave", function (e) {
          e.preventDefault();
          e.stopPropagation();
          droptarget.removeClass("drag-target-hit");
        });
        element.on("dragenter", function (e) {
          e.preventDefault();
          e.stopPropagation();
        });

        element.on("drop", function (e) {
          e.preventDefault();
          e.stopPropagation();
          if (e.originalEvent.dataTransfer) {
            if (e.originalEvent.dataTransfer.files.length < 1) {
              //No files to upload
            } else if (e.originalEvent.dataTransfer.files.length > 1) {
              //More than 1 file... not supported
            } else {
              upload(e.originalEvent.dataTransfer.files[0]);
            }
          }
          return false;
        });

        scope.uploadFile = function (file) {
          //var file = scope.fileModel;
          upload(file);
        };

        scope.browseClick = function () {
          document.querySelector("#file-upload-chooser").click();
        };

        scope.addMoreCards = function () {
          scope.BatchService.currentStatus = "";
        };

        var upload = function (file) {
          var deferred = $q.defer();
          var reader = new FileReader();
          reader.readAsDataURL(file);
          var credential = {};
          scope.myFileName = file.name;
          credential.fileName = file.name;
          reader.onload = function () {
            credential.data = angular.copy(reader.result.split(",")[1]);
            BatchImportCredentialsService.import(
              credential.data,
              credential.fileName
            )
              .then(
                function (data) {
                  deferred.resolve(data);
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          };
          return deferred.promise;
        };
      },
    };
  },
]);
