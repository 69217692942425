import DaColors from "app/common/DaColors";
import DmpIcon from "common/components/DmpIcon";
import { SecurityEvent } from "dealer-admin/src/apis/odata/security-events-parser";
import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const ImageThumbnail = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
const IconThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1rem;
`;
const Wrapper = styled.div<{ isSmall: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 0.4rem;
  background-color: ${DaColors.Neutral25};
  height: ${(props) => (props.isSmall ? "4.95rem" : "7.2rem")};
  width: ${(props) => (props.isSmall ? "8.8rem" : "12.8rem")};
`;
const Video = styled.video`
  max-width: 100%;
  height: auto;
`;

export enum ThumbnailSize {
  Small = "Small",
  Large = "Large",
}

type Props = {
  event: SecurityEvent;
  size: ThumbnailSize;
};

// For V-6000 events, it's not feasible to play live video for the thumbnails because
// we can only pull one recording at a time from a camera. If we try to load several
// events' recordings, one at most will succeed, and even that one might take a long
// time to load. So it's a terrible experience.
//
// Similarly, playing live video for iENSO thumbnails doesn't work well because some
// of the clips are huge and the browser gets overwhelemed.
//
// Instead, if an image URL is available, we'll use that, and if not, we'll use a
// generic icon.
const StillThumbnail = ({ event, size }: Props) => {
  if (event.imageUrl) {
    return <ImageThumbnail src={event.imageUrl} />;
  } else {
    const iconSize = size === ThumbnailSize.Small ? 33 : 48;

    return (
      <IconThumbnail>
        <DmpIcon icon="clips_with_play" size={iconSize} />
        <div>Play Video</div>
      </IconThumbnail>
    );
  }
};

// For Camect events and legacy clips, we'll use a live video thumbnail if we can.
const LiveThumbnail = ({ event }: Props) => {
  if (event.videoUrl) {
    return (
      <Video
        aria-label="thumbnailVideo"
        src={event.videoUrl}
        autoPlay
        preload="metadata"
        loop
        muted
      />
    );
  } else {
    return <></>;
  }
};

const EventThumbnail = ({ event, size }: Props) => {
  const [intersectionObserverRef, inView] = useInView();

  if (event.cameraType !== "v-6000" && !event.videoUrl) {
    return <></>;
  }

  return (
    <Wrapper
      isSmall={size === ThumbnailSize.Small}
      ref={intersectionObserverRef}
    >
      {(event.cameraType === "v-6000" || event.cameraType === "ienso") && (
        <StillThumbnail event={event} size={size} />
      )}
      {/* We only want to play live video thumbnails for events/clips that are currently visible to
      the user--we don't want to be playing a bunch of videos way down in the list that haven't been
      scrolled into view yet. */}
      {event.cameraType !== "v-6000" &&
        event.cameraType !== "ienso" &&
        event.videoUrl &&
        inView && <LiveThumbnail event={event} size={size} />}
    </Wrapper>
  );
};

export default EventThumbnail;
