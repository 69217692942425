/**
 * @generated SignedSource<<41e5e0ab8d1981d941350d518515027e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationComType = "CELL" | "CID" | "DD" | "NETWORK" | "NONE" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type CommunicationCellTestDaysField_communication$data = {
  readonly backupCell: boolean;
  readonly cellTestDays: number;
  readonly cellTestDaysMax: number;
  readonly cellTestDaysMin: number;
  readonly comType: CommunicationComType;
  readonly " $fragmentType": "CommunicationCellTestDaysField_communication";
};
export type CommunicationCellTestDaysField_communication$key = {
  readonly " $data"?: CommunicationCellTestDaysField_communication$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunicationCellTestDaysField_communication">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunicationCellTestDaysField_communication",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cellTestDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cellTestDaysMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cellTestDaysMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backupCell",
      "storageKey": null
    }
  ],
  "type": "Communication",
  "abstractKey": null
};

(node as any).hash = "9b74c819989cd6d63dcb90c9962d1004";

export default node;
