/**
 * @generated SignedSource<<707c235ed55deff059b6ca8be8e2a0ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
export type SendLocalChangesType = "DIGITAL_DIALER" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type XRRemoteOptionsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly remoteOptions: {
      readonly __typename: "XrRemoteOptions";
      readonly alarmReceiverAuthorization: boolean | null;
      readonly allowCellularRemoteProgramming: boolean | null;
      readonly allowNetworkRemote: boolean | null;
      readonly appKey: string | null;
      readonly armedAnswerRings: number | null;
      readonly disarmedAnswerRings: number | null;
      readonly entreArmDisarmReports: boolean | null;
      readonly entreBackupConnection: RemoteOptionsConnectionType | null;
      readonly entreBackupIncomingPort: string | null;
      readonly entreBackupIpAddress: string | null;
      readonly entreBackupIpv6Address: string | null;
      readonly entreCheckInMinutes: number | null;
      readonly entreConnection: RemoteOptionsConnectionType | null;
      readonly entreDoorReports: boolean | null;
      readonly entreIncomingPort: string | null;
      readonly entreIpAddress: string | null;
      readonly entreIpv6: boolean | null;
      readonly entreIpv6Address: string | null;
      readonly entreOutgoingPort: string | null;
      readonly entrePassphrase: string | null;
      readonly entreSupervisoryReports: boolean | null;
      readonly entreUserCommandReports: boolean | null;
      readonly entreVideoReports: boolean | null;
      readonly entreZoneReports: boolean | null;
      readonly firstApn: string | null;
      readonly id: string;
      readonly integratorArmDisarmReports: boolean | null;
      readonly integratorBackupConnection: RemoteOptionsConnectionType | null;
      readonly integratorBackupIncomingPort: string | null;
      readonly integratorBackupIpAddress: string | null;
      readonly integratorCheckInMinutes: number | null;
      readonly integratorConnection: RemoteOptionsConnectionType | null;
      readonly integratorDoorReports: boolean | null;
      readonly integratorIncomingPort: string | null;
      readonly integratorIpAddress: string | null;
      readonly integratorOutgoingPort: string | null;
      readonly integratorPassphrase: string | null;
      readonly integratorSupervisoryReports: boolean | null;
      readonly integratorUserCommandReports: boolean | null;
      readonly integratorVideoReports: boolean | null;
      readonly integratorZoneReports: boolean | null;
      readonly networkProgrammingPort: string | null;
      readonly pcModem: boolean | null;
      readonly remoteChangesIpAddress: string | null;
      readonly remoteChangesPhoneNumber: string | null;
      readonly remoteChangesPort: string | null;
      readonly remoteDisarm: boolean;
      readonly remoteKey: string;
      readonly secondApn: string | null;
      readonly sendLocalChanges: SendLocalChangesType | null;
      readonly serviceReceiverAuthorization: boolean | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  };
  readonly " $fragmentType": "XRRemoteOptionsProgrammingConceptFormInline_controlSystem";
};
export type XRRemoteOptionsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XRRemoteOptionsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRRemoteOptionsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRRemoteOptionsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "123f2aa4902fe6e886bfaa9e09691f1f";

export default node;
