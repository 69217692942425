import { ascend, range, sort } from "ramda";

/**
 *Attempts to convert everything but empty strings to Number()
 * @param value string or number to convert
 * @returns An empty string or a Number or NaN for non empty strings
 */

export const emptyStringOrNumber = (
  value: string | number
): string | number => {
  return value === "" ? value : Number(value);
};

/**
 *
 * @param value String that is intended to indicate a range of numbers that may or may not have gaps.
 * Represented by single numbers or min max pairs separated by dashes.  Each pair or single number
 * separated by comas.  eg. "1-5,7,11-15"
 * @returns A string with each value from the input range separated by commas, with duplicates removed, in ascending numeric order
 */

export const formatListOfNumbers = (value: string): string =>
  sort(ascend(Number), [
    ...new Set(
      value
        .trim()
        .split(/(\s*,\s*|\s+)/) // splits on spaces and commas
        .map((item) => item.replaceAll(/[^\d-]+/g, "").replaceAll(/[-]+/g, "-")) //removes all non digit characters
        .map((item) => {
          switch (item) {
            case item.match(/^[\d]+$/)?.input: {
              // If it is a number of any length return the number
              return Number(item);
            }
            case item.match(/^[\d]+[-]$|^[-][\d]+$/)?.input: {
              //if it is a dash number or number dash return the number only
              return Number(item.replaceAll(/[-]+/g, ""));
            }
            case item.match(/^[\d]+[-][\d]+$/)?.input: {
              //if it is number dash number return the range of numbers after putting them in proper order
              const [first, second] = item
                .split("-")
                .map((item) => Number(item));
              return first <= second
                ? range(first, second + 1)
                : range(second, first + 1);
            }
            default: {
              //if anything else return empty array
              return [];
            }
          }
        })
        .flat()
    ),
  ]).join(",");
