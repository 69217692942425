/**
 * @generated SignedSource<<ce8b23630b3eae6e133cfcc2af80ba0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts$data = {
  readonly lockoutCode: {
    readonly code: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
  } | null;
  readonly " $fragmentType": "XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts";
};
export type XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts$key = {
  readonly " $data"?: XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XFLockoutCodeTemplateDataInline_xfProgrammingTemplateConcepts"
};

(node as any).hash = "3e289bc344b32a8ea7d015401b9f4df9";

export default node;
