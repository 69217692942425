import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { areaArmedOutputNumberFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaArmedOutputNumberField";
import { areaArmFirstAreaFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaArmFirstAreaField";
import { areaAutoArmFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaAutoArmField";
import { areaAutoDisarmFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaAutoDisarmField";
import { areaBadZonesFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaBadZonesField";
import { areaBankSafeVaultFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaBankSafeVaultField";
import { areaBurglaryBellOutputNumberFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaBurglaryBellOutputNumberField";
import { areaCardPlusPinFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaCardPlusPinField";
import { areaCommonAreaFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaCommonAreaField";
import { areaDualAuthorityFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaDualAuthorityField";
import { areaLateArmDelayFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaLateArmDelayField";
import { areaLateOutputNumberFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaLateOutputNumberField";
import { areaNameFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaNameField";
import {
  areaListItemTemplateId,
  areaNumberFieldId,
} from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaNumberField";
import { areaOpenClosingReportsFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaOpenClosingReportsField";
import { areaTwoManRuleFieldId } from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaTwoManRuleField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { XRAreaInformationProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/XRAreaInformationProgrammingConceptForm/__generated__/XRAreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import * as React from "react";
import { readInlineData } from "react-relay";
import { BadZonesOption, DualAuthorityEnum } from "securecom-graphql/client";
import {
  getAreaState,
  getState as getAreasState,
} from "../../../XRFullProgramming/XRAreaInformationProgrammingConceptForm";
import { XrProgrammingTemplateAreaInformationInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        areaInformations {
          id
          included
          number
          autoArmEnabled {
            data
            included
          }
          autoDisarmEnabled {
            data
            included
          }
          name {
            data
            included
          }
          badZonesOption {
            data
            included
          }
          armedOutputNumber {
            data
            included
          }
          lateOutputNumber {
            data
            included
          }
          lateArmDelay {
            data
            included
          }
          bankSafeVault {
            data
            included
          }
          commonArea {
            data
            included
          }
          armFirstArea {
            data
            included
          }
          dualAuthority {
            data
            included
          }
          openClosingReports {
            data
            included
          }
          burglaryBellOutputNumber {
            data
            included
          }
          cardPlusPin {
            data
            included
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XRAreaInformationProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateAreaInformationInput[] => {
  const {
    panel: {
      areas: { edges: areaInformations },
    },
  } = getAreasState(controlSystem);

  return areaInformations
    ? areaInformations
        .map((area) => area.node)
        .filter(isNotNullOrUndefined)
        .map(getAreaState)
        .map((area) => ({
          included: includedFields.has(areaListItemTemplateId(area.number)),
          number: Number(area.number),
          autoArmEnabled: {
            data: area.autoArmEnabled,
            included: includedFields.has(areaAutoArmFieldId(area.number)),
          },
          autoDisarmEnabled: {
            data: area.autoDisarmEnabled,
            included: includedFields.has(areaAutoDisarmFieldId(area.number)),
          },
          name: {
            data: area.name,
            included: includedFields.has(areaNameFieldId(area.number)),
          },
          badZonesOption: {
            data: area.badZonesOption as BadZonesOption,
            included: includedFields.has(areaBadZonesFieldId(area.number)),
          },
          armedOutputNumber: {
            data: String(area.armedOutputNumber),
            included: includedFields.has(
              areaArmedOutputNumberFieldId(area.number)
            ),
          },
          lateOutputNumber: {
            data: String(area.lateOutputNumber),
            included: includedFields.has(
              areaLateOutputNumberFieldId(area.number)
            ),
          },
          lateArmDelay: {
            data: area.lateArmDelay,
            included: includedFields.has(areaLateArmDelayFieldId(area.number)),
          },
          bankSafeVault: {
            data: area.bankSafeVault,
            included: includedFields.has(areaBankSafeVaultFieldId(area.number)),
          },
          commonArea: {
            data: area.commonArea,
            included: includedFields.has(areaCommonAreaFieldId(area.number)),
          },
          armFirstArea: {
            data: area.armFirstArea,
            included: includedFields.has(areaArmFirstAreaFieldId(area.number)),
          },
          dualAuthority: includedFields.has(areaTwoManRuleFieldId(area.number)) //twoManRule is from older panels which templates can be created from but will not be applied to.  The replacement field is dualAuthority
            ? {
                data: area.twoManRule
                  ? DualAuthorityEnum.ALL
                  : DualAuthorityEnum.NO,
                included: includedFields.has(
                  areaTwoManRuleFieldId(area.number)
                ),
              }
            : {
                data: area.dualAuthority,
                included: includedFields.has(
                  areaDualAuthorityFieldId(area.number)
                ),
              },
          openClosingReports: {
            data: area.openClosingReports,
            included: includedFields.has(
              areaOpenClosingReportsFieldId(area.number)
            ),
          },
          burglaryBellOutputNumber: {
            data: area.burglaryBellOutputNumber,
            included: includedFields.has(
              areaBurglaryBellOutputNumberFieldId(area.number)
            ),
          },
          cardPlusPin: {
            data: area.cardPlusPin,
            included: includedFields.has(areaCardPlusPinFieldId(area.number)),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XRAreaInformationTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: React.Dispatch<
    React.SetStateAction<IncludedTemplateFieldsType>
  >
) => {
  const { areaInformations } = getState(template);

  if (!areaInformations) {
    return;
  }

  areaInformations.filter(isNotNullOrUndefined).forEach((area) => {
    const number = String(area.number);
    if (area.included) {
      setIncludedFields(setToggle(areaListItemTemplateId(String(area.number))));
      setIncludedFields(setToggle(areaNumberFieldId(number)));
    }
    if (area.autoArmEnabled?.included) {
      setIncludedFields(setToggle(areaAutoArmFieldId(number)));
    }
    if (area.autoDisarmEnabled?.included) {
      setIncludedFields(setToggle(areaAutoDisarmFieldId(number)));
    }
    if (area.name?.included) {
      setIncludedFields(setToggle(areaNameFieldId(number)));
    }
    if (area.badZonesOption?.included) {
      setIncludedFields(setToggle(areaBadZonesFieldId(number)));
    }
    if (area.armedOutputNumber?.included) {
      setIncludedFields(setToggle(areaArmedOutputNumberFieldId(number)));
    }
    if (area.lateOutputNumber?.included) {
      setIncludedFields(setToggle(areaLateOutputNumberFieldId(number)));
    }
    if (area.lateArmDelay?.included) {
      setIncludedFields(setToggle(areaLateArmDelayFieldId(number)));
    }
    if (area.bankSafeVault?.included) {
      setIncludedFields(setToggle(areaBankSafeVaultFieldId(number)));
    }
    if (area.commonArea?.included) {
      setIncludedFields(setToggle(areaCommonAreaFieldId(number)));
    }
    if (area.armFirstArea?.included) {
      setIncludedFields(setToggle(areaArmFirstAreaFieldId(number)));
    }
    if (area.dualAuthority?.included) {
      setIncludedFields(setToggle(areaDualAuthorityFieldId(number)));
    }
    if (area.openClosingReports?.included) {
      setIncludedFields(setToggle(areaOpenClosingReportsFieldId(number)));
    }
    if (area.burglaryBellOutputNumber?.included) {
      setIncludedFields(setToggle(areaBurglaryBellOutputNumberFieldId(number)));
    }
    if (area.cardPlusPin?.included) {
      setIncludedFields(setToggle(areaCardPlusPinFieldId(number)));
    }
  });
};
