import { addClassIf } from "common/utils/web/css";
import { Flex } from "components/DaStyledElements";
import * as R from "ramda";
import * as React from "react";
import { Action, ActionType, PageSize, State } from "./usePaginationState";

export * from "./usePaginationState";

const ListItem = ({
  children,
  onClick,
  className = "",
  disabled,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}) => (
  <li className={className + (disabled ? " disabled" : "")}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      href=""
      onClick={(event) => {
        event.preventDefault();
        onClick && onClick();
      }}
    >
      {children}
    </a>
  </li>
);

export const Paginator = <A extends any>({
  state,
  dispatch,
  maxPage,
}: {
  state: State<A>;
  dispatch: (action: Action<A>) => void;
  maxPage: number;
}) => {
  const setCurrentPage = (page: number) =>
    dispatch({
      type: ActionType.SetCurrentPage,
      currentPage: page,
    });

  const { currentPage } = state;

  return (
    <div>
      {maxPage > 1 ? (
        <ul className="pagination">
          {/* Left Arrow */}
          <ListItem
            onClick={() => setCurrentPage(R.clamp(1, maxPage, currentPage - 1))}
            className={currentPage <= 1 ? "disabled" : ""}
          >
            &lsaquo;
          </ListItem>

          {/* first page */}
          <ListItem
            className={addClassIf("active", currentPage === 1)}
            onClick={() => setCurrentPage(1)}
          >
            1
          </ListItem>

          {/* second page */}
          {(() => {
            switch (true) {
              case maxPage <= 1:
                return null;
              case maxPage <= 5:
              case currentPage <= 3:
                return (
                  <ListItem
                    onClick={() => setCurrentPage(2)}
                    className={addClassIf("active", currentPage === 2)}
                  >
                    2
                  </ListItem>
                );
              case currentPage >= 4:
                return <ListItem disabled>...</ListItem>;
              default:
                return null;
            }
          })()}

          {/* current page */}
          {(() => {
            switch (true) {
              case maxPage <= 2:
                return null;
              case currentPage >= maxPage - 2 && maxPage > 5:
                return (
                  <ListItem
                    onClick={() => setCurrentPage(maxPage - 2)}
                    className={addClassIf(
                      "active",
                      currentPage === maxPage - 2
                    )}
                  >
                    {maxPage - 2}
                  </ListItem>
                );
              case currentPage <= 3:
              case maxPage <= 5:
                return (
                  <ListItem
                    onClick={() => setCurrentPage(3)}
                    className={addClassIf("active", currentPage === 3)}
                  >
                    3
                  </ListItem>
                );
              case currentPage >= 3:
                return <ListItem className="active">{currentPage}</ListItem>;
              default:
                return null;
            }
          })()}

          {/* second to last page */}
          {(() => {
            switch (true) {
              case maxPage <= 3:
                return null;
              case maxPage <= 5:
                return (
                  <ListItem
                    onClick={() => setCurrentPage(4)}
                    className={addClassIf("active", currentPage === 4)}
                  >
                    4
                  </ListItem>
                );
              case currentPage >= maxPage - 2:
                return (
                  <ListItem
                    onClick={() => setCurrentPage(maxPage - 1)}
                    className={addClassIf(
                      "active",
                      currentPage === maxPage - 1
                    )}
                  >
                    {maxPage - 1}
                  </ListItem>
                );
              case currentPage < maxPage - 2:
                return <ListItem disabled>...</ListItem>;
              default:
                return null;
            }
          })()}

          {(() => {
            switch (true) {
              case maxPage <= 4:
                return null;
              case true:
                return (
                  <ListItem
                    onClick={() => setCurrentPage(maxPage)}
                    className={addClassIf("active", currentPage === maxPage)}
                  >
                    {maxPage}
                  </ListItem>
                );
              default:
                return null;
            }
          })()}

          {/*RT Arrow */}
          <ListItem
            onClick={() => setCurrentPage(R.clamp(1, maxPage, currentPage + 1))}
            className={currentPage >= maxPage ? "disabled" : ""}
          >
            &rsaquo;
          </ListItem>
        </ul>
      ) : null}
    </div>
  );
};

export const ItemsOfItemCount = ({
  items,
  itemCount,
}: {
  items: number;
  itemCount: number;
}) => {
  return (
    <Flex.Row align="center">
      <span className="mar-r-8">{`Showing: ${items} of ${itemCount}`}</span>
    </Flex.Row>
  );
};

export const ItemsPerPage = <A extends any>({
  state,
  dispatch,
}: {
  state: State<A>;
  dispatch: (action: Action<A>) => void;
}) => {
  return (
    <Flex.Row align="center">
      <span className="mar-r-8">Rows:</span>
      <select
        value={state.pageSize}
        className="form-control"
        onChange={(event) => {
          dispatch({
            type: ActionType.SetPageSize,
            pageSize: Number(event.target.value) as PageSize,
          });
        }}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </Flex.Row>
  );
};

export default Paginator;
