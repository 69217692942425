/**
 * @generated SignedSource<<6ceaaa916d4e533aaa052af672f4ef65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XTSystemReportsProgrammingConceptFormInline_controlSystem$data = {
  readonly id: string;
  readonly panel: {
    readonly id: string;
    readonly systemReports: {
      readonly abortReports: boolean;
      readonly accessKeypads: string;
      readonly ambushReports: boolean;
      readonly bypassReports: boolean;
      readonly codeChangeReports: boolean;
      readonly earlyToClose: number;
      readonly earlyToCloseMax: number;
      readonly earlyToCloseMin: number;
      readonly entryCheckinProtection: boolean;
      readonly id: string;
      readonly lateToOpen: number;
      readonly lateToOpenMax: number;
      readonly lateToOpenMin: number;
      readonly openCloseEnable: boolean;
      readonly scheduleChangeReports: boolean;
      readonly sendStoredMessage: boolean;
      readonly videoSystemReports: boolean;
      readonly zoneRestoralReports: SystemReportsZoneRestoralReports;
    } | null;
  };
  readonly " $fragmentType": "XTSystemReportsProgrammingConceptFormInline_controlSystem";
};
export type XTSystemReportsProgrammingConceptFormInline_controlSystem$key = {
  readonly " $data"?: XTSystemReportsProgrammingConceptFormInline_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XTSystemReportsProgrammingConceptFormInline_controlSystem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XTSystemReportsProgrammingConceptFormInline_controlSystem"
};

(node as any).hash = "e050a6eb9487bdb2c228734a8c6ecf65";

export default node;
