import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsSelectiveSilenceField_bellOptions$key } from "./__generated__/BellOptionsSelectiveSilenceField_bellOptions.graphql";

export const bellOptionsSelectiveSilenceFieldId = () =>
  "bell-options-selective-silence";

function BellOptionsSelectiveSilenceField() {
  const [{ selectiveSilence }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsSelectiveSilenceField_bellOptions$key>(
      graphql`
        fragment BellOptionsSelectiveSilenceField_bellOptions on BellOptions {
          id
          selectiveSilence
        }
      `
    );

  const fieldId = bellOptionsSelectiveSilenceFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Selective Silence"
      tooltip="Applies to NAC circuits 1 and 2 using non-DMP sync protocols: If enabled, Alarm Silence turns off bells and horns, then Sensor Reset turns off strobes. If disabled, Alarm Silence turns off bells, horns, and strobes."
    >
      <Switch
        label="Selective Silence"
        id={fieldId}
        checked={Boolean(selectiveSilence)}
        onChange={() => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(!selectiveSilence, "selectiveSilence");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsSelectiveSilenceField;
