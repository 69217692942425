import MassProgrammingGlobalHolidayDatesJobNew from "components/MassProgrammingGlobalHolidayDatesJobNew";
import { react2angular } from "react2angular";

App.component(
  "massProgrammingGlobalHolidayDatesJobNew",
  react2angular(
    MassProgrammingGlobalHolidayDatesJobNew,
    [],
    ["$stateParams", "RelayService", "$rootScope", "$state"]
  )
);
