/**
 * This service handles retrieving and preparing data for the Dealer Dashboard charts
 */
App.service("BusinessAnalyticsService", [
  "$q",
  "BusinessAnalyticsODataAPI",
  "DealerDashboardAPI",
  "DealerDashboardBrainierAPI",
  "DealerDashboardSalespersonODataAPI",
  "SalespersonTerritoriesODataAPI",
  "DealersBySalesTerritoryAPI",
  "OdataPageService",
  "UserService",
  function (
    $q,
    BusinessAnalyticsODataAPI,
    DealerDashboardAPI,
    DealerDashboardBrainierAPI,
    DealerDashboardSalespersonODataAPI,
    SalespersonTerritoriesODataAPI,
    DealersBySalesTerritoryAPI,
    OdataPageService,
    UserService
  ) {
    var _this = this;

    //BusinessAnalyticsODataAPI
    _this.getAccountOverviewData = getAccountOverviewData;
    _this.getCellularData = getCellularData;
    _this.getVirtualKeypadData = getVirtualKeypadData;
    _this.getSystemsData = getSystemsData;
    //DealerDashboardApi
    _this.getDealerInformationData = getDealerInformationData;
    _this.getDealerLevelData = getDealerLevelData;
    _this.getMonthlySalesHistoryData = getMonthlySalesHistoryData;
    _this.getTopTenProductsPurchasedData = getTopTenProductsPurchasedData;
    _this.getSalesOverviewData = getSalesOverviewData;
    _this.getNewProductsData = getNewProductsData;
    _this.getSecurecomStatsData = getSecurecomStatsData;
    _this.getDoorsTotal = getDoorsTotal;
    // DealerDashboardSalespersonODataAPI
    _this.getSalespersonsData = getSalespersonsData;
    _this.getSalespersonsTerritories = getSalespersonsTerritories;
    //DealersBySalesTerritoryAPI
    _this.getFilteredDealerList = getFilteredDealerList;
    _this.getBrainierTrainingData = getBrainierTrainingData;

    /**
     * Get getFilteredDealerList (dealer_users)
     *
     */
    function getFilteredDealerList() {
      var deferred = $q.defer();
      DealersBySalesTerritoryAPI.getFilteredDealerList(
        {},
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getSalespersonsData() {
      var deferred = $q.defer();
      DealerDashboardSalespersonODataAPI.getSalespersonsData(
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getSalespersonsTerritories(select, filter) {
      const undesiredTerritories = new Set(["DSALC", "DSALE", "DSALW", "EDOS"]);
      var deferred = $q.defer();
      SalespersonTerritoriesODataAPI.getSalespersonsTerritories(
        function (data) {
          deferred.resolve(
            data.value.filter(({ Territory }) => {
              return !undesiredTerritories.has(Territory);
            })
          );
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getDealerInformationData(dealerID) {
      var deferred = $q.defer();
      DealerDashboardAPI.getDealerInformationData(
        { dealer_id: dealerID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getBrainierTrainingData(dealerId) {
      var deferred = $q.defer();
      DealerDashboardBrainierAPI.getBrainierTrainingData(
        { dealer_id: dealerId },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getDealerLevelData() {
      var deferred = $q.defer();
      DealerDashboardAPI.getDealerLevelData(
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getMonthlySalesHistoryData(dealerID) {
      var deferred = $q.defer();
      DealerDashboardAPI.getMonthlySalesHistoryData(
        { dealer_id: dealerID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getTopTenProductsPurchasedData(dealerID) {
      var deferred = $q.defer();
      DealerDashboardAPI.getTopTenProductsPurchasedData(
        { dealer_id: dealerID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getSalesOverviewData(dealerID) {
      var deferred = $q.defer();
      DealerDashboardAPI.getSalesOverviewData(
        { dealer_id: dealerID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
    function getNewProductsData(dealerID) {
      var deferred = $q.defer();
      DealerDashboardAPI.getNewProductsData(
        { dealer_id: dealerID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getSecurecomStatsData(dealerID) {
      var deferred = $q.defer();
      DealerDashboardAPI.getSecurecomStatsData(
        { dealer_id: dealerID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
    function getDoorsTotal(dealerID) {
      var deferred = $q.defer();
      DealerDashboardAPI.getDoorsTotal(
        { dealer_id: dealerID },
        function (data) {
          deferred.resolve(data);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }

    function getAccountOverviewData(getTotals) {
      var deferred = $q.defer();
      var oDataPageService = new OdataPageService();
      var includeYtdTotal = true;
      var params = {
        dealer_id: UserService.dealer_id,
        $filter: getODataFilter(includeYtdTotal),
      };

      var analyticsData;

      if (getTotals) {
        analyticsData =
          BusinessAnalyticsODataAPI.getAccountOverviewTotalsData(
            params
          ).$promise;
      } else {
        analyticsData =
          BusinessAnalyticsODataAPI.getAccountOverviewData(params).$promise;
      }

      oDataPageService
        .getAllPages(analyticsData)
        .then(
          function (data) {
            continuousData = assureContinuity(data, includeYtdTotal);
            deferred.resolve(continuousData);
          },
          function (error) {
            console.error(
              "BusinessAnalyticsService->getAccountOverviewData() - error: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(
            "BusinessAnalyticsService->getAccountOverviewData() - caught error: " +
              angular.toJson(error)
          );
        });
      return deferred.promise;
    }

    function getCellularData(getTotals) {
      var deferred = $q.defer();
      var oDataPageService = new OdataPageService();
      var params = {
        dealer_id: UserService.dealer_id,
        $filter: getODataFilter(),
      };

      var analyticsData;
      let dealerData;

      if (dealerData) {
        dealerData =
          DealerDashboardAPI.getDealerInformationData(params).$promise;
      }

      if (getTotals) {
        analyticsData =
          BusinessAnalyticsODataAPI.getCellularTotalsData(params).$promise;
      } else {
        analyticsData =
          BusinessAnalyticsODataAPI.getCellularData(params).$promise;
      }

      oDataPageService
        .getAllPages(analyticsData)
        .then(
          function (data) {
            continuousData = assureContinuity(data);
            deferred.resolve(continuousData);
          },
          function (error) {
            console.error(
              "BusinessAnalyticsService->getCellularData() - error: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(
            "BusinessAnalyticsService->getCellularData() - caught error: " +
              angular.toJson(error)
          );
        });
      return deferred.promise;
    }

    function getVirtualKeypadData(getTotals) {
      var deferred = $q.defer();
      var oDataPageService = new OdataPageService();
      var params = {
        dealer_id: UserService.dealer_id,
        $filter: getODataFilter(),
      };

      var analyticsData;
      let dealerData;

      if (getTotals) {
        analyticsData =
          BusinessAnalyticsODataAPI.getVirtualKeypadTotalsData(params).$promise;
      } else {
        analyticsData =
          BusinessAnalyticsODataAPI.getVirtualKeypadData(params).$promise;
      }

      oDataPageService
        .getAllPages(analyticsData)
        .then(
          function (data) {
            continuousData = assureContinuity(data);
            deferred.resolve(continuousData);
          },
          function (error) {
            console.error(
              "BusinessAnalyticsService->getVirtualKeypadData() - error: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(
            "BusinessAnalyticsService->getVirtualKeypadData() - caught error: " +
              angular.toJson(error)
          );
        });
      return deferred.promise;
    }

    function getSystemsData(getTotals) {
      var deferred = $q.defer();
      var oDataPageService = new OdataPageService();
      var params = {
        dealer_id: UserService.dealer_id,
        $filter: getODataFilter(),
      };

      var analyticsData;

      if (getTotals) {
        analyticsData =
          BusinessAnalyticsODataAPI.getSystemsTotalsData(params).$promise;
      } else {
        analyticsData =
          BusinessAnalyticsODataAPI.getSystemsData(params).$promise;
      }

      oDataPageService
        .getAllPages(analyticsData)
        .then(
          function (data) {
            continuousData = assureContinuity(data);
            deferred.resolve(continuousData);
          },
          function (error) {
            console.error(
              "BusinessAnalyticsService->getSystemsData() - error: " +
                angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(
            "BusinessAnalyticsService->getSystemsData() - caught error: " +
              angular.toJson(error)
          );
        });
      return deferred.promise;
    }

    /**
     *
     * @param {bool} [includeYtdTotal|false] - include the year-to-date totals object
     */
    function getODataFilter(includeYtdTotal) {
      var today = new Date();
      // Always include the seed data (year 0) because there may not be any other data
      var filter = "year eq 0 or (year eq " + today.getFullYear();
      filter += includeYtdTotal ? ")" : " and month gt 0)";
      // If it's January, get december if last month is needed
      if (today.getMonth() === 0) {
        var lastYear = today.getFullYear() - 1;
        filter += " or (year eq " + lastYear + " and month eq 12)";
      }
      return filter;
    }

    /**
     * The results from OData can have gaps in the months if there is no activity. For example,
     * if a dealer doesn't sell any systems in February but did in January and March. We can't
     * show a chart with Jan and Mar data points without a Feb. This function creates dummy
     * objects to fill in the gaps.
     * @param {[object]} data - array of business analytics objects
     * @param {bool} [includeYtdTotal|false] - year-to-date totals object is expected
     */
    function assureContinuity(data, includeYtdTotal) {
      var continuousData = [];
      if (Array.isArray(data) && data.length > 0) {
        var today = new Date();
        // Create a template object to fill in any missing months
        var template = getTemplateObject(data[0], today.getFullYear());
        // Add the seed to the response
        var seed = data.find(function (o) {
          return o.year === 0 && o.month === 0;
        });
        continuousData.push(seed);
        // If there's a ytd total, add it to the response
        var ytd = data.find(function (o) {
          return o.year === today.getFullYear() && o.month === 0;
        });
        if (ytd) {
          continuousData.push(ytd);
        }
        // If there was no object in the response but it's needed for the chart, create a dummy
        else if (includeYtdTotal) {
          continuousData.push(getDummy(template, 0));
        }
        var ytdMonths = data.filter(function (o) {
          return o.month !== 0;
        });
        // Get a list of all of the months indices (1-indexed) in order
        var monthIndices = ytdMonths
          .map((d) => d.month)
          .filter(function (i) {
            return i > 0;
          })
          .sort();
        // If it's January and you have a December object, it's from last year
        if (today.getMonth() === 0 && monthIndices.includes(12)) {
          // Add the December object to the response and remove it from the month indices
          continuousData.push(
            ytdMonths.find(function (o) {
              return o.year === today.getFullYear() - 1 && o.month === 12;
            })
          );
          monthIndices.splice(monthIndices.indexOf(12));
        }
        // There should be an object for each month from month 1 (January) to the current month
        for (var i = 1; i <= today.getMonth() + 1; i++) {
          if (monthIndices.includes(i)) {
            continuousData.push(
              ytdMonths.find(function (o) {
                return o.month === i;
              })
            );
          } else {
            continuousData.push(getDummy(template, i));
          }
        }
      }
      return continuousData;
    }

    function getTemplateObject(dataObject, thisYear) {
      var template = Object.assign({}, dataObject);
      for (var key in template) {
        template[key] = 0;
      }
      template.year = thisYear;
      template.dealer_id = UserService.dealer_id;
      return template;
    }

    function getDummy(template, monthIndex) {
      var dummy = Object.assign({}, template);
      dummy.month = monthIndex;
      return dummy;
    }
  },
]);
