import { defaultTheme } from "app/common/DaColors";
import DmpIcon from "common/components/DmpIcon";
import React from "react";
import styled from "styled-components";

type Props = {
  mpCount: number;
  megapixelThresholdCritical: boolean;
  supportedMegapixels: number;
  unsupportedFrameRate: boolean;
  unsupportedCodec: boolean;
  confirm: () => void;
  cancel: () => void;
};

const ConfirmationModal = ({
  mpCount,
  megapixelThresholdCritical,
  supportedMegapixels,
  unsupportedFrameRate,
  unsupportedCodec,
  confirm,
  cancel,
}: Props) => (
  <div>
    <Backdrop />
    <Container>
      <Modal>
        <Header>
          <DmpIcon icon="attention" size="2.4rem" constrainToParent={false} />
          <div>You May Experience Performance Issues</div>
        </Header>
        <Body>
          <div>
            You have enabled cameras that exceed the recommended camera
            settings:
          </div>
          <Warnings>
            <div style={{ fontWeight: "600" }}>Warning:</div>
            <WarningDetails>
              <MegapixelLimitWarning>
                <DmpIcon
                  icon="screen_resize"
                  size="1.6rem"
                  color={
                    megapixelThresholdCritical
                      ? defaultTheme.danger
                      : defaultTheme.caution
                  }
                  constrainToParent={false}
                />
                <div>
                  {mpCount > supportedMegapixels
                    ? "Maximum megapixel limit exceeded. "
                    : "Megapixel count is approaching limit. "}
                  <span
                    style={{
                      color: megapixelThresholdCritical
                        ? defaultTheme.danger
                        : "inherit",
                    }}
                  >
                    {mpCount}/{supportedMegapixels} MP enabled.
                  </span>
                </div>
              </MegapixelLimitWarning>
              {unsupportedFrameRate && (
                <UnsupportedFrameRate>
                  <DmpIcon
                    icon="gauge"
                    size="1.6rem"
                    color={defaultTheme.danger}
                    constrainToParent={false}
                  />
                  Unsupported frame rate selected. Ensure all cameras are
                  between 5 and 15 FPS.
                </UnsupportedFrameRate>
              )}
              {unsupportedCodec && (
                <UnsupportedCodec>
                  <DmpIcon
                    icon="clip"
                    size="1.6rem"
                    color={defaultTheme.danger}
                    constrainToParent={false}
                  />
                  Unsupported codec selected. Ensure all cameras are H.264.
                </UnsupportedCodec>
              )}
            </WarningDetails>
          </Warnings>
          <div style={{ marginTop: "2rem" }}>
            You may experience performance issues. Do you wish to continue?
          </div>
        </Body>
        <Footer>
          <button className="btn btn-default btn-sm" onClick={confirm}>
            Continue
          </button>
          <button className="btn btn-dmp btn-sm" onClick={cancel}>
            Back
          </button>
        </Footer>
      </Modal>
    </Container>
  </div>
);

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.5;
  border-radius: 5px;
  z-index: 10;
`;
const Container = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 20;
`;
const Modal = styled.div`
  min-width: 20rem;
  min-height: 10rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 1.6rem;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 2rem;
`;
const Body = styled.div`
  font-size: 1.4rem;
  margin-top: 1.6rem;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2.4rem;
`;
const Warnings = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  margin-top: 2.4rem;
`;
const WarningDetails = styled.div`
  gap: 0.4rem;
  margin-bottom: 0.4rem;
`;
const MegapixelLimitWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0.4rem;
`;
const UnsupportedFrameRate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0.4rem;
`;
const UnsupportedCodec = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export default ConfirmationModal;
