/**
 * @generated SignedSource<<7c7a1dc61d07b817996e1d0215b261ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunicationPathTestFrequencyUnit = "DAYS" | "HOURS";
export type CommunicationPathTestReport = "DEFER" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type CommPathTestFrequencyUnitField_communicationPath$data = {
  readonly number: number;
  readonly testFrequencyUnit: CommunicationPathTestFrequencyUnit;
  readonly testReport: CommunicationPathTestReport;
  readonly " $fragmentType": "CommPathTestFrequencyUnitField_communicationPath";
};
export type CommPathTestFrequencyUnitField_communicationPath$key = {
  readonly " $data"?: CommPathTestFrequencyUnitField_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathTestFrequencyUnitField_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommPathTestFrequencyUnitField_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testFrequencyUnit",
      "storageKey": null
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "143013343ed984fecb9af46f54efaa9e";

export default node;
