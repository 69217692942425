import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { BellActions } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useBellOptionsFragment } from "./BellOptionsContext";
import { BellOptionsBurglaryBellActionField_bellOptions$key } from "./__generated__/BellOptionsBurglaryBellActionField_bellOptions.graphql";

export const bellOptionsBurglaryBellActionFieldId = () =>
  "bell-options-burglary-bell-action";

function BellOptionsBurglaryBellActionField() {
  const [{ burglaryBellAction }, updateBellOptions] =
    useBellOptionsFragment<BellOptionsBurglaryBellActionField_bellOptions$key>(
      graphql`
        fragment BellOptionsBurglaryBellActionField_bellOptions on BellOptions {
          burglaryBellAction
        }
      `
    );

  const fieldId = bellOptionsBurglaryBellActionFieldId();
  const softwareVersion = useSoftwareVersion();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Burglary Bell Action"
      tooltip="Set the cadence the Bell Output uses for alarms on Day, Night or Exit Zone types."
    >
      <Select
        id={fieldId}
        value={burglaryBellAction}
        onChange={({ target }) => {
          updateBellOptions((recordProxy) => {
            recordProxy.setValue(target.value, "burglaryBellAction");
          });
        }}
      >
        <Select.Option value={BellActions.STEADY}>Steady</Select.Option>
        <Select.Option value={BellActions.PULSE}>Pulse</Select.Option>
        <Select.Option value={BellActions.TEMPORAL}>Temporal</Select.Option>
        <Select.Option value={BellActions.NONE}>None</Select.Option>
        {softwareVersion >= 183 && (
          <>
            <Select.Option value={BellActions.TEMPORAL_3}>
              Temporal 3
            </Select.Option>
            <Select.Option value={BellActions.TEMPORAL_4}>
              Temporal 4
            </Select.Option>
          </>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default BellOptionsBurglaryBellActionField;
