import styled from "styled-components";

const SecondaryFields = styled.div`
  width: 100%;
  margin-top: var(--measure-1x);
  padding: var(--measure-half) var(--measure-12);
  border-radius: 0.6rem;
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-100);
`;

export default SecondaryFields;
