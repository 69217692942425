import { HikDoorbellStates } from "./cs-hik-doorbell-states";
import { HikDoorbellUpdateStatus } from "./cs-hik-doorbell-update-status";
/**
 * Provides utilities for dealing with Hikvision doorbells and firmware updates.
 * Stores doorbell information for a control system.
 */
export class HikDoorbellUtil {
  /**
   * Initializes the instance.
   * @param {*} $log The logger.
   * @param {*} thirdPartyVideoService The third-party video service.
   */
  constructor($log, thirdPartyVideoService) {
    this.$log = $log;
    this.thirdPartyVideoService = thirdPartyVideoService;

    /* ----------------------------------------------------
    PROPERTIES
    ---------------------------------------------------- */

    /** Array of doorbell devices. */
    this.devices = [];

    /** Object that has the update status for each device; accessed by device id. */
    this.updates = {};
  }

  /* ------------------------------------------------------
  METHODS
  ------------------------------------------------------ */

  /**
   * Checks the third-party video API to see if a firmware update is available
   * for a device.
   * @param {*} device The device to check.
   */
  checkForUpdates(device) {
    // Get status info
    let updateStatus = this.updates[device.id];

    // Save the previous state
    let hadError = updateStatus.isError;
    let previousState = updateStatus.state;

    // Notify user we are checking for updates
    updateStatus.setState(HikDoorbellStates.CHECKING);

    // Call API to check current firmware status and whether an update is available
    return this.thirdPartyVideoService.hikvisionDoorbells
      .getFirmwareInfo(device.id, device.control_system_id)
      .then((data) => {
        updateStatus.isUpdateAvailable = data.UpgradeAvailable;
        device.firmware_version = data.CurrentVersion;

        if (hadError) {
          // There was an error previously, so restore that error state
          updateStatus.setState(previousState);
        } else if (updateStatus.isUpdateAvailable) {
          // A firmware update is available and has not been started
          updateStatus.setState(HikDoorbellStates.OUT_OF_DATE);
        } else {
          // Doorbell has the latest firmware
          updateStatus.setState(HikDoorbellStates.UP_TO_DATE);
        }
      });
  }

  /**
   * Gets the current firmware update status from the third-party video API.
   * @param {*} device The device to check.
   * @returns A promise.
   */
  checkUpdateStatus(device) {
    // Get status info
    let updateStatus = this.updates[device.id];

    // Call API to check update status
    return this.thirdPartyVideoService.hikvisionDoorbells
      .getFirmwareUpgradeStatus(device.id, device.control_system_id)
      .then((response) => {
        // Get possible status codes returned from the API
        let statusCodes =
          this.thirdPartyVideoService.hikvisionDoorbells.firmwareStatusCodes;

        // Update progress from API
        updateStatus.updateStatusCode = response.Status.Code;
        updateStatus.progress = response.Progress;

        // Set update status based on the API status code
        switch (updateStatus.updateStatusCode) {
          case statusCodes.UPGRADING:
            updateStatus.setState(HikDoorbellStates.UPDATING);
            break;
          case statusCodes.DEVICE_REBOOT:
            updateStatus.setState(HikDoorbellStates.REBOOTING);
            break;
          case statusCodes.UPGRADED:
            updateStatus.setState(HikDoorbellStates.UP_TO_DATE);
            break;
          case statusCodes.FAILED:
            updateStatus.setState(HikDoorbellStates.ERROR_FAILED);
            break;
          default:
            updateStatus.setState(HikDoorbellStates.ERROR_UNKNOWN);
            break;
        }
      })
      .catch((error) => {
        // The API returns a 500 if the device is offline
        if (
          error.status == 500 &&
          error.data &&
          error.data.errors &&
          error.data.errors[0].sub_topic ==
            "EZVIZ message: The device is offline."
        ) {
          // Check if the device was previously rebooting. After an update, the device
          // reboots. Sometimes there is a period of time after the reboot before the device
          // starts reporting that it is online.
          if (updateStatus.state == HikDoorbellStates.REBOOTING) {
            // Do nothing
          } else {
            updateStatus.setState(HikDoorbellStates.ERROR_OFFLINE);
          }
        } else {
          updateStatus.setState(HikDoorbellStates.ERROR_UNKNOWN);
        }
      });
  }

  /**
   * Monitors the progress of a firmware update (if one is in progress).
   * @param {*} device The doorbell.
   */
  async monitorUpdate(device) {
    // Get last known status info
    let updateStatus = this.updates[device.id];

    // Loop until update is finished or an error happens
    do {
      // Call API to get latest update status
      await this.checkUpdateStatus(device);

      // Update still in progress - wait, then check again
      if (updateStatus.isInProgress) {
        await new Promise((r) => setTimeout(r, 1000));
      }
    } while (updateStatus.isInProgress);

    // Recheck the firmware update availability - after update, need to get current firmware version
    await this.checkForUpdates(device).catch((error) => {
      updateStatus.setState(HikDoorbellStates.ERROR_UNKNOWN);
    });
  }

  /**
   * Refreshes the list of Hikvision doorbell devices.
   * @param {*} controlSystemId The control system id.
   */
  refreshDeviceList(controlSystemId) {
    // Get doorbells from Third-Party Video API
    return this.thirdPartyVideoService
      .getDevicesByManufacturer(
        "hikvision",
        controlSystemId,
        "HIKVISION-DOORBELL"
      )
      .then((data) => {
        // Store device list
        this.devices = data;
        this.updates = {};

        // Init update status for each doorbell
        for (let i = 0; i < data.length; ++i) {
          let device = data[i];
          this.updates[device.id] = new HikDoorbellUpdateStatus(
            this.$log,
            this.thirdPartyVideoService
          );
        }

        return data;
      });
  }

  /**
   * Refreshes the update status.
   * @param {*} device The device.
   */
  refreshUpdateStatus(device) {
    // Get last known status info
    let updateStatus = this.updates[device.id];

    // Set an initial status to tell user we are busy doing something
    updateStatus.setState(HikDoorbellStates.CHECKING);

    // Start monitoring - this will check if an update is already in progress and
    // will check if an update is available.
    this.monitorUpdate(device);
  }

  /**
   * Requests the firmware update to start.
   * @param {*} device The device to update.
   */
  startUpdate(device) {
    // Get last update status and update state
    let updateStatus = this.updates[device.id];
    updateStatus.setState(HikDoorbellStates.UPDATING);
    updateStatus.progress = 0;

    // Call third-party video API to initiate a firmware update
    return this.thirdPartyVideoService.hikvisionDoorbells
      .initiateFirmwareUpgrade(device.id)
      .then(async (_) => {
        // Update started, Wait 5 seconds before monitoring the update's progress as hikvision will often not update the status immediately
        await new Promise((r) => setTimeout(r, 5000));
        this.monitorUpdate(device);
      })
      .catch((error) => {
        updateStatus.setState(HikDoorbellStates.ERROR_UNKNOWN);
      });
  }
}
