import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { RemoteOptionsConnectionType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useRemoteOptionsFragment } from "./RemoteOptionsContext";
import { RemoteOptionsEntreBackupConnectionField_remoteOptions$key } from "./__generated__/RemoteOptionsEntreBackupConnectionField_remoteOptions.graphql";

export const remoteOptionsEntreBackupConnectionFieldId = () =>
  "remote-options-entre-backup-connection";

function RemoteOptionsEntreBackupConnectionField() {
  const [{ entreConnection, entreBackupConnection }, updateRemoteOptions] =
    useRemoteOptionsFragment<RemoteOptionsEntreBackupConnectionField_remoteOptions$key>(
      graphql`
        fragment RemoteOptionsEntreBackupConnectionField_remoteOptions on RemoteOptions {
          ... on XrRemoteOptions {
            entreBackupConnection
            entreConnection
          }
          ... on XfRemoteOptions {
            entreBackupConnection
            entreConnection
          }
        }
      `
    );

  const fieldId = remoteOptionsEntreBackupConnectionFieldId();
  const disabled = entreConnection === RemoteOptionsConnectionType.NONE;
  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entré Backup Connection"
      disabled={disabled}
    >
      <Select
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        value={entreBackupConnection ?? undefined}
        onChange={({ target }) => {
          updateRemoteOptions((recordProxy) => {
            recordProxy.setValue(target.value, "entreBackupConnection");
            if (target.value !== RemoteOptionsConnectionType.NETWORK) {
              recordProxy.setValue(
                "0000:0000:0000:0000:0000:0000:0000:0000",
                "entreBackupIpv6Address"
              );
            }
          });
        }}
      >
        <Select.Option value={RemoteOptionsConnectionType.NONE}>
          None
        </Select.Option>
        <Select.Option value={RemoteOptionsConnectionType.NETWORK}>
          Network
        </Select.Option>
        {!isXf ? (
          <Select.Option value={RemoteOptionsConnectionType.CELL}>
            Cell
          </Select.Option>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default RemoteOptionsEntreBackupConnectionField;
