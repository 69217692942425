import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommunicationFragment } from "./CommunicationContext";
import { CommunicationDialerTestDaysField_communication$key } from "./__generated__/CommunicationDialerTestDaysField_communication.graphql";

export const communicationDialerTestDaysFieldId = () =>
  "communication-dialer-test-days";

function CommunicationDialerTestDaysField() {
  const [
    { comType, dialerTestDays, dialerTestDaysMin, dialerTestDaysMax },
    updateCommunication,
  ] = useCommunicationFragment<CommunicationDialerTestDaysField_communication$key>(
    graphql`
      fragment CommunicationDialerTestDaysField_communication on Communication {
        comType
        dialerTestDays
        dialerTestDaysMin
        dialerTestDaysMax
      }
    `
  );

  const fieldId = communicationDialerTestDaysFieldId();
  const { current: originalValue } = React.useRef(dialerTestDays);
  const disabled = ["CELL"].includes(comType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={"Dialer Test Days"}
      tooltip="Number of days the System waits between communication tests to the Receiver. (performed at the programmed Test Time)"
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={dialerTestDays}
        required
        disabled={disabled}
        min={dialerTestDaysMin}
        max={dialerTestDaysMax}
        inlineHelp={`${dialerTestDaysMin}–${dialerTestDaysMax}`}
        onChange={({ target }) => {
          updateCommunication((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "dialerTestDays"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommunication((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(dialerTestDaysMin, dialerTestDaysMax, valueAsNumber);
            recordProxy.setValue(Number(value), "dialerTestDays");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommunicationDialerTestDaysField;
