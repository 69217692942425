/**
 * @generated SignedSource<<85a6736645f0a08f03260c92d800a0f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VarStatusBadge_Puck_varHub$data = {
  readonly id: string;
  readonly isOnline: boolean;
  readonly " $fragmentType": "VarStatusBadge_Puck_varHub";
};
export type VarStatusBadge_Puck_varHub$key = {
  readonly " $data"?: VarStatusBadge_Puck_varHub$data;
  readonly " $fragmentSpreads": FragmentRefs<"VarStatusBadge_Puck_varHub">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./VarStatusBadge_Puck_varHubQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "VarStatusBadge_Puck_varHub",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "VarHub",
  "abstractKey": null
};

(node as any).hash = "f070edefd8fa5f6f9d2aa9a8afdcdeeb";

export default node;
