import { App } from "app-module";
import { getRelayEnvironment } from "common/relay";
import {
  CacheConfig,
  commitMutation as commitMutationToRelay,
  Environment,
  fetchQuery,
  GraphQLTaggedNode,
  MutationParameters,
  OperationType,
  Variables,
} from "relay-runtime";
import { getSecureComEnv } from "utils/env";

function RelayService(
  PROPS: any,
  UserService: any,
  UserSettingsService: { init(): void },
  $rootScope: any
) {
  const cache = new Map();

  function getOrResetEnvironment(resetEnvironment?: boolean) {
    const env = getSecureComEnv(PROPS.environment);
    const authToken = UserService.auth_token;

    if (!env) {
      throw new Error("RelayService requires an authenticated session.");
    }
    // doh! DA environment is different than VKB
    return getRelayEnvironment({
      env,
      authToken,
      cache,
      stagingBranch: process?.env.REACT_APP_CLIENT_STAGING_BRANCH,
      clientName: "Dealer Admin",
      clientVersion: process?.env.REACT_APP_VERSION,
      resetEnvironment,
      clientLoginFunction: login,
      clientLogoutFunction: logout,
      refreshToken: UserService.refresh_token,
      rootScope: $rootScope,
    }) as any as Environment;
  }

  function getEnvironment() {
    return getOrResetEnvironment();
  }

  function login(email: string, auth_token: string, refresh_token: string) {
    UserService.login(email, auth_token, refresh_token);
    UserSettingsService.init();
  }

  function logout() {
    getOrResetEnvironment(true);
    UserService.logout();
    UserSettingsService.init();
  }

  function query<T extends OperationType>(
    query: GraphQLTaggedNode,
    variables: Variables = {},
    config: CacheConfig = {}
  ) {
    const environment = getEnvironment();
    return fetchQuery<T>(environment, query, variables, config as any);
  }

  function commitMutation<T extends MutationParameters>(
    mutation: GraphQLTaggedNode,
    variables: T["variables"]
  ) {
    const environment = getEnvironment();
    return new Promise<T["response"]>((resolve, reject) => {
      commitMutationToRelay<T>(environment, {
        mutation,
        variables,
        onError: reject,
        onCompleted: resolve,
      });
    });
  }

  return { getEnvironment, query, commitMutation, logout };
}

export type RelayServiceType = ReturnType<typeof RelayService>;

App.factory("RelayService", [
  "PROPS",
  "UserService",
  "UserSettingsService",
  "$rootScope",
  RelayService,
]);
