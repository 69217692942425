import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemReportsFragment } from "./SystemReportsContext";
import { SystemReportsAbortReportsField_systemReports$key } from "./__generated__/SystemReportsAbortReportsField_systemReports.graphql";

export const systemReportsAbortReportsFieldId = () =>
  "system-reports-abort-reports";

function SystemReportsAbortReportsField() {
  const [{ abortReports }, updateSystemReports] =
    useSystemReportsFragment<SystemReportsAbortReportsField_systemReports$key>(
      graphql`
        fragment SystemReportsAbortReportsField_systemReports on SystemReports {
          abortReports
        }
      `
    );
  const fieldId = systemReportsAbortReportsFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Abort Reports"
      tooltip="Send an alarm abort report to the Receiver when the System is Disarmed before the Transmit Delay and Bell Cutoff expire."
    >
      <Switch
        label="Abort Reports"
        id={fieldId}
        name={fieldId}
        checked={abortReports}
        onChange={() => {
          updateSystemReports((recordProxy) => {
            recordProxy.setValue(!abortReports, "abortReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemReportsAbortReportsField;
