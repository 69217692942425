/**
 * Controller for the nested nearest techs UI on system information
 */
App.controller("NearestTechController", [
  "$scope",
  "dealerUser",
  "NearestTechService",
  "UserService",
  function ($scope, dealerUser, NearestTechService, UserService) {
    $scope.AnyNearby = false;
    $scope.nearestTechnician = new dealerUser();

    function init() {
      NearestTechService.retrieveNearestTech().then(function (technician) {
        if (technician.id) {
          // only set if there actually is a tech returned
          $scope.nearestTechnician
            .get(technician.id)
            .then()
            .catch(function (error) {
              console.error(error);
            });
          $scope.AnyNearby = true;
        }
      });
    }

    if (UserService.canViewTechLocator()) init();
  },
]);
