import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useStatusListDisplayFragment } from "./StatusListDisplayContext";
import { resolveStatusListDisplayRules } from "./utils";
import { StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay$key } from "./__generated__/StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay.graphql";

export const statusListDisplaySupervisoryZoneKeypadsFieldId = () =>
  "status-list-display-supervisory-zone-keypads";

function StatusListDisplaySupervisoryZoneKeypadsField() {
  const [{ supervisoryZoneKeypads }, updateStatusListDisplay] =
    useStatusListDisplayFragment<StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay$key>(
      graphql`
        fragment StatusListDisplaySupervisoryZoneKeypadsField_statusListDisplay on StatusListDisplay {
          supervisoryZoneKeypads
        }
      `
    );

  const fieldId = statusListDisplaySupervisoryZoneKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } =
    resolveStatusListDisplayRules(useHardwareModel());

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Supervisory Zone Keypads"
      tooltip="Configure which keypad addresses show Supervisory Zone Alarms and Troubles."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={supervisoryZoneKeypads}
        onChange={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "supervisoryZoneKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateStatusListDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "supervisoryZoneKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default StatusListDisplaySupervisoryZoneKeypadsField;
