App.controller("FirmwareWarningModalCtrl", [
  "$scope",
  "$modalInstance",
  "PanelProgArchiveService",
  "UserService",
  function ($scope, $modalInstance, PanelProgArchiveService, UserService) {
    $scope.firmware = {
      backup: {
        version: PanelProgArchiveService.cache.backup.data.FirmwareVersion,
        date: PanelProgArchiveService.cache.backup.data.FirmwareDate,
      },
      panel: {
        version: UserService.controlSystem.panels[0].software_version,
        date: UserService.controlSystem.panels[0].software_date,
      },
    };

    $scope.confirm = function () {
      $modalInstance.close("confirmed");
    };
    $scope.cancel = function () {
      $modalInstance.dismiss("cancelled");
    };
  },
]);
