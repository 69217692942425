/**
 * @generated SignedSource<<6ef1b8d0da6b3069f6603e362c46f9cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data = {
  readonly menuDisplay: {
    readonly armDisarmDisplayKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly armedStatusDisplayKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly timeDisplayKeypads: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts";
};
export type XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key = {
  readonly " $data"?: XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XRMenuDisplayProgrammingConceptFormInline_xrProgrammingTemplateConcepts"
};

(node as any).hash = "11662446bd322625f280d28e802ea903";

export default node;
