/**
 * @ngdoc service
 * @name App.factory:ModemUpgradeCustomersService
 *
 * @description
 * Holds the API and methods for the Modem Upgrade Customers
 *
 */
App.factory("ModemUpgradeCustomersService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "ModemUpgradeCustomersAPI",
  "UserService",
  "IpResolverService",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    ModemUpgradeCustomersAPI,
    UserService,
    IpResolverService
  ) {
    return {
      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeCustomersService
       *
       * @description
       * Gets all customers that have been selected by dealer to participate in the upgrade program.
       *
       * @param {Promise} data- promise response
       */
      getModemUpgradeCustomers: function (dealer_id) {
        var deferred = $q.defer();
        ModemUpgradeCustomersAPI.getModemUpgradeCustomers(
          {
            dealer_id: dealer_id,
          }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeCustomersService
       *
       * @description
       * Gets all customers who have a panel with 'CDMA' or 'HSPA' in the modem_standard field.
       * 'CDMA' and 'HSPA' indicate the panel has a 3g modem.
       *
       * @param {Promise} data- promise response
       */
      getModemUpgradeQualifiedCustomers: function (dealer_id) {
        var deferred = $q.defer();
        ModemUpgradeCustomersAPI.getModemUpgradeQualifiedCustomers(
          {
            dealer_id: dealer_id,
          }, //params
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * @ngdoc object
       * @name method:process
       * @methodOf App.factory:ModemUpgradeCustomersService
       *
       * @description
       * This call wipes out all existing customers and adds only the ones in this PUT call.
       *
       *
       * @param {Promise} data- promise response
       */
      batchCreateModemUpgradeCustomers: function (upgradeCustomers, DealerId) {
        var deferred = $q.defer();

        ModemUpgradeCustomersAPI.batchCreateModemUpgradeCustomers(
          {
            dealer_id: DealerId,
          }, //params

          upgradeCustomers,

          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
