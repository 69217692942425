/**
 * @ngdoc directive
 * @name App.directive:clipPlayer
 * @function
 *
 * @description
 * Replaces the video tag each time a new clip is selected.  The HTML 5 Video Tag does not except dynamic content, so
 * the entire DOM element must be swapped out between clip changes.  If windows, chrome, and VLC plugin are detected,
 * the VLC embeded player is injected instead of the HTML Video tag.  Some of our older clips will not play in the HTML
 * tag.
 *
 * NOTE: This directive replaces the CHILD div in relation to the directive attribute.  Child div must always be present!!
 *
 *﻿ Download VLC from:  ﻿http://www.videolan.org/vlc/download-windows.html
 *
 */

App.directive("daClipPlayer", function ($timeout, $compile, browser) {
  var templates = {
    vlc: '\
          <embed id="player" type="application/x-vlc-plugin"\
          autoplay="true" loop="yes"\
          target="{{clip_url}}" />',
    default:
      '\
          <video video-error id="player" autoplay controls loop class="embed-responsive-item">\
          <source src="{{clip_url}}" type="video/mp4">\
          </video>',
  };
  var currentClip = "";
  var hasChromeAndVLC = function () {
    if (browser.chrome && browser.platform == "win") {
      for (let i = 0; i < navigator.plugins.length; i++) {
        if (navigator.plugins[i].name.indexOf("VLC Web") >= 0) {
          return true;
        }
      }
    }
    return false;
  };
  return {
    link: function (scope, element, attrs) {
      attrs.$observe("daClipPlayer", function (clip) {
        if (currentClip != clip) {
          currentClip = clip;
          if (hasChromeAndVLC()) {
            element
              .children()
              .replaceWith(
                $compile(templates.vlc.replace(/{{clip_url}}/g, clip))(scope)
              );
          } else {
            element
              .children()
              .replaceWith(
                $compile(templates.default.replace(/{{clip_url}}/g, clip))(
                  scope
                )
              );
          }
        }
      });

      element.bind("canplay", function () {});
      element.bind("emptied", function () {});
      element.bind("error", function () {});
    },
  };
});
