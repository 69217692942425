/**
 * @generated SignedSource<<50d7b0e5f237ec73f1ed598c1184a66f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RemoteOptionsConnectionType = "CELL" | "NETWORK" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsEntreConnectionField_remoteOptions$data = {
  readonly entreBackupIpAddress?: string | null;
  readonly entreBackupIpv6Address?: string | null;
  readonly entreConnection?: RemoteOptionsConnectionType | null;
  readonly entreIpAddress?: string | null;
  readonly entreIpv6?: boolean | null;
  readonly entreIpv6Address?: string | null;
  readonly " $fragmentType": "RemoteOptionsEntreConnectionField_remoteOptions";
};
export type RemoteOptionsEntreConnectionField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsEntreConnectionField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsEntreConnectionField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entreConnection",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entreIpAddress",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entreBackupIpAddress",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsEntreConnectionField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreIpv6",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreIpv6Address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entreBackupIpv6Address",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "type": "XfRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "daa6e410b1294836f7eb4792c6c0e042";

export default node;
