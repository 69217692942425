import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useHouseCode } from "../SystemOptionsFields/SystemOptionsContext";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "./utils";
import { OutputOptionsCarbonMonoxideOutputField_outputOptions$key } from "./__generated__/OutputOptionsCarbonMonoxideOutputField_outputOptions.graphql";

export const outputOptionsCarbonMonoxideOutputFieldId = () =>
  `output-options-carbon-monoxide-output`;

function OutputOptionsCarbonMonoxideOutputField() {
  const [{ carbonMonoxideOutput, id: outputOptionsId }, , onChange] =
    useOutputOptionsFragment<OutputOptionsCarbonMonoxideOutputField_outputOptions$key>(
      graphql`
        fragment OutputOptionsCarbonMonoxideOutputField_outputOptions on OutputOptions {
          id
          carbonMonoxideOutput {
            id
            formattedNumber
            number
          }
        }
      `
    );

  const fieldId = outputOptionsCarbonMonoxideOutputFieldId();
  const houseCode = useHouseCode();
  const hardwareModel = useHardwareModel();
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs

  const tooltip =
    hardwareModel === "XTLP" ||
    hardwareModel === "XTLN" ||
    hardwareModel === "XTLW"
      ? "This output will be turned on when a Carbon Monoxide Zone goes into Alarm. Turns off when all such Zones restore to normal."
      : "This output will be turned on when all Areas are CarbonMonoxide. It turns off when an Area is Armed.";

  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));

  const { current: originalValue } = React.useRef(
    carbonMonoxideOutput.formattedNumber
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Carbon Monoxide Output"
      tooltip={tooltip}
    >
      <TextInput
        id={fieldId}
        value={carbonMonoxideOutput.formattedNumber}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) =>
          onChange(outputOptionsId, target.value, "carbonMonoxideOutput")
        }
        onBlur={({ target }) => {
          const newValue = target.value;
          onChange(
            outputOptionsId,
            getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            ),
            "carbonMonoxideOutput"
          );
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsCarbonMonoxideOutputField;
