/**
 * @ngdoc service
 * @name App.factory:VideoChannel
 *
 * @description
 * API factory for Video Channel
 *
 */
App.factory("VideoSchedule", [
  "$q",
  "PROPS",
  function ($q, PROPS) {
    var VideoSchedule = function () {
      angular.extend(this, {
        isNew: true, // This is used to determine if the schedule is newly created or was fetched from the API

        day: "", // The day of the week for the schedule to run

        start_minute: 0,

        start_hour: 0,

        start_am_pm: "AM",

        end_minute: 0,

        end_hour: 0,

        end_am_pm: "AM",
      });
    };

    return VideoSchedule;
  },
]);
