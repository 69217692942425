/**
 *
 * SuccessButton
 * @author Chad Watson
 */

import React from "react";
import { ThemeProvider } from "styled-components";
import { UnthemedButton, VARIANT, SHAPES } from "./index";

const SuccessButton = ({
  shape,
  variant,
  size,
  next,
  prev,
  ...rest
}: {
  shape?: SHAPES;
  variant?: VARIANT;
  size?: "default" | "small";
  next?: boolean;
  prev?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.success,
      opposedColor: currentTheme.trueWhite,
    })}
  >
    <UnthemedButton
      shape={shape}
      variant={variant}
      size={size}
      next={next}
      prev={prev}
      {...rest}
    />
  </ThemeProvider>
);

export default SuccessButton;
