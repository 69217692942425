/**
 * @generated SignedSource<<a0aeb5eea0d0ae301a7f13e7467587a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SystemReportsZoneRestoralReports = "DISARM" | "NO" | "YES";
import { FragmentRefs } from "relay-runtime";
export type XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts$data = {
  readonly systemReports: {
    readonly abortReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly ambushReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly bypassReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly codeChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly earlyToClose: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly entryCheckinProtection: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly id: string;
    readonly included: boolean;
    readonly lateToOpen: {
      readonly data: number | null;
      readonly included: boolean | null;
    } | null;
    readonly openCloseEnable: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly scheduleChangeReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly sendStoredMessage: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly videoSystemReports: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly zoneRestoralReports: {
      readonly data: SystemReportsZoneRestoralReports | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts";
};
export type XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "f0f1f15bd222a84b1113c8893c42b488";

export default node;
