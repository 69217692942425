/**
 * @ngdoc service
 * @name App.factory:CustomReportsService
 *
 * @description
 * Manages Custom Reports
 *
 */
App.service("CustomReportsService", [
  "$rootScope",
  "$q",
  "$http",
  "$filter",
  "PROPS",
  "CustomReportsAPI",
  "customReport",
  function (
    $rootScope,
    $q,
    $http,
    $filter,
    PROPS,
    CustomReportsAPI,
    customReport
  ) {
    return {
      newReport: function () {
        var deferred = $q.defer();
        var _this = this;
        CustomReportsAPI.newReport(
          {},
          function (data) {
            var report = new customReport(data);
            report.isNew = true;
            deferred.resolve(report);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getAllReportsForDealer: function (dealerId) {
        var deferred = $q.defer();
        var _this = this;
        CustomReportsAPI.getAllReportsForDealer(
          { dealer_id: dealerId },
          function (rawReports) {
            var reportArray = [];
            angular.forEach(rawReports, function (rawReport) {
              var reportModel = new customReport(rawReport);
              reportArray.push(reportModel);
            });
            deferred.resolve(reportArray);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getAllReportsForCustomer: function (dealerId, customerId) {
        var deferred = $q.defer();
        var _this = this;
        CustomReportsAPI.getAllReportsForDealer(
          { dealer_id: dealerId, customer_id: customerId },
          function (rawReports) {
            var reportArray = [];
            angular.forEach(rawReports, function (rawReport) {
              var reportModel = new customReport(rawReport);
              reportArray.push(reportModel);
            });
            deferred.resolve(reportArray);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getAllReportsForUser: function (dealerId, userId) {
        var deferred = $q.defer();
        var _this = this;
        CustomReportsAPI.getAllReportsForUser(
          { dealer_id: dealerId, user_id: userId },
          function (rawReports) {
            var reportArray = [];
            angular.forEach(rawReports, function (rawReport) {
              var reportModel = new customReport(rawReport);
              reportArray.push(reportModel);
            });
            deferred.resolve(reportArray);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      getReport: function (id) {
        var deferred = $q.defer();
        CustomReportsAPI.showReport(
          { report_id: id },
          function (report) {
            deferred.resolve(new customReport(report));
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      deleteReport: function (reportId) {
        var deferred = $q.defer();
        CustomReportsAPI.deleteReport(
          { report_id: reportId },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
