/**
 *
 * ThermCoolIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const ThermCoolIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <polygon
        points="63.803,28.974 63.109,25.034 53.442,26.738 57.13,17.918 53.439,16.375 48.762,27.564 33.97,30.173 39.95,13.773 
    50.974,11.964 50.327,8.017 41.522,9.461 43.655,3.611 39.897,2.24 37.876,7.784 31.659,0 28.534,2.496 36.271,12.184 
    30.154,28.96 20.601,17.336 23.954,5.729 20.111,4.619 17.52,13.588 11.88,6.727 8.79,9.266 14.48,16.188 5.292,17.442 
    5.833,21.405 17.463,19.817 26.987,31.404 12.582,33.944 4.523,25.021 1.554,27.702 7.932,34.765 0,36.163 0.695,40.103 
    8.555,38.717 4.865,47.587 8.558,49.124 13.231,37.892 27.836,35.316 22.779,49.186 10.967,51.285 11.667,55.223 21.195,53.529 
    17.877,62.629 21.635,64 24.785,55.361 30.398,62.921 33.61,60.536 26.426,50.86 31.513,36.91 41.054,48.518 38.49,59.443 
    42.385,60.357 44.25,52.407 50.237,59.691 53.327,57.152 46.697,49.086 57.212,47.628 56.662,43.665 43.717,45.46 34.424,34.154 
    49.405,31.513 57.475,40.479 60.447,37.803 54.049,30.693   "
      />
    </g>
  </SvgIcon>
);

export default ThermCoolIcon;
