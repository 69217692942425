import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathRetryTimeField_communicationPath$key } from "./__generated__/CommPathRetryTimeField_communicationPath.graphql";

export const commPathRetryTimeFieldId = (number: string) =>
  `comm-path-retry-time-${number}`;

function CommPathRetryTimeField() {
  const [
    { number, commType, retryTime, retryTimeMin, retryTimeMax },
    updateCommPath,
  ] = useCommPathFragment<CommPathRetryTimeField_communicationPath$key>(
    graphql`
      fragment CommPathRetryTimeField_communicationPath on CommunicationPath {
        number
        commType
        retryTime
        retryTimeMin
        retryTimeMax
      }
    `
  );

  const fieldId = commPathRetryTimeFieldId(String(number));
  const { current: originalValue } = React.useRef(retryTime);
  const disabled = !["NETWORK", "WIFI"].includes(commType);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Retry Seconds"
      tooltip="The number seconds the System waits for an acknowledgement from the receiver before resending a message on this path."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={retryTime.toString()}
        required
        disabled={disabled}
        inlineHelp={`${retryTimeMin}–${retryTimeMax}`}
        onChange={({ target }) => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "retryTime"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCommPath((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(retryTimeMin, retryTimeMax, valueAsNumber);
            recordProxy.setValue(value, "retryTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathRetryTimeField;
