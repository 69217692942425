/**
 * @generated SignedSource<<1900a3813d07a3f88c4365979fdd1b83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XRCardFormatsProgrammingConceptFormNavButton_controlSystem$data = {
  readonly customer: {
    readonly id: string;
  };
  readonly id: string;
  readonly panel: {
    readonly cardFormats: ReadonlyArray<{
      readonly isNew: boolean;
    }>;
  };
  readonly " $fragmentType": "XRCardFormatsProgrammingConceptFormNavButton_controlSystem";
};
export type XRCardFormatsProgrammingConceptFormNavButton_controlSystem$key = {
  readonly " $data"?: XRCardFormatsProgrammingConceptFormNavButton_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRCardFormatsProgrammingConceptFormNavButton_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRCardFormatsProgrammingConceptFormNavButton_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CardFormat",
          "kind": "LinkedField",
          "name": "cardFormats",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isNew",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "866ad6df81a4f4b5876c8740359e2d6d";

export default node;
