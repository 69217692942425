/**
 * @generated SignedSource<<42819a074c828546e36073dd9718e7f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay$data = {
  readonly carbonMonoxideZoneKeypads: string;
  readonly " $fragmentType": "StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay";
};
export type StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay$key = {
  readonly " $data"?: StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusListDisplayCarbonMonoxideZoneKeypadsField_statusListDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carbonMonoxideZoneKeypads",
      "storageKey": null
    }
  ],
  "type": "StatusListDisplay",
  "abstractKey": null
};

(node as any).hash = "f80b7f01935b7924390fe9d6b566300a";

export default node;
