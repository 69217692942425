/**
 * @ngdoc service
 * @name App.factory:PanelCommunicationHistoryService
 *
 * @description
 * Service for retrieving panel events for the VAR
 *
 */
App.factory("PanelConnectionHistoryService", [
  "$q",
  "PanelODataAPI",
  "PanelODataConnectionHistoriesAPI",
  function ($q, PanelODataAPI, PanelODataConnectionHistoriesAPI) {
    return {
      /**
       * @ngdoc object
       * @name property:panel_id
       * @type Number
       * @methodOf App.factory:PanelCommunicationHistoryService
       *
       * @description
       * Holds the Panel ID Number.
       */
      panel_id: 0,

      getPanelConnectionHistories: function (panelId) {
        const deferred = $q.defer();

        PanelODataConnectionHistoriesAPI.getPanelConnectionHistories(
          { panel_id: panelId },
          (data) => {
            deferred.resolve(data);
          },
          (error) => {
            console.log(error);
            return deferred.error(error);
          }
        );

        return deferred.promise;
      },
    };
  },
]);
