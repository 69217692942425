App.directive("daUniqueTemplateName", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      ngModel.$validators.unique = function (modelValue, viewValue) {
        return (
          scope.allTheDefaults &&
          scope.allTheDefaults.findIndex(
            (item) => viewValue.toLowerCase() === item.name.toLowerCase()
          ) < 0
        );
      };
    },
  };
});
