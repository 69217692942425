App.controller("BusinessAnalyticsDownloadModalCtrl", [
  "$scope",
  "$modalInstance",
  "UserService",
  "JobSchedulerService",
  "CustomReportsAPI",
  "PROPS",
  "$timeout",
  "dealerId",
  function (
    $scope,
    $modalInstance,
    UserService,
    JobSchedulerService,
    CustomReportsAPI,
    PROPS,
    $timeout,
    dealerId
  ) {
    /* ----------------------------------------------------
    Time zone map
    ---------------------------------------------------- */
    /**
     * Maps a "tz database" (aka Oslon) time zone string
     * to a Win32 TimeZoneInfo string.
     *
     * https://stackoverflow.com/questions/5996320/net-timezoneinfo-from-olson-time-zone
     */
    const tzDbToWin32TimeZone = {
      "Africa/Bangui": "W. Central Africa Standard Time",
      "Africa/Cairo": "Egypt Standard Time",
      "Africa/Casablanca": "Morocco Standard Time",
      "Africa/Harare": "South Africa Standard Time",
      "Africa/Johannesburg": "South Africa Standard Time",
      "Africa/Lagos": "W. Central Africa Standard Time",
      "Africa/Monrovia": "Greenwich Standard Time",
      "Africa/Nairobi": "E. Africa Standard Time",
      "Africa/Windhoek": "Namibia Standard Time",
      "America/Anchorage": "Alaskan Standard Time",
      "America/Argentina/San_Juan": "Argentina Standard Time",
      "America/Asuncion": "Paraguay Standard Time",
      "America/Bahia": "Bahia Standard Time",
      "America/Bogota": "SA Pacific Standard Time",
      "America/Buenos_Aires": "Argentina Standard Time",
      "America/Caracas": "Venezuela Standard Time",
      "America/Cayenne": "SA Eastern Standard Time",
      "America/Chicago": "Central Standard Time",
      "America/Chihuahua": "Mountain Standard Time (Mexico)",
      "America/Cuiaba": "Central Brazilian Standard Time",
      "America/Denver": "Mountain Standard Time",
      "America/Fortaleza": "SA Eastern Standard Time",
      "America/Godthab": "Greenland Standard Time",
      "America/Guatemala": "Central America Standard Time",
      "America/Halifax": "Atlantic Standard Time",
      "America/Indianapolis": "US Eastern Standard Time",
      "America/Indiana/Indianapolis": "US Eastern Standard Time",
      "America/La_Paz": "SA Western Standard Time",
      "America/Los_Angeles": "Pacific Standard Time",
      "America/Mexico_City": "Mexico Standard Time",
      "America/Montevideo": "Montevideo Standard Time",
      "America/New_York": "Eastern Standard Time",
      "America/Noronha": "UTC-02",
      "America/Phoenix": "US Mountain Standard Time",
      "America/Regina": "Canada Central Standard Time",
      "America/Santa_Isabel": "Pacific Standard Time (Mexico)",
      "America/Santiago": "Pacific SA Standard Time",
      "America/Sao_Paulo": "E. South America Standard Time",
      "America/St_Johns": "Newfoundland Standard Time",
      "America/Tijuana": "Pacific Standard Time",
      "Antarctica/McMurdo": "New Zealand Standard Time",
      "Atlantic/South_Georgia": "UTC-02",
      "Asia/Almaty": "Central Asia Standard Time",
      "Asia/Amman": "Jordan Standard Time",
      "Asia/Baghdad": "Arabic Standard Time",
      "Asia/Baku": "Azerbaijan Standard Time",
      "Asia/Bangkok": "SE Asia Standard Time",
      "Asia/Beirut": "Middle East Standard Time",
      "Asia/Calcutta": "India Standard Time",
      "Asia/Colombo": "Sri Lanka Standard Time",
      "Asia/Damascus": "Syria Standard Time",
      "Asia/Dhaka": "Bangladesh Standard Time",
      "Asia/Dubai": "Arabian Standard Time",
      "Asia/Irkutsk": "North Asia East Standard Time",
      "Asia/Jerusalem": "Israel Standard Time",
      "Asia/Kabul": "Afghanistan Standard Time",
      "Asia/Kamchatka": "Kamchatka Standard Time",
      "Asia/Karachi": "Pakistan Standard Time",
      "Asia/Katmandu": "Nepal Standard Time",
      "Asia/Kolkata": "India Standard Time",
      "Asia/Krasnoyarsk": "North Asia Standard Time",
      "Asia/Kuala_Lumpur": "Singapore Standard Time",
      "Asia/Kuwait": "Arab Standard Time",
      "Asia/Magadan": "Magadan Standard Time",
      "Asia/Muscat": "Arabian Standard Time",
      "Asia/Novosibirsk": "N. Central Asia Standard Time",
      "Asia/Oral": "West Asia Standard Time",
      "Asia/Rangoon": "Myanmar Standard Time",
      "Asia/Riyadh": "Arab Standard Time",
      "Asia/Seoul": "Korea Standard Time",
      "Asia/Shanghai": "China Standard Time",
      "Asia/Singapore": "Singapore Standard Time",
      "Asia/Taipei": "Taipei Standard Time",
      "Asia/Tashkent": "West Asia Standard Time",
      "Asia/Tbilisi": "Georgian Standard Time",
      "Asia/Tehran": "Iran Standard Time",
      "Asia/Tokyo": "Tokyo Standard Time",
      "Asia/Ulaanbaatar": "Ulaanbaatar Standard Time",
      "Asia/Vladivostok": "Vladivostok Standard Time",
      "Asia/Yakutsk": "Yakutsk Standard Time",
      "Asia/Yekaterinburg": "Ekaterinburg Standard Time",
      "Asia/Yerevan": "Armenian Standard Time",
      "Atlantic/Azores": "Azores Standard Time",
      "Atlantic/Cape_Verde": "Cape Verde Standard Time",
      "Atlantic/Reykjavik": "Greenwich Standard Time",
      "Australia/Adelaide": "Cen. Australia Standard Time",
      "Australia/Brisbane": "E. Australia Standard Time",
      "Australia/Darwin": "AUS Central Standard Time",
      "Australia/Hobart": "Tasmania Standard Time",
      "Australia/Perth": "W. Australia Standard Time",
      "Australia/Sydney": "AUS Eastern Standard Time",
      "Etc/GMT": "UTC",
      "Etc/GMT+11": "UTC-11",
      "Etc/GMT+12": "Dateline Standard Time",
      "Etc/GMT+2": "UTC-02",
      "Etc/GMT-12": "UTC+12",
      "Europe/Amsterdam": "W. Europe Standard Time",
      "Europe/Athens": "GTB Standard Time",
      "Europe/Belgrade": "Central Europe Standard Time",
      "Europe/Berlin": "W. Europe Standard Time",
      "Europe/Brussels": "Romance Standard Time",
      "Europe/Budapest": "Central Europe Standard Time",
      "Europe/Dublin": "GMT Standard Time",
      "Europe/Helsinki": "FLE Standard Time",
      "Europe/Istanbul": "GTB Standard Time",
      "Europe/Kiev": "FLE Standard Time",
      "Europe/London": "GMT Standard Time",
      "Europe/Minsk": "E. Europe Standard Time",
      "Europe/Moscow": "Russian Standard Time",
      "Europe/Paris": "Romance Standard Time",
      "Europe/Sarajevo": "Central European Standard Time",
      "Europe/Warsaw": "Central European Standard Time",
      "Indian/Mauritius": "Mauritius Standard Time",
      "Pacific/Apia": "Samoa Standard Time",
      "Pacific/Auckland": "New Zealand Standard Time",
      "Pacific/Fiji": "Fiji Standard Time",
      "Pacific/Guadalcanal": "Central Pacific Standard Time",
      "Pacific/Guam": "West Pacific Standard Time",
      "Pacific/Honolulu": "Hawaiian Standard Time",
      "Pacific/Pago_Pago": "UTC-11",
      "Pacific/Port_Moresby": "West Pacific Standard Time",
      "Pacific/Tongatapu": "Tonga Standard Time",
    };

    /* ----------------------------------------------------
    Functions
    ---------------------------------------------------- */

    /**
     * Downloads the generated report. Called after the job to generate the
     * report has finished.
     */
    function downloadReport(schedule) {
      throwIfCancel();
      let url = `${PROPS.jobsApiUrl}/api/v1/SchedulerJobReports/${schedule.Id}/download`;
      setStatusReady();
      fetch(url, {
        headers: {
          Authorization: `Bearer ${UserService.auth_token}`,
        },
      })
        .then((res) => {
          return res.status === 200 ? res.blob() : setStatusError();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.getElementById("report-download-link");
          a.href = url;
          a.download = "Business Health Report.pdf";
          a.click();
        })
        .catch((err) => {
          setStatusError();
        });

      // window.open(url, "_blank", "auth=Bearer " + auth_token);
    }

    /**
     * Gets the schedule's job group.
     * @param {*} schedule The report schedule.
     */
    async function getJobGroup(schedule) {
      return await new Promise((resolve, reject) => {
        CustomReportsAPI.getReportStatus(
          { report_id: schedule.Id },
          {},
          (data) => resolve(data),
          (error) => reject(error)
        );
      });
    }

    /**
     * Gets the job status of the PDF generation.
     * For a job group used for downloading the PDF, there is only ever one job within
     * that group.
     * @param {*} jobGroup The job group.
     * @returns The job status string for the first job in the job group.
     */
    function getJobStatus(jobGroup) {
      if (!jobGroup || jobGroup.SchedulerJobs.length == 0) {
        throw "Job group did not have a job.";
      }

      return jobGroup.SchedulerJobs[0].JobStatus;
    }

    /**
     * Gets the client's current time zone in Win32 TimeZoneInfo format.
     */
    function getTimeZone() {
      let oslonTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let win32TimeZone = "Central Standard Time";

      if (oslonTimeZone in tzDbToWin32TimeZone) {
        win32TimeZone = tzDbToWin32TimeZone[oslonTimeZone];
      }

      return win32TimeZone;
    }

    function initState() {
      if (!$scope.state) {
        $scope.state = {
          downloadLink: "",
          errorMsg: "Failed to generate report.",
          isError: false,
          isGenerating: true,
          isReportReady: false,
          isCancelPending: false,
        };
      }
    }

    function isJobComplete(jobGroup) {
      let status = getJobStatus(jobGroup);
      return JobSchedulerService.isCompleteStatus(status);
    }

    function isJobRunning(jobGroup) {
      let status = getJobStatus(jobGroup);
      return JobSchedulerService.isRunningStatus(status);
    }

    /**
     * Monitors a job group until it completes or fails.
     * @param {*} schedule The report schedule.
     * @returns Whether the job succeeded.
     */
    async function monitorJob(schedule) {
      while (true) {
        throwIfCancel();

        /* Check the status of the job group */
        let jobGroup = await getJobGroup(schedule);
        if (!jobGroup) {
          return false;
        }

        if (isJobRunning(jobGroup)) {
          /* Still running - wait, then check again */
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else if (isJobComplete(jobGroup)) {
          /* Job success */
          return true;
        } else {
          /* Error */
          return false;
        }
      }
    }

    function setStatusGenerating() {
      $scope.state.isGenerating = true;
      $scope.state.isError = false;
      $scope.state.isReady = false;

      $timeout(() => $scope.$apply());
    }

    function setStatusError(errorMsg) {
      $scope.state.isGenerating = false;
      $scope.state.isError = true;
      $scope.state.errorMsg = errorMsg || "Failed to generate report.";
      $scope.state.isReady = false;

      $timeout(() => $scope.$apply());
    }

    function setStatusReady() {
      $scope.state.isGenerating = false;
      $scope.state.isError = false;
      $scope.state.isReady = true;

      $timeout(() => $scope.$apply());
    }

    /**
     * Runs the on-demand report. The Schedule API handles creating/updating
     * the report schedule and starts the job to generate the report.
     * @returns The report schedule data.
     */
    async function runReport() {
      const data = {
        UserId: UserService.user.id,
        DealerId: dealerId || UserService.dealer_id,
        TimeZone: getTimeZone(),
        ReportName: "Business Health Report",
        Type: "business_analytics",
      };

      return await new Promise((resolve, reject) =>
        CustomReportsAPI.runOnDemandReport(
          {},
          data,
          (data) => resolve(data),
          (error) => reject(error)
        )
      );
    }

    function throwIfCancel() {
      if ($scope.state.isCancelPending) {
        throw "Report download cancelled.";
      }
    }

    /* ----------------------------------------------------
    Top level functions
    ---------------------------------------------------- */

    /**
     * Magic happens here.
     */
    async function process() {
      setStatusGenerating();

      /* Run the report */
      let schedule = await runReport();
      if (!schedule) {
        /* Failed to create schedule */
        console.error("Failed to create schedule");
        setStatusError();
      }

      /* Monitor the job until completed */
      let monitorResult = await monitorJob(schedule);
      if (!monitorResult) {
        /* Failed to generate report */
        setStatusError();
        return;
      }

      /* Report generated, now we just have to download */
      downloadReport(schedule);
    }

    async function main() {
      /*
      JUST DO IT.
      */
      try {
        await process();
      } catch (ex) {
        setStatusError();
      } finally {
        $scope.state.isCancelPending = false;
      }
    }

    /* ----------------------------------------------------
    Run
    ---------------------------------------------------- */

    $scope.cancel = function () {
      try {
        $scope.state.isCancelPending = true;
        const a = document.getElementById("report-download-link");
        window.URL.revokeObjectURL(a.href);
        $modalInstance.dismiss("cancel");
      } catch {
        $scope.state.isCancelPending = true;
        $modalInstance.dismiss("cancel");
      }
    };

    $scope.retry = function () {
      main();
    };

    initState();
    main();
  },
]);
