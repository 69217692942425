import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setToggle } from "common/utils/universal/set";
import { deviceInformationActivateOnboardSpeakerFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateOnboardSpeakerField";
import { deviceInformationActivateZone2BypassFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone2BypassField";
import { deviceInformationActivateZone3RequestToExitFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone3RequestToExitField";
import { deviceInformationCardOptionsFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationCardOptionsField";
import { deviceInformationNoCommWithPanelFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationNoCommWithPanelField";
import { deviceInformationRelockOnZone2ChangeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRelockOnZone2ChangeField";
import { deviceInformationRequireSiteCodeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRequireSiteCodeField";
import { deviceInformationSiteCode1FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode1Field";
import { deviceInformationSiteCode2FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode2Field";
import { deviceInformationSiteCode3FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode3Field";
import { deviceInformationSiteCode4FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode4Field";
import { deviceInformationSiteCode5FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode5Field";
import { deviceInformationSiteCode6FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode6Field";
import { deviceInformationSiteCode7FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode7Field";
import { deviceInformationSiteCode8FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode8Field";
import { deviceInformationSiteCodeLengthFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCodeLengthField";
import { deviceInformationSiteCodePositionFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCodePositionField";
import { deviceInformationUserCodeDigitsFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeDigitsField";
import { deviceInformationUserCodeLengthFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeLengthField";
import { deviceInformationUserCodePositionFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodePositionField";
import { deviceInformationWiegandCodeLengthFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationWiegandCodeLengthField";
import { deviceInformationZone2BypassTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone2BypassTimeField";
import { deviceInformationZone3RequestToExitTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone3RequestToExitTimeField";
import { deviceInformation1100TFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TField";
import { deviceInformation1100TWirelessFrequencyFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TWirelessFrequencyField";
import { deviceInformationAccessAreasFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationAccessAreasField";
import { deviceInformationAutoForceArmFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationAutoForceArmField";
import { deviceInformationDeviceCommunicationTypeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceCommunicationTypeField";
import { deviceInformationDeviceTypeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceTypeField";
import { deviceInformationDisplayAreasFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDisplayAreasField";
import { deviceInformationDoorRealTimeStatusFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDoorRealTimeStatusField";
import { deviceInformationEgressAreasFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationEgressAreasField";
import { deviceInformationFireExitReleaseFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationFireExitReleaseField";
import { deviceInformationNameFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNameField";
import { deviceInformationOutputGroupFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationOutputGroupField";
import { deviceInformationPrivateDoorFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPrivateDoorField";
import { deviceInformationProgram734OptionsFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationProgram734OptionsField";
import { deviceInformationPublicDoorFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPublicDoorField";
import { deviceInformationScheduleOverrideFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationScheduleOverrideField";
import { deviceInformationSendDoorForcedMessageFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSendDoorForcedMessageField";
import { deviceInformationStrikeDelayFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeDelayField";
import { deviceInformationStrikeTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeTimeField";
import { deviceInformationSupervisionTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSupervisionTimeField";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import {
  xrDeviceInformationNumberFieldId,
  xrDeviceListItemTemplateId,
} from "components/FullProgramming/XRFullProgramming/XRDeviceSetupProgrammingConceptForm/XRDeviceInformationNumberField";
import { XRDeviceSetupProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XRFullProgramming/XRDeviceSetupProgrammingConceptForm/__generated__/XRDeviceSetupProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import {
  getDeviceState,
  getState as getDevicesState,
} from "../../../XRFullProgramming/XRDeviceSetupProgrammingConceptForm";
import { XrProgrammingTemplateDeviceInformationInput } from "../Create/__generated__/XRTemplateSaveMutation.graphql";
import { XRDeviceInformationTemplateDataInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRDeviceInformationTemplateDataInline_xrProgrammingTemplateConcepts.graphql";

export const getState = (
  device: XRDeviceInformationTemplateDataInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRDeviceInformationTemplateDataInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        deviceInformations {
          id
          included
          number
          axNumber {
            data
            included
          }
          lxNumber {
            data
            included
          }
          name {
            data
            included
          }
          supervisionTime {
            data
            included
          }
          wirelessTranslator1100tFrequency {
            data
            included
          }
          deviceType {
            data
            included
          }
          deviceCommunicationMethod {
            data
            included
          }
          accessAreas {
            data
            included
          }
          egressAreas {
            data
            included
          }
          displayAreas {
            data
            included
          }
          strikeTime {
            data
            included
          }
          strikeDelay {
            data
            included
          }
          fireExit {
            data
            included
          }
          publicDoor {
            data
            included
          }
          triggerOutputGroupWithAccess {
            data
            included
          }
          overrideScheduleWhenArmed {
            data
            included
          }
          autoForceArm {
            data
            included
          }
          doorRealTimeStatusMessages {
            data
            included
          }
          doorForceMessages {
            data
            included
          }
          remoteProgram734 {
            data
            included
          }
          device734 {
            data {
              number
              zone2BypassOnRequestToExit {
                data
                included
              }
              zone2BypassTime {
                data
                included
              }
              zone2RelockOnStateChange {
                data
                included
              }
              enableZone3RequestToExit {
                data
                included
              }
              zone3RequestToExitTime {
                data
                included
              }
              enableOnboardSpeaker {
                data
                included
              }
              cardFormatOptions {
                data
                included
              }
              wiegandBitLength {
                data
                included
              }
              siteCodeStartBitPosition {
                data
                included
              }
              siteCodeBitLength {
                data
                included
              }
              userCodeStartBitPosition {
                data
                included
              }
              userCodeBitLength {
                data
                included
              }
              enforceSiteCode {
                data
                included
              }
              siteCode1 {
                data
                included
              }
              siteCode2 {
                data
                included
              }
              siteCode3 {
                data
                included
              }
              siteCode4 {
                data
                included
              }
              siteCode5 {
                data
                included
              }
              siteCode6 {
                data
                included
              }
              siteCode7 {
                data
                included
              }
              siteCode8 {
                data
                included
              }
              numberOfUserCodeDigits {
                data
                included
              }
              noCommWithPanelRelayAction {
                data
                included
              }
            }
            included
          }
          privateDoor {
            data
            included
          }
          wirelessTranslator1100t {
            data
            included
          }
        }
      }
    `,
    device
  );

export const getInput = (
  controlSystem: XRDeviceSetupProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): XrProgrammingTemplateDeviceInformationInput[] => {
  const {
    panel: { deviceInformations },
  } = getDevicesState(controlSystem);

  return deviceInformations
    ? deviceInformations
        .filter(isNotNullOrUndefined)
        .map(getDeviceState)
        .map((device) => ({
          included: includedFields.has(
            xrDeviceListItemTemplateId(String(device.number))
          ),
          number: Number(device?.number ?? 0),
          name: {
            data: device.name,
            included: includedFields.has(
              deviceInformationNameFieldId(String(device.number))
            ),
          },
          supervisionTime: {
            data: device.supervisionTime,
            included: includedFields.has(
              deviceInformationSupervisionTimeFieldId(String(device.number))
            ),
          },
          wirelessTranslator1100tFrequency: {
            data: device.wirelessTranslator1100tFrequency,
            included: includedFields.has(
              deviceInformation1100TWirelessFrequencyFieldId(
                String(device.number)
              )
            ),
          },
          deviceType: {
            data: device.deviceType,
            included: includedFields.has(
              deviceInformationDeviceTypeFieldId(String(device.number))
            ),
          },
          deviceCommunicationMethod: {
            data: device.deviceCommunicationMethod,
            included: includedFields.has(
              deviceInformationDeviceCommunicationTypeFieldId(
                String(device.number)
              )
            ),
          },
          accessAreas: {
            data: device.accessAreas,
            included: includedFields.has(
              deviceInformationAccessAreasFieldId(String(device.number))
            ),
          },
          egressAreas: {
            data: device.egressAreas,
            included: includedFields.has(
              deviceInformationEgressAreasFieldId(String(device.number))
            ),
          },
          displayAreas: {
            data: device.displayAreas,
            included: includedFields.has(
              deviceInformationDisplayAreasFieldId(String(device.number))
            ),
          },
          strikeTime: {
            data: device.strikeTime,
            included: includedFields.has(
              deviceInformationStrikeTimeFieldId(String(device.number))
            ),
          },
          strikeDelay: {
            data: device.strikeDelay,
            included: includedFields.has(
              deviceInformationStrikeDelayFieldId(String(device.number))
            ),
          },
          fireExit: {
            data: device.fireExit,
            included: includedFields.has(
              deviceInformationFireExitReleaseFieldId(String(device.number))
            ),
          },
          publicDoor: {
            data: device.publicDoor,
            included: includedFields.has(
              deviceInformationPublicDoorFieldId(String(device.number))
            ),
          },
          triggerOutputGroupWithAccess: {
            data: device.triggerOutputGroupWithAccess,
            included: includedFields.has(
              deviceInformationOutputGroupFieldId(String(device.number))
            ),
          },
          overrideScheduleWhenArmed: {
            data: device.overrideScheduleWhenArmed,
            included: includedFields.has(
              deviceInformationScheduleOverrideFieldId(String(device.number))
            ),
          },
          autoForceArm: {
            data: device.autoForceArm,
            included: includedFields.has(
              deviceInformationAutoForceArmFieldId(String(device.number))
            ),
          },
          doorRealTimeStatusMessages: {
            data: device.doorRealTimeStatusMessages,
            included: includedFields.has(
              deviceInformationDoorRealTimeStatusFieldId(String(device.number))
            ),
          },
          doorForceMessages: {
            data: device.doorForceMessages,
            included: includedFields.has(
              deviceInformationSendDoorForcedMessageFieldId(
                String(device.number)
              )
            ),
          },
          remoteProgram734: {
            data: device.remoteProgram734,
            included: includedFields.has(
              deviceInformationProgram734OptionsFieldId(String(device.number))
            ),
          },
          device734: {
            included:
              includedFields.has(
                deviceInformationProgram734OptionsFieldId(String(device.number))
              ) &&
              includedFields.has(
                xrDeviceListItemTemplateId(String(device.number))
              ),
            data: {
              number: Number(device.device734?.number ?? 0),
              zone2BypassOnRequestToExit: {
                data: device.device734?.zone2BypassOnRequestToExit,
                included: includedFields.has(
                  deviceInformationActivateZone2BypassFieldId(
                    String(device.number)
                  )
                ),
              },
              zone2BypassTime: {
                data: device.device734?.zone2BypassTime,
                included: includedFields.has(
                  deviceInformationZone2BypassTimeFieldId(String(device.number))
                ),
              },
              zone2RelockOnStateChange: {
                data: device.device734?.zone2RelockOnStateChange,
                included: includedFields.has(
                  deviceInformationRelockOnZone2ChangeFieldId(
                    String(device.number)
                  )
                ),
              },
              enableZone3RequestToExit: {
                data: device.device734?.enableZone3RequestToExit,
                included: includedFields.has(
                  deviceInformationActivateZone3RequestToExitFieldId(
                    String(device.number)
                  )
                ),
              },
              zone3RequestToExitTime: {
                data: device.device734?.zone3RequestToExitTime,
                included: includedFields.has(
                  deviceInformationZone3RequestToExitTimeFieldId(
                    String(device.number)
                  )
                ),
              },
              enableOnboardSpeaker: {
                data: device.device734?.enableOnboardSpeaker,
                included: includedFields.has(
                  deviceInformationActivateOnboardSpeakerFieldId(
                    String(device.number)
                  )
                ),
              },
              cardFormatOptions: {
                data: device.device734?.cardFormatOptions,
                included: includedFields.has(
                  deviceInformationCardOptionsFieldId(String(device.number))
                ),
              },
              wiegandBitLength: {
                data: device.device734?.wiegandBitLength,
                included: includedFields.has(
                  deviceInformationWiegandCodeLengthFieldId(
                    String(device.number)
                  )
                ),
              },
              siteCodeStartBitPosition: {
                data: device.device734?.siteCodeStartBitPosition,
                included: includedFields.has(
                  deviceInformationSiteCodePositionFieldId(
                    String(device.number)
                  )
                ),
              },
              siteCodeBitLength: {
                data: device.device734?.siteCodeBitLength,
                included: includedFields.has(
                  deviceInformationSiteCodeLengthFieldId(String(device.number))
                ),
              },
              userCodeStartBitPosition: {
                data: device.device734?.userCodeStartBitPosition,
                included: includedFields.has(
                  deviceInformationUserCodePositionFieldId(
                    String(device.number)
                  )
                ),
              },
              userCodeBitLength: {
                data: device.device734?.userCodeBitLength,
                included: includedFields.has(
                  deviceInformationUserCodeLengthFieldId(String(device.number))
                ),
              },
              enforceSiteCode: {
                data: device.device734?.enforceSiteCode,
                included: includedFields.has(
                  deviceInformationRequireSiteCodeFieldId(String(device.number))
                ),
              },
              siteCode1: {
                data: device.device734?.siteCode1,
                included: includedFields.has(
                  deviceInformationSiteCode1FieldId(String(device.number))
                ),
              },
              siteCode2: {
                data: device.device734?.siteCode2,
                included: includedFields.has(
                  deviceInformationSiteCode2FieldId(String(device.number))
                ),
              },
              siteCode3: {
                data: device.device734?.siteCode3,
                included: includedFields.has(
                  deviceInformationSiteCode3FieldId(String(device.number))
                ),
              },
              siteCode4: {
                data: device.device734?.siteCode4,
                included: includedFields.has(
                  deviceInformationSiteCode4FieldId(String(device.number))
                ),
              },
              siteCode5: {
                data: device.device734?.siteCode5,
                included: includedFields.has(
                  deviceInformationSiteCode5FieldId(String(device.number))
                ),
              },
              siteCode6: {
                data: device.device734?.siteCode6,
                included: includedFields.has(
                  deviceInformationSiteCode6FieldId(String(device.number))
                ),
              },
              siteCode7: {
                data: device.device734?.siteCode7,
                included: includedFields.has(
                  deviceInformationSiteCode7FieldId(String(device.number))
                ),
              },
              siteCode8: {
                data: device.device734?.siteCode8,
                included: includedFields.has(
                  deviceInformationSiteCode8FieldId(String(device.number))
                ),
              },
              numberOfUserCodeDigits: {
                data: device.device734?.numberOfUserCodeDigits,
                included: includedFields.has(
                  deviceInformationUserCodeDigitsFieldId(String(device.number))
                ),
              },
              noCommWithPanelRelayAction: {
                data: device.device734?.noCommWithPanelRelayAction,
                included: includedFields.has(
                  deviceInformationNoCommWithPanelFieldId(String(device.number))
                ),
              },
            },
          },
          privateDoor: {
            data: device.privateDoor,
            included: includedFields.has(
              deviceInformationPrivateDoorFieldId(String(device.number))
            ),
          },
          wirelessTranslator1100t: {
            data: device.wirelessTranslator1100t,
            included: includedFields.has(
              deviceInformation1100TFieldId(String(device.number))
            ),
          },
        }))
    : [];
};

export const setIncludedFields = (
  template: XRDeviceInformationTemplateDataInline_xrProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { deviceInformations } = getState(template);

  if (!deviceInformations) {
    return;
  }

  deviceInformations.filter(isNotNullOrUndefined).forEach((device) => {
    const number = String(String(device.number));

    if (device.included) {
      setIncludedFields(setToggle(xrDeviceListItemTemplateId(number)));
      setIncludedFields(setToggle(xrDeviceInformationNumberFieldId(number)));
    }
    if (device.name?.included) {
      setIncludedFields(setToggle(deviceInformationNameFieldId(number)));
    }
    if (device.supervisionTime?.included) {
      setIncludedFields(
        setToggle(deviceInformationSupervisionTimeFieldId(number))
      );
    }
    if (device.wirelessTranslator1100tFrequency?.included) {
      setIncludedFields(
        setToggle(deviceInformation1100TWirelessFrequencyFieldId(number))
      );
    }
    if (device.deviceType?.included) {
      setIncludedFields(setToggle(deviceInformationDeviceTypeFieldId(number)));
    }
    if (device.deviceCommunicationMethod?.included) {
      setIncludedFields(
        setToggle(deviceInformationDeviceCommunicationTypeFieldId(number))
      );
    }
    if (device.accessAreas?.included) {
      setIncludedFields(setToggle(deviceInformationAccessAreasFieldId(number)));
    }
    if (device.egressAreas?.included) {
      setIncludedFields(setToggle(deviceInformationEgressAreasFieldId(number)));
    }
    if (device.displayAreas?.included) {
      setIncludedFields(
        setToggle(deviceInformationDisplayAreasFieldId(number))
      );
    }
    if (device.strikeTime?.included) {
      setIncludedFields(setToggle(deviceInformationStrikeTimeFieldId(number)));
    }
    if (device.strikeDelay?.included) {
      setIncludedFields(setToggle(deviceInformationStrikeDelayFieldId(number)));
    }
    if (device.fireExit?.included) {
      setIncludedFields(
        setToggle(deviceInformationFireExitReleaseFieldId(number))
      );
    }
    if (device.publicDoor?.included) {
      setIncludedFields(setToggle(deviceInformationPublicDoorFieldId(number)));
    }
    if (device.triggerOutputGroupWithAccess?.included) {
      setIncludedFields(setToggle(deviceInformationOutputGroupFieldId(number)));
    }
    if (device.overrideScheduleWhenArmed?.included) {
      setIncludedFields(
        setToggle(deviceInformationScheduleOverrideFieldId(number))
      );
    }
    if (device.autoForceArm?.included) {
      setIncludedFields(
        setToggle(deviceInformationAutoForceArmFieldId(number))
      );
    }
    if (device.doorRealTimeStatusMessages?.included) {
      setIncludedFields(
        setToggle(deviceInformationDoorRealTimeStatusFieldId(number))
      );
    }
    if (device.doorForceMessages?.included) {
      setIncludedFields(
        setToggle(deviceInformationSendDoorForcedMessageFieldId(number))
      );
    }
    if (device.remoteProgram734?.included) {
      setIncludedFields(
        setToggle(deviceInformationProgram734OptionsFieldId(number))
      );
    }
    if (device.privateDoor?.included) {
      setIncludedFields(setToggle(deviceInformationPrivateDoorFieldId(number)));
    }
    if (device.wirelessTranslator1100t?.included) {
      setIncludedFields(setToggle(deviceInformation1100TFieldId(number)));
    }
    if (device.device734?.data?.zone2BypassOnRequestToExit?.included) {
      setIncludedFields(
        setToggle(deviceInformationActivateZone2BypassFieldId(number))
      );
    }
    if (device.device734?.data?.zone2BypassTime?.included) {
      setIncludedFields(
        setToggle(deviceInformationZone2BypassTimeFieldId(number))
      );
    }
    if (device.device734?.data?.zone2RelockOnStateChange?.included) {
      setIncludedFields(
        setToggle(deviceInformationRelockOnZone2ChangeFieldId(number))
      );
    }
    if (device.device734?.data?.enableZone3RequestToExit?.included) {
      setIncludedFields(
        setToggle(deviceInformationActivateZone3RequestToExitFieldId(number))
      );
    }
    if (device.device734?.data?.zone3RequestToExitTime?.included) {
      setIncludedFields(
        setToggle(deviceInformationZone3RequestToExitTimeFieldId(number))
      );
    }
    if (device.device734?.data?.enableOnboardSpeaker?.included) {
      setIncludedFields(
        setToggle(deviceInformationActivateOnboardSpeakerFieldId(number))
      );
    }
    if (device.device734?.data?.cardFormatOptions?.included) {
      setIncludedFields(setToggle(deviceInformationCardOptionsFieldId(number)));
    }
    if (device.device734?.data?.wiegandBitLength?.included) {
      setIncludedFields(
        setToggle(deviceInformationWiegandCodeLengthFieldId(number))
      );
    }
    if (device.device734?.data?.siteCodeStartBitPosition?.included) {
      setIncludedFields(
        setToggle(deviceInformationSiteCodePositionFieldId(number))
      );
    }
    if (device.device734?.data?.siteCodeBitLength?.included) {
      setIncludedFields(
        setToggle(deviceInformationSiteCodeLengthFieldId(number))
      );
    }
    if (device.device734?.data?.userCodeStartBitPosition?.included) {
      setIncludedFields(
        setToggle(deviceInformationUserCodePositionFieldId(number))
      );
    }
    if (device.device734?.data?.userCodeBitLength?.included) {
      setIncludedFields(
        setToggle(deviceInformationUserCodeLengthFieldId(number))
      );
    }
    if (device.device734?.data?.enforceSiteCode?.included) {
      setIncludedFields(
        setToggle(deviceInformationRequireSiteCodeFieldId(number))
      );
    }
    if (device.device734?.data?.siteCode1?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode1FieldId(number)));
    }
    if (device.device734?.data?.siteCode2?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode2FieldId(number)));
    }
    if (device.device734?.data?.siteCode3?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode3FieldId(number)));
    }
    if (device.device734?.data?.siteCode4?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode4FieldId(number)));
    }
    if (device.device734?.data?.siteCode5?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode5FieldId(number)));
    }
    if (device.device734?.data?.siteCode6?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode6FieldId(number)));
    }
    if (device.device734?.data?.siteCode7?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode7FieldId(number)));
    }
    if (device.device734?.data?.siteCode8?.included) {
      setIncludedFields(setToggle(deviceInformationSiteCode8FieldId(number)));
    }
    if (device.device734?.data?.numberOfUserCodeDigits?.included) {
      setIncludedFields(
        setToggle(deviceInformationUserCodeDigitsFieldId(number))
      );
    }
    if (device.device734?.data?.noCommWithPanelRelayAction?.included) {
      setIncludedFields(
        setToggle(deviceInformationNoCommWithPanelFieldId(number))
      );
    }
  });
};
