import React from "react";
import styled from "styled-components";
import { media } from "../../../utils/styles";
import { widgetPaddingX } from "../Widget";
import ArmingSlider from "./ArmingSlider";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import { Area_area$key } from "./__generated__/Area_area.graphql";

export const AREA_ROW_HEIGHT_IN_PX = 47;

type AreaProps = {
  area: Area_area$key;
  isPending: boolean;
  setBadZonesMethod: Function;
};

const Area = ({ area, isPending, setBadZonesMethod }: AreaProps) => {
  const data = useFragment(
    graphql`
      fragment Area_area on Area {
        id
        name
        ...ArmingSlider_area
      }
    `,
    area
  );

  return (
    <Root key={data.name}>
      <Name>
        <Label htmlFor={data.id}>{data.name}</Label>
      </Name>
      <Slider
        area={data}
        isPending={isPending}
        setBadZonesMethod={setBadZonesMethod}
      />
    </Root>
  );
};

export default Area;

const Root = styled.li`
  ${widgetPaddingX};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${AREA_ROW_HEIGHT_IN_PX}px;
  border-top: 1px solid ${({ theme }) => theme.disabledGray};
  &:last-child {
    margin-bottom: 0;
    border-bottom: 1px solid ${({ theme }) => theme.grayLight};
  }
  &:nth-child(odd) {
    background: ${({ theme }) => theme.zebraDark};
  }
`;
const Name = styled.div`
  flex: 3;
  color: ${({ theme }) => theme.textColor};
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.xsmall`
    font-size: 1em;
  `};
`;
const Label = styled.label`
  cursor: pointer;
`;
const Slider = styled(ArmingSlider)`
  flex: 1;
  min-width: 135px;
  font-size: 12px;
`;
