import DaColors from "app/common/DaColors";
import DmpIcon, { DmpIconName } from "common/components/DmpIcon";
import React from "react";
import styled from "styled-components";

const TabWrapper = styled.div<{ onlyOneTab: boolean; isActive: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: ${(props) => (props.onlyOneTab ? "start" : "center")};
  gap: 0.8rem;
  border-style: solid;
  border-width: 0.1rem 0.1rem 0 0.1rem;
  border-color: ${DaColors.Neutral25};
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  padding: 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bold;
  background-color: ${(props) =>
    props.isActive ? DaColors.White : DaColors.Neutral25};
`;
const TabGroupWrapper = styled.div<{ onlyOneTab: boolean }>`
  display: flex;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  background-color: ${DaColors.Neutral250};
  justify-content: ${(props) => (props.onlyOneTab ? "start" : "space-between")};
  cursor: ${(props) => (props.onlyOneTab ? "auto" : "pointer")};
`;

type TabProps = {
  activeValue: string;
  displayName: string;
  iconName?: DmpIconName;
  title?: string;
  onlyOneTab?: boolean;
  onSelectTab: (value: string) => void;
  value: string;
};

const Tab = ({
  activeValue,
  displayName,
  iconName,
  title,
  onlyOneTab = false,
  onSelectTab,
  value,
}: TabProps) => {
  return (
    <TabWrapper
      onlyOneTab={onlyOneTab}
      isActive={value === activeValue}
      onClick={() => onSelectTab(value)}
      title={title}
    >
      {iconName && (
        <DmpIcon icon={iconName} constrainToParent={false} size={16} />
      )}
      {displayName}
    </TabWrapper>
  );
};

export type TabData = {
  displayName: string;
  value: string;
  iconName?: DmpIconName;
  title?: string;
};

type Props = {
  data: TabData[];
  activeValue: string;
  onSelectTab: (value: string) => void;
};

const TabGroup = ({ data, activeValue, onSelectTab }: Props) => {
  const onlyOneTab = data.length === 1;

  return (
    <TabGroupWrapper onlyOneTab={onlyOneTab}>
      {data.map((d) => {
        return (
          <Tab
            key={d.value}
            activeValue={activeValue}
            displayName={d.displayName}
            iconName={d.iconName}
            title={d.title}
            onlyOneTab={onlyOneTab}
            onSelectTab={onSelectTab}
            value={d.value}
          />
        );
      })}
    </TabGroupWrapper>
  );
};

export default TabGroup;
