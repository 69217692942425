import graphql from "babel-plugin-relay/macro";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useMenuDisplayFragment } from "./MenuDisplayContext";
import { resolveMenuDisplayRules } from "./utils";
import { MenuDisplayTimeDisplayKeypadsField_menuDisplay$key } from "./__generated__/MenuDisplayTimeDisplayKeypadsField_menuDisplay.graphql";

export const menuDisplayTimeDisplayKeypadsFieldId = () =>
  "menu-display-time-display-keypads";

function MenuDisplayTimeDisplayKeypadsField() {
  const [{ timeDisplayKeypads }, updateMenuDisplay] =
    useMenuDisplayFragment<MenuDisplayTimeDisplayKeypadsField_menuDisplay$key>(
      graphql`
        fragment MenuDisplayTimeDisplayKeypadsField_menuDisplay on MenuDisplay {
          timeDisplayKeypads
        }
      `
    );

  const fieldId = menuDisplayTimeDisplayKeypadsFieldId();
  const { inlineHelp, validationMessage, pattern } = resolveMenuDisplayRules(
    useHardwareModel()
  );

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Time Display Keypads"
      tooltip="Enter the keypad addresses that can display the time and day of the week."
    >
      <TextInput
        id={fieldId}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        value={timeDisplayKeypads}
        onChange={({ target }) => {
          updateMenuDisplay((recordProxy) => {
            recordProxy.setValue(target.value, "timeDisplayKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateMenuDisplay((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "timeDisplayKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default MenuDisplayTimeDisplayKeypadsField;
