import { sleep } from "common/utils";
import { getAuthFromJWT } from "utils/string";

/**
 * @ngdoc service
 * @name App.factory:Camera
 *
 * @description
 * API factory for Camera
 *
 */
App.factory("Camera", [
  "$q",
  "PROPS",
  "VideoVerifyV2API",
  "UserService",
  "$http",
  "$timeout",
  "$filter",
  "$interval",

  function (
    $q,
    PROPS,
    VideoVerifyV2API,
    UserService,
    $http,
    $timeout,
    $filter,
    $interval
  ) {
    var Camera = function (url, clip_url, record_url, id) {
      angular.extend(this, {
        available: true,

        id: id,

        fps: 0,

        error_url: "/assets/img/Unable_to_load_image.png",

        retry_count: 0,

        isPT: false,

        light_status: false,

        pan_angle: 0,

        start_angles: undefined,

        tilt_angle: 0,

        peripheral_status: {},

        hls_url: "",

        stream_playback: false,

        device_type: "", // camera, dvr, nvr

        has_light: false, //Added to show or hide based on the return from peripheral status call.  If status returns 'error' light button is not displayed.

        get: function () {
          var deferred = $q.defer();
          var _this = this;
          $http.get(PROPS.apiUrl + url).then(
            function (data) {
              //success
              data = data.data;
              angular.extend(_this, data.video_channel);
              _this.refreshUrlData();
              try {
                //peripheral_status_url can be null for V-6000
                if (this.peripheral_status_url) {
                  _this
                    .getPeripheralStatus()
                    .then(
                      function () {
                        deferred.resolve(data.video_channel);
                      },
                      function () {
                        //error, so wait and try again
                        $timeout(function () {
                          _this.getPeripheralStatus();
                        }, 5000);
                        deferred.resolve(data.video_channel);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              } catch (e) {}
            },
            function (error) {
              //failure
              _this.available = false;
              _this.jpeg_url = _this.error_url;
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name refreshUrlData
         * @methodOf App.factory:Camera
         *
         * @description
         * Transforms the http calls to https to avoid CORS issues.
         */
        refreshUrlData: function () {
          //this.available = this.network_status.toUpperCase() !== 'INACCESSIBLE';
          this.mjpeg_url =
            this.available && this.mjpeg_url //can be null for V-6000
              ? this.mjpeg_url.replace(/^http:\/\//i, "https://")
              : this.error_url;
          this.jpeg_url =
            this.available && this.snapshot_url
              ? this.snapshot_url.replace(/^http:\/\//i, "https://")
              : this.error_url;
          this.rtsp_url =
            this.available && this.rtsp_url //can be null for V-6000
              ? this.rtsp_url.replace(/^http:\/\//i, "https://")
              : this.error_url;
          try {
            //can be null for V-6000
            if (this.peripheral_status_url) {
              this.peripheral_status_url = this.peripheral_status_url.replace(
                /^http:\/\//i,
                "https://"
              );
            }
          } catch (e) {}
          if (this.movement_url) {
            this.movement_url = this.movement_url.replace(
              /^http:\/\//i,
              "https://"
            );
          }
          if (this.light_url) {
            this.light_url = this.light_url.replace(/^http:\/\//i, "https://");
          }
          this.jpeg_retry = new Date().getTime();
          if (this.pan_range.length > 0 && this.tilt_range.length > 0) {
            this.isPT = true;
          }

          this.showing_jpeg = !this.available;
        },
        minPan: function () {
          if (this.pan_range.length) {
            return this.pan_range[0];
          } else {
            return -1;
          }
        },
        maxPan: function () {
          if (this.pan_range.length) {
            return this.pan_range[1];
          } else {
            return 1;
          }
        },
        minTilt: function () {
          if (this.tilt_range.length) {
            if (this.tilt_range[1] == 0 && !this.ceiling_mount) {
              //if tilt_range[1] == 0, PT is a zero to x based movement(i.e. Hikvision)
              return this.tilt_range[0] * -1; //zero based tilt returns min as the largest number, so reversed to negative so the control could better handle the response.
            } else if (this.tilt_range[1] == 0 && this.ceiling_mount) {
              return this.tilt_range[1]; //if the zero based camera is inverted, the min value is swapped with the max
            } else {
              return this.tilt_range[0];
            }
          } else {
            return -1;
          }
        },
        maxTilt: function () {
          if (this.tilt_range.length) {
            if (this.tilt_range[1] == 0 && this.ceiling_mount) {
              //if tilt_range[1] == 0, PT is a zero to x based movement(i.e. Hikvision)
              return this.tilt_range[0]; //If zero based, high number is in the first position of the range array.
            } else {
              return this.tilt_range[1];
            }
          } else {
            return 1;
          }
        },
        /**
         * @ngdoc object
         * @name refreshJpegUrl
         * @methodOf App.factory:Camera
         *
         * @description
         *Make sure that the jpeg request is unique for every call (avoid cached response)
         */
        refreshJpegUrl: function () {
          if (typeof this.jpeg_url == "undefined") {
            return;
          }

          var d = new Date();
          if (this.jpeg_url !== null) {
            if (this.jpeg_url.lastIndexOf("?") > 1) {
              this.jpeg_url =
                this.jpeg_url.substring(0, this.jpeg_url.lastIndexOf("?") + 1) +
                d.getTime();
            } else {
              this.jpeg_url = this.jpeg_url + "?" + d.getTime();
            }
          }
        },
        refreshSnapshotUrl: function () {
          if (typeof this.snapshot_url == "undefined") {
            return;
          }
          // set the auth token for the snapshot url via $sessionStorage
          let token = UserService.auth_token;
          // This is the only place where we are adding the auth token to the snapshot url and it will not be used when the new VV is implemented
          var d = new Date();
          if (this.snapshot_url !== null) {
            if (this.snapshot_url.lastIndexOf("?") > 1) {
              this.snapshot_url =
                this.snapshot_url.substring(
                  0,
                  this.snapshot_url.lastIndexOf("?") + 1
                ) +
                d.getTime() +
                (this.snapshot_url.lastIndexOf("vidprox") !== -1 &&
                token.length > 40 // these are here to account for the 32 character auth token that is used for the VV non login method
                  ? "&auth_token=" + getAuthFromJWT(token)
                  : this.snapshot_url.lastIndexOf("vidprox") !== -1 &&
                    token.length <= 40
                  ? "&auth_token=" + token
                  : "");
            } else {
              this.snapshot_url =
                this.snapshot_url +
                "?" +
                d.getTime() +
                (this.snapshot_url.lastIndexOf("vidprox") !== -1 &&
                token.length > 40
                  ? "&auth_token=" + getAuthFromJWT(token)
                  : this.snapshot_url.lastIndexOf("vidprox") !== -1 &&
                    token.length <= 40
                  ? "&auth_token=" + token
                  : "");
            }
          }
        },
        /**
         * @ngdoc object
         * @name getVVClips
         * @methodOf App.factory:Camera
         *
         * @description
         *Returns the list of clips for this camera
         */
        getVVClips: function () {
          var deferred = $q.defer();
          var _this = this;
          if (_this.device_type === "camera" || _this.device_type === "dvr") {
            $http.get(PROPS.apiUrl + clip_url).then(
              async function (data) {
                //success
                let cameraName = _this.name;
                for (let i = 0; i < 10; i++) {
                  if (cameraName === undefined) {
                    // if the camera name is undefined, try try again
                    await sleep(100);
                    cameraName = _this.name;
                  } else break;
                }
                const dataWithName = data.data.map(({ clip }) => {
                  return { clip: { ...clip, camera_name: cameraName } };
                });
                deferred.resolve(dataWithName);
              },
              function (error) {
                //failure
                deferred.notify({
                  job_uuid: "n/a",
                  status: "error",
                  poll_count: 0,
                });
                deferred.reject(error);
              },
              function (info) {
                //failure
                deferred.notify(info);
              }
            );
          } else {
            deferred.resolve([]);
          }

          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name getPeripheralStatus
         * @methodOf App.factory:Camera
         * gets the extended status of the camera.  Typically returns something like:
         * <pre>
         * {pt_position: "919,156", wl_leds: "error"}
         * </pre>
         * or for Sercomm:
         * <pre>
         * {dn_mode: "error",input_1: "low",input_2: "error",input_3: "error",input_4: "error",ir_cut: "error",ir_leds: "error",
         *  light_sensor: "day", output_1: "high,static", output_2: "error", output_3: "error", output_4: "error", pir: "disabled",
         *  privacy_button: "disabled", pt_position: "-117,8", wl_leds: "off"}
         * </pre>
         * The primary properties used are the pt_position and light status.  Because Sercomm and Hikvision implementations of PT are so
         * different, there are several odd logic branches.  Please note inline comments before changing PT movement and position logic.
         *
         * @returns {deferred promise}
         */
        getPeripheralStatus: function () {
          var deferred = $q.defer();
          var _this = this;
          $http
            .get(_this.peripheral_status_url)
            .then(
              function (data) {
                //success
                angular.extend(_this.peripheral_status, data);
                if (data.pt_position) {
                  var position = data.pt_position.split(",");
                  if (position.length == 2) {
                    // If there's a pan angle and a tilt angle we set the PT positions...
                    //Pan is always the same for Sercomm, but needs to be reversed for Hikvision if it is a ceiling mount
                    _this.pan_angle =
                      _this.pan_range[0] == 0 && _this.ceiling_mount
                        ? _this.pan_range[1] - parseInt(position[0])
                        : parseInt(position[0]);
                    if (_this.ceiling_mount && _this.tilt_range[1] != 0) {
                      //this should be a ceiling mounted Sercomm
                      position[1] =
                        _this.minTilt() +
                        _this.maxTilt() -
                        parseInt(position[1]); //Sercomm PT cameras requires an 'interesting' formula to calculate the tilt while inverted.
                    }
                    //if PT is zero based and not ceiling mounted, the max and min values will be reversed.
                    _this.tilt_angle =
                      _this.tilt_range[1] == 0 && !_this.ceiling_mount
                        ? parseInt(position[1]) * -1
                        : parseInt(position[1]);
                    if (!_this.start_angles) {
                      //if this is the first time thru, the current positions as set for the 'home' button
                      _this.start_angles = 4;
                      _this.postToCandela(_this.movement_url, {
                        preset: "set," + _this.start_angles,
                      });
                    }
                  }
                }
                if (data.wl_leds) {
                  _this.has_light = data.wl_leds != "error";
                  _this.light_status = data.wl_leds == "on";
                }
                deferred.resolve(data);
              },
              function (error) {
                //failure
                deferred.notify({
                  job_uuid: "n/a",
                  status: "error",
                  poll_count: 0,
                });
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
        /**
         * @ngdoc object
         * @name moveToStart
         * @methodOf App.factory:Camera
         *
         * @description
         * Simple function to move PT camera back to position that was observed when the VV page was first loaded.
         */
        moveToStart: function () {
          var _this = this;
          this.postToCandela(this.movement_url, {
            preset: "move," + this.start_angles,
          }).finally(function () {
            _this.getPeripheralStatus();
            $timeout(function () {
              _this.getPeripheralStatus();
            }, 5000);
          });
        },
        tiltUp: function () {
          var _this = this;
          this.tilt_angle = parseInt(this.tilt_angle) + 30;
          if (this.tilt_angle > this.maxTilt()) {
            this.tilt_angle = this.maxTilt();
          }
          //if tilt range is zero based and not ceiling mounted then inverse the value before sending to camera
          this.postToCandela(this.movement_url, {
            mv:
              "T," +
              (_this.tilt_range[1] == 0 && !_this.ceiling_mount
                ? _this.tilt_angle * -1
                : _this.tilt_angle),
          })
            .then(
              function () {},
              function (error) {
                _this.getPeripheralStatus();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },
        tiltDown: function () {
          var _this = this;
          this.tilt_angle = parseInt(this.tilt_angle) - 30;
          if (this.tilt_angle < this.minTilt()) {
            this.tilt_angle = this.minTilt();
          }
          //if tilt range is zero based and not ceiling mounted then inverse the value before sending to camera
          this.postToCandela(this.movement_url, {
            mv:
              "T," +
              (_this.tilt_range[1] == 0 && !_this.ceiling_mount
                ? _this.tilt_angle * -1
                : _this.tilt_angle),
          })
            .then(
              function () {},
              function (error) {
                _this.getPeripheralStatus();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },

        panRight: function () {
          var _this = this;
          _this.pan_angle = parseInt(_this.pan_angle) + 30;
          if (_this.pan_angle > _this.maxPan()) {
            _this.pan_angle = _this.maxPan();
          }
          //if pan is zero based and ceiling mount, subtract desired pan position from maximum pan range before you send to camera.
          this.postToCandela(_this.movement_url, {
            mv:
              "P," +
              (_this.pan_range[0] == 0 && _this.ceiling_mount
                ? _this.pan_range[1] - _this.pan_angle
                : _this.pan_angle),
          })
            .then(
              function () {},
              function (error) {
                _this.getPeripheralStatus();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },
        panLeft: function () {
          var _this = this;
          this.pan_angle = parseInt(this.pan_angle) - 30;
          if (this.pan_angle < this.minPan()) {
            this.pan_angle = this.minPan();
          }
          //if pan is zero based and ceiling mount, subtract desired pan position from maximum pan range before you send to camera.
          this.postToCandela(_this.movement_url, {
            mv:
              "P," +
              (_this.pan_range[0] == 0 && _this.ceiling_mount
                ? _this.pan_range[1] - _this.pan_angle
                : _this.pan_angle),
          })
            .then(
              function () {},
              function (error) {
                _this.getPeripheralStatus();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },
        tiltToAngle: function () {
          var _this = this;
          var postBody = {};
          //postBody.tiltAngle = this.tilt_angle;
          $timeout.cancel(this.setTiltAngle);
          this.setTiltAngle = $timeout(function () {
            //if tilt range is zero based and not ceiling mounted then inverse the value before sending to camera
            _this
              .postToCandela(_this.movement_url, {
                mv:
                  "T," +
                  (_this.tilt_range[1] == 0 && !_this.ceiling_mount
                    ? _this.tilt_angle * -1
                    : _this.tilt_angle),
              })
              .then(
                function () {},
                function (error) {
                  _this.getPeripheralStatus();
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }, 500);
        },
        panToAngle: function () {
          var _this = this;
          var postBody = {};
          //postBody.panAngle = this.pan_angle;
          $timeout.cancel(this.setPanAngle);
          this.setPanAngle = $timeout(function () {
            //if pan is zero based and ceiling mount, subtract desired pan position from maximum pan range before you send to camera.
            _this
              .postToCandela(_this.movement_url, {
                mv:
                  "P," +
                  (_this.pan_range[0] == 0 && _this.ceiling_mount
                    ? _this.pan_range[1] - _this.pan_angle
                    : _this.pan_angle),
              })
              .then(
                function () {},
                function (error) {
                  _this.getPeripheralStatus();
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }, 500);
        },
        toggleLight: function () {
          var _this = this;
          this.postToCandela(this.light_url, {
            action: this.light_status ? 0 : 1,
          })
            .then(
              function () {
                _this.light_status = !_this.light_status;
              },
              function (error) {
                _this.getPeripheralStatus();
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        },

        postToCandela: function (url, params) {
          var deferred = $q.defer();
          var _this = this;
          $http({
            method: "POST",
            url: url,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded", //TODO: Test removing this header property after Angular upgrade
              Accept: "application/json",
            },
          })
            .then(
              function (status) {
                deferred.resolve(status);
              },
              function (data, status, headers, config) {
                deferred.reject(data);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
          return deferred.promise;
        },
        triggerRecord: function () {
          var deferred = $q.defer();
          var _this = this;
          $http
            .post(PROPS.apiUrl + record_url, {})
            .then(
              function (data) {
                //success
                deferred.resolve(data);
              },
              function (error) {
                //failure
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
              deferred.reject(error);
            });

          return deferred.promise;
        },

        /**
         * Get an HLS camera stream for an NVR camera.  If no playbackStartTime is provided,
         * it assumes you want a "LIVE" stream.
         * @param {*} playbackStartTime
         */
        getNVRCameraStream: function (playbackStartTime) {
          var deferred = $q.defer();
          var playbackParams = "?playbackid=" + UserService.auth_token;
          var startTimeParam = "";
          var baseStreamUrl = this.rtsp_url;
          this.stream_playback = false;

          // if there is a playbackStartTime, (not null), we need to format it correctly
          if (!isUndefinedOrNull(playbackStartTime)) {
            this.stream_playback = true;
            let dateFormat = "yyyyMMdd'T'HHmmss'Z'";
            let timezone = null;
            let start_time = $filter("date")(
              playbackStartTime,
              dateFormat,
              timezone
            );
            startTimeParam += "?starttime=" + start_time;
            // Reset the baseStreamUrl to use the playback_url, with trailing slashes stripped
            baseStreamUrl = this.playback_url.replace(/\/$/, "");
            this.stream_start_time = playbackStartTime;
          }
          // else, we want a "LIVE" stream if there is no playbackStartTime
          //either way finish formatting the stream url
          // Create base64 string of the url
          let base64playbackUrl = btoa(baseStreamUrl + startTimeParam);
          base64playbackUrl = base64playbackUrl.replace("/", "_");
          base64playbackUrl = base64playbackUrl.replace("+", "-");
          // Pass those to the proxy_url
          let proxyUrl = !isUndefinedOrNull(playbackStartTime)
            ? this.proxy_url + base64playbackUrl + playbackParams
            : this.proxy_url + base64playbackUrl;
          // Delete existing stream
          this.deleteExistingStream();
          // Create new stream
          this.getHlsUrlFromProxy(proxyUrl)
            .then(
              function (hlsUrl) {
                // Success
                deferred.resolve(hlsUrl);
              },
              function (error) {
                // Failure
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              deferred.reject(error);
            });

          return deferred.promise;
        },

        getV6000CameraStream: function (playbackStartTime) {
          var deferred = $q.defer();
          var playbackParams = "?playbackid=" + UserService.auth_token;
          var startTimeParam = "";
          var baseStreamUrl = this.rtsp_url;
          this.stream_playback = false;

          // if there is a playbackStartTime, (not null), we need to format it correctly
          if (!isUndefinedOrNull(playbackStartTime)) {
            this.stream_playback = true;
            let dateFormat = "yyyyMMdd'T'HHmmss'Z'";
            let timezone = null;
            let start_time = $filter("date")(
              playbackStartTime,
              dateFormat,
              timezone
            );
            startTimeParam += "?starttime=" + start_time;
            // Reset the baseStreamUrl to use the playback_url, with trailing slashes stripped
            baseStreamUrl = this.playback_url.replace(/\/$/, "");
            this.stream_start_time = playbackStartTime;
          }
          // else, we want a "LIVE" stream if there is no playbackStartTime
          //either way finish formatting the stream url
          // Create base64 string of the url
          let base64playbackUrl = btoa(baseStreamUrl + startTimeParam);
          base64playbackUrl = base64playbackUrl.replace("/", "_");
          base64playbackUrl = base64playbackUrl.replace("+", "-");
          // Pass those to the proxy_url
          let proxyUrl = !isUndefinedOrNull(playbackStartTime)
            ? this.proxy_url + base64playbackUrl + playbackParams
            : this.proxy_url + base64playbackUrl;
          // Delete existing stream
          this.deleteExistingStream();
          // Create new stream
          this.getV6000HlsUrlFromProxy(proxyUrl)
            .then(
              function (hlsUrl) {
                // Success
                deferred.resolve(hlsUrl);
              },
              function (error) {
                // Failure
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              deferred.reject(error);
            });

          return deferred.promise;
        },

        /**
         * Function to form a valid request to the camera.proxy_url (vidprox), which should retrieve a
         * valid HLS rtsp stream
         * @param {*} proxyUrl
         */
        getHlsUrlFromProxy: function (proxyUrl) {
          var deferred = $q.defer();
          var _this = this;

          // Create base64 string of admin_username:admin_password, pass as header authorization with "Basic " + variable
          let base64Auth = btoa(
            this.admin_username + ":" + this.admin_password
          );
          let authHeader = "Basic " + base64Auth;
          var req = {
            headers: {
              Authorization: authHeader,
            },
          };

          $http
            .get(proxyUrl, req)
            .then(
              function (data) {
                // The response from that is another rtsp URL that can be passed to hls.js
                let hlsUrl =
                  PROPS.hlsUrl +
                  "/stream?url=" +
                  encodeURIComponent(data.data.GetProxyResult.url);
                _this.hls_url = hlsUrl;
                if (hlsUrl.endsWith("null")) {
                  deferred.reject(
                    data.data.GetProxyResult.status_detail +
                      " : " +
                      data.data.GetProxyResult.response_message
                  );
                } else {
                  deferred.resolve(hlsUrl);
                }
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
              deferred.reject(error);
            });

          return deferred.promise;
        },

        /**
         * Delete playback streams, since only one can exist per camera at a time
         */
        deleteExistingStream: function () {
          // The presence of 'tracks' means it's a playback URL
          if (this.hls_url) {
            $http.delete(this.hls_url);
            this.hls_url = "";
            this.stream_playback = false;
          }
        },
      });
      this.camera_video_verification_path = url;
      this.clips_video_verification_path = clip_url;
      this.trigger_video_verification_path = record_url;
      this.get();
    };
    return Camera;
  },
]);
