/**
 * @generated SignedSource<<e99236f1f3fe4cff5570ee20e08fa973>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemTestButton_controlSystem$data = {
  readonly centralStationAutomationIntegration: {
    readonly id: string;
    readonly systemTestExpiresAt: string | null;
    readonly systemTestsEnabled: boolean;
  } | null;
  readonly currentPersonCan: {
    readonly placeSystemOnTest: boolean;
  };
  readonly id: string;
  readonly " $fragmentType": "SystemTestButton_controlSystem";
};
export type SystemTestButton_controlSystem$key = {
  readonly " $data"?: SystemTestButton_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemTestButton_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemTestButton_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonCan",
      "kind": "LinkedField",
      "name": "currentPersonCan",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeSystemOnTest",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ControlSystemCentralStationAutomationIntegration",
      "kind": "LinkedField",
      "name": "centralStationAutomationIntegration",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTestExpiresAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTestsEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "bcc766b04e78fa0e36c044e3340d672c";

export default node;
