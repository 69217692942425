/**
 * @generated SignedSource<<bc0e19b840ebc23cda99c669b5eda4d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputOptionsPanicAlarmOutputField_outputOptions$data = {
  readonly id: string;
  readonly panicAlarmOutput: {
    readonly formattedNumber: string;
    readonly id: string;
    readonly number: number;
  };
  readonly " $fragmentType": "OutputOptionsPanicAlarmOutputField_outputOptions";
};
export type OutputOptionsPanicAlarmOutputField_outputOptions$key = {
  readonly " $data"?: OutputOptionsPanicAlarmOutputField_outputOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutputOptionsPanicAlarmOutputField_outputOptions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutputOptionsPanicAlarmOutputField_outputOptions",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "panicAlarmOutput",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutputOptions",
  "abstractKey": null
};
})();

(node as any).hash = "a7c89a930a3e1c21b1aa959a33712678";

export default node;
