import * as React from "react";
import { BaseInput, BaseInputProps } from "./BaseInput";
import InlineHelp from "./InlineHelp";
import Label from "./Label";

export const PasswordInput = React.forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > &
      BaseInputProps & {
        inlineHelp?: React.ReactNode;
        validationMessage?: string;
      },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { value, inlineHelp, className, validationMessage, ...rest } = props;

    return (
      <Label>
        <BaseInput
          {...rest}
          type="password"
          ref={ref}
          value={value}
          className={`form-control ${className}`}
        />
        {inlineHelp && <InlineHelp>{inlineHelp}</InlineHelp>}
      </Label>
    );
  }
);
