/**
 * @generated SignedSource<<121d5734e51fb515eb89d8bd8b01408e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TMSentryFullProgrammingContainer_controlSystem$data = {
  readonly isCellComEx: boolean;
  readonly isCellComSl: boolean;
  readonly isIComSl: boolean;
  readonly isTMSentry: boolean;
  readonly name: string;
  readonly panel: {
    readonly softwareVersion: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryFullProgrammingContainer_templateStatus_controlSystem">;
  readonly " $fragmentType": "TMSentryFullProgrammingContainer_controlSystem";
};
export type TMSentryFullProgrammingContainer_controlSystem$key = {
  readonly " $data"?: TMSentryFullProgrammingContainer_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryFullProgrammingContainer_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TMSentryFullProgrammingContainer_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComSl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComEx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isIComSl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTMSentry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "softwareVersion",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryFullProgrammingContainer_templateStatus_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "fd5846842baa556f3098294ef384cdc8";

export default node;
