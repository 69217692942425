import { useEffect, RefObject } from "react";

export type ClickOutsideEvent = MouseEvent | TouchEvent;

export default function useOnClickOutside<
  T extends HTMLElement | null | undefined
>(
  ref: RefObject<T>,
  handler: (event: ClickOutsideEvent) => void,
  active = true
) {
  useEffect(() => {
    if (active) {
      const listener = (event: ClickOutsideEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }
  }, [active, handler]);
}
