/**
 * @generated SignedSource<<e6450790b616a363cd75e5c9cbef1c8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MenuDisplayTimeDisplayKeypadsField_menuDisplay$data = {
  readonly timeDisplayKeypads: string;
  readonly " $fragmentType": "MenuDisplayTimeDisplayKeypadsField_menuDisplay";
};
export type MenuDisplayTimeDisplayKeypadsField_menuDisplay$key = {
  readonly " $data"?: MenuDisplayTimeDisplayKeypadsField_menuDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"MenuDisplayTimeDisplayKeypadsField_menuDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MenuDisplayTimeDisplayKeypadsField_menuDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeDisplayKeypads",
      "storageKey": null
    }
  ],
  "type": "MenuDisplay",
  "abstractKey": null
};

(node as any).hash = "1e26faddf97c61edeebd14362d3396fb";

export default node;
