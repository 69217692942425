/**** Selected Result Component ****/

/*
 *  This component will use the information provided by DiagnosticSearch to query multiple
 *  API's. It will display information about the panel/system such as:
 *
 *  Basic System Info, Active/Scheduled Jobs, Queue Depth for Jobs, Basic SIM Info,
 *  Cellular/Network Analytics
 *  Panel Resets and Type
 */

import Modal from "common/components/web/Modal";
import { Flex } from "components/DaStyledElements";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import ConnectionInformation from "./ConnectionInformation";
import DiagnosticPanelWrapper from "./DiagnosticPanelWrapper";
import JobGroupList from "./JobGroupList";
import SelectedJobGroup from "./SelectedJobGroup";
import SystemInformation from "./SystemInformation";
import { PanelSearchResult } from "./utils";
import VarDiagnosticsRoot from "./VarDiagnosticsRoot";

function SelectedResult({
  selectedResult,
  authToken,
}: {
  selectedResult: PanelSearchResult;
  authToken: string;
}) {
  const [selectedGroup, setSelectedGroup] = React.useState<any>(null);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  return (
    <>
      <DiagnosticPanelWrapper title="System Information">
        <SystemInformation selectedResult={selectedResult} />
      </DiagnosticPanelWrapper>
      <DiagnosticPanelWrapper title="Job Groups - Newest to Oldest">
        <React.Suspense fallback={<GenericPageFallback />}>
          {selectedResult.controlSystemId && (
            <JobGroupList
              inputPanelId={selectedResult.controlSystemId}
              setSelectedGroup={setSelectedGroup}
              setModalOpen={setModalOpen}
            />
          )}
        </React.Suspense>
      </DiagnosticPanelWrapper>
      {modalOpen && (
        <Modal size="xlarge" onClickOutside={() => setModalOpen(false)}>
          <h3 className="printed-concept-heading pad-16">
            Jobs in Group {selectedGroup && selectedGroup.groupId}
          </h3>
          <Modal.Body>
            {selectedGroup && selectedResult.controlSystemId ? (
              <React.Suspense fallback={<GenericPageFallback />}>
                <SelectedJobGroup
                  inputPanelId={selectedResult.controlSystemId}
                  inputJobGroupId={selectedGroup.groupId}
                />
              </React.Suspense>
            ) : (
              <p style={{ textAlign: "center" }}>No job group selected.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Flex.Row justify={"center"} align={"center"}>
              <button
                className="btn btn-default btn-md"
                type="button"
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
            </Flex.Row>
          </Modal.Footer>
        </Modal>
      )}
      <DiagnosticPanelWrapper title="Connection Information">
        <React.Suspense fallback={<GenericPageFallback />}>
          {selectedResult.controlSystemId && (
            <ConnectionInformation
              inputPanelId={selectedResult.controlSystemId}
            />
          )}
        </React.Suspense>
      </DiagnosticPanelWrapper>
      <DiagnosticPanelWrapper title="XV Gateway Diagnostics">
        <React.Suspense fallback={<GenericPageFallback />}>
          {selectedResult.controlSystemId && (
            <VarDiagnosticsRoot
              inputPanelId={selectedResult.controlSystemId}
              authToken={authToken}
            />
          )}
        </React.Suspense>
      </DiagnosticPanelWrapper>
    </>
  );
}

export default SelectedResult;
