import React from "react";
import { joinSpaced } from "utils/string";
import styles from "./Layout.module.css";

export default function Sections({ children }: { children: React.ReactNode }) {
  return <div className={styles.grid}>{children}</div>;
}

Sections.Section = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.section}>{children}</div>;
};

export function FlexCenter({ children }: { children: React.ReactNode }) {
  return <div className={styles.flexCenter}>{children}</div>;
}

export function SheetButton({
  children,
  callout = false,
  ...rest
}: {
  children: React.ReactNode;
  callout?: boolean;
} & React.HTMLProps<HTMLButtonElement>) {
  return (
    <FlexCenter>
      <button
        {...rest}
        type="button"
        className={joinSpaced([
          "panel",
          styles.buttonSheet,
          callout ? "box" : styles.sheetFull,
          rest.className ? rest.className : "",
        ])}
      >
        {children}
      </button>
    </FlexCenter>
  );
}

export function Sheet({
  children,
  callout = false,
  ...rest
}: {
  children: React.ReactNode;
  callout?: boolean;
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <FlexCenter>
      <div
        {...rest}
        className={joinSpaced([
          "panel",
          callout ? "box" : styles.sheetFull,
          rest.className ? rest.className : "",
        ])}
      >
        {children}
      </div>
    </FlexCenter>
  );
}

export function SectionHeaderFull({ children }: { children: React.ReactNode }) {
  return <div className="sub-header">{children}</div>;
}
export function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="sub-header">
      <div className="sub-header__left">{children}</div>
    </div>
  );
}

SectionHeader.Right = ({ children }: { children: React.ReactNode }) => (
  <span className="sub-header__right">{children}</span>
);

SectionHeader.Title = ({
  children,
  className,
}: {
  children: React.ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) => (
  <span
    className={
      className ? `sub-header__title ${className}` : `sub-header__title`
    }
  >
    {children}
  </span>
);

SectionHeader.Add = (
  props: { children?: React.ReactNode } & React.HTMLProps<HTMLButtonElement>
) => {
  return <button className="btn btn-sm btn-add" {...props} type="button" />;
};
