App.controller("CustomerReferralsCtrl", [
  "$scope",
  "$rootScope",
  "CustomerReferralsService",
  "UserService",
  "$modal",
  "$filter",
  function (
    $scope,
    $rootScope,
    CustomerReferralsService,
    UserService,
    $modal,
    $filter
  ) {
    init();

    function init() {
      $scope.customerReferrals = {};
      $scope.showCompleted = false;
      getReferrals();
      UserService.dealerInfo.getReferralsCount();
      $scope.updateReferral = updateReferral;
      $scope.search = {};
    }

    function getReferrals() {
      CustomerReferralsService.getCustomerReferral(UserService.dealer_id).then(
        function (referrals) {
          $scope.customerReferrals = referrals.sort((a, b) =>
            a.CreatedAt < b.CreatedAt ? 1 : -1
          );
          for (let ref of $scope.customerReferrals) {
            ref.CreatedAt = $filter("date")(ref.CreatedAt, "M/d/yy");
          }

          $scope.updateView();
        },
        function (error) {}
      );
    }

    $scope.showFailedToSaveError = function () {
      $rootScope.alerts.push({
        type: "error",
        text: "Failed to save referral settings.",
      });
    };

    function updateReferral(referralEdit) {
      referralEdit.Redeemed = !referralEdit.Redeemed;
      CustomerReferralsService.changeCustomerReferral(
        referralEdit.Id,
        referralEdit
      ).then(
        function () {
          getReferrals();
          UserService.dealerInfo.getReferralsCount();
        },
        function (error) {}
      );
    }

    $scope.openReferralDetailModal = function (referredItem) {
      $scope.referral = referredItem;
      const referralDetailModal = $modal.open({
        templateUrl:
          "app/dealer/customer-referrals/modal--referral-detail.html",
        windowClass: "",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
        controller: function ($scope, $modalInstance) {
          $scope.closeModal = function (e) {
            $modalInstance.close();
          };
          $scope.closeImportModal = function (e) {
            $modalInstance.dismiss();
          };
        },
      });
    };

    $scope.toggleCompleted = function () {
      $scope.showCompleted
        ? ($scope.showCompleted = false)
        : ($scope.showCompleted = true);
      $scope.updateView();
    };

    $scope.updateView = function () {
      $scope.filteredReferrals = [...$scope.customerReferrals];
      //Filter and return either all referrals or just non-redeemed
      $scope.filteredReferrals = $scope.filteredReferrals.filter((referral) => {
        let returnStatement = $scope.showCompleted
          ? true
          : referral.Redeemed === false;
        return returnStatement;
      });
    };
  },
]);
